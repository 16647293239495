import React, { useState, useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';

import SummarydayClassroomsActions from 'store/summarydayClassrooms/actions';

import ModalContainer from 'components/Modal/ModalContainer';
import ModalTitle from 'components/Modal/ModalTitle';

import { ModalProps, ModalContextProps } from './types';

import * as S from './styles';

const ModalHideComment = ({
  activityId,
  commentId,
  isOpen,
  toggleModal,
  modalVariation,
}: ModalProps) => {
  const dispatch = useDispatch();

  const [modalContext, setModalContext] = useState<ModalContextProps>(
    {} as ModalContextProps
  );

  const { setCommentHide } = SummarydayClassroomsActions;

  const handleCommentHide = () => {
    const payload = { activityId, commentId };
    dispatch(setCommentHide(payload));
    toggleModal();
  };

  const hideComment = () => {
    return {
      title: 'Ocultar comentário?',
      description:
        'O comentário continuará disponível para a Equipe Escolar, mas oculto para os alunos. Tem certeza que deseja ocultar?',
      buttons: [
        {
          text: 'Cancelar',
          variation: 'secondary',
          onClick: toggleModal,
        },
        {
          text: 'Ocultar',
          variation: 'danger',
          onClick: handleCommentHide,
          className: 'button-danger',
        },
      ],
    };
  };

  const viewComment = () => {
    return {
      title: 'Exibir comentário?',
      description:
        'O comentário ficará disponível para todos os usuários, incluindo alunos. Tem certeza que deseja exibir?',
      buttons: [
        {
          text: 'Cancelar',
          variation: 'secondary',
          onClick: toggleModal,
        },
        { text: 'Exibir', variation: 'primary', onClick: handleCommentHide },
      ],
    };
  };

  useLayoutEffect(() => {
    switch (modalVariation) {
      case 'hide':
        setModalContext(hideComment);
        break;
      case 'view':
        setModalContext(viewComment);
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalVariation, toggleModal]);

  return (
    <ModalContainer
      isOpen={isOpen}
      toggleModal={toggleModal}
      title={<ModalTitle>{modalContext.title}</ModalTitle>}
    >
      <S.ModalBody>{modalContext.description}</S.ModalBody>

      <S.ModalFooter buttons={modalContext.buttons} />
    </ModalContainer>
  );
};

export default ModalHideComment;
