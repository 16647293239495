import React from 'react';

import FormContainer from 'components/Form/FormContainer';
import Field from 'components/Form/Field';
import Label from 'components/Form/Label';
import ButtonRow from 'components/ButtonRow';
import Button from 'components/Button';

import { SURVEY_LOG_STATUS } from 'core/constants/index';

import './style.scss';

const AnswersFilter = ({
  selectedRecipient,
  changeSurveyQuestionsParams,
  surveyQuestionsMeta,
}) => {
  const preparedSurveyQuestions = () =>
    surveyQuestionsMeta.survey_questions.map((question) => ({
      label: question.question,
      value: question.id,
    }));

  const preparedSurveyClassrooms = () => {
    const classroomList = surveyQuestionsMeta.survey_classrooms.map(
      (classroom) => {
        return {
          label: classroom.name,
          value: classroom.id,
        };
      }
    );

    return [{ label: 'Todas as turmas', value: '' }, ...classroomList];
  };

  const renderQuestionSelection = () => {
    if (surveyQuestionsMeta.survey_questions.length === 1) {
      return (
        <>
          <Label>Pergunta</Label>
          <h4>{surveyQuestionsMeta.survey_questions[0].question}</h4>
        </>
      );
    } else {
      return (
        <Field
          fullWidth="100%"
          label="Pergunta"
          size="large"
          mobileSize="extraLarge"
          attributeName="question"
          type="selectWithIcon"
          className="multi-questions-selector"
          options={preparedSurveyQuestions()}
          onChange={(event) => changeSurveyQuestionsParams({ id: event.value })}
        />
      );
    }
  };

  const renderRecipientSelection = () => {
    const onChangeStatus = (newStatus) => {
      changeSurveyQuestionsParams({ situation: newStatus.value });
    };

    const onChangeClassroom = (newClassroom) => {
      changeSurveyQuestionsParams({ classroom: newClassroom.value });
    };

    const availablePersonaType =
      surveyQuestionsMeta.survey_receivers.persona_type;
    const answeredBy = surveyQuestionsMeta.survey_receivers.answered_by;
    const responsibleAreAvailable =
      ['both', 'responsibles'].includes(availablePersonaType) &&
      ['both', 'responsibles'].includes(answeredBy);
    const studentsAreAvailable =
      ['both', 'students'].includes(availablePersonaType) &&
      ['both', 'students'].includes(answeredBy);

    return (
      <>
        <div className="filter-row">
          <ButtonRow>
            <label className="Label control-label buttons-label">
              Destinatários
            </label>
            <div className="button-container">
              <Button
                type="button"
                variation={
                  selectedRecipient === 'ResponsibleProfile'
                    ? 'primary'
                    : 'secondary'
                }
                className="recipient-button left-recipient-button"
                disabled={!responsibleAreAvailable}
                onClick={() =>
                  changeSurveyQuestionsParams({
                    receiver: 'ResponsibleProfile',
                    page: 1,
                  })
                }
              >
                Responsáveis
              </Button>
              <Button
                type="button"
                variation={
                  selectedRecipient === 'StudentProfile'
                    ? 'primary'
                    : 'secondary'
                }
                className="recipient-button right-recipient-button"
                disabled={!studentsAreAvailable}
                onClick={() =>
                  changeSurveyQuestionsParams({
                    receiver: 'StudentProfile',
                    page: 1,
                  })
                }
              >
                Alunos
              </Button>
            </div>
          </ButtonRow>
          <Field
            fullWidth="100%"
            className="situation-field"
            size="smaller"
            label="Situação"
            attributeName="situation"
            type="selectWithIcon"
            options={SURVEY_LOG_STATUS}
            onChange={onChangeStatus}
          />
          <Field
            fullWidth="100%"
            className="class-field"
            size="smaller"
            label="Turma"
            attributeName="classroom"
            type="selectWithIcon"
            options={preparedSurveyClassrooms()}
            onChange={onChangeClassroom}
          />
        </div>
      </>
    );
  };

  return (
    <section className="answers-filter-area">
      <FormContainer variation="vertical">
        {renderQuestionSelection()}
        {renderRecipientSelection()}
      </FormContainer>
    </section>
  );
};

export default AnswersFilter;
