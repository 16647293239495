import React from 'react';

export const RESPONSIBLE_PROFILE_MODEL_TABLE = [
  {
    field: 'Identificador Registro',
    required: 'Sim',
    description: (
      <>
        Descrição: Campo de uso único e individual utilizado para identificar o
        responsável cadastrado.
      </>
    ),
    rules: (
      <>
        Regras: Campo obrigatório que permite a inserção de caracteres
        alfanuméricos e especiais.
      </>
    ),
  },
  {
    field: 'Identificador Aluno',
    required: 'Sim',
    description: (
      <>
        Descrição: Campo utilizado para identificar o aluno relacionado ao
        responsável.
      </>
    ),
    rules: (
      <>
        Regras: Campo obrigatório que permite a inserção de caracteres
        alfanuméricos e especiais.
      </>
    ),
  },
  {
    field: 'Nome',
    required: 'Sim',
    description: (
      <>
        Descrição: Campo utilizado para preenchimento do nome do responsável a
        ser cadastrado.
      </>
    ),
    rules: (
      <>
        Regras: Campo obrigatório que permite a inserção de caracteres
        alfabéticos.
      </>
    ),
  },
  {
    field: 'E-mail',
    required: 'Não*',
    description: (
      <>
        Descrição: Campo utilizado para preenchimento do e-mail do responsável
        cadastrado.
      </>
    ),
    rules: (
      <>
        Regras: Campo que deve conter um e-mail válido. Exemplo:{' '}
        <b>antonio@gmail.com</b>
      </>
    ),
    observation: (
      <>
        Observação: O preenchimento deste campo será obrigatório se o campo de{' '}
        <b>login</b> não for preenchido.
      </>
    ),
  },
  {
    field: 'Login',
    required: 'Não*',
    description: (
      <>Descrição: Campo utilizado para preenchimento do nome do usuário.</>
    ),
    rules: (
      <>
        Regras: Campo que permite a inserção de caracteres alfanuméricos e
        especiais.
      </>
    ),
    observation: (
      <>
        Observação: O preenchimento deste campo será obrigatório se o campo de{' '}
        <b>e-mail</b> não for preenchido.
      </>
    ),
  },
  {
    field: 'Telefone',
    required: 'Não',
    description: (
      <>
        Descrição: Campo utilizado para o preenchimento do telefone do
        responsável a ser cadastrado.
      </>
    ),
    rules: (
      <>
        Regras: Deve conter 10 ou 11 números, excluindo espaços e caracteres
        especiais. Exemplo: (00) 00000-0000.
      </>
    ),
  },
  {
    field: 'Parentesco',
    required: 'Não',
    description: (
      <>
        Descrição: Campo utilizado para o preenchimento do parentesco dos alunos
        vinculados ao responsável a ser cadastrado.
      </>
    ),
    rules: (
      <>
        Regras: Seus valores devem ser um desses:{' '}
        <b>
          mãe, pai, avô, avò, tia, tio, babá, padastro, madastra, primo(a),
          irmão, irmã ou outros
        </b>
        .
      </>
    ),
  },
  {
    field: 'Responsável Financeiro',
    required: 'Não',
    description: (
      <>
        Descrição: Campo utilizado para identificar se o responsável a ser
        cadastrado é o <b>responsável financeiro</b> dos alunos vinculados.
      </>
    ),
    rules: <>Regras: Seu valor deve ser verdadeiro ou falso.</>,
  },
  {
    field: 'CPF',
    required: 'Não',
    description: (
      <>
        Descrição: Campo utilizado para preencher o CPF do responsável a ser
        cadastrado.
      </>
    ),
  },
];
