import { isLength } from 'validator';

/**
 *
 * @param {object} :min is the minimum string length and :max is the maximum
 * string length.
 * @returns {Function} A function that receives the string to be validated.
 */
export default function validateLength({ min = 0, max = 255 }) {
  return str => {
    return isLength(str, { min, max });
  };
}
