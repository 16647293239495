import { ThemeProps } from 'config/themes/types';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    flex-direction: row;
    margin-top: ${space.lg};
    gap: ${space.lg};
    max-width: 400px;
  `}
`;

export const TicketDetailsWraper = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    flex-direction: column;
    gap: ${space.xs};
  `}
`;

export const AvatarWrapper = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    flex-direction: row;
    gap: ${space.xs};
  `}
`;

export const TicketUserDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LeftSideBar = styled.div`
  ${({
    theme: {
      border,
      colors: { neutral },
    },
  }: ThemeProps) => css`
    background-color: ${neutral.gray4};
    height: auto;
    width: 4px;
    border-radius: ${border.radius.sm};
  `}
`;
