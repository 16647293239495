const orderByFlag = (array, tag) => {
  const sortedFlag = (a, b) => {
      return a[tag] < b[tag] ? - 1 : a[tag] > b[tag] ? 1 : 0;
  }

  const options = array.sort(sortedFlag);

  return options;
}

export default orderByFlag;
