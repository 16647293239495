import React from 'react';
import { DerivativeRecurrentBillsProps } from './types';
import * as S from './styles';
import Accordion from 'components/Accordion';
import withFormContext from 'core/hoc/withFormContext';
import NegotiationCustomTable from '../NegotiationCustomTable';

const DerivativeRecurrentBills: React.FC<DerivativeRecurrentBillsProps> = ({
  formContext: {
    formMeta: { derivativeRecurrentBills },
  },
}) => {
  return (
    <Accordion
      allowZeroExpanded
      header={<S.AccordionTitle> Cobranças de Renegociação </S.AccordionTitle>}
      body={
        <NegotiationCustomTable
          data={derivativeRecurrentBills}
          columns={[
            { key: 'id', header: 'ID Cobrança' },
            { key: 'due_date', header: 'Data venc. Orig' },
            {
              key: 'price_cents',
              header: 'Valor Original',
              format: 'money',
            },
          ]}
        />
      }
    />
  );
};

export default withFormContext(DerivativeRecurrentBills);
