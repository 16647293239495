import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/Button';
import ButtonRow from 'components/ButtonRow';
import ModalTitle from 'components/Modal/ModalTitle';

import withFormContext from 'core/hoc/withFormContext';

import { EditRecurrentPlanWrapperModal } from './style';

const EditRecurrentPlanModal = ({
  showModal,
  disabled,
  toggleModal,
  handleSubmit,
  formContext: { form },
}) => {
  const handleSubmitForm = () => {
    toggleModal();
    handleSubmit(form);
  };

  return (
    <EditRecurrentPlanWrapperModal
      isOpen={showModal}
      toggleModal={toggleModal}
      className="EditRecurrentPlanModal"
      maxWidth="490px"
    >
      <ModalTitle>Atualizar recorrência</ModalTitle>
      <p>
        Atualizando o plano de recorrência, todas as alterações feitas
        anteriormente serão perdidas. Tem certeza que deseja atualizar?
      </p>
      <ButtonRow>
        <Button variation="secondary" onClick={toggleModal}>
          Cancelar
        </Button>
        <Button onClick={handleSubmitForm} disabled={disabled}>
          Confirmar
        </Button>
      </ButtonRow>
    </EditRecurrentPlanWrapperModal>
  );
};

EditRecurrentPlanModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  formContext: PropTypes.shape({
    form: PropTypes.object,
  }).isRequired,
};

export default withFormContext(EditRecurrentPlanModal);
