import React from 'react';
import './style.scss';

export type IconSizes =
  | 'small'
  | 'medium'
  | 'x-medium'
  | 'large'
  | 'x-large'
  | 'x-small'
  | 'xx-small'
  | 'xxx-small';

type AgendaIconProps = {
  className?: string;
  onMouseOver?: (...args: any[]) => any;
  onMouseLeave?: (...args: any[]) => any;
  onClick?: (...args: any[]) => any;
  name:
    | string
    | 'no-other-user'
    | 'message-help'
    | 'no-image'
    | 'progress'
    | 'not-access-yet'
    | 'link-refused'
    | 'wait-link'
    | 'spinner'
    | 'cloud-upload'
    | 'google-drive'
    | 'contraturno'
    | 'briefcase'
    | 'swap-horizontal'
    | 'at'
    | 'alert'
    | 'bank'
    | 'checked'
    | 'user'
    | 'user-group'
    | 'check-square'
    | 'computer'
    | 'description'
    | 'dots-horizontal'
    | 'dots-vertical'
    | 'mail-x'
    | 'mail'
    | 'history'
    | 'info'
    | 'dash'
    | 'menu'
    | 'menu-outline'
    | 'smartphone'
    | 'phone'
    | 'place'
    | 'search'
    | 'lock'
    | 'transactions'
    | 'eye'
    | 'filter'
    | 'image'
    | 'play'
    | 'message-group'
    | 'close'
    | 'send-message'
    | 'check-circle'
    | 'document'
    | 'download-file'
    | 'message-check'
    | 'smile'
    | 'attachment'
    | 'check'
    | 'chat'
    | 'arrow-left'
    | 'arrow-up'
    | 'arrow-right'
    | 'arrow-down'
    | 'arrow-select'
    | 'close'
    | 'headphone'
    | 'plus-circle'
    | 'edit'
    | 'settings'
    | 'block'
    | 'check-small'
    | 'trash'
    | 'camera'
    | 'clock-cycle'
    | 'clock'
    | 'calendar'
    | 'cutlery'
    | 'megaphone'
    | 'health'
    | 'help'
    | 'bookmark'
    | 'message'
    | 'notification'
    | 'organization'
    | 'panel'
    | 'money'
    | 'pencil'
    | 'edugo'
    | 'billet'
    | 'credit-card'
    | 'pix'
    | 'align-right'
    | 'align-left'
    | 'align-center'
    | 'justified'
    | 'editor-color'
    | 'editor-image'
    | 'italic'
    | 'link'
    | 'bold'
    | 'numbered'
    | 'underline'
    | 'topics'
    | 'send'
    | 'print'
    | 'gift'
    | 'edupay'
    | 'eye-blocked'
    | 'income'
    | 'reply'
    | 'calendar-clock'
    | 'forward'
    | 'document-sheet'
    | 'document-text'
    | 'warning'
    | 'megaphone-mute'
    | 'user-heart'
    | 'home';

  size?: IconSizes;
  style?: Record<string, unknown>;
  title?: string;
};

const AgendaIcon = ({
  className,
  name,
  size,
  style,
  onMouseOver,
  onMouseLeave,
  onClick,
  title,
}: AgendaIconProps) => {
  const iconClass = `AgendaIcon icon-ae-${name} ${size} ${className}`;
  return (
    <i
      className={iconClass}
      style={style}
      onMouseOver={onMouseOver}
      onFocus={onMouseOver} // For accessibility purposes.
      onMouseLeave={onMouseLeave}
      onClick={onClick}
      title={title}
      aria-hidden="true"
    />
  );
};

AgendaIcon.defaultProps = {
  className: '',
  onMouseOver: () => {},
  onMouseLeave: () => {},
  onClick: () => {},
  size: 'medium',
  style: undefined,
  title: undefined,
};

export default AgendaIcon;
