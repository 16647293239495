import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { fetchApi } from 'core/utils/api';
import actions from './actions';

function* fetchAvailabilities(action) {
  try {
    const dataArea = yield select((state) => state.root.dataArea);
    const { data, meta } = yield call(
      fetchApi,
      `/${dataArea}/wallets/payment_availabilities.json`,
      { params: action.filter || { typeQuery: 'available' } }
    );

    yield put({ type: actions.FETCH_SUCCESS_AVAILABILATIES, data, meta });
  } catch (error) {
    yield put({ type: actions.FETCH_ERROR, error });
  }
}

function* fetchReceivables(action) {
  try {
    const dataArea = yield select((state) => state.root.dataArea);
    const { data, meta } = yield call(
      fetchApi,
      `/${dataArea}/wallets/payment_availabilities.json`,
      { params: action.filter || { typeQuery: 'receivable' } }
    );

    yield put({ type: actions.FETCH_SUCCESS_RECEIVABLES, data, meta });
  } catch (error) {
    yield put({ type: actions.FETCH_ERROR, error });
  }
}

function* fetchGuaranteedMonthlyFee(action) {
  try {
    const dataArea = yield select((state) => state.root.dataArea);
    const { data } = yield call(
      fetchApi,
      `/${dataArea}/wallets/payment_availabilities.json`,
      { params: action.filter || { typeQuery: 'guaranteed_monthly_fee' } }
    );

    yield put({ type: actions.FETCH_SUCCESS_GUARANTEED_MONTHLY_FEE, data });
  } catch (error) {
    yield put({ type: actions.FETCH_ERROR, error });
  }
}

function* showAvailability(action) {
  try {
    const dataArea = yield select((state) => state.root.dataArea);
    const { data } = yield call(
      fetchApi,
      `/${dataArea}/wallets/payment_availabilities/${action.availabilatyId}.json`
    );

    yield put({
      type: actions.SHOW_SUCCESS_AVAILABILATY,
      date: action.date,
      data,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_ERROR, error });
  }
}

function* fetchPayments(action) {
  try {
    const dataArea = yield select((state) => state.root.dataArea);

    const { data, itemsCountPerPage, totalItemsCount } = yield call(
      fetchApi,
      `/${dataArea}/wallets/payment_availabilities/fetch_payments.json`,
      {
        params: {
          date: action.date.value,
          page: action.page,
          typeQuery: action.typeQuery,
        },
      }
    );

    yield put({
      type: actions.FETCH_SUCCESS_PAYMENTS,
      data: data,
      page: action.page,
      itemsCountPerPage,
      totalItemsCount,
      date: action.date,
      hasMore: action.hasMore,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_ERROR, error });
  }
}

function* availabilitySagas() {
  yield all([
    takeLatest(actions.FETCH_REQUEST_AVAILABILATIES, fetchAvailabilities),
    takeLatest(actions.FETCH_REQUEST_RECEIVABLES, fetchReceivables),
    takeLatest(
      actions.FETCH_REQUEST_GUARANTEED_MONTHLY_FEE,
      fetchGuaranteedMonthlyFee
    ),
    takeLatest(actions.SHOW_REQUEST_AVAILABILATY, showAvailability),
    takeLatest(actions.FETCH_PAYMENTS, fetchPayments),
  ]);
}

export default availabilitySagas;
