import React, { useCallback, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, Button, Select } from '@agendaedu/ae-web-components';

import withAppContext from 'core/hoc/withAppContext';

import actionFilters from 'store/filters/actions';
import actionStorages from 'store/storage/actions';
import { ActiveTermsFilters, FilterStatesProps } from 'store/filters/types';
import { StorageRoot } from 'store/storage/types';

import Accordion from 'components/Accordion';
import { SideSheet } from 'components/Handouts/Filters/SideSheet';

import * as S from './styles';

export const SideFilterModal = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['storage']);

  const tBase = useCallback(
    (key: string, params?: Record<string, string | number>) =>
      t(`side_filter_modal.${key}`, params),
    [t]
  );

  const [showSideFilter, setShowSideFilter] = useState(false);
  const [selectedHeadquarter, setSelectedHeadquarter] = useState(null);
  const [selectedEducationalStage, setSelectedEducationalStage] =
    useState(null);
  const [selectedClassroom, setSelectedClassroom] = useState(null);

  const FADE_OUT_DURATION = 250;

  const { headquarters, educationalStages, classrooms, activeSchoolTerms } =
    useSelector((state: FilterStatesProps) => state.filters);

  const {
    storageList: { filters: storageFilters },
  } = useSelector((state: StorageRoot) => state.storage);

  const [selectedActiveSchoolTerm, setSelectedActiveSchoolTerm] = useState(
    storageFilters.activeSchoolTermId
  );

  const { fetchEducationalStagesRequest, fetchClassRoomsRequest } =
    actionFilters;

  const { setChangeFilters } = actionStorages;

  const hasHeadquarters = () => headquarters && headquarters?.length > 0;
  const hasEducationalStages = () =>
    hasHeadquarters() && selectedHeadquarter && educationalStages?.length > 0;
  const hasClassrooms = () =>
    hasEducationalStages() &&
    selectedEducationalStage &&
    classrooms?.length > 0;

  const handleToggleSideFilter = useCallback(() => {
    setTimeout(() => {
      setShowSideFilter((prevShowFilter) => !prevShowFilter);
    }, FADE_OUT_DURATION);
  }, [setShowSideFilter]);

  const handleFetchEducationalStages = useCallback(
    (headquarterId) => {
      dispatch(fetchEducationalStagesRequest(headquarterId));
    },
    [dispatch, fetchEducationalStagesRequest]
  );

  const handleFetchClassroomFilter = useCallback(
    (headquarterId, educationalStageId) => {
      dispatch(fetchClassRoomsRequest(headquarterId, educationalStageId));
    },
    [dispatch, fetchClassRoomsRequest]
  );

  const handleResetInitialFilters = useCallback(() => {
    dispatch(
      setChangeFilters({
        ...storageFilters,
        headquarterId: null,
        educationalStageId: null,
        classroomId: null,
        activeSchoolTermId: null,
      })
    );
  }, [dispatch, setChangeFilters, storageFilters]);

  const handleSubmitFilters = useCallback(() => {
    dispatch(
      setChangeFilters({
        ...storageFilters,
        headquarterId: selectedHeadquarter,
        educationalStageId: selectedEducationalStage,
        classroomId: selectedClassroom,
        activeSchoolTermId: selectedActiveSchoolTerm,
      })
    );
  }, [
    dispatch,
    selectedActiveSchoolTerm,
    selectedClassroom,
    selectedEducationalStage,
    selectedHeadquarter,
    setChangeFilters,
    storageFilters,
  ]);

  const handleClearFilters = useCallback(() => {
    setSelectedHeadquarter(null);
    setSelectedEducationalStage(null);
    setSelectedClassroom(null);
    setSelectedActiveSchoolTerm(null);
    handleResetInitialFilters();
  }, [handleResetInitialFilters]);

  const activeFiltersCount = useMemo(() => {
    let amountFilter = 0;

    const sideFilters = [
      storageFilters.headquarterId,
      storageFilters.educationalStageId,
      storageFilters.classroomId,
      storageFilters.activeSchoolTermId,
    ];

    sideFilters.forEach((currentFilter) => {
      if (currentFilter) amountFilter += 1;
    });

    return amountFilter;
  }, [storageFilters]);

  return (
    <>
      <Button
        icon="filter"
        variant="secondary"
        isOnlyIcon
        badges={activeFiltersCount}
        onClick={handleToggleSideFilter}
        data-testid="filter-button"
      />
      <Box data-testid="side-modal">
        <SideSheet
          isOpen={showSideFilter}
          title={tBase('title')}
          handleDismiss={handleToggleSideFilter}
          handleClear={handleClearFilters}
          handleSubmit={handleSubmitFilters}
        >
          <S.SectionWrapper>
            <Accordion
              header={
                <S.TitleHeader>{tBase('side_unity_title')}</S.TitleHeader>
              }
              body={
                <S.AccordionWrapper>
                  <Select
                    fullWidth
                    label={tBase('unity.label_text')}
                    placeholder={tBase('unity.placeholder_text')}
                    value={selectedHeadquarter}
                    options={headquarters}
                    onChange={(event) => {
                      setSelectedHeadquarter(event.value);
                      setSelectedEducationalStage(null);
                      setSelectedClassroom(null);
                      handleFetchEducationalStages(event.value);
                    }}
                  />
                  <Select
                    fullWidth
                    label={tBase('segment.label_text')}
                    placeholder={tBase('segment.placeholder_text')}
                    value={selectedEducationalStage}
                    options={educationalStages}
                    disabled={!hasEducationalStages()}
                    onChange={(event) => {
                      setSelectedEducationalStage(event.value);
                      setSelectedClassroom(null);
                      handleFetchClassroomFilter(
                        selectedHeadquarter,
                        event.value
                      );
                    }}
                  />
                  <Select
                    fullWidth
                    label={tBase('classes.label_text')}
                    placeholder={tBase('classes.placeholder_text')}
                    value={selectedClassroom}
                    options={classrooms}
                    disabled={!hasClassrooms()}
                    onChange={(event) => setSelectedClassroom(event.value)}
                  />
                </S.AccordionWrapper>
              }
              expanded="storage"
              allowZeroExpanded
            />
            <Accordion
              header={
                <S.TitleHeader>{tBase('side_terms_title')}</S.TitleHeader>
              }
              body={
                <Select
                  fullWidth
                  label={tBase('school_terms.label_text')}
                  placeholder={tBase('school_terms.placeholder_text')}
                  value={selectedActiveSchoolTerm}
                  options={activeSchoolTerms}
                  onChange={(event) => setSelectedActiveSchoolTerm(event.value)}
                />
              }
              expanded="storage"
              allowZeroExpanded
            />
          </S.SectionWrapper>
        </SideSheet>
      </Box>
    </>
  );
};

withAppContext(SideFilterModal);
