import React, { useEffect } from 'react';

import tabifyForWizard from 'core/hoc/tabifyForWizard';
import FormFieldset from 'components/Form/Fieldset';
import Field from 'components/Form/Field';
import withFormContext, { formPropTypes } from 'core/hoc/withFormContext';

import { useTranslation } from 'react-i18next';

import './style.scss';
import validations from './validations';

const FormInformationsTab = ({
  formContext: { form, formMeta, changeMeta, updateAttribute },
}) => {
  const { t } = useTranslation(['quickaccess']);

  useEffect(() => {
    changeMeta('classroom_with_student_profile_ids_ClassroomStudents', []);
  }, [form.persona_type]);

  return (
    <div className="FormInformationsTab">
      <FormFieldset>
        <div className="row">
          <Field
            type="image"
            label={t('informations_tab.labels.image_access')}
            attributeName="image"
            customPreviewSize={180}
            customPreviewMessage={t('informations_tab.image_access_guidance', {
              size: '10MB',
            })}
          />
        </div>

        <div className="row">
          <Field
            label={t('informations_tab.labels.name_of_access')}
            placeholder=""
            attributeName="name"
          />
        </div>

        <div className="row">
          <Field
            label={t('informations_tab.labels.link_of_access')}
            placeholder=""
            attributeName="link"
          />
        </div>

        <span className="mandatory-fields">
          {t('informations_tab.mandatory_fields')}
        </span>
      </FormFieldset>
    </div>
  );
};

FormInformationsTab.propTypes = {
  ...formPropTypes,
};

export default tabifyForWizard({
  title: '1. Informações',
  subTitle: 'Informações gerais',
  validations,
})(withFormContext(FormInformationsTab));
