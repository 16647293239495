import React, { Component } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Alert from 'components/Alert';
import ColoredText from 'components/ColoredText';
import Currency from 'components/Currency';
import Field from 'components/Form/Field';
import FormContext from 'core/contexts/Form';
import tabifyForWizard from 'core/hoc/tabifyForWizard';
import TaxTransfer from 'components/TaxTransfer';
import Title from 'components/WizardForm/Title';
import validations from './validations';
import valueWithTax from '../valueWithTax';

import * as S from './styles';

const TransferFormTransferInformations = () => {
  const policies = useSelector((state) => state.root.policies);
  return (
    <FormContext.Consumer>
      {({ form, formMeta }) => (
        <S.Container>
          <S.TitleContainer>
            <Title name="Transferir saldo disponível" />
            <Alert variation="info">
              Transferências solicitadas até as 15h do horário de Brasília são
              encaminhadas no mesmo dia. Após esse horário, serão encaminhadas
              apenas no próximo dia útil.
            </Alert>
            <S.Label>Preencha as informações da transferência</S.Label>
          </S.TitleContainer>

          <S.CurrencyInfo>
            <S.Label>Valor disponível</S.Label>
            <S.Label>
              <Currency value={formMeta.availableAmount} />
            </S.Label>
          </S.CurrencyInfo>

          {policies.edupay_provider === 'zoop' && (
            <S.InforContainer>
              <Alert variation="info">
                Este é o valor disponível para saques manuais.Os outros valores
                relacionados ao seu saldo serão transferidos automaticamente.
              </Alert>
            </S.InforContainer>
          )}

          <S.TransferContainer>
            <Field
              label="Valor da transferência*"
              type="currency"
              attributeName="amount"
              size="small"
            />
            <Field
              label="Descrição*"
              attributeName="description"
              type="simpleTextArea"
              rows={3}
            />
          </S.TransferContainer>

          <S.Text>*Campos obrigatórios</S.Text>

          <S.Line />

          <S.TaxTransferContainer>
            <TaxTransfer
              taxTransfer={formMeta.taxTransfer}
              bankCode={formMeta.bankCode}
            />
            <S.AmountReceivable>
              <ColoredText variation="bold">Valor a receber</ColoredText>

              <ColoredText variation="success bold">
                <Currency
                  value={valueWithTax(
                    form.amount,
                    formMeta.bankCode,
                    formMeta.taxTransfer
                  )}
                />
              </ColoredText>
            </S.AmountReceivable>
          </S.TaxTransferContainer>
        </S.Container>
      )}
    </FormContext.Consumer>
  );
};

TransferFormTransferInformations.propTypes = {
  availableAmount: PropTypes.number,
  bankCode: PropTypes.string,
};

export default tabifyForWizard({
  title: '1. Informações',
  validations,
  onChangeValidationsAttributeNames: ['amount'],
})(TransferFormTransferInformations);
