export const s3UrlToFile = async (url) => {
  try {
    const response = await fetch(url);
    
    if (!response.ok) {
      throw new Error('Falha ao carregar o arquivo');
    }

    const blob = await response.blob();
    const mimeType = response.headers.get('Content-Type') || 'application/octet-stream';
    const fileName = url.split('/').pop().split('?')[0];  
    const file = new File([blob], fileName, { type: mimeType });

    return file;
  } catch (error) {
    reject(error);
  }
};