import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { buildDiscount, formattedDiscounts } from 'core/utils/edupay/functions';
import scrollTop from 'core/utils/scrollTop';
import withAppContext from 'core/hoc/withAppContext';
import {
  INSTALLMENTS_SCHOOLPRODUCT,
  DISCOUNT_KINDS,
} from 'core/constants/index';
import { ALLOWED_PAYMENT_METHOD } from 'core/constants';
import { useTranslation } from 'react-i18next';

import Loader from 'components/Loader';
import SchoolProductForm from 'components/Payments/PaymentsContainer/SchoolProductForm';
import Toast from 'components/Toast';

import { SchoolProductsState } from 'store/edupay/schoolProducts/types';

import actionSchoolProducts from 'store/edupay/schoolProducts/actions';

const NewSchoolProduct = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation('payments');

  const [onSubmitErrorFunctions, setOnSubmitErrorFunctions] = useState({
    setSubmitting: null,
    updateFormContext: null,
    addErrorsToFormValidationResultFromServer: null,
    goToFirstStep: null,
  });

  const { invoice_details: invoiceDetails, edupay_provider } = useSelector(
    (state) => state.root.policies
  );

  const { newSchoolProduct, isSubmitting, isLoading, error, classrooms } =
    useSelector((state: SchoolProductsState) => state.schoolProducts);

  const { fetchNewSchoolProductFormMeta, setNewSchoolProduct } =
    actionSchoolProducts;

  useEffect(() => {
    dispatch(fetchNewSchoolProductFormMeta());
  }, []);

  useEffect(() => {
    if (onSubmitErrorFunctions?.setSubmitting && error?.status === 422) {
      onSubmitErrorFunctions?.setSubmitting(false);
      onSubmitErrorFunctions?.updateFormContext(() => ({
        hasBackendValidationError: true,
      }));
      onSubmitErrorFunctions?.addErrorsToFormValidationResultFromServer(error);
      onSubmitErrorFunctions?.goToFirstStep();
      scrollTop();
    }
  }, [error]);

  const onSubmit = (formContext, { goToFirstStep, setSubmitting }) => {
    const {
      form,
      addErrorsToFormValidationResultFromServer,
      updateFormContext,
    } = formContext;

    setSubmitting(true);

    setOnSubmitErrorFunctions({
      setSubmitting: setSubmitting,
      updateFormContext: updateFormContext,
      addErrorsToFormValidationResultFromServer:
        addErrorsToFormValidationResultFromServer,
      goToFirstStep: goToFirstStep,
    });

    let params = form;

    if (
      form.toggle_discount &&
      (form.allowed_payment_method.includes('billet') ||
        form.allowed_payment_method.includes('pix'))
    ) {
      params = {
        ...form,
        edupay_discounts: formattedDiscounts(form),
      };
    } else {
      delete params.edupay_discounts;
    }

    params = {
      ...params,
      settings: { absorb_tax: form?.absorb_tax },
    };

    dispatch(setNewSchoolProduct(params));
  };

  const formatAttributes = () => ({
    legacy_id: '',
    classroom_with_student_profile_ids: {},
    classroom_ids: [],
    allowed_payment_method: [
      ...ALLOWED_PAYMENT_METHOD,
      ...(invoiceDetails?.credit_card_enabled ? ['credit_card'] : []),
    ],
    sent_kind: 'student',
    price: 0,
    absorb_credit_card_tax: true,
    absorb_billet_tax: true,
    absorb_pix_tax: true,
    absorb_installment_tax: true,
    max_installments: edupay_provider === 'bemobi' ? 12 : 1,
    toggle_discount: false,
    edupay_discounts: [buildDiscount()],
    recipient_wallet_id: null,
    allow_credit_card_discount: true,
    absorb_tax: false,
  });

  const formatMetaAttributes = () => ({
    classrooms,
    ...newSchoolProduct?.meta,
    select_options: {
      ...newSchoolProduct?.meta?.select_options,
      max_installments: INSTALLMENTS_SCHOOLPRODUCT,
      kind: DISCOUNT_KINDS,
    },
    status: 'pending',
  });

  if (!newSchoolProduct || !classrooms || isLoading)
    return <Loader data-testid="load" />;

  return (
    <div className="NewSchoolProduct">
      <SchoolProductForm
        action="new"
        form={formatAttributes()}
        formMeta={formatMetaAttributes()}
        onSubmit={onSubmit}
        isSubmitting={isSubmitting}
        backTo="/schools/school_products"
        titlePage={t('school_product_form.title_new_school_product')}
        titleModal={t('school_product_form.title_reject_modal')}
        contentModal={t('school_product_form.content_reject_modal')}
        error={
          onSubmitErrorFunctions?.setSubmitting && error?.status !== 422
            ? error
            : null
        }
      />
      <Toast />
    </div>
  );
};

export default withAppContext(NewSchoolProduct);
