import React from 'react';
import PropTypes from 'prop-types';
import { Line } from 'rc-progress';
import AgendaIcon from 'components/AgendaIcon';
import truncateName from 'core/utils/truncateName';
import { normalizeFileSize } from 'core/utils/formatBytes';
import AttachmentEntity from 'core/entities/Attachment';

import { useDownloadFile } from 'core/hooks/useDownloadFile';

import * as S from './style';

const FilePreview = ({
  fileIndex,
  file,
  useDirectUpload,
  deleteFile,
  disabledDeleteButton,
  cancelVideoUpload,
  progress,
  onlyDownloadAttachment,
  customIcon,
  onUploadFile,
}) => {
  const attachment = new AttachmentEntity(file);
  const isPaperclipAttachment = file.attachmentType === 'paperclip';

  const renderVideoPreview = (file) => {
    if (file.loading && progress < 100) {
      return (
        <S.ProgressContainer>
          <Line
            percent={progress}
            strokeColor="#8345ED"
            strokeLinecap="round"
          />
        </S.ProgressContainer>
      );
    } else if (!file.loading && file.url) {
      return <S.VideoPreview src={file.url} frameBorder="0" controls />;
    } else {
      return (
        <S.ProgressContainer>
          <AgendaIcon name="spinner" size="large" />
          {!file.isDeleting && (
            <p>
              Seu arquivo está sendo processado. Em breve você verá seu vídeo
              aqui &#128522;
            </p>
          )}
        </S.ProgressContainer>
      );
    }
  };

  const { ref, url, name, requestDownloadFile, isLoadingFile } =
    useDownloadFile({
      getFileURL: attachment.getUrl(),
      getFileName: attachment.getName(),
    });

  const renderIcon = () => {
    return customIcon ? (
      <S.TypeIcon name="attachment" />
    ) : (
      <S.TypeIcon name="income" />
    );
  };

  return (
    <>
      {onlyDownloadAttachment ? (
        <>
          {isPaperclipAttachment ? (
            <S.OpenLinkAttachemnt
              ref={ref}
              href={file.url}
              target="_blank"
              rel="noreferrer"
            >
              <S.InfoContainer>
                {!isLoadingFile ? (
                  <>{renderIcon()}</>
                ) : (
                  <S.SpinnerIcon className="fa fa-spinner fa-spin" />
                )}
                <S.FileName>{truncateName(file.name, 30)}</S.FileName>
                <span>{normalizeFileSize(file.size)}</span>
              </S.InfoContainer>
            </S.OpenLinkAttachemnt>
          ) : (
            <>
              <S.Anchor href={url} ref={ref} download={name} />

              <S.FileDownload
                onClick={requestDownloadFile}
                isLoadingFile={isLoadingFile}
              >
                <S.InfoContainer>
                  {!isLoadingFile ? (
                    <>{renderIcon()}</>
                  ) : (
                    <S.SpinnerIcon className="fa fa-spinner fa-spin" />
                  )}
                  <S.FileName>{truncateName(file.name, 30)}</S.FileName>
                  <span>{normalizeFileSize(file.size)}</span>
                </S.InfoContainer>
              </S.FileDownload>
            </>
          )}
        </>
      ) : (
        <S.File destroyed={file?.destroy}>
          {file &&
            file?.type &&
            file.type.includes('video') &&
            renderVideoPreview(file)}
          <S.InfoContainer>
            <S.TypeIcon name="attachment" />
            <S.FileName>{truncateName(file.name, 30)}</S.FileName>
            <span>{normalizeFileSize(file?.size)}</span>

            {(useDirectUpload || onUploadFile) &&
            file.loading &&
            !file.error ? (
              file?.type && file.type.includes('video') ? (
                <S.RemoveButton
                  type="button"
                  onClick={() => cancelVideoUpload(fileIndex)}
                  aria-label="cancel"
                >
                  <AgendaIcon name="close" />
                </S.RemoveButton>
              ) : (
                <S.SpinnerIcon className="fa fa-spinner fa-spin" />
              )
            ) : (
              <>
                {!disabledDeleteButton && (
                  <S.RemoveButton
                    type="button"
                    onClick={() => deleteFile(fileIndex)}
                    aria-label="delete"
                  >
                    <AgendaIcon name="trash" />
                  </S.RemoveButton>
                )}
              </>
            )}
          </S.InfoContainer>
        </S.File>
      )}
    </>
  );
};

FilePreview.propTypes = {
  fileIndex: PropTypes.number,
  file: PropTypes.object,
  deleteFile: PropTypes.func,
  disabledDeleteButton: PropTypes.bool,
  cancelVideoUpload: PropTypes.func,
  useDirectUpload: PropTypes.bool,
  progress: PropTypes.number,
  onlyDownloadAttachment: PropTypes.bool,
  customIcon: PropTypes.bool,
  onUploadFile: PropTypes.bool,
};

export default FilePreview;
