import React from 'react';
import { DefaultThemeProps, theme } from '@agendaedu/ae-web-components';

import Skeleton from 'components/Skeleton';

import * as S from './styles';

const StudentsListSkeleton = () => {
  const defaultTheme: DefaultThemeProps = theme;

  const {
    colors: { neutral },
    border: { radius },
  } = defaultTheme;

  return (
    <S.MainContainer data-testid="students-moods-list-skeleton">
      {Array.from(Array(5).keys()).map((n) => (
        <Skeleton
          key={`skeleton-item-${n}`}
          width={100}
          height={88}
          primaryColor={neutral.gray6}
          borderRadius={radius.md}
          isFullWidth
        />
      ))}
    </S.MainContainer>
  );
};

export default StudentsListSkeleton;
