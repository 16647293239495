import validatePresence from 'core/lib/FormValidator/validators/presence';

export default function validateTextAreaPresence(attribute) {
  const container = document.createElement('div');

  container.innerHTML = attribute;

  if(container.getElementsByTagName('img').length > 0) return true;

  return validatePresence(container.textContent);
}
