import React from 'react';
import {
  Grid,
  DefaultThemeProps,
  theme,
  Box,
} from '@agendaedu/ae-web-components';

import Skeleton from 'components/Skeleton';

import * as S from './styles';

export const DetailsSkeleton = () => {
  const defaultTheme: DefaultThemeProps = theme;

  const {
    colors: { neutral },
    border: { radius },
  } = defaultTheme;

  return (
    <S.HandoutDetailsSkeleton>
      <Grid
        gridTemplateColumns="1fr"
        gutter={{
          _: 'lg',
        }}
      >
        <Grid item gutter="sm">
          <Skeleton
            width={150}
            height={20}
            primaryColor={neutral.gray3}
            borderRadius={radius.md}
          />

          <Skeleton
            maxWidth={400}
            width="100%"
            height={24}
            primaryColor={neutral.gray3}
            borderRadius={radius.md}
            isFullWidth
          />
        </Grid>

        <Grid item>
          <S.TabsSkeleton>
            <S.TabsHeaderSkeleton>
              <S.TabSkeleton isSelected>
                <Skeleton
                  width={85}
                  height={20}
                  primaryColor={neutral.gray4}
                  borderRadius={radius.md}
                />
              </S.TabSkeleton>

              <S.TabSkeleton>
                <Skeleton
                  width={85}
                  height={20}
                  primaryColor={neutral.gray4}
                  borderRadius={radius.md}
                />
              </S.TabSkeleton>
            </S.TabsHeaderSkeleton>

            <Box py={{ _: 'xl2' }} px={{ _: 'sm', desktopLG: 'xl2' }}>
              <Grid item>
                <Box display="flex" alignItems="center">
                  <Skeleton
                    width={90}
                    height={32}
                    primaryColor={neutral.gray4}
                    borderRadius={radius.md}
                  />

                  <Box ml="md">
                    <Skeleton
                      width={140}
                      height={32}
                      primaryColor={neutral.gray4}
                      borderRadius={radius.md}
                    />
                  </Box>
                </Box>

                <Grid item>
                  <Skeleton
                    maxWidth={400}
                    width="100%"
                    height={32}
                    primaryColor={neutral.gray5}
                    borderRadius={radius.md}
                    isFullWidth
                  />
                </Grid>

                <Grid item>
                  <Skeleton
                    width={200}
                    height={48}
                    primaryColor={neutral.gray5}
                    borderRadius={radius.md}
                  />
                </Grid>

                <Grid item gutter="sm">
                  <Skeleton
                    width={100}
                    height={16}
                    primaryColor={neutral.gray4}
                    borderRadius={radius.md}
                  />

                  <Skeleton
                    maxWidth={617}
                    width="100%"
                    height={350}
                    primaryColor={neutral.gray5}
                    borderRadius={radius.md}
                    isFullWidth
                  />
                </Grid>

                <Grid item gutter="sm">
                  <Skeleton
                    width={100}
                    height={16}
                    primaryColor={neutral.gray4}
                    borderRadius={radius.md}
                  />

                  <Skeleton
                    width="100%"
                    height={124}
                    primaryColor={neutral.gray5}
                    borderRadius={radius.md}
                    isFullWidth
                  />
                </Grid>

                <Grid item gutter="sm">
                  <Skeleton
                    width={100}
                    height={16}
                    primaryColor={neutral.gray4}
                    borderRadius={radius.md}
                  />

                  <Skeleton
                    maxWidth={617}
                    width="100%"
                    height={40}
                    primaryColor={neutral.gray5}
                    borderRadius={radius.md}
                    isFullWidth
                  />
                </Grid>

                <Grid item>
                  <Box my="md">
                    <Skeleton
                      width="100%"
                      height={65}
                      primaryColor={neutral.gray5}
                      borderRadius={radius.md}
                      isFullWidth
                    />
                  </Box>
                </Grid>

                <Box
                  display={{
                    _: 'none',
                    desktopLG: 'grid',
                  }}
                >
                  <Grid
                    item
                    gridTemplateAreas='"approve schedule update delete"'
                    gridTemplateColumns="96px 96px 40px auto"
                    gutter="md"
                  >
                    <Grid item gridArea="approve">
                      <Skeleton
                        width={96}
                        height={40}
                        primaryColor={neutral.gray4}
                        borderRadius={radius.md}
                      />
                    </Grid>

                    <Grid item gridArea="schedule">
                      <Skeleton
                        width={96}
                        height={40}
                        primaryColor={neutral.gray4}
                        borderRadius={radius.md}
                      />
                    </Grid>

                    <Grid item gridArea="update">
                      <Skeleton
                        width={40}
                        height={40}
                        primaryColor={neutral.gray4}
                        borderRadius={radius.md}
                      />
                    </Grid>

                    <Grid item gridArea="delete">
                      <Box display="flex" justifyContent="flex-end">
                        <Skeleton
                          width={40}
                          height={40}
                          primaryColor={neutral.gray4}
                          borderRadius={radius.md}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Box>
          </S.TabsSkeleton>
        </Grid>
      </Grid>
    </S.HandoutDetailsSkeleton>
  );
};
