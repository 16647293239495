import React from 'react';
import { Box, DefaultThemeProps, theme } from '@agendaedu/ae-web-components';

import Skeleton from 'components/Skeleton';

export const RecipientsSkeleton = () => {
  const defaultTheme: DefaultThemeProps = theme;

  const {
    colors: { neutral },
    border: { radius },
    breakpoints,
  } = defaultTheme;

  return (
    <Box
      key="skeleton-recipients"
      width="100%"
      maxWidth={breakpoints.tabletMD}
      mt="xl"
      mx="auto"
    >
      <Box position="relative">
        <Skeleton
          width="100%"
          height="400px"
          primaryColor={neutral.gray6}
          borderRadius={radius.md}
          isFullWidth
        />
      </Box>
    </Box>
  );
};
