import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { flags } from 'core/constants/flags';
import tabify from 'core/hoc/tabify';

import Alert from 'components/Alert';
import Loader from 'components/Loader';
import ColoredText from 'components/ColoredText';
import LabeledContainer from 'components/LabeledContainer';
import UserDisplay from 'components/UserDisplay';
import DropdownButton from 'components/DropdownButton';
import Table from 'components/Table';
import Toast from 'components/Toast';
import HandleModalByMethod from 'components/Payments/HandleModalByMethod';
import LinkModal from 'components/Payments/LinkModal';
import DelayModal from 'components/Payments/DelayModal';
import ShowBillModal from '../../ShowBillModal';
import ShowBillModalNegotiation from '../../ShowBillModalNegotiation';
import AlertModal from 'components/AlertModal';
import ManualDealModal from 'components/Payments/ManualDealModal';
import Button from 'components/Button';
import ModalContainer from 'components/Modal/ModalContainer';
import ModalButtons from 'components/Modal/ModalButtons';

import actionRecurrentBills from 'store/edupay/recurrentBills/actions';
import negotiationsActions from 'store/edupay/negotiations/actions';
import table from './table';
import './style.scss';

const BillsTab = ({
  match: {
    params: { id },
  },
}) => {
  const dispatch = useDispatch();
  const [paymentMethod, setPaymentMethod] = useState();
  const [redirectToNewNegotiation, setRedirectToNewNegotiation] =
    useState(false);

  const [showBillModalNegotiationOpen, setShowBillModalNegotiationOpen] =
    useState(false);

  const [showCancelEditModal, setShowCancelEditModal] = useState(false);

  const {
    policies: {
      non_payment_active_flags: nonPaymentActiveFlags,
      can_use_negotiation: canUseNegotiation,
      recurrent_bill_legacy_id: recurrentBillLegacyId,
    },
  } = useSelector((state) => state.root);

  const isActivityNegotiation = nonPaymentActiveFlags.includes(
    flags.NEGOTIATION_IS_ACTIVE
  );

  const currentPlan = useSelector((state) => state.recurrentPlans.current);

  const order = useSelector(
    (state) => (state.recurrentBills.current || {}).order
  );
  const studentProfile = useSelector(
    (state) => (state.recurrentBills.current || {}).studentProfile
  );
  const responsibleProfiles = useSelector(
    (state) => (state.recurrentBills.current || {}).responsibleProfiles
  );

  const hasBatchSelection = useSelector(
    (state) => state.negotiations.hasBatchSelection
  );

  const [currentEditlegacyList, setCurrentEditlegacyList] = useState([]);

  const {
    manualDealModal,
    errorMessage,
    visualizations,
    recurrentBills: bills,
    current,
    refundModal,
    revertManualPaymentModal,
    delayModal,
    paymentLinkModalByMethod,
    paymentModalByMethod,
    modalOpen,
    isLoading,
    isLoadingManuallyPaid,
    isLoadingRefund,
    isLoadingRevertManualPayment,
    isTableEdit,
  } = useSelector((state) => state.recurrentBills);

  const canNegotiateBills =
    currentPlan.studentProfile.attributes.has_financial_responsible;

  const {
    fetchRecurrentBills,
    setRecurrentBill,
    loadInformationToPaid,
    toggleModal,
    toggleBilletOrPixModal,
    toggleTableEdit,
    toggleBilletOrPixLinkModal,
    toggleDeplay,
    toggleRefund,
    toggleRevertManualPayment,
    unsetBill,
    updateRecurrentBillLegacyId,
    checkout,
    delayBillResquest,
    releaseRecurrentBill,
    refundBillResquest,
    revertManualPaymentRequest,
    toggleManualDealModal,
  } = actionRecurrentBills;

  const { setCurrentPlanId, clearSelectedBills } = negotiationsActions;

  const getBills = useCallback(() => {
    dispatch(fetchRecurrentBills(id));
  }, [dispatch, fetchRecurrentBills]);

  useEffect(() => {
    getBills();
  }, []);

  useEffect(() => {
    dispatch(clearSelectedBills());
  }, [dispatch, clearSelectedBills]);

  const loadInformation = useCallback(
    (planId, biilId) => {
      dispatch(loadInformationToPaid(planId, biilId));
    },
    [loadInformationToPaid]
  );

  const toggleShowManualDealModal = useCallback(() => {
    dispatch(toggleManualDealModal());
  }, [dispatch, toggleManualDealModal]);

  const toggleShowModal = useCallback(() => {
    dispatch(toggleModal());
    setShowBillModalNegotiationOpen(false);
  }, [toggleModal]);

  const toggleShowModalNegotiation = useCallback(() => {
    dispatch(toggleModal());
    setShowBillModalNegotiationOpen(true);
  }, [toggleModal]);

  const togglePaymentMethod = useCallback(() => {
    dispatch(toggleBilletOrPixModal());
    dispatch(unsetBill());
  }, [toggleBilletOrPixModal, unsetBill]);

  const togglePaymentMethodLink = useCallback(() => {
    dispatch(toggleBilletOrPixLinkModal());
    dispatch(unsetBill());
  }, [toggleBilletOrPixLinkModal, unsetBill]);

  const toggleDelayModal = useCallback(() => {
    dispatch(toggleDeplay());
    dispatch(unsetBill());
  }, [toggleDeplay, unsetBill]);

  const toggleRefundModal = useCallback(() => {
    dispatch(toggleRefund());
    dispatch(unsetBill());
  }, [toggleRefund, unsetBill]);

  const toggleRevertManualPaymentModal = useCallback(() => {
    dispatch(toggleRevertManualPayment());
    dispatch(unsetBill());
  }, [toggleRevertManualPayment, unsetBill]);

  const toggleCancelEditModal = useCallback(() => {
    setShowCancelEditModal(
      (prevShowCancelEditModal) => !prevShowCancelEditModal
    );
  }, []);

  const toDiscardCancelEditModal = () => {
    dispatch(toggleTableEdit());
    toggleCancelEditModal();
  };

  const confirmCancelEditModalButtons = () => [
    {
      text: 'Cancelar',
      variation: 'secondary',
      onClick: toggleCancelEditModal,
      path: '#',
    },
    {
      text: 'Descartar',
      variation: 'danger',
      onClick: toDiscardCancelEditModal,
      path: '#',
    },
  ];

  const setBill = useCallback(
    (billId) => {
      dispatch(setRecurrentBill(billId));
    },
    [setRecurrentBill]
  );

  const delayBill = () => {
    dispatch(delayBillResquest(current.recurrentPlan.id, current.id, 'bill'));
    toggleDelayModal();
  };

  const releaseBill = useCallback(
    (planId, billId) => {
      dispatch(releaseRecurrentBill(planId, billId));
    },
    [releaseRecurrentBill]
  );

  const refundBill = () => {
    dispatch(refundBillResquest(current.recurrentPlan.id, current.id));
  };

  const revertManualPayment = () => {
    dispatch(revertManualPaymentRequest(current.recurrentPlan.id, current.id));
  };

  const onCheckout = (form) => {
    const {
      id: billId,
      attributes: { order_id: orderId },
    } = current;

    dispatch(checkout(billId, orderId, 'bill', form, paymentMethod));
  };

  const handleUpdateLegacyList = (params) => {
    const currentList = [...currentEditlegacyList];
    const orderId = params.id;

    const currentLegacy = currentList?.some((legacy) => legacy.id === orderId);

    if (!currentLegacy) {
      setCurrentEditlegacyList([...currentList, params]);
    } else {
      const currentLegacyIndex = currentList?.findIndex(
        (legacy) => legacy.id === orderId
      );

      currentList[currentLegacyIndex] = {
        ...currentLegacyIndex,
        ...params,
      };

      setCurrentEditlegacyList(currentList);
    }
  };

  const getBillsTable = () => {
    const billsTable =
      canUseNegotiation && isActivityNegotiation
        ? table
        : table.slice(1, table.length);

    return recurrentBillLegacyId
      ? billsTable
      : billsTable.filter((obj) => obj.Header !== 'Identificador');
  };

  const addActionsToBills = () =>
    bills.map((bill) => {
      bill.actions = {};

      bill.actions.show = () => {
        if (bill.attributes?.negotiation) {
          toggleShowModalNegotiation();
        } else {
          toggleShowModal();
        }
        setBill(bill.id);
      };

      bill.actions.billetModal = () => {
        setPaymentMethod('billet');
        togglePaymentMethod();
        setBill(bill.id);
        loadInformation(id, bill.id);
      };
      bill.actions.pixModal = () => {
        setPaymentMethod('pix');
        togglePaymentMethod();
        setBill(bill.id);
        loadInformation(id, bill.id);
      };
      bill.actions.manualDealModal = () => {
        toggleShowManualDealModal();
        setBill(bill.id);
      };
      bill.actions.billetLinkModal = () => {
        setPaymentMethod('billet');
        togglePaymentMethodLink();
        setBill(bill.id);
        loadInformation(id, bill.id);
      };
      bill.actions.pixLinkModal = () => {
        setPaymentMethod('pix');
        togglePaymentMethodLink();
        setBill(bill.id);
        loadInformation(id, bill.id);
      };
      bill.actions.delay = () => {
        toggleDelayModal();
        setBill(bill.id);
        loadInformation(id, bill.id);
      };
      bill.actions.refundModal = () => {
        toggleRefundModal();
        setBill(bill.id);
      };
      bill.actions.revertManualPaymentModal = () => {
        toggleRevertManualPaymentModal();
        setBill(bill.id);
      };

      bill.actions.release = () => releaseBill(id, bill.id);

      bill.visualizations = visualizations.filter(
        (view) => view.id === bill.attributes.order_id
      );
      bill.planId = id;

      return { bill, currentEditlegacyList, handleUpdateLegacyList };
    });

  const subTitleToDelayModal = () => (
    <React.Fragment>
      {current && (
        <p>
          A cobrança que expira no dia{' '}
          <ColoredText variation="bold">
            {current.attributes.due_date}
          </ColoredText>{' '}
          será
          <br />
          marcada como atrasada
        </p>
      )}
    </React.Fragment>
  );

  const handleNegociarClick = () => {
    dispatch(setCurrentPlanId(id));
    setRedirectToNewNegotiation(true);
  };

  const handleTableEdit = () => {
    dispatch(toggleTableEdit());
    setCurrentEditlegacyList([]);

    if (isTableEdit)
      dispatch(updateRecurrentBillLegacyId({ currentEditlegacyList, id }));
  };

  if (redirectToNewNegotiation) {
    return <Redirect to="/schools/negotiations/new" />;
  }

  return (
    <div className="RecurrentPlanBillsTab">
      {isLoading ? (
        <Loader />
      ) : (
        <React.Fragment>
          <div className="bill-header">
            <div className="bill-info">
              <UserDisplay
                user={currentPlan.studentProfile}
                classroomsNames={
                  currentPlan.studentProfile.attributes.classroom_names
                }
                size="medium"
                currentPlan
              />
              <LabeledContainer title="Título da cobrança">
                {currentPlan.recurrentPlan.title}
              </LabeledContainer>
            </div>
            <div className="export-bill">
              {hasBatchSelection &&
              canUseNegotiation &&
              isActivityNegotiation ? (
                <Button
                  variation="secondary"
                  disabled={!canNegotiateBills}
                  onClick={handleNegociarClick}
                >
                  Negociar
                </Button>
              ) : (
                <div className="buttons-wrapper">
                  {recurrentBillLegacyId && (
                    <Button variation="primary" onClick={handleTableEdit}>
                      {isTableEdit ? 'Salvar' : 'Editar'}
                    </Button>
                  )}
                  <DropdownButton
                    disabled={isTableEdit}
                    text="Exportar"
                    variation="secondary"
                    dropdownItems={[
                      {
                        text: 'Planilha (.xls)',
                        path: `/schools/recurrent/recurrent_plans/${id}/bills_report.xls`,
                      },
                    ]}
                  />
                </div>
              )}
            </div>
          </div>
          {!canNegotiateBills && hasBatchSelection && isActivityNegotiation && (
            <div className="alert-negotiate-error">
              <Alert variation="danger">
                <p>
                  Não há um responsável financeiro registrado na base ou existe
                  mais de um responsável. Para seguir com a negociação precisa
                  cadastrar um responsável financeiro. Para mais informações
                  sobre o cadastro do responsável financeiro acesse a{' '}
                  <a
                    href="https://suporte.agendaedu.com/hc/pt-br"
                    target={'_blank'}
                    rel="noreferrer"
                  >
                    central de ajuda.
                  </a>
                </p>
              </Alert>
            </div>
          )}
          <div className="bill-list">
            <Table
              columns={getBillsTable()}
              data={addActionsToBills()}
              listOf="recurrentPlans"
            />
          </div>

          <ModalContainer
            isOpen={showCancelEditModal}
            toggleModal={toggleCancelEditModal}
            title="Descartar preenchimento"
            maxWidth="490px"
          >
            <p className="sub-title-modal">
              As informações preenchidas serão descartadas. Tem certeza que
              deseja descartar?
            </p>

            <div className="button-container-modal">
              <ModalButtons buttons={confirmCancelEditModalButtons()} />
            </div>
          </ModalContainer>

          <ShowBillModal
            isOpen={modalOpen && !showBillModalNegotiationOpen}
            toggleModal={toggleShowModal}
            bill={current}
          />

          <ShowBillModalNegotiation
            isOpen={modalOpen && showBillModalNegotiationOpen}
            toggleModal={toggleShowModalNegotiation}
            bill={current}
          />

          <HandleModalByMethod
            method={paymentMethod}
            showModal={paymentModalByMethod}
            toggleModal={togglePaymentMethod}
            isLoading={isLoadingManuallyPaid}
            student={studentProfile}
            responsibles={responsibleProfiles}
            onSubmit={onCheckout}
            checkoutError={errorMessage}
          />
          <LinkModal
            method={paymentMethod}
            showModal={paymentLinkModalByMethod}
            toggleModal={togglePaymentMethodLink}
            responsibles={responsibleProfiles}
            isLoading={isLoadingManuallyPaid}
            order={order}
          />
          <DelayModal
            isOpen={!!delayModal}
            title="Atrasar cobrança"
            subTitle={subTitleToDelayModal()}
            toggleModal={toggleDelayModal}
            onDelay={delayBill}
          />
          <AlertModal
            isLoading={isLoadingRefund}
            closeAlert={toggleRefundModal}
            isOpen={refundModal}
            onConfirm={refundBill}
            title="O responsável será reembolsado"
            type="success"
            confirmButtonText="Reembolsar"
          >
            O reembolso é processado em até 7 dias.
          </AlertModal>
          <AlertModal
            isLoading={isLoadingRevertManualPayment}
            closeAlert={toggleRevertManualPaymentModal}
            isOpen={revertManualPaymentModal}
            onConfirm={revertManualPayment}
            title="O pagamento manual será removido"
            type="success"
            confirmButtonText="Reverter"
          >
            Ao reverter o pagamento manual a cobrança ficará disponível para que o responsável faça o pagamento.
          </AlertModal>

          <ManualDealModal
            isOpen={manualDealModal}
            toggleModal={toggleShowManualDealModal}
            bill={current}
            kind="bill"
          />
          <Toast />
        </React.Fragment>
      )}
    </div>
  );
};

export default tabify({
  title: 'Pagamentos',
  icon: 'money',
})(BillsTab);
