import styled, { css } from 'styled-components';

type MessageProps = {
  approvalStatus?: string;
  type?: string;
};

export const Container = styled.div<MessageProps>`
  ${({ theme }) => css`
    display: flex;
    text-decoration: none;
    color: inherit;
    cursor: pointer;

    &::before {
      content: '';
      border-radius: 3px;
      width: 5px;
      background: ${theme.white};
      margin-right: 10px;
    }
  `}
`;

export const Content = styled.div<MessageProps>`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    margin-right: auto;
    color: ${theme.white};
  `}
`;

export const UserName = styled.div`
  font-weight: 700;
`;

export const MessageInfo = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 500;
  font-size: 11px;
  line-height: 15px;
`;
