import styled from 'styled-components';
import ModalContainer from 'components/Modal/ModalContainer';

export const ModalWrapper = styled(ModalContainer)`
  .ReactModal__Content {
    width: 600px !important;
    padding: 32px !important;
    .title {
      width: 100%;
    }
  }
`;
