import React, { useCallback, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import SideSheet from 'components/SideSheet';
import Accordion from 'components/Accordion';
import Button from 'components/Button';

import SurveyActions from 'store/surveys/actions';

import { Select } from '@agendaedu/ae-web-components';

import './style.scss';

const SURVEYS_STATUS = [
  { label: 'Todas situações', value: '' },
  { label: 'Pendente', value: 'pending' },
  { label: 'Enviado', value: 'sent' },
  { label: 'Encerrado', value: 'closed' },
];

const SideSheetFilters = ({ isOpen, onDismiss, title, callFetchSurveys }) => {
  const dispatch = useDispatch();

  const FADE_OUT_DURATION = 250;

  const { fetchEducationalStages, fetchClassroomFilter } = SurveyActions;

  const surveyList = useSelector((state) => state.surveys.surveyList);
  const headquarters = useSelector((state) => state.surveys.headquarters);
  const educationalStages = useSelector(
    (state) => state.surveys.educationalStages
  );
  const classroomFilter = useSelector((state) => state.surveys.classroomFilter);
  const schoolTerms = useSelector((state) => state.surveys.schoolTerms);
  const selectedSituation = useSelector(
    (state) => state.surveys.selectedSituation
  );
  const selectedHeadquarter = useSelector(
    (state) => state.surveys.selectedHeadquarter
  );
  const selectedEducationalStage = useSelector(
    (state) => state.surveys.selectedEducationalStage
  );
  const selectedClassroomFilter = useSelector(
    (state) => state.surveys.selectedClassroomFilter
  );
  const [newSituation, setNewSituation] = useState(selectedSituation);
  const [newHeadquarter, setNewHeadquarter] = useState(selectedHeadquarter);
  const [newEducationalStage, setNewEducationalStage] = useState(
    selectedEducationalStage
  );
  const [newClassroom, setNewClassroom] = useState(selectedClassroomFilter);
  const [newSchoolTerm, setSchoolTerm] = useState('');

  const activeSchoolTerm = schoolTerms.find(
    (schoolTerm) => schoolTerm.active
  )?.name;

  useEffect(() => {
    if (selectedHeadquarter) setEducationalStages(selectedHeadquarter);
    if (selectedHeadquarter && selectedEducationalStage)
      setClassroomFilter(selectedHeadquarter, selectedEducationalStage);
  }, [surveyList]);

  useEffect(() => {
    setSchoolTerm(activeSchoolTerm);
  }, [activeSchoolTerm, schoolTerms.length]);

  const setEducationalStages = useCallback((headquarterValue) => {
    dispatch(fetchEducationalStages(headquarterValue));
  }, []);

  const setClassroomFilter = useCallback(
    (headquarterValue, educationalStageValue) => {
      dispatch(fetchClassroomFilter(headquarterValue, educationalStageValue));
    },
    []
  );

  const headquartersArePresent = () =>
    headquarters && headquarters.data.length > 0;
  const educationalStagesArePresent = () =>
    headquartersArePresent() && newHeadquarter && educationalStages.length > 0;
  const classroomsArePresent = () =>
    educationalStagesArePresent() &&
    newEducationalStage &&
    classroomFilter.length > 0;

  const optionsWithoutPlaceholderItem = (options) => {
    const newOptions = options;
    const index = newOptions.findIndex((item) => item.value === null);
    newOptions.splice(index, 1);
    return newOptions;
  };

  const sortOptions = (options, target) => {
    const newOptions = optionsWithoutPlaceholderItem(options);
    const selectedIndex = newOptions.findIndex(
      (option) => option.value === target
    );
    const selected = newOptions.splice(selectedIndex, 1)[0];
    newOptions.unshift(selected);
    return newOptions;
  };

  const getHeadquarterOptions = () => {
    let options = [{ value: null, label: 'Selecione a unidade' }];
    if (headquartersArePresent()) {
      headquarters.data.forEach((headquarter) => {
        options.push({
          value: headquarter.id,
          label: headquarter.attributes.name,
        });
      });
      if (selectedHeadquarter)
        options = sortOptions(options, selectedHeadquarter);
    }
    return options;
  };

  const getEducationalStagesOptions = () => {
    let options = [{ value: null, label: 'Selecione o segmento' }];
    if (educationalStagesArePresent()) {
      educationalStages.forEach((educationalStage) => {
        options.push({
          value: educationalStage.id,
          label: educationalStage.attributes.name,
        });
      });
      if (newEducationalStage)
        options = sortOptions(options, newEducationalStage);
    }
    return options;
  };

  const getClassroomOptions = () => {
    let options = [{ value: null, label: 'Selecione a turma' }];
    if (classroomsArePresent()) {
      classroomFilter.forEach((classroom) => {
        const { id, name } = classroom.attributes;
        options.push({ value: id, label: name });
      });
      if (newClassroom) options = sortOptions(options, newClassroom);
    }
    return options;
  };

  const getSchoolTermOptions = () => {
    let options = [];
    schoolTerms.forEach((term) => {
      options.push({ label: term.name, value: String(term.name) });
    });

    return options.sort((a, b) => b.value - a.value);
  };

  const applyFilters = (parameters) => {
    document
      .getElementById('side-sheet-wrapper')
      .classList.add('fade-out-animation');
    document.getElementById('side-sheet').classList.add('slide-out-animation');
    setTimeout(() => {
      callFetchSurveys(parameters);
      onDismiss();
    }, FADE_OUT_DURATION);
  };

  const clearFilters = () => {
    setNewSituation('');
    setNewHeadquarter(null);
    setNewEducationalStage(null);
    setNewClassroom(null);
    setSchoolTerm(activeSchoolTerm);
    applyFilters({
      situation: '',
      headquarterIds: null,
      educationalStageIds: null,
      classroomIds: null,
      schoolTermNames: [activeSchoolTerm],
    });
  };

  const renderSituationTitle = () => {
    return <h2 className="accordion-title">Situação</h2>;
  };

  const rendeSituationAccordionBody = () => (
    <Select
      fullWidth={true}
      marginBottom={8}
      marginRight={{ mobileSM: 8 }}
      className="ae-select"
      options={SURVEYS_STATUS}
      value={newSituation}
      defaultValue=""
      onChange={(e) => setNewSituation(e.value)}
    />
  );

  const renderGeneralFiltersTitle = () => {
    return <h2 className="accordion-title">Unidade/Segmento/Turma</h2>;
  };

  const renderGeneralFiltersAccordionBody = () => {
    return (
      <>
        <Select
          marginBottom={24}
          attributeName="headquarter_selector"
          label="Unidade"
          placeholder="Selecione a unidade"
          value={newHeadquarter}
          options={getHeadquarterOptions()}
          disabled={!headquartersArePresent()}
          onChange={(event) => {
            setNewEducationalStage(null);
            setNewClassroom(null);
            setNewHeadquarter(event.value);
            setEducationalStages(event.value);
          }}
        />

        <Select
          marginBottom={24}
          attributeName="educational_stages"
          label="Segmento"
          placeholder="Selecione o segmento"
          value={newEducationalStage}
          options={getEducationalStagesOptions()}
          disabled={!educationalStagesArePresent()}
          onChange={(event) => {
            setNewClassroom(null);
            setNewEducationalStage(event.value);
            setClassroomFilter(newHeadquarter, event.value);
          }}
        />

        <Select
          attributeName="classrooms"
          label="Turma"
          value={newClassroom}
          placeholder="Selecione a turma"
          options={getClassroomOptions()}
          disabled={!classroomsArePresent()}
          onChange={(event) => setNewClassroom(event.value)}
        />
      </>
    );
  };

  const renderSchoolTermFiltersTitle = () => {
    return <h2 className="accordion-title">Período letivo</h2>;
  };

  const renderSchoolTermFiltersAccordionBody = () => {
    return (
      <Select
        attributeName="school_term"
        label="Período letivo"
        value={newSchoolTerm}
        placeholder="Selecione o período"
        options={getSchoolTermOptions()}
        onChange={(event) => {
          setSchoolTerm(event.value);
        }}
      />
    );
  };

  return (
    <SideSheet isOpen={isOpen} onDismiss={onDismiss} title={title}>
      <div className="side-sheet-filters">
        <div>
          <hr className="divider" />
          <Accordion
            header={renderSituationTitle()}
            body={rendeSituationAccordionBody()}
            allowZeroExpanded
            expanded="survey"
          />
          <hr className="divider" />
          <Accordion
            header={renderGeneralFiltersTitle()}
            body={renderGeneralFiltersAccordionBody()}
            allowZeroExpanded
            expanded="survey"
          />
          <hr className="divider" />
          <Accordion
            header={renderSchoolTermFiltersTitle()}
            body={renderSchoolTermFiltersAccordionBody()}
            allowZeroExpanded
            expanded="survey"
          />
          <hr className="divider" />
        </div>
        <div className="button-row">
          <Button
            id="apply-filters-button"
            variation="primary"
            className="left-button"
            onClick={() =>
              applyFilters({
                situation: newSituation,
                headquarterIds: [newHeadquarter],
                educationalStageIds: [newEducationalStage],
                classroomIds: [newClassroom],
                schoolTermNames: [newSchoolTerm],
              })
            }
          >
            Aplicar
          </Button>
          <Button
            id="clear-filters-button"
            variation="secondary"
            className="right-button"
            onClick={clearFilters}
          >
            Limpar
          </Button>
        </div>
      </div>
    </SideSheet>
  );
};

export default SideSheetFilters;
