import React, { useEffect, useState } from 'react';

import { Box, TextField } from '@agendaedu/ae-web-components';
import { Props } from './types';

export const TextQuestion = ({
  question,
  onChange,
  value,
}: Props): React.ReactElement => {
  const [textValue, setTextValue] = useState(value);

  useEffect(() => {
    onChange({ type: 'text', value: textValue, questionId: question.id });
  }, [question.id, textValue]);

  return (
    <Box>
      <TextField
        label={question.title}
        onChange={(value) => setTextValue(value.target.value)}
        value={textValue}
        multiline
        fullWidth
      />
    </Box>
  );
};
