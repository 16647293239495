import React, { useCallback } from 'react';
import ToggleSwitch from 'components/Form/ToggleSwitch';
import { Text } from '@agendaedu/ae-web-components';

function SwitchTitle({ input, disabled, title, value }) {
  const onChange = useCallback(() => input.onChange(input.name), [input]);

  return (
    <React.Fragment>
      <Text
        fontWeight={500}
        fontSize={16}
        variant="subtitle-medium-16"
        color="neutral.black"
        ml={24}
      >
        {title}
      </Text>
      <ToggleSwitch toggled={value} onChange={onChange} disabled={disabled} />
    </React.Fragment>
  );
}

export default SwitchTitle;
