import styled, { css } from 'styled-components';
import Button from 'components/Button';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 16px;
`;

export const FieldWithLabel = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Label = styled.label`
  ${({ theme }) => css`
    color: ${theme.gray1};
    font-weight: 400;
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    margin-top: 12px;
  `}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const InputContainer = styled.div`
  ${({ theme }) => css`
    width: fit-content;
    display: flex;
    margin: 4px 24px 12px 0;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    height: 40px;
    > * {
      border: 1px solid ${theme.gray3};
      border-radius: 0 6px 6px 0;
      height: 40px;
    }
    > button {
      border-left: none;
      background-color: transparent;
      color: ${theme.danger};
      padding: 9px 10px;

      &:disabled {
        color: ${theme.gray3};
      }
    }

    .Field {
      margin-top: 24px;
      padding: 0 0;
      width: 200px;
      border: unset;
      font-size: 16px;

      div:first-child {
        > * {
          border-bottom-right-radius: 0;
          border-top-right-radius: 0;
        }
      }

      &:disabled {
        background-color: ${theme.gray5};
      }
    }
  `}
`;

export const AddButon = styled(Button)`
  margin-top: 16px;
  align-self: flex-start;
  width: 40px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;
