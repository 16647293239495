import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { titleMobile } from 'core/utils/changeText';
import composeFunctions from 'core/utils/composeFunctions';
import FormFieldset from 'components/Form/Fieldset';
import Loader from 'components/Loader';
import Table from 'components/Table';
import EmptyState from 'components/EmptyState';
import withFormContext, { formPropTypes } from 'core/hoc/withFormContext';
import withWindowPathname, {
  windowPropTypes,
} from 'core/hoc/withWindowPathname';
import tabifyForWizard from 'core/hoc/tabifyForWizard';
import importWebActions from 'store/importWeb/actions';
import table from '../../components/ImportsDataTable';
import { mapTableColumns } from '../../utils';
import { IMAGES_PAYMENT_URL } from 'core/constants/index';
import * as S from './styles';

const InconsistenciesTab = ({
  formContext: { changeMeta },
  windowPathname,
}) => {
  const { error, uploadedFile, isValidating, validatedFile } = useSelector(
    (state) => state.importWeb
  );
  const { validateFileValues, setImportStep } = importWebActions;

  const dispatch = useDispatch();

  const importedInto = windowPathname.split('/')[3];

  const handleSetImportStep = useCallback(() => {
    dispatch(setImportStep(3));
  }, [dispatch, setImportStep]);

  const handleValidateFileValues = useCallback(() => {
    changeMeta('nextStepDisabled', true);
    if (uploadedFile)
      dispatch(validateFileValues(importedInto, uploadedFile.signed_id));
  }, [dispatch, uploadedFile, validateFileValues, importedInto, changeMeta]);

  useEffect(() => {
    handleSetImportStep();
  }, [handleSetImportStep]);

  useEffect(() => {
    if (!validatedFile.rows.length && error == null) handleValidateFileValues();
    if (!isValidating) changeMeta('nextStepDisabled', false);
  }, [
    error,
    handleValidateFileValues,
    validatedFile,
    isValidating,
    changeMeta,
  ]);

  const renderTable = () => {
    if (validatedFile.rowsWithErrors.length > 0) {
      return (
        <S.TableWrapper>
          <Table
            columns={mapTableColumns(
              table[importedInto],
              uploadedFile && uploadedFile.headers
            )}
            data={validatedFile.rowsWithErrors}
            customMetadata={tableEntries()}
          />
        </S.TableWrapper>
      );
    } else {
      return (
        <EmptyState
          imgUrl={IMAGES_PAYMENT_URL.onboardingUrl}
          message={
            <p>
              Tudo certo! Não foi encontrada nenhuma inconsistência.
              <br />
              Você pode avançar para o próximo passo da importação.
            </p>
          }
        />
      );
    }
  };

  const tableEntries = () => {
    return (
      <div className="customMetadata">
        {validatedFile.rowsWithErrors.length ? (
          <>
            <span>
              <b className="found">{validatedFile.foundErrors}</b>{' '}
              inconsistência(s) encontrada(s){' '}
            </span>
            <span>
              <b className="corrected">{validatedFile.correctedErrors}</b>{' '}
              inconsistência(s) corrigida(s)
            </span>
          </>
        ) : (
          <>
            <span>Nenhuma inconsistência encontrada</span>
          </>
        )}
      </div>
    );
  };

  return (
    <S.Wrapper className="InconsistenciesTab" data-testid="InconsistenciesTab">
      <FormFieldset>
        <S.InfoText>
          Os campos em vermelho apresentam as inconsistências encontradas no
          arquivo carregado, você poderá corrigir os dados digitando diretamente
          dentro do campo ou se quiser limpar o campo, basta clicar no X.{' '}
          <b>
            Inconsistências de campos obrigatórios não serão importados caso não
            sejam corrigidos.
          </b>
        </S.InfoText>

        <Loader isLoading={isValidating}>{renderTable()}</Loader>
      </FormFieldset>
    </S.Wrapper>
  );
};

InconsistenciesTab.propTypes = {
  ...formPropTypes,
  ...windowPropTypes,
};

export default composeFunctions(
  withFormContext,
  withWindowPathname,
  tabifyForWizard({
    title: titleMobile('3 Inconsistências'),
    subTitle: 'Inconsistências encontradas',
  })
)(InconsistenciesTab);
