/* eslint-disable import/no-unresolved */
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// Actions
import actionRecurrentProducts from 'store/edupay/recurrentProducts/actions';

import ModalContainer from 'components/Modal/ModalContainer';
import ModalTitle from 'components/Modal/ModalTitle';
import FormContainer from './form';

import './style.scss';

const RecurrentProductForm = ({ modalOpen, toggleModal, product }) => {
  const dispatch = useDispatch();

  const sending = useSelector((state) => state.recurrentProducts.sending);
  const formValues = useSelector((state) => state.recurrentProducts.formValues);

  const { addRecurrentProduct, editRecurrentProduct } = actionRecurrentProducts;

  const createProduct = useCallback(
    (form) => {
      dispatch(addRecurrentProduct(form));
    },
    [dispatch]
  );

  const updateProduct = useCallback(
    (form) => {
      dispatch(editRecurrentProduct(form));
    },
    [dispatch]
  );

  const onSubmit = (form) => {
    // eslint-disable-next-line no-unused-expressions
    product ? updateProduct(form) : createProduct(form);
  };

  return (
    <ModalContainer
      isOpen={modalOpen}
      toggleModal={toggleModal}
      className="RecurrentProductForm"
    >
      <ModalTitle>{product ? 'Editar produto' : 'Novo produto'}</ModalTitle>
      <FormContainer
        onSubmit={onSubmit}
        initialValues={formValues}
        action={product ? 'edit' : 'create'}
        onClose={toggleModal}
        sending={sending}
      />
    </ModalContainer>
  );
};

RecurrentProductForm.propTypes = {
  toggleModal: PropTypes.func.isRequired,
  modalOpen: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/require-default-props
  product: PropTypes.shape({
    id: PropTypes.string,
    attributes: PropTypes.PropTypes.shape({
      name: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
    }),
  }),
};

export default RecurrentProductForm;
