import launchDarkly from 'core/helper/launchDarkly';
import { useCookies } from 'react-cookie';
import { HookReturn, Props } from './types';
import { flags } from 'core/constants/flags';

export const useLaunchDarkly = ({ flag }: Props): HookReturn => {
  const [cookies] = useCookies(['agendaedu_launch_darkly']);

  const activeFlags = cookies.agendaedu_launch_darkly?.active_flags;

  const isActiveFlag = launchDarkly.checkFlagActive(activeFlags, flags[flag]);

  return { isActiveFlag };
};
