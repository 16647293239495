import React, { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { titleMobile } from 'core/utils/changeText';
import composeFunctions from 'core/utils/composeFunctions';
import FormFieldset from 'components/Form/Fieldset';
import Table from 'components/Table';
import Pagination from 'components/Pagination';
import Loader from 'components/Loader';
import withFormContext, { formPropTypes } from 'core/hoc/withFormContext';
import tabifyForWizard from 'core/hoc/tabifyForWizard';
import withWindowPathname, {
  windowPropTypes,
} from 'core/hoc/withWindowPathname';
import table from '../../components/ImportsDataTable';
import importWebActions from 'store/importWeb/actions';
import { mapTableColumns } from '../../utils';
import * as S from './styles';

const InformationsTab = ({ formContext: { changeMeta }, windowPathname }) => {
  const [page, setPage] = useState(1);
  const { error, isLoading, uploadedFile, paginatedFile } = useSelector(
    (state) => state.importWeb
  );
  const { setImportStep, fetchPaginatedFile } = importWebActions;
  const dispatch = useDispatch();

  const importedInto = windowPathname.split('/')[3];

  const getPaginatedFile = useCallback(
    (importedInto, uploadedFile, page) => {
      changeMeta('nextStepDisabled', true);
      dispatch(fetchPaginatedFile(importedInto, uploadedFile.signed_id, page));
    },
    [dispatch, fetchPaginatedFile, changeMeta]
  );

  const onPageChanged = useCallback(
    (page) => {
      setPage(page);
      getPaginatedFile(importedInto, uploadedFile, page);
    },
    [getPaginatedFile, importedInto, uploadedFile]
  );

  const handleSetImportStep = useCallback(() => {
    dispatch(setImportStep(2));
  }, [dispatch, setImportStep]);

  useEffect(() => {
    handleSetImportStep();
  }, [handleSetImportStep]);

  useEffect(() => {
    if (importedInto && !paginatedFile.rows.length && error == null)
      getPaginatedFile(importedInto, uploadedFile, page);

    if (!isLoading) changeMeta('nextStepDisabled', false);
    if (paginatedFile.pagination.currentPage)
      setPage(paginatedFile.pagination.currentPage);
  }, [
    error,
    paginatedFile,
    getPaginatedFile,
    importedInto,
    page,
    uploadedFile,
    isLoading,
    changeMeta,
  ]);

  const tableEntries = () => {
    return (
      <div className="customMetadata">
        <span>
          Exibindo {paginatedFile.numberOfRows} linhas de conteúdo do arquivo
          carregado
        </span>
      </div>
    );
  };

  return (
    <S.Wrapper
      className="ImportWebInformationsTab"
      data-testid="ImportWebInformationsTab"
    >
      <FormFieldset>
        <Loader isLoading={isLoading}>
          <S.TableWrapper>
            <Table
              columns={mapTableColumns(
                table[importedInto],
                uploadedFile && uploadedFile.headers
              )}
              data={paginatedFile.rows}
              customMetadata={tableEntries()}
            />
          </S.TableWrapper>
          {paginatedFile.numberOfRows > 10 ? (
            <Pagination
              onChange={onPageChanged}
              activePage={page}
              totalItemsCount={paginatedFile.numberOfRows}
              itemsCountPerPage={10}
            />
          ) : null}
        </Loader>
      </FormFieldset>
    </S.Wrapper>
  );
};

InformationsTab.propTypes = {
  ...formPropTypes,
  ...windowPropTypes,
};

export default composeFunctions(
  withFormContext,
  withWindowPathname,
  tabifyForWizard({
    title: titleMobile('2 Visualizar dados'),
    subTitle: 'Prévia dos dados a serem importados',
  })
)(InformationsTab);
