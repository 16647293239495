import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import {
  renderPenalityMessage,
  renderDiscountMessage,
  canCancelEnrollment,
} from 'core/utils/edupay/functions';
import { normalizeFileSize } from 'core/utils/formatBytes';
import {
  DISPLAY_TRANSACTION_ID_TO_STATUS,
  DISPLAY_STATUS_PAID,
} from 'core/constants/index';

import AgendaIcon from 'components/AgendaIcon';
import Button from 'components/Button';
import ButtonRow from 'components/ButtonRow';
import Currency from 'components/Currency';
import enrollmentPlanStatus from '../EnrollmentPlanStatus';
import enrollmentPlanContractStatus from '../EnrollmentPlanContractStatus';
import LabeledContainer from 'components/LabeledContainer';
import Loader from 'components/Loader';
import ModalContainer from 'components/Modal/ModalContainer';
import ModalTitle from 'components/Modal/ModalTitle';
import OutlineBox from 'components/OutlineBox';
import SimpleSummary from 'components/Payments/PaymentsContainer/FormSections/SimpleSummary';
import Tooltip, { RIGHT_TOP } from 'components/Tooltip';
import UserDisplay from 'components/UserDisplay';

import './style.scss';

const EnrollmentDetailModal = ({
  isOpen,
  toggleModal,
  enrollment,
  responsibles,
  handleToggleCancelModal,
}) => {
  const schoolUser = useSelector((state) => state.root.currentUser);
  const {
    attributes: { is_debug_user: isDebugUser },
  } = schoolUser;

  const { isLoadingEnrollmentModal, currentSignedFile } = useSelector(
    (state) => state.enrollmentPlans
  );

  const policies = useSelector((state) => state.root.policies);

  const content = responsibles.map((responsible) => (
    <p className="tooltip-status" key={responsible.attributes.id}>
      <strong>
        {
          enrollmentPlanContractStatus[responsible.attributes.document_status]
            .text
        }
        {': '}
      </strong>
      {responsible.attributes.responsible_profile_name}
    </p>
  ));

  const statusTag = () => (
    <>
      {enrollment && (
        <div className="status-modal">
          <Tooltip content={content} position={RIGHT_TOP}>
            <OutlineBox
              className="contract-status"
              variation={
                enrollmentPlanContractStatus[
                  enrollment.attributes.document_status
                ].variation
              }
            >
              {`Contrato ${
                enrollmentPlanContractStatus[
                  enrollment.attributes.document_status
                ].text
              }`}
            </OutlineBox>
          </Tooltip>
          <OutlineBox
            variation={
              enrollmentPlanStatus[enrollment.attributes.status].variation
            }
          >
            {enrollmentPlanStatus[enrollment.attributes.status].text}
          </OutlineBox>
        </div>
      )}
    </>
  );

  const handleCancellEnrollment = () => {
    toggleModal();
    handleToggleCancelModal();
  };

  const renderInfoTransaction = () => {
    const {
      attributes: { last_payment },
    } = enrollment;

    if (Object.keys(last_payment).length === 0) return;

    return (
      <>
        <LabeledContainer title="ID da transação">
          <span className="detail-title pagarme-transaction-id">
            <strong>{last_payment.data.attributes.transaction_id}</strong>
          </span>
        </LabeledContainer>
        <LabeledContainer title="Provedor">
          <span className="detail-title payment-provider">
            <strong>{last_payment.data.attributes.payment_provider}</strong>
          </span>
        </LabeledContainer>
      </>
    );
  };

  return (
    <ModalContainer
      className="EnrollmentDetailModal"
      isOpen={isOpen}
      toggleModal={toggleModal}
      maxWidth={484}
    >
      {isLoadingEnrollmentModal ? (
        <Loader />
      ) : (
        <>
          <ModalTitle>Detalhes da matrícula</ModalTitle>
          {statusTag()}
          <UserDisplay
            user={enrollment.attributes.student_profile.data}
            classroomsNames={
              enrollment.attributes.student_profile.data.attributes
                .classroom_names
            }
          />
          <LabeledContainer title="Descrição">
            <div
              className="enrollment-description"
              dangerouslySetInnerHTML={{
                __html: enrollment.attributes.description,
              }}
            />
          </LabeledContainer>
          <LabeledContainer title="Responsável">
            <p className="responsibles">
              {responsibles.map((responsible, index) =>
                responsibles.length - 1 === index
                  ? responsible.attributes.responsible_profile_name
                  : `${responsible.attributes.responsible_profile_name}, `
              )}
            </p>
          </LabeledContainer>
          <div className="double-column">
            <LabeledContainer title="Data da aprovação">
              {enrollment.attributes.approved_at || 'Não aprovado'}
            </LabeledContainer>
            <LabeledContainer title="Vencimento em">
              {enrollment.attributes.expires_at}
            </LabeledContainer>
            {enrollment.attributes?.canceled_at && (
              <LabeledContainer title="Cancelado em">
                {enrollment.attributes?.canceled_at}
              </LabeledContainer>
            )}

            {enrollment.attributes?.paid_at && (
              <LabeledContainer title="Pago em">
                {enrollment.attributes?.paid_at}
              </LabeledContainer>
            )}
          </div>
          {enrollment.attributes?.details && (
            <LabeledContainer title="Observação">
              {enrollment.attributes?.details}
            </LabeledContainer>
          )}
          <div className="double-column">
            {DISPLAY_STATUS_PAID.includes(enrollment.attributes.status) && (
              <LabeledContainer title="Valor recebido">
                <Currency value={enrollment.attributes.paid_price_cents} />
              </LabeledContainer>
            )}
          </div>
          {enrollment.attributes.has_penality && (
            <LabeledContainer title="Multas e juros">
              <div className="penality">
                {renderPenalityMessage(
                  enrollment.attributes.edupay_penality.data.attributes
                )}
              </div>
            </LabeledContainer>
          )}
          {enrollment.attributes.has_discount && (
            <LabeledContainer title="Desconto de pontualidade">
              <div className="unique-column">
                <ul>
                  {enrollment.attributes.edupay_discounts.data.map(
                    ({ attributes, index }) => (
                      <div key={index}>{renderDiscountMessage(attributes)}</div>
                    )
                  )}
                </ul>
              </div>
            </LabeledContainer>
          )}
          {enrollment.attributes.paid_attachment && (
            <LabeledContainer title="Anexo">
              <a
                className="attachment-wrapper"
                href={enrollment.attributes.paid_attachment.url}
                target="_blank"
                rel="noopener noreferrer"
                download
              >
                <div className="pdf-name">
                  <AgendaIcon name="attachment" />
                  <p>{enrollment.attributes.paid_attachment.name}</p>
                </div>
                <div className="size">
                  {normalizeFileSize(
                    enrollment.attributes.paid_attachment.size
                  )}
                </div>
              </a>
            </LabeledContainer>
          )}
          <LabeledContainer title="Contrato">
            <a
              className="attachment-wrapper"
              href={
                (currentSignedFile?.attributes.signed_file ||
                  enrollment.attributes.attachment?.url) ??
                enrollment.attributes.contract_template.data.attributes
                  .attachment.url
              }
              target="_blank"
              rel="noopener noreferrer"
              download
            >
              <div className="pdf-name">
                <AgendaIcon name="attachment" />
                <p>
                  {enrollment.attributes.attachment?.name ??
                    enrollment.attributes.contract_template.data.attributes
                      .attachment.name}
                </p>
              </div>
              <div className="size">
                {normalizeFileSize(
                  enrollment.attributes.attachment?.size ??
                    enrollment.attributes.contract_template.data.attributes
                      .attachment.size
                )}
              </div>
            </a>
          </LabeledContainer>
          {enrollment &&
            isDebugUser &&
            DISPLAY_TRANSACTION_ID_TO_STATUS.includes(
              enrollment.attributes.status
            ) &&
            renderInfoTransaction()}
          <LabeledContainer title="Resumo da cobrança">
            <SimpleSummary price={enrollment.attributes.price} />
          </LabeledContainer>
          <ButtonRow>
            <Button variation="secondary" onClick={() => toggleModal()}>
              Fechar
            </Button>
            <Button
              onClick={handleCancellEnrollment}
              variation="primary"
              disabled={
                !policies.can_cancel_school_product ||
                !canCancelEnrollment(
                  enrollment.attributes.status,
                  enrollment.attributes.document_status
                )
              }
            >
              Cancelar Matrícula
            </Button>
          </ButtonRow>
        </>
      )}
    </ModalContainer>
  );
};

EnrollmentDetailModal.propTypes = {
  isLoading: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  enrollment: PropTypes.object,
  responsibles: PropTypes.object,
  handleToggleCancelModal: PropTypes.func,
};

export default EnrollmentDetailModal;
