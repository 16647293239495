import React, { useContext } from 'react';

import { useTranslation } from 'react-i18next';

import { Box, Button, Modal, Text } from '@agendaedu/ae-web-components';
import { Props } from './types';
import { DiaryStudentsFormContext } from 'core/contexts/DiaryForm/StudentsForm';
import { DiarySectionsFormContext } from 'core/contexts/DiaryForm/SectionsForm';

import { Props as DiaryStudentsFormContextProps } from 'core/contexts/DiaryForm/StudentsForm/types';
import { Props as DiarySectionsFormContextProps } from 'core/contexts/DiaryForm/SectionsForm/types';

const getContext = {
  students: DiaryStudentsFormContext,
  sections: DiarySectionsFormContext,
};

export const NotificationModal = ({
  isOpen,
  onClose,
  formFillType,
}: Props): JSX.Element => {
  const { t } = useTranslation('diary');
  const {
    form: { setFieldValue, submitForm },
  } = useContext<DiaryStudentsFormContextProps | DiarySectionsFormContextProps>(
    getContext[formFillType]
  );

  const onConfirm = (notify: boolean) => {
    setFieldValue('notify', notify);
    submitForm();
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      title={t('form.notification_modal.title')}
      onClose={onClose}
    >
      <Box width="100%" mt="sm">
        <Text
          data-testid="text-content-modal"
          variant="body-regular-16"
          mx="xs3"
          maxWidth={360}
          lineHeight="lg"
        >
          {t('form.notification_modal.content')}
        </Text>

        <Box display="flex" justifyContent="flex-end" mt="md">
          <Button
            id="non-notify-button"
            testId="non-notify-button"
            variant="secondary"
            mr="sm"
            onClick={() => onConfirm(false)}
          >
            {t('form.notification_modal.cancel')}
          </Button>
          <Button
            id="confirm-notify-button"
            testId="confirm-notify-button"
            onClick={() => onConfirm(true)}
          >
            {t('form.notification_modal.confirm')}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
