import React, { useCallback, useEffect } from 'react';
import { appPropTypes } from 'core/hoc/withAppContext';

import { useTranslation } from 'react-i18next';

import PageContainer from 'components/PageContainer';
import Breadcrumb from 'components/Breadcrumb';
import Form from './components/Form';
import Loader from 'components/Loader';
import Toast from 'components/Toast';
import { useDispatch, useSelector } from 'react-redux';
import QuickAccessActions from 'store/quickAccess/actions';
import QuickAccessContext from './quickAccessContext';
import { constants } from './constants';
import normalizeParams from './normalizeParams';
import Skeleton from './components/skeletonQuickAccess';

const QuickAccess = ({ match }) => {
  const { t } = useTranslation(['quickaccess']);
  const { id } = match.params;
  const dispatch = useDispatch();
  const ACTION = id ? constants.EDIT : constants.NEW;

  const {
    submitNewFormRequest,
    submitEditFormRequest,
    getClassrooms,
    getQuickAccessRequest,
  } = QuickAccessActions;

  const { isLoading, isLoadingEdit, isSending, formMeta, currentForm } =
    useSelector((state) => state.quickAccess);

  const fetchClassrooms = useCallback(() => {
    dispatch(getClassrooms());
  }, [dispatch, getClassrooms]);

  const fetchQuickAccess = useCallback(() => {
    dispatch(getQuickAccessRequest(id));
  }, [dispatch, id]);

  useEffect(() => {
    fetchClassrooms();
    if (id) fetchQuickAccess();
  }, []);

  const onSubmit = useCallback(
    (formContext) => {
      ACTION === constants.EDIT
        ? dispatch(submitEditFormRequest(normalizeParams(formContext.form), id))
        : dispatch(submitNewFormRequest(normalizeParams(formContext.form)));
    },
    [dispatch, submitNewFormRequest]
  );

  if (formMeta.classrooms.length <= 0 && (isLoadingEdit || isLoading))
    return <Skeleton />;

  return (
    <PageContainer
      title={
        ACTION === constants.EDIT
          ? t('edit_quick_access')
          : t('new_quick_access')
      }
      breadcrumb={
        <Breadcrumb
          title={t('quick_access')}
          path="/schools/quick_accesses"
          external
        />
      }
    >
      <QuickAccessContext.Provider value={{}}>
        <Form
          action={ACTION}
          form={currentForm}
          formMeta={formMeta}
          onSubmit={onSubmit}
          isSubmitting={isSending}
        />
        <Toast />
      </QuickAccessContext.Provider>
    </PageContainer>
  );
};

QuickAccess.propTypes = {
  ...appPropTypes,
};

export default QuickAccess;
