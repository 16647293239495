import { ThemeProps } from 'config/themes/types';
import styled, { css } from 'styled-components';

import Tooltip from 'components/Tooltip';
import Field from 'components/Form/Field';
import { Alert } from '@agendaedu/ae-web-components';

export const FormContainer = styled.div`
  ${({ theme: { breakpoints } }: ThemeProps) => css`
    .Field {
      padding-left: 0px;
    }
    .FormFieldset {
      display: flex;
      flex-direction: column;

      @media (min-width: ${breakpoints.desktopXL}) {
        margin-left: 25%;
      }
    }
  `}
`;

export const WrapperAlert = styled(Alert)`
  ${({ theme: { space } }: ThemeProps) => css`
    margin-bottom: ${space.xl2};
  `}
`;

export const WrapperTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const WrapperTooltip = styled(Tooltip)`
  margin-bottom: -6px;
`;

export const WrapperField = styled(Field)`
  margin-top: 24px;
  margin-bottom: 40px !important;
`;
