import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Button } from '@agendaedu/ae-web-components';
import { Modal } from 'components/Handouts/Modal';

import withFormContext from 'core/hoc/withFormContext';

import { HandoutState } from 'store/handouts/types';
import { NotificationModalProps } from './types';

const NotificationModal = ({
  handleOnSubmit,
  handleToggle,
  formContext,
}: NotificationModalProps) => {
  const { t } = useTranslation(['handouts']);

  const { showNotificationModal } = useSelector(
    (state: HandoutState) => state.handouts
  );

  const handleUpdateWithoutNotification = () => {
    handleOnSubmit({ ...formContext.form, notify: false });
  };
  const handleUpdateWithNotification = () => {
    handleOnSubmit({ ...formContext.form, notify: true });
  };

  return (
    <Modal.Root
      dataTestId="notification-update-modal"
      isOpen={showNotificationModal}
      handleToggleModal={handleToggle}
      title={t('modals.notification_update.title')}
    >
      <Modal.Content>
        {t('modals.notification_update.description')}
      </Modal.Content>
      <Modal.Actions>
        <Button variant="secondary" isNegativeAction onClick={handleToggle}>
          {t('modals.notification_update.close_button')}
        </Button>
        <Button variant="secondary" onClick={handleUpdateWithoutNotification}>
          {t('modals.notification_update.dont_notify_button')}
        </Button>
        <Button onClick={handleUpdateWithNotification}>
          {t('modals.notification_update.submit_button')}
        </Button>
      </Modal.Actions>
    </Modal.Root>
  );
};

export default withFormContext(NotificationModal);
