import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import withAppContext, { appPropTypes } from 'core/hoc/withAppContext';

import PanelSkeleton from 'pages/panel/Skeleton';
import ChartView from 'pages/panel/ChartView';
import Toast from 'components/Toast';

import AgendaIcon from 'components/AgendaIcon';
import Tooltip, { TOP_CENTER } from 'components/Tooltip';
import PanelButtonAndSideSheet from 'pages/panel/ButtonAndSideSheet';
import FormContainer from 'components/Form/FormContainer';
import PanelActions from 'store/panel/actions';

import * as S from './styles';

const Panel = () => {
  const dispatch = useDispatch();

  const { fetchCharts, fetchReports } = PanelActions;

  const {
    isSending,
    selectedType,
    formMeta: { filterAmount, reports },
    charts,
    selectedReport,
  } = useSelector((state) => state.panel);

  const handleFilters = (params) => {
    dispatch(fetchCharts({ ...params }));
  };

  const renderLoadedCharts = isSending ? (
    <PanelSkeleton />
  ) : (
    <ChartView charts={charts} />
  );

  useEffect(() => {
    dispatch(fetchReports());
    dispatch(fetchCharts({}));
  }, []);

  return (
    <S.MainContainer id="PanelMainContainer">
      <Toast />

      <S.MainTitle>Painel</S.MainTitle>
      <FormContainer variation="horizontal">
        <S.MainFilterField
          attributeName="report_id"
          type="selectWithIcon"
          size="smaller"
          placeholder="Selecione o filtro"
          value={selectedReport}
          options={reports}
          onChange={({ value }) => handleFilters({ report_id: value })}
          withArrowSelect
        />
        <S.DateField
          attributeName="date"
          size="small"
          type="period"
          value={selectedType}
          onChange={({ date }) => handleFilters({ ...date })}
          withArrowSelect
        />
        <S.TooltipWrapper>
          <Tooltip
            position={TOP_CENTER}
            content="Saiba mais sobre os relatórios"
          >
            <a
              href="https://agendaedu.zendesk.com/hc/pt-br/articles/6198417966363-Novo-Painel-Inicial"
              target="_blank"
              rel="noreferrer"
            >
              <AgendaIcon name="help" size="medium" />
            </a>
          </Tooltip>
        </S.TooltipWrapper>
        <PanelButtonAndSideSheet
          amount={filterAmount}
          handleFilter={handleFilters}
        />
      </FormContainer>
      {renderLoadedCharts}
    </S.MainContainer>
  );
};

Panel.propTypes = {
  ...appPropTypes,
};

export default withAppContext(Panel);
