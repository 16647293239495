import React from 'react';
import { useSelector } from 'react-redux';
import { flags } from 'core/constants/flags';

import FilterContainer, {
  DoubleInputsWrapper,
  HalfInputWrapper,
} from 'components/FilterContainer';

import Field from 'components/Form/Field';
import Search from 'components/Form/Search';
import ButtonAndSideFilter from 'components/Payments/ButtonAndSideFilter';
import ResendBilletButton from 'components/Payments/ResendBilletButton';

import './style.scss';

import { RecurrentFiltersProps } from './types';

const RecurrentFilters = ({
  form,
  formMeta,
  handleFilterSubmit,
}: RecurrentFiltersProps) => {
  const { status: formStatus } = form;
  const {
    select_options: {
      status: metaStatus,
      recurrent_search_type: recurrentSearchType,
    },
    amount,
    filters,
  } = formMeta;

  const {
    policies: { non_payment_active_flags: nonPaymentActiveFlags },
  } = useSelector((state) => state.root);

  const canFilterDateRanger = (e) => {
    if (e.range?.startDate !== null && e.range?.endDate !== null)
      handleFilterSubmit({ range: e.range, page: 1 });
  };

  const canResendBilletEmails = (): boolean => {
    const filtersToResendEmail = [
      'all_situations',
      'released',
      'generated_billet',
      undefined, //the initial filter active is undefind in the first render
      null, //the filter active is null after click in active payments
    ];

    const isEnabledFilter = filtersToResendEmail.includes(filters.status);
    const isResendBilletActive = nonPaymentActiveFlags.includes(
      flags.RESEND_BILLET_EMAIL
    );

    return isResendBilletActive && isEnabledFilter;
  };

  return (
    <FilterContainer
      form={form}
      formMeta={formMeta}
      testid="recurrentFilterWrapper"
    >
      <DoubleInputsWrapper>
        <Field
          testid="rangeDate"
          label="Periodo"
          className="dateRange"
          type="rangeDate"
          attributeName="range"
          onSubmit={(e) => canFilterDateRanger(e)}
          showClearDates={false}
          enableSameDate
        />
        <Field
          testid="status"
          label="Situação"
          attributeName="status"
          type="selectWithIcon"
          value={formStatus}
          options={metaStatus}
          onChange={(e) => handleFilterSubmit({ status: e.value, page: 1 })}
          fullWidth
          withArrowSelect
        />
      </DoubleInputsWrapper>
      <DoubleInputsWrapper>
        <HalfInputWrapper>
          <Search
            testid="titleOrNameSearch"
            withSelect
            label="Buscar"
            placeholder="Digite o nome do aluno"
            attributeName="recurrent_title_or_student_name"
            selectOptions={recurrentSearchType}
            onSubmit={(params) => {
              handleFilterSubmit({
                recurrent_search_type: params.selectedOption,
                recurrent_title_or_student_name:
                  params.recurrent_title_or_student_name,
                page: 1,
              });
            }}
          />
          <div className="last-button-wrapper">
            <ButtonAndSideFilter
              handleFilter={(params) => {
                handleFilterSubmit({
                  headquarter_id: params.headquarter_id,
                  educational_stage_id: params.educational_stage_id,
                  classroom_id: params.classroom_id,
                  plan_kind: params.plan_kind,
                  page: 1,
                  recurrent_product_ids: params.recurrent_product_ids,
                });
              }}
              amount={amount}
            />
            {canResendBilletEmails() && <ResendBilletButton />}
          </div>
        </HalfInputWrapper>
      </DoubleInputsWrapper>
    </FilterContainer>
  );
};

export default RecurrentFilters;
