import React from 'react';
import PropTypes from 'prop-types';

import reduxProvider from 'core/hoc/reduxProvider';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import NewEvent from 'components/Events/NewEvent';
import EditEvent from 'components/Events/EditEvent';

const EventsMainScreen = ({ googleClientId }) => (
  <div className="EventsMainsScreen">
    <BrowserRouter>
      <Switch>
        <Route
          exact
          path="/*/events/new"
          render={() => <NewEvent googleClientId={googleClientId} />}
        />

        <Route
          exact
          path="/*/events/:id/edit"
          render={(props) => (
            <EditEvent googleClientId={googleClientId} {...props} />
          )}
        />
      </Switch>
    </BrowserRouter>
  </div>
);

EventsMainScreen.propTypes = {
  googleClientId: PropTypes.string.isRequired,
};

export default reduxProvider(EventsMainScreen);
