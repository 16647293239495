import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const Form = ({ variation, children, onSubmit }) => (
  <form className={`Form ${variation}`} onSubmit={onSubmit}>
    {children}
  </form>
);

Form.defaultProps = {
  variation: 'vertical',
};

Form.propTypes = {
  children: PropTypes.node.isRequired,
  variation: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
};

export default Form;
