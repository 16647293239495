import styled, { css } from 'styled-components';
import { ThemeProps } from 'config/themes/types';
import { DefaultThemeProps } from '@agendaedu/ae-web-components';

export const DetailTabsWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const TabWrapper = styled.div`
  ${({ theme: { border, space } }: ThemeProps) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: ${border.radius.md};
    box-shadow: 0px 0px 4px 0px #00000024;

    .react-tabs__tab-list {
      height: ${space.xl3};
      margin-bottom: 0 !important;

      li {
        height: ${space.xl3};

        span {
          display: block;
          padding: ${space.xs} ${space.lg};
        }
      }

      .tab-style.selected-tab:after {
        height: ${space.xs3};
        margin-top: 0 !important;
      }
    }
  `}
`;

export const EmptyStateWrapper = styled.div`
  ${({ theme: { colors, font } }: ThemeProps) => css`
    display: flex;
    align-items: center;
    justify-content: center;

    line-height: 27.2px;
    text-align: center;
    font-family: ${font.family.brand};
    font-size: ${font.size.md};
    font-weight: ${font.weight.medium};
    color: ${colors.neutral.black};
  `}
`;

export const DiaryContentWrapper = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    flex-direction: column;
    gap: ${space.xl};
    padding: ${space.xl};
  `}
`;

export const TitleWrapper = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    align-items: center;
    gap: ${space.sm};
  `}
`;

export const DetailsContainer = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    flex-direction: column;
    gap: ${space.lg};
  `}
`;

export const DailyEntryTextWrapper = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    flex-direction: column;
    gap: ${space.xs};
  `}
`;

export const DiaryDetailTabSkeleton = styled.div`
  min-height: 100%;
  width: 100%;
`;

export const TabsSkeleton = styled.div`
  ${({ theme: { border, space } }: ThemeProps) => css`
    padding: ${space.xs4};
    border-radius: ${border.radius.md};
    box-shadow: 0 0 10px 0 rgba(51, 51, 51, 0.15);
  `}
`;

export const TabsHeaderSkeleton = styled.div`
  ${({ theme: { border, colors } }: ThemeProps) => css`
    height: 52px;
    border-bottom: ${border.width.sm} ${border.style.solid}
      ${colors.neutral.gray4};

    display: flex;
    align-items: center;
  `}
`;

export const TabSkeleton = styled.div`
  ${({ theme: { colors, space } }) => css`
    height: 52px;
    padding: ${space.sm} ${space.lg} ${space.xs4} ${space.lg};

    position: relative;

    &::after {
      content: '';
      position: absolute;
      bottom: ${space.xs4};
      right: ${space.xs4};
      left: ${space.xs4};
      width: 100%;
      height: ${space.xs3};
      border-radius: ${space.xs3} ${space.xs3} ${space.xs4} ${space.xs4};
      background-color: ${colors.neutral.gray3};
    }
  `}
`;

export const FileWrapper = styled.div`
  ${({ theme: { colors, space, border } }: { theme: DefaultThemeProps }) => css`
    display: flex;
    align-items: center;
    padding: ${space.xs2};
    border-radius: ${border.radius.md};
    border: ${border.width.sm} ${border.style.solid} ${colors.neutral.gray4};
    background-color: ${colors.neutral.gray6};
    overflow: hidden;
    cursor: pointer;
  `}
`;

export const OccurrenceCoverWrapper = styled.div`
  overflow: hidden;
  position: relative;
  border-radius: ${({ theme: { border } }) => border.radius.sm};
`;

export const OccurrenceImage = styled.img`
  max-width: 100%;
  max-height: 400px;
  object-fit: contain;
  border-radius: ${({ theme: { border } }) => border.radius.md};
  transition: 0.5s ease all;
`;
