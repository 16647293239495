import styled, { css } from 'styled-components';
import { IMAGES_URLS } from './constants';

export const CardContainer = styled.div`
  ${({ theme }) => css`
    position: relative;
    display: block;
    padding: 32px 0px;
    width: 100%;
    background-color: ${theme.white};
    border-radius: 8px;
    overflow: hidden;

    @media (min-width: 576px) {
      padding: 32px 0px;
    }

    .EmptyState {
      gap: 48px;

      @media (min-width: 768px) {
        flex-direction: row-reverse;
      }

      img {
        margin-bottom: 0;
        @media (min-width: 768px) {
          width: 440px;
        }
      }

      div {
        margin: 0;
      }
    }
  `}
`;

export const PanelTasksContainer = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 912px;
`;

export const PanelTasks = styled.div`
  display: grid;
  grid-gap: 55px;
  grid-template-columns: repeat(auto-fill, minmax(265px, 1fr));
`;

export const PanelTasksCard = styled.div`
  padding: 24px;
  background-color: ${({ theme, status }) =>
    status !== 'unavailable' ? theme.white : theme.gray14};
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  border-radius: 8px;

  .Button {
    margin-top: 24px;
  }
`;

export const TaskThumbnail = styled.div`
  width: 100%;

  img {
    width: 100%;
  }

  .animated-task-thumbnail {
    background-image: url(${IMAGES_URLS.taskThumbUrl.background});
    background-size: contain;
    background-repeat: no-repeat;
  }
`;

export const Heading = styled.h4`
  font-family: 'Quicksand', sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 100%;
  color: ${({ status, theme }) =>
    status !== 'unavailable' ? theme.black : theme.gray2};
`;

export const Paragraph = styled.p`
  margin: 0;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 170%;
  color: ${({ status, theme }) =>
    status !== 'unavailable' ? theme.gray2 : theme.gray1};
`;
