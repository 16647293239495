import React, { useEffect, useState } from 'react';
import _ from 'lodash';

import { useTranslation } from 'react-i18next';

import * as S from './styles';

import {
  Box,
  Button,
  Checkbox,
  Text,
  TextField,
} from '@agendaedu/ae-web-components';
import { Header } from '../components/Header';
import { Props } from './types';

export const BabyBottleCard = ({
  babyBottles,
  onChange,
  student,
}: Props): React.ReactElement => {
  const { t } = useTranslation(['diary_sections']);

  const [babyBottlesValues, setBabyBottleValues] = useState(babyBottles);

  const handleNotDrink = () =>
    setBabyBottleValues((prev) => {
      const newValue = {
        ...prev,
        not_drink: !prev.not_drink,
      };

      onChange(newValue);
      return newValue;
    });

  const addQuantity = () => {
    setBabyBottleValues((prev) => {
      const newValue = {
        ...prev,
        quantities: [...prev.quantities, { quantity: '' }],
      };

      onChange(newValue);
      return newValue;
    });
  };

  const handleQuantity = (value: string, index: number) => {
    const newBabyBottleValues = _.cloneDeep(babyBottlesValues);

    newBabyBottleValues.quantities[index].quantity = value;

    onChange(newBabyBottleValues);
    setBabyBottleValues(newBabyBottleValues);
  };

  const removeQuantity = (index: number) => {
    const newBabyBottleValues = _.cloneDeep(babyBottlesValues);

    newBabyBottleValues.quantities.splice(index, 1);

    onChange(newBabyBottleValues);
    setBabyBottleValues(newBabyBottleValues);
  };

  useEffect(() => {
    setBabyBottleValues(babyBottles);
  }, [babyBottles]);

  return (
    <S.BabyBottleWrapper data-testid="baby-bottles-card">
      <S.RowWrapper>
        <Header
          sectionTitle={t('sections.card.baby_bottle.title')}
          student={student}
        />

        <Checkbox
          data-testid="not-drink-checkbox"
          checked={babyBottlesValues.not_drink}
          onChange={handleNotDrink}
        >
          <Text variant="label-regular-16" marginBottom="0">
            {t('sections.card.baby_bottle.checkbox_label')}
          </Text>
        </Checkbox>
      </S.RowWrapper>
      {!babyBottlesValues.not_drink && (
        <>
          <S.QuantitiesWrapper>
            {babyBottlesValues.quantities.map(({ quantity }, index) => (
              <Box key={index} maxWidth="250px" display="flex">
                <TextField
                  data-testid="quantity-input"
                  label={t('sections.card.baby_bottle.label')}
                  placeholder={t('sections.card.baby_bottle.placeholder')}
                  value={quantity}
                  onChange={(event) =>
                    handleQuantity(event.target.value, index)
                  }
                />

                {babyBottlesValues.quantities.length > 1 && (
                  <Button
                    isOnlyIcon
                    ml="xs3"
                    variant="secondary"
                    mt="auto"
                    contextVariant="alert"
                    icon="trash-bin"
                    onClick={() => removeQuantity(index)}
                  />
                )}
              </Box>
            ))}
          </S.QuantitiesWrapper>

          <Button
            isOnlyIcon
            variant="secondary"
            icon="plus"
            onClick={addQuantity}
          />
        </>
      )}
    </S.BabyBottleWrapper>
  );
};
