import styled, { css } from 'styled-components';

export const Container = styled.div``;

export const Label = styled.span`
  ${({ theme }) => css`
    color: ${theme.gray2};
    font-family: 'Roboto';
    font-size: 14px;
    line-height: 24px;
  `}
`;

export const Text = styled.span`
  ${({ theme }) => css`
    font-family: 'Roboto';
    font-size: 12px;
    line-height: 22px;
    margin-top: 12px;
    color: ${theme.gray2};
  `}
`;

export const LabelBlack = styled.span`
  ${({ theme }) => css`
    color: ${theme.black};
    font-family: 'Roboto';
    font-size: 16px;
    margin-top: 12px;
    line-height: 100%;
  `}
`;

export const Line = styled.hr`
  ${({ theme }) => css`
    color: ${theme.gray12};
  `}
`;
export const AccountInfo = styled.div`
  display: flex;
  margin-top: 24px;
  flex-direction: column;
`;

export const SpaceBetweenContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TransferContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;

export const WalletDestinationContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 22px;
`;

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

export const TransferValueContainer = styled.div`
  display: flex;
  margin-top: 16px;
  justify-content: space-between;
`;
