import {
  Alert,
  Box,
  Button,
  DefaultThemeProps,
  Modal,
} from '@agendaedu/ae-web-components';
import React, { useContext, useMemo, useState } from 'react';
import _ from 'lodash';
import { Props } from './types';
import { useTranslation } from 'react-i18next';
import StudentsList from 'pages/dailySummaries/DiaryForm/StudentsList';
import { useSelector } from 'react-redux';
import { DiaryFormStructure, DiaryState } from 'store/dailySummaries/types';
import { DiarySectionsFormContext } from 'core/contexts/DiaryForm/SectionsForm';

import {
  defaultAnswersAttributesValues,
  defaultBabyBottles,
  defaultEvacuations,
  defaultMeal,
  defaultOccurrence,
  defaultSleeps,
} from 'core/contexts/DiaryForm/constants';
import { MealsGroup } from './components/MealsGroup';
import { OccurrencesGroup } from './components/OccurrencesGroup';
import {
  BabyBottleCard,
  DiaperCard,
  EvacuationCard,
  isEmptyCustomValues,
  isEmptyMeal,
  isEmptyOccurrence,
  ObservationCard,
  ShowerCard,
  SleepCard,
} from 'pages/diarySectionsV3/Sections/components/ShowSectionModal/Sections';
import { CustomGroup } from './components/CustomGroup';
import { useTheme } from 'styled-components';

const getInitialValues = (): Omit<
  DiaryFormStructure['studentsForm'],
  'notify' | 'occurrences_to_destroy'
> => ({
  meals: [defaultMeal()],
  baby_bottles: defaultBabyBottles(),
  sleeps: defaultSleeps(),
  evacuations: defaultEvacuations(),
  diaper: 0,
  shower: 0,
  occurrences: [defaultOccurrence()],
  observation: '',
  answers_attributes: {},
});

export const FillBatchModal = ({ onClose }: Props) => {
  const { breakpoints } = useTheme() as DefaultThemeProps;

  const { t } = useTranslation(['diary', 'common']);
  const {
    diarySections: { activesSections },
    classroom: {
      attributes: {
        student_profiles: { data: students },
      },
    },
  } = useSelector((state: DiaryState) => state.dailySummaries);

  const initialValues = useMemo(() => {
    const structuredInitialInfoValues = _.cloneDeep(getInitialValues());

    for (const section of activesSections) {
      if (section.custom) {
        structuredInitialInfoValues.answers_attributes[section.id] = [[]];

        for (const question of section.questionsAttributes) {
          structuredInitialInfoValues.answers_attributes[section.id][0].push(
            defaultAnswersAttributesValues(question.id)
          );
        }
      }
    }

    return structuredInitialInfoValues;
  }, [activesSections]);

  const [isApplyingInfos, setIsApplyingInfos] = useState(false);
  const [sectionValue, setSectionValue] = useState(initialValues);
  const [studentsIdsSelecteds, setStudentsIdsSelecteds] = useState<string[]>(
    []
  );

  const {
    currentSectionId,
    form: { values, setFieldValue },
  } = useContext(DiarySectionsFormContext);

  const closeModal = () => {
    setStudentsIdsSelecteds([]);
    setSectionValue(getInitialValues());
    setIsApplyingInfos(false);
    onClose();
  };

  const updateDiariesStudents = async () => {
    const studentsDiariesClone = _.cloneDeep(values.studentsDiaries);

    const updateAction = async (studentId: string) => {
      const update = {
        meals: () => {
          const currentValue = studentsDiariesClone[studentId].meals;

          const isAllEmpty = currentValue.every((meal) => isEmptyMeal(meal));

          if (isAllEmpty) {
            studentsDiariesClone[studentId].meals = sectionValue.meals;
          } else {
            studentsDiariesClone[studentId].meals = [
              ...currentValue,
              ...sectionValue.meals,
            ];
          }
        },
        baby_bottle: () => {
          const currentValue = studentsDiariesClone[studentId].baby_bottles;

          const isAllEmpty = currentValue.quantities.every(
            (babyBottle) => !babyBottle.quantity
          );

          currentValue.not_drink = sectionValue.baby_bottles.not_drink;

          if (isAllEmpty) {
            currentValue.quantities = sectionValue.baby_bottles.quantities;
          } else {
            currentValue.quantities = [
              ...currentValue.quantities,
              ...sectionValue.baby_bottles.quantities,
            ];
          }
        },
        sleep: () => {
          const currentValue = studentsDiariesClone[studentId].sleeps;

          const isAllEmpty = currentValue.periods.every(
            (period) => !period.start_time && !period.end_time
          );

          currentValue.not_slept = sectionValue.sleeps.not_slept;

          if (isAllEmpty) {
            currentValue.periods = sectionValue.sleeps.periods;
          } else {
            currentValue.periods = [
              ...currentValue.periods,
              ...sectionValue.sleeps.periods,
            ];
          }
        },
        evacuation: () => {
          const currentValue = studentsDiariesClone[studentId].evacuations;

          const isAllEmpty = currentValue.appearances.every(
            (appearance) => !appearance.evacuation
          );

          currentValue.not_evacuated = sectionValue.evacuations.not_evacuated;

          if (isAllEmpty) {
            currentValue.appearances = sectionValue.evacuations.appearances;
          } else {
            currentValue.appearances = [
              ...currentValue.appearances,
              ...sectionValue.evacuations.appearances,
            ];
          }
        },
        diaper: () => {
          studentsDiariesClone[studentId].diaper = sectionValue.diaper;
        },
        shower: () => {
          studentsDiariesClone[studentId].shower = sectionValue.shower;
        },
        occurrence: () => {
          const currentValuesStudent = studentsDiariesClone[studentId];

          const isAllEmpty = currentValuesStudent.occurrences.every(
            (occurrence) => isEmptyOccurrence(occurrence)
          );

          if (isAllEmpty) {
            currentValuesStudent.occurrences = sectionValue.occurrences;
          } else {
            currentValuesStudent.occurrences = [
              ...currentValuesStudent.occurrences,
              ...sectionValue.occurrences,
            ];
          }
        },
        observation: () => {
          const currentValue = studentsDiariesClone[studentId].observation;

          if (currentValue) {
            studentsDiariesClone[studentId].observation = studentsDiariesClone[
              studentId
            ].observation
              .concat('<br><br>')
              .concat(sectionValue.observation);
          } else {
            studentsDiariesClone[studentId].observation =
              sectionValue.observation;
          }
        },
        custom: () => {
          const currentValue =
            studentsDiariesClone[studentId].answers_attributes[
              currentSectionId
            ];

          const isAllEmpty = currentValue.every((question) =>
            isEmptyCustomValues(question)
          );

          const newValues = sectionValue.answers_attributes[currentSectionId];

          if (isAllEmpty) {
            studentsDiariesClone[studentId].answers_attributes[
              currentSectionId
            ] = newValues;
          } else {
            studentsDiariesClone[studentId].answers_attributes[
              currentSectionId
            ] = [...currentValue, ...newValues];
          }
        },
      };

      if (currentSectionId in update) {
        update[currentSectionId]();
      } else {
        update.custom();
      }

      return await setFieldValue('studentsDiaries', studentsDiariesClone);
    };

    for (const studentId of studentsIdsSelecteds) {
      await updateAction(studentId);
    }

    closeModal();
  };

  const getSectionCard = useMemo(() => {
    const sectionsComponents = {
      meals: () => (
        <MealsGroup
          meals={sectionValue.meals}
          onChange={(meals) => setSectionValue((prev) => ({ ...prev, meals }))}
        />
      ),
      baby_bottle: () => (
        <BabyBottleCard
          babyBottles={sectionValue.baby_bottles}
          onChange={(baby_bottles) =>
            setSectionValue((prev) => ({ ...prev, baby_bottles }))
          }
        />
      ),
      sleep: () => (
        <SleepCard
          sleeps={sectionValue.sleeps}
          onChange={(sleeps) =>
            setSectionValue((prev) => ({ ...prev, sleeps }))
          }
        />
      ),
      evacuation: () => (
        <EvacuationCard
          evacuations={sectionValue.evacuations}
          onChange={(evacuations) =>
            setSectionValue((prev) => ({ ...prev, evacuations }))
          }
        />
      ),
      diaper: () => (
        <DiaperCard
          diaper={sectionValue.diaper}
          onChange={(diaper) =>
            setSectionValue((prev) => ({ ...prev, diaper }))
          }
        />
      ),
      shower: () => (
        <ShowerCard
          shower={sectionValue.shower}
          onChange={(shower) =>
            setSectionValue((prev) => ({ ...prev, shower }))
          }
        />
      ),
      occurrence: () => (
        <OccurrencesGroup
          occurrences={sectionValue.occurrences}
          onChange={(occurrences) =>
            setSectionValue((prev) => ({ ...prev, occurrences }))
          }
        />
      ),
      observation: () => (
        <ObservationCard
          observation={sectionValue.observation}
          onChange={(observation) =>
            setSectionValue((prev) => ({ ...prev, observation }))
          }
        />
      ),
      custom: () => (
        <CustomGroup
          currentSectionId={currentSectionId}
          answers_attributes={sectionValue.answers_attributes}
          activesSections={activesSections}
          onChange={(answers_attributes) =>
            setSectionValue((prev) => ({ ...prev, answers_attributes }))
          }
        />
      ),
    };

    if (currentSectionId in sectionsComponents) {
      return sectionsComponents[currentSectionId];
    } else {
      return sectionsComponents.custom;
    }
  }, [
    activesSections,
    currentSectionId,
    sectionValue.answers_attributes,
    sectionValue.baby_bottles,
    sectionValue.diaper,
    sectionValue.evacuations,
    sectionValue.meals,
    sectionValue.observation,
    sectionValue.occurrences,
    sectionValue.shower,
    sectionValue.sleeps,
  ]);

  const hasSomeStudentSelectedWithDiary = useMemo(
    () =>
      studentsIdsSelecteds.length > 1 &&
      students.some(
        (student) =>
          studentsIdsSelecteds.includes(student.id) &&
          student.attributes.has_daily_summary
      ),
    [students, studentsIdsSelecteds]
  );

  return (
    <Modal onClose={closeModal} isOpen title={t('form.fill_batch.title')}>
      <Box
        display="flex"
        flexDirection="column"
        maxHeight="80vh"
        overflow="auto"
        px="lg"
        pb="30%"
        gap="sm"
      >
        <Box data-testid="batch-fill-students">
          <StudentsList
            studentsIdsSelecteds={studentsIdsSelecteds}
            setStudentsIdsSelecteds={setStudentsIdsSelecteds}
            students={students}
            isCheckable
            placeholder={t('form.fill_batch.search')}
            multiSelect
            size="sm"
          />
        </Box>

        {hasSomeStudentSelectedWithDiary && (
          <Box maxWidth={breakpoints.tabletMD}>
            <Alert variant="informative">{t('form.fill_info')}</Alert>
          </Box>
        )}

        <Box
          data-testid="batch-fill-section"
          display="flex"
          flexDirection="column"
          gap="sm"
        >
          {getSectionCard()}
        </Box>
      </Box>

      <Box display="flex" width="fit-content" ml="auto" gap="md">
        <Button variant="secondary" onClick={closeModal}>
          {t('common:button.back')}
        </Button>
        <Button
          data-testid="batch-fill-apply-button"
          disabled={!studentsIdsSelecteds.length || isApplyingInfos}
          onClick={async () => {
            setIsApplyingInfos(true);

            setTimeout(() => {
              updateDiariesStudents();
            }, 200);
          }}
        >
          {isApplyingInfos
            ? t('form.fill_batch.applying')
            : t('form.fill_batch.apply')}
        </Button>
      </Box>
    </Modal>
  );
};
