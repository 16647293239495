import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  Box,
  Button,
  DefaultThemeProps,
  FullScreenModal,
  Grid,
  SingleDate,
  Text,
} from '@agendaedu/ae-web-components';

import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { withRouter } from 'react-router';
import withAppContext from 'core/hoc/withAppContext';

import moment from 'moment';
import formatDatetime from 'core/utils/formatDatetime';
import { DiaryState } from 'store/dailySummaries/types';

import { InfoConfirmModal } from '../InfoConfirmModal';
import { DiscardModal } from '../DiscardModal';
import DiaryActions from 'store/dailySummaries/actions';
import { NotificationModal } from '../NotificationModal';

import { DiarySectionsFormContext } from 'core/contexts/DiaryForm/SectionsForm';
import SectionsList from './components/SectionsList';

import * as S from './styles';

import { StudentsList } from './components/StudentsList';

import { Props, SelectSectionDiscardModal } from './types';

export const SectionsForm = ({
  appContext,
  history: { goBack },
  setFillType,
}: Props) => {
  const { fetchClassroom, fetchStudentsDiariesForm } = DiaryActions;
  const dispatch = useDispatch();
  const [showNotificationModal, setShowNotificationModal] = useState(false);
  const [showDiscardModal, setShowDiscardModal] = useState(false);

  const { border, colors } = useTheme() as DefaultThemeProps;
  const [showInfoConfirmModal, setShowInfoConfirmModal] = useState(
    appContext.showDiaryModal
  );
  const [selectSectionDiscardModal, setSelectSectionDiscardModal] =
    useState<SelectSectionDiscardModal>({
      confirmAction: null,
    });

  const {
    form: { dirty, isValid },
    date,
    setDate,
    isSaving,
    classroomId,
  } = useContext(DiarySectionsFormContext);

  const {
    classroom: {
      attributes: {
        name,
        student_profiles: { data: students },
      },
    },
    classroomIncluded,
  } = useSelector((state: DiaryState) => state.dailySummaries);

  const getClassroomIncludedNames = (type) => {
    return classroomIncluded.find((info) => info.type === type)?.attributes
      .name;
  };

  const educationalStageName = getClassroomIncludedNames('educational_stage');
  const headquarterName = getClassroomIncludedNames('headquarter');

  const schoolSubtitle =
    !educationalStageName || !headquarterName
      ? ''
      : `${headquarterName} / ${educationalStageName}`;

  const { t } = useTranslation(['diary', 'common']);

  const tBase = useCallback((key: string) => t(`form.${key}`), [t]);

  const changeFillType = () => {
    if (dirty) {
      return setSelectSectionDiscardModal({
        confirmAction: () => {
          setFillType('students');
        },
      });
    }

    setFillType('students');
  };

  const isWithinLastSevenDays = (day: moment.Moment) =>
    !day.isBetween(
      moment().subtract(8, 'days'),
      moment().add(1, 'days'),
      'day'
    );

  const handleChangeDate = (date: moment.Moment) => {
    if (!date) return setDate(moment(new Date()));

    return setDate(date);
  };

  const disableSaveButton = [!dirty, !isValid, isSaving].some(Boolean);

  const footer = (
    <Button
      data-testid="notify-button"
      ml="auto"
      disabled={disableSaveButton}
      onClick={() => setShowNotificationModal(true)}
    >
      {t('common:button.save')}
    </Button>
  );

  const capitalizeDate = (text: string) =>
    text.charAt(0).toUpperCase() + text.slice(1);

  const shouldOpenDiscardModal = dirty;

  useEffect(() => {
    dispatch(fetchClassroom(classroomId, date.format('YYYY-MM-DD')));
  }, [classroomId, date, dispatch, fetchClassroom, students?.length]);

  useEffect(() => {
    dispatch(
      fetchStudentsDiariesForm({ classroomId, date: date.format('YYYY-MM-DD') })
    );
  }, [classroomId, date, dispatch, fetchStudentsDiariesForm]);

  const header = (
    <Box
      display="block"
      flexDirection={{ _: 'column', tabletMD: 'row' }}
      px={{ mobileXS: 'xl', desktopLG: 'xl5', desktopXL: '15%' }}
      mt={{ _: 0, tabletMD: 'xl' }}
      mb="md"
    >
      <Grid {...S.gridValuesWrapper}>
        <Grid item gridArea="date-label">
          <Text variant="title-bold-20" color="neutral.black" mb={0}>
            {capitalizeDate(formatDatetime(date, tBase('date')))}
          </Text>
        </Grid>

        <Grid item gridArea="classroom">
          <Box mt={{ mobileXS: 'lg', tabletMD: 0 }}>
            <Text variant="title-bold-20" color="neutral.black">
              {name}
            </Text>
            <Text variant="subtitle-medium-14">{schoolSubtitle}</Text>
          </Box>
        </Grid>

        <Grid item gridArea="date-picker">
          <Box ml={{ tabletMD: 'auto' }} mb={{ mobileXS: 'md', tabletMD: 0 }}>
            <SingleDate
              id="diary-date"
              value={date}
              handleChange={handleChangeDate}
              handleOutsideRange={(day) => isWithinLastSevenDays(day)}
            />
          </Box>
        </Grid>

        <Grid item gridArea="change-type">
          <Box ml={{ tabletMD: 'auto' }}>
            <Button
              data-testid="change-fill-type-button"
              icon="sign-left-right"
              variant="secondary"
              onClick={changeFillType}
            >
              {tBase('change_fill_type')}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );

  return (
    <>
      <FullScreenModal
        isOpen
        title={tBase('title')}
        onClose={() =>
          shouldOpenDiscardModal ? setShowDiscardModal(true) : goBack()
        }
      >
        <Box
          display="flex"
          overflow="hidden"
          flexDirection="column"
          height="100%"
        >
          <Box display="flex" overflow="auto" flexDirection="column">
            {header}

            <Box mt="sm" pl={{ desktopLG: 'xl5', desktopXL: '15%' }}>
              <Box
                display="flex"
                flexDirection={{ _: 'column', desktopLG: 'row' }}
                px={{ mobileXS: 'xl', desktopLG: 0 }}
              >
                <Box
                  display="flex"
                  flex={1}
                  flexDirection="column"
                  overflow="hidden"
                >
                  <SectionsList />
                </Box>

                <Box
                  display="flex"
                  flex={2}
                  flexDirection="column"
                  ml={{ _: 0, desktopLG: 'lg' }}
                  mt={{ _: 'lg', desktopLG: 0 }}
                  pr={{ desktopLG: 'xl5', desktopXL: '15%' }}
                >
                  <StudentsList />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box
          py="sm"
          px={{ _: 'lg', mobileXS: 'xs2', desktopLG: 'xl5' }}
          borderTop={`${border.width.sm} ${border.style.solid} ${colors.neutral.gray4}`}
          position="fixed"
          bottom={0}
          width="100%"
          backgroundColor={colors.neutral.white}
        >
          {footer}
        </Box>
      </FullScreenModal>

      <DiscardModal
        isOpen={showDiscardModal}
        onClose={() => setShowDiscardModal(false)}
        onConfirmDiscard={goBack}
      />

      <InfoConfirmModal
        isOpen={showInfoConfirmModal}
        onClose={() => setShowInfoConfirmModal(false)}
      />

      <NotificationModal
        formFillType="sections"
        isOpen={showNotificationModal}
        onClose={() => setShowNotificationModal(false)}
      />

      <DiscardModal
        isOpen={!!selectSectionDiscardModal.confirmAction}
        onClose={() => setSelectSectionDiscardModal({ confirmAction: null })}
        onConfirmDiscard={() => {
          selectSectionDiscardModal.confirmAction();
          setSelectSectionDiscardModal({ confirmAction: null });
        }}
      />
    </>
  );
};

export default withAppContext(withRouter(SectionsForm));
