import createModuleReducer from '../storeConfig/createModuleReducer';
import { phoneMask } from 'core/utils/masks.js';
import actions from './actions';

const setSelectedStudentProfileIds = (options, value) => {
  let selected = [];

  options.map((option) => {
    if (value.includes(parseInt(option.id))) {
      selected.push({
        label: option.attributes.name,
        value: option.id,
      });
    }
  });

  return selected;
};

const setSelectedKinship = (options, value) => {
  const seleted = options.find((option) => option.value == value);
  return {
    label: seleted.name,
    value: seleted.value,
  };
};

export const INITIAL_STATE = {
  error: null,
  isLoading: false,
  isFetchStudents: false,
  isSubmitting: false,
  studentProfiles: [],
  form: {
    legacy_id: '',
    name: '',
    document_number: '',
    phone: '',
    email: '',
    username: '',
    student_profile_ids: [],
    kinship: '',
    cep: '',
    street: '',
    street_number: '',
    complement: '',
    neighborhood: '',
    city: '',
    state: '',
    financial: false,
  },
  formMeta: {
    nextStepDisabled: false,
    nextStepRequiredFields: ['name', 'student_profile_ids'],
    stateSelectOptions: [],
    kinshipSelectOptions: [],
    nextReviewProfileId: null,
  },
};

export const fetchFormMeta = (state) => ({
  ...state,
  isLoading: true,
});

export const fetchFormMetaSuccess = (state, action) => ({
  ...state,
  isLoading: false,
  formMeta: {
    ...state.formMeta,
    stateSelectOptions: action.meta.states,
    kinshipSelectOptions: action.meta.kinships,
  },
});

export const fetchStudentProfiles = (state) => ({
  ...state,
  isFetchStudents: true,
});

export const fetchStudentProfilesSuccess = (state, action) => ({
  ...state,
  isFetchStudents: false,
  studentProfiles: action.data,
});

export const setResponsibleProfile = (state) => ({
  ...state,
  isLoading: true,
});

export const setResponsibleProfileSuccess = (state, action) => {
  const { studentProfilesData, responsibleProfileData, meta } = action;
  const { states, kinships } = meta;
  const { student_profile_ids, kinship, phone, billing_information } =
    responsibleProfileData.attributes;

  const selectedStudentProfileIds = setSelectedStudentProfileIds(
    studentProfilesData,
    student_profile_ids
  );

  const selectedKinship = setSelectedKinship(kinships, kinship);

  const maskedPhone = phone ? phoneMask(phone) : phone;

  return {
    ...state,
    isLoading: false,
    studentProfiles: studentProfilesData,
    form: {
      ...responsibleProfileData.attributes,
      student_profile_ids: selectedStudentProfileIds,
      kinship: selectedKinship,
      phone: maskedPhone,
      document_number: billing_information.document_number,
      cep: billing_information.cep,
      street: billing_information.street,
      street_number: billing_information.street_number,
      complement: billing_information.complement,
      neighborhood: billing_information.neighborhood,
      city: billing_information.city,
      state: billing_information.state,
    },
    formMeta: {
      ...state.formMeta,
      stateSelectOptions: states,
      kinshipSelectOptions: kinships,
    },
  };
};

export const setRecoveryResponsibleProfile = (state) => ({
  ...state,
  isLoading: true,
});

export const setRecoveryResponsibleProfileSuccess = (state, action) => {
  const { responsible, students, meta } = action;
  const { billing_information } = responsible.attributes;

  const selectedStudentProfileIds = setSelectedStudentProfileIds(
    students,
    responsible.attributes.student_profile_ids
  );

  const selectedKinship = setSelectedKinship(
    meta.kinships,
    responsible.attributes.kinship
  );

  const maskedPhone = responsible.attributes.phone
    ? phoneMask(responsible.attributes.phone)
    : responsible.attributes.phone;

  return {
    ...state,
    isLoading: false,
    studentProfiles: students,
    form: {
      ...responsible.attributes,
      student_profile_ids: selectedStudentProfileIds,
      kinship: selectedKinship,
      phone: maskedPhone,
      document_number: billing_information.document_number,
      cep: billing_information.cep,
      street: billing_information.street,
      street_number: billing_information.street_number,
      complement: billing_information.complement,
      neighborhood: billing_information.neighborhood,
      city: billing_information.city,
      state: billing_information.state,
    },
    formMeta: {
      ...state.formMeta,
      stateSelectOptions: meta.states,
      kinshipSelectOptions: meta.kinships,
    },
  };
};

export const setReviewerResponsibleProfile = (state) => ({
  ...state,
  isLoading: true,
});

export const setReviewerResponsibleProfileSuccess = (state, action) => {
  const { studentProfilesData, responsibleProfileData, meta } = action;
  const { states, kinships, next_review_profile_id } = meta;
  const { student_profile_ids, kinship, phone, billing_information } =
    responsibleProfileData.attributes;

  const selectedStudentProfileIds = setSelectedStudentProfileIds(
    studentProfilesData,
    student_profile_ids
  );

  const selectedKinship = setSelectedKinship(kinships, kinship);

  const maskedPhone = phone ? phoneMask(phone) : phone;

  return {
    ...state,
    isLoading: false,
    studentProfiles: studentProfilesData,
    form: {
      ...responsibleProfileData.attributes,
      student_profile_ids: selectedStudentProfileIds,
      kinship: selectedKinship,
      phone: maskedPhone,
      document_number: billing_information.document_number,
      cep: billing_information.cep,
      street: billing_information.street,
      street_number: billing_information.street_number,
      complement: billing_information.complement,
      neighborhood: billing_information.neighborhood,
      city: billing_information.city,
      state: billing_information.state,
    },
    formMeta: {
      ...state.formMeta,
      stateSelectOptions: states,
      kinshipSelectOptions: kinships,
      nextReviewProfileId: next_review_profile_id,
    },
  };
};

export const createResponsibleProfile = (state) => ({
  ...state,
  isSubmitting: true,
});

export const createResponsibleProfileSuccess = (state) => ({
  ...state,
  isSubmitting: false,
});

export const updateResponsibleProfile = (state) => ({
  ...state,
  isSubmitting: true,
});

export const updateResponsibleProfileSuccess = (state) => ({
  ...state,
  isSubmitting: false,
});

export const approveResponsibleProfile = (state) => ({
  ...state,
  isSubmitting: true,
});

export const approveResponsibleProfileSuccess = (state) => ({
  ...state,
  isSubmitting: false,
});

export const recoverResponsibleProfile = (state) => ({
  ...state,
  isSubmitting: true,
});

export const recoverResponsibleProfileSuccess = (state) => ({
  ...state,
  isSubmitting: false,
});

export const removeResponsibleProfile = (state) => ({
  ...state,
  isSubmitting: true,
});

export const removeResponsibleProfileSuccess = (state) => ({
  ...state,
  isSubmitting: false,
});

export const setError = (state, action) => ({
  ...state,
  error: action.error,
  isSubmitting: false,
  isLoading: false,
});

const HANDLERS = {
  [actions.FETCH_FORM_META]: fetchFormMeta,
  [actions.FETCH_FORM_META_SUCCESS]: fetchFormMetaSuccess,
  [actions.FETCH_STUDENT_PROFILES]: fetchStudentProfiles,
  [actions.FETCH_STUDENT_PROFILES_SUCCESS]: fetchStudentProfilesSuccess,
  [actions.SET_RESPONSIBLE_PROFILE]: setResponsibleProfile,
  [actions.SET_RESPONSIBLE_PROFILE_SUCCESS]: setResponsibleProfileSuccess,
  [actions.SET_RECOVERY_RESPONSIBLE_PROFILE]: setRecoveryResponsibleProfile,
  [actions.SET_RECOVERY_RESPONSIBLE_PROFILE_SUCCESS]:
    setRecoveryResponsibleProfileSuccess,
  [actions.SET_REVIEWER_RESPONSIBLE_PROFILE]: setReviewerResponsibleProfile,
  [actions.SET_REVIEWER_RESPONSIBLE_PROFILE_SUCCESS]:
    setReviewerResponsibleProfileSuccess,
  [actions.CREATE_RESPONSIBLE_PROFILE]: createResponsibleProfile,
  [actions.CREATE_RESPONSIBLE_PROFILE_SUCCESS]: createResponsibleProfileSuccess,
  [actions.UPDATE_RESPONSIBLE_PROFILE]: updateResponsibleProfile,
  [actions.UPDATE_RESPONSIBLE_PROFILE_SUCCESS]: updateResponsibleProfileSuccess,
  [actions.APPROVE_RESPONSIBLE_PROFILE]: approveResponsibleProfile,
  [actions.APPROVE_RESPONSIBLE_PROFILE_SUCCESS]:
    approveResponsibleProfileSuccess,
  [actions.RECOVER_RESPONSIBLE_PROFILE]: recoverResponsibleProfile,
  [actions.RECOVER_RESPONSIBLE_PROFILE_SUCCESS]:
    recoverResponsibleProfileSuccess,
  [actions.REMOVE_RESPONSIBLE_PROFILE]: removeResponsibleProfile,
  [actions.REMOVE_RESPONSIBLE_PROFILE_SUCCESS]: removeResponsibleProfileSuccess,
  [actions.SET_ERROR]: setError,
};

const responsibleProfiles = createModuleReducer(INITIAL_STATE, HANDLERS);

export default responsibleProfiles;
