import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  buildDiscount,
  formattedDiscounts,
  formattedPenality,
} from 'core/utils/edupay/functions';
import { DISCOUNT_KINDS } from 'core/constants/index';

import NewRecurrentBillInformations from 'components/Payments/Recurrent/NewRecurrentBill/NewRecurrentBillInformations';
import RecurrentPlanForm from 'components/Payments/Recurrent/RecurrentPlanForm';
import Loader from 'components/Loader';
import Toast from 'components/Toast';

import actionRecurrentBills from 'store/edupay/recurrentBills/actions';
import actionRecurrentProducts from 'store/edupay/recurrentProducts/actions';
import actionRecurrentPlans from 'store/edupay/recurrentPlans/actions';
import actionRecurrentForms from 'store/edupay/recurrentForms/actions';
import selectOptions from '../RecurrentPlanForm/SelectOptions';

import {
  NewRecurrentBillProps,
  CurrentBill,
  RecurrentPlans,
  RecurrentProducts,
} from './types';

import './styles';

const NewRecurrentBill = ({
  match: {
    params: { id: recurrentBillId, recurrent_plan_id: planId },
  },
  location: {
    state: { cancelNote },
  },
}: NewRecurrentBillProps) => {
  const dispatch = useDispatch();

  const { current, isLoading } = useSelector(
    (state: CurrentBill) => state.recurrentBills
  );

  const {
    recipientsWallet,
    isLoadingWallet,
    isLoadingPlan,
    current: currentPlan,
  } = useSelector((state: RecurrentPlans) => state.recurrentPlans);

  const { recurrentProducts, isLoading: isLoadingProducts } = useSelector(
    (state: RecurrentProducts) => state.recurrentProducts
  );

  const { formAction } = actionRecurrentForms;
  const { setPlanRequest } = actionRecurrentPlans;
  const { fetchRecurrentProducts } = actionRecurrentProducts;
  const { fetchRecurrentBill, cancelAndCreateNewBillRequest } =
    actionRecurrentBills;

  const enableFormAction = useCallback(() => {
    dispatch(formAction('edit'));
  }, [formAction, dispatch]);

  const getBill = useCallback(() => {
    dispatch(fetchRecurrentBill(planId, recurrentBillId));
  }, [dispatch, fetchRecurrentBill, recurrentBillId, planId]);

  const createNewRecurrentBill = useCallback((params) => {
    dispatch(cancelAndCreateNewBillRequest(params));
  }, []);

  const getPlan = useCallback(() => {
    dispatch(setPlanRequest(planId));
  }, [setPlanRequest, dispatch, planId]);

  const getRecurrentProducts = useCallback(() => {
    dispatch(fetchRecurrentProducts({ per_page: 'all' }));
  }, [fetchRecurrentProducts, dispatch]);

  useEffect(() => {
    enableFormAction();
    getBill();
    getPlan();
    getRecurrentProducts();
  }, []);

  const recurrentProductIds = () =>
    recurrentProducts &&
    recurrentProducts.map((recurrentProduct) => ({
      name: recurrentProduct.attributes.name,
      value: recurrentProduct.id,
    }));

  const formattedRecurrentItems = (recurrentItems) => {
    const formattedItems = [];

    recurrentItems.forEach((item) => {
      const formattedItem = {
        ...item,
        recurrent_product_id: item.recurrent_product_id,
        price_cents: item.price,
      };

      if (formattedItem.discount_kind === 'percent') {
        formattedItem.discount_percent = formattedItem.discount_value;
      } else {
        formattedItem.discount_price_cents = formattedItem.discount_value;
      }

      formattedItems.push(formattedItem);
    });

    return formattedItems;
  };

  const extractDiscount = (currentBill) => {
    const discounts = [];
    const {
      attributes: { edupay_discounts: edupayDiscounts },
    } = currentBill;

    if (edupayDiscounts) {
      edupayDiscounts.data.map(({ attributes }) => {
        discounts.push({
          kind: attributes.kind,
          discount_value:
            attributes.kind === 'percent'
              ? attributes.discount_percent
              : attributes.discount_price_cents,
          days_before_expire: attributes.days_before_expire,
          key: Math.random().toString(36).substring(7),
        });
      });
    }

    if (!discounts.length) {
      discounts.push(buildDiscount());
    }

    return discounts;
  };

  const extractPenality = (currentBill) => {
    const {
      attributes: {
        edupay_penality: { data },
      },
    } = currentBill;

    let penality = {
      fine_percent: 2.0,
      interest_percent: 1.0,
    };

    if (data) {
      penality = data.attributes;
    }

    return penality;
  };

  const onSubmit = (formContext) => {
    const { form } = formContext;

    createNewRecurrentBill({
      recurrent_bill: {
        ...form,
        recurrent_items_attributes: formattedRecurrentItems(
          form.recurrentItems
        ),
        edupay_penality_attributes: formattedPenality(form),
        edupay_discounts: formattedDiscounts(form),
        bills_quantity: currentPlan?.recurrentPlan?.bills_quantity + 1,
        message: cancelNote,
        recurrent_plan_id: planId,
        canceled_recurrent_bill_id: recurrentBillId,
      },
    });
  };

  if (!current || isLoadingPlan || isLoadingProducts || isLoadingWallet)
    return <Loader />;

  return (
    <Loader isLoading={isLoading}>
      <RecurrentPlanForm
        form={{
          due_date: null,
          allowed_payment_method: current.attributes.allowed_payment_method,
          recurrentItems: current.attributes.recurrent_items.data.map(
            ({ attributes }) => ({
              discount_kind: attributes.discount_kind,
              discount_value:
                attributes.discount_kind === 'percent'
                  ? attributes.discount_percent
                  : attributes.discount_price_cents,
              recurrent_product_id: parseInt(attributes.product_id, 10),
              price: attributes.price_cents,
              product_name: attributes.product_name,
              status: attributes.product_status,
              key: Math.random().toString(36).substring(7),
            })
          ),
          recipient_wallet_id: current.attributes.recipient_wallet_id,
          send_financial_responsible:
            current.attributes.send_financial_responsible,
          toggle_discount: current.attributes.has_discount,
          edupay_discounts: extractDiscount(current),
          toggle_penality: current.attributes.has_penality,
          edupay_penality: extractPenality(current),
          allow_credit_card_discount:
            current.attributes.edupay_discounts?.data[0]?.attributes?.allowed_payment_methods?.includes(
              'credit_card'
            ),
        }}
        formMeta={{
          hasCurrentSubscribe: current.attributes.has_current_subscribe,
          billetTax: current.attributes.billet_tax,
          pixTax: current.attributes.pix_tax,
          status: 'pending',
          beforeSendBilletReleasedAt: current.attributes.before_released_at,
          select_options: {
            ...selectOptions(current.attributes.bill_number),
            recurrent_product_id: recurrentProductIds(),
            recipientWalletOptions: recipientsWallet,
            kind: DISCOUNT_KINDS,
          },
          form_type: 'recurrent',
        }}
        steps={[NewRecurrentBillInformations]}
        onSubmit={onSubmit}
        backTo={`/schools/recurrent/recurrent_plans/${planId}`}
        titlePage="Criar nova cobrança"
        titleModal="Descartar preenchimento"
        contentModal="A criação da cobrança será cancelada e as informações preenchidas serão descartadas. Tem certeza que deseja descartar?"
      />
      <Toast />
    </Loader>
  );
};

export default NewRecurrentBill;
