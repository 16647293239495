import React from 'react';
import PropTypes from 'prop-types';
import withAppContext from 'core/hoc/withAppContext';
import withFormContext, { formPropTypes } from 'core/hoc/withFormContext';

import Field from 'components/Form/Field';

import AgendaIcon from 'components/AgendaIcon';
import Tooltip, { TOP_CENTER } from 'components/Tooltip';

import './styles.scss';

const GeneralInformations = ({
  appContext: { policies },
  onlyDescription,
  withLegacyId,
}) => {
  const {
    can_use_legacy_id: canUseLegacyId,
    school_product_legacy_id: legacyIdEnabled,
  } = policies;

  const displayInputLegacyID = () => {
    if (legacyIdEnabled) return true;

    canUseLegacyId;
  };

  const renderLegacyLabel = () => (
    <React.Fragment>
      <span>Identificador</span>
      <Tooltip
        content="Identificador do sistema de gestão (legacy_id)"
        position={TOP_CENTER}
      >
        <AgendaIcon name="help" size="small" className="custom-size" />
      </Tooltip>
    </React.Fragment>
  );

  return (
    <div className="generalInformation">
      {displayInputLegacyID() && withLegacyId && (
        <div className="row">
          <Field
            label={renderLegacyLabel()}
            placeholder=""
            attributeName="legacy_id"
          />
        </div>
      )}
      {!onlyDescription && (
        <div className="row">
          <Field label="Título*" placeholder="" attributeName="title" />
        </div>
      )}
      <div className="row">
        <Field type="textArea" label="Descrição*" attributeName="description" />
      </div>
    </div>
  );
};

GeneralInformations.propTypes = {
  onlyDescription: PropTypes.bool,
  withLegacyId: PropTypes.bool,
  ...formPropTypes,
};

GeneralInformations.defaultProps = {
  onlyDescription: false,
  withLegacyId: false,
};

export default withFormContext(withAppContext(GeneralInformations));
