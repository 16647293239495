import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Button } from '@agendaedu/ae-web-components';

import withAppContext from 'core/hoc/withAppContext';

import DropdownButton from 'components/DropdownButton';
import Tooltip from 'components/Tooltip';
import { Modal } from 'components/Handouts/Modal';
import Loader from 'components/Loader';

import { ScheduleModal } from './ScheduleModal';

import actionHandouts from 'store/handouts/actions';
import { HandoutState } from 'store/handouts/types';

import { HandoutActionButtonsProps } from './types';

import * as S from './styles';

const HandoutActionButtons = ({
  appContext: { dataArea },
}: HandoutActionButtonsProps) => {
  const { t } = useTranslation(['handouts']);
  const dispatch = useDispatch();

  const {
    current,
    showApprovalModal,
    showDeleteModal,
    showScheduleApprovalModal,
    showCancelScheduleModal,
    showResendModal,
    isCancelScheduleSending,
  } = useSelector((state: HandoutState) => state.handouts);

  const {
    toggleApprovalModal,
    toggleDeleteModal,
    toggleScheduleModal,
    toggleScheduleApprovalModal,
    toggleCancelScheduleModal,
    toggleResendModal,
    approveHandoutRequest,
    approveScheduleHandoutRequest,
    cancelScheduleHandoutRequest,
    scheduleHandoutRequest,
    deleteHandoutRequest,
    resendHandoutRequest,
  } = actionHandouts;

  const { id, userPermissions } = current;

  const canApprove = userPermissions.approve;
  const canApproveSchedule = userPermissions.approveSchedule;
  const canSchedule = userPermissions.schedule;
  const canCancelSchedule = userPermissions.cancelSchedule;
  const canResend = userPermissions.resend;
  const canEdit = userPermissions.edit;
  const canDelete = userPermissions.delete;

  const isOpenCancelScheduleModal =
    showCancelScheduleModal || isCancelScheduleSending;

  const toggleApprovalHandoutModal = useCallback(() => {
    dispatch(toggleApprovalModal());
  }, [dispatch, toggleApprovalModal]);

  const toggleDeleteHandoutModal = useCallback(() => {
    dispatch(toggleDeleteModal());
  }, [dispatch, toggleDeleteModal]);

  const toggleScheduleHandoutModal = useCallback(() => {
    dispatch(toggleScheduleModal());
  }, [dispatch, toggleScheduleModal]);

  const toggleScheduleApprovalHandoutModal = useCallback(() => {
    dispatch(toggleScheduleApprovalModal());
  }, [dispatch, toggleScheduleApprovalModal]);

  const toggleResendHandoutModal = useCallback(() => {
    dispatch(toggleResendModal());
  }, [dispatch, toggleResendModal]);

  const toggleCancelScheduleHandoutModal = useCallback(() => {
    dispatch(toggleCancelScheduleModal());
  }, [dispatch, toggleCancelScheduleModal]);

  const handleApproveHandoutRequest = useCallback(() => {
    dispatch(approveHandoutRequest(current));
    toggleApprovalHandoutModal();
  }, [dispatch, approveHandoutRequest, toggleApprovalHandoutModal, current]);

  const handleApproveScheduleRequest = useCallback(() => {
    dispatch(approveScheduleHandoutRequest(current));
    toggleScheduleApprovalHandoutModal();
  }, [
    dispatch,
    approveScheduleHandoutRequest,
    toggleScheduleApprovalHandoutModal,
    current,
  ]);

  const handleCancelScheduleRequest = useCallback(() => {
    dispatch(cancelScheduleHandoutRequest(current));
    if (isCancelScheduleSending) toggleCancelScheduleHandoutModal();
  }, [
    dispatch,
    cancelScheduleHandoutRequest,
    toggleCancelScheduleHandoutModal,
    current,
    isCancelScheduleSending,
  ]);

  const handleScheduleHandoutRequest = useCallback(
    (dateSchedule, timeSchedule) => {
      dispatch(
        scheduleHandoutRequest({
          ...current,
          dateSchedule,
          timeSchedule,
        })
      );
      toggleScheduleHandoutModal();
    },
    [dispatch, toggleScheduleHandoutModal, scheduleHandoutRequest, current]
  );

  const handleResendHandoutRequest = useCallback(() => {
    dispatch(resendHandoutRequest(current));
    toggleResendHandoutModal();
  }, [dispatch, resendHandoutRequest, toggleResendHandoutModal, current]);

  const handleDeleteHandoutRequest = useCallback(() => {
    dispatch(deleteHandoutRequest(current));
    toggleDeleteHandoutModal();
  }, [dispatch, deleteHandoutRequest, toggleDeleteHandoutModal, current]);

  const disableActionButtons = useMemo(
    () => current.status === 'waiting',
    [current]
  );

  const getApproveButtonText = disableActionButtons
    ? t('handout_details.action_buttons.waiting')
    : t('handout_details.action_buttons.approve');

  const isMobileActions = () => {
    const actions = [];

    canApprove &&
      actions.push({
        text: getApproveButtonText,
        onClick: toggleApprovalHandoutModal,
      });

    canApproveSchedule &&
      actions.push({
        text: t('handout_details.action_buttons.approve_schedule'),
        onClick: toggleScheduleApprovalHandoutModal,
      });

    canSchedule &&
      actions.push({
        text: t('handout_details.action_buttons.schedule'),
        onClick: toggleScheduleHandoutModal,
      });

    canCancelSchedule &&
      actions.push({
        text: t('handout_details.action_buttons.cancel_schedule'),
        onClick: toggleCancelScheduleHandoutModal,
      });

    canResend &&
      actions.push({
        text: t('handout_details.action_buttons.resend'),
        onClick: toggleResendHandoutModal,
      });

    canEdit &&
      actions.push({
        text: t('handout_details.action_buttons.edit'),
        path: `/${dataArea}/handouts/${id}/edit`,
        target: '_self',
      });

    canDelete &&
      actions.push({
        text: t('handout_details.action_buttons.delete'),
        onClick: toggleDeleteHandoutModal,
      });

    return actions;
  };

  const renderButtonWithTooltip = (Button: JSX.Element) => {
    if (disableActionButtons) {
      return (
        <Tooltip
          on="hover"
          position="top center"
          tooltipStyle={{ width: '180px' }}
          content={t('handout_details.sending_handout_tooltip')}
        >
          {Button}
        </Tooltip>
      );
    }

    return Button;
  };

  return (
    <Box>
      <S.DesktopActionButtons>
        <S.GroupButton>
          {canApprove &&
            renderButtonWithTooltip(
              <Button
                onClick={toggleApprovalHandoutModal}
                disabled={disableActionButtons}
              >
                {getApproveButtonText}
              </Button>
            )}

          {canApproveSchedule &&
            renderButtonWithTooltip(
              <Button
                onClick={toggleScheduleApprovalHandoutModal}
                disabled={disableActionButtons}
              >
                {t('handout_details.action_buttons.approve_schedule')}
              </Button>
            )}

          {canResend &&
            renderButtonWithTooltip(
              <Button
                onClick={toggleResendHandoutModal}
                disabled={disableActionButtons}
              >
                {t('handout_details.action_buttons.resend')}
              </Button>
            )}

          {canSchedule &&
            renderButtonWithTooltip(
              <Button
                as="button"
                variant="secondary"
                onClick={toggleScheduleHandoutModal}
                disabled={disableActionButtons}
              >
                {t('handout_details.action_buttons.schedule')}
              </Button>
            )}

          {canCancelSchedule &&
            renderButtonWithTooltip(
              <Button
                variant="secondary"
                isNegativeAction
                onClick={toggleCancelScheduleHandoutModal}
                disabled={disableActionButtons}
              >
                {t('handout_details.action_buttons.cancel_schedule')}
              </Button>
            )}

          {canEdit &&
            renderButtonWithTooltip(
              <Link to={`/${dataArea}/handouts/${id}/edit`}>
                <Button
                  as="button"
                  icon="pencil"
                  variant="secondary"
                  isOnlyIcon
                  disabled={disableActionButtons}
                />
              </Link>
            )}
        </S.GroupButton>

        {canDelete &&
          renderButtonWithTooltip(
            <Button
              icon="trash-bin"
              variant="secondary"
              isOnlyIcon
              isNegativeAction
              onClick={toggleDeleteHandoutModal}
              disabled={disableActionButtons}
            />
          )}
      </S.DesktopActionButtons>

      <S.MobileActionButtons>
        <DropdownButton
          disabled={disableActionButtons}
          text=""
          dropdownItems={isMobileActions()}
          icon="dots-vertical"
          variation="white"
          direction="up"
        />
      </S.MobileActionButtons>

      <Modal.Root
        dataTestId="approval-modal"
        isOpen={showApprovalModal}
        handleToggleModal={toggleApprovalHandoutModal}
        title={t('handout_details.modals.approval.title')}
      >
        <Modal.Content>
          {t('handout_details.modals.approval.description')}
        </Modal.Content>
        <Modal.Actions>
          <Button variant="secondary" onClick={toggleApprovalHandoutModal}>
            {t('handout_details.modals.approval.close_button_text')}
          </Button>
          <Button onClick={handleApproveHandoutRequest}>
            {t('handout_details.modals.approval.submit_button_text')}
          </Button>
        </Modal.Actions>
      </Modal.Root>

      <Modal.Root
        dataTestId="delete-modal"
        isOpen={showDeleteModal}
        handleToggleModal={toggleDeleteHandoutModal}
        title={t('handout_details.modals.delete.title')}
      >
        <Modal.Content>
          {t('handout_details.modals.delete.description')}
        </Modal.Content>
        <Modal.Actions>
          <Button variant="secondary" onClick={toggleDeleteHandoutModal}>
            {t('handout_details.modals.delete.close_button_text')}
          </Button>
          <Button isNegativeAction onClick={handleDeleteHandoutRequest}>
            {t('handout_details.modals.delete.submit_button_text')}
          </Button>
        </Modal.Actions>
      </Modal.Root>

      <Modal.Root
        dataTestId="resend-modal"
        isOpen={showResendModal}
        handleToggleModal={toggleResendHandoutModal}
        title={t('handout_details.modals.resend.title')}
      >
        <Modal.Content>
          {t('handout_details.modals.resend.description')}
        </Modal.Content>
        <Modal.Actions>
          <Button variant="secondary" onClick={toggleResendHandoutModal}>
            {t('handout_details.modals.resend.close_button_text')}
          </Button>
          <Button onClick={handleResendHandoutRequest}>
            {t('handout_details.modals.resend.submit_button_text')}
          </Button>
        </Modal.Actions>
      </Modal.Root>

      <ScheduleModal
        handleToggleModal={toggleScheduleHandoutModal}
        handleSubmit={({ dateSchedule, timeSchedule }) =>
          handleScheduleHandoutRequest(dateSchedule, timeSchedule)
        }
      />

      <Modal.Root
        dataTestId="schedule-approval-modal"
        isOpen={showScheduleApprovalModal}
        handleToggleModal={toggleScheduleApprovalHandoutModal}
        title={t('handout_details.modals.schedule_approval.title')}
      >
        <Modal.Content>
          {t('handout_details.modals.schedule_approval.description')}
        </Modal.Content>
        <Modal.Actions>
          <Button
            variant="secondary"
            onClick={toggleScheduleApprovalHandoutModal}
          >
            {t('handout_details.modals.schedule_approval.close_button_text')}
          </Button>
          <Button onClick={handleApproveScheduleRequest}>
            {t('handout_details.modals.schedule_approval.submit_button_text')}
          </Button>
        </Modal.Actions>
      </Modal.Root>

      <Modal.Root
        dataTestId="cancel-schedule-modal"
        isOpen={isOpenCancelScheduleModal}
        handleToggleModal={toggleCancelScheduleHandoutModal}
        title={t('handout_details.modals.cancel_schedule.title')}
      >
        <Modal.Content>
          {t('handout_details.modals.cancel_schedule.description')}
        </Modal.Content>
        <Modal.Actions>
          <Button
            variant="secondary"
            onClick={toggleCancelScheduleHandoutModal}
          >
            {t('handout_details.modals.cancel_schedule.close_button_text')}
          </Button>
          <S.CancelButton
            isNegativeAction
            onClick={handleCancelScheduleRequest}
            disabled={isCancelScheduleSending}
          >
            {isCancelScheduleSending ? (
              <Loader
                isLoading={isCancelScheduleSending}
                wrapperClassName="loader-cancel-schedule"
              />
            ) : (
              t('handout_details.modals.cancel_schedule.submit_button_text')
            )}
          </S.CancelButton>
        </Modal.Actions>
      </Modal.Root>
    </Box>
  );
};

export default withAppContext(HandoutActionButtons);
