const actions = {
  FETCH_REQUEST_OFFERS: 'FETCH_REQUEST_OFFERS/shopOffers',
  FETCH_SUCCESS_OFFERS: 'FETCH_SUCCESS_OFFERS/shopOffers',
  FETCH_REQUEST_CLASSROMS_SELECT_OPTIONS:
    'FETCH_REQUEST_CLASSROMS_SELECT_OPTIONS/shopOffers',
  FETCH_SUCCESS_CLASSROMS_SELECT_OPTIONS:
    'FETCH_SUCCESS_CLASSROMS_SELECT_OPTIONS/shopOffers',
  FETCH_REQUEST_CLASSROOMS_STUDENTS:
    'FETCH_REQUEST_CLASSROOMS_STUDENTS/shopOffers',
  FETCH_SUCCESS_CLASSROOMS_STUDENTS:
    'FETCH_SUCCESS_CLASSROOMS_STUDENTS/shopOffers',
  FETCH_REQUEST_OFFER_DETAILS: 'FETCH_REQUEST_OFFER_DETAILS/shopOffers',
  FETCH_SUCCESS_OFFER_DETAILS: 'FETCH_SUCCESS_OFFER_DETAILS/shopOffers',
  FETCH_REQUEST_OFFER_EDIT: 'FETCH_REQUEST_OFFER_EDIT/shopOffers',
  FETCH_SUCCESS_OFFER_EDIT: 'FETCH_SUCCESS_OFFER_EDIT/shopOffers',
  FETCH_REQUEST_OFFER_ORDERS: 'FETCH_REQUEST_OFFER_ORDERS/shopOffers',
  FETCH_SUCCESS_OFFER_ORDERS: 'FETCH_SUCCESS_OFFER_ORDERS/shopOffers',
  SET_CLASSROOMS_REQUEST: 'SET_CLASSROOMS_REQUEST/shopOffers',
  SET_CLASSROOMS_SUCCESS: 'SET_CLASSROOMS_SUCCESS/shopOffers',
  ADD_OFFER_REQUEST: 'ADD_OFFER_REQUEST/shopOffers',
  ADD_OFFER_SUCCESS: 'ADD_OFFER_SUCCESS/shopOffers',
  EDIT_OFFER_REQUEST: 'EDIT_OFFER_REQUEST/shopOffers',
  EDIT_OFFER_SUCCESS: 'EDIT_OFFER_SUCCESS/shopOffers',
  FETCH_CART_SETTINGS: 'FETCH_CART_SETTINGS/shopOffers',
  FETCH_CART_SETTINGS_SUCCESS: 'FETCH_CART_SETTINGS_SUCCESS/shopOffers',
  UPDATE_CART_SETTINGS_REQUEST: 'UPDATE_CART_SETTINGS_REQUEST/shopOffers',
  UPDATE_CART_SETTINGS_SUCCESS: 'UPDATE_CART_SETTINGS_SUCCESS/shopOffers',
  FETCH_ERROR: 'FETCH_ERROR/shopOffers',
  fetchOffers: (filter) => ({
    type: actions.FETCH_REQUEST_OFFERS,
    filter,
  }),
  fetchClassroomsSelectOptions: () => ({
    type: actions.FETCH_REQUEST_CLASSROMS_SELECT_OPTIONS,
  }),
  fetchOfferDetails: (offerId, filter) => ({
    type: actions.FETCH_REQUEST_OFFER_DETAILS,
    offerId,
    filter,
  }),
  fetchClassroomsStudents: (id) => ({
    type: actions.FETCH_REQUEST_CLASSROOMS_STUDENTS,
    id,
  }),
  setClassrooms: () => ({ type: actions.SET_CLASSROOMS_REQUEST }),
  addOfferRequest: (params) => ({
    type: actions.ADD_OFFER_REQUEST,
    params,
  }),
  editOfferRequest: (params, offerId) => ({
    type: actions.EDIT_OFFER_REQUEST,
    params,
    offerId,
  }),
  fetchOfferEdit: (id) => ({
    type: actions.FETCH_REQUEST_OFFER_EDIT,
    id,
  }),
  fetchOfferOrders: (id) => ({
    type: actions.FETCH_REQUEST_OFFER_ORDERS,
    id,
  }),
  fetchCartSettings: () => ({
    type: actions.FETCH_CART_SETTINGS,
  }),
  updateCartSettingsRequest: (params) => ({
    type: actions.UPDATE_CART_SETTINGS_REQUEST,
    params,
  }),
};

export default actions;
