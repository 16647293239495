import React, { CSSProperties } from 'react';

import { SkeletonProps } from './types';

import * as S from './styles';

const Skeleton = ({
  ariaLabel = 'Carregando...',
  borderRadius = 0,
  height,
  isFullWidth = false,
  primaryColor = '#eeeeee',
  secondaryColor = '#ecebeb',
  speed = 2,
  width,
  maxWidth,
  ...props
}: SkeletonProps) => {
  const wrapperStyle: CSSProperties = {
    width: isFullWidth ? '100%' : `${width}px`,
    maxWidth: `${maxWidth}px`,
    height: `${height}px`,
  };

  return (
    <S.SkeletonWrapper
      speed={speed}
      primaryColor={primaryColor}
      secondaryColor={secondaryColor}
      ariaLabel={ariaLabel}
      width={width}
      maxWidth={maxWidth}
      height={height}
      style={wrapperStyle}
      {...props}
    >
      <rect
        x="0"
        y="0"
        rx={borderRadius}
        ry={borderRadius}
        width={width}
        height={height}
      />
    </S.SkeletonWrapper>
  );
};

export default Skeleton;
