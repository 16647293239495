import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import tabifyForWizard from 'core/hoc/tabifyForWizard';
import withFormContext, { formPropTypes } from 'core/hoc/withFormContext';

import FormFieldset from 'components/Form/Fieldset';
import BillConfiguration from 'components/Payments/PaymentsContainer/FormSections/BillConfiguration';
import GeneralInformations from 'components/Payments/PaymentsContainer/FormSections/GeneralInformations';
import EdupayDiscount from 'components/Payments/PaymentsContainer/FormSections/EdupayDiscount';
import SimpleSummary from 'components/Payments/PaymentsContainer/FormSections/SimpleSummary';
import WalletConfiguration from 'components/Payments/PaymentsContainer/FormSections/WalletConfiguration';

import validations from './validations.js';
import Alert from 'components/Alert';

import * as S from './styles';

const EnrollmentPlanFormInformationsTab = ({
  formContext: {
    action,
    changeMeta,
    form: { price },
    formMeta: { status },
  },
}) => {
  const { t } = useTranslation(['payments', 'common']);
  const informationTabRef = useRef(null);
  const showDiscountAlert =
    action === 'edit' && !['pending', 'rejected'].includes(status);

  useEffect(() => {
    informationTabRef.current.scrollIntoView(true);
    changeMeta('nextStepDisabled', false);
  }, []);

  return (
    <div className="EnrollmentPlanFormInformationsTab" ref={informationTabRef}>
      <FormFieldset>
        <WalletConfiguration />
        <GeneralInformations onlyDescription />
        <BillConfiguration isEnrollment />
        <EdupayDiscount
          validations={validations}
          withoutTitle
          showCreditCardDiscount
          isNumericField
        />
        {showDiscountAlert && (
          <S.AlertWrapper>
            <Alert variation="info">
              {t(`form_section.alert_discount_date_not_changed`)}
            </Alert>
          </S.AlertWrapper>
        )}
        <SimpleSummary showTitle price={price} />
      </FormFieldset>
    </div>
  );
};

EnrollmentPlanFormInformationsTab.propTypes = {
  ...formPropTypes,
};

export default tabifyForWizard({
  title: '2. Cobranças',
  subTitle: 'Informações gerais',
  validations,
})(withFormContext(EnrollmentPlanFormInformationsTab));
