import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import tabify from 'core/hoc/tabify';
import smoothScrollTo from 'core/utils/smoothScrollTo';

import Loader from 'components/Loader';
import Table from 'components/Table';
import Pagination from 'components/Pagination';

// Actions
import actionEnrollmentPlans from 'store/edupay/enrollmentPlans/actions';

import table from './table';

const HistoryTab = (props) => {
  const dispatch = useDispatch();

  const { fetchHistoryEnrollmentRequest } = actionEnrollmentPlans;

  const { isLoading, paginate, history } = useSelector(
    (state) => state.enrollmentPlans
  );

  const {
    match: {
      params: { id },
    },
  } = props;

  const getHistory = useCallback(
    (params) => {
      dispatch(fetchHistoryEnrollmentRequest(id, params));
    },
    [dispatch, fetchHistoryEnrollmentRequest, id]
  );

  useEffect(() => {
    getHistory();
  }, []);

  const handlePageChange = useCallback(
    (page) => {
      const params = {
        page,
      };

      getHistory(params);
      smoothScrollTo(0, 0);
    },
    [getHistory]
  );

  if (isLoading) {
    return (
      <div className="PageContainer centered">
        <div className="container-content">
          <Loader />
        </div>
      </div>
    );
  }

  return (
    <div className="EnrollmentPlanHistoryTab">
      <div className="tab-name">HISTÓRICO</div>
      <Table columns={table} data={history} />
      <Pagination
        onChange={handlePageChange}
        activePage={paginate.page}
        totalItemsCount={paginate.totalItemsCount}
        itemsCountPerPage={paginate.itemsCountPerPage}
      />
    </div>
  );
};

HistoryTab.propTypes = {
  match: PropTypes.object.isRequired,
};

export default tabify({
  title: 'Histórico',
  icon: 'history',
})(HistoryTab);
