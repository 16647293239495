import React, { useCallback, useMemo, useRef, useState } from 'react';
import {
  Box,
  Breadcrumb,
  Button,
  DefaultThemeProps,
  Text,
  theme,
} from '@agendaedu/ae-web-components';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import useOutsideClick from 'core/hooks/useOutsideClick';
import withAppContext from 'core/hoc/withAppContext';

import { useLaunchDarkly } from 'core/hooks/useLaunchDarkly';

import { StorageRoot } from 'store/storage/types';
import StorageActions from 'store/storage/actions';

import Skeleton from 'components/Skeleton';

import * as S from './styles';

import { Props } from './types';

const Header = ({
  history,
  appContext: {
    policies: { can_create_artifact: canCreateRootArtifact },
  },
  folderAscendingId,
}: Props) => {
  const { t } = useTranslation(['storage']);
  const defaultTheme: DefaultThemeProps = theme;
  const dropdownButtonRef = useRef(null);
  const dispatch = useDispatch();

  const { isActiveFlag: redeLiusFlag } = useLaunchDarkly({
    flag: 'REDE_LIUS_GUIA_DE_EMBARQUE',
  });

  const {
    toggleShowDetaisModal,
    toggleShowDeleteStorageModal,
    setSelectedStorage,
  } = StorageActions;

  const [showDropdownActionsButton, setShowDropdownActionsButton] =
    useState(false);

  const {
    border: { radius },
  } = defaultTheme;

  const dataArea = useSelector(
    (state: { root: { dataArea: string } }) => state.root.dataArea
  );

  const {
    common: { folderPath, currentStorage },
  } = useSelector((state: StorageRoot) => state.storage);

  const handleToggleDeleteModal = useCallback(() => {
    dispatch(setSelectedStorage(currentStorage));
    dispatch(toggleShowDeleteStorageModal());
  }, [
    currentStorage,
    dispatch,
    setSelectedStorage,
    toggleShowDeleteStorageModal,
  ]);

  const handleShowDetailsModal = useCallback(() => {
    dispatch(setSelectedStorage(currentStorage));
    dispatch(toggleShowDetaisModal());
  }, [dispatch, setSelectedStorage, currentStorage, toggleShowDetaisModal]);

  useOutsideClick(dropdownButtonRef, () =>
    setShowDropdownActionsButton(!showDropdownActionsButton)
  );

  const isRootFolder = !folderAscendingId;

  const permissions = currentStorage?.attributes.userPermissions;

  const defaultRootFeatureName = !redeLiusFlag
    ? t('list.title')
    : t('list.custom_rede_lius_title');

  const rootFolderActions = [
    {
      as: 'a',
      title: t('storage_list.actions.edit'),
      link: `/${dataArea}/artifacts/${currentStorage?.id}/folders/edit`,
    },
    {
      as: 'button',
      title: t('storage_list.actions.delete'),
      onClick: handleToggleDeleteModal,
    },
    {
      as: 'button',
      title: t('storage_list.actions.details'),
      onClick: handleShowDetailsModal,
    },
  ];

  const createArtifactActions = [
    {
      title: t('storage_list.actions.new_folder'),
      link: `/${dataArea}/artifacts/${folderAscendingId}/folders/new`,
    },
    {
      title: t('storage_list.actions.new_document'),
      link: `/${dataArea}/artifacts/${folderAscendingId}/documents/new`,
    },
    {
      title: t('storage_list.actions.new_file'),
      link: `/${dataArea}/artifacts/${folderAscendingId}/files/new`,
    },
  ];

  const breadcrumbPath = useMemo(() => {
    const path = [];

    if (!folderPath) return;

    folderPath.ids.map((id, index) => {
      path.push({
        title: folderPath.titles[index],
        path: id
          ? `/${dataArea}/artifacts/folders/${id}`
          : `/${dataArea}/artifacts`,
      });
    });

    return path;
  }, [dataArea, folderPath]);

  return (
    <S.Wrapper>
      {!isRootFolder && currentStorage && (
        <Breadcrumb
          onClick={(path: string) => history.push(path)}
          icon="folder-filled"
          path={breadcrumbPath}
          alias={t('storage_list.header.breadcrumb_title')}
        />
      )}

      <Box display="flex" justifyContent="space-between" gap="xs">
        {!isRootFolder && !currentStorage ? (
          <Skeleton
            data-testid="skeleton"
            width={140}
            height={40}
            borderRadius={radius.md}
          />
        ) : (
          <Box
            display="flex"
            flexDirection="column"
            gap="xs3"
            overflow="hidden"
          >
            <Text
              variant="headline-h2-bold-24"
              fontWeight="bold"
              color="neutral.black"
              data-testid="header-title"
              textOverflow="ellipsis"
              overflow="hidden"
              whiteSpace="nowrap"
              lineHeight="lg"
              mb={0}
            >
              {!isRootFolder
                ? currentStorage?.attributes.title
                : defaultRootFeatureName}
            </Text>
          </Box>
        )}

        {isRootFolder && canCreateRootArtifact ? (
          <>
            <Link
              data-testid="new-artifact-button"
              to={`/${dataArea}/artifacts/new`}
            >
              <S.NewButton id="new-folder" size="md">
                {t('list.new_folder')}
              </S.NewButton>
            </Link>
          </>
        ) : (
          <Box display="flex" flexDirection="row" gap="sm">
            {permissions?.create && (
              <Box data-testid="add-options-button" id="add-options">
                <Button
                  data-testid="new-artifact-button"
                  onClick={() =>
                    setShowDropdownActionsButton(!showDropdownActionsButton)
                  }
                >
                  {t('storage_list.header.create_artifact_button_title')}
                </Button>
                {showDropdownActionsButton && (
                  <S.OptionList ref={dropdownButtonRef}>
                    {createArtifactActions.map((action) => (
                      <Link key={action.link} to={action.link}>
                        <S.OptionsItem key={action.title}>
                          {action.title}
                        </S.OptionsItem>
                      </Link>
                    ))}
                  </S.OptionList>
                )}
              </Box>
            )}

            {permissions?.delete && (
              <S.FolderActionWrapper
                disableStopPropagation
                actions={rootFolderActions}
                align="right"
              />
            )}
          </Box>
        )}
      </Box>
    </S.Wrapper>
  );
};

export default withRouter(withAppContext(Header));
