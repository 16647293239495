import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import { withRouter } from 'react-router';

import withAppContext from 'core/hoc/withAppContext';

import actionHandouts from 'store/handouts/actions';
import { HandoutState } from 'store/handouts/types';

import { Button, Icon, Text } from '@agendaedu/ae-web-components';
import { Modal } from '../Modal';

import * as S from './styles';

import { Props } from './types';

const TemplateDetailsModal: React.FC<Props> = ({
  appContext: {
    dataArea,
    policies: { can_create_handout: canCreateHandout },
  },
  history,
}) => {
  const { t } = useTranslation(['handouts']);
  const dispatch = useDispatch();

  const { toggleHandoutTemplateModal, setCurrentHandoutTemplate } =
    actionHandouts;

  const { showHandoutTemplateModal, currentHandoutTemplate } = useSelector(
    (state: HandoutState) => state.handouts
  );

  const { toggleDeleteHandoutTemplateModal } = actionHandouts;

  const {
    categoryName,
    coverImage,
    description,
    handoutTitle,
    title,
    userPermissions: { edit: canEdit, delete: canDelete },
  } = currentHandoutTemplate?.attributes;

  const handleToggleDetailsModal = useCallback(() => {
    dispatch(toggleHandoutTemplateModal());
    dispatch(setCurrentHandoutTemplate(null));
  }, [dispatch, setCurrentHandoutTemplate, toggleHandoutTemplateModal]);

  const handleDeleteTemplateModal = useCallback(() => {
    dispatch(toggleDeleteHandoutTemplateModal());
  }, [dispatch, toggleDeleteHandoutTemplateModal]);

  const handleEditHandoutTemplateForm = useCallback(() => {
    dispatch(setCurrentHandoutTemplate(null));
    handleToggleDetailsModal();
    history.push(
      `/${dataArea}/handouts/templates/${currentHandoutTemplate.id}/edit`
    );
  }, [
    currentHandoutTemplate,
    dataArea,
    dispatch,
    handleToggleDetailsModal,
    history,
    setCurrentHandoutTemplate,
  ]);

  const handleApplyTemplate = useCallback(
    (templateId: string) => {
      history.push(`/${dataArea}/handouts/templates/${templateId}/apply`);
    },
    [dataArea, history]
  );

  return (
    <Modal.Root
      isOpen={showHandoutTemplateModal}
      maxWidth="607px"
      handleToggleModal={handleToggleDetailsModal}
      overlapModal={false}
      marginAuto
      dataTestId="handout-template-modal"
      title={
        <S.TitleModalWrapper>
          <Text variant="headline-h2-bold-24">
            {t('templates.details.modal_title')}
          </Text>
          <Text color={'neutral.gray2'} variant="subtitle-medium-16">
            {title}
          </Text>
        </S.TitleModalWrapper>
      }
    >
      <Modal.Content>
        <S.Wrapper>
          {coverImage?.url ? (
            <S.CoverImage data-testid="cover-image" src={coverImage.url} />
          ) : (
            <S.EmptyCoverImage data-testid="empty-template-image">
              <Icon name="image" />
            </S.EmptyCoverImage>
          )}

          <S.DetailsWrapper>
            <S.Label>{t('templates.details.title_label')}</S.Label>
            <S.LabelValue>{handoutTitle}</S.LabelValue>
          </S.DetailsWrapper>

          <S.DetailsWrapper>
            <S.Label>{t('templates.details.category_label')}</S.Label>
            <S.LabelValue>{categoryName}</S.LabelValue>
          </S.DetailsWrapper>

          <S.DetailsWrapper>
            <S.Label>{t('templates.details.description_label')}</S.Label>
            <S.LabelValue>{parse(description)}</S.LabelValue>
          </S.DetailsWrapper>

          <S.ActionsWrapper>
            <S.ButtonsWrapper>
              {canDelete && (
                <Button
                  onClick={handleDeleteTemplateModal}
                  isOnlyIcon
                  contextVariant="alert"
                  variant="secondary"
                  icon={'trash-bin'}
                />
              )}
              {canEdit && (
                <Button
                  data-testid="edit-handout-template-button"
                  isOnlyIcon
                  variant="secondary"
                  icon={'pencil'}
                  onClick={handleEditHandoutTemplateForm}
                />
              )}
            </S.ButtonsWrapper>

            <S.ButtonsWrapper>
              <Button
                data-testid="back-button"
                variant="secondary"
                onClick={handleToggleDetailsModal}
              >
                {t('templates.details.back_button')}
              </Button>
              {canCreateHandout && (
                <Button
                  data-testid="apply-handout-template-button"
                  onClick={() => handleApplyTemplate(currentHandoutTemplate.id)}
                >
                  {t('templates.details.use_button')}
                </Button>
              )}
            </S.ButtonsWrapper>
          </S.ActionsWrapper>
        </S.Wrapper>
      </Modal.Content>
    </Modal.Root>
  );
};

export default withRouter(withAppContext(TemplateDetailsModal));
