/* eslint-disable no-debugger, react/prop-types */
import React from 'react';
import styled from 'styled-components';

const TableText = styled.p`
  margin: 0;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  white-space: nowrap;
  color: ${(props) => props.theme.black};
`;

const Field = ({ field }) => (
  <TableText>
    <b>{field}</b>
  </TableText>
);

const Required = ({ required }) => <TableText>{required}</TableText>;

const Description = ({ description, rules, observation }) => (
  <span>
    <TableText>{description}</TableText>
    <TableText>{rules}</TableText>
    <TableText>{observation}</TableText>
  </span>
);

export default [
  {
    Header: 'Campo',
    accessor: Field,
  },
  {
    Header: 'Obrigatório',
    accessor: Required,
  },
  {
    Header: 'Descrição',
    accessor: Description,
  },
];
