import * as TYPES from './types';

export const INITIAL_STATE = {
  data: {
    schoolTerms: { filters: [], selected: [] },
    headquarters: { filters: [], selected: [] },
    courses: { filters: [], selected: [] },
  },
  initialValues: {
    schoolTerms: { filters: [], selected: [] },
    headquarters: { filters: [], selected: [] },
    courses: { filters: [], selected: [] },
  },
  loading: false,
  canChange: false,
  sent: false,
  error: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case TYPES.FETCH_REQUEST:
    return {
      ...state,
      loading: true,
    };

  case TYPES.FETCH_SUCCESS:
    return {
      ...state,
      data: {
        id: action.data.id,
        schoolTerms: action.data.attributes.school_terms,
        headquarters: action.data.attributes.headquarters,
        courses: action.data.attributes.courses,
      },
      initialValues: {
        id: action.data.id,
        schoolTerms: action.data.attributes.school_terms,
        headquarters: action.data.attributes.headquarters,
        courses: action.data.attributes.courses,
      },
      loading: false,
    };

  case TYPES.FETCH_ERROR:
    return {
      ...state,
      loading: false,
      sent: false,
      error: action.error,
    };

  case TYPES.SET_HEADQUARTERS:
    const headquarters = {
      filters: state.initialValues.headquarters.filters,
      selected: action.filters,
    };

    return {
      ...state,
      data: {
        headquarters,
        courses: state.data.courses,
        schoolTerms: state.data.schoolTerms,
      },
    };

  case TYPES.SET_COURSES:
    const courses = {
      filters: state.initialValues.courses.filters,
      selected: action.filters,
    };

    return {
      ...state,
      data: {
        courses,
        headquarters: state.data.headquarters,
        schoolTerms: state.data.schoolTerms,
      },
    };

  case TYPES.SET_SCHOOL_TERMS:
    const schoolTerms = {
      filters: state.initialValues.schoolTerms.filters,
      selected: action.filters,
    };

    return {
      ...state,
      data: {
        schoolTerms,
        headquarters: state.data.schoolTerms,
        courses: state.data.courses,
      },
    };

  case TYPES.UPDATE_REQUEST:
    return {
      ...state,
      canChange: false,
      sent: true,
    };

  case TYPES.UPDATE_SUCCESS:
    return {
      ...state,
      data: {
        id: action.data.id,
        schoolTerms: action.data.attributes.school_terms,
        headquarters: action.data.attributes.headquarters,
        courses: action.data.attributes.courses,
      },
      sent: false,
    };

  default:
    return state;
  }
};
