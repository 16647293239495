/* eslint-disable import/no-unresolved */
import React from 'react';
import PropTypes from 'prop-types';
import autobind from 'autobind-decorator';

import AgendaIcon from 'components/AgendaIcon';

import withFormContext, { formPropTypes } from 'core/hoc/withFormContext';

import './TextFieldWithIcon.scss';

class TextFieldWithIcon extends React.Component {
  @autobind
  onChange(event) {
    const {
      onChange,
      formContext: { changeAttribute },
      attributeName,
    } = this.props;

    if (onChange) {
      changeAttribute(attributeName)(event);
      onChange(event.target.value);
    } else {
      changeAttribute(attributeName)(event);
    }
  }

  render() {
    const {
      attributeName,
      placeholder,
      onIconClick,
      disabled,
      autoComplete,
      formContext: { form },
    } = this.props;

    return (
      <div className="TextFieldWithIcon input-group">
        <input
          type="text"
          className="form-control search-field"
          onChange={(e) => this.onChange(e)}
          name={attributeName}
          placeholder={placeholder}
          disabled={disabled}
          autoComplete={autoComplete ? 'on' : 'off'}
          value={form[attributeName] || ''}
          onKeyPress={(e) => {
            if (e.key === 'Enter') e.preventDefault();
          }}
        />
        <div className="input-group-addon search-addon" onClick={onIconClick}>
          <AgendaIcon name="search" size="small" />
        </div>
      </div>
    );
  }
}

TextFieldWithIcon.propTypes = {
  attributeName: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  autoComplete: PropTypes.bool,
  ...formPropTypes,
};

TextFieldWithIcon.defaultProps = {
  autoComplete: true,
};

export default withFormContext(TextFieldWithIcon);
