import styled, { css } from 'styled-components';
import FormFieldset from 'components/Form/Fieldset';
import { ThemeProps } from 'config/themes/types';

export const GroupMembersStepWrapper = styled(FormFieldset)``;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  ${({ theme: { space } }: ThemeProps) => css`
    padding: ${space.xs4};
    .container-wrap:first-child {
      margin-top: ${space.lg};
      max-width: none !important;

      .selector-filters {
        width: 100%;
      }
    }
  `}
`;

export const ItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 64px;
  height: 100%;
  width: 100%;
  padding: 0 16px;

  .Avatar {
    width: 34px;
    height: 34px;
  }

  > span {
    padding-left: 0;
  }
`;

export const FormSkeletonWrapper = styled.div`
  ${({ theme: { breakpoints, colors, space } }: ThemeProps) => css`
    height: 100%;
    max-width: 100%;
    padding: ${space.xs4} 0;
    margin: ${space.xl3} 0;
    background-color: ${colors.neutral.white};

    @media only screen and (min-width: ${breakpoints.desktopLG}) {
      margin: ${space.xl3} 0;
    }

    @media only screen and (min-width: ${breakpoints.desktopXL}) {
      padding: ${space.xs4} 0;
    }
  `}
`;
