import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

function MessagesBadge({ children }) {
  return (
    <div className="MessagesBadge">
      <span>
        {children}
      </span>
    </div>
  );
}

MessagesBadge.propTypes = {
  children: PropTypes.node
};

export default MessagesBadge;
