import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import withAppContext, { appPropTypes } from 'core/hoc/withAppContext';
import FormFullScreen from 'components/FormFullScreen';
import Toast from 'components/Toast';
import Loader from 'components/Loader';
import SchoolServices from 'core/services/School';
import UserServices from 'core/services/Users';
import StudentProfilesInformationsTab from 'pages/studentProfiles/FormTabs/InformationsTab';
import StudentProfilesActions from 'store/studentProfiles/actions';
import normalizeParams from '../normalizeParams';
import * as S from '../styles';

const contentModal = `
  A recuperação do aluno(a) será cancelada e
  as informações preenchidas serão descartadas.
  Tem certeza que deseja descartar?
`;

const StudentProfilesRecover = ({ match, appContext }) => {
  const {
    form: current,
    formMeta,
    isLoading,
    isSubmitting,
  } = useSelector((state) => state.studentProfiles);
  const { policies } = appContext;
  const dataArea = useSelector((state) => state.root.dataArea);
  const schoolServices = new SchoolServices(dataArea);
  const userServices = new UserServices(dataArea);
  const { can_use_legacy_id: canUseLegacyId } = policies;

  const dispatch = useDispatch();

  const { setRecoveryStudentProfile, recoverStudentProfile } =
    StudentProfilesActions;

  const { id } = match.params;

  const validateLegacyId = async (formContext) => {
    const { form, removeErrorOnAttribute, addErrorOnAttribute } = formContext;
    try {
      await schoolServices.validateLegacyId(form.legacy_id, 'student_profiles');
      removeErrorOnAttribute('legacy_id');
    } catch {
      addErrorOnAttribute(
        'legacy_id',
        'Identificador de registro já está em uso'
      );
    }
  };

  const validateEmail = async (formContext) => {
    const { form, removeErrorOnAttribute, addErrorOnAttribute } = formContext;
    try {
      await userServices.verifyEmail(form.email, 'student_profiles');
      addErrorOnAttribute('email', 'E-mail já está em uso');
    } catch {
      removeErrorOnAttribute('email');
    }
  };

  const validateUsername = async (formContext) => {
    const { form, removeErrorOnAttribute, addErrorOnAttribute } = formContext;
    try {
      await userServices.verifyUsername(form.username, 'student_profiles');
      addErrorOnAttribute('username', 'Nome de usuário já está em uso');
    } catch {
      removeErrorOnAttribute('username');
    }
  };

  const handleSetRecoveryStudentProfile = useCallback(() => {
    dispatch(setRecoveryStudentProfile(id));
  }, [dispatch, setRecoveryStudentProfile, id]);

  useEffect(() => {
    handleSetRecoveryStudentProfile();
  }, [handleSetRecoveryStudentProfile]);

  const onSubmit = async (formContext) => {
    const { form, hasErrorOnAttribute } = formContext;

    if (form.legacy_id && form.legacy_id !== current.legacy_id)
      await validateLegacyId(formContext);

    if (!canUseLegacyId) delete form.legacy_id;

    if (form.email && form.email !== current.email)
      await validateEmail(formContext);

    if (form.username && form.username !== current.username)
      await validateUsername(formContext);

    const hasErrorOnLegacyId = hasErrorOnAttribute('legacy_id');
    const hasErrorOnEmail = hasErrorOnAttribute('email');
    const hasErrorOnUserName = hasErrorOnAttribute('username');

    const isFormValid =
      !hasErrorOnLegacyId && !hasErrorOnEmail && !hasErrorOnUserName;

    isFormValid &&
      dispatch(recoverStudentProfile(id, normalizeParams(form, policies)));
  };

  return (
    <S.PageContainer className="StudentProfilesRecover">
      <Loader isLoading={isLoading}>
        <FormFullScreen
          action="recover"
          titlePage="Recuperar aluno(a)"
          titleModal="Descartar preenchimento"
          contentModal={contentModal}
          toggleModalWithFormUpdates
          onSubmit={onSubmit}
          form={current}
          formMeta={formMeta}
          isSubmitting={isSubmitting}
          steps={[StudentProfilesInformationsTab]}
          backTo={`/${dataArea}/student_profiles`}
          backToExternal
        />
      </Loader>
      <Toast />
    </S.PageContainer>
  );
};

StudentProfilesRecover.propTypes = {
  ...appPropTypes,
  match: PropTypes.object.isRequired,
};

export default withAppContext(StudentProfilesRecover);
