import styled, { css } from 'styled-components';
import { ThemeProps } from 'config/themes/types';
import ModalContainer from 'components/Modal/ModalContainer';
import { Text, Button } from '@agendaedu/ae-web-components';

export const EmptyWalletWarningContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme: { space } }: ThemeProps) => css`
    padding: ${space.xl2} ${space.xl5};

    @media (max-width: 870px) {
      padding: ${space.xl2} ${space.lg};
    }
  `}
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${({ theme: { space } }: ThemeProps) => css`
    gap: ${space.xl3};

    .wrapper-button-test-now {
      display: block;
      width: 183.52px;
    }

    @media (max-width: 770px) {
      flex-direction: column;
      gap: ${space.lg};
    }
  `}
`;

export const HeaderTitle = styled(Text)`
  ${({ theme: { colors, space } }: ThemeProps) => css`
    color: ${colors.neutral.black};
    font-weight: 700 !important;
    margin-bottom: ${space.lg};
  `}
`;

export const HeaderSubtitle = styled(Text)`
  ${({ theme: { colors, space } }: ThemeProps) => css`
    color: ${colors.neutral.gray1};
    margin-bottom: ${space.xl};

    @media (max-width: 770px) {
      margin-bottom: ${space.lg};
    }
  `}
`;

export const HeaderImg = styled.img`
  height: 200px;
  width: 400px;

  @media (max-width: 770px) {
    height: 216px;
    width: 432px;
  }
  @media (max-width: 480px) {
    height: 100%;
    width: 100%;
  }
`;

export const ButtonInBold = styled(Button)`
  font-weight: 700 !important;
`;

export const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(225.5px, 1fr));
  width: 100%;

  ${({ theme: { space } }: ThemeProps) => css`
    margin: ${space.xl} ${space.xl5} 0 0;
    grid-gap: ${space.xl};

    @media (max-width: 1400px) {
      grid-template-columns: repeat(auto-fill, minmax(225.5px, 1fr));
    }
    @media (max-width: 870px) {
      grid-template-columns: repeat(auto-fill, minmax(204px, 1fr));
      grid-gap: ${space.lg};
    }
    @media (max-width: 770px) {
      margin-top: ${space.sm};
    }
    @media (max-width: 480px) {
      justify-content: center;
    }
  `}
`;

export const Card = styled.div`
  ${({ theme: { space, colors, shadow, border } }: ThemeProps) => css`
    padding: ${space.lg};
    background-color: ${colors.neutral.white};
    box-shadow: ${shadow.card};
    border-radius: ${border.radius.md};
  `}
`;

export const CardImg = styled.img`
  height: 128px;
  width: 100%;
  object-fit: cover;

  ${({ theme: { border } }: ThemeProps) => css`
    border-radius: ${border.radius.md};
  `}
`;

export const CardTitle = styled(Text)`
  ${({ theme: { colors, space } }: ThemeProps) => css`
    color: ${colors.neutral.black};
    font-weight: 700 !important;
    margin: ${space.sm} 0;
  `}
`;

export const CardSubtitle = styled(Text)`
  ${({ theme: { colors, space } }: ThemeProps) => css`
    margin-bottom: ${space.lg};
    color: ${colors.neutral.gray1};
  `}
`;

export const ModalWrapper = styled(ModalContainer)`
  ${({ theme: { colors, space, font } }: ThemeProps) => css`
    .ReactModal__Overlay {
      background-color: rgba(0, 0, 0, 0.25) !important;
    }

    .ReactModal__Content {
      width: 800px !important;
      inset: 50% 50% !important;
      padding: 0 !important;
      border: 0 !important;

      @media (max-width: 870px) {
        width: 600px !important;
      }

      @media (max-width: 650px) {
        width: 432px !important;
      }
    }

    .flex-row-modal-head {
      position: absolute;
      display: flex;
      align-items: center;
      margin: ${space.xl} ${space.xl} 0 ${space.xl};
      width: 92%;

      .title,
      .AgendaIcon {
        font-size: ${font.size.xl};
        line-height: ${font.height.lg};
        color: ${colors.neutral.white};
      }

      @media (max-width: 600px) {
        width: 88%;
      }
    }
  `}
`;

export const ModalImg = styled.img`
  height: 100%;
  width: 100%;

  @media (max-width: 870px) {
    height: 391px;
    object-fit: cover;
  }
`;

export const ModalDescription = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    padding: ${space.lg} ${space.xl} ${space.xl} ${space.xl};
  `}
`;

export const Description = styled(Text)`
  ${({ theme: { colors, space } }: ThemeProps) => css`
    margin-bottom: ${space.xl};
    color: ${colors.neutral.gray1};
  `}
`;

export const ModalButton = styled(Button)`
  margin-left: auto;
  font-weight: 700 !important;
`;
