import styled from 'styled-components';
import Tooltip from 'components/Tooltip';

export const FinesAndInterestWrapper = styled.div``;

export const TogglePenalityWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  .ToggleSwitch {
    height: 26px;
  }
`;

export const TogglePenalityTitle = styled.span`
  font-size: 14px;
`;

export const PenalityToolip = styled(Tooltip)`
  .tooltip-div {
    position: relative;
    width: 16px;
    height: 16px;
  }
`;
