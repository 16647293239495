import React, { forwardRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
const div = React.createElement.bind(React, 'div');
const iframe = React.createElement.bind(React, 'iframe');
import scrollTop from 'core/utils/scrollTop';

const divStyle = {
  position: 'relative',
  overflow: 'hidden',
  maxWidth: '100%',
};

/*
 *  Turn `16:9` into `9 / 16` into `56.25%`
 *  Turn `4:3` into `3 / 4` into `75%`
 */
const ratioToPercent = (ratio) => {
  const [w, h] = ratio.split(':').map((num) => Number(num));
  return `${(h / w) * 100}vh`;
};

/*
 *  Usage: <ResponsiveEmbed src='ace youtube video' ratio='4:3' />
 */
const ResponsiveIframe = forwardRef(
  (props: { ratio: string; src: string }, ref) => {
    const changeWrapperBackgroundColor = () => {
      document.body.style.overflow = 'hidden';
      document.getElementsByClassName('wrapper')[0].style.paddingRight = '16px';
      scrollTop();
    };

    useEffect(() => {
      changeWrapperBackgroundColor();
    }, []);

    const heightRatio = ratioToPercent(props.ratio);
    const style = Object.assign({}, divStyle, {
      paddingBottom: heightRatio,
    });
    const iframeProps = Object.assign({ frameBorder: 0 }, props, {
      style: {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        width: '100%',
        height: heightRatio,
      },
      ref: ref,
      allow: 'clipboard-read; clipboard-write',
      ...props,
    });

    delete iframeProps.ratio;
    return div({ style }, iframe(iframeProps));
  }
);

ResponsiveIframe.defaultProps = {
  src: 'https://agendaedu.com/',
  ratio: '16:9',
};

ResponsiveIframe.propTypes = {
  src: PropTypes.string,
  ratio: (props, propName) => {
    if (!/\d+:\d+/.test(props[propName])) {
      return new Error(
        'Invalid ratio supplied to ResponsiveEmbed. Expected a string like "16:9" or any 2 numbers seperated by a colon'
      );
    }
  },
};

export default ResponsiveIframe;
