import React, { useEffect } from 'react';

import FormFieldset from 'components/Form/Fieldset';
import FormStudentSelector from 'components/Form/StudentsSelector';
import Title from 'components/WizardForm/Title';
import Toast from 'components/Toast';

import FinancialResponsibleConfiguration from 'components/Payments/PaymentsContainer/FormSections/FinancialResponsibleConfiguration';
import scrollTop from 'core/utils/scrollTop';
import tabifyForWizard from 'core/hoc/tabifyForWizard';
import withFormContext from 'core/hoc/withFormContext';

import validations from './validations';
import './style.scss';

const RecurrentPlanFormRecipientsTab = () => {
  useEffect(() => {
    scrollTop(0, 0);
  }, []);
  return (
    <div className="RecurrentPlanFormRecipientsTab">
      <FormFieldset>
        <FinancialResponsibleConfiguration />
        <div className="row">
          <Title name="Selecionar aluno(s)" />
          <div className="stepInfo">
            <p>
              Selecione os alunos cuja a cobrança possui as mesmas informações
              preenchidas no passo anterior, pois será criado um plano de
              cobranças recorrentes para cada um.
            </p>
          </div>
        </div>
        <FormStudentSelector
          attributeName="classroom_with_student_profile_ids"
          selectPlaceholder="Selecione uma turma"
        />
      </FormFieldset>
      <Toast />
    </div>
  );
};

export default tabifyForWizard({
  title: '2. Destinatários',
  validations,
})(withFormContext(RecurrentPlanFormRecipientsTab));
