import React, { Suspense, lazy, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import withAppContext from 'core/hoc/withAppContext';

import omniChannelActions from 'store/messages/omniChannel/actions';
import { OmniChannelState } from 'store/messages/omniChannel/types';

import * as S from './styles';

import RecipientsStep from 'components/Messages/OmniChannel/Forms/MessageChat/RecipientsStep';
import { RecipientsStepSkeleton } from './skeleton';
import SendMessageStep from 'components/Messages/OmniChannel/Forms/MessageChat/SendMessageStep';
import Toast from 'components/Toast';

import { NewMessageChannelProps } from './types';

const WIZARD_STEPS = [RecipientsStep, SendMessageStep];

const NewMessageChatForm = ({
  match,
  appContext: { dataArea },
}: NewMessageChannelProps): JSX.Element => {
  const { channelId } = match?.params;

  const { t } = useTranslation(['messages']);
  const dispatch = useDispatch();

  const {
    fetchChatClassroomsRequest,
    setCreateNewChatRequest,
    fetchChannelRequest,
  } = omniChannelActions;

  const { isLoading, chatClassrooms, isSubmittingChatForm, channel } =
    useSelector((state: OmniChannelState) => state.omniChannel);

  const handleCreateNewChat = useCallback(
    ({ form }) => {
      const params = {
        channelId,
        ...form,
      };
      dispatch(setCreateNewChatRequest(params));
    },
    [channelId, dispatch, setCreateNewChatRequest]
  );

  const FormFullScreen = useMemo(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    () => lazy(() => import('components/FormFullScreen')),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoading, chatClassrooms, channel]
  );

  useEffect(() => {
    dispatch(fetchChatClassroomsRequest(channelId));
    dispatch(fetchChannelRequest(channelId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchChatClassroomsRequest, dispatch, channelId]);

  return (
    <S.MessageChannelFormWrapper>
      <Suspense fallback={null}>
        <FormFullScreen
          action="new"
          id="NewMessageChannel"
          form={{
            recipients: [],
            message: '',
            attachment: null,
          }}
          initialAttributes={{}}
          formMeta={{
            nextStepDisabled: true,
          }}
          backTo={`/${dataArea}/messages`}
          backToExternal
          isSubmitting={isSubmittingChatForm}
          steps={
            chatClassrooms && !isLoading && channel
              ? WIZARD_STEPS
              : [RecipientsStepSkeleton]
          }
          onSubmit={handleCreateNewChat}
          titlePage={t('omni_channel.forms.message_chat.steps.first')}
          titleModal={t(
            'omni_channel.forms.message_channel.create.cancel_title'
          )}
          contentModal={t(
            'omni_channel.forms.message_channel.create.cancel_description'
          )}
        />
      </Suspense>

      <Toast />
    </S.MessageChannelFormWrapper>
  );
};

export default withAppContext(NewMessageChatForm);
