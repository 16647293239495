import { ThemeProps } from 'config/themes/types';
import InfiniteScroll from 'react-infinite-scroll-component';
import styled, { css } from 'styled-components';
import { ScrollFloatingButtonStyleProps } from './types';

export const Wrapper = styled.div`
  ${({ theme: { colors, border, space } }: ThemeProps) => css`
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: 100%;
    padding: ${space.md} ${space.xs} ${space.xs4} ${space.md};
    margin: ${space.xs4};
    flex-direction: column-reverse;

    &::-webkit-scrollbar {
      background-color: transparent;
      width: ${space.xs2};
    }

    &::-webkit-scrollbar-button {
      display: none;
    }

    &::-webkit-scrollbar-track {
      background-color: ${colors.neutral.gray5};
    }

    &::-webkit-scrollbar-thumb {
      border-radius: ${border.radius.sm};
      border: ${border.width.sm} ${border.style.solid} ${colors.neutral.gray4};
    }
  `}
`;

export const Scroll = styled(InfiniteScroll)`
  display: flex;
  flex-direction: column-reverse;
`;

export const ScrollFloatingButton = styled.button<ScrollFloatingButtonStyleProps>`
  ${({ disabled, theme: { colors, border, space, shadow } }: ThemeProps) => css`
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background-color: ${colors.neutral.gray3};
    border-radius: ${border.radius.circle};
    position: absolute;
    right: ${space.xl};
    margin-bottom: ${space.sm};
    box-shadow: ${shadow.fab};
    path {
      fill: ${colors.neutral.white};
    }

    ${disabled &&
    css`
      display: none;
    `}
  `}
`;
