const CPF_REGEX = /^(([0-9]{3}\.?[0-9]{3}\.?[0-9]{3}-[0-9]{2})|([0-9]{11}))$/;

// Reject common values.
const REJECT_LIST = [
  '00000000000',
  '11111111111',
  '22222222222',
  '33333333333',
  '44444444444',
  '55555555555',
  '66666666666',
  '77777777777',
  '88888888888',
  '99999999999',
];

function verifierDigit(numbers) {
  const numberList = numbers.split("").map((number) => parseInt(number, 10));

  const modulus = numberList.length + 1;

  const multiplied = numberList.map(
    (number, index) => number * (modulus - index),
  );

  const mod = multiplied.reduce((buffer, number) => buffer + number) % 11;

  return mod < 2 ? 0 : 11 - mod;
}

function strip(cpf) {
  return (cpf || "").toString().replace(/[^\d]+/g, '');
}

export default function validateCpf(cpf) {
  if (!CPF_REGEX.test(cpf)) return false;

  const stripped = strip(cpf);

  // CPF must be defined
  if (!stripped) {
    return false;
  }

  // CPF must have 11 chars
  if (stripped.length !== 11) {
    return false;
  }

  if (REJECT_LIST.includes(stripped)) {
    return false;
  }

  let numbers = stripped.substr(0, 9);
  numbers += verifierDigit(numbers);
  numbers += verifierDigit(numbers);

  return numbers.substr(-2) === stripped.substr(-2);
}
