import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { trackEvent } from 'config/amplitude';

import withAppContext from 'core/hoc/withAppContext';
import { IMAGES_PAYMENT_URL, MODAL_CENTERED } from 'core/constants/index';

import { EmptyWalletWarningProps } from './types';

import * as S from './styles';

const EmptyWalletWarning = ({
  appContext: { policies },
}: EmptyWalletWarningProps) => {
  const { t } = useTranslation(['payments']);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectedCardType, setSelectedCardType] = useState(null);

  const {
    currentUser: { id: userId },
    currentSchool: { id: schoolId },
  } = useSelector((state) => state.root);

  const toggleModal = (cardType) => {
    if (cardType) {
      trackEvent(`${cardType}-card-click`, {
        schoolId,
        userId,
      });
    }
    setSelectedCardType(cardType);
    setIsOpenModal((oldIsOpenModal) => !oldIsOpenModal);
  };

  const redirectToContact = () => {
    trackEvent(`${selectedCardType}-contact-click`, {
      schoolId,
      userId,
    });
    window.open(
      'https://api.whatsapp.com/send?phone=5585982369986&text=Olá!%20Sou%20cliente%20Agenda%20Edu%20e%20quero%20conhecer%20mais%20a%20função%20"Cobranças%20únicas"%20do%20EduPay.%20💚',
      '_blank'
    );
  };

  const cards = [
    {
      type: 'school-product',
      image: IMAGES_PAYMENT_URL.schoolProductCard,
      title: t('onboarding_edupay.cards.schoolProduct.title'),
      subtitle: t('onboarding_edupay.cards.schoolProduct.subtitle'),
    },
    {
      type: 'recurrence',
      image: IMAGES_PAYMENT_URL.recurrenceCard,
      title: t('onboarding_edupay.cards.recurrence.title'),
      subtitle: t('onboarding_edupay.cards.recurrence.subtitle'),
    },
    {
      type: 'enrollment',
      image: IMAGES_PAYMENT_URL.enrollmentCard,
      title: t('onboarding_edupay.cards.enrollment.title'),
      subtitle: t('onboarding_edupay.cards.enrollment.subtitle'),
    },
    {
      type: 'shop',
      image: IMAGES_PAYMENT_URL.shopCard,
      title: t('onboarding_edupay.cards.shop.title'),
      subtitle: t('onboarding_edupay.cards.shop.subtitle'),
    },
  ];

  useEffect(() => {
    trackEvent('new-onboarding-edupay-view', {
      schoolId,
      userId,
    });
  }, []);

  return (
    <S.EmptyWalletWarningContainer id="EmptyWalletWarningContainer">
      <S.HeaderContainer>
        <div>
          <S.HeaderTitle variant="headline-h2-bold-24" lineHeight="lg">
            {t('onboarding_edupay.header.title')}
          </S.HeaderTitle>
          <S.HeaderSubtitle variant="body-regular-14" lineHeight="lg">
            {t('onboarding_edupay.header.subtitle')}
          </S.HeaderSubtitle>

          {policies && policies.can_config_wallet && (
            <Link
              to={`/schools/wallets/new`}
              onClick={() =>
                trackEvent('test-now-click', {
                  schoolId,
                  userId,
                })
              }
              className="wrapper-button-test-now"
            >
              <S.ButtonInBold>
                {t('onboarding_edupay.header.button_text')}
              </S.ButtonInBold>
            </Link>
          )}
        </div>

        <S.HeaderImg
          src={IMAGES_PAYMENT_URL.emptyWalletHeader}
          alt="Empty wallet header"
        />
      </S.HeaderContainer>

      <S.CardsContainer>
        {cards.map((card) => (
          <S.Card key={card.title}>
            <S.CardImg src={card.image} />
            <S.CardTitle variant="title-bold-14" lineHeight="lg">
              {card.title}
            </S.CardTitle>
            <S.CardSubtitle variant="body-regular-12" lineHeight="lg">
              {card.subtitle}
            </S.CardSubtitle>

            <S.ButtonInBold
              variant="secondary"
              size="sm"
              onClick={() => toggleModal(card.type)}
              testId={`button-card-${card.type}`}
            >
              {t('onboarding_edupay.cards.card_button_text')}
            </S.ButtonInBold>
          </S.Card>
        ))}
      </S.CardsContainer>

      <S.ModalWrapper
        isOpen={isOpenModal}
        toggleModal={() => toggleModal(null)}
        maxWidth="800px"
        contentStyles={MODAL_CENTERED}
        title={t('onboarding_edupay.modal.title')}
      >
        <S.ModalImg src={IMAGES_PAYMENT_URL.modal} alt="Empty wallet modal" />

        <S.ModalDescription>
          <S.Description variant="body-regular-16" lineHeight="lg">
            {t('onboarding_edupay.modal.description')}
          </S.Description>

          <S.ModalButton onClick={redirectToContact}>
            {t('onboarding_edupay.modal.button_text')}
          </S.ModalButton>
        </S.ModalDescription>
      </S.ModalWrapper>
    </S.EmptyWalletWarningContainer>
  );
};

export default withAppContext(EmptyWalletWarning);
