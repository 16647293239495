import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, WalletConfigurationProps } from './types';
import * as S from './styles';

import Field from 'components/Form/Field';
import Title from 'components/WizardForm/Title';
import Tooltip from 'components/Tooltip';
import AgendaIcon from 'components/AgendaIcon';
import Alert from 'components/Alert';

import withFormContext from 'core/hoc/withFormContext';

import walletsActions from 'store/edupay/wallets/actions';

const WalletConfiguration = ({
  formContext: {
    updateAttribute,
    form: { recipient_wallet_id: recipientWalletId },
    formMeta: { status },
  },
  isRecurrent = false,
  recurrentBill = false,
  disabled = false,
  isEditBillInBatches = false,
}: WalletConfigurationProps) => {
  const dispatch = useDispatch();
  const [currentRecipientWalletId, setCurrentRecipientWalletId] =
    useState(recipientWalletId);
  const { fetchRecipientsWalletsOptionsRequest } = walletsActions;
  const { walletsOptions } = useSelector((state) => state.wallets);
  const canEdit = ['pending', 'active', 'pending_school_approval'].includes(
    status
  );

  const {
    currentUser: {
      attributes: { is_debug_user: isDebugUser },
    },
    policies,
  } = useSelector((state: RootState) => state.root);

  useEffect(() => {
    dispatch(fetchRecipientsWalletsOptionsRequest());
  }, [dispatch, fetchRecipientsWalletsOptionsRequest]);

  useEffect(() => {
    if (!!currentRecipientWalletId)
      setCurrentRecipientWalletId(currentRecipientWalletId);
    else if (walletsOptions.length === 2) // We get the second because the first one is a placeholder.
      setCurrentRecipientWalletId(walletsOptions[1].value);
    else
      setCurrentRecipientWalletId(null);
  }, [walletsOptions]);

  useEffect(() => {
    updateAttribute('recipient_wallet_id', currentRecipientWalletId);
  }, [currentRecipientWalletId]);

  const content =
    'A carteira selecionada receberá o\nvalor referente a esta cobrança.';

  return (
    <S.Wrapper>
      <div className="wallet-title">
        <Title name="Carteira de destino" />
        <Tooltip content={content}>
          <AgendaIcon name="help" size="small" className="custom-size" />
        </Tooltip>
      </div>

      <Field
        fullWidth
        withArrowSelect
        label="Carteira de destino*"
        type="selectWithIcon"
        attributeName="recipient_wallet_id"
        classNamePrefix="wallet_configuration"
        options={
          isRecurrent && isDebugUser && policies.guaranteed_monthly_fee_enabled
            ? walletsOptions
            : walletsOptions.filter(
                (option) => !option.label?.includes('Mensalidade Garantida')
              )
        }
        value={currentRecipientWalletId}
        onChange={(e) => {
          setCurrentRecipientWalletId(e.value);
        }}
        disabled={!canEdit || disabled}
      />
    </S.Wrapper>
  );
};

export default withFormContext(WalletConfiguration);
