import React from 'react';
import ContentLoader from 'react-content-loader';

const DynamicSkeleton = () => {
  return (
    <ContentLoader
      width="100%"
      height="100%"
      speed={2}
      viewBox="0 0 100 22"
      ariaLabel="Carregando..."
    >
      <rect x="0" y="0" rx="0.5" ry="0.5" width="100" height="15" />
    </ContentLoader>
  );
};

export default DynamicSkeleton;
