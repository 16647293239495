import styled, { css } from 'styled-components';
import { ThemeProps } from 'config/themes/types';
import { Text as AeText } from '@agendaedu/ae-web-components';
import Text from 'components/Text';

export const OfferWrapper = styled.div`
  .checkboxes-wrapper {
    padding-left: 15px;
  }

  .double-input {
    .dateRange {
      padding: 0px;
    }
    .Field:last-child {
      padding: 0px;
    }
  }

  .DateInput {
    height: 38px;
  }

  .Alert {
    height: auto;
    margin-bottom: 20px;
  }
`;

export const AccordionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
  .Title {
    margin-bottom: 0px !important;
  }
`;

export const Paragraph = styled.p`
  font-family: 'Roboto';
  font-size: 12px;
  font-weight: 400;
`;

export const NotificationDescription = styled(Text)`
  margin-bottom: 16px;
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 16px;
  align-items: center;
`;

export const CheckboxSpan = styled.span`
  margin-left: 6px;
  cursor: pointer;
`;

export const CheckboxTooltip = {
  width: '176px',
};

export const CartConfig = styled(AeText)`
  ${({ theme: { colors } }: ThemeProps) => css`
    text-decoration: underline;
    color: ${colors.context.info.default};
  `}
`;
