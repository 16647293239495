import styled from 'styled-components';

import { actionButtonsStyles } from '../Occurrence/styles';

export const TextAreaContainer = styled.div`
  * {
    font-family: 'Roboto', sans-serif;
  }

  label {
    font-weight: normal;
    margin-bottom: 12px;
    font-size: 14px;
  }

  textArea {
    overflow: hidden;
  }
`;

export const MultipleMealContainer = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 0;
  margin-top: 8px;

  @media (max-width: 576px) {
    display: block;
  }
`;

export const MealActionsContainer = styled.div`
  ${actionButtonsStyles}
`;
