import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import {
  handleError,
  postApi,
  fetchApi,
  putApi,
  patchApi,
} from 'core/utils/api';
import actions from './actions';
import {
  buildToast,
  toastCss,
  ToastTypes,
} from 'store/middlewares/toast/actions';

export function* submitNewFormRequest(action) {
  try {
    const { dataArea } = yield select((state) => state.root);

    const { data } = yield call(
      postApi,
      `/${dataArea}/quick_accesses/`,
      action.params
    );

    yield put({
      type: actions.SUBMIT_NEW_FORM_SUCCESS,
      quickAccess: data,
      toast: buildToast(
        'Acesso rápido cadastrado com sucesso.',
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });

    window.location = `/${dataArea}/quick_accesses/`;
  } catch (error) {
    yield put({
      type: actions.ERROR,
      toast: buildToast(
        'Erro ao tentar criar o acesso rápido.',
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
      error,
    });
  }
}

export function* submitEditFormRequest(action) {
  try {
    const { dataArea } = yield select((state) => state.root);

    yield call(
      patchApi,
      `/${dataArea}/quick_accesses/${action.id}`,
      action.params
    );

    yield put({
      type: actions.SUBMIT_EDIT_FORM_SUCCESS,
      toast: buildToast(
        'Acesso rápido salvo com sucesso.',
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
    window.location = `/${dataArea}/quick_accesses/`;
  } catch (error) {
    yield put({
      type: actions.ERROR,
      toast: buildToast(
        'Erro ao tentar editar o acesso rápido.',
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
      error,
    });
  }
}

function* getClassrooms() {
  try {
    const dataArea = yield select((state) => state.root.dataArea);

    const { history } = yield call(
      fetchApi,
      `/${dataArea}/student_profiles/classrooms.json`
    );

    yield put({
      type: actions.GET_CLASSROOMS_SUCCESS,
      data: history,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        handleError(error),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* getQuickAccess(action) {
  try {
    const dataArea = yield select((state) => state.root.dataArea);

    const { data, included } = yield call(
      fetchApi,
      `/${dataArea}/quick_accesses/${action.id}/edit.json`
    );

    yield put({
      type: actions.GET_QUICKACCESS_SUCCESS,
      data: data,
      included: included,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        handleError(error),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}
function* quickAccessSagas() {
  yield all([
    takeLatest(actions.SUBMIT_EDIT_FORM_REQUEST, submitEditFormRequest),
    takeLatest(actions.SUBMIT_NEW_FORM_REQUEST, submitNewFormRequest),
    takeLatest(actions.GET_CLASSROOMS_REQUEST, getClassrooms),
    takeLatest(actions.GET_QUICKACCESS_REQUEST, getQuickAccess),
  ]);
}

export default quickAccessSagas;
