import React, { useCallback } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Avatar, RecipientTag, Tag, Text } from '@agendaedu/ae-web-components';
import { OmniChannelState } from 'store/messages/omniChannel/types';
import omniChannelActions from 'store/messages/omniChannel/actions';

import { OMNI_CHANNEL_CHAT_TAB, TICKET_TAG_VARIANT } from 'core/constants';
import { formatDate } from 'core/helper/message/omni/formatDate';

import * as S from './styles';

import { MessageGroupAvatar } from '../../MessageGroupAvatar';

import { ChatCardProps } from './types';

export const ChatCard = ({
  chatDetails,
  chatId,
  chatUserId,
  ticket,
}: ChatCardProps): JSX.Element => {
  const { t } = useTranslation('messages');

  const dispatch = useDispatch();

  const {
    setCurrentChat,
    setCurrentChatUser,
    socketLeaveChatRoomRequest,
    socketEnterChatRoomRequest,
  } = omniChannelActions;

  const {
    activeChat,
    activeChannel,
    activeTicket,
    activeChatUser,
    chatIsSearchTab,
    chatSearchCurrentTab,
  } = useSelector((state: OmniChannelState) => state.omniChannel);

  const {
    currentUser: { id },
  } = useSelector((state: RootStateOrAny) => state.root);

  const {
    avatarColor,
    avatarUrl,
    classroomsName,
    kind,
    lastMessagePreview,
    lastMessageSentAt,
    name,
    nameInitials,
    readStatus,
    roleMessage,
    senderMessage,
  } = chatDetails;

  const isTicketChannel = activeChannel?.kind === 'ticket';
  const chatRead = readStatus === 'answered';

  const currentTag = TICKET_TAG_VARIANT.find(
    (variant) => variant.status === ticket?.attributes.status
  );

  const chatDate =
    chatIsSearchTab && isTicketChannel
      ? formatDate(ticket.attributes.createdAt)
      : lastMessageSentAt;

  const isSelectedChat =
    chatIsSearchTab && !isTicketChannel
      ? activeChatUser?.id === chatUserId
      : activeChat?.id === chatId;

  const getChatDescription = () => {
    if (isTicketChannel && senderMessage) {
      if (chatIsSearchTab)
        return `${t('omni_channel.common.ticket_chat_search_topic_text')}: ${
          ticket?.attributes.title
        }`;

      const senderSchoolUser = senderMessage.type === 'schoolUser' || 'user';

      const isOwnerLastMessage = senderSchoolUser && senderMessage?.id === id;

      if (isOwnerLastMessage)
        return `${t(
          'omni_channel.common.owner_last_message_text'
        )}: ${lastMessagePreview}`;

      if (senderSchoolUser)
        return `${senderMessage.attributes.name}: ${lastMessagePreview}`;

      return lastMessagePreview;
    }

    return lastMessagePreview;
  };

  const handleSocketConnectRoom = useCallback(() => {
    activeChat?.id && dispatch(socketLeaveChatRoomRequest(activeChat.id));
    chatId && dispatch(socketEnterChatRoomRequest(chatId));
  }, [
    activeChat,
    chatId,
    dispatch,
    socketEnterChatRoomRequest,
    socketLeaveChatRoomRequest,
  ]);

  const handleSetChat = useCallback((): void => {
    const params = { id: chatId };

    activeChat?.id !== chatId && dispatch(setCurrentChat(params));
  }, [activeChat, chatId, dispatch, setCurrentChat]);

  const handleSetChatUser = useCallback((): void => {
    const chatTabName = OMNI_CHANNEL_CHAT_TAB[chatSearchCurrentTab];

    const isSameChat =
      chatTabName === activeChatUser?.attributes?.kind &&
      activeChatUser?.id === chatUserId;

    !isSameChat && dispatch(setCurrentChatUser(chatUserId));
  }, [
    activeChatUser,
    chatUserId,
    dispatch,
    setCurrentChatUser,
    chatSearchCurrentTab,
  ]);

  const handleSelectChat = useCallback(() => {
    handleSocketConnectRoom();
    if (chatIsSearchTab && !isTicketChannel) return handleSetChatUser();
    handleSetChat();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    handleSocketConnectRoom,
    handleSetChat,
    handleSetChatUser,
    chatIsSearchTab,
    isTicketChannel,
    activeTicket,
    ticket,
  ]);

  const renderAvatar = {
    family: (
      <MessageGroupAvatar
        size="sm"
        nameInitials={nameInitials}
        avatarColor={avatarColor}
        avatarUrl={avatarUrl}
      />
    ),
    private: (
      <Avatar
        avatarColor={avatarColor}
        size="md"
        nameInitials={nameInitials}
        avatarUrl={avatarUrl}
      />
    ),
    ticket: (
      <Avatar
        avatarColor={avatarColor}
        size="md"
        nameInitials={nameInitials}
        avatarUrl={avatarUrl}
      />
    ),
  };

  return (
    <S.Wrapper
      data-testid="card-wrapper"
      selected={isSelectedChat}
      onClick={handleSelectChat}
    >
      <S.CardTopContent>
        {renderAvatar[kind]}

        <S.UserContentWrapper>
          <S.RoleWrapper>
            <Text variant="subtitle-medium-12" color={'neutral.gray2'}>
              {roleMessage}
            </Text>

            <Text variant="subtitle-medium-12" color={'neutral.gray2'}>
              {chatDate}
            </Text>
          </S.RoleWrapper>

          <Text
            variant="title-bold-14"
            color={'neutral.black'}
            fontWeight={'700'}
          >
            {name}
          </Text>

          <S.TagsWrapper>
            {classroomsName?.length > 0 && (
              <S.Recipients>
                <RecipientTag
                  variant="neutral"
                  recipientType={t('omni_channel.common.recipient_type_text')}
                  recipients={classroomsName?.map((classroom) => ({
                    name: classroom,
                  }))}
                  modalTitle={t('omni_channel.common.recipient_type_text')}
                  size="small"
                />
              </S.Recipients>
            )}

            {ticket && chatIsSearchTab && (
              <Tag
                size="small"
                name={currentTag.name}
                variant={currentTag.variant}
              />
            )}
          </S.TagsWrapper>
        </S.UserContentWrapper>
      </S.CardTopContent>
      {lastMessagePreview && (
        <S.Message
          fontWeight={!chatRead ? '600' : '400'}
          variant={chatRead ? 'body-regular-14' : 'body-medium-14'}
          color={chatRead ? 'neutral.gray1' : 'neutral.black'}
        >
          {getChatDescription()}
        </S.Message>
      )}
    </S.Wrapper>
  );
};
