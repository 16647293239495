import React from 'react';
import _ from 'lodash';

import { ActionsButtons } from '../ActionsButtons';
import { DiaryFormStructure } from 'store/dailySummaries/types';
import {
  CustomCard,
  isEmptyCustomValues,
} from 'pages/diarySectionsV3/Sections/components/ShowSectionModal/Sections';
import { defaultAnswersAttributesValues } from 'core/contexts/DiaryForm/constants';
import { Props } from './types';

import {
  CustomSectionTypes,
  OnChangeValues,
} from 'pages/diarySectionsV3/Sections/components/ShowSectionModal/Sections/Custom/types';
import { Box } from '@agendaedu/ae-web-components';

export const CustomGroup = ({
  answers_attributes,
  onChange,
  currentSectionId,
  activesSections,
}: Props) => {
  const activesCustomSections = activesSections.filter(
    (section) => section.custom && section.id === currentSectionId
  );

  const answersAttributes = _.cloneDeep(answers_attributes);

  const actions = {
    add: (
      answersAttributesList: DiaryFormStructure['answers_attributes_values'][]
    ) => {
      const newAnswersAttributesList = answersAttributesList.map(
        (answerAttributesItem) =>
          defaultAnswersAttributesValues(answerAttributesItem.diary_question_id)
      );

      answersAttributes[currentSectionId].push(newAnswersAttributesList);

      onChange(answersAttributes);
    },
    remove: (index: number) => {
      answersAttributes[currentSectionId].splice(index, 1);

      onChange(answersAttributes);
    },
    update: (
      sectionIndex: number,
      callbackValues: OnChangeValues,
      answerIndex: number
    ) => {
      const answersAttributesValues =
        answersAttributes[currentSectionId][sectionIndex][answerIndex];

      const handleChangesAction = (kind: CustomSectionTypes) =>
        ({
          text: () => {
            answersAttributesValues.answer_text = callbackValues.value;
          },
          select: () => {
            answersAttributesValues.diary_answer_option_id =
              callbackValues.value;
          },
        }[kind]);

      handleChangesAction(callbackValues.type)();

      onChange(answersAttributes);
    },
    clear: (sectionIndex: number) => {
      const currentValue = answersAttributes[currentSectionId][sectionIndex];

      currentValue.forEach((question) => {
        question.key = defaultAnswersAttributesValues(
          question.diary_question_id
        ).key;
        question.answer_text = '';
        question.diary_answer_option_id = '';
      });

      onChange(answersAttributes);
    },
    isEmpty: (sectionIndex: number) => {
      const currentValue = answersAttributes[currentSectionId][sectionIndex];

      const isEmpty = isEmptyCustomValues(currentValue);

      return isEmpty;
    },
  };

  return (
    <>
      {activesCustomSections.map(({ id, name, questionsAttributes }) =>
        answersAttributes[id]?.map(
          (answersAttributesList, answersAttributesIndex, answersArray) => {
            const { add, remove, update, clear, isEmpty } = actions;
            const canAddSectionButton =
              answersAttributesIndex === answersArray.length - 1;

            const footer = (
              <ActionsButtons
                index={answersAttributesIndex}
                addSectionCard={() => add(answersAttributesList)}
                removeSectionCard={remove}
                clearSectionAction={clear}
                canAddSectionCard={canAddSectionButton}
                isEmpty={isEmpty(answersAttributesIndex)}
              />
            );

            return (
              <Box
                key={`${id}-${answersAttributesIndex}`}
                display="flex"
                width="100%"
              >
                <CustomCard
                  section={{ name, questionsAttributes }}
                  answersAttributesList={answersAttributesList}
                  footer={footer}
                  onChange={(callbackValues, questionAnswerIndex) => {
                    update(
                      answersAttributesIndex,
                      callbackValues,
                      questionAnswerIndex
                    );
                  }}
                />
              </Box>
            );
          }
        )
      )}
    </>
  );
};
