import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';

import formatDatetime from 'core/utils/formatDatetime';

import InfoCard from 'components/InfoCard';
import FileAttachment from 'components/FileAttachment';
import AgendaIcon from 'components/AgendaIcon';
import CommonLabel from 'components/CommonLabel';

import CardSection from 'components/InformationsTab/TabInfo/CardSection';

import { PERSONA_TYPE, DELIVERY_TYPE } from '../ultils/constantTypes';

import './style.scss';

const TabInfo = ({ data, permissions, status, toggleModal }) => {
  const validDeliveryWithDeadline = () => {
    if (data.deadline === null || data.deadline === undefined) return false;

    return true;
  };

  const CARD_SECTION_DATA = [
    {
      icon: 'calendar',
      label: 'Data de cadastro',
      value: formatDatetime(data.date, 'DD [de] MMM[,] YYYY'),
    },
    {
      icon: 'calendar',
      label: 'Data de entrega',
      value: formatDatetime(
        data.deadline?.split('T')[0],
        'DD [de] MMM[,] YYYY'
      ),
      hasDeadline: data.deadline === null ? false : true,
    },
    {
      icon: 'send',
      label: 'Enviado para',
      value: PERSONA_TYPE[data.persona_type],
    },
    {
      icon: 'income',
      label: 'Receber atividade',
      value: DELIVERY_TYPE[data.answer_submit_mode],
      hasActivityDelivery: validDeliveryWithDeadline(),
    },
  ];

  const canCancelSchedule =
    permissions?.can_cancel_schedule &&
    ['scheduled', 'rescheduled', 'pending_schedule'].includes(status);

  const renderWarning = () => {
    if (data.status === 'rejected') {
      return (
        <CommonLabel variation="danger">
          Motivo: {data.cancel_reason}
        </CommonLabel>
      );
    }

    if (data.status === 'scheduled') {
      return (
        <CommonLabel variation="info">
          <div className="common-label-content">
            <div className="description">
              Envio agendado para{' '}
              {formatDatetime(
                data.schedule_date,
                'DD [de] MMM[,] YYYY [às] HH:mm'
              )}
              .
            </div>

            {canCancelSchedule && (
              <AgendaIcon
                name="close"
                className="icon-close"
                onClick={() => toggleModal('cancelSchedule')}
              />
            )}
          </div>
        </CommonLabel>
      );
    }

    if (
      data.status === 'pending_schedule' &&
      !permissions?.can_approve_schedule
    ) {
      return (
        <CommonLabel variation="info">
          <div className="common-label-content">
            <div className="description">
              Agendamento para{' '}
              {formatDatetime(
                data.schedule_date,
                'DD [de] MMM[,] YYYY [às] HH:mm'
              )}
              , pendente de aprovação.
            </div>

            {canCancelSchedule && (
              <AgendaIcon
                name="close"
                className="icon-close"
                onClick={() => toggleModal('cancelSchedule')}
              />
            )}
          </div>
        </CommonLabel>
      );
    }
  };

  return (
    <>
      <CardSection
        keyExtractor={({ label }) => label}
        data={CARD_SECTION_DATA}
        renderItem={(item) => <InfoCard {...item} />}
      />

      <section className="section-area">
        <p className="tab-info-label">Descrição do conteúdo</p>
        {data.description && (
          <p className="tab-info-text">{parse(data.description)}</p>
        )}
      </section>

      <section className="section-area">
        <p className="tab-info-label">Tarefa de casa</p>
        {data.taskhome && (
          <p className="tab-info-text">{parse(data.page_book)}</p>
        )}
      </section>

      <section className="section-area">
        {data.attachments && (
          <FileAttachment
            attachments={data.attachments}
            googleClientId={1}
            useGoogleDrive={1}
            onlyListAttachment={true}
            onlyDownloadAttachment={true}
            customStyle={{ titleColor: '#999999', maxWidthPreview: '60%' }}
            customIcon={true}
          />
        )}
      </section>

      <section className="section-area">{renderWarning()}</section>
    </>
  );
};

TabInfo.propTypes = {
  data: PropTypes.object,
  onActionModal: PropTypes.func,
  permissions: PropTypes.object,
  status: PropTypes.object,
  toggleModal: PropTypes.func,
};

export default TabInfo;
