import React, { useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import AgendaIcon from 'components/AgendaIcon';
import ButtonRow from 'components/ButtonRow';
import Loader from 'components/Loader';
import ModalTitle from 'components/Modal/ModalTitle';

import { ModalWrapperManuallyBilletLink } from './style';

const LinkModal = (props) => {
  const { t } = useTranslation(['payments']);
  const { showModal, toggleModal, isLoading, order, responsibles, method } =
    props;

  const [copySuccess, setCopySuccess] = useState(false);
  const textAreaRef = useRef(null);

  const toggleModalBillet = useCallback(() => {
    setCopySuccess(false);
    toggleModal();
  }, [toggleModal]);

  const copyToClipboard = (e) => {
    textAreaRef.current.select();
    document.execCommand('copy');

    e.target.focus();
    setCopySuccess(true);
  };

  const renderGeneratedFor = () => {
    const {
      attributes: { last_payment: lastPayment },
    } = order;

    const responsible = responsibles.find(
      (r) => r.id === lastPayment.data.attributes.responsible_profile_id
    );

    if (!responsible) return;

    return responsible.attributes.name;
  };

  return (
    <div>
      {showModal && (
        <ModalWrapperManuallyBilletLink
          isOpen={showModal}
          toggleModal={toggleModalBillet}
          maxWidth="490px"
        >
          {isLoading ? (
            <Loader />
          ) : (
            <div className="LinkModal">
              <ModalTitle>{`${t(`modals.link.${method}.title`)}`}</ModalTitle>
              <p className="subTitle">
                {`${t(`modals.link.${method}.subtitle`)}`}
              </p>
              {order !== undefined && (
                <React.Fragment>
                  <p className="subTitle">
                    <strong>Responsável</strong>
                    {renderGeneratedFor()}
                  </p>
                  <div className="boxBilletLink">
                    <input
                      ref={textAreaRef}
                      className={`inputLink ${copySuccess ? 'inputFocus' : ''}`}
                      value={
                        order.attributes.status === 'generated_pix'
                          ? order.attributes.pix_qrcode
                          : order.attributes.billet_url
                      }
                      readOnly
                    />
                    {document.queryCommandSupported('copy') && (
                      <React.Fragment>
                        {copySuccess && (
                          <AgendaIcon
                            name="check"
                            className="iconCheckSuccess"
                          />
                        )}
                      </React.Fragment>
                    )}
                    <ButtonRow
                      buttons={[
                        {
                          text: 'Fechar',
                          variation: 'secondary',
                          onClick: toggleModalBillet,
                        },
                        {
                          text: `${t(`modals.link.${method}.buttons.copy`)}`,
                          onClick: copyToClipboard,
                        },
                      ]}
                    />
                  </div>
                </React.Fragment>
              )}
            </div>
          )}
        </ModalWrapperManuallyBilletLink>
      )}
    </div>
  );
};

LinkModal.defaultProps = {
  showModal: false,
  isLoading: false,
  responsibles: [],
  method: 'billet',
};

LinkModal.propTypes = {
  toggleModal: PropTypes.func.isRequired,
  method: PropTypes.string,
  order: PropTypes.object,
  responsibles: PropTypes.array,
  showModal: PropTypes.bool,
  isLoading: PropTypes.bool,
};

export default LinkModal;
