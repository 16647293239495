import React from 'react';
import { useSelector } from 'react-redux';
import totalPriceItems from 'core/utils/totalPriceItems';
import withAppContext from 'core/hoc/withAppContext';
import BillSummaryNegotiation from 'components/Payments/Recurrent/BillSummaryNegotiation';
import Button from 'components/Button';
import ButtonRow from 'components/ButtonRow';
import Currency from 'components/Currency';
import LabeledContainer from 'components/LabeledContainer';
import Loader from 'components/Loader';
import ModalContainer from 'components/Modal/ModalContainer';
import ModalTitle from 'components/Modal/ModalTitle';
import OutlineBox from 'components/OutlineBox';
import recurrentStatus from '../RecurrentStatus';
import { formatISODate } from 'core/utils/date';
import UserDisplay from 'components/UserDisplay';

import { ShowBillModalProps } from './types';

import * as S from './styles';

const ShowBillModalNegotiation: React.FC<ShowBillModalProps> = ({
  isOpen,
  toggleModal,
  bill,
}) => {
  const statusTag = () => (
    <>
      {bill && (
        <div className="tag-wrapper">
          <OutlineBox
            variation={recurrentStatus[bill.attributes.status].variation}
          >
            {recurrentStatus[bill.attributes.status].text}
          </OutlineBox>
        </div>
      )}
    </>
  );

  const renderSummary = () => {
    const items = bill.attributes.recurrent_items.data.map(
      ({ attributes }) => ({
        discount_kind: attributes.discount_kind,
        discount_value:
          attributes.discount_kind === 'percent'
            ? attributes.discount_percent
            : attributes.discount_price_cents,
        recurrent_product_id: attributes.product_id,
        price: attributes.price_cents,
        product_name: attributes.product_name,
        status: attributes.product_status,
        key: Math.random().toString(36).substring(7),
      })
    );

    return items;
  };
  const getDiscount = () => {
    const { last_payment } = bill?.attributes.order?.data?.attributes;

    if (last_payment.data?.attributes?.discount) {
      const { discount } = last_payment.data?.attributes;
      const { attributes: attributesDiscount } = discount.data;

      const currentDiscount =
        attributesDiscount.kind === 'money'
          ? attributesDiscount.discount_price_cents
          : (bill?.attributes.price_cents *
              attributesDiscount.discount_percent) /
            100;

      return currentDiscount;
    }

    return 0;
  };

  return (
    <ModalContainer
      className="ShowBillModal"
      isOpen={isOpen}
      toggleModal={toggleModal}
      maxWidth={484}
    >
      {!bill ? (
        <Loader />
      ) : (
        <section className="LabeledContent">
          <S.ShowBillModal>
            <ModalTitle>Detalhes da cobrança</ModalTitle>
            {statusTag()}
            <LabeledContainer title="Aluno">
              <UserDisplay
                user={bill.studentProfile}
                classroomsNames={bill.studentProfile.attributes.classroom_names}
                size="large"
              />
            </LabeledContainer>
            <LabeledContainer title="Responsável financeiro">
              <span>
                {bill.attributes?.name_financial_responsible?.length ? (
                  bill.attributes?.name_financial_responsible?.map((name) => (
                    <p className="name-responsibles" key={name}>
                      {name}
                    </p>
                  ))
                ) : (
                  <p className="message-no-responsible-found">
                    Ainda não foram atribuídos.
                  </p>
                )}
              </span>
            </LabeledContainer>

            <LabeledContainer title="ID da cobrança">
              <span>{bill.id}</span>
            </LabeledContainer>

            <LabeledContainer title="Tipo da cobrança">
              <span>
                {bill.attributes.kind === 'regular' ? 'Regular' : 'Acordo'}
              </span>
            </LabeledContainer>

            <div className="double-column">
              <LabeledContainer title="Valor a pagar">
                <Currency value={bill.attributes.price_cents} />
              </LabeledContainer>

              <LabeledContainer title="Vencimento">
                <span>{bill.attributes.due_date_before_delay}</span>
              </LabeledContainer>
            </div>
            <hr />

            <p className="titleContent">Resumo da negociação</p>

            <div className="double-column resume">
              <LabeledContainer title="ID da Negociação">
                <p>{bill.attributes?.negotiation?.data?.attributes?.id}</p>
              </LabeledContainer>
            </div>

            <div className="double-column resume">
              <LabeledContainer title="Data de criação">
                <p>{formatISODate(bill.attributes.created_at)}</p>
              </LabeledContainer>

              <LabeledContainer title="Venc. da primeira">
                <p>
                  {formatISODate(
                    bill.attributes?.negotiation?.data?.attributes
                      ?.first_due_date
                  )}
                </p>
              </LabeledContainer>
            </div>
            <div className="double-column resume">
              <LabeledContainer title="Venc. demais cobranças">
                <p>
                  {`Todo dia ${bill.attributes?.negotiation?.data?.attributes?.others_due_day} do mês`}
                </p>
              </LabeledContainer>

              <LabeledContainer title="Envio das cobranças">
                <p>
                  {`${bill.attributes?.negotiation?.data?.attributes?.release_day} dias antes do venc.`}
                </p>
              </LabeledContainer>
            </div>
            {bill.attributes?.negotiation?.data?.attributes
              ?.linked_recurrent_bills && (
              <LabeledContainer title="Cobrança vinculada">
                <>
                  {bill.attributes?.negotiation?.data?.attributes?.linked_recurrent_bills?.data.map(
                    (item, index) => (
                      <p className="name-responsibles" key={index}>
                        {`Acordo - ${formatISODate(
                          item.attributes.due_date
                        )} - ID ${item.id}`}
                      </p>
                    )
                  )}
                </>
              </LabeledContainer>
            )}
            {bill.order?.attributes?.details && (
              <LabeledContainer>
                <>
                  <p className="sub-title">Observações da negociação</p>
                  {bill.order?.attributes?.details}
                </>
              </LabeledContainer>
            )}
            <LabeledContainer>
              <BillSummaryNegotiation
                items={renderSummary()}
                discount={getDiscount()}
                allowedPaymentMethod={bill.attributes.allowed_payment_method}
                price={totalPriceItems(bill.attributes.recurrent_items.data)}
                billetTax={bill.attributes.billet_tax}
                pixTax={bill.attributes.pix_tax}
                status={bill.attributes.status}
                penality={
                  bill.attributes.has_penality
                    ? bill.attributes.edupay_penality.data.attributes
                    : null
                }
              />
            </LabeledContainer>
            <ButtonRow>
              <Button variation="secondary" onClick={() => toggleModal()}>
                Fechar
              </Button>
            </ButtonRow>
          </S.ShowBillModal>
        </section>
      )}
    </ModalContainer>
  );
};

export default withAppContext(ShowBillModalNegotiation);
