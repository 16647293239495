import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './style.scss';

function Toast() {
  return (
    <div className="Toast">
      <ToastContainer
        pauseOnVisibilityChange={false}
        pauseOnHover={false}
      />
    </div>
  );
}

export default Toast;
