import styled, { css } from 'styled-components';
import { ThemeProps } from 'config/themes/types';
import { ActionsWrapperProps, VariantMessageType } from './types';

const wrapperModifiers = {
  sent: ({ theme: { colors, border } }: ThemeProps) => css`
    grid-template-areas: '. message avatar' '. info .';
    grid-template-columns: 1fr 70% 0fr;

    ${MessageContainer} {
      grid-template-areas: 'actions content';
      grid-template-columns: 16px 1fr;
      background: ${colors.brand.primary.default};
      border-radius: ${border.radius.lg} ${border.radius.lg} 2px
        ${border.radius.lg};
      margin-left: auto;
    }

    ${ContentWrapper} {
      padding: 12px 12px 12px 0;
    }

    ${MessageContent} {
      margin: 0px;
      color: ${colors.neutral.white};

      &::selection {
        background: ${colors.neutral.white};
        color: ${colors.brand.primary.default};
      }
    }

    ${InfoContainer} {
      justify-content: flex-end;
    }
  `,
  received: ({ theme: { colors, border } }: ThemeProps) => css`
    grid-template-areas: 'avatar message .' '. info .';
    grid-template-columns: 0fr 70% 1fr;

    ${MessageContainer} {
      grid-template-areas: 'content actions';
      grid-template-columns: 1fr 16px;
      background: ${colors.neutral.gray6};
      border-radius: ${border.radius.lg} ${border.radius.lg} ${border.radius.lg}
        2px;
      margin-right: auto;
    }

    ${ContentWrapper} {
      padding: 12px 0 12px 12px;
    }

    ${MessageContent} {
      margin: 8px 0px 0px 0px;
      color: ${colors.neutral.black};
    }

    ${InfoContainer} {
      justify-content: flex-start;
    }
  `,
};

export const AvatarContainer = styled.div`
  display: flex;
  grid-area: avatar;
  justify-content: flex-end;
  align-items: flex-end;
`;

export const MessageContainer = styled.div`
  display: grid;
  grid-area: message;
`;

export const MessageUser = styled.span`
  ${({ theme: { colors, typography } }: ThemeProps) => css`
    ${typography.title.Bold12};

    color: ${colors.brand.primary.default};
  `}
`;

export const MessageContent = styled.p`
  ${({ theme: { colors, typography } }: ThemeProps) => css`
    ${typography.body.Regular16};
    white-space: pre-line;
    word-break: break-word;
    color: ${colors.neutral.black};

    a {
      color: #0645ad;
      text-decoration: underline;

      &:hover {
        color: #032e73;
      }
    }
  `}
`;

export const InfoContainer = styled.div`
  ${({ theme: { colors, typography } }: ThemeProps) => css`
    display: flex;
    grid-area: info;
    ${typography.caption.Regular12};
    color: ${colors.neutral.gray2};
  `}
`;

export const WrapperMessage = styled.div<VariantMessageType>`
  ${({ theme, variantType }) => css`
    display: grid;
    width: 100%;
    padding: 0 0 22px 0;
    gap: 8px;

    ${wrapperModifiers[variantType]({ theme })};

    &:not(:first-child) {
      margin: 22px 0 0 0;
    }
  `}
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-area: content;
`;

const actionsModdfiers = {
  received: ({ theme: { colors } }: ThemeProps) => css`
    bottom: 0;
    left: 50%;

    > div {
      color: ${colors.neutral.white};
      background: ${colors.brand.primary.default};
    }
  `,
  sent: ({ theme: { colors } }: ThemeProps) => css`
    bottom: 0;
    right: 50%;

    > div {
      color: ${colors.brand.primary.default};
      background: ${colors.neutral.gray5};
    }
  `,
};

export const ActionContainer = styled.section<VariantMessageType>`
  ${({ theme, variantType }) => css`
    display: none;
    position: absolute;
    flex-direction: row;
    gap: 4px;

    ${actionsModdfiers[variantType]({ theme })};
  `}
`;

export const ActionsWrapper = styled.div<ActionsWrapperProps>`
  ${({ canSubmitAction }) => css`
    display: flex;
    grid-area: actions;
    width: 100%;
    position: relative;

    ${canSubmitAction &&
    css`
      cursor: pointer;

      &:hover {
        ${ActionContainer} {
          display: flex;
        }
      }
    `}
  `}
`;

const contentLoaderWrapperModifiers = {
  received: () => css`
    display: flex;
    align-items: center;
    justify-content: flex-start;

    svg {
      width: 240px;
      height: 96px;
    }
  `,
  sent: () => css`
    display: flex;
    align-items: center;
    justify-content: flex-end;

    svg {
      width: 176px;
      height: 96px;
    }
  `,
};

export const ContentLoaderWrapper = styled.li<VariantMessageType>`
  ${({ variantType }) => css`
    ${contentLoaderWrapperModifiers[variantType]};
  `}
`;

export const MessageBalloonSkeletonWrapper = styled.ul`
  padding: 0px 0px 8px 0px;
  list-style: none;
`;

export const Image = styled.img`
  ${({ theme: { border } }: ThemeProps) => css`
    width: 100%;
    height: auto;
    border-radius: ${border.radius.lg};
  `}
`;
