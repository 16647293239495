import React, { useCallback, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createFilter } from 'react-select';

import Accordion from 'components/Accordion';
import Button from 'components/Button';
import Field from 'components/Form/Field';
import SideSheet from 'components/SideSheet';
import Loader from 'components/Loader';
import withAppContext from 'core/hoc/withAppContext';
import { optionsFilerByType, optionsFilterByOrigin } from 'core/constants';
import CustomOption from 'pages/ResponsibleProfiles/FormSections/StudentSelect/CustomOption';
import CustomMenuList from 'pages/ResponsibleProfiles/FormSections/StudentSelect/CustomMenuList';

import actionFilters from 'store/filters/actions';
import actionProducts from 'store/edupay/recurrentProducts/actions';

import './style.scss';

const SideSheetFilters = ({
  title,
  isOpen,
  onDismiss,
  handleFilter,
  appContext: { policies, primaryColor },
}) => {
  const dispatch = useDispatch();
  const [newHeadquarter, setNewHeadquarter] = useState();
  const [newClassroom, setNewClassroom] = useState();
  const [newEducationalStage, setNewEducationalStage] = useState();
  const [newFilterType, setNewFilterType] = useState();
  const [newFilterOrigin, setNewFilterOrigin] = useState();
  const [newFilterProducts, setNewFilterProducts] = useState([]);

  const FADE_OUT_DURATION = 250;

  const {
    fetchHeadquartersRequest,
    fetchEducationalStagesRequest,
    fetchClassRoomsRequest,
  } = actionFilters;

  const { fetchRecurrentProducts } = actionProducts;

  const { headquarters, educationalStages, classrooms } = useSelector(
    (state) => state.filters
  );
  const { currentTab } = useSelector((state) => state.schoolProducts);

  const { recurrentProducts, isLoading: isLoadingProducts } = useSelector(
    (state) => state.recurrentProducts
  );

  const handleSetHeadquarters = useCallback(() => {
    dispatch(fetchHeadquartersRequest());
  }, [dispatch, fetchHeadquartersRequest]);

  const setEducationalStages = useCallback(
    (headquarterId) => {
      dispatch(fetchEducationalStagesRequest(headquarterId));
    },
    [dispatch, fetchEducationalStagesRequest]
  );

  const setClassroomFilter = useCallback(
    (headquarterId, educationalStageId) => {
      dispatch(fetchClassRoomsRequest(headquarterId, educationalStageId));
    },
    [dispatch, fetchClassRoomsRequest]
  );

  const getRecurrentProducts = useCallback(() => {
    dispatch(fetchRecurrentProducts({ per_page: 'all' }));
  }, [fetchRecurrentProducts, dispatch]);

  const recurrentProductIds = newFilterProducts.map((product) => product.value);

  const hasHeadquarters = () => headquarters && headquarters.length > 0;

  const hasEducationalStages = () =>
    hasHeadquarters() && newHeadquarter && educationalStages.length > 0;

  const hasClassrooms = () =>
    hasEducationalStages() && newEducationalStage && classrooms.length > 0;

  const applyFilters = (parameters) => {
    document
      .getElementById('side-sheet-wrapper')
      .classList.add('fade-out-animation');
    document.getElementById('side-sheet').classList.add('slide-out-animation');
    setTimeout(() => {
      handleFilter(parameters);
      onDismiss();
    }, FADE_OUT_DURATION);
  };

  const handleApplyFilters = () => {
    applyFilters({
      headquarter_id: newHeadquarter,
      educational_stage_id: newEducationalStage,
      classroom_id: newClassroom,
      plan_kind: newFilterType,
      source: newFilterOrigin,
      recurrent_product_ids: recurrentProductIds,
      page: 1,
    });
  };

  const handleClearFilters = () => {
    setNewHeadquarter(null);
    setNewEducationalStage(null);
    setNewClassroom(null);
    setNewFilterType(null);
    setNewFilterOrigin(null);
    setNewFilterProducts([]);
    applyFilters({
      headquarter_id: null,
      educational_stage_id: null,
      classroom_id: null,
      plan_kind: null,
      source: null,
      recurrent_product_ids: null,
    });
  };

  useEffect(() => {
    handleSetHeadquarters();
  }, [handleSetHeadquarters]);

  useEffect(() => {
    getRecurrentProducts();
  }, []);

  const renderFiltersTitle = () => {
    return <h2 className="accordion-title">Unidade/Segmento/Turma</h2>;
  };

  const renderFiltersTitleFilerByType = () => {
    return <h2 className="accordion-title">Tipo de cobrança</h2>;
  };

  const renderFiltersTitleFilterByProducts = () => {
    return <h2 className="accordion-title">Produtos</h2>;
  };

  const renderFiltersAccordionBody = () => (
    <>
      <Field
        attributeName="headquarter_selector"
        label="Unidade"
        type="selectWithIcon"
        placeholder="Selecione a unidade"
        value={newHeadquarter}
        options={headquarters}
        disabled={!hasHeadquarters()}
        onChange={(event) => {
          setNewEducationalStage(null);
          setNewClassroom(null);
          setNewHeadquarter(event.value);
          setEducationalStages(event.value);
        }}
        withArrowSelect
      />
      <Field
        attributeName="educational_stages"
        label="Segmento"
        type="selectWithIcon"
        placeholder="Selecione o segmento"
        value={newEducationalStage}
        options={educationalStages}
        disabled={!hasEducationalStages()}
        onChange={(event) => {
          setNewClassroom(null);
          setNewEducationalStage(event.value);
          setClassroomFilter(newHeadquarter, event.value);
        }}
        withArrowSelect
      />
      <Field
        attributeName="classrooms"
        label="Turma"
        type="selectWithIcon"
        value={newClassroom}
        placeholder="Selecione a turma"
        options={classrooms}
        disabled={!hasClassrooms()}
        onChange={(event) => setNewClassroom(event.value)}
        withArrowSelect
        isSearchable
      />
    </>
  );

  const renderFiltersByType = () => (
    <>
      <Field
        attributeName="headquarter_selector"
        label="Cobrança"
        type="selectWithIcon"
        placeholder="Selecione o tipo"
        value={newFilterType}
        options={optionsFilerByType}
        onChange={(event) => {
          setNewFilterType(event.value);
        }}
        disabled={!hasHeadquarters()}
        withArrowSelect
      />

      <Field
        attributeName="origin_selector"
        label="Origem da Cobrança"
        type="selectWithIcon"
        placeholder="Selecione a origem"
        value={newFilterOrigin}
        options={optionsFilterByOrigin}
        onChange={(event) => {
          setNewFilterOrigin(event.value);
        }}
        disabled={!hasHeadquarters()}
        withArrowSelect
      />
    </>
  );

  const renderFilterByProducts = () => (
    <Field
      fullWidth
      menuPlacement="top"
      isMulti
      withArrowSelect
      label="Produtos recorrentes"
      type="selectWithIcon"
      attributeName="recurrent_products"
      rawValue={newFilterProducts}
      onChange={(value) => setNewFilterProducts(value)}
      options={recurrentProducts?.map((product) => ({
        label: product.attributes.name,
        value: product.id,
      }))}
      filterOption={createFilter({ ignoreAccents: false })}
      components={{
        MenuList: (props) => (
          <CustomMenuList {...props} loading={isLoadingProducts} />
        ),
        Option: CustomOption,
        IndicatorSeparator: () => null,
        DropdownIndicator: () =>
          isLoadingProducts ? <Loader size={20} color={primaryColor} /> : null,
      }}
      placeholder="Selecione"
      isSearchable={true}
    />
  );

  return (
    <SideSheet isOpen={isOpen} onDismiss={onDismiss} title={title}>
      <div className="side-sheet-filters">
        <div>
          <hr className="divider" />
          <Accordion
            header={renderFiltersTitle()}
            body={renderFiltersAccordionBody()}
            allowZeroExpanded
            expanded="edupay"
          />
          {policies?.can_use_shop && currentTab === 0 && (
            <>
              <hr className="divider" />
              <Accordion
                header={renderFiltersTitleFilerByType()}
                body={renderFiltersByType()}
                allowZeroExpanded
                expanded="edupay"
              />
            </>
          )}
          {currentTab === 1 && (
            <Accordion
              header={renderFiltersTitleFilterByProducts()}
              body={renderFilterByProducts()}
              allowZeroExpanded
              expanded="edupay"
            />
          )}
        </div>
        <div className="button-row">
          <Button
            id="apply-filters-button"
            variation="primary"
            className="left-button"
            onClick={handleApplyFilters}
          >
            Aplicar
          </Button>
          <Button
            id="clear-filters-button"
            variation="secondary"
            className="right-button"
            onClick={handleClearFilters}
          >
            Limpar
          </Button>
        </div>
      </div>
    </SideSheet>
  );
};

export default withAppContext(SideSheetFilters);
