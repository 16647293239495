import styled, { css } from 'styled-components';
import { ThemeProps } from 'config/themes/types';

import { Grid } from '@agendaedu/ae-web-components';

type InformationsWrapperProps = {
  isOneColumn?: boolean;
  theme?: ThemeProps;
};

export const Container = styled.div`
  ${({ theme: { font, colors, space } }: ThemeProps) => css`
    .side-sheet-wrapper {
      background-color: ${colors.neutral.modalBackground};
      z-index: 9999999;
      overflow-y: scroll;

      .side-sheet {
        width: auto;
        min-height: 100%;
        height: auto;

        .header {
          margin: ${space.sm} ${space.lg};
          align-items: center;

          .PageTitle {
            font-size: ${font.size.lg};
            font-weight: 700;
            margin: 0;
          }

          .AgendaIcon {
            color: ${colors.neutral.gray1};
          }
        }
      }
    }
  `}
`;

export const Divider = styled.hr`
  ${({ theme: { colors, border } }: ThemeProps) => css`
    border-bottom: ${border.width.sm} solid ${colors.neutral.gray5};
    margin: 0;
  `}
`;

export const StudentWrapper = styled.div`
  ${({ theme: { space, colors, typography, border } }: ThemeProps) => css`
    margin: ${space.sm} ${space.lg} ${space.lg};

    .name {
      color: ${colors.neutral.gray1} !important;
    }

    .ClassroomsList {
      margin-top: ${space.xs2} !important;

      .OutlineBox {
        padding: ${space.xs2};
        border-radius: ${border.radius.sm};
        background-color: ${colors.neutral.gray5};
        margin: 0;
        ${typography.subtitle.Medium12}
        line-height: 100%;
      }
    }
  `}
`;

export const InformationsWrapper = styled(Grid)<InformationsWrapperProps>`
  ${({ theme: { space, colors }, isOneColumn }) => css`
    grid-template-columns: ${isOneColumn
      ? `1fr !important`
      : `repeat(2, 1fr) !important`};
    gap: ${space.lg} !important;
    margin: ${space.lg};

    .tooltip-div {
      display: flex;
      align-items: center;
      margin: 0 !important;

      svg {
        fill: ${colors.neutral.gray1};
      }
    }
  `}
`;

export const Information = styled(Grid)<InformationsWrapperProps>`
  ${({ isOneColumn }) => css`
    width: ${isOneColumn ? `328px` : `152px`};
    word-break: break-word;
  `}
`;
