const maxFinePercent = 2.00;
const maxInterestPercent = 1.0;

export const validateFinePercent = ({ fine_percent }) => {
  return fine_percent > 0 && fine_percent <= maxFinePercent;
};

export const validateInterestPercent = ({ interest_percent }) => {
  return interest_percent > 0 && interest_percent <= maxInterestPercent;
};
