import FormFieldset from 'components/Form/Fieldset';
import styled from 'styled-components';

export const HandoutsFormContainer = styled.main`
  .WizardForm .wizard-content .wizard-taps-header .PageTitle {
    min-width: fit-content;
  }
`;

export const HandoutTemplatesStepWrapper = styled(FormFieldset)``;
