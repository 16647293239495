import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  > .Field {
    padding: 0;
    margin-bottom: 0 !important;

    .DateInput {
      width: 100%;
    }
  }

  > .checkboxes-wrapper {
    padding: 0;
    margin: 0;
  }

  > .checkbox-option-generate-pix {
    display: flex;
    flex-direction: row;
    margin-top: -8px;

    .checkbox-label {
      margin-left: 16px;
      cursor: pointer;
    }
  }
`;

export const AlertWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  > .Title {
    margin-bottom: 0 !important;
  }

  > .Alert {
    display: flex;
    height: 100%;
  }
`;

export const FinancialResponsibleInformation = styled.p`
  margin-bottom: 0 !important;
`;
