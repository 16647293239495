import styled, { css } from 'styled-components';
import colorWithAlpha from 'core/utils/colorWithAlpha';

export const Wrapper = styled.div`
  .TableEntries {
    span {
      b {
        color: ${(props) => props.theme.danger};
      }
    }
  }
`;

export const TableWrapper = styled.div`
  ${() => css`
    .TableContainer {
      .Table.table {
        tbody {
          background-color: ${(props) =>
            colorWithAlpha(props.theme.danger, 0.1)};
        }
      }
    }
  `}
`;
