import { Text } from '@agendaedu/ae-web-components';
import { ThemeProps } from 'config/themes/types';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    flex-direction: column;
    gap: ${space.xs};
  `}
`;

export const RecipientSelectorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  input {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
`;

export const RecipientHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const CountTotalRecipientsText = styled(Text)`
  ${({ theme: { space, colors } }: ThemeProps) => css`
    color: ${colors.neutral.black};
    margin: ${space.xs4};
  `}
`;

export const SelectorWrapper = styled.div`
  ${({ theme: { border, colors, space } }: ThemeProps) => css`
    width: 100%;
    border: ${border.style.solid} ${border.width.sm} ${colors.neutral.gray4};
    border-top: none;
    border-radius: 0 0 ${border.radius.md} ${border.radius.md};
    max-height: 220px;
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      background-color: transparent;
      width: ${space.xs2};
    }

    &::-webkit-scrollbar-button {
      display: none;
    }

    &::-webkit-scrollbar-track {
      background-color: ${colors.neutral.gray5};
    }

    &::-webkit-scrollbar-thumb {
      border-radius: ${border.radius.sm};
      border: ${border.width.sm} ${border.style.solid} ${colors.neutral.gray4};
    }
  `}
`;
