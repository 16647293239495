import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import ModalSurveyAction from 'pages/surveys/ModalSurveyAction';

import appContextProvider from 'core/hoc/appContextProvider';

import {
  Box,
  FlexGrid,
  FlexGridItem,
  SubtleIconButton,
  Tag,
  RecipientTag,
  Text,
} from '@agendaedu/ae-web-components';

import { useTranslation } from 'react-i18next';

const InfoSurvey = ({ survey, history }) => {
  const { t } = useTranslation('surveys');
  const [showModal, setShowModal] = useState(false);
  const [modalVariation, setModalVariation] = useState('');
  const { dataArea } = useSelector((state) => state.root);
  const { surveysIncluded } = useSelector((state) => state.surveys);

  const createdAt = moment(survey.attributes.createdAt, 'DD/MM/YYYY').format(
    t('list.card.created_at_pattern')
  );

  const limitDate = moment(
    survey.attributes.limitDatetime,
    'DD/MM/YYYY'
  ).format('DD/MM/YYYY');

  const toggleModal = (modalVariation) => {
    setShowModal(!showModal);
    setModalVariation(modalVariation);
  };

  const userPermission = surveysIncluded.find(
    (include) =>
      include.type === 'userPermission' &&
      include.id === survey.relationships.userPermission.data.id
  );

  const openSurvey = (e) => {
    e.stopPropagation();
    history.push(`/${dataArea}/surveys/${survey.id}`);
  };

  const editSurvey = (e) => {
    e.stopPropagation();
    history.push(`/${dataArea}/surveys/${survey.id}/edit`);
  };

  const duplicateSurvey = (e) => {
    e.stopPropagation();
    history.push(`/${dataArea}/surveys/new/${survey.attributes.kind}`, {
      duplicateSurveyId: survey.id,
    });
  };

  const getActions = () => {
    const actions = [];

    actions.push({
      as: 'button',
      title: t('list.card.actions.open'),
      onClick: openSurvey,
    });

    userPermission?.attributes.canApprove &&
      ['pending'].includes(survey.attributes.status) &&
      actions.push({
        as: 'button',
        title: t('list.card.actions.approve'),
        onClick: (e) => {
          e.stopPropagation();
          toggleModal('approve');
        },
      });

    userPermission?.attributes.canCreate &&
      actions.push({
        as: 'button',
        title: t('list.card.actions.duplicate'),
        onClick: duplicateSurvey,
      });

    userPermission?.attributes.canUpdate &&
      ['pending', 'rejected', 'approved'].includes(survey.attributes.status) &&
      actions.push({
        as: 'button',
        title: t('list.card.actions.edit'),
        onClick: editSurvey,
      });

    userPermission?.attributes.canDestroy &&
      ['pending', 'opened', 'rejected', 'approved'].includes(
        survey.attributes.status
      ) &&
      actions.push({
        as: 'button',
        title: t('list.card.actions.delete'),
        onClick: (e) => {
          e.stopPropagation();
          toggleModal('delete');
        },
      });

    return actions;
  };

  const recipientTag = useMemo(() => {
    const recipientType =
      survey?.attributes.sentKind === 'students'
        ? t('list.card.recipients.students')
        : t('list.card.recipients.classrooms');

    const recipients = survey?.relationships.recipients.data.reduce(
      (prev, current) => {
        const relatedItem = surveysIncluded.find(
          (include) =>
            include.id === current.id &&
            include.type ===
              (survey?.attributes.sentKind === 'students'
                ? 'studentProfile'
                : 'classroom')
        );

        return relatedItem ? [...prev, relatedItem.attributes] : prev;
      },
      []
    );

    if (!recipients?.length)
      return {
        type: t('list.card.recipients.empty_recipients'),
        recipients: [{ name: t('list.card.recipients.empty_recipients') }],
      };

    return {
      type: recipientType,
      recipients,
    };
  }, [
    survey?.attributes.sentKind,
    survey?.relationships.recipients.data,
    surveysIncluded,
    t,
  ]);

  const surveyStatus = {
    pending: t('list.card.status.pending'),
    opened: t('list.card.status.opened'),
    closed: t('list.card.status.closed'),
    rejected: t('list.card.status.rejected'),
    deleted: t('list.card.status.deleted'),
    processing: t('list.card.status.processing'),
    approved: t('list.card.status.approved'),
  };

  const surveyStatusVariant = {
    pending: 'warning',
    opened: 'positive',
    closed: 'informative',
    rejected: 'negative',
    deleted: 'neutral',
    processing: 'warning',
    approved: 'positive',
  };

  const surveyKind = {
    poll: 'Enquete',
    authorization: 'Autorização',
  };

  const statusBoxProps = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100%',
  };

  return (
    <Box cursor="pointer" width="100%">
      <FlexGrid>
        <FlexGridItem cols={{ desktopLG: 3 }} filled>
          <Box>
            <Text variant="subtitle-medium-12">{createdAt}</Text>
            <Text variant="subtitle-medium-16" mb={0}>
              {survey.attributes.title}
            </Text>
          </Box>
        </FlexGridItem>

        <FlexGridItem cols={{ desktopLG: 4 }} filled>
          <Box
            display="flex"
            alignItems="center"
            justifyContent={{ desktopLG: 'space-evenly' }}
          >
            <Box mr="md">
              <Text variant="subtitle-medium-12">
                {t('list.card.limit_date')}
              </Text>
              <Text variant="subtitle-medium-16" mb={0}>
                {limitDate}
              </Text>
            </Box>

            <Box>
              <Text variant="subtitle-medium-12">
                {t('list.card.responses')}
              </Text>
              <Text variant="subtitle-medium-16" mb={0}>
                {t('list.card.responses_count', {
                  surveyAnsweredCount: survey.attributes.surveyAnsweredCount,
                  surveySentCount: survey.attributes.surveySentCount,
                })}
              </Text>
            </Box>
          </Box>
        </FlexGridItem>

        <FlexGridItem cols={{ desktopLG: 4 }} filled>
          <Box {...statusBoxProps}>
            <FlexGrid>
              <FlexGridItem filled>
                <RecipientTag
                  variant="neutral"
                  recipientType={recipientTag.type}
                  recipients={recipientTag.recipients}
                  modalTitle={recipientTag.type}
                  size="small"
                  enableEllipsis
                />
              </FlexGridItem>

              <FlexGridItem filled>
                <Tag
                  variant={surveyStatusVariant[survey.attributes.status]}
                  name={surveyStatus[survey.attributes.status]}
                  size="small"
                />
              </FlexGridItem>

              <FlexGridItem filled>
                <Tag
                  variant="brand"
                  name={surveyKind[survey.attributes.kind]}
                  size="small"
                />
              </FlexGridItem>
            </FlexGrid>

            <ModalSurveyAction
              showModal={showModal}
              toggleModal={toggleModal}
              variation={modalVariation}
              survey={survey}
            />
          </Box>
        </FlexGridItem>

        <FlexGridItem cols={{ desktopLG: 1 }}>
          <Box position={{ desktopLG: 'relative' }}>
            <Box
              position="absolute"
              top={{ _: 12, desktopLG: 0 }}
              bottom={0}
              right={12}
            >
              <SubtleIconButton actions={getActions()} align="right" />
            </Box>
          </Box>
        </FlexGridItem>
      </FlexGrid>
    </Box>
  );
};

const InfoSurveyShape = {
  id: PropTypes.string,
  attributes: PropTypes.object,
};

InfoSurvey.propTypes = {
  survey: PropTypes.shape(InfoSurveyShape).isRequired,
  history: PropTypes.object,
};

export default withRouter(appContextProvider(InfoSurvey));
