import React from 'react';
import { CancelPaymentProps } from './types';
import withFormContext from 'core/hoc/withFormContext';
import { useDispatch } from 'react-redux';
import actionRecurrentBills from 'store/edupay/recurrentBills/actions';

import Field from 'components/Form/Field';
import Buttons from '../Buttons';

import * as S from './styles';

function CancelPayment({
  formContext,
  toggleModal,
  onSubmit,
  isRecurrent,
}: CancelPaymentProps) {
  const { form, updateAttribute } = formContext;
  const { bill } = form;

  const dispatch = useDispatch();
  const { cancelBillRequest } = actionRecurrentBills;

  const { attributes, id } = form.bill;
  const { cancelNote, kind } = form;

  const handleManuallyCancel = (): void => {
    dispatch(
      cancelBillRequest({
        planId: attributes.recurrent_plan_id,
        billId: id,
        kind,
        note: cancelNote,
      })
    );
    toggleModal();
  };

  return (
    <S.Container testID="cancelWrapper">
      <S.Title>
        A emissão da cobrança do dia{' '}
        <strong>
          {bill.attributes.release_day || bill.attributes.expires_at}
        </strong>{' '}
        será cancelada e não será enviada para o responsável do aluno.
      </S.Title>

      <S.NoteCancelField>
        <Field
          label="Observações (opcional)"
          type="simpleTextArea"
          attributeName="cancelNote"
          onChange={(e) => {
            updateAttribute('cancelNote', e.cancelNote);
          }}
          maxLength="255"
        />
        <S.LabelCounter>{form?.cancelNote?.length || 0}/255</S.LabelCounter>
      </S.NoteCancelField>

      <S.WantToCancelField>
        <S.Label>
          Digite <strong>QUERO CANCELAR</strong> no campo abaixo
        </S.Label>
        <Field
          attributeName="cancelTerm"
          onChange={(e) => {
            updateAttribute('cancelTerm', e.cancelTerm);
          }}
        />
      </S.WantToCancelField>

      <Buttons
        title={'Cancelar Cobrança'}
        isDisable={form?.cancelTerm !== 'QUERO CANCELAR'}
        variation={'danger'}
        toggleModal={toggleModal}
        manuallySubmit={() =>
          isRecurrent ? handleManuallyCancel() : onSubmit(form)
        }
      />
    </S.Container>
  );
}

export default withFormContext(CancelPayment);
