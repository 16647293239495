import React from 'react';
import { Box, DefaultThemeProps, theme } from '@agendaedu/ae-web-components';

import Skeleton from 'components/Skeleton';

export const ContractModelListSkeleton = () => {
  const defaultTheme: DefaultThemeProps = theme;

  const {
    space,
    border: { radius },
  } = defaultTheme;

  return (
    <>
      {Array.from(Array(5).keys()).map((index) => (
        <Box
          mt={space.sm}
          key={index}
          data-testid="contract-model-list-skeleton"
        >
          <Skeleton
            isFullWidth
            width="100%"
            height={80}
            borderRadius={radius.md}
          />
        </Box>
      ))}
    </>
  );
};
