import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import withFormContext from 'core/hoc/withFormContext';
import validateCpf from 'core/lib/FormValidator/validators/cpf';

import ButtonRow from 'components/ButtonRow';
import RadioButton from 'components/RadioButton';
import UserItem from 'components/UserItem';
import Avatar from 'components/Avatar';
import Field from 'components/Form/Field';
import Alert from 'components/Alert';
import * as S from './styles';

const ModalFormByMethod = ({
  method,
  toggleModal,
  responsibles,
  onSubmit,
  formContext: {
    updateAttribute,
    addErrorOnAttribute,
    removeErrorOnAttribute,
    form,
  },
}) => {
  const { t } = useTranslation(['payments']);
  const [showInput, setShowInput] = useState(false);
  const [isInvalidRegistration, setInvalidRegistration] = useState(false);
  const policies = useSelector((state) => state.root.policies);
  const link = `/schools/responsible_profiles/${form?.responsible_profile_id}/edit`;

  const onClick = useCallback(
    (responsible) => {
      updateAttribute('cpf', '');
      updateAttribute('responsible_profile_id', responsible.id);
      setShowInput(false);

      if (
        (method === 'pix' || isZoopProvider()) &&
        !responsible?.attributes?.user?.data?.attributes?.has_full_data
      ) {
        setShowInput(false);
        return setInvalidRegistration(true);
      }

      if (
        (method === 'pix' || isZoopProvider()) &&
        responsible?.attributes?.user?.data?.attributes?.has_full_data
      ) {
        setShowInput(false);
        return setInvalidRegistration(false);
      }

      if (
        responsible.attributes.user &&
        responsible?.attributes?.user?.data?.attributes?.document_number !==
          null &&
        responsible?.attributes?.user?.data?.attributes?.document_number !== ''
      ) {
        return updateAttribute(
          'cpf',
          responsible.attributes.user.data.attributes.document_number
        );
      }

      setInvalidRegistration(false);
      return setShowInput(true);
    },
    [
      updateAttribute,
      setShowInput,
      setInvalidRegistration,
      isZoopProvider,
      method,
    ]
  );

  const isZoopProvider = useCallback(() => {
    return policies.edupay_provider === 'zoop';
  }, [policies]);

  const isDisabled = () => {
    if (method === 'pix' || isZoopProvider()) {
      return isInvalidRegistration;
    }

    return form.cpf === '';
  };

  const onSubmitModal = () => {
    const regexValidation = validateCpf(form.cpf);

    if (method === 'pix' || isZoopProvider() || regexValidation) {
      removeErrorOnAttribute('cpf');
      onSubmit(form);
    } else {
      addErrorOnAttribute('cpf', 'CPF inválido');
    }
  };

  return (
    <React.Fragment>
      <div className="UserItemWrapper">
        {responsibles.map((responsible) => (
          <UserItem
            key={responsible.id}
            onClick={() => onClick(responsible)}
            userName={responsible.attributes.name}
            userRole={responsible.attributes.role}
            highlighted={responsible.id === form.responsible_profile_id}
            avatarComponent={<Avatar user={responsible} size="small" />}
            actionComponent={
              <RadioButton
                attributeName="responsibleId"
                value={responsible.id}
                checked={responsible.id === form.responsible_profile_id}
                simpleInputRadio
              />
            }
          />
        ))}
      </div>
      {showInput && (
        <div className="row cpfWrapper">
          <Field
            type="masked"
            mask="999.999.999-99"
            label="Digite o CPF*"
            placeholder="CPF"
            attributeName="cpf"
            size="half"
          />
        </div>
      )}
      {isInvalidRegistration && (
        <S.InforContainer>
          <Alert variation="danger">
            Atualize os dados cadastrais do responsável para realizar esta ação.
            São necessários os dados de CPF e endereço.{' '}
            <Link to={link} target="_blank" onClick={toggleModal}>
              Clique aqui
            </Link>{' '}
            para atualizar.
          </Alert>
        </S.InforContainer>
      )}
      <ButtonRow
        buttons={[
          {
            text: 'Cancelar',
            variation: 'secondary',
            onClick: toggleModal,
          },
          {
            text: `${t(`modals.generate.${method}.buttons.submit`)}`,
            disabled: isDisabled(),
            onClick: onSubmitModal,
          },
        ]}
      />
    </React.Fragment>
  );
};

ModalFormByMethod.defaultProps = {
  responsibles: [],
  method: 'billet',
};

ModalFormByMethod.propTypes = {
  method: PropTypes.string,
  toggleModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  responsibles: PropTypes.array,
  formContext: PropTypes.object,
};

export default withFormContext(ModalFormByMethod);
