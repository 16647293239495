import React from 'react';
import PropTypes from 'prop-types';

import Label from 'components/Form/Label';
import RadioButtonSimple from 'components/Form/RadioButtonSimple';
import RadioGroup from 'components/Form/RadioGroup';
import Select from 'components/Form/Select';
import SelectWithIcon from 'components/Form/SelectWithIcon';
import TextField from 'components/Form/TextField';
import DateField from 'components/Form/DateField';
import TimeField from 'components/Form/TimeField';
import ImageField from 'components/Form/ImageField';
import MaskedField from 'components/Form/MaskedField';
import CurrencyField from 'components/Form/CurrencyField';
import TextArea from 'components/Form/TextArea';
import SimpleTextArea from 'components/Form/SimpleTextArea';
import TextFieldWithIcon from 'components/Form/TextFieldWithIcon';
import TextFieldWithSelect from 'components/Form/TextFieldWithSelect';
import RangeDateField from 'components/Form/RangeDateField';
import NumberField from 'components/Form/NumberField';
import PeriodField from 'components/Form/PeriodField';
import RangeTimeField from 'components/Form/RangeTimeField';
import CopyField from 'components/Form/CopyField';

import './style.scss';

const SIZES_MAPPING = {
  extraSmall: 'col-md-2',
  smaller: 'col-md-3',
  small: 'col-md-4',
  half: 'col-md-6',
  medium: 'col-md-8',
  large: 'col-md-12',
};

const MOBILE_SIZE_MAPPING = {
  small: 'col-xs-3',
  medium: 'col-xs-6',
  large: 'col-xs-9',
  extraLarge: 'col-xs-12',
};

const COMPONENT_MAPPING = {
  simpleRadio: RadioButtonSimple,
  radio: RadioGroup,
  select: Select,
  selectWithIcon: SelectWithIcon,
  text: TextField,
  date: DateField,
  time: TimeField,
  image: ImageField,
  masked: MaskedField,
  currency: CurrencyField,
  textArea: TextArea,
  simpleTextArea: SimpleTextArea,
  textWithIcon: TextFieldWithIcon,
  textWithSelect: TextFieldWithSelect,
  rangeDate: RangeDateField,
  number: NumberField,
  period: PeriodField,
  rangeTime: RangeTimeField,
  copy: CopyField,
};

class Field extends React.Component {
  getSize() {
    return SIZES_MAPPING[this.props.size];
  }

  getComponentFromType() {
    return COMPONENT_MAPPING[this.props.type];
  }

  getTagProps() {
    const notAllowedProps = ['label', 'row', 'children'];

    const tagProps = Object.keys(this.props)
      .filter((key) => !notAllowedProps.includes(key))
      .reduce((tagProps, key) => {
        tagProps[key] = this.props[key];
        return tagProps;
      }, {});

    return tagProps;
  }

  render() {
    const { label, size, mobileSize, orientation, className } = this.props;

    const Tag = this.getComponentFromType();

    return (
      <div
        className={`Field ${MOBILE_SIZE_MAPPING[mobileSize]} ${SIZES_MAPPING[size]} ${orientation} ${className}`}
      >
        {label && <Label>{label}</Label>}
        <Tag {...this.getTagProps()} />
      </div>
    );
  }
}

Field.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  className: PropTypes.string,
  attributeName: PropTypes.string,
  size: PropTypes.oneOf([
    'extraSmall',
    'smaller',
    'small',
    'medium',
    'half',
    'large',
  ]).isRequired,
  mobileSize: PropTypes.oneOf(['small', 'medium', 'large', 'extraLarge'])
    .isRequired,
  type: PropTypes.oneOf([
    'image',
    'text',
    'select',
    'selectWithIcon',
    'simpleRadio',
    'radio',
    'date',
    'time',
    'masked',
    'currency',
    'textArea',
    'simpleTextArea',
    'textWithIcon',
    'number',
    'textWithSelect',
    'period',
    'rangeTime',
    'rangeDate',
    'copy',
  ]).isRequired,
  disabled: PropTypes.bool,
};

Field.defaultProps = {
  size: 'large',
  mobileSize: 'extraLarge',
  type: 'text',
  orientation: 'vertical',
  className: '',
};

export default Field;
