const actions = {
  ERROR: 'ERROR/surveys',
  ADD_SURVEY_REQUEST: 'ADD_SURVEY_REQUEST/surveys',
  ADD_SURVEY_SUCCESS: 'ADD_SURVEY_SUCCESS/surveys',
  FETCH_SURVEYS_REQUEST: 'FETCH_SURVEYS_REQUEST/surveys',
  FETCH_SURVEYS_SUCCESS: 'FETCH_SURVEYS_SUCCESS/surveys',
  RESET_SURVEY_LIST: 'RESET_SURVEY_LIST/surveys',
  FETCH_SURVEY_DETAILS_REQUEST: 'FETCH_SURVEY_DETAILS_REQUEST/survey',
  FETCH_SURVEY_DETAILS_SUCCESS: 'FETCH_SURVEY_DETAILS_SUCCESS/survey',
  FETCH_SURVEY_FORM_REQUEST: 'FETCH_SURVEY_FORM_REQUEST/survey',
  FETCH_SURVEY_FORM_SUCCESS: 'FETCH_SURVEY_FORM_SUCCESS/survey',
  UPDATE_SURVEY_REQUEST: 'UPDATE_SURVEY_REQUEST/survey',
  UPDATE_SURVEY_SUCCESS: 'UPDATE_SURVEY_SUCCESS/survey',
  FETCH_SURVEY_QUESTIONS_REQUEST:
    'FETCH_SURVEY_QUESTIONS_REQUEST/surveyQuestions',
  FETCH_SURVEY_QUESTIONS_SUCCESS:
    'FETCH_SURVEY_QUESTIONS_SUCCESS/surveyQuestions',
  FETCH_SURVEY_AUTHORIZATION_QUESTIONS_REQUEST:
    'FETCH_SURVEY_AUTHORIZATION_QUESTIONS_REQUEST/surveyQuestions',
  FETCH_SURVEY_AUTHORIZATION_QUESTIONS_SUCCESS:
    'FETCH_SURVEY_AUTHORIZATION_QUESTIONS_SUCCESS/surveyQuestions',
  FETCH_SURVEYS_DELETE_REQUEST: 'FETCH_SURVEYS_DELETE_REQUEST/surveys',
  FETCH_SURVEYS_DELETE_SUCCESS: 'FETCH_SURVEYS_DELETE_SUCCESS/surveys',
  FETCH_SURVEYS_RESEND_REQUEST: 'FETCH_SURVEYS_RESEND_REQUEST/surveys',
  FETCH_SURVEYS_RESEND: 'FETCH_SURVEYS_RESEND/surveys',
  APPROVE_SURVEY_REQUEST: 'APPROVE_SURVEY_REQUEST/surveys',
  APPROVE_SURVEY_SUCCESS: 'APPROVE_SURVEY_SUCCESS/surveys',
  DISAPPROVE_SURVEY_REQUEST: 'DISAPPROVE_SURVEY_REQUEST/surveys',
  DISAPPROVE_SURVEY_SUCCESS: 'DISAPPROVE_SURVEY_SUCCESS/surveys',
  SET_CLASSROOMS_REQUEST: 'SET_CLASSROOMS_REQUEST/surveys',
  SET_CLASSROOMS_SUCCESS: 'SET_CLASSROOMS_SUCCESS/surveys',
  BUILD_TOAST: 'BUILD_TOAST/surveys',
  BUILD_TOAST_SUCCESS: 'BUILD_TOAST_SUCCESS/surveys',
  SET_CURRENT_FORM_REQUEST: 'SET_CURRENT_FORM_REQUEST/surveys',
  SET_CURRENT_FORM_SUCCESS: 'SET_CURRENT_FORM_SUCCESS/surveys',
  FETCH_EDUCATIONAL_STAGES_REQUEST: 'SET_EDUCATIONAL_STAGES_REQUEST/surveys',
  FETCH_EDUCATIONAL_STAGES_SUCCESS: 'SET_EDUCATIONAL_STAGES_SUCCESS/surveys',
  FETCH_CLASSROOM_FILTER_REQUEST: 'FETCH_CLASSROOM_FILTER_REQUEST/surveys',
  FETCH_CLASSROOM_FILTER_SUCCESS: 'FETCH_CLASSROOM_FILTER_SUCCESS/surveys',
  FETCH_SURVEY_VERSIONS_REQUEST: 'FETCH_SURVEY_VERSIONS_REQUEST/surveys',
  FETCH_SURVEY_VERSIONS_SUCCESS: 'FETCH_SURVEY_VERSIONS_SUCCESS/surveys',
  addSurvey: (params, history) => ({
    type: actions.ADD_SURVEY_REQUEST,
    params,
    history,
  }),
  fetchSurveys: (queryParams) => ({
    type: actions.FETCH_SURVEYS_REQUEST,
    queryParams,
  }),
  resetSurveyList: () => ({ type: actions.RESET_SURVEY_LIST }),
  fetchSurveyDetails: (surveyId) => ({
    type: actions.FETCH_SURVEY_DETAILS_REQUEST,
    surveyId,
  }),
  fetchEducationalStages: (headquarterValue) => ({
    type: actions.FETCH_EDUCATIONAL_STAGES_REQUEST,
    headquarterValue,
  }),
  fetchClassroomFilter: (headquarterValue, educationalStageValue) => ({
    type: actions.FETCH_CLASSROOM_FILTER_REQUEST,
    headquarterValue,
    educationalStageValue,
  }),
  fetchSurveyForm: (surveyId) => ({
    type: actions.FETCH_SURVEY_FORM_REQUEST,
    surveyId,
  }),
  updateSurvey: (surveyId, params, history) => ({
    type: actions.UPDATE_SURVEY_REQUEST,
    surveyId,
    params,
    history,
  }),
  fetchSurveyQuestions: (surveyId, params) => ({
    type: actions.FETCH_SURVEY_QUESTIONS_REQUEST,
    surveyId,
    params,
  }),
  fetchSurveyAuthorizationQuestions: (surveyId, params, nextLink) => ({
    type: actions.FETCH_SURVEY_AUTHORIZATION_QUESTIONS_REQUEST,
    surveyId,
    params,
    nextLink,
  }),
  fetchSurveyDeleteRequest: (survey, callbackSuccess) => ({
    type: actions.FETCH_SURVEYS_DELETE_REQUEST,
    survey,
    callbackSuccess,
  }),
  fetchResendSurveyRequest: (survey) => ({
    type: actions.FETCH_SURVEYS_RESEND_REQUEST,
    survey,
  }),
  approveSurveyRequest: (survey, callbackSuccess) => ({
    type: actions.APPROVE_SURVEY_REQUEST,
    survey,
    callbackSuccess,
  }),
  disapproveSurveyRequest: (survey, cancelReason) => ({
    type: actions.DISAPPROVE_SURVEY_REQUEST,
    survey,
    cancelReason,
  }),
  setClassrooms: () => ({ type: actions.SET_CLASSROOMS_REQUEST }),
  setCurrentForm: (current) => ({
    type: actions.SET_CURRENT_FORM_REQUEST,
    current,
  }),
  fetchSurveyVersionsRequest: (page) => ({
    type: actions.FETCH_SURVEY_VERSIONS_REQUEST,
    page,
  }),
  buildToast: (message, typeToast) => ({
    type: actions.BUILD_TOAST,
    message,
    typeToast,
  }),
};

export default actions;
