import Button from 'components/Button';
import styled, { css } from 'styled-components';

export type OptionCardProps = {
  isActive: boolean;
};

export const RadioButtonWithIconContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const OptionCard = styled(Button)<OptionCardProps>`
  ${({ theme, isActive }) => css`
    display: flex;
    border: 1px solid ${theme.gray12};
    border-radius: 8px;
    margin-bottom: 16px;
    justify-content: space-between;
    align-items: center;

    :hover {
      background-color: ${theme.primaryColor}25;
      border-color: ${theme.primaryColor}30;
    }
    ${isActive
      ? css`
          background-color: ${theme.primaryColor}10;
          border-color: transparent;
        `
      : css`
          background-color: ${theme.white};
        `}
  `}
`;

export const OptionCardDetails = styled.div<OptionCardProps>`
  ${({ theme, isActive }) => css`
    flex: 1;
    padding: 6px 12px 6px;

    .Label {
      width: 100%;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 1.5;
      white-space: normal;
      text-align: left;
      color: ${isActive ? theme.primaryColor : theme.gray2};
      margin-bottom: 0;
      cursor: pointer;
    }
  `}
`;

export const Icon = styled.img`
  width: 32px;
`;

export const SelectButton = styled.div`
  ${({ theme }) => css`
    display: flex;
    width: 16px;
    height: 16px;
    border-radius: 24px;
    background-color: ${theme.white};
    border: solid 1px ${theme.primaryColor};
    justify-content: center;
    align-items: center;
  `}
`;

export const IconButton = styled.div`
  ${({ theme }) => css`
    display: flex;
    height: 10px;
    width: 10px;
    border-radius: 12px;
    background-color: ${theme.primaryColor};
  `}
`;
