import React, { useEffect, useState } from 'react';
import { ManualDealModalProps } from './types';
import { RootStateOrAny, useSelector } from 'react-redux';
import { flags } from 'core/constants/flags';

import Field from 'components/Form/Field';
import FilterContainer from 'components/FilterContainer';
import FormContainer from 'components/Form/FormContainer';
import Loader from 'components/Loader';
import ModalTitle from 'components/Modal/ModalTitle';

import Buttons from './Buttons';
import CancelAndCreatePayment from './CancelAndCreatePayment';
import CancelPayment from './CancelPayment';
import NegotiationPayment from './NegotiationPayment';
import PaidAtSchoolModal from './PaidAtSchoolModal';

import * as S from './style';
import { useTranslation } from 'react-i18next';

const ManualDealModal = ({
  isOpen,
  toggleModal,
  bill,
  kind = 'recurrent_bill',
  isRecurrent = true,
  onSubmit,
  title,
  modalType = 'cancel',
  order,
}: ManualDealModalProps) => {
  const { t } = useTranslation(['manual_deal_modal']);
  const initialForm = {
    order,
    bill,
    kind,
    toggleDivergentValue: false,
  };

  const [negotiationType, setNegotiationType] = useState(null);

  const {
    policies: { edupay_active_flags, can_create_school_product },
  } = useSelector((state: RootStateOrAny) => state.root);

  const cancelAndCreatePermission =
    isRecurrent &&
    can_create_school_product &&
    edupay_active_flags.includes(flags.CANCEL_WITH_BOND);

  const options =
    bill?.attributes.kind === 'regular'
      ? [
          { label: t('label.select_type_of'), value: null },
          { label: t('label.paid_at_school'), value: 'paidAtSchool' },
          { label: t('label.cancel'), value: 'cancel' },
          { label: t('label.cancel_and_create'), value: 'cancelAndCreate' },
          { label: t('label.negotiation'), value: 'negotiation' },
        ]
      : [
          { label: t('label.select_type_of'), value: null },
          { label: t('label.paid_at_school'), value: 'paidAtSchool' },
          { label: t('label.cancel'), value: 'cancel' },
        ];

  useEffect(() => {
    setNegotiationType(null);
  }, [isOpen]);

  const currentNegotiationModal = (negotationTypeModal: string) => {
    const types = {
      negotiation: <NegotiationPayment toggleModal={toggleModal} />,
      cancel: (
        <CancelPayment
          toggleModal={toggleModal}
          onSubmit={onSubmit}
          isRecurrent={isRecurrent}
        />
      ),
      cancelAndCreate: <CancelAndCreatePayment toggleModal={toggleModal} />,
      paidAtSchool: (
        <PaidAtSchoolModal
          toggleModal={toggleModal}
          onSubmit={onSubmit}
          isRecurrent={isRecurrent}
        />
      ),
    };

    return types[negotationTypeModal];
  };

  const handleSubmitFilter = (type: string) => {
    setNegotiationType(type);
  };

  return (
    <S.ModalWrapper
      isOpen={isOpen}
      toggleModal={toggleModal}
      maxWidth="490px"
      minHeight="410px"
      testID="modal-container"
    >
      {!bill ? (
        <Loader />
      ) : (
        <>
          <S.HeaderModal isRecurrent={isRecurrent}>
            <ModalTitle>{title || 'Baixa Manual'}</ModalTitle>
          </S.HeaderModal>
          {isRecurrent && (
            <S.NegotiationSelectContainer>
              <FilterContainer>
                <Field
                  testID="selectOptions"
                  label={t('label.select_option')}
                  attributeName="negotiationType"
                  type="selectWithIcon"
                  value={negotiationType}
                  options={
                    cancelAndCreatePermission
                      ? options
                      : options.filter(
                          (item) => item.value !== 'cancelAndCreate'
                        )
                  }
                  onChange={(e) => handleSubmitFilter(e.value)}
                  fullWidth
                  withArrowSelect
                />
              </FilterContainer>
            </S.NegotiationSelectContainer>
          )}
          <FormContainer initialAttributes={initialForm}>
            <>
              {currentNegotiationModal(
                isRecurrent ? negotiationType : modalType
              )}
            </>
          </FormContainer>
        </>
      )}
      {negotiationType === null && bill && isRecurrent && (
        <S.ButtonsContainer>
          <Buttons
            title={t('button.conclude_deal')}
            isDisable={true}
            toggleModal={toggleModal}
            variation={'primary'}
          />
        </S.ButtonsContainer>
      )}
    </S.ModalWrapper>
  );
};

export default ManualDealModal;
