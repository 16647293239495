import createModuleReducer from 'store/storeConfig/createModuleReducer';
import actions from './actions';

export const INITIAL_STATE = {
  error: null,
  isLoading: false,
  isLoadingEdit: false,
  isSending: false,
  quickAccess: null,
  currentStudent: [],
  currentSchool: [],
  currentForm: {
    name: '',
    link: '',
    created_for: 'schools',
    classroom_ids: [],
    classroom_with_student_profile_ids: {},
  },
  formMeta: {
    canEditClassrooms: true,
    canEditKind: true,
    canEditConference: true,
    notificateWhenEditing: false,
    modalAnswered: false,
    openModal: false,
    classrooms: [],
    select_options: {},
  },
};

export const handlerError = (state, action) => ({
  ...state,
  error: action.error,
  isLoading: false,
  isSending: false,
});

export const submitNewFormSuccess = (state, action) => {
  return {
    ...state,
    quickAccess: action.quickAccess,
    isLoading: false,
    isSending: false,
  };
};

export const submitEditFormSuccess = (state) => ({
  ...state,
  isLoading: false,
  isSending: false,
});

export const prepareGetClassrooms = (state) => ({
  ...state,
  isLoading: true,
  formMeta: {
    ...state.formMeta,
    classrooms: [],
  },
});

export const getClassroomsSuccess = (state, action) => {
  const { data } = action;

  data.forEach((headquarter) =>
    headquarter.options.forEach((classroom) => {
      classroom.value = classroom.id;
      classroom.label = classroom.attributes.name;
    })
  );

  return {
    ...state,
    isLoading: false,
    formMeta: {
      ...state.formMeta,
      classrooms: data,
      select_options: {
        classroom_id: data,
      },
      // student_profiles: attributes.student_profiles.data,
    },
  };
};

export const getQuickaccessSuccess = (state, action) => {
  const { attributes } = action.data;

  return {
    ...state,
    isLoadingEdit: false,
    currentForm: {
      ...state.currentForm,
      name: attributes.name,
      link: attributes.link,
      image: { url: attributes.logo, uploaded: true },
      created_for: attributes.created_for,
      classroom_ids: attributes?.classroom_ids
        ? attributes.classroom_ids.map(String)
        : [],
      classroom_with_student_profile_ids:
        attributes?.classroom_with_student_profile_ids
          ? attributes.classroom_with_student_profile_ids
          : {},
    },
    formMeta: {
      ...state.formMeta,
      student_profiles: action.included,
    },
  };
};

export const updateSending = (state) => ({
  ...state,
  isSending: true,
});

export const updateEditLoading = (state) => ({
  ...state,
  isLoadingEdit: true,
});

const HANDLERS = {
  [actions.SUBMIT_NEW_FORM_REQUEST]: updateSending,
  [actions.SUBMIT_EDIT_FORM_REQUEST]: updateSending,
  [actions.GET_QUICKACCESS_REQUEST]: updateEditLoading,

  [actions.ERROR]: handlerError,
  [actions.SUBMIT_NEW_FORM_SUCCESS]: submitNewFormSuccess,
  [actions.SUBMIT_EDIT_FORM_SUCCESS]: submitEditFormSuccess,
  [actions.GET_CLASSROOMS_REQUEST]: prepareGetClassrooms,
  [actions.GET_CLASSROOMS_SUCCESS]: getClassroomsSuccess,
  [actions.GET_QUICKACCESS_SUCCESS]: getQuickaccessSuccess,
};

const quickAccess = createModuleReducer(INITIAL_STATE, HANDLERS);

export default quickAccess;
