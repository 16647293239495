import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import {
  Avatar,
  Box,
  Grid,
  Icon,
  Tag,
  Text,
  theme,
} from '@agendaedu/ae-web-components';

import { handoutsEngagementGrid } from 'core/constants/handouts';

import { RecipientCardProps } from './types';
import * as S from './styles';

const RecipientCard = ({ recipient: { attributes } }: RecipientCardProps) => {
  const { t } = useTranslation(['handouts']);

  const { ClassroomsStyles: C } = S;

  const {
    colors: { context },
  } = theme;

  const ICONS_MAPPING = {
    mobile: 'mobile',
    platform: 'desktop',
    email: 'letter',
  };

  const renderIcon = attributes.seenBy ? (
    <Icon
      name={ICONS_MAPPING[attributes.seenBy]}
      size="md"
      color={context.info.default}
    />
  ) : (
    '-'
  );

  const getAvatarUrl = attributes.shouldUseNameInitialsAvatar
    ? ''
    : attributes.photoUrl?.small;

  const viewInfo = moment(attributes.seenAt);

  const getView = {
    date: viewInfo.format(`DD [de] MMMM [de] YYYY`),
    time: viewInfo.format(`[às] HH:mm`),
  };

  const renderClassroomsTag = attributes.classroom.name ? (
    <C.HandoutTooltip>
      <C.StyledTag>
        <C.TagName>{attributes.classroom.name}</C.TagName>
      </C.StyledTag>

      <C.TooltipClassroomWrapper>
        <C.TooltipContent>{attributes.classroom.name}</C.TooltipContent>
      </C.TooltipClassroomWrapper>
    </C.HandoutTooltip>
  ) : (
    <S.EngagementTag
      name={t('handout_details.tabs.engagement.no_classroom')}
      variant="neutral"
    />
  );

  const renderViewInfo = attributes.seenAt ? (
    <S.SeenAtTooltip>
      <Text as="h4" variant="subtitle-medium-12" mb="sm">
        {t('handout_details.tabs.engagement.viewed')}
      </Text>
      {renderIcon}
      <C.TooltipClassroomWrapper>
        <S.SeenAtContent>
          <span>{t('handout_details.tabs.engagement.viewed_at')}</span>
          <span>{getView.date}</span>
          <span>{getView.time}</span>
        </S.SeenAtContent>
      </C.TooltipClassroomWrapper>
    </S.SeenAtTooltip>
  ) : (
    <>
      <Text as="h4" variant="subtitle-medium-12" mb="sm">
        {t('handout_details.tabs.engagement.viewed')}
      </Text>
      {renderIcon}
    </>
  );

  const renderStatusTag = attributes.confirm ? (
    <Tag
      name={t('handout_details.tabs.engagement.confirmed')}
      variant="positive"
    />
  ) : (
    <Tag name={t('tags.pending')} variant="warning" />
  );

  const renderColumnsRecipentType = {
    responsibles: (
      <>
        <S.ResponsibleContainer
          item
          {...handoutsEngagementGrid.cardGridColumns[attributes.recipientType]
            .responsible}
        >
          <Avatar
            size="sm"
            avatarColor={attributes.avatarColor}
            avatarUrl={getAvatarUrl}
            nameInitials={attributes.nameInitials}
          />
          <S.InfoContainer>
            <Text as="h4" variant="subtitle-medium-12">
              {t('handout_details.tabs.engagement.responsible')}
            </Text>
            <Text as="h3" variant="subtitle-medium-16">
              {attributes.name}
            </Text>
          </S.InfoContainer>
        </S.ResponsibleContainer>
        <Grid
          item
          {...handoutsEngagementGrid.cardGridColumns[attributes.recipientType]
            .student}
        >
          <S.InfoContainer>
            <Text as="h4" variant="subtitle-medium-12">
              {t('handout_details.tabs.engagement.student')}
            </Text>
            <Text as="h3" variant="subtitle-medium-16">
              {attributes?.studentName}
            </Text>
          </S.InfoContainer>
        </Grid>
      </>
    ),

    students: (
      <>
        <S.ResponsibleContainer
          item
          {...handoutsEngagementGrid.cardGridColumns[attributes.recipientType]
            .student}
        >
          <Avatar
            size="sm"
            avatarColor={attributes.avatarColor}
            avatarUrl={getAvatarUrl}
            nameInitials={attributes.nameInitials}
          />

          <S.InfoContainer>
            <Text as="h4" variant="subtitle-medium-12">
              {t('handout_details.tabs.engagement.student')}
            </Text>
            <Text as="h3" variant="subtitle-medium-16">
              {attributes.name}
            </Text>
          </S.InfoContainer>
        </S.ResponsibleContainer>
      </>
    ),
  };

  return (
    <S.CardWrapper>
      <S.CardContainer
        gutter={handoutsEngagementGrid.cardGutter}
        {...handoutsEngagementGrid.gridTemplate}
      >
        {renderColumnsRecipentType[attributes.recipientType]}
        <S.ClassroomsContainer
          item
          {...handoutsEngagementGrid.cardGridColumns[attributes.recipientType]
            .classrooms}
        >
          <Box height="100%" display="flex" alignItems="center">
            {renderClassroomsTag}
          </Box>
        </S.ClassroomsContainer>
        <Grid
          item
          {...handoutsEngagementGrid.cardGridColumns[attributes.recipientType]
            .viewed}
        >
          <S.InfoContainer>{renderViewInfo}</S.InfoContainer>
        </Grid>

        <Grid
          item
          {...handoutsEngagementGrid.cardGridColumns[attributes.recipientType]
            .status}
        >
          {renderStatusTag}
        </Grid>
      </S.CardContainer>
    </S.CardWrapper>
  );
};

export default RecipientCard;
