import { useState, useCallback } from 'react';
import { useGoogleLogin } from 'react-google-login';

const useGoogleDrive = (clientId, scope) => {
  const [googleOAuthSuccess, setGoogleOAuthSuccess] = useState(false);
  const [token, setToken] = useState(null);

  const onGoogleLoginSuccess = useCallback(
    (response) => {
      const { accessToken } = response;

      if (accessToken) {
        setGoogleOAuthSuccess(true);
        setToken(accessToken);
      } else {
        setGoogleOAuthSuccess(false);
      }
    },
    [setGoogleOAuthSuccess]
  );

  const onGoogleLoginFailure = useCallback(() => {
    setGoogleOAuthSuccess(false);
  }, [setGoogleOAuthSuccess]);

  const { signIn } = useGoogleLogin({
    clientId: clientId,
    onSuccess: onGoogleLoginSuccess,
    onFailure: onGoogleLoginFailure,
    cookiePolicy: 'single_host_origin',
    scope: scope,
  });

  return {
    googleOAuthSuccess,
    token,
    signIn,
    setGoogleOAuthSuccess,
  };
};

export default useGoogleDrive;
