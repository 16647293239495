import React from 'react';
import ContentLoader from 'react-content-loader';

import * as S from './styles';

const SectionsSkeleton = () => {
  return (
    <S.MainContainer>
      <ContentLoader
        width="100%"
        height="100%"
        speed={2}
        viewBox="0 0 400 600"
        ariaLabel="Carregando..."
      >
        <rect x="0" y="0" rx="6" ry="6" width="400" height="100" />
        <rect x="0" y="110" rx="6" ry="6" width="400" height="100" />
        <rect x="0" y="220" rx="6" ry="6" width="400" height="100" />
      </ContentLoader>
    </S.MainContainer>
  );
};

export default SectionsSkeleton;
