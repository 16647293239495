import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import FormFullScreen from 'components/FormFullScreen';
import HeadquarterInformationsTab from 'components/Headquarters/Form/HeadquarterInformationsTab';
import HeadquarterContext from 'core/contexts/Headquarter';
import HeadquartersActions from 'store/headquarters/actions';
import SchoolServices from 'core/services/School';
import Toast from 'components/Toast';
import Loader from 'components/Loader';

const contentModalText = `
  A edição da unidade será cancelada e as
  informações preenchidas serão descartadas.
  Tem certeza que deseja descartar?
`;

const HeadquartersEdit = ({ match, history }) => {
  const {
    isLoading,
    form: current,
    formMeta,
    isSubmitting,
  } = useSelector((state) => state.headquarters);
  const { id } = match.params;
  const dispatch = useDispatch();
  const { setHeadquarter, editHeadquarter } = HeadquartersActions;
  const dataArea = useSelector((state) => state.root.dataArea);
  const schoolServices = new SchoolServices(dataArea);

  const validateLegacyId = async (formContext) => {
    const { form, removeErrorOnAttribute, addErrorOnAttribute } = formContext;
    try {
      await schoolServices.validateLegacyId(form.legacy_id, 'headquarters');
      removeErrorOnAttribute('legacy_id');
    } catch {
      addErrorOnAttribute(
        'legacy_id',
        'Identificador de registro já está em uso'
      );
    }
  };

  useEffect(() => {
    dispatch(setHeadquarter(id));
  }, [id]);

  const onSubmit = async (formContext) => {
    const { form, hasErrorOnAttribute } = formContext;

    if (form.legacy_id && form.legacy_id !== current.legacy_id)
      await validateLegacyId(formContext);

    const hasErrorOnLegacyId = hasErrorOnAttribute('legacy_id');
    const isFormValid = !hasErrorOnLegacyId;
    isFormValid && dispatch(editHeadquarter(id, form, history));
  };

  return (
    <Loader isLoading={isLoading}>
      {current.id && (
        <HeadquarterContext.Provider>
          <FormFullScreen
            action="edit"
            titlePage="Editar unidade"
            titleModal="Descartar preenchimento"
            contentModal={contentModalText}
            toggleModalWithFormUpdates
            form={current}
            formMeta={formMeta}
            isSubmitting={isSubmitting}
            steps={[HeadquarterInformationsTab]}
            backTo={`/${dataArea}/headquarters`}
            onSubmit={onSubmit}
          />
        </HeadquarterContext.Provider>
      )}
      <Toast />
    </Loader>
  );
};

HeadquartersEdit.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object.isRequired,
};

export default HeadquartersEdit;
