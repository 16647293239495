import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Button from 'components/Button';

import './style.scss';

class ButtonRow extends Component {
  getVariation(variation) {
    return typeof variation === 'function' ? variation() : variation;
  }

  getButtonStatus(status) {
    return typeof status === 'function' ? status() : status;
  }

  render() {
    const { buttons, children } = this.props;

    if (children) {
      return <div className="ButtonRow">{children}</div>;
    }

    return (
      <div className="ButtonRow">
        {buttons.map((button, index) =>
          button.path ? (
            <Link
              data-testid={button.testId}
              to={button.path}
              key={`button_${index}`}
            >
              <Button variation={this.getVariation(button.variation)}>
                {button.text}
              </Button>
            </Link>
          ) : (
            <Button
              testId={button.testId}
              variation={this.getVariation(button.variation)}
              key={`button_${index}`}
              type={button.type}
              onClick={button.onClick}
              disabled={this.getButtonStatus(button.disabled)}
              className={button.variation === 'danger' ? button.className : ''}
            >
              {button.text}
            </Button>
          )
        )}
      </div>
    );
  }
}

ButtonRow.propTypes = {
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      testId: PropTypes.string,
      path: PropTypes.string,
      onClick: PropTypes.func,
      disabled: PropTypes.bool,
      text: PropTypes.node.isRequired,
      type: PropTypes.string,
      className: PropTypes.string,
    })
  ),
  children: PropTypes.node,
};

export default ButtonRow;
