import { ThemeProps } from 'config/themes/types';
import styled, { css } from 'styled-components';

export const Wrapper = styled.button`
  ${({ theme: { space, border, colors } }: ThemeProps) => css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #f6f6f6;
    border: ${border.width.sm} ${border.style.solid} ${colors.neutral.gray4};
    border-radius: 6px;
    width: 100%;
    padding: ${space.xs3} ${space.xs2};
    transition: color 0.3s ease, background-color 0.3s ease;
    color: ${colors.neutral.gray1};

    svg {
      margin-top: 4px;
      path {
        fill: ${colors.neutral.gray1};
        transition: fill 0.3s ease;
      }
    }

    :hover {
      color: ${colors.neutral.gray2};

      svg {
        path {
          fill: ${colors.neutral.gray2};
        }
      }
    }
  `}
`;
