import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import FormContainer from 'components/Form/FormContainer';
import { IMAGES_INVITE_URL } from 'core/constants';
import { InviteBoxProps, RootState } from './types';
import withFormContext from 'core/hoc/withFormContext';
import inviteEmptyStateActions from 'store/invite/emptyState/actions';
import InviteField from '../InviteField';
import { trackEvent } from '../../../config/amplitude';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import * as S from './styles';

const InviteBox = ({ resource }: InviteBoxProps): JSX.Element => {
  const { form, isLoading, error } = useSelector(
    (state: RootState) => state.inviteEmptyState
  );
  const { generateInviteUrl } = inviteEmptyStateActions;
  const dispatch = useDispatch();

  const handleGenerateInviteUrl = useCallback(() => {
    dispatch(generateInviteUrl(resource));
  }, [dispatch, generateInviteUrl, resource]);

  useEffect(() => {
    handleGenerateInviteUrl();
  }, [handleGenerateInviteUrl]);

  const { t } = useTranslation(['invite_box']);

  return (
    <S.Container className="InviteBox">
      <S.InfoBox>
        <S.InfoTitle>{t('box_title')}</S.InfoTitle>
        <S.InfoText>{parse(t('box_text'))}</S.InfoText>

        <FormContainer initialAttributes={form.invite_link ? form : {}}>
          <InviteField
            attributeName="invite_link"
            disabled={isLoading || error}
            error={error}
            beforeCopy={() =>
              trackEvent('plg-team-invite-link-copied', {
                actionTarget: 'copy',
              })
            }
          />
        </FormContainer>
      </S.InfoBox>

      <S.ImageBox>
        <img src={IMAGES_INVITE_URL.inviteBoxThumbnail} alt="thumbnail" />
      </S.ImageBox>
    </S.Container>
  );
};

export default withFormContext(InviteBox);
