import { Text } from '@agendaedu/ae-web-components';
import { ThemeProps } from 'config/themes/types';
import styled, { css } from 'styled-components';
import { CardLabelStyleProps, ChannelCardWrapperStyleProps } from './types';
import colorWithAlpha from 'core/utils/colorWithAlpha';

export const ChannelCardWrapper = styled.div<ChannelCardWrapperStyleProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  user-select: none;

  ${({
    draggingCard,
    isSelected,
    isDragging,
    canUpdateChannel,
    theme: { space, border, colors, breakpoints },
  }) => css`
    padding: ${space.xs} ${space.lg};
    gap: ${space.xs};

    &:not(:first-child) {
      border-top: ${!draggingCard &&
      `${border.width.sm} ${border.style.solid}
      ${colors.neutral.gray4}`};
    }

    &:last-child {
      border-bottom: ${!draggingCard &&
      `${border.width.sm} ${border.style.solid}
    ${colors.neutral.gray4};`};
    }

    &:hover {
      background-color: ${!isSelected &&
      !isDragging &&
      colorWithAlpha(colors.brand.primary.default, 0.05)};

      & > #actions-wrapper {
        @media (min-width: ${breakpoints.desktopLG}) {
          display: ${canUpdateChannel && 'flex'};
        }
      }

      & > main {
        display: ${!isDragging ? 'none' : 'flex'};
      }
    }

    background-color: ${!isDragging
      ? isSelected
        ? colors.brand.primary.op10
        : colors.neutral.white
      : draggingCard
      ? colors.neutral.white
      : colors.neutral.gray6};

    border: ${isDragging &&
    draggingCard &&
    `${border.width.sm} ${border.style.solid} ${colors.brand.primary.default}`};
  `}
`;

export const AvatarWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const CardLabelWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const CardLabel = styled(Text)<CardLabelStyleProps>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;

  ${({ isSelected, theme: { colors, breakpoints, font } }) => css`
    color: ${isSelected ? colors.brand.primary.default : colors.neutral.black};
    line-height: ${font.height.lg} !important;

    @media (max-width: ${breakpoints.desktopLG}) {
      max-width: 150px;
    }
  `}
`;

export const CardActionsWrapper = styled.div`
  display: none;
  flex-direction: row;

  ${({ theme: { space } }: ThemeProps) => css`
    gap: ${space.xs};
  `}
`;

export const SkeletonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const SkeletonCard = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    flex-direction: row;
    height: 56px;
    justify-content: space-between;
    align-items: center;
    padding: ${space.md};
    padding-top: ${space.lg};
  `}
`;
