/* eslint-disable react/jsx-key */
import React from 'react';
import {
  Grid,
  DefaultThemeProps,
  theme,
  Box,
} from '@agendaedu/ae-web-components';
import { useTranslation } from 'react-i18next';

import * as S from './styles';

import FormFullScreen from 'components/FormFullScreen';
import Skeleton from 'components/Skeleton';

export const FormSkeleton = ({
  dataArea,
  title,
}: {
  dataArea: string;
  title: string;
}) => {
  const defaultTheme: DefaultThemeProps = theme;

  const { t } = useTranslation(['handouts']);

  const {
    colors: { neutral },
    border: { radius },
  } = defaultTheme;

  return (
    <S.HandoutsFormContainer data-testId="form-skeleton">
      <FormFullScreen
        backTo={`/${dataArea}/handouts`}
        backToExternal
        steps={[
          () => (
            <S.HandoutInformationStepWrapper>
              <S.FormSkeletonWrapper>
                <Grid
                  gridTemplateColumns="1fr"
                  gutter={{
                    _: 'lg',
                  }}
                >
                  <Box mb="md">
                    <Skeleton
                      width={224}
                      height={40}
                      primaryColor={neutral.gray6}
                      borderRadius={radius.md}
                    />
                  </Box>

                  <Grid item gutter="sm">
                    <Skeleton
                      width={128}
                      height={28}
                      primaryColor={neutral.gray6}
                      borderRadius={radius.md}
                    />

                    <Skeleton
                      width="100%"
                      height={192}
                      primaryColor={neutral.gray6}
                      borderRadius={radius.md}
                      isFullWidth
                    />
                  </Grid>

                  <Grid item gutter="sm">
                    <Skeleton
                      width={128}
                      height={28}
                      primaryColor={neutral.gray6}
                      borderRadius={radius.md}
                    />

                    <Skeleton
                      width="100%"
                      height={40}
                      primaryColor={neutral.gray6}
                      borderRadius={radius.md}
                      isFullWidth
                    />
                  </Grid>

                  <Grid item gutter="sm">
                    <Skeleton
                      width={128}
                      height={28}
                      primaryColor={neutral.gray6}
                      borderRadius={radius.md}
                    />

                    <Skeleton
                      width="100%"
                      height={40}
                      primaryColor={neutral.gray6}
                      borderRadius={radius.md}
                      isFullWidth
                    />
                  </Grid>

                  <Grid item gutter="sm">
                    <Skeleton
                      width={128}
                      height={28}
                      primaryColor={neutral.gray6}
                      borderRadius={radius.md}
                    />

                    <Skeleton
                      width="100%"
                      height={192}
                      primaryColor={neutral.gray6}
                      borderRadius={radius.md}
                      isFullWidth
                    />
                  </Grid>

                  <Grid item gutter="sm">
                    <Skeleton
                      width={128}
                      height={28}
                      primaryColor={neutral.gray6}
                      borderRadius={radius.md}
                    />

                    <Skeleton
                      width={192}
                      height={40}
                      primaryColor={neutral.gray6}
                      borderRadius={radius.md}
                    />
                  </Grid>

                  <Skeleton
                    width={160}
                    height={28}
                    primaryColor={neutral.gray6}
                    borderRadius={radius.md}
                  />
                </Grid>
              </S.FormSkeletonWrapper>
            </S.HandoutInformationStepWrapper>
          ),
        ]}
        titlePage={title}
        titleModal={t('modals.handout_form_discard.title')}
        contentModal={t('modals.handout_form_discard.description')}
      />
    </S.HandoutsFormContainer>
  );
};
