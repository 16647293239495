import { ThemeProps } from 'config/themes/types';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme: { breakpoints, space } }: ThemeProps) => css`
    .Accordion {
      display: flex;
      width: 100%;

      flex-direction: column;

      .accordion__button {
        padding-left: 34px;
        border-radius: ${space.xs2} ${space.xs2} 0px 0px !important;
        display: flex;
        width: 100%;
        background-color: white;
        height: 72px;
        @media screen and (max-width: ${breakpoints.mobileSM}) {
          height: 100px;
        }
      }

      .accordion__button:before {
        height: 10px;
        width: 10px;
      }

      .accordion__panel {
        border-radius: 0px 0px ${space.xs2} ${space.xs2} !important;
      }
    }
  `}
`;

export const AccordionHeaderWrapper = styled.div`
  ${({ theme: { breakpoints, space } }: ThemeProps) => css`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding-right: ${space.xs};

    @media screen and (max-width: ${breakpoints.mobileSM}) {
      flex-direction: column;
      justify-content: flex-start;
      gap: ${space.md};
      align-items: flex-start;
      margin-left: ${space.xs};
    }
  `}
`;

export const AccordionBodyWrapper = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: ${space.xl};
    padding: ${space.md} ${space.sm} ${space.md} ${space.sm};
  `}
`;

export const AttendingDayWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const DayWeekWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const ToggleWrapper = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${space.lg};
  `}
`;

export const InputTimeWrapper = styled.div`
  ${({ theme: { space, breakpoints } }: ThemeProps) => css`
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: ${space.lg};

    p {
      margin-bottom: 0;
    }

    @media screen and (max-width: ${breakpoints.mobileSM}) {
      flex-direction: column;
    }
  `}
`;
