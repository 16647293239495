import { ThemeProps } from 'config/themes/types';
import styled, { css } from 'styled-components';

export const Breadcrumb = styled.div`
  ${({ theme: { colors, font, space } }: ThemeProps) => css`
    font-size: ${font.size.sm};
    color: ${colors.brand.primary};

    a {
      color: ${colors.brand.primary};
    }

    i:first-child {
      margin-right: ${space.xs2};
    }

    .subTitle {
      color: ${colors.neutral.gray1};
    }
  `}
`;
