import React from 'react';
import PropTypes from 'prop-types';
import ModalContainer from 'components/Modal/ModalContainer';
import ModalTitle from 'components/Modal/ModalTitle';
import Button from 'components/Button';
import MessagesAttachment from 'components/Messages/Attachment';
import Field from 'components/Form/Field';
import AttachmentEntity from 'core/entities/Attachment';

import './style.scss';

class MessagesUploadModal extends React.Component {
  render() {
    const { isOpen, toggleModal, attachment, sendFile, onChange } = this.props;

    return (
      <div className="MessagesUploadModal">
        <ModalContainer
          isOpen={isOpen}
          toggleModal={toggleModal}
          className="MessagesUploadModal"
        >
          <ModalTitle>Anexar arquivo</ModalTitle>
          <MessagesAttachment variation="sent" attachment={attachment} />
          <div className="comment-box">
            <Field
              label="Comentário"
              type="simpleTextArea"
              attributeName="message"
              onChange={onChange}
            />
          </div>
          <div className="action-buttons">
            <Button onClick={toggleModal} variation="secondary">
              Cancelar
            </Button>
            <Button onClick={sendFile}>Enviar</Button>
          </div>
        </ModalContainer>
      </div>
    );
  }
}

MessagesUploadModal.propTypes = {
  attachment: PropTypes.instanceOf(AttachmentEntity),
  isOpen: PropTypes.bool,
  toggleModal: PropTypes.func,
  sendFile: PropTypes.func,
  onUploadCancel: PropTypes.func,
  onChange: PropTypes.func,
};

export default MessagesUploadModal;
