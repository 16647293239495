import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import FormFullScreen from 'components/FormFullScreen';
import Loader from 'components/Loader';
import Toast from 'components/Toast';
import ResponsibleProfilesActions from 'store/responsibleProfiles/actions';
import ResponsibleProfileInformationsTab from 'pages/ResponsibleProfiles/FormTabs/InformationsTab';
import SchoolServices from 'core/services/School';
import withAppContext, { appPropTypes } from 'core/hoc/withAppContext';
import * as S from '../styles';

const contentModalText = `
  O cadastro do responsável será cancelado e as
  informações preenchidas serão descartadas.
  Tem certeza que deseja descartar?
`;

const ResponsibleProfilesNew = ({ appContext: { policies } }) => {
  const dispatch = useDispatch();
  const { fetchFormMeta, fetchStudentProfiles, createResponsibleProfile } =
    ResponsibleProfilesActions;
  const dataArea = useSelector((state) => state.root.dataArea);
  const { isLoading, isSubmitting, form, formMeta } = useSelector(
    (state) => state.responsibleProfiles
  );
  const schoolServices = new SchoolServices(dataArea);
  const {
    can_use_legacy_id: canUseLegacyId,
    has_guaranteed_monthly_fee: hasGuaranteedMonthlyFee,
  } = policies;

  const validateLegacyId = async (formContext) => {
    const { form, removeErrorOnAttribute, addErrorOnAttribute } = formContext;
    try {
      await schoolServices.validateLegacyId(
        form.legacy_id,
        'responsible_profiles'
      );
      removeErrorOnAttribute('legacy_id');
    } catch {
      addErrorOnAttribute(
        'legacy_id',
        'Identificador de registro já está em uso'
      );
    }
  };

  const validateCpf = (formContext) => {
    const { form, removeErrorOnAttribute, addErrorOnAttribute } = formContext;

    if (hasGuaranteedMonthlyFee && form.financial && !form.document_number) {
      addErrorOnAttribute(
        'document_number',
        'CPF é obrigatório, pois a escola possui mensalidade garantida.'
      );
    } else {
      removeErrorOnAttribute('document_number');
    }
  };

  const handleFetchFormMeta = useCallback(() => {
    dispatch(fetchFormMeta());
  }, [dispatch, fetchFormMeta]);

  const handleFetchStudentProfiles = useCallback(() => {
    dispatch(fetchStudentProfiles());
  }, [dispatch, fetchStudentProfiles]);

  const onSubmit = async (
    formContext,
    _,
    redirect = `/${dataArea}/responsible_profiles`
  ) => {
    const { form, hasErrorOnAttribute } = formContext;

    if (form.legacy_id) await validateLegacyId(formContext);

    if (!canUseLegacyId) delete form.legacy_id;

    validateCpf(formContext);

    const hasErrorOnLegacyId = hasErrorOnAttribute('legacy_id');
    const hasErrorOnCpf = hasErrorOnAttribute('document_number');

    const isFormValid = !hasErrorOnLegacyId && !hasErrorOnCpf;

    isFormValid &&
      dispatch(
        createResponsibleProfile({
          form,
          redirect,
        })
      );

    return isFormValid;
  };

  const onSubmitAndNewRegister = async (formContext) => {
    const isFormSubmitted = await onSubmit(formContext, null, null);

    if (isFormSubmitted) {
      formContext.resetForm();
      document.getElementsByClassName('form-control')[0].focus();
    }
  };

  useEffect(() => {
    handleFetchFormMeta();
    handleFetchStudentProfiles();
  }, [handleFetchFormMeta, handleFetchStudentProfiles]);

  return (
    <S.PageContainer
      id="ResponsibleProfilesNew"
      className="ResponsibleProfilesNew"
    >
      <Loader isLoading={isLoading}>
        <FormFullScreen
          action="new"
          titlePage="Novo responsável"
          titleModal="Descartar preenchimento"
          contentModal={contentModalText}
          toggleModalWithFormUpdates
          onSubmit={onSubmit}
          onSubmitAndNewRegister={onSubmitAndNewRegister}
          form={form}
          formMeta={formMeta}
          isSubmitting={isSubmitting}
          steps={[ResponsibleProfileInformationsTab]}
          backTo={`/${dataArea}/responsible_profiles`}
          backToExternal
          finishText="Salvar e fechar"
        />
      </Loader>
      <Toast />
    </S.PageContainer>
  );
};

ResponsibleProfilesNew.propTypes = {
  ...appPropTypes,
};

export default withAppContext(ResponsibleProfilesNew);
