import styled, { css } from 'styled-components';
import { WrapperStyleProps } from './types';

export const Wrapper = styled.div<WrapperStyleProps>`
  ${({ height }) => css`
    .FormFullScreen {
      height: ${height}px;
    }
  `}
`;
