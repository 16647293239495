import React from 'react';
import ColoredText from 'components/ColoredText';
import AgendaIcon from 'components/AgendaIcon';
import * as S from './styles';

const PaymentMethodLabeled = ({ iconName, label }) => {
  return (
    <S.MethodWrapper>
      <span>
        <AgendaIcon name={iconName} />
      </span>
      <ColoredText variation="gray-2">{label}</ColoredText>
    </S.MethodWrapper>
  );
};

export default PaymentMethodLabeled;
