import styled from 'styled-components';

export const Info = styled.p`
  font-size: 12px;
  margin-top: 8px;
  margin-bottom: 28px;
`;

export const TooltipLabel = styled.div`
  line-height: 1;
  vertical-align: middle;
  display: flex;

  .Tooltip {
    margin-left: -4px;
  }
`;
