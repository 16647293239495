import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { titleMobile } from 'core/utils/changeText';
import composeFunctions from 'core/utils/composeFunctions';
import FormFieldset from 'components/Form/Fieldset';
import withFormContext, { formPropTypes } from 'core/hoc/withFormContext';
import withWindowPathname, {
  windowPropTypes,
} from 'core/hoc/withWindowPathname';
import Loader from 'components/Loader';
import tabifyForWizard from 'core/hoc/tabifyForWizard';
import importWebActions from 'store/importWeb/actions';
import { IMPORT_PROFILE_TYPES } from '../../constants';
import * as S from './styles';

const OverviewTab = ({ windowPathname }) => {
  const { validatedFile, uploadedFile, isLoading, fileSummary } = useSelector(
    (state) => state.importWeb
  );
  const { fetchFileSummary, setImportStep } = importWebActions;

  const importedInto = windowPathname.split('/')[3];

  const dispatch = useDispatch();

  const handleSetImportStep = useCallback(() => {
    dispatch(setImportStep(4));
  }, [dispatch, setImportStep]);

  const handleFetchFileSummary = useCallback(() => {
    dispatch(fetchFileSummary(importedInto, uploadedFile.signed_id));
  }, [dispatch, fetchFileSummary, uploadedFile, importedInto]);

  useEffect(() => {
    handleSetImportStep();
    if (importedInto && !fileSummary) handleFetchFileSummary();
  }, [handleFetchFileSummary, importedInto, fileSummary, handleSetImportStep]);

  return (
    <div className="ImportWebOverviewTab" data-testid="ImportWebOverviewTab">
      <FormFieldset>
        <Loader isLoading={isLoading}>
          <S.OverviewList>
            <li>
              Número de linhas do arquivo:{' '}
              <b>{fileSummary && fileSummary.total_lines}</b>
            </li>
            <li>
              Número de registros distintos:{' '}
              <b>{fileSummary && fileSummary.distinct_records}</b>
            </li>
            <li>
              Número de inconsistências encontradas:{' '}
              <b>{validatedFile.foundErrors}</b>
            </li>
            <li>
              Número de inconsistências corrigidas:{' '}
              <b>{validatedFile.correctedErrors}</b>
            </li>
          </S.OverviewList>

          <S.InfoCard>
            <span>
              <b>DICA:</b> Os dados já existentes dentro da plataforma Agenda
              Edu serão atualizados pelos dados do arquivo carregado com as
              inconsistências corrigidas (caso tenha as corrigido)
            </span>
          </S.InfoCard>

          <S.Title>Próximos passos</S.Title>

          <S.NextStepsList>
            <li>
              Ao clicar em <b>Iniciar importação</b>, esta janela será fechada e
              você será redirecionado para a{' '}
              <b>
                Central de importação de {IMPORT_PROFILE_TYPES[importedInto]}
              </b>
              , um processo automático será iniciado e ele passará por duas
              etapas, são eles:
            </li>

            <li>
              <b>Etapa 1 de 2 - Organizando dados</b> - Nesta etapa iremos
              processar os dados do arquivo carregado junto as correções das
              inconsistências (caso as tenha corrigido) e deixá-las organizadas
              para a próxima etapa
            </li>

            <li>
              <b>Etapa 2 de 2 - Importando dados</b> - Nesta etapa iremos
              importar todos os dados que foram organizados na etapa anterior e
              inserí-los ou atualizá-los dentro da plataforma Agenda Edu.
            </li>

            <li>
              Ao final da importação, seus dados estarão todos atualizados e
              dentro da plataforma para poderem ser utilizados.
            </li>
          </S.NextStepsList>
        </Loader>
      </FormFieldset>
    </div>
  );
};

OverviewTab.propTypes = {
  ...formPropTypes,
  ...windowPropTypes,
};

export default composeFunctions(
  withFormContext,
  withWindowPathname,
  tabifyForWizard({
    title: titleMobile('4 Resumo'),
    subTitle: 'Resumo dos dados a serem importados',
  })
)(OverviewTab);
