import React from 'react';
import PropTypes from 'prop-types';

import FormFullScreen from 'components/FormFullScreen';

import * as S from './styles';

const RecurrentPlanForm = ({
  action,
  onSubmit,
  steps,
  form,
  formMeta,
  isSubmitting,
  backTo,
  titlePage,
  titleModal,
  contentModal,
  children,
}) => {
  return (
    <S.NewRecurrentPlanForm>
      <FormFullScreen
        action={action}
        onSubmit={onSubmit}
        form={form}
        formMeta={formMeta}
        isSubmitting={isSubmitting}
        steps={steps}
        backTo={backTo}
        titlePage={titlePage}
        titleModal={titleModal}
        contentModal={contentModal}
      >
        {!!children && children}
      </FormFullScreen>
    </S.NewRecurrentPlanForm>
  );
};

RecurrentPlanForm.propTypes = {
  action: PropTypes.oneOf(['new', 'edit']).isRequired,
  onSubmit: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  formMeta: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool,
  steps: PropTypes.array.isRequired,
  backTo: PropTypes.string,
  titlePage: PropTypes.string,
  titleModal: PropTypes.string,
  contentModal: PropTypes.string,
  children: PropTypes.node,
};

export default RecurrentPlanForm;
