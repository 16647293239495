import {
  all,
  call,
  put,
  select,
  takeLatest,
  takeEvery,
} from 'redux-saga/effects';
import { reset } from 'redux-form';

import {
  buildToast,
  toastCss,
  ToastTypes,
} from 'store/middlewares/toast/actions';
import { fetchApi, postApi, putApi, patchApi } from 'core/utils/api';
import actions from './actions';

export function* fetchRecurrentProducts(action) {
  try {
    const dataArea = yield select((state) => state.root.dataArea);

    const { data, itemsCountPerPage, totalItemsCount } = yield call(
      fetchApi,
      `/${dataArea}/recurrent/recurrent_products.json`,
      { params: action.params }
    );

    yield put({
      type: actions.FETCH_SUCCESS,
      paginate: { ...action.params, itemsCountPerPage, totalItemsCount },
      data,
    });
  } catch (error) {
    yield put({ type: actions.ERROR, error });
  }
}

export function* addRecurrentProduct(action) {
  try {
    const dataArea = yield select((state) => state.root.dataArea);

    const { data } = yield call(
      postApi,
      `/${dataArea}/recurrent/recurrent_products`,
      { recurrent_product: action.params }
    );
    yield put({ type: actions.TOGGLE_MODAL });
    yield put({
      type: actions.ADD_RECURRENT_PRODUCT_SUCCESS,
      data,
      toast: buildToast(
        'Produto cadastrado com sucesso.',
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
    yield put(reset('RecurrentProductForm'));
  } catch (error) {
    yield put({ type: actions.ERROR, error });
  }
}

export function* editRecurrentProduct(action) {
  try {
    const dataArea = yield select((state) => state.root.dataArea);

    const { data } = yield call(
      putApi,
      `/${dataArea}/recurrent/recurrent_products/${action.params.id}`,
      { recurrent_product: action.params }
    );
    yield put({ type: actions.TOGGLE_MODAL });
    yield put({
      type: actions.EDIT_RECURRENT_PRODUCT_SUCCESS,
      data,
      toast: buildToast(
        'Produto Atualizado com sucesso.',
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({ type: actions.ERROR, error });
  }
}

export function* disableRecurrentProduct(action) {
  try {
    const dataArea = yield select((state) => state.root.dataArea);

    const { data } = yield call(
      patchApi,
      `/${dataArea}/recurrent/recurrent_products/${action.id}/disable`
    );
    yield put({
      type: actions.DISABLE_RECURRENT_PRODUCT_SUCCESS,
      data,
      toast: buildToast(
        'Produto desabilitado com sucesso.',
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({ type: actions.ERROR, error });
  }
}

function* recurrentProductSagas() {
  yield all([
    takeLatest(actions.FETCH_REQUEST, fetchRecurrentProducts),
    takeEvery(actions.ADD_RECURRENT_PRODUCT, addRecurrentProduct),
    takeEvery(actions.EDIT_RECURRENT_PRODUCT, editRecurrentProduct),
    takeEvery(actions.DISABLE_RECURRENT_PRODUCT, disableRecurrentProduct),
  ]);
}

export default recurrentProductSagas;
