import React from 'react';

export const STUDENT_PROFILE_MODEL_TABLE = [
  {
    field: 'Identificador Registro',
    required: 'Sim',
    description: (
      <>
        Descrição: Campo de uso único e individual utilizado para identificar o
        aluno cadastrado.
      </>
    ),
    rules: (
      <>
        Regras: Campo obrigatório que permite a inserção de caracteres
        alfanuméricos e especiais.
      </>
    ),
  },
  {
    field: 'Identificador Turma',
    required: 'Sim',
    description: (
      <>
        Descrição: Campo utilizado para preenchimento da turma que o aluno
        cadastrado irá estudar.
      </>
    ),
    rules: (
      <>
        Regras: Campo obrigatório que permite a inserção de caracteres
        alfanuméricos e especiais.
      </>
    ),
  },
  {
    field: 'Nome',
    required: 'Sim',
    description: (
      <>
        Descrição: Campo utilizado para preenchimento do nome do aluno a ser
        cadastrado.
      </>
    ),
    rules: (
      <>
        Regras: Campo obrigatório que permite a inserção de caracteres
        alfabéticos.
      </>
    ),
  },
  {
    field: 'E-mail',
    required: 'Não',
    description: (
      <>
        Descrição: Campo utilizado para preenchimento do e-mail do aluno
        cadastrado.
      </>
    ),
    rules: (
      <>
        Regras: Campo que deve conter um e-mail válido. Exemplo:{' '}
        <b>antonio@gmail.com</b>
      </>
    ),
  },
  {
    field: 'Data de nascimento',
    required: 'Não',
    description: (
      <>
        Descrição: Campo utilizado para preenchimento da data de nascimento do
        aluno cadastrado.
      </>
    ),
    rules: (
      <>
        Regras: Seguindo o formato (dia/mês/ano) e não podendo ser maior que o
        dia atual. Exemplo: <b>10/05/1982</b>
      </>
    ),
  },
  {
    field: 'Turno',
    required: 'Não',
    description: (
      <>
        Descrição: Campo utilizado para preenchimento do período que o aluno
        estuda.
      </>
    ),
    rules: (
      <>
        Regras: Seus valores devem ser um desses:{' '}
        <b>manhã, tarde, noite ou integral</b>.
      </>
    ),
  },
  {
    field: 'Login',
    required: 'Não',
    description: (
      <>Descrição: Campo utilizado para preenchimento do nome do usuário.</>
    ),
    rules: (
      <>
        Regras: Campo que permite a inserção de caracteres alfanuméricos e
        especiais.
      </>
    ),
  },
  {
    field: 'Registro Acadêmico',
    required: 'Não',
    description: (
      <>Descrição: Campo utilizado para preenchimento do registro acadêmico (RA)..</>
    ),
    rules: (
      <>
        Regras: Campo que permite a inserção de caracteres alfanuméricos.
      </>
    ),
  },
];
