import React, { useState } from 'react';
import {
  Box,
  FlexGrid,
  FlexGridItem,
  Text,
  Modal,
} from '@agendaedu/ae-web-components';
import { useTranslation } from 'react-i18next';
import TrainingCard from 'pages/home/Trainings/TrainingCard';
import { useSelector } from 'react-redux';
import { HomeSelectorStore, Training } from 'store/home/types';
import { trackEvent } from 'config/amplitude';

import TrainingModal from './TrainingModal';

const Trainings = (): JSX.Element => {
  const { t } = useTranslation('home');
  const { trainings } = useSelector((state: HomeSelectorStore) => state.home);
  const [modalTrainingContent, setModalTrainingContent] =
    useState<Training>(null);

  const closeModal = () => setModalTrainingContent(null);

  const eventClick = (training: Training) => {
    const removeEmojiRegex = /[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu;

    const trainingTitleEvent = training.attributes.title
      .replace(removeEmojiRegex, '')
      .trim()
      .replace(/[' ']/g, '-')
      .toLowerCase();

    const eventName = `training-${trainingTitleEvent}`;

    setModalTrainingContent(training);
    trackEvent(eventName);
  };

  const trainingModal = (
    <Modal
      onClose={closeModal}
      title={modalTrainingContent?.attributes.title}
      isOpen={!!modalTrainingContent}
      isClosed
    >
      {modalTrainingContent && (
        <TrainingModal training={modalTrainingContent} />
      )}
    </Modal>
  );

  if (!trainings.length) return <></>;
  return (
    <Box mt="xl3">
      <Text variant="headline-h2-bold-24" color="neutral.black">
        {t('trainings')}
      </Text>

      <FlexGrid mt="xl">
        {trainings.map((training) => (
          <FlexGridItem
            key={training.id}
            cols={{ mobileXS: 12, tabletMD: 6, desktopLG: 3 }}
          >
            <TrainingCard openModal={eventClick} training={training} />
          </FlexGridItem>
        ))}
      </FlexGrid>

      {trainingModal}
    </Box>
  );
};

export default Trainings;
