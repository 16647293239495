import React from 'react';
import { theme, DefaultThemeProps, Box } from '@agendaedu/ae-web-components';

import * as S from './styles';
import Skeleton from 'components/Skeleton';

export const GroupCardSkeleton = () => {
  const defaultTheme: DefaultThemeProps = theme;

  const {
    space,
    colors: { neutral },
    border: { radius },
  } = defaultTheme;

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      backgroundColor="neutral.white"
    >
      {Array.from(Array(5).keys()).map((n) => (
        <S.SkeletonWrapper key={n} data-testid="skeleton-wrapper">
          <Box
            display="flex"
            flexDirection="row"
            width="100%"
            alignItems="center"
          >
            <Skeleton
              width={48}
              height={48}
              primaryColor={neutral.gray4}
              borderRadius={radius.circle}
            />

            <Box
              display="flex"
              flexDirection="column"
              ml={space.sm}
              width="100%"
              flex={1}
            >
              <S.SkeletonLabelWrapper>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  width="100%"
                >
                  <Skeleton
                    width="30%"
                    isFullWidth
                    height={16}
                    primaryColor={neutral.gray4}
                    borderRadius={radius.sm}
                  />
                </Box>
                <Box display="flex" flexDirection="row">
                  <Skeleton
                    width="80%"
                    isFullWidth
                    height={16}
                    primaryColor={neutral.gray4}
                    borderRadius={radius.sm}
                  />
                </Box>
              </S.SkeletonLabelWrapper>
            </Box>
            <S.SkeletonNotificationWrapper>
              <Skeleton
                width={29}
                height={11}
                primaryColor={neutral.gray4}
                borderRadius={radius.sm}
              />

              <Skeleton
                width={21}
                height={21}
                primaryColor={neutral.gray4}
                borderRadius={radius.circle}
              />
            </S.SkeletonNotificationWrapper>
          </Box>
        </S.SkeletonWrapper>
      ))}
    </Box>
  );
};
