import styled from 'styled-components';

export const NegotiationBillsContainer = styled.div`
  width: 100%;
`;

export const NegotiationBillsHeader = styled.div`
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  .Title {
    margin: 0;
    width: 100%;
  }

  .PageSubTitle {
    margin: 8px 0px 0px;
  }

  .ColoredText {
    cursor: pointer;
    margin-top: 16px;
  }

  @media screen and (min-width: 768px) {
    flex-wrap: nowrap;

    .Title {
      width: unset;
    }

    .ColoredText {
      margin-top: 0;
    }
  }
`;

export const AlertContent = styled.div`
  margin-bottom: 32px;

  .Alert {
    height: 100%;
  }
`;

export const NegotiationBillsContent = styled.div`
  .item-list {
    display: flex;
    flex-direction: column;

    .item-list-header {
      display: flex;
      font-weight: 600;
      font-size: 14px;
      color: ${(props) => props.theme.gray2};
      padding-bottom: 0;
      border-bottom: solid 2px ${(props) => props.theme.gray1};

      > span:first-child {
        display: flex;
        flex-basis: 60%;
      }

      > span:last-child {
        display: flex;
        flex-basis: 40%;
      }
      @media screen and (min-width: 768px) {
        padding-bottom: 16px;
      }
    }
  }
`;

export const ItemsListSummary = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;

  .subtotal {
    display: flex;
    flex-direction: column;

    .subtotal-info {
      display: flex;
      flex-direction: row;

      span {
        margin-right: 8px;
      }
    }

    > span:first-child {
      color: ${(props) => props.theme.gray2};
      margin-bottom: 4px;
    }

    .ColoredText {
      font-size: 18px;
    }
  }
`;

export const OriginalBillsValues = styled.div`
  margin-top: 25px;
  span {
    font-family: Quicksand;
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    margin-right: 16px;
    color: ${(props) => props.theme.gray2};

    &.active {
      font-weight: 700;
      color: ${(props) => props.theme.primaryColor};
    }
  }

  .Tooltip {
    display: inline-block;
  }
`;
