/* eslint-disable no-unused-vars */
import React, { useCallback, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Button from 'components/Button';
import Field from 'components/Form/Field';
import SideSheet from 'components/SideSheet';
import Accordion from 'components/Accordion';

import MoodTrackersActions from 'store/moodTrackers/actions';
import { MoodTrackersState } from 'store/moodTrackers/types';
import { MoodFiltersProps } from './types';

import * as S from './styles';

const MoodTrackersSideSheetFilters = ({
  isOpen,
  onDismiss,
  handleFilter,
}: MoodFiltersProps) => {
  const dispatch = useDispatch();

  const [newHeadquarter, setNewHeadquarter] = useState(null);
  const [newClassroom, setNewClassroom] = useState(null);
  const [newEducationalStage, setNewEducationalStage] = useState(null);

  const FADE_OUT_DURATION = 250;

  const { headquarters, educationalStages, classrooms } = useSelector(
    (state: MoodTrackersState) => state.moodTrackers.formMeta
  );

  const { fetchHeadquarters, fetchEducationalStages, fetchClassrooms } =
    MoodTrackersActions;

  const setHeadquartersFilter = useCallback(() => {
    dispatch(fetchHeadquarters());
  }, [dispatch, fetchHeadquarters]);

  const setEducationalStagesFilter = useCallback(
    (headquarterId) => {
      dispatch(fetchEducationalStages(headquarterId));
    },
    [dispatch, fetchEducationalStages]
  );

  const setClassroomsFilter = useCallback(
    (headquarterId, educationalStageId) => {
      dispatch(fetchClassrooms(headquarterId, educationalStageId));
    },
    [dispatch, fetchClassrooms]
  );

  const applyFilters = (parameters) => {
    document
      .getElementById('side-sheet-wrapper')
      .classList.add('fade-out-animation');
    document.getElementById('side-sheet').classList.add('slide-out-animation');
    setTimeout(() => {
      handleFilter(parameters);
      onDismiss();
    }, FADE_OUT_DURATION);
  };

  const handleApplyFilters = () => {
    applyFilters({
      headquarter: newHeadquarter,
      educationalStage: newEducationalStage,
      classroom: newClassroom,
    });
  };

  const handleClearFilters = () => {
    setNewHeadquarter(null);
    setNewEducationalStage(null);
    setNewClassroom(null);
    applyFilters({
      headquarter: null,
      educationalStage: null,
      classroom: null,
    });
  };

  const hasHeadquarters = !!headquarters?.length;

  const hasEducationalStages =
    hasHeadquarters && newHeadquarter && educationalStages?.length;

  const hasClassrooms =
    hasEducationalStages && newEducationalStage && classrooms?.length;

  useEffect(() => {
    setHeadquartersFilter();
  }, [setHeadquartersFilter]);

  const renderFiltersTitle = (
    <S.AccordionTitle>Unidade/Segmento/Turma</S.AccordionTitle>
  );

  const renderFiltersAccordionBody = (
    <>
      <Field
        attributeName="headquarter_selector"
        classNamePrefix="mood_trackers"
        label="Unidade"
        type="selectWithIcon"
        placeholder="Selecione a unidade"
        value={newHeadquarter}
        options={headquarters}
        disabled={!hasHeadquarters}
        onChange={({ value }) => {
          setNewEducationalStage(null);
          setNewClassroom(null);
          setNewHeadquarter(value);
          setEducationalStagesFilter(value);
        }}
        withArrowSelect
      />
      <Field
        attributeName="educational_stages"
        classNamePrefix="mood_trackers"
        label="Segmento"
        type="selectWithIcon"
        placeholder="Selecione o segmento"
        value={newEducationalStage}
        options={educationalStages}
        disabled={!hasEducationalStages}
        onChange={({ value }) => {
          setNewClassroom(null);
          setNewEducationalStage(value);
          setClassroomsFilter(newHeadquarter, value);
        }}
        withArrowSelect
      />
      <Field
        attributeName="classrooms"
        classNamePrefix="mood_trackers"
        label="Turma"
        type="selectWithIcon"
        value={newClassroom}
        placeholder="Selecione a turma"
        options={classrooms}
        disabled={!hasClassrooms}
        onChange={({ value }) => setNewClassroom(value)}
        withArrowSelect
      />
    </>
  );
  return (
    <SideSheet isOpen={isOpen} onDismiss={onDismiss} title="Filtros">
      <S.FiltersContainer>
        <Accordion
          header={renderFiltersTitle}
          body={renderFiltersAccordionBody}
          allowZeroExpanded
          expanded="moodTrackers"
        />
        <S.ButtonRow>
          <Button variation="primary" onClick={handleApplyFilters}>
            Aplicar
          </Button>
          <Button variation="secondary" onClick={handleClearFilters}>
            Limpar
          </Button>
        </S.ButtonRow>
      </S.FiltersContainer>
    </SideSheet>
  );
};

export default MoodTrackersSideSheetFilters;
