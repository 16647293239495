import styled from 'styled-components';

export const EditRecurrentBillInBatchesWrapper = styled.div`
  display: flex;
  flex-direction: column;

  .editPaymentMethod {
    .Tooltip {
      .tooltip-div {
        > i {
          font-size: 16px !important;
        }
      }
    }
  }

  .checkboxes-wrapper {
    padding: 0 15px;
  }

  .wizard-content {
    padding-bottom: 48px;
  }
`;
