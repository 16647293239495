import moment from 'moment';

export const formatDate = (date: string) => {
  const currentDate = moment();

  if (currentDate.isSame(date, 'day')) {
    return moment(date).format('LT');
  }

  if (moment().subtract(1, 'days').isSame(date, 'day')) return 'Ontem';

  if (!currentDate.isSame(date, 'week')) {
    if (!currentDate.isSame(date, 'day')) {
      return moment(date).format('L');
    }
  } else {
    const weekDay = moment(date)
      .format('dddd')
      .replace(/-feira$/, '');
    return weekDay.charAt(0).toUpperCase() + weekDay.slice(1);
  }
};
