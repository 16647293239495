import styled, { css } from 'styled-components';

export const Container = styled.div`
  padding: 32px;
`;

export const ModalHeader = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const ModalButtonClose = styled.span`
  position: absolute;
  right: 0;
  cursor: pointer;
`;

export const ModalTitle = styled.h4`
  ${({ theme }) => css`
    margin: 0 auto;
    text-align: center;
    font-family: 'Quicksand', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 100%;
    color: ${theme.black};
  `}
`;

export const ModalText = styled.p`
  ${({ theme }) => css`
    margin: 0 auto;
    margin-bottom: 24px;
    text-align: center;
    width: 100%;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 170%;
    color: ${theme.gray2};
  `}
`;

export const ImageBox = styled.div`
  display: flex;
  justify-content: center;
  margin: 24px 0;
  img {
    height: 100%;
    object-position: center;
    -o-object-position: center;
    object-fit: cover;
    -o-object-fit: cover;
  }
`;

export const FormWrapper = styled.div`
  .Form {
    .Field {
      margin: 0;
      padding: 0;
    }
  }
`;
