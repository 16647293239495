import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import AlbumActions from 'store/albums/actions';

import Avatar from 'components/Avatar';
import StudentListSkeleton from './Skeleton';
import { Box, DefaultThemeProps, Text } from '@agendaedu/ae-web-components';
import { Props } from './types';
import { PhotoVisibilityStudent, RootState } from 'store/albums/types';
import { useTheme } from 'styled-components';

const StudentsList = ({ photoId }: Props) => {
  const dispatch = useDispatch();
  const { fetchPhotoVisibilityStudents } = AlbumActions;
  const { t } = useTranslation('albums');
  const { colors, space } = useTheme() as DefaultThemeProps;
  const tBase = 'album_details.photo_details_modal';
  const {
    albumDetails,
    photoVisibilityStudents,
    isLoadingPhotoVisibilityStudents,
  } = useSelector((state: RootState) => state.albums);

  const renderStudent = (
    studentProfile: PhotoVisibilityStudent,
    index: number
  ) => {
    const {
      id,
      attributes: { name, avatarColor, photoUrl, nameInitials },
    } = studentProfile;

    const profile = {
      id,
      type: 'student_profile',
      attributes: {
        name: name,
        avatar_color: avatarColor,
        name_initials: nameInitials,
        avatar_url: photoUrl.medium,
      },
    };

    return (
      <Box key={id} pb={space.xs2} px={space.sm}>
        {index > 0 && (
          <Box
            display="flex"
            borderTopWidth={1}
            borderTopColor={colors.neutral.gray5}
            borderTopStyle="solid"
            mb={space.xs2}
          />
        )}
        <Box
          data-testid="student-container"
          display="flex"
          height="48px"
          alignItems="center"
        >
          <Avatar user={profile} />

          <Text
            as="span"
            variant="subtitle-medium-14"
            color="neutral.gray1"
            title={name}
            ml="sm"
            textOverflow="ellipsis"
            overflow="hidden"
            whiteSpace="nowrap"
          >
            {name}
          </Text>
        </Box>
      </Box>
    );
  };

  useEffect(() => {
    if (photoId) {
      dispatch(
        fetchPhotoVisibilityStudents({
          albumId: albumDetails.id,
          photoId,
        })
      );
    }
  }, [albumDetails.id, dispatch, fetchPhotoVisibilityStudents, photoId]);

  return (
    <Box mx="xs2">
      <Text
        variant="body-regular-16"
        color="neutral.gray1"
        my="md"
        lineHeight="lg"
      >
        {t(`${tBase}.not_permission_message`)}
      </Text>
      <Box maxHeight="70vh" overflowY="auto">
        {isLoadingPhotoVisibilityStudents ? (
          <StudentListSkeleton />
        ) : (
          photoVisibilityStudents.map(renderStudent)
        )}
      </Box>
    </Box>
  );
};

export default StudentsList;
