import React, { useState } from 'react';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';

import AgendaIcon from 'components/AgendaIcon';
import withFormContext, { formPropTypes } from 'core/hoc/withFormContext';

import * as S from './styles';

const RangeTimeField = ({ value, disabled, onChange, attributeName }) => {
  const [time, setTime] = useState('');
  const startsWithTwo = time[0] === '2';
  const secontdTimeStartsWithTwo = time[8] === '2';

  const charFormatRules = {
    2: '[0-2]',
    5: '[0-5]',
    7: startsWithTwo ? '[0-3]' : '[0-9]',
    8: secontdTimeStartsWithTwo ? '[0-3]' : '[0-9]',
    9: '[0-9]',
  };

  const handleTimeChange = (valueTime) => {
    setTime(valueTime);
    onChange(valueTime);
  };

  const onFocus = () => {
    if (disabled) {
      return;
    }
  };

  const onValueChange = (event) => {
    event.preventDefault();

    handleTimeChange(event.target.value);
  };

  const renderInputMaskField = () => {
    return (
      <InputMask
        mask="27:59 - 28:59"
        onChange={onValueChange}
        value={value}
        maskChar=""
        onFocus={onFocus}
        formatChars={charFormatRules}
        disabled={disabled}
        name={attributeName}
        placeholder="00:00 - 00:00"
      />
    );
  };

  return (
    <S.RangeTimeContainer disabled={disabled}>
      <AgendaIcon name="clock" size="medium" />
      {renderInputMaskField()}
    </S.RangeTimeContainer>
  );
};

RangeTimeField.propTypes = {
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  ...formPropTypes,
};

export default withFormContext(RangeTimeField);
