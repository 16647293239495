import styled, { css } from 'styled-components';
import { ThemeProps } from 'config/themes/types';
import { Card, Grid, Tag } from '@agendaedu/ae-web-components';

import {
  TooltipClassroomWrapper,
  TooltipContent,
  TooltipWrapper,
  HandoutTooltip,
  TooltipTitle,
  Tag as StyledTag,
  TagName,
} from '../../styles';

export const CardWrapper = styled(Card)<{ theme: ThemeProps }>`
  ${({ theme: { space } }: ThemeProps) => css`
    padding: ${space.sm} ${space.lg} !important;
    margin-bottom: ${space.sm};
  `}
`;

export const CardContainer = styled(Grid)`
  ${({ theme: { breakpoints } }: ThemeProps) => css`
    width: 100%;
    align-items: center;
    justify-items: start;

    > div:nth-last-child(2) {
      overflow: visible;

      ${InfoContainer} {
        overflow: visible;
      }
    }

    > div:last-child {
      justify-self: center;

      @media screen and (max-width: ${breakpoints.mobileSM}) {
        align-self: flex-start;
        justify-self: flex-start;
      }
    }
  `}
`;

export const ClassroomsContainer = styled(Grid)`
  ${({ theme: { breakpoints } }: ThemeProps) => css`
    justify-self: center;

    @media screen and (max-width: ${breakpoints.mobileSM}) {
      justify-self: flex-start;
    }
  `}
`;

export const ClassroomsTag = styled(Tag)`
  cursor: pointer;
`;

export const AvatarContainer = styled(Grid)`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
`;

export const ResponsibleContainer = styled(Grid)`
  ${({ theme: { space } }: ThemeProps) => css`
    grid-template-rows: 1fr;
    grid-gap: ${space.xs} !important;
    grid-template-columns: auto auto;
  `}
`;

export const InfoContainer = styled.section`
  ${({ theme: { colors, space } }: ThemeProps) => css`
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: flex-start;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;

    h3 {
      margin: ${space.xs4};
      color: ${colors.neutral.black};
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      width: 100%;
    }

    h4 {
      margin: ${space.xs4} ${space.xs4} ${space.xs2} ${space.xs4};
      color: ${colors.neutral.gray2};
    }
  `}
`;

export const EngagementTag = styled(Tag)`
  justify-self: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`;

export const SeenAtTooltip = styled(HandoutTooltip)`
  ${({ theme: { space } }: ThemeProps) => css`
    &:hover {
      ${TooltipWrapper} {
        bottom: ${space.xl5};
      }
    }
  `}
`;

export const SeenAtContent = styled(TooltipContent)`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
`;

export const ClassroomsStyles = {
  TooltipClassroomWrapper,
  TooltipContent,
  TooltipWrapper,
  HandoutTooltip,
  TooltipTitle,
  StyledTag,
  TagName,
};
