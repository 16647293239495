import React, { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Button, TextField } from '@agendaedu/ae-web-components';
import { Modal } from 'components/Handouts/Modal';

import actionHandouts from 'store/handouts/actions';
import { HandoutState } from 'store/handouts/types';

import * as S from './styles';

export const TemplateDeleteModal: React.FC = () => {
  const { t } = useTranslation(['handouts']);
  const dispatch = useDispatch();

  const [confirmSubmitText, setConfirmSubmitText] = useState('');

  const {
    toggleDeleteHandoutTemplateModal,
    toggleHandoutTemplateModal,
    deleteHandoutTemplateRequest,
  } = actionHandouts;

  const { showDeleteHandoutTemplateModal, currentHandoutTemplate } =
    useSelector((state: HandoutState) => state.handouts);

  const isDisabled =
    confirmSubmitText !== t('templates.delete_modal.input_placeholder');

  const handleToggleDeleteTemplateModal = useCallback(() => {
    dispatch(toggleDeleteHandoutTemplateModal());
  }, [dispatch, toggleDeleteHandoutTemplateModal]);

  const handleSetDeleteTemplate = useCallback(() => {
    dispatch(deleteHandoutTemplateRequest(currentHandoutTemplate?.id));
    setConfirmSubmitText('');
    dispatch(toggleHandoutTemplateModal());
    handleToggleDeleteTemplateModal();
  }, [
    currentHandoutTemplate,
    deleteHandoutTemplateRequest,
    dispatch,
    handleToggleDeleteTemplateModal,
    toggleHandoutTemplateModal,
  ]);

  return (
    <Modal.Root
      maxWidth="396px"
      dataTestId="delete-template"
      isOpen={showDeleteHandoutTemplateModal}
      title={t('templates.delete_modal.title')}
      handleToggleModal={handleToggleDeleteTemplateModal}
    >
      <Modal.Content>
        <Modal.Content>
          <S.InforWrapper>
            {t('templates.delete_modal.description')}
            <TextField
              label={t('templates.delete_modal.message')}
              fullWidth
              placeholder={t('templates.delete_modal.input_placeholder')}
              value={confirmSubmitText}
              onChange={(event) =>
                setConfirmSubmitText(event.currentTarget.value)
              }
            />
          </S.InforWrapper>
        </Modal.Content>
        <Modal.Actions>
          <Button
            testId="cancel-delete-button"
            variant="secondary"
            onClick={() => dispatch(toggleDeleteHandoutTemplateModal())}
          >
            {t('templates.delete_modal.cancel')}
          </Button>

          <Button
            testId="submit-delete-button"
            isNegativeAction
            disabled={isDisabled}
            onClick={handleSetDeleteTemplate}
          >
            {t('templates.delete_modal.submit')}
          </Button>
        </Modal.Actions>
      </Modal.Content>
    </Modal.Root>
  );
};
