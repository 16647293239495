import { isAfter, isBefore } from 'validator';

export default function validateDate(constraints) {
  const { before, after } = constraints || {};

  return str => {
    let result = true;

    if(!str) return false;

    if(before) {
      result = result && isBefore(str, before.toString());
    }

    if(after) {
      result = result && isAfter(str, after.toString());
    }

    return result;
  };
}
