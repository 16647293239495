import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import 'react-dates-custom-month-year-navigation/initialize';
import 'react-dates-custom-month-year-navigation/lib/css/_datepicker.css';
import { SingleDatePicker } from 'react-dates-custom-month-year-navigation';

import FormValidationErrors from 'components/Form/ValidationErrors';
import Select from 'components/Form/Select';
import AgendaIcon from 'components/AgendaIcon';
import Button from 'components/Button';

import withFormContext, { formPropTypes } from 'core/hoc/withFormContext';

import * as S from './styles';

const NUMBER_OF_MONTHS = 1;
const STORE_FORMAT = 'YYYY-MM-DD';
const DISPLAY_FORMAT = 'DD/MM/YYYY';

const DateField = ({
  attributeName,
  showAllDays,
  withYearSelect,
  withYearMonthSelect,
  disabled,
  placeholder,
  onValueChange,
  defaultValue,
  isOutsideRange,
  withActionButtons,
  showClearDates,
  formContext: { form, changeAttribute, updateAttribute, hasErrorOnAttribute },
}) => {
  const shouldGetInitialValue = Boolean(
    moment(form[attributeName]) || defaultValue
  );

  const INITIAL_VALUE = useMemo(
    () => (form[attributeName] ? moment(form[attributeName]) : defaultValue),
    [shouldGetInitialValue]
  );

  const [focused, setFocused] = useState(false);
  const [pickerDate, setPickerDate] = useState(INITIAL_VALUE);
  const [selectedDate, setSelectedDate] = useState(INITIAL_VALUE);

  const handleOutsideRange =
    isOutsideRange ||
    ((day) => !showAllDays && day.isBefore(moment().subtract(1, 'days')));

  const hasError = hasErrorOnAttribute(attributeName);

  const shouldUsePortal = () => {
    return (
      typeof window === 'undefined' ||
      window.matchMedia('(max-width: 834px)').matches
    );
  };

  const onYearChange = ({ month, onYearSelect }) => {
    return (formState) => {
      onYearSelect(
        month,
        formState[`${attributeName}_date_field_years`] || moment().year()
      );
    };
  };

  const onMonthSelectChange = ({ month, onMonthSelect }) => {
    return (formState) => {
      onMonthSelect(
        month,
        formState[`${attributeName}_date_field_months`] || moment().month()
      );
    };
  };

  const onMonthChange = (month) => {
    if (withYearSelect || withYearMonthSelect) {
      if (month.year() !== form[`${attributeName}_date_field_years`]) {
        const fakeEvent = {
          target: {
            value: month.year(),
          },
        };

        changeAttribute(`${attributeName}_date_field_years`)(fakeEvent);
      }
    }

    if (withYearMonthSelect) {
      if (month.month() !== form[`${attributeName}_date_field_months`]) {
        const fakeEvent = {
          target: {
            value: month.month(),
          },
        };

        changeAttribute(`${attributeName}_date_field_months`)(fakeEvent);
      }
    }
  };

  const onFocusChange = ({ focused }) => {
    if (withYearMonthSelect) {
      if (form[attributeName]) {
        const selectedDate = form[attributeName];

        updateAttribute(
          `${attributeName}_date_field_months`,
          moment(selectedDate).month()
        );
        updateAttribute(
          `${attributeName}_date_field_years`,
          moment(selectedDate).year()
        );
      } else {
        updateAttribute(`${attributeName}_date_field_months`, moment().month());
        updateAttribute(`${attributeName}_date_field_years`, moment().year());
      }
    }

    setFocused(focused);
    if (!focused) setPickerDate(selectedDate);
  };

  const onChange = (date) => {
    const fakeEvent = {
      target: {
        value: date ? date.format(STORE_FORMAT) : null,
      },
    };
    changeAttribute(attributeName)(fakeEvent);
  };

  const applyChange = (newDate) => {
    if (onValueChange) {
      onValueChange(newDate?.format(STORE_FORMAT));
    } else {
      onChange(newDate);
    }

    setSelectedDate(newDate);
    setPickerDate(newDate);
    setFocused(false);
  };

  const handleDateChange = (date) => {
    setPickerDate(date);

    if (!withActionButtons) {
      applyChange(date);
    }
  };

  const clearDates = () => {
    applyChange(INITIAL_VALUE);
  };

  const renderCaption = () => {
    if (withYearSelect) return renderTitle;

    if (withYearMonthSelect) return renderMonthYearSelect;

    return undefined;
  };

  const renderMonthYearSelect = ({ month, onMonthSelect, onYearSelect }) => {
    return (
      <div className="calendar-caption">
        <Select
          attributeName={`${attributeName}_date_field_months`}
          onChange={onMonthSelectChange({ month, onMonthSelect })}
          placeholder="Mês"
        />

        <Select
          attributeName={`${attributeName}_date_field_years`}
          onChange={onYearChange({ month, onYearSelect })}
          placeholder="Ano"
        />
      </div>
    );
  };

  const renderTitle = ({ month, onMonthSelect, onYearSelect }) => {
    return (
      <div className="calendar-caption">
        <span>{month.format('MMMM YYYY')}</span>
        <Select
          attributeName={`${attributeName}_date_field_years`}
          onChange={onYearChange({ month, onYearSelect })}
          placeholder="Ano"
        />
      </div>
    );
  };

  const renderCalendarButtons = () => {
    return (
      <S.ButtonContainer>
        <S.ActionButtonRow>
          <Button
            id="apply-button"
            role="button"
            variation="primary"
            onClick={() => applyChange(pickerDate)}
          >
            Aplicar
          </Button>
          <Button
            id="clear-button"
            role="button"
            variation="secondary"
            onClick={clearDates}
          >
            Limpar
          </Button>
        </S.ActionButtonRow>
      </S.ButtonContainer>
    );
  };

  return (
    <S.DateFieldWrapper
      withYearSelect={withYearSelect}
      withYearMonthSelect={withYearMonthSelect}
      className="DateField"
    >
      <S.InputGroup
        disabled={disabled}
        className="input-group"
        focused={focused}
        aria-label="Seletor de data"
      >
        <SingleDatePicker
          date={pickerDate}
          focused={focused}
          numberOfMonths={NUMBER_OF_MONTHS}
          displayFormat={DISPLAY_FORMAT}
          isOutsideRange={handleOutsideRange}
          placeholder={placeholder}
          customInputIcon={
            <AgendaIcon
              name="calendar"
              size="medium"
              className="input-group-addon"
            />
          }
          navPrev={<AgendaIcon name="arrow-left" size="small" />}
          navNext={<AgendaIcon name="arrow-right" size="small" />}
          onFocusChange={onFocusChange}
          withPortal={shouldUsePortal()}
          onDateChange={handleDateChange}
          renderCaption={renderCaption()}
          onPrevMonthClick={onMonthChange}
          onNextMonthClick={onMonthChange}
          showClearDate={showClearDates}
          disabled={disabled}
          hideKeyboardShortcutsPanel
          readOnly
          keepOpenOnDateSelect
          calendarInfoPosition="bottom"
          renderCalendarInfo={withActionButtons && renderCalendarButtons}
        />
      </S.InputGroup>
      {hasError && <FormValidationErrors attributeName={attributeName} />}
    </S.DateFieldWrapper>
  );
};

DateField.propTypes = {
  attributeName: PropTypes.string.isRequired,
  showAllDays: PropTypes.bool,
  withYearSelect: PropTypes.bool,
  withYearMonthSelect: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  onValueChange: PropTypes.func,
  defaultValue: PropTypes.string,
  ...formPropTypes,
};

DateField.defaultProps = {
  showAllDays: false,
  withYearSelect: false,
  withYearMonthSelect: false,
  disabled: false,
  placeholder: 'Selecionar',
  withActionButtons: false,
  showClearDates: true,
};

export default withFormContext(DateField);
