/* eslint-disable no-debugger, react/prop-types */
import React, { useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import formatDatetime from 'core/utils/formatDatetime';
import styled from 'styled-components';
import colorWithAlpha from 'core/utils/colorWithAlpha';
import DropdownButton from 'components/DropdownButton';
import withWindowPathname from 'core/hoc/withWindowPathname';
import { IMPORT_THEME_COLOR, IMPORT_STATUS } from './constants';

const StatusTag = styled.div`
  padding: 5px 8px;
  font-family: Quicksand, sans-serif;
  font-weight: 700;
  font-size: 12px;
  width: fit-content;
  border-radius: 4px;
  color: ${(props) => props.theme[props.color]};
  background-color: ${(props) =>
    colorWithAlpha(`${props.theme[props.color]}`, 0.1)};
`;

const CreatedAt = ({ attributes }) => {
  const formattedDate = formatDatetime(
    attributes.created_at,
    'DD/MM/YYYY HH:mm:ss'
  );
  return <span>{formattedDate}</span>;
};

const Username = ({ attributes }) => <span>{attributes.user_name}</span>;

const Filename = ({ attributes }) => (
  <span>{attributes.document_file_name}</span>
);

const Status = ({ attributes }) => (
  <StatusTag color={IMPORT_THEME_COLOR[attributes.status]}>
    {IMPORT_STATUS[attributes.status]}
  </StatusTag>
);

const Actions = ({ id, history, windowPathname, attributes }) => {
  const importedInto = windowPathname.split('/')[3];

  const pushTo = useCallback(
    (page) => {
      history.push(`/schools/import_webs/${importedInto}/${id}/${page}`);
    },
    [history, importedInto, id]
  );

  const downloadImportFile = useCallback(
    (url) => {
      if (attributes[url].length) window.location.href = attributes[url];
    },
    [attributes]
  );

  const DROPDOWN_ITEMS = [
    {
      text: 'Resumo',
      onClick: () => pushTo('summary'),
    },
    {
      text: 'Inconsistências',
      onClick: () => pushTo('inconsistencies'),
    },
    {
      text: 'Baixar arquivo original',
      onClick: () => downloadImportFile('original_file_url'),
    },
  ];

  if (attributes.corrected_file_url.length > 0) {
    DROPDOWN_ITEMS.push({
      text: 'Baixar arquivo corrigido',
      onClick: () => downloadImportFile('corrected_file_url'),
    });
  }

  return (
    <DropdownButton
      text="Ações"
      dropdownItems={DROPDOWN_ITEMS}
      icon="arrow-down"
      iconSize="medium"
      variation="secondary"
    />
  );
};

export default [
  {
    Header: 'Data/Hora',
    accessor: CreatedAt,
  },
  {
    Header: 'Usuário',
    accessor: Username,
  },
  {
    Header: 'Nome do arquivo importado',
    accessor: Filename,
  },
  {
    Header: 'Status',
    accessor: Status,
  },
  {
    Header: 'Ações',
    accessor: withRouter(withWindowPathname(Actions)),
  },
];
