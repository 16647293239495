import React, { createContext, useCallback, useMemo, useState } from 'react';
import _ from 'lodash';

import { DiaryFormStructure, DiaryState } from 'store/dailySummaries/types';
import { ContextProps, Props, UpdateStudentDiaryAction } from './types';
import { useFormik } from 'formik';
import * as yup from 'yup';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import DiaryActions from 'store/dailySummaries/actions';

import {
  defaultAnswersAttributesValues,
  defaultBabyBottles,
  defaultEvacuations,
  defaultMeal,
  defaultOccurrence,
  defaultSleeps,
} from '../constants';

const initialValuesInfo: DiaryFormStructure['sectionsForm'] = {
  notify: false,
  studentsDiaries: {},
};

export const DiarySectionsFormContext = createContext<Props>({
  form: { values: initialValuesInfo },
} as Props);

export const DiarySectionsFormProvider: React.FC<ContextProps> = ({
  children,
  classroomId,
}) => {
  const dispatch = useDispatch();
  const { saveDiariesPerSections } = DiaryActions;
  const [date, setDate] = useState(moment(new Date()));
  const [isSaving, setIsSaving] = useState(false);
  const [currentSectionId, setCurrentSectionId] = useState<string>('');

  const {
    classroom: {
      attributes: {
        student_profiles: { data: students },
      },
    },
    diarySections: { activesSections },
    studentsDiariesBySection: { studentsDiaries },
  } = useSelector((state: DiaryState) => state.dailySummaries);

  const onSuccess = () => {
    setIsSaving(false);
  };

  const onFail = () => {
    setIsSaving(false);
  };

  const onSubmitDiary = (values: DiaryFormStructure['sectionsForm']) => {
    setIsSaving(true);

    const params = {
      ...values,
      date: date.format('YYYY-MM-DD'),
      classroom_id: classroomId,
      studentsDiaries: values.studentsDiaries,
    };

    dispatch(
      saveDiariesPerSections({
        params,
        onSuccess,
        onFail,
      })
    );
  };

  const initialValues: DiaryFormStructure['sectionsForm'] = useMemo(() => {
    const normalizedStudentsDiaries = students.reduce(
      (
        prevStudentDiaries: DiaryFormStructure['sectionsForm']['studentsDiaries'],
        student
      ) => {
        const studentDiary = {
          meals: [defaultMeal()],
          baby_bottles: defaultBabyBottles(),
          sleeps: defaultSleeps(),
          evacuations: defaultEvacuations(),
          diaper: 0,
          shower: 0,
          occurrences: [defaultOccurrence()],
          occurrences_to_destroy: [],
          observation: '',
          answers_attributes: {},
        };

        for (const section of activesSections) {
          if (section.custom) {
            studentDiary.answers_attributes[section.id] = [[]];

            for (const question of section.questionsAttributes) {
              studentDiary.answers_attributes[section.id][0].push(
                defaultAnswersAttributesValues(question.id)
              );
            }
          }
        }

        const studentAlreadyHasDiary = !!studentsDiaries?.[student.id];

        if (studentAlreadyHasDiary) {
          const newDiaryValues = {
            ...studentDiary,
            ...studentsDiaries[student.id],
            answers_attributes: {
              ...studentDiary.answers_attributes,
              ...studentsDiaries[student.id].answers_attributes,
            },
          };

          return {
            ...prevStudentDiaries,
            [student.id]: newDiaryValues,
          };
        } else {
          return {
            ...prevStudentDiaries,
            [student.id]: studentDiary,
          };
        }
      },
      {}
    );

    return { ...initialValues, studentsDiaries: normalizedStudentsDiaries };
  }, [activesSections, students, studentsDiaries]);

  const formValidationSchema = yup.object().shape({
    studentsDiaries: yup.lazy((students) =>
      yup.object(
        Object.keys(students || {}).reduce((validations, studentId) => {
          validations[studentId] = yup.object().shape({
            occurrences: yup.array().of(
              yup.object().shape({
                files: yup
                  .array()
                  .of(
                    yup.object().shape({
                      invalidReasons: yup.array().max(0),
                    })
                  )
                  .max(10),
              })
            ),
          });
          return validations;
        }, {})
      )
    ),
  });

  const form = useFormik({
    initialValues,
    onSubmit: onSubmitDiary,
    isInitialValid: true,
    enableReinitialize: true,
    validationSchema: formValidationSchema,
  });

  const updateStudentDiary: UpdateStudentDiaryAction = useCallback(
    (studentId, fieldsValues) => {
      const cloneDiaries = _.cloneDeep(form.values.studentsDiaries);

      Object.entries(fieldsValues).forEach(([field, value]) => {
        cloneDiaries[studentId][field] = value;

        form.setFieldValue('studentsDiaries', cloneDiaries);
      });
    },
    [form]
  );

  return (
    <DiarySectionsFormContext.Provider
      value={{
        form,
        setDate,
        date,
        currentSectionId,
        setCurrentSectionId,
        isSaving,
        classroomId,
        updateStudentDiary,
      }}
    >
      {children}
    </DiarySectionsFormContext.Provider>
  );
};
