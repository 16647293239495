import React, { useEffect } from 'react';
import OfferState from './components/OfferState';
import { constants } from './constants';
import * as S from './styles';
import ListBilling from './components/ListBilling';

import reduxProvider from 'core/hoc/reduxProvider';
import withAppContext, { appPropTypes } from 'core/hoc/withAppContext';
import { useTranslation } from 'react-i18next';

const BillingPanel = ({ appContext: { payingSchool } }) => {
  const { t } = useTranslation(['billing_panel']);

  const changeWrapperBackgroundColor = () => {
    document.body.style.backgroundColor = '#ffffff';
  };

  useEffect(() => {
    changeWrapperBackgroundColor();
  }, []);

  return (
    <>
      <S.PageTitle>{t('service_and_payment')}</S.PageTitle>
      <S.Container alignment="horizontally" centered>
        {payingSchool ? (
          <ListBilling />
        ) : (
          <OfferState
            imageUrl={constants.IMAGE_OFFER}
            title={t('empty_state.title')}
            subtitle={t('empty_state.message')}
            topics={constants.TOPICS_EMPTY}
          />
        )}
      </S.Container>
    </>
  );
};

BillingPanel.propTypes = {
  ...appPropTypes,
};

export default reduxProvider(withAppContext(BillingPanel));
