import React, { useEffect, useCallback } from 'react';

import withFormContext from 'core/hoc/withFormContext';
import FormContext from 'core/contexts/Form';
import tabifyForWizard from 'core/hoc/tabifyForWizard';
import FormSelector from 'components/Form/Selector';
import GroupSelector from 'components/Form/GroupSelector';
import Loader from 'components/Loader';

import './style.scss';

const CoursesTab = ({ formContext }) => {
  const {
    changeMeta,
    formMeta,
    form,
    provider
  } = formContext;

  const fieldsFillVerification = useCallback(() => {
    changeMeta('nextStepDisabled', !form.courses.selected.length);
  }, [changeMeta]);

  useEffect(() => {
    fieldsFillVerification();
  }, [fieldsFillVerification]);

  const updateSelectorColumns = useCallback(({ selectedValues }) => {
    form.courses.selected = selectedValues;
    formMeta.course(selectedValues);

    fieldsFillVerification();
  }, [fieldsFillVerification]);

  const updateGroupSelectorColumns = (courseId) => {
    form.courses.selected = courseId;
    formMeta.course(courseId);

    fieldsFillVerification();
  };

  const searchOptions = ({ options, searchTerm }) => (
    options.filter(
      option => option.name.toLowerCase().includes(searchTerm.toLowerCase()),
    )
  );

  const optionComponent = option => (
    <span className="course-name">
      {option.name}
    </span>
  );

  return (
    <div className="coursesTab">
      <div>
        <div className="stepInfo">
          <p>
            Selecione quais
            {' '}
            <b>Cursos</b>
            {' '}
            serão importados na integração.
            Essas configurações serão salvas para as próximas integrações.
          </p>
        </div>

        <div className='selectContent'>
          <FormContext.Consumer>
            {({ form }) => (

              <Loader isLoading={form.loading}>
                { ['gennera', 'ischolar'].includes(provider) ?
                  <GroupSelector
                    options={form.data.courses.filters}
                    selectedValues={form.data.courses.selected}
                    onChange={updateGroupSelectorColumns}
                    onSearch={searchOptions}
                    selectAllLabel="Selecionar todos"
                    deselectAllLabel="Remover todos"
                    counterLabel={length => (length === 1 ? 'curso' : 'cursos')}
                  /> :
                  <FormSelector
                    options={form.data.courses.filters}
                    selectedValues={form.data.courses.selected}
                    optionComponent={optionComponent}
                    onChange={updateSelectorColumns}
                    search={searchOptions}
                    searchPlaceholder="Buscar curso"
                    optionAccessor={option => option.code}
                    selectAllLabel="Selecionar todos"
                    onSelectBehavior="highlight"
                    counterLabel={length => (length === 1 ? 'curso' : 'cursos')}
                  />
                }
              </Loader>
            )}
          </FormContext.Consumer>
        </div>
      </div>
    </div>
  );
};

export default tabifyForWizard({
  title: 'Cursos',
  subTitle: '',
})(withFormContext(CoursesTab));
