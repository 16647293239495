import React, { useEffect, useCallback } from 'react';
import { Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import tabify from 'core/hoc/tabify';

import RejectSchoolProductForm from 'components/Payments/PaymentsContainer/RejectSchoolProductForm';

import actionSchoolProducts from 'store/edupay/schoolProducts/actions';
import { SchoolProductsState } from 'store/edupay/schoolProducts/types';

import { RejectSchoolProductTabProps } from './types';

const RejectSchoolProductTab = ({
  schoolProductId,
}: RejectSchoolProductTabProps) => {
  const dispatch = useDispatch();

  const { currentSchoolProduct } = useSelector(
    (state: SchoolProductsState) => state.schoolProducts
  );
  const { fetchCurrentSchoolProduct } = actionSchoolProducts;

  const handleGetCurrentSchoolProduct = useCallback(
    async (params) => {
      dispatch(fetchCurrentSchoolProduct(params));
    },
    [dispatch, fetchCurrentSchoolProduct]
  );

  useEffect(() => {
    handleGetCurrentSchoolProduct({ schoolProductId });
  }, [schoolProductId, handleGetCurrentSchoolProduct]);

  if (currentSchoolProduct?.attributes?.status == 'rejected') {
    return <Redirect to={`/schools/school_products/${schoolProductId}`} />;
  }

  return (
    <div data-testid="reject-school-product-tab">
      <div className="tab-name" data-testid="tab-name">
        INFORMAÇÕES
      </div>
      <RejectSchoolProductForm schoolProductId={schoolProductId} />
    </div>
  );
};

export default tabify({
  title: 'Informações',
  icon: 'info',
})(RejectSchoolProductTab);
