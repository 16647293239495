import styled, { css } from 'styled-components';
import ModalContainer from 'components/Modal/ModalContainer';

export const ModalWrapper = styled(ModalContainer)`
  ${({ theme }) => css`
    .ReactModal__Content .icon-ae-close {
      margin-top: 0;
      font-size: 16px;
    }

    .modal-title {
      font-family: 'Quicksand';
      font-size: 24px;
      font-style: normal;
      font-weight: bold;
      font-stretch: normal;
      line-height: 1;
      color: ${theme.black};
      margin-bottom: 24px;
    }

    .subTitle {
      font-family: 'Roboto';
      font-size: 16px;
      margin-bottom: 24px;
    }

    .Form {
      margin-bottom: 12px;

      .UserItemWrapper {
        margin-bottom: 12px;

        .UserItem {
          border-radius: 0;

          &:only-child {
            border-radius: 8px;
          }

          &:first-child:not(:last-child) {
            border-radius: 8px 8px 0 0;
          }

          &:last-child:not(:first-child) {
            border-radius: 0 0 8px 8px;
          }

          .selected {
            border: 0;
            border-color: none;
          }

          .OutlineBox {
            border: 0;
            background-color: ${theme.gray14};
          }

          .RadioButton {
            padding-left: 0;
          }

          .ButtonRow {
            margin-top: 32px;
          }
        }
      }

      .cpfWrapper {
        font-family: 'Roboto';
        margin-top: 12px;

        .Field {
          width: 100%;
          margin-bottom: 12px;

          input {
            height: 40px;
          }

          .FormValidationErrors {
            margin-bottom: 0;
          }
        }
      }
    }

    .messageError {
      color: ${theme.danger};
    }

    .click-here {
      color: #773dd3;
      text-decoration: underline;
    }
  `}
`;
