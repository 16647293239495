import React from 'react';
import reduxProvider from 'core/hoc/reduxProvider';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Headquarters from 'pages/headquarters/List';
import HeadquartersNew from 'pages/headquarters/New';
import HeadquartersEdit from 'pages/headquarters/Edit';
import StudentProfilesNew from 'pages/studentProfiles/New';
import StudentProfilesEdit from 'pages/studentProfiles/Edit';
import StudentProfilesRecover from 'pages/studentProfiles/Recover';
import ResponsibleProfilesNew from 'pages/ResponsibleProfiles/New';
import ResponsibleProfilesEdit from 'pages/ResponsibleProfiles/Edit';
import ResponsibleProfilesRecover from 'pages/ResponsibleProfiles/Recover';
import ImportWeb from 'pages/ImportWeb';
import ImportWebNew from 'pages/ImportWeb/New';
import ImportWebSummary from 'pages/ImportWeb/Summary';
import ImportWebInconsistencies from 'pages/ImportWeb/Inconsistencies';

const SchoolOrganizationRoutes = () => {
  return (
    <div className="SchoolOrganizationMainScreen">
      <BrowserRouter>
        <Switch>
          <Route
            exact
            path="/schools/headquarters"
            component={() => <Headquarters />}
          />
          <Route
            exact
            path="/schools/headquarters/new"
            component={() => <HeadquartersNew />}
          />
          <Route
            exact
            path="/schools/headquarters/:id/edit"
            component={(props) => <HeadquartersEdit {...props} />}
          />
          <Route
            exact
            path="/schools/student_profiles/new"
            component={() => <StudentProfilesNew />}
          />
          <Route
            exact
            path="/schools/student_profiles/:id/edit"
            component={(props) => <StudentProfilesEdit {...props} />}
          />
          <Route
            exact
            path="/schools/student_profiles/recover/:id/edit"
            component={(props) => <StudentProfilesRecover {...props} />}
          />
          <Route
            exact
            path="/schools/student_profiles/:id/review"
            component={(props) => <StudentProfilesEdit {...props} />}
          />
          <Route
            exact
            path="/schools/responsible_profiles/new"
            component={() => <ResponsibleProfilesNew />}
          />
          <Route
            exact
            path="/schools/responsible_profiles/:id/edit"
            component={(props) => <ResponsibleProfilesEdit {...props} />}
          />
          <Route
            exact
            path="/schools/responsible_profiles/:id/recover"
            component={(props) => <ResponsibleProfilesRecover {...props} />}
          />
          <Route
            exact
            path="/schools/responsible_profiles/:id/review"
            component={(props) => <ResponsibleProfilesEdit {...props} />}
          />
          <Route
            exact
            path="/schools/import_webs/:importedInto"
            component={(props) => <ImportWeb {...props} />}
          />
          <Route
            exact
            path="/schools/import_webs/:importedInto/new"
            component={() => <ImportWebNew />}
          />
          <Route
            exact
            path="/schools/import_webs/:importedInto/:id/summary"
            component={(props) => <ImportWebSummary {...props} />}
          />
          <Route
            exact
            path="/schools/import_webs/:importedInto/:id/inconsistencies"
            component={(props) => <ImportWebInconsistencies {...props} />}
          />
        </Switch>
      </BrowserRouter>
    </div>
  );
};

export default reduxProvider(SchoolOrganizationRoutes);
