import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Card,
  DefaultThemeProps,
  Icon,
  SubtleIconButton,
  Tag,
  Text,
  Tooltip,
} from '@agendaedu/ae-web-components';
import * as S from './styles';

import AlbumActions from 'store/albums/actions';

import { EMPTY_STATES_URL } from 'core/constants/index';
import { Props } from './types';
import { useTheme } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { AlbumDetailsMediasIncluded, RootState } from 'store/albums/types';
import ApprovePendingMediaModal from '../ApprovePendingMediaModal';
import DeleteMediaModal from '../DeleteMediaModal';
import { useDownloadFile } from 'core/hooks/useDownloadFile';
import { normalizeDownloadURL } from 'core/helper/albums';

const PhotoCard = ({
  id: idElement,
  photo,
  onShowPhotoDetails,
  onEditVisibility,
}: Props) => {
  const { setMediaCover } = AlbumActions;
  const dispatch = useDispatch();

  const { t } = useTranslation(['albums', 'common']);
  const { border, colors } = useTheme() as DefaultThemeProps;
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { albumDetails, albumDetailsMediasIncluded } = useSelector(
    (state: RootState) => state.albums
  );
  const { requestDownloadFileWithFetch } = useDownloadFile({
    getFileURL: normalizeDownloadURL(photo?.attributes?.originalUrl),
    getFileName: photo?.attributes.name,
  });

  const {
    attributes: { description, mediumUrl },
    relationships: {
      userPermission: {
        data: { id: userPermissionId },
      },
    },
  } = photo;

  const userPermission = albumDetailsMediasIncluded.find(
    (include) =>
      include.type === 'userPermission' && include.id === userPermissionId
  ) as AlbumDetailsMediasIncluded;

  const setCover = () => {
    dispatch(setMediaCover({ id: photo.id, albumId: albumDetails.id }));
  };

  const actions = [
    userPermission.attributes.canApprove &&
      photo.attributes.status === 'not_approved' && {
        as: 'button',
        title: t('album_details.photo_card.approve'),
        onClick: (e: Event) => {
          e.stopPropagation();
          setShowApproveModal(true);
        },
      },
    userPermission.attributes.canUpdate && {
      as: 'button',
      title: t('common:button.edit'),
      onClick: (e: Event) => {
        e.stopPropagation();
        onShowPhotoDetails(true);
      },
    },
    userPermission.attributes.canSetCover &&
      !photo.attributes.isCover && {
        as: 'button',
        title: t('album_details.photo_card.set_cover'),
        onClick: (e: Event) => {
          e.stopPropagation();
          setCover();
        },
      },
    {
      as: 'button',
      title: t('album_details.photo_card.download'),
      onClick: (e: Event) => {
        e.stopPropagation();
        requestDownloadFileWithFetch();
      },
    },
    userPermission.attributes.canDestroy && {
      as: 'button',
      title: t('album_details.photo_card.delete'),
      onClick: (e: Event) => {
        e.stopPropagation();
        setShowDeleteModal(true);
      },
    },
  ].filter(Boolean);

  const handleEditVisibility = (e: Event) => {
    e.stopPropagation();
    onEditVisibility(photo);
  };

  return (
    <Card
      id={idElement}
      cursor="pointer"
      data-testid="photo-card"
      width="100%"
      p={0}
      onClick={() => onShowPhotoDetails()}
    >
      <S.PhotoWrapper>
        <Box display="flex" position="relative" width="100%" height="100%">
          <S.CoverWrapper>
            <S.CoverImage
              src={mediumUrl || EMPTY_STATES_URL.photoPlaceholder}
              alt={description}
            />
          </S.CoverWrapper>

          <S.InfoContainer>
            <Box
              position="absolute"
              top={0}
              left={0}
              right={0}
              bottom={0}
              ins
              m="sm"
            >
              <Box display="flex" justifyContent="space-between">
                <Box
                  data-testid="who-can-visible"
                  display="flex"
                  backgroundColor={colors.neutral.gray5}
                  borderRadius={border.radius.sm}
                  alignItems="center"
                  px="xs2"
                  cursor="pointer"
                  onClick={handleEditVisibility}
                >
                  <Tooltip
                    align="top"
                    content={t('album_details.photo_card.student_count_info')}
                    elementRef={
                      <Box display="flex" alignItems="center">
                        <Icon
                          name="user-group"
                          size="sm"
                          color={colors.neutral.gray1}
                        />

                        <Text
                          variant="subtitle-medium-12"
                          color="neutral.gray1"
                          m="xs4"
                          ml="xs3"
                        >
                          {photo.attributes.totalStudents}
                        </Text>
                      </Box>
                    }
                  />
                </Box>

                {!!actions.length && (
                  <Box
                    display="flex"
                    alignItems="center"
                    backgroundColor="white"
                    width="fit-content"
                    borderRadius={border.radius.sm}
                  >
                    <SubtleIconButton
                      data-testid="actions-card"
                      size="xs"
                      align="right"
                      actions={actions}
                    />
                  </Box>
                )}
              </Box>

              <Box
                position="absolute"
                bottom={0}
                width="fit-content"
                backgroundColor="white"
                borderRadius={border.radius.sm}
              >
                {photo.attributes.status === 'not_approved' && (
                  <Tag
                    size="small"
                    variant="warning"
                    name={t('album_details.photo_card.pending')}
                  />
                )}
              </Box>
            </Box>
          </S.InfoContainer>
        </Box>
      </S.PhotoWrapper>

      <ApprovePendingMediaModal
        media={photo}
        isOpen={showApproveModal}
        onClose={() => setShowApproveModal(false)}
      />
      <DeleteMediaModal
        media={photo}
        isOpen={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
      />
    </Card>
  );
};

export default PhotoCard;
