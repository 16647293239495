import { ThemeProps } from 'config/themes/types';
import TextareaAutosize from 'react-autosize-textarea';
import styled, { css } from 'styled-components';
import {
  AttachmentButtonStyleProps,
  EmoteButtonStyleProps,
  InputFieldStyleProps,
  SubmitButtonStyleProps,
} from './types';

export const TextAreaInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Wrapper = styled.div`
  ${({ theme: { space, border } }: ThemeProps) => css`
    display: flex;
    flex-direction: row;
    width: 100%;
    border-radius: ${border.radius.lg} ${border.radius.lg} 0 0;
    align-items: center;
    justify-content: center;
    gap: ${space.sm};
    padding: ${space.xs4} ${space.sm};
    background-color: white;
    filter: drop-shadow(0px -2px 10px rgba(115, 61, 190, 0.078));
  `}
`;

export const InputField = styled(TextareaAutosize)<InputFieldStyleProps>`
  ${({ disabled, theme: { colors, space, border, typography } }) => css`
    width: 100%;
    background-color: ${colors.neutral.white};
    resize: none;
    border-radius: ${border.radius.md};
    border: none;
    ${typography.body.Regular16}
    word-break: break-word;
    padding: ${space.xs2} ${space.sm};
    transition: background-color 0.5s;
    box-sizing: border-box;
    word-wrap: break-word;
    word-break: break-all;
    overflow: unset;
    overflow-y: auto;
    margin: ${space.xs2} ${space.xs4};
    max-height: 320px !important;

    ${
      disabled &&
      css`
      :hover {
        cursor: not-allowed;
      `
    }
    }

    &::-webkit-scrollbar {
      background-color: transparent;
      width: 6px;
    }

    &::-webkit-scrollbar-button {
      display: none;
    }

    &::-webkit-scrollbar-track {
      background-color: ${colors.neutral.gray5};
    }

    &::-webkit-scrollbar-thumb {
      border-radius: ${border.radius.sm};
      border: ${border.width.sm} ${border.style.solid} ${colors.neutral.gray4};
    }

    ::placeholder {
      color: ${disabled ? colors.neutral.gray3 : colors.neutral.gray2};
    }

    :not(:placeholder-shown) {
      background-color: ${colors.neutral.gray6};
    }
  `}
`;

export const EmoteButton = styled.button<EmoteButtonStyleProps>`
  ${({ disabled, theme: { colors, space } }) => css`
    display: flex;
    height: 40px;
    min-width: 40px;
    border: none;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border-radius: ${space.xs2};

    path {
      fill: ${colors.neutral.gray3};
    }

    :hover {
      cursor: ${disabled ? 'not-allowed' : 'pointer'};
    }

    ${!disabled &&
    css`
      :hover {
        background-color: ${colors.brand.primary.op10};
        path {
          fill: ${colors.brand.primary.default};
        }
      }

      :active {
        background-color: ${colors.brand.primary.op20};
      }

      path {
        fill: ${colors.neutral.gray1};
      }
    `}
  `}
`;

export const AttachmentButton = styled.label<AttachmentButtonStyleProps>`
  ${({ disabled, theme: { colors, space } }) => css`
    display: flex;
    height: 40px;
    min-width: 40px;
    border: none;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border-radius: ${space.xs2};

    :hover {
      cursor: ${disabled ? 'not-allowed' : 'pointer'};
    }

    path {
      fill: ${colors.neutral.gray3};
    }

    ${!disabled &&
    css`
      :hover {
        background-color: ${colors.brand.primary.op10};

        path {
          fill: ${colors.brand.primary.default};
        }
      }

      :active {
        background-color: ${colors.brand.primary.op20};
      }

      path {
        fill: ${colors.neutral.gray1};
      }
    `}
  `}
`;

export const SubmitButton = styled.button<SubmitButtonStyleProps>`
  ${({ disabled, theme: { colors } }) => css`
    height: 40px;
    min-width: 40px;
    border: none;
    justify-content: center;
    align-items: center;
    background-color: transparent;

    ${SubmitButtonWrapper} {
      background-color: ${!disabled
        ? colors.brand.primary.default
        : colors.neutral.gray3};

      cursor: ${disabled ? 'not-allowed' : 'pointer'};
    }
  `}
`;

export const SubmitButtonWrapper = styled.div`
  ${({ theme: { colors, border } }: ThemeProps) => css`
    display: flex;
    height: 36px;
    width: 36px;
    justify-content: center;
    align-items: center;
    transition: background-color 0.5s ease;

    border-radius: ${border.radius.circle};

    path {
      fill: ${colors.neutral.white};
    }
  `}
`;

export const AttachmentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  > input {
    display: none;
  }
`;
