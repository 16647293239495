import styled, { css } from 'styled-components';

export const ReceivedMessageWrapper = styled.div`
  width: 100%;
  margin-bottom: 20px;
  word-wrap: break-word;
  word-break: break-all;

  display: flex;
  flex-direction: row;
  align-items: flex-start;

  .Avatar {
    width: 30px;
    height: 30px;
    margin-bottom: 30px;
    margin-right: 10px;

    align-self: flex-start;
    flex-shrink: 0;
  }

  .MessagesInfo {
    margin-top: 5px;
    font-size: 11px;
  }
`;

export const ActionsWrapper = styled.div<{
  isHovered: boolean;
  isOpen: boolean;
}>`
  ${({ theme, isHovered, isOpen }) => css`
    position: absolute;
    bottom: 22px;
    left: calc(100% - 16px);
    display: ${!isHovered && !isOpen ? 'none' : 'flex'};
    flex-direction: row;
    gap: 4px;

    > div {
      color: ${theme.colors.brand.primary.default};
      background: ${theme.colors.neutral.gray5};
      cursor: pointer;
    }
  `}
`;

export const MessageBalloonWrapper = styled.div`
  position: relative;
  max-width: 60%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
