import styled from 'styled-components';

export const Container = styled.section`
  width: 100%;
  margin: 96px 0 32px;

  @media screen {
    &.printContainer {
      visibility: hidden;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
`;

export const Header = styled.header`
  margin: 0 0 16px 0;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  h2,
  span {
    color: #000000;
  }

  span {
    font-size: 14px;
  }

  img {
    margin: 0 0 8px 0;
    width: 8%;
  }
`;

export const Content = styled.div`
  max-width: 576px;
  margin: auto;

  display: flex;
  flex-direction: column;
  text-align: start;

  > div {
    margin: 16px 0;
    padding: 16px 0;

    border-top: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;

    display: flex;
    flex-direction: column;

    p {
      margin-bottom: 0;

      & + p {
        margin-top: 8px;
      }
    }
  }

  p {
    color: #000000;
    font-size: 14px;
  }
`;
