import styled, { css } from 'styled-components';
import { ThemeProps } from 'config/themes/types';

import Field from 'components/Form/Field';

export const MainContainer = styled.section`
  margin: 32px 64px;

  form {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: flex-start;
  }
`;

export const MainTitle = styled.h3`
  ${({ theme: { font, colors, space } }: ThemeProps) => css`
    font-weight: 700;
    font-family: ${font.family.brand};
    font-size: ${font.size.xl};
    line-height: ${font.height.lg};
    color: ${colors.neutral.black};
    margin-bottom: ${space.xl};
  `}
`;

export const MainFilterField = styled(Field)`
  padding-left: 0;
  order: 1;

  @media (max-width: 833px) {
    max-width: 85%;
  }
`;

export const DateField = styled(Field)`
  order: 2;

  @media (max-width: 833px) {
    padding: 0;
    margin: 16px 0;
    order: 3;
  }
`;

export const TooltipWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  order: 3;
  padding: 0;
  margin: 0;
  height: ${({ theme: { space } }: ThemeProps) => space.xl2};

  @media (max-width: 833px) {
    display: none;
  }
`;

export const TermColor = styled.span`
  ${({ theme: { font, colors } }: ThemeProps) => css`
    padding: 1px 2px 1px 1px;
    width: 80%;
    font-weight: 700;
    text-align: justify;
    text-justify: inter-word;
    line-height: ${font.height.lg};
    font-family: ${font.family.auxiliary};
    font-size: ${font.size.sm};
    color: ${colors.brand.primary.default};
  `}
`;
