import { ThemeProps } from 'config/themes/types';
import styled, { css } from 'styled-components';
import { VariantMessageStyleProps } from './types';

const actionsModdfiers = {
  received: ({ theme: { colors } }: ThemeProps) => css`
    bottom: 0;
    left: 50%;

    > div {
      color: ${colors.neutral.white};
    }
  `,
  sent: ({ theme: { colors } }: ThemeProps) => css`
    bottom: 0;
    right: 50%;

    > div {
      color: ${colors.brand.primary.default};
    }
  `,
};

export const ActionsWrapper = styled.div`
  ${() => css`
    display: flex;
    grid-area: actions;
    width: 100%;
    position: relative;
  `}
`;

export const ActionContainer = styled.section<VariantMessageStyleProps>`
  ${({ theme, variantType, isActive }) => css`
    display: ${isActive ? 'flex' : 'none'};
    position: absolute;
    flex-direction: row;
    gap: ${theme.space.xs3};

    ${actionsModdfiers[variantType]({ theme })};
  `}
`;
