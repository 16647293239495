import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Text } from '@agendaedu/ae-web-components';

import withAppContext from 'core/hoc/withAppContext';
import EmptyState from 'components/EmptyState';
import Dashboard from 'pages/moodTrackers/Dashboard';
import StudentsList from 'pages/moodTrackers/StudentsList';

import { IMAGES_SURVERY_URL } from 'core/constants';

import MoodTrackersActions from 'store/moodTrackers/actions';
import { MoodTrackersState } from 'store/moodTrackers/types';

import MoodTrackerFilters from './Filters';

import * as S from './styles';

const MoodTrackersPage = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation(['mood_trackers']);

  const { fetchAnswers } = MoodTrackersActions;

  const {
    date,
    answerType,
    headquarter,
    educationalStage,
    classroom,
    studentsList,
    isSending,
    pagination: { page },
  } = useSelector((state: MoodTrackersState) => state.moodTrackers);

  const renderEmptyState = (
    <EmptyState
      message={t('empty_state_filters')}
      imgUrl={IMAGES_SURVERY_URL.emptyStateUrl}
      centered
    />
  );

  const hasFilters = !!answerType || !!headquarter;

  useEffect(() => {
    dispatch(
      fetchAnswers({
        date,
        answerType,
        headquarter,
        educationalStage,
        classroom,
        page,
      })
    );
  }, [
    dispatch,
    fetchAnswers,
    date,
    answerType,
    headquarter,
    educationalStage,
    classroom,
    page,
  ]);

  return (
    <S.PageContainer>
      <Text variant="headline-h2-bold-24" as="h1">
        {t('title')}
      </Text>

      <MoodTrackerFilters />

      {hasFilters && !studentsList.length && !isSending ? (
        renderEmptyState
      ) : (
        <Dashboard />
      )}

      <StudentsList />
    </S.PageContainer>
  );
};

export default withAppContext(MoodTrackersPage);
