import React from 'react';
import { Link } from 'react-router-dom';

import Currency from 'components/Currency';
import OutlineBox from 'components/OutlineBox';
import Button from 'components/Button';
import ClassroomsList from 'components/ClassroomsList';

const RedirectLink = ({ children, id }) => (
  <Link className="click-to-redirect" to={`/schools/school_products/${id}`}>
    {children}
  </Link>
);

const Title = ({ attributes, id }) => (
  <RedirectLink id={id}>
    {attributes?.source_api_v2 && (
      <OutlineBox variation="warning">API</OutlineBox>
    )}
    <b>{attributes.title}</b>
  </RedirectLink>
);

const Created = ({ attributes, id }) => (
  <RedirectLink id={id}>{attributes.created_at}</RedirectLink>
);

const Price = ({ attributes, id }) => (
  <RedirectLink id={id}>
    <Currency value={attributes.price} />
  </RedirectLink>
);

const Recipients = ({ attributes, id }) => {
  const sentKind = attributes.sent_kind;

  if (sentKind === 'student') {
    return (
      <RedirectLink id={id}>
        <OutlineBox>{attributes.total_students} aluno(s)</OutlineBox>
      </RedirectLink>
    );
  }

  return (
    <RedirectLink id={id}>
      <ClassroomsList classroomsNames={attributes.classroom_names} />
    </RedirectLink>
  );
};

const Situation = ({ attributes, id }) => {
  const mappings = {
    sent: {
      text: 'Enviado',
    },
    pending: {
      text: 'Pendente',
      variation: 'warning',
    },
    rejected: {
      text: 'Não aprovado',
      variation: 'danger',
    },
    canceled: {
      text: 'Cancelado',
      variation: 'dimming',
    },
    deleted: {
      text: 'Deletado',
      variation: 'dimming',
    },
    expired: {
      text: 'Encerrado',
    },
  };

  return (
    <RedirectLink id={id}>
      <OutlineBox variation={mappings[attributes.status].variation}>
        {mappings[attributes.status].text}
      </OutlineBox>
    </RedirectLink>
  );
};

const Payments = ({ attributes, id }) => (
  <RedirectLink id={id}>
    <OutlineBox>
      {`${attributes.total_paid}/${attributes.total_students}`}
    </OutlineBox>
  </RedirectLink>
);

const ExpiresAt = ({ attributes, id }) => (
  <RedirectLink id={id}>{attributes.expires_at}</RedirectLink>
);

const Actions = ({ id }) => (
  <Link to={`/schools/school_products/${id}`}>
    <Button variation="secondary">Visualizar</Button>
  </Link>
);

export default [
  {
    Header: 'Título',
    accessor: Title,
  },
  {
    Header: 'Criação',
    accessor: Created,
  },
  {
    Header: 'Vencimento',
    accessor: ExpiresAt,
  },
  {
    Header: 'Valor',
    accessor: Price,
  },
  {
    Header: 'Destinatários',
    accessor: Recipients,
  },
  {
    Header: 'Situação',
    accessor: Situation,
  },
  {
    Header: 'Pagamentos',
    accessor: Payments,
  },
  {
    Header: 'Ações',
    accessor: Actions,
  },
];
