import React from 'react';
import { Picker } from 'emoji-mart';

import * as S from './styles';
import emoji_translate from 'config/i18n/locales/pt-BR/pt-br.json';

import { SelectorEmotesProps } from './types';

export const SelectorEmotes = ({
  onSelectEmoji,
  disabled,
}: SelectorEmotesProps) => {
  return (
    <S.Wrapper disabled={disabled}>
      <Picker
        onSelect={onSelectEmoji}
        i18n={emoji_translate.messages.omni_channel.messages.emoji_translate}
      />
    </S.Wrapper>
  );
};
