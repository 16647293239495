import React, { CSSProperties } from 'react';

type AttributesType = {
  id: string;
  attributes: {
    name: string;
  };
};

interface ListOptionsProps {
  attributes: Array<AttributesType>;
}

const customStyles: CSSProperties = {
  display: 'inline',
  wordBreak: 'break-all',
};

const ListOptions = ({ attributes }: ListOptionsProps) => {
  return (
    <>
      {attributes?.map(({ attributes, id }) => (
        <div style={customStyles} key={id}>
          {`${attributes.name},  `}
        </div>
      ))}
    </>
  );
};

export default ListOptions;
