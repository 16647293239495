const actions = {
  ERROR: 'ERROR/billingPanel',
  FETCH_BILLING_LIST: 'FETCH_BILLING_LIST/billingPanel',
  FETCH_BILLING_LIST_SUCCESS: 'FETCH_BILLING_LIST_SUCCESS/billingPanel',
  REQUEST_BILLET_PAYMENT: 'REQUEST_BILLET_PAYMENT/billingPanel',
  REQUEST_BILLET_PAYMENT_SUCCESS: 'REQUEST_BILLET_PAYMENT_SUCCESS/billingPanel',

  fetchBillingList: () => ({
    type: actions.FETCH_BILLING_LIST,
  }),

  requestBilletPayment: (signatureId) => ({
    type: actions.REQUEST_BILLET_PAYMENT,
    signatureId,
  }),
};

export default actions;
