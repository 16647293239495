import React from 'react';
import Linkify from 'react-linkify';

import './style.scss';

const TextWithLink = ({ children, target = '_blank' }) => {
  return (
    <Linkify properties={{ target }} className="TextWithLink">
      {children}
    </Linkify>
  );
};

export default TextWithLink;
