import styled, { css } from 'styled-components';
import { ThemeProps } from 'config/themes/types';

export const FieldWrapper = styled.div`
  ${({ theme: { border, colors, space, typography } }: ThemeProps) => css`
    display: flex;
    flex-direction: column;
    height: calc(100% - 140px);
    overflow-y: auto;

    .Accordion {
      display: flex;
      flex-direction: column;
      min-height: 80px;

      .accordion-title {
        ${typography.title.Bold16}
        margin: 0;
      }

      .accordion__button {
        display: flex;
        align-items: center;
        height: 80px;
        width: 100%;
        border: none;
        border-radius: 0;
        padding: ${space.sm} ${space.lg};
        background: ${colors.neutral.white};
        cursor: pointer;

        &[aria-expanded='false'] {
          border-bottom: ${border.style.solid} ${border.width.sm}
            ${colors.neutral.gray5};
        }
      }

      #accordion__heading {
        border: 0;
        border-bottom: 0;
        border-radius: 0;
      }

      .accordion__panel {
        padding: 0 ${space.lg};
        padding-bottom: ${space.lg};
        border-radius: ${border.radius.none};
        border: none;
      }
  `}
`;

export const AccordionWrapper = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: ${space.sm};
  `}
`;
