import React from 'react';
import PropTypes from 'prop-types';

import Loader from 'components/Loader';
import ModalTitle from 'components/Modal/ModalTitle';
import ButtonRow from 'components/ButtonRow';
import RadioButton from 'components/RadioButton';
import UserItem from 'components/UserItem';
import Avatar from 'components/Avatar';

import { ModalWrapperManuallyPaid } from './style';

const ManuallyPaidModal = ({
  showManuallyPaidModal,
  toggleManuallyPaidModal,
  isModalLoading,
  student,
  responsibles,
  onSubmit,
}) => {
  const [responsibleId, setResponsibleId] = React.useState(null);

  const onClick = (selectedResponsibleId) => {
    setResponsibleId(selectedResponsibleId);
  };

  const toggleModal = () => {
    setResponsibleId(null);
    toggleManuallyPaidModal();
  };

  const modalSubmit = () => {
    setResponsibleId(null);
    onSubmit(responsibleId);
  };

  return (
    <div className="ManuallyPaidModal">
      {showManuallyPaidModal && (
        <ModalWrapperManuallyPaid
          isOpen={showManuallyPaidModal}
          toggleModal={toggleModal}
          maxWidth="490px"
        >
          {isModalLoading ? (
            <Loader />
          ) : (
            <>
              <ModalTitle>Registrar pagamento na escola</ModalTitle>
              {student !== undefined && student.attributes && (
                <div className="subTitle">
                  Selecione qual responsável por{' '}
                  <strong>{student.attributes.name}</strong> realizou o
                  pagamento na escola:
                </div>
              )}
              <div className="UserItemWrapper">
                {responsibles &&
                  responsibles.map((responsible) => (
                    <UserItem
                      key={responsible.id}
                      className={
                        responsible.id === responsibleId ? 'selected' : ''
                      }
                      onClick={() => onClick(responsible.id)}
                      userName={responsible.attributes.name}
                      userRole={responsible.attributes.role}
                      highlighted={responsible.id === responsibleId}
                      actionComponent={
                        <RadioButton
                          attributeName="responsibleId"
                          value={responsible.id}
                          checked={responsible.id === responsibleId}
                          simpleInputRadio
                        />
                      }
                      avatarComponent={
                        <Avatar user={responsible} size="small" />
                      }
                    />
                  ))}
              </div>
              <ButtonRow
                buttons={[
                  {
                    text: 'Cancelar',
                    variation: 'secondary',
                    onClick: toggleModal,
                  },
                  {
                    text: 'Registrar pagamento',
                    disabled: responsibleId === null,
                    onClick: modalSubmit,
                  },
                ]}
              />
            </>
          )}
        </ModalWrapperManuallyPaid>
      )}
    </div>
  );
};

ManuallyPaidModal.propTypes = {
  showManuallyPaidModal: PropTypes.bool.isRequired,
  toggleManuallyPaidModal: PropTypes.func.isRequired,
  isModalLoading: PropTypes.bool,
  student: PropTypes.object,
  responsibles: PropTypes.array,
  onSubmit: PropTypes.func,
};

export default ManuallyPaidModal;
