import styled, { css } from 'styled-components';
import { ThemeProps } from 'config/themes/types';

import { Box } from '@agendaedu/ae-web-components';
import Skeleton from 'components/Skeleton';
import ModalContainer from 'components/Modal/ModalContainer';

export const CategoriesListContainer = styled.main`
  ${({ theme: { colors, breakpoints, space, typography } }: ThemeProps) => css`
    padding: ${space.xl} ${space.xl5};

    .EmptyState {
      ${typography.subtitle.Medium16}
      color: ${colors.neutral.black};
    }

    @media screen and (max-width: ${breakpoints.tabletMD}) {
      padding: ${space.xl} ${space.lg};
    }
  `}
`;

export const PageInfo = styled.section`
  ${({ theme: { typography, colors, space } }: ThemeProps) => css`
    .PageTitle {
      color: ${colors.neutral.black};
      margin-top: ${space.sm};
      margin-bottom: ${space.xs4};
      ${typography.headline.H2Bold24}
    }

    a {
      ${typography.subtitle.Medium14}
    }
  `}
`;

export const InfoBox = styled(Box)`
  ${({ theme: { colors, space } }: ThemeProps) => css`
    margin-bottom: ${space.xl2};

    > a {
      &:hover,
      &:active,
      &:visited {
        color: ${colors.neutral.white};

        span {
          background-color: inherit;
          white-space: nowrap;
        }
      }
    }
  `}
`;

export const CardContainer = styled(Box)`
  ${({ theme: { breakpoints } }: ThemeProps) => css`
    @media screen (max-width: ${breakpoints.tabletMD}) {
      width: 70%;
    }
  `}
`;

export const CardLabel = styled.h4`
  ${({ theme: { colors, space, typography } }: ThemeProps) => css`
    margin-bottom: ${space.sm};
    margin-top: ${space.xs4};
    color: ${colors.neutral.gray2};
    ${typography.subtitle.Medium12}
  `}
`;

export const Title = styled.h3`
  ${({ theme: { colors, space, typography } }: ThemeProps) => css`
    width: 100%;
    margin: ${space.xs4};
    color: ${colors.neutral.gray1};
    ${typography.subtitle.Medium16}
    line-height: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `}
`;

const SkeletonModifiers = {
  withSpaceBetween: () => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
  `,
  withFlexGap: (space: string) => css`
    display: flex;
    gap: ${space};
  `,
};

export const SkeletonWrapper = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    width: 100%;
    ${SkeletonModifiers.withFlexGap(space.xl2)};
    flex-direction: column;
  `}
`;

export const ContentSkeleton = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    ${SkeletonModifiers.withFlexGap(space.lg)};
    flex-direction: column;
  `}
`;

export const LabelSkeleton = styled.div`
  ${({ theme: { breakpoints, space } }: ThemeProps) => css`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    #info-skeleton {
      display: flex;
      flex-flow: column wrap;
      gap: ${space.sm};
      align-items: flex-start;
      width: 50%;

      @media screen and (max-width: ${breakpoints.tabletMD}) {
        > svg:last-of-type {
          width: 100% !important;
        }
      }
    }
  `}
`;

export const CardSkeleton = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    svg {
      &:not(:first-child) {
        margin-top: ${space.sm};
      }
    }
  `};
`;

export const SkeletonListItem = styled(Skeleton)`
  ${({ theme: { space, border } }: ThemeProps) => css`
    margin-top: ${space.sm};
    border-radius: ${border.radius.sm};
  `}
`;

export const CategoriesModal = styled(ModalContainer)`
  ${({ theme: { colors, typography, space } }: ThemeProps) => css`
    .double-column {
      width: fit-content;
      float: right;
    }

    p {
      color: ${colors.neutral.gray1};
      ${typography.body.Regular16}
      margin: ${space.xs4};
    }

    .ButtonRow {
      margin-top: ${space.xs4};
    }
  `}
`;
