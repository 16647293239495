import styled, { css } from 'styled-components';

import { ThemeProps } from 'config/themes/types';

export const PageContainer = styled.section<{ theme: ThemeProps }>`
  ${({ theme: { space, breakpoints } }) => css`
    padding: ${space.xl2} ${space.xl5};

    @media screen and (${`max-width: ${breakpoints.mobileSM}`}) {
      padding: ${space.lg};
    }
  `}
`;
