import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import * as S from './styles';
import { GroupHeaderProps } from './types';

import { Box, Button, Text } from '@agendaedu/ae-web-components';
import Tooltip, { LEFT } from 'components/Tooltip';

import withAppContext from 'core/hoc/withAppContext';

const GroupHeader = ({
  appContext: { dataArea, policies, isWebView },
  history,
}: GroupHeaderProps) => {
  const { t } = useTranslation(['messages']);

  const handleoNewGroupForm = useCallback(() => {
    history.push(`/${dataArea}/messages/groups/new`);
  }, [history, dataArea]);

  return (
    <S.GroupHeaderWrapper isWebView={isWebView}>
      {!isWebView && (
        <Text variant="headline-h2-bold-24" color="neutral.black" mb={0}>
          {t('groups.header.module')}
        </Text>
      )}

      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Text variant="title-bold-20" color="neutral.black" mb={0}>
          {t('groups.header.title')}
        </Text>

        {policies?.can_create_group && (
          <Tooltip
            id="new-group-tooltip"
            content={t('groups.header.new_group_button_tip')}
            position={LEFT}
          >
            <Button
              testId="new-group-btn"
              icon="plus"
              variant="secondary"
              onClick={handleoNewGroupForm}
              size="xs"
              isOnlyIcon
            />
          </Tooltip>
        )}
      </Box>
    </S.GroupHeaderWrapper>
  );
};

export default withRouter(withAppContext(GroupHeader));
