import { ThemeProps } from 'config/themes/types';
import { ReactSortable } from 'react-sortablejs';
import styled, { css } from 'styled-components';
import { SortableStyleProps } from './types';

export const SortableWrapper = styled.div`
  ${({ theme: { colors, border, space } }: ThemeProps) => css`
    overflow-y: auto;

    &::-webkit-scrollbar {
      background-color: transparent;
      width: ${space.xs2};
    }

    &::-webkit-scrollbar-button {
      display: none;
    }

    &::-webkit-scrollbar-track {
      background-color: ${colors.neutral.gray5};
    }

    &::-webkit-scrollbar-thumb {
      border-radius: ${border.radius.sm};
      border: ${border.width.sm} ${border.style.solid} ${colors.neutral.gray4};
    }
  `}

  ${({ theme: { colors, border, font } }: ThemeProps) => css`
    & .sortable-drag {
      border: ${border.width.sm} ${border.style.solid}
        ${colors.brand.primary.default} !important;

      #card-label p {
        font-weight: ${font.weight.regular};
      }
      > #actions-wrapper {
        visibility: hidden;
      }
    }
  `}
`;

export const Sortable = styled(ReactSortable)`
  ${({ height }: SortableStyleProps) => css`
    height: ${height - 220}px;
  `}
`;

export const EmptyStateWrapper = styled.div`
  ${({ theme: { colors, space } }: ThemeProps) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: ${space.sm};
    padding: ${space.lg};
    height: calc(100vh - 300px);

    > svg {
      width: 60px;
      height: 60px;

      path {
        fill: ${colors.neutral.gray3} !important;
      }
    }
  `}
`;
