import { MessageProps } from 'store/messages/types';
import { Props } from './types';

export const normalizeGroupMessage = ({ messages, included }: Props) => {
  const messageList: MessageProps[] = [];

  messages?.map((message) => {
    const attached = included?.find(
      (inc) => inc.id === message.relationships?.attached_message?.data?.id
    );

    messageList.push({
      ...message,
      attributes: {
        ...message.attributes,
        has_document: !!message.attributes.document.url,
        attached,
      },
    });
  });

  return messageList;
};
