import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import * as S from './styles';

import withAppContext from 'core/hoc/withAppContext';

import actionMessages from 'store/messages/actions';
import { MessageState } from 'store/messages/types';

import FormFullScreen from 'components/FormFullScreen';
import GroupInformationStep from 'components/Messages/Forms/Steps/GroupInformationStep';
import GroupMembersStep from 'components/Messages/Forms/Steps/GroupMembersStep';
import Toast from 'components/Toast';
import { FormSkeleton } from '../skeleton';

import { NewGroupFormProps } from './types';

const WIZARD_STEPS = [GroupInformationStep, GroupMembersStep];

const NewGroupForm = ({ appContext: { dataArea } }: NewGroupFormProps) => {
  const { t } = useTranslation(['messages']);

  const dispatch = useDispatch();

  const { isLoading, isSending } = useSelector(
    (state: MessageState) => state.messages
  );

  const { createNewGroupRequest } = actionMessages;

  const handlerCreateNewGroup = useCallback(
    (params) => {
      dispatch(createNewGroupRequest(params));
    },
    [dispatch, createNewGroupRequest]
  );

  const handleSubmit = (formContext) => {
    const { form } = formContext;
    handlerCreateNewGroup(form);
  };

  return (
    <S.NewGroupFormsWrapper>
      {!isLoading ? (
        <FormFullScreen
          id="message-group-create"
          form={{
            name: null,
            description: null,
            member_ids: [],
            icon: null,
          }}
          formMeta={{ nextStepDisabled: true }}
          action="new"
          onSubmit={handleSubmit}
          isSubmitting={isSending}
          steps={WIZARD_STEPS}
          backTo={`/${dataArea}/messages/groups`}
          titlePage={t('groups.forms.title_page')}
          titleModal={t('groups.forms.title_modal')}
          contentModal={t('groups.forms.content_modal')}
        />
      ) : (
        <FormSkeleton
          dataArea={dataArea}
          title={t('groups.forms.title_page')}
        />
      )}

      <Toast />
    </S.NewGroupFormsWrapper>
  );
};

export default withAppContext(NewGroupForm);
