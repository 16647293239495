import React from 'react';

import './style.scss';

const ConsumptionBar = ({ list = [], legend}) => {

  const getListItemStyle = item => {
    return { width: `${item.value}%`, backgroundColor: item.color };
  };

  return (
    <div className="consumption-bar-area">
      <div className="progress-bar">
        { list.map((item, index) => (
          <div className="progress-item" key={index} style={getListItemStyle(item)} />
        )) }
      </div>
      { legend }
    </div>
  );
};

export default ConsumptionBar;
