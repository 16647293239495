import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Box, Grid, Select, Text } from '@agendaedu/ae-web-components';

import { HANDOUTS_IMAGES } from 'core/constants';
import {
  RECIPIENTS,
  VIEWS,
  handoutsEngagementGrid,
} from 'core/constants/handouts';

import EmptyState from 'components/EmptyState';
import Loader from 'components/Loader';
import Skeleton from 'components/Skeleton';

import actionHandouts from 'store/handouts/actions';
import { HandoutState } from 'store/handouts/types';

import RecipientCard from '../RecipientCard';

import * as S from './styles';

export const EngagementTab = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['handouts', 'common']);

  const initialPage = 1;

  const { fetchHandoutEngagementRequest, fetchMoreHandoutEngagementRequest } =
    actionHandouts;

  const {
    current,
    engagements: {
      data,
      pagination: { page: currentPage, totalPages },
      filters,
    },
    isEngagementLoading,
    isMoreEngagementLoading,
    meta,
  } = useSelector((state: HandoutState) => state.handouts);

  const { classrooms, persona_type: personaType, status } = current;

  const hasDisabled = status !== 'sent';
  const hasDisabledSelectRecipient = personaType !== 'both';

  const formattedClassrooms = classrooms.map((classroom) => ({
    label: classroom.attributes.name,
    value: classroom.id,
  }));

  formattedClassrooms.unshift({ label: 'Todas as turmas', value: null });

  const handleSubmitFilters = useCallback(
    (params) => {
      dispatch(
        fetchHandoutEngagementRequest({
          current,
          page: initialPage,
          ...params,
        })
      );
    },
    [dispatch, fetchHandoutEngagementRequest, current]
  );

  const fetchMoreEngagements = useCallback(() => {
    dispatch(
      fetchMoreHandoutEngagementRequest({
        ...filters,
        current,
        page: currentPage + 1,
      })
    );
  }, [
    dispatch,
    fetchMoreHandoutEngagementRequest,
    current,
    currentPage,
    filters,
  ]);

  const hasMoreEngagements = useMemo(
    () => currentPage < totalPages,
    [currentPage, totalPages]
  );

  return (
    <>
      <Box py={{ _: 'xl2' }} px={{ _: 'sm', desktopLG: 'xl2' }}>
        <Grid
          gridTemplateColumns="1fr"
          gutter={{ _: 'sm', tabletMD: 'xl', desktopLG: 'xl' }}
        >
          <Grid item>
            <Box display="flex" flexWrap="wrap">
              <S.CardMetrics>
                <S.CardTitle>
                  {t('handout_details.tabs.metrics.total')}
                </S.CardTitle>
                <S.CardContent variant="total">{meta.total}</S.CardContent>
              </S.CardMetrics>

              <S.CardMetrics>
                <S.CardTitle>
                  {t('handout_details.tabs.metrics.views')}
                </S.CardTitle>
                <S.CardContent variant="views">{meta.views}</S.CardContent>
              </S.CardMetrics>

              <S.CardMetrics>
                <S.CardTitle>
                  {t('handout_details.tabs.metrics.confirm')}
                </S.CardTitle>
                <S.CardContent variant="confirm">{meta.confirm}</S.CardContent>
              </S.CardMetrics>

              <S.CardMetrics>
                <S.CardTitle>
                  {t('handout_details.tabs.metrics.pendency')}
                </S.CardTitle>
                <S.CardContent variant="pendency">
                  {meta.pendency}
                </S.CardContent>
              </S.CardMetrics>
            </Box>
          </Grid>

          <Grid item>
            <S.Separator />
          </Grid>

          <Grid
            item
            gutter="lg"
            mt={{
              _: 'xs',
            }}
          >
            <Grid
              item
              gutter="md"
              {...handoutsEngagementGrid.selectsTemplateColumns}
            >
              <Grid item {...handoutsEngagementGrid.selectGridColumn}>
                <Select
                  value={filters.profile}
                  placeholder={t(
                    'handout_details.tabs.filters.recipient_field_placeholder_text'
                  )}
                  options={RECIPIENTS}
                  onChange={({ value }) => {
                    handleSubmitFilters({
                      ...filters,
                      profile: value,
                    });
                  }}
                  fullWidth
                  disabled={hasDisabled || hasDisabledSelectRecipient}
                />
              </Grid>

              <Grid item {...handoutsEngagementGrid.selectGridColumn}>
                <Select
                  value={filters.status || null}
                  placeholder={t(
                    'handout_details.tabs.filters.view_field_placeholder_text'
                  )}
                  options={VIEWS}
                  onChange={({ value }) => {
                    handleSubmitFilters({
                      ...filters,
                      status: value,
                    });
                  }}
                  fullWidth
                  disabled={hasDisabled}
                />
              </Grid>

              <Grid item {...handoutsEngagementGrid.selectGridColumn}>
                <Select
                  value={filters.classroomId || null}
                  placeholder={t(
                    'handout_details.tabs.filters.classroom_field_placeholder_text'
                  )}
                  options={formattedClassrooms}
                  onChange={({ value }) =>
                    handleSubmitFilters({
                      ...filters,
                      classroomId: value,
                    })
                  }
                  fullWidth
                  disabled={hasDisabled}
                />
              </Grid>
            </Grid>

            <Loader isLoading={isEngagementLoading}>
              <Box display="flex" justifyContent="flex-end">
                <Text
                  variant="subtitle-medium-14"
                  color="neutral.gray2"
                  mb="-8px"
                >
                  {t('handout_details.tabs.recipient_text', {
                    count: data.length,
                  })}
                </Text>
              </Box>

              <Box display="flex" alignItems="center" justifyContent="center">
                {data.length > 0 ? (
                  <S.CardList
                    loadMore={!isMoreEngagementLoading && fetchMoreEngagements}
                    hasMore={hasMoreEngagements}
                    loader={
                      <Skeleton
                        width="100%"
                        height={76}
                        primaryColor="#f9f9f9"
                        borderRadius="8px"
                        isFullWidth
                      />
                    }
                    initialLoad={true}
                    threshold={200}
                    pageStart={initialPage}
                  >
                    {data.map((recipient) => (
                      <RecipientCard key={recipient.id} recipient={recipient} />
                    ))}
                  </S.CardList>
                ) : (
                  <S.EmptyStateWrapper>
                    <EmptyState
                      message={t(
                        'handout_details.tabs.empty_state_engagement_text'
                      )}
                      imgUrl={HANDOUTS_IMAGES.no_handouts}
                    />
                  </S.EmptyStateWrapper>
                )}
              </Box>
            </Loader>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
