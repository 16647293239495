const actions = {
  FETCH_ERROR: 'FETCH_ERROR/permissions',

  FETCH_REQUEST: 'FETCH_REQUEST/permissions',
  FETCH_SUCCESS: 'FETCH_SUCCESS/permissions',

  SET_ROLE: 'SET_ROLE/permissions',
  UPDATE_CURRENT_PERMISSION: 'UPDATE_CURRENT_PERMISSION/permissions',

  UPDATE_PERMISSION_REQUEST: 'UPDATE_PERMISSION_REQUEST/permissions',
  UPDATE_PERMISSION_SUCCESS: 'UPDATE_PERMISSION_SUCCESS/permissions',

  fetchRolePermissionsRequest: () => ({
    type: actions.FETCH_REQUEST,
  }),

  setRolePermission: (role) => ({
    type: actions.SET_ROLE,
    role,
  }),

  updateCurrentPermission: (action, permission, isOldForm) => ({
    type: actions.UPDATE_CURRENT_PERMISSION,
    action,
    permission,
    isOldForm,
  }),

  updateRolePermission: (currentRoleSave) => ({
    type: actions.UPDATE_PERMISSION_REQUEST,
    currentRoleSave,
  }),
};

export default actions;
