import React from 'react';
import { Link } from 'react-router-dom';

import { Text } from '@agendaedu/ae-web-components';

import { BreadcrumbProps } from './types';

const Breadcrumb: React.FC<BreadcrumbProps> = ({ title, path }) => {
  return (
    <Link to={path}>
      <Text
        as="a"
        variant="body-bold-14"
        fontFamily="Quicksand"
        fontSize={14}
        fontWeight={700}
        lineHeight="md"
        color="brand.primary.default"
      >
        {title}
      </Text>
    </Link>
  );
};

export default Breadcrumb;
