import { theme, generateBrandColors } from '@agendaedu/ae-web-components';

export default (context) => {
  const brandColors = context?.primaryColor
    ? generateBrandColors(context.primaryColor)
    : theme.colors.brand;

  return {
    primaryColor: context?.primaryColor || '#733dd3',
    secondaryColor: context?.secondaryColor || '#23aa1e',
    gray1: '#999999',
    gray2: '#666666',
    gray3: '#c2c2c2',
    gray4: '#aeb3ba',
    gray5: '#EEEEEE',
    gray6: '#E0E1E7',
    gray11: '#f5f5f5',
    gray12: '#DDDDDD',
    gray14: '#f9f9f9',
    black: '#333333',
    white: '#ffffff',
    info: '#3FACDE',
    success: '#00A99D',
    warning: '#FCC14F',
    danger: '#FF6C60',

    ...theme,
    colors: {
      ...theme.colors,
      brand: brandColors,
    },
  };
};
