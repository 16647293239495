import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Popup from 'reactjs-popup';

import './style.scss';

// TODO: Adjust these constants accordingly to
// https://react-popup.elazizi.com/use-case---tooltip/
export const RIGHT_TOP = 'right top';
export const RIGHT_BOTTOM = 'right bottom';
export const RIGHT_CENTER = 'right center';

export const LEFT = 'left';
export const LEFT_TOP = 'left top';
export const LEFT_BOTTOM = 'left bottom';
export const LEFT_CENTER = 'left center';

export const TOP_CENTER = 'top center';
export const TOP_LEFT = 'top left';
export const TOP_RIGHT = 'top right';

export const BOTTOM_CENTER = 'bottom center';
export const BOTTOM_LEFT = 'bottom left';

class Tooltip extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    content: PropTypes.node.isRequired,
    isOpen: PropTypes.bool,
    tooltipStyle: PropTypes.object,
    position: PropTypes.oneOf([
      RIGHT_TOP,
      RIGHT_BOTTOM,
      RIGHT_CENTER,
      LEFT,
      LEFT_TOP,
      LEFT_BOTTOM,
      LEFT_CENTER,
      BOTTOM_CENTER,
      BOTTOM_LEFT,
      TOP_CENTER,
      TOP_LEFT,
      TOP_RIGHT,
    ]),
    on: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    keepTooltipInside: PropTypes.string,
  };

  static defaultProps = {
    position: TOP_CENTER,
    isOpen: undefined,
    className: '',
    tooltipStyle: {},
    on: ['hover', 'click'],
  };

  contentStyle = {
    borderRadius: '6px',
    width: 'fit-content',
    padding: '10px',
  };

  render() {
    const {
      content,
      children,
      className,
      position,
      tooltipStyle,
      on,
      keepTooltipInside,
      isOpen,
    } = this.props;

    const popupStyle = { ...this.contentStyle, ...tooltipStyle };

    return (
      <div className={`Tooltip ${className}`}>
        <Popup
          trigger={<div className="tooltip-div">{children}</div>}
          position={position}
          on={on}
          contentStyle={popupStyle}
          closeOnDocumentClick
          closeOnEscape
          keepTooltipInside={keepTooltipInside}
          open={isOpen}
        >
          {content}
        </Popup>
      </div>
    );
  }
}

export default Tooltip;
