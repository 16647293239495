import React, { useCallback } from 'react';

import { Button } from '@agendaedu/ae-web-components';
import { useDispatch, useSelector } from 'react-redux';

import OmniChannelActions from 'store/messages/omniChannel/actions';
import { OmniChannelState } from 'store/messages/omniChannel/types';

import * as S from './styles';

import { MessageApproveProps } from './types';

export const MessageApprove = ({
  message,
}: MessageApproveProps): JSX.Element | null => {
  const canApprove =
    message.attributes.canApprove &&
    message.attributes.approvalStatus === 'pending';

  const dispatch = useDispatch();

  const { activeChannel, activeChat } = useSelector(
    (state: OmniChannelState) => state.omniChannel
  );

  const { setApproveMessageRequest } = OmniChannelActions;

  const handleApproveMessage = useCallback(
    (status: 'approve' | 'unapprove') => {
      const params = {
        channelId: activeChannel.id,
        chatId: activeChat.id,
        messageId: message.id,
        approveStatus: status,
      };

      dispatch(setApproveMessageRequest(params));
    },
    [activeChannel, activeChat, message, dispatch, setApproveMessageRequest]
  );

  if (!canApprove) return null;

  return (
    <S.ApproveMessageContentWrapper>
      <Button
        data-testid="approve-button"
        variant="secondary"
        size="xs"
        isOnlyIcon
        icon={'check'}
        onClick={() => handleApproveMessage('approve')}
      />
      <Button
        data-testid="reprove-button"
        variant="secondary"
        size="xs"
        isOnlyIcon
        icon={'multiply'}
        isNegativeAction
        onClick={() => handleApproveMessage('unapprove')}
      />
    </S.ApproveMessageContentWrapper>
  );
};
