import { getCurrentDate } from 'core/utils/date';
import createModuleReducer from '../storeConfig/createModuleReducer';

import actions from './actions';

const normalizeDisciplinesAndClassrooms = (disciplines, classrooms) => {
  disciplines.forEach((discipline) => {
    discipline.value = discipline.id;
    discipline.label = discipline.attributes.name;
  });

  classrooms.forEach((headquarter) =>
    headquarter.options.forEach((classroom) => {
      classroom.value = classroom.id;
      classroom.label = classroom.attributes.name;
    })
  );
};

const normalizeStudentProfiles = (included) =>
  included
    ?.filter((student) => student.type === 'student_profile')
    .map(
      ({
        id,
        type,
        attributes: { name, name_initials, avatar_color, avatar_url },
      }) => ({
        id: id,
        type,
        attributes: {
          name,
          name_initials,
          avatar_color,
          avatar_url,
        },
      })
    );

const normalizeStudentIds = (classroom_with_student_profile_ids) =>
  Object.entries(classroom_with_student_profile_ids || {}).reduce(
    (prev, [classroomId, studentsIds]) => {
      const strIds = studentsIds.map((studentId) => studentId.toString());

      return { ...prev, [classroomId]: strIds };
    },
    []
  );

export const INITIAL_STATE = {
  classrooms: [],
  classroomList: [],
  selectedMonth: new Date().getMonth() + 1,
  selectedYear: new Date().getFullYear(),
  selectedSchoolTerm: null,
  selectedSituation: '',
  selectedDiscipline: '',
  title_cont: '',
  selectedHeadquarter: null,
  selectedEducationalStage: null,
  selectedClassroomFilter: null,
  selectedClassroom: {},
  selectedStartDate: null,
  selectedEndDate: null,
  headquarters: null,
  error: null,
  isLoading: false,
  isSending: false,
  disciplines: [],
  current: {
    discipline_id: null,
    title: '',
    description: '',
    taskhome: '1',
    page_book: '',
    date: getCurrentDate(),
    attachments: [],
    sent_kind: 'classrooms',
    classroom_ids: [],
    classroom_with_student_profile_ids: [],
    persona_type: 'both',
    deadline: getCurrentDate(),
    answer_submit_mode: 'agenda_edu',
  },
  formMeta: {
    classrooms: [],
    canEditClassrooms: true,
    canEditKind: true,
  },
  duplicateActivity: null,
  classroomsPagination: {
    currentPage: 1,
    itemsCountPerPage: 10,
    totalItemsCount: null,
  },
  educationalStages: [],
  classroomFilter: [],
  schoolTerms: [],
  engagementActivity: {
    data: [],
    meta: [],
    pagination: {
      currentPage: 1,
      itemsCountPerPage: 10,
    },
    isLoadingData: false,
  },
  idDuplicateActivity: null,
  flagsActivity: {
    activityDetailsReact: false,
    activityDelivery: false,
  },
  commentsActivity: {
    data: [],
    classrooms: [],
    pagination: {
      currentPage: 1,
      itemsCountPerPage: 20,
    },
    isLoadingData: false,
    totalActiveComments: 0,
  },
  defaultSection: 0,
  isShowScheduleModal: false,
};

export const fetchDisciplines = (state) => {
  return {
    ...state,
    isLoading: true,
    disciplines: [],
  };
};

export const fetchDisciplinesSuccess = (state, action) => {
  const { data } = action;

  const disciplines = data.map((discipline) => {
    discipline.value = discipline.id;
    discipline.label = discipline.attributes.name;

    return discipline;
  });

  return {
    ...state,
    disciplines,
    isLoading: false,
  };
};

export const setClassrooms = (state) => ({
  ...state,
  formMeta: {
    ...state.formMeta,
    classrooms: [],
    select_options: {
      classroom_id: [],
    },
  },
});

export const setClassroomsSuccess = (state, action) => {
  const { data } = action;

  data.forEach((headquarter) =>
    headquarter.options.forEach((classroom) => {
      classroom.value = classroom.id;
      classroom.label = classroom.attributes.name;
    })
  );

  return {
    ...state,
    formMeta: {
      ...state.formMeta,
      classrooms: data,
      select_options: {
        classroom_id: data,
      },
    },
  };
};

export const addSummarydayClassrooms = (state) => ({
  ...state,
  isSending: true,
});

export const addSummarydayClassroomsSuccess = (state) => ({
  ...state,
  isSending: false,
});

export const setSummarydayClassroom = (state) => ({
  ...state,
  isLoading: true,
});

export const setDuplicateActivity = (state) => ({
  ...state,
  isLoading: true,
});

export const setSummarydayClassroomSuccess = (state, action) => {
  const { data, included, disciplines, classrooms } = action;

  normalizeDisciplinesAndClassrooms(disciplines, classrooms);
  const studentProfiles = normalizeStudentProfiles(included);

  const classroom_with_student_profile_ids = normalizeStudentIds(
    data.attributes.classroom_with_student_profile_ids
  );

  return {
    ...state,
    isLoading: false,
    disciplines,
    current: {
      ...data.attributes,
      classroom_with_student_profile_ids,
      taskhome: data.attributes.taskhome ? '1' : '0',
      student_profiles: studentProfiles,
    },
    formMeta: {
      ...state.formMeta,
      classrooms: classrooms,
      select_options: {
        classroom_id: disciplines,
      },
    },
  };
};

export const setDuplicateActivitySuccess = (state, action) => {
  const { data, included, disciplines, classrooms } = action;

  normalizeDisciplinesAndClassrooms(disciplines, classrooms);
  const studentProfiles = normalizeStudentProfiles(included);

  const classroom_with_student_profile_ids = normalizeStudentIds(
    data.attributes.classroom_with_student_profile_ids
  );

  return {
    ...state,
    isLoading: false,
    disciplines,
    duplicateActivity: {
      ...data.attributes,
      classroom_with_student_profile_ids,
      taskhome: data.attributes.taskhome ? '1' : '0',
      student_profiles: studentProfiles,
      date: getCurrentDate(),
    },
    formMeta: {
      ...state.formMeta,
      classrooms: classrooms,
      select_options: {
        classroom_id: disciplines,
      },
    },
  };
};

export const updateSummarydayClassroom = (state) => ({
  ...state,
  isSending: true,
});

export const updateSummarydayClassroomSuccess = (state) => ({
  ...state,
  isSending: false,
});

export const handleError = (state, action) => ({
  ...state,
  //TODO: verificar se a propriedade error é usada, se não, retirar
  error: action.error,
  isLoading: false,
  isSending: false,
});

export const fetchClassroomsRequest = (state, { queryParams: { params } }) => ({
  ...state,
  title_cont: params.title_cont,
  selectedSituation: params.situation,
  selectedEducationalStage: params.educational_stage,
  selectedClassroomFilter: params.classroom,
  selectedHeadquarter: params.headquarter,
  selectedDiscipline: params.discipline,
  selectedSchoolTerm: params.school_term,
  selectedStartDate: params.startDate,
  selectedEndDate: params.endDate,
  classroomsPagination: {
    ...state.classroomsPagination,
    currentPage: params.page,
  },
});

export const fetchClassRoomsSuccess = (state, action) => {
  let updatedClassRoomsList;
  if (action.pagination.currentPage === 1) {
    updatedClassRoomsList = [...action.data];
  } else {
    updatedClassRoomsList = [...state.classrooms, ...action.data];
  }

  return {
    ...state,
    data: action.data,
    classrooms: updatedClassRoomsList,
    classroomList: updatedClassRoomsList,
    headquarters: action.meta.headquarters,
    classroomsPagination: {
      ...state.classroomsPagination,
      currentPage: action.pagination.currentPage,
      totalItemsCount: action.pagination.totalItemsCount,
      itemsCountPerPage: action.pagination.itemsCountPerPage,
    },
    schoolTerms: action.meta.school_terms,
    classroomFilter: action.selectedClassroomFilter
      ? state.classroomFilter
      : [],
    educationalStages: action.selectedEducationalStage
      ? state.educationalStages
      : [],
    isLoading: false,
  };
};

export const fetchEducationalStagesSuccess = (state, action) => ({
  ...state,
  educationalStages: action.educationalStages,
});

export const fetchClassroomFilterSuccess = (state, action) => ({
  ...state,
  classroomFilter: action.classroomFilter,
});

export const resetClassroomsList = (state) => ({ ...state, classroomList: [] });

export const updateLoad = (state, action) => ({
  ...state,
  isLoading: action.isLoading,
});

export const fetchClassroomDetails = (state) => ({
  ...state,
  isLoading: true,
});

export const fetchClassroomDetailsSuccess = (state, action) => ({
  ...state,
  current: INITIAL_STATE.current,
  selectedClassroom: action.data,
  isLoading: false,
});

export const fetchClasroomDelete = (state, action) => ({
  ...state,
  classroom: action.data,
  isLoading: false,
});

export const setApproveClassroom = (state) => ({
  ...state,
  selectedClassroom: {
    ...state.selectedClassroom,
    status: 'sent',
  },
  isLoading: false,
});

export const disapproveClassroom = (state) => ({
  ...state,
  selectedClassroom: {
    ...state.selectedClassroom,
    status: 'rejected',
  },
  isLoading: false,
});

export const setClassroomSchedule = (state) => ({
  ...state,
  selectedClassroom: {
    ...state.selectedClassroom,
    status: 'scheduled',
  },
  isLoading: false,
});

export const setClassroomReschedule = (state) => ({
  ...state,
  selectedClassroom: {
    ...state.selectedClassroom,
    status: 'reschedule',
  },
  isLoading: false,
});

export const fetchActivityEngagement = (state) => ({
  ...state,
  isLoading: true,
});

export const fetchActivityEngagementPagination = (state) => ({
  ...state,
  engagementActivity: {
    ...state.engagementActivity,
    isLoadingData: true,
  },
});

export const fetchActivityEngagementSuccess = (state, action) => {
  let updatedEngagementList;
  if (action.pagination.currentPage === 1) {
    updatedEngagementList = [...action.data];
  } else {
    updatedEngagementList = [...state.engagementActivity.data, ...action.data];
  }

  return {
    ...state,
    engagementActivity: {
      ...state.engagementActivity,
      data: updatedEngagementList,
      meta: action.meta,
      pagination: {
        ...state.engagementActivity.pagination,
        currentPage: action.pagination.currentPage,
      },
      isLoadingData: false,
    },
    isLoading: false,
  };
};

export const fetchActivityComments = (state) => ({
  ...state,
  isLoading: true,
});

export const fetchActivityCommentsPagination = (state) => ({
  ...state,
  commentsActivity: {
    ...state.commentsActivity,
    isLoadingData: true,
  },
});

export const fetchActivityCommentsSuccess = (state, action) => {
  let updatedCommentList;
  if (action.pagination.currentPage === 1) {
    updatedCommentList = [...action.data];
  } else {
    updatedCommentList = [...state.commentsActivity.data, ...action.data];
  }

  return {
    ...state,
    commentsActivity: {
      ...state.commentsActivity,
      data: updatedCommentList,
      totalActiveComments: action.meta.visible_comment_total,
      pagination: {
        ...state.commentsActivity.pagination,
        currentPage: action.pagination.currentPage,
      },
      isLoadingData: false,
    },
    isLoading: false,
  };
};

export const setActivityIdDuplicate = (state, action) => {
  return {
    ...state,
    idDuplicateActivity: action.id,
  };
};

export const setClassroomResend = (state) => ({
  ...state,
  selectedClassroom: {
    ...state.selectedClassroom,
    status: 'sent',
  },
  isLoading: false,
});

export const setClassroomResendRequest = (state) => ({
  ...state,
  isLoading: false,
});

export const setClassroomCancelScheduleRequest = (state) => ({
  ...state,
  isLoading: false,
  isSending: true,
});

export const setClassroomCancelSchedule = (state) => ({
  ...state,
  selectedClassroom: {
    ...state.selectedClassroom,
    status: 'pending',
  },
  isLoading: false,
  isSending: false,
});

export const setFlagsActivity = (state, action) => {
  const newState = {
    tmpKey: action.isActive,
  };

  newState[`${action.typeFlag}`] = newState['tmpKey'];
  delete newState['tmpKey'];

  for (let flagKey in state.flagsActivity) {
    if (action.typeFlag === flagKey) {
      return {
        ...state,
        flagsActivity: {
          ...state.flagsActivity,
          ...newState,
        },
      };
    }
  }

  return {
    ...state,
  };
};

export const fetchCommentSectionsRequest = (state) => ({
  ...state,
  isLoading: true,
});

export const fetchCommentSectionsSuccess = (state, action) => ({
  ...state,
  commentsActivity: {
    ...state.commentsActivity,
    classrooms: action.classrooms,
  },
  isLoading: false,
});

export const fetchTotalCommentsSuccess = (state, action) => ({
  ...state,
  commentsActivity: {
    ...state.commentsActivity,
    totalActiveComments: action.meta.visibleCommentTotal,
  },
  defaultSection: action.data.id,
});

export const addCommentActivity = (state) => ({
  ...state,
  isLoading: false,
});

export const addCommentActivitySuccess = (state, action) => ({
  ...state,
  commentsActivity: {
    ...state.commentsActivity,
    data: [...state.commentsActivity.data, ...action.data],
    totalActiveComments: state.commentsActivity.totalActiveComments + 1,
  },
  isLoading: false,
});

export const setCommentHideRequest = (state) => ({
  ...state,
  isLoading: false,
});

export const setCommentHideSuccess = (state, action) => {
  const updateComment = state.commentsActivity.data.map((comment) =>
    comment.id === action.data.id
      ? {
          ...comment,
          hide: action.data.hide,
        }
      : comment
  );

  return {
    ...state,
    commentsActivity: {
      ...state.commentsActivity,
      data: updateComment,
      totalActiveComments: action.data.hide
        ? state.commentsActivity.totalActiveComments - 1
        : state.commentsActivity.totalActiveComments + 1,
    },
    isLoading: false,
  };
};

const HANDLERS = {
  [actions.FETCH_CLASSROOMS_REQUEST]: fetchClassroomsRequest,
  [actions.FETCH_CLASSROOMS_SUCCESS]: fetchClassRoomsSuccess,
  [actions.FETCH_DISCIPLINES]: fetchDisciplines,
  [actions.FETCH_DISCIPLINES_SUCCESS]: fetchDisciplinesSuccess,
  [actions.SET_CLASSROOMS]: setClassrooms,
  [actions.SET_CLASSROOMS_SUCCESS]: setClassroomsSuccess,
  [actions.ADD_SUMMARYDAY_CLASSROOMS]: addSummarydayClassrooms,
  [actions.ADD_SUMMARYDAY_CLASSROOMS_SUCCESS]: addSummarydayClassroomsSuccess,
  [actions.SET_SUMMARYDAY_CLASSROOM]: setSummarydayClassroom,
  [actions.SET_SUMMARYDAY_CLASSROOM_SUCCESS]: setSummarydayClassroomSuccess,
  [actions.SET_DUPLICATE_ACTIVITY_REQUEST]: setDuplicateActivity,
  [actions.SET_DUPLICATE_ACTIVITY_SUCCESS]: setDuplicateActivitySuccess,
  [actions.UPDATE_SUMMARYDAY_CLASSROOM]: updateSummarydayClassroom,
  [actions.UPDATE_SUMMARYDAY_CLASSROOM_SUCCESS]:
    updateSummarydayClassroomSuccess,
  [actions.RESET_CLASSROOMS_LIST]: resetClassroomsList,
  [actions.FETCH_EDUCATIONAL_STAGES_SUCCESS]: fetchEducationalStagesSuccess,
  [actions.FETCH_CLASSROOM_FILTER_SUCCESS]: fetchClassroomFilterSuccess,
  [actions.FETCH_CLASSROOM_DETAILS_REQUEST]: fetchClassroomDetails,
  [actions.FETCH_CLASSROOM_DETAILS_SUCCESS]: fetchClassroomDetailsSuccess,
  [actions.FETCH_CLASSROOM_DELETE_SUCCESS]: fetchClasroomDelete,
  [actions.DISAPPROVE_CLASSROOM_SUCCESS]: disapproveClassroom,
  [actions.SET_APPROVE_CLASSROOM_SUCCESS]: setApproveClassroom,
  [actions.FETCH_ACTIVITY_ENGAGEMENT_REQUEST]: fetchActivityEngagement,
  [actions.FETCH_ACTIVITY_ENGAGEMENT_SUCCESS]: fetchActivityEngagementSuccess,
  [actions.FETCH_ACTIVITY_ENGAGEMENT_REQUEST_PAGINATION]:
    fetchActivityEngagementPagination,
  [actions.FETCH_ACTIVITY_COMMENTS_REQUEST]: fetchActivityComments,
  [actions.FETCH_ACTIVITY_COMMENTS_SUCCESS]: fetchActivityCommentsSuccess,
  [actions.FETCH_ACTIVITY_COMMENTS_REQUEST_PAGINATION]:
    fetchActivityCommentsPagination,
  [actions.SET_ACTIVITY_ID_DUPLICATE_REQUEST]: setActivityIdDuplicate,
  [actions.SET_CLASSROOM_SCHEDULE_SUCCESS]: setClassroomSchedule,
  [actions.SET_CLASSROOM_SCHEDULE_SUCCESS]: setClassroomReschedule,
  [actions.SET_ACTIVITY_RESEND_SUCCESS]: setClassroomResend,
  [actions.SET_ACTIVITY_RESEND_REQUEST]: setClassroomResendRequest,
  [actions.SET_CLASSROOM_CANCELSCHEDULE_SUCCESS]: setClassroomCancelSchedule,
  [actions.SET_CLASSROOM_CANCELSCHEDULE_REQUEST]:
    setClassroomCancelScheduleRequest,
  [actions.SET_FLAGS_ACTIVITY]: setFlagsActivity,
  [actions.ADD_COMMENT_ACTIVITY_REQUEST]: addCommentActivity,
  [actions.ADD_COMMENT_ACTIVITY_SUCCESS]: addCommentActivitySuccess,
  [actions.SET_COMMENT_HIDE_REQUEST]: setCommentHideRequest,
  [actions.SET_COMMENT_HIDE_SUCCESS]: setCommentHideSuccess,
  [actions.FETCH_COMMENT_SECTIONS_REQUEST]: fetchCommentSectionsRequest,
  [actions.FETCH_COMMENT_SECTIONS_SUCCESS]: fetchCommentSectionsSuccess,
  [actions.FETCH_TOTAL_COMMENTS_SUCCESS]: fetchTotalCommentsSuccess,

  [actions.ERROR]: handleError,
};

const summaryday_classrooms = createModuleReducer(INITIAL_STATE, HANDLERS);

export default summaryday_classrooms;
