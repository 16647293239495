import styled, { css } from 'styled-components';
import colorWithAlpha from 'core/utils/colorWithAlpha';

const CommonDetailHeader = css`
  font-size: 16px;
  font-weight: 500;
`;

export const StyledOption = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.65);
  z-index: 99;
`;

export const Icon = styled.div`
  cursor: pointer;
  text-align: right;
`;

export const ContentWrapper = styled.div`
  overflow: hidden;
`;

export const ListWrapper = styled.div`
  font-family: 'Quicksand';
  width: 100%;
  border-radius: 8px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.14);
  margin-bottom: 10px;
  padding: 16px 16px;
  display: flex;
  justify-content: space-between;
  column-gap: 20px;
  row-gap: 10px;

  &:hover {
    cursor: pointer;
  }

  @media (max-width: 1320px) {
    flex-wrap: wrap;
  }

  ${({ theme }) => css`
    .LabeledContainer {
      margin-bottom: 0px;
      justify-content: center;
      width: auto;

      @media (max-width: 1320px) {
        width: 100px;
      }
      .detail-header {
        font-size: 12px;
        font-weight: 500;
        color: ${theme.gray1};
      }
      .information-content {
        ${CommonDetailHeader}
        color: ${theme.gray2};
      }
      .information-content > .offer-title {
        ${CommonDetailHeader}
        color: ${theme.black};
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 300px;

        @media (max-width: 1320px) {
          max-width: auto;
        }

        @media (max-width: 500px) {
          width: 230px;
        }
        display: block;
        overflow: hidden;
      }
      .date-info {
        width: 100px;
      }
    }
  `}
`;

export const StatusWrapper = styled.div`
  width: 97px;
  ${({ theme }) => css`
    .OutlineBox {
      border: none;
      &.warning {
        font-weight: 700;
        color: ${theme.warning};
        background-color: ${colorWithAlpha(`${theme.warning}`, 0.1)};
      }

      &.danger {
        font-weight: 700;
        color: ${theme.danger};
        background-color: ${colorWithAlpha(`${theme.danger}`, 0.1)};
      }

      &.success {
        font-weight: 700;
        color: ${theme.success};
        background-color: ${colorWithAlpha(`${theme.success}`, 0.1)};
      }

      &.default {
        font-weight: 700;
        color: ${theme.gray1};
        background-color: ${colorWithAlpha(`${theme.gray1}`, 0.1)};
      }

      &.info {
        font-weight: 700;
        color: ${theme.info};
        background-color: ${colorWithAlpha(`${theme.info}`, 0.1)};
      }
    }
  `}
`;

export const OffersQuantity = styled.div`
  margin-top: 31px;
  margin-bottom: 10px;
  p {
    font-family: 'Quicksand';
    font-weight: 500;
    font-size: 14px;
    color: ${(props) => props.theme.gray1};
    text-align: right;
  }
`;

export const LabelContent = styled.span`
  width: 93%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;

  @media (max-width: 1320px) {
    order: 3;
  }

  @media (max-width: 700px) {
    justify-content: flex-start;
  }
`;

export const ShopListContainer = styled.div`
  ${({ theme }) => css`
    .Alert {
      height: 100%;
    }
    .dropdown {
      float: right;
      top: 10px;
      a {
        border: 0 none;
        color: ${theme.gray2};
        i {
          font-size: 24px;
        }
      }
    }
  `}
`;

export const ClassroomContainer = styled.div`
  @media (min-width: 768px) {
    width: 125px;
  }
`;

export const Container = styled.div`
  ${({ theme }) => css`
    position: fixed;
    inset: 60px 0px 40px;
    border: 1px solid rgb(204, 204, 204);
    background: ${theme.white};
    overflow: auto;
    border-radius: 16px;
    outline: none;
    padding: 32px;
    max-width: 490px;
    cursor: default;
    margin-left: auto;
    margin-right: auto;
    height: 375px;
    z-index: 100;
    .Button.btn-danger {
      background-color: ${theme.danger};
      color: ${theme.white};

      &:hover {
        background-color: ${theme.danger}90;
        border-color: ${theme.danger}90;
      }
    }
  `}
`;

export const Input = styled.input`
  ${({ theme }) => css`
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.428571429;
    background-color: ${theme.white};
    background-image: none;
    border: 1px solid #ccc;
    color: #000;
    font-weight: 500;
    border-radius: 4px;
    margin-bottom: 30px;
    font-family: open sans, sans-serif;
    -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    -webkit-transition: border-color ease-in-out 0.15s,
      box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    &:focus {
      border: 1px solid ${theme.primaryColor};
      box-shadow: none;
    }
  `}
`;

export const Title = styled.p`
  ${({ theme }) => css`
    font-size: 24px;
    font-family: Quicksand;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    font-stretch: normal;
    line-height: 1;
    color: ${theme.black};
    text-align: left;
    margin-bottom: 14px;
  `}
`;

export const Label = styled.p`
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  line-height: 24px;
`;

export const NoteCancelField = styled.div`
  display: flex;
  flex-direction: column;
  .Field {
    margin: 0;
    padding: 0;
  }
`;

export const ContentButton = styled.div`
  float: right;
`;

export const WantToCancelField = styled.div`
  margin-bottom: 30px;
  .Field {
    margin: 0;
    padding: 0;
    input {
      height: 40px;
    }
  }
`;

export const LabelCounter = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  display: flex;
  justify-content: end;
  margin-top: -20px;
`;
