import React from 'react';
import { EditRecurrentBillInBatchesModalProps } from './types';
import * as S from './styles';

import withFormContext from 'core/hoc/withFormContext';

import Button from 'components/Button';
import ButtonRow from 'components/ButtonRow';
import ModalTitle from 'components/Modal/ModalTitle';

const EditRecurrentBillInBatchesModal = ({
  isOpen,
  disabled,
  toggleModal,
  handleSubmit,
  formContext: { form },
  titleModal,
  contentModal,
  notActiveUpdateText,
  backButton,
  submitButton,
}: EditRecurrentBillInBatchesModalProps) => {
  const isActiveUpdateInBatches =
    form.toggleBatchDueDate ||
    form.toggleBatchPayment ||
    form.toggleBatchWallet ||
    form.toggleBatchPenality ||
    form.toggleBatchFinancialResponsible ||
    form.toggleBatchDiscount;

  const handleSubmitForm = (): void => {
    toggleModal();
    handleSubmit(form);
  };

  return (
    <S.Wrapper data-testid="edit-recurrent-in-batchs">
      <S.EditRecurrentBillInBatchesWrapperModal
        isOpen={isOpen}
        toggleModal={toggleModal}
        className="EditRecurrentBillInBatchesModal"
        maxWidth="490px"
      >
        <ModalTitle>{titleModal}</ModalTitle>
        <p>{isActiveUpdateInBatches ? contentModal : notActiveUpdateText}</p>
        <ButtonRow>
          <Button
            variation={isActiveUpdateInBatches ? 'secondary' : 'primary'}
            onClick={toggleModal}
          >
            {isActiveUpdateInBatches ? backButton : submitButton}
          </Button>
          {isActiveUpdateInBatches && (
            <Button onClick={handleSubmitForm} disabled={disabled}>
              {submitButton}
            </Button>
          )}
        </ButtonRow>
      </S.EditRecurrentBillInBatchesWrapperModal>
    </S.Wrapper>
  );
};

export default withFormContext(EditRecurrentBillInBatchesModal);
