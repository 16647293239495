import React from 'react';
import { useTranslation } from 'react-i18next';

import Title from 'components/WizardForm/Title';
import Currency from 'components/Currency';

import { SimpleSummaryProps } from './types';

import * as S from './styles';

const SimpleSummary = ({ showTitle, price }: SimpleSummaryProps) => {
  const { t } = useTranslation(['payments']);

  return (
    <S.SimpleSummaryWrapper data-testid="SimpleSummaryWrapper">
      {showTitle && <Title name={t('form_section.simple_summary_title')} />}
      <S.BillAmountWrapper>
        <p>Total da cobrança</p>
        <Currency value={price || 0.0} />
      </S.BillAmountWrapper>
    </S.SimpleSummaryWrapper>
  );
};

export default SimpleSummary;
