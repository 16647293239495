import React from 'react';

import withFormContext, { formPropTypes } from 'core/hoc/withFormContext';
import tabifyForWizard from 'core/hoc/tabifyForWizard';
import { titleMobile } from 'core/utils/changeText';

import Chart from './Chart';
import Button from 'components/Button';
import FormFieldset from 'components/Form/Fieldset';
import * as S from './styles';

import validations, { addValidations, removeValidations } from './validations';

const ChartsTab = ({ formContext }) => {
  const { form, updateAttribute } = formContext;
  const reportCharts = [...form.chart_attributes];
  if (!validations[0]) addValidations(reportCharts[0].key);

  const updateChart = (index, chart) => {
    reportCharts[index] = chart;
    updateAttribute('chart_attributes', reportCharts);
  };

  const addNewChart = () => {
    const newReportCharts = [
      ...reportCharts,
      {
        key: Math.random().toString(36).substring(7),
      },
    ];
    updateAttribute('chart_attributes', newReportCharts);
    addValidations(newReportCharts[newReportCharts.length - 1].key);
  };

  const removeChart = (removeChartKey) => {
    const newReportCharts = reportCharts.filter(
      (chart) => removeChartKey !== chart.key
    );
    updateAttribute('chart_attributes', newReportCharts);
    removeValidations(removeChartKey);
  };
  return (
    <S.ReportFormChartTab>
      <FormFieldset>
        {reportCharts.map((chart, index) => (
          <Chart
            key={chart.key}
            index={index}
            removeChart={removeChart}
            chart={chart}
            totalNumberOfCharts={reportCharts.length}
            updateChart={updateChart}
          />
        ))}
        <S.NewChart>
          <Button onClick={() => addNewChart()} variation="secondary">
            Adicionar gráfico
          </Button>
        </S.NewChart>
        <div className="row">
          <div className="col-xs-12">
            <S.Info>*Campos obrigatórios</S.Info>
          </div>
        </div>
      </FormFieldset>
    </S.ReportFormChartTab>
  );
};

ChartsTab.propTypes = {
  ...formPropTypes,
};

export default tabifyForWizard({
  title: titleMobile('2 Gráficos'),
  subTitle: 'Adicionar Gráficos',
  validations,
})(withFormContext(ChartsTab));
