import Field from 'components/Form/Field';
import styled, { css } from 'styled-components';
import { StyleProps } from './types';
import { ThemeProps } from 'config/themes/types';

export const TextAreaField = styled(Field)<StyleProps>`
  ${({ theme: { colors }, error }) => css`
    padding: 0;

    .ql-container {
      border-color: ${error && colors.context.alert.default};
    }

    .ql-snow {
      border-color: ${error && colors.context.alert.default} !important;
    }
  `}
`;

export const FileFieldWrapper = styled.div`
  ${({ theme: { space, typography } }: ThemeProps) => css`
    a {
      border-radius: ${space.xs2};
      padding: 5px !important;

      span {
        ${typography.button.Bold16}
      }
    }
  `}
`;
