import styled, { css } from 'styled-components';
import PageContainer from 'components/PageContainer';

export const Page = styled(PageContainer)`
  .generic-modal-body {
    font-size: 16px;
    margin: 24px 0 8px;
    line-height: 24px;
  }
`;

export const LoaderContainer = styled.div`
  ${({ top = 0, bottom = 0 }) => css`
    margin: ${top}px 0px ${bottom}px 0px;
  `};
`;
