import styled, { css } from 'styled-components';
import { ThemeProps } from 'config/themes/types';

export const EvacuationWrapper = styled.div`
  ${({ theme: { border, breakpoints, colors, space } }: ThemeProps) => css`
    display: flex;
    flex-direction: column;
    min-width: 520px;
    width: 100%;
    max-width: ${breakpoints.tabletMD};
    gap: ${space.xl};
    padding: ${space.lg};
    border-radius: ${border.radius.md};
    border: ${border.style.solid} ${border.width.sm} ${colors.neutral.gray4};

    @media screen and (max-width: ${breakpoints.tabletMD}) {
      min-width: 300px;
      width: 100%;
    }

    @media screen and (max-width: ${breakpoints.mobileSM}) {
      min-width: 200px;
      width: 100%;
    }
  `}
`;

export const RowWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  label {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

export const ContentBox = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    gap: ${space.xs};
  `}
`;
