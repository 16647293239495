import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';

import Loader from 'components/Loader';
import EditRecurrentPlanInformations from 'components/Payments/Recurrent/EditRecurrentPlan/EditRecurrentPlanInformations';
import EditRecurrentPlanModal from 'components/Payments/Recurrent/EditRecurrentPlan/EditRecurrentPlanModal';
import RecurrentPlanForm from 'components/Payments/Recurrent/RecurrentPlanForm';
import selectOptions from 'components/Payments/Recurrent/RecurrentPlanForm/SelectOptions';
import Toast from 'components/Toast';

import actionRecurrentProducts from 'store/edupay/recurrentProducts/actions';
import actionRecurrentPlans from 'store/edupay/recurrentPlans/actions';
import actionRecurrentForms from 'store/edupay/recurrentForms/actions';

import {
  buildDiscount,
  formattedDiscounts,
  formattedPenality,
} from 'core/utils/edupay/functions';

import { DISCOUNT_KINDS } from 'core/constants/index';

import './style.scss';

const EditRecurrentPlan = ({
  match: {
    params: { id },
  },
}) => {
  const [editModal, setEditModal] = useState(false);

  const { recurrentProducts, isLoading: isLoadingProducts } = useSelector(
    (state) => state.recurrentProducts
  );

  const { current, editing, isLoadingPlan, recipientsWallet, isLoadingWallet } =
    useSelector((state) => state.recurrentPlans);

  const {
    currentUser: {
      attributes: { is_debug_user: isDebugUser },
    },
  } = useSelector((state) => state.root);

  const { formAction } = actionRecurrentForms;
  const { fetchRecurrentProducts } = actionRecurrentProducts;
  const { setPlanRequest, editRecurrentPlan } = actionRecurrentPlans;

  const dispatch = useDispatch();

  const toggleEditModal = () => setEditModal(!editModal);

  const enableFormAction = useCallback(() => {
    dispatch(formAction('edit'));
  }, [formAction, dispatch]);

  const setRecurrentPlan = useCallback(() => {
    dispatch(setPlanRequest(id));
  }, [setPlanRequest, id, dispatch]);

  const getRecurrentProducts = useCallback(() => {
    dispatch(fetchRecurrentProducts({ per_page: 'all' }));
  }, [fetchRecurrentProducts, dispatch]);

  const editRecurrentPlanRequest = useCallback(
    (params) => {
      dispatch(editRecurrentPlan(params));
    },
    [editRecurrentPlan, dispatch]
  );

  const formattedRecurrentItems = (recurrentItems) => {
    const formattedItems = [];

    recurrentItems.forEach((item) => {
      const formattedItem = {
        ...item,
        recurrent_product_id: item.recurrent_product_id,
        price_cents: item.price,
      };

      if (formattedItem.discount_kind === 'percent') {
        formattedItem.discount_percent = formattedItem.discount_value;
      } else {
        formattedItem.discount_price_cents = formattedItem.discount_value;
      }

      formattedItems.push(formattedItem);
    });

    return formattedItems;
  };

  const handleSubmit = (form) => {
    editRecurrentPlanRequest({
      ...form,
      id,
      recurrent_items_attributes: formattedRecurrentItems(form.recurrentItems),
      edupay_penality_attributes: formattedPenality(form),
      edupay_discounts: formattedDiscounts(form),
    });
  };

  const recurrentProductIds = () =>
    recurrentProducts.map((recurrentProduct) => ({
      name: recurrentProduct.attributes.name,
      value: recurrentProduct.id,
    }));

  const extractDiscount = (recurrentPlan) => {
    const discounts = [];
    const { edupay_discounts: edupayDiscounts } = recurrentPlan;

    if (edupayDiscounts) {
      edupayDiscounts.data.map(({ attributes }) => {
        discounts.push({
          kind: attributes.kind,
          discount_value:
            attributes.kind === 'percent'
              ? attributes.discount_percent
              : attributes.discount_price_cents,
          days_before_expire: attributes.days_before_expire,
          key: Math.random().toString(36).substring(7),
        });
      });
    }

    if (!discounts.length) {
      discounts.push(buildDiscount());
    }

    return discounts;
  };

  const extractPenality = (currentBill) => {
    const {
      edupay_penality: { data },
    } = currentBill;

    let penality = {
      fine_percent: 2.0,
      interest_percent: 1.0,
    };

    if (data) {
      penality = data.attributes;
    }

    return penality;
  };

  const formatAttributes = () => ({
    ...current.recurrentPlan,
    first_bill_due_date: moment(
      current.recurrentPlan.first_bill_due_date,
      'DD-MM-YYYY'
    ),
    due_date: current.attributes.due_date,
    allowed_payment_method: current.recurrentPlan.allowed_payment_method,
    recipient_wallet_id: current.attributes.recipient_wallet_id,
    recurrentItems: current.recurrentPlan.recurrent_items.data.map(
      ({ attributes }) => ({
        discount_kind: attributes.discount_kind,
        discount_value:
          attributes.discount_kind === 'percent'
            ? attributes.discount_percent
            : attributes.discount_price_cents,
        recurrent_product_id: parseInt(attributes.product_id, 10),
        price: attributes.price_cents,
        product_name: attributes.product_name,
        status: attributes.product_status,
        key: Math.random().toString(36).substring(7),
      })
    ),
    send_financial_responsible: current.attributes.send_financial_responsible,
    toggle_discount: current.recurrentPlan.has_discount,
    edupay_discounts: extractDiscount(current.recurrentPlan),
    toggle_penality: current.recurrentPlan.has_penality,
    edupay_penality: extractPenality(current.recurrentPlan),
    auto_generate_pix: current.attributes.auto_generate_pix,
    allow_credit_card_discount:
      current?.recurrentPlan?.edupay_discounts?.data[0]?.attributes?.allowed_payment_methods?.includes(
        'credit_card'
      ),
  });

  useEffect(() => {
    enableFormAction();
    setRecurrentPlan(id);
    getRecurrentProducts();
  }, []);

  if (isLoadingPlan || isLoadingProducts || isLoadingWallet) return <Loader />;

  return (
    <div className="EditRecurrentPlan">
      <Loader isLoading={isLoadingPlan}>
        <RecurrentPlanForm
          action="edit"
          form={{ ...formatAttributes() }}
          formMeta={{
            hasCurrentSubscribe: current.attributes.has_current_subscribe,
            billetTax: current.attributes.billet_tax,
            pixTax: current.attributes.pix_tax,
            select_options: {
              ...selectOptions(current.attributes.bill_number),
              recurrent_product_id: recurrentProductIds(),
              recipientWalletOptions: recipientsWallet,
              kind: DISCOUNT_KINDS,
            },
            status: current.recurrentPlan.status,
            form_type: 'recurrent',
          }}
          steps={[EditRecurrentPlanInformations]}
          onSubmit={() => setEditModal(true)}
          backTo={`/schools/recurrent/recurrent_plans/${id}`}
          titlePage="Editar recorrência"
          titleModal="Descartar preenchimento"
          contentModal="A edição de um plano será cancelada e as informações preenchidas serão descartadas. Tem certeza que deseja descartar?"
        >
          <EditRecurrentPlanModal
            showModal={editModal}
            disabled={editing}
            toggleModal={toggleEditModal}
            handleSubmit={handleSubmit}
          />
        </RecurrentPlanForm>
        <Toast />
      </Loader>
    </div>
  );
};

EditRecurrentPlan.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
};

export default EditRecurrentPlan;
