import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';

import composeFunctions from 'core/utils/composeFunctions';
import reduxProvider from 'core/hoc/reduxProvider';
import withAppContext from 'core/hoc/withAppContext';

import Albums from 'pages/albums';
import AlbumForm from 'pages/albums/AlbumForm';
import AlbumDetails from 'pages/albums/AlbumDetails';
import UploadFilesProvider from 'core/contexts/UploadFiles';

import { AlbumsRoutesProps } from './types';

const AlbumsRoutes = ({ appContext: { dataArea } }: AlbumsRoutesProps) => {
  useEffect(() => {
    const mainContent = document.getElementById('main-content');

    if (mainContent) {
      mainContent.style.minHeight = '100vh';
      mainContent.style.backgroundColor = '#ffffff';
    }
  }, []);

  return (
    <div id="AlbumsRoutes">
      <CookiesProvider>
        <Switch>
          <Route exact path={`/${dataArea}/albums`} component={Albums} />
          <Route exact path={`/${dataArea}/albums/new`} component={AlbumForm} />
          <Route
            exact
            path={`/${dataArea}/albums/:id/edit`}
            component={AlbumForm}
          />
          <Route
            exact
            path={`/${dataArea}/albums/:id`}
            component={(props) => (
              <UploadFilesProvider>
                <AlbumDetails {...props} />
              </UploadFilesProvider>
            )}
          />
        </Switch>
      </CookiesProvider>
    </div>
  );
};

export default composeFunctions(withAppContext, reduxProvider)(AlbumsRoutes);
