import styled, { css } from 'styled-components';
import ModalContainer from 'components/Modal/ModalContainer';
import { ThemeProps } from 'config/themes/types';
import Field from 'components/Form/Field';

export const ContainerField = styled.div`
  ${({ theme: { space, border } }: ThemeProps) => css`
    .Field {
      padding: 0px;
      width: 100%;
      margin-bottom: ${space.lg};
    }

    .Field.vertical .Label {
      margin-bottom: ${space.xs2};
    }

    .TextField .form-control {
      border-radius: ${border.radius.md};
    }
  `}
`;
