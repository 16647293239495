import React from 'react';
import PropTypes from 'prop-types';

import Loader from 'components/Loader';

const InformationsTab = ({
  isLoading,
  skeleton,
  tabHeaderElement,
  tabContentElement,
  tabFooterElement,
  id,
}) => {
  return (
    <Loader isLoading={isLoading} customLoadElement={skeleton}>
      <div id={id}>
        {tabHeaderElement}
        {tabContentElement}
        {tabFooterElement}
      </div>
    </Loader>
  );
};

InformationsTab.propTypes = {
  isLoading: PropTypes.bool,
  skeleton: PropTypes.element,
  tabHeaderElement: PropTypes.element,
  tabContentElement: PropTypes.element.isRequired,
  tabFooterElement: PropTypes.element,
  id: PropTypes.string,
};

export default InformationsTab;
