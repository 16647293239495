import styled, { css } from 'styled-components';

export const StudentListContent = styled.div`
  @media (max-width: 1200px) {
    width: 100%;
  }

  .Button {
    font-family: 'Quicksand', sans-serif;
    font-weight: 700;
  }
`;

export const StudentListHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 22px;

  ${({ theme }) => css`
    .btn-default[disabled] {
      background-color: ${theme.white};
      color: ${theme.gray1};
    }
  `};
`;

export const CheckboxGroupContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #e0e1e7;
  border-radius: 8px;
  overflow: hidden;

  .Checkbox .checkmark {
    margin-top: 12px;
  }

  @media (max-width: 600px) {
    flex-direction: column;
    position: relative;
  }
`;

export const CheckboxGroupScroll = styled.div`
  width: 100%;

  @media (max-width: 1200px) {
    max-height: 50vh;
    overflow: scroll;
  }
`;

export const StudentContainer = styled.div`
  ${({ theme, checked }) => css`
    width: 100%;
    align-items: center;
    border-top: 1px solid ${theme.gray6};
    padding: 8px 16px;
    background-color: ${checked ? `${theme.primaryColor}10` : ''};
  `};
`;

export const StudentItem = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
`;

export const StudentInfo = styled.label`
  width: 100%;
  margin-bottom: 0;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  padding: 0 15px;
  white-space: normal;
  color: ${({ checked, theme }) =>
    checked ? theme.primaryColor : theme.black};
`;

export const StudentName = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

export const StyledFlexContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 48px;

  label {
    margin: 0;
  }

  .Avatar.small {
    min-width: 32px;
  }
`;

export const StyledButton = styled.input`
  ${({ theme, disabled }) => css`
    position: absolute;
    opacity: 0;
    visibility: hidden;

    &:checked + ${StyledLabel} {
      &::after {
        padding: 2px;
        background-clip: content-box;
        background-color: ${disabled ? theme.gray1 : theme.primaryColor};
        border-color: ${disabled ? theme.gray1 : theme.primaryColor};
      }
    }
  `}
`;

export const StyledLabel = styled.label`
  ${({ theme, checked, simpleInputRadio }) => css`
    display: flex;
    height: 48px;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    border-radius: 8px;
    font-size: 16px;
    font-family: Roboto;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    margin: 0px;
    color: ${checked ? theme.primaryColor : theme.black};

    ${!simpleInputRadio &&
    css`
      min-width: 230px;
      padding: 8px 16px;
      background-color: ${checked ? `${theme.primaryColor}10` : theme.white};
      border: 1px solid ${checked ? `${theme.primaryColor}20` : theme.gray6}};
    `}

    &::after {
      display: inline-block;
      content: '';
      height: 18px;
      width: 18px;
      color: ${theme.gray1};
      border: 2px solid ${theme.gray1};
      border-radius: 50%;
      margin-left: 5px;
    }

    i {
      margin-right: 13px;
      color: ${checked ? theme.primaryColor : theme.gray2};

      &.medium {
        font-size: 24px;
      }
    }
  `}
`;

export const UpdatedAtInfo = styled.label`
  ${({ theme }) => css`
    color: ${theme.gray1};
    font-weight: 400;
    font-family: 'Roboto';
    font-size: 11px;
  `};
`;
