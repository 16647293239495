import { isFloat } from 'validator';

/**
 * @param {object} :min is the minimum value and :max is the maximum
 * value.
 * @returns {Function} A function that receives the string to be validated.
 */
export default function validateNumericalFloat(constraints) {
  const { min = -Number.MAX_VALUE, max = Number.MAX_VALUE } = constraints || {};

  return str => {
    return isFloat(str.toString(), {
      min,
      max
    });
  };
}
