import React, { createContext, useCallback, useState } from 'react';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';

import { StorageDocument } from 'store/storage/types';
import StorageActions from 'store/storage/actions';

import { ContextParams, Props } from './types';

const initialValuesInfo: StorageDocument['form'] = {
  title: '',
  description: '',
  attachments: null,
  sentKind: 'classrooms',
  personaType: 'both',
  classroomIds: [],
  classroomWithStudentProfileIds: {},
  notify: false,
};

export const StorageDocumentContext = createContext<Props>({
  form: { values: initialValuesInfo },
} as Props);

const StorageDocumentProvider: React.FC<ContextParams> = ({
  children,
  currentStorageId,
  googleClientId,
}) => {
  const dispatch = useDispatch();
  const { toggleShowNotificationModal } = StorageActions;

  const [currentStep, setCurrentStep] = useState(1);

  const { t } = useTranslation('storage');

  const tBase = useCallback(
    (key: string, params?: Record<string, string | number>) =>
      t(`document_form.${key}`, params),
    [t]
  );

  const formValidationSchema = yup.object().shape({
    title: yup
      .string()
      .required(tBase('info_step.required_field_error'))
      .max(100, tBase('info_step.limit_character_error', { count: 100 })),
    description: yup.string().required(tBase('info_step.required_field_error')),
    sentKind: yup.string().required(),
    personaType: yup.string().required(),
    classroomIds: yup
      .array()
      .test(
        'classroomIds-validation',
        'classroomIds-min-error',
        (value, context) => {
          const { sentKind } = context.parent;

          if (sentKind === 'classrooms') {
            return value.length > 0;
          }
          return true;
        }
      ),
    classroomWithStudentProfileIds: yup
      .object()
      .test(
        'classroomWithStudentProfileIds-validation',
        'classroomWithStudentProfileIds-min-error',
        (value, context) => {
          const { sentKind } = context.parent;

          if (sentKind === 'students') {
            return value && Object.keys(value).length > 0;
          }
          return true;
        }
      ),
  });

  const onSubmit = (values: StorageDocument['form']) => {
    dispatch(toggleShowNotificationModal(values));
  };

  const form = useFormik({
    initialValues: initialValuesInfo,
    onSubmit: onSubmit,
    validationSchema: formValidationSchema,
    validateOnChange: true,
    isInitialValid: false,
  });

  const isInfoStepValid = [
    !!form.values.title,
    !form.errors.title,
    !form.errors.description,
  ].every(Boolean);

  return (
    <StorageDocumentContext.Provider
      value={{
        googleClientId,
        currentStorageId,
        form,
        currentStep,
        setCurrentStep,
        isInfoStepValid,
      }}
    >
      {children}
    </StorageDocumentContext.Provider>
  );
};

export default StorageDocumentProvider;
