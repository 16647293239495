import { Text } from '@agendaedu/ae-web-components';
import { ThemeProps } from 'config/themes/types';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    h2 {
      margin: ${space.xs4};
      margin-bottom: ${space.lg};
    }
  `}
`;

export const DetailsWrapper = styled.section`
  ${({ theme: { border, colors, space } }) => css`
    display: flex;
    overflow: auto;
    border-top: ${border.width.sm} ${border.style.solid} ${colors.neutral.gray5};
    padding: ${space.lg};
    gap: ${space.md};
    flex-direction: column;
  `}
`;

export const Label = styled(Text)`
  word-wrap: break-word;
`;
