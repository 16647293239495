import React from 'react';
import { MessagesModalProps } from './types';
import * as S from './styles';

import ButtonRow from 'components/ButtonRow';

const MessagesModal = ({
  dataTestId,
  showModal,
  title,
  description,
  isDisabled = false,
  submitButtonText = 'Confirmar',
  cancelButtonText = 'Cancelar',
  handleSubmit,
  toggleModal,
}: MessagesModalProps) => {
  const MODAL_BUTTONS = [
    {
      text: cancelButtonText,
      variation: 'secondary',
      onClick: toggleModal,
    },
    {
      disabled: isDisabled,
      text: submitButtonText,
      onClick: handleSubmit,
    },
  ];

  return (
    <>
      {showModal && (
        <S.ModalWrapper data-testid={dataTestId}>
          <S.MessagesModal
            isOpen={showModal}
            toggleModal={toggleModal}
            maxWidth="490px"
            title={title}
          >
            <S.Description>{description}</S.Description>
            <ButtonRow buttons={MODAL_BUTTONS} />
          </S.MessagesModal>
        </S.ModalWrapper>
      )}
    </>
  );
};

export default MessagesModal;
