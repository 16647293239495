import {
  all,
  call, put, select, takeLatest
} from 'redux-saga/effects';
import { buildToast, toastCss, ToastTypes } from 'store/middlewares/toast/actions';
import { fetchApi, putApi } from 'core/utils/api';
import actions from './actions';

function* fetchIntegrationFilters(_action) {
  try {
    const location = yield select(state => state.root.location);
    const { data } = yield call(fetchApi, `${location}.json`);

    yield put({ type: actions.FETCH_SUCCESS, data });
  } catch (error) {
    yield put({ type: actions.FETCH_ERROR, error });
  }
}

function* updateIntegrationFilters(action) {
  try {
    const location = yield select(state => state.root.location);
    const { data } = yield call(putApi, `${location}.json`, action.params);
    const { is_trial, school_id, provider, old_partial_path } = data.attributes;

    yield put({
      type: actions.UPDATE_SUCCESS,
      data,
      toast: buildToast(
        'Filtros atualizados com sucesso.',
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });

    const kind_school = is_trial ? 'trials' : 'schools';
    const partial = old_partial_path ? old_partial_path : `integration?provider=${provider}`;
    window.location = `/employees/${kind_school}/${school_id}/tab/${partial}`;
  } catch (error) {
    yield put({
      type: actions.UPDATE_ERROR,
      toast: buildToast('Erro ao atualizar os filtros', ToastTypes.error),
      error,
    });
  }
}

function* integrationSagas() {
  yield all([
    takeLatest(actions.FETCH_REQUEST, fetchIntegrationFilters),
    takeLatest(actions.UPDATE_REQUEST, updateIntegrationFilters),
  ]);
}

export default integrationSagas;
