import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;

  .wallet-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    white-space: pre-wrap;

    .Tooltip {
      margin-bottom: 4px;
      font-size: 12px;
    }
  }

  > .Field {
    padding: 0;

    .wallet_configuration__option,
    .wallet_configuration__single-value {
      white-space: pre-wrap;
    }
  }
`;

