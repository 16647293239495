import styled, { css } from 'styled-components';
import colorWithAlpha from 'core/utils/colorWithAlpha';

export const Container = styled.div`
  width: 100%;
  max-width: 400px;
`;

export const CardHeader = styled.div`
  padding: 16px 0;
  display: flex;
  align-items: center;
`;

export const CardHeaderInfo = styled.div`
  padding: 0 24px;
`;

export const CardHeaderImage = styled.div`
  width: 100%;
  img {
    position: relative;
    right: 35px;
    width: 235px;
    height: 145px;
    object-position: center;
    -o-object-position: center;
    object-fit: contain;
    -o-object-fit: contain;
  }
`;

export const CardHeaderTitle = styled.h4`
  ${({ theme }) => css`
    margin: 0;
    width: 170px;
    font-family: 'Quicksand', sans-serif;
    font-weight: 700;
    font-size: 20px;
    line-height: 170%;
    color: ${theme.white};
  `}
`;

export const CardHeaderText = styled.small`
  ${({ theme }) => css`
    font-family: 'Quicksand', sans-serif;
    font-weight: 500;
    font-size: 12px;
    line-height: 170%;
    color: ${theme.white};
  `}
`;

export const CardBody = styled.div`
  ${({ theme }) => css`
    position: relative;
    width: 100%;
    height: 385px;
    padding: 32px;
    border-radius: 0 0 4px 4px;
    background-color: ${theme.white};
  `}
`;

export const CardWrapper = styled.div`
  ${({ theme }) => css`
    position: absolute;
    top: -30px;
    width: 100%;
    max-width: 335px;
    background-color: ${theme.white};
    border-radius: 8px;
  `}
`;

export const CardList = styled.div`
  width: 100%;
  display: inline-block;
`;

export const CardListItem = styled.a`
  ${({ theme }) => css`
    display: block;
    width: 100%;
    padding: 16px;
    background-color: ${theme.white};
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.14);
    border-radius: 8px;
    cursor: pointer;

    &:not(:first-child) {
      margin: 8px 0;
    }

    &:hover {
      background-color: ${colorWithAlpha(theme.primaryColor, 0.1)};
    }
  `}
`;

export const CardListItemInfo = styled.div`
  display: flex;
  align-items: center;
`;

export const IconBadge = styled.span`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    height: 40px;
    color: ${theme.primaryColor};
    background-color: ${colorWithAlpha(theme.primaryColor, 0.1)};
    border-radius: 8px;
    margin-right: 12px;
  `}
`;

export const CardListItemTitle = styled.h6`
  ${({ theme }) => css`
    margin: 4px 0;
    font-family: 'Quicksand', sans-serif;
    font-weight: 700;
    font-size: 14px;
    line-height: 100%;
    color: ${theme.black};
  `}
`;

export const CardListItemDescription = styled.span`
  ${({ theme }) => css`
    margin: 4px 0;
    font-family: 'Quicksand', sans-serif;
    font-weight: 500;
    font-size: 12px;
    line-height: 100%;
    color: ${theme.gray1};
  `}
`;

export const LinkList = styled.ul`
  position: relative;
  margin: 24px 0 0 0;
  list-style: none;
  padding: 0;
`;

export const LinkListItem = styled.a`
  ${({ theme }) => css`
    display: block;
    text-align: center;
    margin: 8px 0;
    padding: 6px;
    border-radius: 4px;
    font-family: 'Quicksand', sans-serif;
    font-weight: 500;
    font-size: 12px;
    line-height: 100%;
    color: ${theme.primaryColor};
    background-color: ${colorWithAlpha(theme.primaryColor, 0.1)};

    &:hover {
      background-color: ${colorWithAlpha(theme.primaryColor, 0.2)};
    }
  `}
`;
