import styled from 'styled-components';

export const Wrapper = styled.div<{ canShow: boolean }>`
  display: ${({ canShow }) => (canShow ? 'block' : 'none')};

  .Form {
    .Field {
      padding: 0;
      margin: 0;
    }
  }
`;
