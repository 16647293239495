import styled, { css } from 'styled-components';
import { ThemeProps } from 'config/themes/types';
import colorWithAlpha from 'core/utils/colorWithAlpha';

export const MealWrapper = styled.div`
  ${({ theme: { border, breakpoints, colors, space } }: ThemeProps) => css`
    display: flex;
    flex-direction: column;
    min-width: 520px;
    width: 100%;
    max-width: ${breakpoints.tabletMD};
    gap: ${space.xl};
    padding: ${space.lg};
    border-radius: ${border.radius.md};
    border: ${border.style.solid} ${border.width.sm} ${colors.neutral.gray4};

    @media screen and (max-width: ${breakpoints.tabletMD}) {
      min-width: 300px;
      width: 100%;
    }

    @media screen and (max-width: ${breakpoints.mobileSM}) {
      min-width: 200px;
      width: 100%;
    }
  `}
`;

export const RowWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  label {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

export const ContentBox = styled.div`
  ${({ theme: { space, colors } }: ThemeProps) => css`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: ${space.xs};
    background-color: ${colors.neutral.white};
  `}
`;

export const SuggestionsGroup = styled.div`
  ${({ theme }: ThemeProps) => css`
    position: absolute;
    width: 100%;
    border: solid 1px ${theme.primaryColor};
    border-top: none;
    border-radius: 0 0 6px 6px;
    margin-top: -${theme.space.xs};
    padding-top: ${theme.space.md};
    background-color: ${theme.colors.neutral.white};
    z-index: 9999;

    .option :hover {
      color: ${theme.primaryColor};
      background-color: ${colorWithAlpha(`${theme.primaryColor}`, 0.1)};
    }
  `}
`;
