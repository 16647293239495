import { Select } from '@agendaedu/ae-web-components';
import { ThemeProps } from 'config/themes/types';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme: { breakpoints } }: ThemeProps) => css`
    display: flex;
    flex-direction: column;

    @media screen and (min-width: ${breakpoints.mobileSM}) {
      min-width: 400px;
    }
  `}
`;

export const SelectChannel = styled(Select)`
  & > ul {
    max-height: 150px;
  }
`;
