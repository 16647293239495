import styled, { css } from 'styled-components';

import colorWithAlpha from 'core/utils/colorWithAlpha';

export const Wrapper = styled.div`
  ${({ theme, isActive }) => css`
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    width: fit-content;
    margin-left: auto;
    order: 4;

    @media (max-width: 833px) {
      order: 2;
      max-width: 24px;
    }

    > .Button {
      position: relative;
      display: inline-flex;
      align-items: center;
      justify-content: space-around;
      font-family: 'Quicksand', sans-serif;
      font-size: 16px;
      font-weight: 700;
      border-radius: 8px;

      ${isActive &&
      css`
        border-color: ${colorWithAlpha(`${theme.primaryColor}`, 0.1)};
        background-color: ${colorWithAlpha(`${theme.primaryColor}`, 0.1)};

        .AgendaIcon {
          color: ${theme.primaryColor};
        }
      `}

      @media(max-width: 833px) {
        > span {
          display: none;
        }
      }
    }
  `}
`;

export const BadgeFilter = styled.span`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 24px;
    height: 24px;
    top: -10px;
    right: -10px;
    border-radius: 50%;
    font-family: 'Quicksand';
    font-size: 12px;
    font-weight: bold;
    color: ${theme.white};
    background: ${theme.primaryColor};
  `}
`;
