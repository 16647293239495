import { formatDate } from 'core/helper/message/omni/formatDate';

import { UnreadChat } from 'store/messages/omniChannel/types';
import { ChatsUnread, MessageIncluded, UserIncluded } from './types';

export const normalizeCountUnreadParams = (
  chats: ChatsUnread,
  included: (MessageIncluded | UserIncluded)[]
): UnreadChat[] => {
  return chats?.map((unreadChat) => {
    const messageIncluded = included?.find(
      (unreadMessage) =>
        unreadChat.relationships.lastMessage?.data.id === unreadMessage.id
    ) as MessageIncluded;

    const userIncluded = included?.find(
      (unreadUser) =>
        messageIncluded.relationships.sender.data.id === unreadUser.id &&
        (unreadUser.type === 'studentProfile' ||
          unreadUser.type === 'responsibleProfile')
    ) as UserIncluded;

    return {
      chatId: unreadChat.id,
      channelId: unreadChat.attributes.channelId.toString(),
      kind: unreadChat.attributes.kind,
      type: unreadChat.type,
      avatarColor: userIncluded.attributes.avatarColor,
      avatarUrl: userIncluded.attributes.avatarUrl,
      lastMessagePreview: messageIncluded.attributes.message,
      lastMessageSentAt: formatDate(messageIncluded.attributes.sentAt),
      name: userIncluded.attributes.name,
      nameInitials: userIncluded.attributes.nameInitials,
      readStatus: messageIncluded.attributes.status,
      roleMessage: userIncluded.attributes.role,
    };
  });
};
