import { Chat, Message } from 'store/messages/omniChannel/types';
import { normalizeDeletedMessageProps } from './types';

export const normalizeDeletedMessage = ({
  deletedMessage,
  messages,
  chats,
  activeChat,
}: normalizeDeletedMessageProps) => {
  let newChats = null;
  let newMessages = null;

  newMessages = messages.map((message: Message) => {
    if (deletedMessage.id === message.id) {
      return deletedMessage;
    }
    return message;
  });

  const isLastChatMessage = messages[0].id === deletedMessage.id;

  if (isLastChatMessage) {
    newChats = chats.map((chat: Chat) => {
      if (chat.id === activeChat.id) {
        return {
          ...chat,
          attributes: {
            ...chat.attributes,
            last_message_preview: deletedMessage.attributes.text,
            last_message_sent_at: deletedMessage.attributes.sentAt,
            read_status: deletedMessage.attributes.readStatus,
          },
        };
      }

      return chat;
    });
  } else {
    newChats = chats;
  }

  return { chats: newChats, messages: newMessages };
};
