import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const EnrollmentClickShowModal = ({ actions, children }) => (
  <div className="click-show-modal" onClick={() => actions.show()}>
    {children}
  </div>
);

EnrollmentClickShowModal.propTypes = {
  actions: PropTypes.shape({
    show: PropTypes.func,
  }).isRequired,
  children: PropTypes.node.isRequired,
};

export default EnrollmentClickShowModal;
