import React, { useEffect, useCallback, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import PermissionsForm from 'pages/permissions/form';
import PageContainer from 'components/PageContainer';
import Loader from 'components/Loader/index';
import Toast from 'components/Toast';
import PermissionsActions from 'store/permissions/actions';
import AcceptModal from './components/Modal';
import { Box, Text } from '@agendaedu/ae-web-components';
import { useTranslation } from 'react-i18next';
import { trackEvent } from 'config/amplitude';

function PagePermissions() {
  const dispatch = useDispatch();

  const { t } = useTranslation(['role_permissions']);

  const [isSaveModal, setIsSaveModal] = useState({
    status: false,
    origin: '',
  });

  const {
    fetchRolePermissionsRequest,
    setRolePermission,
    updateRolePermission,
    updateCurrentPermission,
  } = PermissionsActions;

  const { usersSelect, loading, currentRole, hasChangedForm } = useSelector(
    (state) => state.permissions
  );

  useEffect(() => {
    dispatch(fetchRolePermissionsRequest());
  }, [dispatch, fetchRolePermissionsRequest]);

  const onSubmit = useCallback(() => {
    setIsSaveModal({
      status: true,
      origin: 'button_save',
    });
  }, [currentRole, dispatch, updateRolePermission]);

  const handleToggleSwitch = useCallback(
    (key, permission, isOldForm) => {
      dispatch(updateCurrentPermission(key, permission, isOldForm));
    },
    [dispatch, updateCurrentPermission]
  );

  const selectRoleProfId = useCallback(
    (id) => {
      dispatch(setRolePermission(id));
    },
    [dispatch, setRolePermission]
  );

  return (
    <>
      <Loader isLoading={loading}>
        <PageContainer title="Perfis e permissões">
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            width="100%"
          >
            <Box width="100%" maxWidth={1800} flexDirection="column">
              <PermissionsForm
                initialValues={currentRole}
                onSubmit={onSubmit}
                handleToggleSwitch={handleToggleSwitch}
                selectRoleProfId={selectRoleProfId}
                usersSelect={usersSelect}
                hasChangedForm={hasChangedForm}
              />
            </Box>
          </Box>
        </PageContainer>
        <Toast />

        <AcceptModal
          isModalOpen={isSaveModal.status}
          origin={isSaveModal.origin}
          actionModal={() => {
            trackEvent(`role_permissions_save_modal_save`);
            dispatch(updateRolePermission(currentRole));
            setIsSaveModal((prevState) => ({
              ...prevState,
              status: false,
              origin: '',
            }));
          }}
          closeModal={() => {
            trackEvent(`role_permissions_save_modal_cancel`);
            setIsSaveModal((prevState) => ({
              ...prevState,
              status: false,
              origin: '',
            }));
          }}
          titleModal={t('modal_save.title')}
          textCloseButton={t('modal_save.button_close')}
          textBackButton={t('modal_save.button_back')}
        >
          <Text
            p={0}
            as="p"
            variant="body-regular-14"
            textAlign="justify"
            color="neutral.black"
            lineHeight="lg"
            fontWeight={500}
            mb={24}
          >
            {t('modal_save.content')}
          </Text>
        </AcceptModal>
      </Loader>
    </>
  );
}

const mapStateToProps = (state, _ownProps) => ({});

export default connect(mapStateToProps)(PagePermissions);
