import React from 'react';

import { TextProps } from './types';
import * as S from './styles';

const Text = ({
  children,
  element,
  weight,
  size,
  color,
  ...attrs
}: TextProps) => (
  <S.Container
    as={element}
    weight={weight}
    size={size}
    color={color}
    {...attrs}
  >
    {children}
  </S.Container>
);

export default Text;
