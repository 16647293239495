import { ThemeProps } from 'config/themes/types';
import styled, { css } from 'styled-components';

export const InforContainer = styled.div`
  ${({ theme }: ThemeProps) => css`
    margin-bottom: ${theme.space.sm};

    > .Alert {
      height: max-content;
    }
  `}
`;
