import React from 'react';
import { Box, Text, Button } from '@agendaedu/ae-web-components';

import { ModalProps } from './types';
import * as S from './styles';

const AcceptModal: React.FC<ModalProps> = ({
  closeModal,
  isModalOpen,
  titleModal,
  textCloseButton,
  children,
}) => {
  const MODAL_CENTERED = {
    top: '50%',
    left: '50%',
    right: '50%',
    padding: '24px',
    transform: 'translate(-50%, -50%)',
  };

  const modalTitle = () => (
    <Box margin="0 auto">
      <Text
        as="h4"
        m={0}
        variant="title-bold-20"
        textAlign="center"
        fontWeight={700}
        lineHeight="lg"
      >
        {titleModal}
      </Text>
    </Box>
  );

  return (
    <S.ModalWrapper
      isOpen={isModalOpen}
      toggleModal={closeModal}
      maxWidth="600px"
      minHeight="253px"
      contentStyles={MODAL_CENTERED}
      title={modalTitle()}
    >
      <Box
        display="flex"
        height="content-fit"
        flexDirection="column"
        justifyContent="space-between"
        flexWrap="wrap"
      >
        <Box mb={16} mt={16}>
          {children}
        </Box>

        <Box display="flex" justifyContent="flex-end" flexDirection="row">
          <Button variant="secondary" onClick={closeModal}>
            {textCloseButton}
          </Button>
        </Box>
      </Box>
    </S.ModalWrapper>
  );
};

export default AcceptModal;
