import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Alert, Avatar, Button, Text } from '@agendaedu/ae-web-components';
import { Channel, OmniChannelState } from 'store/messages/omniChannel/types';
import omniChannelActions from 'store/messages/omniChannel/actions';

import * as S from './styles';

import { Modal } from 'components/Handouts/Modal';
import Checkbox from 'components/Form/Checkbox';

export const RedirectMessageModal = (): JSX.Element => {
  const dispatch = useDispatch();

  const { t } = useTranslation(['messages']);

  const [selectedChannel, setSelectedChannel] = useState<Channel | null>(null);

  const {
    showRedirectMessageModal,
    currentMessage,
    activeChat,
    activeChannel,
    channelsStudentProfile,
  } = useSelector((state: OmniChannelState) => state.omniChannel);

  const { toggleRedirectMessageModal, setRedirectMessageRequest } =
    omniChannelActions;

  const handleToggleRedirectMessageModal = useCallback(() => {
    dispatch(toggleRedirectMessageModal());
  }, [dispatch, toggleRedirectMessageModal]);

  const handleChangeSelectedChannel = useCallback(
    (channel: Channel) => {
      setSelectedChannel(selectedChannel?.id ? null : channel);
    },
    [selectedChannel]
  );

  const handleRedirectMessage = useCallback(() => {
    const params = {
      channelId: activeChannel.id,
      messageId: currentMessage.id,
      chatId: activeChat.id,
      targetChannelId: selectedChannel.id,
      targetChannelName: selectedChannel.attributes.name,
    };
    dispatch(setRedirectMessageRequest(params));
  }, [
    activeChannel,
    activeChat,
    currentMessage,
    dispatch,
    selectedChannel,
    setRedirectMessageRequest,
  ]);

  useEffect(() => {
    setSelectedChannel(null);
  }, [showRedirectMessageModal]);

  return (
    <Modal.Root
      isOpen={showRedirectMessageModal}
      title={t('omni_channel.modals.redirect_message.title')}
      handleToggleModal={handleToggleRedirectMessageModal}
    >
      <Modal.Content>
        <S.Wrapper>
          <S.AlertWrapper>
            <Alert variant="informative">
              {t('omni_channel.modals.redirect_message.alert_message', {
                userName: currentMessage?.attributes.user?.attributes.name,
              })}
            </Alert>
          </S.AlertWrapper>

          <Text variant="title-bold-16" color={'neutral.black'}>
            {t('omni_channel.modals.redirect_message.other_channels_label')}
          </Text>
          {channelsStudentProfile && (
            <S.ChannelsListWrapper>
              {channelsStudentProfile
                .filter((channel) => channel.id !== activeChannel.id)
                .map((channel) => (
                  <S.ChannelWrapper key={channel.id}>
                    <S.ChannelItemWrapper
                      isSelected={selectedChannel?.id === channel.id}
                    >
                      <Checkbox
                        disabled={
                          !!selectedChannel && selectedChannel.id !== channel.id
                        }
                        checked={selectedChannel?.id === channel.id}
                        onChange={() => handleChangeSelectedChannel(channel)}
                      />
                      <Avatar
                        size="xs"
                        avatarUrl={channel.attributes.icon_url}
                      />
                      <Text variant="body-regular-14" color={'neutral.gray1'}>
                        {channel.attributes.name}
                      </Text>
                    </S.ChannelItemWrapper>
                  </S.ChannelWrapper>
                ))}
            </S.ChannelsListWrapper>
          )}
        </S.Wrapper>
      </Modal.Content>
      <Modal.Actions>
        <S.ButtonWrapper>
          <Button
            maxWidth={'max-content'}
            disabled={!selectedChannel}
            onClick={handleRedirectMessage}
          >
            {t('omni_channel.modals.redirect_message.submit_modal_text')}
          </Button>
        </S.ButtonWrapper>
      </Modal.Actions>
    </Modal.Root>
  );
};
