import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/Button';
import * as S from './style';

export default class Tab extends React.Component {
  render() {
    const { step, CurrentStep, title, goToStep, index, isActive } = this.props;
    return (
      <S.Tab className="Tab" clickable={step !== CurrentStep}>
        {title && (
          <Button
            size="btn-lg"
            disabled={!isActive}
            onClick={() => goToStep(index)}
          >
            {title}
          </Button>
        )}
      </S.Tab>
    );
  }
}

Tab.propTypes = {
  step: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  CurrentStep: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  title: PropTypes.string,
  goToStep: PropTypes.func,
  index: PropTypes.number,
  isActive: PropTypes.bool,
};
