import { MessageActionProps } from './types';

const actions: MessageActionProps = {
  ERROR: 'ERROR/messages',
  CLEAR_CURRENT_GROUP: 'CLEAR_CURRENT_GROUP/messages',
  CLOSE_EDIT_MESSAGE: 'CLOSE_EDIT_MESSAGE/messages',
  CREATE_NEW_GROUP_REQUEST: 'CREATE_NEW_GROUP_REQUEST/messages',
  CREATE_NEW_GROUP_SUCCESS: 'CREATE_NEW_GROUP_SUCCESS/messages',
  CREATE_NEW_MESSAGE_REQUEST: 'CREATE_NEW_MESSAGE_REQUEST/messages',
  CREATE_NEW_MESSAGE_SUCCESS: 'CREATE_NEW_MESSAGE_SUCCESS/messages',
  FETCH_GROUP_DETAILS_REQUEST: 'FETCH_GROUP_DETAILS_REQUEST/messages',
  FETCH_GROUP_DETAILS_SUCCESS: 'FETCH_GROUP_DETAILS_SUCCESS/messages',
  FETCH_GROUPS_REQUEST: 'FETCH_GROUPS_REQUEST/messages',
  FETCH_GROUPS_SUCCESS: 'FETCH_GROUPS_SUCCESS/messages',
  FETCH_GROUP_MEMBERS_REQUEST: 'FETCH_GROUP_MEMBERS_REQUEST/messages',
  FETCH_GROUP_MEMBERS_SUCCESS: 'FETCH_GROUP_MEMBERS_SUCCESS/messages',
  FETCH_MESSAGES_REQUEST: 'FETCH_MESSAGES_REQUEST/messages',
  FETCH_MESSAGES_SUCCESS: 'FETCH_MESSAGES_SUCCESS/messages',
  FETCH_LAST_MESSAGE_REQUEST: 'FETCH_LAST_MESSAGE_REQUEST/messages',
  FETCH_LAST_MESSAGE_SUCCESS: 'FETCH_LAST_MESSAGE_SUCCESS/messages',
  FETCH_REAL_TIME_MESSAGE_REQUEST: 'FETCH_REAL_TIME_MESSAGE_REQUEST/messages',
  FETCH_REAL_TIME_MESSAGE_SUCCESS: 'FETCH_REAL_TIME_MESSAGE_SUCCESS/messages',
  FETCH_SCHOOL_USERS_REQUEST: 'FETCH_SCHOOL_USERS_REQUEST/messages',
  FETCH_SCHOOL_USERS_SUCCESS: 'FETCH_SCHOOL_USERS_SUCCESS/messages',
  SET_ARCHIVE_GROUP_REQUEST: 'SET_ARCHIVE_GROUP_REQUEST/messages',
  SET_ARCHIVE_GROUP_SUCCESS: 'SET_ARCHIVE_GROUP_SUCCESS/messages',
  SET_CURRENT_GROUP: 'SET_CURRENT_GROUP/messages',
  SET_CHANGE_FOOTER_TOAST: 'SET_CHANGE_FOOTER_TOAST/messages',
  SET_DELETE_GROUP_REQUEST: 'SET_DELETE_GROUP_REQUEST/messages',
  SET_DELETE_GROUP_SUCCESS: 'SET_DELETE_GROUP_SUCCESS/messages',
  SET_DELETE_MESSAGE_REQUEST: 'SET_DELETE_MESSAGE_REQUEST/messages',
  SET_DELETE_MESSAGE_SUCCESS: 'SET_DELETE_MESSAGE_SUCCESS/messages',
  SET_EDIT_GROUP_REQUEST: 'SET_EDIT_GROUP_REQUEST/messages',
  SET_EDIT_GROUP_SUCCESS: 'SET_EDIT_GROUP_SUCCESS/messages',
  SET_EDIT_MESSAGE: 'SET_EDIT_MESSAGE/messages',
  SET_CURRENT_MESSAGE: 'SET_CURRENT_MESSAGE/messages',
  SET_CURRENT_GROUP_REQUEST: 'SET_CURRENT_GROUP_REQUEST/messages',
  SET_CURRENT_GROUP_SUCCESS: 'SET_CURRENT_GROUP_SUCCESS/messages',
  SET_LEAVE_GROUP_REQUEST: 'SET_LEAVE_GROUP_REQUEST/messages',
  SET_LEAVE_GROUP_SUCCESS: 'SET_LEAVE_GROUP_SUCCESS/messages',
  SET_READ_CURRENT_MESSAGES_REQUEST:
    'SET_READ_CURRENT_MESSAGES_REQUEST/messages',
  SET_READ_CURRENT_MESSAGES_SUCCESS:
    'SET_READ_CURRENT_MESSAGES_SUCCESS/messages',
  SET_MUTE_AND_TURN_ON_NOTIFICATION_REQUEST:
    'SET_MUTE_AND_TURN_ON_NOTIFICATION_REQUEST/messages',
  SET_MUTE_AND_TURN_ON_NOTIFICATION_SUCCESS:
    'SET_MUTE_AND_TURN_ON_NOTIFICATION_SUCCESS/messages',
  SOCKET_CONNECT_SERVER_REQUEST: 'SOCKET_CONNECT_SERVER_REQUEST/messages',
  SOCKET_CONNECT_SERVER_SUCCESS: 'SOCKET_CONNECT_SERVER_SUCCESS/messages',
  SOCKET_ENTER_GROUP_ROOM_REQUEST: 'SOCKET_ENTER_GROUP_ROOM_REQUEST/messages',
  SOCKET_LEAVE_GROUP_ROOM_REQUEST: 'SOCKET_LEAVE_GROUP_ROOM_REQUEST/messages',
  TOGGLE_DELETE_MESSAGE_MODAL: 'TOGGLE_DELETE_MESSAGE_MODAL/messages',
  TOGGLE_DELETE_GROUP_MODAL: 'TOGGLE_DELETE_GROUP_MODAL/messages',
  TOGGLE_DETAIL_MODAL: 'TOGGLE_DETAIL_MODAL/messages',
  TOGGLE_MODAL: 'TOGGLE_MODAL/messages',
  TOGGLE_LEAVE_GROUP_MODAL: 'TOGGLE_LEAVE_GROUP_MODAL/messages',
  TOGGLE_NOTIFICATION_MODAL: 'TOGGLE_NOTIFICATION_MODAL/messages',
  UPDATE_MESSAGE_REQUEST: 'UPDATE_MESSAGE_REQUEST/messages',
  UPDATE_MESSAGE_SUCCESS: 'UPDATE_MESSAGE_SUCCESS/messages',

  clearCurrentGroup: () => ({
    type: actions.CLEAR_CURRENT_GROUP,
  }),

  closeEditMessage: () => ({
    type: actions.CLOSE_EDIT_MESSAGE,
  }),

  createNewGroupRequest: (params) => ({
    type: actions.CREATE_NEW_GROUP_REQUEST,
    params,
  }),

  createNewMessageRequest: (params) => ({
    type: actions.CREATE_NEW_MESSAGE_REQUEST,
    params,
  }),

  fetchSchoolUsersRequest: () => ({
    type: actions.FETCH_SCHOOL_USERS_REQUEST,
  }),

  fetchGroupDetailsRequest: (groupId) => ({
    type: actions.FETCH_GROUP_DETAILS_REQUEST,
    groupId,
  }),

  fetchGroupsRequest: (userId) => ({
    type: actions.FETCH_GROUPS_REQUEST,
    userId,
  }),

  fetchGroupMembersRequest: (groupId, page) => ({
    type: actions.FETCH_GROUP_MEMBERS_REQUEST,
    groupId,
    page,
  }),

  fetchMessagesRequest: (params) => ({
    type: actions.FETCH_MESSAGES_REQUEST,
    params,
  }),

  fetchLastMessageRequest: (params) => ({
    type: actions.FETCH_LAST_MESSAGE_REQUEST,
    params,
  }),

  fetchRealTimeMessageRequest: (params) => ({
    type: actions.FETCH_REAL_TIME_MESSAGE_REQUEST,
    params,
  }),

  setArchiveGroupRequest: (currentGroup) => ({
    type: actions.SET_ARCHIVE_GROUP_REQUEST,
    currentGroup,
  }),

  setCurrentMessage: (message) => ({
    type: actions.SET_CURRENT_MESSAGE,
    message,
  }),

  setCurrentGroup: (groupId) => ({
    type: actions.SET_CURRENT_GROUP,
    groupId,
  }),

  setCurrentGroupRequest: (group) => ({
    type: actions.SET_CURRENT_GROUP_REQUEST,
    group,
  }),

  setChangeFooterToast: (params) => ({
    type: actions.SET_CHANGE_FOOTER_TOAST,
    params,
  }),

  setDeleteGroupRequest: (groupId) => ({
    type: actions.SET_DELETE_GROUP_REQUEST,
    groupId,
  }),

  setDeleteMessageRequest: (groupId, messageId) => ({
    type: actions.SET_DELETE_MESSAGE_REQUEST,
    groupId,
    messageId,
  }),

  setEditGroupRequest: (groupId, form) => ({
    type: actions.SET_EDIT_GROUP_REQUEST,
    groupId,
    form,
  }),

  setEditMessage: (message) => ({
    type: actions.SET_EDIT_MESSAGE,
    message,
  }),

  setLeaveGroupRequest: (groupId) => ({
    type: actions.SET_LEAVE_GROUP_REQUEST,
    groupId,
  }),

  setReadCurrentMessagesRequest: (currentGroup) => ({
    type: actions.SET_READ_CURRENT_MESSAGES_REQUEST,
    currentGroup,
  }),

  setMuteAndTurnOnNotificationRequest: (currentGroup) => ({
    type: actions.SET_MUTE_AND_TURN_ON_NOTIFICATION_REQUEST,
    currentGroup,
  }),

  socketConnectRequest: () => ({
    type: actions.SOCKET_CONNECT_SERVER_REQUEST,
  }),

  socketEnterGroupRoomRequest: (groupId) => ({
    type: actions.SOCKET_ENTER_GROUP_ROOM_REQUEST,
    groupId,
  }),

  socketLeaveGroupRoomRequest: (groupId) => ({
    type: actions.SOCKET_LEAVE_GROUP_ROOM_REQUEST,
    groupId,
  }),

  toggleDeleteGroupModal: () => ({ type: actions.TOGGLE_DELETE_GROUP_MODAL }),

  toggleDeleteMessageModal: () => ({
    type: actions.TOGGLE_DELETE_MESSAGE_MODAL,
  }),

  toggleModal: () => ({ type: actions.TOGGLE_MODAL }),

  toggleDetailModal: () => ({ type: actions.TOGGLE_DETAIL_MODAL }),

  toggleLeaveGroupModal: () => ({ type: actions.TOGGLE_LEAVE_GROUP_MODAL }),

  toggleNotificationModal: () => ({ type: actions.TOGGLE_NOTIFICATION_MODAL }),

  updateMessageRequest: (updateMessage) => ({
    type: actions.UPDATE_MESSAGE_REQUEST,
    updateMessage,
  }),
};

export default actions;
