import styled, { css } from 'styled-components';
import Tooltip from 'components/Tooltip';

export const Title = styled.h3`
  margin-bottom: 16px;
  font-family: 'Quicksand', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  color: ${(props) => props.theme.black};
`;

export const Text = styled.p`
  font-family: 'Roboto' sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: ${(props) => props.theme.black};
`;

export const CustomLabel = styled.div`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.black};
`;

export const CustomTooltip = styled(Tooltip)`
  .tooltip-div {
    margin-left: 8px;
    position: relative;
    width: 16px;
    height: 16px;
  }
`;

export const Separator = styled.div`
  margin: 32px 0;
  width: 100%;
  border-bottom: 1px solid ${(props) => props.theme.gray3};
`;

export const ModelCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 16px;
  border: 1px solid ${(props) => props.theme.gray12};
  border-radius: 8px;
`;

export const ModelCardTitle = styled.h4`
  margin: 10px 0;
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: ${(props) => props.theme.black};
`;

export const ModelCardLinks = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
`;

export const ModelCardLinksItem = styled.li`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.primaryColor};

  &:first-child {
    margin-right: 48px;
  }

  > i {
    margin-right: 12px;
  }

  > span {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const FileUploadWrapper = styled.div`
  margin: 32px 0;

  .FileAttachment {
    .Button {
      border-radius: 6px;
    }
  }
  .FormValidationErrors {
    ul li {
      text-align: start;
    }
  }
`;

export const TableWrapper = styled.div`
  ${() => css`
    .TableContainer {
      .Table.table {
        thead {
          > tr {
            display: flex;
            > th:nth-of-type(1) {
              flex: 1;
              width: 100%;
              max-width: 200px;
              min-width: 200px;
            }
            > th:nth-of-type(2) {
              flex: 1;
              width: 100%;
              max-width: 100px;
              min-width: 100px;
            }
            > th:nth-of-type(3) {
              flex: 3;
              width: 100%;
            }
          }
        }

        tbody {
          > tr {
            padding: 2rem 0;
            display: flex;
            > td {
              display: flex;
              align-items: center;

              span {
                p {
                  margin: 0.5rem 0;
                }
              }
            }
            > td:nth-of-type(1) {
              flex: 1;
              width: 100%;
              max-width: 200px;
              min-width: 200px;
            }
            > td:nth-of-type(2) {
              flex: 1;
              width: 100%;
              max-width: 100px;
              min-width: 100px;
            }
            > td:nth-of-type(3) {
              flex: 3;
              width: 100%;
            }
          }
        }
      }
    }
  `}
`;
