import React from 'react';
import PropTypes from 'prop-types';

import withFormContext, { formPropTypes } from 'core/hoc/withFormContext';
import DragAndDropImage from 'components/DragAndDropImage';
import FormValidationErrors from 'components/Form/ValidationErrors';

const ImageField = ({
  attributeName,
  disabled,
  customPreviewSize,
  customPreviewMessage,
  formContext,
}) => {
  const { form, hasErrorOnAttribute } = formContext;
  const hasError = hasErrorOnAttribute(attributeName);

  const onChange = (attachment) => {
    const { updateAttribute } = formContext;

    updateAttribute(attributeName, attachment);
  };

  return (
    <div className={`ImageField ${hasError ? 'has-error' : ''}`}>
      <DragAndDropImage
        onChange={onChange}
        attachment={form[attributeName]}
        disabled={disabled}
        customPreviewSize={customPreviewSize}
        customPreviewMessage={customPreviewMessage}
      />
      {hasError && <FormValidationErrors attributeName={attributeName} />}
    </div>
  );
};

ImageField.defaultProps = {
  disabled: false,
};

ImageField.propTypes = {
  attributeName: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  customPreviewSize: PropTypes.number,
  customPreviewMessage: PropTypes.string,
  ...formPropTypes,
};

export default withFormContext(ImageField);
