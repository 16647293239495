import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';

import composeFunctions from 'core/utils/composeFunctions';
import reduxProvider from 'core/hoc/reduxProvider';
import withAppContext from 'core/hoc/withAppContext';

import EditGroupForm from 'components/Messages/Forms/EditGroupForm';
import MessageGroupScreen from 'screens/Messages/MessageGroupScreen';
import NewGroupForm from 'components/Messages/Forms/NewGroupForm';

import { MessageGroupRoutesProps } from './types';

const MessageGroupRoutes = ({
  appContext: { dataArea },
}: MessageGroupRoutesProps) => {
  return (
    <CookiesProvider>
      <Switch>
        <Route
          exact
          path={`/${dataArea}/messages/groups`}
          component={MessageGroupScreen}
        />
        <Route
          exact
          path={`/${dataArea}/messages/groups/new`}
          component={NewGroupForm}
        />
        <Route
          exact
          path={`/${dataArea}/messages/groups/:id/edit`}
          component={EditGroupForm}
        />
      </Switch>
    </CookiesProvider>
  );
};

export default composeFunctions(
  withAppContext,
  reduxProvider
)(MessageGroupRoutes);
