import styled from 'styled-components';

export const MenuWrapper = styled.div`
  padding: 4px 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  > span {
    display: block;
    font-family: 'Roboto' sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: ${({ theme }) => theme.gray2};
  }
`;
