import styled from 'styled-components';

export const RejectSchoolProductTab = styled.div`
  .FormBackendErrorWarning {
    text-align: center;
    margin-top: 10px;
  }

  .ButtonRow {
    a {
      margin-right: 15px;
      margin-bottom: 15px;
    }

    a:last-child {
      float: right;
    }

    a:only-child {
      float: right;
    }
  }
`;

export const RejectionDescription = styled.div`
  margin-top: 29px;
`;

export const RejectPaymentDivider = styled.hr`
  border: solid 0.5px #abb1b7;
  width: 98%;
  display: inline-block;
`;
