import styled, { css } from 'styled-components';
import FormFieldset from 'components/Form/Fieldset';
import { ThemeProps } from 'config/themes/types';

export const GroupInformationStepWrapper = styled(FormFieldset)``;

export const RowWrapper = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
    width: 100%;
    gap: ${space.lg};
  `}
`;

export const InputsWrapper = styled.div`
  ${({ theme: { space, breakpoints } }: ThemeProps) => css`
    display: flex;
    flex-direction: row;
    gap: ${space.lg};
    align-items: flex-start;

    @media screen and (max-width: ${breakpoints.tabletMD}) {
      flex-direction: column;
      align-items: center;
    }
  `}
`;
