import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import autobind from 'autobind-decorator';

import FormClassroomSelectorNoOptionsMessage from './NoOptionsMessage';

import withFormContext from 'core/hoc/withFormContext';

import selectStyles from './selectStyles';

/**
 * This class expects the options array to contain JSON API serialized
 * classrooms.
 *
 * ```
 *    [
 *      {
 *        id: 1,
 *        type: 'classroom',
 *        attributes: {
 *          name: '3º ano T'
 *        },
 *        ...
 *      }
 *    ]
 * ```
 */
class FormClassroomSelector extends Component {
  static propTypes = {
    attributeName: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    formContext: PropTypes.shape({
      getOptionsForSelect: PropTypes.func.isRequired,
    }).isRequired,
    inputRef: PropTypes.func,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    searchable: PropTypes.bool,
    value: PropTypes.object,
  };

  static defaultProps = {
    searchable: true,
    placeholder: 'Selecione uma turma',
    value: null,
  };

  getOptionValue = (option) => option.id;

  getOptionLabel = (option) => option.attributes.name;

  customSelectComponents = {
    // TODO: Change the icon when it's available.
    IndicatorSeparator: () => null,
    NoOptionsMessage: FormClassroomSelectorNoOptionsMessage,
  };

  getOptionsForSelect() {
    const { attributeName, formContext } = this.props;
    const { getOptionsForSelect } = formContext;
    return getOptionsForSelect(attributeName) || [];
  }

  @autobind
  onChange(option, { action }) {
    if (action === 'select-option') {
      const { onChange, formContext, attributeName } = this.props;
      const { updateAttribute } = formContext;
      const { id } = option;

      updateAttribute(attributeName, id, (formContext) => {
        onChange && onChange(id, formContext);
      });
    }
  }

  @autobind
  setInputRef(node) {
    this.inputRef = node;
    this.props.inputRef && this.props.inputRef(node);
  }

  @autobind
  scrollTop() {
    this.inputRef.scrollTop = 0;
  }

  render() {
    const { disabled, placeholder, searchable, value } = this.props;

    return (
      <Select
        classNamePrefix="FormClassroomSelector"
        components={this.customSelectComponents}
        value={value}
        getOptionValue={this.getOptionValue}
        getOptionLabel={this.getOptionLabel}
        isDisabled={disabled}
        isSearchable={searchable}
        onChange={this.onChange}
        onFocus={this.scrollTop}
        options={this.getOptionsForSelect()}
        placeholder={placeholder}
        styles={selectStyles}
        ref={this.setInputRef}
      />
    );
  }
}

export default withFormContext(FormClassroomSelector);
