import React from 'react';
import { LabeledContainerProps } from './types';

import * as S from './styles';

const LabeledContainer = ({ title, children }: LabeledContainerProps) => {
  const renderString = () => <span className="simple-string">{children}</span>;

  const content = () => {
    if (typeof children === 'string') {
      return renderString();
    }

    return children;
  };

  return (
    <S.LabeledWrapper className="LabeledContainer">
      <div className="detail-header">
        <span className="detail-title">{title}</span>
      </div>
      <div className="information-content">
        <React.Fragment>{content()}</React.Fragment>
      </div>
    </S.LabeledWrapper>
  );
};

export default LabeledContainer;
