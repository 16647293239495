import styled, { css } from 'styled-components';
import PageContainer from 'components/PageContainer';

const flexStyle = css`
  display: flex;
  align-items: center;
`;

export const Page = styled(PageContainer)`
  .generic-modal-body {
    font-size: 16px;
    margin: 24px 0 8px;
    line-height: 24px;
  }
`;

export const TitleContainer = styled.div`
  position: relative;
  display: flex;
  align-items: end;
`;

export const Text = styled.p`
  text-align: center;
  margin: 0;
  margin: 0 32px 0 0;
`;

export const Badge = styled.span`
  position: absolute;
  top: 0;
  right: 0;

  width: 22px;
  height: 24px;
  margin-left: 10px;
  padding: 0px 7px;

  font-size: 14px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  border-radius: 100px;
`;

export const EngagementContainer = styled.section`
  .infinite-scroll-component__outerdiv {
    width: 100%;

    div.infinite-scroll-component {
      overflow: initial !important;
    }
  }
`;

export const CommentsContainer = styled.section`
  width: 100%;

  .FilterContainerWrapper {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;

    .Form {
      max-width: 250px;
      margin: 0 0 8px 0;
      align-items: center;

      .Tooltip {
        line-height: 0;
        vertical-align: middle;
      }
    }
  }
`;

export const CommentsContent = styled.div`
  ${({ height = 0 }) => css`
    margin: 16px 0 24px 0;
    height: ${height}px;
    display: flex;
    flex-direction: column-reverse;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
    }

    .infinite-scroll-component__outerdiv {
      width: 100%;

      div.infinite-scroll-component {
        display: flex;
        flex-direction: column-reverse;
      }
    }
  `}
`;

export const RecipientsAmount = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: flex-end;
    margin: 40px 0 0 0;

    > p {
      font-size: 12px;
      color: ${theme.gray2};
    }
  `};
`;

export const CommentsList = styled.ul`
  width: 100%;
  padding: 0;

  display: flex;
  flex-direction: column;

  > li {
    margin: 0 0 42px 0;

    &:last-child {
      margin: 0 0 24px 0;
    }
  }
`;

export const Container = styled.li`
  width: 100%;
  list-style: none;
`;

export const Content = styled.div`
  ${flexStyle};
  ${({ theme }) => css`
    justify-content: space-between;

    .DropdownButton {
      .Button {
        border-color: transparent;

        &:hover {
          background: ${theme.gray5};
        }
      }

      .dropdown-menu {
        top: 40px;
        height: auto;
        margin: 0;
        padding: 0;
        background: ${theme.white};
        border: 0;
        border-radius: 6px;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.14);

        .dropdown-item {
          margin: 0;

          &:hover {
            color: ${theme.black};
            background: transparent;
          }
        }
      }
    }
  `}
`;

export const Profile = styled.div`
  position: relative;
  max-width: 60%;
  ${flexStyle};

  .Avatar {
    flex-shrink: 0;
    position: absolute;
    top: 10px;
    left: 0;
  }
`;

export const ProfileOverlay = styled.div`
  ${({ theme }) => css`
    position: absolute;
    inset: 0;
    background: ${theme.white}50;
    z-index: 5;
  `}
`;

export const Message = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 0 64px;
`;

export const Header = styled.div`
  display: flex;
  align-items: baseline;
  gap: 16px;

  &.tab-header-area {
    gap: 0px;
  }
`;

export const EmptyContainer = styled.div`
  ${({ height }) => css`
    max-height: ${height}px;
    margin: 120px 0 100px 0;
    display: flex;
    align-items: center;
    justify-content: center;
  `}
`;

export const MessageGroup = styled.div`
  ${({ theme, maxWidth = 245 }) => css`
    max-width: ${maxWidth}px;
    font-family: 'Quicksand', sans-serif;

    h6 {
      margin: 0 0 6px 0;
      font-weight: 700;
      font-size: 16px;
      color: ${theme.black};
    }

    span {
      font-size: 16px;
      line-height: 28px;
      color: ${theme.gray01};
    }
  `};
`;

export const CommentOn = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    padding: 24px 0 0 0;
    gap: 10px;
    margin-bottom: -24px;
    border-top: 1px solid ${theme.gray5};

    .Avatar.large {
      flex-shrink: 0;
    }
  `}
`;

export const Commenter = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    align-items: center;
    gap: 8px;

    .Button {
      width: 32px;
      height: 32px;

      display: flex;
      align-items: center;
      justify-content: center;
      border: 0;

      &.btn-default[disabled],
      &:disabled {
        background-color: ${theme.gray5};
        border: none;
        color: ${theme.gray1};
      }
    }
  `}
`;

export const Input = styled.input.attrs({
  type: 'text',
})`
  ${({ theme }) => css`
    flex: 1;
    padding: 7px 16px;
    font-family: 'Roboto', sans-serif;
    background: ${theme.white};
    color: ${theme.black};
    border: 1px solid ${theme.gray12};
    border-radius: 4px;

    transition: border-color 180ms ease-in-out;

    &:focus {
      border-color: ${theme.primaryColor};
    }

    &::placeholder {
      color: ${theme.gray2};
    }
  `}
`;

export const LoaderContainer = styled.div`
  ${({ top = 0, bottom = 0 }) => css`
    margin: ${top}px 0px ${bottom}px 0px;
  `};
`;
