import React, { Component } from 'react';
import autobind from 'autobind-decorator';

import withFormContext, { formPropTypes } from 'core/hoc/withFormContext';

import getHocName from 'core/utils/getHocName';

/**
 * Enables onChange validations on fields.
 */
const withOnChangeValidations = (WrappedComponent) => {
  class WithOnChangeValidations extends Component {
    @autobind
    onChange() {
      const { attributeName, onChange, formContext } = this.props;
      const { validateOnChange } = formContext;

      validateOnChange(attributeName);

      return onChange(arguments);
    }

    render() {
      return <WrappedComponent {...this.props} onChange={this.onChange} />;
    }
  }

  WithOnChangeValidations.propTypes = {
    ...formPropTypes,
  };

  WithOnChangeValidations.defaultProps = {
    onChange: () => {},
  };

  WithOnChangeValidations.displayName = getHocName(
    'WithOnChangeValidations',
    WrappedComponent
  );

  return withFormContext(WithOnChangeValidations);
};

export default withOnChangeValidations;
