import styled, { css } from 'styled-components';

export const PeriodField = styled.div`
  display: inline-block;
  width: 100%;

  * {
    font-family: Quicksand, sans-serif;
  }

  .DateRangePicker {
    width: 100%;

    @media (min-width: 834px) {
      width: inherit;
    }

    .DateRangePickerInput {
      width: 100%;
      height: 40px;
      border-radius: 4px;
      ${({ theme }) => css`
        border: 1px solid ${theme.gray12};
      `}

      &:hover, &:active, &:focus {
        ${({ theme }) => css`
          border: 1px solid ${theme.primaryColor};
        `}
      }
    }

    .DayPickerNavigation_button {
      background-color: transparent;
      padding: 4px;
      ${({ theme }) => css`
        color: ${theme.gray1};
        border: 1px solid ${theme.gray12};
        border-radius: 8px;
      `}
    }

    .DateRangePickerInput_calendarIcon {
      padding: 8px;
      padding-left: 0;
      ${({ theme }) => css`
        color: ${theme.primaryColor};
      `}
    }

    .DateInput {
      width: 38%;

      .DateInput_input {
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        height: 32px;
        font-size: 16px;
      }
    }

    .DateRangePickerInput_arrow {
      width: 2%;
    }

    .DateInput_input__focused {
      ${({ theme }) => css`
        border-bottom: 2px solid ${theme.primaryColor};
      `}
    }
  }

  .CalendarDay__default {
    border: 1px solid transparent;
  }

  .CalendarDay__outside {
    ${({ theme }) => css`
      color: ${theme.gray3};
    `}
  }

  .CalendarDay__hovered_span,
  .CalendarDay__selected_span {
    ${({ theme }) => css`
      background-color: ${theme.primaryColor}1a;
      color: ${theme.primaryColor};
    `}

    &:hover {
      ${({ theme }) => css`
        background-color: ${theme.primaryColor};
        background-opacity: 0.1;
        color: ${theme.primaryColor};
      `}
    }
  }

  .CalendarDay__default:hover {
    ${({ theme }) => css`
      border: 1px solid transparent;
      background-color: ${theme.primaryColor}1a;
      color: ${theme.primaryColor};
    `}
  }

  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover {
    ${({ theme }) => css`
      background: ${theme.primaryColor}1a;
      border: 1px solid transparent;
      color: ${theme.primaryColor};
    `}
  }

  .CalendarDay__selected_span {
    ${({ theme }) => css`
      background: ${theme.primaryColor}1a;
      border: 1px solid transparent;
      color: ${theme.primaryColor};
    `}
  }

  .CalendarDay__hovered_span {
    ${({ theme }) => css`
      background: ${theme.primaryColor}1a;
      border: 1px solid transparent;
      color: ${theme.primaryColor};
    `}
  }

  .DateRangePicker_picker {
    z-index: 0;
  }

  .DayPicker_focusRegion {
    .DayPickerNavigation_container {
      .DayPickerNavigation_button {
        svg {
          display: inline;
        }
      }
    }
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  padding: 24px;
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 24px;

  .Button {
    font-family: 'Quicksand', sans-serif;
    border-radius: 6px;
  }
`;

export const PeriodButtonRow = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;

  > .Button {
    font-size: 11px;
    padding: 6.5px 8px;

    ${({ theme }) => css`
      border: 1px solid ${theme.gray12};
    `}
  }
`;

export const ActionButtonRow = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  gap: 16px;

  > .Button {
    flex-grow: 1;
    font-size: 16px;
  }
`;
