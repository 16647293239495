import styled, { css } from 'styled-components';

export const Container = styled.div``;

export const Badge = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    border-radius: 100px;
    background: ${theme.danger};
    color: ${theme.white};
    position: relative;
    left: -10px;
    bottom: -12px;
  `}
`;

export const Button = styled.button`
  ${({ theme }) => css`
    width: 40px;
    height: 40px;
    border-radius: 100px;
    display: flex;
    padding: 16px;
    gap: 10px;
    justify-content: center;
    align-items: center;
    color: ${theme.white};
    background-color: ${theme.gray3};
    border: none;

    box-shadow: 0px 6px 11px rgba(0, 0, 0, 0.25);
  `}
`;
