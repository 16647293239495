import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from './types';
import withAppContext from 'core/hoc/withAppContext';
import reduxProvider from 'core/hoc/reduxProvider';
import OnboardingEmptyState from 'components/EmptyState/Onboarding';
import FormContainer from 'components/Form/FormContainer';
import InviteField from 'components/Invite/InviteField';
import { trackEvent } from 'config/amplitude';
import inviteEmptyStateActions from 'store/invite/emptyState/actions';
import { EMPTY_STATES_URL } from 'core/constants';
import { StudentsEmptyStateScreenProps } from './types';
import { useTranslation } from 'react-i18next';
import * as S from './styles';

const StudentsEmptyStateScreen = ({
  appContext,
}: StudentsEmptyStateScreenProps) => {
  const { form, isLoading, error } = useSelector(
    (state: RootState) => state.inviteEmptyState
  );
  const { generateInviteUrl } = inviteEmptyStateActions;
  const dispatch = useDispatch();

  const { t } = useTranslation(['register_account']);

  const { can_show_empty_state_students: canShowEmptyState } =
    appContext.policies;

  const handleGenerateInviteUrl = useCallback(() => {
    dispatch(generateInviteUrl('student'));
  }, [dispatch, generateInviteUrl]);

  useEffect(() => {
    trackEvent('plg-empty-state-students-view');
    handleGenerateInviteUrl();
  }, [handleGenerateInviteUrl]);

  const renderEmptyStateField = () => (
    <FormContainer initialAttributes={form.invite_link ? form : {}}>
      <InviteField
        attributeName="invite_link"
        disabled={isLoading || error}
        error={error}
        beforeCopy={() =>
          trackEvent('plg-students-invite-link-copied', {
            actionTarget: 'copy',
          })
        }
      />
    </FormContainer>
  );

  return (
    <S.Wrapper
      id="StudentsEmptyState"
      className="StudentsEmptyStateScreen"
      canShow={canShowEmptyState}
    >
      <OnboardingEmptyState
        imgUrl={EMPTY_STATES_URL.studentsUrl}
        title={t('students.empty_state_title')}
        text={t('students.empty_state_text')}
        buttons={renderEmptyStateField()}
      />
    </S.Wrapper>
  );
};

export default reduxProvider(withAppContext(StudentsEmptyStateScreen));
