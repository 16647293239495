import React from 'react';
import _ from 'lodash';

import { ActionsButtons } from '../ActionsButtons';
import { DiaryFormStructure } from 'store/dailySummaries/types';
import {
  isEmptyMeal,
  MealCard,
} from 'pages/diarySectionsV3/Sections/components/ShowSectionModal/Sections';
import { defaultMeal } from 'core/contexts/DiaryForm/constants';
import { Props } from './types';

export const MealsGroup = ({ meals, onChange }: Props) => {
  const actions = {
    add: (index: number) => {
      const newValues = defaultMeal();
      const fieldValues = _.cloneDeep(meals);

      if (index >= 0 && index < fieldValues.length) {
        fieldValues.splice(index + 1, 0, newValues);
      } else {
        fieldValues.push(newValues);
      }

      onChange(fieldValues);
    },
    remove: (index: number) => {
      const fieldValues = _.cloneDeep(meals);

      fieldValues.splice(index, 1);

      onChange(fieldValues);
    },
    update: (meal: DiaryFormStructure['meal'], index: number) => {
      const fieldValues = _.cloneDeep(meals);

      fieldValues[index] = meal;

      onChange(fieldValues);
    },
    clear: (index: number) => {
      const fieldValues = _.cloneDeep(meals);

      const currentValue = fieldValues[index];

      const emptyValue = {
        ...currentValue,
        ...defaultMeal(),
      };

      fieldValues[index] = emptyValue;

      onChange(fieldValues);
    },
  };

  return (
    <>
      {meals.map((meal, index) => {
        const { add, remove, update, clear } = actions;
        const isEmpty = isEmptyMeal(meal);

        const footer = (
          <ActionsButtons
            key={`actions-${meal.key}`}
            index={index}
            addSectionCard={add}
            removeSectionCard={remove}
            clearSectionAction={clear}
            isEmpty={isEmpty}
          />
        );

        return (
          <MealCard
            key={meal.key}
            meal={meal}
            onChange={(newMeal) => update(newMeal, index)}
            footer={footer}
          />
        );
      })}
    </>
  );
};
