import React from 'react';

import ModalTitle from 'components/Modal/ModalTitle';
import ModalButtons from 'components/Modal/ModalButtons';

import { AllStudentsRemovalModalProps } from './types';

import { AllStudentsRemovalModalWrapper } from './styles';

const AllStudentsRemovalModal = ({
  isOpen,
  toggleModal,
  handleSubmit,
  title,
  description,
}: AllStudentsRemovalModalProps) => {
  return (
    <AllStudentsRemovalModalWrapper
      isOpen={isOpen}
      toggleModal={toggleModal}
      className="AllStudentsRemovalModal"
      maxWidth="490px"
    >
      <ModalTitle>{title}</ModalTitle>
      <div className="subTitle">{description}</div>
      <ModalButtons buttons={handleSubmit} />
    </AllStudentsRemovalModalWrapper>
  );
};

export default AllStudentsRemovalModal;
