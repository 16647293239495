import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../Button';
import { Link } from 'react-router-dom';
import './ModalButtons.scss';

/*
ModalButtons
List of props:
buttons: array with buttons configuration

Example:
static modalButtons = [
  {
    text: 'Cancelar',
    variation: 'secondary',
    path: '/schools/wallets'
  },
  {
    text: 'Remover todos',
    path: '/schools/wallets'
  }
];

<ModalButtons buttons={this.constructor.modalButtons}/>

*/

/**
 * @deprecated Use ButtonRow instead.
 */
class ModalButtons extends React.Component {
  render() {
    const {
      buttons,
    } = this.props;

    return (
      <div className="modal-buttons">
        {buttons.map((button, index) =>
          index == 0 /** first button of the array is the cancel button to toggle modal off*/
            ? <Button
              variation={button.variation}
              onClick={button.onClick}
              key={`modal_button_${index}`}
            >
              {button.text}
            </Button>
            : <Button key={`modal_button_${index}`}
              variation={button.variation}
              onClick={button.onClick}
            >
              {button.text}
            </Button>
        )}
      </div>
    )
  }
}

ModalButtons.propTypes = {
  buttons: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    path: PropTypes.string,
    onClick: PropTypes.func
  })).isRequired
};

export default ModalButtons;
