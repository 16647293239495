import validateLength from 'core/lib/FormValidator/validators/length';
import validatePresence from 'core/lib/FormValidator/validators/presence';

export default [
  {
    attributeName: 'title',
    validator: validatePresence,
    message: 'required_field',
  },
  {
    attributeName: 'title',
    validator: validateLength({ max: 100 }),
    message: 'very_long_title',
  },
  {
    attributeName: 'description',
    validator: validatePresence,
    message: 'required_field',
  },
  {
    attributeName: 'description',
    validator: validateLength({ max: 1000 }),
    message: 'very_long_description',
  },
];
