import React from 'react';

import InternalErrorBoundary from 'boundaries/InternalErrorBoundary';

import getHocName from 'core/utils/getHocName';

const withErrorBoundary = (WrappedComponent) => {
  const WithFormContext = (props) => {
    return (
      <InternalErrorBoundary>
        <WrappedComponent {...props} />
      </InternalErrorBoundary>
    );
  };

  WithFormContext.displayName = getHocName('WithFormContext', WrappedComponent);

  return WithFormContext;
};

export default withErrorBoundary;
