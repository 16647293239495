import styled, { css } from 'styled-components';
import { ThemeProps } from 'config/themes/types';

export const ContainerSubButton = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    .gyZEZf {
      gap: ${space.xs2};
    }
  `}
`;
