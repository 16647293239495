import styled, { css } from 'styled-components';
import { ThemeProps } from 'config/themes/types';

export const MainContainer = styled.div`
  ${({ theme: { space, breakpoints } }: ThemeProps) => css`
    width: 85%;
    padding: ${space.xl} ${space.xs4};
    clear: both;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: 1fr;
    grid-gap: ${space.lg};

    @media screen and (${`max-width: ${breakpoints.mobileSM}`}) {
      width: 100%;
      grid-template-rows: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
    }
  `}
`;
