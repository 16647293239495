import React from 'react';
import { theme, DefaultThemeProps, Box } from '@agendaedu/ae-web-components';

import * as S from './styles';
import Skeleton from 'components/Skeleton';
import { ChannelCardSkeletonProps } from './types';

export const ChannelCardSkeleton = ({ amount }: ChannelCardSkeletonProps) => {
  const defaultTheme: DefaultThemeProps = theme;

  const {
    space,
    colors: { neutral },
    border: { radius },
  } = defaultTheme;

  return (
    <>
      {Array.from(Array(amount).keys()).map((n) => (
        <S.SkeletonWrapper key={n}>
          <S.SkeletonCard data-testid="skeleton-wrapper">
            <Box
              display={'flex'}
              flexDirection={'column'}
              flex={1}
              maxWidth={'max-content'}
            >
              <Skeleton
                width={40}
                height={40}
                primaryColor={neutral.gray5}
                borderRadius={radius.circle}
              />
            </Box>

            <Box
              display={'flex'}
              flex={2}
              flexDirection={'column'}
              marginLeft={space.xs}
            >
              <Skeleton
                width={137}
                height={18}
                primaryColor={neutral.gray5}
                borderRadius={radius.sm}
              />
            </Box>
          </S.SkeletonCard>
        </S.SkeletonWrapper>
      ))}
    </>
  );
};
