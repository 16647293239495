import { ThemeProps } from 'config/themes/types';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme: { border, space } }: ThemeProps) => css`
    display: grid;
    grid-template-columns: minmax(50px, auto) repeat(4, 1fr) 30px;
    grid-gap: ${space.sm};
    border-radius: ${border.radius.md};
    padding: ${space.md} ${space.xl3};

    @media screen and (max-width: 768px) {
      grid-template-columns: minmax(80px, auto) repeat(3, 1fr);
    }

    @media screen and (max-width: 480px) {
      grid-template-columns: minmax(40px, auto) 1fr minmax(40px, auto);
      padding: ${space.sm};
      grid-gap: ${space.xs};
    }

    div.information-content > div {
      height: 100%;
    }

    .Checkbox {
      display: flex;
      align-items: center;
    }
  `}
`;
