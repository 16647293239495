import React from 'react';
import { useSelector } from 'react-redux';

import tabifyForWizard from 'core/hoc/tabifyForWizard';

import Alert from 'components/Alert';
import LabeledContainer from 'components/LabeledContainer';
import Title from 'components/WizardForm/Title';
import UserDisplay from 'components/UserDisplay';

import EdupayDiscount from 'components/Payments/PaymentsContainer/FormSections/EdupayDiscount';
import FinesAndInterest from '../../RecurrentFormSections/FinesAndInterest';
import RecurrencyConfiguration from 'components/Payments/PaymentsContainer/FormSections/RecurrencyConfiguration';
import RecurrentItems from '../../RecurrentFormSections/RecurrentItems';
import RecurrentSummary from '../../RecurrentFormSections/RecurrentSummary';
import WalletConfiguration from 'components/Payments/PaymentsContainer/FormSections/WalletConfiguration';
import FinancialResponsibleConfiguration from 'components/Payments/PaymentsContainer/FormSections/FinancialResponsibleConfiguration';

import validations from './validations.js';
import { CurrentBill, RecurrentPlans } from '../types';

import * as S from '../styles';

const NewRecurrentBillInformations = () => {
  const { current } = useSelector((state: CurrentBill) => state.recurrentBills);
  const recurrentPlan = useSelector(
    (state: RecurrentPlans) => state.recurrentPlans.current
  );

  return (
    <S.EditRecurrentBill data-testid="new-recurrent-bill-informations">
      <fieldset className="FormFieldset col-xs-12 col-lg-8 col-lg-offset-2">
        <Alert variation="danger">
          <b>Importante!</b> O preenchimento e envio deste formulário cancelará
          a cobrança de ID {current?.id} e criará uma nova cobrança para o plano
          "{recurrentPlan?.recurrentPlan?.title}", com as informações
          fornecidas.
        </Alert>
        <S.DoubleInput>
          <UserDisplay
            user={recurrentPlan.studentProfile}
            size="medium"
            classroomsNames={
              recurrentPlan.studentProfile.attributes.classroom_names
            }
          />
          <LabeledContainer title="Titulo da cobrança">
            {recurrentPlan.recurrentPlan.title}
          </LabeledContainer>
        </S.DoubleInput>

        <WalletConfiguration isRecurrent recurrentBill />
        <FinancialResponsibleConfiguration />
        <Title name="Vencimento" />
        <RecurrencyConfiguration type="bill" action="edit" isNewRecurrentBill />
        <RecurrentItems title="Itens da cobrança" />
        <FinesAndInterest />
        <EdupayDiscount
          type="bill"
          action="editBill"
          validations={validations}
          showCreditCardDiscount
        />
        <RecurrentSummary />
      </fieldset>
    </S.EditRecurrentBill>
  );
};

export default tabifyForWizard({
  title: '1. Informações',
  validations,
})(NewRecurrentBillInformations);
