import React from 'react';
import getHocName from 'core/utils/getHocName';

/**
 * Makes a component proper to be used by the wizard.
 *
 * @param {string} props.title The title to be showed on top.
 * @param {string} props.subTitle The text right above the fields
 * @param {Array<object>} props.validations The validations to be perfomed when
 *   the user clicks on "next" or "submit".
 * @param {Function<Array>} props.onChangeValidationsAttributeNames An array
 *   which contains the attributeNames to be validaded by the form. This will be
 *   used by the wizard component to know which fields to validate each step.
 */
const tabifyForWizard = (props) => (WrappedComponent) => {
  const { title, subTitle, validations, onChangeValidationsAttributeNames } =
    props;

  const TabifyForWizard = (props) => <WrappedComponent {...props} />;

  TabifyForWizard.title = title;
  TabifyForWizard.subTitle = subTitle;
  TabifyForWizard.validations = validations || [];
  TabifyForWizard.onChangeValidationsAttributeNames =
    onChangeValidationsAttributeNames || (() => []);

  TabifyForWizard.displayName = getHocName('TabifyForWizard', WrappedComponent);

  return TabifyForWizard;
};

export default tabifyForWizard;
