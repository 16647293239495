import styled from 'styled-components';
import colorWithAlpha from 'core/utils/colorWithAlpha';
import Tooltip from 'components/Tooltip';

export const Container = styled.div`
  display: flex;
  align-items: center;
  background-color: ${(props) => colorWithAlpha(props.theme.danger, 0.1)};
  padding: 8px 16px;
  border-radius: 6px;
  min-width: 220px;

  input.table-input {
    background-color: transparent;
    color: ${(props) => props.theme.danger};
    border: none;
    &:focus,
    &:hover {
      border: none;
    }
  }

  .input-actions {
    .ButtonRow {
      .Button.clear {
        display: flex;
        align-items: center;
        padding: 4px;
        border-radius: 4px;
        border-color: ${(props) =>
          colorWithAlpha(props.theme.primaryColor, 0.1)};
        background-color: ${(props) =>
          colorWithAlpha(props.theme.primaryColor, 0.1)};

        i {
          color: #999999;
        }
      }
    }
  }
`;

export const CustomTooltip = styled(Tooltip)`
  .tooltip-div {
    position: relative;
    margin-top: 5px;
    width: 16px;
    height: 16px;

    i {
      font-size: 18px;
    }
  }

  .popup-content {
    white-space: normal;
  }
`;

export const ButtonRow = styled.div`
  display: flex;
  align-items: center;
`;
