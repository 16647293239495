import React from 'react';

import Field from 'components/Form/Field';
import Button from 'components/Button';
import AgendaIcon from 'components/AgendaIcon';
import FormValidationErrors from 'components/Form/ValidationErrors';

import withFormContext from 'core/hoc/withFormContext';

import './style.scss';
import {
  errorIndexOptionsPresence,
  errorIndexOptionsQuantity,
} from '../validations';

const OptionsAttributes = ({
  formContext: { form, hasErrorOnAttribute, updateAttribute },
  onChange,
  indexQuestion,
  setForm,
}) => {
  const isAuthorizationQuestion = indexQuestion === 0;

  const question = form.survey_questions_attributes[indexQuestion];
  const invalidQuantity = hasErrorOnAttribute('quantity_error');
  const hasUnfilledOption = hasErrorOnAttribute('unfilled_option_error');

  const addNewOption = () => {
    const newSurveyOptionsAttributes = [
      ...question.survey_options_attributes,
      {
        key: Math.random().toString(36).substring(7),
        position: `${question.survey_options_attributes.length + 1}`,
        title: '',
        others: false,
      },
    ];

    const newSurveyQuestion = {
      ...question,
      survey_options_attributes: newSurveyOptionsAttributes,
    };

    onChange(indexQuestion, newSurveyQuestion);
  };

  const removeOption = (key) => {
    const currentOptions = question.survey_options_attributes;
    const indexLastQuestion = currentOptions.length - 1;

    const remainingOptions = currentOptions
      .filter((item, optionIndex) => {
        if (item.key === key) {
          delete form[
            `titleOption_${indexQuestion}_${optionIndex}_${item.key}`
          ];
          delete form[
            `titleOption_${indexQuestion}_${indexLastQuestion}_${currentOptions[indexLastQuestion].key}`
          ];
          setForm(form);
        }
        return item.key !== key;
      })
      .map((item, index) => ({ ...item, position: `${index + 1}` }));

    const newSurveyQuestion = {
      ...question,
      survey_options_attributes: remainingOptions,
    };

    onChange(indexQuestion, newSurveyQuestion);

    remainingOptions.forEach((option, indexOption) => {
      updateAttribute(
        `titleOption_${indexQuestion}_${indexOption}_${option.key}`,
        option.title
      );
    });
  };

  const updateValues = (attributeName, form, index) => {
    const currentOptions = question.survey_options_attributes;
    let optionToUpdate = currentOptions[index];
    optionToUpdate['title'] = form[attributeName];

    const newSurveyOptionsAttributes = currentOptions.map((item) =>
      item.index === index ? optionToUpdate : item
    );

    const newSurveyQuestion = {
      ...question,
      survey_options_attributes: newSurveyOptionsAttributes,
    };

    onChange(indexQuestion, newSurveyQuestion);
  };

  return (
    <div className="options">
      <span className="options__title">Opções de resposta</span>

      {question.question_type === 'subjective' ? (
        <Field
          placeholder="Será exibido um campo aberto para resposta do participante"
          disabled
        />
      ) : (
        <>
          {question.survey_options_attributes.map(
            (option_attributes, index) => {
              const { key, title, position } = option_attributes;

              return (
                <div key={position} className="options__attributes">
                  <Field
                    key={key}
                    value={title}
                    placeholder={`Opção ${position}`}
                    disabled={isAuthorizationQuestion}
                    attributeName={`titleOption_${indexQuestion}_${index}_${key}`}
                    onChange={(formState) =>
                      updateValues(
                        `titleOption_${indexQuestion}_${index}_${key}`,
                        formState,
                        index
                      )
                    }
                  />
                  {!isAuthorizationQuestion && (
                    <Button
                      onClick={() => removeOption(key)}
                      variation="secondary"
                    >
                      <AgendaIcon name="close" size="x-medium" />
                    </Button>
                  )}
                </div>
              );
            }
          )}

          {invalidQuantity &&
            errorIndexOptionsQuantity.includes(indexQuestion) && (
              <FormValidationErrors attributeName={'quantity_error'} />
            )}

          {hasUnfilledOption &&
            errorIndexOptionsPresence.includes(indexQuestion) && (
              <FormValidationErrors attributeName={'unfilled_option_error'} />
            )}

          {!isAuthorizationQuestion && (
            <div className="options__buttons">
              <Button onClick={() => addNewOption()} variation="secondary">
                + Adicionar opção
              </Button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default withFormContext(OptionsAttributes);
