/* eslint-disable no-debugger, react/prop-types */
import React from 'react';
import TableField from '../TableField';

const ExternalId = (data) => <TableField property="external_id" data={data} />;

const StudentId = (data) => <TableField property="student_id" data={data} />;

const Name = (data) => <TableField property="name" data={data} />;

const Email = (data) => <TableField property="email" data={data} />;

const Phone = (data) => <TableField property="phone" data={data} />;

const Kinship = (data) => <TableField property="kinship" data={data} />;

const Login = (data) => <TableField property="username" data={data} />;

const DocumentNumber = (data) => (
  <TableField property="document_number" data={data} />
);

const Cep = (data) => <TableField property="cep" data={data} />;

const City = (data) => <TableField property="city" data={data} />;

const Street = (data) => <TableField property="street" data={data} />;

const StreetNumber = (data) => (
  <TableField property="street_number" data={data} />
);

const Neighborhood = (data) => (
  <TableField property="neighborhood" data={data} />
);

const State = (data) => <TableField property="state" data={data} />;

const Complement = (data) => <TableField property="complement" data={data} />;

export default [
  {
    Header: 'Identificador Registro',
    accessor: ExternalId,
  },
  {
    Header: 'Identificador Aluno',
    accessor: StudentId,
  },
  {
    Header: 'Nome',
    accessor: Name,
  },
  {
    Header: 'E-mail',
    accessor: Email,
  },
  {
    Header: 'Telefone',
    accessor: Phone,
  },
  {
    Header: 'Parentesco',
    accessor: Kinship,
  },
  {
    Header: 'Login',
    accessor: Login,
  },
  {
    Header: 'CPF',
    accessor: DocumentNumber,
  },
  {
    Header: 'CEP',
    accessor: Cep,
  },
  {
    Header: 'Cidade',
    accessor: City,
  },
  {
    Header: 'Rua',
    accessor: Street,
  },
  {
    Header: 'Número',
    accessor: StreetNumber,
  },
  {
    Header: 'Bairro',
    accessor: Neighborhood,
  },
  {
    Header: 'Estado',
    accessor: State,
  },
  {
    Header: 'Complemento',
    accessor: Complement,
  },
];
