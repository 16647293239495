import React from 'react';
import { useSelector } from 'react-redux';

import Currency from 'components/Currency';
import HelpTooltip from 'components/Tooltip/variations/help';

import { BillSummaryProps, ItemProps, RootState } from './types';
import * as S from './styles';

const BillSummaryNegotiation = ({
  label = 'Resumo da cobrança',
  discount,
  price,
  billetTax,
  pixTax,
  allowedPaymentMethod,
  items,
  status,
  penality,
}: BillSummaryProps) => {
  const recurrentProducts = useSelector(
    (state: RootState) => state.recurrentProducts.recurrentProducts
  );

  const policies = useSelector((state: RootState) => state.root.policies);

  const hasDelayAndPenality = () => status === 'paid_late' && penality;

  const renderTaxMessages = () => {
    const isBillet = allowedPaymentMethod.includes('billet');
    const isPix = allowedPaymentMethod.includes('pix');
    const isCreditCard = allowedPaymentMethod.includes('credit_card');

    return (
      <>
        {isBillet && <p>Incluindo a taxa do boleto de R$ {billetTax}</p>}
        {isPix && <p>Incluindo a taxa do PIX de R$ {pixTax}</p>}
        {isCreditCard && <p>Incluindo a taxa dinâmica do cartão</p>}
      </>
    );
  };
  const handlerStatus = (statusName: string) =>
    ({
      negotiated: 'Negociado',
      pending: 'Pendente',
    }[statusName] || 'Não informado');

  const renderProductName = (item: ItemProps) => {
    if (!item) return 'Sem produto';

    if (item.product_name) return item.product_name;

    const product = recurrentProducts.find(
      (product) => product.id == item.recurrent_product_id
    );

    if (product) return product.attributes.name;

    return 'Sem produto';
  };

  const renderProductDiscount = ({
    discount_kind,
    discount_value,
    price,
  }: ItemProps) => {
    if (discount_kind === 'percent') return price * (discount_value / 100);

    return discount_value;
  };

  const totalPrice = () => {
    if (hasDelayAndPenality()) {
      return price + penality.fine_price + penality.interest_price;
    } else if (discount > 0) {
      return price - discount;
    }

    return price;
  };

  return (
    <S.BillSummary>
      <div className="summary-content">
        <div>
          <span>Cobranças originais</span>
        </div>
        <div>
          <span>
            Valor cobrado
            <HelpTooltip
              size={'small'}
              text={
                <>
                  O valor total selecionado
                  <br /> para a negociação final.
                </>
              }
            />
          </span>
        </div>
      </div>
      <div className="summary-list">
        {items &&
          items.map((item) => (
            <div key={item.key} className="summary-item-wrapper">
              <div className="wrapper-item-value">
                <div className="summary-item">{handlerStatus(status)}</div>
                <div className="summary-item">
                  <Currency value={item.price === 0.0 ? 0.0 : item.price} />
                </div>
              </div>
              {item.discount_value > 0 && (
                <div className="wrapper-item-value">
                  <div className="summary-item">
                    <div className="discount-item">Desconto</div>
                  </div>
                  <div className="summary-item">
                    <div className="discount-item">
                      - <Currency value={renderProductDiscount(item)} />
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}
      </div>
      {hasDelayAndPenality() && (
        <div className="fines-interest-and-discount">
          <div className="fines">
            <p>Multa</p>
            <Currency value={penality.fine_price} />
          </div>
          <div className="interest">
            <p>Juros</p>
            <Currency value={penality.interest_price} />
          </div>
        </div>
      )}
      {discount > 0 && (
        <div className="fines-interest-and-discount">
          <div className="discount">
            <p>Desconto</p>
            <div className="discount-currency">
              <span>-</span>
              <Currency value={discount} />
            </div>
          </div>
        </div>
      )}
      <div className="summary-total">
        <div>
          <span>Total do acordo</span>
        </div>
        <div>
          <span>
            Valor cobrado
            <HelpTooltip
              size={'small'}
              text={
                <>
                  O valor total do acordo a ser cobrado, <br />
                  podendo conter parcelas.
                </>
              }
            />
            {!policies.guaranteed_monthly_fee_enabled && (
              <Currency value={totalPrice()} />
            )}
          </span>
        </div>
      </div>
      <div className="summary-list">
        {items &&
          items.map((item) => (
            <div key={item.key} className="summary-item-wrapper">
              <div className="wrapper-item-value">
                <div className="summary-item">{renderProductName(item)}</div>
                <div className="summary-item">
                  <Currency value={totalPrice()} />
                </div>
              </div>
            </div>
          ))}
      </div>
    </S.BillSummary>
  );
};

export default BillSummaryNegotiation;
