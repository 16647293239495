import React from 'react';
import { Box, DefaultThemeProps, theme } from '@agendaedu/ae-web-components';

import Skeleton from 'components/Skeleton';

export const SectionsListSkeleton = () => {
  const defaultTheme: DefaultThemeProps = theme;

  const {
    colors: { neutral },
    border: { radius },
  } = defaultTheme;

  return (
    <Box key="skeleton-item" width="auto" mt="lg">
      <Skeleton
        width="100%"
        height="50%"
        primaryColor={neutral.gray6}
        borderRadius={radius.md}
        isFullWidth
      />
    </Box>
  );
};
