import styled, { css, keyframes } from 'styled-components';
import { ThemeProps } from 'config/themes/types';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

export const Backdrop = styled.div<{ isOpen: boolean }>`
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.colors.neutral.modalBackground};
  z-index: 9999;
  animation: ${(props) => (props.isOpen ? fadeIn : fadeOut)} 0.3s ease-in-out;
  width: 100%;
  height: 100%;
`;

export const ModalContent = styled.div<{ isOpen: boolean }>`
  position: fixed;
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  background-color: black;
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  display: flex;
  flex-direction: column;
  border-radius: ${({ theme }) => theme.border.radius.lg};
  box-shadow: ${({ theme }) => theme.shadow.modal};
  transition: all 0.3s ease-in-out;
  width: 90%;
  height: auto;
  max-height: 90%;
  overflow-y: hidden;
`;

export const Image = styled.img`
  max-width: 100%;
  object-fit: contain;
`;

export const ButtonsWrapper = styled.div`
  ${({ theme: { space, breakpoints } }: ThemeProps) => css`
    display: flex;
    justify-content: space-between;
    margin-top: ${space.sm};

    @media (max-width: ${breakpoints.mobileSM}) {
    }
  `}
`;

export const ActionBtnWrapper = styled.div`
  ${({ theme: { space, breakpoints, font } }: ThemeProps) => css`
    display: flex;
    flex-wrap: wrap;
    gap: ${space.sm};

    @media (max-width: ${breakpoints.mobileSM}) {
      gap: ${space.xs2};

      button {
        font-size: ${font.size.xs};
        height: ${space.xl};
        padding: ${space.xs2};
      }
    }
  `}
`;

export const IconBtnWrapper = styled.div`
  ${({ theme: { space, breakpoints } }: ThemeProps) => css`
    display: flex;
    flex-wrap: wrap;
    gap: ${space.sm};

    @media (max-width: ${breakpoints.mobileSM}) {
      gap: ${space.xs2};

      button {
        height: ${space.xl};
        width: ${space.xl};
        padding: ${space.xs2};

        > svg {
          height: ${space.sm};
          width: ${space.sm};
        }
      }
    }
  `}
`;
