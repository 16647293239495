import { Text } from '@agendaedu/ae-web-components';
import { ThemeProps } from 'config/themes/types';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 149px;
`;

export const EmptyProfileWrapper = styled.div`
  ${({ theme: { border, colors, space } }: ThemeProps) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 117px;
    height: 117px;
    border-radius: ${border.radius.circle};
    background-color: ${colors.neutral.gray4};
    margin: ${space.sm};

    svg {
      width: 60px;
      height: 60px;
      path {
        fill: ${colors.neutral.gray2};
      }
    }
  `}
`;

export const InputButton = styled.button`
  ${({ theme: { border, colors } }: ThemeProps) => css`
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    background-color: ${colors.brand.primary.default};
    width: 38px;
    height: 38px;
    border: transparent;
    border-radius: ${border.radius.circle};
    margin-top: 128px;
    margin-left: 96px;
    z-index: 3;

    :hover {
      background-color: ${colors.brand.primary.light};
    }
    :active {
      background-color: ${colors.brand.primary.dark};
    }

    svg {
      path {
        width: 24px;
        height: 24px;
        fill: ${colors.neutral.white};
      }
    }
  `}
`;

export const Overlay = styled.div`
  ${({ theme: { border, space, colors } }: ThemeProps) => css`
    display: flex;
    width: 117px;
    height: 117px;
    margin: ${space.sm};
    border-radius: ${border.radius.circle};
    transition: opacity 0.4s ease-out;
    position: absolute;
    background-color: ${colors.neutral.black};
    opacity: 0;
    z-index: 2;
  `}
`;

export const RemoveImageButtonWrapper = styled.div`
  ${({ theme: { colors } }: ThemeProps) => css`
    display: none;
    position: absolute;
    z-index: 4;

    button {
      background-color: transparent !important;
      color: white !important;
      border-color: white !important;

      > svg {
        path {
          fill: ${colors.neutral.white} !important;
        }
      }

      :hover {
        background-color: ${colors.neutral.whiteOp10} !important;
      }
      :active {
        background-color: ${colors.neutral.whiteOp20} !important;
      }
    }
  `}
`;

export const Image = styled.img`
  ${({ theme: { border, space } }: ThemeProps) => css`
    width: 117px;
    height: 117px;
    border-radius: ${border.radius.circle};
    margin: ${space.sm};
  `}
`;

export const InputLabel = styled(Text)`
  ${({ theme: { colors, space, font } }: ThemeProps) => css`
    margin-bottom: ${space.xs2};
    color: ${colors.neutral.gray1};
    line-height: ${font.height.lg} !important;
  `}
`;

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  :hover {
    ${Overlay} {
      opacity: 60%;
    }
    ${RemoveImageButtonWrapper} {
      display: flex;
    }
  }
`;
