import React from 'react';

import TabsBoxContainer from 'components/TabsBoxContainer';
import InformationTab from './InformationTab';
import HistoryTab from './HistoryTab';
import Toast from 'components/Toast';

const TABS = [
  {
    Component: InformationTab,
  },
  {
    Component: HistoryTab,
  },
];

const ShowEnrollmentPlan = (props) => (
  <div className="ShowEnrollmentPlan">
    <TabsBoxContainer
      breadcrumb={{
        title: 'Matrículas',
        path: '/schools/school_products/tab/enrollment',
      }}
      title="Detalhes da Matrícula"
      tabs={TABS}
      {...props}
    />
    <Toast />
  </div>
);

export default ShowEnrollmentPlan;
