import React from 'react';
import PropTypes from 'prop-types';

import { Container, Card, Label, Metric } from './styles';

const MetricsContainer = ({ metrics, hideMetric }) => (
  <Container>
    {metrics.map((metric) => {
      if (hideMetric?.includes(metric.label)) return;

      return (
        <Card key={metric.label} data-testid="card">
          <Label>{metric.label}</Label>
          <Metric color={metric.color}>{metric.value}</Metric>
        </Card>
      );
    })}
  </Container>
);

MetricsContainer.propTypes = {
  metrics: PropTypes.arrayOf(PropTypes.object).isRequired,
  hideMetric: PropTypes.arrayOf(PropTypes.object),
};

export default MetricsContainer;
