import styled, { css } from 'styled-components';

import { Icon, Text } from '@agendaedu/ae-web-components';
import { ThemeProps } from 'config/themes/types';

import {
  MessageContentStyleProps,
  MessageWrapperStyleProps,
  ReplyMessageWraperStyleProps,
  WrapperStyleProps,
} from './types';

export const SentWrapper = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    flex-direction: column;
    padding: ${space.xs3} ${space.xs2};
    justify-content: center;
    gap: ${space.xs2};

    p {
      margin: ${space.xs4};
    }
  `}
`;

const replyMessageWraper = {
  sent: ({ theme: { colors } }) => css`
    ::before {
      content: '';
      width: 2px;
      background-color: ${colors.neutral.white};
    }
    ${SentWrapper} {
      p {
        color: ${colors.neutral.white};
      }
    }
  `,

  received: ({ approvalStatus, theme: { colors } }) => css`
    ::before {
      content: '';
      width: 2px;
      background-color: ${approvalStatus === 'rejected'
        ? colors.neutral.white
        : colors.neutral.gray2};
    }
    ${SentWrapper} {
      p {
        color: ${approvalStatus === 'rejected'
          ? colors.neutral.white
          : colors.neutral.black};
      }
    }
  `,
};

export const Wrapper = styled.div<WrapperStyleProps>`
  ${({
    isRedirectMessage,
    userName,
    theme: { space, colors, typography },
  }) => css`
    display: flex;
    flex-direction: column;
    gap: ${space.xs3};

    ${isRedirectMessage &&
    css`
      ::before {
        ${typography.body.Bold14};
        color: ${colors.neutral.white};
        content: '🤖 ${userName}';
      }
    `}
  `}
`;

export const MessageWrapper = styled.div<MessageWrapperStyleProps>`
  ${({ approvalStatus, deleted, sent, theme: { space, colors } }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${space.xs2};

    ${approvalStatus === 'rejected' &&
    css`
      ${MessageContent} {
        color: ${colors.neutral.white};
      }
    `}

    ${deleted &&
    css`
      ${DeletedMessageIcon} {
        display: flex;
        path {
          fill: ${colors.neutral.gray3};
        }
      }

      ${MessageContent} {
        color: ${colors.neutral.gray3} !important;
      }
    `}

    ${sent &&
    css`
      ${MessageContent} {
        margin: ${space.xs4};
        color: ${colors.neutral.white};

        &::selection {
          background: ${colors.neutral.white};
          color: ${colors.brand.primary.default};
        }
      }
    `}
  `}
`;

export const DeletedMessageIcon = styled(Icon)`
  display: none;
`;

export const MessageContent = styled(Text)<MessageContentStyleProps>`
  ${({ isMyOwnerMessage, theme: { colors, space, font } }) => css`
    white-space: pre-line;
    word-break: break-word;
    color: ${colors.neutral.black};
    margin: ${space.xs4};
    line-height: ${font.height.lg} !important;

    a {
      color: ${!isMyOwnerMessage ? '#0645ad' : '#ffffffcc'};
      text-decoration: underline;

      &:hover {
        color: ${!isMyOwnerMessage ? '#032e73' : colors.neutral.white};
      }
    }
  `}
`;

export const ReplyMessageWraper = styled.div<ReplyMessageWraperStyleProps>`
  ${({ approvalStatus, variantType, isReplyMessage, theme }) => css`
    display: flex;
    flex-direction: row;

    ${replyMessageWraper[variantType]({ theme, approvalStatus })};

    ${!isReplyMessage &&
    css`
      display: none;
    `}
  `}
`;
