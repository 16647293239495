import React, { useState } from 'react';
import PropTypes from 'prop-types';

import SideSheetFilters from 'pages/dailySummaries/SideSheetFilters';

import { Button } from '@agendaedu/ae-web-components';
import * as S from './styles';

const DailySummariesButtonAndSideSheet = ({ handleFilter, amount }) => {
  const [openSideSheet, setOpenSideSheet] = useState(false);

  const hasActiveFilters = amount > 0;

  const handleSideSheetToggle = () => setOpenSideSheet(!openSideSheet);

  return (
    <S.Wrapper isActive={hasActiveFilters}>
      <Button
        role="button"
        variant="secondary"
        aria-label="Filtros"
        isOnlyIcon
        icon="filter"
        onClick={handleSideSheetToggle}
        badges={amount}
      />
      <SideSheetFilters
        isOpen={openSideSheet}
        onDismiss={handleSideSheetToggle}
        handleFilter={handleFilter}
      />
    </S.Wrapper>
  );
};

DailySummariesButtonAndSideSheet.propTypes = {
  handleFilter: PropTypes.func.isRequired,
  amount: PropTypes.number.isRequired,
};

DailySummariesButtonAndSideSheet.defaultProps = {
  amount: 0,
};

export default DailySummariesButtonAndSideSheet;
