import React from 'react';

import { MOOD_TRACKER_ICONS, MOOD_TRACKER_TITLE } from 'core/constants';

import { MoodCardProps } from './types';

import * as S from './styles';

const MoodCard = ({ answer }: MoodCardProps) => {
  const [mood, count] = answer;
  const title = MOOD_TRACKER_TITLE[mood];

  return (
    <S.MoodCardContainer id={mood}>
      <S.TextContainer mood={mood}>
        <h3>{title}</h3>
        <h5>{count}</h5>
      </S.TextContainer>
      <img src={MOOD_TRACKER_ICONS[mood]} alt={title} />
    </S.MoodCardContainer>
  );
};

export default MoodCard;
