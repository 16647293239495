import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import AlbumActions from 'store/albums/actions';
import { useTheme } from 'styled-components';

import StudentSearch from './StudentSearch';
import Button from 'components/Button';
import Avatar from 'components/Avatar';
import StudentListSkeleton from './Skeleton';
import Checkbox from 'components/Form/Checkbox';
import { Box, DefaultThemeProps, Text } from '@agendaedu/ae-web-components';
import {
  PhotoVisibilityStudentsByHeadquarterData,
  RootState,
} from 'store/albums/types';
import { Props } from './types';

const StudentsByHeadquarterList = ({
  studentsIdsSelecteds,
  setStudentIdsSelecteds,
  photoId,
}: Props) => {
  const dispatch = useDispatch();
  const { fetchPhotoVisibilityStudentsByHeadquarter } = AlbumActions;
  const { t } = useTranslation('albums');
  const { colors, border } = useTheme() as DefaultThemeProps;

  const tBase = 'album_details.photo_details_modal';
  const [search, setSearch] = useState('');
  const {
    albumDetails,
    photoVisibilityStudentsByHeadquarter,
    isLoadingPhotoVisibilityStudentsByHeadquarter,
  } = useSelector((state: RootState) => state.albums);

  const allStudentsIds = useMemo(
    () =>
      photoVisibilityStudentsByHeadquarter.reduce(
        (prevIds, currentHeadquarter) => {
          const allIds = prevIds;

          currentHeadquarter.attributes.classrooms.forEach((classroom) => {
            classroom.studentProfiles.forEach((profile) => {
              allIds.add(profile.id);
            });
          });

          return allIds;
        },
        new Set() as Set<number>
      ),
    [photoVisibilityStudentsByHeadquarter]
  );

  const getSearchedResults = useMemo(
    () =>
      photoVisibilityStudentsByHeadquarter.reduce(
        (prevHeadquarters, currentHeadquarter) => {
          const newHeadquarterClassrooms = {
            ...currentHeadquarter,
            attributes: {
              ...currentHeadquarter.attributes,
              classrooms: currentHeadquarter.attributes.classrooms
                .map((classroom) => ({
                  ...classroom,
                  studentProfiles: classroom.studentProfiles.filter((student) =>
                    student.name.toLowerCase().includes(search.toLowerCase())
                  ),
                }))
                .filter((classroom) => classroom.studentProfiles.length),
            },
          };

          return newHeadquarterClassrooms.attributes.classrooms.some(
            (classroom) => !!classroom.studentProfiles.length
          )
            ? [...prevHeadquarters, newHeadquarterClassrooms]
            : prevHeadquarters;
        },
        []
      ),
    [photoVisibilityStudentsByHeadquarter, search]
  );

  const getStudentProfileByHeadquarters = useMemo(
    () => (search ? getSearchedResults : photoVisibilityStudentsByHeadquarter),
    [getSearchedResults, photoVisibilityStudentsByHeadquarter, search]
  );

  const onChangeStudent = (studentId: number) =>
    setStudentIdsSelecteds((prev) => {
      studentsIdsSelecteds.has(studentId)
        ? prev.delete(studentId)
        : prev.add(studentId);

      return new Set(prev);
    });

  const onChangeMultipleStudents = (
    studentProfileIds: number[],
    action: 'add' | 'remove'
  ) =>
    setStudentIdsSelecteds((prev) => {
      studentProfileIds.forEach((profileId) => {
        action === 'add' ? prev.add(profileId) : prev.delete(profileId);
      });

      return new Set(prev);
    });

  const onChangeHeadquarter = (
    classrooms: PhotoVisibilityStudentsByHeadquarterData['attributes']['classrooms']
  ) => {
    const studentProfileIds = classrooms.reduce((prevIds, currentClassroom) => {
      const classroomStudentsIds = currentClassroom.studentProfiles.map(
        ({ id }) => id
      );

      return [...prevIds, ...classroomStudentsIds];
    }, []);

    const hasHeadquarterEverySelected = studentProfileIds.every((id) =>
      studentsIdsSelecteds.has(id)
    );

    onChangeMultipleStudents(
      studentProfileIds,
      hasHeadquarterEverySelected ? 'remove' : 'add'
    );
  };

  const onChangeClassroom = (
    studentProfiles: PhotoVisibilityStudentsByHeadquarterData['attributes']['classrooms'][0]['studentProfiles']
  ) => {
    const studentProfileIds = studentProfiles.map(({ id }) => id);
    const hasClassroomEverySelected = studentProfileIds.every((id) =>
      studentsIdsSelecteds.has(id)
    );

    onChangeMultipleStudents(
      studentProfileIds,
      hasClassroomEverySelected ? 'remove' : 'add'
    );
  };

  const handleSelectAllStudents = () => {
    onChangeMultipleStudents(
      [...allStudentsIds],
      studentsIdsSelecteds.size === allStudentsIds.size ? 'remove' : 'add'
    );
  };

  const LineContainer = ({
    children,
    keyId,
    isChecked,
  }: {
    children: ReactNode;
    keyId?: string;
    isChecked: boolean;
  }) => (
    <Box
      width="100%"
      alignItems="center"
      borderTopWidth={border.width.sm}
      borderTopColor={colors.neutral.gray4}
      borderTopStyle={border.style.solid}
      borderTopRadius={border.radius.md}
      backgroundColor={isChecked ? colors.brand.primary.op10 : 'transparent'}
      py="xs2"
      px="sm"
      key={keyId}
    >
      {children}
    </Box>
  );

  const renderHeadquarter = (
    studentProfileByHeadquarter: PhotoVisibilityStudentsByHeadquarterData
  ) => {
    const {
      id,
      attributes: { name: nameHeadquarter, classrooms },
    } = studentProfileByHeadquarter;

    const isCheckedHeadquarter = classrooms.every((classroom) =>
      classroom.studentProfiles.every((student) =>
        studentsIdsSelecteds.has(student.id)
      )
    );

    return (
      <LineContainer
        isChecked={isCheckedHeadquarter}
        keyId={`headquarter-${id}`}
      >
        <Box display="flex" cursor="pointer" alignItems="center">
          <Box
            data-testid="headquarter-container"
            display="flex"
            width="100%"
            height="48px"
            alignItems="center"
            onClick={() => onChangeHeadquarter(classrooms)}
          >
            <Text
              as="span"
              variant="title-bold-14"
              color="brand.primary.default"
              whiteSpace="nowrap"
              fontWeight={700}
              textOverflow="ellipsis"
              overflow="hidden"
            >
              {nameHeadquarter}
            </Text>
          </Box>

          <Checkbox
            checked={isCheckedHeadquarter}
            onChange={() => onChangeHeadquarter(classrooms)}
          />
        </Box>
      </LineContainer>
    );
  };

  const renderClassroomsWithStudent = (
    studentProfileByHeadquarter: PhotoVisibilityStudentsByHeadquarterData
  ) => {
    const {
      id,
      attributes: { classrooms },
    } = studentProfileByHeadquarter;

    return classrooms.map(({ id: classroomId, name, studentProfiles }) => {
      const isCheckedClassroom = studentProfiles.every((student) =>
        studentsIdsSelecteds.has(student.id)
      );

      return (
        <React.Fragment key={`classroom-${classroomId}-${id}`}>
          <LineContainer isChecked={isCheckedClassroom}>
            <Box display="flex" cursor="pointer" alignItems="center">
              <Box
                data-testid="classroom-container"
                display="flex"
                width="100%"
                height="48px"
                alignItems="center"
                onClick={() => onChangeClassroom(studentProfiles)}
              >
                <Text
                  as="span"
                  variant="label-regular-14"
                  fontWeight={400}
                  color={
                    isCheckedClassroom
                      ? 'brand.primary.default'
                      : 'neutral.black'
                  }
                  verticalAlign="middle"
                  whiteSpace="nowrap"
                  textOverflow="ellipsis"
                  overflow="hidden"
                  px="sm"
                >
                  {name}
                </Text>
              </Box>
              <Checkbox
                checked={isCheckedClassroom}
                onChange={() => onChangeClassroom(studentProfiles)}
              />
            </Box>
          </LineContainer>
          {renderStudents(studentProfiles, classroomId)}
        </React.Fragment>
      );
    });
  };

  const renderStudents = (
    studentProfiles: PhotoVisibilityStudentsByHeadquarterData['attributes']['classrooms'][0]['studentProfiles'],
    classroomId: number
  ) => {
    return studentProfiles.map(
      ({ name, id: profileId, avatarColor, photoUrl, nameInitials }) => {
        const profile = {
          id: profileId,
          type: 'student_profile',
          attributes: {
            name: name,
            avatar_color: avatarColor,
            name_initials: nameInitials,
            avatar_url: photoUrl,
          },
        };

        const isChecked = studentsIdsSelecteds.has(profileId);

        return (
          <LineContainer
            isChecked={isChecked}
            key={`classroom-${classroomId}-student-${profileId}`}
          >
            <Box display="flex" cursor="pointer" alignItems="center" ml="10%">
              <Box
                display="flex"
                width="100%"
                height="48px"
                alignItems="center"
                overflowY="hidden"
                data-testid="student-container"
                onClick={() => onChangeStudent(profileId)}
              >
                <Box display="flex">
                  <Avatar user={profile} />
                </Box>

                <Text
                  as="span"
                  variant="label-regular-14"
                  fontWeight={400}
                  color={isChecked ? 'brand.primary.default' : 'neutral.black'}
                  verticalAlign="middle"
                  title={name}
                  whiteSpace="nowrap"
                  textOverflow="ellipsis"
                  overflow="hidden"
                  px="sm"
                >
                  {name}
                </Text>
              </Box>

              <Checkbox
                checked={isChecked}
                onChange={() => onChangeStudent(profileId)}
              />
            </Box>
          </LineContainer>
        );
      }
    );
  };

  useEffect(() => {
    if (photoId) {
      dispatch(
        fetchPhotoVisibilityStudentsByHeadquarter({
          albumId: albumDetails.id,
          photoId,
        })
      );
    }
  }, [
    albumDetails.id,
    dispatch,
    fetchPhotoVisibilityStudentsByHeadquarter,
    photoId,
  ]);

  return (
    <Box
      mx="xs2"
      minWidth={{ mobileXS: 300, tabletMD: 400 }}
      maxHeight="70vh"
      overflow="hidden"
    >
      {isLoadingPhotoVisibilityStudentsByHeadquarter ? (
        <StudentListSkeleton />
      ) : (
        <Box mt="md">
          <Box
            display="flex"
            mb="lg"
            justifyContent="space-between"
            alignItems="center"
          >
            <Text variant="subtitle-medium-12" color="neutral.black" mt="sm">
              {t(`${tBase}.students`, {
                selectedCount: studentsIdsSelecteds.size,
                allCount: allStudentsIds.size,
              })}
            </Text>
            <Button
              variation="secondary"
              size="btn-sm"
              onClick={handleSelectAllStudents}
            >
              {studentsIdsSelecteds.size === allStudentsIds.size
                ? t(`${tBase}.unselect_all`)
                : t(`${tBase}.select_all`)}
            </Button>
          </Box>

          <Box
            maxHeight="55vh"
            borderWidth={border.width.sm}
            borderColor={colors.neutral.gray4}
            borderStyle={border.style.solid}
            borderRadius={border.radius.md}
            overflow="auto"
          >
            <StudentSearch
              search={search}
              setSearch={setSearch}
              placeholder={t(`${tBase}.search`)}
            />

            <Box width="100%">
              {getStudentProfileByHeadquarters.map(
                (studentProfileByHeadquarter) => (
                  <React.Fragment
                    key={`studentProfileByHeadquarter-${studentProfileByHeadquarter.id}`}
                  >
                    {renderHeadquarter(studentProfileByHeadquarter)}
                    {renderClassroomsWithStudent(studentProfileByHeadquarter)}
                  </React.Fragment>
                )
              )}
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default StudentsByHeadquarterList;
