import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as S from './styles';

import withAppContext from 'core/hoc/withAppContext';

import omniChannelActions from 'store/messages/omniChannel/actions';
import { OmniChannelState } from 'store/messages/omniChannel/types';

import { Button } from '@agendaedu/ae-web-components';
import { SideModal } from 'components/Messages/OmniChannel/Modals/SideModal';
import { MessagesHeader } from '../../Messages/MessagesHeader';
import { ChannelInformationDetail } from './Content/ChannelInformationDetail';
import { TicketInformationDetail } from './Content/TicketInformationDetail';

import { OwnerProps } from './types';

const ChatDetailModal = ({ appContext: { currentUser } }: OwnerProps) => {
  const { t } = useTranslation(['messages']);
  const dispatch = useDispatch();

  const isMasterUser = currentUser.attributes.role === 'master';

  const { activeChannel, showSideDetailsModal } = useSelector(
    (state: OmniChannelState) => state.omniChannel
  );

  const { toggleReportMessagesModal, toggleSideDetailsModal } =
    omniChannelActions;

  const handleToggleDetailsModal = useCallback(() => {
    dispatch(toggleSideDetailsModal());
  }, [dispatch, toggleSideDetailsModal]);

  const handleToggleReportMessages = useCallback(() => {
    dispatch(toggleReportMessagesModal());
  }, [dispatch, toggleReportMessagesModal]);

  const isTicketChannel = activeChannel.kind === 'ticket';

  return (
    <SideModal.Root
      isOpen={showSideDetailsModal}
      title={
        isTicketChannel
          ? t('omni_channel.modals.ticket_detail.title')
          : t('omni_channel.modals.details_chat.title')
      }
      handleClose={handleToggleDetailsModal}
    >
      <S.HeaderWrapper>
        <MessagesHeader isReadOnly />
      </S.HeaderWrapper>
      {isTicketChannel ? (
        <TicketInformationDetail />
      ) : (
        <ChannelInformationDetail />
      )}
      <SideModal.Actions>
        <Button variant="secondary" onClick={handleToggleDetailsModal}>
          {t('omni_channel.modals.details_chat.close_button')}
        </Button>

        {isMasterUser && (
          <Button
            testId="report-deleted-msg"
            onClick={handleToggleReportMessages}
          >
            {t(
              'omni_channel.modals.details_chat.export_deleted_msgs.export_button'
            )}
          </Button>
        )}
      </SideModal.Actions>
    </SideModal.Root>
  );
};

export default withAppContext(ChatDetailModal);
