import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';

import SummarydayClassroomsActions from 'store/summarydayClassrooms/actions';
import SummarydayClassroomContext from 'core/contexts/SummarydayClassroom';
import useGoogleDrive from 'core/hooks/useGoogleDrive';
import Toast from 'components/Toast';
import Loader from 'components/Loader';
import moment from 'moment/moment';
import launchDarkly from 'core/helper/launchDarkly';
import { flags } from 'core/constants/flags';
import FormFullScreen from 'components/FormFullScreen';
import RecipientsTab from 'components/Form/RecipientsTab';
import GeneralInformation from 'pages/SummarydayClassrooms/FormTabs/GeneralInformation';

const New = ({ googleClientId }) => {
  const dispatch = useDispatch();
  const { addSummarydayClassrooms, fetchDisciplines, setDuplicateActivity } =
    SummarydayClassroomsActions;

  const {
    formMeta,
    isLoading,
    isSending,
    current,
    duplicateActivity,
    idDuplicateActivity,
  } = useSelector((state) => state.summarydayClassrooms);
  const dataArea = useSelector((state) => state.root.dataArea);
  const [cookies] = useCookies(['agendaedu_launch_darkly']);

  const activeFlags = cookies.agendaedu_launch_darkly?.active_flags;

  useEffect(() => {
    if (idDuplicateActivity) {
      dispatch(setDuplicateActivity(idDuplicateActivity));
    } else {
      dispatch(fetchDisciplines());
    }
  }, [idDuplicateActivity]);

  const onSubmit = ({ form }) => {
    const newForm = {
      ...form,
      attachments: form.attachments.map((file) => file.signed_id),
      deadline: moment.isMoment(form.deadline)
        ? form.deadline
            .hours(23)
            .minutes(59)
            .seconds(59)
            .format('YYYY-MM-DD HH:mm:ss')
        : form.deadline,
    };

    dispatch(addSummarydayClassrooms(newForm));
  };

  const actions = useGoogleDrive(
    googleClientId,
    'https://www.googleapis.com/auth/drive.readonly'
  );

  const payload = idDuplicateActivity ? duplicateActivity : current;

  return (
    <Loader isLoading={isLoading}>
      <SummarydayClassroomContext.Provider value={actions}>
        <FormFullScreen
          id="summaryday-classrooms-create"
          action="new"
          onSubmit={onSubmit}
          form={payload}
          formMeta={{
            ...formMeta,
            googleClientId,
            select_options: {
              classroom_id: formMeta.classrooms,
            },
            student_profiles: payload?.student_profiles,
          }}
          isSubmitting={isSending}
          steps={[GeneralInformation, RecipientsTab]}
          backTo={`/${dataArea}/summaryday_classrooms`}
          backToExternal
          titlePage="Nova atividade"
          titleModal="Descartar preenchimento"
          contentModal="As informações já preenchidas não serão salvas até que você as envie, e essa ação não pode ser desfeita. Tem certeza que deseja descartar?"
        />
      </SummarydayClassroomContext.Provider>
      <Toast />
    </Loader>
  );
};

New.propTypes = {
  googleClientId: PropTypes.string.isRequired,
  match: PropTypes.object,
};

export default New;
