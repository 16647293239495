import styled from 'styled-components';

export const Container = styled.div`
  .Button {
    border-radius: 8px;
  }

  .empty-state-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 16px;

    @media (min-width: 768px) {
      justify-content: start;
    }
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: 415px;
  text-align: center;

  @media (min-width: 768px) {
    text-align: start;
    margin-left: 48px;
  }
`;

export const Title = styled.h4`
  font-family: 'Quicksand', sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 170%;
  color: ${({ theme }) => theme.black};
`;

export const Text = styled.span`
  display: block;
  margin-bottom: 24px;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 170%;
  color: ${({ theme }) => theme.gray2};
`;
