import { Text } from '@agendaedu/ae-web-components';
import { GroupIconInput } from 'components/Messages/MessageGroup/GroupIconInput';
import { ThemeProps } from 'config/themes/types';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme: { space, breakpoints } }: ThemeProps) => css`
    display: flex;
    max-width: ${breakpoints.tabletMD};
    margin: 0 auto;
    margin-top: ${space.xl5};
    flex-direction: column;

    @media screen and (max-width: ${breakpoints.tabletMD}) {
      margin: 0;
    }
  `}
`;

export const GroupHeaderWrapper = styled.div`
  ${({ theme: { space, breakpoints } }: ThemeProps) => css`
    display: flex;
    margin: ${space.xl2} 0;
    flex-direction: row;
    gap: ${space.xl4};

    @media screen and (max-width: ${breakpoints.tabletMD}) {
      flex-direction: column;
      gap: ${space.md};
      align-items: center;
      margin-top: ${space.lg};
      margin-bottom: ${space.xl};
    }
  `}
`;

export const GroupIcon = styled(GroupIconInput)`
  ${({ theme: { typography, colors, breakpoints } }: ThemeProps) => css`
    p {
      ${typography.subtitle.Medium14};
      color: ${colors.neutral.gray2};
    }

    @media screen and (max-width: ${breakpoints.tabletMD}) {
      p {
        display: none;
      }
    }
  `}
`;

export const GroupIconMembers = styled.div`
  margin-bottom: -8px;
  > svg {
    width: 40px !important;
    height: 40px !important;
  }
`;

export const GroupMembersWrapper = styled.div`
  ${({ theme: { space, breakpoints } }: ThemeProps) => css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: ${breakpoints.tabletMD}) {
      flex-direction: column;
      align-items: baseline;
      gap: ${space.md};
      a {
        width: 100%;
      }
    }
  `}
`;

export const GroupListWrapper = styled.div`
  ${({ theme: { space, breakpoints } }: ThemeProps) => css`
    display: flex;
    flex-direction: column;
    margin: ${space.xl2} 0;
    .infinite-scroll-component {
      overflow: visible !important;
    }

    @media screen and (max-width: ${breakpoints.desktopXL}) {
      margin: ${space.md} 0;
      overflow: hidden;
    }
  `}
`;

export const GroupTagWrapper = styled.div`
  ${({ theme: { breakpoints } }: ThemeProps) => css`
    display: none;

    @media screen and (max-width: ${breakpoints.tabletMD}) {
      display: flex;
    }
  `}
`;

export const GroupTitleLabel = styled(Text)`
  ${({ theme: { breakpoints, typography, colors, font } }: ThemeProps) => css`
    ${typography.subtitle.Medium14};
    color: ${colors.neutral.gray2};
    margin-bottom: 0;
    line-height: ${font.height.lg} !important;

    @media screen and (max-width: ${breakpoints.tabletMD}) {
      display: none;
    }
  `}
`;

export const GroupTitle = styled(Text)`
  ${({ theme: { breakpoints, colors, font } }: ThemeProps) => css`
    color: ${colors.neutral.gray1};
    line-height: ${font.height.lg} !important;
    margin-bottom: 0;

    @media screen and (max-width: ${breakpoints.tabletMD}) {
      color: ${colors.neutral.black} !important;
      font-size: ${font.size.lg} !important;
      font-weight: ${font.weight.regular} !important;
      text-align: center;
    }
  `}
`;

export const GroupInformationTitle = styled(Text)`
  ${({ theme: { breakpoints, colors } }: ThemeProps) => css`
    color: ${colors.neutral.black};
    font-weight: 700;
    margin-bottom: 0;

    @media screen and (max-width: ${breakpoints.tabletMD}) {
      display: none;
    }
  `}
`;
