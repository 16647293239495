import React from 'react';

import withAppContext from 'core/hoc/withAppContext';
import './style.scss';

const SkeletonItemList = ({ children, isLoading }) => {
  if (children && !isLoading) {
    return children;
  }

  return (
    <div className="skeleton-item">
      <div className="skeleton-info">
        <p className="skeleton-info-title-start" />
        <p className="skeleton-info-value-start" />
      </div>

      <div className="skeleton-info-center">
        <p className="skeleton-info-title-center" />
        <p className="skeleton-info-value-center" />
      </div>

      <div className="skeleton-info-center">
        <p className="skeleton-info-title-center" />
        <p className="skeleton-info-value-center" />
      </div>
    </div>
  );
};

export default withAppContext(SkeletonItemList);
