import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar, Text, Modal } from '@agendaedu/ae-web-components';
import { calendarDateDescription } from 'core/utils/date';

import * as S from './styles';

import { HistoryProps } from './type';
import { deletedUserAvatarInfos } from 'core/constants/user';

export const History = ({ versions }: HistoryProps) => {
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const historyVersions = versions.slice(0).reverse();

  const { t } = useTranslation(['diary', 'common']);

  const toggleHistoryModal = () => setShowHistoryModal(!showHistoryModal);

  const normalizedVersions = historyVersions.map((version) => ({
    ...version,
    attributes: {
      ...version.attributes,
      event: version.attributes.event.replace(/\./g, '_'),
      schoolUser: version.attributes.schoolUser || deletedUserAvatarInfos,
    },
  }));

  const {
    attributes: { schoolUser, createdAt, event },
  } = normalizedVersions[0];

  return (
    <>
      <S.HistoryWrapper>
        <S.AvatarWrapper>
          <Avatar
            avatarColor={schoolUser.avatarColor}
            avatarUrl={schoolUser.avatarUrl}
            nameInitials={schoolUser.nameInitials}
          />

          <S.LabelWrapper>
            <Text variant="title-bold-12" color="neutral.gray1" mb={0}>
              {t(`details.versions.events.${event}`, {
                user: schoolUser.name,
              })}
            </Text>
            <Text variant="subtitle-medium-12" color="neutral.gray2" mb={0}>
              {calendarDateDescription(createdAt)}
            </Text>
          </S.LabelWrapper>
        </S.AvatarWrapper>

        <S.HistoryButton
          testId="hour-history-btn"
          icon="hour-history"
          onClick={toggleHistoryModal}
        >
          {t('details.versions.title')}
        </S.HistoryButton>
      </S.HistoryWrapper>

      {showHistoryModal && (
        <Modal
          isOpen={showHistoryModal}
          onClose={toggleHistoryModal}
          title={t('details.versions.modal_title')}
        >
          <S.UserHistoryWrapper>
            {normalizedVersions.map((version, key) => (
              <S.AvatarWrapper key={key}>
                <Avatar
                  avatarColor={version.attributes.schoolUser.avatarColor}
                  avatarUrl={version.attributes.schoolUser.avatarUrl}
                  nameInitials={version.attributes.schoolUser.nameInitials}
                />

                <S.LabelWrapper>
                  <Text variant="title-bold-12" color="neutral.gray1" mb={0}>
                    {t(`details.versions.events.${version.attributes.event}`, {
                      user: version.attributes.schoolUser.name,
                    })}
                  </Text>
                  <Text
                    variant="subtitle-medium-12"
                    color="neutral.gray2"
                    mb={0}
                  >
                    {calendarDateDescription(version.attributes.createdAt)}
                  </Text>
                </S.LabelWrapper>
              </S.AvatarWrapper>
            ))}
          </S.UserHistoryWrapper>
        </Modal>
      )}
    </>
  );
};
