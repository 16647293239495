import React from 'react';
import tabifyForWizard from 'core/hoc/tabifyForWizard';
import withFormContext from 'core/hoc/withFormContext';
import FormFieldset from 'components/Form/Fieldset';
import WalletConfiguration from '../FormSections/WalletConfiguration';

import { NegotiationFormInformationsTabProps } from '../types';
import Field from 'components/Form/Field';
import DerivativeRecurrentBills from '../FormSections/DerivativeRecurrentBills';
import OriginalRecurrentBills from '../FormSections/OriginalRecurrentBills';
import Tooltip from 'components/Tooltip';
import Alert from 'components/Alert';
import AgendaIcon from 'components/AgendaIcon';

import validations from './validations';
import * as S from './styles';

const NegotiationFormInformationsTab = ({
  formContext: {
    formMeta: { derivativeRecurrentBills },
  },
}: NegotiationFormInformationsTabProps) => {
  return (
    <S.Container>
      <FormFieldset>
        <S.Title fontSize="20px">Informações das cobranças negociadas</S.Title>
        <S.TooltipContainer>
          <S.Title>Carteira de destino</S.Title>
          <Tooltip
            content={
              <>
                Carteira que será direcionada
                <br /> as cobranças pagas.
              </>
            }
          >
            <AgendaIcon name="help" size="medium" />
          </Tooltip>
        </S.TooltipContainer>
        <WalletConfiguration />

        <S.TooltipContainer>
          <S.Title>Dados do RF</S.Title>
          <Tooltip
            content={
              <>
                Responsável financeiro cadastrado <br /> e responsável pelo
                aluno.
              </>
            }
          >
            <AgendaIcon name="help" size="medium" />
          </Tooltip>
        </S.TooltipContainer>
        <div className="row">
          <Field label="Nome" attributeName="name" disabled />
        </div>
        <div className="row">
          <Field
            label="CPF"
            attributeName="cpf"
            type="masked"
            mask="999.999.999-99"
            disabled
          />
        </div>
        {derivativeRecurrentBills?.length > 0 && (
          <S.ContainerDerivativeBills>
            <Alert variation="info">
              Informamos que a nova tabela de Cobrança de Renegociação não
              afetará a negociação em questão. Para saber mais, acesse nossa{' '}
              <a
                href="https://suporte.agendaedu.com/hc/pt-br"
                target={'_blank'}
                rel="noreferrer"
              >
                central de ajuda.
              </a>
            </Alert>
            <DerivativeRecurrentBills />
          </S.ContainerDerivativeBills>
        )}
        <S.Title>Cobranças originais</S.Title>
        <OriginalRecurrentBills />
        <br />
        <S.Paragraph>*Campos de preenchimento obrigatório</S.Paragraph>
      </FormFieldset>
    </S.Container>
  );
};

export default tabifyForWizard({
  title: '1. Informações',
  subTitle: '',
  validations: validations,
})(withFormContext(NegotiationFormInformationsTab));
