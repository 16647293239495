import styled, { css } from 'styled-components';

import { ThemeProps } from 'config/themes/types';
import { ButtonStyleProps } from './types';

export const ButtonsWrappers = styled.section`
  ${({ theme: { space, breakpoints, font } }: ThemeProps) => css`
    display: flex;
    flex-direction: row;
    margin-top: ${space.xl};
    gap: ${space.xl};

    @media (max-width: ${breakpoints.desktopLG}) {
      flex-direction: column;
      gap: ${space.lg};

      strong {
        font-size: ${font.size.md};
      }

      span {
        font-size: ${font.size.sm};
      }
    }
  `}
`;

export const Button = styled.button<ButtonStyleProps>`
  ${({ theme: { border, colors, space, breakpoints }, disabled }) => css`
    display: flex;
    background-color: transparent;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    max-width: 354px;
    max-height: 330px;
    width: 100%;
    gap: ${space.xl};
    border-radius: ${space.xs};
    border: 1px ${border.style.solid} ${colors.neutral.gray4};
    padding: ${space.lg} ${space.xl};
    cursor: pointer;

    ${disabled &&
    css`
      background-color: ${colors.neutral.gray5};

      ${Title} {
        color: ${colors.neutral.gray2};
      }

      ${Description} {
        color: ${colors.neutral.gray2};
      }
    `}

    transition: background-color 0.25s ease;

    ${!disabled &&
    css`
      &:hover {
        border-color: ${colors.brand.primary.op10};
        background-color: ${colors.brand.primary.op10};
      }

      &:active {
        border-color: ${colors.brand.primary.op20};
        background-color: ${colors.brand.primary.op20};
      }
    `}

    @media (max-width: ${breakpoints.desktopLG}) {
      max-width: 330px;
      max-height: 330px;
      gap: ${space.lg};
      text-align: center;
    }
  `}
`;

export const InfomationWrapper = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: ${space.lg};
  `}
`;

export const Image = styled.img`
  height: 84px;
  width: 72px;
`;

export const Title = styled.strong`
  ${({ theme: { colors, typography } }: ThemeProps) => css`
    ${typography.title.Bold20}
    color: ${colors.neutral.black}
  `}
`;

export const Description = styled.span`
  ${({ theme: { colors, typography } }: ThemeProps) => css`
    ${typography.label.Regular16}
    color: ${colors.neutral.gray1};
    text-align: center;
  `}
`;
