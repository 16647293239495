import React, { useCallback, useEffect, useState } from 'react';

import * as S from './styles';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import withAppContext from 'core/hoc/withAppContext';
import HandoutTemplateContext from 'core/contexts/Handout/Templates';

import actions from 'store/handouts/actions';
import { HandoutState } from 'store/handouts/types';

import FormFullScreen from 'components/FormFullScreen';
import Informations from '../Steps/Informations';
import { HandoutTemplateSkeleton } from '../Steps/Informations/skeleton';

const WIZARD_STEPS = [Informations];

import { Props } from './types';

import Toast from 'components/Toast';
import TemplateEditModal from 'components/Handouts/TemplateEditModal';

const EditHandoutTemplate: React.FC<Props> = ({
  appContext: { dataArea },
  match,
}) => {
  const { t } = useTranslation(['handouts']);
  const dispatch = useDispatch();

  const { id: templateId } = match?.params;

  const [initialForm, setInitialForm] = useState(null);

  const {
    fetchCurrentHandoutTemplateRequest,
    editHandoutTemplateRequest,
    toggleEditHandoutTemplateModal,
  } = actions;

  const { isSending, currentHandoutTemplate } = useSelector(
    (state: HandoutState) => state.handouts
  );

  const handleFetchCurrentHandoutTemplate = useCallback(() => {
    dispatch(fetchCurrentHandoutTemplateRequest(templateId));
  }, [dispatch, fetchCurrentHandoutTemplateRequest, templateId]);

  const handleEditHandoutTemplate = useCallback(
    (form) => {
      dispatch(editHandoutTemplateRequest(form, templateId));
    },
    [dispatch, editHandoutTemplateRequest, templateId]
  );

  const handleToggleShowEditModal = useCallback(() => {
    dispatch(toggleEditHandoutTemplateModal());
  }, [dispatch, toggleEditHandoutTemplateModal]);

  useEffect(() => {
    handleFetchCurrentHandoutTemplate();
  }, [handleFetchCurrentHandoutTemplate, templateId]);

  useEffect(() => {
    if (currentHandoutTemplate) {
      const { title, handoutTitle, description, categoryId, coverImage } =
        currentHandoutTemplate?.attributes;

      setInitialForm({
        templateTitle: title,
        title: handoutTitle,
        description: description,
        categoryId: categoryId,
        coverImage: coverImage,
      });
    }
  }, [currentHandoutTemplate]);

  return (
    <S.HandoutsFormContainer>
      <HandoutTemplateContext.Provider value={null}>
        {initialForm ? (
          <FormFullScreen
            action="edit"
            id="editHandoutTemplate"
            form={initialForm}
            initialAttributes={{}}
            formMeta={{}}
            titlePage={t('templates.form.edit_title')}
            backTo={`/${dataArea}/handouts/templates`}
            backToExternal
            isSubmitting={isSending}
            steps={WIZARD_STEPS}
            onSubmit={handleToggleShowEditModal}
            titleModal={t('modals.handout_form_discard.title')}
            contentModal={t('modals.handout_form_discard.description')}
          >
            <TemplateEditModal
              handleToggle={handleToggleShowEditModal}
              handleOnSubmit={handleEditHandoutTemplate}
            />
          </FormFullScreen>
        ) : (
          <HandoutTemplateSkeleton dataArea={dataArea} />
        )}
      </HandoutTemplateContext.Provider>

      <Toast />
    </S.HandoutsFormContainer>
  );
};

export default withAppContext(EditHandoutTemplate);
