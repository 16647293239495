import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { deleteApi, fetchApi, patchApi, postApi, putApi } from 'core/utils/api';

import actions from './actions';
import {
  buildToast,
  toastCss,
  ToastTypes,
} from 'store/middlewares/toast/actions';
import i18n from 'config/i18n';
import {
  fetchStoragesQueryUrl,
  normalizeFetchCurrentStorageSuccess,
  normalizeFetchStoragesSuccess,
  normalizeSaveDocumentRequest,
  normalizeSaveEditDocumentRequest,
  normalizeSaveEditFileRequest,
  normalizeSaveEditFolderRequest,
  normalizeSaveFileRequest,
  normalizeSaveFolderRequest,
} from './normalizes';

import { ActionsSaga, StorageRoot } from './types';

function* fetchCurrentStorageSaga(
  action: ActionsSaga['fetchCurrentStorageSaga']
) {
  try {
    const { dataArea } = yield select((state) => state.root);

    const {
      data,
      included,
      meta: { folderHierarchyPath },
    } = yield call(
      fetchApi,
      `/${dataArea}/artifacts/${action.storageId}.json?fields[artifact]=parentFolderId,versions,title,kind,description,availableTo,receiverType,classrooms,student_profiles,attachments,createdAt,updatedAt,userPermissions&include=classrooms,studentProfiles,versions`
    );

    const { paths: folderPath, storage } = normalizeFetchCurrentStorageSuccess(
      folderHierarchyPath,
      data,
      included
    );

    yield put({
      type: actions.FETCH_CURRENT_STORAGE_SUCCESS,
      storage,
      folderPath,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_CURRENT_STORAGE_ERROR, error });
  }
}

function* fetchCurrentStorageFormSaga(
  action: ActionsSaga['fetchCurrentStorageFormSaga']
) {
  try {
    const { dataArea } = yield select((state) => state.root);

    const {
      data,
      meta: { folderHierarchyPath },
      included,
    } = yield call(
      fetchApi,
      `/${dataArea}/artifacts/${action.storageId}.json?include=classrooms,studentProfiles,versions`
    );

    const { paths: folderPath, storage } = normalizeFetchCurrentStorageSuccess(
      folderHierarchyPath,
      data,
      included
    );

    const folderAscendingId = storage.attributes.parentFolderId;

    if (folderAscendingId && action.kind === 'edit')
      yield put({
        type: actions.FETCH_RECIPIENTS_CLASSROOMS,
        folderAscendingId,
      });

    yield put({
      type: actions.FETCH_CURRENT_STORAGE_FORM_SUCCESS,
      storage,
      folderPath,
      kind: action.kind,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_CURRENT_STORAGE_FORM_ERROR, error });
  }
}

function* fetchRecipientsClassroomsSaga(
  action: ActionsSaga['fetchRecipientsClassroomsSaga']
) {
  try {
    const { dataArea } = yield select((state) => state.root);

    let response;

    if (action.folderAscendingId) {
      response = yield call(
        fetchApi,
        `/${dataArea}/artifacts/receivers/classrooms`,
        { params: { folder_id: action.folderAscendingId } }
      );
    } else {
      response = yield call(
        fetchApi,
        `/${dataArea}/artifacts/receivers/classrooms`
      );
    }

    yield put({
      type: actions.FETCH_RECIPIENTS_CLASSROOMS_SUCCESS,
      data: response.history,
    });
  } catch (error) {
    yield put({ type: actions.ERROR, error });
  }
}

function* fetchStoragesSaga(action: ActionsSaga['fetchStoragesRequest']) {
  try {
    const { dataArea } = yield select((state) => state.root);

    const {
      storageList: { filters },
    } = yield select((state) => state.storage);

    const url = fetchStoragesQueryUrl(action, filters);

    const {
      data,
      included,
      meta: { next, page: currentPage, count: totalCount },
    } = yield call(fetchApi, `/${dataArea}/artifacts.json?${url}`);

    const storages = normalizeFetchStoragesSuccess(data, included);

    yield put({
      type: actions.FETCH_STORAGES_SUCCESS,
      storages,
      next,
      currentPage,
      totalCount,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_STORAGES_ERROR, error });
  }
}

function* saveDocumentRequestSaga(
  action: ActionsSaga['saveDocumentRequestSaga']
) {
  try {
    const { dataArea } = yield select((state) => state.root);

    const normalizeParams = normalizeSaveDocumentRequest(action);

    yield call(postApi, `/${dataArea}/artifacts`, { ...normalizeParams });

    yield put(
      push(`/${dataArea}/artifacts/folders/${action.params.folderAscendingId}`)
    );

    yield put({
      type: actions.SAVE_DOCUMENT_SUCCESS,
      toast: buildToast(
        i18n.t('storage:document_form.save_document_success'),
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.SAVE_DOCUMENT_ERROR,
      error,
      toast: buildToast(
        i18n.t('storage:document_form.save_document_error'),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* saveEditDocumentRequestSaga(
  action: ActionsSaga['saveEditDocumentRequestSaga']
) {
  try {
    const { dataArea } = yield select((state) => state.root);

    const {
      storageForm: {
        current: {
          attributes: { parentFolderId },
        },
      },
    } = yield select((state) => state.storage);

    const normalizeParams = normalizeSaveEditDocumentRequest(
      action,
      parentFolderId
    );

    yield call(
      patchApi,
      `/${dataArea}/artifacts/${action.params.currentStorageId}`,
      { ...normalizeParams }
    );

    yield put(push(`/${dataArea}/artifacts/folders/${parentFolderId}`));

    yield put({
      type: actions.SAVE_EDIT_DOCUMENT_SUCCESS,
      toast: buildToast(
        i18n.t('storage:document_form.save_edit_document_success'),
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.SAVE_EDIT_DOCUMENT_ERROR,
      error,
      toast: buildToast(
        i18n.t('storage:document_form.save_edit_document_error'),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* saveEditFolderRequestSaga(
  action: ActionsSaga['saveEditFolderRequestSaga']
) {
  try {
    const { dataArea } = yield select((state) => state.root);
    const {
      storageForm: {
        current: {
          attributes: { parentFolderId },
        },
      },
    } = yield select((state) => state.storage);

    const normalizeParams = normalizeSaveEditFolderRequest(
      action,
      parentFolderId
    );

    yield call(
      patchApi,
      `/${dataArea}/artifacts/${action.params.currentStorageId}`,
      {
        ...normalizeParams,
      }
    );

    if (parentFolderId) {
      yield put(push(`/${dataArea}/artifacts/folders/${parentFolderId}`));
    } else {
      yield put(push(`/${dataArea}/artifacts`));
    }

    yield put({
      type: actions.SAVE_EDIT_FOLDER_SUCCESS,
      toast: buildToast(
        i18n.t('storage:folder_form.save_edit_folder_success'),
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.SAVE_EDIT_FOLDER_ERROR,
      error,
      toast: buildToast(
        i18n.t('storage:folder_form.save_edit_folder_error'),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* saveEditFileRequestSaga(
  action: ActionsSaga['saveEditFileRequestSaga']
) {
  try {
    const { dataArea } = yield select((state) => state.root);

    const {
      storageForm: {
        current: {
          attributes: { attachments: oldAttachments, parentFolderId },
        },
      },
    } = yield select((state) => state.storage);

    const oldAttachmentSignedId = oldAttachments[0].signed_id;
    const newAttachmentSignedId = action.params.attachments[0].signedId;

    const normalizeParams = normalizeSaveEditFileRequest(
      action,
      parentFolderId
    );

    yield call(
      putApi,
      `/${dataArea}/artifacts/${action.params.currentStorageId}`,
      { ...normalizeParams }
    );

    if (oldAttachmentSignedId !== newAttachmentSignedId)
      yield call(
        deleteApi,
        `/${dataArea}/direct_uploads/${oldAttachmentSignedId}.json`
      );

    yield put(push(`/${dataArea}/artifacts/folders/${parentFolderId}`));

    yield put({
      type: actions.SAVE_EDIT_FILE_SUCCESS,
      toast: buildToast(
        i18n.t('storage:file_form.save_edit_file_success'),
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.SAVE_EDIT_FILE_ERROR,
      error,
      toast: buildToast(
        i18n.t('storage:file_form.save_edit_file_error'),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* saveFileRequestSaga(action: ActionsSaga['saveFileRequestSaga']) {
  try {
    const { params } = action;
    const { dataArea } = yield select((state) => state.root);

    const attachments = [];

    if (params?.attachments) {
      params.attachments.map((attachment) => {
        attachments.push(attachment.signedId);
      });
    }

    const normalizeParams = normalizeSaveFileRequest(action);

    yield call(postApi, `/${dataArea}/artifacts`, { ...normalizeParams });

    yield put(
      push(`/${dataArea}/artifacts/folders/${action.params.folderAscendingId}`)
    );

    yield put({
      type: actions.SAVE_FILE_SUCCESS,
      toast: buildToast(
        i18n.t('storage:file_form.save_file_success'),
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.SAVE_FILE_ERROR,
      error,
      toast: buildToast(
        i18n.t('storage:file_form.save_file_error'),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* saveFolderRequestSaga(action: ActionsSaga['saveFolderRequestSaga']) {
  try {
    const { dataArea } = yield select((state) => state.root);

    const normalizeParams = normalizeSaveFolderRequest(action);

    yield call(postApi, `/${dataArea}/artifacts`, { ...normalizeParams });

    if (action.params.folderAscendingId) {
      yield put(
        push(
          `/${dataArea}/artifacts/folders/${action.params.folderAscendingId}`
        )
      );
    } else {
      yield put(push(`/${dataArea}/artifacts`));
    }

    yield put({
      type: actions.SAVE_FOLDER_SUCCESS,
      toast: buildToast(
        i18n.t('storage:folder_form.save_folder_success'),
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.SAVE_FOLDER_ERROR,
      error,
      toast: buildToast(
        i18n.t('storage:folder_form.save_folder_error'),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* setDeleteStorageRequestSaga(
  action: ActionsSaga['setDeleteStorageRequestSaga']
) {
  const {
    id,
    attributes: { kind },
  } = action.storage;

  const message = {
    folder: [
      i18n.t('storage:requests_message.delete_folder_success'),
      i18n.t('storage:requests_message.delete_folder_error'),
    ],
    document: [
      i18n.t('storage:requests_message.delete_document_success'),
      i18n.t('storage:requests_message.delete_document_error'),
    ],
    file: [
      i18n.t('storage:requests_message.delete_file_success'),
      i18n.t('storage:requests_message.delete_file_error'),
    ],
  };

  const currentRequestMessage = message[kind];

  try {
    const { dataArea } = yield select((state) => state.root);

    const { common } = yield select((state: StorageRoot) => state.storage);

    yield call(deleteApi, `/${dataArea}/artifacts/${id}`);

    const folder = common?.folderPath?.prevFolder?.id;

    if (action.redirectRootPage && folder)
      yield put(push(`/${dataArea}/artifacts/folders/${folder}`));

    if (action.redirectRootPage && !folder)
      yield put(push(`/${dataArea}/artifacts`));

    yield put({
      type: actions.SET_DELETE_STORAGE_SUCCESS,
      toast: buildToast(
        currentRequestMessage[0],
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.SET_DELETE_STORAGE_ERROR,
      error,
      toast: buildToast(
        currentRequestMessage[1],
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* storage() {
  yield all([
    takeLatest(actions.FETCH_CURRENT_STORAGE_REQUEST, fetchCurrentStorageSaga),
    takeLatest(
      actions.FETCH_CURRENT_STORAGE_FORM_REQUEST,
      fetchCurrentStorageFormSaga
    ),
    takeLatest(
      actions.FETCH_RECIPIENTS_CLASSROOMS,
      fetchRecipientsClassroomsSaga
    ),
    takeLatest(actions.FETCH_STORAGES_REQUEST, fetchStoragesSaga),
    takeLatest(actions.SAVE_DOCUMENT_REQUEST, saveDocumentRequestSaga),
    takeLatest(actions.SAVE_EDIT_DOCUMENT_REQUEST, saveEditDocumentRequestSaga),
    takeLatest(actions.SAVE_EDIT_FILE_REQUEST, saveEditFileRequestSaga),
    takeLatest(actions.SAVE_EDIT_FOLDER_REQUEST, saveEditFolderRequestSaga),
    takeLatest(actions.SAVE_FILE_REQUEST, saveFileRequestSaga),
    takeLatest(actions.SAVE_FOLDER_REQUEST, saveFolderRequestSaga),
    takeLatest(actions.SET_DELETE_STORAGE_REQUEST, setDeleteStorageRequestSaga),
  ]);
}

export default storage;
