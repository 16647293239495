import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${() => css`
    .ButtonRow {
      display: flex;
      flex-direction: row;
      gap: 24px;
    }

    .DropdownButton {
      > .Button {
        padding: 20px 4px;
      }
    }

    .customMetadata {
      margin-left: auto;
    }
  `}
`;
