import { DiaryFormStructure } from 'store/dailySummaries/types';

export const defaultMeal = (): DiaryFormStructure['meal'] => ({
  category: '',
  description: '',
  not_ate: false,
  key: Math.random().toString(36).substring(7),
});

export const defaultOccurrence = (): DiaryFormStructure['occurrence'] => ({
  category: '',
  description: '',
  files: [],
  key: Math.random().toString(36).substring(7),
});

export const defaultBabyBottles = (): DiaryFormStructure['baby_bottles'] => ({
  not_drink: false,
  quantities: [{ quantity: '' }],
});

export const defaultSleeps = (): DiaryFormStructure['sleeps'] => ({
  not_slept: false,
  periods: [
    {
      key: Math.random().toString(36).substring(7),
      start_time: '',
      end_time: '',
    },
  ],
});

export const defaultEvacuations = (): DiaryFormStructure['evacuations'] => ({
  not_evacuated: false,
  appearances: [{ evacuation: '' }],
});

export const defaultSectionGroupValues: Record<
  keyof Pick<DiaryFormStructure['studentsForm'], 'meals' | 'occurrences'>,
  () => Record<string, unknown>
> = {
  meals: () => defaultMeal(),
  occurrences: () => defaultOccurrence(),
};

export const defaultAnswersAttributesValues = (
  diary_question_id: string
): DiaryFormStructure['answers_attributes_values'] => ({
  key: Math.random().toString(36).substring(7),
  diary_question_id,
  section_group_id: '',
  diary_answer_option_id: '',
  answer_text: '',
});

export const validFilesTypes = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/heic',
  'image/heif',
  'application/pdf',
];
