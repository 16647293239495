import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { theme, DefaultThemeProps } from '@agendaedu/ae-web-components';

import useWindowDimensions from 'core/hooks/useWindowDimensions';
import withAppContext from 'core/hoc/withAppContext';

import actionOmniChannels from 'store/messages/omniChannel/actions';
import { Channel, OmniChannelState } from 'store/messages/omniChannel/types';

import ChannelCard from '../ChannelCard';
import { ChannelCardSkeleton } from '../ChannelCard/skeleton';
import { EmptyState } from './emptyState';

import * as S from './styles';

const ChannelList = (): JSX.Element => {
  const dispatch = useDispatch();

  const defaultTheme: DefaultThemeProps = theme;

  const { setUpdateChannelsOrderRequest } = actionOmniChannels;

  const [isDragging, setIsDragging] = useState(false);
  const [draggingIndex, setDraggingIndex] = useState(null);

  const { screenWidth, screenHeight } = useWindowDimensions();

  const { channels, isLoadingChannels } = useSelector(
    (state: OmniChannelState) => state.omniChannel
  );

  const changeIsDragging = (value: boolean, draggingIndex: number): void => {
    setIsDragging(value);
    setDraggingIndex(draggingIndex);
  };

  const changeSortingList = (newChannelsOrders: Channel[]) => {
    const isSorting =
      JSON.stringify(newChannelsOrders) !== JSON.stringify(channels);

    if (isSorting) {
      const channelsIds = newChannelsOrders.map((channel) => channel.id);
      dispatch(setUpdateChannelsOrderRequest(channelsIds));
    }
  };

  return (
    <>
      {channels && !isLoadingChannels ? (
        <S.SortableWrapper>
          <S.Sortable
            height={screenHeight}
            list={channels}
            setList={changeSortingList}
            animation={200}
            scroll={true}
            disabled={
              screenWidth < parseInt(defaultTheme.breakpoints.desktopLG)
            }
            onStart={(event) => changeIsDragging(true, event.oldDraggableIndex)}
            onEnd={() => changeIsDragging(false, null)}
          >
            {channels.map((channel, index) => (
              <ChannelCard
                id={`channel-card-${index}`}
                key={index}
                channel={channel}
                isDragging={isDragging}
                draggingCard={draggingIndex === index}
              />
            ))}
          </S.Sortable>
        </S.SortableWrapper>
      ) : (
        <>
          {isLoadingChannels ? (
            <ChannelCardSkeleton amount={6} />
          ) : (
            <EmptyState />
          )}
        </>
      )}
    </>
  );
};
export default withAppContext(ChannelList);
