import {
  Box,
  Button,
  CardList,
  DefaultThemeProps,
  FlexGrid,
  FlexGridItem,
  Tag,
  Text,
  theme,
  SubtleIconButton,
} from '@agendaedu/ae-web-components';
import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import parse from 'html-react-parser';
import EmptyState from 'components/EmptyState';
import Toast from 'components/Toast';
import ModalButtons from 'components/Modal/ModalButtons';
import ModalContainer from 'components/Modal/ModalContainer';
import { CONTRACT_MODEL_STATUS, IMAGES_PAYMENT_URL } from 'core/constants';
import actionEnrollmentPlans from 'store/edupay/enrollmentPlans/actions';
import { ContractModelListSkeleton } from './skeleton';
import { RootState, ActionsParams } from './types';
import * as S from './styles';

const ContractModelList = () => {
  const defaultTheme: DefaultThemeProps = theme;
  const { space, colors } = defaultTheme;
  const { t } = useTranslation(['enrollments']);
  const dispatch = useDispatch();

  const [selectedModel, setSelectedModel] = useState(null);

  const {
    setContractModels,
    setCancelContractModelRequest,
    setEnableContractModelRequest,
  } = actionEnrollmentPlans;

  const { isLoading, contractModels, modelsPaginate } = useSelector(
    (state: RootState) => state.enrollmentPlans
  );

  const closeModal = () => setSelectedModel(null);

  const disableContractModel = (modelId) => {
    dispatch(setCancelContractModelRequest(modelId));
    closeModal();
  };

  const enableContractModel = (modelId) => {
    dispatch(setEnableContractModelRequest(modelId));
  };

  const confirmModalButtons = (modelId) => [
    {
      text: t('contract_models_list.modal_confirm_action.button_back'),
      variation: 'secondary',
      onClick: closeModal,
      path: '#',
    },
    {
      text: t('contract_models_list.modal_confirm_action.button_confirm'),
      variation: 'danger',
      onClick: () => disableContractModel(modelId),
      path: '#',
    },
  ];

  const getActions = ({ id, title, url, status }: ActionsParams) => {
    const actions = [];

    const buttonActions = [
      {
        title: t('button.action.download'),
        onClick: () => window.open(url),
      },
      status === 'active'
        ? {
            title: t('button.action.disable'),
            onClick: () => setSelectedModel({ id, title }),
          }
        : {
            title: t('button.action.active'),
            onClick: () => enableContractModel(id),
          },
    ];

    for (const action of buttonActions) {
      actions.push({
        as: 'button',
        ...action,
      });
    }

    return actions;
  };

  const hasMore = useMemo(() => {
    const { totalItemsCount, itemsCountPerPage, activePage } = modelsPaginate;
    if (totalItemsCount && itemsCountPerPage) {
      const totalPages = totalItemsCount / itemsCountPerPage;
      return activePage < totalPages;
    }
    return true;
  }, [modelsPaginate]);

  const fetchList = () => {
    if (!isLoading && hasMore) {
      dispatch(setContractModels({ page: modelsPaginate.activePage + 1 }));
    }
  };

  useEffect(() => {
    fetchList();
  }, []);

  const renderContractModelList = () => {
    if (!isLoading && !contractModels?.length)
      return (
        <EmptyState
          message={
            parse(t('contract_models_list.empty_state')) as string | JSX.Element
          }
          imgUrl={IMAGES_PAYMENT_URL.enrollmentEmptyStateFilterUrl}
        />
      );
    return (
      <CardList
        mb={space.sm}
        pb={space.xl}
        pt={space.xl}
        hasMore={hasMore}
        loadMore={() => fetchList()}
        loader={isLoading && <ContractModelListSkeleton />}
        initialLoad={true}
        threshold={200}
      >
        {contractModels?.map(
          ({
            id,
            attributes: {
              title,
              status,
              attachment: { url },
            },
          }) => (
            <FlexGrid data-testid="list-card" key={id}>
              <FlexGridItem cols={{ desktopLG: 9 }} filled>
                <Text
                  color={colors.neutral.gray1}
                  variant="subtitle-medium-16"
                  mb={0}
                >
                  {title}
                </Text>
              </FlexGridItem>
              <FlexGridItem cols={{ desktopLG: 2 }} filled>
                <Tag
                  name={CONTRACT_MODEL_STATUS[status]?.label}
                  size="small"
                  variant={CONTRACT_MODEL_STATUS[status]?.variant}
                />
              </FlexGridItem>
              <FlexGridItem cols={{ desktopLG: 1 }} filled>
                <SubtleIconButton
                  actions={getActions({ id, title, url, status })}
                ></SubtleIconButton>
              </FlexGridItem>
            </FlexGrid>
          )
        )}
      </CardList>
    );
  };

  return (
    <S.ContractModelListWrapper>
      <S.InfoWrapper>
        <S.BackButton to="/schools/school_products/tab/enrollment">{`< ${t(
          'contract_models_list.back_button'
        )}`}</S.BackButton>
        <Box display="flex" justifyContent="space-between">
          <Text color={colors.neutral.black} variant="headline-h2-bold-24">
            {t('contract_models_list.title')}
          </Text>
          <Link to="/schools/enrollment_plans/contract_templates/new">
            <Button variant="primary">{t('common.new_model')}</Button>
          </Link>
        </Box>
      </S.InfoWrapper>
      {renderContractModelList()}

      {!!selectedModel && (
        <ModalContainer
          isOpen={!!selectedModel}
          toggleModal={closeModal}
          title={t('contract_models_list.modal_confirm_action.title')}
          maxWidth="490px"
        >
          <p className="sub-title-modal">
            {parse(
              t('contract_models_list.modal_confirm_action.info', {
                title: selectedModel?.title,
              })
            )}
          </p>

          <div className="button-container-modal">
            <ModalButtons buttons={confirmModalButtons(selectedModel?.id)} />
          </div>
        </ModalContainer>
      )}

      <Toast />
    </S.ContractModelListWrapper>
  );
};

export default ContractModelList;
