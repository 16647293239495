const actions = {
  FETCH_REQUEST_AVAILABILATIES:
    'FETCH_REQUEST_AVAILABILATIES/paymentAvailabilities',
  FETCH_SUCCESS_AVAILABILATIES:
    'FETCH_SUCCESS_AVAILABILATIES/paymentAvailabilities',
  FETCH_REQUEST_RECEIVABLES: 'FETCH_REQUEST_RECEIVABLES/paymentAvailabilities',
  FETCH_SUCCESS_RECEIVABLES: 'FETCH_SUCCESS_RECEIVABLES/paymentAvailabilities',
  FETCH_REQUEST_GUARANTEED_MONTHLY_FEE:
    'FETCH_REQUEST_GUARANTEED_MONTHLY_FEE/paymentAvailabilities',
  FETCH_SUCCESS_GUARANTEED_MONTHLY_FEE:
    'FETCH_SUCCESS_GUARANTEED_MONTHLY_FEE/paymentAvailabilities',
  FETCH_PAYMENTS: 'FETCH_PAYMENTS/paymentAvailabilities',
  FETCH_SUCCESS_PAYMENTS: 'FETCH_SUCCESS_PAYMENTS/paymentAvailabilities',
  SHOW_REQUEST_AVAILABILATY: 'SHOW_REQUEST_AVAILABILATY/paymentAvailabilities',
  SHOW_SUCCESS_AVAILABILATY: 'SHOW_SUCCESS_AVAILABILATY/paymentAvailabilities',
  TOGGLE_MODAL: 'TOGGLE_MODAL/paymentAvailabilities',
  FETCH_ERROR: 'FETCH_ERROR/paymentAvailabilities',
  fetchAvailabilities: (filter) => ({
    type: actions.FETCH_REQUEST_AVAILABILATIES,
    filter,
  }),
  fetchReceivables: (filter) => ({
    type: actions.FETCH_REQUEST_RECEIVABLES,
    filter,
  }),
  fetchGuaranteedMonthlyFee: (filter) => ({
    type: actions.FETCH_REQUEST_GUARANTEED_MONTHLY_FEE,
    filter,
  }),
  fetchPayments: (date, page = 1, typeQuery = 'school', hasMore = false) => ({
    type: actions.FETCH_PAYMENTS,
    date,
    page,
    typeQuery,
    hasMore,
  }),
  showAvailability: (date, availabilatyId) => ({
    type: actions.SHOW_REQUEST_AVAILABILATY,
    date,
    availabilatyId,
  }),
  toggleModal: (date) => ({ type: actions.TOGGLE_MODAL, date }),
};

export default actions;
