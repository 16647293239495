import { ThemeProps } from 'config/themes/types';
import styled, { css } from 'styled-components';

export const ChannelHeaderWrapper = styled.div`
  user-select: none;
  display: flex;
  flex-direction: column;

  ${({ theme: { space, border, colors, breakpoints } }: ThemeProps) => css`
    padding: ${space.xl} ${space.lg};
    gap: ${space.lg};
    border-bottom: ${border.width.sm} ${border.style.solid}
      ${colors.neutral.gray4};

    @media (max-width: ${breakpoints.desktopLG}) {
      & .popup-content {
        visibility: hidden;
      }
    }
  `};
`;

export const HeaderAction = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const HeaderTitle = styled.h2`
  ${({ theme: { font, colors, space } }: ThemeProps) => css`
    margin: ${space.xs4};
    font-weight: ${font.weight.bold};
    font-size: ${font.size.xl};
    color: ${colors.neutral.black};
    font-family: ${font.family.brand};
  `}
`;

export const HeaderSubTitle = styled.h3`
  ${({ theme: { font, colors, space } }: ThemeProps) => css`
    margin: ${space.xs4};
    font-weight: ${font.weight.bold};
    font-size: ${font.size.lg};
    color: ${colors.neutral.black};
    font-family: ${font.family.brand};
  `}
`;
