import React from 'react';
import PropTypes from 'prop-types';
import FormFullScreen from 'components/FormFullScreen';
// Tabs
import SurveyFormInformationsTab from './InformationsTab';
import SurveyFormQuestionsPollTab from './QuestionsPollTab';
import SurveyFormQuestionsAuthorizationTab from './QuestionsAuthorizationTab';
import RecipientsTab from 'components/Form/RecipientsTab';

import { titleMobile } from 'core/utils/changeText';

import './style.scss';

const SurveyForm = ({
  action,
  onSubmit,
  form,
  formMeta,
  isSubmitting,
  backTo,
  titlePage,
  titleModal,
  contentModal,
  kind,
}) => {
  const SurveyFormRecipientsTab = RecipientsTab;
  SurveyFormRecipientsTab.title = titleMobile('3 Destinatários');

  const WIZARD_STEPS = [
    SurveyFormInformationsTab,
    kind === 'poll'
      ? SurveyFormQuestionsPollTab
      : SurveyFormQuestionsAuthorizationTab,
    SurveyFormRecipientsTab,
  ];

  const defaultForm =
    kind === 'authorization' && action === 'new'
      ? {
          ...form,
          kind: 'authorization',
          persona_type: 'responsibles',
          survey_questions_attributes: [
            {
              position: '1',
              question_0: 'Você autoriza?',
              question_type: 'unique',
              required: true,
              survey_options_attributes: [
                {
                  position: '1',
                  title: 'Autorizo',
                  others: false,
                  key: Math.random().toString(36).substring(7),
                },
                {
                  position: '2',
                  title: 'Não autorizo',
                  others: false,
                  key: Math.random().toString(36).substring(7),
                },
              ],
              key: Math.random().toString(36).substring(7),
            },
          ],
        }
      : { ...form, kind };

  return (
    <div className="SurveyForm">
      <FormFullScreen
        id={`new-${kind}`}
        action={action}
        onSubmit={onSubmit}
        form={defaultForm}
        formMeta={formMeta}
        isSubmitting={isSubmitting}
        steps={WIZARD_STEPS}
        backTo={backTo}
        titlePage={titlePage}
        titleModal={titleModal}
        contentModal={contentModal}
      />
    </div>
  );
};

SurveyForm.propTypes = {
  action: PropTypes.oneOf(['new', 'edit']).isRequired,
  form: PropTypes.object.isRequired,
  formMeta: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  backTo: PropTypes.string,
  titlePage: PropTypes.string,
  titleModal: PropTypes.string,
  contentModal: PropTypes.string,
  kind: PropTypes.string,
};

export default SurveyForm;
