import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import withAppContext from 'core/hoc/withAppContext';
import withFormContext from 'core/hoc/withFormContext';

import Toast from 'components/Toast';
import SkeletonClassroomsPage from 'components/SummarydayClassrooms/components/SkeletonClassrooms';
import storeActions from '../../store/dynamicModule/actions';
import Loader from 'components/Loader';
import ResponsiveEmbed from './ResponsiveIframe';

import * as S from './styles';

// eslint-disable-next-line react/prop-types
const DynamicModule = ({ match }) => {
  const dispatch = useDispatch();

  const { id } = match.params;

  const { requestUrl } = storeActions;

  const { url, fullScreen, isLoading } = useSelector(
    (state) => state.dynamicModule
  );

  const changeWrapperBackgroundColor = () => {
    if (window.location.pathname.indexOf('/dynamic_modules/') != -1) {
      document.getElementById('main-content').style.backgroundColor = '#ffffff';
    }
  };
  changeWrapperBackgroundColor();

  const EmbedFullScreen = useCallback((screen) => {
    const menu = document.getElementById('sidebar');
    const content = document.getElementById('main-content');

    if (screen === 'smallScreen') {
      content.classList.remove('merge-left');
      menu.classList.remove('hide-left-bar');
    } else {
      content.classList.add('merge-left');
      menu.classList.add('hide-left-bar');
    }
  }, []);

  useEffect(() => {
    const initialSreen =
      window.innerWidth <= 992 ? 'smallScreen' : 'largeScreen';
    if (fullScreen) EmbedFullScreen(initialSreen);

    const windowSizeHandler = () => {
      const currentScreen =
        window.innerWidth <= 992 ? 'smallScreen' : 'largeScreen';
      if (fullScreen) EmbedFullScreen(currentScreen);
    };
    window.addEventListener('resize', windowSizeHandler);

    return () => {
      window.removeEventListener('resize', windowSizeHandler);
    };
  }, [EmbedFullScreen, fullScreen]);

  useEffect(() => {
    dispatch(requestUrl(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id]);

  const ref = React.useRef();

  return (
    <Loader
      isLoading={isLoading}
      customLoadElement={<SkeletonClassroomsPage />}
    >
      <ResponsiveEmbed src={url} ratio="10:9" />
      <Toast />
    </Loader>
  );
};

export default withAppContext(withFormContext(DynamicModule));
