import { ThemeProps } from 'config/themes/types';
import styled, { css } from 'styled-components';

import {
  ChannelResizeWrapperStyleProps,
  ChannelWrapperStyleProps,
  WrapperStyleProps,
} from './types';

export const Wrapper = styled.div<WrapperStyleProps>`
  ${({ activeChannel }) => css`
    display: flex;
    flex-direction: column;

    ${!activeChannel &&
    css`
      width: 100%;
    `}
  `}
`;

export const ChannelWrapper = styled.div<ChannelWrapperStyleProps>`
  ${({ activeChannel, theme: { breakpoints, border, colors } }) => css`
    display: flex;
    flex-direction: row;
    width: 100%;
    border-right: ${border.width.sm} ${border.style.solid}
      ${colors.neutral.gray4};
    @media (max-width: ${breakpoints.desktopLG}) {
      display: ${activeChannel && 'none'};
    }
  `}
`;

export const ChannelResizeWrapper = styled.div<ChannelResizeWrapperStyleProps>`
  display: flex;
  flex-direction: column;

  max-width: 400px;
  min-width: 260px;
  width: 260px;

  ${({ theme: { colors, breakpoints } }: ThemeProps) => css`
    @media (max-width: ${breakpoints.desktopLG}) {
      max-width: none !important;
      width: 100% !important;
    }
    height: 100vh;

    background: ${colors.neutral.white};
  `}
`;

export const ResizeWindow = styled.div`
  ${({ theme: { colors, space, breakpoints } }: ThemeProps) => css`
    @media (min-width: ${breakpoints.desktopLG}) {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: 1px;
      background-color: ${colors.neutral.gray4};
      justify-self: flex-end;
      cursor: col-resize;
      position: relative;
      transition: background 0.4s ease;

      :active,
      :hover {
        background: ${colors.brand.primary.op45};
        cursor: col-resize;
        flex-basis: 4px;
      }
      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        opacity: 0;
        pointer-events: none;
      }

      &:hover::after {
        width: ${space.xs3};
        opacity: 1;
      }
    }
  `}
`;
