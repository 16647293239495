import styled, { css } from 'styled-components';
import { ThemeProps } from 'config/themes/types';
import { Box } from '@agendaedu/ae-web-components';

export const MainContainer = styled.section`
  ${({ theme: { space } }: ThemeProps) => css`
    margin: ${space.xl} ${space.xl5};
  `}
`;

export const BoxEllipsis = styled(Box)`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;
