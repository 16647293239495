import React from 'react';
import PropTypes from 'prop-types';
import * as S from './styles';

import Field from 'components/Form/Field';
import FormFieldset from 'components/Form/Fieldset';
import Title from 'components/WizardForm/Title';
import ZipcodeService from 'core/services/utils/zipcode';

import tabifyForWizard from 'core/hoc/tabifyForWizard';
import withFormContext, { formPropTypes } from 'core/hoc/withFormContext';

import validations from './validations';

const ZIP_CODE_REGEX = /[0-9]{5}-[0-9]{3}/;

const PaymentsSchoolWalletFormSchoolInformationsTab = ({
  formContext: {
    updateAttribute,
    updateFormContext,
    addErrorOnAttribute,
    removeErrorOnAttribute,
    form,
    formMeta: {
      select_options: { state },
    },
  },
}) => {
  const fetchAddressFromZipcode = async ({ cep }) => {
    if (!ZIP_CODE_REGEX.test(cep)) return event;

    try {
      const zipcodeService = new ZipcodeService(cep);

      const { street, neighborhood, city, state } =
        await zipcodeService.fetchAddress();

      updateFormContext(({ prevFormState, buildFormState }) => {
        const newStreet = prevFormState.state || street;
        const newNeighborhood = prevFormState.neighborhood || neighborhood;
        const newCity = prevFormState.city || city;
        const newState = prevFormState.state || state;

        const newFormState = buildFormState(prevFormState, {
          street: newStreet,
          street_number: '',
          neighborhood: newNeighborhood,
          city: newCity,
          state: newState,
        });

        removeErrorOnAttribute('cep');
        return newFormState;
      });
    } catch (error) {
      addErrorOnAttribute('cep', 'Insira um CEP válido');
    }
  };

  const formattedArray = (array) => {
    const formattedArray = array.map((current) => ({
      label: current.name,
      value: current.value,
    }));

    formattedArray.unshift({ value: null, label: 'Selecione um estado' });

    return formattedArray;
  };

  return (
    <S.SchoolInformationsWrapper>
      <FormFieldset>
        <Field
          label="Administrador legal*"
          placeholder="Nome do administrador legal"
          attributeName="legal_name"
        />
        <Field
          type="date"
          label="Data de Nascimento*"
          attributeName="date_of_birth"
          showAllDays
          withYearSelect
          placeholder="DD/MM/AAAA"
        />
        <Field
          label="E-mail*"
          attributeName="email"
          placeholder="Digite o e-mail"
        />
        <Field
          label="CPF*"
          attributeName="admin_document_number"
          type="masked"
          mask="999.999.999-99"
          placeholder="000.000.000-00"
        />
        <S.TitleWrapper>
          <Title name="Informações da escola" />
          <S.InputWrapper>
            <Field
              label="Nome da escola*"
              placeholder="Digitar nome da escola"
              attributeName="school_name"
            />
            <Field
              type="masked"
              mask="(99) 99999-9999"
              label="Telefone da escola*"
              placeholder="(DDD) + Número"
              attributeName="phone"
            />
          </S.InputWrapper>
        </S.TitleWrapper>
        <S.RowWrapper>
          <Field
            type="masked"
            label="CEP*"
            mask="99999-999"
            attributeName="cep"
            placeholder="00000-000"
            onChange={fetchAddressFromZipcode}
          />
          <S.LinkWrapper
            className="link-correios"
            href="http://www.buscacep.correios.com.br/sistemas/buscacep/default.cfm"
            target="_blank"
            rel="noopener noreferrer"
          >
            Não sei meu CEP
          </S.LinkWrapper>
        </S.RowWrapper>
        <S.RowWrapper>
          <Field
            label="Endereço*"
            attributeName="street"
            placeholder="Seu endereço"
          />
          <S.MiddleWrapper>
            <Field label="Nº*" attributeName="street_number" placeholder="Nº" />
          </S.MiddleWrapper>
        </S.RowWrapper>
        <S.RowWrapper>
          <Field
            label="Estado*"
            attributeName="state"
            type="selectWithIcon"
            options={formattedArray(state)}
            value={form.state}
            onChange={(e) => updateAttribute('state', e.value)}
            withArrowSelect
            fullWidth
          />
          <Field
            label="Cidade*"
            attributeName="city"
            placeholder="Sua cidade"
          />
        </S.RowWrapper>
        <Field
          label="Bairro*"
          attributeName="neighborhood"
          placeholder="Seu bairro"
        />
        <Field
          label="Complemento"
          attributeName="complement"
          placeholder="Complemento"
        />
        <S.RequireItemsMessage>
          *Campos de preenchimento obrigatório
        </S.RequireItemsMessage>
      </FormFieldset>
    </S.SchoolInformationsWrapper>
  );
};

PaymentsSchoolWalletFormSchoolInformationsTab.propTypes = {
  ...formPropTypes,
  formContext: PropTypes.shape({
    updateAttribute: PropTypes.func.isRequired,
    updateFormContext: PropTypes.func.isRequired,
    addErrorOnAttribute: PropTypes.func.isRequired,
    removeErrorOnAttribute: PropTypes.func.isRequired,
    formMeta: PropTypes.shape({
      select_options: PropTypes.shape({
        state: PropTypes.array,
      }),
    }).isRequired,
  }),
};

export default tabifyForWizard({
  title: '2. Administrador',
  subTitle: 'Informações do administrador',
  validations,
})(withFormContext(PaymentsSchoolWalletFormSchoolInformationsTab));
