import createModuleReducer from '../storeConfig/createModuleReducer';

// actions
import actions from './actions';

/** Initial state */
export const INITIAL_STATE = {
  isLoading: false,
  url: '',
  fullScreen: false,
};

/** Reducer */
export const updateDynamicUrl = (state, action) => {
  const { url, fullScreen } = action;

  return {
    ...state,
    url: url,
    fullScreen: fullScreen,
    isLoading: false,
  };
};

const HANDLERS = {
  [actions.REQUEST_URL_SUCCESS]: updateDynamicUrl,
};

const dynamicModule = createModuleReducer(INITIAL_STATE, HANDLERS);

export default dynamicModule;
