import React, { useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import Loader from 'components/Loader';
import ModalTitle from 'components/Modal/ModalTitle';
import ModalSubTitle from 'components/Modal/ModalSubTitle';

import ReportActions from 'store/reports/actions';

import * as S from './styles';

const ReportDetailsModal = ({ isOpen, toggleModal, id }) => {
  const dispatch = useDispatch();
  const isMounted = useRef(false);

  const { fetchReportDetails } = ReportActions;

  const { isFetching, selectedReport: report } = useSelector(
    (state) => state.reports
  );

  const buildChartUrl = (id) => {
    return `https://analytics.agendaedu.com/question/${id}`;
  };

  const renderChartLink = (id) => {
    const chartString = buildChartUrl(id);

    return (
      <a
        href={chartString}
        target="_blank"
        aria-label="Link do gráfico"
        rel="noreferrer"
      >
        {chartString}
      </a>
    );
  };

  const onHandleDetails = useCallback(
    (reportId) => {
      dispatch(fetchReportDetails(reportId));
    },
    [id]
  );

  useEffect(() => {
    if (isMounted.current) {
      onHandleDetails(id);
    } else {
      isMounted.current = true;
    }
  }, [id]);

  return (
    <S.ReportModalContainer
      aria-label="Detalhes do relatório"
      isOpen={isOpen}
      toggleModal={toggleModal}
      maxWidth="600px"
    >
      <Loader isLoading={isFetching}>
        <ModalTitle>{report?.data.attributes.title}</ModalTitle>
        <ModalSubTitle
          aria-label="Descrição do relatório"
          subTitle="Descrição"
          description={report?.data.attributes.description}
        />

        <ul aria-label="Lista de gráficos">
          {report?.included?.map((chart, index) => {
            const { title, metabase_question_id } = chart?.attributes;

            return (
              <li key={index} aria-label="Gráfico">
                <S.ChartTitle aria-label="Nome do gráfico">
                  {title}
                </S.ChartTitle>
                <S.ChartUrl aria-label="Informações">
                  {renderChartLink(metabase_question_id)}
                </S.ChartUrl>
              </li>
            );
          })}
        </ul>
      </Loader>
    </S.ReportModalContainer>
  );
};

ReportDetailsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
};

export default ReportDetailsModal;
