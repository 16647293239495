import React from 'react';
import { ActionButtonProps } from './types';

import * as S from './styles';

const ActionButton = ({ name, handleOnClick, testId }: ActionButtonProps) => {
  return (
    <S.ActionsButtonWrapper onClick={handleOnClick} data-testid={testId}>
      <S.IconButton name={name} />
    </S.ActionsButtonWrapper>
  );
};

export default ActionButton;
