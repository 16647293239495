import axios from 'axios';
import { extractBody } from 'core/utils/request';

export default class SchoolTermService {
  /**
   * @param {string} dataArea One of the user's data area. Eg.: schools.
   */
  constructor(dataArea) {
    this.dataArea = dataArea;
  }

  /**
   * @api private
   * @param {Object} school_terms
   * @param {Array<Object>}
   */
  async fetchSchoolTerms() {
    try {
      const response = await axios.get(`/schools/school_terms.json`);
      const { data: schoolTerms } = extractBody(response);
      return { schoolTerms };
    } catch (error) {
      throw error;
    }
  }

  async fetchSchoolTerm(schoolTermId) {
    try {
      const response = await axios.get(
        `/${this.dataArea}/school_terms/${schoolTermId}.json`
      );
      const { data: schoolTerm } = extractBody(response);
      return { schoolTerm };
    } catch (error) {
      throw error;
    }
  }

  /**
   * @api public
   * @param {Object} formState
   */
  async create(formState) {
    try {
      const response = await axios.post(
        `/${this.dataArea}/school_terms/`,
        formState
      );

      const schoolTerm = response.data.data;

      return schoolTerm;
    } catch (error) {
      throw error;
    }
  }

  /**
   * @api private
   * @param {String, Number} schoolTermId
   */
  async activeSchoolTerm(schoolTermId) {
    try {
      const response = await axios.put(
        `/${this.dataArea}/school_terms/${schoolTermId}/activate.json`
      );

      const {
        data: schoolTerm,
        meta: {
          old_school_term_active: { data: oldSchoolTermActive },
        },
      } = extractBody(response);

      return {
        schoolTerm,
        oldSchoolTermActive,
      };
    } catch (error) {
      throw error;
    }
  }

  async fetchActiveSchoolTerm() {
    try {
      const response = await axios.get(
        `/${this.dataArea}/school_terms/active.json`
      );
      const { active, old } = extractBody(response);
      const activeSchoolTerm = active.data;
      const oldSchoolTerm = old.data;
      return { activeSchoolTerm, oldSchoolTerm };
    } catch (error) {
      throw error;
    }
  }

  async update({ schoolTermId, formState }) {
    try {
      const response = await axios.put(
        `/${this.dataArea}/school_terms/${schoolTermId}`,
        formState
      );
      const { data: schoolTerm } = extractBody(response);

      return { schoolTerm };
    } catch (error) {
      throw error;
    }
  }
}
