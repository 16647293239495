import React, { Component } from 'react';
import PropTypes from 'prop-types';

import FormValidationErrors from 'components/Form/ValidationErrors';

import withFormContext, { formPropTypes } from 'core/hoc/withFormContext';

import './style.scss';

/**
 * TODO: Change this component to use react-select
 * We just have to adjust the onChange function and use:
 *
 * ```
 *    getOptionLabel = option => option.name;
 * ```
 *
 * Though I believe adjusting the right endpoint to use `option.label` is the
 * right approach.
 */
class FormSelect extends Component {
  static propTypes = {
    getOptionLabel: PropTypes.func,
    getOptionValue: PropTypes.func,
    attributeName: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    ...formPropTypes,
  };

  static defaultProps = {
    disabled: false,
    placeholder: 'Selecione uma opção',
    controlled: false,
  };

  emptySelectOptions = [];

  getOptionLabel(option) {
    return (
      (this.props.getOptionLabel && this.props.getOptionLabel(option)) ||
      this.defaultGetLabel(option)
    );
  }

  defaultGetLabel(option) {
    return option.name;
  }

  getOptionValue(option) {
    return (
      (this.props.getOptionValue && this.props.getOptionValue(option)) ||
      this.defaultGetValue(option)
    );
  }

  defaultGetValue(option) {
    return option.value;
  }

  /**
   * @returns {Function} The function that handles the event.
   */
  getOnChange() {
    const { onChange, attributeName, formContext } = this.props;
    const { changeAttribute } = formContext;

    if (onChange) {
      return changeAttribute(attributeName, (formState) => onChange(formState));
    } else {
      return changeAttribute(attributeName);
    }
  }

  getValue() {
    const { defaultValue, formContext, controlled, attributeName } = this.props;

    if (controlled) {
      return defaultValue;
    }

    return formContext.form[attributeName];
  }

  render() {
    const { attributeName, placeholder, formContext, disabled, defaultValue } =
      this.props;
    const { form, getOptionsForSelect, hasErrorOnAttribute } = formContext;

    const hasError = hasErrorOnAttribute(attributeName);

    const selectOptions =
      getOptionsForSelect(attributeName) || this.emptySelectOptions;

    return (
      <div className="Select">
        <select
          className={`form-control ${form[attributeName] ? 'selected' : ''}`}
          name={attributeName}
          onChange={this.getOnChange()}
          value={this.getValue()}
          disabled={disabled}
        >
          {placeholder && <option value="">{placeholder}</option>}
          {selectOptions.map((option) => (
            <option
              key={`${attributeName}_${this.getOptionValue(option)}`}
              value={this.getOptionValue(option)}
            >
              {this.getOptionLabel(option)}
            </option>
          ))}
        </select>
        {hasError && <FormValidationErrors attributeName={attributeName} />}
      </div>
    );
  }
}

export default withFormContext(FormSelect);
