const recurrentStatus = {
  billet_paid: {
    text: 'Pago via boleto',
    variation: 'success',
  },
  credit_card_paid: {
    text: 'Pago via cartão',
    variation: 'success',
  },
  manually_paid: {
    text: 'Pago na escola',
    variation: 'success',
  },
  pix_paid: {
    text: 'Pago via PIX',
    variation: 'success',
  },
  paid_late: {
    text: 'Pago atrasado',
    variation: 'success',
  },
  paid_divergent: {
    text: 'Valor divergente',
    variation: 'warning',
  },
  pending: {
    text: 'Não enviado',
    variation: 'default',
  },
  released: {
    text: 'Em aberto',
    variation: 'warning',
  },
  delayed: {
    text: 'Atrasado',
    variation: 'danger',
  },
  generated_billet: {
    text: 'Gerou boleto',
    variation: 'info',
  },
  generated_pix: {
    text: 'Gerou PIX',
    variation: 'info',
  },
  canceled: {
    text: 'Cancelado',
    variation: 'dimming',
  },
  refused: {
    text: 'Recusado',
    variation: 'danger',
  },
  processing_refund: {
    text: 'Processando reembolso',
    variation: 'dark',
  },
  refunded: {
    text: 'Reembolsado',
    variation: 'dark',
  },
  negotiated: {
    text: 'Negociado',
    variation: 'default',
  },
};

export default recurrentStatus;
