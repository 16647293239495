import React from 'react';
import {
  ModalButton,
  NotificationModalProps,
  typeNotificationsModalProps,
} from './types';
import * as S from './styles';

import ButtonRow from 'components/ButtonRow';

const NotificationModal = ({
  showModal,
  type,
  handleMuteAndTurnOnNotification,
  toggleModal,
}: NotificationModalProps) => {
  const backAndCloseModal: ModalButton = {
    testId: 'cancel-button',
    text: 'Cancelar',
    variation: 'secondary',
    onClick: toggleModal,
  };

  const typeNotificationModal: typeNotificationsModalProps = {
    mute: {
      testId: 'mute-notification',
      title: 'Deseja silenciar este grupo?',
      description:
        'As notificações serão desativadas e não será possível ouvir o som de alerta. Tem certeza que deseja silenciar?',
      buttons: [
        backAndCloseModal,
        {
          testId: 'mute-notification-button',
          text: 'Silenciar',
          onClick: handleMuteAndTurnOnNotification,
        },
      ],
    },
    active: {
      testId: 'turn-on-notification',
      title: 'Deseja ativar as notificações?',
      description:
        'As notificações serão ativadas e será emitido um som caso o chat do grupo esteja aberto no navegador. Tem certeza que deseja notificar?',
      buttons: [
        backAndCloseModal,
        {
          testId: 'turn-on-notification-button',
          text: 'Ativar',
          onClick: handleMuteAndTurnOnNotification,
        },
      ],
    },
  };

  return (
    <>
      {showModal && (
        <S.ModalWrapper data-testid={typeNotificationModal[type].testId}>
          <S.NotificationModal
            isOpen={showModal}
            typeModal={type}
            toggleModal={toggleModal}
            maxWidth="490px"
            title={typeNotificationModal[type].title}
          >
            <S.Description>
              {typeNotificationModal[type].description}
            </S.Description>
            <ButtonRow buttons={typeNotificationModal[type].buttons} />
          </S.NotificationModal>
        </S.ModalWrapper>
      )}
    </>
  );
};

export default NotificationModal;
