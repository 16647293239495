import React, { useMemo, useReducer } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import {
  Box,
  Card,
  RecipientTag,
  Tag,
  Text,
  Button,
} from '@agendaedu/ae-web-components';
import { Recipient } from '@agendaedu/ae-web-components/dist/components/display/RecipientTag/types';
import Tabs from 'components/Tabs';
import Tab from 'components/Tab';
import * as S from './styles';
import { useTranslation } from 'react-i18next';
import InfoSession from '../InfoSession';
import { DeleteAlbumModal } from 'pages/albums/components/DeleteAlbumModal';
import { History } from '../History';
import { AlbumVersionIncluded } from 'store/albums/types';
import { Props } from './types';

const DetailsTab = ({ album, albumIncluded, canUpdate, canDestroy }: Props) => {
  const { t } = useTranslation('albums');
  const dataArea = useSelector(
    (state: { root: { dataArea: string } }) => state.root.dataArea
  );

  const [showDeleteAlbumModal, toggleDeleteAlbumModal] = useReducer(
    (prev) => !prev,
    false
  );

  const recipientTag = useMemo(() => {
    const recipientType =
      album?.attributes.category === 'student' ? 'student' : 'classroom';

    const recipients: Recipient[] = album?.relationships.recipients.data.reduce(
      (prev, current) => {
        const relatedItem = albumIncluded.find(
          (include) =>
            include.id === current.id &&
            include.type ===
              (recipientType === 'student' ? 'studentProfile' : 'classroom')
        );

        return relatedItem ? [...prev, relatedItem.attributes] : prev;
      },
      []
    );

    if (!recipients?.length)
      return {
        type: t(`album_details.without_recipients`),
        recipients: [{ name: t(`album_details.without_recipients`) }],
      };

    return {
      type: t(`album_details.${recipientType}`),
      recipients,
    };
  }, [
    album?.attributes.category,
    album?.relationships.recipients.data,
    albumIncluded,
    t,
  ]);

  const formattedVersions = (
    album.relationships.versions.data.length
      ? album.relationships.versions.data.map((version) =>
          albumIncluded.find(
            (include) =>
              version.id === include.id && version.type === include.type
          )
        )
      : []
  ) as AlbumVersionIncluded[];

  return (
    <S.DetailTabsWrapper>
      <Card id="details-card" p={0} pt="md">
        <Tabs defaultIndex={0} animated={false}>
          <Tab title={t('album_details.tab_info')}>
            <Box p="xl" pt={0}>
              <S.DetailTagWrapper>
                {!!album.attributes.totalNumberOfPendingPhotos && (
                  <Tag
                    size="small"
                    variant="warning"
                    name={t('album_details.photos_pending_approval', {
                      count: album.attributes.totalNumberOfPendingPhotos,
                    })}
                  />
                )}
                <RecipientTag
                  variant="neutral"
                  recipientType={recipientTag.type}
                  recipients={recipientTag.recipients}
                  modalTitle={recipientTag.type}
                  size="small"
                  enableEllipsis
                />
                {album.attributes.deleting && (
                  <Tag
                    size="small"
                    variant="negative"
                    name={t('list.album_card.deleting_album')}
                  />
                )}
                {album.attributes.approving && (
                  <Tag
                    size="small"
                    variant="warning"
                    name={t('list.album_card.approving_album')}
                  />
                )}
              </S.DetailTagWrapper>

              <Box mt="lg">
                <Text
                  variant="headline-h2-bold-24"
                  fontWeight="bold"
                  color="neutral.black"
                >
                  {album.attributes.name}
                </Text>

                <InfoSession album={album} />

                {!!album.attributes.description && (
                  <Box>
                    <Text
                      variant="subtitle-medium-14"
                      color="neutral.gray2"
                      mt="md"
                    >
                      {t('album_details.description')}
                    </Text>

                    <Text
                      variant="body-regular-16"
                      color="neutral.gray1"
                      lineHeight="lg"
                    >
                      {album.attributes.description}
                    </Text>
                  </Box>
                )}
              </Box>

              <Box display="flex" justifyContent="space-between" mt="md">
                {canUpdate && !album.attributes.deleting && (
                  <Link
                    data-testid="edit-album-link"
                    to={`/${dataArea}/albums/${album.id}/edit`}
                  >
                    <Button
                      id="edit-album-details-button"
                      icon="pencil"
                      variant="secondary"
                      isOnlyIcon
                    />
                  </Link>
                )}

                {canDestroy && !album.attributes.deleting && (
                  <Button
                    id="delete-album-details-button"
                    data-testid="toggle-delete-album-modal"
                    icon="trash-bin"
                    variant="secondary"
                    isOnlyIcon
                    isNegativeAction
                    onClick={toggleDeleteAlbumModal}
                  />
                )}
              </Box>

              {formattedVersions.length > 0 && (
                <Box mt="xl">
                  <History versions={formattedVersions} />
                </Box>
              )}
            </Box>
          </Tab>
        </Tabs>
      </Card>

      <DeleteAlbumModal
        album={album}
        isOpen={showDeleteAlbumModal}
        onClose={toggleDeleteAlbumModal}
        goBackToList
      />
    </S.DetailTabsWrapper>
  );
};

export default DetailsTab;
