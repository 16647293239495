import amplitude from 'amplitude-js';

const initialize = () => {
  const key = process.env.AMPLITUDE_API_KEY;

  if (!key) return;

  amplitude.getInstance().init(key, undefined, {
    includeReferrer: true,
    disableCookies: true,
  });
};

export const trackEvent = (
  eventName: string,
  eventData?: Record<string, any>
) => {
  if (process.env.NODE_ENV !== 'production') return;

  initialize();

  amplitude.getInstance().logEvent(eventName, eventData);
};
