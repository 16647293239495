import styled, { css } from 'styled-components';

import { ThemeProps } from 'config/themes/types';
import { StylesProps } from './types';

export const DetailsWrapper = styled.div`
  ${({ theme: { space, breakpoints } }: ThemeProps) => css`
    display: flex;
    flex-direction: column;
    margin: 14px ${space.xl};
    gap: ${space.xs3};

    @media screen and (max-width: ${breakpoints.tabletMD}) {
      margin: 14px ${space.sm};
    }
  `}
`;

export const TabsWrapper = styled.div`
  ${({ theme: { border, colors } }: ThemeProps) => css`
    display: flex;
    border-bottom: ${border.width.sm} ${border.style.solid}
      ${colors.neutral.gray4};
  `}
`;

export const DetailsTabWrapper = styled.div`
  ${({ theme: { border, space } }: ThemeProps) => css`
    display: flex;
    flex-direction: column;
    border-radius: ${border.radius.md};
    box-shadow: 0 0 10px 0 rgba(51, 51, 51, 0.15);
    padding: ${space.md} 0;
    margin-top: ${space.lg};
  `}
`;

export const TabsSkeleton = styled.div`
  ${({ theme: { border, space } }: ThemeProps) => css`
    padding: ${space.xs4};
    border-radius: ${border.radius.md};
    box-shadow: 0 0 10px 0 rgba(51, 51, 51, 0.15);
  `}
`;

export const TabSkeleton = styled.div<StylesProps>`
  ${({ theme: { colors, space }, isSelected }) => css`
    height: 52px;
    padding: ${space.sm} ${space.lg} ${space.xs4} ${space.lg};

    position: relative;

    ${isSelected &&
    css`
      &::after {
        content: '';
        position: absolute;
        bottom: ${space.xs4};
        right: ${space.xs4};
        left: ${space.xs4};
        width: 100%;
        height: ${space.xs3};
        border-radius: ${space.xs3} ${space.xs3} ${space.xs4} ${space.xs4};
        background-color: ${colors.neutral.gray3};
      }
    `}
  `}
`;

export const TabsHeaderSkeleton = styled.div`
  ${({ theme: { border, colors } }: ThemeProps) => css`
    height: 52px;
    border-bottom: ${border.width.sm} ${border.style.solid}
      ${colors.neutral.gray4};
    display: flex;
    align-items: center;
  `}
`;

export const SkeletonItensWrapper = styled.div`
  ${({ theme: { breakpoints, space } }: ThemeProps) => css`
    display: flex;
    flex-direction: row;
    gap: ${space.lg};

    @media screen and (max-width: ${breakpoints.tabletMD}) {
      flex-direction: column;
    }
  `}
`;

export const Wrapper = styled.div`
  ul {
    max-height: 32px;
  }
`;
