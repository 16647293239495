import styled, { css } from 'styled-components';

import { Text } from '@agendaedu/ae-web-components';

import { ThemeProps } from 'config/themes/types';

export const ContentWrapper = styled.div`
  ${({ theme: { space, border } }: ThemeProps) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: ${space.lg};

    > a {
      border-radius: ${border.radius.md};
    }
  `}
`;

export const TextArea = styled.textarea`
  min-height: 120px;

  ${({ theme: { border, colors, space, font } }: ThemeProps) => css`
    border: ${border.width.sm} ${border.style.solid} ${colors.neutral.gray4};
    border-radius: ${border.radius.md};
    padding: ${space.xs2} ${space.sm};
    font-size: ${font.size.xs};
    font-weight: ${font.weight.regular};
    font-style: ${font.family.auxiliary};
    color: ${colors.neutral.black};
    margin-bottom: ${space.sm};

    &:hover {
      border-color: ${colors.brand.primary.default};
    }

    &:focus-within {
      border-color: ${colors.brand.primary.default};
    }
  `}
`;

export const TextAreaWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const TextAreaLabel = styled(Text)`
  ${({ theme: { colors, space } }: ThemeProps) => css`
    margin-bottom: ${space.sm};
    color: ${colors.neutral.gray1};
  `}
`;
