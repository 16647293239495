import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autobind from 'autobind-decorator';

import './style.scss';

class ResponsivePanels extends Component {
  @autobind
  renderPanel(panel,index) {
    if(!panel) return null;
    const { viewport, action, compoundKey } = this.props;

    return (
      <div key={`panel-${index}${compoundKey ? compoundKey : ''}`} className={`${viewport}-panel-${index} ${action}`}>
        {panel}
      </div>
    );
  }

  @autobind
  renderPanels() {
    const panels = this.props.children;

    return (
      panels.filter((panel) => panel !== false).map((panel, index) => {
        return (
          this.renderPanel(panel,index + 1)
        );
      })
    );
  }

  render() {
    return (
      <div className="ResponsivePanels" >
        {
          this.renderPanels()
        }
      </div>
    );
  }
}

ResponsivePanels.propTypes = {
  children: PropTypes.array,
  action: PropTypes.string,
  compoundKey: PropTypes.string,
  viewport: PropTypes.oneOf([
    'mobile',
    'tablet',
    'desktop'
  ])
};

export default ResponsivePanels;
