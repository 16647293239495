import { FetchTicketsCount } from './type';

export const queryUrlFetchTicketsCount = ({
  educationalStageId,
  headquarterId,
  classroomId,
  source,
}: FetchTicketsCount) => {
  const filterSource = source ? `&[filter]source=${source}` : '';

  const filterHeadquarter = headquarterId
    ? `&headquarter_ids=${headquarterId}`
    : '';
  const filterEducationalStage = educationalStageId
    ? `&educational_stage_ids=${educationalStageId}`
    : '';
  const filterClassRoom = classroomId ? `&classroom_ids=${classroomId}` : '';

  const queryUrlTickets = `${filterSource}${filterHeadquarter}${filterEducationalStage}${filterClassRoom}`;

  return queryUrlTickets;
};
