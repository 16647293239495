import React, { useState, useEffect } from 'react';

import tabifyForWizard from 'core/hoc/tabifyForWizard';
import GroupSelector from 'components/Form/GroupSelector';
import withFormContext, { formPropTypes } from 'core/hoc/withFormContext';
import { useTranslation } from 'react-i18next';
import { theme, Text } from '@agendaedu/ae-web-components';
import validations from './validations';
import * as S from './styles';

const FormRecipientsTab = ({
  formContext: { form, formMeta, updateAttribute },
}) => {
  const { t } = useTranslation(['hub_store']);

  const {
    colors: { neutral },
  } = theme;

  const [studentsTotal, setStudentsTotal] = useState(0);
  const { classroom_ids, total_currency } = form;
  const { classrooms, subscriptionsProduct } = formMeta;
  const pricePerUnit = subscriptionsProduct.attributes?.price_per_unit;

  useEffect(() => {
    setStudentsTotal(0);
    classrooms.forEach((classroom) =>
      classroom.options.forEach((option) => {
        if (classroom_ids?.includes(option.id)) {
          setStudentsTotal(
            (value) => value + Number(option.attributes.students_amount)
          );
        }
      })
    );
  }, [classroom_ids]);

  useEffect(() => {
    updateAttribute('student_total', studentsTotal);
    updateAttribute('total_currency', Number(studentsTotal * pricePerUnit));
  }, [studentsTotal]);

  const maskCurrency = (value) => {
    return value.toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      style: 'currency',
      currency: 'BRL',
    });
  };

  return (
    <S.WrapperContainer>
      <div className="row">
        <Text
          as="h2"
          mb={32}
          variant="title-bold-20"
          fontWeight={700}
          color={neutral.black}
        >
          {t('subscription.title_classrooms')}
        </Text>
        <Text
          as="p"
          mt={0}
          mb={32}
          variant="subtitle-medium-14"
          color={neutral.black}
          textAlign="left"
          lineHeight={1.7}
        >
          {t('subscription.subtitle_classrooms')}
        </Text>
      </div>

      <div className="row">
        <GroupSelector options={classrooms} attributeName="classroom_ids" />
      </div>

      <div className="row">
        <Text
          as="h2"
          mt={32}
          mb={32}
          variant="title-bold-20"
          fontWeight={700}
          color={neutral.black}
        >
          {t('subscription.title_buy_summary')}
        </Text>
        <S.GrayBox>
          <S.TextContainer>
            <Text
              as="h2"
              mt={0}
              variant="subtitle-medium-12"
              color={neutral.gray1}
              textAlign="left"
              flex={1}
              m="24px 0px 24px 24px"
            >
              {t('subscription.amount_for_student')}
            </Text>

            <Text
              as="h2"
              mt={0}
              variant="subtitle-medium-12"
              color={neutral.gray1}
              textAlign="right"
              flex={1}
              m="24px 24px 24px 0px"
            >
              {maskCurrency(Number(pricePerUnit))}
            </Text>
          </S.TextContainer>
          <S.TextContainer>
            <Text
              as="h2"
              mt={0}
              mb={24}
              variant="subtitle-medium-12"
              color={neutral.gray1}
              textAlign="left"
              flex={1}
              m="0px 0px 0px 24px"
            >
              {t('subscription.quantity_for_student')}
            </Text>

            <Text
              as="h2"
              mt={0}
              mb={24}
              variant="subtitle-medium-12"
              color={neutral.gray1}
              textAlign="right"
              flex={1}
              m="0px 24px 0px 0px"
            >
              {studentsTotal}
            </Text>
          </S.TextContainer>
          <S.GrayLine />
          <S.TextContainer>
            <Text
              as="h2"
              mt={0}
              mb={24}
              variant="subtitle-medium-14"
              color={neutral.gray1}
              textAlign="left"
              flex={1}
              m="0px 0px 24px 24px"
            >
              {t('subscription.total_pay_amount')}
            </Text>

            <Text
              as="h2"
              mt={0}
              mb={24}
              variant="title-bold-16"
              color={neutral.gray1}
              textAlign="right"
              fontWeight={700}
              flex={1}
              m="0px 24px 24px 0px"
            >
              {maskCurrency(Number(total_currency))}
            </Text>
          </S.TextContainer>
        </S.GrayBox>

        <Text
          as="p"
          mt={16}
          variant="label-regular-12"
          color={neutral.gray1}
          textAlign="justified"
          lineHeight={1.7}
        >
          {t('subscription.total_pay_amount_clause')}
        </Text>
      </div>
    </S.WrapperContainer>
  );
};

FormRecipientsTab.propTypes = {
  ...formPropTypes,
};

export default tabifyForWizard({
  title: '1. Seleção de turmas',
  validations,
})(withFormContext(FormRecipientsTab));
