import { all, call, put, takeLatest, select } from 'redux-saga/effects';
import { buildToast, ToastTypes } from 'store/middlewares/toast/actions';
import { fetchApi } from 'core/utils/api';
import actions from './actions';

function* fetchSchoolOnboardings() {
  try {
    const dataArea = yield select((state) => state.root.dataArea);
    const { data } = yield call(fetchApi, `/${dataArea}/onboardings.json`);

    yield put({
      type: actions.FETCH_SCHOOL_ONBOARDINGS_SUCCESS,
      data,
    });
  } catch (error) {
    yield put({
      type: actions.SET_ERROR,
      toast: buildToast(
        'Não foi possível listar as importações.',
        ToastTypes.error
      ),
      error,
    });
  }
}

function* onboardingSagas() {
  yield all([
    takeLatest(actions.FETCH_SCHOOL_ONBOARDINGS, fetchSchoolOnboardings),
  ]);
}

export default onboardingSagas;
