import React from 'react';
import { DefaultThemeProps, theme } from '@agendaedu/ae-web-components';

import * as S from './styles';
import { MessagesEmptyStateProps } from './types';

export const MessagesEmptyState = ({ message }: MessagesEmptyStateProps) => {
  const defaultTheme: DefaultThemeProps = theme;

  const {
    colors: { neutral },
  } = defaultTheme;

  return (
    <S.EmptyStateWrapper data-testid="messages-empty-state">
      <S.IconMessage name="message-heart" color={neutral.gray3} />
      <S.EmptyStateLabel variant="subtitle-medium-16">
        {message}
      </S.EmptyStateLabel>
    </S.EmptyStateWrapper>
  );
};
