import validateLength from 'core/lib/FormValidator/validators/length';
import validateCpf from 'core/lib/FormValidator/validators/cpf';

export const validateDocumentNumber = (cpf) => {
  if (cpf.length === 0) return true;
  return validateCpf(cpf);
};

export const validateEmail = (email) => {
  if (email.length === 0) return true;
  return /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(
    email
  );
};

export const validateUsername = (username) => {
  if (username.length === 0) return true;
  return /^[A-Za-z0-9_.-]*$/.test(username);
};

export const validateUsernameLength = (username) => {
  if (username.length === 0) return true;

  const validate = validateLength({ min: 5 });
  return validate(username);
};

export const validatePhoneNumber = (phone) => {
  if (phone.length === 0) return true;
  return phone.replace(/\D/g, '').length >= 10;
};

export const validateCep = (cep, state) => {
  if (!state.financial) return true;
  if (cep.length === 0) return true;
  return cep.replace(/\D/g, '').length === 8;
};

export default [
  {
    attributeName: 'document_number',
    validator: validateDocumentNumber,
    message: 'CPF inválido',
  },
  {
    attributeName: 'email',
    validator: validateEmail,
    message: 'E-mail inválido',
  },
  {
    attributeName: 'username',
    validator: validateUsername,
    message: 'Nome de usuário inválido',
  },
  {
    attributeName: 'username',
    validator: validateUsernameLength,
    message: 'Nome de usuário é muito curto (mínimo: 5 caracteres)',
  },
  {
    attributeName: 'phone',
    validator: validatePhoneNumber,
    message: 'Telefone inválido, deve conter 10 ou 11 números.',
  },
  {
    attributeName: 'cep',
    validator: validateCep,
    message: 'CEP inválido.',
  }
];
