import createModuleReducer from '../storeConfig/createModuleReducer';
import actions from './actions';
import { DATE_MONTHS } from 'core/constants';

export const INITIAL_STATE = {
  error: null,
  isLoading: false,
  isSubmitting: false,
  form: {
    legacy_id: '',
    name: '',
    classroom_ids: [],
    main_classroom_id: '',
    period: '',
    date_of_birth: '',
    email: '',
    username: '',
    avatar: null,
    document_number: '',
    phone: '',
    cep: '',
    street: '',
    street_number: '',
    complement: '',
    neighborhood: '',
    city: '',
    state: '',
    financial: false,
    ra: ''
  },
  formMeta: {
    nextStepRequiredFields: ['name'],
    ignoreFieldsOnUpdatesCheck: [
      'date_of_birth_date_field_years',
      'date_of_birth_date_field_months',
    ],
    select_options: {
      date_of_birth_date_field_years: [],
      date_of_birth_date_field_months: DATE_MONTHS,
      classrooms: [],
      periods: [],
    },
    nextReviewProfileId: null,
    state_options: [],
  },
};

export const setError = (state, action) => ({
  ...state,
  error: action.error,
  isSubmitting: false,
  isLoading: false,
});

export const fetchFormMeta = (state) => ({
  ...state,
  isLoading: true,
});

export const fetchFormMetaSuccess = (state, action) => ({
  ...state,
  isLoading: false,
  formMeta: {
    ...state.formMeta,
    select_options: {
      date_of_birth_date_field_years: action.meta.date_of_birth_years,
      date_of_birth_date_field_months: DATE_MONTHS,
      classrooms: action.meta.classrooms,
      periods: action.meta.periods,
    },
    state_options: action.meta.states,
  },
});

export const setStudentProfile = (state) => ({
  ...state,
  isLoading: true,
});

export const setStudentProfileSuccess = (state, action) => {
  const { data, meta } = action;

  const classroomIds = data.relationships.classrooms.data.map((classroom) =>
    parseInt(classroom.id)
  );

  return {
    ...state,
    isLoading: false,
    form: {
      ...data.attributes,
      classroom_ids: classroomIds,
      avatar: data.attributes.avatar_url,
    },
    formMeta: {
      ...state.formMeta,
      select_options: {
        date_of_birth_date_field_years: meta.date_of_birth_years,
        date_of_birth_date_field_months: DATE_MONTHS,
        classrooms: meta.classrooms,
        periods: meta.periods,
      },
    },
  };
};

export const setReviewerStudentProfile = (state) => ({
  ...state,
  isLoading: true,
});

export const setReviewerStudentProfileSuccess = (state, action) => {
  const { data, meta } = action;

  const classroomIds = data.relationships.classrooms.data.map((classroom) =>
    parseInt(classroom.id)
  );

  return {
    ...state,
    isLoading: false,
    form: {
      ...data.attributes,
      classroom_ids: classroomIds,
      avatar: data.attributes.avatar_url,
    },
    formMeta: {
      ...state.formMeta,
      select_options: {
        date_of_birth_date_field_years: meta.date_of_birth_years,
        date_of_birth_date_field_months: DATE_MONTHS,
        classrooms: meta.classrooms,
        periods: meta.periods,
      },
      nextReviewProfileId: meta.next_review_profile_id,
    },
  };
};

const setRecoveryStudentProfile = (state) => ({
  ...state,
  isLoading: true,
});

const setRecoveryStudentProfileSuccess = (state, action) => {
  const { data, meta } = action;

  const classroomIds = data.relationships.classrooms.data.map((classroom) =>
    parseInt(classroom.id)
  );

  return {
    ...state,
    isLoading: false,
    form: {
      ...data.attributes,
      classroom_ids: classroomIds,
      avatar: data.attributes.avatar_url,
    },
    formMeta: {
      ...state.formMeta,
      select_options: {
        date_of_birth_date_field_years: meta.date_of_birth_years,
        date_of_birth_date_field_months: DATE_MONTHS,
        classrooms: meta.classrooms,
        periods: meta.periods,
      },
    },
  };
};

const createStudentProfile = (state) => ({
  ...state,
  isSubmitting: true,
});

const createStudentProfileSuccess = (state) => ({
  ...state,
  isSubmitting: false,
});

const updateStudentProfile = (state) => ({
  ...state,
  isSubmitting: true,
});

const updateStudentProfileSuccess = (state) => ({
  ...state,
  isSubmitting: false,
});

const approveStudentProfile = (state) => ({
  ...state,
  isSubmitting: true,
});

const approveStudentProfileSuccess = (state) => ({
  ...state,
  isSubmitting: false,
});

const recoverStudentProfile = (state) => ({
  ...state,
  isSubmitting: true,
});

const recoverStudentProfileSuccess = (state) => ({
  ...state,
  isSubmitting: false,
});

export const removeStudentProfile = (state) => ({
  ...state,
  isSubmitting: true,
});

export const removeStudentProfileSuccess = (state) => ({
  ...state,
  isSubmitting: false,
});

const HANDLERS = {
  [actions.SET_ERROR]: setError,
  [actions.FETCH_FORM_META]: fetchFormMeta,
  [actions.FETCH_FORM_META_SUCCESS]: fetchFormMetaSuccess,
  [actions.SET_STUDENT_PROFILE]: setStudentProfile,
  [actions.SET_STUDENT_PROFILE_SUCCESS]: setStudentProfileSuccess,
  [actions.SET_REVIEWER_STUDENT_PROFILE]: setReviewerStudentProfile,
  [actions.SET_REVIEWER_STUDENT_PROFILE_SUCCESS]:
    setReviewerStudentProfileSuccess,
  [actions.SET_RECOVERY_STUDENT_PROFILE]: setRecoveryStudentProfile,
  [actions.SET_RECOVERY_STUDENT_PROFILE_SUCCESS]:
    setRecoveryStudentProfileSuccess,
  [actions.CREATE_STUDENT_PROFILE]: createStudentProfile,
  [actions.CREATE_STUDENT_PROFILE_SUCCESS]: createStudentProfileSuccess,
  [actions.UPDATE_STUDENT_PROFILE]: updateStudentProfile,
  [actions.UPDATE_STUDENT_PROFILE_SUCCESS]: updateStudentProfileSuccess,
  [actions.APPROVE_STUDENT_PROFILE]: approveStudentProfile,
  [actions.APPROVE_STUDENT_PROFILE_SUCCESS]: approveStudentProfileSuccess,
  [actions.RECOVER_STUDENT_PROFILE]: recoverStudentProfile,
  [actions.RECOVER_STUDENT_PROFILE_SUCCESS]: recoverStudentProfileSuccess,
  [actions.REMOVE_STUDENT_PROFILE]: removeStudentProfile,
  [actions.REMOVE_STUDENT_PROFILE_SUCCESS]: removeStudentProfileSuccess,
};

const studentProfiles = createModuleReducer(INITIAL_STATE, HANDLERS);

export default studentProfiles;
