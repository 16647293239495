const defaultStyles = {
  fontSize: '14px'
};

const lightFont = {
  color: '#666666',
};

const darkFont = {
  color: '#333333',
};

export default {
  control: (base) => ({
    ...base,
    ...defaultStyles,
    height: '50px',
    border: '1px solid #ABB1B7',
    backgroundColor: '#FFFFFF'
  }),
  groupHeading: (base) => ({
    ...base,
    ...defaultStyles,
    ...darkFont,
    fontWeight: '600',
    textTransform: 'unset',
  }),
  input: base => ({
    ...base,
    ...defaultStyles,
  }),
  noOptionsMessage: (base) => ({
    ...base,
    ...defaultStyles,
    ...lightFont,
    textAlign: 'center'
  }),
  option: (base) => ({
    ...base,
    ...defaultStyles,
    ...lightFont,
  }),
  placeholder: (base) => ({
    ...base,
    ...defaultStyles,
    ...lightFont,
    opacity: 0.35
  }),
  singleValue: (base) => ({
    ...base,
    ...defaultStyles,
    ...darkFont,
  }),
  valueContainer: (base) => ({
    ...base,
    paddingLeft: '12px'
  })
};
