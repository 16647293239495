import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import reduxProvider from 'core/hoc/reduxProvider';

import PageCharges from 'pages/charges';
import EmptyWalletWarning from 'components/Payments/EmptyWalletWarning';
import NewSchoolWallet from 'components/Payments/NewSchoolWallet';
import EditSchoolWallet from 'components/Payments/EditSchoolWallet';
import CloneRecurrentPlan from 'components/Payments/Recurrent/CloneRecurrentPlan';
import NewRecurrentPlan from 'components/Payments/Recurrent/NewRecurrentPlan';
import NewOffer from 'components/Payments/Shop/NewOffer';
import EditOffer from 'components/Payments/Shop/EditOffer';
import ShowRecurrentPlan from 'components/Payments/Recurrent/ShowRecurrentPlan';
import ShowEnrollmentPlan from 'components/Payments/Enrollment/ShowEnrollmentPlan';
import EditRecurrentPlan from 'components/Payments/Recurrent/EditRecurrentPlan';
import NewRecurrentBill from 'components/Payments/Recurrent/NewRecurrentBill';
import EditRecurrentBill from 'components/Payments/Recurrent/EditRecurrentBill';
import EditRecurrentBillInBatches from 'components/Payments/Recurrent/EditRecurrentBillInBatches';
import RecurrentProductsList from 'components/Payments/Recurrent/RecurrentProductsList';
import NewEnrollmentPlan from 'components/Payments/Enrollment/NewEnrollmentPlan';
import EditEnrollmentPlan from 'components/Payments/Enrollment/EditEnrollmentPlan';
import Toast from 'components/Toast';
import NewSchoolProduct from 'components/Payments/PaymentsContainer/NewSchoolProduct';
import EditSchoolProduct from 'components/Payments/PaymentsContainer/EditSchoolProduct';
import CloneSchoolProduct from 'components/Payments/PaymentsContainer/CloneSchoolProduct';
import SchoolWalletContainer from 'components/Payments/PaymentsContainer/SchoolWalletContainer';
import SchoolProductDetail from 'components/Payments/PaymentsContainer/SchoolProductDetail';
import RejectSchoolProduct from 'components/Payments/PaymentsContainer/RejectSchoolProduct';
import InternalErrorBoundary from 'boundaries/InternalErrorBoundary';
import walletsActions from 'store/edupay/wallets/actions';
import OfferDetailsContainer from 'components/Payments/Shop/OfferDetailsContainer';
import NewNegotiation from 'components/Payments/Recurrent/NewNegotiation';
import CartSettings from 'components/Payments/Shop/CartSettings';

import NewContractModel from 'components/Payments/Enrollment/NewContractModel';
import ContractModelList from 'components/Payments/Enrollment/ContractModelList';

import './style.scss';

const SchoolPaymentsContainer = (props) => {
  const dispatch = useDispatch();
  const [mustCreateWallet, setMustCreateWallet] = useState(
    props.mustCreateWallet
  );
  const { wallets, currentWallet } = useSelector((state) => state.wallets);
  const { fetchRecipientsWalletsRequest } = walletsActions;

  useEffect(() => {
    if (wallets.length < 1 && !currentWallet && !mustCreateWallet) {
      dispatch(fetchRecipientsWalletsRequest());
    }
  }, []);

  const allowAccessToPayments = () => {
    setMustCreateWallet(false);
  };

  return (
    <div id="SchoolPaymentsContainer">
      <InternalErrorBoundary>
        {mustCreateWallet ? (
          <Switch>
            <Route
              path="/schools/wallets/new"
              render={() => (
                <NewSchoolWallet
                  allowAccessToPayments={allowAccessToPayments}
                />
              )}
            />
            <Route
              exact
              path="/schools/school_products"
              component={EmptyWalletWarning}
            />
            <Route
              exact
              path="/schools/wallets"
              component={EmptyWalletWarning}
            />
            <Route
              exact
              path="/schools/recurrent/recurrent_products"
              component={EmptyWalletWarning}
            />
          </Switch>
        ) : (
          <Switch>
            <Route
              exact
              path="/schools/school_products"
              component={PageCharges}
            />
            <Route
              exact
              path="/schools/school_products/tab/:tab"
              component={PageCharges}
            />
            <Route
              exact
              path="/schools/school_products/new"
              component={NewSchoolProduct}
            />
            <Route
              path="/schools/school_products/:id/edit"
              component={EditSchoolProduct}
            />
            <Route
              path="/schools/school_products/:id/clone_charge"
              component={CloneSchoolProduct}
            />
            <Route
              path="/schools/school_products/:id/reject"
              component={RejectSchoolProduct}
            />
            <Route
              exact
              path="/schools/school_products/:id"
              component={SchoolProductDetail}
            />
            <Route
              exact
              path="/schools/recurrent/recurrent_plans/new"
              component={NewRecurrentPlan}
            />
            <Route
              exact
              path="/schools/recurrent/recurrent_plans/:id"
              component={ShowRecurrentPlan}
            />
            <Route
              exact
              path="/schools/enrollment_plans/:id/details"
              component={ShowEnrollmentPlan}
            />
            <Route
              exact
              path="/schools/recurrent/recurrent_plans/:id/edit"
              component={EditRecurrentPlan}
            />
            <Route
              path="/schools/recurrent/recurrent_plans/:id/clone_charge"
              component={CloneRecurrentPlan}
            />
            <Route
              exact
              path="/schools/recurrent/recurrent_products"
              component={RecurrentProductsList}
            />
            <Route
              exact
              path="/schools/recurrent/recurrent_plans/:recurrent_plan_id/recurrent_bills/:id/cancel_and_clone"
              component={NewRecurrentBill}
            />
            <Route
              exact
              path="/schools/recurrent/recurrent_plans/:plan_id/recurrent_bills/:id/edit"
              component={EditRecurrentBill}
            />
            <Route
              exact
              path="/schools/recurrent/recurrent_bills/edit_in_batches"
              component={EditRecurrentBillInBatches}
            />
            <Route
              exact
              path="/schools/enrollment_plans/new"
              component={NewEnrollmentPlan}
            />
            <Route
              exact
              path="/schools/enrollment_plans/:id/edit"
              component={EditEnrollmentPlan}
            />
            <Route
              exact
              path="/schools/wallets"
              component={SchoolWalletContainer}
            />
            <Route
              path="/schools/wallets/new"
              render={() => (
                <NewSchoolWallet
                  allowAccessToPayments={allowAccessToPayments}
                />
              )}
            />
            <Route
              exact
              path="/schools/wallets/edit"
              render={() => (
                <EditSchoolWallet
                  schoolBillingInformationId={currentWallet.id}
                />
              )}
            />
            <Route
              exact
              path="/schools/marketplaces/offers/new"
              component={NewOffer}
            />
            <Route
              exact
              path="/schools/marketplaces/offers/:id/edit"
              component={EditOffer}
            />
            <Route
              exact
              path="/schools/marketplaces/offers/:id"
              component={OfferDetailsContainer}
            />
            <Route
              exact
              path="/schools/shopping_carts/configuration"
              component={CartSettings}
            />
            <Route
              exact
              path="/schools/negotiations/new"
              component={NewNegotiation}
            />
            <Route
              exact
              path="/schools/enrollment_plans/contract_templates/new"
              component={NewContractModel}
            />
            <Route
              exact
              path="/schools/enrollment_plans/contract_templates"
              component={ContractModelList}
            />
            <Toast />
          </Switch>
        )}
      </InternalErrorBoundary>
    </div>
  );
};

SchoolPaymentsContainer.propTypes = {
  mustCreateWallet: PropTypes.bool,
};

export default reduxProvider(SchoolPaymentsContainer);
