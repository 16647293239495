const actions = {
  FETCH_RECIPIENTS_WALLETS_REQUEST: 'FETCH_RECIPIENTS_WALLETS_REQUEST/wallets',
  FETCH_RECIPIENTS_WALLETS_OPTIONS_REQUEST:
    'FETCH_RECIPIENTS_WALLETS_OPTIONS_REQUEST/wallets',
  FETCH_RECIPIENTS_WALLETS_OPTIONS_SUCCESS:
    'FETCH_RECIPIENTS_WALLETS_OPTIONS_SUCCESS/wallets',
  FETCH_RECIPIENTS_WALLETS_SUCCESS: 'FETCH_RECIPIENTS_WALLETS_SUCCESS/wallets',
  FETCH_SELECT_RECIPIENT_WALLET_REQUEST:
    'FETCH_SELECT_RECIPIENT_WALLET_REQUEST/wallets',
  FETCH_SELECT_RECIPIENT_WALLET_SUCCESS:
    'FETCH_SELECT_RECIPIENT_WALLET_SUCCESS/wallets',
  FETCH_ACCOUNT_BALANCE_REQUEST: 'FETCH_ACCOUNT_BALANCE_REQUEST/wallets',
  FETCH_ACCOUNT_BALANCE_SUCCESS: 'FETCH_ACCOUNT_BALANCE_SUCCESS/wallets',
  FETCH_BANK_ACCOUNT_REQUEST: 'FETCH_BANK_ACCOUNT_REQUEST/wallets',
  FETCH_BANK_ACCOUNT_SUCCESS: 'FETCH_BANK_ACCOUNT_SUCCESS/wallets',
  FETCH_HISTORY_REQUEST: 'FETCH_HISTORY_REQUEST/wallets',
  FETCH_HISTORY_SUCCESS: 'FETCH_HISTORY_SUCCESS/wallets',
  HANDLE_SHOW_CHANGE_WALLET_MODAL: 'HANDLE_SHOW_CHANGE_WALLET_MODAL/wallets',
  fetchRecipientsWalletsRequest: () => ({
    type: actions.FETCH_RECIPIENTS_WALLETS_REQUEST,
  }),
  selectRecipientWallet: (walletId) => ({
    type: actions.FETCH_SELECT_RECIPIENT_WALLET_REQUEST,
    walletId,
  }),
  fetchAccountBalanceRequest: (periodRange = []) => ({
    type: actions.FETCH_ACCOUNT_BALANCE_REQUEST,
    periodRange,
  }),
  fetchBankAccountRequest: () => ({
    type: actions.FETCH_BANK_ACCOUNT_REQUEST,
  }),
  fetchHistoryRequest: (page, schoolBillingInformationId) => ({
    type: actions.FETCH_HISTORY_REQUEST,
    page,
    schoolBillingInformationId,
  }),
  fetchRecipientsWalletsOptionsRequest: () => ({
    type: actions.FETCH_RECIPIENTS_WALLETS_OPTIONS_REQUEST,
  }),
  handleChangeWalletModal: (isOpen) => ({
    type: actions.HANDLE_SHOW_CHANGE_WALLET_MODAL,
    isOpen,
  }),
};

export default actions;
