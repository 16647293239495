import React, { Component } from 'react';
import PropTypes from 'prop-types';

import withFormContext from 'core/hoc/withFormContext';
import withAppContext from 'core/hoc/withAppContext';
import arrayFlatten from 'core/utils/arrayFlatten';
import ClassroomsService from 'core/services/Classrooms';

import Label from 'components/Form/Label';
import FormSelector from 'components/Form/Selector';
import FormClassroomSelector from 'components/Form/ClassroomSelector';
import Avatar from 'components/Avatar';
import AgendaIcon from 'components/AgendaIcon';

import './style.scss';

class StudentsProgressionForm extends Component {
  static propTypes = {
    appContext: PropTypes.shape({
      dataArea: PropTypes.string.isRequired,
    }).isRequired,
    formContext: PropTypes.shape({
      updateAttribute: PropTypes.func,
    }),
    activeSchoolTerm: PropTypes.object.isRequired,
    oldSchoolTerm: PropTypes.object.isRequired,
    students: PropTypes.array.isRequired,
    classroomFrom: PropTypes.object,
    classroomTo: PropTypes.object,
    loadStudents: PropTypes.func.isRequired,
  };

  state = {
    isLoadingClassrooms: false,
  };

  componentDidMount() {
    this.loadClassroomsfromLastSchoolTermActiveOptions();
    this.loadClassroomsOptions();
  }

  loadClassroomsfromLastSchoolTermActiveOptions = async () => {
    const {
      formContext: { loadOptionsToSelect },
      appContext: { dataArea },
    } = this.props;
    const classroomsService = new ClassroomsService(dataArea);
    this.setState({ isLoadingClassrooms: true });

    try {
      const old_classrooms =
        await classroomsService.getClassroomsFromLastSchoolTermActive();

      this.setState({ old_classrooms }, () => {
        loadOptionsToSelect('classroom_from', old_classrooms);
      });
    } catch (errorFetchingClassrooms) {
      this.setState({ errorFetchingClassrooms });
    }
  };

  loadClassroomsOptions = async () => {
    const {
      formContext: { loadOptionsToSelect },
      appContext: { dataArea },
    } = this.props;
    const classroomsService = new ClassroomsService(dataArea);
    this.setState({ isLoadingClassrooms: true });

    try {
      const classrooms = await classroomsService.getClassrooms();

      this.setState({ classrooms }, () => {
        loadOptionsToSelect('classroom_to', classrooms);
      });
    } catch (errorFetchingClassrooms) {
      this.setState({ errorFetchingClassrooms });
    } finally {
      this.setState({ isLoadingClassrooms: false });
    }
  };

  loadStudents = (select) => {
    const {
      formContext: { updateAttribute },
    } = this.props;

    updateAttribute('classroom_from', select);
    this.props.loadStudents(select);
    this.onClassroomSelectFrom(select);
  };

  optionComponent = (option) => {
    return (
      <div className="nameWithAvatar">
        <Avatar user={option} />
        <span>{option.attributes.name}</span>
      </div>
    );
  };

  onClassroomSelectFrom = (select) => {
    const { classroom_from } = this.props.formContext.formMeta.select_options;

    const selectClassroomFrom = arrayFlatten(
      classroom_from.map(({ options }) => options)
    ).find(({ id }) => id === select);

    this.props.onClassroomSelectFrom(selectClassroomFrom);
  };

  onClassroomSelectTo = (select) => {
    const { classrooms } = this.state;

    const selectClassroomTo = arrayFlatten(
      classrooms.map(({ options }) => options)
    ).find(({ id }) => id === select);

    this.props.onClassroomSelectTo(selectClassroomTo);
  };

  updateSelector = ({ selectedValues }) => {
    const {
      formContext: { updateAttribute },
    } = this.props;

    updateAttribute('student_profile_ids', selectedValues);
  };

  searchOptions = ({ options, searchTerm }) => {
    const filteredOptions = options.filter((option) =>
      option.attributes.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return filteredOptions;
  };

  emptyStateComponent = () => {
    return (
      <div className="empty">
        <AgendaIcon name="user-group" size="large" />
        <div className="empty-message">Selecione uma turma no campo acima</div>
      </div>
    );
  };

  render() {
    const {
      students,
      formContext: { form },
      activeSchoolTerm,
      oldSchoolTerm,
      classroomFrom,
      classroomTo,
    } = this.props;

    const { isLoadingClassrooms } = this.state;

    return (
      <div className="StudentsProgressionForm">
        <div className="classrooms-select">
          <div className="Field col-xs-12 col-md-12">
            <Label>{`Turma antiga (${oldSchoolTerm.attributes.name})`}</Label>
            <FormClassroomSelector
              attributeName="classroom_from"
              disabled={isLoadingClassrooms}
              onChange={this.loadStudents}
              placeholder={
                isLoadingClassrooms
                  ? 'Carregando turmas...'
                  : 'Selecionar turma atual'
              }
              value={classroomFrom}
            />
          </div>
          <div className="Field col-xs-12 col-md-12">
            <Label>{`Turma de destino (${activeSchoolTerm.attributes.name})`}</Label>
            <FormClassroomSelector
              attributeName="classroom_to"
              disabled={isLoadingClassrooms}
              onChange={this.onClassroomSelectTo}
              placeholder={
                isLoadingClassrooms
                  ? 'Carregando turmas...'
                  : 'Selecionar turma destino'
              }
              value={classroomTo}
            />
          </div>
        </div>
        <FormSelector
          options={students}
          selectedValues={form.student_profile_ids}
          optionComponent={this.optionComponent}
          onChange={this.updateSelector}
          search={this.searchOptions}
          searchPlaceholder="Buscar alunos"
          optionAccessor={(option) => option.id}
          selectAllLabel="Selecionar todos"
          onSelectBehavior="highlight"
          counterLabel={(length) => (length === 1 ? 'aluno' : 'alunos')}
          emptyState={this.emptyStateComponent()}
        />
      </div>
    );
  }
}

export default withAppContext(withFormContext(StudentsProgressionForm));
