import validatePresence from 'core/lib/FormValidator/validators/presence';
import attending from 'core/lib/FormValidator/validators/attending';

export default [
  {
    attributeName: 'name',
    validator: validatePresence,
    message: 'required_field',
  },

  {
    attributeName: 'description',
    validator: validatePresence,
    message: 'required_field',
  },

  {
    attributeName: 'attending_hours',
    validator: attending,
    message: 'required_field',
  },
];
