import React from 'react';
import { Box, DefaultThemeProps, theme } from '@agendaedu/ae-web-components';

import Skeleton from 'components/Skeleton';

import { StorageSkeletonProps as Props } from './types';

const StorageSkeleton: React.FC<Props> = ({ items }): React.ReactElement => {
  const defaultTheme: DefaultThemeProps = theme;

  const {
    colors: { neutral },
    border: { radius },
  } = defaultTheme;

  return (
    <Box data-testid="skeleton" display="flex" flexDirection="column" gap="sm">
      {Array.from(Array(items).keys()).map((n) => (
        <Skeleton
          key={`skeleton-item-${n}`}
          width="100%"
          height={80}
          primaryColor={neutral.gray6}
          borderRadius={radius.md}
          isFullWidth
        />
      ))}
    </Box>
  );
};

export default StorageSkeleton;
