import {
  ActiveChannel,
  AttendingHours,
  AttendingHoursRequest,
  Channel,
  ChannelRequest,
} from 'store/messages/omniChannel/types';

export const normalizeAttendingHoursRequest = (
  attendingHours: AttendingHours
  // eslint-disable-next-line @typescript-eslint/ban-types
): AttendingHoursRequest | object => {
  if (!attendingHours) return {};

  const days = Object.keys(attendingHours.days).reduce((acc, key) => {
    acc[key] = {
      ...attendingHours.days[key],
      active: attendingHours.days[key].active ? '1' : '0',
    };
    return acc;
  }, {});

  return {
    blockOnlyNotification: attendingHours.blockOnlyNotification ? '1' : '0',
    days,
  };
};

export const normalizeAttendingHours = (
  attendingHours: AttendingHoursRequest
): AttendingHours | null => {
  if (Object.keys(attendingHours).length === 0) return null;

  const days = Object.keys(attendingHours.days).reduce((acc, key) => {
    acc[key] = {
      ...attendingHours.days[key],
      active: attendingHours.days[key].active === '1',
    };
    return acc;
  }, {});

  const blockOnlyNotification = attendingHours.blockOnlyNotification === '1';

  return {
    blockOnlyNotification,
    days,
  };
};

export const normalizeCurrentChannel = (channel: ChannelRequest): Channel => {
  const newChannel = {
    ...channel,
    attributes: {
      ...channel.attributes,
      attendingHours: normalizeAttendingHours(
        channel.attributes.attending_hours
      ),
      attendingClosed: channel?.attributes.attending_closed,
    },
  };

  delete newChannel.attributes.attending_closed;
  delete newChannel.attributes.attending_hours;

  return newChannel;
};

export const normalizeChannels = (channels: ChannelRequest[]): Channel[] => {
  if (channels.length < 0) return channels;

  const newChannels = channels.map((channel) => {
    const newChannel = {
      ...channel,
      attributes: {
        ...channel.attributes,
        attendingHours: normalizeAttendingHours(
          channel.attributes.attending_hours
        ),
        attendingClosed: channel?.attributes.attending_closed,
      },
    };

    delete newChannel.attributes.attending_closed;
    delete newChannel.attributes.attending_hours;
    return newChannel;
  });

  return newChannels;
};

export const normalizeActiveChannel = (channel: Channel): ActiveChannel => {
  return {
    id: channel.id,
    name: channel.attributes.name,
    iconUrl: channel.attributes.icon_url,
    kind: channel.attributes.kind,
    attendingHours: channel.attributes.attendingHours,
    attendingClosed: channel.attributes.attendingClosed,
  };
};
