import React, { memo, useCallback, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar, Text } from '@agendaedu/ae-web-components';

import AttachmentEntity from 'core/entities/Attachment';
import useOutsideClick from 'core/hooks/useOutsideClick';

import OmniChannelActions from 'store/messages/omniChannel/actions';
import { OmniChannelState } from 'store/messages/omniChannel/types';

import MessagesAttachment from 'components/Messages/Attachment';
import MessageInfo from 'components/Messages/Info';
import { MessageActions } from '../MessageActions';
import { MessageBallonContent } from '../MessageBallonContent';
import { MessageApprove } from '../MessageApprove';

import * as S from './styles';

import { MessageBalloonProps } from './types';

/**
 * @deprecated This component will be removed, use components/MessageBalloon
 */
const MessageBalloon = ({ message }: MessageBalloonProps): JSX.Element => {
  const dispatch = useDispatch();

  const [messageActiveActions, setMessageActiveActions] = useState(false);

  const actionRef = useRef(null);

  useOutsideClick(actionRef, () => setMessageActiveActions(false));

  const { activeTicket, activeChat, activeChannel } = useSelector(
    (state: OmniChannelState) => state.omniChannel
  );

  const hasAttendingClosed =
    activeChannel.attendingClosed &&
    !activeChannel.attendingHours?.blockOnlyNotification;

  const disabledChat =
    hasAttendingClosed ||
    (activeChat?.attributes.kind === 'ticket' &&
      (activeTicket?.attributes.status === 'done' ||
        activeTicket?.attributes.status === 'waiting'));

  const { setRedirectChannelPage } = OmniChannelActions;

  const {
    type,
    file,
    approvalStatus,
    metadata: { anchoringData, ticketBotMessage },
    canAccessRedirectedChat,
    status,
    text,
    sentAt,
    user: {
      attributes: {
        avatar_color: avatarColor,
        avatar_url: avatarUrl,
        name_initials: nameInitials,
        name,
        role,
      },
    },
  } = message.attributes;

  const isChatRedirection = anchoringData?.type === 'chatRedirection';

  const isDeletedMessage = status === 'deleted';

  const isMyOwnerMessage = type !== 'received' || isChatRedirection;

  const messageType = isChatRedirection ? 'sent' : type;

  const hasMessageActions =
    !disabledChat &&
    !(isMyOwnerMessage && isDeletedMessage) &&
    !isChatRedirection;

  const hasMessageFile = file && !isDeletedMessage;

  const handleRedirectChannelMessage = useCallback(() => {
    if (isChatRedirection) {
      const params = {
        channelId: anchoringData.channelId,
        chatId: anchoringData.chatId,
        canAccessRedirect: canAccessRedirectedChat,
      };
      dispatch(setRedirectChannelPage(params));
    }
  }, [
    anchoringData,
    canAccessRedirectedChat,
    dispatch,
    isChatRedirection,
    setRedirectChannelPage,
  ]);

  const handleActiveMessageActions = () => {
    setMessageActiveActions(!messageActiveActions);
  };

  return (
    <>
      {!ticketBotMessage ? (
        <S.WrapperMessage
          approvalStatus={approvalStatus}
          variantType={messageType}
          data-testid="message-wrapper"
          isChatRedirection={isChatRedirection}
        >
          {!isMyOwnerMessage && (
            <S.AvatarContainer data-testid="avatar-container">
              <Avatar
                size={'xs'}
                avatarUrl={avatarUrl}
                avatarColor={avatarColor}
                nameInitials={nameInitials}
              />
            </S.AvatarContainer>
          )}

          <S.MessageContainer
            data-testid="message-container"
            onClick={handleActiveMessageActions}
            ref={actionRef}
            hasAction={hasMessageActions}
          >
            <S.ContentWrapper
              data-testid="content-wrapper"
              onClick={handleRedirectChannelMessage}
            >
              {!isMyOwnerMessage && (
                <S.MessageUser
                  variant="title-bold-12"
                  data-testid="message-user"
                >{`${name} ${role && `• ${role}`}`}</S.MessageUser>
              )}

              <MessageBallonContent message={message} />

              {hasMessageFile && (
                <MessagesAttachment
                  attachment={new AttachmentEntity(file)}
                  variation={messageType}
                />
              )}
              <MessageApprove message={message} />
            </S.ContentWrapper>
            {hasMessageActions && (
              <MessageActions
                message={message}
                isActiveActions={messageActiveActions}
              />
            )}
          </S.MessageContainer>

          <S.InfoContainer>
            <MessageInfo message={message} showApprovalInfo />
          </S.InfoContainer>
        </S.WrapperMessage>
      ) : (
        <S.BotMessageWrapper data-testId="bot-atutomatic-message">
          <Text variant="title-bold-12" color="neutral.gray1" mb={0}>
            {`${sentAt} • ${text}`}
          </Text>
        </S.BotMessageWrapper>
      )}
    </>
  );
};

export default memo(MessageBalloon);
