import styled, { css } from 'styled-components';
import { ThemeProps } from 'config/themes/types';

export const Wrapper = styled.div`
  ${({ theme: { colors, border } }: ThemeProps) => css`
    display: flex;
    flex-direction: column;
    .Accordion {
      .accordion__button {
        height: auto;
        border: 1px solid ${colors.neutral.gray4};
        border-radius: ${border.radius.md};
        background-color: ${colors.neutral.gray5};
        &:before {
          color: ${colors.neutral.black};
        }
        &[aria-expanded='true'] {
          border-radius: ${border.radius.md} ${border.radius.md} 0 0px;
          background-color: ${colors.neutral.gray5};
        }
      }
      .accordion__panel {
        background-color: ${colors.neutral.white};
        padding: 0px;
      }
    }
  `}
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  width: 100%;
`;

export const InformationsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
`;

export const HeaderText = styled.span`
  ${({ theme: { font, colors } }: ThemeProps) => css`
    font-family: ${font.family.brand};
    font-weight: ${font.weight.bold};
    font-size: ${font.size.md};
    line-height: ${font.height.lg};
    color: ${colors.neutral.black};
  `}
`;
