import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withFormContext from 'core/hoc/withFormContext';

import FormValidationErrors from 'components/Form/ValidationErrors';

import './SimpleTextArea.scss';

class SimpleTextArea extends Component {
  /**
   * Delete props that should not be used by <input>.
   */
  UNSAFE_componentWillMount() {
    this.props.className && delete this.props.className;
    this.props.value && delete this.props.value;
    this.props.row && delete this.props.row;
    this.props.smallLabel && delete this.props.smallLabel;
  }

  onChange() {
    const { onChange, attributeName, formContext } = this.props;
    const { changeAttribute } = formContext;

    if (onChange) {
      return changeAttribute(attributeName, onChange);
    } else {
      return changeAttribute(attributeName);
    }
  }

  render() {
    const { attributeName, formContext } = this.props;
    const { changeAttribute, form, hasErrorOnAttribute } = formContext;
    const hasError = hasErrorOnAttribute(attributeName);

    return (
      <div>
        <textarea
          {...this.props}
          type="text"
          className="SimpleTextArea form-control"
          onChange={this.onChange()}
          value={form[attributeName]}
        />
        {hasError && <FormValidationErrors attributeName={attributeName} />}
      </div>
    );
  }
}

SimpleTextArea.defaultProps = {
  rows: 5,
  cols: 20,
};

export default withFormContext(SimpleTextArea);
