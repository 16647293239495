import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import withFormContext, { formPropTypes } from 'core/hoc/withFormContext';
import { defaultIsOutsideRange } from 'core/utils/date';
import { flags } from 'core/constants/flags';

import AgendaIcon from 'components/AgendaIcon';
import Alert from 'components/Alert';
import CheckPaymentMethods from 'components/Form/CheckPaymentMethods';
import InstallmentFeesConfiguration from 'components/Payments/PaymentsContainer/FormSections/InstallmentFeesConfiguration';
import FormCheckbox from 'components/Form/Checkbox';
import Field from 'components/Form/Field';
import Title from 'components/WizardForm/Title';
import { TOP_CENTER } from 'components/Tooltip';

import actionSchoolProducts from 'store/edupay/schoolProducts/actions';

import * as S from './styles';

const BillConfiguration = ({
  formContext: { form, formMeta, updateAttribute },
  isSchoolProduct,
  isEnrollment,
}) => {
  const dispatch = useDispatch();

  const {
    allowed_payment_method: paymentMethodChosen,
    max_installments: maxInstallments,
    price,
  } = form;

  const { status, tax, select_options: selectOptions } = formMeta;

  const disabledOnEdit = !['pending'].includes(status);

  const {
    policies: {
      school_plan,
      can_config_school_product_payment_methods,
      can_config_enrollment_plan_payment_methods,
      edupay_active_flags,
      edupay_provider,
      can_absorb_tax,
    },
  } = useSelector((state) => state.root);

  const { fetchCurrentTaxAmount } = actionSchoolProducts;

  const calculateTax = () => {
    if (price && tax) return price * (tax / 100);
    return 0;
  };

  const handleSetNewTax = () => {
    const currentTax = calculateTax();
    updateAttribute('tax', currentTax);
  };

  const handleCurrentTax = () => {
    const params = {
      amount: price,
    };
    dispatch(fetchCurrentTaxAmount(params));
  };

  const absorbLabel = () => {
    const content = (
      <p>
        As taxas serão somadas ao valor da
        <br />
        cobrança e repassadas ao responsável.
        <br />
        <br />
        <a
          href="https://suporte.agendaedu.com/hc/pt-br/articles/360022222214-Entenda-a-nossa-taxa-de-servi%C3%A7o"
          target="_blank"
          rel="noopener noreferrer"
        >
          Entenda a taxa
        </a>
      </p>
    );

    return (
      <S.AbsorbOptionsInformation>
        <span>Repassar taxa</span>
        <S.CustomTooltip content={content} position={TOP_CENTER}>
          <AgendaIcon name="help" size="small" className="custom-size" />
        </S.CustomTooltip>
      </S.AbsorbOptionsInformation>
    );
  };

  const toggleCheckbox = (attributeName) => {
    const value = form[attributeName];

    updateAttribute(attributeName, !value);
  };

  const renderExpiresAtField = () => {
    const content = (
      <p>
        A data de vencimento será a mesma
        <br />
        independente do método de pagamento.
      </p>
    );

    return (
      <S.ExpiresAtField className="Field">
        <div className="labelRow">
          <span>Data de vencimento*</span>
          <S.CustomTooltip content={content} position={TOP_CENTER}>
            <AgendaIcon name="help" size="small" />
          </S.CustomTooltip>
        </div>
        <Field
          attributeName="expires_at"
          type="date"
          size="small"
          mobileSize="extraLarge"
          isOutsideRange={defaultIsOutsideRange}
        />
      </S.ExpiresAtField>
    );
  };

  const renderCheckbox = (absorbMethod, isDisabled) => {
    const paymentMethod = {
      absorb_pix_tax: 'PIX',
      absorb_billet_tax: 'Boleto',
      absorb_credit_card_tax: 'Cartão de crédito à vista',
      absorb_installment_tax: 'Cartão de crédito parcelado',
    };

    return (
      <div className="checkbox-option">
        <FormCheckbox
          checked={!form[absorbMethod]}
          onChange={() => !toggleCheckbox(absorbMethod)}
          disabled={isDisabled}
        />
        <span onClick={() => !isDisabled && !toggleCheckbox(absorbMethod)}>
          {paymentMethod[absorbMethod]}
        </span>
      </div>
    );
  };

  const renderCheckPaymentMethods = () => {
    let schoolHasPermission = true;
    if (isSchoolProduct)
      schoolHasPermission = can_config_school_product_payment_methods;
    else if (isEnrollment)
      schoolHasPermission = can_config_enrollment_plan_payment_methods;

    return (
      <>
        <Title name="Métodos de pagamentos" />
        <S.CheckPaymentWrapper>
          <CheckPaymentMethods
            attributeName="allowed_payment_method"
            disabled={disabledOnEdit || !schoolHasPermission}
            withPix
            canConfigPaymentMethods={schoolHasPermission}
            isNewLayout={edupay_provider === 'bemobi'}
          />
        </S.CheckPaymentWrapper>
      </>
    );
  };

  return (
    <S.BillConfigurationWrapper>
      {renderCheckPaymentMethods()}

      {can_absorb_tax && (
        <InstallmentFeesConfiguration disabled={disabledOnEdit} />
      )}

      <Title name="Valores e vencimentos" />
      <S.InputsConfigurationWrapper>
        <S.DoubleInpurtWrapper>
          <Field
            label="Valor da cobrança*"
            type="currency"
            attributeName="price"
            disabled={disabledOnEdit}
            onChange={handleSetNewTax}
            onBlur={handleCurrentTax}
          />
          {renderExpiresAtField()}
        </S.DoubleInpurtWrapper>
        {paymentMethodChosen.includes('credit_card') &&
          edupay_provider !== 'bemobi' && (
            <Field
              fullWidth
              withArrowSelect
              label="Nº de parcelas"
              type="selectWithIcon"
              attributeName="max_installments"
              value={maxInstallments}
              options={selectOptions.max_installments}
              disabled={disabledOnEdit}
              placeholder={null}
              onChange={(e) => updateAttribute('max_installments', e.value)}
            />
          )}

        {!isEnrollment &&
          !school_plan === 'trial' &&
          edupay_active_flags?.includes(flags.ABSORB_TAX) && (
            <Alert variation="info">
              O repasse de taxas para pagamentos via cartão de crédito está
              temporariamente indisponível.
            </Alert>
          )}
      </S.InputsConfigurationWrapper>
      {!isEnrollment && edupay_active_flags?.includes(flags.ABSORB_TAX) && (
        <S.AbsorbOptionsWrapper>
          {absorbLabel()}
          <S.AbsorbOptionsCheckbox>
            {paymentMethodChosen.includes('credit_card') &&
              renderCheckbox('absorb_credit_card_tax', disabledOnEdit)}

            {paymentMethodChosen.includes('credit_card') &&
              maxInstallments > 1 &&
              renderCheckbox('absorb_installment_tax', disabledOnEdit)}

            {paymentMethodChosen.includes('billet') &&
              renderCheckbox('absorb_billet_tax', disabledOnEdit)}

            {paymentMethodChosen.includes('pix') &&
              renderCheckbox('absorb_pix_tax', disabledOnEdit)}
          </S.AbsorbOptionsCheckbox>
        </S.AbsorbOptionsWrapper>
      )}
    </S.BillConfigurationWrapper>
  );
};

BillConfiguration.propTypes = {
  ...formPropTypes,
  action: PropTypes.string,
  isSchoolProduct: PropTypes.bool,
  isEnrollment: PropTypes.bool,
};

BillConfiguration.defaultProps = {
  action: 'new',
  isSchoolProduct: false,
  isEnrollment: false,
};

export default withFormContext(BillConfiguration);
