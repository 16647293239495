import styled, { css } from 'styled-components';

import PageContainer from 'components/PageContainer';

import { ThemeProps } from 'config/themes/types';
import { StylesProps } from './types';

export const HandoutDetailsWrapper = styled(PageContainer)`
  ${({
    theme: { border, colors, space, typography, breakpoints },
  }: ThemeProps) => css`
    min-height: 100vh;
    margin: ${space.xs4};
    padding: ${space.xl} ${space.lg};

    i {
      color: ${colors.brand.primary.default};
    }

    .container-content {
      padding: ${space.xs4};
      border-radius: ${border.radius.md};

      .main-content {
        padding: ${space.xs4};

        .Tabs.default {
          .tabs-header {
            .tab-list {
              .react-tabs__tab-list {
                margin: ${space.xs4};

                .react-tabs__tab {
                  padding: ${space.sm} ${space.lg} ${space.xs4} ${space.lg};
                  margin: ${space.xs4};
                  height: 52px;

                  position: relative;
                }

                .selected-tab {
                  &::after {
                    content: '';
                    position: absolute;
                    left: ${space.xs4};
                    right: ${space.xs4};
                    width: 100%;
                    height: 4px;
                    border-radius: ${space.xs3} ${space.xs3} ${space.xs4}
                      ${space.xs4};
                  }
                }
              }
            }
          }
        }

        .info-card {
          .icon-area {
            width: ${space.xl3};
            height: ${space.xl3};
            padding: ${space.xs4};

            background-color: ${colors.brand.primary.op10};

            i.icon-ae-send {
              color: ${colors.brand.primary.default};
            }

            display: flex;
            align-items: center;
            justify-content: center;
          }

          p {
            ${typography.subtitle.Medium14};
            color: ${colors.neutral.gray2};
          }

          p.information {
            ${typography.body.Regular16};
            color: ${colors.neutral.black};
          }

          @media only screen and (max-width: ${breakpoints.mobileSM}) {
            width: 100%;
          }
        }

        .handout-attachments {
          div {
            padding: ${space.xs4};

            span {
              ${typography.subtitle.Medium14};
              color: ${colors.neutral.gray2};
            }
          }

          ul {
            li {
              width: 100%;
              max-width: 617px;
              border-radius: ${border.radius.md};
              padding: ${space.xs2} ${space.xs};
              border: ${border.width.sm} ${border.style.solid}
                ${colors.neutral.gray4};

              i.icon-ae-attachment {
                color: ${colors.neutral.gray1};
              }

              span {
                ${typography.label.Regular14};
                color: ${colors.neutral.gray1};

                &:last-child {
                  ${typography.body.Medium14};
                }
              }
            }
          }
        }

        .handout-history-section {
          margin: ${space.xs4};

          p {
            width: 200px;
            text-overflow: ellipsis;
          }

          .Button {
            span {
              color: ${colors.brand.primary.default};
            }

            &:hover {
              background-color: ${colors.brand.primary.op20};
            }

            @media only screen and (max-width: 992px) {
              i.icon-ae-history {
                margin: ${space.xs4};
              }
            }
          }

          @media only screen and (min-width: ${breakpoints.desktopLG}) {
            p {
              width: 280px;
            }
          }
        }
      }
    }

    @media only screen and (min-width: 820px) {
      padding: ${space.xl2} ${space.xl};
    }

    @media only screen and (min-width: 1024px) {
      padding: ${space.xl} ${space.xl5};
    }
  `}
`;

export const HandoutDetailsSkeleton = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    min-height: 100vh;
    margin: ${space.xs4};
    padding: ${space.xl} ${space.lg};

    @media only screen and (min-width: 820px) {
      padding: ${space.xl2} ${space.xl};
    }

    @media only screen and (min-width: 1024px) {
      padding: ${space.xl} ${space.xl5};
    }
  `}
`;

export const TabsSkeleton = styled.div`
  ${({ theme: { border, space } }: ThemeProps) => css`
    padding: ${space.xs4};
    border-radius: ${border.radius.md};
    box-shadow: 0 0 10px 0 rgba(51, 51, 51, 0.15);
  `}
`;

export const TabsHeaderSkeleton = styled.div`
  ${({ theme: { border, colors } }: ThemeProps) => css`
    height: 52px;
    border-bottom: ${border.width.sm} ${border.style.solid}
      ${colors.neutral.gray4};

    display: flex;
    align-items: center;
  `}
`;

export const TabSkeleton = styled.div<StylesProps>`
  ${({ theme: { colors, space }, isSelected }) => css`
    height: 52px;
    padding: ${space.sm} ${space.lg} ${space.xs4} ${space.lg};

    position: relative;

    ${isSelected &&
    css`
      &::after {
        content: '';
        position: absolute;
        bottom: ${space.xs4};
        right: ${space.xs4};
        left: ${space.xs4};
        width: 100%;
        height: ${space.xs3};
        border-radius: ${space.xs3} ${space.xs3} ${space.xs4} ${space.xs4};
        background-color: ${colors.neutral.gray3};
      }
    `}
  `}
`;
