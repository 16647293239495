import * as TYPES from './types';

export const fetchSponteFiltersRequest = () => ({
  type: TYPES.FETCH_REQUEST,
});

export const setSchoolTerms = filters => ({
  type: TYPES.SET_SCHOOL_TERMS,
  filters,
});

export const updateSponteFiltersSent = () => ({
  type: TYPES.UPDATE_SENT,
});

export const updateSponteFiltersRequest = params => ({
  type: TYPES.UPDATE_REQUEST,
  params,
});
