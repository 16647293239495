import { NewMessageParams } from 'store/messages/omniChannel/types';

export const normalizeNewMessageParams = (params: NewMessageParams) => {
  const {
    chatId,
    message,
    studentProfileId,
    userId,
    userType,
    chatKind,
    attachedMessageId,
    attachment,
    ticketId,
  } = params;

  const isTicketMessage = chatKind === 'ticket';

  message.trim();

  const data = new FormData();

  if (attachedMessageId)
    data.append('message[attached_message_id]', attachedMessageId);

  const chatRecipients = {
    chat_type: 'chat',
    kind: chatKind,
    chat_id: chatId,
    student_profile_id: studentProfileId,
    user: {
      id: userId,
      type: userType,
    },
  };

  const ticketRecipients = {
    chat_type: 'ticket',
    kind: chatKind,
    ticket_id: ticketId,
    student_profile_id: studentProfileId,
    user: {
      id: userId,
      type: userType,
    },
  };

  const resource = isTicketMessage ? 'messaging_domain/ticket' : 'chat';

  data.append('resource', resource);
  data.append('message[message]', message.trim() || '');
  data.append(
    'recipients',
    JSON.stringify([isTicketMessage ? ticketRecipients : chatRecipients])
  );

  if (attachment) {
    data.append(
      'message[document]',
      attachment.getUploadedFile(),
      attachment.getName()
    );
  }

  return data;
};
