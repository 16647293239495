import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { OmniChannelState } from 'store/messages/omniChannel/types';
import tabifyForWizard from 'core/hoc/tabifyForWizard';
import withAppContext from 'core/hoc/withAppContext';
import withFormContext from 'core/hoc/withFormContext';

import GroupSelector from 'components/Form/GroupSelector';

import * as S from './styles';

import { ClassroomStepProps } from './types';

const ClassroomsStep = ({
  formContext: {
    form: { classroom_ids },
    changeMeta,
  },
}: ClassroomStepProps) => {
  const { t } = useTranslation(['messages']);

  const canGoToNextStep = classroom_ids?.length;

  const { classrooms } = useSelector(
    (state: OmniChannelState) => state.omniChannel
  );

  useEffect(() => {
    canGoToNextStep
      ? changeMeta('nextStepDisabled', false)
      : changeMeta('nextStepDisabled', true);
  }, [changeMeta, canGoToNextStep]);

  return (
    <S.ClassroomsStepWrapper>
      <S.RowWrapper>
        <S.TitleWrapper>
          <S.StepTitle variant="title-bold-20">
            {t('omni_channel.forms.message_channel.steps.second')}
          </S.StepTitle>

          <S.StepSubtitle lineHeight="lg" variant="label-regular-16">
            {t('omni_channel.forms.message_channel.steps_subtitle.classrooms')}
          </S.StepSubtitle>
        </S.TitleWrapper>

        <GroupSelector options={classrooms} attributeName="classroom_ids" />
      </S.RowWrapper>
    </S.ClassroomsStepWrapper>
  );
};

export default tabifyForWizard({
  title: '2 Turmas',
})(withAppContext(withFormContext(ClassroomsStep)));
