import React from 'react';
import { useSelector } from 'react-redux';

import tabifyForWizard from 'core/hoc/tabifyForWizard';

import Alert from 'components/Alert';
import LabeledContainer from 'components/LabeledContainer';
import Title from 'components/WizardForm/Title';
import UserDisplay from 'components/UserDisplay';

// Sections
import EdupayDiscount from 'components/Payments/PaymentsContainer/FormSections/EdupayDiscount';
import FinesAndInterest from '../../RecurrentFormSections/FinesAndInterest';
import RecurrencyConfiguration from 'components/Payments/PaymentsContainer/FormSections/RecurrencyConfiguration';
import RecurrentItems from '../../RecurrentFormSections/RecurrentItems';
import RecurrentSummary from '../../RecurrentFormSections/RecurrentSummary';
import WalletConfiguration from 'components/Payments/PaymentsContainer/FormSections/WalletConfiguration';
import FinancialResponsibleConfiguration from 'components/Payments/PaymentsContainer/FormSections/FinancialResponsibleConfiguration';

import validations from './validations.js';

const EditRecurrentBillInformations = () => {
  const { current } = useSelector((state) => state.recurrentBills);
  const recurrentPlan = useSelector((state) => state.recurrentPlans.current);

  return (
    <div className="EditRecurrentBill">
      <fieldset className="FormFieldset col-xs-12 col-lg-8 col-lg-offset-2">
        <div className="double-input">
          <UserDisplay
            user={recurrentPlan.studentProfile}
            size="medium"
            classroomsNames={
              recurrentPlan.studentProfile.attributes.classroom_names
            }
          />
          <LabeledContainer title="Titulo da cobrança">
            {recurrentPlan.recurrentPlan.title}
          </LabeledContainer>
        </div>
        <WalletConfiguration isRecurrent recurrentBill />
        <FinancialResponsibleConfiguration />
        <Title name="Editar vencimento" />
        <Alert variation="info">{`Você está editando a cobrança ${current.attributes.bill_number} de ${recurrentPlan.recurrentPlan.bills_quantity}`}</Alert>
        <RecurrencyConfiguration type="bill" />
        <RecurrentItems title="Editar itens da cobrança" />
        <FinesAndInterest />
        <EdupayDiscount
          type="bill"
          action="editBill"
          validations={validations}
          showCreditCardDiscount
        />
        <RecurrentSummary />
      </fieldset>
    </div>
  );
};

export default tabifyForWizard({
  title: '1. Informações',
  validations,
})(EditRecurrentBillInformations);
