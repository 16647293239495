export const constants = Object.freeze({
  NAME_TAG_AE: 'Agenda Edu',
});

export const USER_ACTIONS = Object.freeze({
  activate: 'activate',
  deactivate: 'deactivate',
  lead: 'lead',
  buy: 'buy',
  none: 'none',
});
