import React from 'react';

import { Box } from '@agendaedu/ae-web-components';
import { TextQuestion, SelectQuestion } from './components';
import { DiaryFormStructure } from 'store/dailySummaries/types';

import * as S from './styles';

import { Header } from '../components/Header';
import { Props } from './types';

export const isEmptyCustomValues = (
  answersAttributesList: Props['answersAttributesList']
) =>
  answersAttributesList.every(
    (question) => !question.answer_text && !question.diary_answer_option_id
  );

export const CustomCard = ({
  section,
  onChange,
  answersAttributesList,
  student,
  footer,
}: Props): React.ReactElement => {
  const getKindComponent = (
    answersAttributes: DiaryFormStructure['answers_attributes_values'],
    index: number
  ) => {
    const question = section.questionsAttributes.find(
      (question) => question.id === answersAttributes.diary_question_id
    );

    return {
      text: (
        <TextQuestion
          question={question}
          onChange={(values) => onChange(values, index)}
          value={answersAttributes.answer_text}
        />
      ),
      select: (
        <SelectQuestion
          question={question}
          onChange={(values) => onChange(values, index)}
          value={answersAttributes.diary_answer_option_id}
        />
      ),
    }[
      section.questionsAttributes.find(
        (question) => question.id === answersAttributes.diary_question_id
      ).kind
    ];
  };

  return (
    <S.CustomWrapper data-testid="custom-card">
      <Header sectionTitle={section.name} student={student} />

      <Box>
        {answersAttributesList.map((value, index) => (
          <Box
            key={`kind-component-${section.name}-${value.key}`}
            mt={index > 0 ? 'md' : 0}
          >
            {getKindComponent(value, index)}
          </Box>
        ))}
      </Box>

      {footer}
    </S.CustomWrapper>
  );
};
