import { all, call, put, takeLatest, select } from 'redux-saga/effects';
import { fetchApi } from 'core/utils/api';
import actions from './actions';

function* generateInviteUrl({ resource }) {
  try {
    const dataArea = yield select((state) => state.root.dataArea);

    const { history } = yield call(
      fetchApi,
      `/${dataArea}/invites/${resource}/generate_url.json`
    );

    yield put({
      type: actions.GENERATE_INVITE_URL_SUCCESS,
      url: history.invite_url,
    });
  } catch (error) {
    yield put({
      type: actions.SET_ERROR,
      error,
    });
  }
}

function* inviteEmptyStateSagas() {
  yield all([takeLatest(actions.GENERATE_INVITE_URL, generateInviteUrl)]);
}

export default inviteEmptyStateSagas;
