import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autobind from 'autobind-decorator';
import FormGroupSelectorSelectableColumn from 'components/Form/GroupSelector/SelectableColumn';
import AgendaIcon from 'components/AgendaIcon';
import arrayFlatten from 'core/utils/arrayFlatten';

import withFormContext from 'core/hoc/withFormContext';

import './style.scss';

/**
 * Guia:
 * Esse componente é atualmente utilizado para selecionar turmas
 * Mas pode ser utilizado para qualquer tipo de seleção
 *
 * Modo default do formato de classrooms:
 * [
 *   {
 *     label: 'Principal',
 *     options: [
 *       {
 *         id: 1,
 *         attributes: {
 *           name: 'Turma 1',
 *         },
 *       },
 *     ],
 *   },
 * ];
 *
 * Usando esse padrão de objeto, pode-se usar para selecionar qualquer tipo de model
 * Após ultima refatoração, para usar nesse formaté é bem simples
 * <GroupSelector
 *   options={classrooms}
 *   attributeName="classroom_ids"
 * />
 *
 * Onde options, é um array das turmas no exemplo acima
 * e attributeName o nome do attribute que serão salvos os ids selecionados
 *
 * Caso queira modificar o comportamento padrão, deverá sobrescrever as props de
 * optionComponent, OptionAccessor, groupComponent, groupAccessor
 * Sobrescrevendo então como as opções são mostradas e acessadas.
 * Olhando os componentes defaults dessa clase, é um bom jeito de se basear.
 */

class FormGroupSelector extends Component {
  static propTypes = {
    options: PropTypes.array.isRequired,
    optionComponent: PropTypes.func,
    groupComponent: PropTypes.func,
    groupAccessor: PropTypes.string,
    optionAccessor: PropTypes.string,
    search: PropTypes.func,
    searchPlaceholder: PropTypes.string,
    selectAllLabel: PropTypes.string,
    deselectAllLabel: PropTypes.string,
    selectedValues: PropTypes.array,
    counterLabel: PropTypes.func,
    onChange: PropTypes.func,
    lockSelected: PropTypes.bool,
    lockOptions: PropTypes.bool,
    emptyState: PropTypes.node,
    singleSelectPerGroup: PropTypes.bool,
    showSelectAll: PropTypes.bool,
  };

  static defaultProps = {
    onSelectBehavior: 'remove',
    selectAllLabel: 'Selecionar todas',
    deselectAllLabel: 'Remover todas',
    lockOptions: false,
    lockSelected: false,
    singleSelectPerGroup: false,
  };

  state = {
    isSearching: false,
    filteredOptions: [],
    selectedOptions: [],
    options: [],

    isSelectedSearching: false,
    filteredSelectedOptions: [],
  };

  componentDidMount() {
    const { options } = this.props;

    this.setState({ options });
    this.getSelectedOptions();
  }

  onChange = (selectedValues, selectedGroups) => {
    const {
      onChange,
      attributeName,
      formContext: { updateAttribute },
    } = this.props;

    if (onChange) {
      if (selectedGroups) {
        onChange(selectedValues, selectedGroups);
        return;
      }

      onChange(selectedValues);
      return;
    }

    updateAttribute(attributeName, selectedValues);
  };

  // if there is already selectedValues, load them to selected column
  @autobind
  getSelectedOptions() {
    const {
      options,
      lockSelected,
      lockOptions,
      selectedValues,
      formContext: { form },
      attributeName,
    } = this.props;

    const values = selectedValues ? selectedValues : form[attributeName] || [];
    const newSelected = [];
    const newOptions = [];

    if (lockOptions) {
      this.lockSelectedOptions(options);
      this.setState({ options });
    }

    options.forEach((classroomGroup) => {
      newSelected.push({
        label: classroomGroup.label,
        options: classroomGroup.options.filter((opt) =>
          values.includes(this.optionAccessor()(opt))
        ),
      });

      // if lockSelected prop is true, lock the already selectedValues
      if (lockSelected) this.lockSelectedOptions(newSelected);

      newOptions.push({
        label: classroomGroup.label,
        options: classroomGroup.options.filter(
          (opt) => !values.includes(this.optionAccessor()(opt))
        ),
      });
    });

    this.setState({
      options: this.removeEmptyGroups(newOptions),
      selectedOptions: this.removeEmptyGroups(newSelected),
    });
  }

  // set options to locked( unable to deselect)
  @autobind
  lockSelectedOptions(selectedOptions) {
    selectedOptions.forEach((group) => {
      group.options.forEach((option) => {
        option.locked = true;
      });
    });
  }

  // return all option values from optionGroup array
  @autobind
  getSelectedValues(selectedOptions) {
    const { optionAccessor } = this.props;

    return arrayFlatten(selectedOptions.map((group) => group.options)).map(
      (option) => this.optionAccessor()(option)
    );
  }

  @autobind
  removeEmptyGroups(options) {
    return options.filter((group) => group.options.length > 0);
  }

  // run when user click on one option
  @autobind
  onOptionSelect({ option, optionGroup, placeOption, removeSelectedOption }) {
    const { isSearching, filteredOptions, selectedOptions, options } =
      this.state;

    const { singleSelectPerGroup } = this.props;

    let newSelected = [];
    let newFiltered = filteredOptions;
    let newOptions = options;

    newSelected = placeOption(selectedOptions, optionGroup, option); // place option in the selected array

    newOptions = removeSelectedOption(options, optionGroup, option); // remove option from options array

    //if singleSelectPerGroup Prop is true, when the group is already in the selected array, remove the previous options from there and place to options array
    if (singleSelectPerGroup) {
      const groupAlreadySelected = newSelected.find(
        (select) =>
          this.groupAccessor()(select) === this.groupAccessor()(optionGroup)
      );

      if (groupAlreadySelected?.options.length > 1) {
        const removedFromSelected = groupAlreadySelected.options.shift();

        const optionsGroupIndex = newOptions.findIndex(
          (item) =>
            this.groupAccessor()(item) === this.groupAccessor()(optionGroup)
        );

        if (optionsGroupIndex !== -1) {
          newOptions[optionsGroupIndex].options.push({
            ...removedFromSelected,
            locked: false,
          });
        } else {
          newOptions.push({
            label: optionGroup.label,
            options: [removedFromSelected],
          });
        }
      }
    }

    if (isSearching && filteredOptions) {
      newFiltered = removeSelectedOption(filteredOptions, optionGroup, option); // if is searching also remove from filtered array
    }
    const selectedValues = this.getSelectedValues(newSelected);

    this.onChange(selectedValues, newSelected);
    this.setState({
      filteredOptions: newFiltered,
      selectedOptions: newSelected,
      options: newOptions,
    });
  }

  // run when user click on a option in the selected column
  @autobind
  onOptionDeselect({ option, optionGroup, placeOption, removeSelectedOption }) {
    const { search } = this.props;

    let newSelected = [];
    let newOptions = [];
    let newFiltered = [];

    const { isSearching, selectedOptions, options, searchTerm } = this.state;

    newOptions = placeOption(options, optionGroup, option); // place option in the options array
    newSelected = removeSelectedOption(selectedOptions, optionGroup, option); // remove option from selected array

    if (isSearching) {
      newFiltered = search({ searchTerm, options }); // if searching check if clicked option matches filter
    }

    this.setState({
      filteredOptions: newFiltered,
      selectedOptions: newSelected,
      options: newOptions,
    });
    const selectedValues = this.getSelectedValues(newSelected);
    this.onChange(selectedValues);
  }

  // when user click on option group in options column
  @autobind
  onGroupSelect({ optionGroup, placeOption, removeSelectedOption }) {
    let { filteredOptions, selectedOptions, options } = this.state;

    const { isSearching } = this.state;

    optionGroup.options.forEach((option) => {
      if (!option.locked) {
        selectedOptions = placeOption(
          selectedOptions,
          optionGroup,
          option
        ).filter((group) => group.options.length > 0); // place option in the selected array
        options = removeSelectedOption(options, optionGroup, option).filter(
          (group) => group.options.length > 0
        ); // remove option from selected array
        if (isSearching && filteredOptions) {
          filteredOptions = removeSelectedOption(
            filteredOptions,
            optionGroup,
            option
          ).filter((group) => group.options.length > 0); // if is searching also remove from filtered array
        }
      }
    });

    this.setState({ filteredOptions, selectedOptions, options });
    const selectedValues = this.getSelectedValues(selectedOptions);
    this.onChange(selectedValues);
  }

  // when user click on optionGroup in the selected column
  @autobind
  onGroupDeselect({ optionGroup, placeOption, removeSelectedOption }) {
    let { filteredOptions, selectedOptions, options } = this.state;

    const { searchTerm, isSearching } = this.state;
    const { search } = this.props;

    optionGroup.options.forEach((option) => {
      if (!option.locked) {
        // only update selector if option not locked
        options = placeOption(options, optionGroup, option).filter(
          (group) => group.options.length > 0
        ); // place option in the options array
        selectedOptions = removeSelectedOption(
          selectedOptions,
          optionGroup,
          option
        ).filter((group) => group.options.length > 0); // remove option from selected array

        if (isSearching) {
          filteredOptions = search({ searchTerm, options }); // if searching check if clicked option matches filter
        }
      }
    });

    this.setState({ filteredOptions, selectedOptions });
    const selectedValues = this.getSelectedValues(selectedOptions);
    this.onChange(selectedValues);
  }

  // when user click on select all in options column
  @autobind
  selectAll({ placeOption }) {
    const { lockOptions } = this.props;

    let { filteredOptions, selectedOptions, options } = this.state;

    const { isSearching } = this.state;
    const visibleOptions = isSearching ? filteredOptions : options; // array of visible options

    if (!lockOptions) {
      visibleOptions.forEach((option) => {
        selectedOptions = placeOption(selectedOptions, option); // place option in the selected array
        if (isSearching) {
          // if is searching remove all options that matchs filters
          const index = options.findIndex(
            (opt) => this.groupAccessor()(opt) === this.groupAccessor()(option)
          );
          const mapped = option.options.map((opt) =>
            this.optionAccessor()(opt)
          );
          options[index].options = options[index].options.filter(
            (opt) => !mapped.includes(this.optionAccessor()(opt))
          );
          options = options.filter((opt) => opt.options.length > 0);
        } else {
          options = []; // if not filtering remove all options
        }
      });
    }

    this.setState({ filteredOptions: [], selectedOptions, options });
    const selectedValues = this.getSelectedValues(selectedOptions);
    this.onChange(selectedValues);
  }

  // when user click on select all in selected column
  @autobind
  deselectAll({
    placeOption,
    placeOptionInGroup,
    removeSelectedOptionFromGroup,
  }) {
    let { selectedOptions, options } = this.state;

    const { isSearching, searchTerm } = this.state;
    const { search, lockSelected } = this.props;

    let selectedValues = [];

    if (lockSelected) {
      // if lockSelected keep the locked options
      selectedOptions.forEach((group) => {
        group.options.forEach((option) => {
          if (!option.locked) {
            // move options that aren't locked
            placeOptionInGroup(options, group, option);
            removeSelectedOptionFromGroup(selectedOptions, group, option);
          }
        });
      });

      selectedOptions = this.removeEmptyGroups(selectedOptions);
      this.setState({ selectedOptions });
      selectedValues = arrayFlatten(
        selectedOptions.map((group) => group.options)
      ).map((option) => option.id);
    } else {
      selectedOptions.forEach((option) => {
        // move all selected options to options column
        if (!option.locked) {
          options = placeOption(options, option);
        }
      });

      this.setState({ selectedOptions: [] });
    }

    this.onChange(selectedValues);

    if (isSearching) {
      const filteredOptions = search({ searchTerm, options });
      this.setState({ filteredOptions });
    }
  }

  defaultSearch = ({ options, searchTerm }) => {
    const filteredOptions = [];

    options.forEach((group) => {
      const matches = group.options.filter((opt) =>
        (opt?.attributes?.name || opt?.name)
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      );

      if (matches.length) {
        filteredOptions.push({
          label: group.label,
          options: matches,
        });
      }
    });

    return filteredOptions;
  };

  defaultSearchSelected = ({ selectedOptions, searchTerm }) => {
    const filteredSelectedOptions = [];

    selectedOptions.forEach((group) => {
      const matches = group.options.filter((opt) =>
        (opt?.attributes?.name || opt?.name)
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      );

      if (matches.length) {
        filteredSelectedOptions.push({
          label: group.label,
          options: matches,
        });
      }
    });

    return filteredSelectedOptions;
  };

  searchPlaceholder = () => {
    const { searchPlaceholder } = this.props;

    if (searchPlaceholder) return searchPlaceholder;

    return 'Buscar';
  };

  counterLabel = () => {
    const { counterLabel } = this.props;

    if (counterLabel) return counterLabel;

    return (length) => (length === 1 ? 'turma' : 'turmas');
  };

  // when user types in search field
  onSearch = (searchTerm) => {
    const isSearching = !!searchTerm.length;
    const { options } = this.state;
    const { search } = this.props;

    const searchFunction = search ? search : this.defaultSearch;
    const filteredOptions = searchFunction({ options, searchTerm }); // set filtered options from result of search

    this.setState({ isSearching, searchTerm, filteredOptions });
  };

  onSearchselected = (searchTerm) => {
    const isSelectedSearching = !!searchTerm.length;
    const { selectedOptions } = this.state;

    const filteredSelectedOptions = this.defaultSearchSelected({
      selectedOptions,
      searchTerm,
    }); // set filtered options from result of search

    this.setState({ isSelectedSearching, searchTerm, filteredSelectedOptions });
  };

  defaultOptionComponent = (option) => <span>{option.attributes.name}</span>;

  optionComponent = () => {
    const { optionComponent } = this.props;

    if (optionComponent) return optionComponent;

    return this.defaultOptionComponent;
  };

  defaultGroupComponent = (optionGroup, onOptionGroupSelect) => {
    const { singleSelectPerGroup } = this.props;

    return (
      <span
        className="opt-group"
        onClick={
          singleSelectPerGroup ? null : () => onOptionGroupSelect(optionGroup)
        }
      >
        <strong>{optionGroup.label}</strong>
      </span>
    );
  };

  groupComponent = () => {
    const { groupComponent } = this.props;

    if (groupComponent) return groupComponent;

    return this.defaultGroupComponent;
  };

  defaultOptionAccessor = (option) => option.id;

  optionAccessor = () => {
    const { optionAccessor } = this.props;

    if (optionAccessor) return optionAccessor;

    return this.defaultOptionAccessor;
  };

  defaultGroupAccessor = (option) => option.label;

  groupAccessor = () => {
    const { groupAccessor } = this.props;

    if (groupAccessor) return groupAccessor;

    return this.defaultGroupAccessor;
  };

  defaultEmptyState = () => (
    <div className="empty">
      <AgendaIcon name="user-group" size="large" />
      <div className="empty-message">Selecione uma turma no campo acima</div>
    </div>
  );

  emptyState = () => {
    const { emptyState } = this.props;

    if (emptyState) return emptyState;

    return this.defaultEmptyState();
  };

  render() {
    const {
      selectAllLabel,
      deselectAllLabel,
      lockOptions,
      singleSelectPerGroup,
      showSelectAll,
    } = this.props;

    const {
      isSearching,
      isSelectedSearching,
      filteredOptions,
      filteredSelectedOptions,
      selectedOptions,
      options,
    } = this.state;

    return (
      <div data-testid="group-selector" className="GroupSelector">
        <div className="container-wrap">
          <div className="item-wrap" />
        </div>
        <div className="container-wrap">
          <div className="options-column item-wrap">
            <FormGroupSelectorSelectableColumn
              options={isSearching ? filteredOptions : options}
              optionComponent={this.optionComponent()}
              groupComponent={this.groupComponent()}
              onOptionSelect={this.onOptionSelect}
              onOptionDeselect={this.onOptionDeselect}
              onGroupSelect={this.onGroupSelect}
              groupAccessor={this.groupAccessor()}
              optionAccessor={this.optionAccessor()}
              selectAllLabel={selectAllLabel}
              onSelectBehavior="remove"
              selected={selectedOptions}
              selectAll={this.selectAll}
              counterLabel={this.counterLabel()}
              emptyState={this.emptyState()}
              searchPlaceholder={this.searchPlaceholder()}
              onSearch={(e) => this.onSearch(e)}
              attributeName={'searchTerm'}
              lockSelected
              showSelectAll={!singleSelectPerGroup && showSelectAll}
            />
          </div>
          <div className="icon">
            <AgendaIcon name="swap-horizontal" />
          </div>
          <div className="selected-column item-wrap">
            <FormGroupSelectorSelectableColumn
              options={
                isSelectedSearching ? filteredSelectedOptions : selectedOptions
              }
              optionComponent={this.optionComponent()}
              groupComponent={this.groupComponent()}
              onOptionSelect={this.onOptionDeselect}
              onGroupSelect={this.onGroupDeselect}
              groupAccessor={this.groupAccessor()}
              optionAccessor={this.optionAccessor()}
              selectAllLabel={deselectAllLabel}
              onSelectBehavior="remove"
              selected={[]}
              selectAll={this.deselectAll}
              counterLabel={(length) =>
                length === 1 ? 'selecionado' : 'selecionados'
              }
              lockOptions={lockOptions}
              searchPlaceholder={this.searchPlaceholder()}
              onSearch={(e) => this.onSearchselected(e)}
              attributeName={'searchSelectedTerm'}
              lockSelected
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withFormContext(FormGroupSelector);
