import { CategoriesProps, HandoutActionProps, Template } from './types';

const actions: HandoutActionProps = {
  ERROR: 'ERROR/handouts',
  CREATE_NEW_HANDOUT_REQUEST: 'CREATE_NEW_HANDOUT_REQUEST/handouts',
  CREATE_NEW_HANDOUT_SUCCESS: 'CREATE_NEW_HANDOUT_SUCCESS/handouts',
  CREATE_NEW_HANDOUT_TEMPLATE_REQUEST:
    'CREATE_NEW_HANDOUT_TEMPLATE_REQUEST/handouts',
  CREATE_NEW_HANDOUT_TEMPLATE_SUCCESS:
    'CREATE_NEW_HANDOUT_TEMPLATE_SUCCESS/handouts',
  CREATE_NEW_CATEGORY_REQUEST: 'CREATE_NEW_CATEGORY_REQUEST/handouts',
  CREATE_NEW_CATEGORY_SUCCESS: 'CREATE_NEW_CATEGORY_SUCCESS/handouts',
  DELETE_HANDOUT_TEMPLATE_REQUEST: 'DELETE_HANDOUT_TEMPLATE_REQUEST/handouts',
  DELETE_HANDOUT_TEMPLATE_SUCCESS: 'DELETE_HANDOUT_TEMPLATE_SUCCESS/handouts',
  DUPLICATE_HANDOUT_ATTACHMENTS_REQUEST:
    'DUPLICATE_HANDOUT_ATTACHMENTS_REQUEST/handouts',
  DUPLICATE_HANDOUT_ATTACHMENTS_SUCCESS:
    'DUPLICATE_HANDOUT_ATTACHMENTS_SUCCESS/handouts',
  APPROVE_HANDOUT_REQUEST: 'APPROVE_HANDOUT_REQUEST/handouts',
  APPROVE_HANDOUT_SUCCESS: 'APPROVE_HANDOUT_SUCCESS/handouts',
  APPROVE_SCHEDULE_HANDOUT_REQUEST: 'APPROVE_SCHEDULE_HANDOUT_REQUEST/handouts',
  APPROVE_SCHEDULE_HANDOUT_SUCCESS: 'APPROVE_SCHEDULE_HANDOUT_SUCCESS/handouts',
  CANCEL_SCHEDULE_HANDOUT_REQUEST: 'CANCEL_SCHEDULE_HANDOUT_REQUEST/handouts',
  CANCEL_SCHEDULE_HANDOUT_SUCCESS: 'CANCEL_SCHEDULE_HANDOUT_SUCCESS/handouts',
  SCHEDULE_HANDOUT_REQUEST: 'SCHEDULE_HANDOUT_REQUEST/handouts',
  SCHEDULE_HANDOUT_SUCCESS: 'SCHEDULE_HANDOUT_SUCCESS/handouts',
  DELETE_HANDOUT_REQUEST: 'DELETE_HANDOUT_REQUEST/handouts',
  DELETE_HANDOUT_SUCCESS: 'DELETE_HANDOUT_SUCCESS/handouts',
  RESEND_HANDOUT_REQUEST: 'RESEND_HANDOUT_REQUEST/handouts',
  RESEND_HANDOUT_SUCCESS: 'RESEND_HANDOUT_SUCCESS/handouts',
  FETCH_CATEGORIES_REQUEST: 'FETCH_CATEGORIES_REQUEST/handouts',
  FETCH_CATEGORIES_SUCCESS: 'FETCH_CATEGORIES_SUCCESS/handouts',
  APPLY_HANDOUT_TEMPLATE_REQUEST: 'APPLY_HANDOUT_TEMPLATE_REQUEST/handouts',
  APPLY_HANDOUT_TEMPLATE_SUCCESS: 'APPLY_HANDOUT_TEMPLATE_SUCCESS/handouts',
  FETCH_MORE_CATEGORIES_REQUEST: 'FETCH_MORE_CATEGORIES_REQUEST/handouts',
  FETCH_MORE_CATEGORIES_SUCCESS: 'FETCH_MORE_CATEGORIES_SUCCESS/handouts',
  FETCH_CATEGORY_DETAILS_REQUEST: 'FETCH_CATEGORY_DETAILS_REQUEST/handouts',
  FETCH_CATEGORY_DETAILS_SUCCESS: 'FETCH_CATEGORY_DETAILS_SUCCESS/handouts',
  FETCH_CURRENT_HANDOUT_TEMPLATE_REQUEST:
    'FETCH_CURRENT_HANDOUT_TEMPLATE_REQUEST/handouts',
  FETCH_CURRENT_HANDOUT_TEMPLATE_SUCCESS:
    'FETCH_CURRENT_HANDOUT_TEMPLATE_SUCCESS/handouts',
  FETCH_HANDOUT_ENGAGEMENT_REQUEST: 'FETCH_HANDOUT_ENGAGEMENT_REQUEST/handouts',
  FETCH_HANDOUT_ENGAGEMENT_SUCCESS: 'FETCH_HANDOUT_ENGAGEMENT_SUCCESS/handouts',
  FETCH_HANDOUT_TEMPLATES_REQUEST: 'FETCH_HANDOUT_TEMPLATES_REQUEST/handouts',
  FETCH_HANDOUT_TEMPLATES_SUCCESS: 'FETCH_HANDOUT_TEMPLATES_SUCCESS/handouts',
  FETCH_MORE_HANDOUT_ENGAGEMENT_REQUEST:
    'FETCH_MORE_HANDOUT_ENGAGEMENT_REQUEST/handouts',
  FETCH_MORE_HANDOUT_ENGAGEMENT_SUCCESS:
    'FETCH_MORE_HANDOUT_ENGAGEMENT_SUCCESS/handouts',
  EDIT_CATEGORY_REQUEST: 'EDIT_CATEGORY_REQUEST/handouts',
  EDIT_CATEGORY_SUCCESS: 'EDIT_CATEGORY_SUCCESS/handouts',
  EDIT_HANDOUT_TEMPLATE_REQUEST: 'EDIT_HANDOUT_TEMPLATE_REQUEST/handouts',
  EDIT_HANDOUT_TEMPLATE_SUCCESS: 'EDIT_HANDOUT_TEMPLATE_SUCCESS/handouts',
  FETCH_HANDOUTS_REQUEST: 'FETCH_HANDOUTS_REQUEST/handouts',
  FETCH_HANDOUTS_SUCCESS: 'FETCH_HANDOUTS_SUCCESS/handouts',
  FETCH_MORE_HANDOUTS_REQUEST: 'FETCH_MORE_HANDOUTS_REQUEST/handouts',
  FETCH_MORE_HANDOUTS_SUCCESS: 'FETCH_MORE_HANDOUTS_SUCCESS/handouts',
  TOGGLE_APPROVAL_HANDOUT_MODAL: 'TOGGLE_APPROVAL_HANDOUT_MODAL/handouts',
  TOGGLE_DELETE_HANDOUT_MODAL: 'TOGGLE_DELETE_HANDOUT_MODAL/handouts',
  TOGGLE_EDIT_HANDOUT_TEMPLATE_MODAL:
    'TOGGLE_EDIT_HANDOUT_TEMPLATE_MODAL/handouts',
  TOGGLE_SCHEDULE_HANDOUT_MODAL: 'TOGGLE_SCHEDULE_HANDOUT_MODAL/handouts',
  TOGGLE_SCHEDULE_APPROVAL_HANDOUT_MODAL:
    'TOGGLE_SCHEDULE_APPROVAL_HANDOUT_MODAL/handouts',
  TOGGLE_CANCEL_SCHEDULE_HANDOUT_MODAL:
    'TOGGLE_CANCEL_SCHEDULE_HANDOUT_MODAL/handouts',
  TOGGLE_NEW_CATEGORY_MODAL: 'TOGGLE_NEW_CATEGORY_MODAL/handouts',
  TOGGLE_NEW_HANDOUT_MODAL: 'TOGGLE_NEW_HANDOUT_MODAL/handouts',
  TOGGLE_NOTIFICATION_MODAL: 'TOGGLE_NOTIFICATION_MODAL/handouts',
  TOGGLE_RESEND_HANDOUT_MODAL: 'TOGGLE_RESEND_HANDOUT_MODAL/handouts',
  TOGGLE_HANDOUT_TEMPLATE_MODAL: 'TOGGLE_HANDOUT_TEMPLATE_MODAL',
  TOGGLE_DELETE_HANDOUT_TEMPLATE_MODAL: 'TOGGLE_DELETE_HANDOUT_TEMPLATE_MODAL',
  FETCH_CLASSROOMS_REQUEST: 'FETCH_CLASSROOMS_REQUEST/handouts',
  FETCH_CLASSROOMS_SUCCESS: 'FETCH_CLASSROOMS_SUCCESS/handouts',
  FETCH_HANDOUT_REQUEST: 'FETCH_HANDOUT_REQUEST/handouts',
  FETCH_HANDOUT_SUCCESS: 'FETCH_HANDOUT_SUCCESS/handouts',
  EDIT_HANDOUT_REQUEST: 'EDIT_HANDOUT_REQUEST/handouts',
  EDIT_HANDOUT_SUCCESS: 'EDIT_HANDOUT_SUCCESS/handouts',
  DELETE_CATEGORY_REQUEST: 'DELETE_CATEGORY_REQUEST/handouts',
  DELETE_CATEGORY_SUCCESS: 'DELETE_CATEGORY_SUCCESS/handouts',
  RESEND_MASS_REQUEST: 'RESEND_MASS_REQUEST/handouts',
  RESEND_MASS_SUCCESS: 'RESEND_MASS_SUCCESS/handouts',
  RESET_HANDOUT_FORM: 'RESET_HANDOUT_FORM/handouts',
  RESET_INITIAL_FILTERS: 'RESET_INITIAL_FILTERS/handouts',
  SET_CURRENT_HANDOUT_TEMPLATE: 'SET_CURRENT_HANDOUT_TEMPLATE',

  createNewHandoutRequest: (params) => ({
    type: actions.CREATE_NEW_HANDOUT_REQUEST,
    params,
  }),

  createNewHandoutTemplateRequest: (params) => ({
    type: actions.CREATE_NEW_HANDOUT_TEMPLATE_REQUEST,
    params,
  }),

  approveHandoutRequest: (handout) => ({
    type: actions.APPROVE_HANDOUT_REQUEST,
    handout,
  }),

  approveScheduleHandoutRequest: (handout) => ({
    type: actions.APPROVE_SCHEDULE_HANDOUT_REQUEST,
    handout,
  }),

  cancelScheduleHandoutRequest: (handout) => ({
    type: actions.CANCEL_SCHEDULE_HANDOUT_REQUEST,
    handout,
  }),

  scheduleHandoutRequest: (handout) => ({
    type: actions.SCHEDULE_HANDOUT_REQUEST,
    handout,
  }),

  deleteHandoutRequest: (handout) => ({
    type: actions.DELETE_HANDOUT_REQUEST,
    handout,
  }),

  deleteHandoutTemplateRequest: (handoutTemplateId) => ({
    type: actions.DELETE_HANDOUT_TEMPLATE_REQUEST,
    handoutTemplateId,
  }),

  resendHandoutRequest: (handout) => ({
    type: actions.RESEND_HANDOUT_REQUEST,
    handout,
  }),

  createNewCategoryRequest: (name, isForm = false) => ({
    type: actions.CREATE_NEW_CATEGORY_REQUEST,
    name,
    isForm,
  }),

  fetchCategoriesRequest: () => ({
    type: actions.FETCH_CATEGORIES_REQUEST,
  }),

  fetchCurrentHandoutTemplateRequest: (handoutTemplateId: string) => ({
    type: actions.FETCH_CURRENT_HANDOUT_TEMPLATE_REQUEST,
    handoutTemplateId,
  }),

  applyHandoutTemplateRequest: (handoutTemplateId) => ({
    type: actions.APPLY_HANDOUT_TEMPLATE_REQUEST,
    handoutTemplateId,
  }),

  fetchMoreCategoriesRequest: (page) => ({
    type: actions.FETCH_MORE_CATEGORIES_REQUEST,
    page,
  }),

  fetchHandoutsRequest: (params) => ({
    type: actions.FETCH_HANDOUTS_REQUEST,
    params,
  }),

  fetchMoreHandoutsRequest: (params) => ({
    type: actions.FETCH_MORE_HANDOUTS_REQUEST,
    params,
  }),

  fetchHandoutEngagementRequest: (params) => ({
    type: actions.FETCH_HANDOUT_ENGAGEMENT_REQUEST,
    params,
  }),

  fetchHandoutTemplatesRequest: (params) => ({
    type: actions.FETCH_HANDOUT_TEMPLATES_REQUEST,
    params,
  }),

  fetchMoreHandoutEngagementRequest: (params) => ({
    type: actions.FETCH_MORE_HANDOUT_ENGAGEMENT_REQUEST,
    params,
  }),

  toggleApprovalModal: () => ({ type: actions.TOGGLE_APPROVAL_HANDOUT_MODAL }),

  toggleDeleteModal: () => ({ type: actions.TOGGLE_DELETE_HANDOUT_MODAL }),

  toggleEditHandoutTemplateModal: () => ({
    type: actions.TOGGLE_EDIT_HANDOUT_TEMPLATE_MODAL,
  }),

  toggleScheduleModal: () => ({ type: actions.TOGGLE_SCHEDULE_HANDOUT_MODAL }),

  toggleScheduleApprovalModal: () => ({
    type: actions.TOGGLE_SCHEDULE_APPROVAL_HANDOUT_MODAL,
  }),

  toggleCancelScheduleModal: () => ({
    type: actions.TOGGLE_CANCEL_SCHEDULE_HANDOUT_MODAL,
  }),

  toggleNewCategoryModal: () => ({ type: actions.TOGGLE_NEW_CATEGORY_MODAL }),

  toggleNewHandoutModal: () => ({ type: actions.TOGGLE_NEW_HANDOUT_MODAL }),

  toggleNotificationModal: () => ({ type: actions.TOGGLE_NOTIFICATION_MODAL }),

  toggleResendModal: () => ({ type: actions.TOGGLE_RESEND_HANDOUT_MODAL }),

  toggleHandoutTemplateModal: () => ({
    type: actions.TOGGLE_HANDOUT_TEMPLATE_MODAL,
  }),

  toggleDeleteHandoutTemplateModal: () => ({
    type: actions.TOGGLE_DELETE_HANDOUT_TEMPLATE_MODAL,
  }),

  fetchClassrooms: () => ({ type: actions.FETCH_CLASSROOMS_REQUEST }),

  fetchHandoutRequest: (params) => ({
    type: actions.FETCH_HANDOUT_REQUEST,
    params,
  }),

  editHandoutRequest: (id, params) => ({
    type: actions.EDIT_HANDOUT_REQUEST,
    id,
    params,
  }),

  editHandoutTemplateRequest: (handoutTemplate: Template, id: string) => ({
    type: actions.EDIT_HANDOUT_TEMPLATE_REQUEST,
    handoutTemplate,
    id,
  }),

  fetchCategoryDetails: (id: string) => ({
    type: actions.FETCH_CATEGORY_DETAILS_REQUEST,
    id,
  }),

  editCategory: (category: CategoriesProps) => ({
    type: actions.EDIT_CATEGORY_REQUEST,
    category,
  }),

  deleteCategory: (category: CategoriesProps) => ({
    type: actions.DELETE_CATEGORY_REQUEST,
    category,
  }),

  resendMassHandoutsRequest: () => ({ type: actions.RESEND_MASS_REQUEST }),

  resetForm: () => ({ type: actions.RESET_HANDOUT_FORM }),

  resetInitialFilters: () => ({ type: actions.RESET_INITIAL_FILTERS }),

  setCurrentHandoutTemplate: (handoutTemplate: Template | null) => ({
    type: actions.SET_CURRENT_HANDOUT_TEMPLATE,
    handoutTemplate,
  }),
};

export default actions;
