import React, { Component } from 'react';
import PropTypes from 'prop-types';

import AgendaIcon from 'components/AgendaIcon';

import * as S from './styles';
class TabsBoxTab extends Component {
  render() {
    const { tabComponent, isActive, onTabClick } = this.props;

    return (
      <S.TabsBoxTabButton onClick={onTabClick} active={isActive}>
        <AgendaIcon name={tabComponent.icon} />
        <S.TabsBoxText>{tabComponent.title}</S.TabsBoxText>
      </S.TabsBoxTabButton>
    );
  }
}

TabsBoxTab.propTypes = {
  tabComponent: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
  onTabClick: PropTypes.func.isRequired,
};

export default TabsBoxTab;
