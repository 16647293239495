import React from 'react';

import { EmptyStateProps } from './types';

import * as S from './styles';

const EmptyState = ({
  imgUrl,
  imgWidth,
  message,
  alignment,
  centered,
  spaceBetween,
}: EmptyStateProps): React.ReactElement => (
  <S.Container
    data-testid="empty-state-wrapper"
    className="EmptyState"
    alignment={alignment}
    centered={centered}
    spaceBetween={spaceBetween}
  >
    <S.Image
      data-testid="image"
      src={imgUrl}
      width={imgWidth}
      alt="Empty state"
    />
    {message}
  </S.Container>
);

EmptyState.defaultProps = {
  alignment: 'vertically',
  imgWidth: '250px',
  centered: false,
  spaceBetween: false,
};

export default EmptyState;
