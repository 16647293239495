import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as FilterActions from 'store/sophia/actions';

import SophiaFiltersForm from 'pages/employees/sophia/filters/form';
import PageContainer from 'components/PageContainer';
import normalizeParams from 'pages/employees/sophia/filters/normalizeParams';
import Loader from 'components/Loader/index';
import Toast from 'components/Toast';

import './style.scss';

const SophiaFilters = (props) => {
  const {
    fetchSophiaFiltersRequest,
    updateSophiaFiltersRequest,
    setHeadquarters,
    setCourses,
    setSchoolTerms,
    data,
    canChange,
    initialValues,
    loading,
    sent,
  } = props;

  useEffect(() => {
    fetchSophiaFiltersRequest();
  }, []);

  const onSubmit = useCallback(
    (values) => {
      updateSophiaFiltersRequest(normalizeParams(values.form.data));
    },
    [updateSophiaFiltersRequest]
  );

  const handleHeadquarterSelect = useCallback(
    (key) => {
      setHeadquarters(key.selectedValues);
    },
    [setHeadquarters]
  );

  const handleCourseSelect = useCallback(
    (key) => {
      setCourses(key.selectedValues);
    },
    [setCourses]
  );

  const handleSchoolTermSelect = useCallback(
    (key) => {
      setSchoolTerms(key.selectedValues);
    },
    [setSchoolTerms]
  );

  const handlers = {
    headquarter: handleHeadquarterSelect,
    course: handleCourseSelect,
    schoolTerm: handleSchoolTermSelect,
  };

  return (
    <div className="SophiaFilters">
      <Loader isLoading={loading}>
        <PageContainer title="Configurar integração">
          <SophiaFiltersForm
            filters={data}
            initialValues={initialValues}
            sent={sent}
            canChange={canChange}
            handleSubmit={onSubmit}
            handlers={handlers}
          />
        </PageContainer>
        <Toast />
      </Loader>
    </div>
  );
};

const mapStateToProps = (state) => ({
  data: state.sophiaFilters,
  loading: state.sophiaFilters.loading,
  sent: state.sophiaFilters.sent,
  canChange: state.sophiaFilters.canChange,
  initialValues: state.sophiaFilters.initialValues,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(FilterActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SophiaFilters);
