import createModuleReducer from 'store/storeConfig/createModuleReducer';
import actions from './actions';

export const INITIAL_STATE = {
  error: null,
  isLoading: true,
  errorUpdate: 0,
};

export const sendCodeEmailSuccess = (state) => ({
  ...state,
  isLoadingEdit: false,
});

export const updateToggle = (state) => ({
  ...state,
  errorUpdate: state.errorUpdate + 1,
});

const HANDLERS = {
  [actions.SEND_CODE_EMAIL_SUCCESS]: sendCodeEmailSuccess,
  [actions.UPDATE_TOGGLE]: updateToggle,
};

const twoAuthFactor = createModuleReducer(INITIAL_STATE, HANDLERS);

export default twoAuthFactor;
