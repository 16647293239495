import React from 'react';
import PropTypes from 'prop-types';

import Avatar from 'components/Avatar';
import AgendaIcon from 'components/AgendaIcon';
import Loader from 'components/Loader';
import TextFieldSearchStudents from 'components/Form/StudentsSelector/TextFieldSearchStudents';

import './style.scss';

function FormStudentsSelectorList(props) {
  const {
    emptyIconName,
    emptyMessage,
    ignoreSelectedVisualAid,
    isStudentSelected,
    isLoading,
    onStudentClick,
    showEmptyIcon,
    students,
    filterStudents,
    onStudentNameChange,
    attributeName,
    disabled,
  } = props;

  const studentsWithSelected = students.map(student => {
    return {
      student,
      isStudentSelected: !ignoreSelectedVisualAid && isStudentSelected(student)
    };
  });

  if (isLoading) {
    return (
      <ul className="FormStudentsSelectorList">
        <Loader variation="centered" />
      </ul>
    );
  }

  return (
    <>
      <TextFieldSearchStudents
        onIconClick={filterStudents}
        onChange={onStudentNameChange}
        attributeName={attributeName}
        placeholder="Buscar"
        disabled={disabled}
      />
      <ul className="FormStudentsSelectorList">
        {showEmptyIcon && studentsWithSelected.length < 1
          ? (
            <li className="empty">
              <AgendaIcon name={emptyIconName} size="large" />
              <div className="empty-message">
                {emptyMessage}
              </div>
            </li>
          )
          : (
            <div className="non-empty">
              {studentsWithSelected.map(({ student, isStudentSelected }) =>
                <li
                  key={`FormStudentsSelectorList-${student.id}`}
                  onClick={onStudentClick(student)}
                  className={`${isStudentSelected ? 'selected' : ''} ${disabled ? 'disabled' : ''}`}
                >
                  <Avatar user={student} />
                  {student.attributes.name}
                </li>
              )}
            </div>
          )
        }
      </ul>
    </>
  );
}

FormStudentsSelectorList.propTypes = {
  students: PropTypes.array.isRequired,
  isStudentSelected: PropTypes.func.isRequired,
  onStudentNameChange: PropTypes.func.isRequired,
  filterStudents: PropTypes.func,
  attributeName: PropTypes.string.isRequired,
  onStudentClick: PropTypes.func.isRequired,
  ignoreSelectedVisualAid: PropTypes.bool,
  showEmptyIcon: PropTypes.bool,
  emptyIconName: PropTypes.string,
  emptyMessage: PropTypes.string,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,

};

FormStudentsSelectorList.defaultProps = {
  ignoreSelectedVisualAid: false,
  showEmptyIcon: true,
  emptyIconName: 'dots-horizontal',
  emptyMessage: 'Nada para selecionar',
  disabled: false,
  isLoading: false,
};

export default FormStudentsSelectorList;
