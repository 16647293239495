import {
  validateGreaterThanZero,
  validateSameDay,
  validatePresenceDay,
  validateMaxDay,
} from 'core/utils/edupay/validations/discounts';

const validatesDiscount = (key) => [
  {
    attributeName: key,
    validator: validateGreaterThanZero,
    message: 'O valor tem que ser maior que zero',
  },
  {
    attributeName: key,
    validator: validateSameDay,
    message: 'Já existe um desconto para esta data.',
  },
  {
    attributeName: key,
    validator: validatePresenceDay,
    message: 'Campo dias para vencimento é obrigatório',
  },
  {
    attributeName: key,
    validator: validateMaxDay,
    message: 'Valor máximo para o campo dias para vencimento é 365',
  },
];

export default validatesDiscount;
