import { ThemeProps } from 'config/themes/types';
import styled, { css } from 'styled-components';

const spanTooltipStyles = `
${({ theme: { colors, font } }: ThemeProps) => css`
  display: flex;
  align-items: baseline;
  color: ${colors.neutral.gray1}
  margin-bottom: 8px;
  font-size: ${font.size.md}

  .Tooltip {
    font-size: ${font.size.lg}
    margin-left: 8px;
  }
`}
`;

export const BillSummary = styled.div`
  ${({ theme: { colors, border, font } }: ThemeProps) => css`
    padding: 0;
    width: 100%;
    border: ${border.style.solid} ${border.width.sm} ${colors.neutral.gray4};
    border-radius: ${border.radius.md};


  .Tooltip {
    margin-left: 5px;
    display: inline-block;
  }
  .summary-content {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    padding: 24px 24px 0;

    > div {
      display: flex;
      flex-direction: column;
      flex-basis: 50%;
      font-size: ${font.size.md};
      color: ${colors.neutral.gray1};

      &:first-child {
        margin-bottom: 16px;
      }

      > span:first-child {
        ${spanTooltipStyles}
      }
    }

    @media screen and (min-width: 768px) {
      flex-direction: row;

      > div:first-child {
        margin-bottom: 0;
      }
    }
  }

  .summary-list {
    display: flex;
    flex-direction: column;
    align-items: baseline;

    .summary-item-wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 0 24px 20px;

      .wrapper-item-value {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        width: 100%;
      }

      .summary-item {
        display: flex;
        flex-direction: column;
        flex-basis: 50%;
        font-size: ${font.size.md};
        color: ${colors.neutral.black};

        .discount-item {
          font-size: ${font.size.xs};
          color: ${colors.neutral.gray1};
        }
        &:last-child {
          .Currency {
            font-size: ${font.size.md}
          }
        }
      }
    }
  }

  .fines-interest-and-discount {
    border-top: ${border.style.solid} ${border.width.sm} ${colors.neutral.gray4};
    padding: 16px 20px;

    .fines,
    .interest,
    .discount {
      display: flex;

      p {
        margin: 0;
      }

      p,
      .Currency {
        display: flex;
        align-items: baseline;
        flex-basis: 50%;
        font-size: ${font.size.md}
        font-weight: ${font.weight.regular}
      }
    }

    .interest {
      margin-top: 10px;
    }

    .discount .discount-currency {
      display: flex;
      flex-direction: row;

      span {
        margin-right: 4px;
      }
    }
  }

  .summary-total {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    width: 100%;
    padding-bottom: 0px;
    font-size: ${font.size.md};
    color: ${colors.neutral.gray1};
    border-top: ${border.style.solid} ${border.width.sm} ${colors.neutral.gray4};

    > div {
      display: flex;
      flex-direction: column;
      flex-basis: 50%;
      > span:first-child {
        ${spanTooltipStyles}
      }
    }
  }
  `}
`;
