import styled, { css } from 'styled-components';
import ModalContainer from 'components/Modal/ModalContainer';

export const AllStudentsRemovalModalWrapper = styled(ModalContainer)`
  ${({ theme }) => css`
    .ReactModal__Overlay {
      z-index: 9999 !important;
    }

    .ReactModal__Content .icon-ae-close {
      margin-top: 0;
      font-size: 16px;
    }

    .modal-title {
      font-family: 'Quicksand';
      font-size: 24px;
      font-style: normal;
      font-weight: bold;
      font-stretch: normal;
      line-height: 1;
      color: ${theme.black};
      margin-bottom: 24px;
    }

    .subTitle {
      font-family: 'Roboto';
      font-size: 16px;
      margin-bottom: 24px;
    }

    .modal-buttons {
      display: flex;
      justify-content: flex-end;

      .Button {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;

        padding: 8px 16px;
        margin-top: 0;
        font-family: Quicksand;
        font-size: 16px;
        font-weight: 700;
        border-radius: 6px;
      }
    }
  `}
`;
