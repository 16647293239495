import { RouteComponentProps } from 'react-router-dom';

type HistoryProps = RouteComponentProps['history'];

const launchDarkly = {
  checkFlagActive: (activeFlags: string[], flag: string) => {
    if (activeFlags?.length >= 1) {
      return activeFlags.includes(flag);
    }

    return null;
  },

  applyRouter: (activeFlag: string, history: HistoryProps, router: string) => {
    if (activeFlag) {
      history.push(router);
    } else {
      window.location.href = router;
    }
  },
};

export default launchDarkly;
