import styled from 'styled-components';

export const ModalConten = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: space-between;
  overflow: hidden;
  flex-direction: row;
  padding: 15px;
  gap: 50px;
`;
