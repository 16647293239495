import styled, { css } from 'styled-components';

import FormFieldset from 'components/Form/Fieldset';

import { ThemeProps } from 'config/themes/types';

export const HandoutInformationStepWrapper = styled(FormFieldset)``;

export const Title = styled.h1`
  ${({ theme: { typography, colors } }) => css`
    ${typography.title.Bold20}
    color: ${colors.neutral.black};
  `}
`;

export const RowWrapper = styled.div`
  ${({ theme: { border, colors, space, typography } }: ThemeProps) => css`
    padding: ${space.xs4};
    display: flex;
    flex-direction: column;
    gap: ${space.lg};

    .Title {
      padding: ${space.xs4};
      margin-bottom: ${space.sm} !important;
      ${typography.title.Bold20};
    }

    .Field {
      padding: ${space.xs4};
      margin: ${space.xs4} !important;

      .Label {
        ${typography.label.Regular14};
      }

      .TextArea {
        .FormValidationErrors {
          margin-top: ${space.xs};
          color: ${colors.context.alert.default};
          ${typography.body.Regular14}
        }

        .quill {
          border: ${border.width.sm} ${border.style.solid}
            ${colors.neutral.gray4};
          border-radius: ${border.radius.md};

          &:hover {
            border-color: ${colors.brand.primary.default};
          }

          &:focus-within {
            border-color: ${colors.brand.primary.default};
          }
        }

        .ql-toolbar {
          ${typography.label.Regular14};
          border-top-left-radius: ${border.radius.md};
          border-top-right-radius: ${border.radius.md};

          span {
            color: ${colors.neutral.gray1};
          }
        }

        .ql-toolbar,
        .ql-container.ql-snow {
          border: ${border.width.none};
        }

        .ql-toolbar {
          padding: ${space.xs2} ${space.xs};
          ${typography.label.Regular14};
          color: ${colors.neutral.gray1};

          display: flex;
          gap: ${space.xs3};
          flex-wrap: wrap;

          .ql-formats {
            margin-right: ${space.xs4};
            display: flex;
            gap: ${space.xs3};
          }

          button {
            margin: ${space.xs4};
          }
        }

        .ql-editor {
          padding: ${space.xs2} ${space.sm} ${space.sm};
          ${typography.body.Regular14};
          color: ${colors.neutral.gray1};
        }
      }

      .FormValidationErrors {
        margin-top: ${space.xs};
        ${typography.label.Regular14};
      }
    }

    .FileField {
      span:first-child {
        ${typography.label.Medium14};
        color: ${colors.neutral.gray1};
      }

      .ButtonTrigger {
        height: 40px;
        padding: ${space.xs} ${space.md};
        ${typography.button.Bold16};
        border-radius: ${border.radius.md};
        display: flex;
        align-items: center;
        gap: ${space.xs2};

        transition: background-color 0.25s ease 0s;

        .TextContainer {
          margin: ${space.xs4};
        }

        span {
          .AgendaIcon.icon-ae-attachment {
            color: ${colors.brand.primary.default};
          }
        }

        &:hover {
          background-color: ${colors.brand.primary.op10};
          border-color: ${colors.brand.primary.op20} !important;
        }
      }

      .Tooltip {
        .tooltip-div {
          height: 16px;

          .AgendaIcon.icon-ae-help {
            color: ${colors.neutral.gray1};
          }
        }
      }
    }

    [data-testid='select-wrapper'] {
      label {
        margin-bottom: ${space.xs};
      }

      input {
        &::placeholder {
          color: ${colors.neutral.gray2};
        }
      }

      p {
        margin-top: ${space.xs};
      }
    }
  `}
`;

export const RequiredFields = styled.span`
  ${({ theme: { typography } }: ThemeProps) => css`
    ${typography.body.Regular12};
  `}
`;

export const FormSkeletonWrapper = styled.div`
  ${({ theme: { breakpoints, colors, space } }: ThemeProps) => css`
    height: 100%;
    max-width: 100%;
    padding: ${space.xs4} 0;
    margin: ${space.xl3} 0;
    background-color: ${colors.neutral.white};

    @media only screen and (min-width: ${breakpoints.desktopLG}) {
      margin: ${space.xl3} 0;
    }

    @media only screen and (min-width: ${breakpoints.desktopXL}) {
      padding: ${space.xs4} 0;
    }
  `}
`;

export const FormSkeletonItems = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 14px;
`;

export const AttachmentLabel = styled.p`
  ${({ theme: { space, colors } }: ThemeProps) => css`
    color: ${colors.neutral.blackOp40};
    margin-bottom: ${space.sm};
  `}
`;
