import React, { useEffect, useCallback, useMemo, lazy } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import Loader from 'components/Loader';
import EnrollmentPlanForm from 'components/Payments/Enrollment/EnrollmentPlanForm';
import Toast from 'components/Toast';

import actionEnrollmentPlans from 'store/edupay/enrollmentPlans/actions';

import { buildDiscount, formattedDiscounts } from 'core/utils/edupay/functions';

import {
  DISCOUNT_KINDS,
  INSTALLMENTS_SCHOOLPRODUCT,
  ENROLLMENT_FORM_TYPES,
} from 'core/constants/index';

const EditEnrollmentPlan = (props) => {
  const dispatch = useDispatch();

  const {
    match: {
      params: { id },
    },
  } = props;

  const { fetchEditEnrollmentRequest, updateEnrollmentPlanRequest } =
    actionEnrollmentPlans;

  const { creditCardTax, billetTax, pixTax } = useSelector(
    (state) => state.root
  );

  const {
    enrollmentPlanEdit,
    sending,
    recipients,
    classrooms,
    contractModels,
  } = useSelector((state) => state.enrollmentPlans);

  const isMassPlan = enrollmentPlanEdit?.attributes?.contract_template;

  const fetchEditEnrollmentPlan = useCallback(
    (enrollmentId) => {
      dispatch(fetchEditEnrollmentRequest(enrollmentId));
    },
    [dispatch, fetchEditEnrollmentRequest]
  );

  const extractDiscount = (enrollmentPlan) => {
    const discounts = [];
    const {
      attributes: { edupay_discounts: edupayDiscounts },
    } = enrollmentPlan;

    if (edupayDiscounts) {
      edupayDiscounts.data.map(({ attributes }) => {
        discounts.push({
          kind: attributes.kind,
          discount_value:
            attributes.kind === 'percent'
              ? attributes.discount_percent
              : attributes.discount_price_cents,
          days_before_expire: attributes.days_before_expire,
          key: Math.random().toString(36).substring(7),
        });
      });
    }

    if (!discounts.length) {
      discounts.push(buildDiscount());
    }

    return discounts;
  };

  const updateEnrollmentPlan = useCallback(
    (params) => {
      dispatch(updateEnrollmentPlanRequest(params));
    },
    [dispatch, updateEnrollmentPlanRequest]
  );

  const handleUpdateEnrollment = (formContext) => {
    const { form } = formContext;

    const responsibleProfiles = isMassPlan
      ? form.responsible_ids
      : form.selectedRecipients.map((recipient) => recipient.id);

    updateEnrollmentPlan({
      id,
      title: form.title,
      description: form.description,
      price: form.price,
      expires_at: form.expires_at,
      max_installments: form.max_installments,
      allowed_payment_method: form.allowed_payment_method,
      edupay_discounts: formattedDiscounts(form),
      attachments: [form.signedId],
      classroom_ids: [form.selectedClassroom],
      classroom_with_student_profile_ids: {
        [form.selectedClassroom]: [form.selectedStudent],
      },
      school_products_responsible_profile_ids: responsibleProfiles,
      recipient_wallet_id: form.recipient_wallet_id,
      allow_credit_card_discount: form.allow_credit_card_discount,
      settings: { absorb_tax: form?.absorb_tax },
    });
  };

  useEffect(() => {
    fetchEditEnrollmentPlan(id);
  }, [id, fetchEditEnrollmentPlan]);

  if (!enrollmentPlanEdit) {
    return <Loader />;
  }

  return (
    <>
      <EnrollmentPlanForm
        form={{
          recipients,
          selectedClassroom: enrollmentPlanEdit.attributes.classroom_id,
          selectedStudent: enrollmentPlanEdit.attributes.student_profile_id,
          selectedRecipients: enrollmentPlanEdit.attributes.current_signer_ids,
          description: enrollmentPlanEdit.attributes.description,
          allowed_payment_method:
            enrollmentPlanEdit.attributes.allowed_payment_method,
          expires_at: enrollmentPlanEdit.attributes.expires_at,
          price: enrollmentPlanEdit.attributes.price,
          absorb_credit_card_tax: true,
          absorb_installment_tax: true,
          absorb_billet_tax: true,
          absorb_pix_tax: true,
          max_installments: enrollmentPlanEdit.attributes.max_installments,
          toggle_discount: enrollmentPlanEdit.attributes.toggle_discount,
          edupay_discounts: extractDiscount(enrollmentPlanEdit),
          fileUrl: enrollmentPlanEdit.attributes.attachment?.url,
          nameFile: enrollmentPlanEdit.attributes.attachment?.name,
          sizeFile: enrollmentPlanEdit.attributes.attachment?.size,
          signedId: enrollmentPlanEdit.attributes.attachment?.signed_id,
          title: enrollmentPlanEdit.attributes.title,
          recipient_wallet_id:
            enrollmentPlanEdit.attributes.recipient_wallet_id,
          formType: isMassPlan ? 'mass' : 'single',
          contractModelId:
            enrollmentPlanEdit.attributes.contract_template?.data?.id,
          classroom_id: enrollmentPlanEdit.attributes.classroom_id,
          responsible_ids: enrollmentPlanEdit.attributes.current_signer_ids,
          allow_credit_card_discount:
            enrollmentPlanEdit?.attributes?.edupay_discounts?.data[0]?.attributes?.allowed_payment_methods?.includes(
              'credit_card'
            ),
          absorb_tax: enrollmentPlanEdit.attributes.settings?.absorb_tax,
        }}
        formMeta={{
          pix_tax: pixTax,
          tax: creditCardTax,
          billet_tax: billetTax,
          status: enrollmentPlanEdit.attributes.status,
          classrooms,
          contractModels,
          select_options: {
            max_installments: INSTALLMENTS_SCHOOLPRODUCT,
            kind: DISCOUNT_KINDS,
            formTypes: ENROLLMENT_FORM_TYPES.filter((option) =>
              isMassPlan ? option.value === 'mass' : option.value === 'single'
            ),
          },
        }}
        onSubmit={handleUpdateEnrollment}
        isSubmitting={sending}
        action="edit"
        backTo="/schools/school_products/tab/enrollment"
        titlePage="Editar Matrícula"
        titleModal="Descartar alterações"
        contentModal="As alterações da matrícula serão canceladas. Tem certeza que deseja descartar?"
      />
      <Toast />
    </>
  );
};

EditEnrollmentPlan.propTypes = {
  match: PropTypes.object.isRequired,
};

export default EditEnrollmentPlan;
