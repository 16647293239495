/* eslint-disable no-debugger, react/prop-types */
import React from 'react';
import { FIELD_NAMES } from '../constants';

const RowNumber = ({ attributes }) => <span>{attributes.line}</span>;

const Field = ({ attributes }) => (
  <span>{FIELD_NAMES[attributes.field_name]}</span>
);

const Error = ({ attributes }) => <span>{attributes.error}</span>;

const FieldValue = ({ attributes }) => <span>{attributes.field_value}</span>;

export default [
  {
    Header: 'Linha',
    accessor: RowNumber,
  },
  {
    Header: 'Campo',
    accessor: Field,
  },
  {
    Header: 'Inconsistência',
    accessor: Error,
  },
  {
    Header: 'Valor atual',
    accessor: FieldValue,
  },
];
