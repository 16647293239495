import React, { useMemo } from 'react';

import PageTitle from 'components/PageTitle';
import CommonLabel from 'components/CommonLabel';

import './style.scss';
import { useSelector } from 'react-redux';
import { RecipientTag, Tag } from '@agendaedu/ae-web-components';

const SurveyHeader = ({ survey, areInvalidDates }) => {
  const { selectedSurveyIncluded } = useSelector((state) => state.surveys);

  const SURVEY_KIND = {
    poll: 'Enquete',
    authorization: 'Autorização',
  };

  const recipientTag = useMemo(() => {
    const recipientType =
      survey.attributes.sentKind === 'students' ? 'alunos (as)' : 'turmas';

    const recipients = survey?.relationships?.recipients?.data.reduce(
      (prev, current) => {
        const relatedItem = selectedSurveyIncluded.find(
          (include) =>
            include.id === current.id &&
            include.type ===
              (survey?.attributes.sentKind === 'students'
                ? 'studentProfile'
                : 'classroom')
        );

        return relatedItem ? [...prev, relatedItem.attributes] : prev;
      },
      []
    );

    if (!recipients?.length)
      return {
        type: 'Sem destinatários',
        recipients: [{ name: 'Sem destinatários' }],
      };

    return {
      type: recipientType,
      recipients,
    };
  }, [
    survey.attributes.sentKind,
    survey?.relationships?.recipients?.data,
    selectedSurveyIncluded,
  ]);

  const renderStatus = () => {
    const STATUS = {
      pending: 'Pendente',
      opened: 'Em aberto',
      closed: 'Encerrada',
      rejected: 'Reprovada',
      deleted: 'Excluída',
      processing: 'Processando',
      approved: 'Aprovado',
    };

    const VARIANT = {
      pending: 'warning',
      opened: 'positive',
      closed: 'informative',
      rejected: 'negative',
      deleted: 'neutral',
      processing: 'warning',
      approved: 'positive',
    };

    return (
      <Tag
        variant={VARIANT[survey.attributes.status]}
        name={STATUS[survey.attributes.status]}
        size="small"
        mr="sm"
      />
    );
  };

  const renderWarning = () => {
    if (survey.attributes.status === 'rejected') {
      return (
        <CommonLabel variation="danger">
          <strong>Motivo: </strong>
          {survey.attributes.cancel_reason}
        </CommonLabel>
      );
    } else if (survey.attributes.status === 'pending' && areInvalidDates) {
      return (
        <CommonLabel variation="warning">
          <strong>
            A enquete está com datas inválidas. Edite as datas antes de aprovar.
          </strong>
        </CommonLabel>
      );
    }
  };

  return (
    <>
      <section className="survey-label-area">
        <div className="status">{renderStatus()}</div>
        <Tag name={SURVEY_KIND[survey.attributes.kind]} size="small" mr="sm" />
        <RecipientTag
          variant="neutral"
          recipientType={recipientTag.type}
          recipients={recipientTag.recipients}
          modalTitle={recipientTag.type}
          size="small"
          enableEllipsis
        />
      </section>
      {renderWarning()}
      <PageTitle>{survey.attributes.title}</PageTitle>
    </>
  );
};

export default SurveyHeader;
