/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import AgendaIcon from 'components/AgendaIcon';
import Label from 'components/Form/Label';
import { convertToBase64, convertUrlToBase64 } from 'core/utils/base64';
import { s3UrlToFile } from 'core/utils/s3';
import withFormContext from 'core/hoc/withFormContext';
import { userIconStyle, cameraIconStyle, trashIconStyle } from './styles';
import * as S from './styles';

type FormContext = {
  updateAttribute: (attribute: string, value: File | null) => void;
};

interface AvatarUploaderProps {
  label?: string;
  attributeName: string | undefined;
  formContext: FormContext;
  avatar: string | null;
}

const AvatarUploader = ({
  label,
  attributeName,
  avatar,
  formContext: { updateAttribute },
}: AvatarUploaderProps) => {
  const [currentImage, setCurrentImage] = useState<string | null>(null);

  const onChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];

    if (!file) return;

    updateAttribute(attributeName, file);
    const { base64 } = await convertToBase64(file);
    setCurrentImage(base64);
  };

  const onClear = () => {
    setCurrentImage(null);
    updateAttribute(attributeName, null);
  };

  useEffect(() => {
    const updateImageFromAvatar = async () => {
      if (!avatar) return;
    
      const file = await s3UrlToFile(avatar);

      if (!file) return;

      updateAttribute(attributeName, file);
      const { base64 } = await convertUrlToBase64(avatar);
      setCurrentImage(base64);
    };
  
    updateImageFromAvatar();
  }, [avatar]);

  const renderThumbnail = () => (
    <S.AvatarThumbnail>
      <AgendaIcon name="user" size="x-large" style={userIconStyle} />
    </S.AvatarThumbnail>
  );

  const renderPreview = () => (
    <S.AvatarPreview>
      <img src={currentImage} />
    </S.AvatarPreview>
  );

  return (
    <div className="AvatarUploader">
      <Label>{label}</Label>

      <S.AvatarWrapper>
        {!currentImage ? renderThumbnail() : renderPreview()}

        <S.ButtonsWrapper>
          {currentImage && 
            <S.TrashButton type="button" onClick={onClear}>
              <AgendaIcon name="trash" size="small" style={trashIconStyle} />
            </S.TrashButton>
          }

          <S.CameraButton type="button">
            <AgendaIcon name="camera" size="small" style={cameraIconStyle} />
            <span className="file-input-btn">
              <input type="file" accept="image/*" onChange={onChange} />
            </span>
          </S.CameraButton>
        </S.ButtonsWrapper>
      </S.AvatarWrapper>
    </div>
  );
};

AvatarUploader.defaultProps = {
  label: '',
  avatar: null,
};

export default withFormContext(AvatarUploader);
