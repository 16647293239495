import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import moment, { Moment as MomentType } from 'moment';
import {
  SingleDate,
  FlexGrid,
  FlexGridItem,
  Box,
  Select,
} from '@agendaedu/ae-web-components';

import FormContainer from 'components/Form/FormContainer';

import MoodTrackersActions from 'store/moodTrackers/actions';
import {
  MoodTrackersState,
  FetchAnswersActionProps,
} from 'store/moodTrackers/types';

import ButtonAndSideSheet from './ButtonAndSideSheet';

import * as S from './styles';
import { MOOD_TRACKERS_ANSWER_TYPE } from 'core/constants';

const MoodTrackersFilters = () => {
  const {
    date,
    answerType,
    formMeta: { filterAmount },
  } = useSelector((state: MoodTrackersState) => state.moodTrackers);

  const dispatch = useDispatch();
  const { t } = useTranslation(['mood_trackers']);

  const { setDate, setFilters, setAnswerType } = MoodTrackersActions;

  const handleDateChange = (date: MomentType) => {
    const newDate = date
      ? date.format('YYYY-MM-DD')
      : new Date().toISOString().split('T')[0];

    dispatch(setDate({ date: newDate } as FetchAnswersActionProps));
  };

  const getOnlyPastDates = (day: MomentType): boolean => {
    return day.isAfter(moment().endOf('day'));
  };

  const handleAnswerType = (e) =>
    dispatch(setAnswerType({ answerType: e.value } as FetchAnswersActionProps));

  const onHandleFilter = (params: FetchAnswersActionProps) => {
    dispatch(setFilters(params));
  };
  return (
    <FormContainer variation="horizontal">
      <S.FiltersContent>
        <FlexGrid>
          <FlexGridItem cols={{ mobileXS: 12, tabletMD: 3 }}>
            <Box display={{ mobileSM: 'flex' }}>
              <Select
                options={MOOD_TRACKERS_ANSWER_TYPE.map((option) => ({
                  ...option,
                  label: t(option.label),
                }))}
                value={answerType}
                onChange={(e) => handleAnswerType(e)}
                fullWidth
              />
            </Box>
          </FlexGridItem>
          <FlexGridItem cols={{ mobileXS: 8, tabletMD: 5 }}>
            <SingleDate
              id="mood-date"
              value={moment(date, 'YYYY-MM-DD')}
              handleChange={handleDateChange}
              handleOutsideRange={getOnlyPastDates}
            />
          </FlexGridItem>
          <FlexGridItem cols={4}>
            <ButtonAndSideSheet
              amount={filterAmount}
              handleFilter={onHandleFilter}
            />
          </FlexGridItem>
        </FlexGrid>
      </S.FiltersContent>
    </FormContainer>
  );
};

export default MoodTrackersFilters;
