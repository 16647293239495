import React from 'react';
import parse from 'html-react-parser';

import { Text, Icon } from '@agendaedu/ae-web-components';
import Tooltip from 'components/Tooltip';
import Currency from 'components/Currency';

import { CardBalanceProps } from './types';

import * as S from './styles';

const CardBalance = ({
  title,
  value,
  tooltipMessage,
  variation,
}: CardBalanceProps) => {
  return (
    <S.CardBalanceWrapper variation={variation}>
      <S.TitleWrapper>
        <Text
          variant="subtitle-medium-14"
          lineHeight="md"
          color={'neutral.gray1'}
          mb={0}
          whiteSpace="nowrap"
        >
          {title}
        </Text>

        <Tooltip content={parse(tooltipMessage)}>
          <Icon name="help-circle" size="sm" color={'neutral.gray1'} />
        </Tooltip>
      </S.TitleWrapper>
      <Currency value={value} />
    </S.CardBalanceWrapper>
  );
};

export default CardBalance;
