import React, { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  IMPORT_PROFILE_TYPES,
  IMPORT_THEME_COLOR,
  IMPORT_STATUS,
} from '../constants';
import PageContainer from 'components/PageContainer';
import Breadcrumb from 'components/Breadcrumb';
import Loader from 'components/Loader';
import importWebActions from 'store/importWeb/actions';
import formatDatetime from 'core/utils/formatDatetime';
import {
  Box,
  Text,
  FlexGrid,
  FlexGridItem,
} from '@agendaedu/ae-web-components';
import ProgressBar from '@ramonak/react-progress-bar';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import * as S from './styles';
import { Props } from './types';
const ImportWebSummary = ({ match, history }: Props) => {
  const { t } = useTranslation(['import_web']);

  const {
    colors: {
      brand: {
        primary: { default: primaryColor },
      },
    },
  } = useTheme();

  const { importedInto, id } = match.params;
  const { fetchImportSummary, getImportProgress } = importWebActions;
  const { isLoading, importSummary, currentImportProgress } = useSelector(
    (state) => state.importWeb
  );

  const dispatch = useDispatch();

  const handleFetchImportSummary = useCallback(() => {
    dispatch(fetchImportSummary(importedInto, id));
  }, [dispatch, fetchImportSummary, importedInto, id]);

  const goToInconsistencies = () => {
    history.push(`/schools/import_webs/${importedInto}/${id}/inconsistencies`);
  };

  useEffect(() => {
    handleFetchImportSummary();
  }, [handleFetchImportSummary]);

  const statusShowProgressBar = ['in_progress', 'enqueued'];

  useEffect(() => {
    if (!currentImportProgress.progress_status) {
      dispatch(getImportProgress(importedInto, id));
    }

    if (statusShowProgressBar.includes(currentImportProgress.progress_status)) {
      const interval = setInterval((cb) => {
        if (
          statusShowProgressBar.includes(currentImportProgress.progress_status)
        ) {
          dispatch(getImportProgress(importedInto, id));
        } else {
          clearInterval(cb);
        }
      }, 5000);

      return () => clearInterval(interval);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, currentImportProgress, dispatch]);

  return (
    <S.Wrapper className="ImportWebSummary">
      <PageContainer
        title={`Resumo da importação`}
        className="ImportWeb"
        breadcrumb={
          <Breadcrumb
            title={`Central de importação de ${IMPORT_PROFILE_TYPES[importedInto]}`}
            path={`/schools/import_webs/${importedInto}`}
            external
          />
        }
      >
        <Loader isLoading={isLoading}>
          <Box
            display="flex"
            justifyContent="space-between"
            flexDirection="row"
          >
            <FlexGrid gutter="lg">
              <FlexGridItem
                cols={{
                  mobileXS: 12,
                  mobileSM: 12,
                  tabletMD: 6,
                  desktopLG: 6,
                  desktopXL: 6,
                }}
                filled={false}
              >
                <Box display="flex" flexDirection="column">
                  <S.SummaryList>
                    <li>
                      Status da importação:{' '}
                      <S.StatusTag
                        color={
                          importSummary &&
                          IMPORT_THEME_COLOR[importSummary.attributes.status]
                        }
                      >
                        {importSummary &&
                          IMPORT_STATUS[importSummary.attributes.status]}
                      </S.StatusTag>
                    </li>
                    <li>
                      Número de linhas do arquivo:{' '}
                      <b>
                        {importSummary && importSummary.attributes.total_rows}
                      </b>
                    </li>
                    <li>
                      Número de registros distintos:{' '}
                      <b>
                        {importSummary &&
                          importSummary.attributes.total_distinct_entities}
                      </b>
                    </li>
                    <li>
                      Número de inconsistências encontradas:{' '}
                      <b>
                        {importSummary &&
                          importSummary.attributes.total_inconsistencies}
                      </b>
                    </li>
                    <li>
                      Nome do arquivo:{' '}
                      <b>
                        {importSummary &&
                          importSummary.attributes.original_file_name}
                      </b>
                    </li>
                    <li>
                      Data de importação:{' '}
                      <b>
                        {importSummary &&
                          formatDatetime(
                            importSummary.attributes.created_at,
                            'DD/MM/YYYY HH:mm:ss'
                          )}
                      </b>
                    </li>
                    <li>
                      Usuário:{' '}
                      <b>
                        {importSummary && importSummary.attributes.user_name}
                      </b>
                    </li>
                  </S.SummaryList>

                  <S.InconsistenciesTag onClick={goToInconsistencies}>
                    Ver inconsistências
                  </S.InconsistenciesTag>
                </Box>
              </FlexGridItem>
              <FlexGridItem
                cols={{
                  mobileXS: 12,
                  mobileSM: 12,
                  tabletMD: 6,
                  desktopLG: 6,
                  desktopXL: 6,
                }}
                filled={false}
              >
                {currentImportProgress?.progress_status === 'in_progress' && (
                  <Box display="flex" flexDirection="column" width="100%">
                    <Text as="h3" variant="title-bold-16">
                      {t('progress_import')}
                    </Text>

                    <S.Container>
                      <Text as="h2" variant="title-bold-20" mb="sm">
                        {`${currentImportProgress.processed} de ${currentImportProgress.total}`}
                      </Text>
                      <ProgressBar
                        animateOnRender
                        completed={Number(
                          currentImportProgress.percentile?.replace(/\D/g, '')
                        )}
                        maxCompleted={100}
                        bgColor={primaryColor}
                      />
                    </S.Container>
                  </Box>
                )}
                {currentImportProgress?.progress_status === 'enqueued' && (
                  <Box display="flex" flexDirection="column" width="100%">
                    <Text as="h3" variant="title-bold-16">
                      {t('progress_import')}
                    </Text>

                    <S.Container>
                      <Text as="h2" variant="title-bold-20" mb="sm">
                        {t('enqueued_progress_status')}
                      </Text>
                    </S.Container>
                  </Box>
                )}
              </FlexGridItem>
            </FlexGrid>
          </Box>
        </Loader>
      </PageContainer>
    </S.Wrapper>
  );
};

export default withRouter(ImportWebSummary);
