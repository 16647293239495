const enrollmentPlanStatus = {
  processing: {
    text: 'Processando pagamento',
    variation: 'dark',
  },
  authorized: {
    text: 'Pagamento autorizado',
    variation: 'dark',
  },
  billet_paid: {
    text: 'Pago via boleto',
    variation: 'success',
  },
  credit_card_paid: {
    text: 'Pago via cartão',
    variation: 'success',
  },
  manually_paid: {
    text: 'Pago na escola',
    variation: 'success',
  },
  pix_paid: {
    text: 'Pago via PIX',
    variation: 'success',
  },
  paid_late: {
    text: 'Pago atrasado',
    variation: 'success',
  },
  paid_divergent: {
    text: 'Valor divergente',
    variation: 'warning',
  },
  pending: {
    text: 'Pendente',
    variation: 'warning',
  },
  generated_billet: {
    text: 'Gerou boleto',
    variation: 'info',
  },
  generated_pix: {
    text: 'Gerou PIX',
    variation: 'info',
  },
  canceled: {
    text: 'Cancelado',
    variation: 'dimming',
  },
  refused: {
    text: 'Recusado',
    variation: 'danger',
  },
  expired: {
    text: 'Encerrado',
    variation: 'danger',
  },
  waiting_payment: {
    text: 'Pendente',
    variation: 'warning',
  },
  sent: {
    text: 'Enviado',
    variation: 'info',
  },
  refunded: {
    text: 'Reembolsado',
    variation: 'dark',
  },
};

export default enrollmentPlanStatus;
