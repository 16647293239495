import createModuleReducer from '../storeConfig/createModuleReducer';
import actions from './actions';

export const initialForm = () => ({
  legacy_id: '',
  name: '',
});

export const INITIAL_STATE = {
  error: null,
  isLoading: false,
  isLoadingHistory: false,
  isSubmitting: false,
  canShowEmptyState: false,
  headquarters: [],
  headquartersHistory: [],
  headquartersHistoryCount: null,
  headquartersHistoryPagination: {},
  form: initialForm(),
  formMeta: {
    nextStepDisabled: false,
  },
};

export const addHeadquarter = (state) => ({
  ...state,
  isSubmitting: true,
});

export const addHeadquarterSuccess = (state) => ({
  ...state,
  isSubmitting: false,
});

export const editHeadquarter = (state) => ({
  ...state,
  isSubmitting: true,
});

export const editHeadquarterSuccess = (state) => ({
  ...state,
  isSubmitting: false,
});

export const setHeadquarter = (state) => ({
  ...state,
  isLoading: true,
});

export const setHeadquarterSuccess = (state, action) => {
  const { data } = action;
  return {
    ...state,
    isLoading: false,
    form: {
      ...data.attributes,
    },
  };
};

export const fetchHeadquarters = (state) => ({
  ...state,
  isLoading: true,
});

export const fetchHeadquartersSuccess = (state, action) => {
  const { data } = action;
  return {
    ...state,
    isLoading: false,
    headquarters: data,
  };
};

export const fetchHeadquartersHistory = (state) => ({
  ...state,
  isLoadingHistory: true,
});

export const fetchHeadquartersHistorySuccess = (state, action) => {
  const { data, pagination } = action;

  return {
    ...state,
    isLoadingHistory: false,
    headquartersHistory: data,
    headquartersHistoryPagination: pagination,
  };
};

export const fetchHeadquartersHistoryCount = (state) => ({
  ...state,
});

export const fetchHeadquartersHistoryCountSuccess = (state, action) => {
  const { data } = action;

  return {
    ...state,
    headquartersHistoryCount: data[0].count,
  };
};

export const setCanShowEmptyState = (state, action) => ({
  ...state,
  canShowEmptyState: action.state,
});

export const setError = (state, action) => ({
  ...state,
  error: action.error,
  isSubmitting: false,
  isLoading: false,
});

const buildToastSuccess = (state) => ({ ...state, isLoading: false });

const HANDLERS = {
  [actions.ADD_HEADQUARTER]: addHeadquarter,
  [actions.ADD_HEADQUARTER_SUCCESS]: addHeadquarterSuccess,
  [actions.SET_HEADQUARTER]: setHeadquarter,
  [actions.SET_HEADQUARTER_SUCCESS]: setHeadquarterSuccess,
  [actions.EDIT_HEADQUARTER]: editHeadquarter,
  [actions.EDIT_HEADQUARTER_SUCCESS]: editHeadquarterSuccess,
  [actions.FETCH_HEADQUARTERS]: fetchHeadquarters,
  [actions.FETCH_HEADQUARTERS_SUCCESS]: fetchHeadquartersSuccess,
  [actions.FETCH_HEADQUARTERS_HISTORY]: fetchHeadquartersHistory,
  [actions.FETCH_HEADQUARTERS_HISTORY_SUCCESS]: fetchHeadquartersHistorySuccess,
  [actions.FETCH_HEADQUARTERS_HISTORY_COUNT]: fetchHeadquartersHistoryCount,
  [actions.FETCH_HEADQUARTERS_HISTORY_COUNT_SUCCESS]:
    fetchHeadquartersHistoryCountSuccess,
  [actions.BUILD_TOAST_SUCCESS]: buildToastSuccess,
  [actions.SET_CAN_SHOW_EMPTY_STATE]: setCanShowEmptyState,
  [actions.SET_ERROR]: setError,
};

const headquarters = createModuleReducer(INITIAL_STATE, HANDLERS);

export default headquarters;
