import styled, { css } from 'styled-components';

export const Section = styled.ul`
  padding-left: 0;

  width: 100%;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 24px;

  @media screen and (min-width: 992px) {
    grid-template-columns: repeat(2, minmax(230px, 1fr));
  }

  @media screen and (min-width: 1200px) {
    grid-template-columns: repeat(4, minmax(230px, 1fr));
  }
`;

export const Item = styled.li`
  ${({ theme, hasElement }) => css`
    list-style: none;

    display: ${hasElement.includes(
      ['hasDeadline', 'hasActivityDelivery'] === false
    )
      ? 'none'
      : 'flex'};

    .info-card {
      width: auto;

      .icon-area {
        height: 48px;
        padding: 8px;
        background-color: rgba(104, 55, 172, 0.1);
        border-radius: 8px;

        .icon {
          padding: 3.3px 3.3px 3.3px;
          color: ${theme.primaryColor};
        }
      }
    }
  `}
`;
