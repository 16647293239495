/* eslint-disable import/no-unresolved */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';

import withAppContext from 'core/hoc/withAppContext';
import withToastMessage from 'core/hoc/withToastMessage';

import AgendaIcon from 'components/AgendaIcon';
import PageTitle from 'components/PageTitle';
import PageSubTitle from 'components/PageSubTitle';
import Button from 'components/Button';

import './style.scss';

const ERROR_MAPPINGS = {
  default: 'Ops! Ocorreu um erro interno!',
  504: 'Ops! Parece que a página demorou muito a carregar.',
  404: 'Ops! Página não encontrada!',
};

const ERROR_SUBTITLE_MAPPING = {
  default:
    'Mas vai ficar tudo bem! Nossos analistas já foram avisados sobre esse erro.',
  404: 'Essa página não existe ou você não a possui acesso.',
};

class InternalErrorBoundary extends Component {
  static propTypes = {
    appContext: PropTypes.shape({
      currentUser: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }),
      currentUserType: PropTypes.string.isRequired,
      currentSchool: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }),
      dataArea: PropTypes.oneOf(['schools', 'responsibles', 'students']),
    }).isRequired,
    children: PropTypes.node.isRequired,
    toastActions: PropTypes.shape({
      showToast: PropTypes.func,
    }).isRequired,
  };

  constructor(props) {
    super(props);

    const { currentUser, currentUserType, currentSchool, dataArea } =
      this.props.appContext;

    Sentry.configureScope((scope) => {
      scope.setExtra('id', currentUser.id);
      scope.setExtra('currentUserType', currentUserType);
      scope.setExtra('currentSchoolId', currentSchool.id);
      scope.setExtra('dataArea', dataArea);
    });

    this.state = {
      error: null,
    };
  }

  onTryAgainClick() {
    window.location.reload();
  }

  componentDidCatch(error, info) {
    const {
      toastActions: { showToast },
    } = this.props;

    if (error.response) {
      if (error.response.status === 403) {
        const messages = error.response.data.errors.map(({ detail }) => detail);
        showToast(messages.join('\n'));
      } else {
        this.setState({
          error,
          errorCode: error.response && error.response.status,
          info,
        });
      }
    } else {
      Sentry.captureException(error, { extra: info });
    }
  }

  render() {
    const { error, errorCode } = this.state;

    if (error) {
      return (
        <div className="InternalErrorBoundary">
          <AgendaIcon name="alert" size="x-large" />
          <PageTitle>
            {ERROR_MAPPINGS[errorCode] || ERROR_MAPPINGS.default}
          </PageTitle>
          <PageSubTitle>
            {ERROR_SUBTITLE_MAPPING[errorCode] ||
              ERROR_SUBTITLE_MAPPING.default}
          </PageSubTitle>
          <PageSubTitle>
            Se preferir, entre em contato pelo nosso e-mail de suporte
            <span>
              {' '}
              <a href="mail:to:">suporte@agendaedu.com</a>
            </span>
          </PageSubTitle>
          <Button onClick={this.onTryAgainClick}>Tentar novamente</Button>
        </div>
      );
    }

    return this.props.children;
  }
}

export default withAppContext(withToastMessage(InternalErrorBoundary));
