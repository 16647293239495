import React from 'react';
import {
  Box,
  Grid,
  theme,
  DefaultThemeProps,
} from '@agendaedu/ae-web-components';

import Skeleton from 'components/Skeleton';

import { handoutsGrid } from 'core/constants/handouts';

import * as S from './styles';

export const HandoutsFiltersSkeleton = () => {
  const defaultTheme: DefaultThemeProps = theme;

  const {
    colors: { neutral },
    border: { radius },
  } = defaultTheme;

  return (
    <S.FiltersSkeletonWrapper data-testid="handouts-filters-skeleton">
      <S.HeaderSkeleton>
        <Skeleton
          width={210}
          height={40}
          primaryColor={neutral.gray3}
          borderRadius={radius.md}
        />

        <Skeleton
          width={210}
          height={35}
          primaryColor={neutral.gray2}
          borderRadius={radius.md}
        />
      </S.HeaderSkeleton>

      <Grid
        mb="xl"
        gutter="md"
        gridTemplateAreas={handoutsGrid.filtersTemplateAreas}
        gridTemplateColumns={handoutsGrid.filtersTemplateColumns}
      >
        <Grid item gridArea="dates">
          <S.FieldSkeleton />
        </Grid>

        <Grid item gridArea="situation">
          <S.FieldSkeleton />
        </Grid>

        <Grid item gridArea="title">
          <S.FieldSkeleton />
        </Grid>

        <Grid item gridArea="buttons">
          <Box display="flex" justifyContent="flex-end">
            <Box mr="sm">
              <Skeleton width={40} height={40} borderRadius={radius.md} />
            </Box>
            <Box mr="sm">
              <Skeleton width={40} height={40} borderRadius={radius.md} />
            </Box>
            <Skeleton width={40} height={40} borderRadius={radius.md} />
          </Box>
        </Grid>
      </Grid>
    </S.FiltersSkeletonWrapper>
  );
};
