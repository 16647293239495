import React, { useContext } from 'react';

import Card from '../';
import QuantityCard from '../QuantityCard';
import DailySummariesContext from 'core/contexts/DailySummaries';

const Bath = () => {
  const {
    form: { bath },
    handleForm,
    isDisabled,
    hasStudentSelected,
  } = useContext(DailySummariesContext);

  const handleDecrement = () => {
    if (!isDisabled && bath > 0) handleForm('bath', bath - 1);
  };

  const handleIncrement = () => {
    if (!isDisabled) handleForm('bath', bath + 1);
  };

  const disableCard = !hasStudentSelected || isDisabled;

  return (
    <Card title="Banho">
      <QuantityCard
        label="Indique a quantidade"
        onDecrement={handleDecrement}
        onIncrement={handleIncrement}
        quantity={bath}
        disabled={disableCard}
      />
    </Card>
  );
};

export default Bath;
