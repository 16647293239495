import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import {
  buildToast,
  ToastTypes,
  toastCss,
} from 'store/middlewares/toast/actions';
import { fetchApi, postApi, putApi, deleteApi } from 'core/utils/api';
import actions from './actions';

const getStudentProfileIdsValue = (students) => {
  return Array.from(students, (student) => student.value);
};

function* fetchFormMeta() {
  try {
    const dataArea = yield select((state) => state.root.dataArea);

    const { meta } = yield call(
      fetchApi,
      `/${dataArea}/responsible_profiles/new.json`
    );

    yield put({
      type: actions.FETCH_FORM_META_SUCCESS,
      meta,
    });
  } catch (error) {
    yield put({
      type: actions.SET_ERROR,
      error,
    });
  }
}

function* fetchStudentProfiles() {
  try {
    const dataArea = yield select((state) => state.root.dataArea);

    const { data } = yield call(
      fetchApi,
      `/${dataArea}/student_profiles/all.json?per_page=999999`
    );

    yield put({
      type: actions.FETCH_STUDENT_PROFILES_SUCCESS,
      data,
    });
  } catch (error) {
    yield put({
      type: actions.SET_ERROR,
      error,
    });
  }
}

function* setResponsibleProfile({ id }) {
  try {
    const dataArea = yield select((state) => state.root.dataArea);

    const { data: studentProfilesData } = yield call(
      fetchApi,
      `/${dataArea}/student_profiles/all.json?per_page=999999`
    );

    const { data: responsibleProfileData, meta } = yield call(
      fetchApi,
      `/${dataArea}/responsible_profiles/${id}/edit.json`
    );

    yield put({
      type: actions.SET_RESPONSIBLE_PROFILE_SUCCESS,
      studentProfilesData,
      responsibleProfileData,
      meta,
    });
  } catch (error) {
    yield put({
      type: actions.SET_ERROR,
      toast: buildToast(
        'Erro ao tentar recuperar responsável.',
        ToastTypes.error
      ),
      error,
    });
  }
}

function* setRecoveryResponsibleProfile({ id }) {
  try {
    const dataArea = yield select((state) => state.root.dataArea);

    const { data: students } = yield call(
      fetchApi,
      `/${dataArea}/student_profiles/all.json?per_page=999999`
    );

    const { data: responsible, meta } = yield call(
      fetchApi,
      `/${dataArea}/responsible_profiles/${id}/recover.json`
    );

    yield put({
      type: actions.SET_RECOVERY_RESPONSIBLE_PROFILE_SUCCESS,
      responsible,
      students,
      meta,
    });
  } catch (error) {
    yield put({
      type: actions.SET_ERROR,
      toast: buildToast(
        'Erro ao tentar recuperar responsável.',
        ToastTypes.error
      ),
      error,
    });
  }
}

function* setReviewerResponsibleProfile({ id }) {
  try {
    const dataArea = yield select((state) => state.root.dataArea);

    const { data: studentProfilesData } = yield call(
      fetchApi,
      `/${dataArea}/student_profiles/all.json?per_page=999999`
    );

    const { data: responsibleProfileData, meta } = yield call(
      fetchApi,
      `/${dataArea}/responsible_profiles/${id}/review.json`
    );

    yield put({
      type: actions.SET_REVIEWER_RESPONSIBLE_PROFILE_SUCCESS,
      studentProfilesData,
      responsibleProfileData,
      meta,
    });
  } catch (error) {
    yield put({
      type: actions.SET_ERROR,
      toast: buildToast(
        'Erro ao tentar recuperar responsável.',
        ToastTypes.error
      ),
      error,
    });
  }
}

function* createResponsibleProfile({ params }) {
  try {
    const dataArea = yield select((state) => state.root.dataArea);

    const { form, redirect } = params;

    const studentProfileIds = getStudentProfileIdsValue(
      form.student_profile_ids
    );

    const newForm = {
      ...form,
      kinship: form.kinship.value,
      student_profile_ids: studentProfileIds,
      email: form.email.toLowerCase(),
    };

    yield call(postApi, `/${dataArea}/responsible_profiles`, {
      responsible_profile: newForm,
    });

    if (redirect) window.location = redirect;

    yield put({
      type: actions.CREATE_RESPONSIBLE_PROFILE_SUCCESS,
      toast: buildToast(
        'Responsável cadastrado com sucesso.',
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.SET_ERROR,
      toast: buildToast(
        'Erro ao tentar cadastrar responsável.',
        ToastTypes.error
      ),
      error,
    });
  }
}

function* updateResponsibleProfile({ id, params }) {
  try {
    const { form, redirect, sourceRequest } = params;

    const dataArea = yield select((state) => state.root.dataArea);

    const studentProfileIds = getStudentProfileIdsValue(
      form.student_profile_ids
    );

    const newForm = {
      ...form,
      kinship: form.kinship.value,
      student_profile_ids: studentProfileIds,
      email: form.email.toLowerCase(),
    };

    yield call(putApi, `/${dataArea}/responsible_profiles/${id}`, {
      responsible_profile: newForm,
      source_request: sourceRequest,
    });

    window.location = redirect;
    yield put({
      type: actions.UPDATE_RESPONSIBLE_PROFILE_SUCCESS,
      toast: buildToast(
        'Responsável atualizado com sucesso.',
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.SET_ERROR,
      toast: buildToast(
        'Erro ao tentar atualizar responsável.',
        ToastTypes.error
      ),
      error,
    });
  }
}

function* approveResponsibleProfile({ id, params }) {
  try {
    const { form, redirect, sourceRequest } = params;

    const dataArea = yield select((state) => state.root.dataArea);

    const studentProfileIds = getStudentProfileIdsValue(
      form.student_profile_ids
    );

    const newForm = {
      ...form,
      kinship: form.kinship.value,
      student_profile_ids: studentProfileIds,
      email: form.email.toLowerCase(),
    };

    yield call(putApi, `/${dataArea}/responsible_profiles/${id}/approve`, {
      responsible_profile: newForm,
      source_request: sourceRequest,
    });

    window.location = redirect;
    yield put({
      type: actions.APPROVE_RESPONSIBLE_PROFILE_SUCCESS,
      toast: buildToast(
        'Responsável aprovado com sucesso.',
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.SET_ERROR,
      toast: buildToast(
        'Erro ao tentar aprovar responsável.',
        ToastTypes.error
      ),
      error,
    });
  }
}

function* recoverResponsibleProfile({ id, params }) {
  try {
    const dataArea = yield select((state) => state.root.dataArea);

    const studentProfileIds = getStudentProfileIdsValue(
      params.student_profile_ids
    );

    const newParams = {
      ...params,
      kinship: params.kinship.value,
      student_profile_ids: studentProfileIds,
      email: params.email.toLowerCase(),
    };

    yield call(putApi, `/${dataArea}/responsible_profiles/${id}/run_recover`, {
      responsible_profile: newParams,
    });

    window.location = `/${dataArea}/responsible_profiles`;

    yield put({
      type: actions.RECOVER_RESPONSIBLE_PROFILE_SUCCESS,
      toast: buildToast(
        'Responsável recuperado com sucesso.',
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.SET_ERROR,
      toast: buildToast(
        'Erro ao tentar recuperar responsável.',
        ToastTypes.error
      ),
      error,
    });
  }
}

function* removeResponsibleProfile({ id }) {
  try {
    const dataArea = yield select((state) => state.root.dataArea);

    yield call(deleteApi, `/${dataArea}/responsible_profiles/${id}/deny`);

    window.location = `/${dataArea}/responsible_profiles`;
    yield put({
      type: actions.REMOVE_RESPONSIBLE_PROFILE_SUCCESS,
      toast: buildToast(
        'Responsável removido com sucesso.',
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.SET_ERROR,
      toast: buildToast(
        'Erro ao tentar remover responsável.',
        ToastTypes.error
      ),
      error,
    });
  }
}

function* responsibleProfilesSagas() {
  yield all([takeLatest(actions.FETCH_FORM_META, fetchFormMeta)]);
  yield all([takeLatest(actions.FETCH_STUDENT_PROFILES, fetchStudentProfiles)]);
  yield all([
    takeLatest(actions.SET_RESPONSIBLE_PROFILE, setResponsibleProfile),
  ]);
  yield all([
    takeLatest(
      actions.SET_RECOVERY_RESPONSIBLE_PROFILE,
      setRecoveryResponsibleProfile
    ),
  ]);
  yield all([
    takeLatest(
      actions.SET_REVIEWER_RESPONSIBLE_PROFILE,
      setReviewerResponsibleProfile
    ),
  ]);
  yield all([
    takeLatest(actions.CREATE_RESPONSIBLE_PROFILE, createResponsibleProfile),
  ]);
  yield all([
    takeLatest(actions.UPDATE_RESPONSIBLE_PROFILE, updateResponsibleProfile),
  ]);
  yield all([
    takeLatest(actions.APPROVE_RESPONSIBLE_PROFILE, approveResponsibleProfile),
  ]);
  yield all([
    takeLatest(actions.RECOVER_RESPONSIBLE_PROFILE, recoverResponsibleProfile),
  ]);
  yield all([
    takeLatest(actions.REMOVE_RESPONSIBLE_PROFILE, removeResponsibleProfile),
  ]);
}

export default responsibleProfilesSagas;
