import styled, { css } from 'styled-components';

export const DateFieldWrapper = styled.div`
  ${({ theme, withYearSelect, withYearMonthSelect }) => css`
    * {
      font-family: Quicksand, sans-serif;
    }

    .input-group {
      width: 100%;
    }

    .FormValidationErrors {
      font-family: Roboto, sans-serif;
    }

    .SingleDatePicker {
      width: 100%;


      @media (min-width: 834px) {
        width: inherit;
      }

      .DateInput_fang {
        top: 42px !important;
      }

      .SingleDatePicker_picker {
        top: 53px !important;
      }

      .SingleDatePickerInput {
        width: 100%;
        height: 40px;
        border: 1px solid ${theme.gray12};
        border-radius: 6px;
        background-color: transparent;
        display: flex;
        align-items: center;

        &:hover, &:active, &:focus {
          border: 1px solid ${theme.primaryColor};
        }
      }

      .DateInput_1 {
        background: none;

        .DateInput_fang {
          z-index: 4;
        }

        .DateInput_input {
          height: 40px;
          padding: 8px;
          font-family: Roboto, sans-serif;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: normal;
          text-align: left;
          color: ${theme.black};
          background-color: transparent;
          border: 1px solid transparent;

          &.DateInput_input__focused {
            border-color: transparent;
            background-color: transparent;
          }

          &::placeholder {
            color: ${theme.gray1};
          }
        }
      }

      .SingleDatePicker_picker {
        z-index: 10;

        ${
          (withYearSelect || withYearMonthSelect) &&
          css`
            .calendar-caption {
              display: flex;
              align-items: center;
              justify-content: space-evenly;
              margin: 0 auto;
              width: 65%;
              font-weight: 700;
            }

            .DayPicker_weekHeader {
              top: 75px;
            }

            .Select {
              flex: 0 0 78px;

              select {
                border-radius: 6px;
              }
            }

            .DayPickerNavigation_container button {
              border-radius: 6px;
              margin-top: 8px;
            }
          `
        }

        .CalendarDay {

          &.CalendarDay__default {
            border-color: transparent;
            border-radius: 8px;
            color: ${theme.gray2};

            &:hover {
              background: ${theme.primaryColor}1a;
              color: ${theme.primaryColor};
            }

          &.CalendarDay__selected {
            background: ${theme.primaryColor};
            color: ${theme.white};
          }

          &.CalendarDay__blocked_out_of_range,
          &.CalendarDay__blocked_out_of_range:active,
          &.CalendarDay__blocked_out_of_range:hover {
            background: ${theme.white};
            border: 1px solid transparent;
            color: ${theme.gray3};
          }
        }

        &.CalendarDay__outside {
          color: ${theme.gray3};
        }
      }
    }

      .SingleDatePickerInput_calendarIcon {
        padding: 8px;
        margin: 0;
        height: 100%;
      }
    }

    .DayPicker_focusRegion {
      .DayPickerNavigation_container {
        .DayPickerNavigation_button {
          border-radius: 6px;
          margin-top: 4px;
          svg {
            display: inline;
          }
        }
      }
  `}
`;

export const InputGroup = styled.div`
  ${({ theme, disabled }) => css`
    .DayPickerNavigation_button {
      background-color: transparent;
      padding: 4px;
      color: ${theme.gray1};
      border: 1px solid ${theme.gray12};
      border-radius: 8px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
    }

    i {
      background-color: ${disabled ? theme.gray14 : theme.white};
      border-color: ${theme.gray12};

      &.input-group-addon {
        border: none;
        color: ${theme.primaryColor};
        padding: 0;
      }
    }

    ${disabled &&
    css`
      .SingleDatePickerInput__disabled {
        background-color: ${theme.gray14};
      }

      .DateInput_input__disabled {
        background-color: ${theme.gray14};
      }
    `}
  `}
`;

export const ButtonContainer = styled.div`
  width: 100%;
  padding: 24px;
  padding-top: 0;
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 24px;

  .Button {
    font-family: 'Quicksand', sans-serif;
    border-radius: 6px;

    &#apply-button,
    &#clear-button {
      display: initial;
    }
  }
`;

export const ActionButtonRow = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  gap: 16px;

  > .Button {
    flex-grow: 1;
    font-size: 16px;
  }
`;
