import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';

import walletsActions from 'store/edupay/wallets/actions';
import withFormContext from 'core/hoc/withFormContext';
import FormFieldset from 'components/Form/Fieldset';
import CheckPaymentMethods from 'components/Form/CheckPaymentMethods';
import Field from 'components/Form/Field';
import InstallmentFeesConfiguration from 'components/Payments/PaymentsContainer/FormSections/InstallmentFeesConfiguration';

import {
  Icon,
  Text,
  theme,
  DefaultThemeProps,
} from '@agendaedu/ae-web-components';

import * as S from './styles';

import tabifyForWizard from 'core/hoc/tabifyForWizard';
import { NEW_OFFER_MAX_INSTALLMENTS } from 'core/constants';

const CartSettingsForm = ({
  formContext: {
    form: {
      recipient_wallet_id: recipientWalletId,
      max_installments: maxInstallments,
      allowed_payment_method: allowedPaymentMethods,
    },
    formMeta: { can_absorb_tax, allowed_payment_method },
    updateAttribute,
  },
}) => {
  const defaultTheme: DefaultThemeProps = theme;
  const { t } = useTranslation(['payments']);
  const dispatch = useDispatch();

  const { fetchRecipientsWalletsOptionsRequest } = walletsActions;
  const { walletsOptions } = useSelector((state) => state.wallets);
  const {
    policies: { edupay_provider },
  } = useSelector((state) => state.root);
  const isBemobi = edupay_provider === 'bemobi';

  const [currentRecipientWalletId, setCurrentRecipientWalletId] =
    useState(recipientWalletId);

  useEffect(() => {
    updateAttribute(
      'recipient_wallet_id',
      currentRecipientWalletId !== null ? currentRecipientWalletId : null
    );
  }, [currentRecipientWalletId, updateAttribute]);

  useEffect(() => {
    dispatch(fetchRecipientsWalletsOptionsRequest());
  }, [dispatch, fetchRecipientsWalletsOptionsRequest]);

  return (
    <S.FormContainer>
      <FormFieldset>
        <S.WrapperAlert variant="informative">
          <Text variant="subtitle-medium-14" mb={0}>
            {t('marketplace.cart_shopping_setting.alert')}
          </Text>
        </S.WrapperAlert>

        <S.WrapperTitle>
          <Text
            variant="title-bold-20"
            fontWeight={700}
            mr="8px"
            mb={0}
            color={defaultTheme.colors.neutral.black}
          >
            {t('marketplace.cart_shopping_setting.wallet_title')}
          </Text>
          <S.WrapperTooltip
            content={parse(
              t('marketplace.cart_shopping_setting.wallet_title_tooltip')
            )}
          >
            <Icon
              name="help-circle"
              size="sm"
              color={defaultTheme.colors.neutral.gray1}
            />
          </S.WrapperTooltip>
        </S.WrapperTitle>
        <S.WrapperField
          fullWidth
          withArrowSelect
          label={t('marketplace.cart_shopping_setting.wallet_label')}
          type="selectWithIcon"
          attributeName="recipient_wallet_id"
          classNamePrefix="wallet_configuration"
          options={walletsOptions.filter(
            (option) => !option.label?.includes('Mensalidade Garantida')
          )}
          value={Number(currentRecipientWalletId)}
          onChange={(e) => {
            setCurrentRecipientWalletId(e.value?.toString());
          }}
        />

        <S.WrapperTitle>
          <Text
            variant="title-bold-20"
            fontWeight={700}
            mr="8px"
            mb={0}
            color={defaultTheme.colors.neutral.black}
          >
            {t('marketplace.cart_shopping_setting.payments_method_title')}
          </Text>
          <S.WrapperTooltip
            content={parse(
              t('marketplace.cart_shopping_setting.payments_method_tooltip')
            )}
          >
            <Icon
              name="help-circle"
              size="sm"
              color={defaultTheme.colors.neutral.gray1}
            />
          </S.WrapperTooltip>
        </S.WrapperTitle>
        <CheckPaymentMethods
          withPix
          attributeName="allowed_payment_method"
          isNewLayout
          isCartSettings
          cartSettingsAllowedPaymentMethods={allowed_payment_method}
        />
        {can_absorb_tax && isBemobi && <InstallmentFeesConfiguration />}
        {!isBemobi && allowedPaymentMethods.includes('credit_card') && (
          <Field
            fullWidth
            withArrowSelect
            type="selectWithIcon"
            label={t('marketplace.cart_shopping_setting.max_installments')}
            attributeName="max_installments"
            options={NEW_OFFER_MAX_INSTALLMENTS}
            value={maxInstallments}
            onChange={(e) => updateAttribute('max_installments', e.value)}
          />
        )}
      </FormFieldset>
    </S.FormContainer>
  );
};

export default tabifyForWizard({
  title: '',
  subTitle: '',
  validations: [],
})(withFormContext(CartSettingsForm));
