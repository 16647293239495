import createModuleReducer from '../../storeConfig/createModuleReducer';
import actions from './actions';

export const INITIAL_STATE = {
  recurrentBills: [],
  visualizations: [],
  isLoading: false,
  isLoadingPlan: true,
  isLoadingManuallyPaid: false,
  isLoadingRefund: false,
  isLoadingRevertManualPayment: false,
  modalOpen: false,
  cancelModal: false,
  manuallyPaidModal: false,
  paymentModalByMethod: false,
  paymentLinkModalByMethod: false,
  delayModal: false,
  refundModal: false,
  current: undefined,
  editing: false,
  error: null,
  errorMessage: null,
  manualDealModal: false,
  isLoadingManualDeal: false,
  selectedRecurrentBills: [],
  selectedAmount: 0,
  wasSelectedAllBills: false,
  hasBatchSelection: false,
  isTableEdit: false,
};

export const cancelLoading = (state) => ({
  ...state,
  isLoading: false,
  isLoadingPlan: false,
  isLoadingManuallyPaid: false,
});

export const fetchRequest = (state) => ({
  ...state,
  isLoading: true,
});

export const fetchSuccess = (state, action) => ({
  ...state,
  recurrentBills: action.data,
  visualizations: action.visualizations,
  isLoading: false,
});

export const fetchBill = (state) => ({
  ...state,
  isLoading: true,
});

export const fetchBillSuccess = (state, action) => ({
  ...state,
  current: action.data,
  isLoading: false,
});

export const setBillSuccess = (state, action) => {
  const currentBill = state.recurrentBills.find(
    (bill) => bill.id === action.id
  );

  return {
    ...state,
    current: {
      ...action.plan,
      ...currentBill,
      order: currentBill.attributes.order.data,
    },
  };
};

export const loadInformationToPaid = (state) => ({
  ...state,
  isLoadingManuallyPaid: true,
});

export const loadInformationToPaidSuccess = (state, action) => ({
  ...state,
  isLoadingManuallyPaid: false,
  current: {
    ...state.current,
    responsibleProfiles: action.data.attributes.responsible_profiles.data,
  },
});

export const toogleManuallyPaidModal = (state) => ({
  ...state,
  manuallyPaidModal: !state.manuallyPaidModal,
});

export const toggleBilletOrPixModal = (state) => ({
  ...state,
  errorMessage: null,
  paymentModalByMethod: !state.paymentModalByMethod,
});

export const toggleBilletOrPixLinkModal = (state) => ({
  ...state,
  paymentLinkModalByMethod: !state.paymentLinkModalByMethod,
});

export const toggleDelayModal = (state) => ({
  ...state,
  delayModal: !state.delayModal,
});

export const toggleTableEdit = (state) => ({
  ...state,
  isTableEdit: !state.isTableEdit,
});

export const manuallyPay = (state) => ({
  ...state,
  isLoadingManuallyPaid: true,
});

export const manuallyPaySuccess = (state, action) => {
  const index = state.recurrentBills.findIndex((bill) => bill.id === action.id);

  const recurrentBills = [
    ...state.recurrentBills.slice(0, index),
    {
      ...state.recurrentBills[index],
      attributes: {
        ...state.recurrentBills[index].attributes,
        status: 'manually_paid',
      },
    },
    ...state.recurrentBills.slice(index + 1),
  ];

  return {
    ...state,
    isLoadingManuallyPaid: false,
    manuallyPaidModal: false,
    recurrentBills,
    current: undefined,
  };
};

export const manuallyPaySuccessLD = (state, action) => {
  const index = state.recurrentBills.findIndex((bill) => bill.id === action.id);

  const recurrentBills = [
    ...state.recurrentBills.slice(0, index),
    {
      ...state.recurrentBills[index],
      attributes: {
        ...state.recurrentBills[index].attributes,
        status: 'manually_paid',
      },
    },
    ...state.recurrentBills.slice(index + 1),
  ];

  return {
    ...state,
    recurrentBills,
    current: undefined,
    isLoadingManualDeal: false,
  };
};

export const manuallyPayLD = (state) => {
  return {
    ...state,
    isLoadingManualDeal: true,
  };
};

export const checkout = (state) => ({
  ...state,
  isLoadingManuallyPaid: true,
});

export const checkoutSuccess = (state, action) => {
  const index = state.recurrentBills.findIndex((bill) => bill.id === action.id);

  const recurrentBills = [
    ...state.recurrentBills.slice(0, index),
    {
      ...state.recurrentBills[index],
      attributes: {
        ...state.recurrentBills[index].attributes,
        status: action.data.data.attributes.status,
        order: action.data,
      },
    },
    ...state.recurrentBills.slice(index + 1),
  ];

  return {
    ...state,
    isLoadingManuallyPaid: false,
    recurrentBills,
    current: {
      ...state.current,
      order: action.data.data,
    },
  };
};

export const unsetBill = (state) => ({
  ...state,
  current: undefined,
});

export const cancelBillSuccess = (state, action) => ({
  ...state,
  recurrentBills: state.recurrentBills.map((bill) =>
    bill.id === action.id ? { ...action.data } : bill
  ),
});

export const cancelAndCreateNewBillSuccess = (state) => ({
  ...state,
  current: undefined,
  isLoading: false,
});

export const delayBillSuccess = (state, action) => ({
  ...state,
  recurrentBills: state.recurrentBills.map((bill) =>
    bill.id === action.id ? { ...action.data } : bill
  ),
  delayModal: false,
});

export const requestRefundBill = (state, _action) => ({
  ...state,
  isLoadingRefund: true,
});

export const refundBillSuccess = (state, action) => ({
  ...state,
  recurrentBills: state.recurrentBills.map((bill) =>
    bill.id === action.id ? { ...action.data } : bill
  ),
  refundModal: false,
  isLoadingRefund: false,
});

export const requestRevertManualPayment = (state, _action) => ({
  ...state,
  isLoadingRevertManualPayment: true,
});

export const revertManualPaymentSuccess = (state, action) => ({
  ...state,
  recurrentBills: state.recurrentBills.map((bill) =>
    bill.id === action.id ? { ...action.data } : bill
  ),
  revertManualPaymentModal: false,
  isLoadingRevertManualPayment: false,
});

export const toggleModal = (state) => ({
  ...state,
  modalOpen: !state.modalOpen,
});

export const toggleCancelModal = (state) => ({
  ...state,
  cancelModal: !state.cancelModal,
});

export const toggleRefundModal = (state) => ({
  ...state,
  refundModal: !state.refundModal,
});

export const toggleRevertManualPaymentModal = (state) => ({
  ...state,
  revertManualPaymentModal: !state.revertManualPaymentModal,
});

export const editBill = (state) => ({
  ...state,
  isLoading: true,
});

export const editBillSuccess = (state) => ({
  ...state,
  current: undefined,
  isLoading: false,
});

const negotiationBillRequest = (state) => ({
  ...state,
  isLoadingManualDeal: true,
});

const negotiationBillSuccess = (state, action) => ({
  ...state,
  recurrentBills: state.recurrentBills.map((bill) =>
    bill.id === action.id ? { ...action.data } : bill
  ),
  isLoadingManualDeal: false,
});

export const toggleManualDealModal = (state) => ({
  ...state,
  manualDealModal: !state.manualDealModal,
});

export const handleError = (state, action) => ({
  ...state,
  error: action.error,
  isLoading: false,
  isLoadingManuallyPaid: false,
  isLoadingRefund: false,
  isLoadingManualDeal: false,
  isLoadingRevertManualPayment: false,
});

export const handleErrorCheckout = (state, action) => {
  const {
    error: {
      data: { errors },
    },
  } = action;

  return {
    ...state,
    errorMessage: errors,
    isLoading: false,
    isLoadingManuallyPaid: false,
    isLoadingRefund: false,
    isLoadingRevertManualPayment: false,
  };
};

export const updateLegacyId = (state) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const toggleIsLoadingManualDeal = (state) => ({
  ...state,
  isLoadingManualDeal: !state.isLoadingManualDeal,
});

const HANDLERS = {
  [actions.CANCEL_BILL_SUCCESS]: cancelBillSuccess,
  [actions.CANCEL_AND_CREATE_NEW_BILL_SUCCESS]: cancelAndCreateNewBillSuccess,
  [actions.CANCEL_LOADING]: cancelLoading,
  [actions.EDIT_BILL]: editBill,
  [actions.EDIT_BILL_SUCCESS]: editBillSuccess,
  [actions.ERROR]: handleError,
  [actions.ERROR_CHECKOUT]: handleErrorCheckout,
  [actions.FETCH_BILL]: fetchBill,
  [actions.FETCH_BILL_SUCCESS]: fetchBillSuccess,
  [actions.FETCH_REQUEST]: fetchRequest,
  [actions.FETCH_SUCCESS]: fetchSuccess,
  [actions.CHECKOUT]: checkout,
  [actions.CHECKOUT_SUCCESS]: checkoutSuccess,
  [actions.DELAY_BILL_SUCCESS]: delayBillSuccess,
  [actions.REFUND_BILL]: requestRefundBill,
  [actions.REFUND_BILL_SUCCESS]: refundBillSuccess,
  [actions.REVERT_MANUAL_PAYMENT]: requestRevertManualPayment,
  [actions.REVERT_MANUAL_PAYMENT_SUCCESS]: revertManualPaymentSuccess,
  [actions.LOAD_INFORMATION_TO_PAID]: loadInformationToPaid,
  [actions.LOAD_INFORMATION_TO_PAID_SUCCESS]: loadInformationToPaidSuccess,
  [actions.MANUALLY_PAY]: manuallyPay,
  [actions.MANUALLY_PAY_SUCCESS]: manuallyPaySuccess,
  [actions.MANUALLY_PAY_LD_SUCCESS]: manuallyPaySuccessLD,
  [actions.MANUALLY_PAY_LD_REQUEST]: manuallyPayLD,
  [actions.SET_BILL_SUCCESS]: setBillSuccess,
  [actions.TOGGLE_BILLET_OR_PIX_MODAL]: toggleBilletOrPixModal,
  [actions.TOGGLE_BILLET_OR_PIX_LINK_MODAL]: toggleBilletOrPixLinkModal,
  [actions.TOGGLE_CANCEL_MODAL]: toggleCancelModal,
  [actions.TOGGLE_MANUALLY_PAID_MODAL]: toogleManuallyPaidModal,
  [actions.TOGGLE_MODAL]: toggleModal,
  [actions.TOGGLE_DELAY_MODAL]: toggleDelayModal,
  [actions.TOGGLE_REFUND_MODAL]: toggleRefundModal,
  [actions.TOGGLE_REVERT_MANUAL_PAYMENT_MODAL]: toggleRevertManualPaymentModal,
  [actions.TOGGLE_TABLE_EDIT]: toggleTableEdit,
  [actions.UNSET_BILL]: unsetBill,
  [actions.NEGOTIATION_BILL_REQUEST]: negotiationBillRequest,
  [actions.NEGOTIATION_BILL_SUCCESS]: negotiationBillSuccess,
  [actions.TOGGLE_MANUAL_DEAL_MODAL]: toggleManualDealModal,
  [actions.UPDATE_LEGACY_ID]: updateLegacyId,
  [actions.TOGGLE_IS_LOADING_MANUAL_DEAL]: toggleIsLoadingManualDeal,
};

const recurrentBills = createModuleReducer(INITIAL_STATE, HANDLERS);

export default recurrentBills;
