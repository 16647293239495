import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import withAppContext from 'core/hoc/withAppContext';

import Breadcrumb from 'components/Breadcrumb';
import Tabs from 'components/Tabs';
import Tab from 'components/Tab';
import Toast from 'components/Toast';

import actionHandouts from 'store/handouts/actions';
import { HandoutState } from 'store/handouts/types';

import { HandoutDetailsProps, SelectTabProps } from './types';

import { DetailsTab } from './DetailsTab';
import { EngagementTab } from './EngagementTab';
import { DetailsSkeleton } from './skeleton';

import * as S from './styles';

const HandoutDetails = ({
  appContext: { dataArea },
  match,
}: HandoutDetailsProps) => {
  const { id: handoutId } = match?.params;

  const dispatch = useDispatch();
  const { t } = useTranslation(['handouts', 'common']);

  const initialPage = 1;

  const { fetchHandoutRequest, fetchHandoutEngagementRequest } = actionHandouts;

  const { current, isLoading } = useSelector(
    (state: HandoutState) => state.handouts
  );

  const handleSelectTab = useCallback(
    ({ selectedTab }: SelectTabProps) => {
      if (selectedTab === 1)
        dispatch(fetchHandoutEngagementRequest({ current, page: initialPage }));
    },
    [dispatch, fetchHandoutEngagementRequest, current]
  );

  const fetchHandout = useCallback(
    (handoutId) => {
      const params = {
        id: handoutId,
      };

      dispatch(fetchHandoutRequest(params));
    },
    [dispatch, fetchHandoutRequest]
  );

  useEffect(() => {
    fetchHandout(handoutId);
  }, [fetchHandout, handoutId]);

  return (
    <>
      {isLoading ? (
        <DetailsSkeleton />
      ) : (
        <S.HandoutDetailsWrapper
          title={t('handout_details.title')}
          breadcrumb={
            <Breadcrumb
              title={t('handout_details.breadcrumb_title')}
              path={`/${dataArea}/handouts`}
            />
          }
        >
          <Tabs defaultIndex={0} onSelect={handleSelectTab}>
            <Tab title={t('handout_details.tabs.tab_details_title')}>
              <DetailsTab />
            </Tab>

            <Tab title={t('handout_details.tabs.tab_engagement_title')}>
              <EngagementTab />
            </Tab>
          </Tabs>
        </S.HandoutDetailsWrapper>
      )}

      <Toast />
    </>
  );
};

export default withAppContext(HandoutDetails);
