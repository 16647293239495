import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Label from 'components/Form/Label';
import AgendaIcon from 'components/AgendaIcon';
import FormValidationErrors from 'components/Form/ValidationErrors';
import ModalContainer from 'components/Modal/ModalContainer';
import ModalButtons from 'components/Modal/ModalButtons';
import { BOTTOM_CENTER } from 'components/Tooltip';
import withFormContext, { formPropTypes } from 'core/hoc/withFormContext';
import UserServices from 'core/services/Users';
import * as S from './styles';

const ProfileChecker = ({
  title,
  emailLabel,
  usernameLabel,
  profileType,
  canEditUser,
  formContext: {
    action,
    form,
    changeAttribute,
    updateAttribute,
    hasErrorOnAttribute,
  },
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [, setError] = useState(null);
  const [checkedField, setCheckedField] = useState('');
  const [existentEmail, setExistentEmail] = useState(null);
  const [existentUserName, setExistentUserName] = useState(null);
  const [disableEmail, setDisableEmail] = useState(null);
  const [disableUserName, setDisableUserName] = useState(null);
  const [settedEmail, setSettedEmail] = useState(null);
  const [settedUserName, setSettedUserName] = useState(null);
  const [serviceResponse, setServiceResponse] = useState({});

  const dataArea = useSelector((state) => state.root.dataArea);

  const emailInputRef = useRef(null);
  const userNameInputRef = useRef(null);

  const hasErrorOnUserNameField = hasErrorOnAttribute('username');
  const hasErrorOnEmailField = hasErrorOnAttribute('email');

  const userServices = new UserServices(dataArea);

  const { email, username } = form;

  useEffect(() => {
    if (action === 'edit') {
      setSettedEmail(form.email);
      setSettedUserName(form.username);
    }

    if (action === 'edit' && !canEditUser) {
      setDisableEmail(true);
      setDisableUserName(true);
    }
  }, [form, action, canEditUser]);

  const isEmailCheckable = () => {
    if (action === 'edit') {
      return email !== settedEmail;
    } else {
      return true;
    }
  };

  const isUserNameCheckable = () => {
    if (action === 'edit') {
      return username !== settedUserName;
    } else {
      return true;
    }
  };

  const closeModal = () => setIsModalOpen(false);

  const checkEmailExists = async () => {
    if (email && !isModalOpen && isEmailCheckable()) {
      setCheckedField('email');
      try {
        const response = await userServices.verifyEmail(
          form.email,
          profileType
        );
        setServiceResponse(response.data.data.attributes);
        setExistentEmail(form.email);
        setIsModalOpen(true);
      } catch (error) {
        setExistentEmail(null);
        setDisableUserName(false);
        setError(error);
      }
    }
  };

  const checkUsernameExists = async () => {
    if (username && !isModalOpen && isUserNameCheckable()) {
      setCheckedField('username');
      try {
        const response = await userServices.verifyUsername(
          form.username,
          profileType
        );
        setServiceResponse(response.data.data.attributes);
        setExistentUserName(form.username);
        setIsModalOpen(true);
      } catch (error) {
        setExistentUserName(null);
        setDisableEmail(false);
        setError(error);
      }
    }
  };

  const defineOther = () => {
    closeModal();

    setTimeout(() => {
      if (checkedField === 'email') {
        emailInputRef.current.focus();
      } else if (checkedField === 'username') {
        userNameInputRef.current.focus();
      }
    }, 50);
  };

  const disableUsernameByExistentEmail = () => {
    if (existentEmail) {
      updateAttribute('username', serviceResponse.username);
      setDisableUserName(true);
      closeModal();
    } else {
      closeModal();
    }
  };

  const disableEmailByExistentUsername = () => {
    if (existentUserName) {
      updateAttribute('email', serviceResponse.email);
      setDisableEmail(true);
      closeModal();
    } else {
      closeModal();
    }
  };

  const keepRegister = () => {
    if (checkedField === 'email') {
      disableUsernameByExistentEmail();
    } else if (checkedField === 'username') {
      disableEmailByExistentUsername();
    }
  };

  const modalButtons = () => [
    {
      text: 'Definir outro',
      variation: 'secondary',
      onClick: defineOther,
      path: '#',
    },
    {
      text: 'Continuar',
      variation: 'danger',
      onClick: keepRegister,
      path: '#',
    },
  ];

  const modalTitle = {
    email: 'E-mail já cadastrado',
    username: 'Nome de usuário já cadastrado',
  };

  const modalContent = {
    email: (
      <span>
        O e-mail <b>{form.email}</b> já existe e está vinculado à outra conta na
        Agenda Edu.
        <br /> Caso deseje continuar com o e-mail informado, será enviado uma
        solicitação de vínculo do cadastrado com a escola. Após a confirmação, o
        usuário terá seu cadastro efetivado.
      </span>
    ),
    username: (
      <span>
        O nome de usuário <b>{form.username}</b> já existe e está vinculado à
        outra conta na Agenda Edu. <br /> Caso deseje continuar com o nome de
        usuário informado, será enviado uma solicitação de vínculo do cadastrado
        com a escola. Após a confirmação, o usuário terá seu cadastro efetivado.
      </span>
    ),
  };

  const userNameTooltipContent = () => (
    <p>
      - Deve ter no mínimo 5 e no máximo 255 caracteres;
      <br />
      - Pode conter apenas letras (A-Z) e números (0-9), com excessão de ponto(
      . ), hífen ( - ) e underline ( _ );
      <br />- Não devem conter espaços ou caracteres especiais.
    </p>
  );

  const onChange = (attributeName) => {
    return changeAttribute(attributeName);
  };

  const userNameLabel = () => (
    <S.CustomLabel>
      <span>{usernameLabel}</span>
      <S.UserNameTooltip
        content={userNameTooltipContent()}
        position={BOTTOM_CENTER}
        tooltipStyle={{
          maxWidth: '240px',
        }}
      >
        <AgendaIcon name="help" size="small" />
      </S.UserNameTooltip>
    </S.CustomLabel>
  );

  return (
    <div>
      {title && title}

      <div className="row">
        <S.DoubleInputsWrapper>
          <S.Field>
            <Label>{emailLabel}</Label>
            <div className="TextField">
              <input
                className="form-control"
                type="text"
                placeholder=""
                attributeName="email"
                value={form['email']}
                onChange={onChange('email')}
                onBlur={action === 'new' && checkEmailExists}
                ref={emailInputRef}
                disabled={disableEmail}
              />
              {hasErrorOnEmailField && (
                <FormValidationErrors attributeName={'email'} />
              )}
            </div>
          </S.Field>

          <S.Field>
            <Label>{userNameLabel()}</Label>
            <div className="TextField">
              <input
                className="form-control"
                type="text"
                placeholder=""
                attributeName="username"
                value={form['username']}
                onChange={onChange('username')}
                onBlur={action === 'new' && checkUsernameExists}
                ref={userNameInputRef}
                disabled={disableUserName}
              />
              {hasErrorOnUserNameField && (
                <FormValidationErrors attributeName={'username'} multiple />
              )}
            </div>
          </S.Field>
        </S.DoubleInputsWrapper>

        <ModalContainer
          isOpen={isModalOpen}
          toggleModal={closeModal}
          maxWidth="400px"
          title={<S.ModalTitle>{modalTitle[checkedField]}</S.ModalTitle>}
        >
          <p className="sub-title-modal">{modalContent[checkedField]}</p>
          <div className="button-container-modal">
            <ModalButtons buttons={modalButtons()} />
          </div>
        </ModalContainer>
      </div>
    </div>
  );
};

ProfileChecker.propTypes = {
  title: PropTypes.string,
  emailLabel: PropTypes.string,
  usernameLabel: PropTypes.string,
  profileType: PropTypes.string.isRequired,
  canEditUser: PropTypes.boolean,
  ...formPropTypes,
};

ProfileChecker.defaultProps = {
  title: '',
  emailLabel: 'E-mail',
  usernameLabel: 'Nome de usuário',
};

export default withFormContext(ProfileChecker);
