import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@agendaedu/ae-web-components';
import { Modal } from 'components/Handouts/Modal';

import { ConfirmEditModalProps } from './types';
import withFormContext from 'core/hoc/withFormContext';

const ConfirmEditModal = ({
  isOpen,
  toggleModal,
  onConfirm,
  formContext,
}: ConfirmEditModalProps) => {
  const { t } = useTranslation(['handouts']);

  const handleSubmit = () => {
    onConfirm(formContext.form);
  };

  return (
    <Modal.Root
      isOpen={isOpen}
      title={t('categories.edit_modal.title')}
      handleToggleModal={toggleModal}
    >
      <Modal.Content>{t('categories.edit_modal.content')}</Modal.Content>
      <Modal.Actions>
        <Button as="button" variant="secondary" onClick={toggleModal}>
          {t('actions.cancel_button_text')}
        </Button>
        <Button as="button" variant="primary" onClick={handleSubmit}>
          {t('categories.edit_modal.save_button')}
        </Button>
      </Modal.Actions>
    </Modal.Root>
  );
};

export default withFormContext(ConfirmEditModal);
