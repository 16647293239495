const enrollmentPlanContractStatus = {
  pending: {
    text: 'Pendente',
    variation: 'warning',
  },
  finished: {
    text: 'Assinado',
    variation: 'success',
  },
  signed: {
    text: 'Assinado',
    variation: 'success',
  },
  canceled: {
    text: 'Cancelado',
    variation: 'dimming',
  },
};

export default enrollmentPlanContractStatus;
