export const FETCH_REQUEST = 'sophia_filters::FETCH_REQUEST';
export const FETCH_SUCCESS = 'sophia_filters::FETCH_SUCCESS';
export const FETCH_ERROR = 'sophia_filters::FETCH_ERROR';
export const UPDATE_SENT = 'sophia_filters::UPDATE_SENT';
export const UPDATE_REQUEST = 'sophia_filters::UPDATE_REQUEST';
export const UPDATE_SUCCESS = 'sophia_filters::UPDATE_SUCCESS';
export const UPDATE_ERROR = 'sophia_filters::UPDATE_ERROR';
export const SET_HEADQUARTERS = 'sophia_filters::SET_HEADQUARTERS';
export const SET_COURSES = 'sophia_filters::SET_COURSES';
export const SET_SCHOOL_TERMS = 'sophia_filters::SET_SCHOOL_TERMS';
