import { FetchTicketsParams } from 'store/messages/omniChannel/types';

export const queryUrlFetchTickets = ({
  status,
  filters,
  requesterName,
  requesterType,
}: FetchTicketsParams) => {
  const filterStatus = status ? `&[filter]status=${status}` : '';

  const filterSource = filters?.source
    ? `&[filter]source=${filters?.source}`
    : '';

  const filterHeadquarter = filters?.headquarterId
    ? `&headquarter_ids=${filters?.headquarterId}`
    : '';
  const filterEducationalStage = filters?.educationalStageId
    ? `&educational_stage_ids=${filters?.educationalStageId}`
    : '';
  const filterClassRoom = filters?.classroomId
    ? `&classroom_ids=${filters?.classroomId}`
    : '';

  const allTicketTabs = ['waiting', 'in_attendance', 'done'];

  const requestSearchStatus = allTicketTabs
    .map((currentTab) => `&[filter]status[]=${currentTab}`)
    .join('');

  const filterRequesterName =
    requesterName && requesterType
      ? `&[filter][requester]name=${requesterName}&[filter][requester]type=${requesterType}${requestSearchStatus}`
      : '';

  const queryUrlTickets = `${filterStatus}${filterSource}${filterHeadquarter}${filterEducationalStage}${filterClassRoom}${filterRequesterName}`;

  return queryUrlTickets;
};
