import React, { useState, useMemo } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import Loader from 'components/Loader';
import ModalTitle from 'components/Modal/ModalTitle';
import ModalButtons from 'components/Modal/ModalButtons';
import ModalContainer from 'components/Modal/ModalContainer';

import ReportCard from 'pages/reports/ReportList/ReportCard';
import ReportDetailsModal from 'pages/reports/ReportList/ReportDetailsModal';

import * as S from './styles';

const ReportList = ({ reportList, fetchReports, deleteReport }) => {
  const {
    reportsPagination: { currentPage, itemsCountPerPage, totalItemsCount },
    isLoading,
  } = useSelector((state) => state.reports);

  const [showDeleteModal, setShowModal] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [reportId, setReportId] = useState(null);

  const closeDeleteModal = () => {
    setShowModal(false);
  };

  const toggleDeleteModal = () => {
    setShowModal(!showDeleteModal);
  };

  const toggleDetailsModal = () => {
    setShowDetails(!showDetails);
  };

  const onDeleteReport = (id) => {
    deleteReport(id);
    closeDeleteModal();
  };

  const onOpenDeleteModal = (id) => {
    setReportId(id);
    toggleDeleteModal();
  };

  const onOpenDetailsModal = (id) => {
    setReportId(id);
    toggleDetailsModal();
  };

  const modalButtons = () => [
    {
      text: 'Cancelar',
      variation: 'secondary',
      onClick: closeDeleteModal,
      path: '#',
    },
    {
      text: 'Excluir',
      variation: 'danger',
      onClick: () => onDeleteReport(reportId),
      path: '#',
    },
  ];

  const renderDeleteModal = () => {
    return (
      <ModalContainer
        isOpen={showDeleteModal}
        toggleModal={closeDeleteModal}
        maxWidth="490px"
      >
        <div className="title-modal">
          <ModalTitle>Excluir este relatório</ModalTitle>
        </div>

        <p className="sub-title-modal">
          O relatório será removido dos perfis com permissão para visualizá-lo.
          Tem certeza que deseja excluí-lo?
        </p>

        <div className="button-container-modal">
          <ModalButtons buttons={modalButtons()} />
        </div>
      </ModalContainer>
    );
  };

  const renderDetailsModal = () => {
    return (
      <ReportDetailsModal
        id={reportId}
        isOpen={showDetails}
        toggleModal={toggleDetailsModal}
      />
    );
  };

  const renderReportList = () => {
    return reportList.map((report) => (
      <ReportCard
        aria-label="Relatório"
        key={report.id}
        report={report}
        onDeleteReport={onOpenDeleteModal}
        onReportDetails={onOpenDetailsModal}
      />
    ));
  };

  const hasMoreReports = useMemo(() => {
    const numberOfItemsOnTheList = currentPage * itemsCountPerPage;

    return numberOfItemsOnTheList < totalItemsCount;
  }, [currentPage, itemsCountPerPage, totalItemsCount]);

  const fetchMoreReports = () => {
    fetchReports({ page: currentPage + 1 });
  };

  return (
    <S.TotalReports>
      {totalItemsCount && <span>{`${totalItemsCount} relatórios`}</span>}
      {renderDeleteModal()}
      {renderDetailsModal()}
      <InfiniteScroll
        pageStart={1}
        hasMore={!isLoading && hasMoreReports}
        loadMore={fetchMoreReports}
        loader={<Loader key="report-list-loader" aria-label="Carregando..." />}
        initialLoad={true}
        threshold={200}
      >
        {renderReportList()}
      </InfiniteScroll>
    </S.TotalReports>
  );
};

ReportList.propTypes = {
  reportList: PropTypes.array.isRequired,
  fetchReports: PropTypes.func.isRequired,
  deleteReport: PropTypes.func.isRequired,
};

export default ReportList;
