import { UploadStatus } from 'core/contexts/UploadFiles/types';
import styled, { css } from 'styled-components';

export const CoverImage = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: ${({ theme: { border } }) => border.radius.sm};
  transition: 0.5s ease all;
`;

export const CoverWrapper = styled.div`
  overflow: hidden;
  position: relative;
  border-radius: ${({ theme: { border } }) => border.radius.sm};
  margin-right: ${({ theme: { space } }) => space.sm};
  width: 32px;
  height: 32px;
`;

export const LoadSpinner = styled.div<{ uploadStatus?: UploadStatus }>`
  ${({ theme: { space, colors }, uploadStatus }) => css`
    width: ${space.sm};
    height: ${space.sm};
    border: 2px solid
      ${uploadStatus === 'deleting'
        ? colors.context.alert.default
        : colors.brand.primary.default};
    border-top-color: ${uploadStatus === 'deleting'
      ? colors.context.alert.op20
      : colors.brand.primary.op20};
  `}

  display: inline-block;

  border-radius: 50%;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;

  @keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
`;
