import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { Box, FullScreenModal, Stepper } from '@agendaedu/ae-web-components';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router';

import useWindowDimensions from 'core/hooks/useWindowDimensions';
import { isMobile } from 'core/utils/viewport';
import InfoStep from './components/InfoStep';
import RecipientsStep from './components/RecipientsStep';
import DiaryActions from 'store/dailySummaries/actions';
import Toast from 'components/Toast';
import { DiarySectionContext } from 'core/contexts/DiarySections';
import { useDispatch, useSelector } from 'react-redux';
import { DiaryState } from 'store/dailySummaries/types';
import { SectionsFormSkeleton } from './skeleton';
import { Props } from './types';

const SectionForm = ({ history: { replace }, match }: Props) => {
  const { id: diarySectionId } = match?.params;

  const { fetchRecipientsClassrooms, fetchDiarySectionFormRequest } =
    DiaryActions;
  const dispatch = useDispatch();

  const { t } = useTranslation(['diary_sections', 'common']);
  const { screenWidth } = useWindowDimensions();
  const { form, currentStep, setCurrentStep } = useContext(DiarySectionContext);
  const [isMobileScreen, setIsMobile] = useState(isMobile(screenWidth));
  const [isNextStepDisabled, setIsNextStepDisabled] = useState(false);

  const {
    diarySections: {
      diarySectionForm,
      diarySectionData,
      isLoadingCurrentSection,
    },
  } = useSelector((state: DiaryState) => state.dailySummaries);

  const tBase = useCallback((key: string) => t(`sections.form.${key}`), [t]);

  const redirectToBack = () => replace('/schools/diary_sections');

  const isTabInfoActive =
    diarySectionData?.attributes?.custom || !diarySectionId;

  useEffect(() => {
    setIsNextStepDisabled(!form.isValid);
  }, [form.isValid]);

  useEffect(() => {
    if (isMobileScreen !== isMobile(screenWidth)) {
      setIsMobile(isMobile(screenWidth));
    }
  }, [isMobileScreen, screenWidth]);

  useEffect(() => {
    dispatch(fetchRecipientsClassrooms());
  }, [dispatch, fetchRecipientsClassrooms]);

  useEffect(() => {
    if (diarySectionId) {
      dispatch(fetchDiarySectionFormRequest({ diarySectionId }));
    }
  }, [diarySectionId, dispatch, fetchDiarySectionFormRequest]);

  useEffect(() => {
    if (diarySectionId && diarySectionForm) {
      form.setValues(diarySectionForm);
    }
  }, [diarySectionForm, diarySectionId]);

  const stepInfos = useMemo(
    () => [
      {
        step: 1,
        title: tBase('info'),
        isActive: currentStep === 1,
        isDisabled: !isTabInfoActive,
      },
      {
        title: tBase('recipients'),
        isActive: currentStep === 2,
        isDisabled: isNextStepDisabled,
        step: 2,
      },
    ],
    [currentStep, isTabInfoActive, isNextStepDisabled, tBase]
  );

  const getHeaderContent = useMemo(
    () => (
      <Box
        display="flex"
        justifyContent="center"
        position="absolute"
        left={0}
        right={0}
        width="min-content"
        mx="auto"
      >
        {stepInfos.map((item, index) => (
          <Stepper
            key={index}
            ml={index ? 'sm' : 0}
            isMobile={isMobileScreen}
            onClick={() => setCurrentStep(item.step)}
            {...item}
          />
        ))}
      </Box>
    ),
    [isMobileScreen, setCurrentStep, stepInfos]
  );

  const getStepContent = {
    1: <InfoStep />,
    2: <RecipientsStep />,
  }[isTabInfoActive ? currentStep : 2];

  return (
    <>
      <FullScreenModal
        isOpen
        title={tBase(diarySectionId ? 'title_edit' : 'title_new')}
        onClose={redirectToBack}
        headerContent={getHeaderContent}
      >
        {isLoadingCurrentSection ? (
          <SectionsFormSkeleton />
        ) : (
          <Box data-testid="content" display="flex" flex={1} overflow="hidden">
            {getStepContent}
          </Box>
        )}
      </FullScreenModal>

      <Toast />
    </>
  );
};

export default withRouter(SectionForm);
