import React from 'react';
import PropTypes from 'prop-types';

import withAppContext, { appPropTypes } from 'core/hoc/withAppContext';

const currencyMapping = {
  'pt-br': 'BRL',
};

function Currency({ value, appContext }) {
  const { i18nLocale } = appContext;

  const formattedValue = value.toLocaleString(i18nLocale, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    style: 'currency',
    currency: currencyMapping[i18nLocale],
  });

  return <span className="Currency">{formattedValue}</span>;
}

Currency.propTypes = {
  value: PropTypes.number.isRequired,
  ...appPropTypes,
};

export default withAppContext(Currency);
