import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';

import FormContainer from 'components/Form/FormContainer';
import WizardContainer from 'components/WizardForm/WizardContainer';
import SchoolTermsTab from './school_terms';

const FiltersForm = (props) => {
  const { handleSubmit, handlers, filters, initialValues, canChange } = props;

  const wizardSteps = [SchoolTermsTab];

  return (
    <FormContainer
      initialAttributes={initialValues}
      form={filters}
      action="update"
      formMeta={handlers}
    >
      <WizardContainer
        steps={wizardSteps}
        onSubmit={handleSubmit}
        isSubmitting={canChange}
      />
    </FormContainer>
  );
};

FiltersForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handlers: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
  initialValues: PropTypes.func.isRequired,
  sent: PropTypes.bool.isRequired,
  canChange: PropTypes.bool.isRequired,
};

const SponteFiltersForm = reduxForm({
  form: 'SponteFiltersForm',
  enableReinitialize: true,
})(FiltersForm);

export default SponteFiltersForm;
