import React, { useState } from 'react';
import ContentLoader from 'react-content-loader';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Field from 'components/Form/Field';
import Button from 'components/Button';
import FormContainer from 'components/Form/FormContainer';
import HomeActions from 'store/home/actions';
import Loader from 'components/Loader';
import { schoolPlans } from './constants';

import * as S from './styles';

export const HomeSchoolsSelectSkeleton = () => {
  return (
    <ContentLoader viewBox="0 0 400 13">
      <rect x="0.5" y="0.5" rx="0.5" ry="0.5" width="155" height="24" />
    </ContentLoader>
  );
};

const HomeSchoolsSelect = ({ selectedSchool, isSubmitting, schools }) => {
  const [school, setSchool] = useState(null);

  const disaptch = useDispatch();

  const options = schools.map((school) => ({
    label: `${school[0]} - ${school[1]} ${
      school[2] ? schoolPlans[school[2]] : ''
    }`,
    value: school[0],
  }));

  const defaultValue = {
    label: `${selectedSchool !== null && selectedSchool.school_id} - ${
      selectedSchool !== null && selectedSchool.school_name
    }`,
    value: selectedSchool !== null && selectedSchool.school_id,
  };

  const onSelectSchool = ({ value }) => {
    setSchool(value);
  };

  const onChangeSchool = () => {
    disaptch(HomeActions.changeSchool(school || defaultValue));
  };

  return (
    <S.SelectContainer className="HomeSchoolsSelect">
      <FormContainer>
        <S.FieldWrapper>
          <Field
            attributeName="schools"
            type="selectWithIcon"
            options={options}
            defaultValue={defaultValue}
            value={school}
            onChange={onSelectSchool}
            withArrowSelect
            isSearchable
          />
          <Button disabled={!school || isSubmitting} onClick={onChangeSchool}>
            <Loader
              isLoading={isSubmitting}
              variation="content"
              color="white"
              size={20}
            >
              Acessar
            </Loader>
          </Button>
        </S.FieldWrapper>
      </FormContainer>
    </S.SelectContainer>
  );
};

HomeSchoolsSelect.propTypes = {
  selectedSchool: PropTypes.number,
  schools: PropTypes.array,
  isSubmitting: PropTypes.bool,
};

HomeSchoolsSelect.defaultProps = {
  isSubmitting: false,
};

export default HomeSchoolsSelect;
