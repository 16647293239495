import React from 'react';
import PropTypes from 'prop-types';

import ModalContainer from 'components/Modal/ModalContainer';
import ModalTitle from 'components/Modal/ModalTitle';
import ButtonRow from 'components/ButtonRow';

import * as S from './styles';

const NotificationModal = ({ isOpen, toggleModal, confirmAction }) => {
  const NotificationModalButtons = [
    {
      text: 'Não notificar',
      variation: 'secondary',
      onClick: () => confirmAction(false),
    },
    {
      text: 'Notificar',
      onClick: () => confirmAction(true),
    },
  ];

  return (
    <ModalContainer isOpen={isOpen} toggleModal={toggleModal} maxWidth="475px">
      <ModalTitle>Notificar responsáveis</ModalTitle>

      <S.ModalText>
        As informações serão salvas e enviadas para os responsáveis. Você tem a
        opção de notificá-los no aplicativo ou não.
      </S.ModalText>

      <ButtonRow buttons={NotificationModalButtons} />
    </ModalContainer>
  );
};

NotificationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  confirmAction: PropTypes.func.isRequired,
};

export default NotificationModal;
