/**
 * Convert Array with Object where the id is the key
 * @param included
 * @returns {}
 *
 * @example
 * [{id: 1, name: "abc"}, {id:2,name:"def"}]
 * To
 * {1: {id: 1, name: "abc"}, 2:{id:2,name:"def"}}
 *
 */

export const arrayToObject = (included) => {
  return included.reduce((accumulator, value) => {
    return { ...accumulator, [value.id]: value };
  }, {});
};
