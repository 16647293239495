import styled, { css } from 'styled-components';
import Tooltip from 'components/Tooltip';
import Button from 'components/Button';

export const EdupayDiscountWrapper = styled.div``;

export const ToggleDiscountWrapper = styled.div`
  ${({ theme, withoutTitle }) => css`
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: ${withoutTitle ? '24px' : 0};

    .ToggleSwitch {
      height: 26px;
    }
  `}
`;

export const ToggleDiscountTitle = styled.span`
  font-size: 14px;
`;

export const DiscountToolip = styled(Tooltip)`
  .tooltip-div {
    position: relative;
    width: 16px;
    height: 16px;
  }
`;

export const AddNewDisountButton = styled(Button)`
  ${({ theme }) => css`
    font-family: 'Quicksand', sans-serif;
    font-size: 16px;
    color: ${theme.primaryColor};
    margin-top: xs;
    transition: color 0.2s ease-in-out;

    &:hover {
      color: ${theme.primaryColor}95;
    }
  `}
`;

export const CheckboxWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    margin-top: 16px;

    .Checkbox {
      margin-right: 8px;
    }

    .checkbox-label {
      color: ${theme.colors.neutral.black};
    }

    .tooltip-div {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 4px;

      .AgendaIcon {
        color: ${theme.colors.neutral.gray1};
      }
    }
  `}
`;
