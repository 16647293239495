import styled, { css } from 'styled-components';

export const WalletInformationsWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;

  > fieldset {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .Field {
    display: flex;
    width: 100%;
    margin: 0;
    padding: 0;
    margin-bottom: 0 !important;

    input {
      height: 40px;
      border-radius: 6px;
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }
  }
`;

export const IssWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 18px;
`;

export const RequireItemsMessage = styled.p`
  ${({ theme }) => css`
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    color: ${theme.gray2};
  `}
`;
