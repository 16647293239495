import { NegotiationBill, NormalizeParams } from '../NegotiationForm/types';

const recurrentItemValue = ({
  discount_kind,
  discount_value,
  price_value,
  price_kind,
  minValueSelected,
}: NegotiationBill & { minValueSelected: number }): number => {
  if (price_kind === 'percent') {
    return ((minValueSelected / 100) * price_value) / 100;
  }

  if (discount_kind === 'percent') {
    return price_value - price_value * (discount_value / 100);
  }

  return price_value - discount_value;
};

const getBillsValue = (
  negotiationBills: NegotiationBill[],
  minValueSelected: number
) => {
  const formattedBills = [];

  negotiationBills.forEach((bill) => {
    const billValue = recurrentItemValue({ ...bill, minValueSelected }) * 100;
    formattedBills.push(billValue);
  });

  return formattedBills;
};

const normalizeParams = ({
  form,
  initialData,
  currentPlanId,
}: NormalizeParams) => {
  const billsValue = getBillsValue(
    form.negotiationBills,
    initialData.attributes[form.selected_min_value_to_negotiate]
  );

  const agreementTotal = form.negotiationSubtotal * 100;

  const obj = {
    allowed_payment_method: form.allowed_payment_method,
    first_bill_due_date: form.first_bill_due_date,
    due_day: form.due_day,
    bill_release_day: form.bill_release_day,
    bills_price_cents: billsValue,
    description: form.observation,
    original_bills: initialData.attributes.original_recurrent_bills.data.map(
      (originalRecurrentBill) => originalRecurrentBill.attributes.id
    ),
    intermediary_bills:
      initialData.attributes.derivative_recurrent_bills?.data?.map(
        (derivativeRecurrentBill) => derivativeRecurrentBill.attributes.id
      ),
    agreement_total: agreementTotal,
    original_total: initialData.attributes.gross_amount,
    total_with_penalities:
      initialData.attributes.subtotal_with_fine_and_interest,
    total_without_penalities_and_discounts:
      initialData.attributes.original_amount,
    total_with_partial_discount:
      initialData.attributes.subtotal_with_partial_discount,

    ...(form.toggle_penality && {
      edupay_penality_attributes: form.edupay_penality,
    }),

    recipient_wallet_id: form.recipient_wallet_id,
    recurrent_plan_id: currentPlanId,
  };

  return obj;
};

export default normalizeParams;
