import React from 'react';
import { Box, RecipientTag, Text } from '@agendaedu/ae-web-components';
import { useTranslation } from 'react-i18next';

import * as S from './styles';

import { Props } from './types';

export const GroupMemberCard: React.FC<Props> = ({ member }) => {
  const { t } = useTranslation(['messages']);
  const {
    avatar_color: avatarColor,
    avatar_url: avatarUrl,
    name_initials: nameInitials,
    role,
    name,
    classroom_names: classroomNames,
  } = member.attributes;
  return (
    <S.CardWrapper data-testid={`card-${member.id}`}>
      <Box
        width="100%"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
      >
        <Box display="flex" flexDirection="row" alignItems="center">
          <S.GroupAvatar
            data-testid="group-avatar"
            avatarColor={avatarColor}
            avatarUrl={avatarUrl}
            nameInitials={nameInitials}
          />

          <Box display="flex" flexDirection="column" gap="xs2">
            <Text mb={0} variant="subtitle-medium-12" color="neutral.gray2">
              {role}
            </Text>
            <Text mb={0} variant="subtitle-medium-16" color="neutral.gray1">
              {name}
            </Text>
          </Box>
        </Box>

        <Box
          display="flex"
          flexDirection="row"
          justifyContent="end"
          alignItems="center"
        >
          <Box position="relative">
            <RecipientTag
              variant="neutral"
              recipientType={t('groups.details_modal.classroom_type')}
              recipients={classroomNames?.map((classroom) => ({
                name: classroom,
              }))}
              modalTitle={t('groups.details_modal.classroom_type')}
              size="small"
              enableEllipsis
            />
          </Box>
        </Box>
      </Box>
    </S.CardWrapper>
  );
};
