import React from 'react';
import AgendaIcon from 'components/AgendaIcon';
import { TOP_CENTER } from 'components/Tooltip';
import * as S from './styles';

interface LabelWithTooltipProps {
  label: string;
  tooltipText: string;
  position?: string;
}

const LabelWithTooltip = ({
  label,
  tooltipText,
  position = TOP_CENTER,
}: LabelWithTooltipProps): JSX.Element => (
  <S.CustomLabel>
    <span>{label}</span>
    <S.CustomTooltip content={tooltipText} position={position}>
      <AgendaIcon name="help" size="small" />
    </S.CustomTooltip>
  </S.CustomLabel>
);

export default LabelWithTooltip;
