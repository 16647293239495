import styled, { css } from 'styled-components';

export const TogglesBatchsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  .ToggleSwitch {
    height: 26px;
  }
`;

export const ToggleTitle = styled.span`
  ${({ theme }) => css`
    font-family: 'Quicksand', sans-serif;
    font-size: 21px;
    font-weight: bold;
    color: ${theme.black};
  `}
`;
