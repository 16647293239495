export const reverseArray = (array) => {
  const invertedArray = array
    .slice(0)
    .reverse()
    .map((element) => {
      return element;
    });

  return invertedArray;
};
