import React from 'react';

import ModalTitle from 'components/Modal/ModalTitle';
import ButtonRow from 'components/ButtonRow';
import Button from 'components/Button';
import PropTypes from 'prop-types';

import { EditSchoolProductModalWrapper } from './styles';

const EditSchoolProductModal = ({
  isOpen,
  toggleModal,
  title,
  description,
  handleCancel,
  handleNotNotify,
  handleNotify,
}) => {
  return (
    <div
      className="EditSchoolProductModal"
      data-testid="edit-school-product-modal"
    >
      <EditSchoolProductModalWrapper
        isOpen={isOpen}
        toggleModal={toggleModal}
        maxWidth="490px"
      >
        <ModalTitle>{title}</ModalTitle>
        <p className="subTitle">{description}</p>
        <ButtonRow>
          <Button
            data-testid="cancel-button"
            variation="secondary"
            onClick={handleCancel}
          >
            Cancelar
          </Button>
          <Button variation="secondary" onClick={handleNotNotify}>
            Não notificar
          </Button>
          <Button onClick={handleNotify}>Notificar</Button>
        </ButtonRow>
      </EditSchoolProductModalWrapper>
    </div>
  );
};

EditSchoolProductModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleNotNotify: PropTypes.func.isRequired,
  handleNotify: PropTypes.func.isRequired,
};

export default EditSchoolProductModal;
