import React from 'react';

import { Box, DefaultThemeProps, theme } from '@agendaedu/ae-web-components';

import Skeleton from 'components/Skeleton';

import * as S from './styles';

export const HandoutTemplatesLoading: React.FC = () => {
  const defaultTheme: DefaultThemeProps = theme;

  const {
    colors: { neutral },
    border: { radius },
  } = defaultTheme;

  return (
    <Box mt={24}>
      <S.TemplateCardListWrapper>
        <Skeleton
          width={164}
          height={171}
          primaryColor={neutral.gray4}
          borderRadius={radius.md}
        />

        <Skeleton
          width={164}
          height={171}
          primaryColor={neutral.gray4}
          borderRadius={radius.md}
        />

        <Skeleton
          width={164}
          height={171}
          primaryColor={neutral.gray4}
          borderRadius={radius.md}
        />

        <Skeleton
          width={164}
          height={171}
          primaryColor={neutral.gray4}
          borderRadius={radius.md}
        />

        <Skeleton
          width={164}
          height={171}
          primaryColor={neutral.gray4}
          borderRadius={radius.md}
        />

        <Skeleton
          width={164}
          height={171}
          primaryColor={neutral.gray4}
          borderRadius={radius.md}
        />

        <Skeleton
          width={164}
          height={171}
          primaryColor={neutral.gray4}
          borderRadius={radius.md}
        />

        <Skeleton
          width={164}
          height={171}
          primaryColor={neutral.gray4}
          borderRadius={radius.md}
        />

        <Skeleton
          width={164}
          height={171}
          primaryColor={neutral.gray4}
          borderRadius={radius.md}
        />

        <Skeleton
          width={164}
          height={171}
          primaryColor={neutral.gray4}
          borderRadius={radius.md}
        />
      </S.TemplateCardListWrapper>
    </Box>
  );
};
