import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import autobind from 'autobind-decorator';

import './style.scss';

class Dropdown extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    dropup: PropTypes.bool,
    offsetY: PropTypes.number,
    offsetX: PropTypes.number,
    onToggle: PropTypes.func,
    trigger: PropTypes.node.isRequired,
    forceClose: PropTypes.bool,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    dropup: false,
    forceClose: false,
    offsetY: 30,
    offsetX: 0,
    onToggle: () => {},
  };

  state = {
    isMenuOpen: false,
  };

  menuElement = null;

  @autobind
  toggleMenu() {
    this.setState((prevState) => {
      if (!this.props.disabled) {
        const isMenuOpen = !prevState.isMenuOpen;

        this.props.onToggle(isMenuOpen);

        return { isMenuOpen };
      }
    }, this.handleDocumentEventListeners);
  }

  @autobind
  handleDocumentEventListeners() {
    if (this.state.isMenuOpen) {
      document.addEventListener('click', this.closeMenu);
    } else {
      document.removeEventListener('click', this.closeMenu);
    }
  }

  processCloseMenu() {
    const { onToggle } = this.props;

    this.setState({ isMenuOpen: false });
    onToggle(false);
    this.menuElement = null;
    this.handleDocumentEventListeners();
  }

  @autobind
  closeMenu(event) {
    if (event.target.contains(this.menuElement)) {
      this.processCloseMenu();
    }
  }

  @autobind
  setMenuElement(element) {
    this.menuElement = element;
  }

  getMenuStyle() {
    const { dropup, offsetY, offsetX } = this.props;

    if (dropup) {
      return { bottom: offsetY, left: offsetX };
    }
  }

  @autobind
  componentDidUpdate() {
    const { forceClose } = this.props;

    if (forceClose) {
      this.processCloseMenu();
    }
  }

  render() {
    const { children, dropup, trigger } = this.props;
    const { isMenuOpen } = this.state;

    return (
      <div className={`Dropdown ${dropup ? 'dropup' : ''}`}>
        <div className="opener" onClick={this.toggleMenu}>
          {trigger}
        </div>

        <ul
          className={`menu ${isMenuOpen ? 'open' : ''}`}
          style={this.getMenuStyle()}
          ref={this.setMenuElement}
        >
          {children}
        </ul>
      </div>
    );
  }
}

export default Dropdown;
