import React, { useCallback, useEffect, useState } from 'react';

import DiaryActions from 'store/dailySummaries/actions';
import Toast from 'components/Toast';
import { useDispatch } from 'react-redux';

import { Props } from './types';
import StudentsForm from './StudentsForm';
import SectionsForm from './SectionsForm';
import { DiaryStudentsFormProvider } from 'core/contexts/DiaryForm/StudentsForm';
import { DiarySectionsFormProvider } from 'core/contexts/DiaryForm/SectionsForm';
import { FullScreenModal } from '@agendaedu/ae-web-components';

const DiaryForm = ({ classroomId }: Props) => {
  const { fetchActivesDiarySections } = DiaryActions;
  const [fillType, setFillType] = useState<'students' | 'sections'>('students');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchActivesDiarySections({ classroomId }));
  }, [classroomId, dispatch, fetchActivesDiarySections]);

  const renderStudentsForm = useCallback(
    () => (
      <DiaryStudentsFormProvider classroomId={classroomId}>
        <StudentsForm setFillType={setFillType} />
      </DiaryStudentsFormProvider>
    ),
    [classroomId]
  );

  const renderSectionsForm = useCallback(
    () => (
      <DiarySectionsFormProvider classroomId={classroomId}>
        <SectionsForm setFillType={setFillType} />
      </DiarySectionsFormProvider>
    ),
    [classroomId]
  );

  const renderFillType = {
    students: renderStudentsForm,
    sections: renderSectionsForm,
  }[fillType];

  return (
    <FullScreenModal isOpen>
      {renderFillType()}
      <Toast />
    </FullScreenModal>
  );
};

export default DiaryForm;
