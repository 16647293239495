import React, { useEffect } from 'react';
import { CookiesProvider } from 'react-cookie';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import InternalErrorBoundary from 'boundaries/InternalErrorBoundary';
import QuickAccess from 'pages/QuickAccess';
import composeFunctions from 'core/utils/composeFunctions';
import reduxProvider from 'core/hoc/reduxProvider';
import withAppContext from 'core/hoc/withAppContext';

const QuickAccessRoutes = ({ googleClientId }) => {
  const changeWrapperBackgroundColor = () => {
    document.getElementById('main-content').style.backgroundColor = '#ffffff';
  };

  useEffect(() => {
    changeWrapperBackgroundColor();
  }, []);

  return (
    <InternalErrorBoundary>
      <div className="ClassRoomsMainsScreen">
        <CookiesProvider>
          <BrowserRouter>
            <Switch>
              <Route
                path="/*/quick_accesses/new"
                component={(props) => (
                  <QuickAccess googleClientId={googleClientId} {...props} />
                )}
              />
              <Route
                path="/*/quick_accesses/:id/edit"
                component={(props) => (
                  <QuickAccess googleClientId={googleClientId} {...props} />
                )}
              />
            </Switch>
          </BrowserRouter>
        </CookiesProvider>
      </div>
    </InternalErrorBoundary>
  );
};

export default composeFunctions(
  withAppContext,
  reduxProvider
)(QuickAccessRoutes);
