import React from 'react';
import './ModalSubTitle.scss';

/*
ModalSubTitle
List of props:
subTitle: string
Example:
<ModalSubTitle subTitle="Texto"/>
*/

const ModalSubTitle = ({ subTitle, description }) => (
  <>
    <p className="sub-title">{subTitle}</p>
    {description && <p className="sub-title-description">{description}</p>}
  </>
);
export default ModalSubTitle;
