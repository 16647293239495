import { Chat, Message } from 'store/messages/omniChannel/types';
import { normalizeFetchEditMessageProps } from './types';

export const normalizeFetchEditMessage = ({
  editedMessage,
  includedMessage,
  activeChat,
  chats,
  messages,
  approvalStatus,
}: normalizeFetchEditMessageProps) => {
  let newChats: Chat[];
  let newMessages: Message[] = [];

  const newMessage: Message = {
    ...editedMessage,
    relationships: {
      ...editedMessage.relationships,
      attachedMessage: {
        data: includedMessage,
      },
    },
  };

  const isLastMessage = messages[0].id === newMessage.id;

  if (isLastMessage || approvalStatus === 'manually_approved') {
    newChats = chats.map((chat: Chat) => {
      if (chat.id === activeChat.id) {
        return {
          ...chat,
          attributes: {
            ...chat.attributes,
            last_message_preview: newMessage.attributes.text,
            last_message_sent_at: newMessage.attributes.sentAt,
            read_status: 'answered',
          },
        };
      }

      return chat;
    });
  } else {
    newChats = chats;
  }

  if (approvalStatus === 'approved') {
    newMessages = messages.map((message: Message) => {
      if (message.id === newMessage.id) {
        return newMessage;
      }
      return message;
    });
  } else {
    const filteredList = messages.filter(
      (message) => message.id !== editedMessage.id
    );

    newMessages = [newMessage, ...filteredList];
  }

  return { messages: newMessages, chats: newChats };
};
