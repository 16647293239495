import createModuleReducer from '../storeConfig/createModuleReducer';
import actions from './actions';

const initialForm = () => ({
  title: '',
  description: '',
  date_start: '',
  date_end: '',
  time_start: '',
  time_end: '',
  sent_kind: 'classrooms',
  persona_type: 'both',
  classroom_ids: [],
  classroom_with_student_profile_ids: {},
  survey_questions_attributes: [
    {
      position: '1',
      question_0: '',
      question_type: 'unique',
      required: true,
      survey_options_attributes: [
        {
          position: '1',
          title: '',
          others: false,
          key: Math.random().toString(36).substring(7),
        },
      ],
      key: Math.random().toString(36).substring(7),
    },
  ],
});

export const INITIAL_STATE = {
  data: null,
  surveys: [],
  surveyList: [],
  surveysIncluded: [],
  paginationLinks: {},
  surveysMeta: null,
  error: null,
  sending: false,
  selectedSurvey: null,
  selectedSurveyIncluded: [],
  surveyQuestions: null,
  surveysPagination: {},
  answersPagination: {},
  selectedSituation: '',
  selectedMonth: new Date().getMonth() + 1,
  selectedYear: new Date().getFullYear(),
  title_cont: '',
  selectedHeadquarter: null,
  selectedEducationalStage: null,
  selectedClassroomFilter: null,
  headquarters: null,
  isLoading: true,
  isApprovingSurvey: false,
  isDeletingSurvey: false,
  educationalStages: [],
  classroomFilter: [],
  schoolTerms: [],
  current: initialForm(),
  formMeta: {
    classrooms: [],
    canEditClassrooms: true,
    canEditKind: true,
  },
};

export const addSurvey = (state) => ({
  ...state,
  sending: true,
});

export const addSurveySuccess = (state, action) => ({
  ...state,
  sending: false,
  selectedSurvey: action.data,
  surveys: [action.data, ...state.surveys],
});

const fetchSurveysRequest = (state, action) => ({
  ...state,
  current: initialForm(),
  isLoading: true,
  ...(!action.queryParams.nextLink && {
    surveyList: INITIAL_STATE.surveyList,
    surveysIncluded: INITIAL_STATE.surveysIncluded,
    paginationLinks: INITIAL_STATE.paginationLinks,
    surveysMeta: INITIAL_STATE.surveysMeta,
  }),
});

export const fetchSurveysSuccess = (state, action) => {
  return {
    ...state,
    data: action.data,
    surveyList: action.isPagination
      ? [...state.surveyList, ...action.data]
      : action.data,
    surveysIncluded: action.isPagination
      ? [...state.surveysIncluded, ...action.included]
      : action.included,
    ...(!action.isPagination && { title_cont: action.title_cont }),
    surveysMeta: action.meta,
    paginationLinks: action.paginationLinks,
    selectedSituation: action.selectedSituation,
    headquarters: action.meta.headquarters,
    selectedHeadquarter: action.selectedHeadquarter,
    selectedEducationalStage: action.selectedEducationalStage,
    selectedClassroomFilter: action.selectedClassroomFilter,
    schoolTerms: action.meta.school_terms,
    classroomFilter: action.selectedClassroomFilter
      ? state.classroomFilter
      : [],
    educationalStages: action.selectedEducationalStage
      ? state.educationalStages
      : [],
    isLoading: false,
  };
};

export const resetSurveyList = (state) => ({ ...state, surveyList: [] });

export const fetchSurveyDetailsRequest = (state) => ({
  ...state,
  current: INITIAL_STATE.current,
  selectedSurvey: null,
  selectedSurveyIncluded: [],
  surveyQuestions: null,
  answersPagination: {},
  isLoading: true,
});

export const fetchSurveyDetailsSuccess = (state, action) => ({
  ...state,
  current: INITIAL_STATE.current,
  selectedSurvey: action.data,
  selectedSurveyIncluded: action.included,
  isLoading: false,
});

const fetchSurveysFormRequest = (state) => ({ ...state, isLoading: true });

export const fetchSurveyFormSuccess = (state, action) => {
  const { classrooms } = action;

  classrooms.forEach((headquarter) =>
    headquarter.options.forEach((classroom) => {
      classroom.value = classroom.id;
      classroom.label = classroom.attributes.name;
    })
  );

  return {
    ...state,
    current: {
      ...action.data,
      ...action.meta,
      survey_questions_attributes: action.questions.map((question) => ({
        id: question.id,
        question_type: question.attributes.question_type,
        position: question.attributes.position,
        question: question.attributes.question,
        required: question.attributes.required,
        survey_options_attributes: question.attributes.survey_options.data.map(
          (options) => ({
            id: options.id,
            type: options.type,
            ...options.attributes,
          })
        ),
      })),
      classroom_ids: action.data.attributes.classrooms
        ? action.data.attributes.classrooms.data
        : [],
    },
    formMeta: {
      ...state.formMeta,
      classroom_with_student_profile_ids_SelectedStudents: action.data
        .attributes.student_profiles
        ? action.data.attributes.student_profiles.data
        : [],
      classrooms,
      select_options: {
        classroom_id: classrooms,
      },
    },
    isLoading: false,
  };
};

const updateSurveyRequest = (state) => ({ ...state, sending: true });

const setCurrentFormRequest = (state) => ({ ...state, isLoading: true });

const setCurrentFormSuccess = (state, action) => ({
  ...state,
  current: action.current,
  isLoading: false,
});

export const updateSurveySuccess = (state, action) => ({
  ...state,
  sending: false,
  selectedSurvey: action.selected,
  surveys: [action.data, ...state.surveys],
});

const fetchSurveyQuestionsRequest = (state) => ({ ...state, isLoading: true });

const fetchSurveyAuthorizationQuestionsRequest = (state, action) => ({
  ...state,
  isLoading: true,
  ...(!action.nextLink && {
    surveyQuestions: null,
    answersPagination: {},
  }),
});

const buildToastSuccess = (state) => ({ ...state, isLoading: false });

export const fetchSurveyQuestionsSuccess = (state, action) => ({
  ...state,
  surveyQuestions: { data: action.data, meta: action.meta },
  answersPagination: action.pagination,
  isLoading: false,
});

export const fetchSurveyAuthorizationQuestionsSuccess = (state, action) => ({
  ...state,
  surveyQuestions: {
    data: action.isNextLink
      ? [...state.surveyQuestions.data, ...action.data]
      : action.data,
    meta: action.meta,
  },
  answersPagination: action.pagination,
  isLoading: false,
});

export const fetchSurveyDeleteRequest = (state) => ({
  ...state,
  isDeletingSurvey: true,
});

export const fetchSurveyDeleteSuccess = (state, action) => ({
  ...state,
  surveys: action.data,
  isLoading: false,
  isDeletingSurvey: false,
});

export const fetchSurveyResend = (state, action) => ({
  ...state,
  surveys: action.data,
  selectedSurvey: action.selected,
});

export const approveSurveyRequest = (state) => ({
  ...state,
  isApprovingSurvey: true,
});

export const approveSurveySuccess = (state, action) => ({
  ...state,
  surveys: action.data,
  selectedSurvey: action.selected,
  isLoading: false,
  isApprovingSurvey: false,
});

export const disapproveSurvey = (state, action) => ({
  ...state,
  surveys: action.data,
  selectedSurvey: action.selected,
  isLoading: false,
});

export const setClassrooms = (state) => ({
  ...state,
  isLoading: true,
  formMeta: {
    ...state.formMeta,
    classrooms: [],
  },
});

export const setClassroomsSuccess = (state, action) => {
  const { data } = action;

  data.forEach((headquarter) =>
    headquarter.options.forEach((classroom) => {
      classroom.value = classroom.id;
      classroom.label = classroom.attributes.name;
    })
  );

  return {
    ...state,
    isLoading: false,
    formMeta: {
      ...state.formMeta,
      classrooms: data,
      select_options: {
        classroom_id: data,
      },
    },
  };
};

export const fetchEducationalStagesSuccess = (state, action) => ({
  ...state,
  educationalStages: action.educationalStages,
});

export const fetchClassroomFilterSuccess = (state, action) => ({
  ...state,
  classroomFilter: action.classroomFilter,
});

export const handleError = (state, action) => ({
  ...state,
  error: action.error,
  isLoading: false,
  isApprovingSurvey: false,
  isDeletingSurvey: false,
});

export const fetchSurveyVersionsRequest = (state, action) => ({
  ...state,
  isLoading: true,
  surveys: {
    ...state.surveys,
    surveysPagination: {
      ...state.surveys.surveysPagination,
      page: action.page,
    },
  },
});

export const fetchSurveyVersionsSuccess = (state, action) => {
  const { versions, lastVersion, pagination } = action;

  let versionsList = [];

  if (pagination.page === 1) {
    versionsList = [...versions];
  } else {
    versionsList = [...state.versions, ...versions];
  }

  return {
    ...state,
    isLoading: false,
    versions: versionsList,
    lastVersion: lastVersion || state.lastVersion,
    surveysPagination: {
      ...state.surveysPagination,
      ...pagination,
    },
  };
};

const HANDLERS = {
  [actions.ADD_SURVEY_REQUEST]: addSurvey,
  [actions.ADD_SURVEY_SUCCESS]: addSurveySuccess,
  [actions.FETCH_SURVEYS_REQUEST]: fetchSurveysRequest,
  [actions.FETCH_SURVEYS_SUCCESS]: fetchSurveysSuccess,
  [actions.RESET_SURVEY_LIST]: resetSurveyList,
  [actions.FETCH_SURVEY_DETAILS_REQUEST]: fetchSurveyDetailsRequest,
  [actions.FETCH_SURVEY_DETAILS_SUCCESS]: fetchSurveyDetailsSuccess,
  [actions.FETCH_SURVEY_FORM_REQUEST]: fetchSurveysFormRequest,
  [actions.FETCH_SURVEY_FORM_SUCCESS]: fetchSurveyFormSuccess,
  [actions.UPDATE_SURVEY_REQUEST]: updateSurveyRequest,
  [actions.UPDATE_SURVEY_SUCCESS]: updateSurveySuccess,
  [actions.FETCH_SURVEY_QUESTIONS_REQUEST]: fetchSurveyQuestionsRequest,
  [actions.FETCH_SURVEY_AUTHORIZATION_QUESTIONS_REQUEST]:
    fetchSurveyAuthorizationQuestionsRequest,
  [actions.FETCH_SURVEY_QUESTIONS_SUCCESS]: fetchSurveyQuestionsSuccess,
  [actions.FETCH_SURVEY_AUTHORIZATION_QUESTIONS_SUCCESS]:
    fetchSurveyAuthorizationQuestionsSuccess,
  [actions.FETCH_SURVEYS_DELETE_REQUEST]: fetchSurveyDeleteRequest,
  [actions.FETCH_SURVEYS_DELETE_SUCCESS]: fetchSurveyDeleteSuccess,
  [actions.FETCH_SURVEYS_RESEND]: fetchSurveyResend,
  [actions.APPROVE_SURVEY_REQUEST]: approveSurveyRequest,
  [actions.APPROVE_SURVEY_SUCCESS]: approveSurveySuccess,
  [actions.DISAPPROVE_SURVEY_SUCCESS]: disapproveSurvey,
  [actions.SET_CLASSROOMS_REQUEST]: setClassrooms,
  [actions.SET_CURRENT_FORM_REQUEST]: setCurrentFormRequest,
  [actions.SET_CURRENT_FORM_SUCCESS]: setCurrentFormSuccess,
  [actions.SET_CLASSROOMS_SUCCESS]: setClassroomsSuccess,
  [actions.BUILD_TOAST_SUCCESS]: buildToastSuccess,
  [actions.FETCH_EDUCATIONAL_STAGES_SUCCESS]: fetchEducationalStagesSuccess,
  [actions.FETCH_CLASSROOM_FILTER_SUCCESS]: fetchClassroomFilterSuccess,
  [actions.FETCH_SURVEY_VERSIONS_SUCCESS]: fetchSurveyVersionsSuccess,
  [actions.ERROR]: handleError,
};

const surveys = createModuleReducer(INITIAL_STATE, HANDLERS);

export default surveys;
