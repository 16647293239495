import createModuleReducer from '../storeConfig/createModuleReducer';
import actions from './actions';

export const INITIAL_STATE = {
  isLoading: false,
  isLoadingBillet: false,
  signatureList: [],
};

export const loadingRequest = (state) => ({
  ...state,
  isLoading: true,
});

export const handleError = (state, action) => ({
  ...state,
  isLoading: false,
  isChangingSchool: false,
  error: action.error,
});

export const fetchBillingListSuccess = (state, action) => {
  const signatureWithDetails = action.data.map((signature) => ({
    ...signature,
    details: signature.relationships?.items?.data?.map((relation) =>
      action.included?.find((items) => items.id === relation.id)
    ),
  }));

  return {
    ...state,
    isLoading: false,
    signatureList: signatureWithDetails,
  };
};

export const loadingBilletPayment = (state) => {
  return {
    ...state,
    isLoading: false,
    isLoadingBillet: true,
  };
};

export const updateBilletPayment = (state, action) => {
  const { link } = action;

  const win = window.open(link, '_blank');
  win.focus();

  return {
    ...state,
    isLoadingBillet: false,
  };
};

const HANDLERS = {
  [actions.FETCH_BILLING_LIST]: loadingRequest,
  [actions.FETCH_BILLING_LIST_SUCCESS]: fetchBillingListSuccess,
  [actions.REQUEST_BILLET_PAYMENT]: loadingBilletPayment,
  [actions.REQUEST_BILLET_PAYMENT_SUCCESS]: updateBilletPayment,
  [actions.ERROR]: handleError,
};

const billingPanel = createModuleReducer(INITIAL_STATE, HANDLERS);

export default billingPanel;
