import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { fetchApi } from 'core/utils/api';

import actions from './actions';

import { buildToast, ToastTypes } from 'store/middlewares/toast/actions';

export function* fetchRecipientsWalletsRequest() {
  try {
    const { dataArea, currentSchool } = yield select((state) => state.root);

    const { meta, data } = yield call(
      fetchApi,
      `/${dataArea}/wallets/list_wallets.json`
    );

    if (data.length < 1)
      throw new Error('Não foi possível Listar as carteira')

    yield put({
      type: actions.FETCH_RECIPIENTS_WALLETS_SUCCESS,
      data: meta.securitizers.data
        ? [...data, ...meta.securitizers.data]
        : [...data],
      currentWallet: meta?.current_recipient_wallet,
      schoolId: currentSchool.id,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      toast: buildToast(
        'Não foi possível Listar as carteira',
        ToastTypes.error
      ),
      error,
    });
  }
}

export function* fetchRecipientsWalletsOptionsRequest() {
  try {
    const { dataArea } = yield select((state) => state.root);

    const {
      data: { meta: recipientsWallet },
    } = yield call(
      fetchApi,
      `/${dataArea}/recurrent/recurrent_plans/recipient_wallets`
    );

    yield put({
      type: actions.FETCH_RECIPIENTS_WALLETS_OPTIONS_SUCCESS,
      recipientsWallet,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
    });
  }
}

export function* fetchSelectRecipientWalletRequest(action) {
  try {
    const { dataArea } = yield select((state) => state.root);

    const { data } = yield call(
      fetchApi,
      `/${dataArea}/edupay/recipient_wallets/${action.walletId}/selected_recipient_wallet.json`
    );

    yield put({
      type: actions.FETCH_SELECT_RECIPIENT_WALLET_SUCCESS,
      walletSeleted: data,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      toast: buildToast(
        'Não foi possível selecionar a carteira',
        ToastTypes.error
      ),
      error,
    });
  }
}

export function* fetchAccountBalanceRequest(action) {
  try {
    const { dataArea } = yield select((state) => state.root);
    const periodRange = action.periodRange;

    let endpoint = `/${dataArea}/wallets/balance.json`;
    if (periodRange.length === 2 && !periodRange.includes(null)) {
      endpoint += `?startDate=${periodRange[0]}&endDate=${periodRange[1]}`;
    }

    const { data } = yield call(fetchApi, endpoint);

    yield put({
      type: actions.FETCH_ACCOUNT_BALANCE_SUCCESS,
      data,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      toast: buildToast(
        'Não foi carregar o saldo da sua carteira',
        ToastTypes.error
      ),
      error,
    });
  }
}

export function* fetchBankAccountRequest() {
  try {
    const { dataArea } = yield select((state) => state.root);

    const { data } = yield call(
      fetchApi,
      `/${dataArea}/wallets/bank_account.json`
    );
    yield put({
      type: actions.FETCH_BANK_ACCOUNT_SUCCESS,
      data,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      toast: buildToast(
        'Não foi possível carregar sua conta bancária',
        ToastTypes.error
      ),
      error,
    });
  }
}

export function* fetchHistoryRequest({ page, schoolBillingInformationId }) {
  try {
    const { dataArea } = yield select((state) => state.root);

    const response = yield call(fetchApi, `/${dataArea}/wallets/history.json`, {
      params: {
        page,
        id: schoolBillingInformationId,
      },
    });

    yield put({
      type: actions.FETCH_HISTORY_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      toast: buildToast(
        'Não foi possível carregar o histórico da sua carteira',
        ToastTypes.error
      ),
      error,
    });
  }
}

function* walletsSagas() {
  yield all([
    takeLatest(
      actions.FETCH_RECIPIENTS_WALLETS_REQUEST,
      fetchRecipientsWalletsRequest
    ),
    takeLatest(
      actions.FETCH_ACCOUNT_BALANCE_REQUEST,
      fetchAccountBalanceRequest
    ),
    takeLatest(
      actions.FETCH_SELECT_RECIPIENT_WALLET_REQUEST,
      fetchSelectRecipientWalletRequest
    ),
    takeLatest(
      actions.FETCH_RECIPIENTS_WALLETS_OPTIONS_REQUEST,
      fetchRecipientsWalletsOptionsRequest
    ),
    takeLatest(actions.FETCH_BANK_ACCOUNT_REQUEST, fetchBankAccountRequest),
    takeLatest(actions.FETCH_HISTORY_REQUEST, fetchHistoryRequest),
  ]);
}

export default walletsSagas;
