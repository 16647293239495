import React, { useState } from 'react';
import withFormContext from 'core/hoc/withFormContext';
import { useDispatch, useSelector } from 'react-redux';
import actionRecurrentBills from 'store/edupay/recurrentBills/actions';
import { PaidAtSchoolModalProps, Responsible, OptionType } from './types';
import { RecurrentBillsState } from '../types';

import { RIGHT_CENTER, TOP_CENTER } from 'components/Tooltip';
import AgendaIcon from 'components/AgendaIcon';
import Field from 'components/Form/Field';
import ToggleSwitch from 'components/Form/ToggleSwitch';
import Buttons from '../Buttons';
import FileField from 'components/Form/FileField';
import UserItem from 'components/UserItem';
import Avatar from 'components/Avatar';
import RadioButton from 'components/RadioButton';

import * as S from './styles';

const selectOptions = [
  { label: 'Selecione o método de pagamento', value: null },
  { label: 'Boleto', value: 'billet' },
  { label: 'Cartão de crédito', value: 'credit_card' },
  { label: 'Dinheiro', value: 'cash' },
  { label: 'PIX', value: 'pix' },
];

function PaidAtSchoolModal({
  formContext,
  toggleModal,
  onSubmit,
  isRecurrent,
}: PaidAtSchoolModalProps) {
  const { form, updateAttribute } = formContext;
  const [responsibleId, setResponsibleId] = useState(null);

  const dispatch = useDispatch();
  const { manuallyPayLDRequest } = actionRecurrentBills;

  const { isLoadingManualDeal } = useSelector(
    (state: RecurrentBillsState) => state.recurrentBills
  );

  const {
    paidAtSchoolDate,
    paidAtSchoolNote,
    kind,
    paymentmethod,
    divergentValue,
    toggleDivergentValue,
    order,
    bill,
    attachments,
  } = form;

  const { attributes, id } = bill;

  const handlerSubmitButton = (): void => {
    dispatch(
      manuallyPayLDRequest({
        planId: attributes.recurrent_plan_id,
        billId: id,
        kind,
        date: paidAtSchoolDate,
        note: paidAtSchoolNote,
        paymentmethod,
        divergentValue,
        responsibleId,
        toggleDivergentValue,
        attachments,
      })
    );
  };

  const selectResponsibleProfileId = (responsibleProfileId: string) => {
    setResponsibleId(responsibleProfileId);
    updateAttribute('responsibleProfileId', responsibleProfileId);
  };

  const responsibleProfiles =
    bill?.order?.attributes?.responsible_profiles?.data ||
    order?.attributes?.responsible_profiles?.data ||
    order;

  const contentDateField = (
    <p>
      A data de vencimento será a mesma
      <br />
      independente do método de pagamento.
    </p>
  );

  const contentDivergentValueToggleSwitch = (
    <p>
      Marque esta opção caso o valor recebido tenha sido
      <br />
      maior ou menor que o definido na cobrança.
    </p>
  );

  const toggleSwitch = () => {
    updateAttribute('toggleDivergentValue', !form.toggleDivergentValue);
    updateAttribute('divergentValue', undefined);
  };

  return (
    <S.Container>
      <S.DateField>
        <S.LabelRow>
          <S.Label>Data*</S.Label>
          <S.CustomTooltip content={contentDateField} position={RIGHT_CENTER}>
            <AgendaIcon name="help" size="small" />
          </S.CustomTooltip>
        </S.LabelRow>
        <Field
          placeholder="DD/MM/AAAA"
          attributeName="paidAtSchoolDate"
          type="date"
          showAllDays
          onChange={(value: string) =>
            updateAttribute('paidAtSchoolDate', value)
          }
        />
      </S.DateField>
      <S.PaymentMethodsField>
        <S.Label>Método de pagamento*</S.Label>
        <Field
          attributeName="paymentmethods"
          type="selectWithIcon"
          value={form.paymentmethods}
          options={selectOptions}
          onChange={(option: OptionType) =>
            updateAttribute('paymentmethod', option.value)
          }
          fullWidth
          withArrowSelect
          controll
        />
      </S.PaymentMethodsField>
      <S.ResponsibleSelectField>
        <S.Label>Selecionar Responsável*</S.Label>
        <S.UserItemContainer>
          {responsibleProfiles &&
            responsibleProfiles.map((responsible: Responsible) => (
              <UserItem
                key={responsible.id}
                className={responsible.id === responsibleId ? 'selected' : ''}
                onClick={() => selectResponsibleProfileId(responsible.id)}
                userName={responsible.attributes.name}
                userRole={responsible.attributes.role}
                highlighted={responsible.id === responsibleId}
                actionComponent={
                  <RadioButton
                    attributeName="responsibleId"
                    value={responsible.id}
                    checked={responsible.id === responsibleId}
                    simpleInputRadio
                  />
                }
                avatarComponent={<Avatar user={responsible} size="small" />}
              />
            ))}
        </S.UserItemContainer>
      </S.ResponsibleSelectField>
      <S.ToggleDivergentValueContainer>
        <S.ToggleDivergentValueTitle>
          Pagamento com valor divergente
        </S.ToggleDivergentValueTitle>
        <S.CustomTooltip
          content={contentDivergentValueToggleSwitch}
          position={TOP_CENTER}
        >
          <AgendaIcon name="help" size="small" className="custom-size" />
        </S.CustomTooltip>
        <ToggleSwitch
          toggled={form.toggleDivergentValue}
          onChange={(e) => {
            toggleSwitch();
          }}
          disabled={false}
        />
      </S.ToggleDivergentValueContainer>

      {form.toggleDivergentValue && (
        <S.DivergentValue>
          <Field
            attributeName="divergentValue"
            type="currency"
            label="Valor de pagamento *"
          />
        </S.DivergentValue>
      )}
      <S.FileUploadContainer
        disabled={form?.attachments && form.attachments.length > 0}
      >
        <FileField attributeName="attachments" hideTooltip={true} />
      </S.FileUploadContainer>
      <S.NoteField>
        <Field
          label="Observações (opcional)"
          type="simpleTextArea"
          attributeName="paidAtSchoolNote"
          onChange={(e) =>
            updateAttribute('paidAtSchoolNote', e.paidAtSchoolNote)
          }
          maxLength="255"
        />
      </S.NoteField>
      <S.CounterWrapper>
        <S.LabelCounter>
          {form?.paidAtSchoolNote?.length || 0}/255
        </S.LabelCounter>
      </S.CounterWrapper>
      <Buttons
        title={'Concluir Baixa'}
        isDisable={
          isLoadingManualDeal ||
          !form?.paidAtSchoolDate ||
          !form?.paymentmethod ||
          !form?.responsibleProfileId ||
          (form?.toggleDivergentValue &&
            (form?.divergentValue == 0 || form?.divergentValue == undefined))
        }
        variation={'primary'}
        toggleModal={toggleModal}
        manuallySubmit={() =>
          isRecurrent ? handlerSubmitButton() : onSubmit(form)
        }
      />
    </S.Container>
  );
}

export default withFormContext(PaidAtSchoolModal);
