import React from 'react';
import PropTypes from 'prop-types';

const SIZES_MAPPING = {
  medium: 'col-lg-4 col-lg-offset-4',
  large: 'col-lg-8 col-lg-offset-2',
  extraLarge: 'col-lg-12'
};

const MOBILE_SIZE_MAPPING = {
  large: 'col-xs-10 col-xs-offset-1',
  extraLarge: 'col-xs-12'
}

import './style.scss';

function FormFieldset(props) {
  const { disabled, children, size, mobileSize } = props;

  return (
    <fieldset
      disabled={disabled}
      className={`FormFieldset ${MOBILE_SIZE_MAPPING[mobileSize]} ${SIZES_MAPPING[size]}`}
    >
      {children}
    </fieldset>
  );
}

FormFieldset.propTypes = {
  size: PropTypes.oneOf([
    'medium',
    'large',
    'extraLarge'
  ]),
  mobileSize: PropTypes.oneOf([
    'medium',
    'large',
    'extraLarge'
  ]),
  disabled: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func
  ]).isRequired
};

FormFieldset.defaultProps = {
  disabled: false,
  size: 'large',
  mobileSize: 'extraLarge'
};

export default FormFieldset
