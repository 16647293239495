import React from 'react';
import PropTypes from 'prop-types';

import FormFullScreen from 'components/FormFullScreen';
import PaymentsSchoolWalletFormBankInformationsTab from './BankInformationsTab';
import PaymentsSchoolWalletFormSchoolInformationsTab from './SchoolInformationsTab';
import PaymentsSchoolWalletFormWalletInformationsTab from './WalletInformationsTab';

const WIZARD_STEPS = [
  PaymentsSchoolWalletFormWalletInformationsTab,
  PaymentsSchoolWalletFormSchoolInformationsTab,
  PaymentsSchoolWalletFormBankInformationsTab,
];

const SchoolWalletForm = ({
  action,
  error,
  form,
  formMeta,
  isSubmitting,
  onSubmit,
  backTo,
  titlePage,
  titleModal,
  contentModal,
}) => {
  if (error) throw error;

  return (
    <div className="SchoolWalletForm">
      <FormFullScreen
        action={action}
        onSubmit={onSubmit}
        form={form}
        formMeta={formMeta}
        isSubmitting={isSubmitting}
        steps={WIZARD_STEPS}
        backTo={backTo}
        titlePage={titlePage}
        titleModal={titleModal}
        contentModal={contentModal}
      />
    </div>
  );
};

SchoolWalletForm.propTypes = {
  action: PropTypes.oneOf(['new', 'edit']).isRequired,
  error: PropTypes.any,
  form: PropTypes.object.isRequired,
  formMeta: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  backTo: PropTypes.string,
  titlePage: PropTypes.string,
  titleModal: PropTypes.string,
  contentModal: PropTypes.string,
};

SchoolWalletForm.defaultProps = {
  isSubmitting: false,
};

export default SchoolWalletForm;
