import styled, { css } from 'styled-components';

export const BankInformationsWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;

  > fieldset {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .Field {
    display: flex;
    width: 100%;
    margin: 0;
    padding: 0;
    margin-bottom: 0 !important;

    input {
      height: 40px;
      border-radius: 6px;
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }
  }

  .RadioGroup {
    display: flex;
    gap: 24px;

    .RadioButton {
      display: flex;
      width: 100%;
      padding: 0;
      margin: 0;

      & + .RadioButton {
        margin-left: 0;
        min-width: unset;
      }

      > label {
        height: 40px;
        width: 100%;
        min-width: unset;
        border-radius: 6px;
      }
    }
  }
`;

export const ColumnWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: 24px;
`;

export const RowWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: 24px;

  .Field {
    width: 100%;
  }

  @media (max-width: 400px) {
    flex-direction: column;
  }
`;

export const IssWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 18px;
`;

export const RequireItemsMessage = styled.p`
  ${({ theme }) => css`
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    color: ${theme.gray2};
  `}
`;
