import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import * as S from './styles';

import Loader from 'components/Loader';
import Toast from 'components/Toast';

import ReportsActions from 'store/reports/actions';
import ReportList from 'pages/reports/ReportList';

const Reports = () => {
  const dispatch = useDispatch();
  const { fetchReports, deleteReport } = ReportsActions;

  const { reports, isLoading } = useSelector((state) => state.reports);

  const reportDeletion = (reportId) => {
    dispatch(deleteReport(reportId));
  };

  const reportsRequest = useCallback(
    (queryParams) => {
      dispatch(fetchReports(queryParams));
    },
    [dispatch, fetchReports]
  );

  useEffect(() => {
    reportsRequest();
  }, [reportsRequest]);

  return (
    <S.MainContainer id="ReportsMainContainer">
      <S.MainContainerHeader>
        <S.MainTitle>Painel</S.MainTitle>
        <Link to="reports/new">
          <S.MainButton>Novo Relatório</S.MainButton>
        </Link>
      </S.MainContainerHeader>
      <ReportList
        reportList={reports}
        fetchReports={reportsRequest}
        deleteReport={reportDeletion}
      />
      {isLoading && <Loader />}
      <Toast />
    </S.MainContainer>
  );
};

export default Reports;
