import React, { Component } from 'react';
import PropTypes from 'prop-types';

import MessagesBadge from 'components/Messages/Badge';
import withAppContext from 'core/hoc/withAppContext';
import tinycolor from 'tinycolor2';

import './style.scss';

class MessagesOtherUsersChannelCard extends Component {
  static propTypes = {
    appContext: PropTypes.shape({
      primaryColor: PropTypes.string.isRequired,
    }).isRequired,
    channel: PropTypes.shape({
      attributes: PropTypes.shape({
        name: PropTypes.string.isRequired,
        icon_url: PropTypes.string,
        kind: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
        user_chats: PropTypes.arrayOf(PropTypes.object),
      }).isRequired,
    }),
    unreadCount: PropTypes.number,
  };

  static defaultProps = {
    unreadCount: 0,
  };

  state = {
    selectChannelCard: false,
  };

  listStyle = {
    listGroupItemHover: {
      backgroundColor: tinycolor(this.props.appContext.primaryColor).setAlpha(
        0.1
      ),
      color: this.props.appContext.primaryColor,
    },
  };

  render() {
    const { channel, unreadCount } = this.props;

    const { listGroupItemHover } = this.listStyle;

    const { selectChannelCard } = this.state;

    return (
      <div
        className={`MessagesOtherUsersChannelCard ${channel.attributes.status}`}
        onMouseEnter={() => this.setState({ selectChannelCard: true })}
        onMouseLeave={() => this.setState({ selectChannelCard: false })}
        style={selectChannelCard ? listGroupItemHover : null}
      >
        <img src={channel.attributes.icon_url} />
        <h3 className="title-channel">{channel.attributes.name}</h3>
        {unreadCount > 0 && <MessagesBadge>{unreadCount}</MessagesBadge>}
      </div>
    );
  }
}

export default withAppContext(MessagesOtherUsersChannelCard);
