import React from 'react';
import PropTypes from 'prop-types';

import withFormContext, { formPropTypes } from 'core/hoc/withFormContext';

import './style.scss';

function FormValidationErrors({ attributeName, multiple, formContext }) {
  const { getFormValidationResult } = formContext;

  return (
    <div className="FormValidationErrors">
      {multiple ? (
        <ul>
          {getFormValidationResult(attributeName).messages.map((message) => (
            <div key={attributeName}>
              <li key={message}>{message}</li>
            </div>
          ))}
        </ul>
      ) : (
        getFormValidationResult(attributeName).messages[0]
      )}
    </div>
  );
}

FormValidationErrors.propTypes = {
  attributeName: PropTypes.string.isRequired,
  multiple: PropTypes.bool,
  ...formPropTypes,
};

FormValidationErrors.defaultProps = {
  multiple: false,
};

export default withFormContext(FormValidationErrors);
