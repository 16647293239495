import React from 'react';
import parse from 'html-react-parser';
import { Box } from '@agendaedu/ae-web-components';

import * as S from './styles';

import { OwnProps } from './types';

const TrainingModal = ({ training }: OwnProps): JSX.Element => {
  const {
    attributes: { video_url, content },
  } = training;

  return (
    <Box
      mt="xl"
      width={{ _: '85vw', tabletMD: '80vw', desktopLG: '60vw' }}
      height={{ _: '80vh', tabletMD: '65vh', desktopLG: '65vh' }}
      overflowY="scroll"
      flexWrap="wrap"
    >
      <S.Video src={video_url} />
      <Box mt="xs">
        <S.ContentWrapper>{parse(content)}</S.ContentWrapper>
      </Box>
    </Box>
  );
};

export default TrainingModal;
