import React, { Component } from 'react';
import PropTypes from 'prop-types';

import OutlineBox from 'components/OutlineBox';
import colorWithAlpha from 'core/utils/colorWithAlpha';
import withAppContext from 'core/hoc/withAppContext';

import './style.scss';

class UserItem extends Component {
  static propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    userName: PropTypes.node.isRequired,
    userRole: PropTypes.node.isRequired,
    style: PropTypes.object,
    roleStyle: PropTypes.object,
    actionComponent: PropTypes.node,
    avatarComponent: PropTypes.node,
    highlighted: PropTypes.bool,
  };

  static defaultProps = {
    className: '',
    disabled: false,
    style: null,
    roleStyle: null,
    actionComponent: null,
    avatarComponent: null,
    highlighted: false,
  };

  getStyle = () => {
    const {
      highlighted,
      style,
      appContext: { primaryColor },
    } = this.props;
    if (style !== null) {
      return style;
    } else {
      const activeStyle = {
        backgroundColor: colorWithAlpha(primaryColor, 0.15),
        border: 0,
      };

      return highlighted ? activeStyle : null;
    }
  };

  getNameStyle = () => {
    const {
      highlighted,
      style,
      appContext: { primaryColor },
    } = this.props;
    if (style !== null) {
      return style;
    } else {
      const activeStyle = {
        color: colorWithAlpha(primaryColor),
      };

      return highlighted ? activeStyle : null;
    }
  };

  getRoleStyle = () => {
    const {
      highlighted,
      roleStyle,
      appContext: { primaryColor },
    } = this.props;
    if (roleStyle !== null) {
      return roleStyle;
    } else {
      const activeRoleStyle = {
        borderColor: colorWithAlpha(primaryColor, 0.5),
        color: primaryColor,
      };

      return highlighted ? activeRoleStyle : null;
    }
  };

  render() {
    const {
      className,
      disabled,
      userName,
      userRole,
      actionComponent,
      avatarComponent,
      onClick,
    } = this.props;

    return (
      <div
        className={`UserItem ${disabled ? 'disabled' : ''} ${className}`}
        style={this.getStyle()}
        onClick={onClick}
      >
        {avatarComponent}
        <div className="user-name" style={this.getNameStyle()}>
          {userName}
        </div>
        <div className="user-role">
          <OutlineBox variation="dimming" style={this.getRoleStyle()}>
            {userRole}
          </OutlineBox>
          {actionComponent}
        </div>
      </div>
    );
  }
}

export default withAppContext(UserItem);
