import React from 'react';
import ContentLoader from 'react-content-loader';

const CommentSkeleton = ({ amount }) => {
  return Array.from(Array(amount).keys()).map((n) => (
    <ContentLoader
      key={`skeleton-${n}`}
      width="100%"
      height="100%"
      speed={2}
      viewBox="0 0 400 40"
      ariaLabel="Carregando..."
    >
      <rect x="24" y="2" rx="1" ry="1" width="100" height="4" />
      <rect x="24" y="14" rx="1" ry="1" width="240" height="4" />
      <rect x="24" y="22" rx="1" ry="1" width="120" height="4" />
      <circle cx="9" cy="9" r="9" />
    </ContentLoader>
  ));
};

export default CommentSkeleton;
