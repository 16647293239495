import createModuleReducer from '../storeConfig/createModuleReducer';

// actions
import actions from './actions';

/** Initial state */
export const INITIAL_STATE = {
  isLoading: false,
  isSaved: false,
};

/** Reducer */
export const createAccountRequest = (state) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const createAccountSuccess = (state) => {
  return {
    ...state,
    isLoading: false,
    isSaved: true,
  };
};

export const error = (state) => {
  return {
    ...state,
    isLoading: false,
  };
};

const buildToastSuccess = (state) => ({ ...state, isLoading: false });

const HANDLERS = {
  [actions.CREATE_ACCOUNT_REQUEST]: createAccountRequest,
  [actions.CREATE_ACCOUNT_SUCCESS]: createAccountSuccess,
  [actions.BUILD_TOAST_SUCCESS]: buildToastSuccess,
  [actions.ERROR]: error,
};

const registerAccount = createModuleReducer(INITIAL_STATE, HANDLERS);

export default registerAccount;
