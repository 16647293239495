import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import {
  buildToast,
  toastCss,
  ToastTypes,
} from 'store/middlewares/toast/actions';
import { fetchApi, patchApi, postApi, handleError } from 'core/utils/api';

import actions from './actions';

function* fetchListProductsHubstoreSaga() {
  try {
    const { data, meta } = yield call(
      fetchApi,
      `/schools/hub_store/products.json`
    );

    yield put({
      type: actions.FETCH_LIST_PRODUCTS_HUBSTORE_SUCCESS,
      data: data,
      meta: meta,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        'Não foi possível obter a listagem de produtos.',
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* getProductDetailsSaga({ id }) {
  try {
    const { data, meta, included } = yield call(
      fetchApi,
      `/schools/hub_store/products/${id}.json`
    );

    yield put({
      type: actions.GET_PRODUCT_HUBSTORE_SUCCESS,
      data,
      meta: meta,
      included,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        'Não foi possível obter as informações do produto.',
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* updateProducHubstoreSaga(action) {
  try {
    const { id, isActive } = action;

    const actionProduct = isActive ? 'deactivate' : 'activate';

    const { meta } = yield call(
      patchApi,
      `/schools/hub_store/products/${id}/${actionProduct}`
    );

    yield put({
      type: actions.UPDATE_ITEM_PRODUCT_HUBSTORE_SUCCESS,
      id,
      meta: meta,
      isActive: !isActive,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        handleError(error),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* registerAppLead({ params }) {
  try {
    yield call(postApi, `/schools/hub_store/leads`, {
      lead: params,
    });

    yield put({
      type: actions.REGISTER_APP_LEAD_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        'Não foi possível concluir o cadastro.',
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* getSubscriptionsInfo(action) {
  try {
    const dataArea = yield select((state) => state.root.dataArea);

    const { data } = yield call(
      fetchApi,
      `/${dataArea}/hub_store/products/${action.productId}/subscriptions/new.json`
    );

    yield put({
      type: actions.GET_SUBSCRIPTIONS_INFO_SUCCESS,
      data,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        handleError(error),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* submitSubscriptions(action) {
  try {
    const dataArea = yield select((state) => state.root.dataArea);

    yield call(
      postApi,
      `/${dataArea}/hub_store/products/${action.params.productId}/subscriptions`,
      { subscription: { classroom_ids: action.params.classroom_ids } }
    );

    yield put({
      type: actions.SUBMIT_SUBSCRIPTION_SUCCESS,
      toast: buildToast(
        `Compra concluída com sucesso! O app ${action.params.productName} estará disponível em Meus Apps ou no menu lateral.`,
        ToastTypes.success,
        {
          ...toastCss(ToastTypes.success),
          onClose: () =>
            (window.location = `/${dataArea}/hub_store/products/${action.params.productId}`),
        }
      ),
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        handleError(error),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* hubstoreSagas() {
  yield all([
    takeLatest(
      actions.FETCH_LIST_PRODUCTS_HUBSTORE,
      fetchListProductsHubstoreSaga
    ),
    takeLatest(actions.GET_PRODUCT_HUBSTORE, getProductDetailsSaga),
    takeLatest(actions.UPDATE_ITEM_PRODUCT_HUBSTORE, updateProducHubstoreSaga),
    takeLatest(actions.REGISTER_APP_LEAD, registerAppLead),
    takeLatest(actions.GET_SUBSCRIPTIONS_INFO_REQUEST, getSubscriptionsInfo),
    takeLatest(actions.SUBMIT_SUBSCRIPTION_REQUEST, submitSubscriptions),
  ]);
}

export default hubstoreSagas;
