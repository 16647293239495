import React from 'react';

import RadioButtonWithIcon from 'components/Form/RadioButtonWithIcon';
import Title from 'components/WizardForm/Title';

import withFormContext from 'core/hoc/withFormContext';
import { FINANCIAL_RESPONSIBLE_OPTIONS } from 'core/constants';

import { FinancialResponsibleConfigurationProps } from './types';

import * as S from './styles';

const FinancialResponsibleConfiguration = ({
  formContext: {
    formMeta: { status },
  },
}: FinancialResponsibleConfigurationProps) => {
  const disabledOnEdit = ['released', 'delayed'].includes(status);

  return (
    <S.Wrapper className="financial-responsible-configuration">
      <Title name="Responsáveis Financeiros: Ajustes de Privacidade" />

      <S.Description>
        Quem pode visualizar e pagar as cobranças? Selecione uma opção:
      </S.Description>

      <RadioButtonWithIcon
        options={FINANCIAL_RESPONSIBLE_OPTIONS}
        attributeName="send_financial_responsible"
        disabled={disabledOnEdit}
      />
    </S.Wrapper>
  );
};

export default withFormContext(FinancialResponsibleConfiguration);
