import styled, { css } from 'styled-components';

export const QuantityContainer = styled.div`
  max-width: 95%;
  padding-left: 16px;

  .Button {
    width: 40px;
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
`;

export const Label = styled.label`
  ${({ theme }) => css`
    color: ${theme.gray1};
    font-weight: 400;
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    margin-top: 12px;
  `}
`;

export const QuantityGroup = styled.div`
  display: flex;
  max-width: 200px;
  align-items: center;
  margin-top: 7px;

  ${({ theme }) => css`
    .btn-default[disabled] {
      background-color: ${theme.white};
      color: ${theme.gray1};
    }
  `};
`;

export const QuantityNumber = styled.label`
  ${({ theme }) => css`
    color: ${theme.gray1};
    font-weight: 400;
    font-size: 16px;
    padding: 0 16px;
    margin-bottom: 0;
  `}
`;
