import styled, { css } from 'styled-components';

import FormFieldset from 'components/Form/Fieldset';

import { ThemeProps } from 'config/themes/types';
import { Text } from '@agendaedu/ae-web-components';

export const SchoolProfileStepWrapper = styled(FormFieldset)``;

export const RowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  ${({ theme: { space } }: ThemeProps) => css`
    padding: ${space.xs4};
    .container-wrap:first-child {
      margin-top: ${space.lg};
      max-width: none !important;

      .selector-filters {
        width: 100%;
      }
    }
  `}
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme: { space } }: ThemeProps) => css`
    gap: ${space.xs};
  `}
`;

export const StepTitle = styled(Text)`
  margin-top: 20px;
  ${({ theme: { colors } }: ThemeProps) => css`
    color: ${colors.neutral.black};
  `}
`;

export const StepSubtitle = styled(Text)`
  ${({ theme: { colors } }: ThemeProps) => css`
    color: ${colors.neutral.gray1};
  `}
`;

export const ItemWrapper = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: ${space.xl5};
    height: 100%;
    width: 100%;
    gap: ${space.xs};
    padding: ${space.xs} ${space.sm};
  `}
`;

export const TitleOption = styled(Text)`
  margin: 0;
`;

export const FormSkeletonWrapper = styled.div`
  ${({ theme: { breakpoints, colors, space } }: ThemeProps) => css`
    height: 100%;
    max-width: 100%;
    padding: ${space.xs4} 0;
    margin: ${space.xl3} 0;
    background-color: ${colors.neutral.white};

    @media only screen and (min-width: ${breakpoints.desktopLG}) {
      margin: ${space.xl3} 0;
    }

    @media only screen and (min-width: ${breakpoints.desktopXL}) {
      padding: ${space.xs4} 0;
    }
  `}
`;

export const AlertInformationWrapper = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    padding: ${space.xs2} ${space.xs4};
  `}
`;
