import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Line } from 'rc-progress';
import withAppContext from 'core/hoc/withAppContext';
import AgendaIcon from 'components/AgendaIcon';
import AttachmentEntity from 'core/entities/Attachment';

import './style.scss';

class UploadBar extends Component {
  static propTypes = {
    appContext: PropTypes.shape({
      primaryColor: PropTypes.string.isRequired,
    }).isRequired,
    attachment: PropTypes.instanceOf(AttachmentEntity).isRequired,
    cancelUpload: PropTypes.func.isRequired,
    progress: PropTypes.number.isRequired,
  };

  state = {
    hoveringClose: false,
  };

  uploadPercentageStyle = {
    color: this.props.appContext.primaryColor,
  };

  render() {
    const { appContext, cancelUpload, attachment, progress } = this.props;

    const name = attachment.getName();
    const size = attachment.getFormattedFileSize();

    const { hoveringClose } = this.state;

    return (
      <div className="UploadBar">
        <div className="file-info">
          <div className="file">
            <AgendaIcon name="attachment" size="medium" />
            <div>
              {name} <span className="file-size">• {size}</span>
            </div>
          </div>
          <div className="actions">
            <div
              className="upload-percentage"
              style={this.uploadPercentageStyle}
            >
              {progress}%
            </div>
            <div
              onClick={cancelUpload}
              onMouseEnter={() => this.setState({ hoveringClose: true })}
              onMouseLeave={() => this.setState({ hoveringClose: false })}
            >
              <AgendaIcon
                name="close"
                size="medium"
                className={hoveringClose ? 'hovered' : ''}
              />
            </div>
          </div>
        </div>
        <div className="bar">
          <Line percent={progress} strokeColor={appContext.primaryColor} />
        </div>
      </div>
    );
  }
}

export default withAppContext(UploadBar);
