import styled, { css } from 'styled-components';

export const Wrapper = styled.div``;

export const ToggleWrapper = styled.span`
  display: flex;
  align-items: center;

  font-size: 14px;
  color: #333333;
  margin-bottom: 24px;

  > p {
    margin: 0 0 0 25px;
  }
`;

const variations = {
  col1x3: () => css`
    .Field {
      flex: 1;
    }

    .Field:nth-of-type(2) {
      flex: 3;
    }
  `,
  col4x1: () => css`
    .Field {
      flex: 4;
    }

    .Field:nth-of-type(2) {
      flex: 1;
    }
  `,
};

export const InputsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-basis: 100%;
  ${(props) => variations[props.variation]}
  @media (max-width: 576px) {
    flex-direction: column;
  }
`;
