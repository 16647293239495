import React from 'react';
import PropTypes from 'prop-types';
import reduxProvider from 'core/hoc/reduxProvider';
import IntegrationFilters from 'pages/employees/integration/filters/';

const IntegrationMainScreen = ({ provider, }) => <IntegrationFilters provider={provider} />;

IntegrationMainScreen.propTypes = { provider: PropTypes.string.isRequired, };

export default reduxProvider(IntegrationMainScreen);

