import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { InviteModalBoxProps, RootState } from './types';
import FormContainer from 'components/Form/FormContainer';
import AgendaIcon from 'components/AgendaIcon';
import withFormContext from 'core/hoc/withFormContext';
import inviteEmptyStateActions from 'store/invite/emptyState/actions';
import InviteField from '../InviteField';
import { IMAGES_INVITE_URL } from 'core/constants';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import * as S from './styles';

const InviteModalBox = ({ resource }: InviteModalBoxProps): JSX.Element => {
  const { form, isLoading, error } = useSelector(
    (state: RootState) => state.inviteEmptyState
  );
  const { generateInviteUrl } = inviteEmptyStateActions;
  const dispatch = useDispatch();

  const handleGenerateInviteUrl = useCallback(() => {
    dispatch(generateInviteUrl(resource));
  }, [dispatch, generateInviteUrl, resource]);

  useEffect(() => {
    handleGenerateInviteUrl();
  }, [handleGenerateInviteUrl]);

  const { t } = useTranslation(['invite_box']);

  return (
    <S.Container>
      <S.ModalHeader>
        <S.ModalTitle>{t('modal_title')}</S.ModalTitle>
        <S.ModalButtonClose data-dismiss="modal">
          <AgendaIcon name="close" />
        </S.ModalButtonClose>
      </S.ModalHeader>

      <S.ImageBox>
        <img src={IMAGES_INVITE_URL.inviteBoxThumbnail} alt="thumbnail" />
      </S.ImageBox>

      <S.ModalText>{parse(t('modal_text'))}</S.ModalText>

      <S.FormWrapper>
        <FormContainer initialAttributes={form.invite_link ? form : {}}>
          <InviteField
            attributeName="invite_link"
            disabled={isLoading || error}
            error={error}
          />
        </FormContainer>
      </S.FormWrapper>
    </S.Container>
  );
};

export default withFormContext(InviteModalBox);
