const actions = {
  CANCEL_BILL_REQUEST: 'CANCEL_BILL_REQUEST/recurrentBills',
  CANCEL_AND_CREATE_NEW_BILL_REQUEST:
    'CANCEL_AND_CREATE_NEW_BILL_REQUEST/recurrentBills',
  CANCEL_BILL_SUCCESS: 'CANCEL_BILL_SUCCESS/recurrentBills',
  CANCEL_AND_CREATE_NEW_BILL_SUCCESS:
    'CANCEL_AND_CREATE_NEW_BILL_SUCCESS/recurrentBills',
  CANCEL_LOADING: 'CANCEL_LOADING/recurrentBills',
  NEGOTIATION_BILL_REQUEST: 'NEGOTIATION_BILL_REQUEST/recurrentBills',
  NEGOTIATION_BILL_SUCCESS: 'NEGOTIATION_BILL_SUCCESS/recurrentBills',
  EDIT_BILL: 'EDIT_RECURRENT_BILL/recurrentBills',
  EDIT_BILL_SUCCESS: 'EDIT_RECURRENT_BILL_SUCCESS/recurrentBills',
  ERROR: 'ERROR/recurrentBills',
  ERROR_CHECKOUT: 'ERROR_CHECKOUT/recurrentBills',
  FETCH_REQUEST: 'FETCH_REQUEST/recurrentBills',
  FETCH_SUCCESS: 'FETCH_SUCCESS/recurrentBills',
  FETCH_BILL: 'FETCH_BILL/recurrentBills',
  FETCH_BILL_SUCCESS: 'FETCH_BILL_SUCCESS/recurrentBills',
  CHECKOUT: 'CHECKOUT/recurrentBills',
  CHECKOUT_SUCCESS: 'CHECKOUT_SUCCESS/recurrentBills',
  LOAD_INFORMATION_TO_PAID: 'LOAD_INFORMATION_TO_PAID/recurrentBills',
  LOAD_INFORMATION_TO_PAID_SUCCESS:
    'LOAD_INFORMATION_TO_PAID_SUCCESS/recurrentBills',
  MANUALLY_PAY: 'MANUALLY_PAY/recurrentBills',
  MANUALLY_PAY_SUCCESS: 'MANUALLY_PAY_SUCCESS/recurrentBills',
  MANUALLY_PAY_LD_REQUEST: 'MANUALLY_PAY_LD_REQUEST/recurrentBills',
  MANUALLY_PAY_LD_SUCCESS: 'MANUALLY_PAY_LD_SUCCESS/recurrentBills',
  RELEASE_BILL: 'RELEASE_BILL/recurrentBills',
  RELEASE_BILL_SUCCESS: 'RELEASE_BILL_SUCCESS/recurrentBills',
  DELAY_BILL: 'DELAY_BILL/recurrentBills',
  DELAY_BILL_SUCCESS: 'DELAY_BILL_SUCCESS/recurrentBills',
  REFUND_BILL: 'REFUND_BILL/recurrentBills',
  REFUND_BILL_SUCCESS: 'REFUND_BILL_SUCCESS/recurrentBills',
  REVERT_MANUAL_PAYMENT: 'REVERT_MANUAL_PAYMENT/recurrentBills',
  REVERT_MANUAL_PAYMENT_SUCCESS: 'REVERT_MANUAL_PAYMENT_SUCCESS/recurrentBills',
  SET_BILL: 'SET_BILL/recurrentBills',
  SET_BILL_SUCCESS: 'SET_BILL_SUCCESS/recurrentBills',
  TOGGLE_MODAL: 'TOGGLE_MODAL/recurrentBills',
  TOGGLE_CANCEL_MODAL: 'TOGGLE_CANCEL_MODAL/recurrentBills',
  TOGGLE_MANUALLY_PAID_MODAL: 'TOGGLE_MANUALLY_PAID_MODAL/recurrentBills',
  TOGGLE_BILLET_OR_PIX_MODAL: 'TOGGLE_MANUALLY_BILLET_MODAL/recurrentBills',
  TOGGLE_BILLET_OR_PIX_LINK_MODAL:
    'TOGGLE_BILLET_OR_PIX_LINK_MODAL/recurrentBills',
  TOGGLE_DELAY_MODAL: 'TOGGLE_DELAY_MODAL/recurrentBills',
  TOGGLE_REFUND_MODAL: 'TOGGLE_REFUND_MODAL/recurrentBills',
  TOGGLE_REVERT_MANUAL_PAYMENT_MODAL: 'TOGGLE_REVERT_MANUAL_PAYMENT_MODAL/recurrentBills',
  TOGGLE_MANUAL_DEAL_MODAL: 'TOGGLE_MANUAL_DEAL_MODAL/recurrentBills',
  TOGGLE_TABLE_EDIT: 'TOGGLE_TABLE_EDIT/recurrentBills',
  UNSET_BILL: 'UNSET_BILL/recurrentBills',
  UPDATE_LEGACY_ID: 'UPDATE_LEGACY_ID/recurrentBills',
  TOGGLE_IS_LOADING_MANUAL_DEAL: 'TOGGLE_IS_LOADING_MANUAL_DEAL/recurrentBills',

  fetchRecurrentBills: (id) => ({ type: actions.FETCH_REQUEST, id }),
  fetchRecurrentBill: (planId, billId) => ({
    type: actions.FETCH_BILL,
    planId,
    billId,
  }),
  loadInformationToPaid: (planId, billId) => ({
    type: actions.LOAD_INFORMATION_TO_PAID,
    planId,
    billId,
  }),
  releaseRecurrentBill: (planId, billId) => ({
    type: actions.RELEASE_BILL,
    planId,
    billId,
  }),
  manuallyPay: (planId, billId, responsibleId, kind) => ({
    type: actions.MANUALLY_PAY,
    planId,
    billId,
    responsibleId,
    kind,
  }),
  manuallyPayLDRequest: (params) => ({
    type: actions.MANUALLY_PAY_LD_REQUEST,
    params,
  }),
  checkout: (billId, orderId, kind, form, method) => ({
    type: actions.CHECKOUT,
    billId,
    orderId,
    kind,
    form,
    method,
  }),
  delayBillResquest: (planId, billId) => ({
    type: actions.DELAY_BILL,
    planId,
    billId,
  }),
  refundBillResquest: (planId, billId) => ({
    type: actions.REFUND_BILL,
    planId,
    billId,
  }),
  revertManualPaymentRequest: (planId, billId) => ({
    type: actions.REVERT_MANUAL_PAYMENT,
    planId,
    billId,
  }),
  setRecurrentBill: (id) => ({ type: actions.SET_BILL, id }),
  toggleModal: () => ({ type: actions.TOGGLE_MODAL }),
  toggleManuallyPaidModal: () => ({ type: actions.TOGGLE_MANUALLY_PAID_MODAL }),
  toggleBilletOrPixModal: () => ({ type: actions.TOGGLE_BILLET_OR_PIX_MODAL }),
  toggleBilletOrPixLinkModal: () => ({
    type: actions.TOGGLE_BILLET_OR_PIX_LINK_MODAL,
  }),
  toggleCancel: () => ({ type: actions.TOGGLE_CANCEL_MODAL }),
  toggleDeplay: () => ({ type: actions.TOGGLE_DELAY_MODAL }),
  toggleRefund: () => ({ type: actions.TOGGLE_REFUND_MODAL }),
  toggleRevertManualPayment: () => ({ type: actions.TOGGLE_REVERT_MANUAL_PAYMENT_MODAL }),
  toggleTableEdit: () => ({ type: actions.TOGGLE_TABLE_EDIT }),
  unsetBill: () => ({ type: actions.UNSET_BILL }),
  cancelBillRequest: (params) => ({
    type: actions.CANCEL_BILL_REQUEST,
    params,
  }),
  cancelAndCreateNewBillRequest: (params) => ({
    type: actions.CANCEL_AND_CREATE_NEW_BILL_REQUEST,
    params,
  }),
  editBillRequest: (params) => ({ type: actions.EDIT_BILL, params }),
  negotiationBillRequest: (params) => ({
    type: actions.NEGOTIATION_BILL_REQUEST,
    params,
  }),
  toggleManualDealModal: () => ({ type: actions.TOGGLE_MANUAL_DEAL_MODAL }),
  updateRecurrentBillLegacyId: (params) => ({
    type: actions.UPDATE_LEGACY_ID,
    params,
  }),
};

export default actions;
