import React, { useCallback, useEffect } from 'react';
import FormCheckbox from 'components/Form/Checkbox';
import withFormContext from 'core/hoc/withFormContext';
import { OriginalRecurrentBillsProps } from './types';
import * as S from './styles';
import NegotiationCustomTable from '../NegotiationCustomTable';
import { centsToBRL } from 'core/utils/centsToBrl';

const OriginalRecurrentBills: React.FC<OriginalRecurrentBillsProps> = ({
  formContext: {
    form,
    formMeta: { originalRecurrentBills },
    updateAttribute,
  },
}) => {
  const updateValue = (value: string) => {
    updateAttribute(
      'selected_value_cents',
      originalRecurrentBills.attributes[value]
    );
    updateAttribute('selected_min_value_to_negotiate', value);
  };

  useEffect(() => {
    updateAttribute(
      'selected_value_cents',
      originalRecurrentBills.attributes.subtotal_with_fine_and_interest
    );
  }, []);

  const ValueWithSelect = useCallback(
    (
      value: string,
      type:
        | 'subtotal_with_fine_and_interest'
        | 'gross_amount'
        | 'original_amount'
        | 'subtotal_with_partial_discount'
    ) => (
      <S.FlexContainer>
        <S.SelectTitle>{value}</S.SelectTitle>
        <FormCheckbox
          checked={form.selected_min_value_to_negotiate === type}
          onChange={() => updateValue(type)}
          disabled={false}
        />
      </S.FlexContainer>
    ),
    [form.selected_min_value_to_negotiate]
  );

  return (
    <S.Container>
      <S.TableContainer>
        <NegotiationCustomTable
          data={originalRecurrentBills.attributes.original_recurrent_bills.data}
          columns={[
            { key: 'id', header: 'ID Cobrança' },
            { key: 'price_cents', header: 'Valor Original', format: 'money' },
            { key: 'max_discount_value', header: 'Desc. Máx', format: 'money' },
            {
              key: 'discount_price_cents',
              header: 'Desc. Atual',
              format: 'money',
            },
            {
              key: 'penality_amount_cents',
              header: 'Multas/Juros',
              format: 'money',
            },
            { key: 'due_date', header: 'Data venc. Orig' },
            { key: 'updated_value', header: 'Valor Total', format: 'money' },
          ]}
        />
      </S.TableContainer>
      <S.Divider />

      <S.InfoContainer>
        <S.FlexContainer>
          <S.InfoText>Total de desc. pontualidade atual</S.InfoText>
          <S.InfoText>
            {centsToBRL(
              originalRecurrentBills.attributes.total_current_discount
            )}
          </S.InfoText>
        </S.FlexContainer>
        <S.FlexContainer>
          <S.InfoText>Total de desc. máximo</S.InfoText>
          <S.InfoText>
            {centsToBRL(
              originalRecurrentBills.attributes.total_maximum_discount
            )}
          </S.InfoText>
        </S.FlexContainer>
        <S.FlexContainer>
          <S.InfoText>Multa / Juros</S.InfoText>
          <S.InfoText>
            {centsToBRL(originalRecurrentBills.attributes.fine_and_interest)}
          </S.InfoText>
        </S.FlexContainer>
      </S.InfoContainer>

      <S.Divider />

      <S.InfoContainer>
        <S.FlexContainer>
          <S.SelectTitle>
            Somatório do Valor Total <span>(Desc. Atual e Multas e Juros)</span>
          </S.SelectTitle>
          {ValueWithSelect(
            centsToBRL(
              originalRecurrentBills.attributes.subtotal_with_fine_and_interest
            ),
            'subtotal_with_fine_and_interest'
          )}
        </S.FlexContainer>
      </S.InfoContainer>

      <S.Divider />

      <S.InfoContainer>
        <S.FlexContainer>
          <S.SelectTitle>
            Subtotal do valor original{' '}
            <span>(Somatório do valor original)</span>
          </S.SelectTitle>
          {ValueWithSelect(
            centsToBRL(originalRecurrentBills.attributes.gross_amount),
            'gross_amount'
          )}
        </S.FlexContainer>
      </S.InfoContainer>

      <S.Divider />

      <S.InfoContainer>
        <S.FlexContainer>
          <S.SelectTitle>
            Subtotal do valor com descontos{' '}
            <span>(Desc máx. sem multas e juros)</span>
          </S.SelectTitle>
          {ValueWithSelect(
            centsToBRL(originalRecurrentBills.attributes.original_amount),
            'original_amount'
          )}
        </S.FlexContainer>
      </S.InfoContainer>

      <S.Divider />

      <S.InfoContainer>
        <S.FlexContainer>
          <S.SelectTitle>
            Subtotal do valor com desconto parcial{' '}
            <span>(Desc. Máx com multas e juros)</span>
          </S.SelectTitle>
          {ValueWithSelect(
            centsToBRL(
              originalRecurrentBills.attributes.subtotal_with_partial_discount
            ),
            'subtotal_with_partial_discount'
          )}
        </S.FlexContainer>
      </S.InfoContainer>
    </S.Container>
  );
};

export default withFormContext(OriginalRecurrentBills);
