import { Icon } from '@agendaedu/ae-web-components';
import AgendaIcon from 'components/AgendaIcon';
import { ThemeProps } from 'config/themes/types';
import styled, { css, keyframes } from 'styled-components';

const animationFrames = keyframes`
  0%{height:0}
  100%{height:auto}
`;

export const FooterToastWrapper = styled.div`
  ${({ theme: { border, colors, space } }: ThemeProps) =>
    css`
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: auto;
      padding: ${space.xs} ${space.md};
      border-radius: ${border.radius.lg} ${border.radius.lg} 0 0;
      border-top: ${border.width.sm} ${border.style.solid}
        ${colors.neutral.gray4};

      animation-name: ${animationFrames};
      animation-duration: 0.5s;
    `}
`;

export const ActionIcon = styled(AgendaIcon)`
  ${({ theme: { colors } }: ThemeProps) => css`
    color: ${colors.brand.primary.default};
    animation-name: ${animationFrames};
    animation-duration: 0.5s;
  `}
`;

export const MessageText = styled.span`
  ${({ theme: { colors, font } }: ThemeProps) => css`
    font-family: ${font.family.brand};
    font-weight: ${font.weight.bold};
    color: ${colors.neutral.black};
    animation-name: ${animationFrames};
    animation-duration: 0.5s;
  `}
`;

export const CloseButton = styled(Icon)`
  ${({ theme: { colors } }: ThemeProps) => css`
    color: ${colors.neutral.black};
    animation-name: ${animationFrames};
    animation-duration: 0.5s;

    &:hover {
      cursor: pointer;
    }
  `}
`;

export const ReplyMessageWrapper = styled.div`
  ${({ theme: { colors, space } }: ThemeProps) => css`
    display: flex;
    flex-direction: row;
    gap: ${space.xs2};
    ::before {
      content: '';
      width: 2px;
      background-color: ${colors.neutral.gray2};
    }
  `}
`;

export const MessageInformationWrapper = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    flex-direction: column;
    padding: ${space.xs3};
    gap: ${space.xs};
    p {
      margin-bottom: 0 !important;
    }
  `}
`;
