import moment from 'moment';

import validateTextAreaPresence from 'core/lib/FormValidator/validators/textAreaPresence';
import validatePresence from 'core/lib/FormValidator/validators/presence';
import validateLength from 'core/lib/FormValidator/validators/length';
import validateDate from 'core/lib/FormValidator/validators/date';
import validateTime from 'core/lib/FormValidator/validators/time';

function dateIsUndefined(state) {
  // Use this in surveys scheduling
  // return !state.date_start || !state.time_start || !state.time_end;
  return !state.time_end;
}

function endDateIsGreaterThanStartDate(start, end) {
  return validateDate({ after: start.format('YYYY-MM-DD') })(end);
}

function timeIsValid(start, end) {
  return validateTime({ after: start.format('HH:mm') })(end);
}

function validateEndDate(endDate, state) {
  const currentDate = moment(new Date()).format('YYYY-MM-DD');
  const currentTime = moment(new Date()).format('HH:mm');
  // Use this in surveys scheduling
  // if (dateIsUndefined(state) || endDateIsGreaterThanStartDate(state.date_start, endDate)) return true;
  if (
    dateIsUndefined(state) ||
    endDateIsGreaterThanStartDate(currentDate, endDate)
  )
    return true;
  // Use this in surveys scheduling
  // if (state.date_start === endDate) return timeIsValid(state.time_start, state.time_end);
  if (currentDate === endDate) return timeIsValid(currentTime, state.time_end);
  return false;
}

export default [
  {
    attributeName: 'title',
    validator: validatePresence,
    message: 'Campo obrigatório',
  },
  {
    attributeName: 'title',
    validator: validateLength({ max: 100 }),
    message: 'Máximo: 100 caracteres',
  },
  {
    attributeName: 'description',
    validator: validateTextAreaPresence,
    message: 'Campo obrigatório',
  },
  //TODO: adicionar o código comentado na Enquete v2
  // {
  //   attributeName: 'date_start',
  //   validator: validatePresence,
  //   message: 'Campo obrigatório',
  // },
  {
    attributeName: 'date_end',
    validator: validatePresence,
    message: 'Campo obrigatório',
  },
  {
    attributeName: 'date_end',
    validator: validateEndDate,
    message: 'Não pode ser inferior a data de início',
  },
  //TODO: adicionar o código comentado na Enquete v2
  // {
  //   attributeName: 'time_start',
  //   validator: validatePresence,
  //   message: 'Campo obrigatório',
  // },
  {
    attributeName: 'time_end',
    validator: validatePresence,
    message: 'Campo obrigatório',
  },
];
