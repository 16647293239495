import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
    background-color: ${theme.white};
    border-radius: 0 0 4px 4px;
    overflow: hidden;

    .Field {
      max-width: 560px;
      margin: 0;
      padding: 0;
    }
  `}
`;

export const InfoBox = styled.div`
  margin-right: 88px;
  padding: 32px;
  @media screen and (max-width: 992px) {
    margin-right: 0;
  }
`;

export const ImageBox = styled.div`
  @media screen and (max-width: 992px) {
    display: none;
  }
  img {
    height: 100%;
    object-position: center;
    -o-object-position: center;
    object-fit: cover;
    -o-object-fit: cover;
  }
`;

export const InfoTitle = styled.h4`
  ${({ theme }) => css`
    margin: 0 0 16px 0;
    font-family: 'Quicksand', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 100%;
    color: ${theme.black};
  `}
`;

export const InfoText = styled.p`
  ${({ theme }) => css`
    margin: 0 0 24px 0;
    width: 100%;
    max-width: 560px;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 170%;
    color: ${theme.gray1};
  `}
`;
