import React, { useCallback } from 'react';
import { Box, Button, Modal, Text } from '@agendaedu/ae-web-components';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import messagesActions from 'store/messages/actions';
import { MessageState } from 'store/messages/types';

export const GroupLeaveModal: React.FC = () => {
  const { t } = useTranslation(['messages']);
  const dispatch = useDispatch();

  const { toggleLeaveGroupModal, setLeaveGroupRequest } = messagesActions;

  const { showLeaveGroupModal, currentGroup } = useSelector(
    (state: MessageState) => state.messages
  );

  const handleToggleModal = useCallback((): void => {
    dispatch(toggleLeaveGroupModal());
  }, [dispatch, toggleLeaveGroupModal]);

  const handleLeaveGroupRequest = useCallback((): void => {
    dispatch(setLeaveGroupRequest(currentGroup?.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, setLeaveGroupRequest]);

  return (
    <Modal
      isOpen={showLeaveGroupModal}
      title={t('groups.modals.leave_group.title')}
      onClose={handleToggleModal}
    >
      <Box maxWidth="336px" paddingLeft={4}>
        <Text variant="body-regular-16" mb={32} mt={12} lineHeight="lg">
          {t('groups.modals.leave_group.description')}
        </Text>

        <Box
          display="flex"
          flexDirection="row"
          justifyContent="end"
          mt={32}
          gap="16px"
        >
          <Button
            data-testid="cancel-leave-button"
            variant="secondary"
            onClick={handleToggleModal}
          >
            {t('groups.modals.leave_group.back_button_label')}
          </Button>
          <Button
            data-testid="submit-leave-button"
            contextVariant="alert"
            onClick={handleLeaveGroupRequest}
          >
            {t('groups.modals.leave_group.submit_button_label')}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
