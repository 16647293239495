import styled, { css } from 'styled-components';

import { DefaultThemeProps } from '@agendaedu/ae-web-components';

export const Wrapper = styled.div<{
  isActive: boolean;
  theme: DefaultThemeProps;
}>`
  ${({ theme: { colors, border }, isActive }) => css`
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    width: fit-content;
    margin-left: auto;
    order: 4;

    @media (max-width: 833px) {
      order: 2;
      max-width: 24px;
    }

    > .Button {
      position: relative;
      display: inline-flex;
      align-items: center;
      justify-content: space-around;
      height: 40px;
      width: 40px;
      border-radius: ${border.radius.md};

      ${isActive &&
      css`
        border-color: ${colors.brand.primary.op10};
        background-color: ${colors.brand.primary.op10};

        .AgendaIcon {
          color: ${colors.brand.primary.default};
        }
      `}

      @media(max-width: 833px) {
        > span {
          display: none;
        }
      }
    }
  `}
`;

export const BadgeFilter = styled.span<{ theme: DefaultThemeProps }>`
  ${({ theme: { colors, typography, border } }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 24px;
    height: 24px;
    top: -10px;
    right: -10px;
    border-radius: ${border.radius.circle};
    ${typography.subtitle.Medium12}
    color: ${colors.neutral.white};
    background: ${colors.brand.primary.default};
  `}
`;
