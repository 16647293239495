import React, { useEffect } from 'react';
import withAppContext from 'core/hoc/withAppContext';
import reduxProvider from 'core/hoc/reduxProvider';
import { EMPTY_STATES_URL } from 'core/constants';
import { Button } from '@agendaedu/ae-web-components';
import OnboardingEmptyState from 'components/EmptyState/Onboarding';
import { ScreenProps } from './types';
import { trackEvent } from 'config/amplitude';
import * as S from './styles';

const ClassroomEmptyStateScreen = ({
  appContext: { policies },
}: ScreenProps) => {
  const { can_show_empty_state_classrooms: canShowEmptyStateClassrooms } =
    policies;

  const EMPTY_STATE_TEXT = `
    Aqui, você consegue criar e acompanhar as turmas de todas as
    unidades, independente do segmento (educação infantil, ensino
    fundamental...). Para criar uma nova turma, basta clicar no botão
    abaixo e preencher o formulário. Experimente!
  `;

  const pushToNewClassroom = () => {
    window.location.href = '/schools/classrooms/new';
    trackEvent('plg-empty-state-classroom-click');
  };

  useEffect(() => {
    trackEvent('plg-empty-state-classroom-view');
  }, []);

  return (
    <S.Wrapper
      canShow={canShowEmptyStateClassrooms}
      className="ClassroomEmptyStateScreen"
    >
      <OnboardingEmptyState
        imgUrl={EMPTY_STATES_URL.classroomsURl}
        title="Hora de criar as turmas da escola"
        text={EMPTY_STATE_TEXT}
        buttons={<Button onClick={pushToNewClassroom}>Criar nova turma</Button>}
      />
    </S.Wrapper>
  );
};

export default reduxProvider(withAppContext(ClassroomEmptyStateScreen));
