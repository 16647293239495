import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import parse from 'html-react-parser';

import Tabs from 'components/Tabs';
import Tab from 'components/Tab';
import Avatar from 'components/Avatar';
import EmptyState from 'components/EmptyState';
import Loader from 'components/Loader';

import { IMAGES_CLASSROOMS_URL } from 'core/constants/index';

import * as S from './styles';

const DailySummaryInfoTab = ({ isBeforeToday }) => {
  const {
    isFetching,
    currentStudent,
    dailySummaryId,
    formMeta: { sectionSlugs },
  } = useSelector((state) => state.dailySummaries);

  const findSectionLabel = (slug) => sectionSlugs[slug];

  const renderMeals = (meal) =>
    meal?.map(({ category, description, not_ate }, index) => (
      <S.DailyEntryTextContainer key={index}>
        <S.EntryLabel>{index + 1}ª Refeição</S.EntryLabel>
        <p>
          {!!category && (
            <>
              <b>{`${category.concat(not_ate || !!description ? ':' : '')}`}</b>
              <br />
            </>
          )}
          {!!not_ate && (
            <>
              <span>Não comeu</span>
              <br />
            </>
          )}

          {!!description && <span>{description}</span>}
        </p>
      </S.DailyEntryTextContainer>
    ));

  const renderSummary = () => {
    // eslint-disable-next-line
    const { date, created_at, ...currentSummary } = currentStudent?.summary;

    return Object.entries(currentSummary).map(([slug, value]) => {
      if (slug === 'meal') {
        return renderMeals(currentSummary.meal);
      } else if (slug !== 'occurrence') {
        return (
          <Fragment key={slug}>{getCurrentSummaryValues(slug, value)}</Fragment>
        );
      } else {
        return <Fragment key={slug}>{renderOccurrences(value)}</Fragment>;
      }
    });
  };

  const getCurrentSummaryValues = (slug, value) => {
    const section = findSectionLabel(slug);

    if (!value) return;

    if (Array.isArray(value)) {
      return value.length > 1
        ? renderMultipleEntryValues(section, value)
        : renderEntryValue(section, value[0]);
    }

    // a seção de observação é criada com o Quill, então é importante considerar a marcação HTML embutida
    return section === 'Observação'
      ? renderEntryValue(section, parse(value))
      : renderEntryValue(section, value);
  };

  const renderMultipleEntryValues = (section, values) => {
    const isSleep = section === 'Sono';
    let entriesToRender = [];

    values.map((entry, index) => {
      const newSection = `${index + 1}${isSleep ? 'º' : 'ª'} ${section}`;

      entriesToRender.push(renderEntryValue(newSection, entry));
    });
    return entriesToRender;
  };

  const renderEntryValue = (section, value) => {
    return (
      <S.DailyEntryTextContainer>
        <S.EntryLabel>{section}</S.EntryLabel>
        <p>{value}</p>
      </S.DailyEntryTextContainer>
    );
  };

  const renderOccurrences = (occurrenceList) => {
    if (!occurrenceList.length) return;

    let occurrencesToRender = [];

    occurrenceList.map(({ category, description }, index) => {
      const newLabel = `${index + 1}ª Ocorrência`;

      occurrencesToRender.push(
        occurrenceTemplate(newLabel, category, description)
      );
    });

    return occurrencesToRender;
  };

  const occurrenceTemplate = (label, category, description) => (
    <S.DailyEntryTextContainer>
      <S.EntryLabel>{label}</S.EntryLabel>
      <p>
        <b>{`${category}:`}</b>
        <br />
        <span>{description}</span>
      </p>
    </S.DailyEntryTextContainer>
  );

  const renderEmptyStateMessage = () => {
    if (isBeforeToday) {
      return (
        <p>
          Não foram adicionadas informações <br />
          para esse(a) aluno(a) na data selecionada.
        </p>
      );
    }

    return (
      <p>
        Ainda não foram adicionadas <br />
        informações para esse(a) aluno(a) hoje.
      </p>
    );
  };

  return (
    <Loader isLoading={isFetching}>
      {dailySummaryId ? (
        <S.InfoTabContainer>
          <Tabs defaultIndex={0} animated={false}>
            <Tab title="Informações">
              <S.TitleContainer>
                <Avatar user={currentStudent} size="medium" />
                <h4>{currentStudent?.attributes?.name}</h4>
              </S.TitleContainer>
              <S.LastUpdated variation="info">
                {currentStudent?.attributes?.last_updated}
              </S.LastUpdated>
              <S.DetailsContainer>{renderSummary()}</S.DetailsContainer>
            </Tab>
          </Tabs>
        </S.InfoTabContainer>
      ) : (
        <EmptyState
          imgUrl={IMAGES_CLASSROOMS_URL.emptyStateUrl}
          message={renderEmptyStateMessage()}
        />
      )}
    </Loader>
  );
};

DailySummaryInfoTab.propTypes = {
  isBeforeToday: PropTypes.boolean,
};

export default DailySummaryInfoTab;
