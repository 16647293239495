import styled, { css } from 'styled-components';

import Field from 'components/Form/Field';

export const MainContainer = styled.section`
  padding: 0 5%;

  form {
    display: flex;
    flex-flow: row wrap;
    align-items: center
    justify-content: flex-start;
  }

  @media (max-width: 834px) {
    padding: 0;
  }
`;

export const MainTitle = styled.h3`
  ${({ theme }) => css`
    font-family: Quicksand, sans-serif;
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    color: ${theme.black};
    margin-bottom: 40px;
  `}
`;

export const MainFilterField = styled(Field)`
  padding-left: 0;
  order: 1;

  @media (max-width: 833px) {
    max-width: 85%;
  }
`;

export const DateField = styled(Field)`
  order: 2;

  @media (max-width: 833px) {
    padding: 0;
    margin: 16px 0;
    order: 3;
  }
`;

export const TooltipWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  order: 3;
  height: 40px;
  padding: 0;
  margin: 0;

  @media (max-width: 833px) {
    display: none;
  }
`;
