import styled, { css } from 'styled-components';

export const Container = styled.div``;

export const CurrencyInfo = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Label = styled.span`
  ${({ theme }) => css`
    font-family: 'Roboto';
    font-size: 14px;
    line-height: 24px;
    color: ${theme.gray2};
  `}
`;

export const Text = styled.span`
  ${({ theme }) => css`
    font-family: 'Roboto';
    font-size: 12px;
    line-height: 22px;
    color: ${theme.gray2};
  `}
`;

export const TransferContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    margin-top: 21px;

    .col-xs-12 {
      width: 100%;
      padding: 0px;

      .Label {
        color: ${theme.gray2};
        font-family: 'Roboto';
        font-size: 14px;
        line-height: 24px;
      }
    }
  `}
`;

export const TitleContainer = styled.div`
  margin-bottom: 24px;

  > .Title {
    margin-bottom: 22px !important;
  }

  > .Alert {
    height: max-content;
    margin-bottom: 22px;
  }
`;

export const InforContainer = styled.div`
  ${({ theme }) => css`
    margin-top: ${theme.space.lg};
    margin-bottom: ${theme.space.lg};

    > .Alert {
      height: max-content;
      margin-bottom: ${theme.space.md};
    }
  `}
`;

export const TaxTransferContainer = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

export const Line = styled.hr`
  ${({ theme }) => css`
    color: ${theme.gray12};
  `}
`;

export const AmountReceivable = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
`;
