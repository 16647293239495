import styled, { css } from 'styled-components';
import ModalContainer from 'components/Modal/ModalContainer';
import { ThemeProps } from 'config/themes/types';

export const ModalWrapper = styled(ModalContainer)`
  .ReactModal__Content {
    width: 600px !important;
    padding: 32px !important;
    .title {
      width: 80%;
    }
    .flex-row-modal-head {
      margin-bottom: 8px;
    }
  }
`;
