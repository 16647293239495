import styled, { css } from 'styled-components';

export const SchoolInformationsWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;

  > fieldset {
    display: flex;
    flex-direction: column;
    gap: 24px;

    > .Title {
      margin: 0;
    }
  }

  .Form.vertical .Title {
    margin: 0;
  }

  .Field {
    display: flex;
    width: 100%;
    margin: 0;
    padding: 0;
    margin-bottom: 0 !important;

    input {
      height: 40px;
      border-radius: 6px;
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const ColumnWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: 24px;
`;

export const LinkWrapper = styled.a`
  display: flex;
  align-items: flex-end;
  width: 140px;
  padding-bottom: 8px;
`;

export const MiddleWrapper = styled.a`
  display: flex;
  width: 240px;

  @media (max-width: 650px) {
    width: 100%;
  }
`;

export const RowWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: 24px;

  .Field {
    width: 100%;
  }

  @media (max-width: 650px) {
    flex-direction: column;
  }
`;

export const RequireItemsMessage = styled.p`
  ${({ theme }) => css`
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    color: ${theme.gray2};
  `}
`;
