import styled, { css } from 'styled-components';
import ModalContainer from 'components/Modal/ModalContainer';

const customStyle = css`
  line-height: 24px;
`;

export const Wrapper = styled.div`
  .head-info a {
    max-width: 220px;
  }
`;

export const ContainerBody = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;

    .icon-ae-smartphone,
    .icon-ae-computer {
      color: ${theme.info};
      font-size: 23px;
    }

    .tags {
      display: flex;
      justify-content: space-between;
      padding: 0 16px;
      .ClassroomsList {
        .OutlineBox,
        .OutlineBox.default {
          min-width: 120px;
          border: none;
          background-color: ${theme.gray5};
          color: ${theme.gray2};
          padding: 4px 8px 5px;
        }
      }
      .status {
        margin-left: 3rem;
      }
    }
  `}
`;

export const Content = styled.div`
  width: 100%;

  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;

  gap: 1rem;

  @media only screen and (max-width: 576px) {
    flex-direction: column;
  }
`;

export const Informations = styled.div`
  ${({ theme, hasButton }) => css`
    display: flex;
    flex-direction: column;
    margin: 0 0 0 3rem;
    align-items: left;
    min-width: 100px;

    span {
      font-family: 'Quicksand';
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.75;
      letter-spacing: normal;
      text-align: left;
      color: ${theme.gray1};
    }

    .student-name {
      width: auto;
      font-family: 'Quicksand';
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.75;
      letter-spacing: normal;
      text-align: left;
      color: ${theme.gray2};
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin: 0;
    }

    div {
      display: flex;
      flex-direction: row;
      align-items: center;
      .AgendaIcon,
      .AgendaIcon.x-medium {
        margin-right: 10px;
        color: 'aquamarine';
        font-size: 24px;
      }
      strong {
        font-family: 'Quicksand';
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        color: ${theme.gray2};
      }
    }

    .Button {
      width: 140px;
      height: 32px;
      font-size: 12px;

      display: flex;
      align-items: center;

      .AgendaIcon {
        margin-right: 10px;
        font-size: 22px;
      }
    }

    .not-send-button {
      background: transparent;
      color: ${theme.gray2};
      border-color: ${theme.gray5};
    }

    justify-content: ${hasButton && 'center'};
    align-items: ${hasButton && 'flex-start'};

    @media screen and (max-width: 576px) {
      margin: 0;
    }

    @media screen and (min-width: 1200px) {
      .student-name {
        width: 130px;
      }
    }

    @media screen and (min-width: 1400px) {
      .student-name {
        width: 180px;
      }
    }
  `}
`;

export const ModalActivityDetail = styled(ModalContainer)`
  .ReactModal__Content .modal-title {
    font-size: 20px;
  }
`;

export const Details = styled.div`
  width: 100%;
`;

export const DetailsLine = styled.div`
  ${({ theme }) => css`
    margin-bottom: 16px;

    display: flex;
    flex-direction: column;

    .FileAttachment {
      .LabelContainer {
        padding-bottom: 0px;
        font-size: 16px;
        font-weight: 700;
        color: ${theme.gray2};
      }

      .FilesList {
        margin-top: 12px;
      }
    }
  `}
`;

export const Title = styled.span`
  ${({ theme }) => css`
    font-size: 16px;
    font-weight: 700;
    color: ${theme.gray2};
  `}
`;

export const Subtitle = styled.p`
  ${customStyle};
  ${({ theme }) => css`
    font-size: 14px;
    font-weight: 400;
    color: ${theme.black};
  `}
`;

export const ErrorMessage = styled.p`
  ${({ theme }) => css`
    margin: -12px 0 16px 0;
    font-weight: 600;
    color: ${theme.danger};
  `}
`;
