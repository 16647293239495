import React from 'react';
import PropTypes from 'prop-types';
import withFormContext, { formPropTypes } from 'core/hoc/withFormContext';

import FormValidationErrors from 'components/Form/ValidationErrors';
import PercentField from 'components/Form/PercentField';

import * as S from './styles';

const EdupayPenality = ({
  attributeForm,
  penality,
  disabled,
  formContext: { form, updateAttribute, hasErrorOnAttribute },
}) => {
  const hasError = hasErrorOnAttribute(attributeForm);

  const onChangeValue = (attributeName, value) => {
    penality[attributeName] = value;
    const newPenality = penality;

    updateAttribute('edupay_penality', newPenality);
  };

  return (
    <S.EdupayPenalityWrapper>
      <S.ContainerWrapper>
        <S.EdupayPenality>
          <S.Text>Após o vencimento, aplicar multa de</S.Text>
          <S.PercentFieldWrapper>
            <PercentField
              controlled
              attributeName="fine_percent"
              onChange={() => onChangeValue('fine_percent', form.fine_percent)}
              defaultValue={penality.fine_percent}
              disabled={disabled}
            />
          </S.PercentFieldWrapper>
          <S.Text>e juros de</S.Text>
          <S.PercentFieldWrapper>
            <PercentField
              controlled
              attributeName="interest_percent"
              onChange={() =>
                onChangeValue('interest_percent', form.interest_percent)
              }
              defaultValue={penality.interest_percent}
              disabled={disabled}
            />
          </S.PercentFieldWrapper>
          <S.Text>ao mês.</S.Text>
        </S.EdupayPenality>
      </S.ContainerWrapper>
      {hasError && (
        <FormValidationErrors attributeName={attributeForm} multiple />
      )}
    </S.EdupayPenalityWrapper>
  );
};

EdupayPenality.propTypes = {
  attributeForm: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  penality: PropTypes.shape({
    fine_percent: PropTypes.number,
    interest_percent: PropTypes.number,
  }).isRequired,
  ...formPropTypes,
};

export default withFormContext(EdupayPenality);
