import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  Alert,
  Box,
  DefaultThemeProps,
  FlexGrid,
  FlexGridItem,
  Icon,
  RecipientTag,
  SubtleIconButton,
  Text,
  theme,
} from '@agendaedu/ae-web-components';
import moment from 'moment';

import { ValidFileType } from 'core/constants/storage';

import { normalizeFileType } from 'store/storage/normalizes';
import StorageActions from 'store/storage/actions';
import { Recipient } from 'store/storage/types';
import { normalizeDownloadURL } from 'core/helper/albums';
import { useDownloadFile } from 'core/hooks/useDownloadFile';

import { Props, StorageIcon } from './types';

const StorageCard: React.FC<Props> = ({
  handleOnClick,
  dataTestId,
  isRootFolder,
  storage,
  storage: {
    id,
    attributes: {
      classrooms,
      createdAt,
      receiverType,
      attachments,
      kind,
      availableTo,
      studentProfiles,
      title,
      updatedAt,
      userPermissions,
    },
  },
  history,
}): React.ReactElement => {
  const defaultTheme: DefaultThemeProps = theme;
  const { t } = useTranslation('storage');

  const dataArea = useSelector(
    (state: { root: { dataArea: string } }) => state.root.dataArea
  );

  const { requestDownloadFileWithFetch } = useDownloadFile({
    getFileURL: normalizeDownloadURL(attachments[0]?.url),
    getFileName: attachments[0]?.name,
  });

  const {
    toggleShowDetaisModal,
    toggleShowDeleteStorageModal,
    setSelectedStorage,
  } = StorageActions;

  const dispatch = useDispatch();

  const tBase = useCallback(
    (key: string, params?: Record<string, string | number>) =>
      t(`storage_list.card.${key}`, params),
    [t]
  );

  const fileType: ValidFileType = attachments?.length && attachments[0].type;

  const handleShowDeleteModal = useCallback(() => {
    dispatch(setSelectedStorage(storage));
    dispatch(toggleShowDeleteStorageModal());
  }, [dispatch, setSelectedStorage, storage, toggleShowDeleteStorageModal]);

  const handleShowDetailsModal = useCallback(() => {
    dispatch(setSelectedStorage(storage));
    dispatch(toggleShowDetaisModal());
  }, [dispatch, setSelectedStorage, storage, toggleShowDetaisModal]);

  const handleOpenFile = useCallback(
    (e) => {
      if (e?.target?.id === 'details') return;
      if (attachments && attachments[0].type.includes('image')) {
        window.open(attachments[0].url, '_blank');
        return;
      }

      requestDownloadFileWithFetch();
    },
    [attachments, requestDownloadFileWithFetch]
  );

  const handleEditStorage = useCallback(
    (event: MouseEvent) => {
      const action = {
        folder: () => history.push(`/${dataArea}/artifacts/${id}/folders/edit`),
        document: () =>
          history.push(`/${dataArea}/artifacts/${id}/documents/edit`),
        file: () => history.push(`/${dataArea}/artifacts/${id}/files/edit`),
      };

      action[kind]();
      event.stopPropagation();
    },
    [dataArea, history, id, kind]
  );

  const {
    colors: { neutral },
  } = defaultTheme;

  const actions = () => {
    return [
      ...(userPermissions.edit
        ? [
            {
              as: 'button',
              title: tBase('actions.edit'),
              onClick: handleEditStorage,
            },
          ]
        : []),
      ...((kind === 'file' && attachments[0]?.type.includes('image')) ||
      kind !== 'file'
        ? [
            {
              as: 'button',
              title: tBase('actions.view'),
              onClick: kind === 'file' ? handleOpenFile : handleOnClick,
            },
          ]
        : []),
      ...(kind === 'file'
        ? [
            {
              as: 'button',
              title: tBase('actions.download'),
              onClick: (event: MouseEvent) => {
                event.stopPropagation();
                requestDownloadFileWithFetch();
              },
            },
          ]
        : []),
      ...(userPermissions.delete
        ? [
            {
              as: 'button',
              title: tBase('actions.delete'),
              onClick: (event: MouseEvent) => {
                event.stopPropagation();
                handleShowDeleteModal();
              },
            },
          ]
        : []),
      {
        as: 'button',
        id: 'details',
        title: tBase('actions.details'),
        onClick: handleShowDetailsModal,
      },
    ];
  };

  const storageType = useMemo((): string => {
    if (kind === 'file') return normalizeFileType(fileType);

    return tBase(`type.${kind}`);
  }, [fileType, kind, tBase]);

  const storageIcon = useMemo((): StorageIcon => {
    const validImageTypes = [
      'image/png',
      'image/jpeg',
      'image/jpg',
      'image/gif',
      'image/webp',
      'image/heic',
    ];

    if (fileType === 'application/pdf') return 'file-pdf';

    if (validImageTypes.includes(fileType) && kind === 'file') {
      return 'file-img';
    }

    const icons: Record<string, StorageIcon> = {
      document: 'file-doc',
      folder: 'folder',
      file: 'file-arc',
    };

    return icons[kind];
  }, [fileType, kind]);

  const recipientsTag = useMemo((): {
    recipients: Recipient[];
    type: 'classrooms' | 'students';
  } => {
    const isClassroom = receiverType === 'classroom';
    const recipients = isClassroom ? classrooms : studentProfiles;

    const type = isClassroom
      ? tBase('recipients_classrooms_tag_type')
      : tBase('recipients_students_tag_type');

    return { recipients, type };
  }, [classrooms, receiverType, studentProfiles, tBase]);

  const recipientsTagAlert = useMemo((): React.ReactElement => {
    return (
      <Alert variant="informative">
        {tBase('recipients_alert_tag', {
          sentTo: tBase(`recipints_sent_to.${availableTo}`),
          title,
        })}
      </Alert>
    );
  }, [availableTo, tBase, title]);

  const dateStatus = useMemo((): string => {
    if (updatedAt) return moment(updatedAt).format(tBase('date.updatedAt'));
    return moment(createdAt).format(tBase('date.createdAt'));
  }, [createdAt, tBase, updatedAt]);

  return (
    <Box
      data-testid={dataTestId}
      cursor="pointer"
      width="100%"
      onClick={kind === 'file' ? handleOpenFile : handleOnClick}
    >
      <FlexGrid>
        <FlexGridItem cols={{ desktopLG: 4 }} filled>
          <Box display="flex" flexDirection="row" gap="lg" alignItems="center">
            <Box minWidth={24}>
              <Icon
                data-testid={`icon-${kind}`}
                name={storageIcon}
                color={neutral.gray2}
              />
            </Box>
            <Box
              display="grid"
              flexDirection="column"
              gap="1px"
              marginRight={{ mobileXS: 'xl2' }}
            >
              <Text
                mb={0}
                data-testid="date"
                variant="subtitle-medium-12"
                color="neutral.gray2"
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
                lineHeight="lg"
              >
                {dateStatus}
              </Text>
              <Text
                data-testid="title"
                mb={0}
                variant="subtitle-medium-16"
                color="neutral.gray1"
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
                lineHeight="lg"
              >
                {title}
              </Text>
            </Box>
          </Box>
        </FlexGridItem>

        <FlexGridItem cols={{ desktopLG: 4 }} filled>
          <Box
            display="flex"
            alignItems="baseline"
            justifyContent={{ desktopLG: 'flex-start' }}
          >
            {!isRootFolder && (
              <Box
                mr="md"
                display="flex"
                flexDirection="column"
                gap="1px"
                minWidth="50%"
              >
                <Text
                  mb={0}
                  variant="subtitle-medium-12"
                  color="neutral.gray2"
                  lineHeight="lg"
                >
                  {tBase('type_label')}
                </Text>
                <Text
                  mb={0}
                  variant="subtitle-medium-16"
                  color="neutral.gray1"
                  lineHeight="lg"
                >
                  {storageType}
                </Text>
              </Box>
            )}

            <Box display="flex" flexDirection="column" gap="1px" minWidth="50%">
              <Text
                mb={0}
                variant="subtitle-medium-12"
                color="neutral.gray2"
                lineHeight="lg"
              >
                {tBase('recipients_label')}
              </Text>

              <Text
                mb={0}
                variant="subtitle-medium-16"
                color="neutral.gray1"
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
                paddingRight={{ mobileXS: 'sm' }}
                lineHeight="lg"
              >
                {tBase(`recipints_sent_to.${availableTo}`)}
              </Text>
            </Box>
          </Box>
        </FlexGridItem>

        <FlexGridItem cols={{ desktopLG: 3 }} filled>
          <FlexGridItem filled>
            <Box
              display="flex"
              alignItems="center"
              marginTop="16px"
              marginLeft={{ desktopLG: 'xl3' }}
              justifyContent="start"
            >
              <RecipientTag
                recipientType={recipientsTag.type}
                modalTitle={tBase('recipients_modal_tag')}
                recipients={recipientsTag.recipients}
                size="small"
                variant="neutral"
                enableEllipsis
                modalHeaderContent={recipientsTagAlert}
              />
            </Box>
          </FlexGridItem>
        </FlexGridItem>

        <FlexGridItem cols={{ desktopLG: 1 }}>
          <Box position={{ desktopLG: 'relative' }}>
            <Box
              position="absolute"
              top={{ _: 12, desktopLG: 4 }}
              bottom={0}
              right={12}
            >
              <SubtleIconButton actions={actions()} align="right" />
            </Box>
          </Box>
        </FlexGridItem>
      </FlexGrid>
    </Box>
  );
};

export default StorageCard;
