import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { CardList, Box } from '@agendaedu/ae-web-components';
import InfoSurvey from 'pages/surveys/Cards/InfoSurvey';
import EmptyState from 'components/EmptyState';
import { useSelector } from 'react-redux';
import { IMAGES_SURVERY_URL } from 'core/constants/index';
import { SurveySkeleton } from './skeleton';

import * as S from './styles';

const SurveyList = ({ callFetchSurveys, history }) => {
  const { dataArea } = useSelector((state) => state.root);
  const isLoading = useSelector((state) => state.surveys.isLoading);
  const surveyList = useSelector((state) => state.surveys.surveyList);
  const surveysMeta = useSelector((state) => state.surveys.surveysMeta);
  const surveysPaginationLinks = useSelector(
    (state) => state.surveys.paginationLinks
  );

  const hasFilter = surveysPaginationLinks?.self?.includes('filter');

  const fetchMoreSurveys = () => {
    if (surveysPaginationLinks?.next)
      callFetchSurveys({}, { nextLink: surveysPaginationLinks?.next });
  };

  const renderSurveyList = () => {
    if (!surveysMeta && !surveyList.length) return <SurveySkeleton />;

    if (!isLoading && !surveyList.length) {
      return (
        <Box margin="0 auto">
          <EmptyState
            imgUrl={IMAGES_SURVERY_URL.emptyStateUrl}
            message={
              hasFilter ? (
                <p>Não encontramos resultados</p>
              ) : (
                <>
                  <p>Ainda não foram criados formulários.</p>
                  <p>Dê o primeiro passo e compartilhe</p>
                  <p>solicitações importantes com as famílias</p>
                </>
              )
            }
          />
        </Box>
      );
    }

    return surveyList.map((survey) => (
      <InfoSurvey key={survey.id} survey={survey} />
    ));
  };

  const openSurvey = (id) => history.push(`/${dataArea}/surveys/${id}`);

  return (
    <S.SurveyListWrapper>
      <CardList
        pageStart={1}
        hasMore={!isLoading && !!surveysPaginationLinks?.next}
        loadMore={fetchMoreSurveys}
        onClickItem={({ index }) => {
          openSurvey(surveyList[index].id);
        }}
      >
        {renderSurveyList()}
      </CardList>
      {isLoading && !!surveyList.length && <SurveySkeleton />}
    </S.SurveyListWrapper>
  );
};

SurveyList.propTypes = {
  callFetchSurveys: PropTypes.func,
};

export default withRouter(SurveyList);
