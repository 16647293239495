import styled, { css } from 'styled-components';

import ModalContainer from 'components/Modal/ModalContainer';

export const ReportModalContainer = styled(ModalContainer)`
  .modal-title {
    margin: 0;
    font-size: 20px;
    font-weight: 700;
    color: ${({ theme }) => css`
      ${theme.black};
    `};
  }

  .sub-title {
    font-size: 14px;
    font-weight: 500;
    color: ${({ theme }) => css`
      ${theme.gray1};
    `};
  }

  .sub-title-description {
    font-family: 'Roboto', sans-serif;
    margin-bottom: 24px;
    font-size: 16px;
    font-weight: 400;
    color: ${({ theme }) => css`
      ${theme.gray2};
    `};
  }

  li {
    list-style-type: none;
  }
`;

export const ChartTitle = styled.h5`
  margin-bottom: 8px;
  font-family: 'Quicksand', sans-serif;
  font-size: 18px;
  font-weight: 500;
  color: ${({ theme }) => css`
    ${theme.black};
  `};
`;

export const ChartUrl = styled.div`
  display: inline-flex;
  flex-flow: row;
  align-items: center;
  margin-bottom: 24px;

  .AgendaIcon {
    font-size: 14px;
  }

  a {
    margin-left: 8px;
    text-decoration: underline;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    color: ${({ theme }) => css`
      ${theme.gray2};
    `};
  }
`;
