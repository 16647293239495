import React, {Component} from 'react';
import PropTypes from 'prop-types';

class Address extends Component {
  addressFormat(address,variation) {
    const addressVariation = {
      full:
        `${address.street}, ${address.street_number} - ${address.neighborhood}
        , ${address.city} - ${address.state}, ${address.cep}`
    };

    return (
      addressVariation[variation]
    );
  }


  render() {
    const {
      address,
      variation
    } = this.props;

    return (
      <span className="Address">
        {this.addressFormat(address,variation)}
      </span>
    );
  }
}

Address.propTypes = {
  address: PropTypes.shape({
    street: PropTypes.string,
    street_number: PropTypes.string,
    state: PropTypes.string,
    city: PropTypes.string,
    neighborhood: PropTypes.string,
    cep: PropTypes.string,
    complement: PropTypes.string
  }),
  variation: PropTypes.oneOf([
    'full'
  ])
};

Address.defaultProps = {
  variation: 'full'
};

export default Address;
