import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

import Avatar from 'components/Avatar';
import MessagesGroupAvatar from 'components/Messages/GroupAvatar';

import './style.scss';
import UserItem from "components/UserItem";

function SelectDestination(props) {
  const {
    disabled,
    kind,
    user,
    actionComponent,
    selected
  } = props;

  const isFamilyRecipient = kind === 'family';

  return (
    <UserItem
      className={`SelectDestination ${kind}`}
      disabled={disabled}
      user={user}
      highlighted={selected}
      userName={
        isFamilyRecipient
          ? (
            <Fragment>
              <strong>Família:</strong> {user.attributes.name}
            </Fragment>
          )
          : user.attributes.name
      }
      userRole={isFamilyRecipient ? 'Grupo' : user.attributes.role}
      avatarComponent={
        isFamilyRecipient
          ? <MessagesGroupAvatar user={user} size="small"/>
          : <Avatar user={user} size="small"/>
      }
      actionComponent={actionComponent}
    />
  );
}

SelectDestination.propTypes = {
  disabled: PropTypes.bool,
  kind: PropTypes.oneOf(['private', 'family']),
  user: PropTypes.object.isRequired,
  style: PropTypes.object,
  roleStyle: PropTypes.object,
  actionComponent: PropTypes.node
};

SelectDestination.defaultProps = {
  disabled: false,
  style: null,
  roleStyle: null,
  actionComponent: null
};

export default SelectDestination;
