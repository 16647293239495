import axios from 'axios';
import { extractBody } from 'core/utils/request';

export default class StudentsService {
  constructor(dataArea) {
    this.dataArea = dataArea;
  }

  /**
   * @api public
   */
  async fetchResponsibles(id) {
    const endpoint = `/${this.dataArea}/student_profiles/${id}/responsible_profiles.json`;

    try {
      const response = await axios.get(endpoint);
      const body = extractBody(response);
      const responsibles = body.data;
      return responsibles;
    } catch (error) {
      throw error;
    }
  }

  /**
   * @api public
   */
  async fetchConfirmedResponsibles(id) {
    const endpoint = `/${this.dataArea}/student_profiles/${id}/confirmed_responsible_profiles.json`;

    try {
      const response = await axios.get(endpoint);
      const body = extractBody(response);
      const confirmedResponsibles = body.data;
      return confirmedResponsibles;
    } catch (error) {
      throw error;
    }
  }

  /**
   * @api public
   * @param {Object} formState
   */
  async advanceStudents(formState) {
    try {
      const response = await axios.post(
        `/${this.dataArea}/student_profiles/advance_students/`,
        formState
      );
      const advanceStudents = extractBody(response).data;
      return advanceStudents;
    } catch (error) {
      throw error;
    }
  }

  /**
   * @api public
   * @param {Object} formState
   */
  async transferStudents(formState) {
    const form = {
      student_profile: formState,
    };

    try {
      const response = await axios.post(
        `/${this.dataArea}/student_profiles/transfer/`,
        form
      );
      const transferStudents = extractBody(response).data;
      return transferStudents;
    } catch (error) {
      throw error;
    }
  }
}
