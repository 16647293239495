import styled from 'styled-components';

export const NegotiationItem = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 16px;
  border-bottom: solid 1px ${(props) => props.theme.gray5};

  .item-category {
    display: flex;
    flex-basis: 100%;

    > .Field {
      padding: 0;
      margin-bottom: 16px;

      .Select:after {
        z-index: 0;
      }

      &:first-child {
        margin-top: 16px;
      }
    }

    .bill-title {
      font-family: Roboto;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      color: ${(props) => props.theme.gray2};
    }

    @media screen and (min-width: 768px) {
      flex-basis: 60%;
      padding-right: 24px;

      > .Field {
        margin-bottom: 0;

        > label {
          display: none;
        }

        .Select:after {
          top: 12px;
        }
      }
    }
  }

  .item-actions {
    display: flex;
    flex-basis: 100%;
    align-items: flex-end;

    > .Field {
      padding: 0;
      margin-right: 16px;
      margin-bottom: 0;

      .CurrencyField .input-group {
        height: 40px;

        .input-group-addon {
          font-family: 'Roboto', sans-serif;
          font-size: 16px;
          line-height: 24px;
          color: ${(props) => props.theme.gray2};
          border-radius: 6px 0 0 6px;
        }

        input {
          height: 40px;
          border-radius: 0 6px 6px 0;
        }
      }
    }

    .DropdownButton {
      .Button {
        width: 51px;
        height: 40px;
        justify-content: center;
      }

      .Button:not(:hover) {
        color: ${(props) => props.theme.gray2};
      }
    }

    @media screen and (min-width: 768px) {
      flex-basis: 40%;

      > .Field {
        > label {
          display: none;
        }
      }
    }
  }

  .item-discount {
    display: flex;
    flex-basis: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px 16px 24px;
    margin-top: 16px;
    background-color: ${(props) => props.theme.gray14};
    border: solid 1px ${(props) => props.theme.gray12};
    border-radius: 8px;

    > .Field {
      padding: 0;
      margin-bottom: 0;
      flex-basis: 75%;

      .CurrencyField,
      .PercentField {
        .input-group-addon {
          display: none;
        }

        input {
          width: 80px;
        }
      }

      // Hide number control up and down arrow
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }

      /* Firefox */
      input[type='number'] {
        -moz-appearance: textfield;
      }

      select {
        width: auto;
        padding-left: 6px;
        padding-right: 0;
      }

      @media screen and (min-width: 768px) {
        input {
          width: 70%;
        }
      }
    }
  }
`;
