import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as FilterActions from 'store/sponte/actions';

import SponteFiltersForm from 'pages/employees/sponte/filters/form';
import PageContainer from 'components/PageContainer';
import normalizeParams from 'pages/employees/sponte/filters/normalizeParams';
import Loader from 'components/Loader/index';
import Toast from 'components/Toast';

import './style.scss';

const SponteFilters = (props) => {
  const {
    fetchSponteFiltersRequest,
    updateSponteFiltersRequest,
    setSchoolTerms,
    data,
    canChange,
    initialValues,
    loading,
    sent,
  } = props;

  useEffect(() => {
    fetchSponteFiltersRequest();
  }, []);

  const onSubmit = useCallback(
    (values) => {
      updateSponteFiltersRequest(normalizeParams(values.form.data));
    },
    [updateSponteFiltersRequest]
  );

  const handleSchoolTermSelect = useCallback(
    (key) => {
      setSchoolTerms(key.selectedValues);
    },
    [setSchoolTerms]
  );

  const handlers = {
    schoolTerm: handleSchoolTermSelect,
  };

  return (
    <div className="SponteFilters">
      <Loader isLoading={loading}>
        <PageContainer title="Configurar integração">
          <SponteFiltersForm
            filters={data}
            initialValues={initialValues}
            sent={sent}
            canChange={canChange}
            handleSubmit={onSubmit}
            handlers={handlers}
          />
        </PageContainer>
        <Toast />
      </Loader>
    </div>
  );
};

const mapStateToProps = (state) => ({
  data: state.sponteFilters,
  loading: state.sponteFilters.loading,
  sent: state.sponteFilters.sent,
  canChange: state.sponteFilters.canChange,
  initialValues: state.sponteFilters.initialValues,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(FilterActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SponteFilters);
