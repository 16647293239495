import createModuleReducer from '../storeConfig/createModuleReducer';
import actions from './actions';

export const INITIAL_STATE = {
  error: null,
  isLoading: false,
  multimediaConsumption: {},
  schoolBills: {},
};

export const handleError = (state, action) => ({
  ...state,
  error: action.error,
});

export const fetchRequest = (state) => ({
  ...state,
  isLoading: true,
});

export const fetchMultimediaConsumptionSuccess = (state, action) => ({
  ...state,
  isLoading: false,
  multimediaConsumption: action.data,
});

export const fetchSchoolBillsSuccess = (state, action) => ({
  ...state,
  isLoading: false,
  schoolBills: action.data,
});

const HANDLERS = {
  [actions.ERROR]: handleError,
  [actions.FETCH_MULTIMEDIA_CONSUMPTION_REQUEST]: fetchRequest,
  [actions.FETCH_MULTIMEDIA_CONSUMPTION_SUCCESS]: fetchMultimediaConsumptionSuccess,
  [actions.FETCH_SCHOOL_BILLS_REQUEST]: fetchRequest,
  [actions.FETCH_SCHOOL_BILLS_SUCCESS]: fetchSchoolBillsSuccess,
};

const myAccount = createModuleReducer(INITIAL_STATE, HANDLERS);

export default myAccount;
