import React, { useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import {
  RECURRENT_BILL_STATUSES,
  SEARCH_TYPE,
  IMAGES_PAYMENT_URL,
} from 'core/constants/index';

import Button from 'components/Button';
import EmptyState from 'components/EmptyState';
import PageTitle from 'components/PageTitle';
import PageSubTitle from 'components/PageSubTitle';
import RecurrentFilters from 'components/Payments/PaymentsContainer/RecurrentFilters';
import RecurrentPlansList from 'components/Payments/Recurrent/RecurrentPlansList';
import OutlineBox from 'components/OutlineBox';

import actionRecurrentPlans from 'store/edupay/recurrentPlans/actions';

import './style.scss';

const RecurrentPlansListContainer = () => {
  const dispatch = useDispatch();

  const { fetchRecurrentPlans } = actionRecurrentPlans;

  const {
    policies,
    currentUser: {
      attributes: { is_debug_user: isDebugUser },
    },
  } = useSelector((state) => state.root);
  const { hasRecurrentPlans, filters, activeFiltersCount } = useSelector(
    (state) => state.recurrentPlans
  );
  const { currentWallet } = useSelector((state) => state.wallets);

  const filterRecurrentBillStatuses = (item) => {
    return (
      item.value !== 'pending_school_approval' &&
      item.value !== 'pending_bordero_approval'
    );
  };

  const form = {
    range: {
      startDate: moment().startOf('month').format('YYYY-MM-DD'),
      endDate: moment().endOf('month').format('YYYY-MM-DD'),
    },
  };

  const formMeta = {
    filters,
    select_options: {
      status:
        currentWallet.type === 'securitizer' || isDebugUser
          ? RECURRENT_BILL_STATUSES
          : RECURRENT_BILL_STATUSES.filter(filterRecurrentBillStatuses),
      recurrent_search_type: SEARCH_TYPE,
    },
    amount: activeFiltersCount,
  };

  const getRecurrentPlans = useCallback(
    (params) => {
      dispatch(fetchRecurrentPlans(params));
    },
    [dispatch, fetchRecurrentPlans]
  );

  const filterRecurrentPlans = useCallback(
    (params) => {
      getRecurrentPlans({ ...filters, ...params });
    },
    [getRecurrentPlans, filters]
  );

  useEffect(() => {
    if (currentWallet === null) {
      return;
    }

    const params = {
      range: {
        startDate: moment().startOf('month').format('YYYY-MM-DD'),
        endDate: moment().endOf('month').format('YYYY-MM-DD'),
      },
      page: 1,
    };

    getRecurrentPlans(params);
  }, [getRecurrentPlans, currentWallet]);

  return (
    <div className="RecurrentPlansListContainer">
      {currentWallet.type === 'securitizer' && (
        <OutlineBox className="guaranteed-monthly-fee-message">
          Sua escola aderiu ao Mensalidade Garantida e, por isso, não é possível
          criar e editar cobranças dentro desta carteira. Você pode
          visualizá-las e gerar relatórios normalmente. Em caso de dúvidas,
          entre em contato com a gerência financeira responsável ou o suporte da
          Agenda Edu.
        </OutlineBox>
      )}
      <RecurrentFilters
        form={form}
        formMeta={formMeta}
        handleFilterSubmit={filterRecurrentPlans}
      />
      {!hasRecurrentPlans ? (
        <div className="RecurrentPlansListContainer">
          <EmptyState
            imgUrl={IMAGES_PAYMENT_URL.onboardingUrl}
            message={
              <React.Fragment>
                <div className="vertically-aligned flex-column">
                  <PageTitle>
                    Experimente criar agora sua primeira cobrança recorrente
                  </PageTitle>
                  <PageSubTitle>Leva menos de 2 minutos.</PageSubTitle>
                  {policies && policies.can_create_school_product && (
                    <Link
                      to="/schools/recurrent/recurrent_plans/new"
                      className="action"
                    >
                      <Button className="btn-lg">Comece agora</Button>
                    </Link>
                  )}
                </div>
              </React.Fragment>
            }
          />
        </div>
      ) : (
        <RecurrentPlansList />
      )}
    </div>
  );
};

export default RecurrentPlansListContainer;
