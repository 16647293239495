import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import AgendaIcon from 'components/AgendaIcon';
import Tooltip, { TOP_CENTER } from 'components/Tooltip';
import withFormContext, { formPropTypes } from 'core/hoc/withFormContext';
import withAppContext from 'core/hoc/withAppContext';
import FormValidationErrors from 'components/Form/ValidationErrors';
import * as S from './styles';

const CopyField = ({
  attributeName,
  onChange,
  placeholder,
  disabled,
  readonly,
  beforeCopy,
  formContext,
  icon,
}) => {
  const [copySuccess, setCopySuccess] = useState(false);
  const [onFocused, setOnFocused] = useState();
  const { form, changeAttribute, hasErrorOnAttribute } = formContext;
  const inputRef = useRef(null);

  const hasError = hasErrorOnAttribute(attributeName);

  const handleChange = () => {
    if (onChange) {
      return changeAttribute(attributeName, onChange);
    } else {
      return changeAttribute(attributeName);
    }
  };

  const onCloseTooltip = () => {
    setTimeout(() => {
      setCopySuccess(false);
    }, 3000);
  };

  const copyToClipboard = (event) => {
    if (beforeCopy) beforeCopy();

    inputRef?.current.select();
    document.execCommand('copy');
    event?.target.focus();
    setCopySuccess(true);
    onCloseTooltip();
  };

  const onFocus = () => {
    setOnFocused(true);
    copyToClipboard();
  };

  const onBlur = () => {
    setOnFocused(false);
  };

  return (
    <S.Container className="FormCopyField input-group">
      <div className="input-group-addon">
        <Tooltip
          content={'Copiado!'}
          position={TOP_CENTER}
          isOpen={copySuccess}
        >
          <AgendaIcon
            className={onFocused ? 'iconFocus' : ''}
            name={icon ? icon : 'link'}
            size="medium"
          />
        </Tooltip>
      </div>
      <input
        ref={inputRef}
        type="text"
        className={`form-control ${hasError ? 'has-error' : ''}`}
        name={attributeName}
        placeholder={placeholder}
        disabled={disabled}
        value={form[attributeName] || ''}
        onChange={!readonly ? handleChange() : null}
        onFocus={onFocus}
        onBlur={onBlur}
      />
      {hasError && <FormValidationErrors attributeName={attributeName} />}
    </S.Container>
  );
};

CopyField.propTypes = {
  attributeName: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  readonly: PropTypes.bool,
  beforeCopy: PropTypes.func,
  appContext: PropTypes.shape({
    primaryColor: PropTypes.string,
  }),
  icon: PropTypes.string,
  ...formPropTypes,
};

export default withAppContext(withFormContext(CopyField));
