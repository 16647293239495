import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Currency from 'components/Currency';
import autobind from 'autobind-decorator';
import * as S from './styles';
class TaxTransfer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      taxTransfer: props.taxTransfer,
      bankCode: props.bankCode,
    };
  }

  @autobind
  isFreeTaxBank() {
    const BRADESCO_CODE = '237';
    return this.state.bankCode === BRADESCO_CODE ? true : false;
  }

  render() {
    const { taxTransfer } = this.state;

    return (
      <div>
        {!this.isFreeTaxBank() ? (
          <S.Container>
            <S.Label>Taxa de transferência(TED)</S.Label>
            <S.Label>
              <Currency value={taxTransfer} />
            </S.Label>
          </S.Container>
        ) : null}
      </div>
    );
  }
}

TaxTransfer.propTypes = {
  taxTransfer: PropTypes.number.isRequired,
  bankCode: PropTypes.string.isRequired,
};

export default TaxTransfer;
