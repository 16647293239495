import React from 'react';

export default [
  {
    Header: 'Data',
    accessor: (history) => `${history.data.attributes.created_at}`,
  },
  {
    Header: 'Usuário',
    accessor: (history) => `${history.data.attributes.whodunnit}`,
  },
  {
    Header: 'Ação',
    accessor: (history) => (
      <div
        dangerouslySetInnerHTML={{ __html: history.data.attributes.action }}
      />
    ),
  },
];
