import React from 'react';
import * as S from './styles';

import { FooterToastProps } from './types';
import { Text } from '@agendaedu/ae-web-components';

const FooterToast = ({ message, variant, handleOnClose }: FooterToastProps) => {
  const toastTypes = {
    edit: {
      id: 'footer-toast-edit',
      icon: 'pencil',
      message: 'Editar mensagem',
    },
    forward: {
      id: 'footer-toast-forward',
      icon: 'forward',
      message: 'Redirecionar mensagem',
    },
    reply: {
      id: 'footer-toast-reply',
      icon: 'reply',
      message: 'Responder mensagem',
    },
  };

  return (
    <S.FooterToastWrapper data-testid={toastTypes[variant].id}>
      <S.Wrapper>
        <S.MessageWrapper>
          <S.ActionIcon name={toastTypes[variant].icon} />
          <S.MessageText>{toastTypes[variant].message}</S.MessageText>
        </S.MessageWrapper>
        {variant === 'reply' && message && (
          <S.ReplyMessageWrapper>
            <S.MessageInformationWrapper>
              <Text variant="body-bold-14" color={'neutral.black'}>
                {message.attributes.user?.attributes.name}
              </Text>

              <Text variant="body-regular-14" color={'neutral.gray1'}>
                {message.attributes.text}
              </Text>
            </S.MessageInformationWrapper>
          </S.ReplyMessageWrapper>
        )}
      </S.Wrapper>

      <S.CloseWrapper data-testid="close-toast-button" onClick={handleOnClose}>
        <S.CloseButton name="close" />
      </S.CloseWrapper>
    </S.FooterToastWrapper>
  );
};

export default FooterToast;
