import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import smoothScrollTo from 'core/utils/smoothScrollTo';
import { IMAGES_PAYMENT_URL } from 'core/constants/index';

import Loader from 'components/Loader';
import EmptyState from 'components/EmptyState';
import PageContainer from 'components/PageContainer';
import Button from 'components/Button';
import Title from 'components/WizardForm/Title';
import Table from 'components/Table';
import Pagination from 'components/Pagination';
import ButtonRow from 'components/ButtonRow';
import ModalContainer from 'components/Modal/ModalContainer';
import ModalTitle from 'components/Modal/ModalTitle';
import Toast from 'components/Toast';
import RecurrentProductForm from 'components/Payments/Recurrent/RecurrentProductForm';

import actionRecurrentProducts from 'store/edupay/recurrentProducts/actions';

import columns from './table';
import './style.scss';

const RecurrentProductsList = () => {
  const dispatch = useDispatch();

  const currentProduct = useSelector(
    (state) => state.recurrentProducts.current
  );
  const modalOpen = useSelector((state) => state.recurrentProducts.modalOpen);
  const modalDeleteOpen = useSelector(
    (state) => state.recurrentProducts.modalDeleteOpen
  );
  const isLoading = useSelector((state) => state.recurrentProducts.isLoading);
  const paginate = useSelector((state) => state.recurrentProducts.paginate);
  const recurrentProducts = useSelector(
    (state) => state.recurrentProducts.recurrentProducts
  );
  const {
    fetchRecurrentProducts,
    toggleModal,
    toggleDeleteModal,
    setRecurrentProduct,
    unselect,
    disableRecurrentProduct,
  } = actionRecurrentProducts;

  const getRecurrentProducts = useCallback(() => {
    dispatch(fetchRecurrentProducts());
  }, [fetchRecurrentProducts]);

  useEffect(() => {
    getRecurrentProducts();
  }, []);

  const handlePageChange = useCallback((page) => {
    const params = { page };
    dispatch(fetchRecurrentProducts(params));
    smoothScrollTo(0, 0);
  }, []);

  const openCreateModal = useCallback(
    (id) => {
      dispatch(unselect());
    },
    [unselect]
  );

  const openEditModal = useCallback(
    (id) => {
      dispatch(setRecurrentProduct(id));
      dispatch(toggleModal());
    },
    [setRecurrentProduct, toggleModal]
  );

  const openDeleteModal = useCallback(
    (id) => {
      dispatch(setRecurrentProduct(id));
      dispatch(toggleDeleteModal());
    },
    [setRecurrentProduct, toggleDeleteModal]
  );

  const toggleCreateOrEditModal = useCallback(() => {
    dispatch(toggleModal());
  }, [toggleModal]);

  const toggleDelete = useCallback(() => {
    dispatch(toggleDeleteModal());
  }, [toggleDeleteModal]);

  const deleteProduct = useCallback(
    (id) => {
      dispatch(disableRecurrentProduct(id));
    },
    [toggleDeleteModal, disableRecurrentProduct]
  );

  const prepareProducts = () =>
    recurrentProducts.map((product) => {
      product.actions = {};
      product.actions.openEditModal = () => openEditModal(product.id);
      product.actions.openDeleteModal = () => openDeleteModal(product.id);

      return product;
    });

  if (isLoading) {
    return (
      <div className="PageContainer RecurrentProductsList centered">
        <div className="container-content">
          <Loader />
        </div>
      </div>
    );
  }

  return (
    <PageContainer
      title="Produtos"
      className="RecurrentProductsList"
      header={<Button onClick={openCreateModal}>NOVO PRODUTO</Button>}
      variation="centered"
    >
      <Title name="Produtos das cobranças" />
      <p>
        Cadastre produtos e serviços disponibilizados por sua escola, que
        poderão ser selecionados na criação de cobranças recorrentes.
      </p>
      {recurrentProducts.length ? (
        <React.Fragment>
          <Table data={prepareProducts()} columns={columns} />
          <Pagination
            onChange={handlePageChange}
            activePage={paginate.page}
            totalItemsCount={paginate.totalItemsCount}
            itemsCountPerPage={paginate.itemsCountPerPage}
          />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <EmptyState
            imgUrl={IMAGES_PAYMENT_URL.recurrentProductUrl}
            message={<p>Não há nenhum produto cadastrado</p>}
          />
        </React.Fragment>
      )}
      <RecurrentProductForm
        modalOpen={modalOpen}
        toggleModal={toggleCreateOrEditModal}
        product={currentProduct}
      />
      <ModalContainer
        isOpen={modalDeleteOpen}
        toggleModal={toggleDelete}
        className="DeleteRecurrentProductModal"
      >
        <ModalTitle>Excluir produto</ModalTitle>
        <p>
          Você tem certeza que deseja excluir o produto{' '}
          <strong>
            {`${currentProduct && currentProduct.attributes.name}`}
          </strong>
        </p>
        <ButtonRow>
          <Button variation="secondary" onClick={toggleDelete}>
            Fechar
          </Button>
          <Button
            variation="primary"
            onClick={() => deleteProduct(currentProduct.id)}
          >
            Excluir
          </Button>
        </ButtonRow>
      </ModalContainer>
      <Toast />
    </PageContainer>
  );
};

export default RecurrentProductsList;
