import createModuleReducer from '../storeConfig/createModuleReducer';
import actions from './actions';

export const INITIAL_STATE = {
  data: {
    schoolTerms: { filters: [], selected: [] },
    headquarters: { filters: [], selected: [] },
    courses: { filters: [], selected: [] },
  },
  initialValues: {
    schoolTerms: { filters: [], selected: [] },
    headquarters: { filters: [], selected: [] },
    courses: { filters: [], selected: [] },
  },
  loading: false,
  canChange: false,
  sent: false,
  error: null,
  provider: null
};

export const fetchRequest = state => ({
  ...state,
  loading: true,
});

export const fetchSuccess = (state, action) => {
  return {
    ...state,
    data: {
      id: action.data.id,
      schoolTerms: action.data.attributes.school_terms,
      headquarters: action.data.attributes.headquarters,
      courses: action.data.attributes.courses,
    },
    initialValues: {
      id: action.data.id,
      schoolTerms: action.data.attributes.school_terms,
      headquarters: action.data.attributes.headquarters,
      courses: action.data.attributes.courses,
    },
    loading: false,
  };
};

export const fetchError = (state, action) => {
  return {
    ...state,
    loading: false,
    sent: false,
    error: action.error,
  };
};

export const setHeadquarters = (state, action) => {
  const headquarters = {
    filters: state.initialValues.headquarters.filters,
    selected: action.filters,
  };

  return {
    ...state,
    data: {
      headquarters,
      courses: state.data.courses,
      schoolTerms: state.data.schoolTerms,
    },
  };
};

export const setCourses = (state, action) => {
  const courses = {
    filters: state.initialValues.courses.filters,
    selected: action.filters,
  };

  return {
    ...state,
    data: {
      courses,
      headquarters: state.data.headquarters,
      schoolTerms: state.data.schoolTerms,
    },
  };
};

export const setSchoolTerms = (state, action) => {
  const schoolTerms = {
    filters: state.initialValues.schoolTerms.filters,
    selected: action.filters,
  };

  return {
    ...state,
    data: {
      schoolTerms,
      headquarters: state.data.schoolTerms,
      courses: state.data.courses,
    },
  };
};

export const updateRequest = state => ({
  ...state,
  canChange: false,
  sent: true,
});

export const updateSuccess = (state, action) => {
  return {
    ...state,
    data: {
      id: action.data.id,
      schoolTerms: action.data.attributes.school_terms,
      headquarters: action.data.attributes.headquarters,
      courses: action.data.attributes.courses,
    },
    sent: false,
  };
};

const HANDLERS = {
  [actions.FETCH_REQUEST]: fetchRequest,
  [actions.FETCH_SUCCESS]: fetchSuccess,
  [actions.FETCH_ERROR]: fetchError,
  [actions.SET_HEADQUARTERS]: setHeadquarters,
  [actions.SET_COURSES]: setCourses,
  [actions.SET_SCHOOL_TERMS]: setSchoolTerms,
  [actions.UPDATE_REQUEST]: updateRequest,
  [actions.UPDATE_SUCCESS]: updateSuccess,
};

const integrations = createModuleReducer(INITIAL_STATE, HANDLERS);

export default integrations;
