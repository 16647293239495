import validatePresence from 'core/lib/FormValidator/validators/presence';
import validateZipcode from 'core/lib/FormValidator/validators/zipcode';
import validateCnpj from 'core/lib/FormValidator/validators/cnpj';
import validateLength from 'core/lib/FormValidator/validators/length';
import validateEmail from 'core/lib/FormValidator/validators/email';
import validateCpf from "core/lib/FormValidator/validators/cpf";

const validations = [
  {
    attributeName: 'school_name',
    validator: validatePresence,
    message: 'Campo obrigatório',
  },
  {
    attributeName: 'phone',
    validator: validatePresence,
    message: 'Campo obrigatório',
  },
  {
    attributeName: 'document_number',
    validator: validateCnpj,
    message: 'Insira um CNPJ válido',
  },
  {
    attributeName: 'legal_name',
    validator: validatePresence,
    message: 'Campo obrigatório',
  },
  {
    attributeName: 'legal_name',
    validator: validateLength({ min: 3 }),
    message: 'Mínimo: 3 caracteres',
  },
  {
    attributeName: 'legal_name',
    validator: validateLength({ max: 30 }),
    message: 'Máximo: 30 caracteres',
  },
  {
    attributeName: 'date_of_birth',
    validator: validatePresence,
    message: 'Campo obrigatório',
  },
  {
    attributeName: 'cep',
    validator: validateZipcode,
    message: 'Campo obrigatório',
  },
  {
    attributeName: 'street',
    validator: validatePresence,
    message: 'Campo obrigatório',
  },
  {
    attributeName: 'street_number',
    validator: validatePresence,
    message: 'Campo obrigatório',
  },
  {
    attributeName: 'state',
    validator: validatePresence,
    message: 'Campo obrigatório',
  },
  {
    attributeName: 'city',
    validator: validatePresence,
    message: 'Campo obrigatório',
  },
  {
    attributeName: 'neighborhood',
    validator: validatePresence,
    message: 'Campo obrigatório',
  },
  {
    attributeName: 'email',
    validator: validatePresence,
    message: 'Campo obrigatório',
  },
  {
    attributeName: 'email',
    validator: validateEmail,
    message: 'Insira um E-mail válido',
  },
  {
    attributeName: 'admin_document_number',
    validator: validatePresence,
    message: 'Campo obrigatório',
  },
  {
    attributeName: 'admin_document_number',
    validator: validateCpf,
    message: 'Insira um CPF válido',
  },
];

export default validations;
