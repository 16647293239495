import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    position: relative;

    .input-group-addon {
      position: absolute;
      padding: 0;
      top: 6px;
      left: 8px;
      background-color: transparent;
      border: none;
      color: ${theme.gray3};
    }

    .form-control {
      padding-left: 38px;
      border-radius: 8px;
      color: ${theme.gray3};

      &:focus {
        color: ${theme.primaryColor};
      }

      &:hover {
        border-color: ${theme.primaryColor};
      }

      &:last-child {
        border-radius: 8px;
      }

      &.has-error {
        border-radius: 8px !important;
        border-color: ${theme.danger};
      }
    }

    .iconFocus {
      color: ${theme.primaryColor};
      transition: ease-in-out 0.15s;
    }

    .Tooltip {
      padding-top: 4px;
      width: 30px;
    }
  `}
`;
