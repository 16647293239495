import createModuleReducer from '../storeConfig/createModuleReducer';
import actions from './actions';

export const INITIAL_STATE = {
  errors: null,
  isLoading: false,
  isOnboardingCompleted: false,
  schoolOnboardings: {},
};

const setError = (state, action) => ({
  ...state,
  error: action.error,
  isLoading: false,
});

const fetchSchoolOnboardings = (state) => ({
  ...state,
  isLoading: true,
});

const fetchSchoolOnboardingsSuccess = (state, action) => {
  const { data } = action;

  const { completed_tasks } = data.attributes;

  const isAllTasksCompleted =
    completed_tasks.school_user.status == 'finished' &&
    completed_tasks.student_profile.status == 'finished' &&
    completed_tasks.message.status == 'finished';

  return {
    ...state,
    isLoading: false,
    schoolOnboardings: data,
    isOnboardingCompleted: isAllTasksCompleted,
  };
};

const HANDLERS = {
  [actions.SET_ERROR]: setError,
  [actions.FETCH_SCHOOL_ONBOARDINGS]: fetchSchoolOnboardings,
  [actions.FETCH_SCHOOL_ONBOARDINGS_SUCCESS]: fetchSchoolOnboardingsSuccess,
};

const onboarding = createModuleReducer(INITIAL_STATE, HANDLERS);

export default onboarding;
