import styled, { css } from 'styled-components';
import Tooltip from 'components/Tooltip';

export const Wrapper = styled.div`
  ${() => css`
    .FormTextField .TextField input {
      height: 40px;
      border-radius: 6px;
    }
  `}
`;

export const CustomLabel = styled.div`
  display: flex;
  align-items: center;
  > span {
    margin-right: 2px !important;
  }
`;

export const CustomTooltip = styled(Tooltip)`
  .tooltip-div {
    position: relative;
    width: 16px;
    height: 16px;
  }
`;
