import tinycolor from 'tinycolor2';

let cache = {};

const cacheKey = (color, alpha) => `${color}-${alpha}`;

function colorWithAlpha(color, alpha) {
  const key = cacheKey(color, alpha);

  if(!(key in cache)) {
    cache[key] = tinycolor(color).setAlpha(alpha).toString();
  }

  return cache[key];
}

export default colorWithAlpha;
