import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';

import { EMPTY_USER } from 'core/constants/index';

import Avatar from 'components/Avatar';
import Button from 'components/Button';
import HistoryModal from 'components/HistoryModal';

import './style.scss';

const HistorySection = ({
  requestFunction,
  versionsSelector,
  lastVersionSelector,
  paginationSelector,
}) => {
  const dispatch = useDispatch();

  const surveyVersions = useSelector(versionsSelector);
  const lastVersion = useSelector(lastVersionSelector);
  const versionsPagination = useSelector(paginationSelector);

  const [modalOpen, setModalOpen] = useState(false);

  const onToggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const callFetchHistory = useCallback(
    (page = 1) => {
      dispatch(
        requestFunction(
          versionsPagination.currentPage
            ? versionsPagination.currentPage + 1
            : page
        )
      );
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, requestFunction]
  );

  useEffect(() => {
    callFetchHistory(versionsPagination.page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hasMoreItems = () => {
    if (versionsPagination) {
      const { page, itemsCountPerPage, totalItemsCount } = versionsPagination;
      return page * itemsCountPerPage < totalItemsCount;
    }
    return false;
  };

  const resetVersions = () => {
    callFetchHistory(1);
  };

  const renderLastVersionCard = () => {
    const { data } = lastVersion.attributes.school_user || EMPTY_USER;

    const createdAt = () => {
      const start = moment(
        lastVersion.attributes.created_at,
        'DD/MM/YYYY HH:mm'
      );
      const diff = start.valueOf() - moment().valueOf();

      return moment()
        .add(diff)
        .calendar(null, { sameElse: 'DD/MM/YYYY [às] HH:mm' });
    };

    const customStyle = !data.id
      ? { backgroundColor: '#C2C2C2', color: '#DDD' }
      : null;

    return (
      <div className="card">
        <div className="avatar-user">
          <Avatar user={data} customStyle={customStyle} />
        </div>
        <div className="info-area">
          <p className="user-name">
            {lastVersion.attributes.event} por {data.attributes.name}
          </p>
          <p className="survey-created-at">{createdAt()}</p>
        </div>
      </div>
    );
  };

  return (
    <>
      <section className="history-info-area">
        {lastVersion && (
          <>
            {renderLastVersionCard()}
            <div className="card">
              <div className="button-open-history">
                <Button icon="history" onClick={onToggleModal}>
                  <span className="button-name">Histórico</span>
                </Button>
              </div>
            </div>
          </>
        )}
      </section>
      <HistoryModal
        isOpen={modalOpen}
        toggleModal={onToggleModal}
        list={surveyVersions}
        page={versionsPagination.page}
        hasMore={hasMoreItems()}
        resetHistory={resetVersions}
        fetchHistory={callFetchHistory}
      />
    </>
  );
};

HistorySection.propTypes = {
  requestFunction: PropTypes.func.isRequired,
  versionsSelector: PropTypes.func.isRequired,
  lastVersionSelector: PropTypes.func.isRequired,
  paginationSelector: PropTypes.func.isRequired,
};

export default HistorySection;
