import React, { useState, useEffect } from 'react';
import { SearchProps } from './types';

import AgendaIcon from 'components/AgendaIcon';
import Field from 'components/Form/Field';

import './styles.scss';

const Search = ({
  label,
  attributeName,
  placeholder,
  withSelect = false,
  selectOptions,
  onSubmit,
  ...rest
}: SearchProps) => {
  const [showClearButton, setShowClearButton] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const [searched, setSearched] = useState('');

  const handleChangeSearch = (searched) => {
    setShowClearButton(!!searched);
    setSearched(searched);
  };

  const handleClearButton = () => {
    setSearched('');
    setShowClearButton(false);
    onSubmit({ [attributeName]: '' });
  };

  const handleSearchButton = () => {
    onSubmit({ [attributeName]: searched, selectedOption });
  };

  useEffect(() => {
    if (withSelect) setSelectedOption(selectOptions[0].value);
  }, []);

  return (
    <div className="searchWrapper">
      {!!label && <label>{label}</label>}
      <div className="searchWithSelectWrapper">
        {withSelect && (
          <div className="selectOptions">
            <Field
              attributeName="status"
              type="selectWithIcon"
              value={null}
              options={selectOptions}
              onChange={(e) => setSelectedOption(e.value)}
              fullWidth
              withArrowSelect
              controll
            />
          </div>
        )}
        <div className={`inputWrapper ${withSelect && 'withSelect'}`}>
          <input
            {...rest}
            type="text"
            placeholder={placeholder}
            value={searched}
            onChange={(event) => handleChangeSearch(event.target.value)}
            onKeyPress={(event) => {
              if (event.key === 'Enter') handleSearchButton();
            }}
          />
          <AgendaIcon
            name="close"
            className={showClearButton ? 'close-icon' : 'close-icon-hidden'}
            onClick={handleClearButton}
          />
          <AgendaIcon
            className="search-icon"
            name="search"
            onClick={handleSearchButton}
          />
        </div>
      </div>
    </div>
  );
};

export default Search;
