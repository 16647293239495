import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import axios from 'axios';

import {
  fetchApi,
  postApi,
  putApi,
  patchApi,
  handleError,
} from 'core/utils/api';
import {
  buildToast,
  toastCss,
  ToastTypes,
} from 'store/middlewares/toast/actions';
import { push } from 'connected-react-router';

import actions from './actions';

export function* fetchRecurrentPlans(action) {
  try {
    const dataArea = yield select((state) => state.root.dataArea);

    const { data, meta, itemsCountPerPage, totalItemsCount } = yield call(
      fetchApi,
      `/${dataArea}/recurrent/recurrent_plans.json`,
      { params: action.params }
    );

    yield put({
      type: actions.FETCH_SUCCESS,
      paginate: { itemsCountPerPage, totalItemsCount },
      filters: { ...action.params },
      balance: meta.balance,
      hasRecurrentPlans: meta.has_recurrent_plans,
      data,
    });
  } catch (error) {
    yield put({ type: actions.ERROR, error });
  }
}

export function* addRecurrentPlan(action) {
  try {
    const dataArea = yield select((state) => state.root.dataArea);

    const { data } = yield call(
      postApi,
      `/${dataArea}/recurrent/recurrent_plans`,
      { recurrent_plan: action.params }
    );

    yield put(push('/schools/school_products/tab/recurrent'));

    yield put({
      type: actions.ADD_RECURRENT_PLAN_SUCCESS,
      data,
      toast: buildToast(
        'Plano cadastrado com sucesso.',
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        handleError(error),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* setPlanRequest(action) {
  try {
    const dataArea = yield select((state) => state.root.dataArea);

    const { data } = yield call(
      fetchApi,
      `/${dataArea}/recurrent/recurrent_plans/${action.id}.json`
    );

    yield put({
      type: actions.SET_PLAN_SUCCESS,
      data,
    });
  } catch (error) {
    yield put({ type: actions.ERROR, error });
  }
}

export function* setBillrequest(action) {
  try {
    const dataArea = yield select((state) => state.root.dataArea);

    const { data } = yield call(
      fetchApi,
      `/${dataArea}/recurrent/recurrent_plans/${action.planId}/recurrent_bills/${action.billId}.json`
    );
    yield put({
      type: actions.SET_BILL_PLAN_SUCCESS,
      data,
    });
  } catch (error) {
    yield put({ type: actions.ERROR, error });
  }
}

export function* editRecurrentPlan(action) {
  try {
    const dataArea = yield select((state) => state.root.dataArea);

    const { data } = yield call(
      putApi,
      `/${dataArea}/recurrent/recurrent_plans/${action.params.id}`,
      { recurrent_plan: action.params }
    );

    yield put(push(`/schools/recurrent/recurrent_plans/${action.params.id}`));

    yield put({
      type: actions.EDIT_RECURRENT_PLAN_SUCCESS,
      data,
      toast: buildToast(
        'Plano Atualizado com sucesso.',
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        handleError(error),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* setVersionsRequest(action) {
  try {
    const dataArea = yield select((state) => state.root.dataArea);

    const { history, itemsCountPerPage, totalItemsCount } = yield call(
      fetchApi,
      `/${dataArea}/recurrent/recurrent_plans/${action.id}/history.json`,
      { params: action.params }
    );

    yield put({
      type: actions.SET_VERSIONS_SUCCESS,
      history,
      paginate: { ...action.params, itemsCountPerPage, totalItemsCount },
    });
  } catch (error) {
    yield put({ type: actions.ERROR, error });
  }
}

export function* cancelPlan(action) {
  try {
    const dataArea = yield select((state) => state.root.dataArea);

    const { data } = yield call(
      patchApi,
      `/${dataArea}/recurrent/recurrent_plans/${action.id}/cancel`,
      { params: action.params }
    );

    yield put({
      type: actions.CANCEL_PLAN_SUCCESS,
      data,
      toast: buildToast(
        'Plano cancelado com sucesso.',
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      toast: buildToast('Não foi possivel cancelar o plano', ToastTypes.error),
      error,
    });
  }
}

export function* fetchRecipients(_action) {
  try {
    const dataArea = yield select((state) => state.root.dataArea);

    const response = yield axios.get(`/${dataArea}/messages/classrooms.json`);

    yield put({
      type: actions.FETCH_RECIPIENTS_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({ type: actions.ERROR, error });
  }
}

export function* fetchStudents(action) {
  try {
    const dataArea = yield select((state) => state.root.dataArea);

    const response = yield axios.get(
      `/${dataArea}/recurrent/recurrent_plans/${action.planId}/students.json`
    );

    yield put({
      type: actions.FETCH_STUDENTS_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({ type: actions.ERROR, error });
  }
}

export function* fetchRecurrentInBatchExtraInformations(action) {
  try {
    const { dataArea } = yield select((state) => state.root);

    const { data } = yield axios.get(
      `/${dataArea}/recurrent/recurrent_bills/edit_in_batches.json`,
      {
        params: action.params,
      }
    );

    const {
      data: recurrentInBatchInformations,
      meta: {
        select_options: { recipient_wallet_id: recipientsWallet },
      },
    } = data;

    yield put({
      type: actions.FETCH_IN_BATCH_EXTRA_INFORMATION_SUCCESS,
      recipientsWallet,
      recurrentInBatchInformations,
    });
  } catch (error) {
    yield put({ type: actions.ERROR, error });
  }
}

export function* editRecurrentBillInBatches(action) {
  try {
    const { dataArea } = yield select((state) => state.root);

    yield call(
      putApi,
      `/${dataArea}/recurrent/recurrent_bills/update_in_batches`,
      { recurrent_bill: action.params }
    );

    yield put(push('/schools/school_products/tab/recurrent'));

    yield put({
      type: actions.EDIT_RECURRENT_BILL_IN_BATCHES_SUCCESS,
      toast: buildToast(
        'Cobranças atualizadas com sucesso.',
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        handleError(error),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* setSelectALlRecurrentRequest(action) {
  try {
    const { dataArea } = yield select((state) => state.root);

    const {
      data: {
        recurrent_bill_ids: recurrentBillIds,
        total: totalIdsElegibleToEdit,
      },
    } = yield call(
      fetchApi,
      `/${dataArea}/recurrent/recurrent_bills/elegible_to_edit.json`,
      { params: action.params }
    );

    yield put({
      type: actions.SET_SELECT_ALL_RECURRENT_SUCCESS,
      recurrentBillIds,
      totalIdsElegibleToEdit,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
    });
  }
}

export function* fetchResendBillInformation(action) {
  try {
    const { dataArea } = yield select((state) => state.root);

    const {
      data: { ids, count, resend_emails_enabled: resendEmailsEnabled },
    } = yield call(
      fetchApi,
      `/${dataArea}/recurrent/recurrent_plans/retrieve_billings_to_resend.json`,
      { params: action.rangeDate }
    );

    yield put({
      type: actions.FETCH_RESEND_BILLS_INFORMATION_SUCCESS,
      ids,
      count,
      resendEmailsEnabled,
    });

    if (!resendEmailsEnabled) {
      yield put({
        toast: buildToast(
          'Não há boletos elegíveis para reenvios de emails.',
          ToastTypes.error,
          toastCss(ToastTypes.error)
        ),
      });
    }
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
    });
  }
}

export function* resendBillingRequest(action) {
  try {
    const { dataArea } = yield select((state) => state.root);

    yield call(
      postApi,
      `/${dataArea}/recurrent/recurrent_plans/resend_billing.json`,
      { ...action.params }
    );

    yield put({
      type: actions.RESEND_BILLING_SUCCESS,
      toast: buildToast(
        'Os boletos foram reenviados com sucesso.',
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        'Não foi possível reenviar os boletos. Tente novamente mais tarde.',
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* recurrentPlanSagas() {
  yield all([
    takeEvery(actions.FETCH_REQUEST, fetchRecurrentPlans),
    takeLatest(actions.ADD_RECURRENT_PLAN, addRecurrentPlan),
    takeEvery(actions.EDIT_RECURRENT_PLAN, editRecurrentPlan),
    takeEvery(actions.SET_PLAN_REQUEST, setPlanRequest),
    takeEvery(actions.SET_BILL_PLAN_REQUEST, setBillrequest),
    takeLatest(actions.FETCH_STUDENTS, fetchStudents),
    takeLatest(actions.FETCH_RECIPIENTS, fetchRecipients),
    takeLatest(
      actions.FETCH_RESEND_BILLS_INFORMATION,
      fetchResendBillInformation
    ),
    takeEvery(actions.CANCEL_PLAN, cancelPlan),
    takeLatest(actions.SET_VERSIONS_REQUEST, setVersionsRequest),
    takeEvery(
      actions.FETCH_IN_BATCH_EXTRA_INFORMATION,
      fetchRecurrentInBatchExtraInformations
    ),
    takeEvery(
      actions.EDIT_RECURRENT_BILL_IN_BATCHES,
      editRecurrentBillInBatches
    ),
    takeEvery(
      actions.SET_SELECT_ALL_RECURRENT_REQUEST,
      setSelectALlRecurrentRequest
    ),
    takeLatest(actions.RESEND_BILLING_REQUEST, resendBillingRequest),
  ]);
}

export default recurrentPlanSagas;
