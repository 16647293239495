import React from 'react';
import Modal from 'react-modal';

import './style.scss';
import AgendaIcon from 'components/AgendaIcon';

/*
ModalContainer

List of props:
isOPen: boolean prop when true shows modal
toggleModal: bind function from parent which change isOpen prop

can add any content to modal, has 3 modal components already:
ModalTitle,ModalSubTitle and ModalButtons.

Example:
<ModalContainer isOpen={this.state.showModal} toggleModal={this.toggleModal}>
  <ModalTitle>header.title</ModalTitle>
  <ModalSubTitle subTitle={header.subTitle}/>
  <ModalButtons buttons={this.constructor.modalButtons}/>
</ModalContainer>

*/

type StylesProps = {
  content?: React.CSSProperties | undefined;
  overlay?: React.CSSProperties | undefined;
};

export type ModalContainer = {
  children: React.ReactNode;
  isOpen?: boolean;
  className?: string;
  maxWidth?: string;
  maxHeight?: string;
  minHeight?: string;
  toggleModal: () => void;
  title?: string | null | React.ReactElement;
  contentStyles?: React.CSSProperties | undefined;
  zIndex?: number;
};

const ModalContainer = ({
  children,
  isOpen = false,
  maxWidth = '415px',
  maxHeight,
  minHeight,
  className,
  toggleModal,
  title,
  contentStyles,
  zIndex = 99,
}: ModalContainer) => {
  const styles: StylesProps = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, .65)',
      zIndex,
    },
    content: {
      maxWidth: maxWidth,
      maxHeight: maxHeight,
      minHeight: minHeight,
      top: '60px',
      right: '0',
      left: '0',
      marginLeft: 'auto',
      marginRight: 'auto',
      padding: '32px',
      borderRadius: '16px',
      ...contentStyles,
    },
  };

  return (
    <div className="ModalContainer">
      <Modal
        isOpen={isOpen}
        onRequestClose={toggleModal}
        portalClassName={className}
        style={styles}
      >
        <div className={`${title ? 'flex-row-modal-head' : 'double-column'}`}>
          {!!title && <div className="title">{title}</div>}
          <div>
            <AgendaIcon
              name="close"
              className="custom-size"
              onClick={toggleModal}
            />
          </div>
        </div>

        {children}
      </Modal>
    </div>
  );
};

Modal.setAppElement('body');

export default ModalContainer;
