import React, { useEffect, useCallback } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import PageContainer from 'components/PageContainer';
import { Button } from '@agendaedu/ae-web-components';
import ButtonRow from 'components/ButtonRow';
import DropdownButton from 'components/DropdownButton';
import Table from 'components/Table';
import table from './table';
import Loader from 'components/Loader';
import OnboardingEmptyState from 'components/EmptyState/Onboarding';
import HeadquartersActions from 'store/headquarters/actions';
import Toast from 'components/Toast';
import HeadquarterContext from 'core/contexts/Headquarter';
import withAppContext from 'core/hoc/withAppContext';
import { EMPTY_STATES_URL } from 'core/constants';
import { trackEvent } from 'config/amplitude';
import * as S from './styles';

const Headquarters = ({ history, appContext: { policies } }) => {
  const {
    can_create_headquarter: canCreateHeadquarter,
    can_update_headquarter: canUpdateHeadquarter,
    can_show_empty_state_headquarters: canShowEmptyStateHeadquarters,
    has_import_web: hasImportWeb,
  } = policies;
  const dispatch = useDispatch();
  const { fetchHeadquarters, buildToast, setCanShowEmptyState } =
    HeadquartersActions;
  const { isLoading, headquarters, canShowEmptyState } = useSelector(
    (state) => state.headquarters
  );

  const headquartersWithMeta = useCallback(
    (headquarters) => {
      if (headquarters.length > 0) {
        return headquarters.map((headquarter) => ({
          ...headquarter,
          meta: { can_update_headquarter: canUpdateHeadquarter },
        }));
      }
      return [];
    },
    [canUpdateHeadquarter]
  );

  const exportHeadquartersData = useCallback((type) => {
    document.location.href = `headquarters/export_data.${type}`;
  }, []);

  const dropdownItems = [
    {
      text: 'Exportar unidades em CSV',
      onClick: () => exportHeadquartersData('csv'),
    },
    {
      text: 'Exportar unidades em XLS',
      onClick: () => exportHeadquartersData('xls'),
    },
  ];

  const pushToImports = () => {
    document.location.href = 'headquarters/imports';
  };

  const handleFetchHeadquarters = useCallback(() => {
    dispatch(fetchHeadquarters());
  }, [dispatch, fetchHeadquarters]);

  const handleSetEmptyState = useCallback(
    (state) => {
      dispatch(setCanShowEmptyState(state));
    },
    [dispatch, setCanShowEmptyState]
  );

  const displayToast = useCallback(
    async (message, type) => {
      await dispatch(buildToast(message, type));
    },
    [dispatch, buildToast]
  );

  useEffect(() => {
    if (history.location.successfullyCreated) {
      displayToast('Unidade cadastrada com sucesso.', 'success');
      handleSetEmptyState(false);
    } else if (history.location.successfullyEdited) {
      displayToast('Unidade atualizada com sucesso.', 'success');
    } else {
      handleSetEmptyState(canShowEmptyStateHeadquarters);
    }

    if (canShowEmptyStateHeadquarters) {
      trackEvent('plg-empty-state-headquarter-view');
    }
  }, [
    displayToast,
    history,
    handleSetEmptyState,
    canShowEmptyStateHeadquarters,
  ]);

  useEffect(() => {
    handleFetchHeadquarters();
  }, [handleFetchHeadquarters]);

  const headerButtons = () => (
    <ButtonRow>
      {canCreateHeadquarter && (
        <Link to={'headquarters/new'}>
          <Button role="button" variation="primary">
            Nova unidade
          </Button>
        </Link>
      )}
      <React.Fragment>
        <Button role="button" variation="secondary" onClick={pushToImports}>
          Importar
        </Button>
        <DropdownButton
          text=""
          dropdownItems={dropdownItems}
          icon="dots-vertical"
          iconSize="medium"
          variation="secondary"
        />
      </React.Fragment>
    </ButtonRow>
  );

  const tableEntries = () => {
    const tableLength = headquarters.length > 0 ? headquarters.length : 0;
    return (
      <div className="customMetadata">Exibindo {tableLength} unidades</div>
    );
  };

  return (
    <S.Wrapper>
      <HeadquarterContext.Provider>
        <PageContainer
          title="Central de unidades"
          className="HeadquartersList"
          header={headerButtons()}
        >
          <Loader isLoading={isLoading}>
            <Table
              columns={table}
              data={headquartersWithMeta(headquarters)}
              customMetadata={tableEntries()}
            />
            {canShowEmptyState && (
              <OnboardingEmptyState
                imgUrl={EMPTY_STATES_URL.headquartersUrl}
                title="Vamos cadastrar a primeira unidade da sua escola?"
                text={`
                  Nessa sessão, você pode cadastrar e gerenciar todas as unidades
                  da escola, além de visualizar quais turmas, profissionais, alunos e
                  responsáveis estão atrelados a elas. Este é o primeiro passo para
                  organizar os processos escolares na Agenda Edu.
                `}
                buttons={
                  <Link
                    to="headquarters/new"
                    onClick={() =>
                      trackEvent('plg-empty-state-headquarter-click')
                    }
                  >
                    <Button>Cadastrar unidade</Button>
                  </Link>
                }
              />
            )}
          </Loader>
          <Toast />
        </PageContainer>
      </HeadquarterContext.Provider>
    </S.Wrapper>
  );
};

Headquarters.propTypes = {
  history: PropTypes.object,
  appContext: PropTypes.shape({
    policies: PropTypes.shape({
      can_create_headquarter: PropTypes.bool,
      can_update_headquarter: PropTypes.bool,
      can_show_empty_state_headquarters: PropTypes.bool,
      has_import_web: PropTypes.bool,
    }),
  }),
};

export default withAppContext(withRouter(Headquarters));
