import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@agendaedu/ae-web-components';
import { Modal } from 'components/Handouts/Modal';

import withFormContext from 'core/hoc/withFormContext';

import { NotificationModalProps } from './types';

const DuplicateNotificationModal = ({
  active,
  onClose,
  onSubmit,
  formContext,
}: NotificationModalProps) => {
  const { t } = useTranslation(['handouts']);

  return (
    <Modal.Root
      dataTestId="duplicate-notification-modal"
      isOpen={active}
      handleToggleModal={onClose}
      title={t('modals.notification_duplicate.title')}
    >
      <Modal.Content>
        {t('modals.notification_duplicate.description')}
      </Modal.Content>
      <Modal.Actions>
        <Button variant="secondary" onClick={onClose} data-testid="close-modal">
          {t('modals.notification_duplicate.close_button')}
        </Button>
        <Button
          onClick={() => onSubmit({ ...formContext?.form })}
          data-testid="submit-modal"
        >
          {t('modals.notification_duplicate.submit_button')}
        </Button>
      </Modal.Actions>
    </Modal.Root>
  );
};

export default withFormContext(DuplicateNotificationModal);
