import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isUUID } from 'core/utils/isUuid';
import omniChannelActions from 'store/messages/omniChannel/actions';
import { OmniChannelState } from 'store/messages/omniChannel/types';

import * as S from './styles';

import ActionButton from 'components/Messages/TS/Animations/ActionButton';

import { MessageActionsProps } from './types';

export const MessageActions = ({
  message,
  isActiveActions,
}: MessageActionsProps): JSX.Element => {
  const dispatch = useDispatch();

  const { activeChat, activeChannel, isEditingMessage, isReplyMessage } =
    useSelector((state: OmniChannelState) => state.omniChannel);

  const {
    fetchChannelsByStudentProfileRequest,
    setCurrentMessage,
    setDeleteMessageRequest,
    toggleEditMessage,
    toggleReplyMessage,
    toggleRedirectMessageModal,
  } = omniChannelActions;

  const { canDelete, canUpdate, status, approvalStatus, user } =
    message.attributes;

  const approvalStatusOptions = ['manually_approved', 'approved'];

  const isDeletedUser = (id: string) => {
    return isUUID(id);
  };

  const canReply = !(
    (message.attributes.status === 'deleted' &&
      message.attributes.type === 'sent') ||
    message.attributes.approvalStatus === 'pending' ||
    message.attributes.approvalStatus === 'rejected'
  );

  const canRedirect =
    activeChat?.attributes.kind !== 'ticket' &&
    message.attributes.status !== 'deleted' &&
    approvalStatusOptions.includes(approvalStatus) &&
    user.attributes.role !== '' &&
    !isDeletedUser(user.id);

  const canEdit = canUpdate && status !== 'deleted';

  const handleDeleteMessage = useCallback(() => {
    const params = {
      channelId: activeChannel.id,
      chatId: activeChat.id,
      messageId: message.id,
    };
    dispatch(setDeleteMessageRequest(params));
  }, [activeChannel, activeChat, dispatch, message, setDeleteMessageRequest]);

  const fetchChannelsByStudentProfile = useCallback(() => {
    activeChat &&
      dispatch(
        fetchChannelsByStudentProfileRequest(
          activeChat.attributes.student_profile_id
        )
      );
  }, [activeChat, dispatch, fetchChannelsByStudentProfileRequest]);

  const handleToggleEditMessage = useCallback(() => {
    if (!isEditingMessage) {
      dispatch(toggleEditMessage());
      dispatch(setCurrentMessage(message));
    }
  }, [
    dispatch,
    message,
    setCurrentMessage,
    toggleEditMessage,
    isEditingMessage,
  ]);

  const handleToggleReplyMessage = useCallback(() => {
    if (!isReplyMessage) {
      dispatch(toggleReplyMessage());
      dispatch(setCurrentMessage(message));
    }
  }, [
    isReplyMessage,
    dispatch,
    toggleReplyMessage,
    setCurrentMessage,
    message,
  ]);

  const handleToggleRedirectModal = useCallback(() => {
    fetchChannelsByStudentProfile();
    dispatch(toggleRedirectMessageModal());
    dispatch(setCurrentMessage(message));
  }, [
    dispatch,
    fetchChannelsByStudentProfile,
    message,
    setCurrentMessage,
    toggleRedirectMessageModal,
  ]);

  return (
    <S.ActionsWrapper data-testid="actions-wrapper">
      <S.ActionContainer
        id="action"
        variantType={message.attributes.type}
        isActive={isActiveActions}
      >
        {canReply && (
          <ActionButton
            name="reply"
            handleOnClick={handleToggleReplyMessage}
            testId="reply-action"
          />
        )}

        {canEdit && (
          <ActionButton
            name="pencil"
            handleOnClick={handleToggleEditMessage}
            testId="edit-action"
          />
        )}

        {canDelete && status !== 'deleted' && (
          <ActionButton
            name="trash"
            handleOnClick={handleDeleteMessage}
            testId="delete-action"
          />
        )}

        {canRedirect && (
          <ActionButton
            name="forward"
            handleOnClick={handleToggleRedirectModal}
            testId="forward-action"
          />
        )}
      </S.ActionContainer>
    </S.ActionsWrapper>
  );
};
