import React, { Component } from 'react';
import PropTypes from 'prop-types';
import OutlineBox from 'components/OutlineBox';
import Tooltip from 'components/Tooltip';
import Button from 'components/Button';
import ModalContainer from 'components/Modal/ModalContainer';
import ModalTitle from 'components/Modal/ModalTitle';
import ModalSubTitle from 'components/Modal/ModalSubTitle';
import hoverable from 'core/hoc/hoverable';
import withAppContext from 'core/hoc/withAppContext';
import colorWithAlpha from 'core/utils/colorWithAlpha';
import './style.scss';

class ClassroomsList extends Component {
  static propTypes = {
    classroomsNames: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    style: PropTypes.object,
    appContext: PropTypes.shape({
      primaryColor: PropTypes.string,
    }),
    isHovering: PropTypes.bool.isRequired,
    enterHoverState: PropTypes.func.isRequired,
    leaveHoverState: PropTypes.func.isRequired,
    modalTitle: PropTypes.string,
    modalSubTitle: PropTypes.string,
    modalDescription: PropTypes.string,
  };

  static defaultProps = {
    onMouseEnter: new Function(),
    onMouseLeave: new Function(),
    style: {},
    kindItem: 'turmas',
  };

  state = {
    showModal: false,
  };

  onHoverStyle = {
    backgroundColor: colorWithAlpha(this.props.appContext.primaryColor, 0.1),
    color: this.props.appContext.primaryColor,
    borderColor: colorWithAlpha(this.props.appContext.primaryColor, 0.1),
  };

  toggleModal = () => {
    this.setState({ showModal: !this.state.showModal });
  };

  tooltipContent = () => {
    const { classroomsNames, kindItem } = this.props;
    const firstClasses = classroomsNames.slice(0, 10);

    return (
      <React.Fragment>
        <p className="titleTootipClassroom">{kindItem.toUpperCase()}</p>
        <b className="contentTootipClassroom">{firstClasses.join(', ')}</b>
        {classroomsNames?.length > 10 && (
          <Button onClick={this.toggleModal}>Ver todos</Button>
        )}
      </React.Fragment>
    );
  };

  modalContent = () => {
    const { classroomsNames } = this.props;

    return (
      <React.Fragment>
        <div className="classrooms-list-container">
          {classroomsNames?.map((classroom) => (
            <div className="classrooms-list-item" key={classroom}>
              <li className="classroom-name">
                <span>{classroom}</span>
              </li>
            </div>
          ))}
        </div>
      </React.Fragment>
    );
  };

  render() {
    const {
      classroomsNames,
      isHovering,
      enterHoverState,
      leaveHoverState,
      kindItem,
      modalTitle,
      modalSubTitle,
      modalDescription,
    } = this.props;

    const { showModal } = this.state;
    const style = isHovering ? this.onHoverStyle : undefined;

    if (classroomsNames?.length > 1) {
      return (
        <>
          <Tooltip content={this.tooltipContent()} className="ClassroomsList">
            <OutlineBox
              style={style}
              onMouseEnter={enterHoverState}
              onMouseLeave={leaveHoverState}
            >
              {`${classroomsNames?.length} ${kindItem}`}
            </OutlineBox>
          </Tooltip>
          <ModalContainer isOpen={showModal} toggleModal={this.toggleModal}>
            <ModalTitle>{modalTitle}</ModalTitle>
            <ModalSubTitle
              subTitle={modalSubTitle}
              description={modalDescription}
            />
            {this.modalContent()}
          </ModalContainer>
        </>
      );
    }

    if (classroomsNames?.length === 1) {
      return (
        <div className="ClassroomsList">
          <OutlineBox
            style={style}
            onMouseEnter={enterHoverState}
            onMouseLeave={leaveHoverState}
          >
            {classroomsNames}
          </OutlineBox>
        </div>
      );
    }

    return null;
  }
}
export default withAppContext(hoverable(ClassroomsList));
