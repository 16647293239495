import styled from 'styled-components';

export const Wrapper = styled.div<{ canShow: boolean }>`
  display: ${({ canShow }) => (canShow ? 'block' : 'none')};
`;

export const ButtonRow = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;
