import React from 'react';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import PropTypes from 'prop-types';

import AgendaIcon from 'components/AgendaIcon';

import './style.scss';

const PdfField = ({ disabled, fileUrl, removeFile, nameFile, sizeFile }) => {
  const pageNavigationPluginInstance = pageNavigationPlugin();
  const { CurrentPageLabel } = pageNavigationPluginInstance;

  return (
    <div className="PdfContainer">
      <div className="PdfFile">
        <div className="PdfCurrentPageLabel">
          <CurrentPageLabel>
            {(page) => (
              <span>{`${page.currentPage + 1} de ${page.numberOfPages}`}</span>
            )}
          </CurrentPageLabel>
        </div>
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
          <div className="PdfViewer">
            <Viewer
              fileUrl={fileUrl}
              plugins={[pageNavigationPluginInstance]}
            />
          </div>
        </Worker>
      </div>
      <div className="PdfInfo">
        <div className="PdfName">
          <AgendaIcon name="attachment" />
          <p className="PdfInfoLabel">{nameFile}</p>
        </div>
        <div className="PdfSize">
          <p className="PdfInfoLabel">{sizeFile}</p>
          <button disabled={disabled} onClick={removeFile}>
            <AgendaIcon name="trash" />
          </button>
        </div>
      </div>
    </div>
  );
};

PdfField.propTypes = {
  fileUrl: PropTypes.string,
  nameFile: PropTypes.string,
  sizeFile: PropTypes.number,
  removeFile: PropTypes.func,
  disabled: PropTypes.bool,
};

export default PdfField;
