import React, { useCallback, useEffect, useMemo } from 'react';

import {
  Box,
  Button,
  Checkbox,
  DefaultThemeProps,
  FullScreenModal,
  Icon,
  Radio,
  RangeDate,
  Select,
  SingleDate,
  Tag,
  Text,
} from '@agendaedu/ae-web-components';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import Toast from 'components/Toast';

import DiaryActions from 'store/dailySummaries/actions';

import { useTheme } from 'styled-components';
import moment from 'moment';
import {
  DiaryReportProps,
  DiaryReportStructure,
  DiaryState,
} from 'store/dailySummaries/types';
import { ReportSkeleton } from './skeleton';
import * as S from './styles';
import { Props } from './types';

const initialValuesInfo: DiaryReportStructure['form'] = {
  studentId: null,
  classroomId: null,
  diarySectionIds: [],
  startDate: moment(),
  endDate: moment(),
  headquarterId: null,
  reportKind: 'headquarter',
};

const DiaryReport = ({ history: { goBack } }: Props) => {
  const {
    fetchDiaryReportInitialRecipients,
    fetchDiaryReportStudents,
    fetchDiaryReportSections,
    exportDiaryReport,
  } = DiaryActions;
  const { t } = useTranslation('diary');
  const tBase = useCallback(
    (key: string, params?: Record<string, unknown>) =>
      t(`report.${key}`, params),
    [t]
  );
  const dispatch = useDispatch();
  const { breakpoints, border, colors } = useTheme() as DefaultThemeProps;

  const { diaryReport } = useSelector(
    (state: DiaryState) => state.dailySummaries
  );

  const headquartersOptions = useMemo(
    () =>
      diaryReport.headquarters.map(({ id, attributes: { name } }) => ({
        value: id,
        label: name,
      })),
    [diaryReport.headquarters]
  );

  const classroomsOptions = useMemo(
    () =>
      diaryReport.classrooms.reduce((prevClassrooms, currentHeadquarter) => {
        return [
          ...prevClassrooms,
          ...currentHeadquarter.options.map(({ id, attributes: { name } }) => ({
            value: id,
            label: name,
          })),
        ];
      }, []),
    [diaryReport.classrooms]
  );

  const studentsOptions = useMemo(
    () =>
      diaryReport.students.map(({ id, attributes: { name } }) => ({
        value: id,
        label: name,
      })),
    [diaryReport.students]
  );

  const fetchStudents = useCallback(
    (classroomId: string) =>
      dispatch(
        fetchDiaryReportStudents({
          classroomId,
        })
      ),
    [dispatch, fetchDiaryReportStudents]
  );

  const exportReport = useCallback(
    (values: typeof initialValuesInfo) => {
      const newValues: DiaryReportProps['params'] = {
        start_date: values.startDate.format('YYYY-MM-DD'),
        end_date: values.endDate ? values.endDate.format('YYYY-MM-DD') : '',
        report_kind: values.reportKind,
        headquarter_id: values.headquarterId,
        classroom_id: values.classroomId,
        student_id: values.studentId,
        diary_section_ids: values.diarySectionIds,
      };

      dispatch(exportDiaryReport({ params: newValues }));
    },
    [dispatch, exportDiaryReport]
  );

  const { values, setFieldValue, submitForm } = useFormik<
    DiaryReportStructure['form']
  >({
    initialValues: initialValuesInfo,
    onSubmit: exportReport,
    validateOnChange: true,
    isInitialValid: false,
  });

  const renderReportKind = ['headquarter', 'classroom', 'student'].map(
    (kind) => {
      const isChecked = values.reportKind === kind;

      return (
        <S.SelectItemWrapper
          key={kind}
          isChecked={isChecked}
          onClick={() => setFieldValue('reportKind', kind)}
        >
          <Icon
            name="user-group"
            color={
              isChecked ? colors.brand.primary.default : colors.neutral.gray3
            }
          />

          <Text
            variant="label-medium-14"
            color={isChecked ? 'brand.primary.default' : 'neutral.gray1'}
            mb={0}
            ml="xs"
          >
            {tBase(kind)}
          </Text>

          <Box display="flex" alignItems="center" ml="auto">
            <Radio checked={isChecked} />
          </Box>
        </S.SelectItemWrapper>
      );
    }
  );

  const getKindRecipientsSelect: Record<
    DiaryReportStructure['form']['reportKind'],
    () => JSX.Element
  > = useMemo(
    () => ({
      headquarter: () => (
        <Select
          data-testid="headquarter-select"
          fullWidth
          mt="lg"
          isSearchable
          onChange={({ value }) => setFieldValue('headquarterId', value)}
          label={tBase('headquarter_label')}
          options={headquartersOptions}
          value={values.headquarterId}
        />
      ),
      classroom: () => {
        const handleChange = (classroomId: string) => {
          fetchStudents(classroomId);
          setFieldValue('classroomId', classroomId);
          setFieldValue('studentId', '');
        };

        return (
          <Select
            data-testid="classroom-select"
            fullWidth
            mt="lg"
            isSearchable
            onChange={({ value }) => handleChange(value)}
            label={tBase('classroom_label')}
            options={classroomsOptions}
            value={values.classroomId}
          />
        );
      },
      student: function () {
        return (
          <Box>
            {this.classroom()}

            <Select
              data-testid="student-select"
              fullWidth
              mt="lg"
              isSearchable
              onChange={({ value }) => setFieldValue('studentId', value)}
              label={tBase('student_label')}
              options={studentsOptions}
              value={values.studentId}
            />
          </Box>
        );
      },
    }),
    [
      classroomsOptions,
      fetchStudents,
      headquartersOptions,
      setFieldValue,
      studentsOptions,
      tBase,
      values.classroomId,
      values.headquarterId,
      values.studentId,
    ]
  );

  const handleSelectAllSections = useCallback(() => {
    if (values.diarySectionIds.length === diaryReport.sections.length) {
      setFieldValue('diarySectionIds', []);
    } else {
      const allSectionsIds = diaryReport.sections.map((student) => student.id);
      setFieldValue('diarySectionIds', allSectionsIds);
    }
  }, [diaryReport.sections, setFieldValue, values.diarySectionIds.length]);

  const renderSectionsList = useMemo(
    () =>
      diaryReport.sections.map((section) => {
        const isChecked = values.diarySectionIds.includes(section.id);

        const onChange = () => {
          if (isChecked) {
            setFieldValue(
              'diarySectionIds',
              values.diarySectionIds.filter((id) => id !== section.id)
            );
          } else {
            setFieldValue('diarySectionIds', [
              ...values.diarySectionIds,
              section.id,
            ]);
          }
        };

        return (
          <Box key={section.id} mt="xs">
            <S.SelectItemWrapper isChecked={isChecked} onClick={onChange}>
              <Text
                variant="label-medium-14"
                color={isChecked ? 'brand.primary.default' : 'neutral.gray1'}
                mb={0}
              >
                {section.attributes.name}
              </Text>

              <Box display="flex" ml="auto" alignItems="center">
                {!!section.attributes.deletedAt && (
                  <Tag
                    size="small"
                    mr="lg"
                    enableEllipsis
                    variant={isChecked ? 'brand' : 'neutral'}
                    name={tBase('deleted_at', {
                      deletedAt: moment(section.attributes.deletedAt).format(
                        'DD/MM/YYYY'
                      ),
                    })}
                  />
                )}

                {!section.attributes.enable &&
                  !section.attributes.deletedAt && (
                    <Tag
                      size="small"
                      mr="lg"
                      variant={isChecked ? 'brand' : 'neutral'}
                      name={tBase('inactive_section')}
                    />
                  )}

                <Box mr="sm">
                  <Checkbox
                    key={`${section.id}-${isChecked}`}
                    checked={isChecked}
                    onChange={onChange}
                  />
                </Box>
              </Box>
            </S.SelectItemWrapper>
          </Box>
        );
      }),
    [diaryReport.sections, setFieldValue, tBase, values.diarySectionIds]
  );

  const content = useMemo(
    () => (
      <Box data-testid="content">
        <Text variant="title-bold-20" color="neutral.black">
          {tBase('subtitle')}
        </Text>

        <Box mt="xl2" display="flex">
          {values.reportKind === 'student' ? (
            <RangeDate
              handleOutsideRange={() => false}
              id="report-range-date"
              value={{ startDate: values.startDate, endDate: values.endDate }}
              handleChange={({ startDate, endDate }) => {
                setFieldValue('startDate', startDate);
                setFieldValue('endDate', endDate);
              }}
            />
          ) : (
            <SingleDate
              id="report-single-date"
              value={values.startDate}
              handleOutsideRange={() => false}
              handleChange={(date) => {
                setFieldValue('startDate', date);
                setFieldValue('endDate', date);
              }}
            />
          )}
        </Box>

        <S.KindWrapper>{renderReportKind}</S.KindWrapper>

        <Box mt="lg">
          {diaryReport.isLoadingReportData ? (
            <ReportSkeleton />
          ) : (
            getKindRecipientsSelect[values.reportKind]()
          )}
        </Box>

        <Box mt="lg">
          {!!diaryReport.sections.length && (
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Text variant="subtitle-medium-12" color="neutral.gray2">
                {tBase('sections_count', {
                  selected: values.diarySectionIds.length,
                  total: diaryReport.sections.length,
                })}
              </Text>

              <Button
                variant="secondary"
                size="sm"
                onClick={handleSelectAllSections}
              >
                {tBase(
                  values.diarySectionIds.length === diaryReport.sections.length
                    ? 'unselect_all_sections'
                    : 'select_all_sections'
                )}
              </Button>
            </Box>
          )}

          <Box mt="lg">
            {diaryReport.isLoadingReportSections ? (
              <ReportSkeleton />
            ) : (
              renderSectionsList
            )}
          </Box>
        </Box>
      </Box>
    ),
    [
      diaryReport.isLoadingReportData,
      diaryReport.isLoadingReportSections,
      diaryReport.sections.length,
      getKindRecipientsSelect,
      handleSelectAllSections,
      renderReportKind,
      renderSectionsList,
      setFieldValue,
      tBase,
      values.diarySectionIds.length,
      values.endDate,
      values.reportKind,
      values.startDate,
    ]
  );

  const isExportValid =
    {
      headquarter: !!values.headquarterId,
      classroom: !!values.classroomId,
      student: [values.classroomId, values.studentId].every(Boolean),
    }[values.reportKind] && values.diarySectionIds.length;

  const footer = (
    <Box
      py="sm"
      px={{ _: 'lg', mobileXS: 'xs2', desktopLG: 'xl5' }}
      borderTop={`${border.width.sm} ${border.style.solid} ${colors.neutral.gray4}`}
      position="fixed"
      bottom={0}
      width="100%"
      backgroundColor={colors.neutral.white}
    >
      <Button
        data-testid="export-button"
        ml="auto"
        disabled={!isExportValid}
        onClick={() => submitForm()}
      >
        {tBase('export_button')}
      </Button>
    </Box>
  );

  const handleKindActions = useCallback(
    () =>
      ({
        headquarter: () => {
          setFieldValue('headquarterId', headquartersOptions[0]?.value);
          setFieldValue('classroomId', '');
          setFieldValue('studentId', '');
        },
        classroom: () => {
          setFieldValue('headquarterId', '');
          setFieldValue('classroomId', classroomsOptions[0]?.value);
          setFieldValue('studentId', '');
        },
        student: function () {
          this.classroom();

          if (classroomsOptions[0]?.value)
            fetchStudents(classroomsOptions[0].value);
        },
      }[values.reportKind]()),
    [
      classroomsOptions,
      fetchStudents,
      headquartersOptions,
      setFieldValue,
      values.reportKind,
    ]
  );

  useEffect(() => {
    handleKindActions();
  }, [handleKindActions]);

  useEffect(() => {
    dispatch(fetchDiaryReportInitialRecipients());
  }, [dispatch, fetchDiaryReportInitialRecipients]);

  useEffect(() => {
    dispatch(fetchDiaryReportSections());
  }, [dispatch, fetchDiaryReportSections]);

  return (
    <>
      <FullScreenModal isOpen title={tBase('title')} onClose={goBack}>
        <Box
          display="flex"
          flex={1}
          flexDirection="column"
          overflow="hidden"
          justifyContent="space-between"
          mx={{ _: 'lg', mobileXS: 'xs2', desktopLG: 'xl5' }}
        >
          <Box width="100%" height="100%" mt="xl2" overflow="auto">
            <Box maxWidth={breakpoints.tabletMD} mx="auto" mb="30vh">
              {content}
            </Box>
          </Box>
        </Box>
        {footer}
      </FullScreenModal>

      <Toast />
    </>
  );
};

export default withRouter(DiaryReport);
