import React, { Component } from 'react';

import './MoneySummaryContainer.scss';

class MoneySummaryContainer extends Component {
  render() {
    return (
      <div className="MoneySummaryContainer">
        <div className="summaries">
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default MoneySummaryContainer;
