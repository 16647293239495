import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import InputTextField from 'components/Messages/InputTextField';

import messagesActions from 'store/messages/actions';
import {
  CreateNewMessageRequestParams,
  MessageState,
  UpdateMessageRequestParams,
} from 'store/messages/types';
import AttachmentEntity from 'core/entities/Attachment';

export const GroupInputText = () => {
  const dispatch = useDispatch();
  const [message, setMessage] = useState<string>(null);

  const {
    currentGroup: { id: groupId },
    isSending,
    footerToast: { active, currentMessage, variant },
  } = useSelector((state: MessageState) => state.messages);

  const disabledSubmitBtn = !message || /^[\n]+$/.test(message);

  const {
    createNewMessageRequest,
    updateMessageRequest,
    setChangeFooterToast,
  } = messagesActions;

  const handleOnChangeMessage = (message: string) => setMessage(message);

  const handleOnFooterClose = useCallback(() => {
    dispatch(
      setChangeFooterToast({
        active: false,
        currentMessage: null,
        variant: null,
      })
    );
  }, [dispatch, setChangeFooterToast]);

  const handleCreateNewMessage = useCallback(() => {
    const newMessage: CreateNewMessageRequestParams = {
      groupId,
      content: message.trim(),
    };

    dispatch(createNewMessageRequest(newMessage));
    setMessage('');
  }, [dispatch, createNewMessageRequest, setMessage, groupId, message]);

  const handleReplyMessage = useCallback(() => {
    const newMessage: CreateNewMessageRequestParams = {
      groupId,
      content: message.trim(),
      attachedId: currentMessage?.id,
    };

    dispatch(createNewMessageRequest(newMessage));
    setMessage('');
  }, [groupId, message, currentMessage, dispatch, createNewMessageRequest]);

  const handleUpdateMessage = useCallback(() => {
    const updateMessage: UpdateMessageRequestParams = {
      groupId,
      messageId: currentMessage?.id,
      updatedContent: message.trim(),
    };

    dispatch(updateMessageRequest(updateMessage));
    handleOnFooterClose();
  }, [
    dispatch,
    updateMessageRequest,
    handleOnFooterClose,
    groupId,
    currentMessage,
    message,
  ]);

  const handleAttachmentNewMessage = useCallback(
    (message: string, attachment: AttachmentEntity) => {
      const attachmentMessage: CreateNewMessageRequestParams = {
        groupId,
        content: message?.trim(),
        document: attachment,
      };

      dispatch(createNewMessageRequest(attachmentMessage));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, createNewMessageRequest, groupId, message]
  );

  const handleOnSubmitMessage = useCallback(() => {
    if (disabledSubmitBtn) return;

    const messageSubmitAction = {
      edit: handleUpdateMessage,
      null: handleCreateNewMessage,
      reply: handleReplyMessage,
    };

    messageSubmitAction[variant]();
  }, [
    disabledSubmitBtn,
    handleUpdateMessage,
    handleCreateNewMessage,
    variant,
    handleReplyMessage,
  ]);

  useEffect(() => {
    if (currentMessage && variant === 'edit') {
      setMessage(currentMessage.attributes.content);
      return;
    }
    setMessage('');
  }, [currentMessage, variant]);

  return (
    <InputTextField
      testId="group-input-text"
      isLoading={isSending}
      message={message}
      disabledSubmitBtn={disabledSubmitBtn}
      footerVariant={variant}
      footerShowToast={active}
      handleOnChange={handleOnChangeMessage}
      handleOnSubmit={handleOnSubmitMessage}
      handleOnFooterClose={handleOnFooterClose}
      handleOnSubmitAttachment={handleAttachmentNewMessage}
      currentMessage={currentMessage}
    />
  );
};
