import { ThemeProps } from 'config/themes/types';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  ${({ theme }) => css`
    .wallet-title {
      font-family: 'Roboto';
      font-size: 14px;
      font-weight: 400;
      color: ${theme.gray2};
    }

    .Field {
      padding: 0px;
      .wallet_configuration__option,
      .wallet_configuration__single-value {
        white-space: pre-wrap;
      }
    }
  `}
`;

