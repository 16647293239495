import axios from 'axios';

export default class SchoolServices {
  constructor(dataArea) {
    this.dataArea = dataArea;
  }

  /**
   * @param {string} legacyId - 123456
   * @param {string} schoolArea - headquarters, classrooms, disciplines...
   * @returns {Promise<object>}
   */
  async validateLegacyId(legacyid, schoolArea) {
    const endpoint = `/${this.dataArea}/${schoolArea}/${legacyid}/unique_legacy_id`;
    try {
      const response = await axios.get(endpoint);
      return response;
    } catch (error) {
      throw error;
    }
  }
}
