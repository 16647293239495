import React, { useState } from 'react';
import * as S from './styles';
import { Redirect } from 'react-router-dom';

const ListProducts = () => {
  const [redirectToNewOffer, setRedirectToNewOffer] = useState(false);

  if (redirectToNewOffer) {
    return <Redirect to="/schools/marketplaces/offers/new" />;
  }

  return (
    <S.ListProductContainer>
      <p>Ofertas do shop</p>
      <S.ListProductEmpty>
        <S.ListProductContent>
          <img
            src="https://static.agendaedu.com/shop/emptyList.png"
            alt="Não existem produtos ainda"
          />
          <p>Sua escola ainda não disponibilizou nenhuma oferta para venda.</p>
          <span>Clique aqui para cadastrar um novo produto</span>
          <a
            className="Button btn btn-primary"
            onClick={() => setRedirectToNewOffer(true)}
          >
            Cadastre agora
          </a>
        </S.ListProductContent>
      </S.ListProductEmpty>
    </S.ListProductContainer>
  );
};

export default ListProducts;
