import React, { createContext, useCallback, useState } from 'react';
import * as yup from 'yup';

import { DiarySectionForm } from 'store/dailySummaries/types';
import { Props } from './types';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';

const initialValuesInfo: DiarySectionForm['sectionForm'] = {
  name: '',
  classroomIds: [],
  questionsAttributes: [
    {
      title: '',
      kind: 'text',
    },
  ],
};

export const DiarySectionContext = createContext<Props>({
  form: { values: initialValuesInfo },
} as Props);

const DiarySectionProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [currentStep, setCurrentStep] = useState(1);

  const { t } = useTranslation('diary_sections');

  const tBase = useCallback(
    (key: string, params?: Record<string, string | number>) =>
      t(`sections.form.${key}`, params),
    [t]
  );

  const formValidationSchema = yup.object().shape({
    name: yup
      .string()
      .required(tBase('info_tab.required_field_error'))
      .max(280, tBase('info_tab.limit_character_error', { count: 280 })),
    questionsAttributes: yup.array().of(
      yup.object().shape({
        title: yup
          .string()
          .trim()
          .max(280, tBase('info_tab.limit_character_error', { count: 280 }))
          .required(tBase('info_tab.required_field_error')),
        answerOptionsAttributes: yup
          .array()
          .min(2)
          .optional()
          .of(
            yup.object().shape({
              title: yup
                .string()
                .trim()
                .max(
                  280,
                  tBase('info_tab.limit_character_error', { count: 280 })
                )
                .required(tBase('info_tab.required_field_error')),
            })
          ),
      })
    ),
  });

  const onSubmitInfo = () => {
    setCurrentStep(2);
  };

  const form = useFormik({
    initialValues: initialValuesInfo,
    onSubmit: onSubmitInfo,
    validationSchema: formValidationSchema,
    validateOnChange: true,
    isInitialValid: false,
  });

  return (
    <DiarySectionContext.Provider
      value={{
        form,
        currentStep,
        setCurrentStep,
      }}
    >
      {children}
    </DiarySectionContext.Provider>
  );
};

export default DiarySectionProvider;
