import { ThemeProps } from 'config/themes/types';
import styled, { css } from 'styled-components';

export const CategoriesFormContainer = styled.main`
  ${({ theme: { breakpoints, space } }: ThemeProps) => css`
    .WizardForm .wizard-content .wizard-taps-header .Tabs {
      display: none;
    }

    .FormFullScreen {
      overflow-y: auto;
    }

    @media screen and (max-width: ${breakpoints.tabletMD}) {
      .WizardForm {
        .wizard-content .wizard-taps-header {
          padding: ${space.xs4} ${space.lg};

          .PageTitle {
            margin-left: ${space.xs4};
          }
        }

        .ButtonRow {
          margin-right: ${space.lg};
        }
      }

      .FormFullScreen {
        overflow-y: hidden;
      }

      .FormFieldset {
        padding: ${space.xs4} ${space.lg};
      }
    }
  `}
`;
