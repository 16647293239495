/* eslint-disable react/jsx-key */
import React from 'react';
import * as S from './styles';

// eslint-disable-next-line react/prop-types
const EmptyState = ({ title, descrptions, imageUrl }) => {
  return (
    <S.Wrapper>
      <S.ContainerText>
        <S.Title>{title}</S.Title>
        <S.Text>{descrptions}</S.Text>
      </S.ContainerText>
      <S.ContainerImage>
        <S.Image src={imageUrl} alt="Empty state" />
      </S.ContainerImage>
    </S.Wrapper>
  );
};

export default EmptyState;
