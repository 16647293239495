import React from 'react';
import ContentLoader from 'react-content-loader';

const SkeletonList = () => {
  return (
    <ContentLoader
      width="100%"
      height="100%"
      viewBox="0 0 800 575"
      ariaLabel="Carregando..."
    >
      <rect x="24" y="15" rx="2" ry="2" width="70" height="20" />
      <rect x="24" y="50" rx="2" ry="2" width="753" height="99" />

      <rect x="24" y="158" rx="2" ry="2" width="184" height="140" />
      <rect x="215" y="158" rx="2" ry="2" width="184" height="140" />
      <rect x="404" y="158" rx="2" ry="2" width="184" height="140" />
      <rect x="595" y="158" rx="2" ry="2" width="184" height="140" />

      <rect x="24" y="304" rx="2" ry="2" width="184" height="140" />
      <rect x="215" y="304" rx="2" ry="2" width="184" height="140" />
      <rect x="404" y="304" rx="2" ry="2" width="184" height="140" />
      <rect x="595" y="304" rx="2" ry="2" width="184" height="140" />
    </ContentLoader>
  );
};

export default SkeletonList;
