import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import withAppContext from 'core/hoc/withAppContext';

import { Button } from '@agendaedu/ae-web-components';
import Tooltip, { LEFT } from 'components/Tooltip';

import omniChannelActions from 'store/messages/omniChannel/actions';

import { ChannelHeaderProps } from './types';

import * as S from './styles';

const ChannelHeader = ({
  appContext: {
    dataArea,
    policies: {
      can_create_channel: canCreateChannel,
      can_access_tickets: canAccessTickets,
    },
  },
}: ChannelHeaderProps): JSX.Element => {
  const dispatch = useDispatch();

  const { t } = useTranslation(['messages']);

  const { toggleNewChannelModal } = omniChannelActions;

  const handleToggleNewChannelModal = useCallback(() => {
    dispatch(toggleNewChannelModal());
  }, [dispatch, toggleNewChannelModal]);

  return (
    <S.ChannelHeaderWrapper>
      <S.HeaderTitle>{t('omni_channel.channel.messages_text')}</S.HeaderTitle>
      <S.HeaderAction>
        <S.HeaderSubTitle>
          {t('omni_channel.channel.channels_text')}
        </S.HeaderSubTitle>

        {canCreateChannel && (
          <Tooltip
            id="new-channel-text"
            content={t('omni_channel.channel.new_channel_text')}
            position={LEFT}
          >
            {canAccessTickets ? (
              <Button
                id="new-channel-modal"
                testId="new-channel-modal"
                size="xs"
                icon="plus"
                variant="secondary"
                isOnlyIcon
                onClick={handleToggleNewChannelModal}
              />
            ) : (
              <Link
                data-testId="link-new-channel-form"
                to={`/${dataArea}/messages/channels/new`}
              >
                <Button
                  id="new-channel-default"
                  size="xs"
                  icon="plus"
                  variant="secondary"
                  isOnlyIcon
                />
              </Link>
            )}
          </Tooltip>
        )}
      </S.HeaderAction>
    </S.ChannelHeaderWrapper>
  );
};

export default withAppContext(ChannelHeader);
