import {
  Box,
  Button,
  FullScreenModal,
  Icon,
  Tag,
  Text,
} from '@agendaedu/ae-web-components';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';

import withAppContext from 'core/hoc/withAppContext';

import { MemberProps, MessageState } from 'store/messages/types';
import messagesActions from 'store/messages/actions';

import { GroupMemberCard } from 'components/Messages/MessageGroup/GroupMemberCard';
import { GroupMemberCardSkeleton } from 'components/Messages/MessageGroup/GroupMemberCard/skeleton';

import * as S from './styles';

import { Props } from './types';

const GroupDetailsModal: React.FC<Props> = ({
  appContext: {
    dataArea,
    policies: { can_edit_group: canEditGroup },
  },
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['messages']);

  const { toggleDetailModal, fetchGroupMembersRequest } = messagesActions;

  const {
    currentGroup,
    showDetailModal,
    groupMembers,
    isLoadingGroupMembers,
    groupMembersPagination: { page, next },
  } = useSelector((state: MessageState) => state.messages);

  const handleToggleModal = useCallback(() => {
    dispatch(toggleDetailModal());
  }, [dispatch, toggleDetailModal]);

  const handleFetchGroupMembers = useCallback(
    (page) => {
      dispatch(fetchGroupMembersRequest(currentGroup?.id, page));
    },
    [currentGroup, dispatch, fetchGroupMembersRequest]
  );

  useEffect(() => {
    handleFetchGroupMembers(1);
  }, [currentGroup, dispatch, handleFetchGroupMembers]);

  return (
    <FullScreenModal
      isOpen={showDetailModal}
      onClose={handleToggleModal}
      title={t('groups.details_modal.title')}
    >
      <Box overflow="auto" width="100%" px="lg" id="wrapper">
        <S.Wrapper>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <S.GroupInformationTitle
              data-testid="title"
              variant="title-bold-20"
            >
              {t('groups.details_modal.information_title')}
            </S.GroupInformationTitle>
          </Box>

          <S.GroupHeaderWrapper>
            <Box
              display="flex"
              flexDirection="column"
              gap="sm"
              alignItems="center"
            >
              <S.GroupIcon
                image={currentGroup.attributes.icon}
                isImageChangeDisabled
                data-testid="group-icon"
              />

              <S.GroupTagWrapper>
                <Tag
                  name={t('groups.details_modal.group_id', {
                    id: currentGroup.id,
                  })}
                  size="small"
                  variant="neutral"
                />
              </S.GroupTagWrapper>
            </Box>
            <Box display="flex" width={'100%'} flexDirection="column" gap="md">
              <Box display="flex" flexDirection="column" gap="xs3">
                <S.GroupTitleLabel variant="subtitle-medium-14">
                  {t('groups.details_modal.name')}
                </S.GroupTitleLabel>

                <S.GroupTitle variant="body-regular-16">
                  {currentGroup?.attributes.name}
                </S.GroupTitle>
              </Box>

              {currentGroup.attributes.description && (
                <Box display="flex" flexDirection="column" gap="xs3">
                  <Text
                    variant="subtitle-medium-14"
                    color={'neutral.gray2'}
                    lineHeight="lg"
                    mb={0}
                  >
                    {t('groups.details_modal.description')}
                  </Text>

                  <Text
                    variant="body-regular-16"
                    color={'neutral.gray1'}
                    lineHeight="lg"
                    mb={0}
                  >
                    {currentGroup.attributes.description}
                  </Text>
                </Box>
              )}
            </Box>
          </S.GroupHeaderWrapper>
          <S.GroupMembersWrapper>
            <Box
              display="flex"
              flexDirection="row"
              gap="xs"
              alignItems="center"
            >
              <S.GroupIconMembers>
                <Icon name="user-group" />
              </S.GroupIconMembers>

              <Text
                data-testid="group-count"
                variant="title-bold-20"
                color="neutral.black"
                fontWeight={700}
                mb={0}
              >
                {t('groups.details_modal.members_title', {
                  total: currentGroup.relationships.members.data.length,
                })}
              </Text>
            </Box>
            {canEditGroup && (
              <Button
                as="a"
                href={`/${dataArea}/messages/groups/${currentGroup.id}/edit`}
                variant="secondary"
                icon="pencil"
                data-testid="edit-group-button"
              >
                {t('groups.details_modal.edit_group_button_label')}
              </Button>
            )}
          </S.GroupMembersWrapper>
          <S.GroupListWrapper>
            {!isLoadingGroupMembers ? (
              <InfiniteScroll
                scrollableTarget="wrapper"
                dataLength={groupMembers?.length}
                next={() => handleFetchGroupMembers(page + 1)}
                hasMore={!!next}
                loader={
                  <Box mt={'lg'}>
                    <GroupMemberCardSkeleton amount={1} />
                  </Box>
                }
              >
                <Box display="flex" flexDirection="column" gap="lg">
                  {groupMembers?.map((member: MemberProps) => (
                    <GroupMemberCard member={member} key={member.id} />
                  ))}
                </Box>
              </InfiniteScroll>
            ) : (
              <GroupMemberCardSkeleton amount={3} />
            )}
          </S.GroupListWrapper>
        </S.Wrapper>
      </Box>
    </FullScreenModal>
  );
};

export default withAppContext(GroupDetailsModal);
