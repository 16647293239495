import styled, { css } from 'styled-components';

import {
  TextField,
  Checkbox as CheckboxLib,
} from '@agendaedu/ae-web-components';

import { SearchProps } from './types';

export const Search = styled(TextField)`
  ${({ haveStudents, theme: { border } }: SearchProps) => css`
    input {
      border-color: transparent;
      ${haveStudents
        ? css`
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
          `
        : css`
            border-bottom-left-radius: ${border.radius.md};
            border-bottom-right-radius: ${border.radius.md};
          `}
    }
  `}
`;

export const Checkbox = styled(CheckboxLib)`
  span {
    position: relative;
  }
`;
