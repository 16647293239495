/* eslint-disable radix */
const extractBody = response => response.data;

const extractHeaders = response => ({
  totalItemsCount: parseInt(response.headers['x-total-items']),
  itemsCountPerPage: parseInt(response.headers['x-per-page']),
});

// eslint-disable-next-line max-len
const extractErrorsFromUnauthorizedRequest = response => extractBody(response).errors.map(error => error.detail);

// eslint-disable-next-line no-console
const handleRequestError = (error, { logger = console.log, fallback = new Function() } = {}) => {
  const { response } = error;

  if (response) {
    const { status } = response;

    if (status === 422) {
      logger(extractBody(response).errors.full_messages.join('\n'));
    } else if (status === 403) {
      logger(extractErrorsFromUnauthorizedRequest(response).join('\n'));
    }
  } else {
    fallback();
  }
};

export {
  extractBody,
  extractHeaders,
  extractErrorsFromUnauthorizedRequest,
  handleRequestError,
};
