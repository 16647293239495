import React, { useRef } from 'react';
import AttachmentEntity from 'core/entities/Attachment';
import MessagesAttachment from 'components/Messages/Attachment';
import * as S from './styles';

type Attributes = {
  user: { attributes: { name: string } };
  text: string;
  file: File;
};

type Message = {
  attributes: { approvalStatus: string; type: string };
  relationships: {
    attachedMessage: {
      data: {
        id: string;
        attributes: Attributes;
      };
    };
  };
};

type MessagesBalloonAnchorProps = {
  message: Message;
  anchorMessage: ({ message: Message, ref: HTMLAnchorElement }) => void;
};

export const MessagesBalloonAnchor = ({
  message,
  anchorMessage,
}: MessagesBalloonAnchorProps) => {
  const { text, user, file } =
    message.relationships.attachedMessage.data.attributes;

  const { approvalStatus, type } = message.attributes;

  const attachment = new AttachmentEntity(file);

  const ref = useRef<HTMLAnchorElement>(null);

  return (
    <S.Container
      ref={ref}
      onClick={() => anchorMessage({ message, ref })}
      approvalStatus={approvalStatus}
      type={type}
    >
      <S.Content approvalStatus={approvalStatus} type={type}>
        {text ? (
          <>
            <div>{user.attributes.name}</div>
            <span>{text}</span>
          </>
        ) : (
          <>
            <div>{user.attributes.name}</div>
            <MessagesAttachment attachment={attachment} variation={'sent'} />
          </>
        )}
      </S.Content>
    </S.Container>
  );
};
