import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import {
  buildToast,
  toastCss,
  ToastTypes,
} from 'store/middlewares/toast/actions';
import { fetchApi, postApi, patchApi } from 'core/utils/api';

import actions from './actions';

function* fetchBillingListRequest() {
  try {
    const dataArea = yield select((state) => state.root.dataArea);

    const { data, included, meta } = yield call(
      fetchApi,
      `/${dataArea}/billings.json`
    );

    yield put({
      type: actions.FETCH_BILLING_LIST_SUCCESS,
      data,
      included,
      meta,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        'Não foi possível obter a listagem de assinaturas.',
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* requestBilletPayment(action) {
  try {
    const { signatureId } = action;

    const {
      data: { code, link },
    } = yield call(fetchApi, `/schools/billings/${signatureId}/billet.json`);

    if (!link) throw new Error('Não foi possivel gerar o boleto');

    yield put({
      type: actions.REQUEST_BILLET_PAYMENT_SUCCESS,
      code,
      link,
      signatureId,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        'Erro ao solicitar geração do boleto',
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* billingPanelSagas() {
  yield all([
    takeLatest(actions.FETCH_BILLING_LIST, fetchBillingListRequest),
    takeLatest(actions.REQUEST_BILLET_PAYMENT, requestBilletPayment),
  ]);
}

export default billingPanelSagas;
