import React, { useContext } from 'react';

import validateTextAreaPresence from 'core/lib/FormValidator/validators/textAreaPresence';
import DailySummariesContext from 'core/contexts/DailySummaries';
import * as S from './styles';

import SectionCard from '../';

const ObservationsCard = () => {
  const {
    form: { observation },
    handleForm,
    isDisabled,
    hasStudentSelected,
  } = useContext(DailySummariesContext);

  const disableCard = !hasStudentSelected || isDisabled;

  return (
    <SectionCard title="Observação">
      <S.ObservationField
        quillProps={{
          onChange: (value) =>
            handleForm(
              'observation',
              validateTextAreaPresence(value) ? value : ''
            ),
          value: observation,
          readOnly: disableCard,
        }}
        type="textArea"
        useImage
      />
    </SectionCard>
  );
};

export default ObservationsCard;
