import React from 'react';
import PropTypes from 'prop-types';

import withFormContext from 'core/hoc/withFormContext';
import RecurrentItemsManager from '../../RecurrentItemsManager';

import './style.scss';

const RecurrentItems = ({ title, type, action }) => {
  return (
    <div className="RecurrentItems">
      <div className="row">
        <RecurrentItemsManager title={title} type={type} action={action} />
      </div>
    </div>
  );
};

RecurrentItems.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string,
  action: PropTypes.string,
};

export default withFormContext(RecurrentItems);
