import React, { useState } from 'react';
import PropTypes from 'prop-types';

import AgendaIcon from 'components/AgendaIcon';
import Button from 'components/Button';
import SideSheetFilters from 'components/Payments/SideSheetFilters';

import * as S from './styles';

const ButtonAndSideFilter = ({ handleFilter, amount }) => {
  const [openSideSheet, setOpenSideSheet] = useState(false);

  const hasActiveFilters = amount > 0;

  const handleSideSheetToggle = () => setOpenSideSheet(!openSideSheet);

  return (
    <S.Wrapper isActive={hasActiveFilters}>
      <Button
        testID="filterIcon"
        variation="white"
        onClick={handleSideSheetToggle}
      >
        <AgendaIcon name="filter" />
        {hasActiveFilters && <S.BadgeFilter>{amount}</S.BadgeFilter>}
      </Button>
      <SideSheetFilters
        title="Filtros"
        isOpen={openSideSheet}
        onDismiss={handleSideSheetToggle}
        handleFilter={handleFilter}
      />
    </S.Wrapper>
  );
};

ButtonAndSideFilter.propTypes = {
  handleFilter: PropTypes.func.isRequired,
  amount: PropTypes.number.isRequired,
};

export default ButtonAndSideFilter;
