import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import themes from '../../config/themes';

import AgendaIcon from 'components/AgendaIcon';

import withAppContext from 'core/hoc/withAppContext';

const StyledButton = styled.input`
  ${({ theme, disabled }) => css`
    position: absolute;
    opacity: 0;
    visibility: hidden;

    &:checked + ${StyledLabel} {
      &::after {
        padding: 2px;
        background-clip: content-box;
        background-color: ${disabled ? theme.gray1 : theme.primaryColor};
        border-color: ${disabled ? theme.gray1 : theme.primaryColor};
      }
    }
  `}
`;

const StyledLabel = styled.label`
  ${({ theme, checked, simpleInputRadio }) => css`
    display: flex;
    height: 48px;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    border-radius: 8px;
    font-size: 16px;
    font-family: Roboto;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    margin: 0px;
    color: ${checked ? theme.primaryColor : theme.black};

    ${!simpleInputRadio &&
    css`
      min-width: 230px;
      padding: 8px 16px;
      background-color: ${checked ? `${theme.primaryColor}10` : theme.white};
      border: 1px solid ${checked ? `${theme.primaryColor}20` : theme.gray6}};
    `}

    &::after {
      display: inline-block;
      content: '';
      height: 18px;
      width: 18px;
      color: ${theme.gray1};
      border: 2px solid ${themes.gray1};
      border-radius: 50%;
      margin-left: 5px;
    }

    i {
      margin-right: 13px;
      color: ${checked ? theme.primaryColor : theme.gray2};

      &.medium {
        font-size: 24px;
      }
    }
  `}
`;

const StyledLabelWithIcon = styled.div`
  display: flex;
  flex-direction: row;

  i {
    margin-right: 13px;
  }
`;

function RadioButton(props) {
  const {
    labelText,
    attributeName,
    value,
    onChange,
    checked,
    disabled,
    icon,
    simpleInputRadio,
  } = props;

  const id = `${attributeName}_${value}`;

  return (
    <div className="RadioButton">
      <StyledButton
        type="radio"
        id={id}
        onChange={onChange}
        value={value}
        checked={checked}
        disabled={disabled}
      />
      <StyledLabel
        htmlFor={id}
        disabled={disabled}
        checked={checked}
        icon={icon}
        simpleInputRadio={simpleInputRadio}
      >
        <StyledLabelWithIcon>
          {icon && <AgendaIcon name={icon} size={'medium'} />}
          {labelText && <span>{labelText}</span>}
        </StyledLabelWithIcon>
      </StyledLabel>
    </div>
  );
}

RadioButton.propTypes = {
  labelText: PropTypes.string,
  attributeName: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  simpleInputRadio: PropTypes.bool,
};

RadioButton.defaultProps = {
  disabled: false,
  simpleInputRadio: false,
};

export default withAppContext(RadioButton);
