import styled, { css } from 'styled-components';

import { ThemeProps } from 'config/themes/types';

export const ContentWrapper = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    flex-direction: column;
    max-width: 400px;
    padding: ${space.xs} ${space.xs3} 0 ${space.xs3};
  `}
`;

export const DescriptionWrapper = styled.div`
  ${({ theme: { space, typography } }: ThemeProps) => css`
    display: flex;
    flex-direction: column;
    gap: ${space.xl};
    ${typography.body.Regular16};
  `}
`;

export const ButtonWrapper = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    justify-content: flex-end;
    gap: ${space.sm};
  `}
`;
