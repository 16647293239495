import styled from 'styled-components';

export const DiarySectionContainer = styled.div`
  .WizardForm .wizard-content {
    .wizard-taps-header .PageTitle {
      min-width: 50%;
    }

    fieldset {
      max-width: 650px;
      margin-left: auto;
      margin-right: auto;

      .col-xs-12 .col-md-12 {
        padding-left: 24px;
        padding-right: 24px;
      }

      .col-lg-8 {
        width: unset;
      }

      h3 {
        padding-left: 24px;
      }

      @media (max-width: 890px) {
        .col-xs-12 .col-md-12 {
          padding-left: 0;
          padding-right: 0;
        }

        h3 {
          padding-left: 0;
        }
      }
    }
  }
`;
