import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import Skeleton from 'components/Skeleton';

import { ThemeProps } from 'config/themes/types';

const SkeletonModifiers = {
  withSpaceBetween: () => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
  `,
  withFlexGap: (space: string) => css`
    display: flex;
    gap: ${space};
  `,
};

export const SkeletonWrapper = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    width: 100%;
    ${SkeletonModifiers.withFlexGap(space.xl2)};
    flex-direction: column;
  `}
`;

export const ContentSkeleton = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    ${SkeletonModifiers.withFlexGap(space.lg)};
    flex-direction: column;
  `}
`;

export const LabelSkeleton = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const CardSkeleton = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    svg {
      &:not(:first-child) {
        margin-top: ${space.sm};
      }
    }
  `};
`;

export const HandoutScreenWrapper = styled.main`
  ${({ theme: { breakpoints, colors, space } }: ThemeProps) => css`
    min-height: calc(100vh - 80px);
    padding: ${space.xl} ${space.lg};
    background-color: ${colors.neutral.white};

    @media screen and (min-width: ${breakpoints.mobileSM}) and (max-width: ${breakpoints.desktopXL}) {
      padding: ${space.xl2} ${space.xl};
    }

    @media screen and (min-width: ${breakpoints.desktopXL}) {
      padding: ${space.xl} ${space.xl5};
    }
  `}
`;

export const AmountHandouts = styled.span`
  ${({ theme: { colors, typography } }: ThemeProps) => css`
    ${typography.subtitle.Medium14};
    color: ${colors.neutral.gray2};
  `};
`;

export const SkeletonListItem = styled(Skeleton)`
  ${({ theme }: ThemeProps) => css`
    margin-top: ${theme.space.sm};
  `}
`;

export const Title = styled.span`
  ${({ theme: { space } }: ThemeProps) => css`
    margin-bottom: ${space.xs2};
  `}
`;

export const HandoutName = styled.span`
  ${({ theme: { space } }: ThemeProps) => css`
    margin: ${space.xs4};
  `}
`;

export const Content = styled.div`
  ${({ theme: { breakpoints, space } }: ThemeProps) => css`
    display: grid;
    width: 100%;

    span {
      margin: ${space.xs4};
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    @media only screen and (max-width: ${breakpoints.tabletMD}) {
      ${HandoutName} {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
        white-space: normal;
      }
    }
  `}
`;

export const Tag = styled.div`
  ${({ theme: { border, colors, space, typography } }: ThemeProps) => css`
    width: 88px;
    height: 32px;
    padding: ${space.xs};

    ${typography.subtitle.Medium14};

    background-color: ${colors.neutral.gray5};

    color: ${colors.neutral.gray1};
    border-radius: ${border.radius.sm};

    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: ${colors.neutral.gray4};

      ${TooltipContent} {
        display: flex;
      }
    }
  `}
`;

export const CategoryWrapper = styled.div`
  display: flex;

  > p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const TagName = styled.p`
  ${({ theme: { space } }: ThemeProps) => css`
    margin: ${space.xs4};
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `}
`;

export const TooltipWrapper = styled.div`
  ${({
    theme: { border, colors, breakpoints, space, shadow },
  }: ThemeProps) => css`
    position: absolute;
    bottom: ${space.xl3};
    left: 50%;
    transform: translateX(-50%);
    width: 256px;
    flex-wrap: wrap;
    padding: ${space.sm};
    border-radius: ${border.radius.sm};
    background-color: ${colors.neutral.white};
    box-shadow: ${shadow.hover};
    z-index: 1;

    display: none;
    flex-direction: column;
    gap: ${space.xs2};

    &::before {
      content: '';
      position: absolute;
      bottom: -${space.xs2};
      left: 50%;
      transform: translateX(-50%);
      width: 8px;
      height: 12px;

      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
      border-top: 12px solid ${colors.neutral.white};

      background: transparent;
    }

    @media only screen and (max-width: ${breakpoints.tabletMD}) {
      left: 0%;
      transform: translateX(0%);

      &::before {
        left: 10%;
      }
    }
  `}
`;

export const TooltipTitle = styled.span`
  ${({ theme }: ThemeProps) => css`
    ${theme.typography.subtitle.Medium12};
  `}
`;

export const TooltipContent = styled.p`
  ${({ theme }: ThemeProps) => css`
    margin: ${theme.space.xs4};
  `}
`;

export const TooltipClassroomWrapper = styled(TooltipWrapper)`
  width: 172px;
`;

export const HandoutTooltip = styled.div`
  width: auto;
  position: relative;
  cursor: pointer;

  &:hover {
    ${TooltipWrapper} {
      display: flex;
    }

    ${TooltipClassroomWrapper} {
      display: flex;
    }
  }
`;

export const EmptyStateWrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    .EmptyState {
      width: 264px;
      ${theme.typography.subtitle.Medium16};

      img {
        max-width: fit-content;
        margin-bottom: ${theme.space.lg};
      }
    }
  `}
`;

export const CardAnchorReact = styled(Link)`
  ${({ theme: { colors, typography } }: ThemeProps) => css`
    width: 100%;
    color: initial;

    ${Title},
    ${TooltipTitle} {
      ${typography.subtitle.Medium12};
      color: ${colors.neutral.gray2};
    }

    ${Content},
    ${TooltipContent} {
      ${typography.subtitle.Medium16};
      color: ${colors.neutral.gray1};
    }

    ${TooltipContent} {
      color: ${colors.neutral.black};
      ${typography.body.Regular14};
    }

    &:hover {
      color: initial;

      ${TooltipTitle},
      ${Title} {
        color: ${colors.neutral.gray2};
      }

      ${Content} {
        color: ${colors.neutral.gray1};
      }

      ${TooltipContent} {
        color: ${colors.neutral.black};
      }
    }
  `}
`;

export const CardAnchor = styled.a`
  ${({ theme: { colors, typography } }: ThemeProps) => css`
    width: 100%;
    color: initial;

    ${Title},
    ${TooltipTitle} {
      ${typography.subtitle.Medium12};
      color: ${colors.neutral.gray2};
    }

    ${Content},
    ${TooltipContent} {
      ${typography.subtitle.Medium16};
      color: ${colors.neutral.gray1};
    }

    ${TooltipContent} {
      color: ${colors.neutral.black};
      ${typography.body.Regular14};
    }

    &:hover {
      color: initial;

      ${TooltipTitle},
      ${Title} {
        color: ${colors.neutral.gray2};
      }

      ${Content} {
        color: ${colors.neutral.gray1};
      }

      ${TooltipContent} {
        color: ${colors.neutral.black};
      }
    }
  `}
`;
