import styled, { css } from 'styled-components';

import ButtonRow from 'components/ButtonRow';

export const ModalBody = styled.div`
  ${({ theme }) => css`
    width: 100%;
    font-size: 16px;
    color: ${theme.gray2};
    line-height: 24px;
  `}
`;

export const ModalFooter = styled(ButtonRow)``;
