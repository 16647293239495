import styled from 'styled-components';

export const Wrapper = styled.div`
  .customMetadata {
    margin-left: auto;
  }

  > fieldset {
    width: 100% !important;
    margin: 0 !important;
  }
`;

export const TableWrapper = styled.div`
  .TableContainer {
    .Table.table {
      tbody {
        > tr {
          > td {
            white-space: nowrap;
          }
        }
      }
    }
  }
`;
