import React from 'react';
import { Avatar } from '@agendaedu/ae-web-components';

import * as S from './styles';

import { MessageGroupAvatarProps } from './types';

export const MessageGroupAvatar = ({
  avatarColor,
  size,
  nameInitials,
  avatarUrl,
}: MessageGroupAvatarProps): JSX.Element => {
  return (
    <S.Wrapper size={size}>
      <S.AvatarBackground />

      <Avatar
        avatarUrl={avatarUrl}
        avatarColor={avatarColor}
        size={size}
        nameInitials={nameInitials}
      />
    </S.Wrapper>
  );
};
