import React from 'react';

import './style.scss';

import Tooltip from 'components/Tooltip';
import AgendaIcon from 'components/AgendaIcon';

const MessagesApprove = ({ approveMessage, unapproveMessage }) => {
  return (
    <div
      className='MessagesApprove'>
      <div onClick={approveMessage}>
        <Tooltip content={'Aprovar'}>
          <AgendaIcon
            className='yesIcon'
            name='check'
          />
        </Tooltip>
      </div>
      <div onClick={unapproveMessage}>
        <Tooltip content={'Não aprovar'}>
          <AgendaIcon
            className='noIcon'
            name='close'
          />
        </Tooltip>
      </div>
    </div>
  );
};

export default MessagesApprove;
