/**
 * @param {Boolean} isDebugUser
 * @param {Boolean} isWalletGuaranteedMonthlyFee
 * @param {String} status
 * @returns {Boolean}
 */
export const canGenerateBilletOrPix = (
  isDebugUser,
  isWalletGuaranteedMonthlyFee,
  status
) => {
  if (isWalletGuaranteedMonthlyFee && status === 'delayed' && !isDebugUser) {
    return false;
  }
  return true;
};
