import styled, { css } from 'styled-components';
import Field from 'components/Form/Field';

export const ObservationField = styled(Field)`
  margin-top: 24px;

  ${({ quillProps: { readOnly } }) =>
    readOnly &&
    css`
      .quill .ql-toolbar {
        display: none;
      }
      .ql-toolbar.ql-snow + .ql-container.ql-snow {
        border-top: 1px solid #ccc;
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
      }
    `};
`;
