import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import HelperText from '../HelperText';
import Label from '../../Form/Label';
import { SIZES_MAPPING, MOBILE_SIZE_MAPPING } from '../commoms/constants';
import '../commoms/style.scss';

const TextInput = (props) => {
  const {
    label,
    input,
    mobilesize,
    size,
    orientation,
    meta: { touched, error },
  } = props;

  const onChange = useCallback(value => input.onChange(value), [input]);

  return (
    <div className={`Field ${MOBILE_SIZE_MAPPING[mobilesize]} ${SIZES_MAPPING[size]} ${orientation}`}>
      <Label>
        {label}
      </Label>
      <div className="TextField">
        <input
          className="form-control"
          onChange={onChange}
          value={input.value}
          {...props}
        />
      </div>
      {touched && error ? <HelperText>{error}</HelperText> : ''}
    </div>
  );
};

TextInput.defaultProps = {
  size: 'large',
  type: 'text',
  mobilesize: 'extraLarge',
  orientation: 'vertical',
};

export default TextInput;
