function validateSentFor(input, state) {
  return state.classroom_ids.length > 0;
}

export default [
  {
    attributeName: 'classroom_ids',
    validator: validateSentFor,
    message: 'Selecione as turmas',
  },
];
