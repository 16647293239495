import React, { useCallback, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import withAppContext from 'core/hoc/withAppContext';

import actionHandouts from 'store/handouts/actions';
import { HandoutState, Template } from 'store/handouts/types';

import { Box, Button } from '@agendaedu/ae-web-components';
import Breadcrumb from 'components/Breadcrumb';

import Tabs from 'components/Messages/OmniChannel/Tabs';
import Toast from 'components/Toast';
import TemplateCard from 'components/Handouts/TemplateCard';
import { TemplateDeleteModal } from 'components/Handouts/TemplateDeleteModal';
import TemplateDetailsModal from 'components/Handouts/TemplateDetailsModal';

import { HandoutTemplateEmptyState } from './emptyState';
import { HandoutTemplatesLoading } from './loading';
import { HandoutTemplatesSkeleton } from './skeleton';

import * as S from './styles';

import { HandoutsTemplatesScreenProps } from './types';

const HandoutTemplateScreen: React.FC<HandoutsTemplatesScreenProps> = ({
  appContext: {
    dataArea,
    policies: { can_create_handout_template: canCreateHandoutTemplate },
  },
  history,
}) => {
  const { t } = useTranslation(['handouts']);
  const dispatch = useDispatch();

  const TABS = [
    {
      title: t('templates.tab_title'),
      key: 0,
    },
  ];

  const {
    fetchHandoutTemplatesRequest,
    toggleHandoutTemplateModal,
    setCurrentHandoutTemplate,
  } = actionHandouts;

  const {
    currentHandoutTemplate,
    handoutTemplates: { next, page, templates },
    isLoading,
  } = useSelector((state: HandoutState) => state.handouts);

  const handleSetCurrentHandoutTemplate = useCallback(
    (template) => {
      dispatch(setCurrentHandoutTemplate(template));
    },
    [dispatch, setCurrentHandoutTemplate]
  );

  const handleFetchHandoutTemplates = useCallback(
    ({ page }: { page: number }) => {
      dispatch(fetchHandoutTemplatesRequest({ page }));
    },
    [dispatch, fetchHandoutTemplatesRequest]
  );

  const handleToggleDetailsModal = useCallback(() => {
    dispatch(toggleHandoutTemplateModal());
  }, [dispatch, toggleHandoutTemplateModal]);

  const handleViewTemplate = useCallback(
    (template: Template) => {
      handleSetCurrentHandoutTemplate(template);
      handleToggleDetailsModal();
    },
    [handleSetCurrentHandoutTemplate, handleToggleDetailsModal]
  );

  const handleUseTemplate = useCallback(
    (templateId: string) => {
      history.push(`/${dataArea}/handouts/templates/${templateId}/apply`);
    },
    [dataArea, history]
  );

  useEffect(() => {
    handleFetchHandoutTemplates({ page: 1 });
  }, [dispatch, fetchHandoutTemplatesRequest, handleFetchHandoutTemplates]);

  if (!templates || isLoading) return <HandoutTemplatesSkeleton />;

  return (
    <S.Wrapper>
      <S.HeaderWrapper>
        <Box display={'flex'} flexDirection={'column'}>
          <Breadcrumb
            title={t('handout_details.breadcrumb_title')}
            path={`/${dataArea}/handouts`}
          />

          <S.Title>{t('templates.list_page_title')}</S.Title>
        </Box>

        {canCreateHandoutTemplate && (
          <Link to={`/${dataArea}/handouts/templates/new`}>
            <Button
              id="new-handout-template"
              data-testid="create-template-button"
            >
              {t('templates.new_template_button')}
            </Button>
          </Link>
        )}
      </S.HeaderWrapper>

      <S.TemplatesWrapper>
        <S.TabsWrapper>
          <Box width={113}>
            <Tabs currentTab={0} tabs={TABS} />
          </Box>
        </S.TabsWrapper>

        <S.ListWrapper>
          {templates.length ? (
            <InfiniteScroll
              dataLength={templates?.length}
              next={() => handleFetchHandoutTemplates({ page: page + 1 })}
              hasMore={!!next}
              loader={<HandoutTemplatesLoading />}
            >
              <S.TemplateCardListWrapper data-testid="template-list">
                {templates?.map((template) => (
                  <TemplateCard
                    key={template.id}
                    template={template}
                    applyTemplate={handleUseTemplate}
                    viewTemplate={handleViewTemplate}
                  />
                ))}
              </S.TemplateCardListWrapper>
            </InfiniteScroll>
          ) : (
            <HandoutTemplateEmptyState />
          )}
        </S.ListWrapper>
      </S.TemplatesWrapper>

      {currentHandoutTemplate && <TemplateDetailsModal />}
      <Toast />

      <TemplateDeleteModal />
    </S.Wrapper>
  );
};

export default withRouter(withAppContext(HandoutTemplateScreen));
