import styled, { css } from 'styled-components';

export const DailySummariesContainer = styled.div`
  padding: 16px;
  font-family: 'Quicksand', sans-serif;

  .container-title {
    margin-bottom: 0;
  }

  .container-content {
    box-shadow: initial;
    padding: 16px 0;

    .main-content {
      display: grid;
      grid-template-rows: 1fr;
      grid-template-columns: 35% auto;
      grid-gap: 32px;

      @media (max-width: 768px) {
        grid-template-rows: auto auto;
        grid-template-columns: 1fr;
      }
    }
  }
`;

export const Header = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .Form {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    @media (max-width: 768px) {
      flex-flow: row wrap;
      justify-content: flex-start;

      .Field {
        margin-bottom: 16px;
        padding: 0;
      }
    }

    .SingleDatePicker .SingleDatePickerInput .DateInput {
      width: 15ch;

      @media (max-width: 767px) {
        width: 100%;
      }
    }

    .Button.btn-primary,
    .Button.btn-default {
      height: 40px;
      padding: 12px 16px;
      font-weight: 700;
      line-height: 14px;
    }
  }
`;

export const ClassroomTitle = styled.h4`
  ${({ theme }) => css`
    font-weight: 700;
    font-size: 20px;
    color: ${theme.black};
    margin-bottom: 24px;
  `}
`;
