import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autobind from 'autobind-decorator';

import withAppContext from 'core/hoc/withAppContext';

import './style.scss';

class FormGroupSelectorSelectableOption extends Component {
  static propTypes = {
    children: PropTypes.node,
    onClick: PropTypes.func,
    selected: PropTypes.bool,
    locked: PropTypes.bool,
    appContext: PropTypes.shape({
      primaryColor: PropTypes.string,
    }),
  };

  @autobind
  onClick(e) {
    const { locked, onClick } = this.props;

    locked ? e.stopPropagation() : onClick(e);
  }

  render() {
    const { children, locked } = this.props;

    return (
      <div
        className={`SelectableOption ${locked ? 'locked' : ''}`}
        onClick={this.onClick}
      >
        {children}
      </div>
    );
  }
}

export default withAppContext(FormGroupSelectorSelectableOption);
