import React from 'react';
import PropTypes from 'prop-types';

import Tooltip from 'components/Tooltip';
import AgendaIcon from 'components/AgendaIcon';
import OutlineBox from "components/OutlineBox";

import './style.scss';

const Delete = ({ onClick, variation }) => (
  <div className="Delete" onClick={onClick}>
    <OutlineBox variation={variation}>
      <Tooltip
        content="Excluir"
        on="hover"
      >
        <AgendaIcon
          name="trash"
        />
      </Tooltip>
    </OutlineBox>
  </div>
)

Delete.defaultProps = {
  variation: 'danger'
}

Delete.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default Delete;
