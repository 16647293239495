const normalizeParams = (values) => {
  const data = new FormData();
  const students = values.classroom_with_student_profile_ids;

  data.append('quick_access[name]', values.name);
  data.append('quick_access[link]', values.link);
  data.append('quick_access[created_for]', values.created_for);

  if (values.image && values.image.uploaded === false) {
    data.append('quick_access[image]', values.image, values.image.name);
  }

  values.classroom_ids.forEach((classroomId) => {
    data.append('quick_access[classroom_ids][]', classroomId);
  });

  Object.keys(students).forEach((classroomId) => {
    students[classroomId].forEach((studentId) => {
      data.append(
        `quick_access[classroom_with_student_profile_ids][${classroomId}][]`,
        studentId
      );
    });
  });

  return data;
};

export default normalizeParams;
