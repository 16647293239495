import React, { useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import PropTypes from 'prop-types';
import moment from 'moment';
import styled from 'styled-components';
import InputMask from 'react-input-mask';

import withAppContext, { appPropTypes } from 'core/hoc/withAppContext';
import colorWithAlpha from 'core/utils/colorWithAlpha';

import AgendaIcon from 'components/AgendaIcon';

import './style.scss';

const TimePicker = ({
  time,
  placeholder,
  disabled,
  onChange,
  onFocusChange,
  appContext: { primaryColor },
}) => {
  const initialHour = () => {
    if (typeof time !== 'string') {
      return '00';
    }

    const res = time.split(':');

    if (res.length < 2) {
      return '00';
    }

    return res[0];
  };

  const initialMinute = () => {
    if (typeof time !== 'string') {
      return '00';
    }

    const res = time.split(':');

    if (res.length < 2) {
      return '00';
    }

    return res[1];
  };

  const [value, setValue] = useState(time);
  const [open, setOpen] = useState(false);
  const [hour, setHour] = useState(initialHour);
  const [minute, setMinute] = useState(initialMinute);
  const regexTime = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/;
  const formatChars = {
    2: '[0-2]',
    5: '[0-5]',
    9: '[0-9]',
  };

  const formatTime = (valueTime) => {
    const res = valueTime.split(':');
    const timeNow = moment().hour(res[0]).minute(res[1]).second(0);

    setHour(timeNow.format('HH'));
    setMinute(timeNow.format('mm'));
    onChange(`${timeNow.format('HH')}:${timeNow.format('mm')}`);
  };

  const onFocus = () => {
    if (disabled === true) {
      return;
    }

    setOpen(true);
    onFocusChange(true);
  };

  const onOutsideClick = () => {
    setOpen(false);
    onFocusChange(false);
  };

  const increaseHour = () => {
    const timeNow = moment().hour(hour).minute(minute).second(0);

    timeNow.add(1, 'hour');

    setHour(timeNow.format('HH'));
    setValue(`${timeNow.format('HH')}:${minute}`);
    onChange(`${timeNow.format('HH')}:${minute}`);
  };

  const decreaseHour = () => {
    const timeNow = moment().hour(hour).minute(minute).second(0);

    timeNow.subtract(1, 'hour');

    setHour(timeNow.format('HH'));
    setValue(`${timeNow.format('HH')}:${minute}`);
    onChange(`${timeNow.format('HH')}:${minute}`);
  };

  const increaseMinute = () => {
    const timeNow = moment().hour(hour).minute(minute).second(0);

    if (timeNow.minute() >= 0 && timeNow.minute() < 15) {
      timeNow.minute(15);
    } else if (timeNow.minute() >= 15 && timeNow.minute() < 30) {
      timeNow.minute(30);
    } else if (timeNow.minute() >= 30 && timeNow.minute() < 45) {
      timeNow.minute(45);
    } else {
      timeNow.minute(0);
    }

    setMinute(timeNow.format('mm'));
    setValue(`${hour}:${timeNow.format('mm')}`);
    onChange(`${hour}:${timeNow.format('mm')}`);
  };

  const decreaseMinute = () => {
    const timeNow = moment().hour(hour).minute(minute).second(0);

    if (timeNow.minute() > 0 && timeNow.minute() <= 15) {
      timeNow.minute(0);
    } else if (timeNow.minute() > 15 && timeNow.minute() <= 30) {
      timeNow.minute(15);
    } else if (timeNow.minute() > 30 && timeNow.minute() <= 45) {
      timeNow.minute(30);
    } else {
      timeNow.minute(45);
    }

    setMinute(timeNow.format('mm'));
    setValue(`${hour}:${timeNow.format('mm')}`);
    onChange(`${hour}:${timeNow.format('mm')}`);
  };

  const ArrowButton = styled.button`
    border: 1px transparent solid;
    border-radius: 4px;
    background-color: transparent;
    display: inline-block;
    margin: 0;
    padding: 0;
    width: 32px;
    height: 32px;

    i {
      background-color: transparent;
      padding: 0;
      color: #999999;
    }

    &:hover i,
    &:active,
    &:focus {
      color: ${primaryColor};
    }

    &:hover {
      border-color: ${colorWithAlpha(primaryColor, 0.2)};
      background-color: ${colorWithAlpha(primaryColor, 0.1)};
    }

    &:active,
    &:focus {
      border-color: ${colorWithAlpha(primaryColor, 0.3)};
      background-color: ${colorWithAlpha(primaryColor, 0.2)};
    }
  `;

  const TimePickerWidget = () => (
    <div className="TimePickerWidget">
      <table>
        <tbody>
          <tr>
            <td>
              <ArrowButton type="button" onClick={increaseHour}>
                <AgendaIcon name="arrow-up" />
              </ArrowButton>
            </td>

            <td className="separator" />

            <td>
              <ArrowButton type="button" onClick={increaseMinute}>
                <AgendaIcon name="arrow-up" />
              </ArrowButton>
            </td>
          </tr>

          <tr>
            <td>
              <span className="TimePickerHour">{hour}</span>
            </td>

            <td className="separator">:</td>

            <td>
              <span className="TimePickerHour">{minute}</span>
            </td>
          </tr>

          <tr>
            <td>
              <ArrowButton type="button" onClick={decreaseHour}>
                <AgendaIcon name="arrow-down" />
              </ArrowButton>
            </td>

            <td className="separator" />

            <td>
              <ArrowButton type="button" onClick={decreaseMinute}>
                <AgendaIcon name="arrow-down" />
              </ArrowButton>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );

  const clearTime = () => {
    setValue('');
    onChange('');
    setHour('00');
    setMinute('00');
  };

  const onValueChange = (event) => {
    event.preventDefault();

    setValue(event.target.value);

    if (regexTime.exec(event.target.value)) {
      formatTime(event.target.value);
    }
  };

  return (
    <OutsideClickHandler onOutsideClick={onOutsideClick}>
      <div className="TimePicker">
        {open && TimePickerWidget()}

        <div className={`TimePickerInput ${open ? 'focused' : ''}`}>
          <InputMask
            type="text"
            className="form-control"
            onChange={onValueChange}
            value={value}
            disabled={disabled}
            placeholder={placeholder}
            onFocus={onFocus}
            autoComplete="off"
            mask="29:59"
            formatChars={formatChars}
            maskChar="0"
          />

          {value && (
            <button type="button" className="ClearTime" onClick={clearTime}>
              <AgendaIcon name="close" size="medium" />
            </button>
          )}
        </div>
      </div>
    </OutsideClickHandler>
  );
};

TimePicker.defaultProps = {
  time: '',
  placeholder: '',
  disabled: false,
  onChange: () => {},
  onFocusChange: () => {},
  ...appPropTypes,
};

TimePicker.propTypes = {
  time: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onFocusChange: PropTypes.func,
};

export default withAppContext(TimePicker);
