import validatePresence from 'core/lib/FormValidator/validators/presence';

export default [
  {
    attributeName: 'wallet_name',
    validator: validatePresence,
    message: 'Campo obrigatório',
  },
  {
    attributeName: 'document_number',
    validator: validatePresence,
    message: 'Campo obrigatório',
  },
];
