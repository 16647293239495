import styled, { css } from 'styled-components';
import { ThemeProps } from 'config/themes/types';

import { AnimationStyled } from './types';

const handleAnimationModifiers = {
  fade: {
    open: () => css`
      animation: fade-in 0.2s linear forwards;
    `,
    close: () => css`
      animation: fade-out 0.2s linear forwards;
    `,
  },
  slide: {
    open: () => css`
      animation: right-slide-in 0.3s linear forwards;
    `,
    close: () => css`
      animation: right-slide-out 0.3s linear forwards;
    `,
  },
};

export const SideSheetWrapper = styled.div<AnimationStyled>`
  ${({ animation, type }) => css`
    position: fixed;
    overflow: hidden;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    background: rgba(0, 0, 0, 0.7);

    ${handleAnimationModifiers[type][animation]()}
  `}
`;

export const SideSheetContainer = styled.section<AnimationStyled>`
  ${({ animation, type, theme: { space, colors } }) => css`
    position: absolute;
    height: 100vh;
    width: 375px;
    top: 0;
    right: 0;
    z-index: 2;
    border-top-left-radius: ${space.sm};
    border-bottom-left-radius: ${space.sm};
    background: ${colors.neutral.white};

    @media (max-width: 768px) {
      width: 100%;
      border: ${space.xs4};
      border-top-left-radius: ${space.xs4};
      border-bottom-left-radius: ${space.xs4};
    }

    ${handleAnimationModifiers[type][animation]()}
  `}
`;

export const HeaderWrapper = styled.div`
  ${({ theme: { space, colors, border } }: ThemeProps) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${space.sm} ${space.lg};
    border-bottom: ${border.style.solid} ${border.width.sm}
      ${colors.neutral.gray5};
  `}
`;

export const Title = styled.h1`
  ${({ theme: { colors, typography } }: ThemeProps) => css`
    ${typography.title.Bold20}
    color: ${colors.neutral.black};
    margin: 0;
  `}
`;

export const CloseButton = styled.div`
  display: flex;
  cursor: pointer;
`;

export const BottomWrapper = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    position: absolute;
    display: flex;
    width: 100%;
    bottom: 0;
    gap: ${space.sm};
    align-items: center;
    padding: ${space.sm} ${space.lg};

    > button {
      width: 100%;
    }
  `}
`;
