import React, { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import OutsideClickHandler from 'react-outside-click-handler';
import { Icon, Button } from '@agendaedu/ae-web-components';
import * as S from './styles';

import { OwnerProps, Animation } from './types';

export type SideSheetProps = OwnerProps;

export const SideSheet = ({
  children,
  disabled = false,
  isOpen,
  title,
  handleClear,
  handleDismiss,
  handleSubmit,
}: OwnerProps) => {
  const { t } = useTranslation(['common']);
  const [animation, setAnimation] = useState<Animation>('open');

  const FADE_OUT_DURATION = 250;

  const handleOnDismiss = useCallback(() => {
    setAnimation('close');
    setTimeout(handleDismiss, FADE_OUT_DURATION);
  }, [handleDismiss]);

  const handleOnSubmit = useCallback(() => {
    handleOnDismiss();
    handleSubmit();
  }, [handleSubmit, handleOnDismiss]);

  const handleOnClear = useCallback(() => {
    handleOnDismiss();
    handleClear();
  }, [handleClear, handleOnDismiss]);

  useEffect(() => {
    if (isOpen) setAnimation('open');
  }, [isOpen, setAnimation]);

  return (
    isOpen && (
      <S.SideSheetWrapper
        id="side-sheet-wrapper"
        animation={animation}
        type="fade"
      >
        <OutsideClickHandler onOutsideClick={handleOnDismiss}>
          <S.SideSheetContainer
            id="side-sheet"
            animation={animation}
            type="slide"
          >
            <S.HeaderWrapper>
              <S.Title>{title}</S.Title>
              <S.CloseButton onClick={handleOnDismiss}>
                <Icon name="multiply" />
              </S.CloseButton>
            </S.HeaderWrapper>
            {children}
            <S.BottomWrapper>
              <Button disabled={disabled} onClick={handleOnSubmit}>
                {t('button.apply')}
              </Button>
              <Button variant="secondary" onClick={handleOnClear}>
                {t('button.clear')}
              </Button>
            </S.BottomWrapper>
          </S.SideSheetContainer>
        </OutsideClickHandler>
      </S.SideSheetWrapper>
    )
  );
};
