import React from 'react';
import PropTypes from 'prop-types';

import FormContext from 'core/contexts/Form';

import getHocName from 'core/utils/getHocName';

/**
 * @todo Adjust form components that are not using this core/hoc.
 * @example
 *    import withFormContext, { formPropTypes } from 'core/hoc/withFormContext'
 *
 *    const Component = props => <div>{props.formContext.form.title}</div>
 *
 *    Component.propTypes = {
 *      ...formPropTypes
 *    };
 *
 *    export default withFormContext(Component)
 */
const withFormContext = (WrappedComponent) => {
  const WithFormContext = (props) => {
    return (
      <FormContext.Consumer>
        {(formContext) => {
          return <WrappedComponent {...props} formContext={formContext} />;
        }}
      </FormContext.Consumer>
    );
  };

  WithFormContext.displayName = getHocName('WithFormContext', WrappedComponent);

  return WithFormContext;
};

export const formPropTypes = {
  formContext: PropTypes.shape({
    form: PropTypes.object,
    formMeta: PropTypes.object,
    changeAttribute: PropTypes.func,
    changeMeta: PropTypes.func,
    onSubmit: PropTypes.func,
    loadOptionsToSelect: PropTypes.func,
  }),
};

export default withFormContext;
