import { DefaultThemeProps } from '@agendaedu/ae-web-components';
import styled, { css } from 'styled-components';

export const KindWrapper = styled.div`
  ${({ theme: { space, breakpoints } }: { theme: DefaultThemeProps }) => css`
    display: flex;
    margin-top: ${space.lg};
    gap: ${space.sm};

    @media (max-width: ${breakpoints.mobileSM}) {
      flex-wrap: wrap;
    }
  `}
`;

export const SelectItemWrapper = styled.div<{ isChecked: boolean }>`
  ${({
    theme: { space, border, colors },
    isChecked,
  }: {
    theme: DefaultThemeProps;
    isChecked: boolean;
  }) => css`
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    border: ${border.width.sm} ${border.style.solid}
      ${isChecked ? colors.brand.primary.op10 : colors.neutral.gray4};
    padding: ${space.sm};
    background-color: ${isChecked
      ? colors.brand.primary.op10
      : colors.neutral.white};
    border-radius: ${border.radius.md};
    cursor: pointer;
  `}

  label {
    margin-top: unset;
  }
`;
