import { ThemeProps } from 'config/themes/types';
import styled, { css } from 'styled-components';

const contentAlignment = {
  horizontally: () => css`
    display: flex;
    align-items: center;
    flex-direction: column;

    @media (min-width: 768px) {
      flex-direction: row;
    }
  `,
  vertically: () => css`
    display: flex;
    align-items: center;
    flex-direction: column;
  `,
};

export const Container = styled.div<{
  alignment: 'horizontally' | 'vertically';
  centered?: boolean;
}>`
  height: 100%;
  ${({ theme, alignment, centered }) => css`
    background-color: ${theme.white} !important;

    ${alignment && contentAlignment[alignment]}

    ${centered &&
    css`
      justify-content: center;
    `}
  `}
`;

export const PageTitle = styled.h2`
  height: 17px;
  width: 247px;
  left: 48px;
  top: 33px;
  padding: 0px 0px 50px 25px;
  font-size: 24px;
  ${({ theme: { colors, typography } }: ThemeProps) => css`
    ${typography.title.Bold20};

    color: ${colors.neutral.black};
  `}
`;
