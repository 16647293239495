import styled from 'styled-components';

export const TotalReports = styled.div`
  font-family: 'Quicksand';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  font-stretch: normal;
  line-height: 14px;
  color: $gray-1;
  text-align: right;
  letter-spacing: normal;
  margin-top: 40px;

  @media (max-width: 500px) {
    .card-container {
      flex-direction: unset;
      align-items: unset;

      .card-head .head-info a {
        max-width: calc(100vw - 200px);
        margin-top: 16px;
      }
    }
  }
`;
