import styled, { css } from 'styled-components';
import { ThemeProps } from 'config/themes/types';
import { Box } from '@agendaedu/ae-web-components';

export const WrapperContent = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    word-break: break-all;
    width: 100%;
    padding: ${space.xs2} ${space.sm} ${space.xs4};
  `}
`;

export const Informations = styled.div`
  ${({ theme: { colors, typography, space } }: ThemeProps) => css`
    min-width: 130px;

    &:not(:first-child) {
      margin-left: 3rem;
    }

    span {
      text-align: left;
      ${typography.label.Medium12};
      color: ${colors.neutral.gray1};
    }

    div {
      display: flex;
      flex-direction: row;
      align-items: center;

      svg {
        margin-right: ${space.xs};
      }

      strong {
        text-align: left;
        ${typography.label.Medium16};
        color: ${colors.neutral.gray2};
      }
    }
  `}
`;

export const Title = styled.h5<{ mt?: number; ml?: number }>`
  ${({ theme: { colors, typography, space, font } }: ThemeProps) => css`
    ${typography.title.Bold14};
    font-weight: ${font.weight.medium};
    color: ${colors.neutral.gray1};
    margin: ${space.xs4};
  `}

  ${(props) => !!props.mt && `margin-top: ${props.mt}px`};
  ${(props) => !!props.ml && `margin-left: ${props.ml}px`};
`;

export const Subtitle = styled.h4`
  ${({ theme: { colors, typography, space } }: ThemeProps) => css`
    ${typography.subtitle.Medium12};

    color: ${colors.neutral.gray2};
    margin: ${space.xs4};
  `}
`;

export const BoxBlur = styled(Box)<{ isBlur: boolean }>`
  ${({ theme: { space } }: ThemeProps) => css`
    padding: ${space.xs2};
  `}
  ${(props) =>
    props.isBlur &&
    `
    -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
    filter: blur(5px);
 `}
`;
