import React, { useCallback, useMemo, useEffect, lazy, Suspense } from 'react';
import _ from 'lodash';

import withFormContext, { formPropTypes } from 'core/hoc/withFormContext';
import tabifyForWizard from 'core/hoc/tabifyForWizard';

import FormFieldset from 'components/Form/Fieldset';
import Field from 'components/Form/Field';
import AgendaIcon from 'components/AgendaIcon';

import * as S from './styles';

const SettingsTab = ({ formContext: { form, updateAttribute } }) => {
  const { classroomsDiarySections, sections, activeSection, draftSections } =
    form;

  const OPTIONS = useMemo(
    () => [{ label: 'Selecionar', value: '' }, ...sections],
    [sections]
  );

  const initialDraftSections = useMemo(
    () =>
      sections.reduce(
        (prev, current) => ({
          ...prev,
          [current.value]: current.classrooms.sort((idA, idB) => idA - idB),
        }),
        {}
      ),
    [sections]
  );

  const getClassroomsSelecteds = useCallback(
    (values) => {
      if (activeSection) {
        updateAttribute('draftSections', {
          ...draftSections,
          [activeSection]: values.sort((a, b) => a - b),
        });
      }
    },
    [activeSection, draftSections, updateAttribute]
  );

  const validationSelectedClassrooms = useMemo(
    () =>
      _.isEqual(
        draftSections[activeSection],
        initialDraftSections[activeSection]
      ),
    [draftSections, activeSection, initialDraftSections]
  );

  const renderEmptyState = !activeSection ? (
    <span>
      Selecione uma seção para <br /> exibir as turmas desabilitadas
    </span>
  ) : (
    <div>
      <AgendaIcon name="user-group" size="large" />
      <div>Selecione uma turma</div>
    </div>
  );

  const GroupSelector = useMemo(
    () => lazy(() => import('components/Form/GroupSelector')),
    [activeSection, classroomsDiarySections]
  );

  useEffect(() => {
    updateAttribute(
      'canSave',
      !!activeSection && !validationSelectedClassrooms
    );
  }, [activeSection, updateAttribute, validationSelectedClassrooms]);

  useEffect(() => {
    if (activeSection) {
      updateAttribute('draftSections', initialDraftSections);
    }
  }, [activeSection, initialDraftSections, updateAttribute]);

  return (
    <FormFieldset>
      <S.SettingsDescription>
        Escolha uma seção do Diário e selecione as turmas que terão acesso à
        ela. Do lado esquerdo estarão as turmas cuja seção selecionada ficará
        desabilitada após o salvamento, enquanto que, do lado direito, as turmas
        habilitadas. Você pode configurar uma seção por vez.
      </S.SettingsDescription>
      <S.DiarySectionContainer>
        <div className="row">
          <Field
            label="Seção"
            attributeName="activeSection"
            placeholder="Selecione"
            fullWidth
            size="large"
            mobileSize="extraLarge"
            type="selectWithIcon"
            options={OPTIONS}
            withArrowSelect
            onChange={({ value }) => updateAttribute('activeSection', value)}
          />
          <Suspense fallback={null}>
            <GroupSelector
              options={activeSection ? classroomsDiarySections : []}
              selectedValues={draftSections[activeSection] || []}
              onChange={getClassroomsSelecteds}
              emptyState={renderEmptyState}
              counterLabel={(length) =>
                length === 1 ? 'turma desabilitada' : 'turmas desabilitadas'
              }
            />
          </Suspense>
        </div>
      </S.DiarySectionContainer>
    </FormFieldset>
  );
};

SettingsTab.propTypes = {
  ...formPropTypes,
};

export default tabifyForWizard({
  subTitle: 'Habilitar e desabilitar seções',
})(withFormContext(SettingsTab));
