import styled, { css } from 'styled-components';

import FormFieldset from 'components/Form/Fieldset';

import { ThemeProps } from 'config/themes/types';

export const FormFieldWrapper = styled(FormFieldset)``;

export const FieldsWrapper = styled.section`
  ${({ theme: { breakpoints, space } }: ThemeProps) => css`
    display: flex;
    flex-direction: column;
    gap: ${space.lg};

    textarea {
      min-height: 112px !important;
    }

    @media (max-width: ${breakpoints.mobileSM}) {
      textarea {
        min-height: 260px !important;
      }
    }
  `}
`;
