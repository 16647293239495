import moment from 'moment';
import { Days } from 'store/messages/omniChannel/types';

export const validateFormErrors = (
  attributeName: string,
  getFormValidationResult: (attributeName: string) => { messages: string[] },
  hasErrorOnAttribute: (attributeName: string) => boolean
) => {
  const errorMessages = getFormValidationResult(attributeName)?.messages[0];
  const hasError = hasErrorOnAttribute(attributeName);

  return {
    hasError,
    errorMessages,
  };
};

export const isAfterHours = (hours: string[]): boolean => {
  if (!hours[0] || !hours[1]) return false;

  const startHour = moment(hours[0], 'HH:mm');
  const endHour = moment(hours[1], 'HH:mm');

  return startHour.isAfter(endHour) || startHour.isSame(endHour);
};

export const allDaysInactive = (days: Days) => {
  return Object.values(days).every((day) => !day.active);
};
