import React from 'react';
import PropTypes from 'prop-types';

import RadioButton from 'components/RadioButton';
import FormValidationErrors from 'components/Form/ValidationErrors';

import withFormContext, { formPropTypes } from 'core/hoc/withFormContext';

import './style.scss';

function RadioGroup({
  radios,
  attributeName,
  variation,
  formContext,
  disabled,
}) {
  const { form, updateAttribute, hasErrorOnAttribute } = formContext;
  const hasError = hasErrorOnAttribute(attributeName);

  const onChange = (event) => {
    if (disabled) return;

    updateAttribute(attributeName, event.target.value);
  };

  return (
    <>
      <div className={`RadioGroup ${variation}`}>
        {radios.map((radio) => (
          <RadioButton
            labelText={radio.labelText}
            attributeName={attributeName}
            onChange={(event) => onChange(event)}
            value={radio.value}
            key={radio.value}
            checked={form[attributeName] === radio.value}
            disabled={disabled}
            icon={radio.icon}
          />
        ))}
      </div>
      {hasError && <FormValidationErrors attributeName={attributeName} />}
    </>
  );
}

RadioGroup.propTypes = {
  radios: PropTypes.arrayOf(PropTypes.object).isRequired,
  attributeName: PropTypes.string.isRequired,
  variation: PropTypes.oneOf(['column', 'inline']),
};

RadioGroup.defaultProps = {
  variation: 'inline',
  ...formPropTypes,
  disabled: false,
};

export default withFormContext(RadioGroup);
