import styled, { css } from 'styled-components';

const centerFlex = `
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AvatarWrapper = styled.div`
  position: relative;
  width: 146px;
  height: 146px;
  margin-top: 8px;
`;

export const AvatarThumbnail = styled.div`
  ${centerFlex}
  width: 100%;
  height: 100%;
  background-color: #eeeeee;
  border-radius: 50%;
`;

export const AvatarPreview = styled.div`
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    -o-object-fit: cover;
    object-position: center;
    -o-object-position: center;
    border-radius: 50%;
  }
`;

/** Wrapper para alinhar os botões de forma flexível */
export const ButtonsWrapper = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-between; /* Posiciona os botões nos cantos opostos */
  padding: 0 8px; /* Espaçamento lateral opcional */
`;

/** Estilo do botão da lixeira */
export const TrashButton = styled.button`
  ${centerFlex}
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: #f5f5f5;
  color: #ff5c5c;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: #ff5c5c;
    color: white;
  }
`;

/** Estilo do botão da câmera */
export const CameraButton = styled.button`
  ${({ theme }) => css`
    ${centerFlex}
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: #f5f5f5;
    color: ${theme.primaryColor};
    border: none;
    cursor: pointer;

    &:hover {
      background-color: ${theme.primaryColor};
      color: white;
    }

    .file-input-btn {
      display: inline-block;
      position: absolute;
      width: 100%;
      height: 100%;
    }

    .file-input-btn input[type='file'] {
      opacity: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
  `}
`;

export const userIconStyle = {
  fontSize: '65px',
  color: '#999999',
};

export const cameraIconStyle = {
  fontSize: '25px',
};

export const trashIconStyle = {
  fontSize: '25px',
};
