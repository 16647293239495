/* eslint-disable react/button-has-type */
/* eslint-disable import/no-unresolved */
import React from 'react';
import PropTypes from 'prop-types';

import AgendaIcon from 'components/AgendaIcon';

import './style.scss';

const Button = (props) => {
  const {
    children,
    variation,
    size,
    outline,
    icon,
    onClick,
    disabled,
    className,
    type,
  } = props;

  return (
    <button
      className={`Button btn btn-${variation} ${className} ${size} ${outline ? 'outline' : ''}`}
      onClick={onClick}
      disabled={disabled}
      type={type}
    >
      {icon && (
        <div className="button-icon">
          <AgendaIcon name={icon} size="small" />
        </div>
      )}
      {children}
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  outline: PropTypes.bool,
  onClick: PropTypes.func,
  type: PropTypes.oneOf(['submit', 'button', 'reset']),
  variation: PropTypes.oneOf(
    ['primary',
      'secondary',
      'default',
      'success',
      'danger',
      'white',
    ],
  ),
  size: PropTypes.oneOf(['', 'btn-lg', 'btn-sm', 'btn-xs']),
  icon: PropTypes.string,
};

Button.defaultProps = {
  className: '',
  variation: 'primary',
  disabled: false,
  type: 'button',
  size: '',
  outline: false,
  icon: undefined,
  onClick: () => {},
};

export default Button;
