import React from 'react';
import PropTypes from 'prop-types';
import autobind from 'autobind-decorator';
import _ from 'lodash';

import FormValidator from 'core/lib/FormValidator';

import FormContext from 'core/contexts/Form';

import './style.scss';

/**
 * This is a component for storing forms states and informations about it.
 *
 * The state will be passed in FormContext and become accessible by any of its
 * children without cascading the state manually.
 *
 * The state consists of multiple parts:
 *  - form: The actual form state that should be uploaded back to the server.
 *  - formMeta: Informations that the form should be using such as
 *      select_options and others.
 *  - formValidationResult: The stored result from the validations run.
 *  - action: the form's action. In general it'll be either 'new' or 'edit'.
 *      There'll be cases in which we need to disable some inputs based on the
 *      form's action.
 *
 *  All other objects in state will be functions abstractions about the form's
 *  behavior.
 *
 *  @example
 */
class FormContainer extends React.Component {
  actions = {
    updateFormContext: this.updateFormContext,
    changeAttribute: this.changeAttribute,
    changeMeta: this.changeMeta,
    addErrorOnAttribute: this.addErrorOnAttribute,
    removeErrorOnAttribute: this.removeErrorOnAttribute,
    loadOptionsToSelect: this.loadOptionsToSelect,
    getOptionsForSelect: this.getOptionsForSelect,
    validateFormState: this.validateFormState,
    validateOnChange: this.validateOnChange,
    getFormValidationResult: this.getFormValidationResult,
    hasErrorOnAttribute: this.hasErrorOnAttribute,
    addErrorsToFormValidationResultFromServer:
      this.addErrorsToFormValidationResultFromServer,
    updateAttribute: this.updateAttribute,
    onUpdateAttribute: this.props.onUpdateAttribute,
    initialAttributes: this.props.initialAttributes,
    hasUpdatesOnForm: this.hasUpdatesOnForm,
    resetForm: this.resetForm,
  };

  constructor(props) {
    super(props);

    const { action, form, formMeta, onChangeValidations, provider } = props;

    this.state = {
      provider,
      action,
      form,
      formMeta,
      onChangeValidations,
      formValidationResult: {},
      hasBackendValidationError: false,
      ...this.actions,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { initialAttributes, formMeta } = nextProps;

    const newState = {
      form: { ...initialAttributes, ...prevState.form },
      formMeta: { ...formMeta, ...prevState.formMeta },
    };

    return newState;
  }

  /**
   * This function will be used in more complex cases.
   * @param {Function} callback a function used to access this scope and update
   * the form state.
   * @example
   *    <FormContext.Consumer>
   *      ({updateFormContext}) => {
   *        updateFormContext(({
   *          prevFormState,
   *          buildFormState,
   *          buildFormMeta
   *        }) => {
   *          return {
   *            ...buildFormState(prevFormState. { 'key': 'value' }),
   *            ...buildFormMeta(prevFormState, 'keyInsidemeta', 'another value')
   *          }
   *        }
   *      }
   *    </FormContext.Consumer>
   */
  @autobind
  updateFormContext(callback) {
    this.setState((prevState) => {
      const newState = callback({
        prevFormState: prevState,
        buildFormState: this.buildFormState,
        buildFormMeta: this.buildFormMeta,
      });

      return newState || prevState;
    });
  }

  /**
   *
   * @param {object} prevState the form previous state
   * @param {String} attributeName the accessor to be used.
   * @param {any} value the value to be assigned to the attributeName
   * @returns {object} The object to be used in the form's setState.
   * @example
   * const prevState = { form: { name: 'Renan' } };
   *
   * buildFormState(prevState, { age: 30 });
   * // Expected value:
   * { form: { name: 'Renan', age: 30 } }
   */
  buildFormState(prevState, object) {
    const { form } = prevState;

    return {
      form: {
        ...form,
        ...object,
      },
    };
  }

  /**
   *
   * @param {object} prevState the form previous state
   * @param {String} key the accessor to be used.
   * @param {any} value the value to be assigned to the key
   * @returns {object} The object to be used in the form's setState.
   * @example
   * const prevState = { form: { name: 'Renan' } };
   *
   * buildFormMeta(prevState, 'renan', 30);
   * // Expected value:
   * { formMeta: { renan: 30 }
   */
  buildFormMeta(prevState, key, value) {
    const { formMeta } = prevState;

    return {
      formMeta: {
        ...formMeta,
        [key]: value,
      },
    };
  }

  buildFormValidationResult(prevState, key, value) {
    const { formValidationResult } = prevState;

    return {
      formValidationResult: {
        ...formValidationResult,
        [key]: value,
      },
    };
  }

  /**
   * This method should be passed as a function to inputs so the form can keep
   * track of its state.
   * @param {String} attributeName - The name of one of the form's attributes.
   * @param {?Function} callback - a callback yielding the form's state
   * @returns {Function} - The onChange function to be assigned to inputs.
   * @example
   * <input type="text" onChange={changeAttribute('studentName')} />
   */
  @autobind
  changeAttribute(attributeName, callback) {
    return (event) => {
      const value = event.target.value;

      this.setState(
        (prevState) => {
          return this.buildFormState(prevState, { [attributeName]: value });
        },
        () => callback && callback(this.state.form, this.state)
      );

      return event;
    };
  }

  /**
   * Changes the meta of the state.
   * @param {string} metaKey
   * @param {(object|Function)} metaValue
   */
  @autobind
  changeMeta(metaKey, value) {
    this.setState((prevState) => this.buildFormMeta(prevState, metaKey, value));
  }

  @autobind
  changeValidationResult(validationKey, value) {
    this.setState((prevState) =>
      this.buildFormValidationResult(prevState, validationKey, value)
    );
  }

  @autobind
  updateAttribute(attributeName, value, callback) {
    this.setState(
      (prevState) => {
        return this.buildFormState(prevState, { [attributeName]: value });
      },
      () => {
        this.props.onUpdateAttribute?.(this.state.form);

        return callback && callback(this.state);
      }
    );
  }

  /**
   * This method should be used when we want to change the form information
   * from itself. Example: load a select tag with cities after the user select
   * their state.
   * @param {string} attributeName
   * @param {any} value
   */
  @autobind
  loadOptionsToSelect(attributeName, value, callback) {
    this.setState(
      (prevState) => {
        const { formMeta } = prevState;
        const { select_options } = formMeta;

        const newMeta = this.buildFormMeta(prevState, 'select_options', {
          ...select_options,
          [attributeName]: value,
        });

        return newMeta;
      },
      () => callback && callback(this.state)
    );
  }

  @autobind
  getOptionsForSelect(attributeName) {
    const { formMeta } = this.state;
    return formMeta.select_options && formMeta.select_options[attributeName];
  }

  @autobind
  validateFormState(validations, onChangeValidations = []) {
    const formValidator = new FormValidator([
      ...validations,
      ...onChangeValidations,
    ]);
    const newFormValidationResult = formValidator.validate(this.state.form);
    var isValid = FormValidator.isResultValid(newFormValidationResult);

    this.setState((prevState) => {
      const { formValidationResult: prevFormValidationResult } = prevState;

      const formValidationResult = FormValidator.combineValidationResults(
        {},
        newFormValidationResult
      );

      return { formValidationResult };
    });

    return { formValidationResult: newFormValidationResult, isValid };
  }

  @autobind
  validateOnChange(attributeName) {
    const { onChangeValidations } = this.props;
    const attributeValidations = onChangeValidations(this.state).filter(
      (validation) => {
        return validation.attributeName === attributeName;
      }
    );
    const attributeValue = this.state.form[attributeName];
    const formValidator = new FormValidator(attributeValidations);
    const newFormValidationResult = formValidator.validate({
      [attributeName]: attributeValue,
    });
    const isValid = FormValidator.isResultValid(newFormValidationResult);

    this.setState((prevState) => {
      const { formValidationResult } = prevState;

      return {
        formValidationResult: {
          ...formValidationResult,
          [attributeName]: newFormValidationResult[attributeName],
        },
      };
    });

    return { formValidationResult: newFormValidationResult, isValid };
  }

  @autobind
  addErrorsToFormValidationResultFromServer(response) {
    const formValidationResult =
      FormValidator.buildValidationsFromServerResponse(response);

    this.setState({ formValidationResult });
  }

  @autobind
  addErrorOnAttribute(attributeName, message) {
    this.changeValidationResult(attributeName, {
      isValid: false,
      messages: Array.isArray(message) ? message : [message],
    });
  }

  @autobind
  removeErrorOnAttribute(attributeName) {
    this.changeValidationResult(attributeName, { isValid: true, messages: [] });
  }

  @autobind
  getFormValidationResult(attributeName) {
    return this.state.formValidationResult[attributeName];
  }

  @autobind
  hasErrorOnAttribute(attributeName) {
    const attributeValidation = this.getFormValidationResult(attributeName);
    const hasAttribute = !!attributeValidation;

    if (!hasAttribute) return false;

    return !attributeValidation.isValid;
  }

  @autobind
  hasUpdatesOnForm() {
    const initialAttributes = _.mapValues(
      this.props.initialAttributes,
      (value) => (value === null ? '' : value)
    );

    const form = _.mapValues(this.state.form, (value) =>
      value === null ? '' : value
    );

    if (this.state.formMeta.ignoreFieldsOnUpdatesCheck) {
      this.state.formMeta.ignoreFieldsOnUpdatesCheck.map((field) => {
        delete form[field];
      });
    }

    const isFormEqual = _.isEqual(form, initialAttributes);

    return !isFormEqual;
  }

  @autobind
  resetForm() {
    const { initialAttributes } = this.props;
    const { form } = this.state;
    this.setState({ form: { ...form, ...initialAttributes } });
  }

  @autobind
  onSubmit(event) {
    event.preventDefault();
    event.stopPropagation();

    const { onSubmit } = this.props;

    onSubmit && onSubmit(this.state.form);
  }

  render() {
    const { variation } = this.props;

    return (
      <form className={`Form ${variation}`} onSubmit={this.onSubmit}>
        <FormContext.Provider value={this.state}>
          {this.props.children}
        </FormContext.Provider>
      </form>
    );
  }
}

FormContainer.propTypes = {
  action: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
  initialAttributes: PropTypes.object,
  form: PropTypes.object,
  formMeta: PropTypes.object,
  onChangeValidations: PropTypes.func,
  onSubmit: PropTypes.func,
  onUpdateAttribute: PropTypes.func,
  variation: PropTypes.oneOf(['vertical', 'horizontal']),
};

FormContainer.defaultProps = {
  variation: 'vertical',
  initialAttributes: {},
  formMeta: {},
  onChangeValidations: () => [],
};

export default FormContainer;
