import createModuleReducer from '../../storeConfig/createModuleReducer';
import actions from './actions';

const initForm = {
  id: null,
  name: '',
};

export const INITIAL_STATE = {
  recurrentProducts: [],
  paginate: {},
  formValues: initForm,
  isLoading: true,
  modalOpen: false,
  modalDeleteOpen: false,
  sending: false,
  current: null,
  error: null,
};

export const fetchRequest = (state) => ({
  ...state,
  isLoading: true,
});

export const fetchSuccess = (state, action) => ({
  ...state,
  recurrentProducts: action.data,
  sending: false,
  paginate: action.paginate,
  isLoading: false,
});

export const setRecurrentProduct = (state, action) => {
  const recurrentProduct = state.recurrentProducts.find(
    (product) => product.id === action.id
  );

  return {
    ...state,
    current: recurrentProduct,
    formValues: {
      id: recurrentProduct.id,
      name: recurrentProduct.attributes.name,
    },
  };
};

export const unselect = (state) => ({
  ...state,
  modalOpen: true,
  formValues: initForm,
  current: null,
});

export const toogleModal = (state) => ({
  ...state,
  modalOpen: !state.modalOpen,
});

export const toogleDeleteModal = (state) => ({
  ...state,
  modalDeleteOpen: !state.modalDeleteOpen,
});

export const addRecurrentProduct = (state) => ({
  ...state,
  sending: true,
});

export const addRecurrentProductSuccess = (state, action) => ({
  ...state,
  sending: false,
  recurrentProducts: [action.data, ...state.recurrentProducts],
});

export const editRecurrentProduct = (state) => ({
  ...state,
  sending: true,
});

export const editRecurrentProductSuccess = (state, action) => ({
  ...state,
  sending: false,
  recurrentProducts: state.recurrentProducts.map((product) =>
    product.id === action.data.id ? { ...action.data } : product
  ),
});

export const disableRecurrentProductSuccess = (state, action) => ({
  ...state,
  modalDeleteOpen: false,
  recurrentProducts: state.recurrentProducts.filter(
    (product) => product.id !== action.data.id
  ),
});

export const handleError = (state, action) => ({
  ...state,
  sending: false,
  error: action.error,
});

const HANDLERS = {
  [actions.FETCH_REQUEST]: fetchRequest,
  [actions.FETCH_SUCCESS]: fetchSuccess,
  [actions.SET]: setRecurrentProduct,
  [actions.UNSELECT]: unselect,
  [actions.ADD_RECURRENT_PRODUCT]: addRecurrentProduct,
  [actions.ADD_RECURRENT_PRODUCT_SUCCESS]: addRecurrentProductSuccess,
  [actions.EDIT_RECURRENT_PRODUCT]: editRecurrentProduct,
  [actions.EDIT_RECURRENT_PRODUCT_SUCCESS]: editRecurrentProductSuccess,
  [actions.DISABLE_RECURRENT_PRODUCT_SUCCESS]: disableRecurrentProductSuccess,
  [actions.TOGGLE_MODAL]: toogleModal,
  [actions.TOGGLE_DELETE_MODAL]: toogleDeleteModal,
  [actions.ERROR]: handleError,
};

const recurrentProducts = createModuleReducer(INITIAL_STATE, HANDLERS);

export default recurrentProducts;
