import React from 'react'
import PropTypes from 'prop-types'

import './Title.scss'

export default class Title extends React.Component {
  render() {
    const { name, className } = this.props;

    return <h3 className={`Title ${className}`}>{name}</h3>
  }
}
