import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autobind from 'autobind-decorator';
import FormSelectorSelectableOption from 'components/Form/Selector/SelectableOption';
import OutlineBox from 'components/OutlineBox';

import colorWithAlpha from 'core/utils/colorWithAlpha';
import withAppContext from 'core/hoc/withAppContext';

import './style.scss';

class FormSelectorSelectableColumn extends Component {
  static propTypes = {
    options: PropTypes.array.isRequired,
    optionComponent: PropTypes.func.isRequired,
    onOptionSelect: PropTypes.func.isRequired,
    onOptionDeselect: PropTypes.func.isRequired,
    optionAccessor: PropTypes.func,
    selectAllLabel: PropTypes.string.isRequired,
    onSelectBehavior: PropTypes.string,
    selected: PropTypes.array,
    selectAll: PropTypes.func,
    counterLabel: PropTypes.func,
    appContext: PropTypes.shape({
      primaryColor: PropTypes.string,
    }),
    emptyState: PropTypes.node,
  };

  state = {
    isHovering: false,
  };

  @autobind
  renderOptions() {
    const {
      optionComponent,
      options,
      onSelectBehavior,
      selected,
      optionAccessor,
    } = this.props;

    return options.map((option, index) => (
      <FormSelectorSelectableOption
        key={`opt-${index}`}
        onClick={() => this.onOptionSelect(option)}
        selected={
          onSelectBehavior === 'highlight' &&
          selected.find((opt) => optionAccessor(opt) === optionAccessor(option))
        }
      >
        {optionComponent(option)}
      </FormSelectorSelectableOption>
    ));
  }

  @autobind
  onOptionSelect(option) {
    const { onOptionSelect, onOptionDeselect, selected, optionAccessor } =
      this.props;

    const isSelected = selected.find(
      (opt) => optionAccessor(opt) === optionAccessor(option)
    );

    if (isSelected) {
      onOptionDeselect({
        option,
        placeOption: this.placeOption,
        removeSelectedOption: this.removeSelectedOption,
      });
    } else {
      onOptionSelect({
        option,
        placeOption: this.placeOption,
        removeSelectedOption: this.removeSelectedOption,
      });
    }
  }

  @autobind
  placeOption(options, option) {
    const { optionAccessor } = this.props;

    if (!options.find((opt) => optionAccessor(opt) == optionAccessor(option))) {
      options.push(option);
    }
    return options;
  }

  @autobind
  removeSelectedOption(options, option) {
    const { optionAccessor } = this.props;
    let currentOptions = options;
    currentOptions = options.filter(
      (opt) => optionAccessor(opt) !== optionAccessor(option)
    );

    return currentOptions;
  }

  @autobind
  selectAll() {
    const { selectAll } = this.props;

    selectAll({
      placeOption: this.placeOption,
      removeSelectedOption: this.removeSelectedOption,
    });
  }

  @autobind
  optionsLength() {
    const { options, counterLabel } = this.props;
    const length = options.length;

    return `${length} ${counterLabel(length)}`;
  }

  @autobind
  toggleHover() {
    this.setState((prevState) => {
      return {
        isHovering: !prevState.isHovering,
      };
    });
  }

  render() {
    const {
      selectAllLabel,
      appContext: { primaryColor },
      emptyState,
      options,
    } = this.props;
    const { isHovering } = this.state;
    const style = {
      backgroundColor: isHovering ? colorWithAlpha(primaryColor, 0.1) : '#FFF',
      color: isHovering ? primaryColor : '#666',
      borderColor: isHovering ? colorWithAlpha(primaryColor, 0.1) : '#999',
    };

    return (
      <div className="SelectableColumn">
        <div className="optionsCount">
          <strong>{this.optionsLength()}</strong>
          <OutlineBox
            onClick={() => this.selectAll()}
            style={style}
            onMouseEnter={this.toggleHover}
            onMouseLeave={this.toggleHover}
          >
            {selectAllLabel}
          </OutlineBox>
        </div>
        <div className="selectComponent">
          {options.length ? (
            this.renderOptions()
          ) : (
            <div className="emptyOptions">{emptyState}</div>
          )}
        </div>
      </div>
    );
  }
}

export default withAppContext(FormSelectorSelectableColumn);
