import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Toast from 'components/Toast';
import FormFullScreen from 'components/FormFullScreen';
import Loader from 'components/Loader';
import CartSettingsForm from './CartSettingsForm';

import withAppContext from 'core/hoc/withAppContext';
import {
  NEW_OFFER_MAX_INSTALLMENTS,
  ALLOWED_PAYMENT_METHOD,
} from 'core/constants';
import actionsShop from 'store/edupay/shop/actions';

const CartSettings = () => {
  const { t } = useTranslation(['payments']);
  const dispatch = useDispatch();

  const { cartSettings, sendingCartSettings } = useSelector(
    (state) => state.shop
  );
  const { invoice_details: invoiceDetails } = useSelector(
    (state) => state.root.policies
  );
  const { updateCartSettingsRequest, fetchCartSettings } = actionsShop;

  const maxInstallments = cartSettings?.attributes?.max_installments || 0;
  const allowedPaymentMethods =
    cartSettings?.attributes?.allowed_payment_method || [];

  const formSetting = {
    recipient_wallet_id: cartSettings?.attributes?.recipient_wallet_id,
    allowed_payment_method: allowedPaymentMethods,
    absorb_tax: cartSettings?.attributes?.absorb_tax,
    max_installments: maxInstallments,
  };

  const handleSubmit = (formContext) => {
    dispatch(updateCartSettingsRequest(formContext.form));
  };

  useEffect(() => {
    dispatch(fetchCartSettings());
  }, [dispatch, fetchCartSettings]);

  return (
    <Loader isLoading={!cartSettings}>
      <FormFullScreen
        action="new"
        steps={[CartSettingsForm]}
        form={formSetting}
        formMeta={{
          nextStepRequiredFields: ['allowed_payment_method'],
          select_options: {
            max_installments: NEW_OFFER_MAX_INSTALLMENTS,
          },
          allowed_payment_method: [
            ...ALLOWED_PAYMENT_METHOD,
            ...(invoiceDetails?.credit_card_enabled ? ['credit_card'] : []),
          ],
          can_absorb_tax: cartSettings?.attributes?.can_absorb_tax,
        }}
        isSubmitting={sendingCartSettings}
        onSubmit={handleSubmit}
        backTo={`/schools/school_products/tab/shop`}
        titlePage={t('marketplace.cart_shopping_setting.title_form')}
        titleModal={t('marketplace.cart_shopping_setting.title_form_modal')}
        contentModal={t('marketplace.cart_shopping_setting.content_form_modal')}
      />
      <Toast />
    </Loader>
  );
};

export default withAppContext(CartSettings);
