import React from 'react';

import { Box, Text } from '@agendaedu/ae-web-components';

import * as S from './styles';

import { Props } from './types';
import { useTranslation } from 'react-i18next';

const FooterToast: React.FC<Props> = ({
  handleOnClose,
  messageContent,
  senderName,
  variant,
}) => {
  const { t } = useTranslation(['messages']);

  const toastTypes = {
    edit: {
      id: 'footer-toast-edit',
      icon: 'pencil',
      message: t('common.edit_message'),
    },
    forward: {
      id: 'footer-toast-forward',
      icon: 'forward',
      message: t('common.forward_message'),
    },
    reply: {
      id: 'footer-toast-reply',
      icon: 'reply',
      message: t('common.reply_message'),
    },
  };

  return (
    <S.FooterToastWrapper data-testid={toastTypes[variant].id}>
      <Box display="flex" flexDirection="column" gap="xs2">
        <Box display="flex" flexDirection="row" gap="sm" alignItems="center">
          <S.ActionIcon name={toastTypes[variant].icon} />
          <S.MessageText>{toastTypes[variant].message}</S.MessageText>
        </Box>
        {variant === 'reply' && messageContent && (
          <S.ReplyMessageWrapper>
            <S.MessageInformationWrapper>
              <Text variant="body-bold-14" color={'neutral.black'}>
                {senderName}
              </Text>

              <Text variant="body-regular-14" color={'neutral.gray1'}>
                {messageContent}
              </Text>
            </S.MessageInformationWrapper>
          </S.ReplyMessageWrapper>
        )}
      </Box>

      <Box
        display="flex"
        flexDirection="row"
        data-testid="close-toast-button"
        onClick={handleOnClose}
      >
        <S.CloseButton name="multiply" />
      </Box>
    </S.FooterToastWrapper>
  );
};

export default FooterToast;
