/* eslint-disable react/prop-types */
import React from 'react';
import Accordion from 'components/Accordion';
import * as S from './styles';

const AccordionDetails = ({ children, title }) => {
  const renderAccordionTitle = () => {
    return (
      <S.HeaderWrapper data-testid="headerWrapper">
        <S.InformationsWrapper>
          <S.TitleWrapper>
            <S.HeaderText>{title}</S.HeaderText>
          </S.TitleWrapper>
        </S.InformationsWrapper>
      </S.HeaderWrapper>
    );
  };

  return (
    <S.Wrapper data-testid="accordion-details">
      <Accordion
        allowZeroExpanded
        variation="rightButton"
        header={renderAccordionTitle()}
        body={children}
      />
    </S.Wrapper>
  );
};

export default AccordionDetails;
