import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@agendaedu/ae-web-components';

import * as S from './styles';

import FilePreview from 'components/FileAttachment/FilePreview';

import { AttachmentButtonProps } from './types';

export const AttachmentButton = ({
  attachment,
  onChange,
  fileRef,
}: AttachmentButtonProps): JSX.Element => {
  const { t } = useTranslation(['messages']);

  const handleButtonClick = () => {
    fileRef.current.click();
  };

  const handleFileRemove = () => {
    fileRef.current.value = null;
    onChange(null);
  };

  return (
    <S.Wrapper>
      <Button
        disabled={!!attachment}
        variant="secondary"
        icon={'attachment'}
        maxWidth={'max-content'}
        onClick={handleButtonClick}
      >
        {t('omni_channel.common.attachment_button')}
      </Button>

      <input
        ref={fileRef}
        onChange={onChange}
        style={{ display: 'none' }}
        type="file"
      />

      {attachment && (
        <FilePreview
          deleteFile={handleFileRemove}
          file={attachment?.fileObject}
          useDirectUpload={false}
        />
      )}
    </S.Wrapper>
  );
};
