import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const CommonLabel = ({ children, variation }) => {
  return (
    <div className={`CommonLabel ${variation}`}>
      <span>{children}</span>
    </div>
  );
};

CommonLabel.propTypes = {
  variation: PropTypes.oneOf([
    'success',
    'info',
    'info-gray',
    'warning',
    'danger',
  ]),
};

CommonLabel.defaultProps = {
  variation: 'success',
};

export default CommonLabel;
