import formatDatetime from 'core/utils/formatDatetime';

const classroomsDataAPI = {
  /**
   * Create a pendency information object
   */
  getActivitiesData: (data, included) => {
    const activities = data.map((activity) => {
      const classroomsAttributes = [];
      const disciplineAttributes = [];
      const studentProfilesAttributes = [];
      const sent_kind = activity.attributes.created_for;

      if (sent_kind === 'classrooms') {
        activity.relationships.classrooms.data.map((itemClassrooms) => {
          included.map((itemIncluded) => {
            if (itemIncluded.id === itemClassrooms.id) {
              classroomsAttributes.push(itemIncluded);
            }
          });
        });
      } else {
        activity.relationships.student_profiles.data.map((itemClassrooms) => {
          included.map((itemIncluded) => {
            if (
              itemIncluded.id === itemClassrooms.id &&
              itemIncluded.type === 'student_profile'
            ) {
              studentProfilesAttributes.push(itemIncluded);
            }
          });
        });
      }

      included.map((item) => {
        if (item.id === activity.relationships.discipline.data.id) {
          disciplineAttributes.push(item);
        }
      });

      const confirmedAndTotal = activity.meta.confirmed_and_total.split('/');

      if (!disciplineAttributes || !classroomsAttributes) return null;

      return {
        id: activity.id,
        ...activity.attributes,
        attributes: {
          discipline: disciplineAttributes,
          classrooms: sent_kind === 'classrooms' ? classroomsAttributes : null,
          student_profiles: { data: studentProfilesAttributes },
          confirmed_and_total: {
            confirmed: confirmedAndTotal[0],
            total: confirmedAndTotal[1],
          },
        },
      };
    });

    return activities;
  },

  getActivitiesDetailsData: (activity, included) => {
    const classroomsAttributes = [];
    const disciplineAttributes = [];
    const summarydayClassroomVersion = [];
    const studentProfilesAttributes = [];
    const sent_kind = activity.attributes.created_for;

    if (sent_kind === 'classrooms') {
      activity.relationships.classrooms.data.map((itemClassrooms) => {
        included.map((itemIncluded) => {
          if (itemIncluded.id === itemClassrooms.id) {
            classroomsAttributes.push(itemIncluded);
          }
        });
      });
    } else {
      activity.relationships.student_profiles.data.map((itemClassrooms) => {
        included.map((itemIncluded) => {
          if (
            itemIncluded.id === itemClassrooms.id &&
            itemIncluded.type === 'student_profile'
          ) {
            studentProfilesAttributes.push(itemIncluded);
          }
        });
      });
    }

    activity.relationships.versions.data.map((itemVersions) => {
      included.map((itemIncluded) => {
        if (itemIncluded.id === itemVersions.id) {
          summarydayClassroomVersion.push(itemIncluded);
        }
      });
    });

    included.map((item) => {
      if (item.id === activity.relationships.discipline.data.id) {
        disciplineAttributes.push(item);
      }
    });

    const confirmedAndTotal = activity.meta.confirmed_and_total.split('/');

    if (!disciplineAttributes || !classroomsAttributes) return null;

    return {
      id: activity.id,
      discipline_id: activity.attributes.discipline_id,
      title: activity.attributes.title,
      description: activity.attributes.description,
      taskhome: activity.attributes.taskhome,
      page_book: activity.attributes.page_book,
      sent_kind,
      persona_type: activity.attributes.persona_type,
      classroom_ids: activity.attributes.classroom_ids,
      date: activity.attributes.date,
      deadline: activity.attributes.deadline,
      answer_submit_mode: activity.attributes.answer_submit_mode,
      status: activity.attributes.status,
      attachments: activity.attributes.attachments,
      summayday_classroom_permissions:
        activity.attributes.summayday_classroom_permissions,
      can_comment: activity.attributes.can_comment,
      cancel_reason: activity.attributes.cancel_reason,
      schedule_date: activity.attributes.schedule_date,
      attributes: {
        discipline: disciplineAttributes,
        classrooms: sent_kind === 'classrooms' ? classroomsAttributes : null,
        student_profiles: { data: studentProfilesAttributes },
        versions: summarydayClassroomVersion,
        confirmed_and_total: {
          confirmed: confirmedAndTotal[0],
          total: confirmedAndTotal[1],
        },
      },
    };
  },

  /**
   * Create a Engagement information object
   */
  getActivitiesEngagement: (engagement, included) => {
    const getStudentProfile = ({
      relationships: {
        student_profile: { data },
      },
    }) => data;

    const getResponsibleProfile = ({
      relationships: {
        responsible_profile: { data },
      },
    }) => data;

    const getSummarydayClassroomAnswer = ({
      relationships: {
        summaryday_classroom_answer: { data },
      },
    }) => data;

    const getClassroom = ({
      relationships: {
        classroom: { data },
      },
    }) => data;

    const newEngagement = engagement?.map((rowEngagement) => {
      const studentProfile = included.filter(function (itemIncluded) {
        return itemIncluded.id === getStudentProfile(rowEngagement).id;
      });

      const responsibleProfile = included.filter(function (itemIncluded) {
        return itemIncluded.id === getResponsibleProfile(rowEngagement).id;
      });

      const summarydayClassroomAnswer = included.filter(function (
        itemIncluded
      ) {
        return (
          itemIncluded.id === getSummarydayClassroomAnswer(rowEngagement)?.id
        );
      });

      const classroom = included.filter(function (itemIncluded) {
        return itemIncluded.id === getClassroom(rowEngagement)?.id;
      });

      if (
        rowEngagement.attributes.confirmed_at ||
        rowEngagement.attributes.seen_at
      ) {
        const formatConfirmedDate = formatDatetime(
          rowEngagement.attributes.confirmed_at,
          'DD/MM/YYYY HH:mm'
        );
        const formatViewDate = formatDatetime(
          rowEngagement.attributes.seen_at,
          'DD/MM/YYYY HH:mm'
        );

        rowEngagement.attributes.confirmed_at = formatConfirmedDate;
        rowEngagement.attributes.seen_at = formatViewDate;
      }

      return {
        id: rowEngagement.id,
        attributes: rowEngagement.attributes,
        studentProfile,
        responsibleProfile,
        summarydayClassroomAnswer,
        classroom,
      };
    });

    return newEngagement;
  },

  getActivityCommentData: (data) => {
    const results = data.map(({ id, type, attributes }) => {
      return {
        id,
        type,
        message: attributes.message,
        hide: attributes.hide,
        createdAt: moment(attributes.createdAt).format('DD/MM/YYYY [às] HH:mm'),
        sentBy: {
          ...attributes.sentBy.data.attributes,
        },
      };
    });

    return results;
  },

  getCommentSetionsData: (data, included) => {
    const sections = data?.map((section) => {
      let label = '';

      included.map((itemIncluded) => {
        if (itemIncluded.id === section.relationships.classroom.data.id) {
          label = itemIncluded.attributes.name;
        }
      });

      return {
        value: section.id,
        label,
      };
    });

    return sections;
  },
};

export default classroomsDataAPI;
