import React from 'react';
import ContentLoader from 'react-content-loader';

import * as S from './styles';

const PanelSkeleton = () => {
  return (
    <S.MainContainer>
      <ContentLoader
        width="100%"
        height="100%"
        speed={2}
        viewBox="0 0 100 100"
        ariaLabel="Carregando..."
      >
        <rect x="0" y="0" rx="0.5" ry="0.5" width="8" height="2" />
        <rect x="0" y="4" rx="0.5" ry="0.5" width="100" height="30" />

        <rect x="0" y="38" rx="0.5" ry="0.5" width="8" height="2" />
        <rect x="0" y="42" rx="0.5" ry="0.5" width="48" height="30" />
        <rect x="5" y="74" rx="0.5" ry="0.5" width="38" height="3" />

        <rect x="52" y="38" rx="0.5" ry="0.5" width="8" height="2" />
        <rect x="52" y="42" rx="0.5" ry="0.5" width="48" height="30" />
        <rect x="57" y="74" rx="0.5" ry="0.5" width="38" height="3" />
      </ContentLoader>
    </S.MainContainer>
  );
};

export default PanelSkeleton;
