import createModuleReducer from '../storeConfig/createModuleReducer';
import actions from './actions';

const initialForm = () => ({
  title: '',
  description: '',
  chart_attributes: [
    {
      key: Math.random().toString(36).substring(7),
      title: '',
      metabase_question_id: '',
    },
  ],
});

export const INITIAL_STATE = {
  reports: [],
  reportsTitles: [],
  isSending: false,
  isLoading: false,
  isFetching: false,
  error: null,
  reportsPagination: { currentPage: 1 },
  current: initialForm(),
  selectedReport: null,
};

export const addReport = (state) => ({
  ...state,
  isSending: true,
});

export const addReportSuccess = (state, action) => ({
  ...state,
  isSending: false,
  reports: [action.data, ...state.reports],
});

const fetchReportsRequest = (state) => ({
  ...state,
  isLoading: true,
});

const fetchRepostsSuccess = (state, action) => {
  const { data } = action;
  let updatedReportList;
  if (action.pagination.currentPage === 1) updatedReportList = [...data];
  else updatedReportList = [...state.reports, ...data];

  return {
    ...state,
    reports: updatedReportList,
    isLoading: false,
    reportsPagination: action.pagination,
  };
};

const fetchAllReportsTitlesSuccess = (state, action) => {
  const { reportsTitles } = action;

  return {
    ...state,
    reportsTitles,
  };
};

const fetchDeleteRequest = (state) => ({
  ...state,
  isLoading: true,
});

const fetchDeleteSuccess = (state, action) => {
  const { data, pagination } = action;

  return {
    ...state,
    isLoading: false,
    pagination,
    reports: data,
  };
};

const fetchDetailsRequest = (state) => ({
  ...state,
  isFetching: true,
  selectedReport: null,
});

const fetchDetailsSuccess = (state, action) => {
  const { data, included } = action;

  return {
    ...state,
    isFetching: false,
    selectedReport: { data, included },
  };
};

const handleError = (state, action) => ({
  ...state,
  error: action.error,
  isLoading: false,
});

const HANDLERS = {
  [actions.ADD_REPORT_REQUEST]: addReport,
  [actions.ADD_REPORT_SUCCESS]: addReportSuccess,
  [actions.FETCH_REPORT_REQUEST]: fetchReportsRequest,
  [actions.FETCH_REPORT_SUCCESS]: fetchRepostsSuccess,
  [actions.FETCH_ALL_REPORT_TITLE_SUCCESS]: fetchAllReportsTitlesSuccess,
  [actions.FETCH_DELETE_REPORT_REQUEST]: fetchDeleteRequest,
  [actions.FETCH_DELETE_REPORT_SUCCESS]: fetchDeleteSuccess,
  [actions.FETCH_REPORT_DETAILS_REQUEST]: fetchDetailsRequest,
  [actions.FETCH_REPORT_DETAILS_SUCCESS]: fetchDetailsSuccess,
  [actions.ERROR]: handleError,
};

const reports = createModuleReducer(INITIAL_STATE, HANDLERS);

export default reports;
