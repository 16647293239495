import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import FormFullScreen from 'components/FormFullScreen';
import withAppContext, { appPropTypes } from 'core/hoc/withAppContext';
import Toast from 'components/Toast';
import Loader from 'components/Loader';
import StudentProfilesInformationsTab from 'pages/studentProfiles/FormTabs/InformationsTab';
import StudentProfilesActions from 'store/studentProfiles/actions';
import SchoolServices from 'core/services/School';
import normalizeParams from '../normalizeParams';
import * as S from '../styles';

const contentModal = `
  O cadastro do aluno(a) será cancelado e
  as informações preenchidas serão descartadas.
  Tem certeza que deseja descartar?
`;

const StudentProfilesNew = ({ appContext }) => {
  const dispatch = useDispatch();
  const dataArea = useSelector((state) => state.root.dataArea);
  const { fetchFormMeta, createStudentProfile } = StudentProfilesActions;
  const { form, formMeta, isLoading, isSubmitting } = useSelector(
    (state) => state.studentProfiles
  );
  const { policies } = appContext;
  const { can_use_legacy_id: canUseLegacyId } = policies;
  const schoolServices = new SchoolServices(dataArea);

  const queryParameters = new URLSearchParams(window.location.search);
  const taskContext = queryParameters.get('task_context');

  const validateLegacyId = async (formContext) => {
    const { form, removeErrorOnAttribute, addErrorOnAttribute } = formContext;
    try {
      await schoolServices.validateLegacyId(form.legacy_id, 'student_profiles');
      removeErrorOnAttribute('legacy_id');
    } catch {
      addErrorOnAttribute(
        'legacy_id',
        'Identificador de registro já está em uso'
      );
    }
  };

  const handleFetchFormMeta = useCallback(() => {
    dispatch(fetchFormMeta());
  }, [dispatch, fetchFormMeta]);

  useEffect(() => {
    handleFetchFormMeta();
  }, [handleFetchFormMeta]);

  const onSubmit = async (formContext) => {
    const { form, hasErrorOnAttribute } = formContext;

    if (form.legacy_id) await validateLegacyId(formContext);
    if (!canUseLegacyId) delete form.legacy_id;
    if (taskContext) form['task_context'] = taskContext;

    const hasErrorOnLegacyId = hasErrorOnAttribute('legacy_id');
    const isFormValid = !hasErrorOnLegacyId;

    isFormValid &&
      dispatch(createStudentProfile(normalizeParams(form, policies)));
  };

  return (
    <S.PageContainer id="StudentProfilesNew" className="StudentProfilesNew">
      <Loader isLoading={isLoading}>
        <FormFullScreen
          action="new"
          titlePage="Novo aluno(a)"
          titleModal="Descartar preenchimento"
          contentModal={contentModal}
          toggleModalWithFormUpdates
          onSubmit={onSubmit}
          form={form}
          formMeta={formMeta}
          isSubmitting={isSubmitting}
          steps={[StudentProfilesInformationsTab]}
          backTo={`/${dataArea}/student_profiles`}
          backToExternal
        />
      </Loader>
      <Toast />
    </S.PageContainer>
  );
};

StudentProfilesNew.propTypes = {
  ...appPropTypes,
};

export default withAppContext(StudentProfilesNew);
