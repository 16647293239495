import React from 'react';
import { withRouter } from 'react-router-dom';

import ButtonRow from 'components/ButtonRow';
import DropdownButton from 'components/DropdownButton';

import './style.scss';
import { useSelector } from 'react-redux';
import { Button } from '@agendaedu/ae-web-components';

const SurveyActionButtons = ({
  surveyId,
  status,
  areInvalidDates,
  toggleModal,
  history,
}) => {
  const { dataArea } = useSelector((state) => state.root);
  const { selectedSurvey, selectedSurveyIncluded } = useSelector(
    (state) => state.surveys
  );

  const userPermission = selectedSurveyIncluded.find(
    (include) =>
      include.type === 'userPermission' &&
      include.id === selectedSurvey.relationships.userPermission.data.id
  );

  const canResend = () =>
    userPermission.attributes.canResend &&
    ['pending', 'opened', 'processing'].includes(status) &&
    !areInvalidDates;
  const canApprove = () =>
    userPermission.attributes.canApprove &&
    ['pending'].includes(status) &&
    !areInvalidDates;
  const canEdit = () =>
    userPermission.attributes.canUpdate &&
    ['pending', 'rejected', 'approved', 'processing'].includes(status);
  const canDelete = () =>
    userPermission.attributes.canDestroy &&
    ['pending', 'opened', 'rejected', 'approved', 'processing'].includes(
      status
    );

  const editSurvey = () =>
    history.push(`/${dataArea}/surveys/${surveyId}/edit`);

  const surveyActionButtons = () => {
    return (
      <section className="survey-action-buttons">
        <ButtonRow>
          {canResend() && (
            <Button
              type="button"
              variation="secondary"
              className="survey-button"
              onClick={() => toggleModal('resend')}
            >
              Reenviar
            </Button>
          )}
          {canApprove() && (
            <Button
              type="button"
              variation="primary"
              className="survey-button"
              onClick={() => toggleModal('approve')}
            >
              Aprovar
            </Button>
          )}
          {canEdit() && (
            <Button
              onClick={() => editSurvey()}
              isOnlyIcon
              icon="pencil"
              variant="secondary"
            />
          )}
        </ButtonRow>
        {canDelete() && (
          <Button
            isOnlyIcon
            icon="trash-bin"
            isNegativeAction
            variant="secondary"
            onClick={() => toggleModal('delete')}
          />
        )}
      </section>
    );
  };

  const mobileActions = () => {
    let actions = [];
    if (canResend())
      actions.push({ text: 'Reenviar', onClick: () => toggleModal('resend') });
    if (canApprove())
      actions.push({ text: 'Aprovar', onClick: () => toggleModal('approve') });
    if (canEdit())
      actions.push({ text: 'Editar', onClick: () => editSurvey() });
    if (canDelete())
      actions.push({ text: 'Excluir', onClick: () => toggleModal('delete') });
    return actions;
  };

  return (
    <>
      {surveyActionButtons()}
      <section className="mobile-survey-action-buttons">
        <DropdownButton
          text=""
          dropdownItems={mobileActions()}
          icon="dots-vertical"
          variation="white"
          direction="up"
        />
      </section>
    </>
  );
};

export default withRouter(SurveyActionButtons);
