import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as walletsActions from 'store/edupay/wallets/actions';

import FormContext from 'core/contexts/Form';
import tabifyForWizard from 'core/hoc/tabifyForWizard';

import ColoredText from 'components/ColoredText';
import Currency from 'components/Currency';
import TaxTransfer from 'components/TaxTransfer';
import valueWithTax from '../valueWithTax';
import Title from 'components/WizardForm/Title';

import * as S from './styles';

class TransferSummary extends Component {
  render() {
    const { currentWallet, bankAccount } = this.props;

    return (
      <FormContext.Consumer>
        {({ form, formMeta }) => (
          <S.Container>
            <Title name="Resumo da transferência" />
            <S.Label>Confira as informações da transferência.</S.Label>

            <S.AccountInfo className="account-info">
              <S.SpaceBetweenContainer>
                <S.Label>Valor disponível</S.Label>
                <S.Label>
                  <Currency value={formMeta.availableAmount} />
                </S.Label>
              </S.SpaceBetweenContainer>

              <S.TransferValueContainer>
                <S.Label>Valor da transferência</S.Label>
                <S.Label>
                  <Currency value={form.amount} />
                </S.Label>
              </S.TransferValueContainer>
            </S.AccountInfo>

            <S.Line />

            <S.TransferContainer>
              <TaxTransfer
                taxTransfer={formMeta.taxTransfer}
                bankCode={formMeta.bankCode}
              />
            </S.TransferContainer>

            <S.SpaceBetweenContainer>
              <ColoredText variation="bold">Valor a receber</ColoredText>

              <ColoredText variation="success bold">
                <Currency
                  value={valueWithTax(
                    form.amount,
                    formMeta.bankCode,
                    formMeta.taxTransfer
                  )}
                />
              </ColoredText>
            </S.SpaceBetweenContainer>

            <S.WalletDestinationContainer>
              <S.Label>Carteira de destino</S.Label>
              <S.LabelBlack>
                CNPJ: {currentWallet.attributes.document_number}
              </S.LabelBlack>
              {currentWallet.attributes.wallet_name && (
                <S.Text>{currentWallet.attributes.wallet_name}</S.Text>
              )}
              <S.Text>
                {bankAccount.attributes.code}
                {' - '}
                {bankAccount.attributes.bank_name}
              </S.Text>
              <S.Text>
                Agência: {bankAccount.attributes.agency}-
                {bankAccount.attributes.agency_dv} / Conta:{' '}
                {bankAccount.attributes.account}
                {'-'}
                {bankAccount.attributes.account_dv}
              </S.Text>
            </S.WalletDestinationContainer>

            <S.DescriptionContainer>
              <S.Label>Descrição</S.Label>
              <S.LabelBlack>{form.description}</S.LabelBlack>
            </S.DescriptionContainer>
          </S.Container>
        )}
      </FormContext.Consumer>
    );
  }
}

const mapStateToProps = (state) => ({
  currentWallet: state.wallets.currentWallet,
  bankAccount: state.wallets.bankAccount,
});

TransferSummary.propTypes = {
  currentWallet: PropTypes.object,
  bankAccount: PropTypes.object,
};

const Connect = connect(mapStateToProps, walletsActions)(TransferSummary);

export default tabifyForWizard({
  title: '2. Resumo',
})(Connect);
