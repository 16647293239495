import React from 'react';
import UserDisplay from 'components/UserDisplay';

export default [
  {
    Header: 'Alunos',
    accessor: user => <UserDisplay user={user.data} classroomsNames={[user.meta.classroom]} size="medium" />,
  },
  {
    Header: 'Nome de usuário',
    accessor: user => `${user.data.attributes.username}`,
  },
  {
    Header: 'Senha temporária',
    accessor: user => `${user.meta.password}`,
  },
];
