import styled from 'styled-components';

export const SelectContainer = styled.div`
  margin: 2rem 0;

  .Field {
    padding: 0;
    margin: 0;
    width: 350px;

    &.vertical {
      margin: 0;
    }
  }

  .Button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 38px;
    width: 80px;
    margin-left: 4px;
  }
`;

export const FieldWrapper = styled.div`
  display: flex;
  align-items: center;
`;
