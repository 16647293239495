export const ToastTypes = {
  default: 'default',
  success: 'success',
  info: 'info',
  warning: 'warning',
  error: 'error',
};

const variations = {
  default: 'toast-default',
  success: 'toast-success',
  info: 'toast-info',
  warning: 'toast-warning',
  error: 'toast-error',
};

const progressVariations = {
  default: 'toast-progress-default',
  success: 'toast-progress-success',
  info: 'toast-progress-info',
  warning: 'toast-progress-warning',
  error: 'toast-progress-error',
};

export const toastCss = (variation = 'default') => {
  return {
    className: variations[variation],
    bodyClassName: 'toast-white-text',
    progressClassName: progressVariations[variation],
  };
};

export const buildToast = (message, type = 'default', options) => ({
  message,
  type,
  options,
});

export default (message, type = 'default', options) => ({
  type: 'SHOW_TOAST',
  toast: buildToast(message, type, options),
});
