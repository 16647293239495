import React from 'react';
import { Link } from 'react-router-dom';
import * as S from './styles';
import { BreadcrumbProps, PathProps } from './types';

const Breadcrumb = ({
  title,
  path,
  subTitle,
  external = false,
}: BreadcrumbProps) => {
  const renderSinglePath = () => (
    <>
      {subTitle && <span className="subTitle">{subTitle} / </span>}
      {external ? (
        <a href={`${path}`} data-testid="externalLink">
          {title}
        </a>
      ) : (
        <Link to={path}>{title}</Link>
      )}
    </>
  );

  const renderArrayPath = (path: PathProps[]) => (
    <>
      {path.map(({ path, title }) => (
        <Link key={title} to={path} data-testid="arrayLink">
          {title}
        </Link>
      ))}
    </>
  );

  return (
    <S.Breadcrumb data-testid="breadcrumpWrapper">
      <i className="fa fa-angle-left" />
      {Array.isArray(path) ? renderArrayPath(path) : renderSinglePath()}
    </S.Breadcrumb>
  );
};

export default Breadcrumb;
