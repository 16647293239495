import React from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';

import Card from 'components/Card';
import ClassroomsList from 'components/ClassroomsList';
import OutlineBox from 'components/OutlineBox';
import Popover from 'components/Popover';
import Pagination from 'components/Pagination';

import './style.scss';

const AnswersList = ({
  surveyQuestionsData,
  changeSurveyQuestionsParams,
  activePage
}) => {
  const ANSWER_SIZE_LIMIT = 103;

  const answersPagination = useSelector(
    (state) => state.surveys.answersPagination
  );

  const getReceiverData = (answerAttributes) => {
    if (answerAttributes.receiver) return answerAttributes.receiver.data;
    else return answerAttributes.student_profile.data;
  };

  const renderStatusDate = (status, statusDate) => {
    let format = ' em] DD/MM/YYYY [às] hh:mm';
    if (status === 'seen') format = '[Visualizou' + format;
    else if (['answered', 'done'].includes(status))
      format = '[Respondido' + format;
    else format = '[Enviado' + format;
    return moment(statusDate, 'DD/MM/YYYY hh:mm').format(format);
  };

  const classroomNames = (classrooms) =>
    classrooms.data.map((classroom) => classroom.attributes.name);

  const extractQuestionAnswer = (answerData) => {
    return (
      (answerData.data && answerData.data.attributes.answer) ||
      (answerData.included && answerData.included[0]?.attributes.title)
    );
  };

  const renderQuestionAnswer = (answer) => {
    if (answer === undefined) return '—';

    if (answer.length >= ANSWER_SIZE_LIMIT) {
      return (
        <Popover content={answer}>
          <p className="question-answer">
            {answer.substr(0, ANSWER_SIZE_LIMIT) + '...'}
          </p>
        </Popover>
      );
    } else {
      return <p className="question-answer">{answer}</p>;
    }
  };

  const renderStatusLabel = (status) => {
    const STATUS = {
      pending: { label: 'Pendente', style: 'pending' },
      seen: { label: 'Visualizado', style: 'viewed' },
      draft: { label: 'Visualizado', style: 'viewed' },
      answered: { label: 'Respondido', style: 'answered' },
      done: { label: 'Respondido', style: 'answered' },
    };

    return (
      <OutlineBox className={`${STATUS[status].style} status-label`}>
        {STATUS[status].label}
      </OutlineBox>
    );
  };

  const renderAnswerData = (answerData) => {
    return (
      <div className="answer-card-body">
        <div className="answer-area">
          <span className="label">Resposta</span>
          {renderQuestionAnswer(
            extractQuestionAnswer(answerData.attributes.survey_answer)
          )}
        </div>
        <div className="label-area">
          {renderIdentifier(answerData.attributes)}
          <div className="status">
            {renderStatusLabel(answerData.attributes.status)}
          </div>
        </div>
      </div>
    );
  };

  const renderIdentifier = (answerData) => {
    const { type } = getReceiverData(answerData);
    const labelContent =
      type === 'responsible_profile'
        ? [answerData.student_profile.data.attributes.name]
        : classroomNames(answerData.classrooms);

    return <ClassroomsList classroomsNames={labelContent} />;
  };

  return (
    <>
      <section className="export-button-area">
        <span className="total-participants-label">
          {answersPagination.totalItemsCount} participantes
        </span>
      </section>
      <section className="answers-list">
        {surveyQuestionsData.map((answerData, index) => {
          return (
            <Card
              key={index}
              title={getReceiverData(answerData.attributes).attributes.name}
              subtitle={renderStatusDate(
                answerData.attributes.status,
                answerData.attributes.status_date
              )}
              body={renderAnswerData(answerData)}
              avatar={getReceiverData(answerData.attributes)}
              avatarSize="medium"
              className="answers-data-card"
            />
          );
        })}
      </section>
      <Pagination
        onChange={(page) => changeSurveyQuestionsParams({ page })}
        activePage={activePage}
        totalItemsCount={answersPagination.totalItemsCount}
        itemsCountPerPage={answersPagination.itemsCountPerPage}
      />
    </>
  );
};

export default AnswersList;
