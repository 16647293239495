import { File, FileType } from './types';

export const getFileType = (file: File): FileType => {
  const fileTypeMapping = {
    application: 'document',
    image: 'image',
    video: 'video',
    audio: 'audio',
  };

  const fileType = file.type.substring(0, file.type.indexOf('/'));
  return fileTypeMapping[fileType] || 'document';
};
