import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import actions from './actions';
import { fetchApi, postApi } from 'core/utils/api';
import { push } from 'connected-react-router';
import {
  buildToast,
  toastCss,
  ToastTypes,
} from 'store/middlewares/toast/actions';
import { ErrorType, ServerResponseError } from './types';

export function* fetchNegotiationInitialData(action) {
  try {
    const { dataArea } = yield select((state) => state.root);
    const { selectedRecurrentBills } = yield select(
      (state) => state.negotiations
    );

    const queryString = `?recurrent_bill_ids[]=${selectedRecurrentBills.join(
      '&recurrent_bill_ids[]='
    )}`;

    const { data } = yield call(
      fetchApi,
      `/${dataArea}/negotiations/agreement_recurrent_bills.json${queryString}`
    );
    yield put({
      type: actions.FETCH_SUCCESS_NEGOTIATION_INITIAL_DATA,
      data: data,
    });
  } catch (error) {
    const { currentPlanId } = yield select((state) => state.negotiations);

    if (error.response.status === 403)
      yield put(push(`/schools/recurrent/recurrent_plans/${currentPlanId}`));

    yield put({
      type: actions.FETCH_ERROR,
      toast: buildToast(
        'Não foi possivel obter os dados da negociação',
        ToastTypes.error
      ),
      error,
    });
  }
}

export function* fetchNegotiations(action) {
  try {
    const { dataArea } = yield select((state) => state.root);
    const { page, planId } = action.filters;

    const queryString = `?recurrent_plan_id=${planId}&page=${page}`;

    const { data, totalItemsCount } = yield call(
      fetchApi,
      `/${dataArea}/negotiations.json${queryString}`
    );

    yield put({
      type: actions.FETCH_SUCCESS_NEGOTIATIONS,
      data: data,
      totalItemsCount: totalItemsCount,
    });
  } catch (error) {
    yield put({
      type: actions.FETCH_ERROR,
      toast: buildToast(
        'Não foi possivel obter a lista de negociação',
        ToastTypes.error
      ),
      error,
    });
  }
}

export function* postNegotiation(action) {
  try {
    const { dataArea } = yield select((state) => state.root);
    const { currentPlanId } = yield select((state) => state.negotiations);

    const { data } = yield call(
      postApi,
      `/${dataArea}/negotiations`,
      action.params
    );

    yield put(push(`/schools/recurrent/recurrent_plans/${currentPlanId}`));

    yield put({
      type: actions.POST_NEGOTIATION_SUCCESS,
      toast: buildToast(
        'Negociação cadastrada com sucesso.',
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    const { errors }: ServerResponseError = error.data;
    const errorMessages =
      errors && errors.length > 0
        ? errors
            .map((error: ErrorType) => `${error.title}\n${error.detail}`)
            .join('\n')
        : 'Erro ao criar negociação';

    yield put({
      type: actions.FETCH_ERROR,
      error,
      toast: buildToast(
        errorMessages,
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* negotiationsSagas() {
  yield all([
    takeLatest(
      actions.FETCH_REQUEST_NEGOTIATION_INITIAL_DATA,
      fetchNegotiationInitialData
    ),

    takeLatest(actions.FETCH_REQUEST_NEGOTIATIONS, fetchNegotiations),
    takeLatest(actions.POST_NEGOTIATION_REQUEST, postNegotiation),
  ]);
}

export default negotiationsSagas;
