import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import logoAgenda from './agenda-icon-app.png';
import formatDate from 'core/utils/date';

import { getMaster } from '..';
import ListOptions from '../ListOptions';

import * as S from './styles';

const SingleSheetTemplate = ({ contentPrint }) => {
  const { date, description, title, attributes } = contentPrint;

  return (
    <S.Container className="printContainer">
      <S.Header>
        <img src={logoAgenda} alt="Agenda icon app" />

        <h2>Agenda Edu</h2>
        <span>
          {title} - {formatDate(date)}
        </span>
      </S.Header>

      <S.Content>
        <div>
          <p>
            <b>Turma(s)</b>: <ListOptions attributes={attributes.classrooms} />
          </p>
          <p>
            <b>Master</b>: {getMaster(attributes.versions[0].attributes)}
          </p>
        </div>
        <p>
          <b>Disciplina(s)</b>:{' '}
          <ListOptions attributes={attributes.discipline} />
        </p>
        <p>
          <b>Título</b>: {title}
        </p>
        <p>
          <b>Conteúdo</b>: {parse(description)}
        </p>
      </S.Content>
    </S.Container>
  );
};

SingleSheetTemplate.propTypes = {
  contentPrint: PropTypes.shape({
    date: PropTypes.string,
    description: PropTypes.string,
    title: PropTypes.string,
    attributes: PropTypes.string,
  }),
};

export default SingleSheetTemplate;
