import createModuleReducer from '../storeConfig/createModuleReducer';
import actions from './actions';

const getErrorsFound = (errors) => {
  let errorsFound = 0;
  errors.map((error) => (errorsFound += error && error.attributes.length));
  return errorsFound;
};

export const INITIAL_STATE = {
  error: null,
  isLoading: false,
  isValidating: false,
  isSubmitting: false,
  imports: [],
  importStep: 1,
  paginatedFile: {
    rows: [],
    numberOfRows: null,
    pagination: {},
  },
  validatedFile: {
    rows: [],
    rowsWithErrors: [],
    rowErrors: [],
    foundErrors: 0,
    correctedErrors: 0,
    totalErrors: 0,
  },
  fileSummary: null,
  importProfileType: '',
  paginatedInconsistencies: {
    data: [],
    pagination: {},
  },
  importSummary: null,
  uploadedFile: null,
  form: {
    attachments: [],
  },
  formMeta: {
    nextStepRequiredFields: ['attachments'],
    resetFormTabs: false,
  },
  currentImportProgress: {
    progress_status: null,
    total: 0,
    processed: 0,
    percentile: '00',
  },
};

const setError = (state, action) => ({
  ...state,
  error: action.error,
  isLoading: false,
  isValidating: false,
});

const setImportProfileType = (state, action) => {
  const { profileType } = action;
  return {
    ...state,
    importProfileType: profileType,
  };
};

const fetchImports = (state) => ({
  ...state,
  isLoading: true,
});

const fetchImportsSuccess = (state, action) => ({
  ...state,
  isLoading: false,
  imports: action.data,
});

const setUploadedFile = (state, action) => ({
  ...state,
  uploadedFile: action.file,
});

const setImportStep = (state, action) => ({
  ...state,
  importStep: action.step,
});

const validateFileValues = (state) => ({
  ...state,
  isValidating: true,
});

const validateFileValuesSuccess = (state, action) => {
  let inconsistencies = action.data.filter((inconsistency) =>
    action.errors.some((error) => error.id === inconsistency.id)
  );

  return {
    ...state,
    isValidating: false,
    validatedFile: {
      rows: action.data,
      rowsWithErrors: inconsistencies,
      rowErrors: action.errors,
      foundErrors: getErrorsFound(action.errors),
      totalErrors: getErrorsFound(action.errors),
      correctedErrors: 0,
    },
  };
};

const validateFileRow = (state) => ({
  ...state,
  isValidating: true,
});

const validateFileRowSuccess = (state, action) => {
  let rows = state.validatedFile.rows;
  let rowsWithErrors = state.validatedFile.rowsWithErrors;
  let rowErrors = state.validatedFile.rowErrors;

  let rowIndex = state.validatedFile.rows.findIndex(
    (row) => row.id === action.data[0].id
  );

  let rowWithErrorIndex = state.validatedFile.rowsWithErrors.findIndex(
    (row) => row.id === action.data[0].id
  );

  let rowErrorIndex = state.validatedFile.rowErrors.findIndex(
    (error) => error.id === action.data[0].id
  );

  rows[rowIndex] = action.data[0];
  rowsWithErrors[rowWithErrorIndex] = action.data[0];
  rowErrors[rowErrorIndex] = action.errors[0] || {
    id: action.data[0].id,
    attributes: [],
  };

  let correctedErrors =
    state.validatedFile.totalErrors -
    getErrorsFound(state.validatedFile.rowErrors);

  return {
    ...state,
    isValidating: false,
    validatedFile: {
      ...state.validatedFile,
      rows,
      rowsWithErrors,
      rowErrors,
      foundErrors: getErrorsFound(state.validatedFile.rowErrors),
      correctedErrors,
    },
  };
};

const fetchPaginatedFile = (state) => ({
  ...state,
  isLoading: true,
});

const fetchPaginatedFileSuccess = (state, action) => {
  const { data, number_of_rows, pagination } = action;
  return {
    ...state,
    isLoading: false,
    paginatedFile: {
      rows: data,
      numberOfRows: number_of_rows,
      pagination: pagination,
    },
  };
};

const fetchFileSummary = (state) => ({
  ...state,
  isLoading: true,
});

const fetchFileSummarySuccess = (state, action) => {
  return {
    ...state,
    isLoading: false,
    fileSummary: action.data,
  };
};

const startImport = (state) => ({
  ...state,
  isSubmitting: true,
});

const startImportSuccess = (state) => ({
  ...state,
  isSubmitting: false,
});

const fetchImportInconsistencies = (state) => ({
  ...state,
  isLoading: true,
});

const fetchImportInconsistenciesSuccess = (state, action) => {
  const { data, pagination } = action;

  return {
    ...state,
    isLoading: false,
    paginatedInconsistencies: {
      data,
      pagination,
    },
  };
};

const fetchImportSummary = (state) => ({
  ...state,
  isLoading: true,
});

const fetchImportSummarySuccess = (state, action) => {
  return {
    ...state,
    isLoading: false,
    importSummary: action.data,
  };
};

const getImportProgressSuccess = (state, action) => {
  const { attributes } = action.data;

  return {
    ...state,
    isLoading: false,
    currentImportProgress: attributes,
  };
};

const deleteFile = () => INITIAL_STATE;

const HANDLERS = {
  [actions.SET_ERROR]: setError,
  [actions.SET_IMPORT_PROFILE_TYPE]: setImportProfileType,
  [actions.FETCH_IMPORTS]: fetchImports,
  [actions.SET_IMPORT_STEP]: setImportStep,
  [actions.FETCH_IMPORTS_SUCCESS]: fetchImportsSuccess,
  [actions.SET_UPLOADED_FILE]: setUploadedFile,
  [actions.VALIDATE_FILE_VALUES]: validateFileValues,
  [actions.VALIDATE_FILE_VALUES_SUCCESS]: validateFileValuesSuccess,
  [actions.VALIDATE_FILE_ROW]: validateFileRow,
  [actions.VALIDATE_FILE_ROW_SUCCESS]: validateFileRowSuccess,
  [actions.FETCH_PAGINATED_FILE]: fetchPaginatedFile,
  [actions.FETCH_PAGINATED_FILE_SUCCESS]: fetchPaginatedFileSuccess,
  [actions.FETCH_FILE_SUMMARY]: fetchFileSummary,
  [actions.FETCH_FILE_SUMMARY_SUCCESS]: fetchFileSummarySuccess,
  [actions.START_IMPORT]: startImport,
  [actions.START_IMPORT_SUCCESS]: startImportSuccess,
  [actions.FETCH_IMPORT_INCONSISTENCIES]: fetchImportInconsistencies,
  [actions.FETCH_IMPORT_INCONSISTENCIES_SUCCESS]:
    fetchImportInconsistenciesSuccess,
  [actions.FETCH_IMPORT_SUMMARY]: fetchImportSummary,
  [actions.FETCH_IMPORT_SUMMARY_SUCCESS]: fetchImportSummarySuccess,
  [actions.DELETE_FILE]: deleteFile,
  [actions.GET_IMPORT_PROGRESS_SUCCESS]: getImportProgressSuccess,
};

const importWeb = createModuleReducer(INITIAL_STATE, HANDLERS);

export default importWeb;
