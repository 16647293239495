import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as S from './styles';

import { Button, Modal } from '@agendaedu/ae-web-components';

import omniChannelActions from 'store/messages/omniChannel/actions';
import { OmniChannelState } from 'store/messages/omniChannel/types';

export const ReportMessageModal = () => {
  const { t } = useTranslation(['messages', 'common']);
  const dispatch = useDispatch();

  const { activeChannel, activeChat, showReportMessagesModal } =
    useSelector((state: OmniChannelState) => state.omniChannel);

  const { toggleReportMessagesModal } = omniChannelActions;

  const handleToggleReportMessages = useCallback(() => {
    dispatch(toggleReportMessagesModal());
  }, [dispatch, toggleReportMessagesModal]);

  const handleFetchReportMessages = () => {
    const baseURL = window.location.origin;

    window.open(
      baseURL.concat(
        `/schools/messages/channels/${activeChannel.id}/chats/${activeChat.id}/messages/report`
      )
    );

    handleToggleReportMessages();
  };

  return (
    <>
      {showReportMessagesModal && (
        <Modal
          isOpen={showReportMessagesModal}
          onClose={handleToggleReportMessages}
          title={t(
            'omni_channel.modals.details_chat.export_deleted_msgs.title'
          )}
        >
          <S.ContentWrapper>
            <S.DescriptionWrapper>
              {t(
                'omni_channel.modals.details_chat.export_deleted_msgs.description'
              )}

              <S.ButtonWrapper>
                <Button
                  testId="report-back-btn"
                  variant="secondary"
                  onClick={handleToggleReportMessages}
                >
                  {t('common:button.back')}
                </Button>

                <Button
                  testId="report-deleted-msgs-btn"
                  onClick={handleFetchReportMessages}
                >
                  {t('common:button.confirm')}
                </Button>
              </S.ButtonWrapper>
            </S.DescriptionWrapper>
          </S.ContentWrapper>
        </Modal>
      )}
    </>
  );
};
