import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import {
  buildToast,
  toastCss,
  ToastTypes,
} from 'store/middlewares/toast/actions';
import { fetchApi, putApi, postApi } from 'core/utils/api';

import actions from './actions';

export function* addEvent(action) {
  try {
    const dataArea = yield select((state) => state.root.dataArea);
    const { data } = yield call(postApi, `/${dataArea}/events`, action.params);

    yield put({
      type: actions.ADD_EVENT_SUCCESS,
      data,
      toast: buildToast(
        'Evento cadastrado com sucesso.',
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });

    window.location = `/${dataArea}/events/${data.id}`;
  } catch (error) {
    yield put({
      type: actions.ERROR,
      toast: buildToast('Erro ao tentar cadastrar o evento.', ToastTypes.error),
      error,
    });
  }
}

function* setClassrooms() {
  try {
    const dataArea = yield select((state) => state.root.dataArea);

    const { history } = yield call(
      fetchApi,
      `/${dataArea}/student_profiles/classrooms.json`
    );

    yield put({
      type: actions.SET_CLASSROOMS_SUCCESS,
      data: history,
    });
  } catch (error) {
    yield put({ type: actions.ERROR, error });
  }
}

function* setEvent(action) {
  try {
    const dataArea = yield select((state) => state.root.dataArea);

    const [event, classrooms] = yield all([
      call(fetchApi, `/${dataArea}/events/${action.id}.json`),
      call(fetchApi, `/${dataArea}/student_profiles/classrooms.json`),
    ]);

    yield put({
      type: actions.SET_EVENT_SUCCESS,
      event: event.data,
      classrooms: classrooms.history,
      meta: event.meta,
    });
  } catch (error) {
    yield put({ type: actions.ERROR, error });
  }
}

function* editEvent(action) {
  try {
    const dataArea = yield select((state) => state.root.dataArea);

    const { data } = yield call(
      putApi,
      `/${dataArea}/events/${action.id}`,
      action.params
    );

    yield put({
      type: actions.EDIT_EVENT_SUCCESS,
      data,
      toast: buildToast(
        'Evento atualizado com sucesso.',
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });

    window.location = `/${dataArea}/events/${action.id}`;
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        'Houve um erro na atualização.',
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* eventSagas() {
  yield all([
    takeLatest(actions.ADD_EVENT, addEvent),
    takeLatest(actions.SET_CLASSROOMS_REQUEST, setClassrooms),
    takeLatest(actions.SET_EVENT_REQUEST, setEvent),
    takeLatest(actions.EDIT_EVENT, editEvent),
  ]);
}

export default eventSagas;
