import React, { useCallback, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Button, Select, Text } from '@agendaedu/ae-web-components';
import { Modal } from 'components/Handouts/Modal';
import ToggleSwitch from 'components/Form/ToggleSwitch';

import filtersActions from 'store/filters/actions';
import omniChannelActions from 'store/messages/omniChannel/actions';

import { OmniChannelState } from 'store/messages/omniChannel/types';

import * as S from './styles';
import { FilterStatesProps } from 'store/filters/types';

export const FilterChatModal = (): JSX.Element => {
  const { t } = useTranslation(['messages']);
  const dispatch = useDispatch();

  const [isInactiveChats, setIsInactiveChats] = useState(false);
  const [studentStatus, setStudentStatus] = useState<string | null>(null);
  const [headquarterId, setHeadquarterId] = useState<string | null>(null);
  const [educationalStageId, setEducationalStageId] = useState<string | null>(
    null
  );
  const [classroomId, setClassroomId] = useState<string | null>(null);

  const { headquarters, educationalStages, classrooms } = useSelector(
    (state: FilterStatesProps) => state.filters
  );

  const { showFilterChatModal } = useSelector(
    (state: OmniChannelState) => state.omniChannel
  );

  const hasHeadquarters = () => headquarters && !!headquarters.length;

  const hasEducationalStages = () =>
    hasHeadquarters() && headquarterId && !!educationalStages?.length;

  const hasClassrooms = () =>
    hasEducationalStages() && educationalStageId && !!classrooms?.length;

  const {
    fetchHeadquartersRequest,
    fetchEducationalStagesRequest,
    fetchClassRoomsRequest,
  } = filtersActions;
  const { setChatFilters, toggleFilterChatModal } = omniChannelActions;

  const handleFetchHeadquarters = useCallback(() => {
    dispatch(fetchHeadquartersRequest());
  }, [dispatch, fetchHeadquartersRequest]);

  const handleFetchEducationalStage = useCallback(
    (headquarterId: string) => {
      if (headquarterId === null) return;
      dispatch(fetchEducationalStagesRequest(headquarterId));
    },
    [dispatch, fetchEducationalStagesRequest]
  );

  const handleFetchClassroom = useCallback(
    (headquarterId: string, educationalStageId: string) => {
      if (headquarterId === null || educationalStageId === null) return;
      dispatch(fetchClassRoomsRequest(headquarterId, educationalStageId));
    },
    [dispatch, fetchClassRoomsRequest]
  );

  const handleToggleFilterChatModal = useCallback(() => {
    dispatch(toggleFilterChatModal());
  }, [dispatch, toggleFilterChatModal]);

  const handleSetChatFilters = useCallback(
    (params) => {
      dispatch(setChatFilters(params));
    },
    [dispatch, setChatFilters]
  );

  const handleSubmitFilters = () => {
    handleSetChatFilters({
      isInactiveChats,
      studentStatus,
      headquarterId,
      educationalStageId,
      classroomId,
    });

    handleToggleFilterChatModal();
  };

  const handleClearFilters = () => {
    setIsInactiveChats(false);
    setStudentStatus(null);
    setHeadquarterId(null);
    setEducationalStageId(null);
    setClassroomId(null);

    handleSetChatFilters({
      isInactiveChats: false,
      studentStatus: null,
      headquarterId: null,
      educationalStageId: null,
      classroomId: null,
    });

    handleToggleFilterChatModal();
  };

  useEffect(() => {
    handleFetchHeadquarters();
  }, []);

  return (
    <>
      {showFilterChatModal && (
        <Modal.Root
          dataTestId="omni-filter-chat"
          isOpen={showFilterChatModal}
          title={t('omni_channel.modals.filter_chat.title')}
          handleToggleModal={handleToggleFilterChatModal}
          maxWidth="400px"
          overlapModal={false}
        >
          <Modal.Content>
            <S.InforWrapper>
              <S.ToggleWrapper>
                <ToggleSwitch
                  toggled={isInactiveChats}
                  onChange={() => setIsInactiveChats(!isInactiveChats)}
                />
                <Text variant="label-regular-16">
                  {t('omni_channel.modals.filter_chat.toggle_text')}
                </Text>
              </S.ToggleWrapper>

              <Select
                label={t('omni_channel.modals.filter_chat.status.label_text')}
                placeholder={t(
                  'omni_channel.modals.filter_chat.status.placeholder_text'
                )}
                options={[
                  { label: 'Selecione um status', value: null },
                  { label: 'Alunos não progredidos', value: 'not_progressed' },
                ]}
                value={studentStatus}
                onChange={(studentStatus) =>
                  setStudentStatus(studentStatus.value)
                }
                fullWidth
              />

              <Select
                disabled={!hasHeadquarters()}
                label={t(
                  'omni_channel.modals.filter_chat.headquarter.label_text'
                )}
                placeholder={t(
                  'omni_channel.modals.filter_chat.headquarter.placeholder_text'
                )}
                value={headquarterId}
                options={headquarters}
                onChange={(headquarter) => {
                  setHeadquarterId(headquarter.value);
                  setEducationalStageId(null);
                  setClassroomId(null);
                  handleFetchEducationalStage(headquarter.value);
                }}
                fullWidth
              />

              <Select
                disabled={!hasEducationalStages()}
                label={t(
                  'omni_channel.modals.filter_chat.educational.label_text'
                )}
                placeholder={t(
                  'omni_channel.modals.filter_chat.educational.placeholder_text'
                )}
                value={educationalStageId}
                options={educationalStages}
                onChange={(educationalStage) => {
                  setEducationalStageId(educationalStage.value);
                  setClassroomId(null);
                  handleFetchClassroom(headquarterId, educationalStage.value);
                }}
                fullWidth
              />

              <Select
                disabled={!hasClassrooms()}
                label={t(
                  'omni_channel.modals.filter_chat.classroom.label_text'
                )}
                placeholder={t(
                  'omni_channel.modals.filter_chat.classroom.placeholder_text'
                )}
                value={classroomId}
                options={classrooms}
                onChange={(classroom) => setClassroomId(classroom.value)}
                fullWidth
              />
            </S.InforWrapper>
          </Modal.Content>
          <Modal.Actions>
            <S.ButtonWrapper>
              <Button
                testId="clear-filter-chat-button"
                variant="secondary"
                onClick={handleClearFilters}
              >
                {t('omni_channel.modals.filter_chat.button_clear')}
              </Button>

              <Button
                testId="submit-filter-chat-button"
                onClick={handleSubmitFilters}
              >
                {t('omni_channel.modals.filter_chat.button_submit')}
              </Button>
            </S.ButtonWrapper>
          </Modal.Actions>
        </Modal.Root>
      )}
    </>
  );
};
