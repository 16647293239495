import styled, { css } from 'styled-components';
import { Text } from '@agendaedu/ae-web-components';

import { ThemeProps } from 'config/themes/types';

import { StylesProps } from './types';

const textOverflowStyle = css`
  white-space: nowrap;
  overflow: hidden;

  text-overflow: ellipsis;
`;

export const GroupCardContent = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    flex-direction: column;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    gap: ${space.xs3};
  `}
`;

export const GroupCardWrapper = styled.li<StylesProps>`
  ${({ theme: { colors, space }, isSelected }) => css`
    user-select: none;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: ${space.sm} ${space.lg};
    background: ${isSelected ? `${colors.brand.primary.op10}` : 'initial'};
    gap: ${space.sm};
    border-bottom: 1px solid ${colors.neutral.gray4};

    cursor: pointer;

    &:hover {
      background: ${colors.brand.primary.op10};
    }
  `}
`;

export const GroupCardHeader = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`;

export const GroupCardWrapperTitle = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    max-width: 80%;
    display: flex;
    align-items: center;
    gap: ${space.xs2};
  `}
`;

export const GroupCardTitle = styled(Text)`
  ${textOverflowStyle};
  ${({ theme: { colors } }: ThemeProps) => css`
    width: 100%;
    margin: 0px;
    font-weight: 700 !important;
    color: ${colors.neutral.black};
  `}
`;

export const GroupCardTimestamp = styled(Text)`
  ${({ theme: { colors } }: ThemeProps) => css`
    color: ${colors.neutral.gray2};
    margin: 0px;
  `}
`;

export const LastMessagePreview = styled(Text)`
  ${textOverflowStyle};
  ${({ theme: { colors } }: ThemeProps) => css`
    width: 100%;
    margin: 0px;
    font-weight: 400;
    color: ${colors.neutral.black};
  `}
`;

type NotificationWrapperProps = {
  hasUnreadMessage: boolean;
  theme: ThemeProps;
};

export const NotificationWrapper = styled.div<NotificationWrapperProps>`
  ${({ theme, hasUnreadMessage }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    ${hasUnreadMessage &&
    css`
      > p {
        width: 80%;
        font-weight: ${theme.font.weight.bold};
      }
    `}
  `}
`;

export const CountWrapper = styled.span`
  ${({ theme: { colors, typography } }: ThemeProps) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 20px;
    height: 20px;
    border-radius: 10px;
    padding: 0 6px;
    text-align: center;
    ${typography.subtitle.Medium12};

    color: ${colors.neutral.white};
    background: ${colors.brand.primary.default};
  `}
`;

export const Avatar = styled.img`
  ${({ theme: { border } }: ThemeProps) => css`
    width: 48px;
    height: 48px;
    border-radius: ${border.radius.circle};
  `}
`;

export const SkeletonWrapper = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: ${space.sm} ${space.lg};
    gap: ${space.xs};
  `}
`;

export const SkeletonLabelWrapper = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: ${space.xs2};
  `}
`;

export const SkeletonNotificationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6px;
`;

export const EmptyAvatarWrapper = styled.div`
  ${({ theme: { border, colors } }: ThemeProps) => css`
    display: flex;
    min-width: 48px;
    min-height: 48px;
    border-radius: ${border.radius.circle};
    background-color: ${colors.neutral.gray4};
    justify-content: center;
    align-items: center;

    svg {
      width: 30px;
      height: 30px;
      path {
        fill: ${colors.neutral.gray2};
      }
    }
  `}
`;

export const LastMessageAttachmentWrapper = styled.div`
  ${({ theme: { colors } }: ThemeProps) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: -2px;
    svg {
      width: 16px;
      height: 16px;
      fill: ${colors.neutral.gray1};
    }
  `}
`;
