import { ThemeProps } from 'config/themes/types';
import styled, { css } from 'styled-components';

export const BodyContainer = styled.div`
  ${({ theme: { breakpoints } }: ThemeProps) => css`
    display: flex;
    width: 100%;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;

    @media (max-width: ${breakpoints.tabletMD}) {
      justify-content: flex-start;
    }
  `}
`;

export const Ellipse = `
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @supports (-webkit-line-clamp: 1) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
`;

export const UpdateContainer = styled.div`
  ${({ theme: { typography } }: ThemeProps) => css`
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: flex-end;

    label {
      ${typography.label.Medium12}
      ${Ellipse}
    }

    h4 {
      ${typography.title.Bold16}
      margin-top: 0px;
      margin-bottom: 0px;

      ${Ellipse}
    }
  `}
`;

export const ClassroomsCounter = styled.span`
  display: flex;
  justify-content: flex-end;
  ${({ theme: { font, space } }: ThemeProps) => css`
    font-family: ${font.family.brand};
    margin-bottom: ${space.sm};
  `};
`;

export const labelTitle = styled.p`
  ${({ theme: { colors, typography } }: ThemeProps) => css`
    ${typography.subtitle.Medium12};
    color: ${colors.neutral.gray2};
    margin: 0px;

    ${Ellipse}
  `}
`;

export const Row = styled.div`
  width: 100%;
  cursor: pointer;
`;

export const CardTitle = styled.p`
  ${({ theme: { colors, typography } }: ThemeProps) => css`
    ${typography.subtitle.Medium16};
    color: ${colors.neutral.gray1};
    display: block;
    width: 100%;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-decoration: none;
    &:hover,
    &:focus,
    &:active {
      color: ${colors.neutral.gray2};
    }
  `}
`;

export const Informations = styled.div`
  ${({ theme: { breakpoints } }: ThemeProps) => css`
    min-width: 130px;

    @media (max-width: ${breakpoints.tabletMD}) {
      width: 600px;
    }
    @media (max-width: ${breakpoints.mobileSM}) {
      width: 240px;
    }

    @media (max-width: ${breakpoints.mobileXS}) {
      width: 130px;
    }

    &:not(:first-child) {
      margin-left: 3rem;
    }
  `}
`;
