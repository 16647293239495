import {
  EditGroupRequestParams,
  GroupProps,
  MemberProps,
} from 'store/messages/types';

export const getGroupListWithMembers = (
  data: GroupProps[],
  included: MemberProps[]
) => {
  const dataWithMembers = data.map((group: GroupProps) => {
    const members = group.relationships.members.data.map(
      (memberData: MemberProps) => {
        return included.find(
          (member: MemberProps) =>
            member.id === memberData.id && member.type === memberData.type
        );
      }
    );

    return {
      ...group,
      attributes: {
        ...group.attributes,
        icon: group.attributes.icon?.url
          ? {
              ...group.attributes.icon,
              uploaded: true,
            }
          : {},
      },
      relationships: {
        ...group.relationships,
        members: {
          ...group.relationships.members,
          data: members,
        },
      },
    };
  });

  return dataWithMembers;
};

export const getCurrentGroupWithMembers = (
  data: GroupProps,
  included: MemberProps[]
) => {
  const currentGroupWithMembers = {
    ...data,
    relationships: {
      ...data.relationships,
      members: {
        ...data.relationships.members,
        data: included,
      },
    },
  };

  return currentGroupWithMembers;
};

export const editGroupNormalizeParams = (values: EditGroupRequestParams) => {
  const formData = new FormData();

  const { name, icon, description, member_ids } = values;

  formData.append('name', name);
  formData.append('description', description);

  member_ids.map((memberId) => {
    formData.append('member_ids[]', memberId.toString());
  });

  if (icon?.url) {
    if (!icon?.uploaded) formData.append('icon', icon);
  } else {
    formData.append('icon', '');
  }

  return formData;
};
