import { ThemeProps } from 'config/themes/types';
import styled, { css } from 'styled-components';
import { Box } from '@agendaedu/ae-web-components';

export const Breadcrumb = styled(Box)`
  ${({ theme: { colors, space } }: ThemeProps) => css`
    gap: ${space.xs3};

    .ae-home-filled-icon,
    .ae-chevron-right-icon {
      margin: 8px 12px 8px 12px;

      path {
        fill: ${colors.brand.primary.default};
      }
    }

    .subTitle {
      color: ${colors.neutral.gray1};
    }
  `}
`;
