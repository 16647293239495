function validateStudents(input, _state) {
  let result = false;

  Object.entries(input).some(([_key, value]) => {
    if (value.length > 0) {
      result = true;
      return true;
    }
  });

  return result;
}

export default [
  {
    attributeName: 'classroom_with_student_profile_ids',
    validator: validateStudents,
    message: 'Nenhum aluno selecionado',
  },
];
