import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import withAppContext, { appPropTypes } from 'core/hoc/withAppContext';
import scrollTop from 'core/utils/scrollTop';
import walletsActions from 'store/edupay/wallets/actions';
import WalletsService from 'core/services/Wallets';

import Loader from 'components/Loader';
import SchoolWalletForm from 'components/Payments/PaymentsContainer/SchoolWalletForm';
import InternalErrorBoundary from 'boundaries/InternalErrorBoundary';

class NewSchoolWallet extends React.Component {
  state = {
    isInitialLoading: true,
    error: null,
  };

  async componentDidMount() {
    this.setState({ isLoading: true });

    try {
      const {
        appContext: { dataArea },
      } = this.props;
      const walletsService = new WalletsService(dataArea);
      const { attributes, meta } = await walletsService.getEmptyFormMeta();
      this.setState({
        form: attributes,
        formMeta: meta,
        isLoading: false,
        isInitialLoading: false,
      });
    } catch (error) {
      this.setState({ error });
    } finally {
      this.setState({ isInitialLoading: false });
    }
  }

  handleSubmit = async (formContext, { goToFirstStep }) => {
    const {
      form,
      addErrorsToFormValidationResultFromServer,
      updateFormContext,
    } = formContext;

    this.setState({ isLoading: true });

    try {
      const { allowAccessToPayments } = this.props;
      const walletsService = new WalletsService('schools');
      await walletsService.createWallet(form);
      this.props.fetchRecipientsWalletsRequest();
      this.setState({ isRedirectingToWallet: true });
      allowAccessToPayments();
    } catch (error) {
      if (error.response.status === 422) {
        updateFormContext(() => ({ hasBackendValidationError: true }));
        addErrorsToFormValidationResultFromServer(error.response);
        goToFirstStep();
        scrollTop();
      } else {
        this.setState({ error });
      }
    } finally {
      this.setState({ isLoading: false });
    }
  };

  render() {
    const {
      form,
      formMeta,
      error,
      isLoading,
      isInitialLoading,
      isRedirectingToWallet,
    } = this.state;

    if (isRedirectingToWallet) {
      return (
        <Redirect
          to={{
            pathname: '/schools/wallets',
            state: { walletWasCreated: true },
          }}
        />
      );
    }

    return (
      <div className="NewSchoolWallet">
        <Loader isLoading={isInitialLoading}>
          <InternalErrorBoundary>
            <SchoolWalletForm
              action="new"
              onSubmit={this.handleSubmit}
              form={form}
              formMeta={formMeta}
              error={error}
              isSubmitting={isLoading}
              backTo="/schools/wallets"
              titlePage="Nova carteira"
              titleModal="Descartar preenchimento"
              contentModal="A criação da carteira será cancelada e as informações preenchidas serão descartadas. Tem certeza que deseja descartar?"
            />
          </InternalErrorBoundary>
        </Loader>
      </div>
    );
  }
}

NewSchoolWallet.propTypes = {
  allowAccessToPayments: PropTypes.func.isRequired,
  ...appPropTypes,
};

const mapStateToProps = (state) => ({
  currentWallet: state.wallets.currentWallet,
  bankAccount: state.wallets.bankAccount,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(walletsActions, dispatch);

const Connect = connect(mapStateToProps, mapDispatchToProps)(NewSchoolWallet);

export default withAppContext(Connect);
