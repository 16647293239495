import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './styles';

import { Button } from '@agendaedu/ae-web-components';
import { Modal } from 'components/Handouts/Modal';
import MessagesAttachment from 'components/Messages/Attachment';

import { AttachmentModalProps } from './types';

export const AttachemntModal = ({
  isOpen,
  optionalMessage,
  attachmentFile,
  handleOnSubmitAttachment,
  handleOnClose,
}: AttachmentModalProps): React.ReactElement => {
  const { t } = useTranslation(['messages']);
  const [attachmentMessage, setAttachmentMessage] = useState<string | null>(
    null
  );

  const handleOnSubmitAttachmentMessage = useCallback(() => {
    handleOnSubmitAttachment(attachmentMessage, attachmentFile);
    setAttachmentMessage(null);
    handleOnClose();
  }, [
    attachmentMessage,
    attachmentFile,
    setAttachmentMessage,
    handleOnClose,
    handleOnSubmitAttachment,
  ]);

  const disabled = !optionalMessage && !attachmentMessage;

  return (
    <Modal.Root
      isOpen={isOpen}
      title={t('omni_channel.modals.attchemnt_message.title')}
      handleToggleModal={handleOnClose}
    >
      <Modal.Content>
        <S.ContentWrapper>
          <MessagesAttachment variation="sent" attachment={attachmentFile} />

          <S.TextAreaWrapper>
            <S.TextAreaLabel variant="label-regular-14">
              {t(
                'omni_channel.forms.message_channel.fields.new_message_text_area_label'
              )}
            </S.TextAreaLabel>
            <S.TextArea
              data-testId="attachment-input-msg"
              value={attachmentMessage}
              onChange={({ target: { value } }) => setAttachmentMessage(value)}
            />
          </S.TextAreaWrapper>
        </S.ContentWrapper>
      </Modal.Content>
      <Modal.Actions>
        <Button
          testId="cancel-attachment-btn"
          variant="secondary"
          onClick={handleOnClose}
        >
          {t('omni_channel.modals.attchemnt_message.cancel_button')}
        </Button>
        <Button
          testId="submit-attachment-btn"
          disabled={disabled}
          onClick={handleOnSubmitAttachmentMessage}
        >
          {t('omni_channel.modals.attchemnt_message.submit_button')}
        </Button>
      </Modal.Actions>
    </Modal.Root>
  );
};
