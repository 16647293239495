import React, { useState } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { WalletsState } from './types';

import walletsActions from 'store/edupay/wallets/actions';
import { walletStatus } from 'core/constants/index';

import AgendaIcon from 'components/AgendaIcon';
import Button from 'components/Button';
import Label from 'components/Form/Label';
import PageSubTitle from 'components/PageSubTitle';
import Popover, { TOP } from 'components/Popover';
import Skeleton from './Skeleton';
import Tooltip from 'components/Tooltip';
import WalletTransfer from 'components/Payments/WalletTransfer';
import AlertModal from 'components/AlertModal';
import WalletList from 'components/Payments/WalletList';

import * as S from './styles';

const WalletInformation = () => {
  const dispatch = useDispatch();
  const [transferModal, setTransferModal] = useState(false);
  const { currentWallet, wallets, showChangeWalletModal } = useSelector(
    (state: WalletsState) => state.wallets
  );

  const [walletId, setWalletId] = useState(
    currentWallet?.attributes?.recipient_wallet_id
  );

  const { policies } = useSelector((state: RootStateOrAny) => state.root);

  const { handleChangeWalletModal, selectRecipientWallet } = walletsActions;

  const handleShowChangeWalletModal = () => {
    dispatch(handleChangeWalletModal(true));
  };

  const handleCurrentWallet = (walletId) => {
    setWalletId(walletId);
  };

  const content = (
    <p className="tooltip-content">
      Este CNPJ está em análise de aprovação. Após análise,
      <br />
      você receberá um feedback informando o status da carteira.
    </p>
  );

  const transferPopover = `Você não pode realizar uma transferência. Em caso
      de dúvida, consulte algum profissional com perfil Master, na sua escola.`;

  const toggleModal = () => {
    setTransferModal(transferModal ? false : true);
  };

  const showWalletName = () =>
    currentWallet.attributes.wallet_name && currentWallet.type != 'securitizer';

  return (
    <>
      <WalletTransfer transferModal={transferModal} toggleModal={toggleModal} />
      {currentWallet ? (
        <>
          <S.WalletContainer data-testid="wallet-wrapper">
            <S.WalletInformationContainer>
              <PageSubTitle variation="large">
                {currentWallet.type != 'securitizer'
                  ? `CNPJ: ${currentWallet.attributes.document_number}`
                  : currentWallet.attributes.legal_name}
              </PageSubTitle>

              {showWalletName() && (
                <Label>{`Nome da carteira: ${currentWallet.attributes.wallet_name}`}</Label>
              )}
              {currentWallet.type != 'securitizer' && (
                <>
                  <Label>
                    {currentWallet.attributes.bank_code} -{' '}
                    {currentWallet.attributes.bank_name}
                  </Label>
                </>
              )}

              {!!currentWallet.attributes.status &&
                currentWallet.attributes.status !== 'active' && (
                  <S.WalletStatusContainer>
                    <S.WalletStatus>
                      <S.WalletStatusText>
                        {walletStatus[currentWallet.attributes.status].text}
                      </S.WalletStatusText>
                    </S.WalletStatus>

                    <Tooltip content={content}>
                      <AgendaIcon
                        name="help"
                        size="medium"
                        className="custom-size"
                      />
                    </Tooltip>
                  </S.WalletStatusContainer>
                )}
            </S.WalletInformationContainer>

            <S.WalletTransferContainer>
              {wallets.length > 1 && (
                <Tooltip content={'Trocar de carteira'}>
                  <Button
                    className="change-wallet-button"
                    outline={true}
                    icon="swap-horizontal"
                    data-testid="change_wallet"
                    onClick={handleShowChangeWalletModal}
                  ></Button>
                </Tooltip>
              )}

              {currentWallet?.type != 'securitizer' &&
                (policies.can_transfer ? (
                  <Button className="transfer-button" onClick={toggleModal}>
                    Transferir saldo
                  </Button>
                ) : (
                  <Popover content={transferPopover} position={TOP}>
                    <Button className="transfer-button" disabled>
                      Transferir saldo
                    </Button>
                  </Popover>
                ))}
            </S.WalletTransferContainer>
          </S.WalletContainer>
          <AlertModal
            isOpen={showChangeWalletModal}
            confirmButtonText={'Selecionar'}
            cancelButtonText={'Cancelar'}
            closeAlert={() => dispatch(handleChangeWalletModal(false))}
            title={'Trocar de carteira'}
            onConfirm={() => dispatch(selectRecipientWallet(walletId))}
          >
            <WalletList
              wallets={wallets}
              defaultWalletId={currentWallet.attributes.recipient_wallet_id}
              handleCurrentWallet={handleCurrentWallet}
            />
          </AlertModal>
        </>
      ) : (
        <Skeleton />
      )}
    </>
  );
};

export default WalletInformation;
