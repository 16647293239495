const actions = {
  FETCH_FORM_META: 'FETCH_FORM_META/responsibleProfiles',
  FETCH_FORM_META_SUCCESS: 'FETCH_FORM_META_SUCCESS/responsibleProfiles',
  FETCH_STUDENT_PROFILES: 'FETCH_STUDENT_PROFILES/responsibleProfiles',
  FETCH_STUDENT_PROFILES_SUCCESS:
    'FETCH_STUDENT_PROFILES_SUCCESS/responsibleProfiles',
  SET_RESPONSIBLE_PROFILE: 'SET_RESPONSIBLE_PROFILE/responsibleProfiles',
  SET_RESPONSIBLE_PROFILE_SUCCESS:
    'SET_RESPONSIBLE_PROFILE_SUCCESS/responsibleProfiles',
  SET_REVIEWER_RESPONSIBLE_PROFILE:
    'SET_REVIEWER_RESPONSIBLE_PROFILE/responsibleProfiles',
  SET_REVIEWER_RESPONSIBLE_PROFILE_SUCCESS:
    'SET_REVIEWER_RESPONSIBLE_PROFILE_SUCCESS/responsibleProfiles',
  SET_RECOVERY_RESPONSIBLE_PROFILE:
    'SET_RECOVERY_RESPONSIBLE_PROFILE/responsibleProfiles',
  SET_RECOVERY_RESPONSIBLE_PROFILE_SUCCESS:
    'SET_RECOVERY_RESPONSIBLE_PROFILE_SUCCESS/responsibleProfiles',
  CREATE_RESPONSIBLE_PROFILE: 'CREATE_RESPONSIBLE_PROFILE/responsibleProfiles',
  CREATE_RESPONSIBLE_PROFILE_SUCCESS:
    'CREATE_RESPONSIBLE_PROFILE_SUCCESS/responsibleProfiles',
  UPDATE_RESPONSIBLE_PROFILE: 'UPDATE_RESPONSIBLE_PROFILE/responsibleProfiles',
  UPDATE_RESPONSIBLE_PROFILE_SUCCESS:
    'UPDATE_RESPONSIBLE_PROFILE_SUCCESS/responsibleProfiles',
  APPROVE_RESPONSIBLE_PROFILE:
    'APPROVE_RESPONSIBLE_PROFILE/responsibleProfiles',
  APPROVE_RESPONSIBLE_PROFILE_SUCCESS:
    'APPROVE_RESPONSIBLE_PROFILE_SUCCESS/responsibleProfiles',
  RECOVER_RESPONSIBLE_PROFILE:
    'RECOVER_RESPONSIBLE_PROFILE/responsibleProfiles',
  RECOVER_RESPONSIBLE_PROFILE_SUCCESS:
    'RECOVER_RESPONSIBLE_PROFILE_SUCCESS/responsibleProfiles',
  REMOVE_RESPONSIBLE_PROFILE: 'REMOVE_RESPONSIBLE_PROFILE/responsibleProfiles',
  REMOVE_RESPONSIBLE_PROFILE_SUCCESS:
    'REMOVE_RESPONSIBLE_PROFILE_SUCCESS/responsibleProfiles',
  SET_ERROR: 'SET_ERROR/responsibleProfiles',
  fetchFormMeta: () => ({
    type: actions.FETCH_FORM_META,
  }),
  fetchStudentProfiles: () => ({
    type: actions.FETCH_STUDENT_PROFILES,
  }),
  setResponsibleProfile: (id) => ({
    type: actions.SET_RESPONSIBLE_PROFILE,
    id,
  }),
  setReviewerResponsibleProfile: (id) => ({
    type: actions.SET_REVIEWER_RESPONSIBLE_PROFILE,
    id,
  }),
  setRecoveryResponsibleProfile: (id) => ({
    type: actions.SET_RECOVERY_RESPONSIBLE_PROFILE,
    id,
  }),
  createResponsibleProfile: (params) => ({
    type: actions.CREATE_RESPONSIBLE_PROFILE,
    params,
  }),
  updateResponsibleProfile: (id, params) => ({
    type: actions.UPDATE_RESPONSIBLE_PROFILE,
    id,
    params,
  }),
  approveResponsibleProfile: (id, params) => ({
    type: actions.APPROVE_RESPONSIBLE_PROFILE,
    id,
    params,
  }),
  recoverResponsibleProfile: (id, params) => ({
    type: actions.RECOVER_RESPONSIBLE_PROFILE,
    id,
    params,
  }),
  removeResponsibleProfile: (id) => ({
    type: actions.REMOVE_RESPONSIBLE_PROFILE,
    id,
  }),
};

export default actions;
