import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Field from 'components/Form/Field';
import Label from 'components/Form/Label';
import FormFieldset from 'components/Form/Fieldset';
import tabifyForWizard from 'core/hoc/tabifyForWizard';
import withFormContext, { formPropTypes } from 'core/hoc/withFormContext';
import withFormInputFocused from 'core/hoc/withFormInputFocused';
import withAppContext from 'core/hoc/withAppContext';
import { RIGHT_CENTER } from 'components/Tooltip';
import AgendaIcon from 'components/AgendaIcon';
import composeFunctions from 'core/utils/composeFunctions';
import ResponsibleAddress from '../../FormSections/ResponsibleAddress';
import ProfileChecker from '../../FormSections/ProfileChecker';
import FormValidationErrors from 'components/Form/ValidationErrors';
import StudentSelect from '../../FormSections/StudentSelect';
import validations from './validations';
import { phoneMask } from 'core/utils/masks.js';
import * as S from './styles';

const ResponsibleProfileInformationsTab = ({
  appContext: { policies },
  formContext: {
    form,
    formMeta,
    updateAttribute,
    changeMeta,
    hasErrorOnAttribute,
    action,
  },
}) => {
  const { kinshipSelectOptions } = formMeta;
  const { can_use_legacy_id: canUseLegacyId, can_edit_user: canEditUser } =
    policies;
  const { studentProfiles, isFetchStudents } = useSelector(
    (state) => state.responsibleProfiles
  );

  const { t } = useTranslation(['responsible_profiles']);

  const hasErrorOnPhoneField = hasErrorOnAttribute('phone');

  const onSelectStudents = (selected) => {
    updateAttribute('student_profile_ids', selected);
  };

  const onSelectKinship = (selected) => {
    updateAttribute('kinship', selected);
  };

  const validateNextStepButton = useCallback(() => {
    const allRequiredFieldsAreFilled = form.email || form.username;
    changeMeta('nextStepDisabled', !allRequiredFieldsAreFilled);
  }, [changeMeta, form.email, form.username]);

  useEffect(() => {
    validateNextStepButton();
  }, [form.email, form.username, validateNextStepButton]);

  const onPhoneChange = (e) => {
    updateAttribute('phone', phoneMask(e.target.value));
  };

  const onPhoneKeyUp = (e) => {
    e.target.value = phoneMask(e.target.value);
  };

  const onChangeCPF = (form) => {
    const documentNumber = form.document_number.replace(/[^0-9]/g, '');
    updateAttribute('document_number', documentNumber);
  };

  const legacyIdLabel = () => (
    <S.CustomLabel>
      <span>{t('form.legacy_id.label')}</span>
      <S.CustomTooltip
        content={t('form.legacy_id_tooltip')}
        position={RIGHT_CENTER}
        tooltipStyle={{
          maxWidth: '285px',
        }}
      >
        <AgendaIcon name="help" size="small" />
      </S.CustomTooltip>
    </S.CustomLabel>
  );

  const profileCheckerTitle = () => (
    <S.CheckerTitle>
      <h6>{t('form.profile_section_title')}</h6>
      <S.CustomTooltip
        content={t('form.profile_section_text')}
        position={RIGHT_CENTER}
        tooltipStyle={{
          maxWidth: '230px',
        }}
      >
        <AgendaIcon name="help" size="small" />
      </S.CustomTooltip>
    </S.CheckerTitle>
  );

  return (
    <div
      className="ResponsibleProfileInformationsTab"
      data-testid="ResponsibleProfileInformationsTab"
    >
      <S.Wrapper>
        <FormFieldset>
          {action == 'review' && <S.FormAlert>{t('form.alert')}</S.FormAlert>}
          {canUseLegacyId && (
            <div className="row">
              <Field
                label={legacyIdLabel()}
                placeholder=""
                attributeName="legacy_id"
              />
            </div>
          )}

          <div className="row">
            <Field
              label={t('form.name.label')}
              placeholder=""
              attributeName="name"
            />
          </div>

          <div className="row">
            <S.DoubleInputsWrapper>
              <Field
                label={t('form.document_number.label')}
                placeholder=""
                type="masked"
                mask="999.999.999-99"
                onChange={onChangeCPF}
                attributeName="document_number"
              />
              <S.Field>
                <Label>{t('form.phone.label')}</Label>
                <div className="TextField">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    attributeName="phone"
                    onKeyUp={onPhoneKeyUp}
                    onChange={onPhoneChange}
                    value={form['phone']}
                  />
                  {hasErrorOnPhoneField && (
                    <FormValidationErrors attributeName={'phone'} multiple />
                  )}
                </div>
              </S.Field>
            </S.DoubleInputsWrapper>
          </div>

          <ProfileChecker
            profileType="responsible_profiles"
            title={profileCheckerTitle()}
            emailLabel={t('form.email.label')}
            usernameLabel={t('form.username.label')}
            canEditUser={canEditUser}
          />

          <ResponsibleAddress />

          <StudentSelect
            loading={isFetchStudents}
            options={studentProfiles}
            onChange={onSelectStudents}
            value={form.student_profile_ids}
            defaultValue={form.student_profile_ids}
          />

          <S.StudentKinshipWrapper>
            <div className="row">
              <Field
                className="col-xs-12 col-md-6"
                menuPlacement="top"
                withArrowSelect
                label={t('form.kinship.label')}
                type="selectWithIcon"
                attributeName="kinship"
                rawValue={form.kinship}
                defaultValue=" "
                onChange={onSelectKinship}
                options={kinshipSelectOptions?.map((kinship) => ({
                  label: kinship.name,
                  value: kinship.value,
                }))}
                placeholder={t('form.kinship.placeholder')}
              />
            </div>
          </S.StudentKinshipWrapper>
        </FormFieldset>
      </S.Wrapper>
    </div>
  );
};

ResponsibleProfileInformationsTab.propTypes = {
  ...formPropTypes,
};

export default composeFunctions(
  withFormInputFocused,
  withFormContext,
  withAppContext,
  tabifyForWizard({
    title: '',
    subTitle: 'Informações gerais',
    validations,
  })
)(ResponsibleProfileInformationsTab);
