const validateTime = (constraints) => {
  const { before, after } = constraints || {};

  const parseToTime = (str) => {
    const hour = str.split(':')[0];
    const minute = str.split(':')[1];

    return (new Date(2020, 7, 12, hour, minute, 0));
  };

  return (str) => {
    if (!str) return false;

    const time = parseToTime(str);

    let result = true;

    if (before) {
      result = result && (time < parseToTime(before));
    }

    if (after) {
      result = result && (time > parseToTime(after));
    }

    return result;
  };
};

export default validateTime;
