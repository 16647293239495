import styled, { css } from 'styled-components';

type ContainerProps = {
  alignment: 'horizontally' | 'vertically';
  centered: boolean;
  spaceBetween: boolean;
};

type ImageProps = {
  width: string;
};

const contentAlignment = {
  horizontally: () => css`
    display: flex;
    align-items: center;
    flex-direction: column; // Ensure vertical alignment for small screen

    @media (min-width: 768px) {
      flex-direction: row;
    }
  `,
  vertically: () => css`
    display: flex;
    align-items: center;
    flex-direction: column;
  `,
};

export const Container = styled.div<ContainerProps>`
  ${({ theme, alignment, centered, spaceBetween }) => css`
    text-align: center;
    margin: 64px 0 32px;

    p {
      font-size: 16px;
      font-weight: 600;
      color: ${theme.black};
    }

    .vertically-aligned {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .flex-column {
      flex-direction: column;
    }

    .PageTitle {
      margin-bottom: 0px;

      @media (min-width: 768px) and (max-width: 1199px) {
        max-width: 27%;
      }
    }

    .PageSubTitle {
      margin-top: 12px;

      @media (min-width: 768px) and (max-width: 1199px) {
        max-width: 27%;
      }
    }

    .action {
      margin: 2em;
    }

    ${alignment && contentAlignment[alignment]}

    ${centered &&
    css`
      justify-content: center;
    `}
    ${spaceBetween &&
    css`
      justify-content: space-between;
    `}
  `}
`;

export const Image = styled.img<ImageProps>`
  width: 250px; // Ensure image decrease image width for small screens
  margin-bottom: 32px;

  @media (min-width: 768px) {
    width: ${({ width }) => width};
  }
`;
