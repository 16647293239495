/* eslint-disable @typescript-eslint/ban-ts-comment */

// @ts-ignore
import validatePresence from 'core/lib/FormValidator/validators/presence/';

// @ts-ignore
import validateLength from 'core/lib/FormValidator/validators/length/';

export default [
  {
    attributeName: 'categoryName',
    validator: validatePresence,
    message: 'Campo obrigatório',
  },
  {
    attributeName: 'categoryName',
    validator: validateLength({ max: 30 }),
    message: 'Insira um nome de até 30 caracteres',
  },
] as const;
