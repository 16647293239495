import React, { useEffect } from 'react';
import AgendaIcon from 'components/AgendaIcon';
import { IMAGES_HELP_URL } from 'core/constants';
import { HELP_URLS } from 'core/constants';
import { useTranslation } from 'react-i18next';
import * as S from './styles';

const HelpCard = (): JSX.Element => {
  const { t } = useTranslation(['help_popover']);

  const redirectMailTo = () => {
    window.open(
      'https://atendimento.agendaedu.com/hc/pt-br/requests/new',
      '_blank'
    );
  };

  return (
    <S.Container className="HelpBaloon">
      <S.CardHeader>
        <S.CardHeaderInfo>
          <S.CardHeaderTitle>{t('header.title')}</S.CardHeaderTitle>
          <S.CardHeaderText>{t('header.text')}</S.CardHeaderText>
        </S.CardHeaderInfo>

        <S.CardHeaderImage>
          <img src={IMAGES_HELP_URL.onboarding} alt="onboarding" />
        </S.CardHeaderImage>
      </S.CardHeader>

      <S.CardBody>
        <S.CardWrapper>
          <S.CardList>
            <S.CardListItem className="balloon-help">
              <S.CardListItemInfo>
                <S.IconBadge>
                  <AgendaIcon name="message" />
                </S.IconBadge>
                <div>
                  <S.CardListItemTitle>
                    {t('links.chat_bot.title')}
                  </S.CardListItemTitle>
                  <S.CardListItemDescription>
                    {t('links.chat_bot.description')}
                  </S.CardListItemDescription>
                </div>
              </S.CardListItemInfo>
            </S.CardListItem>

            <S.CardListItem
              href={HELP_URLS.home}
              target="_blank"
              rel="noopener noreferrer"
            >
              <S.CardListItemInfo>
                <S.IconBadge>
                  <AgendaIcon name="bookmark" />
                </S.IconBadge>
                <div>
                  <S.CardListItemTitle>
                    {t('links.help_center.title')}
                  </S.CardListItemTitle>
                  <S.CardListItemDescription>
                    {t('links.help_center.description')}
                  </S.CardListItemDescription>
                </div>
              </S.CardListItemInfo>

              <S.LinkList>
                <S.LinkListItem
                  href={HELP_URLS.forgotPassword}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('links.help_center.forgot_password_link')}
                </S.LinkListItem>
                <S.LinkListItem
                  href={HELP_URLS.accountConfirmation}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('links.help_center.account_confirmation_link')}
                </S.LinkListItem>
                <S.LinkListItem
                  href={HELP_URLS.gettingStarted}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('links.help_center.getting_started_link')}
                </S.LinkListItem>
              </S.LinkList>
            </S.CardListItem>

            <S.CardListItem onClick={redirectMailTo}>
              <S.CardListItemInfo>
                <S.IconBadge>
                  <AgendaIcon name="mail" />
                </S.IconBadge>
                <div>
                  <S.CardListItemTitle>
                    {t('links.send_email.title')}
                  </S.CardListItemTitle>
                  <S.CardListItemDescription>
                    {t('links.send_email.description')}
                  </S.CardListItemDescription>
                </div>
              </S.CardListItemInfo>
            </S.CardListItem>
          </S.CardList>
        </S.CardWrapper>
      </S.CardBody>
    </S.Container>
  );
};

export default HelpCard;
