import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import moment from 'moment';

import shopActions from 'store/edupay/shop/actions';
import actionSchoolProducts from 'store/edupay/schoolProducts/actions';

import { Button, Grid, Text } from '@agendaedu/ae-web-components';
import Loader from 'components/Loader';
import PageTitle from 'components/PageTitle';
import EmptyState from 'components/EmptyState';
import OfferOrderCard from 'components/Payments/Shop/OfferOrdersTab/OfferOrderCard';
import Toast from 'components/Toast';
import ManualDealModal from 'components/Payments/ManualDealModal';
import CancelModal from 'components/Payments/CancelModal';
import RefundModal from 'components/Payments/RefundModal';

import { IMAGES_PAYMENT_URL } from 'core/constants/index';

import * as S from './styles';

const OfferOrdersTab = ({ id }) => {
  const { fetchOfferOrders } = shopActions;
  const {
    setPaidAtSchoolOrder,
    setCancelOrder,
    setRefundOrder,
    setCloseRefundOrderSuccessModal,
    fetchCurrentSchoolProduct,
    fetchOrderRequest,
  } = actionSchoolProducts;

  const { offerOrders, isLoadingOfferOrders } = useSelector((state) => state.shop);
  const {
    isLoadingRefundOrder,
    showModalRefundOrderSuccess,
    currentSchoolProduct,
    currentOrder,
  } = useSelector((state) => state.schoolProducts);

  const dispatch = useDispatch();
  const { t } = useTranslation(['payments', 'common']);

  useEffect(() => {
    dispatch(fetchOfferOrders(id));
  }, [id]);

  const [showManuallyPaidModal, setShowManuallyPaidModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showRefundModal, setShowRefundModal] = useState(false);
  const [currentOfferOrder, setCurrentOfferOrder] = useState({});

  const toggleShowManuallyPaidModal = () => setShowManuallyPaidModal(!showManuallyPaidModal);
  const toggleShowCancelModal = () => setShowCancelModal(!showCancelModal);
  const toggleShowRefundModal = () => setShowRefundModal(!showRefundModal);
  const hasOrders = () => offerOrders?.meta?.total_orders_count > 0;
  const generateReport = () => window.open(`/schools/marketplaces/offers/${id}/orders.xls`, '_blank');

  const startManuallyPaidProcess = useCallback((offerOrder) => {
    setCurrentOfferOrder(offerOrder);
    dispatch(fetchCurrentSchoolProduct({ schoolProductId: offerOrder.school_product_id }));
    dispatch(fetchOrderRequest({ schoolProductId: offerOrder.school_product_id, orderId: offerOrder.order_id }));
    toggleShowManuallyPaidModal();
  }, [dispatch, setCurrentOfferOrder, fetchCurrentSchoolProduct, fetchOrderRequest, toggleShowManuallyPaidModal]);

  const manuallyPaid = useCallback((form) => {
    const { id: orderId } = currentOrder;

    dispatch(setPaidAtSchoolOrder({
      form,
      orderId,
      schoolProductId: currentOfferOrder.school_product_id,
    }));

    setTimeout(() => {
      toggleShowManuallyPaidModal();
      dispatch(fetchOfferOrders(id));
    }, 3000);
  },
    [
      dispatch,
      currentOrder,
      setPaidAtSchoolOrder,
      currentOfferOrder,
      toggleShowManuallyPaidModal,
      fetchOfferOrders
    ]
  );

  const startCancelProcess = (offerOrder) => {
    setCurrentOfferOrder(offerOrder);
    toggleShowCancelModal();
  };

  const cancelOrder = useCallback(() => {
    dispatch(setCancelOrder({
      schoolProductId: currentOfferOrder.school_product_id,
      orderId: currentOfferOrder.order_id
    }));

    setTimeout(() => {
      toggleShowCancelModal();
      dispatch(fetchOfferOrders(id));
    }, 3000);
  }, [dispatch, setCancelOrder, currentOfferOrder]);

  const startRefundProcess = (offerOrder) => {
    setCurrentOfferOrder(offerOrder);
    toggleShowRefundModal();
  };

  const refundOrder = useCallback((orderId) => {
    dispatch(setRefundOrder({
      schoolProductId: currentOfferOrder.school_product_id,
      orderId
    }));

    setTimeout(() => {
      toggleShowRefundModal();
      dispatch(fetchOfferOrders(id));
    }, 3000);
  }, [dispatch, setRefundOrder, currentOfferOrder]);

  const handleSetCloseRefundOrderSuccessModal = useCallback(() => {
    dispatch(setCloseRefundOrderSuccessModal());
  }, [dispatch, setCloseRefundOrderSuccessModal]);

  const renderOrderCounter = () => {
    return (
      <>
        <S.OrderCounterWrapper>
          <Text
            variant="subtitle-medium-14"
            lineHeight="md"
            mb={0}
            color={'neutral.gray2'}
          >
            {`${offerOrders.meta.total_orders_count} ${t('marketplace.orders.list.total_count_label')}`}
          </Text>
        </S.OrderCounterWrapper>
      </>
    );
  };

  const formatGroupDate = (date) => moment(date, "MMM YYYY").format("MMMM, YYYY");

  const renderOrderGroups = () => {
    return offerOrders.data.map((g) => (
      <div key={g.id}>
        <S.CapitalizedText
          variant="title-bold-20"
          lineHeight="md"
          mt="sm"
          mb="lg"
          color={'neutral.black'}
        >
          {formatGroupDate(g.attributes.date)}
        </S.CapitalizedText>

        {g.attributes.orders.map((o) => (
          <OfferOrderCard
            key={g.id}
            offerOrder={o}
            manuallyPaidAction={startManuallyPaidProcess}
            cancelAction={startCancelProcess}
            refundAction={startRefundProcess}
          />
        ))}
      </div>
    ));
  };

  const renderOrdersList = () => {
    return (
      <>
        {renderOrderCounter()}
        {renderOrderGroups()}
      </>
    );
  };

  const renderEmptyState = () => {
    return (
      <>
        <EmptyState
          imgUrl={IMAGES_PAYMENT_URL.availabilityUrl}
          message={parse(t('marketplace.orders.list.empty_state'))}
        />
      </>
    );
  };

  return (
    <Loader isLoading={isLoadingOfferOrders}>
      <S.FiltersWrapper>
        <S.ExportButtonWrapper item gridArea="export">
          <Button
            variant="secondary"
            disabled={!hasOrders()}
            onClick={generateReport}
          >
            {t('button.export', { ns: 'common' })}
          </Button>
        </S.ExportButtonWrapper>
      </S.FiltersWrapper>

      {hasOrders()
       ? renderOrdersList()
       : renderEmptyState()}

      {!!showManuallyPaidModal &&
       <ManualDealModal
         isOpen={showManuallyPaidModal}
         toggleModal={toggleShowManuallyPaidModal}
         order={currentOrder}
         bill={currentSchoolProduct}
         isRecurrent={false}
         title={t('modals.paidAtSchool.title')}
         onSubmit={manuallyPaid}
         modalType={'paidAtSchool'}
       />}

      <CancelModal
        isOpen={showCancelModal}
        toggleModal={toggleShowCancelModal}
        title={t('modals.cancel.title')}
        subTitle={t('modals.cancel.subtitle')}
        onCancel={cancelOrder}
      />
      <RefundModal
        showModalRefundOrder={showRefundModal}
        toggleRefundOrderModal={toggleShowRefundModal}
        isModalLoading={isLoadingRefundOrder}
        orderId={currentOfferOrder.order_id}
        onSubmit={refundOrder}
        showModalRefundSuccess={showModalRefundOrderSuccess}
        closeAlertModal={handleSetCloseRefundOrderSuccessModal}
      />
      <Toast />
    </Loader>
  );
};

export default OfferOrdersTab;
