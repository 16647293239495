import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TransferSummary from './TransferSummary';
import FormContainer from 'components/Form/FormContainer';
import WizardContainer from 'components/WizardForm/WizardContainer';
import Loader from 'components/Loader';
import TransferInformations from './TransferInformations';

import onChangeValidations from './onChangeValidations';

import './style.scss';

const WIZARD_STEPS = [TransferInformations, TransferSummary];

class TransferForm extends Component {
  state = {
    formMeta: {
      availableAmount: this.props.availableAmount,
      taxTransfer: this.props.taxTransfer,
      bankCode: this.props.bankCode,
      customValidation: ['amount'],
    },
  };

  render() {
    const { onSubmit, isSubmitting } = this.props;
    const { form, formMeta } = this.state;

    return (
      <div className="TransferForm">
        <FormContainer
          initialAttributes={form}
          formMeta={formMeta}
          onChangeValidations={onChangeValidations}
        >
          {isSubmitting ? (
            <Loader />
          ) : (
            <WizardContainer
              steps={WIZARD_STEPS}
              onSubmit={onSubmit}
              finishText="Transferir"
            />
          )}
        </FormContainer>
      </div>
    );
  }
}

TransferForm.propTypes = {
  availableAmount: PropTypes.number.isRequired,
  isSubmitting: PropTypes.bool,
  bankCode: PropTypes.string.isRequired,
  taxTransfer: PropTypes.number.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

TransferForm.defaultProps = {
  isSubmitting: false,
};

export default TransferForm;
