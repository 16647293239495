import React from 'react';
import { Link } from 'react-router-dom';
import * as S from './styles';
import { BreadcrumbProps, PathProps } from './types';
import { Text, Icon } from '@agendaedu/ae-web-components';

const Breadcrumb = ({
  title,
  path,
  subTitle,
  external = false,
}: BreadcrumbProps) => {
  const renderSinglePath = () => (
    <>
      {subTitle && <span className="subTitle">{subTitle} / </span>}
      {external ? (
        <a href={`${path}`} data-testid="externalLink">
          {title}
        </a>
      ) : (
        <Link key={title} to={path}>
          <Text
            as="p"
            variant="title-bold-14"
            fontWeight={700}
            m="8px 0px 8px 0px"
          >
            {title}
          </Text>
        </Link>
      )}
    </>
  );

  const renderArrayPath = (path: PathProps[]) => (
    <>
      {path.map(({ path, title }) => (
        <Link key={title} to={path}>
          <Text
            as="p"
            variant="title-bold-14"
            fontWeight={700}
            m="8px 0px 8px 0px"
          >
            {title}
          </Text>
        </Link>
      ))}
    </>
  );

  return (
    <S.Breadcrumb display="flex" flexDirection="row" alignContent="flex-start">
      <Icon name="home-filled" size="sm" />
      <Icon name="chevron-right" size="sm" />

      {Array.isArray(path) ? renderArrayPath(path) : renderSinglePath()}
    </S.Breadcrumb>
  );
};

export default Breadcrumb;
