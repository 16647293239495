import { getHoursAndMinutes } from 'core/utils/date';

import createModuleReducer from '../storeConfig/createModuleReducer';
import {
  GroupProps,
  MessageReducerProps,
  ResultNewGroupAction,
  ResultSchoolUsersAction,
  ResultGroupsAction,
  ResultGroupAction,
  ResultCurrentGroupAction,
  ResultMessagesAction,
  ResultNewMessageAction,
  ResultArchiveGroupAction,
  MessageProps,
  ResultMessageAction,
  ResultMuteAndTurnOnNotificationAction,
  ResultsActionDefault,
  ResultEditMessageAction,
  ResultGroupDetailsAction,
  ResultEditGroupSuccessAction,
  ResultGroupMembersAction,
  ResultDeleteMessageAction,
  ResultCurrentMessageAction,
  SetChangeFooterToastActionProps,
  SocketConnectSuccessActionProps,
} from './types';
import actions from './actions';

export const INITIAL_STATE: MessageReducerProps = {
  error: null,
  groups: [],
  groupMembers: [],
  groupMembersPagination: {
    page: 1,
    next: false,
  },
  messages: [],
  footerToast: {
    active: false,
    currentMessage: null,
    variant: null,
  },
  schoolUsers: [],
  currentGroup: null,
  currentMessage: null,
  selectedMessage: null,
  showDeleteGroupModal: false,
  showDetailModal: false,
  showDeleteMessageModal: false,
  showLeaveGroupModal: false,
  showModal: false,
  showNotificationModal: false,
  pagination: {
    page: 1,
    per_page: 20,
  },
  isEditingMessage: false,
  isSending: false,
  isLoading: false,
  isLoadingGroupMembers: false,
  isLoadingMessages: false,
  isLoadingMoreMessages: false,
  isSoundNotification: false,
  isReadCurrentMessages: false,
  socket: null,
};

export const handlerError = (
  state: MessageReducerProps,
  action: ResultsActionDefault
) => ({
  ...state,
  error: action.error,
  isLoading: false,
  isLoadingGroupMembers: false,
  footerToast: {
    active: false,
    currentMessage: null,
    variant: null,
  },
  showLeaveGroupModal: false,
});

export const clearCurrentGroup = (state: MessageReducerProps) => ({
  ...state,
  currentGroup: null,
  isSoundNotification: false,
});

export const closeEditMessage = (state: MessageReducerProps) => ({
  ...state,
  isEditingMessage: false,
  selectedMessage: null,
});

export const createNewGroupRequest = (state: MessageReducerProps) => ({
  ...state,
  isSending: true,
});

export const createNewGroupSuccess = (
  state: MessageReducerProps,
  action: ResultNewGroupAction
) => {
  const currentNewGroupList = [action.newGroupCreated, ...state.groups];

  return {
    ...state,
    groups: currentNewGroupList,
    currentGroup: action.newGroupCreated,
    isSending: false,
    isLoadingMessages: false,
  };
};

export const createNewMessageRequest = (state: MessageReducerProps) => ({
  ...state,
  isSending: true,
});

export const createNewMessageSuccess = (
  state: MessageReducerProps,
  action: ResultNewMessageAction
) => {
  const currentNewGroupMessageList = [
    action.newGroupMessageCreated,
    ...state.messages,
  ];

  const updateGroupList = state.groups.map((group: GroupProps) =>
    group.id === action.groupId
      ? {
          ...group,
          attributes: {
            ...state.currentGroup.attributes,
            last_message: {
              ...state.currentGroup.attributes.last_message,
              is_logged: true,
              content: action.newGroupMessageCreated.attributes.content,
              created_at: getHoursAndMinutes(
                action.newGroupMessageCreated.attributes.created_at
              ),
            },
          },
        }
      : group
  );

  return {
    ...state,
    groups: updateGroupList,
    messages: currentNewGroupMessageList,
    isSending: false,
    footerToast: {
      active: false,
      currentMessage: null,
      variant: null,
    },
  };
};

export const fetchSchoolUsersRequest = (state: MessageReducerProps) => ({
  ...state,
});

export const fetchSchoolUsersSuccess = (
  state: MessageReducerProps,
  action: ResultSchoolUsersAction
) => {
  return {
    ...state,
    schoolUsers: action.schoolUsers,
  };
};

export const fetchGroupsRequest = (state: MessageReducerProps) => ({
  ...state,
  isLoading: true,
});

export const fetchGroupsSuccess = (
  state: MessageReducerProps,
  action: ResultGroupsAction
) => {
  return {
    ...state,
    groups: action.groups,
    isLoading: false,
  };
};

export const fetchGroupDetailsRequest = (state: MessageReducerProps) => {
  return {
    ...state,
    currentGroup: null,
  };
};

export const fetchGroupDetailsSuccess = (
  state: MessageReducerProps,
  action: ResultGroupDetailsAction
) => {
  return {
    ...state,
    currentGroup: action.group,
  };
};

export const fetchGroupMembersRequest = (state: MessageReducerProps) => {
  return {
    ...state,
    isLoadingGroupMembers: !state.groupMembers.length,
  };
};

export const fetchGroupMembersSuccess = (
  state: MessageReducerProps,
  action: ResultGroupMembersAction
) => {
  return {
    ...state,
    groupMembers: state.groupMembers.length
      ? [...state.groupMembers, ...action.members]
      : action.members,
    groupMembersPagination: {
      page: action.meta.page,
      next: action.meta.next,
    },
    isLoadingGroupMembers: false,
  };
};

export const setCurrentGroup = (
  state: MessageReducerProps,
  action: ResultGroupAction
) => {
  const currentGroup = state.groups.find(
    (group: GroupProps) => group.id === action.groupId
  );

  return {
    ...state,
    currentGroup,
    messages: [],
    pagination: {
      ...state.pagination,
      page: 1,
    },
    isLoadingMessages: true,
  };
};

export const setCurrentGroupRequest = (state: MessageReducerProps) => ({
  ...state,
  isLoadingMessages: false,
});

export const setCurrentGroupSuccess = (
  state: MessageReducerProps,
  action: ResultCurrentGroupAction
) => {
  const currentGroup = state.groups.find(
    (group: GroupProps) => group.id === action.group.id
  );

  const settedCurrentGroup = {
    ...currentGroup,
    attributes: {
      ...currentGroup.attributes,
      unread_message_count: 0,
    },
  };

  const updateGroupList = state.groups.map((group: GroupProps) =>
    group.id === settedCurrentGroup.id ? settedCurrentGroup : group
  );

  return {
    ...state,
    currentGroup: settedCurrentGroup,
    groups: updateGroupList,
    messages: [],
    pagination: {
      ...state.pagination,
      page: 1,
    },
    isLoadingMessages: true,
  };
};

export const setDeleteGroupRequest = (state: MessageReducerProps) => ({
  ...state,
  showDeleteGroupModal: false,
});

export const setDeleteGroupSuccess = (state: MessageReducerProps) => ({
  ...state,
  groups: state.groups?.filter(
    (group: GroupProps) => group?.id !== state.currentGroup?.id
  ),
  currentGroup: null,
});

export const setDeleteMessageRequest = (state: MessageReducerProps) => ({
  ...state,
  showDeleteMessageModal: false,
});

export const setDeleteMessageSuccess = (
  state: MessageReducerProps,
  action: ResultDeleteMessageAction
) => {
  const { deletedMessage } = action;

  const messages = state.messages.map((message: MessageProps) =>
    message.id === deletedMessage.id ? deletedMessage : message
  );

  return {
    ...state,
    messages,
  };
};

export const setEditGroupRequest = (state: MessageReducerProps) => ({
  ...state,
  isSending: true,
});

export const setEditGroupSuccess = (
  state: MessageReducerProps,
  action: ResultEditGroupSuccessAction
) => ({
  ...state,
  isSending: false,
  currentGroup: action.group,
});

export const setLeaveGroupRequest = (state: MessageReducerProps) => ({
  ...state,
});

export const setLeaveGroupSuccess = (state: MessageReducerProps) => ({
  ...state,
  groups: state.groups?.filter(
    (group: GroupProps) => group?.id !== state.currentGroup?.id
  ),
  currentGroup: null,
  showLeaveGroupModal: false,
});

export const fetchMessagesRequest = (state: MessageReducerProps) => ({
  ...state,
  isLoadingMoreMessages: true,
});

export const fetchMessagesSuccess = (
  state: MessageReducerProps,
  action: ResultMessagesAction
) => {
  let updatedMessageList: MessageProps[];

  if (action.pagination.page === 1) {
    updatedMessageList = [...action.messages];
  } else {
    updatedMessageList = [...state.messages, ...action.messages];
  }

  return {
    ...state,
    messages: updatedMessageList,
    pagination: {
      ...state.pagination,
      page: action.pagination.page,
    },
    isLoadingMessages: false,
    isLoadingMoreMessages: false,
  };
};

export const fetchLastMessageRequest = (state: MessageReducerProps) => ({
  ...state,
  isSoundNotification: false,
});

export const fetchLastMessageSuccess = (
  state: MessageReducerProps,
  action: ResultMessageAction
) => {
  let updateMessageList: MessageProps[] = [...state.messages];
  let isNotification: boolean;
  let isReadCurrentMessages: boolean;

  const updateGroupList = state.groups.map((group: GroupProps) =>
    group.id === action.groupId
      ? {
          ...group,
          attributes: {
            ...group.attributes,
            last_message: {
              ...group.attributes.last_message,
              content: action.message.attributes.content,
              sender: action.message?.attributes.sender?.attributes.name,
              is_logged: action.message.attributes.is_logged,
              created_at: getHoursAndMinutes(
                action.message.attributes.created_at
              ),
            },
            unread_message_count:
              state.currentGroup?.id === action.groupId
                ? group.attributes.unread_message_count
                : group.attributes.unread_message_count + 1,
          },
        }
      : group
  );

  const isGroupNotification = state.groups.find(
    (group: GroupProps) => group.id === action.groupId
  );

  const updatedMessages = {
    sent: () => {
      isNotification = false;
    },
    received: () => {
      updateMessageList =
        state.currentGroup?.id === action.groupId
          ? [action.message, ...state.messages]
          : [...state.messages];
      isNotification =
        isGroupNotification.attributes.enable_sound &&
        state.currentGroup?.id !== action.groupId;
      isReadCurrentMessages = state.currentGroup?.id === action.groupId;
    },
  };

  updatedMessages[action.message.attributes.type]();

  return {
    ...state,
    groups: updateGroupList,
    messages: updateMessageList,
    isSoundNotification: isNotification,
    isReadCurrentMessages,
  };
};

export const fetchRealTimeMessageRequest = (state: MessageReducerProps) => ({
  ...state,
  isEditingMessage: false,
});

export const fetchRealTimeMessageSuccess = (
  state: MessageReducerProps,
  action: ResultMessageAction
) => {
  const { message, groupId } = action;

  const updatedMessageList = state.messages.map((msg: MessageProps) =>
    msg.id === message.id ? message : msg
  );

  const isLastMessage = message.id === state.messages[0].id;

  const updatedGroupList = state.groups?.map((group: GroupProps) =>
    group.id === groupId && isLastMessage
      ? {
          ...group,
          attributes: {
            ...group.attributes,
            last_message: {
              sender: message?.attributes.sender.attributes.name,
              content: message?.attributes.content,
              is_logged: message?.attributes.is_logged,
              created_at: getHoursAndMinutes(message?.attributes.created_at),
            },
          },
        }
      : group
  );

  return {
    ...state,
    messages: updatedMessageList,
    isSending: false,
    isEditingMessage: false,
    selectedMessage: null,
    groups: updatedGroupList,
  };
};

export const setArchiveGroupRequest = (state: MessageReducerProps) => ({
  ...state,
  isLoading: true,
});

export const setArchiveGroupSuccess = (
  state: MessageReducerProps,
  action: ResultArchiveGroupAction
) => {
  const currentGroupList = state.groups.filter(
    (group: GroupProps) => group.id !== action.currentGroup.id
  );

  return {
    ...state,
    groups: currentGroupList,
    messages: [],
    currentGroup: null,
    isLoading: false,
  };
};

export const setCurrentMessage = (
  state: MessageReducerProps,
  action: ResultCurrentMessageAction
) => ({
  ...state,
  currentMessage: action.message,
});

export const setChangeFooterToast = (
  state: MessageReducerProps,
  action: SetChangeFooterToastActionProps
) => ({
  ...state,
  footerToast: {
    ...state.footerToast,
    ...action.params,
  },
});

export const setEditMessage = (
  state: MessageReducerProps,
  action: ResultEditMessageAction
) => ({
  ...state,
  isEditingMessage: true,
  selectedMessage: action.message,
});

export const setReadCurrentMessagesRequest = (state: MessageReducerProps) => ({
  ...state,
  isReadCurrentMessages: true,
});

export const setReadCurrentMessagesSuccess = (state: MessageReducerProps) => ({
  ...state,
  isReadCurrentMessages: false,
});

export const setMuteAndTurnOnNotificationRequest = (
  state: MessageReducerProps
) => ({
  ...state,
  isLoading: false,
});

export const setMuteAndTurnOnNotificationSuccess = (
  state: MessageReducerProps,
  action: ResultMuteAndTurnOnNotificationAction
) => {
  const currentGroup = {
    ...action.currentGroup,
    attributes: {
      ...action.currentGroup.attributes,
      enable_sound: action.enableSound,
    },
  };

  const updateGroupList = state.groups.map((group: GroupProps) =>
    group.id === action.currentGroup.id ? currentGroup : group
  );

  return {
    ...state,
    groups: updateGroupList,
    currentGroup,
  };
};

export const toggleDeleteMessageModal = (state: MessageReducerProps) => ({
  ...state,
  showDeleteMessageModal: !state.showDeleteMessageModal,
});

export const toggleDeleteGroupModal = (state: MessageReducerProps) => ({
  ...state,
  showDeleteGroupModal: !state.showDeleteGroupModal,
});

export const toggleModal = (state: MessageReducerProps) => ({
  ...state,
  showModal: !state.showModal,
});

export const toggleDetailModal = (state: MessageReducerProps) => ({
  ...state,
  showDetailModal: !state.showDetailModal,
  groupMembersPagination: {
    page: 1,
    next: false,
  },
  groupMembers: [],
});

export const toggleLeaveGroupModal = (state: MessageReducerProps) => ({
  ...state,
  showLeaveGroupModal: !state.showLeaveGroupModal,
});

export const toggleNotificationModal = (state: MessageReducerProps) => ({
  ...state,
  showNotificationModal: !state.showNotificationModal,
});

export const updateMessageRequest = (state: MessageReducerProps) => ({
  ...state,
  isSending: true,
});

export const updateMessageSuccess = (
  state: MessageReducerProps,
  action: ResultMessageAction
) => {
  const updatedMessageList = state.messages.map((message: MessageProps) =>
    message.id === action.message.id ? action.message : message
  );

  return {
    ...state,
    messages: updatedMessageList,
    isSending: false,
    isEditingMessage: false,
    selectedMessage: null,
    footerToast: {
      active: false,
      currentMessage: null,
      variant: null,
    },
  };
};

export const socketConnectSuccess = (
  state: MessageReducerProps,
  action: SocketConnectSuccessActionProps
) => ({
  ...state,
  socket: action.socket,
});

const HANDLERS = {
  [actions.ERROR]: handlerError,
  [actions.CLEAR_CURRENT_GROUP]: clearCurrentGroup,
  [actions.CLOSE_EDIT_MESSAGE]: closeEditMessage,
  [actions.CREATE_NEW_GROUP_REQUEST]: createNewGroupRequest,
  [actions.CREATE_NEW_GROUP_SUCCESS]: createNewGroupSuccess,
  [actions.CREATE_NEW_MESSAGE_REQUEST]: createNewMessageRequest,
  [actions.CREATE_NEW_MESSAGE_SUCCESS]: createNewMessageSuccess,
  [actions.FETCH_SCHOOL_USERS_REQUEST]: fetchSchoolUsersRequest,
  [actions.FETCH_SCHOOL_USERS_SUCCESS]: fetchSchoolUsersSuccess,
  [actions.FETCH_GROUP_DETAILS_REQUEST]: fetchGroupDetailsRequest,
  [actions.FETCH_GROUP_DETAILS_SUCCESS]: fetchGroupDetailsSuccess,
  [actions.FETCH_GROUP_MEMBERS_REQUEST]: fetchGroupMembersRequest,
  [actions.FETCH_GROUP_MEMBERS_SUCCESS]: fetchGroupMembersSuccess,
  [actions.FETCH_GROUPS_REQUEST]: fetchGroupsRequest,
  [actions.FETCH_GROUPS_SUCCESS]: fetchGroupsSuccess,
  [actions.FETCH_MESSAGES_REQUEST]: fetchMessagesRequest,
  [actions.FETCH_MESSAGES_SUCCESS]: fetchMessagesSuccess,
  [actions.FETCH_LAST_MESSAGE_REQUEST]: fetchLastMessageRequest,
  [actions.FETCH_LAST_MESSAGE_SUCCESS]: fetchLastMessageSuccess,
  [actions.FETCH_REAL_TIME_MESSAGE_REQUEST]: fetchRealTimeMessageRequest,
  [actions.FETCH_REAL_TIME_MESSAGE_SUCCESS]: fetchRealTimeMessageSuccess,
  [actions.SET_ARCHIVE_GROUP_REQUEST]: setArchiveGroupRequest,
  [actions.SET_ARCHIVE_GROUP_SUCCESS]: setArchiveGroupSuccess,
  [actions.SET_CURRENT_MESSAGE]: setCurrentMessage,
  [actions.SET_EDIT_MESSAGE]: setEditMessage,
  [actions.SET_MUTE_AND_TURN_ON_NOTIFICATION_REQUEST]:
    setMuteAndTurnOnNotificationRequest,
  [actions.SET_MUTE_AND_TURN_ON_NOTIFICATION_SUCCESS]:
    setMuteAndTurnOnNotificationSuccess,
  [actions.SET_CURRENT_GROUP]: setCurrentGroup,
  [actions.SET_CURRENT_GROUP_REQUEST]: setCurrentGroupRequest,
  [actions.SET_CURRENT_GROUP_SUCCESS]: setCurrentGroupSuccess,
  [actions.SET_DELETE_GROUP_REQUEST]: setDeleteGroupRequest,
  [actions.SET_DELETE_GROUP_SUCCESS]: setDeleteGroupSuccess,
  [actions.SET_DELETE_MESSAGE_REQUEST]: setDeleteMessageRequest,
  [actions.SET_DELETE_MESSAGE_SUCCESS]: setDeleteMessageSuccess,
  [actions.SET_EDIT_GROUP_REQUEST]: setEditGroupRequest,
  [actions.SET_EDIT_GROUP_SUCCESS]: setEditGroupSuccess,
  [actions.SET_LEAVE_GROUP_REQUEST]: setLeaveGroupRequest,
  [actions.SET_LEAVE_GROUP_SUCCESS]: setLeaveGroupSuccess,
  [actions.SET_READ_CURRENT_MESSAGES_REQUEST]: setReadCurrentMessagesRequest,
  [actions.SET_READ_CURRENT_MESSAGES_SUCCESS]: setReadCurrentMessagesSuccess,
  [actions.SET_CHANGE_FOOTER_TOAST]: setChangeFooterToast,
  [actions.TOGGLE_DELETE_MESSAGE_MODAL]: toggleDeleteMessageModal,
  [actions.TOGGLE_DELETE_GROUP_MODAL]: toggleDeleteGroupModal,
  [actions.TOGGLE_MODAL]: toggleModal,
  [actions.TOGGLE_DETAIL_MODAL]: toggleDetailModal,
  [actions.TOGGLE_LEAVE_GROUP_MODAL]: toggleLeaveGroupModal,
  [actions.TOGGLE_NOTIFICATION_MODAL]: toggleNotificationModal,
  [actions.UPDATE_MESSAGE_REQUEST]: updateMessageRequest,
  [actions.UPDATE_MESSAGE_SUCCESS]: updateMessageSuccess,
  [actions.SOCKET_CONNECT_SERVER_SUCCESS]: socketConnectSuccess,
};

const messages = createModuleReducer(INITIAL_STATE, HANDLERS);

export default messages;
