import React from 'react';

import { useTranslation } from 'react-i18next';

import DiaryActions from 'store/dailySummaries/actions';

import { Box, Button, Modal, Text } from '@agendaedu/ae-web-components';
import { Props } from './types';
import { useDispatch } from 'react-redux';

export const DeleteSectionModal = ({
  diarySectionId,
  isOpen,
  onClose,
}: Props): JSX.Element => {
  const { t } = useTranslation(['diary_sections', 'common']);
  const dispatch = useDispatch();

  const { deleteDiarySection } = DiaryActions;

  const onConfirmeDelete = () => {
    dispatch(deleteDiarySection({ diarySectionId }));
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      title={t('sections.delete_section_modal.title')}
      onClose={onClose}
    >
      <Box width="100%" mt="sm">
        <Text
          data-testid="text-content-modal"
          variant="body-regular-16"
          mx="xs3"
          maxWidth={360}
          lineHeight="lg"
        >
          {t('sections.delete_section_modal.content')}
        </Text>

        <Box display="flex" justifyContent="flex-end" mt="md">
          <Button
            id="cancel-section-delete-button"
            testId="back-button"
            variant="secondary"
            mr="sm"
            onClick={onClose}
          >
            {t('common:button.back')}
          </Button>
          <Button
            id="confirm-section-delete-button"
            testId="delete-button"
            isNegativeAction
            onClick={onConfirmeDelete}
          >
            {t('common:button.delete')}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
