import React from 'react';
import Linkify from 'react-linkify';
import { Text } from '@agendaedu/ae-web-components';

import * as S from './styles';

import { MessageBallonContentProps } from './types';

export const MessageBallonContent = ({
  message,
}: MessageBallonContentProps): JSX.Element => {
  const {
    approvalStatus,
    status,
    type,
    text,
    attachedMessageType,
    user,
    metadata: { anchoringData },
  } = message.attributes;

  const attached = message.relationships.attachedMessage.data?.attributes;
  const isRedirectMessage = anchoringData?.type === 'chatRedirection';
  const isDeletedMessage = status === 'deleted';
  const isReplyMessage = attachedMessageType === 'direct_reply';
  const isMyOwnerMessage = type !== 'received' || isRedirectMessage;

  return (
    <S.Wrapper
      isRedirectMessage={isRedirectMessage}
      userName={user?.attributes?.name}
    >
      {isReplyMessage && status !== 'deleted' && (
        <S.ReplyMessageWraper
          variantType={type}
          isReplyMessage={isReplyMessage}
          approvalStatus={approvalStatus}
        >
          <S.SentWrapper>
            <Text variant="body-bold-14">{attached?.user.attributes.name}</Text>
            <Text variant="body-regular-14">{attached?.text}</Text>
          </S.SentWrapper>
        </S.ReplyMessageWraper>
      )}

      <S.MessageWrapper
        deleted={isDeletedMessage}
        sent={isMyOwnerMessage}
        approvalStatus={approvalStatus}
      >
        {isDeletedMessage && (
          <S.DeletedMessageIcon
            name="forbidden"
            size="md"
            data-testid="deleted-icon"
          />
        )}

        <S.MessageContent
          variant="body-regular-14"
          isMyOwnerMessage={isMyOwnerMessage}
        >
          <Linkify properties={{ target: '_blank' }}>{text}</Linkify>
        </S.MessageContent>
      </S.MessageWrapper>
    </S.Wrapper>
  );
};
