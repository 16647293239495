import styled, { css } from 'styled-components';

export const ThumbnailWrapper = styled.div<{ hasTagNew: boolean }>`
  ${({
    theme: {
      border: { radius },
    },
    hasTagNew,
  }) => css`
    position: relative;
    height: 50%;
    border-radius: ${radius.md};
    overflow: hidden;

    ${hasTagNew &&
    css`
      &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.5) 100%
        );

        z-index: 1;
      }
    `}
  `}
`;

export const ThumbnailImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  -o-object-fit: cover;
  object-position: center;
  -o-object-position: center;
`;
