import React from 'react';
import moment from 'moment';

import ModalContainer from 'components/Modal/ModalContainer';
import LabeledContainer from 'components/LabeledContainer';
import Button from 'components/Button';
import ButtonRow from 'components/ButtonRow';

import { addZeroBeforeNumber } from 'core/utils/addZeroBeforeNumber';
import { centsToBRL } from 'core/utils/centsToBrl';

import { NegotiationConfirmSaveModalProps } from './types';
import * as S from './styles';

const NegotiationConfirmSaveModal = ({
  isConfirmModalOpen,
  handleSave,
  handleClose,
  form,
  formMeta: { originalRecurrentBills, selectedRecurrentBills },
}: NegotiationConfirmSaveModalProps) => {
  const minValueSelected = form.selected_value_cents;

  const formatBillData = () => {
    const data = form.negotiationBills.map((bill, index) => {
      return {
        title:
          index === 0
            ? 'Primeira cobrança'
            : `Cobrança ${addZeroBeforeNumber(index + 1)}`,
        value:
          bill.price_kind === 'money'
            ? bill.price_value
            : ((minValueSelected / 100) * bill.price_value) / 100,
      };
    });

    return data;
  };

  const formatedBillData = formatBillData();

  const formatedFinesAndInterestData = [
    {
      title: '%Juros',
      value: form.edupay_penality.fine_percent,
    },
    {
      title: '%Multa',
      value: form.edupay_penality.interest_percent,
    },
  ];

  const BRLMoney = (value: number) =>
    value.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });

  return (
    <ModalContainer
      isOpen={isConfirmModalOpen}
      toggleModal={handleClose}
      maxWidth="522px"
      className=""
      title="Resumo da negociação"
    >
      <>
        <div>
          <S.Subtitle>Cobranças originais</S.Subtitle>
          <S.List>
            {selectedRecurrentBills.map((recurrentBillId) => (
              <li key={recurrentBillId}>{`ID ${recurrentBillId}`}</li>
            ))}
          </S.List>
          <S.SubtotalContainer>
            <span>Subtotal da cobranças originais</span>
            <span>{centsToBRL(minValueSelected)}</span>
          </S.SubtotalContainer>
        </div>

        <S.Divider />

        <S.BillInfosContainer>
          <LabeledContainer title="Venc. da 1° cobrança">
            <p>{moment(form.first_bill_due_date).format('DD/MM/YYYY')}</p>
          </LabeledContainer>
          <LabeledContainer title="Venc. demais cobranças">
            <p>{`Todo dia ${form.due_day} do mês`}</p>
          </LabeledContainer>
          <LabeledContainer title="Envio das cobranças">
            <p>{`${form.bill_release_day} dias antes do vencimento`}</p>
          </LabeledContainer>
        </S.BillInfosContainer>
        <S.ObservationContainer>
          <p>Observações da negociação</p>
          {form.observation}
        </S.ObservationContainer>

        <S.Divider />

        <S.BillSummaryTitle>Resumo da cobrança</S.BillSummaryTitle>
        <S.BillSummaryContainer>
          <S.Table>
            <thead>
              <tr>
                <S.Th>Cobranças</S.Th>
                <S.Th>Valor cobrado</S.Th>
              </tr>
            </thead>
            <tbody>
              {formatedBillData.map((bill, index) => (
                <tr key={index}>
                  <S.Td>{bill.title}</S.Td>
                  <S.Td>{BRLMoney(bill.value)}</S.Td>
                </tr>
              ))}
            </tbody>
          </S.Table>

          <S.NegativeMarginDivider />

          {form.toggle_penality && (
            <>
              <S.Table>
                <thead>
                  <tr>
                    <S.Th>Multas e juros aplicados na cobrança</S.Th>
                  </tr>
                </thead>
                <tbody>
                  {formatedFinesAndInterestData.map((bill, index) => (
                    <tr key={index}>
                      <S.Td>{bill.title}</S.Td>
                      <S.Td>
                        {`% ${bill.value.toLocaleString('pt-BR', {
                          minimumFractionDigits: 2,
                        })}`}
                      </S.Td>
                    </tr>
                  ))}
                </tbody>
              </S.Table>

              <S.NegativeMarginDivider />
            </>
          )}

          <S.FixedWidthDiv>
            <S.BillSummaryTitle size="18px">
              Total da Cobrança
            </S.BillSummaryTitle>
          </S.FixedWidthDiv>
          <S.BillSummaryTitle size="18px">
            {BRLMoney(form.negotiationSubtotal)}
          </S.BillSummaryTitle>
        </S.BillSummaryContainer>

        <S.InfoText>
          O valor total da negociação pode conter taxas. Para saber mais, acesse
          nossa{' '}
          <a
            href="https://suporte.agendaedu.com/hc/pt-br"
            target={'_blank'}
            rel="noreferrer"
          >
            central de ajuda.
          </a>
        </S.InfoText>
        <S.BottomContainer>
          <ButtonRow>
            <Button variation="secondary" onClick={handleClose}>
              Fechar
            </Button>
            <Button variation="primary" onClick={handleSave}>
              Criar novas cobranças
            </Button>
          </ButtonRow>
        </S.BottomContainer>
      </>
    </ModalContainer>
  );
};

export default NegotiationConfirmSaveModal;
