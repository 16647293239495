import React, { useMemo } from 'react';
import Parser from 'html-react-parser';
import PropTypes from 'prop-types';
import { Icon, DefaultThemeProps } from '@agendaedu/ae-web-components';
import { useTheme } from 'styled-components';
import { OFFER_STATUS, PAYMENT_METHODS_ICONS } from 'core/constants/index';
import ColoredText from 'components/ColoredText';
import LabeledContainer from 'components/LabeledContainer';
import AgendaIcon from 'components/AgendaIcon';
import PaymentMethodLabeled from 'components/Payments/Shop/PaymentMethodLabeled';
import ClassroomsList from 'components/ClassroomsList';
import OutlineBox from 'components/OutlineBox';
import PageTitle from 'components/PageTitle';
import ButtonRow from 'components/ButtonRow';
import * as S from './styles';

const OfferDetailsInformationsTab = ({ offer }) => {
  const {
    attributes: {
      allowed_payment_methods,
      days_to_expire_payment,
      expires_at,
      original_price_cents,
      product,
      starts_at,
      max_quantity_per_order,
      status,
      max_installments,
    },
    id,
  } = offer.data;
  const centsToBRL = (cents) =>
    (cents / 100).toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });

  const {
    attributes: { has_stock, quantity_stock, items_sold },
  } = product.data;

  const { colors } = useTheme() as DefaultThemeProps;

  const image = product.data.attributes.image.data.attributes;

  const getButtons = () => {
    let buttons = [];

    if (
      status === 'active' ||
      status === 'not_started' ||
      status === 'closed'||
      status === 'stock_exceeded'
    ) {
      buttons.unshift({
        path: `/schools/marketplaces/offers/${id}/edit`,
        variation: 'secondary',
        text: <AgendaIcon name="edit" />,
      });
    }

    return buttons;
  };

  const footerButtons = useMemo(() => getButtons(), []);

  return (
    <S.OfferDetailsInformationsWrapper>
      <S.StatusWrapper>
        <OutlineBox variation={OFFER_STATUS[status].variation}>
          {OFFER_STATUS[status].label}
        </OutlineBox>

        <ClassroomsList
          classroomsNames={offer.included
            .filter((recipients) => recipients.type === 'classroom')
            .map((classrooms) => classrooms.attributes.name)}
        />

        <ClassroomsList
          classroomsNames={offer.included
            .filter((recipients) => recipients.type === 'student_profile')
            .map((students) => students.attributes.name)}
          kindItem="alunos"
        />
      </S.StatusWrapper>

      <PageTitle>{product.data.attributes.title}</PageTitle>

      <S.InfoFlexbox>
        <S.InfoIconWrapper>
          <span className="icon-wrapper">
            <AgendaIcon name="calendar" size="x-medium" />
          </span>
          <LabeledContainer title="Período de venda">
            <p>{`${starts_at} até ${expires_at}`}</p>
          </LabeledContainer>
        </S.InfoIconWrapper>

        <S.InfoIconWrapper>
          <span className="icon-wrapper">
            <AgendaIcon name="calendar-clock" size="x-medium" />
          </span>
          <LabeledContainer title="Data de vencimento">
            <p>{`Último dia de venda ou ${days_to_expire_payment} dias após a compra`}</p>
          </LabeledContainer>
        </S.InfoIconWrapper>

        {has_stock && (
          <S.InfoIconWrapper>
            <span className="icon-wrapper">
              <Icon color={colors.brand.primary.default} name={'menu'} size="md" />
            </span>
            <LabeledContainer title="Estoque disponível">
              <p>{`${quantity_stock - items_sold} de ${quantity_stock}`}</p>
            </LabeledContainer>
          </S.InfoIconWrapper>
        )}

        <S.InfoIconWrapper>
          <span className="icon-wrapper">
            <AgendaIcon name="money" size="x-medium" />
          </span>
          <LabeledContainer title="Valor">
            <p>{centsToBRL(original_price_cents)}</p>
          </LabeledContainer>
        </S.InfoIconWrapper>

        <S.InfoIconWrapper>
          <span className="icon-wrapper">
            <AgendaIcon name="transactions" size="x-medium" />
          </span>
          <LabeledContainer title="Quantidade máxima">
            <p>{`${max_quantity_per_order} itens por compra`}</p>
          </LabeledContainer>
        </S.InfoIconWrapper>
      </S.InfoFlexbox>

      <S.PaymentMethodsWrapper>
        <ColoredText variation="dark">
          Métodos de pagamento habilitados
        </ColoredText>
        <div className="methods">
          {allowed_payment_methods?.map((paymentMethod) => {
            return (
              <PaymentMethodLabeled
                key={paymentMethod}
                iconName={PAYMENT_METHODS_ICONS[paymentMethod].iconName}
                label={
                  paymentMethod === 'credit_card'
                    ? `${PAYMENT_METHODS_ICONS[paymentMethod].label} - ${max_installments}x`
                    : PAYMENT_METHODS_ICONS[paymentMethod].label
                }
              />
            );
          })}
        </div>
      </S.PaymentMethodsWrapper>

      <S.DescriptionWrapper>
        <ColoredText variation="dark">Descrição</ColoredText> <br />
        {Parser(product.data.attributes.description)}
      </S.DescriptionWrapper>

      <ColoredText variation="gray-2">Capa da oferta</ColoredText>
      <S.OfferImgWrapper>
        <div className="offer-img">
          <img src={image.url} />
        </div>
        <div className="offer-img-footer">
          <AgendaIcon name="attachment" size="x-medium" />
          <ColoredText variation="gray-2">{image.name}</ColoredText>
          <ColoredText variation="default">{`${image.size} kb`}</ColoredText>
        </div>
      </S.OfferImgWrapper>
      {footerButtons.length > 0 && (
        <>
          <hr />
          <S.FooterButtonsContainer>
            <ButtonRow buttons={footerButtons} />
          </S.FooterButtonsContainer>
        </>
      )}
    </S.OfferDetailsInformationsWrapper>
  );
};

OfferDetailsInformationsTab.defaultProps = {};

OfferDetailsInformationsTab.propTypes = {
  offer: PropTypes.shape().isRequired,
  id: PropTypes.number,
};

export default OfferDetailsInformationsTab;
