import React from 'react';

import reduxProvider from 'core/hoc/reduxProvider';

import MultimediaConsumption from 'components/MyAccount/MultimediaConsumption';

import './style.scss';

const MultimediaConsumptionScreen = () => {
  return (
    <section id="multimedia-consumption-screen">
      <h2 className="page-title">Consumo de dados</h2>
      <MultimediaConsumption />
    </section>
  );
};

export default reduxProvider(MultimediaConsumptionScreen);
