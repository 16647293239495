import createModuleReducer from '../storeConfig/createModuleReducer';
import actions from './actions';

export const INITIAL_STATE = {
  headquarters: null,
  educationalStages: [],
  classrooms: [],
  activeSchoolTerms: [],
  error: null,
  isLoading: false,
};

const fetchHeadquartersRequest = (state) => ({
  ...state,
  isLoading: true,
});

const fetchHeadquartersSuccess = (state, action) => {
  const formattedHeadquarters = [{ value: null, label: 'Selecione a unidade' }];

  action.headquarters.forEach((headquarter) => {
    formattedHeadquarters.push({
      value: headquarter.id,
      label: headquarter.attributes.name,
    });
  });

  return {
    ...state,
    headquarters: formattedHeadquarters,
    isLoading: false,
  };
};

const fetchEducationalStagesSuccess = (state, action) => {
  const formattedEducationalStages = [
    { value: null, label: 'Selecione o segmento' },
  ];

  action.educationalStages.forEach((educationalStage) => {
    formattedEducationalStages.push({
      value: educationalStage.id,
      label: educationalStage.attributes.name,
    });
  });

  return {
    ...state,
    educationalStages: formattedEducationalStages,
  };
};

const fetchClassRoomsSuccess = (state, action) => {
  const formattedClassrooms = [{ value: null, label: 'Selecione a turma' }];

  action.classrooms.forEach((classroom) => {
    formattedClassrooms.push({
      value: classroom.id,
      label: classroom.attributes.name,
    });
  });

  return {
    ...state,
    classrooms: formattedClassrooms,
  };
};

const fetchActiveSchoolTermsRequest = (state) => ({
  ...state,
  isLoading: true,
});

const fetchActiveSchoolTermsSuccess = (state, action) => {
  const formattedSchoolTerms = action.activeSchoolTerm.map((schoolTerm) => ({
    id: schoolTerm.id,
    label: schoolTerm.attributes.name,
    value: schoolTerm.attributes.name,
    isActive: schoolTerm.attributes.status,
  }));

  return {
    ...state,
    activeSchoolTerms: formattedSchoolTerms,
    isLoading: false,
  };
};

const handleError = (state, action) => ({
  ...state,
  error: action.error,
  isLoading: false,
});

const HANDLERS = {
  [actions.FETCH_ACTIVE_SCHOOL_TERMS_REQUEST]: fetchActiveSchoolTermsRequest,
  [actions.FETCH_ACTIVE_SCHOOL_TERMS_SUCCESS]: fetchActiveSchoolTermsSuccess,
  [actions.FETCH_HEADQUARTERS_REQUEST]: fetchHeadquartersRequest,
  [actions.FETCH_HEADQUARTERS_SUCCESS]: fetchHeadquartersSuccess,
  [actions.FETCH_EDUCATIONAL_STAGES_SUCCESS]: fetchEducationalStagesSuccess,
  [actions.FETCH_CLASSROOMS_SUCCESS]: fetchClassRoomsSuccess,
  [actions.ERROR]: handleError,
};

const filters = createModuleReducer(INITIAL_STATE, HANDLERS);

export default filters;
