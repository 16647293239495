/* eslint-disable no-debugger, react/prop-types */
import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import Button from 'components/Button';
import ButtonRow from 'components/ButtonRow';
import AgendaIcon from 'components/AgendaIcon';
import Tooltip, { LEFT_CENTER } from 'components/Tooltip';
import HeadquartersActions from 'store/headquarters/actions';
import HeadquarterHistoryModal from 'components/Headquarters/HistoryModal';

const ActionsWrapper = styled.div`
  ${() => css`
    .Button {
      padding: 8px 10px;
      display: flex;
      align-items: center;
      justify-content: center;

      a {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  `}
`;

const Name = ({ attributes }) => <b>{attributes.name}</b>;

const Classrooms = ({ attributes }) => (
  <span>{attributes.relationship_counter.classrooms}</span>
);

const Teachers = ({ attributes }) => (
  <span>{attributes.relationship_counter.teachers}</span>
);

const Students = ({ attributes }) => (
  <span>{attributes.relationship_counter.students}</span>
);

const Responsibles = ({ attributes }) => (
  <span>{attributes.relationship_counter.responsibles}</span>
);

const Actions = ({ attributes, id, meta }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [previousPage, setPreviousPage] = useState(1);

  const { can_update_headquarter: canUpdateHeadquarter } = meta;

  const dispatch = useDispatch();

  const { fetchHeadquartersHistory, fetchHeadquartersHistoryCount } =
    HeadquartersActions;

  const closeModal = () => setIsModalOpen(false);

  const handleFetchHeadquartersHistory = useCallback(
    (id, page) => {
      dispatch(fetchHeadquartersHistory(id, page));
    },
    [dispatch, fetchHeadquartersHistory]
  );

  const handleFetchHeadquartersHistoryCount = useCallback(
    (id) => {
      dispatch(fetchHeadquartersHistoryCount(id));
    },
    [dispatch, fetchHeadquartersHistoryCount]
  );

  const openModal = useCallback(() => {
    setIsModalOpen(true);
    handleFetchHeadquartersHistoryCount(id);
    handleFetchHeadquartersHistory(id, page);
  }, [
    handleFetchHeadquartersHistory,
    handleFetchHeadquartersHistoryCount,
    id,
    page,
  ]);

  const handlePageChange = useCallback(
    (page) => {
      setPage(page);
      setPreviousPage(page);
      page !== previousPage && handleFetchHeadquartersHistory(id, page);
    },
    [handleFetchHeadquartersHistory, id, previousPage]
  );

  return (
    <ActionsWrapper>
      <ButtonRow>
        {canUpdateHeadquarter && (
          <Tooltip content="Editar" position={LEFT_CENTER}>
            <Button testId="editButton" variation="secondary">
              <Link to={`headquarters/${id}/edit`}>
                <AgendaIcon name="edit" size="medium" />
              </Link>
            </Button>
          </Tooltip>
        )}

        <Tooltip content="Histórico" position={LEFT_CENTER}>
          <Button variation="secondary" onClick={openModal}>
            <AgendaIcon name="clock-cycle" size="medium" />
          </Button>
        </Tooltip>
      </ButtonRow>

      <HeadquarterHistoryModal
        headquarterId={attributes.id}
        headquarterName={attributes.name}
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        activePage={page}
        onPageChanged={handlePageChange}
      />
    </ActionsWrapper>
  );
};

export default [
  {
    Header: 'Nome da unidade',
    accessor: Name,
  },
  {
    Header: 'Turmas',
    accessor: Classrooms,
  },
  {
    Header: 'Professores',
    accessor: Teachers,
  },
  {
    Header: 'Alunos',
    accessor: Students,
  },
  {
    Header: 'Responsáveis',
    accessor: Responsibles,
  },
  {
    Header: 'Ações',
    accessor: Actions,
  },
];
