import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import parse from 'html-react-parser';
import * as S from './styles';

import { Box, Icon, Tag, Text } from '@agendaedu/ae-web-components';

import Tabs from 'components/Tabs';
import Tab from 'components/Tab';
import Avatar from 'components/Avatar';
import EmptyState from 'components/EmptyState';
import { DiaryDetailTabsSkeleton } from './skeleton';
import { History } from '../History';

import {
  DiaryDetailsStructure,
  DiaryState,
  StudentDiaryStructure,
} from 'store/dailySummaries/types';
import { IMAGES_CLASSROOMS_URL } from 'core/constants/index';
import formatBytes from 'core/utils/formatBytes';
import { useDownloadFile } from 'core/hooks/useDownloadFile';

const AttachmentItem = ({
  file,
}: {
  file: StudentDiaryStructure['occurrences']['data'][0]['attributes']['files'][0];
}) => {
  const { requestDownloadFileWithFetch } = useDownloadFile({
    getFileName: file.name,
    getFileURL: file.url,
  });

  return (
    <Box height="100%" mt="sm">
      {!file.type.includes('image') ? (
        <S.FileWrapper onClick={requestDownloadFileWithFetch}>
          <Icon name="attachment" />
          <Text variant="label-regular-14" ml="xs" mb={0} color="neutral.black">
            {file.name}
          </Text>
          <Text
            variant="label-regular-14"
            ml="auto"
            mb={0}
            color="neutral.black"
          >
            {formatBytes(file.size)}
          </Text>
        </S.FileWrapper>
      ) : (
        <S.OccurrenceCoverWrapper>
          <S.OccurrenceImage src={file.url} alt={file.name} loading="lazy" />
        </S.OccurrenceCoverWrapper>
      )}
    </Box>
  );
};

export const DiaryDetailsTab = () => {
  const { t } = useTranslation(['diary', 'common']);

  const {
    currentStudent,
    dailySummaryId,
    formMeta: { sectionSlugs },
    isLoading,
    isFetching,
  } = useSelector((state: DiaryState) => state.dailySummaries);

  const tBase = useCallback((key: string) => t(`details.${key}`), [t]);

  const findSectionLabel = (slug) => sectionSlugs[slug];

  const renderMeals = (meals) => {
    return meals?.map((mealData, index) => {
      const { category, description, notAte } = mealData;

      const categoryLabel =
        category && `${category}${notAte || description ? ':' : ''}`;

      return (
        <S.DailyEntryTextWrapper key={`meal-${index}`}>
          <Text variant="subtitle-medium-14" color="neutral.gray2" mb={0}>
            {t('details.summary.meal.label', { value: index + 1 })}
          </Text>
          {categoryLabel && (
            <Text variant="body-medium-16" color="neutral.gray1" mb={0}>
              <b>{categoryLabel}</b>
            </Text>
          )}
          {notAte && (
            <Text variant="body-medium-16" color="neutral.gray1" mb={0}>
              {tBase('summary.meal.not_ate')}
            </Text>
          )}
          {description && (
            <Text variant="label-medium-16" color="neutral.gray1" mb={0}>
              {description}
            </Text>
          )}
        </S.DailyEntryTextWrapper>
      );
    });
  };

  const renderEntryValue = (section, value) => (
    <S.DailyEntryTextWrapper>
      <Text variant="subtitle-medium-14" color="neutral.gray2" mb={0}>
        {section}
      </Text>
      <Text variant="body-medium-16" color="neutral.gray1" mb={0}>
        {value}
      </Text>
    </S.DailyEntryTextWrapper>
  );

  const renderMultipleEntryValues = (section, values) => {
    const ordinal = section === tBase('summary.sleep') ? 'º' : 'ª';

    return values.map((entry, index) => {
      const newSection = `${index + 1}${ordinal} ${section}`;
      return renderEntryValue(newSection, entry);
    });
  };

  const formatArrayValue = (sectionLabel, values) => {
    return values.length > 1
      ? renderMultipleEntryValues(sectionLabel, values)
      : renderEntryValue(sectionLabel, values[0]);
  };

  const parseAndRender = (sectionLabel, value) => {
    return renderEntryValue(sectionLabel, parse(value));
  };

  const getCurrentSummaryValues = (slug, value) => {
    const sectionLabel = findSectionLabel(slug);

    if (!value) {
      return undefined;
    }

    const formattedValue = Array.isArray(value)
      ? formatArrayValue(sectionLabel, value)
      : sectionLabel === tBase('summary.observation')
      ? parseAndRender(sectionLabel, value)
      : renderEntryValue(sectionLabel, value);

    return formattedValue;
  };

  const renderOccurrences = (occurrenceList) => {
    if (!occurrenceList.length) return;

    return occurrenceList.map((occurrence, index) => (
      <S.DailyEntryTextWrapper key={`occurrence-${index}`}>
        <Text variant="subtitle-medium-14" color="neutral.gray2" mb={0}>
          {t('details.summary.occurrence', { value: index + 1 })}
        </Text>
        <Text variant="body-medium-16" color="neutral.gray1" mb={0}>
          <b>{`${occurrence.category}:`}</b>
        </Text>
        <Text variant="body-medium-16" color="neutral.gray1" mb={0}>
          {occurrence.description}
        </Text>

        {!!occurrence.files.length && (
          <Box>
            <Text variant="body-medium-16" color="neutral.gray1" mt="xs2">
              {t('details.summary.attachments')}
            </Text>

            {occurrence.files.map((file) => (
              <AttachmentItem key={file.signed_id} file={file} />
            ))}
          </Box>
        )}
      </S.DailyEntryTextWrapper>
    ));
  };

  const renderCurrentDiarySummary = () => {
    const { date, created_at, ...currentSummary } = currentStudent?.summary;

    return Object.entries(currentSummary).map(([slug, value]) => {
      if (slug === 'meal') {
        return renderMeals(currentSummary.meal);
      } else if (slug !== 'occurrence') {
        return (
          <React.Fragment key={slug}>
            {getCurrentSummaryValues(slug, value)}
          </React.Fragment>
        );
      } else {
        return (
          <React.Fragment key={slug}>{renderOccurrences(value)}</React.Fragment>
        );
      }
    });
  };

  const renderCustomAnswers = () => {
    if (!currentStudent?.sections.data.length || !currentStudent?.included)
      return;

    const sectionsAnswersByGroups = currentStudent?.sections.data
      .filter((section) => section.attributes.custom)
      .reduce(
        (prevSectionsAnswers, currentSection) => {
          const sectionAnswers = currentStudent.included.filter(
            (item) =>
              item.type === 'diaryAnswer' &&
              String(item.attributes.question.sectionId) === currentSection.id
          );

          const answersByGroup = sectionAnswers.reduce(
            (prevGroups, currentAnswer) => {
              const group = prevGroups[currentAnswer.attributes.sectionGroupId];

              if (group) {
                group.push(currentAnswer);
              } else {
                prevGroups[currentAnswer.attributes.sectionGroupId] = [
                  currentAnswer,
                ];
              }
              return prevGroups;
            },
            {} as Record<
              string,
              DiaryDetailsStructure['currentStudent']['included']
            >
          );

          return [
            ...prevSectionsAnswers,
            {
              ...currentSection,
              answers: answersByGroup,
            },
          ];
        },
        [] as (DiaryDetailsStructure['section'] & {
          answers: DiaryDetailsStructure['currentStudent']['included'][];
        })[]
      );

    const renderSectionsAnswers = sectionsAnswersByGroups.map((section) =>
      Object.values(section.answers).map(
        (group: DiaryDetailsStructure['currentStudent']['included'], index) => (
          <Box key={`section-${section.id}-${index}`}>
            <Text variant="subtitle-medium-14" color="neutral.gray2">
              {index + 1}º {section.attributes.name}
            </Text>

            {group.map((answerInfo, index) => {
              const kindResponse = {
                text: () => (
                  <Box>
                    <Text variant="subtitle-medium-14" color="neutral.gray2">
                      {answerInfo.attributes?.question.title}
                    </Text>
                    <Text
                      variant="label-regular-16"
                      color="neutral.gray1"
                      mb={0}
                    >
                      {answerInfo.attributes?.answerText}
                    </Text>
                  </Box>
                ),
                select: () => (
                  <Box>
                    <Text variant="body-regular-16" fontWeight="bold">
                      {answerInfo.attributes?.question.title}:
                    </Text>
                    <Text variant="body-medium-16" color="neutral.gray1" mb={0}>
                      {answerInfo.attributes?.answerOption.title}
                    </Text>
                  </Box>
                ),
              }[answerInfo.attributes.question.kind];

              return (
                <Box key={`answer-${answerInfo.id}`} mt={index > 0 ? 'sm' : 0}>
                  {kindResponse()}
                </Box>
              );
            })}
          </Box>
        )
      )
    );

    return renderSectionsAnswers;
  };

  return (
    <S.DetailTabsWrapper>
      {isLoading || isFetching ? (
        <DiaryDetailTabsSkeleton />
      ) : (
        <>
          {dailySummaryId ? (
            <S.TabWrapper>
              <Tabs defaultIndex={0} animated={false}>
                <Tab title="Informações">
                  <S.DiaryContentWrapper>
                    <S.TitleWrapper>
                      <Avatar user={currentStudent} size="medium" />
                      <Text
                        variant="headline-h2-bold-24"
                        color="neutral.black"
                        mb={0}
                      >
                        {currentStudent?.attributes?.name}
                      </Text>
                    </S.TitleWrapper>

                    {currentStudent?.attributes?.last_updated && (
                      <Tag
                        variant="informative"
                        name={currentStudent?.attributes?.last_updated}
                      />
                    )}

                    <S.DetailsContainer>
                      {renderCurrentDiarySummary()}
                      {renderCustomAnswers()}
                    </S.DetailsContainer>

                    {currentStudent.versions.length > 0 && (
                      <History versions={currentStudent.versions} />
                    )}
                  </S.DiaryContentWrapper>
                </Tab>
              </Tabs>
            </S.TabWrapper>
          ) : (
            <S.EmptyStateWrapper>
              <EmptyState
                imgUrl={IMAGES_CLASSROOMS_URL.emptyStateUrl}
                message={tBase('empty_msg')}
              />
            </S.EmptyStateWrapper>
          )}
        </>
      )}
    </S.DetailTabsWrapper>
  );
};
