import createModuleReducer from '../storeConfig/createModuleReducer';
import actions from './actions';

export const INITIAL_STATE = {
  isSaved: false,
  isLoading: false,
  isSubmiting: false,
  isLoadinglistProductsHubstore: false,
  listProductsHubstore: [],
  listProductsHubstoreMeta: [],
  productHubstore: null,
  productMeta: null,
  productsHubstoreIncluded: null,
  formMeta: {
    canEditClassrooms: true,
    canEditKind: true,
    canEditConference: true,
    notificateWhenEditing: false,
    modalAnswered: false,
    openModal: false,
    classrooms: [],
    select_options: {},
    subscriptionsProduct: [],
  },
  students: [],
};

export const setLoadListProducts = (state) => ({
  ...state,
  isLoadinglistProductsHubstore: !state.isLoadinglistProductsHubstore,
});

export const fetchListProductsHubstoreSuccess = (state, action) => {
  const { data, meta } = action;

  return {
    ...state,
    listProductsHubstore: data?.length > 0 ? data : [],
    listProductsHubstoreMeta: meta,
    isLoadinglistProductsHubstore: !state.isLoadinglistProductsHubstore,
  };
};

export const updateItemPanelProductSuccess = (state, action) => {
  const { id, isActive } = action;

  let newMeta = state.listProductsHubstoreMeta;

  if (state.productMeta?.available_action) {
    return {
      ...state,
      productMeta: { available_action: isActive ? 'deactivate' : 'activate' },
    };
  }

  if (!newMeta?.active_products) {
    return {
      ...state,
      listProductsHubstoreMeta: isActive
        ? { active_products: [id] }
        : { active_products: [] },
    };
  }

  isActive
    ? newMeta.active_products.push(id)
    : newMeta.active_products.indexOf(id) !== -1 &&
      newMeta.active_products.splice(newMeta.active_products.indexOf(id), 1);

  return {
    ...state,
    productMeta: action.meta,
    listProductsHubstoreMeta: newMeta,
  };
};

export const setLoadProductHubstore = (state) => ({
  ...state,
  isLoading: true,
});

export const getProductHubstoreSuccess = (state, action) => ({
  ...state,
  isLoading: false,
  isSaved: false,
  productHubstore: action.data,
  productMeta: action.meta,
  productsHubstoreIncluded: action.included,
});

export const registerLoadAppLead = (state) => ({
  ...state,
  isSubmiting: true,
});

export const registerAppLeadSuccess = (state) => ({
  ...state,
  isSubmiting: false,
  isSaved: true,
});

export const handleError = (state, action) => ({
  ...state,
  isLoading: false,
  isLoadinglistProductsHubstore: false,
  error: action.error,
});

export const prepareSubscriptionsProduct = (state) => ({
  ...state,
  isLoading: true,
  formMeta: {
    ...state.formMeta,
    classrooms: [],
  },
});

export const getSubscriptionsInfoSuccess = (state, action) => {
  const { attributes } = action.data;
  const { product, classrooms } = attributes;

  classrooms.forEach((headquarter) =>
    headquarter.options.forEach((classroom) => {
      classroom.value = classroom.id;
      classroom.label = classroom.attributes.name;
    })
  );

  return {
    ...state,
    isLoading: false,
    formMeta: {
      ...state.formMeta,
      classrooms: classrooms,
      select_options: {
        classroom_id: classrooms,
      },
      subscriptionsProduct: product.data,
    },
  };
};

export const updatSending = (state) => ({
  ...state,
  isSending: false,
});

const HANDLERS = {
  // effects
  [actions.FETCH_LIST_PRODUCTS_HUBSTORE_SUCCESS]:
    fetchListProductsHubstoreSuccess,
  [actions.UPDATE_ITEM_PRODUCT_HUBSTORE_SUCCESS]: updateItemPanelProductSuccess,
  [actions.GET_PRODUCT_HUBSTORE_SUCCESS]: getProductHubstoreSuccess,
  [actions.REGISTER_APP_LEAD_SUCCESS]: registerAppLeadSuccess,
  [actions.GET_SUBSCRIPTIONS_INFO_REQUEST]: prepareSubscriptionsProduct,
  [actions.GET_SUBSCRIPTIONS_INFO_SUCCESS]: getSubscriptionsInfoSuccess,

  //loads
  [actions.FETCH_LIST_PRODUCTS_HUBSTORE]: setLoadListProducts,
  [actions.GET_PRODUCT_HUBSTORE]: setLoadProductHubstore,
  [actions.REGISTER_APP_LEAD]: registerLoadAppLead,
  [actions.SUBMIT_SUBSCRIPTION_SUCCESS]: updatSending,

  [actions.ERROR]: handleError,
};

const hubstore = createModuleReducer(INITIAL_STATE, HANDLERS);

export default hubstore;
