import React from 'react';
import { useTranslation } from 'react-i18next';

import withAppContext from 'core/hoc/withAppContext';

import {
  theme,
  DefaultThemeProps,
  Box,
  Text,
  FlexGrid,
  FlexGridItem,
  Tag,
  Icon,
} from '@agendaedu/ae-web-components';

import * as S from './styles';

import { constants, USER_ACTIONS } from 'pages/hubstore/constants';

import { DetailsHeaderProps } from './types';
import { trackEvent } from 'config/amplitude';

const TARGET_AUDIENCE = {
  StudentProfile: 'alunos',
  ResponsibleProfile: 'pais',
  SchoolUser: 'equipe escolar',
};

const DetailsHeaderInfo: React.FC<DetailsHeaderProps> = ({
  product,
  productMeta,
  onUpdateProduct,
  openModal,
  isActive,
  appContext: { primaryColor },
  history,
}) => {
  const { t } = useTranslation(['hub_store']);
  const defaultThemeProps: DefaultThemeProps = theme;
  const productId = history.location.pathname?.split('/')[4];

  const {
    colors: { neutral },
  } = defaultThemeProps;

  const {
    attributes: { app, title, description },
  } = product;

  const isFreeApp = product?.attributes.free;

  const canActivate = productMeta.available_action.includes(
    USER_ACTIONS.activate
  );

  const canDeactivate = productMeta.available_action.includes(
    USER_ACTIONS.deactivate
  );

  const canLead = productMeta.available_action.includes(USER_ACTIONS.lead);
  const canBuy = productMeta.available_action.includes(USER_ACTIONS.buy);
  const userWithoutAction = productMeta.available_action.includes(
    USER_ACTIONS.none
  );

  const targetAudience = app.data.attributes.target_audience.map(
    (target, index) => {
      return (
        <b key={target}>
          {TARGET_AUDIENCE[target]}
          {index == app.data.attributes.target_audience.length - 1 ? ' ' : ', '}
        </b>
      );
    }
  );

  const maskCurrency = (value) => {
    return value.toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      style: 'currency',
      currency: 'BRL',
    });
  };

  const checkCurrencyProduct = () => {
    if (product.attributes?.price_per_unit) {
      return t('currency_paid_app_message', {
        currency: maskCurrency(Number(product.attributes.price_per_unit)),
      });
    }

    return t('paid_app_message');
  };

  return (
    <Box display="flex" mt={32} position="relative">
      <FlexGrid>
        <FlexGridItem cols={{ desktopLG: 10 }} filled>
          <Box display="flex" flexDirection={{ _: 'column', desktopLG: 'row' }}>
            <Box
              mr={24}
              mb={{ _: 24, desktopLG: 0 }}
              height={146}
              width={146}
              minWidth={146}
              borderRadius={8}
              overflow="hidden"
            >
              <img
                src={app.data.attributes.logo}
                height="100%"
                width="100%"
                alt="Logo do app"
              />
            </Box>

            <Box>
              <Text
                as="h2"
                mt={0}
                mb={24}
                variant="headline-h2-bold-24"
                fontWeight={700}
                color={neutral.black}
              >
                {title}
              </Text>
              <Text
                as="p"
                mt={0}
                mb={16}
                variant="subtitle-medium-16"
                color={neutral.black}
                lineHeight="lg"
              >
                {description}
              </Text>

              <Box display="flex" alignItems="center" mb={16}>
                {app.data.attributes.partner_name === constants.NAME_TAG_AE ? (
                  <Box display="flex" alignItems="center" mr={24}>
                    <Box width={24} height={24}>
                      <Icon name="user-group" color={primaryColor} />
                    </Box>
                    <Text
                      as="p"
                      m={0}
                      ml={8}
                      variant="subtitle-medium-14"
                      color="brand.primary.dark"
                    >
                      {t('created_by')}{' '}
                      <b>{app.data.attributes.partner_name}</b>
                    </Text>
                  </Box>
                ) : (
                  <Box display="flex" alignItems="center" mr={24}>
                    <Box width={24} height={24}>
                      <Icon name="user-group" color={neutral.black} />
                    </Box>
                    <Text
                      as="p"
                      m={0}
                      ml={8}
                      variant="subtitle-medium-14"
                      color={neutral.black}
                    >
                      {t('created_by')}{' '}
                      <b>{app.data.attributes.partner_name}</b>
                    </Text>
                  </Box>
                )}

                <Box display="flex" alignItems="center">
                  <Box width={24} height={24}>
                    <Icon name="id-card" color={neutral.gray1} />
                  </Box>
                  <Text
                    as="p"
                    m={0}
                    ml={8}
                    variant="subtitle-medium-14"
                    color="neutral.gray1"
                  >
                    {t('target')} {targetAudience}
                  </Text>
                </Box>
              </Box>

              <Box display="flex" alignItems="center">
                {app.data.attributes.platforms.map((platform) => (
                  <Tag
                    key={platform}
                    name={platform}
                    variant="neutral"
                    mr={24}
                  />
                ))}
              </Box>
            </Box>
          </Box>
        </FlexGridItem>

        <FlexGridItem cols={{ desktopLG: 2 }} filled>
          <Box
            display="flex"
            position={{ _: 'absolute', desktopLG: 'relative' }}
            top={0}
            right={0}
          >
            <Box display="flex" flexDirection="column" ml="auto">
              {(canDeactivate || canActivate) && (
                <S.ButtonActions
                  variant={isActive ? 'secondary' : 'primary'}
                  onClick={() => {
                    onUpdateProduct();
                    if (!isActive) {
                      trackEvent('hubstore_activate_app', {
                        id: app.data.id,
                        name: title,
                      });
                    } else {
                      trackEvent('hubstore_deactivate_app', {
                        id: app.data.id,
                        name: title,
                      });
                    }
                  }}
                  disabled={isActive ? !canDeactivate : !canActivate}
                >
                  <span>
                    {isActive
                      ? t('deactivate_app_button')
                      : t('activate_app_button')}
                  </span>
                </S.ButtonActions>
              )}

              {canBuy && (
                <S.ButtonActions
                  variant="primary"
                  onClick={() => {
                    trackEvent('hubstore_buy_form', {
                      id: app.data.id,
                      name: title,
                    });
                    history.push({
                      pathname: `/schools/hub_store/products/${productId}/subscriptions/new`,
                      state: { contact_info: productMeta.contact_info },
                    });
                  }}
                  disabled={!canBuy}
                >
                  {t('buy_app_button')}
                </S.ButtonActions>
              )}

              {canLead && (
                <S.ButtonActions
                  variant="primary"
                  onClick={() => {
                    trackEvent('hubstore_lead_form', {
                      id: app.data.id,
                      name: title,
                    });
                    openModal();
                  }}
                  disabled={!canLead}
                >
                  {t('free_test_button')}
                </S.ButtonActions>
              )}

              {userWithoutAction && (
                <S.ButtonActions variant="primary" disabled={true}>
                  {t('acquired_app_button')}
                </S.ButtonActions>
              )}

              <Text
                as="p"
                mt={27}
                variant="title-bold-20"
                textAlign="center"
                fontWeight={700}
                color={isFreeApp ? 'context.success.default' : 'neutral.gray1'}
              >
                <span>
                  {isFreeApp ? t('free_app_message') : checkCurrencyProduct()}
                </span>
              </Text>
            </Box>
          </Box>
        </FlexGridItem>
      </FlexGrid>
    </Box>
  );
};

export default withAppContext(DetailsHeaderInfo);
