const actions = {
  ERROR: 'ERROR/hubstore',

  FETCH_LIST_PRODUCTS_HUBSTORE: 'FETCH_LIST_PRODUCTS_HUBSTORE/hubstore',
  FETCH_LIST_PRODUCTS_HUBSTORE_SUCCESS:
    'FETCH_LIST_PRODUCTS_HUBSTORE_SUCCESS/hubstore',

  GET_PRODUCT_HUBSTORE: 'GET_PRODUCT_HUBSTORE/hubstore',
  GET_PRODUCT_HUBSTORE_SUCCESS: 'GET_PRODUCT_HUBSTORE_SUCCESS/hubstore',

  UPDATE_ITEM_PRODUCT_HUBSTORE: 'UPDATE_ITEM_PRODUCT_HUBSTORE/hubstore',
  UPDATE_ITEM_PRODUCT_HUBSTORE_SUCCESS:
    'UPDATE_ITEM_PRODUCT_HUBSTORE_SUCCESS/hubstore',

  REGISTER_APP_LEAD: 'REGISTER_APP_LEAD/hubstore',
  REGISTER_APP_LEAD_SUCCESS: 'REGISTER_APP_LEAD_SUCCESS/hubstore',

  GET_SUBSCRIPTIONS_INFO_REQUEST: 'GET_SUBSCRIPTIONS_INFO_REQUEST/hubstore',
  GET_SUBSCRIPTIONS_INFO_SUCCESS: 'GET_SUBSCRIPTIONS_INFO_SUCCESS/hubstore',

  SUBMIT_SUBSCRIPTION_REQUEST: 'SUBMIT_SUBSCRIPTION_REQUEST/hubstore',
  SUBMIT_SUBSCRIPTION_SUCCESS: 'SUBMIT_SUBSCRIPTION_SUCCESS/hubstore',

  fetchListProductsHubstore: () => ({
    type: actions.FETCH_LIST_PRODUCTS_HUBSTORE,
  }),

  getProducHubstore: (id) => ({
    type: actions.GET_PRODUCT_HUBSTORE,
    id,
  }),

  updateProductHubstore: (id, isActive) => ({
    type: actions.UPDATE_ITEM_PRODUCT_HUBSTORE,
    id,
    isActive,
  }),

  registerAppLead: (params) => ({
    type: actions.REGISTER_APP_LEAD,
    params,
  }),

  getSubscriptionsInfo: (productId) => ({
    type: actions.GET_SUBSCRIPTIONS_INFO_REQUEST,
    productId,
  }),

  submitSubscriptions: (params) => ({
    type: actions.SUBMIT_SUBSCRIPTION_REQUEST,
    params,
  }),
};

export default actions;
