import { select, call, put, all, takeLatest } from 'redux-saga/effects';
import { fetchApi } from 'core/utils/api';

import actions from './actions';

export function* fetchMultimediaConsumption() {
  try {
    const dataArea = yield select((state) => state.root.dataArea);
    const { data } = yield call(
      fetchApi,
      `/${dataArea}/multimedia_consumption`
    );
    yield put({
      type: actions.FETCH_MULTIMEDIA_CONSUMPTION_SUCCESS,
      data,
    });
  } catch (error) {
    yield put({ type: actions.ERROR, error });
  }
}

export function* fetchSchoolBills() {
  try {
    const dataArea = yield select((state) => state.root.dataArea);
    const { data } = yield call(fetchApi, `/${dataArea}/school_bills`);
    yield put({
      type: actions.FETCH_SCHOOL_BILLS_SUCCESS,
      data,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
    });
  }
}

function* myAccountSagas() {
  yield all([takeLatest(actions.FETCH_SCHOOL_BILLS_REQUEST, fetchSchoolBills)]);
  yield all([
    takeLatest(
      actions.FETCH_MULTIMEDIA_CONSUMPTION_REQUEST,
      fetchMultimediaConsumption,
    ),
  ]);
}

export default myAccountSagas;
