import styled, { css } from 'styled-components';
import { ThemeProps } from 'config/themes/types';

export const GrayBox = styled.div`
  ${({ theme: { colors, border, space } }: ThemeProps) => css`
    width: 100%;
    border-radius: ${border.radius.md};
    border: ${border.width.sm} solid ${colors.neutral.gray4};
  `}
`;

export const GrayLine = styled.div`
  ${({ theme: { colors, border, space } }: ThemeProps) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: ${border.width.sm} solid ${colors.neutral.gray4};
    margin: ${space.lg} 0px ${space.lg} 0px;
  `}
`;

export const TextContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const WrapperContainer = styled.div`
  ${({ theme: { space, breakpoints } }: ThemeProps) => css`
    @media screen and (min-width: ${breakpoints.tabletMD}) {
      width: 607px;
      margin-left: auto;
      margin-right: auto;
    }

    @media screen and (max-width: ${breakpoints.tabletMD}) {
      width: auto;
      margin-left: auto;
      margin-right: auto;
      padding-left: ${space.xs2};
      padding-right: ${space.xs2};
    }

    .row {
      margin-right: 0px !important;
      margin-left: 0px !important;

      padding: 0 15px;
    }

    .GroupSelector {
      margin-right: auto;
      width: auto;
    }
  `}
`;
