import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
import { Alert, Icon, Text, TextField } from '@agendaedu/ae-web-components';
import { OmniChannelState } from 'store/messages/omniChannel/types';

import { attendingHoursDays, attendingHoursDaysWeek } from 'core/constants';
import tabifyForWizard from 'core/hoc/tabifyForWizard';
import { flags } from 'core/constants/flags';
import launchDarkly from 'core/helper/launchDarkly';
import { validateFormErrors } from 'core/helper/handouts';
import withAppContext from 'core/hoc/withAppContext';
import withFormContext from 'core/hoc/withFormContext';

import AttendingHoursAccordion from 'components/Messages/OmniChannel/Forms/MessageChannel/AttendingHoursAccordion';
import Checkbox from 'components/Form/Checkbox';
import ToggleSwitch from 'components/Form/ToggleSwitch';
import { SelectAvatarField } from 'components/Messages/SelectAvatarField';
import { PickerOpeningHoursModal } from 'components/Messages/OmniChannel/Modals';
import { PickerDayAndHours } from 'components/Messages/OmniChannel/Modals/PickerOpeningHoursModal/types';

import validations from './validations';

import * as S from './styles';

import { GeneralInformationStepProps } from './types';

const GeneralInformationStep = ({
  formContext: {
    action,
    form: { name, icon, description, kind, attending_hours: attendingHours },
    updateAttribute,
    hasErrorOnAttribute,
    getFormValidationResult,
    changeMeta,
  },
}: GeneralInformationStepProps) => {
  const { t } = useTranslation(['messages']);
  const [cookies] = useCookies(['agendaedu_launch_darkly']);

  const activeFlags = cookies.agendaedu_launch_darkly?.active_flags;

  const hasLDAttendantTimeFlag = launchDarkly.checkFlagActive(
    activeFlags,
    flags.ATTENDANT_TIME
  );

  const { avatarIconsList } = useSelector(
    (state: OmniChannelState) => state.omniChannel
  );

  const isGroupFamily = kind === 'family';
  const isTicketChannel = kind === 'ticket';

  const hoursModalValues = {
    sunday: attendingHours?.days[0].active,
    monday: attendingHours?.days[1].active,
    tuesday: attendingHours?.days[2].active,
    wednesday: attendingHours?.days[3].active,
    thursday: attendingHours?.days[4].active,
    friday: attendingHours?.days[5].active,
    saturday: attendingHours?.days[6].active,
  };

  const handleChangeAttribute = (
    attributeName: string,
    value: string | number | boolean
  ) => updateAttribute(attributeName, value);

  const handleGetErrors = (name: string) =>
    validateFormErrors(name, getFormValidationResult, hasErrorOnAttribute);

  const handleChangeAttendantToggle = useCallback(() => {
    const newAttendingHoursDays = JSON.parse(
      JSON.stringify(attendingHoursDays)
    );
    updateAttribute(
      'attending_hours',
      !attendingHours ? newAttendingHoursDays : null
    );
  }, [attendingHours, updateAttribute]);

  const handleChangeOnlyNotifications = useCallback(() => {
    updateAttribute('attending_hours', {
      ...attendingHours,
      blockOnlyNotification: !attendingHours.blockOnlyNotification,
    });
  }, [attendingHours, updateAttribute]);

  const handleSubmitOpeningHoursModal = useCallback(
    (modalHours: PickerDayAndHours) => {
      const days = attendingHours.days;

      for (const key in days) {
        const dayWeek = attendingHoursDaysWeek[key];
        const isActive = modalHours.daysOfTheWeek[dayWeek];

        days[key].active = isActive;
        days[key].hours = isActive
          ? [`${modalHours.openingHour}`, `${modalHours.closeHour}`]
          : ['', ''];
      }

      updateAttribute('attending_hours', {
        ...attendingHours,
        days,
      });
    },
    [attendingHours, updateAttribute]
  );

  useEffect(() => {
    changeMeta('nextStepDisabled', false);
  }, [changeMeta]);

  return (
    <>
      <S.GeneralInformationStepWrapper>
        <S.RowWrapper>
          <S.StepTitle>
            {t('omni_channel.forms.message_channel.steps.first')}
          </S.StepTitle>

          <S.FieldRow>
            <SelectAvatarField
              icons={avatarIconsList}
              selectedIconName={icon}
              onChange={(icon) => handleChangeAttribute('icon', icon)}
              label={t(
                'omni_channel.forms.message_channel.fields.avatar_select_label_name'
              )}
            />
            <TextField
              date-testid="field-name"
              label={t('omni_channel.forms.message_channel.fields.title_name')}
              placeholder={t(
                'omni_channel.forms.message_channel.fields.title_placeholder'
              )}
              value={name}
              onChange={({ target: { value } }) => {
                handleChangeAttribute('name', value);
              }}
              fullWidth
              error={!name.length && handleGetErrors('name').hasError}
              errorMessage={t(
                `omni_channel.forms.${handleGetErrors('name').errorMessages}`
              )}
            />
          </S.FieldRow>

          <S.TextArea
            multiline
            fullWidth
            label={t(
              'omni_channel.forms.message_channel.fields.description_name'
            )}
            value={description}
            onChange={({ target: { value } }) =>
              handleChangeAttribute('description', value)
            }
            error={
              !description.length && handleGetErrors('description').hasError
            }
            errorMessage={t(
              `omni_channel.forms.${
                handleGetErrors('description').errorMessages
              }`
            )}
          />

          {!isTicketChannel && (
            <>
              <S.ConfigurationWrapper>
                {t(
                  'omni_channel.forms.message_channel.fields.group_family_name'
                )}

                <S.GroupCheckWrapper>
                  <Checkbox
                    testId={`check-group-channel-${isGroupFamily}`}
                    checked={isGroupFamily}
                    disabled={action === 'edit'}
                    onChange={() =>
                      handleChangeAttribute(
                        'kind',
                        isGroupFamily ? 'private' : 'family'
                      )
                    }
                  />

                  <S.GroupCheckText>
                    {t(
                      'omni_channel.forms.message_channel.fields.group_family_description'
                    )}
                  </S.GroupCheckText>
                </S.GroupCheckWrapper>
              </S.ConfigurationWrapper>
            </>
          )}
          {hasLDAttendantTimeFlag && (
            <>
              <S.ConfigurationWrapper>
                {t('omni_channel.forms.attending_hours.toggle_title_text')}

                <Alert variant="informative">
                  <S.AlertInformationWrapper>
                    <Icon name="warning" />
                    {t('omni_channel.forms.attending_hours.alert_title_text')}
                  </S.AlertInformationWrapper>
                </Alert>

                <S.ToggleWrapper>
                  <ToggleSwitch
                    testId="toggle-attendant-time"
                    toggled={!!attendingHours}
                    onChange={handleChangeAttendantToggle}
                  />
                  {t(
                    'omni_channel.forms.attending_hours.toggle_description_text'
                  )}
                </S.ToggleWrapper>
              </S.ConfigurationWrapper>

              {!!attendingHours && (
                <>
                  <S.OnlyNotificationsWrapper>
                    <Text color={'neutral.gray1'} variant="body-regular-16">
                      {t(
                        'omni_channel.forms.attending_hours.only_notifications_alert_title'
                      )}
                    </Text>

                    <S.OnlyNotificationsCheckWrapper>
                      <Checkbox
                        testId={`checkbox-only-notifications`}
                        checked={!!attendingHours?.blockOnlyNotification}
                        onChange={handleChangeOnlyNotifications}
                      />

                      <Text
                        lineHeight="lg"
                        color={'neutral.gray2'}
                        variant="body-regular-14"
                      >
                        {t(
                          'omni_channel.forms.attending_hours.only_notifications_alert_description'
                        )}
                      </Text>
                    </S.OnlyNotificationsCheckWrapper>
                  </S.OnlyNotificationsWrapper>

                  <AttendingHoursAccordion />
                </>
              )}
            </>
          )}
        </S.RowWrapper>
      </S.GeneralInformationStepWrapper>

      {hasLDAttendantTimeFlag && (
        <PickerOpeningHoursModal
          handleSubmit={handleSubmitOpeningHoursModal}
          initialDays={hoursModalValues}
        />
      )}
    </>
  );
};

export default tabifyForWizard({
  title: '1 Informações',
  validations,
})(withAppContext(withFormContext(GeneralInformationStep)));
