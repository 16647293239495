import React from 'react';

import AgendaIcon from 'components/AgendaIcon';

import './style.scss';

const InfoCard = ({ icon, label, value }) => {
  return (
    <div className="info-card">
      <div className="icon-area">
        <AgendaIcon name={icon} size="x-medium" className="icon" />
      </div>
      <div className="info-area">
        <p className="survey-info-label">{label}</p>
        <p className="information">{value}</p>
      </div>
    </div>
  );
};

export default InfoCard;
