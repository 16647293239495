import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as S from './styles';

import { Button, Text } from '@agendaedu/ae-web-components';
import { UnreadChatCard } from 'components/Messages/OmniChannel/Modals/UnreadChatModal/card';

import reduxProvider from 'core/hoc/reduxProvider';
import withAppContext from 'core/hoc/withAppContext';

import { OmniChannelState, UnreadChat } from 'store/messages/omniChannel/types';
import omniChannelActions from 'store/messages/omniChannel/actions';

import { UnreadChatModalProps } from './types';

const UnreadChatModal = ({
  appContext: { dataArea },
}: UnreadChatModalProps): JSX.Element => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['messages']);

  const modalRef = useRef<HTMLDivElement>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { unreadCount } = useSelector(
    (state: OmniChannelState) => state.omniChannel
  );

  const { fetchUnreadChatsrequest } = omniChannelActions;

  const handleOpenModal = () => setIsModalOpen(true);

  const handleCloseModal = () => setIsModalOpen(false);

  const handleRedirectOnClickMessages = useCallback(() => {
    window.location.href = `/${dataArea}/messages/`;
    handleCloseModal();
  }, [dataArea]);

  useEffect(() => {
    const handleOutsideClick = ({ target }: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(target as Node)) {
        handleCloseModal();
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    dispatch(fetchUnreadChatsrequest());
  }, []);

  return (
    <S.MessageCountWrapper>
      <Button
        className="modal-button"
        isOnlyIcon
        icon="message-heart"
        variant="secondary"
        badges={unreadCount.total}
        onClick={handleOpenModal}
      />
      {isModalOpen && (
        <S.UnreadModal ref={modalRef}>
          <S.InformationWrapper>
            <Text variant="title-bold-20" color="neutral.black" mb={0}>
              {t('omni_channel.modals.count_notification.title')}
            </Text>
            <Text variant="body-regular-14" color="neutral.gray2" mb={0}>
              {t('omni_channel.modals.count_notification.count', {
                count: unreadCount.total,
              })}
            </Text>
          </S.InformationWrapper>

          <S.ListMessagesWrapper>
            {unreadCount.chats.map((unreadChatCard: UnreadChat) => (
              <UnreadChatCard
                key={unreadChatCard.chatId}
                dataArea={dataArea}
                unreadChatCard={unreadChatCard}
                handleCloseModal={handleCloseModal}
              />
            ))}
          </S.ListMessagesWrapper>

          <Button onClick={handleRedirectOnClickMessages}>
            {t('omni_channel.modals.count_notification.all_notification')}
          </Button>
        </S.UnreadModal>
      )}
    </S.MessageCountWrapper>
  );
};

export default reduxProvider(withAppContext(UnreadChatModal));
