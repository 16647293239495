import styled, { css } from 'styled-components';

export const ChartContainer = styled.div`
  .Button {
    display: block;
    width: fit-content;
    margin: 24px auto 0 auto;
    font-family: 'Quicksand', sans-serif;
    font-weight: 700;
    font-size: 14px;

    @media (max-width: 834px) {
      width: 100%;
      font-size: 12px;
    }
  }
`;

export const ChartFrame = styled.iframe`
  width: 100%;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.14);
  border-radius: 8px;
`;

export const Title = styled.h3`
  margin-bottom: 24px;
  font-size: 18px;
  font-family: 'Quicksand', sans-serif;
  font-weight: 500;
  ${({ theme }) => css`
    color: ${theme.black};
  `}

  @media (max-width: 834px) {
    margin-bottom: 14px;
  }
`;
