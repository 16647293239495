/**
  This function is based on the redux-sauce(https://github.com/jkeam/reduxsauce) library

  Creates a module reducer.
  @param {any} initialState - The initial state for this reducer.
  @param {object} handlers - Keys are action types (strings), values are reducers (functions).
  @return {object} A reducer object.

  1 - Create a initialState

  const INITIAL_STATE = { name: null, age: null }

  2 - Create a handlers actions

  const HANDLERS = {
    [Types.SAY_HELLO]: sayHello,
    [Types.SAY_GOODBYE]: sayGoodbye
  }

  3 - Export your reducer

  export defualt createModuleReducer(INITIAL_STATE, HANDLERS)

  Only that!

  With that we don't need to be doing large switch statement and noise in our reducers
*/
const createModuleReducer = (initialState, handleActions) => {
  if (typeof initialState === 'undefined') {
    throw new Error('initial state is required');
  }

  if (Object.prototype.toString.call(handleActions) !== '[object Object]') {
    throw new Error('createModuleReducer handlers must be an object');
  }

  return (state = initialState, action) =>
    handleActions.hasOwnProperty(action.type)
      ? handleActions[action.type](state, action)
      : state;
};

export default createModuleReducer;
