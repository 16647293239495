import styled, { css } from 'styled-components';

export const RadioContainer = styled.div`
  margin-right: 15px;
  display: inline-flex;
  ${({ vertical }) => css`
    flex-flow: ${vertical ? 'column' : 'row wrap'};
  `};
  gap: 12px 32px;
`;

export const StyledRadioButton = styled.input`
  position: absolute;
  opacity: 0;
  visibility: hidden;
  ${({ theme, disabled }) => css`
    &:checked {
      background-color: ${disabled ? theme.gray4 : theme.primaryColor};
      border-color: ${disabled ? theme.gray4 : theme.primaryColor};
    }

    &:checked + ${StyledRadioLabel} {
      &::before {
        padding: 2px;
        background-clip: content-box;
        background-color: ${disabled ? theme.gray1 : theme.primaryColor};
        border-color: ${disabled ? theme.gray12 : theme.primaryColor};
      }
    }

    + ${StyledRadioLabel} {
      cursor: ${disabled ? 'not-allowed' : 'pointer'};
      &::before {
        border-color: ${disabled ? theme.gray12 : theme.gray4};
        color: ${disabled ? theme.gray12 : theme.gray4};
      }

      &:hover {
        &::before {
          border-color: ${disabled ? theme.gray12 : theme.primaryColor};
          color: ${disabled ? theme.gray12 : theme.primaryColor};
        }
      }
    }
  `};
`;

export const StyledRadioLabel = styled.label`
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  font-size: 16px;
  font-family: Roboto;
  font-weight: normal;
  width: max-content;

  ${({ theme }) => css`
    color: ${theme.black};
    &::before {
      display: inline-block;
      content: '';
      height: 18px;
      width: 18px;
      color: ${theme.gray1};
      border: 2px solid ${theme.gray1};
      border-radius: 50%;
      margin-right: 12px;
    }
  `};
`;
