/*eslint no-useless-catch: "off"*/

import axios from 'axios';
import { extractBody, extractHeaders } from 'core/utils/request';
import { arrayToObject } from 'core/utils/arrayToObject';

export default class OtherUsersMessagesService {
  /**
   * @param {string} dataArea One of the user's data area. Eg.: schools.
   */
  constructor(dataArea) {
    this.dataArea = dataArea;
  }

  async fetchChannels() {
    const endpoint = `/${this.dataArea}/messages/channels.json`;

    try {
      const response = await axios.get(endpoint);

      const {
        data: channels,
        included: userChats,
        meta: { private_count: privateCount, family_count: familyCount },
      } = extractBody(response);

      return {
        channels,
        privateCount,
        familyCount,
        userChats,
      };
    } catch (error) {
      throw error;
    }
  }

  /**
   * @api public
   * @param {String, Number} channelId
   * @param {String, Number} page
   */
  async fetchMessages({ channelId, kind, page }) {
    const endpoint = `/${this.dataArea}/messages/channels/${channelId}/messages/${kind}.json`;
    const options = { params: { page } };

    try {
      const response = await axios.get(endpoint, options);

      const {
        data,
        meta: {
          channel,
          chat_id: chatId,
          can_send_message: canSendMessage,
          inactive_chat_message: inactiveChatMessage,
        },
        included,
      } = extractBody(response);

      const { totalItemsCount } = extractHeaders(response);

      const attach = arrayToObject(included);

      const messages = data.map((msg) => {
        return {
          ...msg,
          relationships: {
            attachedMessage: {
              data: attach[msg.relationships.attachedMessage?.data?.id] || null,
            },
          },
        };
      });

      return {
        messages,
        channel,
        chatId,
        totalItemsCount,
        canSendMessage,
        inactiveChatMessage,
      };
    } catch (error) {
      throw error;
    }
  }

  /**
   * @param {String} message
   * @param {AttachmentEntity} attachment
   * @param {Integer} channelId
   * @param {String} kind
   * @param {Function} onUploadProgress
   */
  async createMessage({
    message,
    attachment,
    channelId,
    kind,
    onUploadProgress,
  }) {
    const endpoint = `/${this.dataArea}/messages/channels/${channelId}/messages/${kind}.json`;

    let data = new FormData();
    let config;

    data.append('message[message]', message.trim() || '');

    if (attachment) {
      data.append(
        'message[document]',
        attachment.getUploadedFile(),
        attachment.getName()
      );

      const { CancelToken } = axios;
      const source = CancelToken.source();
      const token = source.token;

      config = {
        cancelToken: token,
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          const percentCompleted = Math.round((loaded * 100) / total);
          onUploadProgress && onUploadProgress(percentCompleted, source);
        },
      };
    }

    try {
      const response = await axios.post(endpoint, data, config);
      const { data: message } = extractBody(response);
      return message;
    } catch (error) {
      throw error;
    }
  }

  async getMessage({ channelId, messageId }) {
    const endpoint = `/${this.dataArea}/messages/channels/${channelId}/messages/${messageId}`;

    try {
      const response = await axios.get(endpoint);

      const { data: message, included } = extractBody(response);

      return {
        data: {
          ...message,
          relationships: {
            attachedMessage: {
              data: included[0] || null,
            },
          },
        },
      };
    } catch (error) {
      throw error;
    }
  }

  async fetchAnchorMessage({ channelId, rangeIdStart, rangeIdEnd, kind }) {
    const endpoint = `/${this.dataArea}/messages/channels/${channelId}/messages/${kind}?range_of_ids[start]=${rangeIdStart}&range_of_ids[end]=${rangeIdEnd}`;

    try {
      const response = await axios.get(endpoint);

      const {
        data,
        meta: { chat, main_user: chatMainUser },
        included,
      } = extractBody(response);

      const { totalItemsCount } = extractHeaders(response);

      const attach = arrayToObject(included);

      const messages = data.map((msg) => {
        return {
          ...msg,
          relationships: {
            attachedMessage: {
              data: attach[msg.relationships.attachedMessage?.data?.id] || null,
            },
          },
        };
      });

      return { messages, chat, chatMainUser, totalItemsCount };
    } catch (error) {
      throw error;
    }
  }
}
