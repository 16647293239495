import React, { useContext, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import Field from 'components/Form/Field';
import Button from 'components/Button';
import AgendaIcon from 'components/AgendaIcon';

import SectionCard from 'pages/dailySummaries/DailySummaryForm/StudentSections/Card';

import withFormContext from 'core/hoc/withFormContext';
import DailySummariesContext from 'core/contexts/DailySummaries';

import * as S from './styles';

const OccurrenceCard = ({
  occurrence,
  addOccurrence,
  index,
  removeOccurrence,
}) => {
  const textAreaRef = useRef(null);

  const {
    formMeta: { occurrencesList: categoriesList },
  } = useSelector((state) => state.dailySummaries);

  const { canShowField } = useContext(DailySummariesContext);

  const {
    form: { occurrences },
    handleForm,
    isDisabled,
    hasStudentSelected,
  } = useContext(DailySummariesContext);

  const disableCard = !hasStudentSelected || isDisabled;

  const handleOccurrenceChange = (value) => {
    occurrences[index] = {
      ...occurrences[index],
      ...value,
    };
    handleForm('occurrences', occurrences);
  };

  useEffect(() => {
    if (canShowField('Ocorrência') && textAreaRef.current) {
      textAreaRef.current.style.height = '0';
      const scrollHeight = textAreaRef.current.scrollHeight;
      textAreaRef.current.style.height = scrollHeight + 'px';
    }
  }, [textAreaRef, occurrence?.description, canShowField]);

  return (
    <S.MultipleOccurrenceContainer>
      <SectionCard title="Ocorrência">
        <S.Container>
          <Field
            fullWidth
            type="selectWithIcon"
            disabled={disableCard}
            withArrowSelect
            placeholder="Selecionar"
            options={categoriesList}
            label="Categoria"
            value={occurrence?.category}
            onChange={(e) => handleOccurrenceChange({ category: e.value })}
          />
          <S.TextAreaContainer className="Field vertical col-md-12 col-xs-12">
            <label htmlFor="description-field">Descrição</label>
            <textarea
              placeholder="Ex.: Bom comportamento"
              rows="1"
              ref={textAreaRef}
              disabled={disableCard || !occurrence?.category}
              className="SimpleTextArea form-control "
              id="description-field"
              value={occurrence?.description}
              onChange={(e) =>
                handleOccurrenceChange({ description: e.target.value })
              }
            />
          </S.TextAreaContainer>
        </S.Container>
      </SectionCard>
      {canShowField('Ocorrência') && (
        <S.OccurrenceActionsContainer>
          {occurrences.length - 1 === index && (
            <Button
              variation="secondary"
              icon="plus"
              onClick={addOccurrence}
              id="add-button-occurrence"
            />
          )}
          <button
            variation="secondary"
            onClick={() => removeOccurrence(occurrence.id)}
            aria-label="Remover ocorrência"
            id="remove-button-occurrence"
          >
            <AgendaIcon name="trash" size="small" />
          </button>
        </S.OccurrenceActionsContainer>
      )}
    </S.MultipleOccurrenceContainer>
  );
};

OccurrenceCard.propTypes = {
  occurrence: PropTypes.shape({
    id: PropTypes.string,
    category: PropTypes.string,
    description: PropTypes.string,
  }),
  addOccurrence: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  removeOccurrence: PropTypes.func.isRequired,
};

export default withFormContext(OccurrenceCard);
