import { Box, DefaultThemeProps, theme } from '@agendaedu/ae-web-components';
import React, { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { StorageRoot } from 'store/storage/types';

import Skeleton from 'components/Skeleton';

import * as S from './styles';

import { Props } from './types';

const FolderPath: React.FC<Props> = ({ title }): React.ReactElement => {
  const defaultTheme: DefaultThemeProps = theme;

  const {
    colors,
    border: { radius },
  } = defaultTheme;

  const dataArea = useSelector(
    (state: { root: { dataArea: string } }) => state.root.dataArea
  );

  const {
    storageForm: { current },
    common: { folderPath },
  } = useSelector((state: StorageRoot) => state.storage);

  const getFolderLink = useCallback(
    (id): string => {
      if (!id) return `/${dataArea}/artifacts`;

      return `/${dataArea}/artifacts/folders/${id}`;
    },
    [dataArea]
  );

  const path = useMemo(() => {
    if (!folderPath || !current) return;

    const folders = [];

    folderPath.ids.map((id, index) =>
      folders.push({
        title: folderPath.titles[index],
        link: getFolderLink(id),
      })
    );

    folders.push({
      title: current.attributes.title,
      link: getFolderLink(current.id),
    });

    return folders;
  }, [current, folderPath, getFolderLink]);

  return (
    <S.AlertWrapper variant="informative">
      <Box display="flex" flexDirection="row">
        {!path || path?.length === 0 ? (
          <Skeleton
            data-testid="skeleton"
            width={240}
            height={20}
            borderRadius={radius.md}
            primaryColor={colors.context.info.op20}
            secondaryColor={colors.context.info.op10}
          />
        ) : (
          <Box display="flex" flexWrap="wrap" flexDirection="row" gap="xs3">
            {path?.map((item, index) => {
              const isLastItem = index + 1 === path.length;
              const isFirstItem = index === 0;

              return (
                <React.Fragment key={item.link}>
                  {isFirstItem && title}

                  <Link to={item.link}>
                    <Box display="flex" flexDirection="row">
                      <S.LinkTitle
                        mb={0}
                        color="context.info.default"
                        variant="subtitle-medium-14"
                      >
                        {item.title}
                      </S.LinkTitle>
                    </Box>
                  </Link>

                  {!isLastItem && <Box color="context.info.default">&gt;</Box>}

                  {isLastItem && <Box color="context.info.default">.</Box>}
                </React.Fragment>
              );
            })}
          </Box>
        )}
      </Box>
    </S.AlertWrapper>
  );
};

export default FolderPath;
