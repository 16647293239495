import React, { useEffect } from 'react';
import { CookiesProvider } from 'react-cookie';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import InternalErrorBoundary from 'boundaries/InternalErrorBoundary';
import reduxProvider from 'core/hoc/reduxProvider';

import EditDynamicModules from 'screens/EditDynamicModules';

const EditDynamicModulesRoutes = () => {
  const changeWrapperBackgroundColor = () => {
    document.getElementById('main-content').style.backgroundColor = '#ffffff';
  };

  useEffect(() => {
    changeWrapperBackgroundColor();
  }, []);

  return (
    <InternalErrorBoundary>
      <div className="EditDynamicModulesScreen">
        <CookiesProvider>
          <BrowserRouter>
            <Switch>
              <Route
                path="/*/edit_feature_modules/"
                // path="/*/dynamic_modules/"
                component={(props) => <EditDynamicModules {...props} />}
              />
            </Switch>
          </BrowserRouter>
        </CookiesProvider>
      </div>
    </InternalErrorBoundary>
  );
};

EditDynamicModulesRoutes.propTypes = {};

export default reduxProvider(EditDynamicModulesRoutes);
