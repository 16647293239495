import styled, { css } from 'styled-components';

import { ThemeProps } from 'config/themes/types';
import { ChannelItemWrapperStyleProps } from './types';

export const Wrapper = styled.section`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    flex-direction: column;
    gap: ${space.md};
  `}
`;

export const ChannelWrapper = styled.div`
  ${({ theme: { colors, border } }: ThemeProps) => css`
    display: flex;
    width: 100%;
    align-items: center;
    padding: 8px 0px;
    border-bottom: ${border.style.solid} ${border.width.sm}
      ${colors.brand.primary.op10};
  `}
`;

export const ChannelsListWrapper = styled.div`
  ${({ theme: { space, colors, border } }: ThemeProps) => css`
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    max-height: 200px;
    &::-webkit-scrollbar {
      background-color: transparent;
      width: ${space.xs2};
    }

    &::-webkit-scrollbar-button {
      display: none;
    }

    &::-webkit-scrollbar-track {
      background-color: ${colors.neutral.gray5};
    }

    &::-webkit-scrollbar-thumb {
      border-radius: ${border.radius.sm};
      border: ${border.width.sm} ${border.style.solid} ${colors.neutral.gray4};
    }
    display: flex;
  `}
`;

export const ChannelItemWrapper = styled.div<ChannelItemWrapperStyleProps>`
  ${({ isSelected, theme: { space, colors, border } }) => css`
    display: flex;
    width: 100%;
    border-radius: ${border.radius.md};
    flex-direction: row;
    gap: ${space.xs};
    align-items: center;
    background-color: ${isSelected
      ? colors.brand.primary.op10
      : colors.neutral.white};
    padding: ${space.xs2};

    span {
      background-color: ${isSelected && colors.brand.primary.default};
      border-color: ${isSelected && colors.brand.primary.default} !important;
    }

    :hover {
      background-color: ${colors.brand.primary.op10};
    }

    .Checkbox {
      margin-top: 7px;
    }
  `}

  p {
    margin-bottom: 0px !important;
  }
`;

export const ButtonWrapper = styled.section`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    gap: ${space.xs};

    > button {
      width: 100%;
    }
  `}
`;

export const AlertWrapper = styled.div`
  ${({ theme: { typography } }: ThemeProps) => css`
    p {
      ${typography.title.Bold14}
    }
  `}
`;
