import React from 'react';

import ColoredText from 'components/ColoredText';
import DropdownButton from 'components/DropdownButton';
import Tooltip from 'components/Tooltip';
import AgendaIcon from 'components/AgendaIcon';

import './style.scss';

const Table = ({
  data,
  columns,
  metadata,
  customMetadata = null,
  otherOptions,
  exportOptions,
  listOf,
  exportTrackEvent = null,
}) => {
  const getCell = ({ d, column, Cell, row, rowCount }) => {
    const content =
      typeof column.accessor === 'function'
        ? column.accessor(d, row, rowCount)
        : d[column.accessor];

    return Cell ? <Cell>{content}</Cell> : content;
  };

  const entriesInfo = ({
    page,
    itemsCountPerPage,
    totalItemsCount,
    singular,
    plural,
  }) => {
    const start = page ? page * itemsCountPerPage - (itemsCountPerPage - 1) : 1;
    const finish = page ? page * itemsCountPerPage : itemsCountPerPage;

    return (
      <span>
        <ColoredText variation="gray-2 w600">
          {start} - {finish > totalItemsCount ? totalItemsCount : finish}{' '}
        </ColoredText>
        de
        <ColoredText variation="gray-2 w600"> {totalItemsCount} </ColoredText>
        {totalItemsCount !== 1 ? plural : singular}
      </span>
    );
  };

  const recurrentPlansListHeader = (header) => {
    const content = {
      Vencimento: (
        <p>
          Referente a última data <br /> de vencimento definida.
        </p>
      ),
      Valor: (
        <p>
          Valor inicial da cobrança definido pela escola.
          <br />
          Não considera multa, juros e descontos.
        </p>
      ),
    };

    if (header === 'Vencimento') {
      return (
        <div className="recurrent-tooltip">
          <div>{header}</div>
          <Tooltip content={content[header]}>
            <AgendaIcon name="help" size="small" />
          </Tooltip>
        </div>
      );
    } else if (header === 'Valor' || header === 'Total a pagar') {
      const key = 'Valor';
      return (
        <div className="recurrent-tooltip">
          <div>{header}</div>
          <Tooltip content={content[key]}>
            <AgendaIcon name="help" size="small" />
          </Tooltip>
        </div>
      );
    }

    return header;
  };

  return (
    <div data-testid="table-wrapper" className="TableContainer">
      <div
        className={`table-container-header ${exportOptions ? 'exporting' : ''}`}
      >
        {metadata && !customMetadata && (
          <div className="table-metadata">{entriesInfo(metadata)}</div>
        )}

        {customMetadata && customMetadata}

        <div className="buttons-wrapper">
          {!!otherOptions && otherOptions}
          {exportOptions && (
            <DropdownButton
              id="export-dropdown"
              dropdownItems={exportOptions}
              trackEventName={exportTrackEvent}
              text="Exportar"
              variation="secondary"
            />
          )}
        </div>
      </div>
      <div className="table-responsive TableOverflowX">
        <table className="Table table table-hover">
          <thead>
            <tr>
              {columns.map((column, index) => (
                <th key={`th-${index}`}>
                  {typeof column.Header === 'function' ? (
                    <column.Header />
                  ) : listOf === 'recurrentPlans' ? (
                    recurrentPlansListHeader(column.Header)
                  ) : (
                    column.Header
                  )}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data &&
              data.map((d, row) => (
                <tr key={`tr-${row}`}>
                  {columns.map((column, index) => (
                    <td key={`td-${index}`}>
                      {getCell({
                        d,
                        column,
                        Cell: column.cell,
                        row,
                        rowCount: data.length,
                      })}
                    </td>
                  ))}
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Table;
