import React from 'react';

import FormContainer from 'components/Form/FormContainer';

import './styles.scss';

import { ChildrenProps, FilterContainerProps } from './types';

export const DoubleInputsWrapper = ({ children }: ChildrenProps) => (
  <div className="wrapper-filter-input" data-testid="DoubleInputsWrapper">
    {children}
  </div>
);

export const HalfInputWrapper = ({ children }: ChildrenProps) => (
  <div className="multiFilterWrapper" data-testid="HalfInputWrapper">
    {children}
  </div>
);

const FilterContainer = ({
  form,
  formMeta,
  children,
}: FilterContainerProps) => (
  <div className="FilterContainerWrapper" data-testid="FilterContainerWrapper">
    <FormContainer variation="horizontal" form={form} formMeta={formMeta}>
      {children}
    </FormContainer>
  </div>
);

export default FilterContainer;
