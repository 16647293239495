import React from 'react';
import PropTypes from 'prop-types';
import withFormContext, { formPropTypes } from 'core/hoc/withFormContext';

import AgendaIcon from 'components/AgendaIcon';
import Alert from 'components/Alert';
import EdupayPenality from 'components/Form/EdupayPenality';
import Title from 'components/WizardForm/Title';
import ToggleSwitch from 'components/Form/ToggleSwitch';
import { TOP_CENTER } from 'components/Tooltip';

import * as S from './styles';

const FinesAndInterest = ({
  type,
  action,
  withoutTitle,
  formContext: { form, formMeta, updateAttribute },
}) => {
  const { status } = formMeta;
  const { toggle_penality: togglePenality, edupay_penality: edupayPenality } =
    form;

  const disabledOnEdit = ['released', 'delayed'].includes(status);

  const toggleSwitch = () => {
    updateAttribute('toggle_penality', !togglePenality);
  };

  const tooltipContent =
    type === 'plan' && action === 'edit' ? (
      <p>
        A edição de multas e juros irá impactar
        <br />
        todas as demais cobranças do plano.
      </p>
    ) : (
      <p>
        Sendo no máximo 1% de juros
        <br />e 2% de multa.
      </p>
    );

  return (
    <S.FinesAndInterestWrapper>
      {!togglePenality && !withoutTitle && (
        <Alert variation="danger">
          Aplique multas e juros para que as taxas sejam calculadas
          automaticamente baseado na data de pagamento da fatura.
        </Alert>
      )}
      {!withoutTitle && <Title name="Condições de pagamento" />}
      <S.TogglePenalityWrapper>
        <S.TogglePenalityTitle>Aplicar multas e juros</S.TogglePenalityTitle>
        <S.PenalityToolip content={tooltipContent} position={TOP_CENTER}>
          <AgendaIcon name="help" size="small" className="custom-size" />
        </S.PenalityToolip>
        <ToggleSwitch
          testID="tooglePenality"
          toggled={togglePenality}
          onChange={toggleSwitch}
          disabled={disabledOnEdit}
        />
      </S.TogglePenalityWrapper>
      {togglePenality && (
        <EdupayPenality
          testID="edupayPenality"
          attributeForm="edupay_penality"
          penality={edupayPenality}
          disabled={disabledOnEdit}
        />
      )}
    </S.FinesAndInterestWrapper>
  );
};

FinesAndInterest.propTypes = {
  ...formPropTypes,
  type: PropTypes.string,
  action: PropTypes.string,
  withoutTitle: PropTypes.string,
};

FinesAndInterest.defaultProps = {
  withoutTitle: false,
};

export default withFormContext(FinesAndInterest);
