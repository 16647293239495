import { ThemeProps } from 'config/themes/types';
import styled, { css } from 'styled-components';

export const LabeledWrapper = styled.div`
  ${({ theme: { colors, font, space } }: ThemeProps) => css`
    display: flex;
    flex-direction: column;
    align-items: start;
    margin-bottom: ${space.lg};

    .detail-header {
      display: flex;
      margin-bottom: ${space.xs2};

      .detail-title {
        color: ${colors.neutral.gray1};
      }
    }

    .information-content {
      width: 100%;
      color: ${colors.neutral.black};

      .simple-string {
        color: ${colors.neutral.black};
        font-size: ${font.size.md};
        display: block;
      }

      .PaymentAccordion {
        width: 100%;
        max-width: 100%;
        margin-bottom: ${space.xs2};
      }

      .classrooms-students {
        display: flex;
      }

      @media screen (min-width: 767px) and (max-width: 1199px) {
        width: 100%;
      }

      @media screen (max-width: 767px) {
        display: flex;
        flex-direction: column;
      }
    }
  `}
`;
