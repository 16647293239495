import React, {
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Icon } from '@agendaedu/ae-web-components';
import { OmniChannelState } from 'store/messages/omniChannel/types';
import OmniChannelActions from 'store/messages/omniChannel/actions';

import * as S from './styles';

import MessageBalloon from '../MessageBalloon';
import MessageBalloonSkeleton from '../MessageBalloon/skeleton';
import { MessagesListSkeleton } from './skeleton';

export const MessagesList = (): JSX.Element => {
  const wrapperRef: RefObject<HTMLDivElement> = useRef(null);

  const [scrollFloatingButton, setScrollFloatingButton] = useState(false);

  const {
    messages,
    messagesPagination: { totalItemsCount, page },
    activeChannel,
    activeChat,
    isLoadingMessages,
  } = useSelector((state: OmniChannelState) => state.omniChannel);

  const dispatch = useDispatch();

  const { fetchMessagesRequest } = OmniChannelActions;

  const hasMoreMessages = (): boolean => {
    return messages.length < totalItemsCount;
  };

  const onScroolList = useCallback(() => {
    if (wrapperRef.current)
      setScrollFloatingButton(wrapperRef.current.scrollTop < 0);
  }, []);

  const handleButtonOnScrool = useCallback(() => {
    wrapperRef.current.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const fetchMoreMessages = useCallback(() => {
    const params = {
      channelId: activeChannel.id,
      chatId: activeChat.id,
      page: page + 1,
    };

    dispatch(fetchMessagesRequest(params));
  }, [activeChannel?.id, activeChat?.id, page, dispatch, fetchMessagesRequest]);

  useEffect(() => {
    setScrollFloatingButton(false);
  }, [activeChat]);

  return (
    <S.Wrapper
      ref={wrapperRef}
      id="messages-content-wrapper"
      data-testid="messages-content-wrapper"
    >
      {isLoadingMessages ? (
        <MessagesListSkeleton />
      ) : (
        <>
          <S.Scroll
            onScroll={onScroolList}
            dataLength={messages.length}
            next={fetchMoreMessages}
            hasMore={hasMoreMessages()}
            loader={
              <>
                <MessageBalloonSkeleton variantType="sent" />
                <MessageBalloonSkeleton variantType="received" />
              </>
            }
            scrollableTarget="messages-content-wrapper"
            inverse={true}
          >
            {messages.map((message) => (
              <MessageBalloon key={message.id} message={message} />
            ))}
          </S.Scroll>
          <S.ScrollFloatingButton
            disabled={!scrollFloatingButton}
            onClick={handleButtonOnScrool}
          >
            <Icon name="chevron-down" size="md" />
          </S.ScrollFloatingButton>
        </>
      )}
    </S.Wrapper>
  );
};
