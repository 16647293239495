import React, { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { flags } from 'core/constants/flags';
import { Link } from 'react-router-dom';
import moment from 'moment';
import {
  renderDiscountMessage,
  canCancelEnrollment,
} from 'core/utils/edupay/functions';
import { normalizeFileSize } from 'core/utils/formatBytes';
import tabify from 'core/hoc/tabify';

import actionEnrollmentPlans from 'store/edupay/enrollmentPlans/actions';

import AgendaIcon from 'components/AgendaIcon';
import ButtonRow from 'components/ButtonRow';
import Button from 'components/Button';
import CancelModal from 'components/Payments/CancelModal';
import ColoredText from 'components/ColoredText';
import enrollmentPlanContractStatus from '../../EnrollmentPlanContractStatus';
import enrollmentPlanStatus from '../../EnrollmentPlanStatus';
import LabeledContainer from 'components/LabeledContainer';
import Loader from 'components/Loader';
import ManualDealModal from 'components/Payments/ManualDealModal';
import ModalContainer from 'components/Modal/ModalContainer';
import ModalTitle from 'components/Modal/ModalTitle';
import OutlineBox from 'components/OutlineBox';
import PaymentAccordion from 'components/Accordion/variations/payment';
import Toast from 'components/Toast';
import Tooltip, { TOP_CENTER } from 'components/Tooltip';
import UserDisplay from 'components/UserDisplay';
import SimpleSummary from 'components/Payments/PaymentsContainer/FormSections/SimpleSummary';

import './style.scss';

const InformationTab = ({
  match: {
    params: { id },
  },
}) => {
  const dispatch = useDispatch();

  const [toggleModalApprove, setToggleModalApprove] = useState(false);
  const [textCancel, setTextCancel] = useState('');

  const {
    currentEnrollmentPlan,
    isLoading,
    currentSignedFile,
    cancelModal,
    showManualDealModal,
  } = useSelector((state) => state.enrollmentPlans);

  const policies = useSelector((state) => state.root.policies);

  const {
    setCurrentEnrollmentPlanRequest,
    approveEnrollmentPlanRequest,
    toggleCancel,
    cancelEnrollmentRequest,
    cancelEnrollmentRequestLD,
    toggleManualDealModal,
  } = actionEnrollmentPlans;

  const setCurrentEnrollmentPlan = useCallback(() => {
    dispatch(setCurrentEnrollmentPlanRequest(id));
  }, [dispatch, id, setCurrentEnrollmentPlanRequest]);

  const approveEnrollmentPlan = useCallback(() => {
    dispatch(approveEnrollmentPlanRequest(id));
  }, [dispatch, id, approveEnrollmentPlanRequest]);

  const handleToggleCancelModal = useCallback(() => {
    dispatch(toggleCancel());
  }, [dispatch, toggleCancel]);

  const handleToggleManualDealModal = useCallback(() => {
    dispatch(toggleManualDealModal());
  }, [dispatch, toggleManualDealModal]);

  const markCancel = useCallback(
    (enrollmentId, textCancel) => {
      dispatch(cancelEnrollmentRequest(enrollmentId, textCancel));
      handleToggleCancelModal();
    },
    [dispatch, cancelEnrollmentRequest, handleToggleCancelModal]
  );

  const handleSetCancelOrder = useCallback(
    (form) => {
      const { id: enrollmentId } = currentEnrollmentPlan.attributes;
      dispatch(cancelEnrollmentRequestLD(enrollmentId, form));
    },
    [dispatch, cancelEnrollmentRequestLD, currentEnrollmentPlan]
  );

  const areInvalidDate = () => {
    const expireDate = moment(
      currentEnrollmentPlan.attributes.expires_at,
      'DD/MM/YYYY'
    ).format('YYYY-MM-DD');
    const today = moment(Date()).format('YYYY-MM-DD');
    const validate = moment(today).isAfter(expireDate);

    return validate;
  };

  const subTitle = () => (
    <>
      {currentEnrollmentPlan && (
        <p>
          A emissão da cobrança do dia{' '}
          <ColoredText variation="bold">
            {currentEnrollmentPlan.attributes.expires_at}
          </ColoredText>{' '}
          será
          <br />
          cancelada e não será enviada para o responsável
          <br />
          do aluno.
        </p>
      )}
    </>
  );

  useEffect(() => {
    setCurrentEnrollmentPlan();
  }, []);

  const handleModalApprove = () => {
    setToggleModalApprove(!toggleModalApprove);
  };

  const responsibles =
    currentEnrollmentPlan?.attributes.school_products_responsible_profiles
      .data || [];

  const content = responsibles.map((responsible) => (
    <p className="tooltip-status" key={responsible.attributes.id}>
      <strong>
        {
          enrollmentPlanContractStatus[responsible.attributes.document_status]
            .text
        }
        {': '}
      </strong>
      {responsible.attributes.responsible_profile_name}
    </p>
  ));

  return (
    <div className="EnrollmentPlanInformationTab">
      {isLoading || !currentEnrollmentPlan ? (
        <Loader />
      ) : (
        <React.Fragment>
          <div className="information-content">
            <div className="left-side">
              <div className="status-modal">
                <Tooltip content={content} position={TOP_CENTER}>
                  <OutlineBox
                    variation={
                      enrollmentPlanContractStatus[
                        currentEnrollmentPlan.attributes.document_status
                      ].variation
                    }
                  >
                    {`Contrato ${
                      enrollmentPlanContractStatus[
                        currentEnrollmentPlan.attributes.document_status
                      ].text
                    }`}
                  </OutlineBox>
                </Tooltip>
                <OutlineBox
                  variation={
                    enrollmentPlanStatus[
                      currentEnrollmentPlan.attributes.status
                    ].variation
                  }
                >
                  {
                    enrollmentPlanStatus[
                      currentEnrollmentPlan.attributes.status
                    ].text
                  }
                </OutlineBox>
              </div>
              <UserDisplay
                user={currentEnrollmentPlan.attributes.student_profile.data}
                classroomsNames={
                  currentEnrollmentPlan.attributes.student_profile.data
                    .attributes.classroom_names
                }
                size="medium"
              />
              <LabeledContainer title="Descrição">
                <p
                  dangerouslySetInnerHTML={{
                    __html: currentEnrollmentPlan.attributes.description,
                  }}
                />
              </LabeledContainer>
              <LabeledContainer title="Responsável">
                <p className="responsibles">
                  {responsibles.map((responsible, index) =>
                    responsibles.length - 1 === index
                      ? responsible.attributes.responsible_profile_name
                      : `${responsible.attributes.responsible_profile_name}, `
                  )}
                </p>
              </LabeledContainer>
              <LabeledContainer title="Vencimento da matrícula">
                {currentEnrollmentPlan.attributes.expires_at}
              </LabeledContainer>
              {currentEnrollmentPlan.attributes.has_discount && (
                <LabeledContainer title="Desconto de pontualidade">
                  <div className="unique-column">
                    <ul>
                      {currentEnrollmentPlan.attributes.edupay_discounts.data.map(
                        ({ attributes, index }) => (
                          <div key={index}>
                            {renderDiscountMessage(attributes)}
                          </div>
                        )
                      )}
                    </ul>
                  </div>
                </LabeledContainer>
              )}
              <LabeledContainer title="Contrato">
                <a
                  className="contract"
                  href={
                    (currentSignedFile?.attributes.signed_file ||
                      currentEnrollmentPlan.attributes.attachment?.url) ??
                    currentEnrollmentPlan.attributes.contract_template.data
                      .attributes.attachment.url
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  download
                >
                  <div className="pdf-name">
                    <AgendaIcon name="attachment" />
                    <p>
                      {currentEnrollmentPlan.attributes.attachment?.name ??
                        currentEnrollmentPlan.attributes.contract_template.data
                          .attributes.attachment.name}
                    </p>
                  </div>
                  <p className="size">
                    {normalizeFileSize(
                      currentEnrollmentPlan.attributes.attachment?.size ??
                        currentEnrollmentPlan.attributes.contract_template.data
                          .attributes.attachment.size
                    )}
                  </p>
                </a>
              </LabeledContainer>
            </div>
            <div className="right-side">
              <LabeledContainer title="Resumo da cobrança">
                <SimpleSummary price={currentEnrollmentPlan.attributes.price} />
              </LabeledContainer>
            </div>
          </div>
          <div className="action-area">
            <section className="enrollment-action-buttons">
              <ButtonRow>
                {['pending'].includes(
                  currentEnrollmentPlan.attributes.status
                ) &&
                  !areInvalidDate() && (
                    <Button
                      type="button"
                      variation="primary"
                      style={{ marginLeft: '0' }}
                      className="action-button"
                      onClick={() => handleModalApprove()}
                      disabled={!policies.can_approve_school_product}
                    >
                      Aprovar Envio
                    </Button>
                  )}
                {![
                  'credit_card_paid',
                  'manually_paid',
                  'billet_paid',
                  'paid',
                  'paid_late',
                  'canceled',
                  'paid_divergent',
                ].includes(currentEnrollmentPlan.attributes.status) && (
                  <Button
                    className="action-button-edit"
                    type="button"
                    variation="secondary"
                    disabled={!policies.can_edit_after_approved}
                  >
                    <Link to={`/schools/enrollment_plans/${id}/edit`}>
                      <AgendaIcon name="edit" size="medium" />
                    </Link>
                  </Button>
                )}
              </ButtonRow>
              {policies.can_cancel_school_product &&
                canCancelEnrollment(
                  currentEnrollmentPlan.attributes.status,
                  currentEnrollmentPlan.attributes.document_status
                ) && (
                  <Button
                    type="button"
                    variation="danger"
                    onClick={() =>
                      policies.edupay_active_flags.includes(
                        flags.ENROLLMENT_CANCELLATION
                      )
                        ? handleToggleManualDealModal()
                        : handleToggleCancelModal()
                    }
                  >
                    <AgendaIcon name="trash" size="medium" />
                  </Button>
                )}
            </section>
          </div>
          <ModalContainer
            isOpen={toggleModalApprove}
            toggleModal={handleModalApprove}
            className="CloneRecurrentPlanModal"
          >
            <ModalTitle>Deseja aprovar o envio da matrícula?</ModalTitle>
            <p>
              A cobrança e o contrato serão enviados para o responsável
              selecionado.
            </p>
            <ButtonRow>
              <Button variation="secondary" onClick={handleModalApprove}>
                Fechar
              </Button>
              <Button
                onClick={() => {
                  approveEnrollmentPlan();
                  handleModalApprove();
                }}
              >
                Aprovar
              </Button>
            </ButtonRow>
          </ModalContainer>
          <CancelModal
            className="cancel-modal"
            isOpen={cancelModal}
            title="Cancelar cobrança"
            subTitle={subTitle()}
            toggleModal={handleToggleCancelModal}
            onCancel={() => markCancel(id, textCancel)}
            setTextCancel={setTextCancel}
          />
          <ManualDealModal
            isOpen={showManualDealModal}
            toggleModal={handleToggleManualDealModal}
            bill={currentEnrollmentPlan}
            isRecurrent={false}
            title="Cancelar cobrança"
            modalType={'cancel'}
            onSubmit={(form) => handleSetCancelOrder(form)}
          />
          <Toast />
        </React.Fragment>
      )}
    </div>
  );
};

InformationTab.propTypes = {
  match: PropTypes.object.isRequired,
};

export default tabify({
  title: 'Informações',
  icon: 'info',
})(InformationTab);
