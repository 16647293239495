import React from 'react';
import PropTypes from 'prop-types';
import withFormContext, { formPropTypes } from 'core/hoc/withFormContext';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

import Tooltip from 'components/Tooltip';
import FormCheckbox from 'components/Form/Checkbox';
import FormValidationErrors from 'components/Form/ValidationErrors';
import Alert from 'components/Alert';
import AgendaIcon from 'components/AgendaIcon';

import { CHECKOUT_PAYMENT_METHODS_TOOLTIP_MESSAGES } from 'core/constants';

import './style.scss';

const CheckPaymentMethods = ({
  attributeName,
  withPix,
  disabled,
  canConfigPaymentMethods,
  isNewLayout = false,
  isCartSettings = false,
  isRecurrency = false,
  cartSettingsAllowedPaymentMethods = [],
  formContext: { form, formMeta, updateAttribute, hasErrorOnAttribute },
}) => {
  const { t } = useTranslation(['payments']);

  const {
    school_plan,
    edupay_provider,
    can_absorb_tax: canAbsorbTax,
    invoice_details: invoiceDetails,
  } = useSelector((state) => state.root.policies);

  const { allowed_payment_method } = form;
  const { hasCurrentSubscribe } = formMeta;
  const hasError = hasErrorOnAttribute(attributeName);

  const toggleCheckbox = (choosenMethod) => {
    let newValue;
    if (!allowed_payment_method.includes(choosenMethod)) {
      newValue = allowed_payment_method.concat(choosenMethod);
    } else {
      newValue = allowed_payment_method.filter((value) => {
        return value !== choosenMethod;
      });
    }
    updateAttribute(attributeName, newValue);
  };

  const renderCheckbox = (chosenMethod, disabled, hasSubscribe) => {
    const paymentMethod = {
      billet: 'Boleto',
      credit_card: 'Cartão de crédito',
      pix: 'PIX',
    };

    return (
      <div className={`checkbox-option ${isNewLayout ? 'new' : ''}`}>
        {isNewLayout && disabled ? (
          <>
            <Tooltip
              content={parse(CHECKOUT_PAYMENT_METHODS_TOOLTIP_MESSAGES.options)}
            >
              <FormCheckbox
                testid={`checkbox-${chosenMethod}`}
                checked={allowed_payment_method.includes(chosenMethod)}
                onChange={() => toggleCheckbox(chosenMethod)}
                disabled={hasSubscribe || disabled}
              />
            </Tooltip>

            <span
              className="checkbox-label new"
              onClick={() =>
                !hasSubscribe && !disabled && toggleCheckbox(chosenMethod)
              }
            >
              {paymentMethod[chosenMethod]}
            </span>
          </>
        ) : (
          <>
            <FormCheckbox
              testid={`checkbox-${chosenMethod}`}
              checked={allowed_payment_method.includes(chosenMethod)}
              onChange={() => toggleCheckbox(chosenMethod)}
              disabled={hasSubscribe || disabled}
            />
            <span
              className={`checkbox-label ${isNewLayout ? 'new' : ''}`}
              onClick={() =>
                !hasSubscribe && !disabled && toggleCheckbox(chosenMethod)
              }
            >
              {paymentMethod[chosenMethod]}
            </span>
          </>
        )}

        {isNewLayout && !isCartSettings && chosenMethod === 'credit_card' && (
          <Tooltip
            content={parse(
              canAbsorbTax && !isRecurrency
                ? CHECKOUT_PAYMENT_METHODS_TOOLTIP_MESSAGES.info_absorb_tax
                : CHECKOUT_PAYMENT_METHODS_TOOLTIP_MESSAGES.info
            )}
          >
            <AgendaIcon name="help" size="small" />
          </Tooltip>
        )}
      </div>
    );
  };

  return (
    <div testid="check-wrapper-container" className="checkboxes-wrapper">
      <span className="description">
        {edupay_provider === 'bemobi'
          ? isCartSettings
            ? t(`form_section.payment_methods_cart_label`)
            : t(`form_section.payment_methods_bemobi_label`)
          : t(`form_section.payment_methods_label`)}
      </span>
      {allowed_payment_method.includes('credit_card') &&
        canConfigPaymentMethods &&
        edupay_provider === 'bemobi' &&
        !isNewLayout && (
          <Alert variation="info">
            {t(
              `form_section.bemobi_school_config_payment_methods_enable_message`
            )}
          </Alert>
        )}
      {edupay_provider === 'bemobi' &&
        !canConfigPaymentMethods &&
        !isNewLayout && (
          <Alert variation="info">
            {t(
              `form_section.bemobi_school_config_payment_methods_disabled_message`
            )}
          </Alert>
        )}
      <div className={`options-${isNewLayout ? 'new' : ''}`}>
        {isCartSettings ? (
          <>
            {cartSettingsAllowedPaymentMethods.includes('billet') &&
              renderCheckbox('billet', disabled, false)}
            {cartSettingsAllowedPaymentMethods.includes('pix') &&
              renderCheckbox('pix', disabled, false)}
            {cartSettingsAllowedPaymentMethods.includes('credit_card') &&
              renderCheckbox('credit_card', disabled, false)}
          </>
        ) : (
          <>
            {renderCheckbox('billet', disabled, false)}
            {withPix && renderCheckbox('pix', disabled, false)}
            {school_plan != 'trial' &&
              invoiceDetails?.credit_card_enabled &&
              renderCheckbox('credit_card', disabled, hasCurrentSubscribe)}
          </>
        )}
      </div>
      {hasError && (
        <FormValidationErrors
          testid="checkform-validation"
          attributeName={attributeName}
        />
      )}
    </div>
  );
};

CheckPaymentMethods.propTypes = {
  attributeName: PropTypes.string.isRequired,
  withPix: PropTypes.bool,
  disabled: PropTypes.bool,
  canConfigPaymentMethods: PropTypes.bool,
  formContext: PropTypes.shape({
    action: PropTypes.string.isRequired,
    changeMeta: PropTypes.func.isRequired,
    form: PropTypes.shape({
      allowed_payment_method: PropTypes.array.isRequired,
    }),
    formMeta: PropTypes.object,
    updateAttribute: PropTypes.func.isRequired,
    hasErrorOnAttribute: PropTypes.func.isRequired,
  }),
  isNewLayout: PropTypes.bool,
  isRecurrency: PropTypes.bool,
  isCartSettings: PropTypes.bool,
  cartSettingsAllowedPaymentMethods: PropTypes.array,
};

CheckPaymentMethods.defaultProps = {
  withPix: false,
  ...formPropTypes,
};

export default withFormContext(CheckPaymentMethods);
