import { Avatar, Button, Icon } from '@agendaedu/ae-web-components';
import { ThemeProps } from 'config/themes/types';
import styled, { css } from 'styled-components';
import {
  ExpandingButtonStyleProps,
  HeaderActionsWrapperStyleProps,
} from './types';

export const Wrapper = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    flex-direction: column;
    padding: ${space.xl} ${space.lg};
  `}
`;

export const ChatHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const NewChatButtonWrapper = styled.div`
  display: flex;
`;

export const NewChatButton = styled(Button)`
  white-space: nowrap;
`;

export const FilterButton = styled(Button)`
  ${({ theme: { border } }: ThemeProps) => css`
    border-radius: ${border.radius.md} !important;
  `}
`;

export const SearchButton = styled(Button)`
  ${({ theme: { border, space } }: ThemeProps) => css`
    border-radius: ${border.radius.md} !important;
    margin-left: ${space.sm};
  `}
`;

export const HeaderActionsWrapper = styled.div<HeaderActionsWrapperStyleProps>`
  ${({ isSearchFilter, theme: { space } }) => css`
    display: flex;
    flex-direction: row;
    gap: ${space.sm};
    width: ${isSearchFilter ? '0px' : '100%'};
    justify-content: space-between;
    transition: width 0.4s ease-in-out;
    visibility: ${isSearchFilter ? 'hidden' : 'visible'};
  `}
`;

export const ExpandingInput = styled.input`
  ${({ theme: { colors } }: ThemeProps) => css`
    width: 100%;
    border: none;
    color: ${colors.neutral.black};

    :focus {
      ${HeaderActionsWrapper} border-color: red;
    }
  `}
`;

export const ExpandingInputWrapper = styled.div<ExpandingButtonStyleProps>`
  ${({ isSearchFilter, hasSearchTabs, theme: { colors, border } }) => css`
    display: flex;
    flex-direction: row;
    border: solid 1px ${colors.neutral.gray4};
    border-radius: ${border.radius.md};
    opacity: ${!hasSearchTabs && !isSearchFilter && 0};
    width: ${!isSearchFilter ? '0' : '100%'};
    transition: width 0.4s ease-in-out;
    background-color: ${colors.neutral.white};
    align-items: center;
    justify-content: center;

    :focus-within,
    :hover {
      border-color: ${colors.brand.primary.default};
    }
  `}
`;

export const InputIcon = styled(Icon)`
  ${({ theme: { colors } }: ThemeProps) => css`
    margin: 0 8px;
    width: 18px;
    height: 18px;

    path {
      fill: ${colors.neutral.gray1};
    }

    ${ExpandingInputWrapper}:hover & {
      path {
        fill: ${colors.brand.primary.default};
      }
    }
  `}
`;

export const BackChannelWrapper = styled.div`
  ${({ theme: { space, breakpoints } }: ThemeProps) => css`
    display: none;
    flex-direction: row;
    align-items: center;
    margin-bottom: ${space.sm};
    margin-left: ${space.xs2};
    p {
      margin-bottom: ${space.xs4};
    }

    @media screen and (max-width: ${breakpoints.desktopLG}) {
      display: flex;
    }
  `}
`;

export const BackChannelIcon = styled(Icon)`
  ${({ theme: { colors } }: ThemeProps) => css`
    fill: {
      color: ${colors.brand.primary.default};
    }
    cursor: pointer;
  `}
`;

export const BackChannelAvatar = styled(Avatar)`
  ${({ theme: { space } }: ThemeProps) => css`
    margin-left: ${space.xs};
    margin-right: ${space.xs2};
  `}
`;
