import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import withFormContext from 'core/hoc/withFormContext';
import walletsActions from 'store/edupay/wallets/actions';
import { RootState, WalletConfigurationProps } from './types';
import Field from 'components/Form/Field';
import Alert from 'components/Alert';
import * as S from './styles';

const WalletConfiguration = ({
  formContext: {
    updateAttribute,
    form: { recipient_wallet_id: recipientWalletId },
    formMeta: { status },
  },
  isRecurrent = false,
  recurrentBill = false,
  disabled = false,
}: WalletConfigurationProps) => {
  const dispatch = useDispatch();
  const [currentRecipientWalletId, setCurrentRecipientWalletId] =
    useState(recipientWalletId);
  const { fetchRecipientsWalletsOptionsRequest } = walletsActions;
  const { walletsOptions } = useSelector((state) => state.wallets);

  const canEdit = ['active', 'not_started'].includes(status);

  const {
    currentUser: {
      attributes: { is_debug_user: isDebugUser },
    },
    policies,
  } = useSelector((state: RootState) => state.root);

  const canSetRecipientWalletId = () => {
    if (isRecurrent && recurrentBill) {
      return true;
    } else if (isRecurrent) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (!canSetRecipientWalletId()) setCurrentRecipientWalletId(null);
  }, []);

  useEffect(() => {
    updateAttribute(
      'recipient_wallet_id',
      currentRecipientWalletId !== null ? currentRecipientWalletId : null
    );
  }, [currentRecipientWalletId, updateAttribute, disabled]);

  useEffect(() => {
    dispatch(fetchRecipientsWalletsOptionsRequest());
  }, [dispatch, fetchRecipientsWalletsOptionsRequest]);

  useEffect(() => {
    // We get the second because the first one is a placeholder.
    if (walletsOptions.length === 2) setCurrentRecipientWalletId(walletsOptions[1].value);
  }, [walletsOptions]);

  const content =
    'Antes de iniciar o preenchimento da nova oferta, selecione a carteira que irá receber o valor das vendas.';

  return (
    <S.Wrapper>
      <p className="wallet-title">{content}</p>
      <Field
        fullWidth
        withArrowSelect
        label="Carteira de destino*"
        type="selectWithIcon"
        attributeName="recipient_wallet_id"
        classNamePrefix="wallet_configuration"
        options={
          isRecurrent && isDebugUser && policies.guaranteed_monthly_fee_enabled
            ? walletsOptions
            : walletsOptions.filter(
                (option) => !option.label?.includes('Mensalidade Garantida')
              )
        }
        value={currentRecipientWalletId}
        onChange={(e) => {
          setCurrentRecipientWalletId(e.value);
        }}
        disabled={!canEdit || disabled}
      />
    </S.Wrapper>
  );
};

export default withFormContext(WalletConfiguration);
