import { ThemeProps } from 'config/themes/types';
import styled, { css } from 'styled-components';
import Field from 'components/Form/Field';

type InstallmentFeesConfigurationWrapperProps = {
  creditCardDisabled?: boolean;
  theme?: ThemeProps;
};

type ToggleAssumeFeesWrapperProps = {
  showBorder?: boolean;
  theme?: ThemeProps;
};

export const InstallmentFeesConfigurationWrapper = styled.div<InstallmentFeesConfigurationWrapperProps>`
  ${({
    theme: { border, colors, typography, space },
    creditCardDisabled,
  }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: ${creditCardDisabled ? '#f6f6f6' : 'transparent'};
    border: ${border.width.sm} solid
      ${creditCardDisabled ? colors.neutral.gray5 : colors.neutral.gray4};
    border-radius: ${border.radius.md};
    color: ${creditCardDisabled ? colors.neutral.gray3 : colors.neutral.gray1};
    pointer-events: ${creditCardDisabled ? 'none' : 'auto'};
    ${typography.body.Regular14}

    .tooltip-div {
      margin-top: ${space.xs3};
      i {
        color: ${creditCardDisabled
          ? colors.neutral.gray3
          : colors.neutral.gray1};
      }
    }
  `}
`;

export const ToggleAssumeFeesWrapper = styled.div<ToggleAssumeFeesWrapperProps>`
  ${({ theme: { border, colors, space }, showBorder }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${space.md} ${space.lg};
    border-bottom: ${showBorder
      ? `${border.width.sm} solid ${colors.neutral.gray4}`
      : 'none'};

    .ToggleSwitch {
      height: 24px;

      .switch {
        height: 24px;
        width: 48px;

        .switch-toggle {
          height: 22px;
          width: 23px;
        }
      }
    }
  `}
`;

export const ToggleAssumeFeesTitleWrapper = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    align-items: center;
    gap: ${space.xs2};
  `}
`;

export const AvailableInstallmentsWrapper = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: ${space.lg};
  `}
`;

export const SelectInstallmentsField = styled(Field)`
  margin: 0 !important;
  padding: 0 !important;
`;
