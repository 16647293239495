import styled, { css } from 'styled-components';
import colorWithAlpha from 'core/utils/colorWithAlpha';

export const actionButtonsStyles = css`
  ${({ theme }) => css`
    height: fit-content;
    width: fit-content;
    display: grid;
    grid-gap: 6px;
    padding: 12px;
    border: 1px solid ${theme.gray12};
    border-radius: 8px;
    margin-left: 8px;

    button,
    .Button {
      height: 40px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }

    button {
      border: 1px solid ${theme.gray3};
      background-color: transparent;
      color: ${theme.danger};
      padding: 9px 10px;
      border-radius: 4px;
      border-color: ${theme.danger};

      &:hover {
        background-color: ${colorWithAlpha(`${theme.danger}`, 0.1)};
        border-color: ${colorWithAlpha(`${theme.danger}`, 0.1)};
      }

      &:disabled {
        color: ${theme.gray3};
        border-color: ${theme.gray5};
        background-color: ${theme.white};
      }
    }

    @media (max-width: 576px) {
      grid-template-columns: 1fr 1fr;
      margin: 16px 0 0 0;
    }
  `};
`;

export const Container = styled.section`
  margin-top: 20px;
`;
export const TextAreaContainer = styled.div`
  * {
    font-family: 'Roboto', sans-serif;
  }

  label {
    font-weight: normal;
    margin-bottom: 12px;
    font-size: 14px;
  }

  textArea {
    overflow: hidden;
  }
`;

export const MultipleOccurrenceContainer = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 0;
  margin-top: 8px;

  @media (max-width: 576px) {
    display: block;
  }
`;

export const OccurrenceActionsContainer = styled.div`
  ${actionButtonsStyles}
`;
