import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Props } from './types';
import tabify from 'core/hoc/tabify';
import smoothScrollTo from 'core/utils/smoothScrollTo';

import Loader from 'components/Loader';
import Pagination from 'components/Pagination';
import Table from 'components/Table';

import table from './table';

import actionSchoolProducts from 'store/edupay/schoolProducts/actions';
import { SchoolProductsState } from 'store/edupay/schoolProducts/types';

const SchoolProductHistoryTab = ({ schoolProductId }: Props) => {
  const { isLoadingHistory, history } = useSelector(
    (state: SchoolProductsState) => state.schoolProducts
  );

  const { fetchHistory } = actionSchoolProducts;

  const dispatch = useDispatch();

  useEffect(() => {
    handlePageChange(1);
  }, []);

  const handlePageChange = (page: number) => {
    dispatch(fetchHistory({ schoolProductId, page }));
    smoothScrollTo(0, 0);
  };

  return (
    <div className="SchoolProductHistoryTab">
      {isLoadingHistory ? (
        <div data-testid="loader-wrapper">
          <Loader />
        </div>
      ) : (
        <div>
          <span>HISTÓRICO</span>
          <Table columns={table} data={history.historyData} />
          <Pagination
            onChange={handlePageChange}
            activePage={history.activePage}
            totalItemsCount={history.totalItemsCount}
            itemsCountPerPage={history.itemsCountPerPage}
          />
        </div>
      )}
    </div>
  );
};

export default tabify({
  title: 'Histórico',
  icon: 'history',
})(SchoolProductHistoryTab);
