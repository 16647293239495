import React from 'react';

import FilterContainer, {
  DoubleInputsWrapper,
} from 'components/FilterContainer';

import Field from 'components/Form/Field';
import Search from 'components/Form/Search';

import orderByFlag from 'core/utils/orderByFlag';
import {
  ORDER_STATUSES,
  PAYMENT_METHODS_BY_SCHOOL_PRODUCT,
} from 'core/constants/index';

import './style.scss';

import { SchoolProductOrdersFilterPros } from './types';

const SchoolProductOrdersFilter = ({
  filters,
  schoolProduct: {
    attributes: { classrooms },
  },
  handleFilterSubmit,
}: SchoolProductOrdersFilterPros): React.ReactElement => {
  const getFormattedClassrooms = () => {
    const formattedClassrooms = classrooms.map((classroom) => ({
      label: classroom.name,
      value: classroom.id,
    }));

    orderByFlag(formattedClassrooms, 'label');

    formattedClassrooms.unshift({ value: null, label: 'Todas as turmas' });

    return formattedClassrooms;
  };

  const form = {
    ...filters,
  };

  const formMeta = {
    select_options: {
      classroom_id: getFormattedClassrooms(),
      order_status: ORDER_STATUSES,
      payment_method: PAYMENT_METHODS_BY_SCHOOL_PRODUCT,
    },
  };

  return (
    <FilterContainer
      form={form}
      formMeta={formMeta}
      testID="schoolProductOrdersFilter"
    >
      <DoubleInputsWrapper>
        <Field
          label="Método de pagamento"
          testID="paymentMethod"
          attributeName="payment_method"
          type="selectWithIcon"
          value={form.payment_method}
          options={formMeta.select_options.payment_method}
          onChange={(e) => handleFilterSubmit({ payment_method: e.value })}
          fullWidth
          withArrowSelect
        />
        <Field
          label="Situação"
          testID="orderStatus"
          attributeName="order_status"
          type="selectWithIcon"
          value={form.order_status}
          options={formMeta.select_options.order_status}
          onChange={(e) => handleFilterSubmit({ order_status: e.value })}
          fullWidth
          withArrowSelect
        />
      </DoubleInputsWrapper>
      <DoubleInputsWrapper>
        <Field
          label="Turmas"
          testID="classroomId"
          attributeName="classroom_id"
          type="selectWithIcon"
          value={form.classroom_id}
          options={formMeta.select_options.classroom_id}
          onChange={(e) => handleFilterSubmit({ classroom_id: e.value })}
          fullWidth
          withArrowSelect
        />
        <Search
          label="Buscar"
          testID="searchInput"
          placeholder="Digite o nome do aluno"
          attributeName="name"
          onSubmit={(params) => handleFilterSubmit({ name: params.name })}
        />
      </DoubleInputsWrapper>
    </FilterContainer>
  );
};

export default SchoolProductOrdersFilter;
