import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autobind from 'autobind-decorator';

import withAppContext from 'core/hoc/withAppContext';

import './style.scss';

class FormCheckbox extends Component {
  static propTypes = {
    appContext: PropTypes.shape({
      primaryColor: PropTypes.string.isRequired,
    }).isRequired,
    disabled: PropTypes.bool,
    checked: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    checked: false,
    disabled: false,
  };

  spanCheckedStyle = {
    backgroundColor: this.props.appContext.primaryColor,
    borderColor: this.props.appContext.primaryColor,
  };

  @autobind
  onChange(event) {
    if (this.props.disabled) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }

    this.props.onChange();
  }

  getSpanStyle() {
    const { checked, disabled } = this.props;

    return checked && !disabled ? this.spanCheckedStyle : null;
  }

  render() {
    const { disabled, checked } = this.props;

    return (
      <div className={`Checkbox ${disabled ? 'disabled' : ''}`}>
        <div className="checkbox-container">
          <label>
            <input
              type="checkbox"
              checked={checked || undefined}
              disabled={disabled || undefined}
              onChange={this.onChange}
              className="ag-checkbox"
            />
            <span
              className={`checkmark ${checked ? 'checked' : ''}`}
              style={this.getSpanStyle()}
            />
          </label>
        </div>
      </div>
    );
  }
}

export default withAppContext(FormCheckbox);
