import React, { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Button, TextField } from '@agendaedu/ae-web-components';
import { Modal } from 'components/Handouts/Modal';

import omniChannelActions from 'store/messages/omniChannel/actions';
import { OmniChannelState } from 'store/messages/omniChannel/types';

import * as S from './styles';

export const DeleteChannelModal = (): JSX.Element => {
  const { t } = useTranslation(['messages']);
  const dispatch = useDispatch();

  const [confirmSubmitText, setConfirmSubmitText] = useState('');

  const { activeChannel, showDeleteChannelModal } = useSelector(
    (state: OmniChannelState) => state.omniChannel
  );

  const isDisabled =
    confirmSubmitText !==
    t('omni_channel.modals.delete_channel.input_placeholder');

  const { setDeleteChannelRequest, toggleDeleteChannelModal } =
    omniChannelActions;

  const handleToggleDeleteChannelModal = useCallback(() => {
    dispatch(toggleDeleteChannelModal());
  }, [dispatch, toggleDeleteChannelModal]);

  const handleSetDeleteChannel = useCallback(
    (channelId) => {
      dispatch(setDeleteChannelRequest(channelId));
      setConfirmSubmitText('');
      handleToggleDeleteChannelModal();
    },
    [dispatch, setDeleteChannelRequest, handleToggleDeleteChannelModal]
  );

  return (
    <>
      {showDeleteChannelModal && activeChannel && (
        <Modal.Root
          dataTestId="omni-delete-channel"
          isOpen={showDeleteChannelModal}
          title={t('omni_channel.modals.delete_channel.title')}
          handleToggleModal={handleToggleDeleteChannelModal}
        >
          <Modal.Content>
            <Modal.Content>
              <S.InforWrapper>
                {t('omni_channel.modals.delete_channel.description', {
                  name: activeChannel.name,
                })}
                <TextField
                  id="input-delete-chanel-confirmation"
                  label={t('omni_channel.modals.delete_channel.input_label')}
                  fullWidth
                  placeholder={t(
                    'omni_channel.modals.delete_channel.input_placeholder'
                  )}
                  value={confirmSubmitText}
                  onChange={(event) =>
                    setConfirmSubmitText(event.currentTarget.value)
                  }
                />
              </S.InforWrapper>
            </Modal.Content>
            <Modal.Actions>
              <Button
                testId="cancel-delete-button"
                variant="secondary"
                onClick={handleToggleDeleteChannelModal}
              >
                {t('omni_channel.modals.delete_channel.button_cancel')}
              </Button>

              <Button
                testId="submit-delete-button"
                isNegativeAction
                disabled={isDisabled}
                onClick={() => handleSetDeleteChannel(activeChannel.id)}
              >
                {t('omni_channel.modals.delete_channel.button_submit')}
              </Button>
            </Modal.Actions>
          </Modal.Content>
        </Modal.Root>
      )}
    </>
  );
};
