import React from 'react';
import Accordion from 'components/Accordion';
import { trackEvent } from 'config/amplitude';

import * as S from './styles';
import { AccordionDetailsProps } from './types';

const AccordionDetails: React.FC<AccordionDetailsProps> = ({
  children,
  title,
  integrationID,
  expandClick,
  ...props
}) => {
  const renderAccordionTitle = () => {
    return (
      <S.HeaderWrapper data-testid="headerWrapper">
        <S.InformationsWrapper>
          <S.TitleWrapper>
            <S.HeaderText>{title}</S.HeaderText>
          </S.TitleWrapper>
        </S.InformationsWrapper>
      </S.HeaderWrapper>
    );
  };

  return (
    <S.Wrapper
      data-testid="accordion-details-integrations-monitor"
      onClick={() => {
        trackEvent(`integrations_monitor_access_configuration`, {
          integrationID: integrationID,
        });
        expandClick();
      }}
    >
      <Accordion
        {...props}
        allowZeroExpanded
        variation="rightButton"
        header={renderAccordionTitle()}
        body={children}
      />
    </S.Wrapper>
  );
};

export default AccordionDetails;
