import styled, { css } from 'styled-components';

import { ThemeProps } from 'config/themes/types';

export const InforWrapper = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    flex-direction: column;
    gap: ${space.xl};
  `}
`;
