import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  buildToast,
  toastCss,
  ToastTypes,
} from 'store/middlewares/toast/actions';

import { fetchApi, putApi } from 'core/utils/api';

import actions from './actions';

function* fetchRolePermissions() {
  try {
    const dataArea = yield select((state) => state.root.dataArea);

    const { data } = yield call(fetchApi, `/${dataArea}/role_permissions.json`);

    yield put({ type: actions.FETCH_SUCCESS, data });
  } catch (error) {
    yield put({
      type: actions.FETCH_ERROR,
      toast: buildToast(
        'Não foi possível obter a listagem de perfil de permissões.',
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
      error,
    });
  }
}

function* updatePermission(action) {
  try {
    const { id } = action.currentRoleSave;
    const dataArea = yield select((state) => state.root.dataArea);

    const { data } = yield call(
      putApi,
      `/${dataArea}/role_permissions/${id}`,
      action.currentRoleSave.attributes
    );

    yield put({
      type: actions.UPDATE_PERMISSION_SUCCESS,
      data,
      toast: buildToast(
        'Perfil de permissão atualizado com sucesso!',
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.FETCH_ERROR,
      toast: buildToast(
        'Erro ao atualizar perfil de permissão.',
        ToastTypes.error
      ),
      error,
    });
  }
}

function* permissionSagas() {
  yield takeLatest(actions.FETCH_REQUEST, fetchRolePermissions);
  yield takeLatest(actions.UPDATE_PERMISSION_REQUEST, updatePermission);
}

export default permissionSagas;
