import React from 'react';
import PropTypes from 'prop-types';
import { CookiesProvider } from 'react-cookie';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import InternalErrorBoundary from 'boundaries/InternalErrorBoundary';
import composeFunctions from 'core/utils/composeFunctions';
import withAppContext from 'core/hoc/withAppContext';
import reduxProvider from 'core/hoc/reduxProvider';

import DynamicModule from 'screens/DynamicModule';
import DynamicView from 'pages/home/DynamicApps';

// eslint-disable-next-line react/prop-types
const DynamicModuleRoutes = ({ appContext: { dataArea } }) => {
  return (
    <InternalErrorBoundary>
      <div className="DynamicModuleMainsScreen">
        <CookiesProvider>
          <BrowserRouter>
            <Switch>
              <Route
                exact
                path={`/${dataArea}`}
                render={(props) => <DynamicView {...props} />}
              />
              <Route
                exact
                path="/*/dynamic_modules/:id"
                render={(props) => <DynamicModule {...props} />}
              />
            </Switch>
          </BrowserRouter>
        </CookiesProvider>
      </div>
    </InternalErrorBoundary>
  );
};

DynamicModuleRoutes.propTypes = {
  module: PropTypes.string,
};

export default composeFunctions(
  withAppContext,
  reduxProvider
)(DynamicModuleRoutes);
