/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React, { useState, useRef, useEffect, useCallback } from 'react';
import ReactToPrint, { useReactToPrint, onAfterPrint } from 'react-to-print';

const PrintElement = ({ elementToPrint, isShowPrint, onDismiss }) => {
  const toPrintRef = useRef(null);

  const onBeforeGetContentResolve = useRef(null);

  const [isLoading, setIsLoading] = useState(false);

  const handleAfterPrint = useCallback(() => {
    onDismiss();
  }, []);

  const handleBeforePrint = useCallback(() => {}, []);

  const handleOnBeforeGetContent = useCallback(() => {
    setIsLoading(true);

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setIsLoading(false);
        resolve();
      }, 800);
    });
  }, [setIsLoading]);

  const reactToPrintContent = useCallback(() => {
    return toPrintRef.current;
  }, [elementToPrint]);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: 'arquivo',
    // onBeforeGetContent: handleOnBeforeGetContent,
    // onBeforePrint: handleBeforePrint,
    onAfterPrint: handleAfterPrint,
    removeAfterPrint: true,
  });

  useEffect(() => {
    isShowPrint && handlePrint();
  }, [isShowPrint]);

  return <div ref={toPrintRef}>{elementToPrint}</div>;
};

export default PrintElement;
