import React, { Component } from 'react';
import PropTypes from 'prop-types';

import withAppContext from 'core/hoc/withAppContext';

import './style.scss';

class MessagesOtherUsersChannelSidebarFilter extends Component {
  static propTypes = {
    appContext: PropTypes.shape({
      primaryColor: PropTypes.string.isRequired,
    }).isRequired,
    activeChannelType: PropTypes.oneOf(['family', 'private']),
    onFilterChannelClick: PropTypes.func.isRequired,
    familyCount: PropTypes.number.isRequired,
    privateCount: PropTypes.number.isRequire,
  };

  filters = ['private', 'family'];

  state = {
    activeChannelType: this.filters[0],
  };

  styleFilter = {
    buttonOutline: {
      color: this.props.appContext.primaryColor,
      borderColor: this.props.appContext.primaryColor,
    },
    badgeOutline: {
      color: 'white',
      backgroundColor: this.props.appContext.primaryColor,
      borderColor: this.props.appContext.primaryColor,
    },
    buttonActive: {
      color: 'white',
      backgroundColor: this.props.appContext.primaryColor,
    },
    badgeActive: {
      color: this.props.appContext.primaryColor,
      backgroundColor: 'white',
      borderColor: this.props.appContext.primaryColor,
    },
  };

  onFilterChannelClick(typeName) {
    this.setState({ activeChannelType: typeName });
    this.props.onFilterChannelClick(typeName);
  }

  isFilterSelected(typeName) {
    return typeName === this.state.activeChannelType;
  }

  render() {
    const { buttonOutline, badgeOutline, buttonActive, badgeActive } =
      this.styleFilter;

    const { activeChannelType } = this.state;

    const { familyCount, privateCount } = this.props;

    const buttonClassName =
      activeChannelType === 'private' ? 'btn-primary' : 'btn-outline';

    return (
      <div className="MessagesOtherUsersChannelSidebarFilter">
        <div className="btn-group" role="group">
          <button
            type="button"
            className={`btn ${buttonClassName}`}
            onClick={() => this.onFilterChannelClick('private')}
            style={
              this.isFilterSelected('private') ? buttonActive : buttonOutline
            }
          >
            <strong>Privado</strong>
            {privateCount > 0 && (
              /* TODO: Use the badge component here. */
              <span
                className="badge"
                style={
                  this.isFilterSelected('private') ? badgeActive : badgeOutline
                }
              >
                {privateCount}
              </span>
            )}
          </button>
          <button
            type="button"
            className={`btn ${
              activeChannelType == 'family' ? 'btn-primary' : 'btn-outline'
            }`}
            onClick={() => this.onFilterChannelClick('family')}
            style={
              this.isFilterSelected('family') ? buttonActive : buttonOutline
            }
          >
            Família
            {familyCount > 0 && (
              /* TODO: Use the badge component here. */
              <span
                className="badge"
                style={
                  this.isFilterSelected('family') ? badgeActive : badgeOutline
                }
              >
                {familyCount}
              </span>
            )}
          </button>
        </div>
      </div>
    );
  }
}

export default withAppContext(MessagesOtherUsersChannelSidebarFilter);
