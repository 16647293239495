import React, { useContext } from 'react';

import Card from '../';
import QuantityCard from '../QuantityCard';
import DailySummariesContext from 'core/contexts/DailySummaries';

const Diaper = () => {
  const {
    form: { diaper },
    handleForm,
    isDisabled,
    hasStudentSelected,
  } = useContext(DailySummariesContext);

  const handleDecrement = () => {
    if (!isDisabled && diaper > 0) handleForm('diaper', diaper - 1);
  };

  const handleIncrement = () => {
    if (!isDisabled) handleForm('diaper', diaper + 1);
  };

  const disableCard = !hasStudentSelected || isDisabled;

  return (
    <Card title="Fralda">
      <QuantityCard
        label="Indique a quantidade"
        onDecrement={handleDecrement}
        onIncrement={handleIncrement}
        quantity={diaper}
        disabled={disableCard}
      />
    </Card>
  );
};

export default Diaper;
