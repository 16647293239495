import React from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Breadcrumb from 'components/Breadcrumb';
import PageContainer from 'components/PageContainer';
import Tabs from 'components/Tabs';
import Tab from 'components/Tab';
import Toast from 'components/Toast';

import InformationsTab from './InformationsTab';
import AnswersPollTab from './AnswersPollTab';
import AnswersAuthorizationTab from './AnswersAuthorizationTab';

import './style.scss';

const SurveyDetails = ({ surveyId }) => {
  const { t } = useTranslation('surveys');

  const { dataArea } = useSelector((state) => state.root);
  const { selectedSurvey } = useSelector((state) => state.surveys);

  const currentAnswerTab =
    selectedSurvey?.attributes?.kind &&
    {
      poll: <AnswersPollTab surveyId={surveyId} />,
      authorization: <AnswersAuthorizationTab surveyId={surveyId} />,
    }[selectedSurvey?.attributes?.kind];

  return (
    <PageContainer
      title={t('details.page_title')}
      breadcrumb={
        <Breadcrumb
          title={t('details.breadcrumb_title')}
          path={`/${dataArea}/surveys`}
        />
      }
    >
      <Tabs>
        <Tab title={t('details.tab_information')}>
          <InformationsTab surveyId={surveyId} />
        </Tab>
        <Tab title={t('details.tab_answers')}>{currentAnswerTab}</Tab>
      </Tabs>
      <Toast />
    </PageContainer>
  );
};

export default withRouter(SurveyDetails);
