import React from 'react';
import {
  Grid,
  DefaultThemeProps,
  theme,
  Box,
} from '@agendaedu/ae-web-components';

import Skeleton from 'components/Skeleton';

import * as S from './styles';

export const HandoutTemplatesSkeleton = () => {
  const defaultTheme: DefaultThemeProps = theme;

  const {
    colors: { neutral },
    border: { radius },
  } = defaultTheme;

  return (
    <S.HandoutTemplateSkeleton data-testid="handout-template-skeleton">
      <Grid
        gridTemplateColumns="1fr"
        gutter={{
          _: 'lg',
        }}
      >
        <Grid item gutter="sm">
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Box>
              <Skeleton
                width={102}
                height={14}
                primaryColor={neutral.gray2}
                borderRadius={radius.md}
              />

              <Box mt={12}>
                <Skeleton
                  width={103}
                  height={21}
                  primaryColor={neutral.gray2}
                  borderRadius={radius.md}
                />
              </Box>
            </Box>
            <Box mt={10}>
              <Skeleton
                width={136}
                height={41}
                primaryColor={neutral.gray2}
                borderRadius={radius.md}
              />
            </Box>
          </Box>
        </Grid>

        <Grid item mt={22}>
          <S.TabsSkeleton>
            <S.TabsHeaderSkeleton>
              <S.TabSkeleton isSelected>
                <Skeleton
                  width={66}
                  height={21}
                  primaryColor={neutral.gray3}
                  borderRadius={radius.md}
                />
              </S.TabSkeleton>
            </S.TabsHeaderSkeleton>

            <Box
              flexWrap={'wrap'}
              display={'flex'}
              flexDirection={'row'}
              py={{ _: 'xl2' }}
              px={{ _: 'sm', desktopLG: 'xl2' }}
            >
              <S.TemplateCardListWrapper>
                <Skeleton
                  width={164}
                  height={171}
                  primaryColor={neutral.gray4}
                  borderRadius={radius.md}
                />

                <Skeleton
                  width={164}
                  height={171}
                  primaryColor={neutral.gray4}
                  borderRadius={radius.md}
                />

                <Skeleton
                  width={164}
                  height={171}
                  primaryColor={neutral.gray4}
                  borderRadius={radius.md}
                />

                <Skeleton
                  width={164}
                  height={171}
                  primaryColor={neutral.gray4}
                  borderRadius={radius.md}
                />

                <Skeleton
                  width={164}
                  height={171}
                  primaryColor={neutral.gray4}
                  borderRadius={radius.md}
                />

                <Skeleton
                  width={164}
                  height={171}
                  primaryColor={neutral.gray4}
                  borderRadius={radius.md}
                />

                <Skeleton
                  width={164}
                  height={171}
                  primaryColor={neutral.gray4}
                  borderRadius={radius.md}
                />

                <Skeleton
                  width={164}
                  height={171}
                  primaryColor={neutral.gray4}
                  borderRadius={radius.md}
                />

                <Skeleton
                  width={164}
                  height={171}
                  primaryColor={neutral.gray4}
                  borderRadius={radius.md}
                />

                <Skeleton
                  width={164}
                  height={171}
                  primaryColor={neutral.gray4}
                  borderRadius={radius.md}
                />
              </S.TemplateCardListWrapper>
            </Box>
          </S.TabsSkeleton>
        </Grid>
      </Grid>
    </S.HandoutTemplateSkeleton>
  );
};
