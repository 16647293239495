import styled, { css } from 'styled-components';
import { ThemeProps } from 'config/themes/types';

import { Grid } from '@agendaedu/ae-web-components';

export const WrapperAccordion = styled.div`
  ${({ theme: { colors, border, space } }: ThemeProps) => css`
    .Accordion .accordion__button {
      background-color: ${colors.neutral.white};
      padding: ${space.sm} ${space.sm} ${space.sm} ${space.lg};
      border: ${border.width.sm} solid ${colors.neutral.gray3};
      border-radius: ${border.radius.md};
      margin-bottom: ${space.sm};
      height: min-content;
      pointer-events: none;

      &[aria-expanded='true'] {
        border-bottom: none;
      }

      &::before {
        display: none;
      }

      &:hover {
        cursor: default;
      }
    }

    .Accordion .accordion__panel {
      padding: ${space.xs2} ${space.xs2} ${space.xs2} ${space.sm};
      border: ${border.width.sm} solid ${colors.neutral.gray3};
      border-radius: 0 0 ${border.radius.md} ${border.radius.md};
      border-top: none;
    }
  `}
`;

export const WrapperHeaderAccordion = styled(Grid)`
  width: 100%;
  grid-template-areas: 'date date date transferred transferred transferred provider provider provider value value status';
  align-items: center;
  margin-bottom: 0;
`;
