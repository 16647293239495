import styled from 'styled-components';

export const AccordionTitle = styled.p`
  font-family: Quicksand;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  margin-bottom: 0px;
  color: ${(props) => props.theme.black};
`;
