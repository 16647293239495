import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import AgendaIcon from '../../AgendaIcon';
import ButtonRow from '../../ButtonRow';
import Button from '../../Button';
import DropdownButton from '../../DropdownButton';

import './style.scss';

const TabActionButtons = ({
  infoId,
  toggleModal,
  permissions,
  history,
  status,
}) => {
  const canResend = () =>
    permissions?.can_resend && ['sent', 'updated'].includes(status);
  const canApprove = () =>
    permissions?.can_approve && ['pending'].includes(status);

  const canDisapprove = () =>
    permissions?.can_reject && ['pending', 'pending_schedule'].includes(status);

  const canEdit = () =>
    permissions?.can_edit &&
    [
      'pending',
      'rejected',
      'approved',
      'pending_schedule',
      'sent',
      'updated',
    ].includes(status);

  const canDelete = () =>
    permissions?.can_destroy &&
    [
      'pending',
      'opened',
      'rejected',
      'approved',
      'scheduled',
      'sent',
      'updated',
      'pending',
      'pending_schedule',
    ].includes(status);

  const canSchedule = () =>
    permissions?.can_schedule && ['pending', 'rejected'].includes(status);

  const canReschedule = () =>
    permissions?.can_reschedule &&
    ['scheduled', 'pending_schedule'].includes(status);

  const editAction = () =>
    history.push(`/schools/summaryday_classrooms/${infoId}/edit`);

  const canApprovechedule = () =>
    permissions?.can_approve_schedule && ['pending_schedule'].includes(status);

  const actionButtons = () => {
    return (
      <section className="section-action-buttons">
        <ButtonRow>
          {canResend() && (
            <Button
              type="button"
              variation="secondary"
              className="tab-action-button"
              onClick={() => toggleModal('resend')}
            >
              Reenviar
            </Button>
          )}
          {canApprove() && (
            <Button
              type="button"
              variation="primary"
              className="tab-action-button"
              onClick={() => toggleModal('approve')}
            >
              Aprovar
            </Button>
          )}
          {canApprovechedule() && (
            <Button
              type="button"
              variation="primary"
              className="tab-action-button"
              onClick={() => toggleModal('approveChedule')}
            >
              Aprovar agendamento
            </Button>
          )}

          {canSchedule() && (
            <Button
              type="button"
              variation="secondary"
              className="tab-action-button"
              onClick={() => toggleModal('schedule')}
            >
              Agendar
            </Button>
          )}
          {canReschedule() && (
            <Button
              type="button"
              variation="primary"
              className="tab-action-button"
              onClick={() => toggleModal('reschedule')}
            >
              Reagendar
            </Button>
          )}
          {canDisapprove() && (
            <Button
              type="button"
              variation="secondary"
              className="tab-action-button"
              onClick={() => toggleModal('disapprove')}
            >
              Reprovar
            </Button>
          )}
          {canEdit() && (
            <Button
              onClick={editAction}
              type="button"
              className="custom-tab-action-button"
              variation="secondary"
            >
              <AgendaIcon name="edit" size="medium" />
            </Button>
          )}
        </ButtonRow>
        {canDelete() && (
          <Button
            type="button"
            variation="danger"
            className="custom-tab-action-button"
            onClick={() => toggleModal('delete')}
          >
            <AgendaIcon name="trash" size="medium" />
          </Button>
        )}
      </section>
    );
  };

  const mobileActions = () => {
    let actions = [];
    if (canResend())
      actions.push({ text: 'Reenviar', onClick: () => toggleModal('resend') });
    if (canApprove())
      actions.push({ text: 'Aprovar', onClick: () => toggleModal('approve') });
    if (canApprovechedule())
      actions.push({
        text: 'Aprovar agendamento',
        onClick: () => toggleModal('approveChedule'),
      });
    if (canDisapprove())
      actions.push({
        text: 'Reprovar',
        onClick: () => toggleModal('disapprove'),
      });
    if (canSchedule())
      actions.push({
        text: 'Agendar',
        onClick: () => toggleModal('schedule'),
      });
    if (canReschedule())
      actions.push({
        text: 'Reagendar',
        onClick: () => toggleModal('reschedule'),
      });
    if (canEdit())
      actions.push({ text: 'Editar', onClick: () => editAction() });
    if (canDelete())
      actions.push({ text: 'Excluir', onClick: () => toggleModal('delete') });
    return actions;
  };

  return (
    <>
      {actionButtons()}
      <section className="mobile-section-action-buttons">
        <DropdownButton
          text=""
          dropdownItems={mobileActions()}
          icon="dots-vertical"
          variation="white"
          direction="up"
        />
      </section>
    </>
  );
};

TabActionButtons.propTypes = {
  infoId: PropTypes.string,
  data: PropTypes.object,
  permissions: PropTypes.object,
  toggleModal: PropTypes.func,
  history: PropTypes.object,
  status: PropTypes.string,
};

export default withRouter(TabActionButtons);
