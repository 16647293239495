import React, { lazy, Suspense, useCallback, useContext, useMemo } from 'react';

import {
  Alert,
  Box,
  Button,
  DefaultThemeProps,
  Text,
} from '@agendaedu/ae-web-components';

import RecipientsTab from 'components/Form/RecipientsTab';
import DiaryActions from 'store/dailySummaries/actions';

import { DiaryState } from 'store/dailySummaries/types';
import { useDispatch, useSelector } from 'react-redux';
import { DiarySectionContext } from 'core/contexts/DiarySections';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { withRouter } from 'react-router';
import { RecipientsSkeleton } from './skeleton';
import { Props } from './types';

const RecipientsStep = ({ history: { replace }, match }: Props) => {
  const { id: diarySectionId } = match?.params;

  const { saveSectionRequest } = DiaryActions;
  const dispatch = useDispatch();
  const { t } = useTranslation(['diary_sections', 'common']);
  const { breakpoints, colors } = useTheme() as DefaultThemeProps;

  const {
    diarySections: {
      isSubmittingSection,
      diarySectionData,
      recipients: { initialAttributes, formMeta },
    },
  } = useSelector((state: DiaryState) => state.dailySummaries);
  const {
    form: {
      values,
      values: { classroomIds },
      setFieldValue,
    },
    setCurrentStep,
  } = useContext(DiarySectionContext);

  const tBase = useCallback(
    (key: string, params?: Record<string, unknown>) =>
      t(`sections.form.recipients_tab.${key}`, params),
    [t]
  );

  const redirectOnSuccess = () => replace('/schools/diary_sections');

  const isDefaultSection = diarySectionData?.attributes?.slug;

  const saveSectionForm = () => {
    dispatch(
      saveSectionRequest({
        diarySectionId,
        diarySection: values,
        onSuccess: redirectOnSuccess,
      })
    );
  };

  const header = (
    <Box mx="sm" my="lg">
      {isDefaultSection && (
        <Alert mb="lg" variant="informative">
          {tBase('info')}
        </Alert>
      )}

      <Text variant="title-bold-20" color="neutral.black">
        {tBase('title')}
      </Text>

      <Text variant="label-regular-14" mt="lg">
        {tBase('subtitle')}
      </Text>
    </Box>
  );

  const footer = (
    <Box
      py="sm"
      position="fixed"
      display="flex"
      alignItems="center"
      left={0}
      right={0}
      bottom={0}
      px={{ _: 'lg', mobileXS: 'xs2', desktopLG: 'xl5' }}
      backgroundColor={colors.neutral.white}
    >
      {!isDefaultSection && (
        <Button variant="secondary" onClick={() => setCurrentStep(1)}>
          {t('common:button.back')}
        </Button>
      )}
      <Button
        testId="save-section"
        ml="auto"
        disabled={!classroomIds.length || isSubmittingSection}
        onClick={saveSectionForm}
      >
        {t('common:button.save')}
      </Button>
    </Box>
  );

  const memoizedFormMetaClassrooms = useMemo(
    () => formMeta.classrooms,
    [formMeta]
  );

  const FormContainer = useMemo(
    () => lazy(() => import('components/Form/FormContainer')),
    [diarySectionData, memoizedFormMetaClassrooms]
  );

  return (
    <Box mx="auto" pb="20vh" overflow="auto">
      <Box
        display="flex"
        flex={1}
        flexDirection="column"
        position="relative"
        mx={{ _: 'lg', mobileXS: 'xs2', desktopLG: 'xl5' }}
      >
        <Box maxWidth={breakpoints.tabletMD} mx="auto" mt="lg" overflow="auto">
          {header}
          <Suspense fallback={<RecipientsSkeleton />}>
            <FormContainer
              onUpdateAttribute={(formContainerValues) =>
                setFieldValue('classroomIds', formContainerValues.classroom_ids)
              }
              initialAttributes={{
                ...initialAttributes,
                classroom_ids: classroomIds,
              }}
              formMeta={formMeta}
            >
              <RecipientsTab />
            </FormContainer>
          </Suspense>
        </Box>

        {footer}
      </Box>
    </Box>
  );
};

export default withRouter(RecipientsStep);
