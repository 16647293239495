import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import PageContainer from 'components/PageContainer';
import Breadcrumb from 'components/Breadcrumb';
import EventForm from 'components/Events/EventForm';
import Loader from 'components/Loader';
import Toast from 'components/Toast';
import ModalContainer from 'components/Modal/ModalContainer';
import ModalTitle from 'components/Modal/ModalTitle';
import ModalButtons from 'components/Modal/ModalButtons';
import EventActions from 'store/events/actions';
import EventContext from '../eventContext';

import normalizeParams from '../normalizeParams';

import useGoogleDrive from 'core/hooks/useGoogleDrive';

const EditEvent = ({ googleClientId, match }) => {
  const { id } = match.params;

  const dispatch = useDispatch();

  const { setEvent, editEvent } = EventActions;
  const form = useSelector((state) => state.events.current);
  const formMeta = useSelector((state) => state.events.formMeta);
  const isLoading = useSelector((state) => state.events.isLoading);
  const sending = useSelector((state) => state.events.sending);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [notify, setNotify] = useState(false);
  const [actualFormContext, setActualFormContext] = useState();
  const [canSubmit, setCanSubmit] = useState(false);
  const SCOPE =
    'https://www.googleapis.com/auth/calendar.readonly https://www.googleapis.com/auth/calendar.events';

  const fetchEvent = useCallback(() => {
    dispatch(setEvent(id));
  }, [dispatch, setEvent]);

  const actions = useGoogleDrive(googleClientId, SCOPE);

  useEffect(() => {
    if (!!canSubmit && !!actualFormContext) {
      dispatch(
        editEvent(id, normalizeParams({ ...actualFormContext, notify }))
      );
    }
  }, [canSubmit, actualFormContext]);

  const onNotNotifying = () => {
    setIsConfirmModalOpen(false);
    setNotify(false);
    setCanSubmit(true);
  };

  const onSubmit = (formContext) => {
    const { notificateWhenEditing } = formContext.formMeta;

    setActualFormContext(formContext.form);

    if (notificateWhenEditing) {
      setIsConfirmModalOpen(true);
    } else {
      onNotNotifying();
    }
  };

  const closeModal = () => setIsConfirmModalOpen(false);

  const onDoNotify = () => {
    setIsConfirmModalOpen(false);
    setNotify(true);
    setCanSubmit(true);
  };

  const confirmModalButtons = () => [
    {
      text: 'Cancelar',
      variation: 'secondary',
      onClick: closeModal,
      path: '#',
    },
    {
      text: 'Não notificar',
      variation: 'secondary',
      onClick: onNotNotifying,
      path: '#',
    },
    {
      text: 'Notificar',
      onClick: onDoNotify,
      path: '#',
    },
  ];

  useEffect(() => {
    fetchEvent();
  }, []);

  return (
    <PageContainer
      title="Editar evento"
      classrooms="EditEvent"
      breadcrumb={
        <Breadcrumb title="Eventos" path="/schools/events" external />
      }
    >
      <Loader isLoading={isLoading}>
        <EventContext.Provider value={actions}>
          <EventForm
            action="edit"
            form={form}
            formMeta={{ googleClientId, ...formMeta }}
            onSubmit={onSubmit}
            isSubmitting={sending}
          />
        </EventContext.Provider>

        {formMeta.notificateWhenEditing && (
          <ModalContainer
            isOpen={isConfirmModalOpen}
            toggleModal={closeModal}
            maxWidth="475px"
          >
            <ModalTitle>Deseja notificar a atualização?</ModalTitle>

            <div>
              Os responsáveis e alunos receberão uma notificação sobre a
              atualização das informações do evento.
            </div>

            <ModalButtons buttons={confirmModalButtons()} />
          </ModalContainer>
        )}

        <Toast />
      </Loader>
    </PageContainer>
  );
};

EditEvent.propTypes = {
  googleClientId: PropTypes.string.isRequired,
  match: PropTypes.object.isRequired,
};

export default EditEvent;
