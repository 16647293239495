import validatePresence from 'core/lib/FormValidator/validators/presence';
import validateNumericalFloat from 'core/lib/FormValidator/validators/numericalFloat';
import validateArrayPresence from 'core/lib/FormValidator/validators/array/presence';
import validateLength from 'core/lib/FormValidator/validators/length';
import {
  validateGreaterThanZero,
  validateSameDay,
  validatePresenceDay,
  validateMaxDay,
} from 'core/utils/edupay/validations/discounts';

export const validateExpiresAt = (_input, state) => {
  let result = true;

  // check with == because it's valid for null and undefined
  result = !(state.expires_at == undefined || state.expires_at == '');

  return result;
};

export const validateLegacyPresence = (_input, state) => {
  let result = true;

  result = !(state.school_product_legacy_id && state.legacy_id == '');

  return result;
};

export const validatesDiscount = (key) => [
  {
    attributeName: key,
    validator: validateGreaterThanZero,
    message: 'O valor tem que ser maior que zero',
  },
  {
    attributeName: key,
    validator: validateSameDay,
    message: 'Já existe um desconto para esta data.',
  },
  {
    attributeName: key,
    validator: validatePresenceDay,
    message: 'Campo dias para vencimento é obrigatório',
  },
  {
    attributeName: key,
    validator: validateMaxDay,
    message: 'Valor máximo para o campo dias para vencimento é 365',
  },
];

export default [
  {
    attributeName: 'title',
    validator: validatePresence,
    message: 'Campo obrigatório',
  },
  {
    attributeName: 'legacy_id',
    validator: validateLegacyPresence,
    message: 'Campo obrigatório',
  },
  {
    attributeName: 'legacy_id',
    validator: validateLength({ max: 50 }),
    message: 'Máximo: 50 caracteres',
  },
  {
    attributeName: 'title',
    validator: validateLength({ max: 150 }),
    message: 'Máximo: 150 caracteres',
  },
  {
    attributeName: 'price',
    validator: validateNumericalFloat({ min: 10.0 }),
    message: 'Valor mínimo R$ 10,00',
  },
  {
    attributeName: 'expires_at',
    validator: validateExpiresAt,
    message: 'Escolha uma data de vencimento.',
  },
  {
    attributeName: 'description',
    validator: validatePresence,
    message: 'Campo obrigatório',
  },
  {
    attributeName: 'allowed_payment_method',
    validator: validateArrayPresence,
    message: 'Selecione um método de pagamento para continuar',
  },
  {
    attributeName: 'recipient_wallet_id',
    validator: validatePresence,
    message: 'Campo obrigatório',
  },
];
