import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { fetchApi } from 'core/utils/api';

import actions from './actions';

function* fetchHeadquartersRequest() {
  try {
    const { dataArea } = yield select((state) => state.root);

    const { data: headquarters } = yield call(
      fetchApi,
      `/${dataArea}/headquarters/with_active_school_term`
    );

    yield put({
      type: actions.FETCH_HEADQUARTERS_SUCCESS,
      headquarters,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
    });
  }
}

export function* fetchEducationalStagesRequest({ headquarterId }) {
  try {
    const { data } = yield call(
      fetchApi,
      `/schools/headquarters/${headquarterId}/educational_stages`
    );

    yield put({
      type: actions.FETCH_EDUCATIONAL_STAGES_SUCCESS,
      educationalStages: data,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
    });
  }
}

function* fetchClassRoomsRequest({ headquarterId, educationalStageId }) {
  try {
    const { data } = yield call(
      fetchApi,
      `/schools/headquarters/${headquarterId}/${educationalStageId}/classrooms`
    );

    yield put({
      type: actions.FETCH_CLASSROOMS_SUCCESS,
      classrooms: data,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
    });
  }
}

function* fetchActiveSchoolTermsRequest() {
  try {
    const { data } = yield call(fetchApi, `/schools/school_terms.json`);

    yield put({
      type: actions.FETCH_ACTIVE_SCHOOL_TERMS_SUCCESS,
      activeSchoolTerm: data,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
    });
  }
}

function* enrollmentPlanSagas() {
  yield all([
    takeLatest(actions.FETCH_HEADQUARTERS_REQUEST, fetchHeadquartersRequest),
    takeLatest(
      actions.FETCH_EDUCATIONAL_STAGES_REQUEST,
      fetchEducationalStagesRequest
    ),
    takeLatest(actions.FETCH_CLASSROOMS_REQUEST, fetchClassRoomsRequest),
    takeLatest(
      actions.FETCH_ACTIVE_SCHOOL_TERMS_REQUEST,
      fetchActiveSchoolTermsRequest
    ),
  ]);
}

export default enrollmentPlanSagas;
