import styled, { css } from 'styled-components';
import { ThemeProps } from 'config/themes/types';

import { Button } from '@agendaedu/ae-web-components';

export const HistoryWrapper = styled.div`
  ${({ theme: { border, colors, space } }: ThemeProps) => css`
    display: flex;
    justify-content: space-between;
    padding: ${space.sm} 0;
    border-top: ${border.width.sm} ${border.style.solid} ${colors.neutral.gray5};
    border-bottom: ${border.width.sm} ${border.style.solid}
      ${colors.neutral.gray5};
  `}
`;

export const AvatarWrapper = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    align-items: center;
    gap: ${space.sm};
  `}
`;

export const LabelWrapper = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    flex-direction: column;
    gap: ${space.xs2};
  `}
`;

export const HistoryButton = styled(Button)`
  ${({ theme: { colors } }: ThemeProps) => css`
    display: flex;
    align-items: center;
    color: ${colors.brand.primary.default} !important;
    background-color: transparent !important;
    border: none !important;

    svg {
      path {
        fill: ${colors.brand.primary.default} !important;
      }
    }

    &:hover {
      background-color: ${colors.brand.primary.op10} !important;
    }
  `}
`;

export const UserHistoryWrapper = styled.div`
  ${({ theme: { border, colors, space } }: ThemeProps) => css`
    display: flex;
    flex-direction: column;
    min-width: 380px;
    max-height: calc(100dvh - 400px);
    overflow: auto;
    padding: 0 ${space.xs3};

    ${AvatarWrapper} {
      padding: ${space.sm} 0;
      border-bottom: ${border.width.sm} ${border.style.solid}
        ${colors.neutral.gray4};

      &:last-child {
        border-bottom: none;
      }
    }
  `}
`;
