import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import ActionButton from 'components/Messages/TS/Animations/ActionButton';

import withAppContext from 'core/hoc/withAppContext';

import MessagesBalloon from '../Balloon';
import MessagesInfo from '../Info';

import * as S from './styles';

class MessagesSentMessage extends PureComponent {
  constructor(props) {
    super(props);

    this.wrapperRef = React.createRef();
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
  }

  static propTypes = {
    appContext: PropTypes.shape({
      primaryColor: PropTypes.string.isRequired,
      currentUserType: PropTypes.string.isRequired,
      policies: PropTypes.object.isRequired,
    }).isRequired,
    channelKind: PropTypes.string,
    message: PropTypes.shape({
      id: PropTypes.string.isRequired,
      attributes: PropTypes.shape({
        type: PropTypes.string,
        canAccessRedirectedChat: PropTypes.bool,
        canUpdate: PropTypes.bool,
        approvalStatus: PropTypes.string,
        readStatus: PropTypes.bool,
        sentAt: PropTypes.string.isRequired,
        status: PropTypes.string,
        metadata: PropTypes.shape({
          anchoringData: PropTypes.shape({
            type: PropTypes.number,
            chatId: PropTypes.number,
            channelId: PropTypes.number,
          }),
        }),
      }),
    }),
    showActionMenu: PropTypes.bool,
    showApprovalInfo: PropTypes.bool,
    deleteMessage: PropTypes.func,
    editMessage: PropTypes.func,
    replyMessage: PropTypes.func,
    anchorMessage: PropTypes.func,
  };

  static defaultProps = {
    showApprovalInfo: false,
  };

  approvalStatusOptions = ['manually_approved', 'approved'];

  state = {
    isDeletingMessage: false,
    hasActions: false,
    isHovered: false,
  };

  deleteMessage = async (message) => {
    this.setState({ isDeletingMessage: true });
    await this.props.deleteMessage(message);
    this.setState({ isDeletingMessage: false });
  };

  editMessage = async (message) => {
    this.props.editMessage(message);
  };

  replyMessage = (message) => {
    this.props.replyMessage(message);
  };

  onMouseEnter = () => {
    this.setState({ isHovered: true });
  };

  onMouseLeave = () => {
    this.setState({ isHovered: false });
  };

  onToggle = () => {
    this.setState({ isOpen: true });
  };

  handleOutsideClick(event) {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.setState({ isOpen: false });
    }
  }

  componentDidMount() {
    document.addEventListener('click', this.handleOutsideClick);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleOutsideClick);
  }

  render() {
    const {
      message,
      showActionMenu,
      anchorMessage,
      showApprovalInfo,
      channelKind,
    } = this.props;

    const { isHovered, isOpen } = this.state;

    const {
      attributes: { approvalStatus, canUpdate, canAccessRedirectedChat },
    } = message;

    const showActionsItems = showActionMenu || canUpdate;

    const canReplyMessage = this.approvalStatusOptions.includes(approvalStatus);

    const canEditMessage = canUpdate && !canAccessRedirectedChat;

    const canDeleteMessage = showActionMenu;

    return (
      <S.SentMessageWrapper
        ref={this.wrapperRef}
        id={`message-${message.id}`}
        onMouseLeave={this.onMouseLeave}
      >
        <S.MessageBalloonWrapper
          onClick={this.onToggle}
          onMouseEnter={this.onMouseEnter}
        >
          <MessagesBalloon
            message={message}
            anchorMessage={anchorMessage}
            channelKind={channelKind}
          />

          {!!showActionsItems && (
            <S.ActionsWrapper isHovered={isHovered} isOpen={isOpen}>
              {canReplyMessage && (
                <ActionButton
                  name="reply"
                  handleOnClick={() => this.replyMessage(message)}
                />
              )}

              {canEditMessage && (
                <ActionButton
                  name="pencil"
                  handleOnClick={() => this.editMessage(message)}
                />
              )}

              {canDeleteMessage && (
                <ActionButton name="trash" handleOnClick={this.deleteMessage} />
              )}
            </S.ActionsWrapper>
          )}
        </S.MessageBalloonWrapper>
        <MessagesInfo message={message} showApprovalInfo={showApprovalInfo} />
      </S.SentMessageWrapper>
    );
  }
}

export default withAppContext(MessagesSentMessage);
