import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import tabifyForWizard from 'core/hoc/tabifyForWizard';
import withFormContext, { formPropTypes } from 'core/hoc/withFormContext';
import {
  theme,
  Box,
  Text,
  FlexGrid,
  FlexGridItem,
  Tag,
  Icon,
} from '@agendaedu/ae-web-components';
import moment from 'moment';
import * as S from './styles';

const InformationsTab = ({
  formContext: { form, formMeta, updateAttribute },
}) => {
  const { subscriptionsProduct } = formMeta;
  const { attributes } = subscriptionsProduct;
  const { classroom_ids, student_total, total_currency, contactInfo } = form;

  const { t } = useTranslation(['hub_store']);
  const today = new Date();

  const maskCurrency = (value) => {
    return value.toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      style: 'currency',
      currency: 'BRL',
    });
  };

  const {
    colors: { neutral, primaryColor },
  } = theme;

  useEffect(() => {
    updateAttribute('productName', attributes?.app?.data?.attributes?.name);
  }, []);

  return (
    <S.WrapperContainer>
      <div className="row">
        <FlexGrid>
          <FlexGridItem cols={{ desktopLG: 11 }} filled>
            <Box
              display="flex"
              flexDirection={{ _: 'column', desktopLG: 'row' }}
            >
              <Box
                mr={24}
                mb={{ _: 16, desktopLG: 0 }}
                height={120}
                width={120}
                minWidth={120}
                borderRadius={8}
                overflow="hidden"
              >
                <img
                  src={attributes.app.data.attributes.logo}
                  height="100%"
                  width="100%"
                  alt="Logo do app"
                />
              </Box>

              <Box>
                <Text
                  as="h2"
                  mt={0}
                  mb={24}
                  variant="headline-h2-bold-24"
                  fontWeight={700}
                  color={neutral.black}
                >
                  {attributes.title}
                </Text>

                <Box display="flex" alignItems="center" mb={16}>
                  {attributes.app.data.attributes.partner_name ===
                  'Agenda Edu' ? (
                    <Box display="flex" alignItems="center" mr={24}>
                      <Box width={24} height={24}>
                        <Icon name="user-group" color={primaryColor} />
                      </Box>
                      <Text
                        as="p"
                        m={0}
                        ml={8}
                        variant="subtitle-medium-14"
                        color="brand.primary.dark"
                      >
                        {t('created_by')}{' '}
                        <b>{attributes.app.data.attributes.partner_name}</b>
                      </Text>
                    </Box>
                  ) : (
                    <Box display="flex" alignItems="center" mr={24}>
                      <Box width={24} height={24}>
                        <Icon name="user-group" color={neutral.black} />
                      </Box>
                      <Text
                        as="p"
                        m={0}
                        ml={8}
                        variant="subtitle-medium-14"
                        color={neutral.black}
                      >
                        {t('created_by')}{' '}
                        <b>{attributes.app.data.attributes.partner_name}</b>
                      </Text>
                    </Box>
                  )}
                </Box>

                <Box display="flex" alignItems="center">
                  {attributes.app.data.attributes.platforms.map((platform) => (
                    <Tag
                      key={platform}
                      name={platform}
                      variant="neutral"
                      mr={24}
                    />
                  ))}
                </Box>
              </Box>
            </Box>
          </FlexGridItem>
        </FlexGrid>
      </div>
      <div className="row">
        <S.BoxInfo>
          <S.TextContainer>
            <Text
              as="h2"
              mt={32}
              mb={24}
              variant="headline-h2-bold-24"
              fontWeight={700}
              fontSize={14}
              color={neutral.gray1}
              textAlign="left"
              flex={1}
            >
              {t('subscription.responsibility_subscription')}
            </Text>
            <Text
              as="p"
              mt={32}
              mb={24}
              variant="subtitle-medium-14"
              color={neutral.gray1}
              textAlign="right"
              flex={1}
            >
              {contactInfo.name}
            </Text>
          </S.TextContainer>
          <S.TextContainer>
            <Text
              as="h2"
              mt={0}
              mb={48}
              variant="headline-h2-bold-24"
              fontWeight={700}
              fontSize={14}
              color={neutral.gray1}
              textAlign="left"
              flex={1}
            >
              {t('subscription.day_subscription')}
            </Text>
            <Text
              as="p"
              mb={48}
              variant="subtitle-medium-14"
              color={neutral.gray1}
              textAlign="right"
              flex={1}
            >
              {today.toLocaleDateString('pt-BR')}
            </Text>
          </S.TextContainer>
        </S.BoxInfo>
      </div>
      <div className="row">
        <Text
          as="h2"
          mb={32}
          variant="title-bold-20"
          fontWeight={700}
          color={neutral.black}
        >
          {t('subscription.informations_title')}
        </Text>
        <S.ContainerGrayBox>
          <S.GrayBox>
            <S.TextContainerResume>
              <S.TitleResume>{t('subscription.item')} </S.TitleResume>
              <S.TitleResume>
                {t('subscription.number_classrooms')}
              </S.TitleResume>
              <S.TitleResume>{t('subscription.number_students')}</S.TitleResume>
              <S.TitleResume>{t('subscription.amount')}</S.TitleResume>
            </S.TextContainerResume>

            <S.TextContainerResume>
              <S.ParagraphResume>
                {attributes.title}{' '}
              </S.ParagraphResume>
              <S.ParagraphResume>{classroom_ids.length}</S.ParagraphResume>
              <S.ParagraphResume>{student_total}</S.ParagraphResume>
              <S.ParagraphResume>
                {maskCurrency(Number(total_currency))}
              </S.ParagraphResume>
            </S.TextContainerResume>

            <S.GrayLine />

            <S.TextContainerResume>
              <S.ParagraphResume>
                {t('subscription.total_pay_amount_estimated')}{' '}
              </S.ParagraphResume>
              <S.ParagraphResume></S.ParagraphResume>
              <S.ParagraphResume></S.ParagraphResume>
              <S.TitleTotal>
                {maskCurrency(Number(total_currency))}
              </S.TitleTotal>
            </S.TextContainerResume>
          </S.GrayBox>
        </S.ContainerGrayBox>
      </div>

      <div className="row">
        <Text
          as="p"
          mt={5}
          variant="label-regular-12"
          color={neutral.gray1}
          textAlign="justified"
          lineHeight={1.7}
        >
          {t('subscription.total_pay_amount_clause_confirme', {
            date: moment().add(2, 'M').startOf('month').format('DD/MM/YYYY'),
          })
            .split('\n')
            .map((line, key) => (
              <p key={key}>{line}</p>
            ))}
        </Text>
      </div>
    </S.WrapperContainer>
  );
};

InformationsTab.propTypes = {
  ...formPropTypes,
};

export default tabifyForWizard({
  title: '2. Confirmação de compra',
})(withFormContext(InformationsTab));
