import { DefaultThemeProps } from '@agendaedu/ae-web-components';
import { ThemeProps } from 'config/themes/types';
import styled, { css } from 'styled-components';
import { WrapperStyleProps } from './types';

const WrapperModifiers = {
  xs: ({ space }: DefaultThemeProps) => css`
    width: 32px;
    height: 32px;

    > main {
      height: 25px !important;
      width: 25px !important;
      top: ${space.xs2};
      left: ${space.xs2};
    }

    ${AvatarBackground} {
      height: 25px;
      width: 25px;
    }
  `,
  sm: ({ space }: DefaultThemeProps) => css`
    width: 48px;
    height: 48px;

    > main {
      width: 34px !important;
      height: 34px !important;
      top: ${space.sm};
      left: ${space.sm};
    }

    ${AvatarBackground} {
      width: 32px;
      height: 32px;
    }
  `,
};

export const Wrapper = styled.div<WrapperStyleProps>`
  ${({ size, theme }) => css`
    display: block;
    position: relative;
    justify-content: flex-start;
    align-items: flex-start;
    > main {
      position: absolute;
      border: ${theme.border.style.solid} ${theme.border.width.md}
        ${theme.colors.neutral.white};
    }

    ${!!size && WrapperModifiers[size](theme)}
  `}
`;

export const AvatarBackground = styled.div`
  ${({ theme }: ThemeProps) => css`
    border-radius: ${theme.border.radius.circle};
    background-color: ${theme.colors.neutral.gray4};
  `}
`;
