import styled, { css } from 'styled-components';

import { ThemeProps } from 'config/themes/types';
import { Button } from '@agendaedu/ae-web-components';

export const DesktopActionButtons = styled.div`
  ${({ theme: { breakpoints } }: ThemeProps) => css`
    display: none;
    align-content: center;
    justify-content: space-between;

    @media only screen and (min-width: ${breakpoints.tabletMD}) {
      display: flex;
    }
  `}
`;

export const GroupButton = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    align-items: center;
    gap: ${space.sm};
  `}
`;

export const MobileActionButtons = styled.div`
  ${({
    theme: { border, breakpoints, colors, font, space, shadow, typography },
  }: ThemeProps) => css`
    position: fixed;
    bottom: ${space.xs4};
    left: ${space.xs4};
    display: none;
    width: 100%;
    height: 72px;
    padding: ${space.xs} ${space.xl3};
    background-color: ${colors.neutral.white};
    border-top-left-radius: ${border.radius.md};
    border-top-right-radius: ${border.radius.md};
    box-shadow: 0px -1px 2px 1px rgba(0, 0, 0, 0.1);

    .dropdown {
      float: right;
      width: ${space.xl2};
    }

    .dropdown-menu {
      width: fit-content;
      min-width: auto;
      padding: ${space.xs2};
      border: ${border.width.none};
      border-radius: ${border.radius.md};
      box-shadow: ${shadow.hover};

      .dropdown-item {
        margin: ${space.xs4};
        padding: ${space.xs2} ${space.sm};
        ${typography.label.Regular16};
      }
    }

    a.Button {
      width: ${space.xl3};
      height: ${space.xl3};
      border-radius: ${border.radius.md};
      border: ${border.width.none};

      display: flex;
      align-items: center;
      justify-content: center;

      i.icon-ae-dots-vertical {
        color: ${colors.neutral.black};
        font-size: ${font.size.lg};
      }

      &:hover,
      &:active {
        background-color: ${colors.brand.primary.op20};

        i.icon-ae-dots-vertical {
          color: ${colors.brand.primary.default};
        }
      }
    }

    @media only screen and (max-width: ${breakpoints.tabletMD}) {
      display: block;

      .DropdownButton .dropdown-menu.up {
        right: -${space.xs2};
      }
    }
  `}
`;

export const CancelButton = styled(Button)`
  ${({ theme: { colors, space } }: ThemeProps) => css`
    min-width: 100px;

    .loader-cancel-schedule {
      span {
        width: ${space.lg};
        height: ${space.lg};

        border-color: ${colors.neutral.gray4};
      }
    }
  `}
`;
