import { Box, Button, Modal, Text } from '@agendaedu/ae-web-components';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

import SurveyActions from 'store/surveys/actions';

const ModalSurveyAction = ({
  showModal,
  toggleModal,
  variation,
  survey,
  redirectTo,
  history,
}) => {
  const { t } = useTranslation(['surveys', 'common']);
  const dispatch = useDispatch();
  const { isApprovingSurvey, isDeletingSurvey } = useSelector(
    (state) => state.surveys
  );

  const [modalContext, setModalContext] = useState({
    title: '',
    description: '',
    button: null,
  });
  const {
    fetchSurveyDeleteRequest,
    fetchResendSurveyRequest,
    approveSurveyRequest,
  } = SurveyActions;

  const surveyKind = {
    poll: t('poll').toLowerCase(),
    authorization: t('authorization').toLowerCase(),
  }[survey.attributes.kind];

  useEffect(() => {
    const modalContextMap = {
      delete: deleteContext,
      approve: approveContext,
      resend: resendContext,
    };
    if (modalContextMap[variation]) setModalContext(modalContextMap[variation]);
  }, [variation, isApprovingSurvey, isDeletingSurvey]);

  const handleDeleteSurvey = () => {
    const callbackSuccess = () => {
      toggleModal(variation);
      if (redirectTo) history.push(redirectTo);
    };

    dispatch(fetchSurveyDeleteRequest(survey, callbackSuccess));
  };

  const handleResendSurvey = () => {
    dispatch(fetchResendSurveyRequest(survey));
    toggleModal(variation);
  };

  const handleApproveSurvey = () => {
    dispatch(approveSurveyRequest(survey, () => toggleModal(variation)));
  };

  const cancelButtonProps = {
    children: t('common:button.cancel'),
    variant: 'secondary',
    onClick: toggleModal,
  };

  const deleteContext = {
    title: t('action_modal_delete.title', { surveyKind }),
    description: t('action_modal_delete.description', { surveyKind }),
    button: {
      children: t(`common:button.${isDeletingSurvey ? 'deleting' : 'delete'}`),
      variant: 'primary',
      contextVariant: 'alert',
      onClick: () => handleDeleteSurvey(),
      disabled: isDeletingSurvey,
    },
  };

  const resendContext = {
    title: t('action_modal_resend.title', { surveyKind }),
    description: t('action_modal_resend.description', { surveyKind }),
    button: {
      children: t('common:button.resend'),
      variant: 'primary',
      onClick: () => handleResendSurvey(),
    },
  };

  const approveContext = {
    title: t('action_modal_approve.title', { surveyKind }),
    description: t('action_modal_approve.description', { surveyKind }),
    button: {
      children: t(
        `common:button.${isApprovingSurvey ? 'approving' : 'approve'}`
      ),
      variant: 'primary',
      onClick: () => handleApproveSurvey(),
      disabled: isApprovingSurvey,
    },
  };

  return (
    <Modal
      isOpen={showModal}
      title={modalContext.title}
      onClose={(e) => {
        e.stopPropagation();
        toggleModal();
      }}
    >
      <Box width="100%">
        <Text
          data-testid="text-content-modal"
          variant="body-regular-16"
          mx="xs3"
          maxWidth={360}
          lineHeight="lg"
        >
          {modalContext.description}
        </Text>

        <Box display="flex" justifyContent="flex-end">
          <Button mr="sm" {...cancelButtonProps} />

          <Button {...modalContext.button} />
        </Box>
      </Box>
    </Modal>
  );
};

export default withRouter(ModalSurveyAction);
