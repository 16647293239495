import React from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

import PageTitle from 'components/PageTitle';
import AgendaIcon from 'components/AgendaIcon';

import './style.scss';

const SideSheet = ({ isOpen, onDismiss, title, children }) => {
  const FADE_OUT_DURATION = 250;

  const closeSideSheet = () => {
    document
      .getElementById('side-sheet-wrapper')
      .classList.add('fade-out-animation');
    document.getElementById('side-sheet').classList.add('slide-out-animation');
    document.body.style.removeProperty('overflow');
    setTimeout(onDismiss, FADE_OUT_DURATION);
  };

  return (
    <>
      {isOpen && (
        <div
          id="side-sheet-wrapper"
          className="side-sheet-wrapper fade-in-animation"
        >
          <OutsideClickHandler onOutsideClick={closeSideSheet}>
            <section id="side-sheet" className="side-sheet slide-in-animation">
              <div className="header">
                <PageTitle variation="small">{title}</PageTitle>
                <AgendaIcon name="close" onClick={closeSideSheet} />
              </div>
              {children}
            </section>
          </OutsideClickHandler>
        </div>
      )}
    </>
  );
};

export default SideSheet;
