import { useState, useEffect, useRef, useCallback } from 'react';

const useResizableSidebar = () => {
  const sidebarRef = useRef(null);
  const [isResizing, setIsResizing] = useState<boolean>(false);

  useEffect(() => {
    const dimensions = {
      max: parseInt(window.getComputedStyle(sidebarRef.current).maxWidth),
      min: parseInt(window.getComputedStyle(sidebarRef.current).minWidth),
    };

    sidebarRef.current.style.width = `${dimensions.min}px`;
  }, []);

  const startResizing = useCallback(() => {
    setIsResizing(true);
  }, []);

  const stopResizing = useCallback(() => {
    setIsResizing(false);
  }, []);

  const resize = useCallback(
    (mouseMoveEvent) => {
      const newScreenWidth =
        mouseMoveEvent.clientX -
        sidebarRef.current?.getBoundingClientRect().left;

      if (
        isResizing &&
        newScreenWidth >
          parseInt(window.getComputedStyle(sidebarRef.current).minWidth) &&
        newScreenWidth <
          parseInt(window.getComputedStyle(sidebarRef.current).maxWidth)
      ) {
        sidebarRef.current.style.width = `${newScreenWidth}px`;
      }
    },
    [isResizing]
  );

  useEffect(() => {
    window.addEventListener('mousemove', resize);
    window.addEventListener('mouseup', stopResizing);
    return () => {
      window.removeEventListener('mousemove', resize);
      window.removeEventListener('mouseup', stopResizing);
    };
  }, [resize, stopResizing]);

  return { sidebarRef, startResizing, isResizing };
};

export default useResizableSidebar;
