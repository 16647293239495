import styled from 'styled-components';

export const RecurrencyConfigurationWrapper = styled.div`
  > .Title {
    margin-bottom: 16px;
  }

  > span {
    display: block;
    margin-bottom: 32px;
  }

  .Alert {
    margin-bottom: 32px;
  }

  .Field {
    padding-left: 15px !important;
    margin-left: 0px;
  }
`;

export const DoubleInputWrapper = styled.div`
  .row .double-input {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .DateInput {
    width: 100%;
  }

  .DateField .disabled input {
    cursor: not-allowed;
  }
`;
