import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import MyAccountActions from 'store/myAccount/actions';
import Chart from 'components/Chart';

import * as S from './styles';
import {ViewContainer} from "./styles";

const SchoolBills = () => {
  const dispatch = useDispatch();

  const { fetchSchoolBills } = MyAccountActions;
  const schoolBills = useSelector(
    (state) => state.myAccount.schoolBills
  );

  useEffect(() => { dispatch(fetchSchoolBills()) }, []);

  return (
    <S.ViewContainer>
      <S.Title>Portal de boletos da Agenda Edu</S.Title>
      {schoolBills.attributes && (
        <Chart
          title={schoolBills.attributes.title}
          url={schoolBills.attributes.url}
          height={550}
        />
      )}
    </S.ViewContainer>
  );
};

export default SchoolBills;
