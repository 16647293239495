import React, { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import smoothScrollTo from 'core/utils/smoothScrollTo';
import { IMAGES_PAYMENT_URL } from 'core/constants/index';
import { flags } from 'core/constants/flags';

import EnrollmentPlansActions from 'store/edupay/enrollmentPlans/actions';

import Table from 'components/Table';
import EmptyState from 'components/EmptyState';
import EnrollmentDetailModal from 'components/Payments/Enrollment/EnrollmentDetailModal';
import Pagination from 'components/Pagination';
import Loader from 'components/Loader';

import ManuallyPaidModal from 'components/Payments/ManuallyPaidModal';
import HandleModalByMethod from 'components/Payments/HandleModalByMethod';
import LinkModal from 'components/Payments/LinkModal';
import ColoredText from 'components/ColoredText';
import CancelModal from 'components/Payments/CancelModal';
import ManualDealModal from 'components/Payments/ManualDealModal';

import table from './table';

import './style.scss';
import { Link } from 'react-router-dom';
import { Button, Tooltip } from '@agendaedu/ae-web-components';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import * as S from './styles';

const FILTERS = [
  'contract_status',
  'status',
  'school_product_title',
  'headquarter_id',
  'educational_stage_id',
  'classroom_id',
];

const EnrollmentPlansList = () => {
  const dispatch = useDispatch();

  const {
    fetchEnrollmentPlansRequest,
    setCurrentEnrollmentPlanRequest,
    setPaidAtSchoolOrder,
    manuallyPayEnrollmentRequest,
    checkout,
    paymentMethodLinkEnrollmentRequest,
    toggleModal,
    toggleManuallyPaidModal,
    togglePaymentMethodModal,
    togglePaymentMethodLink,
    toggleCancel,
    toggleManualDealModal,
    cancelEnrollmentRequest,
    cancelEnrollmentRequestLD,
  } = EnrollmentPlansActions;

  const {
    enrollmentPlans,
    currentEnrollmentPlan,
    currentResponsibleProfile,
    recipients: responsibles,
    modalOpen,
    manuallyPaidModal,
    showModalCheckout,
    showModalCheckoutLink,
    paginate,
    filters,
    isLoading,
    isLoadingEnrollmentModal,
    errorMessage,
    cancelModal,
    showManualDealModal,
  } = useSelector((state) => state.enrollmentPlans);

  const link = `/schools/enrollment_plans/${currentEnrollmentPlan?.attributes.id}/edit`;

  const { t } = useTranslation(['enrollments']);
  const policies = useSelector((state) => state.root.policies);

  const [paymentMethod, setPaymentMethod] = useState();
  const [textCancel, setTextCancel] = useState('');
  const [modalType, setModalType] = useState(null);

  const getEnrollmentPlans = useCallback(
    (params) => {
      dispatch(fetchEnrollmentPlansRequest(params));
    },
    [dispatch, fetchEnrollmentPlansRequest]
  );

  const setCurrentEnrollmentPlan = useCallback(
    (enrollmentId) => {
      dispatch(setCurrentEnrollmentPlanRequest(enrollmentId));
    },
    [dispatch, setCurrentEnrollmentPlanRequest]
  );

  const handleManuallyPayEnrollmentPlan = useCallback(
    ({ orderId, responsibleId }) => {
      dispatch(manuallyPayEnrollmentRequest({ orderId, responsibleId }));
    },
    [dispatch, manuallyPayEnrollmentRequest]
  );

  const handleManuallyGenerateBilletEnrollmentPlan = useCallback(
    ({ orderId, responsibleId, cpf }) => {
      dispatch(checkout({ orderId, responsibleId, cpf, paymentMethod }));
    },
    [dispatch, checkout, paymentMethod]
  );

  const handlePaymentMethodLinkEnrollmentRequest = useCallback(
    (enrollmentId) => {
      dispatch(paymentMethodLinkEnrollmentRequest(enrollmentId));
    },
    [dispatch, paymentMethodLinkEnrollmentRequest]
  );

  const handleToggleShowModal = useCallback(() => {
    dispatch(toggleModal());
  }, [dispatch, toggleModal]);

  const handleToggleManuallyPaid = useCallback(() => {
    dispatch(toggleManuallyPaidModal());
  }, [dispatch, toggleManuallyPaidModal]);

  const handleTogglePaymentMethodModal = useCallback(() => {
    dispatch(togglePaymentMethodModal());
  }, [dispatch, togglePaymentMethodModal]);

  const handleTogglePaymentMethodLink = useCallback(() => {
    dispatch(togglePaymentMethodLink());
  }, [dispatch, togglePaymentMethodLink]);

  const handlePageChange = useCallback(
    (page) => {
      const params = {
        page,
      };

      getEnrollmentPlans({ ...filters, ...params });
      smoothScrollTo(0, 0);
    },
    [getEnrollmentPlans, filters]
  );

  const exportReportParams = () => {
    let query = '?';
    query += `range=${JSON.stringify(filters.range)}`;
    Object.keys(filters).forEach((key) => {
      if (FILTERS.includes(key) && filters[key]) {
        query += `&${key}=${filters[key]}`;
      }
    });

    return query;
  };

  const handleToggleCancelModal = useCallback(() => {
    dispatch(toggleCancel());
  }, [dispatch, toggleCancel]);

  const handleToggleManualDealModal = useCallback(() => {
    dispatch(toggleManualDealModal());
  }, [dispatch, toggleManualDealModal]);

  const markCancel = useCallback(
    (enrollmentId, textCancel) => {
      dispatch(cancelEnrollmentRequest(enrollmentId, textCancel));
      handleToggleCancelModal();
    },
    [dispatch, cancelEnrollmentRequest, handleToggleCancelModal]
  );

  const handleSetManualDeal = useCallback(
    (form) => {
      const { id: enrollmentId } = currentEnrollmentPlan.attributes;
      const orderId = currentEnrollmentPlan.relationships.orders.data[0].id;

      modalType == 'cancel'
        ? dispatch(cancelEnrollmentRequestLD(enrollmentId, form))
        : dispatch(setPaidAtSchoolOrder({ form, enrollmentId, orderId }));
    },
    [
      dispatch,
      cancelEnrollmentRequestLD,
      currentEnrollmentPlan,
      modalType,
      setPaidAtSchoolOrder,
    ]
  );

  const subTitle = () => (
    <>
      {currentEnrollmentPlan && (
        <p>
          A emissão da cobrança do dia{' '}
          <ColoredText variation="bold">
            {currentEnrollmentPlan.attributes.expires_at}
          </ColoredText>{' '}
          será
          <br />
          cancelada e não será enviada para o responsável
          <br />
          do aluno.
        </p>
      )}
    </>
  );

  const prepareTableData = () =>
    enrollmentPlans.map((enrollmentPlan) => {
      enrollmentPlan.actions = {};
      enrollmentPlan.actions.show = () => {
        handleToggleShowModal();
        setCurrentEnrollmentPlan(enrollmentPlan.attributes.id);
      };
      enrollmentPlan.actions.manuallyPaidModal = () => {
        handleToggleManuallyPaid();
        setCurrentEnrollmentPlan(enrollmentPlan.attributes.id);
      };
      enrollmentPlan.actions.paidAtSchool = () => {
        setCurrentEnrollmentPlan(enrollmentPlan.attributes.id);
        setModalType('paidAtSchool');
        handleToggleManualDealModal();
      };
      enrollmentPlan.actions.generateBilletModal = () => {
        setPaymentMethod('billet');
        handleTogglePaymentMethodModal();
        setCurrentEnrollmentPlan(enrollmentPlan.attributes.id);
      };
      enrollmentPlan.actions.billetLinkModal = () => {
        setPaymentMethod('billet');
        handleTogglePaymentMethodLink();
        setCurrentEnrollmentPlan(enrollmentPlan.attributes.id);
        handlePaymentMethodLinkEnrollmentRequest(enrollmentPlan.attributes.id);
      };
      enrollmentPlan.actions.generatePixModal = () => {
        setPaymentMethod('pix');
        handleTogglePaymentMethodModal();
        setCurrentEnrollmentPlan(enrollmentPlan.attributes.id);
      };
      enrollmentPlan.actions.pixLinkModal = () => {
        setPaymentMethod('pix');
        handleTogglePaymentMethodLink();
        setCurrentEnrollmentPlan(enrollmentPlan.attributes.id);
        handlePaymentMethodLinkEnrollmentRequest(enrollmentPlan.attributes.id);
      };
      enrollmentPlan.actions.cancelModal = () => {
        handleToggleCancelModal();
        setCurrentEnrollmentPlan(enrollmentPlan.attributes.id);
      };
      enrollmentPlan.actions.cancelModalLD = () => {
        setCurrentEnrollmentPlan(enrollmentPlan.attributes.id);
        setModalType('cancel');
        handleToggleManualDealModal();
      };
      return enrollmentPlan;
    });

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {enrollmentPlans.length > 0 ? (
            <>
              <Table
                columns={table}
                data={prepareTableData()}
                metadata={{
                  page: paginate.activePage,
                  totalItemsCount: paginate.totalItemsCount,
                  itemsCountPerPage: paginate.itemsCountPerPage,
                  singular: 'matrícula',
                  plural: 'matrículas',
                }}
                exportOptions={[
                  {
                    path: `/schools/enrollment_plans/report.xls${exportReportParams()}`,
                    text: 'Planilha(.xls)',
                  },
                ]}
                otherOptions={
                  <Tooltip
                    align="top"
                    content={parse(t('tooltips.models'))}
                    elementRef={
                      <Link to="/schools/enrollment_plans/contract_templates">
                        <Button variant="secondary">
                          {t('common.models')}
                        </Button>
                      </Link>
                    }
                  />
                }
              />
              <Pagination
                onChange={handlePageChange}
                activePage={paginate.activePage}
                totalItemsCount={paginate.totalItemsCount}
                itemsCountPerPage={paginate.itemsCountPerPage}
              />
            </>
          ) : (
            <>
              <S.NewModelContainer>
                <Link to="/schools/enrollment_plans/contract_templates">
                  <Button variant="secondary">{t('common.models')}</Button>
                </Link>
              </S.NewModelContainer>

              <EmptyState
                imgUrl={IMAGES_PAYMENT_URL.enrollmentEmptyStateFilterUrl}
                message={<p>{parse(t('common.results_not_found'))}</p>}
              />
            </>
          )}
          {modalOpen && (
            <EnrollmentDetailModal
              isOpen={modalOpen}
              toggleModal={handleToggleShowModal}
              enrollment={currentEnrollmentPlan}
              responsibles={currentResponsibleProfile}
              handleToggleCancelModal={
                policies.edupay_active_flags.includes(
                  flags.ENROLLMENT_CANCELLATION
                )
                  ? () => {
                      setModalType('cancel'), handleToggleManualDealModal();
                    }
                  : handleToggleCancelModal
              }
            />
          )}
          <ManuallyPaidModal
            showManuallyPaidModal={manuallyPaidModal}
            toggleManuallyPaidModal={handleToggleManuallyPaid}
            isModalLoading={isLoadingEnrollmentModal}
            student={currentEnrollmentPlan?.attributes.student_profile.data}
            responsibles={responsibles}
            onSubmit={(responsibleId) => {
              handleManuallyPayEnrollmentPlan({
                orderId: currentEnrollmentPlan.relationships.orders.data[0].id,
                responsibleId,
              });
            }}
          />
          <HandleModalByMethod
            method={paymentMethod}
            showModal={showModalCheckout}
            link={link}
            toggleModal={handleTogglePaymentMethodModal}
            isLoading={isLoadingEnrollmentModal}
            student={currentEnrollmentPlan?.attributes.student_profile.data}
            responsibles={responsibles}
            checkoutError={errorMessage}
            onSubmit={({ responsible_profile_id, cpf }) => {
              handleManuallyGenerateBilletEnrollmentPlan({
                orderId: currentEnrollmentPlan.relationships.orders.data[0].id,
                responsibleId: responsible_profile_id,
                cpf,
              });
            }}
          />
          <LinkModal
            method={paymentMethod}
            showModal={showModalCheckoutLink}
            toggleModal={handleTogglePaymentMethodLink}
            isLoading={isLoadingEnrollmentModal}
            responsibles={responsibles}
            order={currentEnrollmentPlan}
          />
          <CancelModal
            className="cancel-modal"
            isOpen={cancelModal}
            title="Cancelar cobrança"
            subTitle={subTitle()}
            toggleModal={handleToggleCancelModal}
            onCancel={() =>
              markCancel(currentEnrollmentPlan.attributes.id, textCancel)
            }
            setTextCancel={setTextCancel}
          />

          <ManualDealModal
            order={responsibles}
            isOpen={showManualDealModal}
            toggleModal={handleToggleManualDealModal}
            bill={currentEnrollmentPlan}
            isRecurrent={false}
            title={modalType == 'cancel' ? 'Cancelar cobrança' : 'Baixa manual'}
            onSubmit={(form) => handleSetManualDeal(form)}
            modalType={modalType}
          />
        </>
      )}
    </>
  );
};
export default EnrollmentPlansList;
