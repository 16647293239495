const actions = {
  ERROR: 'ERROR/panel',
  FETCH_HEADQUARTERS: 'FETCH_HEADQUARTERS/panel',
  FETCH_HEADQUARTERS_SUCCESS: 'FETCH_HEADQUARTERS_SUCCESS/panel',
  FETCH_EDUCATIONAL_STAGES: 'FETCH_EDUCATIONAL_STAGES/panel',
  FETCH_EDUCATIONAL_STAGES_SUCCESS: 'FETCH_EDUCATIONAL_STAGES_SUCCESS/panel',
  FETCH_CLASSROOMS: 'FETCH_CLASSROOMS/panel',
  FETCH_CLASSROOMS_SUCCESS: 'FETCH_CLASSROOMS_SUCCESS/panel',
  FETCH_CHARTS: 'FETCH_CHARTS/panel',
  FETCH_CHARTS_SUCCESS: 'FETCH_CHARTS_SUCCESS/panel',
  FETCH_REPORTS: 'FETCH_REPORTS/panel',
  FETCH_REPORTS_SUCCESS: 'FETCH_REPORTS_SUCCESS/panel',

  fetchHeadquarters: () => ({
    type: actions.FETCH_HEADQUARTERS,
  }),
  fetchEducationalStages: (headquarter) => ({
    type: actions.FETCH_EDUCATIONAL_STAGES,
    headquarter,
  }),
  fetchClassrooms: (headquarter, educationalStage) => ({
    type: actions.FETCH_CLASSROOMS,
    headquarter,
    educationalStage,
  }),
  fetchCharts: (filters) => ({
    type: actions.FETCH_CHARTS,
    filters,
  }),
  fetchReports: () => ({
    type: actions.FETCH_REPORTS,
  }),
};

export default actions;
