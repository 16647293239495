import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { canEditRecurrentBill } from 'core/utils/recurrentBill';
import pluck from 'core/utils/pluck';
import withAppContext from 'core/hoc/withAppContext';
import { flags } from 'core/constants/flags';
import {
  CAN_GENERATE_BILLET_OR_PIX_BY_RECURRENT_STATUS,
  CAN_MANUALLY_PAID_BY_RECURRENT_STATUS,
  CAN_DELAY_RECURRENT_BILL_BY_STATUS,
  CAN_NEGOTIATE_RECURRENT_BILL_BY_STATUS,
  CAN_REVERT_MANUAL_PAYMENT_BY_RECURRENT_STATUS,
} from 'core/constants/index';
import { canUseGuaranteedMonthlyFee } from 'core/utils/guaranteedMonthlyFee';
import { canGenerateBilletOrPix } from 'core/utils/canGenerateBilletOrPix';
import { isRecurrentBillNonNegotiable } from 'core/utils/edupay/functions';
import negotiationsActions from 'store/edupay/negotiations/actions';

import Currency from 'components/Currency';
import OutlineBox from 'components/OutlineBox';
import DropdownButton from 'components/DropdownButton';
import ClassroomsList from 'components/ClassroomsList';
import ColoredText from 'components/ColoredText';
import AgendaIcon from 'components/AgendaIcon';
import Tooltip, { TOP_CENTER } from 'components/Tooltip';
import RecurrentClickShowModal from 'components/Payments/Recurrent/RecurrentClickShowModal';
import recurrentStatus from '../../RecurrentStatus';
import FormCheckbox from 'components/Form/Checkbox';
import InputTableField from 'components/Payments/InputTableField';

import './style.scss';
import { useTranslation } from 'react-i18next';

const SelectAllBills = () => {
  const dispatch = useDispatch();

  const recurrentBills = useSelector(
    (state) => state.recurrentBills.recurrentBills
  );

  const { setSelectAllRecurrentBills } = negotiationsActions;
  const { wasSelectedAllBills } = useSelector((state) => state.negotiations);

  const { isTableEdit } = useSelector((state) => state.recurrentBills);

  const {
    currentUser: {
      attributes: { is_debug_user },
    },
  } = useSelector((state) => state.root);

  const handleSelect = useCallback(() => {
    dispatch(setSelectAllRecurrentBills(is_debug_user, recurrentBills));
  }, [dispatch, setSelectAllRecurrentBills, is_debug_user, recurrentBills]);

  return (
    <FormCheckbox
      checked={wasSelectedAllBills}
      onChange={handleSelect}
      disabled={isTableEdit}
    />
  );
};

const SelectRecurrentBill = ({ bill: { id, attributes } }) => {
  const dispatch = useDispatch();
  const {
    currentUser: {
      attributes: { is_debug_user },
    },
  } = useSelector((state) => state.root);
  const { setSelectedRecurrentBills } = negotiationsActions;
  const { selectedRecurrentBills } = useSelector((state) => state.negotiations);

  const { isTableEdit } = useSelector((state) => state.recurrentBills);

  const handleSelect = useCallback(() => {
    dispatch(setSelectedRecurrentBills(id));
  }, [dispatch, id, setSelectedRecurrentBills]);

  const isDisabled = isRecurrentBillNonNegotiable(attributes.status, isTableEdit);

  return (
    <>
      {isDisabled ? (
        <Tooltip
          content={
            isTableEdit
              ? 'Não é permitido negociar ao editar uma cobrança'
              : !CAN_NEGOTIATE_RECURRENT_BILL_BY_STATUS.includes(
                  attributes.status
                )
              ? 'Status desta cobrança não permite negociar'
              : 'Carteira dessa cobrança não permite negociar'
          }
        >
          <FormCheckbox
            checked={selectedRecurrentBills?.includes(id)}
            onChange={handleSelect}
            disabled={isDisabled}
          />
        </Tooltip>
      ) : (
        <FormCheckbox
          checked={selectedRecurrentBills?.includes(id)}
          onChange={handleSelect}
          disabled={isDisabled}
        />
      )}
    </>
  );
};

SelectRecurrentBill.propTypes = {
  bill: {
    id: PropTypes.string.isRequired,
    attributes: PropTypes.shape({
      status: PropTypes.string,
      is_wallet_guaranteed_monthly_fee: PropTypes.bool,
    }).isRequired,
  },
};

const LegacyId = ({
  bill: { attributes, actions },
  currentEditlegacyList,
  handleUpdateLegacyList,
}) => {
  const { isTableEdit } = useSelector((state) => state.recurrentBills);

  const orderId = attributes?.order?.data?.attributes?.orderable_id;

  const getLegacyIdValue = () => {
    const legacy = currentEditlegacyList?.find(
      (legacy) => legacy.id === orderId
    );

    return legacy ? legacy.legacy_id : attributes?.legacy_id;
  };

  return (
    <div className="legacy-id-wrapper">
      {isTableEdit ? (
        <InputTableField
          placeholder="0"
          value={getLegacyIdValue()}
          onChange={(e) =>
            handleUpdateLegacyList({ id: orderId, legacy_id: e })
          }
        />
      ) : (
        <RecurrentClickShowModal actions={actions}>
          {!attributes?.legacy_id ? (
            <AgendaIcon name="dash" />
          ) : (
            attributes.legacy_id
          )}
        </RecurrentClickShowModal>
      )}
    </div>
  );
};

LegacyId.propTypes = {
  bill: {
    attributes: PropTypes.shape({
      legacy_id: PropTypes.string,
      order: {
        data: {
          attributes: {
            orderable_id: PropTypes.number,
          },
        },
      },
    }),
    actions: PropTypes.shape({
      show: PropTypes.func,
    }).isRequired,
  },
  currentEditlegacyList: PropTypes.array,
  handleUpdateLegacyList: PropTypes.func,
};

const Number = ({ bill: { attributes, actions } }) => (
  <RecurrentClickShowModal actions={actions}>
    {attributes.bill_number}
  </RecurrentClickShowModal>
);

Number.propTypes = {
  bill: {
    attributes: PropTypes.shape({
      bill_number: PropTypes.number,
    }).isRequired,
    actions: PropTypes.shape({
      show: PropTypes.func,
    }).isRequired,
  },
};

const Sent = ({ bill: { attributes, actions } }) => (
  <RecurrentClickShowModal actions={actions}>
    {attributes.release_day}
  </RecurrentClickShowModal>
);

Sent.propTypes = {
  bill: {
    attributes: PropTypes.shape({
      release_day: PropTypes.string,
    }).isRequired,
    actions: PropTypes.shape({
      show: PropTypes.func,
    }).isRequired,
  },
};

const Expire = ({ bill: { attributes, actions } }) => (
  <RecurrentClickShowModal actions={actions}>
    {attributes.due_date}
  </RecurrentClickShowModal>
);

Expire.propTypes = {
  bill: {
    attributes: PropTypes.shape({
      due_date: PropTypes.string,
    }).isRequired,
    actions: PropTypes.shape({
      show: PropTypes.func,
    }).isRequired,
  },
};

const Products = ({ bill: { attributes, actions } }) => (
  <RecurrentClickShowModal actions={actions}>
    <ClassroomsList
      kindItem="produtos"
      classroomsNames={pluck(
        attributes.recurrent_items.data,
        'product_name',
        'attributes'
      )}
    />
  </RecurrentClickShowModal>
);

Products.propTypes = {
  bill: {
    attributes: PropTypes.shape({
      recurrent_items: PropTypes.shape({
        data: PropTypes.array,
      }),
    }).isRequired,
    actions: PropTypes.shape({
      show: PropTypes.func,
    }).isRequired,
  },
};

const Price = ({ bill: { attributes, actions } }) => (
  <RecurrentClickShowModal actions={actions}>
    <Currency value={attributes.price_cents} />
  </RecurrentClickShowModal>
);

Price.propTypes = {
  bill: {
    attributes: PropTypes.shape({
      price_cents: PropTypes.number,
    }).isRequired,
    actions: PropTypes.shape({
      show: PropTypes.func,
    }).isRequired,
  },
};

const Visualization = ({ bill }) => {
  const filteredVisualizations = bill.visualizations.filter(
    (visualization) => visualization.attributes.responsible_exist
  );

  const views = filteredVisualizations.length ? (
    <RecurrentClickShowModal actions={bill.actions}>
      <ColoredText variation="bold">Visualizou pelo aplicativo</ColoredText>
      <br />
      {filteredVisualizations.map((view, index) => (
        <div key={`${view.attributes.name}${index}`}>
          <span>
            {view.attributes.name} {view.attributes.created_at}
          </span>
          {index + 1 !== filteredVisualizations.length && <hr />}
        </div>
      ))}
    </RecurrentClickShowModal>
  ) : null;

  const separator = filteredVisualizations.length ? <hr /> : null;

  const notSeen = bill.attributes.responsibles_not_seen.length ? (
    <RecurrentClickShowModal actions={bill.actions}>
      {separator}
      <ColoredText variation="bold">Não Visualizou</ColoredText>
      <br />
      {bill.attributes.responsibles_not_seen.map((name, index) => (
        <div key={`${name}${index}`}>
          <span>{name}</span>
          <br />
        </div>
      ))}
    </RecurrentClickShowModal>
  ) : null;

  if (bill.visualizations.length) {
    return (
      <RecurrentClickShowModal actions={bill.actions}>
        <Tooltip
          content={[views, notSeen]}
          position={TOP_CENTER}
          className="orderVisualizations"
        >
          <AgendaIcon name="eye" />
        </Tooltip>
      </RecurrentClickShowModal>
    );
  }

  return (
    <RecurrentClickShowModal actions={bill.actions}>
      <AgendaIcon name="dash" />
    </RecurrentClickShowModal>
  );
};

Visualization.propTypes = {
  bill: {
    visualizations: {
      attributes: PropTypes.shape({
        responsible_exist: PropTypes.string,
        responsibles_not_seen: PropTypes.array,
      }),
    },
    attributes: PropTypes.shape({
      status: PropTypes.string,
    }).isRequired,
    actions: PropTypes.shape({
      show: PropTypes.func,
    }).isRequired,
  },
};

const Situation = ({ bill: { attributes, actions } }) => (
  <RecurrentClickShowModal actions={actions}>
    <OutlineBox variation={recurrentStatus[attributes.status].variation}>
      {recurrentStatus[attributes.status].text}
    </OutlineBox>
  </RecurrentClickShowModal>
);

Situation.propTypes = {
  bill: {
    attributes: PropTypes.shape({
      status: PropTypes.string,
    }).isRequired,
    actions: PropTypes.shape({
      show: PropTypes.func,
    }).isRequired,
  },
};

const MenuOptions = (id, attributes, actions) => {
  const { t } = useTranslation(['recurrent_plan']);
  const { policies } = useSelector((state) => state.root);

  const {
    can_delay: canDelay,
    can_refund: canRefund,
    is_wallet_fidc: isWalletFidc,
    is_wallet_guaranteed_monthly_fee: isGuaranteedFee,
    status,
  } = attributes;
  const checkStatus = (statuses) => statuses.includes(status);

  if (attributes.kind === 'agreement') {
    return [
      checkStatus(['pending']) && {
        text: t('list.actions.send_bill'),
        onClick: () => actions.release(),
      },
      checkStatus(CAN_MANUALLY_PAID_BY_RECURRENT_STATUS) && {
        text: t('list.actions.manual_deal'),
        onClick: () => actions.manualDealModal(),
      },
    ];
  }

  return [
    canEditRecurrentBill(status, isWalletFidc, isGuaranteedFee) && {
      text: t('list.actions.edit_bill'),
      path: `/schools/recurrent/recurrent_plans/${attributes.recurrent_plan_id}/recurrent_bills/${id}/edit`,
      target: '_self',
    },
    checkStatus(['pending']) && {
      text: t('list.actions.send_bill'),
      onClick: () => actions.release(),
    },
    checkStatus(CAN_MANUALLY_PAID_BY_RECURRENT_STATUS) && {
      text: t('list.actions.manual_deal'),
      onClick: () => actions.manualDealModal(),
    },
    checkStatus(CAN_DELAY_RECURRENT_BILL_BY_STATUS) &&
      canDelay && {
        text: t('list.actions.delay_bill'),
        onClick: () => actions.delay(),
      },
    canRefund && {
      text: t('list.actions.refund'),
      onClick: () => actions.refundModal(),
    },
    checkStatus(CAN_REVERT_MANUAL_PAYMENT_BY_RECURRENT_STATUS) && {
      text: t('list.actions.revert_manual_payment'),
      onClick: () => actions.revertManualPaymentModal(),
    },
  ];
};

const Actions = ({ bill: { attributes, id, actions } }) => {
  const { t } = useTranslation(['recurrent_plan', 'common']);
  const schoolUser = useSelector((state) => state.root.currentUser);
  const {
    policies: { core_banking_flags: coreBankingFlags },
  } = useSelector((state) => state.root);
  const {
    attributes: { is_debug_user: isDebugUser },
  } = schoolUser;
  const checkStatus = (statuses) => statuses.includes(attributes.status);

  const { hasBatchSelection } = useSelector((state) => state.negotiations);

  const { isTableEdit } = useSelector((state) => state.recurrentBills);

  let options = [
    canGenerateBilletOrPix(
      isDebugUser,
      attributes.is_wallet_guaranteed_monthly_fee,
      attributes.status
    ) &&
      attributes.allowed_payment_method.includes('billet') &&
      checkStatus(CAN_GENERATE_BILLET_OR_PIX_BY_RECURRENT_STATUS) && {
        text: t('list.actions.generate_billet'),
        onClick: () => actions.billetModal(),
      },
    attributes.allowed_payment_method.includes('billet') &&
      checkStatus(['generated_billet']) && {
        text: t('list.actions.billet_link'),
        onClick: () => actions.billetLinkModal(),
      },
    attributes.allowed_payment_method.includes('pix') &&
      coreBankingFlags.includes(flags.PIX_ISSUED_AT_SCHOOL) &&
      checkStatus(CAN_GENERATE_BILLET_OR_PIX_BY_RECURRENT_STATUS) && {
        text: t('list.actions.generate_pix'),
        onClick: () => actions.pixModal(),
      },
    attributes.allowed_payment_method.includes('pix') &&
      coreBankingFlags.includes(flags.PIX_ISSUED_AT_SCHOOL) &&
      checkStatus('generated_pix') && {
        text: t('list.actions.pix_key'),
        onClick: () => actions.pixLinkModal(),
      },
  ];

  if (!attributes.is_wallet_guaranteed_monthly_fee) {
    options.push(...MenuOptions(id, attributes, actions, checkStatus));
  } else if (
    canUseGuaranteedMonthlyFee(
      isDebugUser,
      attributes.is_wallet_guaranteed_monthly_fee
    )
  ) {
    options.push(...MenuOptions(id, attributes, actions, checkStatus));
  }

  options = options.filter((option) => option !== false);

  return (
    <DropdownButton
      dropdownItems={options}
      text={t('common:button.actions')}
      variation="secondary"
      disabled={hasBatchSelection || isTableEdit}
    />
  );
};

Actions.propTypes = {
  bill: PropTypes.shape({
    id: PropTypes.string.isRequired,
    attributes: PropTypes.shape({
      status: PropTypes.string,
      can_delay: PropTypes.bool,
      can_refund: PropTypes.bool,
      allowed_payment_method: PropTypes.arrayOf(
        PropTypes.oneOf(['billet', 'credit_card', 'pix'])
      ).isRequired,
      is_wallet_guaranteed_monthly_fee: PropTypes.bool,
    }).isRequired,
    actions: PropTypes.shape({
      release: PropTypes.func,
      manuallyPaidModal: PropTypes.func,
      billetModal: PropTypes.func,
      billetLinkModal: PropTypes.func,
      pixModal: PropTypes.func,
      pixLinkModal: PropTypes.func,
      delay: PropTypes.func,
      refundModal: PropTypes.func,
      negotiation: PropTypes.func,
      revertManualPaymentModal: PropTypes.func,
    }).isRequired,
  }),
};

const RecipientWalletName = ({ bill: { attributes, actions } }) => (
  <RecurrentClickShowModal actions={actions}>
    {attributes.recipient_wallet_name}
  </RecurrentClickShowModal>
);

RecipientWalletName.propTypes = {
  bill: {
    attributes: PropTypes.shape({
      recipient_wallet_name: PropTypes.string,
    }).isRequired,
    actions: PropTypes.shape({
      show: PropTypes.func,
    }).isRequired,
  },
};

const RecurrentBillTabs = [
  {
    Header: SelectAllBills,
    accessor: SelectRecurrentBill,
  },
  {
    Header: 'Nº',
    accessor: Number,
  },
  {
    Header: 'Identificador',
    accessor: LegacyId,
  },
  {
    Header: 'Envio',
    accessor: Sent,
  },
  {
    Header: 'Vencimento',
    accessor: Expire,
  },
  {
    Header: 'Produtos',
    accessor: Products,
  },
  {
    Header: 'Total a pagar',
    accessor: Price,
  },
  {
    Header: 'Visualização',
    accessor: Visualization,
  },
  {
    Header: 'Carteira destino',
    accessor: RecipientWalletName,
  },
  {
    Header: 'Situação',
    accessor: Situation,
  },
  {
    Header: 'Ação',
    accessor: withAppContext(Actions),
  },
];

export default RecurrentBillTabs;
