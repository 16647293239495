import React from 'react';

import EmptyState from 'components/EmptyState';
import { EMPTY_STATES_URL } from 'core/constants';
import * as S from './styles';

export const NegotiationEmptyState = () => {
  return (
    <EmptyState
      imgUrl={EMPTY_STATES_URL.negotiationsUrl}
      message={
        <S.EmptyStateContentContainer>
          <h1>
            Ainda não existem negociações
            <br /> para esse plano.
          </h1>
          <p>
            Para fazer uma negociação precisa
            <br /> selecionar cobranças na aba “Pagamentos”.
          </p>
        </S.EmptyStateContentContainer>
      }
    />
  );
};
