/* eslint-disable no-unused-vars */
import { reverseArray } from 'core/utils/reverseArray';

import {
  HandoutProps,
  FetchHandoutsRequestParams,
  FormAttributesProps,
  EngagementProps,
  RelationshipsDataType,
  HandoutMetaProps,
  TemplateDataProps,
  TemplateDataIncludedProps,
  Template,
} from 'store/handouts/types';

export const getHandoutsData = (
  data: HandoutProps[],
  included: any,
  meta: HandoutMetaProps
) => {
  const dataWithRelationships = data.map((handout) => {
    const category = included.find(
      (category) =>
        category.id === handout.relationships.category?.data?.id &&
        category.type === handout.relationships.category?.data?.type
    );

    const classrooms = handout.relationships.classrooms?.data?.map(
      (classroomData) =>
        included.find(
          (classroom) =>
            classroom.id === classroomData.id &&
            classroom.type === classroomData.type
        )
    );

    const studentProfiles = handout.relationships.studentProfiles?.data?.map(
      (studentProfile) =>
        included.find(
          (student) =>
            student.id === studentProfile.id &&
            student.type === studentProfile.type
        )
    );

    const countInformation = meta.handoutCounters.find(
      (handoutCounter) => handout.id === handoutCounter.handout_id.toString()
    );

    return {
      ...handout,
      relationships: {
        ...handout.relationships,
        category: {
          ...handout.relationships.category,
          data: category,
        },
        classrooms: {
          ...handout.relationships.classrooms,
          data: classrooms,
        },
        students: {
          data: studentProfiles,
        },
        count: countInformation,
      },
    };
  });

  return dataWithRelationships;
};

export const getCurrentHandoutData = (data: HandoutProps, included: any) => {
  const classrooms = data.relationships.classrooms?.data?.map((classroomData) =>
    included.find(
      (classroom) =>
        classroom.id === classroomData.id &&
        classroom.type === classroomData.type
    )
  );

  const students = data.relationships.studentProfiles?.data?.map(
    (studentProfile) =>
      included.find(
        (student) =>
          student.id === studentProfile.id &&
          student.type === studentProfile.type
      )
  );

  const versions = data.relationships.versions?.data?.map((versionData) =>
    included.find(
      (version) =>
        version.id === versionData.id && version.type === versionData.type
    )
  );

  const studentProfiles = included
    ?.filter((student) => student.type === 'studentProfile')
    .map((student) => ({
      id: student.id,
      type: 'student_profile',
      attributes: {
        name: student.attributes.name,
        name_initials: student.attributes.nameInitials,
        avatar_color: student.attributes.avatarColor,
        avatar_url: null,
      },
    }));

  let classroom_with_student_profile_ids = [];

  classroom_with_student_profile_ids =
    data.attributes.classroomWithStudentProfileIds;

  Object.keys(classroom_with_student_profile_ids).forEach((classromId) => {
    const strValue = classroom_with_student_profile_ids[classromId].map(
      (stundentId) => stundentId.toString()
    );

    classroom_with_student_profile_ids[classromId] = strValue;
  });

  const formattedVersions = versions?.map((version) => ({
    ...version,
    attributes: {
      event: version.attributes.event,
      school_user: {
        data: {
          attributes: {
            name: version.attributes.name,
            name_initials: version.attributes.nameInitials,
            avatar_color: version.attributes.avatarColor,
            avatar_url: version.attributes.avatarUrl,
          },
        },
      },
      created_at: version.attributes.createdAt,
    },
  }));

  return {
    ...data,
    attributes: {
      ...data.attributes,
      classrooms,
      students,
      versions: reverseArray(formattedVersions),
      classroom_with_student_profile_ids,
    },
    student_profiles: studentProfiles,
  };
};

export const getEngagementsData = (
  data: EngagementProps[],
  included: RelationshipsDataType[],
  recipientType?: string
) => {
  const dataWithRelationships = data.map((engagement) => {
    const studentProfile = included.find(
      (studentProfile: EngagementProps) =>
        studentProfile.id ===
          engagement.relationships.studentProfile?.data?.id &&
        studentProfile.type ===
          engagement.relationships.studentProfile?.data?.type
    );

    const responsibleProfileId =
      engagement.relationships.responsibleProfile?.data?.id;

    const responsibleProfile =
      responsibleProfileId !== '0'
        ? included.find(
            (responsibleProfile: EngagementProps) =>
              responsibleProfile.id ===
                engagement.relationships.responsibleProfile?.data?.id &&
              responsibleProfile.type ===
                engagement.relationships.responsibleProfile?.data?.type
          )
        : responsibleProfileId;

    const classrooms = included.find(
      (classroom: EngagementProps) =>
        classroom.id === engagement.relationships.classroom?.data?.id &&
        classroom.type === engagement.relationships.classroom?.data?.type
    );

    const recipient = {
      responsibles: {
        ...responsibleProfile.attributes,
        studentName: studentProfile.attributes.name,
      },
      students: {
        ...studentProfile.attributes,
      },
    };

    const confirmType = {
      responsibles: {
        confirm: engagement.attributes.confirm,
      },
      students: {
        confirm: engagement.attributes.confirmStudent,
      },
    };

    delete engagement.relationships;

    return {
      ...engagement,
      attributes: {
        ...engagement.attributes,
        ...recipient[recipientType],
        ...confirmType[recipientType],
        recipientType: recipientType,
        classroom: {
          id: classrooms.id,
          name: classrooms.attributes.name,
        },
      },
    };
  });

  return dataWithRelationships;
};

export const queryUrlFiltered = (params: FetchHandoutsRequestParams) => {
  const {
    title,
    situation,
    startDate,
    endDate,
    headquarterId,
    educationalStageId,
    classroomId,
    categoryId,
    activeSchoolTermId,
  } = params;

  const filterTitle = title ? `&filter[title]=${title}` : '';
  const filterStatus = situation
    ? situation !== 'withoutClassrooms'
      ? situation === 'pending'
        ? `&filter[status][]=${situation}&filter[status][]=pending_schedule`
        : `&filter[status][]=${situation}`
      : `&filter[withoutClassrooms]=${activeSchoolTermId}`
    : '';
  const filterStartDate = startDate
    ? `&filter[createdAtPeriod][startAt]=${startDate}`
    : '';
  const filterEndDate = startDate
    ? `&filter[createdAtPeriod][endAt]=${endDate}`
    : '';
  const filterHeadquarter = headquarterId
    ? `&filter[headquarterIds][]=${headquarterId}`
    : '';
  const filterEducationalStage = educationalStageId
    ? `&filter[educationalStageIds][]=${educationalStageId}`
    : '';
  const filterClassRoom = classroomId
    ? `&filter[classroomIds][]=${classroomId}`
    : '';
  const filterCategory = categoryId
    ? `&filter[categoryIds][]=${categoryId}`
    : '';
  const filterSchoolTerm = activeSchoolTermId
    ? `&filter[schoolTermNames][]=${activeSchoolTermId}`
    : '';

  const queryParamsFilters = `${filterTitle}${filterStatus}${filterStartDate}${filterEndDate}${filterHeadquarter}${filterEducationalStage}${filterClassRoom}${filterCategory}${filterSchoolTerm}`;

  return queryParamsFilters;
};

export const normalizeParams = (values: FormAttributesProps) => {
  const formData = new FormData();

  const {
    persona_type: sendTo,
    title,
    description,
    coverImage,
    categoryId,
    classroom_ids: classroomIds,
    directUploadAttachments,
    classroom_with_student_profile_ids,
    sent_kind,
  } = values;

  formData.append('title', title);
  formData.append('description', description);
  formData.append('categoryId', categoryId);
  formData.append('sendTo', sendTo);
  if (values?.id) formData.append('source_handout_id', values.id);
  if (values?.templateId) formData.append('template_id', values.templateId);

  if (coverImage) {
    if (coverImage.uploaded === false) {
      formData.append('coverImage', coverImage);
    }
  } else {
    formData.append('coverImage', '');
  }

  if (directUploadAttachments) {
    directUploadAttachments.forEach((attachment) => {
      formData.append('directUploadAttachments[]', attachment.signed_id);
    });
  }

  classroomIds.forEach((classroom) => {
    formData.append('classroomIds[]', classroom);
  });

  if (classroom_with_student_profile_ids) {
    const objKeys = Object.keys(classroom_with_student_profile_ids);

    objKeys.forEach((classromId) => {
      classroom_with_student_profile_ids[classromId].forEach((studentId) => {
        formData.append(
          `classroom_with_student_profile_ids[${classromId}][]`,
          studentId
        );
      });
    });

    formData.append('createdFor', sent_kind);
  }

  return formData;
};

export const handoutTemplateNormalizeParams = (values: FormAttributesProps) => {
  const formData = new FormData();

  const { title, templateTitle, description, coverImage, categoryId } = values;

  formData.append('category_id', categoryId);
  formData.append('title', templateTitle);
  formData.append('fields[title]', title);
  formData.append('fields[description]', description);
  if (coverImage) formData.append('cover_image', coverImage as File);

  return formData;
};

export const editHandoutTemplateNormalizeParams = (
  values: FormAttributesProps
) => {
  const formData = new FormData();

  const { title, templateTitle, description, coverImage, categoryId } = values;

  formData.append('category_id', categoryId);
  formData.append('title', templateTitle);
  formData.append('fields[title]', title);
  formData.append('fields[description]', description);

  if (coverImage) {
    if (!coverImage?.uploaded) formData.append('coverImage', coverImage);
  } else {
    formData.append('coverImage', '');
  }

  return formData;
};

export const validateFormErrors = (
  attributeName: string,
  getFormValidationResult: (attributeName: string) => { messages: string[] },
  hasErrorOnAttribute: (attributeName: string) => boolean
) => {
  const errorMessages = getFormValidationResult(attributeName)?.messages[0];
  const hasError = hasErrorOnAttribute(attributeName);

  return {
    hasError,
    errorMessages,
  };
};

export const getEngagementFilters = (filterParams: {
  profile: string;
  status: string;
  classroomId: string;
  sendTo: string;
}) => {
  const { profile, status, classroomId, sendTo } = filterParams;
  const filters = [];

  const profileType = {
    responsibles: 'responsibles',
    students: 'students',
  };

  const selectedProfileType = profileType[sendTo] || profile;

  const addFilter = (key: string, value: string) => {
    if (value) {
      filters.push(`${key}=${encodeURIComponent(value)}`);
    }
  };

  addFilter('filter[profile]', selectedProfileType);
  addFilter('filter[status]', status);
  addFilter('filter[classroom_ids][]', classroomId);

  const queryURL = filters.length > 0 ? `&${filters.join('&')}` : '';

  return {
    filters: queryURL,
    profileType: selectedProfileType,
  };
};

export async function getCoverFileImage(
  coverImage: {
    name: string;
    url: string;
  },
  uploaded?: boolean
): Promise<File | null> {
  try {
    const { name, url } = coverImage;

    if (!url) return null;

    const response = await fetch(url);

    const blob = await response.blob();

    const metadata = {
      type: blob.type,
    };

    const file = new File([blob], name, metadata);

    const fileUrl = URL.createObjectURL(file);

    file.url = fileUrl;
    file.uploaded = uploaded;

    return file;
  } catch (error) {
    throw new Error(`Failed to fetch image: ${error.message}`);
  }
}

export const fetchHandoutTemplatesNormalize = (
  templates: TemplateDataProps[],
  included: TemplateDataIncludedProps[]
) => {
  const templateList = [];

  templates?.map((template) => {
    const category = included?.find(
      (item) => item.id === template.relationships.categorizable?.data.id
    );

    templateList.push({
      ...template,
      attributes: {
        ...template.attributes,
        categoryId: category?.id,
        categoryName: category?.attributes.name,
      },
    });
  });

  return templateList;
};
