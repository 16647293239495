import styled from 'styled-components';
import colorWithAlpha from 'core/utils/colorWithAlpha';

export const Title = styled.h3`
  margin: 24px 0;
  font-family: 'Quicksand', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  color: ${(props) => props.theme.black};
`;

export const OverviewList = styled.ul`
  margin: 0;
  padding: 0;

  li {
    font-family: 'Quicksand', sans-serif;
    font-weight: 500;
    font-size: 16px;
    list-style: none;
    line-height: 170%;
    color: ${(props) => props.theme.black};
  }
`;

export const InfoCard = styled.div`
  margin: 24px 0 48px 0;
  padding: 16px;
  background-color: ${(props) => colorWithAlpha(props.theme.info, 0.1)};
  border-radius: 4px;

  span {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 14px;
    color: ${(props) => props.theme.black};
  }
`;

export const NextStepsList = styled.ul`
  margin: 0;
  padding: 0;

  li {
    margin: 24px 0;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    list-style: none;
    color: ${(props) => props.theme.black};
  }
`;
