import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import validations from './validations';

import tabifyForWizard from 'core/hoc/tabifyForWizard';
import withAppContext from 'core/hoc/withAppContext';
import withFormContext from 'core/hoc/withFormContext';
import { validateFormErrors } from 'core/helper/handouts';

import { Text, TextField } from '@agendaedu/ae-web-components';

import * as S from './styles';

import { Props } from './types';

const Informations: React.FC<Props> = ({
  formContext: {
    form: { title, description },
    changeMeta,
    updateAttribute,
    hasErrorOnAttribute,
    getFormValidationResult,
  },
}) => {
  const { t } = useTranslation(['messages']);

  const tBase = useCallback(
    (key: string, params?: Record<string, string | number>) =>
      t(`omni_channel.forms.new_ticket_by_school.steps.first.${key}`, params),
    [t]
  );

  const handleChangeFormAttributes = useCallback(
    (attributeName: string, value: string): void => {
      updateAttribute(attributeName, value);
    },
    [updateAttribute]
  );

  const handleGetErrors = (name: string) =>
    validateFormErrors(name, getFormValidationResult, hasErrorOnAttribute);

  const canGoToNextStep = !!title && !!description;

  useEffect(() => {
    canGoToNextStep
      ? changeMeta('nextStepDisabled', false)
      : changeMeta('nextStepDisabled', true);
  }, [changeMeta, canGoToNextStep]);

  return (
    <S.FormFieldWrapper>
      <S.FieldsWrapper>
        <Text mt={32} variant="title-bold-20" color="neutral.black" mb={0}>
          {tBase('title')}
        </Text>

        <TextField
          data-testid="input-new-ticket-title"
          label={tBase('content_label')}
          placeholder={tBase('content_placeholder')}
          value={title}
          onChange={({ target: { value } }) =>
            handleChangeFormAttributes('title', value)
          }
          error={handleGetErrors('title').hasError}
          errorMessage={tBase(
            `validations.${handleGetErrors('title').errorMessages}`
          )}
          clearable
          fullWidth
        />

        <TextField
          data-testid="input-new-ticket-description"
          value={description}
          label={tBase('description_label')}
          onChange={({ target: { value } }) =>
            handleChangeFormAttributes('description', value)
          }
          error={handleGetErrors('description').hasError}
          errorMessage={tBase(
            `validations.${handleGetErrors('description').errorMessages}`
          )}
          fullWidth
          multiline
        />

        <Text variant="body-regular-12">{tBase('required_fields')}</Text>
      </S.FieldsWrapper>
    </S.FormFieldWrapper>
  );
};
export default tabifyForWizard({
  title: '1 Informações',
  validations,
})(withAppContext(withFormContext(Informations)));
