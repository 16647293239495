import React, { useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './styles';

import {
  Box,
  Checkbox,
  DefaultThemeProps,
  Select,
  Text,
  TextField,
} from '@agendaedu/ae-web-components';
import { useTheme } from 'styled-components';
import { Header } from '../components/Header';
import i18n from 'config/i18n';

import { Props } from './types';

const SUGGESTIONS = [
  'refused',
  'less_than_half',
  'half',
  'more_than_half',
  'ate_all',
  'repeated',
].map((suggestion) =>
  i18n.t(`diary_sections:sections.card.meals.suggestions.${suggestion}`)
);

export const isEmptyMeal = (meal: Props['meal']) =>
  [meal.category, meal.description, meal.not_ate].every((field) => !field);

export const MealCard = ({
  meal,
  onChange,
  student,
  footer,
}: Props): React.ReactElement => {
  const { t } = useTranslation(['diary_sections']);
  const { border } = useTheme() as DefaultThemeProps;
  const [mealValues, setMealValues] = useReducer(
    (state: Props['meal'], action: (state: Props['meal']) => Props['meal']) => {
      const newMeal = action(state);

      onChange(newMeal);

      return newMeal;
    },
    meal
  );

  const [textMatches, setTextMatches] = useState(SUGGESTIONS);
  const [showSuggestions, setShowSuggestions] = useState(false);

  const OPTIONS = [
    {
      label: t('sections.card.meals.options.select'),
      value: '',
    },
    {
      label: t('sections.card.meals.options.breakfast'),
      value: 'breakfast',
    },
    {
      label: t('sections.card.meals.options.lunch'),
      value: 'lunch',
    },
    {
      label: t('sections.card.meals.options.afternoon_snack'),
      value: 'afternoon_snack',
    },
    {
      label: t('sections.card.meals.options.dinner'),
      value: 'dinner',
    },
    {
      label: t('sections.card.meals.options.others'),
      value: 'others',
    },
  ];

  const handleOnchange = (value) => {
    const matches = SUGGESTIONS.filter((suggestion) => {
      const escapedValue = value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
      const regex = new RegExp(escapedValue, 'gi');
      return suggestion.match(regex);
    });
    setTextMatches(matches);
    setMealValues((prev) => ({
      ...prev,
      description: value,
    }));
  };

  const handleOnSelectSuggestion = (value) => {
    setMealValues((prev) => ({
      ...prev,
      description: value,
    }));
    setTextMatches(SUGGESTIONS);
  };

  return (
    <S.MealWrapper data-testid="meals-card">
      <S.RowWrapper>
        <Header
          sectionTitle={t('sections.card.meals.title')}
          student={student}
        />

        <Checkbox
          checked={mealValues.not_ate}
          onChange={() =>
            setMealValues((prev) => ({ ...prev, not_ate: !prev.not_ate }))
          }
        >
          <Text variant="label-regular-16" marginBottom="0">
            {t('sections.card.meals.checkbox_label')}
          </Text>
        </Checkbox>
      </S.RowWrapper>

      <S.ContentBox>
        {t('sections.card.meals.category')}
        <Select
          fullWidth
          placeholder={t('sections.card.meals.category_placeholder')}
          options={OPTIONS}
          value={mealValues.category}
          onChange={(option) =>
            setMealValues((prev) => ({ ...prev, category: option.value }))
          }
        />
      </S.ContentBox>

      <S.ContentBox>
        <Box>
          <TextField
            fullWidth
            label={t('sections.card.meals.description')}
            placeholder={t('sections.card.meals.description_placeholder')}
            value={mealValues.description}
            onChange={(value) => handleOnchange(value.target.value)}
            onFocus={() => setShowSuggestions(true)}
            onBlur={() =>
              setTimeout(() => {
                setShowSuggestions(false);
              }, 200)
            }
          />

          {showSuggestions && !!textMatches.length && (
            <S.SuggestionsGroup>
              {textMatches.map((match, index) => (
                <Box
                  className="option"
                  cursor="pointer"
                  key={index}
                  onClick={() => handleOnSelectSuggestion(match)}
                  borderRadius={border.radius.md}
                  overflow="hidden"
                  m="xs2"
                >
                  <Text variant="label-regular-16" mb={0} px="xs" py="xs">
                    {match}
                  </Text>
                </Box>
              ))}
            </S.SuggestionsGroup>
          )}
        </Box>
      </S.ContentBox>

      {footer}
    </S.MealWrapper>
  );
};
