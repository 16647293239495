import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/Button';
import ButtonRow from 'components/ButtonRow';
import ModalContainer from 'components/Modal/ModalContainer';
import ModalTitle from 'components/Modal/ModalTitle';
import PageSubTitle from 'components/PageSubTitle';

const SchoolProductModal = ({
  isOpen,
  title,
  subtitle,
  labelButton,
  onClick,
}) => (
  <ModalContainer isOpen={isOpen} toggleModal={onClick}>
    <ModalTitle>{title}</ModalTitle>
    <PageSubTitle>{subtitle}</PageSubTitle>
    <ButtonRow>
      <Button onClick={onClick}>{labelButton}</Button>
    </ButtonRow>
  </ModalContainer>
);

SchoolProductModal.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.node.isRequired,
  labelButton: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default SchoolProductModal;
