import styled from 'styled-components';

export const SettingsDescription = styled.p`
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 14px;
  margin: 0 20px 24px;

  @media (max-width: 890px) {
    margin: 0 0 24px;
  }
`;

export const DiarySectionContainer = styled.div`
  .row {
    margin: 0;
  }
`;
