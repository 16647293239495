import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import HandoutContext from 'core/contexts/Handout';
import useGoogleDrive from 'core/hooks/useGoogleDrive';
import withAppContext from 'core/hoc/withAppContext';

import HandoutsActions from 'store/handouts/actions';
import { HandoutState } from 'store/handouts/types';

import * as S from './styles';

import FormFullScreen from 'components/FormFullScreen';
import GeneralInformationStep from '../GeneralInformationStep';
import RecipientsStep from 'components/Form/RecipientsTab';
import Toast from 'components/Toast';

import { FormSkeleton } from '../skeleton';

import { Props } from './types';

const WIZARD_STEPS = [GeneralInformationStep, RecipientsStep];

const ApplyHandoutTemplate: React.FC<Props> = ({
  appContext: { dataArea },
  match,
}) => {
  const { id: templateId } = match?.params;

  const { t } = useTranslation(['handouts']);

  const dispatch = useDispatch();

  const { formMeta, current, isSending } = useSelector(
    (state: HandoutState) => state.handouts
  );

  const googleClientId = process.env.GOOGLE_CLIENT_ID;

  const googleClient = useGoogleDrive(
    googleClientId,
    'https://www.googleapis.com/auth/drive.readonly'
  );

  const hasTemplate = current?.title && current.description;

  const {
    applyHandoutTemplateRequest,
    createNewHandoutRequest,
    fetchClassrooms,
  } = HandoutsActions;

  const handleOnSubmit = (form) => {
    dispatch(createNewHandoutRequest(form));
  };

  const handleFetchClassrooms = useCallback(() => {
    dispatch(fetchClassrooms());
  }, [dispatch, fetchClassrooms]);

  useEffect(() => {
    handleFetchClassrooms();
  }, [handleFetchClassrooms]);

  useEffect(() => {
    dispatch(applyHandoutTemplateRequest(templateId));
  }, [dispatch, applyHandoutTemplateRequest, templateId]);

  return (
    <S.HandoutsFormContainer>
      <HandoutContext.Provider value={googleClient}>
        {hasTemplate && formMeta?.classrooms.length ? (
          <FormFullScreen
            action="apply"
            id="aplyHandoutTemplate"
            form={current}
            initialAttributes={current}
            formMeta={{
              ...formMeta,
              googleClientId,
              canEditClassrooms: true,
              select_options: {
                classroom_id: formMeta.classrooms,
              },
              student_profiles: current.student_profiles,
            }}
            titlePage={t('new_handout_form.title')}
            backTo={`/${dataArea}/handouts/templates`}
            backToExternal
            isSubmitting={isSending}
            steps={WIZARD_STEPS}
            onSubmit={handleOnSubmit}
            titleModal={t('modals.handout_form_discard.title')}
            contentModal={t('modals.handout_form_discard.description')}
          />
        ) : (
          <FormSkeleton
            title={t('new_handout_form.title')}
            dataArea={dataArea}
          />
        )}
      </HandoutContext.Provider>
      <Toast />
    </S.HandoutsFormContainer>
  );
};

export default withAppContext(ApplyHandoutTemplate);
