import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import Field from 'components/Form/Field';
import Button from 'components/Button';
import AgendaIcon from 'components/AgendaIcon';
import TextFieldWithSuggestions from 'components/TextFieldWithSuggestions';

import SectionCard from 'pages/dailySummaries/DailySummaryForm/StudentSections/Card';

import withFormContext from 'core/hoc/withFormContext';
import DailySummariesContext from 'core/contexts/DailySummaries';

import * as S from './styles';

const MealCard = ({ meal, addMeal, index, removeMeal }) => {
  const {
    canShowField,
    handleForm,
    form: { meals },
  } = useContext(DailySummariesContext);

  const { id, category, notEat, description } = meal;

  const OPTIONS = [
    {
      label: 'Selecione',
      value: '',
    },
    {
      label: 'Lanche da manhã',
      value: 'breakfast',
    },
    {
      label: 'Almoço',
      value: 'lunch',
    },
    {
      label: 'Lanche da tarde',
      value: 'afternoon_snack',
    },
    {
      label: 'Jantar',
      value: 'dinner',
    },
    {
      label: 'Outros',
      value: 'others',
    },
  ];

  const SUGGESTIONS = [
    'Recusou',
    'Menos da metade',
    'Metade',
    'Mais da metade',
    'Comeu tudo',
    'Repetiu',
  ];

  const toggleNotEat = () => {
    meals[index] = {
      ...meals[index],
      notEat: !notEat,
    };

    handleForm('meals', meals);
  };

  const handleMealChange = (value) => {
    meals[index] = {
      ...meals[index],
      ...value,
    };
    handleForm('meals', meals);
  };

  return (
    <S.MultipleMealContainer>
      <SectionCard
        title="Refeição"
        checkboxLabel="Não comeu"
        isChecked={notEat}
        onChangeCheckbox={toggleNotEat}
        canCollapse={false}
      >
        <Field
          fullWidth
          type="selectWithIcon"
          withArrowSelect
          placeholder="Selecionar"
          options={OPTIONS}
          label="Categoria"
          value={category}
          onChange={(e) => handleMealChange({ category: e.value })}
        />
        <S.TextAreaContainer className="Field vertical col-md-12 col-xs-12">
          <label htmlFor="description-field">Descrição</label>
          <TextFieldWithSuggestions
            placeholder="Ex. comeu uma maçã"
            id="meal-description-field"
            onChange={(value) => handleMealChange({ description: value })}
            value={description}
            suggestions={SUGGESTIONS}
          />
        </S.TextAreaContainer>
      </SectionCard>
      {canShowField('Refeição') && (
        <S.MealActionsContainer>
          <Button
            variation="secondary"
            icon="plus"
            onClick={addMeal}
            aria-label="Adicionar refeição"
            id="add-button-meal"
          />
          <button
            variation="secondary"
            onClick={() => removeMeal(id)}
            aria-label="Remover refeição"
            id="remove-button-meal"
          >
            <AgendaIcon name="trash" size="small" />
          </button>
        </S.MealActionsContainer>
      )}
    </S.MultipleMealContainer>
  );
};

MealCard.propTypes = {
  meal: {
    id: PropTypes.string,
    category: PropTypes.string,
    description: PropTypes.string,
  },
  addMeal: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  removeMeal: PropTypes.func.isRequired,
};

export default withFormContext(MealCard);
