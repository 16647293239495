import { CAN_NEGOTIATE_RECURRENT_BILL_BY_STATUS } from 'core/constants';
import createModuleReducer from '../../storeConfig/createModuleReducer';
import actions from './actions';
import { NegotiationInitialState, SetSelectedBillsAction } from './types';

export const INITIAL_STATE: NegotiationInitialState = {
  negotiations: null,
  currentNegotiationInitialData: null,
  currentPlanId: null,
  isLoading: false,
  sending: false,
  error: null,
  totalItemsCount: null,
  selectedRecurrentBills: [],
  selectedNegotiations: [],
  selectedAmount: 0,
  hasBatchSelection: false,
  wasSelectedAllBills: false,
};

export const fetchNegotiationInitialData = (state) => ({
  ...state,
  currentNegotiationInitialData: null,
  isLoading: true,
});

export const fetchSuccessNegotiationInitialData = (state, action) => ({
  ...state,
  currentNegotiationInitialData: action.data,
  isLoading: false,
});

export const fetchNegotiations = (state) => ({
  ...state,
  negotiations: null,
  isLoading: true,
});

export const fetchSuccessNegotiations = (state, action) => ({
  ...state,
  totalItemsCount: action.totalItemsCount,
  negotiations: action.data,
  isLoading: false,
});

export const postNegotiationRequest = (state) => ({
  ...state,
  sending: true,
});

export const postNegotiationSuccess = (state) => ({
  ...state,
  sending: false,
  currentNegotiationInitialData: null,
});

export const setCurrentPlanId = (state, action) => ({
  ...state,
  currentPlanId: action.currentPlanId,
});

export const fetchError = (state, action) => ({
  ...state,
  error: action.error,
  isLoading: false,
});

export const setSelectAllRecurrentBills = (state, action) => {
  const { isDebugUser, recurrentBills } = action;
  if (state.wasSelectedAllBills) {
    return {
      ...state,
      selectedRecurrentBills: [],
      selectedAmount: 0,
      hasBatchSelection: false,
      wasSelectedAllBills: false,
    };
  }

  const canNegotiate = (recurrentBill) =>
    CAN_NEGOTIATE_RECURRENT_BILL_BY_STATUS.includes(
      recurrentBill.attributes.status
    );

  const idsEligibleToNegotiate = recurrentBills
    .filter((recurrentBill) =>
      canNegotiate(recurrentBill)
        ? (isDebugUser &&
            recurrentBill.attributes.is_wallet_guaranteed_monthly_fee) ||
          (!isDebugUser &&
            !recurrentBill.attributes.is_wallet_guaranteed_monthly_fee)
        : false
    )
    .map((recurrentBill) => recurrentBill.id);

  const amount = idsEligibleToNegotiate.length;

  return {
    ...state,
    selectedRecurrentBills: idsEligibleToNegotiate,
    selectedAmount: amount,
    hasBatchSelection: !!amount,
    wasSelectedAllBills: !!amount,
  };
};

export const setSelectedRecurrentBills = (
  state: NegotiationInitialState,
  action: SetSelectedBillsAction
) => {
  const newSelectedRecurrent = state.selectedRecurrentBills ?? [];

  const existInArray = newSelectedRecurrent.indexOf(action.id);

  !newSelectedRecurrent.includes(action.id)
    ? newSelectedRecurrent.push(action.id)
    : newSelectedRecurrent.splice(existInArray, 1);

  const amount = newSelectedRecurrent.length;

  return {
    ...state,
    selectedRecurrentBills: newSelectedRecurrent,
    selectedAmount: amount,
    hasBatchSelection: !!amount,
    wasSelectedAllBills: false,
  };
};

export const clearSelectedBills = (state: NegotiationInitialState) => {
  return {
    ...state,
    selectedRecurrentBills: [],
    selectedNegotiations: [],
    selectedAmount: 0,
    hasBatchSelection: false,
    wasSelectedAllBills: false,
  };
};

export const setSelectNegotiation = (state, action) => {
  const newSelectedNegotiations = state.selectedNegotiations ?? [];

  const existInArray = newSelectedNegotiations.indexOf(action.id);

  !newSelectedNegotiations.includes(action.id)
    ? newSelectedNegotiations.push(action.id)
    : newSelectedNegotiations.splice(existInArray, 1);

  return {
    ...state,
    selectedNegotiations: newSelectedNegotiations,
  };
};

const HANDLERS = {
  [actions.FETCH_REQUEST_NEGOTIATION_INITIAL_DATA]: fetchNegotiationInitialData,
  [actions.FETCH_REQUEST_NEGOTIATIONS]: fetchNegotiations,
  [actions.FETCH_SUCCESS_NEGOTIATIONS]: fetchSuccessNegotiations,
  [actions.FETCH_SUCCESS_NEGOTIATION_INITIAL_DATA]:
    fetchSuccessNegotiationInitialData,
  [actions.POST_NEGOTIATION_REQUEST]: postNegotiationRequest,
  [actions.POST_NEGOTIATION_SUCCESS]: postNegotiationSuccess,
  [actions.SET_CURRENT_PLAN_ID]: setCurrentPlanId,
  [actions.FETCH_ERROR]: fetchError,
  [actions.SET_SELECTED_RECURRENT_BILLS]: setSelectedRecurrentBills,
  [actions.SET_SELECT_ALL_RECURRENT_BILLS]: setSelectAllRecurrentBills,
  [actions.CLEAR_SELECTED_BILLS]: clearSelectedBills,
  [actions.SET_SELECT_NEGOTIATION]: setSelectNegotiation,
};

const negotiations = createModuleReducer(INITIAL_STATE, HANDLERS);

export default negotiations;
