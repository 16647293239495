const actions = {
  ERROR: 'ERROR/myAccount',
  FETCH_MULTIMEDIA_CONSUMPTION_REQUEST: 'FETCH_MULTIMEDIA_CONSUMPTION_REQUEST/multimediaConsumption',
  FETCH_MULTIMEDIA_CONSUMPTION_SUCCESS: 'FETCH_MULTIMEDIA_CONSUMPTION_SUCCESS/multimediaConsumption',
  fetchMultimediaConsumption: () => ({ type: actions.FETCH_MULTIMEDIA_CONSUMPTION_REQUEST }),
  FETCH_SCHOOL_BILLS_REQUEST: 'FETCH_SCHOOL_BILLS_REQUEST/school_bills',
  FETCH_SCHOOL_BILLS_SUCCESS: 'FETCH_SCHOOL_BILLS_SUCCESS/school_bills',
  fetchSchoolBills: () => ({ type: actions.FETCH_SCHOOL_BILLS_REQUEST }),
};

export default actions;
