import React, { useEffect, useCallback, useRef } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, Icon, SubtleIconButton } from '@agendaedu/ae-web-components';

import * as S from './styles';

import { MESSAGE_GROUP_IMAGES } from 'core/constants';
import withAppContext from 'core/hoc/withAppContext';
import { useLaunchDarkly } from 'core/hooks/useLaunchDarkly';

import messagesActions from 'store/messages/actions';
import { MessageProps, MessageState } from 'store/messages/types';

import EmptyState from 'components/EmptyState';
import MessageBalloonSkeleton from 'components/Messages/MessageGroup/MessageBalloon/skeleton';
import { GroupDeleteModal } from 'components/Messages/Modals/GroupDeleteModal';
import { GroupLeaveModal } from 'components/Messages/Modals/GroupLeaveModal';
import GroupDetailsModal from 'components/Messages/Modals/GroupDetailsModal';
import GroupDetailsModalOld from 'components/Messages/Modals/GroupDetailsModalOld';
import { GroupInputText } from '../GroupInputText';
import { GroupDeleteMessageModal } from 'components/Messages/Modals/GroupDeleteMessageModal';
import { GroupMessageBalloon } from '../GroupMessageBalloon';

import { Props } from './types';

const GroupChat: React.FC<Props> = ({
  appContext: {
    policies: { can_destroy_group: canDestroyGroup },
  },
}) => {
  const { t } = useTranslation(['messages']);
  const dispatch = useDispatch();

  const soundNotificationRef = useRef<HTMLAudioElement>(
    new Audio(
      'https://static.agendaedu.com/communication/group/notification.mp3'
    )
  );

  const {
    messages,
    currentGroup,
    pagination: { page: currentPage, per_page: itemsPerPage },
    showDetailModal,
    isLoading,
    isLoadingMessages,
    isLoadingMoreMessages,
    isSoundNotification,
  } = useSelector((state: MessageState) => state.messages);

  const { isActiveFlag } = useLaunchDarkly({ flag: 'GROUPS_V2' });

  const {
    id: groupId,
    attributes: {
      name,
      enable_sound: enableSound,
      icon,
      user_permissions: { can_exit: canExit },
    },
  } = currentGroup;

  const {
    clearCurrentGroup,
    fetchMessagesRequest,
    toggleDeleteGroupModal,
    toggleLeaveGroupModal,
    toggleModal,
    toggleDetailModal,
    toggleNotificationModal,
  } = messagesActions;

  const fetchMessages = useCallback(
    (page: number) => {
      const params = { groupId, page };

      dispatch(fetchMessagesRequest(params));
    },
    [dispatch, fetchMessagesRequest, groupId]
  );

  const handleClearCurrentGroup = useCallback(() => {
    dispatch(clearCurrentGroup());
  }, [dispatch, clearCurrentGroup]);

  const handleToggleDeleteGroupModal = useCallback(() => {
    dispatch(toggleDeleteGroupModal());
  }, [dispatch, toggleDeleteGroupModal]);

  const handleToggleDetailModal = useCallback(() => {
    dispatch(toggleDetailModal());
  }, [dispatch, toggleDetailModal]);

  const handleToggleLeaveGroupModal = useCallback(() => {
    dispatch(toggleLeaveGroupModal());
  }, [dispatch, toggleLeaveGroupModal]);

  const handleToggleModal = useCallback(() => {
    dispatch(toggleModal());
  }, [dispatch, toggleModal]);

  const handleToggleNotificationModal = useCallback(() => {
    dispatch(toggleNotificationModal());
  }, [dispatch, toggleNotificationModal]);

  const renderActionsItems = () => {
    const actions = [];

    actions.push({
      as: 'button',
      title: enableSound
        ? t('groups.chat.silence_group_title')
        : t('groups.chat.active_notification_title'),
      onClick: handleToggleNotificationModal,
    });

    isActiveFlag &&
      actions.push({
        as: 'button',
        title: t('groups.chat.details_title'),

        onClick: handleToggleDetailModal,
      });

    canDestroyGroup &&
      !isActiveFlag &&
      actions.push({
        as: 'button',
        title: t('groups.chat.archive_group_title'),
        onClick: handleToggleModal,
      });

    canExit &&
      isActiveFlag &&
      actions.push({
        as: 'button',
        title: t('groups.chat.leave_group_title'),
        onClick: handleToggleLeaveGroupModal,
      });

    canDestroyGroup &&
      isActiveFlag &&
      actions.push({
        as: 'button',
        title: t('groups.chat.delete_group_title'),
        onClick: handleToggleDeleteGroupModal,
      });

    return actions;
  };

  const hasMoreMessages = () => {
    const totalNumberOfMessages = currentPage * itemsPerPage;
    return totalNumberOfMessages === messages.length;
  };

  const fetchMoreMessages = () => {
    const page = currentPage + 1;
    fetchMessages(page);
  };

  const renderMessages = () => {
    if (isLoading || isLoadingMessages) {
      return (
        <>
          <MessageBalloonSkeleton variantType="received" />
          <MessageBalloonSkeleton variantType="sent" />
        </>
      );
    } else if (messages.length > 0) {
      return (
        <InfiniteScroll
          dataLength={messages.length}
          next={fetchMoreMessages}
          hasMore={hasMoreMessages()}
          loader={
            isLoadingMoreMessages && (
              <>
                <MessageBalloonSkeleton variantType="received" />
                <MessageBalloonSkeleton variantType="sent" />
              </>
            )
          }
          inverse={true}
          scrollableTarget="chat-content-wrapper"
        >
          {messages.map((message: MessageProps) => (
            <GroupMessageBalloon key={message.id} message={message} />
          ))}
        </InfiniteScroll>
      );
    } else {
      return (
        <S.EmptyWrapper>
          <EmptyState
            message={t('groups.chat.empty_state')}
            imgUrl={MESSAGE_GROUP_IMAGES.no_messages}
          />
        </S.EmptyWrapper>
      );
    }
  };

  useEffect(() => {
    fetchMessages(1);
  }, [fetchMessages]);

  useEffect(() => {
    const { current: soundNotification } = soundNotificationRef;

    if (!soundNotification) return;

    if (isSoundNotification) soundNotification.play();
  }, [isSoundNotification]);

  return (
    <S.GroupChatWrapper>
      <S.ChatHeaderWrapper data-testid="chat-header-wrapper">
        <S.ChatHeaderContainer>
          <S.BackButton
            isOnlyIcon
            icon="chevron-left"
            variant="secondary"
            onClick={handleClearCurrentGroup}
            data-testid="back-button"
          />
          <S.ChatHeaderContent
            onClick={handleToggleDetailModal}
            data-testid="chat-header-content"
          >
            <S.AvatarWrapper>
              {icon?.url ? (
                <S.Avatar data-testid="group-avatar" src={icon.url} />
              ) : (
                <S.EmptyAvatarWrapper data-testid="empty-avatar">
                  <Icon name="user-profile" />
                </S.EmptyAvatarWrapper>
              )}
            </S.AvatarWrapper>

            <Box display="flex" flexDirection="column" gap="8px">
              <S.ChatHeaderTitle variant="subtitle-medium-14">
                {t('groups.chat.header_title')}
              </S.ChatHeaderTitle>
              <S.ChatHeaderSubtitle variant="button-bold-16">
                {name}
              </S.ChatHeaderSubtitle>
            </Box>
          </S.ChatHeaderContent>
        </S.ChatHeaderContainer>

        <SubtleIconButton
          data-testid="options-button"
          actions={renderActionsItems()}
          align="right"
          size="lg"
        />
      </S.ChatHeaderWrapper>

      <S.ChatContentWrapper
        id="chat-content-wrapper"
        data-testid="chat-content-wrapper"
      >
        {renderMessages()}
      </S.ChatContentWrapper>

      <GroupInputText />

      {showDetailModal &&
        (isActiveFlag ? (
          <GroupDetailsModal />
        ) : (
          <GroupDetailsModalOld
            dataTestId="group-detail-modal"
            title={t('groups.chat.model_details_title')}
            isOpen={showDetailModal}
            group={currentGroup}
            toggleModal={handleToggleDetailModal}
          />
        ))}
      <GroupDeleteModal />
      <GroupLeaveModal />
      <GroupDeleteMessageModal />
    </S.GroupChatWrapper>
  );
};

export default withAppContext(GroupChat);
