import validatePresence from 'core/lib/FormValidator/validators/presence';
import validateLength from 'core/lib/FormValidator/validators/length';

const validations = [];

export const errorIndexOptionsPresence = [];
export const errorIndexOptionsQuantity = [];

function validateOptionsQuantity(input, state) {
  errorIndexOptionsQuantity.splice(0, errorIndexOptionsQuantity.length);
  const allQuestions = state.survey_questions_attributes.filter(
    (question, indexQuestion) => {
      const options = question.survey_options_attributes;
      const question_type = question.question_type;
      if (options.length < 2 && question_type !== 'subjective')
        errorIndexOptionsQuantity.push(indexQuestion);
      return options.length > 1 || question_type === 'subjective';
    }
  );

  if (allQuestions.length === state.survey_questions_attributes.length)
    return true;
  return false;
}

function validateOptionsPresence(input, state) {
  errorIndexOptionsPresence.splice(0, errorIndexOptionsPresence.length);
  const allOptions = state.survey_questions_attributes.filter(
    (question, indexQuestion) => {
      const options = question.survey_options_attributes;
      const blankOptions = options.filter((item) => {
        if (item.title === '') errorIndexOptionsPresence.push(indexQuestion);
        return item.title === '';
      }).length;
      return blankOptions > 0;
    }
  );

  if (allOptions.length > 0) return false;
  return true;
}

export function loadValidations(surveyQuestions) {
  validations.splice(0, validations.length);
  errorIndexOptionsPresence.splice(0, errorIndexOptionsPresence.length);
  errorIndexOptionsQuantity.splice(0, errorIndexOptionsQuantity.length);

  validations.push({
    attributeName: 'quantity_error',
    validator: validateOptionsQuantity,
    message: 'Precisa ter no mínimo duas opções',
  });

  validations.push({
    attributeName: 'unfilled_option_error',
    validator: validateOptionsPresence,
    message: 'Todas as opções devem ser preenchidas',
  });

  for (
    let indexQuestion = 0;
    indexQuestion < surveyQuestions.length;
    indexQuestion++
  ) {
    validations.push({
      attributeName: `question_${indexQuestion}`,
      validator: validatePresence,
      message: 'Campo obrigatório',
    });

    validations.push({
      attributeName: `question_${indexQuestion}`,
      validator: validateLength({ max: 500 }),
      message: 'Máximo: 500 caracteres',
    });
  }
}

export function addValidations(index) {
  validations.push({
    attributeName: `question_${index}`,
    validator: validatePresence,
    message: 'Campo obrigatório',
  });

  validations.push({
    attributeName: `question_${index}`,
    validator: validateLength({ max: 500 }),
    message: 'Máximo: 500 caracteres',
  });
}

export default validations;
