import FormFieldset from 'components/Form/Fieldset';
import { ThemeProps } from 'config/themes/types';
import styled, { css } from 'styled-components';

export const HandoutsFormContainer = styled.main`
  .WizardForm .wizard-content .wizard-taps-header .PageTitle {
    min-width: fit-content;
  }
`;

export const HandoutInformationStepWrapper = styled(FormFieldset)``;

export const FormSkeletonWrapper = styled.div`
  ${({ theme: { breakpoints, colors, space } }: ThemeProps) => css`
    height: 100%;
    max-width: 100%;
    padding: ${space.xs4} 0;
    margin: ${space.xl3} 0;
    background-color: ${colors.neutral.white};

    @media only screen and (min-width: ${breakpoints.desktopLG}) {
      margin: ${space.xl3} 0;
    }

    @media only screen and (min-width: ${breakpoints.desktopXL}) {
      padding: ${space.xs4} 0;
    }
  `}
`;
