import { all, put, takeLatest, select, call } from 'redux-saga/effects';
import {
  buildToast,
  toastCss,
  ToastTypes,
} from 'store/middlewares/toast/actions';
import { handleError, fetchApi } from 'core/utils/api';

import actions from './actions';
import {
  RequestEducationalStagesSagaParams,
  RequestClassroomsSagaParams,
  FetchAnswersActionType,
} from './types';

function* fetchHeadquarters() {
  try {
    const { dataArea } = yield select((state) => state.root);

    const { data } = yield call(
      fetchApi,
      `/${dataArea}/headquarters/with_active_school_term`
    );

    yield put({
      type: actions.FETCH_HEADQUARTERS_SUCCESS,
      headquarters: data,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        handleError(error),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* fetchEducationalStages(action: RequestEducationalStagesSagaParams) {
  try {
    const { dataArea } = yield select((state) => state.root);
    const { headquarter } = action;

    const { data } = yield call(
      fetchApi,
      `/${dataArea}/headquarters/${headquarter}/educational_stages`
    );

    yield put({
      type: actions.FETCH_EDUCATIONAL_STAGES_SUCCESS,
      educationalStages: data,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
    });
  }
}

function* fetchClassrooms(action: RequestClassroomsSagaParams) {
  try {
    const { dataArea } = yield select((state) => state.root);
    const { headquarter, educationalStage } = action;

    const { data } = yield call(
      fetchApi,
      `/${dataArea}/headquarters/${headquarter}/${educationalStage}/classrooms`
    );

    yield put({
      type: actions.FETCH_CLASSROOMS_SUCCESS,
      classrooms: data,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
    });
  }
}

function* fetchAnswers(action: FetchAnswersActionType) {
  try {
    const { dataArea } = yield select((state) => state.root);

    const { date, headquarter, educationalStage, classroom, page, answerType } =
      action.params;

    const config = {
      params: {
        headquarter_id: headquarter,
        educational_stage_id: educationalStage,
        classroom_id: classroom,
        date,
        answer_type: answerType,
        page,
      },
    };

    const {
      history: {
        data,
        meta: { answers_count },
      },
    } = yield call(fetchApi, `/${dataArea}/moods.json`, config);

    yield put({
      type: actions.FETCH_ANSWERS_SUCCESS,
      studentsList: data,
      answers: answers_count,
      filters: action.params,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        handleError(error),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* moodTrackersSagas() {
  yield all([
    takeLatest(actions.FETCH_ANSWERS, fetchAnswers),
    takeLatest(actions.FETCH_HEADQUARTERS, fetchHeadquarters),
    takeLatest(actions.FETCH_EDUCATIONAL_STAGES, fetchEducationalStages),
    takeLatest(actions.FETCH_CLASSROOMS, fetchClassrooms),
  ]);
}

export default moodTrackersSagas;
