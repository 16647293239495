import React, { useEffect, useState } from 'react';

import Loader from 'components/Loader';
import Toast from 'components/Toast';
import RecurrentPlanForm from 'components/Payments/Recurrent/RecurrentPlanForm';
import NegotiationFormInformationsTab from 'components/Payments/Recurrent/NegotiationForm/NegotiationFormInformationsTab';
import NegotiationFormPaymentTab from 'components/Payments/Recurrent/NegotiationForm/NegotiationFormPaymentTab';
import withAppContext from 'core/hoc/withAppContext';
import { useDispatch, useSelector } from 'react-redux';
import negotiationsActions from 'store/edupay/negotiations/actions';
import SelectOptions from './SelectOptions';
import NegotiationConfirmSaveModal from './NegotiationConfirmSaveModal';
import { InformationForm, PaymentForm } from '../NegotiationForm/types';
import normalizeParams from './normalizeParams';

const WIZARD_STEPS = [
  NegotiationFormInformationsTab,
  NegotiationFormPaymentTab,
];

const NewNegotiation = (props) => {
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [latestNegotiationForm, setLatestNegotiationForm] = useState(
    {} as InformationForm & PaymentForm
  );

  const dispatch = useDispatch();

  const { fetchNegotiationInitialData, postNegotiation } = negotiationsActions;

  const {
    isLoading,
    currentNegotiationInitialData,
    currentPlanId,
    selectedRecurrentBills,
  } = useSelector((state) => state.negotiations);

  useEffect(() => {
    dispatch(fetchNegotiationInitialData());
  }, []);

  const handleOnSubmit = (formContext) => {
    const { form } = formContext;
    setIsConfirmationModalOpen(true);
    setLatestNegotiationForm(form);
  };

  const handleSave = () => {
    const params = normalizeParams({
      form: latestNegotiationForm,
      initialData: currentNegotiationInitialData,
      currentPlanId: currentPlanId,
    });

    setIsConfirmationModalOpen(false);
    dispatch(postNegotiation(params));
  };

  const getForm = (): InformationForm & PaymentForm => ({
    recipient_wallet_id: null,
    name: currentNegotiationInitialData.attributes.financial_responsible.name,
    cpf: currentNegotiationInitialData.attributes.financial_responsible.cpf,
    selected_min_value_to_negotiate: 'subtotal_with_fine_and_interest',
    selected_value_cents: 0,
    negotiationBills: [
      {
        price_value: 0,
        price_kind: 'money',
        discount_kind: 'percent',
        discount_value: 0,
        key: Math.random().toString(36).substring(7),
      },
    ],
    allowed_payment_method: ['billet'],
    toggle_penality: true,
    bill_release_day: null,
    edupay_penality: {
      fine_percent: 2,
      interest_percent: 1,
    },
    observation: '',
    first_bill_due_date: null,
    due_day: null,
    isSubtotalValid: false,
    negotiationSubtotal: 0,
  });

  const getFormMeta = () => ({
    derivativeRecurrentBills:
      currentNegotiationInitialData.attributes.derivative_recurrent_bills
        ?.data ?? null,
    originalRecurrentBills: currentNegotiationInitialData,
    select_options: { ...SelectOptions() },
    selectedRecurrentBills,
  });

  return (
    <div className="NewNegotiation">
      <Loader isLoading={isLoading || !currentNegotiationInitialData}>
        {currentNegotiationInitialData && (
          <RecurrentPlanForm
            action="new"
            steps={WIZARD_STEPS}
            form={getForm()}
            formMeta={getFormMeta()}
            isSubmitting={false}
            onSubmit={handleOnSubmit}
            backTo={`/schools/recurrent/recurrent_plans/${currentPlanId}`}
            titlePage="Nova negociação"
            titleModal="Descartar preenchimento"
            contentModal="O cadastro de uma negociação será cancelado e as informações preenchidas serão descartadas. Tem certeza que deseja descartar?"
          />
        )}

        <Toast />
        {isConfirmationModalOpen && (
          <NegotiationConfirmSaveModal
            isConfirmModalOpen={true}
            handleSave={handleSave}
            handleClose={() => setIsConfirmationModalOpen(false)}
            form={latestNegotiationForm}
            formMeta={getFormMeta()}
          />
        )}
      </Loader>
    </div>
  );
};

export default withAppContext(NewNegotiation);
