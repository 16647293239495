import styled, { css } from 'styled-components';
import { ThemeProps } from 'config/themes/types';

import { Grid } from '@agendaedu/ae-web-components';

export const WrapperCard = styled(Grid)`
  ${({ theme: { colors, border, space } }: ThemeProps) => css`
    width: 100%;
    grid-template-areas: 'date date date date user user action action action action action action';
    align-items: center;
    padding: ${space.sm} ${space.sm} ${space.sm} ${space.lg};
    border: ${border.width.sm} solid ${colors.neutral.gray3};
    border-radius: ${border.radius.md};
    height: min-content;
  `}
`;
