import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { ThemeProps } from 'config/themes/types';

export const ContractModelListWrapper = styled.div`
  ${({ theme: { breakpoints, colors, space } }: ThemeProps) => css`
    min-height: calc(100vh - 80px);
    padding: ${space.xl};
    background-color: ${colors.neutral.white};

    .ae-flex-grid {
      align-items: center;
    }

    @media screen and (min-width: ${breakpoints.mobileSM}) and (max-width: ${breakpoints.desktopXL}) {
      padding: ${space.xl2} ${space.xl};
    }

    @media screen and (min-width: ${breakpoints.desktopXL}) {
      padding: ${space.xl} ${space.xl5};
    }
  `}
`;

export const InfoWrapper = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    margin-bottom: ${space.xl2};
  `}
`;

export const BackButton = styled(Link)`
  ${({ theme: { typography, colors } }: ThemeProps) => css`
    color: ${colors.brand.primary};
    ${typography.title.Bold14};
    :hover {
      cursor: pointer;
    }
  `}
`;
