import React from 'react';
import { ThemeProvider } from 'styled-components';
import { StylesProvider } from '@agendaedu/ae-web-components';
import themes from 'config/themes';
import AppContext from 'core/contexts/App';
import getHocName from 'core/utils/getHocName';

/**
 * @todo Adjust form components that are not using this hoc.
 * @example
 *    import appContextProvider, { formPropTypes } from 'core/hoc/appContextProvider'
 *
 *    const Component = props => <div>{props.appContext.form.title}</div>
 *
 *    Component.propTypes = {
 *      ...formPropTypes
 *    };
 *
 *    export default appContextProvider(Component)
 */
const appContextProvider = (WrappedComponent) => {
  const AppContextProvider = (props, railsContext) => {
    const theme = themes(railsContext);

    return (
      <AppContext.Provider value={railsContext}>
        <ThemeProvider theme={theme}>
          <StylesProvider theme={theme}>
            <WrappedComponent {...props} />
          </StylesProvider>
        </ThemeProvider>
      </AppContext.Provider>
    );
  };

  AppContextProvider.displayName = getHocName(
    'AppContextProvider',
    WrappedComponent
  );

  return AppContextProvider;
};

export default appContextProvider;
