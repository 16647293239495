import React from 'react';
import PropTypes from 'prop-types';

import AppContext from 'core/contexts/App';
import getHocName from 'core/utils/getHocName';

/**
 * @todo Adjust form components that are not using this core/hoc.
 * @example
 *    import withAppContext, { appPropTypes } from 'core/hoc/withAppContext'
 *
 *    const Component = props => <div>{props.appContext.form.title}</div>
 *
 *    Component.propTypes = {
 *      ...appPropTypes
 *    };
 *
 *    export default withAppContext(Component)
 */
const withAppContext = (WrappedComponent) => {
  const WithAppContext = (props) => {
    return (
      <AppContext.Consumer>
        {(appContext) => {
          return <WrappedComponent {...props} appContext={appContext} />;
        }}
      </AppContext.Consumer>
    );
  };

  WithAppContext.displayName = getHocName('WithAppContext', WrappedComponent);

  return WithAppContext;
};

export const appPropTypes = {
  appContext: PropTypes.shape({
    dataArea: PropTypes.string.isRequired,
    currentUser: PropTypes.object.isRequired,
    currentSchool: PropTypes.object,
    primaryColor: PropTypes.string,
    isWebView: PropTypes.bool,
  }),
};

export default withAppContext;
