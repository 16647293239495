import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

import { useDownloadFile } from 'core/hooks/useDownloadFile';
import { colorHexRadom } from 'core/utils/colorHex';

import Card from 'components/Card';
import AgendaIcon from 'components/AgendaIcon';
import Button from 'components/Button';
import ModalTitle from 'components/Modal/ModalTitle';
import ModalBody from 'components/Modal/ModalBody';
import FileAttachment from 'components/FileAttachment';
import Tooltip from 'components/Tooltip';

import * as S from './styles';

const InfoData = ({ dataRow, activityTitle, googleClientId }) => {
  const { hasErrorMessage } = useDownloadFile({});

  const [isShowModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal((prevShowModal) => !prevShowModal);
  };

  const {
    attributes,
    studentProfile,
    responsibleProfile,
    summarydayClassroomAnswer,
    classroom,
  } = dataRow;

  const getInitialName = () => {
    return {
      attributes: {
        avatar_color: colorHexRadom(),
        name_initials:
          responsibleProfile?.length > 0
            ? responsibleProfile.map((values) =>
                values.attributes?.name.substring(0, 2)
              )
            : '',
      },
    };
  };

  const tooltipContent = (title, value) => {
    return (
      <p>
        {title}
        <p>{value}</p>
      </p>
    );
  };

  const renderBody = () => {
    const username = studentProfile?.map(
      (student) => `${student?.attributes.name}`
    );

    return (
      <S.ContainerBody>
        <S.Content>
          <S.Informations>
            <span>Aluno</span>
            <p className="student-name" title={username}>
              {username}
            </p>
          </S.Informations>
          <S.Informations>
            <span>Visualização</span>
            {attributes.seen_by === 'mobile' ? (
              <Tooltip
                content={tooltipContent(
                  'Visualizado pelo aplicativo',
                  attributes?.seen_at
                )}
              >
                <AgendaIcon name="smartphone" size="x-medium" />
              </Tooltip>
            ) : (
              !!attributes.seen_by && (
                <Tooltip
                  content={tooltipContent(
                    'Visualizado pela web',
                    attributes?.seen_at
                  )}
                >
                  <AgendaIcon name="computer" size="x-medium" />
                </Tooltip>
              )
            )}
          </S.Informations>
          <S.Informations>
            <span>Confirmação</span>
            {attributes.confirm_by === 'mobile' ? (
              <Tooltip
                content={tooltipContent(
                  'Confirmado pelo aplicativo',
                  attributes?.confirmed_at
                )}
              >
                <AgendaIcon name="smartphone" size="x-medium" />
              </Tooltip>
            ) : (
              !!attributes.confirm_by && (
                <Tooltip
                  content={tooltipContent(
                    'Confirmado pela web',
                    attributes?.confirmed_at
                  )}
                >
                  <AgendaIcon name="computer" size="x-medium" />
                </Tooltip>
              )
            )}
          </S.Informations>
          <S.Informations hasButton>
            {summarydayClassroomAnswer.length > 0 ? (
              summarydayClassroomAnswer.map((answer, index) => {
                return (
                  <div key={`answer-${index}`}>
                    {answer.attributes.attachments.length > 0 && (
                      <Button
                        variation="secondary"
                        type="button"
                        icon="eye"
                        onClick={toggleModal}
                      >
                        Ver atividade
                      </Button>
                    )}
                  </div>
                );
              })
            ) : (
              <Button className="not-send-button" icon="eye-blocked" disabled>
                Não enviado
              </Button>
            )}
          </S.Informations>
        </S.Content>
      </S.ContainerBody>
    );
  };

  const renderActivityDetails = () => {
    return (
      <S.Details>
        <S.DetailsLine>
          <S.Title>Atividade</S.Title>
          <S.Subtitle>{activityTitle}</S.Subtitle>
        </S.DetailsLine>
        <S.DetailsLine>
          <S.Title>Aluno</S.Title>
          {classroom.length > 0 &&
            studentProfile.map((student) => (
              <S.Subtitle key={student.type}>
                {student?.attributes.name}
              </S.Subtitle>
            ))}
        </S.DetailsLine>
        <S.DetailsLine>
          <S.Title>Unidade / Segmento / Turma</S.Title>
          {classroom.length > 0 &&
            classroom.map((room) => (
              <S.Subtitle key={room.type}>
                {room.attributes.name_with_headquarter_and_educational_stage}
              </S.Subtitle>
            ))}
        </S.DetailsLine>
        <S.DetailsLine>
          <S.Title>Envio</S.Title>
          {summarydayClassroomAnswer.length > 0 &&
            summarydayClassroomAnswer.map((answer) => (
              <S.Subtitle key={answer.type}>
                {moment(answer.attributes.updatedAt).format(
                  'DD/MM/YYYY [às] HH:MM'
                )}
              </S.Subtitle>
            ))}
        </S.DetailsLine>
        <S.DetailsLine>
          {summarydayClassroomAnswer.length > 0 &&
            summarydayClassroomAnswer.map((answer) => (
              <FileAttachment
                key={answer.attributes.attachments.signed_id}
                attachments={answer.attributes.attachments}
                googleClientId={googleClientId}
                useGoogleDrive
                onlyListAttachment={true}
                onlyDownloadAttachment={true}
              />
            ))}
        </S.DetailsLine>
      </S.Details>
    );
  };

  return (
    <S.Wrapper>
      <Card
        title={
          responsibleProfile?.length > 0
            ? responsibleProfile.map((values) => values.attributes?.name)
            : 'Não identificado'
        }
        subtitle={
          responsibleProfile?.length > 1 ? 'Responsaveis' : 'Responsável'
        }
        avatar={getInitialName()}
        body={renderBody()}
      />

      <S.ModalActivityDetail
        isOpen={isShowModal}
        toggleModal={toggleModal}
        title={<ModalTitle>Recebimento de atividades</ModalTitle>}
      >
        <ModalBody>{renderActivityDetails()}</ModalBody>

        {hasErrorMessage && (
          <S.ErrorMessage>
            Não foi possível fazer download do arquivo.
          </S.ErrorMessage>
        )}
      </S.ModalActivityDetail>
    </S.Wrapper>
  );
};

const DatarowShape = {};

InfoData.propTypes = {
  dataRow: PropTypes.shape(DatarowShape).isRequired,
  activityTitle: PropTypes.string,
  googleClientId: PropTypes.string,
};

export default withRouter(InfoData);
