import React from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar } from '@agendaedu/ae-web-components';

import * as S from './styles';

import Checkbox from 'components/Form/Checkbox';
import { MessageGroupAvatar } from '../OmniChannel/MessageGroupAvatar';

import { RecipientChatCardProps } from './types';

export const RecipientChatCard = ({
  disabled,
  disabledActions,
  isSelected,
  onChange,
  recipient,
  removeItemButton,
}: RecipientChatCardProps): JSX.Element => {
  const { t } = useTranslation(['messages']);

  const isGroupFamily = recipient.type === 'family';
  return (
    <S.Wrapper
      disabled={disabled}
      isSelected={isSelected}
      removeItemButton={removeItemButton}
      isGroupFamily={isGroupFamily}
    >
      <S.OptionItemWrapper>
        {isGroupFamily ? (
          <MessageGroupAvatar
            size="xs"
            nameInitials={recipient.attributes.name_initials}
            avatarColor={recipient.attributes.avatar_color}
            avatarUrl={recipient.attributes.avatar_url}
          />
        ) : (
          <Avatar
            size="xs"
            nameInitials={recipient.attributes.name_initials}
            avatarColor={recipient.attributes.avatar_color}
            avatarUrl={recipient.attributes.avatar_url}
          />
        )}
        <S.OptionItemText variant="body-regular-14">
          {recipient.attributes.name}
        </S.OptionItemText>
      </S.OptionItemWrapper>

      <S.OptionItemWrapper isSelected={isSelected}>
        <S.OptionTag
          isSelected={isSelected}
          size="small"
          name={
            isGroupFamily
              ? t('omni_channel.chat.chat_card_select_text')
              : recipient.attributes.role
          }
          variant="neutral"
        />

        {!disabledActions && (
          <>
            {!removeItemButton ? (
              <Checkbox
                disabled={disabled}
                checked={isSelected}
                onChange={() => onChange(recipient, isSelected)}
              />
            ) : (
              <S.OptionIcon
                name="multiply"
                size="sm"
                onClick={() => {
                  onChange(recipient, isSelected);
                }}
              />
            )}
          </>
        )}
      </S.OptionItemWrapper>
    </S.Wrapper>
  );
};
