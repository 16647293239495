const normalizeParams = (values) => ({
  id: values.id,
  filters: {
    headquarters: values?.headquarters?.selected,
    courses: values?.courses?.selected,
    school_terms: values?.schoolTerms?.selected,
  },
});

export default normalizeParams;
