import React from 'react';
import { CancelAndCreatePaymentProps } from './types';
import withFormContext from 'core/hoc/withFormContext';

import ButtonRow from 'components/ButtonRow';
import Button from 'components/Button';
import Field from 'components/Form/Field';

import * as S from './styles';

function CancelAndCreatePayment({
  formContext,
  toggleModal,
}: CancelAndCreatePaymentProps) {
  const { form, updateAttribute } = formContext;

  const {
    id,
    recurrentPlan,
    attributes: { release_day },
  } = form.bill;

  const isDisabledButton = form?.cancelTerm !== 'QUERO CANCELAR';

  const handleCancelAndCreate = (): void => {
    toggleModal();
  };

  return (
    <S.Container>
      <S.Title>
        A emissão da cobrança do dia <strong>{release_day}</strong> será
        cancelada e não será enviada para o responsável do aluno.
      </S.Title>

      <S.NoteCancelField>
        <Field
          label="Observações (opcional)"
          type="simpleTextArea"
          attributeName="cancelNote"
          onChange={(e) => {
            updateAttribute('cancelNote', e.cancelNote);
          }}
          maxLength="255"
        />
        <S.LabelCounter>{form?.cancelNote?.length || 0}/255</S.LabelCounter>
      </S.NoteCancelField>

      <S.WantToCancelField>
        <S.Label>
          Digite <strong>QUERO CANCELAR</strong> no campo abaixo
        </S.Label>
        <Field
          attributeName="cancelTerm"
          onChange={(e) => {
            updateAttribute('cancelTerm', e.cancelTerm);
          }}
        />
      </S.WantToCancelField>

      <ButtonRow>
        <Button onClick={toggleModal} variation="secondary">
          Voltar
        </Button>
        <S.LinkButton
          isDisabled={isDisabledButton}
          to={{
            pathname: `/schools/recurrent/recurrent_plans/${
              recurrentPlan?.id || recurrentPlan.recurrent_plan_id
            }/recurrent_bills/${id}/cancel_and_clone`,
            state: {
              cancelNote: form?.cancelNote,
            },
          }}
        >
          <Button
            disabled={isDisabledButton}
            variation="danger"
            onClick={handleCancelAndCreate}
          >
            Cancelar e Ir para Nova Cobrança
          </Button>
        </S.LinkButton>
      </ButtonRow>
    </S.Container>
  );
}

export default withFormContext(CancelAndCreatePayment);
