import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';

import actionSchoolProducts from 'store/edupay/schoolProducts/actions';
import { IMAGES_PAYMENT_URL } from 'core/constants/index';
import colorWithAlpha from 'core/utils/colorWithAlpha';
import withAppContext from 'core/hoc/withAppContext';
import withWindowSize from 'core/hoc/withWindowSize';

import Button from 'components/Button';
import ButtonRow from 'components/ButtonRow';
import InternalErrorBoundary from 'boundaries/InternalErrorBoundary/index';
import ModalContainer from 'components/Modal/ModalContainer';
import ModalTitle from 'components/Modal/ModalTitle';
import PageContainer from 'components/PageContainer';
import WalletInformation from 'components/Payments/WalletInformation';

import Popover, { TOP } from 'components/Popover';
import Tab from 'components/Tab';
import Tabs from 'components/Tabs';
import Toast from 'components/Toast';

import SchoolProductsListContainer from 'components/Payments/PaymentsContainer/SchoolProductsListContainer';
import RecurrentPlansListContainer from 'components/Payments/PaymentsContainer/RecurrentPlansListContainer';
import EnrollmentPlansListContainer from 'components/Payments/PaymentsContainer/EnrollmentPlansListContainer';
import ShopListContainer from 'components/Payments/Shop/ShopListContainer';

import './style.scss';

const newPaymentPopover = `Você não tem permissão para cadastrar uma nova
  cobrança. Em caso de dúvida, consulte algum profissional com perfil
  Master, na sua escola.`;

const redirectCurrentTab = {
  schoolproduct: 0,
  recurrent: 1,
  enrollment: 2,
  shop: 3,
};

const tabShopEnable = true;

const PageCharges = ({
  match: {
    params: { tab },
  },
  appContext: { primaryColor, policies },
  windowSize,
}) => {
  const [newModal, setNewModal] = useState(false);
  const [redirectToSchoolProduct, setRedirectToSchoolProduct] = useState(false);
  const [redirectToRecurrentPlan, setRedirectToRecurrentPlan] = useState(false);
  const [redirectToNewOffer, setRedirectToNewOffer] = useState(false);

  const { toggleCurrentTab } = actionSchoolProducts;

  const [redirectToEnrollmentPlan, setRedirectToEnrollmentPlan] =
    useState(false);

  const schoolUser = useSelector((state) => state.root.currentUser);
  const { currentTab } = useSelector((state) => state.schoolProducts);
  const { currentWallet } = useSelector((state) => state.wallets);
  const {
    policies: { can_use_shop },
  } = useSelector((state) => state.root);

  const dispatch = useDispatch();

  const {
    attributes: { is_debug_user: isDebugUser },
  } = schoolUser;

  useEffect(() => {
    dispatch(toggleCurrentTab({ currentTab: redirectCurrentTab[tab] || 0 }));
  }, []);

  useEffect(() => {
    if (can_use_shop)
      window?.kompassifySegmentation?.addUserToSegment('UsaEduPayShop');
  }, [can_use_shop]);

  const toggleNewModal = () => {
    setNewModal(!newModal);
  };

  const newButton = () => {
    if (policies.can_create_school_product) {
      return <Button onClick={toggleNewModal}>CRIAR</Button>;
    }

    return (
      <Popover content={newPaymentPopover} position={TOP}>
        <Button type="submit" disabled>
          <span>NOVA COBRANÇA</span>
        </Button>
      </Popover>
    );
  };

  const StyledPaymentOption = styled.div`
    // change these colors to variables from theme when connect to redux
    color: #666;
    border: solid 2px #eee;

    :hover {
      background-color: ${colorWithAlpha(primaryColor, 0.1)};
      border-color: ${colorWithAlpha(primaryColor, 0.1)};
      color: ${primaryColor};
    }

    :focus,
    :active {
      background-color: ${colorWithAlpha(primaryColor, 0.2)};
    }

    > span {
      color: $black;
      font-weight: 600;
      font-size: 16px;
      margin-bottom: 12px;
    }

    :hover span {
      color: ${primaryColor};
    }
  `;

  const setCorrectlyWidth = () => {
    let additionalButtons = 0;
    if (policies?.can_use_enrollment_plan) additionalButtons += 1;
    if (policies?.can_use_shop) additionalButtons += 1;
    //The minimum size is 600px and for each button, add 280px
    return additionalButtons * 280 + 600 + 'px';
  };

  const renderWallet = () => {
    return <WalletInformation />;
  };

  const listHeader = () => (
    <React.Fragment>
      {(currentWallet?.type != 'securitizer' || isDebugUser) && (
        <ButtonRow>{newButton()}</ButtonRow>
      )}
      <ModalContainer
        isOpen={newModal}
        toggleModal={toggleNewModal}
        className="NewPaymentModal"
        maxWidth={windowSize > 992 ? setCorrectlyWidth() : '415px'}
      >
        <ModalTitle>Criar</ModalTitle>
        <div className="payment-options">
          <StyledPaymentOption
            className="single-payment payment-option"
            onClick={() => setRedirectToSchoolProduct(true)}
          >
            <img
              src={IMAGES_PAYMENT_URL.schoolProductUrl}
              width="80"
              height="80"
              alt="Single payment image"
            />
            <span>Cobrança única</span>
            <p>Pagamentos únicos no boleto, pix ou cartão de crédito.</p>
          </StyledPaymentOption>

          <StyledPaymentOption
            className="recurrent-payment payment-option"
            onClick={() => setRedirectToRecurrentPlan(true)}
          >
            <img
              src={IMAGES_PAYMENT_URL.recurrentPlanUrl}
              width="80"
              height="80"
              alt="recurrent payment image"
            />
            <span>Cobrança recorrente</span>
            <p>
              Cobranças que se repetem <br /> automaticamente, como <br />{' '}
              mensalidades.
            </p>
          </StyledPaymentOption>
          {policies?.can_use_enrollment_plan && (
            <StyledPaymentOption
              className="enrollment-payment payment-option"
              onClick={() => setRedirectToEnrollmentPlan(true)}
            >
              <img
                src={IMAGES_PAYMENT_URL.enrollmentUrl}
                width="80"
                height="80"
                alt="enrollment payment image"
              />
              <span>Matrícula</span>
              <p>
                Cobrança referente a <br /> inscrição de alunos para o <br />{' '}
                ano letivo.
              </p>
            </StyledPaymentOption>
          )}
          {policies?.can_use_shop && (
            <StyledPaymentOption
              className="offer payment-option"
              onClick={() => setRedirectToNewOffer(true)}
            >
              <img
                src={IMAGES_PAYMENT_URL.offerUrl}
                width="80"
                height="80"
                alt="offer payment image"
              />
              <span>Oferta</span>
              <p>
                Cadastre produtos e serviços que serão vendidos através do Shop,
                como materiais e uniformes.
              </p>
            </StyledPaymentOption>
          )}
        </div>
      </ModalContainer>
    </React.Fragment>
  );

  if (redirectToSchoolProduct) {
    return <Redirect to="/schools/school_products/new" />;
  }

  if (redirectToRecurrentPlan) {
    return <Redirect to="/schools/recurrent/recurrent_plans/new" />;
  }

  if (redirectToEnrollmentPlan) {
    return <Redirect to="/schools/enrollment_plans/new" />;
  }

  if (redirectToNewOffer) {
    return <Redirect to="/schools/marketplaces/offers/new" />;
  }

  return (
    <PageContainer
      title="Cobranças"
      header={listHeader()}
      wallet={renderWallet()}
      className="PageCharge"
    >
      {currentTab !== null && (
        <InternalErrorBoundary>
          <Tabs
            onSelect={(index) =>
              dispatch(toggleCurrentTab({ currentTab: index.selectedTab }))
            }
            defaultIndex={currentTab}
          >
            <Tab title="Únicas">
              <SchoolProductsListContainer />
            </Tab>
            <Tab title="Recorrentes">
              <RecurrentPlansListContainer />
            </Tab>
            {policies && policies.can_use_enrollment_plan && (
              <Tab title="Matrícula">
                <EnrollmentPlansListContainer />
              </Tab>
            )}
            {policies?.can_use_shop && (
              <Tab title="Shop" flag="Novo">
                <ShopListContainer />
              </Tab>
            )}
          </Tabs>
          <Toast />
        </InternalErrorBoundary>
      )}
    </PageContainer>
  );
};

export default withAppContext(withWindowSize(PageCharges));
