import React from 'react';
import PropTypes from 'prop-types';

import ContentLoader from 'react-content-loader';

import './style.scss';

const svgProperties = {
  desktop: {
    student: {
      avatar: {
        r: '8%',
        cx: '7%',
        cy: '50%',
      },
      name: {
        x: '16%',
        y: '28%',
      },
    },
    method: {
      x: '0',
      y: '28%',
    },
    income: {
      plus: {
        x: '0',
        y: '28%',
      },
      value: {
        x: '10%',
        y: '28%',
      },
    },
  },
  mobile: {
    student: {
      avatar: {
        r: '8%',
        cx: '7%',
        cy: '50%',
      },
      name: {
        x: '16%',
        y: '28%',
      },
    },
    method: {
      x: '3%',
      y: '23%',
    },
    income: {
      plus: {
        x: '3%',
        y: '0',
      },
      value: {
        x: '13%',
        y: '0',
      },
    },
  },
};

function Skeleton(props) {
  const { viewport } = props;

  return (
    <div className={`availability loading ${viewport}`} id="loading-skeleton">
      <div className="student-display">
        <ContentLoader
          height={44}
          preserveAspectRatio="xMinYMid meet"
        >
          <circle
            cx={`${svgProperties[viewport].student.avatar.cx}`}
            cy={`${svgProperties[viewport].student.avatar.cy}`}
            r={`${svgProperties[viewport].student.avatar.r}`}
          />
          <rect
            x={`${svgProperties[viewport].student.name.x}`}
            y={`${svgProperties[viewport].student.name.y}`}
            rx="4"
            ry="4"
            width="30%"
            height="50%"
          />
        </ContentLoader>
      </div>
      <div className="availability-method" id="availability-method">
        <span id="method-skeleton">
          <ContentLoader
            height={44}
            preserveAspectRatio="xMaxYMid meet"
          >
            <rect
              x={`${svgProperties[viewport].method.x}`}
              y={`${svgProperties[viewport].method.y}`}
              rx="4"
              ry="4"
              width="25%"
              height="50%"
            />
          </ContentLoader>
        </span>
      </div>
      <div className="availability-value">
        <span>
          <ContentLoader
            height={44}
            preserveAspectRatio="xMaxYMid meet"
          >
            <rect
              x={`${svgProperties[viewport].income.plus.x}`}
              y={`${svgProperties[viewport].income.plus.y}`}
              rx="4"
              ry="4"
              width="6%"
              height="50%"
            />
            <rect
              x={`${svgProperties[viewport].income.value.x}`}
              y={`${svgProperties[viewport].income.value.y}`}
              rx="4"
              ry="4"
              width="20%"
              height="50%"
            />
          </ContentLoader>
        </span>
      </div>
    </div>
  );
}

Skeleton.propTypes = {
  viewport: PropTypes.oneOf([
    'desktop',
    'mobile',
  ]).isRequired,
};

export default Skeleton;
