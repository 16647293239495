import React, { useCallback } from 'react';

import { InputTableFieldProps } from './types';

import * as S from './styles';

const InputTableField = ({
  value,
  onChange,
  ...props
}: InputTableFieldProps) => {
  const handleClearInput = useCallback(() => {
    onChange('');
  }, [onChange]);

  return (
    <S.Container data-testid="input-table-field">
      <S.Input
        type="text"
        data-testid="input-table"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        {...props}
      />

      <S.ButtonClear testId="button-clear" onClick={handleClearInput}>
        <S.IconClear name="close" size="small" />
      </S.ButtonClear>
    </S.Container>
  );
};

export default InputTableField;
