import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  buildToast,
  toastCss,
  ToastTypes,
} from 'store/middlewares/toast/actions';
import { fetchApi, putApi } from 'core/utils/api';

import * as TYPES from './types';

function* fetchSponteFilters(_action) {
  try {
    const location = yield select((state) => state.root.location);
    const { data } = yield call(fetchApi, `${location}.json`);

    yield put({ type: TYPES.FETCH_SUCCESS, data });
  } catch (error) {
    yield put({ type: TYPES.FETCH_ERROR, error });
  }
}

function* updateSponteFilters(action) {
  try {
    const location = yield select((state) => state.root.location);
    const { data } = yield call(putApi, `${location}.json`, action.params);

    yield put({
      type: TYPES.UPDATE_SUCCESS,
      data,
      toast: buildToast(
        'Filtros atualizados com sucesso.',
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });

    if (data.attributes.is_trial) {
      window.location = `/employees/trials/${data.attributes.school_id}/tab/integration?provider=sponte`;
    } else {
      window.location = `/employees/schools/${data.attributes.school_id}/tab/integration?provider=sponte`;
    }
  } catch (error) {
    yield put({
      type: TYPES.UPDATE_ERROR,
      toast: buildToast('Erro ao atualizar os filtros', ToastTypes.error),
      error,
    });
  }
}

function* sponteFiltersSagas() {
  yield takeLatest(TYPES.FETCH_REQUEST, fetchSponteFilters);
  yield takeLatest(TYPES.UPDATE_REQUEST, updateSponteFilters);
}

export default sponteFiltersSagas;
