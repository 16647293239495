import {
  ActionsParams,
  ActionsSaga,
  StorageDocument,
  StorageFile,
  StorageFilter,
} from './types';

const actions = {
  ERROR: 'ERROR/storage',
  SUCCESS: 'SUCCESS/storage',

  FETCH_CURRENT_STORAGE_REQUEST: 'FETCH_CURRENT_STORAGE_REQUEST/storage',
  FETCH_CURRENT_STORAGE_SUCCESS: 'FETCH_CURRENT_STORAGE_SUCCESS/storage',
  FETCH_CURRENT_STORAGE_ERROR: 'FETCH_CURRENT_STORAGE_ERROR/storage',

  FETCH_CURRENT_STORAGE_FORM_REQUEST:
    'FETCH_CURRENT_STORAGE_FORM_REQUEST/storage',
  FETCH_CURRENT_STORAGE_FORM_SUCCESS:
    'FETCH_CURRENT_STORAGE_FORM_SUCCESS/storage',
  FETCH_CURRENT_STORAGE_FORM_ERROR: 'FETCH_CURRENT_STORAGE_FORM_ERROR/storage',

  FETCH_RECIPIENTS_CLASSROOMS: 'FETCH_RECIPIENTS_CLASSROOMS/storage',
  FETCH_RECIPIENTS_CLASSROOMS_SUCCESS:
    'FETCH_RECIPIENTS_CLASSROOMS_SUCCESS/storage',

  FETCH_STORAGES_REQUEST: 'FETCH_STORAGES_REQUEST/storage',
  FETCH_STORAGES_SUCCESS: 'FETCH_STORAGES_SUCCESS/storage',
  FETCH_STORAGES_ERROR: 'FETCH_STORAGES_ERROR/storage',

  TOGGLE_SHOW_DETAILS_MODAL: 'TOGGLE_SHOW_DETAILS_MODAL/storage',
  TOGGLE_SHOW_DELETE_STORAGE_MODAL: 'TOGGLE_SHOW_DELETE_STORAGE_MODAL/storage',
  TOGGLE_SHOW_DISCARD_MODAL: 'TOGGLE_SHOW_DISCARD_MODAL/storage',
  TOGGLE_SHOW_NOTIFICATION_MODAL: 'TOGGLE_SHOW_NOTIFICATION_MODAL/storage',

  SAVE_EDIT_DOCUMENT_REQUEST: 'SAVE_EDIT_DOCUMENT_REQUEST/storage',
  SAVE_EDIT_DOCUMENT_SUCCESS: 'SAVE_EDIT_DOCUMENT_SUCCESS/storage',
  SAVE_EDIT_DOCUMENT_ERROR: 'SAVE_EDIT_DOCUMENT_ERROR/storage',

  SAVE_EDIT_FILE_REQUEST: 'SAVE_EDIT_FILE_REQUEST/storage',
  SAVE_EDIT_FILE_SUCCESS: 'SAVE_EDIT_FILE_SUCCESS/storage',
  SAVE_EDIT_FILE_ERROR: 'SAVE_EDIT_FILE_ERROR/storage',

  SAVE_EDIT_FOLDER_REQUEST: 'SAVE_EDIT_FOLDER_REQUEST/storage',
  SAVE_EDIT_FOLDER_SUCCESS: 'SAVE_EDIT_FOLDER_SUCCESS/storage',
  SAVE_EDIT_FOLDER_ERROR: 'SAVE_EDIT_FOLDER_ERROR/storage',

  SAVE_DOCUMENT_REQUEST: 'SAVE_DOCUMENT_REQUEST/storage',
  SAVE_DOCUMENT_SUCCESS: 'SAVE_DOCUMENT_SUCCESS/storage',
  SAVE_DOCUMENT_ERROR: 'SAVE_DOCUMENT_ERROR/storage',

  SAVE_FILE_REQUEST: 'SAVE_FILE_REQUEST/storage',
  SAVE_FILE_SUCCESS: 'SAVE_FILE_SUCCESS/storage',
  SAVE_FILE_ERROR: 'SAVE_FILE_ERROR/storage',

  SAVE_FOLDER_REQUEST: 'SAVE_FOLDER_REQUEST/storage',
  SAVE_FOLDER_SUCCESS: 'SAVE_FOLDER_SUCCESS/storage',
  SAVE_FOLDER_ERROR: 'SAVE_FOLDER_ERROR/storage',

  SET_CHANGE_FILTERS: 'SET_CHANGE_FILTERS/storage',

  SET_CLEAN_CURRENT_STORAGE_FORM: 'SET_CLEAN_CURRENT_STORAGE_FORM/storage',

  SET_SELECTED_STORAGE: 'SET_SELECTED_STORAGE/storage',

  SET_DELETE_STORAGE_REQUEST: 'SET_DELETE_STORAGE_REQUEST/storage',
  SET_DELETE_STORAGE_SUCCESS: 'SET_DELETE_STORAGE_SUCCESS/storage',
  SET_DELETE_STORAGE_ERROR: 'SET_DELETE_STORAGE_ERROR/storage',

  fetchCurrentStorageRequest: (
    storageId: string
  ): ActionsParams['fetchCurrentStorageRequest'] => ({
    type: actions.FETCH_CURRENT_STORAGE_REQUEST,
    storageId,
  }),
  fetchCurrentStorageFormRequest: (
    storageId: string,
    kind: 'new' | 'edit'
  ): ActionsParams['fetchCurrentStorageFormRequest'] => ({
    type: actions.FETCH_CURRENT_STORAGE_FORM_REQUEST,
    storageId,
    kind,
  }),
  fetchRecipientsClassrooms: (folderAscendingId?: string) => ({
    type: actions.FETCH_RECIPIENTS_CLASSROOMS,
    folderAscendingId,
  }),
  fetchStoragesRequest: (
    page: number,
    folderAscendingId?: string
  ): ActionsParams['fetchStoragesRequest'] => ({
    type: actions.FETCH_STORAGES_REQUEST,
    page,
    folderAscendingId,
  }),
  toggleShowDetaisModal: () => ({
    type: actions.TOGGLE_SHOW_DETAILS_MODAL,
  }),
  toggleShowDeleteStorageModal: () => ({
    type: actions.TOGGLE_SHOW_DELETE_STORAGE_MODAL,
  }),
  toggleShowDiscardModal: () => ({
    type: actions.TOGGLE_SHOW_DISCARD_MODAL,
  }),
  toggleShowNotificationModal: (
    form?: StorageDocument['form'] | StorageFile['form']
  ) => ({
    type: actions.TOGGLE_SHOW_NOTIFICATION_MODAL,
    form,
  }),
  saveDocumentRequest: ({
    folderAscendingId,
    title,
    description,
    attachments,
    personaType,
    sentKind,
    classroomIds,
    classroomWithStudentProfileIds,
    notify,
  }: ActionsParams['saveDocumentRequest']): ActionsSaga['saveDocumentRequestSaga'] => {
    const availableTo: Record<
      ActionsParams['saveDocumentRequest']['personaType'],
      ActionsSaga['saveDocumentRequestSaga']['params']['availableTo']
    > = {
      both: 'family',
      students: 'student',
      responsibles: 'responsible',
    };

    const receiverType: Record<
      ActionsParams['saveDocumentRequest']['sentKind'],
      ActionsSaga['saveDocumentRequestSaga']['params']['receiverType']
    > = {
      students: 'student',
      classrooms: 'classroom',
    };

    return {
      type: actions.SAVE_DOCUMENT_REQUEST,
      params: {
        folderAscendingId,
        attachments,
        title,
        description,
        availableTo: availableTo[personaType],
        receiverType: receiverType[sentKind],
        classroomIds,
        classroomWithStudentProfileIds,
        notify,
      },
    };
  },
  saveFileRequest: ({
    attachments,
    personaType,
    sentKind,
    classroomIds,
    classroomWithStudentProfileIds,
    folderAscendingId,
    notify,
  }: ActionsParams['saveFileRequest']): ActionsSaga['saveFileRequestSaga'] => {
    const availableTo: Record<
      ActionsParams['saveFileRequest']['personaType'],
      ActionsSaga['saveFileRequestSaga']['params']['availableTo']
    > = {
      both: 'family',
      students: 'student',
      responsibles: 'responsible',
    };

    const receiverType: Record<
      ActionsParams['saveFileRequest']['sentKind'],
      ActionsSaga['saveFileRequestSaga']['params']['receiverType']
    > = {
      students: 'student',
      classrooms: 'classroom',
    };

    return {
      type: actions.SAVE_FILE_REQUEST,
      params: {
        attachments,
        availableTo: availableTo[personaType],
        receiverType: receiverType[sentKind],
        classroomIds,
        classroomWithStudentProfileIds,
        folderAscendingId,
        notify,
      },
    };
  },
  saveFolderRequest: ({
    title,
    description,
    personaType,
    folderAscendingId,
    sentKind,
    classroomIds,
    classroomWithStudentProfileIds,
  }: ActionsParams['saveFolderRequest']): ActionsSaga['saveFolderRequestSaga'] => {
    const availableTo: Record<
      ActionsParams['saveFolderRequest']['personaType'],
      ActionsSaga['saveFolderRequestSaga']['params']['availableTo']
    > = {
      both: 'family',
      students: 'student',
      responsibles: 'responsible',
    };

    const receiverType: Record<
      ActionsParams['saveFolderRequest']['sentKind'],
      ActionsSaga['saveFolderRequestSaga']['params']['receiverType']
    > = {
      students: 'student',
      classrooms: 'classroom',
    };

    return {
      type: actions.SAVE_FOLDER_REQUEST,
      params: {
        kind: 'folder',
        title,
        description,
        folderAscendingId,
        availableTo: availableTo[personaType],
        receiverType: receiverType[sentKind],
        classroomIds,
        classroomWithStudentProfileIds,
      },
    };
  },
  saveEditDocumentRequest: ({
    currentStorageId,
    title,
    description,
    attachments,
    personaType,
    sentKind,
    classroomIds,
    classroomWithStudentProfileIds,
    notify,
  }: ActionsParams['saveEditDocumentRequest']): ActionsSaga['saveEditDocumentRequestSaga'] => {
    const availableTo: Record<
      ActionsParams['saveEditDocumentRequest']['personaType'],
      ActionsSaga['saveEditDocumentRequestSaga']['params']['availableTo']
    > = {
      both: 'family',
      students: 'student',
      responsibles: 'responsible',
    };

    const receiverType: Record<
      ActionsParams['saveEditDocumentRequest']['sentKind'],
      ActionsSaga['saveEditDocumentRequestSaga']['params']['receiverType']
    > = {
      students: 'student',
      classrooms: 'classroom',
    };

    return {
      type: actions.SAVE_EDIT_DOCUMENT_REQUEST,
      params: {
        currentStorageId,
        attachments,
        title,
        description,
        availableTo: availableTo[personaType],
        receiverType: receiverType[sentKind],
        classroomIds,
        classroomWithStudentProfileIds,
        notify,
      },
    };
  },
  saveEditFileRequest: ({
    attachments,
    personaType,
    sentKind,
    title,
    classroomIds,
    classroomWithStudentProfileIds,
    currentStorageId,
    notify,
  }: ActionsParams['saveEditFileRequest']): ActionsSaga['saveEditFileRequestSaga'] => {
    const availableTo: Record<
      ActionsParams['saveEditFileRequest']['personaType'],
      ActionsSaga['saveEditFileRequestSaga']['params']['availableTo']
    > = {
      both: 'family',
      students: 'student',
      responsibles: 'responsible',
    };

    const receiverType: Record<
      ActionsParams['saveEditFileRequest']['sentKind'],
      ActionsSaga['saveEditFileRequestSaga']['params']['receiverType']
    > = {
      students: 'student',
      classrooms: 'classroom',
    };

    return {
      type: actions.SAVE_EDIT_FILE_REQUEST,
      params: {
        attachments,
        availableTo: availableTo[personaType],
        receiverType: receiverType[sentKind],
        title,
        classroomIds,
        classroomWithStudentProfileIds,
        currentStorageId,
        notify,
      },
    };
  },
  saveEditFolderRequest: ({
    title,
    description,
    personaType,
    currentStorageId,
    sentKind,
    classroomIds,
    classroomWithStudentProfileIds,
  }: ActionsParams['saveEditFolderRequest']): ActionsSaga['saveEditFolderRequestSaga'] => {
    const availableTo: Record<
      ActionsParams['saveEditFolderRequest']['personaType'],
      ActionsSaga['saveEditFolderRequestSaga']['params']['availableTo']
    > = {
      both: 'family',
      students: 'student',
      responsibles: 'responsible',
    };

    const receiverType: Record<
      ActionsParams['saveEditFolderRequest']['sentKind'],
      ActionsSaga['saveEditFolderRequestSaga']['params']['receiverType']
    > = {
      students: 'student',
      classrooms: 'classroom',
    };

    return {
      type: actions.SAVE_EDIT_FOLDER_REQUEST,
      params: {
        kind: 'folder',
        title,
        description,
        currentStorageId,
        availableTo: availableTo[personaType],
        receiverType: receiverType[sentKind],
        classroomIds,
        classroomWithStudentProfileIds,
      },
    };
  },
  setChangeFilters: (
    filters: StorageFilter
  ): ActionsParams['setChangeFilters'] => ({
    type: actions.SET_CHANGE_FILTERS,
    filters,
  }),
  setCleanCurrentStorageForm: () => ({
    type: actions.SET_CLEAN_CURRENT_STORAGE_FORM,
  }),
  setSelectedStorage: (storage): ActionsParams['setSelectedStorage'] => ({
    type: actions.SET_SELECTED_STORAGE,
    storage,
  }),
  setDeleteStorageRequest: (
    storage,
    redirectRootPage?: boolean
  ): ActionsParams['setDeleteStorageRequest'] => ({
    type: actions.SET_DELETE_STORAGE_REQUEST,
    storage,
    redirectRootPage,
  }),
};

export default actions;
