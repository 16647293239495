import styled, { css } from 'styled-components';
import Tooltip from 'components/Tooltip';

export const BillConfigurationWrapper = styled.div`
  display: flex;
  flex-direction: column;

  .Alert {
    height: auto;
  }
`;

export const CheckPaymentWrapper = styled.div`
  .checkboxes-wrapper {
    margin: 0;
    margin-bottom: 12px;
  }
`;

export const InputsConfigurationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;

  .Field {
    .SingleDatePicker,
    .SingleDatePickerInput,
    .DateInput {
      width: 100%;
    }

    width: 100%;
    height: 40px;
    margin: 0;
    padding: 0;
  }
`;

export const DoubleInpurtWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    gap: 30px;

    > .Field {
      margin-bottom: 24px;
    }

    .CurrencyField .input-group {
      height: 40px;

      .input-group-addon {
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: ${theme.gray2};
      }

      input {
        height: 40px;
        border-radius: 0 6px 6px 0;
      }
    }
  `}
`;

export const AbsorbOptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
`;

export const AbsorbOptionsInformation = styled.div`
  display: flex;
  flex-direction: row;
`;

export const CustomTooltip = styled(Tooltip)`
  .tooltip-div {
    position: relative;
    width: 16px;
    height: 16px;
    margin-left: 8px;
    margin-top: 2px;
  }
`;

export const ExpiresAtField = styled.div`
  display: flex;
  flex-direction: column;

  .labelRow {
    display: flex;
    flex-direction: row;
    margin-bottom: 12px;
  }
`;

export const AbsorbOptionsCheckbox = styled.div`
  display: flex;
  flex-direction: column;

  .checkbox-option {
    display: flex;
    margin-top: 16px;
    flex-direction: row;

    > span {
      margin-left: 16px;
      cursor: pointer;
    }
  }
`;

export const AlertPixWrapper = styled.div`
  margin-top: 32px;
`;
