/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/no-unresolved */
import React from 'react';

import OutlineBox from 'components/OutlineBox';
import AgendaIcon from 'components/AgendaIcon';

const Product = ({ attributes }) => attributes.name;

const Actions = ({ attributes, actions }) => (
  <div className="product-actions">
    <OutlineBox onClick={actions.openEditModal}>
      <AgendaIcon name="edit" />
    </OutlineBox>
    <OutlineBox
      variation="danger"
      onClick={actions.openDeleteModal}
    >
      <AgendaIcon name="trash" />
    </OutlineBox>
  </div>
);


export default [
  {
    Header: 'Produto',
    accessor: Product,
  },
  {
    Header: 'Ações',
    accessor: Actions,
  },
];
