import styled, { css } from 'styled-components';

import { ThemeProps } from 'config/themes/types';
import { StylesProps } from './types';

export const Wrapper = styled.div`
  ${({ theme: { space, colors, breakpoints } }: ThemeProps) => css`
    display: flex;
    flex-direction: column;
    padding: ${space.xl} ${space.xl5};
    gap: ${space.xl};

    i {
      color: ${colors.brand.primary.default};
    }

    @media only screen and (max-width: ${breakpoints.desktopLG}) {
      padding: ${space.xl} ${space.lg};
    }
  `}
`;

export const HeaderWrapper = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    flex-direction: row;
    gap: ${space.sm};
    justify-content: space-between;
    align-items: center;
  `}
`;

export const Title = styled.h1`
  ${({
    theme: {
      colors,
      font: { family, size },
    },
  }: ThemeProps) => css`
    font-weight: 700;
    color: ${colors.neutral.black};
    margin: 0px;
    margin-top: 6px;
    font-family: ${family.brand}, sans-serif;
    font-size: ${size.xl};
  `}
`;

export const TemplatesWrapper = styled.div`
  ${({ theme: { border } }: ThemeProps) => css`
    display: flex;
    flex-direction: column;
    border-radius: ${border.radius.md};
    box-shadow: 0 0 10px 0 rgba(51, 51, 51, 0.15);
    padding: 16px 0;
  `}
`;

export const ListWrapper = styled.div`
  display: flex;
  padding: 40px;
  box-shadow: none;
  flex-direction: row;
`;

export const TabsWrapper = styled.div`
  ${({ theme: { border, colors } }: ThemeProps) => css`
    display: flex;
    border-bottom: ${border.width.sm} ${border.style.solid}
      ${colors.neutral.gray4};
  `}
`;

export const TemplateCardListWrapper = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    flex-direction: 'row';
    gap: ${space.lg};
    flex-wrap: wrap;
  `}
`;

export const TabsSkeleton = styled.div`
  ${({ theme: { border, space } }: ThemeProps) => css`
    padding: ${space.xs4};
    border-radius: ${border.radius.md};
    box-shadow: 0 0 10px 0 rgba(51, 51, 51, 0.15);
  `}
`;

export const TabsHeaderSkeleton = styled.div`
  ${({ theme: { border, colors } }: ThemeProps) => css`
    height: 49px;
    border-bottom: ${border.width.sm} ${border.style.solid}
      ${colors.neutral.gray4};

    display: flex;
    align-items: center;
  `}
`;

export const TabSkeleton = styled.div<StylesProps>`
  ${({ theme: { colors, space }, isSelected }) => css`
    height: 52px;
    padding: ${space.sm} ${space.lg} ${space.xs4} ${space.lg};

    position: relative;

    ${isSelected &&
    css`
      &::after {
        content: '';
        position: absolute;
        bottom: 1px;
        right: ${space.xs4};
        left: ${space.xs4};
        width: 100%;
        height: ${space.xs3};
        border-radius: ${space.xs3} ${space.xs3} ${space.xs4} ${space.xs4};
        background-color: ${colors.neutral.gray3};
      }
    `}
  `}
`;

export const HandoutTemplateSkeleton = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    min-height: 100vh;
    margin: ${space.xs4};
    padding: ${space.xl} ${space.lg};

    @media only screen and (min-width: 820px) {
      padding: ${space.xl2} ${space.xl};
    }

    @media only screen and (min-width: 1024px) {
      padding: ${space.xl} ${space.xl5};
    }
  `}
`;

export const EmptyStateImage = styled.img`
  ${({ theme: { breakpoints } }: ThemeProps) => css`
    height: 260px;

    @media only screen and (max-width: ${breakpoints.desktopLG}) {
      height: 173px;
    }

    @media only screen and (max-width: ${breakpoints.mobileXS}) {
      height: 130px;
    }
  `}
`;
