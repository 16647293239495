import React, { Component, useEffect } from 'react';
import ModalContainer from 'components/Modal/ModalContainer';
import ModalTitle from 'components/Modal/ModalTitle';
import Button from 'components/Button';

import withAppContext from 'core/hoc/withAppContext';

import './style.scss';

function MessagesUploadModalInvalidAttachment({
  errorType,
  isOpen,
  toggleModal,
  appContext,
}) {
  const typeMapping = {
    type: {
      title: 'Tipo de arquivo inválido.',
      message: {
        User: 'As extensões de arquivos suportadas são: .PDF, .PNG, .JPG, .MP3, .MP4',
        SchoolUser:
          'As extensões de arquivos suportadas são: .PDF, .PNG, .JPG, .MP3, .MP4, .DOC, .DOCX, .XLS, .XLSX, .PPT, .PPTX',
      },
    },
    size: {
      title: 'O arquivo é muito grande',
      message: {
        User: 'O tamanho máximo do arquivo deve ser 20mb.',
        SchoolUser: 'O tamanho máximo do arquivo deve ser 20mb.',
      },
    },
  };

  return (
    <div className="MessagesUploadModalInvalidAttachment">
      <ModalContainer
        isOpen={isOpen}
        toggleModal={toggleModal}
        className="MessagesUploadModalInvalidAttachment"
      >
        {errorType.length > 1 ? (
          <ModalTitle>Arquivo inválido</ModalTitle>
        ) : (
          <ModalTitle>{typeMapping[errorType[0]].title}</ModalTitle>
        )}
        <div className="errorMessage">
          {errorType.map((error) => (
            <p key={error}>
              {typeMapping[error].message[appContext.currentUserType]}
            </p>
          ))}
        </div>
        <div className="actions">
          <Button onClick={toggleModal}>Entendi</Button>
        </div>
      </ModalContainer>
    </div>
  );
}

export default withAppContext(MessagesUploadModalInvalidAttachment);
