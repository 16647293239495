import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import reduxProvider from 'core/hoc/reduxProvider';
import withAppContext, { appPropTypes } from 'core/hoc/withAppContext';

import DynamicView from 'pages/home/DynamicApps';
import Banners from 'pages/home/Banners';
import Trainings from 'pages/home/Trainings';
import Toast from 'components/Toast';

import HomeActions from 'store/home/actions';
import HomeOnboardingPanel from 'pages/home/OnboardingPanel';
import HomeSchoolsSelect, { HomeSchoolsSelectSkeleton } from './SchoolsSelect';

import * as S from './styles';

const Home = ({ appContext: { policies } }) => {
  const dispatch = useDispatch();

  const {
    fetchSchoolUserSchools,
    fetchTrainings,
    fetchBanners,
    fetchDynamicModules,
  } = HomeActions;

  const {
    can_show_empty_state_dashboard: canShowEmptyStateDashboard,
    can_show_select_school: canShowSelectSchool,
    can_show_my_apps: canShowMyApps,
  } = policies;

  const {
    isLoadingSchools,
    isChangingSchool,
    formMeta: { schoolUserSchools },
    selectedSchool,
  } = useSelector((state) => state.home);

  const renderSchoolsSelect = isLoadingSchools ? (
    <HomeSchoolsSelectSkeleton />
  ) : (
    <HomeSchoolsSelect
      isSubmitting={isChangingSchool}
      selectedSchool={selectedSchool}
      schools={schoolUserSchools}
    />
  );

  useEffect(() => {
    if (canShowSelectSchool) {
      dispatch(fetchSchoolUserSchools());
    }
  }, [canShowSelectSchool]);

  useEffect(() => {
    dispatch(fetchTrainings());
    dispatch(fetchBanners());
  }, []);

  return (
    <div id="HomeMainContainer">
      <S.MainContainer>
        {canShowSelectSchool && renderSchoolsSelect}
        {canShowEmptyStateDashboard && <HomeOnboardingPanel />}

        {canShowMyApps && <DynamicView />}

        <Toast />

        <Banners />
        <Trainings />
      </S.MainContainer>
    </div>
  );
};

Home.propTypes = {
  ...appPropTypes,
};

export default reduxProvider(withAppContext(Home));
