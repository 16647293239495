import React, { useState } from 'react';
import PropTypes from 'prop-types';

import PageTitle from 'components/PageTitle';
import OutlineBox from 'components/OutlineBox';
import RecipientsList from 'components/RecipientsList';
import DropdownButton from 'components/DropdownButton';
import PrintElement from 'components/PrintElement';

import SingleSheetTemplate from 'components/PrintTemplates/SingleSheetTemplate';
import TicketTemplate from 'components/PrintTemplates/TicketTemplate';

import './style.scss';

const TabHeader = ({ data }) => {
  const [isShowPrint, handlePrint] = useState(false);
  const [element, setElement] = useState(null);

  const renderStatus = () => {
    const status = {
      pending: 'Pendente',
      opened: 'Em aberto',
      closed: 'Encerrada',
      rejected: 'Reprovada',
      only_deleted: 'Excluída',
      processing: 'Processando',
      approved: 'Aprovado',
      sent: 'Enviado',
      scheduled: 'Agendada',
      pending_schedule: 'Agendamento pendente',
      updated: 'Atualizado',
      deleted: 'Excluída',
    };

    return (
      <OutlineBox className={`${data.status}`}>
        <span>{status[data.status]}</span>
      </OutlineBox>
    );
  };

  const actionItemSelected = (value) => {
    const switchStructType = {
      1: () => {
        return setElement(<TicketTemplate contentPrint={data} />);
      },
      2: () => {
        return setElement(<SingleSheetTemplate contentPrint={data} />);
      },
      default: () => {
        return null;
      },
    };

    switchStructType[value]();

    handlePrint(true);
  };

  const actionItems = () => {
    const actions = [
      {
        text: 'Bilhetes',
        onClick: () => actionItemSelected('1'),
      },
      {
        text: 'Folha única',
        onClick: () => actionItemSelected('2'),
      },
    ];

    return actions;
  };

  return (
    <>
      <section className="tab-header-area">
        <div className="column-left">
          <div className="status">{renderStatus()}</div>
          <RecipientsList attributes={data.attributes} />
        </div>
        <div className="column-right">
          <PrintElement
            elementToPrint={element}
            isShowPrint={isShowPrint}
            onDismiss={() => handlePrint(false)}
          />

          <DropdownButton
            dropdownItems={actionItems()}
            icon="print"
            variation="white"
            align="right"
          />
        </div>
      </section>

      <PageTitle>{data.title}</PageTitle>
    </>
  );
};

TabHeader.propTypes = {
  data: PropTypes.object.isRequired,
};

export default TabHeader;
