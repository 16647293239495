import React, { useEffect, useState } from 'react';
import moment from 'moment';
import ContentLoader from 'react-content-loader';

import { useDispatch, useSelector } from 'react-redux';
import BillingPanelActions from 'store/billingPanel/actions';

import CardBilling from '../CardBilling';
import { CardList } from '@agendaedu/ae-web-components';
import EmptyState from 'components/EmptyState';
import Loader from 'components/Loader';
import { useTranslation } from 'react-i18next';
import Toast from 'components/Toast';
import { constants } from '../../constants';
import * as S from './styles';

const ListBilling = () => {
  const { t } = useTranslation(['billing_panel']);

  const dispatch = useDispatch();

  const { fetchBillingList, requestBilletPayment } = BillingPanelActions;

  useEffect(() => {
    dispatch(fetchBillingList());
  }, [dispatch, fetchBillingList]);

  const { isLoading, signatureList } = useSelector(
    (state) => state.billingPanel
  );

  const handleBilletPayment = (signatureId: string) => {
    dispatch(requestBilletPayment(signatureId));
  };

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const fetchMoreItems = () => {};

  const renderCard = ({ key, item }) => {
    return (
      <CardBilling
        id={item.id}
        key={key}
        content={item}
        onClickBillet={handleBilletPayment}
      />
    );
  };

  if (!isLoading && signatureList.length <= 0) {
    return (
      <EmptyState
        imgUrl={constants.IMAGE_EMPTY}
        message={
          <p>
            {t('list_billing.empty_state.message', {
              date: moment().add(1, 'M').startOf('month').format('DD/MM/YYYY'),
            })
              .split('\n')
              .map((line, key) => (
                <p key={key}>{line}</p>
              ))}
          </p>
        }
      />
    );
  }

  return (
    <>
      {isLoading && !signatureList.length ? (
        <ContentLoader
          width="100%"
          height="100%"
          speed={2}
          viewBox="0 0 600 300"
          ariaLabel="Carregando..."
        >
          <rect x="118" y="5" rx="2" ry="2" width="364" height="143" />
          <rect x="118" y="166" rx="2" ry="2" width="364" height="143" />
          <rect x="118" y="328" rx="2" ry="2" width="364" height="143" />
        </ContentLoader>
      ) : (
        <S.Wrapper>
          <CardList
            hasMore={false}
            loadMore={fetchMoreItems}
            loader={
              <Loader key="classrooms-list-loader" aria-label="Carregando..." />
            }
            initialLoad={true}
            threshold={400}
            pageStart={1}
            mb={26}
          >
            {signatureList.map((item, index) =>
              renderCard({ key: index, item: item })
            )}
          </CardList>
        </S.Wrapper>
      )}

      <Toast />
    </>
  );
};

export default ListBilling;
