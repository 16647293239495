import _ from 'lodash';

import { normalizeDiaryToSave } from 'core/helper/diary';
import {
  ChangeVisibilitySagaProps,
  DeleteDiarySectionProps,
  DiaryReportProps,
  DuplicateDiarySectionProps,
  FetchActivesDiarySectionsProps,
  FetchDiaryReportStudentsProps,
  FetchDiarySectionsFormProps,
  FetchStudentDiaryFormProps,
  FetchStudentsDiariesFormProps,
  SaveDiariesPerSectionsProps,
  SaveDiariesPerSectionsSagaParams,
  SaveDiaryProps,
  SaveDiarySagaParams,
  SaveSectionSagaProps,
} from './types';

const actions = {
  ERROR: 'ERROR/daily_summaries',
  FETCH_CLASSROOMS_LIST: 'FETCH_CLASSROOMS_LIST/daily_summaries',
  FETCH_CLASSROOMS_LIST_SUCCESS:
    'FETCH_CLASSROOMS_LIST_SUCCESS/daily_summaries',
  HANDLE_DRAFT_DAILY_SUMMARY: 'HANDLE_DRAFT_DAILY_SUMMARY/daily_summaries',
  ADD_DAILY_SUMMARY_REQUEST: 'ADD_DAILY_SUMMARY_REQUEST/daily_summaries', //old
  ADD_DAILY_SUMMARY_SUCCESS: 'ADD_DAILY_SUMMARY_SUCCESS/daily_summaries', //old
  FETCH_STUDENT_SUMMARY_REQUEST:
    'FETCH_STUDENT_SUMMARY_REQUEST/daily_summaries',
  FETCH_STUDENT_SUMMARY_SUCCESS:
    'FETCH_STUDENT_SUMMARY_SUCCESS/daily_summaries',
  FETCH_CLASSROOM_DETAILS: 'FETCH_CLASSROOM_DETAILS/daily_summaries',
  FETCH_CLASSROOM_DETAILS_SUCCESS:
    'FETCH_CLASSROOM_DETAILS_SUCCESS/daily_summaries',
  SET_DATE: 'SET_DATE/daily_summaries',
  SET_HEADQUARTER: 'SET_HEADQUARTER/daily_summaries',
  SET_EDUCATIONAL_STAGE: 'SET_EDUCATIONAL_STAGE/daily_summaries',
  FETCH_DAILY_SUMMARY_DETAILS_REQUEST:
    'FETCH_DAILY_SUMMARY_DETAILS_REQUEST/daily_summaries',
  FETCH_DAILY_SUMMARY_DETAILS_SUCCESS:
    'FETCH_DAILY_SUMMARY_DETAILS_SUCCESS/daily_summaries',
  SET_SELECTEDS_IDS: 'SET_SELECTEDS_IDS/daily_summaries',
  FETCH_HEADQUARTERS: 'FETCH_HEADQUARTERS/daily_summaries',
  FETCH_HEADQUARTERS_SUCCESS: 'FETCH_HEADQUARTERS_SUCCESS/daily_summaries',
  FETCH_EDUCATIONAL_STAGES: 'FETCH_EDUCATIONAL_STAGES/daily_summaries',
  FETCH_EDUCATIONAL_STAGES_SUCCESS:
    'FETCH_EDUCATIONAL_STAGES_SUCCESS/daily_summaries',
  FETCH_SETTINGS_REQUEST: 'FETCH_SETTINGS_REQUEST/daily_summaries',
  FETCH_SETTINGS_SUCCESS: 'FETCH_SETTINGS_SUCCESS/daily_summaries',
  SAVE_SETTINGS_REQUEST: 'SAVE_SETTINGS_REQUEST/daily_summaries',
  SAVE_SETTINGS_SUCCESS: 'SAVE_SETTINGS_SUCCESS/daily_summaries',
  FETCH_DIARY_SECTIONS: 'FETCH_DIARY_SECTIONS/daily_summaries',
  FETCH_DIARY_SECTIONS_SUCCESS: 'FETCH_DIARY_SECTIONS_SUCCESS/daily_summaries',
  FETCH_DIARY_SECTIONS_ERROR: 'FETCH_DIARY_SECTIONS_ERROR/daily_summaries',
  CHANGE_SECTION_VISIBILITY: 'CHANGE_SECTION_VISIBILITY/daily_summaries',
  FETCH_RECIPIENTS_CLASSROOMS: 'FETCH_RECIPIENTS_CLASSROOMS/daily_summaries',
  FETCH_RECIPIENTS_CLASSROOMS_SUCCESS:
    'FETCH_RECIPIENTS_CLASSROOMS_SUCCESS/daily_summaries',
  SAVE_SECTION_REQUEST: 'SAVE_SECTION_REQUEST/daily_summaries',
  SAVE_SECTION_REQUEST_SUCCESS: 'SAVE_SECTION_REQUEST_SUCCESS/daily_summaries',
  SAVE_SECTION_REQUEST_ERROR: 'SAVE_SECTION_REQUEST_ERROR/daily_summaries',
  FETCH_DIARY_SECTION_FORM_REQUEST:
    'FETCH_DIARY_SECTION_FORM_REQUEST/daily_summaries',
  FETCH_DIARY_SECTION_FORM_REQUEST_SUCCESS:
    'FETCH_DIARY_SECTION_FORM_REQUEST_SUCCESS/daily_summaries',
  FETCH_DIARY_SECTION_FORM_REQUEST_ERROR:
    'SAVE_SECTION_REQUEST_ERROR/daily_summaries',
  DELETE_DIARY_SECTION_REQUEST: 'DELETE_DIARY_SECTION_REQUEST/daily_summaries',
  DELETE_DIARY_SECTION_SUCCESS: 'DELETE_DIARY_SECTION_SUCCESS/daily_summaries',
  DELETE_DIARY_SECTION_ERROR: 'DELETE_DIARY_SECTION_ERROR/daily_summaries',
  DUPLICATE_DIARY_SECTION_REQUEST:
    'DUPLICATE_DIARY_SECTION_REQUEST/daily_summaries',
  DUPLICATE_DIARY_SECTION_SUCCESS:
    'DUPLICATE_DIARY_SECTION_SUCCESS/daily_summaries',
  DUPLICATE_DIARY_SECTION_ERROR:
    'DUPLICATE_DIARY_SECTION_ERROR/daily_summaries',
  FETCH_ACTIVES_DIARY_SECTIONS_REQUEST:
    'FETCH_ACTIVES_DIARY_SECTIONS_REQUEST/daily_summaries',
  FETCH_ACTIVES_DIARY_SECTIONS_SUCCESS:
    'FETCH_ACTIVES_DIARY_SECTIONS_SUCCESS/daily_summaries',
  FETCH_ACTIVES_DIARY_SECTIONS_ERROR:
    'FETCH_ACTIVES_DIARY_SECTIONS_ERROR/daily_summaries',
  SAVE_DIARY_REQUEST: 'SAVE_DIARY_REQUEST/daily_summaries',
  SAVE_DIARY_SUCCESS: 'SAVE_DIARY_SUCCESS/daily_summaries',
  FETCH_STUDENT_DIARY_FORM: 'FETCH_STUDENT_DIARY_FORM/daily_summaries',
  FETCH_STUDENT_DIARY_FORM_SUCCESS:
    'FETCH_STUDENT_DIARY_FORM_SUCCESS/daily_summaries',
  FETCH_STUDENT_DIARY_FORM_ERROR:
    'FETCH_STUDENT_DIARY_FORM_ERROR/daily_summaries',
  FETCH_DIARY_REPORT_INITIAL_RECIPIENTS:
    'FETCH_DIARY_REPORT_INITIAL_RECIPIENTS/daily_summaries',
  FETCH_DIARY_REPORT_INITIAL_RECIPIENTS_SUCCESS:
    'FETCH_DIARY_REPORT_INITIAL_RECIPIENTS_SUCCESS/daily_summaries',
  FETCH_DIARY_REPORT_STUDENTS: 'FETCH_DIARY_REPORT_STUDENTS/daily_summaries',
  FETCH_DIARY_REPORT_STUDENTS_SUCCESS:
    'FETCH_DIARY_REPORT_STUDENTS_SUCCESS/daily_summaries',
  FETCH_DIARY_REPORT_SECTIONS: 'FETCH_DIARY_REPORT_SECTIONS/daily_summaries',
  FETCH_DIARY_REPORT_SECTIONS_SUCCESS:
    'FETCH_DIARY_REPORT_SECTIONS_SUCCESS/daily_summaries',
  EXPORT_DIARY_REPORT_REQUEST: 'EXPORT_DIARY_REPORT_REQUEST/daily_summaries',
  EXPORT_DIARY_REPORT_SUCCESS: 'EXPORT_DIARY_REPORT_SUCCESS/daily_summaries',
  HIDE_DIARY_INFO_MODAL_REQUEST:
    'HIDE_DIARY_INFO_MODAL_REQUEST/daily_summaries',
  FETCH_STUDENTS_DIARIES_FORM: 'FETCH_STUDENTS_DIARIES_FORM/daily_summaries',
  FETCH_STUDENTS_DIARIES_FORM_SUCCESS:
    'FETCH_STUDENTS_DIARIES_FORM_SUCCESS/daily_summaries',
  FETCH_STUDENTS_DIARIES_FORM_ERROR:
    'FETCH_STUDENTS_DIARIES_FORM_ERROR/daily_summaries',
  SAVE_DIARIES_PER_SECTION_REQUEST:
    'SAVE_DIARIES_PER_SECTION_REQUEST/daily_summaries',
  SAVE_DIARIES_PER_SECTION_SUCCESS:
    'SAVE_DIARIES_PER_SECTION_SUCCESS/daily_summaries',

  fetchClassroomsList: (filters) => ({
    type: actions.FETCH_CLASSROOMS_LIST,
    filters,
  }),
  handleDraftDailySummary: (key, value) => ({
    type: actions.HANDLE_DRAFT_DAILY_SUMMARY,
    key,
    value,
  }),
  addDailySummary: (params) => {
    const {
      date,
      notify,
      classroomId,
      studentsIdsSelecteds,
      draftDailySummary: {
        meals,
        diaper,
        bath,
        observation,
        babyBottles: { notDrink, quantities },
        sleeps: { notSlept, periods },
        evacuations: { notEvacuated, appearances },
        occurrences,
      },
      current,
    } = params;

    const formatedParams = {
      date,
      classroom_id: classroomId,
      student_profile_ids: studentsIdsSelecteds,
      daily_summary: {
        meals: meals
          .map(({ category, description, notEat }) => ({
            category,
            description,
            not_ate: notEat,
          }))
          .filter((meal) => Object.values(meal).some(Boolean)),
        diaper,
        shower: bath,
        observation,
        notify,
        occurrences: occurrences
          .filter(({ category }) => {
            return category.length > 0;
          })
          .map(({ id, ...occurrence }) => {
            return !isNaN(id) ? { id, ...occurrence } : occurrence;
          }),
        occurrences_to_destroy: current.occurrences
          .filter(
            (occurrence) =>
              !occurrences.some(
                (draftOccurrence) => draftOccurrence.id === occurrence.id
              ) && !isNaN(occurrence.id)
          )
          .map((occurrence) => occurrence.id),
        baby_bottles: {
          not_drink: notDrink,
          quantities: notDrink
            ? []
            : quantities.map(({ quantity }) => ({
                quantity,
              })),
        },
        sleeps: {
          not_slept: notSlept,
          periods:
            notSlept || periods.every(({ sleep }) => !sleep)
              ? []
              : periods
                  .filter(({ sleep }) => !!sleep)
                  .map(({ sleep }) => {
                    const [start_time, end_time] = sleep
                      .replace(/\s/g, '')
                      .split('-');
                    return { start_time, end_time };
                  }),
        },
        evacuations: {
          not_evacuated: notEvacuated,
          appearances: notEvacuated
            ? []
            : appearances.map(({ evacuation }) => ({ evacuation })),
        },
      },
    };
    return {
      type: actions.ADD_DAILY_SUMMARY_REQUEST,
      params: formatedParams,
    };
  },
  fetchStudentSummary: (student, classroom, date) => ({
    type: actions.FETCH_STUDENT_SUMMARY_REQUEST,
    student,
    classroom,
    date,
  }),
  fetchClassroom: (id, date) => ({
    type: actions.FETCH_CLASSROOM_DETAILS,
    id,
    date,
  }),
  setDate: (date) => ({
    type: actions.SET_DATE,
    date,
  }),
  setHeadquarter: (headquarter) => ({
    type: actions.SET_HEADQUARTER,
    headquarter,
  }),
  setEducationalStage: (educationalStage) => ({
    type: actions.SET_EDUCATIONAL_STAGE,
    educationalStage,
  }),
  fetchDailySummaryDetails: (classroomId, id) => ({
    type: actions.FETCH_DAILY_SUMMARY_DETAILS_REQUEST,
    classroomId,
    id,
  }),
  setSelectedsIds: (ids) => ({
    type: actions.SET_SELECTEDS_IDS,
    studentsIdsSelecteds: ids,
  }),
  fetchHeadquarters: () => ({
    type: actions.FETCH_HEADQUARTERS,
  }),
  fetchEducationalStages: (headquarter) => ({
    type: actions.FETCH_EDUCATIONAL_STAGES,
    headquarter,
  }),
  fetchSettings: () => ({
    type: actions.FETCH_SETTINGS_REQUEST,
  }),
  saveSettings: (draftSections, initialSections, activeSection) => {
    const sectionsArray = Object.entries(draftSections);
    const data = sectionsArray.map(([key, value]: [string, string]) => {
      const currentSection = initialSections.find(
        (section) => section.value === key
      );

      const initialClassroomsIds = currentSection.classrooms;

      return {
        diary_section_id: key,
        diary_section: {
          active_classroom_ids: value,
          inactive_classroom_ids: initialClassroomsIds.filter(
            (id) => !value.includes(id)
          ),
        },
      };
    });

    const currentDraftSection = data.find(
      (section) => section.diary_section_id === activeSection
    );

    return {
      type: actions.SAVE_SETTINGS_REQUEST,
      data: currentDraftSection,
    };
  },
  fetchDiarySections: () => ({
    type: actions.FETCH_DIARY_SECTIONS,
  }),
  changeVisibility: ({
    id,
    enable,
    onFail,
  }: Omit<ChangeVisibilitySagaProps, 'type'>) => ({
    type: actions.CHANGE_SECTION_VISIBILITY,
    id,
    enable,
    onFail,
  }),
  fetchRecipientsClassrooms: () => ({
    type: actions.FETCH_RECIPIENTS_CLASSROOMS,
  }),
  saveSectionRequest: ({
    diarySection,
    onSuccess,
    diarySectionId,
  }: Omit<SaveSectionSagaProps, 'type'>) => ({
    type: actions.SAVE_SECTION_REQUEST,
    diarySection,
    diarySectionId,
    onSuccess,
  }),
  fetchDiarySectionFormRequest: ({
    diarySectionId,
  }: Omit<FetchDiarySectionsFormProps, 'type'>) => ({
    type: actions.FETCH_DIARY_SECTION_FORM_REQUEST,
    diarySectionId,
  }),
  deleteDiarySection: ({
    diarySectionId,
  }: Omit<DeleteDiarySectionProps, 'type'>) => ({
    type: actions.DELETE_DIARY_SECTION_REQUEST,
    diarySectionId,
  }),
  duplicateDiarySection: ({
    diarySectionId,
  }: Omit<DuplicateDiarySectionProps, 'type'>) => ({
    type: actions.DUPLICATE_DIARY_SECTION_REQUEST,
    diarySectionId,
  }),
  fetchActivesDiarySections: ({
    classroomId,
  }: Omit<FetchActivesDiarySectionsProps, 'type'>) => ({
    type: actions.FETCH_ACTIVES_DIARY_SECTIONS_REQUEST,
    classroomId,
  }),
  saveDiary: ({
    params: { date, classroom_id, student_profile_ids, daily_summary },
    onSuccess,
    onFail,
  }: SaveDiaryProps): SaveDiarySagaParams => {
    const dailySummaryClone = _.cloneDeep(daily_summary);

    return {
      type: actions.SAVE_DIARY_REQUEST,
      params: {
        date,
        classroom_id,
        student_profile_ids,
        daily_summary: {
          notify: dailySummaryClone.notify,
          ...normalizeDiaryToSave(dailySummaryClone),
        },
      },
      onSuccess,
      onFail,
    };
  },
  fetchStudentDiaryForm: ({
    params,
    onFail,
  }: Omit<FetchStudentDiaryFormProps, 'type'>) => ({
    type: actions.FETCH_STUDENT_DIARY_FORM,
    params,
    onFail,
  }),
  fetchDiaryReportInitialRecipients: () => ({
    type: actions.FETCH_DIARY_REPORT_INITIAL_RECIPIENTS,
  }),
  fetchDiaryReportStudents: ({
    classroomId,
  }: Omit<FetchDiaryReportStudentsProps, 'type'>) => ({
    type: actions.FETCH_DIARY_REPORT_STUDENTS,
    classroomId,
  }),
  fetchDiaryReportSections: () => ({
    type: actions.FETCH_DIARY_REPORT_SECTIONS,
  }),
  exportDiaryReport: ({ params }: Omit<DiaryReportProps, 'type'>) => ({
    type: actions.EXPORT_DIARY_REPORT_REQUEST,
    params,
  }),
  requestHideDiaryInfo: () => ({
    type: actions.HIDE_DIARY_INFO_MODAL_REQUEST,
  }),
  fetchStudentsDiariesForm: ({
    classroomId,
    date,
  }: Omit<FetchStudentsDiariesFormProps, 'type'>) => ({
    type: actions.FETCH_STUDENTS_DIARIES_FORM,
    classroomId,
    date,
  }),
  saveDiariesPerSections: ({
    params,
    onSuccess,
    onFail,
  }: SaveDiariesPerSectionsProps): SaveDiariesPerSectionsSagaParams => {
    const paramsClone: SaveDiariesPerSectionsProps['params'] =
      _.cloneDeep(params);

    const { date, notify, classroom_id, studentsDiaries } = paramsClone;

    const daily_summaries = Object.entries(studentsDiaries).map(
      ([studentId, diary]) => ({
        student_profile_id: studentId,
        ...normalizeDiaryToSave(diary),
      })
    );

    return {
      type: actions.SAVE_DIARIES_PER_SECTION_REQUEST,
      params: {
        date,
        classroom_id,
        notify,
        daily_summaries,
      },
      onSuccess,
      onFail,
    };
  },
};

export default actions;
