import styled, { css } from 'styled-components';
import { ThemeProps } from 'config/themes/types';

export const MainContainer = styled.section`
  ${({ theme: { space } }: ThemeProps) => css`
    margin-top: ${space.xl};

    svg {
      &:not(:first-child) {
        margin-top: ${space.sm};
      }
    }
  `};
`;
