import React, { useEffect, useState } from 'react';
import { CloneSchoolProductProps } from './types';
import { useSelector } from 'react-redux';
import { SchoolProductsState } from 'store/edupay/schoolProducts/types';
import { useDispatch } from 'react-redux';

import actionSchoolProducts from 'store/edupay/schoolProducts/actions';

import Loader from 'components/Loader';
import SchoolProductForm from 'components/Payments/PaymentsContainer/SchoolProductForm';
import Toast from 'components/Toast';

import {
  extractDiscount,
  extractStudents,
  formattedDiscounts,
  makeClassroomIds,
} from 'core/utils/edupay/functions';
import scrollTop from 'core/utils/scrollTop';

import * as S from './styles';
import { DISCOUNT_KINDS, INSTALLMENTS_SCHOOLPRODUCT } from 'core/constants';

const CloneSchoolProduct = ({ match }: CloneSchoolProductProps) => {
  const schoolProductId = match.params.id;
  const dispatch = useDispatch();

  const [onSubmitErrorFunctions, setOnSubmitErrorFunctions] = useState({
    setSubmitting: null,
    updateFormContext: null,
    addErrorsToFormValidationResultFromServer: null,
    goToFirstStep: null,
  });

  const { fetchCloneSchoolProductRequest, setCloneSchoolProduct } =
    actionSchoolProducts;

  const {
    classroomsStudents,
    classrooms,
    cloneSchoolProduct,
    isLoading,
    isSubmitting,
    error,
  } = useSelector((state: SchoolProductsState) => state.schoolProducts);

  const formatAttributes = () => ({
    title: cloneSchoolProduct.attributes.title,
    allowed_payment_method:
      cloneSchoolProduct.attributes.allowed_payment_method,
    price: cloneSchoolProduct.attributes.price,
    description: cloneSchoolProduct.attributes.description,
    sent_kind: cloneSchoolProduct.attributes.sent_kind,
    classroom_with_student_profile_ids: makeClassroomIds(classroomsStudents),
    classroom_ids: cloneSchoolProduct.meta.classroom_ids,
    absorb_credit_card_tax: true,
    absorb_installment_tax: true,
    absorb_billet_tax: true,
    absorb_pix_tax: true,
    max_installments: cloneSchoolProduct.attributes.max_installments,
    parent_id: cloneSchoolProduct.id,
    toggle_discount: cloneSchoolProduct.attributes.toggle_discount,
    edupay_discounts: extractDiscount(cloneSchoolProduct),
    recipient_wallet_id: cloneSchoolProduct.attributes.recipient_wallet_id,
    allow_credit_card_discount:
      cloneSchoolProduct.attributes.edupay_discounts?.data[0]?.attributes.allowed_payment_methods?.includes(
        'credit_card'
      ),
    absorb_tax: cloneSchoolProduct.attributes.settings?.absorb_tax,
  });

  const formatMetaAttributes = () => {
    return {
      classrooms,
      status: 'pending',
      student_profiles: extractStudents(classroomsStudents),
      ...cloneSchoolProduct.meta,
      select_options: {
        ...cloneSchoolProduct.meta.select_options,
        max_installments: INSTALLMENTS_SCHOOLPRODUCT,
        kind: DISCOUNT_KINDS,
      },
    };
  };

  useEffect(() => {
    dispatch(
      fetchCloneSchoolProductRequest({
        schoolProductId,
      })
    );
  }, [schoolProductId]);

  useEffect(() => {
    if (onSubmitErrorFunctions?.setSubmitting && error?.status === 422) {
      onSubmitErrorFunctions?.setSubmitting(false);
      onSubmitErrorFunctions?.updateFormContext(() => ({
        hasBackendValidationError: true,
      }));
      onSubmitErrorFunctions?.addErrorsToFormValidationResultFromServer(error);
      onSubmitErrorFunctions?.goToFirstStep();
      scrollTop();
    }
  }, [error]);

  const onSubmit = (formContext, { goToFirstStep, setSubmitting }) => {
    const {
      form,
      addErrorsToFormValidationResultFromServer,
      updateFormContext,
    } = formContext;

    setOnSubmitErrorFunctions({
      setSubmitting: setSubmitting,
      updateFormContext: updateFormContext,
      addErrorsToFormValidationResultFromServer:
        addErrorsToFormValidationResultFromServer,
      goToFirstStep: goToFirstStep,
    });

    let params = form;

    if (
      form.toggle_discount &&
      (form.allowed_payment_method.includes('billet') ||
        form.allowed_payment_method.includes('pix'))
    ) {
      params = {
        ...form,
        edupay_discounts: formattedDiscounts(form),
      };
    } else {
      delete params.edupay_discounts;
    }

    params = {
      ...params,
      settings: { absorb_tax: form?.absorb_tax },
    };

    dispatch(setCloneSchoolProduct(params));
  };

  return (
    <S.CloneSchoolProductWrapper>
      {isLoading ||
      !cloneSchoolProduct ||
      !classrooms ||
      !classroomsStudents ? (
        <Loader data-testid="loader" />
      ) : (
        <>
          <SchoolProductForm
            data-testid="school-product-form"
            action="new"
            form={formatAttributes()}
            formMeta={formatMetaAttributes()}
            onSubmit={onSubmit}
            isSubmitting={isSubmitting}
            backTo={`/schools/school_products/${schoolProductId}`}
            titlePage="Nova cobrança"
            titleModal="Descartar preenchimento"
            contentModal="A duplicação da cobrança será cancelada e as informações preenchidas serão descartadas. Tem certeza que deseja descartar?"
            error={error?.status !== 422 ? error : null}
          />
        </>
      )}
      <Toast />
    </S.CloneSchoolProductWrapper>
  );
};

export default CloneSchoolProduct;
