const actions = {
  FETCH_REQUEST: 'FETCH_REQUEST/recurrentProducts',
  FETCH_SUCCESS: 'FETCH_SUCCESS/recurrentProducts',
  TOGGLE_MODAL: 'TOGGLE_MODAL/recurrentProducts',
  TOGGLE_DELETE_MODAL: 'TOGGLE_DELETE_MODAL/recurrentProducts',
  SET: 'SET/recurrentProducts',
  UNSELECT: 'UNSELECT/recurrentProducts',
  ADD_RECURRENT_PRODUCT: 'ADD_RECURRENT_PRODUCT/recurrentProducts',
  ADD_RECURRENT_PRODUCT_SUCCESS: 'ADD_RECURRENT_PRODUCT_SUCCESS/recurrentProducts',
  EDIT_RECURRENT_PRODUCT: 'EDIT_RECURRENT_PRODUCT/recurrentProducts',
  EDIT_RECURRENT_PRODUCT_SUCCESS: 'EDIT_RECURRENT_PRODUCT_SUCCESS/recurrentProducts',
  DISABLE_RECURRENT_PRODUCT: 'DISABLE_RECURRENT_PRODUCT/recurrentProducts',
  DISABLE_RECURRENT_PRODUCT_SUCCESS: 'DISABLE_RECURRENT_PRODUCT_SUCCESS/recurrentProducts',
  ERROR: 'ERROR/recurrentProducts',
  fetchRecurrentProducts: params => ({ type: actions.FETCH_REQUEST, params }),
  setRecurrentProduct: id => ({ type: actions.SET, id }),
  unselect: () => ({ type: actions.UNSELECT }),
  toggleModal: () => ({ type: actions.TOGGLE_MODAL }),
  toggleDeleteModal: () => ({ type: actions.TOGGLE_DELETE_MODAL }),
  addRecurrentProduct: params => ({ type: actions.ADD_RECURRENT_PRODUCT, params }),
  editRecurrentProduct: params => ({ type: actions.EDIT_RECURRENT_PRODUCT, params }),
  disableRecurrentProduct: id => ({ type: actions.DISABLE_RECURRENT_PRODUCT, id }),
};

export default actions;
