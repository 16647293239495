import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autobind from 'autobind-decorator';

import withAppContext from 'core/hoc/withAppContext';
import colorWithAlpha from 'core/utils/colorWithAlpha';

import FormGroupSelectorSelectableOption from 'components/Form/GroupSelector/SelectableOption';
import FormGroupSelectorSelectableOptionGroup from 'components/Form/GroupSelector/SelectableOptionGroup';
import TextFieldSearchGroup from 'components/Form/GroupSelector/TextFieldSearchGroup';
import OutlineBox from 'components/OutlineBox';

import './style.scss';

class FormGroupSelectorSelectableColumn extends Component {
  static propTypes = {
    options: PropTypes.array.isRequired,
    optionComponent: PropTypes.func.isRequired,
    groupComponent: PropTypes.func,
    onOptionSelect: PropTypes.func.isRequired,
    onOptionDeselect: PropTypes.func,
    onGroupSelect: PropTypes.func,
    groupAccessor: PropTypes.func,
    optionAccessor: PropTypes.func,
    selectAllLabel: PropTypes.string.isRequired,
    onSelectBehavior: PropTypes.string,
    selected: PropTypes.array,
    selectAll: PropTypes.func,
    counterLabel: PropTypes.func,
    appContext: PropTypes.shape({
      primaryColor: PropTypes.string,
    }),
    lockSelected: PropTypes.bool,
    lockOptions: PropTypes.bool,
    emptyState: PropTypes.node,
    searchPlaceholder: PropTypes.string,
    onSearch: PropTypes.func,
    attributeName: PropTypes.string.isRequired,
    showSelectAll: PropTypes.bool,
  };

  static defaultProps = {
    lockSelected: false,
    lockOptions: false,
    showSelectAll: true,
  };

  state = {
    isHovering: false,
  };

  @autobind
  renderOptions() {
    const {
      optionComponent,
      options,
      onSelectBehavior,
      selected,
      optionAccessor,
    } = this.props;

    return options.map((option, index) => (
      <FormGroupSelectorSelectableOption
        key={`opt-${index}`}
        onClick={() => this.onOptionSelect(option)}
        selected={
          onSelectBehavior === 'highlight' &&
          selected.find((opt) => optionAccessor(opt) === optionAccessor(option))
        }
      >
        {optionComponent(option)}
      </FormGroupSelectorSelectableOption>
    ));
  }

  @autobind
  renderOptionGroup() {
    const { options, optionComponent, groupComponent, lockSelected } =
      this.props;

    return options.map((optionGroup, index) => (
      <FormGroupSelectorSelectableOptionGroup key={`opt-group-${index}`}>
        {groupComponent(optionGroup, this.onOptionGroupSelect)}
        {optionGroup.options.map((option, index) => (
          <FormGroupSelectorSelectableOption
            key={`opt-${index}`}
            onClick={() => this.onOptionSelect(option, optionGroup)}
            locked={!!(option.locked && lockSelected)}
          >
            {optionComponent(option)}
          </FormGroupSelectorSelectableOption>
        ))}
      </FormGroupSelectorSelectableOptionGroup>
    ));
  }

  @autobind
  onOptionSelect(option, optionGroup) {
    const { onOptionSelect } = this.props;

    onOptionSelect({
      optionGroup,
      option,
      placeOption: this.placeOptionInGroup,
      removeSelectedOption: this.removeSelectedOptionFromGroup,
    });
  }

  @autobind
  onOptionGroupSelect(optionGroup) {
    const { onGroupSelect } = this.props;

    onGroupSelect({
      optionGroup,
      placeOption: this.placeOptionInGroup,
      removeSelectedOption: this.removeSelectedOptionFromGroup,
    });
  }

  @autobind
  placeOption(options, option) {
    const { optionAccessor } = this.props;

    if (!options.find((opt) => optionAccessor(opt) == optionAccessor(option))) {
      options.push(option);
    }
    return options;
  }

  @autobind
  placeOptionGroup(options, optionGroup) {
    const { groupAccessor } = this.props;

    const alreadySelected = options.find(
      (opt) => groupAccessor(opt) === groupAccessor(optionGroup)
    );
    if (alreadySelected) {
      optionGroup.options.forEach((option) => {
        alreadySelected.options.push(option);
      });
    } else {
      options.push(optionGroup);
    }
    return options;
  }

  @autobind
  placeOptionInGroup(groups, currentGroup, option) {
    const { groupAccessor } = this.props;
    const groupAlreadySelected = groups.find(
      (select) => groupAccessor(select) === groupAccessor(currentGroup)
    );
    if (groupAlreadySelected) {
      groupAlreadySelected.options.push(option);
    } else {
      groups.push({
        label: groupAccessor(currentGroup),
        options: [option],
      });
    }

    return groups;
  }

  @autobind
  removeSelectedOption(options, option) {
    const { optionAccessor } = this.props;
    let currentOptions = options;
    currentOptions = options.filter(
      (opt) => optionAccessor(opt) !== optionAccessor(option)
    );

    return currentOptions;
  }

  @autobind
  removeOptionGroup(options, option) {
    const { groupAccessor } = this.props;

    const newOptions = options.filter(
      (opt) => groupAccessor(opt) !== groupAccessor(option)
    );

    return newOptions;
  }

  @autobind
  removeSelectedOptionFromGroup(groups, currentGroup, option) {
    const { optionAccessor, groupAccessor } = this.props;
    const currentOptionGroup = groups.find(
      (optGroup) => groupAccessor(optGroup) === groupAccessor(currentGroup)
    );
    const currentOptions = currentOptionGroup.options.filter(
      (opt) => optionAccessor(opt) !== optionAccessor(option)
    );
    currentOptionGroup.options = currentOptions;
    if (currentOptionGroup && (currentOptions && currentOptions.length) === 0) {
      groups = groups.filter(
        (opt) => groupAccessor(opt) != groupAccessor(currentOptionGroup)
      );
    }

    return groups;
  }

  @autobind
  selectAll() {
    const { selectAll } = this.props;

    selectAll({
      placeOption: this.placeOptionGroup,
      placeOptionInGroup: this.placeOptionInGroup,
      removeSelectedOptionFromGroup: this.removeSelectedOptionFromGroup,
    });
  }

  @autobind
  optionsLength() {
    const { options, counterLabel } = this.props;
    let length = 0;

    length = options.reduce(
      (accumulator, currentValue) => accumulator + currentValue.options.length,
      0
    );

    return `${length} ${counterLabel(length)}`;
  }

  @autobind
  toggleHover() {
    this.setState((prevState) => ({
      isHovering: !prevState.isHovering,
    }));
  }

  render() {
    const {
      selectAllLabel,
      appContext: { primaryColor },
      options,
      emptyState,
      attributeName,
      searchPlaceholder,
      onSearch,
      showSelectAll,
    } = this.props;
    const { isHovering } = this.state;
    const style = {
      backgroundColor: isHovering ? colorWithAlpha(primaryColor, 0.1) : '#FFF',
      color: isHovering ? primaryColor : '#666',
      borderColor: isHovering ? colorWithAlpha(primaryColor, 0.1) : '#999',
    };

    return (
      <div className="SelectableColumn">
        <div className="optionsCount">
          <strong>{this.optionsLength()}</strong>
          {showSelectAll && (
            <OutlineBox
              onClick={() => this.selectAll()}
              style={style}
              onMouseEnter={this.toggleHover}
              onMouseLeave={this.toggleHover}
            >
              {selectAllLabel}
            </OutlineBox>
          )}
        </div>
        <TextFieldSearchGroup
          attributeName={attributeName}
          placeholder={searchPlaceholder}
          onChange={onSearch}
        />
        <div className="selectComponent">
          {options.length ? (
            this.renderOptionGroup()
          ) : (
            <div className="emptyOptions">{emptyState}</div>
          )}
        </div>
      </div>
    );
  }
}

export default withAppContext(FormGroupSelectorSelectableColumn);
