const actions = {
  ERROR: 'ERROR/twoaf',
  UPDATE_TOGGLE: 'UPDATE_TOGGLE/twoaf',

  SEND_CODE_EMAIL: 'SEND_CODE_EMAIL/twoaf',
  SEND_CODE_EMAIL_SUCCESS: 'SEND_CODE_EMAIL_SUCCESS/twoaf',

  ACTIVATION_TWO_AF: 'ACTIVATION_TWO_AF/twoaf',
  ACTIVATION_TWO_AF_SUCCESS: 'ACTIVATION_TWO_AF_SUCCESS/twoaf',

  DEACTIVATION_TWO_AF: 'DEACTIVATION_TWO_AF/twoaf',
  DEACTIVATION_TWO_AF_SUCCESS: 'DEACTIVATION_TWO_AF_SUCCESS/twoaf',

  sendCodeEmail: () => ({
    type: actions.SEND_CODE_EMAIL,
  }),

  activationTwoAF: (otpCode: string) => ({
    type: actions.ACTIVATION_TWO_AF,
    otpCode,
  }),

  deactivationTwoAF: () => ({
    type: actions.DEACTIVATION_TWO_AF,
  }),
};

export default actions;
