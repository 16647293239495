import React, { Component } from 'react';
import PropTypes from 'prop-types';
import InfinityScroll from 'react-infinite-scroller';
import Loader from 'components/Loader';
import MessagesOtherUsersSuperiorBar from 'components/Messages/OtherUsers/SuperiorBar';
import SentMessage from 'components/Messages/SentMessage';
import ReceivedMessage from 'components/Messages/ReceivedMessage';
import MessagesChatInput from 'components/Messages/ChatInput';
import UploadBar from 'components/Form/UploadBar';
import FormContainer from 'components/Form/FormContainer';
import MessagesDisabledChatInput from 'components/Messages/DisabledChatInput';
import AttachmentEntity from 'core/entities/Attachment';
import withAppContext from 'core/hoc/withAppContext';
import withToastMessage from 'core/hoc/withToastMessage';
import OtherUsersMessagesService from 'core/services/OtherUsers/Messages';
import { handleRequestError } from 'core/utils/request';
import { ButtonRadius } from 'components/ButtonRadius';

import './style.scss';

const FOOTER_EDIT_HEIGTH = 40;
const APPROVED_MESSAGE_HEIGTH = 46;

class MessagesOtherUsersChatDisplay extends Component {
  static propTypes = {
    activeChannelKind: PropTypes.oneOf(['private', 'family']),
    activeChat: PropTypes.object,
    appContext: PropTypes.shape({
      dataArea: PropTypes.string.isRequired,
      currentUserType: PropTypes.string.isRequired,
      currentSchool: PropTypes.object.isRequired,
    }).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        channelId: PropTypes.string.isRequired,
        chatId: PropTypes.string,
        kind: PropTypes.string,
      }).isRequired,
    }).isRequired,
    showBackButton: PropTypes.bool,
    toastActions: PropTypes.shape({
      showToast: PropTypes.func.isRequired,
    }),
    socket: PropTypes.object.isRequired,
  };

  state = {
    inactiveChatMessage: '',
    canSendMessage: true,
    isLoadingMessages: true,
    isSendingMessage: false,
    hasToLoadMore: false,
    messages: [],
    uploadProgress: 0,
    showProgressBar: false,
    isUploadModalOpen: false,
    showScrollButton: false,
    countUnreadMessages: 0,
    messagesPendingIds: [],
    refSetInterval: null,
    refScroller: null,
    channel: {},
    chatId: null,
  };

  featureFlag = false;

  async componentDidMount() {
    await this.loadMessages();
    this.pullingMessages();

    this.displayFabButton();

    this.scrollToBottom('auto', true);
    this.enterWebsocketRoom();
  }

  enterWebsocketRoom() {
    const {
      appContext: { dataArea },
      socket,
      match: {
        params: { channelId },
      },
    } = this.props;

    const { chatId } = this.state;

    if (!socket) return;

    const messageService = new OtherUsersMessagesService(dataArea);

    if (!chatId) return;

    socket.emit('join-room', chatId);

    socket.on(
      'new-message',
      async (messageId, _chatId, newMessageApprovalStatus) => {
        if (newMessageApprovalStatus === 'pending') return;

        const newMessage = await messageService.getMessage({
          channelId,
          messageId,
        });

        if (newMessage?.data?.attributes?.type !== 'sent') {
          this.addNewMessage(newMessage.data);
          this.scrollToBottom('smooth', true);
        }
      }
    );

    socket.on('approve-message', async (messageId) => {
      const newMessage = await messageService.getMessage({
        channelId,
        messageId,
      });

      this.addNewMessage(newMessage.data);
      this.scrollToBottom('smooth', true);
    });

    socket.on('delete-message', async (messageId) => {
      const newMessage = await messageService.getMessage({
        channelId,
        messageId,
      });

      if (newMessage) this.updateMessage(newMessage.data);
    });

    socket.on('edit-message', async (messageId) => {
      const newMessage = await messageService.getMessage({
        channelId,
        messageId,
      });

      const approvedMessage =
        newMessage.data.attributes.approvalStatus === 'approved';

      if (approvedMessage) {
        this.updateMessage(newMessage.data);
      } else {
        this.addNewMessage(newMessage.data);
      }
    });
  }

  componentWillUnmount() {
    const { socket } = this.props;

    const { chatId } = this.state;

    if (!socket) return;

    socket.off('new-message');
    socket.off('approve-message');
    socket.off('delete-message');
    socket.off('edit-message');

    if (!chatId) return;

    socket.emit('leave-room', chatId);
  }

  pullingMessages = () => {
    const ref = setInterval(async () => {
      // await this.loadMessages();
      // await this.checkMessagesPending();
    }, 5000);

    this.setState({ refSetInterval: ref });
  };

  displayFabButton = () => {
    const ref = document.querySelector('.message-list');

    this.setState({ refScroller: ref });

    let position = 0;

    ref.addEventListener('scroll', () => {
      if (position == 0 || position < ref.scrollTop) {
        position = ref.scrollTop;
      }

      const distance = ref.scrollTop - position;

      const scroller =
        distance === FOOTER_EDIT_HEIGTH || distance === APPROVED_MESSAGE_HEIGTH;

      if (scroller || position <= ref.scrollTop) {
        this.setState({ showScrollButton: false, countUnreadMessages: 0 });
      } else {
        this.setState({ showScrollButton: true });
      }
    });
  };

  displayToast = (text) => {
    this.props.toastActions.showToast(text);
  };

  sendMessage = async ({ message, clearInput }) => {
    const {
      appContext: { dataArea },
      match: {
        params: { channelId, kind },
      },
      toastActions: { showToast },
    } = this.props;

    const { attachment } = this.state;

    const messagesService = new OtherUsersMessagesService(dataArea);

    this.setState({
      isSendingMessage: true,
    });

    if (attachment) {
      this.toggleUploadModal();
    }

    try {
      const newMessage = await messagesService.createMessage({
        message,
        attachment,
        channelId,
        kind,
        onUploadProgress: this.uploadProgress,
      });
      clearInput();
      this.clearAttachment();

      this.addNewMessage(newMessage);

      if (!this.state.chatId) {
        const chatId = newMessage?.relationships.chat?.data?.id;

        this.setState({ chatId });

        this.enterWebsocketRoom();
      }
    } catch (errorSendingMessage) {
      const fallback = () => {
        if (errorSendingMessage.response) {
          showToast(
            'Ocorreu um erro ao tentar enviar sua mensagem. Se o erro persistir, aguarde um instante e tente novamente.'
          );
        } else {
          this.setState({ errorSendingMessage });
        }
      };

      handleRequestError(errorSendingMessage, { logger: showToast, fallback });
    } finally {
      this.setState({ isSendingMessage: false }, () =>
        this.scrollToBottom('smooth')
      );
    }
  };

  clearAttachment() {
    this.setState({ attachment: null });
  }

  addNewMessage = (newMessage) => {
    this.setState((prevState) => ({
      messages: [...prevState.messages, newMessage],
    }));
  };

  updateMessage = (newMessage) => {
    this.setState((prevState) => {
      const { messages } = prevState;
      const messageIndex = messages.findIndex(({ id }) => newMessage.id === id);

      return {
        messages: [
          ...messages.slice(0, messageIndex),
          newMessage,
          ...messages.slice(messageIndex + 1, messages.length),
        ],
      };
    });
  };

  toggleUploadModal = () => {
    this.setState((prevState) => ({
      isUploadModalOpen: !prevState.isUploadModalOpen,
    }));
  };

  toggleInvalidAttachmentModal = () => {
    this.setState((prevState) => ({
      invalidAttachment: !prevState.invalidAttachment,
    }));
  };

  onAttachmentSelect = (event) => {
    const attachment = new AttachmentEntity(event.target.files[0]);
    const {
      appContext: { currentUserType, currentSchool },
    } = this.props;

    if (
      attachment.isTypeValid(currentUserType) &&
      attachment.isSizeValid(currentUserType, currentSchool)
    ) {
      this.setState({
        attachment: attachment,
        isUploadModalOpen: true,
        invalidAttachment: false,
      });
    } else {
      let errors = [];
      if (!attachment.isTypeValid(currentUserType)) errors.push('type');
      if (!attachment.isSizeValid(currentUserType, currentSchool))
        errors.push('size');

      this.setState({
        invalidAttachment: true,
        invalidAttachmentError: errors,
      });
    }
  };

  uploadProgress = (progress, cancelToken) => {
    const showBar = progress !== 100;

    this.setState({
      uploadProgress: progress,
      showProgressBar: showBar,
      cancelToken: cancelToken,
      isLoadingNewMessage: showBar,
    });
  };

  cancelUpload = () => {
    try {
      this.state.cancelToken.cancel();
      this.setState({
        showProgressBar: false,
      });
    } catch (error) {
      this.setState({ error });
    }
  };

  onUploadSuccess = (message) => {
    this.addNewMessage(message);
    this.setState({ isLoadingNewMessage: false }, () =>
      this.scrollToBottom('smooth')
    );
  };

  onUploadCancel = () => {
    this.setState({
      showProgressBar: false,
      attachment: null,
      uploadProgress: 0,
      cancelToken: null,
      isUploadModalOpen: false,
      isLoadingNewMessage: false,
    });
  };

  handleAnchorMessage = async ({ message, ref }) => {
    const {
      appContext: { dataArea },
      toastActions: { showToast },
      match: {
        params: { kind },
      },
    } = this.props;

    const { messages, channel } = this.state;

    this.setState({ isLoadingMessages: true, showScrollButton: true });

    const messageRefId = message.relationships.attachedMessage.data.id;

    try {
      const messageAlreadyExist = messages.some((m) => m.id === messageRefId);

      if (messageAlreadyExist) {
        ref.current.href = `#${messageRefId}`;
      } else {
        const messagesService = new OtherUsersMessagesService(dataArea);
        const lastMessage = messages[messages.length - 1];

        const { messages: listMessages } =
          await messagesService.fetchAnchorMessage({
            channelId: channel.id,
            kind,
            rangeIdStart: messageRefId,
            rangeIdEnd: lastMessage.id,
          });

        this.setState(
          {
            messages: listMessages,
            showScrollButton: true,
            isLoadingMessages: false,
          },
          () => this.displayFabButton()
        );
      }
    } catch (error) {
      this.setState({ error });
      showToast(
        'Ocorreu um erro ao localizar a mensagem. Por favor, tente mais tarde.'
      );
    } finally {
      this.setState({ isLoadingMessages: false });
    }
  };

  handleLoadMoreMessages = (page) => {
    this.loadMessages({ page });
  };

  loadOldMessages = async (page) => {
    const {
      appContext: { dataArea },
      match: {
        params: { channelId, kind },
      },
    } = this.props;

    const messagesService = new OtherUsersMessagesService(dataArea);

    try {
      const response = await messagesService.fetchMessages({
        channelId,
        page,
        kind,
      });

      const {
        messages,
        channel,
        totalItemsCount,
        canSendMessage,
        inactiveChatMessage,
      } = response;

      const withMoreMessages = (prevState) => {
        const containsMessage = messages.length > 0;

        const concatenatedMessages = containsMessage && [
          ...messages,
          ...prevState.messages,
        ];

        const hasToLoadMore = this.hasToLoadMore(
          concatenatedMessages,
          totalItemsCount
        );

        return {
          ...(containsMessage && { messages: concatenatedMessages }),
          channel,
          hasToLoadMore,
          canSendMessage,
          inactiveChatMessage,
        };
      };

      this.setState(withMoreMessages);
    } catch (error) {
      this.setState({ errorFetchingMessages: error });
    } finally {
      this.setState({ isLoadingMessages: false });
    }
  };

  hasToLoadMore = (messages, totalItemsCount) => {
    return messages.length < totalItemsCount;
  };

  loadMessages = async ({ page } = {}) => {
    const {
      appContext: { dataArea },
      match: {
        params: { channelId, kind },
      },
    } = this.props;

    const { showScrollButton } = this.state;

    const messagesService = new OtherUsersMessagesService(dataArea);

    try {
      const response = await messagesService.fetchMessages({
        channelId,
        page,
        kind,
      });

      const {
        messages,
        channel,
        totalItemsCount,
        canSendMessage,
        chatId,
        inactiveChatMessage,
      } = response;

      const withMoreMessages = (prevState) => {
        const lastMessageRequest = messages[messages.length - 1];
        const lastMessageState =
          prevState.messages[prevState.messages.length - 1];
        let concatenatedMessages = [];

        let stateDataChange = {};

        if (
          lastMessageState &&
          lastMessageRequest &&
          lastMessageRequest.id > lastMessageState.id
        ) {
          const index = messages.findIndex(
            (message) => message.id === lastMessageState.id
          );
          const newMessages = messages.slice(index + 1);
          concatenatedMessages = [...prevState.messages, ...newMessages];

          stateDataChange = {
            messages: concatenatedMessages,
          };

          const countUnreadMessages =
            prevState.countUnreadMessages + newMessages.length;

          this.setState({ countUnreadMessages });
        } else if (prevState.messages.length === 0) {
          concatenatedMessages = messages;
          stateDataChange = {
            messages: concatenatedMessages,
          };
        } else {
          concatenatedMessages = prevState.messages;
          stateDataChange = {
            messages: concatenatedMessages,
          };
        }

        const hasToLoadMore = this.hasToLoadMore(
          concatenatedMessages,
          totalItemsCount
        );

        const messagesPendingIds = concatenatedMessages
          .filter(
            (msg) =>
              msg.attributes.approvalStatus === 'pending' &&
              dataArea !== 'school'
          )
          .map((msg) => msg.id);

        this.setState({ messagesPendingIds });

        stateDataChange = {
          ...stateDataChange,
          channel,
          hasToLoadMore,
          canSendMessage,
          chatId,
          inactiveChatMessage,
        };

        return stateDataChange;
      };

      this.setState(withMoreMessages);
      !showScrollButton && this.scrollToBottom('smooth', true);
    } catch (error) {
      this.setState({ errorFetchingMessages: error });
    } finally {
      const finishedLoading = { isLoadingMessages: false };
      this.setState(finishedLoading);
    }
  };

  checkMessagesPending = async () => {
    const { messagesPendingIds } = this.state;

    if (messagesPendingIds.length > 0) {
      const {
        activeChat,
        appContext: { dataArea },
        match: {
          params: { chatId },
        },
      } = this.props;

      const { messagesPendingIds } = this.state;

      const messagesService = new OtherUsersMessagesService(dataArea);

      const { messages } = await messagesService.fetchChatMessages({
        channelId: activeChat.id,
        chatId,
        messageIds: messagesPendingIds,
      });

      messages.forEach((message) => {
        if (message.attributes.approvalStatus !== 'pending') {
          this.updateMessage({ newMessage: message });

          const index = messagesPendingIds.indexOf(message.id);
          if (index > -1) {
            this.setState({
              messagesPendingIds: messagesPendingIds.splice(index, 1),
            });
          }
        }
      });
    }
  };

  shouldShowActionMenu = (message) => {
    return message.attributes.canDelete;
  };

  scrollToBottom = (scrollBehavior) => {
    let scrollOption = {
      behavior: scrollBehavior === 'smooth' ? 'smooth' : 'auto',
    };

    this.lastMessageNode && this.lastMessageNode.scrollIntoView(scrollOption);
    this.setState({ showScrollButton: false, countUnreadMessages: 0 });
  };

  handleActionFabClick = () => {
    this.scrollToBottom('smooth', true);
    this.setState({ countUnreadMessages: 0 });
  };

  setLastMessageNode = (node) => {
    this.lastMessageNode = node;
  };

  render() {
    const {
      appContext: { dataArea },
      match,
      showBackButton,
    } = this.props;

    const {
      canSendMessage,
      inactiveChatMessage,
      errorFetchingMessages,
      errorSendingMessage,
      isLoadingMessages,
      isSendingMessage,
      messages,
      uploadProgress,
      showProgressBar,
      isUploadModalOpen,
      hasToLoadMore,
      attachment,
      channel,
      invalidAttachment,
      invalidAttachmentError,
      showScrollButton,
      countUnreadMessages,
    } = this.state;

    if (errorFetchingMessages) throw errorFetchingMessages;
    if (errorSendingMessage) throw errorSendingMessage;

    return (
      <div className="MessagesOtherUserChatDisplay">
        <div className="chat-content">
          <Loader isLoading={isLoadingMessages}>
            <MessagesOtherUsersSuperiorBar
              channel={channel}
              match={match}
              showBackButton={showBackButton}
              backTo={`/${dataArea}/messages`}
            />
            <div className="message-list">
              <InfinityScroll
                hasMore={hasToLoadMore}
                initialLoad={false}
                isReverse
                loadMore={this.loadOldMessages}
                loader={
                  isLoadingMessages && <Loader key="chats-infinity-scroll" />
                }
                pageStart={1}
                threshold={700}
                useWindow={false}
              >
                {messages &&
                  messages.map((message) =>
                    message.attributes.type === 'sent' ? (
                      <SentMessage
                        key={message.id}
                        message={message}
                        setRef={this.setLastMessageNode}
                        anchorMessage={this.handleAnchorMessage}
                        featureFlag={this.featureFlag}
                        replyMessage={() => this.handleReplyMessage(message)}
                      />
                    ) : (
                      <ReceivedMessage
                        key={message.id}
                        message={message}
                        authorize={() => this.authorizeMessage(message.id)}
                        unauthorize={() => this.unauthorizeMessage(message.id)}
                        setRef={this.setLastMessageNode}
                        replyMessage={() => this.handleReplyMessage(message)}
                        anchorMessage={this.handleAnchorMessage}
                        featureFlag={this.featureFlag}
                        showActionMenu={this.shouldShowActionMenu(message)}
                      />
                    )
                  )}
              </InfinityScroll>
            </div>

            {showScrollButton && (
              <span className="fab-button">
                <ButtonRadius
                  badge={countUnreadMessages}
                  onClick={this.handleActionFabClick}
                />
              </span>
            )}

            <div className="message-input">
              {canSendMessage ? (
                <FormContainer>
                  {showProgressBar && (
                    <UploadBar
                      attachment={attachment}
                      progress={uploadProgress}
                      cancelUpload={this.cancelUpload}
                    />
                  )}
                  <MessagesChatInput
                    attributeName="message"
                    onSubmit={this.sendMessage}
                    uploadProgress={this.uploadProgress}
                    onUploadCancel={this.onUploadCancel}
                    onUploadSuccess={this.onUploadSuccess}
                    onAttachmentSelect={this.onAttachmentSelect}
                    isLoadingNewMessage={isSendingMessage}
                    isUploadModalOpen={isUploadModalOpen}
                    attachment={attachment}
                    toggleModal={this.toggleUploadModal}
                    toggleInvalidAttachmentModal={
                      this.toggleInvalidAttachmentModal
                    }
                    invalidAttachment={invalidAttachment}
                    invalidAttachmentError={invalidAttachmentError}
                    displayToast={this.displayToast}
                  />
                </FormContainer>
              ) : (
                <MessagesDisabledChatInput
                  inactiveChatMessage={inactiveChatMessage}
                />
              )}
            </div>
          </Loader>
        </div>
      </div>
    );
  }
}

export default withAppContext(withToastMessage(MessagesOtherUsersChatDisplay));
