import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import autobind from 'autobind-decorator';

import AgendaIcon from 'components/AgendaIcon';
import Tooltip from 'components/Tooltip';

import withAppContext from 'core/hoc/withAppContext';

import './style.scss';

class MessagesInfo extends PureComponent {
  static propTypes = {
    appContext: PropTypes.shape({
      primaryColor: PropTypes.string.isRequired,
    }),
    message: PropTypes.shape({
      attributes: PropTypes.shape({
        approvalStatus: PropTypes.oneOf([
          'approved',
          'manually_approved',
          'rejected',
          'pending',
        ]).isRequired,
        statusText: PropTypes.string,
        authorizedBy: PropTypes.string,
        authorizedAt: PropTypes.string,
        deleterName: PropTypes.string,
        deletedAt: PropTypes.string,
        status: PropTypes.oneOf(['created', 'updated', 'deleted']).isRequired,
        sentAt: PropTypes.string.isRequired,
        readStatus: PropTypes.bool,
        type: PropTypes.oneOf(['sent', 'received']),
        user: PropTypes.shape({
          attributes: PropTypes.shape({
            name: PropTypes.string,
          }),
        }),
      }),
    }),

    showApprovalInfo: PropTypes.bool.isRequired,
  };

  readStyle = {
    color: this.props.appContext.primaryColor,
  };

  separator = '•';

  approvalStatusTranslation = {
    pending: 'Aguardando aprovação',
    manually_approved: 'Aprovada',
    rejected: 'Não aprovada',
  };

  readStatusTranslation = {
    false: 'Enviada',
    true: 'Lida',
  };

  tooltipStyle = {
    wordBreak: 'break-word',
  };

  @autobind
  renderApprovedInfo() {
    const { message, showApprovalInfo } = this.props;

    const { approvalStatus, statusText, sentAt, readStatus, type, status } =
      message.attributes;

    let infos = [sentAt];

    if (status === 'updated' && statusText) {
      infos.push(<span>{statusText}</span>);
    }

    if (
      approvalStatus === 'manually_approved' &&
      showApprovalInfo &&
      status !== 'updated'
    ) {
      infos.push(
        <Tooltip content={statusText}>
          <span>{this.approvalStatusTranslation[approvalStatus]}</span>
        </Tooltip>
      );
    }

    if (
      type === 'sent' &&
      readStatus !== undefined &&
      (approvalStatus === 'manually_approved' || approvalStatus === 'approved')
    ) {
      infos.push(<span>{this.readStatusTranslation[readStatus]}</span>);
    }

    if (type === 'sent' && readStatus !== undefined) {
      infos.push(this.renderInfoIcon());
    }

    return this.joinInformations(infos);
  }

  @autobind
  renderPendingOrRejectedInfo() {
    const { statusText } = this.props.message.attributes;
    return statusText;
  }

  @autobind
  renderDeletedInfo() {
    const { statusText, sentAt } = this.props.message.attributes;
    let infos = [sentAt];

    infos.push(
      <Tooltip content={statusText} tooltipStyle={this.tooltipStyle}>
        <span>Excluída</span>
      </Tooltip>
    );

    return this.joinInformations(infos);
  }

  renderSeparator() {
    return <span className="separator">{this.separator}</span>;
  }

  renderInfoIcon() {
    return (
      <span>
        <AgendaIcon
          style={
            this.props.message.attributes.readStatus ? this.readStyle : null
          }
          className="infoIcon"
          name="check-circle"
          size="x-small"
        />
      </span>
    );
  }

  joinInformations(infos) {
    return infos.reduce((acc, info) => {
      return acc === null ? [info] : [...acc, this.renderSeparator(), info];
    }, null);
  }

  render() {
    const {
      message: {
        attributes: { approvalStatus, status },
      },
    } = this.props;

    let renderMethod;

    if (status === 'deleted') {
      renderMethod = this.renderDeletedInfo;
    } else if (approvalStatus === 'pending' || approvalStatus === 'rejected') {
      renderMethod = this.renderPendingOrRejectedInfo;
    } else {
      renderMethod = this.renderApprovedInfo;
    }

    return <div className="MessagesInfo">{renderMethod()}</div>;
  }
}

export default withAppContext(MessagesInfo);
