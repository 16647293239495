import styled, { css } from 'styled-components';
import ModalContainer from 'components/Modal/ModalContainer';

export const CustomModalContainer = styled(ModalContainer)`
  .ReactModal__Content {
    .flex-row-modal-head {
      margin-bottom: 16px !important;
    }
  }
`;

export const TableWrapper = styled.div`
  ${() => css`
    .Loader {
      margin-top: 50px;
    }

    .TableContainer {
      min-height: 427px;

      .Table.table {
        thead {
          tr th:last-child {
            text-align: right;
          }
        }
        tbody {
          b {
            color: #333333;
            font-weight: 430;
          }

          tr td:last-child {
            min-width: 220px;
            text-align: right;
          }
        }
      }

      .customMetadata {
        margin-left: auto;
      }
    }
  `}
`;

export const Title = styled.h3`
  font-family: 'Quicksand', sans-serif;
  font-size: 20px;
  font-weight: 700;
  color: #333333;
  margin: 0;
`;

export const Subtitle = styled.h6`
  display: flex;
  align-items: center;
  font-family: 'Quicksand', sans-serif;
  font-size: 12px;
  font-weight: 500;
  color: #333333;
  margin: 0;
`;
