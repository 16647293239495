import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import PageTitle from 'components/PageTitle';
import PageSubTitle from 'components/PageSubTitle';
import Button from 'components/Button';

import withFormContext, { formPropTypes } from 'core/hoc/withFormContext';

import * as S from './styles';

const PageContainer = ({
  title,
  subtitle,
  children,
  footer,
  breadcrumb,
  header,
  className,
  variation,
  formContext,
  id,
  wallet,
  canCreateWalletButton = false,
}) => {
  const footerAccessor = () => {
    const { form } = formContext;
    return typeof footer === 'function' ? footer(form) : footer;
  };

  return (
    <S.MainContainer
      className={`PageContainer ${className} ${variation}`}
      id={id}
    >
      {breadcrumb}
      <S.HeaderContainer className="container-header">
        <S.TitlesContainer
          className="container-title"
          canCreateWalletButton={canCreateWalletButton}
        >
          <div>
            <PageTitle>{title}</PageTitle>
            <PageSubTitle>{subtitle}</PageSubTitle>
          </div>

          {canCreateWalletButton && (
            <Link to="/schools/wallets/new">
              <Button className="NewWalletButton">Nova carteira</Button>
            </Link>
          )}
        </S.TitlesContainer>
        {header}
      </S.HeaderContainer>
      <div className="container-wallet">{wallet}</div>

      <S.ContentContainer className="container-content">
        <S.MainContent className="main-content">{children}</S.MainContent>
        {footer && (
          <S.FooterContainer className="container-footer">
            {footerAccessor()}
          </S.FooterContainer>
        )}
      </S.ContentContainer>
    </S.MainContainer>
  );
};

PageContainer.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  variation: PropTypes.oneOf(['default', 'centered']),
  className: PropTypes.string,
  header: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  ...formPropTypes,
  wallet: PropTypes.oneOfType([PropTypes.element]),
  footer: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  breadcrumb: PropTypes.node,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.array]),
  id: PropTypes.string,
};

PageContainer.defaultProps = {
  variation: 'default',
  className: '',
  title: '',
  children: [],
};

export default withFormContext(PageContainer);
