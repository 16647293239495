import { Text } from '@agendaedu/ae-web-components';
import { ThemeProps } from 'config/themes/types';
import styled, { css } from 'styled-components';
import { StyleProps } from './types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const selectWrapperStyles = (colors: any) => css`
  background-color: ${colors.brand.primary.op10};
  border-color: ${colors.brand.primary.op45};
  transition: background-color 0.3s ease, transform 0.3s ease;

  svg {
    path {
      fill: ${colors.brand.primary.op45};
    }
  }

  ${AcceptedFormatsLabel} {
    color: ${colors.brand.primary.op45};
  }

  ${OptionsLabel} {
    color: ${colors.brand.primary.op45};
  }

  ${DragDropTitle} {
    color: ${colors.brand.primary.op45};
  }
`;

export const ImageDropWrapper = styled.div<StyleProps>`
  ${({ theme: { colors, space, border }, inDraging, error }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    border: ${border.width.md} ${border.style.solid} ${colors.neutral.gray4};
    background-color: ${colors.neutral.gray6};
    border-radius: ${border.radius.md};
    width: 336px;
    padding: ${space.lg} 0;
    margin-top: ${space.sm};

    svg {
      width: 48px;
      height: 48px;
      path {
        fill: ${colors.neutral.gray2};
      }
    }

    :hover {
      ${selectWrapperStyles(colors)}
    }

    ${inDraging &&
    css`
      ${selectWrapperStyles(colors)}
    `}

    ${error &&
    css`
      border: ${border.width.md} ${border.style.solid}
        ${colors.context.alert.default};
    `}
  `}
`;

export const ImageCropperWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 336px;
  height: 350px;
`;

export const HiddenInput = styled.input`
  display: none;
`;

export const DragDropTitle = styled(Text)`
  ${({ theme: { colors } }: ThemeProps) => css`
    font-weight: 700;
    margin-bottom: 0;
    margin-top: 16px;
    color: ${colors.neutral.gray2};
  `}
`;

export const OptionsLabel = styled(Text)`
  ${({ theme: { colors, space } }: ThemeProps) => css`
    margin: ${space.lg};
    color: ${colors.neutral.gray2};
  `}
`;

export const AcceptedFormatsLabel = styled(Text)`
  ${({ theme: { colors, space } }: ThemeProps) => css`
    margin-bottom: 0;
    margin-top: ${space.xl};
    color: ${colors.neutral.gray3};
  `}
`;

export const FieldErrorMessage = styled.p`
  ${({ theme: { typography, space, colors } }) => css`
    display: inline-block;
    margin-top: ${space.xs};
    margin-bottom: 0;
    color: ${colors.context.alert.default};
    ${typography.body.Regular14}
  `}
`;
