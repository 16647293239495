import validatePresence from 'core/lib/FormValidator/validators/presence';
import validateNumericalFloat from 'core/lib/FormValidator/validators/numericalFloat';

export default [
  {
    attributeName: 'description',
    validator: validatePresence,
    message: 'Campo obrigatório',
  },
  {
    attributeName: 'amount',
    validator: validateNumericalFloat({ min: 0.001 }),
    message: 'Insira um valor',
  },
];
