import styled from 'styled-components';

const defaultMargin = '16px';

export const Container = styled.div`
  border: 1px solid ${(props) => props.theme.gray12};
  border-radius: 6px;
`;

export const Divider = styled.hr`
  margin-top: ${defaultMargin};
  margin-bottom: ${defaultMargin};
  border-top: 1px solid ${(props) => props.theme.gray12};
`;

export const InfoContainer = styled.div`
  margin: ${defaultMargin};
`;

export const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
`;

export const InfoText = styled.p`
  font-family: Quicksand;
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: left;
  color: ${(props) => props.theme.black};
`;

export const SelectTitle = styled.p`
  font-family: Quicksand;
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: ${(props) => props.theme.black};
  margin: 0px;
  span {
    font-size: 12px;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: left;
  }
`;

export const TableContainer = styled.div`
  margin: ${defaultMargin};
`;
