import { ThemeProps } from 'config/themes/types';
import styled, { css } from 'styled-components';

import { Modal } from '@agendaedu/ae-web-components';

export const ContentWrapper = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    flex-direction: column;
    gap: ${space.xl};
    margin-top: ${space.md};
  `}
`;

export const FooterWrapper = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: ${space.sm};
  `}
`;
