import React from 'react';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

import { Box, Text, Grid, Icon } from '@agendaedu/ae-web-components';

import CardBalance from 'components/Payments/SchoolTransfersTab/CardBalance';
import SideSheet from 'components/SideSheet';
import StudentDisplay from 'components/StudentDisplay';
import Loader from 'components/Loader';
import Tooltip, { LEFT_CENTER } from 'components/Tooltip';

import { ExtractDetailsModalProps } from './types';

import * as S from './styles';

const ExtractDetailsModal = ({
  isOpen,
  toggleModal,
  modalLoading,
  payment,
  selectedFilter,
}: ExtractDetailsModalProps) => {
  const { t } = useTranslation(['payments', 'common']);

  const isRefund = payment?.attributes?.status === 'refunded';

  const isPaidCreditCard = ['credit_card', 'credit_card_parcel'].includes(
    payment?.attributes?.payment_method
  );

  const renderBillTitle = (payment) => {
    return payment?.attributes?.orderable_type === 'RecurrentBill'
      ? payment?.attributes?.recurrent_bill.data.attributes?.title
      : payment?.attributes?.school_product.data.attributes?.title;
  };

  const renderPaymentMethods = (payment) => {
    const paymentMethods = {
      billet: 'Boleto',
      credit_card: 'Cartão de crédito',
      credit_card_parcel: 'Cartão de crédito',
      pix: 'PIX',
    };
    return paymentMethods[payment.attributes.payment_method];
  };

  const renderQuantityInstallments = (payment) => {
    const { orderable_type: type } = payment?.attributes;

    if (type === 'RecurrentBill') {
      const { bill_number, bills_quantity } =
        payment?.attributes?.recurrent_bill.data.attributes;

      return (
        <React.Fragment>{`${bill_number}/${bills_quantity}`}</React.Fragment>
      );
    }

    if (payment?.attributes?.school_product) {
      return (
        <React.Fragment>
          {`${payment?.attributes?.installment}/${payment?.attributes?.school_product?.data?.attributes?.max_installments}`}
        </React.Fragment>
      );
    }

    return <React.Fragment>À vista</React.Fragment>;
  };

  const renderPaymentInstallment = (payment) => {
    const { provider } = payment?.attributes;

    if (provider === 'bemobi') return '---';

    const paymentInstallment = {
      billet: renderQuantityInstallments(payment),
      credit_card: renderQuantityInstallments(payment),
      credit_card_parcel: renderQuantityInstallments(payment),
      pix: renderQuantityInstallments(payment),
    };

    return paymentInstallment[payment?.attributes?.payment_method];
  };

  return (
    <S.Container>
      <SideSheet
        isOpen={isOpen}
        onDismiss={toggleModal}
        title={t('wallet.payment_details_extract.modal_title')}
      >
        <S.Divider />

        <Loader isLoading={modalLoading}>
          {payment && (
            <>
              <S.StudentWrapper>
                <StudentDisplay
                  user={payment?.attributes?.student_profile.data}
                  withClassrooms
                  size="x-large"
                />
                <Box mb="lg" mt="lg">
                  <Text variant="body-regular-14" color="neutral.gray1" mb="sm">
                    {t(
                      'wallet.payment_details_extract.labels.responsible_name'
                    )}
                  </Text>
                  <Text variant="body-regular-16" color="neutral.black">
                    {
                      payment?.attributes?.responsible_profile?.data?.attributes
                        ?.name
                    }
                  </Text>
                </Box>
              </S.StudentWrapper>

              <S.Divider />

              <S.InformationsWrapper gutter="lg">
                <Grid item>
                  <CardBalance
                    title={t(
                      'wallet.payment_details_extract.cards_balance.amout_charged.label'
                    )}
                    value={payment?.attributes?.charge_amount_cents}
                    tooltipMessage={t(
                      'wallet.payment_details_extract.cards_balance.amout_charged.tooltip'
                    )}
                    variation={!isRefund && 'receivable'}
                  ></CardBalance>
                </Grid>
                {!isRefund && (
                  <>
                    <Grid item>
                      <CardBalance
                        title={t(
                          'wallet.payment_details_extract.cards_balance.discount.label'
                        )}
                        value={payment?.attributes?.discount_value}
                        tooltipMessage={t(
                          'wallet.payment_details_extract.cards_balance.discount.tooltip'
                        )}
                      ></CardBalance>
                    </Grid>
                    <Grid item>
                      <CardBalance
                        title={t(
                          'wallet.payment_details_extract.cards_balance.rate.label'
                        )}
                        value={payment?.attributes?.tax}
                        tooltipMessage={t(
                          'wallet.payment_details_extract.cards_balance.rate.tooltip'
                        )}
                      ></CardBalance>
                    </Grid>
                  </>
                )}
                <Grid item>
                  <CardBalance
                    title={t(
                      `wallet.payment_details_extract.cards_balance.${
                        isRefund
                          ? 'refund'
                          : selectedFilter === 'receivable'
                          ? 'amount_to_received'
                          : 'amount_received'
                      }.label`
                    )}
                    value={payment?.attributes?.amount_cents}
                    tooltipMessage={t(
                      `wallet.payment_details_extract.cards_balance.${
                        isRefund
                          ? 'refund'
                          : selectedFilter === 'receivable'
                          ? 'amount_to_received'
                          : 'amount_received'
                      }.tooltip`
                    )}
                    variation={isRefund ? 'refund' : selectedFilter}
                  ></CardBalance>
                </Grid>
              </S.InformationsWrapper>

              <S.Divider />

              <S.InformationsWrapper gutter="lg" isOneColumn={isPaidCreditCard}>
                <S.Information item gutter="md" isOneColumn={isPaidCreditCard}>
                  <Text variant="body-regular-14" color="neutral.gray1" mb={0}>
                    {t('wallet.payment_details_extract.labels.bill_name')}
                  </Text>
                  <Text
                    variant="body-regular-16"
                    color="neutral.black"
                    mb={0}
                    lineHeight="lg"
                  >
                    {renderBillTitle(payment)}
                  </Text>
                </S.Information>

                {!isPaidCreditCard && (
                  <S.Information item gutter="md">
                    <Text
                      variant="body-regular-14"
                      color="neutral.gray1"
                      mb={0}
                    >
                      {t('wallet.payment_details_extract.labels.parcel')}
                    </Text>
                    <Text
                      variant="body-regular-16"
                      color="neutral.black"
                      mb={0}
                    >
                      {renderPaymentInstallment(payment)}
                    </Text>
                  </S.Information>
                )}
              </S.InformationsWrapper>

              <S.InformationsWrapper gutter="lg">
                <S.Information item gutter="md">
                  <Text variant="body-regular-14" color="neutral.gray1" mb={0}>
                    {t('wallet.payment_details_extract.labels.entry_date')}
                  </Text>
                  <Text variant="body-regular-16" color="neutral.black" mb={0}>
                    {payment?.attributes?.date}
                  </Text>
                </S.Information>

                <S.Information item gutter="md">
                  <Box display="flex" alignItems="center">
                    <Text
                      variant="body-regular-14"
                      color="neutral.gray1"
                      mb={0}
                      flex={payment?.attributes?.receiving_by ? 0.6 : 1}
                    >
                      {t(
                        'wallet.payment_details_extract.labels.payment_method'
                      )}
                    </Text>
                    {payment?.attributes?.receiving_by && (
                      <Tooltip
                        content={parse(
                          t(
                            'wallet.payment_details_extract.labels.payment_method_tooltip'
                          )
                        )}
                        position={LEFT_CENTER}
                      >
                        <Icon
                          name="help-circle"
                          size="sm"
                          color={'neutral.gray1'}
                        />
                      </Tooltip>
                    )}
                  </Box>

                  <Text variant="body-regular-16" color="neutral.black" mb={0}>
                    {renderPaymentMethods(payment)}
                  </Text>
                </S.Information>
              </S.InformationsWrapper>

              <S.Divider />

              <S.InformationsWrapper gutter="lg" isOneColumn>
                <S.Information item gutter="md" isOneColumn>
                  <Text variant="body-regular-14" color="neutral.gray1" mb={0}>
                    {t('wallet.payment_details_extract.labels.transaction_id')}
                  </Text>
                  <Text
                    variant="body-regular-16"
                    color="neutral.black"
                    mb={0}
                    fontWeight={600}
                  >
                    {payment?.attributes?.transaction_id}
                  </Text>
                </S.Information>

                <S.Information item gutter="md" isOneColumn>
                  <Text variant="body-regular-14" color="neutral.gray1" mb={0}>
                    {t('wallet.payment_details_extract.labels.provider')}
                  </Text>
                  <Text variant="body-regular-16" color="neutral.black" mb={0}>
                    {payment?.attributes?.provider}
                  </Text>
                </S.Information>
              </S.InformationsWrapper>
            </>
          )}
        </Loader>
      </SideSheet>
    </S.Container>
  );
};

export default ExtractDetailsModal;
