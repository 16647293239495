import React, { useCallback, useRef, useState } from 'react';
import Linkify from 'react-linkify';
import { Box, Icon, Text } from '@agendaedu/ae-web-components';

import useOutsideClick from 'core/hooks/useOutsideClick';
import formatBytes from 'core/utils/formatBytes';
import { getFileType } from 'core/utils/getFileType';

import ActionButton from 'components/Messages/TS/Animations/ActionButton';

import * as S from './styles';

import { Props } from './types';

export const MessageBalloon: React.FC<Props> = ({
  actions,
  attachment,
  attached,
  status,
  balloonLeft,
  balloonHeader,
  balloonFooter,
  content,
  variant,
  testId,
}): React.ReactElement => {
  const balloonRef = useRef();

  const [showAction, setShowActions] = useState<boolean>(false);

  const toggleShowActions = useCallback((): void => {
    setShowActions((prev) => !prev);
  }, []);

  useOutsideClick(balloonRef, () => setShowActions(false));

  const hasAction = !!actions?.length;

  const renderAttachment = useCallback(() => {
    if (!attachment?.url) return;

    return (
      <S.AttachmentWrapper
        href={attachment.url}
        data-testid="attachment-wrapper"
        target="_blank"
      >
        {getFileType(attachment) !== 'image' ? (
          <>
            <Icon name="file-generic" data-testid="file-generic-icon" />
            <Text mb={0} variant="body-regular-12">
              {attachment.name}
            </Text>
            <Text mb={0} variant="body-medium-12">
              {formatBytes(attachment.size)}
            </Text>
          </>
        ) : (
          <S.AttachmentImage
            data-testid="attachment-image"
            src={attachment.url}
          />
        )}
      </S.AttachmentWrapper>
    );
  }, [attachment]);

  const contentVariant = {
    deleted: (
      <S.DeletedMessageWrapper data-testid="deleted-wrapper">
        <Icon name="forbidden" data-testid="forbidden-icon" />
        {content}
      </S.DeletedMessageWrapper>
    ),

    default: (
      <Linkify properties={{ target: '_blank' }}>
        <Box display="flex" flexDirection="column" gap="xs2">
          {attached?.content && attached?.title && (
            <S.AttachedWrapper data-testid="attached-wrapper">
              <Text variant="title-bold-12" fontWeight={700}>
                {attached.title}
              </Text>
              <Text variant="body-regular-14">{attached.content}</Text>
            </S.AttachedWrapper>
          )}
          {content}
          {renderAttachment()}
        </Box>
      </Linkify>
    ),
  };

  return (
    <S.Wrapper
      data-testid={testId}
      status={status}
      showAction={showAction}
      hasAction={hasAction}
    >
      <S.BalloonLeftWrapper data-testid="balloon-left">
        {balloonLeft}
      </S.BalloonLeftWrapper>

      <S.MessageContainer
        data-testid="message-container"
        ref={balloonRef}
        onClick={toggleShowActions}
      >
        <S.ContentWrapper>
          {balloonHeader && (
            <S.Content data-testid="balloon-header" variant="title-bold-12">
              {balloonHeader}
            </S.Content>
          )}
          <S.MessageContent>{contentVariant[variant]}</S.MessageContent>
        </S.ContentWrapper>

        {hasAction && (
          <S.ActionsWrapper>
            <S.ActionContainer>
              {actions.map((action, index) => (
                <ActionButton
                  testId={action.testId}
                  key={index}
                  name={action.iconName}
                  handleOnClick={action.onClick}
                />
              ))}
            </S.ActionContainer>
          </S.ActionsWrapper>
        )}
      </S.MessageContainer>

      {balloonFooter && (
        <S.BalloonFooterWrapper data-testid="balloon-footer">
          {balloonFooter}
        </S.BalloonFooterWrapper>
      )}
    </S.Wrapper>
  );
};
