import React, { useState } from 'react';
import PropTypes from 'prop-types';

import AgendaIcon from 'components/AgendaIcon';
import FormValidationErrors from 'components/Form/ValidationErrors';
import withFormContext, { formPropTypes } from 'core/hoc/withFormContext';
import TimePicker from 'components/TimePicker';

import './style.scss';

const TimeField = ({
  attributeName,
  disabled,
  formContext,
  placeholder,
  onValueChange,
  defaultValue,
}) => {
  const [focused, setFocused] = useState(false);

  const { form } = formContext;

  const onChange = (time) => {
    const { updateAttribute } = formContext;

    updateAttribute(attributeName, time);
  };

  const onFocusChange = (focus) => setFocused(focus);

  const hasError = () => {
    const { hasErrorOnAttribute } = formContext;

    return hasErrorOnAttribute(attributeName);
  };

  return (
    <div className={`TimeField ${hasError() ? 'has-error' : ''}`}>
      <div className={`input-group ${focused ? 'focused' : ''}`}>
        <AgendaIcon name="clock" size="medium" className="input-group-addon" />

        <TimePicker
          time={defaultValue || form[attributeName]}
          onChange={onValueChange ? onValueChange : onChange}
          onFocusChange={onFocusChange}
          disabled={disabled}
          placeholder={placeholder}
        />
      </div>

      {hasError() && <FormValidationErrors attributeName={attributeName} />}
    </div>
  );
};

TimeField.defaultProps = {
  disabled: false,
  placeholder: 'Selecionar horário',
};

TimeField.propTypes = {
  attributeName: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  onValueChange: PropTypes.func,
  defaultValue: PropTypes.string,
  ...formPropTypes,
};

export default withFormContext(TimeField);
