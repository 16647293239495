import React, { useState, useEffect } from 'react';
import { WalletListProps } from './types';

import Label from 'components/Form/Label';
import PageTitle from 'components/PageTitle';
import { walletStatus } from 'core/constants/index';

import * as S from './styles';

const WalletList = ({
  defaultWalletId,
  wallets,
  handleCurrentWallet,
}: WalletListProps) => {
  const [currentWalletId, setCurrentWalletId] = useState(defaultWalletId);

  useEffect(() => {
    handleCurrentWallet(currentWalletId);
  }, [currentWalletId, handleCurrentWallet]);

  return (
    <S.WalletListContainer>
      {wallets.map((wallet) => {
        return (
          <S.WalletCard
            variation="white"
            key={wallet.id}
            isActive={currentWalletId === wallet.attributes.recipient_wallet_id}
            onClick={() =>
              setCurrentWalletId(wallet.attributes.recipient_wallet_id)
            }
          >
            <S.WalletCardDetails>
              <PageTitle>
                {wallet?.type == 'securitizer'
                  ? wallet.attributes.school_name
                  : wallet.attributes.document_number}
              </PageTitle>
              <Label>{wallet.attributes.wallet_name}</Label>
              {wallet?.type != 'securitizer' && (
                <Label>{wallet.attributes.school_name}</Label>
              )}

              {!!wallet.attributes.status &&
                wallet.attributes.status !== 'active' && (
                  <S.WalletStatus>
                    <S.WalletStatusText>
                      {walletStatus[wallet.attributes.status].text}
                    </S.WalletStatusText>
                  </S.WalletStatus>
                )}
            </S.WalletCardDetails>

            <S.SelectButton>
              {currentWalletId === wallet.attributes.recipient_wallet_id && (
                <S.IconButton />
              )}
            </S.SelectButton>
          </S.WalletCard>
        );
      })}
    </S.WalletListContainer>
  );
};

export default WalletList;
