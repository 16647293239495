import React from 'react';
import PropTypes from 'prop-types';
// The original package is 'react-intl-currency-input'. I published this fork
// because there is a fix that wasn't published in the original package.
// Check the last update in the original gem. If it is after 2018-06-15, you
// you should change the package.
import IntlCurrencyInput from 'react-intl-currency-input-fork';
import autobind from 'autobind-decorator';

import FormValidationErrors from 'components/Form/ValidationErrors';

import withFormContext, { formPropTypes } from 'core/hoc/withFormContext';
import withOnChangeValidations from 'core/hoc/withOnChangeValidations';

import composeFunctions from 'core/utils/composeFunctions';

const maxValue = 99999999.99;

const CURRENCY_CONFIG = {
  locale: 'pt-BR',
  formats: {
    number: {
      BRL: {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
};

class CurrencyField extends React.Component {
  @autobind
  onChange(_event, value, _maskedValue) {
    const { onChange, attributeName, formContext } = this.props;
    const { changeAttribute } = formContext;

    const fakeEvent = {
      target: {
        value: value,
      },
    };

    if (onChange) {
      return changeAttribute(attributeName, onChange)(fakeEvent);
    } else {
      return changeAttribute(attributeName)(fakeEvent);
    }
  }

  getValue() {
    const { defaultValue, formContext, controlled, attributeName } = this.props;

    if (controlled) {
      return defaultValue;
    }

    return formContext.form[attributeName];
  }

  render() {
    const { onBlur, attributeName, disabled, formContext } = this.props;
    const { form, hasErrorOnAttribute } = formContext;
    const hasError = hasErrorOnAttribute(attributeName);

    return (
      <div className="CurrencyField">
        <div className="input-group">
          <div className="input-group-addon">R$</div>
          <IntlCurrencyInput
            type="tel"
            className="form-control"
            onChange={this.onChange}
            onBlur={onBlur}
            defaultValue={this.getValue()}
            currency="BRL"
            config={CURRENCY_CONFIG}
            disabled={disabled}
            max={maxValue}
          />
        </div>
        {hasError && <FormValidationErrors attributeName={attributeName} />}
      </div>
    );
  }
}

CurrencyField.propTypes = {
  attributeName: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  ...formPropTypes,
  defaultValue: PropTypes.number,
};

CurrencyField.defaultProps = {
  controlled: false,
};

export default composeFunctions(
  withOnChangeValidations,
  withFormContext
)(CurrencyField);
