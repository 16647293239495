import React from 'react';
import * as S from './styles';

import { ModalRootProps } from './types';

export const ModalRoot = ({
  dataTestId,
  children,
  isOpen,
  maxWidth = '490px',
  title,
  handleToggleModal,
  overlapModal = true,
  marginAuto = false,
}: ModalRootProps) => {
  return (
    <>
      {isOpen && (
        <S.ModalWrapper data-testid={dataTestId}>
          <S.ModalHeader
            overlap={overlapModal}
            isOpen={isOpen}
            toggleModal={handleToggleModal}
            maxWidth={maxWidth}
            title={title}
            marginAuto={marginAuto}
          >
            <S.Wrapper>{children}</S.Wrapper>
          </S.ModalHeader>
        </S.ModalWrapper>
      )}
    </>
  );
};
