import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Button';
import { Link } from 'react-router-dom';
import AgendaIcon from 'components/AgendaIcon';
import { trackEvent } from 'config/amplitude';

import * as S from './styles';

// TODO add variations of position

function DropdownButton({
  id,
  dropdownItems,
  trackEventName,
  text,
  variation,
  disabled,
  icon,
  iconSize,
  direction,
  align,
}) {
  return (
    <S.DropdownButton className="DropdownButton dropdown show">
      <Button
        className={`dropdown-toggle ${text ? 'text' : ''}`}
        id={id}
        variation={variation}
        disabled={disabled ? disabled : !dropdownItems.length}
      >
        <span>{text}</span>
        <AgendaIcon name={icon} size={iconSize} />
      </Button>
      <ul className={`dropdown-menu ${direction}`} aria-labelledby={id}>
        {dropdownItems.map((item, index) => {
          return item.path ? (
            <li key={`item${index}`}>
              <Link
                className={`dropdown-item ${align}`}
                to={item.path}
                key={`dropdown_item_${index}`}
                target={item.target || '_blank'}
                onClick={() => {
                  if (trackEventName) {
                    trackEvent(trackEventName);
                  }
                }}
              >
                {item.text}
              </Link>
            </li>
          ) : (
            <li key={`item${index}`} onClick={item.onClick}>
              <a
                className={`dropdown-item ${align}`}
                key={`dropdown_item_${index}`}
              >
                {item.text}
              </a>
            </li>
          );
        })}
      </ul>
    </S.DropdownButton>
  );
}

DropdownButton.propTypes = {
  id: PropTypes.string,
  dropdownItems: PropTypes.array.isRequired,
  trackEventName: PropTypes.string,
  text: PropTypes.string.isRequired,
  variation: PropTypes.string,
  icon: PropTypes.string,
  iconSize: PropTypes.string,
  direction: PropTypes.string,
  align: PropTypes.string,
  disabled: PropTypes.bool,
};

DropdownButton.defaultProps = {
  icon: 'arrow-down',
  iconSize: 'small',
  direction: 'down',
  align: 'left',
};

export default DropdownButton;
