import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import autobind from 'autobind-decorator';

import PageContainer from 'components/PageContainer';
import PageSubTitle from 'components/PageSubTitle';
import Breadcrumb from 'components/Breadcrumb';
import Button from 'components/Button';
import Loader from 'components/Loader';

import FormContainer from 'components/Form/FormContainer';
import FormContext from 'core/contexts/Form';

import TempPasswordService from 'core/services/TempPassword';
import withAppContext from 'core/hoc/withAppContext';
import withToastMessage from 'core/hoc/withToastMessage';

import './style.scss';
import TempPasswordForm from 'components/TempPassword/TempPasswordForm';
import ListPasswords from 'components/TempPassword/ListPasswords';

class TempPassword extends Component {
  static propTypes = {
    appContext: PropTypes.shape({
      dataArea: PropTypes.string.isRequired,
    }).isRequired,
    toastActions: PropTypes.shape({
      showToast: PropTypes.func.isRequired,
    }).isRequired,
    formContext: PropTypes.shape({
      formMeta: PropTypes.object,
      updateAttribute: PropTypes.func.isRequired,
    }),
    userType: PropTypes.string.isRequired,
  };

  state = {
    users: [],
    generatedUsers: [],
    isSubmitting: false,
    successSubmit: false,
    selectClassroomFrom: null,
  };

  @autobind
  async loadUsers(select) {
    const {
      appContext: { dataArea },
      userType,
    } = this.props;
    const tempPasswordService = new TempPasswordService(dataArea);
    const classroom_from = select;

    try {
      if (classroom_from.length) {
        const users = await tempPasswordService.getUsers(
          classroom_from,
          userType
        );
        this.setState({ users });
      } else {
        this.setState({ users: [] });
      }
    } catch (errorFetchingUsers) {
      this.setState({ errorFetchingUsers });
    } finally {
      this.setState({ isLoadingUsers: false });
    }
  }

  @autobind
  async onSubmit(formState, updateAttribute) {
    const {
      appContext: { dataArea },
    } = this.props;

    const tempPasswordService = new TempPasswordService(dataArea);

    this.setState({ isSubmitting: true });
    try {
      const generatedUsers = await tempPasswordService.tempPasswordBatch(
        formState
      );
      updateAttribute('classroom_from', '');
      updateAttribute('user_ids', []);
      this.setState({
        users: [],
        selectClassroomFrom: null,
        successSubmit: true,
        generatedUsers,
      });
    } catch (error) {
      this.setState({ error, isSubmitting: false });
    } finally {
      this.setState({ isSubmitting: false });
    }
  }

  tempPasswordReport = async () => {
    const {
      appContext: { dataArea },
    } = this.props;

    const { generatedUsers } = this.state;

    const tempPasswordService = new TempPasswordService(dataArea);
    this.setState({ isSubmitting: true });
    try {
      const pdf = await tempPasswordService.tempPasswordReport(generatedUsers);
      const file = new Blob([pdf], {
        type: 'application/pdf',
        filename: 'senhas_temporarias.pdf',
      });
      // Build a URL from the file
      const fileURL = URL.createObjectURL(file);
      // Open the URL on new Window
      window.open(fileURL);
    } catch (error) {
      this.setState({ error, isSubmitting: false });
    } finally {
      this.setState({ isSubmitting: false });
    }
  };

  @autobind
  onClassroomSelectFrom(classroom) {
    this.setState({ selectClassroomFrom: classroom });
  }

  @autobind
  footer(formState, updateAttribute) {
    const { isSubmitting, successSubmit } = this.state;
    return (
      <Fragment>
        {successSubmit ? (
          <Button onClick={this.tempPasswordReport} disabled={isSubmitting}>
            <i className="fa fa-print " />
            {isSubmitting ? 'Gerando PDF...' : 'Imprimir lista'}
          </Button>
        ) : (
          <Button
            onClick={() => this.onSubmit(formState, updateAttribute)}
            disabled={!(formState.user_ids.length && !isSubmitting)}
          >
            Gerar senhas
          </Button>
        )}
      </Fragment>
    );
  }

  pageInfo() {
    const { userType } = this.props;
    const user = userType === 'student_profile' ? 'alunos' : 'responsáveis';
    return (
      <React.Fragment>
        <PageSubTitle>Selecione os {user}</PageSubTitle>
        <span className="info">
          Você pode gerar uma senha temporária para os{' '}
          <strong> {user} que não possuem e-mail cadastrados. </strong>
          Com isso, eles poderão acessar utilizando o nome de usuário e a senha
          temporária gerada.
        </span>
      </React.Fragment>
    );
  }

  generatedPasswordsInfo() {
    return (
      <React.Fragment>
        <PageSubTitle>Senhas temporárias geradas</PageSubTitle>
        <span className="info">
          Por questão de segurança, essas senhas não podem ser guardadas na
          plataforma. Imprima e repasse os dados para os alunos. Caso perca
          essas senhas, será necessário gerar novamente.
        </span>
      </React.Fragment>
    );
  }

  render() {
    const {
      users,
      generatedUsers,
      selectClassroomFrom,
      isSubmitting,
      successSubmit,
    } = this.state;

    const { userType } = this.props;

    const breadcrumb = {
      responsible_profile: {
        title: 'Responsáveis',
        path: '/schools/responsible_profiles',
      },
      student_profile: {
        title: 'Alunos',
        path: '/schools/student_profiles',
      },
    };

    return (
      <div className="TempPassword">
        <FormContainer
          action="new"
          form={{ user_ids: [], user_type: userType }}
        >
          <FormContext.Consumer>
            {({ form, updateAttribute }) => (
              <PageContainer
                title="Definir senhas temporárias"
                breadcrumb={
                  <Breadcrumb
                    title={breadcrumb[userType].title}
                    path={breadcrumb[userType].path}
                    external
                  />
                }
                footer={this.footer(form, updateAttribute)}
                variation="centered"
              >
                {successSubmit ? (
                  <React.Fragment>
                    {this.generatedPasswordsInfo()}
                    <ListPasswords generatedUsers={generatedUsers} />
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Loader isLoading={isSubmitting}>
                      {this.pageInfo()}
                      <TempPasswordForm
                        users={users}
                        userType={userType}
                        selectClassroomFrom={selectClassroomFrom}
                        loadUsers={this.loadUsers}
                        onClassroomSelectFrom={this.onClassroomSelectFrom}
                      />
                    </Loader>
                  </React.Fragment>
                )}
              </PageContainer>
            )}
          </FormContext.Consumer>
        </FormContainer>
      </div>
    );
  }
}

export default withAppContext(withToastMessage(TempPassword));
