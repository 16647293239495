import styled, { css } from 'styled-components';

import { StyleProps } from './types';

export const GroupHeaderWrapper = styled.section<StyleProps>`
  ${({ theme: { border, colors, space }, isWebView }) => css`
    display: flex;
    flex-direction: column;
    gap: ${isWebView ? space.lg : space.xl};
    padding: ${space.lg};
    border-bottom: ${border.width.sm} ${border.style.solid}
      ${colors.neutral.gray4};
  `}
`;
