import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import Title from 'components/WizardForm/Title';
import PageSubTitle from 'components/PageSubTitle';
import Tooltip, { TOP_CENTER } from 'components/Tooltip';
import AgendaIcon from 'components/AgendaIcon';
import ColoredText from 'components/ColoredText';
import Button from 'components/Button';
import Currency from 'components/Currency';
import RecurrentProductForm from 'components/Payments/Recurrent/RecurrentProductForm';
import FormValidationErrors from 'components/Form/ValidationErrors';
import Alert from 'components/Alert';
import Toast from 'components/Toast';

import withWindowSize from 'core/hoc/withWindowSize';
import withFormContext, { formPropTypes } from 'core/hoc/withFormContext';
import RecurrentItem from './RecurrentItem';

// Actions
import actionRecurrentProducts from 'store/edupay/recurrentProducts/actions';

import './style.scss';

const newItem = () => ({
  price: 0,
  discount_kind: 'percent',
  discount_value: 0,
  recurrent_product_id: undefined,
  key: Math.random().toString(36).substring(7), // Had to add this unique key to prevent issues, we can think how to generate a random key based on the properties
});

const TooltipSubtotal = () => (
  <>
    O subtotal dos <br />
    produtos deve ser no
    <br /> mínimo R$ 10,00
  </>
);

const RecurrentItemsManager = ({
  windowSize,
  title,
  attributeName,
  formContext: {
    form,
    updateAttribute,
    hasErrorOnAttribute,
    loadOptionsToSelect,
    formMeta: { status },
  },
  type,
  action,
}) => {
  const dispatch = useDispatch();

  const { toggleModal } = actionRecurrentProducts;

  const recurrentItems = form[attributeName] || [].concat(newItem());
  const hasError = hasErrorOnAttribute(attributeName);

  const modalOpen = useSelector((state) => state.recurrentProducts.modalOpen);
  const recurrentProducts = useSelector(
    (state) => state.recurrentProducts.recurrentProducts
  );

  const disabledOnEdit = ['released', 'delayed'].includes(status);

  const recurrentProductIds = () =>
    recurrentProducts &&
    recurrentProducts.map((recurrentProduct) => ({
      label: recurrentProduct.attributes.name,
      value: recurrentProduct.id,
    }));

  useEffect(() => {
    loadOptionsToSelect('recurrent_product_id', recurrentProductIds());
  }, [recurrentProducts]);

  const recurrentItemValue = ({ discount_kind, discount_value, price }) => {
    if (discount_kind === 'percent') {
      return price - price * (discount_value / 100);
    }

    return price - discount_value;
  };

  const toogleModalProduct = useCallback(() => {
    dispatch(toggleModal());
  }, [dispatch, toggleModal]);

  const subTotalCalculator = () => {
    const reducer = (accumulator, currentValue) =>
      accumulator + recurrentItemValue(currentValue);
    return recurrentItems.reduce(reducer, 0);
  };

  const addNewItem = () => {
    const newItems = recurrentItems.concat(newItem());
    updateAttribute(attributeName, newItems);
  };

  const removeItem = (index) => {
    const newItems = [
      ...recurrentItems.slice(0, index),
      ...recurrentItems.slice(index + 1),
    ];
    updateAttribute(attributeName, newItems);
  };

  const onChange = (index, values) => {
    recurrentItems[index] = values;

    updateAttribute(attributeName, recurrentItems);
  };

  return (
    <div className="RecurrentItemsManager">
      <div className="items-manager-header">
        <div className="items-manager-text">
          <Title name={title} />
          <PageSubTitle>
            O subtotal dos produtos deve ser no mínimo R$ 10,00
          </PageSubTitle>
        </div>
        {!disabledOnEdit && (
          <ColoredText variation="primary w600" onClick={toogleModalProduct}>
            + Novo produto
          </ColoredText>
        )}
      </div>
      <div className="alert-content">
        {type === 'plan' && action === 'edit' && (
          <Alert variation="info">
            Ao editar esse campo, todas as cobranças não enviadas terão apenas
            os itens dessa edição.
          </Alert>
        )}
      </div>
      <div className="items-manager-content">
        <div className="item-list">
          <div className="item-list-header">
            {windowSize > 767 && (
              <>
                <span>Produto*</span>
                <span>Valor*</span>
              </>
            )}
          </div>
          <div className="item-list-content">
            {recurrentItems.map((recurrentItem, index) => (
              <RecurrentItem
                itemValues={recurrentItem}
                recurrentProducts={recurrentProductIds()}
                index={index}
                removeItem={removeItem}
                onChange={onChange}
                key={recurrentItem.key}
                disabled={disabledOnEdit}
              />
            ))}
          </div>
          {hasError && <FormValidationErrors attributeName={attributeName} />}
          <div className="items-list-summary">
            {!disabledOnEdit && (
              <Button variation="secondary" onClick={addNewItem}>
                Adicionar item
              </Button>
            )}
            <div className="subtotal">
              <div className="subtotal-info">
                <span>Subtotal</span>
                <Tooltip content={TooltipSubtotal} position={TOP_CENTER}>
                  <AgendaIcon
                    name="help"
                    size="small"
                    className="custom-size"
                  />
                </Tooltip>
              </div>
              <ColoredText variation="dark w600">
                <Currency value={subTotalCalculator()} />
              </ColoredText>
            </div>
          </div>
        </div>
      </div>
      <RecurrentProductForm
        modalOpen={modalOpen}
        toggleModal={toogleModalProduct}
      />
      <Toast />
    </div>
  );
};

RecurrentItemsManager.propTypes = {
  windowSize: PropTypes.number.isRequired,
  attributeName: PropTypes.string,
  title: PropTypes.string,
  ...formPropTypes,
};

RecurrentItemsManager.defaultProps = {
  attributeName: 'recurrentItems',
  title: 'Itens da cobrança',
};

export default withFormContext(withWindowSize(RecurrentItemsManager));
