import React, { useState } from 'react';

import AgendaIcon from 'components/AgendaIcon';
import Button from 'components/Button';
import SideSheetFilters from 'pages/panel/SideSheetFilters';

import * as S from './styles';

const PanelButtonAndSideSheet = ({ handleFilter, amount }) => {
  const [openSideSheet, setOpenSideSheet] = useState(false);

  const hasActiveFilters = amount > 0;

  const handleSideSheetToggle = () => setOpenSideSheet(!openSideSheet);

  return (
    <S.Wrapper isActive={hasActiveFilters}>
      <Button
        testId="filter-button"
        role="button"
        variation="secondary"
        onClick={handleSideSheetToggle}
      >
        <AgendaIcon name="filter" />
        {hasActiveFilters && <S.BadgeFilter>{amount}</S.BadgeFilter>}
      </Button>
      <SideSheetFilters
        isOpen={openSideSheet}
        onDismiss={handleSideSheetToggle}
        handleFilter={handleFilter}
      />
    </S.Wrapper>
  );
};

export default PanelButtonAndSideSheet;
