import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from 'react';
import * as S from './styles';
import FormValidationErrors from 'components/Form/ValidationErrors';
import tabifyForWizard from 'core/hoc/tabifyForWizard';
import FormFieldset from 'components/Form/Fieldset';
import Dropdown from 'components/Dropdown';
import PdfField from 'components/Form/PdfField';
import { Button, Select, Tag } from '@agendaedu/ae-web-components';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import withFormContext, { formPropTypes } from 'core/hoc/withFormContext';
import { normalizeFileSize } from 'core/utils/formatBytes';
import DirectUpload from 'core/services/Uploader/DirectUpload';
import '@react-pdf-viewer/page-navigation/lib/styles/index.css';
import '@react-pdf-viewer/core/lib/styles/index.css';
import actionEnrollmentPlans from 'store/edupay/enrollmentPlans/actions';

const ONE_MB_VALUE_IN_KB = 1024;
const LIMIT_SIZE_10_MB = 10 * Math.pow(ONE_MB_VALUE_IN_KB, 2);

const EnrollmentPlanFormContractTab = ({
  formContext: {
    hasErrorOnAttribute,
    changeMeta,
    updateAttribute,
    form,
    formMeta: { status, contractModels, select_options },
    action,
  },
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['enrollments', 'common']);
  const { fetchStudentsResponsiblesRequest } = actionEnrollmentPlans;
  const { fileUrl, nameFile, sizeFile, signedId, formType, contractModelId } =
    form;
  const { formTypes } = select_options;
  const invalidField = hasErrorOnAttribute('fileUrl');
  const inputRef = useRef(null);

  const [forceClose, setForceClose] = useState(false);
  const [dropdownDisabled, setDropdownDisabled] = useState(false);
  const [hasContractSizeError, setHasContractSizeError] = useState(false);

  const directUpload = new DirectUpload('schools');

  useEffect(() => {
    changeMeta('resetFormTabs', true);
  }, [formType]);

  useEffect(() => {
    if (!fileUrl) changeMeta('nextStepDisabled', true);
    if (fileUrl) setDropdownDisabled(true);
  }, [fileUrl]);

  useEffect(() => {
    if (formType === 'single') {
      changeMeta('nextStepDisabled', !fileUrl);
      if (!fileUrl) {
        inputRef.current.value = '';
      }
    }
    if (formType === 'mass') {
      changeMeta('nextStepDisabled', !contractModelId);
    }
  }, [fileUrl, formType, contractModelId, changeMeta]);

  const onToggle = (isMenuOpen) => {
    if (!isMenuOpen) {
      setForceClose(false);
    }
  };

  const uploadFile = async (file) => {
    const response = await directUpload.upload(file);
    return response;
  };

  const destroyFile = async () => {
    await directUpload.destroyFile(signedId);
  };

  const onChangePdfFile = async (e) => {
    const file = e.target.files[0];
    setHasContractSizeError(false);
    if (file && file.size < LIMIT_SIZE_10_MB) {
      setForceClose(true);
      setDropdownDisabled(true);

      updateAttribute('nameFile', file.name);
      updateAttribute('sizeFile', file.size);
      updateAttribute('fileUrl', URL.createObjectURL(file));

      const { signed_id, url } = await uploadFile(file);
      updateAttribute('signedId', signed_id);
      updateAttribute('awsUrl', url);
    } else {
      setHasContractSizeError(true);
    }
  };

  const removeFile = async () => {
    setForceClose(false);
    setDropdownDisabled(false);

    updateAttribute('nameFile', '');
    updateAttribute('sizeFile', '');
    updateAttribute('fileUrl', '');
    updateAttribute('signedId', '');
    updateAttribute('awsUrl', '');
    if (action !== 'edit') await destroyFile();
  };

  const fetchStudentsResponsibles = useCallback(() => {
    dispatch(fetchStudentsResponsiblesRequest());
  }, [dispatch, fetchStudentsResponsiblesRequest]);

  const buttonTrigger = () => (
    <S.ButtonTrigger dropdownDisabled={dropdownDisabled}>
      <span className="TextContainer">Adicionar contrato</span>

      <span className="RightIcon">
        <i className="fa fa-caret-down" />
      </span>
    </S.ButtonTrigger>
  );

  const selectedModel = useMemo(
    () => contractModels?.filter((model) => model.id === contractModelId)[0],
    [contractModelId, contractModels]
  );

  const getVariables = () => {
    const result = Object.values(selectedModel.attributes.variables).map(
      (value) => `{{ ${value} }}`
    );
    return result;
  };

  const handleTypeFormChange = (value) => {
    updateAttribute('formType', value);
    updateAttribute('selectedClassroom', null);
    updateAttribute('responsible_ids', null);
    updateAttribute('selectedStudent', null);
    updateAttribute('selectedRecipients', []);
    updateAttribute('contractModelId', null);
    changeMeta('resetFormTabs', false);
    fetchStudentsResponsibles();
  };

  return (
    <S.EnrollmentPlanFormContractTab>
      <FormFieldset>
        <div className="row">
          <Select
            label={`${t('form.enrollment.field.select_type')}*`}
            marginLeft={12}
            marginRight={12}
            marginBottom={24}
            fullWidth
            options={formTypes}
            value={formType}
            defaultValue=""
            onChange={(event) => handleTypeFormChange(event.value)}
            disabled={action === 'edit'}
          />
        </div>
        {formType === 'single' && (
          <>
            <S.ContractInfo>
              {t('form.enrollment.add_enrollment_contract')}
            </S.ContractInfo>

            {hasContractSizeError && (
              <S.ErrorSize>
                {t('common.file_type', { type: 'PDF' })}
              </S.ErrorSize>
            )}

            <Dropdown
              trigger={buttonTrigger()}
              forceClose={dropdownDisabled ? true : forceClose}
              onToggle={onToggle}
            >
              <S.FileInputContainer>
                <span>{t('form.enrollment.field.file_input')}</span>
                <input
                  type="file"
                  accept=".pdf"
                  onChange={onChangePdfFile}
                  name="fileUrl"
                  ref={inputRef}
                />
              </S.FileInputContainer>
            </Dropdown>

            {invalidField && <FormValidationErrors attributeName="fileUrl" />}

            {fileUrl && (
              <PdfField
                fileUrl={fileUrl}
                removeFile={removeFile}
                nameFile={nameFile}
                sizeFile={normalizeFileSize(sizeFile)}
                disabled={action === 'edit' && status === 'sent'}
              />
            )}
          </>
        )}
        {formType === 'mass' && (
          <>
            {fileUrl ? (
              <PdfField
                fileUrl={fileUrl}
                removeFile={removeFile}
                nameFile={nameFile}
                sizeFile={normalizeFileSize(sizeFile)}
                disabled={action === 'edit' && status === 'sent'}
              />
            ) : (
              <>
                <div className="row">
                  <Select
                    label={`${t('form.enrollment.field.select_model')}*`}
                    marginLeft={12}
                    marginRight={12}
                    marginBottom={24}
                    fullWidth
                    options={[
                      {
                        value: null,
                        label: t('form.enrollment.field.select_model'),
                      },
                      ...(contractModels
                        ? contractModels.map((model) => ({
                            value: model.id,
                            label: model.attributes.title,
                          }))
                        : []),
                    ]}
                    value={contractModelId}
                    defaultValue=""
                    onChange={(event) => {
                      updateAttribute('contractModelId', event.value);
                    }}
                    disabled={action === 'edit'}
                  />
                </div>
                {contractModelId && (
                  <>
                    <S.Label12>
                      {t('form.enrollment.model_variables')}
                    </S.Label12>
                    <S.TagContainer>
                      {getVariables().map((variable) => (
                        <Tag
                          variant="neutral"
                          key={variable}
                          name={variable}
                          size={'large'}
                        />
                      ))}
                    </S.TagContainer>
                    <Button
                      variant="secondary"
                      onClick={() =>
                        window.open(selectedModel.attributes.attachment.url)
                      }
                    >
                      {t('form.enrollment.button.download_model')}
                    </Button>
                  </>
                )}
              </>
            )}

            <S.InfoText>{t('common:fields.required_fields')}</S.InfoText>
          </>
        )}
      </FormFieldset>
    </S.EnrollmentPlanFormContractTab>
  );
};

EnrollmentPlanFormContractTab.propTypes = {
  ...formPropTypes,
};

export default tabifyForWizard({
  title: '1. Contrato',
  subTitle: 'Contrato',
  validations: [],
})(withFormContext(EnrollmentPlanFormContractTab));
