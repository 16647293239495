import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './styles';

import { Alert, Text } from '@agendaedu/ae-web-components';
import validateTextAreaPresence from 'core/lib/FormValidator/validators/textAreaPresence';
import FormContainer from 'components/Form/FormContainer';
import { Header } from '../components/Header';
import { Props } from './types';

export const ObservationCard = ({
  observation,
  onChange,
  hasInitialValue,
  student,
}: Props): React.ReactElement => {
  const { t } = useTranslation(['diary_sections']);

  useEffect(() => {
    const metaTag = document.querySelector('meta[name="viewport"]');

    if (metaTag) {
      metaTag.setAttribute(
        'content',
        'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no, viewport-fit=cover'
      );
    }
  }, []);

  return (
    <S.ObservationWrapper data-testid="observation-card">
      <Header
        sectionTitle={t('sections.card.observation.title')}
        student={student}
      />

      {hasInitialValue && (
        <Alert variant="informative">
          <Text
            variant="subtitle-medium-14"
            color="context.info.default"
            marginBottom="0"
          >
            {t('sections.card.observation.info')}
          </Text>
        </Alert>
      )}

      <FormContainer>
        <S.ObservationField
          quillProps={{
            onChange: (value: string) =>
              observation !== value &&
              onChange(validateTextAreaPresence(value) ? value : ''),
            value: observation,
          }}
          type="textArea"
          useImage
        />
      </FormContainer>
    </S.ObservationWrapper>
  );
};
