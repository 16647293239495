const totalPriceItems = (items) => {
  if (!items.length) return;

  const reducer = (accumulator, currentValue) => {
    if (currentValue.attributes.discount_kind === 'percent') {
      return accumulator + (currentValue.attributes.price_cents * ((100 - currentValue.attributes.discount_percent) / 100));
    }

    return accumulator + (currentValue.attributes.price_cents - currentValue.attributes.discount_price_cents);
  };

  return items.reduce(reducer, 0);
};

export default totalPriceItems;
