import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';

import withFormContext, { formPropTypes } from 'core/hoc/withFormContext';
import withAppContext from 'core/hoc/withAppContext';
import colorWithAlpha from 'core/utils/colorWithAlpha';

import AgendaIcon from 'components/AgendaIcon';
import FormValidationErrors from 'components/Form/ValidationErrors';

import './style.scss';

/**
 * Example for use.
 *
 * There is AgendaIcon component, where you pass the icon name you want
 * use in options, following the pattern below:
 *
 * const options = [
 *  { value: "answer-only", label: "Answer only", icon: 'check' },
 *  { value: "answers-multiple", label: "Answers-multiple", icon: "check-square" },
 *  { value: "answers-open", label: "Answers open", icon: "align-left" }
 * ];
 *
 * <Field
 *    label="Select with Icon"
 *    attributeName="selectData"
 *    type="selectWithIcon"
 *    options={options} <- array with options
 * />
 */

const SelectWithIcon = ({
  appContext: { primaryColor },
  formContext: { hasErrorOnAttribute },
  options,
  onChange,
  disabled,
  placeholder,
  value,
  rawValue,
  withArrowSelect,
  fullWidth,
  hasDisabledPlaceholder,
  isSearchable,
  attributeName,
  defaultValue,
  menuPlacement,
  isMulti,
  components,
  filterOption,
  classNamePrefix,
}) => {
  const menuPlacementStyles = {
    top: {
      borderRadius: '6px 6px 0 0',
      borderBottom: 'none',
      marginBottom: '-5px',
    },
    bottom: {
      borderRadius: '0 0 6px 6px',
      borderTop: 'none',
      marginTop: '-5px',
    },
  };

  const customStyles = {
    control: (base, state) => ({
      ...base,
      maxWidth: fullWidth ? 'none' : 350,
      height: isMulti ? '100%' : 40,
      minHeight: isMulti ? 40 : 'none',
      fontSize: 16,
      borderRadius: 6,
      fontFamily: 'Roboto',
      backgroundColor: '#FFFFFF',
      borderColor: state.isFocused ? primaryColor : '#DDDDDD',
      boxShadow: 'none',
      '&:hover': {
        borderColor: primaryColor,
      },
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      color: state.isFocused ? primaryColor : '#DDDDDD',
    }),
    menu: (provided) => ({
      ...provided,
      maxWidth: fullWidth ? 'none' : 350,
      border: `solid 1px ${primaryColor}`,
      boxShadow: 'none',
      textAlign: 'left',
      ...menuPlacementStyles[menuPlacement],
    }),
    option: (provided, state) => ({
      ...provided,
      width: 'calc(100% - 10px)',
      maxWidth: '100%',
      fontFamily: 'Roboto',
      overflowWrap: 'break-all',
      cursor: 'pointer',
      fontSize: 16,
      margin: 5,
      borderRadius: 6,
      overflow: 'hidden',
      color: state.isSelected ? primaryColor : '#333333',
      backgroundColor: state.isSelected
        ? colorWithAlpha(primaryColor, 0.1)
        : '#FFFFFF',
      '&:hover': {
        backgroundColor: colorWithAlpha(primaryColor, 0.1),
        color: primaryColor,
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      marginLeft: 10,
    }),
    multiValue: (provided) => ({
      ...provided,
      borderRadius: '4px',
      backgroundColor: colorWithAlpha(primaryColor, 0.1),
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: primaryColor,
      '&:hover': {
        color: primaryColor,
        backgroundColor: colorWithAlpha(primaryColor, 0.1),
      },
    }),
  };

  const [customPlaceholder, setCustomPlaceholder] = useState('');

  useEffect(() => {
    const currentPlaceholder = getValueSelect();
    if (currentPlaceholder) {
      setCustomPlaceholder(currentPlaceholder.label);
    }
  }, []);

  const formatGroupLabel = (data) => (
    <div className="GroupStyles">
      <span>{data.label}</span>
      <span className="GroupBadgeStyles">{data.options.length}</span>
    </div>
  );

  const formatOptionLabel = ({ label, icon }) => (
    <div style={{ display: 'flex' }}>
      {icon && (
        <div style={{ marginRight: '10px' }}>
          <AgendaIcon name={icon} />
        </div>
      )}
      <div className="teste">{label}</div>
    </div>
  );

  const dropdowIndicatorIcon = () => {
    return withArrowSelect ? (
      <AgendaIcon
        className={disabled ? 'ArrowSelectDisabled' : 'ArrowSelect'}
        name="arrow-select"
      />
    ) : (
      <AgendaIcon
        className={disabled ? 'ArrowDownDisabled' : 'ArrowDown'}
        name="arrow-down"
      />
    );
  };

  const getValueSelect = () => {
    let currentValue = options.find((option) => option.value === value);

    if (!currentValue) {
      const allLabels = [];
      options.forEach((groupOption) => {
        if (groupOption.options) {
          groupOption.options.forEach((option) => {
            allLabels.push(option);
          });
        }
      });
      currentValue = allLabels.find((option) => option.value === value);
    }

    return currentValue;
  };

  const getMultiValuesSelect = () => {
    let currentValues = [];

    if (value) {
      value.map((value) => {
        let option = options.find((option) => option.value == value);
        option && currentValues.push(option);
      });
    }

    return currentValues;
  };

  const selectValue = () => {
    if (rawValue) return rawValue;

    if (isMulti && !rawValue) return getMultiValuesSelect();

    return getValueSelect();
  };

  const hasError = hasErrorOnAttribute(attributeName);

  return (
    <>
      <Select
        filterOption={filterOption}
        placeholder={hasDisabledPlaceholder ? customPlaceholder : placeholder}
        options={options}
        isSearchable={isSearchable}
        styles={customStyles}
        onChange={onChange}
        value={selectValue()}
        defaultValue={defaultValue || options[0]}
        formatOptionLabel={formatOptionLabel}
        isDisabled={disabled}
        components={
          components
            ? components
            : {
                IndicatorSeparator: () => null,
                DropdownIndicator: () => dropdowIndicatorIcon(),
              }
        }
        controlShouldRenderValue={disabled && !fullWidth ? false : true}
        formatGroupLabel={formatGroupLabel}
        noOptionsMessage={() => 'Sem resultados'}
        menuPlacement={menuPlacement}
        isMulti={isMulti}
        classNamePrefix={classNamePrefix}
      />
      {hasError && <FormValidationErrors attributeName={attributeName} />}
    </>
  );
};

SelectWithIcon.propTypes = {
  ...formPropTypes,
  fullWidth: PropTypes.bool,
  menuPlacement: PropTypes.string,
  isMulti: PropTypes.bool,
  classNamePrefix: PropTypes.string,
};

SelectWithIcon.defaultProps = {
  fullWidth: false,
  hasDisabledPlaceholder: false,
  isSearchable: false,
  menuPlacement: 'bottom',
  isMulti: false,
};

export default withAppContext(withFormContext(SelectWithIcon));
