import styled, { css } from 'styled-components';
import FormContainer from 'components/Form/FormContainer';

import { ThemeProps } from 'config/themes/types';

export const FormWrapper = styled(FormContainer)``;

export const FormContent = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    flex-direction: row;
    gap: ${space.xs2};
    margin-top: ${space.lg};
  `}
`;

export const InputsWrapper = styled.div`
  ${({ theme: { border } }: ThemeProps) => css`
    display: flex;
    flex-direction: column;
    width: 100%;

    > div {
      display: flex;
      width: 100%;

      .SingleDatePicker,
      .SingleDatePickerInput {
        width: 100%;
      }
    }

    .TimeField .input-group i.input-group-addon {
      border-radius: ${border.radius.md} 0 0 ${border.radius.md};
    }

    .TimeField .input-group .form-control {
      border-radius: 0 ${border.radius.md} ${border.radius.md} 0;
    }
  `}
`;

export const Label = styled.label`
  ${({ theme: { typography } }: ThemeProps) => css`
    display: flex;
    ${typography.label.Regular14}
  `}
`;
