import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import HandoutContext from 'core/contexts/Handout';
import useGoogleDrive from 'core/hooks/useGoogleDrive';
import withAppContext from 'core/hoc/withAppContext';

import HandoutsActions from 'store/handouts/actions';
import { HandoutState } from 'store/handouts/types';

import * as S from '../styles';

import FormFullScreen from 'components/FormFullScreen';
import { FormSkeleton } from '../skeleton';
import GeneralInformationStep from '../GeneralInformationStep';
import NotificationModal from '../NotificationModal';
import RecipientsStep from 'components/Form/RecipientsTab';
import Toast from 'components/Toast';

import { Props } from './types';

const WIZARD_STEPS = [GeneralInformationStep, RecipientsStep];

const EditHandout: React.FC<Props> = ({ appContext: { dataArea }, match }) => {
  const { t } = useTranslation(['handouts']);

  const { id: handoutId } = match?.params;

  const dispatch = useDispatch();

  const googleClientId = process.env.GOOGLE_CLIENT_ID;

  const { formMeta, current, isSending } = useSelector(
    (state: HandoutState) => state.handouts
  );

  const googleClient = useGoogleDrive(
    googleClientId,
    'https://www.googleapis.com/auth/drive.readonly'
  );

  const mustNofityUpdated = current?.status === 'sent';

  const hasCurrentHandout = formMeta?.classrooms.length && current?.id;

  const {
    fetchClassrooms,
    fetchHandoutRequest,
    toggleNotificationModal,
    editHandoutRequest,
  } = HandoutsActions;

  const handleToggleNotificationModal = useCallback(() => {
    dispatch(toggleNotificationModal());
  }, [dispatch, toggleNotificationModal]);

  const handleEditHandoutRequest = useCallback(
    (form) => {
      dispatch(editHandoutRequest(handoutId, form));

      mustNofityUpdated && handleToggleNotificationModal();
    },
    [
      dispatch,
      editHandoutRequest,
      handleToggleNotificationModal,
      handoutId,
      mustNofityUpdated,
    ]
  );

  const handleActionEditOrNotify = ({ form }) => {
    if (mustNofityUpdated) {
      handleToggleNotificationModal();
      return;
    }
    handleEditHandoutRequest(form);
  };

  const handleFetchClassrooms = useCallback(() => {
    dispatch(fetchClassrooms());
  }, [dispatch, fetchClassrooms]);

  useEffect(() => {
    handleFetchClassrooms();
  }, [handleFetchClassrooms]);

  useEffect(() => {
    if (handoutId) {
      const params = {
        id: handoutId,
      };
      dispatch(fetchHandoutRequest(params));
    }
  }, [dispatch, fetchHandoutRequest, handoutId]);

  return (
    <S.HandoutsFormContainer>
      <HandoutContext.Provider value={googleClient}>
        {hasCurrentHandout ? (
          <FormFullScreen
            action="edit"
            id="editHandout"
            form={current}
            initialAttributes={current}
            formMeta={{
              ...formMeta,
              googleClientId,
              hideRadio: false,
              canEditClassrooms: current?.status !== 'sent',
              select_options: {
                classroom_id: formMeta.classrooms,
              },
              student_profiles: current.student_profiles,
            }}
            titlePage={t('edit_handout_form.title')}
            backTo={`/${dataArea}/handouts`}
            backToExternal
            isSubmitting={isSending}
            steps={WIZARD_STEPS}
            onSubmit={handleActionEditOrNotify}
            titleModal={t('modals.handout_form_discard.title')}
            contentModal={t('modals.handout_form_discard.description')}
          >
            <NotificationModal
              handleToggle={handleToggleNotificationModal}
              handleOnSubmit={handleEditHandoutRequest}
            />
          </FormFullScreen>
        ) : (
          <FormSkeleton
            title={t('edit_handout_form.title')}
            dataArea={dataArea}
          />
        )}
      </HandoutContext.Provider>
      <Toast />
    </S.HandoutsFormContainer>
  );
};

export default withAppContext(EditHandout);
