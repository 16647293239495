import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Button from 'components/Button';
import ConsumptionBar from 'components/ConsumptionBar';

import MyAccountActions from 'store/myAccount/actions';

import './style.scss';

const MultimediaConsumption = () => {
  const dispatch = useDispatch();

  const { fetchMultimediaConsumption } = MyAccountActions;

  const multimediaConsumption = useSelector(
    (state) => state.myAccount.multimediaConsumption
  );

  useEffect(() => {
    dispatch(fetchMultimediaConsumption());
  }, []);

  const getConsumptionList = () => {
    return [
      {
        label: 'Imagens',
        consumption: multimediaConsumption.attributes.images,
        value: multimediaConsumption.attributes.images_percentage,
        color: '#80d5f9',
      },
      {
        label: 'Vídeos',
        consumption: multimediaConsumption.attributes.videos,
        value: multimediaConsumption.attributes.videos_percentage,
        color: '#8345ed',
      },
      {
        label: 'Arquivos',
        consumption: multimediaConsumption.attributes.files,
        value: multimediaConsumption.attributes.files_percentage,
        color: '#5ad0c7',
      },
    ];
  };

  const onOpenChat = () => {
    zE('messenger', 'open');
  };

  const renderLegend = () => {
    return (
      <div>
        {getConsumptionList().map((item, index) => (
          <div key={index} className="legend">
            <div className="label-area">
              <div
                className="color"
                style={{ backgroundColor: item.color }}
              ></div>
              <p className="roboto-text label">{item.label}</p>
            </div>
            <p className="roboto-text consumption">{item.consumption}</p>
          </div>
        ))}
      </div>
    );
  };

  return (
    <>
      {multimediaConsumption.attributes && (
        <section>
          <div className="usage-area">
            <p className="percentage">
              {multimediaConsumption.attributes.total_percentage}
            </p>
            <p className="text">usado(s)</p>
          </div>
          <div className="limit-area">
            <p className="roboto-text text">
              {multimediaConsumption.attributes.total}
            </p>
            <p className="roboto-text text">
              {multimediaConsumption.attributes.limit}
            </p>
          </div>
          <div className="data-area">
            <ConsumptionBar
              list={getConsumptionList()}
              legend={renderLegend()}
            />
            <div className="roboto-text summary">
              Sua escola consumiu até agora{' '}
              {multimediaConsumption.attributes.total} de dados na nossa
              plataforma. Você tem até {multimediaConsumption.attributes.limit}{' '}
              de espaço de armazenamento para uso.
            </div>
          </div>
          <div className="button-area">
            <p className="roboto-text label">Precisa de mais espaço?</p>
            <Button disabled={false} variation="secondary" onClick={onOpenChat}>
              Aumentar armazenamento
            </Button>
          </div>
        </section>
      )}
    </>
  );
};

export default MultimediaConsumption;
