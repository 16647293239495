/* eslint-disable no-case-declarations */
import actions from './actions';

export const INITIAL_STATE = {
  dataAvailabilities: [],
  dataReceivables: [],
  dataGuaranteedMonthlyFee: [],
  meta: {},
  page: 1,
  isLoading: false,
  error: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actions.FETCH_REQUEST_AVAILABILATIES:
      return {
        ...state,
        isLoading: true,
      };
    case actions.FETCH_SUCCESS_AVAILABILATIES:
      return {
        ...state,
        dataAvailabilities: action.data,
        meta: action.meta,
        isLoading: false,
      };
    case actions.FETCH_REQUEST_RECEIVABLES:
      return {
        ...state,
        isLoading: true,
      };
    case actions.FETCH_SUCCESS_RECEIVABLES:
      return {
        ...state,
        dataReceivables: action.data,
        meta: action.meta,
        isLoading: false,
      };
    case actions.FETCH_REQUEST_GUARANTEED_MONTHLY_FEE:
      return {
        ...state,
        isLoading: true,
      };
    case actions.FETCH_SUCCESS_GUARANTEED_MONTHLY_FEE:
      return {
        ...state,
        dataGuaranteedMonthlyFee: action.data,
        isLoading: false,
      };
    case actions.FETCH_PAYMENTS:
      const totalAvailabilities = state[action.date.value]
        ? state[action.date.value].payments.length
        : 0;

      const hasMore = state[action.date.value]
        ? totalAvailabilities < state[action.date.value].totalItemsCount
        : false;

      return {
        ...state,
        [action.date.value]: {
          ...state[action.date.value],
          date: action.date.value,
          isLoading: true,
          hasMore,
        },
      };
    case actions.FETCH_SUCCESS_PAYMENTS:
      return {
        ...state,
        [action.date.value]: {
          ...state[action.date.value],
          isLoading: false,
          totalItemsCount: action.totalItemsCount,
          hasMore: action.hasMore,
          payments: action.hasMore
            ? [...(state[action.date.value]?.payments || []), ...action.data]
            : action.data,
        },
        page: action.page,
      };
    case actions.SHOW_REQUEST_AVAILABILATY:
      return {
        ...state,
        [action.date.value]: {
          ...state[action.date.value],
          modalLoading: true,
          payment: null,
        },
      };
    case actions.SHOW_SUCCESS_AVAILABILATY:
      return {
        ...state,
        [action.date.value]: {
          ...state[action.date.value],
          modalLoading: false,
          payment: action.data,
        },
      };
    case actions.TOGGLE_MODAL:
      return {
        ...state,
        [action.date.value]: {
          ...state[action.date.value],
          modalOpen: !state[action.date.value].modalOpen,
        },
      };
    case actions.FETCH_ERROR:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    default:
      return state;
  }
};
