import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Text, Button, TextField } from '@agendaedu/ae-web-components';

import * as S from './styles';
import { Props } from './types';
import { modalActions } from 'core/constants/schoolTerms';

const ValidationModal: React.FC<Props> = ({
  closeModal,
  isModalOpen,
  onSubmit,
  variant,
  periodsNames,
}) => {
  const { t } = useTranslation(['school_terms']);
  const [isEnableButtonActivate, setIsEnableButtonActivate] = useState(true);

  const MODAL_CENTERED = {
    top: '50%',
    left: '50%',
    right: '50%',
    padding: '24px',
    transform: 'translate(-50%, -50%)',
  };

  const variants = {
    [modalActions.createPeriod]: {
      title: `${t('modals.create_period.title')}`,
      subtitle: `${t('modals.create_period.subtitle')}`,
      buttonAction: `${t('modals.create_period.button_action')}`,
      buttonBack: `${t('modals.create_period.button_back')}`,
    },
    [modalActions.savePeriod]: {
      title: `${t('modals.save_period.title')}`,
      subtitle: `${t('modals.save_period.subtitle')}`,
      buttonAction: `${t('modals.save_period.button_action')}`,
      buttonBack: `${t('modals.save_period.button_back')}`,
    },
    [modalActions.deletePeriod]: {
      title: `${t('modals.delete_period.title')}`,
      subtitle: `${t('modals.delete_period.subtitle')}`,
      buttonAction: `${t('modals.delete_period.button_action')}`,
      buttonBack: `${t('modals.delete_period.button_back')}`,
    },
    [modalActions.editPeriod]: {
      title: `${t('modals.edit_period.title')}`,
      subtitle: `${t('modals.edit_period.subtitle')}`,
      buttonAction: `${t('modals.edit_period.button_action')}`,
      buttonBack: `${t('modals.edit_period.button_back')}`,
    },
    [modalActions.saveEditPeriod]: {
      title: `${t('modals.save_edit_period.title')}`,
      subtitle: `${t('modals.save_edit_period.subtitle')}`,
      buttonAction: `${t('modals.save_edit_period.button_action')}`,
      buttonBack: `${t('modals.save_edit_period.button_back')}`,
    },
    [modalActions.activatePeriod]: {
      title: `${t('modals.activate_period.title', {
        periodName: periodsNames?.periodName,
        oldPeriodName: periodsNames?.oldPeriodName,
      })}`,
      subtitle: `${t('modals.activate_period.subtitle', {
        periodName: periodsNames?.periodName,
        oldPeriodName: periodsNames?.oldPeriodName,
      })}`,
      attention: `${t('modals.activate_period.attention')}`,
      label: `${t('modals.activate_period.label')}`,
      buttonAction: `${t('modals.activate_period.button_action')}`,
      buttonBack: `${t('modals.activate_period.button_back')}`,
    },
  };

  const renderSpecificActivate = () => {
    return {
      input: (
        <Box
          display="flex"
          flexDirection="row"
          minHeight="fit-content"
          width="100%"
        >
          <TextField
            label={variants[variant].label}
            onChange={(e) => {
              setIsEnableButtonActivate(
                !(e?.target?.value?.toUpperCase() === 'DESEJO ATIVAR')
              );
            }}
            placeholder="DESEJO ATIVAR"
          />
        </Box>
      ),
      attention: (
        <Text
          margin="12px 0 0"
          as="p"
          variant="title-bold-12"
          textAlign="start"
          lineHeight="lg"
          whiteSpace="pre-line"
          fontWeight={700}
        >
          {variants[variant].attention}
        </Text>
      ),
    };
  };

  if (!variant) return <></>;

  return (
    <S.ModalWrapper
      isOpen={isModalOpen}
      toggleModal={closeModal}
      maxWidth="396px"
      contentStyles={MODAL_CENTERED}
      title={variants[variant].title}
    >
      <Box display="flex" flexDirection="column" minHeight="fit-content">
        {modalActions.activatePeriod === variant &&
          renderSpecificActivate().attention}

        <Text
          margin="12px 0 0"
          as="p"
          variant="body-regular-16"
          textAlign="start"
          lineHeight="lg"
          whiteSpace="pre-line"
        >
          {variants[variant].subtitle}
        </Text>

        {modalActions.activatePeriod === variant &&
          renderSpecificActivate().input}

        <Box mt={24} display="flex" justifyContent="flex-end">
          <Box mr={16}>
            <Button variant="secondary" onClick={closeModal}>
              {variants[variant].buttonBack}
            </Button>
          </Box>
          <Button
            onClick={() => onSubmit(variant)}
            {...(modalActions.deletePeriod === variant && {
              isNegativeAction: true,
            })}
            {...(modalActions.activatePeriod === variant && {
              disabled: isEnableButtonActivate,
            })}
          >
            {variants[variant].buttonAction}
          </Button>
        </Box>
      </Box>
    </S.ModalWrapper>
  );
};

export default ValidationModal;
