import * as TYPES from './types';

export const fetchSophiaFiltersRequest = () => ({
  type: TYPES.FETCH_REQUEST,
});

export const setHeadquarters = filters => ({
  type: TYPES.SET_HEADQUARTERS,
  filters,
});

export const setCourses = filters => ({
  type: TYPES.SET_COURSES,
  filters,
});

export const setSchoolTerms = filters => ({
  type: TYPES.SET_SCHOOL_TERMS,
  filters,
});

export const updateSophiaFiltersSent = () => ({
  type: TYPES.UPDATE_SENT,
});

export const updateSophiaFiltersRequest = params => ({
  type: TYPES.UPDATE_REQUEST,
  params,
});
