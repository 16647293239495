import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { IMPORT_PROFILE_TYPES } from './constants';
import PageContainer from 'components/PageContainer';
import Button from 'components/Button';
import ButtonRow from 'components/ButtonRow';
import Table from 'components/Table';
import table from './table';
import Toast from 'components/Toast';
import Loader from 'components/Loader';
import Breadcrumb from 'components/Breadcrumb';
import EmptyState from 'components/EmptyState';
import { IMAGES_SURVERY_URL } from 'core/constants/index';
import importWebActions from 'store/importWeb/actions';
import * as S from './styles';

const ImportWeb = ({ match }) => {
  const { isLoading, imports } = useSelector((state) => state.importWeb);
  const { fetchImports } = importWebActions;
  const dispatch = useDispatch();
  const { importedInto } = match.params;

  const handleFetchImports = useCallback(() => {
    dispatch(fetchImports(importedInto));
  }, [dispatch, fetchImports, importedInto]);

  useEffect(() => {
    handleFetchImports();
  }, [handleFetchImports]);

  const breadcrumbPath = (importedInto) =>
    importedInto == 'school_user' ? 'teachers' : `${importedInto}s`;

  const renderTable = () => {
    if (imports.length > 0) {
      return (
        <>
          <Table
            columns={table}
            data={imports}
            customMetadata={tableEntries()}
          />
        </>
      );
    } else {
      return (
        <EmptyState
          imgUrl={IMAGES_SURVERY_URL.emptyStateUrl}
          message={<p>Não foi encontrado nenhuma importação.</p>}
        />
      );
    }
  };

  const tableEntries = () => {
    if (imports.length > 0) {
      return (
        <div className="customMetadata">
          {imports.length === 1 ? (
            <span>Exibindo 1 importação</span>
          ) : (
            <span>Exibindo {imports.length} importações</span>
          )}
        </div>
      );
    }
  };

  return (
    <S.ImportWebContainer>
      <PageContainer
        title={`Central de importação de ${IMPORT_PROFILE_TYPES[importedInto]}`}
        className="ImportWeb"
        header={
          <Link to={`${importedInto}/new`}>
            <ButtonRow>
              <Button role="button">Nova importação</Button>
            </ButtonRow>
          </Link>
        }
        breadcrumb={
          <Breadcrumb
            title={`Central de ${IMPORT_PROFILE_TYPES[importedInto]}`}
            path={`/schools/${breadcrumbPath(importedInto)}`}
            external
          />
        }
      >
        <Loader isLoading={isLoading}>{renderTable()}</Loader>
      </PageContainer>
      <Toast />
    </S.ImportWebContainer>
  );
};

ImportWeb.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      importedInto: PropTypes.string,
    }),
  }),
};

export default ImportWeb;
