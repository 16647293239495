import React, { useEffect, useCallback } from 'react';

import tabifyForWizard from 'core/hoc/tabifyForWizard';
import withFormContext from 'core/hoc/withFormContext';
import { titleMobile } from 'core/utils/changeText';

import Button from 'components/Button';
import FormFieldset from 'components/Form/Fieldset';
import Question from './Question';

import validations, {
  loadValidations,
  addValidations,
  removeValidations,
} from './validations';
import './style.scss';

import SurveyActions from 'store/surveys/actions';
import { useDispatch, useSelector } from 'react-redux';

const SurveyFormQuestionsTab = ({ formContext: { form, updateAttribute } }) => {
  const { setCurrentForm } = SurveyActions;
  const dispatch = useDispatch();

  const multiQuestionSurveys = useSelector(
    (state) => state.root.policies.has_access_to_multiple_question_surveys
  );

  const surveyQuestions = form.survey_questions_attributes;

  const setForm = useCallback((form) => {
    dispatch(setCurrentForm(form));
  }, []);

  useEffect(() => {
    loadValidations(surveyQuestions);
  }, [surveyQuestions]);

  const addNewQuestion = () => {
    const newQuestions = [
      ...surveyQuestions,
      {
        position: `${surveyQuestions.length + 1}`,
        [`question_${surveyQuestions.length}`]: '',
        question_type: 'unique',
        required: true,
        key: Math.random().toString(36).substring(7),
        survey_options_attributes: [
          {
            position: '1',
            title: '',
            others: false,
            key: Math.random().toString(36).substring(7),
          },
        ],
      },
    ];

    addValidations(newQuestions.length - 1);

    updateAttribute('survey_questions_attributes', newQuestions);
  };

  const cloneQuestion = (index) => {
    const clonedQuestion = surveyQuestions
      .filter((question) => question.position === `${index + 1}`)
      .map((question) => ({
        ...question,
        [`question_${surveyQuestions.length}`]: question[`question_${index}`],
        position: `${surveyQuestions.length + 1}`,
        key: Math.random().toString(36).substring(7),
        ...(question.question_type === 'subjective'
          ? { survey_options_attributes: [] }
          : {
              survey_options_attributes: question.survey_options_attributes.map(
                (option, indexOption) => {
                  const key = Math.random().toString(36).substring(7);
                  updateAttribute(
                    `titleOption_${surveyQuestions.length}_${indexOption}_${key}`,
                    option.title
                  );
                  return {
                    ...option,
                    key,
                  };
                }
              ),
            }),
      }));

    addValidations(index);

    delete clonedQuestion[0][`question_${index}`];

    const addClonedQuestion = [...surveyQuestions, clonedQuestion[0]];

    updateAttribute('survey_questions_attributes', addClonedQuestion);
    updateAttribute(
      `question_${surveyQuestions.length}`,
      clonedQuestion[0][`question_${surveyQuestions.length}`]
    );
  };

  const removeQuestion = (index) => {
    const indexLastQuestion = surveyQuestions.length - 1;

    const allQuestionsTitle = surveyQuestions
      .map((question, indexQuestion) => {
        if (index === indexQuestion) {
          if (question.question_type !== 'subjective') {
            question.survey_options_attributes.forEach(
              (option, optionIndex) => {
                delete form[
                  `titleOption_${index}_${optionIndex}_${option.key}`
                ];
              }
            );
            surveyQuestions[
              indexLastQuestion
            ].survey_options_attributes.forEach((option, optionIndex) => {
              delete form[
                `titleOption_${indexLastQuestion}_${optionIndex}_${option.key}`
              ];
            });
          }
          delete form[`question_${index}`];
          delete form[`question_${indexLastQuestion}`];
          setForm(form);
        }
        if (index !== indexQuestion)
          return question[`question_${indexQuestion}`];
      })
      .filter((notUndefined) => notUndefined !== undefined);

    const remainingQuestions = surveyQuestions.filter(
      (question, indexQuestion) => {
        delete surveyQuestions[indexQuestion][`question_${indexQuestion}`];
        return question.position !== `${index + 1}`;
      }
    );

    loadValidations(remainingQuestions);

    const newQuestions = remainingQuestions.map((question, indexQuestion) => ({
      ...question,
      [`question_${indexQuestion}`]: allQuestionsTitle[indexQuestion],
      position: `${indexQuestion + 1}`,
    }));

    updateAttribute('survey_questions_attributes', newQuestions);

    newQuestions.forEach((question, indexQuestion) => {
      updateAttribute(
        `question_${indexQuestion}`,
        question[`question_${indexQuestion}`]
      );
      question.survey_options_attributes.forEach((option, indexOption) => {
        updateAttribute(
          `titleOption_${indexQuestion}_${indexOption}_${option.key}`,
          option.title
        );
      });
    });
  };

  const toggleSwitch = (key) => {
    const changeRequiredQuestion = surveyQuestions.map((question) =>
      question.key === key
        ? { ...question, required: !question.required }
        : question
    );

    updateAttribute('survey_questions_attributes', changeRequiredQuestion);
  };

  const onChange = (index, values) => {
    surveyQuestions[index] = values;
    updateAttribute('survey_questions_attributes', surveyQuestions);
  };

  return (
    <div className="SurveyFormQuestionsTab">
      <FormFieldset>
        {surveyQuestions.map((question, index) => (
          <Question
            key={question.key}
            index={index}
            question={question}
            totalNumberOfQuestions={surveyQuestions.length}
            toggleSwitch={toggleSwitch}
            cloneQuestion={cloneQuestion}
            removeQuestion={removeQuestion}
            onChange={onChange}
            form={form}
            setForm={setForm}
            enableActions={multiQuestionSurveys}
          />
        ))}
        {multiQuestionSurveys && (
          <div className="new-question">
            <Button onClick={() => addNewQuestion()} variation="secondary">
              Adicionar pergunta
            </Button>
          </div>
        )}
      </FormFieldset>
    </div>
  );
};

export default tabifyForWizard({
  title: titleMobile('2 Perguntas'),
  subTitle: 'Perguntas',
  validations,
})(withFormContext(SurveyFormQuestionsTab));
