import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Alert } from '@agendaedu/ae-web-components';
import SchoolBillingInformationsTab from 'components/Payments/SchoolBillingInformationsTab';
import SchoolWalletHistory from 'components/Payments/PaymentsContainer/SchoolWalletHistory';
import WalletInformation from 'components/Payments/WalletInformation';
import ExtractTab from 'components/Payments/SchoolTransfersTab/ExtractTab';
import TransfersTab from 'components/Payments/SchoolTransfersTab/TransfersTab';
import Tabs from 'components/Tabs';
import Tab from 'components/Tab';

import withAppContext from 'core/hoc/withAppContext';

import { SchoolWalletContainerProps } from './types';

import * as S from './styles';

const SchoolWalletContainer = ({
  appContext: { dataArea, policies },
}: SchoolWalletContainerProps) => {
  const { t } = useTranslation(['payments', 'common']);

  return (
    <S.SchoolWalletWrapper
      title={t('wallet.title')}
      wallet={<WalletInformation />}
      canCreateWalletButton={
        policies.multiple_wallets_enabled && policies.can_config_wallet
      }
    >
      <Tabs defaultIndex={0}>
        <Tab title={t('wallet.tabs.tab_extract_title')}>
          <ExtractTab></ExtractTab>
        </Tab>

        <Tab title={t('wallet.tabs.tab_transfers_title')}>
          <TransfersTab dataArea={dataArea}></TransfersTab>
        </Tab>

        <Tab title={t('wallet.tabs.tab_account_details_title')}>
          <SchoolBillingInformationsTab></SchoolBillingInformationsTab>
        </Tab>

        <Tab title={t('wallet.tabs.tab_history_title')}>
          <SchoolWalletHistory></SchoolWalletHistory>
        </Tab>
      </Tabs>
    </S.SchoolWalletWrapper>
  );
};

export default withAppContext(SchoolWalletContainer);
