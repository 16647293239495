import React from 'react';
import { useTranslation } from 'react-i18next';

import { Text, Grid, Box, Tag, SubtleIconButton } from '@agendaedu/ae-web-components';

import Currency from 'components/Currency';

import { ORDER_STATUS_TAGS } from 'core/constants/index';

import * as S from './styles';

const OfferOrderCard = ({ offerOrder, manuallyPaidAction, cancelAction, refundAction }) => {
  const { t } = useTranslation('payments');

  const formatDate = (d) => moment(d).format('DD/MM/YYYY');

  const availableActions = () => {
    let actions = []

    if (offerOrder.actions.includes('cancel')) {
      actions.push({
        as: 'button',
        title: t('common.buttons.cancel'),
        onClick: () => cancelAction(offerOrder)
      })
    }

    if (offerOrder.actions.includes('pay_manually')) {
      actions.push({
        as: 'button',
        title: t('common.buttons.pay_manually'),
        onClick: () => manuallyPaidAction(offerOrder)
      })
    }

    if (offerOrder.actions.includes('refund')) {
      actions.push({
        as: 'button',
        title: t('common.buttons.refund'),
        onClick: () => refundAction(offerOrder)
      })
    }

    return actions;
  };

  return (
    <S.CardWrapper>
      <Grid item gridArea="name">
        <S.BoxWithoutOverflow>
          <Text variant="subtitle-medium-12" color="neutral.gray2">
            {t('marketplace.orders.list.card.labels.order_name')}
          </Text>
          <S.TextWithoutOverflow
            variant="subtitle-medium-16"
            mt="sm"
            mb="xs4"
            color="neutral.gray1"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            overflow="hidden"
          >
            {offerOrder.title}
          </S.TextWithoutOverflow>
        </S.BoxWithoutOverflow>
      </Grid>

      <Grid item gridArea="creation">
        <S.BoxWithoutOverflow>
          <Text variant="subtitle-medium-12" color="neutral.gray2">
            {t('marketplace.orders.list.card.labels.creation_date')}
          </Text>
          <Text
            variant="subtitle-medium-16"
            mt="sm"
            mb="xs4"
            color="neutral.gray1"
          >
            {formatDate(offerOrder.created_at)}
          </Text>
        </S.BoxWithoutOverflow>
      </Grid>

      <Grid item gridArea="expiration">
        <S.BoxWithoutOverflow>
          <Text variant="subtitle-medium-12" color="neutral.gray2">
            {t('marketplace.orders.list.card.labels.expiration_date')}
          </Text>
          <Text
            variant="subtitle-medium-16"
            mt="sm"
            mb="xs4"
            color="neutral.gray1"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            overflow="hidden"
          >
            {formatDate(offerOrder.expires_at)}
          </Text>
        </S.BoxWithoutOverflow>
      </Grid>

      <Grid item gridArea="value">
        <S.BoxWithoutOverflow>
          <Text variant="subtitle-medium-12" color="neutral.gray2">
            {t('marketplace.orders.list.card.labels.value')}
          </Text>
          <Text
            variant="subtitle-medium-16"
            mt="sm"
            mb="xs4"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            overflow="hidden"
          >
            <Currency value={offerOrder.price_cents / 100} />
          </Text>
        </S.BoxWithoutOverflow>
      </Grid>

      <Grid item gridArea="status">
        <S.BoxWithoutOverflow alignItems={'center'}>
          <Tag
            name={t(`common.orders.status.${offerOrder.status}`)}
            variant={ORDER_STATUS_TAGS[offerOrder.status]}
            size="small"
            enableEllipsis
          />
        </S.BoxWithoutOverflow>
      </Grid>

      <Grid item gridArea="actions">
        <Box alignItems={'center'}>
          <SubtleIconButton align="left" actions={availableActions()} />
        </Box>
      </Grid>
    </S.CardWrapper>
  );
};

export default OfferOrderCard;
