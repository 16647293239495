import { QuickAccess } from './types';

const actions = {
  ERROR: 'ERROR/quickaccess',
  SUBMIT_NEW_FORM_REQUEST: 'SUBMIT_NEW_FORM_REQUEST/quickaccess',
  SUBMIT_NEW_FORM_SUCCESS: 'SUBMIT_NEW_FORM_SUCCESS/quickaccess',

  SUBMIT_EDIT_FORM_REQUEST: 'SUBMIT_EDIT_FORM_REQUEST/quickaccess',
  SUBMIT_EDIT_FORM_SUCCESS: 'SUBMIT_EDIT_FORM_SUCCESS/quickaccess',

  GET_CLASSROOMS_REQUEST: 'GET_CLASSROOMS_REQUEST/quickaccess',
  GET_CLASSROOMS_SUCCESS: 'GET_CLASSROOMS_SUCCESS/quickaccess',

  GET_QUICKACCESS_REQUEST: 'GET_QUICKACCESS_REQUEST/quickaccess',
  GET_QUICKACCESS_SUCCESS: 'GET_QUICKACCESS_SUCCESS/quickaccess',

  submitNewFormRequest: (params: QuickAccess) => ({
    type: actions.SUBMIT_NEW_FORM_REQUEST,
    params,
  }),

  submitEditFormRequest: (params: QuickAccess, id: number) => ({
    type: actions.SUBMIT_EDIT_FORM_REQUEST,
    params,
    id,
  }),

  getQuickAccessRequest: (id: number) => ({
    type: actions.GET_QUICKACCESS_REQUEST,
    id,
  }),

  getClassrooms: () => ({ type: actions.GET_CLASSROOMS_REQUEST }),
};

export default actions;
