import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ModalContainer from 'components/Modal/ModalContainer';
import ModalTitle from 'components/Modal/ModalTitle';
import FormContainer from 'components/Form/FormContainer';
import Field from 'components/Form/Field';
import ButtonRow from 'components/ButtonRow';
import Button from 'components/Button';

import './style.scss';

const CancelModal = ({
  isOpen,
  subTitle,
  title,
  onCancel,
  toggleModal,
  setTextCancel,
}) => {
  const [enableCancel, setEnableCancel] = useState(false);

  const onChange = ({ name }) => {
    if (setTextCancel) setTextCancel(name);
    setEnableCancel(name === 'QUERO CANCELAR');
  };

  const clearBeforeAction = (action) => {
    setEnableCancel(false);
    action();
  };

  return (
    <ModalContainer
      className="CancelBillModal"
      isOpen={isOpen}
      toggleModal={toggleModal}
      maxWidth="490px"
    >
      <ModalTitle>{title}</ModalTitle>
      {subTitle}
      <p>
        Digite <strong>QUERO CANCELAR</strong> no campo abaixo
      </p>
      <FormContainer>
        <Field attributeName="name" onChange={onChange} />
        <ButtonRow>
          <Button
            variation="secondary"
            onClick={() => clearBeforeAction(toggleModal)}
          >
            Fechar
          </Button>
          <Button
            variation="danger"
            disabled={!enableCancel}
            onClick={() => clearBeforeAction(onCancel)}
          >
            Cancelar cobrança
          </Button>
        </ButtonRow>
      </FormContainer>
    </ModalContainer>
  );
};

CancelModal.propTypes = {
  title: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
  subTitle: PropTypes.object.isRequired,
  setTextCancel: PropTypes.func,
};

export default CancelModal;
