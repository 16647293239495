import { ActionInconsistenciesTypes } from './types';

const actions = {
  ERROR: 'ERROR/integrationsmonitor',
  GET_INTEGRATION: 'GET_INTEGRATION/integrationsmonitor',
  GET_INTEGRATION_SUCCESS: 'GET_INTEGRATION_SUCCESS/integrationsmonitor',

  GET_INTEGRATION_INCONSISTENCIES:
    'GET_INTEGRATION_INCONSISTENCIES/integrationsmonitor',
  GET_INTEGRATION_INCONSISTENCIES_SUCCESS:
    'GET_INTEGRATION_INCONSISTENCIES_SUCCESS/integrationsmonitor',

  getIntegration: () => ({
    type: actions.GET_INTEGRATION,
  }),

  getIntegrationInconsistencies: ({
    idIntegration,
    page,
    entity,
  }: ActionInconsistenciesTypes) => ({
    type: actions.GET_INTEGRATION_INCONSISTENCIES,
    idIntegration,
    page,
    entity,
  }),
};

export default actions;
