import React, { useEffect } from 'react';
import withAppContext from 'core/hoc/withAppContext';
import reduxProvider from 'core/hoc/reduxProvider';
import { EMPTY_STATES_URL } from 'core/constants';
import { Button } from '@agendaedu/ae-web-components';
import OnboardingEmptyState from 'components/EmptyState/Onboarding';
import { DisciplinesEmptyStateScreenProps } from './types';
import { HELP_URLS } from 'core/constants';
import { trackEvent } from 'config/amplitude';
import * as S from './styles';

const DisciplinesEmptyStateScreen = ({
  appContext: { policies },
}: DisciplinesEmptyStateScreenProps) => {
  const { can_show_empty_state_disciplines: canShowEmptyStateDisciplines } =
    policies;

  const EMPTY_STATE_TEXT = `
    Quais disciplinas são ministradas na sua escola? Nessa seção,
    você pode visualizar, criar e editar todas elas. Além disso, uma
    disciplina pode ser atrelada a várias turmas, para facilitar sua
    gestão. Clique no botão abaixo para criar a primeira.
  `;

  const pushToNewDiscipline = () => {
    window.location.href = '/schools/disciplines/new';
    trackEvent('plg-empty-state-disciplines-click');
  };

  const pushToArticle = () => {
    window.open(HELP_URLS.polyvalentDisciplines, '_blank');
  };

  useEffect(() => {
    trackEvent('plg-empty-state-disciplines-view');
  }, []);

  return (
    <S.Wrapper
      canShow={canShowEmptyStateDisciplines}
      className="DisciplinesEmptyStateScreen"
    >
      <OnboardingEmptyState
        imgUrl={EMPTY_STATES_URL.disciplinesUrl}
        title="Cadastre uma nova disciplina"
        text={EMPTY_STATE_TEXT}
        buttons={
          <S.ButtonRow>
            <Button onClick={pushToNewDiscipline}>Cadastrar disciplina</Button>
            <Button variant="secondary" onClick={pushToArticle}>
              Disciplinas polivalentes
            </Button>
          </S.ButtonRow>
        }
      />
    </S.Wrapper>
  );
};

export default reduxProvider(withAppContext(DisciplinesEmptyStateScreen));
