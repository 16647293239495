import React from 'react';
import { Box, DefaultThemeProps, theme } from '@agendaedu/ae-web-components';

import Skeleton from 'components/Skeleton';

export const AlbumsListSkeleton = () => {
  const defaultTheme: DefaultThemeProps = theme;

  const {
    colors: { neutral },
    border: { radius },
  } = defaultTheme;

  return (
    <Box key="skeleton-item" display="flex" height={280} width="auto" mt="lg">
      <Skeleton
        width="100%"
        height="100%"
        primaryColor={neutral.gray6}
        borderRadius={radius.md}
        isFullWidth
      />
    </Box>
  );
};
