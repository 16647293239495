import styled, { css } from 'styled-components';
import { ThemeProps } from 'config/themes/types';

import { Wrapper } from 'components/Messages/OmniChannel/Messages/MessagesHeader/styles';

export const HeaderWrapper = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    flex-direction: column;

    ${Wrapper} {
      padding: ${space.xl} ${space.lg};
    }
  `}
`;

export const Description = styled.p`
  ${({ theme: { colors, font, typography } }: ThemeProps) => css`
    ${typography.subtitle.Medium16}
    font-weight: ${font.weight.bold};
    color: ${colors.neutral.gray1};
    margin-bottom: 0;
  `}
`;

export const InformationWrapper = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    flex-direction: column;
    height: calc(100% - 252px);
    overflow-y: auto;
    gap: ${space.lg};
    padding: ${space.lg};
  `}
`;

export const InfoBlock = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    flex-direction: column;
    gap: ${space.xs};
  `}
`;

export const Label = styled.strong`
  ${({ theme: { colors, font, typography } }: ThemeProps) => css`
    ${typography.subtitle.Medium14}
    font-weight: ${font.weight.bold};
    color: ${colors.neutral.black};
  `}
`;

export const Text = styled.span`
  ${({ theme: { colors, typography } }: ThemeProps) => css`
    ${typography.body.Medium14}
    color: ${colors.neutral.gray1};
  `}
`;

export const AttendingHoursWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
