/* eslint-disable no-debugger, react/prop-types */
import React from 'react';
import formatDatetime from 'core/utils/formatDatetime';

const userRoles = {
  master: 'Diretor(a)',
  admin: 'Administrador(a)',
  coordinator: 'Coordenador(a)',
  secretariat: 'Secretariado(a)',
  teacher: 'professor(a)',
  assistant: 'Assistente',
  advisor: 'Assessor(a)',
  developer: 'Desenvolvedor(a)',
  financial: 'Financeiro',
  financial_assistant: 'Assistente financeiro',
  pearson: 'Pearson',
  representative: 'Representante',
  support: 'Suporte',
  salesman: 'Vendedor(a)',
};

const Date = ({ attributes }) => {
  const formattedDate = formatDatetime(
    attributes.created_at,
    'DD/MM/YYYY HH:mm:ss'
  );

  return <b>{formattedDate}</b>;
};

const Event = ({ attributes }) => <b>{attributes.event}</b>;

const Name = ({ attributes }) => <b>{attributes.whodunnit_name}</b>;

const Role = ({ attributes }) => <b>{userRoles[attributes.whodunnit_role]}</b>;

export default [
  {
    Header: 'Data/hora',
    accessor: Date,
  },
  {
    Header: 'Usuário',
    accessor: Name,
  },
  {
    Header: 'Perfil',
    accessor: Role,
  },
  {
    Header: 'Interação',
    accessor: Event,
  },
];
