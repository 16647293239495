const normalizeParams = (values) => {
  const data = new FormData();
  const students = values.classroom_with_student_profile_ids;

  data.append('events_event_form[kind]', values.kind);
  data.append('events_event_form[sent_kind]', values.sent_kind);
  data.append('events_event_form[title]', values.title);
  data.append('events_event_form[date]', values.date);
  data.append('events_event_form[start_at]', values.start_at);
  data.append('events_event_form[end_at]', values.end_at);
  data.append('events_event_form[location]', values.location);
  data.append('events_event_form[description]', values.description);
  data.append('events_event_form[persona_type]', values.persona_type);
  data.append('events_event_form[has_conference]', values.has_conference);

  if (values.image && values.image.uploaded === false) {
    data.append('events_event_form[image]', values.image, values.image.name);
  }

  if (values.attachments) {
    values.attachments.filter(file => !file.attached).forEach((attachment) => {
      data.append('events_event_form[attachments][]', attachment.signed_id);
    });
  }

  if (values.google_oauth_token) {
    data.append('events_event_form[google_oauth_token]', values.google_oauth_token);
  }

  if (values.conference_link) {
    data.append('events_event_form[conference_link]', values.conference_link);
  }

  if (values.notify) {
    data.append('events_event_form[notify]', values.notify);
  }

  if (values.confirm_until) {
    data.append('events_event_form[confirm_until]', values.confirm_until);
  }


  values.classroom_ids.forEach((classroomId) => {
    data.append('events_event_form[classroom_ids][]', classroomId);
  });

  Object.keys(students).forEach(classroomId => {
    students[classroomId].forEach(studentId => {
      data.append(
        `events_event_form[classroom_with_student_profile_ids][${classroomId}][]`,
        studentId,
      );
    });
  });

  return data;
};

export default normalizeParams;
