import React from 'react';
import getHocName from 'core/utils/getHocName';

export default function tabify(props) {
  return (WrappedComponent) => {
    const { title, icon } = props;

    const Tabify = (props) => <WrappedComponent {...props} />;

    Tabify.title = title;
    Tabify.icon = icon;

    Tabify.displayName = getHocName('Tabify', WrappedComponent);

    return Tabify;
  };
}
