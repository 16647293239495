import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Lottie from 'react-lottie';
import completedTaskAnimation from './anim/task_completed.json';
import PropTypes from 'prop-types';
import OnboardingEmptyState from '../../../components/EmptyState/Onboarding';
import Button from 'components/Button';
import Loader from 'components/Loader';
import {
  tasks,
  taskProfile,
  taskText,
  taskRedirectUrl,
  IMAGES_URLS,
  taskContextText,
} from './constants';
import withWindowSize from 'core/hoc/withWindowSize';
import onboardingActions from 'store/onboarding/actions';
import { trackEvent } from 'config/amplitude';
import * as S from './styles';

const HomeOnboardingPanel = ({ windowSize }) => {
  const { isLoading, schoolOnboardings, isOnboardingCompleted } = useSelector(
    (state) => state.onboarding
  );

  const dispatch = useDispatch();

  const { fetchSchoolOnboardings } = onboardingActions;

  const { taskThumbUrl } = IMAGES_URLS;

  const EMPTY_STATE_IMG_URL = isOnboardingCompleted
    ? IMAGES_URLS.emptyStateDoneUrl
    : IMAGES_URLS.emptyStateProgressUrl;

  const EMPTY_STATE_TITLE = isOnboardingCompleted
    ? `Parabéns, tarefas concluídas com sucesso!`
    : `Bem-vindo à Agenda Edu`;

  const EMPTY_STATE_TEXT = isOnboardingCompleted
    ? `Lembre-se: Sua jornada está só começando! Ainda há muitas
    ferramentas para explorar e aprender. Em caso de dúvidas, acesse
    um de nossos canais de suporte e fale conosco:`
    : `
    Essa é a página inicial, onde você pode visualizar informações importantes sobre o
    conteúdo da Agenda Edu. Para começar, você pode realizar essas 3 tarefas:
  `;

  const getSchoolOnboardings = useCallback(() => {
    dispatch(fetchSchoolOnboardings());
  }, [dispatch, fetchSchoolOnboardings]);

  const openHelpPopover = () => {
    const popovers = document.getElementsByClassName('balloon-popover');

    if (popovers.length) {
      windowSize >= 992 ? popovers[1].click() : popovers[0].click();
    }
  };

  const listOnboardingTasks = () => {
    if (schoolOnboardings.id) {
      const { completed_tasks } = schoolOnboardings.attributes;

      return tasks.map((task) => {
        let taskStatus = completed_tasks[task.profile].status;
        let taskContext = completed_tasks[task.profile].context || 'default';

        return {
          ...task,
          thumbUrl: taskThumbUrl[task.profile][taskStatus],
          text:
            taskText[task.profile][taskStatus] + taskContextText[taskContext],
          status: taskStatus,
          action: () => {
            trackEvent('empty-state-home', {
              actionTarget: taskProfile[task.profile],
            });

            window.location.href = taskRedirectUrl[task.profile];
          },
        };
      });
    }

    return [];
  };

  useEffect(() => {
    getSchoolOnboardings();
  }, [getSchoolOnboardings]);

  return (
    <S.CardContainer id="HomeOnboardingPanel">
      <OnboardingEmptyState
        imgUrl={EMPTY_STATE_IMG_URL}
        title={EMPTY_STATE_TITLE}
        text={EMPTY_STATE_TEXT}
        spaceBetween
        buttons={
          isOnboardingCompleted && (
            <Button onClick={openHelpPopover}>Central de ajuda</Button>
          )
        }
      />

      {!isOnboardingCompleted && (
        <Loader isLoading={isLoading}>
          <S.PanelTasksContainer>
            <S.PanelTasks>
              {listOnboardingTasks().map((task) => (
                <S.PanelTasksCard key={task.profile} status={task.status}>
                  <S.TaskThumbnail>
                    {task.status == 'finished' ? (
                      <div className="animated-task-thumbnail">
                        <Lottie
                          width="100%"
                          height="100%"
                          options={{
                            loop: false,
                            autoplay: true,
                            animationData: completedTaskAnimation,
                            rendererSettings: {
                              preserveAspectRatio: 'xMidYMid slice',
                            },
                          }}
                        />
                      </div>
                    ) : (
                      <img src={task.thumbUrl} />
                    )}
                  </S.TaskThumbnail>
                  <S.Heading status={task.status}>{task.heading}</S.Heading>
                  <S.Paragraph status={task.status}>{task.text}</S.Paragraph>
                  {task.status === 'in_progress' && (
                    <Button onClick={task.action}>{task.buttonText}</Button>
                  )}
                </S.PanelTasksCard>
              ))}
            </S.PanelTasks>
          </S.PanelTasksContainer>
        </Loader>
      )}
    </S.CardContainer>
  );
};

HomeOnboardingPanel.propTypes = {
  windowSize: PropTypes.number.isRequired,
};

export default withWindowSize(HomeOnboardingPanel);
