import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import withFormContext, { formPropTypes } from 'core/hoc/withFormContext';
import { DISCOUNT_DAYS_BEFORE_EXPIRATION } from 'core/constants';

import Delete from 'components/Delete';
import Field from 'components/Form/Field';
import FormValidationErrors from 'components/Form/ValidationErrors';

import * as S from './styles';

const clean = (number) => {
  if (typeof number === 'number') return number;

  return Number(number.toString().replace(/[^0-9]/g, ''));
};

const NewDiscount = ({
  attributeForm,
  counter,
  disabled,
  discountValues,
  index,
  onChange,
  removeDiscount,
  isNumericField = false,
  formContext: { hasErrorOnAttribute, form },
}) => {
  const { t } = useTranslation(['payments', 'common']);

  const hasError = hasErrorOnAttribute(attributeForm);

  const updateValues = (attributeName, form) => {
    let newValues = discountValues;

    if (attributeName === 'discount_kind') {
      newValues[attributeName] = form[attributeName];
      newValues.discount_value = 0;
    } else if (attributeName === 'days_before_expire') {
      newValues[attributeName] = clean(form[attributeName]);
    } else {
      newValues[attributeName] = form[attributeName];
    }

    onChange(index, newValues);
  };

  return (
    <>
      {discountValues && (
        <S.EdupayDiscountWrapper>
          <S.ContainerWrapper>
            <S.TitleWrapper>
              <S.Title>
                {counter}. {t(`form_section.punctuality_discount`)}
              </S.Title>
              {!disabled && index !== 0 && (
                <S.DeleteButtonWrapper>
                  <Delete
                    variation="danger"
                    onClick={() => removeDiscount(index)}
                  />
                </S.DeleteButtonWrapper>
              )}
            </S.TitleWrapper>
            <S.EdupayDiscount>
              <S.Text>{t(`form_section.discount_from`)}</S.Text>
              <S.TypeDiscountWrapper>
                <Field
                  type="textWithSelect"
                  attributeName="discount_value"
                  selectAttributeName="kind"
                  controlled
                  defaultValue={discountValues.discount_value}
                  defaultSelectValue={discountValues.kind}
                  disabled={disabled}
                  onChange={(formState) =>
                    updateValues('discount_value', formState)
                  }
                  onChangeSelect={(formState) =>
                    updateValues('kind', formState)
                  }
                />
              </S.TypeDiscountWrapper>
              <S.Text>{t(`form_section.paid_by`)}</S.Text>
              {isNumericField ? (
                <S.QuantityField>
                  <Field
                    type="number"
                    controlled
                    attributeName="days_before_expire"
                    onChange={(formState) =>
                      updateValues('days_before_expire', formState)
                    }
                    value={discountValues.days_before_expire}
                    disabled={disabled}
                  />
                </S.QuantityField>
              ) : (
                <S.SelectedDays
                  withArrowSelect
                  type="selectWithIcon"
                  attributeName="days_before_expire"
                  value={discountValues.days_before_expire}
                  options={DISCOUNT_DAYS_BEFORE_EXPIRATION}
                  onChange={(e) => {
                    updateValues('days_before_expire', {
                      ...form,
                      ['days_before_expire']: e.value,
                    });
                  }}
                  disabled={disabled}
                  fullWidth
                />
              )}

              <S.Text>
                {isNumericField
                  ? t(`form_section.before_day`)
                  : t(`form_section.current_day`)}
              </S.Text>
            </S.EdupayDiscount>
          </S.ContainerWrapper>
          {hasError && <FormValidationErrors attributeName={attributeForm} />}
        </S.EdupayDiscountWrapper>
      )}
    </>
  );
};

NewDiscount.propTypes = {
  removeItem: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  discountValues: PropTypes.shape({
    discount_value: PropTypes.number,
    kind: PropTypes.string,
    days_before_expire: PropTypes.number,
  }).isRequired,
  index: PropTypes.number.isRequired,
  ...formPropTypes,
};

export default withFormContext(NewDiscount);
