import React from 'react';
import { useSelector } from 'react-redux';

import withFormContext, { formPropTypes } from 'core/hoc/withFormContext';
import tabifyForWizard from 'core/hoc/tabifyForWizard';
import { titleMobile } from 'core/utils/changeText';

import FormFieldset from 'components/Form/Fieldset';
import Field from 'components/Form/Field';

import AgendaIcon from 'components/AgendaIcon';
import Tooltip, { TOP_CENTER } from 'components/Tooltip';

import * as S from './styles';
import validations, { handleReportsTitles } from './validations';
import { useEffect } from 'react';

const ReportFormInformationsTab = () => {
  const { reportsTitles } = useSelector((state) => state.reports);

  const radios = [
    {
      labelText: 'Sim',
      value: 'true',
    },
    {
      labelText: 'Não',
      value: 'false',
    },
  ];

  useEffect(() => {
    if (reportsTitles.length) {
      handleReportsTitles(reportsTitles);
    }
  }, [reportsTitles]);

  const renderDescriptionLabel = () => (
    <>
      <span>Descrição*</span>
      <Tooltip content="Digite até 160 caracteres" position={TOP_CENTER}>
        <AgendaIcon name="help" size="small" className="custom-size" />
      </Tooltip>
    </>
  );

  return (
    <div>
      <FormFieldset>
        <div className="row">
          <Field
            label="Título*"
            placeholder="ex. Relatório de Engajamento"
            attributeName="title"
          />
        </div>
        <div className="row">
          <Field
            type="simpleTextArea"
            rows={window.innerWidth > 834 ? 1 : 2}
            label={renderDescriptionLabel()}
            placeholder="ex. Aqui acompanhamos família, responsáveis e alunos."
            attributeName="description"
          />
        </div>
        <div className="ReportRadioGroup">
          <S.RadioButtonGroupReport
            label="É premium?*"
            testId="radio_buttons"
            type="radio"
            attributeName="premium"
            radios={radios}
            disabled={false}
          />
        </div>
        <div className="row">
          <div className="col-xs-12">
            <S.Info>*Campos obrigatórios</S.Info>
          </div>
        </div>
      </FormFieldset>
    </div>
  );
};

ReportFormInformationsTab.propTypes = {
  ...formPropTypes,
};

export default tabifyForWizard({
  title: titleMobile('1 Informações'),
  subTitle: 'Informações gerais',
  validations,
})(withFormContext(ReportFormInformationsTab));
