import React from 'react';
import InviteAccountModal from 'components/Invite/InviteAccountModal';
import reduxProvider from 'core/hoc/reduxProvider';
import { IMAGES_INVITE_URL } from 'core/constants';
import { useTranslation } from 'react-i18next';

const InviteStudentsModalScreen = (): JSX.Element => {
  const { t } = useTranslation(['register_account']);

  return (
    <div id="InviteStudentsModal" className="InviteStudentsModalScreen">
      <InviteAccountModal
        thumbUrl={IMAGES_INVITE_URL.inviteStudentsThumbnail}
        resource={'student'}
        title={t('students.invite_modal_title')}
        text={t('students.empty_state_text')}
      />
    </div>
  );
};

export default reduxProvider(InviteStudentsModalScreen);
