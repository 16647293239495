import React from 'react';
import ToggleSwitch from 'components/Form/ToggleSwitch';
import Field from 'components/Form/Field';
import Label from 'components/Form/Label';
import withFormContext, { formPropTypes } from 'core/hoc/withFormContext';
import FormValidationErrors from 'components/Form/ValidationErrors';
import { phoneMask } from 'core/utils/masks.js';
import { useTranslation } from 'react-i18next';
import { defaultOptionsState } from './constants';
import * as S from './styles';

const StudentAddressForm = ({
  formContext: { form, formMeta, updateAttribute, hasErrorOnAttribute },
}) => {
  const { t } = useTranslation(['student_address']);

  const { financial } = form;
  const { state_options } = formMeta;

  const opstionsState =
    state_options?.lenght > 0 ? state_options : defaultOptionsState;

  const hasErrorOnPhoneField = hasErrorOnAttribute('phone');

  const toggleSwitch = () => {
    updateAttribute('financial', !financial);
  };

  const onChangeState = (event) => {
    updateAttribute('state', event.value);
  };

  const onPhoneChange = (e) => {
    updateAttribute('phone', phoneMask(e.target.value));
  };

  const onPhoneKeyUp = (e) => {
    e.target.value = phoneMask(e.target.value);
  };

  return (
    <S.Wrapper>
      <S.ToggleWrapper>
        <ToggleSwitch
          testID="financial"
          toggled={financial}
          onChange={toggleSwitch}
        />
        <p>{t('form.financial_student')}</p>
      </S.ToggleWrapper>

      {financial && (
        <React.Fragment>
          <div className="row">
            <S.InputsWrapper>
              <Field
                label="CPF"
                type="masked"
                mask="999.999.999-99"
                placeholder=""
                attributeName="document_number"
              />
              <S.Field>
                <Label>Telefone</Label>
                <div className="TextField">
                  <input
                    className="form-control"
                    type="text"
                    placeholder=""
                    attributeName="phone"
                    onKeyUp={onPhoneKeyUp}
                    onChange={onPhoneChange}
                    value={form['phone']}
                  />
                  {hasErrorOnPhoneField && (
                    <FormValidationErrors attributeName={'phone'} multiple />
                  )}
                </div>
              </S.Field>
            </S.InputsWrapper>
          </div>

          <div className="row">
            <S.InputsWrapper variation="col1x3">
              <Field
                label="CEP"
                placeholder=""
                type="masked"
                mask="99999-999"
                attributeName="cep"
              />
              <Field label="Endereço" placeholder="" attributeName="street" />
              <Field
                label="Número"
                placeholder=""
                attributeName="street_number"
              />
            </S.InputsWrapper>
          </div>

          <div className="row">
            <S.InputsWrapper>
              <Field
                label="Complemento"
                placeholder=""
                attributeName="complement"
              />
              <Field
                label="Bairro"
                placeholder=""
                attributeName="neighborhood"
              />
            </S.InputsWrapper>
          </div>

          <div className="row">
            <S.InputsWrapper variation="col4x1">
              <Field label="Cidade" placeholder="" attributeName="city" />
              <Field
                fullWidth
                withArrowSelect
                label="Estado"
                type="selectWithIcon"
                attributeName="state"
                value={form.state}
                defaultValue={form?.state ? form.state : ''}
                onChange={onChangeState}
                options={opstionsState?.map((state) => ({
                  label: state.name,
                  value: state.name,
                }))}
                placeholder="Selecione"
              />
            </S.InputsWrapper>
          </div>
        </React.Fragment>
      )}
    </S.Wrapper>
  );
};

StudentAddressForm.propTypes = {
  ...formPropTypes,
};

export default withFormContext(StudentAddressForm);
