import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { defaultIsOutsideRange } from 'core/utils/date';
import tabifyForWizard from 'core/hoc/tabifyForWizard';
import { FINANCIAL_RESPONSIBLE_OPTIONS } from 'core/constants';

import Alert from 'components/Alert';
import CheckPaymentMethods from 'components/Form/CheckPaymentMethods';
import EdupayDiscount from 'components/Payments/PaymentsContainer/FormSections/EdupayDiscount';
import Field from 'components/Form/Field';
import RadioButtonWithIcon from 'components/Form/RadioButtonWithIcon';
import FormCheckbox from 'components/Form/Checkbox';
import FinesAndInterest from 'components/Payments/Recurrent/RecurrentFormSections/FinesAndInterest';
import FormFieldset from 'components/Form/Fieldset';
import Title from 'components/WizardForm/Title';
import ToggleBatch from 'components/Payments/Recurrent/EditRecurrentBillInBatches/ToggleBatch';
import withFormContext from 'core/hoc/withFormContext';
import EditInBatchSummary from 'components/Payments/PaymentsContainer/FormSections/EditInBatchSummary';
import WalletConfiguration from 'components/Payments/PaymentsContainer/FormSections/WalletConfiguration';

import validations from './validations';

import { EditRecurrentBillInBatchesTabProps, RootState } from './types';
import * as S from './styles';

const EditRecurrentBillInBatchesTab = ({
  formContext: {
    updateAttribute,
    form: {
      toggleBatchDiscount,
      toggleBatchPenality,
      toggleBatchPayment,
      toggleBatchDueDate,
      toggleBatchWallet,
      toggleBatchFinancialResponsible,
      auto_generate_pix: autoGeneratePix,
      toggle_discount: toggleDiscount,
      toggle_penality: togglePenality,
      allowed_payment_method: allowedPaymentMethod,
    },
    formMeta: {
      select_options: { recipient_wallet_id: recipientWalletId },
    },
  },
}: EditRecurrentBillInBatchesTabProps) => {
  const canHaveEdupayDiscount = (paymentMethod: string): boolean =>
    ['billet', 'pix', 'credit_card'].includes(paymentMethod);

  const {
    policies: { can_config_recurrent_plan_payment_methods },
  } = useSelector((state) => state.root);

  const headerInformation = `Ao habilitar os campos para ediçāo, novas informações deverāo ser
    inseridas. Os campos desabilitados nāo sofreram alterações.`;

  const removedUnusedInfoDescription = `O campo estando desabilitado, as informações inseridas
    anteriormente serāo removidas. Para inserir novas informações,
    o campo precisa estar habilitado.`;

  useEffect(() => {
    if (!allowedPaymentMethod.includes('pix')) {
      return updateAttribute('auto_generate_pix', false);
    }
    return updateAttribute('auto_generate_pix', true);
  }, [allowedPaymentMethod, updateAttribute]);

  return (
    <FormFieldset>
      <S.Wrapper>
        <S.AlertWrapper>
          <Title name="Habilitar campos" />

          <Alert variation="info">{headerInformation}</Alert>

          <Alert variation="info">
            <S.FinancialResponsibleInformation>
              Apenas cobranças <b>não enviadas</b> poderão ter seus
              destinatários alterados.
            </S.FinancialResponsibleInformation>
          </Alert>
        </S.AlertWrapper>

        <ToggleBatch
          title="Destinatário"
          attributeName="toggleBatchFinancialResponsible"
          subComponent="send_financial_responsible"
          initialValue={false}
        />
        <RadioButtonWithIcon
          options={FINANCIAL_RESPONSIBLE_OPTIONS}
          attributeName="send_financial_responsible"
          disabled={!toggleBatchFinancialResponsible}
          isRecurrentBillInBatches
        />

        <ToggleBatch
          title="Vencimento"
          attributeName="toggleBatchDueDate"
          subComponent="due_date"
          initialValue={null}
        />
        <Field
          type="date"
          attributeName="due_date"
          placeholder="Selecionar dia do vencimento"
          disabled={!toggleBatchDueDate}
          isOutsideRange={defaultIsOutsideRange}
        />

        <ToggleBatch
          title="Métodos de pagamento"
          attributeName="toggleBatchPayment"
          subComponent="allowed_payment_method"
          initialValue={[]}
        />
        <CheckPaymentMethods
          attributeName="allowed_payment_method"
          withPix
          disabled={
            !toggleBatchPayment || !can_config_recurrent_plan_payment_methods
          }
          canConfigPaymentMethods={can_config_recurrent_plan_payment_methods}
        />

        <ToggleBatch
          title="Carteiras"
          attributeName="toggleBatchWallet"
          subComponent="recipient_wallet_id"
        />
        <WalletConfiguration
          isRecurrent
          disabled={!toggleBatchWallet}
          isEditBillInBatches
        />

        <ToggleBatch
          title="Multas e juros"
          attributeName="toggleBatchPenality"
          subComponent="toggle_penality"
          initialValue={false}
        />
        {toggleBatchPenality && (
          <>
            <FinesAndInterest withoutTitle />
            {!togglePenality && (
              <S.AlertWrapper>
                <Alert variation="info">{removedUnusedInfoDescription}</Alert>
              </S.AlertWrapper>
            )}
          </>
        )}

        {allowedPaymentMethod.some(canHaveEdupayDiscount) && (
          <>
            <ToggleBatch
              title="Desconto de pontualidade"
              attributeName="toggleBatchDiscount"
              subComponent="toggle_discount"
              initialValue={false}
            />
            {toggleBatchDiscount && (
              <>
                <EdupayDiscount
                  type="bill"
                  withoutTitle
                  validations={validations}
                  showCreditCardDiscount
                />
                {!toggleDiscount && (
                  <S.AlertWrapper>
                    <Alert variation="info">
                      {removedUnusedInfoDescription}
                    </Alert>
                  </S.AlertWrapper>
                )}
              </>
            )}
          </>
        )}
        <EditInBatchSummary />
      </S.Wrapper>
    </FormFieldset>
  );
};

export default tabifyForWizard({
  title: '1. Informações',
  validations,
})(withFormContext(EditRecurrentBillInBatchesTab));
