export default class MemoryStorage {
  setLocalStorageItem = (key: string, value: undefined) => {
    window.localStorage.setItem(key, JSON.stringify(value));
  };

  getLocalStorageItem = (key: string) => {
    const storedItem = JSON.parse(window.localStorage.getItem(key));
    return storedItem;
  };

  removeLocalStorageItem = (key: string) => {
    const removedItem = window.localStorage.removeItem(key);
    return removedItem;
  };
}
