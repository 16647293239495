import { Chat } from 'store/messages/omniChannel/types';

export const normalizeRedirectMessage = ({ chats, messageBot, messages }) => {
  const newChats = chats.map((chat: Chat) => {
    if (chat.id !== messageBot.relationships.chat.data.id) {
      return chat;
    } else {
      return {
        ...chat,
        attributes: {
          ...chat.attributes,
          last_message_preview: messageBot.attributes.text,
          last_message_sent_at: messageBot.attributes.sentAt,
          read_status: 'answered',
        },
      };
    }
  });

  const newMessages = [messageBot, ...messages];

  return { chats: newChats, messages: newMessages };
};
