import { ThemeProps } from 'config/themes/types';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme: { font, colors, space } }: ThemeProps) => css`
    .LabeledContainer {
      margin-right: ${space.xs};
      font-family: ${font.family.brand};
      font-weight: ${font.weight.medium};
      .detail-header {
        font-size: ${font.size.xs};
        color: ${colors.neutral.gray2};
      }
      .information-content {
        font-size: ${font.size.md};
        color: ${colors.neutral.gray1};
      }
      margin-bottom: 0;
      p {
        margin-bottom: 0;
      }
    }
  `}
`;

export const NegotiationsCount = styled.div`
  ${({ theme: { font, colors, space } }: ThemeProps) => css`
    margin-bottom: ${space.xs2};
    margin-top: ${space.xs2};
    p {
      font-family: ${font.family.brand};
      font-weight: ${font.weight.medium};
      font-size: ${font.size.sm};
      color: ${colors.neutral.gray1};
      text-align: right;
    }
  `}
`;

export const AccordionContainer = styled.div`
  ${({ theme: { shadow, colors, border, space, font } }: ThemeProps) => css`
    margin-bottom: ${space.xs};
    box-shadow: ${shadow.card};
    border-radius: ${border.radius.md};

    .DisabledText {
      .detail-title,
      .information-content {
        color: ${colors.neutral.gray3};
      }
    }
    .NegotiationCard:not(:last-child) {
      border-radius: 0;
      border-bottom: ${border.width.sm} ${border.style.solid}
        ${colors.neutral.gray5};
    }
    .Accordion .accordion .accordion__item .DropdownButton {
      left: 0;
      a {
        border: none;
        i {
          font-size: ${font.size.xl};
        }
      }
      .Button {
        margin-top: 0;
      }
    }
    .Accordion
      .accordion
      .accordion__item
      .accordion__heading
      .NegotiationCard {
      padding-left: 0;
    }
    .Accordion
      .accordion
      .accordion__item
      .accordion__heading
      .accordion__button {
      background-color: ${colors.neutral.white};
      border: ${border.width.none};
      height: 100%;
      padding: 0;
      &:before {
        position: relative;
        left: calc(100% - 20px);
      }
    }
    .Accordion .accordion .accordion__panel {
      padding: 0;
      border: none;
      border-top: ${border.width.sm} ${border.style.solid}
        ${colors.neutral.gray3};
      .dropdown.show > a {
        background-color: ${colors.neutral.white};
      }
    }
  `}
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const AlertContainer = styled.div`
  .Alert.danger {
    height: 100%;
  }
`;
