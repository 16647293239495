const actions = {
  ERROR: 'ERROR/events',
  ADD_EVENT: 'ADD_EVENT/events',
  ADD_EVENT_SUCCESS: 'ADD_EVENT_SUCCESS/events',
  SET_EVENT_REQUEST: 'SET_EVENT_REQUEST/events',
  SET_EVENT_SUCCESS: 'SET_EVENT_SUCCESS/events',
  SET_CLASSROOMS_REQUEST: 'SET_CLASSROOMS_REQUEST/events',
  SET_CLASSROOMS_SUCCESS: 'SET_CLASSROOMS_SUCCESS/events',
  EDIT_EVENT: 'EDIT_EVENT/events',
  EDIT_EVENT_SUCCESS: 'EDIT_EVENT_SUCCESS/events',
  addEvent: params => ({ type: actions.ADD_EVENT, params }),
  setEvent: id => ({ type: actions.SET_EVENT_REQUEST, id }),
  setClassrooms: () => ({ type: actions.SET_CLASSROOMS_REQUEST }),
  editEvent: (id, params) => ({ type: actions.EDIT_EVENT, id, params }),
};

export default actions;
