import { all, call, put, takeLatest } from 'redux-saga/effects';
import { postApi, fetchApi, deleteApi, putApi } from 'core/utils/api';
import { push } from 'connected-react-router';
import actions from './actions';
import {
  buildToast,
  toastCss,
  ToastTypes,
} from 'store/middlewares/toast/actions';

export function* getListPeriodsSagas() {
  try {
    const { data } = yield call(fetchApi, `/schools/school_terms.json`);
    yield put({
      type: actions.GET_PERIODS_SUCCESS,
      data,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        'Erro ao listar períodos.',
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* getListClassroomsSagas() {
  try {
    const { history } = yield call(
      fetchApi,
      `/schools/messages/classrooms.json`
    );

    yield put({
      type: actions.GET_CLASSROOMS_SUCCESS,
      data: history,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        'Erro ao listar turmas.',
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* saveNewPeriodSagas(action) {
  try {
    yield call(postApi, `/schools/school_terms/`, action.form);

    yield put({
      type: actions.SAVE_NEW_PERIOD_SUCCESS,
      toast: buildToast(
        'Período criado com sucesso',
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });

    window.location.href = '/schools/school_terms';
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        'Erro ao criar novo período',
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* deletePeriodSagas(action) {
  try {
    yield call(deleteApi, `/schools/school_terms/${action.periodId}`);

    yield put({
      type: actions.DELETE_PERIOD_SUCCESS,
      periodId: action.periodId,
      toast: buildToast(
        'Período deletado com sucesso',
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        'Erro ao deletar período',
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* getPeriodDetailsSagas(action) {
  try {
    const { data } = yield call(
      fetchApi,
      `/schools/school_terms/${action.periodId}.json`
    );

    // if period is current activated, change classroom
    if (data.attributes.status) {
      yield put({
        type: actions.GET_ACTIVATED_PERIOD_CLASSROOMS,
      });
    }

    yield put({
      type: actions.GET_PERIOD_DETAILS_SUCCESS,
      data,
      isLoadingEdit: data.attributes.status ? true : false,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        'Erro ao obter detalhes do período',
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* getClassroomsActivatedPeriodSagas() {
  try {
    const { history } = yield call(
      fetchApi,
      `/schools/classrooms/list_classrooms_from_last_school_term_active.json`
    );

    yield put({
      type: actions.GET_ACTIVATED_PERIOD_CLASSROOMS_SUCCESS,
      data: history,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        'Erro ao obter turmas do período ativo.',
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* saveEditPeriodSagas(action) {
  try {
    yield call(putApi, `/schools/school_terms/${action.periodId}`, action.form);

    yield put({
      type: actions.SAVE_EDIT_PERIOD_SUCCESS,
      toast: buildToast(
        'Período atualizado com sucesso',
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });

    window.location.href = '/schools/school_terms';
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        'Erro ao atualizar período',
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* activatePeriodSagas(action) {
  try {
    yield call(
      putApi,
      `/schools/school_terms/${action.periodId}/activate.json`
    );

    yield put({
      type: actions.ACTIVATE_PERIODS_SUCCESS,
      toast: buildToast(
        'O novo período letivo foi ativado com sucesso, agora você pode realizar a progressão dos alunos.',
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });

    window.location.href = '/schools/school_terms';
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        'Erro ao ativar período',
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* schoolTermsSagas() {
  yield all([
    takeLatest(actions.GET_PERIODS, getListPeriodsSagas),
    takeLatest(actions.GET_CLASSROOMS, getListClassroomsSagas),
    takeLatest(actions.SAVE_NEW_PERIOD, saveNewPeriodSagas),
    takeLatest(actions.DELETE_PERIOD, deletePeriodSagas),
    takeLatest(actions.GET_PERIOD_DETAILS, getPeriodDetailsSagas),
    takeLatest(
      actions.GET_ACTIVATED_PERIOD_CLASSROOMS,
      getClassroomsActivatedPeriodSagas
    ),
    takeLatest(actions.SAVE_EDIT_PERIOD, saveEditPeriodSagas),
    takeLatest(actions.ACTIVATE_PERIODS, activatePeriodSagas),
  ]);
}

export default schoolTermsSagas;
