import { all, call, put, takeLatest } from 'redux-saga/effects';
import { postApi } from 'core/utils/api';
import actions from './actions';
import {
  buildToast,
  toastCss,
  ToastTypes,
} from 'store/middlewares/toast/actions';

export function* sendCodeEmail() {
  try {
    yield call(postApi, `/two_factors/send_verification_code`);

    yield put({
      type: actions.SEND_CODE_EMAIL_SUCCESS,
      toast: buildToast(
        'Um novo e-mail foi enviado com o código de verificação.',
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.UPDATE_TOGGLE,
    });
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        'Erro ao enviar código de verificação.',
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* activationTwoAuthFactor(action) {
  try {
    yield call(postApi, `/two_factors/enable`, { otp_code: action.otpCode });

    yield put({
      type: actions.ACTIVATION_TWO_AF_SUCCESS,
      toast: buildToast(
        'Dois fatores ativado com sucesso',
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.UPDATE_TOGGLE,
    });
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        `${error?.data?.errors || 'Erro ao ativar dois fatores.'}`,
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* deactivationTwoAuthFactor() {
  try {
    yield call(postApi, `/two_factors/disable`);

    yield put({
      type: actions.DEACTIVATION_TWO_AF_SUCCESS,
      toast: buildToast(
        'Dois fatores desabilitado com sucesso',
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.UPDATE_TOGGLE,
    });
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        `${error?.data?.errors || 'Erro ao desativar dois fatores.'}`,
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* twoAuthFactorSagas() {
  yield all([
    takeLatest(actions.SEND_CODE_EMAIL, sendCodeEmail),
    takeLatest(actions.ACTIVATION_TWO_AF, activationTwoAuthFactor),
    takeLatest(actions.DEACTIVATION_TWO_AF, deactivationTwoAuthFactor),
  ]);
}

export default twoAuthFactorSagas;
