import { ThemeProps } from 'config/themes/types';
import styled, { css } from 'styled-components';

export const SimpleSummaryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const BillAmountWrapper = styled.div`
  ${({ theme: { font, border, colors } }: ThemeProps) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: ${border.width.sm} solid ${colors.neutral.gray4};
    border-radius: ${border.radius.md};
    width: 100%;
    padding: 24px;

    p,
    span {
      margin: 0;
      font-size: ${font.size.md};
      font-weight: 700;
      color: ${colors.neutral.gray1};
    }
  `}
`;
