import React, { useCallback, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import Button from 'components/Button';
import Field from 'components/Form/Field';
import SideSheet from 'components/SideSheet';
import Accordion from 'components/Accordion';

import DailySummariesActions from 'store/dailySummaries/actions';

import * as S from './styles';

const DailySummariesSideSheetFilters = ({
  isOpen,
  onDismiss,
  handleFilter,
}) => {
  const dispatch = useDispatch();

  const [newHeadquarter, setNewHeadquarter] = useState(null);
  const [newEducationalStage, setNewEducationalStage] = useState(null);

  const FADE_OUT_DURATION = 250;

  const { headquarters, educationalStages } = useSelector(
    (state) => state.dailySummaries.formMeta
  );

  const {
    fetchHeadquarters,
    fetchEducationalStages,
    setHeadquarter,
    setEducationalStage,
  } = DailySummariesActions;

  const setHeadquartersFilter = useCallback(() => {
    dispatch(fetchHeadquarters());
  }, [dispatch, fetchHeadquarters]);

  const setEducationalStagesFilter = useCallback(
    (headquarterId) => {
      dispatch(fetchEducationalStages(headquarterId));
    },
    [dispatch, fetchEducationalStages]
  );

  const hasHeadquarters = () => headquarters && headquarters.length > 0;

  const hasEducationalStages = () =>
    hasHeadquarters() && newHeadquarter && educationalStages.length > 0;

  const applyFilters = () => {
    document
      .getElementById('side-sheet-wrapper')
      .classList.add('fade-out-animation');
    document.getElementById('side-sheet').classList.add('slide-out-animation');
    setTimeout(() => {
      handleFilter({});
      onDismiss();
    }, FADE_OUT_DURATION);
  };

  const handleApplyFilters = () => {
    dispatch(setHeadquarter(newHeadquarter));
    dispatch(setEducationalStage(newEducationalStage));
    applyFilters();
  };

  const handleClearFilters = () => {
    setNewHeadquarter(null);
    setNewEducationalStage(null);
    dispatch(setHeadquarter(null));
    dispatch(setEducationalStage(null));
    applyFilters();
  };

  const renderFiltersTitle = () => {
    return <S.AccordionTitle>Unidade/Segmento</S.AccordionTitle>;
  };

  const renderFiltersAccordionBody = () => {
    return (
      <>
        <Field
          attributeName="headquarter_selector"
          label="Unidade"
          type="selectWithIcon"
          placeholder="Selecione a unidade"
          value={newHeadquarter}
          options={headquarters}
          disabled={!hasHeadquarters()}
          onChange={(event) => {
            setNewEducationalStage(null);
            setNewHeadquarter(event.value);
            setEducationalStagesFilter(event.value);
          }}
          withArrowSelect
        />
        <Field
          attributeName="educational_stages"
          label="Segmento"
          type="selectWithIcon"
          placeholder="Selecione o segmento"
          value={newEducationalStage}
          options={educationalStages}
          disabled={!hasEducationalStages()}
          onChange={(event) => {
            setNewEducationalStage(event.value);
          }}
          withArrowSelect
        />
      </>
    );
  };

  useEffect(() => {
    setHeadquartersFilter();
  }, [setHeadquartersFilter]);

  return (
    <SideSheet isOpen={isOpen} onDismiss={onDismiss} title="Filtros">
      <S.FiltersContainer>
        <Accordion
          header={renderFiltersTitle()}
          body={renderFiltersAccordionBody()}
          allowZeroExpanded
          expanded="dashboard"
        />
        <S.ButtonRow>
          <Button variation="primary" onClick={handleApplyFilters}>
            Aplicar
          </Button>
          <Button variation="secondary" onClick={handleClearFilters}>
            Limpar
          </Button>
        </S.ButtonRow>
      </S.FiltersContainer>
    </SideSheet>
  );
};

DailySummariesSideSheetFilters.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onDismiss: PropTypes.func,
  handleFilter: PropTypes.func,
};

export default DailySummariesSideSheetFilters;
