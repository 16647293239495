const TASKHOME_OPTIONS = [
  {
    label: 'Sim',
    value: '1',
  },
  {
    label: 'Não',
    value: '0',
  },
];

const SUBMIT_MODE_OPTIONS = [
  {
    label: 'Sim',
    value: 'agenda_edu',
  },
  {
    label: 'Não',
    value: 'classroom',
  },
];

export { TASKHOME_OPTIONS, SUBMIT_MODE_OPTIONS };
