import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Text } from '@agendaedu/ae-web-components';
import { useTranslation } from 'react-i18next';

import * as S from './styles';

import tabifyForWizard from 'core/hoc/tabifyForWizard';
import withFormContext from 'core/hoc/withFormContext';

import actionMessages from 'store/messages/actions';
import { MessageState } from 'store/messages/types';

import Avatar from 'components/Avatar';
import FormSelector from 'components/Form/Selector';

import { GroupMembersStepSkeleton } from './skeleton';

import {
  GroupMembersStepProps,
  SchoolMembersSelected,
  SearchOptionsProps,
} from './types';

const GroupMembersStep = ({
  formContext: {
    form: { member_ids: schoolMembersIds },
    changeMeta,
    updateAttribute,
  },
}: GroupMembersStepProps) => {
  const { t } = useTranslation(['messages']);

  const dispatch = useDispatch();

  const { schoolUsers } = useSelector((state: MessageState) => state.messages);

  const { fetchSchoolUsersRequest } = actionMessages;

  const handlerSelectSchoolMemberIds = ({
    selectedValues,
  }: SchoolMembersSelected): void => {
    updateAttribute('member_ids', selectedValues);
  };

  const searchOptions = ({ options, searchTerm }: SearchOptionsProps) => {
    const filteredOptions = options.filter((option) =>
      option?.attributes.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return filteredOptions;
  };

  const renderOptionComponent = (option): React.ReactNode => {
    if (option) {
      return (
        <S.ItemWrapper>
          <Avatar user={option} />
          <Text color="neutral.black" variant="label-regular-14">
            {option.attributes.name}
          </Text>
        </S.ItemWrapper>
      );
    }
  };
  useEffect(() => {
    schoolMembersIds?.length > 0
      ? changeMeta('nextStepDisabled', false)
      : changeMeta('nextStepDisabled', true);
  }, [schoolMembersIds, changeMeta]);

  useEffect(() => {
    dispatch(fetchSchoolUsersRequest());
  }, [dispatch, fetchSchoolUsersRequest]);

  return (
    <S.GroupMembersStepWrapper>
      {schoolUsers?.length ? (
        <S.Wrapper>
          <Text
            marginTop={24}
            fontWeight={700}
            color="neutral.black"
            variant="title-bold-20"
          >
            {t('groups.forms.fields.select_members_label')}
          </Text>
          <div className="FormSelectorWrapper">
            <FormSelector
              newSearchField
              searchPlaceholder={t(
                'omni_channel.forms.message_channel.fields.search_placeholder_text'
              )}
              search={searchOptions}
              options={schoolUsers}
              selectedValues={schoolMembersIds}
              optionComponent={renderOptionComponent}
              onChange={handlerSelectSchoolMemberIds}
              optionAccessor={(optionAccessor) => optionAccessor.id}
              onSelectBehavior="highlight"
              selectAllLabel={t('groups.forms.fields.select_all_members_label')}
              counterLabel={(length) =>
                length === 1
                  ? t('groups.forms.fields.member_label')
                  : t('groups.forms.fields.members_label')
              }
            />
          </div>
        </S.Wrapper>
      ) : (
        <GroupMembersStepSkeleton />
      )}
    </S.GroupMembersStepWrapper>
  );
};

export default tabifyForWizard({
  title: '2 Profissionais',
})(withFormContext(GroupMembersStep));
