import React, { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { IMPORT_PROFILE_TYPES } from '../constants';
import PageContainer from 'components/PageContainer';
import Breadcrumb from 'components/Breadcrumb';
import Table from 'components/Table';
import Loader from 'components/Loader';
import EmptyState from 'components/EmptyState';
import Pagination from 'components/Pagination';
import importWebActions from 'store/importWeb/actions';
import { IMAGES_PAYMENT_URL } from 'core/constants/index';
import table from './table';
import * as S from './styles';

const ImportWebInconsistencies = ({ match }) => {
  const [page, setPage] = useState(1);
  const { importedInto, id } = match.params;
  const { isLoading, paginatedInconsistencies } = useSelector(
    (state) => state.importWeb
  );

  const { fetchImportsInconsistencies } = importWebActions;

  const dispatch = useDispatch();

  const handleFetchImportInconciestencies = useCallback(() => {
    dispatch(fetchImportsInconsistencies(importedInto, id, page));
  }, [dispatch, fetchImportsInconsistencies, importedInto, id, page]);

  const onPageChanged = useCallback((page) => {
    setPage(page);
  }, []);

  useEffect(() => {
    handleFetchImportInconciestencies();
  }, [handleFetchImportInconciestencies]);

  const renderTable = () => {
    if (paginatedInconsistencies.pagination.totalItemsCount > 0) {
      return (
        <S.TableWrapper>
          <Table columns={table} data={paginatedInconsistencies.data} />
          <Pagination
            onChange={onPageChanged}
            activePage={page}
            totalItemsCount={paginatedInconsistencies.pagination.totalItemsCount}
            itemsCountPerPage={20}
          />
        </S.TableWrapper>
      );
    } else {
      return (
        <EmptyState
          imgUrl={IMAGES_PAYMENT_URL.onboardingUrl}
          message={
            <p>Tudo certo! Não foi encontrada nenhuma inconsistência.</p>
          }
        />
      );
    }
  };

  const tableEntries = () => {
    return (
      <div className="TableEntries">
        <span>
          {paginatedInconsistencies.pagination.totalItemsCount ? (
            <>
              <b>{paginatedInconsistencies.pagination.totalItemsCount}</b> inconsistência(s)
              encontrada(s)
            </>
          ) : (
            <>Nenhuma inconsistência encontrada</>
          )}
        </span>
      </div>
    );
  };

  return (
    <S.Wrapper className="ImportWebInconsistencies">
      <PageContainer
        title={`Inconsistências`}
        header={tableEntries()}
        className="ImportWeb"
        breadcrumb={
          <Breadcrumb
            title={`Central de importação de ${IMPORT_PROFILE_TYPES[importedInto]}`}
            path={`/schools/import_webs/${importedInto}`}
            external
          />
        }
      >
        <Loader isLoading={isLoading}>{renderTable()}</Loader>
      </PageContainer>
    </S.Wrapper>
  );
};

ImportWebInconsistencies.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      importedInto: PropTypes.string,
      id: PropTypes.string,
    }),
  }),
};

export default ImportWebInconsistencies;
