import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { IMPORT_PROFILE_TYPES } from '../constants';
import FormFullScreen from 'components/FormFullScreen';
import InsertFileTab from '../FormTabs/InsertFileTab';
import InformationsTab from '../FormTabs/InformationsTab';
import inconsistenciesTab from '../FormTabs/InconsistenciesTab';
import OverviewTab from '../FormTabs/OverviewTab';
import importWebActions from 'store/importWeb/actions.js';
import withWindowPathname, {
  windowPropTypes,
} from 'core/hoc/withWindowPathname';
import Toast from 'components/Toast';
import * as S from '../styles';

const WIZARD_STEPS = [
  InsertFileTab,
  InformationsTab,
  inconsistenciesTab,
  OverviewTab,
];

const ImportWebNew = ({ windowPathname }) => {
  const dataArea = useSelector((state) => state.root.dataArea);
  const { form, formMeta, uploadedFile, isSubmitting, validatedFile } =
    useSelector((state) => state.importWeb);

  const importedInto = windowPathname.split('/')[3];

  const dispatch = useDispatch();

  const { startImport } = importWebActions;

  const handleStartImport = useCallback(() => {
    dispatch(
      startImport(
        importedInto,
        uploadedFile.signed_id,
        validatedFile.rows,
        validatedFile.rowErrors
      )
    );
  }, [dispatch, startImport, uploadedFile, validatedFile, importedInto]);

  const onSubmit = () => {
    handleStartImport();
  };

  return (
    <S.FormContainer className="ImportWebNew">
      <FormFullScreen
        action="new"
        titlePage={`Importar ${IMPORT_PROFILE_TYPES[importedInto]}`}
        titleModal="Descartar preenchimento"
        contentModal={`A importação de ${IMPORT_PROFILE_TYPES[importedInto]} será cancelada e as
          informações preenchidas serão descartadas.
          Tem certeza que deseja descartar?`}
        toggleModalWithFormUpdates
        onSubmit={onSubmit}
        form={form}
        formMeta={formMeta}
        isSubmitting={isSubmitting}
        steps={WIZARD_STEPS}
        finishText="Iniciar importação"
        backToExternal
        backTo={`/${dataArea}/import_webs/${importedInto}`}
      />
      <Toast />
    </S.FormContainer>
  );
};

ImportWebNew.propTypes = {
  ...windowPropTypes,
};

export default withWindowPathname(ImportWebNew);
