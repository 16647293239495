import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

function PageTitle({ variation, children }) {
  return (
    <h2 className={`PageTitle ${variation || ''}`}>
      {children}
    </h2>
  );
}

PageTitle.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func
  ]).isRequired,
  variation: PropTypes.oneOf([
    'small',
    'default',
    'medium',
    'large'
  ])
};

PageTitle.defaultProps = {
  variation: 'default',
};

export default PageTitle;
