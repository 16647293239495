import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import EmptyState from 'components/EmptyState';
import GroupCard from 'components/Messages/MessageGroup/GroupCard';
import { GroupCardSkeleton } from 'components/Messages/MessageGroup/GroupCard/skeleton';
import GroupChat from 'components/Messages/MessageGroup/GroupChat';
import GroupHeader from 'components/Messages/MessageGroup/GroupHeader';
import MessagesModal from 'components/Messages/Modals/MessagesModal';
import NotificationModal from 'components/Messages/Modals/NotificationModal';
import Toast from 'components/Toast';

import { MESSAGE_GROUP_IMAGES } from 'core/constants';
import withAppContext from 'core/hoc/withAppContext';
import useResizableSidebar from 'core/hooks/useResizableSidebar';

import actionMessages from 'store/messages/actions';
import { MessageState, GroupProps } from 'store/messages/types';

import { MessageGroupScreenProps } from './types';

import * as S from './styles';

const MessageGroupScreen = ({
  appContext: {
    isWebView,
    currentUser: { id: userId },
  },
}: MessageGroupScreenProps) => {
  const dispatch = useDispatch();

  const { sidebarRef, startResizing } = useResizableSidebar();

  const {
    groups,
    currentGroup,
    showModal,
    showNotificationModal,
    isLoading,
    isReadCurrentMessages,
  } = useSelector((state: MessageState) => state.messages);

  const {
    fetchGroupsRequest,
    setArchiveGroupRequest,
    setReadCurrentMessagesRequest,
    setMuteAndTurnOnNotificationRequest,
    toggleModal,
    toggleNotificationModal,
    socketConnectRequest,
    socketEnterGroupRoomRequest,
  } = actionMessages;

  const isEmpty = !isLoading && groups.length === 0;

  const handleToggleModal = useCallback(() => {
    dispatch(toggleModal());
  }, [dispatch, toggleModal]);

  const handleToggleNotificationModal = useCallback(() => {
    dispatch(toggleNotificationModal());
  }, [dispatch, toggleNotificationModal]);

  const handleArchiveGroup = useCallback((): void => {
    dispatch(setArchiveGroupRequest(currentGroup));
    handleToggleModal();
  }, [dispatch, setArchiveGroupRequest, handleToggleModal, currentGroup]);

  const handleSetReadCurrentMessages = useCallback(() => {
    dispatch(setReadCurrentMessagesRequest(currentGroup));
  }, [dispatch, setReadCurrentMessagesRequest, currentGroup]);

  const renderGroups = () => {
    if (isLoading) {
      return <GroupCardSkeleton />;
    } else if (groups.length > 0) {
      return groups.map((group: GroupProps) => (
        <GroupCard key={group.id} group={group} />
      ));
    } else {
      return (
        <EmptyState
          message="Não há grupos criados"
          imgUrl={MESSAGE_GROUP_IMAGES.no_groups}
        />
      );
    }
  };

  const handleMuteAndTurnOnNotification = useCallback(() => {
    if (currentGroup) {
      dispatch(setMuteAndTurnOnNotificationRequest(currentGroup));

      handleToggleNotificationModal();
    }
  }, [
    currentGroup,
    dispatch,
    setMuteAndTurnOnNotificationRequest,
    handleToggleNotificationModal,
  ]);

  const setModalType = () =>
    currentGroup && currentGroup.attributes.enable_sound ? 'mute' : 'active';

  useEffect(() => {
    dispatch(fetchGroupsRequest(userId));
  }, []);

  useEffect(() => {
    dispatch(socketConnectRequest());
  }, [dispatch, socketConnectRequest]);

  useEffect((): void => {
    if (isReadCurrentMessages) handleSetReadCurrentMessages();
  }, [handleSetReadCurrentMessages, isReadCurrentMessages]);

  useEffect((): void => {
    groups?.map((group: GroupProps) => {
      dispatch(socketEnterGroupRoomRequest(group?.id));
    });
  }, [dispatch, groups, socketEnterGroupRoomRequest]);

  return (
    <>
      <S.GroupWrapper isWebView={isWebView} currentGroup={!!currentGroup}>
        <S.GroupResizeWrapper>
          <S.GroupContainer id="resize-wrapper" ref={sidebarRef}>
            <GroupHeader />
            <S.GroupList empty={isEmpty}>{renderGroups()}</S.GroupList>
          </S.GroupContainer>
          <S.ResizeWindow id="resize-window" onMouseDown={startResizing} />
        </S.GroupResizeWrapper>

        <S.MessageContainer>
          {currentGroup ? (
            <GroupChat />
          ) : (
            <EmptyState
              message="Selecione um grupo e veja as mensagens aqui"
              imgUrl={MESSAGE_GROUP_IMAGES.message_info}
            />
          )}
        </S.MessageContainer>
      </S.GroupWrapper>
      <MessagesModal
        dataTestId="archive-group-modal"
        showModal={showModal}
        toggleModal={handleToggleModal}
        handleSubmit={handleArchiveGroup}
        title="Deseja arquivar este grupo?"
        description="O grupo será arquivado e todos os membros não terão mais acesso ao histórico de mensagens. Tem certeza que deseja arquivar?"
        submitButtonText="Arquivar"
      />
      <NotificationModal
        showModal={showNotificationModal}
        type={setModalType()}
        toggleModal={handleToggleNotificationModal}
        handleMuteAndTurnOnNotification={handleMuteAndTurnOnNotification}
      />
      <Toast />
    </>
  );
};

export default withAppContext(MessageGroupScreen);
