import createModuleReducer from '../../storeConfig/createModuleReducer';
import actions from './actions';

export const INITIAL_STATE = {
  error: null,
  isLoading: false,
  form: {
    invite_link: '',
  },
};

const generateInviteUrl = (state) => ({
  ...state,
  isLoading: true,
});

const generateInviteUrlSuccess = (state, action) => ({
  ...state,
  isLoading: false,
  form: {
    invite_link: action.url,
  },
});

const setError = (state, action) => ({
  ...state,
  error: action.error,
  isLoading: false,
});

const HANDLERS = {
  [actions.SET_ERROR]: setError,
  [actions.GENERATE_INVITE_URL]: generateInviteUrl,
  [actions.GENERATE_INVITE_URL_SUCCESS]: generateInviteUrlSuccess,
};

const inviteEmptyState = createModuleReducer(INITIAL_STATE, HANDLERS);

export default inviteEmptyState;
