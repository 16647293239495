import styled, { css } from 'styled-components';

import { ThemeProps } from 'config/themes/types';

export const InforWrapper = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    flex-direction: column;
    gap: ${space.lg};
  `}
`;

export const ToggleWrapper = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${space.xs};
  `}
`;

export const ButtonWrapper = styled.section`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: ${space.xs};

    > button {
      width: 100%;
    }
  `}
`;
