import styled, { css } from 'styled-components';
import ModalContainer from 'components/Modal/ModalContainer';

export const Wrapper = styled.div`
  display: flex;

  > .Button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
  }
`;

export const ResendEmailsModalWrapper = styled(ModalContainer)`
  .ReactModal__Content .icon-ae-close {
    margin-top: 0;
    font-size: 16px;
  }
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    font-family: 'Quicksand';
    font-size: 24px;
    font-style: normal;
    font-weight: bold;
    font-stretch: normal;
    line-height: 1;
    color: ${theme.black};
    margin-bottom: 24px;
  `}
`;

export const Description = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 24px;
`;
