import React from 'react';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';

import FormValidationErrors from 'components/Form/ValidationErrors';

import withFormContext, { formPropTypes } from 'core/hoc/withFormContext';

class MaskedField extends React.Component {
  /**
   * @returns {Function} The function that handles the event.
   */
  onChange() {
    const { onChange, attributeName, formContext } = this.props;
    const { changeAttribute } = formContext;

    if (onChange) {
      return changeAttribute(attributeName, (formState) => onChange(formState));
    }

    return changeAttribute(attributeName);
  }

  render() {
    const { attributeName, disabled, formContext, mask, placeholder } =
      this.props;

    const { form, hasErrorOnAttribute } = formContext;

    const hasError = hasErrorOnAttribute(attributeName);

    return (
      <div className="MaskedField">
        <InputMask
          disabled={disabled}
          type="text"
          className="form-control"
          mask={mask}
          maskChar={null}
          onChange={this.onChange()}
          value={form[attributeName]}
          placeholder={placeholder}
        />
        {hasError && <FormValidationErrors attributeName={attributeName} />}
      </div>
    );
  }
}

MaskedField.propTypes = {
  attributeName: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  ...formPropTypes,
};

MaskedField.defaultProps = {
  disabled: false,
};

export default withFormContext(MaskedField);
