import React from 'react';
import PropTypes from 'prop-types';

import CheckboxWithIcon from 'components/Form/CheckboxWithIcon';

import {
  CheckboxGroupContainer,
  CheckboxGroupContent,
  CheckboxGroupTitle,
} from './styles';

const CheckboxWithIconGroup = ({ options, title, disabled = false }) => {
  return (
    <CheckboxGroupContainer>
      <CheckboxGroupTitle>{title}</CheckboxGroupTitle>
      <CheckboxGroupContent>
        {options.map((option, index) => {
          const { icon, checked, onChange, label } = option;

          return (
            <CheckboxWithIcon
              key={index}
              icon={icon}
              checked={checked}
              onChange={onChange}
              disabled={disabled}
            >
              {label}
            </CheckboxWithIcon>
          );
        })}
      </CheckboxGroupContent>
    </CheckboxGroupContainer>
  );
};

CheckboxWithIconGroup.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
      checked: PropTypes.bool.isRequired,
      onChange: PropTypes.func.isRequired,
    })
  ).isRequired,
  title: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

export default CheckboxWithIconGroup;
