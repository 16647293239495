import React from 'react';
import { useTranslation } from 'react-i18next';

import withAppContext from 'core/hoc/withAppContext';

import {
  theme,
  DefaultThemeProps,
  Box,
  Text,
  FlexGrid,
  FlexGridItem,
  Tag,
  Icon,
  Card,
} from '@agendaedu/ae-web-components';

import { GeneralIntegrationsProps } from './types';

const GeneralIntegrations: React.FC<GeneralIntegrationsProps> = ({
  integration,
}) => {
  const attributes = integration?.attributes;
  const { features } = attributes;

  const { t } = useTranslation(['integrations_monitor']);
  const defaultThemeProps: DefaultThemeProps = theme;

  const {
    colors: { neutral },
  } = defaultThemeProps;

  const renderItemIntegration = (
    data: {
      name: string;
      value: boolean;
    },
    key: string
  ): JSX.Element => {
    return (
      <FlexGridItem key={key} cols={{ desktopLG: 4 }} filled>
        <Box display="flex" alignItems="left" flexDirection="row" p={16}>
          <Text
            as="p"
            ml={12}
            mr={12}
            mt={9}
            variant="subtitle-medium-16"
            fontWeight={500}
            color={neutral.gray1}
          >
            {data?.name}
          </Text>
          <Tag
            name={
              data.value
                ? t('card.general_integrations.active')
                : t('card.general_integrations.deactivate')
            }
            variant={data.value ? 'positive' : 'neutral'}
          />
        </Box>
      </FlexGridItem>
    );
  };

  return (
    <>
      <Text as="h2" variant="headline-h2-bold-24" mb={32} mt={32} ml={-16}>
        {t('card.general_integrations.title_general_integrations')}
      </Text>

      <Box display="flex" position="relative">
        <FlexGrid>
          <Card mt={32} padding={0}>
            <Box width="100%" p="24px" pl={-12}>
              <FlexGridItem cols={{ desktopLG: 10 }} filled>
                <Box>
                  <Text
                    as="h2"
                    mt={8}
                    mr={24}
                    mb={24}
                    variant="title-bold-20"
                    fontWeight={700}
                    color={neutral.black}
                  >
                    {t('card.general_integrations.title_features')}
                  </Text>
                </Box>
              </FlexGridItem>

              <Box
                display="flex"
                width="100%"
                flexDirection="row"
                justifyContent="flex-start"
                flexWrap="wrap"
                mt={18}
              >
                {features?.map(
                  (data: { name: string; value: boolean }, key: string) =>
                    renderItemIntegration(data, key)
                )}
              </Box>
            </Box>
          </Card>
        </FlexGrid>
      </Box>
    </>
  );
};

export default withAppContext(GeneralIntegrations);
