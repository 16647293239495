import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';

import * as S from './styles';

const TicketTemplate = ({ contentPrint }) => {
  const { description, title, taskhome, page_book } = contentPrint;

  return (
    <S.Container className="printContainer">
      <S.Content>
        <p>
          <b>Título</b>: {title}
        </p>
        <p>
          <b>Descrição</b>: {!!description && parse(description)}
        </p>
        <p>
          <b>Lição de casa?</b> {taskhome === true ? 'Sim' : 'Não'}
        </p>
        <p>
          <b>Página/Livro:</b> {!!page_book && parse(page_book)}
        </p>

        <div>
          ---------------------------------------------------------------------------------------------------------------------------------------------------------
        </div>
      </S.Content>
    </S.Container>
  );
};

TicketTemplate.propTypes = {
  contentPrint: PropTypes.shape({
    description: PropTypes.string,
    title: PropTypes.string,
    taskhome: PropTypes.string,
    page_book: PropTypes.string,
  }),
};

export default TicketTemplate;
