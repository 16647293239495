import React, { useEffect, useCallback } from 'react';

import withFormContext from 'core/hoc/withFormContext';
import FormContext from 'core/contexts/Form';
import tabifyForWizard from 'core/hoc/tabifyForWizard';
import FormSelector from 'components/Form/Selector';
import Loader from 'components/Loader';

import './style.scss';

const SchoolTermsTab = ({ formContext }) => {
  const { changeMeta, formMeta, form } = formContext;

  const optionComponent = (option) => (
    <span className="school-term-name">{option.name}</span>
  );

  const fieldsFillVerification = useCallback(() => {
    changeMeta('nextStepDisabled', !form.schoolTerms.selected.length);
  }, [changeMeta]);

  useEffect(() => {
    fieldsFillVerification();
  }, [fieldsFillVerification]);

  const updateSelectorColumns = useCallback(
    ({ selectedValues }) => {
      form.schoolTerms.selected = selectedValues;
      formMeta.schoolTerm(selectedValues);

      fieldsFillVerification();
    },
    [fieldsFillVerification]
  );

  const searchOptions = ({ options, searchTerm }) =>
    options.filter((option) =>
      option.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  return (
    <div className="schoolTermTab">
      <div>
        <div className="stepInfo">
          <p>
            Selecione quais <b>Períodos</b> serão importadas na integração.
            Essas configurações serão salvas para as próximas integrações.
          </p>
        </div>
        <FormContext.Consumer>
          {({ form }) => (
            <Loader isLoading={form.loading}>
              <FormSelector
                options={form.data.schoolTerms.filters}
                selectedValues={form.data.schoolTerms.selected}
                optionComponent={optionComponent}
                onChange={updateSelectorColumns}
                search={searchOptions}
                searchPlaceholder="Buscar período"
                optionAccessor={(option) => option.code}
                selectAllLabel="Selecionar todos"
                onSelectBehavior="highlight"
                counterLabel={(length) =>
                  length === 1 ? 'período' : 'períodos'
                }
              />
            </Loader>
          )}
        </FormContext.Consumer>
      </div>
    </div>
  );
};

export default tabifyForWizard({
  title: 'Períodos',
  subTitle: '',
})(withFormContext(SchoolTermsTab));
