import React from 'react';
import ContentLoader from 'react-content-loader';

const SkeletonList = () => {
  return (
    <ContentLoader speed={2} width={400} height={260} viewBox="0 0 400 260">
      <rect x="0" y="0" rx="4" ry="4" width="85" height="6" />
      <rect x="360" y="0" rx="4" ry="4" width="40" height="8" />

      <rect x="0" y="16" rx="4" ry="4" width="350" height="4" />
      <rect x="0" y="22" rx="4" ry="4" width="350" height="4" />

      <rect x="0" y="35" rx="4" ry="4" width="400" height="25" />
      <rect x="0" y="65" rx="4" ry="4" width="400" height="25" />
      <rect x="0" y="95" rx="4" ry="4" width="400" height="25" />
      <rect x="0" y="125" rx="4" ry="4" width="400" height="25" />
    </ContentLoader>
  );
};

export default SkeletonList;
