import styled, { css } from 'styled-components';
import colorWithAlpha from 'core/utils/colorWithAlpha';

import OutlineBox from 'components/OutlineBox';

export const InfoTabContainer = styled.section`
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.14);
  border-radius: 8px;

  .Tabs.default > .tabs-header > .tab-list {
    @media (max-width: 768px) {
      width: 50%;
    }

    > .react-tabs__tab-list {
      height: 52px;
      margin-bottom: 0;

      li {
        padding: 16px 8px;
        height: 100%;
        pointer-events: none;

        &.tab-style.selected-tab:after {
          margin-top: 0;
          position: relative;
          bottom: 5px;
        }

        span {
          height: 100%;
          display: block;
          padding: 16px 24px;
        }
      }
    }
  }

  .react-tabs__tab-panel--selected {
    padding: 40px;
  }
`;

export const TitleContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .Avatar {
      margin-right: 12px;
    }

    h4 {
      font-weight: 700;
      font-size: 24px;
      color: ${theme.black};
    }
  `}
`;

export const LastUpdated = styled(OutlineBox)`
  ${({ theme }) => css`
    &.OutlineBox.info {
      margin: 32px 0;
      border-color: transparent;
      background-color: ${colorWithAlpha(theme.info, 0.1)};
    }
  `}
`;

export const DetailsContainer = styled.div`
  width: 100%;
`;

export const DailyEntryTextContainer = styled.div`
  margin-bottom: 24px;

  &:last-of-type {
    margin-bottom: 0;
  }

  p {
    ${({ theme }) => css`
      font-family: 'Roboto', sans-serif;
      font-size: 16px;
      font-weight: 400;
      color: ${theme.gray2};
    `}
  }

  b {
    margin-bottom: 4px;
  }
`;

export const EntryLabel = styled.h5`
  ${({ theme }) => css`
    font-size: 14px;
    font-weight: 500;
    color: ${theme.gray1};
    margin-bottom: 8px;
  `}
`;
