import React from 'react';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

import AgendaIcon from 'components/AgendaIcon';
import ToggleSwitch from 'components/Form/ToggleSwitch';
import Tooltip, { TOP_CENTER } from 'components/Tooltip';

import withFormContext from 'core/hoc/withFormContext';

import { InstallmentFeesConfigurationProps } from './types';

import * as S from './styles';

const InstallmentFeesConfiguration = ({
  formContext: {
    updateAttribute,
    form: {
      absorb_tax: absorbTax,
      max_installments: maxInstallments,
      allowed_payment_method: paymentMethodChosen,
    },
    formMeta: {
      select_options: { max_installments: selectMaxInstallments },
    },
  },
  disabled,
}: InstallmentFeesConfigurationProps) => {
  const { t } = useTranslation(['payments']);

  const toggleSwitch = () => {
    updateAttribute('absorb_tax', !absorbTax);
  };

  const creditCardDisabled = !paymentMethodChosen.includes('credit_card');
  const showInstallmentsSelector = absorbTax && !creditCardDisabled;

  return (
    <S.InstallmentFeesConfigurationWrapper
      creditCardDisabled={creditCardDisabled}
    >
      <S.ToggleAssumeFeesWrapper showBorder={showInstallmentsSelector}>
        <S.ToggleAssumeFeesTitleWrapper>
          <span>{t('form_section.toggle_assume_fees_title')}</span>
          <Tooltip
            content={parse(t('form_section.toggle_assume_fees_tooltip'))}
            position={TOP_CENTER}
          >
            <AgendaIcon name="help" size="small" className="custom-size" />
          </Tooltip>
        </S.ToggleAssumeFeesTitleWrapper>

        <ToggleSwitch
          testId="toggleDiscount"
          toggled={showInstallmentsSelector}
          onChange={toggleSwitch}
          disabled={disabled || creditCardDisabled}
        />
      </S.ToggleAssumeFeesWrapper>

      {showInstallmentsSelector && (
        <S.AvailableInstallmentsWrapper>
          <S.SelectInstallmentsField
            fullWidth
            withArrowSelect
            label={t('form_section.available_installments_label')}
            type="selectWithIcon"
            attributeName="max_installments"
            value={maxInstallments}
            options={selectMaxInstallments}
            disabled={disabled}
            placeholder={null}
            onChange={(e) => updateAttribute('max_installments', e.value)}
          />
        </S.AvailableInstallmentsWrapper>
      )}
    </S.InstallmentFeesConfigurationWrapper>
  );
};

export default withFormContext(InstallmentFeesConfiguration);
