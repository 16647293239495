import styled, { css } from 'styled-components';
import { Grid, Text } from '@agendaedu/ae-web-components';

import { ThemeProps } from 'config/themes/types';

export const FiltersWrapper = styled(Grid)`
  ${({ theme: { space } }: ThemeProps) => css`
    grid-template-areas: 'export';
    align-items: flex-end;
    margin: ${space.xl} 0;
  `}
`;

export const ExportButtonWrapper = styled(Grid)`
  grid-column: auto / -1 !important;
  grid-column-start: 3;
  grid-column-end: 10;
  justify-content: flex-end;
`;

export const OrderCounterWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 0;
`;

export const CapitalizedText = styled(Text)`
  text-transform: capitalize;
`;
