import styled from 'styled-components';

export const Wrapper = styled.div`
  margin-bottom: 24px;
  > span {
    font-weight: 400;
    font-size: 14px;
    color: ${({ theme }) => theme.black};
  }
`;

export const LoaderWrapper = styled.div`
  margin-right: 8px;
`;
