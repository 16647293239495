import styled, { css } from 'styled-components';

import FormFieldset from 'components/Form/Fieldset';

import { ThemeProps } from 'config/themes/types';
import { Text } from '@agendaedu/ae-web-components';

export const ClassroomsStepWrapper = styled(FormFieldset)``;

export const RowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  ${({ theme: { space } }: ThemeProps) => css`
    gap: ${space.xl};
    padding: ${space.xs4};
  `}
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme: { space } }: ThemeProps) => css`
    gap: ${space.xs};
  `}
`;

export const StepTitle = styled(Text)`
  margin-top: 20px;
  ${({ theme: { colors } }: ThemeProps) => css`
    color: ${colors.neutral.black};
  `}
`;

export const StepSubtitle = styled(Text)`
  ${({ theme: { colors } }: ThemeProps) => css`
    color: ${colors.neutral.gray1};
  `}
`;
