import React from 'react';
import { useSelector } from 'react-redux';

import tabifyForWizard from 'core/hoc/tabifyForWizard';
import withFormContext from 'core/hoc/withFormContext';
import FormFieldset from 'components/Form/Fieldset';
import Title from 'components/WizardForm/Title';
import Field from 'components/Form/Field';
import CheckPaymentMethods from 'components/Form/CheckPaymentMethods';
import FinesAndInterest from '../../RecurrentFormSections/FinesAndInterest';
import NegotiationBills from '../FormSections/NegotiationBills';
import RecurrencyConfiguration from '../FormSections/RecurrencyConfiguration';

import validations from './validations';
import { NegotiationFormPaymentTabProps } from '../types';
import * as S from './styles';

const NegotiationFormPaymentTab = ({
  formContext: { form },
}: NegotiationFormPaymentTabProps) => {
  const {
    policies: {
      can_config_recurrent_plan_payment_methods:
        canConfigRecurrentPlanPaymentMethods,
      edupay_provider: edupayProvider,
    },
  } = useSelector((state) => state.root);

  return (
    <FormFieldset>
      <Title name="Método de pagamento" />
      <CheckPaymentMethods
        withPix
        attributeName="allowed_payment_method"
        disabled={!canConfigRecurrentPlanPaymentMethods}
        canConfigPaymentMethods={canConfigRecurrentPlanPaymentMethods}
        isNewLayout={edupayProvider === 'bemobi'}
      />
      <RecurrencyConfiguration />
      <NegotiationBills />
      <FinesAndInterest />
      <br />
      <div className="row">
        <Field
          type="simpleTextArea"
          label="Observações*"
          placeholder=""
          attributeName="observation"
        />
      </div>
      <S.Paragraph>*Campos de preenchimento obrigatório</S.Paragraph>
    </FormFieldset>
  );
};

export default tabifyForWizard({
  title: '2. Pagamento',
  subTitle: 'Forma de pagamento da negociação',
  validations: validations,
})(withFormContext(NegotiationFormPaymentTab));
