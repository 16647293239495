import React from 'react'
import './ModalTitle.scss'

/*
ModalTitle

List of props:
title: string

Example:
<ModalTitle>Texto</ModalTitle>
*/

const ModalTitle = ({children}) => (
  <p className="modal-title">{children}</p>
);

export default ModalTitle;
