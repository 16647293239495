import React from 'react';
import PropTypes from 'prop-types';

import Field from 'components/Form/Field';
import Accordion from 'components/Accordion';

import AgendaIcon from 'components/AgendaIcon';
import Tooltip, { TOP_CENTER } from 'components/Tooltip';

import withFormContext from 'core/hoc/withFormContext';

const Chart = ({
  index,
  removeChart,
  chart,
  totalNumberOfCharts,
  updateChart,
}) => {
  const updateValues = (values) => {
    const newChart = { ...chart, ...values };
    updateChart(index, newChart);
  };

  const actionItems = () => {
    if (totalNumberOfCharts > 1) {
      let actions = [
        { text: 'Excluir', onClick: () => removeChart(chart.key) },
      ];
      return actions;
    }
  };

  const renderAccordionTitle = () => {
    return (
      <React.Fragment>
        <span className="report-chart-title-form">{`Gráfico ${
          index + 1
        }`}</span>
      </React.Fragment>
    );
  };

  const renderBodyMetabaseIdLabel = () => (
    <>
      <span>ID do Metabase*</span>
      <Tooltip
        content="Você pode encontrar o número do ID na URL após o /question. Por exemplo, para metabase.suaescola.com/question/123, o ID é 123."
        position={TOP_CENTER}
        tooltipStyle={{ maxWidth: '300px', height: '80px' }}
      >
        <AgendaIcon name="help" size="small" className="custom-size" />
      </Tooltip>
    </>
  );

  const renderAccordionBody = () => {
    return (
      <div className="accordion-chart-body">
        <Field
          label="Título*"
          placeholder="ex. Família"
          attributeName={`chart_title_${chart.key}`}
          onChange={(e) =>
            updateValues({ title: e[`chart_title_${chart.key}`] })
          }
        />
        <Field
          label={renderBodyMetabaseIdLabel()}
          placeholder="123"
          attributeName={`metabase_question_id_${chart.key}`}
          onChange={(e) =>
            updateValues({
              metabase_question_id: e[`metabase_question_id_${chart.key}`],
            })
          }
        />
      </div>
    );
  };

  return (
    <Accordion
      header={renderAccordionTitle()}
      body={renderAccordionBody()}
      actions={actionItems()}
      allowZeroExpanded
      expanded="chart"
    />
  );
};

const ReportFormChartShape = {
  key: PropTypes.string,
  title: PropTypes.string,
  metabase_question_id: PropTypes.string,
};

Chart.propTypes = {
  index: PropTypes.number.isRequired,
  removeChart: PropTypes.func.isRequired,
  chart: PropTypes.shape(ReportFormChartShape).isRequired,
  totalNumberOfCharts: PropTypes.number.isRequired,
  updateChart: PropTypes.func.isRequired,
};

export default withFormContext(Chart);
