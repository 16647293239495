import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Text } from '@agendaedu/ae-web-components';

import { trackEvent } from 'config/amplitude';
import tabifyForWizard from 'core/hoc/tabifyForWizard';
import withFormContext from 'core/hoc/withFormContext';
import validations from './validations';
import FormFieldset from 'components/Form/Fieldset';
import Alert from 'components/Alert';
import Toast from 'components/Toast';
import Field from 'components/Form/Field';
import CheckPaymentMethods from 'components/Form/CheckPaymentMethods';
import InstallmentFeesConfiguration from 'components/Payments/PaymentsContainer/FormSections/InstallmentFeesConfiguration';
import SimpleSummary from 'components/Payments/PaymentsContainer/FormSections/SimpleSummary';
import Title from 'components/WizardForm/Title';
import Tooltip from 'components/Tooltip';
import FormCheckbox from 'components/Form/Checkbox';
import AgendaIcon from 'components/AgendaIcon';
import LabelWithTooltip from 'components/Payments/Shop/OfferForm/LabelWithTooltip';
import { NewOfferFormOfferTabProps } from 'components/Payments/Shop/OfferForm/types';
import * as S from './styles';
import moment from 'moment';

const TOOLTIP_SELL_PERIOD =
  'A oferta será lançada no aplicativo na data inicial e encerrada na data final.';

const OfferFormOfferTab = ({
  formContext: { form, formMeta, updateAttribute },
}: NewOfferFormOfferTabProps) => {
  const {
    allowed_payment_method,
    original_price,
    max_installments,
    status,
    enabled_notification_types,
    addable_to_cart,
  } = form;

  const { meta_max_installments } = formMeta;

  const { t } = useTranslation(['payments']);

  const {
    policies: {
      school_plan,
      can_config_offer_payment_methods,
      edupay_provider,
      can_absorb_tax,
    },
  } = useSelector((state) => state.root);

  const { cartSettings } = useSelector((state) => state.shop);

  const toggleOfferAvailableInCart = () => {
    updateAttribute('addable_to_cart', !addable_to_cart);
  };

  const toggleNotificationCheckbox = (value) => {
    let newNotificationTypes = [...enabled_notification_types];

    if (newNotificationTypes.includes(value)) {
      updateAttribute(
        'enabled_notification_types',
        newNotificationTypes.filter((t) => t != value)
      );
    } else {
      updateAttribute('enabled_notification_types', [
        ...newNotificationTypes,
        value,
      ]);
    }
  };

  return (
    <S.OfferWrapper>
      <FormFieldset>
        <div className="row">
          <Field
            size="extraSmall"
            attributeName="original_price"
            type="currency"
            label="Valor da oferta*"
          />
        </div>

        {!status && !!cartSettings?.attributes?.enabled && (
          <div>
            <Text variant="label-regular-16" mb="24px">
              {t('marketplace.new_offer_form.offer_available')}
            </Text>
            <S.CheckboxWrapper>
              <FormCheckbox
                testid={`checkbox-offer-available-in-cart`}
                checked={addable_to_cart}
                onChange={() => toggleOfferAvailableInCart()}
                disabled={false}
              />
              <S.CheckboxSpan onClick={() => toggleOfferAvailableInCart()}>
                <Text variant="body-regular-14" color="neutral.gray2" mb={0}>
                  {t('marketplace.new_offer_form.offer_available_checkbox')}
                </Text>
              </S.CheckboxSpan>
            </S.CheckboxWrapper>

            <Alert variation="info">
              <Link
                to="/schools/shopping_carts/configuration"
                onClick={() => trackEvent('shopping-carts-configuration')}
              >
                <S.CartConfig variant="subtitle-medium-14" mb={0}>
                  {t('marketplace.new_offer_form.access_cart_settings')}
                </S.CartConfig>
              </Link>
            </Alert>
          </div>
        )}

        <div className="row">
          <CheckPaymentMethods
            withPix
            attributeName="allowed_payment_method"
            disabled={!can_config_offer_payment_methods || status === 'active'}
            canConfigPaymentMethods={can_config_offer_payment_methods}
            isNewLayout={edupay_provider === 'bemobi'}
          />

          {can_absorb_tax && (
            <InstallmentFeesConfiguration
              disabled={
                !can_config_offer_payment_methods || status === 'active'
              }
            />
          )}

          {school_plan === 'trial' && (
            <Alert variation="info">
              <p>
                Durante o período de testes, pagamentos via cartão de crédito
                ficam desabilitados.
              </p>
              Caso queira habilitar o recebimento por esse método de pagamento,
              entre em contato com nosso time de atendimento:{' '}
              <a
                href="https://atendimento.agendaedu.com/hc/pt-br/articles/360022221754-Conhecendo-sua-carteira"
                target="_blank"
                rel="noreferrer"
              >
                atendimento@agendaedu.com
              </a>
            </Alert>
          )}
        </div>
        {allowed_payment_method.includes('credit_card') &&
          edupay_provider !== 'bemobi' && (
            <div className="row">
              <Field
                size="small"
                fullWidth
                withArrowSelect
                type="selectWithIcon"
                label="Nº de parcelas*"
                attributeName="max_installments"
                options={meta_max_installments}
                value={max_installments}
                onChange={(e) => {
                  updateAttribute('max_installments', e.value);
                }}
              />
            </div>
          )}

        <Title name={t('marketplace.new_offer_form.notification_section')} />
        <S.NotificationDescription>
          {t('marketplace.new_offer_form.notification_description')}
        </S.NotificationDescription>
        <S.CheckboxWrapper>
          <FormCheckbox
            testid={`checkbox-email-notification`}
            checked={enabled_notification_types.includes('email')}
            onChange={() => toggleNotificationCheckbox('email')}
            disabled={false}
          />
          <S.CheckboxSpan onClick={() => toggleNotificationCheckbox('email')}>
            {t('marketplace.new_offer_form.email_checkbox')}
          </S.CheckboxSpan>
          <Tooltip
            content={t('marketplace.new_offer_form.email_checkbox_tooltip')}
            tooltipStyle={S.CheckboxTooltip}
          >
            <AgendaIcon name="help" size="small" className="custom-size" />
          </Tooltip>
        </S.CheckboxWrapper>
        <S.CheckboxWrapper>
          <FormCheckbox
            testid={`checkbox-push-notification`}
            checked={enabled_notification_types.includes('push')}
            onChange={() => toggleNotificationCheckbox('push')}
            disabled={false}
          />
          <S.CheckboxSpan onClick={() => toggleNotificationCheckbox('push')}>
            {t('marketplace.new_offer_form.push_checkbox')}
          </S.CheckboxSpan>
          <Tooltip
            content={t('marketplace.new_offer_form.push_checkbox_tooltip')}
            tooltipStyle={S.CheckboxTooltip}
          >
            <AgendaIcon name="help" size="small" className="custom-size" />
          </Tooltip>
        </S.CheckboxWrapper>

        <S.AccordionsWrapper>
          <Title name={t('form_section.simple_summary_title')} />
          <SimpleSummary price={original_price} />
        </S.AccordionsWrapper>
        <Title name="Período de vendas" />
        <div className="double-input">
          <Field
            label={
              <LabelWithTooltip
                label="Período*"
                tooltipText={TOOLTIP_SELL_PERIOD}
              />
            }
            className="dateRange"
            type="rangeDate"
            attributeName="sell_period"
            showClearDates={false}
            isOutsideRange={(day) => moment(day._d).isBefore(moment(), 'day')}
            enableSameDate
            disabled={status === 'closed' ? 'startDate' : ''}
          />
        </div>
        <S.Paragraph>*Campos de preenchimento obrigatório</S.Paragraph>
      </FormFieldset>
      <Toast />
    </S.OfferWrapper>
  );
};

export default tabifyForWizard({
  title: '2. Oferta',
  subTitle: 'Métodos de pagamentos',
  validations,
})(withFormContext(OfferFormOfferTab));
