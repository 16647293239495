import React from 'react';

import './style.scss';
import PropTypes from 'prop-types';
import withAppContext from 'core/hoc/withAppContext';
import tinycolor from 'tinycolor2';
import AgendaIcon from 'components/AgendaIcon';
import AttachmentEntity from 'core/entities/Attachment';

const MessagesAttachment = ({ variation, attachment, appContext }) => {
  let backgroundColor = tinycolor(appContext.primaryColor);
  let textColor = tinycolor(appContext.primaryColor);
  let attachmentStyle;
  let fileStyle;
  let fileSizeStyle;
  if (variation === 'sent') {
    attachmentStyle = {
      backgroundColor: backgroundColor.setAlpha(0.45),
    };
  } else {
    attachmentStyle = {
      backgroundColor: backgroundColor.setAlpha(0.1),
    };
    fileStyle = {
      color: appContext.primaryColor,
    };
    fileSizeStyle = {
      color: textColor.setAlpha(0.7),
    };
  }

  return (
    <a
      style={attachmentStyle}
      className="MessagesAttachment"
      href={attachment.getUrl()}
      target="_blank"
      rel="noreferrer"
    >
      <AgendaIcon
        style={fileStyle}
        className="fileIcon"
        name={attachment.getType()}
      />
      <div style={fileStyle} className="fileName">
        {attachment.getName()}
      </div>
      <div style={fileSizeStyle} className="fileSize">
        {attachment.getFormattedFileSize()}
      </div>
    </a>
  );
};

MessagesAttachment.propTypes = {
  appContext: PropTypes.shape({
    primaryColor: PropTypes.string.isRequired,
  }).isRequired,
  attachment: PropTypes.instanceOf(AttachmentEntity),
  variation: PropTypes.oneOf(['sent', 'received']).isRequired,
};

export default withAppContext(MessagesAttachment);
