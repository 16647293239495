import {
  Box,
  DefaultThemeProps,
  Icon,
  Text,
} from '@agendaedu/ae-web-components';
import { IconProps } from '@agendaedu/ae-web-components/dist/components/display/Icon/types';
import React from 'react';
import { useTheme } from 'styled-components';
import { Props } from './types';
import { useTranslation } from 'react-i18next';
import formatDatetime from 'core/utils/formatDatetime';

const InfoSession = ({ album }: Props) => {
  const { t } = useTranslation('albums');
  const { colors, border } = useTheme() as DefaultThemeProps;
  const { createdAt, updatedAt, totalNumberOfPhotos } = album.attributes;

  const SessionItem = ({
    iconName,
    label,
    value,
  }: {
    iconName: IconProps['name'];
    label: string;
    value: string | number;
  }) => (
    <Box display="flex" mr="xl" mt="sm">
      <Box
        display="flex"
        size={40}
        justifyContent="center"
        alignItems="center"
        mr="sm"
        borderRadius={border.radius.md}
        backgroundColor={colors.brand.primary.op20}
      >
        <Icon name={iconName} color={colors.brand.primary.default} />
      </Box>
      <Box>
        <Text variant="subtitle-medium-14" color="neutral.gray2">
          {label}
        </Text>
        <Text variant="label-regular-16" color="neutral.black">
          {value}
        </Text>
      </Box>
    </Box>
  );

  const formatDatePattern = (date: string) =>
    formatDatetime(date, t('album_details.date_pattern'));

  return (
    <Box display="flex" flexWrap="wrap">
      <SessionItem
        iconName="calendar"
        label={t('album_details.created_at')}
        value={formatDatePattern(createdAt)}
      />
      {createdAt !== updatedAt && (
        <SessionItem
          iconName="calendar"
          label={t('album_details.updated_at')}
          value={formatDatePattern(updatedAt)}
        />
      )}
      {!!totalNumberOfPhotos && (
        <SessionItem
          iconName="image"
          label={t('album_details.number_of_images')}
          value={totalNumberOfPhotos}
        />
      )}
    </Box>
  );
};

export default InfoSession;
