import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

import { ThemeProps } from 'config/themes/types';

export const ButtonsWrappers = styled.section`
  ${({ theme: { space, breakpoints, font } }: ThemeProps) => css`
    display: flex;
    flex-direction: row;
    margin-top: ${space.xl};
    gap: ${space.xl};

    @media (max-width: ${breakpoints.desktopLG}) {
      flex-direction: column;
      gap: ${space.lg};

      strong {
        font-size: ${font.size.md};
      }

      span {
        font-size: ${font.size.sm};
      }
    }
  `}
`;

export const Button = styled(Link)`
  ${({ theme: { border, colors, space, breakpoints } }: ThemeProps) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    max-width: 354px;
    max-heigth: 354px;
    width: 100%;
    gap: ${space.xl};
    border-radius: ${space.xs};
    border: 1px ${border.style.solid} ${colors.neutral.gray4};
    padding: ${space.lg} ${space.xl};
    cursor: pointer;

    transition: background-color 0.25s ease;

    &:hover {
      border-color: ${colors.brand.primary.op10};
      background-color: ${colors.brand.primary.op10};
    }

    &:active {
      border-color: ${colors.brand.primary.op20};
      background-color: ${colors.brand.primary.op20};
    }

    @media (max-width: ${breakpoints.desktopLG}) {
      max-width: 330px;
      max-heigth: 330px;
      gap: ${space.lg};
      text-align: center;
    }
  `}
`;

export const InfomationWrapper = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: ${space.lg};
  `}
`;

export const Image = styled.img`
  height: 100px;
  width: 100px;
`;

export const Title = styled.strong`
  ${({ theme: { colors, typography } }: ThemeProps) => css`
    ${typography.title.Bold20}
    color: ${colors.neutral.black}
  `}
`;

export const Description = styled.span`
  ${({ theme: { colors, typography } }: ThemeProps) => css`
    ${typography.label.Regular16}
    color: ${colors.neutral.gray1};
    text-align: center;
  `}
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
