import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import { required, maxLength, blankSpace } from 'core/utils/validate';

import FormContainer from 'components/ReduxFormCommom/Form';
import TextInput from 'components/ReduxFormCommom/TextInput';
import ButtonRow from 'components/ButtonRow';
import Button from 'components/ReduxFormCommom/Button';

const validateMaxLength = maxLength(90);

const RecurrentProductForm = ({
  handleSubmit,
  sending,
  onClose,
  action,
  pristine,
}) => (
  <FormContainer onSubmit={handleSubmit}>
    <div className="row">
      <Field
        name="name"
        component={TextInput}
        label="Nome do produto"
        validate={[required, validateMaxLength, blankSpace]}
      />
    </div>
    <ButtonRow>
      <Button variation="secondary" onClick={onClose}>
        Cancelar
      </Button>
      <Button variation="primary" type="submit" disabled={pristine || sending}>
        {action === 'edit' ? 'Salvar' : 'Cadastrar'}
      </Button>
    </ButtonRow>
  </FormContainer>
);

RecurrentProductForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  action: PropTypes.string.isRequired,
  sending: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
};

export default reduxForm({
  form: 'RecurrentProductForm',
})(RecurrentProductForm);
