import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

import { Box, Grid, Tag, RecipientTag } from '@agendaedu/ae-web-components';

import formatDatetime from 'core/utils/formatDatetime';

import FileAttachment from 'components/FileAttachment';
import History from 'components/History';
import InfoCard from 'components/InfoCard';

import { HandoutState } from 'store/handouts/types';

import HandoutActionButtons from '../ActionButtons';

import { AlertType, PersonaType, StatusType } from '../types';

import * as S from './styles';

export const DetailsTab = () => {
  const { t } = useTranslation(['handouts', 'common']);

  const googleClientId = process.env.GOOGLE_CLIENT_ID;

  const {
    current: {
      title,
      status,
      attachments,
      classrooms,
      createdFor,
      coverImage,
      description,
      persona_type: personaType,
      scheduleDate,
      students,
      unapprovalReason,
      updatedAt,
      versions,
    },
  } = useSelector((state: HandoutState) => state.handouts);

  const handoutDeleted = updatedAt && status !== 'deleted';

  const recipientTag = () => {
    const currentType = {
      classrooms: classrooms,
      students: students,
    };

    return {
      type: t(`card_columns.tooltip_${createdFor}_title_text`),
      recipients: currentType[createdFor].map(({ attributes: { name } }) => ({
        name,
      })),
    };
  };

  const hasAlert = [
    'scheduled',
    'rejected',
    'pending_schedule',
    'waiting',
  ].includes(status);

  const renderStatusType: StatusType<
    | 'pending'
    | 'pending_schedule'
    | 'sent'
    | 'scheduled'
    | 'rejected'
    | 'deleted'
    | 'waiting'
  > = {
    pending: {
      name: t('tags.pending'),
      variant: 'warning',
    },
    pending_schedule: {
      name: t('tags.pending'),
      variant: 'warning',
    },
    sent: {
      name: t('tags.sent'),
      variant: 'positive',
    },
    scheduled: {
      name: t('tags.scheduled'),
      variant: 'informative',
    },
    rejected: {
      name: t('tags.rejected'),
      variant: 'negative',
    },
    deleted: {
      name: t('tags.deleted'),
      variant: 'neutral',
    },
    waiting: {
      name: t('tags.waiting'),
      variant: 'informative',
    },
  };

  const renderPersonaType: PersonaType<'both' | 'students' | 'responsibles'> = {
    both: t('persona_type.both', { ns: 'common' }),
    students: t('persona_type.students', { ns: 'common' }),
    responsibles: t('persona_type.responsibles', { ns: 'common' }),
  };

  const renderAlertType: AlertType<
    'pending_schedule' | 'scheduled' | 'rejected' | 'waiting'
  > = {
    pending_schedule: {
      value: t('handout_details.alert.pending_schedule.value', {
        date: formatDatetime(
          scheduleDate,
          t('handout_details.alert.format_date')
        ),
      }),
    },
    scheduled: {
      value: t('handout_details.alert.scheduled.value', {
        date: formatDatetime(
          scheduleDate,
          t('handout_details.alert.format_date')
        ),
      }),
    },
    rejected: {
      label: t('handout_details.alert.rejected.label'),
      value: `${unapprovalReason}`,
    },
    waiting: {
      value: t('handout_details.sending_handout_tooltip'),
    },
  };

  return (
    <>
      <Box py={{ _: 'xl2' }} px={{ _: 'sm', desktopLG: 'xl2' }}>
        {hasAlert && (
          <Box mb="lg">
            <S.Alert statusType={status}>
              <span>{renderAlertType[status].label}</span>
              {renderAlertType[status].value}
            </S.Alert>
          </Box>
        )}

        <Grid gridTemplateColumns="1fr">
          <Grid item gridTemplateAreas='"tags" "title" "sendTo"'>
            <Grid item gridArea="tags">
              <S.TagsWrapper>
                <Tag
                  name={
                    handoutDeleted
                      ? t('card_columns.updated_status_text')
                      : renderStatusType[status].name
                  }
                  variant={
                    handoutDeleted
                      ? 'informative'
                      : renderStatusType[status].variant
                  }
                />

                {!!classrooms.length && (
                  <RecipientTag
                    variant="neutral"
                    enableEllipsis
                    modalTitle={recipientTag().type}
                    recipientType={recipientTag().type}
                    recipients={recipientTag().recipients}
                  />
                )}
              </S.TagsWrapper>
            </Grid>

            <Grid item gridArea="title">
              <S.HandoutTitle>{title}</S.HandoutTitle>
            </Grid>

            <Grid item gridArea="sendTo">
              <InfoCard
                icon="send"
                label={t('handout_details.send_to_text')}
                value={renderPersonaType[personaType]}
              />
            </Grid>
          </Grid>

          <Grid
            item
            gridTemplateAreas='"coverImage" "description" "attachments"'
          >
            {coverImage && (
              <Grid item gridArea="coverImage" gutter="sm">
                <S.HandoutLabel>
                  {t('handout_details.tabs.labels.cover_image_label')}
                </S.HandoutLabel>
                <S.CoverImagePreview>
                  <img
                    src={coverImage.url}
                    alt={t('handout_details.tabs.labels.cover_image_label')}
                    loading="lazy"
                  />
                </S.CoverImagePreview>
              </Grid>
            )}

            <Grid item gridArea="description" gutter="sm">
              <S.HandoutLabel>
                {t('handout_details.tabs.labels.descriotion_label')}
              </S.HandoutLabel>
              <S.HandoutContent>{parse(description)}</S.HandoutContent>
            </Grid>

            {attachments?.length > 0 && (
              <Grid item gridArea="attachments">
                <FileAttachment
                  className="handout-attachments"
                  attachments={attachments}
                  googleClientId={googleClientId}
                  useGoogleDrive
                  onlyListAttachment
                  onlyDownloadAttachment
                />
              </Grid>
            )}
          </Grid>

          {versions?.length > 0 && (
            <Grid mt="xs" item>
              <History
                data={versions}
                isModalList
                className="handout-history-section"
              />
            </Grid>
          )}

          <HandoutActionButtons />
        </Grid>
      </Box>
    </>
  );
};
