import React from 'react';

import { Button, Icon } from '@agendaedu/ae-web-components';

import { Props } from './types';

import * as S from './styles';
import { useTranslation } from 'react-i18next';
import withAppContext from 'core/hoc/withAppContext';

const TemplateCard: React.FC<Props> = ({
  template,
  applyTemplate,
  viewTemplate,
  appContext: {
    policies: { can_create_handout: canCreateHandout },
  },
}) => {
  const { t } = useTranslation(['handouts']);

  const { title, coverImage } = template.attributes;
  return (
    <S.Wrapper data-testid="template-card">
      {coverImage?.url ? (
        <S.TemplateCoverImage
          data-testid="template-image"
          src={coverImage.url}
        />
      ) : (
        <S.EmptyImageWrapper data-testid="empty-template-image">
          <Icon name="image" />
        </S.EmptyImageWrapper>
      )}

      <S.OverlayTemplateCard>
        <S.OverlayOptionsWrapper>
          {canCreateHandout && (
            <Button
              data-testid="apply-template-button"
              onClick={() => applyTemplate(template.id)}
            >
              {t('templates.card_use_button')}
            </Button>
          )}

          <Button
            data-testid="view-template-button"
            onClick={() => viewTemplate(template)}
            variant="secondary"
          >
            {t('templates.card_view_button')}
          </Button>
        </S.OverlayOptionsWrapper>
      </S.OverlayTemplateCard>

      <S.TemplateTitleWrapper>
        <S.TemplateTitle>{title}</S.TemplateTitle>
      </S.TemplateTitleWrapper>
    </S.Wrapper>
  );
};

export default withAppContext(TemplateCard);
