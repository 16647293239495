import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  .customMetadata {
    margin-left: auto;
    display: flex;
    align-items: flex-end;
    flex-direction: column;

    b.found {
      color: ${(props) => props.theme.danger};
    }

    b.corrected {
      color: ${(props) => props.theme.success};
    }
  }

  .Loader {
    margin-top: 32px;
  }

  > fieldset {
    width: 100% !important;
    margin: 0 !important;
  }
`;

export const InfoText = styled.span`
  display: block;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: ${(props) => props.theme.black};
  width: 100%;
  max-width: 1000px;

  @media (min-width: 1200px) {
    margin-left: 16.666666666666664%;
  }
`;

export const TableWrapper = styled.div`
  ${() => css`
    .TableContainer {
      .Table.table {
        tbody {
          > tr {
            > td {
              white-space: nowrap;
            }
          }
        }
      }
    }
  `}
`;
