import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import tabify from 'core/hoc/tabify';
import smoothScrollTo from 'core/utils/smoothScrollTo';

import Loader from 'components/Loader';
import Table from 'components/Table';
import Pagination from 'components/Pagination';

import actionRecurrentPlans from 'store/edupay/recurrentPlans/actions';

import table from './table';

import './style.scss';

const HistoryTab = (props) => {
  const dispatch = useDispatch();

  const isLoading = useSelector((state) => state.recurrentPlans.isLoading);
  const versions = useSelector((state) => state.recurrentPlans.versions);
  const paginate = useSelector((state) => state.recurrentPlans.paginateVersion);

  const {
    match: {
      params: { id },
    },
  } = props;

  const { setVersionsRequest } = actionRecurrentPlans;

  const getVersions = useCallback(
    (params) => {
      dispatch(setVersionsRequest(id, params));
    },
    [dispatch, setVersionsRequest]
  );

  useEffect(() => {
    if (versions?.length) return;

    getVersions();
  }, []);

  const handlePageChange = useCallback((page) => {
    const params = { page };
    getVersions(params);
    smoothScrollTo(0, 0);
  }, []);

  if (isLoading) {
    return (
      <div className="PageContainer RecurrentProductsList centered">
        <div className="container-content">
          <Loader />
        </div>
      </div>
    );
  }

  return (
    <div className="RecurrentPlanHistoryTab">
      <div className="tab-name">HISTÓRICO</div>
      <Table columns={table} data={versions} />
      <Pagination
        onChange={handlePageChange}
        activePage={paginate?.page}
        totalItemsCount={paginate?.totalItemsCount}
        itemsCountPerPage={paginate?.itemsCountPerPage}
      />
    </div>
  );
};

export default tabify({
  title: 'Histórico',
  icon: 'history',
})(HistoryTab);
