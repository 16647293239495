import React, { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Icon } from '@agendaedu/ae-web-components';

import * as S from './styles';

import { LastMessage, MemberProps, MessageState } from 'store/messages/types';
import messagesActions from 'store/messages/actions';

import AgendaIcon from 'components/AgendaIcon';

import { GroupCardProps } from './types';

const GroupCard = ({ group }: GroupCardProps) => {
  const { t } = useTranslation(['messages']);
  const dispatch = useDispatch();

  const { currentGroup: current } = useSelector(
    (state: MessageState) => state.messages
  );

  const { setCurrentGroupRequest } = messagesActions;

  const {
    id,
    attributes: {
      name,
      last_message: lastMessage,
      enable_sound: enableSound,
      unread_message_count: unreadMessageCount,
      icon,
    },
    relationships: {
      members: { data },
    },
  } = group;

  const hasUnreadMessage = unreadMessageCount > 0;

  const renderGroupMembers = (): string =>
    t('groups.group_list.group_created_by', {
      userName: data
        .map((member: MemberProps) => member.attributes?.name)
        .join(', '),
    });

  const renderLastMessage = (
    lastMessage: LastMessage
  ): string | React.ReactElement => {
    if (!lastMessage.content && lastMessage.has_document)
      return (
        <S.LastMessageAttachmentWrapper>
          <Icon name="attachment" />
          {t('common.attachment')}
        </S.LastMessageAttachmentWrapper>
      );

    return !lastMessage.sender || lastMessage.is_logged
      ? lastMessage.content
      : `${lastMessage.sender}: ${lastMessage.content}`;
  };

  const handleSetCurrentGroup = useCallback(() => {
    if (current?.id === id) return;

    dispatch(setCurrentGroupRequest(group));
  }, [current, dispatch, group, id, setCurrentGroupRequest]);

  return (
    <S.GroupCardWrapper
      onClick={handleSetCurrentGroup}
      isSelected={current?.id === id}
      data-testid="group-card-wrapper"
    >
      {icon?.url ? (
        <S.Avatar data-testid="group-avatar" src={icon.url} />
      ) : (
        <S.EmptyAvatarWrapper data-testid="empty-avatar">
          <Icon name="user-profile" />
        </S.EmptyAvatarWrapper>
      )}

      <S.GroupCardContent>
        <S.GroupCardHeader>
          <S.GroupCardWrapperTitle>
            <S.GroupCardTitle variant="title-bold-14">{name}</S.GroupCardTitle>

            {!enableSound && <AgendaIcon name="megaphone-mute" size="small" />}
          </S.GroupCardWrapperTitle>

          {lastMessage?.created_at && (
            <S.GroupCardTimestamp variant="subtitle-medium-12">
              {lastMessage.created_at}
            </S.GroupCardTimestamp>
          )}
        </S.GroupCardHeader>

        <S.NotificationWrapper hasUnreadMessage={hasUnreadMessage}>
          <S.LastMessagePreview variant="body-regular-12">
            {!lastMessage?.content && !lastMessage?.has_document
              ? renderGroupMembers()
              : renderLastMessage(lastMessage)}
          </S.LastMessagePreview>

          {hasUnreadMessage && (
            <S.CountWrapper data-testid="count-wrapper">
              {unreadMessageCount}
            </S.CountWrapper>
          )}
        </S.NotificationWrapper>
      </S.GroupCardContent>
    </S.GroupCardWrapper>
  );
};

export default memo(GroupCard);
