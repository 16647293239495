import React from 'react';
import { InviteModalScreenProps } from './types';
import InviteModalBox from 'components/Invite/InviteModalBox';
import reduxProvider from 'core/hoc/reduxProvider';

const InviteModalScreen = ({
  resource,
}: InviteModalScreenProps): JSX.Element => {
  return (
    <div id="InviteModalScreen" className="InviteModalMainScreen">
      <InviteModalBox resource={resource} />
    </div>
  );
};

export default reduxProvider(InviteModalScreen);
