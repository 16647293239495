import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import DailySummariesContext from 'core/contexts/DailySummaries';

import Checkbox from 'components/Form/Checkbox';
import * as S from './styles';

const Card = ({
  title,
  checkboxLabel,
  isChecked,
  onChangeCheckbox,
  children,
  disabled,
  fieldList,
  canCollapse,
}) => {
  const { canShowField, canShowMultipleFields } = useContext(
    DailySummariesContext
  );

  const handleShowField = () => {
    if (fieldList) {
      return canShowMultipleFields(fieldList);
    }

    return canShowField(title);
  };

  const header = (
    <S.HeaderContainer>
      <S.Title>{title}</S.Title>
      {onChangeCheckbox && (
        <S.CheckboxContainer>
          <Checkbox
            checked={isChecked}
            onChange={onChangeCheckbox}
            disabled={disabled}
          />

          <S.CheckboxLabel>{checkboxLabel}</S.CheckboxLabel>
        </S.CheckboxContainer>
      )}
    </S.HeaderContainer>
  );

  const renderContent = (
    <S.CardContainer>
      {header}
      {canCollapse && !isChecked && children}
      {!canCollapse && children}
    </S.CardContainer>
  );

  return handleShowField() && <S.ContentContainer body={renderContent} />;
};

Card.defaultProps = {
  canCollapse: true,
};

Card.propTypes = {
  title: PropTypes.string.isRequired,
  checkboxLabel: PropTypes.string.isRequired,
  isChecked: PropTypes.bool,
  canCollapse: PropTypes.bool,
  onChangeCheckbox: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
  disabled: PropTypes.bool.isRequired,
  fieldList: PropTypes.arrayOf(PropTypes.string),
};

export default Card;
