import React, { useContext, useEffect, useCallback } from 'react';

import Meal from 'pages/dailySummaries/DailySummaryForm/StudentSections/Card/Meal';

import withFormContext from 'core/hoc/withFormContext';
import DailySummariesContext from 'core/contexts/DailySummaries';

const MealsList = () => {
  const {
    form: { meals },
    handleForm,
  } = useContext(DailySummariesContext);

  const addMeal = useCallback(() => {
    handleForm('meals', [
      ...meals,
      {
        id: Math.random().toString(36).substring(7),
        category: '',
        description: '',
        notEat: false,
      },
    ]);
  }, [handleForm, meals]);

  const removeMeal = (id) => {
    handleForm(
      'meals',
      meals.filter((occurrence) => occurrence.id !== id)
    );
  };

  useEffect(() => {
    meals.length < 1 && addMeal();
  }, [addMeal, meals]);

  return meals.map((meal, index) => (
    <Meal
      meal={meal}
      addMeal={addMeal}
      removeMeal={removeMeal}
      key={index}
      index={index}
    />
  ));
};

export default withFormContext(MealsList);
