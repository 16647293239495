import React from 'react';
import PropTypes from 'prop-types';

import FormFullScreen from 'components/FormFullScreen';

import EnrollmentPlanFormContractTab from './EnrollmentPlanFormContractTab';
import EnrollmentPlanFormInformationsTab from './EnrollmentPlanFormInformationsTab';
import EnrollmentPlanFormRecipientsTab from './EnrollmentPlanFormRecipientsTab';

import './style.scss';

const WIZARD_STEPS = [
  EnrollmentPlanFormContractTab,
  EnrollmentPlanFormInformationsTab,
  EnrollmentPlanFormRecipientsTab,
];

const EnrollmentPlanForm = ({
  action,
  onSubmit,
  form,
  formMeta,
  isSubmitting,
  backTo,
  titlePage,
  titleModal,
  contentModal,
}) => (
  <div className="EnrollmentForm">
    <FormFullScreen
      action={action}
      onSubmit={onSubmit}
      form={form}
      formMeta={formMeta}
      isSubmitting={isSubmitting}
      steps={WIZARD_STEPS}
      backTo={backTo}
      titlePage={titlePage}
      titleModal={titleModal}
      contentModal={contentModal}
    />
  </div>
);

EnrollmentPlanForm.propTypes = {
  action: PropTypes.oneOf(['new', 'edit']).isRequired,
  form: PropTypes.object.isRequired,
  formMeta: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  backTo: PropTypes.string,
  titlePage: PropTypes.string,
  titleModal: PropTypes.string,
  contentModal: PropTypes.string,
};

export default EnrollmentPlanForm;
