import React, { createContext, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';

import { DiaryFormStructure, DiaryState } from 'store/dailySummaries/types';
import { ContextProps, Props } from './types';
import { useFormik } from 'formik';
import * as yup from 'yup';
import moment from 'moment';
import {
  defaultAnswersAttributesValues,
  defaultBabyBottles,
  defaultEvacuations,
  defaultMeal,
  defaultOccurrence,
  defaultSleeps,
} from '../constants';
import { useSelector, useDispatch } from 'react-redux';
import DiaryActions from 'store/dailySummaries/actions';

const initialValuesInfo: DiaryFormStructure['studentsForm'] = {
  notify: false,
  meals: [defaultMeal()],
  baby_bottles: defaultBabyBottles(),
  sleeps: defaultSleeps(),
  evacuations: defaultEvacuations(),
  diaper: 0,
  shower: 0,
  occurrences: [defaultOccurrence()],
  occurrences_to_destroy: [],
  observation: '',
  answers_attributes: {},
};

export const DiaryStudentsFormContext = createContext<Props>({
  form: { values: initialValuesInfo },
} as Props);

export const DiaryStudentsFormProvider: React.FC<ContextProps> = ({
  children,
  classroomId,
}) => {
  const dispatch = useDispatch();
  const { saveDiary, fetchStudentDiaryForm } = DiaryActions;
  const [date, setDate] = useState(moment(new Date()));
  const [isSaving, setIsSaving] = useState(false);
  const [studentsIdsSelecteds, setStudentsIdsSelecteds] = useState<string[]>(
    []
  );

  const {
    diarySections: { activesSections },
    studentDiaryForm,
  } = useSelector((state: DiaryState) => state.dailySummaries);

  const onSuccess = () => {
    setIsSaving(false);
    setStudentsIdsSelecteds([]);
    form.setValues(initialValues);
  };
  const onFail = () => {
    setIsSaving(false);
  };

  const onSubmitDiary = (values: DiaryFormStructure['studentsForm']) => {
    setIsSaving(true);
    dispatch(
      saveDiary({
        params: {
          ...values,
          date: date.format('YYYY-MM-DD'),
          student_profile_ids: studentsIdsSelecteds,
          classroom_id: classroomId,
          daily_summary: values,
        },
        onSuccess,
        onFail,
      })
    );
  };

  const initialValues = useMemo(() => {
    const cloneInitialInfoValues = _.cloneDeep(initialValuesInfo);

    for (const section of activesSections) {
      if (section.custom) {
        cloneInitialInfoValues.answers_attributes[section.id] = [[]];

        for (const question of section.questionsAttributes) {
          cloneInitialInfoValues.answers_attributes[section.id][0].push(
            defaultAnswersAttributesValues(question.id)
          );
        }
      }
    }

    if (studentDiaryForm && studentsIdsSelecteds.length === 1) {
      const newDiaryValues = {
        ...cloneInitialInfoValues,
        ...studentDiaryForm,
        answers_attributes: {
          ...cloneInitialInfoValues.answers_attributes,
          ...studentDiaryForm.answers_attributes,
        },
      };

      return newDiaryValues;
    }

    return cloneInitialInfoValues;
  }, [activesSections, studentDiaryForm, studentsIdsSelecteds]);

  const formValidationSchema = yup.object().shape({
    occurrences: yup.array().of(
      yup.object().shape({
        files: yup
          .array()
          .of(
            yup.object().shape({
              invalidReasons: yup.array().max(0),
            })
          )
          .max(10),
      })
    ),
  });

  const form = useFormik({
    initialValues,
    onSubmit: onSubmitDiary,
    isInitialValid: true,
    enableReinitialize: true,
    validationSchema: formValidationSchema,
  });

  useEffect(() => {
    if (studentsIdsSelecteds.length === 1) {
      dispatch(
        fetchStudentDiaryForm({
          params: {
            classroomId,
            date: date.format('YYYY-MM-DD'),
            studentProfileId: studentsIdsSelecteds[0],
          },
          onFail: () => setStudentsIdsSelecteds([]),
        })
      );
    }
  }, [
    classroomId,
    date,
    dispatch,
    fetchStudentDiaryForm,
    studentsIdsSelecteds,
  ]);

  return (
    <DiaryStudentsFormContext.Provider
      value={{
        form,
        setDate,
        date,
        studentsIdsSelecteds,
        setStudentsIdsSelecteds,
        isSaving,
        classroomId,
      }}
    >
      {children}
    </DiaryStudentsFormContext.Provider>
  );
};
