export const columnsMargins = {
  desktopLG: 'sm',
  desktopXL: 'md',
};

export const subtleIconSpace = {
  _: 'xs2',
  desktopLG: 'sm',
  desktopXL: 'md',
};

export const statusBoxProps = {
  display: 'flex',
  alignItems: 'center',
  height: '100%',
};
