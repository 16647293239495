import React from 'react';
import { components } from 'react-select';

function FormClassroomSelectorNoOptionsMessage(props) {
  return (
    <components.NoOptionsMessage {...props}>
      Nenhuma turma encontrada
    </components.NoOptionsMessage>
  );
}

export default FormClassroomSelectorNoOptionsMessage;
