import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Text,
  Button,
  CardList,
  FlexGrid,
  FlexGridItem,
  SubtleIconButton,
  Tag,
} from '@agendaedu/ae-web-components';
import withAppContext from 'core/hoc/withAppContext';
import withToastMessage from 'core/hoc/withToastMessage';
import PageContainer from 'components/PageContainer';
import Toast from 'components/Toast';
import Skeleton from 'components/Skeleton';
import Breadcrumb from 'components/_DS/Breadcrumb';
import ValidationModal from '../components/ValidationModal';
import SkeletonList from '../components/SkeletonList';
import SkeletonBreadcrumb from '../components/SkeletonBreadcrumb';
import { useTranslation } from 'react-i18next';
import { formatDescriptiveDate } from 'core/helper/dates';
import SchoolTermsActions from 'store/schoolTerms/actions';
import { modalActions } from 'core/constants/schoolTerms';
import { trackEvent } from '../../../config/amplitude';

import * as S from './styles';

const SchoolTermsList = ({ history }) => {
  const { t } = useTranslation(['school_terms']);
  const dispatch = useDispatch();

  const { getListPeriods, getListClassrooms, deletePeriod, activatePeriod } =
    SchoolTermsActions;
  const { periods } = useSelector((state) => state.schoolTerms);

  const [oldAndNewPeriodName, setOldAndNewPeriodName] = useState();
  const [containsNewPeriod, setContainsNewPeriod] = useState(false);

  const [validationModal, setValidationModal] = useState({
    isOpen: false,
    variant: null,
    periodId: null,
    periodsNames: null,
  });

  const resetModal = () =>
    setValidationModal({
      isOpen: false,
      variant: null,
      periodId: null,
    });

  useEffect(() => {
    dispatch(getListPeriods());
    dispatch(getListClassrooms());
  }, [dispatch, getListPeriods, getListClassrooms]);

  useEffect(() => {
    const _oldAndNewPeriodName = periods?.reduce(
      (object, period) => {
        if (period.attributes.can_be_activated)
          return { ...object, periodName: period.attributes.name };

        if (period.attributes.status)
          return { ...object, oldPeriodName: period.attributes.name };

        return object;
      },
      { periodName: '', oldPeriodName: '' }
    );
    setOldAndNewPeriodName(_oldAndNewPeriodName);

    const _containsNewPeriod = periods
      ?.map((period) => period.attributes.can_be_activated)
      .includes(true);

    setContainsNewPeriod(_containsNewPeriod);
  }, [periods]);

  const renderActionsItems = (id, isActivatedPeriod) => {
    return [
      {
        as: 'button',
        title: t('list.edit'),
        onClick: () => {
          setValidationModal({
            isOpen: true,
            variant: modalActions.editPeriod,
            periodId: id,
          });
        },
      },
      {
        as: 'button',
        title: t('list.delete'),
        disabled: isActivatedPeriod,
        onClick: () => {
          setValidationModal({
            isOpen: true,
            variant: modalActions.deletePeriod,
            periodId: id,
          });
        },
      },
    ];
  };

  const submitModal = (variantAction) => {
    switch (variantAction) {
      case modalActions.createPeriod:
        history.push(`/schools/school_terms/new`);
        trackEvent('open_new_school_terms');
        break;
      case modalActions.deletePeriod:
        dispatch(deletePeriod(validationModal.periodId));
        trackEvent('delete_new_school_terms');
        break;
      case modalActions.editPeriod:
        history.push(`/schools/school_terms/${validationModal.periodId}/edit`);
        trackEvent('open_edit_school_terms');
        break;
      case modalActions.activatePeriod:
        dispatch(activatePeriod(validationModal.periodId));
        trackEvent('activate_period');
        break;
      default:
      // all submitModal actions must have a case
    }

    resetModal();
  };

  const periodsList = periods?.map(({ id, attributes }) => {
    return (
      <Box key={`period-${id}`} width="100%" display="flex" flexWrap="wrap">
        <FlexGrid>
          <FlexGridItem
            cols={{ desktopLG: 3, mobileSM: 2, mobileXS: 4 }}
            filled
          >
            <Box ml={20} display="flex" flexDirection="column">
              <Text
                variant="subtitle-medium-12"
                color="neutral.gray2"
                fontWeight={500}
                lineHeight="27.2px"
              >
                {t(`list.label_school_period`)}
              </Text>

              <Text
                variant="subtitle-medium-16"
                color="neutral.gray1"
                fontWeight={500}
                lineHeight="27.2px"
              >
                {attributes.name}
              </Text>
            </Box>
          </FlexGridItem>

          <FlexGridItem
            cols={{ desktopLG: 6, mobileSM: 6, mobileXS: 4 }}
            filled
          >
            <Box display="flex" flexDirection="column">
              <Text
                variant="subtitle-medium-12"
                color="neutral.gray2"
                fontWeight={500}
                lineHeight="27.2px"
              >
                {t(`list.label_history`)}
              </Text>

              <Text
                variant="subtitle-medium-16"
                color="neutral.gray1"
                fontWeight={500}
                lineHeight="27.2px"
              >
                {t(`list.last_update`, {
                  user: attributes?.last_modified_by
                    ? `${attributes.last_modified_by},`
                    : ' ',
                  date: attributes.updated_at
                    ? formatDescriptiveDate(attributes.updated_at)
                    : '',
                })}
              </Text>
            </Box>
          </FlexGridItem>

          <FlexGridItem
            cols={{ desktopLG: 2, mobileSM: 2, mobileXS: 2 }}
            filled
          >
            <Box
              width="100%"
              display="flex"
              flexDirection="row"
              justifyContent="flex-end"
              alignItems="flex-end"
            >
              <Box
                height="74px"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <S.ContainerSubButton>
                  {(attributes.status || attributes.can_be_activated) && (
                    <SubtleIconButton
                      variants="primary"
                      actions={renderActionsItems(id, attributes.status)}
                      align="right"
                    />
                  )}
                </S.ContainerSubButton>
              </Box>
            </Box>
          </FlexGridItem>

          <FlexGridItem
            cols={{ desktopLG: 1, mobileSM: 2, mobileXS: 2 }}
            filled
          >
            <Box
              width="100%"
              display="flex"
              flexDirection="row"
              justifyContent="flex-end"
              alignItems="flex-end"
            >
              <Box
                height="74px"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                {attributes.can_be_activated ? (
                  <Button
                    className="tour-ativar"
                    role="button"
                    variation="primary"
                    onClick={() => {
                      setValidationModal({
                        isOpen: true,
                        variant: modalActions.activatePeriod,
                        periodId: id,
                        periodsNames: oldAndNewPeriodName,
                      });
                    }}
                  >
                    {t(`list.activate`)}
                  </Button>
                ) : (
                  <Tag
                    name={
                      attributes.status
                        ? t(`list.tag_active`)
                        : t(`list.tag_inactive`)
                    }
                    size="large"
                    variant={attributes.status ? 'positive' : 'neutral'}
                  />
                )}
              </Box>
            </Box>
          </FlexGridItem>
        </FlexGrid>
      </Box>
    );
  });

  if (periodsList.length == 0)
    return (
      <Box mt={-15}>
        <SkeletonBreadcrumb />
        <PageContainer>
          <SkeletonList />
        </PageContainer>
      </Box>
    );

  return (
    <PageContainer
      breadcrumb={
        <Breadcrumb title="Períodos letivos" path="/schools/school_terms" />
      }
    >
      <Box
        as="section"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        mb={13}
      >
        <Text
          variant="title-bold-20"
          color="neutral.black"
          fontWeight={700}
          lineHeight={2.1}
        >
          {t('list.title')}
        </Text>

        <Button
          size="md"
          disabled={containsNewPeriod}
          onClick={() => {
            setValidationModal({ isOpen: true, variant: 'createPeriod' });
          }}
          variant="primary"
          className="tour-novo-periodo"
        >
          {t('list.button_new_period')}
        </Button>
      </Box>
      <Text
        variant="label-regular-16"
        color="neutral.gray2"
        fontWeight={400}
        lineHeight="27px"
        maxWidth="90%"
      >
        {t('list.sub_title')}
      </Text>
      <Box pt={24}>
        <CardList
          loader={
            <Skeleton
              width="100%"
              height={80}
              primaryColor="neutral.gray6"
              borderRadius="radius.md"
              isFullWidth
            />
          }
          initialLoad={true}
          threshold={200}
          pageStart={1}
          marginBottom="md"
        >
          {periodsList}
        </CardList>
      </Box>

      <ValidationModal
        variant={validationModal.variant}
        isModalOpen={validationModal.isOpen}
        periodsNames={validationModal.periodsNames}
        onSubmit={submitModal}
        closeModal={() => {
          setValidationModal({ isOpen: false, variant: null });
        }}
      />
      <Toast />
    </PageContainer>
  );
};

export default withAppContext(withToastMessage(SchoolTermsList));
