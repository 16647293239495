import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import FormFullScreen from 'components/FormFullScreen';
import withAppContext, { appPropTypes } from 'core/hoc/withAppContext';
import Toast from 'components/Toast';
import Loader from 'components/Loader';
import StudentProfilesInformationsTab from 'pages/studentProfiles/FormTabs/InformationsTab';
import StudentProfilesActions from 'store/studentProfiles/actions';
import SchoolServices from 'core/services/School';
import UserServices from 'core/services/Users';
import normalizeParams from '../normalizeParams';
import { reviewFormRedirect } from 'core/utils/reviewFormRedirect';
import * as S from '../styles';

const StudentProfilesEdit = ({ match, appContext }) => {
  const { id } = match.params;
  const dispatch = useDispatch();
  const dataArea = useSelector((state) => state.root.dataArea);
  const {
    setStudentProfile,
    setReviewerStudentProfile,
    updateStudentProfile,
    approveStudentProfile,
    removeStudentProfile,
  } = StudentProfilesActions;
  const {
    form: current,
    formMeta,
    isLoading,
    isSubmitting,
  } = useSelector((state) => state.studentProfiles);
  const { policies } = appContext;
  const schoolServices = new SchoolServices(dataArea);
  const userServices = new UserServices(dataArea);
  const { can_use_legacy_id: canUseLegacyId } = policies;

  const formType = match.url.split('/').slice(-1)[0];
  const { t } = useTranslation(['student_profiles']);

  const validateLegacyId = async (formContext) => {
    const { form, removeErrorOnAttribute, addErrorOnAttribute } = formContext;
    try {
      await schoolServices.validateLegacyId(form.legacy_id, 'student_profiles');
      removeErrorOnAttribute('legacy_id');
    } catch {
      addErrorOnAttribute(
        'legacy_id',
        t('form.legacy_id.already_used_message')
      );
    }
  };

  const validateEmail = async (formContext) => {
    const { form, removeErrorOnAttribute, addErrorOnAttribute } = formContext;
    try {
      await userServices.verifyEmail(form.email, 'student_profiles');
      addErrorOnAttribute('email', t('form.email.already_used_message'));
    } catch {
      removeErrorOnAttribute('email');
    }
  };

  const validateUsername = async (formContext) => {
    const { form, removeErrorOnAttribute, addErrorOnAttribute } = formContext;
    try {
      await userServices.verifyUsername(form.username, 'student_profiles');
      addErrorOnAttribute('username', t('form.username.already_used_message'));
    } catch {
      removeErrorOnAttribute('username');
    }
  };

  const handleSetStudentProfile = useCallback(() => {
    dispatch(setStudentProfile(id));
  }, [dispatch, setStudentProfile, id]);

  const handleSetReviewerStudentProfile = useCallback(() => {
    dispatch(setReviewerStudentProfile(id));
  }, [dispatch, setReviewerStudentProfile, id]);

  useEffect(() => {
    formType == 'review'
      ? handleSetReviewerStudentProfile()
      : handleSetStudentProfile();
  }, [formType, handleSetReviewerStudentProfile, handleSetStudentProfile]);

  const onSubmit = async (
    formContext,
    _,
    redirect = `/${dataArea}/student_profiles`
  ) => {
    const { form, hasErrorOnAttribute } = formContext;

    if (form.legacy_id && form.legacy_id !== current.legacy_id)
      await validateLegacyId(formContext);

    if (!canUseLegacyId) delete form.legacy_id;

    if (form.email && form.email !== current.email)
      await validateEmail(formContext);

    if (form.username && form.username !== current.username)
      await validateUsername(formContext);

    const hasErrorOnLegacyId = hasErrorOnAttribute('legacy_id');
    const hasErrorOnEmail = hasErrorOnAttribute('email');
    const hasErrorOnUserName = hasErrorOnAttribute('username');

    const isFormValid =
      !hasErrorOnLegacyId && !hasErrorOnEmail && !hasErrorOnUserName;

    if (formType == 'review') {
      redirect = reviewFormRedirect(redirect, formMeta.nextReviewProfileId);
    }

    if (isFormValid) {
      formType == 'review'
        ? dispatch(
            approveStudentProfile(id, {
              form: normalizeParams(form, policies, formType),
              redirect,
            })
          )
        : dispatch(
            updateStudentProfile(id, {
              form: normalizeParams(form, policies, formType),
              redirect,
            })
          );
    }
  };

  const onSubmitAndNewRegister = async (formContext) => {
    await onSubmit(
      formContext,
      null,
      `/${dataArea}/student_profiles/${formMeta.nextReviewProfileId}/review`
    );
  };

  const onRemove = () => {
    dispatch(removeStudentProfile(id));
  };

  return (
    <S.PageContainer id="StudentProfilesEdit" className="StudentProfilesEdit">
      <Loader isLoading={isLoading}>
        <FormFullScreen
          action={formType == 'review' ? 'review' : 'edit'}
          titlePage={
            formType == 'review' ? t('form.review.title') : t('form.edit.title')
          }
          titleModal={t('form.edit.modal_title')}
          contentModal={
            formType == 'review'
              ? t('form.review.modal_content')
              : t('form.edit.modal_content')
          }
          toggleModalWithFormUpdates={formType == 'review' ? false : true}
          onSubmit={onSubmit}
          onSubmitAndNewRegister={
            formType == 'review' && formMeta.nextReviewProfileId
              ? onSubmitAndNewRegister
              : undefined
          }
          form={{
            ...current,
            ...current?.billing_information,
            phone: current.phone,
          }}
          formMeta={
            formType == 'review'
              ? {
                  ...formMeta,
                  nextStepRequiredFields: ['name', 'classroom_ids'],
                }
              : formMeta
          }
          isSubmitting={isSubmitting}
          steps={[StudentProfilesInformationsTab]}
          backTo={`/${dataArea}/student_profiles`}
          onRemove={formType == 'review' ? onRemove : undefined}
          backToExternal
          finishText={
            formType == 'review'
              ? t('form.review.finish_button_text')
              : t('form.edit.finish_button_text')
          }
          finishAndNewText={t('form.review.finish_and_new_button_text')}
        />
      </Loader>
      <Toast />
    </S.PageContainer>
  );
};

StudentProfilesEdit.propTypes = {
  ...appPropTypes,
  match: PropTypes.object.isRequired,
};

export default withAppContext(StudentProfilesEdit);
