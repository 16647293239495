import React from 'react';

import reduxProvider from 'core/hoc/reduxProvider';
import { Route, Switch } from 'react-router-dom';

import Reports from 'pages/reports';
import ReportNew from 'pages/reports/ReportNew';

const ReportsScreen = () => {
  return (
    <div className="ReportsMainScreen">
      <Switch>
        <Route exact path="/*/reports" render={() => <Reports />} />
        <Route exact path="/*/reports/new" render={() => <ReportNew />} />
      </Switch>
    </div>
  );
};

export default reduxProvider(ReportsScreen);
