import React, { Component } from 'react';
import TabsBoxContainer from 'components/TabsBoxContainer';
import RejectSchoolProductTab from 'components/Payments/RejectSchoolProductTab';
import AlertContext from 'core/contexts/Alert';

class RejectSchoolProduct extends Component {
  state = {
    alert: '',
  };

  getTabs() {
    const schoolProductId = this.props.match.params.id;

    return [
      {
        Component: RejectSchoolProductTab,
        props: {
          schoolProductId,
        },
      },
    ];
  }

  getBreadCrumb() {
    const paymentId = this.props.match.params.id;

    return {
      title: 'Detalhes da cobrança',
      path: `/schools/school_products/${paymentId}`,
      subTitle: 'Pagamentos',
    };
  }

  render() {
    return (
      <div className="RejectSchoolProduct">
        <AlertContext.Provider value={this.state}>
          <TabsBoxContainer
            breadcrumb={this.getBreadCrumb()}
            title="Motivo da não aprovação"
            tabs={this.getTabs()}
          />
        </AlertContext.Provider>
      </div>
    );
  }
}

export default RejectSchoolProduct;
