import styled, { css } from 'styled-components';
import { StyleProps } from './types';

export const SideFilterWrapper = styled.div`
  display: flex;
  width: 40px;

  button {
    padding: 12px 7px;
  }
`;

export const FiltersWrapper = styled.div<StyleProps>`
  ${({ hasSideFilter, theme: { space, breakpoints } }) => css`
    display: flex;
    flex-direction: row;
    gap: ${space.lg};
    width: 100%;

    ${SideFilterWrapper} {
      margin-left: ${hasSideFilter ? '80px' : '138px'};
    }

    @media screen and (max-width: ${breakpoints.desktopLG}) {
      flex-direction: column;
      ${SideFilterWrapper} {
        display: ${!hasSideFilter && 'none'};
        margin-left: 0px;
      }
    }

    @media screen and (max-width: ${breakpoints.desktopXL}) {
      ${SideFilterWrapper} {
        margin-left: ${space.lg};
        display: ${!hasSideFilter && 'none'};
      }
    }
  `}
`;
