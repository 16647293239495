import React from 'react';
import { Tag } from '@agendaedu/ae-web-components';
import icons from '../icons/icons.json';
import { useTranslation } from 'react-i18next';

import * as S from './styles';
import { Props, CardListProps } from './types';
import { opacity } from 'core/utils/colorOpacity';
import EmptyState from 'components/EmptyState';
import { FlexGrid, FlexGridItem } from '@agendaedu/ae-web-components';
import { trackEvent } from 'config/amplitude';

const CardComponent: React.FC<Props> = ({
  name,
  description,
  logoUrl,
  partnerName,
  platforms,
  ...otherProps
}) => (
  <S.WrapperCard {...otherProps}>
    <S.WrapperContainer>
      {logoUrl ? (
        <S.Logo src={logoUrl} alt="Logo" />
      ) : (
        <S.SquareIcon bgcolor={opacity('#773DD3', 0.1)}>
          <svg
            width={40}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1024 1024"
          >
            <g fill="#773DD3">
              <path d={icons[name?.replace('i_', '')]} />
            </g>
          </svg>
        </S.SquareIcon>
      )}
    </S.WrapperContainer>

    <S.WrapperContent>
      <S.Title>{name}</S.Title>
      <S.Text>{description}</S.Text>
    </S.WrapperContent>
    <S.BodyContainer>
      <S.IconTag name="user-group" partnerName={partnerName} />

      {!!partnerName && (
        <S.ByTag partnerName={partnerName}>
          Criado por {''}
          <S.TextTag partnerName={partnerName}>{partnerName}</S.TextTag>
        </S.ByTag>
      )}
    </S.BodyContainer>

    <S.BottomContainer>
      <S.BottomLeftColumn>
        {!!platforms &&
          platforms.map((item, key) => (
            <Tag key={key} name={item} variant="neutral" p={8} size="small" />
          ))}
      </S.BottomLeftColumn>
    </S.BottomContainer>
  </S.WrapperCard>
);

const CardList: React.FC<CardListProps> = ({
  meta,
  listProductsHubstore,
  history,
}) => {
  const { t } = useTranslation(['hub_store']);

  if (listProductsHubstore.length <= 0) {
    return (
      <EmptyState
        imgUrl="https://static.agendaedu.com/survey/app_error.png"
        message={t('empty_state.message')}
      />
    );
  }

  const handleDetails = ({ id, appName }) => {
    trackEvent('hubstore_access_product', {
      id: id,
      name: appName,
    });
    history.push(`/schools/hub_store/products/${id}`);
  };

  return (
    <FlexGrid gutter="lg">
      {listProductsHubstore.map((card, index) => {
        const { attributes } = card;

        return (
          <FlexGridItem
            gutter="lg"
            key={index + card.id}
            cols={{
              mobileXS: 12,
              mobileSM: 6,
              tabletMD: 4,
              desktopLG: 4,
              desktopXL: 3,
            }}
            filled={false}
          >
            <CardComponent
              key={index + card.id}
              id={card.id}
              name={attributes.title}
              description={attributes.description}
              logoUrl={attributes.app.data.attributes.logo}
              partnerName={attributes.app.data.attributes.partner_name}
              platforms={attributes.app.data.attributes.platforms}
              isActive={meta?.active_products?.includes(Number(card.id))}
              onClick={() =>
                handleDetails({
                  id: card.id,
                  appName: attributes.title,
                })
              }
            />
          </FlexGridItem>
        );
      })}
    </FlexGrid>
  );
};

export default CardList;
