import React from 'react';
import * as S from './styles';
import { RefundModalProps } from './types';

import AlertModal from 'components/AlertModal';
import ButtonRow from 'components/ButtonRow';
import Loader from 'components/Loader';
import ModalTitle from 'components/Modal/ModalTitle';

const RefundModal = ({
  showModalRefundOrder,
  toggleRefundOrderModal,
  isModalLoading,
  orderId,
  studentName,
  onSubmit,
  showModalRefundSuccess,
  closeAlertModal,
}: RefundModalProps) => {
  return (
    <>
      {showModalRefundOrder && (
        <S.ModalWrapperRefundOrder
          isOpen={showModalRefundOrder}
          toggleModal={toggleRefundOrderModal}
        >
          {isModalLoading ? (
            <Loader />
          ) : (
            <div>
              <ModalTitle>Deseja reembolsar o responsável?</ModalTitle>

              {!!studentName &&
               <span className="responsible-for">
                 `Responsável pelo aluno(a): ${studentName}`
               </span>}

              <ButtonRow
                buttons={[
                  {
                    text: 'Cancelar',
                    variation: 'secondary',
                    onClick: toggleRefundOrderModal,
                  },
                  {
                    text: 'Reembolsar',
                    onClick: () => onSubmit(orderId),
                  },
                ]}
              />
            </div>
          )}
        </S.ModalWrapperRefundOrder>
      )}
      <AlertModal
        title="O responsável será reembolsado"
        type="success"
        isOpen={showModalRefundSuccess}
        closeAlert={closeAlertModal}
        onConfirm={closeAlertModal}
      >
        O reembolso é processado em até 7 dias.
      </AlertModal>
    </>
  );
};

export default RefundModal;
