import React from 'react';
import PropTypes from 'prop-types';

import Chart from 'components/Chart';

import * as S from './styles';

const ChartView = ({ charts }) => {
  return (
    <S.ViewContainer>
      {charts.map((chart, index) => {
        const { url, title, button = null } = chart;

        return <Chart key={index} url={url} title={title} button={button} />;
      })}
    </S.ViewContainer>
  );
};

ChartView.propTypes = {
  charts: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      title: PropTypes.string,
      button: PropTypes.shape({
        link: PropTypes.string,
        label: PropTypes.string,
      }),
    })
  ).isRequired,
};

export default ChartView;
