import React, { useEffect, useCallback } from 'react';
import Field from 'components/Form/Field';
import FormFieldset from 'components/Form/Fieldset';
import { RIGHT_CENTER } from 'components/Tooltip';
import AgendaIcon from 'components/AgendaIcon';
import tabifyForWizard from 'core/hoc/tabifyForWizard';
import composeFunctions from 'core/utils/composeFunctions';
import withFormContext, { formPropTypes } from 'core/hoc/withFormContext';
import withAppContext from 'core/hoc/withAppContext';
import withFormInputFocused from 'core/hoc/withFormInputFocused';
import * as S from './styles';

const tooltipText = `
  Este é o antigo Legacy ID, e serve para 
  auxiliar a identificação em suas integrações
`;

const tooltipStyle = {
  maxWidth: '285px',
};

const HeadquarterInformationsTab = ({
  formContext,
  appContext: { policies },
}) => {
  const { changeMeta, hasUpdatesOnForm } = formContext;
  const { can_use_legacy_id: canUseLegacyId } = policies;

  const hasUpdates = useCallback(() => {
    const formUpdates = hasUpdatesOnForm();
    !formUpdates && changeMeta('nextStepDisabled', true);
  }, [changeMeta, hasUpdatesOnForm]);

  // TODO: Create a separate approach for this validation
  const validateNextStepButton = useCallback(
    (form) => {
      const allRequiredFieldsAreFilled = form.name;
      const formUpdates = hasUpdatesOnForm();

      changeMeta(
        'nextStepDisabled',
        !(allRequiredFieldsAreFilled && formUpdates)
      );
    },
    [changeMeta, hasUpdatesOnForm]
  );

  const onChange = useCallback(
    (form) => {
      validateNextStepButton(form);
    },
    [validateNextStepButton]
  );

  useEffect(() => {
    hasUpdates();
  }, [hasUpdates]);

  const labelWithTooltip = () => (
    <S.CustomLabel>
      <span>Identificador de registro</span>
      <S.CustomTooltip
        content={tooltipText}
        position={RIGHT_CENTER}
        tooltipStyle={tooltipStyle}
      >
        <AgendaIcon name="help" size="small" />
      </S.CustomTooltip>
    </S.CustomLabel>
  );

  return (
    <div className="NewHeadquarterForm">
      <S.Wrapper>
        <FormFieldset>
          {canUseLegacyId && (
            <div className="row">
              <Field
                label={labelWithTooltip()}
                placeholder=""
                attributeName="legacy_id"
                onChange={onChange}
              />
            </div>
          )}
          <div className="row">
            <Field
              label="Nome da unidade*"
              placeholder="ex. Colégio Jabuti do Sul I"
              attributeName="name"
              onChange={onChange}
            />
          </div>
        </FormFieldset>
      </S.Wrapper>
    </div>
  );
};

HeadquarterInformationsTab.propTypes = {
  ...formPropTypes,
};

export default composeFunctions(
  withFormInputFocused,
  withFormContext,
  withAppContext,
  tabifyForWizard({
    title: '',
    subTitle: 'Informações gerais',
  })
)(HeadquarterInformationsTab);
