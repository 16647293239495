import React from 'react';
import { Box, DefaultThemeProps, theme } from '@agendaedu/ae-web-components';

import Skeleton from 'components/Skeleton';

export const MediaDetailsSkeleton = () => {
  const defaultTheme: DefaultThemeProps = theme;

  const {
    colors: { neutral },
  } = defaultTheme;

  return (
    <Box
      key="skeleton"
      position="absolute"
      display="flex"
      width="100%"
      height="100%"
      minWidth="400px"
      minHeight="30vh"
      mt="1px"
      zIndex={1}
    >
      <Skeleton
        width="100%"
        height="100%"
        primaryColor={neutral.gray6}
        isFullWidth
      />
    </Box>
  );
};

export const DescriptionMediaDetailsSkeleton = () => {
  const defaultTheme: DefaultThemeProps = theme;

  const {
    colors: { neutral },
    border: { radius },
  } = defaultTheme;

  return (
    <Box key="skeleton" display="flex" my="sm" height="40px">
      <Skeleton
        width="100%"
        height="100%"
        primaryColor={neutral.gray6}
        borderRadius={radius.md}
        isFullWidth
      />
    </Box>
  );
};
