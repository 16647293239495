import React from 'react';
import { Grid, DefaultThemeProps, theme } from '@agendaedu/ae-web-components';

import Skeleton from 'components/Skeleton';

import * as S from './styles';

export const SchoolProfileStepSkeleton = () => {
  const defaultTheme: DefaultThemeProps = theme;

  const {
    colors: { neutral },
    border: { radius },
  } = defaultTheme;

  return (
    <S.FormSkeletonWrapper data-testid="skeleton-wrapper">
      <Grid
        gridTemplateColumns="1fr"
        gutter={{
          _: 'lg',
        }}
      >
        <Grid item gutter="sm">
          <Skeleton
            width={140}
            height={35}
            primaryColor={neutral.gray6}
            borderRadius={radius.md}
          />
        </Grid>

        <Skeleton
          width={180}
          height={35}
          borderRadius={radius.md}
          primaryColor={neutral.gray6}
        />

        <Grid item gutter={'md'}>
          <Skeleton
            width="50%"
            isFullWidth
            height={40}
            primaryColor={neutral.gray6}
            borderRadius={radius.md}
          />

          <Skeleton
            width="100%"
            height={300}
            primaryColor={neutral.gray6}
            borderRadius={radius.md}
            isFullWidth
          />
        </Grid>
      </Grid>
    </S.FormSkeletonWrapper>
  );
};
