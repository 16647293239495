import { Icon, Text } from '@agendaedu/ae-web-components';
import { ThemeProps } from 'config/themes/types';
import styled, { css } from 'styled-components';
import { MessagesWrapperStyleProps, WrapperStyleProps } from './types';

export const Wrapper = styled.div<WrapperStyleProps>`
  ${({ isActiveChat, theme: { breakpoints, border, colors } }) => css`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    background-color: ${colors.neutral.white};
    border-right: ${border.width.sm} ${border.style.solid}
      ${colors.neutral.gray4};

    @media screen and (max-width: ${breakpoints.desktopXL}) {
      ${!isActiveChat &&
      css`
        display: none;
      `}
    }
  `}
`;

export const MessagesWrapper = styled.div<MessagesWrapperStyleProps>`
  ${({ height }) => css`
    display: flex;
    flex-direction: column;
    height: ${height - 80}px;
  `}
`;

export const EmptyStateWrapper = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    gap: ${space.md};
  `}
`;

export const IconMessage = styled(Icon)`
  height: 60px !important;
  width: 60px !important;
`;

export const EmptyStateLabel = styled(Text)`
  ${({ theme: { font } }: ThemeProps) => css`
    line-height: ${font.height.lg} !important;
    max-width: 200px;
    text-align: center;
  `}
`;
