import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  flex-direction: row
  background-color: white;
  box-shadow: ${({ theme }) => theme.shadow.card};
  border-radius: 8px;
  &::-webkit-scrollbar {
    background-color: ${({ theme }) => theme.white};
    width: 1px;
  }
  &::-webkit-scrollbar-button {
    display: none;
  }
  &::-webkit-scrollbar-track {
    background-color: white;
  }
  &::-webkit-scrollbar-thumb {
    background: transparent;
    box-shadow: 0px 0px 0px 10vh white;
    border-radius: 10px;
    border: 50%;
  }
`;

export const ContainerText = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  background-color: ${({ theme }) => theme.white};
  justify-content: center;
  align-items: center;
  border-top-left-radius: 11px;
  border-bottom-left-radius: 11px;

  @media (max-width: 768px) {
    flex-basis: 100%;
  }
`;

export const ContainerImage = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  background-color: ${({ theme }) => theme.white};
  justify-content: center;
  align-items: center;
  border-top-right-radius: 11px;
  border-bottom-right-radius: 11px;
  @media (max-width: 768px) {
    flex-basis: 100%;
  }
`;

export const Image = styled.img`
  max-width: 500px;
  width: 80%;
  height: auto;
  padding: 20px;
`;

export const Text = styled.p`
  line-height: 170%;
  font-family: ${({ theme }) => theme.font.family.auxiliary};
  font-style: normal;
  font-weight: 400;
  font-size: ${({ theme }) => theme.font.size.sm};
  width: 80%;
  color: ${({ theme }) => theme.colors.neutral.gray1};
  padding: 15px 0px;
  text-align: justify;
  text-justify: inter-word;
  white-space: pre-line;
`;

export const Title = styled.h4`
  ${({ theme: { colors, typography, font } }) => css`
    ${typography.title.Bold20};
    color: ${colors.neutral.gray1};
    font-size: ${font.size.xl};
    line-height: 24px;
    margin-top: 0px;
  `}
`;

export const ContainerTitle = styled.div`
  display: flex;
  flex-direction: row;
  width: 80%;
  margin-top: 13px;

  @media (max-width: 768px) {
    margin-top: 24px;
    width: 80%;
  }
`;
