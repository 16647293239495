function validateSentKind(input, state) {
  let result = false;

  if (input === 'students') {
    const object = state.classroom_with_student_profile_ids;
    Object.keys(object).forEach((key) => {
      if (object[key].length > 0) {
        result = true;
      }
    });
  } else if (input === 'classrooms' && state.classroom_ids.length > 0) {
    result = true;
  }

  return result;
}

function validatePersonaType(input, state) {
  return state.persona_type === null ? false : true;
}

export default [
  {
    attributeName: 'sent_kind',
    validator: validateSentKind,
    message: 'Selecione as turmas ou alunos destinatários',
  },
  {
    attributeName: 'persona_type_select',
    validator: validatePersonaType,
    message: 'Selecione ao menos um dos destinatários',
  },
];
