import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import withAppContext from 'core/hoc/withAppContext';
import withToastMessage from 'core/hoc/withToastMessage';
import ValidationModal from '../components/ValidationModal';
import { useTranslation } from 'react-i18next';
import FormFullScreen from 'components/FormFullScreen';
import Loader from 'components/Loader';
import Toast from 'components/Toast';
import SchoolTermsActions from 'store/schoolTerms/actions';
import { modalActions } from 'core/constants/schoolTerms';
import periodStepForm from '../components/periodStepForm';
import arrayFlatten from 'core/utils/arrayFlatten';
import { trackEvent } from '../../../config/amplitude';
import { Redirect } from 'react-router-dom';

const SchoolTermsEdit = ({ history, match }) => {
  const { id } = match.params;

  const { t } = useTranslation(['school_terms']);
  const dispatch = useDispatch();

  const { getPeriodDetails, saveEditPeriod } = SchoolTermsActions;
  const {
    isLoadingEdit,
    isSending,
    classrooms,
    activatedPeriodClassrooms,
    periodDetails,
  } = useSelector((state) => state.schoolTerms);

  useEffect(() => {
    dispatch(getPeriodDetails(id));
  }, [dispatch, getPeriodDetails, id]);

  const [validationModal, setValidationModal] = useState({
    isOpen: false,
    variant: null,
    dataForm: null,
  });

  const submitModal = () => {
    trackEvent('save_edit_school_terms');
    dispatch(saveEditPeriod(validationModal.dataForm, id, history));
    setValidationModal({ isOpen: false, variant: null, dataForm: null });
  };

  const onSubmit = ({ form }) => {
    setValidationModal({
      isOpen: true,
      variant: modalActions.saveEditPeriod,
      dataForm: form,
    });
  };

  const mountForm = () => {
    const currentClassRooms = periodDetails.attributes.status
      ? activatedPeriodClassrooms
      : classrooms;

    const selectedClassrooms = arrayFlatten(
      currentClassRooms.map((group) => group.options)
    )
      .filter((classroom) => classroom.attributes.progressed)
      .map((classroom) => classroom.id);

    return {
      classroom_ids: selectedClassrooms,
      name: periodDetails.attributes.name,
    };
  };

  return (
    <Loader isLoading={isLoadingEdit}>
      {!isLoadingEdit && (
        <FormFullScreen
          id="school-terms-create"
          action="edit"
          onSubmit={onSubmit}
          form={mountForm()}
          formMeta={{
            classrooms: classrooms,
            canEditClassrooms: false,
          }}
          isSubmitting={isSending}
          steps={[periodStepForm]}
          backTo="/schools/school_terms"
          backToExternal
          titlePage={t('edit_form.title')}
          titleModal={t('edit_form.title_modal')}
          contentModal={t('edit_form.content_modal')}
        />
      )}

      <ValidationModal
        variant={validationModal.variant}
        isModalOpen={validationModal.isOpen}
        onSubmit={submitModal}
        closeModal={() => {
          setValidationModal({ isOpen: false, variant: null });
        }}
      />
      <Toast />
    </Loader>
  );
};

export default withAppContext(withToastMessage(SchoolTermsEdit));
