import styled, { css } from 'styled-components';
import Tooltip from 'components/Tooltip';

export const Wrapper = styled.div`
  .FormTextField .TextField input {
    height: 40px;
    border-radius: 6px;
  }

  .Field .Label span {
    margin-right: 2px;
  }

  .DateInput input {
    height: 40px;
    border-radius: 6px;
  }

  .double-input {
    @media (max-width: 768px) {
      padding-left: 0;
    }
  }
`;

export const CustomLabel = styled.div`
  display: flex;
  align-items: center;
`;

export const CustomTooltip = styled(Tooltip)`
  .tooltip-div {
    position: relative;
    width: 16px;
    height: 16px;
  }
`;

export const DoubleInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-basis: 100%;

  .Field:only-child {
    width: 50%;
  }

  @media (max-width: 576px) {
    flex-direction: column;

    .Field:only-child {
      width: 100%;
    }
  }
`;

export const ToggleWrapper = styled.span`
  display: flex;
  align-items: center;

  font-size: 14px;
  color: ${(props) => props.theme.black};
  margin-bottom: 24px;

  > p {
    margin: 0 0 0 25px;
  }
`;

export const CheckerTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  h6 {
    margin-right: 2px;
    font-weight: 700;
    font-size: 14px;
    color: ${(props) => props.theme.black};
  }
`;

export const FormAlert = styled.div`
  ${({ theme: { colors, space, font } }) => css`
    display: block;
    padding: ${space.lg};
    margin-bottom: ${space.lg};
    font-family: ${font.family.brand};
    font-size: ${font.size.sm};
    font-weight: ${font.weight.bold};
    line-height: ${font.height.lg};
    color: ${colors.context.warning.default};
    background-color: ${colors.context.warning.op20};
    border-radius: 8px;
  `}
`;
