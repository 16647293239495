import React, { ChangeEvent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, TextField } from '@agendaedu/ae-web-components';

import { Recipient } from 'store/messages/omniChannel/types';

import * as S from './styles';

import { RecipientChatCard } from '../RecipientChatCard';

import { RecipientsSelectProps } from './types';

export const RecipientsSelect = ({
  addAllRecipients,
  recipientsList,
  onChange,
  selectedRecipients,
  selectedStudent,
}: RecipientsSelectProps): JSX.Element => {
  const { t } = useTranslation(['messages']);

  const [searchValue, setSearchValue] = useState<string>('');

  const filteredRecipients = useMemo(() => {
    const lowerSearchValue = searchValue.toLowerCase();

    return recipientsList?.filter(
      (recipient: Recipient) =>
        recipient.attributes.name.toLowerCase().includes(lowerSearchValue) ||
        recipient.type === 'family'
    );
  }, [searchValue, recipientsList]);

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchValue(value);
  };

  return (
    <S.Wrapper>
      <S.RecipientHeaderWrapper>
        <S.CountTotalRecipientsText variant="title-bold-12">
          {filteredRecipients.length > 1 || filteredRecipients.length === 0
            ? filteredRecipients.length +
              t(
                'omni_channel.forms.message_chat.fields.recipients_count_plural'
              )
            : filteredRecipients.length +
              t('omni_channel.forms.message_chat.fields.recipients_count')}
        </S.CountTotalRecipientsText>

        <Button
          variant="secondary"
          size="xs"
          onClick={() => addAllRecipients(recipientsList)}
        >
          {t('omni_channel.forms.message_chat.fields.select_all')}
        </Button>
      </S.RecipientHeaderWrapper>
      <S.RecipientSelectorWrapper>
        <TextField
          icon={'search'}
          fullWidth
          placeholder={t(
            'omni_channel.forms.message_chat.fields.button_find_text'
          )}
          onChange={handleSearchChange}
        />

        <S.SelectorWrapper>
          {filteredRecipients?.map((recipient, index) => {
            const isSelected = !!selectedRecipients?.find(
              (option) =>
                option.id === recipient.id && option.type === recipient.type
            );

            const recipientCardDisabled = selectedRecipients.find(
              (item) =>
                selectedStudent.id === item.studentProfileId &&
                ((item.type !== 'family' && recipient.type === 'family') ||
                  (item.type === 'family' && recipient.type !== 'family'))
            );
            return (
              <RecipientChatCard
                disabled={!!recipientCardDisabled}
                key={index}
                recipient={recipient}
                isSelected={isSelected}
                onChange={() =>
                  onChange(
                    [{ ...recipient, studentProfileId: selectedStudent.id }],
                    isSelected
                  )
                }
              />
            );
          })}
        </S.SelectorWrapper>
      </S.RecipientSelectorWrapper>
    </S.Wrapper>
  );
};
