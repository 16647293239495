import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Button } from '@agendaedu/ae-web-components';
import { Modal } from 'components/Handouts/Modal';

import withFormContext from 'core/hoc/withFormContext';

import { HandoutState } from 'store/handouts/types';

import { Props } from './types';

const TemplateEditModal: React.FC<Props> = ({
  handleOnSubmit,
  handleToggle,
  formContext: { form },
}) => {
  const { t } = useTranslation(['handouts']);

  const { showEditHandoutTemplateModal } = useSelector(
    (state: HandoutState) => state.handouts
  );

  return (
    <Modal.Root
      dataTestId="edit-modal"
      isOpen={showEditHandoutTemplateModal}
      handleToggleModal={handleToggle}
      maxWidth="396px"
      title={t('templates.edit_modal.title')}
    >
      <Modal.Content>{t('templates.edit_modal.description')}</Modal.Content>
      <Modal.Actions>
        <Button
          data-testid="back-button"
          variant="secondary"
          onClick={handleToggle}
        >
          {t('templates.edit_modal.back_button')}
        </Button>
        <Button
          data-testid="submit-button"
          onClick={() => handleOnSubmit(form)}
        >
          {t('templates.edit_modal.submit_button')}
        </Button>
      </Modal.Actions>
    </Modal.Root>
  );
};

export default withFormContext(TemplateEditModal);
