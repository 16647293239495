import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { CookiesProvider } from 'react-cookie';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import InternalErrorBoundary from 'boundaries/InternalErrorBoundary';
import reduxProvider from 'core/hoc/reduxProvider';
import New from 'pages/SummarydayClassrooms/New';
import Edit from 'pages/SummarydayClassrooms/Edit';
import SummarydayClassrooms from 'pages/SummarydayClassrooms/List';
import Details from 'pages/SummarydayClassrooms/Details';

const SummarydayClassroomsRoutes = ({ googleClientId }) => {
  const changeWrapperBackgroundColor = () => {
    document.getElementById('main-content').style.backgroundColor = '#ffffff';
  };

  useEffect(() => {
    changeWrapperBackgroundColor();
  }, []);

  return (
    <InternalErrorBoundary>
      <div className="ClassRoomsMainsScreen">
        <CookiesProvider>
          <BrowserRouter>
            <Switch>
              <Route
                path="/*/summaryday_classrooms/new"
                component={(props) => (
                  <New googleClientId={googleClientId} {...props} />
                )}
              />
              <Route
                path="/*/summaryday_classrooms/:id/edit"
                component={(props) => (
                  <Edit googleClientId={googleClientId} {...props} />
                )}
              />
              <Route
                exact
                path="/*/summaryday_classrooms"
                component={(props) => (
                  <SummarydayClassrooms
                    googleClientId={googleClientId}
                    {...props}
                  />
                )}
              />

              <Route
                path="/*/summaryday_classrooms/:id"
                component={(props) => (
                  <Details googleClientId={googleClientId} {...props} />
                )}
              />
            </Switch>
          </BrowserRouter>
        </CookiesProvider>
      </div>
    </InternalErrorBoundary>
  );
};

SummarydayClassroomsRoutes.propTypes = {
  googleClientId: PropTypes.string,
};

export default reduxProvider(SummarydayClassroomsRoutes);
