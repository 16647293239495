import React from 'react';
import AgendaIcon from '../AgendaIcon';
import * as S from './styles';

type ButtonRadiusProps = {
  onClick?: (...args: any[]) => any;
  badge?: number;
};

export function ButtonRadius({ onClick, badge }: ButtonRadiusProps) {
  return (
    <S.Container>
      {badge > 0 && <S.Badge>{badge}</S.Badge>}
      <S.Button onClick={onClick}>
        <AgendaIcon name="arrow-down" />
      </S.Button>
    </S.Container>
  );
}
