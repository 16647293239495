import React, { useEffect } from 'react';
import { CookiesProvider } from 'react-cookie';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import InternalErrorBoundary from 'boundaries/InternalErrorBoundary';
import composeFunctions from 'core/utils/composeFunctions';
import reduxProvider from 'core/hoc/reduxProvider';
import withAppContext from 'core/hoc/withAppContext';

import SchoolTermsList from 'pages/SchoolTerms/List';
import SchoolTermsNew from 'pages/SchoolTerms/New';
import SchoolTermsEdit from 'pages/SchoolTerms/Edit';

const SchoolTermsMainScreen = () => {
  const changeWrapperBackgroundColor = () => {
    document.getElementById('main-content').style.backgroundColor = '#ffffff';
  };

  useEffect(() => {
    changeWrapperBackgroundColor();
  }, []);

  return (
    <InternalErrorBoundary>
      <div className="SchoolTermsMainScreen">
        <CookiesProvider>
          <BrowserRouter>
            <Switch>
              <Route
                exact
                path="/schools/school_terms"
                component={(props) => <SchoolTermsList {...props} />}
              />
              <Route
                exact
                path="/schools/school_terms/new"
                component={(props) => <SchoolTermsNew {...props} />}
              />
              <Route
                exact
                path="/schools/school_terms/:id/edit"
                component={(props) => <SchoolTermsEdit {...props} />}
              />
            </Switch>
          </BrowserRouter>
        </CookiesProvider>
      </div>
    </InternalErrorBoundary>
  );
};

export default composeFunctions(
  withAppContext,
  reduxProvider
)(SchoolTermsMainScreen);
