const or = (a, b) => a | b;

export default function formatBytes(bytes) {
  if (bytes === 0) return '0 Bytes';
  const exp = or(Math.log(bytes) / Math.log(1024), 0);
  const result = (bytes / Math.pow(1024, exp)).toFixed(2);
  return result + ' ' + (exp == 0 ? 'bytes': 'KMGTPEZY'[exp - 1] + 'B');
}

export const normalizeFileSize = (size) => {
  return size < 1048576 ?
  (size / 1024).toFixed(2) + " kb" :
  (size / 1024 / 1024).toFixed(2) + " mb";
}
