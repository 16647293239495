import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';

import { Box, Icon, TextField } from '@agendaedu/ae-web-components';

import * as S from './styles';

const SurveySearchTitle = ({ callFetchSurveys }) => {
  const title = useSelector((state) => state.surveys.title_cont);
  const [titleSearch, setTitleSearch] = useState('');

  useEffect(() => {
    onChangeTitle(title);
  }, []);

  const onChangeTitle = (titleSearch) => {
    setTitleSearch(titleSearch);
  };

  const onClickClearButton = () => {
    setTitleSearch('');
    callFetchSurveys({ title: '' });
  };

  const handleSearchButton = (event) => {
    callFetchSurveys({ title: titleSearch });
    event.preventDefault();
  };

  return (
    <S.TitleSearchWrapper>
      <Box position="relative" width="100%" mb="sm">
        <TextField
          fullWidth
          placeholder="Buscar formulário"
          onKeyPress={(event) => {
            if (event.key === 'Enter') handleSearchButton(event);
          }}
          onChange={(event) => onChangeTitle(event.target.value)}
          value={titleSearch}
        />
        <Box position="absolute" top={8} right={8}>
          <Box display="flex" alignItems="center">
            {!!titleSearch && (
              <Icon name="multiply" onClick={onClickClearButton} size="sm" />
            )}
            <Icon name="search" onClick={handleSearchButton} />
          </Box>
        </Box>
      </Box>
    </S.TitleSearchWrapper>
  );
};

SurveySearchTitle.propTypes = {
  callFetchSurveys: PropTypes.func,
};

export default SurveySearchTitle;
