import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import AttachmentEntity from 'core/entities/Attachment';
import tabifyForWizard from 'core/hoc/tabifyForWizard';
import omniChannelActions from 'store/messages/omniChannel/actions';
import withAppContext from 'core/hoc/withAppContext';
import withFormContext from 'core/hoc/withFormContext';

import * as S from './styles';

import { AttachmentButton } from 'components/Messages/OmniChannel/Forms/AttachmentButton';
import ErrorAttachmentModal from 'components/Messages/OmniChannel/Modals/ErrorAttachmentModal';
import FormFieldset from 'components/Form/Fieldset';
import { RecipientsSelected } from 'components/Messages/RecipientsSelected';

import { RecipientsStepProps } from './types';

const SendMessageStep = ({
  appContext: { currentUserType, currentSchool },
  formContext: {
    changeMeta,
    updateAttribute,
    form: { recipients, message, attachment },
  },
}: RecipientsStepProps) => {
  const { t } = useTranslation(['messages']);
  const dispatch = useDispatch();
  const attachmentFileRef = useRef<HTMLInputElement>(null);
  const [attachmentError, setAttachmentError] = useState(null);

  const canGoToNextStep = recipients?.length && message.length > 0;

  const { toggleAttachmentModal } = omniChannelActions;

  const handleChangeAttribute = useCallback(
    (attributeName: string, value: string | AttachmentEntity | number) => {
      updateAttribute(attributeName, value);
    },
    [updateAttribute]
  );

  const handleToggleAttachmentModal = useCallback(() => {
    dispatch(toggleAttachmentModal());
  }, [dispatch, toggleAttachmentModal]);

  const handleToggleOnCloseAttachment = useCallback(() => {
    handleChangeAttribute('attachment', null);
    handleToggleAttachmentModal();
  }, [handleChangeAttribute, handleToggleAttachmentModal]);

  const handleToggleAttachmentMessage = useCallback(
    (event) => {
      if (event === null) return handleChangeAttribute('attachment', null);
      const currentAttachment = new AttachmentEntity(event.target.files[0]);

      if (
        currentAttachment.isTypeValid(currentUserType) &&
        currentAttachment.isSizeValid(currentUserType, currentSchool)
      ) {
        handleChangeAttribute('attachment', currentAttachment);
        setAttachmentError([]);
      } else {
        handleToggleAttachmentModal();

        attachmentFileRef.current.value = null;
        const errors = [];
        if (!currentAttachment.isTypeValid(currentUserType))
          errors.push('type');
        if (!currentAttachment.isSizeValid(currentUserType, currentSchool))
          errors.push('size');

        setAttachmentError(errors);
      }
    },
    [
      currentSchool,
      currentUserType,
      handleChangeAttribute,
      handleToggleAttachmentModal,
    ]
  );

  useEffect(() => {
    canGoToNextStep
      ? changeMeta('nextStepDisabled', false)
      : changeMeta('nextStepDisabled', true);
  }, [changeMeta, canGoToNextStep]);

  return (
    <FormFieldset>
      <S.RowWrapper>
        <S.StepTitle>
          {t('omni_channel.forms.message_chat.create.send_step')}
        </S.StepTitle>

        {recipients?.length > 0 && (
          <>
            <RecipientsSelected recipientsList={recipients} disabledActions />

            <S.TextAreaWrapper>
              <S.TextAreaLabel variant="label-regular-14">
                {t(
                  'omni_channel.forms.message_channel.fields.new_message_text_area_label'
                )}
              </S.TextAreaLabel>
              <S.TextArea
                value={message}
                onChange={({ target: { value } }) =>
                  handleChangeAttribute('message', value)
                }
              />
              <AttachmentButton
                fileRef={attachmentFileRef}
                attachment={attachment}
                onChange={handleToggleAttachmentMessage}
              />
            </S.TextAreaWrapper>
          </>
        )}

        {attachmentError?.length > 0 && (
          <ErrorAttachmentModal
            attachmentError={attachmentError}
            currentUserType={currentUserType}
            handleOnClose={handleToggleOnCloseAttachment}
          />
        )}
      </S.RowWrapper>
    </FormFieldset>
  );
};

export default tabifyForWizard({
  title: '2 Enviar mensagem',
})(withAppContext(withFormContext(SendMessageStep)));
