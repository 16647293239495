/* eslint-disable @typescript-eslint/no-unused-vars */
import { ValidFileType } from 'core/constants/storage';
import {
  ActionsSaga,
  FolderPath,
  Recipient,
  Storage,
  StorageFilter,
} from '../types';
import {
  ArtifactVersion,
  ClassroomIncluded,
  StorageRequest,
  StudentProfileIncluded,
} from './types';

import i18n from 'config/i18n';

export const normalizeFetchStoragesSuccess = (
  storages: StorageRequest[],
  included: StudentProfileIncluded[] | ClassroomIncluded[] | ArtifactVersion[]
) => {
  const storageList: Storage[] = [];

  storages.map(({ relationships, ...rest }) => {
    const classrooms: Recipient[] = [];
    const studentProfiles: Recipient[] = [];
    const versions: ArtifactVersion[] = [];

    relationships.classrooms.data.map((classroom: ClassroomIncluded) => {
      included.find((inc) => {
        if (inc.id === classroom.id)
          classrooms.push({
            name: inc.attributes.name,
          });
      });
    });

    relationships.studentProfiles.data.map((studentProfile) => {
      included.find((inc: StudentProfileIncluded) => {
        if (inc.id === studentProfile.id) {
          studentProfiles.push({
            name: inc.attributes.name,
            avatarColor: inc.attributes.avatarColor,
            nameInitials: inc.attributes.nameInitials,
          });
        }
      });
    });

    relationships.versions.data.map((artifactVersion) => {
      included.find((inc: ArtifactVersion) => {
        if (inc.id === artifactVersion.id) {
          versions.push(inc);
        }
      });
    });

    storageList.push({
      ...rest,
      attributes: {
        ...rest.attributes,
        classrooms,
        studentProfiles,
        versions,
      },
    });
  });

  return storageList;
};

export const normalizeSaveEditFolderRequest = (
  action: ActionsSaga['saveEditFolderRequestSaga'],
  parentFolderId: string
) => {
  const { params } = action;

  const students = Object.values(params.classroomWithStudentProfileIds).reduce(
    (acc, itens) => acc.concat(itens),
    []
  );

  const classroomsIds =
    params.receiverType === 'student'
      ? Object.keys(params.classroomWithStudentProfileIds)
      : params.classroomIds;

  const data = {
    title: params.title,
    description: params.description,
    kind: params.kind,
    available_to: params.availableTo,
    receiver_type: params.receiverType,
    classroom_ids: classroomsIds,
    is_root: !parentFolderId,
    student_profile_ids: students,
    parent_folder_id: parentFolderId ?? undefined,
  };

  return data;
};

export const normalizeSaveFolderRequest = (
  action: ActionsSaga['saveFolderRequestSaga']
) => {
  const { params } = action;

  const students = Object.values(params.classroomWithStudentProfileIds).reduce(
    (acc, itens) => acc.concat(itens),
    []
  );

  const classroomsIds =
    params.receiverType === 'student'
      ? Object.keys(params.classroomWithStudentProfileIds)
      : params.classroomIds;

  return {
    title: params.title,
    description: params.description,
    kind: params.kind,
    available_to: params.availableTo,
    receiver_type: params.receiverType,
    classroom_ids: classroomsIds,
    parent_folder_id: params.folderAscendingId,
    is_root: !params.folderAscendingId,
    student_profile_ids: students,
  };
};

export const normalizeSaveEditDocumentRequest = (
  action: ActionsSaga['saveEditDocumentRequestSaga'],
  parentFolderId: string
) => {
  const { params } = action;

  const students = Object.values(params.classroomWithStudentProfileIds).reduce(
    (acc, itens) => acc.concat(itens),
    []
  );

  const attachments = [];

  if (params?.attachments) {
    params.attachments.map((attachment) => {
      attachments.push(attachment.signed_id);
    });
  }

  const classroomsIds =
    params.receiverType === 'student'
      ? Object.keys(params.classroomWithStudentProfileIds)
      : params.classroomIds;

  return {
    title: params.title,
    description: params.description,
    kind: 'document',
    available_to: params.availableTo,
    receiver_type: params.receiverType,
    classroom_ids: classroomsIds,
    direct_upload_attachments: attachments,
    parent_folder_id: parentFolderId,
    student_profile_ids: students,
    notify: params.notify,
  };
};

export const normalizeSaveDocumentRequest = (
  action: ActionsSaga['saveDocumentRequestSaga']
) => {
  const { params } = action;

  const students = Object.values(params.classroomWithStudentProfileIds).reduce(
    (acc, itens) => acc.concat(itens),
    []
  );

  const attachments = [];

  if (params?.attachments) {
    params.attachments.map((attachment) => {
      attachments.push(attachment.signed_id);
    });
  }

  const classroomsIds =
    params.receiverType === 'student'
      ? Object.keys(params.classroomWithStudentProfileIds)
      : params.classroomIds;

  return {
    title: params.title,
    description: params.description,
    kind: 'document',
    available_to: params.availableTo,
    receiver_type: params.receiverType,
    classroom_ids: classroomsIds,
    direct_upload_attachments: attachments,
    parent_folder_id: params.folderAscendingId,
    student_profile_ids: students,
    notify: action.params.notify,
  };
};

export const normalizeSaveEditFileRequest = (
  action: ActionsSaga['saveEditFileRequestSaga'],
  folderAscendingId: string
) => {
  const { params } = action;

  const students = Object.values(params.classroomWithStudentProfileIds).reduce(
    (acc, itens) => acc.concat(itens),
    []
  );

  const attachments = [];

  const classroomsIds =
    params.receiverType === 'student'
      ? Object.keys(params.classroomWithStudentProfileIds)
      : params.classroomIds;

  if (params?.attachments) {
    params.attachments.map((attachment) => {
      attachments.push(attachment.signedId);
    });
  }

  return {
    title: params.title,
    kind: 'file',
    available_to: params.availableTo,
    receiver_type: params.receiverType,
    classroom_ids: classroomsIds,
    direct_upload_attachment: attachments[0],
    parent_folder_id: folderAscendingId,
    student_profile_ids: students,
    notify: params.notify,
  };
};

export const normalizeSaveFileRequest = (
  action: ActionsSaga['saveFileRequestSaga']
) => {
  const { params } = action;

  const students = Object.values(params.classroomWithStudentProfileIds).reduce(
    (acc, itens) => acc.concat(itens),
    []
  );

  const attachments = [];

  const classroomsIds =
    params.receiverType === 'student'
      ? Object.keys(params.classroomWithStudentProfileIds)
      : params.classroomIds;

  if (params?.attachments) {
    params.attachments.map((attachment) => {
      attachments.push(attachment.signedId);
    });
  }

  return {
    kind: 'file',
    available_to: params.availableTo,
    receiver_type: params.receiverType,
    classroom_ids: classroomsIds,
    direct_upload_attachments: attachments,
    parent_folder_id: params.folderAscendingId,
    student_profile_ids: students,
    notify: params.notify,
  };
};

export const fetchStoragesQueryUrl = (
  action: ActionsSaga['fetchStoragesRequest'],
  filters: StorageFilter
) => {
  const { page, folderAscendingId } = action;

  const folder = folderAscendingId ? `folder_id=${folderAscendingId}` : '';
  const currentPage = page ? `&page[number]=${page}` : '';
  const included = '&include=classrooms,studentProfiles,versions';
  const artifactFields =
    '&fields[artifact]=title,description,kind,receiverType,versions,' +
    'availableTo,destroyProcess,createdAt,updatedAt,deletedAt,userPermissions,' +
    'attachments,classrooms,student_profiles';
  const classroomFields = '&fields[classroom]=name';
  const titleFilter = filters?.title ? `&filter[title]=${filters.title}` : '';
  const ordenationFilter =
    filters.ordenation !== 'alphabetic'
      ? `&direction=${filters.ordenation}`
      : '&alphabetic=true';
  const periodFilter =
    filters?.startDate && filters?.endDate
      ? `&filter[created_at_period][start_at]=${filters.startDate}&filter[created_at_period][end_at]=${filters.endDate}`
      : '';
  const filterHeadquarter = filters.headquarterId
    ? `&filter[headquarter_ids][]=${filters.headquarterId}`
    : '';
  const filterEducationalStage = filters.educationalStageId
    ? `&filter[educational_stage_ids][]=${filters.educationalStageId}`
    : '';
  const filterClassRoom = filters.classroomId
    ? `&filter[classroom_ids][]=${filters.classroomId}`
    : '';
  const filterSchoolTerm = filters.activeSchoolTermId
    ? `&filter[school_term_names][]=${filters.activeSchoolTermId}`
    : '';

  const queryParams = `${folder}${currentPage}${included}${artifactFields}${classroomFields}${titleFilter}${ordenationFilter}${periodFilter}${filterHeadquarter}${filterEducationalStage}${filterClassRoom}${filterSchoolTerm}`;

  return queryParams;
};

export const normalizeFetchCurrentStorageSuccess = (
  folderHierarchyPath: FolderPath,
  storageData: StorageRequest,
  included: StudentProfileIncluded[] | ClassroomIncluded[] | ArtifactVersion[]
) => {
  const paths = {
    ids: [null, ...folderHierarchyPath.ids],
    titles: [
      i18n.t('storage:storage_list.header.breadcrumb_title'),
      ...folderHierarchyPath.titles,
    ],
  };

  const attachments = (storageData.attributes.attachments || []).map(
    (attachment) => {
      return {
        ...attachment,
        uploaded: true,
        isForm: true,
        signed_id: attachment.signedId,
      };
    }
  );

  const versions: ArtifactVersion[] = [];

  storageData.relationships.versions.data.map((artifactVersion) => {
    included.find((inc: ArtifactVersion) => {
      if (inc.id === artifactVersion.id) {
        versions.push(inc);
      }
    });
  });

  const studentProfiles: Recipient[] = [];

  if (storageData.relationships.studentProfiles) {
    storageData.relationships.studentProfiles.data.map((studentProfile) => {
      included.find((inc: StudentProfileIncluded) => {
        if (inc.id === studentProfile.id) {
          studentProfiles.push({
            id: inc.id,
            name: inc.attributes.name,
            avatarColor: inc.attributes.avatarColor,
            nameInitials: inc.attributes.nameInitials,
          });
        }
      });
    });
  }

  const classrooms: Recipient[] = storageData.relationships.classrooms
    ? storageData.relationships.classrooms.data
        .map((classroom: ClassroomIncluded) => {
          const foundClassroom = included.find(
            (inc) => inc.id === classroom.id
          );
          return foundClassroom
            ? { name: foundClassroom.attributes.name, id: foundClassroom.id }
            : null;
        })
        .filter(Boolean)
    : [];

  const createdBy = included.find((inc) => inc.type === 'artifactVersion');

  const storage = {
    ...storageData,
    attributes: {
      ...storageData.attributes,
      attachments,
      classroomWithStudentProfileIds: (
        storageData.attributes.classroomWithStudentProfileIds || []
      ).reduce(
        (acc, obj) => ({
          ...acc,
          [Object.keys(obj)[0]]: obj[Object.keys(obj)[0]].map(String),
        }),
        {} as Record<string, string[]>
      ),
      classrooms,
      studentProfiles,
      versions,
      createdBy: {
        name: createdBy?.attributes.name,
        avatarColor: createdBy?.attributes.avatarColor,
        avatarUrl: createdBy?.attributes.avatarUrl,
        nameInitials: createdBy?.attributes.nameInitials,
      },
    },
  };

  delete storage.relationships;

  return { paths, storage };
};

export const normalizeFileType = (fileType: ValidFileType) => {
  const typeMapping = {
    'image/jpeg': 'JPEG',
    'image/jpg': 'JPG',
    'image/png': 'PNG',
    'image/heic': 'HEIC',
    'image/heif': 'HEIF',
    'video/mp4': 'MP4',
    'application/pdf': 'PDF',
    'application/vnd.ms-powerpoint': 'PPT',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      'PPTX',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      'DOCX',
    'application/xml': 'XML',
  };
  return typeMapping[fileType];
};
