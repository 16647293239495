import styled, { css } from 'styled-components';
import Card from 'components/Card';
import { WrapperLink } from 'components/Card/styles';

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  * {
    &:disabled {
      cursor: not-allowed;
    }
  }

  a:hover {
    color: unset;
  }

  .Field {
    ${({ theme }) => css`
      color: ${theme.gray2};
    `};
  }
`;

export const ContentContainer = styled(Card)`
  ${({ theme }) => css`
    border: 1px solid ${theme.gray12};
    box-shadow: none;
    flex-wrap: wrap;
  `}

  &:first-of-type {
    margin-top: 0;
  }

  ${WrapperLink} {
    width: 90%;

    .card-head {
      display: none;
    }
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.label`
  ${({ theme }) => css`
    font-family: 'Quicksand';
    font-size: 16px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: ${theme.black};
    padding-left: 15px;
  `}
`;

export const CheckboxLabel = styled.label`
  ${({ theme }) => css`
    font-size: 16px;
    margin: 0 16px 0 8px;
    font-family: 'Roboto', sans-serif;
    color: ${theme.black};
    font-weight: normal;
  `}
`;

export const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
