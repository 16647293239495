import { ThemeProps } from 'config/themes/types';
import styled, { css } from 'styled-components';

export const ButtonContainer = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    justify-content: flex-end;
    gap: ${space.sm};
    margin-top: ${space.lg};
  `}
`;

export const BodyRegular16 = styled.p`
  ${({ theme: { typography, colors } }: ThemeProps) => css`
    ${typography.body.Regular16}
    color: ${colors.neutral.gray1}
  `}
`;
