import React from 'react';

import { useTranslation } from 'react-i18next';

import AlbumActions from 'store/albums/actions';

import { Box, Button, Modal, Text } from '@agendaedu/ae-web-components';
import { Props } from './types';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/albums/types';

const DeleteMediaModal = ({ media, isOpen, onClose }: Props): JSX.Element => {
  const { t } = useTranslation(['albums', 'common']);
  const dispatch = useDispatch();

  const { deleteMedia } = AlbumActions;

  const { albumDetails } = useSelector((state: RootState) => state.albums);

  const onConfirmeDelete = () => {
    dispatch(deleteMedia({ id: media.id, albumId: albumDetails.id }));
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      title={t('album_details.delete_media_modal.title')}
      onClose={onClose}
    >
      <Box width="100%">
        <Text
          data-testid="text-content-modal"
          variant="body-regular-16"
          mx="xs3"
          maxWidth={360}
          lineHeight="lg"
        >
          {t('album_details.delete_media_modal.content')}
        </Text>

        <Box display="flex" justifyContent="flex-end" mt="lg">
          <Button
            id="cancel-media-delete-button"
            testId="back-button"
            variant="secondary"
            mr="sm"
            onClick={onClose}
          >
            {t('common:button.back')}
          </Button>
          <Button
            id="confirm-media-delete-button"
            testId="delete-button"
            isNegativeAction
            variant="secondary"
            onClick={onConfirmeDelete}
          >
            {t('common:button.delete')}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default DeleteMediaModal;
