import React from 'react';
import ContentLoader from 'react-content-loader';

const Skeleton = () => {
  return (
    <ContentLoader
      width="100%"
      height="100%"
      viewBox="0 0 600 700"
      ariaLabel="Carregando..."
    >
      <rect x="1" y="18" rx="3" ry="3" width="88" height="6" />
      <rect x="1" y="36" rx="3" ry="3" width="52" height="6" />
      <rect x="3" y="48" rx="3" ry="3" width="580" height="257" />
      <rect x="0" y="700" rx="3" ry="3" width="600" height="6" />
    </ContentLoader>
  );
};

export default Skeleton;
