/* eslint-disable no-extra-boolean-cast */
import moment from 'moment';
import formatDatetime from 'core/utils/formatDatetime';

import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import {
  buildToast,
  toastCss,
  ToastTypes,
} from 'store/middlewares/toast/actions';
import { fetchApi, postApi, putApi, deleteApi } from 'core/utils/api';
import actions from './actions';
import classroomsDataAPI from 'core/helper/classroomsDataAPI';
import { SentryExceptionSaga } from 'core/sentry/captureException';

function* fetchDisciplines() {
  try {
    const { data } = yield call(
      fetchApi,
      `/schools/disciplines.json?classrooms_with_students=true`
    );

    yield put({
      type: actions.FETCH_DISCIPLINES_SUCCESS,
      data,
    });
  } catch (error) {
    yield put({ type: actions.ERROR, error });
  }
}

function* setClassrooms(action) {
  try {
    const discipline = action.payload || null;

    const { history } = yield call(
      fetchApi,
      `/schools/student_profiles/classrooms.json${
        discipline ? `?discipline_id=${discipline}` : ''
      }`
    );

    yield put({
      type: actions.SET_CLASSROOMS_SUCCESS,
      data: history,
    });
  } catch (error) {
    yield put({ type: actions.ERROR, error });
  }
}

export function* addSummarydayClassrooms(action) {
  try {
    const dataArea = yield select((state) => state.root.dataArea);
    const { data } = yield call(postApi, `/${dataArea}/summaryday_classrooms`, {
      summaryday_classroom: {
        ...action.payload,
        created_for: action.payload.sent_kind,
      },
    });

    yield put({
      type: actions.ADD_SUMMARYDAY_CLASSROOMS_SUCCESS,
      toast: buildToast(
        'Atividade cadastrada com sucesso.',
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });

    window.location = `/${dataArea}/summaryday_classrooms/${data.id}`;
  } catch (error) {
    yield put({
      type: actions.ERROR,
      toast: buildToast(
        'Erro ao tentar cadastrar a atividade.',
        ToastTypes.error
      ),
      error,
    });
  }
}

export function* setSummarydayClassroom(action) {
  try {
    const dataArea = yield select((state) => state.root.dataArea);

    const [{ data, included }, { data: disciplines }] = yield all([
      call(
        fetchApi,
        `/${dataArea}/summaryday_classrooms/${action.payload}/edit.json`
      ),
      call(fetchApi, `/schools/disciplines.json?classrooms_with_students=true`),
    ]);

    const { history } = yield call(
      fetchApi,
      `/${dataArea}/student_profiles/classrooms.json?discipline_id=${data.attributes.discipline_id}`
    );

    if (data.attributes.deadline !== null) {
      const formatDeadline = formatDatetime(
        data.attributes.deadline,
        'YYYY-MM-DD  HH:mm:ss'
      );

      data.attributes.deadline = formatDeadline;
    }

    if (data.attributes.created_for) {
      data.attributes.sent_kind = data.attributes.created_for;
    }

    yield put({
      type: actions.SET_SUMMARYDAY_CLASSROOM_SUCCESS,
      data,
      included,
      disciplines,
      classrooms: history,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      toast: buildToast(
        'Erro ao tentar recuperar atividade.',
        ToastTypes.error
      ),
      error,
    });
  }
}

export function* setDuplicateActivity(action) {
  try {
    const dataArea = yield select((state) => state.root.dataArea);

    const [{ data, included }, { data: disciplines }] = yield all([
      call(
        fetchApi,
        `/${dataArea}/summaryday_classrooms/${action.payload}.json`
      ),
      call(fetchApi, `/schools/disciplines.json?classrooms_with_students=true`),
    ]);

    const { history } = yield call(
      fetchApi,
      `/${dataArea}/student_profiles/classrooms.json?discipline_id=${data.attributes.discipline_id}`
    );

    if (data.attributes.created_for) {
      data.attributes.sent_kind = data.attributes.created_for;
    }

    yield put({
      type: actions.SET_DUPLICATE_ACTIVITY_SUCCESS,
      data,
      included,
      disciplines,
      classrooms: history,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      toast: buildToast('Erro ao tentar duplicar atividade.', ToastTypes.error),
      error,
    });
  }
}

export function* updateSummarydayClassroom(action) {
  try {
    const dataArea = yield select((state) => state.root.dataArea);

    const activity = {
      ...action.payload.activity,
      created_for: action.payload.activity.sent_kind,
    };

    yield call(
      putApi,
      `/${dataArea}/summaryday_classrooms/${action.payload.id}`,
      {
        summaryday_classroom: activity,
      }
    );

    yield put({
      type: actions.UPDATE_SUMMARYDAY_CLASSROOM_SUCCESS,
      toast: buildToast(
        'Atividade atualizada com sucesso.',
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });

    window.location = `/${dataArea}/summaryday_classrooms/${action.payload.id}`;
  } catch (error) {
    yield put({
      type: actions.ERROR,
      toast: buildToast(
        'Erro ao tentar atualizar atividade.',
        ToastTypes.error
      ),
      error,
    });
  }
}

export function* fetchsummarydayClassrooms(_action) {
  try {
    const { queryParams } = _action;

    const dataArea = yield select((state) => state.root.dataArea);

    let filters = '';
    if (!!queryParams.params.headquarter)
      filters += `q[classrooms_headquarter_id_eq]=${queryParams.params.headquarter}`;
    if (!!queryParams.params.educational_stage)
      filters += `&q[classrooms_educational_stage_id_eq]=${queryParams.params.educational_stage}`;
    if (!!queryParams.params.classroom)
      filters += `&q[classrooms_id_eq]=${queryParams.params.classroom}`;
    if (!!queryParams.params.startDate) {
      const formatedDate = moment(queryParams.params.startDate).format(
        'YYYY-MM-DD'
      );
      filters += `&q[date_date_gteq]=${formatedDate}`;
    }
    if (!!queryParams.params.endDate) {
      const formatedDate = moment(queryParams.params.endDate).format(
        'YYYY-MM-DD'
      );

      filters += `&q[date_date_lteq]=${formatedDate}&`;
    }

    const { data, meta, itemsCountPerPage, totalItemsCount, included } =
      yield call(
        fetchApi,
        `/${dataArea}/summaryday_classrooms.json?${filters}`,
        queryParams
      );

    yield put({
      type: actions.FETCH_CLASSROOMS_SUCCESS,
      pagination: {
        currentPage: queryParams.params.page || 1,
        itemsCountPerPage,
        totalItemsCount,
      },
      data: classroomsDataAPI.getActivitiesData(data, included),
      meta,
      included,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        'Não foi possível listar atividades.',
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });

    SentryExceptionSaga(error, {
      url: `/summaryday_classrooms.json`,
      dataArea: yield select((state) => state.root.dataArea),
      filters: _action.queryParams,
      from: 'fetchsummarydayClassrooms',
    });
  }
}

export function* fetchEducationalStagesRequest(action) {
  try {
    const { headquarterValue } = action;

    const { data } = yield call(
      fetchApi,
      `/schools/headquarters/${headquarterValue}/educational_stages`
    );

    yield put({
      type: actions.FETCH_EDUCATIONAL_STAGES_SUCCESS,
      educationalStages: data,
    });
  } catch (error) {
    yield put({ type: actions.ERROR, error });
  }
}

export function* fetchClassroomFilterRequest(action) {
  try {
    const { headquarterValue, educationalStageValue } = action;

    const { data } = yield call(
      fetchApi,
      `/schools/headquarters/${headquarterValue}/${educationalStageValue}/classrooms`
    );

    yield put({
      type: actions.FETCH_CLASSROOM_FILTER_SUCCESS,
      classroomFilter: data,
    });
  } catch (error) {
    yield put({ type: actions.ERROR, error });
  }
}

export function* requestClassroomApprove(action) {
  try {
    const { activityId } = action;

    const dataArea = yield select((state) => state.root.dataArea);

    const updatedActivityDetail = yield call(
      putApi,
      `/${dataArea}/summaryday_classrooms/${activityId}/approve.json`
    );

    yield put({
      type: actions.SET_APPROVE_CLASSROOM_SUCCESS,
      selected: updatedActivityDetail.data,
      toast: buildToast(
        'A atividade foi enviada. Acompanhe a situação na aba Engajamento',
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });

    yield put({
      type: actions.FETCH_CLASSROOM_DETAILS_REQUEST,
      activityId,
    });

    yield put({
      type: actions.FETCH_TOTAL_COMMENTS_REQUEST,
      activityId,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        'Não foi possível aprovar esta atividade.',
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* fetchClassroomDetails(action) {
  try {
    const { activityId } = action;

    const dataArea = yield select((state) => state.root.dataArea);

    const { data, included } = yield call(
      fetchApi,
      `/${dataArea}/summaryday_classrooms/${activityId}.json`
    );

    yield put({
      type: actions.FETCH_CLASSROOM_DETAILS_SUCCESS,
      data: classroomsDataAPI.getActivitiesDetailsData(data, included),
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        'Não foi possível exibir a atividade selecionada.',
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* fetchDeleteClassroom(action) {
  try {
    const { classroom } = action;

    const dataArea = yield select((state) => state.root.dataArea);

    yield call(
      deleteApi,
      `/${dataArea}/summaryday_classrooms/${classroom.id}.json`
    );

    yield put({
      type: actions.FETCH_CLASSROOM_DELETE_SUCCESS,
      toast: buildToast(
        'Atividade excluída com sucesso.',
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        'Não foi possível excluir esta atividade.',
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* requestClassroomDisapprove(action) {
  try {
    const { activityId, cancelReason } = action;

    const dataArea = yield select((state) => state.root.dataArea);

    const disapprovedClassroom = yield call(
      putApi,
      `/${dataArea}/summaryday_classrooms/${activityId}/reject.json`,
      {
        unapproval_reason: cancelReason,
      }
    );

    yield put({
      type: actions.DISAPPROVE_CLASSROOM_SUCCESS,
      selected: disapprovedClassroom.data,
      toast: buildToast(
        'Atividade reprovada com sucesso.',
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });

    yield put({
      type: actions.FETCH_CLASSROOM_DETAILS_REQUEST,
      activityId,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        'Não foi possível reprovar esta atividade.',
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* fetchActivityEngagement(action) {
  const { activityId, page } = action;
  const dataArea = yield select((state) => state.root.dataArea);

  try {
    const { data, included, meta, itemsCountPerPage, totalItemsCount } =
      yield call(
        fetchApi,
        `/${dataArea}/summaryday_classrooms/${activityId}/engagement?page=${page}`
      );

    yield put({
      type: actions.FETCH_ACTIVITY_ENGAGEMENT_SUCCESS,
      data: classroomsDataAPI.getActivitiesEngagement(data, included),
      meta: [
        {
          label: 'Envios',
          value: meta?.total,
          color: 'gray2',
        },
        {
          label: 'Visualizações',
          value: meta?.views,
          color: 'info',
        },
        {
          label: 'Confirmações',
          value: meta?.confirm,
          color: 'success',
        },
        {
          label: 'Pendentes',
          value: meta?.pendency,
          color: 'warning',
        },
        {
          label: 'Recebidas',
          value: meta?.answers !== 0 ? meta.answers : 0,
          color: meta?.answers !== 0 ? '#5AD0C7' : 'gray3',
        },
      ],
      pagination: {
        currentPage: page || 1,
        itemsCountPerPage,
        totalItemsCount,
      },
    });
  } catch (error) {
    yield put({ type: actions.ERROR, error });
  }
}

export function* requestClassroomSchedule(action) {
  try {
    const {
      activityId,
      dateTimeSchedule: { date, time },
      scheduleType,
    } = action;

    const dataArea = yield select((state) => state.root.dataArea);
    const scheduleClassroom = yield call(
      putApi,
      `/${dataArea}/summaryday_classrooms/${activityId}/schedule`,
      {
        date,
        time,
      }
    );

    const successMessage = `Atividade ${
      scheduleType.type === 'reschedule' ? 'reagendada' : 'agendada'
    } com sucesso`;

    if (!scheduleClassroom?.success) {
      yield put({
        type: actions.ERROR,
        toast: buildToast(
          scheduleClassroom.message,
          ToastTypes.error,
          toastCss(ToastTypes.error)
        ),
      });
    } else {
      yield put({
        type: actions.SET_CLASSROOM_SCHEDULE_SUCCESS,
        toast: buildToast(
          successMessage,
          ToastTypes.success,
          toastCss(ToastTypes.success)
        ),
      });

      yield put({
        type: actions.FETCH_CLASSROOM_DETAILS_REQUEST,
        activityId,
      });
    }
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        'Não foi possível agendar esta atividade.',
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* requestClassroomResend(action) {
  try {
    const { activityId } = action;

    const dataArea = yield select((state) => state.root.dataArea);

    yield call(
      putApi,
      `/${dataArea}/summaryday_classrooms/${activityId}/resend.json`
    );

    yield put({
      type: actions.SET_ACTIVITY_RESEND_SUCCESS,
      toast: buildToast(
        'A atividade foi reenviada. Acompanhe a situação na área de engajamento.',
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });

    yield put({
      type: actions.FETCH_CLASSROOM_DETAILS_REQUEST,
      activityId,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        'Não foi possível reenviar esta atividade.',
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* requestClassroomCancelSchedule(action) {
  try {
    const { activityId } = action;

    const dataArea = yield select((state) => state.root.dataArea);

    yield call(
      putApi,
      `/${dataArea}/summaryday_classrooms/${activityId}/cancel_schedule`
    );

    yield put({
      type: actions.SET_CLASSROOM_CANCELSCHEDULE_SUCCESS,
      toast: buildToast(
        'Cancelamento de agendamento efetivado.',
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });

    yield put({
      type: actions.FETCH_CLASSROOM_DETAILS_REQUEST,
      activityId,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        'Não foi possível cancelar o agendamento.',
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* requestClassroomApproveSchedule(action) {
  try {
    const { activityId } = action;

    const dataArea = yield select((state) => state.root.dataArea);

    yield call(
      putApi,
      `/${dataArea}/summaryday_classrooms/${activityId}/approve_schedule.json`
    );

    yield put({
      type: actions.SET_CLASSROOM_CANCELSCHEDULE_SUCCESS,
      toast: buildToast(
        'Agendamento aprovado.',
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });

    yield put({
      type: actions.FETCH_CLASSROOM_DETAILS_REQUEST,
      activityId,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        'Não foi possível aprovar o agendamento.',
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* fetchCommentList(action) {
  const { queryParams } = action;
  const { activityId, page, sectionId } = queryParams;

  const dataArea = yield select((state) => state.root.dataArea);

  try {
    const { data, meta, itemsCountPerPage, totalItemsCount } = yield call(
      fetchApi,
      `/${dataArea}/summaryday_classrooms/${activityId}/comments?page=${page}&comment_section_id=${sectionId}`
    );

    yield put({
      type: actions.FETCH_ACTIVITY_COMMENTS_SUCCESS,
      data: classroomsDataAPI.getActivityCommentData(data),
      meta,
      pagination: {
        currentPage: queryParams?.page || 1,
        itemsCountPerPage,
        totalItemsCount,
      },
    });
  } catch (error) {
    yield put({ type: actions.ERROR, error });
  }
}

export function* fetchCommentSections(action) {
  const { activityId } = action;

  const dataArea = yield select((state) => state.root.dataArea);

  try {
    const { data, included } = yield call(
      fetchApi,
      `/${dataArea}/summaryday_classrooms/${activityId}/comment_sections`
    );

    yield put({
      type: actions.FETCH_COMMENT_SECTIONS_SUCCESS,
      classrooms: classroomsDataAPI.getCommentSetionsData(data, included),
    });
  } catch (error) {
    yield put({ type: actions.ERROR, error });
  }
}

export function* fetchTotalComments(action) {
  const { activityId } = action;

  const dataArea = yield select((state) => state.root.dataArea);

  try {
    const { data, meta } = yield call(
      fetchApi,
      `/${dataArea}/summaryday_classrooms/${activityId}/comment_sections/total_comments`
    );

    yield put({
      type: actions.FETCH_TOTAL_COMMENTS_SUCCESS,
      data,
      meta,
    });
  } catch (error) {
    yield put({ type: actions.ERROR, error });
  }
}

export function* addCommentActivity(action) {
  const { payload } = action;
  const { activityId, message, sectionId } = payload;

  const dataArea = yield select((state) => state.root.dataArea);

  try {
    const { data } = yield call(
      postApi,
      `/${dataArea}/summaryday_classrooms/${activityId}/comments`,
      { message, comment_section_id: sectionId }
    );

    const updatedComments = [
      {
        id: data.id,
        type: data.type,
        message: data.attributes.message,
        hide: data.attributes.hide,
        createdAt: moment(data.attributes.createdAt).format(
          'DD/MM/YYYY [às] HH:mm'
        ),
        sentBy: {
          ...data.attributes.sentBy.data.attributes,
        },
      },
    ];

    yield put({
      type: actions.ADD_COMMENT_ACTIVITY_SUCCESS,
      data: updatedComments,
    });
  } catch (error) {
    yield put({ type: actions.ERROR, error });
  }
}

export function* requestCommentHide(action) {
  const { payload } = action;

  const { activityId, commentId } = payload;

  const dataArea = yield select((state) => state.root.dataArea);

  try {
    const { data } = yield call(
      postApi,
      `/${dataArea}/summaryday_classrooms/${activityId}/comments/toggle_visibility`,
      { comment_id: commentId }
    );

    const updatedComment = {
      id: data.id,
      type: data.type,
      message: data.attributes.message,
      hide: data.attributes.hide,
      createdAt: moment(data.attributes.createdAt).format(
        'DD/MM/YYYY [às] HH:mm'
      ),
      sentBy: {
        ...data.attributes.sentBy.data.attributes,
      },
    };

    const toastMessage = updatedComment.hide
      ? 'O comentário foi ocultado.'
      : 'O comentário está sendo exibido.';

    yield put({
      type: actions.SET_COMMENT_HIDE_SUCCESS,
      data: updatedComment,
      toast: buildToast(
        toastMessage,
        ToastTypes.info,
        toastCss(ToastTypes.info)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        'Não foi possível ocultar o comentário.',
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* requestUpdateNotification(action) {
  const { activityId } = action;

  const dataArea = yield select((state) => state.root.dataArea);

  try {
    yield call(
      putApi,
      `/${dataArea}/summaryday_classrooms/${activityId}/notify_update`
    );

    yield put({
      type: actions.SET_UPDATE_NOTIFICATION_SUCCESS,
      toast: buildToast(
        'Notificação enviada com sucesso.',
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        'Ops, ocorreu um erro e não conseguimos completar a requisição. Tente novamente!',
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* summarydayClassroomsSagas() {
  yield all([
    takeLatest(actions.FETCH_DISCIPLINES, fetchDisciplines),
    takeLatest(actions.SET_CLASSROOMS, setClassrooms),
    takeLatest(actions.ADD_SUMMARYDAY_CLASSROOMS, addSummarydayClassrooms),
    takeLatest(actions.SET_SUMMARYDAY_CLASSROOM, setSummarydayClassroom),
    takeLatest(actions.SET_DUPLICATE_ACTIVITY_REQUEST, setDuplicateActivity),
    takeLatest(actions.UPDATE_SUMMARYDAY_CLASSROOM, updateSummarydayClassroom),
    takeLatest(actions.FETCH_CLASSROOMS_REQUEST, fetchsummarydayClassrooms),
    takeLatest(
      actions.FETCH_EDUCATIONAL_STAGES_REQUEST,
      fetchEducationalStagesRequest
    ),
    takeLatest(
      actions.FETCH_CLASSROOM_FILTER_REQUEST,
      fetchClassroomFilterRequest
    ),
    takeLatest(actions.SET_CLASSROOM_APPROVE_REQUEST, requestClassroomApprove),
    takeLatest(actions.FETCH_CLASSROOM_DETAILS_REQUEST, fetchClassroomDetails),
    takeLatest(actions.FETCH_CLASSROOM_DELETE_REQUEST, fetchDeleteClassroom),
    takeLatest(
      actions.DISAPPROVE_CLASSROOM_REQUEST,
      requestClassroomDisapprove
    ),
    takeLatest(
      actions.FETCH_ACTIVITY_ENGAGEMENT_REQUEST,
      fetchActivityEngagement
    ),
    takeLatest(
      actions.FETCH_ACTIVITY_ENGAGEMENT_REQUEST_PAGINATION,
      fetchActivityEngagement
    ),
    takeLatest(
      actions.SET_CLASSROOM_SCHEDULE_REQUEST,
      requestClassroomSchedule
    ),
    takeLatest(actions.SET_ACTIVITY_RESEND_REQUEST, requestClassroomResend),
    takeLatest(
      actions.SET_CLASSROOM_CANCELSCHEDULE_REQUEST,
      requestClassroomCancelSchedule
    ),
    takeLatest(
      actions.SET_CLASSROOM_APPROVECHEDULE_REQUEST,
      requestClassroomApproveSchedule
    ),
    takeLatest(actions.FETCH_ACTIVITY_COMMENTS_REQUEST, fetchCommentList),
    takeLatest(actions.ADD_COMMENT_ACTIVITY_REQUEST, addCommentActivity),
    takeLatest(
      actions.FETCH_ACTIVITY_COMMENTS_REQUEST_PAGINATION,
      fetchCommentList
    ),
    takeLatest(actions.SET_COMMENT_HIDE_REQUEST, requestCommentHide),
    takeLatest(actions.FETCH_COMMENT_SECTIONS_REQUEST, fetchCommentSections),
    takeLatest(actions.FETCH_TOTAL_COMMENTS_REQUEST, fetchTotalComments),
    takeLatest(
      actions.SET_UPDATE_NOTIFICATION_REQUEST,
      requestUpdateNotification
    ),
  ]);
}

export default summarydayClassroomsSagas;
