import React, { useCallback, useEffect } from 'react';
import { Text, TextField } from '@agendaedu/ae-web-components';
import { useTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';

import * as S from './styles';

import tabifyForWizard from 'core/hoc/tabifyForWizard';
import withFormContext from 'core/hoc/withFormContext';

import { GroupIconInput } from 'components/Messages/MessageGroup/GroupIconInput';

import { GroupInformationStepProps } from './types';
import launchDarkly from 'core/helper/launchDarkly';
import { flags } from 'core/constants/flags';

const GroupInformationStep = ({
  formContext: {
    form: { icon, name, description },
    changeMeta,
    updateAttribute,
  },
}: GroupInformationStepProps) => {
  const { t } = useTranslation(['messages']);
  const [cookies] = useCookies(['agendaedu_launch_darkly']);

  const activeFlags = cookies.agendaedu_launch_darkly?.active_flags;

  const hasGroupV2 = launchDarkly.checkFlagActive(activeFlags, flags.GROUPS_V2);

  useEffect(() => {
    !name || !description
      ? changeMeta('nextStepDisabled', true)
      : changeMeta('nextStepDisabled', false);
  }, [name, changeMeta, description]);

  const handleOnChangeAttribute = useCallback(
    (value, attributeName: string) => {
      updateAttribute(attributeName, value);
    },
    [updateAttribute]
  );

  return (
    <S.GroupInformationStepWrapper>
      <Text
        marginTop={24}
        marginBottom={38}
        fontWeight={700}
        color="neutral.black"
        variant="title-bold-20"
      >
        {t('groups.forms.fields.title_step')}
      </Text>

      <S.InputsWrapper>
        {hasGroupV2 && (
          <GroupIconInput
            image={icon}
            onChange={(icon) => handleOnChangeAttribute(icon, 'icon')}
            data-testid="grop-icon-input"
          />
        )}
        <S.RowWrapper>
          <TextField
            value={name}
            data-testid="group-name-field"
            label={t('groups.forms.fields.name_label')}
            placeholder={t('groups.forms.fields.name_placeholder')}
            fullWidth
            onChange={(e) => handleOnChangeAttribute(e.target.value, 'name')}
          />
          <TextField
            value={description}
            data-testid="group-description-field"
            label={t('groups.forms.fields.description_label')}
            fullWidth
            height={80}
            multiline
            onChange={(e) =>
              handleOnChangeAttribute(e.target.value, 'description')
            }
          />
        </S.RowWrapper>
      </S.InputsWrapper>
    </S.GroupInformationStepWrapper>
  );
};

export default tabifyForWizard({
  title: '1 Informações',
})(withFormContext(GroupInformationStep));
