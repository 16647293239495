import React from 'react';
import * as S from './styles';

const Skeleton = () => {
  return (
    <S.SkeletonContainer>
      <S.SkeletonLeftContainer>
        <S.SkeletonLabel width={'220px'} height={'18px'} />
        <S.SkeletonLabel width={'120px'} height={'16px'} />
        <S.SkeletonLabel width={'180px'} height={'16px'} />
        <S.RowContainer>
          <S.SkeletonLabel width={'170px'} height={'24px'} />
          <S.SkeletonTooltipIcon />
        </S.RowContainer>
      </S.SkeletonLeftContainer>
      <S.SkeletonRightContainer>
        <S.SkeletonButton width={'32px'} height={'32px'} />
        <S.SkeletonButton width={'136px'} height={'32px'} />
      </S.SkeletonRightContainer>
    </S.SkeletonContainer>
  );
};

export default Skeleton;
