import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

function PageSubTitle({ children, className, variation }) {
  return (
    <h3 className={`PageSubTitle ${variation} ${className}`}>{children}</h3>
  );
}

PageSubTitle.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
    PropTypes.string,
  ]),
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  variation: PropTypes.oneOf(['small', 'default', 'large']),
};

PageSubTitle.defaultProps = {
  className: '',
  variation: 'default',
};

export default PageSubTitle;
