import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import reduxProvider from 'core/hoc/reduxProvider';
import { Box, Text, TextField, Tag } from '@agendaedu/ae-web-components';
import PageContainer from 'components/PageContainer';
import ToggleSwitch from 'components/Form/ToggleSwitch';
import { useTranslation } from 'react-i18next';
import ValidationModal from './components/ValidationModal';
import Toast from 'components/Toast';
import { trackEvent } from 'config/amplitude';
import twoAuthFactoActions from 'store/twoAuthFactor/actions';

const TwoAuthFactorScreen = ({ email, isOtpRequired }) => {
  const { t } = useTranslation(['twoAuthFactor']);
  const dispatch = useDispatch();

  const [isTwoAFToggleSwitch, setIsTwoAFToggleSwitch] = useState(isOtpRequired);
  const [isTwoAFModal, setIstwoAFModal] = useState(false);
  const [keyRerenderTroggle, setKeyRerenderTroggle] = useState(0);

  const { deactivationTwoAF, activationTwoAF } = twoAuthFactoActions;
  const { isLoading, errorUpdate } = useSelector(
    (state) => state.twoAuthFactor
  );

  const submitModal = (codeOPT) => {
    trackEvent('two_auth_factor_activation');
    dispatch(activationTwoAF(codeOPT));
    setIstwoAFModal(false);
  };

  useEffect(() => {
    setIsTwoAFToggleSwitch(isOtpRequired);
  }, [keyRerenderTroggle, errorUpdate]);

  return (
    <PageContainer title={t('title_page')}>
      <Box
        as="section"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        mb={16}
      >
        <Text variant="subtitle-medium-16" color="neutral.black" weight={500}>
          {t('sub_title_page')}
        </Text>
        <Box
          key="2af"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          mt={32}
        >
          <Text variant="label-regular-16" color="neutral.gray2" weight={500}>
            {t('description_two_auth_factor')}
          </Text>

          <Box pl={32} pr={32}>
            <ToggleSwitch
              testId="2afToggleSwitch"
              toggled={isTwoAFToggleSwitch}
              onChange={() => {
                if (isTwoAFToggleSwitch) {
                  dispatch(deactivationTwoAF());
                  trackEvent('two_auth_factor_deactivation');
                  setIsTwoAFToggleSwitch(false);
                } else {
                  email && setIsTwoAFToggleSwitch(true);
                  email && setIstwoAFModal(true);
                }
              }}
            />
          </Box>
        </Box>

        <Box
          as="section"
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          mb={16}
          mt={16}
        >
          {email ? (
            <>
              <Text
                variant="label-regular-14"
                color="neutral.gray2"
                weight={400}
              >
                {t('label_two_auth_factor')}
              </Text>
              <TextField value={email} disabled />
            </>
          ) : (
            <Tag
              p={24}
              size="large"
              variant="warning"
              name={t('tag_whitouth_mail')}
            />
          )}
        </Box>
      </Box>

      {email && (
        <ValidationModal
          isModalOpen={isTwoAFModal}
          onSubmit={submitModal}
          closeModal={() => {
            setIstwoAFModal(false);
            setKeyRerenderTroggle((prev) => prev + 1);
          }}
          email={email}
        />
      )}

      <Toast />
    </PageContainer>
  );
};

export default reduxProvider(TwoAuthFactorScreen);
