/**
 * @see https://github.com/chriso/validator.js in order to implement and
 * understand validators.
 */
class FormValidator {
  constructor(validations) {
    this.validations = validations;
  }

  validate(state) {
    const emptyValidationResult = this.buildEmptyValidationResult();
    const attributeNames = this.extractAttributeNamesFromValidations();

    return attributeNames.reduce((validationResult, attributeName) => {
      const attributeValidationResult = this.buildValidationResultForAttribute(
        state,
        attributeName,
        validationResult
      );

      return {
        ...validationResult,
        [attributeName]: attributeValidationResult,
      };
    }, emptyValidationResult);
  }

  static buildValidationsFromServerResponse(serverResponse) {
    const { errors } = serverResponse.data;
    const attributeNames = Object.keys(errors);

    return attributeNames.reduce((validationsResult, attributeName) => {
      validationsResult[attributeName] = {
        isValid: false,
        messages: errors[attributeName],
      };

      return validationsResult;
    }, {});
  }

  /**
   * This method is intended to keep validation errors even if we somehow are
   * able to navigate through form steps.
   * @see the tests for a better understanding.
   * @param {object} prevResult
   * @param {object} nextResult
   * @returns {object}
   */
  static combineValidationResults(prevResult, nextResult) {
    const prevResultAttributeNames = Object.keys(prevResult || {});
    const nextResultAttributeNames = Object.keys(nextResult);

    const attributeNames = [
      ...new Set([...prevResultAttributeNames, ...nextResultAttributeNames]),
    ];

    return attributeNames.reduce((combinedResults, attributeName) => {
      if (prevResultAttributeNames.includes(attributeName)) {
        const { isValid: isPrevValid, messages: prevMessages = [] } =
          prevResult[attributeName] || {};

        const { isValid: isNextValid, messages: nextMessages = [] } =
          nextResult[attributeName] || {};

        const isValid = isNextValid === undefined ? isPrevValid : isNextValid;

        const messages = isValid
          ? []
          : [...new Set([...prevMessages, ...nextMessages])];

        combinedResults[attributeName] = {
          isValid,
          messages,
        };
      } else {
        combinedResults[attributeName] = nextResult[attributeName];
      }

      return combinedResults;
    }, {});
  }

  /**
   * Receives the result from `validate` and check if all validations are ok.
   *
   * @param {object} validationResult the return from the `validate` method.
   * @returns {Boolean} True if all attributes are valid. False is there's at
   * least one attribute that isn't valid.
   */
  static isResultValid(validationResult) {
    const attributeNames = Object.keys(validationResult);

    return attributeNames.reduce(
      (isValid, attributeName) =>
        isValid && validationResult[attributeName].isValid,
      true
    );
  }

  /**
   * @returns {object} An empty result to be used as a null result.
   * @example
   *  {
   *    title: {
   *      isValid: true,
   *      message: []
   *    }
   *  }
   */
  buildEmptyValidationResult() {
    const attributeNames = this.validations.map(
      ({ attributeName }) => attributeName
    );

    return attributeNames.reduce((validationResult, attributeName) => {
      validationResult[attributeName] = {
        isValid: true,
        messages: [],
      };

      return validationResult;
    }, {});
  }

  extractAttributeNamesFromValidations() {
    return this.validations
      .map(({ attributeName }) => attributeName)
      .filter((elem, pos, arr) => arr.indexOf(elem) == pos);
  }

  buildValidationResultForAttribute(state, attributeName, validationResult) {
    const attributeValidations = this.getValidationsForAttribute(attributeName);
    const value = state[attributeName] || '';

    return attributeValidations.reduce((result, attributeValidation) => {
      const isAttributeValid = attributeValidation.validator(
        value,
        state,
        attributeName
      );
      const isValid = result.isValid && isAttributeValid;

      const messages = isAttributeValid
        ? result.messages
        : [...[attributeValidation.message], ...result.messages];

      return { isValid, messages };
    }, validationResult[attributeName]);
  }

  getValidationsForAttribute(attrName) {
    return this.validations.filter(
      ({ attributeName }) => attributeName === attrName
    );
  }
}

export default FormValidator;
