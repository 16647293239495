import React from 'react';
import {
  Grid,
  DefaultThemeProps,
  theme,
  Box,
} from '@agendaedu/ae-web-components';
import Skeleton from 'components/Skeleton';

import * as S from './styles';
import { useTranslation } from 'react-i18next';
import FormFullScreen from 'components/FormFullScreen';

export const HandoutTemplateSkeleton: React.FC<{ dataArea: string }> = ({
  dataArea,
}) => {
  const { t } = useTranslation(['handouts']);

  const defaultTheme: DefaultThemeProps = theme;

  const {
    colors: { neutral },
    border: { radius },
  } = defaultTheme;

  return (
    <S.HandoutTemplateFormContainer>
      <FormFullScreen
        titlePage={t('templates.form.edit_title')}
        backTo={`/${dataArea}/handouts/templates`}
        titleModal={t('modals.handout_form_discard.title')}
        contentModal={t('modals.handout_form_discard.description')}
        backToExternal
        steps={[
          () => (
            <S.StepWrapper>
              <S.FormSkeletonWrapper>
                <Grid
                  gridTemplateColumns="1fr"
                  gutter={{
                    _: 'lg',
                  }}
                >
                  <Box mb="md">
                    <Skeleton
                      width={224}
                      height={40}
                      primaryColor={neutral.gray6}
                      borderRadius={radius.md}
                    />
                  </Box>

                  <Grid item gutter="sm">
                    <Skeleton
                      width={128}
                      height={28}
                      primaryColor={neutral.gray6}
                      borderRadius={radius.md}
                    />

                    <Skeleton
                      width="100%"
                      height={192}
                      primaryColor={neutral.gray6}
                      borderRadius={radius.md}
                      isFullWidth
                    />
                  </Grid>

                  <Grid item gutter="sm">
                    <Skeleton
                      width={128}
                      height={28}
                      primaryColor={neutral.gray6}
                      borderRadius={radius.md}
                    />

                    <Skeleton
                      width="100%"
                      height={40}
                      primaryColor={neutral.gray6}
                      borderRadius={radius.md}
                      isFullWidth
                    />
                  </Grid>

                  <Grid item gutter="sm">
                    <Skeleton
                      width={128}
                      height={28}
                      primaryColor={neutral.gray6}
                      borderRadius={radius.md}
                    />

                    <Skeleton
                      width="100%"
                      height={40}
                      primaryColor={neutral.gray6}
                      borderRadius={radius.md}
                      isFullWidth
                    />
                  </Grid>

                  <Grid item gutter="sm">
                    <Skeleton
                      width={128}
                      height={28}
                      primaryColor={neutral.gray6}
                      borderRadius={radius.md}
                    />

                    <Skeleton
                      width="100%"
                      height={192}
                      primaryColor={neutral.gray6}
                      borderRadius={radius.md}
                      isFullWidth
                    />
                  </Grid>
                </Grid>
              </S.FormSkeletonWrapper>
            </S.StepWrapper>
          ),
        ]}
      ></FormFullScreen>
    </S.HandoutTemplateFormContainer>
  );
};
