import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import FormContainer from 'components/Form/FormContainer';
import AgendaIcon from 'components/AgendaIcon';

import Evacuation from './Card/Evacuation';
import MealsList from './MealsList';
import BabyBottle from './Card/BabyBottle';
import Sleep from './Card/Sleep';
import Observations from './Card/Observations';
import Diaper from './Card/Diaper';
import Bath from './Card/Bath';
import OccurrencesList from './OccurrencesList';

import DailySummariesActions from 'store/dailySummaries/actions';

import DailySummariesContext from 'core/contexts/DailySummaries';
import { getCurrentDate } from 'core/utils/date';

import * as S from './styles';

const StudentSections = ({ hasStudentSelected }) => {
  const dispatch = useDispatch();

  const { handleDraftDailySummary } = DailySummariesActions;

  const { date, draftDailySummary, classroomSections } = useSelector(
    (state) => state.dailySummaries
  );
  const diaperAndBathChildren = classroomSections.filter((item) =>
    ['Fralda', 'Banho'].includes(item)
  ).length;

  const handleForm = (key, value) =>
    dispatch(handleDraftDailySummary(key, value));

  const isDisabled =
    date.format('DD/MM/YYYY') !== getCurrentDate().format('DD/MM/YYYY');

  const canShowField = (title) => classroomSections.includes(title);

  const canShowMultipleFields = (fields) =>
    fields.some((field) => canShowField(field));

  const contextValues = {
    form: draftDailySummary,
    handleForm,
    isDisabled,
    hasStudentSelected,
    canShowField,
    canShowMultipleFields,
  };

  return (
    <DailySummariesContext.Provider value={contextValues}>
      <FormContainer>
        <MealsList />
        <BabyBottle />
        <Evacuation />
        <Sleep />
        <S.DiaperAndBathContainer diaperAndBathChildren={diaperAndBathChildren}>
          <Diaper />
          <Bath />
        </S.DiaperAndBathContainer>
        <OccurrencesList />
        <Observations />
        <S.FooterInfo>
          <AgendaIcon name="info" size="medium" />
          <S.Label>
            Somente os campos preenchidos serão exibidos para os responsáveis
          </S.Label>
        </S.FooterInfo>
      </FormContainer>
    </DailySummariesContext.Provider>
  );
};

StudentSections.propTypes = {
  hasStudentSelected: PropTypes.bool,
  hasMeals: PropTypes.bool,
};

export default StudentSections;
