import { Box, Modal } from '@agendaedu/ae-web-components';
import React from 'react';

import { Props } from './types';

const CenterModal: React.FC<Props> = ({
  body,
  footer,
  isOpen,
  title,
  onClose,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} title={title}>
      <Box
        display="flex"
        paddingX="xs3"
        width="332px"
        flexDirection="column"
        gap="lg"
      >
        {body}
        {footer}
      </Box>
    </Modal>
  );
};

export default CenterModal;
