import React, { useRef, useState } from 'react';
import { DefaultThemeProps, Icon, theme } from '@agendaedu/ae-web-components';

import useOutsideClick from 'core/hooks/useOutsideClick';

import * as S from './styles';
import { SelectAvatarFieldProps } from './types';

export const SelectAvatarField = ({
  icons,
  selectedIconName,
  label,
  onChange,
}: SelectAvatarFieldProps): JSX.Element => {
  const {
    colors: { neutral },
  }: DefaultThemeProps = theme;

  const buttonRef = useRef<HTMLButtonElement>(null);
  const [showOptions, setShowOptions] = useState(false);

  const currentIcon = icons.find((icon) => icon.name === selectedIconName);

  useOutsideClick(buttonRef, () => {
    setShowOptions(false);
  });

  const handleOnPressButton = (): void => setShowOptions((show) => !show);

  return (
    <S.SelectAvatarWrapper>
      <S.Label lineHeight="lg" variant="label-regular-14">
        {label}
      </S.Label>
      <S.SelectAvatar
        ref={buttonRef}
        showOptions={showOptions}
        onClick={handleOnPressButton}
      >
        <S.OptionButtonImage size="sm" src={currentIcon?.image_url} />
        <Icon name="caret-down" color={neutral.gray1} />
      </S.SelectAvatar>
      {showOptions && (
        <S.OptionsWrapper data-testid="options">
          {icons?.map((icon, index) => (
            <S.OptionButton
              data-testid="icon"
              onClick={(): void => onChange(icon.name)}
              key={index}
              selected={currentIcon?.name === icon?.name}
            >
              <S.OptionButtonImage size="md" src={icon?.image_url} />
            </S.OptionButton>
          ))}
        </S.OptionsWrapper>
      )}
    </S.SelectAvatarWrapper>
  );
};
