import React, { ChangeEvent, useMemo, useRef, useState } from 'react';

import useOutsideClick from 'core/hooks/useOutsideClick';

import * as S from './styles';
import { ClassroomSelectProps, Option } from './types';

export const ClassroomSelect = ({
  emptyText,
  label,
  onChange,
  options,
  placeholder,
}: ClassroomSelectProps): JSX.Element => {
  const selectRef = useRef(null);
  const [searchValue, setSearchValue] = useState<string>('');
  const [selectedOption, setSelectedOption] = useState<Option | null>(null);
  const [isOptionsVisible, setOptionsVisible] = useState<boolean>(false);

  useOutsideClick(selectRef, () => setOptionsVisible(false));

  const filteredOptions = useMemo(() => {
    const lowerSearchValue = searchValue.toLowerCase();

    return options
      .map((educationState) => ({
        ...educationState,
        options: educationState?.options?.filter((option) =>
          option.label.toLowerCase().includes(lowerSearchValue)
        ),
      }))
      .filter((educationState) => educationState.options.length > 0);
  }, [searchValue, options]);

  const handleToggleOptions = () => setOptionsVisible(!isOptionsVisible);

  const handleSelectOption = (option: Option) => {
    onChange(option);
    setSelectedOption(option);
    handleToggleOptions();
    setSearchValue('');
  };

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    setSearchValue(value);
    setSelectedOption(null);
    onChange({ label: value, value: null });
  };

  return (
    <S.ClassroomSelectWrapper ref={selectRef}>
      {!!label && <S.SelectTextLabel>{label}</S.SelectTextLabel>}

      <S.SelectWrapper
        isOptionsVisible={isOptionsVisible}
        onClick={handleToggleOptions}
      >
        <S.SelectContent>
          <S.SelectIcon name={'search'} />
          <S.TextField
            type="text"
            placeholder={placeholder}
            value={selectedOption?.label || searchValue}
            disabled={false}
            onChange={handleSearchChange}
          />
        </S.SelectContent>

        <S.SelectIcon name="caret-down" />
      </S.SelectWrapper>
      {isOptionsVisible && (
        <S.OptionList role="listbox">
          {!filteredOptions || filteredOptions.length === 0 ? (
            <S.EmptyOption>{emptyText}</S.EmptyOption>
          ) : (
            <>
              {filteredOptions.map((educationStage, index) => (
                <div key={index}>
                  <S.EmptyOption role="option" aria-disabled>
                    {educationStage.label}
                  </S.EmptyOption>
                  {educationStage.options.map((option: Option) => {
                    const optionSelected =
                      option.value === selectedOption?.value;

                    return (
                      <S.OptionItem
                        onClick={() => handleSelectOption(option)}
                        key={option.value}
                        role="option"
                        aria-selected={optionSelected}
                        isSelected={optionSelected}
                      >
                        {option.label}
                      </S.OptionItem>
                    );
                  })}
                </div>
              ))}
            </>
          )}
        </S.OptionList>
      )}
    </S.ClassroomSelectWrapper>
  );
};
