import styled, { css } from 'styled-components';
import InfiniteScroller from 'react-infinite-scroller';

import { ThemeProps } from 'config/themes/types';
import { StylesProps } from '../types';

const contentModifiers = {
  total: ({ theme: { colors } }: ThemeProps) => css`
    color: ${colors.neutral.gray1};
  `,
  views: ({ theme: { colors } }: ThemeProps) => css`
    color: ${colors.context.info.default};
  `,
  confirm: ({ theme: { colors } }: ThemeProps) => css`
    color: ${colors.context.success.default};
  `,
  pendency: ({ theme: { colors } }: ThemeProps) => css`
    color: ${colors.context.warning.default};
  `,
};

export const CardMetrics = styled.div`
  ${({ theme: { border, breakpoints, colors, space } }: ThemeProps) => css`
    padding: ${space.sm};
    margin-bottom: ${space.xs};
    margin-right: ${space.xs};
    border: ${border.width.sm} ${border.style.solid} ${colors.neutral.gray3};
    border-radius: ${border.radius.md};
    display: flex;
    flex-direction: column;
    flex: 1 0 128px;
    gap: ${space.sm};

    @media only screen and (min-width: ${breakpoints.tabletMD}) {
      flex: 0 0 140px;
      margin-right: ${space.sm};
      margin-bottom: ${space.sm};
    }

    @media only screen and (min-width: ${breakpoints.desktopLG}) {
      margin-bottom: ${space.xs4};
    }
  `}
`;

export const CardTitle = styled.span`
  ${({ theme: { colors, font } }: ThemeProps) => css`
    font-family: ${font.family.brand}, sans-serif;
    font-size: ${font.size.sm};
    font-weight: ${font.weight.medium};
    line-height: ${font.height.md};
    color: ${colors.neutral.gray1};
  `}
`;

export const CardContent = styled.h2<StylesProps>`
  ${({ theme, variant }) => css`
    margin: ${theme.space.xs4};
    font-family: ${theme.font.family.brand}, sans-serif;
    font-size: ${theme.font.size.xl};
    font-weight: ${theme.font.weight.medium};
    line-height: ${theme.font.height.md};

    ${!!variant && contentModifiers[variant]({ theme })};
  `}
`;

export const Separator = styled.div`
  ${({ theme: { border, colors } }: ThemeProps) => css`
    border-bottom: ${border.width.sm} ${border.style.solid}
      ${colors.neutral.gray4};
  `}
`;

export const CardList = styled(InfiniteScroller)`
  width: 100%;
`;

export const EmptyStateWrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    .EmptyState {
      width: 264px;
      ${theme.typography.subtitle.Medium16};

      img {
        max-width: fit-content;
        margin-bottom: ${theme.space.lg};
      }
    }
  `}
`;
