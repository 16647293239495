import React, { useState } from 'react';
import Avatar from 'components/Avatar';
import { calendarDateDescription } from 'core/utils/date';
import ModalContainer from 'components/Modal/ModalContainer';

import {
  Container,
  Content,
  HistoryButton,
  ButtonTitle,
  InfoArea,
  Title,
  Date,
  AvatarWrapper,
} from './styles';
import ModalTitle from 'components/Modal/ModalTitle';

type DataType = {
  id: string;
  type: string;
  attributes: {
    created_at: string;
    event: string;
    school_user: {
      data: {
        id: string;
        type: string;
        attributes: {
          name_initials: string;
          avatar_color: string;
          name: string;
          avatar_url: string;
        };
      };
    };
  };
};

interface HistoryProps {
  data: Array<DataType>;
  isModalList?: boolean;
  className?: string;
}

const uknowUser = 'user';

const History = ({ data, isModalList, className }: HistoryProps) => {
  const response = data?.slice(0).reverse();

  const [isShowModal, setShowModal] = useState(false);

  const toggleModal = () => setShowModal(!isShowModal);

  const renderHeadInfo = (renderItem) => {
    return `${renderItem.attributes.event} por ${
      renderItem.attributes.school_user?.data.attributes.name || uknowUser
    }`;
  };

  const {
    attributes: { school_user: schoolUser, created_at: createdAt },
  } = response[0];

  const avatarAttributes = {
    attributes: {
      name_initials: schoolUser?.data.attributes.name_initials || 'US',
      avatar_color: schoolUser?.data.attributes.avatar_color || '#cccccc',
      name: schoolUser?.data.attributes.name || uknowUser,
    },
  };

  const renderListModal = () => {
    return (
      <>
        {!!isModalList &&
          response.map((item, key) => (
            <AvatarWrapper key={key}>
              <Content>
                <Avatar
                  size="medium"
                  user={{
                    attributes: {
                      avatar_color:
                        item.attributes.school_user?.data.attributes
                          .avatar_color || '#cccccc',
                      name:
                        item.attributes.school_user?.data.attributes.name ||
                        uknowUser,
                      name_initials:
                        item.attributes.school_user?.data.attributes
                          .name_initials || 'US',
                    },
                  }}
                />

                <InfoArea>
                  <Title>{renderHeadInfo(item)}</Title>
                  <Date>
                    {calendarDateDescription(item.attributes.created_at)}
                  </Date>
                </InfoArea>
              </Content>
            </AvatarWrapper>
          ))}
      </>
    );
  };

  return (
    <>
      <Container className={className}>
        <Content>
          <Avatar size="small" user={avatarAttributes} />

          <InfoArea>
            <Title>{renderHeadInfo(response[0])}</Title>
            <Date>{calendarDateDescription(createdAt)}</Date>
          </InfoArea>
        </Content>

        <Content>
          <HistoryButton
            icon="history"
            onClick={(): void => setShowModal(!isShowModal)}
          >
            <ButtonTitle>Histórico</ButtonTitle>
          </HistoryButton>
        </Content>
      </Container>

      <ModalContainer
        toggleModal={toggleModal}
        isOpen={isShowModal}
        title={<ModalTitle>{'Histórico de ações'}</ModalTitle>}
      >
        {renderListModal()}
      </ModalContainer>
    </>
  );
};

export default History;
