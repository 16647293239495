import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import './style.scss';

class ChannelAvatar extends PureComponent {
  static propTypes = {
    channel: PropTypes.object.isRequired,
  };

  render() {
    const { channel } = this.props;
    return (
      <div className="ChannelAvatar">
        <img src={channel.attributes.icon_url} alt={channel.attributes.name} />
      </div>
    );
  }
}

export default ChannelAvatar;
