import styled from 'styled-components';

export const ImportWebContainer = styled.div`
  .TableContainer {
    > .table-responsive.TableOverflowX {
      overflow-x: unset;
    }
  }

  .customMetadata {
    margin-left: auto;
  }

  .DropdownButton {
    max-width: 105px;
    > .Button {
      height: auto;
      padding: 8px 16px;
      border-radius: 6px;
    }
  }
`;

export const FormContainer = styled.div`
  @media (min-width: 992px) {
    .PageTitle {
      white-space: nowrap;
    }
  }
`;
