/* eslint-disable @typescript-eslint/ban-ts-comment */

import React, { useEffect, useState, useCallback } from 'react';
import { useDropzone, FileRejection, FileError } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import { Button, Icon } from '@agendaedu/ae-web-components';

import * as S from './styles';

import { DragAndDropImageProps } from './types';

const DragAndDropImage = ({
  disabled = false,
  attachment = null,
  onChange,
  customPreviewSize,
  customPreviewMessage,
}: DragAndDropImageProps) => {
  const [file, setFile] = useState<File>(attachment);
  const [errors, setErrors] = useState([]);
  const { t } = useTranslation(['common']);

  const MAP_ERRORS = {
    'file-too-large': t('fields.file.errors.too_large', { size: '10MB' }),
    'file-invalid-type': t('fields.image.errors.format'),
  };

  const haveFile = file !== null;

  const onAttachmentSelect = useCallback(
    (fileSelected) => {
      if (!fileSelected) return;

      onChange(fileSelected);
    },
    [onChange]
  );

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/jpg, image/jpeg, image/png',
    multiple: false,
    maxSize: 10000000,
    onDropAccepted: (acceptedFiles: File[]) => {
      if (acceptedFiles.length === 0) return;
      const fileSelected = acceptedFiles[0];

      Object.assign(fileSelected, {
        url: URL.createObjectURL(fileSelected),
        uploaded: false,
      });

      setFile(fileSelected);
      setErrors([]);
      onAttachmentSelect(fileSelected);
    },
    onDropRejected: (fileRejections: FileRejection[]) => {
      const newErrors = [];

      fileRejections[0].errors.map((error: FileError) =>
        newErrors.push(MAP_ERRORS[error.code])
      );

      setErrors(newErrors);
    },
    disabled,
  });

  const onRemoveImage = (event: Event) => {
    setFile(null);
    onChange(null);
    event.stopPropagation();
  };

  const errorMessages = errors.map((error, index) => (
    <S.ErrorMessage key={index}>{error}</S.ErrorMessage>
  ));

  const renderImagePreview = (
    // @ts-ignore
    <S.PreviewContainer image={file?.url} customPreviewSize={customPreviewSize}>
      <Button variant="secondary" icon="cycle">
        {t('button.alter')}
      </Button>
      <Button variant="secondary" icon="trash-bin" onClick={onRemoveImage}>
        {t('fields.image.labels.remove')}
      </Button>
    </S.PreviewContainer>
  );

  const dropzoneBackground = (
    <S.DropzoneBackground>
      <S.DragHere>
        <Icon name="image" size="md" />
        <S.CaptionText>{t('fields.image.labels.drag_here')}</S.CaptionText>
      </S.DragHere>
      <S.SelectImageArea>
        {/* <S.Divider /> */}
        <span>{t('fields.image.labels.or')}</span>
        {/* <S.Divider /> */}
        <Button variant="primary">{t('fields.image.labels.select')}</Button>
      </S.SelectImageArea>
      <S.ImageSpecs>
        {customPreviewMessage
          ? customPreviewMessage
          : t('fields.image.labels.specs', { size: '10MB' })}
      </S.ImageSpecs>
    </S.DropzoneBackground>
  );

  useEffect(() => {
    if (!haveFile) return;

    // Make sure to revoke the data uris to avoid memory leaks
    // @ts-ignore
    URL.revokeObjectURL(file.preview);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  return (
    <S.ImageFieldContainer>
      <div
        {...getRootProps({ className: 'dropzone' })}
        className="DropzoneContainer"
      >
        <input {...getInputProps()} />
        {haveFile ? renderImagePreview : dropzoneBackground}
      </div>
      {errors.length > 0 && errorMessages}
    </S.ImageFieldContainer>
  );
};

export default DragAndDropImage;
