import * as Sentry from '@sentry/browser';
import { Extra } from '@sentry/types';

export function SentryExceptionSaga(
  err: Error,
  extra?: Record<string, Extra>,
  skipLogging = false
) {
  Sentry.withScope((scope) => {
    if (extra) {
      for (const key in extra) {
        scope.setExtra(key, extra[key]);
      }
    }

    if (process.env.NODE_ENV !== 'development') {
      scope.setLevel(Sentry.Severity.Error);
      Sentry.captureException(err);
    }

    if (skipLogging && process.env.NODE_ENV === 'development') {
      console.error(err);
    }
  });
}
