import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import AgendaIcon from 'components/AgendaIcon';

import './style.scss';

class MessagesBalloonDeleted extends PureComponent {
  static propTypes = {
    message: PropTypes.shape({
      attributes: PropTypes.shape({
        text: PropTypes.string.isRequired
      })
    })
  }

  render() {
    const { text } = this.props.message.attributes;

    return (
      <div className="MessagesBalloonDeleted">
        <AgendaIcon name='block' />
        <span>{text}</span>
      </div>
    );
  }
}

export default MessagesBalloonDeleted;
