/**
 * Compose functions.
 *
 * @param {...Function} functions
 * @returns {Function}
 *
 * @example
 * const multiplyBy10 = x => x * 10;
 * const minus3 = x => x - 3;
 *
 * composeFunctionse(multiplyBy10, minus3)(8) === 77
 */
function composeFunctions(...functions) {
  return functions.reduceRight((f, g) => (...args) => f(g(...args)));
}

export default composeFunctions;
