import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import UserDisplay from 'components/UserDisplay';
import Loader from 'components/Loader';
import LabeledContainer from 'components/LabeledContainer';
import { NegotiationsList } from '../NegotiationList';
import { NegotiationEmptyState } from '../NegotiationEmptyState';

import tabify from 'core/hoc/tabify';

import negotiationsActions from 'store/edupay/negotiations/actions';

import { NegotiationTabProps, RootState } from './types';
import * as S from './styles';

export const NegotiationTab = ({
  match: {
    params: { id },
  },
}: NegotiationTabProps) => {
  const [page, setPage] = useState(1);

  const handlePageChange = (pageNumber: number) => setPage(pageNumber);

  const dispatch = useDispatch();

  const currentPlan = useSelector(
    (state: RootState) => state.recurrentPlans.current
  );
  const { isLoading, negotiations, totalItemsCount } = useSelector(
    (state: RootState) => state.negotiations
  );

  const { fetchNegotiations } = negotiationsActions;

  useEffect(() => {
    dispatch(fetchNegotiations({ page, planId: parseInt(id) }));
  }, [page, dispatch, fetchNegotiations, id]);

  return (
    <div>
      <S.BillInfo>
        <UserDisplay
          user={currentPlan.studentProfile}
          classroomsNames={
            currentPlan.studentProfile.attributes.classroom_names
          }
          size="medium"
        />
        <LabeledContainer title="Título da cobrança">
          <>{currentPlan.recurrentPlan.title}</>
        </LabeledContainer>
      </S.BillInfo>
      <Loader isLoading={isLoading}>
        {negotiations?.length ? (
          <NegotiationsList
            page={page}
            handlePageChange={handlePageChange}
            negotiations={negotiations}
            totalItemsCount={totalItemsCount}
            planId={id}
          />
        ) : (
          <NegotiationEmptyState />
        )}
      </Loader>
    </div>
  );
};

export default tabify({
  title: 'Negociações',
  icon: 'transactions',
})(NegotiationTab);
