import { ROLE_LIST } from 'core/locale';
import createModuleReducer from 'store/storeConfig/createModuleReducer';
import actions from './actions';

export const INITIAL_ROLE = 'coordinator';

export const INITIAL_STATE = {
  usersSelect: [],
  loading: true,
  currentRole: {},
  initialValuesNotChange: {},
  hasChangedForm: false,
  error: null,
  initialValues: {},
  usersPermissions: [],
};

export const updateLoad = (state) => ({
  ...state,
  loading: !state.loading,
});

export const setRole = (state, action) => {
  const newCurrentRole = state.usersPermissions.find(
    (obj) => Number(obj.id) === Number(action.role)
  );
  return {
    ...state,
    currentRole: newCurrentRole,
    initialValuesNotChange: newCurrentRole,
    hasChangedForm: false,
  };
};

export const fetchSuccess = (state, action) => {
  // sort first by role coordinator
  action.data.sort(function (
    a: { attributes: { role: string | number } },
    b: { attributes: { role: string | number } }
  ) {
    if (a.attributes.role === 'coordinator') {
      return -1;
    } else if (b.attributes.role === 'coordinator') {
      return 1;
    } else
      return a.attributes.role < b.attributes.role
        ? -1
        : a.attributes.role > b.attributes.role
        ? 1
        : 0;
  });

  const usersSelect = action.data.map((data) => {
    return {
      label: ROLE_LIST[data.attributes.role],
      value: data.attributes.id,
      id: data.attributes.id,
    };
  });

  return {
    ...state,
    usersSelect: usersSelect,
    usersPermissions: action.data,
    currentRole: action.data[0],
    initialValuesNotChange: action.data[0],
    loading: false,
  };
};

/**
 *@isOldForm para os formulários antigos, isso deve ser deprecated junto com as modificações de state
 *@permission role indetificador que é uma permissão, não ações
 *@lastAttributeChaged string contendo aba.permissão.novo_valor_acao
 */
export const updateCurrentPermission = (state, action) => {
  let allUpdatePermissions = [];
  const lastAttributeChaged = action.action.split('.');
  const newValue = lastAttributeChaged[2] === 'true' ? true : false;

  if (action.isOldForm) {
    if (action.permission === 'role') {
      const oldFormNewValue = lastAttributeChaged[2] === 'true' ? true : false;

      const newCurrentRole = {
        ...state.currentRole,
        attributes: {
          ...state.currentRole.attributes,
          [lastAttributeChaged[1]]: {
            ...state.currentRole.attributes[lastAttributeChaged[1]],
            enabled: oldFormNewValue,
          },
        },
      };

      return {
        ...state,
        hasChangedForm:
          JSON.stringify(state.initialValuesNotChange) !==
          JSON.stringify(newCurrentRole),
        currentRole: newCurrentRole,
      };
    } else {
      const actionNewValue = lastAttributeChaged[2] === 'true' ? true : false;

      const actionNewCurrentRole = {
        ...state.currentRole,
        attributes: {
          ...state.currentRole.attributes,
          [lastAttributeChaged[0]]: {
            ...state.currentRole.attributes[lastAttributeChaged[0]],
            actions: {
              ...state.currentRole.attributes[lastAttributeChaged[0]].actions,
              [lastAttributeChaged[1]]: actionNewValue,
            },
          },
        },
      };

      return {
        ...state,
        hasChangedForm:
          JSON.stringify(state.initialValuesNotChange) !==
          JSON.stringify(actionNewCurrentRole),
        currentRole: actionNewCurrentRole,
      };
    }
  }

  if (action.permission === 'role' && action.isOldForm === false) {
    allUpdatePermissions = state.currentRole.attributes.permissions.map(
      (values) => {
        if (values.name == lastAttributeChaged[0]) {
          return {
            ...values,
            value: {
              ...values.value,
              [lastAttributeChaged[1]]: {
                actions: values.value[lastAttributeChaged[1]].actions,
                enabled: newValue,
              },
            },
          };
        } else {
          return values;
        }
      }
    );
  } else {
    const newValue = lastAttributeChaged[2] === 'true' ? true : false;

    allUpdatePermissions = state.currentRole.attributes.permissions.map(
      (values) => {
        if (values.name == lastAttributeChaged[0]) {
          return {
            ...values,
            value: {
              ...values.value,
              [action.permission]: {
                actions: {
                  ...values.value[action.permission].actions,
                  [lastAttributeChaged[1]]: newValue,
                },
                enabled: values.value[action.permission].enabled,
              },
            },
          };
        } else {
          return values;
        }
      }
    );
  }

  const newCurrentRole = {
    ...state.currentRole,
    attributes: {
      ...state.currentRole.attributes,
      permissions: allUpdatePermissions,
    },
  };

  return {
    ...state,
    hasChangedForm:
      JSON.stringify(state.initialValuesNotChange) !==
      JSON.stringify(newCurrentRole),
    currentRole: newCurrentRole,
  };
};

export const updateSuccessPermission = (state, action) => {
  return {
    ...state,
    initialValuesNotChange: action.data,
    hasChangedForm: false,
  };
};

export const handlerError = (state, action) => ({
  ...state,
  loading: false,
  error: action.error,
});

const HANDLERS = {
  [actions.FETCH_SUCCESS]: fetchSuccess,
  [actions.SET_ROLE]: setRole,
  [actions.UPDATE_CURRENT_PERMISSION]: updateCurrentPermission,
  [actions.UPDATE_PERMISSION_SUCCESS]: updateSuccessPermission,
  [actions.FETCH_ERROR]: handlerError,
};

const permissions = createModuleReducer(INITIAL_STATE, HANDLERS);

export default permissions;
