import moment from 'moment';

export function extractClassroomsForEdit(classrooms) {
  const result = [];

  classrooms &&
    classrooms.map((classroom) => {
      const id = String(classroom.attributes.id);
      result.push(id);
    });

  return result;
}

function extractOptions(options) {
  const arrayOptions = [];

  options &&
    options.map((option) => {
      const addedOption = {
        key: option.key || Math.random().toString(36).substring(7),
        position: String(option.position),
        title: option.title,
        others: option.others,
      };
      arrayOptions.push(addedOption);
    });

  return arrayOptions;
}

export function extractQuestions(questions) {
  const arrayQuestions = [];

  questions &&
    questions.map((question, indexQuestion) => {
      const addedQuestion = {
        key: question.key || Math.random().toString(36).substring(7),
        position: String(question.position),
        question_type: question.question_type,
        required: question.required,
        survey_options_attributes: extractOptions(
          question.survey_options_attributes
        ),
        ...(question.question
          ? { [`question_${indexQuestion}`]: question.question }
          : { question: question[`question_${indexQuestion}`] }),
      };
      arrayQuestions.push(addedQuestion);
    });

  return arrayQuestions;
}

export const convertClassroomIdsToInt = (classroomIds) =>
  classroomIds.map((id) => parseInt(id));

export const formatSurveyForm = (survey) => {
  let form = {
    title: survey.attributes.title,
    kind: survey.attributes.kind,
    description: survey.attributes.description,
    date_start: moment(
      survey.attributes.start_datetime,
      'DD/MM/YYYY HH:mm'
    ).format('YYYY/MM/DD'),
    time_start: moment(
      survey.attributes.start_datetime,
      'DD/MM/YYYY HH:mm'
    ).format('HH:mm'),
    date_end: moment(
      survey.attributes.limit_datetime,
      'DD/MM/YYYY HH:mm'
    ).format('YYYY/MM/DD'),
    time_end: moment(
      survey.attributes.limit_datetime,
      'DD/MM/YYYY HH:mm'
    ).format('HH:mm'),
    sent_kind: survey.classroom_ids.length > 0 ? 'classrooms' : 'students',
    persona_type: survey.attributes.persona_type,
    survey_questions_attributes: extractQuestions(
      survey.survey_questions_attributes
    ),
    classroom_ids: extractClassroomsForEdit(survey.classroom_ids),
    classroom_with_student_profile_ids:
      survey.attributes.classrooms_with_student_profile_ids || {},
  };

  form.survey_questions_attributes.forEach((question, indexQuestion) => {
    form = {
      ...form,
      [`question_${indexQuestion}`]: question[`question_${indexQuestion}`],
    };
    question.survey_options_attributes.forEach((option, indexOption) => {
      form = {
        ...form,
        [`titleOption_${indexQuestion}_${indexOption}_${option.key}`]:
          option.title,
      };
    });
  });

  return form;
};
