import React, { useState } from 'react';
import SideSheet from 'components/SideSheet';
import { Box, Button } from '@agendaedu/ae-web-components';

import * as S from './styles';
import { trackEvent } from 'config/amplitude';
import { ButtonAndSideSheetProps } from './types';

const ButtonAndSideSheet: React.FC<ButtonAndSideSheetProps> = ({
  title,
  buttonTitle,
  children,
}) => {
  const [openSideSheet, setOpenSideSheet] = useState(false);

  const handleSideSheetToggle = () => {
    setOpenSideSheet(!openSideSheet);
    if (!openSideSheet) document.body.style.overflow = 'hidden';
  };

  const closeSideSheet = () => {
    trackEvent(`integrations_monitor_close_how_to_fix`);
    document
      .getElementById('side-sheet-wrapper')
      .classList.add('fade-out-animation');
    document.getElementById('side-sheet').classList.add('slide-out-animation');
    setTimeout(() => {
      document.body.style.removeProperty('overflow');
      handleSideSheetToggle();
    }, 250);
  };

  return (
    <>
      <Button variant="secondary" onClick={handleSideSheetToggle}>
        {buttonTitle}
      </Button>

      <SideSheet
        isOpen={openSideSheet}
        onDismiss={handleSideSheetToggle}
        title={title}
      >
        <S.BoxContent
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
        >
          <S.StyledHr mt={1} mb={20} />
          {children}
        </S.BoxContent>

        <S.StyledHr mt={20} mb={1} />
        <Box
          p="24px 32px 32px 32px"
          display="flex"
          flexDirection="row-reverse"
          alignContent="center"
          alignItems="center"
        >
          <Button variant="secondary" onClick={closeSideSheet}>
            Fechar
          </Button>
        </Box>
      </SideSheet>
    </>
  );
};

export default ButtonAndSideSheet;
