import styled from 'styled-components';

import { Grid, Text, Box } from '@agendaedu/ae-web-components';

import DropdownButton from 'components/DropdownButton';

export const CardWrapper = styled(Grid)`
  width: 100%;
  grid-template-areas: 'name name name name creation expiration expiration value value status status actions';
  align-items: center;
  margin-bottom: 10px;
  border-radius: 8px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.14);
  padding: 16px 16px;
`;

export const ActionsMenu = styled(DropdownButton)`
  border: 0;
`;

export const BoxWithoutOverflow = styled(Box)`
  overflow: hidden;
  min-width: 125px;
`;

export const TextWithoutOverflow = styled(Text)`
  text-overflow: ellipsis;
`;
