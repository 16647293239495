/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { getCurrentDate } from 'core/utils/date';

import FormFullScreen from 'components/FormFullScreen';
import NotificationModal from './NotificationModal';
import GeneralInformation from './GeneralInformation';

import DailySummariesActions from 'store/dailySummaries/actions';

import * as S from './styles';

const DailySummaryForm = ({ classroomId }) => {
  const dispatch = useDispatch();
  const [isNotificationModalOpen, setIsNotificationModalOpen] = useState(false);

  const {
    setDate,
    fetchClassroom,
    addDailySummary,
    setSelectedsIds,
    fetchDailySummaryDetails,
  } = DailySummariesActions;

  const dataArea = useSelector((state) => state.root.dataArea);

  const {
    date,
    current,
    formMeta,
    isSending,
    draftDailySummary,
    studentsIdsSelecteds,
  } = useSelector((state) => state.dailySummaries);

  const submit = (notify) => {
    const dailySummaryFormat = {
      notify,
      current,
      classroomId,
      draftDailySummary,
      studentsIdsSelecteds,
      date: date.format('YYYY-MM-DD'),
    };

    dispatch(addDailySummary(dailySummaryFormat));
    setIsNotificationModalOpen(false);
  };

  const closeNotificationModal = () => setIsNotificationModalOpen(false);

  const showCancelModal = useMemo(() => {
    return Boolean(studentsIdsSelecteds.length);
  }, [studentsIdsSelecteds]);

  useEffect(() => {
    dispatch(setSelectedsIds([]));
    dispatch(setDate(getCurrentDate()));
  }, []);

  useEffect(() => {
    dispatch(fetchClassroom(classroomId, date.format('YYYY-MM-DD')));
  }, [date]);

  useEffect(() => {
    if (studentsIdsSelecteds.length === 1) {
      dispatch(fetchDailySummaryDetails(classroomId, studentsIdsSelecteds[0]));
    }
  }, [classroomId, studentsIdsSelecteds]);

  return (
    <S.DailySummariesFormContainer>
      <FormFullScreen
        action="new"
        id="DailySummariesForm"
        form={current}
        initialAttributes={current}
        formMeta={formMeta}
        steps={[GeneralInformation]}
        isSubmitting={isSending}
        titlePage="Preencher diário"
        onSubmit={() => setIsNotificationModalOpen(true)}
        backTo={`/${dataArea}/daily_summaries`}
        backToExternal
        titleModal="Descartar preenchimento"
        contentModal="As informações já preenchidas não serão salvas até que você as envie. Tem certeza que deseja descartar?"
        toggleModalWithFormUpdates
        openCancelModal={showCancelModal}
      >
        <NotificationModal
          isOpen={isNotificationModalOpen}
          toggleModal={closeNotificationModal}
          confirmAction={submit}
        />
      </FormFullScreen>
    </S.DailySummariesFormContainer>
  );
};

DailySummaryForm.propTypes = {
  classroomId: PropTypes.string.isRequired,
};

export default withRouter(DailySummaryForm);
