import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import parse, { domToReact } from 'html-react-parser';

import HubstoreActions from 'store/hubstore/actions';

import { Box, CarouselSlider, Card } from '@agendaedu/ae-web-components';
import Toast from 'components/Toast';
import Tab from 'components/Tab';
import Tabs from 'components/Tabs';
import Alert from 'components/Alert';
import DetailsHeaderInfo from '../components/DetailsHeaderInfo';
import Breadcrumb from '../components/Breadcrumb';
import LeadsFormModal from '../components/LeadsFormModal';
import { loadImage } from 'core/helper/ImageDimensions';

import Skeleton from './skeleton';
import { HubstoreDetailsProps } from './types';
import { RootState } from '../types';
import * as S from './styles';
import { USER_ACTIONS } from '../constants';

const HubstoreDetails: React.FC<HubstoreDetailsProps> = ({
  match,
  history,
}): JSX.Element => {
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [imageDimensions, setImageDimensions] = useState(400);
  const [keyCarouselSlider, setKeyCarouselSlider] = useState(1);
  const [alertStatus, setAlertStatus] = useState<boolean>();

  const [alert, setAlert] = useState(false);
  const { t } = useTranslation(['hub_store']);
  const { id } = match.params;

  const styleOptions = {
    fontSize: '16px',
    fontFamily: 'Roboto',
    fontWeight: 400,
    lineHeight: '170%',
    fontStyle: 'normal',
    color: '#666',
    textEdge: 'cap',
    leadingTrim: 'both',
  };

  const tagStyleds = ['p', 'u', 'i', 'li', 'a'];
  const titleStyleds = ['h1', 'h2', 'h3', 'h4', 'h5'];
  const listStyleds = ['ol', 'ul'];

  const options = {
    replace: (domNode) => {
      const { attribs, children } = domNode;

      if (domNode.name === listStyleds) {
        return <S.styledOl {...attribs}>{domToReact(children)}</S.styledOl>;
      }

      if (titleStyleds.includes(domNode.name)) {
        return <S.styledh2 {...attribs}>{domToReact(children)}</S.styledh2>;
      }

      if (tagStyleds.includes(domNode.name)) {
        return (
          <S.Styledparagraph style={{ ...styleOptions }} {...attribs}>
            {domToReact(children)}
          </S.Styledparagraph>
        );
      }
    },
  };

  const { productHubstore, productMeta, productsHubstoreIncluded, isLoading } =
    useSelector((state: RootState) => state.hubstore);

  const { getProducHubstore, updateProductHubstore } = HubstoreActions;

  const isActive =
    productMeta?.available_action.includes(USER_ACTIONS.deactivate) ||
    productMeta?.available_action.includes(USER_ACTIONS.none);

  const closeModal = () => setIsModalOpen(false);
  const openModal = () => setIsModalOpen(true);
  const showAlert = () => setAlert(true);

  const handleSetPanelDynamicModule = useCallback(() => {
    dispatch(getProducHubstore(id));
  }, [dispatch, getProducHubstore, id]);

  const handleUpdateProduct = useCallback(() => {
    dispatch(updateProductHubstore(Number(id), isActive));
    setAlertStatus(!isActive);
    showAlert();
  }, [dispatch, updateProductHubstore, id, isActive]);

  useEffect(() => {
    handleSetPanelDynamicModule();
    setAlertStatus(isActive);
  }, [handleSetPanelDynamicModule]);

  useEffect(() => {
    if (productsHubstoreIncluded) {
      loadImage(productsHubstoreIncluded[0]['attributes']['url']).then(
        (size: { width: number; height: number }) => {
          setImageDimensions(size.width);
          setKeyCarouselSlider((value) => value + 1);
        }
      );
    }
  }, [productsHubstoreIncluded]);

  if (isLoading) return <Skeleton />;

  return (
    <>
      {productHubstore && (
        <Box margin={16}>
          <Breadcrumb path="/schools/hub_store/products" title="< Hub Store" />

          {alert && (
            <Box margin="24px 0">
              <Alert variation="success">
                {alertStatus
                  ? t('app_activated_alert')
                  : t('app_deactivate_alert')}
              </Alert>
            </Box>
          )}

          <DetailsHeaderInfo
            product={productHubstore}
            productMeta={productMeta}
            isActive={isActive}
            onUpdateProduct={handleUpdateProduct}
            openModal={openModal}
            history={history}
          />

          <Box margin="40px 0" key={keyCarouselSlider}>
            <CarouselSlider
              data={productsHubstoreIncluded.map(
                (media) => media['attributes']['url']
              )}
              itemsToShow={
                productsHubstoreIncluded.map(
                  (media) => media['attributes']['url']
                ).length
              }
              height={304}
              width={imageDimensions > 200 ? 400 : 171}
              border="4px solid white"
            />
          </Box>

          <Card>
            <Tabs defaultIndex={0}>
              <Tab title="Visão Geral">
                <Box>
                  {!!productHubstore.attributes.full_description &&
                    parse(productHubstore.attributes.full_description, options)}
                </Box>
              </Tab>

              <Tab title="Instruções">
                <Box>
                  {!!productHubstore.attributes.instructions &&
                    parse(productHubstore.attributes.instructions, options)}
                </Box>
              </Tab>

              <Tab title="Suporte">
                <Box>
                  {!!productHubstore.attributes.support &&
                    parse(productHubstore.attributes.support, options)}
                </Box>
              </Tab>
            </Tabs>
          </Card>

          <LeadsFormModal
            appName={productHubstore.attributes?.title}
            formData={{
              product_id: id,
              email: productMeta?.contact_info?.email,
              name: productMeta?.contact_info?.name,
            }}
            isModalOpen={isModalOpen}
            closeModal={closeModal}
          />
          <Toast />
        </Box>
      )}
    </>
  );
};

export default HubstoreDetails;
