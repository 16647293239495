import React from 'react';
import PropTypes from 'prop-types';
import ChannelAvatar from 'components/Messages/ChannelAvatar';
import AgendaIcon from 'components/AgendaIcon';
import { Link } from 'react-router-dom';

import './style.scss';

const propTypes = {
  backTo: PropTypes.string,
  channel: PropTypes.object,
  match: PropTypes.shape({
    params: PropTypes.shape({
      kind: PropTypes.string.isRequired,
    }),
  }),
  showBackButton: PropTypes.bool,
};

function MessagesOtherUsersSuperiorBar({
  backTo,
  channel,
  match: {
    params: { kind },
  },
  showBackButton,
}) {
  const chatName = `${kind === 'family' ? 'Família' : 'Privado'}: ${
    channel.attributes.name
  }`;

  return (
    <div className="MessagesSuperiorBar">
      {showBackButton && (
        <Link to={backTo}>
          <AgendaIcon name="arrow-left" />
        </Link>
      )}
      <ChannelAvatar channel={channel} />
      <div className="chat-info">
        <span className="chat-name">{chatName}</span>
      </div>
    </div>
  );
}

MessagesOtherUsersSuperiorBar.propTypes = propTypes;

export default MessagesOtherUsersSuperiorBar;
