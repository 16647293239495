/**
 * @param {Boolean} isDebugUser
 * @param {Boolean} isWalletGuaranteedMonthlyFee
 * @returns {Boolean}
 */
export const canUseGuaranteedMonthlyFee = (
  isDebugUser,
  isWalletGuaranteedMonthlyFee
) => {
  return isDebugUser && isWalletGuaranteedMonthlyFee;
};
