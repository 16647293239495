import moment from 'moment';

import createModuleReducer from '../storeConfig/createModuleReducer';
import actions from './actions';

export const INITIAL_STATE = {
  isLoading: false,
  isSending: false,
  startDate: moment().startOf('year').format('YYYY-MM-DD'),
  endDate: moment().endOf('year').format('YYYY-MM-DD'),
  selectedReport: null,
  selectedHeadquarter: null,
  selectedEducationalStage: null,
  selectedClassroom: null,
  formMeta: {
    filterAmount: 0,
    headquarters: [],
    educationalStages: [],
    classrooms: [],
    reports: [],
  },
  charts: [],
};

export const fetchHeadquarters = (state) => ({
  ...state,
  isLoading: true,
  formMeta: {
    ...state.formMeta,
    headquarters: [],
  },
});

export const fetchHeadquartersSuccess = (state, action) => {
  const formattedHeadquarters = [{ value: null, label: 'Selecione a unidade' }];

  action.headquarters.forEach((headquarter) => {
    formattedHeadquarters.push({
      value: headquarter.id,
      label: headquarter.attributes.name,
    });
  });

  return {
    ...state,
    isLoading: false,
    formMeta: {
      ...state.formMeta,
      headquarters: formattedHeadquarters,
    },
  };
};

export const fetchEducationalStages = (state) => ({
  ...state,
  isLoading: true,
  formMeta: {
    ...state.formMeta,
    educationalStages: [],
  },
});

export const fetchEducationalStagesSuccess = (state, action) => {
  const formattedEducationalStages = [
    { value: null, label: 'Selecione o segmento' },
  ];

  action.educationalStages.forEach((educationalStage) => {
    formattedEducationalStages.push({
      value: educationalStage.id,
      label: educationalStage.attributes.name,
    });
  });

  return {
    ...state,
    isLoading: false,
    formMeta: {
      ...state.formMeta,
      educationalStages: formattedEducationalStages,
    },
  };
};

export const fetchClassrooms = (state) => ({
  ...state,
  isLoading: true,
  formMeta: {
    ...state.formMeta,
    classrooms: [],
  },
});

export const fetchClassroomsSuccess = (state, action) => {
  const formattedClassrooms = [{ value: null, label: 'Selecione a turma' }];

  action.classrooms.forEach((classroom) => {
    formattedClassrooms.push({
      value: classroom.id,
      label: classroom.attributes.name,
    });
  });

  return {
    ...state,
    isLoading: false,
    formMeta: {
      ...state.formMeta,
      classrooms: formattedClassrooms,
    },
  };
};

export const fetchCharts = (state, action) => {
  const {
    filters: {
      headquarter,
      educationalStage,
      classroom,
      startDate,
      endDate,
      report_id,
    },
  } = action;

  return {
    ...state,
    isSending: true,
    charts: [],
    selectedHeadquarter: headquarter,
    selectedEducationalStage: educationalStage,
    selectedClassroom: classroom,
    startDate: startDate || state.startDate,
    endDate: endDate || state.endDate,
    selectedReport: report_id || state.selectedReport,
  };
};

export const fetchChartsSuccess = (state, action) => {
  const {
    filters,
    history: { charts, report_id },
  } = action;

  const {
    headquarter,
    educationalStage,
    classroom,
    startDate,
    endDate,
    report_id: filteredReport,
  } = filters;

  const newFilterAmount = () => {
    const actionFilters = Object.values({
      headquarter,
      educationalStage,
      classroom,
    }).filter((value) => Boolean(value)).length;

    const mainFilters = Object.values({
      filteredReport,
      startDate,
      endDate,
    }).filter((value) => Boolean(value)).length;

    if (mainFilters > 0) return state.formMeta.filterAmount;

    if (actionFilters > 0) return actionFilters;

    return 0;
  };

  const newCharts = charts.map(({ title, chart: url }) => ({
    title,
    url,
  }));

  const setActionsToChartList = () => {
    const adhesionReportId = state.formMeta.reports[0]?.value;
    const chartListWithoutButtons = newCharts;

    if (report_id === adhesionReportId) {
      chartListWithoutButtons[1].button = {
        label: 'Ver os responsáveis que não fizeram o primeiro acesso',
        link: '/schools/responsible_profiles?situation=not_logged_in',
      };

      chartListWithoutButtons[2].button = {
        label: 'Ver os alunos que não fizeram o primeiro acesso',
        link: '/schools/student_profiles?situation=not_logged_in',
      };
    }
    return chartListWithoutButtons;
  };

  return {
    ...state,
    isSending: false,
    selectedReport: report_id,
    selectedHeadquarter: headquarter || state.selectedHeadquarter,
    selectedEducationalStage:
      educationalStage || state.selectedEducationalStage,
    selectedClassroom: classroom || state.selectedClassroom,
    startDate: startDate || state.startDate,
    endDate: endDate || state.endDate,
    charts: setActionsToChartList(),
    formMeta: {
      ...state.formMeta,
      filterAmount: newFilterAmount(),
    },
  };
};

export const fetchReports = (state) => ({
  ...state,
  isSending: true,
  formMeta: {
    ...state.formMeta,
    reports: [],
  },
});

export const fetchReportsSuccess = (state, action) => {
  const { data } = action;

  const reports = data.map((report) => {
    const {
      id,
      attributes: { title },
    } = report;

    return {
      value: id,
      label: title,
    };
  });

  return {
    ...state,
    isSending: false,
    selectedReport: reports[0].value,
    formMeta: {
      ...state.formMeta,
      reports,
    },
  };
};

export const handleError = (state, action) => ({
  ...state,
  isLoading: false,
  error: action.error,
});

const HANDLERS = {
  [actions.FETCH_HEADQUARTERS]: fetchHeadquarters,
  [actions.FETCH_HEADQUARTERS_SUCCESS]: fetchHeadquartersSuccess,
  [actions.FETCH_EDUCATIONAL_STAGES]: fetchEducationalStages,
  [actions.FETCH_EDUCATIONAL_STAGES_SUCCESS]: fetchEducationalStagesSuccess,
  [actions.FETCH_CLASSROOMS]: fetchClassrooms,
  [actions.FETCH_CLASSROOMS_SUCCESS]: fetchClassroomsSuccess,
  [actions.FETCH_CHARTS]: fetchCharts,
  [actions.FETCH_CHARTS_SUCCESS]: fetchChartsSuccess,
  [actions.FETCH_REPORTS]: fetchReports,
  [actions.FETCH_REPORTS_SUCCESS]: fetchReportsSuccess,
  [actions.ERROR]: handleError,
};

const panel = createModuleReducer(INITIAL_STATE, HANDLERS);

export default panel;
