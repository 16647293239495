const normalizeParams = (form) => {
  const data = new FormData();

  data.append('title', form.title);
  data.append('attachment', form.attachment[0].signed_id);

  form.variables.forEach((variable: string) => {
    data.append('variables[]', variable);
  });

  return data;
};

export default normalizeParams;
