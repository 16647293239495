import React, { Component} from 'react';
import PropTypes from 'prop-types';

import TabsBoxTab from 'components/TabsBoxContainer/TabsBoxTab';

import './TabsBoxTabs.scss';

class TabsBoxTabs extends Component {
  isTabActive(tabComponent) {
    const { activeTab } = this.props;
    return activeTab === tabComponent;
  }

  render() {
    const { tabs, onTabClick } = this.props;

    return (
      <div className="TabsBoxTabs">
        {tabs.map(tab =>
          <TabsBoxTab
            tabComponent={tab.Component}
            isActive={this.isTabActive(tab)}
            key={`tab${tab.Component.title}`}
            onTabClick={onTabClick(tab)}
          />
        )}
      </div>
    );
  }
}

TabsBoxTabs.propTypes = {
  tabs: PropTypes.array.isRequired,
  activeTab: PropTypes.object.isRequired,
  onTabClick: PropTypes.func.isRequired
};

export default TabsBoxTabs;
