import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  style: PropTypes.object,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  variation: PropTypes.oneOf([
    'default',
    'warning',
    'danger',
    'dimming',
    'success',
    'info',
    'dark',
  ]),
};

const defaultProps = {
  className: '',
  variation: 'default',
  style: null,
};

function OutlineBox(props) {
  const {
    className,
    children,
    variation,
    onClick,
    style,
    onMouseEnter,
    onMouseLeave,
  } = props;

  return (
    <div
      className={`OutlineBox ${variation} ${className}`}
      onClick={onClick}
      style={style}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </div>
  );
}

OutlineBox.propTypes = propTypes;
OutlineBox.defaultProps = defaultProps;

export default OutlineBox;
