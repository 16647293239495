/* eslint-disable no-extra-boolean-cast */
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { postApi } from 'core/utils/api';
import actions from './actions';
import {
  buildToast,
  toastCss,
  ToastTypes,
} from 'store/middlewares/toast/actions';

function* createAccountRequest({ params, account, token }) {
  try {
    yield call(postApi, `/invites/${account}/${token}`, {
      [account]: params,
    });

    yield put({
      type: actions.CREATE_ACCOUNT_SUCCESS,
    });
  } catch (error) {
    const { data } = error;

    if (data.errors) {
      for (var _error of data.errors) {
        yield put({
          type: actions.ERROR,
          error,
          toast: buildToast(
            `${_error}`,
            ToastTypes.error,
            toastCss(ToastTypes.error)
          ),
        });
      }
    }
  }
}

export function* buildToastMessage({ message, typeToast }) {
  yield put({
    type: actions.BUILD_TOAST_SUCCESS,
    toast: buildToast(message, typeToast, toastCss(typeToast)),
  });
}

function* registerAccountSagas() {
  yield all([takeLatest(actions.CREATE_ACCOUNT_REQUEST, createAccountRequest)]);
  yield all([takeLatest(actions.BUILD_TOAST, buildToastMessage)]);
}

export default registerAccountSagas;
