import React from 'react';

import withAppContext from 'core/hoc/withAppContext';
import './style.scss';

const SkeletonEngagemenTab = ({ children, isLoading }) => {
  if (children && !isLoading) {
    return children;
  }

  return (
    <div className="default">
      <div className="container-header">
        <div className="skeleton-conteiner-row-engagement">
          <p
            style={{ width: '20%' }}
            className="skeleton-item-box-line-engagement"
          />
          <p
            style={{ width: '20%' }}
            className="skeleton-item-box-line-engagement"
          />
          <p
            style={{ width: '20%' }}
            className="skeleton-item-box-line-engagement"
          />
          <p style={{ width: '60%' }}></p>
        </div>
      </div>

      <div>
        <div className="skeleton-conteiner-row-engagement"></div>
        <div className="skeleton-line-table-engagement"></div>
        <div className="skeleton-line-table-engagement"></div>
        <div className="skeleton-line-table-engagement"></div>
        <div className="skeleton-line-table-engagement"></div>
        <div className="skeleton-line-table-engagement"></div>
        <div className="skeleton-line-table-engagement"></div>
      </div>
    </div>
  );
};

export default withAppContext(SkeletonEngagemenTab);
