import { ThemeProps } from 'config/themes/types';
import styled, { css } from 'styled-components';

export const ApproveMessageContentWrapper = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    flex-direction: row;
    gap: ${space.xs2};
  `}
`;
