import React, { useEffect } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import reduxProvider from 'core/hoc/reduxProvider';

import DailySummariesPage from 'pages/dailySummaries';
import Students from 'pages/dailySummaries/DailySummaryForm';
import DiarySections from 'pages/diary_sections';
import DailySummaryDetails from 'pages/dailySummaries/DailySummaryDetails';

const DailySummariesScreen = () => {
  const changeWrapperBackgroundColor = () => {
    document.getElementById('main-content').style.backgroundColor = '#ffffff';
  };

  useEffect(() => {
    changeWrapperBackgroundColor();
  }, []);

  return (
    <div className="DailySummariesScreen">
      <BrowserRouter>
        <Switch>
          <Route
            exact
            path="/*/daily_summaries/classrooms/:classroom_id"
            render={(routerProps) => (
              <Students classroomId={routerProps.match.params.classroom_id} />
            )}
          />
          <Route
            exact
            path="/*/diary_sections"
            render={() => <DiarySections />}
          />
          <Route
            exact
            path="/*/daily_summaries"
            render={() => <DailySummariesPage />}
          />
          <Route
            exact
            path="/*/daily_summaries/classrooms/:classroom_id/details"
            render={(routerProps) => (
              <DailySummaryDetails
                classroomId={routerProps.match.params.classroom_id}
              />
            )}
          />
        </Switch>
      </BrowserRouter>
    </div>
  );
};

export default reduxProvider(DailySummariesScreen);
