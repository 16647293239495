import styled, { css } from 'styled-components';
import { Button, Text } from '@agendaedu/ae-web-components';

import { ThemeProps } from 'config/themes/types';

export const GroupChatWrapper = styled.div`
  ${({ theme: { colors } }: ThemeProps) => css`
    height: 100%;
    width: 100%;

    background: ${colors.neutral.white};

    display: flex;
    flex-direction: column;
  `};
`;

export const AvatarWrapper = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    margin-right: ${space.xs};
  `}
`;

export const Avatar = styled.img`
  ${({ theme: { border } }: ThemeProps) => css`
    width: 48px;
    height: 48px;
    border-radius: ${border.radius.circle};
  `}
`;

export const ChatHeaderWrapper = styled.div`
  ${({ theme: { colors, space } }: ThemeProps) => css`
    width: 100%;
    padding: 14px ${space.lg};
    background: ${colors.neutral.white};
    user-select: none;

    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.14);

    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  `};
`;

export const ChatHeaderContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
`;

export const BackButton = styled(Button)`
  border-color: transparent !important;

  @media screen and (min-width: 992px) {
    display: none !important;
  }
`;

export const ChatHeaderContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  @media screen and (max-width: 992px) {
    ${AvatarWrapper} {
      display: none;
    }
  }
`;

export const LoaderWrapper = styled.div`
  margin: 20px 0px;
`;

export const ChatHeaderTitle = styled(Text)`
  ${({ theme: { colors } }: ThemeProps) => css`
    color: ${colors.neutral.gray2};
    margin: 0px;
  `}
`;

export const ChatHeaderSubtitle = styled(Text)`
  ${({ theme: { colors } }: ThemeProps) => css`
    color: ${colors.neutral.black};
    margin: 0px;
  `}
`;

export const ChatContentWrapper = styled.div`
  ${({ theme: { border, colors, gray4 } }: ThemeProps) => css`
    height: 100%;
    padding: 20px 14px 0px 20px;
    margin: 0px;

    display: flex;
    flex-direction: column-reverse;
    overflow: auto;

    &::-webkit-scrollbar {
      background-color: transparent;
      width: 6px;
    }

    &::-webkit-scrollbar-button {
      display: none;
    }

    &::-webkit-scrollbar-track {
      background-color: ${colors.neutral.gray5};
    }

    &::-webkit-scrollbar-thumb {
      border-radius: ${border.radius.sm};
      border: ${border.width.sm} ${border.style.solid} ${gray4};
    }

    .infinite-scroll-component {
      display: flex;
      flex-direction: column-reverse;
    }
  `}
`;

export const EmptyWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;

  > div.EmptyState {
    width: 256px;
  }
`;

export const EmptyAvatarWrapper = styled.div`
  ${({ theme: { border, colors } }: ThemeProps) => css`
    display: flex;
    min-width: 48px;
    min-height: 48px;
    border-radius: ${border.radius.circle};
    background-color: ${colors.neutral.gray4};
    justify-content: center;
    align-items: center;

    svg {
      width: 30px;
      height: 30px;
      path {
        fill: ${colors.neutral.gray2};
      }
    }
  `}
`;
