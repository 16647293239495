import React from 'react';
import MessageBalloonSkeleton from '../MessageBalloon/skeleton';

export const MessagesListSkeleton = () => {
  return (
    <div data-testid="messages-skeleton">
      <MessageBalloonSkeleton variantType="received" />
      <MessageBalloonSkeleton variantType="sent" />
      <MessageBalloonSkeleton variantType="received" />
      <MessageBalloonSkeleton variantType="sent" />
    </div>
  );
};
