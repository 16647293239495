import React from 'react';
import { Grid } from '@agendaedu/ae-web-components';
import { trackEvent } from 'config/amplitude';
import { useSelector } from 'react-redux';
import { HomeSelectorStore, Banner } from 'store/home/types';

import * as S from './styles';

const Banners = () => {
  const { banners } = useSelector((state: HomeSelectorStore) => state.home);

  const eventClick = (eventName: string) => trackEvent(eventName);

  if (!banners.length) return <></>;

  const mainBanner: Banner = banners.find(
    (banner) => banner.attributes.name === 'home_main_banner_ae'
  );

  const secondaryBanner: Banner = banners.find(
    (banner) => banner.attributes.name === 'home_secondary_banner_ae'
  );

  if (mainBanner == null || secondaryBanner == null) return <></>;

  return (
    <Grid
      gridTemplateColumns={{ desktopLG: '3fr 2fr' }}
      gutter={{ _: 'sm', desktopXL: 'xl2' }}
      mt="xl2"
    >
      <Grid item gridColumn="auto">
        <a
          data-testid="main-banner"
          href={mainBanner.attributes.redirect_link}
          rel="noreferrer"
          target="_blank"
          onClick={() => eventClick('home-main-banner')}
        >
          <S.Banner alt="main-banner" src={mainBanner.attributes.file.url} />
        </a>
      </Grid>
      <Grid item gridColumn="auto">
        <a
          data-testid="secondary-banner"
          href={secondaryBanner.attributes.redirect_link}
          rel="noreferrer"
          target="_blank"
          onClick={() => eventClick('home-secondary-banner')}
        >
          <S.Banner
            alt="secondary-banner"
            src={secondaryBanner.attributes.file.url}
          />
        </a>
      </Grid>
    </Grid>
  );
};

export default Banners;
