import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import Card from 'components/Card';

import * as S from './styles';

const ReportCard = ({ report, onDeleteReport, onReportDetails }) => {
  const {
    id,
    attributes: { title, standard },
  } = report;

  const idString = `report-${id}`;

  const getActions = [
    {
      text: 'Excluir',
      onClick: () => onDeleteReport(id),
    },
  ];

  const onOpenDetailsModal = () => {
    onReportDetails(id);
  };

  return (
    <div id={idString} role="listitem" aria-label="Relatório">
      <S.ReportCard
        aria-labelledby={idString}
        title={title}
        actions={getActions}
        link={`#`}
        disableActions={standard}
        onClick={onOpenDetailsModal}
      />
    </div>
  );
};

const ReportCardShape = {
  id: PropTypes.string,
  attributes: {
    title: PropTypes.string,
    standard: PropTypes.bool,
  },
};

ReportCard.propTypes = {
  report: PropTypes.shape(ReportCardShape).isRequired,
  onDeleteReport: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  onReportDetails: PropTypes.func.isRequired,
};

export default withRouter(ReportCard);
