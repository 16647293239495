import validateArrayPresence from 'core/lib/FormValidator/validators/array/presence';
import validateNumericalFloat from 'core/lib/FormValidator/validators/numericalFloat';
import validatePresence from 'core/lib/FormValidator/validators/presence';

export default [
  {
    attributeName: 'original_price',
    validator: validateNumericalFloat({ min: 10.0 }),
    message: 'Valor mínimo de R$ 10,00',
  },

  {
    attributeName: 'allowed_payment_method',
    validator: validateArrayPresence,
    message: 'Selecione um método de pagamento para continuar',
  },
  {
    attributeName: 'sell_period',
    validator: validatePresence,
    message: 'Campo obrigatório',
  },
];
