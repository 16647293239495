import axios from 'axios';

export default class UserServices {
  constructor(dataArea) {
    this.dataArea = dataArea;
  }

  /**
   * @param {string} email
   * @param {string} profileType - student_profiles, responsible_profiles
   * @returns {Promise<object>}
   */
  async verifyEmail(email, profileType) {
    const endpoint = `/${this.dataArea}/users/email_verification?email=${email.toLowerCase()}&profile_type=${profileType}&is_new_record=true`;
    try {
      const response = await axios.get(endpoint);
      return response;
    } catch (error) {
      throw error;
    }
  }

  /**
   * @param {string} username
   * @param {string} profileType - student_profiles, responsible_profiles
   * @returns {Promise<object>}
   */
  async verifyUsername(username, profileType) {
    const endpoint = `/${this.dataArea}/users/username_verification?username=${username.toLowerCase()}&profile_id=new&profile_type=${profileType}&is_new_record=true`;
    try {
      const response = await axios.get(endpoint);
      return response;
    } catch (error) {
      throw error;
    }
  }
}
