/* eslint-disable react/jsx-key */
import React from 'react';
import {
  Grid,
  DefaultThemeProps,
  theme,
  Box,
} from '@agendaedu/ae-web-components';
import { useTranslation } from 'react-i18next';

import * as S from './EditGroupForm/styles';

import FormFullScreen from 'components/FormFullScreen';
import Skeleton from 'components/Skeleton';

export const FormSkeleton = ({
  dataArea,
  title,
}: {
  dataArea: string;
  title: string;
}) => {
  const defaultTheme: DefaultThemeProps = theme;

  const { t } = useTranslation(['handouts']);

  const {
    colors: { neutral },
    border: { radius },
  } = defaultTheme;

  return (
    <S.SkeletonWrapper data-testId="form-skeleton">
      <FormFullScreen
        backTo={`/${dataArea}/handouts`}
        backToExternal
        formMeta={{ nextStepDisabled: true }}
        steps={[
          () => (
            <S.InformationStepWrapper>
              <S.FormSkeletonWrapper>
                <Grid
                  gridTemplateColumns="1fr"
                  gutter={{
                    _: 'lg',
                  }}
                >
                  <Box mb="md">
                    <Skeleton
                      width={190}
                      height={32}
                      primaryColor={neutral.gray6}
                      borderRadius={radius.md}
                    />
                  </Box>

                  <Box display={'flex'}>
                    <Grid item gutter="sm">
                      <Skeleton
                        width={90}
                        height={24}
                        primaryColor={neutral.gray6}
                        borderRadius={radius.md}
                      />

                      <Skeleton
                        width={117}
                        height={117}
                        primaryColor={neutral.gray6}
                        borderRadius={radius.circle}
                      />
                    </Grid>

                    <Box marginLeft={42} width={'90%'}>
                      <Skeleton
                        isFullWidth
                        width={'100%'}
                        height={42}
                        primaryColor={neutral.gray6}
                        borderRadius={radius.md}
                      />

                      <Box display={'flex'} marginTop={24}>
                        <Skeleton
                          isFullWidth
                          width={'100%'}
                          height={91}
                          primaryColor={neutral.gray6}
                          borderRadius={radius.md}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </S.FormSkeletonWrapper>
            </S.InformationStepWrapper>
          ),
        ]}
        titlePage={title}
        titleModal={t('modals.handout_form_discard.title')}
        contentModal={t('modals.handout_form_discard.description')}
      />
    </S.SkeletonWrapper>
  );
};
