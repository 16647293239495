/* eslint-disable @typescript-eslint/no-unused-vars */
import styled, { css } from 'styled-components';
import { ThemeProps } from 'config/themes/types';
import { CardAvatar, Text } from '@agendaedu/ae-web-components';
import AgendaIcon from 'components/AgendaIcon';

export const WhiteBackground = styled.div`
  background-color: ${({ theme }) => theme.white};
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.14);
  border-radius: ${({ theme }) => theme.border.radius.md};
  &::-webkit-scrollbar {
    background-color: ${({ theme }) => theme.white};
    width: 1px;
    border-radius: ${({ theme }) => theme.border.radius.md};
  }
  &::-webkit-scrollbar-button {
    display: none;
  }
  &::-webkit-scrollbar-track {
    background-color: ${({ theme }) => theme.white};
    border-radius: ${({ theme }) => theme.border.radius.md};
  }
  &::-webkit-scrollbar-thumb {
    background: transparent;
    box-shadow: 0px 0px 0px 10vh white;
    border-radius: 10px;
    border: 50%;
  }
`;

export const HorizontalScroll = styled(WhiteBackground)`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    margin-bottom: ${space.xl2};
  `}
`;

export const ContainerCard = styled.div`
  ${({ theme: { colors, space } }: ThemeProps) => css`
    display: inline-block;
    background-color: ${colors.neutral.white};
    margin: ${space.lg} 0px ${space.lg} ${space.lg};
  `}
`;

export const SquareIcon = styled.div<{ bgcolor: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  background-color: ${(props) => props.bgcolor};
  border-radius: ${({ theme }) => theme.border.radius.md};
`;

export const Content = styled.div`
  position: relative;
  z-index: 0;
`;

export const Container = styled.div`
  position: relative;
`;

export const Overlay = styled.div`
  position: absolute;
  z-index: 1;
  margin-left: -18px;
  margin-top: 8px;
  visibility: hidden;
`;

export const Logo = styled.img`
  height: 64px;
  width: 64px;
  border-radius: ${({ theme }) => theme.border.radius.md};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MainTitle = styled.h3`
  ${({ theme: { colors, typography, font } }: ThemeProps) => css`
    ${typography.title.Bold20};
    color: ${colors.neutral.black};
    font-size: ${font.size.xl};
    line-height: 24px;
    margin-top: 0px;
  `}
`;

export const TermColor = styled.span`
  line-height: 170%;
  font-family: ${({ theme }) => theme.font.family.auxiliary};
  font-style: normal;
  font-weight: 700;
  font-size: ${({ theme }) => theme.font.size.sm};
  width: 80%;
  color: ${({ theme }) => theme.colors.brand.primary.default};
  padding: 1px 2px 1px 1px;
  text-align: justify;
  text-justify: inter-word;
`;

export const ContainerTitle = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: ${space.xl} 0px ${space.xl} 0px;
  `}
`;

export const CardStyled = styled.div<{ idCard: string }>`
  ${({ theme: { colors, space, border, shadow } }: ThemeProps) => css`
    width: 112px;
    height: 112px;
    background-color: ${colors.neutral.white};
    border-radius: ${border.radius.md};
    padding: ${space.xs2};
    box-shadow: ${shadow.card};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: width 0.3s ease;
    cursor: pointer;

    ${({ isHovered }) =>
      isHovered &&
      `
       width: 344px;
       justify-content: flex-start;
       flex-direction: row;
       padding: ${space.xs};

    `}
  `}
`;

export const CardStyledContent = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    display: none;
    flex-direction: column;
    padding: ${space.xs};
    word-break: break-all;

    ${({ isHovered }) =>
      isHovered &&
      `
      display: flex;
      animation: fadeIn 2s;
      animation: cssAnimation 0s 0.1s forwards;
      opacity: 0;

      @keyframes cssAnimation {
        to {
          opacity: 1;
        }
      }

    `}
  `}
`;

export const releasedBy = styled.div`
  ${({ theme: { colors, space, border, shadow } }: ThemeProps) => css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: ${space.xs3};
  `}
`;

export const IconTag = styled(AgendaIcon)`
  ${({ theme: { typography } }: ThemeProps) => css`
    color: ${({ partnerName, theme }) =>
      partnerName === 'Agenda Edu' ? theme.primaryColor : theme.gray2};
  `}
`;

export const TextTag = styled(Text)`
  ${({ theme: { typography, space } }: ThemeProps) => css`
    color: ${({ partnerName, theme }) =>
      partnerName === 'Agenda Edu' ? theme.primaryColor : theme.gray2};
    lineheight: ${space.xs};
    ${typography.body.Medium14}
    span {
      font-weight: bold;
    }
  `}
`;
