import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import PageContainer from 'components/PageContainer';
import PageSubTitle from 'components/PageSubTitle';
import Breadcrumb from 'components/Breadcrumb';
import Button from 'components/Button';
import StudentsProgressionForm from 'components/Students/ProgressionForm';
import Loader from 'components/Loader';

import FormContainer from 'components/Form/FormContainer';
import FormContext from 'core/contexts/Form';

import SchoolTermService from 'core/services/SchoolTerm';
import ClassroomsService from 'core/services/Classrooms';
import StudentsService from 'core/services/Students';
import withAppContext from 'core/hoc/withAppContext';
import withToastMessage from 'core/hoc/withToastMessage';

import './style.scss';

class StudentsProgression extends Component {
  static propTypes = {
    appContext: PropTypes.shape({
      dataArea: PropTypes.string.isRequired,
    }).isRequired,
    toastActions: PropTypes.shape({
      showToast: PropTypes.func.isRequired,
    }).isRequiredi,
    formContext: PropTypes.shape({
      formMeta: PropTypes.object,
      updateAttribute: PropTypes.func.isRequired,
    }),
  };

  state = {
    students: [],
    isLoadingSchoolTerm: true,
    activeSchoolTerm: {},
    oldSchoolTerm: {},
    isSubmitting: false,
    classroomFrom: '',
  };

  componentDidMount() {
    this.loadActiveSchoolTerm();
  }

  onClassroomSelectFrom = (classroom) => {
    this.setState({ classroomFrom: classroom });
  };

  onClassroomSelectTo = (classroom) => {
    this.setState({ classroomTo: classroom });
  };

  loadActiveSchoolTerm = async () => {
    const {
      appContext: { dataArea },
    } = this.props;
    const schoolTermService = new SchoolTermService(dataArea);

    try {
      const { activeSchoolTerm, oldSchoolTerm } =
        await schoolTermService.fetchActiveSchoolTerm();

      this.setState({ activeSchoolTerm, oldSchoolTerm });
    } catch (error) {
      this.setState({ error });
    } finally {
      this.setState({ isLoadingSchoolTerm: false });
    }
  };

  loadStudents = async (select) => {
    const {
      appContext: { dataArea },
    } = this.props;
    const classroomsService = new ClassroomsService(dataArea);

    try {
      if (select.length) {
        const students = await classroomsService.getStudentsNotProgressed(
          select
        );
        this.setState({ students });
      } else {
        this.setState({ students: [] });
      }
    } catch (errorFetchingStudents) {
      this.setState({ errorFetchingStudents });
    } finally {
      this.setState({ isLoadingStudents: false });
    }
  };

  onSubmit = async (formState, updateAttribute) => {
    const {
      appContext: { dataArea },
      toastActions: { showToast },
    } = this.props;

    const studentsService = new StudentsService(dataArea);

    this.setState({ isSubmitting: true });
    try {
      const students = await studentsService.advanceStudents(formState);
      updateAttribute('classroom_from', '');
      updateAttribute('classroom_to', '');
      updateAttribute('student_profile_ids', []);
      this.setState({ students: [], classroomFrom: null, classroomTo: null });
      showToast(
        'Os alunos selecionados foram progredidos com sucesso.',
        'success'
      );
    } catch (error) {
      this.setState({ error, isSubmitting: false });
      showToast(
        'Ocorreu um erro, verifique se os alunos possuem algo pendente de validação. Se o erro persistir, aguarde um instante e tente novamente.'
      );
    } finally {
      this.setState({ isSubmitting: false });
    }
  };

  footer = (formState, updateAttribute) => {
    const { isSubmitting } = this.state;
    return (
      <Fragment>
        <a href="/schools/student_profiles">
          <Button variation="secondary">Voltar</Button>
        </a>
        <Button
          onClick={() => this.onSubmit(formState, updateAttribute)}
          disabled={
            !(
              formState.classroom_to &&
              formState.student_profile_ids.length &&
              !isSubmitting
            )
          }
        >
          Salvar
        </Button>
      </Fragment>
    );
  };

  render() {
    const {
      students,
      isLoadingSchoolTerm,
      activeSchoolTerm,
      oldSchoolTerm,
      classroomFrom,
      classroomTo,
    } = this.state;

    return (
      <div className="StudentsProgression">
        <FormContainer action="new" form={{ student_profile_ids: [] }}>
          <FormContext.Consumer>
            {({ form, updateAttribute }) => (
              <Loader isLoading={isLoadingSchoolTerm}>
                <PageContainer
                  title="Progredir alunos"
                  breadcrumb={
                    <Breadcrumb
                      title="Alunos"
                      path="/schools/student_profiles"
                      external
                    />
                  }
                  footer={this.footer(form, updateAttribute)}
                  variation="centered"
                >
                  <PageSubTitle>{`Progredir alunos para ${
                    activeSchoolTerm.attributes &&
                    activeSchoolTerm.attributes.name
                  }`}</PageSubTitle>
                  <span className="info">
                    Escolha a turma antiga e a turma de destino dos alunos que
                    deseja progredir.
                  </span>
                  <StudentsProgressionForm
                    activeSchoolTerm={activeSchoolTerm}
                    oldSchoolTerm={oldSchoolTerm}
                    students={students}
                    loadStudents={this.loadStudents}
                    classroomFrom={classroomFrom}
                    classroomTo={classroomTo}
                    onClassroomSelectFrom={this.onClassroomSelectFrom}
                    onClassroomSelectTo={this.onClassroomSelectTo}
                  />
                </PageContainer>
              </Loader>
            )}
          </FormContext.Consumer>
        </FormContainer>
      </div>
    );
  }
}

export default withAppContext(withToastMessage(StudentsProgression));
