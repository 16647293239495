import React from 'react';
import PropTypes from 'prop-types';

import withFormContext, { formPropTypes } from 'core/hoc/withFormContext';
import FileAttachment from 'components/FileAttachment';

const FileField = ({
  attributeName,
  useGoogleDrive,
  disabled,
  formContext,
  hideTooltip,
  acceptTypes,
  maxSize,
  buttonText,
  multiple,
}) => {
  const { form, formMeta, hasErrorOnAttribute } = formContext;
  const hasError = hasErrorOnAttribute(attributeName);

  const onChangeFiles = (files) => {
    const { updateAttribute } = formContext;

    updateAttribute(attributeName, files);
  };

  return (
    <div className={`FileField ${hasError ? 'has-error' : ''}`}>
      <FileAttachment
        onChangeFiles={onChangeFiles}
        attachments={form[attributeName]}
        disabled={disabled}
        googleClientId={formMeta.googleClientId}
        useGoogleDrive={useGoogleDrive}
        hideTooltip={hideTooltip}
        acceptTypes={acceptTypes}
        maxSize={maxSize}
        buttonText={buttonText}
        multiple={multiple}
      />
    </div>
  );
};

FileField.defaultProps = {
  disabled: false,
  useGoogleDrive: false,
  googleClientId: null,
  hideTooltip: false,
};

FileField.propTypes = {
  attributeName: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  useGoogleDrive: PropTypes.bool,
  googleClientId: PropTypes.string,
  hideTooltip: PropTypes.bool,
  acceptTypes: PropTypes.string,
  maxSize: PropTypes.number,
  buttonText: PropTypes.string,
  multiple: PropTypes.bool,
  ...formPropTypes,
};

export default withFormContext(FileField);
