import styled, { css } from 'styled-components';

import { Icon, Tag, Text } from '@agendaedu/ae-web-components';
import { ThemeProps } from 'config/themes/types';

import {
  OptionItemWrapperStyleProps,
  OptionTagStyleProps,
  WrapperStyleProps,
} from './types';

export const Wrapper = styled.div<WrapperStyleProps>`
  ${({
    disabled,
    removeItemButton,
    isGroupFamily,
    isSelected,
    theme: { border, colors, font },
  }) => css`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    justify-content: space-between;

    background-color: ${isSelected && colors.brand.primary.op10};

    ${!disabled &&
    css`
      :hover {
        background-color: ${colors.brand.primary.op10};

        :hover ${OptionTag} {
          background-color: ${colors.neutral.white} !important;
        }
      }
    `}

    :not(:last-child) {
      border-bottom: ${border.style.solid} ${border.width.sm}
        ${colors.neutral.gray4};
    }

    :last-child {
      border-radius: 0 0 ${border.radius.md} ${border.radius.md};
    }

    :first-child {
      ${removeItemButton &&
      css`
        border-radius: ${border.radius.md} ${border.radius.md} 0 0;
      `}
    }

    :only-child {
      ${removeItemButton &&
      css`
        border-radius: ${border.radius.md};
      `}
    }

    ${disabled &&
    css`
      ${OptionItemText} {
        color: ${colors.neutral.gray3};
      }
    `}

    ${isGroupFamily &&
    css`
      ${OptionItemText} {
        ::before {
          content: 'Família: ';
          ${!disabled &&
          css`
            color: ${colors.neutral.black};
            font-weight: ${font.weight.bold};
          `}
        }
      }
    `}
  `}
`;

export const OptionItemWrapper = styled.div<OptionItemWrapperStyleProps>`
  ${({ isSelected, theme: { space, colors } }) => css`
    display: flex;
    gap: ${space.sm};
    align-items: center;
    justify-content: center;
    padding: ${space.sm};

    span {
      background-color: ${isSelected && colors.brand.primary.default};
      border-color: ${isSelected && colors.brand.primary.default} !important;
    }
  `}
`;

export const OptionItemText = styled(Text)`
  ${({ theme: { space, colors } }: ThemeProps) => css`
    color: ${colors.neutral.black};
    margin: ${space.xs4};
  `}
`;

export const OptionIcon = styled(Icon)`
  cursor: pointer;
`;

export const OptionTag = styled(Tag)<OptionTagStyleProps>`
  ${({ isSelected, theme: { colors } }) => css`
    background-color: ${isSelected && colors.neutral.white} !important;
  `}
`;
