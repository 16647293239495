import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import tabifyForWizard from 'core/hoc/tabifyForWizard';
import withAppContext from 'core/hoc/withAppContext';
import withFormContext from 'core/hoc/withFormContext';

import omniChannelActions from 'store/messages/omniChannel/actions';

import * as S from './styles';

import FormFieldset from 'components/Form/Fieldset';
import { ClassroomSelect } from 'components/Messages/ClassroomSelect';
import { RecipientsSelect } from 'components/Messages/RecipientsSelect';
import { RecipientsSelected } from 'components/Messages/RecipientsSelected';
import { StudentSelect } from 'components/Messages/StudentSelect';

import { Text } from '@agendaedu/ae-web-components';

import {
  Classroom,
  OmniChannelState,
  Recipient,
  StudentProfile,
} from 'store/messages/omniChannel/types';

import { RecipientsStepProps } from './types';

const RecipientsStep = ({
  formContext: {
    changeMeta,
    updateAttribute,
    form: { recipients },
  },
}: RecipientsStepProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['messages']);

  const tBase = useCallback(
    (key: string, params?: Record<string, string | number>) =>
      t(`omni_channel.forms.new_ticket_by_school.steps.second.${key}`, params),
    [t]
  );

  const [groupFamilyStudent, setGroupFamilyStudent] = useState<Recipient>(null);
  const [selectedClassroomId, setSelectedClassroomId] = useState<string>(null);
  const [selectedStudent, setSelectedStudent] = useState<StudentProfile>(null);

  const {
    channel,
    chatClassrooms,
    chatConfirmedResponsibles,
    chatStudentProfiles,
  } = useSelector((state: OmniChannelState) => state.omniChannel);

  const {
    fetchChatStudentProfilesRequest,
    fetchChatConfirmedResponsiblesRequest,
  } = omniChannelActions;

  const fetchStudentProfiles = useCallback(() => {
    dispatch(fetchChatStudentProfilesRequest(selectedClassroomId));
  }, [dispatch, fetchChatStudentProfilesRequest, selectedClassroomId]);

  const fetchConfirmedResponsibles = useCallback(() => {
    dispatch(fetchChatConfirmedResponsiblesRequest(selectedStudent.id));
  }, [dispatch, selectedStudent, fetchChatConfirmedResponsiblesRequest]);

  const canGoToNextStep = recipients?.length;

  useEffect(() => {
    canGoToNextStep
      ? changeMeta('nextStepDisabled', false)
      : changeMeta('nextStepDisabled', true);
  }, [changeMeta, canGoToNextStep]);

  const handleOnChangeSelectedClassroom = (value: string) =>
    setSelectedClassroomId(value);

  const handleOnChangeSelectedStudent = (student: StudentProfile) => {
    const isFamilyChannel = channel.attributes.kind === 'family';

    isFamilyChannel &&
      setGroupFamilyStudent({
        ...student,
        type: 'family',
      });
    setSelectedStudent(student);
  };

  const getClassroomOptions = (data: Classroom[]) =>
    data.map(({ label, options }) => ({
      label,
      options: options?.map(({ attributes: { name }, id }) => ({
        label: name,
        value: id,
      })),
    }));

  const handleChangeRecipients = (
    recipientsList: Recipient[],
    removeRecipients: boolean
  ) => {
    if (removeRecipients) {
      const newRecipientsList = recipients?.filter(
        (item) => !recipientsList?.some((recipient) => recipient.id === item.id)
      );

      updateAttribute('recipients', newRecipientsList);
    } else {
      updateAttribute('recipients', [...recipients, ...recipientsList]);
    }
  };

  const handleAddAllRecipients = (recipientsList: Recipient[]) => {
    const newListRecipients = [
      ...recipients,
      ...recipientsList
        .filter((recipient) => {
          return (
            !recipients.some((item) => item.id === recipient.id) &&
            recipient.type !== 'family'
          );
        })
        .map((recipient) => ({
          ...recipient,
          studentProfileId: selectedStudent.id,
        })),
    ];

    updateAttribute('recipients', newListRecipients);
  };

  const handleRemoveAllRecipients = () => updateAttribute('recipients', []);

  useEffect(() => {
    !!selectedClassroomId && fetchStudentProfiles();
    handleOnChangeSelectedStudent(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClassroomId, fetchStudentProfiles]);

  useEffect(() => {
    !!selectedStudent && fetchConfirmedResponsibles();
  }, [selectedStudent, fetchConfirmedResponsibles]);

  return (
    <FormFieldset>
      <S.RowWrapper>
        <Text variant="title-bold-20" color="neutral.black" mb={0} mt={32}>
          {tBase('title')}
        </Text>

        <Text variant="body-regular-14" mb={0} mt={24}>
          {tBase('tip')}
        </Text>

        <S.SelectOptionsWrapper>
          <ClassroomSelect
            label={tBase('select.classroom')}
            options={getClassroomOptions(chatClassrooms)}
            placeholder={tBase('select.placeholder_classroom')}
            emptyText={tBase('select.not_find_classroom_text')}
            onChange={(option) =>
              handleOnChangeSelectedClassroom(option?.value)
            }
          />

          <StudentSelect
            label={tBase('select.students')}
            options={chatStudentProfiles}
            placeholder={tBase('select.placeholder_students')}
            emptyText={tBase('select.not_find_student_text')}
            onChange={(student) => handleOnChangeSelectedStudent(student)}
            selectedStudent={selectedStudent}
            disabled={!selectedClassroomId}
          />
        </S.SelectOptionsWrapper>

        {selectedStudent && !!chatConfirmedResponsibles && (
          <>
            <S.RecipientSelectText variant="body-regular-16">
              {tBase('select.select_recipients_title')}
            </S.RecipientSelectText>

            <RecipientsSelect
              onChange={handleChangeRecipients}
              addAllRecipients={handleAddAllRecipients}
              selectedRecipients={recipients}
              selectedStudent={selectedStudent}
              recipientsList={
                groupFamilyStudent
                  ? [
                      groupFamilyStudent,
                      selectedStudent,
                      ...chatConfirmedResponsibles,
                    ]
                  : [selectedStudent, ...chatConfirmedResponsibles]
              }
            />
          </>
        )}

        {recipients?.length > 0 && (
          <>
            <S.RecipientSelectText variant="body-regular-16">
              {tBase('select.selected_recipients_title')}
            </S.RecipientSelectText>

            <RecipientsSelected
              recipientsList={recipients}
              onChange={handleChangeRecipients}
              removeAllRecipients={handleRemoveAllRecipients}
            />
          </>
        )}
      </S.RowWrapper>
    </FormFieldset>
  );
};

export default tabifyForWizard({
  title: '2 Participantes',
})(withAppContext(withFormContext(RecipientsStep)));
