import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class TextField extends PureComponent {
  static propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    placeholder: PropTypes.string,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
  };

  static defaultProps = {
    value: '',
    disabled: false,
    placeholder: '',
  };

  render() {
    const { value, onChange, disabled, placeholder, onFocus, onBlur } =
      this.props;

    return (
      <div className="TextField">
        <input
          type="text"
          className="form-control"
          onChange={onChange}
          value={value}
          disabled={disabled}
          placeholder={placeholder}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      </div>
    );
  }
}

export default TextField;
