import styled, { css } from 'styled-components';

import { ThemeProps } from 'config/themes/types';
import { Alert, Text } from '@agendaedu/ae-web-components';

export const StepTitle = styled.h1`
  ${({ theme: { typography, colors } }: ThemeProps) => css`
    ${typography.title.Bold20}
    color: ${colors.neutral.black};
  `}
`;

export const RowWrapper = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    flex-direction: column;
    gap: ${space.lg};
    padding: ${space.xs4};
    width: 100%;

    .Field {
      display: flex;
      padding: ${space.xs4};
      margin: ${space.xs4} !important;
    }
  `}
`;

export const SelectOptionsWrapper = styled.div`
  ${({ theme: { space, breakpoints } }: ThemeProps) => css`
    display: flex;
    flex-direction: row;
    gap: ${space.sm};

    @media screen and (max-width: ${breakpoints.tabletMD}) {
      flex-direction: column;
      gap: ${space.xs4};
    }
  `}
`;

export const RecipientsAlert = styled(Alert)`
  width: 100% !important;
  justify-content: flex-start !important;
`;

export const TextArea = styled.textarea`
  min-height: 120px;

  ${({ theme: { border, colors, space, font } }: ThemeProps) => css`
    border: ${border.width.sm} ${border.style.solid} ${colors.neutral.gray4};
    border-radius: ${border.radius.md};
    padding: ${space.xs2} ${space.sm};
    font-size: ${font.size.xs};
    font-weight: ${font.weight.regular};
    font-style: ${font.family.auxiliary};
    color: ${colors.neutral.black};
    margin-bottom: ${space.sm};

    &:hover {
      border-color: ${colors.brand.primary.default};
    }

    &:focus-within {
      border-color: ${colors.brand.primary.default};
    }
  `}
`;

export const TextAreaWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const TextAreaLabel = styled(Text)`
  ${({ theme: { colors, space } }: ThemeProps) => css`
    margin-bottom: ${space.sm};
    color: ${colors.neutral.gray1};
  `}
`;
