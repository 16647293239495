const actions = {
  ERROR: 'ERROR/reports',
  ADD_REPORT_REQUEST: 'ADD_REPORT_REQUEST/reports',
  ADD_REPORT_SUCCESS: 'ADD_REPORT_SUCCESS/reports',
  FETCH_REPORT_REQUEST: 'FETCH_REPORT_REQUEST/reports',
  FETCH_REPORT_SUCCESS: 'FETCH_REPORT_SUCCESS/reports',
  FETCH_ALL_REPORT_TITLE_REQUEST: 'FETCH_ALL_REPORT_TITLE_REQUEST/reports',
  FETCH_ALL_REPORT_TITLE_SUCCESS: 'FETCH_ALL_REPORT_TITLE_SUCCESS/reports',
  FETCH_DELETE_REPORT_REQUEST: 'FETCH_DELETE_REPORT_REQUEST/reports',
  FETCH_DELETE_REPORT_SUCCESS: 'FETCH_DELETE_REPORT_SUCCESS/reports',
  FETCH_REPORT_DETAILS_REQUEST: 'FETCH_REPORT_DETAILS_REQUEST/reports',
  FETCH_REPORT_DETAILS_SUCCESS: 'FETCH_REPORT_DETAILS_SUCCESS/reports',
  addReport: (params, history) => ({
    type: actions.ADD_REPORT_REQUEST,
    params,
    history,
  }),
  fetchReports: (queryParams) => ({
    type: actions.FETCH_REPORT_REQUEST,
    queryParams,
  }),
  fetchAllReportsTitles: (queryParams) => ({
    type: actions.FETCH_ALL_REPORT_TITLE_REQUEST,
    queryParams,
  }),
  deleteReport: (reportId) => ({
    type: actions.FETCH_DELETE_REPORT_REQUEST,
    reportId,
  }),
  fetchReportDetails: (reportId) => ({
    type: actions.FETCH_REPORT_DETAILS_REQUEST,
    reportId,
  }),
};

export default actions;
