import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import * as S from './styles';

import withAppContext from 'core/hoc/withAppContext';

import actionMessages from 'store/messages/actions';
import { MessageState } from 'store/messages/types';

import FormFullScreen from 'components/FormFullScreen';
import GroupInformationStep from 'components/Messages/Forms/Steps/GroupInformationStep';
import GroupMembersStep from 'components/Messages/Forms/Steps/GroupMembersStep';
import Loader from 'components/Loader';
import Toast from 'components/Toast';
import { FormSkeleton } from '../skeleton';

import { Props } from './types';

const WIZARD_STEPS = [GroupInformationStep, GroupMembersStep];

const NewGroupForm: React.FC<Props> = ({ appContext: { dataArea }, match }) => {
  const { t } = useTranslation(['messages']);

  const { id: groupId } = match?.params;

  const [form, setForm] = useState({
    name: null,
    description: null,
    member_ids: [],
    icon: null,
  });

  const dispatch = useDispatch();

  const { isLoading, isSending, currentGroup } = useSelector(
    (state: MessageState) => state.messages
  );

  const { setEditGroupRequest, fetchGroupDetailsRequest } = actionMessages;

  const handlerEditGroup = useCallback(
    (params) => {
      dispatch(setEditGroupRequest(groupId, params));
    },
    [dispatch, groupId, setEditGroupRequest]
  );

  const handleSubmit = (formContext) => {
    const { form } = formContext;
    handlerEditGroup(form);
  };

  useEffect(() => {
    if (groupId && currentGroup?.id !== groupId) {
      dispatch(fetchGroupDetailsRequest(groupId));
    }
  }, [currentGroup, dispatch, fetchGroupDetailsRequest, groupId]);

  useEffect(() => {
    if (currentGroup) {
      setForm({
        ...form,
        name: currentGroup.attributes.name,
        description: currentGroup.attributes.description,
        icon: currentGroup?.attributes?.icon,
        member_ids: currentGroup.relationships.members.data.map((member) =>
          member?.attributes?.member_id.toString()
        ),
      });
    }
  }, [currentGroup]);

  return (
    <S.NewGroupFormsWrapper>
      <Loader isLoading={isLoading}>
        {form?.member_ids.length ? (
          <FormFullScreen
            id="message-group-edit"
            form={form}
            formMeta={{ nextStepDisabled: true }}
            action="edit"
            onSubmit={handleSubmit}
            isSubmitting={isSending}
            steps={WIZARD_STEPS}
            backTo={`/${dataArea}/messages/groups`}
            titlePage={t('groups.forms.title_edit_page')}
            titleModal={t('groups.forms.title_modal')}
            contentModal={t('groups.forms.content_modal')}
          />
        ) : (
          <FormSkeleton
            dataArea={dataArea}
            title={t('groups.forms.title_edit_page')}
          />
        )}
        <Toast />
      </Loader>
    </S.NewGroupFormsWrapper>
  );
};

export default withAppContext(NewGroupForm);
