import styled, { keyframes, css } from 'styled-components';
import AgendaIcon from 'components/AgendaIcon';

const customStyle = css`
  color: ${({ theme }) => theme.gray2};
  font-size: 14px;
  display: ${({ destroyed }) => (destroyed ? 'none' : 'flex')};
  flex-flow: column wrap;
  list-style: none;
  padding: 6px 12px;
  margin-bottom: 16px;
  border-radius: 4px;
  border: solid 1px ${({ theme }) => theme.gray12};
  background-color: ${({ theme }) => theme.gray14};

  * {
    line-height: 24px;
  }
`;

export const File = styled.li`
  ${customStyle};
`;

export const TypeIcon = styled(AgendaIcon)`
  margin-right: 8px;
`;

export const SpinnerIcon = styled.i`
  margin: 0 10px 0 32px;
`;

export const RemoveButton = styled.button`
  display: inline-flex;
  align-items: center;
  margin-left: 32px;
  background-color: transparent;
  border: 0px;
`;

export const VideoPreview = styled.video`
  width: 100%;
  height: 347px;
  background-color: #000;
  border-radius: 1%;
  margin: 8px 0;
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const ProgressContainer = styled.div`
  height: 347px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column wrap;

  .rc-progress-line {
    height: 16px;
    width: 100%;
    border-radius: 8px;
  }

  i {
    margin-bottom: 8px;
    color: ${({ theme }) => theme.primaryColor};
    animation: ${rotate} 1.2s linear infinite;
  }

  p {
    margin-top: 8px;
    line-height: 27px;
    width: 100%;
    text-align: center;
    font-weight: 600;
    font-size: 16px;
  }
`;

export const InfoContainer = styled.div`
  width: 100%;
  display: inline-flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
`;

export const FileName = styled.span`
  flex-grow: 2;
`;

export const FileDownload = styled.li`
  ${customStyle};
  cursor: pointer;

  .fa-spinner {
    margin: 0px 12px 0px 4px;
  }

  opacity: ${({ isLoadingFile }) => isLoadingFile && 0.5};
  cursor: ${({ isLoadingFile }) => isLoadingFile && 'not-allowed'};

  &:hover {
    color: ${({ theme }) => theme.primaryColor};
  }
`;

export const Anchor = styled.a`
  display: none;
`;

export const OpenLinkAttachemnt = styled.a`
  ${customStyle};
  width: 100%;
  max-width: 617px;
  border-radius: 8px;
  padding: 8px 12px;
  cursor: pointer;

  .fa-spinner {
    margin: 0px 12px 0px 4px;
  }

  opacity: ${({ isLoadingFile }) => isLoadingFile && 0.5};
  cursor: ${({ isLoadingFile }) => isLoadingFile && 'not-allowed'};

  span {
    font-family: 'Roboto', sans-serif !important;
    font-size: 14px;
    font-weight: 400;
    line-height: 170%;
    letter-spacing: 0%;
    color: ${({ theme }) => theme.gray2} !important;
  }

  &:hover {
    color: ${({ theme }) => theme.primaryColor};
  }
`;
