import { ThemeProps } from 'config/themes/types';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme: { border, colors } }: ThemeProps) => css`
    display: flex;
    flex-direction: column;
    height: 171px;
    width: 164px;
    border: ${border.style.solid} ${border.width.sm} ${colors.neutral.gray4};
    border-radius: 12px;
    justify-content: start;
    align-items: center;
  `}
`;

export const OverlayOptionsWrapper = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: ${space.sm};
    opacity: 0;
    transition: opacity 0.5s ease;
  `}
`;

export const OverlayTemplateCard = styled.div`
  ${({ theme: { colors } }: ThemeProps) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    height: 170px;
    width: 163px;
    border-radius: 12px;
    transition: background 0.5s ease, backdrop-filter 0.5s ease,
      z-index 0.3s ease;

    :hover {
      background: ${colors.brand.primary.op10};
      backdrop-filter: blur(2px);
      z-index: 1;
      ${OverlayOptionsWrapper} {
        opacity: 1;
      }
    }
  `}
`;

export const TemplateCoverImage = styled.img`
  ${({ theme: { border, space } }: ThemeProps) => css`
    height: 79px;
    width: 116px;
    border-radius: ${border.radius.md};
    margin-top: ${space.lg};
  `}
`;

export const EmptyImageWrapper = styled.div`
  ${({ theme: { border, colors, space } }: ThemeProps) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 79px;
    width: 116px;
    border: ${border.radius.md};
    background-color: ${colors.neutral.gray5};
    border-radius: ${border.radius.md};
    margin-top: ${space.lg};

    .ae-image-icon {
      width: 40px !important;
      height: 40px !important;
      path {
        fill: ${colors.neutral.gray2};
      }
    }
  `}
`;

export const TemplateTitleWrapper = styled.div`
  width: 113px;
  display: flex;
  justify-content: center;
`;

export const TemplateTitle = styled.label`
  ${({ theme: { colors, space, font } }: ThemeProps) => css`
    margin-top: ${space.sm};
    margin-bottom: 0px;
    font-size: ${font.size.xs};
    font-family: ${font.family.brand};
    font-weight: 700;
    color: ${colors.neutral.black};
    white-space: normal;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  `}
`;
