/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import { Box, Text } from '@agendaedu/ae-web-components';
import { Props } from './types';

const CountDown = ({ timeMinutes, finishedCount }: Props): JSX.Element => {
  const [elementOut, setElementOut] = useState<JSX.Element>();

  const formatNumber = (num: number): string => {
    return num.toLocaleString('pt-BR', {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
  };

  useEffect(() => {
    const duration = timeMinutes ? timeMinutes : 1;
    const endTime = new Date();

    endTime.setMinutes(endTime.getMinutes() + duration);
    let min = duration;
    let sec = 0;

    const interval = setInterval(() => {
      sec--;

      if (sec === -1) {
        sec = 59;
        min--;
      }

      const countElement = (
        <Text
          as="p"
          variant="body-regular-16"
          textAlign="start"
          lineHeight="lg"
          isBold
        >
          {formatNumber(min)}:{formatNumber(sec)}
        </Text>
      );

      if (min === 0 && sec === 0) {
        clearInterval(interval);
        finishedCount();
      }

      setElementOut(countElement);
    }, 1000);

    return () => clearInterval(interval);
  }, [timeMinutes]);

  return (
    <Box flexDirection="column" alignContent="center" justifyContent="center">
      {elementOut}
    </Box>
  );
};

export default CountDown;
