import React from 'react';
import { RegisterAccountFormBoxProps } from './types';
import { Box, Card, Button } from '@agendaedu/ae-web-components';
import FormContainer from 'components/Form/FormContainer';
import Loader from 'components/Loader';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

import * as S from './styles';

const RegisterAccountFormBox = ({
  children,
  onSubmit,
  isFormValid,
  isSaved,
  isLoading,
  hasAccount,
}: RegisterAccountFormBoxProps) => {
  const { t } = useTranslation(['register_account']);

  return (
    <S.BoxContainer className="RegisterAccountForm">
      <Loader isLoading={isLoading}>
        {isSaved ? (
          <Card>
            <S.CompletedFormTitle>
              {t('form.completed_title')}
            </S.CompletedFormTitle>

            <S.CompletedFormIllustration>
              <img
                src="https://static.agendaedu.com/invite/teamgroup.png"
                alt="Logo"
                height={250}
              />
            </S.CompletedFormIllustration>

            <S.CompletedFormInfo>
              {parse(t('form.completed_text'))}
            </S.CompletedFormInfo>
          </Card>
        ) : (
          <Card>
            <Box
              margin="0 auto 32px auto"
              display="flex"
              alignItems="center"
              flexDirection="column"
            >
              <img
                src="https://static.agendaedu.com/assets/marca/logo-agenda-cf3d296583f9253dd102e6a25586bce4c68ef4f669bea31cf25a7f6f49a265f3.svg"
                alt="Logo"
                width={64}
              />

              <S.FormTitle>{t('form.title')}</S.FormTitle>
            </Box>

            <FormContainer variation="vertical">
              {children}
              {hasAccount && (
                <S.PasswordNew>
                  Esqueceu sua senha?{' '}
                  <a
                    href="/school_users/password/new"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Clique aqui
                  </a>{' '}
                  para redefini-la.
                </S.PasswordNew>
              )}
              <Button onClick={onSubmit} disabled={!isFormValid}>
                {parse(t('form.submit_button_text'))}
              </Button>
            </FormContainer>
          </Card>
        )}
      </Loader>

      <S.BoxContainerFooter>
        {t('form.footer.paragraph')} <br />
        <>
          <S.BoxContainerLink
            target="_blank"
            href="https://web.agendaedu.com/users/terms_of_use"
            rel="noreferrer"
          >
            {t('form.footer.terms_of_use')}
          </S.BoxContainerLink>{' '}
          e{' '}
          <S.BoxContainerLink
            target="_blank"
            href="https://web.agendaedu.com/users/privacy_terms"
            rel="noreferrer"
          >
            {t('form.footer.privacy_terms')}
          </S.BoxContainerLink>
        </>
      </S.BoxContainerFooter>
    </S.BoxContainer>
  );
};

export default RegisterAccountFormBox;
