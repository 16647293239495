import styled, { css } from 'styled-components';

export const ImageFieldContainer = styled.section`
  --container-height: 180px;
  width: 100%;

  .DropzoneContainer {
    width: auto;
  }
`;

export const CaptionText = styled.span`
  ${({ theme: { colors, typography, space } }) => css`
    display: block;
    text-align: center;
    margin-top: ${space.sm};
    color: ${colors.neutral.gray2};
    ${typography.title.Bold16};
  `}
`;

export const DropzoneBackground = styled.div`
  ${({ theme: { colors, border } }) => css`
    height: var(--container-height);
    min-height: var(--container-height);
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    background-color: ${colors.neutral.gray6};
    border: ${border.width.md} ${border.style.solid} ${colors.neutral.gray4};
    border-radius: ${border.radius.md};

    &:hover {
      border: ${border.width.md} ${border.style.solid}
        ${colors.brand.primary.default};

      background-color: ${colors.brand.primary.op10};

      ${CaptionText}, ${ImageSpecs}, ${SelectImageArea} > span {
        color: ${colors.brand.primary.op45};
      }

      .ae-image-icon path {
        fill: ${colors.brand.primary.op45};
      }

      ${Divider} {
        border-left: ${border.width.md} ${border.style.solid}
          ${colors.brand.primary.op45};
      }
    }
  `}
`;

export const DragHere = styled.div`
  ${({ theme: { colors, breakpoints } }) => css`
    width: 50%;
    height: 70%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;

    .ae-image-icon {
      display: block;
      text-align: center;
      height: 48px;
      width: 48px;

      path {
        fill: ${colors.neutral.gray2};
      }
    }

    @media screen and (max-width: ${breakpoints.mobileSM}) {
      display: none;
    }
  `}
`;

export const Divider = styled.span`
  ${({ theme: { space, border, colors, breakpoints } }) => css`
    height: ${space.lg};
    width: ${space.xs4};
    border-left: ${border.width.sm} ${border.style.solid}
      ${colors.neutral.gray3};
    border-radius: ${border.radius.md};

    position: absolute;
    right: 50%;

    &:first-of-type {
      top: 15%;
    }

    &:last-of-type {
      top: 45%;
    }

    @media screen and (max-width: ${breakpoints.mobileSM}) {
      display: none;
    }
  `}
`;

export const SelectImageArea = styled.div`
  ${({ theme: { typography, breakpoints } }) => css`
    width: 50%;
    height: 70%;
    display: flex;
    justify-content: center;
    align-items: center;

    > span:not(${Divider}) {
      width: 18px;

      position: absolute;
      right: calc(50% - 9px);
      ${typography.subtitle.Medium14}
      line-height: 100%;

      @media screen and (max-width: ${breakpoints.mobileSM}) {
        display: none;
      }
    }
  `}
`;

export const ImageSpecs = styled.p`
  ${({ theme: { typography } }) => css`
    width: 100%;
    height: 30%;
    text-align: center;
    flex-grow: 2;
    ${typography.label.Medium12}
  `}
`;

export const ImagePreview = styled.img`
  display: block;
  margin: auto;
  background-size: cover;
  width: 100%;
  border-radius: 8px;
`;

export const ErrorMessage = styled.p`
  ${({ theme: { colors, space, typography } }) => css`
    margin-top: ${space.xs2};
    color: ${colors.context.alert.default};
    ${typography.label.Regular14}
  `}
`;

export const PreviewContainer = styled.div<{
  image: string;
  customPreviewSize?: number;
}>`
  ${(props) =>
    props.customPreviewSize &&
    css`
      width: ${props.customPreviewSize}px !important;
      margin-left: auto !important;
      margin-right: auto !important;
    `}

  ${({ theme: { space, border, colors }, image }) => css`
    height: var(--container-height);
    min-height: var(--container-height);
    border-radius: ${border.radius.md};
    background-image: url(${image});
    background-size: cover;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    gap: ${space.xs};
    transition: all 1s;
    box-shadow: none;

    &:hover {
      box-shadow: inset 0 0 0 100vw ${colors.neutral.modalBackground};

      button {
        display: flex;
      }
    }

    button {
      display: none;
      /* transition: all 1s; */
      pointer-events: initial;
      background-color: transparent;
      border: ${border.width.sm} ${border.style.solid} ${colors.neutral.white};
      color: ${colors.neutral.white};

      > svg path {
        fill: ${colors.neutral.white};
      }
    }
  `}
`;
