// phone mask for (99) 9999-9999 or (99) 99999-9999 digits
export const phoneMask = (value) => {
  const phone = value.replace(/\D/g, '');

  if (!phone) return '';

  if (phone.length > 10) {
    return value
      .replace(/[\D]/g, '')
      .replace(/(\d{2})(\d)/, '($1) $2')
      .replace(/(\d{5})(\d)/, '$1-$2')
      .replace(/(-\d{4})(\d+?)/, '$1');
  } else {
    return value
      .replace(/[\D]/g, '')
      .replace(/(\d{2})(\d)/, '($1) $2')
      .replace(/(\d{4})(\d)/, '$1-$2')
      .replace(/(-\d{4})(\d+?)/, '$1');
  }
};
