import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import {
  buildToast,
  toastCss,
  ToastTypes,
} from 'store/middlewares/toast/actions';
import { fetchApi, postApi, deleteApi, putApi } from 'core/utils/api';

import i18n from 'config/i18n';

import actions from './actions';

export function* addSurvey(action) {
  try {
    const dataArea = yield select((state) => state.root.dataArea);
    const { data } = yield call(postApi, `/${dataArea}/surveys`, action.params);

    action.history.push({ pathname: `/${dataArea}/surveys/${data.id}` });

    yield put({
      type: actions.ADD_SURVEY_SUCCESS,
      data,
      toast: buildToast(
        i18n.t(`surveys:toasts.${action.params.survey.kind}.create_success`),
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });

    yield put({
      type: actions.FETCH_SURVEY_VERSIONS_REQUEST,
      page: 1,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      toast: buildToast(
        i18n.t(`surveys:toasts.${action.params.survey.kind}.create_error`),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
      error,
    });
  }
}

export function* fetchSurveys(_action) {
  try {
    const { queryParams } = _action;

    const filter = Object.entries(queryParams.params.filter).reduce(
      (prev, [key, value]) => {
        if (Array.isArray(value)) {
          return value.some(Boolean)
            ? prev.concat(`&filter[${key}][]=${value.join(',')}`)
            : prev;
        }

        return value ? prev.concat(`&filter[${key}]=${value}`) : prev;
      },
      ''
    );

    const dataArea = yield select((state) => state.root.dataArea);

    const surveysListFields = [
      'title',
      'kind',
      'status',
      'createdAt',
      'limitDatetime',
      'sentKind',
      'surveyAnsweredCount',
      'surveySentCount',
      'recipients',
      'user_permission',
    ];

    const urlToFetch = queryParams.nextLink
      ? queryParams.nextLink.split('surveys.json?')[1]
      : `fields[survey]=${surveysListFields}&include=userPermission,recipients${filter}`;

    const {
      data,
      meta,
      included,
      history: { links },
    } = yield call(fetchApi, `/${dataArea}/surveys.json?${urlToFetch}`);

    yield put({
      type: actions.FETCH_SURVEYS_SUCCESS,
      title_cont: queryParams.params.filter.title,
      selectedSituation: queryParams.params.filter.situation,
      selectedHeadquarter: queryParams.params.filter.headquarterIds?.[0],
      selectedEducationalStage:
        queryParams.params.filter.educationalStageIds?.[0],
      selectedClassroomFilter: queryParams.params.filter.classroomIds?.[0],
      paginationLinks: links,
      data,
      meta,
      included,
      isPagination: !!queryParams.nextLink,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        'Não foi possível listar formulários.',
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* fetchSurveyDetails(_action) {
  try {
    const { surveyId } = _action;

    const dataArea = yield select((state) => state.root.dataArea);

    const { data, included } = yield call(
      fetchApi,
      `/${dataArea}/surveys/${surveyId}.json?include=userPermission,recipients`
    );

    yield put({
      type: actions.FETCH_SURVEY_DETAILS_SUCCESS,
      data,
      included,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        'Não foi possível exibir o formulário.',
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* fetchSurveyForm(_action) {
  try {
    const { surveyId } = _action;

    const dataArea = yield select((state) => state.root.dataArea);

    const { data, meta, included } = yield call(
      fetchApi,
      `/${dataArea}/surveys/${surveyId}/edit.json`
    );

    const { history } = yield call(
      fetchApi,
      `/${dataArea}/student_profiles/classrooms.json`
    );

    yield put({
      type: actions.FETCH_SURVEY_FORM_SUCCESS,
      data,
      meta,
      classrooms: history,
      questions: included,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        'Não foi possível exibir os dados.',
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* updateSurvey(_action) {
  try {
    const { surveyId, params } = _action;

    const dataArea = yield select((state) => state.root.dataArea);

    const { data } = yield call(
      putApi,
      `/${dataArea}/surveys/${surveyId}`,
      params
    );

    _action.history.push({ pathname: `/${dataArea}/surveys/${data.id}` });

    yield put({
      type: actions.UPDATE_SURVEY_SUCCESS,
      data,
      selected: data,
      toast: buildToast(
        i18n.t(`surveys:toasts.${params.survey.kind}.update_success`),
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });

    yield put({
      type: actions.FETCH_SURVEY_VERSIONS_REQUEST,
      page: 1,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        i18n.t(`surveys:toasts.${_action.params.survey.kind}.update_error`),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* fetchSurveyQuestions(_action) {
  try {
    const { surveyId } = _action;

    const dataArea = yield select((state) => state.root.dataArea);

    const { data, meta, itemsCountPerPage, totalItemsCount } = yield call(
      fetchApi,
      `/${dataArea}/surveys/${surveyId}/survey_questions.json`,
      { params: _action.params }
    );

    yield put({
      type: actions.FETCH_SURVEY_QUESTIONS_SUCCESS,
      pagination: { itemsCountPerPage, totalItemsCount },
      data,
      meta,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        'Não foi possível listar as respostas.',
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* fetchSurveyAuthorizationQuestions(_action) {
  try {
    const { surveyId, params, nextLink } = _action;

    const filtersFields = Object.entries(params).reduce(
      (prev, [key, value]) => {
        if (Array.isArray(value)) {
          return value.some(Boolean)
            ? prev.concat(`&filter[${key}][]=${value.join(',')}`)
            : prev;
        }

        return value ? prev.concat(`&filter[${key}]=${value}`) : prev;
      },
      ''
    );

    const dataArea = yield select((state) => state.root.dataArea);

    const urlToFetch = nextLink
      ? nextLink.split('survey_questions.json?')[1]
      : `${filtersFields}`;

    const {
      data,
      meta,
      history: { links },
    } = yield call(
      fetchApi,
      `/${dataArea}/surveys/${surveyId}/survey_questions.json?${urlToFetch}`
    );

    yield put({
      type: actions.FETCH_SURVEY_AUTHORIZATION_QUESTIONS_SUCCESS,
      pagination: links,
      data,
      meta,
      isNextLink: !!nextLink,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        'Não foi possível listar as respostas.',
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* fetchDeleteSurvey(_action) {
  try {
    const { survey, callbackSuccess } = _action;

    const dataArea = yield select((state) => state.root.dataArea);
    const month = yield select((state) => state.surveys.selectedMonth);
    const year = yield select((state) => state.surveys.selectedYear);
    const title_cont = yield select((state) => state.surveys.title_cont);

    yield call(deleteApi, `/${dataArea}/surveys/${survey.id}`);

    const { data } = yield call(fetchApi, `/${dataArea}/surveys.json`, {
      params: { month, year, title_cont },
    });

    yield put({
      type: actions.FETCH_SURVEYS_DELETE_SUCCESS,
      data,
      toast: buildToast(
        i18n.t(`surveys:toasts.${survey.attributes.kind}.delete_success`),
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });

    callbackSuccess?.();
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        i18n.t(`surveys:toasts.${_action.survey.attributes.kind}.delete_error`),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* fetchResendSurvey(_action) {
  try {
    const { survey } = _action;

    const dataArea = yield select((state) => state.root.dataArea);
    const month = yield select((state) => state.surveys.selectedMonth);
    const year = yield select((state) => state.surveys.selectedYear);
    const title_cont = yield select((state) => state.surveys.title_cont);

    const updatedSurvey = yield call(
      putApi,
      `/${dataArea}/surveys/${survey.id}/resend`,
      survey
    );

    const { data } = yield call(fetchApi, `/${dataArea}/surveys.json`, {
      params: { month, year, title_cont },
    });

    yield put({
      type: actions.FETCH_SURVEYS_RESEND,
      data,
      selected: updatedSurvey.data,
      toast: buildToast(
        i18n.t(`surveys:toasts.${survey.attributes.kind}.re_send_success`),
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });

    yield put({
      type: actions.FETCH_SURVEY_VERSIONS_REQUEST,
      page: 1,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        i18n.t(
          `surveys:toasts.${_action.survey.attributes.kind}.re_send_error`
        ),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* approveSurveyRequest(_action) {
  try {
    const { survey, callbackSuccess } = _action;

    const dataArea = yield select((state) => state.root.dataArea);
    const month = yield select((state) => state.surveys.selectedMonth);
    const year = yield select((state) => state.surveys.selectedYear);
    const title_cont = yield select((state) => state.surveys.title_cont);

    const updatedSurvey = yield call(
      putApi,
      `/${dataArea}/surveys/${survey.id}/approve`,
      survey
    );

    const { data } = yield call(fetchApi, `/${dataArea}/surveys.json`, {
      params: { month, year, title_cont },
    });

    yield put({
      type: actions.APPROVE_SURVEY_SUCCESS,
      data,
      selected: updatedSurvey.data,
      toast: buildToast(
        i18n.t(`surveys:toasts.${survey.attributes.kind}.approve_success`),
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });

    callbackSuccess?.();
    yield put({
      type: actions.FETCH_SURVEY_VERSIONS_REQUEST,
      page: 1,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        i18n.t(
          `surveys:toasts.${_action.survey.attributes.kind}.approve_error`
        ),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* disapproveSurveyRequest(_action) {
  try {
    const { survey, cancelReason } = _action;

    const dataArea = yield select((state) => state.root.dataArea);
    const month = yield select((state) => state.surveys.selectedMonth);
    const year = yield select((state) => state.surveys.selectedYear);
    const title_cont = yield select((state) => state.surveys.title_cont);

    const disapprovedSurvey = yield call(
      putApi,
      `/${dataArea}/surveys/${survey.id}/reject`,
      {
        cancel_reason: cancelReason,
      }
    );

    const { data } = yield call(fetchApi, `/${dataArea}/surveys.json`, {
      params: { month, year, title_cont },
    });

    yield put({
      type: actions.DISAPPROVE_SURVEY_SUCCESS,
      data,
      selected: disapprovedSurvey.data,
      toast: buildToast(
        'Reprovado com sucesso',
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });

    yield put({
      type: actions.FETCH_SURVEY_VERSIONS_REQUEST,
      page: 1,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        'Não foi possível reprovar.',
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* setClassrooms() {
  try {
    const dataArea = yield select((state) => state.root.dataArea);

    const { history } = yield call(
      fetchApi,
      `/${dataArea}/student_profiles/classrooms.json`
    );

    yield put({
      type: actions.SET_CLASSROOMS_SUCCESS,
      data: history,
    });
  } catch (error) {
    yield put({ type: actions.ERROR, error });
  }
}

function* setCurrentForm(action) {
  try {
    const { current } = action;

    yield put({
      type: actions.SET_CURRENT_FORM_SUCCESS,
      current,
    });
  } catch (error) {
    yield put({ type: actions.ERROR, error });
  }
}

export function* buildToastMessage(action) {
  const { message, typeToast } = action;
  const type = typeToast === 'success' ? ToastTypes.success : ToastTypes.error;

  yield put({
    type: actions.BUILD_TOAST_SUCCESS,
    toast: buildToast(message, type, toastCss(type)),
  });
}

export function* fetchEducationalStagesRequest(action) {
  try {
    const { headquarterValue } = action;

    const { data } = yield call(
      fetchApi,
      `/schools/headquarters/${headquarterValue}/educational_stages`
    );

    yield put({
      type: actions.FETCH_EDUCATIONAL_STAGES_SUCCESS,
      educationalStages: data,
    });
  } catch (error) {
    yield put({ type: actions.ERROR, error });
  }
}

export function* fetchClassroomFilterRequest(action) {
  try {
    const { headquarterValue, educationalStageValue } = action;

    const { data } = yield call(
      fetchApi,
      `/schools/headquarters/${headquarterValue}/${educationalStageValue}/classrooms`
    );

    yield put({
      type: actions.FETCH_CLASSROOM_FILTER_SUCCESS,
      classroomFilter: data,
    });
  } catch (error) {
    yield put({ type: actions.ERROR, error });
  }
}

export function* fetchSurveyVersionsRequest(action) {
  const { page } = action;

  try {
    const dataArea = yield select((state) => state.root.dataArea);
    const id = yield select((state) => state.surveys.selectedSurvey.id);

    const isPageOne = () => page === 1;

    const pageString = isPageOne() ? '' : `?page=${page}`;

    const { data, itemsCountPerPage, totalItemsCount } = yield call(
      fetchApi,
      `/${dataArea}/surveys/${id}/versions${pageString}`
    );

    yield put({
      type: actions.FETCH_SURVEY_VERSIONS_SUCCESS,
      versions: data,
      lastVersion: isPageOne() ? data[0] : null,
      pagination: { page, itemsCountPerPage, totalItemsCount },
    });
  } catch (error) {
    yield put({ type: actions.ERROR, error });
  }
}

function* surveySagas() {
  yield all([
    takeLatest(actions.ADD_SURVEY_REQUEST, addSurvey),
    takeLatest(actions.FETCH_SURVEYS_REQUEST, fetchSurveys),
    takeLatest(actions.FETCH_SURVEY_DETAILS_REQUEST, fetchSurveyDetails),
    takeLatest(actions.FETCH_SURVEY_FORM_REQUEST, fetchSurveyForm),
    takeLatest(actions.FETCH_SURVEY_QUESTIONS_REQUEST, fetchSurveyQuestions),
    takeLatest(
      actions.FETCH_SURVEY_AUTHORIZATION_QUESTIONS_REQUEST,
      fetchSurveyAuthorizationQuestions
    ),
    takeLatest(actions.FETCH_SURVEYS_DELETE_REQUEST, fetchDeleteSurvey),
    takeLatest(actions.FETCH_SURVEYS_RESEND_REQUEST, fetchResendSurvey),
    takeLatest(actions.APPROVE_SURVEY_REQUEST, approveSurveyRequest),
    takeLatest(actions.DISAPPROVE_SURVEY_REQUEST, disapproveSurveyRequest),
    takeLatest(actions.SET_CLASSROOMS_REQUEST, setClassrooms),
    takeLatest(actions.SET_CURRENT_FORM_REQUEST, setCurrentForm),
    takeLatest(actions.BUILD_TOAST, buildToastMessage),
    takeLatest(actions.UPDATE_SURVEY_REQUEST, updateSurvey),
    takeLatest(
      actions.FETCH_EDUCATIONAL_STAGES_REQUEST,
      fetchEducationalStagesRequest
    ),
    takeLatest(
      actions.FETCH_CLASSROOM_FILTER_REQUEST,
      fetchClassroomFilterRequest
    ),
    takeLatest(
      actions.FETCH_SURVEY_VERSIONS_REQUEST,
      fetchSurveyVersionsRequest
    ),
  ]);
}

export default surveySagas;
