import { StartOrCloseTicket } from 'store/messages/omniChannel/types';

import {
  AttendanceInclued,
  ChatIncluded,
  MessageIncluded,
  TicketBot,
  TraceIncluded,
} from './types';
import { formatDate } from '../formatDate';

export const normalizeStartOrCloseTicketParams = (
  ticket: TicketBot,
  included: (
    | AttendanceInclued
    | TraceIncluded
    | ChatIncluded
    | MessageIncluded
  )[]
): StartOrCloseTicket => {
  const currentChat = included?.find(
    (included) =>
      included.id === ticket.relationships.chat?.data.id &&
      included.type === 'chat'
  ) as ChatIncluded;

  const botMessage = included?.find(
    (included) =>
      currentChat.relationships?.lastBotMessage?.data.id === included.id
  ) as MessageIncluded;

  return {
    id: ticket.id,
    type: ticket.type,
    attributes: {
      status: ticket.attributes.status,
      chat: {
        id: currentChat.id,
        type: currentChat.type,
        lastBotMessage: {
          id: botMessage.id,
          type: botMessage.type,
          attributes: {
            metadata: {
              ticketBotMessage: botMessage.attributes.metadata.ticketBotMessage,
            },
            text: botMessage.attributes.text,
            sentAt: formatDate(botMessage.attributes.sentAt),
            user: {
              attributes: {
                name_initials: 'MA',
                avatar_color: '#c6e2a6',
                avatar_url: null,
                role: '',
              },
            },
          },
        },
      },
    },
  };
};
