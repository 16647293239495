import React from 'react';
import HelpCard from 'components/Help/HelpCard';
import reduxProvider from 'core/hoc/reduxProvider';

const HelpBalloonScreen = (): JSX.Element => {
  return (
    <div id="BalloonScreen" className="HelpBalloonScreen">
      <HelpCard />
    </div>
  );
};

export default reduxProvider(HelpBalloonScreen);
