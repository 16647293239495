import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import FormContainer from 'components/Form/FormContainer';
import Loader from 'components/Loader';
import ModalTitle from 'components/Modal/ModalTitle';

import ModalFormByMethod from './form/index';

import { ModalWrapper } from './style';

const HandleModalByMethod = ({
  method,
  showModal,
  toggleModal,
  isLoading,
  student,
  responsibles,
  onSubmit,
  checkoutError,
  link,
}) => {
  const toggle = useCallback(() => {
    toggleModal();
  }, [toggleModal]);
  const { t } = useTranslation(['payments']);

  return (
    <div className="HandleModalByMethod">
      {showModal && (
        <ModalWrapper
          className="ModalWrapperByMethod"
          isOpen={showModal}
          toggleModal={toggle}
          maxWidth="490px"
        >
          {isLoading && student ? (
            <Loader />
          ) : (
            <>
              <ModalTitle>
                {`${t(`modals.generate.${method}.title`)}`}
              </ModalTitle>
              {student !== undefined && student.attributes && (
                <p className="subTitle">
                  Selecione para qual responsável por{' '}
                  <strong>{student.attributes.name}</strong> deseja gerar o{' '}
                  {t(`methods.${method}`)}:
                </p>
              )}
              {checkoutError && (
                <p className="subTitle messageError">
                  {!!link &&
                  checkoutError.description.match(/cobrança.*atrasada/i) ? (
                    <strong>
                      Esta cobrança está atrasada, para gerar um novo link do{' '}
                      {t(`methods.${method}`)}, edite a data de vencimento da cobrança{' '}
                      <a className="click-here" href={link}>
                        aqui.
                      </a>
                    </strong>
                  ) : (
                    <strong>{checkoutError.description}</strong>
                  )}
                </p>
              )}
              <FormContainer
                initialAttributes={{ responsible_profile_id: '', cpf: '' }}
              >
                <ModalFormByMethod
                  method={method}
                  responsibles={responsibles}
                  toggleModal={toggle}
                  onSubmit={onSubmit}
                />
              </FormContainer>
            </>
          )}
        </ModalWrapper>
      )}
    </div>
  );
};

HandleModalByMethod.defaultProps = {
  showModal: false,
  isLoading: false,
  responsibles: [],
  link: null,
  method: 'billet',
};

HandleModalByMethod.propTypes = {
  method: PropTypes.string,
  title: PropTypes.string,
  toggleModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  student: PropTypes.object,
  checkoutError: PropTypes.object,
  responsibles: PropTypes.array,
  showModal: PropTypes.bool,
  isLoading: PropTypes.bool,
  link: PropTypes.string,
};

export default HandleModalByMethod;
