/** Action creators */
const actions = {
  ERROR: 'ERROR/registerAccount',
  CREATE_ACCOUNT_REQUEST: 'CREATE_ACCOUNT_REQUEST/registerAccount',
  CREATE_ACCOUNT_SUCCESS: 'CREATE_ACCOUNT_SUCCESS/registerAccount',
  BUILD_TOAST: 'BUILD_TOAST/registerAccount',
  BUILD_TOAST_SUCCESS: 'BUILD_TOAST_SUCCESS/registerAccount',

  sendRegister: (params, account, token) => {
    return {
      type: actions.CREATE_ACCOUNT_REQUEST,
      params,
      account,
      token,
    };
  },
  buildToast: (message, typeToast) => ({
    type: actions.BUILD_TOAST,
    message,
    typeToast,
  }),
};

export default actions;
