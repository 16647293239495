import styled from 'styled-components';
import Field from 'components/Form/Field';

export const Info = styled.p`
  color: ${({ theme }) => theme.gray2};
  font-size: 12px;
  margin-top: 12px;
`;

export const RadioButtonGroupReport = styled(Field)`
  padding: 0;

  .RadioGroup {
    flex-direction: row;
  }

  input[type='radio'] + label {
    display: flex;
    align-items: center;
    padding-left: 16px;

    @media (max-width: 900px) {
      min-width: 45vw;
    }
  }

  input[type='radio']:checked + label:before {
    all: unset;
  }

  input[type='radio'] + label:before {
    left: unset;
    top: unset;
    right: 16px;
  }

  input[type='radio'] + label:after {
    top: unset;
    left: unset;
    right: 16px;
    width: 18px;
    height: 18px;
  }

  input[type='radio']:checked + label {
    background-color: rgba(119, 61, 211, 0.1);
    color: #773dd3;
  }
`;
