import styled from 'styled-components';

export const ReportFormChartTab = styled.div`
  .Accordion {
    margin-bottom: 30px;

    .accordion__button {
      height: 72px;
      background-color: #fff;
      padding-left: 27px;

      .report-chart-title-form {
        font-family: 'Quicksand';
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.17;
        letter-spacing: normal;
        text-align: left;
        color: #000;
      }

      &:before {
        height: 10px;
        width: 10px;
        color: $gray-1;
      }
    }

    .accordion__panel {
      padding-top: 36px;
      background-color: $white;

      .accordion-chart-body {
        display: flex;
        flex-direction: column;

        .Label {
          font-family: 'Roboto';
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.14;
          letter-spacing: normal;
          text-align: left;
          color: $gray-2;
        }

        input {
          height: 40px;
          font-family: 'Roboto';
          font-size: 16px;
          font-style: normal;
          font-weight: normal;
          font-stretch: normal;
          line-height: 1;
          color: $black;
          text-align: left;
          letter-spacing: normal;
          border-radius: 6px;
        }

        input::placeholder {
          color: $gray-1;
        }

        .survey-toggle-question {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-left: 15px;

          > span {
            margin-left: 16px;
            font-family: 'Roboto';
            font-size: 16px;
            font-weight: 700;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.31;
            letter-spacing: normal;
            text-align: left;
            color: $gray-2;
          }
        }
      }
    }

    .accordion__item {
      .DropdownButton .Button {
        margin-top: -55px;

        .AgendaIcon.small {
          font-size: 24px;
        }
      }
    }
  }

  .new-chart {
    display: flex;
    justify-content: center;

    .Button {
      height: 40px;
      padding: 10px 20px;
      border-radius: 6px;
      text-align: center;
      font-family: 'Quicksand';
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
    }
  }
`;

export const NewChart = styled.div`
  display: flex;
  justify-content: center;
`;

export const Info = styled.p`
  color: ${({ theme }) => theme.gray2};
  font-size: 12px;
  margin-top: 12px;
`;
