import React from 'react';
import ContentLoader from 'react-content-loader';

const SkeletonBreadcrumb = () => {
  return (
    <ContentLoader speed={2} width={400} height={10} viewBox="0 0 400 10">
      <rect x="6" y="4" rx="4" ry="4" width="40" height="6" />
    </ContentLoader>
  );
};

export default SkeletonBreadcrumb;
