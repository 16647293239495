import styled from 'styled-components';

export const ListProductContainer = styled.div`
  overflow: hidden;
  min-height: 40vh;
  font-family: Quicksand;
  p {
    font-size: 24px;
    color: #333;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 12px;
  },
`;

export const ListProductEmpty = styled.div`
  margin-top: 50px;
  overflow: hidden;
`;

export const ListProductContent = styled.div`
  width: 390px;
  margin: 0 auto;
  text-align: center;
  img {
    margin-bottom: 12px;
  }
  span {
    display: block;
    margin: 0 auto 24px auto;
    font-style: normal;
    color: #333;
    font-weight: 500;
    font-size: 14px;
    width: 278px;
    line-height: 27px;
  }
`;
