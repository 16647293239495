import React from 'react';
import { Props } from './types';
import { Avatar, Box, Text } from '@agendaedu/ae-web-components';

export const Header = ({ student, sectionTitle }: Props) => {
  return (
    <Box display="flex">
      {student ? (
        <Box display="flex" alignItems="center">
          <Box>
            <Avatar
              avatarColor={student.attributes.avatar_color}
              avatarUrl={
                !student.attributes.should_use_name_initials_avatar &&
                student.attributes.photo_url
              }
              nameInitials={student.attributes.name_initials}
            />
          </Box>

          <Text
            display="flex"
            variant="title-bold-16"
            color="neutral.black"
            mb="0"
            ml="xs"
          >
            {student.attributes.name}
          </Text>
        </Box>
      ) : (
        <Text variant="title-bold-16" color="neutral.black" mb="0">
          {sectionTitle}
        </Text>
      )}
    </Box>
  );
};
