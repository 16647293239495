import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import withAppContext from 'core/hoc/withAppContext';
import IntegrationsMonitorActions from 'store/integrationsMonitor/actions';

import Toast from 'components/Toast';
import SkeletonIntegrationsMonitor from './components/skeletonIntegrationsMonitor';

import { Box, Card, Text } from '@agendaedu/ae-web-components';

import { RootState } from './types';
import * as S from './styles';
import HeaderInfoDetails from './HeaderInfoDetails';
import HealthDataInfo from './HealthDataInfo';
import GeneralIntegrations from './GeneralIntegrations';
import EmptyState from 'components/EmptyState';
import { INTEGRATION_MONITOR_URLS } from 'core/constants';

const IntegrationsMonitor = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation(['integrations_monitor']);

  const { isLoading, integration } = useSelector(
    (state: RootState) => state.integrationsMonitor
  );

  const { getIntegration } = IntegrationsMonitorActions;

  useEffect(() => {
    dispatch(getIntegration());
  }, [dispatch, getIntegration]);

  if (isLoading) return <SkeletonIntegrationsMonitor />;

  return (
    <S.MainContainer id="IntegrationsMonitorMainContainer">
      <Text as="h2" variant="headline-h2-bold-24" mb={11} ml={-16}>
        {t('title')}
      </Text>
      {!!integration && (
        <Box>
          <HeaderInfoDetails integration={integration} />

          {Object.keys(integration?.attributes.data_health).length > 0 ? (
            <HealthDataInfo integration={integration} />
          ) : (
            <>
              <Text
                as="h2"
                variant="headline-h2-bold-24"
                mb={32}
                mt={40}
                ml={-16}
              >
                {t('card.health_data.title_health_integration')}
              </Text>
              <Box display="flex" position="relative" ml={-16} padding={0}>
                <Card
                  display="flex"
                  flexDirection="row"
                  justifyContent="center"
                >
                  <Box>
                    <EmptyState
                      message={t('health_details_empty_state')
                        .split('\n')
                        .map((line, key) => (
                          <Text
                            as="p"
                            variant="subtitle-medium-12"
                            key={key}
                            fontWeight="500"
                          >
                            {line}
                          </Text>
                        ))}
                      imgUrl={INTEGRATION_MONITOR_URLS.emptyState}
                    />
                  </Box>
                </Card>
              </Box>
            </>
          )}

          {Object.keys(integration?.attributes.features).length > 0 && (
            <GeneralIntegrations integration={integration} />
          )}
        </Box>
      )}
      <Toast />
    </S.MainContainer>
  );
};

export default withAppContext(IntegrationsMonitor);
