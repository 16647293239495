import styled, { css } from 'styled-components';
import { ThemeProps } from 'config/themes/types';

import ModalContainer from 'components/Modal/ModalContainer';
import { ModalHeaderStyleProps } from './types';

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ModalHeader = styled(ModalContainer)<ModalHeaderStyleProps>`
  ${({ overlap, marginAuto, theme: { space } }) => css`
    display: flex;
    flex-direction: column;

    ${overlap &&
    css`
      .ReactModal__Content {
        overflow: initial !important;
        margin: auto;

        .flex-row-modal-head {
          margin-bottom: ${space.lg};
        }
      }
    `}

    ${marginAuto &&
    css`
      .ReactModal__Content {
        margin: auto;

        .flex-row-modal-head {
          align-items: center;
        }
      }
    `}
  `}
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContentWrapper = styled.div`
  ${({ theme: { colors, typography } }: ThemeProps) => css`
    display: flex;
    flex-direction: column;
    color: ${colors.neutral.gray1};
    ${typography.body.Regular16};
  `}
`;

export const ButtonWrapper = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin-top: ${space.xl};
    gap: ${space.sm};
  `}
`;
