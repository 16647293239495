const actions = {
  SET_ERROR: 'SET_ERROR/onboarding',
  FETCH_SCHOOL_ONBOARDINGS: 'FETCH_SCHOOL_ONBOARDINGS/onboarding',
  FETCH_SCHOOL_ONBOARDINGS_SUCCESS:
    'FETCH_SCHOOL_ONBOARDINGS_SUCCESS/onboarding',
  fetchSchoolOnboardings: () => ({
    type: actions.FETCH_SCHOOL_ONBOARDINGS,
  }),
};

export default actions;
