import styled from 'styled-components';

export const NewTicketBySchoolModalWrapper = styled.main`
  display: flex;
  flex-direction: column;

  .ReactModal__Content .ReactModal__Content--after-open {
    margin-left: 0;
    margin-right: 0;
    margin: auto;
  }

  #newTicketBySchool .Form .WizardForm .wizard-footer .Button {
    &::disabled {
      cursor: not-allowed !important;
    }
  }
`;
