import React from 'react';
import PropTypes from 'prop-types';

import AgendaIcon from 'components/AgendaIcon';

import * as S from './styles';

const StudentSearch = ({ search, setSearch, placeholder = 'Buscar' }) => {
  const onClickClearButton = () => {
    setSearch('');
  };

  return (
    <S.SearchContainer>
      <AgendaIcon className="search-icon" name="search" />
      <S.SearchInput
        type="text"
        placeholder={placeholder}
        onChange={(event) => setSearch(event.target.value)}
        value={search}
        data-testid="search-student-input"
      />
      {search && (
        <AgendaIcon
          name="close"
          onClick={onClickClearButton}
          className="clear-button"
        />
      )}
    </S.SearchContainer>
  );
};

StudentSearch.propTypes = {
  search: PropTypes.string.isRequired,
  setSearch: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};

export default StudentSearch;
