export function getViewport(screenWidth) {
  if (screenWidth < 992) {
    return 'mobile';
  } else if (screenWidth > 992 && screenWidth < 1200) {
    return 'tablet';
  } else {
    return 'desktop';
  }
}

export function isMobile(screenWidth) {
  return getViewport(screenWidth) === 'mobile';
}

export function isTablet(screenWidth) {
  return getViewport(screenWidth) === 'tablet';
}

export function isDesktop(screenWidth) {
  return getViewport(screenWidth) === 'desktop';
}
