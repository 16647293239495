import React, { useState, useCallback, useEffect } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { Icon } from '@agendaedu/ae-web-components';
import * as S from './styles';

import { OwnerProps, Animation } from './types';

export type SideSheetProps = OwnerProps;

export const SideRoot = ({
  children,
  isOpen = false,
  title,
  handleClose,
}: OwnerProps) => {
  const [animation, setAnimation] = useState<Animation>('open');

  const FADE_OUT_DURATION = 250;

  const handleOnClose = useCallback(() => {
    setAnimation('close');
    setTimeout(handleClose, FADE_OUT_DURATION);
  }, [handleClose]);

  useEffect(() => {
    if (isOpen) setAnimation('open');
  }, [isOpen, setAnimation]);

  return (
    isOpen && (
      <S.SideSheetWrapper
        id="side-details-wrapper"
        animation={animation}
        type="fade"
      >
        <OutsideClickHandler onOutsideClick={handleOnClose}>
          <S.SideSheetContainer
            id="side-details"
            animation={animation}
            type="slide"
          >
            <S.HeaderWrapper>
              <S.Title>{title}</S.Title>
              <S.CloseButton onClick={handleOnClose}>
                <Icon name="multiply" />
              </S.CloseButton>
            </S.HeaderWrapper>
            {children}
          </S.SideSheetContainer>
        </OutsideClickHandler>
      </S.SideSheetWrapper>
    )
  );
};
