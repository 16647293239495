import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Alert, Avatar } from '@agendaedu/ae-web-components';

import omniChannelActions from 'store/messages/omniChannel/actions';
import { OmniChannelState, SchoolUser } from 'store/messages/omniChannel/types';

import tabifyForWizard from 'core/hoc/tabifyForWizard';
import withAppContext from 'core/hoc/withAppContext';
import withFormContext from 'core/hoc/withFormContext';

import * as S from './styles';

import FormSelector from 'components/Form/Selector';

import { SchoolProfileStepSkeleton } from './skeleton';

import { SchoolProfileStepProps, SearchOptionsProps } from './types';

const SchoolProfileStep = ({
  formContext: {
    form: {
      classroom_ids: classroomsIds,
      school_user_ids: schoolUserIds,
      kind,
    },
    changeMeta,
    updateAttribute,
  },
}: SchoolProfileStepProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['messages']);

  const isTicketChannel = kind === 'ticket';

  const { fetchSchoolUsersRequest } = omniChannelActions;

  const { schoolUsers } = useSelector(
    (state: OmniChannelState) => state.omniChannel
  );

  const updateSelectorColumns = ({ selectedValues }) => {
    const nextStepDisabled = selectedValues.length ? false : true;

    changeMeta('nextStepDisabled', nextStepDisabled);
    updateAttribute('school_user_ids', selectedValues);
  };

  const searchOptions = ({ options, searchTerm }: SearchOptionsProps) => {
    const filteredOptions = options.filter((option) =>
      option.data.attributes.name
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    );

    return filteredOptions;
  };

  const renderOptionComponent = (option: SchoolUser): React.ReactNode => {
    if (option) {
      const { avatar_url, name, name_initials, avatar_color } =
        option.data?.attributes;
      return (
        <S.ItemWrapper>
          <Avatar
            size="sm"
            avatarColor={avatar_color}
            avatarUrl={avatar_url}
            nameInitials={name_initials}
          />
          <S.TitleOption variant="body-medium-14">{name}</S.TitleOption>
        </S.ItemWrapper>
      );
    }
  };

  useEffect(() => {
    schoolUserIds.length
      ? changeMeta('nextStepDisabled', false)
      : changeMeta('nextStepDisabled', true);
  }, [schoolUserIds, changeMeta]);

  useEffect(() => {
    dispatch(fetchSchoolUsersRequest(classroomsIds));
  }, [fetchSchoolUsersRequest, dispatch, classroomsIds]);

  return (
    <S.SchoolProfileStepWrapper>
      {schoolUsers ? (
        <S.RowWrapper>
          <S.TitleWrapper>
            <S.StepTitle variant="title-bold-20">
              {t('omni_channel.forms.message_channel.steps.third')}
            </S.StepTitle>

            <S.StepSubtitle lineHeight="lg" variant="label-regular-16">
              {t(
                'omni_channel.forms.message_channel.steps_subtitle.school_profile'
              )}
            </S.StepSubtitle>
          </S.TitleWrapper>

          {isTicketChannel && (
            <S.AlertInformationWrapper>
              <Alert variant="informative">
                {t(
                  'omni_channel.forms.ticket_channel.select_professionals_step_alert'
                )}
              </Alert>
            </S.AlertInformationWrapper>
          )}

          <FormSelector
            newSearchField
            searchPlaceholder={t(
              'omni_channel.forms.message_channel.fields.search_placeholder_text'
            )}
            search={searchOptions}
            options={schoolUsers}
            selectedValues={schoolUserIds}
            optionComponent={renderOptionComponent}
            onChange={updateSelectorColumns}
            optionAccessor={(optionAccessor: SchoolUser) =>
              optionAccessor.data.id
            }
            onSelectBehavior="highlight"
            selectAllLabel={t(
              'omni_channel.forms.message_channel.fields.select_all'
            )}
            counterLabel={(length: number) =>
              length === 1
                ? t(
                    'omni_channel.forms.message_channel.create.selector_counter_text'
                  )
                : t(
                    'omni_channel.forms.message_channel.create.selector_counter_plural_text'
                  )
            }
          />
        </S.RowWrapper>
      ) : (
        <SchoolProfileStepSkeleton />
      )}
    </S.SchoolProfileStepWrapper>
  );
};

export default tabifyForWizard({
  title: '3 Profissionais',
})(withAppContext(withFormContext(SchoolProfileStep)));
