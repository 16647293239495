import React from 'react';

import ToastContext from 'core/contexts/Toast';

import getHocName from 'core/utils/getHocName';

const withToastmessage = (WrappedComponent) => {
  const WithToastMessage = (props) => {
    return (
      <ToastContext.Consumer>
        {(actions) => <WrappedComponent {...props} toastActions={actions} />}
      </ToastContext.Consumer>
    );
  };

  WithToastMessage.displayName = getHocName(
    'WithToastMessage',
    WrappedComponent
  );

  return WithToastMessage;
};

export default withToastmessage;
