import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as S from './styles';

import withAppContext from 'core/hoc/withAppContext';

import omniChannelActions from 'store/messages/omniChannel/actions';
import { OmniChannelState } from 'store/messages/omniChannel/types';
import actionFilters from 'store/filters/actions';

import { Button, Select, Text } from '@agendaedu/ae-web-components';
import Accordion from 'components/Accordion';
import { SideModal } from 'components/Messages/OmniChannel/Modals/SideModal';

import { FilterStatesProps } from 'store/filters/types';
import { FormValues, Source, SourceOption } from './types';

const TicketFilterModal = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['messages']);

  const tBase = useCallback(
    (key: string, params?: Record<string, string | number>) =>
      t(`omni_channel.modals.filter_chat.${key}`, params),
    [t]
  );

  const { chatFilters, showFilterTicketModal } = useSelector(
    (state: OmniChannelState) => state.omniChannel
  );

  const { headquarters, educationalStages, classrooms } = useSelector(
    (state: FilterStatesProps) => state.filters
  );

  const [formValues, setFormValues] = useState<FormValues>({
    source: chatFilters.source,
    headquarter: chatFilters.headquarterId,
    educationalStage: chatFilters.educationalStageId,
    classroom: chatFilters.classroomId,
  });

  const disabledButton = !Object.values(formValues).some(
    (value) => value !== null
  );

  const sourceOptions: SourceOption[] = [
    {
      label: tBase('source.placeholder_text'),
      value: null,
    },
    {
      label: tBase('source.options.school'),
      value: 'school',
    },
    {
      label: tBase('source.options.responsible'),
      value: 'responsible',
    },
    {
      label: tBase('source.options.student'),
      value: 'student',
    },
  ];

  const { setChatFilters, toggleFilterTicketModal } = omniChannelActions;

  const { fetchEducationalStagesRequest, fetchClassRoomsRequest } =
    actionFilters;

  const handleToggleTicketFilter = useCallback(() => {
    dispatch(toggleFilterTicketModal());
  }, [dispatch, toggleFilterTicketModal]);

  const handleFetchEducationalStages = useCallback(
    (headquarterId) => {
      dispatch(fetchEducationalStagesRequest(headquarterId));
    },
    [dispatch, fetchEducationalStagesRequest]
  );

  const handleFetchClassroomFilter = useCallback(
    (headquarterId, educationalStageId) => {
      dispatch(fetchClassRoomsRequest(headquarterId, educationalStageId));
    },
    [dispatch, fetchClassRoomsRequest]
  );

  const handleSetFilters = useCallback(() => {
    dispatch(
      setChatFilters({
        ...chatFilters,
        headquarterId: formValues.headquarter,
        educationalStageId: formValues.educationalStage,
        classroomId: formValues.classroom,
        source: formValues.source,
      })
    );
    handleToggleTicketFilter();
  }, [chatFilters, formValues, dispatch, handleToggleTicketFilter]);

  const handleClearFilter = useCallback(() => {
    dispatch(
      setChatFilters({
        ...chatFilters,
        headquarterId: null,
        educationalStageId: null,
        classroomId: null,
        source: null,
      })
    );
    handleToggleTicketFilter();
  }, [chatFilters, formValues, dispatch, handleToggleTicketFilter]);

  const hasHeadquarters = () => {
    return headquarters && headquarters?.length > 0;
  };

  const hasEducationalStages = () => {
    return (
      hasHeadquarters() &&
      formValues.headquarter &&
      educationalStages?.length > 0
    );
  };

  const hasClassrooms = () => {
    return (
      hasEducationalStages() &&
      formValues.educationalStage &&
      classrooms?.length > 0
    );
  };

  useEffect(() => {
    setFormValues({
      headquarter: chatFilters.headquarterId,
      educationalStage: chatFilters.educationalStageId,
      classroom: chatFilters.classroomId,
      source: chatFilters.source,
    });
  }, [chatFilters]);

  return (
    <SideModal.Root
      isOpen={showFilterTicketModal}
      title={tBase('title')}
      handleClose={handleToggleTicketFilter}
    >
      <S.FieldWrapper>
        <Accordion
          header={
            <Text variant="title-bold-20" color="neutral.black" mb={0}>
              {tBase('source_filter_text')}
            </Text>
          }
          body={
            <S.AccordionWrapper>
              <Select
                fullWidth
                label={tBase('source.label_text')}
                placeholder={tBase('source.placeholder_text')}
                value={formValues.source}
                options={sourceOptions}
                onChange={(event) =>
                  setFormValues({
                    ...formValues,
                    source: event.value as Source,
                  })
                }
              />
            </S.AccordionWrapper>
          }
          expanded="handouts"
          allowZeroExpanded
        />
        <Accordion
          header={
            <Text variant="title-bold-20" color="neutral.black" mb={0}>
              {tBase('general_filter_title')}
            </Text>
          }
          body={
            <S.AccordionWrapper>
              <Select
                fullWidth
                label={tBase('headquarter.label_text')}
                placeholder={tBase('headquarter.placeholder_text')}
                value={formValues.headquarter}
                options={headquarters}
                onChange={(event) => {
                  setFormValues({
                    ...formValues,
                    headquarter: event.value,
                    educationalStage: null,
                    classroom: null,
                  });
                  handleFetchEducationalStages(event.value);
                }}
              />
              <Select
                fullWidth
                label={tBase('educational.label_text')}
                placeholder={tBase('educational.placeholder_text')}
                value={formValues.educationalStage}
                options={educationalStages}
                disabled={!hasEducationalStages()}
                onChange={(event) => {
                  setFormValues({
                    ...formValues,
                    educationalStage: event.value,
                    classroom: null,
                  });
                  handleFetchClassroomFilter(
                    formValues.headquarter,
                    event.value
                  );
                }}
              />
              <Select
                fullWidth
                label={tBase('classroom.label_text')}
                placeholder={tBase('classroom.placeholder_text')}
                value={formValues.classroom}
                options={classrooms}
                disabled={!hasClassrooms()}
                onChange={(event) =>
                  setFormValues({ ...formValues, classroom: event.value })
                }
              />
            </S.AccordionWrapper>
          }
          expanded="handouts"
          allowZeroExpanded
        />
      </S.FieldWrapper>
      <SideModal.Actions>
        <Button disabled={disabledButton} onClick={handleSetFilters}>
          {tBase('button_submit')}
        </Button>

        <Button variant="secondary" onClick={handleClearFilter}>
          {tBase('button_clear')}
        </Button>
      </SideModal.Actions>
    </SideModal.Root>
  );
};

export default withAppContext(TicketFilterModal);
