import React from 'react';
import ContentLoader from 'react-content-loader';

import * as S from './styles';

const StudentListSkeleton = () => {
  return (
    <S.MainContainer>
      <ContentLoader
        width="100%"
        height="100%"
        speed={2}
        viewBox="0 0 400 800"
        ariaLabel="Carregando..."
      >
        <rect x="0" y="10" rx="6" ry="6" width="120" height="30" />
        <rect x="220" y="0" rx="6" ry="6" width="180" height="50" />
        <rect x="0" y="66" rx="6" ry="6" width="400" height="800" />
      </ContentLoader>
    </S.MainContainer>
  );
};

export default StudentListSkeleton;
