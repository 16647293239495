import React from 'react';

import LabeledContainer from 'components/LabeledContainer';
import BillSummary from 'components/Payments/Recurrent/BillSummary';
import withFormContext from 'core/hoc/withFormContext';

import './style.scss';

const RecurrentSummary = ({ formContext: { form, formMeta }, action }) => {
  const recurrentItems = form.recurrentItems;
  const allowesPaymentMethod = form.allowed_payment_method;
  const { billetTax, pixTax } = formMeta;

  const recurrentItemValue = ({ discount_kind, discount_value, price }) => {
    if (discount_kind === 'percent') {
      return price - price * (discount_value / 100);
    }

    return price - discount_value;
  };

  const subTotalCalculator = () => {
    const reducer = (accumulator, currentValue) =>
      accumulator + recurrentItemValue(currentValue);
    return recurrentItems.reduce(reducer, 0);
  };

  return (
    <div className="recurrent-summary">
      <LabeledContainer title="Resumo da cobrança">
        <BillSummary
          items={recurrentItems}
          allowedPaymentMethod={allowesPaymentMethod}
          price={subTotalCalculator()}
          billetTax={billetTax}
          pixTax={pixTax}
        />
      </LabeledContainer>
    </div>
  );
};

export default withFormContext(RecurrentSummary);
