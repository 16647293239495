import React from 'react';

import './style.scss';

function MessagesDisabledChatInput(props) {
  return (
    <div className="MessagesDisabledChatInput">
      <p>
        { props.inactiveChatMessage }
      </p>
    </div>
  );
}

export default MessagesDisabledChatInput;
