import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

const GOOGLE_SDK_URL = 'https://apis.google.com/js/api.js?onload=onApiLoad';

const ALLOWED_CONTENT_TYPES = [
  'application/pdf',
  'text/plain',
  'image/jpeg',
  'image/png',
  'image/gif',
  'image/jpg',
  'video/mp4',
  'video/webm',
  'video/ogv',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.google-apps.document',
  'application/vnd.ms-powerpoint',
  'application/mspowerpoint',
  'application/ms-powerpoint',
  'application/mspowerpnt',
  'application/vnd-mspowerpoint',
  'application/powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.google-apps.video',
  'application/vnd.google-apps.presentation',
  'application/vnd.google-apps.folder',
];

const insertGoogleScript = (documentRoot, id, handleClientLoad) => {
  // Check if script already present
  if (!documentRoot.getElementById(id)) {
    const firstScriptTag = documentRoot.getElementsByTagName('script')[0];
    const scriptTag = documentRoot.createElement('script');
    scriptTag.async = 'async';
    scriptTag.defer = 'defer';
    scriptTag.id = id;
    scriptTag.src = GOOGLE_SDK_URL;
    scriptTag.onload = handleClientLoad;
    firstScriptTag?.parentNode?.insertBefore(scriptTag, firstScriptTag);
  }
};

const GooglePicker = ({
  clientId,
  scope,
  locale,
  multiselect,
  mimeTypes,
  children,
  onChange,
  onAuthenticate,
  onAuthFailed,
}) => {
  const [oAuthToken, setOAuthToken] = useState(null);
  const [isSignedIn, setIsSignedIn] = useState(false);

  const pickerCallback = useCallback(
    (oauthToken, data) => {
      if (data.action === 'picked') {
        onChange(
          oauthToken,
          data.docs.map((doc) => Object.assign(doc, { fromGoogleDrive: true }))
        );
      }
    },
    [onChange]
  );

  const createPicker = (oauthToken) => {
    const { google } = window;

    const viewDocs = new google.picker.View(google.picker.ViewId.DOCS);
    const viewVideos = new google.picker.View(google.picker.ViewId.DOCS_VIDEOS);
    const pickerBuilder = new google.picker.PickerBuilder();

    if (multiselect) {
      pickerBuilder.enableFeature(google.picker.Feature.MULTISELECT_ENABLED);
    }

    if (mimeTypes) {
      viewDocs.setMimeTypes(mimeTypes.join(','));
      viewVideos.setMimeTypes(mimeTypes.join(','));
    }

    pickerBuilder.addView(viewDocs);
    pickerBuilder.addView(viewVideos);
    pickerBuilder.setLocale(locale);
    pickerBuilder.setOAuthToken(oauthToken);
    pickerBuilder.setCallback((data) => pickerCallback(oauthToken, data));

    const picker = pickerBuilder.build();

    picker.setVisible(true);
  };

  const handleSignin = useCallback(
    (authResult) => {
      if (authResult && !authResult.error) {
        const authResponse = authResult.getAuthResponse();

        setIsSignedIn(true);
        setOAuthToken(authResponse.access_token);

        onAuthenticate(authResponse);
        createPicker(authResponse.access_token);
      } else {
        onAuthFailed(authResult);
      }
    },
    [onAuthenticate, setOAuthToken, setIsSignedIn]
  );

  const signIn = () => {
    if (!window.gapi) {
      return;
    }

    const GoogleAuth = window.gapi.auth2.getAuthInstance();
    const params = {
      client_id: clientId,
      scope: scope.join(' '),
    };

    if (GoogleAuth) {
      GoogleAuth.signIn(params).then(handleSignin, onAuthFailed);
    } else {
      window.gapi.auth2.authorize(params, handleSignin);
    }
  };

  const handleScriptLoad = () => {
    window.gapi.load('auth2');
    window.gapi.load('picker');
  };

  useEffect(() => {
    insertGoogleScript(document, 'google-script', handleScriptLoad);
  }, []);

  const onChoose = () => {
    if (isSignedIn && oAuthToken) {
      createPicker(oAuthToken);
    } else {
      signIn();
    }
  };

  return (
    <div className="google-picker" onClick={() => onChoose()}>
      {children}
    </div>
  );
};

GooglePicker.defaultProps = {
  scope: ['https://www.googleapis.com/auth/drive.readonly'],
  locale: 'pt-br',
  multiselect: true,
  mimeTypes: ALLOWED_CONTENT_TYPES,
  onChange: () => {},
  onAuthenticate: () => {},
  onAuthFailed: () => {},
};

GooglePicker.propTypes = {
  clientId: PropTypes.string.isRequired,
  scope: PropTypes.array,
  locale: PropTypes.string,
  multiselect: PropTypes.bool,
  mimeTypes: PropTypes.array,
  children: PropTypes.node.isRequired,
  onChange: PropTypes.func,
  onAuthenticate: PropTypes.func,
  onAuthFailed: PropTypes.func,
};

export default GooglePicker;
