import React, { FC, useEffect, useState } from 'react';
import {
  Tag,
  Icon,
  FlexGrid,
  FlexGridItem,
  Box,
  Button,
  Tooltip,
  Text,
} from '@agendaedu/ae-web-components';

import { trackEvent } from '../../../../config/amplitude';

import * as S from './styles';
import { Props } from './types';
import FormContainer from 'components/Form/FormContainer';
import withFormContext from 'core/hoc/withFormContext';
import Field from 'components/Form/Field';
import { useTranslation } from 'react-i18next';
import AccordionDetails from '../AccordionDetails';

const variants = {
  Pago: 'positive',
  Pendente: 'informative',
  Atrasado: 'negative',
  Cancelado: 'negative',
  Processando: 'informative',
};

const STATUS_PAYMENT = {
  Pago: 'Pago',
  Pendente: 'Pendente',
  Atrasado: 'Atrasado',
  AguardandoBoleto: 'Aguardando Boleto',
  Processando: 'Processando',
  Cancelado: 'Cancelado',
};

const CardBilling: FC<Props> = ({
  id,
  content,
  onClickBillet,
  ...otherProps
}) => {
  const { t } = useTranslation(['billing_panel']);

  const { attributes, details } = content;
  const {
    status,
    due_date,
    gross_total,
    net_total,
    billet_bar_code,
    tax_total,
    nfse_url,
    billet_price,
    title,
  } = attributes;

  const handleOpenNfse = (): void => {
    const win = window.open(nfse_url, '_blank');
    win.focus();
  };

  // futuramente deve ser um componete centralizado
  const maskCurrency = (value: number) => {
    return value.toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      style: 'currency',
      currency: 'BRL',
    });
  };

  const renderBodyTedails = (data): JSX.Element => {
    if (!data) return <></>;
    return (
      <>
        {data?.map((sub, index) => {
          const {
            title,
            gross_total,
            amount_billed,
            net_total,
            price_per_unit,
            price_per_unit_with_discount,
          } = sub.attributes;

          return (
            <>
              <S.WrapperContent>
                <FlexGrid gutter="sm">
                  <FlexGridItem cols={{ desktopLG: 10 }} filled>
                    <Box display="flex" alignItems="center">
                      <S.Informations>
                        <S.Subtitle>{t('card.details.service')}</S.Subtitle>
                        <div>
                          <S.Title>{title}</S.Title>
                        </div>
                      </S.Informations>
                    </Box>
                  </FlexGridItem>

                  <FlexGridItem cols={{ desktopLG: 2 }} filled>
                    <Box display="flex" alignItems="center">
                      <S.Informations>
                        <S.Subtitle>{t('card.details.value')} </S.Subtitle>
                        <S.TextLineThrough>
                          {maskCurrency(Number(gross_total))}
                        </S.TextLineThrough>
                        <S.TextTotal>
                          {maskCurrency(Number(net_total))}
                        </S.TextTotal>
                      </S.Informations>
                    </Box>
                  </FlexGridItem>
                </FlexGrid>

                <FlexGrid>
                  <FlexGridItem cols={{ desktopLG: 4 }} filled>
                    <Box display="flex" alignItems="center">
                      <S.Informations>
                        <S.Subtitle>{t('card.total_students')}</S.Subtitle>
                        <div>
                          <Icon name="user-group" />
                          <S.Title>{amount_billed}</S.Title>
                        </div>
                      </S.Informations>
                    </Box>
                  </FlexGridItem>

                  <FlexGridItem cols={{ desktopLG: 2 }} filled>
                    <Box display="flex" alignItems="center">
                      <S.Informations>
                        <S.Subtitle>{t('card.pay_per_student')}</S.Subtitle>
                        <S.TextLineThrough>
                          {maskCurrency(Number(price_per_unit))}
                        </S.TextLineThrough>
                        <div>
                          <Icon name="money-cycle" />
                          <S.Title>
                            {maskCurrency(Number(price_per_unit_with_discount))}
                          </S.Title>
                        </div>
                      </S.Informations>
                    </Box>
                  </FlexGridItem>
                </FlexGrid>
              </S.WrapperContent>

              {index % 2 === 0 && <S.SeparatorLine />}
            </>
          );
        })}
      </>
    );
  };

  return (
    <>
      <S.TitleCard>{title}</S.TitleCard>

      <FlexGrid mb={10} gutter="sm" {...otherProps}>
        <FlexGridItem cols={{ desktopLG: 4 }} filled>
          <Box display="flex" alignItems="center">
            <S.Informations>
              <S.Subtitle>{t('card.status')}</S.Subtitle>
              <div>
                <Tag name={status} variant={variants[status]} />
              </div>
            </S.Informations>
          </Box>
        </FlexGridItem>

        <FlexGridItem cols={{ desktopLG: 4 }} filled>
          <Box display="flex" alignItems="center">
            <S.Informations>
              <S.Subtitle>{t('card.payment_over')} </S.Subtitle>
              <div>
                <Icon name="calendar" />
                <S.Title>{due_date}</S.Title>
              </div>
            </S.Informations>
          </Box>
        </FlexGridItem>

        <FlexGridItem cols={{ desktopLG: 4 }} filled>
          <Box display="flex" alignItems="center">
            <S.Informations>
              <S.Subtitle>{t('card.total_payment')} </S.Subtitle>
              <div>
                <S.TextBold>{maskCurrency(Number(billet_price))}</S.TextBold>
              </div>
            </S.Informations>
          </Box>
        </FlexGridItem>
      </FlexGrid>

      <FlexGrid gutter="md" mb={32}>
        <FlexGridItem cols={{ desktopLG: 4 }} filled>
          <Box display="flex" alignItems="center">
            <S.Informations>
              <S.Subtitle>{t('card.payment_amount')}</S.Subtitle>
              <div>
                <Icon name="tag" />
                <S.TextLineThrough>
                  {maskCurrency(Number(gross_total))}
                </S.TextLineThrough>
              </div>

              <Text
                as="p"
                variant="title-bold-14"
                textAlign="center"
                color="neutral.gray1"
                fontWeight={500}
              >
                {maskCurrency(Number(net_total))}
              </Text>
            </S.Informations>
          </Box>
        </FlexGridItem>

        <FlexGridItem cols={{ desktopLG: 2 }} filled>
          <Box display="flex" alignItems="center">
            <S.Informations>
              <S.Subtitle>{t('card.tax_total')}</S.Subtitle>
              <div>
                <Icon name="tag" />
                <S.Title>{maskCurrency(Number(tax_total))}</S.Title>
              </div>
            </S.Informations>
          </Box>
        </FlexGridItem>
      </FlexGrid>

      <FlexGrid gutter="md" mb={32}>
        <FlexGridItem cols={{ desktopLG: 12 }} filled>
          <Box display="flex" alignItems="center" pr={12}>
            <AccordionDetails title={t('card.details.title_billing_items')}>
              {renderBodyTedails(details)}
            </AccordionDetails>
          </Box>
        </FlexGridItem>
      </FlexGrid>

      <FlexGrid gutter="md" mb={status == STATUS_PAYMENT.Pago && 18}>
        <FlexGridItem cols={{ desktopLG: 3 }} filled>
          <S.ActionBox display="flex" alignItems="center">
            <Tooltip
              content={t('card.donwload_billet')}
              align="top-end"
              elementRef={
                <Button
                  variant="secondary"
                  isOnlyIcon={true}
                  size="lg"
                  icon="income"
                  onClick={() => {
                    trackEvent('billing-client-billet-download');
                    onClickBillet(id);
                  }}
                />
              }
            />
            <Tooltip
              content={`${
                !nfse_url
                  ? t('card.donwload_nfse_indisponible')
                  : t('card.donwload_nfse')
              }`}
              align="top-end"
              elementRef={
                <Button
                  disabled={!nfse_url}
                  variant="secondary"
                  isOnlyIcon={true}
                  size="lg"
                  icon="file-download"
                  onClick={() => {
                    trackEvent('billing-client-nfse-download');
                    handleOpenNfse();
                  }}
                />
              }
            />
          </S.ActionBox>
        </FlexGridItem>

        {status != STATUS_PAYMENT.Pago && (
          <FlexGridItem cols={{ desktopLG: 9 }} filled>
            <FormContainer initialAttributes={{ barcode: billet_bar_code }}>
              <Field
                icon="document-text"
                fullWidth={true}
                label="Código do boleto"
                type="copy"
                placeholder="Clique aqui para copiar o código"
                attributeName="barcode"
                beforeCopy={() => {
                  trackEvent('billing-client-billet-copy');
                }}
              />
            </FormContainer>
          </FlexGridItem>
        )}
      </FlexGrid>

      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
        width="80%"
      >
        <Text as="h5" variant="label-medium-12" justifyContent="space-around">
          {t('instructions_billet')
            .split('\n')
            .map((line, key) => (
              <Text
                ml={key == 1 ? 8 : 0}
                as="p"
                variant="label-medium-12"
                key={key}
              >
                {line}
              </Text>
            ))}
        </Text>
      </Box>
    </>
  );
};

export default withFormContext(CardBilling);
