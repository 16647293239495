import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';

import Loader from 'components/Loader';
import ModalSurveyAction from 'pages/surveys/ModalSurveyAction';

import SurveyActions from 'store/surveys/actions';

import SurveyHeader from './SurveyHeader';
import SurveyInfo from './SurveyInfo';
import HistorySection from 'components/HistorySection';
import SurveyActionButtons from './SurveyActionButtons';

import '../../style.scss';

const InformationsTab = ({ surveyId }) => {
  const dispatch = useDispatch();
  const { fetchSurveyDetails, fetchSurveyVersionsRequest } = SurveyActions;

  const [showModal, setShowModal] = useState(false);
  const [modalVariation, setModalVariation] = useState('');

  useEffect(() => {
    dispatch(fetchSurveyDetails(surveyId));
  }, [dispatch, fetchSurveyDetails]);

  const isLoading = useSelector((state) => state.surveys.isLoading);
  const selectedSurvey = useSelector((state) => state.surveys.selectedSurvey);
  const { dataArea } = useSelector((state) => state.root);

  const formatSurveyDatetime = (datetime, format = 'DD [de] MMM[,] YYYY') =>
    moment(datetime, 'DD/MM/YYYY').format(format);

  const areInvalidDates = () => {
    const startDate = formatSurveyDatetime(
      selectedSurvey.attributes.startDatetime,
      'YYYY-MM-DD'
    );
    const finalDate = formatSurveyDatetime(
      selectedSurvey.attributes.limitDatetime,
      'YYYY-MM-DD'
    );
    return Date.parse(startDate) > Date.parse(finalDate);
  };

  const toggleModal = (modalVariation) => {
    setShowModal(!showModal);
    setModalVariation(modalVariation);
  };

  const versionsSelector = (state) => state.surveys.versions;
  const lastVersionSelector = (state) => state.surveys.lastVersion;
  const paginationSelector = (state) => state.surveys.surveysPagination;

  return (
    <Loader isLoading={isLoading}>
      {!!Object.keys(selectedSurvey?.attributes || {}).length && (
        <div>
          <SurveyHeader
            survey={selectedSurvey}
            areInvalidDates={areInvalidDates()}
          />
          <SurveyInfo
            startDatetime={selectedSurvey.attributes.startDatetime}
            limitDatetime={selectedSurvey.attributes.limitDatetime}
            personaType={selectedSurvey.attributes.personaType}
            description={selectedSurvey.attributes.description}
          />
          <HistorySection
            versionsSelector={versionsSelector}
            paginationSelector={paginationSelector}
            lastVersionSelector={lastVersionSelector}
            requestFunction={fetchSurveyVersionsRequest}
          />
          <SurveyActionButtons
            surveyId={surveyId}
            status={selectedSurvey.attributes.status}
            areInvalidDates={areInvalidDates()}
            toggleModal={toggleModal}
            history={history}
          />
          <ModalSurveyAction
            showModal={showModal}
            toggleModal={toggleModal}
            variation={modalVariation}
            survey={selectedSurvey}
            redirectTo={`/${dataArea}/surveys`}
          />
        </div>
      )}
    </Loader>
  );
};

export default InformationsTab;
