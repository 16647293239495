import createModuleReducer from '../storeConfig/createModuleReducer';
import actions from './actions';
import { HomeState, ResultsActionDefault, SchoolsResponse } from './types';

export const INITIAL_STATE: HomeState = {
  isLoadingSchools: false,
  isChangingSchool: false,
  selectedSchool: null,
  formMeta: {
    schoolUserSchools: [],
  },
  loadDynamicApps: false,
  appsDynamicModule: [],
  trainings: [],
  banners: [],
};

export const fetchDynamicModuleSuccess = (
  state: HomeState,
  action: { data: HomeState['appsDynamicModule'] }
) => {
  const { data } = action;

  return {
    ...state,
    loadDynamicApps: false,
    appsDynamicModule: data?.length > 0 ? data : [],
  };
};

export const fetchDynamicModulLoad = (state: HomeState) => ({
  ...state,
  loadDynamicApps: true,
});

export const fetchSchoolUserSchools = (state: HomeState) => ({
  ...state,
  isLoadingSchools: true,
});

export const fetchSchoolUserSchoolsSuccess = (
  state: HomeState,
  action: SchoolsResponse
) => {
  return {
    ...state,
    isLoadingSchools: false,
    selectedSchool: action.data.attributes,
    formMeta: {
      ...state.formMeta,
      schoolUserSchools: action.meta.other_schools,
    },
  };
};

export const changeSchool = (state: HomeState) => ({
  ...state,
  isChangingSchool: true,
});

export const changeSchoolSuccess = (state: HomeState) => ({
  ...state,
  isChangingSchool: false,
});

export const fetchTrainingsSuccess = (
  state: HomeState,
  action: { data: HomeState['trainings'] }
) => ({
  ...state,
  trainings: action.data,
});

export const fetchBannersSuccess = (
  state: HomeState,
  action: { data: HomeState['banners'] }
) => ({
  ...state,
  banners: action.data,
});

export const handleError = (
  state: HomeState,
  action: ResultsActionDefault
) => ({
  ...state,
  isLoading: false,
  isChangingSchool: false,
  error: action.error,
});

const HANDLERS = {
  [actions.FETCH_DYNAMIC_MODULES]: fetchDynamicModulLoad,
  [actions.FETCH_DYNAMIC_MODULES_SUCCESS]: fetchDynamicModuleSuccess,
  [actions.FETCH_TRAININGS_SUCCESS]: fetchTrainingsSuccess,
  [actions.FETCH_BANNERS_SUCCESS]: fetchBannersSuccess,
  [actions.FETCH_SCHOOL_USER_SCHOOLS]: fetchSchoolUserSchools,
  [actions.FETCH_SCHOOL_USER_SCHOOLS_SUCCESS]: fetchSchoolUserSchoolsSuccess,
  [actions.CHANGE_SCHOOL]: changeSchool,
  [actions.CHANGE_SCHOOL_SUCCESS]: changeSchoolSuccess,
  [actions.ERROR]: handleError,
};

const home = createModuleReducer(INITIAL_STATE, HANDLERS);

export default home;
