import styled, { css } from 'styled-components';

import Button from 'components/Button';
import AgendaIcon from 'components/AgendaIcon';

import { ThemeProps } from 'config/themes/types';

export const Container = styled.div`
  ${({ theme: { space, colors, border } }: ThemeProps) => css`
    display: flex;
    align-items: center;
    padding: ${space.xs2};
    background-color: ${colors.neutral.white};
    border: ${border.width.sm} ${border.style.solid} ${colors.neutral.gray4};
    border-radius: ${border.radius.sm};
    height: 40px;
  `}
`;

export const Input = styled.input`
  ${({ theme: { colors, space } }: ThemeProps) => css`
    width: 100%;
    background-color: transparent;
    border: none;
    color: ${colors.neutral.gray1};
    margin-right: ${space.xs2};

    &::placeholder {
      color: ${colors.neutral.gray2};
    }

    &:focus,
    &:hover {
      border: none;
    }
  `}
`;

export const ButtonClear = styled(Button)`
  ${({ theme: { space, colors, border } }: ThemeProps) => css`
    display: flex;
    align-items: center;
    padding: ${space.xs3};
    background-color: ${colors.brand.primary.op10};
    border-color: ${colors.brand.primary.op10};
    border-radius: ${border.radius.sm};

    &:active,
    &:hover {
      background-color: ${colors.brand.primary.op20} !important;
      border-color: ${colors.brand.primary.op10} !important;
    }
  `}
`;

export const IconClear = styled(AgendaIcon)`
  ${({ theme: { colors } }: ThemeProps) => css`
    color: ${colors.neutral.gray2};
  `}
`;
