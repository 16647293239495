import React, { useState } from 'react';

import Field from 'components/Form/Field';
import DropdownButton from 'components/DropdownButton';
import Currency from 'components/Currency';

import { NegotiationItemProps } from 'components/Payments/Recurrent/NegotiationForm/FormSections/NegotiationBills/types';
import {
  InformationForm,
  PaymentForm,
} from 'components/Payments/Recurrent/NegotiationForm/types';

import * as S from './styles';
import { addZeroBeforeNumber } from 'core/utils/addZeroBeforeNumber';

const NegotiationItem = ({
  removeItem,
  onChange,
  itemValues,
  index,
  disabled = false,
  minValueSelected,
  withDiscount,
}: NegotiationItemProps) => {
  const [showDiscount, setShowDiscount] = useState(
    itemValues.discount_value !== 0
  );

  const totalDiscount = () => {
    const { discount_kind, discount_value, price_value } = itemValues;

    if (discount_kind === 'percent') {
      return price_value * (discount_value / 100);
    }

    return discount_value;
  };

  const removeDiscount = () => {
    setShowDiscount(false);

    const newValues = itemValues;
    newValues.discount_value = 0;
    newValues.discount_kind = 'percent';

    onChange(index, newValues);
  };

  const updateValues = (
    attributeName: string,
    form: InformationForm & PaymentForm
  ) => {
    const newValues = itemValues;

    const negotiationOptions = {
      price_value: () => {
        if (index === 0) newValues[attributeName] = form[attributeName];
        else newValues[attributeName] = form[0][attributeName];
      },
      price_kind: () => {
        newValues[attributeName] = form[attributeName];
        newValues.price_value = 0;
      },
      discount_kind: () => {
        newValues[attributeName] = form[attributeName];
        newValues.discount_value = 0;
      },
      discount_value: () => {
        newValues[attributeName] = form[attributeName];
      },
      default: () => {
        newValues[attributeName] = form;
      },
    };

    (negotiationOptions[attributeName] || negotiationOptions.default)();

    onChange(index, newValues);
  };

  const actionItems = () => {
    const actions = [
      {
        text: 'Remover item',
        onClick: () => removeItem(index),
      },
    ];

    if (withDiscount) {
      if (showDiscount) {
        actions.push({
          text: 'Remover desconto',
          onClick: () => removeDiscount(),
        });
      } else {
        actions.push({
          text: 'Adicionar desconto',
          onClick: () => setShowDiscount(true),
        });
      }
    }

    return actions;
  };

  return (
    <S.NegotiationItem>
      {index ? (
        <>
          <div className="item-category">
            <div className="Field col-xs-12 col-md-12 vertical">
              <p className="bill-title">
                {`Cobrança ${addZeroBeforeNumber(index + 1)}`}
              </p>
            </div>
          </div>
          <div className="item-actions">
            <Field
              attributeName="price_value"
              type="currency"
              label="Valor"
              controlled
              defaultValue={itemValues.price_value}
              onChange={(formState) => updateValues('price_value', formState)}
              disabled={disabled}
            />
            {!disabled && (
              <DropdownButton
                dropdownItems={actionItems()}
                icon="dots-vertical"
                variation="secondary white"
                disabled={disabled}
              />
            )}
          </div>
          {showDiscount && (
            <div className="item-discount">
              <Field
                type="textWithSelect"
                attributeName="discount_value"
                selectAttributeName="discount_kind"
                label="Valor do desconto"
                controlled
                onChange={(formState) =>
                  updateValues('discount_value', formState)
                }
                onChangeSelect={(formState) =>
                  updateValues('discount_kind', formState)
                }
                defaultValue={itemValues.discount_value}
                value={itemValues.discount_value}
                defaultSelectValue={itemValues.discount_kind}
                withIcon={false}
                orientation="horizontal"
                disabled={disabled}
              />
              <div className="discount-value">
                - <Currency value={totalDiscount()} />
              </div>
            </div>
          )}
        </>
      ) : (
        <div className="item-discount">
          <Field
            type="textWithSelect"
            attributeName="price_value"
            selectAttributeName="price_kind"
            label="Primeira cobrança"
            controlled
            onChange={(formState) => updateValues('price_value', formState)}
            onChangeSelect={(formState) =>
              updateValues('price_kind', formState)
            }
            defaultValue={itemValues.price_value}
            value={itemValues.price_value}
            defaultSelectValue={itemValues.price_kind}
            withIcon={false}
            orientation="horizontal"
            disabled={disabled}
          />
          <div className="discount-value">
            subtotal da cobrança{' '}
            <Currency
              value={
                itemValues.price_kind === 'money'
                  ? itemValues.price_value
                  : ((minValueSelected / 100) * itemValues.price_value) / 100
              }
            />
          </div>
        </div>
      )}
    </S.NegotiationItem>
  );
};

export default NegotiationItem;
