import React from 'react';
import ContentLoader from 'react-content-loader';
import { DefaultThemeProps, theme } from '@agendaedu/ae-web-components';

import * as S from './styles';

import { MessageBalloonSkeletonProps } from './types';

const MessageBalloonSkeleton = ({
  variantType,
  testId,
}: MessageBalloonSkeletonProps) => {
  const defaultTheme: DefaultThemeProps = theme;

  const {
    colors: { neutral },
  } = defaultTheme;

  const messageBalloonType = {
    received: (testId: string) => (
      <S.ContentLoaderWrapper variantType={variantType} data-testid={testId}>
        <ContentLoader
          speed={2}
          width={240}
          height={96}
          backgroundColor={neutral.gray5}
          foregroundColor={neutral.gray4}
          ariaLabel="Carregando..."
        >
          <circle cx="16" cy="47" r="16" />
          <rect x="40" y="0" rx="12" ry="12" width="200" height="64" />
          <rect x="40" y="72" rx="4" ry="4" width="64" height="16" />
        </ContentLoader>
      </S.ContentLoaderWrapper>
    ),
    sent: (testId: string) => (
      <S.ContentLoaderWrapper variantType={variantType} data-testid={testId}>
        <ContentLoader
          speed={2}
          width={176}
          height={96}
          backgroundColor={neutral.gray5}
          foregroundColor={neutral.gray4}
          ariaLabel="Carregando..."
        >
          <rect x="0" y="0" rx="12" ry="12" width="176" height="64" />
          <rect x="112" y="72" rx="4" ry="4" width="64" height="16" />
        </ContentLoader>
      </S.ContentLoaderWrapper>
    ),
  };

  return (
    <S.MessageBalloonSkeletonWrapper>
      {messageBalloonType[variantType](testId)}
    </S.MessageBalloonSkeletonWrapper>
  );
};

export default MessageBalloonSkeleton;
