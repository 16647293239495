import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import withAppContext from 'core/hoc/withAppContext';
import { trackEvent } from 'config/amplitude';

import {
  theme,
  DefaultThemeProps,
  Box,
  Text,
  FlexGrid,
  FlexGridItem,
  Tag,
  Icon,
  Card,
  Button,
} from '@agendaedu/ae-web-components';

import { HealthDataInfoProps } from './types';
import { IconProps } from '@agendaedu/ae-web-components/dist/components/display/Icon/types';

const HealthDataInfo: React.FC<HealthDataInfoProps> = ({
  integration,
  history,
}) => {
  const attributes = integration?.attributes;
  const { t } = useTranslation(['integrations_monitor']);
  const defaultThemeProps: DefaultThemeProps = theme;
  const baseURLDetails = `/schools/erp_integrations/monitor/${integration.id}?entity=`;
  const [maxHeighCards, setMaxHeighCards] = useState(0);

  const {
    student_profiles,
    responsible_profiles,
    school_users,
    disciplines,
    classrooms,
    headquarters,
    notifications,
    classroom_schedules,
  } = attributes.data_health;

  const {
    colors: {
      neutral,
      brand: {
        primary: { default: primaryColor },
      },
    },
  } = defaultThemeProps;

  const calculateHeightColumn = (): void => {
    const propsLeftCols = [
      'student_profiles',
      'responsible_profiles',
      'school_users',
      'notifications',
    ];

    const propsRightCols = [
      'disciplines',
      'classrooms',
      'headquarters',
      'classroom_schedules',
    ];
    const totaItemsLeftCols = propsLeftCols.filter(
      (prop) => Object.hasOwn(attributes.data_health, prop) && prop
    );

    const totaItemsRightCols = propsRightCols.filter(
      (prop) => Object.hasOwn(attributes.data_health, prop) && prop
    );

    const largestColumn = Math.max(
      totaItemsLeftCols.length,
      totaItemsRightCols.length
    );

    switch (largestColumn) {
      case 1:
      case 2:
      case 3:
        setMaxHeighCards(260);
        break;
      case 4:
        setMaxHeighCards(330);
        break;
      default:
        setMaxHeighCards(430);
        break;
    }
  };

  useEffect(() => {
    calculateHeightColumn();

    const cardSizeHandler = () => {
      const cardLeft = document.getElementById('card-left')?.offsetHeight;
      const cardRifht = document.getElementById('card-right')?.offsetHeight;

      const maxHeight = Math.max(cardLeft, cardRifht);
      setMaxHeighCards(maxHeight);
    };

    window.addEventListener('resize', cardSizeHandler);

    return () => {
      window.removeEventListener('resize', cardSizeHandler);
    };
  }, []);

  const checkProps = (variable): boolean => {
    if (variable === null || variable === undefined) return false;

    return true;
  };

  const openInconsistency = (entity: string): void => {
    trackEvent(`integrations_monitor_access_inconsistency_${entity}`);
    history.push(`${baseURLDetails}${entity}`);
  };

  const buttonInconsistency = (entity: string): JSX.Element => (
    <Button
      variant="secondary"
      isOnlyIcon
      size="md"
      icon="show"
      color={primaryColor}
      onClick={() => openInconsistency(entity)}
    />
  );

  const boxTitleInconsistency = (
    iconName: IconProps['name'],
    in18Text: string
  ): JSX.Element => (
    <Box
      display="flex"
      alignItems="center"
      flexDirection="row"
      flexWrap="wrap"
      mt={-3}
    >
      <Icon name={iconName} />
      <Text
        as="p"
        ml={8}
        mt={9}
        variant="subtitle-medium-16"
        fontWeight={500}
        color={neutral.gray1}
      >
        {t(in18Text)}
      </Text>
    </Box>
  );

  const tagInconsistency = (entity: number): JSX.Element => (
    <Tag
      mr={16}
      enableEllipsis
      name={
        entity > 0
          ? t('card.health_data.error_inconsistencies', { quantity: entity })
          : t('card.health_data.success_inconsistencies')
      }
      variant={entity > 0 ? 'negative' : 'positive'}
    />
  );

  return (
    <>
      <Text as="h2" variant="headline-h2-bold-24" mb={32} mt={40} ml={-16}>
        {t('card.health_data.title_health_integration')}
      </Text>
      <FlexGrid>
        <FlexGrid gutter="lg">
          <FlexGridItem cols={{ desktopLG: 6 }} filled>
            <Card mt={32} padding={0} height={maxHeighCards} maxHeight={430}>
              <Box
                id="card-left"
                width="100%"
                p="24px"
                pl={-12}
                minHeight={maxHeighCards}
                maxHeight="90vh"
              >
                <FlexGridItem cols={{ desktopLG: 10 }} filled>
                  <Box mb={32}>
                    <Text
                      as="h2"
                      mr={24}
                      mb={0}
                      variant="title-bold-20"
                      fontWeight={700}
                      color={neutral.black}
                    >
                      {t('card.health_data.title_registration_data')}
                    </Text>
                  </Box>
                </FlexGridItem>
                {checkProps(student_profiles) && (
                  <Box
                    display="flex"
                    width="100%"
                    flexDirection="row"
                    justifyContent="space-between"
                    flexWrap="wrap"
                    mt={16}
                  >
                    {boxTitleInconsistency(
                      'user-profile',
                      'card.health_data.label_student'
                    )}

                    <Box
                      display="flex"
                      alignItems="center"
                      flexDirection="row"
                      flexWrap="wrap"
                    >
                      {tagInconsistency(student_profiles)}
                      {buttonInconsistency('student_profiles')}
                    </Box>
                  </Box>
                )}
                {checkProps(responsible_profiles) && (
                  <Box
                    display="flex"
                    width="100%"
                    flexDirection="row"
                    justifyContent="space-between"
                    flexWrap="wrap"
                    mt={16}
                  >
                    {boxTitleInconsistency(
                      'user-group',
                      'card.health_data.label_responsible'
                    )}

                    <Box display="flex" alignItems="center">
                      {tagInconsistency(responsible_profiles)}
                      {buttonInconsistency('responsible_profiles')}
                    </Box>
                  </Box>
                )}
                {checkProps(school_users) && (
                  <Box
                    display="flex"
                    width="100%"
                    flexDirection="row"
                    justifyContent="space-between"
                    flexWrap="wrap"
                    mt={16}
                  >
                    {boxTitleInconsistency(
                      'id-card',
                      'card.health_data.label_employee'
                    )}

                    <Box display="flex" alignItems="center">
                      {tagInconsistency(school_users)}
                      {buttonInconsistency('school_users')}
                    </Box>
                  </Box>
                )}
                {checkProps(notifications) && (
                  <Box
                    display="flex"
                    width="100%"
                    flexDirection="row"
                    justifyContent="space-between"
                    flexWrap="wrap"
                    mt={16}
                  >
                    {boxTitleInconsistency(
                      'notification',
                      'card.health_data.label_notification'
                    )}

                    <Box display="flex" alignItems="center">
                      {tagInconsistency(notifications)}
                      {buttonInconsistency('notifications')}
                    </Box>
                  </Box>
                )}
              </Box>
            </Card>
          </FlexGridItem>
          <FlexGridItem cols={{ desktopLG: 6 }} filled>
            <Card mt={32} padding={0} height={maxHeighCards} maxHeight={430}>
              <Box
                id="card-right"
                width="100%"
                p="24px"
                pl={-12}
                minHeight={maxHeighCards}
                maxHeight="90vh"
              >
                <FlexGridItem cols={{ desktopLG: 10 }} filled>
                  <Box mb={32}>
                    <Text
                      as="h2"
                      mr={24}
                      mb={0}
                      variant="title-bold-20"
                      fontWeight={700}
                      color={neutral.black}
                    >
                      {t('card.health_data.title_structural_data')}
                    </Text>
                  </Box>
                </FlexGridItem>
                {checkProps(disciplines) && (
                  <Box
                    display="flex"
                    width="100%"
                    flexDirection="row"
                    justifyContent="space-between"
                    flexWrap="wrap"
                    mt={16}
                  >
                    {boxTitleInconsistency(
                      'books',
                      'card.health_data.label_subjects'
                    )}
                    <Box
                      display="flex"
                      alignItems="center"
                      flexDirection="row"
                      flexWrap="wrap"
                    >
                      {tagInconsistency(disciplines)}
                      {buttonInconsistency('disciplines')}
                    </Box>
                  </Box>
                )}
                {checkProps(classrooms) && (
                  <Box
                    display="flex"
                    width="100%"
                    flexDirection="row"
                    justifyContent="space-between"
                    flexWrap="wrap"
                    mt={16}
                  >
                    {boxTitleInconsistency(
                      'flow',
                      'card.health_data.label_class'
                    )}

                    <Box display="flex" alignItems="center">
                      {tagInconsistency(classrooms)}
                      {buttonInconsistency('classrooms')}
                    </Box>
                  </Box>
                )}
                {checkProps(headquarters) && (
                  <Box
                    display="flex"
                    width="100%"
                    flexDirection="row"
                    justifyContent="space-between"
                    flexWrap="wrap"
                    mt={16}
                  >
                    {boxTitleInconsistency(
                      'home',
                      'card.health_data.label_unit'
                    )}

                    <Box display="flex" alignItems="center">
                      {tagInconsistency(headquarters)}
                      {buttonInconsistency('headquarters')}
                    </Box>
                  </Box>
                )}
                {checkProps(classroom_schedules) && (
                  <Box
                    display="flex"
                    width="100%"
                    flexDirection="row"
                    justifyContent="space-between"
                    flexWrap="wrap"
                    mt={16}
                  >
                    {boxTitleInconsistency(
                      'calendar',
                      'card.health_data.label_classroom_schedules'
                    )}

                    <Box display="flex" alignItems="center">
                      {tagInconsistency(classroom_schedules)}
                      {buttonInconsistency('classroom_schedules')}
                    </Box>
                  </Box>
                )}
              </Box>
            </Card>
          </FlexGridItem>
        </FlexGrid>
      </FlexGrid>
    </>
  );
};

export default withRouter(withAppContext(HealthDataInfo));
