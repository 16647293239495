import React, { useCallback, useContext } from 'react';
import _ from 'lodash';
import { Box, Button, DefaultThemeProps } from '@agendaedu/ae-web-components';

import {
  CustomCard,
  isEmptyCustomValues,
} from 'pages/diarySectionsV3/Sections/components/ShowSectionModal/Sections';
import {
  CustomSectionTypes,
  OnChangeValues,
} from 'pages/diarySectionsV3/Sections/components/ShowSectionModal/Sections/Custom/types';
import { DiarySectionsFormContext } from 'core/contexts/DiaryForm/SectionsForm';
import { DiaryFormStructure, DiaryState } from 'store/dailySummaries/types';
import { useTheme } from 'styled-components';
import { useSelector } from 'react-redux';
import { defaultAnswersAttributesValues } from 'core/contexts/DiaryForm/constants';
import { Props } from './types';

export const CustomSectionGroup = ({ student }: Props) => {
  const { border, colors } = useTheme() as DefaultThemeProps;

  const {
    form: { values },
    currentSectionId,
    updateStudentDiary,
  } = useContext(DiarySectionsFormContext);

  const {
    diarySections: { activesSections },
  } = useSelector((state: DiaryState) => state.dailySummaries);

  const answersAttributes = _.cloneDeep(
    values.studentsDiaries[student.id].answers_attributes
  );

  const updateValues = useCallback(
    () =>
      updateStudentDiary(student.id, { answers_attributes: answersAttributes }),
    [answersAttributes, student.id, updateStudentDiary]
  );

  const activesCustomSections = activesSections.filter(
    (section) => section.custom && section.id === currentSectionId
  );

  const addSectionCard = useCallback(
    (
      sectionId: string,
      answersAttributesList: DiaryFormStructure['answers_attributes_values'][]
    ) => {
      const newAnswersAttributesList = answersAttributesList.map(
        (answerAttributesItem) =>
          defaultAnswersAttributesValues(answerAttributesItem.diary_question_id)
      );

      answersAttributes[sectionId].push(newAnswersAttributesList);
      updateValues();
    },
    [answersAttributes, updateValues]
  );

  const removeSectionCard = (sectionId: string, index: number) => {
    answersAttributes[sectionId].splice(index, 1);

    updateValues();
  };

  const handleChanges = (
    sectionId: string,
    sectionIndex: number,
    callbackValues: OnChangeValues,
    answerIndex: number
  ) => {
    const answersAttributesValues =
      answersAttributes[sectionId][sectionIndex][answerIndex];

    const handleChangesAction = (kind: CustomSectionTypes) =>
      ({
        text: () => {
          answersAttributesValues.answer_text = callbackValues.value;
        },
        select: () => {
          answersAttributesValues.diary_answer_option_id = callbackValues.value;
        },
      }[kind]);

    handleChangesAction(callbackValues.type)();

    updateValues();
  };

  const clearAnswersSection = (sectionId: string, sectionIndex: number) => {
    const currentValue = answersAttributes[sectionId][sectionIndex];

    currentValue.forEach((question) => {
      question.key = defaultAnswersAttributesValues(
        question.diary_question_id
      ).key;
      question.answer_text = '';
      question.diary_answer_option_id = '';
    });

    updateValues();
  };

  const isEmptyAnswers = (sectionId: string, sectionIndex: number) => {
    const currentValue = answersAttributes[sectionId][sectionIndex];

    const isEmpty = isEmptyCustomValues(currentValue);

    return isEmpty;
  };

  return (
    <Box display="flex" flexDirection="column" gap="lg">
      {activesCustomSections.map(({ id, name, questionsAttributes }) =>
        values.studentsDiaries[student.id].answers_attributes[id]?.map(
          (answersAttributesList, answersAttributesIndex, answersArray) => {
            const showRenderAddSectionButton =
              answersAttributesIndex === answersArray.length - 1;
            const showRenderDeleteSectionButton = answersAttributesIndex === 0;

            return (
              <React.Fragment key={`${id}-${answersAttributesIndex}`}>
                <Box display="flex" width="100%">
                  <CustomCard
                    section={{ name, questionsAttributes }}
                    answersAttributesList={answersAttributesList}
                    student={student}
                    onChange={(callbackValues, questionAnswerIndex) => {
                      handleChanges(
                        id,
                        answersAttributesIndex,
                        callbackValues,
                        questionAnswerIndex
                      );
                    }}
                  />

                  <Box
                    ml="sm"
                    p="xs"
                    gap="xs"
                    display="flex"
                    flexDirection="column"
                    height="fit-content"
                    borderRadius={border.radius.md}
                    border={`${border.width.sm} ${border.style.solid} ${colors.neutral.gray4}`}
                  >
                    {showRenderAddSectionButton && (
                      <Button
                        testId="add-section"
                        isOnlyIcon
                        icon="plus"
                        size="sm"
                        variant="secondary"
                        onClick={() =>
                          addSectionCard(id, answersAttributesList)
                        }
                      />
                    )}

                    <Button
                      testId="clear-values"
                      isOnlyIcon
                      icon="eraser"
                      size="sm"
                      variant="secondary"
                      disabled={isEmptyAnswers(id, answersAttributesIndex)}
                      onClick={() =>
                        clearAnswersSection(id, answersAttributesIndex)
                      }
                    />

                    <Button
                      testId="remove-section"
                      isOnlyIcon
                      icon="trash-bin"
                      size="sm"
                      variant="secondary"
                      contextVariant="alert"
                      disabled={showRenderDeleteSectionButton}
                      onClick={() =>
                        removeSectionCard(id, answersAttributesIndex)
                      }
                    />
                  </Box>
                </Box>
              </React.Fragment>
            );
          }
        )
      )}
    </Box>
  );
};
