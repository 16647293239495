import React, { useEffect, useCallback } from 'react';
import {connect, useDispatch} from 'react-redux';

import actionIntegrations from 'store/integrations/actions';
import IntegrationFiltersForm from 'pages/employees/integration/filters/form';
import normalizeParams from 'pages/employees/integration/filters/normalizeParams';

import PageContainer from 'components/PageContainer';
import Loader from 'components/Loader/index';
import Toast from 'components/Toast';

import './style.scss';

const IntegrationFilters = (props) => {
  const dispatch = useDispatch();

  const {
    data,
    canChange,
    initialValues,
    loading,
    sent,
    provider,
  } = props;

  const {
    fetchIntegrationFiltersRequest,
    updateIntegrationFiltersRequest,
    setHeadquarters,
    setCourses,
    setSchoolTerms,
  } = actionIntegrations;

  useEffect(() => {
    dispatch(fetchIntegrationFiltersRequest());
  }, []);

  const onSubmit = useCallback((values) => {
    dispatch(updateIntegrationFiltersRequest(normalizeParams(values.form.data)));
  }, [dispatch, updateIntegrationFiltersRequest]);

  const handleHeadquarterSelect = useCallback((key) => {
    dispatch(setHeadquarters(key.selectedValues));
  }, [dispatch, setHeadquarters]);

  const handleCourseSelect = useCallback((key) => {
    dispatch(setCourses(key.selectedValues));
  }, [dispatch, setCourses]);

  const handleSchoolTermSelect = useCallback((key) => {
    dispatch(setSchoolTerms(key.selectedValues));
  }, [dispatch, setSchoolTerms]);

  const handlers = {
    headquarter: handleHeadquarterSelect,
    course: handleCourseSelect,
    schoolTerm: handleSchoolTermSelect,
  };

  return (
    <div className="IntegrationFilters">
      <Loader isLoading={loading}>
        <PageContainer title="Configurar integração">
          <IntegrationFiltersForm
            filters={data}
            initialValues={initialValues}
            sent={sent}
            canChange={canChange}
            handleSubmit={onSubmit}
            handlers={handlers}
            provider={provider}
          />
        </PageContainer>
        <Toast />
      </Loader>
    </div>
  );
};

const mapStateToProps = state => ({
  data: state.integrations,
  loading: state.integrations.loading,
  sent: state.integrations.sent,
  canChange: state.integrations.canChange,
  initialValues: state.integrations.initialValues,
});

export default connect(mapStateToProps)(IntegrationFilters);
