import { fetchNegotiationsType } from './types';

const actions = {
  FETCH_REQUEST_NEGOTIATION_INITIAL_DATA:
    'FETCH_REQUEST_NEGOTIATION_INITIAL_DATA/negotiations',
  FETCH_SUCCESS_NEGOTIATION_INITIAL_DATA:
    'FETCH_SUCCESS_NEGOTIATION_INITIAL_DATA/negotiations',
  FETCH_REQUEST_NEGOTIATIONS: 'FETCH_REQUEST_NEGOTIATIONS/negotiations',
  FETCH_SUCCESS_NEGOTIATIONS: 'FETCH_SUCCESS_NEGOTIATIONS/negotiations',
  POST_NEGOTIATION_REQUEST: 'POST_NEGOTIATION_REQUEST/negotiations',
  POST_NEGOTIATION_SUCCESS: 'POST_NEGOTIATION_SUCCESS/negotiations',
  SET_CURRENT_PLAN_ID: 'SET_CURRENT_PLAN_ID/negotiations',
  FETCH_ERROR: 'FETCH_ERROR/negotiations',
  SET_SELECTED_RECURRENT_BILLS: 'SET_SELECTED_RECURRENT_BILLS/negotiations',
  SET_SELECT_ALL_RECURRENT_BILLS: 'SET_SELECT_ALL_RECURRENT_BILLS/negotiations',
  CLEAR_SELECTED_BILLS: 'CLEAR_SELECTED_BILLS/negotiations',
  SET_SELECT_NEGOTIATION: 'SET_SELECT_NEGOTIATION/negotiations',
  fetchNegotiationInitialData: () => ({
    type: actions.FETCH_REQUEST_NEGOTIATION_INITIAL_DATA,
  }),
  postNegotiation: (params) => ({
    type: actions.POST_NEGOTIATION_REQUEST,
    params,
  }),
  setCurrentPlanId: (currentPlanId) => ({
    type: actions.SET_CURRENT_PLAN_ID,
    currentPlanId,
  }),
  fetchNegotiations: (filters: fetchNegotiationsType) => ({
    type: actions.FETCH_REQUEST_NEGOTIATIONS,
    filters,
  }),

  setSelectedRecurrentBills: (id) => ({
    type: actions.SET_SELECTED_RECURRENT_BILLS,
    id,
  }),

  setSelectAllRecurrentBills: (isDebugUser, recurrentBills) => ({
    type: actions.SET_SELECT_ALL_RECURRENT_BILLS,
    isDebugUser,
    recurrentBills,
  }),
  setSelectNegotiation: (id) => ({
    type: actions.SET_SELECT_NEGOTIATION,
    id,
  }),

  clearSelectedBills: () => ({
    type: actions.CLEAR_SELECTED_BILLS,
  }),
};

export default actions;
