import styled, { css } from 'styled-components';
import { ThemeProps } from 'config/themes/types';

export const DiaperWrapper = styled.div`
  ${({ theme: { border, breakpoints, colors, space } }: ThemeProps) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: ${breakpoints.tabletMD} / 2;
    gap: ${space.xl};
    padding: ${space.lg};
    border-radius: ${border.radius.md};
    border: ${border.style.solid} ${border.width.sm} ${colors.neutral.gray4};

    @media screen and (max-width: ${breakpoints.tabletMD}) {
      min-width: 200px;
      width: 100%;
    }

    @media screen and (max-width: ${breakpoints.mobileSM}) {
      min-width: 100px;
      width: 100%;
    }
  `}
`;

export const ButtonWrapper = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    align-items: center;
    gap: ${space.sm};
  `}
`;
