import React from 'react';
import ContentLoader from 'react-content-loader';

const SkeletonList = () => {
  return (
    <ContentLoader
      width="100%"
      height="100%"
      viewBox="0 0 600 700"
      ariaLabel="Carregando..."
    >
      <rect x="26" y="143" rx="8" ry="8" width="128" height="17" />
      <rect x="27" y="37" rx="8" ry="8" width="30" height="31" />
      <rect x="66" y="56" rx="4" ry="4" width="116" height="11" />
      <rect x="184" y="56" rx="4" ry="4" width="104" height="11" />
      <rect x="27" y="83" rx="8" ry="8" width="536" height="27" />
      <rect x="26" y="190" rx="8" ry="8" width="554" height="40" />
      <rect x="27" y="240" rx="4" ry="4" width="80" height="14" />
      <rect x="25" y="265" rx="8" ry="8" width="268" height="91" />
      <rect x="303" y="265" rx="8" ry="8" width="268" height="91" />
      <rect x="25" y="405" rx="8" ry="8" width="553" height="91" />
      <rect x="25" y="372" rx="4" ry="4" width="116" height="14" />
      <rect x="28" y="127" rx="4" ry="4" width="72" height="11" />
    </ContentLoader>
  );
};

export default SkeletonList;
