import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import ActionButton from 'components/Messages/TS/Animations/ActionButton';
import Avatar from 'components/Avatar';

import withAppContext from 'core/hoc/withAppContext';
import { isUUID } from 'core/utils/isUuid';

import MessagesBalloon from '../Balloon';
import MessagesInfo from '../Info';

import * as S from './styles';
class MessagesReceivedMessage extends PureComponent {
  constructor(props) {
    super(props);

    this.wrapperRef = React.createRef();
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
  }

  static propTypes = {
    appContext: PropTypes.shape({
      primaryColor: PropTypes.string.isRequired,
      policies: PropTypes.object.isRequired,
      dataArea: PropTypes.string,
    }).isRequired,
    approveMessage: PropTypes.func,
    message: PropTypes.shape({
      attributes: PropTypes.shape({
        type: PropTypes.string,
        canUpdate: PropTypes.bool,
        user: PropTypes.object.isRequired,
        status: PropTypes.string,
        authorizedBy: PropTypes.string,
        authorizedAt: PropTypes.string,
        approvalStatus: PropTypes.string,
        sentAt: PropTypes.string.isRequired,
      }),
      id: PropTypes.string,
    }).isRequired,
    setRef: PropTypes.func.isRequired,
    showActionMenu: PropTypes.bool,
    showApprovalInfo: PropTypes.bool,
    unapproveMessage: PropTypes.func,
    deleteMessage: PropTypes.func,
    editMessage: PropTypes.func,
    replyMessage: PropTypes.func,
    anchorMessage: PropTypes.func,
    featureFlagRedirectMessage: PropTypes.bool,
    redirectMessage: PropTypes.func,
  };

  static defaultProps = {
    setRef: undefined,
    showApprovalInfo: false,
  };

  approvalStatusOptions = ['manually_approved', 'approved'];

  state = {
    isDeletingMessage: false,
    isHovered: false,
    isOpen: false,
  };

  deleteMessage = async (message) => {
    this.setState({ isDeletingMessage: true });
    await this.props.deleteMessage(message);
    this.setState({ isDeletingMessage: false });
  };

  editMessage = (message) => {
    this.props.editMessage(message);
  };

  isDeletedUser = (id) => {
    return isUUID(id);
  };

  replyMessage = (message) => {
    this.props.replyMessage(message);
  };

  redirectMessage = (message) => {
    this.props.redirectMessage(message);
  };

  onMouseEnter = () => {
    this.setState({ isHovered: true });
  };

  onMouseLeave = () => {
    this.setState({ isHovered: false });
  };

  onToggle = () => {
    this.setState({ isOpen: true });
  };

  handleOutsideClick(event) {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.setState({ isOpen: false });
    }
  }

  componentDidMount() {
    document.addEventListener('click', this.handleOutsideClick);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleOutsideClick);
  }

  render() {
    const {
      appContext,
      message,
      approveMessage,
      unapproveMessage,
      anchorMessage,
      showActionMenu,
      showApprovalInfo,
      featureFlagRedirectMessage,
    } = this.props;

    const { dataArea } = appContext;

    const {
      attributes: { user, approvalStatus, status, canUpdate },
    } = message;

    const { isHovered, isOpen } = this.state;

    const hasAccessToActions =
      dataArea === 'schools' || dataArea === 'teachers';

    const showActionsItems =
      (showActionMenu ||
        this.approvalStatusOptions.includes(approvalStatus) ||
        canUpdate) &&
      hasAccessToActions;

    const canReplyMessage = this.approvalStatusOptions.includes(approvalStatus);

    const canRedirectMessage =
      featureFlagRedirectMessage &&
      this.approvalStatusOptions.includes(approvalStatus) &&
      status !== 'deleted' &&
      user.attributes.role !== '' &&
      !this.isDeletedUser(user.id);

    const canDeleteMessage = showActionMenu;

    return (
      <S.ReceivedMessageWrapper
        ref={this.wrapperRef}
        id={`message-${message.id}`}
        onMouseLeave={this.onMouseLeave}
      >
        <Avatar user={user} />

        <S.MessageBalloonWrapper
          onClick={this.onToggle}
          onMouseEnter={this.onMouseEnter}
        >
          <MessagesBalloon
            message={message}
            approveMessage={approveMessage}
            unapproveMessage={unapproveMessage}
            anchorMessage={anchorMessage}
          />

          {!!showActionsItems && (
            <S.ActionsWrapper isHovered={isHovered} isOpen={isOpen}>
              {canReplyMessage && (
                <ActionButton
                  name="reply"
                  handleOnClick={() => this.replyMessage(message)}
                />
              )}

              {canRedirectMessage && (
                <ActionButton
                  name="forward"
                  handleOnClick={() => this.redirectMessage(message)}
                />
              )}

              {canUpdate && (
                <ActionButton
                  name="pencil"
                  handleOnClick={() => this.editMessage(message)}
                />
              )}

              {canDeleteMessage && (
                <ActionButton name="trash" handleOnClick={this.deleteMessage} />
              )}
            </S.ActionsWrapper>
          )}

          <MessagesInfo message={message} showApprovalInfo={showApprovalInfo} />
        </S.MessageBalloonWrapper>
      </S.ReceivedMessageWrapper>
    );
  }
}

export default withAppContext(MessagesReceivedMessage);
