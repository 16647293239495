import React from 'react';
import PropTypes from 'prop-types';

import withFormContext, { formPropTypes } from 'core/hoc/withFormContext';
import './style.scss';

import FormValidationErrors from 'components/Form/ValidationErrors';

const NumberField = ({
  value,
  onChange,
  disabled,
  placeholder,
  attributeName,
  formContext: { form, changeAttribute, hasErrorOnAttribute },
}) => {
  const getOnChange = React.useCallback(() => {
    if (onChange) {
      return changeAttribute(attributeName, (formState) => onChange(formState));
    } else {
      return changeAttribute(attributeName);
    }
  }, [onChange, changeAttribute]);

  const hasError = hasErrorOnAttribute(attributeName);

  return (
    <div className="TextField NumberField">
      <input
        type="number"
        pattern="[0-9]*"
        className="form-control"
        onChange={getOnChange()}
        value={value}
        disabled={disabled}
        placeholder={placeholder}
      />
      {hasError && <FormValidationErrors attributeName={attributeName} />}
    </div>
  );
};

NumberField.defaultProps = {
  disabled: false,
  placeholder: '',
};

NumberField.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  ...formPropTypes,
};

export default withFormContext(NumberField);
