import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import {
  buildToast,
  toastCss,
  ToastTypes,
} from 'store/middlewares/toast/actions';
import { fetchApi, postApi } from 'core/utils/api';

import actions from './actions';
import { ChangeSchoolRequestProps } from './types';

function* fetchDynamicModuleSaga() {
  try {
    const dataArea = yield select((state) => state.root.dataArea);

    const { data } = yield call(fetchApi, `/${dataArea}/dynamic_modules`);

    yield put({
      type: actions.FETCH_DYNAMIC_MODULES_SUCCESS,
      data,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        'Não foi possível obter a listagem de apps.',
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* fetchSchoolUserSchoolsRequest() {
  try {
    const { data, meta } = yield call(
      fetchApi,
      '/schools/school_users/schools'
    );

    yield put({
      type: actions.FETCH_SCHOOL_USER_SCHOOLS_SUCCESS,
      data,
      meta,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        'Não foi possível listar as escolas vinculadas.',
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* changeSchoolRequest({ schoolId }: ChangeSchoolRequestProps) {
  try {
    yield call(postApi, `/schools/school_users/change_school`, {
      school_id: schoolId,
    });

    yield put({
      type: actions.CHANGE_SCHOOL_SUCCESS,
    });

    window.location = '/' as string & Location;
  } catch (error) {
    let errorMessage = 'Não foi possível trocar de escola.';
    switch (error.status) {
      case 422:
        errorMessage = error.data.errors[0].detail;
        break;
      case 401:
        errorMessage = 'Você acessou uma escola cancelada.';
        break;
    }

    yield put({
      type: actions.ERROR,
      toast: buildToast(errorMessage, ToastTypes.error),
      error,
    });
  }
}

function* fetchTrainingsSaga() {
  try {
    const { data } = yield call(fetchApi, `/schools/training.json`);

    yield put({
      type: actions.FETCH_TRAININGS_SUCCESS,
      data,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        'Não foi possível obter a listagem de treinamentos.',
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* fetchBannersSaga() {
  try {
    const { data } = yield call(fetchApi, `/schools/banners.json`);

    yield put({
      type: actions.FETCH_BANNERS_SUCCESS,
      data,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        'Não foi possível obter a listagem de banners.',
        ToastTypes.error,
        toastCss(ToastTypes.e)
      ),
    });
  }
}

function* homeSagas() {
  yield all([
    takeLatest(actions.FETCH_DYNAMIC_MODULES, fetchDynamicModuleSaga),
    takeLatest(
      actions.FETCH_SCHOOL_USER_SCHOOLS,
      fetchSchoolUserSchoolsRequest
    ),
    takeLatest(actions.CHANGE_SCHOOL, changeSchoolRequest),
    takeLatest(actions.FETCH_TRAININGS, fetchTrainingsSaga),
    takeLatest(actions.FETCH_BANNERS, fetchBannersSaga),
  ]);
}

export default homeSagas;
