import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'react-dates/initialize';
import 'react-dates-custom-month-year-navigation/initialize';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates-custom-month-year-navigation/lib/css/_datepicker.css';
import { DateRangePicker } from 'react-dates';

import AgendaIcon from 'components/AgendaIcon';
import Button from 'components/Button';

import withFormContext, { formPropTypes } from 'core/hoc/withFormContext';

import * as S from './styles';

const STORE_FORMAT = 'YYYY-MM-DD';
const DISPLAY_FORMAT = 'DD/MM/YYYY';

const PeriodField = ({
  attributeName,
  onChange,
  isOutsideRange,
  showClearDates,
  ...formProps
}) => {
  const { form, changeAttribute } = formProps.formContext;

  const [focusedInput, setFocusedInput] = useState(null);

  const shouldUsePortal = () => {
    if (typeof window === 'undefined') return true;
    return window.matchMedia('(max-width: 720px)').matches;
  };

  const handleChange = (date) => {
    const newDate = Object.keys(date).reduce((previous, current) => {
      previous[current] = date[current]
        ? date[current].format(STORE_FORMAT)
        : null;

      return previous;
    }, {});

    const event = {
      target: {
        value: newDate,
      },
    };

    changeAttribute(attributeName)(event);
    form[attributeName] = newDate;
  };

  const formatDateFromFormState = (attribute, date) => {
    if (!form[attribute][date]) return null;

    return moment(form[attribute][date]);
  };

  const value = form[attributeName]
    ? {
        startDate: formatDateFromFormState(attributeName, 'startDate'),
        endDate: formatDateFromFormState(attributeName, 'endDate'),
      }
    : null;

  const applyChange = () => {
    onChange(form);
    setFocusedInput(false);
  };

  const clearDates = () => {
    const clearedDates = {
      startDate: null,
      endDate: null,
    };

    handleChange(clearedDates);
    onChange(clearedDates);
    setFocusedInput(false);
  };

  const getLastSevenDays = () => {
    handleChange({
      startDate: moment().subtract(7, 'days'),
      endDate: moment().subtract(1, 'days'),
    });
  };

  const getLastThirtyDays = () => {
    handleChange({
      startDate: moment().subtract(30, 'days'),
      endDate: moment().subtract(1, 'days'),
    });
  };

  const getCurrentMonth = () => {
    handleChange({
      startDate: moment().startOf('month'),
      endDate: moment(),
    });
  };

  const renderCalendarButtons = () => {
    return (
      <S.ButtonContainer>
        <S.PeriodButtonRow>
          <Button role="button" variation="outline" onClick={getLastSevenDays}>
            Últimos 7 dias
          </Button>
          <Button role="button" variation="outline" onClick={getLastThirtyDays}>
            Últimos 30 dias
          </Button>
          <Button role="button" variation="outline" onClick={getCurrentMonth}>
            Este mês
          </Button>
        </S.PeriodButtonRow>
        <S.ActionButtonRow>
          <Button role="button" variation="primary" onClick={applyChange}>
            Aplicar
          </Button>
          <Button role="button" variation="secondary" onClick={clearDates}>
            Limpar
          </Button>
        </S.ActionButtonRow>
      </S.ButtonContainer>
    );
  };
  return (
    <S.PeriodField>
      <DateRangePicker
        startDate={value && value.startDate}
        startDateId="inicio"
        startDatePlaceholderText="Data inicial"
        endDate={value && value.endDate}
        endDateId="termino"
        navPrev={<AgendaIcon name="arrow-left" size="small" />}
        navNext={<AgendaIcon name="arrow-right" size="small" />}
        endDatePlaceholderText="Data final"
        onDatesChange={handleChange}
        focusedInput={focusedInput}
        onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
        showClearDates={showClearDates}
        customArrowIcon={<span>-</span>}
        customInputIcon={<AgendaIcon name="calendar" size="medium" />}
        numberOfMonths={1}
        withPortal={shouldUsePortal()}
        displayFormat={DISPLAY_FORMAT}
        isOutsideRange={(day) => isOutsideRange(day)}
        hideKeyboardShortcutsPanel
        autoFocusEndDate
        keepOpenOnDateSelect
        enableOutsideDays
        readOnly
        calendarInfoPosition="bottom"
        renderCalendarInfo={renderCalendarButtons}
      />
    </S.PeriodField>
  );
};

PeriodField.propTypes = {
  attributeName: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  isOutsideRange: PropTypes.func,
  showClearDates: PropTypes.bool,
  ...formPropTypes,
};

PeriodField.defaultProps = {
  showClearDates: false,
  // eslint-disable-next-line
  isOutsideRange: (_day) => {},
};

export default withFormContext(PeriodField);
