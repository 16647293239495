import React from 'react';
import { useTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';
import parse from 'html-react-parser';
import PropTypes from 'prop-types';
import * as S from './styles';
import { Button } from '@agendaedu/ae-web-components';

import withFormContext, { formPropTypes } from 'core/hoc/withFormContext';
import { buildDiscount } from 'core/utils/edupay/functions';
import { flags } from 'core/constants/flags';
import launchDarkly from 'core/helper/launchDarkly';

import Discount from './Discount';
import NewDiscount from './NewDiscount';
import AgendaIcon from 'components/AgendaIcon';
import CommonLabel from 'components/CommonLabel';
import FormCheckbox from 'components/Form/Checkbox';
import Tooltip from 'components/Tooltip';
import Title from 'components/WizardForm/Title';
import { TOP_CENTER } from 'components/Tooltip';
import ToggleSwitch from 'components/Form/ToggleSwitch';

import validatesDiscount from './validations';

const EdupayDiscount = ({
  action,
  type,
  variation,
  validations,
  withoutTitle,
  showCreditCardDiscount = false,
  isNumericField = false,
  formContext: { form, formMeta, updateAttribute },
}) => {
  const {
    toggle_discount: toggleDiscount,
    edupay_discounts: edupayDiscounts,
    allowed_payment_method: allowedPaymentMethod,
    allow_credit_card_discount: allowCreditCardDiscount,
  } = form;

  const {
    status,
    form_type,
    beforeSendBilletReleasedAt,
    before_approved_billet_expires_at: beforeApprovedBilletExpiresAt,
  } = formMeta;

  const { t } = useTranslation(['payments', 'common']);

  const [cookies] = useCookies(['agendaedu_launch_darkly']);
  const activeFlags = cookies.agendaedu_launch_darkly?.active_flags;
  const hasCreditCardDiscountsFlag = launchDarkly.checkFlagActive(
    activeFlags,
    flags.CREDIT_CARD_DISCOUNT
  );

  const disabledOnEdit =
    form_type === 'recurrent'
      ? ['released', 'delayed'].includes(status)
      : !['pending', 'rejected'].includes(status);

  edupayDiscounts &&
    edupayDiscounts.map((discount) => {
      validations.push(...validatesDiscount(discount.key));
    });

  const toggleSwitch = () => {
    updateAttribute('toggle_discount', !toggleDiscount);
    if (
      !hasCreditCardDiscountsFlag ||
      !allowedPaymentMethod.includes('credit_card')
    )
      updateAttribute('allow_credit_card_discount', false);
  };

  const tooltipContent =
    type === 'plan' && action === 'edit' ? (
      <p>
        A edição de desconto pontualidade irá impactar <br />
        todas as demais cobranças do plano.
      </p>
    ) : (
      <p>Dê um desconto para o pagamento efetuado.</p>
    );

  const onChangeDiscount = (index, values) => {
    edupayDiscounts[index] = values;

    updateAttribute('edupay_discounts', edupayDiscounts);
  };

  const addNewDiscount = () => {
    const newDiscounts = edupayDiscounts.concat(buildDiscount());

    updateAttribute('edupay_discounts', newDiscounts);
  };

  const removeDiscount = (index) => {
    const newDiscounts = [
      ...edupayDiscounts.slice(0, index),
      ...edupayDiscounts.slice(index + 1),
    ];

    updateAttribute('edupay_discounts', newDiscounts);
  };

  const renderLabelMessage = {
    schoolProduct: () => {
      if (status !== 'pending') {
        return (
          <CommonLabel variation="info">
            {`O desconto por pontualidade só é válido para a primeira
          data de vencimento informada (${beforeApprovedBilletExpiresAt}).`}
          </CommonLabel>
        );
      }
    },
    recurrentPlan: () => {
      if (disabledOnEdit && action === 'editBill') {
        return (
          <CommonLabel variation="info">
            {`O desconto por pontualidade só é válido para a primeira
          data de vencimento informada (${beforeSendBilletReleasedAt}).`}
          </CommonLabel>
        );
      }
    },
  };

  const canHaveEdupayDiscount = (paymentMethod) =>
    ['billet', 'pix', 'credit_card'].includes(paymentMethod);

  return (
    <>
      {allowedPaymentMethod.some(canHaveEdupayDiscount) && (
        <S.EdupayDiscountWrapper>
          {!withoutTitle && <Title name="Condições de pagamento" />}
          <S.ToggleDiscountWrapper withoutTitle>
            <S.ToggleDiscountTitle>Desconto pontualidade</S.ToggleDiscountTitle>
            <S.DiscountToolip content={tooltipContent} position={TOP_CENTER}>
              <AgendaIcon name="help" size="small" className="custom-size" />
            </S.DiscountToolip>
            <ToggleSwitch
              testId="toggleDiscount"
              toggled={toggleDiscount}
              onChange={toggleSwitch}
              disabled={disabledOnEdit}
            />
          </S.ToggleDiscountWrapper>

          {hasCreditCardDiscountsFlag &&
            showCreditCardDiscount &&
            toggleDiscount &&
            allowedPaymentMethod.includes('credit_card') && (
              <S.CheckboxWrapper>
                <FormCheckbox
                  checked={allowCreditCardDiscount}
                  onChange={() =>
                    updateAttribute(
                      'allow_credit_card_discount',
                      !allowCreditCardDiscount
                    )
                  }
                  disabled={disabledOnEdit}
                />
                <span
                  className={`checkbox-label`}
                  onClick={() =>
                    !disabledOnEdit &&
                    updateAttribute(
                      'allow_credit_card_discount',
                      !allowCreditCardDiscount
                    )
                  }
                >
                  {t(`form_section.active_credit_card_discount`)}
                </span>
                <Tooltip
                  content={parse(
                    t(`form_section.credit_card_discount_tooltip`)
                  )}
                  position={TOP_CENTER}
                >
                  <AgendaIcon
                    name="help"
                    size="small"
                    className="custom-size"
                  />
                </Tooltip>
              </S.CheckboxWrapper>
            )}

          {toggleDiscount && (
            <>
              {hasCreditCardDiscountsFlag && showCreditCardDiscount ? (
                <>
                  {edupayDiscounts.map((discount, index) => (
                    <NewDiscount
                      key={discount.key}
                      attributeForm={discount.key}
                      counter={index + 1}
                      index={index}
                      discountValues={discount}
                      onChange={onChangeDiscount}
                      disabled={disabledOnEdit}
                      removeDiscount={removeDiscount}
                      isNumericField={isNumericField}
                    />
                  ))}
                </>
              ) : (
                <>
                  {edupayDiscounts.map((discount, index) => (
                    <Discount
                      key={discount.key}
                      attributeForm={discount.key}
                      counter={index + 1}
                      index={index}
                      discountValues={discount}
                      onChange={onChangeDiscount}
                      disabled={disabledOnEdit}
                      removeDiscount={removeDiscount}
                    />
                  ))}
                </>
              )}
              {!disabledOnEdit &&
                (hasCreditCardDiscountsFlag && showCreditCardDiscount ? (
                  <Button
                    variant="secondary"
                    icon="plus"
                    data-testid="addNewDiscount"
                    onClick={addNewDiscount}
                    marginTop="lg"
                  >
                    {t(`form_section.add_new_discount_button`)}
                  </Button>
                ) : (
                  <S.AddNewDisountButton
                    testID="addNewDiscount"
                    type="a"
                    variation="link"
                    onClick={addNewDiscount}
                  >
                    {t(`form_section.add_old_discount_button`)}
                  </S.AddNewDisountButton>
                ))}
              {renderLabelMessage[variation]}
            </>
          )}
        </S.EdupayDiscountWrapper>
      )}
    </>
  );
};

EdupayDiscount.propTypes = {
  ...formPropTypes,
  variation: PropTypes.oneOf(['schoolProduct', 'recurrentPlan']),
  withoutTitle: PropTypes.bool,
};

EdupayDiscount.defaultProps = {
  variation: 'recurrentPlan',
  withoutTitle: false,
};

export default withFormContext(EdupayDiscount);
