import styled from 'styled-components';

export const BillInfo = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap;

  .UserDisplay {
    margin-bottom: 16px;
    margin-right: 56px;
  }
`;
