import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select, { components as reactSelectComponents } from 'react-select';
import autobind from 'autobind-decorator';

import withFormContext, { formPropTypes } from 'core/hoc/withFormContext';

class SelectWithGroup extends Component {
  getOptionsForSelect() {
    const { attributeName, formContext } = this.props;
    const { getOptionsForSelect } = formContext;

    return getOptionsForSelect(attributeName) || [];
  }

  @autobind
  getPlaceholderComponent(props) {
    const { placeholder } = this.props;

    return (
      <reactSelectComponents.Placeholder {...props}>
        {placeholder}
      </reactSelectComponents.Placeholder>
    );
  }

  @autobind
  onChange(option, { action }) {
    if (action === 'select-option') {
      const { onChange, formContext, attributeName } = this.props;
      const { changeAttribute, updateAttribute } = formContext;
      const { value } = option;

      if (onChange) {
        onChange(value, changeAttribute(attributeName), updateAttribute);
      } else {
        const fakeEvent = {
          target: {
            value,
          },
        };

        changeAttribute(attributeName)(fakeEvent);
      }
    }
  }

  render() {
    const { attributeName, disabled, formContext, searchable } = this.props;

    const { form } = formContext;

    return (
      <Select
        classNamePrefix="Select"
        components={{ Placeholder: this.getPlaceholderComponent }}
        isDisabled={disabled}
        isSearchable={searchable}
        onChange={this.onChange}
        options={this.getOptionsForSelect()}
        value={form[attributeName]}
        noOptionsMessage={() => 'Sem resultados'}
      />
    );
  }
}

SelectWithGroup.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  searchable: PropTypes.bool,
  ...formPropTypes,
};

SelectWithGroup.defaultPropTypes = {
  placeholder: 'Selecione uma opção',
};

export default withFormContext(SelectWithGroup);
