import React, { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import * as S from './styles';

import omniChannelActions from 'store/messages/omniChannel/actions';
import { OmniChannelState } from 'store/messages/omniChannel/types';

import { messagesReadStatus } from 'core/constants';
import { MessageGroupAvatar } from 'components/Messages/OmniChannel/MessageGroupAvatar';
import Tooltip from 'components/Tooltip';

import {
  Avatar,
  Button,
  RecipientTag,
  Tag,
  Text,
} from '@agendaedu/ae-web-components';

import { formatDataToOmniHeader } from './formmatedDataForHeader';

import { Actions, OwnerProps, VariantButton } from './types';

export const MessagesHeader = ({
  isReadOnly = false,
}: OwnerProps): JSX.Element => {
  const { t } = useTranslation('messages');
  const dispatch = useDispatch();

  const {
    activeChat,
    activeChatUser,
    activeChannel,
    activeTicket,
    isNewChat,
    isLoadingMessages,
  } = useSelector((state: OmniChannelState) => state.omniChannel);

  const {
    toggleTransferTicketModal,
    toggleSideDetailsModal,
    setMessagesMarkReadStatusRequest,
    setStartOrCloseAttendanceRequest,
    socketLeaveChatRoomRequest,
    toggleResetActiveChat,
  } = omniChannelActions;

  const hasAttendingClosed =
    activeChannel.attendingClosed &&
    !activeChannel.attendingHours?.blockOnlyNotification;

  const {
    ticketId,
    name,
    subject,
    classroomsNames,
    mainUserRole,
    readStatus,
    avatarColor,
    avatarUrl,
    nameInitials,
    isTypeTicket,
    isTypeFamily,
    currentType,
    roleMessage,
    isDisabledBtn,
  } = formatDataToOmniHeader(activeChat, activeChatUser, activeTicket);

  const canTransferTicket =
    isTypeTicket &&
    !isDisabledBtn &&
    activeTicket?.attributes.permissions?.canTransfer;

  const canShowRightActionButton =
    !isReadOnly && !isNewChat && !isLoadingMessages && !hasAttendingClosed;

  const renderUserRoleTag = (): JSX.Element | null => {
    if (isTypeFamily) {
      return null;
    } else {
      return roleMessage ? (
        <Tooltip content={roleMessage}>
          <Tag
            size="small"
            name={mainUserRole}
            variant="neutral"
            enableEllipsis
          />
        </Tooltip>
      ) : (
        <Tag
          size="small"
          name={mainUserRole}
          variant="neutral"
          enableEllipsis
        />
      );
    }
  };

  const handleChangeReadStatus = useCallback(() => {
    const params = {
      channelId: activeChannel?.id,
      chatId: activeChat?.id,
      status: messagesReadStatus[readStatus],
    };

    dispatch(setMessagesMarkReadStatusRequest(params));
  }, [
    activeChannel?.id,
    activeChat?.id,
    dispatch,
    readStatus,
    setMessagesMarkReadStatusRequest,
  ]);

  const handleToggleTransferModal = useCallback(() => {
    dispatch(toggleTransferTicketModal());
  }, [dispatch, toggleTransferTicketModal]);

  const handleStartAttendanceTicket = useCallback(() => {
    if (!activeTicket) return;

    const currentAction = {
      waiting: 'start',
      in_attendance: 'close',
      done: 'start',
    };

    dispatch(
      setStartOrCloseAttendanceRequest({
        channelId: activeChannel.id,
        ticketId: activeTicket.id,
        action: currentAction[activeTicket.attributes.status] as Actions,
      })
    );
  }, [activeChannel.id, activeTicket, setStartOrCloseAttendanceRequest]);

  const typeButton = {
    privateorfamily: {
      variant: 'secondary',
      text: {
        answered: 'omni_channel.messages.mark_not_read_text',
        unanswered: 'omni_channel.messages.mark_read_text',
      },
      onSubmit: handleChangeReadStatus,
    },
    ticket: {
      variant: 'primary',
      text: {
        waiting: 'omni_channel.messages.start_service',
        in_attendance: 'omni_channel.messages.close_service',
        done: 'omni_channel.messages.closed_service',
      },
      onSubmit: handleStartAttendanceTicket,
    },
  };

  const handleOnBackChats = useCallback(() => {
    dispatch(toggleResetActiveChat());
    activeChat && dispatch(socketLeaveChatRoomRequest(activeChat.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, toggleResetActiveChat]);

  const handleToggleDetailsModal = useCallback(() => {
    if (isReadOnly) return;

    dispatch(toggleSideDetailsModal());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, toggleSideDetailsModal]);

  return (
    <S.Wrapper data-testid="header-wrapper">
      <S.InformationWrapper isReadOnly={isReadOnly}>
        <S.AvatarWrapper>
          {!isReadOnly && (
            <S.BackChannelIcon
              name="chevron-left"
              size="md"
              onClick={handleOnBackChats}
            />
          )}

          <S.AvatarButton
            isReadOnly={isReadOnly}
            onClick={handleToggleDetailsModal}
          >
            {isTypeFamily ? (
              <MessageGroupAvatar
                avatarColor={avatarColor}
                size="sm"
                nameInitials={nameInitials}
                avatarUrl={avatarUrl}
              />
            ) : (
              <Avatar
                avatarColor={avatarColor}
                size="md"
                nameInitials={nameInitials}
                avatarUrl={avatarUrl}
              />
            )}
          </S.AvatarButton>
          <S.MessageInformationsWrapper>
            <Text color={'neutral.black'} variant="subtitle-medium-14">
              {name}
            </Text>

            <S.MessageInformationsRow>
              <>
                {renderUserRoleTag()}
                <RecipientTag
                  id="recipient-tag"
                  variant="neutral"
                  recipientType={t('omni_channel.common.recipient_type_text')}
                  recipients={classroomsNames?.map((classroom) => ({
                    name: classroom,
                  }))}
                  modalTitle={t('omni_channel.common.recipient_type_text')}
                  size="small"
                />
              </>
            </S.MessageInformationsRow>
          </S.MessageInformationsWrapper>
        </S.AvatarWrapper>

        {canShowRightActionButton && (
          <S.ActionButtonWrapper>
            {canTransferTicket && (
              <Button
                data-testID="transfer-button"
                size="sm"
                variant="secondary"
                onClick={handleToggleTransferModal}
              >
                {t('omni_channel.messages.transfer_service')}
              </Button>
            )}

            <Button
              size="sm"
              variant={typeButton[currentType].variant as VariantButton}
              onClick={typeButton[currentType].onSubmit}
              disabled={isDisabledBtn}
            >
              {t(typeButton[currentType].text[readStatus])}
            </Button>
          </S.ActionButtonWrapper>
        )}
      </S.InformationWrapper>

      {!isReadOnly && isTypeTicket && (
        <S.SubjectAndIdWrapper>
          <Text variant="subtitle-medium-12" color="neutral.gray2" mb={0}>
            <Trans>
              {t('omni_channel.messages.ticket_and_subject_text', {
                id: ticketId,
                subject,
              })}
            </Trans>
          </Text>
        </S.SubjectAndIdWrapper>
      )}
    </S.Wrapper>
  );
};
