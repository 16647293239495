import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CardList, Text } from '@agendaedu/ae-web-components';
import { useTranslation } from 'react-i18next';

import Loader from 'components/Loader';
import StudentCard from './StudentCard';
import StudentsListSkeleton from './Skeleton';

import MoodTrackersActions from 'store/moodTrackers/actions';

import {
  FetchAnswersActionProps,
  MoodTrackersState,
} from 'store/moodTrackers/types';

import * as S from './styles';

const StudentsList = () => {
  const { t } = useTranslation(['mood_trackers']);

  const dispatch = useDispatch();

  const { setPage } = MoodTrackersActions;

  const { studentsList, isSending, pagination } = useSelector(
    (state: MoodTrackersState) => state.moodTrackers
  );

  const renderStudentsInfo = studentsList.map((student) => (
    <StudentCard student={student} key={student.id} />
  ));

  const hasMoreStudents = () => {
    const numberOfItemsOnTheList = pagination.page * pagination.per_page;

    return numberOfItemsOnTheList === studentsList.length;
  };

  const handlePageLoadMore = () => {
    dispatch(setPage({ page: pagination.page + 1 } as FetchAnswersActionProps));
  };

  return (
    <Loader isLoading={isSending} customLoadElement={<StudentsListSkeleton />}>
      {!!studentsList.length && (
        <S.StudentsCountContainer>
          <Text variant="subtitle-medium-14">
            {t('students_count', { count: studentsList.length })}
          </Text>
        </S.StudentsCountContainer>
      )}

      <CardList
        pageStart={1}
        hasMore={!isSending && hasMoreStudents()}
        loadMore={handlePageLoadMore}
        loader={<StudentsListSkeleton />}
        my="sm"
      >
        {renderStudentsInfo}
      </CardList>
    </Loader>
  );
};

export default StudentsList;
