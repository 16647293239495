import React from 'react';

import {
  theme,
  DefaultThemeProps,
  Box,
  Card,
  FlexGrid,
  FlexGridItem,
  Text,
} from '@agendaedu/ae-web-components';
import { useTranslation } from 'react-i18next';
import { TotalizerCardsProps } from './types';

const TotalizerCards = ({
  totals: {
    created_count = 0,
    updated_count = 0,
    deleted_count = 0,
    restored_count = 0,
  },
}: TotalizerCardsProps) => {
  const { t } = useTranslation(['integrations_monitor']);

  const defaultThemeProps: DefaultThemeProps = theme;

  const {
    colors: { neutral, context },
  } = defaultThemeProps;

  return (
    <FlexGrid gutter="sm" data-testid="totalizer-cards">
      <FlexGridItem cols={{ desktopLG: 3, desktopXL: 2 }} filled>
        <Card
          p={16}
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
        >
          <Text
            as="h2"
            mt={0}
            mb={12}
            variant="subtitle-medium-14"
            fontWeight={500}
            color={neutral.gray1}
          >
            {t('totalizer_cards.created')}
          </Text>
          <Text
            as="h2"
            mt={0}
            mb={0}
            variant="headline-h2-bold-24"
            fontWeight={500}
            color={context.success.default}
          >
            {created_count}
          </Text>
        </Card>
      </FlexGridItem>
      <FlexGridItem cols={{ desktopLG: 3, desktopXL: 2 }} filled>
        <Card
          p={16}
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
        >
          <Text
            as="h2"
            mt={0}
            mb={12}
            variant="subtitle-medium-14"
            fontWeight={500}
            color={neutral.gray1}
          >
            {t('totalizer_cards.updated')}
          </Text>
          <Text
            as="h2"
            mt={0}
            mb={0}
            variant="headline-h2-bold-24"
            fontWeight={500}
            color={context.info.default}
          >
            {updated_count}
          </Text>
        </Card>
      </FlexGridItem>
      <FlexGridItem cols={{ desktopLG: 3, desktopXL: 2 }} filled>
        <Card
          p={16}
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
        >
          <Text
            as="h2"
            mt={0}
            mb={12}
            variant="subtitle-medium-14"
            fontWeight={500}
            color={neutral.gray1}
          >
            {t('totalizer_cards.deleted')}
          </Text>
          <Text
            as="h2"
            mt={0}
            mb={0}
            variant="headline-h2-bold-24"
            fontWeight={500}
            color={context.warning.default}
          >
            {deleted_count}
          </Text>
        </Card>
      </FlexGridItem>
      <FlexGridItem cols={{ desktopLG: 3, desktopXL: 2 }} filled>
        <Box>
          <Card
            p={16}
            pr={24}
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
          >
            <Text
              as="h2"
              mt={0}
              mb={12}
              variant="subtitle-medium-14"
              fontWeight={500}
              color={neutral.gray1}
            >
              {t('totalizer_cards.restored')}
            </Text>
            <Text
              as="h2"
              mt={0}
              mb={0}
              variant="headline-h2-bold-24"
              fontWeight={500}
              color={neutral.gray2}
            >
              {restored_count}
            </Text>
          </Card>
        </Box>
      </FlexGridItem>
    </FlexGrid>
  );
};

export default TotalizerCards;
