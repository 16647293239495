import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import withAppContext, { appPropTypes } from 'core/hoc/withAppContext';
import HubstoreActions from 'store/hubstore/actions';

import Toast from 'components/Toast';
import EmptyState from './components/EmptyState';
import CardList from './components/CardList';
import SkeletonList from './skeletonList';

import { IMAGES_HUB_STORE_URL } from 'core/constants';

import { HobStoreProps, RootState } from './types';
import * as S from './styles';

const Hubstore = ({ history }: HobStoreProps) => {
  const dispatch = useDispatch();

  const { t } = useTranslation(['hub_store']);

  const {
    listProductsHubstore,
    listProductsHubstoreMeta,
    isLoadinglistProductsHubstore,
  } = useSelector((state: RootState) => state.hubstore);

  const { fetchListProductsHubstore } = HubstoreActions;

  useEffect(() => {
    dispatch(fetchListProductsHubstore());
  }, [dispatch, fetchListProductsHubstore]);

  if (isLoadinglistProductsHubstore) return <SkeletonList />;

  return (
    <S.MainContainer id="HubStoreMainContainer">
      <S.MainTitle>Hub Store</S.MainTitle>

      <EmptyState
        imageUrl={IMAGES_HUB_STORE_URL.bannerUrl}
        title={t('empty_state.title')}
        descrptions={t('empty_state.descrption')}
      />

      <CardList
        listProductsHubstore={listProductsHubstore}
        meta={listProductsHubstoreMeta}
        history={history}
      />

      <Toast />
    </S.MainContainer>
  );
};

Hubstore.propTypes = {
  ...appPropTypes,
};

export default withAppContext(Hubstore);
