import styled, { css } from 'styled-components';

import { ThemeProps } from 'config/themes/types';
import { StylesProps } from '../types';

const alertModifiers = {
  pending_schedule: ({ theme: { colors } }: ThemeProps) => css`
    background-color: ${colors.context.warning.op20};
    color: ${colors.context.warning.default};
  `,
  scheduled: ({ theme: { colors } }: ThemeProps) => css`
    background-color: ${colors.context.info.op20};
    color: ${colors.context.info.default};
  `,
  rejected: ({ theme: { colors } }: ThemeProps) => css`
    background-color: ${colors.context.alert.op20};
    color: ${colors.context.alert.default};
  `,
  waiting: ({ theme: { colors } }: ThemeProps) => css`
    background-color: ${colors.context.info.op20};
    color: ${colors.context.info.default};
  `,
};

export const TagsWrapper = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    align-items: center;
    gap: ${space.sm};
  `};
`;

export const HandoutTitle = styled.h2`
  ${({ theme: { colors, font, space, typography } }: ThemeProps) => css`
    margin: ${space.xs4};
    ${typography.headline.H2Bold24};
    color: ${colors.neutral.black};

    @media screen and (max-width: 820px) {
      font-size: ${font.size.lg};
    }
  `};
`;

export const HandoutLabel = styled.span`
  ${({ theme: { colors, typography } }: ThemeProps) => css`
    ${typography.subtitle.Medium14};
    color: ${colors.neutral.gray2};
  `}
`;

export const HandoutContent = styled.div`
  ${({ theme: { colors, typography } }: ThemeProps) => css`
    ${typography.body.Regular16};
    color: ${colors.neutral.gray1};
  `}
`;

export const CoverImagePreview = styled.div`
  ${({ theme: { border, colors, space } }: ThemeProps) => css`
    max-width: 617px;
    max-height: 349px;
    padding: ${space.xs2};
    background-color: ${colors.neutral.gray6};
    border: ${border.width.sm} ${border.style.solid} ${colors.neutral.gray4};
    border-radius: ${border.radius.md};
    overflow: hidden;

    img {
      width: 100%;
      max-height: 317px;
      border: ${border.width.sm} ${border.style.solid}
        ${colors.brand.primary.op20};
      border-radius: inherit;
      aspect-ratio: 1 / 1;
      object-fit: cover;
    }
  `}
`;

export const TooltipClassroomWrapper = styled.div`
  ${({
    theme: { border, colors, breakpoints, space, shadow },
  }: ThemeProps) => css`
    position: absolute;
    bottom: ${space.xl3};
    left: 50%;
    transform: translateX(-50%);
    width: 256px;
    flex-wrap: wrap;
    padding: ${space.sm};
    border-radius: ${border.radius.sm};
    background-color: ${colors.neutral.white};
    box-shadow: ${shadow.hover};
    z-index: 1;

    display: none;
    flex-direction: column;
    gap: ${space.xs2};

    &::before {
      content: '';
      position: absolute;
      bottom: -${space.xs2};
      left: 50%;
      transform: translateX(-50%);
      width: 8px;
      height: 12px;

      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
      border-top: 12px solid ${colors.neutral.white};

      background: transparent;
    }

    @media only screen and (max-width: ${breakpoints.tabletMD}) {
      left: 50%;
      transform: translateX(-50%);
    }
  `}
`;

export const HandoutTooltip = styled.div`
  width: auto;
  position: relative;
  cursor: pointer;

  &:hover {
    ${TooltipClassroomWrapper} {
      display: flex;
    }
  }
`;

export const TooltipTitle = styled.span`
  ${({ theme: { typography } }: ThemeProps) => css`
    ${typography.subtitle.Medium12};
  `}
`;

export const TooltipContent = styled.p`
  ${({ theme: { colors, space, typography } }: ThemeProps) => css`
    margin: ${space.xs4};
    ${typography.body.Regular14};
    color: ${colors.neutral.black};
  `}
`;

export const Alert = styled.div<StylesProps>`
  ${({ theme, statusType }) => css`
    width: 100%;
    padding: ${theme.space.sm} ${theme.space.lg};
    ${theme.typography.title.Bold14};
    border-radius: ${theme.border.radius.md};
    border: ${theme.border.width.sm} ${theme.border.style.solid} transparent;

    span {
      margin-right: ${theme.space.xs3};
    }

    ${!!statusType && alertModifiers[statusType]({ theme })};
  `};
`;
