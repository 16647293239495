import React from 'react';
import Field from 'components/Form/Field';
import Title from 'components/WizardForm/Title';
import withAppContext, { appPropTypes } from 'core/hoc/withAppContext';
import { createFilter } from 'react-select';
import CustomMenuList from './CustomMenuList';
import Loader from 'components/Loader';
import CustomOption from './CustomOption';

import * as S from './styles';

const StudentSelect = ({
  options,
  onChange,
  value,
  defaultValue,
  loading,
  appContext: { primaryColor },
}) => {
  const dropdowIndicatorIcon = () => {
    return (
      <S.LoaderWrapper>
        <Loader size={20} color={primaryColor} />
      </S.LoaderWrapper>
    );
  };

  return (
    <S.Wrapper>
      <Title name="Vincular aluno(s) ao responsável" />
      <div className="row">
        <Field
          fullWidth
          menuPlacement="top"
          isMulti
          withArrowSelect
          label="Nome do(s) aluno(s)*"
          type="selectWithIcon"
          attributeName="student_profile_ids"
          rawValue={value}
          defaultValue={defaultValue}
          onChange={onChange}
          options={options?.map((student) => ({
            label: student.attributes.name,
            value: student.id,
          }))}
          filterOption={createFilter({ ignoreAccents: false })}
          components={{
            MenuList: (props) => (
              <CustomMenuList {...props} loading={loading} />
            ),
            Option: CustomOption,
            IndicatorSeparator: () => null,
            DropdownIndicator: () => (loading ? dropdowIndicatorIcon() : null),
          }}
          placeholder="Selecione"
          isSearchable={true}
        />
      </div>
      <span>
        Se somente <b>aluno(s) sem turma(s) neste ano letivo</b> forem
        vinculados ao responsável, esse responsável poderá ser acessado apenas
        através do filtro <b>Situação</b> dentro da{' '}
        <b>Central de Responsáveis</b>
      </span>
    </S.Wrapper>
  );
};

StudentSelect.propTypes = {
  ...appPropTypes,
};

export default withAppContext(StudentSelect);
