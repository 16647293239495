import React, { Suspense, useEffect, useMemo, lazy } from 'react';

import withAppContext, { appPropTypes } from 'core/hoc/withAppContext';

import Toast from 'components/Toast';

import Loader from 'components/Loader';
import AlbumContext from 'core/contexts/Album';
import { Box } from '@agendaedu/ae-web-components';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import InformationTab from '../FormTabs/Information';
import RecipientsTab from 'components/Form/RecipientsTab';
import { SaveAlbumRequestParams, RootState } from 'store/albums/types';
import AlbumActions from 'store/albums/actions';

const AlbumForm = ({ match }) => {
  const { id: albumId } = match?.params;

  const dispatch = useDispatch();
  const { fetchFormClassrooms, requestSaveAlbum, fetchAlbum } = AlbumActions;
  const dataArea = useSelector(
    (state: { root: { dataArea: string } }) => state.root.dataArea
  );
  const { current, formMeta, isLoadingFormClassrooms } = useSelector(
    (state: RootState) => state.albums
  );
  const { t } = useTranslation('albums');

  const FormFullScreen = useMemo(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    () => lazy(() => import('components/FormFullScreen')),
    [current, isLoadingFormClassrooms]
  );

  const onSubmit = ({
    form: {
      title,
      description,
      sent_kind,
      classroom_ids,
      classroom_with_student_profile_ids,
    },
  }) => {
    const created_for = sent_kind === 'classrooms' ? 'classroom' : 'student';
    const normalizedStudentsIds = [].concat(
      ...Object.values(classroom_with_student_profile_ids || {})
    );

    const normalizedClassroomsIds =
      created_for === 'classroom'
        ? classroom_ids
        : Object.keys(classroom_with_student_profile_ids);

    const formatForm: SaveAlbumRequestParams['album'] = {
      id: albumId,
      name: title,
      description: description,
      created_for,
      classroom_ids: normalizedClassroomsIds,
      ...(created_for === 'student' && {
        student_profile_ids: normalizedStudentsIds,
      }),
    };

    dispatch(requestSaveAlbum(formatForm));
  };

  useEffect(() => {
    dispatch(fetchFormClassrooms());
  }, [dispatch, fetchFormClassrooms]);

  useEffect(() => {
    if (albumId) dispatch(fetchAlbum(albumId));
  }, [albumId, dispatch, fetchAlbum]);

  return (
    <Box display="flex" justifyContent="center">
      <Loader isLoading={isLoadingFormClassrooms}>
        <AlbumContext.Provider value={{}}>
          <Suspense fallback={<></>}>
            <FormFullScreen
              id="album-form"
              onSubmit={onSubmit}
              steps={[InformationTab, RecipientsTab]}
              backTo={
                albumId
                  ? `/${dataArea}/albums/${albumId}`
                  : `/${dataArea}/albums`
              }
              backToExternal
              form={current}
              formMeta={formMeta}
              isSubmitting={formMeta.isSubmittingAlbum}
              titlePage={t(`album_form.title_${albumId ? 'edit' : 'new'}`)}
              titleModal={t('album_form.titleModal')}
              contentModal={t('album_form.contentModal')}
            />
          </Suspense>
        </AlbumContext.Provider>
        <Toast />
      </Loader>
    </Box>
  );
};

AlbumForm.propTypes = {
  ...appPropTypes,
};

export default withAppContext(AlbumForm);
