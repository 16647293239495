/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import withAppContext from 'core/hoc/withAppContext';
import IntegrationsMonitorActions from 'store/integrationsMonitor/actions';

import Toast from 'components/Toast';

import {
  theme,
  DefaultThemeProps,
  Box,
  Card,
  CardList,
  FlexGrid,
  FlexGridItem,
  Tag,
  Text,
  Button,
} from '@agendaedu/ae-web-components';

import { RootState } from '../types';
import * as S from './styles';
import Breadcrumb from 'components/Breadcrumb';
import Tab from 'components/Tab';
import Tabs from 'components/Tabs';
import TotalizerCards from '../components/TotalizerCards';
import { INTEGRATION_MONITOR_URLS } from 'core/constants';
import EmptyState from 'components/EmptyState';
import ButtonAndSideSheet from '../components/ButtonAndSideSheet';

// eslint-disable-next-line react/prop-types
const InconsistencyDetails = ({
  match,
  location,
  appContext: { dataArea },
}) => {
  // eslint-disable-next-line react/prop-types
  const { id: inconsistencyId } = match.params;

  const tempEntityName = location?.search;
  const entityName = tempEntityName.toString().split('=')[1];

  const defaultThemeProps: DefaultThemeProps = theme;

  const {
    colors: { neutral },
  } = defaultThemeProps;

  const dispatch = useDispatch();

  const { t } = useTranslation(['integrations_monitor']);

  const entityTitleNames = {
    student_profiles: 'Alunos',
    responsible_profiles: 'Responsáveis',
    school_users: 'Funcionários',
    classroom_schedules: 'Quadro de horário',
    notifications: 'Notificação',
    disciplines: 'Disciplinas',
    classrooms: 'Turmas',
    headquarters: 'Unidades',
  };

  const {
    isLoading,
    isLoadList,
    inconsistence,
    inconsistenceMeta,
    inconsistencePagination,
  } = useSelector((state: RootState) => state.integrationsMonitor);

  const { getIntegrationInconsistencies } = IntegrationsMonitorActions;

  const hasMore = () => {
    const numberOfItemsOnTheList =
      inconsistencePagination.page * inconsistencePagination.per_page;

    return numberOfItemsOnTheList === inconsistence?.length;
  };

  const updateList = () => {
    if (!isLoadList && hasMore) {
      dispatch(
        getIntegrationInconsistencies({
          idIntegration: inconsistencyId,
          page: inconsistencePagination.page + 1,
          entity: entityName,
        })
      );
    }
  };

  const downloadInconsistenciesReport = () => {
    const baseURL: string = window.location.origin;
    const urlArchive = baseURL.concat(
      `/${dataArea}/erp_integrations/monitor/${inconsistencyId}.xls?entity=${entityName}`
    );
    window.open(urlArchive);
  };

  useEffect(() => {
    dispatch(
      getIntegrationInconsistencies({
        idIntegration: inconsistencyId,
        page: 1,
        entity: entityName,
      })
    );
  }, [dispatch, entityName, inconsistencyId, getIntegrationInconsistencies]);

  if (isLoading || !inconsistence) return <> </>;

  const renderContentSideSheet = (
    type: string,
    name: string,
    action: string,
    message: string,
    solution: string,
    debug_informations: {
      email_in_ae: string;
      username_in_ae: string;
      email_in_erp: string;
      username_in_erp: string;
      id_in_erp: string;
    }
  ): JSX.Element => {
    return (
      <Box pt={24} pb={24} pl={32} pr={32}>
        <Text
          as="h2"
          width="100%"
          mt={0}
          mb={16}
          variant="subtitle-medium-14"
          fontWeight={700}
          color={neutral.black}
        >
          {t('inconsistency_details.title_ae_section')}
        </Text>

        <Box pb={24}>
          <Tag
            enableEllipsis
            name={type}
            size="small"
            variant="negative"
            p={8}
          />
        </Box>
        <Box pb={24} display="flex" flexDirection="column" flexWrap="wrap">
          <Text
            as="h2"
            width="100%"
            mt={0}
            mb={16}
            variant="subtitle-medium-12"
            fontWeight={500}
            color={neutral.gray2}
          >
            {t('inconsistency_details.label_user')}
          </Text>
          <Text
            as="p"
            mt={0}
            mb={0}
            variant="subtitle-medium-16"
            fontWeight={500}
            color={neutral.gray1}
          >
            {name}
          </Text>
        </Box>

        <Box pb={24} display="flex" flexDirection="column" flexWrap="wrap">
          <Text
            as="h2"
            width="100%"
            mt={0}
            mb={16}
            variant="subtitle-medium-12"
            fontWeight={500}
            color={neutral.gray2}
          >
            {t('inconsistency_details.label_action')}
          </Text>
          <Text
            as="p"
            mt={0}
            mb={0}
            variant="subtitle-medium-16"
            fontWeight={500}
            color={neutral.gray1}
          >
            {action}
          </Text>
        </Box>

        <Box pb={24} display="flex" flexDirection="column" flexWrap="wrap">
          <Text
            as="h2"
            width="100%"
            mt={0}
            mb={16}
            variant="subtitle-medium-12"
            fontWeight={500}
            color={neutral.gray2}
          >
            {t('inconsistency_details.label_mensseger')}
          </Text>
          <Text
            as="p"
            mt={0}
            mb={0}
            variant="subtitle-medium-16"
            fontWeight={500}
            color={neutral.gray1}
            lineHeight="lg"
            textAlign="justify"
          >
            {message}
          </Text>
        </Box>
        <Box pb={24} display="flex" flexDirection="column" flexWrap="wrap">
          <Text
            as="h2"
            width="100%"
            mt={0}
            mb={16}
            variant="subtitle-medium-12"
            fontWeight={500}
            color={neutral.gray2}
          >
            {t('inconsistency_details.label_how_to_fix')}
          </Text>
          <Text
            as="p"
            mt={0}
            mb={0}
            variant="subtitle-medium-16"
            fontWeight={500}
            color={neutral.gray1}
            lineHeight="lg"
            textAlign="justify"
          >
            {solution}
          </Text>
        </Box>

        {!!debug_informations?.username_in_ae && (
          <Box pb={24} display="flex" flexDirection="column" flexWrap="wrap">
            <Text
              as="h2"
              width="100%"
              mt={0}
              mb={16}
              variant="subtitle-medium-12"
              fontWeight={500}
              color={neutral.gray2}
            >
              {t('inconsistency_details.label_username')}
            </Text>
            <Text
              as="p"
              mt={0}
              mb={0}
              variant="subtitle-medium-16"
              fontWeight={500}
              color={neutral.gray1}
              lineHeight="lg"
              textAlign="justify"
            >
              {debug_informations?.username_in_ae}
            </Text>
          </Box>
        )}

        {!!debug_informations?.email_in_ae && (
          <Box pb={24} display="flex" flexDirection="column" flexWrap="wrap">
            <Text
              as="h2"
              width="100%"
              mt={0}
              mb={16}
              variant="subtitle-medium-12"
              fontWeight={500}
              color={neutral.gray2}
            >
              {t('inconsistency_details.label_email')}
            </Text>
            <Text
              as="p"
              mt={0}
              mb={0}
              variant="subtitle-medium-16"
              fontWeight={500}
              color={neutral.gray1}
              lineHeight="lg"
              textAlign="justify"
            >
              {debug_informations.email_in_ae}
            </Text>
          </Box>
        )}

        <Text
          as="h2"
          width="100%"
          mt={16}
          mb={16}
          variant="subtitle-medium-14"
          fontWeight={700}
          color={neutral.black}
        >
          {t('inconsistency_details.title_erp_section')}
        </Text>

        {!!debug_informations?.id_in_erp && (
          <Box pb={24} display="flex" flexDirection="column" flexWrap="wrap">
            <Text
              as="h2"
              width="100%"
              mt={0}
              mb={16}
              variant="subtitle-medium-12"
              fontWeight={500}
              color={neutral.gray2}
            >
              {t('inconsistency_details.label_legacyid')}
            </Text>
            <Text
              as="p"
              mt={0}
              mb={0}
              variant="subtitle-medium-16"
              fontWeight={500}
              color={neutral.gray1}
              lineHeight="lg"
              textAlign="justify"
            >
              {debug_informations.id_in_erp}
            </Text>
          </Box>
        )}

        {!!debug_informations?.username_in_erp && (
          <Box pb={24} display="flex" flexDirection="column" flexWrap="wrap">
            <Text
              as="h2"
              width="100%"
              mt={0}
              mb={16}
              variant="subtitle-medium-12"
              fontWeight={500}
              color={neutral.gray2}
            >
              {t('inconsistency_details.label_username')}
            </Text>
            <Text
              as="p"
              mt={0}
              mb={0}
              variant="subtitle-medium-16"
              fontWeight={500}
              color={neutral.gray1}
              lineHeight="lg"
              textAlign="justify"
            >
              {debug_informations.username_in_erp}
            </Text>
          </Box>
        )}

        {!!debug_informations?.email_in_erp && (
          <Box pb={24} display="flex" flexDirection="column" flexWrap="wrap">
            <Text
              as="h2"
              width="100%"
              mt={0}
              mb={16}
              variant="subtitle-medium-12"
              fontWeight={500}
              color={neutral.gray2}
            >
              {t('inconsistency_details.label_email')}
            </Text>
            <Text
              as="p"
              mt={0}
              mb={0}
              variant="subtitle-medium-16"
              fontWeight={500}
              color={neutral.gray1}
              lineHeight="lg"
              textAlign="justify"
            >
              {debug_informations.email_in_erp}
            </Text>
          </Box>
        )}
      </Box>
    );
  };

  return (
    <S.MainContainer id="InconsistencyDetailsMainContainer">
      <Breadcrumb
        path="/schools/erp_integrations/monitor"
        title={t('inconsistency_details.breadcrumb_title')}
      />

      <Text
        as="h2"
        variant="headline-h2-bold-24"
        mb={32}
        mt={8}
        fontWeight={700}
        color={neutral.black}
      >
        {t('inconsistency_details.page_title', {
          integrationName: entityTitleNames[entityName],
        })}
      </Text>

      <Box>
        <Card>
          <Tabs defaultIndex={0}>
            <Tab title={t('inconsistency_details.information_tab')}>
              {inconsistenceMeta?.attributes && (
                <Box p={24}>
                  <TotalizerCards totals={inconsistenceMeta.attributes} />
                </Box>
              )}

              <Text
                as="h2"
                variant="title-bold-20"
                pl={26}
                pt={40}
                fontWeight={700}
                color={neutral.black}
              >
                {t('inconsistency_details.inconsistencies')}
              </Text>

              <Box
                display="flex"
                width="100%"
                flexDirection="row"
                justifyContent="flex-end"
                flexWrap="wrap"
                pr={26}
              >
                <Button
                  id="monitor-export-inconsistencies-button"
                  className="monitor-export-inconsistencies-button"
                  variant="secondary"
                  onClick={downloadInconsistenciesReport}
                >
                  {t('inconsistency_details.export_inconsistencies')}
                </Button>
              </Box>

              <Box
                display="flex"
                width="100%"
                flexDirection="row"
                justifyContent="space-between"
                flexWrap="wrap"
                pl={26}
                pr={26}
                pt={16}
              >
                <Text
                  as="h2"
                  mt={0}
                  mb={12}
                  variant="subtitle-medium-14"
                  fontWeight={500}
                  color={neutral.gray2}
                >
                  {t('inconsistency_details.inconsistencies_info_sync')}
                </Text>
                <Text
                  as="h2"
                  mt={0}
                  mb={12}
                  variant="subtitle-medium-14"
                  fontWeight={500}
                  color={neutral.gray2}
                >
                  {t('inconsistency_details.inconsistencies_info_sync_total', {
                    loaded: inconsistence?.length.toString(),
                    total: inconsistencePagination?.total,
                  })}
                </Text>
              </Box>

              <Box p={24}>
                {inconsistence?.length > 0 ? (
                  <CardList
                    mb={24}
                    padding={24}
                    hasMore={!isLoadList && hasMore()}
                    loadMore={() => updateList()}
                    initialLoad={true}
                    threshold={200}
                  >
                    {inconsistence?.map(
                      ({
                        id,
                        attributes: {
                          type,
                          name,
                          action,
                          message,
                          solution,
                          debug_informations,
                        },
                      }) => (
                        <FlexGrid gutter="lg" data-testid="list-card" key={id}>
                          <FlexGridItem cols={{ desktopLG: 2 }} filled>
                            <Box
                              height={62}
                              display="flex"
                              flexDirection="row"
                              flexWrap="wrap"
                              alignItems="center"
                              minHeight="100%"
                            >
                              <Tag
                                enableEllipsis
                                name={type}
                                size="small"
                                variant="negative"
                                p={8}
                              />
                            </Box>
                          </FlexGridItem>
                          <FlexGridItem cols={{ desktopLG: 3 }} filled>
                            <Box
                              height={62}
                              display="flex"
                              flexDirection="column"
                              flexWrap="wrap"
                            >
                              <Text
                                as="h2"
                                width="100%"
                                mt={0}
                                mb={16}
                                variant="subtitle-medium-12"
                                fontWeight={500}
                                color={neutral.gray2}
                              >
                                {t('inconsistency_details.label_user')}
                              </Text>
                              <S.BoxEllipsis>
                                <Text
                                  as="p"
                                  mt={0}
                                  mb={0}
                                  variant="subtitle-medium-16"
                                  fontWeight={500}
                                  color={neutral.gray1}
                                >
                                  {name}
                                </Text>
                              </S.BoxEllipsis>
                            </Box>
                          </FlexGridItem>
                          <FlexGridItem cols={{ desktopLG: 5 }} filled>
                            <Box
                              height={62}
                              display="flex"
                              flexDirection="column"
                              flexWrap="wrap"
                              pr={32}
                            >
                              <Text
                                as="h2"
                                width="100%"
                                mt={0}
                                mb={16}
                                variant="subtitle-medium-12"
                                fontWeight={500}
                                color={neutral.gray2}
                              >
                                {t('inconsistency_details.label_mensseger')}
                              </Text>
                              <S.BoxEllipsis>
                                <Text
                                  as="p"
                                  mt={0}
                                  mb={0}
                                  variant="subtitle-medium-16"
                                  fontWeight={500}
                                  color={neutral.gray1}
                                >
                                  {message}
                                </Text>
                              </S.BoxEllipsis>
                            </Box>
                          </FlexGridItem>
                          <FlexGridItem cols={{ desktopLG: 2 }} filled>
                            <Box
                              height={62}
                              display="flex"
                              flexDirection="row-reverse"
                              alignContent="center"
                              alignItems="center"
                            >
                              <ButtonAndSideSheet
                                title="Detalhe da inconsistência"
                                buttonTitle={t(
                                  'inconsistency_details.label_how_to_fix'
                                )}
                              >
                                {renderContentSideSheet(
                                  type,
                                  name,
                                  action,
                                  message,
                                  solution,
                                  debug_informations
                                )}
                              </ButtonAndSideSheet>
                            </Box>
                          </FlexGridItem>
                        </FlexGrid>
                      )
                    )}
                  </CardList>
                ) : (
                  <>
                    <Box display="flex" position="relative" padding={0}>
                      <Card
                        display="flex"
                        flexDirection="row"
                        justifyContent="center"
                      >
                        <Box>
                          <EmptyState
                            message={t(
                              'inconsistency_details.empty_state_list_inconsistency'
                            )
                              .split('\n')
                              .map((line, key) => (
                                <Text
                                  as="p"
                                  variant="subtitle-medium-12"
                                  key={key}
                                  fontWeight="500"
                                >
                                  {line}
                                </Text>
                              ))}
                            imgUrl={INTEGRATION_MONITOR_URLS.emptyState}
                          />
                        </Box>
                      </Card>
                    </Box>
                  </>
                )}
              </Box>
            </Tab>
          </Tabs>
        </Card>
        <Toast />
      </Box>
      <Toast />
    </S.MainContainer>
  );
};

export default withAppContext(InconsistencyDetails);
