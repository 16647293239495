const actions = {
  ERROR: 'ERROR/home',

  FETCH_DYNAMIC_MODULES: 'FETCH_DYNAMIC_MODULES/home',
  FETCH_DYNAMIC_MODULES_SUCCESS: 'FETCH_DYNAMIC_MODULES_SUCCESS/home',
  FETCH_SCHOOL_USER_SCHOOLS: 'FETCH_SCHOOL_USER_SCHOOLS/home',
  FETCH_SCHOOL_USER_SCHOOLS_SUCCESS: 'FETCH_SCHOOL_USER_SCHOOLS_SUCCESS/home',
  FETCH_TRAININGS: 'FETCH_TRAININGS/home',
  FETCH_TRAININGS_SUCCESS: 'FETCH_TRAININGS_SUCCESS/home',
  FETCH_BANNERS: 'FETCH_BANNERS/home',
  FETCH_BANNERS_SUCCESS: 'FETCH_BANNERS_SUCCESS/home',
  CHANGE_SCHOOL: 'CHANGE_SCHOOL/home',
  CHANGE_SCHOOL_SUCCESS: 'CHANGE_SCHOOL_SUCCESS/home',

  fetchDynamicModules: () => ({
    type: actions.FETCH_DYNAMIC_MODULES,
  }),

  fetchSchoolUserSchools: () => ({
    type: actions.FETCH_SCHOOL_USER_SCHOOLS,
  }),

  changeSchool: (schoolId: string) => ({
    type: actions.CHANGE_SCHOOL,
    schoolId,
  }),

  fetchTrainings: () => ({
    type: actions.FETCH_TRAININGS,
  }),

  fetchBanners: () => ({
    type: actions.FETCH_BANNERS,
  }),
};

export default actions;
