const classroom = 'Sala de Aula';

const PERSONA_TYPE = {
  both: 'Alunos e responsáveis',
  students: 'Alunos',
  responsibles: 'Responsáveis',
};

const DELIVERY_TYPE = {
  agenda_edu: 'Agenda Edu',
  classroom,
  null: classroom,
};

export { PERSONA_TYPE, DELIVERY_TYPE };
