import styled from 'styled-components';

export const ConteinerAccordion = styled.div.attrs((props) => ({
  variations: props.variations,
}))`
  .Accordion {
    .accordion__button:before {
      display: ${(props) =>
        props.variations === 'permission' ? 'none' : 'inline-block'};
      content: '';
      height: 8px;
      width: 8px;
      margin-right: 12px;
      border-bottom: 2px solid currentColor;
      border-right: 2px solid currentColor;
      transform: rotate(-45deg);
    }

    .TitleAccordion {
      font-family: 'Open Sans', sans-serif;
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.36;
      letter-spacing: normal;
      text-align: left;
      padding-left: 24px;
      margin: 0px;
      color: ${(props) => props.theme.black};
    }

    .accordion__button {
      ${(props) =>
        props.variations === 'rightButton' &&
        `
        display: flex;
        flex-direction: row-reverse;
      `}

      background-color: ${(props) => props.theme.gray14};
      cursor: pointer;
      padding: 16px;
      width: 100%;
      height: inherit;
      text-align: left;
      border: none;
      display: flex;
      align-items: center;
      border: 1px solid ${(props) => props.theme.gray12};
      border-radius: 6px;
      height: 56px;

      .TitleAccordion {
        flex: auto;
        padding-left: 8px;
        font-size: 16px;
        font-weight: 600;
        color: $black;
      }

      &:focus {
        outline: none;
      }

      &::before {
        color: ${(props) => props.theme.gray1};
      }
    }

    .accordion__button[aria-expanded='true'],
    .accordion__button[aria-selected='true'] {
      border-radius: 6px 6px 0px 0px;

      &::before {
        transform: rotate(45deg);
      }
    }

    .accordion__panel {
      padding: 16px;
      animation: fadein 0.35s ease-in;
      border-left: 1px solid ${(props) => props.theme.gray12};
      border-right: 1px solid ${(props) => props.theme.gray12};
      border-bottom: 1px solid ${(props) => props.theme.gray12};
      border-radius: 0px 0px 6px 6px;
    }

    .accordion__item {
      .DropdownButton {
        height: 40px;
        width: 40px;
        left: calc(100% - 50px);

        .Button {
          height: 40px;
          width: 40px;
          border: none;
          margin-top: -48px;
          border-radius: 6px;

          &:hover {
            border: 1px solid ${(props) => props.theme.primaryColor}20;
          }
        }

        .Button:not(:hover) {
          color: ${(props) => props.theme.gray2};
        }

        .dropdown-menu {
          margin-top: 5px;
          border: none;
          border-radius: 6px;
          box-shadow: 0px 0px 8px 0px ${(props) => props.theme.black}20;

          &.down {
            left: -114px;
          }

          .dropdown-item {
            height: 40px;
            margin: 8px;
            font-family: Roboto;
            font-size: 16px;
            color: ${(props) => props.theme.gray1};

            &:hover {
              color: ${(props) => props.theme.primaryColor};
              background-color: ${(props) => props.theme.primaryColor}10;
              border-radius: 6px;
            }
          }
        }
      }
    }

    @keyframes fadein {
      0% {
        opacity: 0;
      }

      100% {
        opacity: 1;
      }
    }

    @media (min-width: 320px) and (max-width: 480px) {
      .accordion {
        min-width: auto;
      }
    }
  }
`;
