import React, { useState } from 'react';

import {
  Box,
  Button,
  Checkbox,
  Modal,
  Text,
} from '@agendaedu/ae-web-components';
import { Props } from './types';
import { useTranslation } from 'react-i18next';
import DiaryActions from 'store/dailySummaries/actions';
import { useDispatch } from 'react-redux';

export const InfoConfirmModal = ({ isOpen, onClose }: Props): JSX.Element => {
  const [neverShowMessage, setNeverShowMessage] = useState(false);
  const { t } = useTranslation('diary');

  const { requestHideDiaryInfo } = DiaryActions;
  const dispatch = useDispatch();

  const onConfirm = () => {
    onClose();
    if (neverShowMessage) {
      dispatch(requestHideDiaryInfo());
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      title={t('form.info_confirm_modal.title')}
      onClose={onClose}
    >
      <Box width="100%" mt="sm">
        <Text
          data-testid="text-content-modal"
          variant="body-regular-16"
          mx="xs3"
          maxWidth={420}
          lineHeight="lg"
        >
          {t('form.info_confirm_modal.content')}
        </Text>

        <Box mx="xs3" mt="md">
          <Checkbox
            checked={neverShowMessage}
            onChange={() => setNeverShowMessage((prev) => !prev)}
          >
            <Text variant="label-regular-16" color="neutral.gray1" mb={0}>
              {t('form.info_confirm_modal.confirm_hide_message')}
            </Text>
          </Checkbox>
        </Box>

        <Box display="flex" justifyContent="flex-end" mt="lg">
          <Button
            id="confirm-button"
            testId="confirm-button"
            onClick={onConfirm}
          >
            {t('form.info_confirm_modal.confirm')}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
