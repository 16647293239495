import styled from 'styled-components';
import Tooltip from 'components/Tooltip';

export const Field = styled.div`
  display: flex;
  margin-bottom: 24px;
  flex-direction: column;
  padding-right: 15px;
  padding-left: 15px;
  min-height: 1px;
  width: 100%;

  .Label {
    margin-bottom: 12px;
  }

  .TextField input {
    height: 40px;
    border-radius: 6px;
  }
`;

export const CustomLabel = styled.div`
  display: flex;
  align-items: center;
  > span {
    margin-right: 2px !important;
  }
`;

export const CustomTooltip = styled(Tooltip)`
  .tooltip-div {
    position: relative;
    width: 16px;
    height: 16px;
    font-weight: 400;
  }
`;

export const UserNameTooltip = styled(Tooltip)`
  .tooltip-div {
    position: relative;
    width: 16px;
    height: 16px;
  }

  .popup-content {
    text-align: left !important;
  }
`;

export const DoubleInputsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-basis: 100%;
  @media (max-width: 576px) {
    flex-direction: column;
  }
`;

export const ModalTitle = styled.h3`
  font-family: 'Quicksand', sans-serif;
  font-size: 20px;
  font-weight: 700;
  color: #333333;
  margin: 0;
`;
