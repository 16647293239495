import React from 'react';
import * as S from './styles';
import { NegotiationCardProps } from './types';

export const NegotiationCard = ({ children }: NegotiationCardProps) => {
  return (
    <>
      <S.CardContainer className="NegotiationCard">{children}</S.CardContainer>
    </>
  );
};
