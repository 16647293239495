import styled, { css } from 'styled-components';
import { ThemeProps } from 'config/themes/types';

export const DetailTabsWrapper = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    .tabs-header .tab-style span {
      padding: 0 ${space.lg};
    }
  `}
`;

export const DetailTagWrapper = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    flex-wrap: wrap;
    width: fit-content;
    gap: ${space.sm};
  `}
`;
