const actions = {
  SET_ERROR: 'SET_ERROR/studentProfiles',
  FETCH_FORM_META: 'FETCH_FORM_META/studentProfiles',
  FETCH_FORM_META_SUCCESS: 'FETCH_FORM_META_SUCCESS/studentProfiles',
  SET_STUDENT_PROFILE: 'SET_STUDENT_PROFILE/studentProfiles',
  SET_STUDENT_PROFILE_SUCCESS: 'SET_STUDENT_PROFILE_SUCCESS/studentProfiles',
  SET_REVIEWER_STUDENT_PROFILE: 'SET_REVIEWER_STUDENT_PROFILE/studentProfiles',
  SET_REVIEWER_STUDENT_PROFILE_SUCCESS:
    'SET_REVIEWER_STUDENT_PROFILE_SUCCESS/studentProfiles',
  SET_RECOVERY_STUDENT_PROFILE: 'SET_RECOVERY_STUDENT_PROFILE/studentProfiles',
  SET_RECOVERY_STUDENT_PROFILE_SUCCESS:
    'SET_RECOVERY_STUDENT_PROFILE_SUCCESS/studentProfiles',
  CREATE_STUDENT_PROFILE: 'CREATE_STUDENT_PROFILE/studentProfiles',
  CREATE_STUDENT_PROFILE_SUCCESS:
    'CREATE_STUDENT_PROFILE_SUCCESS/studentProfiles',
  UPDATE_STUDENT_PROFILE: 'UPDATE_STUDENT_PROFILE/studentProfiles',
  UPDATE_STUDENT_PROFILE_SUCCESS:
    'UPDATE_STUDENT_PROFILE_SUCCESS/studentProfiles',
  APPROVE_STUDENT_PROFILE: 'APPROVE_STUDENT_PROFILE/studentProfiles',
  APPROVE_STUDENT_PROFILE_SUCCESS:
    'APPROVE_STUDENT_PROFILE_SUCCESS/studentProfiles',
  RECOVER_STUDENT_PROFILE: 'RECOVER_STUDENT_PROFILE/studentProfiles',
  RECOVER_STUDENT_PROFILE_SUCCESS:
    'RECOVER_STUDENT_PROFILE_SUCCESS/studentProfiles',
  REMOVE_STUDENT_PROFILE: 'REMOVE_STUDENT_PROFILE/studentProfiles',
  REMOVE_STUDENT_PROFILE_SUCCESS:
    'REMOVE_STUDENT_PROFILE_SUCCESS/studentProfiles',
  fetchFormMeta: () => ({
    type: actions.FETCH_FORM_META,
  }),
  setStudentProfile: (id) => ({
    type: actions.SET_STUDENT_PROFILE,
    id,
  }),
  setReviewerStudentProfile: (id) => ({
    type: actions.SET_REVIEWER_STUDENT_PROFILE,
    id,
  }),
  setRecoveryStudentProfile: (id) => ({
    type: actions.SET_RECOVERY_STUDENT_PROFILE,
    id,
  }),
  createStudentProfile: (params) => ({
    type: actions.CREATE_STUDENT_PROFILE,
    params,
  }),
  updateStudentProfile: (id, params) => ({
    type: actions.UPDATE_STUDENT_PROFILE,
    id,
    params,
  }),
  approveStudentProfile: (id, params) => ({
    type: actions.APPROVE_STUDENT_PROFILE,
    id,
    params,
  }),
  recoverStudentProfile: (id, params) => ({
    type: actions.RECOVER_STUDENT_PROFILE,
    id,
    params,
  }),
  removeStudentProfile: (id) => ({
    type: actions.REMOVE_STUDENT_PROFILE,
    id,
  }),
};

export default actions;
