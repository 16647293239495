import React from 'react';
import { DefaultThemeProps, theme } from '@agendaedu/ae-web-components';

import Skeleton from 'components/Skeleton';

import * as S from './styles';

export const CategoriesListSkeleton = () => {
  const defaultTheme: DefaultThemeProps = theme;

  const {
    colors: { neutral },
    border: { radius },
  } = defaultTheme;

  return (
    <S.SkeletonWrapper>
      <S.ContentSkeleton>
        <S.LabelSkeleton>
          <div id="info-skeleton">
            <Skeleton
              width={150}
              height={20}
              borderRadius={radius.md}
              primaryColor={neutral.gray3}
            />
            <Skeleton
              width={400}
              height={24}
              borderRadius={radius.md}
              primaryColor={neutral.gray3}
            />
          </div>
          <Skeleton
            width={160}
            height={40}
            borderRadius={radius.md}
            primaryColor={neutral.gray2}
          />
        </S.LabelSkeleton>

        <S.CardSkeleton>
          {Array.from(Array(5).keys()).map((n) => (
            <Skeleton
              key={`skeleton-item-${n}`}
              width="100%"
              height={88}
              primaryColor={neutral.gray6}
              borderRadius={radius.md}
              isFullWidth
            />
          ))}
        </S.CardSkeleton>
      </S.ContentSkeleton>
    </S.SkeletonWrapper>
  );
};
