import { DefaultThemeProps, Icon } from '@agendaedu/ae-web-components';
import { ThemeProps } from 'config/themes/types';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme: { space, border, colors } }: ThemeProps) => css`
    display: flex;
    flex-direction: column;
    border-bottom: ${border.width.sm} ${border.style.solid}
      ${colors.neutral.gray4};

    > button {
      span {
        text-wrap: nowrap;
      }
    }
  `}
`;

type AvatarButtonProps = {
  isReadOnly: boolean;
};

export const InformationWrapper = styled.div<AvatarButtonProps>`
  ${({ theme: { space }, isReadOnly }) => css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    gap: ${space.xs};

    ${!isReadOnly &&
    css`
      padding: ${space.sm} ${space.lg};
    `}
  `}
`;

export const AvatarButton = styled.div<AvatarButtonProps>`
  ${({ isReadOnly }) => css`
    display: flex;
    cursor: ${isReadOnly ? 'auto' : 'pointer'};
  `}
`;

export const AvatarWrapper = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    flex-direction: row;
    gap: ${space.xs};
    align-items: center;
  `}
`;

export const MessageInformationsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MessageInformationsRow = styled.div`
  ${({ theme: { space, breakpoints } }: ThemeProps) => css`
    display: flex;
    flex-direction: row;
    gap: ${space.xs};

    @media screen and (max-width: ${breakpoints.desktopXL}) {
      flex-direction: column;
      gap: ${space.xs2};
    }
  `}
`;

export const BackChannelIcon = styled(Icon)`
  ${({ theme: { colors, breakpoints } }: ThemeProps) => css`
    fill: {
      color: ${colors.brand.primary.default};
    }
    cursor: pointer;

    @media screen and (min-width: ${breakpoints.desktopXL}) {
      flex-direction: column;
      display: none;
    }
  `}
`;

export const ActionButtonWrapper = styled.div`
  ${({ theme: { space, breakpoints, border } }: ThemeProps) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${space.xs};

    @media screen and (max-width: ${breakpoints.mobileSM}) {
      flex-direction: column;
      align-items: flex-end;
    }
  `}
`;

export const SubjectAndIdWrapper = styled.div`
  ${({ theme: { space, border, colors } }: ThemeProps) => css`
    display: flex;
    border-top: ${border.style.solid} ${border.width.sm} ${colors.neutral.gray4};
    padding: ${space.sm} ${space.lg};
    gap: ${space.xs3};
  `}
`;
