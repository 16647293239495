import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Avatar, Badge, Button } from '@agendaedu/ae-web-components';
import withAppContext from 'core/hoc/withAppContext';

import actionOmniChannels from 'store/messages/omniChannel/actions';
import { OmniChannelState } from 'store/messages/omniChannel/types';

import * as S from './styles';

import { BOTTOM } from 'components/Popover';
import Tooltip, { TOP_LEFT } from 'components/Tooltip';

import { ChannelCardProps } from './types';

const ChannelCard = ({
  id,
  channel,
  draggingCard,
  isDragging,
  appContext: {
    dataArea,
    policies: { can_update_channel: canUpdateChannel },
  },
}: ChannelCardProps): JSX.Element => {
  const { t } = useTranslation(['messages']);

  const dispatch = useDispatch();

  const {
    icon_url: iconUrl,
    kind,
    name,
    pending_action_count: pendingActionCount,
  } = channel.attributes;

  const {
    setCurrentChannel,
    toggleDeleteChannelModal,
    toggleChatIsSearchTab,
    socketLeaveChatRoomRequest,
  } = actionOmniChannels;

  const { activeChannel, activeChat, chatIsSearchTab } = useSelector(
    (state: OmniChannelState) => state.omniChannel
  );

  const selectedChannel = channel.id === activeChannel?.id;
  const isTicketChannel = channel.attributes.kind === 'ticket';

  const handleSocketLeaveRoom = useCallback(() => {
    activeChat?.id && dispatch(socketLeaveChatRoomRequest(activeChat.id));
  }, [activeChat, dispatch, socketLeaveChatRoomRequest]);

  const handleDeleteChannel = useCallback(() => {
    if (isTicketChannel && pendingActionCount > 0)
      return toast.info(t('omni_channel.channel.delete_channel_alert_text'));

    dispatch(toggleDeleteChannelModal());
  }, [
    dispatch,
    isTicketChannel,
    pendingActionCount,
    t,
    toggleDeleteChannelModal,
  ]);

  const handleSetActiveChannel = useCallback(
    (params) => {
      handleSocketLeaveRoom();
      chatIsSearchTab && dispatch(toggleChatIsSearchTab());
      dispatch(setCurrentChannel(params));
    },
    [
      setCurrentChannel,
      dispatch,
      chatIsSearchTab,
      handleSocketLeaveRoom,
      toggleChatIsSearchTab,
    ]
  );

  const renderAvatar = {
    family: (
      <S.AvatarWrapper>
        <Avatar avatarUrl={iconUrl} size="xs" />
      </S.AvatarWrapper>
    ),
    private: (
      <S.AvatarWrapper>
        <Avatar avatarUrl={iconUrl} size="xs" />
      </S.AvatarWrapper>
    ),
    ticket: (
      <Tooltip
        content={t('omni_channel.channel.ticket_channel_text')}
        position={TOP_LEFT}
      >
        <S.AvatarWrapper>
          <Avatar
            avatarUrl={iconUrl}
            data-testid="headphone-icon"
            iconName="headphone-2"
            size="xs"
          />
        </S.AvatarWrapper>
      </Tooltip>
    ),
  };

  return (
    <S.ChannelCardWrapper
      id={id}
      canUpdateChannel={canUpdateChannel}
      data-testid="channel-card-wrapper"
      isSelected={selectedChannel}
      isDragging={isDragging}
      draggingCard={draggingCard}
      onClick={() =>
        handleSetActiveChannel({
          iconUrl,
          id: channel.id,
          kind,
          name,
          attendingHours: channel.attributes.attendingHours,
          attendingClosed: channel.attributes.attendingClosed,
        })
      }
    >
      {renderAvatar[kind]}

      <S.CardLabelWrapper id="card-label">
        <S.CardLabel
          isSelected={selectedChannel}
          variant={
            (selectedChannel || !!pendingActionCount) && !isDragging
              ? 'body-bold-14'
              : 'body-regular-14'
          }
        >
          {name}
        </S.CardLabel>
      </S.CardLabelWrapper>
      {!!pendingActionCount && (
        <Badge data-testid="pending-badges" value={pendingActionCount} />
      )}

      {!isDragging && (
        <S.CardActionsWrapper id="actions-wrapper">
          <Tooltip
            content={t('omni_channel.channel.edit_channel_text')}
            position={BOTTOM}
          >
            <Link
              to={
                isTicketChannel
                  ? `/${dataArea}/messages/channels/${channel.id}/tickets/edit`
                  : `/${dataArea}/messages/channels/${channel.id}/edit`
              }
            >
              <Button
                id="edit-channel-button"
                isOnlyIcon
                icon="pencil"
                size="xs"
                variant="secondary"
              />
            </Link>
          </Tooltip>
          <Tooltip
            content={t('omni_channel.channel.delete_channel_text')}
            position={BOTTOM}
          >
            <Button
              id="delete-channel-button"
              icon="trash-bin"
              isOnlyIcon
              size="xs"
              onClick={handleDeleteChannel}
              variant="secondary"
            />
          </Tooltip>
        </S.CardActionsWrapper>
      )}
    </S.ChannelCardWrapper>
  );
};

export default withAppContext(ChannelCard);
