export const canEditRecurrentBill = (
  status: string,
  isFidc: boolean,
  isWalletGuaranteedMonthlyFee: boolean,
  inBatch = false
) => {
  const orderStatus = isWalletGuaranteedMonthlyFee
    ? isFidc
      ? inBatch
        ? ['pending_school_approval']
        : ['pending_school_approval', 'delayed']
      : inBatch
      ? ['pending']
      : ['delayed', 'pending']
    : ['pending', 'delayed'];

  return orderStatus.includes(status);
};
