import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from './types';
import withAppContext from 'core/hoc/withAppContext';
import reduxProvider from 'core/hoc/reduxProvider';
import OnboardingEmptyState from 'components/EmptyState/Onboarding';
import FormContainer from 'components/Form/FormContainer';
import InviteField from 'components/Invite/InviteField';
import { trackEvent } from 'config/amplitude';
import inviteEmptyStateActions from 'store/invite/emptyState/actions';
import { EMPTY_STATES_URL } from 'core/constants';
import { ResponsiblesEmptyStateScreenProps } from './types';
import { useTranslation } from 'react-i18next';
import * as S from './styles';

const ResponsiblesEmptyStateScreen = ({
  appContext,
}: ResponsiblesEmptyStateScreenProps) => {
  const { form, isLoading, error } = useSelector(
    (state: RootState) => state.inviteEmptyState
  );
  const { generateInviteUrl } = inviteEmptyStateActions;
  const dispatch = useDispatch();

  const { t } = useTranslation(['empty_state']);

  const { can_show_empty_state_responsibles: canShowEmptyState } =
    appContext.policies;

  const handleGenerateInviteUrl = useCallback(() => {
    dispatch(generateInviteUrl('responsible'));
  }, [dispatch, generateInviteUrl]);

  useEffect(() => {
    trackEvent('plg-empty-state-responsibles-view');
    handleGenerateInviteUrl();
  }, [handleGenerateInviteUrl]);

  const renderEmptyStateField = () => (
    <FormContainer initialAttributes={form.invite_link ? form : {}}>
      <InviteField
        attributeName="invite_link"
        disabled={isLoading || error}
        error={error}
        beforeCopy={() =>
          trackEvent('plg-responsibles-invite-link-copied', {
            actionTarget: 'copy',
          })
        }
      />
    </FormContainer>
  );

  return (
    <S.Wrapper
      id="ResponsiblesEmptyState"
      className="ResponsiblesEmptyStateScreen"
      canShow={canShowEmptyState}
    >
      <OnboardingEmptyState
        imgUrl={EMPTY_STATES_URL.responsiblesUrl}
        title={t('responsibles.title')}
        text={t('responsibles.text')}
        buttons={renderEmptyStateField()}
      />
    </S.Wrapper>
  );
};

export default reduxProvider(withAppContext(ResponsiblesEmptyStateScreen));
