import React, { Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import HeadquarterContext from 'core/contexts/Headquarter';
import FormFullScreen from 'components/FormFullScreen';
import HeadquarterInformationsTab from 'components/Headquarters/Form/HeadquarterInformationsTab';
import { initialForm } from 'store/headquarters';
import HeadquartersActions from 'store/headquarters/actions';
import SchoolServices from 'core/services/School';

const contentModalText = `
  O cadastro da unidade será cancelado e as
  informações preenchidas serão descartadas.
  Tem certeza que deseja descartar?
`;

const HeadquartersNew = ({ history }) => {
  const dispatch = useDispatch();
  const { addHeadquarter } = HeadquartersActions;
  const dataArea = useSelector((state) => state.root.dataArea);
  const { formMeta, isSubmitting } = useSelector((state) => state.headquarters);
  const schoolServices = new SchoolServices(dataArea);

  const validateLegacyId = async (formContext) => {
    const { form, removeErrorOnAttribute, addErrorOnAttribute } = formContext;
    try {
      await schoolServices.validateLegacyId(form.legacy_id, 'headquarters');
      removeErrorOnAttribute('legacy_id');
    } catch {
      addErrorOnAttribute(
        'legacy_id',
        'Identificador de registro já está em uso'
      );
    }
  };

  const onSubmit = async (formContext) => {
    const { form, hasErrorOnAttribute } = formContext;

    if (form.legacy_id) await validateLegacyId(formContext);

    const hasErrorOnLegacyId = hasErrorOnAttribute('legacy_id');
    const isFormValid = !hasErrorOnLegacyId;
    isFormValid && dispatch(addHeadquarter(form, history));
  };

  return (
    <Fragment>
      <HeadquarterContext.Provider>
        <FormFullScreen
          action="new"
          titlePage="Nova unidade"
          titleModal="Descartar preenchimento"
          contentModal={contentModalText}
          toggleModalWithFormUpdates
          onSubmit={onSubmit}
          form={initialForm()}
          formMeta={formMeta}
          isSubmitting={isSubmitting}
          steps={[HeadquarterInformationsTab]}
          backTo={`/${dataArea}/headquarters`}
        />
      </HeadquarterContext.Provider>
    </Fragment>
  );
};

HeadquartersNew.propTypes = {
  history: PropTypes.object,
};

export default withRouter(HeadquartersNew);
