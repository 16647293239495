import React from 'react';

import { Box, Text } from '@agendaedu/ae-web-components';
import { useTranslation } from 'react-i18next';

const emptyImageUrl = 'https://static.agendaedu.com/survey/app_error.png';

import * as S from './styles';

export const HandoutTemplateEmptyState: React.FC = () => {
  const { t } = useTranslation(['handouts']);

  return (
    <Box
      data-testid="handout-templates-empty"
      display={'flex'}
      flexDirection={'column'}
      width={'100%'}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <S.EmptyStateImage src={emptyImageUrl} />
      <Text
        lineHeight="lg"
        maxWidth={'316px'}
        mt={24}
        textAlign={'center'}
        variant="subtitle-medium-16"
        color={'neutral.black'}
      >
        {t('templates.empty_state_title')}
      </Text>
    </Box>
  );
};
