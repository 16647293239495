import React, { useContext } from 'react';

import DailySummariesContext from 'core/contexts/DailySummaries';

import Field from 'components/Form/Field';
import AgendaIcon from 'components/AgendaIcon';

import Card from '../';
import * as S from './styles';

const Evacuation = () => {
  const {
    form: { evacuations },
    handleForm,
    isDisabled,
    hasStudentSelected,
  } = useContext(DailySummariesContext);
  const { appearances, notEvacuated } = evacuations;

  const PLACEHOLDER_TEXT = 'Selecionar';

  const OPTIONS = [
    {
      label: 'Selecionar',
      value: '',
    },
    {
      value: 'solid',
      label: 'Sólida',
    },
    {
      value: 'liquid',
      label: 'Líquida',
    },
    {
      value: 'pasty',
      label: 'Pastosa',
    },
  ];

  const removeEvacuation = (id) =>
    appearances.length > 1 &&
    handleForm('evacuations', {
      notEvacuated,
      appearances: appearances.filter((appearence) => appearence.id !== id),
    });

  const toggleNotEvacuated = () => {
    handleForm('evacuations', {
      notEvacuated: !notEvacuated,
      appearances,
    });
  };

  const addAppearence = () =>
    handleForm('evacuations', {
      notEvacuated,
      appearances: [
        ...appearances,
        { id: Math.random().toString(36).substring(7), evacuation: '' },
      ],
    });

  const onChange = (index, value) => {
    appearances[index].evacuation = value;

    handleForm('evacuations', {
      notEvacuated,
      appearances,
    });
  };

  const handlePlaceholder = (value) => {
    if (!isDisabled || !value) return PLACEHOLDER_TEXT;
    return OPTIONS.find((option) => option.value == value)?.label;
  };

  const disableCard = !hasStudentSelected || isDisabled;

  return (
    <Card
      title="Evacuação"
      checkboxLabel="Não evacuou"
      isChecked={notEvacuated}
      onChangeCheckbox={toggleNotEvacuated}
      disabled={disableCard}
    >
      <S.Wrapper>
        <S.Content>
          {appearances.map(({ id, evacuation }, index) => (
            <S.FieldWithLabel key={id}>
              <S.Label>Aparência</S.Label>
              <S.InputContainer key={id} disabled={disableCard}>
                <Field
                  disabled={disableCard}
                  type="selectWithIcon"
                  placeholder={handlePlaceholder(evacuation)}
                  value={evacuation}
                  defaultValue=""
                  options={OPTIONS}
                  withArrowSelect
                  onChange={(e) => onChange(index, e.value)}
                />
                <button
                  variation="secondary"
                  onClick={() => removeEvacuation(id)}
                  disabled={appearances.length === 1 || disableCard}
                >
                  <AgendaIcon name="trash" size="medium" />
                </button>
              </S.InputContainer>
            </S.FieldWithLabel>
          ))}
        </S.Content>
        {!disableCard && (
          <S.AddButon
            variation="secondary"
            onClick={addAppearence}
            id="add-button-evacuation"
          >
            <AgendaIcon name="plus" size="small" />
          </S.AddButon>
        )}
      </S.Wrapper>
    </Card>
  );
};

export default Evacuation;
