import styled, { css } from 'styled-components';

export const CoverImage = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: ${({ theme: { border } }) => border.radius.md};
  transition: 0.5s ease all;
`;

export const CoverWrapper = styled.div`
  overflow: hidden;
  position: relative;
  border-radius: ${({ theme: { border } }) => border.radius.md};
  width: 100%;
`;

export const AlbumWrapper = styled.div`
  aspect-ratio: 1/1;
  width: 100%;

  &:hover img {
    transform: scale(1.04);
  }
`;

export const InfoContainer = styled.div`
  ${css`
    &:before {
      border-radius: ${({ theme: { border } }) => border.radius.md};

      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.5) 0%,
        rgba(0, 0, 0, 0) 30%,
        rgba(0, 0, 0, 0) 35%,
        rgba(0, 0, 0, 0.9) 100%
      );
    }
  `}
`;
