import createModuleReducer from '../../storeConfig/createModuleReducer';
import actions from './actions';

export const INITIAL_STATE = {
  action: 'new',
};

export const formAction = (state, action) => ({
  ...state,
  action: action.params,
});

export const handleError = (state, action) => ({
  ...state,
  error: action.error,
});

const HANDLERS = {
  [actions.FORM_ACTION]: formAction,
  [actions.ERROR]: handleError,
};

const recurrentForms = createModuleReducer(INITIAL_STATE, HANDLERS);

export default recurrentForms;
