import React from 'react';
import { useSelector } from 'react-redux';

import Currency from 'components/Currency';
import HelpTooltip from 'components/Tooltip/variations/help';

import './style.scss';

type ItemProps = {
  key: string;
  status: string;
  product_name: string;
  discount_kind: string;
  discount_value: number;
  price: number;
  recurrent_product_id: number;
};

export type BillSummaryProps = {
  label?: string;
  discount?: number;
  price: number;
  billetTax: number;
  pixTax: number;
  allowedPaymentMethod: string[];
  items: ItemProps[];
  status?: string;
  penality?: {
    interest_price: number;
    fine_price: number;
  } | null;
};

type RecurrentProductProps = {
  id: number;
  attributes: {
    key: string;
    name: string;
  };
};

type RootState = {
  recurrentProducts: {
    recurrentProducts: RecurrentProductProps[];
  };
  root: {
    policies: {
      guaranteed_monthly_fee_enabled: boolean;
    };
  };
};

const BillSummary = ({
  label = 'Resumo da cobrança',
  discount,
  price,
  billetTax,
  pixTax,
  allowedPaymentMethod,
  items,
  status,
  penality,
}: BillSummaryProps) => {
  const recurrentProducts = useSelector(
    (state: RootState) => state.recurrentProducts.recurrentProducts
  );

  const policies = useSelector((state: RootState) => state.root.policies);

  const hasDelayAndPenality = () => status === 'paid_late' && penality;

  const renderTaxMessages = () => {
    const isBillet = allowedPaymentMethod.includes('billet');
    const isPix = allowedPaymentMethod.includes('pix');
    const isCreditCard = allowedPaymentMethod.includes('credit_card');

    return (
      <>
        {isBillet && <p>Incluindo a taxa do boleto de R$ {billetTax}</p>}
        {isPix && <p>Incluindo a taxa do PIX de R$ {pixTax}</p>}
        {isCreditCard && <p>Incluindo a taxa dinâmica do cartão</p>}
      </>
    );
  };

  const renderProductName = (item: ItemProps) => {
    if (!item) return 'Sem produto';

    if (item.product_name) return item.product_name;

    const product = recurrentProducts.find(
      (product) => product.id == item.recurrent_product_id
    );

    if (product) return product.attributes.name;

    return 'Sem produto';
  };

  const renderProductDiscount = ({
    discount_kind,
    discount_value,
    price,
  }: ItemProps) => {
    if (discount_kind === 'percent') return price * (discount_value / 100);

    return discount_value;
  };

  const totalPrice = () => {
    if (hasDelayAndPenality()) {
      return price + penality.fine_price + penality.interest_price;
    } else if (discount > 0) {
      return price - discount;
    }

    return price;
  };

  return (
    <div className="BillSummary">
      <div className="summary-content">
        <div>
          <span>Produto</span>
        </div>
        <div>
          <span>
            Valor cobrado
            {!policies.guaranteed_monthly_fee_enabled && (
              <HelpTooltip text={renderTaxMessages()} />
            )}
          </span>
        </div>
      </div>
      <div className="summary-list">
        {items &&
          items.map((item) => (
            <div key={item.key} className="summary-item-wrapper">
              <div className="wrapper-item-value">
                <div className="summary-item">{renderProductName(item)}</div>
                <div className="summary-item">
                  <Currency value={item.price === 0.0 ? 0.0 : item.price} />
                </div>
              </div>
              {item.discount_value > 0 && (
                <div className="wrapper-item-value">
                  <div className="summary-item">
                    <div className="discount-item">Desconto</div>
                  </div>
                  <div className="summary-item">
                    <div className="discount-item">
                      - <Currency value={renderProductDiscount(item)} />
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}
      </div>
      {hasDelayAndPenality() && (
        <div className="fines-interest-and-discount">
          <div className="fines">
            <p>Multa</p>
            <Currency value={penality.fine_price} />
          </div>
          <div className="interest">
            <p>Juros</p>
            <Currency value={penality.interest_price} />
          </div>
        </div>
      )}
      {discount > 0 && (
        <div className="fines-interest-and-discount">
          <div className="discount">
            <p>Desconto</p>
            <div className="discount-currency">
              <span>-</span>
              <Currency value={discount} />
            </div>
          </div>
        </div>
      )}
      <div className="summary-total">
        <div>Total da cobrança</div>
        <div>
          <Currency value={totalPrice()} />
        </div>
      </div>
    </div>
  );
};

export default BillSummary;
