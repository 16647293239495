import React, { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import tabify from 'core/hoc/tabify';
import totalPriceItems from 'core/utils/totalPriceItems';
import { formatISODate } from 'core/utils/date';
import {
  renderPenalityMessage,
  renderDiscountMessage,
} from 'core/utils/edupay/functions';

import Loader from 'components/Loader';
import LabeledContainer from 'components/LabeledContainer';
import UserDisplay from 'components/UserDisplay';
import BillSummary from 'components/Payments/Recurrent/BillSummary';
import Button from 'components/Button';
import ColoredText from 'components/ColoredText';
import Popover, { TOP } from 'components/Popover';
import Toast from 'components/Toast';
import OutlineBox from 'components/OutlineBox';
import ModalContainer from 'components/Modal/ModalContainer';
import ModalTitle from 'components/Modal/ModalTitle';
import ButtonRow from 'components/ButtonRow';

import actionRecurrentPlans from 'store/edupay/recurrentPlans/actions';
import actionRecurrentBills from 'store/edupay/recurrentBills/actions';

import recurrentPlanStatus from '../../RecurrentPlanStatus';
import CancelModal from 'components/Payments/CancelModal';

import './style.scss';

const policeCanCancelPopover = `Você não tem permissão para cancelar esse plano.
  Em caso de dúvida, consulte algum profissional com perfil
  Master, na sua escola.`;

const canCancelPopover = `O plano não pode ser cancelado,
  pois a última cobrança está em aberto.`;

const InformationTab = ({
  match: {
    params: { id },
  },
}) => {
  const dispatch = useDispatch();

  const [cloneModal, setCloneModal] = useState(false);

  const isLoadingPlan = useSelector(
    (state) => state.recurrentPlans.isLoadingPlan
  );
  const cancelModal = useSelector((state) => state.recurrentPlans.cancelModal);
  const policies = useSelector((state) => state.root.policies);
  const currentPlan = useSelector((state) => state.recurrentPlans.current);
  const schoolUser = useSelector((state) => state.root.currentUser);
  const {
    attributes: { is_debug_user: isDebugUser },
  } = schoolUser;

  const { setPlanRequest, cancelPlanRequest, toggleCancel } =
    actionRecurrentPlans;

  const { unsetBill } = actionRecurrentBills;

  const getPlan = useCallback(() => {
    dispatch(setPlanRequest(id));
  }, [setPlanRequest]);

  const unsetCurrentBill = useCallback(() => {
    dispatch(unsetBill());
  }, [unsetBill]);

  useEffect(() => {
    getPlan();
    unsetCurrentBill();
  }, []);

  const toggleCancelModal = useCallback(() => {
    dispatch(toggleCancel());
  }, [toggleCancel]);

  const cancelPlan = () => {
    dispatch(cancelPlanRequest(currentPlan.recurrentPlan.id));
    toggleCancelModal();
  };

  const toggleCloneModal = () => setCloneModal(!cloneModal);

  const subTitle = () => (
    <p>
      O plano de recorrência do aluno(a){' '}
      <ColoredText variation="bold">
        {currentPlan.studentProfile.attributes.name}
      </ColoredText>{' '}
      será cancelado. Todas as cobranças que não foram enviadas serão canceladas
      e não serão enviadas ao responsável do aluno(a).
    </p>
  );

  const renderEditButton = () => {
    if (currentPlan && currentPlan.recurrentPlan.status === 'canceled') {
      return (
        <Popover
          content={
            <div>
              Não é possível editar um plano <br /> que foi cancelado
            </div>
          }
          position={TOP}
        >
          <Button type="submit" disabled>
            Editar
          </Button>
        </Popover>
      );
    }

    return (
      <Link
        to={`/schools/recurrent/recurrent_plans/${currentPlan.recurrentPlan.id}/edit`}
      >
        <Button
          variation="primary"
          disabled={!policies.can_edit_after_approved}
        >
          Editar plano
        </Button>
      </Link>
    );
  };

  const renderCloneButton = () => {
    if (
      !(policies.can_create_school_product && policies.can_edit_after_approved)
    ) {
      return;
    }

    return (
      <Button
        variation="secondary"
        onClick={toggleCloneModal}
        className="cloneButton"
      >
        Duplicar
      </Button>
    );
  };

  const renderDueDay = () => {
    if (!(currentPlan && currentPlan.recurrentPlan)) return;

    return currentPlan.recurrentPlan.due_day === -1
      ? 'Todo último dia do mês'
      : `Todo dia ${currentPlan.recurrentPlan.due_day} do mês`;
  };

  const renderSummary = () => {
    const items = currentPlan.recurrentPlan.recurrent_items.data.map(
      ({ attributes }) => ({
        discount_kind: attributes.discount_kind,
        discount_value:
          attributes.discount_kind === 'percent'
            ? attributes.discount_percent
            : attributes.discount_price_cents,
        recurrent_product_id: parseInt(attributes.product_id, 10),
        price: attributes.price_cents,
        product_name: attributes.product_name,
        status: attributes.product_status,
        key: Math.random().toString(36).substring(7),
      })
    );

    return items;
  };

  const renderButtonOnBottom = () => {
    if (
      !isDebugUser &&
      currentPlan.recurrentPlan.has_any_with_wallet_securitizer
    ) {
      return;
    }

    return (
      <>
        <div className="action-area">
          {renderEditButton()}
          {renderCloneButton()}
          {currentPlan && currentPlan.recurrentPlan.status !== 'canceled' && (
            <React.Fragment>
              {policies.can_cancel_school_product ? (
                <React.Fragment>
                  {currentPlan.recurrentPlan.can_cancel ? (
                    <Button variation="danger" onClick={toggleCancelModal}>
                      Cancelar recorrência
                    </Button>
                  ) : (
                    <Popover content={canCancelPopover} position={TOP}>
                      <Button type="submit" disabled>
                        Cancelar recorrência
                      </Button>
                    </Popover>
                  )}
                </React.Fragment>
              ) : (
                <Popover content={policeCanCancelPopover} position={TOP}>
                  <Button type="submit" disabled>
                    Cancelar recorrência
                  </Button>
                </Popover>
              )}
            </React.Fragment>
          )}
        </div>
        <CancelModal
          isOpen={cancelModal}
          title="Cancelar plano de recorrência"
          subTitle={subTitle()}
          toggleModal={toggleCancelModal}
          onCancel={cancelPlan}
        />
        <ModalContainer
          isOpen={cloneModal}
          toggleModal={toggleCloneModal}
          className="CloneRecurrentPlanModal"
        >
          <ModalTitle>Duplicar plano</ModalTitle>
          <p>
            Será criado um novo plano com as mesmas informações.
            <br /> Tem certeza que deseja duplicar?
          </p>
          <ButtonRow>
            <Button variation="secondary" onClick={toggleCloneModal}>
              Cancelar
            </Button>
            <Link
              to={`/schools/recurrent/recurrent_plans/${currentPlan.recurrentPlan.id}/clone_charge`}
            >
              <Button>Duplicar</Button>
            </Link>
          </ButtonRow>
        </ModalContainer>
      </>
    );
  };

  return (
    <div className="RecurrentPlanInformationTab">
      {isLoadingPlan && !currentPlan ? (
        <Loader />
      ) : (
        <React.Fragment>
          <div className="information-content">
            <div className="left-side">
              <OutlineBox
                variation={
                  recurrentPlanStatus[currentPlan.recurrentPlan.status]
                    .variation
                }
                className="planStatus"
              >
                {recurrentPlanStatus[currentPlan.recurrentPlan.status].text}
              </OutlineBox>
              <UserDisplay
                user={currentPlan.studentProfile}
                classroomsNames={
                  currentPlan.studentProfile.attributes.classroom_names
                }
                size="x-large"
              />
              <LabeledContainer title="Título do plano">
                {currentPlan.recurrentPlan.title}
              </LabeledContainer>
              <LabeledContainer title="Frequência da cobrança">
                Mensal
              </LabeledContainer>
              <LabeledContainer title="Quantidade de cobranças">
                {currentPlan.recurrentPlan.bills_quantity}
              </LabeledContainer>
              <LabeledContainer title="Vencimento da cobrança atual">
                {currentPlan.attributes.due_date}
              </LabeledContainer>
              <LabeledContainer title="Vencimento das demais cobranças">
                {renderDueDay()}
              </LabeledContainer>
              <LabeledContainer title="Dia de envio da cobrança">
                {currentPlan.recurrentPlan.bill_release_day} dias antes do
                vencimento
              </LabeledContainer>
              {currentPlan.recurrentPlan.current_bill.data.attributes.order.data
                .attributes.negotiated_at && (
                <>
                  <LabeledContainer title="Negociação">
                    {formatISODate(
                      currentPlan.recurrentPlan.current_bill.data.attributes
                        .order.data.attributes.negotiated_at
                    )}
                  </LabeledContainer>
                  <LabeledContainer title="Observação">
                    {
                      currentPlan.recurrentPlan.current_bill.data.attributes
                        .order.data.attributes.details
                    }
                  </LabeledContainer>
                </>
              )}
              {currentPlan.recurrentPlan.has_penality && (
                <LabeledContainer title="Multas e Juros">
                  {renderPenalityMessage(
                    currentPlan.recurrentPlan.edupay_penality.data.attributes
                  )}
                </LabeledContainer>
              )}
              {currentPlan.recurrentPlan.has_discount && (
                <LabeledContainer title="Desconto pontualidade">
                  <div>
                    <ul>
                      {currentPlan.recurrentPlan.edupay_discounts.data.map(
                        ({ attributes }, index) => (
                          <React.Fragment key={`key-${index}`}>
                            {renderDiscountMessage(attributes)}
                          </React.Fragment>
                        )
                      )}
                    </ul>
                  </div>
                </LabeledContainer>
              )}
              <LabeledContainer title="Descrição">
                <p
                  dangerouslySetInnerHTML={{
                    __html: currentPlan.recurrentPlan.description,
                  }}
                />
              </LabeledContainer>
            </div>
            <div className="right-side">
              <LabeledContainer title="Valor da cobrança">
                <BillSummary
                  items={renderSummary()}
                  allowedPaymentMethod={
                    currentPlan.recurrentPlan.allowed_payment_method
                  }
                  price={totalPriceItems(
                    currentPlan.recurrentPlan.recurrent_items.data
                  )}
                  billetTax={currentPlan.attributes.billet_tax}
                  pixTax={currentPlan.attributes.pix_tax}
                />
              </LabeledContainer>
            </div>
          </div>
          {renderButtonOnBottom()}
          <Toast />
        </React.Fragment>
      )}
    </div>
  );
};

export default tabify({
  title: 'Informações',
  icon: 'info',
})(InformationTab);
