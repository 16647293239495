import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import EmptyState from 'components/EmptyState';
import PageTitle from 'components/PageTitle';
import PageSubTitle from 'components/PageSubTitle';
import Button from 'components/Button';
import { Button as AEButton } from '@agendaedu/ae-web-components';
import EnrollmentPlansList from 'components/Payments/Enrollment/EnrollmentPlansList';

import EnrollmentFilters from 'components/Payments/PaymentsContainer/EnrollmentFilters';

import EnrollmentPlansActions from 'store/edupay/enrollmentPlans/actions';

import orderByFlag from 'core/utils/orderByFlag';

import { IMAGES_PAYMENT_URL } from 'core/constants/index';
import {
  ENROLLMENT_STATUSES,
  ENROLLMENT_DOCUMENT_STATUSES,
} from 'core/constants/index';

import './style.scss';
import * as S from './styles';

const EnrollmentPlansListContainer = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['enrollments']);

  const { fetchEnrollmentPlansRequest, resetFilters } = EnrollmentPlansActions;

  const { hasEnrollmentPlans, isLoading, filters, activeFiltersCount } =
    useSelector((state) => state.enrollmentPlans);

  const { currentWallet } = useSelector((state) => state.wallets);

  const { policies } = useSelector((state) => state.root);

  const form = {
    range: null,
  };

  const formMeta = {
    select_options: {
      contract_status: orderByFlag(ENROLLMENT_DOCUMENT_STATUSES, 'name'),
      status: orderByFlag(ENROLLMENT_STATUSES, 'name'),
    },
    amount: activeFiltersCount,
  };

  const handleGetEnrollmentPlans = useCallback(
    (params) => {
      dispatch(fetchEnrollmentPlansRequest(params));
    },
    [dispatch, fetchEnrollmentPlansRequest]
  );

  const handleFilterRecurrentPlans = useCallback(
    (params) => {
      handleGetEnrollmentPlans({ ...filters, ...params });
    },
    [handleGetEnrollmentPlans, filters]
  );

  const handleResetFilters = useCallback(() => {
    dispatch(resetFilters());
  }, [dispatch, resetFilters]);

  useEffect(() => {
    if (currentWallet === null) {
      return;
    }

    handleResetFilters();
    handleGetEnrollmentPlans();
  }, [handleResetFilters, handleGetEnrollmentPlans, currentWallet]);

  return (
    <div className="EnrollmentPlanListContainer">
      <EnrollmentFilters
        form={form}
        formMeta={formMeta}
        handleFilterSubmit={handleFilterRecurrentPlans}
      />
      {!hasEnrollmentPlans && !isLoading ? (
        <div className="EnrollmentPlansListContainer">
          <S.NewModelContainer>
            <Link to="/schools/enrollment_plans/contract_templates">
              <AEButton variant="secondary">{t('common.models')}</AEButton>
            </Link>
          </S.NewModelContainer>
          <EmptyState
            imgUrl={IMAGES_PAYMENT_URL.onboardingUrl}
            message={
              <>
                <div className="vertically-aligned flex-column">
                  <PageTitle>
                    Boas-vindas a um novo jeito de fazer suas matrículas.
                    Simples e automático.
                  </PageTitle>
                  <PageSubTitle>
                    Envie cobranças via boleto ou cartão de crédito dentro do
                    app que faz parte da rotina da família.
                  </PageSubTitle>
                  {policies && policies.can_create_school_product && (
                    <Link to="/schools/enrollment_plans/new" className="action">
                      <Button className="btn-lg">Comece agora</Button>
                    </Link>
                  )}
                </div>
                <a
                  href="https://agendaedu.com/edupay/"
                  className="learn-more"
                  target="_blank"
                  rel="noreferrer"
                >
                  Saiba mais sobre o Edupay
                </a>
              </>
            }
          />
        </div>
      ) : (
        <EnrollmentPlansList />
      )}
    </div>
  );
};

export default EnrollmentPlansListContainer;
