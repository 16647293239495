import styled, { css } from 'styled-components';
import Tooltip from 'components/Tooltip';
import { ThemeProps } from 'config/themes/types';

export const CustomLabel = styled.div`
  ${({ theme: { typography, colors } }: ThemeProps) => css`
    display: flex;
    align-items: center;

    > span {
      margin-right: 2px !important;
      color: ${colors.neutral.gray1};
      ${typography.body.Regular14}
    }
  `}
`;

export const CustomTooltip = styled(Tooltip)`
  .tooltip-div {
    position: relative;
    width: 16px;
    height: 16px;
  }
`;
