import React from 'react';
import { Route, Switch } from 'react-router';
import { CookiesProvider } from 'react-cookie';
import composeFunctions from 'core/utils/composeFunctions';
import withAppContext from 'core/hoc/withAppContext';
import reduxProvider from 'core/hoc/reduxProvider';
import useWindowDimensions from 'core/hooks/useWindowDimensions';

import NewMessageChannelForm from 'screens/Messages/OmniChannelForms/NewMessageChannel';
import OmniChannelScreen from 'screens/Messages/OmniChannelScreen';

import { OmniChannelRoutesProps } from './types';
import EditMessageChannel from 'screens/Messages/OmniChannelForms/EditMessageChannel';
import NewMessageChatForm from 'screens/Messages/OmniChannelForms/NewMessageChatForm';
import NewTicketChannel from 'screens/Messages/OmniChannelForms/NewTicketChannel';
import EditTicketChannel from 'screens/Messages/OmniChannelForms/EditTicketChannel';
import NewTicketBySchoolForm from 'screens/Messages/OmniChannelForms/NewTicketBySchool';

import * as S from './styles';

const OmniChannelRoutes = ({
  appContext: { dataArea },
}: OmniChannelRoutesProps) => {
  const { screenHeight } = useWindowDimensions();

  return (
    <S.Wrapper height={screenHeight}>
      <CookiesProvider>
        <Switch>
          <Route
            exact
            path={`/${dataArea}/messages`}
            component={OmniChannelScreen}
          />
          <Route
            exact
            path={`/${dataArea}/messages/channels/:channelId/chats/:chatId/messages`}
            component={OmniChannelScreen}
          />
          <Route
            exact
            path={`/${dataArea}/messages/channels/new`}
            component={NewMessageChannelForm}
          />
          <Route
            exact
            path={`/${dataArea}/messages/channels/:channelId/edit`}
            component={EditMessageChannel}
          />
          <Route
            exact
            path={`/${dataArea}/messages/channels/:channelId/new_message`}
            component={NewMessageChatForm}
          />
          <Route
            exact
            path={`/${dataArea}/messages/channels/:channelId/new_ticket`}
            component={NewTicketBySchoolForm}
          />
          <Route
            exact
            path={`/${dataArea}/messages/channels/tickets/new`}
            component={NewTicketChannel}
          />
          <Route
            exact
            path={`/${dataArea}/messages/channels/:channelId/tickets/edit`}
            component={EditTicketChannel}
          />
        </Switch>
      </CookiesProvider>
    </S.Wrapper>
  );
};

export default composeFunctions(
  withAppContext,
  reduxProvider
)(OmniChannelRoutes);
