import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Avatar from 'components/Avatar';

import './style.scss';

class MessagesGroupAvatar extends PureComponent {
  static propTypes = {
    size: PropTypes.oneOf([
      'small',
      'medium',
      'large',
    ]),
    user: PropTypes.shape({
      attributes: PropTypes.shape({
        name_initials: PropTypes.string.isRequired,
      }),
    }).isRequired,
  }

  static defaultProps = {
    size: 'medium',
  }

  render() {
    const { size, user } = this.props;

    return (
      <span className={`MessagesGroupAvatar ${size}`}>
        <span className="circle" />
        <Avatar user={user} />
      </span>
    );
  }
}

export default MessagesGroupAvatar;
