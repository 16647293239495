import React from 'react';
import PropTypes from 'prop-types';

import Avatar from 'components/Avatar';
import ClassroomsList from 'components/ClassroomsList';

import './style.scss';

function UserDisplay({ user, classroomsNames, size }) {
  return (
    <div className={`UserDisplay ${size}`}>
      <Avatar user={user} size={size} />
      <div className="user-info">
        <span className={`name ${size}`}>
          {user.attributes.name}
        </span>
        {classroomsNames && (
          <ClassroomsList
            classroomsNames={classroomsNames}
          />
        )}
      </div>
    </div>
  );
}

UserDisplay.propTypes = {
  user: PropTypes.shape({
    attributes: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
  }).isRequired,
  withClassrooms: PropTypes.bool,
  size: PropTypes.string,
};

UserDisplay.defaultProps = {
  withClassrooms: false,
  size: 'small',
};

export default UserDisplay;
