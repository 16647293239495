import moment from 'moment';
import 'moment/locale/pt-br';
moment.locale('pt-br');
//TODO: Implement i18n in the near future to make information dynamic according to country/language

export const prevOrNextMonth = (type, month, year) => {
  const newDate = moment(`${year}-${month}`, 'YYYY-MM');
  if (type === 'prev') newDate.subtract(1, 'month');
  else if (type === 'next') newDate.add(1, 'month');
  return { newMonth: newDate.format('MM'), newYear: newDate.format('YYYY') };
};

export const getCurrentDate = () => {
  return moment();
};

export const calendarDateDescription = (date) => {
  return moment(date).calendar({
    sameElse: function (now) {
      const from = moment(this);
      now = moment(now);
      const dayDiff = now.diff(from, 'days');

      if (dayDiff <= 7) {
        return `[semana passada ${from.format('DD/MM/YYYY [às] HH:mm')}]`;
      }
      if (dayDiff <= 14) {
        return `[há duas semanas ${from.format('DD/MM/YYYY [às] HH:mm')}]`;
      }
      if (dayDiff < moment(from, 'YYYY-MM').daysInMonth()) {
        return `[neste mês ${from.format('DD/MM/YYYY [às] HH:mm')}]`;
      }
      if (dayDiff == moment(from, 'YYYY-MM').daysInMonth()) {
        return `[há um mês ${from.format('DD/MM/YYYY [às] HH:mm')}]`;
      } else {
        const monthDiff = now.diff(from, 'months', true);
        if (monthDiff < 2) {
          return `[mês passado ${from.format('DD/MM/YYYY [às] HH:mm')}]`;
        } else if (monthDiff >= 2) {
          if (Number(from.format('YYYY')) - Number(now.format('YYYY')) < 0) {
            return `[${from.format('DD/MM/YYYY [às] HH:mm')}]`;
          }
          return `[${from.format('MMMM [às] HH:mm')}]`;
        }
      }
      return `[${from.format('DD/MM/YYYY [às] HH:mm')}]`;
    },
  });
};

export default function formatDate(date, format = 'DD [de] MMMM[,] YYYY') {
  return moment(date, 'DD-MM-YYYY').format(format);
}

export function defaultIsOutsideRange(day) {
  const today = moment(new Date()).startOf('day');
  return day <= today;
}

export function formatISODate(date, format = 'DD/MM/YYYY') {
  return moment(date, 'YYYY-MM-DD').format(format);
}

export function getHoursAndMinutes(date) {
  return moment(date, 'YYYY-MM-DD HH:mm').format('HH:mm');
}

export function formatDatesCalendar(date) {
  return moment(date, 'DD/MM/YYYY HH:mm').calendar({
    sameDay: 'HH:mm',
    lastDay: '[Ontem às] HH:mm',
    sameElse: 'DD/MM/YYYY [às] HH:mm',
  });
}

export const formatRangeDate = (date) =>
  date ? moment(date).format('YYYY-MM-DD') : null;
