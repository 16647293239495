import styled, { css } from 'styled-components';
import { WrapperStyleProps } from './types';

export const Wrapper = styled.div<WrapperStyleProps>`
  ${({ isActiveChat, theme: { breakpoints, border, colors } }) => css`
    display: flex;
    flex-direction: column;
    width: 440px;
    background-color: ${colors.neutral.white};
    border-right: ${border.width.sm} ${border.style.solid}
      ${colors.neutral.gray4};

    @media screen and (max-width: ${breakpoints.desktopXL}) {
      ${isActiveChat
        ? css`
            display: none;
          `
        : css`
            width: 100%;
          `}
    }
  `}
`;
