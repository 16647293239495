import styled, { css } from 'styled-components';

export const SchoolProductInformationsWrapper = styled.div`
  .PageTitle {
    margin-bottom: 16px;
  }
`;

export const SchoolProductStatus = styled.div`
  margin-bottom: 12px;
`;

export const CancelationDescription = styled.p`
  margin-top: 5px;
`;

export const InformationBox = styled.div`
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 1 50%;
`;

export const RightSide = styled.div`
  display: flex;
  flex: 0 1 50%;
  justify-content: center;
  align-items: flex-start;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }

  .LabeledContainer {
    display: flex;
    width: 100%;

    .information-content {
      display: block;
    }
  }
`;

export const AccordionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const SchoolProductDetailsDescription = styled.p`
  margin-top: -5px;
`;

export const FooterDivider = styled.hr`
  ${({ theme }) => css`
    border: solid 0.5px ${theme.gray12};
    width: 100%;
  `}
`;

export const FooterButtonWrapper = styled.div`
  display: flex;
  .btn-danger {
    display: flex;
    height: 40px;
    width: 40px;
    align-items: center;
    justify-content: center;
  }

  .ButtonRow {
    @media screen and (min-width: 992px) {
      .Button {
        height: 40px;
      }

      .btn-primary {
        margin-left: 0px;
      }

      > a:last-child {
        float: right;
        margin-right: 0px;
      }
    }

    @media screen and (min-width: 768px) and (max-width: 992px) {
      display: flex;
      flex: 1;
      gap: 24px;

      .Button {
        height: 40px;
        margin: 0;
      }

      a {
        width: 100%;
      }
    }

    @media screen and (max-width: 768px) {
      display: flex;
      flex-direction: column;
      flex: 1;
      gap: 12px;

      a {
        display: flex;
        height: 40px;
        width: 100%;
        align-items: center;
        justify-content: center;
        margin-left: 0;
      }
    }
  }
`;
