import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { RIGHT_CENTER } from 'components/Tooltip';
import { IMPORT_MODEL_TABLES } from '../../constants';
import AgendaIcon from 'components/AgendaIcon';
import FileAttachment from 'components/FileAttachment';
import FormFieldset from 'components/Form/Fieldset';
import Table from 'components/Table';
import { titleMobile } from 'core/utils/changeText';
import composeFunctions from 'core/utils/composeFunctions';
import tabifyForWizard from 'core/hoc/tabifyForWizard';
import withFormContext, { formPropTypes } from 'core/hoc/withFormContext';
import withAppContext from 'core/hoc/withAppContext';
import withWindowPathname, {
  windowPropTypes,
} from 'core/hoc/withWindowPathname';
import FormValidationErrors from 'components/Form/ValidationErrors';
import DirectUpload from 'core/services/Uploader/DirectUpload';
import ImportWebsServices from 'core/services/ImportWeb';
import importWebsActions from 'store/importWeb/actions';
import table from './table';
import * as S from './styles';

const InsertFileTab = ({ formContext, windowPathname }) => {
  const {
    form,
    updateAttribute,
    addErrorOnAttribute,
    hasErrorOnAttribute,
    removeErrorOnAttribute,
    changeMeta,
  } = formContext;
  const hasErrorOnAttachments = hasErrorOnAttribute('attachments');
  const directUpload = new DirectUpload('schools');
  const importWebsServices = new ImportWebsServices('schools');
  const { setUploadedFile, deleteFile, setImportStep } = importWebsActions;

  const importedInto = windowPathname.split('/')[3];

  const dispatch = useDispatch();

  const ALLOWED_FILE_TYPES = [
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheetapplication/vnd.ms-excel',
    'text/csv',
    'text/plain',
    'application/csv',
    'application/x-ole-storage',
    'application/octet-stream',
    'application/zip',
  ];

  const handleSetImportStep = useCallback(() => {
    dispatch(setImportStep(1));
  }, [dispatch, setImportStep]);

  const onFileChange = useCallback(
    (files) => {
      updateAttribute('attachments', files);
    },
    [updateAttribute]
  );

  const downloadExamples = useCallback((format) => {
    window.location.href = `download_import_template?file_format=${format}`;
  }, []);

  const uploadFile = async (file) => {
    const cancelSource = axios.CancelToken.source();
    const newFile = Object.assign(file, { cancelSource });
    try {
      const { signed_id, url } = await directUpload.upload(newFile);
      return { signed_id, url };
    } catch (error) {
      return error;
    }
  };

  const validateUploadedFile = async (signed_id) => {
    const validatedUploadedFile = {
      headers: [],
      errors: [],
    };

    try {
      const response = await importWebsServices.validateFile(
        importedInto,
        signed_id
      );
      validatedUploadedFile.headers = response.data.meta.headers;
      return validatedUploadedFile;
    } catch (error) {
      if (error.response) {
        validatedUploadedFile.errors = error.response.data.errors.map(
          (err) => err.detail
        );
        return validatedUploadedFile;
      }
    }
  };

  const validateFile = async (file, fileOptions) => {
    removeErrorOnAttribute('attachments');
    const fileErrors = [];
    const uploadedFileErrors = [];
    let uploadedFile;

    if (file.size > 10000000) {
      fileErrors.push(
        'O arquivo carregado possui o tamanho maior que o permitido (10MB)'
      );
    }

    if (!ALLOWED_FILE_TYPES.includes(file.type)) {
      fileErrors.push(
        'O arquivo não possui uma das extensões permitidas (csv, .xls ou .xlsx)'
      );
    }

    const { signed_id, url } = await uploadFile(file);

    if (!fileErrors.length) {
      const validatedUploadedFile = await validateUploadedFile(signed_id);
      uploadedFile = validatedUploadedFile;

      if (validatedUploadedFile.errors.length)
        uploadedFileErrors.push(validatedUploadedFile.errors);
    }

    const attachmentFileErrors = fileErrors.concat(uploadedFileErrors);

    attachmentFileErrors.length
      ? addErrorOnAttribute('attachments', attachmentFileErrors)
      : fileOptions.setSuccessUploaded(file, signed_id, url);

    return { signed_id, url, uploadedFile };
  };

  const onUploadFile = async (file, fileOptions) => {
    changeMeta('nextStepDisabled', true);

    file.loading = true;

    const { signed_id, uploadedFile } = await validateFile(file, fileOptions);
    file.signed_id = signed_id;
    const hasErrorOnFile = hasErrorOnAttribute('attachments');

    if (hasErrorOnFile) {
      fileOptions.removeFile(file);
      fileOptions.destroyFile(file);
    }

    if (!hasErrorOnFile) {
      changeMeta('resetFormTabs', false);
      changeMeta('nextStepDisabled', false);
      dispatch(setUploadedFile(Object.assign(file, { ...uploadedFile })));
    }
  };

  const onDeleteFile = useCallback(
    (files, fileOptions) => {
      const file = files[0];
      fileOptions.removeFile(file);
      fileOptions.destroyFile(file);
      dispatch(deleteFile());
      changeMeta('resetFormTabs', true);
    },
    [deleteFile, dispatch, changeMeta]
  );

  useEffect(() => {
    handleSetImportStep();
  }, [handleSetImportStep]);

  const customLabel = (
    <S.CustomLabel>
      <span>Depois, é só carregar esse arquivo abaixo:</span>
      <S.CustomTooltip
        content={
          <p>
            Aceitamos os formatos .csv, .xls ou .xlsx
            <br />
            com o tamanho máximo de 10MB
          </p>
        }
        position={RIGHT_CENTER}
        tooltipStyle={{
          maxWidth: '280px',
        }}
      >
        <AgendaIcon name="help" size="small" />
      </S.CustomTooltip>
    </S.CustomLabel>
  );

  return (
    <div
      className="ImportWebInsertFileTab"
      data-testid="ImportWebInsertFileTab"
    >
      <FormFieldset>
        <section className="FileUpload">
          <S.Title>
            Baixe nosso modelo de arquivo e preencha com os dados requisitados
          </S.Title>

          <S.ModelCard>
            <S.ModelCardTitle>
              Modelo de arquivo para importação
            </S.ModelCardTitle>

            <S.ModelCardLinks>
              <S.ModelCardLinksItem onClick={() => downloadExamples('xlsx')}>
                <AgendaIcon name="document-sheet" size="medium" />
                <span>Baixar em formato Excel</span>
              </S.ModelCardLinksItem>

              <S.ModelCardLinksItem onClick={() => downloadExamples('csv')}>
                <AgendaIcon name="document-text" size="medium" />
                <span>Baixar em formato CSV</span>
              </S.ModelCardLinksItem>
            </S.ModelCardLinks>
          </S.ModelCard>

          <S.FileUploadWrapper>
            <FileAttachment
              customLabel={customLabel}
              onChangeFiles={onFileChange}
              onUploadFile={onUploadFile}
              onDeleteFile={onDeleteFile}
              attachments={form.attachments}
              buttonText="Carregar arquivo"
              useDirectUpload={false}
              multiple={false}
            />
            {hasErrorOnAttachments && (
              <FormValidationErrors attributeName={'attachments'} multiple />
            )}
          </S.FileUploadWrapper>
        </section>

        <S.Separator />

        <section className="FileModelInformation">
          <S.Title>
            Por que baixar nosso modelo de arquivo antes de importar?
          </S.Title>

          <S.Text>
            Para que durante sua importação não ocorra nenhum erro e ela
            aconteça da melhor forma possível.
          </S.Text>

          <S.Title>
            Quais são os campos e como preencher o modelo de arquivo?
          </S.Title>

          <S.TableWrapper>
            <Table columns={table} data={IMPORT_MODEL_TABLES[importedInto]} />
          </S.TableWrapper>
        </section>
      </FormFieldset>
    </div>
  );
};

InsertFileTab.propTypes = {
  ...formPropTypes,
  ...windowPropTypes,
};

export default composeFunctions(
  withFormContext,
  withAppContext,
  withWindowPathname,
  tabifyForWizard({
    title: titleMobile('1 Inserir arquivo'),
  })
)(InsertFileTab);
