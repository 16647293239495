import React from 'react';

import ModalContainer from 'components/Modal/ModalContainer';
import PageTitle from 'components/PageTitle';
import PageSubTitle from 'components/PageSubTitle';
import Button from 'components/Button';
import ButtonRow from 'components/ButtonRow';
import Loader from 'components/Loader';

import './style.scss';

export type AlertModalProps = {
  closeAlert?: () => void;
  confirmButtonText?: string;
  cancelButtonText?: string;
  isOpen?: boolean;
  title: React.ReactNode | string;
  children: React.ReactNode;
  onConfirm?: () => void;
  type?: string;
  isLoading?: boolean;
  showBackButton?: boolean;
};

const AlertModal = ({
  closeAlert,
  confirmButtonText = 'Entendi',
  cancelButtonText = 'Fechar',
  isOpen = false,
  title,
  children,
  onConfirm,
  showBackButton = true,
  type = 'success',
  isLoading = false,
}: AlertModalProps) => {
  return (
    <div className={`AlertModal ${type || ''}`} data-testid="alertModalWrapper">
      <ModalContainer isOpen={isOpen} toggleModal={closeAlert}>
        <Loader isLoading={isLoading}>
          {typeof title === 'string' ? (
            <PageTitle variation="small">{title}</PageTitle>
          ) : (
            title
          )}
          <PageSubTitle className={!showBackButton && 'subTitle'}>
            {children}
          </PageSubTitle>
          <br />
          {showBackButton ? (
            <ButtonRow>
              <Button variation="secondary" onClick={closeAlert}>
                {cancelButtonText}
              </Button>
              <Button variation="primary" onClick={onConfirm}>
                {confirmButtonText}
              </Button>
            </ButtonRow>
          ) : (
            <div className="primaryButtonContainer">
              <Button variation="primary" onClick={onConfirm}>
                {confirmButtonText}
              </Button>
            </div>
          )}
        </Loader>
      </ModalContainer>
    </div>
  );
};

export default AlertModal;
