import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import shopActions from 'store/edupay/shop/actions';
import LabeledContainer from 'components/LabeledContainer';
import DropdownButton from 'components/DropdownButton';
import ButtonRow from 'components/ButtonRow';
import Button from 'components/Button';
import PropTypes from 'prop-types';
import OutlineBox from 'components/OutlineBox';
import { OFFER_STATUS } from 'core/constants/index';
import Alert from 'components/Alert';
import { Redirect } from 'react-router';
import Pagination from 'components/Pagination';
import ClassroomsList from 'components/ClassroomsList';
import DisableOffer from 'core/services/Shop';
import { ToastContainer, toast } from 'react-toastify';

import * as S from './styles';

const headerInformation =
  'Os produtos e serviços que estão sendo ofertados pela escola podem ser gerenciados neste módulo. Os pedidos realizados pelas famílias podem ser acompanhados no módulo de "Cobranças únicas"';

const ShopListContent = ({
  offers,
  page,
  totalItemsCount,
  handlePageChange,
}) => {
  const [redirectToOfferDetails, setRedirectToOfferDetails] = useState(0);
  const [redirectToOfferEdit, setRedirectToOfferEdit] = useState(0);
  const [selectedOptionId, setSelectedOptionId] = useState(null);
  const [confirm, setConfirm] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);
  const [optionValue, setOptionValue] = useState(false);
  const [wasSended, setWasSended] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const { fetchOffers } = shopActions;
  const dispatch = useDispatch();

  useEffect(() => {
    if (confirm === confirm.toUpperCase() && confirm === 'QUERO CANCELAR') {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [confirm]);

  useEffect(() => {
    if (wasSended) {
      handleOptions();
      setWasSended(null);
    }
  }, [wasSended]);

  if (redirectToOfferDetails) {
    return (
      <Redirect to={`/schools/marketplaces/offers/${redirectToOfferDetails}`} />
    );
  }

  if (redirectToOfferEdit) {
    return (
      <Redirect
        to={{
          pathname: `/schools/marketplaces/offers/${redirectToOfferEdit}/edit`,
          state: { referrer: '/schools/school_products/tab/shop' }
        }}
      />
    );
  }

  const sendDisableOffer = (selectedOptionId) => {
    setLoading(true);
    DisableOffer.disableProduct(selectedOptionId)
      .then(setWasSended)
      .catch(setError)
      .finally(() => setLoading(false));
  };

  const handleOptions = () => {
    setOptionValue(!optionValue);
  };

  const getActions = (id, attributes) => {
    const actions = [];
    actions.push({
      text: 'Visualizar oferta',
      onClick: () => setRedirectToOfferDetails(id),
    });

    if (attributes.status === 'canceled') {
      return actions;
    }

    if (
      attributes.status === 'active' ||
      attributes.status === 'not_started' ||
      attributes.status === 'closed' ||
      attributes.status === 'stock_exceeded'
    ) {
      actions.push({
        text: 'Editar oferta',
        onClick: () => setRedirectToOfferEdit(id),
      });
    }

    if (
      attributes.status === 'active' ||
      attributes.status === 'not_started' ||
      attributes.status === 'stock_exceeded'
    ) {
      actions.push({
        text: 'Cancelar oferta',
        onClick: () => {
          setSelectedOptionId(id), handleOptions();
        },
      });
    }

    return actions;
  };

  const handleSubmit = () => {
    sendDisableOffer(selectedOptionId);
    toast.success('Oferta cancelada com sucesso!');
    dispatch(fetchOffers({ page }));
  };

  return (
    <S.ShopListContainer>
      <Alert variation="info">{headerInformation}</Alert>
      <S.OffersQuantity>
        <p>{`${totalItemsCount} ${
          totalItemsCount > 1 ? 'ofertas' : 'oferta'
        }`}</p>
      </S.OffersQuantity>
      <ToastContainer />
      {offers.data.map((offer) => {
        const {
          id,
          attributes,
          relationships: { classrooms },
        } = offer;
        return (
          <S.ListWrapper key={id}>
            <LabeledContainer title="Nome" className="title">
              <p className="offer-title">
                {attributes.product.data.attributes.title}
              </p>
            </LabeledContainer>
            <S.LabelContent onClick={() => setRedirectToOfferDetails(offer.id)}>
              <LabeledContainer title="Turmas">
                <S.ClassroomContainer>
                  <ClassroomsList
                    classroomsNames={classrooms.data.map(
                      (classroom) =>
                        offers.included.find(
                          (included) => included.id === classroom.id
                        ).attributes.name
                    )}
                  />
                </S.ClassroomContainer>
              </LabeledContainer>
              <LabeledContainer title="Valor">
                <p>
                  {(attributes.original_price_cents / 100).toLocaleString(
                    'pt-BR',
                    {
                      style: 'currency',
                      currency: 'BRL',
                    }
                  )}
                </p>
              </LabeledContainer>
              <LabeledContainer title="Inicia em">
                <p className="date-info">{attributes.starts_at}</p>
              </LabeledContainer>
              <LabeledContainer title="Expira em">
                <p className="date-info">{attributes.expires_at}</p>
              </LabeledContainer>
              <LabeledContainer title="Status">
                <S.StatusWrapper>
                  <OutlineBox
                    variation={OFFER_STATUS[attributes.status].variation}
                  >
                    {OFFER_STATUS[attributes.status].label}
                  </OutlineBox>
                </S.StatusWrapper>
              </LabeledContainer>
            </S.LabelContent>
            {optionValue && (
              <>
                <S.ContentWrapper>
                  <S.Container>
                    <S.Icon onClick={() => handleOptions()}>
                      <i
                        className="AgendaIcon icon-ae-close medium custom-size"
                        aria-hidden="true"
                      ></i>
                    </S.Icon>
                    <S.Title>Cancelar oferta</S.Title>
                    <S.WantToCancelField>
                      <S.Label>
                        Ao cancelar, a oferta deixará de ser exibida no
                        aplicativo dos responsáveis e a ação não poderá ser
                        desfeita. Tem certeza que deseja cancelar?
                      </S.Label>
                    </S.WantToCancelField>
                    <S.Label>
                      Digite <strong>QUERO CANCELAR</strong> no campo abaixo
                    </S.Label>
                    <S.Input
                      type="text"
                      onChange={(e) => setConfirm(e.target.value)}
                    />
                    <S.ContentButton>
                      <ButtonRow>
                        <Button
                          variation={'danger'}
                          disabled={isDisabled}
                          onClick={handleSubmit}
                        >
                          Cancelar oferta
                        </Button>
                      </ButtonRow>
                    </S.ContentButton>
                  </S.Container>
                </S.ContentWrapper>
              </>
            )}
            <DropdownButton
              text=""
              disabled={false}
              dropdownItems={getActions(id, attributes)}
              icon="dots-vertical"
              variation="secondary"
            />
          </S.ListWrapper>
        );
      })}
      <Pagination
        onChange={handlePageChange}
        activePage={page}
        totalItemsCount={totalItemsCount}
        itemsCountPerPage={15}
        onlySubmit
      />
      {optionValue && (
        <S.StyledOption onClick={() => handleOptions()}></S.StyledOption>
      )}
    </S.ShopListContainer>
  );
};

ShopListContent.propTypes = {
  offers: PropTypes.object.isRequired,
  page: PropTypes.number.isRequired,
  totalItemsCount: PropTypes.number.isRequired,
  handlePageChange: PropTypes.func.isRequired,
};

export default ShopListContent;
