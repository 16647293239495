/** Action creators */
const actions = {
  ERROR: 'ERROR/dynamicModules',
  REQUESTURL: 'REQUEST_URL/dynamicModules',
  REQUEST_URL_SUCCESS: 'REQUEST_URL_SUCCESS/dynamicModules',

  requestUrl: (id) => ({
    type: actions.REQUESTURL,
    id,
  }),
};

export default actions;
