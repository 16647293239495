import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const ModalBody = ({ children }) => {
  return <section className="generic-modal-body">{children}</section>;
};

ModalBody.propTypes = {
  children: PropTypes.element,
};

export default ModalBody;
