import styled, { css } from 'styled-components';
import { ThemeProps } from 'config/themes/types';

export const Title = styled.h2`
  ${({ theme: { colors, space, typography } }: ThemeProps) => css`
    ${typography.title.Bold20}
    color: ${colors.neutral.black};
    margin-bottom: ${space.xl2};
  `}
`;

export const RequiredFields = styled.span`
  ${({ theme: { space, typography } }: ThemeProps) => css`
    margin-top: ${space.md};
    ${typography.body.Regular12};
  `}
`;
