import createModuleReducer from 'store/storeConfig/createModuleReducer';
import actions from './actions';

export const INITIAL_STATE = {
  integration: null,
  inconsistence: [],
  inconsistenceMeta: null,
  inconsistencePagination: {
    page: 1,
    per_page: 10,
    total: 0,
  },
  error: null,
  isLoading: false,
  isLoadList: false,
};

export const handlerError = (state, action) => ({
  ...state,
  error: action.error,
  isLoading: false,
});

export const getIntegrationsSuccess = (state, action) => {
  return {
    ...state,
    isLoading: false,
    integration: action.data,
  };
};

export const getIconsistenciesSuccess = (state, action) => {
  return {
    ...state,
    isLoading: false,
    isLoadList: false,
    inconsistence:
      action.page === 1
        ? action.included
        : [...state.inconsistence, ...action.included],
    inconsistenceMeta: action.data,
    inconsistencePagination: action.meta,
  };
};

export const updateIsloadInconsistencies = (state, action) => {
  if (action.page == 1) {
    return {
      ...state,
      isLoading: !state.isLoading,
    };
  } else {
    return {
      ...state,
      isLoadList: !state.isLoadList,
    };
  }
};

export const updateIsLoading = (state) => {
  return {
    ...state,
    isLoading: !state.isLoading,
  };
};

const HANDLERS = {
  [actions.ERROR]: handlerError,
  [actions.GET_INTEGRATION_SUCCESS]: getIntegrationsSuccess,
  [actions.GET_INTEGRATION_INCONSISTENCIES_SUCCESS]: getIconsistenciesSuccess,
  [actions.GET_INTEGRATION_INCONSISTENCIES]: updateIsloadInconsistencies,
  [actions.GET_INTEGRATION]: updateIsLoading,
};

const integrationsMonitor = createModuleReducer(INITIAL_STATE, HANDLERS);

export default integrationsMonitor;
