import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '@agendaedu/ae-web-components';

import AttachmentEntity from 'core/entities/Attachment';
import useOutsideClick from 'core/hooks/useOutsideClick';
import withAppContext from 'core/hoc/withAppContext';

import FooterToast from 'components/FooterToast';
import Loader from 'components/Loader';
import { SelectorEmotes } from 'components/Messages/EmojiPicker';

import * as S from './styles';

import ErrorAttachmentModal from './components/ErrorAttachmentModal';
import { AttachemntModal } from './components/AttachmentModal';

import { InputTextFieldProps } from './types';

const InputTextField = ({
  appContext: { currentUserType, currentSchool },
  currentMessage,
  disabled = false,
  disabledPlaceholder,
  disabledSubmitBtn,
  footerShowToast,
  footerVariant,
  isLoading,
  message,
  testId,
  handleOnChange,
  handleOnFooterClose,
  handleOnSubmit,
  handleOnSubmitAttachment,
}: InputTextFieldProps): JSX.Element => {
  const { t } = useTranslation(['messages']);

  const emojiRef = useRef(null);
  const [attachmentError, setAttachmentError] = useState(null);
  const [attachmentFile, setAttachmentFile] = useState<AttachmentEntity>(null);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [showAttachmentModal, setShowAttachmentModal] = useState(false);

  const handleOnChangeShowEmoji = () => setShowEmojiPicker(!showEmojiPicker);

  useOutsideClick(emojiRef, () => showEmojiPicker && setShowEmojiPicker(false));

  const handleToggleAttachmentModal = () =>
    setShowAttachmentModal(!showAttachmentModal);

  const handleKeyboardSendMensage = (
    event: React.KeyboardEvent<HTMLTextAreaElement>
  ) => {
    if ((event.key === 'Enter' || event.keyCode === 13) && event.shiftKey) {
      event.preventDefault();
      !disabledSubmitBtn && handleOnSubmit();
    }
  };

  const handleOnAddEmoji = ({ native }) => {
    const newMessage = message.concat(native);
    handleOnChange(newMessage);
  };

  const handleOnChangeMessage = useCallback(
    ({ target }) => handleOnChange(target.value),
    [handleOnChange]
  );

  const handleToggleAttachmentMessage = useCallback(
    (event) => {
      const currentAttachment = new AttachmentEntity(event.target.files[0]);

      if (
        currentAttachment.isTypeValid(currentUserType) &&
        currentAttachment.isSizeValid(currentUserType, currentSchool)
      ) {
        setAttachmentFile(currentAttachment);
        setAttachmentError([]);
      } else {
        const errors = [];
        if (!currentAttachment.isTypeValid(currentUserType))
          errors.push('type');
        if (!currentAttachment.isSizeValid(currentUserType, currentSchool))
          errors.push('size');

        setAttachmentError(errors);
      }

      handleToggleAttachmentModal();
    },
    [setAttachmentFile, setAttachmentError]
  );

  return (
    <>
      <S.TextAreaInputWrapper data-testid={testId}>
        {footerShowToast && (
          <FooterToast
            handleOnClose={handleOnFooterClose}
            variant={footerVariant}
            messageContent={currentMessage?.attributes?.content}
            senderName={currentMessage?.attributes?.sender?.attributes?.name}
          />
        )}

        <S.Wrapper>
          <S.EmoteButton
            data-testid="emote-button"
            disabled={disabled}
            onClick={handleOnChangeShowEmoji}
          >
            <Icon name="emoji" />
            <div ref={emojiRef}>
              <SelectorEmotes
                onSelectEmoji={handleOnAddEmoji}
                disabled={!showEmojiPicker}
              />
            </div>
          </S.EmoteButton>

          <S.InputField
            data-testid="input-field"
            disabled={disabled}
            maxRows={2}
            onKeyPress={handleKeyboardSendMensage}
            placeholder={
              disabled
                ? disabledPlaceholder
                : t('omni_channel.messages.new_message_chat_input_text')
            }
            onChange={handleOnChangeMessage}
            value={message}
          />

          {!footerShowToast && (
            <S.AttachmentWrapper>
              <S.AttachmentButton
                data-testid="attachment-button"
                disabled={disabled}
                htmlFor="attachment-message-input"
              >
                <Icon name="attachment" size="md" />
              </S.AttachmentButton>

              <input
                disabled={disabled}
                id="attachment-message-input"
                type="file"
                data-testid="attachment-input"
                onChange={handleToggleAttachmentMessage}
              />
            </S.AttachmentWrapper>
          )}

          <S.SubmitButton
            data-testid="submit-button"
            onClick={handleOnSubmit}
            disabled={disabledSubmitBtn}
          >
            <S.SubmitButtonWrapper>
              {isLoading ? (
                <Loader isLoading={true} variation="centered" color="white" />
              ) : (
                <Icon name="send-filled" size="sm" />
              )}
            </S.SubmitButtonWrapper>
          </S.SubmitButton>
        </S.Wrapper>
      </S.TextAreaInputWrapper>

      {!attachmentError?.length ? (
        <AttachemntModal
          isOpen={showAttachmentModal}
          attachmentFile={attachmentFile}
          handleOnSubmitAttachment={handleOnSubmitAttachment}
          handleOnClose={handleToggleAttachmentModal}
          optionalMessage
        />
      ) : (
        <ErrorAttachmentModal
          isOpen={showAttachmentModal}
          attachmentError={attachmentError}
          currentUserType={currentUserType}
          handleOnClose={handleToggleAttachmentModal}
        />
      )}
    </>
  );
};

export default withAppContext(InputTextField);
