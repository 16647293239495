import {
  Box,
  DefaultThemeProps,
  Modal,
  Text,
} from '@agendaedu/ae-web-components';
import React from 'react';
import { Props } from './types';
import { useTheme } from 'styled-components';
import { IMAGES_SURVERY_URL } from 'core/constants';
import { useSelector } from 'react-redux';
import { ModalKindSkeleton } from './skeleton';
import { withRouter } from 'react-router';

const KindSurveyModal = ({ isOpen, onClose, history }: Props) => {
  const { colors, border } = useTheme() as DefaultThemeProps;
  const { surveysMeta } = useSelector((state: any) => state.surveys);
  const { dataArea } = useSelector((state: any) => state.root);

  const IMG_INFO = {
    poll: {
      on: {
        url: IMAGES_SURVERY_URL.pollOn,
        alt: 'Enquete',
      },
      off: {
        url: IMAGES_SURVERY_URL.pollOff,
        alt: 'Enquete desativada',
      },
    },
    authorization: {
      on: {
        url: IMAGES_SURVERY_URL.authorizationOn,
        alt: 'Autorização',
      },
      off: {
        url: IMAGES_SURVERY_URL.authorizationOff,
        alt: 'Autorização desativada',
      },
    },
  };

  const pollInfo = {
    type: 'poll',
    title: 'Enquete',
    description:
      'Saiba o que as famílias acham da sua escola e descubra como melhorar serviços na instituição com a criação de Enquetes.',
    imgInfo: IMG_INFO.poll,
    isActive: surveysMeta?.allowed_kinds?.includes('poll'),
  };

  const authorizationInfo = {
    type: 'authorization',
    title: 'Autorização',
    description:
      'Para pedir autorização sobre passeios, autorização de imagem e atividades extraclasse em geral.',
    imgInfo: IMG_INFO.authorization,
    isActive: surveysMeta?.allowed_kinds?.includes('authorization'),
  };

  const ContentBox = ({
    title,
    description,
    imgInfo,
    isActive,
    type,
  }: typeof authorizationInfo | typeof pollInfo) => {
    const imageInfos = imgInfo[isActive ? 'on' : 'off'];
    const textColor = {
      title: isActive ? colors.neutral.black : colors.neutral.gray3,
      description: isActive ? colors.neutral.gray1 : colors.neutral.gray3,
    };

    const onClick = () => {
      if (isActive) {
        history.push(`/${dataArea}/surveys/new/${type}`);
      }
    };

    return (
      <Box
        id={`survey-${title}`}
        p={!surveysMeta ? 0 : 'lg'}
        mx="xs2"
        mt="sm"
        display="flex"
        flexDirection="column"
        alignItems="center"
        borderWidth={border.width.sm}
        borderColor={colors.neutral.gray4}
        borderStyle={border.style.solid}
        borderRadius={border.radius.md}
        maxWidth={{ mobileXS: '100%', desktopLG: 300 }}
        overflow="hidden"
        {...(isActive && { cursor: 'pointer' })}
        backgroundColor={isActive ? 'transparent' : colors.neutral.gray5}
        onClick={onClick}
      >
        {!surveysMeta ? (
          <ModalKindSkeleton />
        ) : (
          <>
            <img src={imageInfos.url} alt={imageInfos.alt} width="40%" />

            <Text
              variant="title-bold-20"
              textAlign="center"
              fontWeight="bold"
              mt="xl"
              color={textColor.title}
            >
              {title}
            </Text>
            <Text
              variant="label-medium-16"
              textAlign="center"
              lineHeight="lg"
              color={textColor.description}
            >
              {description}
            </Text>
          </>
        )}
      </Box>
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Novo formulário">
      <Box display={{ mobileXS: 'block', desktopLG: 'flex' }}>
        <ContentBox {...pollInfo} />
        <ContentBox {...authorizationInfo} />
      </Box>
    </Modal>
  );
};

export default withRouter(KindSurveyModal);
