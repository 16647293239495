import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import HandoutTemplateContext from 'core/contexts/Handout/Templates';
import withAppContext from 'core/hoc/withAppContext';

import HandoutsActions from 'store/handouts/actions';
import { HandoutState } from 'store/handouts/types';

import FormFullScreen from 'components/FormFullScreen';
import Informations from '../Steps/Informations';
import Toast from 'components/Toast';

import * as S from './styles';

import { Props } from './types';

const WIZARD_STEPS = [Informations];

const NewHandoutTemplate: React.FC<Props> = ({ appContext: { dataArea } }) => {
  const { t } = useTranslation(['handouts']);
  const dispatch = useDispatch();

  const { createNewHandoutTemplateRequest } = HandoutsActions;

  const { isSending } = useSelector((state: HandoutState) => state.handouts);

  const initialForm = {
    templateTitle: '',
    title: '',
    description: '',
    categoryId: null,
    coverImage: null,
  };

  const handleCreateNewHandoutTemplate = useCallback(
    (form) => {
      dispatch(createNewHandoutTemplateRequest(form));
    },
    [createNewHandoutTemplateRequest, dispatch]
  );

  return (
    <S.HandoutsFormContainer>
      <HandoutTemplateContext.Provider value={null}>
        <FormFullScreen
          action="new"
          id="newHandoutTemplate"
          form={initialForm}
          initialAttributes={{}}
          formMeta={{}}
          titlePage={t('templates.form.new_title')}
          backTo={`/${dataArea}/handouts/templates`}
          backToExternal
          isSubmitting={isSending}
          steps={WIZARD_STEPS}
          onSubmit={handleCreateNewHandoutTemplate}
          titleModal={t('modals.handout_form_discard.title')}
          contentModal={t('modals.handout_form_discard.description')}
        />
      </HandoutTemplateContext.Provider>
      <Toast />
    </S.HandoutsFormContainer>
  );
};

export default withAppContext(NewHandoutTemplate);
