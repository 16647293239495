import React from 'react';
import PropTypes from 'prop-types';
import autobind from 'autobind-decorator';

import AgendaIcon from 'components/AgendaIcon';

import withFormContext, { formPropTypes } from 'core/hoc/withFormContext';

import './style.scss';

class TextFieldSearchGroup extends React.Component {
  @autobind
  onChange(event) {
    const {
      onChange,
      formContext: { changeAttribute },
      attributeName,
    } = this.props;

    if (onChange) {
      changeAttribute(attributeName)(event);
      onChange(event.target.value);
    } else {
      changeAttribute(attributeName)(event);
    }
  }

  render() {
    const {
      attributeName,
      placeholder,
      onIconClick,
      disabled,
      formContext: { form },
    } = this.props;

    return (
      <div className="TextFieldSearchGroup">
        <div className="search-addon" onClick={onIconClick}>
          <AgendaIcon name="search" size="medium" />
        </div>
        <input
          type="text"
          className="search-field"
          onChange={(e) => this.onChange(e)}
          name={attributeName}
          placeholder={placeholder}
          disabled={disabled}
          value={form[attributeName] || ''}
          onKeyPress={(e) => {
            if (e.key === 'Enter') e.preventDefault();
          }}
          autoComplete="off"
        />
      </div>
    );
  }
}

TextFieldSearchGroup.propTypes = {
  attributeName: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  ...formPropTypes,
};

export default withFormContext(TextFieldSearchGroup);
