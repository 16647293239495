import colorWithAlpha from 'core/utils/colorWithAlpha';
import styled, { css } from 'styled-components';

export const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 16px;
  font-size: 14px;
  gap: 8px;

  a {
    text-decoration: underline;
    color: white;
    font-weight: 600;
  }

  .CustomSpan {
    ${({ theme }) => css`
      color: '${theme.gray1}';
    `}
  }
`;

export const LeftIcon = styled.span`
  position: relative;
  top: 2px;
`;

export const FilesList = styled.ul`
  margin-top: 16px;
  padding-left: 0;
`;

export const FileAttachment = styled.div`
  ${({ theme }) => css`
    .Dropdown .menu {
      margin-top: 4px;
      padding: 4px;
      border-radius: 6px;
      border: 1px solid ${theme.gray12};

      > * {
        padding: 12px 16px;
        line-height: 24px;
        border-radius: 6px;
        width: 100%;
        background-color: white;
        border: 0px;
        color: ${theme.gray2};
        font-size: 14px;

        &:hover {
          background-color: ${colorWithAlpha(`${theme.primaryColor}`, 0.1)};
          color: ${theme.primaryColor};
        }

        .GoogleDriveButton {
          border: none;
          background-color: transparent;
          padding: 0;
        }
      }
    }
    .ButtonTrigger {
      display: flex;
      align-items: center;
      background-color: transparent;
      padding: 8px;

      &:hover {
        background-color: ${colorWithAlpha(`${theme.primaryColor}`, 0.1)};
      }

      .TextContainer {
        margin: 0 16px;
      }

      .RightIcon {
        margin-right: 5.2px;
      }
    }
  `}
`;
