import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Loader from 'components/Loader';
import SchoolProductsFilters from 'components/Payments/PaymentsContainer/SchoolProductsFilters';
import SchoolProductsList from 'components/Payments/SchoolProductsList';

import {
  SCHOOL_PRODUCTS_STATUSES,
  SCHOOL_PRODUCTS_FILTER_TYPES,
} from 'core/constants/index';

import smoothScrollTo from 'core/utils/smoothScrollTo';

import withWindowSize from 'core/hoc/withWindowSize';

import actionSchoolProducts from 'store/edupay/schoolProducts/actions';
import { SchoolProductsState } from 'store/edupay/schoolProducts/types';

import './style.scss';

const SchoolProductsListContainer = () => {
  const dispatch = useDispatch();

  const { fetchSchoolProductsList } = actionSchoolProducts;

  const {
    schoolProducts,
    hasSchoolProducts,
    filters,
    activeFiltersCount,
    paginate,
    isLoading,
    errorMessage,
  } = useSelector((state: SchoolProductsState) => state.schoolProducts);

  const { currentWallet } = useSelector((state) => state.wallets);

  const form = { status: filters.status };

  const formMeta = {
    amount: activeFiltersCount,
    select_options: {
      status: SCHOOL_PRODUCTS_STATUSES,
      payments_search_type: SCHOOL_PRODUCTS_FILTER_TYPES,
    },
  };

  const getSchoolProducts = useCallback(
    (params) => {
      dispatch(fetchSchoolProductsList(params));
    },
    [dispatch, fetchSchoolProductsList]
  );

  const handleSchoolProductFilter = useCallback(
    (params) => {
      getSchoolProducts({ ...filters, ...params });
    },
    [getSchoolProducts, filters]
  );

  const handlePageChange = useCallback(
    (page) => {
      const params = {
        page,
      };

      getSchoolProducts({ ...filters, ...params });
      smoothScrollTo(0, 0);
    },
    [getSchoolProducts, filters]
  );

  useEffect(() => {
    if (currentWallet === null) {
      return;
    }

    getSchoolProducts({ page: 1, status: filters.status });
  }, [getSchoolProducts, currentWallet]);

  return (
    <div className="SchoolProductsListContainer">
      <SchoolProductsFilters
        form={form}
        formMeta={formMeta}
        handleFilterSubmit={handleSchoolProductFilter}
      />
      {isLoading && !errorMessage ? (
        <Loader />
      ) : (
        <SchoolProductsList
          meta={hasSchoolProducts}
          schoolProducts={schoolProducts}
          onPageChanged={handlePageChange}
          activePage={paginate.activePage}
          totalItemsCount={paginate.totalItemsCount}
          itemsCountPerPage={paginate.itemsCountPerPage}
          fetchError={errorMessage}
          filters={filters}
        />
      )}
    </div>
  );
};

export default withWindowSize(SchoolProductsListContainer);
