import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import FormFullScreen from 'components/FormFullScreen';
import Loader from 'components/Loader';
import Toast from 'components/Toast';
import ResponsibleProfilesActions from 'store/responsibleProfiles/actions';
import ResponsibleProfileInformationsTab from 'pages/ResponsibleProfiles/FormTabs/InformationsTab';
import SchoolServices from 'core/services/School';
import UserServices from 'core/services/Users';
import withAppContext, { appPropTypes } from 'core/hoc/withAppContext';
import { reviewFormRedirect } from 'core/utils/reviewFormRedirect';
import * as S from '../styles';

const ResponsibleProfilesEdit = ({ match, appContext: { policies } }) => {
  const { id } = match.params;
  const dispatch = useDispatch();
  const {
    setResponsibleProfile,
    setReviewerResponsibleProfile,
    updateResponsibleProfile,
    approveResponsibleProfile,
    removeResponsibleProfile,
  } = ResponsibleProfilesActions;
  const dataArea = useSelector((state) => state.root.dataArea);
  const {
    isLoading,
    isSubmitting,
    form: current,
    formMeta,
  } = useSelector((state) => state.responsibleProfiles);
  const schoolServices = new SchoolServices(dataArea);
  const userServices = new UserServices(dataArea);
  const {
    can_use_legacy_id: canUseLegacyId,
    has_guaranteed_monthly_fee: hasGuaranteedMonthlyFee,
  } = policies;

  const formType = match.url.split('/').slice(-1)[0];

  const { t } = useTranslation(['responsible_profiles']);

  const validateLegacyId = async (formContext) => {
    const { form, removeErrorOnAttribute, addErrorOnAttribute } = formContext;
    try {
      await schoolServices.validateLegacyId(
        form.legacy_id,
        'responsible_profiles'
      );
      removeErrorOnAttribute('legacy_id');
    } catch {
      addErrorOnAttribute(
        'legacy_id',
        t('form.legacy_id.already_used_message')
      );
    }
  };

  const validateEmail = async (formContext) => {
    const { form, removeErrorOnAttribute, addErrorOnAttribute } = formContext;
    try {
      await userServices.verifyEmail(form.email, 'responsible_profiles');
      addErrorOnAttribute('email', t('form.email.already_used_message'));
    } catch {
      removeErrorOnAttribute('email');
    }
  };

  const validateUsername = async (formContext) => {
    const { form, removeErrorOnAttribute, addErrorOnAttribute } = formContext;
    try {
      await userServices.verifyUsername(form.username, 'responsible_profiles');
      addErrorOnAttribute('username', t('form.username.already_used_message'));
    } catch {
      removeErrorOnAttribute('username');
    }
  };

  const validateCpf = (formContext) => {
    const { form, removeErrorOnAttribute, addErrorOnAttribute } = formContext;

    if (hasGuaranteedMonthlyFee && form.financial && !form.document_number) {
      addErrorOnAttribute(
        'document_number',
        t('form.document_number.invalid_message')
      );
    } else {
      removeErrorOnAttribute('document_number');
    }
  };

  const handleSetResponsibleProfile = useCallback(() => {
    dispatch(setResponsibleProfile(id));
  }, [id, dispatch, setResponsibleProfile]);

  const handleSetReviewerResponsibleProfile = useCallback(() => {
    dispatch(setReviewerResponsibleProfile(id));
  }, [id, dispatch, setReviewerResponsibleProfile]);

  useEffect(() => {
    formType == 'review'
      ? handleSetReviewerResponsibleProfile()
      : handleSetResponsibleProfile();
  }, [
    formType,
    handleSetResponsibleProfile,
    handleSetReviewerResponsibleProfile,
  ]);

  const onSubmit = async (
    formContext,
    _,
    redirect = `/${dataArea}/responsible_profiles`
  ) => {
    const { form, hasErrorOnAttribute } = formContext;

    if (form.legacy_id && form.legacy_id !== current.legacy_id)
      await validateLegacyId(formContext);

    if (!canUseLegacyId) delete form.legacy_id;

    if (form.email && form.email !== current.email)
      await validateEmail(formContext);

    if (form.username && form.username !== current.username)
      await validateUsername(formContext);

    validateCpf(formContext);

    const hasErrorOnLegacyId = hasErrorOnAttribute('legacy_id');
    const hasErrorOnEmail = hasErrorOnAttribute('email');
    const hasErrorOnUserName = hasErrorOnAttribute('username');
    const hasErrorOnCpf = hasErrorOnAttribute('document_number');

    const isFormValid =
      !hasErrorOnLegacyId &&
      !hasErrorOnEmail &&
      !hasErrorOnUserName &&
      !hasErrorOnCpf;

    if (formType == 'review') {
      redirect = reviewFormRedirect(redirect, formMeta.nextReviewProfileId);
    }

    if (isFormValid) {
      formType == 'review'
        ? dispatch(
            approveResponsibleProfile(id, {
              form,
              redirect,
              sourceRequest: formType,
            })
          )
        : dispatch(
            updateResponsibleProfile(id, {
              form,
              redirect,
              sourceRequest: formType,
            })
          );
    }
  };

  const onSubmitAndNewRegister = async (formContext) => {
    await onSubmit(
      formContext,
      null,
      `/${dataArea}/responsible_profiles/${formMeta.nextReviewProfileId}/review`
    );
  };

  const onRemove = () => {
    dispatch(removeResponsibleProfile(id));
  };

  return (
    <S.PageContainer
      id={
        formType == 'review'
          ? 'ResponsibleProfilesReview'
          : 'ResponsibleProfilesEdit'
      }
    >
      <Loader isLoading={isLoading}>
        <FormFullScreen
          action={formType == 'review' ? 'review' : 'edit'}
          titlePage={
            formType == 'review' ? t('form.review.title') : t('form.edit.title')
          }
          titleModal={t('form.edit.modal_title')}
          contentModal={
            formType == 'review'
              ? t('form.review.modal_content')
              : t('form.edit.modal_content')
          }
          toggleModalWithFormUpdates={formType == 'review' ? false : true}
          onSubmit={onSubmit}
          onSubmitAndNewRegister={
            formType == 'review' && formMeta.nextReviewProfileId
              ? onSubmitAndNewRegister
              : undefined
          }
          onRemove={formType == 'review' ? onRemove : undefined}
          form={current}
          formMeta={formMeta}
          isSubmitting={isSubmitting}
          steps={[ResponsibleProfileInformationsTab]}
          backTo={`/${dataArea}/responsible_profiles`}
          backToExternal
          finishText={
            formType == 'review'
              ? t('form.review.finish_button_text')
              : t('form.edit.finish_button_text')
          }
          finishAndNewText={t('form.review.finish_and_new_button_text')}
        />
      </Loader>
      <Toast />
    </S.PageContainer>
  );
};

ResponsibleProfilesEdit.propTypes = {
  ...appPropTypes,
  match: PropTypes.object.isRequired,
};

export default withAppContext(ResponsibleProfilesEdit);
