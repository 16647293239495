import React, { useEffect, useState } from 'react';
import { AlbumDetailsMediasIncluded, RootState } from 'store/albums/types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, Button, Modal } from '@agendaedu/ae-web-components';
import AlbumActions from 'store/albums/actions';
import StudentsListByHeadquarter from '../StudentsListByHeadquarter';
import StudentsList from '../StudentsList';

import { Props } from './types';

const EditVisibilityModal = ({
  isOpen,
  onClose,
  photo,
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const { updateMedia } = AlbumActions;
  const { t } = useTranslation(['albums', 'common']);
  const {
    albumDetails,
    photoVisibilityStudentsByHeadquarterMeta,
    albumDetailsMediasIncluded,
    isUpdatingAlbumMedia,
  } = useSelector((state: RootState) => state.albums);

  const [studentsIdsSelecteds, setStudentIdsSelecteds] = useState<Set<number>>(
    new Set<number>()
  );
  const userPermission = albumDetailsMediasIncluded.find(
    (include) =>
      include.type === 'userPermission' &&
      include.id === photo?.relationships.userPermission.data.id
  ) as AlbumDetailsMediasIncluded;

  const canUpdate = userPermission?.attributes.canUpdate;

  useEffect(() => {
    if (photoVisibilityStudentsByHeadquarterMeta?.selectedStudentIds) {
      setStudentIdsSelecteds(
        new Set(photoVisibilityStudentsByHeadquarterMeta?.selectedStudentIds)
      );
    }
  }, [photoVisibilityStudentsByHeadquarterMeta?.selectedStudentIds]);

  const onSave = () => {
    dispatch(
      updateMedia({
        id: photo.id,
        albumId: albumDetails.id,
        studentProfileIds: [...studentsIdsSelecteds],
        callbackSuccess: onClose,
      })
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      title={t('album_details.photo_details_modal.who_to_visible')}
      onClose={onClose}
    >
      <Box width="100%">
        <Box display="flex" flexDirection="column" backgroundColor="white">
          <Box minWidth={{ mobileXS: 300, tabletMD: 366 }} maxWidth={600}>
            {canUpdate ? (
              <StudentsListByHeadquarter
                photoId={photo?.id}
                setStudentIdsSelecteds={setStudentIdsSelecteds}
                studentsIdsSelecteds={studentsIdsSelecteds}
              />
            ) : (
              <StudentsList photoId={photo?.id} />
            )}
          </Box>
        </Box>

        {canUpdate && (
          <Box display="flex" justifyContent="flex-end" mt="lg">
            <Button
              testId="back-button"
              variant="secondary"
              mr="sm"
              onClick={onClose}
            >
              {t('common:button.cancel')}
            </Button>
            <Button
              testId="save-button"
              disabled={isUpdatingAlbumMedia || !studentsIdsSelecteds.size}
              onClick={onSave}
            >
              {t('common:button.save')}
            </Button>
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default EditVisibilityModal;
