import React, { useContext, useState } from 'react';
import { SectionGroup } from './components/SectionGroup';
import {
  BabyBottleCard,
  DiaperCard,
  EvacuationCard,
  ObservationCard,
  ShowerCard,
  SleepCard,
} from 'pages/diarySectionsV3/Sections/components/ShowSectionModal/Sections';
import { DiarySectionsFormContext } from 'core/contexts/DiaryForm/SectionsForm';

import * as S from './styles';
import {
  Box,
  Button,
  DefaultThemeProps,
  Icon,
  Text,
  TextField,
} from '@agendaedu/ae-web-components';
import { useTranslation } from 'react-i18next';
import { CustomSectionGroup } from './components/CustomSectionGroup';
import { useSelector } from 'react-redux';
import { DiaryFormStructure, DiaryState } from 'store/dailySummaries/types';
import StudentsSkeleton from './components/Skeleton';
import { useTheme } from 'styled-components';
import EmptyState from 'components/EmptyState';
import { IMAGES_CLASSROOMS_URL } from 'core/constants';
import { FillBatchModal } from './components/FillBatchModal';

export const StudentsList = () => {
  const { t } = useTranslation('diary');
  const { breakpoints } = useTheme() as DefaultThemeProps;

  const {
    form: { values, initialValues },
    currentSectionId,
    updateStudentDiary,
  } = useContext(DiarySectionsFormContext);
  const {
    classroom: {
      attributes: {
        student_profiles: { data: students },
      },
    },
    studentsDiariesBySection: { isLoading },
  } = useSelector((state: DiaryState) => state.dailySummaries);

  const [search, setSearch] = useState('');
  const [openFillBatchModal, setOpenFillBatchModal] = useState(false);

  const getStudents = search
    ? students.filter((student) =>
        student.attributes.name.toLowerCase().includes(search.toLowerCase())
      )
    : students;

  const renderCurrentSection = (
    student: DiaryFormStructure['studentProfile']
  ) => {
    const studentValues = values.studentsDiaries[student.id];

    const sectionsComponents = {
      baby_bottle: (
        <BabyBottleCard
          babyBottles={studentValues.baby_bottles}
          onChange={(babyBottles) =>
            updateStudentDiary(student.id, { baby_bottles: babyBottles })
          }
          student={student}
        />
      ),
      evacuation: (
        <EvacuationCard
          evacuations={studentValues.evacuations}
          onChange={(evacuations) =>
            updateStudentDiary(student.id, { evacuations })
          }
          student={student}
        />
      ),
      sleep: (
        <SleepCard
          sleeps={studentValues.sleeps}
          onChange={(sleeps) => updateStudentDiary(student.id, { sleeps })}
          student={student}
        />
      ),
      diaper: (
        <DiaperCard
          diaper={studentValues.diaper}
          onChange={(diaper) => updateStudentDiary(student.id, { diaper })}
          student={student}
        />
      ),
      shower: (
        <ShowerCard
          shower={studentValues.shower}
          onChange={(shower) => updateStudentDiary(student.id, { shower })}
          student={student}
        />
      ),
      observation: (
        <ObservationCard
          observation={studentValues.observation}
          onChange={(observation) =>
            updateStudentDiary(student.id, { observation })
          }
          student={student}
          hasInitialValue={
            !!initialValues.studentsDiaries[student.id].observation
          }
        />
      ),
      occurrence: <SectionGroup field="occurrences" student={student} />,
      meals: <SectionGroup field="meals" student={student} />,
    };

    return (
      sectionsComponents[currentSectionId] || (
        <CustomSectionGroup student={student} />
      )
    );
  };

  const showEmptySearch = !getStudents.length && !!search;

  const showFooterInfo = !!getStudents.length;

  if (isLoading) return <StudentsSkeleton />;

  return (
    <>
      <S.SectionsWrapper>
        <Box display="flex" maxWidth={breakpoints.tabletMD}>
          <Button
            data-testid="batch-fill-button"
            isOnlyIcon
            icon="user-group-plus"
            variant="secondary"
            mr="sm"
            onClick={() => setOpenFillBatchModal(true)}
          />

          <TextField
            value={search}
            onChange={(event) => setSearch(event.target.value)}
            placeholder={t('form.search_student')}
            fullWidth
            icon="search"
            clearable
          />
        </Box>

        {getStudents.map((student) => (
          <Box key={student.id} data-testid="student-item">
            {renderCurrentSection(student)}
          </Box>
        ))}

        {showEmptySearch && (
          <EmptyState
            imgUrl={IMAGES_CLASSROOMS_URL.emptyStateUrl}
            message={
              <Text variant="body-bold-14">
                {t('form.empty_student_search_list')}
              </Text>
            }
          />
        )}

        {showFooterInfo && (
          <Box>
            <Box display="flex" alignItems="center" mt="xl">
              <Icon name="info-circle" />
              <Text
                variant="label-regular-14"
                color="neutral.gray2"
                ml="xs2"
                mb={0}
              >
                {t('form.footer_info')}
              </Text>
            </Box>
          </Box>
        )}
      </S.SectionsWrapper>

      {openFillBatchModal && (
        <FillBatchModal onClose={() => setOpenFillBatchModal(false)} />
      )}
    </>
  );
};
