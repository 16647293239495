import React, { useState } from 'react';
import PropTypes from 'prop-types';

import TextField from 'components/TextField';

import * as S from './styles';

const TextFieldWithSuggestions = ({
  value,
  onChange,
  disabled,
  placeholder,
  suggestions,
}) => {
  const [textMatches, setTextMatches] = useState(suggestions);
  const [showSuggestions, setShowSuggestions] = useState(false);

  const handleOnchange = (value) => {
    const matches = suggestions.filter((suggestion) => {
      const escapedValue = value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
      const regex = new RegExp(escapedValue, 'gi');
      return suggestion.match(regex);
    });
    setTextMatches(matches);
    onChange(value);
  };

  const handleOnSelectSuggestion = (value) => {
    onChange(value);
    setTextMatches(suggestions);
  };
  return (
    <S.Container suggestionsList={textMatches}>
      <TextField
        value={value}
        data-testId="user-type-text"
        onChange={(e) => handleOnchange(e.target.value)}
        disabled={disabled}
        placeholder={placeholder}
        onFocus={() => setShowSuggestions(true)}
        onBlur={() =>
          setTimeout(() => {
            setShowSuggestions(false);
          }, 200)
        }
      />
      {showSuggestions && !!textMatches.length && (
        <S.SuggestionsGroup>
          {textMatches.map((match, index) => (
            <S.SuggestionOption
              onClick={() => handleOnSelectSuggestion(match)}
              key={index}
            >
              {match}
            </S.SuggestionOption>
          ))}
        </S.SuggestionsGroup>
      )}
    </S.Container>
  );
};

TextFieldWithSuggestions.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  suggestions: PropTypes.array,
};

export default TextFieldWithSuggestions;
