import styled, { css } from 'styled-components';

import { ThemeProps } from 'config/themes/types';

export const DiaryDetailsWrapper = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 100dvh;
    padding: ${space.xl};
    gap: ${space.xl};
  `}
`;

export const HeaderWrapper = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 560px) {
      flex-direction: column;
      align-items: flex-start;
      gap: ${space.lg};
    }
  `}
`;

export const ActionWrapper = styled.div`
  ${({ theme: { breakpoints, space } }: ThemeProps) => css`
    display: flex;
    gap: ${space.md};

    @media (max-width: ${breakpoints.desktopLG}) {
      flex-direction: column;

      .SingleDatePicker {
        width: 100%;

        .SingleDatePickerInput {
          width: 100%;
        }
      }
    }
  `}
`;

export const ColumnsWrapper = styled.div`
  ${({ theme: { breakpoints, space } }: ThemeProps) => css`
    display: flex;
    gap: ${space.xl};

    @media (max-width: ${breakpoints.desktopLG}) {
      flex-direction: column;
    }
  `}
`;

export const StudentListWrapper = styled.div`
  ${({ theme: { breakpoints } }: ThemeProps) => css`
    display: flex;
    flex-direction: column;
    width: 35%;

    @media (max-width: ${breakpoints.desktopXL}) {
      width: 100%;
    }
  `}
`;

export const TitleInfoWrapper = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    flex-direction: column;
    gap: ${space.xs};
  `}
`;

export const Breadcrumbs = styled.div`
  ${({ theme: { colors, space } }: ThemeProps) => css`
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: ${space.xs3};
    transition: background-color 0.25s;

    > svg {
      width: 12px;
      height: 12px;

      path {
        fill: ${colors.brand.primary.default};
      }
    }

    &:hover {
      p {
        color: ${colors.brand.primary.light};
      }

      > svg {
        path {
          fill: ${colors.brand.primary.light};
        }
      }
    }
  `}
`;
