import styled, { css } from 'styled-components';

import { TextProps } from './types';

export const Container = styled.div<TextProps>`
  ${({ font, weight, size, color }) => css`
    font-family: ${font}, sans-serif;
    font-size: ${size && size}px;
    font-weight: ${weight && weight};
    color: ${color && color};
  `}
`;
