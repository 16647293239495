import Button from 'components/Button';
import styled, { css } from 'styled-components';

export const WalletListContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 400px;
  overflow-y: auto;
`;

export type WalletCardProps = {
  isActive: boolean;
};

export const WalletCard = styled(Button)<WalletCardProps>`
  ${({ theme, isActive }) => css`
    display: flex;
    border: 1px solid ${theme.gray12};
    border-radius: 8px;
    margin-bottom: 16px;
    justify-content: space-between;
    align-items: center;

    :hover {
      background-color: ${theme.primaryColor}25;
      border-color: ${theme.primaryColor}30;
    }
    ${isActive
      ? css`
          background-color: ${theme.primaryColor}10;
        `
      : css`
          background-color: ${theme.white};
        `}
  `}
`;

export const WalletCardDetails = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    padding: 6px;
    align-items: flex-start;

    .PageTitle {
      color: ${theme.black};
      font-family: 'Quicksand';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 100%;
      margin-bottom: 12px;
    }
    .Label {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 130%;
      white-space: normal;
      text-align: left;
      color: ${theme.black};
      max-width: 285px;
      margin-bottom: 8px;
    }
  `}
`;

export const WalletStatus = styled.div`
  ${({ theme }) => css`
    display: flex;
    padding: 4px 6px 4px 6px;
    background-color: ${theme.gray5};
    border-radius: 4px;
    width: fit-content;
    margin-top: 10px;
  `}
`;

export const WalletStatusText = styled.span`
  ${({ theme }) => css`
    font-family: 'Quicksand';
    font-weight: 700;
    font-size: 13px;
    color: ${theme.gray2};
  `}
`;

export const SelectButton = styled.div`
  ${({ theme }) => css`
    display: flex;
    width: 22px;
    height: 22px;
    border-radius: 24px;
    background-color: ${theme.white};
    border: solid 2px ${theme.primaryColor}60;
    justify-content: center;
    align-items: center;
  `}
`;

export const IconButton = styled.div`
  ${({ theme }) => css`
    display: flex;
    height: 14px;
    width: 14px;
    border-radius: 12px;
    background-color: ${theme.primaryColor};
  `}
`;
