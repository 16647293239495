import React from 'react';
import { Box, DefaultThemeProps, theme } from '@agendaedu/ae-web-components';

import Skeleton from 'components/Skeleton';

export const SectionsFormSkeleton = () => {
  const defaultTheme: DefaultThemeProps = theme;

  const {
    colors: { neutral },
    border: { radius },
    breakpoints,
  } = defaultTheme;

  return (
    <Box
      key="skeleton-item"
      width="100%"
      maxWidth={breakpoints.tabletMD}
      mt="xl"
      mx="auto"
      overflow="hidden"
    >
      <Box position="relative" height="50px" mb="lg">
        <Skeleton
          width="100%"
          height="50px"
          primaryColor={neutral.gray6}
          borderRadius={radius.md}
          isFullWidth
        />
      </Box>
      <Box position="relative" height="100px" mb="lg">
        <Skeleton
          width="100%"
          height="100px"
          primaryColor={neutral.gray6}
          borderRadius={radius.md}
          isFullWidth
        />
      </Box>
      <Box height="100px">
        <Skeleton
          width="100%"
          height="100px"
          primaryColor={neutral.gray6}
          borderRadius={radius.md}
          isFullWidth
        />
      </Box>
    </Box>
  );
};
