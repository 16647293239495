import {
  all,
  call,
  cancelled,
  fork,
  put,
  select,
  take,
  takeLatest,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
  fetchApi,
  postApi,
  handleError,
  deleteApi,
  patchApi,
  putApi,
} from 'core/utils/api';
import {
  editGroupNormalizeParams,
  getCurrentGroupWithMembers,
  getGroupListWithMembers,
} from 'core/helper/message';
import { reverseArray } from 'core/utils/reverseArray';
import {
  buildToast,
  toastCss,
  ToastTypes,
} from 'store/middlewares/toast/actions';

import { normalizeNewGroup } from 'core/helper/message/group/normalizeNewGroup';
import { normalizeGroupMessage } from 'core/helper/message/group/normalizeGroupMessage';

import i18n from 'config/i18n';

import actions from './actions';

import {
  CreateNewGroupSagaProps,
  CreateNewGroupMessageSagaProps,
  fetchGroupsSagaProps,
  fetchLastMessageSagaProps,
  fetchMessagesSagaProps,
  setArchiveGroupRequestSagaProps,
  setCurrentGroupRequestSagaProps,
  setMuteAndTurnOnNotificationRequestSagaProps,
  UpdateMessageSagaProps,
  fetchGroupDetailsSagaProps,
  EditGroupSagaProps,
  DeleteGroupSagaProps,
  MessageState,
  SetLeaveGroupRequestSagaProps,
  FetchGroupMembersSagaProps,
  DeleteMessageSagaProps,
  SocketEmitParams,
  SocketEnterGroupRoomParams,
} from './types';
import { io, Socket } from 'socket.io-client';
import { eventChannel } from 'redux-saga';

const socketEvents = ['new-message', 'delete-message', 'edit-message'];

function socketEventListener(socket: Socket) {
  return (emit: (params: SocketEmitParams) => void) => {
    socketEvents.map((eventName) => {
      socket?.on(eventName, (messageId, groupId) => {
        const params = {
          groupId,
          messageId,
          eventName,
        };
        emit(params);
      });
    });

    return () => {
      socketEvents.map((eventName) => socket.off(eventName));
    };
  };
}

function* socketListenerSaga() {
  const { socket } = yield select((state) => state.messages);

  const channel = eventChannel(socketEventListener(socket));

  const eventActionMap = {
    'new-message': actions.FETCH_LAST_MESSAGE_REQUEST,
    'edit-message': actions.FETCH_REAL_TIME_MESSAGE_REQUEST,
    'delete-message': actions.FETCH_REAL_TIME_MESSAGE_REQUEST,
    'leave-room': actions.FETCH_LAST_MESSAGE_REQUEST,
  };

  try {
    while (true) {
      const event = yield take(channel);

      const actionType = eventActionMap[event?.eventName];
      if (actionType) {
        yield put({
          type: actionType,
          params: {
            ...event,
          },
        });
      }
    }
  } finally {
    if (yield cancelled()) {
      channel.close();
    }
  }
}

function* socketConnectSaga() {
  try {
    const { socket: socketState } = yield select((state) => state.messages);

    if (socketState) return;

    const socket = io(
      process.env.WEBSOCKETS_SERVER_URL || 'ws://localhost:8080'
    );

    yield put({
      type: actions.SOCKET_CONNECT_SERVER_SUCCESS,
      socket,
    });

    yield all([fork(socketListenerSaga)]);
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        handleError(error),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* socketEnterGroupRoomSaga({ groupId }: SocketEnterGroupRoomParams) {
  try {
    const { socket } = yield select((state) => state.messages);

    if (!socket) return;

    yield socket.emit('join-room', groupId);
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        handleError(error),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* socketLeaveGroupRoomSaga({ groupId }: SocketEnterGroupRoomParams) {
  try {
    const { socket } = yield select((state) => state.messages);

    if (!socket) return;

    yield socket.emit('leave-room', groupId);
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        handleError(error),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* createNewGroupRequestSaga({
  params,
}: CreateNewGroupSagaProps) {
  try {
    const { dataArea } = yield select((state) => state.root);

    const formData = normalizeNewGroup(params);

    const { data, included } = yield call(
      postApi,
      `/${dataArea}/messages/groups`,
      formData
    );

    const newGroupCreated = getCurrentGroupWithMembers(data, included);

    yield put(push(`/${dataArea}/messages/groups`));

    yield put({
      type: actions.CREATE_NEW_GROUP_SUCCESS,
      newGroupCreated,
      toast: buildToast(
        i18n.t('messages:groups.toasts.success_create_new_group', {
          name: params.name,
        }),
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        handleError(error),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* createNewMessageRequestSaga({
  params,
}: CreateNewGroupMessageSagaProps) {
  try {
    const { groupId, content, document, attachedId } = params;

    const { dataArea } = yield select((state) => state.root);

    const msgFormData = new FormData();

    content && msgFormData.append('content', content);

    if (document) {
      msgFormData.append(
        'document',
        document.getUploadedFile(),
        document.getName()
      );
    }

    if (attachedId) {
      msgFormData.append('attached_message_id', attachedId);
    }

    const { data, included } = yield call(
      postApi,
      `/${dataArea}/messages/groups/${groupId}/messages`,
      msgFormData
    );

    const message = normalizeGroupMessage({
      messages: [data],
      included,
    });

    yield put({
      type: actions.CREATE_NEW_MESSAGE_SUCCESS,
      newGroupMessageCreated: message[0],
      groupId,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        handleError(error),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* fetchSchoolUsersRequestSaga() {
  try {
    const { dataArea } = yield select((state) => state.root);

    const { data: schoolUsers } = yield call(
      fetchApi,
      `/${dataArea}/messages/groups/search_new_members.json?without_master=true`
    );

    yield put({
      type: actions.FETCH_SCHOOL_USERS_SUCCESS,
      schoolUsers,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        handleError(error),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* fetchGroupDetailsRequestSaga({
  groupId,
}: fetchGroupDetailsSagaProps) {
  try {
    const { dataArea } = yield select((state) => state.root);

    const { data, included } = yield call(
      fetchApi,
      `/${dataArea}/messages/groups/${groupId}`
    );

    yield put({
      type: actions.FETCH_GROUP_DETAILS_SUCCESS,
      group: getGroupListWithMembers([data], included)[0],
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        handleError(error),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* fetchGroupsRequestSaga({ userId }: fetchGroupsSagaProps) {
  try {
    const { dataArea } = yield select((state) => state.root);

    const { data, included } = yield call(
      fetchApi,
      `/${dataArea}/messages/groups?school_user_id=${userId}`
    );

    const groups =
      data && reverseArray(getGroupListWithMembers(data, included));

    yield put({
      type: actions.FETCH_GROUPS_SUCCESS,
      groups,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        handleError(error),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* fetchGroupMembersRequestSaga({
  groupId,
  page,
}: FetchGroupMembersSagaProps) {
  try {
    const { dataArea } = yield select((state) => state.root);

    const { data: members, meta } = yield call(
      fetchApi,
      `/${dataArea}/messages/groups/${groupId}/members?page[number]=${page}`
    );

    yield put({
      type: actions.FETCH_GROUP_MEMBERS_SUCCESS,
      members,
      meta,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        handleError(error),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* fetchMessagesRequestSaga({ params }: fetchMessagesSagaProps) {
  const { groupId, page } = params;

  try {
    const { dataArea } = yield select((state) => state.root);

    const { data, included } = yield call(
      fetchApi,
      `/${dataArea}/messages/groups/${groupId}/messages?page=${page}`
    );

    const messagesNormalized = normalizeGroupMessage({
      messages: data,
      included,
    });

    const messages = data && reverseArray(messagesNormalized);

    yield put({
      type: actions.FETCH_MESSAGES_SUCCESS,
      messages,
      pagination: {
        page,
      },
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        handleError(error),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* fetchLastMessageRequestSaga({
  params,
}: fetchLastMessageSagaProps) {
  const { groupId, messageId } = params;

  try {
    const { dataArea } = yield select((state) => state.root);

    const { showLeaveGroupModal } = yield select((state) => state.messages);

    if (showLeaveGroupModal) return;

    const { data, included } = yield call(
      fetchApi,
      `/${dataArea}/messages/groups/${groupId}/messages/${messageId}`
    );

    const message = normalizeGroupMessage({
      messages: [data],
      included,
    })[0];

    yield put({
      type: actions.FETCH_LAST_MESSAGE_SUCCESS,
      groupId,
      message,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        handleError(error),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* fetchRealTimeMessageRequestSaga({
  params,
}: UpdateMessageSagaProps) {
  const { groupId, messageId } = params;

  try {
    const { dataArea } = yield select((state) => state.root);

    const { data, included } = yield call(
      fetchApi,
      `/${dataArea}/messages/groups/${groupId}/messages/${messageId}`
    );

    const updatedMessage = normalizeGroupMessage({
      messages: [data],
      included,
    })[0];

    yield put({
      type: actions.FETCH_REAL_TIME_MESSAGE_SUCCESS,
      groupId,
      messageId,
      message: updatedMessage,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
    });
  }
}

export function* setArchiveGroupRequestSaga({
  currentGroup,
}: setArchiveGroupRequestSagaProps) {
  try {
    const { dataArea } = yield select((state) => state.root);

    yield call(deleteApi, `/${dataArea}/messages/groups/${currentGroup.id}`);

    yield put({
      type: actions.SET_ARCHIVE_GROUP_SUCCESS,
      currentGroup,
      toast: buildToast(
        `O grupo "${currentGroup.attributes.name}" foi arquivado com sucesso.`,
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        `Ops, houve um erro ao tentar arquivar o grupo "${currentGroup.attributes.name}". Tente novamente!`,
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* setDeleteGroupRequestSaga({ groupId }: DeleteGroupSagaProps) {
  const { currentGroup } = yield select(
    (state: MessageState) => state.messages
  );

  try {
    const { dataArea } = yield select((state) => state.root);

    yield call(deleteApi, `/${dataArea}/messages/groups/${groupId}`);

    yield put({
      type: actions.SET_DELETE_GROUP_SUCCESS,
      toast: buildToast(
        i18n.t('messages:groups.toasts.success_delete_group', {
          name: currentGroup?.attributes.name,
        }),
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });

    yield put(push(`/${dataArea}/messages/groups`));
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        i18n.t('messages:groups.toasts.error_delete_group', {
          name: currentGroup?.attributes.name,
        }),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* setDeleteMessageRequestSaga({
  groupId,
  messageId,
}: DeleteMessageSagaProps) {
  try {
    const { dataArea } = yield select((state) => state.root);

    const { data: deletedMessage } = yield call(
      deleteApi,
      `/${dataArea}/messages/groups/${groupId}/messages/${messageId}`
    );

    yield put({
      type: actions.SET_DELETE_MESSAGE_SUCCESS,
      deletedMessage,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        i18n.t('messages:groups.toasts.error_delete_message'),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* setEditGroupRequestSaga({
  form,
  groupId,
}: EditGroupSagaProps) {
  try {
    const { dataArea } = yield select((state) => state.root);

    const formData = editGroupNormalizeParams(form);

    const { data, included } = yield call(
      putApi,
      `/${dataArea}/messages/groups/${groupId}`,
      formData
    );

    const group = getGroupListWithMembers([data], included)[0];

    yield put({
      type: actions.SET_EDIT_GROUP_SUCCESS,
      group,
      toast: buildToast(
        i18n.t('messages:groups.toasts.success_edit_group', {
          name: form.name,
        }),
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });

    yield put(push(`/${dataArea}/messages/groups`));
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        i18n.t('messages:groups.toasts.error_edit_group', {
          name: form.name,
        }),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* setMuteAndTurnOnNotificationRequestSaga({
  currentGroup,
}: setMuteAndTurnOnNotificationRequestSagaProps) {
  const enableSound = !currentGroup.attributes.enable_sound;
  const currentMemberId = currentGroup.attributes.current_member_logged_id;

  try {
    const { dataArea } = yield select((state) => state.root);

    yield call(
      patchApi,
      `/${dataArea}/messages/groups/${currentGroup.id}/members/${currentMemberId}?enable_sound=${enableSound}`
    );

    yield put({
      type: actions.SET_MUTE_AND_TURN_ON_NOTIFICATION_SUCCESS,
      currentGroup,
      enableSound,
      toast: buildToast(
        enableSound
          ? `As notificações do grupo "${currentGroup.attributes.name}" foram ativadas com sucesso. Você irá receber notificações.`
          : `O grupo "${currentGroup.attributes.name}" foi silenciado com sucesso. Você não receberá mais notificações.`,
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        enableSound
          ? `Ops, houve um erro ao ativar as notificações deste grupo. Tente novamente!`
          : `Ops, houve um erro ao tentar silenciar este grupo. Tente novamente!`,
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  }
}

export function* setCurrentGroupRequestSaga({
  group,
}: setCurrentGroupRequestSagaProps) {
  const {
    id,
    attributes: { current_member_logged_id: memberId },
  } = group;

  try {
    const { dataArea } = yield select((state) => state.root);

    yield call(
      patchApi,
      `/${dataArea}/messages/groups/${id}/members/${memberId}?unread_message_count=0`
    );

    yield put({
      type: actions.SET_CURRENT_GROUP_SUCCESS,
      group,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
    });
  }
}

export function* setLeaveGroupRequestSaga({
  groupId,
}: SetLeaveGroupRequestSagaProps) {
  const {
    currentGroup: {
      attributes: { name, current_member_logged_id: memberId },
    },
  } = yield select((state: MessageState) => state.messages);

  try {
    const { dataArea } = yield select((state) => state.root);

    yield call(
      deleteApi,
      `/${dataArea}/messages/groups/${groupId}/members/${memberId}`
    );

    yield put({
      type: actions.SOCKET_LEAVE_GROUP_ROOM_REQUEST,
      groupId,
    });

    yield put({
      type: actions.SET_LEAVE_GROUP_SUCCESS,
      toast: buildToast(
        i18n.t('messages:groups.toasts.success_leave_group', {
          name,
        }),
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        i18n.t('messages:groups.toasts.error_leave_group', {
          name,
        }),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* setReadCurrentMessagesRequestSaga({
  currentGroup,
}: setCurrentGroupRequestSagaProps) {
  const {
    id,
    attributes: { current_member_logged_id: memberId },
  } = currentGroup;

  try {
    const { dataArea } = yield select((state) => state.root);

    yield call(
      patchApi,
      `/${dataArea}/messages/groups/${id}/members/${memberId}?unread_message_count=0`
    );

    yield put({ type: actions.SET_READ_CURRENT_MESSAGES_SUCCESS });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
    });
  }
}

export function* updateMessageRequestSaga({
  updateMessage,
}: UpdateMessageSagaProps) {
  const { groupId, messageId, updatedContent } = updateMessage;

  try {
    const { dataArea } = yield select((state) => state.root);

    const { data, included } = yield call(
      patchApi,
      `/${dataArea}/messages/groups/${groupId}/messages/${messageId}`,
      {
        content: updatedContent,
      }
    );

    const updatedMessage = normalizeGroupMessage({
      messages: [data],
      included,
    });

    yield put({
      type: actions.UPDATE_MESSAGE_SUCCESS,
      groupId,
      message: updatedMessage[0],
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
    });
  }
}

function* messagesSagas() {
  yield all([
    takeLatest(actions.CREATE_NEW_GROUP_REQUEST, createNewGroupRequestSaga),
    takeLatest(actions.CREATE_NEW_MESSAGE_REQUEST, createNewMessageRequestSaga),
    takeLatest(actions.FETCH_SCHOOL_USERS_REQUEST, fetchSchoolUsersRequestSaga),
    takeLatest(
      actions.FETCH_GROUP_DETAILS_REQUEST,
      fetchGroupDetailsRequestSaga
    ),
    takeLatest(actions.FETCH_GROUPS_REQUEST, fetchGroupsRequestSaga),
    takeLatest(
      actions.FETCH_GROUP_MEMBERS_REQUEST,
      fetchGroupMembersRequestSaga
    ),
    takeLatest(actions.FETCH_MESSAGES_REQUEST, fetchMessagesRequestSaga),
    takeLatest(actions.FETCH_LAST_MESSAGE_REQUEST, fetchLastMessageRequestSaga),
    takeLatest(
      actions.FETCH_REAL_TIME_MESSAGE_REQUEST,
      fetchRealTimeMessageRequestSaga
    ),
    takeLatest(actions.SET_ARCHIVE_GROUP_REQUEST, setArchiveGroupRequestSaga),
    takeLatest(actions.SET_DELETE_GROUP_REQUEST, setDeleteGroupRequestSaga),
    takeLatest(actions.SET_DELETE_MESSAGE_REQUEST, setDeleteMessageRequestSaga),
    takeLatest(actions.SET_EDIT_GROUP_REQUEST, setEditGroupRequestSaga),
    takeLatest(
      actions.SET_MUTE_AND_TURN_ON_NOTIFICATION_REQUEST,
      setMuteAndTurnOnNotificationRequestSaga
    ),
    takeLatest(actions.SET_CURRENT_GROUP_REQUEST, setCurrentGroupRequestSaga),
    takeLatest(actions.SET_LEAVE_GROUP_REQUEST, setLeaveGroupRequestSaga),
    takeLatest(
      actions.SET_READ_CURRENT_MESSAGES_REQUEST,
      setReadCurrentMessagesRequestSaga
    ),
    yield all([
      takeLatest(actions.SOCKET_CONNECT_SERVER_REQUEST, socketConnectSaga),
    ]),
    yield all([
      takeLatest(
        actions.SOCKET_ENTER_GROUP_ROOM_REQUEST,
        socketEnterGroupRoomSaga
      ),
    ]),
    takeLatest(
      actions.SOCKET_LEAVE_GROUP_ROOM_REQUEST,
      socketLeaveGroupRoomSaga
    ),
    takeLatest(actions.UPDATE_MESSAGE_REQUEST, updateMessageRequestSaga),
  ]);
}

export default messagesSagas;
