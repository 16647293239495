import styled, { css } from 'styled-components';
import Button from 'components/Button';

export const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const Label = styled.label`
  ${({ theme }) => css`
    color: ${theme.gray1};
    font-weight: 400;
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    margin-top: 12px;
  `}
`;

export const Content = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  margin-top: 12px;
`;

export const InputWithLabel = styled.div`
  display: flex;
  flex-direction: column;
  margin: 4px 9px 12px 0;
`;

export const InputContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 4px;

    > button {
      height: 40px;
      border: 1px solid ${theme.gray3};
      border-left: none;
      background-color: transparent;
      color: ${theme.danger};
      padding: 9px 10px;
      border-radius: 0 6px 6px 0;

      &:disabled {
        color: ${theme.gray3};
      }
    }

    > div {
      width: fit-content;
    }

    .Field {
      padding-right: 0;
      margin-bottom: 0 !important;

      input {
        width: 118px;
        font-family: 'Roboto', sans-serif;
        font-size: 16px;
        color: ${theme.black};
      }
    }
  `}
`;

export const WarningLabel = styled.label`
  ${({ theme }) => css`
    color: ${theme.danger};
    margin-left: 16px;
  `}
`;

export const AddButton = styled(Button)`
  margin-top: 12px;
  margin-left: 16px;
  width: 40px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;
