import styled from 'styled-components';
import { Box } from '@agendaedu/ae-web-components';

export const BoxContent = styled(Box)`
  height: calc(87vh - 70px);
  overflow: auto;
`;

export const StyledHr = styled.hr<{ mt?: number; mb?: number }>`
  ${(props) => !!props.mt && `margin-top: ${props.mt}px`};
  ${(props) => !!props.mb && `margin-bottom: ${props.mb}px`};
`;
