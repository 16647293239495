import React, { useContext } from 'react';

import TextField from 'components/TextField';
import AgendaIcon from 'components/AgendaIcon';
import DailySummariesContext from 'core/contexts/DailySummaries';
import Button from 'components/Button';

import Card from '../';
import * as S from './styles';

const BabyBottleCard = () => {
  const {
    form: { babyBottles },
    handleForm,
    isDisabled,
    hasStudentSelected,
  } = useContext(DailySummariesContext);
  const { quantities, notDrink } = babyBottles;

  const toggleNotDrink = () =>
    handleForm('babyBottles', {
      notDrink: !notDrink,
      quantities,
    });

  const removeBabyBottle = (id) =>
    quantities.length > 1 &&
    handleForm('babyBottles', {
      notDrink,
      quantities: quantities.filter((babyBottle) => babyBottle.id !== id),
    });

  const addBabyBottle = () =>
    handleForm('babyBottles', {
      notDrink,
      quantities: [
        ...quantities,
        { id: Math.random().toString(36).substring(7), quantity: '' },
      ],
    });

  const onChange = (index, value) => {
    quantities[index].quantity = value;

    handleForm('babyBottles', {
      notDrink,
      quantities: quantities,
    });
  };

  const disableCard = !hasStudentSelected || isDisabled;

  return (
    <Card
      data-testid="baby-bottle-card"
      title="Mamadeira"
      checkboxLabel="Não bebeu"
      isChecked={notDrink}
      onChangeCheckbox={toggleNotDrink}
      disabled={disableCard}
    >
      <S.Wrapper>
        <S.Content>
          {quantities.map(({ id, quantity }, index) => (
            <S.FieldWithLabel key={id}>
              <S.Label>Indique a quantidade</S.Label>
              <S.InputContainer disabled={disableCard}>
                <TextField
                  type="text"
                  disabled={disableCard}
                  value={quantity}
                  placeholder="Ex: 100ml, recusou etc."
                  onChange={(e) => onChange(index, e.target.value)}
                />

                <button
                  data-testid="remove-baby-bottle-button"
                  onClick={() => removeBabyBottle(id)}
                  disabled={quantities.length === 1 || disableCard}
                >
                  <AgendaIcon name="trash" size="medium" />
                </button>
              </S.InputContainer>
            </S.FieldWithLabel>
          ))}
        </S.Content>
        {!disableCard && (
          <Button
            data-testid="add-baby-bottle-button"
            variation="secondary"
            onClick={addBabyBottle}
          >
            <AgendaIcon name="plus" size="small" />
          </Button>
        )}
      </S.Wrapper>
    </Card>
  );
};

export default BabyBottleCard;
