import React from 'react';
import {
  Text,
  Card,
  Box,
  DefaultThemeProps,
} from '@agendaedu/ae-web-components';

import * as S from './styles';

import { OwnProps } from './types';
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

const TrainingCard = ({ training, openModal }: OwnProps): JSX.Element => {
  const {
    attributes: { title, subtitle, thumbnail, tag_new },
  } = training;
  const {
    border: { radius },
  } = useTheme() as DefaultThemeProps;
  const { t } = useTranslation('home');

  return (
    <Card
      data-testid="training-item"
      height="100%"
      p="xs"
      cursor="pointer"
      onClick={() => openModal(training)}
    >
      <Box height="100%">
        <S.ThumbnailWrapper hasTagNew={tag_new}>
          <S.ThumbnailImage src={thumbnail} />
          {tag_new && (
            <Box
              position="absolute"
              bottom="xs"
              left="xs"
              backgroundColor="neutral.white"
              zIndex={3}
              borderRadius={radius.md}
              overflow="hidden"
            >
              <Text
                color="brand.primary.default"
                variant="subtitle-medium-12"
                m="xs2"
              >
                {t('new')}
              </Text>
            </Box>
          )}
        </S.ThumbnailWrapper>

        <Box>
          <Text variant="subtitle-medium-14" color="neutral.black" my="sm">
            {title}
          </Text>

          <Text
            variant="subtitle-medium-12"
            color="neutral.gray1"
            lineHeight="lg"
            lineClamp={3}
            mb="xs2"
          >
            {subtitle}
          </Text>
        </Box>
      </Box>
    </Card>
  );
};

export default TrainingCard;
