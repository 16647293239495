import React from 'react';

import ButtonRow from 'components/ButtonRow';
import Button from 'components/Button';
import { ButtonsProps } from './types';

function Buttons({
  title,
  isDisable,
  toggleModal,
  variation,
  manuallySubmit,
}: ButtonsProps) {
  return (
    <ButtonRow>
      <Button variation="secondary" onClick={toggleModal}>
        Voltar
      </Button>
      <Button
        testId="submit-button"
        variation={variation}
        disabled={isDisable}
        onClick={manuallySubmit}
      >
        {title}
      </Button>
    </ButtonRow>
  );
}

export default Buttons;
