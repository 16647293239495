import React from 'react';
import PropTypes from 'prop-types';
import { ClipLoader } from 'react-spinners';

import withAppContext from 'core/hoc/withAppContext';

import './style.scss';

const propTypes = {
  appContext: PropTypes.shape({
    primaryColor: PropTypes.string.isRequired,
  }),
  children: PropTypes.node,
  isLoading: PropTypes.bool,
  variation: PropTypes.oneOf(['default', 'content', 'centered']),
  color: PropTypes.string,
  size: PropTypes.number,
  wrapperClassName: PropTypes.string,
  customLoadElement: PropTypes.node,
  dataTestId: PropTypes.string,
};

const defaultProps = {
  isLoading: false,
  variation: 'centered',
  color: '#644696',
  wrapperClassName: '',
};

function Loader({
  appContext,
  children,
  isLoading,
  variation,
  color,
  size,
  wrapperClassName,
  customLoadElement,
  dataTestId = 'loader',
}) {
  if (children && !isLoading) {
    return children;
  }

  if (customLoadElement && isLoading) {
    return customLoadElement;
  }

  return (
    <div
      className={`Loader ${variation} ${wrapperClassName}`}
      data-testid={dataTestId}
    >
      <ClipLoader
        color={color || appContext.primaryColor}
        loading
        size={size}
      />
    </div>
  );
}

Loader.propTypes = propTypes;

Loader.defaultProps = defaultProps;

export default withAppContext(Loader);
