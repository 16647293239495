const actions = {
  SET_ERROR: 'SET_ERROR/inviteEmptyState',
  GENERATE_INVITE_URL: 'GENERATE_INVITE_URL/inviteEmptyState',
  GENERATE_INVITE_URL_SUCCESS: 'GENERATE_INVITE_URL_SUCCESS/inviteEmptyState',
  generateInviteUrl: (resource) => ({
    type: actions.GENERATE_INVITE_URL,
    resource,
  }),
};

export default actions;
