import styled, { css } from 'styled-components';
import { WrapperStyleProps } from './types';

export const Wrapper = styled.div<WrapperStyleProps>`
  ${({ disabled, theme: { breakpoints, space } }) => css`
    display: flex;
    position: absolute;
    bottom: 70px;
    left: 0;
    margin: 0 ${space.sm};

    > section {
      border-radius: ${space.xs2};
    }

    @media screen and (max-width: ${breakpoints.mobileSM}) {
      .emoji-mart {
        width: 100% !important;
      }
    }

    ${disabled &&
    css`
      display: none;
    `}
  `}
`;
