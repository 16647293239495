import styled, { css } from 'styled-components';

import Button from 'components/Button';

export const MainContainer = styled.section`
  padding: 0 5%;
  height: auto;
  margin-top: 76px;
`;

export const MainContainerHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const MainTitle = styled.h3`
  ${({ theme }) => css`
    font-family: Quicksand, sans-serif;
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    color: ${theme.black};
    margin: 0;
  `}
`;

export const MainButton = styled(Button)``;
