import React, { Component } from 'react';
import autobind from 'autobind-decorator';

/**
 * @example
 *
 *    import hoverable from 'core/hoc/hoverable';
 *
 *    const Component = ({ isHovering, enterHoverState, leaveHoverState }) => (
 *      <span onMouseOver={enterHoverState} onMouseLeave={leaveHoverState}>
 *        {isHovering ? 'Mouse\'s here!' : 'Mouse isn\'t here!'}
 *      </span>
 *    );
 *
 *    export default hoverable(Component);
 */
function hoverable(WrappedComponent) {
  class Hoverable extends Component {
    state = {
      isHovering: false,
    };

    @autobind
    enterHoverState() {
      this.setState({ isHovering: true });
    }

    @autobind
    leaveHoverState() {
      this.setState({ isHovering: false });
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          isHovering={this.state.isHovering}
          enterHoverState={this.enterHoverState}
          leaveHoverState={this.leaveHoverState}
        />
      );
    }
  }

  return Hoverable;
}

export default hoverable;
