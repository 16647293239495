/* eslint-disable import/no-unresolved */
import React from 'react';
import PropTypes from 'prop-types';

import ModalContainer from 'components/Modal/ModalContainer';
import ModalTitle from 'components/Modal/ModalTitle';
import ButtonRow from 'components/ButtonRow';
import Button from 'components/Button';

import './style.scss';

const DelayModal = ({ isOpen, subTitle, title, onDelay, toggleModal }) => {
  const clearBeforeAction = (action) => {
    action();
  };

  return (
    <ModalContainer
      className="DelayBillModal"
      isOpen={isOpen}
      toggleModal={toggleModal}
    >
      <ModalTitle>{title}</ModalTitle>
      {subTitle}
      <ButtonRow>
        <Button
          variation="secondary"
          onClick={() => clearBeforeAction(toggleModal)}
        >
          Fechar
        </Button>
        <Button onClick={() => clearBeforeAction(onDelay)}>
          Atrasar cobrança
        </Button>
      </ButtonRow>
    </ModalContainer>
  );
};

DelayModal.propTypes = {
  title: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onDelay: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
};

export default DelayModal;
