const numberArrayGenerator = (max, min) =>
  Array(...Array(min ? max - min : max)).map((_, i) => (min ? i + min : i + 1));

export default (minBill) => {
  let bill = minBill === 1 ? 0 : minBill;

  const altenateDays = [
    { value: 3, label: '3 dias antes do vencimento' },
    { value: 4, label: '4 dias antes do vencimento' },
    { value: 5, label: '5 dias antes do vencimento' },
    { value: 6, label: '6 dias antes do vencimento' },
    { value: 7, label: '7 dias antes do vencimento' },
    { value: 14, label: '14 dias antes do vencimento' },
    { value: 21, label: '21 dias antes do vencimento' },
    { value: 30, label: '30 dias antes do vencimento' },
  ];

  altenateDays.unshift({ value: null, label: 'Selecionar dia da cobrança' });

  const billsQuantity = numberArrayGenerator(24, bill).map((i) => {
    return { value: i, label: i };
  });

  billsQuantity.unshift({ value: null, label: 'Selecionar quantidade' });

  const dueDay = numberArrayGenerator(28)
    .map((i) => ({ value: i, label: `Todo dia ${i} do mês` }))
    .concat({ value: -1, label: 'Todo último dia do mês' });

  dueDay.unshift({ value: null, label: 'Selecionar dia do vencimento' });

  return {
    bills_quantity: billsQuantity,
    due_day: dueDay,
    bill_release_day: altenateDays,
    discount_kind: [
      {
        name: '%',
        value: 'percent',
      },
      {
        name: 'R$',
        value: 'money',
      },
    ],
  };
};
