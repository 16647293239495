import React from 'react';

import { useTranslation } from 'react-i18next';

import { Box, Button, Modal, Text } from '@agendaedu/ae-web-components';
import { Props } from './types';

const ChangeMediaConfirmModal = ({
  isOpen,
  onClose,
  onConfirm,
}: Props): JSX.Element => {
  const { t } = useTranslation(['albums', 'common']);

  const onCloseAction = () => {
    onConfirm();
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      title={t('album_details.change_media_confirm_media.title')}
      onClose={onClose}
    >
      <Box width="100%">
        <Text
          data-testid="text-content-modal"
          variant="body-regular-16"
          mx="xs3"
          maxWidth={360}
          lineHeight="lg"
        >
          {t('album_details.change_media_confirm_media.content')}
        </Text>

        <Box display="flex" justifyContent="flex-end" mt="lg">
          <Button
            testId="back-button"
            variant="secondary"
            mr="sm"
            onClick={onClose}
          >
            {t('common:button.back')}
          </Button>
          <Button testId="confirm-button" onClick={onCloseAction}>
            {t('common:button.confirm')}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ChangeMediaConfirmModal;
