import styled, { css } from 'styled-components';

export const WalletContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    border-radius: 8px;
    border: 1px solid ${theme.gray12};
    background-color: ${theme.white};
    margin-bottom: 24px;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 480px) {
      flex-direction: column;
    }

    .PageSubTitle {
      font-family: 'Quicksand';
      color: ${theme.black};
      font-weight: 600;
      margin-top: 0px;
      margin-bottom: 2px;
    }

    .PageSubTitle:only-child {
      margin: 0;
    }

    .change-wallet-button {
      padding: 4px;
      border-radius: 8px;
      :hover {
        background-color: ${theme.primaryColor}20;
      }
    }

    .button-icon {
      display: flex;
      align-items: center;
      padding: 0px;

      .AgendaIcon {
        font-size: 24px;
      }
    }
    .custom-size {
      font-size: 18px;
      color: ${theme.black};
    }
    .Tooltip {
      margin-top: 2px;
    }
    .transfer-button {
      margin-left: 14px;
    }
  `}
`;

export const WalletInformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
`;

export const WalletTransferContainer = styled.div`
  display: flex;
  padding: 16px;
  align-items: center;
  justify-content: center;

  .Button {
    border-radius: 6px;
  }
`;

export const WalletStatusText = styled.span`
  ${({ theme }) => css`
    font-family: 'Quicksand';
    font-weight: 700;
    font-size: 13px;
    color: ${theme.gray2};
  `}
`;

export const WalletStatus = styled.div`
  ${({ theme }) => css`
    display: flex;
    padding: 5px 8px 7px;
    background-color: ${theme.gray5};
    border-radius: 4px;
    width: fit-content;
    margin-right: 15px;
  `}
`;

export const WalletStatusContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 2px;
`;
