import styled from 'styled-components';

export const NewRecurrentPlanForm = styled.div`
  .FormFullScreen {
    width: 100%;
    height: auto;
    overflow: hidden;

    .WizardForm {
      height: auto;
    }
  }
`;
