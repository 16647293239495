import React, { useCallback } from 'react';
import { withRouter } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import withAppContext from 'core/hoc/withAppContext';

import actionHandouts from 'store/handouts/actions';
import { HandoutState } from 'store/handouts/types';

import { Modal } from '../Modal';

import * as S from './styles';

import { Props } from './types';

const NewHandoutModal: React.FC<Props> = ({
  appContext: {
    dataArea,
    policies: { can_show_handout_template: canShowHandoutTemplate },
  },
  history,
}) => {
  const { t } = useTranslation(['handouts']);
  const dispatch = useDispatch();

  const { toggleNewHandoutModal } = actionHandouts;

  const { showNewHandoutModal } = useSelector(
    (state: HandoutState) => state.handouts
  );

  const handleToggleNewHandoutModal = useCallback(() => {
    dispatch(toggleNewHandoutModal());
  }, [dispatch, toggleNewHandoutModal]);

  const handleNavigate = useCallback(
    (url: string) => {
      handleToggleNewHandoutModal();
      history.push(url);
    },
    [handleToggleNewHandoutModal, history]
  );

  return (
    <Modal.Root
      dataTestId="handout-modal"
      isOpen={showNewHandoutModal}
      title={t('modals.new_handout.title')}
      handleToggleModal={handleToggleNewHandoutModal}
      maxWidth="fit-content"
      overlapModal={false}
      marginAuto
    >
      <Modal.Content>
        <S.ButtonsWrappers>
          <S.Button
            id="blank-handout"
            data-testid="new-blank-handout-button"
            onClick={() => handleNavigate(`/${dataArea}/handouts/new`)}
          >
            <S.Image src="https://static.agendaedu.com/upload-images/novo.png" />

            <S.InfomationWrapper>
              <S.Title>{t('modals.new_handout.form_blank_title')}</S.Title>
              <S.Description>
                {t('modals.new_handout.form_blank_description')}
              </S.Description>
            </S.InfomationWrapper>
          </S.Button>

          {canShowHandoutTemplate ? (
            <S.Button
              id="template-handout"
              data-testId="new-template-handout-button"
              onClick={() => handleNavigate(`/${dataArea}/handouts/templates`)}
            >
              <S.Image src="https://static.agendaedu.com/upload-images/existente.png" />

              <S.InfomationWrapper>
                <S.Title>{t('modals.new_handout.form_template_title')}</S.Title>
                <S.Description>
                  {t('modals.new_handout.form_template_description')}
                </S.Description>
              </S.InfomationWrapper>
            </S.Button>
          ) : (
            <S.Button
              id="template-handout"
              data-testId="new-template-handout-button-disabled"
              disabled
            >
              <S.Image src="https://static.agendaedu.com/upload-images/template_disabled.png" />

              <S.InfomationWrapper>
                <S.Title>{t('modals.new_handout.form_template_title')}</S.Title>
                <S.Description>
                  {t('modals.new_handout.form_template_description')}
                </S.Description>
              </S.InfomationWrapper>
            </S.Button>
          )}
        </S.ButtonsWrappers>
      </Modal.Content>
    </Modal.Root>
  );
};

export default withRouter(withAppContext(NewHandoutModal));
