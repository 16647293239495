import styled, { css } from 'styled-components';

export const Container = styled.section`
  width: 90%;
  margin: 32px;

  @media screen {
    &.printContainer {
      visibility: hidden;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
`;

export const Content = styled.div`
  ${({ theme }) => css`
    display: flex;
    max-width: 90%;
    flex-direction: column;
    text-align: start;

    p {
      font-size: 14px;
      color: ${theme.black};
    }
  `}
`;
