import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';

import ModalTitle from 'components/Modal/ModalTitle';
import ModalBody from 'components/Modal/ModalBody';
import ButtonRow from 'components/ButtonRow';
import FilterContainer from 'components/FilterContainer';
import Loader from 'components/Loader';

import TimeField from 'components/Form/TimeField';
import DateField from 'components/Form/DateField';

import {
  ModalContainerContext,
  ModalSubtitle,
  Field,
  FieldLabel,
} from './style';

const ModalAction = ({
  showModal,
  toggleModal,
  variation,
  dataRow,
  redirectTo,
  history,
  titleContext,
  onActionModal,
  isLoading,
}) => {
  const [canSubmit, setCanSubmit] = useState(false);
  const [cancelReason, setCancelReason] = useState('');

  const [dateSchedule, setDateSchedule] = useState('');
  const [timeSchedule, setTimeSchedule] = useState('');

  const [scheduleActionType, setScheduleActionType] = useState('schedule');

  const [modalContext, setModalContext] = useState({
    title: '',
    description: '',
    buttons: [],
  });

  useEffect(() => {
    if (dataRow.schedule_date) {
      setScheduleActionType('reschedule');
      setDateSchedule(moment(dataRow.schedule_date).format('YYYY-MM-DD'));
      setTimeSchedule(moment(dataRow.schedule_date).format('HH:mm'));
    }
  }, [dataRow]);

  useEffect(() => {
    switch (variation) {
      case 'delete':
        setModalContext(deleteContext);
        break;
      case 'approve':
        setModalContext(approveContext);
        break;
      case 'resend':
        setModalContext(resendContext);
        break;
      case 'disapprove':
        setModalContext(disapproveContext);
        break;
      case 'schedule':
        setModalContext(scheduleContext);
        break;
      case 'reschedule':
        setModalContext(scheduleContext);
        break;
      case 'approveChedule':
        setModalContext(approveScheduleContext);
        break;
      case 'cancelSchedule':
        cancelScheduleContext();
        break;
    }
  }, [variation, cancelReason]);

  useEffect(() => {
    if (variation === 'cancelSchedule' && isLoading) {
      cancelScheduleContext();
    }
  }, [variation, isLoading]);

  const onChangeCancelReason = (cancelReason) => {
    setCancelReason(cancelReason);
    setCanSubmit(!!cancelReason);
  };

  const onChangeDateTimeSchedule = (value, type) => {
    let date = dateSchedule;
    let time = timeSchedule;

    switch (type) {
      case 'date':
        date = value ? value.format('YYYY-MM-DD') : null;
        setDateSchedule(date);
        break;
      case 'time':
        time = value ? value : null;
        setTimeSchedule(time);
        break;
      default:
        break;
    }

    setModalContext((modalContext) => ({
      ...modalContext,
      buttons: [
        {
          text: 'Cancelar',
          variation: 'secondary',
          onClick: toggleModal,
        },
        {
          text: scheduleActionType === 'schedule' ? 'Agendar' : 'Reagendar',
          variation: 'primary',
          onClick: () => handleSchedule(date, time),
        },
      ],
    }));
  };

  const handleDelete = () => {
    onActionModal({ action: 'Delete', data: dataRow });
    toggleModal(variation);
    if (redirectTo) history.push(redirectTo);
  };

  const handleResend = () => {
    onActionModal({ action: 'resend', data: dataRow });
    toggleModal(variation);
  };

  const handleApprove = () => {
    onActionModal({ action: 'approve', data: dataRow });
    toggleModal(variation);
  };

  const handleDisapprove = (cancelReason) => {
    onActionModal({
      action: 'disapprove',
      data: dataRow,
      reason: cancelReason,
    });
    toggleModal(variation);
  };

  const handleSchedule = (dateSchedule, timeSchedule) => {
    onActionModal({
      action: scheduleActionType === 'schedule' ? 'schedule' : 'reschedule',
      data: dataRow,
      date: dateSchedule,
      time: timeSchedule,
    });
    toggleModal(variation);
  };

  const handleApproveSchedule = () => {
    onActionModal({
      action: 'approveSchedule',
      data: dataRow,
    });
    toggleModal(variation);
  };

  const handleCancelSchedule = () => {
    onActionModal({
      action: 'cancelSchedule',
      data: dataRow,
    });
  };

  const deleteContext = {
    title: `Excluir ${titleContext}`,
    description: `A atividade será removida do aplicativo dos alunos e responsáveis. As informações só poderão ser acessadas pelo filtro de situação “Excluídas”.`,
    buttons: [
      {
        text: 'Cancelar',
        variation: 'secondary',
        onClick: toggleModal,
      },
      {
        text: 'Excluir',
        variation: 'danger',
        onClick: () => handleDelete(),
      },
    ],
  };

  const resendContext = {
    title: `Reenviar ${titleContext}`,
    description: `A ${titleContext} será reenviada para os destinatários das turmas que ainda não receberam. Tem certeza que deseja reenviar a ${titleContext}?`,
    buttons: [
      {
        text: 'Cancelar',
        variation: 'secondary',
        onClick: toggleModal,
      },
      {
        text: 'Reenviar',
        variation: 'primary',
        onClick: () => handleResend(),
      },
    ],
  };

  const approveContext = {
    title: 'Aprovar envio',
    description: `A ${titleContext} será enviada para os destinatários selecionados. Tem certeza que deseja aprovar o envio da ${titleContext}?`,
    buttons: [
      {
        text: 'Cancelar',
        variation: 'secondary',
        onClick: toggleModal,
      },
      {
        text: 'Aprovar',
        variation: 'primary',
        onClick: () => handleApprove(),
      },
    ],
  };

  const disapproveContext = {
    title: 'Reprovar envio',
    description: `É preciso informar o motivo da reprovação. Tem certeza que deseja reprovar o envio da atividade?`,
    buttons: [
      {
        text: 'Cancelar',
        variation: 'secondary',
        onClick: toggleModal,
      },
      {
        text: 'Reprovar',
        variation: 'primary',
        onClick: () => handleDisapprove(cancelReason),
        cancelReason,
        disabled: !canSubmit,
      },
    ],
  };

  const scheduleContext = {
    title:
      scheduleActionType === 'schedule' ? 'Agendar envio' : 'Reagendar envio',
    description:
      'Determine uma data e hora para aprovar o envio automático da atividade para os responsáveis e alunos.',
    buttons: [
      {
        text: 'Cancelar',
        variation: 'secondary',
        onClick: toggleModal,
      },
      {
        text: scheduleActionType === 'schedule' ? 'Agendar' : 'Reagendar',
        variation: 'primary',
        onClick: () => handleSchedule(),
        disabled: !canSubmit,
      },
    ],
  };

  const approveScheduleContext = {
    title: 'Aprovação de agendamento',
    description: `O agendamento da ${titleContext} será realizada. Tem certeza que deseja aprovar o agendamento da ${titleContext}?`,
    buttons: [
      {
        text: 'Cancelar',
        variation: 'secondary',
        onClick: toggleModal,
      },
      {
        text: 'Aprovar',
        variation: 'primary',
        onClick: () => handleApproveSchedule(),
      },
    ],
  };

  const cancelScheduleContext = () => {
    setModalContext(() => ({
      title: 'Cancelar agendamento',
      description:
        'Tem certeza que deseja cancelar o agendamento da atividade?',
      buttons: [
        {
          text: 'Fechar',
          variation: 'secondary',
          onClick: toggleModal,
        },
        {
          text: isLoading ? (
            <Loader
              isLoading={isLoading}
              variation="content"
              color="white"
              size={20}
            />
          ) : (
            'Confirmar'
          ),
          variation: 'primary',
          onClick: () => handleCancelSchedule(),
          disabled: isLoading,
        },
      ],
    }));
  };

  return (
    <ModalContainerContext
      isOpen={showModal}
      toggleModal={toggleModal}
      className="modalContainerContext"
      zIndex={1}
      contentStyles={{ inset: '60px 48px 89px' }}
      title={<ModalTitle>{modalContext.title}</ModalTitle>}
    >
      <ModalSubtitle>{modalContext.description}</ModalSubtitle>

      <ModalBody>
        {variation === 'disapprove' && (
          <form>
            <FieldLabel htmlFor="cancelReason" fontWeight="400">
              Motivo
            </FieldLabel>
            <input
              type="textarea"
              name="cancelReason"
              placeholder="Digite o motivo da reprovação"
              autoFocus
              onChange={(event) => onChangeCancelReason(event.target.value)}
            />
          </form>
        )}

        {variation === scheduleActionType && (
          <FilterContainer>
            <Field>
              <FieldLabel htmlFor="attributeName">Data</FieldLabel>

              <DateField
                attributeName="dateSchedule"
                type="date"
                placeholder="Selecione a data"
                showClearDate={false}
                defaultValue={dateSchedule ? moment(dateSchedule) : ''}
                onValueChange={(value) =>
                  onChangeDateTimeSchedule(value, 'date')
                }
              />
            </Field>
            <Field>
              <FieldLabel htmlFor="timeSchedule">Hora</FieldLabel>
              <TimeField
                attributeName="timeSchedule"
                type="time"
                placeholder="Selecione a hora"
                defaultValue={timeSchedule}
                onValueChange={(value) =>
                  onChangeDateTimeSchedule(value, 'time')
                }
              />
            </Field>
          </FilterContainer>
        )}
      </ModalBody>

      <ButtonRow buttons={modalContext.buttons} />
    </ModalContainerContext>
  );
};

ModalAction.propTypes = {
  showModal: PropTypes.bool,
  toggleModal: PropTypes.func,
  variation: PropTypes.string,
  dataRow: PropTypes.object,
  redirectTo: PropTypes.string,
  history: PropTypes.string,
  titleContext: PropTypes.string,
  onActionModal: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default withRouter(ModalAction);
