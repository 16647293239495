import styled, { css } from 'styled-components';

export const PageWrapper = styled.div`
  ${({ theme }) => css`
    .ae-select {
      width: 100%;
      white-space: nowrap;
    }

    .container-header {
      margin-top: 32px;
      margin-right: 24px;
      margin-left: 24px;

      .PageTitle {
        margin-top: 10px;
      }
    }

    .container-content {
      padding: 0;
      margin-left: 24px;
      margin-right: 24px;
      box-shadow: none;

      .filterMonth {
        strong {
          font-family: ${theme.font.family.brand}, sans-serif;
          font-size: ${theme.font.size.lg};
          font-style: normal;
          font-weight: ${theme.font.weight.medium};
          font-stretch: normal;
          line-height: 1.25;
          color: ${theme.colors.neutral.gray1};
          text-align: left;
          text-transform: capitalize;
          letter-spacing: normal;
          margin-left: 20px;
        }

        .totalSurveys {
          font-family: ${theme.font.family.brand}, sans-serif;
          font-size: ${theme.font.size.sm};
          font-style: normal;
          font-weight: ${theme.font.weight.medium};
          font-stretch: normal;
          line-height: 1.14;
          color: ${theme.colors.neutral.gray2};
          text-align: right;
          letter-spacing: normal;
        }
      }
    }
  `}
`;
