import { STUDENT_PROFILE_MODEL_TABLE } from './importModels/studentProfileModel';
import { RESPONSIBLE_PROFILE_MODEL_TABLE } from './importModels/responsibleProfileModel';

export const IMPORT_MODEL_TABLES = {
  student_profile: STUDENT_PROFILE_MODEL_TABLE,
  responsible_profile: RESPONSIBLE_PROFILE_MODEL_TABLE,
};

export const IMPORT_PROFILE_TYPES = {
  headquarter: 'unidades',
  classroom: 'turmas',
  discipline: 'disciplinas',
  team: 'profissionais',
  school_user: 'professores',
  student_profile: 'alunos',
  responsible_profile: 'responsáveis',
};

export const PERIODS = {
  morning: 'Manhã',
  afternoon: 'Tarde',
  night: 'Noite',
  integral: 'Integral',
};

export const FIELD_NAMES = {
  external_id: 'Identificador Registro',
  student_id: 'Identificador Aluno',
  classroom_id: 'Identificador Turma',
  name: 'Nome',
  email: 'E-mail',
  date_of_birth: 'Data de Nascimento	',
  period: 'Turno',
  username: 'Login',
  phone: 'Telefone',
  kinship: 'Parentesco',
  document_number: 'CPF',
  cep: 'CEP',
  city: 'Cidade',
  street: 'Rua',
  street_number: 'Número',
  neighborhood: 'Bairro',
  state: 'Estado',
  complement: 'Complemento',
  ra: 'Registro Acadêmico'
};

export const HEADER_NAMES = {
  'Identificador Registro': 'external_id',
  'Identificador Aluno': 'student_id',
  'Identificador Turma': 'classroom_id',
  Nome: 'name',
  'E-mail': 'email',
  'Data de Nascimento': 'date_of_birth',
  Turno: 'period',
  Login: 'username',
  Telefone: 'phone',
  Parentesco: 'kinship',
  CPF: 'document_number',
  CEP: 'cep',
  Cidade: 'city',
  Rua: 'street',
  Número: 'street_number',
  Bairro: 'neighborhood',
  Estado: 'state',
  Complemento: 'complement',
  'Registro Acadêmico': 'ra',
};

export const IMPORT_STATUS = {
  in_progress: 'Em andamento',
  done: 'Concluído',
  error: 'Falha',
};

export const IMPORT_THEME_COLOR = {
  in_progress: 'warning',
  done: 'success',
  error: 'danger',
};
