import { MoodTrackersActionProps, FetchAnswersActionProps } from './types';

const actions: MoodTrackersActionProps = {
  ERROR: 'ERROR/mood_trackers',
  FETCH_ANSWERS: 'FETCH_ANSWERS/mood_trackers',
  SET_DATE: 'SET_DATE/mood_trackers',
  SET_ANSWER_TYPE: 'SET_ANSWER_TYPE/mood_trackers',
  SET_FILTERS: 'SET_FILTERS/mood_trackers',
  SET_PAGE: 'SET_PAGE/mood_trackers',
  FETCH_ANSWERS_SUCCESS: 'FETCH_ANSWERS_SUCCESS/mood_trackers',
  FETCH_HEADQUARTERS: 'FETCH_HEADQUARTERS/mood_trackers',
  FETCH_HEADQUARTERS_SUCCESS: 'FETCH_HEADQUARTERS_SUCCESS/mood_trackers',
  FETCH_EDUCATIONAL_STAGES: 'FETCH_EDUCATIONAL_STAGES/mood_trackers',
  FETCH_EDUCATIONAL_STAGES_SUCCESS:
    'FETCH_EDUCATIONAL_STAGES_SUCCESS/mood_trackers',
  FETCH_CLASSROOMS: 'FETCH_CLASSROOMS/mood_trackers',
  FETCH_CLASSROOMS_SUCCESS: 'FETCH_CLASSROOMS_SUCCESS/mood_trackers',
  fetchAnswers: (params: FetchAnswersActionProps) => ({
    type: actions.FETCH_ANSWERS,
    params,
  }),
  fetchHeadquarters: () => ({
    type: actions.FETCH_HEADQUARTERS,
  }),
  setDate: ({ date }: FetchAnswersActionProps) => ({
    type: actions.SET_DATE,
    date,
  }),
  setAnswerType: ({ answerType }: FetchAnswersActionProps) => ({
    type: actions.SET_ANSWER_TYPE,
    answerType,
  }),
  setFilters: ({
    headquarter,
    educationalStage,
    classroom,
  }: FetchAnswersActionProps) => ({
    type: actions.SET_FILTERS,
    headquarter,
    educationalStage,
    classroom,
  }),
  setPage: ({ page }: FetchAnswersActionProps) => ({
    type: actions.SET_PAGE,
    page,
  }),
  fetchEducationalStages: (headquarter: string) => ({
    type: actions.FETCH_EDUCATIONAL_STAGES,
    headquarter,
  }),
  fetchClassrooms: (headquarter: string, educationalStage: string) => ({
    type: actions.FETCH_CLASSROOMS,
    headquarter,
    educationalStage,
  }),
};

export default actions;
