import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import withFormContext from 'core/hoc/withFormContext';
import tabifyForWizard from 'core/hoc/tabifyForWizard';
import { titleMobile } from 'core/utils/changeText';
import { IMAGES_SURVERY_URL } from 'core/constants/index';

import FormFieldset from 'components/Form/Fieldset';
import Title from 'components/WizardForm/Title';
import Field from 'components/Form/Field';
import CheckboxWithIconGroup from 'components/Form/CheckboxWithIconGroup';
import FormStudentSelector from 'components/Form/StudentsSelector';
import GroupSelector from 'components/Form/GroupSelector';
import FormValidationErrors from 'components/Form/ValidationErrors';

import './style.scss';
import validations from './validations';

const RecipientsTab = ({
  formContext: { form, formMeta, updateAttribute, hasErrorOnAttribute },
}) => {
  const {
    sent_kind,
    persona_type,
    classroom_ids,
    classroom_with_student_profile_ids,
  } = form;
  const { classrooms } = formMeta;
  const personaTypeHasError = hasErrorOnAttribute('persona_type_select');
  const [sendStudents, setSendStudents] = useState(
    ['students', 'both'].includes(persona_type)
  );
  const [sendResponsibles, setSendResponsibles] = useState(
    ['responsibles', 'both'].includes(persona_type)
  );

  useEffect(() => {
    if (sendStudents && sendResponsibles) {
      updateAttribute('persona_type', 'both');
    }

    if (sendStudents && !sendResponsibles) {
      updateAttribute('persona_type', 'students');
    }

    if (!sendStudents && sendResponsibles) {
      updateAttribute('persona_type', 'responsibles');
    }

    if (!sendStudents && !sendResponsibles) {
      updateAttribute('persona_type', null);
    }
  }, [sendStudents, sendResponsibles]);

  useEffect(() => {
    if (mandatoryFieldsAreFilled()) {
      updateAttribute('canSave', true);
    } else {
      updateAttribute('canSave', false);
    }
  }, [classroom_ids, classroom_with_student_profile_ids, persona_type]);

  const mandatoryFieldsAreFilled = () => {
    const classroomStudentProfileIds = classroom_with_student_profile_ids
      ? classroom_with_student_profile_ids
      : [];

    return (
      (classroom_ids.length > 0 ||
        Object.values(classroomStudentProfileIds).length > 0) &&
      persona_type !== null
    );
  };

  const handleOptions = (type) => {
    if (type == 'students') {
      setSendStudents(!sendStudents);
    } else {
      setSendResponsibles(!sendResponsibles);
    }
  };

  const radios = [
    {
      labelText: 'Turmas',
      value: 'classrooms',
      icon: 'user-group',
    },
    {
      labelText: 'Alunos',
      value: 'students',
      icon: 'user',
    },
  ];

  const options = [
    {
      icon: IMAGES_SURVERY_URL.students,
      label: 'Alunos',
      checked: sendStudents,
      onChange: () => handleOptions('students'),
    },
    {
      icon: IMAGES_SURVERY_URL.responsibles,
      label: 'Responsáveis',
      checked: sendResponsibles,
      onChange: () => handleOptions('responsibles'),
    },
  ];

  return (
    <div className="RecipientsTab">
      <FormFieldset>
        {!formMeta.hideRadio && (
          <>
            <Title name="Selecionar destinatários" />
            <div className="RadioGroup">
              <Field
                testId="radiobuttons"
                type="radio"
                attributeName="sent_kind"
                radios={radios}
                disabled={!formMeta.canEditClassrooms}
              />
            </div>
          </>
        )}

        <div className="row">
          {sent_kind === 'students' && (
            <FormStudentSelector
              attributeName="classroom_with_student_profile_ids"
              selectPlaceholder="Selecione uma turma"
              studentsWithResponsibles={persona_type === 'responsibles'}
              disabled={!formMeta.canEditClassrooms}
            />
          )}

          {sent_kind === 'classrooms' && (
            <GroupSelector
              options={classrooms}
              attributeName="classroom_ids"
              lockSelected={!formMeta.canEditClassrooms}
              lockOptions={!formMeta.canEditClassrooms}
            />
          )}
        </div>
        {!formMeta.hideSendTo && (
          <CheckboxWithIconGroup
            title="Enviar para:"
            options={options}
            disabled={!formMeta.canEditClassrooms}
          />
        )}
        {personaTypeHasError && (
          <FormValidationErrors attributeName="persona_type_select" />
        )}
      </FormFieldset>
    </div>
  );
};

RecipientsTab.propTypes = {
  formContext: PropTypes.object.isRequired,
};

export default tabifyForWizard({
  title: titleMobile('2 Destinatários'),
  validations,
})(withFormContext(RecipientsTab));
