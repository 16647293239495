import React from 'react';
import {
  Grid,
  DefaultThemeProps,
  theme,
  Box,
} from '@agendaedu/ae-web-components';

import Skeleton from 'components/Skeleton';

import * as S from './styles';
import FormFieldset from 'components/Form/Fieldset';

export const GeneralInformationStepSkeleton = () => {
  const defaultTheme: DefaultThemeProps = theme;

  const {
    colors: { neutral },
    border: { radius },
  } = defaultTheme;

  return (
    <FormFieldset>
      <S.FormSkeletonWrapper data-testid="skeleton-wrapper">
        <Grid
          gridTemplateColumns="1fr"
          gutter={{
            _: 'lg',
          }}
        >
          <Box mb="md">
            <Skeleton
              width={224}
              height={40}
              primaryColor={neutral.gray6}
              borderRadius={radius.md}
            />
          </Box>

          <S.FormSkeletonInputs>
            <Skeleton
              width={60}
              height={40}
              borderRadius={radius.md}
              primaryColor={neutral.gray6}
            />
            <Skeleton
              height={40}
              width="100%"
              isFullWidth
              primaryColor={neutral.gray6}
              borderRadius={radius.md}
            />
          </S.FormSkeletonInputs>

          <Skeleton
            width="100%"
            height={150}
            primaryColor={neutral.gray6}
            borderRadius={radius.md}
            isFullWidth
          />

          <Grid item gutter="sm">
            <Skeleton
              width="100%"
              isFullWidth
              height={60}
              primaryColor={neutral.gray6}
              borderRadius={radius.md}
            />

            <Skeleton
              width="100%"
              height={90}
              primaryColor={neutral.gray6}
              borderRadius={radius.md}
              isFullWidth
            />
          </Grid>
        </Grid>
      </S.FormSkeletonWrapper>
    </FormFieldset>
  );
};
