const actions = {
  SET_ERROR: 'SET_ERROR/importWeb',
  SET_IMPORT_PROFILE_TYPE: 'SET_IMPORT_PROFILE_TYPE/importWeb',
  FETCH_IMPORTS: 'FETCH_IMPORTS/importWeb',
  FETCH_IMPORTS_SUCCESS: 'FETCH_IMPORTS_SUCCESS/importWeb',
  SET_UPLOADED_FILE: 'SET_UPLOADED_FILE/importWeb',
  SET_IMPORT_STEP: 'SET_IMPORT_STEP/importWeb',
  VALIDATE_FILE_VALUES: 'VALIDATE_FILE_VALUES/importWeb',
  VALIDATE_FILE_VALUES_SUCCESS: 'VALIDATE_FILE_VALUES_SUCCESS/importWeb',
  VALIDATE_FILE_ROW: 'VALIDATE_FILE_ROW/importWeb',
  VALIDATE_FILE_ROW_SUCCESS: 'VALIDATE_FILE_ROW_SUCCESS/importWeb',
  FETCH_FILE_SUMMARY: 'FETCH_FILE_SUMMARY/importWeb',
  FETCH_FILE_SUMMARY_SUCCESS: 'FETCH_FILE_SUMMARY_SUCCESS/importWeb',
  FETCH_PAGINATED_FILE: 'FETCH_PAGINATED_FILE/importWeb',
  FETCH_PAGINATED_FILE_SUCCESS: 'FETCH_PAGINATED_FILE_SUCCESS/importWeb',
  START_IMPORT: 'START_IMPORT/importWeb',
  START_IMPORT_SUCCESS: 'START_IMPORT_SUCCESS/importWeb',
  FETCH_IMPORT_INCONSISTENCIES: 'FETCH_IMPORT_INCONSISTENCIES/importWeb',
  FETCH_IMPORT_INCONSISTENCIES_SUCCESS:
    'FETCH_IMPORT_INCONSISTENCIES_SUCCESS/importWeb',
  FETCH_IMPORT_SUMMARY: 'FETCH_IMPORT_SUMMARY/importWeb',
  FETCH_IMPORT_SUMMARY_SUCCESS: 'FETCH_IMPORT_SUMMARY_SUCCESS/importWeb',
  DELETE_FILE: 'DELETE_FILE/importWeb',

  GET_IMPORT_PROGRESS: 'GET_IMPORT_PROGRESS/importWeb',
  GET_IMPORT_PROGRESS_SUCCESS: 'GET_IMPORT_PROGRESS_SUCCESS/importWeb',

  setImportProfileType: (profileType) => ({
    type: actions.SET_IMPORT_PROFILE_TYPE,
    profileType,
  }),
  fetchImports: (importedInto) => ({
    type: actions.FETCH_IMPORTS,
    importedInto,
  }),
  setUploadedFile: (file) => ({
    type: actions.SET_UPLOADED_FILE,
    file,
  }),
  setImportStep: (step) => ({
    type: actions.SET_IMPORT_STEP,
    step,
  }),
  validateFileValues: (importedInto, fileSignedId) => ({
    type: actions.VALIDATE_FILE_VALUES,
    importedInto,
    fileSignedId,
  }),
  validateFileRow: (importedInto, fileRow) => ({
    type: actions.VALIDATE_FILE_ROW,
    importedInto,
    fileRow,
  }),
  fetchPaginatedFile: (importedInto, fileSignedId, page) => ({
    type: actions.FETCH_PAGINATED_FILE,
    importedInto,
    fileSignedId,
    page,
  }),
  fetchFileSummary: (importedInto, fileSignedId) => ({
    type: actions.FETCH_FILE_SUMMARY,
    importedInto,
    fileSignedId,
  }),
  startImport: (importedInto, fileSignedId, correctedData, errors) => ({
    type: actions.START_IMPORT,
    importedInto,
    fileSignedId,
    correctedData,
    errors,
  }),
  fetchImportsInconsistencies: (importedInto, id, page) => ({
    type: actions.FETCH_IMPORT_INCONSISTENCIES,
    importedInto,
    id,
    page,
  }),
  fetchImportSummary: (importedInto, id) => ({
    type: actions.FETCH_IMPORT_SUMMARY,
    importedInto,
    id,
  }),
  deleteFile: () => ({ type: actions.DELETE_FILE }),

  getImportProgress: (importedInto, id) => ({
    type: actions.GET_IMPORT_PROGRESS,
    importedInto,
    id,
  }),
};

export default actions;
