import styled, { css } from 'styled-components';
import { Box } from '@agendaedu/ae-web-components';
import { ThemeProps } from 'config/themes/types';

export const Informations = styled.div`
  ${({ theme: { colors, typography, space } }: ThemeProps) => css`
    min-width: 130px;

    &:not(:first-child) {
      margin-left: 3rem;
    }

    span {
      text-align: left;
      ${typography.label.Medium12};
      color: ${colors.neutral.gray1};
    }

    div {
      display: flex;
      flex-direction: row;
      align-items: center;

      svg {
        margin-right: ${space.xs};
      }

      strong {
        text-align: left;
        ${typography.label.Medium16};
        color: ${colors.neutral.gray2};
      }
    }
  `}
`;

export const Title = styled.h5`
  ${({ theme: { colors, typography, space } }: ThemeProps) => css`
    ${typography.title.Bold14};

    color: ${colors.neutral.gray2};
    margin: ${space.xs4};
  `}
`;

export const Subtitle = styled.h4`
  ${({ theme: { colors, typography, space } }: ThemeProps) => css`
    ${typography.subtitle.Medium12};

    color: ${colors.neutral.gray2};
    margin: ${space.xs4};
  `}
`;

export const TitleCard = styled.h5`
  ${({ theme: { colors, typography, space } }: ThemeProps) => css`
    ${typography.title.Bold20};

    color: ${colors.brand.primary.default};
    margin: ${space.xs};
    margin-left: ${space.xs4};
  `}
`;

export const TextTotal = styled.h4`
  ${({ theme: { colors, typography } }: ThemeProps) => css`
    ${typography.title.Bold16};

    color: ${colors.neutral.black};
  `}
`;

export const TextBold = styled.p`
  ${({ theme: { colors, typography } }: ThemeProps) => css`
    ${typography.title.Bold16};
    font-weight: 700;
    color: ${colors.neutral.black};
  `}
`;

export const TextLineThrough = styled.p`
  ${({ theme: { typography, colors } }: ThemeProps) => css`
    text-decoration: line-through;
    margin: 0 0 0px !important;
    ${typography.subtitle.Medium12};

    color: ${colors.neutral.gray1};
  `}
`;

export const ActionBox = styled(Box)`
  ${({ theme: { typography, breakpoints } }: ThemeProps) => css`
    gap: 16px;
    > div {
      margin-top: 29px !important;
    }
    > div > div > button {
      border: none;
    }
    > div > div {
      ${typography.body.Regular12}
      white-space: pre-line;
    }
    @media (min-width: ${breakpoints.tabletMD}) {
      .gBaqSt[data-show='true'] {
        transform: translate(-13px, -152px) !important;
      }
      .gBaqSt {
        max-width: 160px;
      }
    }

    @media (minwidth: ${breakpoints.mobileXS}) {
      .gBaqSt[data-show='true'] {
        transform: translate(-13px, -247px) !important;
      }
      .gBaqSt {
        max-width: 160px;
      }
    }
  `}
`;

export const CodeCopy = styled.span`
  ${({ theme: { colors, typography } }: ThemeProps) => css`
    ${typography.title.Bold14};

    color: ${colors.context.success.default};
  `}
`;

export const SeparatorLine = styled.hr`
  ${({ theme: { colors, space } }: ThemeProps) => css`
    width: 100%;
    margin-top: ${space.xs4};
    margin-bottom: ${space.xs4};
    border: 0px;
    border-top: 1px solid ${colors.neutral.gray4};
  `}
`;

export const WrapperContent = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    width: 100%;
    padding: ${space.lg} ${space.sm} ${space.lg};
  `}
`;
