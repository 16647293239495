import axios from 'axios';

export default class ImportWebsServices {
  constructor(dataArea) {
    this.dataArea = dataArea;
  }

  /**
   * @param {string} importedInto - headquarter, classroom, discipline...
   * @param {string} signedId - uploaded file signed id hash
   * @returns {Promise<object>}
   */
  async validateFile(importedInto, signedId) {
    const endpoint = `/${this.dataArea}/import_webs/validate_file.json?imported_into=${importedInto}&file=${signedId}`;
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await axios.post(endpoint);
      return response;
    } catch (error) {
      throw error;
    }
  }
}
