import React, { useContext } from 'react';

import withFormContext from 'core/hoc/withFormContext';
import DailySummariesContext from 'core/contexts/DailySummaries';

import Field from 'components/Form/Field';
import AgendaIcon from 'components/AgendaIcon';
import SectionCard from 'pages/dailySummaries/DailySummaryForm/StudentSections/Card';

import * as S from './styles';

const SleepCard = () => {
  const {
    form: { sleeps },
    handleForm,
    isDisabled,
    hasStudentSelected,
  } = useContext(DailySummariesContext);
  const { notSlept, periods } = sleeps;

  const toggleSleepCheckbox = () => {
    handleForm('sleeps', {
      ...sleeps,
      notSlept: !notSlept,
    });
  };

  const addSleepTime = () => {
    handleForm('sleeps', {
      ...sleeps,
      periods: [
        ...sleeps.periods,
        { id: Math.random().toString(36).substring(7), sleep: '' },
      ],
    });
  };

  const removeSleepTime = (id) => {
    sleeps.periods.length > 1 &&
      handleForm('sleeps', {
        ...sleeps,
        periods: periods.filter((entry) => entry.id !== id),
      });
  };

  const onChange = (index, value) => {
    periods[index].sleep = value;

    handleForm('sleeps', {
      ...sleeps,
      periods,
    });
  };

  const disableCard = !hasStudentSelected || isDisabled;

  return (
    <SectionCard
      title="Sono"
      checkboxLabel="Não dormiu"
      isChecked={notSlept}
      onChangeCheckbox={toggleSleepCheckbox}
      disabled={disableCard}
    >
      <S.Wrapper>
        <S.Content>
          {periods.map(({ id, sleep }, index) => (
            <S.InputWithLabel key={id}>
              <S.InputContainer disabled={disableCard}>
                <Field
                  size="small"
                  value={sleep}
                  type="rangeTime"
                  label="Horário de início e fim"
                  clearTime={removeSleepTime}
                  onChange={(value) => onChange(index, value)}
                  disabled={disableCard}
                />
                <button
                  variation="secondary"
                  onClick={() => removeSleepTime(id)}
                  disabled={sleeps.periods.length === 1 || disableCard}
                  aria-label="Remover entrada de sono"
                >
                  <AgendaIcon name="trash" size="medium" />
                </button>
              </S.InputContainer>
            </S.InputWithLabel>
          ))}
        </S.Content>
        {!disableCard && (
          <S.AddButton
            variation="secondary"
            onClick={addSleepTime}
            aria-label="Adicionar entrada de sono"
            id="add-button-sleep"
          >
            <AgendaIcon name="plus" size="small" />
          </S.AddButton>
        )}
      </S.Wrapper>
    </SectionCard>
  );
};

export default withFormContext(SleepCard);
