const AcceptedContentTypes = [
  'application/pdf',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-powerpoint',
  'application/mspowerpoint',
  'application/ms-powerpoint',
  'application/mspowerpnt',
  'application/vnd-mspowerpoint',
  'application/powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.google-apps.document',
  'application/vnd.google-apps.spreadsheet',
  'application/vnd.google-apps.form',
  'application/vnd.google-apps.presentation',
  'text/plain',
  'text/csv',
  'image/jpeg',
  'image/png',
  'image/gif',
  'image/jpg',
  'video/mp4',
  'video/ogg',
  'video/webm',
];

export default AcceptedContentTypes;
