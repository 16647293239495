import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { dropdownDirection } from 'core/utils/table';
import { flags } from 'core/constants/flags';
import { canCancelEnrollment } from 'core/utils/edupay/functions';

import UserDisplay from 'components/UserDisplay';
import Currency from 'components/Currency';
import OutlineBox from 'components/OutlineBox';
import DropdownButton from 'components/DropdownButton';
import EnrollmentClickShowModal from 'components/Payments/Enrollment/EnrollmentClickShowModal';

import enrollmentPlanStatus from '../EnrollmentPlanStatus';
import enrollmentPlanContractStatus from '../EnrollmentPlanContractStatus';

import './style.scss';

const StudentProfile = ({ attributes, actions }) => (
  <EnrollmentClickShowModal actions={actions}>
    <UserDisplay
      user={attributes.student_profile.data}
      classroomsNames={
        attributes.student_profile.data.attributes.classroom_names
      }
      size="medium"
    />
  </EnrollmentClickShowModal>
);

StudentProfile.propTypes = {
  attributes: PropTypes.shape({
    student_profile: PropTypes.shape({
      data: PropTypes.shape({
        attributes: PropTypes.shape({
          classroom_names: PropTypes.array,
        }),
      }),
    }),
  }).isRequired,
  actions: PropTypes.shape({
    show: PropTypes.func,
  }).isRequired,
};

const Price = ({ attributes, actions }) => (
  <EnrollmentClickShowModal actions={actions}>
    <Currency value={attributes.price} />
  </EnrollmentClickShowModal>
);

Price.propTypes = {
  attributes: PropTypes.shape({
    price: PropTypes.number,
  }).isRequired,
  actions: PropTypes.shape({
    show: PropTypes.func,
  }).isRequired,
};

const Expire = ({ attributes, actions }) => (
  <EnrollmentClickShowModal actions={actions}>
    {attributes.expires_at}
  </EnrollmentClickShowModal>
);

Expire.propTypes = {
  attributes: PropTypes.shape({
    expires_at: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    show: PropTypes.func,
  }).isRequired,
};

const SituationContract = ({ attributes, actions }) => (
  <EnrollmentClickShowModal actions={actions}>
    <OutlineBox
      className={`BoxStatus ${attributes.document_status} ${attributes.document_status}`}
      variation={
        enrollmentPlanContractStatus[attributes.document_status].variation
      }
    >
      {enrollmentPlanContractStatus[attributes.document_status].text}
    </OutlineBox>
  </EnrollmentClickShowModal>
);

SituationContract.propTypes = {
  attributes: PropTypes.shape({
    document_status: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    show: PropTypes.func,
  }).isRequired,
};

const SituationPayment = ({ attributes, actions }) => (
  <EnrollmentClickShowModal actions={actions}>
    <OutlineBox
      className={`BoxStatus ${attributes.status}`}
      variation={enrollmentPlanStatus[attributes.status].variation}
    >
      {enrollmentPlanStatus[attributes.status].text}
    </OutlineBox>
  </EnrollmentClickShowModal>
);

SituationPayment.propTypes = {
  attributes: PropTypes.shape({
    status: PropTypes.string,
  }).isRequired,
  actions: PropTypes.shape({
    show: PropTypes.func,
  }).isRequired,
};

const Actions = ({ attributes, actions }, row, rowCount) => {
  const policies = useSelector((state) => state.root.policies);

  const checkStatus = (statuses) => statuses.includes(attributes.status);

  const pixIssuedAtSchoolProductAndNegotiationLDFlag =
    policies.core_banking_flags.includes(
      flags.PIX_ISSUED_AT_SCHOOL_PRODUCT_AND_NEGOTIATION
    );

  let options = [
    {
      text: 'Visualizar Matrícula',
      path: `/schools/enrollment_plans/${attributes.id}/details`,
      target: '_self',
    },
    !checkStatus([
      'credit_card_paid',
      'manually_paid',
      'billet_paid',
      'pix_paid',
      'paid',
      'paid_late',
      'canceled',
      'paid_divergent',
    ]) &&
      policies.can_edit_after_approved && {
        text: 'Editar',
        path: `/schools/enrollment_plans/${attributes.id}/edit`,
        target: '_self',
      },
    !checkStatus([
      'credit_card_paid',
      'manually_paid',
      'billet_paid',
      'pix_paid',
      'paid',
      'paid_late',
      'canceled',
      'paid_divergent',
      'pending',
    ]) && {
      text: 'Marcar como pago',
      onClick: () =>
        policies.edupay_active_flags.includes(flags.ENROLLMENT_PAID_AT_SCHOOL)
          ? actions.paidAtSchool()
          : actions.manuallyPaidModal(),
    },

    attributes.allowed_payment_method.includes('billet') &&
      !checkStatus([
        'credit_card_paid',
        'manually_paid',
        'billet_paid',
        'pix_paid',
        'paid',
        'paid_late',
        'canceled',
        'paid_divergent',
        'pending',
      ]) && {
        text: 'Gerar boleto',
        onClick: () => actions.generateBilletModal(),
      },
    attributes.allowed_payment_method.includes('pix') &&
      pixIssuedAtSchoolProductAndNegotiationLDFlag &&
      !checkStatus([
        'credit_card_paid',
        'manually_paid',
        'billet_paid',
        'pix_paid',
        'paid',
        'paid_late',
        'canceled',
        'paid_divergent',
        'pending',
      ]) && {
        text: 'Gerar PIX',
        onClick: () => actions.generatePixModal(),
      },
    attributes.allowed_payment_method.includes('pix') &&
      pixIssuedAtSchoolProductAndNegotiationLDFlag &&
      checkStatus(['generated_pix']) && {
        text: 'Ver chave PIX',
        onClick: () => actions.pixLinkModal(),
      },
    attributes.allowed_payment_method.includes('billet') &&
      checkStatus(['generated_billet']) && {
        text: 'Link do boleto',
        onClick: () => actions.billetLinkModal(),
      },
    policies.can_cancel_school_product &&
      canCancelEnrollment(attributes.status, attributes.document_status) && {
        text: 'Cancelar Matrícula',
        path: '',
        target: '',
        onClick: () =>
          policies.edupay_active_flags.includes(flags.ENROLLMENT_CANCELLATION)
            ? actions.cancelModalLD()
            : actions.cancelModal(),
      },
  ];

  options = options.filter((option) => option !== false);

  return (
    <div className="DropdownActions">
      <DropdownButton
        dropdownItems={options}
        text="Ações"
        variation="secondary"
        direction={dropdownDirection({
          options: options.length,
          optionsPerRow: 3,
          row,
          rowCount,
        })}
      />
    </div>
  );
};

Actions.propTypes = {
  attributes: PropTypes.shape({
    id: PropTypes.string,
    status: PropTypes.string,
    document_status: PropTypes.string,
    allowed_payment_method: PropTypes.array,
  }),
  actions: PropTypes.shape({
    show: PropTypes.func,
    manuallyPaidModal: PropTypes.func,
    paidAtSchool: PropTypes.func,
    generateBilletModal: PropTypes.func,
    generatePixModal: PropTypes.func,
    billetLinkModal: PropTypes.func,
    pixLinkModal: PropTypes.func,
    cancelModal: PropTypes.func,
    cancelModalLD: PropTypes.func,
  }).isRequired,
};

export default [
  {
    Header: 'Aluno',
    accessor: StudentProfile,
  },
  {
    Header: 'Valor',
    accessor: Price,
  },
  {
    Header: 'Vencimento',
    accessor: Expire,
  },
  {
    Header: 'Contrato',
    accessor: SituationContract,
  },
  {
    Header: 'Pagamento',
    accessor: SituationPayment,
  },
  {
    Header: 'Ações',
    accessor: Actions,
  },
];
