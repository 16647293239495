import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  Box,
  Button,
  CardList,
  SubtleIconButton,
  theme,
} from '@agendaedu/ae-web-components';

import Toast from 'components/Toast';
import Breadcrumb from 'components/Breadcrumb';
import PageTitle from 'components/PageTitle';
import EmptyState from 'components/EmptyState';
import ModalBody from 'components/Modal/ModalBody';
import ModalTitle from 'components/Modal/ModalTitle';
import ButtonRow from 'components/ButtonRow';

import { EMPTY_STATES_URL } from 'core/constants';
import withAppContext from 'core/hoc/withAppContext';

import HandoutsActions from 'store/handouts/actions';
import { CategoriesProps, HandoutState } from 'store/handouts/types';

import { CategoriesListSkeleton } from './skeleton';
import { CategoriesListTypes } from './types';
import * as S from './styles';

const HandoutCategoriesListScreen = ({
  appContext: { dataArea },
}: CategoriesListTypes) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['handouts']);

  const [showModal, setShowModal] = useState<boolean>(false);
  const [selected, setSelected] = useState<CategoriesProps | null>(null);

  const { fetchMoreCategoriesRequest, deleteCategory } = HandoutsActions;

  const {
    isCategoryLoading,
    isLoadingMoreCategories,
    pagination: { page },
    handoutCategories: { categories, totalPages },
  } = useSelector((state: HandoutState) => state.handouts);

  const fetchCategories = useCallback(() => {
    dispatch(fetchMoreCategoriesRequest());
  }, [dispatch, fetchMoreCategoriesRequest]);

  const fetchMoreCategories = useCallback(() => {
    dispatch(fetchMoreCategoriesRequest(page + 1));
  }, [dispatch, fetchMoreCategoriesRequest, page]);

  const hasMoreCategories = useMemo(
    () => page < totalPages,
    [page, totalPages]
  );

  const handleDeleteCategory = useCallback(
    (category: CategoriesProps) => {
      setShowModal(false);
      dispatch(deleteCategory(category));
    },
    [dispatch, deleteCategory]
  );

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  const renderActions = (category: CategoriesProps) => {
    return [
      {
        as: 'a',
        title: t('actions.edit_button_text'),
        link: `/${dataArea}/handout_categories/${category.id}/edit`,
      },
      {
        as: 'button',
        title: t('actions.delete_button_text'),
        onClick: () => handleModalOpen(category),
      },
    ];
  };

  const handleModalOpen = (category: CategoriesProps) => {
    setShowModal(true);
    setSelected(category);
  };

  const toggleModal = () => setShowModal(false);

  return (
    <S.CategoriesListContainer id="HandoutCategoriesListScreen">
      {isCategoryLoading ? (
        <CategoriesListSkeleton />
      ) : (
        <>
          <S.InfoBox
            width="100%"
            display="flex"
            justifyContent={{
              _: 'space-between',
            }}
            alignItems={{
              _: 'center',
            }}
          >
            <S.PageInfo>
              <Breadcrumb
                title={t('screen_title_text')}
                path={`/${dataArea}/handouts`}
              />
              <PageTitle>{t('categories.screen_title')}</PageTitle>
            </S.PageInfo>
            <Link to={`/${dataArea}/handout_categories/new`}>
              <Button as="button" variant="primary">
                {t('categories.new_category')}
              </Button>
            </Link>
          </S.InfoBox>

          {categories.length > 0 ? (
            <CardList
              loadMore={!isLoadingMoreCategories && fetchMoreCategories}
              hasMore={hasMoreCategories}
              initialLoad={true}
              threshold={200}
              pageStart={1}
              marginBottom="md"
              loader={
                <S.SkeletonListItem
                  width="100%"
                  height={96}
                  primaryColor={theme.colors.neutral.gray6}
                  isFullWidth
                />
              }
            >
              {categories.map((category) => (
                <S.CardContainer
                  key={category.id}
                  width="100%"
                  display="flex"
                  alignItems={{
                    _: 'flex-start',
                    desktopLG: 'center',
                  }}
                  justifyContent={{
                    _: 'space-between',
                  }}
                >
                  <Box
                    display="flex"
                    alignItems="flex-start"
                    flexDirection="column"
                    width={{ desktopXL: '80%', tabletMD: '35%', _: '35%' }}
                  >
                    <S.CardLabel>Categoria</S.CardLabel>
                    <S.Title>{category.label}</S.Title>
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                  >
                    <SubtleIconButton
                      actions={renderActions(category)}
                      align="right"
                    />
                  </Box>
                </S.CardContainer>
              ))}
            </CardList>
          ) : (
            <EmptyState
              message={t('categories.empty_state')}
              imgUrl={EMPTY_STATES_URL.handoutCategoriesUrl}
            />
          )}
        </>
      )}
      <Toast />
      <S.CategoriesModal
        isOpen={showModal}
        maxWidth="450px"
        toggleModal={toggleModal}
      >
        <ModalTitle>{t('categories.delete_modal.title')}</ModalTitle>
        <ModalBody>
          <p>{t('categories.delete_modal.content')}</p>
        </ModalBody>
        <ButtonRow>
          <Button variant="secondary" onClick={toggleModal}>
            {t('actions.cancel_button_text')}
          </Button>
          <Button
            variant="primary"
            isNegativeAction
            onClick={() => handleDeleteCategory(selected)}
          >
            {t('actions.delete_button_text')}
          </Button>
        </ButtonRow>
      </S.CategoriesModal>
    </S.CategoriesListContainer>
  );
};

export default withAppContext(HandoutCategoriesListScreen);
