import styled, { css } from 'styled-components';
import { ThemeProps } from 'config/themes/types';
import { DefaultThemeProps, Icon, Option } from '@agendaedu/ae-web-components';
import { SelectWrapperStyleProps, OptionItemPropsStyle } from './types';

const selectModifiers = {
  visibleOptions: (theme: DefaultThemeProps) => css`
    border-bottom: ${theme.border.width.sm} ${theme.border.style.solid}
      transparent !important;
    border-radius: ${theme.border.radius.md} ${theme.border.radius.md} 0 0;
    border-color: ${theme.colors.brand.primary.default};
  `,

  selectDisabled: (theme: DefaultThemeProps) => css`
    background-color: ${theme.colors.neutral.gray6} !important;
    color: ${theme.colors.neutral.gray3} !important;
    border-color: ${theme.colors.neutral.gray5} !important;
  `,

  selectedOption: (theme: DefaultThemeProps) => css`
    background-color: ${theme.colors.brand.primary.op20} !important;
    color: ${theme.colors.brand.primary.default} !important;
  `,
};

export const ClassroomSelectWrapper = styled.div`
  flex: auto;
  position: relative;
  padding: 20px 0;
`;

export const SelectWrapper = styled.div<SelectWrapperStyleProps>`
  ${({ isOptionsVisible, theme }) => css`
    height: 40px;
    width: 100%;
    color: ${theme.colors.neutral.black};
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: ${theme.colors.neutral.white};
    border: ${theme.border.width.sm} ${theme.border.style.solid}
      ${theme.colors.neutral.gray4};
    border-radius: ${theme.border.radius.md};
    padding: 0 ${theme.space.xs};

    cursor: pointer;

    ${!!isOptionsVisible && selectModifiers.visibleOptions(theme)};

    &:hover {
      border-color: ${theme.colors.brand.primary.default};
    }
  `}
`;

export const SelectIcon = styled(Icon)`
  ${({ theme: { colors } }: ThemeProps) => css`
    path {
      fill: ${colors.neutral.gray1};
    }
  `}
`;

export const SelectContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const SelectTextLabel = styled.label`
  ${({ theme: { colors, typography } }) => css`
    display: inline-block;
    color: ${colors.neutral.gray1};
    ${typography.label.Regular14}
  `}
`;

export const TextField = styled.input`
  ${({ theme }) => css`
    flex: 1;
    border: ${theme.border.width.none};
    font-family: ${theme.font.family.auxiliary}, sans-serif;
    font-weight: ${theme.font.weight.regular};
    font-size: ${theme.font.size.md};
    margin-left: 8px;

    &:focus {
      outline: none;
    }

    &:disabled {
      ${selectModifiers.selectDisabled(theme)};
    }
  `}
`;

export const OptionList = styled.ul`
  ${({ theme: { border, colors, space } }) => css`
    position: absolute;
    left: ${space.xs4};
    right: ${space.xs4};
    padding: ${space.xs4} ${space.xs} ${space.xs};
    margin: ${space.xs4};
    max-height: 253px;
    background-color: ${colors.neutral.white};
    border: ${border.width.sm} solid ${colors.brand.primary.default};
    border-top: none;
    border-radius: ${border.radius.none} ${border.radius.none}
      ${border.radius.md} ${border.radius.md};
    z-index: 1;
    overflow-y: scroll;
  `}
`;

export const EmptyOption = styled(Option)`
  ${({ theme: { colors } }) => css`
    width: 100%;
    color ${colors.neutral.gray2} !important;

    &:hover,
    &:active {
      background-color: ${colors.neutral.white} !important;
      cursor: default;
    }
  `}
`;

export const OptionItem = styled(Option)<OptionItemPropsStyle>`
  ${({ theme, isSelected }) => css`
    position: relative;
    width: 100%;

    ${!!isSelected && selectModifiers.selectedOption(theme)}

    &:focus {
      background-color: ${theme.colors.brand.primary.op20};
      color: ${theme.colors.brand.primary.default};
    }

    &:not(:last-child) {
      margin-top: ${theme.space.xs3};
    }
  `}
`;
