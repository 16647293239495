import { Avatar } from '@agendaedu/ae-web-components';
import { ThemeProps } from 'config/themes/types';
import styled, { css } from 'styled-components';

export const CardWrapper = styled.article`
  ${({ theme: { colors, border, space } }: ThemeProps) => css`
    display: flex;
    flex-direction: row;
    border: ${border.width.sm} ${border.style.solid} ${colors.neutral.gray4};
    border-radius: ${border.radius.md};
    padding: ${space.sm} ${space.lg};
  `}
`;

export const GroupAvatar = styled(Avatar)`
  ${({ theme: { breakpoints, space } }: ThemeProps) => css`
    margin-right: ${space.lg};

    @media screen and (max-width: ${breakpoints.tabletMD}) {
      width: 32px !important;
      height: 32px !important;
      margin-right: ${space.xs};
    }
  `}
`;
