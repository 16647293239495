import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, Button, Text } from '@agendaedu/ae-web-components';

import { StorageRoot } from 'store/storage/types';

import CenterModal from 'pages/storage/Modals/CenterModal';

import { Props } from './types';

const DiscardModal: React.FC<Props> = ({
  onClick,
  onClose,
  kind,
}): React.ReactElement => {
  const { t } = useTranslation(['storage', 'common']);

  const tBase = useCallback((key: string) => t(`folder_form.${key}`), [t]);

  const {
    storageForm: { showDiscardModal },
  } = useSelector((state: StorageRoot) => state.storage);

  const title = {
    document: tBase('modal.discard_title'),
    folder: tBase('modal.discard_title'),
    file: tBase('modal.discard_file_title'),
  };

  const body = {
    document: tBase('modal.discard_body'),
    folder: tBase('modal.discard_body'),
    file: tBase('modal.discard_file_body'),
  };

  return (
    <CenterModal
      isOpen={showDiscardModal}
      onClose={onClose}
      body={
        <Text variant="body-regular-16" lineHeight="lg" color="neutral.gray1">
          {body[kind]}
        </Text>
      }
      footer={
        <Box display="flex" flexDirection="row-reverse" gap="sm">
          <Button
            data-testid="discard-submit-button"
            variant="secondary"
            contextVariant="alert"
            onClick={onClick}
          >
            {tBase('modal.discard_primary_button')}
          </Button>

          <Button
            data-testid="close-button"
            variant="secondary"
            onClick={onClose}
          >
            {tBase('modal.discard_secondary_button')}
          </Button>
        </Box>
      }
      title={title[kind]}
    />
  );
};

export default DiscardModal;
