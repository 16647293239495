import React, { useState, useEffect } from 'react';
import FormBox from '../components/FormBox';
import { useTranslation } from 'react-i18next';
import { RootState } from '../types';
import { TextField } from '@agendaedu/ae-web-components';
import Toast from 'components/Toast';
import reduxProvider from 'core/hoc/reduxProvider';
import { trackEvent } from '../../../config/amplitude';
import { phoneMask } from 'core/utils/masks.js';
import { useDispatch, useSelector } from 'react-redux';
import registerAccountActions from 'store/registerAccount/actions';
import UserServices from 'core/services/Users';

const RegisterAccountResponsibles = () => {
  const [fieldValues, setFieldValues] = useState({
    name: '',
    email: '',
    phone: '',
    password: '',
    password_confirmation: '',
  });

  const [fieldErrors, setFieldErrors] = useState({
    name: { hasError: false, message: '' },
    email: { hasError: false, message: '' },
    phone: { hasError: false, message: '' },
    password: { hasError: false, message: '' },
    password_confirmation: { hasError: false, message: '' },
  });

  const [existentEmail, setExistentEmail] = useState(null);

  const { isSaved, isLoading } = useSelector(
    (state: RootState) => state.registerAccount
  );

  const userServices = new UserServices('schools');

  const { t } = useTranslation(['register_account']);

  const { sendRegister, buildToast } = registerAccountActions;

  const dispatch = useDispatch();

  const token = window.location.pathname.replace('/invites/responsible/', '');

  const checkEmailExists = async () => {
    try {
      await userServices.verifyEmail(fieldValues.email, 'responsible_profiles');
      dispatch(buildToast(t('toast.existent_email_text'), 'info'));
      setExistentEmail(fieldValues.email);
      setFieldValues({
        ...fieldValues,
        password: '',
        password_confirmation: '',
      });
    } catch (error) {
      setExistentEmail(null);
    }
  };

  const validateName = (name: string) => {
    const invalidLength = !name.length;
    setFieldErrors({
      ...fieldErrors,
      name: {
        hasError: invalidLength,
        message: t('form.name.invalid_name_message'),
      },
    });
  };

  const validateEmail = (email: string) => {
    const invalidLength = !email.length;
    // eslint-disable-next-line
    const invalidEmail = !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
      email
    );

    let message;
    if (invalidLength) message = t('form.email.required_email_message');
    if (!invalidLength && invalidEmail)
      message = t('form.email.invalid_email_message');

    setFieldErrors({
      ...fieldErrors,
      email: {
        hasError: invalidLength || invalidEmail,
        message: message,
      },
    });
  };

  const validatePhone = (phone: string) => {
    const invalidPhone =
      phone.length === 0 ? true : phone.replace(/\D/g, '').length >= 10;

    setFieldErrors({
      ...fieldErrors,
      phone: {
        hasError: !invalidPhone,
        message: t('form.phone.invalid_phone_message'),
      },
    });
  };

  const validatePassword = (password: string) => {
    const invalidLength = !password.length;
    const invalidMinLength = password.length < 8;

    let message;
    if (invalidLength) message = t('form.password.required_password_message');
    if (!invalidLength && invalidMinLength)
      message = t('form.password.min_password_length_message');

    setFieldErrors({
      ...fieldErrors,
      password: {
        hasError: invalidLength || invalidMinLength,
        message: message,
      },
    });
  };

  const validateComfirmPassword = (password: string) => {
    const invalidLength = !password.length;
    const invalidMatch = password !== fieldValues.password;

    let message;
    if (invalidLength)
      message = t('form.comfirm_password.min_comfirm_password_length_message');
    if (!invalidLength && invalidMatch)
      message = t('form.comfirm_password.invalid_comfirm_password_message');

    setFieldErrors({
      ...fieldErrors,
      password_confirmation: {
        hasError: invalidLength || invalidMatch,
        message: message,
      },
    });
  };

  const onPhoneKeyUp = (e) => {
    e.target.value = phoneMask(e.target.value);
  };

  const isFormValid =
    fieldValues.name &&
    fieldValues.email &&
    (existentEmail
      ? true
      : fieldValues.password && fieldValues.password_confirmation) &&
    !fieldErrors.name.hasError &&
    !fieldErrors.email.hasError &&
    !fieldErrors.phone.hasError &&
    !fieldErrors.password.hasError &&
    !fieldErrors.password_confirmation.hasError;

  const onSubmit = () => {
    if (isFormValid) {
      dispatch(sendRegister(fieldValues, 'responsible', token));
      trackEvent('plg-responsibles-invite-form-submit', {
        actionTarget: 'submit',
      });
    }
  };

  useEffect(() => {
    trackEvent('plg-responsibles-invite-form-view');
  }, []);

  return (
    <div className="RegisterAccountResponsibles">
      <FormBox
        onSubmit={onSubmit}
        isFormValid={isFormValid}
        isSaved={isSaved}
        isLoading={isLoading}
        hasAccount={existentEmail}
      >
        <fieldset>
          <TextField
            name="name"
            type="text"
            label={t('form.name.label')}
            placeholder={t('form.name.placeholder')}
            value={fieldValues.name}
            onChange={(e) => {
              setFieldValues({ ...fieldValues, name: e.target.value });
              validateName(e.target.value);
            }}
            error={fieldErrors.name.hasError}
            errorMessage={fieldErrors.name.message}
          />
        </fieldset>

        <fieldset>
          <TextField
            name="email"
            type="text"
            label={t('form.email.label')}
            placeholder={t('form.email.placeholder')}
            value={fieldValues.email}
            onChange={(e) => {
              setFieldValues({ ...fieldValues, email: e.target.value });
              validateEmail(e.target.value);
            }}
            error={fieldErrors.email.hasError}
            errorMessage={fieldErrors.email.message}
            onBlur={checkEmailExists}
          />
        </fieldset>

        <fieldset>
          <TextField
            name="phone"
            type="text"
            label={t('form.phone.label')}
            placeholder={t('form.phone.placeholder')}
            value={fieldValues.phone}
            onChange={(e) => {
              setFieldValues({
                ...fieldValues,
                phone: phoneMask(e.target.value),
              });
              validatePhone(phoneMask(e.target.value));
            }}
            onKeyUp={onPhoneKeyUp}
            error={fieldErrors.phone.hasError}
            errorMessage={fieldErrors.phone.message}
          />
        </fieldset>

        <fieldset>
          <TextField
            name="password"
            type="password"
            label={t('form.password.label')}
            placeholder={t('form.password.placeholder')}
            value={fieldValues.password}
            onChange={(e) => {
              setFieldValues({ ...fieldValues, password: e.target.value });
              validatePassword(e.target.value);
            }}
            error={fieldErrors.password.hasError}
            errorMessage={fieldErrors.password.message}
            disabled={existentEmail}
          />
        </fieldset>

        <fieldset>
          <TextField
            name="comfirmPassword"
            type="password"
            label={t('form.comfirm_password.label')}
            placeholder={t('form.comfirm_password.placeholder')}
            value={fieldValues.password_confirmation}
            onChange={(e) => {
              setFieldValues({
                ...fieldValues,
                password_confirmation: e.target.value,
              });
              validateComfirmPassword(e.target.value);
            }}
            error={fieldErrors.password_confirmation.hasError}
            errorMessage={fieldErrors.password_confirmation.message}
            disabled={existentEmail}
          />
        </fieldset>
        <Toast />
      </FormBox>
    </div>
  );
};

export default reduxProvider(RegisterAccountResponsibles);
