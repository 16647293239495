import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { CookiesProvider } from 'react-cookie';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import InternalErrorBoundary from 'boundaries/InternalErrorBoundary';
import reduxProvider from 'core/hoc/reduxProvider';

import Hubstore from 'pages/hubstore';
import HubstoreDetails from 'pages/hubstore/Details';
import SubscriptionForm from 'pages/hubstore/SubscriptionForm';

const HubstoreRoutes = ({ googleClientId }) => {
  const changeWrapperBackgroundColor = () => {
    document.getElementById('main-content').style.backgroundColor = '#ffffff';
  };

  useEffect(() => {
    changeWrapperBackgroundColor();
  }, []);

  return (
    <InternalErrorBoundary>
      <div className="HubstoreMainsScreen">
        <CookiesProvider>
          <BrowserRouter>
            <Switch>
              <Route
                exact
                path="/*/hub_store/products"
                component={(props) => <Hubstore {...props} />}
              />

              <Route
                exact
                path="/*/hub_store/products/:id"
                component={(props) => <HubstoreDetails {...props} />}
              />

              <Route
                exact
                path="/*/hub_store/products/:id/subscriptions/new"
                component={(props) => <SubscriptionForm {...props} />}
              />
            </Switch>
          </BrowserRouter>
        </CookiesProvider>
      </div>
    </InternalErrorBoundary>
  );
};

HubstoreRoutes.propTypes = {
  googleClientId: PropTypes.string,
};

export default reduxProvider(HubstoreRoutes);
