import styled, { css } from 'styled-components';

export const DiaperAndBathContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-between;

  ${({ diaperAndBathChildren }) => css`
    > div {
      width: calc(100% / ${diaperAndBathChildren} - 6px);
    }
  `}

  @media (max-width: 1200px) {
    flex-direction: column;

    > div:first-child {
      margin-right: unset;
      margin-bottom: 24px;
    }

    > div {
      width: 100%;
    }
  }
`;

export const FooterInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 36px;
`;

export const Label = styled.label`
  ${({ theme }) => css`
    color: ${theme.gray1};
    font-weight: 400;
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    padding-left: 8px;
    margin-bottom: 0;
  `}
`;
