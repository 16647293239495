import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Label = styled.span`
  ${({ theme }) => css`
    color: ${theme.gray2};
    font-family: 'Roboto';
    font-style: normal;
    font-size: 14px;
    line-height: 24px;
  `}
`;
