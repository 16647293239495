import styled, { css } from 'styled-components';
import OutlineBox from 'components/OutlineBox';

export const TagContainer = styled(OutlineBox)`
  ${({ theme }) => css`
    border-radius: 4px;
    z-index: 10;

    &.badge {
      padding: 5px 8px;
      background: ${theme.gray5};
      border: 0;
      flex-shrink: 0;
    }
  `}
`;
