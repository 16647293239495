import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autobind from 'autobind-decorator';

import withFormContext from 'core/hoc/withFormContext';
import withAppContext from 'core/hoc/withAppContext';
import arrayFlatten from 'core/utils/arrayFlatten';
import ClassroomsService from 'core/services/Classrooms';

import FormSelector from 'components/Form/Selector';
import FormClassroomSelector from 'components/Form/ClassroomSelector';
import Avatar from 'components/Avatar';
import AgendaIcon from 'components/AgendaIcon';

import './style.scss';

class TempPasswordForm extends Component {
  static propTypes = {
    appContext: PropTypes.shape({
      dataArea: PropTypes.string.isRequired,
    }).isRequired,
    formContext: PropTypes.shape({
      updateAttribute: PropTypes.func,
    }),
    activeSchoolTerm: PropTypes.object.isRequired,
    users: PropTypes.array.isRequired,
    loadUsers: PropTypes.func.isRequired,
  };

  state = {
    isLoadingClassrooms: false,
  };

  componentDidMount() {
    this.loadClassroomsOptions();
  }

  @autobind
  async loadClassroomsOptions() {
    const {
      formContext: { loadOptionsToSelect, updateAttribute },
      appContext: { dataArea },
    } = this.props;
    const classroomsService = new ClassroomsService(dataArea);
    this.setState({ isLoadingClassrooms: true });

    try {
      const classrooms = await classroomsService.getClassrooms();

      this.setState({ classrooms }, () => {
        loadOptionsToSelect('classroom_from', classrooms);
      });
    } catch (errorFetchingClassrooms) {
      this.setState({ errorFetchingClassrooms });
    } finally {
      this.setState({ isLoadingClassrooms: false });
    }
  }

  @autobind
  loadUsers(select) {
    const {
      formContext: { updateAttribute },
      loadUsers,
    } = this.props;
    loadUsers(select);
    this.onClassroomSelectFrom(select);
    updateAttribute('user_ids', []);
  }

  @autobind
  onClassroomSelectFrom(select) {
    const { classrooms } = this.state;

    const selectClassroomFrom = arrayFlatten(
      classrooms.map(({ options }) => options)
    ).find(({ id }) => id === select);

    this.props.onClassroomSelectFrom(selectClassroomFrom);
  }

  @autobind
  optionComponent(option) {
    return (
      <div className="nameWithAvatar">
        <Avatar user={option} />
        <span>{option.attributes.name}</span>
      </div>
    );
  }

  @autobind
  updateSelector({ selectedValues }) {
    const {
      formContext: { updateAttribute },
    } = this.props;

    updateAttribute('user_ids', selectedValues);
  }

  @autobind
  searchOptions({ options, searchTerm }) {
    const filteredOptions = options.filter((option) =>
      option.attributes.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return filteredOptions;
  }

  @autobind
  emptyStateComponent() {
    return (
      <div className="empty">
        <AgendaIcon name="class" size="large" />
        <div className="empty-message">Selecione uma turma no campo acima</div>
      </div>
    );
  }

  render() {
    const {
      users,
      userType,
      formContext: { form },
      selectClassroomFrom,
    } = this.props;

    const { isLoadingClassrooms } = this.state;

    return (
      <div className="TempPasswordForm">
        <div className="classrooms-select">
          <div className="Field col-xs-12 col-md-12">
            <FormClassroomSelector
              attributeName="classroom_from"
              disabled={isLoadingClassrooms}
              onChange={this.loadUsers}
              placeholder={
                isLoadingClassrooms
                  ? 'Carregando turmas...'
                  : 'Selecionar turma'
              }
              value={selectClassroomFrom}
            />
          </div>
        </div>
        <FormSelector
          options={users}
          selectedValues={form.user_ids}
          optionComponent={this.optionComponent}
          onChange={this.updateSelector}
          search={this.searchOptions}
          searchPlaceholder={`Buscar ${
            userType === 'student' ? 'aluno' : 'responsável'
          }`}
          optionAccessor={(option) => option.id}
          selectAllLabel="Selecionar todos"
          onSelectBehavior="highlight"
          counterLabel={(length) => (length === 1 ? 'aluno' : 'alunos')}
          emptyState={this.emptyStateComponent()}
        />
      </div>
    );
  }
}

export default withAppContext(withFormContext(TempPasswordForm));
