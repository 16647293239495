import React from 'react';
import tabifyForWizard from 'core/hoc/tabifyForWizard';
import withFormContext, { formPropTypes } from 'core/hoc/withFormContext';
import * as S from './styles';

import Field from 'components/Form/Field';
import FormCheckbox from 'components/Form/Checkbox';
import FormFieldset from 'components/Form/Fieldset';

import validations from './validations';

const PaymentsSchoolWalletFormWalletInformationsTab = ({
  formContext: {
    action,
    form,
    updateAttribute,
    formMeta: {
      select_options: { category },
    },
  },
}) => {
  const toggleCheckbox = () => {
    const value = form.free_iss;

    updateAttribute('free_iss', !value);
  };

  const formattedArray = (array) => {
    const formattedArray = array.map((current) => ({
      label: current.name,
      value: current.value,
    }));

    formattedArray.unshift({ value: null, label: 'Selecione uma categoria' });

    return formattedArray;
  };

  return (
    <S.WalletInformationsWrapper>
      <FormFieldset>
        <Field
          label="Nome da carteira*"
          placeholder="Insira um nome"
          attributeName="wallet_name"
        />
        <Field
          label="CNPJ da conta bancária*"
          attributeName="document_number"
          type="masked"
          mask="99.999.999/9999-99"
          disabled={action !== 'new'}
          placeholder={'00.000.000/0000-00'}
        />
        <Field
          fullWidth
          withArrowSelect
          type="selectWithIcon"
          label={'Categoria'}
          attributeName="category"
          options={formattedArray(category)}
          value={form.category}
          onChange={(e) => updateAttribute('category', e.value)}
        />
        <S.IssWrapper>
          <FormCheckbox checked={form.free_iss} onChange={toggleCheckbox} />
          <span onClick={toggleCheckbox}>A escola é isenta de ISS</span>
        </S.IssWrapper>

        <S.RequireItemsMessage>
          *Campos de preenchimento obrigatório
        </S.RequireItemsMessage>
      </FormFieldset>
    </S.WalletInformationsWrapper>
  );
};

PaymentsSchoolWalletFormWalletInformationsTab.propTypes = {
  ...formPropTypes,
};

export default tabifyForWizard({
  title: '1. Informações',
  subTitle: 'Informações gerais',
  validations,
})(withFormContext(PaymentsSchoolWalletFormWalletInformationsTab));
