import React, { useEffect, useState } from 'react';
import { Props } from './types';
import { IMAGES_PAYMENT_URL } from 'core/constants';
import { useDispatch, useSelector } from 'react-redux';
import { WalletsState } from 'components/Payments/WalletInformation/types';

import walletsActions from 'store/edupay/wallets/actions';

import AlertModal from 'components/AlertModal';
import ModalContainer from 'components/Modal/ModalContainer';
import PaymentsTransferService from 'core/services/Payments/TransfersService';
import TransferForm from 'components/Payments/TransferForm';
import PageTitle from 'components/PageTitle';
import Loader from 'components/Loader';

const WalletTransfer = ({ transferModal, toggleModal }: Props) => {
  const dispatch = useDispatch();

  const { balance, bankAccount, currentWallet } = useSelector(
    (state: WalletsState) => state.wallets
  );

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isTransferSucceeded, setIsTransferSucceeded] = useState(false);
  const { fetchAccountBalanceRequest, fetchBankAccountRequest } =
    walletsActions;

  useEffect(() => {
    if (transferModal) {
      dispatch(fetchBankAccountRequest());
    }
  }, [dispatch, transferModal, fetchBankAccountRequest]);

  const toggleSuccessModal = () => {
    setIsTransferSucceeded(isTransferSucceeded ? false : true);
  };

  const renderTitleModal = (imageUrl: string) => {
    return (
      <div className="titleModalContainer">
        <img className="imageModal" src={imageUrl} />
        <PageTitle>Transferência solicitada</PageTitle>
      </div>
    );
  };

  const onTransferSubmit = async (formState, { goToFirstStep }) => {
    const {
      form,
      updateFormContext,
      addErrorsToFormValidationResultFromServer,
    } = formState;

    const paymentsTransfersService = new PaymentsTransferService('schools');

    try {
      setIsSubmitting(true);
      await paymentsTransfersService.create({
        recipient_wallet_id: currentWallet.attributes.recipient_wallet_id,
        amount: form.amount,
        description: form.description,
      });
      dispatch(fetchAccountBalanceRequest());
      toggleModal();
      toggleSuccessModal();
    } catch (error) {
      if (error.response.status === 422) {
        updateFormContext(() => ({ hasBackendValidationError: true }));
        addErrorsToFormValidationResultFromServer(error.response);
        goToFirstStep();
      } else {
        throw error;
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <ModalContainer
        isOpen={transferModal}
        toggleModal={() => {
          toggleModal();
        }}
        className="TransferModal"
      >
        {balance && bankAccount ? (
          <TransferForm
            availableAmount={balance.manual}
            bankCode={bankAccount.attributes.code}
            taxTransfer={bankAccount.attributes.tax_transfer}
            onSubmit={onTransferSubmit}
            isSubmitting={isSubmitting}
          />
        ) : (
          <Loader />
        )}
      </ModalContainer>
      <AlertModal
        closeAlert={toggleSuccessModal}
        isOpen={isTransferSucceeded}
        onConfirm={toggleSuccessModal}
        showBackButton={false}
        title={renderTitleModal(IMAGES_PAYMENT_URL.onboardingUrl)}
      >
        Caso tenha sido realizada em um dia útil, entre 8h e 15h, o valor será
        transferido ainda hoje. Caso contrário, será realizada no próximo dia
        útil. Acompanhe o andamento na aba ‘Transferências’.
      </AlertModal>
    </>
  );
};

export default WalletTransfer;
