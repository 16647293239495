import React from 'react';

import withAppContext from 'core/hoc/withAppContext';

import * as S from './styled';

const SkeletonEngagementItem = ({ children, isLoading }) => {
  if (children && !isLoading) {
    return children;
  }

  return (
    <S.Item>
      <S.ResponsibleProfile>
        <S.Avatar />
        <S.Column>
          <S.Row width="150px" />
          <S.Row width="100px" />
        </S.Column>
      </S.ResponsibleProfile>
      <S.Column>
        <S.Row width="50px" />
        <S.Row width="100px" />
      </S.Column>
      <S.Column>
        <S.Row width="100px" />
        <S.Row width="50px" />
      </S.Column>
      <S.Column>
        <S.Row width="100px" />
        <S.Row width="50px" />
      </S.Column>
      <S.Column>
        <S.Row width="135px" height="32px" />
      </S.Column>
    </S.Item>
  );
};

export default withAppContext(SkeletonEngagementItem);
