import React from 'react';
import { ShopListFiltersProps } from './types';
import FilterContainer, {
  DoubleInputsWrapper,
} from 'components/FilterContainer';
import Field from 'components/Form/Field';
import Search from 'components/Form/Search';
import * as S from './styles';

const ShopListFilters = ({
  form,
  formMeta,
  handleFilterSubmit,
}: ShopListFiltersProps) => {
  const { status: metaStatus } = formMeta;

  return (
    <FilterContainer form={form} formMeta={formMeta}>
      <S.FilterWrapper>
        <DoubleInputsWrapper>
          <Field
            label="Status"
            attributeName="status"
            type="selectWithIcon"
            options={metaStatus}
            onChange={(e) => handleFilterSubmit({ status: e.value, page: 1 })}
            fullWidth
            withArrowSelect
          />
          <Search
            label="Buscar"
            placeholder="Digite o nome da oferta"
            attributeName="title"
            onSubmit={(params) =>
              handleFilterSubmit({
                title: params.title,
                page: 1,
              })
            }
          />
        </DoubleInputsWrapper>
      </S.FilterWrapper>
    </FilterContainer>
  );
};

export default ShopListFilters;
