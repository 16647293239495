import styled from 'styled-components';
import colorWithAlpha from 'core/utils/colorWithAlpha';

export const Wrapper = styled.div`
  .PageContainer .container-content .main-content {
    padding-bottom: 0;
  }
`;

export const SummaryList = styled.ul`
  margin: 0;
  padding: 0;

  li {
    display: flex;
    align-items: center;
    font-family: 'Quicksand', sans-serif;
    font-weight: 500;
    font-size: 16px;
    list-style: none;
    line-height: 170%;
    color: ${(props) => props.theme.black};

    div,
    b {
      margin-left: 8px;
    }
  }
`;

export const InconsistenciesTag = styled.span`
  display: block;
  margin-top: 10px;
  max-width: 130px;
  font-family: 'Quicksand', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 100%;
  padding: 5px 8px;
  border-radius: 4px;
  background-color: ${(props) => colorWithAlpha(props.theme.danger, 0.1)};
  cursor: pointer;
  color: ${(props) => props.theme.danger};
`;

export const StatusTag = styled.div`
  padding: 5px 8px;
  font-family: Quicksand, sans-serif;
  font-weight: 700;
  font-size: 12px;
  width: fit-content;
  border-radius: 4px;
  color: ${(props) => props.theme[props.color]};
  background-color: ${(props) =>
    colorWithAlpha(`${props.theme[props.color]}`, 0.1)};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
