import React from 'react';
import { useSelector } from 'react-redux';
import TabsBoxContainer from 'components/TabsBoxContainer';
import InformationTab from './InformationTab';
import BillsTab from './BillsTab';
import HistoryTab from './HistoryTab';
import NegotiationTab from './NegotiationTab';
import { flags } from 'core/constants/flags';
import Toast from 'components/Toast';

const MOCKED_RECURRENT_PLAN = {
  id: 1,
  attributes: {
    title: 'Mensalidade - Matheus',
  },
};

const TABS = [
  {
    Component: InformationTab,
  },
  {
    Component: BillsTab,
    props: {
      recurrentPlan: MOCKED_RECURRENT_PLAN,
    },
  },
  {
    Component: HistoryTab,
  },
];

const ShowRecurrentPlan = (props) => {
  const {
    policies: { non_payment_active_flags: nonPaymentActiveFlags },
  } = useSelector((state) => state.root);

  const isNegotiationTabActive = nonPaymentActiveFlags.includes(
    flags.NEGOTIATION_TAB
  );

  return (
    <div className="ShowRecurrentPlan">
      <TabsBoxContainer
        breadcrumb={{
          title: 'Cobranças',
          path: '/schools/school_products/tab/recurrent',
        }}
        title="Detalhes do plano de recorrência"
        tabs={
          isNegotiationTabActive
            ? [
                ...TABS,
                {
                  Component: NegotiationTab,
                },
              ]
            : TABS
        }
        {...props}
      />
      <Toast />
    </div>
  );
};
export default ShowRecurrentPlan;
