import React from 'react';
import ReactJsPagination, { ReactJsPaginationProps } from 'react-js-pagination';
import AgendaIcon from 'components/AgendaIcon';

import './Pagination.scss';

const Pagination = ({
  activePage = 1,
  totalItemsCount = 1,
  itemsCountPerPage = 1,
  onChange,
}: ReactJsPaginationProps): React.ReactElement => {
  return (
    <div data-testid="pagination-wrapper" className="Pagination">
      <ReactJsPagination
        data-testid="pagination"
        activePage={activePage}
        disabledClass="disabled"
        onChange={onChange}
        hideFirstLastPages={true}
        totalItemsCount={totalItemsCount}
        itemsCountPerPage={itemsCountPerPage}
        prevPageText={<AgendaIcon name="arrow-left" size="medium" />}
        nextPageText={<AgendaIcon name="arrow-right" size="medium" />}
      />
    </div>
  );
};

export default Pagination;
