import React from 'react';
import FormFullScreen from 'components/FormFullScreen';
import { NewOfferFormProps } from './types';
import OfferFormInformationTab from 'components/Payments/Shop/OfferForm/OfferFormInformationTab';
import OfferFormOfferTab from 'components/Payments/Shop/OfferForm/OfferFormOfferTab';
import OfferFormRecipientTab from 'components/Payments/Shop/OfferForm/OfferFormRecipientTab';

const WIZARD_STEPS = [
  OfferFormInformationTab,
  OfferFormOfferTab,
  OfferFormRecipientTab,
];

const OfferForm = ({
  action,
  onSubmit,
  form,
  formMeta,
  isSubmitting,
  backTo,
  titlePage,
  titleModal,
  contentModal,
  children,
}: NewOfferFormProps): JSX.Element => {
  return (
    <div>
      <FormFullScreen
        action={action}
        onSubmit={onSubmit}
        form={form}
        formMeta={formMeta}
        isSubmitting={isSubmitting}
        steps={WIZARD_STEPS}
        backTo={backTo}
        titlePage={titlePage}
        titleModal={titleModal}
        contentModal={contentModal}
      >
        {!!children && children}
      </FormFullScreen>
    </div>
  );
};

export default OfferForm;
