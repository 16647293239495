export const EXTRACT_FROM_OPTIONS = [
  { label: 'Valor recebido', value: 'available' },
  { label: 'Valor a receber', value: 'receivable' },
];

export const EXTRACT_STATUS = {
  available: 'available',
  receivable: 'receivable',
};

export const TRANSFERS_SITUATIONS = [
  { label: 'Todas as situações', value: '' },
  { label: 'Transferido', value: 'transferred' },
  { label: 'Processando', value: 'processing' },
  { label: 'Erro', value: 'failed' },
];

export const TRANSFERS_STATUS = {
  transferred: { label: 'Transferido', variant: 'positive' },
  processing: { label: 'Processando', variant: 'informative' },
  failed: { label: 'Erro', variant: 'negative' },
  debited: { label: 'Debitado', variant: 'negative' },
};

export const CARD_BALANCE_TOOLTIP_MESSAGES = {
  receivable:
    'Valores pagos que já foram transferidos<br>para a conta bancária da escola.',
  available:
    'Somatório dos valores já disponíveis que<br>serão enviados para a conta bancária da escola.',
  waiting_funds:
    'Valores pagos pelos responsáveis que<br>ainda não estão disponíveis para transferência.',
  transferred:
    'Valores transferidos para a<br>conta bancária da escola<br>durante o período definido.',
};
