import React, { useState, useEffect } from 'react';

const withWindowSize = WrappedComponent => (props) => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  const updateWindowSize = e => setWindowSize(e.target.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', updateWindowSize);

    return () => {
      window.removeEventListener('resize', updateWindowSize);
    };
  }, [windowSize]);

  return <WrappedComponent {...props} windowSize={windowSize} />;
};

export default withWindowSize;
