import React from 'react';
import {
  Grid,
  DefaultThemeProps,
  theme,
  Box,
} from '@agendaedu/ae-web-components';

import Skeleton from 'components/Skeleton';

import * as S from './styles';

export const GroupMembersStepSkeleton = () => {
  const defaultTheme: DefaultThemeProps = theme;

  const {
    colors: { neutral },
    border: { radius },
  } = defaultTheme;

  return (
    <S.FormSkeletonWrapper data-testid="skeleton-wrapper">
      <Grid
        gridTemplateColumns="1fr"
        gutter={{
          _: 'lg',
        }}
      >
        <Grid item gutter="sm">
          <Skeleton
            width={190}
            height={35}
            primaryColor={neutral.gray6}
            borderRadius={radius.md}
          />
        </Grid>

        <Grid item gutter={'md'}>
          <Box gap={40} display={'flex'} flexDirection={'row'}>
            <Skeleton
              width="100%"
              height={360}
              primaryColor={neutral.gray6}
              borderRadius={radius.md}
              isFullWidth
            />

            <Skeleton
              width="100%"
              height={360}
              primaryColor={neutral.gray6}
              borderRadius={radius.md}
              isFullWidth
            />
          </Box>
        </Grid>
      </Grid>
    </S.FormSkeletonWrapper>
  );
};
