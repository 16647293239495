import validatePresence from 'core/lib/FormValidator/validators/presence';
import validateLength from 'core/lib/FormValidator/validators/length';
import validateArrayPresence from 'core/lib/FormValidator/validators/array/presence';
import validateRecurrentItems from 'core/lib/FormValidator/validators/recurrentItems';
import {
  validateFinePercent,
  validateInterestPercent,
} from 'core/lib/FormValidator/validators/penality';

export default [
  {
    attributeName: 'title',
    validator: validatePresence,
    message: 'Campo obrigatório',
  },
  {
    attributeName: 'legacy_id',
    validator: validateLength({ max: 50 }),
    message: 'Máximo: 50 caracteres',
  },
  {
    attributeName: 'title',
    validator: validateLength({ max: 90 }),
    message: 'Máximo: 90 caracteres',
  },
  {
    attributeName: 'description',
    validator: validatePresence,
    message: 'Campo obrigatório',
  },
  {
    attributeName: 'allowed_payment_method',
    validator: validateArrayPresence,
    message: 'Selecione um método de pagamento para continuar',
  },
  {
    attributeName: 'bills_quantity',
    validator: validatePresence,
    message: 'Campo obrigatório',
  },
  {
    attributeName: 'first_bill_due_date',
    validator: validatePresence,
    message: 'Campo obrigatório',
  },
  {
    attributeName: 'due_day',
    validator: validatePresence,
    message: 'Campo obrigatório',
  },
  {
    attributeName: 'bill_release_day',
    validator: validatePresence,
    message: 'Campo obrigatório',
  },
  {
    attributeName: 'recipient_wallet_id',
    validator: validatePresence,
    message: 'Campo obrigatório',
  },
  {
    attributeName: 'recurrentItems',
    validator: validateRecurrentItems,
    message: 'Possui itens inválidos, reveja os valores informados',
  },
  {
    attributeName: 'edupay_penality',
    validator: validateFinePercent,
    message: 'A multa é de no máximo 2%',
  },
  {
    attributeName: 'edupay_penality',
    validator: validateInterestPercent,
    message: 'Os juros são de no máximo 1% a.m.',
  },
];
