import axios from 'axios';

/**
 * Class responsible for fetching the address from a given zipcode.
 * @example
 *    zipcodeService = new ZipcodeService('60440-135');
 *    zipcodeService
 *      .fetchAddress()
 *      .then(({ street, neighborhood, city, state }) => {
 *        // Do stuff...
 *      });
 */
export default class ZipcodeService {
  /**
   * @param {String} zipcode
   */
  constructor(zipcode) {
    this.zipcode = zipcode;
  }

  /**
   * This is the reponse from pagar.me.
   *    {
   *      "zipcode": "60440135",
   *      "street": "Rua Guanabara",
   *      "neighborhood": "Demócrito Rocha",
   *      "city":"Fortaleza",
   *      "state":"CE"
   *    }
   * @returns {Promise}
   */
  async fetchAddress() {
    const response =
      await axios.get(`https://api.pagar.me/1/zipcodes/${this.zipcode}`);

    const { street, neighborhood, city, state } = response.data;

    return { street, neighborhood, city, state };
  }
}
