import validatePresence from 'core/lib/FormValidator/validators/presence';
import validateArrayPresence from 'core/lib/FormValidator/validators/array/presence';
import {
  validateFinePercent,
  validateInterestPercent,
} from 'core/lib/FormValidator/validators/penality';

const validateRecurrentBatchPresence = (input, state) => {
  let result = true;

  if (!state.toggleBatchDueDate) return true;

  result = validatePresence(input);

  return result;
};

const validateRecurrentBatchArrayPresence = (input, state) => {
  let result = true;

  if (!state.toggleBatchPayment) return true;

  result = validateArrayPresence(input);

  return result;
};

const validateRecurrentBatchWalletPresence = (input, state) => {
  let result = true;

  if (!state.toggleBatchWallet) return true;

  result = validatePresence(input);

  return result;
};

export default [
  {
    attributeName: 'due_date',
    validator: validateRecurrentBatchPresence,
    message: 'Campo obrigatório',
  },
  {
    attributeName: 'allowed_payment_method',
    validator: validateRecurrentBatchArrayPresence,
    message: 'Selecione um método de pagamento para continuar',
  },
  {
    attributeName: 'edupay_penality',
    validator: validateFinePercent,
    message: 'A multa é de no máximo 2%',
  },
  {
    attributeName: 'edupay_penality',
    validator: validateInterestPercent,
    message: 'Os juros são de no máximo 1% a.m.',
  },
  {
    attributeName: 'recipient_wallet_id',
    validator: validateRecurrentBatchWalletPresence,
    message: 'Campo obrigatório',
  },
];
