/* eslint-disable no-debugger, react/prop-types */
import React from 'react';
import TableField from '../TableField';

const RowNumber = (data) => <span>{data.id}</span>;

const ExternalId = (data) => <TableField property="external_id" data={data} />;

const ClassroomExternalId = (data) => (
  <TableField property="classroom_id" data={data} />
);

const Name = (data) => <TableField property="name" data={data} />;

const Email = (data) => <TableField property="email" data={data} />;

const DateOfBirth = (data) => (
  <TableField property="date_of_birth" data={data} />
);

const Period = (data) => <TableField property="period" data={data} />;
const Login = (data) => <TableField property="username" data={data} />;
const Ra = (data) => <TableField property="ra" data={data} />;

export default [
  {
    Header: 'Linha',
    accessor: RowNumber,
  },
  {
    Header: 'Identificador Registro',
    accessor: ExternalId,
  },
  {
    Header: 'Identificador Turma',
    accessor: ClassroomExternalId,
  },
  {
    Header: 'Nome',
    accessor: Name,
  },
  {
    Header: 'E-mail',
    accessor: Email,
  },
  {
    Header: 'Data de Nascimento',
    accessor: DateOfBirth,
  },
  {
    Header: 'Turno',
    accessor: Period,
  },
  {
    Header: 'Login',
    accessor: Login,
  },
  {
    Header: 'Registro Acadêmico',
    accessor: Ra,
  },
];
