import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { InviteAccountModalProps, RootState } from './types';
import { Box, Icon } from '@agendaedu/ae-web-components';
import InviteField from '../InviteField';
import FormContainer from 'components/Form/FormContainer';
import inviteEmptyStateActions from 'store/invite/emptyState/actions';
import * as S from './styles';

const InviteAccountModal = ({
  thumbUrl,
  resource,
  title,
  text,
}: InviteAccountModalProps) => {
  const { form, isLoading, error } = useSelector(
    (state: RootState) => state.inviteEmptyState
  );

  const { generateInviteUrl } = inviteEmptyStateActions;
  const dispatch = useDispatch();

  const handleGenerateInviteUrl = useCallback(() => {
    dispatch(generateInviteUrl(resource));
  }, [dispatch, generateInviteUrl, resource]);

  useEffect(() => {
    handleGenerateInviteUrl();
  }, [handleGenerateInviteUrl]);

  return (
    <S.ModalContainer>
      <S.ModalHeader thumbUrl={thumbUrl}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <S.ModalTitle>{title}</S.ModalTitle>
          <Icon name="multiply" color="#ffffff" data-dismiss="modal" />
        </Box>
      </S.ModalHeader>

      <S.ModalInfo>
        <S.ModalText>{text}</S.ModalText>

        <FormContainer initialAttributes={form.invite_link ? form : {}}>
          <InviteField
            attributeName="invite_link"
            disabled={isLoading || error}
            error={error}
          />
        </FormContainer>
      </S.ModalInfo>
    </S.ModalContainer>
  );
};

export default InviteAccountModal;
