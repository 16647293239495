/* eslint-disable react/jsx-key */
import React from 'react';
import * as S from './styles';

const EmptyState = ({ descrptions, title, imageUrl }) => {
  return (
    <S.Wrapper>
      <S.ContainerText>
        <S.ContainerTitle>
          <S.Title>{title}</S.Title>
        </S.ContainerTitle>
        <S.Text>{descrptions}</S.Text>
      </S.ContainerText>
      <S.ContainerImage>
        <S.Image src={imageUrl} alt="Empty state" />
      </S.ContainerImage>
    </S.Wrapper>
  );
};

export default EmptyState;
