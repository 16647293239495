import styled, { css } from 'styled-components';

export const MethodWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    width: 65px;
    height: 40px;
    flex-wrap: wrap;
    > span:first-of-type {
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.14) !important;
      border: none;
      text-align: center;
      vertical-align: middle;
      padding: 6px 12px;
      border-radius: 4px;
      display: flex;
      align-items: center;
    }

    > .ColoredText {
      min-width: 20px;
      font-weight: 500;
      font-size: 11px;
    }
    .AgendaIcon {
      color: ${theme.primaryColor} !important;
    }
    .icon-ae-pix:before {
      color: ${theme.primaryColor} !important;
    }
  `}
`;
