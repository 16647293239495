import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const VARIATION_TYPES = [
  'primary',
  'success',
  'bold',
  'warning',
  'danger',
  'default',
  'gray-7',
  'gray-2',
  'w600',
];

const StyledSpan = styled.span`
  &.primary {
    color: ${props => props.theme.primaryColor};
  }

  &.success {
    color: #00A99D;
  }

  &.info {
    color: #3FACDE;
  }

  &.warning {
    color: #fcb323;
  }

  &.danger {
    color: #FF6C60;
  }

  &.bold {
    font-weight: bold;
  }

  &.dark {
    color: #333333;
    font-weight: 600;
  }

  &.gray-2 {
    color: ${props => props.theme.gray2};
  }

  &.gray-7 {
    color: #777;
  }

  &.w600 {
    font-weight: 600;
  }
`;

const ColoredText = ({ variation, children, onClick }) => (
  <StyledSpan className={`ColoredText ${variation}`} onClick={onClick}>{children}</StyledSpan>
);

ColoredText.propTypes = {
  variation: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

ColoredText.defaultProps = {
  onClick: () => { },
}

export default ColoredText;
