import React from 'react';
import _ from 'lodash';

import { ActionsButtons } from '../ActionsButtons';
import { DiaryFormStructure } from 'store/dailySummaries/types';
import {
  isEmptyOccurrence,
  OccurrenceCard,
} from 'pages/diarySectionsV3/Sections/components/ShowSectionModal/Sections';
import { defaultOccurrence } from 'core/contexts/DiaryForm/constants';
import { Props } from './types';

export const OccurrencesGroup = ({ occurrences, onChange }: Props) => {
  const actions = {
    add: (index: number) => {
      const newValues = defaultOccurrence();
      const fieldValues = _.cloneDeep(occurrences);

      if (index >= 0 && index < fieldValues.length) {
        fieldValues.splice(index + 1, 0, newValues);
      } else {
        fieldValues.push(newValues);
      }

      onChange(fieldValues);
    },
    remove: (index: number) => {
      const fieldValues = _.cloneDeep(occurrences);

      fieldValues.splice(index, 1);

      onChange(fieldValues);
    },
    update: (occurrence: DiaryFormStructure['occurrence'], index: number) => {
      const fieldValues = _.cloneDeep(occurrences);

      fieldValues[index] = occurrence;

      onChange(fieldValues);
    },
    clear: (index: number) => {
      const fieldValues = _.cloneDeep(occurrences);

      const currentValue = fieldValues[index];

      const emptyValue = {
        ...currentValue,
        ...defaultOccurrence(),
      };

      fieldValues[index] = emptyValue;

      onChange(fieldValues);
    },
  };

  return (
    <>
      {occurrences.map((occurrence, index) => {
        const { add, remove, update, clear } = actions;
        const isEmpty = isEmptyOccurrence(occurrence);

        const footer = (
          <ActionsButtons
            key={`actions-${occurrence.key}`}
            index={index}
            addSectionCard={add}
            removeSectionCard={remove}
            clearSectionAction={clear}
            canAddSectionCard={occurrences.length <= 5}
            isEmpty={isEmpty}
          />
        );

        return (
          <OccurrenceCard
            id={occurrence.key}
            key={occurrence.key}
            occurrence={occurrence}
            onChange={(newOccurrence) => update(newOccurrence, index)}
            footer={footer}
          />
        );
      })}
    </>
  );
};
