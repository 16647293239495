import React from 'react';
import PropTypes from 'prop-types';
import getHocName from 'core/utils/getHocName';

const withWindowPathname = (WrappedComponent) => {
  const withWindowPathname = (props) => {
    return (
      <WrappedComponent {...props} windowPathname={window.location.pathname} />
    );
  };

  withWindowPathname.displayName = getHocName(
    'withWindowPathname',
    WrappedComponent
  );

  return withWindowPathname;
};

export const windowPropTypes = {
  windowPathname: PropTypes.string,
};

export default withWindowPathname;
