import validatePresence from 'core/lib/FormValidator/validators/presence';
import validateNumericalFloat from 'core/lib/FormValidator/validators/numericalFloat';
import validateArrayPresence from 'core/lib/FormValidator/validators/array/presence';
import {
  validateGreaterThanZero,
  validateSameDay,
  validatePresenceDay,
  validateMaxDay,
} from 'core/utils/edupay/validations/discounts';

export const validatesDiscount = (key) => [
  {
    attributeName: key,
    validator: validateGreaterThanZero,
    message: 'O valor tem que ser maior que zero',
  },
  {
    attributeName: key,
    validator: validateSameDay,
    message: 'Já existe um desconto para esta data.',
  },
  {
    attributeName: key,
    validator: validatePresenceDay,
    message: 'Campo dias para vencimento é obrigatório',
  },
  {
    attributeName: key,
    validator: validateMaxDay,
    message: 'Valor máximo para o campo dias para vencimento é 365',
  },
];

export default [
  {
    attributeName: 'description',
    validator: validatePresence,
    message: 'Campo obrigatório',
  },
  {
    attributeName: 'allowed_payment_method',
    validator: validateArrayPresence,
    message: 'Selecione um método de pagamento para continuar',
  },
  {
    attributeName: 'price',
    validator: validateNumericalFloat({ min: 10.0 }),
    message: 'Valor mínimo R$ 10,00',
  },
  {
    attributeName: 'expires_at',
    validator: validatePresence,
    message: 'Escolha uma data de vencimento.',
  },
  {
    attributeName: 'recipient_wallet_id',
    validator: validatePresence,
    message: 'Campo obrigatório',
  },
];
