import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './styles';

import { Avatar, Text } from '@agendaedu/ae-web-components';
import { MessageGroupAvatar } from 'components/Messages/OmniChannel/MessageGroupAvatar';

import { ChatUnreadCardProps } from './types';

export const UnreadChatCard = ({
  dataArea,
  unreadChatCard: {
    chatId,
    channelId,
    avatarColor,
    avatarUrl,
    type,
    kind,
    lastMessagePreview,
    lastMessageSentAt,
    name,
    nameInitials,
    roleMessage,
  },
  handleCloseModal,
}: ChatUnreadCardProps): JSX.Element => {
  const { t } = useTranslation('messages');

  const handleRedirectCurrentPathOnClickMessages = useCallback(() => {
    window.location.href = `/${dataArea}/messages/channels/${channelId}/chats/${chatId}/messages`;
    handleCloseModal();
  }, [channelId, chatId]);

  return (
    <S.CardWrapper
      data-testid="card-notification-wrapper"
      onClick={handleRedirectCurrentPathOnClickMessages}
    >
      <S.CardTopContent>
        {kind === 'family' ? (
          <MessageGroupAvatar
            size="sm"
            nameInitials={nameInitials}
            avatarColor={avatarColor}
            avatarUrl={avatarUrl}
          />
        ) : (
          <Avatar
            avatarColor={avatarColor}
            size="md"
            nameInitials={nameInitials}
            avatarUrl={avatarUrl}
          />
        )}

        <S.UserContentWrapper>
          <S.RoleWrapper>
            <Text variant="subtitle-medium-12" color={'neutral.gray2'}>
              {roleMessage ===
              t('omni_channel.modals.count_notification.student_role')
                ? t('omni_channel.modals.count_notification.student_role')
                : t('omni_channel.modals.count_notification.profile_role', {
                    role: roleMessage,
                  })}
            </Text>
            <Text variant="subtitle-medium-12" color={'neutral.gray2'}>
              {lastMessageSentAt}
            </Text>
          </S.RoleWrapper>
          <Text variant="title-bold-14" color="neutral.black" fontWeight="700">
            {name}
          </Text>
        </S.UserContentWrapper>
      </S.CardTopContent>
      {lastMessagePreview && (
        <Text
          variant="body-medium-14"
          color="neutral.black"
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          {lastMessagePreview}
        </Text>
      )}
    </S.CardWrapper>
  );
};
