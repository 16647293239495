import React, {
  useEffect,
  useMemo,
  lazy,
  Suspense,
  useState,
  useCallback,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Toast from 'components/Toast';
import Loader from 'components/Loader';
import ConfirmEditModal from './ConfirmEditModal';
import CategoriesGeneralInformationStep from './InformationStep';

import withAppContext from 'core/hoc/withAppContext';

import { HandoutState, FormAttributesProps } from 'store/handouts/types';
import HandoutsActions from 'store/handouts/actions';

import { CategoriesScreenProps, FormSubmitProps } from './types';
import * as S from './styles';

const HandoutCategoriesForm = ({ match }: CategoriesScreenProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['handouts']);
  const [showEditModal, setShowModal] = useState(false);

  const { id: routeId } = match?.params;
  const currentAction = routeId ? 'edit' : 'new';

  const { createNewCategoryRequest, editCategory, fetchCategoryDetails } =
    HandoutsActions;

  const { isCategoryLoading, isLoading, formMeta, current } = useSelector(
    (state: HandoutState) => state.handouts
  );

  const handleToggleModal = () => {
    setShowModal(!showEditModal);
  };

  const handleNewCategory = ({ form: { categoryName } }: FormSubmitProps) => {
    dispatch(createNewCategoryRequest(categoryName, true));
  };

  const handleEditCategory = useCallback(
    ({ categoryName: label, categoryId: id }: FormAttributesProps) => {
      dispatch(editCategory({ label, id, value: id }));
      handleToggleModal();
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, editCategory]
  );

  const FormFullScreen = useMemo(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    () => lazy(() => import('components/FormFullScreen')),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [current]
  );

  const currentForm = {
    new: {
      action: 'new',
      onSubmit: handleNewCategory,
    },
    edit: {
      action: 'edit',
      onSubmit: handleToggleModal,
    },
  };

  useEffect(() => {
    if (routeId) dispatch(fetchCategoryDetails(routeId));
  }, [dispatch, fetchCategoryDetails, routeId]);

  return (
    <S.CategoriesFormContainer>
      <Suspense fallback={null}>
        <Loader isLoading={isLoading}>
          <FormFullScreen
            form={current}
            initialAttributes={current}
            formMeta={{
              ...formMeta,
              nextStepDisabled: true,
              currentTitle: current.categoryName,
            }}
            isSubmitting={isCategoryLoading}
            steps={[CategoriesGeneralInformationStep]}
            backTo={`/schools/handout_categories`}
            backToExternal
            titlePage={t('categories.form_screen_title')}
            titleModal={t('modals.handout_form_discard.title')}
            contentModal={t('modals.handout_form_discard.description')}
            {...currentForm[currentAction]}
          >
            <ConfirmEditModal
              isOpen={showEditModal}
              toggleModal={handleToggleModal}
              onConfirm={handleEditCategory}
            />
          </FormFullScreen>
        </Loader>
        <Toast />
      </Suspense>
    </S.CategoriesFormContainer>
  );
};

export default withAppContext(HandoutCategoriesForm);
