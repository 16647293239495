import React, { useState, useEffect, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, TextField } from '@agendaedu/ae-web-components';
import FormFieldset from 'components/Form/Fieldset';

import { validateFormErrors } from 'core/helper/handouts';
import withAppContext from 'core/hoc/withAppContext';
import withFormContext from 'core/hoc/withFormContext';
import tabifyForWizard from 'core/hoc/tabifyForWizard';

import { CategoriesFormProps } from '../types';
import validations from './validations';
import * as S from './styles';
import { useSelector } from 'react-redux';
import { HandoutState } from 'store/handouts/types';

const CategoriesGeneralInformationStep = ({
  formContext: {
    action,
    updateAttribute,
    addErrorOnAttribute,
    hasErrorOnAttribute,
    removeErrorOnAttribute,
    getFormValidationResult,
    changeMeta,
    formMeta: { currentTitle },
  },
}: CategoriesFormProps) => {
  const {
    current: { categoryName },
  } = useSelector((state: HandoutState) => state.handouts);

  const { t } = useTranslation(['handouts']);
  const [newTitle, setNewTitle] = useState<Partial<string>>(categoryName);

  const handleTitleChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    if (value === currentTitle) {
      addErrorOnAttribute('categoryName', t('categories.no_change_on_name'));
    } else {
      removeErrorOnAttribute('categoryName', t('categories.no_change_on_name'));
    }

    setNewTitle(value);
    updateAttribute('categoryName', value);
  };

  const handleGetErrors = (name: string) =>
    validateFormErrors(name, getFormValidationResult, hasErrorOnAttribute);

  const getFormTitle =
    action === 'new'
      ? t('categories.create_form_title')
      : t('categories.edit_form_title');

  useEffect(() => {
    changeMeta('nextStepDisabled', !newTitle);
  }, [changeMeta, newTitle]);

  useEffect(() => {
    changeMeta('nextStepDisabled', newTitle === currentTitle);
  }, [changeMeta, newTitle, currentTitle]);

  return (
    <FormFieldset>
      <S.Title>{getFormTitle}</S.Title>
      <Box mb="xs2">
        <TextField
          label={`${t('categories.category_name')}*`}
          placeholder={t('categories.name_placeholder')}
          value={newTitle}
          onChange={handleTitleChange}
          fullWidth
          error={handleGetErrors('categoryName').hasError}
          errorMessage={handleGetErrors('categoryName').errorMessages}
        />
      </Box>
      <S.RequiredFields>
        {`*${t('categories.required_field_text')}`}
      </S.RequiredFields>
    </FormFieldset>
  );
};

export default tabifyForWizard({
  title: '',
  validations,
})(withAppContext(withFormContext(CategoriesGeneralInformationStep)));
