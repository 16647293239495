import styled from 'styled-components';

export const Container = styled.section`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  font-family: Quicksand;

  @media screen and (max-width: 767px) {
    justify-content: center;
  }
`;

export const Card = styled.div`
  width: 140px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border: 1px solid ${({ theme }) => theme.gray3};
  border-radius: 8px;

  @media screen and (max-width: 767px) {
    width: 47%;
  }
`;

export const Label = styled.span`
  font-size: 14px;
  color: ${({ theme }) => theme.gray2};
  font-weight: 500;
  line-height: 1;
`;

export const Metric = styled.span`
  font-size: 24px;
  color: ${({ color, theme }) => theme[color]};
  font-weight: bold;
  line-height: 1.25;
`;
