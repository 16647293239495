import {
  Chat,
  Message,
  SetCreateNewMessageSuccessActionProps,
} from 'store/messages/omniChannel/types';

export const normalizeNewMessageReducer = (
  action: SetCreateNewMessageSuccessActionProps,
  chats: Chat[],
  chat: Chat,
  isSearchTab: boolean,
  messages: Message[]
) => {
  const { lastMessage, included } = action;

  const approvalPending = lastMessage.attributes.approvalStatus === 'pending';

  let newChat: Chat = chat;
  let newChatsList: Chat[] = chats;

  const currentChat = chats.find(
    (chat) => chat.id === lastMessage.relationships.chat.data.id
  );

  const newMessage: Message = {
    ...lastMessage,
    relationships: {
      ...lastMessage.relationships,
      attachedMessage: {
        data: included,
      },
    },
  };
  const newMessages = [newMessage, ...messages];

  if (!approvalPending && !isSearchTab && currentChat) {
    const newlastMessage = lastMessage.attributes;
    newChat = {
      ...currentChat,
      attributes: {
        ...currentChat.attributes,
        last_message_preview: newlastMessage.text,
        last_message_sent_at: newlastMessage.sentAt,
        student_profile_name: chat.attributes.student_profile_name,
        student_profile_id: chat.attributes.student_profile_id,
        read_status: 'answered',
      },
      relationships: {
        ...currentChat.relationships,
        last_message: {
          data: {
            id: lastMessage.id,
            type: lastMessage.type,
          },
        },
        sender: action.lastMessage.attributes.user,
      },
    };

    newChatsList = [newChat, ...chats.filter((chat) => chat.id !== newChat.id)];
  } else {
    newChatsList = chats;
  }

  return { messages: newMessages, chats: newChatsList, activeChat: newChat };
};
