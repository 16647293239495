import React from 'react';
import PropTypes from 'prop-types';

import withAppContext from 'core/hoc/withAppContext';
import colorWithAlpha from 'core/utils/colorWithAlpha';

import './style.scss';

function FormSelectorSelectableOption({
  children,
  onClick,
  selected,
  appContext,
}) {
  const style = {
    backgroundColor: selected
      ? colorWithAlpha(appContext.primaryColor, 0.1)
      : '#FFF',
  };

  return (
    <div
      className={`SelectableOption ${selected ? 'selected' : ''}`}
      onClick={onClick}
      style={style}
    >
      {children}
    </div>
  );
}

FormSelectorSelectableOption.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  appContext: PropTypes.shape({
    primaryColor: PropTypes.string,
  }),
};

export default withAppContext(FormSelectorSelectableOption);
