import styled, { css } from 'styled-components';
import { ThemeProps } from 'config/themes/types';
import { StylesProps } from './types';

const emptyStateStyles = ({ theme: { colors, typography } }: ThemeProps) => css`
  width: 192px;

  margin: 0px;
  ${typography.subtitle.Medium16};

  color: ${colors.neutral.gray1};

  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    max-width: fit-content;
    margin-bottom: 24px;
  }
`;

export const GroupResizeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;

  @media screen and (max-width: 992px) {
    width: 100%;
  }
`;

export const GroupWrapper = styled.div<StylesProps>`
  ${({ theme, currentGroup, isWebView }) => css`
    height: calc(100vh - 80px);

    margin: -16px -16px -90px;

    display: flex;

    ${GroupResizeWrapper} {
      display: ${currentGroup && 'none'};
    }

    ${MessageContainer} {
      display: ${currentGroup && 'flex'};
    }

    .EmptyState {
      ${emptyStateStyles({ theme })};
    }

    @media screen and (min-width: 992px) {
      ${GroupResizeWrapper} {
        display: flex;
      }

      ${MessageContainer} {
        display: flex;
      }
    }

    ${isWebView &&
    css`
      height: 100vh;
    `}
  `}
`;

export const GroupContainer = styled.div`
  ${({ theme: { border, colors } }: ThemeProps) => css`
    height: 100%;
    border-right: ${border.width.sm} ${border.style.solid}
      ${colors.neutral.gray4};
    background: ${colors.neutral.white};
    display: flex;
    flex-direction: column;
    min-width: 366px;
    max-width: 500px;
    @media screen and (max-width: 992px) {
      width: 100%;
      max-width: 100%;
      min-width: 100%;
    }
  `}
`;

export const GroupList = styled.ul<Omit<StylesProps, 'currentGroup'>>`
  ${({ theme: { border, colors, gray4 }, empty }) => css`
    height: 100%;
    padding: 0px;
    margin: 0px;

    display: flex;
    align-items: ${empty ? 'center' : 'initial'};
    justify-content: ${empty ? 'center' : 'flex-start'};
    flex-direction: column;

    overflow-y: auto;

    &::-webkit-scrollbar {
      background-color: transparent;
      width: 6px;
    }

    &::-webkit-scrollbar-button {
      display: none;
    }

    &::-webkit-scrollbar-track {
      background-color: ${colors.neutral.gray5};
    }

    &::-webkit-scrollbar-thumb {
      border-radius: ${border.radius.sm};
      border: ${border.width.sm} ${border.style.solid} ${gray4};
    }
  `}
`;

export const MessageContainer = styled.div`
  width: 100%;
  height: 100%;

  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ResizeWindow = styled.div`
  ${({ theme: { colors, space } }: ThemeProps) => css`
    @media screen and (min-width: 992px) {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: 1px;
      background-color: ${colors.neutral.gray4};
      justify-self: flex-end;
      cursor: col-resize;
      position: relative;
      transition: background 0.4s ease;

      :active,
      :hover {
        background: ${colors.brand.primary.op45};
        cursor: col-resize;
        flex-basis: 4px;
      }
      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        opacity: 0;
        pointer-events: none;
      }

      &:hover::after {
        width: ${space.xs3};
        opacity: 1;
      }
    }
  `}
`;
