import styled, { css } from 'styled-components';

export const EdupayDiscountWrapper = styled.div`
  display: flex;
  flex-direction: column;

  .FormValidationErrors {
    display: flex;
    align-items: flex-start;
  }
`;

export const ContainerWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    border: 1px solid ${theme.gray12};
    border-radius: 6px;
    background: ${theme.gray14};
    margin-top: 24px;
    padding: 24px 16px;
  `}
`;

export const EdupayDiscount = styled.div`
  gap: 16px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
`;

export const Text = styled.p`
  ${({ theme }) => css`
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: ${theme.gray2};
    line-height: 24px;
    margin-bottom: 0;
  `}
`;

export const TypeDiscountWrapper = styled.div`
  display: flex;
  margin-bottom: 0;

  > div {
    margin: 0;
    padding: 0;
    margin-bottom: 0 !important;
  }
`;

export const QuantityField = styled.div`
  ${({ theme }) => css`
    height: 40px;
    width: 60px;
    padding: 0;
    margin: 0;
    margin-bottom: 0;

    input {
      height: 40px;
      border: 1px solid ${theme.gray12};
      border-radius: 6px;
      font-size: 16px;
    }

    > div {
      padding: 0;
      margin: 0;
    }
  `}
`;

export const DeleteButtonWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;

    .Delete {
      .OutlineBox {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        width: 40px;
        border-radius: 6px;
        margin-right: 0;
        cursor: pointer;

        .AgendaIcon {
          height: 20px;
          width: 20px;
          color: ${theme.gray2};
        }

        &:hover {
          .AgendaIcon {
            color: ${theme.white};
          }
        }
      }
    }
  `}
`;
