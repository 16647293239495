import colorWithAlpha from 'core/utils/colorWithAlpha';
import styled, { css } from 'styled-components';

export const DropdownButton = styled.div`
  display: flex;

  > .Button {
    height: 34px;
    padding: 6px 8px;
    display: flex;
    align-items: center;
    border-radius: 6px;

    &.text {
      padding: 6px 12px;
      font-size: 16px;
      font-weight: 700;
      font-family: 'Quicksand';

      .AgendaIcon {
        margin-left: 12px;
        margin-bottom: 2px;
      }
    }
  }

  & > ul > li {
    cursor: pointer;
  }

  .dropdown-menu {
    right: 0;
    left: auto;
    border-radius: 6px;

    &.down {
      top: 100%;
      bottom: unset;
      margin-top: 8px;
    }

    &.up {
      top: unset;
      bottom: 100%;
      margin-bottom: 8px;
    }
  }

  .dropdown-item {
    ${({ theme }) => css`
      font-size: 14px;
      font-family: 'Roboto';
      font-weight: 400;
      color: ${theme.gray7};
      margin: 3px;
      border-radius: 6px;

      &:hover {
        background-color: ${colorWithAlpha(`${theme.primaryColor}`, 0.1)};
        color: ${theme.primaryColor};
      }
    `}
  }

  .left {
    text-align: left;
  }

  .right {
    text-align: right;
  }
`;
