/* eslint-disable react/jsx-key */
import React, { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import HomeActions from 'store/home/actions';
import { opacity } from '../../../core/utils/colorOpacity';
import explosionCofetti from './anim/explosion_confet.json';
import icons from './icons/icons.json';
import Lottie from 'react-lottie';
import { Button, Text } from '@agendaedu/ae-web-components';
import EmptyState from './EmptyState';
import { trackEvent } from '../../../config/amplitude';
import withAppContext, { appPropTypes } from 'core/hoc/withAppContext';
import DynamicSkeleton from 'pages/home/DynamicSkeleton';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import * as S from './styles';

const DynamicView = ({ appContext: { policies } }) => {
  const { can_show_hub_store: canShowHubStore } = policies;

  const {
    colors: {
      brand: {
        primary: { default: primaryColor },
      },
    },
  } = useTheme();

  const { t } = useTranslation(['hub_store']);
  const dispatch = useDispatch();
  const { appsDynamicModule, loadDynamicApps } = useSelector(
    (state) => state.home
  );

  const dataArea = useSelector((state) => state.root.dataArea);

  const { fetchDynamicModules } = HomeActions;

  const quantityApps = appsDynamicModule?.length;
  const playerRef = useRef([]);
  const overlayrRef = useRef([]);
  const [isEmptyState, setIsEmptyState] = useState(true);
  const [hoveredIndex, setHoveredIndex] = useState(null);

  useEffect(() => {
    hoveredIndex &&
      document.getElementById(`card-${hoveredIndex}`).scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'center',
      });
  }, [hoveredIndex]);

  useEffect(() => {
    dispatch(fetchDynamicModules());
  }, []);

  useEffect(() => {
    setIsEmptyState(appsDynamicModule.length <= 0);
  }, [quantityApps]);

  const handlerApp = ({ slug, index, renderType, name }) => {
    overlayrRef.current[index].style.visibility = 'visible';
    playerRef.current[index].play();
    trackEvent(`hubstore_${renderType}_${name.replace(/ /g, '_')}`);
    window.location.href = `/${dataArea}/dynamic_modules/${slug}`;
  };

  const handleAccessMarket = () => {
    trackEvent('hubstore_access_dashboard');
    window.location.href = `/${dataArea}/hub_store/products`;
  };

  const renderLoadedContent =
    loadDynamicApps & isEmptyState ? (
      <DynamicSkeleton />
    ) : (
      isEmptyState && (
        <EmptyState
          title={t('fast_access.empty_state.title')}
          imageUrl="https://static.agendaedu.com/hubstore/hubstore_empty.png?v1"
          descrptions={t('fast_access.empty_state.descrption')}
        />
      )
    );

  return (
    <>
      {canShowHubStore && (
        <S.ContainerTitle>
          <S.MainTitle>{t('fast_access.title_page')}</S.MainTitle>
          <Button size="md" my={3} m={5} onClick={handleAccessMarket} onLoad>
            {t('fast_access.go_to_market')}
          </Button>
        </S.ContainerTitle>
      )}

      {renderLoadedContent}
      {!isEmptyState && (
        <S.HorizontalScroll>
          {appsDynamicModule.map((app, index) => {
            const { id, attributes, type } = app;

            const targetIdentifier = attributes?.slug ? attributes.slug : id;

            const renderModuleLogo = () => {
              if (attributes.logo) {
                return <S.Logo src={attributes.logo} />;
              } else {
                return (
                  <S.SquareIcon bgcolor={opacity(primaryColor, 0.1)}>
                    <svg
                      width={40}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 1024 1024"
                    >
                      <g fill={primaryColor}>
                        <path d={icons[attributes.icon]} />
                      </g>
                    </svg>
                  </S.SquareIcon>
                );
              }
            };

            return (
              <S.Container>
                <S.ContainerCard key={index}>
                  <S.Overlay ref={(el) => (overlayrRef.current[index] = el)}>
                    <Lottie
                      width={100}
                      height={130}
                      isStopped
                      options={{
                        loop: false,
                        autoplay: false,
                        isStopped: true,
                        animationData: explosionCofetti,
                        rendererSettings: {
                          preserveAspectRatio: 'xMidYMid slice',
                        },
                      }}
                      ref={(el) => (playerRef.current[index] = el)}
                    />
                  </S.Overlay>

                  <S.CardStyled
                    id={`card-${index}`}
                    key={index}
                    isHovered={hoveredIndex === index}
                    onMouseEnter={() => setHoveredIndex(index)}
                    onMouseLeave={() => setHoveredIndex(null)}
                    onClick={() =>
                      handlerApp({
                        slug: targetIdentifier,
                        index: index,
                        renderType: type,
                        name: attributes.name,
                      })
                    }
                  >
                    {renderModuleLogo()}
                    <S.CardStyledContent isHovered={hoveredIndex === index}>
                      <Text
                        variant="title-bold-16"
                        isBold
                        color="neutral.black"
                        textOverflow="ellipsis"
                        whiteSpace="nowrap"
                      >
                        {attributes.name}
                      </Text>
                      <S.releasedBy>
                        <S.IconTag
                          name={
                            attributes?.partner_name === 'Agenda Edu'
                              ? 'user-group'
                              : 'user'
                          }
                          partnerName={attributes?.partner_name}
                        />
                        <S.TextTag
                          m={0}
                          variant="body-medium-14"
                          partnerName={attributes?.partner_name}
                        >
                          {t('fast_access.reseled_by')}
                          <span>{attributes?.partner_name}</span>
                        </S.TextTag>
                      </S.releasedBy>
                    </S.CardStyledContent>
                  </S.CardStyled>
                </S.ContainerCard>
              </S.Container>
            );
          })}
        </S.HorizontalScroll>
      )}
    </>
  );
};

DynamicView.propTypes = {
  ...appPropTypes,
};

export default withAppContext(DynamicView);
