import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';

import * as S from './styles';

import ListSkeleton from './Skeleton';

import { DiaryState } from 'store/dailySummaries/types';
import {
  Box,
  DefaultThemeProps,
  Icon,
  Text,
} from '@agendaedu/ae-web-components';
import { useTranslation } from 'react-i18next';
import { IMAGES_CLASSROOMS_URL } from 'core/constants/index';
import EmptyState from 'components/EmptyState';
import { DiarySectionsFormContext } from 'core/contexts/DiaryForm/SectionsForm';
import { DiscardModal } from 'pages/dailySummaries/DiaryForm/DiscardModal';
import { SelectSectionDiscardModal } from '../../types';

const defaultIcons = {
  baby_bottle: 'baby-bottle',
  evacuation: 'toilet',
  sleep: 'snooze',
  diaper: 'diaper',
  shower: 'shower',
  observation: 'info-circle',
  occurrence: 'warning',
  meals: 'fork-and-spoon',
  custom: 'brush',
};

const SectionsList = () => {
  const { t } = useTranslation('diary');
  const { breakpoints, border, colors } = useTheme() as DefaultThemeProps;
  const {
    diarySections: { activesSections, isLoadingSections },
  } = useSelector((state: DiaryState) => state.dailySummaries);

  const [selectSectionDiscardModal, setSelectSectionDiscardModal] =
    useState<SelectSectionDiscardModal>({
      confirmAction: null,
    });

  const {
    currentSectionId,
    setCurrentSectionId,
    form: { dirty, resetForm },
  } = useContext(DiarySectionsFormContext);

  const changeCurrentSection = (id: string) => {
    if (id !== currentSectionId && dirty) {
      return setSelectSectionDiscardModal({
        confirmAction: () => {
          resetForm();
          setCurrentSectionId(id);
        },
      });
    }

    setCurrentSectionId(id);
  };

  const defaultSectionsSlugs = useMemo(
    () => [
      'meals',
      'baby_bottle',
      'sleep',
      'evacuation',
      'diaper',
      'shower',
      'occurrence',
      'observation',
    ],
    []
  );

  const ordainedSections = useMemo(() => {
    const defaultSections = defaultSectionsSlugs
      .map((slug) =>
        activesSections.find((activeSection) => activeSection.slug === slug)
      )
      .filter(Boolean);

    const customSections = activesSections.filter(
      (activeSection) => activeSection.custom
    );

    return [...defaultSections, ...customSections];
  }, [activesSections, defaultSectionsSlugs]);

  const listSections = ordainedSections.map((section, index) => {
    const { id, name, custom, slug } = section;

    const checked = custom
      ? id === currentSectionId
      : slug === currentSectionId;

    return (
      <Box
        key={id}
        data-testid="section-item"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
        height="56px"
        cursor="pointer"
        px="sm"
        borderTop={`${index > 0 ? border.width.sm : 0} ${border.style.solid} ${
          colors.neutral.gray4
        }`}
        backgroundColor={checked ? colors.brand.primary.op10 : 'transparent'}
        onClick={() => changeCurrentSection(custom ? id : slug)}
      >
        <Box display="flex" gap="sm" alignItems="center">
          <Icon
            color={
              checked ? colors.brand.primary.default : colors.neutral.gray1
            }
            name={defaultIcons[slug || 'custom']}
          />
          <Text
            variant="label-regular-14"
            title={name}
            mb={0}
            color={checked ? 'brand.primary.default' : 'neutral.gray1'}
          >
            {name}
          </Text>
        </Box>

        <S.Radio checked={checked} />
      </Box>
    );
  });

  useEffect(() => {
    if (ordainedSections.length) {
      const firstSection = ordainedSections[0];
      setCurrentSectionId(
        firstSection.custom ? firstSection.id : firstSection.slug
      );
    }
  }, []);

  return (
    <>
      <Box
        width="100%"
        minWidth={300}
        maxWidth={{ _: '100%', desktopLG: breakpoints.tabletMD }}
      >
        {isLoadingSections ? (
          <ListSkeleton />
        ) : (
          <>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              alignItems="center"
              border={`${border.width.sm} ${border.style.solid} ${colors.neutral.gray4}`}
              borderRadius={border.radius.md}
            >
              <Box
                width="100%"
                maxHeight={{ mobileXS: '35vh', tabletMD: '50vh' }}
                overflow="auto"
              >
                {listSections}
              </Box>
            </Box>

            {!ordainedSections.length && (
              <EmptyState
                imgUrl={IMAGES_CLASSROOMS_URL.emptyStateUrl}
                message={
                  <Text variant="body-bold-14">
                    {t('form.empty_section_list')}
                  </Text>
                }
              />
            )}
          </>
        )}
      </Box>

      <DiscardModal
        isOpen={!!selectSectionDiscardModal.confirmAction}
        onClose={() => setSelectSectionDiscardModal({ confirmAction: null })}
        onConfirmDiscard={() => {
          selectSectionDiscardModal.confirmAction();
          setSelectSectionDiscardModal({ confirmAction: null });
        }}
      />
    </>
  );
};

export default SectionsList;
