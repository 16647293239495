import styled, { css } from 'styled-components';
import Tooltip from 'components/Tooltip';

export const Wrapper = styled.div`
  .FormTextField .TextField input {
    height: 40px;
    border-radius: 6px;
  }

  .MaskedField input {
    height: 40px;
    border-radius: 6px;
  }

  .Field .Label span {
    margin-right: 2px;
  }
`;

export const CustomLabel = styled.div`
  display: flex;
  align-items: center;
`;

export const CustomTooltip = styled(Tooltip)`
  .tooltip-div {
    position: relative;
    width: 16px;
    height: 16px;
  }
`;

export const DoubleInputsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-basis: 100%;
  @media (max-width: 576px) {
    flex-direction: column;
  }
`;

export const StudentsSelectWrapper = styled.div`
  margin-bottom: 24px;
  > span {
    font-weight: 400;
    font-size: 14px;
    color: ${(props) => props.theme.black};
  }
`;

export const StudentKinshipWrapper = styled.div`
  max-width: 50%;
  @media (max-width: 576px) {
    max-width: 100%;
  }
`;

export const Field = styled.div`
  display: flex;
  margin-bottom: 24px;
  flex-direction: column;
  padding-right: 15px;
  padding-left: 15px;
  min-height: 1px;
  width: 100%;

  .Label {
    margin-bottom: 12px;
  }

  .TextField input {
    height: 40px;
    border-radius: 6px;
  }
`;

export const CheckerTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  h6 {
    margin-right: 2px;
    font-weight: 700;
    font-size: 14px;
    color: ${(props) => props.theme.black};
  }
`;

export const FormAlert = styled.div`
  ${({ theme: { colors, space, font } }) => css`
    display: block;
    padding: ${space.lg};
    margin-bottom: ${space.lg};
    font-family: ${font.family.brand};
    font-size: ${font.size.sm};
    font-weight: ${font.weight.bold};
    line-height: ${font.height.lg};
    color: ${colors.context.warning.default};
    background-color: ${colors.context.warning.op20};
    border-radius: 8px;
  `}
`;
