import React from 'react';

import ClassroomsList from 'components/ClassroomsList';

const RecipientsList = ({ attributes }) => {
  let recipientType = '';

  const recipients = () => {
    if (attributes?.classrooms) {
      recipientType = 'Turmas';
      return attributes?.classrooms.map(
        (classroom) => classroom.attributes.name
      );
    } else {
      recipientType = 'Alunos';
      return attributes?.student_profiles?.data.map(
        (student) => student.attributes.name
      );
    }
  };

  return (
    <ClassroomsList classroomsNames={recipients()} kindItem={recipientType} />
  );
};

export default RecipientsList;
