import validatePresence from 'core/lib/FormValidator/validators/presence';

export default [
  {
    attributeName: 'recipient_wallet_id',
    validator: validatePresence,
    message: 'Campo obrigatório',
  },
  {
    attributeName: 'name',
    validator: validatePresence,
    message: 'Campo obrigatório',
  },
  {
    attributeName: 'cpf',
    validator: validatePresence,
    message: 'Campo obrigatório',
  },
];
