import React from 'react';
import PropTypes from 'prop-types';

import ModalContainer from 'components/Modal/ModalContainer';
import ModalTitle from 'components/Modal/ModalTitle';
import ButtonRow from 'components/ButtonRow';

import * as S from './styles';

const ConfirmModal = ({ isOpen, toggleModal, confirmAction }) => {
  const ConfirmModalButtons = [
    {
      text: 'Cancelar',
      variation: 'secondary',
      onClick: () => toggleModal(false),
    },
    {
      text: 'Salvar',
      onClick: () => confirmAction(true),
    },
  ];

  return (
    <ModalContainer isOpen={isOpen} toggleModal={toggleModal} maxWidth="475px">
      <ModalTitle>Salvar as alterações</ModalTitle>

      <S.ModalText>
        As informações serão salvas e aplicadas para as turmas selecionadas. Tem
        certeza que deseja salvar?
      </S.ModalText>

      <ButtonRow buttons={ConfirmModalButtons} />
    </ModalContainer>
  );
};

ConfirmModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  confirmAction: PropTypes.func.isRequired,
};

export default ConfirmModal;
