import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as S from './styles';
import { Props } from './types';

import {
  Box,
  Card,
  RecipientTag,
  SubtleIconButton,
  Text,
} from '@agendaedu/ae-web-components';

import DailySummariesActions from 'store/dailySummaries/actions';
import { DiarySectionList, DiaryState } from 'store/dailySummaries/types';

import ToggleSwitch from 'components/Form/ToggleSwitch';
import { DeleteSectionModal } from '../DeleteSectionModal';
import { ShowSectionModal } from '../ShowSectionModal';

const SectionCard = ({ section, redirectToEdit }: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('diary_sections');
  const { changeVisibility, duplicateDiarySection } = DailySummariesActions;
  const [showCurrentSectionModal, setShowCurrentSectionModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isActiveSection, setIsActiveSection] = useState(
    section.attributes.enable
  );

  const {
    diarySections: { sectionsIncluded },
  } = useSelector((state: DiaryState) => state.dailySummaries);

  const handleChangeVisibility = () => {
    setIsActiveSection((prev) => !prev);

    dispatch(
      changeVisibility({
        id: section.id,
        enable: !isActiveSection,
        onFail: () => setIsActiveSection(section.attributes.enable),
      })
    );
  };

  const handleDuplicateSection = () =>
    dispatch(duplicateDiarySection({ diarySectionId: section.id }));

  const recipientTag = useMemo(() => {
    const recipients = section.relationships.classrooms.data.reduce(
      (prev, current) => {
        const relatedItem = sectionsIncluded.find(
          (include) => include.id === current.id && include.type === 'classroom'
        );

        return relatedItem ? [...prev, relatedItem.attributes] : prev;
      },
      []
    );

    if (!recipients?.length)
      return {
        type: t(`sections.list.without_recipients`),
        recipients: [{ name: t(`sections.list.without_recipients`) }],
      };

    return {
      type: t(`sections.list.classrooms`),
      recipients,
    };
  }, [section.relationships.classrooms.data, sectionsIncluded, t]);

  const userPermission = sectionsIncluded.find(
    (include) =>
      include.type === 'userPermission' &&
      include.id === section.relationships.userPermission.data.id
  ) as DiarySectionList['userPermission'];

  const actions = [
    {
      as: 'button',
      title: t('sections.list.show'),
      onClick: () => setShowCurrentSectionModal(true),
    },
    userPermission.attributes.canDuplicate && {
      as: 'button',
      title: t('sections.list.duplicate'),
      onClick: handleDuplicateSection,
    },
    userPermission.attributes.canUpdate && {
      as: 'button',
      title: t('sections.list.edit'),
      onClick: () => redirectToEdit(section.id),
    },
    userPermission.attributes.canDestroy && {
      as: 'button',
      title: t('sections.list.delete'),
      onClick: () => setShowDeleteModal(true),
    },
  ].filter(Boolean);

  return (
    <Card mt="sm" testId="section-card">
      <Box
        display="flex"
        flexWrap="wrap"
        alignItems="center"
        width="100%"
        justifyContent="space-between"
      >
        <Box display="flex" alignItems="center">
          <S.SwitchWrapper>
            <ToggleSwitch
              testId="toggle-section"
              toggled={isActiveSection}
              onChange={handleChangeVisibility}
            />
          </S.SwitchWrapper>

          <Text variant="title-bold-16" ml="sm" mb={0} color="neutral.black">
            {section.attributes.name}
          </Text>
        </Box>

        <Box
          display="flex"
          width={{ _: '100%', mobileSM: '35%' }}
          mt={{ _: 'sm', mobileSM: 0 }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Box display="flex" mr="sm" maxWidth="70%">
            <RecipientTag
              variant="neutral"
              recipientType={recipientTag.type}
              recipients={recipientTag.recipients}
              modalTitle={recipientTag.type}
              enableEllipsis
            />
          </Box>

          <Box display="flex" justifyContent="flex-end">
            <SubtleIconButton
              data-testid="actions-section"
              size="md"
              align="right"
              actions={actions}
            />
          </Box>
        </Box>
      </Box>

      {showCurrentSectionModal && (
        <ShowSectionModal
          section={section}
          isOpen={showCurrentSectionModal}
          onClose={() => setShowCurrentSectionModal(false)}
          onDelete={() => setShowDeleteModal(true)}
          onEdit={() => redirectToEdit(section.id)}
        />
      )}

      <DeleteSectionModal
        diarySectionId={section.id}
        isOpen={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
      />
    </Card>
  );
};

export default SectionCard;
