import styled, { css } from 'styled-components';
import { ThemeProps } from 'config/themes/types';
import { Text } from '@agendaedu/ae-web-components';
import { WrapperStyleProps } from './types';

const wrapperModifiers = {
  sent: ({ theme: { colors, border, space }, hasAction }) => css`
    grid-template-areas: '. message left' '. footer .';
    grid-template-columns: 1fr 70% 0fr;

    ${ActionContainer} {
      right: 50%;
    }

    ${BalloonLeftWrapper} {
      display: none;
    }

    ${Content} {
      display: none;
    }

    ${DeletedMessageWrapper} {
      svg {
        path {
          fill: ${colors.neutral.white};
        }
      }
    }

    ${MessageContainer} {
      grid-template-areas: 'actions content';
      grid-template-columns: ${space.sm} 1fr;
      background: ${colors.brand.primary.default};
      border-radius: ${border.radius.lg} ${border.radius.lg} 2px
        ${border.radius.lg};
      margin-left: auto;

      :hover {
        background-color: ${hasAction && colors.brand.primary.dark};
        cursor: ${hasAction && 'pointer'};
      }
    }

    ${ContentWrapper} {
      padding: ${space.xs} ${space.sm} ${space.xs} ${space.xs4};
    }

    ${AttachmentWrapper} {
      background-color: transparent;

      p {
        color: ${colors.neutral.white};
      }

      svg {
        path {
          fill: ${colors.neutral.white};
        }
      }
    }

    ${MessageContent} {
      color: ${colors.neutral.white};

      &::selection {
        background: ${colors.neutral.white};
        color: ${colors.brand.primary.default};
      }
    }

    ${BalloonFooterWrapper} {
      justify-content: flex-end;
    }
  `,
  received: ({ theme: { colors, border, space }, hasAction }) => css`
    grid-template-areas: 'left message .' '. footer .';
    grid-template-columns: 0fr 70% 1fr;

    ${ActionContainer} {
      left: 50%;
    }

    ${AttachedWrapper} {
      ::before {
        background-color: ${colors.neutral.gray3};
      }
    }

    ${MessageContainer} {
      grid-template-areas: 'content actions';
      grid-template-columns: 1fr ${border.radius.lg};
      background: ${colors.neutral.gray6};
      border-radius: ${border.radius.lg} ${border.radius.lg} ${border.radius.lg}
        2px;
      margin-right: auto;

      :hover {
        background-color: ${hasAction && colors.neutral.gray5};
        cursor: ${hasAction && 'pointer'};
      }

      a {
        color: ${colors.brand.primary.default};
        text-decoration: underline;

        &:hover {
          color: ${colors.brand.primary.dark};
        }
      }
    }

    ${ContentWrapper} {
      padding: ${space.xs} ${space.xs4} ${space.xs} ${space.sm};
    }

    ${AttachmentWrapper} {
      p {
        color: ${colors.brand.primary.default};
      }

      background-color: ${colors.brand.primary.op10};

      path {
        fill: ${colors.brand.primary.default};
      }
    }

    ${BalloonFooterWrapper} {
      justify-content: flex-start;
    }
  `,
};

export const BalloonLeftWrapper = styled.div`
  display: flex;
  grid-area: left;
  justify-content: flex-end;
  align-items: flex-end;
`;

export const Content = styled(Text)`
  ${({ theme: { colors, typography, space } }: ThemeProps) => css`
    ${typography.title.Bold12};
    color: ${colors.brand.primary.default};
    margin-bottom: ${space.xs2};
    line-height: 170% !important;
  `}
`;

export const MessageContent = styled.p`
  ${({ theme: { colors, typography, space } }: ThemeProps) => css`
    ${typography.body.Regular14};
    white-space: pre-line;
    word-break: break-word;
    color: ${colors.neutral.black};
    margin: ${space.xs4};
    line-height: 170% !important;

    a {
      color: ${colors.neutral.gray5};
      text-decoration: underline;

      &:hover {
        color: ${colors.neutral.gray6};
      }
    }
  `}
`;

export const BalloonFooterWrapper = styled.div`
  ${({ theme: { colors, typography, space } }: ThemeProps) => css`
    display: flex;
    grid-area: footer;
    ${typography.body.Regular12};
    color: ${colors.neutral.gray2};
    margin-bottom: ${space.xs4};
  `}
`;

export const ActionsWrapper = styled.div`
  display: flex;
  grid-area: actions;
  width: 100%;
  position: relative;
`;

export const ActionContainer = styled.section`
  ${({ theme: { space } }: ThemeProps) => css`
    display: none;
    position: absolute;
    flex-direction: row;
    gap: ${space.xs2};
    bottom: ${space.xs4};
  `}
`;

export const Wrapper = styled.div<WrapperStyleProps>`
  ${({ theme, status, showAction, hasAction }) => css`
    display: grid;
    width: 100%;
    padding: ${theme.space.xs4} ${theme.space.xs4} 22px ${theme.space.xs4};
    gap: ${theme.space.xs2};

    ${ActionContainer} {
      display: ${showAction ? 'flex' : 'none'};
    }

    ${wrapperModifiers[status]({ theme, hasAction })};

    &:not(:first-child) {
      margin: 22px ${theme.space.xs4} ${theme.space.xs4} ${theme.space.xs4};
    }
  `}
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-area: content;
`;

export const MessageContainer = styled.div`
  display: grid;
  grid-area: message;
  transition: background-color 0.3s ease;

  :hover {
    ${ActionContainer} {
      display: flex;
    }
  }
`;

export const AttachmentWrapper = styled.a`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    flex-direction: row;
    gap: ${space.xs};
    padding: ${space.xs};
    border-radius: 6px;
    align-items: center;
    cursor: pointer;
    text-decoration: none !important;
  `}
`;

export const DeletedMessageWrapper = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    flex-direction: row;
    gap: ${space.xs2};
    align-items: center;
  `}
`;

export const AttachmentImage = styled.img`
  ${({ theme: { border } }: ThemeProps) => css`
    width: 100%;
    max-width: 350px;
    border-radius: ${border.radius.md};
  `}
`;

export const AttachedWrapper = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    position: relative;
    flex-direction: column;
    gap: ${space.xs};
    padding: ${space.xs3};
    padding-left: ${space.xs};

    p {
      margin-bottom: 0;
    }

    position: relative;

    ::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      width: 3px;
      border-radius: ${space.xs3};
      height: 100%;
      background-color: white;
      transform: translateY(-50%);
    }
  `}
`;
