import styled from 'styled-components';

export const SearchContainer = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  padding: 0 14px 0 16px;
  background-color: white;
`;

export const SearchInput = styled.input`
  font-family: Roboto;
  font-size: 16px;
  width: 100%;
  border: none;
  font-size: 16px;
  color: black;
  padding: 0 8px;
`;
