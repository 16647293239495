import moment from 'moment';

import createModuleReducer from 'store/storeConfig/createModuleReducer';

import {
  MoodTrackersReducerProps,
  ResultsActionDefault,
  FetchAnswersActionType,
  FetchHeadquartersSuccessActionProps,
  FetchEducationalStagesSuccessActionProps,
  FetchClassroomsSuccessActionProps,
  FetchAnswersActionProps,
} from './types';
import actions from './actions';

export const INITIAL_STATE: MoodTrackersReducerProps = {
  isLoading: false,
  isSending: false,
  date: moment().format('YYYY-MM-DD'),
  headquarter: null,
  educationalStage: null,
  classroom: null,
  studentSearch: '',
  answerType: '',
  studentsList: [],
  answers: {},
  pagination: {
    page: 1,
    per_page: 10,
  },
  formMeta: {
    headquarters: [],
    educationalStages: [],
    classrooms: [],
    filterAmount: 0,
  },
  error: null,
};

export const fetchAnswers = (
  state: MoodTrackersReducerProps,
  action: FetchAnswersActionType
): MoodTrackersReducerProps => {
  const { headquarter, educationalStage, classroom, date, page } =
    action?.params;

  return {
    ...state,
    isSending: page === 1,
    answers: {},
    studentsList: page === 1 ? [] : state.studentsList,
    headquarter,
    educationalStage,
    classroom,
    date: date || state.date,
  };
};

export const setDate = (
  state: MoodTrackersReducerProps,
  action: FetchAnswersActionType
): MoodTrackersReducerProps => ({
  ...state,
  date: action.date,
  pagination: { ...state.pagination, page: 1 },
});

export const setAnswerType = (
  state: MoodTrackersReducerProps,
  action: FetchAnswersActionType
): MoodTrackersReducerProps => ({
  ...state,
  answerType: action.answerType,
  pagination: { ...state.pagination, page: 1 },
});

export const setFilters = (
  state: MoodTrackersReducerProps,
  { headquarter, educationalStage, classroom }: FetchAnswersActionProps
): MoodTrackersReducerProps => ({
  ...state,
  headquarter,
  educationalStage,
  classroom,
  pagination: { ...state.pagination, page: 1 },
});

export const setPage = (
  state: MoodTrackersReducerProps,
  action: FetchAnswersActionType
): MoodTrackersReducerProps => ({
  ...state,
  pagination: { ...state.pagination, page: action.page },
});

export const fetchAnswersSuccess = (
  state: MoodTrackersReducerProps,
  action
): MoodTrackersReducerProps => {
  const {
    filters: { headquarter, educationalStage, classroom, date, page },
    answers,
    studentsList,
  } = action;

  let newStudentsList;
  if (page === 1) {
    newStudentsList = [...studentsList];
  } else {
    newStudentsList = [...state.studentsList, ...studentsList];
  }

  const newFilterAmount = () => {
    const actionFilters = Object.values({
      headquarter,
      educationalStage,
      classroom,
    }).filter((value) => Boolean(value)).length;

    if (actionFilters > 0) return actionFilters;

    return 0;
  };

  return {
    ...state,
    isSending: false,
    answers,
    studentsList: newStudentsList,
    headquarter: headquarter || state.headquarter,
    educationalStage: educationalStage || state.educationalStage,
    classroom: classroom || state.classroom,
    date: date || state.date,
    formMeta: {
      ...state.formMeta,
      filterAmount: newFilterAmount(),
    },
  };
};

export const fetchHeadquarters = (
  state: MoodTrackersReducerProps
): MoodTrackersReducerProps => ({
  ...state,
  isLoading: true,
  formMeta: {
    ...state.formMeta,
    headquarters: [],
  },
});

export const fetchHeadquartersSuccess = (
  state: MoodTrackersReducerProps,
  action: FetchHeadquartersSuccessActionProps
): MoodTrackersReducerProps => {
  const formattedHeadquarters = [{ value: null, label: 'Selecione a unidade' }];

  action.headquarters.forEach(({ id, attributes: { name } }) => {
    formattedHeadquarters.push({
      value: id,
      label: name,
    });
  });

  return {
    ...state,
    isLoading: false,
    formMeta: {
      ...state.formMeta,
      headquarters: formattedHeadquarters,
    },
  };
};

export const fetchEducationalStages = (
  state: MoodTrackersReducerProps
): MoodTrackersReducerProps => ({
  ...state,
  isLoading: true,
  formMeta: {
    ...state.formMeta,
    educationalStages: [],
  },
});

export const fetchEducationalStagesSuccess = (
  state: MoodTrackersReducerProps,
  action: FetchEducationalStagesSuccessActionProps
): MoodTrackersReducerProps => {
  const formattedEducationalStages = [
    { value: null, label: 'Selecione o segmento' },
  ];

  action.educationalStages.forEach(({ id, attributes: { name } }) => {
    formattedEducationalStages.push({
      value: id,
      label: name,
    });
  });

  return {
    ...state,
    isLoading: false,
    formMeta: {
      ...state.formMeta,
      educationalStages: formattedEducationalStages,
    },
  };
};

export const fetchClassrooms = (
  state: MoodTrackersReducerProps
): MoodTrackersReducerProps => ({
  ...state,
  isLoading: true,
  formMeta: {
    ...state.formMeta,
    classrooms: [],
  },
});

export const fetchClassroomsSuccess = (
  state: MoodTrackersReducerProps,
  action: FetchClassroomsSuccessActionProps
): MoodTrackersReducerProps => {
  const formattedClassrooms = [{ value: null, label: 'Selecione a turma' }];

  action.classrooms.forEach(({ id, attributes: { name } }) => {
    formattedClassrooms.push({
      value: id,
      label: name,
    });
  });

  return {
    ...state,
    isLoading: false,
    formMeta: {
      ...state.formMeta,
      classrooms: formattedClassrooms,
    },
  };
};

export const handleError = (
  state: MoodTrackersReducerProps,
  action: ResultsActionDefault
): MoodTrackersReducerProps => ({
  ...state,
  error: action.error,
  isLoading: false,
  isSending: false,
});

const HANDLERS = {
  [actions.ERROR]: handleError,
  [actions.FETCH_ANSWERS]: fetchAnswers,
  [actions.SET_DATE]: setDate,
  [actions.SET_ANSWER_TYPE]: setAnswerType,
  [actions.SET_FILTERS]: setFilters,
  [actions.SET_PAGE]: setPage,
  [actions.FETCH_ANSWERS_SUCCESS]: fetchAnswersSuccess,
  [actions.FETCH_HEADQUARTERS]: fetchHeadquarters,
  [actions.FETCH_HEADQUARTERS_SUCCESS]: fetchHeadquartersSuccess,
  [actions.FETCH_EDUCATIONAL_STAGES]: fetchEducationalStages,
  [actions.FETCH_EDUCATIONAL_STAGES_SUCCESS]: fetchEducationalStagesSuccess,
  [actions.FETCH_CLASSROOMS]: fetchClassrooms,
  [actions.FETCH_CLASSROOMS_SUCCESS]: fetchClassroomsSuccess,
} as const;

const moodTrackers = createModuleReducer(INITIAL_STATE, HANDLERS);

export default moodTrackers;
