import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { DateRangePicker } from 'react-dates';
import AgendaIcon from 'components/AgendaIcon';
import FormValidationErrors from 'components/Form/ValidationErrors';

import withFormContext, { formPropTypes } from 'core/hoc/withFormContext';

import './style.scss';

const DISPLAY_FORMAT = 'DD/MM/YYYY';

class RangeDateField extends Component {
  constructor(props) {
    super(props);

    this.state = {
      startDate: moment('05/28/2018'),
      endDate: moment('06/10/2018'),
      focusedInput: null,
    };
  }

  shouldUsePortal() {
    if (typeof window === 'undefined') return true;
    return window.matchMedia('(max-width: 720px)').matches;
  }

  onChange = (date) => {
    const { attributeName, formContext } = this.props;
    const { changeAttribute } = formContext;

    date = Object.keys(date).reduce((previous, current) => {
      previous[current] = date[current]
        ? date[current].format('YYYY-MM-DD')
        : null;
      return previous;
    }, {});

    const fakeEvent = {
      target: {
        value: date,
      },
    };

    changeAttribute(attributeName)(fakeEvent);

    const formState = formContext.form;
    formState[attributeName] = date;

    this.props.onSubmit(formState);
  };

  render() {
    const {
      attributeName,
      enableSameDate,
      formContext,
      isOutsideRange,
      showClearDates,
      disabled,
    } = this.props;

    const { form, hasErrorOnAttribute } = formContext;

    const hasError = hasErrorOnAttribute(attributeName);

    const value = form[attributeName]
      ? {
          startDate: form[attributeName].startDate
            ? moment(form[attributeName].startDate)
            : null,
          endDate: form[attributeName].endDate
            ? moment(form[attributeName].endDate)
            : null,
        }
      : null;

    return (
      <div className="RangeDateField">
        <DateRangePicker
          startDate={value && value.startDate}
          startDateId="inicio"
          startDatePlaceholderText="Data inicial"
          endDate={value && value.endDate}
          endDateId="termino"
          endDatePlaceholderText="Data final"
          onDatesChange={this.onChange}
          focusedInput={this.state.focusedInput}
          onFocusChange={(focusedInput) => this.setState({ focusedInput })}
          showClearDates={showClearDates}
          customArrowIcon={<span>até</span>}
          customInputIcon={<AgendaIcon name="calendar" size="small" />}
          numberOfMonths={this.shouldUsePortal() ? 1 : 2}
          withPortal={this.shouldUsePortal()}
          displayFormat={DISPLAY_FORMAT}
          isOutsideRange={(day) => isOutsideRange(day)}
          hideKeyboardShortcutsPanel
          minimumNights={enableSameDate ? 0 : 1}
          readOnly
          disabled={disabled}
        />
        {hasError && <FormValidationErrors attributeName={attributeName} />}
      </div>
    );
  }
}

RangeDateField.propTypes = {
  attributeName: PropTypes.string.isRequired,
  enableSameDate: PropTypes.bool,
  onSubmit: PropTypes.func,
  isOutsideRange: PropTypes.func,
  showClearDates: PropTypes.bool,
  hasErrorOnAttribute: PropTypes.func,
  disabled: PropTypes.string,
  ...formPropTypes,
};

RangeDateField.defaultProps = {
  enableSameDate: false,
  isOutsideRange: (_day) => {},
  showClearDates: true,
};

export default withFormContext(RangeDateField);
