import styled, { css } from 'styled-components';

import ModalContainer from 'components/Modal/ModalContainer';

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const GroupDetailModal = styled(ModalContainer)`
  display: flex;
  flex-direction: column;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20px;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const DetailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const ButtonWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    > .Button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      border-radius: 6px;
      padding: 12px 20px;
      font-family: 'Quicksand';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 100%;
      color: ${theme.white};
    }
  `}
`;

export const Label = styled.strong`
  ${({ theme }) => css`
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 100%;
    color: ${theme.gray1};
    margin-bottom: 6px;
  `}
`;

export const Info = styled.div`
  ${({ theme }) => css`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 170%;
    color: ${theme.black};
  `}
`;
