import validatePresence from 'core/lib/FormValidator/validators/presence';
import { validateHttpsUrl } from 'core/helper/httpsUrlValidator.ts';

function validateLink(input, state) {
  return validateUrl(state.link);
}

export default [
  {
    attributeName: 'image',
    validator: validatePresence,
    message: 'Campo obrigatório',
  },
  {
    attributeName: 'name',
    validator: validatePresence,
    message: 'Campo obrigatório',
  },
  {
    attributeName: 'link',
    validator: validateHttpsUrl,
    message: 'URL inválida. Insira uma URL HTTPS.',
  },
  {
    attributeName: 'link',
    validator: validatePresence,
    message: 'Campo obrigatório',
  },
];
