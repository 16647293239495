import validatePresence from 'core/lib/FormValidator/validators/presence';
import validateLength from 'core/lib/FormValidator/validators/length';
import validateTextAreaPresence from 'core/lib/FormValidator/validators/textAreaPresence';

function validatePageBookPresence(pageBook, formState) {
  if (formState.taskhome === '0') return true;

  return validateTextAreaPresence(pageBook);
}

export default [
  {
    attributeName: 'date',
    validator: validatePresence,
    message: 'Selecione uma data',
  },
  {
    attributeName: 'title',
    validator: validatePresence,
    message: 'Campo obrigatório',
  },
  {
    attributeName: 'title',
    validator: validateLength({ max: 100 }),
    message: 'Insira um título de até 100 caracteres',
  },
  {
    attributeName: 'description',
    validator: validateTextAreaPresence,
    message: 'Campo obrigatório',
  },
  {
    attributeName: 'discipline_id',
    validator: validatePresence,
    message: 'Selecione uma disciplina',
  },
  {
    attributeName: 'page_book',
    validator: validatePageBookPresence,
    message: 'Campo obrigatório',
  },
];
