import React, { Component } from 'react';
import { toast } from 'react-toastify';

import ToastContext from 'core/contexts/Toast';
import getHocName from 'core/utils/getHocName';

import Toast from 'components/Toast';

const variations = {
  default: 'toast-default',
  success: 'toast-success',
  error: 'toast-error',
  info: 'toast-info',
};

const progressVariations = {
  default: 'toast-progress-default',
  success: 'toast-progress-success',
  error: 'toast-progress-error',
  info: 'toast-progress-info',
};

export const toastCss = (variation) => {
  return {
    className: variations[variation],
    bodyClassName: 'toast-white-text',
    progressClassName: progressVariations[variation],
  };
};

const toastContainerProvider = (WrappedComponent) => {
  class ToastContainerProvider extends Component {
    actions = {
      showToast: this.showToast,
    };
    showToast(message, variation) {
      toast(message, toastCss(variation ? variation : 'default'));
    }
    render() {
      return (
        <ToastContext.Provider value={this.actions}>
          <WrappedComponent {...this.props} />
          <Toast pauseOnVisibilityChange={false} pauseOnHover={false} />
        </ToastContext.Provider>
      );
    }
  }
  ToastContainerProvider.displayName = getHocName(
    'ToastContainerProvider',
    WrappedComponent
  );
  return ToastContainerProvider;
};
export default toastContainerProvider;
