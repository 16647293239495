import styled, { css } from 'styled-components';
import Button from 'components/Button';
import AgendaIcon from 'components/AgendaIcon';
import { ThemeProps } from 'config/themes/types';

export const DailySummariesContainer = styled.section`
  ${({ theme: { breakpoints, space } }: ThemeProps) => css`
    width: 100%;
    height: 100%;
    padding: ${space.xl2} ${space.xl5};

    @media (max-width: ${breakpoints.desktopXL}) {
      padding: ${space.xs2};
    }
  `}
`;

export const DailySummariesTitle = styled.h1`
  ${({ theme: { colors, typography } }: ThemeProps) => css`
    ${typography.title.Bold20}
    colors: ${colors.neutral.black}
  `}
`;

export const SearchContainer = styled.div`
  width: 33%;
`;

export const SettingsButton = styled(Button)`
  ${({ theme: { breakpoints, space, border } }: ThemeProps) => css`
    margin-right: ${space.sm};
    border-radius: ${border.radius.md};
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: space-around;
    height: 40px;
    width: 40px;

    @media (max-width: ${breakpoints.desktopLG}) {
      margin-right: ${space.xl};
    }
  `}
`;

export const IconButtons = styled.div`
  ${({ theme: { breakpoints } }: ThemeProps) => css`
    display: flex;
    justify-content: space-between;
    margin-left: auto;

    @media (max-width: ${breakpoints.tabletMD}) {
      height: fit-content;
      margin-top: 10px;
    }
    @media (max-width: ${breakpoints.mobileXS}) {
      margin-left: initial;
    }
  `}
`;

export const SettingsIcon = styled(AgendaIcon)`
  line-height: 0;
`;

export const ActionsBtnWrapper = styled.div`
  ${({ theme: { breakpoints, space } }: ThemeProps) => css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: ${space.xl};
    gap: ${space.sm};

    @media (max-width: ${breakpoints.mobileSM}) {
      flex-direction: column;
    }
  `}
`;

export const SectionWrapper = styled.div`
  ${({ theme: { breakpoints, space } }: ThemeProps) => css`
    display: flex;
    flex-direction: row;
    gap: ${space.sm};

    > div {
      margin: 0;
      padding: 0;
    }

    div.search-filter {
      margin: 0;
      padding: 0;
      min-width: 300px !important;

      @media (max-width: ${breakpoints.tabletMD}) {
        min-width: 200px !important;
      }

      @media (max-width: ${breakpoints.mobileSM}) {
        min-width: 100% !important;
      }
    }

    @media (max-width: 660px) {
      flex-direction: column;
    }
  `}
`;

export const BtnActionWrapper = styled.div`
  ${({ theme: { breakpoints, space } }: ThemeProps) => css`
    display: flex;
    flex-direction: row;
    gap: ${space.sm};

    div {
      margin: 0;
      padding: 0;
    }

    @media (max-width: ${breakpoints.mobileSM}) {
      flex-direction: row;
      align-self: flex-end;
    }
  `}
`;

export const BtnWrapper = styled.div`
  ${({ theme: { breakpoints, space } }: ThemeProps) => css`
    display: flex;
    flex-direction: row;
    gap: ${space.sm};
  `}
`;
