import React, { useState } from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';

import FormContainer from 'components/Form/FormContainer';
import ModalButtons from 'components/Modal/ModalButtons';
import ModalContainer from 'components/Modal/ModalContainer';
import WizardContainer from 'components/WizardForm/WizardContainer';

import './style.scss';

const FormFullScreen = ({
  action,
  form,
  formMeta,
  steps,
  onSubmit,
  onRemove,
  onSubmitAndNewRegister,
  isSubmitting,
  backTo,
  backToExternal,
  titlePage,
  titleModal,
  contentModal,
  history,
  children,
  toggleModalWithFormUpdates,
  finishText,
  finishAndNewText,
  id,
  openCancelModal,
}) => {
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const closeModal = () => setIsConfirmModalOpen(false);

  const handleBackTo = () => {
    if (backToExternal) {
      document.location.href = backTo;
    } else {
      history.push(backTo);
    }
  };

  const toggleModal = ({ hasUpdatesOnForm }) => {
    if (toggleModalWithFormUpdates) {
      const formUpdates = hasUpdatesOnForm();

      !formUpdates
        ? handleBackTo()
        : setIsConfirmModalOpen(!isConfirmModalOpen);
    } else {
      setIsConfirmModalOpen(!isConfirmModalOpen);
    }
  };

  const toDiscard = () => {
    setIsConfirmModalOpen(false);
    handleBackTo();
  };

  const confirmModalButtons = () => [
    {
      text: 'Cancelar',
      variation: 'secondary',
      onClick: closeModal,
      path: '#',
    },
    {
      text: 'Descartar',
      variation: 'danger',
      onClick: toDiscard,
      path: '#',
    },
  ];

  return (
    <div className="FormFullScreen" id={id}>
      <FormContainer
        action={action}
        initialAttributes={form}
        formMeta={formMeta}
      >
        <WizardContainer
          steps={steps}
          onSubmit={onSubmit}
          onRemove={onRemove}
          onSubmitAndNewRegister={onSubmitAndNewRegister}
          isSubmitting={isSubmitting}
          backTo={backTo}
          titlePage={titlePage}
          toggleModal={openCancelModal ? toggleModal : toDiscard}
          finishText={finishText}
          finishAndNewText={finishAndNewText}
        />

        {!!children && children}

        {openCancelModal && (
          <ModalContainer
            isOpen={isConfirmModalOpen}
            toggleModal={closeModal}
            title={titleModal}
            maxWidth="490px"
          >
            <p className="sub-title-modal">{contentModal}</p>

            <div className="button-container-modal">
              <ModalButtons buttons={confirmModalButtons()} />
            </div>
          </ModalContainer>
        )}
      </FormContainer>
    </div>
  );
};

FormFullScreen.defaultProps = {
  isSubmitting: false,
  openCancelModal: true,
};

FormFullScreen.propTypes = {
  action: PropTypes.oneOf(['new', 'edit']).isRequired,
  form: PropTypes.object.isRequired,
  formMeta: PropTypes.object.isRequired,
  steps: PropTypes.arrayOf(PropTypes.func).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onRemove: PropTypes.func,
  onSubmitAndNewRegister: PropTypes.func,
  isSubmitting: PropTypes.bool,
  backTo: PropTypes.string,
  titlePage: PropTypes.string,
  titleModal: PropTypes.string.isRequired,
  contentModal: PropTypes.string.isRequired,
  history: PropTypes.object,
  children: PropTypes.node,
  backToExternal: PropTypes.bool,
  toggleModalWithFormUpdates: PropTypes.bool,
  finishText: PropTypes.string,
  finishAndNewText: PropTypes.string,
  id: PropTypes.string,
  openCancelModal: PropTypes.bool,
};

export default withRouter(FormFullScreen);
