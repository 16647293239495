import React from 'react';
import moment from 'moment';
import { NegotiationProps } from './types';
import { RIGHT_CENTER } from 'components/Tooltip';
import { useDispatch } from 'react-redux';
import withFormContext from 'core/hoc/withFormContext';
import actionRecurrentBills from 'store/edupay/recurrentBills/actions';

import Alert from 'components/Alert';
import AgendaIcon from 'components/AgendaIcon';
import Buttons from '../Buttons';
import Field from 'components/Form/Field';

import * as S from './style';

function NegotiationPayment({ formContext, toggleModal }: NegotiationProps) {
  const { form, updateAttribute } = formContext;
  const dispatch = useDispatch();
  const { negotiationBillRequest } = actionRecurrentBills;

  const { attributes, id } = form.bill;
  const { negotiateDate, negotiateNote, kind } = form;

  const content = (
    <p>
      A data de vencimento será a mesma
      <br />
      independente do método de pagamento.
    </p>
  );

  const handleManuallyNegotiation = (): void => {
    dispatch(
      negotiationBillRequest({
        planId: attributes.recurrent_plan_id,
        billId: id,
        date: negotiateDate,
        note: negotiateNote,
        kind,
      })
    );
    toggleModal();
  };

  const createdAtOutsideRange = (day: string) => {
    const createdAt = moment(form.bill?.attributes?.created_at);
    return day <= createdAt;
  };

  return (
    <>
      <S.InformationAlert>
        <Alert variation="info">
          A baixa manual por negociação representa apenas uma mudança de status
          da cobrança. Após a alteração, o responsável não poderá efetuar o
          pagamento. Saiba mais sobre a baixa por negociação{' '}
          <a
            href="https://suporte.agendaedu.com/hc/pt-br/articles/10618790167067"
            target="_blank"
            rel="noreferrer"
          >
            clicando aqui
          </a>
          .
        </Alert>
      </S.InformationAlert>
      <S.DateField>
        <S.LabelRow>
          <S.Label>Data*</S.Label>
          <S.CustomTooltip content={content} position={RIGHT_CENTER}>
            <AgendaIcon name="help" size="small" />
          </S.CustomTooltip>
        </S.LabelRow>
        <Field
          placeholder="DD/MM/AAAA"
          attributeName="negotiateDate"
          type="date"
          showAllDays
          onChange={(value: string) => updateAttribute('negotiateDate', value)}
          isOutsideRange={(day) => createdAtOutsideRange(day)}
        />
      </S.DateField>
      <S.NoteField>
        <Field
          label="Observações (opcional)"
          type="simpleTextArea"
          attributeName="negotiateNote"
          onChange={(e) => updateAttribute('negotiateNote', e.negotiateNote)}
          maxLength="255"
        />
      </S.NoteField>
      <S.CounterWrapper>
        <S.LabelCounter>{form?.negotiateNote?.length || 0}/255</S.LabelCounter>
      </S.CounterWrapper>
      <S.WantToNegotiateField>
        <S.Label>
          Digite <strong>QUERO NEGOCIAR</strong> no campo abaixo
        </S.Label>
        <Field
          attributeName="negotiateTerm"
          onChange={(e) => {
            updateAttribute('negotiateTerm', e.negotiateTerm);
          }}
        />
      </S.WantToNegotiateField>
      <Buttons
        title={'Negociar'}
        isDisable={
          !form?.negotiateDate || form?.negotiateTerm !== 'QUERO NEGOCIAR'
        }
        variation={'primary'}
        toggleModal={toggleModal}
        manuallySubmit={handleManuallyNegotiation}
      />
    </>
  );
}

export default withFormContext(NegotiationPayment);
