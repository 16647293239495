const normalizeParams = (values, policies, sourceRequest) => {
  const { can_use_legacy_id: canUseLegacyId, has_edugo: hasEdugo } = policies;

  const data = new FormData();

  data.append('student_profile[name]', values.name);

  values.period
    ? data.append('student_profile[period]', values.period)
    : data.append('student_profile[period]', '');

  values.date_of_birth
    ? data.append('student_profile[date_of_birth]', values.date_of_birth)
    : data.append('student_profile[date_of_birth]', '');

  if (values.classroom_ids.length) {
    values.classroom_ids.forEach((classroom) => {
      data.append('student_profile[classroom_ids][]', classroom);
    });
  }

  if (canUseLegacyId) {
    values.legacy_id
      ? data.append('student_profile[legacy_id]', values.legacy_id)
      : data.append('student_profile[legacy_id]', '');
  }

  values.ra
    ? data.append('student_profile[ra]', values.ra)
    : data.append('student_profile[ra]', '');

  values.main_classroom_id && hasEdugo
    ? data.append(
        'student_profile[main_classroom_id]',
        values.main_classroom_id
      )
    : data.append('student_profile[main_classroom_id]', '');

  values.email
    ? data.append('student_profile[email]', values.email.toLowerCase())
    : data.append('student_profile[email]', '');

  values.username
    ? data.append('student_profile[username]', values.username.toLowerCase())
    : data.append('student_profile[username]', '');

  values.avatar && typeof values.avatar !== 'string'
    ? data.append('student_profile[avatar]', values.avatar)
    : data.append('student_profile[avatar]', '');

  if (values.task_context) {
    data.append('student_profile[task_context]', values.task_context);
  }

  if (sourceRequest == 'review') {
    data.append('source_request', sourceRequest);
  }

  data.append('student_profile[financial]', values.financial);

  if (values.document_number)
    data.append('student_profile[document_number]', values.document_number);

  if (values.cep) data.append('student_profile[cep]', values.cep);

  if (values.phone) data.append('student_profile[phone]', values.phone);

  if (values.street) data.append('student_profile[street]', values.street);

  if (values.city) data.append('student_profile[city]', values.city);

  if (values.street_number)
    data.append('student_profile[street_number]', values.street_number);

  if (values.complement)
    data.append('student_profile[complement]', values.complement);

  if (values.neighborhood)
    data.append('student_profile[neighborhood]', values.neighborhood);

  if (values.state) data.append('student_profile[state]', values.state);

  return data;
};

export default normalizeParams;
