import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';

import { Text } from '@agendaedu/ae-web-components';
import { WalletsState } from 'components/Payments/WalletInformation/types';
import Loader from 'components/Loader';
import EmptyState from 'components/EmptyState';
import HistoryActionCard from './HistoryActionCard';

import walletsActions from 'store/edupay/wallets/actions';
import { IMAGES_PAYMENT_URL } from 'core/constants/index';
import withFormContext from 'core/hoc/withFormContext';

import * as S from './styles';

const SchoolWalletHistory = () => {
  const { t } = useTranslation(['payments', 'common']);
  const dispatch = useDispatch();

  const { fetchHistoryRequest } = walletsActions;
  const { isLoadingHistory, walletHistory, currentWallet } = useSelector(
    (state: WalletsState) => state.wallets
  );

  const page = 1;
  const [currentPage, setCurrentPage] = useState(1);

  const hasMoreActions = () => {
    const numberOfItemsOnTheList =
      currentPage * walletHistory?.itemsCountPerPage;
    return numberOfItemsOnTheList < walletHistory?.totalItemsCount;
  };

  const fetchMoreActions = () => {
    if (isLoadingHistory) return;
    setCurrentPage((oldCurrentPage) => oldCurrentPage + 1);
  };

  const renderHistoryList = () => {
    if (!isLoadingHistory && !walletHistory)
      return (
        <EmptyState
          imgUrl={IMAGES_PAYMENT_URL.availabilityUrl}
          message={parse(t('wallet.tab_history.empty_state_message'))}
        />
      );
    return (
      <S.WrapperCardList
        pageStart={page}
        hasMore={!isLoadingHistory && hasMoreActions()}
        loadMore={fetchMoreActions}
        loader={<Loader key="history-list-loader" />}
        initialLoad={true}
      >
        {walletHistory?.data?.map((action) => (
          <HistoryActionCard action={action} key={action.id} />
        ))}
      </S.WrapperCardList>
    );
  };

  useEffect(() => {
    if (currentWallet && currentWallet.type != 'securitizer')
      dispatch(fetchHistoryRequest(page, currentWallet.attributes.id));
  }, [fetchHistoryRequest, dispatch, currentWallet]);

  return (
    <S.SchoolWalletHistoryWrapper>
      <Loader isLoading={isLoadingHistory}>
        <S.WrapperNumberEntries>
          <Text
            variant="subtitle-medium-14"
            lineHeight="md"
            mb={0}
            color={'neutral.gray2'}
          >
            {`${walletHistory?.totalItemsCount} ${t(
              'wallet.tab_history.actions'
            )}`}
          </Text>
        </S.WrapperNumberEntries>

        {renderHistoryList()}
      </Loader>
    </S.SchoolWalletHistoryWrapper>
  );
};

export default withFormContext(SchoolWalletHistory);
