import styled, { css } from 'styled-components';

export const ModalText = styled.label`
  ${({ theme }) => css`
    color: ${theme.gray1};
    font-weight: 400;
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    margin-top: 24px;
  `}
`;
