import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

class Label extends React.Component {
  UNSAFE_componentWillMount() {
    delete this.props.className;
  }

  render() {
    const { fromBootstrap } = this.props;

    return (
      <label className="Label control-label" {...this.props}>
        {this.props.children}
      </label>
    );
  }
}

export default Label;
