import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';

import * as S from './styles';

import StudentListSkeleton from './Skeleton';

import { Props } from './types';
import { DiaryState } from 'store/dailySummaries/types';
import {
  Box,
  DefaultThemeProps,
  Text,
  Button,
  Avatar,
} from '@agendaedu/ae-web-components';
import { useTranslation } from 'react-i18next';
import { IMAGES_CLASSROOMS_URL } from 'core/constants/index';
import EmptyState from 'components/EmptyState';

const StudentsList = ({
  studentsIdsSelecteds,
  setStudentsIdsSelecteds,
  students,
  isCheckable,
  placeholder,
  multiSelect,
  size = 'md',
}: Props) => {
  const { t } = useTranslation('diary');
  const { breakpoints, border, colors } = useTheme() as DefaultThemeProps;
  const { isLoading } = useSelector(
    (state: DiaryState) => state.dailySummaries
  );
  const [search, setSearch] = useState('');

  const heightSize = {
    sm: { mobileXS: '20vh', tabletMD: '35vh' },
    md: { mobileXS: '35vh', tabletMD: '50vh' },
  }[size];

  const getStudents = search
    ? students.filter((student) =>
        student.attributes.name.toLowerCase().includes(search.toLowerCase())
      )
    : students;

  const haveStudents = !!getStudents.length;

  const onChange = (id: string) => {
    if (!multiSelect) return setStudentsIdsSelecteds([id]);
    if (studentsIdsSelecteds.includes(id)) {
      setStudentsIdsSelecteds(
        studentsIdsSelecteds.filter((studentId) => studentId !== id)
      );
    } else {
      setStudentsIdsSelecteds([...studentsIdsSelecteds, id]);
    }
  };

  const handleSelectAllStudents = () => {
    if (studentsIdsSelecteds.length === students.length) {
      setStudentsIdsSelecteds([]);
    } else {
      const allStudents = getStudents.map((student) => student.id);
      setStudentsIdsSelecteds(allStudents);
    }
  };

  const header = isCheckable && (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      mb="lg"
    >
      <Text variant="subtitle-medium-12" mb={0}>
        {t('form.students', {
          selected: studentsIdsSelecteds.length,
          count: getStudents.length,
        })}
      </Text>

      <Button
        variant="secondary"
        aria-label={t(
          studentsIdsSelecteds.length === students.length
            ? 'form.unselect_all'
            : 'form.select_all'
        )}
        size="sm"
        onClick={handleSelectAllStudents}
      >
        {t(
          studentsIdsSelecteds.length === students.length
            ? 'form.unselect_all'
            : 'form.select_all'
        )}
      </Button>
    </Box>
  );

  const listStudents = getStudents.map((student) => {
    const {
      id,
      attributes: {
        name,
        photo_url,
        avatar_color,
        name_initials,
        should_use_name_initials_avatar,
        last_updated,
      },
    } = student;

    const checked = studentsIdsSelecteds.includes(id);
    const paddingBoxY = {
      sm: 'xs2',
      md: 'xs',
    }[size];

    return (
      <Box
        key={id}
        width="100%"
        alignItems="center"
        borderTop={`${border.width.sm} ${border.style.solid} ${colors.neutral.gray4}`}
        px="sm"
        py={paddingBoxY}
        backgroundColor={checked ? colors.brand.primary.op10 : 'transparent'}
      >
        <Box display="flex" flexDirection="row" cursor="pointer">
          <Box
            data-testid="student-item"
            display="flex"
            alignItems="center"
            width="100%"
            height="48px"
            onClick={() => onChange(id)}
          >
            <Box>
              <Avatar
                avatarColor={avatar_color}
                avatarUrl={!should_use_name_initials_avatar && photo_url}
                nameInitials={name_initials}
              />
            </Box>
            <Box width="100%">
              <Text
                variant="label-regular-14"
                title={name}
                mb={0}
                ml="sm"
                mr={isCheckable ? 'sm' : 0}
                color={checked ? 'brand.primary.default' : 'neutral.gray1'}
              >
                {name}
              </Text>
              {!!last_updated && (
                <Text
                  variant="label-regular-12"
                  title={last_updated}
                  mb={0}
                  mt="xs3"
                  ml="sm"
                  color={'neutral.gray1'}
                >
                  {last_updated}
                </Text>
              )}
            </Box>
          </Box>
          {isCheckable && (
            <S.Checkbox checked={checked} onChange={() => onChange(id)} />
          )}
        </Box>
      </Box>
    );
  });

  return (
    <Box
      width="100%"
      minWidth={300}
      maxWidth={{ _: '100%', desktopLG: breakpoints.tabletMD }}
    >
      {isLoading ? (
        <StudentListSkeleton />
      ) : (
        <>
          {haveStudents && header}

          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            alignItems="center"
            border={`${border.width.sm} ${border.style.solid} ${colors.neutral.gray4}`}
            borderRadius={border.radius.md}
          >
            <S.Search
              value={search}
              onChange={(event) => setSearch(event.target.value)}
              placeholder={placeholder}
              fullWidth
              icon="search"
              haveStudents={haveStudents}
            />

            {haveStudents && (
              <Box width="100%" maxHeight={heightSize} overflow="auto">
                {listStudents}
              </Box>
            )}
          </Box>

          {!haveStudents && (
            <EmptyState
              imgUrl={IMAGES_CLASSROOMS_URL.emptyStateUrl}
              message={
                <Text variant="body-bold-14">
                  {t('form.empty_student_list')}
                </Text>
              }
            />
          )}
        </>
      )}
    </Box>
  );
};

export default StudentsList;
