import moment from 'moment';
import createModuleReducer from '../../storeConfig/createModuleReducer';
import actions from './actions';
import { canEditRecurrentBill } from 'core/utils/recurrentBill';

export const INITIAL_STATE = {
  recurrentPlans: [],
  recipients: [],
  studentProfiles: [],
  selectedRecurrent: [],
  selectedAmount: 0,
  wasSelectedAllRecurrent: false,
  hasBatchSelection: false,
  recipientsWallet: [],
  recurrentIdsElegibleToEdit: [],
  recurrentInBatchInformations: [],
  totalIdsElegibleToEdit: 0,
  wasSelectedAllIdsElegibleToEdit: false,
  balance: {
    billet_paid: 0,
    credit_card_paid: 0,
    manually_paid: 0,
    pix_paid: 0,
    pending: 0,
    delayed: 0,
    released: 0,
  },
  hasRecurrentPlans: true,
  paginate: {},
  filters: {
    range: {
      startDate: moment().startOf('month').format('YYYY-MM-DD'),
      endDate: moment().endOf('month').format('YYYY-MM-DD'),
    },
    headquarter_id: null,
    educational_stage_id: null,
    classroom_id: null,
    recurrent_title_or_student_name: null,
    status: null,
    recurrent_product_ids: [],
    page: 1,
  },
  activeFiltersCount: 0,
  versions: [],
  paginateVersion: {},
  isLoading: false,
  isLoadingPlan: true,
  isLoadingWallet: false,
  isLoadingBatchs: false,
  modalOpen: false,
  cancelModal: false,
  delayModal: false,
  current: undefined,
  currentBill: undefined,
  currentBillPlan: undefined,
  resendEmails: {
    ids: [],
    count: 0,
    resendEmailsEnabled: false,
  },
  sending: false,
  editing: false,
  error: null,
  isLoadingManualDeal: false,
  manualDealModal: false,
  isLoadingResendBill: false,
};

export const fetchRequest = (state) => ({
  ...state,
  isLoading: true,
});

export const fetchSuccess = (state, action) => {
  let amount = 0;
  const sideFilter = [
    action.filters.headquarter_id,
    action.filters.educational_stage_id,
    action.filters.classroom_id,
    action.filters.recurrent_title_or_student_name,
    action.filters.status,
    action.filters.recurrent_product_ids,
  ];

  sideFilter.forEach((filter) => {
    if (filter && filter.length > 0) amount += 1;
  });

  return {
    recurrentPlans: action.data,
    hasRecurrentPlans: action.hasRecurrentPlans,
    balance: action.balance,
    paginate: action.paginate,
    filters: action.filters,
    activeFiltersCount: amount,
    isLoading: false,
    selectedRecurrent: [],
    selectedAmount: 0,
    hasBatchSelection: false,
    recipientsWallet: [],
    wasSelectedAllIdsElegibleToEdit: false,
    manualDealModal: false,
    resendEmails: {
      ids: [],
      count: 0,
      resendEmailsEnabled: false,
    },
  };
};

export const addRecurrentPlan = (state) => ({
  ...state,
  sending: true,
});

export const addRecurrentPlanSuccess = (state) => ({
  ...state,
  sending: false,
});

export const editRecurrentPlan = (state) => ({
  ...state,
  editing: true,
});

export const editRecurrentPlanSuccess = (state, action) => {
  const {
    attributes,
    attributes: {
      current_bill: { data },
    },
  } = action.data;

  const bill = {
    ...data,
    recurrentPlan: attributes,
    studentProfile: attributes.student_profile.data,
  };

  return {
    ...state,
    current: bill,
    editing: false,
  };
};

export const setPlanRequest = (state) => ({
  ...state,
  current: undefined,
  isLoadingPlan: true,
});

export const setPlanRequestSuccess = (state, action) => {
  const {
    attributes,
    attributes: {
      current_bill: { data },
    },
  } = action.data;

  const bill = {
    ...data,
    recurrentPlan: attributes,
    studentProfile: attributes.student_profile.data,
  };

  return {
    ...state,
    current: bill,
    isLoadingPlan: false,
  };
};

export const setBillPlanSuccess = (state, action) => {
  const bill = state.recurrentPlans.find((bill) => bill.id === action.data.id);
  const {
    data: {
      attributes: {
        order: { data },
      },
    },
  } = action;

  const currentBillPlan = {
    ...action.data,
    studentProfile: bill.attributes.student_profile.data,
    recurrentPlan: bill.attributes,
    order: data,
  };

  return {
    ...state,
    currentBillPlan,
    current: currentBillPlan,
    isLoadingPlan: false,
  };
};

export const setCurrentBill = (state, action) => {
  const bill = state.recurrentPlans.find((bill) => bill.id === action.id);

  return {
    ...state,
    currentBill: bill,
  };
};

export const fetchRecipients = (state) => ({
  ...state,
  isLoading: true,
});

export const fetchRecipientsSuccess = (state, action) => ({
  ...state,
  recipients: action.data,
  isLoading: false,
});

export const fetchStudentsSuccess = (state, action) => ({
  ...state,
  studentProfiles: action.data,
});

export const manuallyPaySuccess = (state, action) => {
  const index = state.recurrentPlans.findIndex((bill) => bill.id === action.id);

  const recurrentPlans = [
    ...state.recurrentPlans.slice(0, index),
    {
      ...state.recurrentPlans[index],
      attributes: {
        ...state.recurrentPlans[index].attributes,
        status: 'manually_paid',
      },
    },
    ...state.recurrentPlans.slice(index + 1),
  ];

  return {
    ...state,
    recurrentPlans,
  };
};

export const manuallyPayLDSuccess = (state, action) => {
  const index = state.recurrentPlans.findIndex((bill) => bill.id === action.id);

  const recurrentPlans = [
    ...state.recurrentPlans.slice(0, index),
    {
      ...state.recurrentPlans[index],
      attributes: {
        ...state.recurrentPlans[index].attributes,
        status: 'manually_paid',
      },
    },
    ...state.recurrentPlans.slice(index + 1),
  ];

  return {
    ...state,
    recurrentPlans,
  };
};

export const checkoutSuccess = (state, action) => {
  const index = state.recurrentPlans.findIndex((bill) => bill.id === action.id);

  const recurrentPlans = [
    ...state.recurrentPlans.slice(0, index),
    {
      ...state.recurrentPlans[index],
      attributes: {
        ...state.recurrentPlans[index].attributes,
        status: action.data.data.attributes.status,
        order: action.data,
      },
    },
    ...state.recurrentPlans.slice(index + 1),
  ];

  return {
    ...state,
    recurrentPlans,
    currentBillPlan: {
      ...state.currentBillPlan,
      order: action.data.data,
    },
  };
};

export const cancelBillSuccess = (state, action) => {
  const index = state.recurrentPlans.findIndex((bill) => bill.id === action.id);

  const recurrentPlans = [
    ...state.recurrentPlans.slice(0, index),
    {
      ...state.recurrentPlans[index],
      attributes: {
        ...state.recurrentPlans[index].attributes,
        status: 'canceled',
      },
    },
    ...state.recurrentPlans.slice(index + 1),
  ];

  return {
    ...state,
    recurrentPlans,
    currentBill: undefined,
  };
};

export const setVersionsRequest = (state) => ({
  ...state,
  isLoading: true,
});

export const setVersionsSuccess = (state, action) => ({
  ...state,
  versions: action.history,
  paginateVersion: action.paginate,
  isLoading: false,
});

export const cancelPlanSuccess = (state, action) => {
  const { attributes } = action.data;

  return {
    ...state,
    current: {
      ...state.current,
      recurrentPlan: attributes,
    },
  };
};

export const delayBillSuccess = (state, action) => {
  const index = state.recurrentPlans.findIndex((bill) => bill.id === action.id);

  const recurrentPlans = [
    ...state.recurrentPlans.slice(0, index),
    {
      ...state.recurrentPlans[index],
      attributes: {
        ...state.recurrentPlans[index].attributes,
        status: 'delayed',
      },
    },
    ...state.recurrentPlans.slice(index + 1),
  ];

  return {
    ...state,
    recurrentPlans,
    currentBill: undefined,
  };
};

export const toggleModal = (state) => ({
  ...state,
  modalOpen: !state.modalOpen,
  currentBillPlan: undefined,
});

export const toggleCancelModal = (state) => ({
  ...state,
  cancelModal: !state.cancelModal,
});

export const toggleManualDealModal = (state) => ({
  ...state,
  manualDealModal: !state.manualDealModal,
  currentBillPlan: undefined,
});

export const toggleDelayModal = (state) => ({
  ...state,
  delayModal: !state.delayModal,
});

export const handleError = (state, action) => ({
  ...state,
  sending: false,
  editing: false,
  isLoadingBatchs: false,
  error: action.error,
});

export const setSelectRecurrent = (state, action) => {
  let newSelectedRecurrent = state.selectedRecurrent;

  const existInArray = newSelectedRecurrent.indexOf(action.id);

  !newSelectedRecurrent.includes(action.id)
    ? newSelectedRecurrent.push(action.id)
    : newSelectedRecurrent.splice(existInArray, 1);

  const amount = newSelectedRecurrent.length;

  return {
    ...state,
    selectedRecurrent: newSelectedRecurrent,
    selectedAmount: amount,
    hasBatchSelection: !!amount,
  };
};

export const setSelectAllRecurrentRequest = (state) => ({
  ...state,
  isLoadingBatchs: true,
});

export const setSelectAllRecurrentSuccess = (state, action) => ({
  ...state,
  recurrentIdsElegibleToEdit: action.recurrentBillIds,
  totalIdsElegibleToEdit: action.totalIdsElegibleToEdit,
  isLoadingBatchs: false,
});

export const toggleSelectAllRecurrent = (state) => {
  let newSelectedRecurrent = state.selectedRecurrent;
  let currentWasSelectedAllIdsElegibleToEdit =
    state.wasSelectedAllIdsElegibleToEdit;

  if (!state.wasSelectedAllRecurrent) {
    state.recurrentPlans.map((current) => {
      const wasNotSelected = !state.selectedRecurrent.includes(current.id);

      canEditRecurrentBill(
        current.attributes.status,
        current.attributes.is_wallet_fidc,
        current.attributes.is_wallet_guaranteed_monthly_fee,
        true
      ) &&
        wasNotSelected &&
        newSelectedRecurrent.push(current.id);
    });
  } else {
    newSelectedRecurrent = [];
    currentWasSelectedAllIdsElegibleToEdit = false;
  }

  const amount = newSelectedRecurrent.length;

  return {
    ...state,
    selectedRecurrent: newSelectedRecurrent,
    selectedAmount: amount,
    hasBatchSelection: !!amount,
    wasSelectedAllRecurrent: !!amount,
    wasSelectedAllIdsElegibleToEdit: currentWasSelectedAllIdsElegibleToEdit,
  };
};

export const fetchRecurrentInBatchExtraInformations = (state) => ({
  ...state,
  isLoading: true,
});

export const fetchRecurrentInBatchExtraInformationsSuccess = (
  state,
  action
) => ({
  ...state,
  recipientsWallet: action.recipientsWallet,
  recurrentInBatchInformations: action.recurrentInBatchInformations,
  isLoading: false,
});

export const editRecurrentBillInBatches = (state) => ({
  ...state,
  isLoadingBatchs: true,
});

export const editRecurrentBillInBatchesSuccess = (state) => ({
  ...state,
  isLoadingBatchs: false,
});

export const setAllIdsElegibleToEdit = (state) => {
  let newSelectedRecurrent = [];

  if (!state.wasSelectedAllIdsElegibleToEdit) {
    newSelectedRecurrent = state.recurrentIdsElegibleToEdit;
  }

  const amount = newSelectedRecurrent.length;

  return {
    ...state,
    selectedRecurrent: newSelectedRecurrent,
    selectedAmount: amount,
    hasBatchSelection: !!amount,
    wasSelectedAllRecurrent: !!amount,
    wasSelectedAllIdsElegibleToEdit: !state.wasSelectedAllIdsElegibleToEdit,
  };
};

const negotiationPlanRequest = (state) => ({
  ...state,
  isLoadingManualDeal: true,
});

const negotiationPlanSuccess = (state, action) => {
  const index = state.recurrentPlans.findIndex((bill) => bill.id === action.id);

  const recurrentPlans = [
    ...state.recurrentPlans.slice(0, index),
    {
      ...state.recurrentPlans[index],
      attributes: {
        ...state.recurrentPlans[index].attributes,
        status: 'negotiated',
      },
    },
    ...state.recurrentPlans.slice(index + 1),
  ];

  return {
    ...state,
    recurrentPlans,
    isLoadingManualDeal: false,
  };
};

const fetchResendBillInformation = (state) => ({
  ...state,
  isLoadingResendBill: true,
});

const fetchResendBillInformationSuccess = (state, action) => ({
  ...state,
  resendEmails: {
    ids: action.ids,
    count: action.count,
    resendEmailsEnabled: action.resendEmailsEnabled,
  },
  isLoadingResendBill: false,
});

const resendBillingRequest = (state) => ({
  ...state,
  isLoadingResendBill: true,
});

const resendBillingSuccess = (state) => ({
  ...state,
  isLoadingResendBill: false,
});

const clearResendBillingInfo = (state) => ({
  ...state,
  resendEmails: {
    ids: [],
    count: 0,
    resendEmailsEnabled: false,
  },
});

const HANDLERS = {
  [actions.ADD_RECURRENT_PLAN]: addRecurrentPlan,
  [actions.ADD_RECURRENT_PLAN_SUCCESS]: addRecurrentPlanSuccess,
  [actions.CANCEL_PLAN_SUCCESS]: cancelPlanSuccess,
  [actions.CANCEL_BILL_SUCCESS]: cancelBillSuccess,
  [actions.CANCEL_BILL_LD_SUCCESS]: cancelBillSuccess,
  [actions.DELAY_BILL_SUCCESS]: delayBillSuccess,
  [actions.EDIT_RECURRENT_PLAN]: editRecurrentPlan,
  [actions.EDIT_RECURRENT_PLAN_SUCCESS]: editRecurrentPlanSuccess,
  [actions.ERROR]: handleError,
  [actions.FETCH_STUDENTS_SUCCESS]: fetchStudentsSuccess,
  [actions.FETCH_RECIPIENTS]: fetchRecipients,
  [actions.FETCH_RECIPIENTS_SUCCESS]: fetchRecipientsSuccess,
  [actions.FETCH_REQUEST]: fetchRequest,
  [actions.FETCH_SUCCESS]: fetchSuccess,
  [actions.CHECKOUT_SUCCESS]: checkoutSuccess,
  [actions.MANUALLY_PAY_SUCCESS]: manuallyPaySuccess,
  [actions.MANUALLY_PAY_LD_SUCCESS]: manuallyPayLDSuccess,
  [actions.SET_BILL_PLAN_SUCCESS]: setBillPlanSuccess,
  [actions.SET_CURRENT_BILL]: setCurrentBill,
  [actions.SET_PLAN_REQUEST]: setPlanRequest,
  [actions.SET_PLAN_SUCCESS]: setPlanRequestSuccess,
  [actions.SET_VERSIONS_REQUEST]: setVersionsRequest,
  [actions.SET_VERSIONS_SUCCESS]: setVersionsSuccess,
  [actions.TOGGLE_MODAL]: toggleModal,
  [actions.TOGGLE_CANCEL_MODAL]: toggleCancelModal,
  [actions.TOGGLE_DELAY_MODAL]: toggleDelayModal,
  [actions.SET_SELECT_RECURRENT]: setSelectRecurrent,
  [actions.TOGGLE_SELECT_ALL_RECURRENT]: toggleSelectAllRecurrent,
  [actions.SET_SELECT_ALL_RECURRENT_REQUEST]: setSelectAllRecurrentRequest,
  [actions.SET_SELECT_ALL_RECURRENT_SUCCESS]: setSelectAllRecurrentSuccess,
  [actions.FETCH_IN_BATCH_EXTRA_INFORMATION]:
    fetchRecurrentInBatchExtraInformations,
  [actions.FETCH_IN_BATCH_EXTRA_INFORMATION_SUCCESS]:
    fetchRecurrentInBatchExtraInformationsSuccess,
  [actions.EDIT_RECURRENT_BILL_IN_BATCHES]: editRecurrentBillInBatches,
  [actions.EDIT_RECURRENT_BILL_IN_BATCHES_SUCCESS]:
    editRecurrentBillInBatchesSuccess,
  [actions.SET_ALL_IDS_ELEGIBLE_TO_EDIT]: setAllIdsElegibleToEdit,
  [actions.NEGOTIATION_PLAN_REQUEST]: negotiationPlanRequest,
  [actions.NEGOTIATION_PLAN_SUCCESS]: negotiationPlanSuccess,
  [actions.TOGGLE_MANUAL_DEAL_MODAL]: toggleManualDealModal,
  [actions.FETCH_RESEND_BILLS_INFORMATION]: fetchResendBillInformation,
  [actions.FETCH_RESEND_BILLS_INFORMATION_SUCCESS]:
    fetchResendBillInformationSuccess,
  [actions.RESEND_BILLING_REQUEST]: resendBillingRequest,
  [actions.RESEND_BILLING_SUCCESS]: resendBillingSuccess,
  [actions.CLEAR_RESEND_BILLING_INFO]: clearResendBillingInfo,
};

const recurrentPlans = createModuleReducer(INITIAL_STATE, HANDLERS);

export default recurrentPlans;
