import React from 'react';

import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { OmniChannelState } from 'store/messages/omniChannel/types';

import { Avatar, Text } from '@agendaedu/ae-web-components';

import * as S from './styles';

const TransferCard = (): JSX.Element => {
  const { t } = useTranslation('messages');

  const { activeTicket, activeChat } = useSelector(
    (state: OmniChannelState) => state.omniChannel
  );

  const {
    avatar_color: avatarColor,
    avatar_url: avatarUrl,
    name,
    name_initials: nameInitials,
  } = activeChat.included.attributes;

  return (
    <S.Wrapper>
      <S.LeftSideBar />

      <S.TicketDetailsWraper>
        <S.AvatarWrapper>
          <Avatar
            data-testID="avatar"
            avatarColor={avatarColor}
            size="xs"
            nameInitials={nameInitials}
            avatarUrl={avatarUrl}
          />
          <S.TicketUserDetailsWrapper>
            <Text variant="body-medium-16" color={'neutral.black'}>
              {name}
            </Text>
            <Text variant="body-medium-14" color={'neutral.gray2'}>
              {t('omni_channel.modals.transfer_modal.ticket_id_label')} #
              {activeTicket?.id}
            </Text>
          </S.TicketUserDetailsWrapper>
        </S.AvatarWrapper>

        <Text variant="body-regular-14" color={'neutral.gray1'}>
          {activeTicket.attributes.description}
        </Text>
      </S.TicketDetailsWraper>
    </S.Wrapper>
  );
};

export default TransferCard;
