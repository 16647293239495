import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Field from 'components/Form/Field';
import DropdownButton from 'components/DropdownButton';
import Currency from 'components/Currency';

import './style.scss';

const RecurrentItem = ({
  removeItem,
  itemValues,
  index,
  onChange,
  recurrentProducts,
  disabled = false,
}) => {
  const [showDiscount, setShowDiscount] = useState(
    itemValues.discount_value !== 0
  );

  recurrentProducts.unshift({ value: null, label: 'Selecione uma opção' });

  const totalDiscount = () => {
    const { discount_kind, discount_value, price } = itemValues;

    if (discount_kind === 'percent') {
      return price * (discount_value / 100);
    }

    return parseFloat(discount_value);
  };

  const removeDiscount = () => {
    setShowDiscount(false);

    let newValues = itemValues;
    newValues.discount_value = 0;
    newValues.discount_kind = 'percent';

    onChange(index, newValues);
  };

  const updateValues = (attributeName, form) => {
    let newValues = itemValues;

    if (attributeName === 'price') {
      newValues[attributeName] = form[0][attributeName];
    } else if (attributeName === 'discount_kind') {
      newValues[attributeName] = form[attributeName];
      newValues.discount_value = 0;
    } else if (attributeName === 'discount_value') {
      newValues[attributeName] = form[attributeName];
    } else {
      newValues[attributeName] = form;
    }

    onChange(index, newValues);
  };

  const actionItems = () => {
    let actions = [
      {
        text: 'Remover item',
        onClick: () => removeItem(index),
      },
    ];

    if (showDiscount) {
      actions.push({
        text: 'Remover desconto',
        onClick: () => removeDiscount(),
      });
    } else {
      actions.push({
        text: 'Adicionar desconto',
        onClick: () => setShowDiscount(true),
      });
    }

    return actions;
  };

  return (
    <div className="RecurrentItem">
      <div className="recurrent-item-category">
        {itemValues && itemValues.status === 'inactive' ? (
          <div className="recurrent-item-category">
            <div className="Field col-xs-12 col-md-12 vertical">
              <p className="product-inactive">{itemValues.product_name}</p>
            </div>
          </div>
        ) : (
          <Field
            withArrowSelect
            type="selectWithIcon"
            attributeName="recurrent_product_id"
            label="Produto"
            controlled
            fullWidth
            options={recurrentProducts}
            value={String(itemValues.recurrent_product_id)}
            onChange={(formState) =>
              updateValues('recurrent_product_id', formState.value)
            }
            disabled={disabled}
          />
        )}
      </div>
      <div className="recurrent-item-actions">
        <Field
          attributeName="price"
          type="currency"
          label="Valor"
          controlled
          defaultValue={itemValues.price}
          onChange={(formState) => updateValues('price', formState)}
          disabled={disabled}
        />
        {!disabled && (
          <DropdownButton
            dropdownItems={actionItems()}
            icon="dots-vertical"
            variation="secondary white"
          />
        )}
      </div>
      {showDiscount && (
        <div className="recurrent-item-discount">
          <Field
            type="textWithSelect"
            variation="discount"
            attributeName="discount_value"
            selectAttributeName="discount_kind"
            label="Valor do desconto"
            controlled
            onChange={(formState) => updateValues('discount_value', formState)}
            onChangeSelect={(formState) =>
              updateValues('discount_kind', formState)
            }
            defaultValue={itemValues.discount_value}
            value={itemValues.discount_value}
            defaultSelectValue={itemValues.discount_kind}
            withIcon={false}
            orientation="horizontal"
            disabled={disabled}
          />
          <div className="discount-value">
            - <Currency value={totalDiscount()} />
          </div>
        </div>
      )}
    </div>
  );
};

RecurrentItem.propTypes = {
  removeItem: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  itemValues: PropTypes.shape({
    price: PropTypes.number.isRequired,
    discount_value: PropTypes.number.isRequired,
    discount_kind: PropTypes.string.isRequired,
    recurrent_product_id: PropTypes.number.isRequired,
  }).isRequired,
  index: PropTypes.number.isRequired,
  recurrentProducts: PropTypes.func,
  disabled: PropTypes.bool,
};

export default RecurrentItem;
