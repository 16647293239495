import React from 'react';
import PropTypes from 'prop-types';

import FormContainer from 'components/Form/FormContainer';
import WizardContainer from 'components/WizardForm/WizardContainer';

// Tabs
import EventFormInformationsTab from './InformationsTab';
import EventFormRecipientsTab from './RecipientsTab';

const WIZARD_STEPS = [EventFormInformationsTab, EventFormRecipientsTab];

const EventForm = ({ action, onSubmit, form, formMeta, isSubmitting }) => (
  <div className="EventForm">
    <FormContainer action={action} initialAttributes={form} formMeta={formMeta}>
      <WizardContainer
        steps={WIZARD_STEPS}
        onSubmit={onSubmit}
        isSubmitting={isSubmitting}
      />
    </FormContainer>
  </div>
);

EventForm.defaultProps = {
  isSubmitting: false,
};

EventForm.propTypes = {
  action: PropTypes.oneOf(['new', 'edit']).isRequired,
  onSubmit: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  formMeta: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool,
};

export default EventForm;
