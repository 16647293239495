import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  lazy,
  Suspense,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import tabifyForWizard from 'core/hoc/tabifyForWizard';
import withFormContext, { formPropTypes } from 'core/hoc/withFormContext';

import FormFieldset from 'components/Form/Fieldset';
import FormValidationErrors from 'components/Form/ValidationErrors';
import Loader from 'components/Loader';
import Field from 'components/Form/Field';
import SelectDestinationWithCheckbox from 'components/Messages/SelectDestination/WithCheckbox';

import actionEnrollmentPlans from 'store/edupay/enrollmentPlans/actions';

import './style.scss';
import * as S from './styles';
import {
  Avatar,
  Tag,
  FlexGrid,
  FlexGridItem,
  Tooltip,
  Icon,
} from '@agendaedu/ae-web-components';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

const EnrollmentPlanFormRecipientsTab = ({
  formContext: {
    hasErrorOnAttribute,
    changeMeta,
    updateAttribute,
    form,
    formMeta,
    action,
  },
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['enrollments']);

  const {
    selectedClassroom,
    selectedStudent,
    selectedRecipients,
    responsible_ids,
    formType,
  } = form;
  const { classrooms, status } = formMeta;
  const invalidField = hasErrorOnAttribute('selectedRecipients');
  const [isLoadingResponsibles, setIsLoadingResponsibles] = useState(false);
  const [disabledEdit, setDisabledEdit] = useState(false);

  const { studentsProfile, recipients, isLoadingStudentsResponsibles } =
    useSelector((state) => state.enrollmentPlans);

  const {
    fetchStudentsRequest,
    fetchRecipientsRequest,
    fetchStudentsResponsiblesRequest,
  } = actionEnrollmentPlans;

  const isEditing = action === 'edit';

  const fetchStudentsProfile = useCallback(
    (classroomId) => {
      dispatch(fetchStudentsRequest(classroomId));
    },
    [dispatch, fetchStudentsRequest]
  );

  const fetchStudentsResponsibles = useCallback(
    (classroomId) => {
      dispatch(fetchStudentsResponsiblesRequest(classroomId));
    },
    [dispatch, fetchStudentsRequest]
  );

  const fetchRecipientsProfile = useCallback(
    (studentId) => {
      dispatch(fetchRecipientsRequest(studentId));
    },
    [dispatch, fetchRecipientsRequest]
  );

  useEffect(() => {
    if (formType === 'single') {
      selectedRecipients.length > 0 && selectedStudent && selectedClassroom
        ? changeMeta('nextStepDisabled', false)
        : changeMeta('nextStepDisabled', true);
    }
  }, [selectedRecipients, selectedStudent, selectedClassroom, changeMeta]);

  useEffect(() => {
    if (formType === 'mass') {
      responsible_ids?.length
        ? changeMeta('nextStepDisabled', false)
        : changeMeta('nextStepDisabled', true);
    }
  }, [changeMeta, responsible_ids, studentsProfile, formType]);

  useEffect(() => {
    if (formType === 'single') {
      if (isEditing && status !== 'pending') setDisabledEdit(true);
      if (isEditing) checkOnEdit();
    }
  }, []);

  useEffect(() => {
    if (formType === 'mass' && selectedClassroom) {
      fetchStudentsResponsibles(selectedClassroom);
    }
  }, [selectedClassroom, formType, fetchStudentsResponsibles]);

  const checkOnEdit = () => {
    let newSelectedsRecipients = [...recipients];

    newSelectedsRecipients.forEach((recipient) => {
      selectedRecipients.forEach((selected) => {
        if (selected === recipient.id) recipient.checked = true;
      });
    });

    newSelectedsRecipients = newSelectedsRecipients.filter(
      (selected) => selected.checked === true
    );

    updateAttribute('selectedRecipients', newSelectedsRecipients);
  };

  const onSelectRecipient = (recipient) => {
    let newRecipients = [...recipients];
    let newSelecteds = selectedRecipients;

    const selectedRecipientsIndex = newRecipients.findIndex(
      (r) => r.id === recipient.id
    );

    newRecipients[selectedRecipientsIndex].checked =
      !newRecipients[selectedRecipientsIndex].checked;

    newSelecteds.push(newRecipients[selectedRecipientsIndex]);
    newSelecteds = newSelecteds.filter((selected) => selected.checked === true);

    updateAttribute('selectedRecipients', newSelecteds);
    updateAttribute('recipients', newRecipients);
  };

  const updateSelectorColumns = (selectedValues, selectedGroups) => {
    if (selectedGroups) {
      const selectedGroupsWithIds = selectedGroups?.map((group) => {
        const filteredId = studentsProfile
          .filter((student) => student.label === group.label)
          .map((filteredStudent) => filteredStudent.id)[0];

        return {
          id: filteredId,
          ...group,
        };
      });

      const selectedStudentResponsibleIds = selectedGroupsWithIds.map(
        (student) => {
          return {
            [student.id]: student.options.map((option) => option.id),
          };
        }
      );
      updateAttribute(
        'selectedStudentResponsibleIds',
        selectedStudentResponsibleIds
      );
    }

    updateAttribute('responsible_ids', selectedValues);
  };

  const getGroupSelectorData = () => {
    if (selectedStudent) {
      return studentsProfile.filter(
        (student) => student.id === selectedStudent
      );
    }
    return studentsProfile;
  };

  const GroupSelector = useMemo(
    () => lazy(() => import('components/Form/GroupSelector')),
    [studentsProfile, isLoadingStudentsResponsibles]
  );

  const optionComponent = (option) => {
    return (
      <S.OptionComponentContainer>
        <S.AvatarNameContainer>
          <Avatar
            nameInitials={option?.name}
            avatarColor={option?.avatar_color}
          />
          <S.LabelRegular14 id="responsible-name">
            {option?.name}
          </S.LabelRegular14>
        </S.AvatarNameContainer>
        <Tag
          id="responsible-role"
          name={option?.role}
          variant="neutral"
          size="small"
        />
      </S.OptionComponentContainer>
    );
  };

  const groupComponent = (optionGroup) => {
    return (
      <span disabled className="opt-group">
        <S.GroupContainer>
          <S.TitleBold12>{optionGroup?.label}</S.TitleBold12>
          <Tooltip
            align="top"
            content={<>{parse(t('tooltips.group_selector'))}</>}
            elementRef={<Icon color="#333" name="help-circle" size="sm" />}
          />
        </S.GroupContainer>
      </span>
    );
  };

  const handleClassroomSingleChange = (value) => {
    updateAttribute('selectedClassroom', value);
    updateAttribute('selectedStudent', null);
    updateAttribute('selectedRecipients', []);
    fetchStudentsProfile(value);
  };

  const handleStudentSingleChange = (value, label) => {
    updateAttribute('selectedStudent', value);
    updateAttribute('selectedRecipients', []);
    fetchRecipientsProfile(value);
    updateAttribute('title', label);
  };
  const handleClassroomMassChange = (value) => {
    updateAttribute('selectedClassroom', value);
    updateAttribute('responsible_ids', null);
    updateAttribute('selectedStudentResponsibleIds', null);
    fetchStudentsResponsibles(value);
  };

  return (
    <div className={`EnrollmentPlanFormRecipientsTab type-${formType}`}>
      <FormFieldset>
        {formType === 'single' ? (
          <div className="single-form-container">
            <S.TitleBold20>{t('form.enrollment.select_student')}</S.TitleBold20>
            <p>{t('form.enrollment.info_single_contract')}</p>
            <div className="RecipientsContainer">
              <Field
                withArrowSelect
                isSearchable
                fullWidth="520px"
                className="RecipientClassrooms"
                attributeName="selectedClassroom"
                label={t('form.enrollment.field.classroom')}
                type="selectWithIcon"
                placeholder={t('form.enrollment.field.select_classroom')}
                hasDisabledPlaceholder={disabledEdit}
                value={Number(selectedClassroom)}
                options={classrooms}
                disabled={disabledEdit}
                onChange={(event) => {
                  handleClassroomSingleChange(event.value);
                }}
              />
              <Field
                withArrowSelect
                isSearchable
                fullWidth="520px"
                className="RecipientStudents"
                attributeName="selectedStudent"
                label={t('form.enrollment.field.student')}
                type="selectWithIcon"
                placeholder={t('form.enrollment.field.select_student')}
                hasDisabledPlaceholder={disabledEdit}
                value={selectedStudent}
                options={studentsProfile}
                disabled={studentsProfile.length < 2 || disabledEdit}
                onChange={(event) => {
                  handleStudentSingleChange(event.value, event.label);
                }}
              />
            </div>
            {selectedStudent && (
              <div className="RecipientDestination">
                <p>{t('form.enrollment.select_recipients')}</p>
                <Loader isLoading={isLoadingResponsibles} variation="centered">
                  {recipients.map((recipient) => (
                    <div
                      className={`${
                        recipient.checked ? 'CheckedRecipient' : ''
                      } ${recipients.length === 1 ? 'OneRecipient' : ''}`}
                      key={`${recipient.kind}-${recipient.type}-${recipient.id}`}
                    >
                      <SelectDestinationWithCheckbox
                        user={recipient}
                        kind={recipient.kind}
                        checked={recipient.checked}
                        disabled={recipient.disabled || disabledEdit}
                        onRecipientSelect={() => onSelectRecipient(recipient)}
                      />
                    </div>
                  ))}
                  {invalidField && (
                    <FormValidationErrors attributeName="selectedRecipients" />
                  )}
                </Loader>
              </div>
            )}
          </div>
        ) : (
          <div className="mass-form-container">
            <div>
              <S.MassFormContainer>
                <S.Row>
                  <div>
                    <S.TitleBold20>
                      {t('form.enrollment.recipient_mass_title')}
                    </S.TitleBold20>
                    <S.InfoText>
                      {t('form.enrollment.info_mass_contract')}
                    </S.InfoText>
                  </div>
                </S.Row>
                <S.Row>
                  <Field
                    withArrowSelect
                    isSearchable
                    className="RecipientClassrooms"
                    attributeName="selectedClassroom"
                    label={t('form.enrollment.field.classroom')}
                    type="selectWithIcon"
                    placeholder={t('form.enrollment.field.select_classroom')}
                    fullWidth
                    hasDisabledPlaceholder={disabledEdit}
                    value={Number(selectedClassroom)}
                    options={classrooms}
                    disabled={isEditing}
                    onChange={(event) => {
                      handleClassroomMassChange(event.value);
                    }}
                  />
                </S.Row>
                <S.Row>
                  <Suspense fallback={null}>
                    <GroupSelector
                      options={
                        studentsProfile?.length ? getGroupSelectorData() : []
                      }
                      selectedValues={responsible_ids ?? []}
                      onChange={updateSelectorColumns}
                      optionComponent={optionComponent}
                      groupComponent={groupComponent}
                      searchPlaceholder={t('form.enrollment.search')}
                      optionAccessor={(option) => option?.id}
                      lockOptions={isEditing}
                      lockSelected={isEditing}
                      counterLabel={(length) =>
                        t('form.enrollment.student', {
                          count: length,
                        })
                      }
                      emptyState={isLoadingStudentsResponsibles && <Loader />}
                      singleSelectPerGroup
                    />
                  </Suspense>
                </S.Row>
              </S.MassFormContainer>
            </div>
          </div>
        )}
      </FormFieldset>
    </div>
  );
};

EnrollmentPlanFormRecipientsTab.propTypes = {
  ...formPropTypes,
};

export default tabifyForWizard({
  title: '3. Destinatários',
  subTitle: '',
  validations: [],
})(withFormContext(EnrollmentPlanFormRecipientsTab));
