import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Button, Text, TimePicker } from '@agendaedu/ae-web-components';

import omniChannelActions from 'store/messages/omniChannel/actions';
import {
  allDaysInactive,
  isAfterHours,
  validateFormErrors,
} from 'core/helper/message/omni/attendingHours';
import withFormContext from 'core/hoc/withFormContext';

import Accordion from 'components/Accordion';
import ToggleSwitch from 'components/Form/ToggleSwitch';

import * as S from './styles';

import { AttendingHoursAccordionProps } from './types';

const AttendingHoursAccordion = ({
  formContext: {
    updateAttribute,
    hasErrorOnAttribute,
    getFormValidationResult,
    form: { attending_hours: attendingHours },
  },
}: AttendingHoursAccordionProps): JSX.Element => {
  const { t } = useTranslation(['messages']);
  const dispatch = useDispatch();

  const { toggleOpeningHoursModal } = omniChannelActions;

  const handleGetErrors = (name: string) =>
    validateFormErrors(name, getFormValidationResult, hasErrorOnAttribute);

  const handleAllDaysButton = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();

      dispatch(toggleOpeningHoursModal());
    },
    [dispatch, toggleOpeningHoursModal]
  );

  const handleChangeTime = useCallback(
    (day: string, value: string, position: number) => {
      const newAttendingHours = attendingHours;
      let currentHours = newAttendingHours.days[day].hours;

      if (!currentHours.length) {
        currentHours = ['', ''];
      }

      currentHours[position] = value;

      newAttendingHours.days[day].hours = currentHours;

      updateAttribute('attending_hours', newAttendingHours);
    },
    [attendingHours, updateAttribute]
  );

  const handleChangeToggle = useCallback(
    (day: string) => {
      const newAttendingHours = attendingHours;

      const currentToggleValue = newAttendingHours.days[day].active;
      newAttendingHours.days[day].active = !currentToggleValue;

      updateAttribute('attending_hours', newAttendingHours);

      if (allDaysInactive(newAttendingHours.days))
        updateAttribute('attending_hours', null);
    },
    [attendingHours, updateAttribute]
  );

  return (
    <S.Wrapper data-testid="attending-hours-wrapper">
      <Accordion
        variation="leftButton"
        header={
          <S.AccordionHeaderWrapper>
            <Text margin={0} variant="title-bold-16" color={'neutral.black'}>
              {t('omni_channel.forms.attending_hours.accordion_title_text')}
            </Text>
            <Button
              testId="all-days-button"
              variant="secondary"
              size={'xs'}
              onClick={handleAllDaysButton}
            >
              {t('omni_channel.forms.attending_hours.accordion_button_text')}
            </Button>
          </S.AccordionHeaderWrapper>
        }
        body={
          <S.AccordionBodyWrapper>
            {[
              ...Object.entries(attendingHours.days).filter(
                ([day]) => day !== '0'
              ),
              Object.entries(attendingHours.days).find(([day]) => day === '0'),
            ].map(([day, { active, hours }]) => {
              const startHours = hours[0];
              const endHours = hours[1];

              const isAfter = isAfterHours(hours);

              return (
                <S.AttendingDayWrapper key={day}>
                  <S.DayWeekWrapper>
                    <Text color={'neutral.black'} variant="subtitle-medium-14">
                      {t(`omni_channel.forms.attending_hours.days.${day}`)}
                    </Text>
                  </S.DayWeekWrapper>
                  <S.ToggleWrapper>
                    <ToggleSwitch
                      testId={`toggle-day-${active}`}
                      toggled={active}
                      onChange={() => handleChangeToggle(day)}
                    />
                    <S.InputTimeWrapper>
                      <TimePicker
                        data-testid="time-picker-start"
                        onChange={(value: string) =>
                          handleChangeTime(day, value, 0)
                        }
                        disabled={!active}
                        error={
                          !!active &&
                          (startHours?.length < 5 || !startHours) &&
                          handleGetErrors('attending_hours').hasError
                        }
                        errorMessage={t(
                          `omni_channel.forms.${
                            handleGetErrors('attending_hours').errorMessages
                          }`
                        )}
                        placeholder="00:00"
                        value={startHours}
                        label={t(
                          'omni_channel.forms.attending_hours.accordion_start_time_input_text'
                        )}
                      />
                      <TimePicker
                        data-testid="time-picker-end"
                        onChange={(value: string) =>
                          handleChangeTime(day, value, 1)
                        }
                        disabled={!active}
                        error={
                          !!active &&
                          (endHours?.length < 5 || !endHours || isAfter) &&
                          handleGetErrors('attending_hours').hasError
                        }
                        errorMessage={t(
                          `omni_channel.forms.${
                            isAfter
                              ? 'after_hours'
                              : handleGetErrors('attending_hours').errorMessages
                          }`
                        )}
                        placeholder="00:00"
                        value={endHours}
                        label={t(
                          'omni_channel.forms.attending_hours.accordion_end_time_input_text'
                        )}
                      />
                    </S.InputTimeWrapper>
                  </S.ToggleWrapper>
                </S.AttendingDayWrapper>
              );
            })}
          </S.AccordionBodyWrapper>
        }
        expanded="messages"
        allowZeroExpanded
      />
    </S.Wrapper>
  );
};

export default withFormContext(AttendingHoursAccordion);
