import React from 'react';
import PropTypes from 'prop-types';

import withFormContext, { formPropTypes } from 'core/hoc/withFormContext';
import withToastMessage from 'core/hoc/withToastMessage';
import FormValidationErrors from 'components/Form/ValidationErrors';

import DirectUpload from 'core/services/Uploader/DirectUpload';

import ReactQuill, { Quill } from 'react-quill';
import ImageUploader from 'quill-image-uploader';
import ImageResize from 'quill-image-resize-module-react-mobile';
import 'react-quill/dist/quill.snow.css';
import './style.scss';

import validateTextAreaPresence from 'core/lib/FormValidator/validators/textAreaPresence';

window.Quill = Quill;
Quill.register('modules/imageUploader', ImageUploader);
Quill.register('modules/imageResize', ImageResize);

const DirectionAttribute = Quill.import('attributors/attribute/direction');
Quill.register(DirectionAttribute, true);

const AlignClass = Quill.import('attributors/class/align');
Quill.register(AlignClass, true);

const BackgroundClass = Quill.import('attributors/class/background');
Quill.register(BackgroundClass, false);

const ColorClass = Quill.import('attributors/class/color');
Quill.register(ColorClass, true);

const DirectionClass = Quill.import('attributors/class/direction');
Quill.register(DirectionClass, true);

const FontClass = Quill.import('attributors/class/font');
Quill.register(FontClass, true);

const SizeClass = Quill.import('attributors/class/size');
Quill.register(SizeClass, true);

const AlignStyle = Quill.import('attributors/style/align');
Quill.register(AlignStyle, true);

const ColorStyle = Quill.import('attributors/style/color');
Quill.register(ColorStyle, true);

const DirectionStyle = Quill.import('attributors/style/direction');
Quill.register(DirectionStyle, true);

const FontStyle = Quill.import('attributors/style/font');
Quill.register(FontStyle, true);

const FontAttributor = ReactQuill.Quill.import('formats/font');
FontAttributor.whitelist = ['Roboto', 'Roboto Slab', 'Roboto Mono'];
ReactQuill.Quill.register(FontAttributor, true);

const FontSizeStyle = Quill.import('attributors/style/size');
FontSizeStyle.whitelist = ['1', '2', '3', ''];
Quill.register(FontSizeStyle, true);

const Link = Quill.import('formats/link');
const builtInFunc = Link.sanitize;
Link.sanitize = function customSanitizeLinkInput(linkValueInput) {
  let val = linkValueInput;

  if (/^\w+:/.test(val));
  else if (!/^https?:/.test(val)) val = 'http://' + val;

  return builtInFunc.call(this, val);
};

const icons = Quill.import('ui/icons');
icons['bold'] = '<i class="AgendaIcon icon-ae-bold" style="font-size: 24px"/>';
icons['italic'] =
  '<i class="AgendaIcon icon-ae-italic" style="font-size: 24px"/>';
icons['underline'] =
  '<i class="AgendaIcon icon-ae-underline" style="font-size: 24px"/>';
icons['color'] =
  '<i class="AgendaIcon icon-ae-editor-color" style="font-size: 24px"/>';
icons['align']['center'] =
  '<i class="AgendaIcon icon-ae-align-center" style="font-size: 24px"/>';
icons['align']['right'] =
  '<i class="AgendaIcon icon-ae-align-right" style="font-size: 24px"/>';
icons['align']['left'] =
  '<i class="AgendaIcon icon-ae-align-left" style="font-size: 24px"/>';
icons['align']['justify'] =
  '<i class="AgendaIcon icon-ae-justified" style="font-size: 24px"/>';
icons['list']['ordered'] =
  '<i class="AgendaIcon icon-ae-numbered" style="font-size: 24px"/>';
icons['list']['bullet'] =
  '<i class="AgendaIcon icon-ae-topics" style="font-size: 24px"/>';
icons['link'] = '<i class="AgendaIcon icon-ae-link" style="font-size: 24px"/>';
icons['image'] =
  '<i class="AgendaIcon icon-ae-editor-image" style="font-size: 24px"/>';

const uploadImageCallBack = (file) => {
  const directUpload = new DirectUpload('schools');

  return new Promise(async (resolve, reject) => {
    await directUpload
      .upload(file)
      .then((result) => {
        resolve(result.permanent_url);
      })
      .catch((error) => {
        reject('Erro ao tentar Upload failed');
        showToast(
          'Ocorreu um erro ao tentar realizar o upload da imagem',
          'error'
        );
      });
  });
};

const formats = [
  'background',
  'bold',
  'color',
  'font',
  'code',
  'italic',
  'link',
  'size',
  'strike',
  'script',
  'underline',
  'blockquote',
  'header',
  'indent',
  'list',
  'align',
  'direction',
];

const modules = {
  toolbar: [
    [{ header: ['1', '2', '3', ''] }],
    [{ font: FontAttributor.whitelist }],
    [
      'bold',
      'italic',
      'underline',
      {
        color: [
          '#d92b25',
          '#ff8a05',
          '#e0ad04',
          '#29a11b',
          '#59c5be',
          '#4fc1f9',
          '#006fff',
          '#4d4cd0',
          '#733dbf',
          '#e84393',
          '#000000',
          '#999999',
          '#666666',
          '#C2C2C2',
          '#AEB3BA',
        ],
      },
    ],
    [{ align: ['justify', 'left', 'right', 'center'] }],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['link', 'image'],
  ],
  imageResize: {
    parchment: Quill.import('parchment'),
  },
  imageUploader: {
    upload: uploadImageCallBack,
  },
  clipboard: {
    matchVisual: false,
  },
};

const modulesWithoutImagens = {
  toolbar: [
    [{ header: ['1', '2', '3', ''] }],
    [{ font: FontAttributor.whitelist }],
    [
      'bold',
      'italic',
      'underline',
      {
        color: [
          '#d92b25',
          '#ff8a05',
          '#e0ad04',
          '#29a11b',
          '#59c5be',
          '#4fc1f9',
          '#006fff',
          '#4d4cd0',
          '#733dbf',
          '#e84393',
          '#000000',
          '#999999',
          '#666666',
          '#C2C2C2',
          '#AEB3BA',
        ],
      },
    ],
    [{ align: ['justify', 'left', 'right', 'center'] }],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['link'],
  ],
  clipboard: {
    matchVisual: false,
  },
};

/**
 * This class change font-family of textarea.
 */

const BlockPrototype = Quill.import('blots/block');

class CustomBlock extends BlockPrototype {
  constructor(domNode, value) {
    super(domNode, value);
    this.format('font', 'Roboto');
  }

  static tagName = 'DIV';

  format(name, value) {
    if (name === 'font') {
      this.domNode.style.fontFamily = value;
    } else {
      super.format(name, value);
    }
  }
}

Quill.register(CustomBlock, true);

/**
 * This class change styles of images.
 */

const BlockEmbed = Quill.import('blots/block/embed');

const ATTRIBUTES = ['height', 'width', 'style', 'src', 'alt'];

class ImageBlot extends BlockEmbed {
  static create(value) {
    const node = super.create(value);
    if (typeof value === 'string') {
      node.setAttribute('src', value);
    }
    node.setAttribute('width', '100%');
    node.setAttribute('height', 'auto');
    node.setAttribute('alt', '');
    return node;
  }
  static formats(domNode) {
    return ATTRIBUTES.reduce((formats, attribute) => {
      if (domNode.hasAttribute(attribute)) {
        formats[attribute] = domNode.getAttribute(attribute);
      }
      return formats;
    }, {});
  }
  static value(domNode) {
    return domNode.getAttribute('src');
  }

  format(name, value) {
    if (ATTRIBUTES.indexOf(name) > -1) {
      if (value) {
        this.resizeImage(name, value);
      } else {
        this.domNode.removeAttribute(name);
      }
    } else {
      super.format(name, value);
    }
  }

  resizeImage(name, value) {
    if (name === 'width' && value > 450) {
      this.domNode.setAttribute(name, '100%');
    } else {
      this.domNode.setAttribute(name, value);
    }
  }
}

ImageBlot.blotName = 'image';
ImageBlot.tagName = 'IMG';
Quill.register(ImageBlot, true);

/**
 * This component creates a textarea with a simple WYIWYG. It must be used
 * within a Form component.
 *
 * @example
 *  <FormContainer {...formProps}>
 *    <TextArea attributeName="description" />
 *  </FormContainer>
 */
class TextArea extends React.Component {
  /**
   * Returns the EditorState
   * @param {String} value is the initial HTML.
   * @param {Object} formMeta is the formMeta that comes from FormContext.
   */

  isEmptyField = (value) => {
    return !validateTextAreaPresence(value);
  };

  getCreatedEditorState = (value) => {
    const {
      formContext: { formMeta },
    } = this.props;
    const editorState = formMeta[this.getEditorConfigMetaKey()];

    if (editorState) {
      return editorState;
    }

    if (!value) {
      return '';
    }

    const contentState = value || '';

    return contentState;
  };

  getEditorConfigMetaKey = () => {
    const { attributeName } = this.props;
    return `${attributeName}EditorConfig`;
  };

  /**
   * @param {object} form The form state from FormContext.
   * @param {Function} changeAttribute The changeAttribute function from
   *  FormContext.
   * @param {Function} changeMeta The changeMeta function from
   *  FormContext.
   * @returns {Function} The function to be used when controlling the Editor.
   */

  onChange = (editorState) => {
    const { attributeName, formContext } = this.props;
    const { updateFormContext } = formContext;

    updateFormContext(({ prevFormState, buildFormState, buildFormMeta }) => {
      const fieldState = this.isEmptyField(editorState) ? null : editorState;

      const formState = buildFormState(prevFormState, {
        [attributeName]: fieldState,
      });

      const formMeta = buildFormMeta(
        prevFormState,
        this.getEditorConfigMetaKey(),
        fieldState
      );

      return { ...formState, ...formMeta };
    });
  };

  render() {
    const { attributeName, useImage, formContext, quillProps } = this.props;
    const { form, hasErrorOnAttribute } = formContext;
    const toolbar = useImage ? modules : modulesWithoutImagens;
    const showFormats = useImage ? '' : formats;

    const editorState = this.getCreatedEditorState(form[attributeName]);

    const hasError = hasErrorOnAttribute(attributeName);

    return (
      <div className="TextArea">
        <ReactQuill
          theme="snow"
          value={editorState}
          onChange={this.onChange}
          modules={toolbar}
          formats={showFormats}
          {...quillProps}
        />
        {hasError && <FormValidationErrors attributeName={attributeName} />}
      </div>
    );
  }
}

TextArea.defaultProps = {
  disabled: false,
  useImage: false,
};

TextArea.propTypes = {
  attributeName: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  useImage: PropTypes.bool,
  ...formPropTypes,
};

export default withFormContext(withToastMessage(TextArea));
