import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@agendaedu/ae-web-components';

import OmniChannelActions from 'store/messages/omniChannel/actions';
import {
  ChannelOption,
  OmniChannelState,
} from 'store/messages/omniChannel/types';

import * as S from './styles';

import { Modal } from 'components/Handouts/Modal';
import TransferCard from 'components/Messages/OmniChannel/TransferCard';

export const TransferTicketModal = (): JSX.Element => {
  const { t } = useTranslation('messages');

  const dispatch = useDispatch();

  const [selectedChannel, setSelectedChannel] = useState<ChannelOption>(null);

  const {
    toggleTransferTicketModal,
    setTransferTicketRequest,
    fetchTicketStudentChannelsRequest,
  } = OmniChannelActions;

  const {
    channels,
    showTransferTicketModal,
    activeChannel,
    activeChat,
    activeTicket,
    ticketStudentChannels,
  } = useSelector((state: OmniChannelState) => state.omniChannel);

  const disabledModal = !selectedChannel;

  const handleOnChangeChannel = useCallback((channel: ChannelOption) => {
    setSelectedChannel(channel);
  }, []);

  const handleToggleTransferModal = useCallback(() => {
    setSelectedChannel(null);
    dispatch(toggleTransferTicketModal());
  }, [dispatch, toggleTransferTicketModal]);

  const handleTransferTicket = useCallback(() => {
    const params = {
      channelId: activeChannel.id,
      ticketId: activeTicket.id,
      targetChannelId: selectedChannel.value,
    };

    dispatch(setTransferTicketRequest(params));
    handleToggleTransferModal();
  }, [
    activeChannel,
    activeTicket,
    dispatch,
    handleToggleTransferModal,
    selectedChannel,
    setTransferTicketRequest,
  ]);

  useEffect(() => {
    if (channels && showTransferTicketModal) {
      const params = {
        studentProfileId: activeChat?.attributes?.student_profile_id,
      };
      dispatch(fetchTicketStudentChannelsRequest(params));
    }
  }, [showTransferTicketModal]);

  return (
    <Modal.Root
      dataTestId="omni-channel-transfer"
      isOpen={showTransferTicketModal}
      title={t('omni_channel.modals.transfer_modal.title')}
      handleToggleModal={handleToggleTransferModal}
      maxWidth="fit-content"
    >
      <Modal.Content>
        {ticketStudentChannels && (
          <S.Wrapper>
            <S.SelectChannel
              data-testID="select-channel"
              label={t('omni_channel.modals.transfer_modal.select_label')}
              placeholder={t(
                'omni_channel.modals.transfer_modal.select_placeholder'
              )}
              options={ticketStudentChannels}
              onChange={handleOnChangeChannel}
              isSearchable
              fullWidth
            />

            <TransferCard />
          </S.Wrapper>
        )}

        <Modal.Actions>
          <Button
            testId="cancel-button"
            variant="secondary"
            onClick={handleToggleTransferModal}
          >
            {t('omni_channel.modals.transfer_modal.button_cancel')}
          </Button>

          <Button
            testId="submit-button"
            disabled={disabledModal}
            onClick={handleTransferTicket}
          >
            {t('omni_channel.modals.transfer_modal.button_submit')}
          </Button>
        </Modal.Actions>
      </Modal.Content>
    </Modal.Root>
  );
};
