import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import withAppContext from 'core/hoc/withAppContext';
import withToastMessage from 'core/hoc/withToastMessage';
import ValidationModal from '../components/ValidationModal';
import { useTranslation } from 'react-i18next';
import FormFullScreen from 'components/FormFullScreen';
import Toast from 'components/Toast';
import SchoolTermsActions from 'store/schoolTerms/actions';
import { modalActions } from 'core/constants/schoolTerms';
import periodStepForm from '../components/periodStepForm';
import { trackEvent } from '../../../config/amplitude';

const SchoolTermsNew = ({ history }) => {
  const { t } = useTranslation(['school_terms']);
  const dispatch = useDispatch();

  const { saveNewPeriod } = SchoolTermsActions;
  const { isSending, classrooms } = useSelector((state) => state.schoolTerms);
  const [validationModal, setValidationModal] = useState({
    isOpen: false,
    variant: null,
    dataForm: null,
  });

  useEffect(() => {
    if (classrooms.length <= 0) history.push('/schools/school_terms');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classrooms]);

  const submitModal = () => {
    trackEvent('save_new_school_terms');
    dispatch(saveNewPeriod(validationModal.dataForm));
    setValidationModal({ isOpen: false, variant: null, dataForm: null });
  };

  const onSubmit = ({ form }) => {
    setValidationModal({
      isOpen: true,
      variant: modalActions.savePeriod,
      dataForm: form,
    });
  };

  return (
    <>
      <FormFullScreen
        id="school-terms-create"
        action="new"
        onSubmit={onSubmit}
        form={{
          classroom_ids: [],
          name: '',
        }}
        formMeta={{
          classrooms: classrooms,
          canEditClassrooms: true,
        }}
        isSubmitting={isSending}
        steps={[periodStepForm]}
        backTo="/schools/school_terms"
        backToExternal
        titlePage={t('new_form.title')}
        titleModal={t('new_form.title_modal')}
        contentModal={t('new_form.content_modal')}
      />

      <ValidationModal
        variant={validationModal.variant}
        isModalOpen={validationModal.isOpen}
        onSubmit={submitModal}
        closeModal={() => {
          setValidationModal({ isOpen: false, variant: null });
        }}
      />
      <Toast />
    </>
  );
};

export default withAppContext(withToastMessage(SchoolTermsNew));
