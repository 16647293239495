import React, { useEffect, useCallback } from 'react';

import FormContext from 'core/contexts/Form';
import withFormContext from 'core/hoc/withFormContext';
import tabifyForWizard from 'core/hoc/tabifyForWizard';
import FormSelector from 'components/Form/Selector';
import Loader from 'components/Loader';

import './style.scss';

const CoursesTab = ({ formContext }) => {
  const { changeMeta, formMeta, form } = formContext;

  const optionComponent = (option) => (
    <span className="course-name">{option.name}</span>
  );

  const updateSelectorColumns = useCallback(
    ({ selectedValues }) => {
      form.courses.selected = selectedValues;
      formMeta.course(selectedValues);
    },
    []
  );

  const searchOptions = ({ options, searchTerm }) =>
    options.filter((option) =>
      option.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

  return (
    <div className="coursesTab">
      <div>
        <div className="stepInfo">
          <p>
            Selecione quais <b>Cursos</b> serão importadas na integração. Essas
            configurações serão salvas para as próximas integrações.
          </p>
        </div>

        <FormContext.Consumer>
          {({ form }) => (
            <Loader isLoading={form.loading}>
              <FormSelector
                options={form.data.courses.filters}
                selectedValues={form.data.courses.selected}
                optionComponent={optionComponent}
                onChange={updateSelectorColumns}
                search={searchOptions}
                searchPlaceholder="Buscar curso"
                optionAccessor={(option) => option.code}
                selectAllLabel="Selecionar todos"
                onSelectBehavior="highlight"
                counterLabel={(length) => (length === 1 ? 'curso' : 'cursos')}
              />
            </Loader>
          )}
        </FormContext.Consumer>
      </div>
    </div>
  );
};

export default tabifyForWizard({
  title: '2. Cursos',
  subTitle: '',
})(withFormContext(CoursesTab));
