import styled, { css } from 'styled-components';

export const Container = styled.li`
  ${({ hidden = 'block' }) => css`
    width: 100%;
    list-style: none;
    display: ${hidden && 'none'};
  `};
`;

export const Content = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;

    .DropdownButton {
      .Button {
        border-color: transparent;

        &:hover {
          background: ${theme.gray5};
        }
      }

      .dropdown-menu {
        top: 40px;
        right: 2px;
        height: auto;
        margin: 0;
        padding: 0;
        background: ${theme.white};
        border: 0;
        border-radius: 6px;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.14);

        .dropdown-item {
          margin: 0;

          &:hover {
            color: ${theme.black};
            background: transparent;
          }
        }
      }
    }
  `}
`;

export const Profile = styled.div`
  position: relative;
  max-width: 100%;

  display: flex;
  align-items: center;

  .Avatar {
    flex-shrink: 0;
    position: absolute;
    top: 10px;
    left: 0;
  }

  @media only screen and (min-width: 768px) {
    max-width: 60%;
  }
`;

export const ProfileOverlay = styled.div`
  ${({ theme }) => css`
    position: absolute;
    inset: 0;
    background: ${theme.white}80;
    z-index: 5;
  `}
`;

export const Message = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 0 64px;
`;

export const Header = styled.div`
  margin-bottom: 16px;
  display: flex;
  align-items: baseline;
  flex-direction: column;
  gap: 8px;

  @media only screen and (min-width: 768px) {
    margin-bottom: 0px;
    flex-direction: row;
    gap: 16px;
  }
`;

export const Subheader = styled.div`
  margin-top: -12px;
  display: flex;
  align-items: baseline;
  flex-direction: column;
  gap: 8px;

  @media only screen and (min-width: 768px) {
    margin-top: 0px;
    flex-direction: row;
    gap: 16px;
  }
`;
