import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { IMAGES_PAYMENT_URL } from 'core/constants/index';

import Button from 'components/Button';
import EmptyState from 'components/EmptyState';
import PageTitle from 'components/PageTitle';
import PageSubTitle from 'components/PageSubTitle';
import Pagination from 'components/Pagination';
import Table from 'components/Table';

import withAppContext from 'core/hoc/withAppContext';
import table from './table';

import './style.scss';

const SchoolProductsList = ({
  meta,
  schoolProducts,
  activePage,
  totalItemsCount,
  itemsCountPerPage,
  onPageChanged,
  fetchError,
  filters,
  appContext: { policies },
}) => {
  if (fetchError) throw fetchError;

  if (!meta) {
    return (
      <div className="SchoolProductsList">
        <EmptyState
          imgUrl={IMAGES_PAYMENT_URL.onboardingUrl}
          message={
            <React.Fragment>
              <div className="vertically-aligned flex-column">
                <PageTitle>
                  Experimente criar agora sua primeira cobrança
                </PageTitle>
                <PageSubTitle>Leva menos de 2 minutos.</PageSubTitle>
                {policies && policies.can_config_wallet && (
                  <Link to="/schools/school_products/new" className="action">
                    <Button className="btn-lg">Comece agora</Button>
                  </Link>
                )}
              </div>
            </React.Fragment>
          }
        />
      </div>
    );
  }

  const reportParams = () => {
    const params = Object.entries(filters || {}).reduce(
      (accumulator, [key, value]) => {
        if (!!value) {
          accumulator[key] = value;
        }
        return accumulator;
      },
      {}
    );

    return `?${new URLSearchParams(params)}`;
  };

  return (
    <div className="SchoolProductsList">
      {schoolProducts && schoolProducts.length > 0 ? (
        <React.Fragment>
          <Table
            columns={table}
            data={schoolProducts}
            metadata={{
              page: activePage,
              totalItemsCount,
              itemsCountPerPage,
              singular: 'cobrança',
              plural: 'cobranças',
            }}
            exportOptions={[
              {
                path: `/schools/school_products/report.xls${reportParams()}`,
                text: 'Planilha (.xls)',
              },
            ]}
            exportTrackEvent="export-school-products"
          />
          <Pagination
            onChange={onPageChanged}
            activePage={activePage}
            totalItemsCount={totalItemsCount}
            itemsCountPerPage={itemsCountPerPage}
          />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <EmptyState
            imgUrl={IMAGES_PAYMENT_URL.availabilityUrl}
            message={
              <p>
                Não foram encontrados registros <br />
                para o termo buscado
              </p>
            }
          />
        </React.Fragment>
      )}
    </div>
  );
};

SchoolProductsList.propTypes = {
  schoolProducts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      attributes: PropTypes.object.isRequired,
    })
  ),
  meta: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.objectOf(
      PropTypes.shape({
        has_school_products: PropTypes.bool,
      })
    ),
  ]),
  activePage: PropTypes.number,
  totalItemsCount: PropTypes.number,
  itemsCountPerPage: PropTypes.number,
  onPageChanged: PropTypes.func.isRequired,
  fetchError: PropTypes.object,
  appContext: PropTypes.object,
  filters: PropTypes.object,
};

export default withAppContext(SchoolProductsList);
