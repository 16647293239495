import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const HelperText = ({ children }) => (
  <div className="HelperText">
    <span>{children}</span>
  </div>
);

HelperText.propTypes = {
  children: PropTypes.node.isRequired,
};

export default HelperText;
