import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, Button, Text } from '@agendaedu/ae-web-components';

import { StorageRoot } from 'store/storage/types';

import CenterModal from 'pages/storage/Modals/CenterModal';

import { Props } from './types';

const NotificationModal: React.FC<Props> = ({
  onClose,
  onClick,
  kind,
  event,
}): React.ReactElement => {
  const { t } = useTranslation(['storage', 'common']);

  const tBase = useCallback((key: string) => t(`common.form.${key}`), [t]);

  const {
    storageForm: { showNotificationModal },
  } = useSelector((state: StorageRoot) => state.storage);

  const modalDescription = {
    document:
      event === 'new'
        ? t('document_form.notification_modal_description')
        : t('document_form.notification_edit_modal_description'),
    file:
      event === 'new'
        ? t('file_form.notification_modal_description')
        : t('file_form.notification_edit_modal_description'),
  };

  return (
    <CenterModal
      isOpen={showNotificationModal}
      onClose={onClose}
      body={
        <Text
          variant="body-regular-16"
          color="neutral.gray1"
          lineHeight="lg"
          mt="xs"
        >
          {modalDescription[kind]}
        </Text>
      }
      footer={
        <Box display="flex" flexDirection="row-reverse" gap="sm">
          <Button
            data-testid="notification-button"
            onClick={() => onClick({ notification: true })}
          >
            {tBase('submit_button')}
          </Button>

          <Button
            data-testid="not-notification-button"
            variant="secondary"
            onClick={() => onClick({ notification: false })}
          >
            {tBase('cancel_button')}
          </Button>
        </Box>
      }
      title={tBase('notification_modal_title')}
    />
  );
};

export default NotificationModal;
