import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';

import SummarydayClassroomsActions from 'store/summarydayClassrooms/actions';
import SummarydayClassroomContext from 'core/contexts/SummarydayClassroom';
import useGoogleDrive from 'core/hooks/useGoogleDrive';

import { composeStorageKey } from 'core/utils/composeStorageKey';

import MemoryStorage from 'core/utils/MemoryStorage';

import Toast from 'components/Toast';
import Loader from 'components/Loader';
import RecipientsTab from 'components/Form/RecipientsTab';
import FormFullScreen from 'components/FormFullScreen';
import GeneralInformation from 'pages/SummarydayClassrooms/FormTabs/GeneralInformation';
import moment from 'moment/moment';
import launchDarkly from 'core/helper/launchDarkly';
import { flags } from 'core/constants/flags';

const Edit = ({ googleClientId, match }) => {
  const dispatch = useDispatch();
  const [cookies] = useCookies(['agendaedu_launch_darkly']);
  const { id } = match.params;

  const activeFlags = cookies.agendaedu_launch_darkly?.active_flags;

  const memoryStorage = new MemoryStorage();

  const { setSummarydayClassroom, updateSummarydayClassroom } =
    SummarydayClassroomsActions;
  const { formMeta, isLoading, isSending, current } = useSelector(
    (state) => state.summarydayClassrooms
  );

  const dataArea = useSelector((state) => state.root.dataArea);

  useEffect(() => {
    dispatch(setSummarydayClassroom(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const onSubmit = ({ form }) => {
    const newForm = {
      ...form,
      attachments: form.attachments.map((file) => file.signed_id),
      deadline: moment.isMoment(form.deadline)
        ? form.deadline
            .hours(23)
            .minutes(59)
            .seconds(59)
            .format('YYYY-MM-DD HH:mm:ss')
        : form.deadline,
    };

    dispatch(updateSummarydayClassroom(id, newForm));
    memoryStorage.setLocalStorageItem(composeStorageKey('modal'), true);
  };

  const actions = useGoogleDrive(
    googleClientId,
    'https://www.googleapis.com/auth/drive.readonly'
  );

  const hideRadio = ['sent', 'updated'].includes(current.status)

  return (
    <Loader isLoading={isLoading}>
      <SummarydayClassroomContext.Provider value={actions}>
        <FormFullScreen
          action="edit"
          onSubmit={onSubmit}
          form={current}
          formMeta={{
            ...formMeta,
            googleClientId,
            hideRadio,
            select_options: {
              classroom_id: formMeta.classrooms,
            },
            student_profiles: current.student_profiles,
          }}
          isSubmitting={isSending}
          steps={[GeneralInformation, RecipientsTab]}
          backTo={`/${dataArea}/summaryday_classrooms/${id}`}
          backToExternal
          titlePage="Editar Atividade"
          titleModal="Descartar alterações"
          contentModal="As informações alteradas não serão salvas até que você as envie, e essa ação não pode ser desfeita. Tem certeza que deseja descartar?"
        />
      </SummarydayClassroomContext.Provider>
      <Toast />
    </Loader>
  );
};

Edit.propTypes = {
  googleClientId: PropTypes.string.isRequired,
  match: PropTypes.object.isRequired,
};

export default Edit;
