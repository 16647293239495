import styled, { css } from 'styled-components';

import { DefaultThemeProps } from '@agendaedu/ae-web-components';

export const FiltersContainer = styled.div<{ theme: DefaultThemeProps }>`
  ${({ theme: { border, colors, typography } }) => css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100% - 87px);

    #accordion__heading-moodTrackers {
      padding: 16px 24px;
      border-right: 0;
      border-left: 0;
      border-bottom: 0;
      border-radius: ${border.radius.none};
      background-color: ${colors.neutral.white};
      border-top: 1px solid ${colors.neutral.gray5};
    }

    #accordion__panel-moodTrackers {
      height: 103px;
      padding: 16px 24px;
      border: ${border.width.none};

      .Field {
        padding: 0;
        margin-bottom: 24px;
        width: 100%;

        .Label {
          ${typography.label.Regular14}
        }

        .mood_trackers__control--is-focused,
        .mood_trackers__control--menu-is-open,
        .mood_trackers__menu {
          border: 1px solid ${colors.brand.primary.default};

          .mood_trackers__option.mood_trackers__option--is-focused,
          .mood_trackers__option.mood_trackers__option--is-selected {
            background-color: ${colors.brand.primary.op10};
            color: ${colors.brand.primary.default};
          }
        }

        .mood_trackers__menu {
          border-top: ${border.width.none};
        }
      }
    }
  `}
`;

export const AccordionTitle = styled.h2<{ theme: DefaultThemeProps }>`
  ${({ theme: { colors, typography } }) => css`
    margin: 0;
    background-color: ${colors.neutral.white};
    ${typography.subtitle.Medium16}
  `}
`;

export const ButtonRow = styled.div<{ theme: DefaultThemeProps }>`
  ${({ theme: { border, typography } }) => css`
    display: flex;
    justify-content: space-around;

    .Button {
      width: 50%;
      height: 40px;
      ${typography.button.Bold16}
      border-radius: ${border.radius.md};
      display: unset;

      &:nth-child(1) {
        margin: 24px 8px 24px 24px;
      }

      &:nth-child(2) {
        margin: 24px 24px 24px 8px;
      }
    }
  `}
`;
