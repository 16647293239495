import styled, { css } from 'styled-components';

export const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  width: 48%;
  height: 48px;
  padding: 8px 16px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.gray6};
  background-color: ${({ theme, checked }) =>
    checked ? `${theme.primaryColor}10` : ''};

  ${({ theme, disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      background-color: ${theme.colors.neutral.gray5};

      label {
        color: ${theme.colors.neutral.gray3};

        &::selection {
          background: ${theme.colors.neutral.gray3};
          color: ${theme.colors.neutral.white};
        }
      }
    `}

  @media (max-width: 600px) {
    margin: 10px 0;
    width: 100%;
  }
`;

export const IconCheck = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 3px;
`;

export const StyledImg = styled.img`
  width: 32px;
  margin-right: 16px;
`;

export const Text = styled.label`
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${({ checked, theme }) =>
    checked ? theme.primaryColor : theme.black};
`;

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const StyledCheckbox = styled.div`
  display: inline-block;
  width: 18px;
  height: 18px;
  border-radius: 4px;
  border: ${({ checked, theme }) =>
    checked ? 'none' : `2px solid ${theme.gray1}`};
  background: ${({ checked, theme }) =>
    checked ? theme.primaryColor : theme.white};
  transition: all 150ms;

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px;
  }

  ${IconCheck} {
    visibility: ${({ checked }) => (checked ? 'visible' : 'hidden')};
  }

  ${({ disabled, theme }) =>
    disabled &&
    css`
      cursor: not-allowed;
      background-color: ${theme.colors.neutral.gray3};
    `}
`;

export const StyledFlexContainer = styled.div`
  display: flex;
  align-items: center;

  label {
    margin-bottom: 0;
  }
`;
