import styled, { css } from 'styled-components';

export const DailySummariesContainer = styled.main`
  padding: 16px;

  @media (max-width: 1200px) {
    padding: 0;
  }

  .Field .Label {
    font-family: 'Roboto', sans-serif;
  }
`;

export const ActionsContainer = styled.section`
  ${({ theme }) => css`
    width: 100%;

    @media (max-width: 1200px) {
      margin-bottom: 24px;

      h2 {
        margin: 0;
        font-size: 20px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 50%;
      }

      .Form {
        width: 100%;
        align-items: center;

        .Field {
          padding: 0;
        }
      }
    }

    > * {
      color: ${theme.black};
      font-family: 'Quicksand', sans-serif;
    }

    h2 {
      margin: 0 0 8px 0;
      font-size: 20px;
      color: ${theme.black};
      font-weight: 700;
    }

    h3 {
      font-size: 14px;
      font-weight: 500;
      color: ${theme.gray2};
      margin: 0 0 24px 0;
    }
  `}
`;

export const BodyContent = styled.section`
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 2fr;

  .Alert {
    width: 100%;
    margin-bottom: 16px;
    grid-column: 1 / col2-end;
    font-family: 'Quicksand', sans-serif;
    font-weight: 700;
    font-size: 14px;
  }

  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
  }
`;

export const FormContent = styled.div`
  ${({ theme }) => css`
    padding-left: 32px;
    width: 100%;
    min-width: 100%;

    @media (max-width: 1200px) {
      flex-direction: column;
      padding-left: unset;
    }

    h4 {
      font-family: 'Quicksand', sans-serif;
      color: ${theme.black};
      font-weight: 700;
      font-size: 20px;
      margin: 0 0 24px 0;

      @media (max-width: 1200px) {
        margin-top: 32px;
      }
    }

    .Form > * {
      margin-top: 24px;

      @media (max-width: 1200px) {
        &:first-of-type {
          margin-top: 0;
        }
      }
    }

    #form-header {
      display: flex;
      flex-flow: row nowrap;
      align-items: flex-start;
      justify-content: space-between;

      @media (max-width: 1200px) {
        flex-flow: column wrap;

        .Form {
          width: 100%;
        }
      }

      .Field {
        margin: 0;
        padding: 0;

        @media (max-width: 1200px) {
          padding: 0;
        }

        .SingleDatePickerInput_clearDate {
          display: none;
        }

        .SingleDatePickerInput__showClearDate {
          padding: 0;
        }

        .SingleDatePicker .DateInput_1 {
          width: 15ch;

          .DateInput_input:hover {
            border: none;
          }
        }
      }
    }
  `}
`;

export const DateLabel = styled.h4`
  ${({ theme }) => css`
    margin-top: 0;
    font-family: 'Quicksand', sans-serif;
    color: ${theme.black};
    font-weight: 700;
  `}
`;

export const FooterContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 36px;
    padding: 16px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    margin-bottom: 0px;
    border-top: 1px solid ${theme.gray3};
    background-color: ${theme.white};

    .Button {
      padding: 12px 24px;
      font-family: 'Quicksand', sans-serif;
    }
  `}
`;
