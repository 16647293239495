import styled, { css } from 'styled-components';

export const EditInBatchAccordionWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;

    .Accordion {
      .accordion__button {
        height: 62px;
        border: 1px solid ${theme.gray12};
        border-radius: 8px;
        background-color: ${theme.white};

        &:before {
          color: ${theme.black};
        }

        &[aria-expanded='true'] {
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          background-color: ${theme.gray14};
        }
      }

      .accordion__panel {
        background-color: ${theme.gray14};
      }
    }
  `}
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const HeaderText = styled.span`
  ${({ theme }) => css`
    font-family: 'Quicksand', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: ${theme.black};
  `}
`;

export const InfoDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 32px;
  padding-bottom: 8px;
`;

export const LineDetailsWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;

    > hr {
      display: flex;
      width: 100%;
      color: ${theme.black};
    }

    &:last-child {
      > hr {
        display: none;
      }
    }
  `}
`;

export const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const LabelText = styled.strong`
  ${({ theme }) => css`
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: ${theme.gray2};
  `}
`;
