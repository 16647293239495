import React, { useCallback, useMemo } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, Button, Text } from '@agendaedu/ae-web-components';

import { normalizeFileSize } from 'core/utils/formatBytes';

import StorageActions from 'store/storage/actions';
import { StorageRoot } from 'store/storage/types';
import { normalizeFileType } from 'store/storage/normalizes';

import * as S from './styles';

import SideSheet from 'components/SideSheet';
import useWindowDimensions from 'core/hooks/useWindowDimensions';

const DetailsModal: React.FC = (): React.ReactElement => {
  const dispatch = useDispatch();

  const { t } = useTranslation('storage');

  const { screenHeight } = useWindowDimensions();

  const tBase = useCallback(
    (key: string, params?: Record<string, string | number>) =>
      t(`details_modal.${key}`, params),
    [t]
  );

  const { toggleShowDetaisModal } = StorageActions;

  const {
    storageList: { showDetailsModal },
    common: { selectedStorage },
  } = useSelector((state: StorageRoot) => state.storage);

  const dismissShowDetailsModal = useCallback(() => {
    dispatch(toggleShowDetaisModal());
  }, [dispatch, toggleShowDetaisModal]);

  const storageKind = selectedStorage.attributes.kind;

  const file =
    selectedStorage.attributes.attachments?.length &&
    selectedStorage.attributes.attachments[0];

  const getStorageVersions = useMemo(() => {
    const dateFormatMask = `
  DD/MM/YYYY [${tBase('date_as')}] HH:mm
`;

    const createdMessage = tBase('date_full', {
      date: moment(selectedStorage?.attributes.createdAt).format(
        dateFormatMask
      ),
      user: selectedStorage.attributes.versions?.find(
        (version) => version.attributes.event === 'Cadastro'
      ).attributes.name,
    });

    const updatedMessage =
      selectedStorage.attributes.versions[0]?.attributes.event !== 'Cadastro' &&
      tBase('date_full', {
        date: moment(selectedStorage?.attributes.updatedAt).format(
          dateFormatMask
        ),
        user: selectedStorage.attributes.versions[0]?.attributes.name,
      });

    return { createdMessage, updatedMessage };
  }, [selectedStorage, tBase]);

  const recipients = {
    classroom: selectedStorage.attributes.classrooms
      .map((item) => item.name)
      .join(', '),
    student: selectedStorage.attributes.studentProfiles
      .map((item) => item.name)
      .join(', '),
  };

  return (
    <S.Wrapper>
      <SideSheet
        isOpen={showDetailsModal}
        onDismiss={dismissShowDetailsModal}
        title={tBase(`${selectedStorage.attributes.kind}_title`)}
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          height={`${screenHeight - 60}px`}
        >
          <S.DetailsWrapper>
            <Box display="flex" flexDirection="column">
              <Text variant="subtitle-medium-14" color="neutral.black">
                {tBase('title')}
              </Text>
              <S.Label
                variant="body-regular-14"
                color="neutral.gray1"
                lineHeight="lg"
              >
                {selectedStorage.attributes.title}
              </S.Label>
            </Box>

            {storageKind === 'file' && file && (
              <Box display="flex" flexDirection="column">
                <Text variant="subtitle-medium-14" color="neutral.black">
                  {tBase('file_format')}
                </Text>
                <Text variant="body-regular-14" color="neutral.gray1">
                  {normalizeFileType(file.type)}
                </Text>
              </Box>
            )}

            {storageKind === 'file' && file && (
              <Box display="flex" flexDirection="column">
                <Text variant="subtitle-medium-14" color="neutral.black">
                  {tBase('file_size')}
                </Text>
                <Text variant="body-regular-14" color="neutral.gray1">
                  {normalizeFileSize(file.size)}
                </Text>
              </Box>
            )}

            {storageKind === 'folder' &&
              selectedStorage?.attributes?.description && (
                <Box display="flex" flexDirection="column">
                  <Text variant="subtitle-medium-14" color="neutral.black">
                    {tBase('description')}
                  </Text>
                  <S.Label
                    variant="body-regular-14"
                    color="neutral.gray1"
                    lineHeight="lg"
                  >
                    {selectedStorage.attributes.description}
                  </S.Label>
                </Box>
              )}

            {getStorageVersions.createdMessage && (
              <Box display="flex" flexDirection="column">
                <Text variant="subtitle-medium-14" color="neutral.black">
                  {tBase('created_at_date')}
                </Text>
                <Text variant="body-regular-14" color="neutral.gray1">
                  {getStorageVersions.createdMessage}
                </Text>
              </Box>
            )}

            {getStorageVersions.updatedMessage && (
              <Box display="flex" flexDirection="column">
                <Text variant="subtitle-medium-14" color="neutral.black">
                  {tBase('updated_at_date')}
                </Text>
                <Text variant="body-regular-14" color="neutral.gray1">
                  {getStorageVersions.updatedMessage}
                </Text>
              </Box>
            )}

            <Box display="flex" flexDirection="column">
              <Text variant="subtitle-medium-14" color="neutral.black">
                {tBase('avaliable_to')}
              </Text>
              <Text variant="body-regular-14" color="neutral.gray1">
                {tBase(
                  `recipients_sent_to.${selectedStorage.attributes.availableTo}`
                )}
              </Text>
            </Box>

            <Box display="flex" flexDirection="column">
              <Text variant="subtitle-medium-14" color="neutral.black">
                {tBase('destinations')}
              </Text>
              <Text
                variant="body-regular-14"
                color="neutral.gray1"
                lineHeight="lg"
              >
                {recipients[selectedStorage.attributes.receiverType]}
              </Text>
            </Box>
          </S.DetailsWrapper>

          <Box display="flex" margin="lg" mb="xl2">
            <Button
              width="100%"
              variant="secondary"
              onClick={dismissShowDetailsModal}
            >
              {tBase('cancel_button_label')}
            </Button>
          </Box>
        </Box>
      </SideSheet>
    </S.Wrapper>
  );
};

export default DetailsModal;
