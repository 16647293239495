import styled from 'styled-components';

export const CheckboxGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 624px;
`;

export const CheckboxGroupContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-width: 100%;

  @media (max-width: 600px) {
    flex-direction: column;
    position: relative;
  }
`;

export const CheckboxGroupTitle = styled.label`
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: ${({ theme }) => theme.gray1};
  margin-bottom: 10px;
`;
