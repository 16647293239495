import React, { useCallback } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import {
  MESSAGE_SEARCH_CHANNEL_FAMILY_CHAT_TABS,
  MESSAGE_SEARCH_CHAT_TABS,
} from 'core/constants';
import useWindowDimensions from 'core/hooks/useWindowDimensions';

import { ChatUser, OmniChannelState } from 'store/messages/omniChannel/types';
import omniChannelActions from 'store/messages/omniChannel/actions';

import * as S from './styles';

import { ChatCard } from '../ChatCard';
import { ChatCardSkeleton } from '../ChatCard/skeleton';
import { ChatDetails } from '../ChatCard/types';
import { EmptyState } from './emptyState';

export const ChatSearchList = (): JSX.Element => {
  const dispatch = useDispatch();
  const { screenHeight } = useWindowDimensions();

  const {
    activeChannel,
    chatFilters,
    chatSearchCurrentTab,
    chatSearchPagination: { currentPage, nextPage },
    chatSearchUsers,
    isLoadingSearchTab,
  } = useSelector((state: OmniChannelState) => state.omniChannel);

  const CURRENT_TAB =
    activeChannel?.kind === 'family'
      ? MESSAGE_SEARCH_CHANNEL_FAMILY_CHAT_TABS
      : MESSAGE_SEARCH_CHAT_TABS;

  const { fetchChatSearchUsersRequest } = omniChannelActions;

  const getChatDetails = (chat: ChatUser): ChatDetails => {
    return {
      classroomsName: chat.attributes?.classroom_names,
      kind: chat.attributes?.kind,
      name: chat.attributes?.name,
      roleMessage: chat.attributes?.role,
      avatarColor: chat.attributes.avatar_color,
      avatarUrl: chat.attributes.avatar_url,
      nameInitials: chat.attributes.name_initials,
    };
  };

  const hasMoreChats = (): boolean => {
    return nextPage !== null;
  };

  const fetchMoreUsers = useCallback(() => {
    const params = {
      tabName: CURRENT_TAB[chatSearchCurrentTab].tabName,
      channelId: activeChannel.id,
      filters: chatFilters,
      page: currentPage + 1,
    };

    dispatch(fetchChatSearchUsersRequest(params));
  }, [
    dispatch,
    activeChannel,
    chatSearchCurrentTab,
    fetchChatSearchUsersRequest,
    chatFilters,
    currentPage,
    CURRENT_TAB,
  ]);

  return (
    <S.Wrapper id="chat-content-wrapper" height={screenHeight}>
      {chatSearchUsers && !isLoadingSearchTab ? (
        chatSearchUsers.length > 0 ? (
          <InfiniteScroll
            dataLength={chatSearchUsers.length}
            next={fetchMoreUsers}
            hasMore={hasMoreChats()}
            loader={<ChatCardSkeleton amount={1} />}
            scrollableTarget="chat-content-wrapper"
          >
            {chatSearchUsers?.map((chatUser, index) => (
              <ChatCard
                key={index}
                chatId={chatUser.attributes.chat_id}
                chatUserId={chatUser.id}
                chatDetails={getChatDetails(chatUser)}
              />
            ))}
          </InfiniteScroll>
        ) : (
          <EmptyState />
        )
      ) : (
        <ChatCardSkeleton amount={3} />
      )}
    </S.Wrapper>
  );
};
