import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/Button';
import AgendaIcon from 'components/AgendaIcon';

import * as S from './styles';

const QuantityCard = ({
  label,
  onDecrement,
  onIncrement,
  quantity,
  disabled,
}) => {
  return (
    <S.QuantityContainer>
      <S.Label>{label}</S.Label>
      <S.QuantityGroup>
        <Button
          disabled={disabled || !quantity}
          variation="secondary"
          onClick={onDecrement}
          id="decrement"
        >
          <AgendaIcon name="dash" size="small" />
        </Button>
        <S.QuantityNumber data-testid="quantity">{quantity}</S.QuantityNumber>
        <Button
          disabled={disabled}
          variation="secondary"
          onClick={onIncrement}
          id="increment"
        >
          <AgendaIcon name="plus" size="small" />
        </Button>
      </S.QuantityGroup>
    </S.QuantityContainer>
  );
};

QuantityCard.propTypes = {
  label: PropTypes.string.isRequired,
  onDecrement: PropTypes.func.isRequired,
  onIncrement: PropTypes.func.isRequired,
  quantity: PropTypes.number.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default QuantityCard;
