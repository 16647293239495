import moment from 'moment';

import validateTextAreaPresence from 'core/lib/FormValidator/validators/textAreaPresence';
import validatePresence from 'core/lib/FormValidator/validators/presence';
import validateLength from 'core/lib/FormValidator/validators/length';
import validateDate from 'core/lib/FormValidator/validators/date';
import validateTime from 'core/lib/FormValidator/validators/time';

function validatePresenceConfirmUntil(input, state) {
  if (state.kind !== 'attendance') {
    return true;
  }

  return validatePresence(input);
}

function validateDateConfirmUntil(input, state) {
  if (state.kind !== 'attendance') {
    return true;
  }
  if (!state.date) {
    return true;
  }

  const date = moment(state.date);

  date.add(1, 'days');

  return validateDate({ before: date.format('YYYY-MM-DD') })(input);
}

function validateEndAt(input, state) {
  if (!state.start_at) {
    return true;
  }
  if (!input) {
    return true;
  }

  return validateTime({ after: state.start_at })(input);
}

export default [
  {
    attributeName: 'title',
    validator: validatePresence,
    message: 'Campo obrigatório',
  },
  {
    attributeName: 'title',
    validator: validateLength({ max: 100 }),
    message: 'Máximo: 100 caracteres',
  },
  {
    attributeName: 'description',
    validator: validateTextAreaPresence,
    message: 'Campo obrigatório',
  },
  {
    attributeName: 'date',
    validator: validatePresence,
    message: 'Campo obrigatório',
  },
  {
    attributeName: 'start_at',
    validator: validatePresence,
    message: 'Campo obrigatório',
  },
  {
    attributeName: 'end_at',
    validator: validateEndAt,
    message: 'Não pode ser inferior ou igual ao horário de início',
  },
  {
    attributeName: 'location',
    validator: validateLength({ max: 100 }),
    message: 'Máximo: 100 caracteres',
  },
  {
    attributeName: 'confirm_until',
    validator: validatePresenceConfirmUntil,
    message: 'Campo obrigatório',
  },
  {
    attributeName: 'confirm_until',
    validator: validateDateConfirmUntil,
    message: 'Não pode ser posterior a data do evento',
  },
];
