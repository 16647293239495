import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// Import translations
import ptBRTranslation from './locales/pt-BR/pt-br.json';
import deutschTranslation from './locales/de/de.json';

const resources = {
  'pt-BR': ptBRTranslation,
  de: deutschTranslation,
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    fallbackLng: 'pt-BR',
    resources,
    interpolation: {
      escapeValue: false,
    },
  });

export const changeLanguage = async (language: string): Promise<void> => {
  await i18n.changeLanguage(language);
};

export const getCurrentLanguage = (): string => {
  return i18n.languages[0];
};

export const getSupportLanguage = (): {
  key: string;
  value: string;
  label: string;
}[] => {
  return [
    { key: '0', value: 'pt-BR', label: 'Português' },
    { key: '1', value: 'de', label: 'Alemão' },
  ];
};

export default i18n;
