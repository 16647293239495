import { isAfterHours } from 'core/helper/message/omni/attendingHours';
import { AttendingHours } from 'store/messages/omniChannel/types';

export default function attending(attendingHours: AttendingHours): boolean {
  if (!attendingHours) return true;

  return !Object.values(attendingHours.days).some((day) => {
    const startHours = day.hours[0];
    const endHours = day.hours[1];

    if (!day.active) return false;

    if (!startHours || !endHours) return true;

    return (
      isAfterHours(day.hours) ||
      day.hours.length !== 2 ||
      startHours?.length < 5 ||
      endHours?.length < 5
    );
  });
}
