import React from 'react';
import PropTypes from 'prop-types';

import CurrencyField from 'components/Form/CurrencyField';
import Field from 'components/Form/Field';
import PercentField from 'components/Form/PercentField';

import withFormContext, { formPropTypes } from 'core/hoc/withFormContext';
import * as S from './styles';

const TextFieldWithSelect = ({
  attributeName,
  controlled,
  defaultValue,
  defaultSelectValue,
  disabled,
  formContext,
  onChange,
  onChangeSelect,
  selectAttributeName,
}) => {
  const { form, formMeta } = formContext;
  const { select_options } = formMeta;

  const getValue = () => {
    if (controlled) {
      return defaultValue;
    }

    return formContext.form[attributeName];
  };

  const renderInput = {
    money: () => (
      <CurrencyField
        controlled
        attributeName={attributeName}
        onChange={() => onChange(form)}
        defaultValue={getValue()}
        disabled={disabled}
      />
    ),
    percent: () => (
      <PercentField
        controlled
        attributeName={attributeName}
        onChange={() => onChange(form)}
        defaultValue={getValue()}
        disabled={disabled}
      />
    ),
  };

  const formatedOptions = select_options[selectAttributeName].map((option) => {
    return {
      key: `${attributeName}-${option.value}`,
      label: option.name,
      value: option.value,
    };
  });

  const getDefaultTypeDiscount = () => {
    const typeFounded = formatedOptions.find(
      (discount) => discount.value === defaultSelectValue
    );

    return typeFounded.value;
  };

  return (
    <S.TextFiledWithSelectWrapper>
      <Field
        className="SelectDiscountType"
        attributeName="selectDiscountType"
        type="selectWithIcon"
        value={getDefaultTypeDiscount()}
        options={formatedOptions}
        onChange={(e) =>
          onChangeSelect({ ...form, [selectAttributeName]: e.value })
        }
        disabled={disabled}
        fullWidth
        withArrowSelect
        controll
      />
      {renderInput[defaultSelectValue]()}
    </S.TextFiledWithSelectWrapper>
  );
};

TextFieldWithSelect.propTypes = {
  controlled: PropTypes.bool,
  defaultValue: PropTypes.number.isRequired,
  defaultSelectValue: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onChangeSelect: PropTypes.func.isRequired,
  selectAttributeName: PropTypes.string.isRequired,
  attributeName: PropTypes.string.isRequired,
  ...formPropTypes,
};

TextFieldWithSelect.defaultProps = {
  controlled: false,
};

export default withFormContext(TextFieldWithSelect);
