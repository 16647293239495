import createModuleReducer from '../storeConfig/createModuleReducer';

import {
  HandoutReducerProps,
  HandoutProps,
  ResultsActionDefault,
  ResultHandoutsAction,
  ResultCategoriesAction,
  ResultCategoryAction,
  ClassroomsAction,
  ResultHandoutRequestAction,
  FetchCategoriesSagaProps,
  CategoryDetailsSagaProps,
  ResultEngagementRequestAction,
  EngagementData,
  FetchHandoutsRequestParams,
  ResultDuplicateHandoutAttachmentsSuccessAction,
  ResultHandoutTemplatesRequestAction,
  ResultSetCurrentHandoutTemplateAction,
  ResultApplyHandoutTemplateAction,
  ResultCurrentHandoutTemplateAction,
} from './types';
import actions from './actions';

export const initialStateForm = () => ({
  id: '',
  sendTo: 'both',
  title: '',
  description: '',
  coverImage: null,
  categoryId: '',
  categoryName: '',
  directUploadAttachments: [],
  sent_kind: 'classrooms',
  persona_type: 'both',
  classroom_ids: [],
  classroom_with_student_profile_ids: {},
  createdAt: null,
  updatedAt: null,
  deletedAt: null,
  sendAt: null,
  scheduleDate: null,
  unapprovalReason: '',
  classrooms: [],
  versions: [],
  attachments: null,
  isLoadingDuplicateHandout: false,
  userPermissions: {
    edit: false,
    delete: false,
    approve: false,
    schedule: false,
    approveSchedule: false,
    cancelSchedule: false,
    reschedule: false,
    reject: false,
    resend: false,
    updateClassrooms: false,
  },
  status: 'pending',
});

export const INITIAL_STATE: HandoutReducerProps = {
  error: null,
  handouts: [],
  handoutCategories: {
    categories: [],
    totalPages: 1,
  },
  pagination: {
    page: 1,
    itemsPerPage: 10,
  },
  handoutTemplates: {
    templates: null,
    page: 1,
    next: null,
  },
  filters: {
    startDate: null,
    endDate: null,
    situation: null,
    title: null,
    headquarterId: null,
    educationalStageId: null,
    classroomId: null,
    categoryId: null,
    activeSchoolTermId: null,
  },
  showApprovalModal: false,
  showDeleteModal: false,
  showScheduleApprovalModal: false,
  showHandoutTemplateModal: false,
  showCancelScheduleModal: false,
  showNewCategoryModal: false,
  showNotificationModal: false,
  showResendModal: false,
  showScheduleModal: false,
  showDeleteHandoutTemplateModal: false,
  showEditHandoutTemplateModal: false,
  showNewHandoutModal: false,
  activeFiltersCount: 0,
  isInitialLoading: true,
  isInitialFilters: true,
  isLoading: false,
  isMoreHandoutsLoading: false,
  isCategoryLoading: false,
  isLoadingMoreCategories: false,
  isEngagementLoading: false,
  isMoreEngagementLoading: false,
  isSending: false,
  isCancelScheduleSending: false,
  current: initialStateForm(),
  currentHandoutTemplate: null,
  engagements: {
    data: [],
    pagination: {
      page: 1,
      itemsPerPage: 10,
      totalPages: 1,
    },
    filters: {
      profile: null,
      status: null,
      classroomId: null,
    },
  },
  formMeta: {
    classrooms: [],
    canEditClassrooms: true,
    canEditKind: true,
  },
  meta: {
    total: 0,
    views: 0,
    confirm: 0,
    pendency: 0,
  },
};

export const handlerError = (
  state: HandoutReducerProps,
  action: ResultsActionDefault
) => ({
  ...state,
  error: action.error,
  isInitialLoading: false,
  isLoading: false,
  isMoreHandoutsLoading: false,
  isCategoryLoading: false,
  isSending: false,
});

export const createNewHandoutRequest = (state: HandoutReducerProps) => ({
  ...state,
  isSending: true,
});

export const createNewHandoutSuccess = (state: HandoutReducerProps) => {
  return {
    ...state,
    isSending: false,
  };
};

export const createNewHandoutTemplateRequest = (
  state: HandoutReducerProps
) => ({
  ...state,
  isSending: true,
});

export const createNewHandoutTemplateSuccess = (state: HandoutReducerProps) => {
  return {
    ...state,
    isSending: false,
  };
};

export const createNewCategoryRequest = (state: HandoutReducerProps) => ({
  ...state,
  isCategoryLoading: true,
});

export const createNewCategorySuccess = (
  state: HandoutReducerProps,
  action: ResultCategoryAction
) => {
  const formattedCategory = {
    id: action.newCategoryCreated.id,
    label: action.newCategoryCreated.attributes.name,
    value: action.newCategoryCreated.id,
  };

  const updatedCategoriesList = [
    formattedCategory,
    ...state.handoutCategories.categories,
  ];

  return {
    ...state,
    handoutCategories: {
      categories: updatedCategoriesList,
    },
    isCategoryLoading: false,
  };
};

export const editCategoryRequest = (state: HandoutReducerProps) => ({
  ...state,
  isCategoryLoading: true,
});

export const editCategorySuccess = (state: HandoutReducerProps) => ({
  ...state,
  isCategoryLoading: false,
});

export const approveHandoutRequest = (state: HandoutReducerProps) => ({
  ...state,
  isSending: true,
});

export const approveHandoutSuccess = (
  state: HandoutReducerProps,
  action: ResultHandoutRequestAction
) => {
  return {
    ...state,
    current: {
      ...state.current,
      status:
        action.data.attributes.status === 'sent'
          ? action.data.attributes.jobStatus
          : action.data.attributes.status,
    },
    isSending: false,
  };
};

export const approveScheduleHandoutRequest = (state: HandoutReducerProps) => ({
  ...state,
  isSending: true,
});

export const approveScheduleHandoutSuccess = (
  state: HandoutReducerProps,
  action: ResultHandoutRequestAction
) => {
  return {
    ...state,
    current: {
      ...state.current,
      status: action.data.attributes.status,
      userPermissions: {
        ...action.data.attributes.userPermissions,
      },
      versions: action.data.attributes.versions,
    },
    isSending: false,
  };
};

export const cancelScheduleHandoutRequest = (state: HandoutReducerProps) => ({
  ...state,
  isCancelScheduleSending: true,
});

export const cancelScheduleHandoutSuccess = (
  state: HandoutReducerProps,
  action: ResultHandoutRequestAction
) => {
  return {
    ...state,
    current: {
      ...state.current,
      status: action.data.attributes.status,
      userPermissions: {
        ...action.data.attributes.userPermissions,
      },
      versions: action.data.attributes.versions,
    },
    showCancelScheduleModal: false,
    isCancelScheduleSending: false,
  };
};

export const duplicateHandoutAttachmentsRequest = (
  state: HandoutReducerProps
) => {
  return {
    ...state,
    isLoadingDuplicateHandout: true,
  };
};

export const duplicateHandoutAttachmentsSuccess = (
  state: HandoutReducerProps,
  action: ResultDuplicateHandoutAttachmentsSuccessAction
) => {
  const newAttachments = [];

  for (let index = 0; index < action.attachments.length; index++) {
    newAttachments.push({
      ...state.current.directUploadAttachments[index],
      signed_id: action.attachments[index].signedId,
      url: action.attachments[index].url,
      duplicated: true,
    });
  }

  return {
    ...state,
    current: {
      ...state.current,
      directUploadAttachments: newAttachments,
    },
    isLoadingDuplicateHandout: false,
  };
};

export const scheduleHandoutRequest = (state: HandoutReducerProps) => ({
  ...state,
  isSending: true,
});

export const scheduleHandoutSuccess = (
  state: HandoutReducerProps,
  action: ResultHandoutRequestAction
) => {
  return {
    ...state,
    current: {
      ...state.current,
      status: action.data.attributes.status,
      userPermissions: {
        ...action.data.attributes.userPermissions,
      },
      versions: action.data.attributes.versions,
      scheduleDate: action.data.attributes.scheduleDate,
    },
    isSending: false,
  };
};

export const deleteHandoutRequest = (state: HandoutReducerProps) => ({
  ...state,
  isSending: true,
});

export const deleteHandoutSuccess = (state: HandoutReducerProps) => ({
  ...state,
  isSending: false,
});

export const deleteHandoutTemplateRequest = (state: HandoutReducerProps) => ({
  ...state,
});

export const deleteHandoutTemplateSuccess = (state: HandoutReducerProps) => {
  const newHandoutTemplateList = state.handoutTemplates?.templates?.filter(
    (template) => template.id !== state.currentHandoutTemplate?.id
  );

  return {
    ...state,
    handoutTemplates: {
      ...state.handoutTemplates,
      templates: newHandoutTemplateList,
    },
    currentHandoutTemplate: null,
  };
};

export const resendHandoutRequest = (state: HandoutReducerProps) => ({
  ...state,
  isSending: true,
});

export const resendHandoutSuccess = (state: HandoutReducerProps) => ({
  ...state,
  current: {
    ...state.current,
  },
  isSending: false,
});

export const fetchCategoriesRequest = (state: HandoutReducerProps) => ({
  ...state,
  isCategoryLoading: true,
});

export const fetchCategoriesSuccess = (
  state: HandoutReducerProps,
  action: ResultCategoriesAction
) => {
  const formattedCategories = action.categories.map((category) => ({
    id: category.id,
    label: category.attributes.name,
    value: category.id,
  }));

  const newCategoriesList = action?.page
    ? [...state.handoutCategories.categories, ...formattedCategories]
    : formattedCategories;

  return {
    ...state,
    handoutCategories: {
      categories: newCategoriesList,
      totalPages: action.totalPages,
    },
    pagination: {
      ...state.pagination,
      currentPage: action?.page,
    },
    isCategoryLoading: false,
  };
};

export const fetchCurrentHandoutTemplateRequest = (
  state: HandoutReducerProps
) => ({
  ...state,
  currentHandoutTemplate: null,
});

export const fetchCurrentHandoutTemplateSuccess = (
  state: HandoutReducerProps,
  action: ResultCurrentHandoutTemplateAction
) => ({
  ...state,
  currentHandoutTemplate: action.data,
});

export const fetchMoreCategoriesRequest = (
  state: HandoutReducerProps,
  action: FetchCategoriesSagaProps
) => ({
  ...state,
  isLoadingMoreCategories: true,
  isCategoryLoading: !action?.page,
  pagination: {
    ...state.pagination,
    page: action?.page || state.pagination.page,
  },
});

export const fetchMoreCategoriesSuccess = (
  state: HandoutReducerProps,
  { categories, page, totalPages }: ResultCategoriesAction
) => {
  const formattedCategories = categories.map((category) => ({
    id: category.id,
    label: category.attributes.name,
    value: category.id,
  }));

  const handoutCategories =
    page === 1
      ? [...formattedCategories]
      : [...state.handoutCategories.categories, ...formattedCategories];

  return {
    ...state,
    isLoadingMoreCategories: false,
    isCategoryLoading: false,
    pagination: {
      ...state.pagination,
      page: page || state.pagination.page,
    },
    handoutCategories: {
      ...state.handoutCategories,
      totalPages,
      categories: handoutCategories,
    },
  };
};

export const fetchHandoutsRequest = (
  state: HandoutReducerProps,
  action: { params: FetchHandoutsRequestParams }
) => {
  const disableInitialFilters =
    !!state.isInitialFilters &&
    !!state.filters.activeSchoolTermId &&
    action.params.activeSchoolTermId !== state.filters.activeSchoolTermId;

  return {
    ...state,
    isLoading: true,
    isInitialFilters: !!state.isInitialFilters && !disableInitialFilters,
    handoutTemplates: {
      templates: null,
      next: null,
      page: 1,
    },
  };
};

export const fetchHandoutsSuccess = (
  state: HandoutReducerProps,
  action: ResultHandoutsAction
) => {
  const updatedHandoutList: HandoutProps[] = [...action.handouts];

  let amountFilter = 0;

  const sideFilters = [
    action.filters.headquarterId,
    action.filters.educationalStageId,
    action.filters.classroomId,
    action.filters.categoryId,
  ];

  if (!state.isInitialFilters)
    sideFilters.push(action.filters.activeSchoolTermId);

  sideFilters.forEach((currentFilter) => {
    if (currentFilter) amountFilter += 1;
  });

  return {
    ...state,
    handouts: updatedHandoutList,
    pagination: {
      ...state.pagination,
      page: action.pagination.page,
      totalPages: action.pagination.totalPages,
    },
    isLoading: false,
    filters: {
      ...state.filters,
      ...action.filters,
    },
    engagements: {
      ...state.engagements,
      filters: {
        ...state.engagements.filters,
        profile: null,
        status: null,
        classroomId: null,
      },
    },
    activeFiltersCount: amountFilter,
    isInitialLoading: false,
  };
};

export const fetchMoreHandoutsRequest = (state: HandoutReducerProps) => ({
  ...state,
  isMoreHandoutsLoading: true,
});

export const fetchMoreHandoutsSuccess = (
  state: HandoutReducerProps,
  action: ResultHandoutsAction
) => {
  const updatedHandoutList: HandoutProps[] = [
    ...state.handouts,
    ...action.handouts,
  ];

  let amountFilter = 0;

  const sideFilters = [
    action.filters.headquarterId,
    action.filters.educationalStageId,
    action.filters.classroomId,
    action.filters.categoryId,
  ];

  if (!state.isInitialFilters)
    sideFilters.push(action.filters.activeSchoolTermId);

  sideFilters.forEach((currentFilter) => {
    if (currentFilter) amountFilter += 1;
  });

  return {
    ...state,
    handouts: updatedHandoutList,
    pagination: {
      ...state.pagination,
      page: action.pagination.page,
    },
    isMoreHandoutsLoading: false,
    filters: {
      ...state.filters,
      ...action.filters,
    },
    engagements: {
      ...state.engagements,
      filters: {
        ...state.engagements.filters,
        profile: null,
        status: null,
        classroomId: null,
      },
    },
    activeFiltersCount: amountFilter,
  };
};

export const fetchHandoutRequest = (state: HandoutReducerProps) => ({
  ...state,
  isLoading: true,
});

export const fetchHandoutSuccess = (
  state: HandoutReducerProps,
  { data, meta }: ResultHandoutRequestAction
) => {
  const { attachments, sendTo, createdFor } = data.attributes;

  const { category, classrooms } = data.relationships;

  const classroomIds = classrooms.data.map(({ id }) => id);

  const directUploadAttachments = attachments.map((attachment) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { signedId, attachmentType } = attachment;

    return {
      ...attachment,
      signed_id: signedId,
      attachment_type: attachmentType,
    };
  });

  return {
    ...state,
    isLoading: false,
    current: {
      ...state.current,
      ...data.attributes,
      id: data.id,
      directUploadAttachments,
      categoryId: category.data?.id,
      classroom_ids: classroomIds,
      persona_type: sendTo,
      sent_kind: createdFor,
      student_profiles: data.student_profiles,
    },
    meta: {
      ...meta,
    },
  };
};

export const fetchHandoutEngagementRequest = (state: HandoutReducerProps) => ({
  ...state,
  isEngagementLoading: true,
});

export const fetchHandoutEngagementSuccess = (
  state: HandoutReducerProps,
  action: ResultEngagementRequestAction
) => {
  return {
    ...state,
    engagements: {
      ...state.engagements,
      data: action.data,
      pagination: {
        ...state.engagements.pagination,
        page: action.pagination.page,
        totalPages: action.pagination.totalPages,
      },
      filters: {
        ...state.engagements.filters,
        profile: action.filters.profile,
        status: action.filters.status,
        classroomId: action.filters.classroomId,
      },
    },
    isEngagementLoading: false,
  };
};

export const fetchHandoutTemplatesRequest = (state: HandoutReducerProps) => ({
  ...state,
});

export const fetchHandoutTemplatesSuccess = (
  state: HandoutReducerProps,
  action: ResultHandoutTemplatesRequestAction
) => {
  const { data, page, next } = action;

  const templates =
    page === 1 ? data : [...state.handoutTemplates.templates, ...data];

  return {
    ...state,
    handoutTemplates: {
      templates,
      next: next,
      page: page,
    },
  };
};

export const fetchMoreHandoutEngagementRequest = (
  state: HandoutReducerProps
) => ({
  ...state,
  isMoreEngagementLoading: true,
});

export const fetchMoreHandoutEngagementSuccess = (
  state: HandoutReducerProps,
  action: ResultEngagementRequestAction
) => {
  const updatedEngegementList: EngagementData[] = [
    ...state.engagements.data,
    ...action.data,
  ];

  return {
    ...state,
    engagements: {
      ...state.engagements,
      data: updatedEngegementList,
      pagination: {
        ...state.engagements.pagination,
        page: action.pagination.page,
        totalPages: action.pagination.totalPages,
      },
      filters: {
        ...state.engagements.filters,
        profile: action.filters.profile,
        status: action.filters.status,
        classroomId: action.filters.classroomId,
      },
    },
    isMoreEngagementLoading: false,
  };
};

export const editHandoutRequest = (state: HandoutReducerProps) => ({
  ...state,
  isSending: true,
});

export const editHandoutSuccess = (state: HandoutReducerProps) => ({
  ...state,
  isSending: false,
});

export const editHandoutTemplateRequest = (state: HandoutReducerProps) => ({
  ...state,
  isSending: true,
  showEditHandoutTemplateModal: false,
});

export const editHandoutTemplateSuccess = (state: HandoutReducerProps) => ({
  ...state,
  isSending: false,
});

export const fetchClassroomsRequest = (state: HandoutReducerProps) => ({
  ...state,
  isLoading: true,
  formMeta: {
    ...state.formMeta,
    classrooms: [],
  },
});

export const fetchClassroomsSuccess = (
  state: HandoutReducerProps,
  action: ClassroomsAction
) => {
  const formattedClassrooms = action.classrooms.map((classroom) => ({
    ...classroom,
    options: classroom.options.map((option) => ({
      ...option,
      label: option.attributes.name,
      value: option.id,
    })),
  }));

  return {
    ...state,
    isLoading: false,
    formMeta: {
      ...state.formMeta,
      classrooms: formattedClassrooms,
    },
  };
};

export const fetchCategoryDetailsRequest = (state: HandoutReducerProps) => ({
  ...state,
  isLoading: true,
});

export const fetchCategoryDetailsSuccess = (
  state: HandoutReducerProps,
  action: CategoryDetailsSagaProps
) => {
  const {
    id,
    attributes: { name },
  } = action.category;

  return {
    ...state,
    isLoading: false,
    current: {
      ...state.current,
      categoryId: id,
      categoryName: name,
    },
  };
};

export const applyHandoutTemplateRequest = (state: HandoutReducerProps) => ({
  ...state,
  current: null,
});

export const applyHandoutTemplateSuccess = (
  state: HandoutReducerProps,
  action: ResultApplyHandoutTemplateAction
) => {
  const initialHandout = initialStateForm();
  const {
    handoutTitle: title,
    categoryId,
    categoryName,
    coverImage,
    description,
  } = action?.data.attributes;

  return {
    ...state,
    current: {
      ...initialHandout,
      templateId: action.data.id,
      title,
      categoryId,
      categoryName,
      description,
      coverImage,
    },
  };
};

export const deleteCategoryRequest = (state: HandoutReducerProps) => ({
  ...state,
  isLoading: true,
  isCategoryLoading: true,
  handoutCategories: {
    ...state.handoutCategories,
    categories: [],
  },
});

export const deleteCategorySuccess = (state: HandoutReducerProps) => ({
  ...state,
  isLoading: false,
  isCategoryLoading: false,
});

export const resendMassHandoutsRequest = (state: HandoutReducerProps) => ({
  ...state,
  isSending: true,
});

export const resendMassHandoutSuccess = (state: HandoutReducerProps) => ({
  ...state,
  isSending: false,
});

export const toggleApprovalModal = (state: HandoutReducerProps) => ({
  ...state,
  showApprovalModal: !state.showApprovalModal,
});

export const toggleDeleteModal = (state: HandoutReducerProps) => ({
  ...state,
  showDeleteModal: !state.showDeleteModal,
});

export const toggleEditHandoutTemplateModal = (state: HandoutReducerProps) => ({
  ...state,
  showEditHandoutTemplateModal: !state.showEditHandoutTemplateModal,
});

export const toggleScheduleModal = (state: HandoutReducerProps) => ({
  ...state,
  showScheduleModal: !state.showScheduleModal,
});

export const toggleScheduleApprovalModal = (state: HandoutReducerProps) => ({
  ...state,
  showScheduleApprovalModal: !state.showScheduleApprovalModal,
});

export const toggleCancelScheduleModal = (state: HandoutReducerProps) => ({
  ...state,
  showCancelScheduleModal: !state.showCancelScheduleModal,
});

export const toggleHandoutTemplateModal = (state: HandoutReducerProps) => ({
  ...state,
  showHandoutTemplateModal: !state.showHandoutTemplateModal,
});

export const toggleDeleteHandoutTemplateModal = (
  state: HandoutReducerProps
) => ({
  ...state,
  showDeleteHandoutTemplateModal: !state.showDeleteHandoutTemplateModal,
});

export const toggleNewCategoryModal = (state: HandoutReducerProps) => ({
  ...state,
  showNewCategoryModal: !state.showNewCategoryModal,
});

export const toggleNewHandoutModal = (state: HandoutReducerProps) => ({
  ...state,
  showNewHandoutModal: !state.showNewHandoutModal,
});

export const toggleNotificationModal = (state: HandoutReducerProps) => ({
  ...state,
  showNotificationModal: !state.showNotificationModal,
});

export const toggleResendModal = (state: HandoutReducerProps) => ({
  ...state,
  showResendModal: !state.showResendModal,
});

export const resetHandoutForm = (state: HandoutReducerProps) => ({
  ...state,
  current: initialStateForm(),
});

export const resetInitialFilters = (state: HandoutReducerProps) => ({
  ...state,
  isInitialFilters: true,
  filters: {
    ...state.filters,
    activeSchoolTermId: null,
  },
});

export const setCurrentHandoutTemplate = (
  state: HandoutReducerProps,
  action: ResultSetCurrentHandoutTemplateAction
) => ({
  ...state,
  currentHandoutTemplate: action.handoutTemplate,
});

const HANDLERS = {
  [actions.ERROR]: handlerError,
  [actions.CREATE_NEW_HANDOUT_REQUEST]: createNewHandoutRequest,
  [actions.CREATE_NEW_HANDOUT_SUCCESS]: createNewHandoutSuccess,
  [actions.CREATE_NEW_HANDOUT_TEMPLATE_REQUEST]: createNewHandoutRequest,
  [actions.CREATE_NEW_HANDOUT_TEMPLATE_SUCCESS]: createNewHandoutSuccess,
  [actions.CREATE_NEW_CATEGORY_REQUEST]: createNewCategoryRequest,
  [actions.CREATE_NEW_CATEGORY_SUCCESS]: createNewCategorySuccess,
  [actions.APPROVE_HANDOUT_REQUEST]: approveHandoutRequest,
  [actions.APPROVE_HANDOUT_SUCCESS]: approveHandoutSuccess,
  [actions.APPROVE_SCHEDULE_HANDOUT_REQUEST]: approveScheduleHandoutRequest,
  [actions.APPROVE_SCHEDULE_HANDOUT_SUCCESS]: approveScheduleHandoutSuccess,
  [actions.CANCEL_SCHEDULE_HANDOUT_REQUEST]: cancelScheduleHandoutRequest,
  [actions.CANCEL_SCHEDULE_HANDOUT_SUCCESS]: cancelScheduleHandoutSuccess,
  [actions.DUPLICATE_HANDOUT_ATTACHMENTS_REQUEST]:
    duplicateHandoutAttachmentsRequest,
  [actions.DUPLICATE_HANDOUT_ATTACHMENTS_SUCCESS]:
    duplicateHandoutAttachmentsSuccess,
  [actions.SCHEDULE_HANDOUT_REQUEST]: scheduleHandoutRequest,
  [actions.SCHEDULE_HANDOUT_SUCCESS]: scheduleHandoutSuccess,
  [actions.DELETE_HANDOUT_REQUEST]: deleteHandoutRequest,
  [actions.DELETE_HANDOUT_SUCCESS]: deleteHandoutSuccess,
  [actions.RESEND_HANDOUT_REQUEST]: resendHandoutRequest,
  [actions.RESEND_HANDOUT_SUCCESS]: resendHandoutSuccess,
  [actions.FETCH_CATEGORIES_REQUEST]: fetchCategoriesRequest,
  [actions.FETCH_CATEGORIES_SUCCESS]: fetchCategoriesSuccess,
  [actions.FETCH_CURRENT_HANDOUT_TEMPLATE_REQUEST]:
    fetchCurrentHandoutTemplateRequest,
  [actions.FETCH_CURRENT_HANDOUT_TEMPLATE_SUCCESS]:
    fetchCurrentHandoutTemplateSuccess,
  [actions.FETCH_MORE_CATEGORIES_REQUEST]: fetchMoreCategoriesRequest,
  [actions.FETCH_MORE_CATEGORIES_SUCCESS]: fetchMoreCategoriesSuccess,
  [actions.FETCH_HANDOUT_ENGAGEMENT_REQUEST]: fetchHandoutEngagementRequest,
  [actions.FETCH_HANDOUT_ENGAGEMENT_SUCCESS]: fetchHandoutEngagementSuccess,
  [actions.FETCH_HANDOUT_TEMPLATES_REQUEST]: fetchHandoutTemplatesRequest,
  [actions.FETCH_HANDOUT_TEMPLATES_SUCCESS]: fetchHandoutTemplatesSuccess,
  [actions.FETCH_MORE_HANDOUT_ENGAGEMENT_REQUEST]:
    fetchMoreHandoutEngagementRequest,
  [actions.FETCH_MORE_HANDOUT_ENGAGEMENT_SUCCESS]:
    fetchMoreHandoutEngagementSuccess,
  [actions.FETCH_HANDOUTS_REQUEST]: fetchHandoutsRequest,
  [actions.FETCH_HANDOUTS_SUCCESS]: fetchHandoutsSuccess,
  [actions.FETCH_MORE_HANDOUTS_REQUEST]: fetchMoreHandoutsRequest,
  [actions.FETCH_MORE_HANDOUTS_SUCCESS]: fetchMoreHandoutsSuccess,
  [actions.TOGGLE_APPROVAL_HANDOUT_MODAL]: toggleApprovalModal,
  [actions.TOGGLE_DELETE_HANDOUT_MODAL]: toggleDeleteModal,
  [actions.TOGGLE_SCHEDULE_HANDOUT_MODAL]: toggleScheduleModal,
  [actions.TOGGLE_SCHEDULE_APPROVAL_HANDOUT_MODAL]: toggleScheduleApprovalModal,
  [actions.TOGGLE_CANCEL_SCHEDULE_HANDOUT_MODAL]: toggleCancelScheduleModal,
  [actions.TOGGLE_EDIT_HANDOUT_TEMPLATE_MODAL]: toggleEditHandoutTemplateModal,
  [actions.TOGGLE_NEW_CATEGORY_MODAL]: toggleNewCategoryModal,
  [actions.TOGGLE_NEW_HANDOUT_MODAL]: toggleNewHandoutModal,
  [actions.TOGGLE_NOTIFICATION_MODAL]: toggleNotificationModal,
  [actions.TOGGLE_RESEND_HANDOUT_MODAL]: toggleResendModal,
  [actions.TOGGLE_HANDOUT_TEMPLATE_MODAL]: toggleHandoutTemplateModal,
  [actions.TOGGLE_DELETE_HANDOUT_TEMPLATE_MODAL]:
    toggleDeleteHandoutTemplateModal,
  [actions.FETCH_HANDOUT_REQUEST]: fetchHandoutRequest,
  [actions.FETCH_HANDOUT_SUCCESS]: fetchHandoutSuccess,
  [actions.EDIT_HANDOUT_REQUEST]: editHandoutRequest,
  [actions.EDIT_HANDOUT_SUCCESS]: editHandoutSuccess,
  [actions.FETCH_CLASSROOMS_REQUEST]: fetchClassroomsRequest,
  [actions.FETCH_CLASSROOMS_SUCCESS]: fetchClassroomsSuccess,
  [actions.FETCH_CATEGORY_DETAILS_REQUEST]: fetchCategoryDetailsRequest,
  [actions.FETCH_CATEGORY_DETAILS_SUCCESS]: fetchCategoryDetailsSuccess,
  [actions.APPLY_HANDOUT_TEMPLATE_REQUEST]: applyHandoutTemplateRequest,
  [actions.APPLY_HANDOUT_TEMPLATE_SUCCESS]: applyHandoutTemplateSuccess,
  [actions.EDIT_CATEGORY_REQUEST]: editCategoryRequest,
  [actions.EDIT_CATEGORY_SUCCESS]: editCategorySuccess,
  [actions.EDIT_HANDOUT_TEMPLATE_REQUEST]: editHandoutTemplateRequest,
  [actions.EDIT_HANDOUT_TEMPLATE_SUCCESS]: editHandoutTemplateSuccess,
  [actions.DELETE_CATEGORY_REQUEST]: deleteCategoryRequest,
  [actions.DELETE_CATEGORY_SUCCESS]: deleteCategorySuccess,
  [actions.DELETE_HANDOUT_TEMPLATE_REQUEST]: deleteHandoutTemplateRequest,
  [actions.DELETE_HANDOUT_TEMPLATE_SUCCESS]: deleteHandoutTemplateSuccess,
  [actions.RESEND_MASS_REQUEST]: resendMassHandoutsRequest,
  [actions.RESEND_MASS_SUCCESS]: resendMassHandoutSuccess,
  [actions.RESET_HANDOUT_FORM]: resetHandoutForm,
  [actions.RESET_INITIAL_FILTERS]: resetInitialFilters,
  [actions.SET_CURRENT_HANDOUT_TEMPLATE]: setCurrentHandoutTemplate,
};

const handouts = createModuleReducer(INITIAL_STATE, HANDLERS);

export default handouts;
