import styled, { css } from 'styled-components';
import ModalContainer from 'components/Modal/ModalContainer';
import { ThemeProps } from 'config/themes/types';

export type HeaderModalProps = {
  isRecurrent: boolean;
  theme: ThemeProps;
};

export const ModalWrapper = styled(ModalContainer)`
  ${({ theme: { space } }: ThemeProps) => css`
    .modal-title {
      margin-right: ${space.xs};
    }

    .ReactModal__Overlay {
      z-index: 1 !important;
    }
  `}
`;

export const HeaderModal = styled.div<HeaderModalProps>`
  ${({ isRecurrent, theme: { space } }) => css`
    display: flex;
    align-items: center;
    flex-direction: row;

    margin-bottom: ${isRecurrent && space.sm};

    .Tooltip {
      margin-top: ${space.xs2};
      margin-right: ${space.md};
    }
  `}
`;

export const NegotiationSelectContainer = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    margin-top: ${space.lg};
  `}
`;

export const ButtonsContainer = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    margin-top: ${space.xl5};
  `}
`;
