import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/Button';

import * as S from './styles';

const Chart = ({ url, title, button, height }) => {
  return (
    <S.ChartContainer aria-label={`Gráfico de ${title}`}>
      <S.Title>{title}</S.Title>
      <S.ChartFrame
        aria-label="Visualização"
        title={title}
        src={url}
        height={height}
        frameBorder="0"
        allowtransparency
        role="presentation"
      />
      {button && (
        <a href={button.link}>
          <Button variation="secondary">{button.label}</Button>
        </a>
      )}
    </S.ChartContainer>
  );
};

Chart.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  height: PropTypes.number,
  button: PropTypes.shape({
    link: PropTypes.string,
    label: PropTypes.string,
  }),
};

Chart.defaultProps = {
  height: 276,
};

export default Chart;
