import React from 'react';
import { MessagesBalloonRedirectProps } from './types';
import withToastMessage from 'core/hoc/withToastMessage';
import AttachmentEntity from 'core/entities/Attachment';
import MessagesAttachment from 'components/Messages/Attachment';
import * as S from './styles';

const MessagesBalloonRedirect = ({
  channelKind: kind,
  message,
  dataArea,
  toastActions: { showToast },
}: MessagesBalloonRedirectProps) => {
  const baseURL: string = window.location.origin;
  let userRoleUrl: string;

  const {
    attributes: {
      text,
      user,
      file,
      metadata: {
        anchoringData: { channelId, chatId },
      },
      canAccessRedirectedChat,
    },
  } = message;

  const canRedirect = canAccessRedirectedChat === false;
  const channelKind = kind === 'private' ? 'private' : 'family';

  const attachment = new AttachmentEntity(file);

  const openNewTab = (url: string) =>
    window.open(url, '_blank', 'noopener noreferrer');

  const redirectToNewChat = (role: string) => {
    switch (role) {
      case 'responsibles':
      case 'students':
        userRoleUrl = baseURL.concat(
          `/${dataArea}/messages/channels/${channelId}/messages/${channelKind}`
        );
        openNewTab(userRoleUrl);
        break;
      case 'schools':
      case 'teachers':
        userRoleUrl = baseURL.concat(
          `/${dataArea}/messages/channels/${channelId}/chats/${chatId}/messages`
        );
        openNewTab(userRoleUrl);
        break;
      default:
        break;
    }
  };

  const onToastError = () =>
    canRedirect
      ? showToast(
          'Você não tem acesso ao canal selecionado, mas a mensagem foi redirecionada com sucesso.',
          'info'
        )
      : redirectToNewChat(dataArea);

  return (
    <S.Container role="button" onClick={onToastError}>
      <S.Content>
        {text ? (
          <>
            <S.UserName>🤖 {user.attributes.name}</S.UserName>
            <span>{text}</span>
          </>
        ) : (
          <>
            <S.UserName>{user.attributes.name}</S.UserName>
            <MessagesAttachment attachment={attachment} variation={'sent'} />
          </>
        )}
      </S.Content>
    </S.Container>
  );
};

export default withToastMessage(MessagesBalloonRedirect);
