import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import {
  buildToast,
  toastCss,
  ToastTypes,
} from 'store/middlewares/toast/actions';
import { fetchApi, postApi, deleteApi } from 'core/utils/api';

import actions from './actions';

export function* addReport(action) {
  try {
    const dataArea = yield select((state) => state.root.dataArea);
    const { data } = yield call(postApi, `/${dataArea}/reports`, action.params);

    action.history.push({
      pathname: `/${dataArea}/reports`,
      successfullyCreated: true,
    });

    yield put({
      type: actions.ADD_REPORT_SUCCESS,
      data,
      toast: buildToast(
        'Relatório criado com sucesso.',
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        'Não foi possível listar relatórios.',
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* fetchReports(_actions) {
  try {
    const { queryParams } = _actions;

    const dataArea = yield select((state) => state.root.dataArea);

    const config = {
      params: { ...queryParams },
    };

    const { data, itemsCountPerPage, totalItemsCount } = yield call(
      fetchApi,
      `/${dataArea}/reports.json`,
      config
    );

    yield put({
      type: actions.FETCH_REPORT_SUCCESS,
      pagination: {
        currentPage: queryParams?.page || 1,
        itemsCountPerPage,
        totalItemsCount,
      },
      data,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        'Não foi possível listar relatórios.',
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* fetchAllReportsTitles(_actions) {
  try {
    const { queryParams } = _actions;
    const dataArea = yield select((state) => state.root.dataArea);

    const config = {
      params: { ...queryParams },
    };
    const { data } = yield call(fetchApi, `/${dataArea}/reports.json`, config);
    const reportsTitles = data.map((report) => report.attributes.title);
    yield put({
      type: actions.FETCH_ALL_REPORT_TITLE_SUCCESS,
      reportsTitles,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
    });
  }
}

export function* deleteReport(action) {
  try {
    const dataArea = yield select((state) => state.root.dataArea);
    const reports = yield select((state) => state.reports.reports);

    const { reportId } = action;

    yield call(deleteApi, `/${dataArea}/reports/${reportId}`);

    const { data, itemsCountPerPage, totalItemsCount } = yield call(
      fetchApi,
      `/${dataArea}/reports.json`,
      { page: 1 }
    );

    yield put({
      type: actions.FETCH_DELETE_REPORT_SUCCESS,
      data,
      pagination: {
        currentPage: 1,
        itemsCountPerPage,
        totalItemsCount,
      },
      toast: buildToast(
        'Relatório excluído com sucesso.',
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });

    yield call(fetchReports, { page: 1 });

    const reportsTitles = reports
      ?.filter((report) => report.id != reportId)
      .map((report) => report.attributes.title);

    yield put({
      type: actions.FETCH_ALL_REPORT_TITLE_SUCCESS,
      reportsTitles,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        'Não foi possível excluir o relatório.',
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* fetchReportDetails(action) {
  try {
    const dataArea = yield select((state) => state.root.dataArea);

    const { reportId } = action;

    const { data, included } = yield call(
      fetchApi,
      `/${dataArea}/reports/${reportId}`
    );

    yield put({
      type: actions.FETCH_REPORT_DETAILS_SUCCESS,
      data,
      included,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        'Não foi possível obter detalhes do relatório.',
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* reportsSagas() {
  yield all([
    takeLatest(actions.ADD_REPORT_REQUEST, addReport),
    takeLatest(actions.FETCH_REPORT_REQUEST, fetchReports),
    takeLatest(actions.FETCH_ALL_REPORT_TITLE_REQUEST, fetchAllReportsTitles),
    takeLatest(actions.FETCH_DELETE_REPORT_REQUEST, deleteReport),
    takeLatest(actions.FETCH_REPORT_DETAILS_REQUEST, fetchReportDetails),
  ]);
}

export default reportsSagas;
