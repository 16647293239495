import React from 'react';
import Skeleton from 'components/Skeleton';

import withWindowSize from 'core/hoc/withWindowSize';

import {
  theme,
  DefaultThemeProps,
  Box,
  FlexGrid,
  FlexGridItem,
} from '@agendaedu/ae-web-components';

type Props = {
  windowSize: number;
};

const HubstoreDetailsSkeleton: React.FC<Props> = ({ windowSize }) => {
  const defaultTheme: DefaultThemeProps = theme;

  const {
    colors: { neutral },
    border: { radius },
  } = defaultTheme;

  return (
    <Box>
      <Box display="flex" mt={32} position="relative">
        <FlexGrid>
          <FlexGridItem cols={{ desktopLG: 10 }} filled>
            <Box
              display="flex"
              flexDirection={{ _: 'column', desktopLG: 'row' }}
            >
              <Box mr={24} mb={{ _: 24, desktopLG: 0 }}>
                <Skeleton
                  width={146}
                  height={146}
                  primaryColor={neutral.gray4}
                  borderRadius={radius.md}
                />
              </Box>

              <Box>
                <Box mb={24}>
                  <Skeleton
                    width={150}
                    height={30}
                    primaryColor={neutral.gray4}
                    borderRadius={radius.md}
                  />
                </Box>
                <Box mb={16}>
                  <Skeleton
                    width={windowSize >= 890 ? 600 : 300}
                    height={20}
                    primaryColor={neutral.gray4}
                    borderRadius={radius.md}
                  />
                </Box>
                <Box mb={16}>
                  <Skeleton
                    width={windowSize >= 890 ? 400 : 200}
                    height={20}
                    primaryColor={neutral.gray4}
                    borderRadius={radius.md}
                  />
                </Box>
                <Box>
                  <Skeleton
                    width={windowSize >= 890 ? 400 : 200}
                    height={20}
                    primaryColor={neutral.gray4}
                    borderRadius={radius.md}
                  />
                </Box>
              </Box>
            </Box>
          </FlexGridItem>

          <FlexGridItem cols={{ desktopLG: 2 }} filled>
            <Box
              display="flex"
              position={{ _: 'absolute', desktopLG: 'relative' }}
              top={0}
              right={0}
            >
              <Box display="flex" flexDirection="column">
                <Box>
                  <Skeleton
                    width={150}
                    height={48}
                    primaryColor={neutral.gray4}
                    borderRadius={radius.md}
                  />
                </Box>
                <Box mt={28}>
                  <Skeleton
                    width={130}
                    height={20}
                    primaryColor={neutral.gray4}
                    borderRadius={radius.md}
                  />
                </Box>
              </Box>
            </Box>
          </FlexGridItem>
        </FlexGrid>
      </Box>

      <Box margin="40px 0">
        <Box overflow={'hidden'}>
          <Skeleton width={2000} height={500} primaryColor={neutral.gray4} />
        </Box>
      </Box>
    </Box>
  );
};

export default withWindowSize(HubstoreDetailsSkeleton);
