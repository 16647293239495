import React from 'react';
import PropTypes from 'prop-types';

import FormValidationErrors from 'components/Form/ValidationErrors';

import withFormContext, { formPropTypes } from 'core/hoc/withFormContext';

import TextField from 'components/TextField';

import './style.scss';

class FormTextField extends React.Component {
  onChange() {
    const { onChange, attributeName, formContext } = this.props;
    const { changeAttribute } = formContext;

    if (onChange) {
      return changeAttribute(attributeName, onChange);
    } else {
      return changeAttribute(attributeName);
    }
  }

  render() {
    const { attributeName, disabled, formContext, placeholder } = this.props;
    const { form, hasErrorOnAttribute } = formContext;
    const hasError = hasErrorOnAttribute(attributeName);

    return (
      <div className={`FormTextField ${hasError ? 'has-error' : ''}`}>
        <TextField
          onChange={this.onChange()}
          value={form[attributeName]}
          disabled={disabled}
          placeholder={placeholder}
        />
        {hasError && <FormValidationErrors attributeName={attributeName} />}
      </div>
    );
  }
}

TextField.propTypes = {
  attributeName: PropTypes.string,
  disabled: PropTypes.bool,
  ...formPropTypes,
};

export default withFormContext(FormTextField);
