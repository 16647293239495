import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import shopActions from 'store/edupay/shop/actions';
import { OFFER_STATUS, IMAGES_PAYMENT_URL } from 'core/constants/index';
import ShopListFilters from 'components/Payments/Shop/ShopListFilters';
import Loader from 'components/Loader';
import InternalErrorBoundary from 'boundaries/InternalErrorBoundary';
import ShopList from 'components/Payments/Shop/ShopList';
import ListProducts from 'components/Payments/Shop/ListProducts';
import EmptyState from 'components/EmptyState';
import Tooltip, { TOP_RIGHT } from 'components/Tooltip';
import { Button, Box } from '@agendaedu/ae-web-components';
import { trackEvent } from 'config/amplitude';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ShopListContainer = () => {
  const { t } = useTranslation(['payments']);
  const [page, setPage] = useState(1);

  const { fetchOffers, fetchCartSettings } = shopActions;
  const { dataOffers, isLoading, totalItemsCount, filters, cartSettings } =
    useSelector((state) => state.shop);

  const { currentWallet } = useSelector((state) => state.wallets);

  const dispatch = useDispatch();

  const arrayOfStatus = useMemo(() => {
    const status = Object.entries(OFFER_STATUS).map(([key, value]) => ({
      label: value.label,
      value: key,
    }));
    status.unshift({ label: 'Todas as situações', value: null });
    return status;
  }, []);

  const formMeta = {
    status: arrayOfStatus,
  };

  useEffect(() => {
    setPage(1);
  }, [currentWallet]);

  useEffect(() => {
    if (currentWallet === null) {
      return;
    }
    dispatch(fetchOffers({ page }));
  }, [page, dispatch, fetchOffers, currentWallet]);

  useEffect(() => {
    dispatch(fetchCartSettings());
  }, [dispatch, fetchCartSettings]);

  const handlePageChange = (pageNumber) => setPage(pageNumber);

  const handleFilterSubmit = useCallback(
    (params) => {
      dispatch(fetchOffers({ ...filters, ...params }));
    },
    [fetchOffers, filters, dispatch]
  );

  const getEmptyPage = useCallback(() => {
    const filteredKeys = Object.keys(filters).filter((key) => {
      return key !== 'page' && filters[key] !== null;
    });

    if (filteredKeys.length) {
      return (
        <EmptyState
          imgUrl={IMAGES_PAYMENT_URL.enrollmentEmptyStateFilterUrl}
          message={
            <p>
              Não foram encontrados resultados <br></br> para os parâmetros
              aplicados
            </p>
          }
        />
      );
    }
    return <ListProducts />;
  }, [filters]);

  return (
    <InternalErrorBoundary>
      <Box display="flex" flexDirection="row" alignItems="flex-end">
        <ShopListFilters
          form={{}}
          formMeta={formMeta}
          handleFilterSubmit={handleFilterSubmit}
        />
        {!!cartSettings?.attributes?.enabled && (
          <Tooltip
            content={t(
              'marketplace.cart_shopping_setting.settings_icon_tooltip'
            )}
            position={TOP_RIGHT}
          >
            <Link
              to="/schools/shopping_carts/configuration"
              onClick={() => trackEvent('shopping-carts-configuration')}
            >
              <Button
                type="button"
                variant="secondary"
                className="tab-action-button"
                marginBottom="24px"
                icon="cog"
                isOnlyIcon
              ></Button>
            </Link>
          </Tooltip>
        )}
      </Box>

      <Loader isLoading={isLoading}>
        {dataOffers?.data?.length ? (
          <ShopList
            page={page}
            handlePageChange={handlePageChange}
            offers={dataOffers}
            totalItemsCount={totalItemsCount}
          />
        ) : (
          getEmptyPage()
        )}
      </Loader>
    </InternalErrorBoundary>
  );
};

ShopListContainer.defaultProps = {};

ShopListContainer.propTypes = {};

export default ShopListContainer;
