import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import parse from 'html-react-parser';

import { RangeDate, Grid, Text } from '@agendaedu/ae-web-components';
import Loader from 'components/Loader';
import DropdownButton from 'components/DropdownButton';
import TransferAccordion from 'components/Accordion/variations/transfer';
import EmptyState from 'components/EmptyState';
import CardBalance from '../CardBalance';

import withFormContext from 'core/hoc/withFormContext';
import WalletsService from 'core/services/Wallets';
import { IMAGES_PAYMENT_URL } from 'core/constants/index';
import {
  EXTRACT_STATUS,
  CARD_BALANCE_TOOLTIP_MESSAGES,
  TRANSFERS_SITUATIONS,
} from 'core/constants/wallet';
import { formatRangeDate } from 'core/utils/date';
import walletsActions from 'store/edupay/wallets/actions';

import * as S from './styles';

const TransfersTab = ({ dataArea }: string) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['payments', 'common']);

  const thirtyDaysAgo = moment().subtract(30, 'days');
  const now = moment();

  const [transferSituation, setTransferSituation] = useState(
    TRANSFERS_SITUATIONS[0].value
  );
  const [startRangeDate, setStartRangeDate] = useState(thirtyDaysAgo);
  const [endRangeDate, setEndRangeDate] = useState(now);

  const [transfers, setTransfers] = useState([]);
  const [itemsCountPerPageTransfers, setItemsCountPerPageTransfers] =
    useState(0);
  const [totalItemsCountTransfers, setTotalItemsCountTransfers] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoadingTransfers, setIsLoadingTransfers] = useState(false);

  const { currentWallet, balance, isLoadingBalance } = useSelector(
    (state) => state.wallets
  );

  const { fetchAccountBalanceRequest } = walletsActions;

  const handleRangeDateChange = ({ startDate, endDate }) => {
    setStartRangeDate(startDate);
    setEndRangeDate(endDate);
  };

  function formattedRepeatingMonthTransfers(oldTransfers, newTransfers) {
    const oldTransfersOfMonth = oldTransfers;
    const newTransfersOfMonth = newTransfers;

    for (const oldTransfer of oldTransfersOfMonth) {
      for (const newTransfer of newTransfersOfMonth) {
        if (oldTransfer.attributes.name === newTransfer.attributes.name) {
          oldTransfer.attributes.transfers =
            oldTransfer.attributes.transfers.concat(
              newTransfer.attributes.transfers
            );

          const repeatedMonthTransferIndex =
            newTransfersOfMonth.indexOf(newTransfer);
          newTransfersOfMonth.splice(repeatedMonthTransferIndex, 1);
        }
      }
    }

    setIsLoadingTransfers(false);
    return [...oldTransfersOfMonth, ...newTransfersOfMonth];
  }

  const filterTransfers = useCallback(async () => {
    const walletsService = new WalletsService(dataArea);
    const filter = {
      range: {
        startDate: formatRangeDate(startRangeDate),
        endDate: formatRangeDate(endRangeDate),
      },
      transfer_status: transferSituation,
    };
    setIsLoadingTransfers(true);

    try {
      const { transfers, itemsCountPerPage, totalItemsCount } =
        await walletsService.transfers({
          formState: filter,
          page: currentPage,
        });

      setTransfers((oldTransfers) =>
        formattedRepeatingMonthTransfers(oldTransfers, transfers)
      );
      setItemsCountPerPageTransfers(itemsCountPerPage);
      setTotalItemsCountTransfers(totalItemsCount);
    } catch (error) {
      setIsLoadingTransfers(false);
    }
  }, [dataArea, startRangeDate, endRangeDate, transferSituation, currentPage]);

  const fetchBalance = () => {
    dispatch(
      fetchAccountBalanceRequest([
        formatRangeDate(startRangeDate),
        formatRangeDate(endRangeDate),
      ])
    );
  };

  const hasMoreClassrooms = () => {
    const numberOfItemsOnTheList = currentPage * itemsCountPerPageTransfers;
    return numberOfItemsOnTheList < totalItemsCountTransfers;
  };

  const fetchMoreClassrooms = () => {
    if (isLoadingTransfers) return;
    setCurrentPage((oldCurrentPage) => oldCurrentPage + 1);
  };

  const renderTransferOfMonths = (transferOfMonths) => {
    return (
      <S.TransfersList>
        <Text
          variant="title-bold-20"
          lineHeight="md"
          mt="sm"
          mb="lg"
          color={'neutral.black'}
        >
          {transferOfMonths.name}
        </Text>

        {transferOfMonths.transfers.map((day) => (
          <TransferAccordion key={day.id} transfer={day}></TransferAccordion>
        ))}
      </S.TransfersList>
    );
  };

  const renderTransfersList = () => {
    if (!isLoadingTransfers && !transfers.length)
      return (
        <EmptyState
          imgUrl={IMAGES_PAYMENT_URL.availabilityUrl}
          message={parse(t('wallet.tab_transfers.empty_state_message'))}
        />
      );
    return (
      <S.WrapperCardList
        pageStart={1}
        hasMore={!isLoadingTransfers && hasMoreClassrooms()}
        loadMore={fetchMoreClassrooms}
        loader={<Loader key="transfers-list-loader" />}
        initialLoad={true}
      >
        {transfers.map((transferOfMonths) =>
          renderTransferOfMonths(transferOfMonths?.attributes)
        )}
      </S.WrapperCardList>
    );
  };

  useEffect(() => {
    setTransfers([]);
    setCurrentPage(1);
    fetchBalance();
  }, [currentWallet, transferSituation, startRangeDate, endRangeDate]);

  useEffect(() => {
    if (Boolean(startRangeDate) === Boolean(endRangeDate)) {
      filterTransfers();
    }
  }, [
    filterTransfers,
    currentWallet,
    currentPage,
    transferSituation,
    startRangeDate,
    endRangeDate,
  ]);

  return (
    <>
      <S.WrapperFilters>
        <Grid item gridArea="situations">
          <S.SelectFilter
            label={t('wallet.tab_transfers.filters.situation')}
            options={TRANSFERS_SITUATIONS}
            value={transferSituation}
            onChange={(option) => {
              setTransferSituation(option.value);
            }}
            fullWidth
          />
        </Grid>
        <Grid item gridArea="rangeDate">
          <RangeDate
            label={t('wallet.tab_transfers.filters.range_data')}
            value={{
              startDate: startRangeDate,
              endDate: endRangeDate,
            }}
            handleChange={handleRangeDateChange}
            handleOutsideRange={() => false}
          />
        </Grid>
        <S.ButtonExport item gridArea="export">
          <DropdownButton
            dropdownItems={[
              {
                path: `/schools/wallets/transfers.xls?startDate=${
                  formatRangeDate(startRangeDate) || ''
                }&endDate=${formatRangeDate(endRangeDate) || ''}&typeQuery=${
                  transferSituation || ''
                } `,
                text: `${t(
                  'wallet.tab_transfers.filters.button_export.dropdown_transfers'
                )}(.xls)`,
              },
            ]}
            text={t('wallet.tab_transfers.filters.button_export.title')}
            variation="secondary"
            disabled={!transfers.length}
          />
        </S.ButtonExport>
      </S.WrapperFilters>

      <S.WrapperCardsBalance>
        <Loader isLoading={isLoadingBalance}>
          <CardBalance
            title={t('wallet.card_balance.transferred')}
            value={balance?.transferred || 0}
            tooltipMessage={CARD_BALANCE_TOOLTIP_MESSAGES.transferred}
            variation={EXTRACT_STATUS.available}
          ></CardBalance>
        </Loader>
      </S.WrapperCardsBalance>

      <S.WrapperNumberEntries>
        <Text
          variant="subtitle-medium-14"
          lineHeight="md"
          mb={0}
          color={'neutral.gray2'}
        >
          {`${totalItemsCountTransfers} ${t('wallet.tab_transfers.transfers')}`}
        </Text>
      </S.WrapperNumberEntries>

      {renderTransfersList()}
    </>
  );
};

export default withFormContext(TransfersTab);
