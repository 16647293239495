import React, { useEffect, useState } from 'react';

import { Box, Radio, Text } from '@agendaedu/ae-web-components';
import { Props } from './types';

export const SelectQuestion = ({
  question,
  onChange,
  value,
}: Props): React.ReactElement => {
  const [selectedOptionIdValue, setSelectedOptionIdValue] = useState(value);

  useEffect(() => {
    onChange({
      type: 'select',
      value: selectedOptionIdValue,
      questionId: question.id,
    });
  }, [question.id, selectedOptionIdValue]);

  return (
    <Box>
      <Text variant="label-regular-14" color="neutral.gray1" marginBottom="0">
        {question.title}
      </Text>

      {question.answerOptionsAttributes.map((option, index) => (
        <Radio
          key={`${question.id}-option-${index}`}
          onChange={() => setSelectedOptionIdValue(String(option.id))}
          checked={selectedOptionIdValue === String(option.id)}
        >
          <Text
            variant="label-regular-16"
            color="neutral.black"
            marginBottom="0"
          >
            {option.title}
          </Text>
        </Radio>
      ))}
    </Box>
  );
};
