import { toast } from 'react-toastify';

const toastMiddleware = () => (next) => (action) => {
  if (action.toast) {
    switch (action.toast.type) {
      case 'error': {
        toast.error(action.toast.message, action.toast.options);
        break;
      }
      case 'warn': {
        toast.warn(action.toast.message, action.toast.options);
        break;
      }
      case 'success': {
        toast.success(action.toast.message, action.toast.options);
        break;
      }
      case 'info': {
        toast.info(action.toast.message, action.toast.options);
        break;
      }
      default:
        toast(action.toast.message, action.toast.options);
    }
  }
  return next(action);
};

export default toastMiddleware;
