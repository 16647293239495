import React, { useState } from 'react';
import {
  Text,
  Icon,
  DefaultThemeProps,
  theme,
  Box,
} from '@agendaedu/ae-web-components';

import { TextToggleEyeProps } from './types';
import { trackEvent } from 'config/amplitude';

export const TextToggleEye: React.FC<TextToggleEyeProps> = ({
  text,
  hiddenCharacters = 6,
}): JSX.Element => {
  const defaultThemeProps: DefaultThemeProps = theme;

  const {
    colors: { neutral },
  } = defaultThemeProps;

  const [visible, setVisible] = useState<boolean>(true);

  return (
    <Box display="flex" flexDirection="row">
      <Text
        mt={6}
        mr={6}
        as="p"
        variant="title-bold-14"
        fontWeight={500}
        color={neutral.gray1}
      >
        {visible
          ? text.substr(0, text.length - hiddenCharacters) +
            '* '.repeat(hiddenCharacters)
          : text}
      </Text>

      <Icon
        name={visible ? 'hide' : 'show'}
        onClick={() => {
          setVisible(!visible);
          trackEvent(
            `integrations_monitor_click_password_${visible ? 'hide' : 'show'}`
          );
        }}
      />
    </Box>
  );
};

export default TextToggleEye;
