/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Text } from '@agendaedu/ae-web-components';
import { Link } from 'react-router-dom';
import _ from 'lodash';

import * as S from './styles';

import omniChannelActions from 'store/messages/omniChannel/actions';
import { OmniChannelState } from 'store/messages/omniChannel/types';
import withAppContext from 'core/hoc/withAppContext';
import { useLaunchDarkly } from 'core/hooks/useLaunchDarkly';

import TicketFilterModal from 'components/Messages/OmniChannel/Modals/TicketFilterModal';
import { ChatHeaderProps } from './types';

const ChatHeader = ({
  appContext: { dataArea },
}: ChatHeaderProps): JSX.Element => {
  const { t } = useTranslation(['messages']);
  const dispatch = useDispatch();
  const { isActiveFlag } = useLaunchDarkly({ flag: 'NEW_TICKET_BY_SCHOOL' });

  const [searchTabsTransition, setSearchTabsTransition] = useState(false);

  const {
    setChatFilters,
    socketLeaveChatRoomRequest,
    toggleChatIsSearchTab,
    toggleFilterChatModal,
    toggleFilterTicketModal,
    toggleResetActiveChannel,
  } = omniChannelActions;

  const { chatFilters, chatIsSearchTab, activeChannel, activeChat } =
    useSelector((state: OmniChannelState) => state.omniChannel);

  const isTicketChannel = activeChannel.kind === 'ticket';
  const hasAttendingClosed =
    activeChannel.attendingClosed &&
    !activeChannel.attendingHours?.blockOnlyNotification;

  const handleToggleFilterChatModal = useCallback(() => {
    dispatch(toggleFilterChatModal());
  }, [dispatch, toggleFilterChatModal]);

  const handleToggleTicketFilter = useCallback(() => {
    dispatch(toggleFilterTicketModal());
  }, [dispatch, toggleFilterTicketModal]);

  const handleSetChatFilters = useCallback(
    (params) => {
      dispatch(setChatFilters(params));
    },
    [dispatch, setChatFilters]
  );

  const debouncedFetchSearch = _.debounce((value: string) => {
    handleSetChatFilters({ ...chatFilters, searchName: value });
  }, 500);

  const handleOnChangeSearchName = ({ target }) =>
    debouncedFetchSearch(target.value);

  const changeSearchTab = () => {
    if (chatIsSearchTab === searchTabsTransition) {
      activeChat && dispatch(socketLeaveChatRoomRequest(activeChat.id));
      dispatch(toggleChatIsSearchTab());
    }
  };

  const currentChannelInfos = () => {
    const kindChannel = isTicketChannel ? 'ticket' : 'chat';

    const typeInformation = {
      chat: {
        path: `/${dataArea}/messages/channels/${activeChannel.id}/new_message`,
        name: t('omni_channel.chat.new_chat_button_text'),
        badges: chatFilters.amount as number,
        submit: handleToggleFilterChatModal,
      },
      ticket: {
        path: `/${dataArea}/messages/channels/${activeChannel.id}/new_ticket`,
        name: t('omni_channel.chat.new_ticket_button_text'),
        badges: chatFilters.amount as number,
        submit: handleToggleTicketFilter,
      },
    };

    return {
      name: typeInformation[kindChannel].name,
      path: typeInformation[kindChannel].path,
      badges: typeInformation[kindChannel].badges,
      submit: typeInformation[kindChannel].submit,
    };
  };

  const handleOnBackChannels = useCallback(() => {
    dispatch(toggleResetActiveChannel());
  }, [dispatch, toggleResetActiveChannel]);

  useEffect(() => {
    setTimeout(() => {
      setSearchTabsTransition(chatIsSearchTab);
    }, 400);
  }, [chatIsSearchTab]);

  return (
    <S.Wrapper>
      <S.BackChannelWrapper>
        <S.BackChannelIcon
          name="chevron-left"
          size="md"
          onClick={handleOnBackChannels}
        />
        <S.BackChannelAvatar
          avatarUrl={activeChannel.iconUrl}
          size="sm"
          iconName={isTicketChannel ? 'headphone-2' : null}
        />
        <Text color={'neutral.black'} variant="title-bold-20">
          {activeChannel.name}
        </Text>
      </S.BackChannelWrapper>
      <S.ChatHeaderWrapper>
        <S.HeaderActionsWrapper isSearchFilter={chatIsSearchTab}>
          <S.NewChatButtonWrapper>
            {!hasAttendingClosed && (
              <>
                {isActiveFlag ? (
                  <Link to={currentChannelInfos().path}>
                    <S.NewChatButton size="sm">
                      {currentChannelInfos().name}
                    </S.NewChatButton>
                  </Link>
                ) : (
                  <>
                    {!isTicketChannel && (
                      <Link
                        to={`/${dataArea}/messages/channels/${activeChannel.id}/new_message`}
                      >
                        <S.NewChatButton size="sm">
                          {t('omni_channel.chat.new_chat_button_text')}
                        </S.NewChatButton>
                      </Link>
                    )}
                  </>
                )}
              </>
            )}
          </S.NewChatButtonWrapper>

          {isActiveFlag ? (
            <S.FilterButton
              testId="filter-chat-button"
              badges={currentChannelInfos().badges}
              variant="secondary"
              icon="filter"
              size="sm"
              isOnlyIcon
              onClick={currentChannelInfos().submit}
            />
          ) : (
            <>
              {!isTicketChannel && (
                <S.FilterButton
                  testId="filter-chat-button"
                  badges={chatFilters.amount as number}
                  variant="secondary"
                  icon="filter"
                  size="sm"
                  isOnlyIcon
                  onClick={handleToggleFilterChatModal}
                />
              )}
            </>
          )}
        </S.HeaderActionsWrapper>

        <S.ExpandingInputWrapper
          isSearchFilter={chatIsSearchTab}
          hasSearchTabs={searchTabsTransition}
        >
          <S.InputIcon name="search" size="sm" />
          <S.ExpandingInput
            data-testid="expanding_input"
            onChange={handleOnChangeSearchName}
          />
        </S.ExpandingInputWrapper>

        <S.SearchButton
          variant="secondary"
          icon={chatIsSearchTab ? 'multiply' : 'search'}
          size="sm"
          isOnlyIcon
          onClick={changeSearchTab}
        />
      </S.ChatHeaderWrapper>

      <TicketFilterModal />
    </S.Wrapper>
  );
};

export default withAppContext(ChatHeader);
