import React, { useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Accordion from 'components/Accordion';
import { Button, Select } from '@agendaedu/ae-web-components';
import { SideSheet } from 'components/Handouts/Filters/SideSheet';

import withAppContext from 'core/hoc/withAppContext';

import actionFilters from 'store/filters/actions';
import actionHandouts from 'store/handouts/actions';

import { ActiveTermsFilters, FilterStatesProps } from 'store/filters/types';
import { HandoutState } from 'store/handouts/types';

import * as S from './styles';

export const HandoutSideFilters = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['handouts']);

  const [showSideFilter, setShowSideFilter] = useState(false);
  const [selectedHeadquarter, setSelectedHeadquarter] = useState(null);
  const [selectedEducationalStage, setSelectedEducationalStage] =
    useState(null);
  const [selectedClassroom, setSelectedClassroom] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const FADE_OUT_DURATION = 250;

  const { headquarters, educationalStages, classrooms, activeSchoolTerms } =
    useSelector((state: FilterStatesProps) => state.filters);

  const { handoutCategories, activeFiltersCount, filters } = useSelector(
    (state: HandoutState) => state.handouts
  );

  const [selectedActiveSchoolTerm, setSelectedActiveSchoolTerm] = useState(
    filters.activeSchoolTermId
  );

  const currentActiveSchoolTermId = activeSchoolTerms.find(
    (terms: ActiveTermsFilters) => terms.isActive === true
  ).value;

  const { fetchEducationalStagesRequest, fetchClassRoomsRequest } =
    actionFilters;

  const { fetchHandoutsRequest, resetInitialFilters } = actionHandouts;

  const hasHeadquarters = () => headquarters && headquarters?.length > 0;
  const hasEducationalStages = () =>
    hasHeadquarters() && selectedHeadquarter && educationalStages?.length > 0;
  const hasClassrooms = () =>
    hasEducationalStages() &&
    selectedEducationalStage &&
    classrooms?.length > 0;

  const handleToggleSideFilter = useCallback(() => {
    setTimeout(() => {
      setShowSideFilter((prevShowFilter) => !prevShowFilter);
    }, FADE_OUT_DURATION);
  }, [setShowSideFilter]);

  const handleFetchEducationalStages = useCallback(
    (headquarterId) => {
      dispatch(fetchEducationalStagesRequest(headquarterId));
    },
    [dispatch, fetchEducationalStagesRequest]
  );

  const handleFetchClassroomFilter = useCallback(
    (headquarterId, educationalStageId) => {
      dispatch(fetchClassRoomsRequest(headquarterId, educationalStageId));
    },
    [dispatch, fetchClassRoomsRequest]
  );

  const handleFetchHandoutsFiltered = useCallback(
    (filterParams) => {
      dispatch(fetchHandoutsRequest(filterParams));
    },
    [dispatch, fetchHandoutsRequest]
  );

  const handleResetInitialFilters = useCallback(() => {
    dispatch(resetInitialFilters());
  }, [dispatch, resetInitialFilters]);

  const handleSubmitFilters = useCallback(() => {
    handleFetchHandoutsFiltered({
      ...filters,
      headquarterId: selectedHeadquarter,
      educationalStageId: selectedEducationalStage,
      classroomId: selectedClassroom,
      categoryId: selectedCategory,
      activeSchoolTermId: selectedActiveSchoolTerm,
      page: 1,
    });
  }, [
    handleFetchHandoutsFiltered,
    filters,
    selectedHeadquarter,
    selectedEducationalStage,
    selectedClassroom,
    selectedCategory,
    selectedActiveSchoolTerm,
  ]);

  const handleClearFilters = useCallback(() => {
    setSelectedHeadquarter(null);
    setSelectedEducationalStage(null);
    setSelectedClassroom(null);
    setSelectedCategory(null);
    setSelectedActiveSchoolTerm(currentActiveSchoolTermId);
    handleResetInitialFilters();
    handleFetchHandoutsFiltered({
      ...filters,
      headquarterId: null,
      educationalStageId: null,
      classroomId: null,
      categoryId: null,
      activeSchoolTermId: currentActiveSchoolTermId,
      page: 1,
    });
  }, [
    handleFetchHandoutsFiltered,
    filters,
    currentActiveSchoolTermId,
    handleResetInitialFilters,
  ]);

  return (
    <>
      <Button
        icon="filter"
        variant="secondary"
        isOnlyIcon
        badges={activeFiltersCount}
        onClick={handleToggleSideFilter}
      />
      <SideSheet
        isOpen={showSideFilter}
        title="Filtros"
        handleDismiss={handleToggleSideFilter}
        handleClear={handleClearFilters}
        handleSubmit={handleSubmitFilters}
      >
        <S.SectionWrapper>
          <Accordion
            header={
              <S.TitleHeader>{t('filters.side_unity_title')}</S.TitleHeader>
            }
            body={
              <S.AccordionWrapper>
                <Select
                  fullWidth
                  label={t('filters.unity.label_text')}
                  placeholder={t('filters.unity.placeholder_text')}
                  value={selectedHeadquarter}
                  options={headquarters}
                  onChange={(event) => {
                    setSelectedHeadquarter(event.value);
                    setSelectedEducationalStage(null);
                    setSelectedClassroom(null);
                    handleFetchEducationalStages(event.value);
                  }}
                />
                <Select
                  fullWidth
                  label={t('filters.segment.label_text')}
                  placeholder={t('filters.segment.placeholder_text')}
                  value={selectedEducationalStage}
                  options={educationalStages}
                  disabled={!hasEducationalStages()}
                  onChange={(event) => {
                    setSelectedEducationalStage(event.value);
                    setSelectedClassroom(null);
                    handleFetchClassroomFilter(
                      selectedHeadquarter,
                      event.value
                    );
                  }}
                />
                <Select
                  fullWidth
                  label={t('filters.classes.label_text')}
                  placeholder={t('filters.classes.placeholder_text')}
                  value={selectedClassroom}
                  options={classrooms}
                  disabled={!hasClassrooms()}
                  onChange={(event) => setSelectedClassroom(event.value)}
                />
              </S.AccordionWrapper>
            }
            expanded="handouts"
            allowZeroExpanded
          />
          <Accordion
            header={
              <S.TitleHeader>{t('filters.side_terms_title')}</S.TitleHeader>
            }
            body={
              <Select
                fullWidth
                label={t('filters.school_terms.label_text')}
                placeholder={t('filters.school_terms.placeholder_text')}
                value={selectedActiveSchoolTerm}
                options={activeSchoolTerms}
                onChange={(event) => setSelectedActiveSchoolTerm(event.value)}
              />
            }
            expanded="handouts"
            allowZeroExpanded
          />
          <Accordion
            header={
              <S.TitleHeader>{t('filters.side_category_title')}</S.TitleHeader>
            }
            body={
              <Select
                fullWidth
                label={t('filters.categories.label_text')}
                placeholder={t('filters.categories.placeholder_text')}
                value={selectedCategory}
                options={handoutCategories?.categories}
                onChange={(event) => setSelectedCategory(event.value)}
              />
            }
            allowZeroExpanded
          />
        </S.SectionWrapper>
      </SideSheet>
    </>
  );
};

withAppContext(HandoutSideFilters);
