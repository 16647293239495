import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import MessagesBalloonDeleted from 'components/Messages/Balloon/Deleted';
import { MessagesBalloonAnchor } from './Anchor';
import MessagesBalloonRedirect from './Redirect';
import SenderName from 'components/Messages/SenderName';
import MessagesAttachment from '../Attachment';
import MessagesApprove from '../Approve';
import TextWithLink from 'components/TextWithLink';

import AttachmentEntity from 'core/entities/Attachment';

import withAppContext from 'core/hoc/withAppContext';

import './style.scss';

class MessagesBalloon extends PureComponent {
  static propTypes = {
    appContext: PropTypes.shape({
      primaryColor: PropTypes.string,
      dataArea: PropTypes.string,
    }),
    channelKind: PropTypes.string,
    approveMessage: PropTypes.func,
    message: PropTypes.shape({
      attributes: PropTypes.shape({
        user: PropTypes.object.isRequired,
        type: PropTypes.string.isRequired,
        approvalStatus: PropTypes.string,
        attachedMessageType: PropTypes.string,
        text: PropTypes.string,
        file: PropTypes.object,
        canApprove: PropTypes.bool,
        canAccessRedirectedChat: PropTypes.bool,
        status: PropTypes.string,
        metadata: PropTypes.shape({
          anchoringData: PropTypes.shape({
            chatId: PropTypes.number,
            channelId: PropTypes.number,
            studentProfileId: PropTypes.number,
            type: PropTypes.string,
          }),
        }),
      }),
    }),
    unapproveMessage: PropTypes.func,
    anchorMessage: PropTypes.func,
  };

  render() {
    const {
      appContext: { primaryColor, dataArea },
      approveMessage,
      anchorMessage,
      channelKind,
      message,
      unapproveMessage,
    } = this.props;

    const {
      attributes: {
        user,
        type,
        approvalStatus,
        attachedMessageType,
        text,
        file,
        canApprove,
        status,
        metadata: { anchoringData },
      },
    } = message;

    const attachment = new AttachmentEntity(file);
    const messageDeleted = status === 'deleted';
    let balloonColor = primaryColor;
    let balloonStyle;
    let automaticBalloonStyle;
    let senderNameStyle;
    let approvalStatusClass = approvalStatus;

    if (type === 'sent' || anchoringData?.type === 'chatRedirection') {
      if (
        !approvalStatus ||
        approvalStatus === 'approved' ||
        approvalStatus === 'manually_approved'
      ) {
        balloonStyle = {
          backgroundColor: balloonColor,
          borderColor: balloonColor,
        };
      }
    } else {
      if (approvalStatus === 'pending') {
        if (canApprove) {
          approvalStatusClass = type;
          senderNameStyle = {
            color: primaryColor,
          };
        }
      } else if (approvalStatus !== 'rejected') {
        senderNameStyle = {
          color: primaryColor,
        };
      }
    }

    if (messageDeleted) {
      return (
        <div
          style={balloonStyle}
          className={`MessagesBalloon ${type} ${anchoringData?.type}Type ${approvalStatusClass}`}
        >
          {type === 'received' && !anchoringData && (
            <div style={senderNameStyle} className="senderName">
              <SenderName user={user} />
            </div>
          )}
          <MessagesBalloonDeleted message={message} />
        </div>
      );
    } else {
      return (
        <div
          style={balloonStyle || automaticBalloonStyle}
          className={`MessagesBalloon ${type} ${anchoringData?.type} ${approvalStatusClass} ${dataArea}`}
        >
          {type === 'received' && !anchoringData && (
            <div style={senderNameStyle} className="senderName">
              <SenderName user={user} />
            </div>
          )}

          {attachedMessageType === 'direct_reply' && (
            <MessagesBalloonAnchor
              message={message}
              anchorMessage={anchorMessage}
            />
          )}

          {!anchoringData && <TextWithLink>{text}</TextWithLink>}

          {anchoringData?.type === 'chatRedirection' && (
            <MessagesBalloonRedirect
              message={message}
              dataArea={dataArea}
              channelKind={channelKind}
            />
          )}

          {attachment.isPresent() && (
            <MessagesAttachment attachment={attachment} variation={type} />
          )}
          {type === 'received' &&
            approvalStatus === 'pending' &&
            canApprove && (
              <MessagesApprove
                approveMessage={approveMessage}
                unapproveMessage={unapproveMessage}
              />
            )}
        </div>
      );
    }
  }
}

export default withAppContext(MessagesBalloon);
