import styled, { css } from 'styled-components';

import Tooltip from 'components/Tooltip';
import { FileUploadProps } from './types';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  .Field {
    margin: 0;
    padding: 0;

    .Label {
      font-size: 14px;
      font-family: 'Roboto', sans-serif;
    }
  }
`;

export const DateField = styled.div`
  .Field {
    .SingleDatePicker,
    .SingleDatePickerInput,
    .DateInput {
      width: 100%;
    }
    width: 100%;
  }
`;

export const LabelRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  gap: 10px;
`;

export const Label = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #666666;
  line-height: 24px;
  padding: 0;
  margin: 0;
  margin-bottom: 8px;
`;

export const CustomTooltip = styled(Tooltip)`
  .tooltip-div {
    width: 16px;
    height: 16px;
    margin-bottom: 2px;
  }
`;

export const PaymentMethodsField = styled.div`
  width: 100%;
`;

export const ResponsibleSelectField = styled.div`
  margin-bottom: 24px;
`;

export const FileUploadContainer = styled.div<FileUploadProps>`
  ${({ disabled }) => css`
    .Dropdown {
      ${disabled &&
      css`
        pointer-events: none;
        opacity: 0.4;
      `}
    }
  `}
`;

export const DivergentValue = styled.div`
  ${({ theme }) => css`
    width: 160px;

    > .Field {
      padding: 0;
      margin-right: 16px;
      margin-bottom: 0;

      .CurrencyField .input-group {
        height: 40px;

        .input-group-addon {
          font-family: 'Roboto', sans-serif;
          font-size: 16px;
          line-height: 24px;
          color: ${theme.gray2};
          border-radius: 6px 0 0 6px;
        }

        input {
          height: 40px;
          border-radius: 0 6px 6px 0;
        }
      }
    }
  `}
`;

export const ToggleDivergentValueContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  margin-bottom: 16px;
`;

export const ToggleDivergentValueTitle = styled.p`
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  padding: 0;
  margin: 0;
`;

export const UserItemContainer = styled.div`
  border-radius: 0px;
  > * {
    border-radius: 0;

    &:only-child {
      border-radius: 8px;
    }

    &:first-child:not(:last-child) {
      border-radius: 8px 8px 0 0;
    }

    &:last-child:not(:first-child) {
      border-radius: 0 0 8px 8px;
    }
  }
`;

export const NoteField = styled.div`
  display: flex;
`;

export const CounterWrapper = styled.div`
  display: flex;
  justify-content: right;
  margin-top: -20px;
`;

export const LabelCounter = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
`;
