import React from 'react';
import AgendaIcon from 'components/AgendaIcon';
import moment from 'moment';
import { InformationButtonsProps } from '../types';

export const getButtons = ({
  toggleModal,
  currentSchoolProduct,
  policies,
  changeActionModal,
}: InformationButtonsProps) => {
  const setupModal = (action: string) => {
    changeActionModal(action);
    toggleModal();
  };

  const {
    id,
    attributes: { status, expires_at, classrooms, source_api_v2 },
  } = currentSchoolProduct;
  const buttons = {
    pending: [],
    sent: [],
    pending_update: [],
    canceled: [],
    deleted: [],
    rejected: [],
    expired: [],
  };

  if (policies.can_create_school_product) {
    buttons.pending.unshift(
      {
        path: `/schools/school_products/${id}/edit`,
        variation: 'secondary',
        text: 'Editar',
      },
      {
        variation: 'danger',
        onClick: () => setupModal('delete'),
        text: <AgendaIcon name="trash" />,
      }
    );

    buttons.rejected.unshift(
      {
        path: `/schools/school_products/${id}/edit`,
        variation: 'secondary',
        text: 'Editar',
      },
      {
        variation: 'danger',
        onClick: () => setupModal('delete'),
        text: <AgendaIcon name="trash" />,
      }
    );
  } else {
    buttons.pending.unshift(
      {
        variation: 'secondary',
        text: 'Editar',
        disabled: true,
      },
      {
        variation: 'danger',
        text: <AgendaIcon name="trash" />,
        disabled: true,
      }
    );

    buttons.rejected.unshift(
      {
        variation: 'secondary',
        text: 'Editar',
        disabled: true,
      },
      {
        variation: 'danger',
        text: <AgendaIcon name="trash" />,
        disabled: true,
      }
    );
  }

  if (policies.can_delete_after_cancellation) {
    buttons.canceled.unshift({
      text: <AgendaIcon name="trash" />,
      variation: 'danger',
      onClick: () => setupModal('delete'),
    });
  } else {
    buttons.canceled.unshift({
      text: <AgendaIcon name="trash" />,
      variation: 'danger',
      disabled: true,
    });
  }

  if (policies.can_reject_school_product) {
    buttons.pending.unshift({
      path: `/schools/school_products/${id}/reject`,
      variation: 'secondary',
      text: 'Não aprovar',
    });
  } else {
    buttons.pending.unshift({
      text: 'Não aprovar',
      variation: 'secondary',
      disabled: true,
    });
  }

  if (!source_api_v2) {
    if (policies.can_cancel_school_product) {
      buttons.sent.unshift({
        variation: 'secondary',
        onClick: () => setupModal('cancel'),
        text: 'Cancelar',
      });

      buttons.pending_update.unshift({
        variation: 'secondary',
        onClick: () => setupModal('cancel'),
        text: 'Cancelar',
      });
    } else {
      buttons.sent.unshift({
        variation: 'secondary',
        text: 'Cancelar',
        disabled: true,
      });

      buttons.pending_update.unshift({
        variation: 'secondary',
        text: 'Cancelar',
        disabled: true,
      });
    }
  }

  if (policies.can_edit_after_approved) {
    buttons.sent.unshift({
      path: `/schools/school_products/${id}/edit`,
      text: 'Editar',
    });
  } else {
    buttons.sent.unshift({
      disabled: true,
      text: 'Editar',
    });
  }

  if (policies.can_approve_school_product) {
    let action = 'approve';
    const expiresAt = moment(expires_at, 'DD/MM/YYYY');

    if (expiresAt.isBefore(new Date(), 'day')) {
      action = 'expired';
    }

    buttons.pending.unshift({
      text: 'Aprovar envio',
      onClick: () => setupModal(action),
    });

    buttons.pending_update.unshift(
      {
        path: '/schools/school_products',
        text: 'Enviar atualização',
      },
      {
        path: `/schools/school_products/${id}/edit`,
        variation: 'secondary',
        text: 'Editar',
      }
    );

    buttons.rejected.unshift({
      text: 'Aprovar envio',
      onClick: () => setupModal('approve'),
    });
  } else {
    buttons.pending.unshift({
      text: 'Aprovar envio',
      disabled: true,
    });

    buttons.pending_update.unshift(
      {
        text: 'Enviar atualização',
        disabled: true,
      },
      {
        variation: 'secondary',
        text: 'Editar',
        disabled: true,
      }
    );

    buttons.rejected.unshift({
      text: 'Aprovar envio',
      disabled: true,
    });
  }

  if (policies.can_create_school_product && classrooms.length) {
    const cloneButton = {
      text: 'Duplicar',
      variation: 'secondary',
      onClick: () => setupModal('clone'),
    };

    const initialValue = {};

    // Transform a new button object
    // adding the SchoolProduct Clone Button
    const newObjectButtons = Object.keys(buttons).reduce(
      (accumulator, objectKey) => {
        // head is first element of array
        // tail return all remaining elements
        // buttons[objectKey] is an array of obejcts
        const [head, ...tail] = buttons[objectKey];

        // sort elements in an object
        const sortButtons = {
          [objectKey]: [
            head,
            ...tail.slice(0, -1), // return all but the last elements
            !source_api_v2 ? cloneButton : undefined,
            tail[tail.length - 1], // return last element
          ],
        };

        // Element filtering other than undefined
        const filterObject = Object.keys(sortButtons).map((key) => {
          const filterdElements = sortButtons[key].filter(
            (element) => element !== undefined
          );

          return {
            [objectKey]: [...filterdElements],
          };
        });

        const [filteredObject] = filterObject;

        // Create new element with accumulator of reducer
        const newObject = {
          ...accumulator,
          ...filteredObject,
        };

        return newObject;
      },
      initialValue
    );

    return newObjectButtons[status];
  }

  return buttons[status];
};
