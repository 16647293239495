import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import * as S from './styles';
import StudentSearch from './StudentSearch';
import Button from 'components/Button';
import Avatar from 'components/Avatar';
import StudentListSkeleton from './Skeleton';
import Checkbox from 'components/Form/Checkbox';
import { getCurrentDate } from 'core/utils/date';

const StudentsList = ({
  studentsIdsSelecteds,
  setStudentIdsSelecteds,
  students,
  isCheckable,
  placeholder,
  multiSelect,
}) => {
  const { isLoading, date } = useSelector((state) => state.dailySummaries);
  const [search, setSearch] = useState('');

  const isNotToday =
    date.format('DD/MM/YYYY') !== getCurrentDate().format('DD/MM/YYYY');

  const getStudents = search
    ? students.filter((student) =>
        student.attributes.name.toLowerCase().includes(search.toLowerCase())
      )
    : students;

  const onChange = (id) => {
    if (isNotToday || !multiSelect) return setStudentIdsSelecteds([id]);
    if (studentsIdsSelecteds.includes(id)) {
      setStudentIdsSelecteds(
        studentsIdsSelecteds.filter((studentId) => studentId !== id)
      );
    } else {
      setStudentIdsSelecteds([...studentsIdsSelecteds, id]);
    }
  };

  const handleSelectAllStudents = () => {
    if (studentsIdsSelecteds.length === students.length) {
      setStudentIdsSelecteds([]);
    } else {
      const allStudents = getStudents.map((student) => student.id);
      setStudentIdsSelecteds(allStudents);
    }
  };

  return (
    <S.StudentListContent>
      {isLoading ? (
        <StudentListSkeleton />
      ) : (
        <>
          {isCheckable && (
            <S.StudentListHeader>
              <span>
                {studentsIdsSelecteds.length}/{students.length} alunos
              </span>
              <Button
                variation="secondary"
                onClick={handleSelectAllStudents}
                disabled={isNotToday}
              >
                {studentsIdsSelecteds.length === students.length
                  ? 'Desmarcar todos'
                  : 'Selecionar todos'}
              </Button>
            </S.StudentListHeader>
          )}

          <S.CheckboxGroupContent>
            <StudentSearch
              search={search}
              setSearch={setSearch}
              placeholder={placeholder}
            />

            <S.CheckboxGroupScroll>
              {getStudents.map((student) => {
                const {
                  id,
                  attributes: {
                    name,
                    photo_url,
                    should_use_name_initials_avatar,
                    last_updated,
                  },
                } = student;

                const user = {
                  ...student,
                  attributes: {
                    ...student.attributes,
                    avatar_url: !should_use_name_initials_avatar && photo_url,
                  },
                };
                const checked = studentsIdsSelecteds.includes(id);
                return (
                  <S.StudentContainer key={id} checked={checked}>
                    <S.StudentItem checked={checked}>
                      <S.StyledFlexContainer onClick={() => onChange(id)}>
                        <Avatar user={user} />
                        <S.StudentInfo checked={checked}>
                          <S.StudentName title={name}>{name}</S.StudentName>
                          {!!last_updated && (
                            <S.UpdatedAtInfo>{last_updated}</S.UpdatedAtInfo>
                          )}
                        </S.StudentInfo>
                      </S.StyledFlexContainer>
                      {isCheckable && (
                        <>
                          {isNotToday ? (
                            <>
                              <S.StyledButton
                                type="radio"
                                id={`student-${id}`}
                                onChange={() => onChange(id)}
                                checked={checked}
                              />
                              <S.StyledLabel
                                htmlFor={`student-${id}`}
                                checked={checked}
                                simpleInputRadio
                              />
                            </>
                          ) : (
                            <Checkbox
                              checked={checked}
                              onChange={() => onChange(id)}
                            />
                          )}
                        </>
                      )}
                    </S.StudentItem>
                  </S.StudentContainer>
                );
              })}
            </S.CheckboxGroupScroll>
          </S.CheckboxGroupContent>
        </>
      )}
    </S.StudentListContent>
  );
};

const StudentShape = PropTypes.shape({
  id: PropTypes.string,
  type: PropTypes.string,
  attributes: {
    name: PropTypes.string,
    avatar_color: PropTypes.string,
    photo_url: PropTypes.string,
    should_use_name_initials_avatar: PropTypes.bool,
    name_initials: PropTypes.string,
    has_daily_summary: PropTypes.bool,
    last_updated: PropTypes.string,
  },
});

StudentsList.propTypes = {
  studentsIdsSelecteds: PropTypes.arrayOf(PropTypes.string).isRequired,
  setStudentIdsSelecteds: PropTypes.func.isRequired,
  students: PropTypes.arrayOf(StudentShape).isRequired,
  isCheckable: PropTypes.bool.isRequired,
  placeholder: PropTypes.string,
  multiSelect: PropTypes.bool.isRequired,
};

export default StudentsList;
