import styled, { css } from 'styled-components';

export const MainContainer = styled.div`
  margin: 0;

  &.centered {
    .container-content {
      .main-content {
        max-width: 590px;
      }
    }
  }

  @media (min-width: 768px) {
    margin: 8px 16px 0 16px;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin-bottom: 16px;

  .ButtonRow {
    > a:first-child {
      margin-left: 0;
    }
  }
`;

export const TitlesContainer = styled.div`
  ${({ theme, canCreateWalletButton }) => css`
    width: 100%;
    margin-right: auto;
    margin-bottom: 16px;
    display: ${canCreateWalletButton ? 'flex' : 'auto'};
    justify-content: ${canCreateWalletButton ? 'space-between' : 'auto'};

    .PageTitle {
      font-size: 24px;
      margin-bottom: 8px;
      font-weight: 700;
      line-height: 1.5;

      @media (min-width: 768px) {
        margin-bottom: 0;
      }
    }

    .PageSubTitle.default {
      margin-top: 0;
      font-family: 'Quicksand', sans-serif;
      font-size: 14px;
      font-weight: 500;
      color: ${theme.gray2};
    }

    .NewWalletButton {
      font-size: 18px;
      font-weight: bold;
      border-radius: 6px;
      padding: 8px 16px;
      margin: 0;
    }
  `}
`;

export const ContentContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    background-color: ${theme.white};
    border-radius: 10px;
    padding: 24px;

    @media (min-width: 768px) {
      padding: 32px;
    }
  `}
`;

export const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding-bottom: 24px;
`;

export const FooterContainer = styled.div`
  ${({ theme }) => css`
    border-top: 1px solid ${theme.gray12};
    padding: 20px 0;
    margin: 0 20px;

    .Button.btn-primary,
    .Button.btn-default[disabled] {
      float: right;
    }

    .Button.btn-default {
      float: left;
    }
  `}
`;
