import styled, { css } from 'styled-components';

import colorWithAlpha from 'core/utils/colorWithAlpha';

export const Container = styled.div`
  ${({ theme, suggestionsList }) => css`
    position: relative;

    input {
      height: 40px;
      font-family: 'Roboto', sans-serif;
      font-weight: 400;
      font-size: 16px;
      color: ${theme.black};
      padding-left: 16px;

      :focus {
        ${suggestionsList.length &&
        css`
          border-bottom: none;
          border-radius: 6px 6px 0 0;
        `}
      }
    }
  `}
`;

export const SuggestionsGroup = styled.div`
  ${({ theme }) => css`
    border: solid 1px ${theme.primaryColor};
    border-top: none;
    border-radius: 0 0 6px 6px;
    position: absolute;
    z-index: 1;
    background-color: ${theme.white};
    left: 0;
    right: 0;
  `}
`;

export const SuggestionOption = styled.div`
  ${({ theme }) => css`
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 16px;
    padding: 8px 16px;
    border-radius: 6px;
    margin: 4px;

    :hover {
      color: ${theme.primaryColor};
      background-color: ${colorWithAlpha(`${theme.primaryColor}`, 0.1)};
    }
  `}
`;
