import React from 'react';
import { useTranslation } from 'react-i18next';

import { Text, Box, Grid } from '@agendaedu/ae-web-components';

import { HistoryActionCardProps } from './types';

import * as S from './styles';

const HistoryActionCard = ({ action }: HistoryActionCardProps) => {
  const { t } = useTranslation(['payments', 'common']);

  const { date, user, action: userAction } = action.attributes;

  return (
    <S.WrapperCard>
      <Grid item gridArea="date">
        <Box>
          <Text variant="subtitle-medium-12" color="neutral.gray2">
            {t('wallet.tab_history.action_card.date')}
          </Text>
          <Text
            variant="subtitle-medium-16"
            mt="sm"
            mb="xs4"
            color="neutral.gray1"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            overflow="hidden"
          >
            {date}
          </Text>
        </Box>
      </Grid>

      <Grid item gridArea="user">
        <Box>
          <Text variant="subtitle-medium-12" color="neutral.gray2">
            {t('wallet.tab_history.action_card.user')}
          </Text>
          <Text
            variant="subtitle-medium-16"
            mt="sm"
            mb="xs4"
            color="neutral.gray1"
          >
            {user.length ? user : '-'}
          </Text>
        </Box>
      </Grid>

      <Grid item gridArea="action">
        <Box>
          <Text variant="subtitle-medium-12" color="neutral.gray2">
            {t('wallet.tab_history.action_card.action')}
          </Text>
          <Text
            variant="subtitle-medium-16"
            mt="sm"
            mb="xs4"
            color="neutral.gray1"
          >
            {userAction.replace('<strong>', '').replace('</strong>', '')}
          </Text>
        </Box>
      </Grid>
    </S.WrapperCard>
  );
};

export default HistoryActionCard;
