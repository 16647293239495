import React from 'react';

import tabifyForWizard from 'core/hoc/tabifyForWizard';
import FormFieldset from 'components/Form/Fieldset';

import GeneralInformations from 'components/Payments/PaymentsContainer/FormSections/GeneralInformations';
import RecurrencyConfiguration from 'components/Payments/PaymentsContainer/FormSections/RecurrencyConfiguration';
import WalletConfiguration from 'components/Payments/PaymentsContainer/FormSections/WalletConfiguration';
import RecurrentItems from '../../RecurrentFormSections/RecurrentItems';
import EdupayDiscount from 'components/Payments/PaymentsContainer/FormSections/EdupayDiscount';
import FinesAndInterest from '../../RecurrentFormSections/FinesAndInterest';
import RecurrentSummary from '../../RecurrentFormSections/RecurrentSummary';

import validations from './validations.js';

const RecurrentPlanFormInformationsTab = () => (
  <div className="RecurrentPlanFormInformationsTab">
    <FormFieldset>
      <WalletConfiguration isRecurrent />
      <GeneralInformations />
      <RecurrencyConfiguration type="plan" />
      <RecurrentItems />
      <FinesAndInterest />
      <EdupayDiscount validations={validations} withoutTitle showCreditCardDiscount />
      <RecurrentSummary />
    </FormFieldset>
  </div>
);

export default tabifyForWizard({
  title: '1. Informações',
  subTitle: 'Informações gerais',
  validations,
})(RecurrentPlanFormInformationsTab);
