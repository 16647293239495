import { Text } from '@agendaedu/ae-web-components';
import { ThemeProps } from 'config/themes/types';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    flex-direction: column;
    gap: ${space.xs};
  `}
`;

export const DestinationHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const CountTotalDestinationsText = styled(Text)`
  ${({ theme: { space, colors } }: ThemeProps) => css`
    color: ${colors.neutral.black};
    margin: ${space.xs4};
  `}
`;

export const OptionsWrapper = styled.div`
  ${({ theme: { border, colors, space, breakpoints } }: ThemeProps) => css`
    display: flex;
    flex-direction: column;
    max-height: 324px;

    overflow-y: auto;
    overflow-x: hidden;

    @media (max-width: ${breakpoints.mobileSM}) {
      max-height: 180px;
    }

    &::-webkit-scrollbar {
      background-color: transparent;
      width: ${space.xs2};
    }

    &::-webkit-scrollbar-button {
      display: none;
    }

    &::-webkit-scrollbar-track {
      background-color: ${colors.neutral.gray5};
    }

    &::-webkit-scrollbar-thumb {
      border-radius: ${border.radius.sm};
      border: ${border.width.sm} ${border.style.solid} ${colors.neutral.gray4};
    }
  `}
`;
