import styled, { css } from 'styled-components';

export const ViewContainer = styled.section`
  font-family: 'Quicksand', sans-serif;
  margin: 40px;
`;

export const Title = styled.h2`
  font-size: 24px;
  font-weight: bold;
  text-align: left;
  margin-bottom: 24px;
  ${({ theme }) => css`
    color: ${theme.black};
  `}

  @media (max-width: 834px) {
    margin-bottom: 14px;
  }
`;
