import * as Sentry from '@sentry/browser';

import { ENVIRONMENT, RELEASE } from 'core/constants/index.js.erb';

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://707911cd0b1b4c71863bd0faaed7ace8@sentry.io/251703',
    environment: ENVIRONMENT,
    release: RELEASE,
    ignoreErrors: [
      /Network Error/i,
      /Firebase App named/i,
      /Cannot read property/i,
      /Loading chunk 1 failed/i,
      /Blocked a frame with origin/i,
      /TypeError/i,
      /AbortError/i,
      /ResizeObserver/i,
      /IndexSizeError/i,
      /NotFoundError/i,
      /ReferenceError/i,
      /UnknownError/i,
      /RangeError/i,
      /ChunkLoadError/i,
    ],
  });
}
