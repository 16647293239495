import { ThemeProps } from 'config/themes/types';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme: { colors } }: ThemeProps) => css`
    .Button.btn-danger {
      background-color: ${colors.context.alert.default};
      color: ${colors.neutral.white};

      &:hover {
        background-color: ${colors.context.alert.dark};
        border-color: ${colors.context.alert.dark};
      }
    }
  `}
`;

export const Title = styled.p`
  ${({ theme: { font } }: ThemeProps) => css`
    font-family: ${font.family.auxiliary};
    font-size: ${font.size.md};
  `}
`;

export const Label = styled.p`
  ${({ theme: { font } }: ThemeProps) => css`
    font-size: ${font.size.sm};
    font-family: ${font.family.auxiliary};
  `}
`;

export const NoteCancelField = styled.div`
  display: flex;
  flex-direction: column;
  .Field {
    margin: 0;
    padding: 0;
  }
`;

export const WantToCancelField = styled.div`
  .Field {
    margin: 0;
    padding: 0;
    input {
      height: 40px;
    }
  }
`;

export const LabelCounter = styled.p`
  ${({ theme: { font } }: ThemeProps) => css`
    font-family: ${font.family.auxiliary};
    font-weight: ${font.weight.regular};
    font-size: ${font.size.xs};
    display: flex;
    justify-content: end;
    margin-top: -20px;
  `}
`;
