import React from 'react';
import PropTypes from 'prop-types';
import { CardList, Box } from '@agendaedu/ae-web-components';

import InfoData from '../InfoData';
import EmptyState from 'components/EmptyState';
import Loader from 'components/Loader';
import { useSelector } from 'react-redux';
import SkeletonItemList from '../SkeletonItemList';
import { IMAGES_CLASSROOMS_URL } from 'core/constants/index';

const ClassroomsList = ({ callFetch }) => {
  const { classroomsPagination, classrooms, isLoading } = useSelector(
    (state) => state.summarydayClassrooms
  );

  const hasMoreClassrooms = () => {
    const numberOfItemsOnTheList =
      classroomsPagination.currentPage * classroomsPagination.itemsCountPerPage;

    return numberOfItemsOnTheList === classrooms.length;
  };

  const fetchMoreClassrooms = () => {
    callFetch({ page: classroomsPagination.currentPage + 1 });
  };

  const rendercallFetchClassroomsList = () => {
    if (isLoading)
      return (
        <Box margin="0 auto">
          <Loader />;
        </Box>
      );

    if (classrooms.length > 0)
      return classrooms.map((dataRow) => (
        <InfoData key={dataRow.id} dataRow={dataRow} />
      ));

    if (!isLoading && !classrooms.length) {
      return (
        <Box margin="0 auto">
          <EmptyState
            imgUrl={IMAGES_CLASSROOMS_URL.emptyStateUrl}
            message={
              <p>Não foram encontradas atividades nos filtros selecionados.</p>
            }
          />
        </Box>
      );
    }
  };

  return (
    <CardList
      pageStart={1}
      hasMore={!isLoading && hasMoreClassrooms()}
      loadMore={fetchMoreClassrooms}
      loader={<SkeletonItemList key="list-infinity-scroll" />}
      my="sm"
    >
      {rendercallFetchClassroomsList()}
    </CardList>
  );
};

ClassroomsList.propTypes = {
  callFetch: PropTypes.func,
  activeFlag: PropTypes.bool,
};

export default ClassroomsList;
