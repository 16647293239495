import React from 'react';
import reduxProvider from 'core/hoc/reduxProvider';

import SchoolBills from 'components/MyAccount/SchoolBills';

const SchoolBillsScreen = () => {
  return (
    <section className="SchoolBillsScreen">
      <SchoolBills />
    </section>
  );
};

export default reduxProvider(SchoolBillsScreen);
