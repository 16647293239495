import React from 'react';

import AgendaIcon, { IconSizes } from 'components/AgendaIcon';
import Tooltip, { TOP_CENTER } from 'components/Tooltip';

export type HelpTooltipProps = {
  text: string | React.ReactElement;
  size: IconSizes;
};

const HelpTooltip = ({ text, size }: HelpTooltipProps) => (
  <Tooltip content={text} position={TOP_CENTER}>
    <AgendaIcon name="help" size={size} />
  </Tooltip>
);

export default HelpTooltip;
