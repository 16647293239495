import React from 'react';
import { theme, DefaultThemeProps, Box } from '@agendaedu/ae-web-components';

import * as S from './styles';
import Skeleton from 'components/Skeleton';

import { ChatCardSkeletonProps } from './types';

export const ChatCardSkeleton = ({ amount }: ChatCardSkeletonProps) => {
  const defaultTheme: DefaultThemeProps = theme;

  const {
    space,
    colors: { neutral },
    border: { radius },
  } = defaultTheme;

  return (
    <>
      {Array.from(Array(amount).keys()).map((n) => (
        <S.SkeletonWrapper key={n} data-testid="skeleton-wrapper">
          <Box display={'flex'} flexDirection={'row'} width={'100%'}>
            <Skeleton
              width={48}
              height={48}
              primaryColor={neutral.gray5}
              borderRadius={radius.circle}
            />

            <Box
              display={'flex'}
              flexDirection={'column'}
              ml={space.sm}
              width={'100%'}
              flex={1}
            >
              <S.SkeletonLabelWrapper>
                <Box
                  display={'flex'}
                  flexDirection={'row'}
                  justifyContent={'space-between'}
                  width={'100%'}
                >
                  <Skeleton
                    width={67}
                    height={12}
                    primaryColor={neutral.gray5}
                    borderRadius={radius.sm}
                  />

                  <Skeleton
                    width={24}
                    height={12}
                    primaryColor={neutral.gray5}
                    borderRadius={radius.sm}
                  />
                </Box>

                <Skeleton
                  width={151}
                  height={12}
                  primaryColor={neutral.gray5}
                  borderRadius={radius.sm}
                />

                <Skeleton
                  width={107}
                  height={24}
                  primaryColor={neutral.gray5}
                  borderRadius={radius.sm}
                />
              </S.SkeletonLabelWrapper>
            </Box>
          </Box>
          <Skeleton
            width={242}
            height={24}
            primaryColor={neutral.gray5}
            borderRadius={radius.sm}
          />
        </S.SkeletonWrapper>
      ))}
    </>
  );
};
