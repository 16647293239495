import styled from 'styled-components';

export const TextFiledWithSelectWrapper = styled.div`
  display: flex;

  .SelectDiscountType {
    &:first-child {
      display: flex;
      width: 80px;
      margin: 0;
      padding: 0;
      margin-bottom: 0;

      > div > div {
        &:only-child {
          border-radius: 6px 0 0 6px;
        }

        &:nth-child(2) {
          border-radius: 6px 0 0 6px;
        }
      }
    }
  }

  .CurrencyField,
  .PercentField {
    .input-group {
      input {
        height: 40px;
        width: 80px;
        border-radius: 0 6px 6px 0;
        font-size: 16px;
      }
    }

    .input-group-addon {
      display: none;
    }
  }
`;
