import React from 'react';

import { SkeletonProps } from './types';
import { Box, DefaultThemeProps, theme } from '@agendaedu/ae-web-components';

import Skeleton from 'components/Skeleton';

export const GroupMemberCardSkeleton: React.FC<SkeletonProps> = ({
  amount,
}) => {
  const defaultTheme: DefaultThemeProps = theme;

  const {
    space,
    colors: { neutral },
    border: { radius },
  } = defaultTheme;

  return (
    <div data-testid="group-member-card-skeleton">
      {Array.from(Array(amount).keys()).map((n) => (
        <Box
          display="flex"
          flexDirection="column"
          key={n}
          paddingBottom={space.lg}
        >
          <Skeleton
            width={'100%'}
            height={80}
            primaryColor={neutral.gray5}
            borderRadius={radius.md}
            isFullWidth
          />
        </Box>
      ))}
    </div>
  );
};
