/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import useWindowDimensions from 'core/hooks/useWindowDimensions';
import withAppContext from 'core/hoc/withAppContext';

import { OmniChannelState } from 'store/messages/omniChannel/types';
import OmniChannelActions from 'store/messages/omniChannel/actions';

import * as S from './styles';

import { MessagesHeader } from 'components/Messages/OmniChannel/Messages/MessagesHeader';
import { MessagesList } from 'components/Messages/OmniChannel/Messages/MessagesList';
import TextAreaInput from 'components/Messages/OmniChannel/TextAreaInput';

import {
  RedirectMessageModal,
  ReportMessageModal,
  TransferTicketModal,
} from 'components/Messages/OmniChannel/Modals';

import ChatDetailModal from 'components/Messages/OmniChannel/Modals/ChatDetailModal';

import { MessagesEmptyState } from './emptyState';

const MessagesWrapper = (): JSX.Element => {
  const { t } = useTranslation('messages');
  const dispatch = useDispatch();
  const [message, setMessage] = useState('');

  const { screenHeight } = useWindowDimensions();

  const {
    fetchMessagesRequest,
    fetchTicketDetailRequest,
    setCreateNewMessageRequest,
    setCreateNewChatUserRequest,
    setEditMessageRequest,
    socketConnectRequest,
  } = OmniChannelActions;

  const {
    activeChat,
    activeTicket,
    activeChatUser,
    activeChannel,
    currentMessage,
    chatIsSearchTab,
    messages,
    isEditingMessage,
    isLoadingEditMessage,
    isLoadingMessages,
    isLoadingSendMessage,
    isNewChat,
    isReplyMessage,
  } = useSelector((state: OmniChannelState) => state.omniChannel);

  const chatTicketStatus = activeTicket?.attributes.status;

  const isChatTicket = activeChat?.attributes.kind === 'ticket';

  const hasAttendingClosed =
    activeChannel.attendingClosed &&
    !activeChannel.attendingHours?.blockOnlyNotification;

  const disabledChat =
    hasAttendingClosed ||
    (isChatTicket &&
      (chatTicketStatus === 'done' || chatTicketStatus === 'waiting'));

  const getDisabledChatMessage = () => {
    const disabledChatMessage = {
      done: t(`omni_channel.messages.new_message_chat_input_disabled_text`),
      waiting: t(`omni_channel.messages.new_message_chat_input_waiting_text`),
      in_attendance: '',
      attendingClosed: t(
        'omni_channel.messages.new_message_chat_input_attending_closed_text'
      ),
    };

    if (hasAttendingClosed) return disabledChatMessage['attendingClosed'];

    return disabledChatMessage[chatTicketStatus];
  };

  const onChangeMessage = (value: string) => setMessage(value);

  const handleSendMessage = useCallback(() => {
    const params = {
      chatId: activeChat.id,
      channelId: activeChannel.id,
      message,
      studentProfileId: activeChat.attributes.student_profile_id,
      userId: activeChat.relationships.main_user.data.id,
      userType: activeChat.relationships.main_user.data.type,
      chatKind: activeChat.attributes.kind,
      attachedMessageId: isReplyMessage && currentMessage.id,
      ticketId: activeTicket?.id || null,
    };
    dispatch(setCreateNewMessageRequest(params));
    setMessage('');
  }, [
    activeChannel,
    activeChat,
    currentMessage,
    dispatch,
    isReplyMessage,
    message,
    setCreateNewMessageRequest,
  ]);

  const handleCreateNewChat = useCallback(() => {
    const params = {
      channelId: activeChannel.id,
      message,
      recipient: [
        {
          kind: activeChatUser.attributes.kind,
          student_profile_id:
            activeChatUser.type === 'student_profile'
              ? activeChatUser.id
              : activeChatUser.attributes.student_id,
          user: {
            id: activeChatUser.id,
            type: activeChatUser.type,
          },
        },
      ],
    };
    dispatch(setCreateNewChatUserRequest(params));
    setMessage('');
  }, [
    activeChannel,
    activeChatUser,
    dispatch,
    message,
    setCreateNewChatUserRequest,
  ]);

  const handleEditMessage = useCallback(() => {
    const params = {
      chatId: activeChat.id,
      channelId: activeChannel.id,
      channelType: activeChannel.kind,
      messageId: currentMessage.id,
      message,
    };

    dispatch(setEditMessageRequest(params));
  }, [
    activeChannel,
    activeChat,
    currentMessage,
    dispatch,
    message,
    setEditMessageRequest,
  ]);

  const handleSubmitMessage = useCallback(() => {
    chatIsSearchTab && !activeChat
      ? handleCreateNewChat()
      : handleSendMessage();
  }, [activeChat, chatIsSearchTab, handleCreateNewChat, handleSendMessage]);

  const handleFetchTicketDetail = useCallback(
    (channelId: string, ticketId: string) => {
      if (!!activeTicket && activeTicket.id === ticketId) return;

      dispatch(
        fetchTicketDetailRequest({
          channelId,
          ticketId,
        })
      );
    },
    [dispatch, fetchTicketDetailRequest]
  );

  useEffect(() => {
    if (activeChat) {
      const params = {
        channelId: activeChannel.id,
        chatId: activeChat.id,
        page: 1,
      };
      activeChat && dispatch(fetchMessagesRequest(params));
      onChangeMessage('');
    }
  }, [activeChat?.id, dispatch, fetchMessagesRequest]);

  useEffect(() => {
    onChangeMessage(isEditingMessage ? currentMessage.attributes.text : '');
  }, [isEditingMessage]);

  useEffect(() => {
    !isEditingMessage && setMessage('');
  }, [isEditingMessage, isReplyMessage]);

  useEffect(() => {
    !!activeTicket &&
      activeChannel?.kind === 'ticket' &&
      handleFetchTicketDetail(activeChannel?.id, activeTicket?.id);
  }, [activeTicket?.id, activeTicket?.attributes.status]);

  useEffect(() => {
    dispatch(socketConnectRequest());
  }, []);

  return (
    <S.Wrapper isActiveChat={!!activeChat || !!activeChatUser}>
      {!activeChat && !activeChatUser ? (
        <MessagesEmptyState
          message={t(`omni_channel.messages.empty_state_text`)}
        />
      ) : (
        <>
          <S.MessagesWrapper height={screenHeight}>
            <MessagesHeader />

            {chatIsSearchTab && activeChatUser && !activeChat && (
              <MessagesEmptyState
                message={t(
                  `omni_channel.messages.empty_state_new_message_text`
                )}
              />
            )}

            {(messages || isLoadingMessages) && <MessagesList />}

            {!isLoadingMessages && (messages || isNewChat) && (
              <TextAreaInput
                disabled={disabledChat}
                disabledText={getDisabledChatMessage()}
                onSubmit={
                  isEditingMessage ? handleEditMessage : handleSubmitMessage
                }
                onChange={onChangeMessage}
                message={message}
                isLoading={isLoadingSendMessage || isLoadingEditMessage}
              />
            )}
          </S.MessagesWrapper>
          {activeChat?.id && <ChatDetailModal />}
          <RedirectMessageModal />
        </>
      )}

      <TransferTicketModal />
      <ReportMessageModal />
    </S.Wrapper>
  );
};

export default withAppContext(MessagesWrapper);
