import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import {
  fetchApi,
  postApi,
  putApi,
  patchApi,
  handleError,
} from 'core/utils/api';
import {
  buildToast,
  toastCss,
  ToastTypes,
} from 'store/middlewares/toast/actions';
import { push } from 'connected-react-router';

import actions from './actions';
import { CHECKOUT_METHODS } from 'core/constants';

export function* fetchEnrollmentPlansRequest(action) {
  try {
    const { dataArea } = yield select((state) => state.root);

    const { data, meta, itemsCountPerPage, totalItemsCount } = yield call(
      fetchApi,
      `/${dataArea}/enrollment_plans.json`,
      { params: action.params }
    );

    const activePage =
      action.params && action.params.page ? action.params.page : 1;

    yield put({
      type: actions.FETCH_ENROLLMENT_PLANS_SUCCESS,
      paginate: {
        activePage,
        itemsCountPerPage,
        totalItemsCount,
      },
      filters: { ...action.params },
      data,
      hasEnrollmentPlans: meta.has_enrollment_plans,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      toast: buildToast(
        'Não foi possível listar as matrículas',
        ToastTypes.error
      ),
      error,
    });
  }
}

export function* addEnrollmentPlanRequest(action) {
  try {
    const { dataArea } = yield select((state) => state.root);

    const { data } = yield call(
      postApi,
      `/${dataArea}/enrollment_plans.json`,
      action.params
    );

    yield put(push(`/schools/enrollment_plans/${data.attributes.id}/details`));

    yield put({
      type: actions.ADD_ENROLLMENT_PLAN_SUCCESS,
      toast: buildToast(
        'Matrícula cadastrada com sucesso.',
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        handleError(error),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* updateEnrollmentPlanRequest(action) {
  try {
    const { dataArea } = yield select((state) => state.root);
    const { id } = action.params;

    yield call(
      putApi,
      `/${dataArea}/enrollment_plans/${id}.json`,
      action.params
    );

    yield put(push(`/schools/enrollment_plans/${id}/details`));

    yield put({
      type: actions.UPDATE_ENROLLMENT_PLAN_SUCCESS,
      toast: buildToast(
        'Matrícula editada com sucesso.',
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        handleError(error),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* addMassEnrollmentPlanRequest(action) {
  try {
    const { dataArea } = yield select((state) => state.root);

    const { data } = yield call(
      postApi,
      `/${dataArea}/enrollment_plans/create_in_batches`,
      action.params
    );

    yield put(push(`/${dataArea}/school_products/tab/enrollment`));

    yield put({
      type: actions.ADD_MASS_ENROLLMENT_PLAN_SUCCESS,
      toast: buildToast(
        'Matrícula em massa cadastrada com sucesso.',
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        handleError(error),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* setCurrentEnrollmentPlanRequest({ enrollmentId }) {
  try {
    const { dataArea } = yield select((state) => state.root);

    const [{ data }, { data: signedFile }, { data: responsibles }] = yield all([
      call(
        fetchApi,
        `/${dataArea}/enrollment_plans/${enrollmentId}/details.json`
      ),
      call(
        fetchApi,
        `/${dataArea}/enrollment_plans/${enrollmentId}/signed_file.json`
      ),
      call(
        fetchApi,
        `/${dataArea}/enrollment_plans/${enrollmentId}/order.json`
      ),
    ]);

    yield put({
      type: actions.SET_CURRENT_ENROLLMENT_PLAN_SUCCESS,
      currentEnrollmentPlan: data,
      currentSignedFile: signedFile,
      currentResponsibleProfile:
        data.attributes.school_products_responsible_profiles.data,
      recipients: responsibles.attributes.responsible_profiles.data,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      toast: buildToast(
        'Não foi possível carregar a matrícula selecionada',
        ToastTypes.error
      ),
      error,
    });
  }
}

export function* fetchEditEnrollmentRequest({ enrollmentId }) {
  try {
    const { dataArea } = yield select((state) => state.root);

    const [{ data: enrollmentPlan }, { meta: classrooms }] = yield all([
      call(fetchApi, `/${dataArea}/enrollment_plans/${enrollmentId}/edit.json`),
      call(fetchApi, `/${dataArea}/school_products/new.json`),
    ]);

    const [
      { data: studentProfiles },
      { data: responsibleProfiles },
      { data: contractModels },
    ] = yield all([
      call(
        fetchApi,
        `/${dataArea}/school_products/classrooms/${enrollmentPlan.attributes.classroom_id}/students.json`
      ),
      call(
        fetchApi,
        `/${dataArea}/student_profiles/${enrollmentPlan.attributes.student_profile_id}/responsible_profiles.json`
      ),
      call(fetchApi, `/${dataArea}/enrollment_plans/contract_templates.json`),
    ]);

    yield put({
      type: actions.FETCH_EDIT_ENROLLMENT_SUCCESS,
      enrollmentPlan,
      classrooms: classrooms.select_options.classroom_id,
      studentProfiles,
      responsibleProfiles,
      contractModels,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      toast: buildToast(
        'Não foi possível carregar a matrícula selecionada',
        ToastTypes.error
      ),
      error,
    });
  }
}

function* setClassroomsRequest() {
  try {
    const { dataArea } = yield select((state) => state.root);

    const { meta } = yield call(
      fetchApi,
      `/${dataArea}/school_products/new.json`
    );

    yield put({
      type: actions.SET_CLASSROOMS_SUCCESS,
      classrooms: meta.select_options.classroom_id,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
    });
  }
}

function* setContractModelRequest(action) {
  try {
    const { dataArea } = yield select((state) => state.root);
    const activePage = action.params?.page;

    const url = `/${dataArea}/enrollment_plans/contract_templates.json`;

    const { data, itemsCountPerPage, totalItemsCount } = yield call(
      fetchApi,
      url,
      { params: { ...action.params, per_page: 15 } }
    );

    yield put({
      type: actions.SET_CONTRACT_MODEL_SUCCESS,
      contractModels: data,
      paginate: {
        activePage,
        itemsCountPerPage,
        totalItemsCount,
      },
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
    });
  }
}

function* setContractModelActivesRequest() {
  try {
    const { dataArea } = yield select((state) => state.root);

    const { data } = yield call(
      fetchApi,
      `/${dataArea}/enrollment_plans/contract_templates/active_contract_templates.json`
    );

    yield put({
      type: actions.SET_ACTIVE_CONTRACT_MODEL_SUCCESS,
      contractModels: data,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
    });
  }
}

function* setCancelContractModelRequest({ contractModelId }) {
  try {
    const { dataArea } = yield select((state) => state.root);

    const { data } = yield call(
      putApi,
      `/${dataArea}/enrollment_plans/contract_templates/${contractModelId}/cancel`
    );

    yield put({
      type: actions.SET_TOGGLE_CONTRACT_MODEL_SUCCESS,
      data,
      toast: buildToast(
        'Modelo do contrato desativado com sucesso',
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      toast: buildToast(
        'Não foi possível desativar o modelo do contrato',
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
      error,
    });
  }
}

function* setEnableContractModelRequest({ contractModelId }) {
  try {
    const { dataArea } = yield select((state) => state.root);

    const { data } = yield call(
      putApi,
      `/${dataArea}/enrollment_plans/contract_templates/${contractModelId}/activate`
    );

    yield put({
      type: actions.SET_TOGGLE_CONTRACT_MODEL_SUCCESS,
      data,
      toast: buildToast(
        'Modelo do contrato ativado com sucesso',
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      toast: buildToast(
        'Não foi possível ativar o modelo do contrato',
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
      error,
    });
  }
}

function* fetchStudentsRequest({ classroomId }) {
  try {
    const { dataArea } = yield select((state) => state.root);
    let studentProfiles = [];

    if (classroomId) {
      const { data } = yield call(
        fetchApi,
        `/${dataArea}/classrooms/${classroomId}/student_profiles.json?with_responsibles=true`
      );
      studentProfiles = data;
    }

    yield put({
      type: actions.FETCH_STUDENTS_SUCCESS,
      data: studentProfiles,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      toast: buildToast(
        'Não foi possível carregar os alunos',
        ToastTypes.error
      ),
      error,
    });
  }
}

function* fetchStudentsResponsiblesRequest({ classroomId }) {
  try {
    const { dataArea } = yield select((state) => state.root);
    let studentProfiles = [];

    if (classroomId) {
      const { data } = yield call(
        fetchApi,
        `/${dataArea}/classrooms/${classroomId}/student_profiles.json?with_responsibles=true&with_responsibles_array=true`
      );
      studentProfiles = data;
    }

    yield put({
      type: actions.FETCH_STUDENT_RESPONSIBLES_SUCCESS,
      data: studentProfiles,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      toast: buildToast(
        'Não foi possível carregar os responsáveis',
        ToastTypes.error
      ),
      error,
    });
  }
}

function* fetchRecipientsRequest({ studentId }) {
  try {
    const { dataArea } = yield select((state) => state.root);
    let responsibleProfiles = [];

    if (studentId) {
      const { data } = yield call(
        fetchApi,
        `/${dataArea}/student_profiles/${studentId}/responsible_profiles.json`
      );
      responsibleProfiles = data;
    }

    yield put({
      type: actions.FETCH_RECIPIENTS_SUCCESS,
      data: responsibleProfiles,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      toast: buildToast(
        'Não foi possível carregar os destinatários',
        ToastTypes.error
      ),
      error,
    });
  }
}

function* fetchHistoryEnrollmentRequest(action) {
  try {
    const { dataArea } = yield select((state) => state.root);

    const activePage =
      action.params && action.params.page ? action.params.page : 1;

    const { history, itemsCountPerPage, totalItemsCount } = yield call(
      fetchApi,
      `/${dataArea}/enrollment_plans/${action.enrollmentId}/history`,
      { params: action.params }
    );

    yield put({
      type: actions.FETCH_HISTORY_ENROLLMENT_SUCCESS,
      history,
      paginate: {
        activePage,
        itemsCountPerPage,
        totalItemsCount,
      },
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      toast: buildToast(
        'Não foi possível buscar o histórico',
        ToastTypes.error
      ),
      error,
    });
  }
}

function* approveEnrollmentPlanRequest({ enrollmentId }) {
  try {
    const { dataArea } = yield select((state) => state.root);

    yield call(
      putApi,
      `/${dataArea}/enrollment_plans/${enrollmentId}/approve.json`
    );

    const { data } = yield call(
      fetchApi,
      `/${dataArea}/enrollment_plans/${enrollmentId}/details.json`
    );

    yield put({
      type: actions.APPROVE_ENROLLMENT_PLAN_SUCCESS,
      data,
      toast: buildToast(
        'Matrícula aprovada com sucesso.',
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      toast: buildToast(
        'Não foi possível aprovar a matrícula',
        ToastTypes.error
      ),
      error,
    });
  }
}

export function* manuallyPayEnrollmentRequest({ orderId, responsibleId }) {
  try {
    const { dataArea } = yield select((state) => state.root);

    const { data } = yield call(
      postApi,
      `/${dataArea}/edupay/orders/${orderId}/checkout`,
      {
        edupay: { responsible_profile_id: responsibleId },
        event: 'manually_paid',
      }
    );

    yield put({
      type: actions.MANUALLY_PAY_ENROLLMENT_SUCCESS,
      id: data.attributes.orderable_id,
      toast: buildToast(
        'Matrícula marcada como paga com sucesso.',
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      toast: buildToast(
        'Não foi possivel marcar a matrícula como paga',
        ToastTypes.error
      ),
      error,
    });
  }
}

export function* setPaidAtSchoolOrder({ form, enrollmentId, orderId }) {
  try {
    const { dataArea } = yield select((state) => state.root);

    const {
      paymentmethod,
      divergentValue,
      responsibleProfileId,
      toggleDivergentValue,
      attachments,
      paidAtSchoolDate,
      paidAtSchoolNote,
    } = form;

    const signedId = [attachments[0]?.signed_id] || null;

    const { data } = yield call(
      patchApi,
      `/${dataArea}/enrollment_plans/${enrollmentId}/orders/${orderId}/manual_order_payment`,
      {
        responsible_profile_id: responsibleProfileId,
        observation: paidAtSchoolNote,
        method_payment: paymentmethod,
        paid_amount_cents: divergentValue,
        paid_at: paidAtSchoolDate,
        paid_with_different_amount: toggleDivergentValue,
        attachments: signedId,
      }
    );

    yield put({
      type: actions.SET_PAID_AT_SCHOOL_ORDER_SUCCESS,
      id: data.attributes.orderable_id,
      toast: buildToast(
        'Matrícula marcada como paga com sucesso.',
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        handleError(error),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

export function* checkout({ orderId, responsibleId, cpf, paymentMethod }) {
  try {
    const { dataArea } = yield select((state) => state.root);

    const { data } = yield call(
      postApi,
      `/${dataArea}/edupay/orders/${orderId}/checkout`,
      {
        edupay: {
          responsible_profile_id: responsibleId,
          cpf,
        },
        event: `school_generated_${paymentMethod}`,
      }
    );

    yield put({
      type: actions.CHECKOUT_SUCCESS,
      id: data.attributes.orderable_id,
      event: `generated_${paymentMethod}`,
      data,
      toast: buildToast(
        `${CHECKOUT_METHODS[paymentMethod]} gerado com sucesso.`,
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });

    yield put({
      type: actions.TOGGLE_PAYMENT_METHOD_LINK_MODAL,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR_CHECKOUT,
      toast: buildToast(
        `Não foi possivel gerar o ${CHECKOUT_METHODS[paymentMethod]}`,
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
      error,
    });
    yield put({
      type: actions.ERROR,
      error,
    });
  }
}

export function* paymentMethodLinkEnrollmentRequest({ enrollmentId }) {
  try {
    const { dataArea } = yield select((state) => state.root);

    const { data } = yield call(
      fetchApi,
      `/${dataArea}/enrollment_plans/${enrollmentId}/order.json`
    );

    yield put({
      type: actions.PAYMENT_METHOD_LINK_ENROLLMENT_SUCCESS,
      currentEnrollmentPlan: data,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        'Não foi possível carregar a matrícula selecionada',
        ToastTypes.error
      ),
    });
  }
}

function* cancelEnrollmentRequest({ enrollmentId, textCancel }) {
  try {
    const { dataArea } = yield select((state) => state.root);

    const { data } = yield call(
      putApi,
      `/${dataArea}/enrollment_plans/${enrollmentId}/cancel`,
      {
        school_product: {
          cancellation_description: textCancel,
        },
      }
    );

    yield put({
      type: actions.CANCEL_ENROLLMENT_SUCCESS,
      data,
      toast: buildToast(
        'Cobrança cancelada com sucesso.',
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        'Não foi possível cancelar a matrícula selecionada',
        ToastTypes.error
      ),
    });
  }
}

function* cancelEnrollmentRequestLD({ enrollmentId, form }) {
  try {
    const { dataArea } = yield select((state) => state.root);

    const { cancelNote, cancelTerm } = form;

    const { data } = yield call(
      putApi,
      `/${dataArea}/enrollment_plans/${enrollmentId}/manual_cancel`,
      {
        message: cancelNote,
        school_product: {
          cancellation_description: cancelTerm,
        },
      }
    );

    yield put({
      type: actions.CANCEL_ENROLLMENT_SUCCESS_LD,
      data,
      toast: buildToast(
        'Cobrança cancelada com sucesso.',
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        'Não foi possível cancelar a matrícula selecionada',
        ToastTypes.error
      ),
    });
  }
}

export function* addContractModelRequest(action) {
  try {
    const { dataArea } = yield select((state) => state.root);

    const { data } = yield call(
      postApi,
      `/${dataArea}/enrollment_plans/contract_templates`,
      action.data
    );

    yield put(push(`/${dataArea}/enrollment_plans/contract_templates`));

    yield put({
      type: actions.ADD_CONTRACT_MODEL_SUCCESS,
      data,
      toast: buildToast(
        'Modelo criado com sucesso.',
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        handleError(error),
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* enrollmentPlanSagas() {
  yield all([
    takeLatest(actions.ADD_ENROLLMENT_PLAN_REQUEST, addEnrollmentPlanRequest),
    takeLatest(
      actions.UPDATE_ENROLLMENT_PLAN_REQUEST,
      updateEnrollmentPlanRequest
    ),
    takeLatest(
      actions.ADD_MASS_ENROLLMENT_PLAN_REQUEST,
      addMassEnrollmentPlanRequest
    ),
    takeEvery(
      actions.SET_CURRENT_ENROLLMENT_PLAN_REQUEST,
      setCurrentEnrollmentPlanRequest
    ),
    takeEvery(
      actions.FETCH_EDIT_ENROLLMENT_REQUEST,
      fetchEditEnrollmentRequest
    ),
    takeLatest(
      actions.FETCH_ENROLLMENT_PLANS_REQUEST,
      fetchEnrollmentPlansRequest
    ),
    takeLatest(actions.SET_CLASSROOMS_REQUEST, setClassroomsRequest),
    takeLatest(actions.SET_CONTRACT_MODEL_REQUEST, setContractModelRequest),
    takeLatest(
      actions.SET_ACTIVE_CONTRACT_MODEL_REQUEST,
      setContractModelActivesRequest
    ),
    takeLatest(
      actions.SET_CANCEL_CONTRACT_MODEL_REQUEST,
      setCancelContractModelRequest
    ),
    takeLatest(
      actions.SET_ENABLE_CONTRACT_MODEL_REQUEST,
      setEnableContractModelRequest
    ),
    takeLatest(actions.FETCH_STUDENTS_REQUEST, fetchStudentsRequest),

    takeLatest(
      actions.FETCH_STUDENT_RESPONSIBLES_REQUEST,
      fetchStudentsResponsiblesRequest
    ),
    takeLatest(actions.FETCH_RECIPIENTS_REQUEST, fetchRecipientsRequest),
    takeLatest(
      actions.FETCH_HISTORY_ENROLLMENT_REQUEST,
      fetchHistoryEnrollmentRequest
    ),
    takeLatest(
      actions.APPROVE_ENROLLMENT_PLAN_REQUEST,
      approveEnrollmentPlanRequest
    ),
    takeLatest(
      actions.MANUALLY_PAY_ENROLLMENT_REQUEST,
      manuallyPayEnrollmentRequest
    ),
    takeLatest(actions.CHECKOUT, checkout),
    takeEvery(
      actions.PAYMENT_METHOD_LINK_ENROLLMENT_REQUEST,
      paymentMethodLinkEnrollmentRequest
    ),
    takeLatest(actions.CANCEL_ENROLLMENT_REQUEST, cancelEnrollmentRequest),
    takeLatest(actions.CANCEL_ENROLLMENT_REQUEST_LD, cancelEnrollmentRequestLD),
    takeLatest(actions.SET_PAID_AT_SCHOOL_ORDER_REQUEST, setPaidAtSchoolOrder),
    takeLatest(actions.ADD_CONTRACT_MODEL_REQUEST, addContractModelRequest),
  ]);
}

export default enrollmentPlanSagas;
