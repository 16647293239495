import React, { useContext, useEffect, useCallback } from 'react';

import Occurrence from 'pages/dailySummaries/DailySummaryForm/StudentSections/Card/Occurrence';

import withFormContext from 'core/hoc/withFormContext';
import DailySummariesContext from 'core/contexts/DailySummaries';

const OccurrencesList = () => {
  const {
    form: { occurrences },
    handleForm,
  } = useContext(DailySummariesContext);

  const addOccurrence = useCallback(() => {
    handleForm('occurrences', [
      ...occurrences,
      {
        id: Math.random().toString(36).substring(7),
        category: '',
        description: '',
      },
    ]);
  }, [handleForm, occurrences]);

  const removeOccurrence = (id) =>
    handleForm(
      'occurrences',
      occurrences.filter((occurrence) => occurrence.id !== id)
    );

  useEffect(() => {
    occurrences.length < 1 && addOccurrence();
  }, [addOccurrence, occurrences]);

  return occurrences.map((occurrence, index) => (
    <Occurrence
      occurrence={occurrence}
      addOccurrence={addOccurrence}
      removeOccurrence={removeOccurrence}
      key={index}
      index={index}
    />
  ));
};

export default withFormContext(OccurrencesList);
