import styled, { css } from 'styled-components';
import { ThemeProps } from 'config/themes/types';

import { CardList } from '@agendaedu/ae-web-components';

export const SchoolWalletHistoryWrapper = styled.div`
  ${({ theme: {} }: ThemeProps) => css``}
`;

export const WrapperNumberEntries = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 0;
`;

export const WrapperCardList = styled(CardList)`
  ${({ theme: { space } }: ThemeProps) => css`
    margin: ${space.sm} 0 0 0 !important;
    padding: 0 !important;
    box-shadow: none !important;
  `}
`;
