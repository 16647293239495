import React from 'react';

import './style.scss';

export default function FormBackendErrorWarning() {
  return (
    <div className="FormBackendErrorWarning container-fluid text-center">
      Houve um erro ao salvar as informações! Por favor, verifique os campos
      novamente.
    </div>
  );
}
