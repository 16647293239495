import React from 'react';
import PropTypes from 'prop-types';

import withFormContext, { formPropTypes } from 'core/hoc/withFormContext';

import { StyledRadioButton, StyledRadioLabel, RadioContainer } from './styles';

const RadioButtonSimple = ({
  attributeName,
  options,
  disabled,
  formContext,
  defaultValue,
  vertical,
}) => {
  const { updateAttribute } = formContext;

  const onChange = (event) => {
    if (disabled) return;

    updateAttribute(attributeName, event.target.value);
  };

  return (
    options.length > 0 && (
      <>
        <RadioContainer vertical={vertical}>
          {options.map((option) => {
            const optionId = `${attributeName}-${option.value}`;

            return (
              <div key={`radio-${optionId}`}>
                <StyledRadioButton
                  id={optionId}
                  type="radio"
                  name={attributeName}
                  onChange={onChange}
                  value={option.value}
                  disabled={disabled}
                  defaultChecked={option.value === defaultValue}
                />
                <StyledRadioLabel htmlFor={optionId}>
                  {option.label}
                </StyledRadioLabel>
              </div>
            );
          })}
        </RadioContainer>
      </>
    )
  );
};

RadioButtonSimple.defaultProps = {
  disabled: false,
  checked: false,
  options: [],
  vertical: false,
};

RadioButtonSimple.propTypes = {
  ...formPropTypes,
  attributeName: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.any,
  vertical: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
    })
  ).isRequired,
};

export default withFormContext(RadioButtonSimple);
