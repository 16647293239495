import React from 'react';

import * as S from './styles';
import { DefaultThemeProps, theme } from '@agendaedu/ae-web-components';
import { useTranslation } from 'react-i18next';
import { EmptyStateProps } from './types';

export const EmptyState = ({ tabName }: EmptyStateProps) => {
  const defaultTheme: DefaultThemeProps = theme;

  const {
    colors: { neutral },
  } = defaultTheme;

  const { t } = useTranslation('messages');
  return (
    <S.EmptyStateWrapper>
      <S.MessageWrapper>
        <S.IconMessage name="message-dots" color={neutral.gray3} />
        <S.EmptyStateLabel variant="subtitle-medium-16">
          {t(`omni_channel.chat.${tabName}_empty_state_text`)}
        </S.EmptyStateLabel>
      </S.MessageWrapper>
    </S.EmptyStateWrapper>
  );
};
