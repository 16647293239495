import styled, { css } from 'styled-components';
import { ThemeProps } from 'config/themes/types';

import { Grid, Select } from '@agendaedu/ae-web-components';

type WrapperFiltersProps = {
  isCreditCardInstallments: boolean;
  extractFrom: string;
};

export const WrapperFilters = styled(Grid)<WrapperFiltersProps>`
  ${({ theme: { space, colors, typography }, extractFrom }) => css`
    grid-template-areas: 'extract rangeDate export';
    grid-gap: ${space.sm} !important;
    align-items: flex-end;

    .btn-default.dropdown-toggle {
      border-color: #ccc !important;
    }

    .btn-secondary.dropdown-toggle {
      border-color: ${colors.brand.primary.light};

      &:hover {
        background-color: ${colors.brand.primary.op10};
        border-color: ${colors.brand.primary.op20};
      }

      &:active {
        background-color: ${colors.brand.primary.op20};
        border-color: #c7b8ed;
      }
    }

    .dropdown-toggle {
      height: ${space.xl2};
      width: 100px;
      border-radius: ${space.xs2};
      padding: 10px ${space.sm} !important;
      ${typography.button.Bold16}

      .Button.text {
        font-weight: 700;
      }

      i {
        display: none;
      }
    }

    .DayPicker {
      div > div:not(:last-child) > button:nth-last-child(-n + 3) {
        display: ${extractFrom === 'receivable' ? 'none' : 'auto'};
      }
      div > div:last-child > button {
        margin-top: ${extractFrom === 'receivable' ? '-24px' : 'auto'};
      }
    }
  `}
`;

export const SelectFilter = styled(Select)`
  ${({ theme: { colors } }: ThemeProps) => css`
    width: 260px !important;

    div {
      color: ${colors.neutral.gray2} !important;
    }
    ul {
      overflow: hidden;
    }
  `}
`;

export const ButtonExport = styled(Grid)`
  grid-column: auto / -1 !important;
  grid-column-start: 3;
  grid-column-end: 10;
  justify-content: flex-end;
`;

export const WrapperCardsBalance = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    gap: ${space.sm};
    margin: ${space.xl} 0;

    .Loader {
      margin: auto;
    }
  `}
`;

export const WrapperNumberEntries = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 0;
`;
