import React from 'react';

import AgendaIcon from 'components/AgendaIcon';
import PageSubTitle from 'components/PageSubTitle';

import './style.scss';

export default function MessagesEmptyChatDisplay() {
  return (
    <div className="MessagesEmptyChatDisplay">
      <AgendaIcon name="message-group" size="large" />
      <PageSubTitle>
        Selecione uma conversa e veja as mensagens aqui
      </PageSubTitle>
    </div>
  );
}
