import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Tooltip, { TOP_CENTER, TOP_RIGHT } from 'components/Tooltip';
import AgendaIcon from 'components/AgendaIcon';
import Currency from 'components/Currency';

import './MoneySummary.scss';

function MoneySummary(props) {
  const {
    title,
    price,
    complement,
    variation,
    tooltipMessage,
    tooltipPosition,
  } = props;

  return (
    <div className="MoneySummary">
      <div className="summary-title">
        {title}
        <Tooltip
          content={tooltipMessage}
          position={tooltipPosition}
        >
          <AgendaIcon name="help" size="small" className="custom-size" />
        </Tooltip>
      </div>
      <div className={`summary-price ${variation}`}>
        <Currency value={price} />
      </div>
      {complement}
    </div>
  );
}

MoneySummary.defaultProps = {
  variation: 'default',
  complementMessages: {
    default: '',
    success: 'pago na escola',
    warning: 'rejeitado',
    transfered: 'disponível',
  },
  tooltipPosition: TOP_CENTER,
};

MoneySummary.propTypes = {
  text: PropTypes.string,
  price: PropTypes.number,
  tooltipMessage: PropTypes.node,
  complementPrice: PropTypes.number,
  variation: PropTypes.string,
  title: PropTypes.string,
  complementMessages: PropTypes.object,
};

export default MoneySummary;
