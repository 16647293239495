/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useEffect } from 'react';

import { titleMobile } from 'core/utils/changeText';
import tabifyForWizard from 'core/hoc/tabifyForWizard';
import withFormContext, { formPropTypes } from 'core/hoc/withFormContext';
import { Box, Text, TextField } from '@agendaedu/ae-web-components';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as yup from 'yup';

const Information = ({
  formContext: { form, changeMeta, updateAttribute },
}) => {
  const { t } = useTranslation('albums');

  const initialValues = {
    title: form.title || '',
    description: form.description || '',
  };
  const formValidationSchema = yup.object().shape({
    title: yup
      .string()
      .required(t('album_form.info_tab.required_field'))
      .max(100, t('album_form.info_tab.title_message_error_text')),
    description: yup
      .string()
      .max(2000, t('album_form.info_tab.description_message_error_text')),
  });

  const { isValid, values, errors, setFieldValue } = useFormik({
    initialValues,
    onSubmit: () => {},
    validationSchema: formValidationSchema,
    validateOnChange: true,
    isInitialValid: Object.values(initialValues).some(Boolean),
  });

  useEffect(() => {
    changeMeta('nextStepDisabled', !isValid);
  }, [changeMeta, isValid]);

  useEffect(() => {
    updateAttribute('title', values.title);
    updateAttribute('description', values.description);
  }, [updateAttribute, values]);

  return (
    <Box px="sm">
      <Box mx="auto" maxWidth={600}>
        <TextField
          id="title"
          data-testid="title"
          fullWidth
          label={t('album_form.info_tab.title_label_text')}
          placeholder={t('album_form.info_tab.title_placeholder_text')}
          onChange={(e) => setFieldValue('title', e.target.value)}
          value={values.title}
          error={!!errors.title}
          errorMessage={`${errors.title}`}
        />

        <TextField
          data-testid="description"
          mt="md"
          fullWidth
          multiline
          rows={4}
          label={t('album_form.info_tab.description_label_text')}
          onChange={(e) => setFieldValue('description', e.target.value)}
          value={values.description}
          error={!!errors.description}
          errorMessage={`${errors.description}`}
        />

        <Text mt="md" variant="body-regular-12" color="neutral.gray1">
          {t('album_form.info_tab.required_fields')}
        </Text>
      </Box>
    </Box>
  );
};

Information.propTypes = {
  ...formPropTypes,
};

export default tabifyForWizard({
  title: titleMobile('1 Informações'),
  subTitle: 'Informações',
})(withFormContext(Information));
