import React, { useEffect } from 'react';
import getHocName from 'core/utils/getHocName';

const withFormInputFocused = (WrappedComponent) => {
  const WithFormInputFocused = (props) => {
    useEffect(() => {
      const formInputs = document.getElementsByClassName('form-control');
      formInputs[0].focus();
    }, []);

    return <WrappedComponent {...props} />;
  };

  WithFormInputFocused.displayName = getHocName(
    'WithFormInputFocused',
    WrappedComponent
  );

  return WithFormInputFocused;
};

export default withFormInputFocused;
