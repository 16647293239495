import React from 'react';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroller';
import PropTypes from 'prop-types';

import { EMPTY_USER } from 'core/constants/index';

import ModalContainer from 'components/Modal/ModalContainer';
import ModalTitle from 'components/Modal/ModalTitle';
import Card from 'components/Card';
import Loader from 'components/Loader';

import './style.scss';

const HistoryModal = ({
  isOpen,
  toggleModal,
  list,
  page,
  fetchHistory,
  resetHistory,
  hasMore,
}) => {
  const renderEntryTitle = (entry) => {
    return `${entry.attributes.event} por ${entry.attributes.school_user.data.attributes.name}`;
  };

  const renderEntrySubtitle = (entry) => {
    const start = moment(entry.attributes.created_at, 'DD/MM/YYYY HH:mm');
    const diff = start.valueOf() - moment().valueOf();

    return moment()
      .add(diff)
      .calendar(null, { sameElse: 'DD/MM/YYYY [às] HH:mm' });
  };

  const onCloseModal = () => {
    resetHistory();
    toggleModal();
  };

  const renderHistoryList = () => {
    return (
      <InfiniteScroll
        pageStart={page}
        useWindow={false}
        initialLoad={true}
        hasMore={hasMore}
        loader={<Loader key="history-loader" />}
        loadMore={fetchHistory}
        threshold={10}
      >
        {list.map((item, index) => {
          const itemCopy = {
            ...item,
            attributes: {
              ...item.attributes,
              school_user: item.attributes.school_user || EMPTY_USER,
            },
          };

          const customStyle = !itemCopy.attributes.school_user.id
            ? { backgroundColor: '#C2C2C2', color: '#DDD' }
            : null;

          return (
            <Card
              key={index}
              avatarSize="medium"
              avatar={itemCopy.attributes.school_user.data}
              customStyle={customStyle}
              title={renderEntryTitle(itemCopy)}
              subtitle={renderEntrySubtitle(itemCopy)}
            />
          );
        })}
      </InfiniteScroll>
    );
  };

  return (
    <ModalContainer
      className="history-modal"
      isOpen={isOpen}
      toggleModal={onCloseModal}
    >
      <ModalTitle>Histórico de ações</ModalTitle>
      {renderHistoryList()}
    </ModalContainer>
  );
};

HistoryModal.propTypes = {
  isOpen: PropTypes.bool,
  toggleModal: PropTypes.func.isRequired,
  list: PropTypes.arrayOf(PropTypes.object).isRequired,
  page: PropTypes.number,
  fetchHistory: PropTypes.func.isRequired,
  resetHistory: PropTypes.func.isRequired,
  hasMore: PropTypes.bool,
};

HistoryModal.defaultProps = {
  isOpen: false,
  page: 1,
  hasMore: false,
  list: [],
};

export default HistoryModal;
