import styled from 'styled-components';
import Tooltip from 'components/Tooltip';

export const CustomTooltip = styled(Tooltip)`
  .tooltip-div {
    width: 16px;
    height: 16px;
    margin-left: 4px;
    margin-top: 4px;
  }
`;

export const DateField = styled.div`
  .Field {
    .SingleDatePicker,
    .SingleDatePickerInput,
    .DateInput {
      width: 100%;
    }
    width: 100%;
    margin: 0;
    padding: 0;
  }
`;

export const NoteField = styled.div`
  .Field {
    padding: 0;
  }
  .Field.vertical .Label {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #666666;
    line-height: 24px;
  }
`;

export const LabelRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Label = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #666666;
  line-height: 24px;
`;

export const LabelCounter = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
`;

export const CounterWrapper = styled.div`
  display: flex;
  justify-content: right;
  margin-top: -20px;
`;

export const Information = styled.p`
  padding: 0;
  margin: 0px 0 0 0;

  font-family: 'Roboto', sans-serif;
  font-size: 16px;
`;

export const WantToNegotiateField = styled.div`
  .Field {
    margin: 0;
    padding: 0;
  }
`;

export const InformationAlert = styled.div`
  > .Alert {
    height: 90px;
  }
  margin-bottom: 10px;
`;
