/**
 * @param {String} hocName
 * @param {React.Component} WrappedComponent
 * @returns {String | undefined}
 */
export default function getHocName(hocName, WrappedComponent) {
  if (process.env.NODE_ENV === 'development') {
    return `${hocName}${WrappedComponent.displayName}`;
  }
}
