import styled from 'styled-components';

export const EditRecurrentBill = styled.div`
  .FormFieldset {
    padding: 0 15px;
  }

  .editPaymentMethod {
    .checkboxes-wrapper {
      padding: 0 15px;
      margin-bottom: 32px;
    }
  }

  .UserDisplay {
    flex: 1;
    margin-bottom: 16px;
  }

  .Title {
    font-size: 20px;
    margin-bottom: 32px;
    text-align: left;
  }

  .Alert {
    margin-bottom: 24px;
  }
`;

export const DoubleInput = styled.div`
  display: flex;
  justify-content: space-between;

  > .Field:only-child {
    padding-left: 15px;
  }

  .LabeledContainer {
    flex: 1;
    align-self: flex-start;
    margin-bottom: 0;
  }
`;
