import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import {
  buildToast,
  ToastTypes,
  toastCss,
} from 'store/middlewares/toast/actions';
import { fetchApi, postApi, putApi, deleteApi } from 'core/utils/api';
import actions from './actions';

function* fetchFormMeta() {
  try {
    const dataArea = yield select((state) => state.root.dataArea);

    const { meta } = yield call(
      fetchApi,
      `/${dataArea}/student_profiles/new.json`
    );

    yield put({
      type: actions.FETCH_FORM_META_SUCCESS,
      meta,
    });
  } catch (error) {
    yield put({
      type: actions.SET_ERROR,
      error,
    });
  }
}

function* setStudentProfile({ id }) {
  try {
    const dataArea = yield select((state) => state.root.dataArea);

    const { data, meta } = yield call(
      fetchApi,
      `/${dataArea}/student_profiles/${id}/edit.json`
    );

    yield put({
      type: actions.SET_STUDENT_PROFILE_SUCCESS,
      data,
      meta,
    });
  } catch (error) {
    yield put({
      type: actions.SET_ERROR,
      toast: buildToast('Erro ao tentar recuperar aluno(a).', ToastTypes.error),
      error,
    });
  }
}

function* setRecoveryStudentProfile({ id }) {
  try {
    const dataArea = yield select((state) => state.root.dataArea);

    const { data, meta } = yield call(
      fetchApi,
      `/${dataArea}/student_profiles/recover/${id}/edit.json`
    );

    yield put({
      type: actions.SET_RECOVERY_STUDENT_PROFILE_SUCCESS,
      data,
      meta,
    });
  } catch (error) {
    yield put({
      type: actions.SET_ERROR,
      toast: buildToast('Erro ao tentar recuperar aluno(a).', ToastTypes.error),
      error,
    });
  }
}

function* setReviewerStudentProfile({ id }) {
  try {
    const dataArea = yield select((state) => state.root.dataArea);

    const { data, meta } = yield call(
      fetchApi,
      `/${dataArea}/student_profiles/${id}/review.json`
    );

    yield put({
      type: actions.SET_REVIEWER_STUDENT_PROFILE_SUCCESS,
      data,
      meta,
    });
  } catch (error) {
    yield put({
      type: actions.SET_ERROR,
      toast: buildToast('Erro ao tentar recuperar aluno(a).', ToastTypes.error),
      error,
    });
  }
}

function* createStudentProfile({ params }) {
  try {
    const dataArea = yield select((state) => state.root.dataArea);

    yield call(postApi, `/${dataArea}/student_profiles`, params);

    window.location = `/${dataArea}/student_profiles`;

    yield put({
      type: actions.CREATE_STUDENT_PROFILE_SUCCESS,
      toast: buildToast(
        'Aluno(a) cadastrado com sucesso.',
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.SET_ERROR,
      toast: buildToast('Erro ao tentar cadastrar aluno(a).', ToastTypes.error),
      error,
    });
  }
}

function* updateStudentProfile({ id, params }) {
  try {
    const { form, redirect } = params;

    const dataArea = yield select((state) => state.root.dataArea);

    yield call(putApi, `/${dataArea}/student_profiles/${id}`, form);

    window.location = redirect;

    yield put({
      type: actions.UPDATE_STUDENT_PROFILE_SUCCESS,
      toast: buildToast(
        'Aluno(a) atualizado com sucesso.',
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.SET_ERROR,
      toast: buildToast('Erro ao tentar atualizar aluno(a).', ToastTypes.error),
      error,
    });
  }
}

function* approveStudentProfile({ id, params }) {
  try {
    const { form, redirect } = params;

    const dataArea = yield select((state) => state.root.dataArea);

    yield call(putApi, `/${dataArea}/student_profiles/${id}/approve`, form);

    window.location = redirect;

    yield put({
      type: actions.APPROVE_STUDENT_PROFILE_SUCCESS,
      toast: buildToast(
        'Aluno(a) aprovado com sucesso.',
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.SET_ERROR,
      toast: buildToast('Erro ao tentar aprovar aluno(a).', ToastTypes.error),
      error,
    });
  }
}

function* recoverStudentProfile({ id, params }) {
  try {
    const dataArea = yield select((state) => state.root.dataArea);

    yield call(putApi, `/${dataArea}/student_profiles/recover/${id}`, params);

    window.location = `/${dataArea}/student_profiles`;

    yield put({
      type: actions.RECOVER_STUDENT_PROFILE_SUCCESS,
      toast: buildToast(
        'Aluno(a) recuperado com sucesso.',
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.SET_ERROR,
      toast: buildToast('Erro ao tentar recuperar aluno(a).', ToastTypes.error),
      error,
    });
  }
}

function* removeStudentProfile({ id }) {
  try {
    const dataArea = yield select((state) => state.root.dataArea);

    yield call(deleteApi, `/${dataArea}/student_profiles/${id}/deny`);

    window.location = `/${dataArea}/student_profiles`;
    yield put({
      type: actions.REMOVE_STUDENT_PROFILE_SUCCESS,
      toast: buildToast(
        'Aluno(a) removido com sucesso.',
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.SET_ERROR,
      toast: buildToast('Erro ao tentar remover aluno(a).', ToastTypes.error),
      error,
    });
  }
}

function* studentProfilesSagas() {
  yield all([takeLatest(actions.FETCH_FORM_META, fetchFormMeta)]);
  yield all([takeLatest(actions.SET_STUDENT_PROFILE, setStudentProfile)]);
  yield all([
    takeLatest(actions.SET_RECOVERY_STUDENT_PROFILE, setRecoveryStudentProfile),
  ]);
  yield all([
    takeLatest(actions.SET_REVIEWER_STUDENT_PROFILE, setReviewerStudentProfile),
  ]);
  yield all([takeLatest(actions.CREATE_STUDENT_PROFILE, createStudentProfile)]);
  yield all([takeLatest(actions.UPDATE_STUDENT_PROFILE, updateStudentProfile)]);
  yield all([
    takeLatest(actions.APPROVE_STUDENT_PROFILE, approveStudentProfile),
  ]);
  yield all([
    takeLatest(actions.RECOVER_STUDENT_PROFILE, recoverStudentProfile),
  ]);
  yield all([takeLatest(actions.REMOVE_STUDENT_PROFILE, removeStudentProfile)]);
}

export default studentProfilesSagas;
