import { Alert, Text } from '@agendaedu/ae-web-components';
import styled from 'styled-components';

export const AlertWrapper = styled(Alert)`
  width: 100% !important;
  justify-content: flex-start !important;
`;

export const LinkTitle = styled(Text)`
  margin-bottom: 0;
  text-decoration: underline;
`;
