import React from 'react';
import { DefaultThemeProps, theme, Box } from '@agendaedu/ae-web-components';

import Skeleton from 'components/Skeleton';

export const InfoStepSkeleton = () => {
  const defaultTheme: DefaultThemeProps = theme;

  const {
    breakpoints,
    colors: { neutral },
    border: { radius },
  } = defaultTheme;

  return (
    <Box
      display="flex"
      position="relative"
      flex={1}
      flexDirection="column"
      justifyContent="space-between"
      mx={{ _: 'lg', mobileXS: 'xs2', desktopLG: 'xl5' }}
    >
      <Box width="100%" height="100%" mt="xl2" pb="md">
        <Box
          display="flex"
          flexDirection="column"
          maxWidth={breakpoints.tabletMD}
          mx="auto"
          mb="10vh"
          gap="md"
        >
          <Skeleton
            maxWidth={150}
            width="100%"
            height={20}
            primaryColor={neutral.gray3}
            borderRadius={radius.md}
            isFullWidth
          />

          <Box display="flex">
            <Skeleton
              width="100%"
              height={45}
              primaryColor={neutral.gray4}
              borderRadius={radius.md}
              isFullWidth
            />
          </Box>

          <Box display="flex" gap="xs2" flexDirection="column">
            <Skeleton
              width={60}
              height={20}
              primaryColor={neutral.gray3}
              borderRadius={radius.md}
            />
            <Skeleton
              width="100%"
              height={40}
              primaryColor={neutral.gray4}
              borderRadius={radius.md}
              isFullWidth
            />
          </Box>

          <Box display="flex" gap="xs2" flexDirection="column">
            <Skeleton
              width="100%"
              height={100}
              primaryColor={neutral.gray4}
              borderRadius={radius.md}
              isFullWidth
            />
          </Box>

          <Box
            display="flex"
            gap="sm"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Skeleton
              width="100%"
              height={40}
              primaryColor={neutral.gray4}
              borderRadius={radius.md}
              isFullWidth
            />
            <Skeleton
              width="100%"
              height={40}
              primaryColor={neutral.gray4}
              borderRadius={radius.md}
              isFullWidth
            />
          </Box>
          <Skeleton
            width="100%"
            height={50}
            primaryColor={neutral.gray4}
            borderRadius={radius.md}
            isFullWidth
          />
        </Box>
      </Box>
    </Box>
  );
};
