/**
 * This function is used to validate the file mime type for uploads.
 * @param {String} fileMimeType
 * @param {String} currentUserType - One of Responsible, Student or SchoolUser
 */
export default function validMimeType(fileMimeType, currentUserType) {
  const mimeTypesByUser = {
    SchoolUser: /^image\/(jpg|jpeg|png)|^application\/(pdf|msword|(vnd\.(ms-|openxmlformats-).*))|^audio\/(mpeg|x-mpeg|mp3|x-mp3|mpeg3|x-mpeg3|mpg|x-mpg|xmpegaudio)|^video\/(mp4|ogg|webm)/,
    default: /^image\/(jpg|jpeg|png)|^application\/(pdf|msword|(vnd\.(ms-|openxmlformats-).*))|^video\/(mp4)/,
  };

  const allowedMimeType =
    mimeTypesByUser[currentUserType] || mimeTypesByUser['default'];

  return Boolean(fileMimeType.match(allowedMimeType));
}
