import React from 'react';

import './style.scss';

import Table from 'components/Table';
import table from './table';

const ListPasswords = ({ generatedUsers }) => (
  <div className="ListPasswords">
    <Table columns={table} data={generatedUsers} />
  </div>
);

export default ListPasswords;
