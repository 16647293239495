import React from 'react';

import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { formatISODate } from 'core/utils/date';
import { normalizeFileSize } from 'core/utils/formatBytes';
import totalPriceItems from 'core/utils/totalPriceItems';
import { canEditRecurrentBill } from 'core/utils/recurrentBill';
import withAppContext from 'core/hoc/withAppContext';
import {
  renderPenalityMessage,
  renderDiscountMessage,
} from 'core/utils/edupay/functions';
import {
  ALLOWED_PAYMENT_METHODS,
  DISPLAY_TRANSACTION_ID_TO_STATUS,
  DISPLAY_STATUS_PAID,
} from 'core/constants/index';

import AgendaIcon from 'components/AgendaIcon';
import BillSummary from 'components/Payments/Recurrent/BillSummary';
import Button from 'components/Button';
import ButtonRow from 'components/ButtonRow';
import Currency from 'components/Currency';
import LabeledContainer from 'components/LabeledContainer';
import Loader from 'components/Loader';
import ModalContainer from 'components/Modal/ModalContainer';
import ModalTitle from 'components/Modal/ModalTitle';
import OutlineBox from 'components/OutlineBox';
import recurrentStatus from '../RecurrentStatus';
import Tooltip, { TOP_RIGHT } from 'components/Tooltip';
import UserDisplay from 'components/UserDisplay';
import Alert from 'components/Alert';

import './style.scss';

const ShowBillModal = ({ isOpen, toggleModal, bill }) => {
  const {
    currentUser: {
      attributes: { is_debug_user: isDebugUser },
    },
    policies: { recurrent_bill_legacy_id: recurrentBillLegacyId },
  } = useSelector((state) => state.root);

  const handleDisableEditButton = () => {
    let canEdit = canEditRecurrentBill(
      bill.attributes.status,
      bill.attributes.is_wallet_fidc,
      bill.attributes.is_wallet_guaranteed_monthly_fee
    );

    if (
      canEdit &&
      !isDebugUser &&
      bill.attributes.is_wallet_guaranteed_monthly_fee
    ) {
      canEdit = false;
    }

    return !canEdit;
  };

  const renderDisabledMessage = () => {
    if (
      handleDisableEditButton() &&
      bill.attributes.is_wallet_fidc &&
      isDebugUser
    ) {
      return (
        <div>
          Cobranças do FIDC com esse status
          <br />
          não podem ser alteradas
        </div>
      );
    }

    if (
      handleDisableEditButton() &&
      bill.attributes.is_wallet_guaranteed_monthly_fee
    ) {
      return (
        <div>
          Opção bloqueada devido à adesão
          <br />
          ao Módulo Mensalidade Garantida
        </div>
      );
    }

    return (
      <div>
        Não é possível editar cobranças
        <br />
        que já foram enviadas
      </div>
    );
  };

  const statusTag = () => (
    <>
      {bill && (
        <div className="tag-wrapper">
          <OutlineBox
            variation={recurrentStatus[bill.attributes.status].variation}
          >
            {recurrentStatus[bill.attributes.status].text}
          </OutlineBox>
          {bill.attributes.status === 'paid_late' && (
            <OutlineBox
              variation={
                recurrentStatus[bill.attributes.paid_payment_method].variation
              }
            >
              {recurrentStatus[bill.attributes.paid_payment_method].text}
            </OutlineBox>
          )}
        </div>
      )}
    </>
  );

  const handleEditBill = () => {
    toggleModal();
  };

  const renderInfoTransaction = () => {
    const {
      order: {
        attributes: { last_payment },
      },
    } = bill;

    if (Object.keys(last_payment).length === 0) return;

    return (
      <>
        <LabeledContainer title="ID da transação">
          <span className="detail-title pagarme-transaction-id">
            <strong>{last_payment.data.attributes.transaction_id}</strong>
          </span>
        </LabeledContainer>
        <LabeledContainer title="Provedor">
          <span className="detail-title payment-provider">
            <strong>{last_payment.data.attributes.payment_provider}</strong>
          </span>
        </LabeledContainer>
      </>
    );
  };

  const renderSummary = () => {
    const items = bill.attributes.recurrent_items.data.map(
      ({ attributes }) => ({
        discount_kind: attributes.discount_kind,
        discount_value:
          attributes.discount_kind === 'percent'
            ? attributes.discount_percent
            : attributes.discount_price_cents,
        recurrent_product_id: parseInt(attributes.product_id, 10),
        price: attributes.price_cents,
        product_name: attributes.product_name,
        status: attributes.product_status,
        key: Math.random().toString(36).substring(7),
      })
    );

    return items;
  };
  const getDiscount = () => {
    const { last_payment } = bill?.attributes.order?.data?.attributes;

    if (last_payment.data?.attributes?.discount) {
      const { discount } = last_payment.data?.attributes;
      const { attributes: attributesDiscount } = discount.data;

      const currentDiscount =
        attributesDiscount.kind === 'money'
          ? attributesDiscount.discount_price_cents
          : (bill?.attributes.price_cents *
              attributesDiscount.discount_percent) /
            100;

      return currentDiscount;
    }

    return 0;
  };

  return (
    <ModalContainer
      className="ShowBillModal"
      isOpen={isOpen}
      toggleModal={toggleModal}
      maxWidth={484}
    >
      {!bill ? (
        <Loader />
      ) : (
        <>
          <ModalTitle>Detalhes da cobrança</ModalTitle>
          {statusTag()}
          <LabeledContainer title="Aluno">
            <UserDisplay
              user={bill.studentProfile}
              classroomsNames={bill.studentProfile.attributes.classroom_names}
              size="large"
            />
          </LabeledContainer>

          <div className="wrapper-alert">
            {bill.attributes?.send_financial_responsible ? (
              bill.attributes?.name_financial_responsible?.length ? (
                <Alert variation="info">
                  {`Cobrança ${
                    bill.attributes?.status === 'pending' ? 'será' : ''
                  } enviada apenas ao(s) Responsável(is) Financeiro(s):`}
                </Alert>
              ) : (
                <Alert variation="info">
                  <p>Não há Responsável Financeiro cadastrado.</p>
                  {`A cobrança ${
                    bill.attributes?.status === 'pending' ? 'será' : 'foi'
                  } enviada a todos os responsáveis cadastrados e qualquer um
                  deles pode pagar.`}
                </Alert>
              )
            ) : (
              <Alert variation="info">
                {`Cobrança ${
                  bill.attributes?.status === 'pending' ? 'será' : ''
                } enviada aos responsáveis cadastrados. Qualquer
                  responsável pelo aluno pode pagar:`}
              </Alert>
            )}
          </div>

          <LabeledContainer title="Responsável(is) Financeiro(s)">
            {bill.attributes?.name_financial_responsible?.length ? (
              bill.attributes?.name_financial_responsible?.map((name) => (
                <p className="name-responsibles" key={name}>
                  {name}
                </p>
              ))
            ) : (
              <p className="message-no-responsible-found">
                Ainda não foram atribuídos.
              </p>
            )}
          </LabeledContainer>

          {(!bill.attributes?.send_financial_responsible ||
            !bill.attributes?.name_financial_responsible?.length) && (
            <LabeledContainer title="Demais Responsáveis">
              {bill.attributes?.name_not_financial_responsible?.length ? (
                bill.attributes?.name_not_financial_responsible.map((name) => (
                  <p className="name-responsibles" key={name}>
                    {name}
                  </p>
                ))
              ) : (
                <p className="message-no-responsible-found others-responsibles">
                  Aluno possuí apenas responsável(is) financeiro(s).
                </p>
              )}
            </LabeledContainer>
          )}

          <div className="double-column">
            <LabeledContainer title="ID da cobrança">
              {bill.id}
            </LabeledContainer>
            {bill.attributes?.legacy_id && recurrentBillLegacyId && (
              <LabeledContainer title="Identificacão da cobrança">
                {bill.attributes?.legacy_id}
              </LabeledContainer>
            )}
          </div>

          <LabeledContainer title="Título da recorrência">
            {bill.recurrentPlan.title}
          </LabeledContainer>
          <div className="double-column">
            <LabeledContainer title="Envio">
              {bill.attributes.release_day}
            </LabeledContainer>
            <LabeledContainer title="Vencimento">
              {bill.attributes.due_date_before_delay}
            </LabeledContainer>
            {bill.order?.attributes?.negotiated_at && (
              <LabeledContainer title="Negociação">
                {formatISODate(bill.order?.attributes?.negotiated_at)}
              </LabeledContainer>
            )}
            {bill.order?.attributes?.canceled_at && (
              <LabeledContainer title="Cancelado em">
                {formatISODate(bill.order?.attributes?.canceled_at)}
              </LabeledContainer>
            )}
          </div>
          <div className="double-column">
            <LabeledContainer title="Valor a pagar">
              <Currency value={bill.attributes.price_cents} />
            </LabeledContainer>
            {DISPLAY_STATUS_PAID.includes(bill.attributes.status) && (
              <LabeledContainer title="Valor recebido">
                <Currency value={bill.attributes.paid_price_cents} />
              </LabeledContainer>
            )}
          </div>

          <div className="double-column">
            {bill.attributes.due_date_before_delay !==
              bill.attributes.due_date && (
              <LabeledContainer title="Renegociação">
                {bill.attributes.due_date}
              </LabeledContainer>
            )}
            {DISPLAY_STATUS_PAID.includes(bill.attributes.status) && (
              <LabeledContainer title="Data de pagamento">
                {bill.attributes.order.data.attributes.paid_at}
              </LabeledContainer>
            )}
          </div>
          {bill.order?.attributes?.details && (
            <LabeledContainer title="Observação">
              {bill.order?.attributes?.details}
            </LabeledContainer>
          )}
          <LabeledContainer title="Método(s) de pagamento ">
            <div className="unique-column">
              <ul>
                {bill.attributes.allowed_payment_method.map((value) => (
                  <li key={value}>{ALLOWED_PAYMENT_METHODS[value]}</li>
                ))}
              </ul>
            </div>
          </LabeledContainer>
          {bill.attributes.has_penality && (
            <LabeledContainer title="Multas e juros">
              <div className="penality">
                {renderPenalityMessage(
                  bill.attributes.edupay_penality.data.attributes
                )}
              </div>
            </LabeledContainer>
          )}
          {bill.attributes.has_discount && (
            <LabeledContainer title="Desconto de pontualidade">
              <div className="unique-column">
                <ul>
                  {bill.attributes.edupay_discounts.data.map(
                    ({ attributes }) => (
                      <>{renderDiscountMessage(attributes)}</>
                    )
                  )}
                </ul>
              </div>
            </LabeledContainer>
          )}
          {bill &&
            isDebugUser &&
            DISPLAY_TRANSACTION_ID_TO_STATUS.includes(bill.attributes.status) &&
            renderInfoTransaction()}
          <LabeledContainer title="Carteira destino">
            <div className="unique-column">
              {bill.attributes.recipient_wallet_name}
            </div>
          </LabeledContainer>
          {bill.order.attributes.attachment && (
            <LabeledContainer title="Anexo">
              <a
                className="attachment-wrapper"
                href={bill.order.attributes.attachment.url}
                target="_blank"
                rel="noopener noreferrer"
                download
              >
                <div className="pdf-name">
                  <AgendaIcon name="attachment" />
                  <p>{bill.order.attributes.attachment.name}</p>
                </div>
                <div className="size">
                  {normalizeFileSize(bill.order.attributes.attachment.size)}
                </div>
              </a>
            </LabeledContainer>
          )}
          <LabeledContainer title="Resumo da cobrança">
            <BillSummary
              items={renderSummary()}
              discount={getDiscount()}
              allowedPaymentMethod={bill.attributes.allowed_payment_method}
              price={totalPriceItems(bill.attributes.recurrent_items.data)}
              billetTax={bill.attributes.billet_tax}
              pixTax={bill.attributes.pix_tax}
              status={bill.attributes.status}
              penality={
                bill.attributes.has_penality
                  ? bill.attributes.edupay_penality.data.attributes
                  : null
              }
            />
          </LabeledContainer>
          <ButtonRow>
            <Button variation="secondary" onClick={() => toggleModal()}>
              Fechar
            </Button>
            {handleDisableEditButton() ? (
              <Tooltip content={renderDisabledMessage} position={TOP_RIGHT}>
                <Button disabled={handleDisableEditButton()}>Editar</Button>
              </Tooltip>
            ) : (
              <Link
                to={`/schools/recurrent/recurrent_plans/${bill.attributes.recurrent_plan_id}/recurrent_bills/${bill.id}/edit`}
              >
                <Button onClick={handleEditBill} variation="primary">
                  Editar
                </Button>
              </Link>
            )}
          </ButtonRow>
        </>
      )}
    </ModalContainer>
  );
};

ShowBillModal.propTypes = {
  isLoading: PropTypes.bool,
  isOpen: PropTypes.bool,
  toggleModal: PropTypes.func.isRequired,
  bill: PropTypes.object,
};

export default withAppContext(ShowBillModal);
