import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import ButtonRow from 'components/ButtonRow';
import Button from 'components/Button';
import AgendaIcon from 'components/AgendaIcon';
import importWebActions from 'store/importWeb/actions';
import { RIGHT_CENTER } from 'components/Tooltip';
import { PERIODS } from '../../../constants';
import withWindowPathname, {
  windowPropTypes,
} from 'core/hoc/withWindowPathname';
import * as S from './styles';

const TableField = ({ data, property, windowPathname }) => {
  const [inputValue, setInputValue] = useState('');
  const {
    validatedFile: { rowErrors },
    importStep,
  } = useSelector((state) => state.importWeb);
  const { validateFileRow } = importWebActions;

  const importedInto = windowPathname.split('/')[3];

  const dispatch = useDispatch();

  const onChange = useCallback((e) => {
    setInputValue(e.target.value);
  }, []);

  const handleClearInput = useCallback(() => {
    setInputValue('');
  }, []);

  const rowHasError =
    rowErrors.length &&
    rowErrors.find(
      (error) =>
        error.id === data.id &&
        error.attributes.find((attr) => attr.title === property)
    );

  const error =
    rowHasError &&
    rowHasError.attributes.find((item) => item.title === property);

  const handleValidateFileRow = useCallback(() => {
    const updatedContent = data.attributes.updated_content
      ? { ...data.attributes.updated_content }
      : { ...data.attributes.original_content };
    updatedContent[property] = inputValue;
    data.attributes.updated_content = updatedContent;
    dispatch(validateFileRow(importedInto, data));
  }, [data, dispatch, validateFileRow, inputValue, property, importedInto]);

  const getInputValue = useCallback(() => {
    const value =
      data && data.attributes.updated_content
        ? data.attributes.updated_content[property]
        : data.attributes.original_content[property];

    setInputValue(value);
  }, [data, property]);

  const editableField = (
    <S.Container className="TableField" data-testid="TableField">
      <input
        type="text"
        data-testid="TableInput"
        className="form-control table-input"
        value={inputValue}
        onChange={onChange}
        onBlur={handleValidateFileRow}
      />
      <div className="input-actions">
        <ButtonRow>
          <Button className="clear" onClick={handleClearInput}>
            <AgendaIcon name="close" size="small" />
          </Button>

          <S.CustomTooltip
            tooltipStyle={{ width: '240px' }}
            content={error && error.detail}
            position={RIGHT_CENTER}
          >
            <AgendaIcon name="warning" size="small" />
          </S.CustomTooltip>
        </ButtonRow>
      </div>
    </S.Container>
  );

  useEffect(() => {
    data && getInputValue();
  }, [data, getInputValue]);

  return (
    <React.Fragment>
      {rowHasError && importStep === 3 ? (
        editableField
      ) : (
        <span>{property === 'period' ? PERIODS[inputValue] : inputValue}</span>
      )}
    </React.Fragment>
  );
};

TableField.propTypes = {
  ...windowPropTypes,
  data: PropTypes.object,
  property: PropTypes.string,
};

export default withWindowPathname(TableField);
