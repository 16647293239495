import React from 'react';
import ToggleSwitch from 'components/Form/ToggleSwitch';
import Field from 'components/Form/Field';
import withFormContext, { formPropTypes } from 'core/hoc/withFormContext';
import * as S from './styles';

const ResponsibleAddressForm = ({
  formContext: { form, formMeta, updateAttribute },
}) => {
  const { financial } = form;
  const { stateSelectOptions } = formMeta;

  const toggleSwitch = () => {
    updateAttribute('financial', !financial);
  };

  const onChangeState = (event) => {
    updateAttribute('state', event.value);
  };

  return (
    <S.Wrapper>
      <S.ToggleWrapper>
        <ToggleSwitch
          testID="financial"
          toggled={financial}
          onChange={toggleSwitch}
        />
        <p>É o responsável financeiro pelo(s) aluno(s) vinculados?</p>
      </S.ToggleWrapper>

      {financial && (
        <React.Fragment>
          <div className="row">
            <S.InputsWrapper variation="col1x3">
              <Field
                label="CEP"
                placeholder=""
                type="masked"
                mask="99999-999"
                attributeName="cep"
              />
              <Field label="Endereço" placeholder="" attributeName="street" />
              <Field
                label="Número"
                placeholder=""
                attributeName="street_number"
              />
            </S.InputsWrapper>
          </div>

          <div className="row">
            <S.InputsWrapper>
              <Field
                label="Complemento"
                placeholder=""
                attributeName="complement"
              />
              <Field
                label="Bairro"
                placeholder=""
                attributeName="neighborhood"
              />
            </S.InputsWrapper>
          </div>

          <div className="row">
            <S.InputsWrapper variation="col4x1">
              <Field label="Cidade" placeholder="" attributeName="city" />
              <Field
                fullWidth
                withArrowSelect
                label="Estado"
                type="selectWithIcon"
                attributeName="state"
                value={form.state}
                defaultValue=" "
                onChange={onChangeState}
                options={stateSelectOptions?.map((state) => ({
                  label: state.name,
                  value: state.name,
                }))}
                placeholder="Selecione"
              />
            </S.InputsWrapper>
          </div>
        </React.Fragment>
      )}
    </S.Wrapper>
  );
};

ResponsibleAddressForm.propTypes = {
  ...formPropTypes,
};

export default withFormContext(ResponsibleAddressForm);
