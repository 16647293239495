import React from 'react';

import { Trans, useTranslation } from 'react-i18next';

import AlbumActions from 'store/albums/actions';

import { Box, Button, Modal, Text } from '@agendaedu/ae-web-components';
import { Props } from './types';
import { useDispatch } from 'react-redux';

const ApprovePendingsAlbumModal = ({
  album,
  isOpen,
  onClose,
}: Props): JSX.Element => {
  const { t } = useTranslation(['albums', 'common']);
  const dispatch = useDispatch();

  const { approvePendingsAlbum } = AlbumActions;

  const onConfirmeApprove = () => {
    dispatch(approvePendingsAlbum(album.id));
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      title={t('album_details.approve_pendings_album_modal.title')}
      onClose={onClose}
    >
      <Box width="100%">
        <Text
          data-testid="text-content-modal"
          variant="body-regular-16"
          mx="xs3"
          maxWidth={360}
          lineHeight="lg"
        >
          <Trans>
            {t('album_details.approve_pendings_album_modal.content', {
              count: album.attributes.totalNumberOfPendingPhotos,
            })}
          </Trans>
        </Text>

        <Box display="flex" justifyContent="flex-end" mt="lg">
          <Button
            id="cancel-album-approve-button"
            testId="back-button"
            variant="secondary"
            mr="sm"
            onClick={onClose}
          >
            {t('common:button.back')}
          </Button>
          <Button
            id="confirm-album-approve-button"
            testId="approve-button"
            onClick={onConfirmeApprove}
          >
            {t('common:button.approve')}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ApprovePendingsAlbumModal;
