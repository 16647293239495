import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IMAGES_SURVERY_URL } from 'core/constants/index';
import smoothScrollTo from 'core/utils/smoothScrollTo';

import Loader from 'components/Loader';
import EmptyState from 'components/EmptyState';
import MetricsContainer from 'components/MetricsContainer';
import AnswersFilter from './AnswersFilter';
import AnswersList from './AnswersList';
import { Button } from '@agendaedu/ae-web-components';

import SurveyActions from 'store/surveys/actions';

const AnswersTab = ({ surveyId }) => {
  const dispatch = useDispatch();
  const { fetchSurveyQuestions } = SurveyActions;

  const [isTheFirstLoad, setIsTheFirstLoad] = useState(true);
  const [surveyQuestionsParams, setSurveyQuestionsParams] = useState({
    page: 1,
  });

  useEffect(() => {
    dispatch(fetchSurveyQuestions(surveyId, surveyQuestionsParams));
    if (isTheFirstLoad) setIsTheFirstLoad(false);
    smoothScrollTo(0, 0);
  }, [dispatch, fetchSurveyQuestions, surveyQuestionsParams]);

  const dataArea = useSelector((state) => state.root.dataArea);
  const isLoading = useSelector((state) => state.surveys.isLoading);
  const surveyQuestions = useSelector((state) => state.surveys.surveyQuestions);

  const isQueryFiltered = () =>
    surveyQuestionsParams.situation || surveyQuestionsParams.classroom;

  const changeSurveyQuestionsParams = (newParams) =>
    setSurveyQuestionsParams({ ...surveyQuestionsParams, ...newParams });

  const generateQueryParams = () => {
    const { id, receiver, situation, classroom } = surveyQuestionsParams;

    let query = [];

    if (id) query.push(`id=${id}`);
    if (receiver) query.push(`receiver=${receiver}`);
    if (situation) query.push(`situation=${situation}`);
    if (classroom) query.push(`classroom=${classroom}`);

    return query.length ? `?${query.join('&')}` : '';
  };

  const exportSurveyAnswers = () => {
    const questionId = surveyQuestions.meta.survey_questions.find(
      (question) => question.selected
    ).id;
    const url = `/${dataArea}/surveys/${surveyId}/survey_questions/${questionId}/answers_report.xls/${generateQueryParams()}`;
    window.open(url, '_blank');
  };

  const renderEmptyStateMessage = () => {
    if (isQueryFiltered()) {
      return (
        <p className="empty-text">
          Não encontramos resultados <br />
          para os filtros aplicados
        </p>
      );
    } else {
      return (
        <p className="empty-text">
          Ainda não há respostas <br />
          para esta enquete
        </p>
      );
    }
  };

  const renderAnswersFilter = () => {
    return (
      <AnswersFilter
        selectedRecipient={surveyQuestions.meta.survey_receivers.selected}
        changeSurveyQuestionsParams={changeSurveyQuestionsParams}
        surveyQuestionsMeta={surveyQuestions.meta}
      />
    );
  };

  const renderAnswersList = () => {
    if (
      surveyQuestions.data.length > 0 &&
      surveyQuestions.meta.survey_questions.length > 0
    ) {
      return (
        <>
          {renderAnswersFilter()}
          <Loader isLoading={isLoading}>
            <AnswersList
              surveyQuestionsData={surveyQuestions.data}
              changeSurveyQuestionsParams={changeSurveyQuestionsParams}
              activePage={surveyQuestionsParams.page}
            />
          </Loader>
        </>
      );
    } else {
      return (
        <>
          {isQueryFiltered() && renderAnswersFilter()}
          <Loader isLoading={isLoading}>
            <EmptyState
              imgUrl={IMAGES_SURVERY_URL.emptyStateUrl}
              message={renderEmptyStateMessage()}
            />
          </Loader>
        </>
      );
    }
  };

  const metrics = [
    {
      label: 'Envios',
      value: surveyQuestions?.meta?.survey_info_count.sent,
      color: 'gray2',
    },
    {
      label: 'Visualizações',
      value: surveyQuestions?.meta?.survey_info_count.seen,
      color: 'info',
    },
    {
      label: 'Confirmações',
      value: surveyQuestions?.meta?.survey_info_count.answers,
      color: 'success',
    },
    {
      label: 'Pendente',
      value: surveyQuestions?.meta?.survey_info_count.pending,
      color: 'warning',
    },
  ];

  return (
    <Loader isLoading={isTheFirstLoad}>
      {surveyQuestions && (
        <div>
          <MetricsContainer metrics={metrics} />

          <Button
            variant="secondary"
            icon="file-download"
            onClick={exportSurveyAnswers}
            mt="sm"
          >
            Exportar
          </Button>

          {renderAnswersList()}
        </div>
      )}
    </Loader>
  );
};

export default AnswersTab;
