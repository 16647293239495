import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

function FormGroupSelectorSelectableOptionGroup({ children, onClick }) {

  return (
    <div className="SelectableOptionGroup" onClick={onClick}>
      {children}
    </div>
  );
}

FormGroupSelectorSelectableOptionGroup.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func
};

export default FormGroupSelectorSelectableOptionGroup;
