import styled, { css } from 'styled-components';
import ModalContainer from 'components/Modal/ModalContainer';
import { ThemeProps } from 'config/themes/types';

export const ModalWrapper = styled(ModalContainer)`
  .ReactModal__Content {
    width: 600px !important;
    padding: 32px !important;
    .title {
      width: 100%;
    }
  }
`;

export const ConteinerCharacterCount = styled.div`
  ${({ theme: { space, typography } }: ThemeProps) => css`
    ${typography.label.Medium14}
    text-align: right;
    margin-top: ${space.xs};
  `}
`;

export const ConteinerVerificationInput = styled.div`
  ${({ theme: { colors, typography, border } }: ThemeProps) => css`
    width: 100%;

    .styled-container {
      width: 100%;
      gap: 24px;
    }

    .styled-character {
      ${typography.label.Regular16}
      font-weight: 400px;
      border-radius: ${border.radius.md};
      border: 1px solid ${colors.neutral.gray4};
      line-height: 3;
    }

    .styled-character-inactive {
      background-color: ${colors.neutral.white};
    }

    .styled-character--selected {
      outline: 2px solid ${colors.brand.primary.default};
      color: ${colors.neutral.black};
    }
  `}
`;

export const ContainerResend = styled.div`
  cursor: pointer;
`;
