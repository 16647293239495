import { ThemeProps } from 'config/themes/types';
import styled, { css } from 'styled-components';
import { FileUploadProps } from './types';

export const FormContainer = styled.div`
  ${({ theme: { breakpoints } }: ThemeProps) => css`
    .Field {
      padding-left: 0px;
    }
    .FormFieldset {
      @media (min-width: ${breakpoints.desktopXL}) {
        margin-left: 25%;
      }
    }
  `}
`;

export const Title = styled.p`
  ${({ theme: { font, colors, space } }: ThemeProps) => css`
    font-family: ${font.family.brand};
    font-size: ${font.size.lg};
    font-weight: ${font.weight.bold};
    color: ${colors.neutral.black};
    margin-bottom: ${space.xl};
  `}
`;

type InfoTextProps = {
  textSize: 'sm' | 'xs';
};

export const InfoText = styled.p<InfoTextProps>`
  ${({
    theme: { colors, font, space },
    textSize,
  }: ThemeProps & InfoTextProps) => css`
    font-size: ${font.size[textSize]};
    color: ${colors.neutral.gray1};
    font-weight: ${font.weight.regular};
    margin-top: ${space.xs};
    margin-bottom: ${space.xs};
  `}
`;

export const FileUploadContainer = styled.div<FileUploadProps>`
  ${({ disabled }) => css`
    .Dropdown {
      ${disabled &&
      css`
        pointer-events: none;
        opacity: 0.4;
      `}
    }
  `}
`;

export const TagContainer = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    flex-wrap: wrap;
    gap: ${space.xs};
  `}
`;

export const Row = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    margin-bottom: ${space.lg};
  `}
`;
