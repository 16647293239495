/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import moment from 'moment';

import Toast from 'components/Toast';
import Button from 'components/Button';
import Field from 'components/Form/Field';
import FormContainer from 'components/Form/FormContainer';
import Breadcrumb from 'components/Breadcrumb';
import PageContainer from 'components/PageContainer';

import StudentsList from 'pages/dailySummaries/DailySummaryForm/StudentsList';
import DailySummaryInfoTab from './DailySummaryInfoTab';

import DailySummariesActions from 'store/dailySummaries/actions';

import * as S from './styles';

const DailySummaryDetails = ({ classroomId, history }) => {
  const dispatch = useDispatch();
  const { fetchClassroom, setDate, setSelectedsIds, fetchStudentSummary } =
    DailySummariesActions;

  const { date, classroom, classroomIncluded, studentsIdsSelecteds } =
    useSelector((state) => state.dailySummaries);

  const {
    attributes: {
      name,
      student_profiles: { data: studentProfilesList },
    },
  } = classroom;

  const getClassroomIncludedNames = (type) => {
    return classroomIncluded.find((info) => info.type === type)?.attributes
      .name;
  };

  const educationalStageName = getClassroomIncludedNames('educational_stage');
  const headquarterName = getClassroomIncludedNames('headquarter');

  const onChangeDate = (value) => {
    dispatch(setDate(moment(value)));
  };

  const getOnlyPastDates = (day) => {
    return day.isAfter(moment().endOf('day'));
  };

  const onSelectStudent = (id) => {
    dispatch(setSelectedsIds(id));
  };

  const searchPlaceholder = () =>
    `Buscar entre ${studentProfilesList.length} alunos`;

  const renderSchoolSubtitle = () => {
    if (!educationalStageName || !headquarterName) return '';

    return `${headquarterName} / ${educationalStageName}`;
  };

  const getFormURL = () => {
    history.push(`/schools/daily_summaries/classrooms/${classroomId}`);
  };

  const isBeforeToday = moment(date).isBefore(moment().startOf('day'));

  useEffect(() => {
    dispatch(fetchClassroom(classroomId, date.format('YYYY-MM-DD')));
  }, [classroomId, date]);

  useEffect(() => {
    if (studentProfilesList.length) {
      const firstStudentID = studentProfilesList[0].id;
      onSelectStudent([firstStudentID]);
    }
  }, [classroom]);

  useEffect(() => {
    if (studentsIdsSelecteds.length) {
      dispatch(fetchStudentSummary(studentsIdsSelecteds[0], classroomId, date));
    }
  }, [studentsIdsSelecteds]);

  const renderHeader = () => {
    return (
      <S.Header>
        <FormContainer variation="horizontal">
          <Field
            attributeName="date"
            type="date"
            onValueChange={(value) => onChangeDate(value)}
            defaultValue={date}
            showAllDays
            isOutsideRange={getOnlyPastDates}
            showClearDates={false}
          />
          <Button
            variation="primary"
            onClick={getFormURL}
            disabled={isBeforeToday}
          >
            Adicionar informações
          </Button>
        </FormContainer>
      </S.Header>
    );
  };

  return (
    <S.DailySummariesContainer>
      <PageContainer
        title="Detalhes do diário"
        subtitle={renderSchoolSubtitle()}
        breadcrumb={
          <Breadcrumb title="Diário" path="/schools/daily_summaries" />
        }
        header={renderHeader()}
      >
        <nav>
          <S.ClassroomTitle>{name}</S.ClassroomTitle>
          <StudentsList
            students={studentProfilesList}
            studentsIdsSelecteds={studentsIdsSelecteds}
            setStudentIdsSelecteds={onSelectStudent}
            isCheckable={false}
            placeholder={searchPlaceholder()}
            multiSelect={false}
          />
        </nav>
        <DailySummaryInfoTab isBeforeToday={isBeforeToday} />
      </PageContainer>
      <Toast />
    </S.DailySummariesContainer>
  );
};

DailySummaryDetails.propTypes = {
  classroomId: PropTypes.string.isRequired,
  history: PropTypes.object,
};

export default withRouter(DailySummaryDetails);
