import React from 'react';
import InviteAccountModal from 'components/Invite/InviteAccountModal';
import reduxProvider from 'core/hoc/reduxProvider';
import { IMAGES_INVITE_URL } from 'core/constants';
import { useTranslation } from 'react-i18next';

const InviteResponsiblesModalScreen = (): JSX.Element => {
  const { t } = useTranslation(['empty_state']);

  return (
    <div id="InviteResponsiblesModal" className="InviteResponsiblesModalScreen">
      <InviteAccountModal
        thumbUrl={IMAGES_INVITE_URL.inviteResponsiblesThumbnail}
        resource={'responsible'}
        title={t('responsibles.title')}
        text={t('responsibles.text')}
      />
    </div>
  );
};

export default reduxProvider(InviteResponsiblesModalScreen);
