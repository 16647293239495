import React, { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ResendEmailsModalProps } from './types';

import * as S from './styles';

import actionRecurrentPlans from 'store/edupay/recurrentPlans/actions';

import AgendaIcon from 'components/AgendaIcon';
import Button from 'components/Button';
import ButtonRow from 'components/ButtonRow';

const ResendBilletButton = () => {
  const [isOpen, setIsOpen] = useState(false);

  const dispatch = useDispatch();

  const { filters, resendEmails } = useSelector(
    (state: ResendEmailsModalProps) => state.recurrentPlans
  );

  const {
    fetchResendBillInformation,
    resendBillingRequest,
    clearResendBillingInfo,
  } = actionRecurrentPlans;

  const handleFetchResendBillInformation = useCallback(() => {
    dispatch(fetchResendBillInformation(filters));
  }, [dispatch, fetchResendBillInformation, filters]);

  const handleCloseModal = useCallback(() => {
    setIsOpen(false);
    dispatch(clearResendBillingInfo());
  }, [setIsOpen, dispatch, clearResendBillingInfo]);

  const handleSubmit = useCallback(() => {
    dispatch(resendBillingRequest({ resend_billing_ids: resendEmails.ids }));
    handleCloseModal();
  }, [dispatch, resendBillingRequest, handleCloseModal, resendEmails.ids]);

  useEffect(() => {
    if (resendEmails.resendEmailsEnabled) setIsOpen(true);
  }, [resendEmails]);

  return (
    <S.Wrapper>
      <Button
        testID="resend-billet-button"
        variation="white"
        onClick={handleFetchResendBillInformation}
      >
        <AgendaIcon name="mail" />
      </Button>
      <S.ResendEmailsModalWrapper
        isOpen={isOpen}
        toggleModal={handleCloseModal}
        className="ResendEmailsModal"
        maxWidth="490px"
      >
        <S.Title>Reenviar boletos em massa</S.Title>
        <S.Description>
          {`${resendEmails.count} boleto(s) de cobrança(s) em aberto serão reenviados para os responsáveis.Tem certeza que deseja reenviar?`}
        </S.Description>
        <ButtonRow>
          <Button variation="secondary" onClick={handleCloseModal}>
            Cancelar
          </Button>
          <Button onClick={handleSubmit}>Confirmar</Button>
        </ButtonRow>
      </S.ResendEmailsModalWrapper>
    </S.Wrapper>
  );
};

export default ResendBilletButton;
