import React, { useEffect } from 'react';
import reduxProvider from 'core/hoc/reduxProvider';
import { Modal, CardAvatar } from '@agendaedu/ae-web-components';
import { ModalConten } from './styles';
import { constants } from './constants';
import { useSelector } from 'react-redux';

const SwitchUserSso = () => {
  const searchParams = new URLSearchParams(window.location.search);

  const { railsEnv, host } = useSelector((state) => state.root);

  const clientID = searchParams.get('client_id');
  const redirectUri = searchParams.get('redirect_uri');
  const responseType = searchParams.get('response_type');
  const scope = searchParams.get('scope');

  const systemsBase = {
    development: {
      school: 'http://escola.agendaedu.localhost:3000/',
      web: 'http://web.agendaedu.localhost:3000/',
    },
    production: {
      school: 'https://escola.agendaedu.com/',
      web: 'https://web.agendaedu.com/',
    },
  };

  const mapStaging = (host: string) => {
    const hostSegment = host.split('.');
    const staging = hostSegment[0].split('-');

    return {
      school: `https://${staging[0]}-escola.${hostSegment[1]}.${hostSegment[2]}/`,
      web: `https://${staging[0]}-web.${hostSegment[1]}.${hostSegment[2]}/`,
    };
  };

  const baseEnv: { school: string; web: string } = systemsBase[railsEnv]
    ? systemsBase[railsEnv]
    : mapStaging(host);

  const handleSchool = () => {
    window.location.href = `${baseEnv.school}oauth/v2/authorize?client_id=${clientID}&redirect_uri=${redirectUri}&response_type=${responseType}`;
  };
  const handleStudentAndResponsible = () => {
    let newLocation = `${baseEnv.web}oauth/v2/authorize?client_id=${clientID}&redirect_uri=${redirectUri}&response_type=${responseType}`;

    if (scope) newLocation += `&scope=${scope}`;

    window.location.href = newLocation;
  };

  const HiddenElementsBackground = () => {
    document.getElementsByClassName('copyright')[0].style.visibility = 'hidden';
  };

  useEffect(() => {
    HiddenElementsBackground();
  }, []);

  return (
    <Modal title="Selecione seu perfil de acesso" isOpen isClosed={false}>
      <ModalConten>
        <CardAvatar
          buttonText="Escola"
          imageUrl={constants.IMG_SCHOOL}
          buttonOnClick={handleSchool}
        />

        <CardAvatar
          buttonText="Responsável"
          imageUrl={constants.IMG_RESPONSIBLE}
          buttonOnClick={handleStudentAndResponsible}
        />

        <CardAvatar
          buttonText="Aluno"
          imageUrl={constants.IMG_STUDENT}
          buttonOnClick={handleStudentAndResponsible}
        />
      </ModalConten>
    </Modal>
  );
};

export default reduxProvider(SwitchUserSso);
