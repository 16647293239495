import styled, { css } from 'styled-components';

export const TabsBoxTabButton = styled.button`
  ${({ theme, active }) => css`
    @media (max-width: 1140px) {
      flex-grow: 1;
      justify-content: center;
      &:last-child {
        border-start-end-radius: 10px;
        border: 0px;
      }
    }
    display: flex;
    cursor: pointer;
    height: 58px;
    padding: 20px;
    border: 0px;
    border-right: 1px solid #e0dfe7;
    background-color: ${active ? `white` : `#e0e1e7`};
    align-items: center;
    color: ${active && theme.primaryColor};
    &:first-child {
      border-start-start-radius: 10px;
    }
    &:hover {
      background-color: white;
      color: ${theme.primaryColor};
    }
    & > .AgendaIcon {
      margin-right: 10px;
      @media (max-width: 1140px) {
        display: flex;
      }
    }
  `}
`;

export const TabsBoxText = styled.span`
  display: block;
  text-transform: uppercase;
  @media (max-width: 1140px) {
    display: none;
  }
`;
