import React from 'react';
import PropTypes from 'prop-types';
import Switch from 'react-toggle-switch';

import './style.scss';

function ToggleSwitch({ onChange, toggled, disabled, testId }) {
  return (
    <div
      className={`ToggleSwitch ${disabled ? 'disabled' : ''}`}
      data-testid={`${testId}-${toggled ? 'enabled' : 'disabled'}`}
    >
      <Switch
        on={toggled}
        onClick={(e) => (disabled ? e.stopPropagation() : onChange())}
      />
    </div>
  );
}

ToggleSwitch.propTypes = {
  onChange: PropTypes.func.isRequired,
  toggled: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  testId: PropTypes.string,
};

ToggleSwitch.defaultProps = {
  disabled: false,
};

export default ToggleSwitch;
