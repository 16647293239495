const actions = {
  ADD_RECURRENT_PLAN: 'ADD_RECURRENT_PLAN/recurrentPlans',
  ADD_RECURRENT_PLAN_SUCCESS: 'ADD_RECURRENT_PLAN_SUCCESS/recurrentPlans',
  CANCEL_PLAN: 'CANCEL_PLAN/recurrentPlans',
  CANCEL_PLAN_SUCCESS: 'CANCEL_PLAN_SUCCESS/recurrentPlans',
  CANCEL_BILL_LD_SUCCESS: 'CANCEL_BILL_LD_SUCCESS/recurrentPlans',
  CANCEL_BILL_SUCCESS: 'CANCEL_BILL_SUCCESS/recurrentPlans',
  EDIT_RECURRENT_BILL_IN_BATCHES:
    'EDIT_RECURRENT_BILL_IN_BATCHES/recurrentPlans',
  EDIT_RECURRENT_BILL_IN_BATCHES_SUCCESS:
    'EDIT_RECURRENT_BILL_IN_BATCHES_SUCCESS/recurrentPlans',
  EDIT_RECURRENT_PLAN: 'EDIT_RECURRENT_PLAN/recurrentPlans',
  EDIT_RECURRENT_PLAN_SUCCESS: 'EDIT_RECURRENT_PLAN_SUCCESS/recurrentPlans',
  ERROR: 'ERROR/recurrentPlans',
  FETCH_REQUEST: 'FETCH_REQUEST/recurrentPlans',
  FETCH_SUCCESS: 'FETCH_SUCCESS/recurrentPlans',
  FETCH_RECIPIENTS: 'FETCH_RECIPIENTS/recurrentPlans',
  FETCH_RECIPIENTS_SUCCESS: 'FETCH_RECIPIENTS_SUCCESS/recurrentPlans',
  FETCH_RESEND_BILLS_INFORMATION:
    'FETCH_RESEND_BILLS_INFORMATION/recurrentPlans',
  FETCH_RESEND_BILLS_INFORMATION_SUCCESS:
    'FETCH_RESEND_BILLS_INFORMATION_SUCCESS/recurrentPlans',
  FETCH_IN_BATCH_EXTRA_INFORMATION:
    'FETCH_IN_BATCH_EXTRA_INFORMATION/recurrentPlans',
  FETCH_IN_BATCH_EXTRA_INFORMATION_SUCCESS:
    'FETCH_IN_BATCH_EXTRA_INFORMATION_SUCCESS/recurrentPlans',
  FETCH_STUDENTS: 'FETCH_STUDENTS/recurrentPlans',
  FETCH_STUDENTS_SUCCESS: 'FETCH_STUDENTS_SUCCESS/recurrentPlans',
  MANUALLY_PAY_SUCCESS: 'MANUALLY_PAY_SUCCESS/recurrentPlans',
  MANUALLY_PAY_LD_SUCCESS: 'MANUALLY_PAY_LD_SUCCESS/recurrentPlans',
  CHECKOUT_SUCCESS: 'CHECKOUT_SUCCESS/recurrentPlans',
  DELAY_BILL_SUCCESS: 'DELAY_BILL_SUCCESS/recurrentPlans',
  SET_BILL_PLAN_REQUEST: 'SET_BILL_PLAN_REQUEST/recurrentPlans',
  SET_BILL_PLAN_SUCCESS: 'SET_BILL_PLAN_SUCCESS/recurrentPlans',
  SET_CURRENT_BILL: 'SET_CURRENT_BILL/recurrentPlans',
  SET_PLAN_REQUEST: 'SET_PLAN_REQUEST/recurrentPlans',
  SET_PLAN_SUCCESS: 'SET_PLAN_SUCCESS/recurrentPlans',
  SET_VERSIONS_REQUEST: 'SET_VERSIONS_REQUEST/recurrentPlans',
  SET_VERSIONS_SUCCESS: 'SET_VERSIONS_SUCCESS/recurrentPlans',
  SET_SELECT_RECURRENT: 'SET_SELECT_RECURRENT/recurrentPlans',
  SET_SELECT_ALL_RECURRENT_REQUEST:
    'SET_SELECT_ALL_RECURRENT_REQUEST/recurrentPlans',
  SET_SELECT_ALL_RECURRENT_SUCCESS:
    'SET_SELECT_ALL_RECURRENT_SUCCESS/recurrentPlans',
  SET_ALL_IDS_ELEGIBLE_TO_EDIT: 'SET_ALL_IDS_ELEGIBLE_TO_EDIT/recurrentPlans',
  TOGGLE_MODAL: 'TOGGLE_MODAL/recurrentPlans',
  TOGGLE_CANCEL_MODAL: 'TOGGLE_CANCEL_MODAL/recurrentPlans',
  TOGGLE_DELAY_MODAL: 'TOGGLE_DELAY_MODAL/recurrentPlans',
  TOGGLE_SELECT_ALL_RECURRENT: 'TOGGLE_SELECT_ALL_RECURRENT/recurrentPlans',
  TOGGLE_MANUAL_DEAL_MODAL: 'TOGGLE_MANUAL_DEAL_MODAL/recurrentPlans',
  NEGOTIATION_PLAN_REQUEST: 'NEGOTIATION_PLAN_REQUEST/recurrentPlans',
  NEGOTIATION_PLAN_SUCCESS: 'NEGOTIATION_PLAN_SUCCESS/recurrentPlans',
  RESEND_BILLING_REQUEST: 'RESEND_BILLING_REQUEST/recurrentPlans',
  RESEND_BILLING_SUCCESS: 'RESEND_BILLING_SUCCESS/recurrentPlans',
  CLEAR_RESEND_BILLING_INFO: 'CLEAR_RESEND_BILLING_INFO/recurrentPlans',
  clearResendBillingInfo: () => ({ type: actions.CLEAR_RESEND_BILLING_INFO }),
  addRecurrentPlan: (params) => ({ type: actions.ADD_RECURRENT_PLAN, params }),
  cancelPlanRequest: (id) => ({ type: actions.CANCEL_PLAN, id }),
  editRecurrentPlan: (params) => ({
    type: actions.EDIT_RECURRENT_PLAN,
    params,
  }),
  editRecurrentBillInBatches: (params) => ({
    type: actions.EDIT_RECURRENT_BILL_IN_BATCHES,
    params,
  }),
  fetchRecurrentPlans: (params) => ({ type: actions.FETCH_REQUEST, params }),
  fetchRecipients: () => ({ type: actions.FETCH_RECIPIENTS }),
  fetchRecurrentInBatchExtraInformations: (params) => ({
    type: actions.FETCH_IN_BATCH_EXTRA_INFORMATION,
    params,
  }),
  fetchRequestStudents: (planId) => ({ type: actions.FETCH_STUDENTS, planId }),
  fetchResendBillInformation: (rangeDate) => ({
    type: actions.FETCH_RESEND_BILLS_INFORMATION,
    rangeDate,
  }),
  setCurrentBill: (id) => ({ type: actions.SET_CURRENT_BILL, id }),
  setBillrequest: (planId, billId) => ({
    type: actions.SET_BILL_PLAN_REQUEST,
    planId,
    billId,
  }),
  setPlanRequest: (id) => ({ type: actions.SET_PLAN_REQUEST, id }),
  setVersionsRequest: (id, params) => ({
    type: actions.SET_VERSIONS_REQUEST,
    id,
    params,
  }),
  setSelectRecurrent: (id) => ({ type: actions.SET_SELECT_RECURRENT, id }),
  setSelectAllRecurrentRequest: (params) => ({
    type: actions.SET_SELECT_ALL_RECURRENT_REQUEST,
    params,
  }),
  setAllIdsElegibleToEdit: () => ({
    type: actions.SET_ALL_IDS_ELEGIBLE_TO_EDIT,
  }),
  toggleModal: () => ({ type: actions.TOGGLE_MODAL }),
  toggleCancel: () => ({ type: actions.TOGGLE_CANCEL_MODAL }),
  toggleDelay: () => ({ type: actions.TOGGLE_DELAY_MODAL }),
  toggleSelectAllRecurrent: () => ({
    type: actions.TOGGLE_SELECT_ALL_RECURRENT,
  }),
  toggleManualDealModal: () => ({ type: actions.TOGGLE_MANUAL_DEAL_MODAL }),
  resendBillingRequest: (params) => ({
    type: actions.RESEND_BILLING_REQUEST,
    params,
  }),
};

export default actions;
