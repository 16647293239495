import { postApi } from 'core/utils/api';
const baseUrl = '/schools/marketplaces/offers';

async function disableProduct(id) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await postApi(`${baseUrl}/${id}/cancel`, id);
      resolve(data);
    } catch (error) {
      reject({
        message: 'Erro ao tentar desativar a oferta',
        detail: error,
      });
    }
  });
}

export default {
  disableProduct,
};
