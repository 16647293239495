function validateSentKind(input, state) {
  let result = false;

  if (input === 'student') {
    const object = state.classroom_with_student_profile_ids;
    Object.keys(object).forEach((key) => {
      if (object[key].length > 0) {
        result = true;
      }
    });
  } else if (input === 'classroom' && state.classroom_ids.length > 0) {
    result = true;
  }

  return result;
}

export default [
  {
    attributeName: 'sent_kind',
    validator: validateSentKind,
    message: 'Selecione as turmas ou alunos destinatários',
  },
];
