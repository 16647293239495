import React, { useEffect } from 'react';

import Field from 'components/Form/Field';
import Accordion from 'components/Accordion';
import ToggleSwitch from 'components/Form/ToggleSwitch';
import OptionsAttributes from '../OptionsAttributes';

import withFormContext from 'core/hoc/withFormContext';

const Question = ({
  question,
  totalNumberOfQuestions,
  index,
  toggleSwitch,
  cloneQuestion,
  removeQuestion,
  onChange,
  form,
  setForm,
  enableActions,
}) => {
  const isAuthorizationQuestion = index === 0;

  const sortOptions = (options) => {
    const selectedQuestionTypeIndex = options.findIndex(
      (option) => option.value === question.question_type
    );
    const selectedQuestionType = options.splice(
      selectedQuestionTypeIndex,
      1
    )[0];
    options.unshift(selectedQuestionType);
    return options;
  };

  const getQuestionTypeOptions = () => {
    const options = [
      { value: 'unique', label: 'Resposta única', icon: 'check' },
      { value: 'multiple', label: 'Resposta múltipla', icon: 'check-square' },
      { value: 'subjective', label: 'Resposta aberta', icon: 'align-left' },
    ];
    return sortOptions(options);
  };

  const actionItems = () => {
    if (!enableActions) {
      return null;
    }

    let actions = [
      {
        text: 'Duplicar',
        onClick: () => cloneQuestion(index),
      },
    ];
    if (totalNumberOfQuestions > 1) {
      actions.push({ text: 'Excluir', onClick: () => removeQuestion(index) });
    }
    return actions;
  };

  const updateValues = (attributeName, newValue) => {
    let newQuestion = question;

    if (attributeName === 'question_type' && newValue === 'subjective') {
      newQuestion.survey_options_attributes.forEach((option, optionIndex) => {
        delete form[`titleOption_${index}_${optionIndex}_${option.key}`];
      });
      newQuestion.survey_options_attributes = [];
      setForm(form);
    }

    newQuestion[attributeName] = newValue;

    onChange(index, newQuestion);
  };

  const renderAccordionTitle = () => {
    return (
      <React.Fragment>
        <span className="survey-question-title">{`Pergunta ${index + 1}`}</span>
      </React.Fragment>
    );
  };

  const renderRequiredQuestionToggle = () => {
    return (
      <div className="survey-toggle-question">
        <ToggleSwitch
          disabled={isAuthorizationQuestion}
          toggled={question.required}
          onChange={() => toggleSwitch(question.key)}
        />
        <span>Esta pergunta é obrigatória</span>
      </div>
    );
  };

  const renderAccordionBody = () => {
    return (
      <div className="accordion-question">
        <Field
          label="Pergunta"
          placeholder="Digite a pergunta"
          disabled={isAuthorizationQuestion}
          attributeName={`question_${index}`}
          onChange={(formState) =>
            updateValues(`question_${index}`, formState[`question_${index}`])
          }
        />

        <Field
          fullWidth="420px"
          label="Tipo de Resposta"
          size="half"
          disabled={isAuthorizationQuestion}
          attributeName="question_type"
          type="selectWithIcon"
          value={question.question_type}
          options={getQuestionTypeOptions()}
          onChange={(formState) =>
            updateValues('question_type', formState.value)
          }
        />

        <OptionsAttributes
          onChange={onChange}
          indexQuestion={index}
          setForm={setForm}
        />
        {renderRequiredQuestionToggle()}
      </div>
    );
  };

  return (
    <Accordion
      header={renderAccordionTitle()}
      body={renderAccordionBody()}
      actions={!isAuthorizationQuestion && actionItems()}
      allowZeroExpanded
      expanded="survey"
    />
  );
};

export default withFormContext(Question);
