/* eslint-disable import/no-unresolved */
import axios from 'axios';
import {
  extractHeaders,
  extractBody,
  extractErrorsFromUnauthorizedRequest,
} from 'core/utils/request';

export const fetchApi = (url, params) =>
  new Promise((resolve, reject) => {
    axios
      .get(url, params)
      .then((response) => {
        const { data, meta, included } = response.data;
        const { itemsCountPerPage, totalItemsCount } = extractHeaders(response);
        const history = response.data;

        resolve({
          data,
          included,
          history,
          meta,
          itemsCountPerPage,
          totalItemsCount,
        });
      })
      .catch((error) => reject(error));
  });
export const postApi = (url, params) =>
  axios
    .post(url, params)
    .then((response) => response.data)
    .catch((error) => {
      throw {
        data: error.response?.data,
        status: error.response.status,
        statusText: error.response.statusText,
      };
    });

export const putApi = (url, params) =>
  axios.put(url, params).then((response) => response.data);
export const patchApi = (url, params) =>
  axios.patch(url, params).then((response) => response.data);
export const deleteApi = (url, params) =>
  axios.delete(url, params).then((response) => response.data);
export const handleError = (error, fallback = new Function()) => {
  const { response } = error;
  if (response) {
    const { status } = response;

    if (status === 422) {
      return extractBody(response).errors.full_messages.join('\n');
    } else if (status === 403) {
      return extractErrorsFromUnauthorizedRequest(response).join('\n');
    }
  }

  return fallback();
};
