import styled, { css } from 'styled-components';
import ModalContainer from 'components/Modal/ModalContainer';

export const ModalContainerContext = styled(ModalContainer)`
  ${({ theme }) => css`
    .FilterContainerWrapper {
      flex-wrap: wrap;
      align-content: center;
    }
    input {
      width: 100%;
      padding: 10px 16px;
      border-radius: 6px;
      border: solid 1px #dddddd;
    }

    .Form {
      margin: 0px;
      gap: 8px;
    }

    .TimeField {
      .input-group {
        border: 1px solid ${theme.gray12};
        border-radius: 8px;

        &:hover,
        &:active,
        &:focus {
          border-color: ${theme.primaryColor};
        }

        .input-group-addon,
        input {
          border: none;
        }
      }
    }

    .TimePickerInput {
      input {
        background-color: ${theme.white};
        border-color: #dddddd;
      }
    }

    .DateInput {
      input {
        border-color: ${theme.white};
      }
    }

    .ReactModal__Content .ButtonRow {
      margin: 0;
    }

    &.modalContainerContext .ReactModal__Content {
      overflow: initial !important;
    }

    .modal-buttons {
      display: flex;
      justify-content: flex-end;

      .btn-danger {
        background-color: ${theme.danger};
        color: ${theme.gray11};
        border-color: ${theme.danger};

        &:hover {
          background-color: rgba(${theme.danger}, 0.1);
          border-color: rgba(${theme.danger}, 0.1);
          color: ${theme.danger};
        }

        &:active {
          background-color: rgba(${theme.danger}, 0.2);
          border-color: rgba(${theme.danger}, 0.2);
          color: ${theme.danger};
        }
      }
    }
  `}
`;

export const ModalSubtitle = styled.div`
  ${({ theme }) => css`
    color: ${theme.gray2};
    font-size: 16px;
  `};
`;

export const Field = styled.div`
  ${() => css`
    display: block;
  `}
`;

export const FieldLabel = styled.label`
  ${({ theme, fontWeight }) => css`
    margin-bottom: 12px;
    color: ${theme.gray2};
    font-weight: ${fontWeight};
  `}
`;
