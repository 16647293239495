import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  FlexGrid,
  FlexGridItem,
  Box,
  Avatar,
  Text,
  RecipientTag,
} from '@agendaedu/ae-web-components';

import { MOOD_TRACKER_ICONS } from 'core/constants';

import * as S from './styles';
import { OwnProps } from './types';

const StudentCard = ({ student }: OwnProps) => {
  const {
    name,
    should_use_name_initials_avatar,
    name_initials,
    photo_url,
    avatar_color,
    mood_answer,
    classroom_names,
  } = student.attributes;
  const { t } = useTranslation(['mood_trackers']);
  const title = t(`mood_answer.${mood_answer}`);

  return (
    <S.CardContainer>
      <FlexGrid>
        <FlexGridItem cols={{ desktopLG: 6, mobileXS: 12 }}>
          <Box {...S.boxProps}>
            <Avatar
              avatarColor={avatar_color}
              avatarUrl={!should_use_name_initials_avatar && photo_url}
              nameInitials={name_initials}
              size="md"
            />
            <S.DescriptionColumn>
              <Text variant="subtitle-medium-12">{t('student')}</Text>
              <Text variant="subtitle-medium-16">{name}</Text>
            </S.DescriptionColumn>
          </Box>
        </FlexGridItem>
        <FlexGridItem cols={{ desktopLG: 3, mobileXS: 6 }}>
          <Box {...S.boxProps}>
            <RecipientTag
              variant="neutral"
              modalTitle={t('recipient_type')}
              recipientType={t('recipient_type')}
              modalButtonText={t('modal_button_text')}
              recipients={classroom_names.map((classroom) => ({
                name: classroom,
              }))}
            />
          </Box>
        </FlexGridItem>
        <FlexGridItem cols={{ desktopLG: 3, mobileXS: 6 }}>
          <Box {...S.boxProps}>
            {mood_answer && (
              <img
                src={MOOD_TRACKER_ICONS[mood_answer]}
                alt={title}
                data-testid="mood-image-answer"
              />
            )}
            <S.AnswerColumn hasMood={!!mood_answer}>
              <Text variant="subtitle-medium-12">{t('answer')}</Text>
              <Text variant="subtitle-medium-16">
                {mood_answer ? title : '—'}
              </Text>
            </S.AnswerColumn>
          </Box>
        </FlexGridItem>
      </FlexGrid>
    </S.CardContainer>
  );
};

export default StudentCard;
