import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  padding-bottom: 15px;

  .Button {
    margin-left: 15px;
    align-self: flex-start;
    width: 40px;
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-left: 15px;
  margin-bottom: 15px;
`;

export const InputContainer = styled.div`
  ${({ theme, disabled, hasError }) => css`
    width: fit-content;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    height: 40px;
    margin: 4px 24px 12px 0;

    input {
      height: 100%;
    }

    > * {
      height: 40px;
      border: 1px solid ${theme.gray3};
      border-radius: 6px 0 0 6px;

      ${({ disabled }) =>
        disabled &&
        css`
          background-color: ${theme.gray5};
        `}
    }

    > .AgendaIcon {
      border-right: none;
      border-color: ${hasError ? theme.danger : 'inherit'};
      color: ${hasError ? theme.danger : theme.gray3};
      border-radius: 6px 0 0 6px;
      line-height: 40px;
      padding: 0 5px;
      background-color: ${disabled ? theme.gray5 : 'inherit'};
    }

    input {
      border: none;
      font-size: 16px;
      border-color: ${hasError ? theme.danger : theme.gray3};
      font-family: 'Roboto';

      &:disabled {
        background-color: ${theme.gray5};
      }
      &:focus {
        border: none;
      }
    }

    > button {
      background-color: transparent;
      border-radius: 0 6px 6px 0;
      padding: 9px 10px;
      color: ${theme.danger};
      border-left: none;

      &:disabled {
        color: ${theme.gray3};
      }
    }
  `}
`;

export const FieldWithLabel = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Label = styled.label`
  ${({ theme }) => css`
    color: ${theme.gray1};
    font-weight: 400;
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    margin-top: 12px;
  `}
`;
