import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import normalizeParams from '../normalizeParams';
import PageContainer from 'components/PageContainer';
import Breadcrumb from 'components/Breadcrumb';
import EventForm from 'components/Events/EventForm';
import Loader from 'components/Loader';
import Toast from 'components/Toast';

import EventActions from 'store/events/actions';
import EventContext from '../eventContext';

import useGoogleDrive from 'core/hooks/useGoogleDrive';

const NewEvent = ({ googleClientId }) => {
  const dispatch = useDispatch();

  const { addEvent, setClassrooms } = EventActions;

  const sending = useSelector((state) => state.events.sending);
  const isLoading = useSelector((state) => state.events.isLoading);
  const form = useSelector((state) => state.events.current);
  const formMeta = useSelector((state) => state.events.formMeta);

  const SCOPE =
    'https://www.googleapis.com/auth/calendar.readonly https://www.googleapis.com/auth/calendar.events';

  const actions = useGoogleDrive(googleClientId, SCOPE);

  const fetchClassrooms = useCallback(() => {
    dispatch(setClassrooms());
  }, [dispatch, setClassrooms]);

  useEffect(() => {
    fetchClassrooms();
  }, []);

  const onSubmit = useCallback(
    (formContext) => {
      dispatch(addEvent(normalizeParams(formContext.form)));
    },
    [dispatch, addEvent]
  );

  return (
    <PageContainer
      title="Novo evento"
      className="NewEvent"
      breadcrumb={
        <Breadcrumb title="Eventos" path="/schools/events" external />
      }
    >
      <Loader isLoading={isLoading}>
        <EventContext.Provider value={actions}>
          <EventForm
            action="new"
            form={form}
            formMeta={{ googleClientId, ...formMeta }}
            onSubmit={onSubmit}
            isSubmitting={sending}
          />
          <Toast />
        </EventContext.Provider>
      </Loader>
    </PageContainer>
  );
};

NewEvent.propTypes = {
  googleClientId: PropTypes.string.isRequired,
};

export default NewEvent;
