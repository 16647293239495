/* eslint-disable @typescript-eslint/ban-ts-comment */

// @ts-ignore
import validatePresence from 'core/lib/FormValidator/validators/presence/';

// @ts-ignore
import validateLength from 'core/lib/FormValidator/validators/length/';
import validateTextAreaPresence from 'core/lib/FormValidator/validators/textAreaPresence';

export default [
  {
    attributeName: 'title',
    validator: validatePresence,
    message: 'required_field',
  },
  {
    attributeName: 'title',
    validator: validateLength({ max: 100 }),
    message: 'title',
  },
  {
    attributeName: 'description',
    validator: validateTextAreaPresence,
    message: 'Campo obrigatório',
  },
  {
    attributeName: 'categoryId',
    validator: validatePresence,
    message: 'required_field',
  },
] as const;
