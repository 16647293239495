import React from 'react';
import { RecurrentInBatchesFormProps } from './types';

import FormFullScreen from 'components/FormFullScreen';

const RecurrentInBatchesForm = ({
  action,
  onSubmit,
  steps,
  form,
  formMeta,
  isSubmitting,
  backTo,
  titlePage,
  titleModal,
  contentModal,
  children,
}: RecurrentInBatchesFormProps) => {
  return (
    <FormFullScreen
      action={action}
      onSubmit={onSubmit}
      form={form}
      formMeta={formMeta}
      isSubmitting={isSubmitting}
      steps={steps}
      backTo={backTo}
      titlePage={titlePage}
      titleModal={titleModal}
      contentModal={contentModal}
    >
      {!!children && children}
    </FormFullScreen>
  );
};

export default RecurrentInBatchesForm;
