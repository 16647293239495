import { DAYS_BEFORE_EXPIRATION, DISCOUNT_KINDS } from 'core/constants';

const numberArrayGenerator = (max, min) =>
  Array(...Array(min ? max - min : max)).map((_, i) => (min ? i + min : i + 1));

export default () => {
  const dueDay = numberArrayGenerator(28, 1)
    .map((i) => ({ value: i, label: `Todo dia ${i} do mês` }))
    .concat({ value: -1, label: 'Todo último dia do mês' });

  dueDay.unshift({ value: null, label: 'Selecionar dia do vencimento' });

  return {
    price_kind: DISCOUNT_KINDS,
    discount_kind: DISCOUNT_KINDS,
    due_day: dueDay,
    bill_release_day: [
      { value: null, label: 'Selecionar dia de envio das cobranças' },
      ...DAYS_BEFORE_EXPIRATION,
    ],
  };
};
