import React from 'react';
import withFormContext from 'core/hoc/withFormContext';
import { defaultIsOutsideRange } from 'core/utils/date';

import AgendaIcon from 'components/AgendaIcon';
import Field from 'components/Form/Field';
import Tooltip, { TOP_CENTER } from 'components/Tooltip';

import * as S from './styles';
import { RecurrencyConfigurationProps } from './types';

const RecurrencyConfiguration = ({
  formContext: { form, formMeta, updateAttribute },
}: RecurrencyConfigurationProps) => {
  const { select_options: selectOptions } = formMeta;

  const { due_day: dueDay, bill_release_day: billReleaseDay } = form;

  return (
    <S.RecurrencyConfigurationWrapper>
      <span>
        A frequência de envio da cobrança será <b>Mensal</b>
      </span>
      <S.DoubleInputWrapper>
        <div className="row double-input">
          <Field
            label="Vencimento da primeira cobrança*"
            type="date"
            attributeName="first_bill_due_date"
            placeholder="Selecionar"
            isOutsideRange={defaultIsOutsideRange}
          />
          <Field
            fullWidth
            withArrowSelect
            type="selectWithIcon"
            label="Vencimento das demais cobranças*"
            attributeName="due_day"
            options={selectOptions.due_day}
            value={dueDay}
            onChange={(e) => updateAttribute('due_day', e.value)}
          />
        </div>
      </S.DoubleInputWrapper>
      <div className="row double-input">
        <Field
          fullWidth
          withArrowSelect
          type="selectWithIcon"
          label="Dia de envio das cobranças*"
          attributeName="bill_release_day"
          options={selectOptions.bill_release_day}
          value={billReleaseDay}
          onChange={(e) => updateAttribute('bill_release_day', e.value)}
        />
      </div>
    </S.RecurrencyConfigurationWrapper>
  );
};

export default withFormContext(RecurrencyConfiguration);
