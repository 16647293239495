import styled, { css } from 'styled-components';

export const WrapperContent = styled.div`
  display: flex;
  overflow-y: auto;
  height: calc(100% - 87px);
  justify-content: space-between;
  flex-direction: column;

  ${({ theme }) => css`
    .Accordion {
      .accordion-title {
        margin: 0;
        font-family: Quicksand;
        font-size: ${theme.font.size.md};
      }

      #accordion__heading-albums {
        padding: 16px 24px;
        background-color: #ffffff;
        border: 0;
        border-bottom: 0;
        border-radius: 0;
      }

      #accordion__panel-albums {
        border: 0;
      }
    }
  `}
`;

export const Divider = styled.div`
  ${({ theme }) => css`
    margin: 0;
    border-top: 1px solid ${theme.colors.neutral.gray5};
    width: 100%;
  `}
`;
