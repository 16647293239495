import validatePresence from 'core/lib/FormValidator/validators/presence';

const validations = [];

export function addValidations(key) {
  validations.push({
    attributeName: `chart_title_${key}`,
    validator: validatePresence,
    message: 'Campo obrigatório',
  });

  validations.push({
    attributeName: `metabase_question_id_${key}`,
    validator: validatePresence,
    message: 'Campo obrigatório',
  });
}

export function removeValidations(key) {
  const newValidation = validations.filter(
    (item) =>
      ![`chart_title_${key}`, `metabase_question_id_${key}`].includes(
        item.attributeName
      )
  );

  validations.length = 0;
  validations.push(...newValidation);
}

export default validations;
