import styled, { css } from 'styled-components';

export const EdupayPenalityWrapper = styled.div`
  display: flex;
  flex-direction: column;

  .FormValidationErrors {
    display: flex;
    align-items: flex-start;

    ul > div {
      display: flex;
      align-items: flex-start;
    }
  }
`;

export const ContainerWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    border: 1px solid ${theme.gray12};
    border-radius: 6px;
    background: ${theme.gray14};
    margin-top: 24px;
    padding: 24px 16px;
  `}
`;

export const EdupayPenality = styled.div`
  gap: 16px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
`;

export const Text = styled.p`
  ${({ theme }) => css`
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: ${theme.gray2};
    line-height: 24px;
    margin-bottom: 0;
  `}
`;

export const PercentFieldWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;

    .PercentField {
      .input-group-addon {
        width: 52px;
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        background: ${theme.gray5};
        border: 1px solid ${theme.gray12};
        border-radius: 6px 0px 0px 6px;
        font-family: 'Roboto';
        color: ${theme.gray2};
      }

      input {
        width: 68px;
        height: 40px;
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        border-radius: 0 6px 6px 0;
      }

      > .FormValidationErrors {
        margin: 0;
      }
    }
  `}
`;
