import styled from 'styled-components';

export const DailySummariesFormContainer = styled.main`
  #DailySummariesForm {
    .PageTitle {
      @media (max-width: 1200px) {
        width: 100%;
      }
    }

    .Tabs .Tab {
      display: none;
    }
  }

  .wizard-content {
    .Separator {
      margin-bottom: 48px;

      @media (max-width: 1200px) {
        margin-bottom: 32px;
      }
    }

    > fieldset {
      display: grid;
      grid-template-columns: 1fr min(160ch, 100%) 1fr;

      @media (max-width: 1200px) {
        padding: 0 24px;
      }

      > * {
        grid-column: 2;
      }
    }
  }

  .wizard-footer .ButtonRow {
    @media (max-width: 1200px) {
      margin-right: 24px;
    }
  }
`;
