import axios from 'axios';
import { extractBody } from 'core/utils/request';

export default class TempPasswordService {
  constructor(dataArea) {
    this.dataArea = dataArea;
  }

  async getUsers(classroomId, userType) {
    try {
      const response = await axios.get(
        `/${this.dataArea}/reset_passwords/${classroomId}/${userType}/users.json`
      );
      return extractBody(response).data;
    } catch (error) {
      throw error;
    }
  }

  async tempPasswordBatch(formState) {
    try {
      const response = await axios.post(
        `/${this.dataArea}/reset_passwords/temp_password_batch/${formState.classroom_from}`,
        formState
      );
      return extractBody(response);
    } catch (error) {
      throw error;
    }
  }

  async tempPasswordReport(users) {
    try {
      const response = await axios.post(
        `/${this.dataArea}/reset_passwords/temp_password_report.pdf`,
        { users },
        { responseType: 'blob' }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}
