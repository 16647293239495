import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autobind from 'autobind-decorator';

import PageTitle from 'components/PageTitle';
import Breadcrumb from 'components/Breadcrumb';
import Button from 'components/Button';
import Alert from 'components/Alert';
import AlertContext from 'core/contexts/Alert';

import TabsBoxTabs from 'components/TabsBoxContainer/TabsBoxTabs';
import * as walletsActions from 'store/edupay/wallets/actions';
import { connect } from 'react-redux';

import './style.scss';
import { Link } from 'react-router-dom';

class TabsBoxContainer extends Component {
  constructor(props) {
    super(props);

    const currentTab = (props.location && props.location.tabs) || 0;

    this.state = {
      activeTab: props.tabs[currentTab],
    };
  }

  @autobind
  selectTab(tab) {
    return (_event) => {
      this.setState({ activeTab: tab });
    };
  }

  componentDidUpdate(prevProps) {
    const { currentWallet, tabs, wallet } = this.props;
    if (
      wallet &&
      prevProps?.currentWallet != null &&
      prevProps?.currentWallet != currentWallet
    ) {
      this.setState({ activeTab: tabs[0] });
    }
  }

  render() {
    const {
      title,
      breadcrumb,
      tabs,
      wallet,
      canCreateWalletButton = false,
    } = this.props;
    const { activeTab } = this.state;

    const activeTabProps = activeTab.props || {};

    return (
      <AlertContext.Consumer>
        {({ alert }) => (
          <div className="TabsContainerBox">
            {breadcrumb && (
              <Breadcrumb
                title={breadcrumb.title}
                path={breadcrumb.path}
                subTitle={breadcrumb.subTitle}
              />
            )}
            {title && (
              <PageTitle>
                {title}
                {canCreateWalletButton && (
                  <Link to="/schools/wallets/new">
                    <Button className="new-wallet">Nova carteira</Button>
                  </Link>
                )}
              </PageTitle>
            )}

            {wallet}

            {alert && <Alert>alert</Alert>}
            <div className="box-wrapper">
              <TabsBoxTabs
                tabs={tabs}
                activeTab={activeTab}
                onTabClick={this.selectTab}
              />
              <div className="body">
                <activeTab.Component {...activeTabProps} {...this.props} />
              </div>
            </div>
          </div>
        )}
      </AlertContext.Consumer>
    );
  }
}

TabsBoxContainer.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      Component: PropTypes.func.isRequired,
    })
  ).isRequired,
  wallet: PropTypes.oneOfType([PropTypes.element]),
  canCreateWalletButton: PropTypes.bool,
  title: PropTypes.string,
  breadcrumb: PropTypes.object,
  currentWallet: PropTypes.object,
};

const mapStateToProps = (state) => ({
  currentWallet: state.wallets.currentWallet,
});

const Connect = connect(mapStateToProps, walletsActions)(TabsBoxContainer);

export default Connect;
