import createModuleReducer from 'store/storeConfig/createModuleReducer';
import {
  ActionsParams,
  ActionsPayload,
  StorageFilter,
  StorageState,
} from './types';
import actions from './actions';
import _ from 'lodash';

export const initialStorageFilters: StorageFilter = {
  startDate: null,
  endDate: null,
  title: '',
  ordenation: 'desc',
  headquarterId: null,
  educationalStageId: null,
  classroomId: null,
  activeSchoolTermId: null,
};

export const INITIAL_STATE: StorageState = {
  common: {
    selectedStorage: null,
    currentStorage: null,
    folderPath: null,
    isLoading: false,
    showDeleteStorageModal: false,
  },
  storageForm: {
    current: null,
    showDiscardModal: false,
    showNotificationModal: false,
    isSubmitting: false,
    form: null,
    recipients: {
      formMeta: {
        canEditClassrooms: true,
        hideRadio: false,
        hideSendTo: false,
        classrooms: [],
        select_options: {
          classroom_id: [],
        },
        student_profiles: null,
      },
    },
  },
  storageList: {
    filters: initialStorageFilters,
    storages: null,
    isLoading: true,
    showDetailsModal: false,
    pagination: {
      page: 1,
      hasMorePage: false,
      isLoading: false,
      totalCount: 0,
    },
  },
};

export const fetchCurrentStorageRequest = (state: StorageState) => {
  const newState = _.cloneDeep(state);

  newState.common.isLoading = true;
  newState.common.currentStorage = null;
  newState.common.folderPath = null;

  return newState;
};

export const fetchCurrentStorageSuccess = (
  state: StorageState,
  action: ActionsPayload['fetchCurrentStorageSuccess']
) => {
  const newState = _.cloneDeep(state);

  const { folderPath, storage } = action;

  newState.common.currentStorage = storage;
  newState.common.folderPath = {
    ...folderPath,
    prevFolder: {
      title: folderPath.titles[folderPath.titles.length - 1],
      id: folderPath.ids[folderPath.ids.length - 1],
    },
  };
  newState.common.isLoading = false;

  return newState;
};

export const fetchCurrentStorageFormRequest = (state: StorageState) => {
  const newState = _.cloneDeep(state);

  newState.storageForm.current = null;
  newState.common.folderPath = null;

  newState.storageForm.recipients = {
    formMeta: {
      canEditClassrooms: true,
      hideRadio: false,
      hideSendTo: false,
      classrooms: [],
      select_options: {
        classroom_id: [],
      },
      student_profiles: null,
    },
  };
  return newState;
};

export const fetchCurrentStorageFormSuccess = (
  state: StorageState,
  action: ActionsPayload['fetchCurrentStorageFormSuccess']
) => {
  const newState = _.cloneDeep(state);

  const { folderPath, storage, kind } = action;

  const canEditForm =
    kind === 'new' ||
    storage.attributes.kind === 'document' ||
    storage.attributes.kind === 'file';

  newState.storageForm.current = storage;
  newState.common.folderPath = {
    ...folderPath,
    prevFolder: {
      title: folderPath.titles[folderPath.titles.length - 1],
      id: folderPath.ids[folderPath.ids.length - 1],
    },
  };

  if (kind === 'edit' && storage.attributes.receiverType === 'student') {
    newState.storageForm.recipients.formMeta.student_profiles =
      storage.attributes.studentProfiles.map((profile) => {
        return {
          id: profile.id,
          type: 'student_profile',
          attributes: {
            name: profile.name,
            name_initials: profile.nameInitials,
            avatar_color: profile.avatarColor,
            avatar_url: profile.avatarUrl,
          },
        };
      });
  }
  newState.storageForm.recipients.formMeta.canEditClassrooms = canEditForm;

  return newState;
};

export const fetchRecipientsClassroomsSuccess = (
  state: StorageState,
  action
) => {
  const { data } = action;
  const classrooms = data.map((classroom) => ({
    ...classroom,
    options: classroom.options.map((option) => ({
      ...option,
      label: option.attributes.name,
      value: option.id,
    })),
  }));

  const newState = _.cloneDeep(state);

  newState.storageForm.recipients.formMeta.classrooms = classrooms;
  newState.storageForm.recipients.formMeta.select_options.classroom_id =
    classrooms;

  return newState;
};

export const fetchStoragesRequest = (
  state: StorageState,
  action: ActionsParams['fetchStoragesRequest']
) => {
  const isPaginationActive = action.page > 1;

  const newState = _.cloneDeep(state);

  if (!isPaginationActive) {
    newState.storageList.isLoading = true;
    newState.storageList.storages = null;
  }
  if (isPaginationActive)
    newState.storageList.pagination = {
      ...newState.storageList.pagination,
      isLoading: true,
      hasMorePage: false,
    };

  return newState;
};

export const fetchStoragesSuccess = (
  state: StorageState,
  action: ActionsPayload['fetchStoragesSuccess']
) => {
  const newState = _.cloneDeep(state);

  const { currentPage, next, storages, totalCount } = action;

  const isPaginationActive = currentPage > 1;

  if (isPaginationActive) {
    newState.storageList = {
      ...newState.storageList,
      storages: [...newState.storageList.storages, ...storages],
      pagination: {
        ...newState.storageList.pagination,
        page: currentPage,
      },
    };
  } else {
    newState.storageList.storages = storages;
  }

  newState.storageList.pagination = {
    ...newState.storageList.pagination,
    hasMorePage: !!next,
    isLoading: false,
    totalCount: totalCount,
  };

  newState.storageList.isLoading = false;

  return newState;
};

export const fetchStoragesError = (state: StorageState) => {
  const newState = _.cloneDeep(state);

  newState.storageList.isLoading = false;

  return newState;
};

export const toggleShowDetailsModal = (state: StorageState) => {
  const newState = _.cloneDeep(state);

  newState.storageList.showDetailsModal = !state.storageList.showDetailsModal;

  return newState;
};

export const toggleShowDeleteStorageModal = (state: StorageState) => {
  const newState = _.cloneDeep(state);

  newState.common.showDeleteStorageModal = !state.common.showDeleteStorageModal;

  return newState;
};

export const toggleShowDiscardModal = (state: StorageState) => {
  const newState = _.cloneDeep(state);

  newState.storageForm.showDiscardModal = !state.storageForm.showDiscardModal;

  return newState;
};

export const toggleShowNotificationModal = (
  state: StorageState,
  action: ActionsPayload['toggleShowNotificationModal']
) => {
  const newState = _.cloneDeep(state);

  newState.storageForm.showNotificationModal =
    !state.storageForm.showNotificationModal;

  newState.storageForm.form = action.form ?? null;

  return newState;
};

export const saveDocumentRequest = (state: StorageState) => {
  const newState = _.cloneDeep(state);

  newState.storageForm.isSubmitting = true;

  return newState;
};

export const saveDocumentSuccess = (state: StorageState) => {
  const newState = _.cloneDeep(state);

  newState.storageForm.isSubmitting = false;
  newState.storageForm.current = null;

  return newState;
};

export const saveEditDocumentRequest = (state: StorageState) => {
  const newState = _.cloneDeep(state);

  newState.storageForm.isSubmitting = true;

  return newState;
};

export const saveEditDocumentSuccess = (state: StorageState) => {
  const newState = _.cloneDeep(state);

  newState.storageForm.isSubmitting = false;
  newState.storageForm.current = null;

  return newState;
};

export const saveEditDocumentError = (state: StorageState) => {
  const newState = _.cloneDeep(state);

  newState.storageForm.isSubmitting = false;

  return newState;
};

export const saveEditFiletRequest = (state: StorageState) => {
  const newState = _.cloneDeep(state);

  newState.storageForm.isSubmitting = true;

  return newState;
};

export const saveEditFileSuccess = (state: StorageState) => {
  const newState = _.cloneDeep(state);

  newState.storageForm.isSubmitting = false;
  newState.storageForm.current = null;

  return newState;
};

export const saveEditFileError = (state: StorageState) => {
  const newState = _.cloneDeep(state);

  newState.storageForm.isSubmitting = false;

  return newState;
};

export const saveEditFolderRequest = (state: StorageState) => {
  const newState = _.cloneDeep(state);

  newState.storageForm.isSubmitting = true;

  return newState;
};

export const saveEditFolderSuccess = (state: StorageState) => {
  const newState = _.cloneDeep(state);

  newState.storageForm.isSubmitting = false;
  newState.storageForm.current = null;

  return newState;
};

export const saveEditFolderError = (state: StorageState) => {
  const newState = _.cloneDeep(state);

  newState.storageForm.isSubmitting = false;

  return newState;
};

export const saveFileRequest = (state: StorageState) => {
  const newState = _.cloneDeep(state);

  newState.storageForm.isSubmitting = true;

  return newState;
};

export const saveFileSuccess = (state: StorageState) => {
  const newState = _.cloneDeep(state);

  newState.storageForm.isSubmitting = false;

  return newState;
};

export const saveFileError = (state: StorageState) => {
  const newState = _.cloneDeep(state);

  newState.storageForm.isSubmitting = false;

  return newState;
};

export const saveFolderRequest = (state: StorageState) => {
  const newState = _.cloneDeep(state);

  newState.storageForm.isSubmitting = true;

  return newState;
};

export const saveFolderSuccess = (state: StorageState) => {
  const newState = _.cloneDeep(state);

  newState.storageForm.isSubmitting = false;

  return newState;
};

export const saveFolderError = (state: StorageState) => {
  const newState = _.cloneDeep(state);

  newState.storageForm.isSubmitting = false;

  return newState;
};

export const saveDocumentError = (state: StorageState) => {
  const newState = _.cloneDeep(state);

  newState.storageForm.isSubmitting = false;

  return newState;
};

export const setSelectedStorage = (
  state: StorageState,
  action: ActionsParams['setSelectedStorage']
) => {
  const newState = _.cloneDeep(state);

  newState.common.selectedStorage = action.storage;
  newState.common.showDeleteStorageModal = false;

  return newState;
};

export const setDeleteStorageSuccess = (state: StorageState) => {
  const newState = _.cloneDeep(state);

  newState.storageList.storages = state.storageList?.storages?.filter(
    (storage) => storage.id !== state.common?.selectedStorage.id
  );
  newState.common.selectedStorage = null;
  newState.storageList.pagination = {
    ...newState.storageList.pagination,
    totalCount: newState.storageList.pagination.totalCount - 1,
  };

  return newState;
};

export const setChangeFilters = (
  state: StorageState,
  action: ActionsParams['setChangeFilters']
) => {
  const newState = _.cloneDeep(state);

  newState.storageList.filters = action.filters;
  newState.storageList.pagination = {
    page: 1,
    hasMorePage: false,
    isLoading: false,
    totalCount: 0,
  };

  return newState;
};

export const setCleanCurrentStorageForm = (state: StorageState) => {
  const newState = _.cloneDeep(state);

  newState.storageForm.current = null;
  newState.storageForm.recipients = {
    ...newState.storageForm.recipients,
    formMeta: {
      ...newState.storageForm.recipients.formMeta,
      canEditClassrooms: true,
      hideRadio: false,
      hideSendTo: false,
      student_profiles: null,
    },
  };

  return newState;
};

const HANDLERS = {
  [actions.FETCH_CURRENT_STORAGE_REQUEST]: fetchCurrentStorageRequest,
  [actions.FETCH_CURRENT_STORAGE_SUCCESS]: fetchCurrentStorageSuccess,
  [actions.FETCH_CURRENT_STORAGE_FORM_REQUEST]: fetchCurrentStorageFormRequest,
  [actions.FETCH_CURRENT_STORAGE_FORM_SUCCESS]: fetchCurrentStorageFormSuccess,
  [actions.FETCH_RECIPIENTS_CLASSROOMS_SUCCESS]:
    fetchRecipientsClassroomsSuccess,
  [actions.FETCH_STORAGES_REQUEST]: fetchStoragesRequest,
  [actions.FETCH_STORAGES_SUCCESS]: fetchStoragesSuccess,
  [actions.FETCH_STORAGES_ERROR]: fetchStoragesError,
  [actions.TOGGLE_SHOW_DETAILS_MODAL]: toggleShowDetailsModal,
  [actions.TOGGLE_SHOW_DELETE_STORAGE_MODAL]: toggleShowDeleteStorageModal,
  [actions.TOGGLE_SHOW_DISCARD_MODAL]: toggleShowDiscardModal,
  [actions.TOGGLE_SHOW_NOTIFICATION_MODAL]: toggleShowNotificationModal,
  [actions.SAVE_DOCUMENT_REQUEST]: saveDocumentRequest,
  [actions.SAVE_DOCUMENT_SUCCESS]: saveDocumentSuccess,
  [actions.SAVE_DOCUMENT_ERROR]: saveDocumentError,
  [actions.SAVE_EDIT_DOCUMENT_REQUEST]: saveEditDocumentRequest,
  [actions.SAVE_EDIT_DOCUMENT_SUCCESS]: saveEditDocumentSuccess,
  [actions.SAVE_EDIT_DOCUMENT_ERROR]: saveEditDocumentError,
  [actions.SAVE_EDIT_FILE_REQUEST]: saveEditFiletRequest,
  [actions.SAVE_EDIT_FILE_SUCCESS]: saveEditFileSuccess,
  [actions.SAVE_EDIT_FILE_ERROR]: saveEditFileError,
  [actions.SAVE_EDIT_FOLDER_REQUEST]: saveEditFolderRequest,
  [actions.SAVE_EDIT_FOLDER_SUCCESS]: saveEditFolderSuccess,
  [actions.SAVE_EDIT_FOLDER_ERROR]: saveEditFolderError,
  [actions.SAVE_FILE_REQUEST]: saveFileRequest,
  [actions.SAVE_FILE_SUCCESS]: saveFileSuccess,
  [actions.SAVE_FILE_ERROR]: saveFileError,
  [actions.SAVE_FOLDER_REQUEST]: saveFolderRequest,
  [actions.SAVE_FOLDER_SUCCESS]: saveFolderSuccess,
  [actions.SAVE_FOLDER_ERROR]: saveFolderError,
  [actions.SET_CHANGE_FILTERS]: setChangeFilters,
  [actions.SET_CLEAN_CURRENT_STORAGE_FORM]: setCleanCurrentStorageForm,
  [actions.SET_DELETE_STORAGE_SUCCESS]: setDeleteStorageSuccess,
  [actions.SET_SELECTED_STORAGE]: setSelectedStorage,
};

const storage = createModuleReducer(INITIAL_STATE, HANDLERS);

export default storage;
