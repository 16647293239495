import React, { Suspense, lazy, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import withAppContext from 'core/hoc/withAppContext';
import omniChannelActions from 'store/messages/omniChannel/actions';
import { OmniChannelState } from 'store/messages/omniChannel/types';

import * as S from './styles';

import ClassroomsStep from 'components/Messages/OmniChannel/Forms/MessageChannel/ClassroomsStep';
import GeneralInformationStep from 'components/Messages/OmniChannel/Forms/MessageChannel/GeneralInformationStep';
import Toast from 'components/Toast';
import SchoolProfileStep from 'components/Messages/OmniChannel/Forms/MessageChannel/SchoolProfileStep';

import { EditTicketChannelProps } from './types';
import { GeneralInformationStepSkeleton } from 'components/Messages/OmniChannel/Forms/MessageChannel/GeneralInformationStep/skeleton';

const WIZARD_STEPS = [
  GeneralInformationStep,
  ClassroomsStep,
  SchoolProfileStep,
];

const EditTicketChannelForm = ({
  match,
  appContext: { dataArea },
}: EditTicketChannelProps): JSX.Element => {
  const { channelId } = match?.params;

  const { t } = useTranslation(['messages']);
  const dispatch = useDispatch();

  const {
    fetchInitialChannelFormRequest,
    setEditChannelRequest,
    fetchChannelRequest,
  } = omniChannelActions;

  const {
    avatarIconsList,
    isLoading,
    isSubmittingChannelForm,
    classrooms,
    channel,
  } = useSelector((state: OmniChannelState) => state.omniChannel);

  const handleEditChannel = useCallback(
    ({ form }) => {
      const params = {
        form,
        channelId,
      };
      dispatch(setEditChannelRequest(params));
    },
    [dispatch, setEditChannelRequest, channelId]
  );

  const fetchEditChannel = useCallback(() => {
    dispatch(fetchChannelRequest(channelId));
  }, [fetchChannelRequest, channelId, dispatch]);

  const fetchInitialChannelForm = useCallback(() => {
    dispatch(fetchInitialChannelFormRequest());
  }, [fetchInitialChannelFormRequest, dispatch]);

  useEffect(() => {
    fetchInitialChannelForm();
    fetchEditChannel();
  }, [fetchInitialChannelForm, fetchEditChannel]);

  const FormFullScreen = useMemo(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    () => lazy(() => import('components/FormFullScreen')),
    [isLoading, channel, classrooms, avatarIconsList]
  );

  const getEditForm = () => {
    return {
      name: channel.attributes.name,
      description: channel.attributes.description,
      kind: channel.attributes.kind,
      icon: channel.attributes.icon,
      classroom_ids: channel.attributes.classroom_ids,
      school_user_ids: channel.attributes.school_user_ids,
      attending_hours: channel.attributes.attendingHours,
    };
  };

  return (
    <S.TicketChannelFormWrapper>
      <Suspense fallback={null}>
        <FormFullScreen
          action="edit"
          id="EditMessageChannel"
          form={channel && getEditForm()}
          initialAttributes={{}}
          formMeta={{
            nextStepDisabled: true,
          }}
          backTo={`/${dataArea}/messages`}
          backToExternal
          isSubmitting={isSubmittingChannelForm}
          steps={
            classrooms && avatarIconsList && !isLoading && channel
              ? WIZARD_STEPS
              : [GeneralInformationStepSkeleton]
          }
          onSubmit={handleEditChannel}
          titlePage={t('omni_channel.forms.message_channel.edit.title')}
          titleModal={t(
            'omni_channel.forms.message_channel.create.cancel_title'
          )}
          contentModal={t(
            'omni_channel.forms.message_channel.create.cancel_description'
          )}
        />
      </Suspense>
      <Toast />
    </S.TicketChannelFormWrapper>
  );
};

export default withAppContext(EditTicketChannelForm);
