export const validateGreaterThanZero = (input, state, attributeName) => {
  let result = true;

  if (!state.toggle_discount) return true;

  const discount = state.edupay_discounts.find(discount => (
    discount.key === attributeName));

  if (discount && discount.discount_value === 0) {
    result = false;
  }

  return result;
};

export const validateSameDay = (input, state, attributeName) => {
  let result = true;
  let sameDay;

  if (!state.toggle_discount) return true;

  const discount = state.edupay_discounts.find(form => (
    form.key === attributeName));

  if (discount) {
    sameDay = state.edupay_discounts.some(form => (
      form.key !== attributeName && 
      form.days_before_expire === discount.days_before_expire));
  }

  if (discount && sameDay) {
    result = false;
  }

  return result;
};

export const validatePresenceDay = (input, state, attributeName) => {
  let result = true;

  if (!state.toggle_discount) return true;

  const discount = state.edupay_discounts.find(form => (
    form.key === attributeName));

  if (discount && discount.days_before_expire === undefined) {
    result = false;
  }

  return result;
};


export const validateMaxDay = (input, state, attributeName) => {
  let result = true;

  if (!state.toggle_discount) return true;

  const discount = state.edupay_discounts.find(form => (
    form.key === attributeName));

  if (discount && discount.days_before_expire > 365) {
    result = false;
  }

  return result;
};