import styled from 'styled-components';

export const ViewContainer = styled.section`
  width: 100%;
  display: grid;
  grid-template-rows: repeat(2, auto);
  grid-template-columns: 1fr 1fr;
  gap: 0 40px;

  @media (max-width: 834px) {
    grid-template-rows: repeat(3, auto);
    grid-template-columns: 100%;
    gap: 30px 0;
  }

  > {
    &:first-child {
      grid-column: 1 / span 2;

      @media (max-width: 834px) {
        grid-column: 1;
      }
    }
  }
`;
