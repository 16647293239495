import React from 'react';
import PropTypes from 'prop-types';
import {
  CheckboxContainer,
  IconCheck,
  StyledImg,
  Text,
  StyledCheckbox,
  StyledFlexContainer,
} from './styles';

import withAppContext from 'core/hoc/withAppContext';

const CheckboxWithIcon = ({
  children,
  icon,
  checked,
  onChange,
  className,
  disabled = false,
}) => {
  const handleOnChange = () => {
    if (disabled) return;
    onChange();
  };

  return (
    <CheckboxContainer
      checked={checked}
      onClick={handleOnChange}
      className={className}
      disabled={disabled}
    >
      <StyledFlexContainer>
        <StyledImg alt="" src={icon} />
        <Text checked={checked}>{children}</Text>
      </StyledFlexContainer>
      <StyledCheckbox
        onChange={handleOnChange}
        checked={checked}
        disabled={disabled}
      >
        <IconCheck viewBox="0 0 24 24">
          <polyline points="20 6 9 17 4 12" />
        </IconCheck>
      </StyledCheckbox>
    </CheckboxContainer>
  );
};

CheckboxWithIcon.propTypes = {
  children: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

export default withAppContext(CheckboxWithIcon);
