import styled, { css } from 'styled-components';
import { ThemeProps } from 'config/themes/types';

import { Grid, Text, Box } from '@agendaedu/ae-web-components';

export const WrapperAccordion = styled.div`
  ${({ theme: { colors, border, space } }: ThemeProps) => css`
    margin-bottom: ${space.sm} !important;

    .Accordion .accordion__button {
      background-color: ${colors.neutral.white};
      padding: ${space.sm} ${space.sm} ${space.sm} ${space.lg};
      border: ${border.width.sm} solid ${colors.neutral.gray3};
      border-radius: ${border.radius.md};
      height: min-content;

      &[aria-expanded='true'] {
        border-bottom: none;
      }

      &::before {
        display: none;
      }
    }

    .Accordion .accordion__panel {
      padding: ${space.xs2} ${space.xs} 0 ${space.xs};
      border: ${border.width.sm} solid ${colors.neutral.gray3};
      border-radius: 0 0 ${border.radius.md} ${border.radius.md};
      border-top: none;
    }
  `}
`;

export const WrapperHeaderAccordion = styled(Grid)`
  width: 100%;
  grid-template-areas: 'date date date date amount amount amount amount entry entry entry icon';
  align-items: center;
  margin-bottom: 0;
`;

export const WrapperIcon = styled(Grid)`
  justify-content: end;
`;

export const WrapperBodyAccordion = styled(Grid)`
  ${({ theme: { colors, border, space } }: ThemeProps) => css`
    width: 100%;
    grid-template-areas: 'student student bill bill method method method method entry entry entry entry';
    align-items: center;
    margin-bottom: ${space.xs};
    border-radius: ${border.radius.md};
    background-color: ${colors.neutral.gray6};
    padding: ${space.xs2};

    &:hover {
      background-color: ${colors.neutral.gray5};
      cursor: pointer;
    }
  `}
`;

export const WrapperStudent = styled(Grid)`
  ${({ theme: { space } }: ThemeProps) => css`
    grid-template-columns: ${space.xl} 1fr;
    grid-gap: ${space.sm} !important;
  `}
`;

export const PaymentMethod = styled(Text)`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    align-items: center;
    gap: ${space.xs2};
  `}
`;

export const BoxWithoutOverflow = styled(Box)`
  ${({ theme: { space } }: ThemeProps) => css`
    min-width: 0;
  `}
`;
