import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import smoothScrollTo from 'core/utils/smoothScrollTo';
import InfiniteScroll from 'react-infinite-scroll-component';

import Loader from 'components/Loader';

import MetricsContainer from 'components/MetricsContainer';

import SurveyActions from 'store/surveys/actions';
import { SURVEY_LOG_STATUS } from 'core/constants/index';

import {
  Avatar,
  Box,
  Button,
  Card,
  RecipientTag,
  Select,
  Tag,
  Text,
} from '@agendaedu/ae-web-components';
import moment from 'moment';

const AnswersAuthorizationTab = ({ surveyId }) => {
  const dispatch = useDispatch();
  const { fetchSurveyAuthorizationQuestions } = SurveyActions;

  const dataArea = useSelector((state) => state.root.dataArea);
  const isLoading = useSelector((state) => state.surveys.isLoading);
  const surveyQuestions = useSelector((state) => state.surveys.surveyQuestions);
  const pagination = useSelector((state) => state.surveys.answersPagination);

  const [isTheFirstLoad, setIsTheFirstLoad] = useState(true);
  const [surveyQuestionsParams, setSurveyQuestionsParams] = useState({});

  const exportSurveyAnswers = () => {
    const url = `/${dataArea}/surveys/${surveyId}/survey_questions/authorization_answers_report.xls`;
    window.open(url, '_blank');
  };

  const metrics = [
    {
      label: 'Envios',
      value: surveyQuestions?.meta?.survey_info_count?.sent,
      color: 'gray2',
    },
    {
      label: 'Visualizações',
      value: surveyQuestions?.meta?.survey_info_count?.seen,
      color: 'info',
    },
    {
      label: 'Confirmações',
      value: surveyQuestions?.meta?.survey_info_count?.answers,
      color: 'success',
    },
    {
      label: 'Pendente',
      value: surveyQuestions?.meta?.survey_info_count?.pending,
      color: 'warning',
    },
  ];

  const questionsOptions = surveyQuestions?.meta?.survey_questions?.map(
    (question) => ({
      value: question.id,
      label: question.question,
    })
  );

  const classroomsOptions = surveyQuestions?.meta?.classrooms?.reduce(
    (prev, classroom) => [
      ...prev,
      {
        value: classroom.id,
        label: classroom.name,
      },
    ],
    [
      {
        value: '',
        label: 'Todas as turmas',
      },
    ]
  );

  const getEmptyState = useMemo(() => {
    if (Object.values(surveyQuestionsParams).length) {
      return (
        <p className="empty-text">
          Não encontramos resultados <br />
          para os filtros aplicados
        </p>
      );
    } else {
      return (
        <p className="empty-text">
          Ainda não há respostas <br />
          para esta autorização
        </p>
      );
    }
  }, [surveyQuestionsParams]);

  const fetchStudents = (nextLink) =>
    dispatch(
      fetchSurveyAuthorizationQuestions(
        surveyId,
        surveyQuestionsParams,
        nextLink
      )
    );

  const renderStudentCard = (student) => {
    const sentAt = moment(student.attributes.sentAt, 'DD/MM/YYYY HH:mm').format(
      'DD/MM/YYYY [às] HH:mm'
    );

    const answeredAt = moment(
      student.attributes.answeredAt,
      'DD/MM/YYYY HH:mm'
    ).format('DD/MM/YYYY [às] HH:mm');

    const STATUS = {
      answered: { name: 'Respondido', variant: 'positive' },
      pending: { name: 'Pendente', variant: 'warning' },
      seen: { name: 'Visualizado', variant: 'neutral' },
    };

    return (
      <Card key={student.id} mt="md">
        <Box
          display="flex"
          width="100%"
          flexWrap="wrap"
          justifyContent="space-between"
        >
          <Box display="flex" mr="lg" mt="xs2" flex={1}>
            <Box>
              <Avatar {...student.attributes.studentProfile.data.attributes} />
            </Box>

            <Box ml="sm" overflow="hidden">
              <Text variant="subtitle-medium-12" color="neutral.gray2">
                Aluno
              </Text>
              <Text
                variant="subtitle-medium-16"
                color="neutral.black"
                textOverflow="ellipsis"
                overflow="hidden"
                whiteSpace="nowrap"
              >
                {student.attributes.studentProfile.data.attributes.name}
              </Text>
            </Box>
          </Box>

          <Box
            display="flex"
            flex={1}
            mr={{ tabletMD: 'xl3' }}
            justifyContent="space-between"
          >
            <Box mr="lg" mt="xs2">
              {student.attributes.status === 'answered' ? (
                <Text variant="subtitle-medium-12" color="neutral.gray2">
                  Respondido em {answeredAt}
                </Text>
              ) : (
                <Text variant="subtitle-medium-12" color="neutral.gray2">
                  Criado em {sentAt}
                </Text>
              )}
              <Text variant="subtitle-medium-16" color="neutral.black">
                {student.attributes.answeredBy || '-'}
              </Text>
            </Box>

            <Box mt="xs2">
              <Text variant="subtitle-medium-12" color="neutral.gray2">
                Resposta
              </Text>
              <Text variant="subtitle-medium-16" color="neutral.black">
                {student.attributes.answer || '-'}
              </Text>
            </Box>
          </Box>

          <Box
            display="flex"
            mt="xs2"
            flex={1}
            mr={{ tabletMD: 'sm' }}
            justifyContent="space-between"
          >
            <Box display="flex" mr="xl" alignItems="center">
              <RecipientTag
                variant="neutral"
                enableEllipsis
                recipientType="Destinatários"
                modalTitle="Destinatários"
                recipients={student.attributes.receivers.data.map(
                  (receiver) => receiver.attributes
                )}
              />
            </Box>

            <Box alignItems="center" display="flex">
              <Tag {...STATUS[student.attributes.status]} />
            </Box>
          </Box>
        </Box>
      </Card>
    );
  };

  const list = useMemo(() => {
    if (!isLoading && !surveyQuestions?.data?.length) return getEmptyState;

    if (isLoading && !surveyQuestions?.data?.length) return <Loader />;

    return (
      <InfiniteScroll
        dataLength={surveyQuestions?.data?.length}
        next={() => fetchStudents(pagination?.next)}
        hasMore={!!pagination?.next}
        loader={<Loader />}
        style={{ overflow: 'visible' }}
      >
        <Box display="flex" flexDirection="column" flexWrap="nowrap">
          {!!surveyQuestions?.meta && (
            <Text variant="subtitle-medium-14" ml="auto">
              {`${surveyQuestions.meta.count} ${
                surveyQuestions.meta.count > 1 ? 'alunos(as)' : 'aluno(a)'
              }`}
            </Text>
          )}
          {surveyQuestions?.data.map(renderStudentCard)}
        </Box>
      </InfiniteScroll>
    );
  }, [
    getEmptyState,
    isLoading,
    pagination?.next,
    surveyQuestions?.data,
    renderStudentCard,
  ]);

  useEffect(() => {
    fetchStudents();
    if (isTheFirstLoad) setIsTheFirstLoad(false);
    smoothScrollTo(0, 0);
  }, [dispatch, fetchSurveyAuthorizationQuestions, surveyQuestionsParams]);

  return (
    <Loader isLoading={isTheFirstLoad}>
      {!!surveyQuestions && (
        <>
          <MetricsContainer metrics={metrics} />

          <Button
            variant="secondary"
            mt="lg"
            icon="file-download"
            onClick={exportSurveyAnswers}
          >
            Exportar
          </Button>

          {!!questionsOptions.length && (
            <Select
              mt="lg"
              options={questionsOptions}
              fullWidth
              value={questionsOptions[0].value}
              onChange={(e) =>
                setSurveyQuestionsParams((prev) => ({
                  ...prev,
                  id: e.value,
                }))
              }
            />
          )}

          <Box display="flex" mt="lg">
            <Select
              mr="lg"
              options={SURVEY_LOG_STATUS}
              value={
                surveyQuestionsParams?.situation || SURVEY_LOG_STATUS[0].value
              }
              onChange={(e) =>
                setSurveyQuestionsParams((prev) => ({
                  ...prev,
                  situation: e.value,
                }))
              }
            />
            <Select
              options={classroomsOptions}
              value={
                surveyQuestionsParams?.classroom_ids?.[0] ||
                classroomsOptions[0].value
              }
              onChange={(e) =>
                setSurveyQuestionsParams((prev) => ({
                  ...prev,
                  classroom_ids: [e.value],
                }))
              }
            />
          </Box>

          <Box mt="lg">{list}</Box>
        </>
      )}
    </Loader>
  );
};

export default AnswersAuthorizationTab;
