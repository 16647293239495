import styled from 'styled-components';

export const TitleSearchWrapper = styled.div`
  width: 100%;
  min-width: 200px;

  svg {
    cursor: pointer;

    &.ae-multiply-icon {
      margin-right: 8px;
    }
  }
`;
