import React, { useEffect, useState } from 'react';
import { EditSchoolProductProps } from './types';
import { SchoolProductsState } from 'store/edupay/schoolProducts/types';
import { useDispatch, useSelector } from 'react-redux';
import actionSchoolProducts from 'store/edupay/schoolProducts/actions';

import EditSchoolProductModal from 'components/Payments/EditSchoolProductModal';
import Loader from 'components/Loader';
import Toast from 'components/Toast';
import SchoolProductForm from '../SchoolProductForm';
import withAppContext from 'core/hoc/withAppContext';
import {
  extractDiscount,
  extractStudents,
  formattedDiscounts,
  makeClassroomIds,
} from 'core/utils/edupay/functions';
import scrollTop from 'core/utils/scrollTop';
import { DISCOUNT_KINDS, INSTALLMENTS_SCHOOLPRODUCT } from 'core/constants';

const EditSchoolProduct = ({ match }: EditSchoolProductProps) => {
  const schoolProductId = match.params.id;
  const dispatch = useDispatch();

  const [notificationModal, setNotificationModal] = useState(false);
  const [onSubmitErrorFunctions, setOnSubmitErrorFunctions] = useState({
    setSubmitting: null,
    updateFormContext: null,
    addErrorsToFormValidationResultFromServer: null,
    goToFirstStep: null,
    formContext: null,
  });

  const { fetchEditSchoolProductRequest, setEditSchoolProduct } =
    actionSchoolProducts;

  const {
    isLoading,
    classroomsStudents,
    classrooms,
    isSubmitting,
    editSchoolProduct,
    error,
  } = useSelector((state: SchoolProductsState) => state.schoolProducts);

  const onSubmit = (notify: boolean, formContext) => {
    let params = formContext.form;

    if (
      params?.toggle_discount &&
      params?.allowed_payment_method !== 'credit_card'
    ) {
      params = {
        ...formContext.form,
        edupay_discounts: formattedDiscounts(params),
      };
    } else {
      delete params.edupay_discounts;
    }

    params = {
      ...params,
      settings: { absorb_tax: formContext?.form?.absorb_tax },
    };

    setNotificationModal(false);
    dispatch(
      setEditSchoolProduct({ form: { ...params, notify }, schoolProductId })
    );
  };

  const handleUpdateModal = (formContext, { goToFirstStep, setSubmitting }) => {
    const { addErrorsToFormValidationResultFromServer, updateFormContext } =
      formContext;

    setOnSubmitErrorFunctions({
      setSubmitting,
      updateFormContext,
      addErrorsToFormValidationResultFromServer,
      goToFirstStep,
      formContext,
    });

    editSchoolProduct && editSchoolProduct?.attributes?.status === 'sent'
      ? setNotificationModal(true)
      : onSubmit(true, formContext);
  };

  const toggleNotificationModal = () =>
    setNotificationModal(!notificationModal);

  const formatAttributes = () => ({
    legacy_id: editSchoolProduct.attributes.legacy_id,
    title: editSchoolProduct.attributes.title,
    allowed_payment_method: editSchoolProduct.attributes.allowed_payment_method,
    price: editSchoolProduct.attributes.price,
    expires_at: editSchoolProduct.attributes.expires_at,
    description: editSchoolProduct.attributes.description,
    sent_kind: editSchoolProduct.attributes.sent_kind,
    classroom_with_student_profile_ids: makeClassroomIds(classroomsStudents),
    classroom_ids: editSchoolProduct?.meta?.classroom_ids,
    absorb_credit_card_tax: editSchoolProduct.attributes.absorb_credit_card_tax,
    absorb_billet_tax: editSchoolProduct.attributes.absorb_billet_tax,
    absorb_pix_tax: editSchoolProduct.attributes.absorb_pix_tax,
    absorb_installment_tax: editSchoolProduct.attributes.absorb_installment_tax,
    max_installments: editSchoolProduct.attributes.max_installments,
    toggle_discount: editSchoolProduct.attributes.toggle_discount,
    recipient_wallet_id: editSchoolProduct.attributes.recipient_wallet_id,
    edupay_discounts: extractDiscount(editSchoolProduct),
    allow_credit_card_discount:
      editSchoolProduct.attributes.edupay_discounts?.data[0]?.attributes.allowed_payment_methods?.includes(
        'credit_card'
      ),
    absorb_tax: editSchoolProduct.attributes.settings?.absorb_tax,
  });

  const formatMetaAttributes = () => ({
    classrooms,
    status: editSchoolProduct.attributes.status,
    student_profiles: extractStudents(classroomsStudents),
    old_billet_expiration: editSchoolProduct.attributes.billet_expires_at,
    before_approved_billet_expires_at:
      editSchoolProduct.attributes.before_approved_billet_expires_at,
    hasCurrentSubscribe: false,
    ...editSchoolProduct.meta,
    select_options: {
      ...editSchoolProduct.meta.select_options,
      max_installments: INSTALLMENTS_SCHOOLPRODUCT,
      kind: DISCOUNT_KINDS,
    },
  });

  useEffect(() => {
    dispatch(fetchEditSchoolProductRequest({ schoolProductId }));
  }, []);

  useEffect(() => {
    if (
      onSubmitErrorFunctions?.setSubmitting &&
      error?.response?.status === 422
    ) {
      onSubmitErrorFunctions?.setSubmitting(false);
      onSubmitErrorFunctions?.updateFormContext(() => ({
        hasBackendValidationError: true,
      }));
      onSubmitErrorFunctions?.addErrorsToFormValidationResultFromServer(
        error.response
      );
      onSubmitErrorFunctions?.goToFirstStep();
      scrollTop();
    }
  }, [error]);

  return (
    <div className="EditSchoolProduct">
      {isLoading || !editSchoolProduct || !classrooms || !classroomsStudents ? (
        <Loader />
      ) : (
        <>
          <SchoolProductForm
            action="edit"
            form={formatAttributes()}
            formMeta={formatMetaAttributes()}
            onSubmit={handleUpdateModal}
            isSubmitting={isSubmitting}
            backTo={`/schools/school_products/${schoolProductId}`}
            titlePage="Editar cobrança"
            titleModal="Descartar preenchimento"
            contentModal="A edição da cobrança será cancelada e as informações preenchidas serão descartadas. Tem certeza que deseja descartar?"
          />
          <Toast />
        </>
      )}
      <EditSchoolProductModal
        isOpen={notificationModal}
        toggleModal={toggleNotificationModal}
        title="Deseja notificar a atualização?"
        description="Os responsáveis receberão uma notificação sobre a atualização das
          informações do pagamento."
        handleCancel={() => setNotificationModal(false)}
        handleNotNotify={() =>
          onSubmit(false, onSubmitErrorFunctions.formContext)
        }
        handleNotify={() => onSubmit(true, onSubmitErrorFunctions.formContext)}
      />
    </div>
  );
};

export default withAppContext(EditSchoolProduct);
