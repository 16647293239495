import React from 'react';
import PropTypes from 'prop-types';

import PageContainer from 'components/PageContainer';
import PageSubTitle from 'components/PageSubTitle';
import Breadcrumb from 'components/Breadcrumb';
import Button from 'components/Button';
import StudentsTransferForm from 'components/Students/TransferForm';
import Loader from 'components/Loader';

import FormContainer from 'components/Form/FormContainer';
import FormContext from 'core/contexts/Form';

import SchoolTermService from 'core/services/SchoolTerm';
import ClassroomsService from 'core/services/Classrooms';
import StudentsService from 'core/services/Students';

import withAppContext from 'core/hoc/withAppContext';
import withToastMessage from 'core/hoc/withToastMessage';

import './style.scss';

const StudentsTransfer = ({
  appContext: { dataArea },
  toastActions: { showToast },
}) => {
  const [state, setState] = React.useState({
    students: [],
    isLoadingSchoolTerm: true,
    isLoadingStudents: true,
    activeSchoolTerm: {},
    isSubmitting: false,
    selectClassroomFrom: null,
    selectClassroomTo: null,
    error: null,
  });

  const {
    students,
    isLoadingStudents,
    isLoadingSchoolTerm,
    isSubmitting,
    activeSchoolTerm,
    selectClassroomFrom,
    selectClassroomTo,
  } = state;

  React.useEffect(() => {
    if (!isLoadingStudents) {
      return;
    }

    loadActiveSchoolTerm();
  }, [isLoadingStudents]);

  const loadActiveSchoolTerm = async () => {
    try {
      const schoolTermService = new SchoolTermService(dataArea);
      const { activeSchoolTerm } =
        await schoolTermService.fetchActiveSchoolTerm();

      setState((prevState) => ({ ...prevState, activeSchoolTerm }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, error }));
    } finally {
      setState((prevState) => ({ ...prevState, isLoadingSchoolTerm: false }));
    }
  };

  const loadStudents = async (classroomFrom) => {
    const classroomsService = new ClassroomsService(dataArea);

    try {
      if (classroomFrom) {
        const data = await classroomsService.getStudents(classroomFrom);
        setState((prevState) => ({ ...prevState, students: data }));
      } else {
        setState((prevState) => ({ ...prevState, students: [] }));
      }
    } catch (error) {
      setState((prevState) => ({ ...prevState, error }));
    } finally {
      setState((prevState) => ({ ...prevState, isLoadingStudents: false }));
    }
  };

  const onSubmit = async (formState, updateAttribute) => {
    setState((prevState) => ({ ...prevState, isSubmitting: true }));

    try {
      const studentsService = new StudentsService(dataArea);
      await studentsService.transferStudents(formState);

      updateAttribute('classroom_from', '');
      updateAttribute('classroom_to', '');
      updateAttribute('student_profile_ids', []);

      setState((prevState) => ({
        ...prevState,
        students: [],
        selectClassroomFrom: null,
        selectClassroomTo: null,
      }));

      showToast(
        'Os alunos selecionados foram transferidos com sucesso.',
        'success'
      );
    } catch (error) {
      setState((prevState) => ({ ...prevState, isSubmitting: false, error }));

      showToast(
        'Ocorreu um erro ao tentar ao tentar transferir. Se o erro persistir, aguarde um instante e tente novamente.',
        'error'
      );
    } finally {
      setState((prevState) => ({ ...prevState, isSubmitting: false }));
    }
  };

  const onClassroomSelectFrom = React.useCallback(
    (classroom) => {
      setState((prevState) => ({
        ...prevState,
        selectClassroomFrom: classroom,
      }));
    },
    [setState]
  );

  const onClassroomSelectTo = React.useCallback(
    (classroom) => {
      setState((prevState) => ({ ...prevState, selectClassroomTo: classroom }));
    },
    [setState]
  );

  const footer = (formState, updateAttribute) => {
    return (
      <React.Fragment>
        <a href="/schools/student_profiles">
          <Button variation="secondary">Voltar</Button>
        </a>
        <Button
          onClick={() => onSubmit(formState, updateAttribute)}
          disabled={
            !(
              formState.classroom_to &&
              formState.student_profile_ids.length &&
              !isSubmitting
            )
          }
        >
          Salvar
        </Button>
      </React.Fragment>
    );
  };

  return (
    <div className="StudentsTransfer">
      <FormContainer action="new" form={{ student_profile_ids: [] }}>
        <FormContext.Consumer>
          {({ form, updateAttribute }) => (
            <Loader isLoading={isLoadingSchoolTerm}>
              <PageContainer
                title="Transferir alunos"
                breadcrumb={
                  <Breadcrumb
                    title="Alunos"
                    path="/schools/student_profiles"
                    external
                  />
                }
                footer={footer(form, updateAttribute)}
                variation="centered"
              >
                <PageSubTitle>Transferir alunos entre turmas</PageSubTitle>
                <span className="info">
                  Escolha a turma atual e a turma de destino dos alunos que
                  deseja transferir.
                </span>
                <StudentsTransferForm
                  activeSchoolTerm={activeSchoolTerm}
                  students={students}
                  selectClassroomFrom={selectClassroomFrom}
                  selectClassroomTo={selectClassroomTo}
                  loadStudents={loadStudents}
                  onClassroomSelectFrom={onClassroomSelectFrom}
                  onClassroomSelectTo={onClassroomSelectTo}
                />
              </PageContainer>
            </Loader>
          )}
        </FormContext.Consumer>
      </FormContainer>
    </div>
  );
};

StudentsTransfer.propTypes = {
  appContext: PropTypes.shape({
    dataArea: PropTypes.string.isRequired,
  }).isRequired,
  toastActions: PropTypes.shape({
    showToast: PropTypes.func.isRequired,
  }).isRequired,
  formContext: PropTypes.shape({
    formMeta: PropTypes.object,
    updateAttribute: PropTypes.func.isRequired,
  }),
};

export default withAppContext(withToastMessage(StudentsTransfer));
