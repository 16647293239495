import createModuleReducer from 'store/storeConfig/createModuleReducer';
import actions from './actions';

export const INITIAL_STATE = {
  currentWallet: null,
  wallets: [],
  walletsOptions: [],
  balance: null,
  bankAccount: null,
  showChangeWalletModal: false,
  walletHistory: null,
  isLoadingHistory: false,
  isLoadingWallet: false,
  isLoadingBalance: false,
};

const fetchRecipientsWalletsSuccess = (state, action) => {
  return {
    ...state,
    currentWallet:
      action.data.find(
        (wallet) =>
          wallet.attributes.recipient_wallet_id === action.currentWallet.id
      ) || action.data[0],
    wallets: action.data,
  };
};

const fetchSelectRecipientWalletRequest = (state) => ({
  ...state,
  currentWallet: null,
});

const fetchSelectRecipientWalletSuccess = (state, action) => {
  const currentWallet = state.wallets.find(
    (wallet) =>
      wallet.attributes.recipient_wallet_id ===
      parseInt(action.walletSeleted.id)
  );

  return {
    ...state,
    balance: null,
    bankAccount: null,
    currentWallet,
    showChangeWalletModal: false,
  };
};

const fetchAccountBalanceRequest = (state, action) => ({
  ...state,
  isLoadingBalance: true,
});

const fetchAccountBalanceSuccess = (state, action) => ({
  ...state,
  balance: action.data.attributes,
  isLoadingBalance: false,
});

const fetchBankAccountSuccess = (state, action) => ({
  ...state,
  bankAccount: action.data,
});

const fetchHistoryRequest = (state) => ({
  ...state,
  isLoadingHistory: true,
});

const fetchHistorySuccess = (state, action) => ({
  ...state,
  walletHistory: action.response,
  isLoadingHistory: false,
});

const handleShowChangeWalletModal = (state, action) => ({
  ...state,
  showChangeWalletModal: action.isOpen,
});

export const fetchRecipientsWalletsOptionsRequest = (state) => ({
  ...state,
  isLoadingWallet: true,
});

export const fetchRecipientsWalletsOptionsSuccess = (state, action) => {
  const currentRecipientWallets = [...action.recipientsWallet];

  currentRecipientWallets.unshift({
    value: null,
    label: 'Selecione uma carteira',
  });

  return {
    ...state,
    walletsOptions: currentRecipientWallets,
    isLoadingWallet: false,
  };
};

const HANDLERS = {
  [actions.FETCH_RECIPIENTS_WALLETS_SUCCESS]: fetchRecipientsWalletsSuccess,
  [actions.FETCH_SELECT_RECIPIENT_WALLET_REQUEST]:
    fetchSelectRecipientWalletRequest,
  [actions.FETCH_SELECT_RECIPIENT_WALLET_SUCCESS]:
    fetchSelectRecipientWalletSuccess,
  [actions.FETCH_RECIPIENTS_WALLETS_OPTIONS_REQUEST]:
    fetchRecipientsWalletsOptionsRequest,
  [actions.FETCH_RECIPIENTS_WALLETS_OPTIONS_SUCCESS]:
    fetchRecipientsWalletsOptionsSuccess,
  [actions.FETCH_ACCOUNT_BALANCE_REQUEST]: fetchAccountBalanceRequest,
  [actions.FETCH_ACCOUNT_BALANCE_SUCCESS]: fetchAccountBalanceSuccess,
  [actions.FETCH_BANK_ACCOUNT_SUCCESS]: fetchBankAccountSuccess,
  [actions.HANDLE_SHOW_CHANGE_WALLET_MODAL]: handleShowChangeWalletModal,
  [actions.FETCH_HISTORY_REQUEST]: fetchHistoryRequest,
  [actions.FETCH_HISTORY_SUCCESS]: fetchHistorySuccess,
};

const wallets = createModuleReducer(INITIAL_STATE, HANDLERS);

export default wallets;
