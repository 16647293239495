/* eslint-disable no-unused-vars */

import React, { useState } from 'react';

import AgendaIcon from 'components/AgendaIcon';
import Button from 'components/Button';
import SideSheetFilters from './SideSheetFilters';

import { MoodTrackerFiltersProps } from './types';

import * as S from './styles';

const MoodTrackerFilters = ({
  handleFilter,
  amount,
}: MoodTrackerFiltersProps) => {
  const [openSideSheet, setOpenSideSheet] = useState(false);

  const hasActiveFilters = amount > 0;

  const handleSideSheetToggle = () => setOpenSideSheet(!openSideSheet);

  return (
    <S.Wrapper isActive={hasActiveFilters}>
      <Button variation="secondary" onClick={handleSideSheetToggle}>
        <AgendaIcon name="filter" title="Filtros" />
        {hasActiveFilters && <S.BadgeFilter>{amount}</S.BadgeFilter>}
      </Button>
      <SideSheetFilters
        isOpen={openSideSheet}
        onDismiss={handleSideSheetToggle}
        handleFilter={handleFilter}
      />
    </S.Wrapper>
  );
};

export default MoodTrackerFilters;
