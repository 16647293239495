const pluck = (objs, name, path) => {
  const array = [];

  objs.forEach((obj) => {
    if (path) {
      const properties = path.split('.');

      for (let i = 0; i < properties.length; i++) {
        const prop = properties[i];

        if (obj[prop].hasOwnProperty(name)) {
          array.push(obj[prop][name]);
        }
      }
    }

    if (obj.hasOwnProperty(name)) {
      array.push(obj[name]);
    }
  });

  return array;
};

export default pluck;
