import axios from 'axios';
import qs from 'qs';
import { extractBody, extractHeaders } from 'core/utils/request';

export default class WalletsService {
  constructor(dataArea) {
    this.dataArea = dataArea;
  }

  async getEmptyFormMeta() {
    try {
      const response = await axios.get(`/${this.dataArea}/wallets/new.json`);
      const { attributes, meta } = response.data;
      return { attributes, meta };
    } catch (error) {
      throw error;
    }
  }

  async getEditData(schoolBillingInformationId) {
    try {
      const response = await axios.get(`/${this.dataArea}/wallets/edit.json`, {
        params: {
          id: schoolBillingInformationId,
        },
      });
      const { data, meta } = response.data;
      const { attributes } = data;
      return { attributes, meta };
    } catch (error) {
      throw error;
    }
  }

  async createWallet(form) {
    try {
      const response = await axios.post(`/${this.dataArea}/wallets`, {
        ...form,
      });
      return response;
    } catch (error) {
      throw error;
    }
  }

  async updateWallet(form, schoolBillingInformationId) {
    try {
      const response = await axios.put(
        `/${this.dataArea}/wallets.json?id=${schoolBillingInformationId}`,
        {
          ...form,
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  async transfers({ formState, page }) {
    const endpoint = `/${this.dataArea}/wallets/transfers.json`;
    const params = {
      ...formState,
      page,
    };

    try {
      const response = await axios.get(endpoint, {
        params,
        paramsSerializer: qs.stringify,
      });
      const body = extractBody(response);
      const { totalItemsCount, itemsCountPerPage } = extractHeaders(response);
      const transfers = body.data;
      return { transfers, itemsCountPerPage, totalItemsCount };
    } catch (error) {
      throw error;
    }
  }

  schoolProducts({ formState, page }) {
    return new Promise((resolve, reject) => {
      axios
        .get(this.getEndpointWithDataArea('/wallets/school_products.json'), {
          params: {
            ...formState,
            page,
          },
          paramsSerializer: qs.stringify,
        })
        .then((response) => {
          const schoolProducts = response.data.data;
          const { totalItemsCount, itemsCountPerPage } =
            extractHeaders(response);
          resolve({ totalItemsCount, itemsCountPerPage, schoolProducts });
        })
        .catch((error) => reject(error));
    });
  }

  /**
   * @param {String} endpoint
   * @returns {String}
   * @example
   * new PaymentService('schools').getEndpoint('/payments.json')
   * '/schools/payments.json'
   */
  getEndpointWithDataArea(endpoint) {
    return `/${this.dataArea}${endpoint}`;
  }
}
