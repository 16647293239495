import React from 'react';

import Panel from 'pages/panel';
import reduxProvider from 'core/hoc/reduxProvider';

const PanelScreen = () => (
  <div className="PanelScreen">
    <Panel />
  </div>
);

export default reduxProvider(PanelScreen);
