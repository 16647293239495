import React from 'react';
import { Box, Text } from '@agendaedu/ae-web-components';
import { useTranslation } from 'react-i18next';

import { IMAGES_SURVERY_URL } from 'core/constants';

import * as S from './styles';

import { SkeletonProps } from './types';

const StorageEmptyState: React.FC<SkeletonProps> = ({
  isRootFolder,
}): React.ReactElement => {
  const { t } = useTranslation(['storage']);

  return (
    <Box
      data-testid="empty-state"
      mt="lg"
      display="flex"
      flexDirection="column"
      alignItems="center"
      width="100%"
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        gap="lg"
        maxWidth="360px"
      >
        <S.EmptyStateImage src={IMAGES_SURVERY_URL.emptyStateUrl} />
        <Text
          textAlign="center"
          lineHeight="lg"
          variant="subtitle-medium-16"
          color={'neutral.black'}
        >
          {isRootFolder
            ? t('list.empty_state')
            : t('list.empty_state_root_folder')}
        </Text>
      </Box>
    </Box>
  );
};

export default StorageEmptyState;
