import React from 'react';
import parse from 'html-react-parser';
import ModalContainer from 'components/Modal/ModalContainer';
import { Button } from '@agendaedu/ae-web-components';
import { useTranslation } from 'react-i18next';
import * as S from './styles';
import { SaveConfirmationModal } from './types';
import Loader from 'components/Loader';

export const SaveConfirmatioModal = ({
  isOpen,
  handleClose,
  handleSave,
  sending,
}: SaveConfirmationModal) => {
  const { t } = useTranslation(['enrollments']);
  return (
    <ModalContainer
      isOpen={isOpen}
      toggleModal={() => handleClose()}
      maxWidth="396px"
      className=""
      title={t('form.enrollment.save_modal.title')}
    >
      <S.BodyRegular16>
        {parse(t('form.enrollment.save_modal.info'))}
      </S.BodyRegular16>
      <S.ButtonContainer>
        <Button variant="secondary" onClick={() => handleClose()}>
          {t('form.enrollment.button.back')}
        </Button>
        <Button disabled={sending} onClick={handleSave}>
          {sending ? <Loader size={16} /> : t('form.enrollment.button.confirm')}
        </Button>
      </S.ButtonContainer>
    </ModalContainer>
  );
};
