/* eslint-disable prettier/prettier */
import styled, { css } from 'styled-components';
import colorWithAlpha from 'core/utils/colorWithAlpha';
import Button from 'components/Button';
import AgendaIcon from 'components/AgendaIcon';
import { ThemeProps } from 'config/themes/types';

export const WrapperCard = styled.div`
  ${({ theme: { colors, border, shadow, space } }: ThemeProps) => css`
    display: flex;
    flex-direction: column;
    background-color: ${colors.neutral.white};
    box-shadow: ${shadow.card};
    border-radius: ${border.radius.md};
    padding: ${space.lg};
    cursor: pointer;
    height: 283px;
    word-wrap: break-word;
    overflow: hidden;
    padding: 24px;
  `}
`;

export const WrapperContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
  background-color: ${({ theme: { colors } }: ThemeProps) =>
    colors.neutral.white};
`;

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${({ theme: { colors } }: ThemeProps) =>
    colors.neutral.white};
  margin-bottom: 16px;
`;

export const BottomContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.white};
`;

export const WrapperColumn = styled.header`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.white};
  max-width: 90%;
`;

export const WrapperContent = styled.header`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.white};
  max-width: 99%;
  margin-bottom: 16px;
`;

export const BottomRightColumn = styled.header`
  display: flex;
  flex-direction: row;
  background-color: ${({ theme }) => theme.white};
  max-width: 50%;
  gap: 10px;
`;

export const BottomLeftColumn = styled.header`
  display: flex;
  flex-direction: row;
  background-color: ${({ theme }) => theme.white};
  max-width: 50%;
  gap: 16px;
`;

export const IconTag = styled(AgendaIcon)<{ partnerName: string }>`
  color: ${(props) =>
    props.partnerName === 'Agenda Edu'
      ? props.theme.primaryColor
      : props.theme.gray2};
  ${({ theme: { space } }: ThemeProps) => css`
    margin-right: ${space.xs2};
  `}
`;

export const TextTag = styled.span<{ partnerName: string }>`
color: ${(props) =>
  props.partnerName === 'Agenda Edu'
    ? props.theme.primaryColor
    : props.theme.gray2};
  ${({ theme: { font } }: ThemeProps) => css`
    font-family: ${font.family.brand};
    font-size: ${font.size.xs};
    font-weight: ${font.weight.bold};
  `}
`;


export const ByTag = styled.p<{ partnerName: string }>`
  color: ${(props) =>
    props.partnerName === 'Agenda Edu'
      ? props.theme.primaryColor
      : props.theme.gray2};

  ${({ theme: { font, space } }: ThemeProps) => css`
    font-family: ${font.family.brand};
    font-size: ${font.size.xs};
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 2px 0px 0px !important;
  `}
`;

export const Logo = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 8px;
`;

export const Title = styled.h3`
  font-family: ${({ theme }) => theme.font.family.brand};
  font-size: ${({ theme }) => theme.font.size.md};
  font-weight: 700;
  color: ${({ theme }) => theme.colors.neutral.black};
  margin-top: 0px;
  text-overflow: ellipsis;
  margin-bottom: 16px;
`;

export const Text = styled.p`
  font-family: ${({ theme }) => theme.font.family.brand};
  font-size: ${({ theme }) => theme.font.size.xs};
  font-weight: ${({ theme }) => theme.font.weight.medium};
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0px 0px 0px;
  min-height: 34px;
`;

export const ButtonSide = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 11px 12px;
  width: 86px;
  height: 32px;
  font-family: ${({ theme }) => theme.font.family.brand};
  font-size: ${({ theme }) => theme.font.size.sm};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  border-radius: 4px;
  .AgendaIcon {
    color: ${({ theme }) => theme.primaryColor};
  }
  &.active {
    border-color: ${({ theme }) =>
      colorWithAlpha(`${theme.secondaryColor}`, 0.1)};
  }
`;

export const SquareIcon = styled.div<{ bgcolor: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 65px;
  height: 65px;
  background-color: ${(props) => props.bgcolor};
  border-radius: 8px;
`;
