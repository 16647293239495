import styled, { css } from 'styled-components';
import AgendaIcon from 'components/AgendaIcon';

import { ThemeProps } from 'config/themes/types';

export const ActionsButtonWrapper = styled.div`
  ${({ theme: { colors } }: ThemeProps) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 8px;
    color: ${colors.neutral.black};
    background: ${colors.neutral.gray5};
    transition: 180ms;
    cursor: pointer;

    &:hover {
      background: ${colors.neutral.gray4};
    }
  `}
`;

export const IconButton = styled(AgendaIcon)`
  ${({ theme: { colors, font } }: ThemeProps) => css`
    color: ${colors.neutral.black};

    &.icon-ae-reply {
      &::before {
        color: ${colors.neutral.black};
      }
    }

    &.icon-ae-forward,
    &.icon-ae-reply {
      font-size: ${font.size.lg};
    }
  `}
`;
