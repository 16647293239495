import React from 'react';

import Label from 'components/Form/Label';

import withFormContext from 'core/hoc/withFormContext';

import { RadioButtonWithIconProps } from './types';

import * as S from './styles';

const RadioButtonWithIcon = ({
  options,
  attributeName,
  disabled,
  isRecurrentBillInBatches,
  formContext: { updateAttribute, form },
}: RadioButtonWithIconProps) => {
  const defaultOptionValue =
    isRecurrentBillInBatches && disabled ? null : form[attributeName];

  const handleOption = (option: boolean) => {
    updateAttribute(attributeName, option);
  };

  return (
    <S.RadioButtonWithIconContainer data-testid="radio-button-with-icon">
      {options.map((option) => {
        return (
          <S.OptionCard
            key={option.label}
            variation="white"
            isActive={defaultOptionValue === option.value}
            onClick={() => handleOption(option.value)}
            disabled={disabled}
          >
            <S.Icon alt="" src={option.icon} />

            <S.OptionCardDetails isActive={defaultOptionValue === option.value}>
              <Label>{option.label}</Label>
            </S.OptionCardDetails>

            <S.SelectButton>
              {defaultOptionValue === option.value && <S.IconButton />}
            </S.SelectButton>
          </S.OptionCard>
        );
      })}
    </S.RadioButtonWithIconContainer>
  );
};

RadioButtonWithIcon.defaultProps = {
  disabled: false,
  isRecurrentBillInBatches: false,
};

export default withFormContext(RadioButtonWithIcon);
