import { Button, Option, SubtleIconButton } from '@agendaedu/ae-web-components';
import { ThemeProps } from 'config/themes/types';
import styled, { css } from 'styled-components';

export const OptionList = styled.div`
  ${({ theme }: ThemeProps) => css`
    display: flex;
    position: absolute;
    flex-direction: column;
    margin-top: ${theme.space.xs2};
    padding: ${theme.space.xs2};
    z-index: 1;
    list-style-type: none;
    background-color: ${theme.colors.neutral.white};
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border-radius: ${theme.border.radius.md};
    width: max-content;
    right: 120px;

    @media (max-width: ${theme.breakpoints.tabletMD}) {
      right: 89px;
    }
  `}
`;

export const NewButton = styled(Button)`
  ${({ theme: { breakpoints, font } }: ThemeProps) => css`
    @media (max-width: ${breakpoints.mobileSM}) {
      font-size: ${font.size.sm} !important;
    }
  `}
`;

export const FolderActionWrapper = styled(SubtleIconButton)`
  ${({ theme: { border, colors } }: ThemeProps) => css`
    > button {
      border: ${border.width.sm} ${border.style.solid}
        ${colors.brand.primary.default};
      background-color: ${colors.neutral.white};
      color: ${colors.brand.primary.default};

      > svg {
        path {
          fill: ${colors.brand.primary.default};
        }
      }
    }
  `}
`;

export const OptionsItem = styled(Option)`
  width: 100%;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  ul {
    max-height: 32px;
  }
`;
