import React, { useState } from 'react';

import AgendaIcon from 'components/AgendaIcon';

import './style.scss';

export type AlertProps = {
  variation?: string;
  dismissable?: boolean;
  children: React.ReactNode;
};

const Alert = ({
  children,
  variation = 'success',
  dismissable = false,
}: AlertProps): React.ReactElement | null => {
  const [dismissed, setDismissed] = useState(false);

  const closeAlert = (): void => setDismissed(true);

  if (dismissed) return null;

  return (
    <div className={`Alert ${variation}`}>
      <div className="alert-message">{children}</div>
      {dismissable && (
        <div className="dismiss-alert" onClick={closeAlert}>
          <AgendaIcon name="close" />
        </div>
      )}
    </div>
  );
};

export default Alert;
