import { ThemeProps } from 'config/themes/types';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    flex-direction: column;
    gap: ${space.sm};
    overflow: auto;
  `}
`;

export const TitleModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CoverImage = styled.img`
  ${({ theme: { border } }: ThemeProps) => css`
    width: 100%;
    border-radius: ${border.radius.md};
  `}
`;

export const Label = styled.label`
  ${({ theme: { colors, typography } }: ThemeProps) => css`
    ${typography.subtitle.Medium14};
    color: ${colors.neutral.gray2};
    margin-bottom: 0px;
  `}
`;

export const LabelValue = styled.p`
  ${({ theme: { colors, typography } }: ThemeProps) => css`
    ${typography.body.Regular16}
    color: ${colors.neutral.gray1};
    margin-bottom: 0px;
  `}
`;

export const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ActionsWrapper = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    flex-direction: row;
    margin-top: ${space.xs3};
    justify-content: space-between;
    align-items: center;
  `}
`;

export const ButtonsWrapper = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    flex-direction: row;
    gap: ${space.sm};
  `}
`;

export const EmptyCoverImage = styled.div`
  ${({ theme: { border, colors } }: ThemeProps) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50vw;
    max-height: 306px;

    border: ${border.radius.md};
    background-color: ${colors.neutral.gray5};
    border-radius: ${border.radius.md};

    .ae-image-icon {
      width: 120px !important;
      height: 120px !important;
      path {
        fill: ${colors.neutral.gray2};
      }
    }
  `}
`;
