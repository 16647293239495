import styled, { css } from 'styled-components';

import { ThemeProps } from 'config/themes/types';

export const ModalContainer = styled.div`
  ${({ theme }: ThemeProps) => css`
    width: 100%;
    max-width: 800px;
    position: relative;
    border-radius: ${theme.border.radius.lg};
    overflow: hidden;
  `}
`;

export const ModalHeader = styled.div<{ thumbUrl: string }>`
  ${({ thumbUrl, theme }) => css`
    padding: ${theme.space.xl};
    width: 100%;
    height: 390px;
    background-image: url(${thumbUrl});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    svg {
      cursor: pointer;
    }
  `}
`;

export const ModalInfo = styled.div`
  padding: ${({ theme }) => theme.space.xl};

  .Form {
    .Field {
      padding: 0;
      margin: 0;
    }
  }
`;

export const ModalTitle = styled.h2`
  ${({ theme: { colors, typography } }: ThemeProps) => css`
    margin: 0;
    ${typography.headline.H2Bold24};
    color: ${colors.neutral.white};
  `}
`;

export const ModalText = styled.p`
  ${({ theme: { colors, typography } }: ThemeProps) => css`
    margin: 0 0 32px 0;
    ${typography.body.Regular16};
    color: ${colors.neutral.gray1};
  `}
`;
