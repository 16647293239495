import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import HubstoreActions from 'store/hubstore/actions';
import { trackEvent } from 'config/amplitude';
import FormFullScreen from 'components/FormFullScreen';
import BuyFormStepsContext from './FormSteps/BuyFormStepsContext';
import RecipientsTab from './FormSteps/RecipientsTab';
import InformationsTab from './FormSteps/InformationsTab';
import Loader from 'components/Loader';
import { FormSubscriptionProps } from './types';
import { RootState } from '../types';
import Toast from 'components/Toast';

const SubscriptionForm: React.FC<FormSubscriptionProps> = ({
  match,
  history,
}) => {
  const dispatch = useDispatch();
  const productId = match.params.id;
  const contactInfo = history.location.state.contact_info;

  const { t } = useTranslation(['hub_store']);

  const { getSubscriptionsInfo, submitSubscriptions } = HubstoreActions;

  const { isLoading, isSaved, isSending, formMeta } = useSelector(
    (state: RootState) => state.hubstore
  );

  const onSubmit = useCallback(
    (formContext) => {
      dispatch(submitSubscriptions(formContext.form));
    },
    [dispatch]
  );

  const fetchProcutInfo = useCallback(() => {
    dispatch(getSubscriptionsInfo(productId));
  }, [dispatch, getSubscriptionsInfo]);

  useEffect(() => {
    fetchProcutInfo();
  }, []);

  return (
    <Loader isLoading={isLoading}>
      <BuyFormStepsContext.Provider value={{}}>
        <FormFullScreen
          id="buy-form-steps"
          action="new"
          onSubmit={onSubmit}
          form={{
            classroom_ids: [],
            student_total: 0,
            total_currency: 0,
            contactInfo: contactInfo,
            productId: productId,
            productName: '',
          }}
          formMeta={formMeta}
          isSubmitting={isSending}
          steps={[RecipientsTab, InformationsTab]}
          backTo={`/schools/hub_store/products/${productId}`}
          titlePage={t('subscription.title_page')}
          titleModal={t('subscription.title_modal_close')}
          contentModal={t('subscription.content_modal_close')}
        />
      </BuyFormStepsContext.Provider>
      <Toast />
    </Loader>
  );
};

export default SubscriptionForm;
