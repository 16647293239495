import React, { useEffect } from 'react';

import Title from 'components/WizardForm/Title';
import PageSubTitle from 'components/PageSubTitle';
import AgendaIcon from 'components/AgendaIcon';
import ColoredText from 'components/ColoredText';
import Button from 'components/Button';
import Currency from 'components/Currency';
import FormValidationErrors from 'components/Form/ValidationErrors';
import Toast from 'components/Toast';
import Alert from 'components/Alert';

import withWindowSize from 'core/hoc/withWindowSize';
import withFormContext from 'core/hoc/withFormContext';
import NegotiationItem from './NegotiationItem';

import { ItemValues, NegotiationBillsProps } from './types';
import * as S from './styles';
import { centsToBRL } from 'core/utils/centsToBrl';
import Tooltip from 'components/Tooltip';

const newItem = (): ItemValues => ({
  price_value: 0,
  price_kind: 'money',
  discount_kind: 'percent',
  discount_value: 0,
  key: Math.random().toString(36).substring(7), // Had to add this unique key to prevent issues, we can think how to generate a random key based on the properties
});

const TooltipSubtotal =
  'Subtotal é o valor parcial sem aplicação de multas e juros';

const NegotiationBills = ({
  windowSize,
  title = 'Negociação da cobrança',
  attributeName = 'negotiationBills',
  formContext: {
    form,
    updateAttribute,
    hasErrorOnAttribute,
    formMeta: { originalRecurrentBills },
  },
}: NegotiationBillsProps) => {
  const negotiationBills = form[attributeName];
  const hasError = hasErrorOnAttribute(attributeName);

  const minValueSelected = form.selected_value_cents;

  const recurrentItemValue = ({
    discount_kind,
    discount_value,
    price_value,
    price_kind,
  }) => {
    if (price_kind === 'percent') {
      return ((minValueSelected / 100) * price_value) / 100;
    }

    if (discount_kind === 'percent') {
      return price_value - price_value * (discount_value / 100);
    }

    return price_value - discount_value;
  };

  const reducer = (accumulator, currentValue) =>
    accumulator + recurrentItemValue(currentValue);

  const subTotal = negotiationBills.reduce(reducer, 0);

  useEffect(() => {
    updateAttribute('negotiationSubtotal', subTotal);
    updateAttribute('isSubtotalValid', subTotal >= minValueSelected / 100);
  }, [subTotal, minValueSelected, updateAttribute]);

  const addNewItem = () => {
    const newItems = negotiationBills.concat(newItem());
    updateAttribute(attributeName, newItems);
  };

  const removeItem = (index: number) => {
    const newItems = [
      ...negotiationBills.slice(0, index),
      ...negotiationBills.slice(index + 1),
    ];
    updateAttribute(attributeName, newItems);
  };

  const onChange = (index, values) => {
    negotiationBills[index] = values;

    updateAttribute(attributeName, negotiationBills);
  };

  const originalBillsValues = [
    {
      value_cents:
        originalRecurrentBills.attributes.subtotal_with_fine_and_interest,
      name: 'subtotal_with_fine_and_interest',
    },
    {
      value_cents: originalRecurrentBills.attributes.gross_amount,
      name: 'gross_amount',
    },
    {
      value_cents: originalRecurrentBills.attributes.original_amount,
      name: 'original_amount',
    },
    {
      value_cents:
        originalRecurrentBills.attributes.subtotal_with_partial_discount,
      name: 'subtotal_with_partial_discount',
    },
  ];

  return (
    <S.NegotiationBillsContainer>
      <S.NegotiationBillsHeader>
        <div>
          <Title name={title} />
        </div>
      </S.NegotiationBillsHeader>
      <S.NegotiationBillsContent>
        <div className="item-list">
          <div className="item-list-header">
            {windowSize > 767 && (
              <>
                <span>Cobrança*</span>
                <span>Valor*</span>
              </>
            )}
          </div>
          <S.OriginalBillsValues>
            {originalBillsValues.map((originalBill, index) => (
              <span
                className={
                  form.selected_min_value_to_negotiate === originalBill.name
                    ? 'active'
                    : ''
                }
                key={index}
              >
                {centsToBRL(originalBill.value_cents)}
              </span>
            ))}
            <Tooltip
              content={
                <>
                  Apenas o valor selecionado irá entrar
                  <br /> no cálculo de negociação.
                </>
              }
            >
              <AgendaIcon name="help" size="medium" />
            </Tooltip>
          </S.OriginalBillsValues>
          <PageSubTitle>
            O subtotal das cobranças deve ser R$ 10,00, e máximo o valor da
            negociação.
          </PageSubTitle>
          <div className="item-list-content">
            {negotiationBills.map((bill, index) => (
              <NegotiationItem
                itemValues={bill}
                index={index}
                removeItem={removeItem}
                onChange={onChange}
                key={bill.key}
                minValueSelected={minValueSelected}
                withDiscount={false}
              />
            ))}
          </div>
          {hasError && <FormValidationErrors attributeName={attributeName} />}
          <S.ItemsListSummary>
            <Button variation="secondary" onClick={addNewItem}>
              Adicionar cobrança
            </Button>
            <div className="subtotal">
              <div className="subtotal-info">
                <span>Subtotal</span>
                <Tooltip content={TooltipSubtotal}>
                  <AgendaIcon
                    name="help"
                    size="medium"
                    className="custom-size"
                  />
                </Tooltip>
              </div>
              <ColoredText
                variation={`${!form.isSubtotalValid ? 'danger' : 'dark'} w600`}
              >
                <Currency value={subTotal} />
              </ColoredText>
            </div>
          </S.ItemsListSummary>
        </div>
      </S.NegotiationBillsContent>
      <Toast />
      <br />
      {!form.isSubtotalValid && (
        <S.AlertContent>
          <Alert variation="warning">
            O valor proposto para a negociação está fora da política da empresa.
            Precisa fazer ajuste no valor para seguir com a negociação. Para
            saber mais, acesse nossa{' '}
            <a
              href="https://suporte.agendaedu.com/hc/pt-br"
              target={'_blank'}
              rel="noreferrer"
            >
              central de ajuda.
            </a>
          </Alert>
        </S.AlertContent>
      )}
    </S.NegotiationBillsContainer>
  );
};

export default withFormContext(withWindowSize(NegotiationBills));
