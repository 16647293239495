/* eslint-disable react/display-name */
import React from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { ConnectedRouter } from 'connected-react-router/immutable';
import { StylesProvider } from '@agendaedu/ae-web-components';

import AppContext from 'core/contexts/App';
import history from 'core/utils/history';
import configureStore from 'store/storeConfig/configureStore';

import themes from 'config/themes';

const reduxProvider = (WrappedComponent) => (props, railsContext) => {
  const store = configureStore(railsContext, history);
  const theme = themes(railsContext);

  return (
    <AppContext.Provider value={railsContext}>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <ThemeProvider theme={theme}>
            <StylesProvider theme={theme}>
              <WrappedComponent {...props} />
            </StylesProvider>
          </ThemeProvider>
        </ConnectedRouter>
      </Provider>
    </AppContext.Provider>
  );
};

export default reduxProvider;
