/* eslint-disable no-prototype-builtins */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';

import Toast from 'components/Toast';
import Alert from 'components/Alert';
import Field from 'components/Form/Field';
import FormContainer from 'components/Form/FormContainer';
import EmptyState from 'components/EmptyState';

import StudentsList from '../StudentsList';
import StudentSections from '../StudentSections';

import {
  IMAGES_CLASSROOMS_URL,
  IMAGES_JOURNAL_DOMAIN_URL,
} from 'core/constants/index';
import withFormContext, { formPropTypes } from 'core/hoc/withFormContext';
import validateTextAreaReady from 'core/lib/FormValidator/validators/textAreaReady';
import tabifyForWizard from 'core/hoc/tabifyForWizard';

import DailySummariesActions from 'store/dailySummaries/actions';

import * as S from './styles';

const GeneralInformationTab = ({ formContext: { updateAttribute } }) => {
  const dispatch = useDispatch();
  const { setDate, setSelectedsIds } = DailySummariesActions;

  const {
    classroom: {
      attributes: {
        name,
        student_profiles: { data },
      },
    },
    isLoading,
    classroomIncluded,
    date,
    draftDailySummary,
    studentsIdsSelecteds,
  } = useSelector((state) => state.dailySummaries);

  const initialDraft = useMemo(() => ({ ...draftDailySummary }), []);

  const onChangeDate = (value) => {
    dispatch(setDate(moment(value)));
  };

  const onSelectStudents = (ids) => {
    dispatch(setSelectedsIds(ids));
  };

  const getOnlyPastDates = (day) => day.isAfter(moment().endOf('day'));

  const hasStudentSelected = Boolean(studentsIdsSelecteds.length);

  const getClassroomIncludedNames = (type) => {
    return classroomIncluded.find((info) => info.type === type)?.attributes
      .name;
  };

  const educationalStageName = getClassroomIncludedNames('educational_stage');
  const headquarterName = getClassroomIncludedNames('headquarter');

  const schoolSubtitle =
    !educationalStageName || !headquarterName
      ? ''
      : `${headquarterName} / ${educationalStageName}`;

  const renderDateText = () => {
    let dateString = '';
    if (moment(date).year() !== moment().year()) {
      dateString = moment(date).format('dddd[,] LL');
    } else {
      dateString = moment(date).format('dddd[,] D [de] MMMM');
    }

    return dateString.charAt(0).toUpperCase() + dateString.slice(1);
  };

  const renderForm = () => {
    return (
      <>
        <div id="form-header">
          <h4>{renderDateText()}</h4>
          <FormContainer>
            <Field
              attributeName="date"
              type="date"
              onValueChange={(value) => onChangeDate(value)}
              defaultValue={date}
              showAllDays
              isOutsideRange={getOnlyPastDates}
            />
          </FormContainer>
        </div>
        {hasStudentSelected ? (
          <StudentSections hasStudentSelected={hasStudentSelected} />
        ) : (
          <EmptyState
            imgUrl={IMAGES_JOURNAL_DOMAIN_URL.selectStudentInfo}
            message={
              <p>
                Selecione um ou mais alunos para <br />
                começar o preenchimento do diário.
              </p>
            }
          />
        )}
      </>
    );
  };

  const removeIds = (list) => {
    return list.map(({ id, ...attributes }) => attributes);
  };

  const formatDraft = (object) => {
    const newObject = { ...object };

    for (let prop in newObject) {
      if (Array.isArray(newObject[prop])) {
        newObject[prop] = removeIds(newObject[prop]);
      } else if (
        typeof newObject[prop] === 'object' &&
        newObject[prop] !== null
      ) {
        newObject[prop] = formatDraft(newObject[prop]);
      }
    }

    return newObject;
  };

  const areDraftsEqual = useMemo(() => {
    return _.isEqualWith(
      formatDraft(draftDailySummary),
      formatDraft(initialDraft)
    );
  }, [draftDailySummary]);

  useEffect(() => {
    updateAttribute(
      'canSave',
      !areDraftsEqual && validateTextAreaReady(draftDailySummary.observation)
    );
  }, [areDraftsEqual, draftDailySummary]);

  return (
    <S.DailySummariesContainer>
      <S.BodyContent>
        {!data.length && !isLoading ? (
          <EmptyState
            imgUrl={IMAGES_CLASSROOMS_URL.emptyStateUrl}
            message={
              <p>
                Não foram encontrados alunos <br />
                nesta turma.
              </p>
            }
          />
        ) : (
          <>
            <div id="StudentsSectionContainer">
              <S.ActionsContainer>
                <h2>{name}</h2>
                <h3>{schoolSubtitle}</h3>
              </S.ActionsContainer>
              <StudentsList
                studentsIdsSelecteds={studentsIdsSelecteds}
                setStudentIdsSelecteds={onSelectStudents}
                students={data}
                isCheckable
                multiSelect
              />
            </div>
            <S.FormContent>{renderForm()}</S.FormContent>
          </>
        )}
      </S.BodyContent>
      <Toast />
    </S.DailySummariesContainer>
  );
};

GeneralInformationTab.propTypes = {
  ...formPropTypes,
};

export default tabifyForWizard({
  title: 'Informações',
})(withFormContext(GeneralInformationTab));
