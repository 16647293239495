import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import HandoutContext from 'core/contexts/Handout';
import useGoogleDrive from 'core/hooks/useGoogleDrive';
import withAppContext from 'core/hoc/withAppContext';

import HandoutsActions from 'store/handouts/actions';
import { FormAttributesProps, HandoutState } from 'store/handouts/types';

import * as S from './styles';

import DuplicateNotificationModal from '../DuplicateNotificationModal';
import FormFullScreen from 'components/FormFullScreen';
import GeneralInformationStep from '../GeneralInformationStep';
import RecipientsStep from 'components/Form/RecipientsTab';
import Toast from 'components/Toast';

import { FormSkeleton } from '../skeleton';

import { Props } from './types';

const WIZARD_STEPS = [GeneralInformationStep, RecipientsStep];

const DuplicateHandout: React.FC<Props> = ({
  appContext: { dataArea },
  match,
}) => {
  const { id: handoutId } = match?.params;

  const [activeNotificationModal, setActiveNotificationModal] = useState(false);

  const { t } = useTranslation(['handouts']);

  const dispatch = useDispatch();

  const { formMeta, current, isSending, isLoadingDuplicateHandout } =
    useSelector((state: HandoutState) => state.handouts);

  const googleClientId = process.env.GOOGLE_CLIENT_ID;

  const googleClient = useGoogleDrive(
    googleClientId,
    'https://www.googleapis.com/auth/drive.readonly'
  );

  const hasCurrentHandout = current?.id;

  const { createNewHandoutRequest, fetchClassrooms, fetchHandoutRequest } =
    HandoutsActions;

  const handleCreateHandout = (form: FormAttributesProps) => {
    setActiveNotificationModal(false);
    dispatch(createNewHandoutRequest({ form, duplicate: true }));
  };

  const handleOnSubmit = ({ form }) => {
    isLoadingDuplicateHandout
      ? setActiveNotificationModal(true)
      : handleCreateHandout(form);
  };

  const handleFetchClassrooms = useCallback(() => {
    dispatch(fetchClassrooms());
  }, [dispatch, fetchClassrooms]);

  useEffect(() => {
    handleFetchClassrooms();
  }, [handleFetchClassrooms]);

  useEffect(() => {
    if (handoutId) {
      const params = {
        id: handoutId,
        duplicate: true,
      };
      dispatch(fetchHandoutRequest(params));
    }
  }, [dispatch, fetchClassrooms, fetchHandoutRequest, handoutId]);

  return (
    <S.HandoutsFormContainer>
      <HandoutContext.Provider value={googleClient}>
        {hasCurrentHandout ? (
          <FormFullScreen
            id="duplicateHandout"
            form={current}
            initialAttributes={current}
            formMeta={{
              ...formMeta,
              googleClientId,
              canEditClassrooms: true,
              select_options: {
                classroom_id: formMeta.classrooms,
              },
              student_profiles: current.student_profiles,
              duplicatedUploadAttachments: false,
              action: 'duplicate',
            }}
            titlePage={t('new_handout_form.title')}
            backTo={`/${dataArea}/handouts`}
            backToExternal
            isSubmitting={isSending}
            steps={WIZARD_STEPS}
            onSubmit={handleOnSubmit}
            titleModal={t('modals.handout_form_discard.title')}
            contentModal={t('modals.handout_form_discard.description')}
          >
            <DuplicateNotificationModal
              active={activeNotificationModal}
              onClose={() => setActiveNotificationModal(false)}
              onSubmit={handleCreateHandout}
            />
          </FormFullScreen>
        ) : (
          <FormSkeleton
            title={t('new_handout_form.title')}
            dataArea={dataArea}
          />
        )}
      </HandoutContext.Provider>
      <Toast />
    </S.HandoutsFormContainer>
  );
};

export default withAppContext(DuplicateHandout);
