import React from 'react';
import { useSelector } from 'react-redux';

import { DEFAULT_MOODTRACK_VALUE } from 'core/constants';

import withAppContext from 'core/hoc/withAppContext';

import { MoodTrackersState } from 'store/moodTrackers/types';

import * as S from './styles';
import MoodCard from './MoodCard';
import DashboardSkeleton from './Skeleton';

const Dashboard = () => {
  const { isSending, answers, answerType } = useSelector(
    (state: MoodTrackersState) => state.moodTrackers
  );

  const renderAllMoodTrakersAnswers = Object.entries({
    ...DEFAULT_MOODTRACK_VALUE,
    ...answers,
  }).map((answer) => <MoodCard key={answer[0].toString()} answer={answer} />);

  const renderAnswerWithMood = Object.entries(answers).map((answer) => (
    <MoodCard key={answer[0].toString()} answer={answer} />
  ));

  const renderAnswerTypeCard = {
    without_mood: <></>,
    with_mood: renderAnswerWithMood,
    default: renderAllMoodTrakersAnswers,
  }[answerType === '' ? 'default' : answerType];

  return (
    <>
      {isSending ? (
        <DashboardSkeleton />
      ) : (
        <S.MoodsContainer>{renderAnswerTypeCard}</S.MoodsContainer>
      )}
    </>
  );
};

export default withAppContext(Dashboard);
