import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { RecurrentPlansState, RootState } from './types';
import * as S from './styles';

import actionRecurrentForms from 'store/edupay/recurrentForms/actions';
import actionRecurrentPlans from 'store/edupay/recurrentPlans/actions';

import {
  buildDiscount,
  formattedDiscounts,
  formattedPenality,
} from 'core/utils/edupay/functions';
import { DISCOUNT_KINDS } from 'core/constants/index';
import withAppContext from 'core/hoc/withAppContext';

import EditRecurrentBillInBatchesTab from 'components/Payments/Recurrent/EditRecurrentBillInBatches/EditRecurrentBillInBatchesTab';
import EditRecurrentBillInBatchesModal from 'components/Payments/Recurrent/EditRecurrentBillInBatches/EditRecurrentBillInBatchesModal';
import Loader from 'components/Loader';
import RecurrentInBatchesForm from 'components/Payments/Recurrent/RecurrentInBatchesForm';
import Toast from 'components/Toast';
import { WalletState } from 'components/Payments/WalletList/types';

const EditRecurrentBillInBatches = () => {
  const dispatch = useDispatch();
  const [showEditInBatchModal, setShowEditInBatchsModal] = useState(false);

  const { billetTax, pixTax } = useSelector((state: RootState) => state.root);

  const maxBillQuantity = 30;

  const {
    selectedRecurrent,
    selectedAmount,
    isLoading,
    isLoadingBatchs,
    recipientsWallet,
  } = useSelector((state: RecurrentPlansState) => state.recurrentPlans);

  const { currentWallet } = useSelector((state: WalletState) => state.wallets);

  const { formAction } = actionRecurrentForms;
  const { fetchRecurrentInBatchExtraInformations, editRecurrentBillInBatches } =
    actionRecurrentPlans;

  const toggleshowEditInBatchModal = (): void =>
    setShowEditInBatchsModal(!showEditInBatchModal);

  const editRecurrentPlanRequest = useCallback(
    (params) => {
      dispatch(editRecurrentBillInBatches(params));
    },
    [dispatch, editRecurrentBillInBatches]
  );

  const handleSubmit = (form) => {
    editRecurrentPlanRequest({
      recurrent_bill_ids: selectedRecurrent,
      due_date: form.due_date,
      send_financial_responsible: form.send_financial_responsible,
      allowed_payment_method: form.allowed_payment_method,
      recipient_wallet_id: form.recipient_wallet_id,
      toggle_penality: form.toggle_penality,
      edupay_penality_attributes: formattedPenality(form),
      toggle_discount: form.toggle_discount,
      edupay_discounts: formattedDiscounts(form),
      toggle_batch_penality: form.toggleBatchPenality,
      toggle_batch_discount: form.toggleBatchDiscount,
      toggle_batch_financial_responsible: form.toggleBatchFinancialResponsible,
      toggle_batch_payment: form.toggleBatchPayment,
      auto_generate_pix: form.auto_generate_pix,
      allow_credit_card_discount: form.allow_credit_card_discount,
    });
  };

  const enableFormAction = useCallback(() => {
    dispatch(formAction('editBillInBatch'));
  }, [formAction, dispatch]);

  const getRecurrentInBatchExtraInformations = useCallback(() => {
    dispatch(
      fetchRecurrentInBatchExtraInformations({
        recurrent_bill_ids: selectedRecurrent.slice(0, maxBillQuantity),
        limit: maxBillQuantity,
      })
    );
  }, [dispatch, fetchRecurrentInBatchExtraInformations, selectedRecurrent]);

  useEffect(() => {
    enableFormAction();
    getRecurrentInBatchExtraInformations();
  }, []);

  if (!selectedAmount)
    return <Redirect to="/schools/school_products/tab/recurrent" />;

  return (
    <S.EditRecurrentBillInBatchesWrapper>
      <Loader isLoading={isLoading}>
        <RecurrentInBatchesForm
          action="bill"
          form={{
            due_date: null,
            allowed_payment_method: [],
            send_financial_responsible: false,
            toggle_penality: false,
            edupay_penality: {
              fine_percent: 2.0,
              interest_percent: 1.0,
            },
            recipient_wallet_id: currentWallet.attributes.recipient_wallet_id,
            toggle_discount: false,
            edupay_discounts: [buildDiscount()],
            toggleBatchDueDate: false,
            toggleBatchPayment: false,
            toggleBatchWallet: false,
            toggleBatchPenality: false,
            toggleBatchDiscount: false,
            toggleBatchFinancialResponsible: false,
            auto_generate_pix: true,
            allow_credit_card_discount: true,
          }}
          formMeta={{
            pixTax,
            billetTax,
            status: 'pending',
            select_options: {
              kind: DISCOUNT_KINDS,
              recipient_wallet_id: recipientsWallet,
            },
          }}
          steps={[EditRecurrentBillInBatchesTab]}
          onSubmit={toggleshowEditInBatchModal}
          isSubmitting={isLoadingBatchs}
          backTo="/schools/school_products/tab/recurrent"
          titlePage="Editar recorrências"
          titleModal="Descartar preenchimento"
          contentModal="A edição das cobranças será cancelada e as informações preenchidas serão descartadas. Tem certeza que deseja descartar?"
        >
          <EditRecurrentBillInBatchesModal
            isOpen={showEditInBatchModal}
            disabled={isLoadingBatchs}
            toggleModal={toggleshowEditInBatchModal}
            handleSubmit={handleSubmit}
            titleModal="Atualizar cobranças"
            contentModal={`${selectedAmount} cobranças serão atualizadas com as novas informações. Tem certeza que deseja atualizar?`}
            notActiveUpdateText="Edite pelo menos um item para atualizar as cobranças"
            backButton="Cancelar"
            submitButton="Confirmar"
          />
        </RecurrentInBatchesForm>
        <Toast />
      </Loader>
    </S.EditRecurrentBillInBatchesWrapper>
  );
};

export default withAppContext(EditRecurrentBillInBatches);
