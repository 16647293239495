import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { withRouter } from 'react-router-dom';
import withAppContext from 'core/hoc/withAppContext';

import {
  theme,
  DefaultThemeProps,
  Box,
  Text,
  FlexGrid,
  FlexGridItem,
  Tag,
  Icon,
  Card,
  Tooltip,
  BarStatus,
  Option,
} from '@agendaedu/ae-web-components';

import * as S from './styles';
import AccordionDetails from '../components/AccordionDetails';

import moment from 'moment';
import { HeaderInfoDetailsProps } from './types';
import AcceptModal from '../components/Modal';
import TextToggleEye from '../components/TextToggleEye';
import { trackEvent } from 'config/amplitude';

const VARIANT_STATUS = {
  done: 'positive',
  error: 'negative',
};

const VARIANT_LIGHTHOUSE = {
  done: 'positive',
  error: 'negative',
  neutral: 'neutral',
};

const VARIANT_LIGHTHOUSE_MESSEGER = {
  done: 'Sucesso na integração',
  error: 'Falha na integração',
  neutral: 'Não houveram importações',
};

const HeaderInfoDetails: React.FC<HeaderInfoDetailsProps> = ({
  integration,
  history,
}) => {
  const attributes = integration?.attributes;
  const { t } = useTranslation(['integrations_monitor']);
  const defaultThemeProps: DefaultThemeProps = theme;
  const baseURLDetails = `/school/erp_integrations/monitor/${integration.id}?entity=`;

  const [rangedates, setRangedates] = useState([]);
  const [isAcceptModal, setIsAcceptModal] = useState(false);

  const {
    colors: { neutral },
  } = defaultThemeProps;

  const today = new Date();
  const tomorrow = new Date();
  tomorrow.setDate(today.getDate() + 1);

  const getDatesBetween = useCallback(() => {
    const priorDate = new Date(new Date().setDate(today.getDate() - 29));
    const currentDate = new Date(priorDate);
    const dates = [];
    while (currentDate <= tomorrow) {
      const tempDate = moment(currentDate).format('DD/MM/YY');
      dates.push({
        last_import_date: tempDate.toString(),
        status: 'neutral',
        import_count: 0,
      });

      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
  }, []);

  useEffect(() => {
    const rageValidDates = getDatesBetween();

    const map = {};
    rageValidDates.forEach((item) => (map[item.last_import_date] = item));
    attributes.headlight.forEach((item) => (map[item.last_import_date] = item));

    setRangedates(Object.values(map));
  }, [getDatesBetween]);

  const openInconsistency = (entity: string): void => {
    history.push(`${baseURLDetails}${entity}`);
  };

  const checkTypesBlacklist = (value: string): boolean => {
    if (value == 'null' || value == 'undefined') {
      return true;
    }

    return false;
  };

  const rulesRenderValuesDetails = (data: {
    name: string;
    value: string;
  }): JSX.Element => {
    if (typeof data.value === 'boolean') {
      return (
        <S.Title>
          {data.value
            ? t('card.header_details.enabled')
            : t('card.header_details.disabled')}
        </S.Title>
      );
    } else if (data.name === 'Senha') {
      return <TextToggleEye text={`${data.value}`} hiddenCharacters={6} />;
    } else {
      return <S.Title>{`${data.value}`}</S.Title>;
    }
  };

  const renderBodyDetails = (
    data: { name: string; value: string }[]
  ): JSX.Element => {
    if (!data) return <></>;

    return (
      <>
        {data?.map(
          (value: { name: string; value: string }, index): JSX.Element => {
            if (checkTypesBlacklist(`${value.value}`)) return null;

            return (
              <Box key={index}>
                <S.WrapperContent>
                  <FlexGrid gutter="sm">
                    <FlexGridItem cols={{ desktopLG: 10 }} filled>
                      <Box display="flex" alignItems="center">
                        <S.Informations>
                          <S.Subtitle>{value.name}</S.Subtitle>
                          <div>
                            {rulesRenderValuesDetails({
                              name: value.name,
                              value: value.value,
                            })}
                          </div>
                        </S.Informations>
                      </Box>
                    </FlexGridItem>
                  </FlexGrid>
                </S.WrapperContent>
              </Box>
            );
          }
        )}
      </>
    );
  };

  return (
    <Box display="flex" position="relative">
      <FlexGrid>
        <Card mt={32} padding={0}>
          <Box width="100%" p="24px">
            <FlexGridItem cols={{ desktopLG: 10 }} filled>
              <Box
                display="flex"
                flexDirection={{ _: 'column', desktopLG: 'row' }}
              >
                <Box
                  mr={24}
                  mb={{ _: 24, desktopLG: 0 }}
                  height={68}
                  width={68}
                  minWidth={68}
                  borderRadius={8}
                  overflow="hidden"
                >
                  <img
                    src={attributes?.logo}
                    height="100%"
                    width="100%"
                    alt="Logo da integração"
                  />
                </Box>
                <Box display="flex" flexDirection="column" flexWrap="wrap">
                  <Text
                    as="h2"
                    mt={4}
                    mr={24}
                    mb={8}
                    variant="headline-h2-bold-24"
                    fontWeight={700}
                    color={neutral.black}
                  >
                    {attributes?.name}
                  </Text>

                  <Box
                    display="flex"
                    alignItems="center"
                    flexDirection="row"
                    flexWrap="wrap"
                  >
                    <Tooltip
                      content={
                        <Box
                          display="flex"
                          alignItems="center"
                          flexDirection="row"
                          flexWrap="wrap"
                        >
                          <Text
                            as="p"
                            variant="body-regular-12"
                            textAlign="justify"
                            justifyContent="justify"
                          >
                            {attributes.enabled
                              ? t('card.header_details.enable_automatic_alert')
                              : t(
                                  'card.header_details.disabled_automatic_alert'
                                )}
                          </Text>
                        </Box>
                      }
                      align="bottom-end"
                      elementRef={
                        <Tag
                          mr={8}
                          mb={8}
                          name={
                            attributes.enabled
                              ? t('card.header_details.enable_automatic')
                              : t('card.header_details.disabled_automatic')
                          }
                          variant={attributes.enabled ? 'positive' : 'neutral'}
                        />
                      }
                    />

                    <Tooltip
                      content={
                        <Box
                          display="flex"
                          alignItems="center"
                          flexDirection="row"
                          flexWrap="wrap"
                        >
                          <Text
                            as="p"
                            variant="body-regular-12"
                            textAlign="justify"
                            justifyContent="justify"
                          >
                            {attributes.allow_delete
                              ? t(
                                  'card.header_details.enable_deleted_data_alert'
                                )
                              : t(
                                  'card.header_details.disabled_deleted_data_alert'
                                )}
                          </Text>
                        </Box>
                      }
                      align="bottom-end"
                      elementRef={
                        <Tag
                          mr={8}
                          mb={8}
                          name={
                            attributes.allow_delete
                              ? t('card.header_details.enable_deleted_data')
                              : t('card.header_details.disabled_deleted_data')
                          }
                          variant={
                            attributes.allow_delete ? 'positive' : 'neutral'
                          }
                        />
                      }
                    />
                  </Box>
                </Box>
              </Box>
            </FlexGridItem>

            <FlexGridItem cols={{ desktopLG: 12 }} filled>
              <Box display="flex" alignItems="center" mt={32} mb={32}>
                <AccordionDetails
                  title={t('card.header_details.title_info_integration')}
                  integrationID={integration.id}
                  expandClick={() => {
                    const elem = document.getElementById(
                      'accordion__panel-raa-0'
                    );
                    elem.attributes?.hidden != undefined &&
                      setIsAcceptModal(true);
                  }}
                >
                  <S.BoxBlur isBlur={isAcceptModal}>
                    {renderBodyDetails(attributes?.configurations)}
                  </S.BoxBlur>
                </AccordionDetails>
              </Box>
            </FlexGridItem>

            <FlexGridItem cols={{ desktopLG: 12 }} filled>
              <Text as="h2" variant="title-bold-20" mb="16px">
                {t('card.header_details.title_last_sync')}
              </Text>
            </FlexGridItem>
            <Box
              display="flex"
              alignItems="center"
              mt={32}
              flexDirection="row"
              flexWrap="wrap"
            >
              <FlexGrid gutter="sm">
                <FlexGridItem cols={{ desktopLG: 5 }} filled>
                  <Box
                    display="flex"
                    alignItems="center"
                    flexDirection="row"
                    flexWrap="wrap"
                  >
                    <Box display="flex" alignItems="center">
                      <S.Informations>
                        <S.Subtitle>{t('card.header_details.hour')}</S.Subtitle>
                        <div>
                          <Icon name="calendar-hour" />
                          <S.Title mt={8}>
                            {attributes?.last_integration_log.time}
                          </S.Title>
                        </div>
                      </S.Informations>
                    </Box>
                    <Box display="flex" alignItems="center">
                      <S.Informations>
                        <S.Subtitle>{t('card.header_details.date')}</S.Subtitle>
                        <div>
                          <Icon name="calendar" />
                          <S.Title mt={8}>
                            {attributes?.last_integration_log.date}
                          </S.Title>
                        </div>
                      </S.Informations>
                    </Box>
                    <Box display="flex" alignItems="center" pr={16}>
                      <S.Informations>
                        <S.Subtitle>
                          {t('card.header_details.status')}
                        </S.Subtitle>
                        <div>
                          <Tag
                            name={
                              attributes?.last_integration_log.status === 'done'
                                ? t(
                                    'card.header_details.integration_succes_status'
                                  )
                                : t(
                                    'card.header_details.integration_error_status'
                                  )
                            }
                            variant={
                              VARIANT_STATUS[
                                attributes?.last_integration_log.status
                              ]
                            }
                          />
                        </div>
                      </S.Informations>
                    </Box>

                    {attributes?.last_integration_log.status === 'error' && (
                      <Box
                        mt={16}
                        display="flex"
                        alignItems="center"
                        flexDirection="row"
                      >
                        <Icon name="error-circle" />
                        <S.Title>
                          <Option onClick={() => openInconsistency('provider')}>
                            {t('card.header_details.see_details')}
                          </Option>
                        </S.Title>
                      </Box>
                    )}
                  </Box>
                </FlexGridItem>

                {attributes.enabled && (
                  <Box
                    display="flex"
                    alignItems="center"
                    mt={16}
                    ml={8}
                    width="100%"
                  >
                    <Text as="p" variant="label-regular-12">
                      {t('card.header_details.lighthouse_observation', {
                        day: today.toLocaleDateString('pt-BR'),
                      })}
                    </Text>
                  </Box>
                )}
              </FlexGrid>
            </Box>
          </Box>

          <Box
            display="flex"
            width="100%"
            flexDirection="row"
            justifyContent="space-between"
            flexWrap="wrap"
            p={24}
            mt={32}
            backgroundColor={neutral.gray6}
          >
            <Text as="h2" variant="subtitle-medium-16">
              {t('card.header_details.lighthouse_title')}
            </Text>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              flexWrap="wrap"
              mt={8}
            >
              {rangedates.map((value, key) => (
                <Tooltip
                  key={key}
                  content={`${value.last_import_date}\n ${value.import_count} ${
                    value.import_count > 1 ? 'importações' : 'importação'
                  }\n ${VARIANT_LIGHTHOUSE_MESSEGER[value.status]}`}
                  align="top-end"
                  elementRef={
                    <BarStatus
                      size={40}
                      variant={VARIANT_LIGHTHOUSE[value.status]}
                    />
                  }
                />
              ))}
            </Box>
          </Box>
        </Card>
      </FlexGrid>

      <AcceptModal
        isModalOpen={isAcceptModal}
        closeModal={() => {
          trackEvent(`integrations_monitor_close_modal_conf_details`);
          setIsAcceptModal(false);
        }}
        titleModal={t('card.header_details.modal.title')}
        textCloseButton={t('card.header_details.modal.text_close_button')}
      >
        <Text
          p={24}
          as="p"
          variant="body-regular-14"
          textAlign="justify"
          color="neutral.black"
          lineHeight="lg"
          fontWeight={500}
          mb={24}
        >
          {t('card.header_details.modal.content')}
        </Text>
      </AcceptModal>
    </Box>
  );
};

export default withRouter(withAppContext(HeaderInfoDetails));
