export default {
  search: 'Procurar',
  notfound: 'Nenhum emoji encontrado',
  skintext: 'Escolha seu tom de pele padrão',
  categories: {
    search: 'Procurar resultados',
    recent: 'Usados frequentemente',
    people: 'Sorrisos & Pessoas',
    nature: 'Animais & Natureza',
    foods: 'Comidas & Bebidas',
    activity: 'Atividade',
    places: 'Viagens e locais',
    objects: 'Objetos',
    symbols: 'Símbolos',
    flags: 'Bandeiras',
    custom: 'Outros',
  }
};
