const actions = {
  FETCH_REQUEST: 'FETCH_REQUEST/integrations',
  FETCH_SUCCESS: 'FETCH_SUCCESS/integrations',
  FETCH_ERROR: 'FETCH_ERROR/integrations',
  SET_HEADQUARTERS: 'SET_HEADQUARTERS/integrations',
  SET_COURSES: 'SET_COURSES/integrations',
  SET_SCHOOL_TERMS: 'SET_SCHOOL_TERMS/integrations',
  UPDATE_REQUEST: 'UPDATE_REQUEST/integrations',
  UPDATE_SUCCESS: 'UPDATE_SUCCESS/integrations',
  UPDATE_SENT: 'UPDATE_SENT/integrations',
  UPDATE_ERROR: 'UPDATE_ERROR/integrations',
  fetchIntegrationFiltersRequest: () => ({ type: actions.FETCH_REQUEST }),
  setHeadquarters: filters => ({ type: actions.SET_HEADQUARTERS, filters }),
  setCourses: filters => ({ type: actions.SET_COURSES, filters }),
  setSchoolTerms: filters => ({ type: actions.SET_SCHOOL_TERMS, filters }),
  updateIntegrationFiltersSent: () => ({ type: actions.UPDATE_SENT }),
  updateIntegrationFiltersRequest: params => ({ type: actions.UPDATE_REQUEST, params }),
};

export default actions;
