import React, { useCallback } from 'react';
import { Avatar } from '@agendaedu/ae-web-components';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { formatDatesCalendar } from 'core/utils/date';
import { useLaunchDarkly } from 'core/hooks/useLaunchDarkly';

import messagesActions from 'store/messages/actions';
import { MessageActionVariant } from 'store/messages/types';

import { MessageAction, MessageVariant } from 'components/MessageBalloon/types';
import { MessageBalloon } from 'components/MessageBalloon';

import { Props } from './types';

export const GroupMessageBalloon: React.FC<Props> = ({ message }) => {
  const { t } = useTranslation(['messages']);
  const dispatch = useDispatch();

  const { isActiveFlag } = useLaunchDarkly({ flag: 'GROUPS_V2' });

  const { toggleDeleteMessageModal, setCurrentMessage, setChangeFooterToast } =
    messagesActions;

  const {
    content,
    created_at: createdAt,
    deleted_at: deletedAt,
    document,
    attached,
    sender: {
      attributes: {
        name,
        avatar_color: avatarColor,
        avatar_url: avatarUrl,
        name_initials: nameInitials,
      },
    },
    type,
    updated,
  } = message.attributes;

  const isMessageOwner: boolean = type === 'sent';
  const isDeletedMessage: boolean = deletedAt !== '';

  const handleDeleteMessage = useCallback((): void => {
    dispatch(setCurrentMessage(message));
    dispatch(toggleDeleteMessageModal());
  }, [dispatch, message, setCurrentMessage, toggleDeleteMessageModal]);

  const handleToggleFooterToast = useCallback(
    (variant: MessageActionVariant) => {
      dispatch(
        setChangeFooterToast({
          active: true,
          variant,
          currentMessage: message,
        })
      );
    },
    [dispatch, message, setChangeFooterToast]
  );

  const getMessageVariant = (): MessageVariant => {
    if (isDeletedMessage) return 'deleted';

    return 'default';
  };

  const getActions = (): MessageAction[] => {
    const actions: MessageAction[] = [];

    if (isDeletedMessage) return;

    {
      isActiveFlag &&
        actions.push({
          iconName: 'reply',
          onClick: () => handleToggleFooterToast('reply'),
          testId: 'reply-action',
        });
    }

    if (isMessageOwner)
      actions.push({
        iconName: 'pencil',
        onClick: () => handleToggleFooterToast('edit'),

        testId: 'edit-action',
      });

    if (isMessageOwner && isActiveFlag)
      actions.push({
        iconName: 'trash',
        onClick: handleDeleteMessage,
        testId: 'delete-action',
      });

    return actions;
  };

  const renderSenderAvatar = (): React.ReactNode => {
    if (isMessageOwner) return;

    return (
      <Avatar
        avatarColor={avatarColor}
        avatarUrl={avatarUrl}
        nameInitials={nameInitials}
        size="xs"
      />
    );
  };

  const renderMessageInfo = (): string => {
    const date = formatDatesCalendar(createdAt);

    if (isDeletedMessage)
      return `${date} • ${t('groups.chat.deleted_message')}`;
    if (updated) return `${date} • ${t('groups.chat.edited_message')}`;

    return date;
  };

  return (
    <MessageBalloon
      actions={getActions()}
      attachment={document}
      balloonFooter={renderMessageInfo()}
      balloonHeader={name}
      balloonLeft={renderSenderAvatar()}
      content={content}
      status={type}
      variant={getMessageVariant()}
      attached={{
        title: attached?.attributes.sender?.attributes.name,
        content: attached?.attributes.content,
      }}
    />
  );
};
