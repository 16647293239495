export const loadImage = (imageUrl: string) => {
  const img = new Image();
  img.src = imageUrl;

  return new Promise((res, rej) => {
    img.onload = function () {
      res({
        width: img.width,
        height: img.height,
      });
    };

    img.onerror = () => {
      // default designer
      res({
        width: 400,
        height: 304,
      });
    };
  });
};
