import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import FormFullScreen from 'components/FormFullScreen';
import Loader from 'components/Loader';
import Toast from 'components/Toast';
import ResponsibleProfilesActions from 'store/responsibleProfiles/actions';
import ResponsibleProfileInformationsTab from 'pages/ResponsibleProfiles/FormTabs/InformationsTab';
import SchoolServices from 'core/services/School';
import withAppContext, { appPropTypes } from 'core/hoc/withAppContext';
import * as S from 'pages/ResponsibleProfiles/styles.js';

const contentModalText = `
  A recuperação do responsável será cancelada e as
  informações preenchidas serão descartadas.
  Tem certeza que deseja descartar?
`;

const ResponsibleProfilesRecover = ({ match, appContext: { policies } }) => {
  const { id } = match.params;
  const dispatch = useDispatch();
  const { setRecoveryResponsibleProfile, recoverResponsibleProfile } =
    ResponsibleProfilesActions;
  const dataArea = useSelector((state) => state.root.dataArea);
  const { isLoading, isSubmitting, form, formMeta } = useSelector(
    (state) => state.responsibleProfiles
  );
  const schoolServices = new SchoolServices(dataArea);
  const { can_use_legacy_id: canUseLegacyId } = policies;

  const validateLegacyId = async (formContext) => {
    const { form, removeErrorOnAttribute, addErrorOnAttribute } = formContext;
    try {
      await schoolServices.validateLegacyId(
        form.legacy_id,
        'responsible_profiles'
      );
      removeErrorOnAttribute('legacy_id');
    } catch {
      addErrorOnAttribute(
        'legacy_id',
        'Identificador de registro já está em uso'
      );
    }
  };

  const handleSetRecoveryResponsible = useCallback(() => {
    dispatch(setRecoveryResponsibleProfile(id));
  }, [id, dispatch, setRecoveryResponsibleProfile]);

  const onSubmit = async (formContext) => {
    const { form, hasErrorOnAttribute } = formContext;

    if (form.legacy_id) await validateLegacyId(formContext);

    if (!canUseLegacyId) delete form.legacy_id;

    const hasErrorOnLegacyId = hasErrorOnAttribute('legacy_id');

    const isFormValid = !hasErrorOnLegacyId;

    isFormValid && dispatch(recoverResponsibleProfile(id, form));
  };

  useEffect(() => {
    handleSetRecoveryResponsible();
  }, [handleSetRecoveryResponsible]);

  return (
    <S.PageContainer className="ResponsibleProfilesRecover">
      <Loader isLoading={isLoading}>
        <FormFullScreen
          action="recover"
          titlePage="Recuperar responsável"
          titleModal="Descartar preenchimento"
          contentModal={contentModalText}
          toggleModalWithFormUpdates
          onSubmit={onSubmit}
          form={form}
          formMeta={formMeta}
          isSubmitting={isSubmitting}
          steps={[ResponsibleProfileInformationsTab]}
          backTo={`/${dataArea}/responsible_profiles`}
          backToExternal
        />
      </Loader>
      <Toast />
    </S.PageContainer>
  );
};

ResponsibleProfilesRecover.propTypes = {
  ...appPropTypes,
  match: PropTypes.object.isRequired,
};

export default withAppContext(ResponsibleProfilesRecover);
