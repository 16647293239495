import React from 'react';

import FilterContainer, {
  DoubleInputsWrapper,
  HalfInputWrapper,
} from 'components/FilterContainer';

import Field from 'components/Form/Field';
import Search from 'components/Form/Search';
import ButtonAndSideFilter from 'components/Payments/ButtonAndSideFilter';

import './style.scss';

import { SchoolProductsFiltersProps } from './types';

const SchoolProductsFilters = ({
  form,
  formMeta,
  handleFilterSubmit,
}: SchoolProductsFiltersProps) => {
  const { status: formStatus } = form;
  const {
    select_options: {
      status: metaStatus,
      payments_search_type: paymentsSearchType,
    },
    amount,
  } = formMeta;
  return (
    <FilterContainer
      form={form}
      formMeta={formMeta}
      testid="schoolProductFilterWrapper"
    >
      <DoubleInputsWrapper>
        <Field
          testid="status"
          label="Situação"
          attributeName="status"
          type="selectWithIcon"
          value={formStatus}
          options={metaStatus}
          onChange={(e) => handleFilterSubmit({ status: e.value, page: 1 })}
          fullWidth
          withArrowSelect
        />
        <HalfInputWrapper>
          <Search
            testid="titleOrNameSearch"
            withSelect
            label="Buscar"
            placeholder="Digite o nome do aluno"
            attributeName="payment_title_or_student_name"
            selectOptions={paymentsSearchType}
            onSubmit={(params) => {
              handleFilterSubmit({
                payments_search_type: params.selectedOption,
                payment_title_or_student_name:
                  params.payment_title_or_student_name,
                page: 1,
              });
            }}
          />
          <ButtonAndSideFilter
            handleFilter={(params) => {
              handleFilterSubmit({
                headquarter_id: params.headquarter_id,
                educational_stage_id: params.educational_stage_id,
                classroom_id: params.classroom_id,
                plan_kind: params.plan_kind,
                source: params.source,
                page: 1,
              });
            }}
            amount={amount}
          />
        </HalfInputWrapper>
      </DoubleInputsWrapper>
    </FilterContainer>
  );
};

export default SchoolProductsFilters;
