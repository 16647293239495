import React from 'react';
import PropTypes from 'prop-types';

import { Section, Item } from './styles';

const CardSection = ({ keyExtractor, data, renderItem }) => {
  return (
    <Section>
      {data.map((item) => {
        return (
          <Item
            key={keyExtractor(item)}
            hasElement={[item.hasDeadline, item.hasActivityDelivery]}
          >
            {renderItem(item)}
          </Item>
        );
      })}
    </Section>
  );
};

CardSection.propTypes = {
  keyExtractor: PropTypes.func,
  data: PropTypes.array,
  renderItem: PropTypes.element,
};

export default CardSection;
