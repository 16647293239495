import styled, { css } from 'styled-components';
import Field from 'components/Form/Field';
import { ImageUploader } from '@agendaedu/ae-web-components';
import { ThemeProps } from 'config/themes/types';

export const Paragraph = styled.p`
  ${({ theme: { typography } }: ThemeProps) => css`
    ${typography.body.Regular12}
  `}
`;

export const ImageWrapper = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    margin-bottom: ${space.lg};
  `}
`;

export const ImageUploaderWrapper = styled(ImageUploader)`
  margin-top: 12px;
`;

export const ItemsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  ${({ theme: { space } }: ThemeProps) => css`
    gap: ${space.xl};
  `}
`;

export const ItemsField = styled(Field)`
  width: 100%;
  padding: 0;
`;

export const StockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const StockSwitchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  ${({ theme: { space } }: ThemeProps) => css`
    gap: ${space.sm};
    margin: ${space.lg} 0;
  `}
`;

export const StockField = styled(Field)`
  padding: 0;
  width: 100%;
`;
