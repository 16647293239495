import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import SurveyForm from 'pages/surveys/SurveyForm';
import Toast from 'components/Toast';
import Loader from 'components/Loader';

import {
  formatSurveyForm,
  convertClassroomIdsToInt,
  extractQuestions,
} from 'pages/surveys/extractDataForForm';

import SurveyActions from 'store/surveys/actions';
import { SurveyRoot } from 'store/surveys/types';
import { Props } from './types';

const SurveyEdit = ({ surveyId, history }: Props) => {
  const { t } = useTranslation('surveys');

  const dispatch = useDispatch();

  const { fetchSurveyForm, updateSurvey, setCurrentForm } = SurveyActions;

  const current = useSelector((state: SurveyRoot) => state.surveys.current);
  const isSubmitting = useSelector(
    (state: SurveyRoot) => state.surveys.sending
  );
  const formMeta = useSelector((state: SurveyRoot) => state.surveys.formMeta);
  const isLoading = useSelector((state: SurveyRoot) => state.surveys.isLoading);
  const { dataArea } = useSelector(
    (state: { root: { dataArea: string } }) => state.root
  );

  const setSurvey = useCallback(() => {
    dispatch(fetchSurveyForm(surveyId));
  }, [dispatch, fetchSurveyForm]);

  const callUpdateSurvey = useCallback((params) => {
    dispatch(updateSurvey(surveyId, params, history));
  }, []);

  useEffect(() => {
    setSurvey();
  }, []);

  useEffect(() => {
    getForm(current);
  }, [current]);

  const getForm = (current) => {
    if (current.attributes) {
      const form = formatSurveyForm(current);

      dispatch(setCurrentForm(form));
    }
  };

  const onSubmit = ({ form }) => {
    callUpdateSurvey({
      survey: {
        title: form.title,
        kind: form.kind,
        description: form.description,
        start_datetime: moment(`${form.date_start} ${form.time_start}`).format(
          'YYYY-MM-DD HH:mm'
        ),
        limit_datetime: moment(`${form.date_end} ${form.time_end}`).format(
          'YYYY-MM-DD HH:mm'
        ),
        sent_kind: form.sent_kind,
        persona_type: form.persona_type,
        survey_questions_attributes: extractQuestions(
          form.survey_questions_attributes
        ),
        classroom_ids: convertClassroomIdsToInt(form.classroom_ids),
        classrooms_with_student_profile_ids:
          form.classroom_with_student_profile_ids,
      },
    });
  };

  const kindFormInfo = {
    poll: {
      titlePage: t('forms.update.pool.title_page'),
      titleModal: t('forms.update.pool.title_modal'),
      contentModal: t('forms.update.pool.content_modal'),
    },
    authorization: {
      titlePage: t('forms.update.authorization.title_page'),
      titleModal: t('forms.update.authorization.title_modal'),
      contentModal: t('forms.update.authorization.content_modal'),
    },
  }[current.kind];

  return (
    <div className="SurveyEdit">
      <Loader isLoading={isLoading}>
        <SurveyForm
          form={current}
          formMeta={formMeta}
          onSubmit={onSubmit}
          action="edit"
          backTo={`/${dataArea}/surveys`}
          kind={current.kind}
          isSubmitting={isSubmitting}
          {...kindFormInfo}
        />
        <Toast />
      </Loader>
    </div>
  );
};

export default withRouter(SurveyEdit);
