import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

import withAppContext from 'core/hoc/withAppContext';

import ReportForm from '../ReportForm';
import Toast from 'components/Toast';
import * as S from './styles';

import ReportActions from 'store/reports/actions';

const ReportsNew = ({ history }) => {
  const dispatch = useDispatch();

  const { addReport, fetchReports, fetchAllReportsTitles } = ReportActions;
  const {
    reportsPagination: { totalItemsCount },
    current,
    reports,
    formMeta,
  } = useSelector((state) => state.reports);

  const reportsRequest = useCallback(
    (queryParams) => {
      dispatch(fetchReports(queryParams, history));
    },
    [dispatch, fetchReports, history]
  );

  const reportsAllRequest = useCallback(() => {
    dispatch(
      fetchAllReportsTitles({
        page: 1,
        per: totalItemsCount,
      })
    );
  }, [dispatch, fetchAllReportsTitles, totalItemsCount]);

  useEffect(() => {
    if (!reports.length) reportsRequest();
  }, [reportsRequest, reports]);

  useEffect(() => {
    if (totalItemsCount) reportsAllRequest();
  }, [reportsAllRequest, totalItemsCount]);

  const addChartInArray = (charts) => {
    const addedChart = [];
    charts.map((chart) => {
      let newChart = {
        title: chart.title,
        metabase_question_id: chart.metabase_question_id,
      };
      addedChart.push(newChart);
    });
    return addedChart;
  };

  const createReport = useCallback(
    (params) => {
      dispatch(addReport(params, history));
    },
    [addReport, dispatch, history]
  );

  const onSubmit = ({ form }) => {
    createReport({
      title: form.title,
      description: form.description,
      premium: form.premium,
      standard: false,
      charts_attributes: addChartInArray(form.chart_attributes),
    });
  };
  return (
    <S.FormContainer>
      <ReportForm
        action="new"
        form={current}
        formMeta={formMeta}
        onSubmit={onSubmit}
        backTo="/employees/reports"
        titlePage="Novo Relatório"
        titleModal="Descartar preenchimento"
        contentModal="A criação de relatório será cancelada e as informações preenchidas serão descartadas. Tem certeza que deseja descartar?"
      />
      <Toast />
    </S.FormContainer>
  );
};

export default withRouter(withAppContext(ReportsNew));
