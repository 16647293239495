import styled, { css } from 'styled-components';

export const FiltersContent = styled.div`
  ${({ theme: { space, breakpoints } }) => css`
    margin-top: ${space.xl2};
    display: flex;
    justify-content: space-between;

    .Field {
      padding: ${space.xs4};
      width: 15%;

      @media screen and (${`max-width: ${breakpoints.mobileSM}`}) {
        width: 100%;
      }
    }
  `}
`;
