import styled, { css } from 'styled-components';

export const ContentWrapper = styled.div`
  * {
    max-width: 100%;
  }
`;

export const Video = styled.iframe`
  ${({
    theme: {
      border: { radius },
    },
  }) => css`
    width: 100%;
    height: 100%;
    -o-object-fit: scale-down;
    object-position: center;
    -o-object-position: center;
    border-radius: ${radius.md};
  `}
`;
