import React, { useEffect } from 'react';

import withFormContext, { formPropTypes } from 'core/hoc/withFormContext';
import tabifyForWizard from 'core/hoc/tabifyForWizard';
import { titleMobile } from 'core/utils/changeText';

import FormFieldset from 'components/Form/Fieldset';
import Field from 'components/Form/Field';
import { Text } from '@agendaedu/ae-web-components';

import validateTextAreaReady from 'core/lib/FormValidator/validators/textAreaReady';

import './style.scss';
import validations from './validations';

const SurveyFormInformationsTab = ({
  formContext: {
    form: { title, description, time_end, date_end, kind },
    changeMeta,
  },
}) => {
  const nextStepDisabled = [
    title,
    date_end,
    time_end,
    description,
    validateTextAreaReady(description),
  ].some((value) => !value);

  const placeholder = {
    poll: 'ex: Pesquisa de Satisfação',
    authorization: 'ex: Passeio ao Zoológico',
  }[kind];

  useEffect(() => {
    changeMeta('nextStepDisabled', nextStepDisabled);
  }, [changeMeta, nextStepDisabled]);

  return (
    <div className="SurveyFormInformationsTab">
      <FormFieldset>
        <div className="row">
          <Field
            label="Título*"
            placeholder={placeholder}
            attributeName="title"
          />
        </div>

        <div className="row">
          <Field
            label="Descrição*"
            attributeName="description"
            type="textArea"
            useImage
          />
        </div>

        <div className="row double-input time-inputs">
          <Field
            label="Data limite para respostas*"
            placeholder="Selecionar data"
            attributeName="date_end"
            type="date"
          />

          <Field
            label="Hora limite para respostas*"
            attributeName="time_end"
            type="time"
            placeholder="Selecionar hora"
          />
        </div>

        <Text variant="body-regular-12" color="neutral.gray1">
          *Campos obrigatórios
        </Text>
      </FormFieldset>
    </div>
  );
};

SurveyFormInformationsTab.propTypes = {
  ...formPropTypes,
};

export default tabifyForWizard({
  title: titleMobile('1 Informações'),
  subTitle: 'Informações gerais',
  validations,
})(withFormContext(SurveyFormInformationsTab));
