import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import * as S from './styles';

import { Button, SingleDate } from '@agendaedu/ae-web-components';

import TimeField from 'components/Form/TimeField';
import { Modal } from 'components/Handouts/Modal';

import { HandoutState } from 'store/handouts/types';
import { ScheduleModalProps } from './types';

export const ScheduleModal = ({
  handleSubmit,
  handleToggleModal,
}: ScheduleModalProps) => {
  const [dateSchedule, setDateSchedule] = useState(null);
  const [timeSchedule, setTimeSchedule] = useState(null);
  const { t } = useTranslation('handouts');

  const isDisabled = !dateSchedule || !timeSchedule;

  const { showScheduleModal } = useSelector(
    (state: HandoutState) => state.handouts
  );

  const handleOnSubmit = () => {
    handleSubmit({ dateSchedule, timeSchedule });
    setDateSchedule(null);
    setTimeSchedule(null);
  };

  const handleOnToggleModal = () => {
    handleToggleModal();
    setDateSchedule(null);
    setTimeSchedule(null);
  };

  return (
    <Modal.Root
      dataTestId="schedule-modal"
      isOpen={showScheduleModal}
      handleToggleModal={handleOnToggleModal}
      title={t('handout_details.modals.schedule.title')}
    >
      <Modal.Content>
        {t('handout_details.modals.schedule.description')}
        <S.FormWrapper form={{}} formMeta={{}}>
          <S.FormContent>
            <S.InputsWrapper>
              <S.Label htmlFor="dateSchedule">
                {t('handout_details.modals.schedule.inputs.data.label')}
              </S.Label>
              <SingleDate
                id="sindgle-date"
                placeholder={t(
                  'handout_details.modals.schedule.inputs.data.placeholder'
                )}
                value={dateSchedule ? moment(dateSchedule) : ''}
                handleChange={(value) =>
                  setDateSchedule(value ? value.format('YYYY-MM-DD') : null)
                }
              />
            </S.InputsWrapper>
            <S.InputsWrapper>
              <S.Label htmlFor="timeSchedule">
                {t('handout_details.modals.schedule.inputs.hour.label')}
              </S.Label>
              <TimeField
                attributeName="timeSchedule"
                type="time"
                placeholder={t(
                  'handout_details.modals.schedule.inputs.hour.placeholder'
                )}
                defaultValue={timeSchedule}
                onValueChange={(value) => setTimeSchedule(value ? value : null)}
              />
            </S.InputsWrapper>
          </S.FormContent>
        </S.FormWrapper>
      </Modal.Content>
      <Modal.Actions>
        <Button variant="secondary" onClick={handleOnToggleModal}>
          {t('handout_details.modals.schedule.close_button_text')}
        </Button>
        <Button onClick={handleOnSubmit} disabled={isDisabled}>
          {t('handout_details.modals.schedule.submit_button_text')}
        </Button>
      </Modal.Actions>
    </Modal.Root>
  );
};
