import {
  Chat,
  ChatTicket,
  SenderMessage,
} from 'store/messages/omniChannel/types';
import { ChatIncluded, MessageIncluded, RequesterIncluded } from './types';
import { formatDate } from '../formatDate';
import i18n from 'config/i18n';

const getLastMessage = (message: MessageIncluded) => {
  if (message.attributes.document && !message.attributes.text)
    return i18n.t('messages:omni_channel.chat.last_message_attachment_text');

  return message.attributes.text;
};

export const normalizeTicketParams = (
  tickets: ChatTicket[],
  included: (RequesterIncluded | ChatIncluded | MessageIncluded)[]
): Chat[] => {
  return tickets?.map((ticket) => {
    const chat = included?.find(
      (included) =>
        included.id === ticket.relationships.chat?.data.id &&
        included.type === 'chat'
    ) as ChatIncluded;

    const requester = included?.find(
      (included) =>
        ticket.relationships.requester?.data.id === included.id &&
        (included.type === 'studentProfile' ||
          included.type === 'responsibleProfile')
    ) as RequesterIncluded;

    const message = included?.find(
      (included) => chat.relationships.lastMessage?.data.id === included.id
    ) as MessageIncluded;

    const sender = included?.find(
      (included) => included.id === message.relationships.sender?.data.id
    ) as SenderMessage;

    return {
      id: chat.id,
      type: chat.type,
      attributes: {
        classrooms_names: chat?.attributes.classroomsNames,
        read_status: chat?.attributes.answered ? 'answered' : 'unanswered',
        last_message_preview: getLastMessage(message),
        last_message_sent_at: formatDate(message.attributes.sentAt),
        main_user_role: requester?.attributes.role,
        kind: 'ticket',
        name: requester?.attributes.name,
        student_profile_id: null,
        role_message: chat?.attributes.roleMessage,
      },
      relationships: {
        main_user: {
          data: {
            id: requester.id,
            type:
              requester?.type === 'responsibleProfile'
                ? 'responsible_profile'
                : 'student_profile',
          },
        },
        ticket,
        sender,
      },
      included: {
        id: requester?.id,
        type:
          requester?.type === 'responsibleProfile'
            ? 'responsible_profile'
            : 'student_profile',
        attributes: {
          name: requester?.attributes.name,
          role: requester?.attributes.role,
          name_initials: requester?.attributes.nameInitials,
          avatar_color: requester?.attributes.avatarColor,
          avatar_url: requester?.attributes.avatarUrl,
        },
      },
    };
  });
};

export const normalizeTicketDetail = (
  ticket: ChatTicket,
  included: (RequesterIncluded | ChatIncluded | MessageIncluded)[]
): ChatTicket => {
  const traces = ticket.relationships.traces.data.map((trace) =>
    included.find((i) => i.id === trace.id && i.type === trace.type)
  );

  const currentTicket = traces.find(
    (trace) => trace.attributes.channelId === ticket.attributes.currentChannelId
  );

  const currentAttendant = included.find(
    (i) =>
      i.id === ticket.relationships.currentAttendant.data?.id &&
      i.type === ticket.relationships.currentAttendant.data?.type
  );

  const sourceChannel = included.find(
    (i) =>
      i.id === ticket.relationships.sourceChannel.data?.id &&
      i.type === ticket.relationships.sourceChannel.data?.type
  );

  return {
    id: ticket.id,
    type: ticket.type,
    attributes: {
      ...ticket.attributes,
      deletedAt: currentTicket.attributes.deletedAt,
      createdAt: currentTicket.attributes.createdAt,
      currentAttendant: currentAttendant?.attributes.name || '-',
      sourceChannel: sourceChannel?.attributes.name,
      supportEndedAt: !!currentTicket.attributes.supportEndedAt
        ? formatDate(currentTicket.attributes.supportEndedAt)
        : '-',
      supportStartedAt: !!currentTicket.attributes.supportStartedAt
        ? formatDate(currentTicket.attributes.supportStartedAt)
        : '-',
      supportTransferredAt: !!currentTicket.attributes.supportTransferredAt
        ? formatDate(currentTicket.attributes.supportTransferredAt)
        : '-',
      updatedAt: currentTicket.attributes.updatedAt,
    },
  };
};
