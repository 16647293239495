import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icon } from '@agendaedu/ae-web-components';

import * as S from './styles';

import { IconUploadModal } from 'components/IconUploadModal';

import { Props } from './types';

export const GroupIconInput: React.FC<Props> = ({
  label,
  image,
  onChange,
  isImageChangeDisabled = false,
  ...props
}) => {
  const [showModal, setShowModal] = useState(false);

  const { t } = useTranslation(['messages']);

  const toggleShowModal = useCallback(() => {
    setShowModal(!showModal);
  }, [showModal]);

  const handleRemoveImage = useCallback(() => {
    onChange(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderSelectedImage = (): React.ReactNode => (
    <S.ImageWrapper>
      <S.Image src={image?.url} data-testId="current-image" />
      {!isImageChangeDisabled && (
        <>
          <S.Overlay />
          <S.RemoveImageButtonWrapper>
            <Button
              onClick={handleRemoveImage}
              isOnlyIcon
              icon={'trash-bin'}
              variant="secondary"
            />
          </S.RemoveImageButtonWrapper>
        </>
      )}
    </S.ImageWrapper>
  );

  const renderEmptyImage = (): React.ReactNode => (
    <S.EmptyProfileWrapper data-testId="empty-profile-image">
      <Icon name="user-profile" />
    </S.EmptyProfileWrapper>
  );
  return (
    <S.Wrapper {...props}>
      <S.InputLabel data-testId="input-label" variant="label-regular-14">
        {label || t('groups.group_icon_input_label')}
      </S.InputLabel>

      {image?.url ? renderSelectedImage() : renderEmptyImage()}

      {!isImageChangeDisabled && (
        <S.InputButton data-testId="input-button" onClick={toggleShowModal}>
          <Icon name="camera" />
        </S.InputButton>
      )}

      <IconUploadModal
        isOpen={showModal}
        closeModal={toggleShowModal}
        onChange={onChange}
      />
    </S.Wrapper>
  );
};
