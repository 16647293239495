import React from 'react';
import PropTypes from 'prop-types';

import DropdownButton from 'components/DropdownButton';
import Avatar from 'components/Avatar';
import * as S from './styles';

const Card = ({
  title,
  subtitle,
  link,
  body,
  actions,
  avatar,
  avatarSize,
  customStyle,
  disableActions,
  ...attr
}) => (
  <S.WrapperLink>
    <S.CardContainer className="card-container" {...attr}>
      <S.CardHead className="card-head">
        <div className="head-avatar">
          {avatar && (
            <Avatar user={avatar} size={avatarSize} customStyle={customStyle} />
          )}
        </div>
        <div className="head-info">
          <a href={link}>{title}</a>
          <span>{subtitle}</span>
        </div>
      </S.CardHead>

      <S.CardBody>{body}</S.CardBody>
    </S.CardContainer>
    {actions && (
      <DropdownButton
        text=""
        disabled={disableActions}
        dropdownItems={actions}
        icon="dots-vertical"
        variation="secondary"
      />
    )}
  </S.WrapperLink>
);

Card.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  link: PropTypes.string,
  body: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    })
  ),
  avatar: PropTypes.shape({
    attributes: PropTypes.shape({
      avatar_color: PropTypes.string.isRequired,
      avatar_url: PropTypes.string,
      name_initials: PropTypes.string.isRequired,
    }),
  }),
  avatarSize: PropTypes.string,
  containerLink: PropTypes.string,
  disableActions: PropTypes.bool,
};

Card.defaultProps = {
  avatarSize: 'large',
  disableActions: false,
};

export default Card;
