import React from 'react';
import { useSelector } from 'react-redux';

import Accordion from 'components/Accordion';

import * as S from './styles';

const EditInBatchSummary = () => {
  const { selectedAmount, recurrentInBatchInformations } = useSelector(
    (state) => state.recurrentPlans
  );

  const renderHeaderInformation = () => {
    return (
      <S.HeaderWrapper data-testid="headerWrapper">
        <S.HeaderText>Total de cobranças</S.HeaderText>
        <S.HeaderText>{selectedAmount}</S.HeaderText>
      </S.HeaderWrapper>
    );
  };

  const renderBodyInformation = () => {
    return (
      <S.BodyWrapper data-testid="bodyWrapper">
        <S.HeaderWrapper>
          <S.HeaderText>Alunos</S.HeaderText>
          <S.HeaderText>Parcelas</S.HeaderText>
        </S.HeaderWrapper>
        <S.InfoDetailsWrapper>
          {recurrentInBatchInformations?.map((recurrent) => (
            <S.LineDetailsWrapper key={recurrent.id}>
              <S.HeaderWrapper>
                <S.LabelText>
                  {recurrent.attributes.student_profile_name}
                </S.LabelText>
                <S.LabelText>
                  {recurrent.attributes.bill_description}
                </S.LabelText>
              </S.HeaderWrapper>
              <hr></hr>
            </S.LineDetailsWrapper>
          ))}
        </S.InfoDetailsWrapper>
      </S.BodyWrapper>
    );
  };

  return (
    <S.EditInBatchAccordionWrapper>
      <Accordion
        header={renderHeaderInformation()}
        body={renderBodyInformation()}
        allowZeroExpanded
      />
    </S.EditInBatchAccordionWrapper>
  );
};

export default EditInBatchSummary;
