import React, { useEffect, useState } from 'react';

import tabifyForWizard from 'core/hoc/tabifyForWizard';
import FormFieldset from 'components/Form/Fieldset';
import Title from 'components/WizardForm/Title';
import Field from 'components/Form/Field';
import FormStudentsSelector from 'components/Form/StudentsSelector';
import GroupSelector from 'components/Form/GroupSelector';

import withFormContext from 'core/hoc/withFormContext';
import { useTranslation } from 'react-i18next';

import './style.scss';

import validations from './validations';

const RADIOS = [
  {
    labelText: 'Escola',
    value: 'schools',
    icon: 'notification',
  },
  {
    labelText: 'Turmas',
    value: 'classrooms',
    icon: 'user-group',
  },
  {
    labelText: 'Alunos',
    value: 'students',
    icon: 'user',
  },
];

const FormRecipientsTab = ({ formContext: { form, formMeta, changeMeta } }) => {
  const { created_for, persona_type, classroom_ids } = form;
  const { classrooms } = formMeta;

  const { t } = useTranslation(['quickaccess']);

  useEffect(() => {
    changeMeta('classroom_with_student_profile_ids_ClassroomStudents', []);
  }, [form.persona_type]);

  return (
    <div className="FormRecipientsTab">
      <FormFieldset>
        <div className="row">
          <Title name="Selecionar destinatários" />
          <div className="stepInfo">
            <p>
              {t('informations_tab.step_info.title')}
              <br />
              {t('informations_tab.step_info.subtitle')}
            </p>
          </div>
        </div>

        <div className="RadioGroup">
          <Field type="radio" attributeName="created_for" radios={RADIOS} />
        </div>

        <div className="row" key={created_for}>
          {created_for === 'students' && (
            <FormStudentsSelector
              key={created_for}
              attributeName="classroom_with_student_profile_ids"
              selectPlaceholder="Selecione uma turma"
              studentsWithResponsibles={persona_type === 'responsibles'}
            />
          )}

          {created_for === 'classrooms' && (
            <GroupSelector
              key={created_for}
              options={classrooms}
              attributeName="classroom_ids"
            />
          )}
        </div>
      </FormFieldset>
    </div>
  );
};

export default tabifyForWizard({
  title: '2. Destinatários',
  validations,
})(withFormContext(FormRecipientsTab));
