import { Icon, Text } from '@agendaedu/ae-web-components';
import { ThemeProps } from 'config/themes/types';
import styled, { css } from 'styled-components';
import { WrapperStyleProps } from './types';

export const Wrapper = styled.div`
  ${({
    height,
    theme: { colors, border, space, breakpoints },
  }: WrapperStyleProps) => css`
    display: flex;
    height: ${height - 220}px;
    flex-direction: column;

    @media only screen and (max-width: ${breakpoints.desktopLG}) {
      height: ${height - 277}px;
    }

    overflow-y: auto;

    &::-webkit-scrollbar {
      background-color: transparent;
      width: ${space.xs2};
    }

    &::-webkit-scrollbar-button {
      display: none;
    }

    &::-webkit-scrollbar-track {
      background-color: ${colors.neutral.gray5};
    }

    &::-webkit-scrollbar-thumb {
      border-radius: ${border.radius.sm};
      border: ${border.width.sm} ${border.style.solid} ${colors.neutral.gray4};
    }
  `}
`;

export const MessageWrapper = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    width: 220px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: ${space.md};
    text-align: center;
    margin-bottom: 80px;
  `}
`;

export const EmptyStateWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const IconMessage = styled(Icon)`
  height: 48px !important;
  width: 48px !important;
`;

export const EmptyStateLabel = styled(Text)`
  ${({ theme: { font } }: ThemeProps) => css`
    line-height: ${font.height.lg} !important;
  `}
`;
