import { centsToBRL } from 'core/utils/centsToBrl';
import React from 'react';
import * as S from './styles';
import { NegotiationCustomTableProps } from './types';

const NegotiationCustomTable = ({
  columns,
  data,
}: NegotiationCustomTableProps) => {
  return (
    <S.Table>
      <thead>
        <tr>
          {columns.map(({ key, header }) => (
            <S.TableHead key={key}>{header ? header : key}</S.TableHead>
          ))}
        </tr>
      </thead>
      <S.TBody>
        {data.map((row, rowIndex) => (
          <tr key={rowIndex}>
            {columns.map(({ key, format }) => (
              <S.TableData key={`${rowIndex}-${key}`}>
                {format === 'money'
                  ? centsToBRL(row.attributes[key])
                  : row.attributes[key]}
              </S.TableData>
            ))}
          </tr>
        ))}
      </S.TBody>
    </S.Table>
  );
};

export default NegotiationCustomTable;
