export const handoutsGrid = {
  cardTemplateColumns: {
    _: 'repeat(12, 1fr)',
  },

  filtersTemplateAreas: {
    mobileXS: '"dates dates" "title situation" "buttons buttons"',
    mobileSM: '"dates dates" "title situation" "buttons buttons"',
    tabletMD:
      '"title title situation situation dates dates" "buttons buttons buttons buttons buttons buttons"',
    desktopLG:
      '"title title title situation situation situation dates dates dates buttons buttons buttons buttons"',
    desktopXL:
      '"title title title situation situation situation dates dates dates buttons buttons buttons buttons"',
  },

  filtersTemplateColumns: {
    mobileXS: 'repeat(2, 1fr)',
    mobileSM: 'repeat(2, 1fr)',
    tabletMD: 'repeat(6, 1fr)',
    desktopLG: 'repeat(12, 1fr)',
    desktopXL: 'repeat(12, 1fr)',
  },

  columnsMargin: {
    desktopLG: 'sm',
    desktopXL: 'md',
  },

  titleColumn: {
    mobileXS: 'span 10',
    desktopLG: 'span 4',
  },

  subtleIconSpace: {
    top: { _: 'sm', desktopLG: '28px' },
    right: { _: 'xs2', tableMD: 'sm', desktopXL: 'md' },
  },
};

export const handoutsEngagementGrid = {
  cardGutter: { _: 'md' },
  gridTemplate: {
    gridTemplateColumns: {
      tabletMD: 'repeat(12, 1fr)',
      mobileXS: 'repeat(3, 1fr)',
    },
    gridTemplateRows: {
      tabletMD: '1fr',
      mobileXS: 'repeat(3, 1fr)',
    },
  },
  avatar: {
    gridColumn: { _: 'span 1' },
  },

  cardGridColumns: {
    responsibles: {
      avatar: {
        gridColumn: { _: 'span 1' },
      },
      responsible: {
        gridColumn: { mobileXS: 'span 3', tabletMD: 'span 4' },
      },
      student: {
        gridColumn: { mobileXS: '2 / span 2', tabletMD: 'span 3' },
      },
      classrooms: {
        gridColumn: {
          mobileXS: '1 / span 1',
          desktopLG: 'span 2',
        },
        gridRow: {
          mobileXS: '2 / span 1',
          desktopLG: 'span 1',
        },
      },
      viewed: {
        gridColumn: {
          mobileXS: '2 / span 1',
          tabletMD: 'span 1',
          _: 'span 1',
        },
      },
      status: {
        gridColumn: { mobileXS: '1 / span 1', desktopLG: 'span 2' },
        gridRow: { mobileXS: '3 / span 1', desktopLG: 'span 1' },
      },
    },

    students: {
      student: {
        gridColumn: { mobileXS: 'span 4', tabletMD: 'span 7' },
      },
      classrooms: {
        gridColumn: {
          mobileXS: '1 / span 1',
          desktopLG: 'span 2',
        },
        gridRow: {
          mobileXS: '2 / span 1',
          desktopLG: 'span 1',
        },
      },
      viewed: {
        gridColumn: {
          _: 'span 1',
          mobileXS: '2 / span 1',
          tabletMD: 'span 2',
          desktopLG: 'span 1',
        },
      },
      status: {
        gridColumn: { mobileXS: '1 / span 1', desktopLG: 'span 2' },
        gridRow: { mobileXS: '3 / span 1', desktopLG: 'span 1' },
      },
    },
  },

  selectsTemplateColumns: {
    gridTemplateColumns: {
      _: 'repeat(4, 1fr)',
      desktopLG: 'repeat(12, 1fr)',
    },
  },

  selectGridColumn: {
    gridColumn: {
      _: 'span 4',
      tabletMD: 'span 4',
      desktopLG: 'span 3',
    },
  },
};

export const RECIPIENTS = [
  { label: 'Responsáveis', value: 'responsibles' },
  { label: 'Alunos', value: 'students' },
];

export const VIEWS = [
  { label: 'Todas as confirmações', value: null },
  { label: 'Confirmado', value: 'confirmed' },
  { label: 'Pendente', value: 'pending' },
];
