export const convertToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    const { name, size, type } = file;
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve({
        name,
        size,
        type,
        base64: fileReader.result,
      });
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

export const convertUrlToBase64 = async (url) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();

      const fileReader = new FileReader();
      const { size, type } = blob;

      fileReader.readAsDataURL(blob);

      fileReader.onload = () => {
        resolve({
          name: url.split('/').pop(),
          size,
          type,
          base64: fileReader.result,
        });
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    } catch (error) {
      reject(error);
    }
  });
};
