import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const RecurrentClickShowModal = ({ actions, children }) => (
  <div className="click-show-modal" onClick={() => actions.show()}>
    {children}
  </div>
);

RecurrentClickShowModal.propTypes = {
  actions: PropTypes.shape({
    show: PropTypes.func
  }).isRequired,
  children: PropTypes.node.isRequired,
};

export default RecurrentClickShowModal;
