import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import VerificationInput from 'react-verification-input';
import {
  Box,
  Text,
  Button,
  DefaultThemeProps,
} from '@agendaedu/ae-web-components';
import twoAuthFactorActions from 'store/twoAuthFactor/actions';
import { useTheme } from 'styled-components';

import * as S from './styles';
import CountDown from '../CountDown';
import { Props } from './types';

const ValidationModal: React.FC<Props> = ({
  closeModal,
  isModalOpen,
  onSubmit,
  email,
}) => {
  const dispatch = useDispatch();

  const { t } = useTranslation(['twoAuthFactor']);

  const { sendCodeEmail } = twoAuthFactorActions;

  const {
    currentUser: { id: userId },
  } = useSelector((state) => state.root);

  const [codeOPT, setCodeOPT] = useState('');
  const [isResendCode, setIsResendCode] = useState({ status: false, time: 5 });
  const { colors } = useTheme() as DefaultThemeProps;

  const MODAL_CENTERED = {
    top: '50%',
    left: '50%',
    right: '50%',
    padding: '24px',
    transform: 'translate(-50%, -50%)',
  };

  useEffect(() => {
    !!isModalOpen && dispatch(sendCodeEmail());
  }, [userId, isModalOpen, dispatch, sendCodeEmail]);

  return (
    <S.ModalWrapper
      isOpen={isModalOpen}
      toggleModal={closeModal}
      maxWidth="496px"
      minHeight="338px"
      contentStyles={MODAL_CENTERED}
      title={t('validation_modal.title')}
    >
      <Box display="flex" flexDirection="column" minHeight="338px">
        <Text
          margin="24px 0 0"
          as="p"
          variant="body-regular-16"
          textAlign="start"
          lineHeight="lg"
        >
          {t('validation_modal.sub_title')}
        </Text>

        <Text
          margin="0px 0px 24px"
          as="p"
          variant="body-regular-16"
          textAlign="start"
          lineHeight="lg"
          fontWeight={700}
          bold
        >
          {email}
        </Text>

        <Box
          mb={8}
          width="100%"
          display="flex"
          flexDirection="row"
          alignContent="center"
          justifyContent="center"
        >
          <S.ConteinerVerificationInput>
            <VerificationInput
              classNames={{
                container: 'styled-container',
                character: 'styled-character',
                characterInactive: 'styled-character-inactive',
                characterSelected: 'styled-character--selected',
              }}
              placeholder=" "
              onComplete={(value: string) => setCodeOPT(value)}
            />
          </S.ConteinerVerificationInput>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          alignContent="space-between"
          justifyContent="space-between"
        >
          <CountDown
            timeMinutes={isResendCode.time}
            finishedCount={(): void =>
              setIsResendCode((prevState) => ({
                ...prevState,
                status: true,
              }))
            }
          />

          <S.ContainerResend
            onClick={() => {
              if (isResendCode.status) {
                dispatch(sendCodeEmail());
                setIsResendCode((prevState) => ({
                  ...prevState,
                  status: false,
                  time: prevState.time + 1,
                }));
              }
            }}
          >
            <Text
              as="p"
              variant="body-regular-16"
              textAlign="start"
              lineHeight="lg"
              color={`${
                isResendCode.status
                  ? colors.brand.primary
                  : colors.neutral.gray2
              }`}
            >
              {t('validation_modal.resend_button')}
            </Text>
          </S.ContainerResend>
        </Box>
        <Box mt="auto" display="flex" justifyContent="flex-end">
          <Box mr={16}>
            <Button variant="secondary" onClick={closeModal}>
              {t('validation_modal.cancel_button')}
            </Button>
          </Box>
          <Button
            onClick={() => onSubmit(codeOPT)}
            disabled={codeOPT.length < 4}
          >
            {t('validation_modal.activate_button')}
          </Button>
        </Box>
      </Box>
    </S.ModalWrapper>
  );
};

export default ValidationModal;
