import { all, call, put, takeLatest, select } from 'redux-saga/effects';
import {
  buildToast,
  toastCss,
  ToastTypes,
} from 'store/middlewares/toast/actions';
import { postApi, fetchApi, putApi } from 'core/utils/api';
import actions from './actions';

export function* addHeadquarter({ params, history }) {
  try {
    const dataArea = yield select((state) => state.root.dataArea);
    yield call(postApi, `/${dataArea}/headquarters`, {
      headquarter: params,
    });

    yield put({ type: actions.ADD_HEADQUARTER_SUCCESS });

    history.push({
      pathname: `/${dataArea}/headquarters`,
      successfullyCreated: true,
    });
  } catch (error) {
    yield put({
      type: actions.SET_ERROR,
      toast: buildToast(
        'Erro ao tentar cadastrar a unidade.',
        ToastTypes.error
      ),
      error,
    });
  }
}

export function* setHeadquarter({ id }) {
  try {
    const dataArea = yield select((state) => state.root.dataArea);
    const { data } = yield call(
      fetchApi,
      `/${dataArea}/headquarters/${id}/edit.json`
    );
    yield put({
      type: actions.SET_HEADQUARTER_SUCCESS,
      data,
    });
  } catch (error) {
    yield put({
      type: actions.SET_ERROR,
      toast: buildToast('Erro ao tentar recuperar unidade.', ToastTypes.error),
      error,
    });
  }
}

export function* editHeadquarter({ id, params, history }) {
  try {
    const dataArea = yield select((state) => state.root.dataArea);
    yield call(putApi, `/${dataArea}/headquarters/${id}`, params);

    yield put({ type: actions.EDIT_HEADQUARTER_SUCCESS });

    history.push({
      pathname: `/${dataArea}/headquarters`,
      successfullyEdited: true,
    });
  } catch (error) {
    yield put({
      type: actions.SET_ERROR,
      toast: buildToast(
        'Erro ao tentar atualizar a unidade.',
        ToastTypes.error
      ),
      error,
    });
  }
}

export function* fetchHeadquarters() {
  try {
    const dataArea = yield select((state) => state.root.dataArea);
    const { data } = yield call(fetchApi, `/${dataArea}/headquarters.json`);
    yield put({
      type: actions.FETCH_HEADQUARTERS_SUCCESS,
      data,
    });
  } catch (error) {
    yield put({
      type: actions.SET_ERROR,
      toast: buildToast('Não foi possível listar unidades.', ToastTypes.error),
      error,
    });
  }
}

export function* fetchHeadquartersHistory({ id, page }) {
  try {
    const dataArea = yield select((state) => state.root.dataArea);
    const { data, meta } = yield call(
      fetchApi,
      `/${dataArea}/headquarters/${id}/history.json?per_page=5&page=${page}`
    );
    yield put({
      type: actions.FETCH_HEADQUARTERS_HISTORY_SUCCESS,
      data,
      pagination: {
        itemsCountPerPage: 5,
        totalItemsCount: meta.count,
      },
    });
  } catch (error) {
    yield put({
      type: actions.SET_ERROR,
      toast: buildToast(
        'Não foi possível buscar o histórico da unidade.',
        ToastTypes.error
      ),
      error,
    });
  }
}

export function* fetchHeadquartersHistoryCount({ id }) {
  try {
    const dataArea = yield select((state) => state.root.dataArea);
    const { data } = yield call(
      fetchApi,
      `/${dataArea}/headquarters/${id}/history_count.json`
    );
    yield put({
      type: actions.FETCH_HEADQUARTERS_HISTORY_COUNT_SUCCESS,
      data,
    });
  } catch (error) {
    yield put({
      type: actions.SET_ERROR,
      error,
    });
  }
}

export function* buildToastMessage({ message, typeToast }) {
  const type = typeToast === 'success' ? ToastTypes.success : ToastTypes.error;

  yield put({
    type: actions.BUILD_TOAST_SUCCESS,
    toast: buildToast(message, type, toastCss(type)),
  });
}

function* headquartersSagas() {
  yield all([takeLatest(actions.ADD_HEADQUARTER, addHeadquarter)]);
  yield all([takeLatest(actions.SET_HEADQUARTER, setHeadquarter)]);
  yield all([takeLatest(actions.EDIT_HEADQUARTER, editHeadquarter)]);
  yield all([takeLatest(actions.FETCH_HEADQUARTERS, fetchHeadquarters)]);
  yield all([
    takeLatest(actions.FETCH_HEADQUARTERS_HISTORY, fetchHeadquartersHistory),
  ]);
  yield all([
    takeLatest(
      actions.FETCH_HEADQUARTERS_HISTORY_COUNT,
      fetchHeadquartersHistoryCount
    ),
  ]);
  yield all([takeLatest(actions.BUILD_TOAST, buildToastMessage)]);
}

export default headquartersSagas;
