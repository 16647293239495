import React from 'react';
import Accordion from 'components/Accordion';
import { trackEvent } from 'config/amplitude';

import * as S from './styles';
import { AccordionDetailsProps } from './types';

const AccordionDetails: React.FC<AccordionDetailsProps> = ({
  children,
  rolePermissonName,
  header,
  expanded,
  ...props
}) => {
  return (
    <S.Wrapper
      data-testid="accordion-permissions"
      onClick={() => {
        trackEvent(`role_permissions_accordion_name_click`, {
          rolePermissonName: rolePermissonName,
        });
      }}
    >
      <Accordion
        {...props}
        variation="permission"
        header={header}
        body={children}
        expanded={expanded}
      />
    </S.Wrapper>
  );
};

export default AccordionDetails;
