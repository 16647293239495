import React from 'react';
import PropTypes from 'prop-types';

import FormFullScreen from 'components/FormFullScreen';

import withAppContext from 'core/hoc/withAppContext';
import withFormContext from 'core/hoc/withFormContext';

import ReportFormInformationsTab from './InformationsTab';
import ChartsTab from './ChartsTab';

const WIZARD_STEPS = [ReportFormInformationsTab, ChartsTab];

const ReportForm = ({
  action,
  onSubmit,
  form,
  formMeta,
  isSubmitting,
  backTo,
  titlePage,
  titleModal,
  contentModal,
}) => {
  return (
    <FormFullScreen
      action={action}
      onSubmit={onSubmit}
      form={form}
      formMeta={formMeta}
      isSubmitting={isSubmitting}
      steps={WIZARD_STEPS}
      backTo={backTo}
      titlePage={titlePage}
      titleModal={titleModal}
      contentModal={contentModal}
    />
  );
};

ReportForm.propTypes = {
  action: PropTypes.oneOf(['new', 'edit']).isRequired,
  form: PropTypes.object.isRequired,
  formMeta: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  backTo: PropTypes.string,
  titlePage: PropTypes.string,
  titleModal: PropTypes.string,
  contentModal: PropTypes.string,
};

export default withAppContext(withFormContext(ReportForm));
