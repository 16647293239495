import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import withFormContext, { formPropTypes } from 'core/hoc/withFormContext';
import { defaultIsOutsideRange } from 'core/utils/date';

import AgendaIcon from 'components/AgendaIcon';
import Alert from 'components/Alert';
import CheckPaymentMethods from 'components/Form/CheckPaymentMethods';
import Field from 'components/Form/Field';
import Title from 'components/WizardForm/Title';
import Tooltip, { TOP_CENTER } from 'components/Tooltip';
import FormCheckbox from 'components/Form/Checkbox';

import './style.scss';

const RecurrencyConfiguration = ({
  type,
  action,
  isNewRecurrentBill = false,
  formContext: { form, formMeta, updateAttribute },
}) => {
  const { status, select_options: selectOptions } = formMeta;

  const {
    policies: {
      school_plan,
      can_config_recurrent_plan_payment_methods,
      edupay_provider,
    },
  } = useSelector((state) => state.root);

  const isPlan = type === 'plan';

  const {
    bills_quantity: billsQuantity,
    due_day: dueDay,
    bill_release_day: billReleaseDay,
    auto_generate_pix: autoGeneratePix,
    allowed_payment_method: allowedPaymentMethod,
  } = form;

  const disabledOnEdit = ['released', 'delayed'].includes(status);

  const tooltipsLabel = (name) => {
    const tooltipData = {
      legacy_id: {
        label: 'identificador',
        text: 'Identificador do sistema de gestão (legacy_id)',
      },
      bills_quantity: {
        label: 'Quantidade de cobranças*',
        text: () => (
          <>
            Quantidade de vezes em que a cobrança <br />
            será emitida para o responsável.
          </>
        ),
      },
      first_bill_due_date: {
        label: 'Vencimento da primeira cobrança*',
        text: () => (
          <>
            A data de vencimento da primeira cobrança <br />
            define o início do plano de recorrência.
          </>
        ),
      },
      bill_release_day: {
        label: 'Dia de envio das cobranças*',
        text: () => (
          <>
            Quantidade de dias para envio das cobranças <br />
            definido a partir da data de vencimento.
          </>
        ),
      },
      [isPlan ? 'due_day' : 'due_date']: {
        label: isPlan
          ? 'Vencimento das demais cobranças*'
          : 'Vencimento da cobrança*',
        text: () => (
          <>
            A data de vencimento das demais <br />
            cobranças define em que dia <br />
            de cada mês a cobrança irá vencer.
          </>
        ),
      },
    };

    return (
      <>
        <span>{tooltipData[name].label}</span>
        <Tooltip content={tooltipData[name].text} position={TOP_CENTER}>
          <AgendaIcon name="help" size="small" className="custom-size" />
        </Tooltip>
      </>
    );
  };

  const currentBillNumber = () => selectOptions.bills_quantity[1].value;

  useEffect(() => {
    if (!allowedPaymentMethod.includes('pix')) {
      return updateAttribute('auto_generate_pix', false);
    }
    return updateAttribute('auto_generate_pix', true);
  }, [allowedPaymentMethod, updateAttribute]);

  return (
    <div className="RecurrencyConfiguration">
      {isPlan && (
        <>
          <Title name="Configurar recorrência" />

          <CheckPaymentMethods
            withPix
            attributeName="allowed_payment_method"
            disabled={
              disabledOnEdit || !can_config_recurrent_plan_payment_methods
            }
            canConfigPaymentMethods={can_config_recurrent_plan_payment_methods}
            isNewLayout={edupay_provider === 'bemobi'}
            isRecurrency
          />

          {school_plan === 'trial' && (
            <Alert variation="info">
              <p>
                Durante o período de testes, pagamentos via cartão de crédito
                ficam desabilitados.
              </p>
              Caso queira habilitar o recebimento por esse método de pagamento,
              entre em contato com nosso time de atendimento:{' '}
              <a
                href="https://atendimento.agendaedu.com/hc/pt-br/articles/360022221754-Conhecendo-sua-carteira"
                target="_blank"
                rel="noreferrer"
              >
                atendimento@agendaedu.com
              </a>
            </Alert>
          )}

          <span>
            A frequência de envio da cobrança será <b>Mensal</b>
          </span>

          {isPlan && action === 'edit' && (
            <Alert variation="info">
              {`Essa recorrência já está na cobrança ${currentBillNumber()} de ${
                form.bills_quantity
              }`}
            </Alert>
          )}

          <div className="doubleInputWrapper">
            <div className="row double-input">
              <Field
                fullWidth
                withArrowSelect
                type="selectWithIcon"
                label={tooltipsLabel('bills_quantity')}
                attributeName="bills_quantity"
                options={selectOptions.bills_quantity}
                value={billsQuantity}
                onChange={(e) => updateAttribute('bills_quantity', e.value)}
              />
              <Field
                label={tooltipsLabel('first_bill_due_date')}
                type="date"
                attributeName="first_bill_due_date"
                placeholder="Selecionar"
                disabled={action === 'edit'}
                isOutsideRange={defaultIsOutsideRange}
              />
            </div>
          </div>
        </>
      )}

      <div className="row double-input">
        {isPlan ? (
          <Field
            fullWidth
            withArrowSelect
            type="selectWithIcon"
            label={tooltipsLabel('due_day')}
            attributeName="due_day"
            options={selectOptions.due_day}
            value={dueDay}
            onChange={(e) => updateAttribute('due_day', e.value)}
          />
        ) : (
          <div className="editPaymentMethod">
            <Field
              label={
                isNewRecurrentBill
                  ? 'Vencimento da cobrança*'
                  : tooltipsLabel('due_date')
              }
              type="date"
              attributeName="due_date"
              placeholder="Selecionar dia do vencimento"
              isOutsideRange={defaultIsOutsideRange}
            />

            <>
              <Title
                name={`${
                  isNewRecurrentBill
                    ? 'Método de pagamento'
                    : 'Editar método de pagamento'
                }`}
              />

              <CheckPaymentMethods
                withPix
                attributeName="allowed_payment_method"
                disabled={
                  disabledOnEdit || !can_config_recurrent_plan_payment_methods
                }
                canConfigPaymentMethods={
                  can_config_recurrent_plan_payment_methods
                }
                isNewLayout={edupay_provider === 'bemobi'}
              />
            </>
          </div>
        )}

        {isPlan && (
          <Field
            fullWidth
            withArrowSelect
            type="selectWithIcon"
            label={tooltipsLabel('bill_release_day')}
            attributeName="bill_release_day"
            options={selectOptions.bill_release_day}
            value={billReleaseDay}
            onChange={(e) => updateAttribute('bill_release_day', e.value)}
          />
        )}
      </div>
    </div>
  );
};

RecurrencyConfiguration.propTypes = {
  type: PropTypes.string.isRequired,
  action: PropTypes.string,
  ...formPropTypes,
};

RecurrencyConfiguration.defaultProps = {
  action: 'new',
};

export default withFormContext(RecurrencyConfiguration);
