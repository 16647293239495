import { Box, CardList, Text } from '@agendaedu/ae-web-components';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router';

import { StorageRoot } from 'store/storage/types';
import StorageActions from 'store/storage/actions';

import StorageEmptyState from '../../emptyState';
import StorageSkeleton from '../../skeleton';
import StorageCard from 'pages/storage/StorageCard';

import { Props } from './types';

const List = ({ folderAscendingId, history }: Props): React.ReactElement => {
  const { t } = useTranslation(['storage']);
  const dispatch = useDispatch();

  const isRootFolder = !folderAscendingId;

  const { fetchStoragesRequest } = StorageActions;

  const dataArea = useSelector(
    (state: { root: { dataArea: string } }) => state.root.dataArea
  );

  const {
    storageList: {
      storages,
      isLoading,
      pagination: {
        hasMorePage,
        page,
        isLoading: isLoadingPagination,
        totalCount,
      },
    },
  } = useSelector((state: StorageRoot) => state.storage);

  const handleFetchMoreStorages = useCallback(() => {
    hasMorePage && dispatch(fetchStoragesRequest(page + 1, folderAscendingId));
  }, [dispatch, fetchStoragesRequest, folderAscendingId, hasMorePage, page]);

  const handleOnPressCard = useCallback(
    (storageId, storageKind) => {
      const action = {
        folder: () =>
          history.push(`/${dataArea}/artifacts/folders/${storageId}`),
        document: () => history.push(`/${dataArea}/artifacts/${storageId}`),
      };

      action[storageKind]();
    },

    [dataArea, history]
  );

  return (
    <Box
      display="flex"
      mt="xs2"
      mb="lg"
      flexDirection="column"
      data-testid="list-wrapper"
    >
      {!!totalCount && !isLoading && (
        <Text
          data-testid="total-count"
          textAlign="end"
          variant="subtitle-medium-14"
          color="neutral.gray2"
        >
          {isRootFolder
            ? t('storage_list.total_count_folder', { count: totalCount })
            : t('storage_list.total_count_item', { count: totalCount })}
        </Text>
      )}

      {isLoading ? (
        <Box display="flex" flexDirection="column" mt="lg">
          <StorageSkeleton items={5} />
        </Box>
      ) : (
        <>
          {!storages?.length ? (
            <StorageEmptyState isRootFolder={isRootFolder} />
          ) : (
            <CardList
              loadMore={!isLoading && handleFetchMoreStorages}
              hasMore={hasMorePage || isLoadingPagination}
              loader={<StorageSkeleton items={1} />}
              initialLoad={true}
              threshold={200}
              pageStart={1}
              marginBottom="sm"
            >
              {storages?.map((storage) => (
                <StorageCard
                  key={storage.id}
                  dataTestId={`card_${storage.id}`}
                  isRootFolder={isRootFolder}
                  storage={storage}
                  handleOnClick={(e) => {
                    e.target.id !== 'details' &&
                      handleOnPressCard(storage.id, storage.attributes.kind);
                  }}
                  history={history}
                />
              ))}
            </CardList>
          )}
        </>
      )}
    </Box>
  );
};

export default withRouter(List);
