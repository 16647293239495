import React, { useContext, useEffect, useReducer, useState } from 'react';

import withAppContext, { appPropTypes } from 'core/hoc/withAppContext';

import Toast from 'components/Toast';
import { Box, Button, Text, Tooltip } from '@agendaedu/ae-web-components';
import { useTranslation } from 'react-i18next';
import Breadcrumb from 'components/Breadcrumb';
import DetailsTab from './components/DetailsTabs';
import AlbumActions from 'store/albums/actions';
import { useDispatch, useSelector } from 'react-redux';
import {
  AlbumMedia,
  AlbumUserPermissionIncluded,
  RootState,
} from 'store/albums/types';
import ApprovePendingsAlbumModal from './components/ApprovePendingsAlbumModal';
import List from './components/List';
import PhotoDetailsModal from './components/PhotoDetailsModal';
import EditVisibilityModal from './components/EditVisibilityModal';
import KindUploadModal from './components/KindUploadModal';

import { AlbumsDetailsTabSkeleton } from './skeleton';
import { EditVisibilityModalState, PhotoDetailsModalState } from './types';
import AddMultipleMediaModal from './components/AddMultipleMediaModal';
import { MEDIA_LIMIT_PER_ALBUM } from 'core/constants/albums';
import { UploadFilesContext } from 'core/contexts/UploadFiles';
import { validFilesTypes } from 'core/constants/albums';

const AlbumDetails = ({ match, appContext }) => {
  const { id: albumId } = match?.params;
  const dispatch = useDispatch();
  const { openSelectLocalFiles } = useContext(UploadFilesContext);
  const dataArea = useSelector(
    (state: { root: { dataArea: string } }) => state.root.dataArea
  );

  const { fetchAlbumDetails, fetchAlbumDetailsMedias, downloadAlbum } =
    AlbumActions;

  const { t } = useTranslation('albums');

  const [showKindUploadModal, setShowKindUploadModal] = useState(false);

  const [activePhotoDetails, setActivePhotoDetails] =
    useState<PhotoDetailsModalState>({
      isOpen: false,
      index: null,
      isEditAction: false,
    });

  const [editVisibilityModal, setEditVisibilityModal] =
    useState<EditVisibilityModalState>({
      isOpen: false,
      photo: null,
      albumId: null,
    });

  const openPhotoDetailsModal = (index: number, isEditAction?: boolean) => {
    setActivePhotoDetails({ isOpen: true, index, isEditAction });
  };

  const closePhotoDetailsModal = () => {
    setActivePhotoDetails({ isOpen: false, index: null, isEditAction: false });
  };

  const onEditVisibilityModal = (photo: AlbumMedia) => {
    setEditVisibilityModal({
      isOpen: true,
      photo,
      albumId,
    });
  };

  const closeEditVisibilityModal = () => {
    setEditVisibilityModal({ isOpen: false, photo: null, albumId: null });
  };

  const [showApproveModal, toggleApproveModal] = useReducer(
    (prev) => !prev,
    false
  );

  const { albumDetails, albumDetailsIncluded, isLoading } = useSelector(
    (state: RootState) => state.albums
  );

  const userPermission = albumDetailsIncluded.find(
    (include) =>
      include.type === 'userPermission' &&
      include.id === albumDetails.relationships.userPermission.data.id
  ) as AlbumUserPermissionIncluded;

  const isDisabledAddPhotos =
    !userPermission?.attributes?.canAddPhotos ||
    albumDetails?.attributes?.deleting;

  const isDisabledApprove =
    !userPermission?.attributes?.canApprove ||
    albumDetails?.attributes?.approving ||
    albumDetails?.attributes?.deleting;

  const onDownloadAlbum = () => {
    dispatch(downloadAlbum(albumDetails.id));
  };

  const handleOpenKindModal = () => {
    if (appContext.isWebView) {
      return openSelectLocalFiles(validFilesTypes);
    }

    return setShowKindUploadModal(true);
  };

  const hideAddButtonTooltip =
    !userPermission?.attributes?.canAddPhotos &&
    albumDetails?.attributes?.totalNumberOfPhotos < MEDIA_LIMIT_PER_ALBUM;

  useEffect(() => {
    if (albumId) {
      dispatch(fetchAlbumDetails(albumId));
      dispatch(fetchAlbumDetailsMedias({ id: albumId }));
    }
  }, [albumId, dispatch, fetchAlbumDetails, fetchAlbumDetailsMedias]);

  useEffect(() => {
    closeEditVisibilityModal();
  }, [activePhotoDetails.index]);

  if (!albumDetails) return <></>;

  return (
    <Box py={{ desktopLG: 'lg' }} px={{ desktopLG: 'xl' }}>
      <Box
        display="flex"
        alignItems="center"
        flexWrap="wrap"
        justifyContent="space-between"
        mb="md"
      >
        <Box>
          <Breadcrumb title={t('list.title')} path={`/${dataArea}/albums`} />
          <Text
            variant="headline-h2-bold-24"
            fontWeight="bold"
            color="neutral.black"
            mt="xs2"
          >
            {t('album_details.title')}
          </Text>
        </Box>

        <Box display="flex">
          {hideAddButtonTooltip ? (
            <Button
              id="add-media-button"
              size="md"
              mr="sm"
              disabled={isDisabledAddPhotos}
              onClick={() => handleOpenKindModal()}
            >
              {t('album_details.add')}
            </Button>
          ) : (
            <Tooltip
              content={t(
                userPermission.attributes.canAddPhotos
                  ? 'album_details.add_info'
                  : 'album_details.add_info_limited'
              )}
              align="left"
              elementRef={
                <Button
                  id="add-media-button"
                  size="md"
                  mr="sm"
                  disabled={isDisabledAddPhotos}
                  onClick={() => handleOpenKindModal()}
                >
                  {t('album_details.add')}
                </Button>
              }
            />
          )}
          <Button
            id="approve-album-button"
            disabled={isDisabledApprove}
            size="md"
            mr="sm"
            onClick={toggleApproveModal}
          >
            {t('album_details.approve')}
          </Button>
          <Tooltip
            content={t('album_details.download_info')}
            align="left"
            elementRef={
              <Button
                id="download-album-button"
                icon="income"
                variant="secondary"
                size="md"
                isOnlyIcon
                disabled={!userPermission?.attributes?.canDownload}
                onClick={onDownloadAlbum}
              />
            }
          />
        </Box>
      </Box>

      {isLoading ? (
        <AlbumsDetailsTabSkeleton />
      ) : (
        <DetailsTab
          album={albumDetails}
          albumIncluded={albumDetailsIncluded}
          canUpdate={!!userPermission?.attributes?.canUpdate}
          canDestroy={!!userPermission?.attributes?.canDestroy}
        />
      )}

      <List
        album={albumDetails}
        setActivePhotoDetails={openPhotoDetailsModal}
        onEditVisibility={onEditVisibilityModal}
      />

      <ApprovePendingsAlbumModal
        isOpen={showApproveModal}
        album={albumDetails}
        onClose={toggleApproveModal}
      />

      <PhotoDetailsModal
        album={albumDetails}
        isOpen={activePhotoDetails.isOpen}
        activePhotoDetails={activePhotoDetails}
        setActivePhotoDetails={openPhotoDetailsModal}
        onClose={closePhotoDetailsModal}
        onEditVisibility={onEditVisibilityModal}
      />

      <EditVisibilityModal
        photo={editVisibilityModal.photo}
        isOpen={editVisibilityModal.isOpen}
        onClose={closeEditVisibilityModal}
      />

      <AddMultipleMediaModal />

      <KindUploadModal
        isOpen={showKindUploadModal}
        onClose={() => setShowKindUploadModal(false)}
      />

      <Toast />
    </Box>
  );
};

AlbumDetails.propTypes = {
  ...appPropTypes,
};

export default withAppContext(AlbumDetails);
