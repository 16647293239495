import React from 'react';
import PropTypes from 'prop-types';

import IntlCurrencyInput from 'react-intl-currency-input-fork';

import FormValidationErrors from 'components/Form/ValidationErrors';
import withFormContext, { formPropTypes } from 'core/hoc/withFormContext';
import withOnChangeValidations from 'core/hoc/withOnChangeValidations';
import composeFunctions from 'core/utils/composeFunctions';

const CONFIG = {
  locale: 'en-US',
  formats: {
    number: {
      USD: {
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
};

const maxValue = 100.0;

const PercentField = ({
  controlled,
  defaultValue,
  attributeName,
  onChange,
  disabled,
  formContext,
}) => {
  const { changeAttribute, hasErrorOnAttribute } = formContext;
  const hasError = hasErrorOnAttribute(attributeName);

  const onInputChange = React.useCallback(
    (_event, value, _maskedValue) => {
      const fakeEvent = {
        target: {
          value: value,
        },
      };

      if (onChange) {
        return changeAttribute(attributeName, onChange)(fakeEvent);
      } else {
        return changeAttribute(attributeName)(fakeEvent);
      }
    },
    [changeAttribute]
  );

  const getValue = () => {
    if (controlled) {
      return defaultValue;
    }

    return formContext.form[attributeName];
  };

  return (
    <div className="PercentField">
      <div className="input-group">
        <div className="input-group-addon">%</div>
        <IntlCurrencyInput
          type="tel"
          className="form-control"
          onChange={onInputChange}
          defaultValue={getValue()}
          config={CONFIG}
          disabled={disabled}
          max={maxValue}
        />
      </div>
      {hasError && <FormValidationErrors attributeName={attributeName} />}
    </div>
  );
};

PercentField.propTypes = {
  attributeName: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  ...formPropTypes,
  defaultValue: PropTypes.number,
};

PercentField.defaultProps = {
  controlled: false,
};

export default composeFunctions(
  withOnChangeValidations,
  withFormContext
)(PercentField);
