import React, { FC } from 'react';
import appContextProvider from 'core/hoc/appContextProvider';
import * as S from './styles';
import { Props } from './types';
import { trackEvent } from '../../../../config/amplitude';

const OfferState: FC<Props> = ({
  imageUrl,
  title,
  subtitle,
  topics,
  ...otherProps
}) => {
  const onOpenChat = () => {
    trackEvent('billing-trial-contact');
    zE('messenger', 'open');
  };

  return (
    <S.Container {...otherProps}>
      <S.LeftColumn>
        <S.Title>{title}</S.Title>
        <S.Subtitle>{subtitle}</S.Subtitle>

        {topics.map((topic, index) => (
          <S.Line key={index}>
            <S.Icon name={topic.icon} />
            <S.Text>{topic.description}</S.Text>
          </S.Line>
        ))}
        <S.Line>
          <S.ButtonEmpty variation="primary" onClick={onOpenChat}>
            Contratar agora
          </S.ButtonEmpty>
        </S.Line>
      </S.LeftColumn>
      <S.Column>
        <S.ImageWrapper>
          <S.Image src={imageUrl} alt="Imagem" />
        </S.ImageWrapper>
      </S.Column>
    </S.Container>
  );
};

export default appContextProvider(OfferState);
