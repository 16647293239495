import React, { useCallback, useMemo } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { useTranslation, Trans } from 'react-i18next';
import { EMPTY_STATES_URL } from 'core/constants/index';
import EmptyState from 'components/EmptyState';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store/albums/types';
import PhotoCard from '../PhotoCard';

import AlbumActions from 'store/albums/actions';

import { Props } from './types';
import { Box, FlexGrid, FlexGridItem } from '@agendaedu/ae-web-components';
import { MediasListSkeleton } from './skeleton';

const List = ({ album, setActivePhotoDetails, onEditVisibility }: Props) => {
  const { t } = useTranslation('albums');
  const dispatch = useDispatch();
  const { fetchAlbumDetailsMedias } = AlbumActions;

  const {
    isLoadingAlbumDetailsMedias,
    albumDetailsMedias,
    albumDetailsMediasPaginationLinks,
  } = useSelector((state: RootState) => state.albums);

  const fetchMoreAlbums = useCallback(() => {
    !isLoadingAlbumDetailsMedias &&
      albumDetailsMediasPaginationLinks?.next &&
      dispatch(
        fetchAlbumDetailsMedias({
          id: album.id,
          nextLink: albumDetailsMediasPaginationLinks?.next,
        })
      );
  }, [
    album.id,
    albumDetailsMediasPaginationLinks?.next,
    dispatch,
    fetchAlbumDetailsMedias,
    isLoadingAlbumDetailsMedias,
  ]);

  const renderList = useMemo(() => {
    if (!album?.attributes.totalNumberOfPhotos)
      return (
        <EmptyState
          message={<Trans>{t('album_details.list_empty_state')}</Trans>}
          imgUrl={EMPTY_STATES_URL.defaultCover}
        />
      );

    if (isLoadingAlbumDetailsMedias && !albumDetailsMedias.length)
      return <MediasListSkeleton />;

    return (
      <InfiniteScroll
        dataLength={albumDetailsMedias.length}
        next={fetchMoreAlbums}
        hasMore={!!albumDetailsMediasPaginationLinks?.next}
        loader={<MediasListSkeleton />}
        style={{ overflow: 'visible' }}
      >
        <Box display="flex" flexWrap="nowrap">
          <FlexGrid gutter="lg">
            {albumDetailsMedias.map((photo, index) => (
              <FlexGridItem
                key={photo.id}
                cols={{
                  mobileXS: 12,
                  tabletMD: 6,
                  desktopLG: 4,
                  desktopXL: 3,
                }}
                filled
              >
                <PhotoCard
                  id={`photo-card-${index}`}
                  data-testid="photo-card"
                  photo={photo}
                  onEditVisibility={onEditVisibility}
                  onShowPhotoDetails={(isEditAction) =>
                    setActivePhotoDetails(index, isEditAction)
                  }
                />
              </FlexGridItem>
            ))}
          </FlexGrid>
        </Box>
      </InfiniteScroll>
    );
  }, [
    album?.attributes.totalNumberOfPhotos,
    albumDetailsMedias,
    albumDetailsMediasPaginationLinks?.next,
    fetchMoreAlbums,
    isLoadingAlbumDetailsMedias,
    onEditVisibility,
    setActivePhotoDetails,
    t,
  ]);

  return <Box mt="xl2">{renderList}</Box>;
};

export default List;
