function validateAmount(form, formState) {
  return _str => {
    const { amount } = form;
    const { formMeta, getFormValidationResult } = formState;

    const { availableAmount } = formMeta;

    const amountValidationResult = getFormValidationResult('amount');

    return amount > availableAmount ? false : true;
  };
}

function validateAmountTax(form, formState) {
  return _str => {
    const BRADESCO_CODE = '237';
    const { formMeta } = formState;
    const tax = formMeta.bankCode === BRADESCO_CODE ? 0 : formMeta.taxTransfer;
    const { amount } = form;

    return amount > tax ? true : false;
  };
}

export default function(formState) {
  return [
    {
      attributeName: 'amount',
      message: 'Saldo insuficiente',
      validator: validateAmount(formState.form, formState)
    },
    {
      attributeName: 'amount',
      message: 'O valor deve ser maior que a taxa',
      validator: validateAmountTax(formState.form, formState)
    }
  ];
}
