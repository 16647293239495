import { all, call, put, takeLatest, select } from 'redux-saga/effects';
import {
  buildToast,
  ToastTypes,
  toastCss,
} from 'store/middlewares/toast/actions';
import { fetchApi, postApi } from 'core/utils/api';
import actions from './actions';

function* fetchImports({ importedInto }) {
  try {
    const dataArea = yield select((state) => state.root.dataArea);

    const { data } = yield call(
      fetchApi,
      `/${dataArea}/import_webs/${importedInto}.json`
    );

    yield put({
      type: actions.FETCH_IMPORTS_SUCCESS,
      data,
    });
  } catch (error) {
    yield put({
      type: actions.SET_ERROR,
      toast: buildToast(
        'Não foi possível listar as importações.',
        ToastTypes.error
      ),
      error,
    });
  }
}

function* validateFileValues({ importedInto, fileSignedId }) {
  try {
    const dataArea = yield select((state) => state.root.dataArea);

    const { data, errors } = yield call(
      postApi,
      `/${dataArea}/import_webs/validate_values.json?imported_into=${importedInto}&file=${fileSignedId}`
    );

    yield put({
      type: actions.VALIDATE_FILE_VALUES_SUCCESS,
      data,
      errors,
    });
  } catch (error) {
    yield put({
      type: actions.SET_ERROR,
      toast: buildToast(
        'Não foi possível encontrar inconsistências.',
        ToastTypes.error
      ),
      error,
    });
  }
}

function* validateFileRow({ importedInto, fileRow }) {
  try {
    const dataArea = yield select((state) => state.root.dataArea);

    const formattedFileRow = {
      row_number: fileRow.id,
      original_content: fileRow.attributes.original_content,
      updated_content: fileRow.attributes.updated_content,
    };

    const { data, errors } = yield call(
      postApi,
      `/${dataArea}/import_webs/validate_line.json?imported_into=${importedInto}`,
      {
        data: [formattedFileRow],
      }
    );

    yield put({
      type: actions.VALIDATE_FILE_ROW_SUCCESS,
      data,
      errors,
    });
  } catch (error) {
    yield put({
      type: actions.SET_ERROR,
      toast: buildToast('Não foi possível validar a linha.', ToastTypes.error),
      error,
    });
  }
}

function* fetchPaginatedFile({ importedInto, fileSignedId, page }) {
  try {
    const dataArea = yield select((state) => state.root.dataArea);

    const { data, number_of_rows } = yield call(
      postApi,
      `/${dataArea}/import_webs/read_file_by_pagination.json?imported_into=${importedInto}&file=${fileSignedId}&page=${page}&per_page=10`
    );

    yield put({
      type: actions.FETCH_PAGINATED_FILE_SUCCESS,
      data,
      number_of_rows,
      pagination: { currentPage: page },
    });
  } catch (error) {
    yield put({
      type: actions.SET_ERROR,
      toast: buildToast('Não foi possível ler o arquivo.', ToastTypes.error),
      error,
    });
  }
}

function* fetchFileSummary({ importedInto, fileSignedId }) {
  try {
    const dataArea = yield select((state) => state.root.dataArea);

    const { data } = yield call(
      fetchApi,
      `/${dataArea}/import_webs/${importedInto}/summary_import.json?file=${fileSignedId}`
    );

    yield put({
      type: actions.FETCH_FILE_SUMMARY_SUCCESS,
      data,
    });
  } catch (error) {
    yield put({
      type: actions.SET_ERROR,
      toast: buildToast(
        'Não foi possível buscar o resumo do arquivo.',
        ToastTypes.error
      ),
      error,
    });
  }
}

function* startImport({ importedInto, fileSignedId, correctedData, errors }) {
  try {
    const dataArea = yield select((state) => state.root.dataArea);

    yield call(
      postApi,
      `/${dataArea}/import_webs/${importedInto}/start_import.json?file=${fileSignedId}`,
      {
        corrected_data: { data: correctedData, errors: errors },
      }
    );

    window.location = `/${dataArea}/import_webs/${importedInto}`;

    yield put({
      type: actions.START_IMPORT_SUCCESS,
      toast: buildToast(
        'Importação iniciada com sucesso.',
        ToastTypes.success,
        toastCss(ToastTypes.success)
      ),
    });
  } catch (error) {
    yield put({
      type: actions.SET_ERROR,
      toast: buildToast(
        'Não foi possível iniciar a importação do arquivo',
        ToastTypes.error
      ),
      error,
    });
  }
}

function* fetchImportsInconsistencies({ importedInto, id, page }) {
  try {
    const dataArea = yield select((state) => state.root.dataArea);

    const { data, itemsCountPerPage, totalItemsCount } = yield call(
      fetchApi,
      `/${dataArea}/import_webs/${importedInto}/${id}/inconsistencies.json?page=${page}&per_page=20`
    );

    yield put({
      type: actions.FETCH_IMPORT_INCONSISTENCIES_SUCCESS,
      data,
      pagination: {
        itemsCountPerPage,
        totalItemsCount,
        currentPage: page,
      },
    });
  } catch (error) {
    yield put({
      type: actions.SET_ERROR,
      toast: buildToast(
        'Não foi possível listar as inconsistências.',
        ToastTypes.error
      ),
      error,
    });
  }
}

function* fetchImportSummary({ importedInto, id }) {
  try {
    const dataArea = yield select((state) => state.root.dataArea);

    const { data } = yield call(
      fetchApi,
      `/${dataArea}/import_webs/${importedInto}/${id}/summary.json`
    );

    yield put({
      type: actions.FETCH_IMPORT_SUMMARY_SUCCESS,
      data,
    });
  } catch (error) {
    yield put({
      type: actions.SET_ERROR,
      toast: buildToast(
        'Não foi possível buscar o resumo da importação.',
        ToastTypes.error
      ),
      error,
    });
  }
}

function* getImportProgress({ importedInto, id }) {
  try {
    const dataArea = yield select((state) => state.root.dataArea);

    const { data } = yield call(
      fetchApi,
      `/${dataArea}/import_webs/${id}/progress`
    );

    yield put({
      type: actions.GET_IMPORT_PROGRESS_SUCCESS,
      data,
    });

    if (
      data.attributes.progress_status === 'done' ||
      data.attributes.progress_status === 'error'
    ) {
      yield put({
        type: actions.FETCH_IMPORT_SUMMARY,
        importedInto,
        id,
      });
    }
  } catch (error) {
    yield put({
      type: actions.SET_ERROR,
      toast: buildToast(
        'Não foi possível buscar o status da importação.',
        ToastTypes.error
      ),
      error,
    });
  }
}

function* importWebSagas() {
  yield all([takeLatest(actions.FETCH_IMPORTS, fetchImports)]);
  yield all([takeLatest(actions.VALIDATE_FILE_VALUES, validateFileValues)]);
  yield all([takeLatest(actions.VALIDATE_FILE_ROW, validateFileRow)]);
  yield all([takeLatest(actions.FETCH_PAGINATED_FILE, fetchPaginatedFile)]);
  yield all([takeLatest(actions.FETCH_FILE_SUMMARY, fetchFileSummary)]);
  yield all([takeLatest(actions.START_IMPORT, startImport)]);
  yield all([
    takeLatest(
      actions.FETCH_IMPORT_INCONSISTENCIES,
      fetchImportsInconsistencies
    ),
  ]);
  yield all([takeLatest(actions.FETCH_IMPORT_SUMMARY, fetchImportSummary)]);
  yield all([takeLatest(actions.GET_IMPORT_PROGRESS, getImportProgress)]);
}

export default importWebSagas;
