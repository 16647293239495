export const dropdownDirection = ({
  options,
  optionsPerRow,
  row,
  rowCount,
}) => {
  const bottomRows = rowCount - (row + 1);
  const upperRows = row;

  const canOpenUpward = upperRows >= options / optionsPerRow;
  const canOpenDownward = bottomRows >= options / optionsPerRow;

  if (canOpenDownward) return 'down';
  if (canOpenUpward) return 'up';

  return 'down';
};
