import React, { useEffect } from 'react';
import withFormContext from 'core/hoc/withFormContext';
import FormFieldset from 'components/Form/Fieldset';
import * as S from './styles';
import Field from 'components/Form/Field';
import { FlexGrid, FlexGridItem, Tag } from '@agendaedu/ae-web-components';
import { useTranslation } from 'react-i18next';
import PizZip from 'pizzip';
import Docxtemplater from 'docxtemplater';
import { ContractModelFormInformationsTabProps } from './types';
import tabifyForWizard from 'core/hoc/tabifyForWizard';
import FileField from 'components/Form/FileField';

const ONE_MB_VALUE_IN_KB = 1024;
const LIMIT_SIZE_10_MB = 10 * Math.pow(ONE_MB_VALUE_IN_KB, 2);

const ContractModelFormInformationsTab = ({
  formContext: { updateAttribute, form, changeMeta, hasErrorOnAttribute },
}: ContractModelFormInformationsTabProps) => {
  const { t } = useTranslation(['enrollments']);

  const { variables, attachment } = form;

  const handleFileChange = async (file) => {
    const isFileDocx =
      file.type ===
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document';

    if (!file || !isFileDocx) {
      return;
    }

    if (file && file.size < LIMIT_SIZE_10_MB) {
      const arrayBuffer = await file.arrayBuffer();
      const zip = new PizZip();

      const loadedZip = zip.load(arrayBuffer);
      const doc = new Docxtemplater();
      doc.loadZip(loadedZip);

      const regex = /{{(.*?)}}/gi;
      const textNodes = doc.getFullText();
      const textMatches = textNodes.match(regex) || [];
      const lowerCase = textMatches.map((item) => item.toLowerCase());

      updateAttribute('variables', lowerCase);
    }
  };

  useEffect(() => {
    if (hasErrorOnAttribute('attachment'))
      return changeMeta('nextStepDisabled', true);

    if (attachment?.length) {
      handleFileChange(attachment[0]);
    } else {
      updateAttribute('variables', '');
    }
  }, [attachment]);

  return (
    <S.FormContainer>
      <FormFieldset>
        <S.Title>{t('form.contract_model.title')}</S.Title>
        <FlexGrid>
          <FlexGridItem cols={{ desktopLG: 8 }}>
            <S.Row>
              <Field
                label="Título*"
                placeholder="ex. Contrato de Matrícula"
                attributeName="title"
              />
            </S.Row>
            <S.Row>
              <S.InfoText textSize="sm">{`${t(
                'form.contract_model.pdf_size_limit'
              )}`}</S.InfoText>
              <S.FileUploadContainer
                disabled={attachment && attachment.length > 0}
              >
                <FileField
                  attributeName="attachment"
                  hideTooltip={true}
                  acceptTypes="application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  maxSize={LIMIT_SIZE_10_MB}
                  buttonText="Adicionar contrato"
                  multiple={false}
                />
              </S.FileUploadContainer>
            </S.Row>
            {variables?.length ? (
              <S.Row>
                <S.InfoText textSize="sm">
                  {t('form.contract_model.contract_variables')}
                </S.InfoText>
                <S.TagContainer>
                  {variables.map((variable) => (
                    <Tag
                      variant="neutral"
                      key={variable}
                      name={variable}
                      size={'large'}
                    />
                  ))}
                </S.TagContainer>
              </S.Row>
            ) : (
              !!attachment?.length && (
                <S.InfoText textSize="sm">
                  {t('form.contract_model.contract_variables_not_found')}
                </S.InfoText>
              )
            )}

            <S.InfoText textSize="xs">
              {t('form.contract_model.required_fields')}
            </S.InfoText>
          </FlexGridItem>
        </FlexGrid>
      </FormFieldset>
    </S.FormContainer>
  );
};

export default tabifyForWizard({
  title: '',
  subTitle: '',
  validations: [],
})(withFormContext(ContractModelFormInformationsTab));
