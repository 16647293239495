import React from 'react';

import './style.scss';

/**
 * Renders the user avatar. Their profile photo or their initials.
 * Note that we have to remove the first char from `avatar_color` because it's
 * a hashtag.
 */

export type AvatarProps = {
  user: {
    attributes: {
      avatar_color: string;
      avatar_url?: string;
      name_initials: string;
    };
  };
  size?: 'small' | 'medium' | 'large';
  customStyle?: {
    backgroundColor: string;
  };
};

function Avatar({ user, size = 'small', customStyle = null }: AvatarProps) {
  const { avatar_color, avatar_url, name_initials } = user.attributes;

  const styleColor = customStyle || { backgroundColor: avatar_color };

  const firstTwoLetters = name_initials.slice(0, 2);

  return (
    <div style={styleColor} className={`Avatar ${size}`} testid="avatarWrapper">
      {avatar_url ? <img src={avatar_url} /> : firstTwoLetters}
    </div>
  );
}

export default Avatar;
