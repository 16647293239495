import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class SenderName extends PureComponent {
  static propTypes = {
    user: PropTypes.object.isRequired,
  }

  render() {
    const { user } = this.props;

    return (
      <React.Fragment>
        {[user.attributes.name, user.attributes.role].filter(item => !!item).join(' • ')}
      </React.Fragment>
    );
  }
}

export default SenderName;
