import React from 'react';
import { Button } from '@agendaedu/ae-web-components';
import { useTranslation } from 'react-i18next';

import * as S from './styles';

import { RecipientChatCard } from '../RecipientChatCard';

import { RecipientsSelectedProps } from './types';

export const RecipientsSelected = ({
  recipientsList,
  onChange,
  removeAllRecipients,
  disabledActions,
}: RecipientsSelectedProps) => {
  const { t } = useTranslation(['messages']);

  return (
    <S.Wrapper>
      <S.DestinationHeaderWrapper>
        <S.CountTotalDestinationsText variant="title-bold-12">
          {recipientsList.length > 1 || recipientsList.length === 0
            ? recipientsList.length +
              t(
                'omni_channel.forms.message_chat.fields.recipients_count_plural'
              )
            : recipientsList.length +
              t('omni_channel.forms.message_chat.fields.recipients_count')}
        </S.CountTotalDestinationsText>

        {!disabledActions && (
          <Button variant="secondary" size="xs" onClick={removeAllRecipients}>
            {t('omni_channel.forms.message_chat.fields.remove_all')}
          </Button>
        )}
      </S.DestinationHeaderWrapper>
      <S.OptionsWrapper>
        {recipientsList?.map((recipient, index) => (
          <RecipientChatCard
            removeItemButton
            key={index}
            onChange={() => onChange([recipient], true)}
            recipient={recipient}
            isSelected
            disabledActions={disabledActions}
          />
        ))}
      </S.OptionsWrapper>
    </S.Wrapper>
  );
};
