import styled from 'styled-components';

const SkeletonAnimation = styled.div`
  display: inline-block;
  height: 100%;
  width: 100%;
  background: linear-gradient(-90deg, #f0f0f0 0%, #f8f8f8 50%, #f0f0f0 100%);
  background-size: 400% 400%;
  animation: pulse 1.2s ease-in-out infinite;
  @keyframes pulse {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: -135% 0%;
    }
  }
`;

export const SkeletonContainer = styled.div`
  display: flex;
  background-color: white;
  justify-content: space-between;
  width: 100%;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 1px 5px 0 #33333315;
  margin: 22px 0px 24px 0px;
  border: 1px solid #dddddd;

  @media screen and (max-width: 480px) {
    flex-direction: column;
  }
`;

export const SkeletonLeftContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SkeletonRightContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media screen and (max-width: 480px) {
    justify-content: center;
    margin-top: 20px;
  }
`;

export const SkeletonLabel = styled(SkeletonAnimation)`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  border-radius: 3px;
  margin-top: 7px;
`;

export const SkeletonButton = styled(SkeletonAnimation)`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  border-radius: 6px;
  margin-left: 16px;
`;

export const SkeletonTooltipIcon = styled(SkeletonAnimation)`
  width: 18px;
  height: 18px;
  border-radius: 18px;
  margin-left: 16px;
  margin-top: 7px;
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
