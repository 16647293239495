import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';

import composeFunctions from 'core/utils/composeFunctions';
import reduxProvider from 'core/hoc/reduxProvider';
import withAppContext from 'core/hoc/withAppContext';
import UploadFilesProvider from 'core/contexts/UploadFiles';
import StorageDocumentProvider from 'core/contexts/Storage/Document';
import StorageFileProvider from 'core/contexts/Storage/File';
import StorageFolderProvider from 'core/contexts/Storage/Folder';

import DocumentForm from 'pages/storage/Forms/Document';
import FileForm from 'pages/storage/Forms/File';
import FolderForm from 'pages/storage/Forms/Folder';
import StorageDetails from 'pages/storage/StorageDetails';
import StorageList from 'pages/storage/StorageList';

import { StorageRoutesProps } from './types';

const StorageRoutes = ({
  googleClientId,
  appContext: { dataArea },
}: StorageRoutesProps) => {
  useEffect(() => {
    const mainContent = document.getElementById('main-content');

    if (mainContent) {
      mainContent.style.minHeight = '100vh';
      mainContent.style.backgroundColor = '#ffffff';
    }
  }, []);

  return (
    <div id="StorageRoutes" style={{ marginBottom: -90 }}>
      <CookiesProvider>
        <Switch>
          <Route
            exact
            path={`/${dataArea}/artifacts`}
            component={StorageList}
          />
          <Route
            exact
            path={`/${dataArea}/artifacts/new`}
            component={() => (
              <StorageFolderProvider>
                <FolderForm />
              </StorageFolderProvider>
            )}
          />
          <Route
            exact
            path={`/${dataArea}/artifacts/folders/:id`}
            component={() => <StorageList />}
          />
          <Route
            exact
            path={`/${dataArea}/artifacts/:id`}
            component={() => <StorageDetails />}
          />
          <Route
            exact
            path={`/${dataArea}/artifacts/:id/folders/new`}
            component={({
              match: {
                params: { id },
              },
            }) => (
              <StorageFolderProvider folderAscendingId={id}>
                <FolderForm />
              </StorageFolderProvider>
            )}
          />
          <Route
            exact
            path={`/${dataArea}/artifacts/:id/folders/edit`}
            component={({
              match: {
                params: { id },
              },
            }) => (
              <StorageFolderProvider currentStorageId={id}>
                <FolderForm />
              </StorageFolderProvider>
            )}
          />
          <Route
            exact
            path={`/${dataArea}/artifacts/new`}
            component={() => (
              <StorageFolderProvider>
                <FolderForm />
              </StorageFolderProvider>
            )}
          />
          <Route
            exact
            path={`/${dataArea}/artifacts/:id/documents/new`}
            component={({
              match: {
                params: { id },
              },
            }) => (
              <UploadFilesProvider>
                <StorageDocumentProvider
                  folderAscendingId={id}
                  googleClientId={googleClientId}
                >
                  <DocumentForm />
                </StorageDocumentProvider>
              </UploadFilesProvider>
            )}
          />
          <Route
            exact
            path={`/${dataArea}/artifacts/:id/documents/edit`}
            component={({
              match: {
                params: { id },
              },
            }) => (
              <UploadFilesProvider>
                <StorageDocumentProvider
                  currentStorageId={id}
                  googleClientId={googleClientId}
                >
                  <DocumentForm />
                </StorageDocumentProvider>
              </UploadFilesProvider>
            )}
          />
          <Route
            exact
            path={`/${dataArea}/artifacts/:id/files/new`}
            component={({
              match: {
                params: { id },
              },
            }) => (
              <UploadFilesProvider shouldConcatFiles>
                <StorageFileProvider folderAscendingId={id}>
                  <FileForm />
                </StorageFileProvider>
              </UploadFilesProvider>
            )}
          />
          <Route
            exact
            path={`/${dataArea}/artifacts/:id/files/edit`}
            component={({
              match: {
                params: { id },
              },
            }) => (
              <UploadFilesProvider>
                <StorageFileProvider currentStorageId={id}>
                  <FileForm />
                </StorageFileProvider>
              </UploadFilesProvider>
            )}
          />
        </Switch>
      </CookiesProvider>
    </div>
  );
};

export default composeFunctions(withAppContext, reduxProvider)(StorageRoutes);
