import {
  DiaryFormStructure,
  DiaryWithOutKeys,
} from 'store/dailySummaries/types';

export const normalizeDiaryToSave = (
  diary: Omit<DiaryFormStructure['studentsForm'], 'notify'>
): DiaryWithOutKeys => {
  const {
    meals,
    baby_bottles,
    sleeps,
    occurrences,
    occurrences_to_destroy,
    evacuations,
    answers_attributes,
    ...rest
  } = diary;

  return {
    ...rest,
    meals: meals
      .map(({ category, description, not_ate }) => ({
        category,
        description,
        not_ate,
      }))
      .filter((meal) => Object.values(meal).some(Boolean)),
    baby_bottles: {
      not_drink: baby_bottles.not_drink,
      quantities: baby_bottles.not_drink
        ? []
        : baby_bottles.quantities.map(({ quantity }) => ({
            quantity,
          })),
    },
    occurrences: occurrences
      .map(({ id, category, description, files }) => ({
        id,
        category,
        description,
        signed_ids: files.map((file) => file.signedId),
      }))
      .filter(({ category }) => !!category),
    occurrences_to_destroy: [
      ...occurrences_to_destroy,
      ...occurrences
        .filter((occurrence) => !!occurrence.id && !occurrence.category)
        .map((occurrence) => occurrence.id),
    ],
    sleeps: {
      not_slept: sleeps.not_slept,
      periods:
        sleeps.not_slept ||
        sleeps.periods.every(
          ({ start_time, end_time }) => !start_time && !end_time
        )
          ? []
          : sleeps.periods.filter(
              ({ start_time, end_time }) => !!start_time || !!end_time
            ),
    },
    evacuations: {
      not_evacuated: evacuations.not_evacuated,
      appearances: evacuations.not_evacuated ? [] : evacuations.appearances,
    },
    answers_attributes: Object.entries(answers_attributes).reduce(
      (prevSections, [sectionId, sectionGroups]) => {
        const newSectionsGroups = sectionGroups.map((group) =>
          group
            .map((question) => {
              delete question.key;

              const hasAnswer = [
                question.diary_answer_option_id,
                question.answer_text,
              ].some(Boolean);

              if (hasAnswer) return question;
            })
            .filter(Boolean)
        );

        return {
          ...prevSections,
          [sectionId]: newSectionsGroups,
        };
      },
      {}
    ),
  };
};
