import React from 'react';

import withAppContext from 'core/hoc/withAppContext';
import './style.scss';

const SkeletonClassroomsPage = ({ children, isLoading }) => {
  if (children && !isLoading) {
    return children;
  }

  return (
    <div className="PageContainer Classrooms default">
      <div className="container-header">
        <div className="skeleton-conteiner-row">
          <p style={{ width: '10%' }} className="skeleton-item-box-line" />
          <p style={{ width: '80%' }}></p>
          <p style={{ width: '10%' }} className="skeleton-item-box-line" />
        </div>
        <div className="skeleton-conteiner-row">
          <p style={{ width: '20%' }} className="skeleton-item-box-line" />
          <p style={{ width: '20%' }} className="skeleton-item-box-line" />
          <p style={{ width: '20%' }} className="skeleton-item-box-line" />
          <p style={{ width: '44%' }}></p>
          <p style={{ width: '5%' }} className="skeleton-item-box-line" />
        </div>
      </div>

      <div className="container-content">
        <div className="skeleton-conteiner-row"></div>
        <div className="skeleton-line-table-classrooms"></div>
        <div className="skeleton-line-table-classrooms"></div>
        <div className="skeleton-line-table-classrooms"></div>
        <div className="skeleton-line-table-classrooms"></div>
        <div className="skeleton-line-table-classrooms"></div>
        <div className="skeleton-line-table-classrooms"></div>
      </div>
    </div>
  );
};

export default withAppContext(SkeletonClassroomsPage);
