import { HEADER_NAMES } from '../constants';

export function mapTableColumns(table, headers) {
  if (table && headers) {
    return table.filter((column) =>
      headers.includes(HEADER_NAMES[column.Header])
    );
  }

  return [];
}
