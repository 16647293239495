import styled, { css } from 'styled-components';

export const SentMessageWrapper = styled.div`
  width: 100%;
  margin-bottom: 20px;
  word-wrap: break-word;
  word-break: break-all;

  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .MessagesInfo {
    margin-top: 5px;
    font-size: 11px;
  }
`;

export const ActionsWrapper = styled.div<{
  isOpen: boolean;
  isHovered: boolean;
}>`
  ${({ theme, isHovered, isOpen }) => css`
    position: absolute;
    bottom: 0;
    right: calc(100% - 16px);
    display: ${!isHovered && !isOpen ? 'none' : 'flex'};
    flex-direction: row;
    gap: 4px;

    > div {
      color: ${theme.colors.brand.primary.default};
      background: ${theme.colors.neutral.gray5};
      cursor: pointer;
    }
  `}
`;

export const MessageBalloonWrapper = styled.div`
  position: relative;
  max-width: 60%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;
