import React from 'react';
import PropTypes from 'prop-types';
import {
  Accordion as AccessibleAccordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

import DropdownButton from 'components/DropdownButton';
import { ConteinerAccordion } from './styles';
import './variations/permission/style.scss';

const VARIATIONS = {
  default: '',
  permission: 'PermissionAccordion',
};

function Accordion(props) {
  const {
    header,
    body,
    expanded,
    variation,
    allowZeroExpanded,
    onChange,
    actions,
  } = props;

  return (
    <ConteinerAccordion variations={`${variation}`}>
      <div className={`Accordion ${VARIATIONS[variation]}`}>
        <AccessibleAccordion
          preExpanded={[expanded]}
          allowZeroExpanded={allowZeroExpanded}
          onChange={onChange}
        >
          <AccordionItem uuid={expanded}>
            <AccordionItemHeading>
              <AccordionItemButton>
                {header}
                {actions && <div style={{ width: '60px' }} />}
              </AccordionItemButton>
            </AccordionItemHeading>
            {actions && (
              <DropdownButton
                text=""
                dropdownItems={actions}
                icon="dots-vertical"
                variation="secondary"
              />
            )}
            <AccordionItemPanel>{body}</AccordionItemPanel>
          </AccordionItem>
        </AccessibleAccordion>
      </div>
    </ConteinerAccordion>
  );
}

Accordion.defaultProps = {
  variation: 'default',
  allowZeroExpanded: false,
  onChange: () => {},
};

Accordion.propTypes = {
  header: PropTypes.object.isRequired,
  expanded: PropTypes.string,
  body: PropTypes.oneOfType([PropTypes.func, PropTypes.element]).isRequired,
  allowZeroExpanded: PropTypes.bool,
  variation: PropTypes.oneOf([
    'default',
    'payment',
    'permission',
    'rightButton',
  ]),
  onChange: PropTypes.func,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    })
  ),
};

export default Accordion;
