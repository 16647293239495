import React from 'react';
import parse from 'html-react-parser';

import InfoCard from 'components/InfoCard';

import './style.scss';
import moment from 'moment';

const SurveyInfo = ({
  startDatetime,
  limitDatetime,
  personaType,
  description,
}) => {
  const formatSurveyDatetime = (
    datetime,
    format = 'DD [de] MMM[,] YYYY HH:mm'
  ) => moment(datetime, 'DD/MM/YYYY HH:mm').format(format);

  const PERSONA_TYPE = {
    both: 'Alunos e responsáveis',
    students: 'Alunos',
    responsibles: 'Responsáveis',
  };

  const SURVEY_INFOS = [
    {
      icon: 'calendar',
      label: 'Data de cadastro',
      value: formatSurveyDatetime(startDatetime),
    },
    {
      icon: 'calendar',
      label: 'Data limite para respostas',
      value: formatSurveyDatetime(limitDatetime),
    },
    { icon: 'send', label: 'Enviado para', value: PERSONA_TYPE[personaType] },
  ];

  return (
    <>
      <section className="survey-info-area">
        {SURVEY_INFOS.map((info, index) => (
          <InfoCard key={index} {...info} />
        ))}
      </section>
      <section className="description-area">
        <p className="survey-info-label">Descrição</p>
        <p className="survey-info-description">{parse(description)}</p>
      </section>
    </>
  );
};

export default SurveyInfo;
