import { ThemeProps } from 'config/themes/types';
import styled, { css } from 'styled-components';
import AgendaIcon from 'components/AgendaIcon';
import Button from 'components/Button';

export const Container = styled.div`
  ${({ theme: { colors, breakpoints } }: ThemeProps) => css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    max-width: 950px;
    background-color: ${colors.neutral.white};

    @media (max-width: ${breakpoints.tabletMD}) {
      flex-direction: column;
    }
  `}
`;

export const Column = styled.div`
  flex: 1;
  padding: 20px;
`;

export const LeftColumn = styled(Column)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 10px;
`;

export const Title = styled.h1`
  ${({ theme: { colors, typography } }: ThemeProps) => css`
    margin-top: inherit;
    font-size: 24px;
    ${typography.title.Bold20};

    color: ${colors.neutral.black};
  `}
`;

export const Subtitle = styled.h2`
  ${({ theme: { colors, typography } }: ThemeProps) => css`
    ${typography.body.Regular14};

    color: ${colors.neutral.gray1};
  `}
`;

export const Line = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0px 10px;
`;

export const Icon = styled(AgendaIcon)`
  ${({ theme: { colors } }: ThemeProps) => css`
    width: 19px;
    height: 19px;
    margin-right: 10px;
    color: ${colors.neutral.gray1};
  `}
`;

export const Text = styled.p`
  ${({ theme: { colors, typography } }: ThemeProps) => css`
    margin: 0;
    ${typography.title.Bold12};

    color: ${colors.neutral.gray1};
  `}
`;

export const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const Image = styled.img`
  width: 380px;
  height: 380px;
`;

export const ButtonEmpty = styled(Button)`
  ${({ theme: { border } }: ThemeProps) => css`
    width: 160px;
    height: 40px;
    top: 312px;
    border-radius: ${border.radius.md};
    padding: 9px 16px 9px 16px;
    gap: 20px;
  `}
`;
