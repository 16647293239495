import React from 'react';
import { InviteScreenProps } from './types';
import InviteBox from 'components/Invite/InviteBox';
import reduxProvider from 'core/hoc/reduxProvider';

const InviteScreen = ({ resource }: InviteScreenProps): JSX.Element => {
  return (
    <div id="InviteScreen" className="InviteMainScreen">
      <InviteBox resource={resource} />
    </div>
  );
};

export default reduxProvider(InviteScreen);
