import FormFieldset from 'components/Form/Fieldset';
import { ThemeProps } from 'config/themes/types';
import styled, { css } from 'styled-components';

export const NewGroupFormsWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .FormSelector {
      display: flex;
      flex-direction: column;
      width: 100%;

      @media (max-width: 768px) {
        flex-direction: column;

        .container-wrap {
          &:first-child {
            display: none;
          }
        }
      }

      .container-wrap {
        display: flex;
        flex-direction: row;
        width: 100%;
        gap: 72px;

        @media (max-width: 768px) {
          flex-direction: column;
          gap: 48px;
        }

        .options-column {
          margin-right: 0;
        }

        .item-wrap {
          width: 100%;

          .optionsCount {
            strong {
              font-family: 'Quicksand';
              font-style: normal;
              font-weight: 700;
              font-size: 12px;
              line-height: 100%;
              color: ${theme.black};
            }

            .OutlineBox {
              border-radius: 6px;
              padding: 0 10px;
              font-family: 'Quicksand';
              font-style: normal;
              font-weight: 700;
              font-size: 11px;
              line-height: 24px;
            }
          }

          .selectComponent {
            height: 325px;
            border-radius: 8px;

            .SelectableOption {
              div {
                display: flex;
                flex-direction: row;
                gap: 16px;

                .Avatar {
                  width: 24px;
                  height: 24px;
                }

                span {
                  padding-left: 0;
                  font-family: 'Roboto';
                  font-style: normal;
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 24px;
                }
              }
            }
          }
        }
      }
    }
  `}
`;

export const SkeletonWrapper = styled.main`
  .WizardForm .wizard-content .wizard-taps-header .PageTitle {
    min-width: fit-content;
  }
`;

export const InformationStepWrapper = styled(FormFieldset)``;

export const FormSkeletonWrapper = styled.div`
  ${({ theme: { breakpoints, colors, space } }: ThemeProps) => css`
    height: 100%;
    max-width: 100%;
    padding: ${space.xs4} 0;
    margin: ${space.xl3} 0;
    background-color: ${colors.neutral.white};

    @media only screen and (min-width: ${breakpoints.desktopLG}) {
      margin: ${space.xl3} 0;
    }

    @media only screen and (min-width: ${breakpoints.desktopXL}) {
      padding: ${space.xs4} 0;
    }
  `}
`;
