import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Pagination from 'components/Pagination';
import Accordion from 'components/Accordion';
import { NegotiationAccordionHeader } from './NegotiationAccordionHeader';
import { NegotiationAccordionPanel } from './NegotiationAccordionPanel';
import Button from 'components/Button';
import { useSelector, useDispatch } from 'react-redux';
import negotiationsActions from 'store/edupay/negotiations/actions';

import { NegotiationsListProps, RootState } from './types';
import * as S from './styles';
import Alert from 'components/Alert';

export const NegotiationsList = ({
  negotiations,
  page,
  handlePageChange,
  totalItemsCount,
  planId,
}: NegotiationsListProps) => {
  const { t } = useTranslation(['negotiations']);
  const dispatch = useDispatch();
  const [redirectToNewNegotiation, setRedirectToNewNegotiation] =
    useState(false);

  const {
    policies: { can_use_negotiation: canUseNegotiation },
  } = useSelector((state: RootState) => state.root);

  const { hasBatchSelection } = useSelector(
    (state: RootState) => state.negotiations
  );
  const { setCurrentPlanId, clearSelectedBills } = negotiationsActions;

  useEffect(() => {
    dispatch(clearSelectedBills());
  }, [dispatch, clearSelectedBills]);

  const currentPlan = useSelector(
    (state: RootState) => state.recurrentPlans.current
  );
  const canNegotiateBills =
    currentPlan.studentProfile.attributes.has_financial_responsible;

  const handleNegociarClick = () => {
    dispatch(setCurrentPlanId(planId));
    setRedirectToNewNegotiation(true);
  };

  if (redirectToNewNegotiation) {
    return <Redirect to="/schools/negotiations/new" />;
  }

  return (
    <>
      <S.ButtonContainer>
        {hasBatchSelection && canUseNegotiation && (
          <Button
            variation="secondary"
            disabled={!canNegotiateBills}
            onClick={handleNegociarClick}
          >
            {t('button.negotiate')}
          </Button>
        )}
      </S.ButtonContainer>
      {!canNegotiateBills && hasBatchSelection && (
        <S.AlertContainer>
          <Alert variation="danger">
            <p
              dangerouslySetInnerHTML={{
                __html: t('messages.financial_responsible'),
              }}
            />
          </Alert>
        </S.AlertContainer>
      )}
      <S.NegotiationsCount>
        <p>{`${totalItemsCount} ${t('messages.negotiation', {
          count: totalItemsCount,
        })}`}</p>
      </S.NegotiationsCount>
      <S.Container>
        {negotiations.map(
          ({
            id,
            attributes: {
              recurrent_bills,
              negotiated_at,
              bills_quantity,
              total_price_cents,
              status,
              percent_paid,
            },
          }) => {
            const { data: recurrentBills } = recurrent_bills;
            return (
              <S.AccordionContainer key={id}>
                <Accordion
                  header={
                    <NegotiationAccordionHeader
                      recurrentBills={recurrentBills.map(
                        ({
                          id,
                          attributes: {
                            is_wallet_guaranteed_monthly_fee,
                            status,
                          },
                        }) => ({
                          id,
                          status,
                          is_wallet_guaranteed_monthly_fee,
                        })
                      )}
                      {...{
                        negotiated_at,
                        bills_quantity,
                        total_price_cents,
                        status,
                        percent_paid,
                        negotiationId: id,
                      }}
                    />
                  }
                  body={
                    <>
                      {recurrentBills.map(
                        ({
                          id: recurrentBillId,
                          attributes: {
                            due_date,
                            price_cents,
                            status,
                            allowed_payment_method,
                            kind,
                            order_id,
                          },
                        }) => (
                          <NegotiationAccordionPanel
                            key={recurrentBillId}
                            kind={kind}
                            recurrentBillId={recurrentBillId}
                            dueDate={due_date}
                            priceCents={price_cents}
                            status={status}
                            allowedPaymentMethod={allowed_payment_method}
                            planId={planId}
                            order_id={order_id}
                          />
                        )
                      )}
                    </>
                  }
                  allowZeroExpanded
                />
              </S.AccordionContainer>
            );
          }
        )}

        <Pagination
          onChange={handlePageChange}
          activePage={page}
          totalItemsCount={totalItemsCount}
          itemsCountPerPage={6}
        />
      </S.Container>
    </>
  );
};
