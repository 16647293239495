import styled, { css } from 'styled-components';

export const RangeTimeContainer = styled.div`
  ${({ theme, disabled, hasError }) => css`
    width: fit-content;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;

    > * {
      border: 1px solid ${theme.gray3};
      height: 40px;
    }

    > .AgendaIcon {
      border-right: none;
      border-color: ${hasError ? theme.danger : 'inherit'};
      color: ${hasError ? theme.danger : theme.gray3};
      border-radius: 6px 0 0 6px;
      line-height: 40px;
      padding: 0 10px;
      background-color: ${disabled ? theme.gray5 : 'inherit'};
    }

    input {
      border-left: none;
      font-size: 16px;
      border-color: ${hasError ? theme.danger : theme.gray3};

      &:disabled {
        background-color: ${theme.gray5};
      }
    }

    > button {
      background-color: transparent;
      border-radius: 0 6px 6px 0;
      padding: 9px 10px;
      color: ${theme.danger};

      &:disabled {
        color: inherit;
      }
    }
  `}
`;
