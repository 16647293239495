import { Box, Button } from '@agendaedu/ae-web-components';
import React, { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { UploadFilesContext } from 'core/contexts/UploadFiles';
import { validFilesTypes } from 'core/constants/storage';
import { LIMIT_SIZE_40_MB } from 'core/contexts/UploadFiles/constants';

import { FileItem } from './FileItem';

import * as S from './styles';

import { FileUpload } from './types';

const driveImageUrl =
  'https://static.agendaedu.com/upload-images/google-drive.png';

const FileUploader: React.FC = (): React.ReactElement => {
  const { t } = useTranslation(['storage', 'common']);
  const {
    uploadFile,
    destroyFile,
    openSelectLocalFiles,
    selectedFiles,
    googleDriveWrapper,
  } = useContext(UploadFilesContext);

  const tBase = useCallback(
    (key: string, params?: Record<string, unknown>) =>
      t(`file_form.info_step.${key}`, params),
    [t]
  );

  const validateSelectedFiles: FileUpload[] = selectedFiles.reduce(
    (prev, file: FileUpload) => {
      const invalidType =
        !validFilesTypes.includes(file.type) &&
        t('file_uploader.file_errors.invalid_file');

      const invalidSize =
        file.size > LIMIT_SIZE_40_MB &&
        t('file_uploader.file_errors.invalid_size');

      const invalidReasons = [invalidType, invalidSize].filter(Boolean);

      return [...prev, Object.assign(file, { invalidReasons })];
    },
    []
  );

  return (
    <Box display="flex" flexDirection="column" data-testid="file-uploader">
      <Box display="flex" flexDirection="row" gap="lg" mb="lg">
        <Button
          data-testid="input-local-file"
          onClick={() => openSelectLocalFiles(validFilesTypes)}
          width="100%"
          variant="secondary"
        >
          {tBase('input_file_local')}
        </Button>

        {/* TODO: This component will be uncommented shortly after the permission issues are resolved.
        <S.DriveWrapper>
          {googleDriveWrapper(
            <S.FileUploderButton id="upload-google-drive-action">
              <S.ButtonImage src={driveImageUrl} />
              {tBase('input_file_gdrive')}
            </S.FileUploderButton>
          )}
        </S.DriveWrapper> */}
      </Box>

      <Box display="flex" flexDirection="column" gap="xs">
        {validateSelectedFiles.map((file) => (
          <FileItem
            key={file.fileId}
            file={file}
            uploadFile={uploadFile}
            destroyFile={destroyFile}
            isRemoveDisabled={file?.isForm}
          />
        ))}
      </Box>
    </Box>
  );
};

export default FileUploader;
