export const gridValuesWrapper = {
  gridTemplateAreas: {
    mobileXS: `"classroom"
               "date-label"
               "date-picker"
               "change-type"`,
    tabletMD: `"date-label date-picker"
               "classroom change-type"`,
  },
  gridTemplateColumns: {
    mobileXS: `1fr`,
    tabletMD: `1fr 1fr`,
  },
};
