/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  ChangeEvent,
  createRef,
  RefObject,
  useCallback,
  useState,
} from 'react';
import Cropper from 'react-easy-crop';
import { FileRejection, useDropzone } from 'react-dropzone';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Button, Icon, Modal } from '@agendaedu/ae-web-components';

import getCroppedImg from 'core/utils/CropImage';

import * as S from './styles';

import { CropArea, FileImage, Props } from './types';

export const IconUploadModal: React.FC<Props> = ({
  acceptedFormats = 'image/jpg, image/png',
  closeModal,
  dragAndDropImageLabel,
  isOpen,
  maxFileSize = 10485760,
  modalTitle,
  onChange,
  selectImageButtonLabel,
}) => {
  const { t } = useTranslation(['icon_uploader']);

  const fileInputRef: RefObject<HTMLInputElement> = createRef();

  const [crop, setCrop] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<CropArea | null>(
    null
  );
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [image, setImage] = useState<FileImage | undefined | null>();
  const [inCropper, setInCropper] = useState<boolean>(false);
  const [inDraging, setInDraging] = useState<boolean>(false);
  const [zoom, setZoom] = useState<number>(1);

  const fileSizeNormalize = (fileSize: number): number => {
    return fileSize / (1024 * 1024);
  };

  function getFileFormats(mimeTypes: string) {
    const mimeTypeArray = mimeTypes.split(',').map((type) => type.trim());

    const extensions = mimeTypeArray.map((mimeType) => {
      if (mimeType.includes('/')) {
        return `.${mimeType.split('/')[1].toUpperCase()}`;
      }
      return '';
    });

    return extensions.filter(Boolean).join(t('or_accepted_format'));
  }

  const imageInformationLabel = useCallback((): React.ReactNode => {
    const size = maxFileSize / (1024 * 1024);
    const formats = getFileFormats(acceptedFormats);

    return (
      <S.AcceptedFormatsLabel variant="label-regular-12">
        <Trans>
          {t('accepted_format_and_size_labels', {
            fizeSize: size,
            acceptedFormats: formats,
          })}
        </Trans>
      </S.AcceptedFormatsLabel>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptedFormats, maxFileSize, t]);

  const onDragEnter = useCallback((): void => {
    setInDraging(true);
  }, []);

  const handleChangeValue = (value: FileImage | null): void => {
    if (!onChange) return;

    onChange(value);
  };

  const onCropComplete = (
    _croppedArea: any,
    croppedAreaPixels: CropArea
  ): void => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const handleCancelCrop = (): void => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    setInCropper(false);
    setImage(null);
  };

  const handleCropImage = async (): Promise<void | any> => {
    const imageUrl = image?.url;

    if (imageUrl) {
      try {
        const newCroppedImage = await getCroppedImg(
          imageUrl,
          croppedAreaPixels as CropArea,
          image.name,
          image.type
        );

        setInCropper(false);
        setImage(newCroppedImage);
        handleChangeValue(newCroppedImage);
      } catch (e) {
        return e;
      }
    }

    setInCropper(false);
    setImage(null);
    closeModal();
  };

  const onDragLeave = useCallback((): void => {
    setInDraging(false);
  }, []);

  const fileIsMaxSize = useCallback((): void => {
    setErrorMessage(
      t('max_file_size', { fileSize: fileSizeNormalize(maxFileSize) })
    );
  }, [maxFileSize, t]);

  const handleImageChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const fileSelected = e.target.files?.[0] as FileImage;

    if (fileSelected) {
      if (fileSelected?.size > maxFileSize) return fileIsMaxSize();

      Object.assign(fileSelected, {
        url: URL.createObjectURL(fileSelected),
        uploaded: false,
      });
      setInCropper(true);
      setImage(fileSelected);
      setErrorMessage(null);
    }
  };

  const handleShowSelectImage = (): void => {
    if (fileInputRef?.current) {
      fileInputRef.current.click();
    }
  };

  const onDrop = useCallback(
    (acceptedFiles): void => {
      const file = acceptedFiles[0];

      if (file) {
        if (file?.size > maxFileSize) return fileIsMaxSize();

        Object.assign(file, {
          url: URL.createObjectURL(file),
          uploaded: false,
        });
        setImage(file);
        setInCropper(true);
        setErrorMessage(null);
      }
    },
    [fileIsMaxSize, maxFileSize]
  );

  const onDropRejected = useCallback(
    (fileRejections: FileRejection[]): void => {
      if (fileRejections[0].errors[0].code === 'file-invalid-type')
        setErrorMessage(t('format_invalid'));
    },
    [t]
  );

  const { getRootProps, getInputProps } = useDropzone({
    accept: acceptedFormats,
    multiple: false,
    onDrop,
    onDragEnter,
    onDragLeave,
    onDropRejected,
  });

  const renderImageInput = (): React.ReactNode => (
    <S.ImageDropWrapper
      {...getRootProps()}
      data-testid="uploader-image"
      onClick={handleShowSelectImage}
      inDraging={inDraging}
      error={errorMessage}
    >
      <Icon name="image" />
      <S.DragDropTitle variant="title-bold-16">
        {dragAndDropImageLabel || t('drag_and_drop_here')}
      </S.DragDropTitle>

      <S.OptionsLabel variant="subtitle-medium-14">{t('or')}</S.OptionsLabel>

      <Button>{selectImageButtonLabel || t('select_image')}</Button>

      {imageInformationLabel()}

      <S.HiddenInput
        {...getInputProps()}
        ref={fileInputRef}
        type="file"
        accept={acceptedFormats}
        onChange={handleImageChange}
        data-testid="file-input"
      />
    </S.ImageDropWrapper>
  );

  const renderImageCrop = (): React.ReactNode => (
    <S.ImageCropperWrapper data-testId="image-crop-modal">
      <Cropper
        image={image?.url}
        crop={crop}
        zoom={zoom}
        aspect={1}
        cropShape="round"
        onCropComplete={onCropComplete}
        onCropChange={setCrop}
        onZoomChange={setZoom}
        showGrid={false}
        style={{
          containerStyle: {
            display: 'flex',
            marginTop: 88,
            borderRadius: 8,
            height: 264,
            width: 336,
            left: 32,
          },
        }}
      />
      <Box
        mt={314}
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
      >
        <Button onClick={handleCancelCrop} variant="secondary">
          {t('crop_cancel')}
        </Button>
        <Button onClick={handleCropImage}>{t('crop_submit')}</Button>
      </Box>
    </S.ImageCropperWrapper>
  );

  return (
    <Modal
      data-testid="icon-upload-modal"
      isOpen={isOpen}
      title={modalTitle || t('title')}
      onClose={closeModal}
    >
      {inCropper ? renderImageCrop() : renderImageInput()}
      {!!errorMessage && (
        <S.FieldErrorMessage data-testid="file-size-error">
          {errorMessage}
        </S.FieldErrorMessage>
      )}
    </Modal>
  );
};
