import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { withRouter } from 'react-router-dom';

import SurveyForm from 'pages/surveys/SurveyForm';
import Toast from 'components/Toast';
import Loader from 'components/Loader';

import {
  formatSurveyForm,
  convertClassroomIdsToInt,
  extractQuestions,
} from 'pages/surveys/extractDataForForm';

import SurveyActions from 'store/surveys/actions';
import { SurveyRoot } from 'store/surveys/types';
import { Props } from './types';

const SurveyNew = ({ history, kind, location }: Props) => {
  const { t } = useTranslation(['surveys']);
  const duplicateSurveyId = location.state?.duplicateSurveyId;
  const dispatch = useDispatch();

  const { setClassrooms, addSurvey, fetchSurveyForm, setCurrentForm } =
    SurveyActions;

  const current = useSelector((state: SurveyRoot) => state.surveys.current);
  const isSubmitting = useSelector(
    (state: SurveyRoot) => state.surveys.sending
  );
  const formMeta = useSelector((state: SurveyRoot) => state.surveys.formMeta);
  const isLoading = useSelector((state: SurveyRoot) => state.surveys.isLoading);
  const { dataArea } = useSelector(
    (state: { root: { dataArea: string } }) => state.root
  );

  const fetchClassrooms = useCallback(() => {
    dispatch(setClassrooms());
  }, [dispatch, setClassrooms]);

  useEffect(() => {
    if (!duplicateSurveyId) fetchClassrooms();
  }, [duplicateSurveyId]);

  const createSurvey = useCallback(
    (params) => {
      dispatch(addSurvey(params, history));
    },
    [addSurvey]
  );

  const setSurvey = useCallback(() => {
    dispatch(fetchSurveyForm(duplicateSurveyId));
  }, [dispatch, duplicateSurveyId, fetchSurveyForm]);

  useEffect(() => {
    if (duplicateSurveyId) {
      setSurvey();
    }
  }, [duplicateSurveyId, setSurvey]);

  useEffect(() => {
    getForm(current);
  }, [current]);

  const getForm = (current) => {
    if (current.attributes) {
      const form = formatSurveyForm(current);

      dispatch(setCurrentForm(form));
    }
  };

  const onSubmit = ({ form }) => {
    createSurvey({
      survey: {
        title: form.title,
        description: form.description,
        kind: form.kind,
        //TODO: remover a linha 83 e adicionar o codigo comentado na Enquete v2
        // start_datetime: moment(`${form.date_start} ${form.time_start}`)
        //   .format('YYYY-MM-DD HH:mm'),
        start_datetime: moment(new Date()).format('YYYY-MM-DD HH:mm'),
        limit_datetime: moment(`${form.date_end} ${form.time_end}`).format(
          'YYYY-MM-DD HH:mm'
        ),
        sent_kind: form.sent_kind,
        persona_type: form.persona_type,
        survey_questions_attributes: extractQuestions(
          form.survey_questions_attributes
        ),
        classroom_ids: convertClassroomIdsToInt(form.classroom_ids),
        classrooms_with_student_profile_ids:
          form.classroom_with_student_profile_ids,
      },
    });
  };

  const kindFormInfo = {
    poll: {
      titlePage: t('forms.create.pool.title_page'),
      titleModal: t('forms.create.pool.title_modal'),
      contentModal: t('forms.create.pool.content_modal'),
    },
    authorization: {
      titlePage: t('forms.create.authorization.title_page'),
      titleModal: t('forms.create.authorization.title_modal'),
      contentModal: t('forms.create.authorization.content_modal'),
    },
  }[kind];

  return (
    <div className="SurveyNew">
      <Loader isLoading={isLoading}>
        <SurveyForm
          form={current}
          formMeta={formMeta}
          onSubmit={onSubmit}
          action={duplicateSurveyId ? 'edit' : 'new'}
          backTo={`/${dataArea}/surveys`}
          kind={kind}
          isSubmitting={isSubmitting}
          {...kindFormInfo}
        />
        <Toast />
      </Loader>
    </div>
  );
};

export default withRouter(SurveyNew);
