import styled from 'styled-components';

export const Table = styled.table`
  font-family: Roboto;
  width: 100%;
`;

export const TableHead = styled.th`
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: ${(props) => props.theme.gray2};
`;

export const TBody = styled.tbody`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  :before {
    content: '@';
    display: block;
    line-height: 16px;
    text-indent: -99999px;
  }
`;

export const TableData = styled.td`
  padding-bottom: 20px;
`;
