import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import appContextProvider from 'core/hoc/appContextProvider';
import toastMessageProvider from 'core/hoc/toastMessageProvider';
import withAppContext from 'core/hoc/withAppContext';
import withErrorBoundary from 'core/hoc/withErrorBoundary';
import composeFunctions from 'core/utils/composeFunctions';

import StudentsProgression from 'components/Students/Progression';
import TempPassword from 'components/TempPassword';
import StudentsTransfer from 'components/Students/Transfer';

class StudentsSchoolMainScreen extends Component {
  static propTypes = {
    appContext: PropTypes.shape({
      dataArea: PropTypes.string.isRequired,
    }).isRequired,
  };

  render() {
    return (
      <div className="StudentsSchoolMainScreen">
        <BrowserRouter>
          <Switch>
            <Route
              exact
              path="/schools/student_profiles/progression"
              render={() => <StudentsProgression />}
            />
            <Route
              exact
              path="/schools/student_profiles/new_transfer"
              render={() => <StudentsTransfer />}
            />
            <Route
              exact
              path="/schools/student_profiles/temp_password"
              render={() => <TempPassword userType="student_profile" />}
            />
            <Route
              exact
              path="/schools/responsible_profiles/temp_password"
              render={() => <TempPassword userType="responsible_profile" />}
            />
          </Switch>
        </BrowserRouter>
      </div>
    );
  }
}

export default composeFunctions(
  toastMessageProvider,
  withErrorBoundary,
  withAppContext,
  appContextProvider
)(StudentsSchoolMainScreen);
