import React from 'react';
import PropTypes from 'prop-types';
import * as S from './styles';

import withFormContext, { formPropTypes } from 'core/hoc/withFormContext';

import Delete from 'components/Delete';
import Field from 'components/Form/Field';
import FormValidationErrors from 'components/Form/ValidationErrors';

const clean = (number) => {
  if (typeof number === 'number') return number;

  return Number(number.toString().replace(/[^0-9]/g, ''));
};

const Discount = ({
  attributeForm,
  counter,
  disabled,
  discountValues,
  index,
  onChange,
  removeDiscount,
  formContext: { hasErrorOnAttribute },
}) => {
  const hasError = hasErrorOnAttribute(attributeForm);

  const updateValues = (attributeName, form) => {
    let newValues = discountValues;

    if (attributeName === 'discount_kind') {
      newValues[attributeName] = form[attributeName];
      newValues.discount_value = 0;
    } else if (attributeName === 'days_before_expire') {
      newValues[attributeName] = clean(form[attributeName]);
    } else {
      newValues[attributeName] = form[attributeName];
    }

    onChange(index, newValues);
  };

  return (
    <>
      {discountValues && (
        <S.EdupayDiscountWrapper>
          <S.ContainerWrapper>
            <S.EdupayDiscount>
              <S.Text>{counter}. Desconto de</S.Text>
              <S.TypeDiscountWrapper>
                <Field
                  type="textWithSelect"
                  attributeName="discount_value"
                  selectAttributeName="kind"
                  controlled
                  defaultValue={discountValues.discount_value}
                  defaultSelectValue={discountValues.kind}
                  disabled={disabled}
                  onChange={(formState) =>
                    updateValues('discount_value', formState)
                  }
                  onChangeSelect={(formState) =>
                    updateValues('kind', formState)
                  }
                />
              </S.TypeDiscountWrapper>
              <S.Text>se pago até</S.Text>
              <S.QuantityField>
                <Field
                  type="number"
                  controlled
                  attributeName="days_before_expire"
                  onChange={(formState) =>
                    updateValues('days_before_expire', formState)
                  }
                  value={discountValues.days_before_expire}
                  disabled={disabled}
                />
              </S.QuantityField>
              <S.Text>dias antes do vencimento.</S.Text>
            </S.EdupayDiscount>
            {!disabled && index !== 0 && (
              <S.DeleteButtonWrapper>
                <Delete
                  variation="default"
                  onClick={() => removeDiscount(index)}
                />
              </S.DeleteButtonWrapper>
            )}
          </S.ContainerWrapper>
          {hasError && <FormValidationErrors attributeName={attributeForm} />}
        </S.EdupayDiscountWrapper>
      )}
    </>
  );
};

Discount.propTypes = {
  removeItem: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  discountValues: PropTypes.shape({
    discount_value: PropTypes.number,
    kind: PropTypes.string,
    days_before_expire: PropTypes.number,
  }).isRequired,
  index: PropTypes.number.isRequired,
  ...formPropTypes,
};

export default withFormContext(Discount);
