const maxPrice = 99999999.99;

const recurrentItemValue = ({ discount_kind, discount_value, price }) => {
  if (discount_kind === 'percent') {
    return price - (price * (discount_value / 100));
  }

  return price - discount_value;
};

const validateRecurrentItems = (input) => {
  let result = true;
  let total = 0.0;

  input.some((item) => {
    const itemValue = recurrentItemValue(item);

    if (!item.recurrent_product_id || itemValue < 0) {
      result = false;
      return true;
    }

    total += itemValue;
  });

  return total >= 10.0 && total <= maxPrice ? result : false;
}

export default validateRecurrentItems;
