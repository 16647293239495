import React from 'react';
import { useSelector } from 'react-redux';

import withAppContext from 'core/hoc/withAppContext';
import { OmniChannelState } from 'store/messages/omniChannel/types';

import * as S from './styles';

import ChatHeader from '../ChatHeader';
import { ChatTabs } from '../ChatTabs';
import { ChatSearchTabs } from '../ChatSearchTabs';

const ChatWrapper = (): JSX.Element => {
  const { chatIsSearchTab, activeChat, activeChatUser } = useSelector(
    (state: OmniChannelState) => state.omniChannel
  );

  const isActive = activeChat || activeChatUser;

  return (
    <S.Wrapper isActiveChat={!!isActive}>
      <ChatHeader />
      {chatIsSearchTab ? <ChatSearchTabs /> : <ChatTabs />}
    </S.Wrapper>
  );
};

export default withAppContext(ChatWrapper);
