import React, { useEffect } from 'react';
import Field from 'components/Form/Field';
import withFormContext from 'core/hoc/withFormContext';
import { InviteFieldProps } from './types';

const InviteField = ({
  attributeName,
  disabled,
  error,
  beforeCopy,
  formContext: { addErrorOnAttribute },
}: InviteFieldProps) => {
  useEffect(() => {
    if (error)
      addErrorOnAttribute(attributeName, 'Erro ao gerar link de cadastro');
  }, [error]);

  return (
    <>
      <Field
        type="copy"
        beforeCopy={beforeCopy}
        attributeName={attributeName}
        disabled={disabled}
      />
    </>
  );
};

export default withFormContext(InviteField);
