import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';

import composeFunctions from 'core/utils/composeFunctions';
import reduxProvider from 'core/hoc/reduxProvider';
import withAppContext from 'core/hoc/withAppContext';

import ApplyHandoutTemplate from 'screens/Handouts/Form/ApplyHandoutTemplate';
import DuplicateHandout from 'screens/Handouts/Form/DuplicateHandout';
import EditHandout from 'screens/Handouts/Form/EditHandout';
import EditHandoutTemplate from 'screens/Handouts/HandoutTemplate/Form/EditHandoutTemplate';
import HandoutCategoriesForm from 'screens/Handouts/Categories/Form';
import HandoutCategoriesListScreen from 'screens/Handouts/Categories/List';
import HandoutDetails from 'screens/Handouts/HandoutDetails';
import HandoutScreen from 'screens/Handouts';
import HandoutTemplateScreen from 'screens/Handouts/HandoutTemplate';
import NewHandout from 'screens/Handouts/Form/NewHandout';
import NewHandoutTemplate from 'screens/Handouts/HandoutTemplate/Form/NewHandoutTemplate';

import { HandoutRoutesProps, HandoutFormProps } from './types';

const HandoutGroupRoutes = ({
  appContext: { dataArea },
}: HandoutRoutesProps) => {
  return (
    <div id="HandoutGroupRoutes">
      <CookiesProvider>
        <Switch>
          <Route
            exact
            path={`/${dataArea}/handouts/templates`}
            component={HandoutTemplateScreen}
          />

          <Route
            exact
            path={`/${dataArea}/handouts/templates/new`}
            component={NewHandoutTemplate}
          />

          <Route
            exact
            path={`/${dataArea}/handouts/templates/:id/edit`}
            component={EditHandoutTemplate}
          />

          <Route
            exact
            path={`/${dataArea}/handouts`}
            component={HandoutScreen}
          />

          <Route
            exact
            path={`/${dataArea}/handouts/new`}
            component={() => <NewHandout />}
          />

          <Route
            exact
            path={`/${dataArea}/handouts/:id/edit`}
            component={(props: HandoutFormProps) => <EditHandout {...props} />}
          />

          <Route
            exact
            path={`/${dataArea}/handouts/:id/duplicate`}
            component={(props: HandoutFormProps) => (
              <DuplicateHandout {...props} />
            )}
          />

          <Route
            exact
            path={`/${dataArea}/handouts/templates/:id/apply`}
            component={(props: HandoutFormProps) => (
              <ApplyHandoutTemplate {...props} />
            )}
          />

          <Route
            exact
            path={`/${dataArea}/handouts/:id`}
            component={HandoutDetails}
          />

          <Route
            exact
            path={`/${dataArea}/handout_categories`}
            component={HandoutCategoriesListScreen}
          />

          <Route
            exact
            path={`/${dataArea}/handout_categories/new`}
            component={HandoutCategoriesForm}
          />

          <Route
            exact
            path={`/${dataArea}/handout_categories/:id/edit`}
            component={(props: HandoutFormProps) => (
              <HandoutCategoriesForm {...props} />
            )}
          />
        </Switch>
      </CookiesProvider>
    </div>
  );
};

export default composeFunctions(
  withAppContext,
  reduxProvider
)(HandoutGroupRoutes);
