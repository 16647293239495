import React from 'react';
import tabifyForWizard from 'core/hoc/tabifyForWizard';
import withFormContext, { formPropTypes } from 'core/hoc/withFormContext';
import * as S from './styles';

import Field from 'components/Form/Field';
import FormCheckbox from 'components/Form/Checkbox';
import FormFieldset from 'components/Form/Fieldset';

import validations from './validations';

const RADIOS = [
  {
    labelText: 'Corrente',
    value: 'checking_account',
  },
  {
    labelText: 'Poupança',
    value: 'savings_account',
  },
];

const PaymentsSchoolWalletFormBankInformationsTab = ({
  formContext: {
    action,
    form,
    formMeta: {
      select_options: { code },
    },
    updateAttribute,
  },
}) => {
  const toggleCheckbox = () => {
    const value = form.free_iss;

    updateAttribute('free_iss', !value);
  };

  const formattedArray = (array) => {
    const formattedArray = array.map((current) => ({
      label: current.value + ' - ' + current.name,
      value: current.value,
    }));

    formattedArray.unshift({ value: null, label: 'Selecione um banco' });

    return formattedArray;
  };

  return (
    <S.BankInformationsWrapper>
      <FormFieldset>
        <Field
          label="Tipo de conta*"
          attributeName="type"
          type="radio"
          radios={RADIOS}
        />
        <S.RowWrapper>
          <Field
            label="Banco*"
            attributeName="code"
            type="selectWithIcon"
            options={formattedArray(code)}
            value={form.code}
            onChange={(e) => updateAttribute('code', e.value)}
            withArrowSelect
            fullWidth
          />
        </S.RowWrapper>
        <S.RowWrapper>
          <S.ColumnWrapper>
            <Field
              label="Agência*"
              attributeName="agency"
              placeholder="0000"
              type="masked"
              mask="9999"
            />
          </S.ColumnWrapper>
          <S.ColumnWrapper>
            <Field
              label="Dígito da agência"
              attributeName="agency_dv"
              placeholder="0"
              type="masked"
              mask="*"
            />
          </S.ColumnWrapper>
        </S.RowWrapper>
        <S.RowWrapper>
          <S.ColumnWrapper>
            <Field
              label="Conta*"
              attributeName="account"
              placeholder="00000000000"
              type="masked"
              mask="99999999999"
            />
          </S.ColumnWrapper>
          <S.ColumnWrapper>
            <Field
              label="Dígito da conta*"
              attributeName="account_dv"
              placeholder="0"
              type="masked"
              mask="**"
            />
          </S.ColumnWrapper>
        </S.RowWrapper>
        <S.RequireItemsMessage>
          *Campos de preenchimento obrigatório
        </S.RequireItemsMessage>
      </FormFieldset>
    </S.BankInformationsWrapper>
  );
};

PaymentsSchoolWalletFormBankInformationsTab.propTypes = {
  ...formPropTypes,
};

export default tabifyForWizard({
  title: '3. Conta bancária',
  subTitle: 'Dados da conta bancária da escola',
  validations,
})(withFormContext(PaymentsSchoolWalletFormBankInformationsTab));
