import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import ColoredText from 'components/ColoredText';
import Button from 'components/Button';
import Table from 'components/Table';
import Pagination from 'components/Pagination';
import table from './table';
import Loader from 'components/Loader';
import * as S from './styles';

const HeadquarterHistoryModal = ({
  headquarterId,
  headquarterName,
  isModalOpen,
  closeModal,
  onPageChanged,
  activePage,
}) => {
  const {
    isLoadingHistory,
    headquartersHistory,
    headquartersHistoryCount,
    headquartersHistoryPagination,
  } = useSelector((state) => state.headquarters);

  const { totalItemsCount, itemsCountPerPage } = headquartersHistoryPagination;

  const exportHeadquartersHistory = useCallback(
    (type) => {
      document.location.href = `headquarters/${headquarterId}/export_history.${type}`;
    },
    [headquarterId]
  );

  const tableEntries = () => {
    const start = activePage * itemsCountPerPage - (itemsCountPerPage - 1);
    const finish = activePage * itemsCountPerPage;

    if (headquartersHistory.length > 0) {
      return (
        <div className="customMetadata">
          {headquartersHistory.length === 1 ? (
            <span>Exibindo 1 - 1 registro</span>
          ) : (
            <span>
              Exibindo {start} -{' '}
              {finish > totalItemsCount ? totalItemsCount : finish} registros
              dos últimos{' '}
              {headquartersHistoryCount < 50 ? headquartersHistoryCount : 50}
            </span>
          )}
        </div>
      );
    }
  };

  const modalTitle = () => (
    <S.Title>
      Histórico da unidade&nbsp;
      <ColoredText variation="primary">{headquarterName}</ColoredText>
    </S.Title>
  );

  const modalSubtitle = () => (
    <S.Subtitle>
      Fazer download de todos os registros desta unidade (
      {headquartersHistoryCount === 1
        ? `${headquartersHistoryCount} registro`
        : `${headquartersHistoryCount} registros`}
      )
      <div>
        <Button
          size="btn-xs"
          variation="link"
          onClick={() => exportHeadquartersHistory('csv')}
        >
          CSV
        </Button>
        |
        <Button
          size="btn-xs"
          variation="link"
          onClick={() => exportHeadquartersHistory('xls')}
        >
          XLS
        </Button>
      </div>
    </S.Subtitle>
  );

  const MODAL_CENTERED = {
    top: '50%',
    left: '50%',
    padding: '24px',
    transform: 'translate(-50%, -50%)',
  };

  return (
    <S.CustomModalContainer
      isOpen={isModalOpen}
      toggleModal={closeModal}
      maxWidth="800px"
      minHeight="618px"
      contentStyles={MODAL_CENTERED}
      title={modalTitle()}
    >
      <div>
        {modalSubtitle()}
        <S.TableWrapper>
          <Loader isLoading={isLoadingHistory}>
            <Table
              columns={table}
              data={headquartersHistory}
              customMetadata={tableEntries()}
            />
            {headquartersHistory.length > 0 && (
              <Pagination
                onChange={onPageChanged}
                activePage={activePage}
                totalItemsCount={totalItemsCount}
                itemsCountPerPage={itemsCountPerPage}
              />
            )}
          </Loader>
        </S.TableWrapper>
      </div>
    </S.CustomModalContainer>
  );
};

HeadquarterHistoryModal.propTypes = {
  headquarterId: PropTypes.string,
  headquarterName: PropTypes.string,
  isModalOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  onPageChanged: PropTypes.func,
  activePage: PropTypes.number,
};

export default HeadquarterHistoryModal;
