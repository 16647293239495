import styled from 'styled-components';

const defaultMargin = '24px';

export const Paragraph = styled.p`
  font-family: 'Roboto';
  font-size: 12px;
  font-weight: 400;
`;

export const Title = styled.h1<{ fontSize?: string }>`
  font-family: Quicksand;
  font-weight: 700;
  font-size: ${(props) => props.fontSize || '16px'};
  line-height: ${(props) => props.fontSize || '16px'};
  letter-spacing: 0em;
  text-align: left;
  color: ${(props) => props.theme.black};
  margin-bottom: ${defaultMargin};
  margin-top: 0px;
`;

export const TooltipContainer = styled.div`
  h1,
  div {
    display: inline-block !important;
  }

  .Tooltip {
    margin-left: 8px;
  }
`;

export const Container = styled.div`
  .Field {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .Alert {
    height: 100%;
    margin-bottom: 24px;
  }
`;

export const ContainerDerivativeBills = styled.div`
  margin-bottom: 24px;
`;
