import React from 'react';
import { useSelector } from 'react-redux';

import tabifyForWizard from 'core/hoc/tabifyForWizard';

import Alert from 'components/Alert';
import Title from 'components/WizardForm/Title';
import UserDisplay from 'components/UserDisplay';

// Sections
import GeneralInformations from 'components/Payments/PaymentsContainer/FormSections/GeneralInformations';
import RecurrencyConfiguration from 'components/Payments/PaymentsContainer/FormSections/RecurrencyConfiguration';
import WalletConfiguration from 'components/Payments/PaymentsContainer/FormSections/WalletConfiguration';
import FinancialResponsibleConfiguration from 'components/Payments/PaymentsContainer/FormSections/FinancialResponsibleConfiguration';
import RecurrentItems from 'components/Payments/Recurrent/RecurrentFormSections/RecurrentItems';
import EdupayDiscount from 'components/Payments/PaymentsContainer/FormSections/EdupayDiscount';
import FinesAndInterest from 'components/Payments/Recurrent/RecurrentFormSections/FinesAndInterest';
import RecurrentSummary from 'components/Payments/Recurrent/RecurrentFormSections/RecurrentSummary';

import validations from './validations.js';

const EditRecurrentPlanInformations = () => {
  const { current } = useSelector((state) => state.recurrentPlans);

  return (
    <>
      <fieldset className="FormFieldset col-xs-12 col-lg-8 col-lg-offset-2">
        <div className="double-input">
          <UserDisplay
            user={current.recurrentPlan.student_profile.data}
            classroomsNames={
              current.recurrentPlan.student_profile.data.attributes
                .classroom_names
            }
            size="medium"
          />
        </div>
        <div className="alert-wrapper">
          <Alert variation="warning">
            Ao editar os campos abaixo, todas as cobranças não enviadas
            receberão as mesmas atualizações.
          </Alert>
        </div>
        <WalletConfiguration isRecurrent />
        <FinancialResponsibleConfiguration />
        <Title name="Informações gerais" />
        <GeneralInformations />
        <RecurrencyConfiguration type="plan" action="edit" />
        <RecurrentItems title="Itens da cobrança" type="plan" action="edit" />
        <FinesAndInterest type="plan" action="edit" />
        <EdupayDiscount
          type="plan"
          action="edit"
          validations={validations}
          showCreditCardDiscount
        />
        <RecurrentSummary action="edit" />
      </fieldset>
    </>
  );
};

export default tabifyForWizard({
  title: '1. Informações',
  validations,
})(EditRecurrentPlanInformations);
