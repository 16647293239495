import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import SwitchTitle from 'components/ReduxFormCommom/SwitchTitle';
import * as Locale from 'core/locale';

import Tab from 'components/Tab';
import Tabs from 'components/Tabs';
import { Box, Card, Text, Select, Button } from '@agendaedu/ae-web-components';

import AccordionDetails from '../components/AccordionDetails';
import AcceptModal from '../components/Modal';
import { trackEvent } from 'config/amplitude';

import { useTranslation } from 'react-i18next';

function InitPermissionsForm({
  handleSubmit,
  handleToggleSwitch,
  initialValues,
  usersSelect,
  selectRoleProfId,
  hasChangedForm,
}) {
  const { t } = useTranslation(['role_permissions']);

  const [isDiscardModal, setIsDiscardModal] = useState({
    status: false,
    origin: '',
  });

  const renderSwitchTitle = useCallback(
    (title, permission, tabTitle, isOldForm) => {
      return (
        <Box
          width="100%"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          backgroundColor="neutral.gray5"
        >
          <Box>
            <Text
              mt={8}
              variant="title-bold-16"
              color="neutral.gray1"
              fontWeight={700}
            >
              {Locale.PERMISSIONS_ROLES[title]
                ? Locale.PERMISSIONS_ROLES[title]
                : title}
            </Text>
          </Box>
          <Box>
            <Field
              onChange={() =>
                handleToggleSwitch(
                  `${tabTitle}.${title}.${!permission.enabled}`,
                  'role',
                  isOldForm
                )
              }
              component={SwitchTitle}
              value={permission.enabled}
              props={{ value: permission.enabled }}
            />
          </Box>
        </Box>
      );
    },
    [handleToggleSwitch]
  );

  const renderSwitchBody = useCallback(
    (actions, actionName, role, enabled, tabTitle, isOldForm) => {
      return (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          mb={16}
        >
          {Object.keys(actions).map((name) => {
            return (
              <Box
                key={name}
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                mt={32}
              >
                <Field
                  onChange={(key) =>
                    handleToggleSwitch(
                      `${tabTitle}.${name}.${!actions[name]}`,
                      role,
                      isOldForm
                    )
                  }
                  component={SwitchTitle}
                  props={{
                    disabled: !enabled,
                    title: actionName[name],
                    value: actions[name],
                  }}
                />
              </Box>
            );
          })}
        </Box>
      );
    },
    [handleToggleSwitch, initialValues]
  );

  const renderAcordeon = useCallback(
    (value, name, tabTitle, localeDicionary, isOldForm) => {
      return (
        <AccordionDetails
          key={name}
          rolePermissonName={name}
          // expanded={value[name].enabled ? name : null} // melhoria futura
          expanded={name}
          header={renderSwitchTitle(name, value[name], tabTitle, isOldForm)}
        >
          {renderSwitchBody(
            value[name].actions,
            localeDicionary,
            name,
            value[name].enabled,
            tabTitle,
            isOldForm
          )}
        </AccordionDetails>
      );
    },
    [renderSwitchBody, renderSwitchTitle, initialValues]
  );

  return (
    <>
      <Text variant="title-bold-16" color="neutral.black">
        {t('title_select_profile')}
      </Text>

      <Box mt={30}>
        <Select
          label={t('label_profile_permission')}
          value={usersSelect.length > 0 ? usersSelect[0].id : null}
          options={usersSelect}
          onChange={({ value: roleId }) => {
            // !extra!, será uma melhoria futura
            // hasChangedForm
            //   ? setIsDiscardModal({
            //       status: true,
            //       value: roleId,
            //       origin: 'select_role',
            //     })
            //   : selectRoleProfId(roleId);

            selectRoleProfId(roleId);
          }}
        />
      </Box>
      <Box mt={45}>
        <Text
          m={24}
          ml={0}
          variant="subtitle-medium-14"
          fontWeight={500}
          color="neutral.black"
        >
          {t('title_permissions')}
        </Text>
        <Card>
          <Tabs defaultIndex={0}>
            {initialValues?.attributes?.permissions.map((permission) => {
              const { name: tabTitle, value } = permission;

              return (
                <Tab
                  key={tabTitle}
                  title={
                    Locale.TAB_NAMES[tabTitle]
                      ? Locale.TAB_NAMES[tabTitle]
                      : tabTitle
                  }
                >
                  <Box>
                    {Object.keys(value).length >= 0 &&
                      Object.keys(value).map((name) => {
                        return renderAcordeon(
                          value,
                          name,
                          tabTitle,
                          Locale.PERMISSIONS_ACTIONS,
                          false
                        );
                      })}
                  </Box>
                </Tab>
              );
            })}
            {!!initialValues.attributes?.payments && (
              <Tab key="financeiro" title="Financeiro">
                {renderAcordeon(
                  { payments: initialValues.attributes.payments },
                  'payments',
                  'payments',
                  Locale.PERMISSIONS_PAYMENTS,
                  true
                )}
              </Tab>
            )}
          </Tabs>

          <Box
            width="100%"
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            m={24}
            ml={0}
            mr={0}
          >
            <Button
              variant="secondary"
              size="md"
              onClick={() => {
                hasChangedForm
                  ? setIsDiscardModal({ status: true, origin: 'button_close' })
                  : window.location.assign(`/`);
              }}
            >
              {t('button_close')}
            </Button>

            <Button
              onClick={handleSubmit}
              variant="primary"
              size="md"
              disabled={!hasChangedForm}
            >
              {t('save_permissions')}
            </Button>
          </Box>
        </Card>
      </Box>

      <AcceptModal
        isModalOpen={isDiscardModal.status}
        origin={isDiscardModal.origin}
        value={isDiscardModal.value}
        actionModal={(value, origin) => {
          if (origin == 'select_role') selectRoleProfId(value);
          else if (origin == 'button_close') window.location.assign(`/`);

          setIsDiscardModal((prevState) => ({
            ...prevState,
            status: false,
            origin: '',
          }));
        }}
        closeModal={() => {
          trackEvent(`role_permissions_close_modal_save|discard`);
          setIsDiscardModal((prevState) => ({
            ...prevState,
            status: false,
            origin: '',
          }));
        }}
        titleModal={t('modal_discard.title')}
        textCloseButton={t('modal_discard.button_close')}
        textBackButton={t('modal_discard.button_back')}
      >
        <Text
          p={0}
          as="p"
          variant="body-regular-14"
          textAlign="justify"
          color="neutral.black"
          lineHeight="lg"
          fontWeight={500}
          mb={24}
        >
          {t('modal_discard.content')}
        </Text>
      </AcceptModal>
    </>
  );
}

InitPermissionsForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleToggleSwitch: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  usersSelect: PropTypes.bool.isRequired,
  roleId: PropTypes.bool.isRequired,
  selectRoleProfId: PropTypes.string.isRequired,
  hasChangedForm: PropTypes.bool.isRequired,
};

const PermissionsForm = reduxForm({
  form: 'PermissionsForm',
  enableReinitialize: true,
  usersSelect: [],
})(InitPermissionsForm);

export default PermissionsForm;
