import styled, { css } from 'styled-components';

export const CardContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    margin-top: 16px;
    width: 100%;
    padding: 16px 24px;
    border-radius: 8px;
    box-shadow: 0 1px 5px 0 ${theme.black}15;
    background-color: ${theme.white};

    @media screen and (max-width: 900px) {
      flex-wrap: wrap;
    }
  `}
`;

export const CardHead = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    margin-right: 10px;
    min-width: 270px;

    .head-avatar .Avatar.large {
      min-width: 48px;
      margin-right: 12px;
    }

    .head-info {
      display: flex;
      flex-direction: column;

      a {
        display: inline-block;
        width: 270px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        font-family: 'Quicksand';
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.31;
        letter-spacing: normal;
        text-align: left;
        color: ${theme.black};
      }

      span {
        margin-top: 4px;
        font-family: 'Quicksand';
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.75;
        letter-spacing: normal;
        text-align: left;
        color: ${theme.gray2};
      }
    }
  `}
`;

export const CardBody = styled.div`
  display: flex;
  width: 100%;
  font-family: 'Quicksand';

  @media screen and (max-width: 900px) {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 16px;
  }
`;

export const WrapperLink = styled.div`
  ${({ theme }) => css`
    position: relative;
    cursor: pointer;

    .DropdownButton {
      position: absolute;
      right: 24px;
      top: 14px;
      height: 40px;
      width: 40px;
      margin-left: 10px;
      margin-top: 8px;

      .Button {
        border: none;

        .AgendaIcon.small {
          font-size: 24px;
        }

        &:hover {
          border: 1px solid ${theme.primaryColor}20;
        }
      }

      .Button:not(:hover) {
        color: ${theme.gray2};
      }

      .dropdown-menu {
        position: relative;
        border: none;
        border-radius: 6px;
        box-shadow: 0px 0px 8px 0px ${theme.black}20;

        &.down {
          float: right;
          min-width: 111px;
          top: 4px;
        }

        .dropdown-item {
          height: 40px;
          margin: 8px;
          font-family: Roboto;
          font-size: 16px;
          color: ${theme.gray2};

          &:hover {
            color: ${theme.primaryColor};
            background-color: ${theme.primaryColor}10;
            border-radius: 6px;
          }
        }
      }
    }

    @media screen and (max-width: 900px) {
      flex-direction: column;
      position: relative;
      align-items: flex-start;
      box-shadow: 0 1 5px 0 ${theme.black}20;

      .DropdownButton {
        position: absolute;
        right: 10px;
      }
    }
  `}
`;
