import createModuleReducer from '../storeConfig/createModuleReducer';
import actions from './actions';

export const INITIAL_STATE = {
  events: [],
  error: null,
  sending: false,
  isLoading: false,
  current: {
    title: '',
    description: '',
    location: '',
    persona_type: 'both',
    kind: 'confirmation',
    has_conference: false,
    start_at: '',
    end_at: '',
    sent_kind: 'classroom',
    classroom_ids: [],
    attachments: [],
    classroom_with_student_profile_ids: {},
  },
  formMeta: {
    canEditClassrooms: true,
    canEditKind: true,
    canEditConference: true,
    notificateWhenEditing: false,
    modalAnswered: false,
    openModal: false,
  },
};

export const addEvent = (state) => ({
  ...state,
  sending: true,
});

export const addEventSuccess = (state, action) => ({
  ...state,
  events: [action.data, ...state.events],
});

export const setEvent = (state) => ({
  ...state,
  isLoading: true,
  formMeta: {
    ...state.formMeta,
    student_profiles: [],
    classrooms: [],
    select_options: {
      classroom_id: [],
    },
  },
});

export const setEventSuccess = (state, action) => {
  const {
    event: { attributes, relationships },
    classrooms,
    meta,
  } = action;

  classrooms.forEach((headquarter) =>
    headquarter.options.forEach((classroom) => {
      classroom.value = classroom.id;
      classroom.label = classroom.attributes.name;
    })
  );

  return {
    ...state,
    current: {
      ...attributes,
      classroom_ids: relationships.classrooms.data.map(
        (classroom) => classroom.id
      ),
    },
    isLoading: false,
    formMeta: {
      ...state.formMeta,
      canEditClassrooms: meta.can_edit_classrooms,
      canEditKind: meta.can_edit_kind,
      canEditConference: meta.can_edit_conference,
      notificateWhenEditing: meta.notificate_when_editing,
      student_profiles: attributes.student_profiles.data,
      select_options: {
        classroom_id: classrooms,
      },
      classrooms,
    },
  };
};

export const setClassrooms = (state) => ({
  ...state,
  isLoading: true,
  formMeta: {
    ...state.formMeta,
    classrooms: [],
  },
});

export const setClassroomsSuccess = (state, action) => {
  const { data } = action;

  data.forEach((headquarter) =>
    headquarter.options.forEach((classroom) => {
      classroom.value = classroom.id;
      classroom.label = classroom.attributes.name;
    })
  );

  return {
    ...state,
    isLoading: false,
    formMeta: {
      ...state.formMeta,
      classrooms: data,
      select_options: {
        classroom_id: data,
      },
    },
  };
};

export const editEvent = (state) => ({
  ...state,
  sending: true,
});

export const editEventSuccess = (state, action) => {
  const { attributes } = action.data;

  return {
    ...state,
    current: attributes,
  };
};

export const handleError = (state, action) => ({
  ...state,
  error: action.error,
  sending: false,
  isLoading: false,
});

const HANDLERS = {
  [actions.ADD_EVENT]: addEvent,
  [actions.ADD_EVENT_SUCCESS]: addEventSuccess,
  [actions.SET_EVENT_REQUEST]: setEvent,
  [actions.SET_EVENT_SUCCESS]: setEventSuccess,
  [actions.SET_CLASSROOMS_REQUEST]: setClassrooms,
  [actions.SET_CLASSROOMS_SUCCESS]: setClassroomsSuccess,
  [actions.EDIT_EVENT]: editEvent,
  [actions.EDIT_EVENT_SUCCESS]: editEventSuccess,
  [actions.ERROR]: handleError,
};

const events = createModuleReducer(INITIAL_STATE, HANDLERS);

export default events;
