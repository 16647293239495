import { Chat, Message } from 'store/messages/omniChannel/types';
import i18n from 'config/i18n';

export const normalizeFetchMessageReducer = (
  chats: Chat[],
  message: Message
) => {
  const newList: Chat[] = [];

  let currentChat: Chat;

  const getLastMessage = (message: Message) => {
    if (message.attributes.file && !message.attributes.text)
      return i18n.t('messages:omni_channel.chat.last_message_attachment_text');

    if (message.attributes.user.attributes.role)
      return `${message.attributes.user.attributes.role}: ${message.attributes.text}`;

    return message.attributes.text;
  };

  chats.map((chat: Chat) => {
    if (chat.id === message.relationships.chat.data.id) {
      currentChat = {
        ...chat,
        attributes: {
          ...chat.attributes,
          last_message_preview: getLastMessage(message),
          last_message_sent_at: message.attributes.sentAt,
          read_status: message.attributes.readStatus
            ? 'answered'
            : 'unanswered',
        },
      };

      return newList.push(currentChat);
    }
    newList.push(chat);
  });

  return { activeChat: currentChat, chats: newList };
};
