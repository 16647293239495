import {
  AddMediaSagaProps,
  CommonMediaParams,
  PhotoVisibilityStudentsSagaProps,
  SaveAlbumRequestParams,
  SetFilterProps,
  UpdateMediaSagaProps,
} from './types';

const actions = {
  ERROR: 'ERROR/albums',
  SUCCESS: 'SUCCESS/albums',

  FETCH_ALBUMS: 'FETCH_ALBUMS/albums',
  FETCH_ALBUMS_SUCCESS: 'FETCH_ALBUMS_SUCCESS/albums',
  FETCH_ALBUM: 'FETCH_ALBUM/albums',
  FETCH_ALBUM_SUCCESS: 'FETCH_ALBUM_SUCCESS/albums',
  FETCH_ALBUM_DETAILS: 'FETCH_ALBUM_DETAILS/albums',
  FETCH_ALBUM_DETAILS_SUCCESS: 'FETCH_ALBUM_DETAILS_SUCCESS/albums',
  DELETE_ALBUM: 'DETETE_ALBUM/albums',
  DELETE_ALBUM_SUCCESS: 'DELETE_ALBUM_SUCCESS/albums',
  DELETE_MEDIA: 'DELETE_MEDIA/albums',
  DELETE_MEDIA_SUCCESS: 'DELETE_MEDIA_SUCCESS/albums',
  ADD_MEDIA: 'ADD_MEDIA/albums',
  ADD_MEDIA_SUCCESS: 'ADD_MEDIA_SUCCESS/albums',
  ADD_MEDIA_ERROR: 'ADD_MEDIA_ERROR/albums',
  UPDATE_MEDIA: 'UPDATE_MEDIA/albums',
  UPDATE_MEDIA_SUCCESS: 'UPDATE_MEDIA_SUCCESS/albums',
  UPDATE_MEDIA_ERROR: 'UPDATE_MEDIA_ERROR/albums',
  SET_MEDIA_COVER: 'SET_MEDIA_COVER/albums',
  SET_MEDIA_COVER_SUCCESS: 'SET_MEDIA_COVER_SUCCESS/albums',
  APPROVE_PENDINGS: 'APPROVE_PENDINGS_ALBUM/albums',
  APPROVE_PENDINGS_SUCCESS: 'APPROVE_PENDINGS_ALBUM_SUCCESS/albums',
  DOWNLOAD_ALL_MEDIAS: 'DOWNLOAD_ALL_MEDIAS/albums',
  APPROVE_PENDING_MEDIA: 'APPROVE_PENDING_MEDIA/albums',
  SET_FILTER: 'SET_FILTER/albums',
  SET_SIDE_FILTERS_DATA: 'SET_SIDE_FILTERS_DATA/albums',
  FETCH_FORM_CLASSROOMS: 'FETCH_FORM_CLASSROOMS/albums',
  FETCH_FORM_CLASSROOMS_SUCCESS: 'FETCH_FORM_CLASSROOMS_SUCCESS/albums',
  REQUEST_SAVE_ALBUM: 'REQUEST_SAVE_ALBUM/albums',
  REQUEST_SAVE_ALBUM_SUCCESS: 'REQUEST_SAVE_ALBUM_SUCCESS/albums',
  FETCH_ALBUM_DETAILS_MEDIAS: 'FETCH_ALBUM_DETAILS_MEDIAS/albums',
  FETCH_ALBUM_DETAILS_MEDIAS_SUCCESS:
    'FETCH_ALBUM_DETAILS_MEDIAS_SUCCESS/albums',
  FETCH_PHOTO_VISIBILITY_STUDENTS_BY_HEADQUARTER:
    'FETCH_PHOTO_VISIBILITY_STUDENTS_BY_HEADQUARTER/albums',
  FETCH_PHOTO_VISIBILITY_STUDENTS_BY_HEADQUARTER_SUCCESS:
    'FETCH_PHOTO_VISIBILITY_STUDENTS_BY_HEADQUARTER_SUCCESS/albums',
  FETCH_PHOTO_VISIBILITY_STUDENTS_BY_HEADQUARTER_ERROR:
    'FETCH_PHOTO_VISIBILITY_STUDENTS_BY_HEADQUARTER_ERROR/albums',
  FETCH_PHOTO_VISIBILITY_STUDENTS: 'FETCH_PHOTO_VISIBILITY_STUDENTS/albums',
  FETCH_PHOTO_VISIBILITY_STUDENTS_SUCCESS:
    'FETCH_PHOTO_VISIBILITY_STUDENTS_SUCCESS/albums',
  FETCH_PHOTO_VISIBILITY_STUDENTS_ERROR:
    'FETCH_PHOTO_VISIBILITY_STUDENTS_ERROR/albums',

  fetchAlbums: ({ nextLink }: { nextLink?: string }) => ({
    type: actions.FETCH_ALBUMS,
    nextLink,
  }),
  deleteAlbum: (id: string, goBackToList?: boolean) => ({
    type: actions.DELETE_ALBUM,
    id,
    goBackToList,
  }),
  approvePendingsAlbum: (id: string) => ({
    type: actions.APPROVE_PENDINGS,
    id,
  }),
  downloadAlbum: (id: string) => ({
    type: actions.DOWNLOAD_ALL_MEDIAS,
    id,
  }),
  approvePendingMedia: ({ id, albumId }: CommonMediaParams) => ({
    type: actions.APPROVE_PENDING_MEDIA,
    id,
    albumId,
  }),
  deleteMedia: ({ id, albumId }: CommonMediaParams) => ({
    type: actions.DELETE_MEDIA,
    id,
    albumId,
  }),
  setMediaCover: ({ id, albumId }: CommonMediaParams) => ({
    type: actions.SET_MEDIA_COVER,
    id,
    albumId,
  }),
  setFilters: ({ filters }: SetFilterProps) => ({
    type: actions.SET_FILTER,
    filters,
  }),
  setSideFiltersData: () => ({
    type: actions.SET_SIDE_FILTERS_DATA,
  }),
  fetchFormClassrooms: () => ({
    type: actions.FETCH_FORM_CLASSROOMS,
  }),
  requestSaveAlbum: (album: SaveAlbumRequestParams['album']) => ({
    type: actions.REQUEST_SAVE_ALBUM,
    album,
  }),
  fetchAlbum: (id: string) => ({
    type: actions.FETCH_ALBUM,
    id,
  }),
  fetchAlbumDetails: (id: string) => ({
    type: actions.FETCH_ALBUM_DETAILS,
    id,
  }),
  fetchAlbumDetailsMedias: ({
    id,
    nextLink,
  }: {
    id: string;
    nextLink?: string;
  }) => ({
    type: actions.FETCH_ALBUM_DETAILS_MEDIAS,
    nextLink,
    id,
  }),
  addMedia: ({
    albumId,
    signedIds,
    callbackSuccess,
  }: Omit<AddMediaSagaProps, 'type'>) => ({
    type: actions.ADD_MEDIA,
    albumId,
    signedIds,
    callbackSuccess,
  }),
  updateMedia: ({
    id,
    albumId,
    description,
    studentProfileIds,
    callbackSuccess,
  }: Omit<UpdateMediaSagaProps, 'type'>) => ({
    type: actions.UPDATE_MEDIA,
    id,
    albumId,
    description,
    studentProfileIds,
    callbackSuccess,
  }),
  fetchPhotoVisibilityStudentsByHeadquarter: ({
    albumId,
    photoId,
  }: Omit<PhotoVisibilityStudentsSagaProps, 'type'>) => ({
    type: actions.FETCH_PHOTO_VISIBILITY_STUDENTS_BY_HEADQUARTER,
    albumId,
    photoId,
  }),
  fetchPhotoVisibilityStudents: ({
    albumId,
    photoId,
  }: Omit<PhotoVisibilityStudentsSagaProps, 'type'>) => ({
    type: actions.FETCH_PHOTO_VISIBILITY_STUDENTS,
    albumId,
    photoId,
  }),
};

export default actions;
