export const constants = Object.freeze({
  IMAGE_OFFER:
    'https://static.agendaedu.com/hubstore/empty_state_billings_painel.png',
  IMAGE_EMPTY: 'https://static.agendaedu.com/survey/app_error.png',
  TOPICS_EMPTY: [
    {
      icon: 'message-check',
      description: 'Transforme sua comunicação escolar em poucas semanas;',
    },
    {
      icon: 'menu',
      description:
        'Tenha acesso a recursos como Atividades, Diário e muito mais;',
    },
    {
      icon: 'user-group',
      description:
        'Conecte seu sistema de gestão escolar à Agenda Edu (IScholar, TOTVS, SophiA...).',
    },
  ],
});
