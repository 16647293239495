import React from 'react';
import { useTheme } from 'styled-components';

import Text from 'components/Text';

import { CardProps } from './types';

import * as S from './styles';

const Card = ({
  title,
  subtitle,
  timestamp,
  tag,
  avatar,
  dropdown,
  overlay,
  hidden,
}: CardProps) => {
  const theme = useTheme();

  return (
    <S.Container hidden={hidden}>
      <S.Content>
        <S.Profile>
          {overlay && <S.ProfileOverlay />}

          {avatar && avatar}

          <S.Message>
            <S.Header>
              <Text
                element="h6"
                font="Quicksand"
                size={16}
                weight="500"
                color={theme.primaryColor}
              >
                {title}
              </Text>
              <S.Subheader>
                <Text element="span" size={12} weight="400" color={theme.gray2}>
                  {timestamp}
                </Text>
                {tag && tag}
              </S.Subheader>
            </S.Header>
            <Text element="span" size={16} weight="400" color={theme.black}>
              {subtitle}
            </Text>
          </S.Message>
        </S.Profile>

        {dropdown && dropdown}
      </S.Content>
    </S.Container>
  );
};

export default Card;
