import React from 'react';
import { useTranslation } from 'react-i18next';

import withAppContext from 'core/hoc/withAppContext';

import { Button } from '@agendaedu/ae-web-components';
import { Modal } from 'components/Handouts/Modal';

import { ErrorAttachemntModalProps } from './types';

const ErrorAttachmentModal = ({
  isOpen,
  attachmentError,
  currentUserType,
  handleOnClose,
}: ErrorAttachemntModalProps) => {
  const { t } = useTranslation(['messages']);

  const typeMapping = {
    type: {
      title: t('omni_channel.modals.error_attachment.type_error.title'),
      message: {
        User: t('omni_channel.modals.error_attachment.type_error.message.user'),
        SchoolUser: t(
          'omni_channel.modals.error_attachment.type_error.message.school_user'
        ),
      },
    },
    size: {
      title: t('omni_channel.modals.error_attachment.size_error.title'),
      message: {
        User: t('omni_channel.modals.error_attachment.size_error.message.user'),
        SchoolUser: t(
          'omni_channel.modals.error_attachment.size_error.message.school_user'
        ),
      },
    },
  };

  return (
    <Modal.Root
      isOpen={isOpen}
      title={
        attachmentError.length > 1
          ? t('omni_channel.modals.error_attachment.multiple_errors')
          : typeMapping[attachmentError[0]].title
      }
      handleToggleModal={handleOnClose}
    >
      <Modal.Content>
        {attachmentError.map((currentError) => (
          <p key={currentError}>
            {typeMapping[currentError].message[currentUserType]}
          </p>
        ))}
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={handleOnClose}>
          {t('omni_channel.modals.error_attachment.submit_button')}
        </Button>
      </Modal.Actions>
    </Modal.Root>
  );
};

export default withAppContext(ErrorAttachmentModal);
