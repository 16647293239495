import { ThemeProps } from 'config/themes/types';
import styled, { css } from 'styled-components';

export const MessageChannelFormWrapper = styled.main`
  ${({ theme: { space, border, breakpoints } }: ThemeProps) => css`
    .WizardForm .wizard-content .wizard-taps-header .PageTitle {
      min-width: fit-content;
    }

    .FormFullScreen {
      overflow: auto;
    }

    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .FormSelector {
      display: flex;
      flex-direction: column;
      width: 100%;

      @media (max-width: ${breakpoints.tabletMD}) {
        flex-direction: column;

        .container-wrap {
          &:first-child {
            display: none;
          }
        }
      }

      .container-wrap {
        display: flex;
        flex-direction: row;
        width: 100%;

        @media (max-width: ${breakpoints.desktopLG}) {
          flex-direction: column;
        }

        @media (max-width: ${breakpoints.tabletMD}) {
          flex-direction: column;
          gap: ${space.xl3};
        }

        .item-wrap {
          width: 100%;

          .selectComponent {
            height: 325px;
            border-radius: ${border.radius.md};

            .SelectableOption {
              div {
                display: flex;
                flex-direction: row;
                gap: ${space.sm};
              }
            }
          }
        }
      }
    }

    .GroupSelector {
      .container-wrap {
        width: 100%;
        .search-addon {
          width: 80px;
        }

        @media (min-width: ${breakpoints.desktopLG}) {
          gap: ${space.xs};
        }

        .options-column {
          width: 100%;
        }
        .selected-column {
          width: 100%;
        }
      }
    }
  `}
`;

export const FormSkeletonWrapper = styled.div`
  ${({ theme: { breakpoints, colors, space } }: ThemeProps) => css`
    height: 100%;
    max-width: 100%;
    padding: ${space.xs4} 0;
    margin: ${space.xl3} 0;
    background-color: ${colors.neutral.white};

    @media only screen and (min-width: ${breakpoints.desktopLG}) {
      margin: ${space.xl3} 0;
    }

    @media only screen and (min-width: ${breakpoints.desktopXL}) {
      padding: ${space.xs4} 0;
    }
  `}
`;

export const FormSkeletonInputs = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    flex-direction: row;
    gap: ${space.xs};
  `}
`;
