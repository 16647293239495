import styled, { css } from 'styled-components';

import { ThemeProps } from 'config/themes/types';

export const InforWrapper = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    flex-direction: column;
    gap: ${space.sm};
  `}
`;

export const SectionWrapper = styled.section`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    flex-direction: column;
    gap: ${space.xs2};
  `}
`;

export const ColumnWrapper = styled.section`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    flex-direction: row;
    gap: ${space.sm};
  `}
`;

export const DaysWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const NameOfTheDays = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

type DayBtnProps = {
  isSelected: boolean;
} & ThemeProps;

export const DayBtn = styled.div<DayBtnProps>`
  ${({ isSelected, theme: { colors, typography } }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    color: ${colors.brand.primary.default};
    background-color: ${colors.brand.primary.op10};
    ${typography.subtitle.Medium16}

    ${isSelected &&
    css`
      color: ${colors.neutral.white};
      background-color: ${colors.brand.primary.default};
    `}
  `}
`;

export const ButtonWrapper = styled.section`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    flex-direction: row;
    gap: ${space.sm};
    margin-top: ${space.lg};

    > Button {
      width: 100%;
    }
  `}
`;
