import styled, { css } from 'styled-components';

import { ThemeProps } from 'config/themes/types';

type AnswerColumnType = {
  theme: ThemeProps;
  hasMood: boolean;
};

export const boxProps = {
  display: 'flex',
  alignItems: 'center',
  height: '100%',
};

export const CardContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const DescriptionColumn = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    flex-direction: column;
    margin-left: ${space.xs};
  `}
`;

export const AnswerColumn = styled.div<AnswerColumnType>`
  ${({ theme: { space }, hasMood }: AnswerColumnType) => css`
    display: flex;
    flex-direction: column;
    margin-left: ${hasMood ? space.xs : space.xl3};
  `}
`;
