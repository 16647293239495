import React, { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as S from './styles';

import { Button, TimePicker } from '@agendaedu/ae-web-components';
import { Modal } from 'components/Handouts/Modal';
import Tooltip, { TOP_CENTER } from 'components/Tooltip';

import { isAfterHours } from 'core/helper/message/omni/attendingHours';
import omniChannelActions from 'store/messages/omniChannel/actions';
import { OmniChannelState } from 'store/messages/omniChannel/types';

import {
  DaysOfTheWeek,
  PickerDayAndHours,
  PickerDayAndHoursModal,
} from './types';

export const PickerOpeningHoursModal = ({
  handleSubmit,
  initialDays,
}: PickerDayAndHoursModal): JSX.Element => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['messages', 'common']);

  const { toggleOpeningHoursModal } = omniChannelActions;

  const [pickedDaysAndHours, setPickedDaysAndHours] =
    useState<PickerDayAndHours>({
      openingHour: null,
      closeHour: null,
      daysOfTheWeek: initialDays,
    });

  const { showOpeningHoursModal } = useSelector(
    (state: OmniChannelState) => state.omniChannel
  );

  const daysOfTheWeek = [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
  ];

  const hasDaySelectd = (days: DaysOfTheWeek): boolean => {
    return Object.values(days).some((value) => value === true);
  };

  const isAfterPickerHours = isAfterHours([
    `${pickedDaysAndHours.openingHour}`,
    `${pickedDaysAndHours.closeHour}`,
  ]);

  const isDisabledBtn =
    isAfterPickerHours ||
    !pickedDaysAndHours.openingHour ||
    !pickedDaysAndHours.closeHour ||
    !hasDaySelectd(pickedDaysAndHours.daysOfTheWeek);

  const handleToggleOpeningHoursModal = useCallback(() => {
    dispatch(toggleOpeningHoursModal());
  }, [dispatch, toggleOpeningHoursModal]);

  const handleClearPickHour = () =>
    setPickedDaysAndHours({
      openingHour: '',
      closeHour: '',
      daysOfTheWeek: initialDays,
    });

  const handleOnSubmit = () => {
    handleSubmit(pickedDaysAndHours);
    handleToggleOpeningHoursModal();
  };

  useEffect(() => {
    setPickedDaysAndHours({
      openingHour: null,
      closeHour: null,
      daysOfTheWeek: initialDays,
    });
  }, [initialDays]);

  return (
    <>
      {showOpeningHoursModal && (
        <Modal.Root
          maxWidth="400px"
          dataTestId="omni-picker-hour"
          title={t('omni_channel.modals.picker_hour.title')}
          handleToggleModal={handleToggleOpeningHoursModal}
          isOpen
        >
          <Modal.Content>
            <Modal.Content>
              <S.InforWrapper>
                <S.DaysWrapper>
                  <S.SectionWrapper>
                    {t('omni_channel.modals.picker_hour.select_days')}
                    <S.NameOfTheDays>
                      {daysOfTheWeek.map((day) => (
                        <Tooltip
                          key={day}
                          content={t(`common:days_full_name.${day}`)}
                          position={TOP_CENTER}
                        >
                          <S.DayBtn
                            data-testId={`${day}-btn`}
                            isSelected={pickedDaysAndHours.daysOfTheWeek[day]}
                            onClick={() =>
                              setPickedDaysAndHours({
                                ...pickedDaysAndHours,
                                daysOfTheWeek: {
                                  ...pickedDaysAndHours.daysOfTheWeek,
                                  [day]: !pickedDaysAndHours.daysOfTheWeek[day],
                                },
                              })
                            }
                          >
                            {t(`common:days.${day}`)}
                          </S.DayBtn>
                        </Tooltip>
                      ))}
                    </S.NameOfTheDays>
                  </S.SectionWrapper>
                </S.DaysWrapper>

                <S.ColumnWrapper>
                  <TimePicker
                    data-testId="opening_hour"
                    label={t('omni_channel.modals.picker_hour.opening_hour')}
                    placeholder={t(
                      'omni_channel.modals.picker_hour.default_hour'
                    )}
                    value={pickedDaysAndHours.openingHour}
                    onChange={(value: string) =>
                      setPickedDaysAndHours({
                        ...pickedDaysAndHours,
                        openingHour: value,
                      })
                    }
                  />
                  <TimePicker
                    data-testId="close_hour"
                    label={t('omni_channel.modals.picker_hour.close_hour')}
                    placeholder={t(
                      'omni_channel.modals.picker_hour.default_hour'
                    )}
                    value={pickedDaysAndHours.closeHour}
                    error={isAfterPickerHours}
                    errorMessage={t('omni_channel.forms.after_hours')}
                    onChange={(value: string) =>
                      setPickedDaysAndHours({
                        ...pickedDaysAndHours,
                        closeHour: value,
                      })
                    }
                  />
                </S.ColumnWrapper>
              </S.InforWrapper>
            </Modal.Content>
            <S.ButtonWrapper>
              <Button
                testId="cancel-delete-button"
                variant="secondary"
                onClick={handleClearPickHour}
              >
                {t('omni_channel.modals.picker_hour.button_clear')}
              </Button>

              <Button
                testId="submit-delete-button"
                disabled={isDisabledBtn}
                onClick={handleOnSubmit}
              >
                {t('omni_channel.modals.picker_hour.button_apply')}
              </Button>
            </S.ButtonWrapper>
          </Modal.Content>
        </Modal.Root>
      )}
    </>
  );
};
