import axios from 'axios';

export default class PaymentsTransfersService {
  constructor(dataArea) {
    this.dataArea = dataArea;
  }

  async create(params) {
    try {
      const { data } = await axios.post(`/${this.dataArea}/transfers`, params);
      return data.data;
    } catch(error) {
      throw error;
    }
  }
}
