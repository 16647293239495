import styled, { css } from 'styled-components';

import ModalContainer from 'components/Modal/ModalContainer';

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MessagesModal = styled(ModalContainer)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;

    .ButtonRow {
      .Button {
        &:last-child {
          color: ${theme.white};
          background-color: ${theme.danger};
          border-color: ${theme.danger};

          &:hover {
            background-color: rgba(255, 108, 96, 0.8);
            border-color: rgba(255, 108, 96, 0.8);
            color: ${theme.white};
          }

          &:active {
            background-color: rgba(255, 108, 96, 0.8);
            border-color: rgba(255, 108, 96, 0.8);
            color: ${theme.white};
          }
        }
      }
    }
  `}
`;

export const Description = styled.div`
  ${({ theme }) => css`
    display: flex;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: start;
    color: ${theme.gray2};
  `}
`;
