import React from 'react';
import ContentLoader from 'react-content-loader';

import * as S from './styles';

const StudentListSkeleton = () => {
  return (
    <S.MainContainer>
      <ContentLoader
        width="100%"
        height="100%"
        speed={2}
        viewBox="0 0 400 800"
        ariaLabel="Carregando..."
      >
        <rect x="0" y="10" rx="2" ry="2" width="400" height="30" />
        <rect x="0" y="50" rx="2" ry="2" width="400" height="30" />
        <rect x="0" y="90" rx="2" ry="2" width="400" height="30" />
        <rect x="0" y="130" rx="2" ry="2" width="400" height="30" />
        <rect x="0" y="170" rx="2" ry="2" width="400" height="30" />
        <rect x="0" y="210" rx="2" ry="2" width="400" height="30" />
      </ContentLoader>
    </S.MainContainer>
  );
};

export default StudentListSkeleton;
