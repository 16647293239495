import React from 'react';

import Toast from 'components/Toast';
import withAppContext from 'core/hoc/withAppContext';
import ContractModelFormInformationsTab from '../ContractModelForm/ContractModelFormInformationsTab';
import FormFullScreen from 'components/FormFullScreen';
import { useTranslation } from 'react-i18next';
import normalizeParams from './normalizeParams';
import { useDispatch, useSelector } from 'react-redux';
import actionEnrollmentPlans from 'store/edupay/enrollmentPlans/actions';
import { RootState } from './types';

const NewContractModel = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['enrollments']);
  const { addContractModelRequest } = actionEnrollmentPlans;

  const { sending } = useSelector((state: RootState) => state.enrollmentPlans);

  const handleSubmit = (formContext) => {
    const data = normalizeParams(formContext.form);
    dispatch(addContractModelRequest(data));
  };

  return (
    <>
      <FormFullScreen
        action="new"
        steps={[ContractModelFormInformationsTab]}
        form={{}}
        formMeta={{
          nextStepRequiredFields: ['title', 'attachment', 'variables'],
        }}
        isSubmitting={sending}
        onSubmit={handleSubmit}
        backTo={`/schools/enrollment_plans/contract_templates`}
        titlePage={t('common.new_model')}
        titleModal={t('form.contract_model.discard')}
        contentModal={t('form.contract_model.discard_content')}
      />
      <Toast />
    </>
  );
};

export default withAppContext(NewContractModel);
