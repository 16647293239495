import React from 'react';
import EmptyState from '..';
import { OnboardingEmptyStateProps } from './types';
import * as S from './styles';

const OnboardingEmptyState = ({
  imgUrl,
  title,
  text,
  buttons,
  spaceBetween,
}: OnboardingEmptyStateProps) => {
  const emptyStateContent = () => {
    return (
      <S.Content>
        <S.Title>{title}</S.Title>
        <S.Text>{text}</S.Text>
        {buttons && buttons}
      </S.Content>
    );
  };

  return (
    <S.Container className="OnboardingEmptyState">
      <EmptyState
        alignment="horizontally"
        imgUrl={imgUrl}
        imgWidth="408px"
        message={emptyStateContent()}
        centered
        spaceBetween={spaceBetween}
      />
    </S.Container>
  );
};

export default OnboardingEmptyState;
