import styled, { css } from 'styled-components';
import { ThemeProps } from 'config/themes/types';
import { Text } from '@agendaedu/ae-web-components';

export const SchoolBillingInformationWrapper = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    flex-direction: column;

    .Alert {
      height: auto;
      margin-bottom: ${space.xl2};
      padding: ${space.sm} ${space.lg} ${space.sm} ${space.sm};

      .alert-message {
        display: flex;
        align-items: center;
      }
    }
  `}
`;

export const WrapperIcon = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    width: ${space.lg};
    display: flex;
    align-items: center;
    justify-content: center;
  `}
`;

export const AllInformationWrapper = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    gap: ${space.xl2};
    margin-bottom: ${space.xl};

    @media (max-width: 1150px) {
      flex-direction: column;
    }
  `}
`;

export const SchoolWrapper = styled.div`
  ${({ theme: { colors } }: ThemeProps) => css`
    ${({ theme: { space, border } }: ThemeProps) => css`
      width: 440px;
      display: flex;
      flex-direction: column;
      border: ${border.width.sm} ${border.style.solid} ${colors.neutral.gray3};
      border-radius: ${space.xs2};
      padding: ${space.lg};

      @media (max-width: 650px) {
        width: auto;
      }
    `}
  `}
`;

export const InformationsTitle = styled(Text)`
  ${({ theme: { colors, space } }: ThemeProps) => css`
    font-weight: 700 !important;
    margin-bottom: ${space.sm};
    color: ${colors.neutral.black};
  `}
`;

export const InformationsSubtitle = styled(Text)`
  ${({ theme: { colors, space } }: ThemeProps) => css`
    font-weight: 700 !important;
    margin-bottom: ${space.xl};
    color: ${colors.neutral.black};
  `}
`;

export const InformationWrapper = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;

    &:not(:last-child) {
      margin-bottom: ${space.sm};
    }
  `}
`;

export const InformationsText = styled(Text)`
  ${({ theme: { colors, font } }: ThemeProps) => css`
    color: ${colors.neutral.gray1};
    font-weight: 700 !important;
    line-height: ${font.height.lg} !important;
  `}
`;

export const BankWrapper = styled.div`
  ${({ theme: { colors, space, border } }: ThemeProps) => css`
    width: 438px;
    display: flex;
    flex-direction: column;
    border: ${border.width.sm} ${border.style.solid}
      ${colors.brand.primary.default};
    border-radius: ${space.xs2};
    padding: ${space.lg};
    height: min-content;

    @media (max-width: 650px) {
      width: auto;
    }
  `}
`;

export const BankTitle = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    align-items: center;
    gap: ${space.xs2};
    margin-bottom: ${space.xl};

    > img {
      width: ${space.sm};
      height: ${space.sm};
    }
  `}
`;
