import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as S from './styles';

import { Button } from '@agendaedu/ae-web-components';
import { Modal } from 'components/Handouts/Modal';
import MessagesAttachment from 'components/Messages/Attachment';

import OmniChannelActions from 'store/messages/omniChannel/actions';
import { OmniChannelState } from 'store/messages/omniChannel/types';

import { AttachmentModalProps } from './types';

export const AttachemntModal = ({
  attachmentFile,
  handleOnClose,
}: AttachmentModalProps) => {
  const { t } = useTranslation(['messages']);
  const dispatch = useDispatch();

  const [attachmentMessage, setAttachmentMessage] = useState('');

  const {
    activeChannel,
    activeChat,
    showAttachmentModal,
    chatIsSearchTab,
    activeChatUser,
  } = useSelector((state: OmniChannelState) => state.omniChannel);

  const hasChat = !chatIsSearchTab && !!activeChat;

  const {
    setCreateNewMessageRequest,
    setCreateNewChatUserRequest,
    toggleAttachmentModal,
  } = OmniChannelActions;

  const handleToggleAttachmentModal = useCallback(() => {
    dispatch(toggleAttachmentModal());
  }, [dispatch, toggleAttachmentModal]);

  const handleOnSubmitAttachment = useCallback(() => {
    const params = {
      chatId: activeChat.id,
      channelId: activeChannel.id,
      message: attachmentMessage,
      studentProfileId: activeChat.attributes.student_profile_id,
      userId: activeChat.relationships.main_user.data.id,
      userType: activeChat.relationships.main_user.data.type,
      chatKind: activeChat.attributes.kind,
      ticketId: activeChat.relationships.ticket?.id || null,
      attachedMessageId: null,
      attachment: attachmentFile,
    };

    dispatch(setCreateNewMessageRequest(params));
    setAttachmentMessage('');
    handleOnClose();
  }, [
    activeChannel,
    activeChat,
    dispatch,
    attachmentMessage,
    handleOnClose,
    setCreateNewMessageRequest,
    attachmentFile,
  ]);

  const handleCreateNewChat = useCallback(() => {
    const params = {
      channelId: activeChannel.id,
      message: attachmentMessage,
      recipient: [
        {
          kind: activeChatUser.attributes.kind,
          student_profile_id:
            activeChatUser.type === 'student_profile'
              ? activeChatUser.id
              : activeChatUser.attributes.student_id,
          user: {
            id: activeChatUser.id,
            type: activeChatUser.type,
          },
        },
      ],
      attachment: attachmentFile,
    };
    handleToggleAttachmentModal();

    dispatch(setCreateNewChatUserRequest(params));
  }, [
    activeChannel,
    activeChatUser,
    attachmentFile,
    attachmentMessage,
    dispatch,
    setCreateNewChatUserRequest,
    handleToggleAttachmentModal,
  ]);

  return (
    <Modal.Root
      isOpen={showAttachmentModal}
      title={t('omni_channel.modals.attchemnt_message.title')}
      handleToggleModal={handleOnClose}
    >
      <Modal.Content>
        <S.ContentWrapper>
          <MessagesAttachment variation="sent" attachment={attachmentFile} />

          <S.TextAreaWrapper>
            <S.TextAreaLabel variant="label-regular-14">
              {t(
                'omni_channel.forms.message_channel.fields.new_message_text_area_label'
              )}
            </S.TextAreaLabel>
            <S.TextArea
              value={attachmentMessage}
              onChange={({ target: { value } }) => setAttachmentMessage(value)}
            />
          </S.TextAreaWrapper>
        </S.ContentWrapper>
      </Modal.Content>
      <Modal.Actions>
        <Button variant="secondary" onClick={handleOnClose}>
          {t('omni_channel.modals.attchemnt_message.cancel_button')}
        </Button>
        <Button
          disabled={!attachmentMessage}
          onClick={hasChat ? handleOnSubmitAttachment : handleCreateNewChat}
        >
          {t('omni_channel.modals.attchemnt_message.submit_button')}
        </Button>
      </Modal.Actions>
    </Modal.Root>
  );
};
