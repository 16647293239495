import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import autobind from 'autobind-decorator';

import AgendaIcon from 'components/AgendaIcon';
import MessagesOtherUsersChannelCard from 'components/Messages/OtherUsers/ChannelCard';

import withAppContext from 'core/hoc/withAppContext';

import colorWithAlpha from 'core/utils/colorWithAlpha';

import './style.scss';

class MessagesOtherUsersChannelSideBarList extends Component {
  static propTypes = {
    appContext: PropTypes.shape({
      primaryColor: PropTypes.string.isRequired,
    }).isRequired,
    activeChannelKind: PropTypes.string.isRequired,
    errorFetchingChannel: PropTypes.any,
    channels: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
      })
    ),
    onChannelSelect: PropTypes.func.isRequired,
    userChats: PropTypes.array.isRequired,
  };

  state = {
    scrollabeNodeStyle: null,
  };

  listStyle = {
    listGroupItemHover: {
      backgroundColor: colorWithAlpha(this.props.appContext.primaryColor, 0.1),
      color: this.props.appContext.primaryColor,
      fontWeight: 'bold',
      display: 'block',
    },
  };

  scrollabeNode = null;

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
    this.setScrollabeNodeStyle();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  /*
   * This is needed to show the scroll bar because scroll bars need height to be
   * set.
   */
  setScrollabeNodeStyle() {
    if (!this.scrollabeNode) return;
    const { top } = this.scrollabeNode.getBoundingClientRect();
    const scrollabeNodeStyle = { height: window.innerHeight - top };
    this.setState({ scrollabeNodeStyle });
  }

  @autobind
  setScrollabeNode(node) {
    this.scrollabeNode = node;
  }

  @autobind
  handleWindowSizeChange() {
    this.setScrollabeNodeStyle();
  }

  renderEmptyList() {
    const { activeChannelKind } = this.props;

    const textMapping = {
      family: {
        text: 'Não há canais família para responder',
      },
      private: {
        text: 'Não há canais privados para responder',
      },
    };

    return (
      <Fragment>
        <AgendaIcon name="message" size="large" />
        <span>{textMapping[activeChannelKind].text}</span>
      </Fragment>
    );
  }

  getMessagesNotSeenCount(channel) {
    const { activeChannelKind, userChats } = this.props;

    const userChat = userChats.find(
      (userChat) =>
        userChat.attributes.channel_id === channel.id &&
        userChat.attributes.kind == activeChannelKind
    );

    if (userChat) {
      return userChat.attributes.messages_not_seen_count;
    }
  }

  render() {
    const {
      appContext: { dataArea },
      activeChannelKind,
      channels,
      errorFetchingChannel,
      onChannelSelect,
    } = this.props;

    const { scrollabeNodeStyle } = this.state;

    const { listGroupItemHover } = this.listStyle;

    if (errorFetchingChannel) throw errorFetchingChannel;

    return (
      <div className="MessagesOtherUsersChannelSideBarList">
        <ul
          className="list-group"
          ref={this.setScrollabeNode}
          style={scrollabeNodeStyle}
        >
          {channels.length > 0 ? (
            <div>
              {channels.map((channel) => (
                <li key={`${activeChannelKind}-${channel.id}`}>
                  <NavLink
                    to={`/${dataArea}/messages/channels/${channel.id}/messages/${activeChannelKind}`}
                    activeClassName="selected"
                    activeStyle={listGroupItemHover}
                    onClick={() => onChannelSelect(channel)}
                  >
                    <MessagesOtherUsersChannelCard
                      channel={channel}
                      unreadCount={this.getMessagesNotSeenCount(channel)}
                    />
                  </NavLink>
                </li>
              ))}
            </div>
          ) : (
            <div className="empty">{this.renderEmptyList()}</div>
          )}
        </ul>
      </div>
    );
  }
}

export default withAppContext(MessagesOtherUsersChannelSideBarList);
