import React from 'react';
import PropTypes from 'prop-types';

import AlertContext from 'core/contexts/Alert';
import TabsBoxContainer from 'components/TabsBoxContainer';
import Toast from 'components/Toast';
import SchoolProductInformationsTab from 'components/Payments/SchoolProductInformationsTab';
import SchoolProductOrdersTab from 'components/Payments/SchoolProductOrdersTab';
import SchoolProductHistoryTab from 'components/Payments/SchoolProductHistoryTab';

const BREADCRUMB = { title: 'Pagamentos', path: '/schools/school_products' };

function SchoolProductDetail(props) {
  const {
    alert,
    match: {
      params: { id },
    },
  } = props;
  const schoolProductId = id;
  const alertValue = { alert };
  const tabs = [
    {
      Component: SchoolProductInformationsTab,
      props: { schoolProductId },
    },
    {
      Component: SchoolProductOrdersTab,
      props: { schoolProductId },
    },
    {
      Component: SchoolProductHistoryTab,
      props: { schoolProductId },
    },
  ];

  return (
    <div className="PaymentsDetail">
      <AlertContext.Provider value={alertValue}>
        <TabsBoxContainer
          breadcrumb={BREADCRUMB}
          title="Detalhes da cobrança"
          tabs={tabs}
        />
        <Toast />
      </AlertContext.Provider>
    </div>
  );
}

SchoolProductDetail.defaultProps = {
  alert: '',
};

SchoolProductDetail.propTypes = {
  alert: PropTypes.string,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default SchoolProductDetail;
