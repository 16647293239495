import styled, { css } from 'styled-components';
import { ThemeProps } from 'config/themes/types';

export const ContainerGrayBox = styled.div`
  ${({ theme: { colors, border, shadow, typography } }: ThemeProps) => css`
    width: 100%;
    display: flex;
    flex-direction: row;
    overflow-x: scroll;

    &::-webkit-scrollbar {
      background-color: ${colors.neutral.white};
      width: 1px;
      border-radius: ${border.radius.md};
    }
    &::-webkit-scrollbar-button {
      display: none;
    }
    &::-webkit-scrollbar-track {
      background-color: ${colors.neutral.white};
      border-radius: ${border.radius.md};
    }
    &::-webkit-scrollbar-thumb {
      background: transparent;
      border-radius: ${border.radius.md};
      border: ${border.width.sm};
    }
  `}
`;

export const GrayBox = styled.div`
  ${({ theme: { colors, border, space } }: ThemeProps) => css`
    display: inline-block;
    min-width: 574px;
    border-radius: ${border.radius.md};
    border: ${border.width.sm} solid ${colors.neutral.gray4};
    padding: ${space.lg};
  `}
`;

export const BoxInfo = styled.div`
  width: 100%;
`;

export const BoxLimit = styled.div`
  width: 607px;
  margin-left: auto;
  margin-right: auto;
`;

export const GrayLine = styled.div`
  ${({ theme: { colors, border, space } }: ThemeProps) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: ${border.width.sm} solid ${colors.neutral.gray4};
    margin: 0px -${space.lg} ${space.lg} -${space.lg};
  `}
`;

export const TextContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const TextContainerResume = styled.div`
  display: flex;
  justify-content: space-between;
  width: 557px;
`;

export const WrapperContainer = styled.div`
  ${({ theme: { space, breakpoints } }: ThemeProps) => css`
    @media screen and (min-width: ${breakpoints.tabletMD}) {
      width: 607px;
      margin-left: auto;
      margin-right: auto;
    }

    @media screen and (max-width: ${breakpoints.tabletMD}) {
      margin-left: auto;
      margin-right: auto;
      padding-left: ${space.md};
      padding-right: ${space.md};
    }

    .row {
      margin-right: 0px !important;
      margin-left: 0px !important;
    }

    .GroupSelector {
      margin-right: auto;
      width: auto;
    }
  `}
`;

export const TitleTotal = styled.p`
  ${({ theme: { colors, typography } }: ThemeProps) => css`
    ${typography.title.Bold20};
    color: ${colors.brand.primary.default};
  `}
`;

export const ParagraphResume = styled.p`
  ${({ theme: { colors, typography, space } }: ThemeProps) => css`
    ${typography.subtitle.Medium14};
    color: ${colors.neutral.gray1};
    margin-bottom: ${space.lg};
  `}
`;

export const TitleResume = styled.p`
  ${({ theme: { colors, typography, space } }: ThemeProps) => css`
    ${typography.title.Bold12};
    color: ${colors.neutral.gray1};
    margin-top: ${space.lg};
    margin-bottom: ${space.lg};
  `}
`;
