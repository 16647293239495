import React from 'react';
import * as S from './styles';
import { ToggleBatchProps } from './types';

import ToggleSwitch from 'components/Form/ToggleSwitch';
import withFormContext from 'core/hoc/withFormContext';

const ToggleBatch = ({
  title,
  attributeName,
  subComponent,
  initialValue,
  formContext: { form, updateAttribute },
}: ToggleBatchProps) => {
  const toggleSwitch = (): void => {
    if (form[attributeName]) updateAttribute(subComponent, initialValue);
    updateAttribute(attributeName, !form[attributeName]);
  };

  return (
    <S.TogglesBatchsWrapper data-testid={`${attributeName}ToggleWrapper`}>
      <ToggleSwitch toggled={form[attributeName]} onChange={toggleSwitch} />
      <S.ToggleTitle data-testid={`${attributeName}Title`}>
        {title}
      </S.ToggleTitle>
    </S.TogglesBatchsWrapper>
  );
};

export default withFormContext(ToggleBatch);
