import React, { useCallback, useState, useEffect } from 'react';
import { Tag } from '@agendaedu/ae-web-components';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { NEGOTIATION_STATUS } from 'core/constants';
import { CAN_GENERATE_BILLET_OR_PIX_BY_RECURRENT_STATUS } from 'core/constants/index';
import actionRecurrentBills from 'store/edupay/recurrentBills/actions';
import { flags } from 'core/constants/flags';

import LabeledContainer from 'components/LabeledContainer';
import DropdownButton from 'components/DropdownButton';
import HandleModalByMethod from 'components/Payments/HandleModalByMethod';
import LinkModal from 'components/Payments/LinkModal';
import Toast from 'components/Toast';
import { NegotiationCard } from '../../NegotiationCard';

import { NegotiationAccordionPanelProps, RootState } from './types';
import * as S from './styles';

export const NegotiationAccordionPanel = ({
  dueDate,
  priceCents,
  status,
  allowedPaymentMethod,
  planId,
  kind,
  recurrentBillId,
  order_id,
}: NegotiationAccordionPanelProps) => {
  const dispatch = useDispatch();

  const { t } = useTranslation(['recurrent_plan']);
  const {
    paymentModalByMethod,
    isLoadingManuallyPaid,
    paymentLinkModalByMethod,
    errorMessage,
  } = useSelector((state: RootState) => state.recurrentBills);

  const order = useSelector(
    (state: RootState) => (state.recurrentBills.current || {}).order
  );
  const studentProfile = useSelector(
    (state: RootState) => (state.recurrentBills.current || {}).studentProfile
  );
  const responsibleProfiles = useSelector(
    (state: RootState) =>
      (state.recurrentBills.current || {}).responsibleProfiles
  );

  const {
    releaseRecurrentBill,
    toggleBilletOrPixModal,
    unsetBill,
    setRecurrentBill,
    loadInformationToPaid,
    checkout,
    toggleBilletOrPixLinkModal,
    fetchRecurrentBills,
  } = actionRecurrentBills;

  const policies = useSelector((state) => state.root.policies);

  const pixIssuedAtSchoolProductAndNegotiationLDFlag =
    policies.core_banking_flags.includes(
      flags.PIX_ISSUED_AT_SCHOOL_PRODUCT_AND_NEGOTIATION
    );

  const [paymentMethod, setPaymentMethod] = useState(null);

  const getBills = useCallback(() => {
    dispatch(fetchRecurrentBills(planId));
  }, [dispatch, fetchRecurrentBills]);

  const releaseBill = useCallback(
    (planId, billId) => {
      dispatch(releaseRecurrentBill(planId, billId));
    },
    [releaseRecurrentBill]
  );

  const togglePaymentMethod = useCallback(() => {
    dispatch(toggleBilletOrPixModal());
    dispatch(unsetBill());
  }, [toggleBilletOrPixModal, unsetBill]);

  const setBill = useCallback(
    (billId) => {
      dispatch(setRecurrentBill(billId));
    },
    [setRecurrentBill]
  );

  const loadInformation = useCallback(
    (planId, biilId) => {
      dispatch(loadInformationToPaid(planId, biilId));
    },
    [loadInformationToPaid]
  );

  const togglePaymentMethodLink = useCallback(() => {
    dispatch(toggleBilletOrPixLinkModal());
    dispatch(unsetBill());
  }, [toggleBilletOrPixLinkModal, unsetBill]);

  const onCheckout = (form) => {
    dispatch(checkout(recurrentBillId, order_id, 'bill', form, paymentMethod));
  };

  useEffect(() => {
    getBills();
  }, [getBills]);

  const getActions = () => {
    const actions = [];
    if (status === 'pending') {
      actions.push({
        text: 'Enviar Cobrança',
        onClick: () => releaseBill(planId, recurrentBillId),
      });
    }
    if (
      allowedPaymentMethod.includes('billet') &&
      CAN_GENERATE_BILLET_OR_PIX_BY_RECURRENT_STATUS.includes(status)
    ) {
      actions.push({
        text: t('list.actions.generate_billet'),
        onClick: () => {
          setPaymentMethod('billet');
          togglePaymentMethod();
          setBill(recurrentBillId);
          loadInformation(planId, recurrentBillId);
        },
      });
    }
    if (
      allowedPaymentMethod.includes('pix') &&
      pixIssuedAtSchoolProductAndNegotiationLDFlag &&
      CAN_GENERATE_BILLET_OR_PIX_BY_RECURRENT_STATUS.includes(status)
    ) {
      actions.push({
        text: t('list.actions.generate_pix'),
        onClick: () => {
          setPaymentMethod('pix');
          togglePaymentMethod();
          setBill(recurrentBillId);
          loadInformation(planId, recurrentBillId);
        },
      });
    }
    if (
      allowedPaymentMethod.includes('billet') &&
      status === 'generated_billet'
    ) {
      actions.push({
        text: t('list.actions.billet_link'),
        onClick: () => {
          setPaymentMethod('billet');
          togglePaymentMethodLink();
          setBill(recurrentBillId);
          loadInformation(planId, recurrentBillId);
        },
      });
    }
    if (
      allowedPaymentMethod.includes('pix') &&
      pixIssuedAtSchoolProductAndNegotiationLDFlag &&
      status === 'generated_pix'
    ) {
      actions.push({
        text: t('list.actions.pix_key'),
        onClick: () => {
          setPaymentMethod('pix');
          togglePaymentMethodLink();
          setBill(recurrentBillId);
          loadInformation(planId, recurrentBillId);
        },
      });
    }

    return actions;
  };

  const isNegotiated = status === 'negotiated';
  const isRegular = kind === 'regular';

  return (
    <>
      <NegotiationCard key={recurrentBillId}>
        <S.Container>
          <LabeledContainer title="ID">
            <p>{recurrentBillId}</p>
          </LabeledContainer>
          <LabeledContainer title="Vencimento">
            <p>{dueDate}</p>
          </LabeledContainer>
          <div className={isNegotiated ? 'DisabledText' : ''}>
            <LabeledContainer title="Total a pagar">
              <p>
                {Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                }).format(priceCents)}
              </p>
            </LabeledContainer>
          </div>
          <LabeledContainer title="">
            <Tag
              name={isRegular ? 'Regular' : 'Acordo'}
              size="small"
              variant={isRegular ? 'neutral' : 'informative'}
            />
          </LabeledContainer>
          <LabeledContainer title="">
            <Tag
              name={NEGOTIATION_STATUS[status]?.name}
              size="small"
              variant={NEGOTIATION_STATUS[status]?.variant}
            />
          </LabeledContainer>
          <DropdownButton
            text=""
            disabled={isNegotiated}
            dropdownItems={getActions()}
            icon="dots-vertical"
            variation="white"
          />
        </S.Container>
      </NegotiationCard>
      <HandleModalByMethod
        method={paymentMethod}
        showModal={paymentModalByMethod}
        toggleModal={togglePaymentMethod}
        isLoading={isLoadingManuallyPaid}
        student={studentProfile}
        responsibles={responsibleProfiles}
        onSubmit={onCheckout}
        checkoutError={errorMessage}
      />

      <LinkModal
        method={paymentMethod}
        showModal={paymentLinkModalByMethod}
        toggleModal={togglePaymentMethodLink}
        responsibles={responsibleProfiles}
        isLoading={isLoadingManuallyPaid}
        order={order}
      />
      <Toast />
    </>
  );
};
