import styled, { css } from 'styled-components';

export const FiltersContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 87px);

  .accordion-title {
    margin: 0;
    font-family: Quicksand;
    font-size: 16px;
  }

  #accordion__heading-panel {
    padding: 16px 24px;
    border-right: 0;
    border-left: 0;
    border-bottom: 0;
    border-radius: 0;
    ${({ theme }) => css`
      background-color: ${theme.white};
      border-top: 1px solid ${theme.gray5};
    `}
  }

  #accordion__panel-panel {
    height: 103px;
    padding: 16px 24px;
    border: 0;

    .Field {
      padding: 0;
      margin-bottom: 26px;

      .Label {
        font-family: Roboto;
        line-height: 1.14;
      }

      .Select .form-control {
        height: 40px;
        font-family: Roboto;
        font-size: 16px;
        border-radius: 6px;
        ${({ theme }) => css`
          color: ${theme.gray7};
        `}
      }
    }
  }
`;

export const AccordionTitle = styled.h2`
  margin: 0;
  font-family: 'Quicksand', sans-serif;
  font-size: 16px;
  ${({ theme }) => css`
    background-color: ${theme.white};
  `}
`;

export const ButtonRow = styled.div`
  display: flex;
  justify-content: space-around;

  .Button {
    width: 50%;
    height: 40px;
    font-family: Quicksand, sans-serif;
    font-size: 16px;
    font-weight: bold;
    border-radius: 6px;
    display: unset;

    &:nth-child(1) {
      margin: 24px 12px 24px 24px;
    }

    &:nth-child(2) {
      margin: 24px 24px 24px 12px;
    }
  }
`;
