import styled, { css } from 'styled-components';
import { ThemeProps } from 'config/themes/types';

export const styledOl = styled.ol`
  ${({ theme: { colors, space, typography } }: ThemeProps) => css`
    ${typography.body.Regular16};
    color: ${colors.neutral.gray1};
    padding-left: ${space.xs};

    > li {
      ${typography.body.Regular16}
      color: ${colors.neutral.gray1};
    }
    > li > b {
      ${typography.body.Regular16}
      color: ${colors.neutral.gray1};
      font-weight: bold;
    }
    > li > a {
      ${typography.body.Regular16}
      color: ${colors.neutral.gray1};
    }
  `}
`;

export const Styledparagraph = styled.p`
  ${({ theme: { border } }: ThemeProps) => css`
    > img {
      border-radius: ${border.radius.md};
    }
    > iframe {
      border-radius: ${border.radius.md};
    }
  `}
`;

export const styledh2 = styled.h2`
  ${({ theme: { colors, typography, font } }: ThemeProps) => css`
    ${typography.headline.H2Bold24};
    color: ${colors.neutral.black};
    font-weight: 700;
    line-height: ${font.height.lg};
  `}
`;
