import createModuleReducer from 'store/storeConfig/createModuleReducer';
import actions from './actions';

export const INITIAL_STATE = {
  periods: [],
  periodDetails: [],
  classrooms: [],
  activatedPeriodClassrooms: [],
  isLoading: true,
  isLoadingEdit: true,
};

export const getListPeriodsSuccess = (state, actions) => ({
  ...state,
  isLoading: false,
  periods: actions.data,
});

export const getListClassroomsSuccess = (state, actions) => ({
  ...state,
  isLoading: false,
  classrooms: actions.data,
});

export const updateIsLoading = (state, action) => ({
  ...state,
  isLoading: action.isLoading,
});

export const updateIsSending = (state) => ({
  ...state,
  isSending: !state.isSending,
});

export const saveNewPeriodSuccess = (state) => ({
  ...state,
  isSending: false,
});

export const deletePeriodSuccess = (state, action) => ({
  ...state,
  periods: state.periods.filter(
    (period: { id: any }) => period.id !== action.periodId
  ),
});

export const getPeriodDetailsSuccess = (state, action) => ({
  ...state,
  periodDetails: action.data,
  isLoadingEdit: action.isLoadingEdit,
});

export const getActivatedPeriodClassroomsSuccess = (state, action) => ({
  ...state,
  activatedPeriodClassrooms: action.data,
  classrooms: action.data,
  isLoadingEdit: false,
});

const HANDLERS = {
  [actions.GET_PERIODS_SUCCESS]: getListPeriodsSuccess,
  [actions.GET_CLASSROOMS_SUCCESS]: getListClassroomsSuccess,
  [actions.SAVE_NEW_PERIOD]: updateIsSending,
  [actions.SAVE_NEW_PERIOD_SUCCESS]: saveNewPeriodSuccess,
  [actions.DELETE_PERIOD_SUCCESS]: deletePeriodSuccess,
  [actions.GET_PERIOD_DETAILS]: updateIsLoading,
  [actions.GET_PERIOD_DETAILS_SUCCESS]: getPeriodDetailsSuccess,
  [actions.GET_ACTIVATED_PERIOD_CLASSROOMS_SUCCESS]:
    getActivatedPeriodClassroomsSuccess,
};

const schoolTerms = createModuleReducer(INITIAL_STATE, HANDLERS);

export default schoolTerms;
