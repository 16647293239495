import React from 'react';
import { useTranslation } from 'react-i18next';

import parse from 'html-react-parser';

import { Icon, Text } from '@agendaedu/ae-web-components';

import * as S from './styles';

export const EmptyState: React.FC = () => {
  const { t } = useTranslation('messages');

  return (
    <S.EmptyStateWrapper data-testid="channel-empty-state">
      <Icon name="message-bubble" />
      <Text
        textAlign={'center'}
        variant="subtitle-medium-16"
        color={'neutral.gray1'}
        lineHeight="lg"
      >
        {parse(t('omni_channel.channel.channels_empty_state'))}
      </Text>
    </S.EmptyStateWrapper>
  );
};
