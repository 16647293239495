import React, { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import parse from 'html-react-parser';
import * as yup from 'yup';

import {
  Box,
  Text,
  TextField,
  Button,
  FlexGrid,
  FlexGridItem,
} from '@agendaedu/ae-web-components';

import HubstoreActions from 'store/hubstore/actions';
import { phoneMask } from 'core/utils/masks.js';

import { RootState } from '../../types';
import { LeadsFormModalProps } from './types';
import * as S from './styles';
import { trackEvent } from 'config/amplitude';

const LeadsFormModal: React.FC<LeadsFormModalProps> = ({
  closeModal,
  isModalOpen,
  formData,
  appName,
}) => {
  const dispatch = useDispatch();

  const { t } = useTranslation(['hub_store']);

  const { registerAppLead } = HubstoreActions;

  const { isSaved } = useSelector((state: RootState) => state.hubstore);
  const [characterCount, setCharacterCount] = useState(0);

  const MODAL_CENTERED = {
    top: '50%',
    left: '50%',
    right: '50%',
    padding: '24px',
    transform: 'translate(-50%, -50%)',
  };

  const modalTitle = () => (
    <Box margin="0 auto">
      <Text
        as="h4"
        m={0}
        variant="title-bold-20"
        textAlign="center"
        fontWeight={700}
        lineHeight="lg"
      >
        {t('leads_form.title', {
          appName,
        })}
      </Text>
    </Box>
  );

  const formInitialValues = {
    name: formData.name,
    email: formData.email,
    phone: '',
    product_id: formData.product_id,
  };

  const onFormSubmit = async (values) => {
    trackEvent('hubstore_modal_form_fills', {
      id: formData.product_id,
      name: appName,
    });

    dispatch(registerAppLead(values));
  };

  const formValidationSchema = yup.object().shape({
    name: yup.string().required(t('leads_form.name.invalid_name_message')),
    email: yup
      .string()
      .email(t('leads_form.email.invalid_email_message'))
      .required(t('leads_form.email.required_email_message')),
    phone: yup
      .string()
      .required(t('leads_form.phone.required_phone_message'))
      .test('', t('leads_form.phone.invalid_phone_message'), (value) => {
        if (value) {
          return value.replace(/\D/g, '').length >= 10;
        } else {
          return true;
        }
      }),
  });

  const {
    isValid,
    values,
    handleChange,
    handleSubmit,
    errors,
    setFieldTouched,
    setFieldValue,
    touched,
    validateForm,
  } = useFormik({
    initialValues: formInitialValues,
    onSubmit: onFormSubmit,
    validationSchema: formValidationSchema,
    validateOnChange: true,
  });

  const onChange = (
    field: string,
    event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>
  ) => {
    setFieldTouched(field, true, false);

    if (field == 'phone') {
      setFieldValue('phone', phoneMask(event.target.value));
    } else {
      handleChange(event);
    }
  };

  const onPhoneKeyUp = (e) => {
    e.target.value = phoneMask(e.target.value);
  };

  useEffect(() => {
    validateForm();
  }, [validateForm]);

  return (
    <S.ModalWrapper
      isOpen={isModalOpen}
      toggleModal={closeModal}
      maxWidth="600px"
      minHeight="538px"
      contentStyles={MODAL_CENTERED}
      title={isSaved ? '' : modalTitle()}
    >
      {isSaved ? (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Box width={200} height={190} mb={24}>
            <img
              src="https://static.agendaedu.com/invite/teamgroup.png"
              alt="Logo"
              height={200}
            />
          </Box>

          <Text
            as="h2"
            variant="title-bold-20"
            textAlign="center"
            color="neutral.black"
            lineHeight="lg"
            fontWeight={700}
            mb={24}
          >
            {t('leads_form.completed_title', {
              appName,
            })}
          </Text>

          <Text
            as="p"
            variant="body-medium-16"
            textAlign="center"
            color="neutral.gray1"
            lineHeight="lg"
          >
            {t('leads_form.completed_subtitle', {
              appName,
            })}
          </Text>
        </Box>
      ) : (
        <>
          <Text
            margin="24px 0"
            as="p"
            variant="body-regular-16"
            textAlign="start"
            lineHeight="lg"
          >
            {parse(
              t('leads_form.text', {
                appName,
              })
            )}
          </Text>

          <Box mb={32}>
            <Box width="100%" mb={16}>
              <TextField
                fullWidth
                id="name"
                type="text"
                label={t('leads_form.name.label')}
                placeholder={t('leads_form.name.placeholder')}
                value={values.name}
                onChange={(e) => onChange('name', e)}
                error={!!(touched.name && errors.name)}
                errorMessage={`${errors.name}`}
              />
            </Box>
            <FlexGrid>
              <FlexGridItem cols={{ desktopLG: 6 }}>
                <Box width="100%" mb={16}>
                  <TextField
                    id="email"
                    type="text"
                    label={t('leads_form.email.label')}
                    placeholder={t('leads_form.email.placeholder')}
                    value={values.email}
                    onChange={(e) => onChange('email', e)}
                    error={!!(touched.email && errors.email)}
                    errorMessage={`${errors.email}`}
                  />
                </Box>
              </FlexGridItem>
              <FlexGridItem>
                <Box width="100%" mb={16}>
                  <TextField
                    id="phone"
                    type="text"
                    label={t('leads_form.phone.label')}
                    placeholder={t('leads_form.phone.placeholder')}
                    value={values.phone}
                    onChange={(e) => onChange('phone', e)}
                    onKeyUp={onPhoneKeyUp}
                    error={!!(touched.phone && errors.phone)}
                    errorMessage={`${errors.phone}`}
                  />
                </Box>
              </FlexGridItem>
            </FlexGrid>

            <Box width="100%">
              <label htmlFor="textarea" className="sc-bYOEQd jDHQxQ">
                {t('leads_form.motivation.label')}
              </label>
              <textarea
                id="motivation"
                maxLength={255}
                placeholder={t('leads_form.motivation.placeholder')}
                value={values.motivation}
                className="SimpleTextArea form-control "
                onChange={(e) => {
                  setCharacterCount(e.target.value.length);
                  onChange('motivation', e);
                }}
              />
              <S.ConteinerCharacterCount>
                {characterCount}/255
              </S.ConteinerCharacterCount>
            </Box>
          </Box>

          <Box display="flex" justifyContent="flex-end">
            <Box mr={16}>
              <Button variant="secondary" onClick={closeModal}>
                {t('leads_form.cancel_button')}
              </Button>
            </Box>
            <Button onClick={handleSubmit} disabled={!isValid}>
              {t('leads_form.send_button')}
            </Button>
          </Box>
        </>
      )}
    </S.ModalWrapper>
  );
};

export default LeadsFormModal;
