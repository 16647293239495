import styled, { css } from 'styled-components';

import { ThemeProps } from 'config/themes/types';
import { Alert, Text } from '@agendaedu/ae-web-components';

export const RowWrapper = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    flex-direction: column;
    gap: ${space.lg};
    padding: ${space.xs4};
    width: 100%;

    .Field {
      display: flex;
      padding: ${space.xs4};
      margin: ${space.xs4} !important;
    }
  `}
`;

export const SelectOptionsWrapper = styled.div`
  ${({ theme: { space, breakpoints } }: ThemeProps) => css`
    display: flex;
    flex-direction: row;
    gap: ${space.sm};

    @media screen and (max-width: ${breakpoints.tabletMD}) {
      flex-direction: column;
      gap: ${space.xs4};
    }
  `}
`;

export const RecipientSelectText = styled(Text)`
  ${({ theme: { colors } }: ThemeProps) => css`
    color: ${colors.neutral.black};
  `}
`;

export const RecipientsAlert = styled(Alert)`
  width: 100% !important;
  justify-content: flex-start !important;
`;
