import styled, { css } from 'styled-components';
import colorWithAlpha from 'core/utils/colorWithAlpha';

export const OfferDetailsInformationsWrapper = styled.div`
  font-family: 'Quicksand';
  .OutlineBox {
    border: none;
  }
`;

export const InfoFlexbox = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    column-gap: 10rem;
    row-gap: 2rem;

    p {
      font-family: 'Roboto';
      color: ${theme.gray1};
      font-weight: 400;
      margin-top: -5px;
    }
  `}
`;

export const InfoIconWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    gap: 12px;

    .detail-title {
      color: ${theme.black} !important;
      font-weight: 700;
    }

    span.icon-wrapper {
      border: none;
      background-color: ${colorWithAlpha(`${theme.primaryColor}`, 0.1)};
      text-align: center;
      vertical-align: middle;
      padding: 6px 12px;
      border-radius: 4px;
      height: 48px;
      display: flex;
      align-items: center;

      .AgendaIcon {
        color: ${theme.primaryColor};
      }
    }
  `}
`;

export const StatusWrapper = styled.div`
  margin-bottom: 24px;
  display: flex;
  justify-content: flex-start;
  ${({ theme }) => css`
    .OutlineBox:not(:last-child) {
      &.warning {
        color: ${theme.warning};
        background-color: ${colorWithAlpha(`${theme.warning}`, 0.1)};
      }

      &.danger {
        color: ${theme.danger};
        background-color: ${colorWithAlpha(`${theme.danger}`, 0.1)};
      }

      &.success {
        color: ${theme.success};
        background-color: ${colorWithAlpha(`${theme.success}`, 0.1)};
      }
      &.default {
        font-weight: 700;
        color: ${theme.gray1};
        background-color: ${colorWithAlpha(`${theme.gray1}`, 0.1)};
      }
    }
  `}
`;

export const PaymentMethodsWrapper = styled.div`
  .methods {
    display: flex;
    justify-content: flex-start;
    margin-top: 8px;
    gap: 16px;
  }
`;

export const DescriptionWrapper = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
`;

export const OfferImgWrapper = styled.div`
  ${({ theme }) => css`
    background: ${theme.gray14};
    max-width: 648px;
    max-height: 413px;
    padding: 16px;
    padding-bottom: 10px;
    border: 1px solid ${theme.gray6};
    border-radius: 6px;
    .offer-img {
      max-width: 616px;
      max-height: 347px;
      img {
        max-height: 347px;
        width: 100%;
        object-fit: cover;
        border-radius: 8px;
        margin-bottom: 15px;
      }
    }

    .offer-img-footer {
      display: flex;
      justify-content: flex-start;
      gap: 5px;
      align-items: center;

      .AgendaIcon {
        color: ${theme.gray2};
      }
      .ColoredText {
        font-family: 'Roboto';
        font-weight: 400;
      }
      .ColoredText:last-of-type {
        margin-left: auto;
        font-weight: 500;
      }
    }
  `}
`;

export const FooterButtonsContainer = styled.div`
  height: 40px;
  .ButtonRow {
    justify-content: space-between;
  }
`;
