import styled, { css } from 'styled-components';
import colorWithAlpha from 'core/utils/colorWithAlpha';

export const Tab = styled.div`
  ${({ theme, clickable }) => css`
    .Button {
      border-radius: 30px;
      cursor: default;
      font-size: 14px;
      padding: 10px 20px;
      font-family: Quicksand, sans-serif;

      background-color: ${
        clickable
          ? colorWithAlpha(`${theme.primaryColor}`, 0.1)
          : theme.primaryColor
      };
      color: ${clickable ? theme.primaryColor : theme.white};
      border-style: none;

      &[disabled] {
        background: #eee;
        color: #aaa;
        border: #eee;
      }

      &:hover {
        background-color: ${
          clickable
            ? colorWithAlpha(`${theme.primaryColor}`, 0.2)
            : colorWithAlpha(`${theme.primaryColor}`, 0.9)
        };
    }
  `}
`;
