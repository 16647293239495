import styled, { css } from 'styled-components';
import { ThemeProps } from 'config/themes/types';

export const Wrapper = styled.div`
  ${({ theme: { colors, border } }: ThemeProps) => css`
    display: flex;
    flex-flow: row wrap;
    flex-direction: row;
    margin-bottom: 40px;
    background-color: ${colors.brand.primary.op10};
    border-radius: ${border.radius.md};
    &::-webkit-scrollbar {
      background-color: ${colors.neutral.white};
      width: 1px;
    }
    &::-webkit-scrollbar-button {
      display: none;
    }
    &::-webkit-scrollbar-track {
      background-color: ${colors.neutral.white};
    }
    &::-webkit-scrollbar-thumb {
      background: transparent;
      border-radius: ${border.radius.md};
      border: ${border.radius.circle};
    }
  `}
`;

export const ContainerText = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    flex-basis: 100%;
  }
`;

export const ContainerImage = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    flex-basis: 100%;
  }
`;

export const Image = styled.img`
  max-width: 500px;
  width: 100%;
  height: auto;
`;

export const Text = styled.p`
  ${({ theme: { typography, colors, font } }: ThemeProps) => css`
    ${typography.subtitle.Medium16};

    color: ${colors.brand.primary.dark};
    ${typography.title.Bold16};
    font-weight: ${font.weight.medium};
    line-height: 27px;
    width: 80%;
    text-justify: inter-word;
  `}
`;

export const Title = styled.p`
  ${({ theme: { typography, colors } }: ThemeProps) => css`
    color: ${colors.brand.primary.default};
    ${typography.title.Bold20};
    font-weight: 700;
    line-height: 34px;
    width: 80%;
    text-align: justify;
    text-justify: inter-word;
  `}
`;
