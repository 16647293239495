import { Chat, StartOrCloseTicket } from 'store/messages/omniChannel/types';

export const normalizeChatTicket = (
  chats: Chat[],
  ticket: StartOrCloseTicket
) => {
  const newChats: Chat[] = [];

  chats.map((chat: Chat) => {
    if (chat.id === ticket.attributes.chat.id) {
      return newChats.push({
        ...chat,
        relationships: {
          ...chat.relationships,
          ticket: {
            ...chat.relationships.ticket,
            attributes: {
              ...chat.relationships.ticket.attributes,
              status: ticket.attributes.status,
            },
          },
        },
      });
    }
    newChats.push(chat);
  });

  return newChats;
};
