/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import SummarydayClassroomsActions from 'store/summarydayClassrooms/actions';

import Breadcrumb from 'components/Breadcrumb';
import InformationsTab from 'components/InformationsTab';
import Button from 'components/Button';
import Avatar from 'components/Avatar';

import Tabs from 'components/Tabs';
import Tab from 'components/Tab';
import Toast from 'components/Toast';

import TabHeader from 'components/InformationsTab/TabHeader';
import TabInfo from 'components/InformationsTab/TabInfo';
import TabActionButtons from 'components/InformationsTab/TabActionButtons';

import History from 'components/History';
import ModalAction from 'components/ModalAction';
import DropdownButton from 'components/DropdownButton';

import FilterContainer from 'components/FilterContainer';
import Field from 'components/Form/Field';
import Tooltip from 'components/Tooltip';
import AgendaIcon from 'components/AgendaIcon';

import ModalContainer from 'components/Modal/ModalContainer';
import ModalTitle from 'components/Modal/ModalTitle';
import ModalBody from 'components/Modal/ModalBody';

import ButtonRow from 'components/ButtonRow';

import InfoData from 'components/InformationsTab/InfoData';
import SkeletonEngagementItem from 'pages/SummarydayClassrooms/components/SkeletonEngagementItem';

import MetricsContainer from 'components/MetricsContainer';
import { IMAGES_CLASSROOMS_URL } from 'core/constants/index';
import EmptyState from 'components/EmptyState';
import SkeletonEngagemenTab from '../components/SkeletonEngagementTab';
import SkeletonDetailsTab from '../components/SkeletonDetailsTab';

import CommentSkeleton from '../components/CommentSkeleton';

import Card from '../components/Card';
import Tag from '../components/Tag';
import ModalHideComment from '../components/ModalHideComment';

import * as S from './styles';

const INITIAL_PAGE = 1;
let COMMENT_ID = 0;

import { composeStorageKey } from 'core/utils/composeStorageKey';
import MemoryStorage from 'core/utils/MemoryStorage';

const tooltipStyle = {
  width: '300px',
  lineHeight: '22px',
};

const Details = ({ match, history, googleClientId }) => {
  const { id: activityId } = match.params;
  const dispatch = useDispatch();

  const [showDetailModal, setDetailModal] = useState(false);
  const [showCommentModal, setCommentModal] = useState(false);
  const [modalNotifyUpdate, setModalNotifyUpdate] = useState(false);
  const [modalVariation, setModalVariation] = useState('');
  const [message, setMessage] = useState('');
  const [selectedSection, setSelectedSection] = useState(defaultSection);

  const onMessageChange = (event) => {
    const { value } = event.target;
    setMessage(value);
  };

  const memoryStorage = new MemoryStorage();

  const {
    fetchClassroomDetails,
    setClassroomApprove,
    fetchClassroomDelete,
    setClassroomDisapprove,
    setClassroomSchedule,
    setClassroomReschedule,
    fetchActivityEngagement,
    fetchActivityEngagementPagination,
    fetchActivityComments,
    fetchActivityCommentsPagination,
    setClassroomResend,
    setClassroomCancelSchedule,
    classroomApproveSchedule,
    addCommentActivity,
    fetchCommentSections,
    fetchTotalComments,
    setUpdateNotification,
  } = SummarydayClassroomsActions;

  const {
    selectedClassroom,
    isLoading,
    isSending,
    engagementActivity,
    commentsActivity,
    defaultSection,
  } = useSelector((state) => state.summarydayClassrooms);

  const toggleDetailModal = (modalVariation) => {
    setDetailModal(!showDetailModal);
    setModalVariation(modalVariation);
  };

  const toggleCommentModal = (modalVariation) => {
    setCommentModal(!showCommentModal);
    setModalVariation(modalVariation);
  };

  const callFetchClassroomsList = (param) => {
    const queryParams = {
      activityId,
      page: INITIAL_PAGE,
      sectionId: param || defaultSection,
    };

    setSelectedSection(queryParams.sectionId);
    dispatch(fetchActivityComments(queryParams));
  };

  const getAvatarAttributes = (avatarColor, avatarUrl, nameInitials) => {
    return {
      attributes: {
        avatar_color: avatarColor,
        avatar_url: avatarUrl,
        name_initials: nameInitials,
      },
    };
  };

  const validCommentTab = (selectedTab) => selectedTab === 1;
  const validEngagementTab = (selectedTab) => selectedTab === 2;

  const selectedTab = ({ selectedTab }) => {
    if (validCommentTab(selectedTab)) {
      callFetchClassroomsList();
      dispatch(fetchCommentSections(activityId));
    }
    if (validEngagementTab(selectedTab)) {
      dispatch(fetchActivityEngagement(activityId, INITIAL_PAGE));
    }
  };

  const onActionModal = (callbackData) => {
    const {
      date,
      time,
      action,
      data: { id },
    } = callbackData;

    const dateTimeSchedule = { date, time };

    switch (callbackData.action) {
      case 'approve':
        dispatch(setClassroomApprove(callbackData.data.id));
        break;

      case 'Delete':
        dispatch(fetchClassroomDelete(callbackData.data));
        break;

      case 'disapprove':
        dispatch(
          setClassroomDisapprove(callbackData.data.id, callbackData.reason)
        );
        break;

      case 'schedule':
        dispatch(setClassroomSchedule(id, dateTimeSchedule, { type: action }));
        break;

      case 'reschedule':
        dispatch(
          setClassroomReschedule(id, dateTimeSchedule, { type: action })
        );
        break;

      case 'resend':
        dispatch(setClassroomResend(callbackData.data.id));
        break;

      case 'cancelSchedule':
        dispatch(setClassroomCancelSchedule(callbackData.data.id));
        break;

      case 'approveSchedule':
        dispatch(classroomApproveSchedule(callbackData.data.id));
        break;

      default:
        break;
    }
  };

  const toggleModalNotify = () => {
    setModalNotifyUpdate(!modalNotifyUpdate);
    memoryStorage.removeLocalStorageItem(composeStorageKey('modal'));
  };

  const hasMore = (state) => {
    const { pagination, data } = state;
    const numberOfItemsOnTheList =
      pagination.currentPage * pagination.itemsCountPerPage;
    return numberOfItemsOnTheList === data.length;
  };

  const fetchMoreData = useCallback(
    (state, type) => {
      const { pagination } = state;
      const page = pagination.currentPage + INITIAL_PAGE;

      switch (type) {
        case 'dispatchEngagement':
          dispatch(fetchActivityEngagementPagination(activityId, page));
          break;
        case 'dispatchComments':
          dispatch(fetchActivityCommentsPagination(activityId, page));
          break;
        default:
          break;
      }
    },
    [activityId]
  );

  const handleAction = (id, isVisible) => {
    COMMENT_ID = id;
    setCommentModal(!showCommentModal);
    setModalVariation(isVisible ? 'view' : 'hide');
  };

  const getActions = (id, isVisible) => {
    let actions = [];

    actions.push({
      text: isVisible ? 'Exibir comentário' : 'Ocultar comentário',
      onClick: isVisible
        ? () => handleAction(id, isVisible)
        : () => handleAction(id, isVisible),
    });

    return actions;
  };

  const handleSubmit = () => {
    const payload = {
      activityId,
      message,
      sectionId: selectedSection,
    };

    dispatch(addCommentActivity(payload));
  };

  const onActionNotify = () => {
    dispatch(setUpdateNotification(activityId));
    toggleModalNotify();
  };

  const notifyActivityUpdate = {
    title: 'Notificar atualização',
    description:
      'Os responsáveis e alunos receberão uma notificação sobre a atualização da atividade. Deseja notificar atualização? ',
    buttons: [
      {
        text: 'Não notificar',
        variation: 'secondary',
        onClick: toggleModalNotify,
      },
      { text: 'Notificar', variation: 'primary', onClick: onActionNotify },
    ],
  };

  const renderLineList = () => {
    const { data } = engagementActivity;

    if (!!data && data.length > 0) {
      return engagementActivity.data.map((dataRow) => {
        return (
          <InfoData
            key={dataRow.id}
            activityTitle={selectedClassroom.title}
            dataRow={dataRow}
            googleClientId={googleClientId}
          />
        );
      });
    } else {
      return (
        <EmptyState
          imgUrl={IMAGES_CLASSROOMS_URL.emptyStateUrl}
          message={<p>Não há dados para serem exibidos.</p>}
        />
      );
    }
  };

  const renderCommentsList = () => {
    if (commentsActivity.data.length) {
      return (
        <S.CommentsList>
          {commentsActivity.data.map((comment) => {
            return (
              <Card
                key={comment.id}
                title={`${comment.sentBy.name} • ${comment.sentBy.role}`}
                subtitle={comment.message}
                timestamp={comment.createdAt}
                tag={comment.hide && <Tag description={'Comentário oculto'} />}
                avatar={
                  <Avatar
                    user={getAvatarAttributes(
                      comment.sentBy.avatarColor,
                      comment.sentBy.avatarUrl,
                      comment.sentBy.nameInitials
                    )}
                    size="large"
                  />
                }
                dropdown={
                  <DropdownButton
                    dropdownItems={getActions(comment.id, comment.hide)}
                    icon="dots-vertical"
                    variation="white"
                  />
                }
                overlay={comment.hide}
                hidden={comment.hide && comment.sentBy.role === 'Aluno(a)'}
              />
            );
          })}
        </S.CommentsList>
      );
    } else if (!selectedClassroom.can_comment) {
      return (
        <S.EmptyContainer height={768}>
          <EmptyState
            imgUrl={IMAGES_CLASSROOMS_URL.commentsDisabled}
            message={
              <S.MessageGroup maxWidth={325}>
                <h6>Ops, os comentários estão desativados</h6>
                <span>
                  Comentários não estão habilitados para essa turma. Entre em
                  contato com a Escola.
                </span>
              </S.MessageGroup>
            }
          />
        </S.EmptyContainer>
      );
    } else {
      return (
        <S.EmptyContainer>
          <EmptyState
            imgUrl={IMAGES_CLASSROOMS_URL.emptyComments}
            message={
              <S.MessageGroup>
                <h6>Ainda não temos comentários</h6>
                <span>Novos comentários na atividade aparecerão aqui.</span>
              </S.MessageGroup>
            }
          />
        </S.EmptyContainer>
      );
    }
  };

  const renderRecipientsAmount = () => (
    <p>{`${engagementActivity.meta[0]?.value} destinatários`}</p>
  );

  useEffect(() => {
    dispatch(fetchClassroomDetails(activityId));
    dispatch(fetchTotalComments(activityId));
  }, [activityId]);

  useEffect(() => {
    setModalNotifyUpdate(() => {
      const storedValue = memoryStorage.getLocalStorageItem(
        composeStorageKey('modal')
      );

      const withStatus = ['updated'].includes(selectedClassroom?.status);

      if (storedValue && withStatus) {
        return storedValue;
      }

      return false;
    });
  }, [selectedClassroom?.status]);

  useEffect(() => {
    setDetailModal(isSending);
  }, [isSending]);

  return (
    <S.Page
      id="summaryday-classrooms-details"
      title="Detalhes da atividade"
      breadcrumb={
        <Breadcrumb title="Atividades" path="/schools/summaryday_classrooms" />
      }
    >
      <Tabs defaultIndex={0} onSelect={selectedTab}>
        <Tab title="Informações">
          <InformationsTab
            id="summaryday-classrooms-informations"
            isLoading={isLoading}
            skeleton={<SkeletonDetailsTab key="skeleton-details-tab" />}
            tabHeaderElement={<TabHeader data={selectedClassroom} />}
            tabContentElement={
              <TabInfo
                data={selectedClassroom}
                onActionModal={onActionModal}
                permissions={selectedClassroom.summayday_classroom_permissions}
                status={selectedClassroom?.status}
                toggleModal={toggleDetailModal}
              />
            }
            tabFooterElement={
              <>
                {selectedClassroom?.attributes?.versions.length > 0 && (
                  <History
                    data={selectedClassroom.attributes.versions}
                    isModalList
                  />
                )}
                <TabActionButtons
                  infoId={activityId}
                  permissions={
                    selectedClassroom.summayday_classroom_permissions
                  }
                  status={selectedClassroom?.status}
                  toggleModal={toggleDetailModal}
                  history={history}
                />
                <ModalAction
                  showModal={showDetailModal}
                  toggleModal={toggleDetailModal}
                  variation={modalVariation}
                  dataRow={selectedClassroom}
                  titleContext={'atividade'}
                  onActionModal={onActionModal}
                  redirectTo={`/schools/summaryday_classrooms`}
                  isLoading={isSending}
                />
              </>
            }
          />
        </Tab>
        <Tab
          title={
            <S.TitleContainer>
              <S.Text>Comentários</S.Text>
              <S.Badge className="badge-tab">
                {commentsActivity.totalActiveComments}
              </S.Badge>
            </S.TitleContainer>
          }
        >
          <InformationsTab
            id="summaryday-classrooms-comments"
            isLoading={isLoading}
            skeleton={<CommentSkeleton amount={7} />}
            tabContentElement={
              <S.CommentsContainer>
                {selectedClassroom.can_comment && (
                  <FilterContainer>
                    <Tooltip
                      content={
                        'Caso a atividade tenha sido enviada para mais de uma turma, aqui você pode selecionar qual turma você deseja ver os comentários e comentar também.'
                      }
                      tooltipStyle={tooltipStyle}
                    >
                      <AgendaIcon name="help" size="small" />
                    </Tooltip>
                    <Field
                      mobileSize="extraLarge"
                      attributeName="classrooms"
                      pointerEvents="none"
                      type="selectWithIcon"
                      value={selectedSection}
                      options={[...commentsActivity.classrooms]}
                      fullWidth
                      withArrowSelect
                      onChange={(event) => callFetchClassroomsList(event.value)}
                    />
                  </FilterContainer>
                )}
                <S.CommentsContent
                  id="scrollableComments"
                  height={
                    commentsActivity.data.length >= 7 && window.innerHeight
                  }
                >
                  <InfiniteScroll
                    dataLength={commentsActivity.data.length}
                    next={() =>
                      fetchMoreData(commentsActivity, 'dispatchComments')
                    }
                    hasMore={hasMore(commentsActivity)}
                    loader={
                      <S.LoaderContainer top={8} bottom={-8}>
                        <CommentSkeleton amount={1} />
                      </S.LoaderContainer>
                    }
                    style={{
                      display: 'flex',
                      flexDirection: 'column-reverse',
                    }}
                    inverse={true}
                    scrollableTarget="scrollableComments"
                  >
                    {renderCommentsList()}
                  </InfiniteScroll>
                </S.CommentsContent>
              </S.CommentsContainer>
            }
            tabFooterElement={
              selectedClassroom.can_comment && (
                <S.CommentOn>
                  <S.Commenter id="commenter">
                    <S.Input
                      name="message"
                      placeholder="Adicionar comentário"
                      value={message}
                      onChange={onMessageChange}
                    />
                    <Button
                      icon="send"
                      disabled={!message}
                      onClick={() => {
                        handleSubmit();
                        setMessage('');
                      }}
                    />
                  </S.Commenter>
                </S.CommentOn>
              )
            }
          />
        </Tab>
        <Tab title="Engajamento">
          <InformationsTab
            id="summaryday-classrooms-engagement"
            isLoading={isLoading}
            skeleton={<SkeletonEngagemenTab key="skeleton-engagement-tab" />}
            tabHeaderElement={
              <>
                <S.Header className="tab-header-area">
                  <MetricsContainer metrics={engagementActivity.meta} />
                </S.Header>
                <S.RecipientsAmount>
                  {renderRecipientsAmount()}
                </S.RecipientsAmount>
              </>
            }
            tabContentElement={
              <S.EngagementContainer>
                <InfiniteScroll
                  dataLength={engagementActivity.data.length}
                  next={() =>
                    fetchMoreData(engagementActivity, 'dispatchEngagement')
                  }
                  hasMore={hasMore(engagementActivity)}
                  loader={<SkeletonEngagementItem key="list-infinity-scroll" />}
                >
                  {renderLineList()}
                </InfiniteScroll>
              </S.EngagementContainer>
            }
          />
        </Tab>
      </Tabs>

      <ModalHideComment
        activityId={activityId}
        commentId={COMMENT_ID}
        isOpen={showCommentModal}
        toggleModal={toggleCommentModal}
        modalVariation={modalVariation}
      />

      <ModalContainer
        isOpen={modalNotifyUpdate}
        toggleModal={toggleModalNotify}
        zIndex={1}
        title={<ModalTitle>{notifyActivityUpdate.title}</ModalTitle>}
      >
        <ModalBody>{notifyActivityUpdate.description}</ModalBody>

        <ButtonRow buttons={notifyActivityUpdate.buttons} />
      </ModalContainer>

      <Toast />
    </S.Page>
  );
};

Details.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object,
  googleClientId: PropTypes.string,
};

export default withRouter(Details);
