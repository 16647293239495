import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SelectWithGroup from 'components/Form/SelectWithGroup';

import './style.scss';

class ClassroomAndStudentSelector extends Component {
  render() {
    const {
      classroomIdKey,
      onClassroomChange,
      disabled,
    } = this.props;

    return (
      <div className="ClassroomAndStudentSelector">
        <span>Selecionar turma</span>
        <div className="ClassroomAndStudentSelectWithGroup">
          <SelectWithGroup
            attributeName={classroomIdKey}
            onChange={onClassroomChange}
            placeholder="Selecionar turma"
            size="1"
            disabled={disabled}
          />
        </div>
      </div>
    );
  }
}

ClassroomAndStudentSelector.propTypes = {
  formContext: PropTypes.object.isRequired,
  onClassroomChange: PropTypes.func.isRequired,
  classroomIdKey: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
};

export default ClassroomAndStudentSelector;
