const actions = {
  ADD_ENROLLMENT_PLAN_REQUEST: 'ADD_ENROLLMENT_PLAN_REQUEST/enrollmentPlans',
  ADD_ENROLLMENT_PLAN_SUCCESS: 'ADD_ENROLLMENT_PLAN_SUCCESS/enrollmentPlans',
  ADD_MASS_ENROLLMENT_PLAN_REQUEST:
    'ADD_MASS_ENROLLMENT_PLAN_REQUEST/enrollmentPlans',
  ADD_MASS_ENROLLMENT_PLAN_SUCCESS:
    'ADD_MASS_ENROLLMENT_PLAN_SUCCESS/enrollmentPlans',
  ADD_CONTRACT_MODEL_REQUEST: 'ADD_CONTRACT_MODEL_REQUEST/enrollmentPlans',
  ADD_CONTRACT_MODEL_SUCCESS: 'ADD_CONTRACT_MODEL_SUCCESS/enrollmentPlans',
  UPDATE_ENROLLMENT_PLAN_REQUEST:
    'UPDATE_ENROLLMENT_PLAN_REQUEST/enrollmentPlans',
  UPDATE_ENROLLMENT_PLAN_SUCCESS:
    'UPDATE_ENROLLMENT_PLAN_SUCCESS/enrollmentPlans',
  SET_CURRENT_ENROLLMENT_PLAN_REQUEST:
    'SET_CURRENT_ENROLLMENT_PLAN_REQUEST/enrollmentPlans',
  SET_CURRENT_ENROLLMENT_PLAN_SUCCESS:
    'SET_CURRENT_ENROLLMENT_PLAN_SUCCESS/enrollmentPlans',
  FETCH_ENROLLMENT_PLANS_REQUEST:
    'FETCH_ENROLLMENT_PLANS_REQUEST/enrollmentPlans',
  FETCH_ENROLLMENT_PLANS_SUCCESS:
    'FETCH_ENROLLMENT_PLANS_SUCCESS/enrollmentPlans',
  SET_CLASSROOMS_REQUEST: 'SET_CLASSROOMS_REQUEST/enrollmentPlans',
  SET_CLASSROOMS_SUCCESS: 'SET_CLASSROOMS_SUCCESS/enrollmentPlans',
  SET_CONTRACT_MODEL_REQUEST: 'SET_CONTRACT_MODEL_REQUEST/enrollmentPlans',
  SET_CONTRACT_MODEL_SUCCESS: 'SET_CONTRACT_MODEL_SUCCESS/enrollmentPlans',
  SET_ACTIVE_CONTRACT_MODEL_REQUEST:
    'SET_ACTIVE_CONTRACT_MODEL_REQUEST/enrollmentPlans',
  SET_ACTIVE_CONTRACT_MODEL_SUCCESS:
    'SET_ACTIVE_CONTRACT_MODEL_SUCCESS/enrollmentPlans',
  SET_CANCEL_CONTRACT_MODEL_REQUEST:
    'SET_CANCEL_CONTRACT_MODEL_REQUEST/enrollmentPlans',
  SET_ENABLE_CONTRACT_MODEL_REQUEST:
    'SET_ENABLE_CONTRACT_MODEL_REQUEST/enrollmentPlans',
  SET_TOGGLE_CONTRACT_MODEL_SUCCESS:
    'SET_TOGGLE_CONTRACT_MODEL_SUCCESS/enrollmentPlans',
  FETCH_STUDENTS_REQUEST: 'FETCH_STUDENTS_REQUEST/enrollmentPlans',
  FETCH_STUDENTS_SUCCESS: 'FETCH_STUDENTS_SUCCESS/enrollmentPlans',
  FETCH_RECIPIENTS_REQUEST: 'FETCH_RECIPIENTS_REQUEST/enrollmentPlans',
  FETCH_RECIPIENTS_SUCCESS: 'FETCH_RECIPIENTS_SUCCESS/enrollmentPlans',
  FETCH_STUDENT_RESPONSIBLES_REQUEST:
    'FETCH_STUDENT_RESPONSIBLES_REQUEST/enrollmentPlans',
  FETCH_STUDENT_RESPONSIBLES_SUCCESS:
    'FETCH_STUDENT_RESPONSIBLES_SUCCESS/enrollmentPlans',
  FETCH_EDIT_ENROLLMENT_REQUEST:
    'FETCH_EDIT_ENROLLMENT_REQUEST/enrollmentPlans',
  FETCH_EDIT_ENROLLMENT_SUCCESS:
    'FETCH_EDIT_ENROLLMENT_SUCCESS/enrollmentPlans',
  FETCH_HISTORY_ENROLLMENT_REQUEST:
    'FETCH_HISTORY_ENROLLMENT_REQUEST/enrollmentPlans',
  FETCH_HISTORY_ENROLLMENT_SUCCESS:
    'FETCH_HISTORY_ENROLLMENT_SUCCESS/enrollmentPlans',
  APPROVE_ENROLLMENT_PLAN_REQUEST:
    'APPROVE_ENROLLMENT_PLAN_REQUEST/enrollmentPlans',
  APPROVE_ENROLLMENT_PLAN_SUCCESS:
    'APPROVE_ENROLLMENT_PLAN_SUCCESS/enrollmentPlans',
  MANUALLY_PAY_ENROLLMENT_REQUEST:
    'MANUALLY_PAY_ENROLLMENT_PLAN_REQUEST/enrollmentPlans',
  MANUALLY_PAY_ENROLLMENT_SUCCESS:
    'MANUALLY_PAY_ENROLLMENT_PLAN_SUCCESS/enrollmentPlans',
  CHECKOUT: 'CHECKOUT/enrollmentPlans',
  CHECKOUT_SUCCESS: 'CHECKOUT_SUCCESS/enrollmentPlans',
  PAYMENT_METHOD_LINK_ENROLLMENT_REQUEST:
    'PAYMENT_METHOD_LINK_ENROLLMENT_REQUEST/enrollmentPlans',
  PAYMENT_METHOD_LINK_ENROLLMENT_SUCCESS:
    'PAYMENT_METHOD_LINK_ENROLLMENT_SUCCESS/enrollmentPlans',
  SET_PAID_AT_SCHOOL_ORDER_REQUEST:
    'SET_PAID_AT_SCHOOL_ORDER_REQUEST/enrollmentPlans',
  SET_PAID_AT_SCHOOL_ORDER_SUCCESS:
    'SET_PAID_AT_SCHOOL_ORDER_SUCCESS/enrollmentPlans',
  RESET_FILTERS: 'RESET_FILTERS/enrollmentPlans',
  CANCEL_ENROLLMENT_REQUEST: 'CANCEL_ENROLLMENT_REQUEST/enrollmentPlans',
  CANCEL_ENROLLMENT_SUCCESS: 'CANCEL_ENROLLMENT_SUCCESS/enrollmentPlans',
  CANCEL_ENROLLMENT_REQUEST_LD: 'CANCEL_ENROLLMENT_REQUEST_LD/enrollmentPlans',
  CANCEL_ENROLLMENT_SUCCESS_LD: 'CANCEL_ENROLLMENT_SUCCESS_LD/enrollmentPlans',
  TOGGLE_MODAL: 'TOGGLE_MODAL/enrollmentPlans',
  TOGGLE_MANUALLY_PAID_MODAL: 'TOGGLE_MANUALLY_PAID_MODAL/enrollmentPlans',
  TOGGLE_PAYMENT_METHOD_MODAL: 'TOGGLE_PAYMENT_METHOD_MODAL/enrollmentPlans',
  TOGGLE_PAYMENT_METHOD_LINK_MODAL:
    'TOGGLE_PAYMENT_METHOD_LINK_MODAL/enrollmentPlans',
  TOGGLE_CANCEL_MODAL: 'TOGGLE_CANCEL_MODAL/recurrentPlans',
  TOGGLE_MANUAL_DEAL_MODAL: 'TOGGLE_MANUAL_DEAL_MODAL/recurrentPlans',
  ERROR: 'ERROR/enrollmentPlans',
  ERROR_CHECKOUT: 'ERROR_CHECKOUT/enrollmentPlans',
  addEnrollmentPlanRequest: (params) => ({
    type: actions.ADD_ENROLLMENT_PLAN_REQUEST,
    params,
  }),
  updateEnrollmentPlanRequest: (params) => ({
    type: actions.UPDATE_ENROLLMENT_PLAN_REQUEST,
    params,
  }),
  addMassEnrollmentPlanRequest: (params) => ({
    type: actions.ADD_MASS_ENROLLMENT_PLAN_REQUEST,
    params,
  }),

  setCurrentEnrollmentPlanRequest: (enrollmentId) => ({
    type: actions.SET_CURRENT_ENROLLMENT_PLAN_REQUEST,
    enrollmentId,
  }),
  fetchEnrollmentPlansRequest: (params) => ({
    type: actions.FETCH_ENROLLMENT_PLANS_REQUEST,
    params,
  }),
  fetchEditEnrollmentRequest: (enrollmentId) => ({
    type: actions.FETCH_EDIT_ENROLLMENT_REQUEST,
    enrollmentId,
  }),
  setClassrooms: () => ({ type: actions.SET_CLASSROOMS_REQUEST }),
  setContractModels: (params) => ({
    type: actions.SET_CONTRACT_MODEL_REQUEST,
    params,
  }),
  setActiveContractModels: () => ({
    type: actions.SET_ACTIVE_CONTRACT_MODEL_REQUEST,
  }),
  setCancelContractModelRequest: (contractModelId) => ({
    type: actions.SET_CANCEL_CONTRACT_MODEL_REQUEST,
    contractModelId,
  }),
  setEnableContractModelRequest: (contractModelId) => ({
    type: actions.SET_ENABLE_CONTRACT_MODEL_REQUEST,
    contractModelId,
  }),
  fetchStudentsRequest: (classroomId) => ({
    type: actions.FETCH_STUDENTS_REQUEST,
    classroomId,
  }),
  fetchStudentsResponsiblesRequest: (classroomId) => ({
    type: actions.FETCH_STUDENT_RESPONSIBLES_REQUEST,
    classroomId,
  }),

  fetchRecipientsRequest: (studentId) => ({
    type: actions.FETCH_RECIPIENTS_REQUEST,
    studentId,
  }),
  fetchHistoryEnrollmentRequest: (enrollmentId, params) => ({
    type: actions.FETCH_HISTORY_ENROLLMENT_REQUEST,
    enrollmentId,
    params,
  }),
  approveEnrollmentPlanRequest: (enrollmentId) => ({
    type: actions.APPROVE_ENROLLMENT_PLAN_REQUEST,
    enrollmentId,
  }),
  manuallyPayEnrollmentRequest: ({ orderId, responsibleId }) => ({
    type: actions.MANUALLY_PAY_ENROLLMENT_REQUEST,
    orderId,
    responsibleId,
  }),
  setPaidAtSchoolOrder: ({ form, enrollmentId, orderId }) => ({
    type: actions.SET_PAID_AT_SCHOOL_ORDER_REQUEST,
    form,
    enrollmentId,
    orderId,
  }),
  checkout: ({ orderId, responsibleId, cpf, paymentMethod }) => ({
    type: actions.CHECKOUT,
    orderId,
    responsibleId,
    cpf,
    paymentMethod,
  }),
  paymentMethodLinkEnrollmentRequest: (enrollmentId) => ({
    type: actions.PAYMENT_METHOD_LINK_ENROLLMENT_REQUEST,
    enrollmentId,
  }),
  resetFilters: () => ({ type: actions.RESET_FILTERS }),
  cancelEnrollmentRequest: (enrollmentId, textCancel) => ({
    type: actions.CANCEL_ENROLLMENT_REQUEST,
    enrollmentId,
    textCancel,
  }),
  cancelEnrollmentRequestLD: (enrollmentId, form) => ({
    type: actions.CANCEL_ENROLLMENT_REQUEST_LD,
    enrollmentId,
    form,
  }),
  toggleModal: () => ({ type: actions.TOGGLE_MODAL }),
  toggleManuallyPaidModal: () => ({ type: actions.TOGGLE_MANUALLY_PAID_MODAL }),
  togglePaymentMethodModal: () => ({
    type: actions.TOGGLE_PAYMENT_METHOD_MODAL,
  }),
  togglePaymentMethodLink: () => ({
    type: actions.TOGGLE_PAYMENT_METHOD_LINK_MODAL,
  }),
  toggleCancel: () => ({ type: actions.TOGGLE_CANCEL_MODAL }),
  toggleManualDealModal: () => ({
    type: actions.TOGGLE_MANUAL_DEAL_MODAL,
  }),
  addContractModelRequest: (data) => ({
    type: actions.ADD_CONTRACT_MODEL_REQUEST,
    data,
  }),
};

export default actions;
