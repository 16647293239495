import React from 'react';
import {
  Grid,
  DefaultThemeProps,
  theme,
  Button,
} from '@agendaedu/ae-web-components';

import Skeleton from 'components/Skeleton';

import * as S from './styles';
import { useTranslation } from 'react-i18next';

export const FileFieldSkeleton = ({ files }: { files: number }) => {
  const defaultTheme: DefaultThemeProps = theme;

  const { t } = useTranslation(['handouts']);

  const {
    colors: { neutral },
    border: { radius },
  } = defaultTheme;

  return (
    <div>
      <S.AttachmentLabel>{t('attachment_skeleton_label')}</S.AttachmentLabel>
      <Button icon="attachment" variant="secondary" disabled>
        {t('attachment_skeleton_button_label')}
      </Button>
      <S.FormSkeletonItems>
        {Array.from(Array(files).keys()).map((n) => (
          <>
            <Grid gridTemplateColumns="1fr" gutter="sm" mb={10}>
              <Skeleton
                key={n}
                width="100%"
                height={40}
                primaryColor={neutral.gray6}
                borderRadius={radius.md}
                isFullWidth
              />
            </Grid>
          </>
        ))}
      </S.FormSkeletonItems>
    </div>
  );
};
