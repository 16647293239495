import { ThemeProps } from 'config/themes/types';
import styled, { css } from 'styled-components';

export const EmptyStateContentContainer = styled.div`
  ${({ theme: { font, colors } }: ThemeProps) => css`
    font-family: ${font.family.brand};
    color: ${colors.neutral.black};
    h1 {
      font-weight: ${font.weight.bold};
      font-size: ${font.size.lg};
      margin-top: 0px;
    }
    p {
      font-weight: ${font.weight.medium};
      font-size: ${font.size.sm};
    }
  `}
`;
