import React, {PureComponent} from 'react';
import PropTypes from "prop-types";

import SelectDestination from 'components/Messages/SelectDestination'
import FormCheckbox from 'components/Form/Checkbox';

class SelectDestinationWithCheckbox extends PureComponent {
  static propTypes = {
    appContext: PropTypes.shape({
      primaryColor: PropTypes.string.isRequired
    }).isRequired,
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    onRecipientSelect: PropTypes.func,
    kind: PropTypes.string.isRequired,
    user: PropTypes.object.isRequired
  };

  static defaultProps = {
    checked: false,
    disabled: false
  };

  render() {
    const {
      checked,
      disabled,
      onRecipientSelect,
      kind,
      user
    } = this.props;

    return (
      <div className={`SelectDestinationWithCheckbox`}>
        <SelectDestination
          disabled={disabled}
          kind={kind}
          user={user}
          selected={checked}
          actionComponent={
            <FormCheckbox
              disabled={disabled}
              checked={checked}
              onChange={onRecipientSelect}
            />
          }
        >

        </SelectDestination>
      </div>
    )
  }
}

export default SelectDestinationWithCheckbox;
