import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import reduxProvider from 'core/hoc/reduxProvider';

import MoodTrackersPage from 'pages/moodTrackers';

const MoodTrackersScreen = () => {
  return (
    <div id="MoodTrackersScreen">
      <BrowserRouter>
        <Switch>
          <Route exact path="/*/moods" render={MoodTrackersPage} />
        </Switch>
      </BrowserRouter>
    </div>
  );
};

export default reduxProvider(MoodTrackersScreen);
