import React, { useEffect, useState } from 'react';
import _ from 'lodash';

import { useTranslation } from 'react-i18next';

import * as S from './styles';

import {
  Box,
  Button,
  Checkbox,
  Text,
  TimePicker,
} from '@agendaedu/ae-web-components';
import { Header } from '../components/Header';
import { Props } from './types';

const generateKey = () => Math.random().toString(36).substring(7);

export const SleepCard = ({
  sleeps,
  onChange,
  student,
}: Props): React.ReactElement => {
  const { t } = useTranslation(['diary_sections']);
  const [sleepsValues, setSleepsValues] = useState(sleeps);

  const handleNotSlept = () =>
    setSleepsValues((prev) => {
      const newValue = {
        ...prev,
        not_slept: !prev.not_slept,
      };

      onChange(newValue);
      return newValue;
    });

  const addSleep = () => {
    setSleepsValues((prev) => {
      const newValue = {
        ...prev,
        periods: [
          ...prev.periods,
          {
            start_time: '',
            end_time: '',
            key: generateKey(),
          },
        ],
      };

      onChange(newValue);
      return newValue;
    });
  };

  const handleSleep = (
    value: string,
    time: 'start_time' | 'end_time',
    index: number
  ) => {
    const newSleepsValues = _.cloneDeep(sleepsValues);

    newSleepsValues.periods[index][time] = value;

    onChange(newSleepsValues);
    setSleepsValues(newSleepsValues);
  };

  const removeSleep = (index: number) => {
    const newSleepsValues = _.cloneDeep(sleepsValues);

    newSleepsValues.periods.splice(index, 1);

    onChange(newSleepsValues);
    setSleepsValues(newSleepsValues);
  };

  useEffect(() => {
    setSleepsValues(sleeps);
  }, [sleeps]);

  return (
    <S.SleepWrapper data-testid="sleep-card">
      <S.RowWrapper>
        <Header
          sectionTitle={t('sections.card.sleep.title')}
          student={student}
        />

        <Checkbox
          data-testid="not-slept-checkbox"
          checked={sleepsValues.not_slept}
          onChange={handleNotSlept}
        >
          <Text variant="label-regular-16" marginBottom="0">
            {t('sections.card.sleep.checkbox_label')}
          </Text>
        </Checkbox>
      </S.RowWrapper>

      {!sleepsValues.not_slept && (
        <>
          <S.SleepsList>
            {sleepsValues.periods.map(
              ({ start_time, end_time, key }, index) => (
                <Box key={key} display="flex" mr="sm">
                  <S.ContentBox>
                    <Box width="100px">
                      <TimePicker
                        label={t('sections.card.sleep.start_time')}
                        placeholder="00:00"
                        value={start_time}
                        onChange={(time: string) =>
                          handleSleep(time, 'start_time', index)
                        }
                      />
                    </Box>
                    <Text variant="label-regular-14" mt="13px" mb="13px">
                      {t('sections.card.sleep.to')}
                    </Text>
                    <Box width="100px">
                      <TimePicker
                        label={t('sections.card.sleep.end_time')}
                        placeholder="00:00"
                        disabled={!start_time}
                        value={end_time}
                        onChange={(time: string) =>
                          handleSleep(time, 'end_time', index)
                        }
                      />
                    </Box>
                  </S.ContentBox>

                  {sleepsValues.periods.length > 1 && (
                    <Button
                      isOnlyIcon
                      ml="xs3"
                      variant="secondary"
                      mt="auto"
                      contextVariant="alert"
                      icon="trash-bin"
                      onClick={() => removeSleep(index)}
                    />
                  )}
                </Box>
              )
            )}
          </S.SleepsList>

          <Button
            isOnlyIcon
            variant="secondary"
            icon="plus"
            onClick={addSleep}
          />
        </>
      )}
    </S.SleepWrapper>
  );
};
