import React, { useEffect } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import reduxProvider from 'core/hoc/reduxProvider';

import Surveys from 'pages/surveys';
import SurveyNew from 'pages/surveys/SurveyNew';
import SurveyEdit from 'pages/surveys/SurveyEdit';
import SurveyDetails from 'pages/surveys/SurveyDetails';

const SurveysRoutes = () => {
  const changeWrapperBackgroundColor = () => {
    document.getElementById('main-content').style.backgroundColor = '#ffffff';
  };

  useEffect(() => {
    changeWrapperBackgroundColor();
  }, []);

  return (
    <div className="SurveysRoutes">
      <BrowserRouter>
        <Switch>
          <Route exact path="/*/surveys" render={() => <Surveys />} />

          <Route
            exact
            path="/*/surveys/new/poll"
            render={() => <SurveyNew kind="poll" />}
          />

          <Route
            exact
            path="/*/surveys/new/authorization"
            render={() => <SurveyNew kind="authorization" />}
          />

          <Route
            exact
            path="/*/surveys/:id"
            render={(routerProps) => (
              <SurveyDetails surveyId={routerProps.match.params.id} />
            )}
          />

          <Route
            exact
            path="/*/surveys/:id/edit"
            render={(routerProps) => (
              <SurveyEdit surveyId={routerProps.match.params.id} />
            )}
          />
        </Switch>
      </BrowserRouter>
    </div>
  );
};

export default reduxProvider(SurveysRoutes);
