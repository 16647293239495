import validatePresence from 'core/lib/FormValidator/validators/presence';
import validateArrayPresence from 'core/lib/FormValidator/validators/array/presence';
import validateRecurrentItems from 'core/lib/FormValidator/validators/recurrentItems';
import {
  validateFinePercent,
  validateInterestPercent,
} from 'core/lib/FormValidator/validators/penality';

export default [
  {
    attributeName: 'due_date',
    validator: validatePresence,
    message: 'Campo obrigatório',
  },
  {
    attributeName: 'allowed_payment_method',
    validator: validateArrayPresence,
    message: 'Selecione um método de pagamento para continuar',
  },
  {
    attributeName: 'recipient_wallet_id',
    validator: validatePresence,
    message: 'Campo obrigatório',
  },
  {
    attributeName: 'recurrentItems',
    validator: validateRecurrentItems,
    message: 'Possui itens inválidos, reveja os valores informados',
  },
  {
    attributeName: 'edupay_penality',
    validator: validateFinePercent,
    message: 'A multa é de no máximo 2%',
  },
  {
    attributeName: 'edupay_penality',
    validator: validateInterestPercent,
    message: 'Os juros são de no máximo 1% a.m.',
  },
];
