const actions = {
  ERROR: 'ERROR/schoolTerms',
  UPDATE_IS_LOADING: 'UPDATE_IS_LOADING/schoolTerms',

  GET_PERIODS: 'GET_PERIODS/schoolTerms',
  GET_PERIODS_SUCCESS: 'GET_PERIODS_SUCCESS/schoolTerms',

  GET_CLASSROOMS: 'GET_CLASSROOMS/schoolTerms',
  GET_CLASSROOMS_SUCCESS: 'GET_CLASSROOMS_SUCCESS/schoolTerms',

  SAVE_NEW_PERIOD: 'SAVE_NEW_PERIOD/schoolTerms',
  SAVE_NEW_PERIOD_SUCCESS: 'SAVE_NEW_PERIOD_SUCCESS/schoolTerms',

  DELETE_PERIOD: 'DELETE_PERIOD/schoolTerms',
  DELETE_PERIOD_SUCCESS: 'DELETE_PERIOD_SUCCESS/schoolTerms',

  GET_PERIOD_DETAILS: 'GET_PERIOD_DETAILS/schoolTerms',
  GET_PERIOD_DETAILS_SUCCESS: 'GET_PERIOD_DETAILS_SUCCESS/schoolTerms',

  GET_ACTIVATED_PERIOD_CLASSROOMS:
    'GET_ACTIVATED_PERIOD_CLASSROOMS/schoolTerms',
  GET_ACTIVATED_PERIOD_CLASSROOMS_SUCCESS:
    'GET_ACTIVATED_PERIOD_CLASSROOMS_SUCCESS/schoolTerms',

  SAVE_EDIT_PERIOD: 'SAVE_EDIT_PERIOD/schoolTerms',
  SAVE_EDIT_PERIOD_SUCCESS: 'SAVE_EDIT_PERIOD_SUCCESS/schoolTerms',

  ACTIVATE_PERIODS: 'ACTIVATE_PERIODS/schoolTerms',
  ACTIVATE_PERIODS_SUCCESS: 'ACTIVATE_PERIODS_SUCCESS/schoolTerms',

  getListPeriods: () => ({
    type: actions.GET_PERIODS,
  }),

  getListClassrooms: () => ({
    type: actions.GET_CLASSROOMS,
  }),

  saveNewPeriod: (form: {
    classroom_ids: Array<number | string>;
    name: string;
  }) => ({
    type: actions.SAVE_NEW_PERIOD,
    form,
  }),

  deletePeriod: (periodId: string) => ({
    type: actions.DELETE_PERIOD,
    periodId,
  }),

  getPeriodDetails: (periodId: string) => ({
    type: actions.GET_PERIOD_DETAILS,
    periodId,
  }),

  saveEditPeriod: (
    form: {
      classroom_ids: Array<number | string>;
      name: string;
    },
    periodId: string
  ) => ({
    type: actions.SAVE_EDIT_PERIOD,
    form,
    periodId,
  }),

  activatePeriod: (periodId: string) => ({
    type: actions.ACTIVATE_PERIODS,
    periodId,
  }),
};

export default actions;
