const actions = {
  SET_ERROR: 'SET_ERROR/headquarters',
  SET_CAN_SHOW_EMPTY_STATE: 'SET_CAN_SHOW_EMPTY_STATE/headquarters',
  ADD_HEADQUARTER: 'ADD_HEADQUARTER/headquarters',
  ADD_HEADQUARTER_SUCCESS: 'ADD_HEADQUARTER_SUCCESS/headquarters',
  EDIT_HEADQUARTER: 'EDIT_HEADQUARTER/headquarters',
  EDIT_HEADQUARTER_SUCCESS: 'EDIT_HEADQUARTER_SUCCESS/headquarters',
  SET_HEADQUARTER: 'SET_HEADQUARTER/headquarters',
  SET_HEADQUARTER_SUCCESS: 'SET_HEADQUARTER_SUCCESS/headquarters',
  FETCH_HEADQUARTERS: 'FETCH_HEADQUARTERS/headquarters',
  FETCH_HEADQUARTERS_SUCCESS: 'FETCH_HEADQUARTERS_SUCCESS/headquarters',
  FETCH_HEADQUARTERS_HISTORY: 'FETCH_HEADQUARTERS_HISTORY/headquarters',
  FETCH_HEADQUARTERS_HISTORY_SUCCESS:
    'FETCH_HEADQUARTERS_HISTORY_SUCCESS/headquarters',
  FETCH_HEADQUARTERS_HISTORY_COUNT:
    'FETCH_HEADQUARTERS_HISTORY_COUNT/headquarters',
  FETCH_HEADQUARTERS_HISTORY_COUNT_SUCCESS:
    'FETCH_HEADQUARTERS_HISTORY_COUNT_SUCCESS/headquarters',
  BUILD_TOAST: 'BUILD_TOAST/headquarters',
  BUILD_TOAST_SUCCESS: 'BUILD_TOAST_SUCCESS/headquarters',
  addHeadquarter: (params, history) => ({
    type: actions.ADD_HEADQUARTER,
    params,
    history,
  }),
  editHeadquarter: (id, params, history) => ({
    type: actions.EDIT_HEADQUARTER,
    id,
    params,
    history,
  }),
  setHeadquarter: (id) => ({
    type: actions.SET_HEADQUARTER,
    id,
  }),
  fetchHeadquarters: () => ({
    type: actions.FETCH_HEADQUARTERS,
  }),
  fetchHeadquartersHistory: (id, page) => ({
    type: actions.FETCH_HEADQUARTERS_HISTORY,
    id,
    page,
  }),
  fetchHeadquartersHistoryCount: (id) => ({
    type: actions.FETCH_HEADQUARTERS_HISTORY_COUNT,
    id,
  }),
  setCanShowEmptyState: (state) => ({
    type: actions.SET_CAN_SHOW_EMPTY_STATE,
    state,
  }),
  buildToast: (message, typeToast) => ({
    type: actions.BUILD_TOAST,
    message,
    typeToast,
  }),
};

export default actions;
