import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autobind from 'autobind-decorator';

import PageTitle from 'components/PageTitle';
import Loader from 'components/Loader/index';
import withAppContext from 'core/hoc/withAppContext';
import MessagesOtherUsersChannelSideBarFilter from 'components/Messages/OtherUsers/ChannelSideBar/Filter';
import MessagesOtherUsersChannelSideBarList from 'components/Messages/OtherUsers/ChannelSideBar/List';
import './style.scss';

class MessagesOtherUsersChannelSideBar extends Component {
  static propTypes = {
    activeChannelKind: PropTypes.string.isRequired,
    appContext: PropTypes.shape({
      dataArea: PropTypes.string.isRequired,
      currentUserType: PropTypes.string.isRequired,
    }).isRequired,
    channels: PropTypes.arrayOf(PropTypes.object).isRequired,
    errorFetchingChannel: PropTypes.any,
    familyCount: PropTypes.number.isRequired,
    filteredChannels: PropTypes.arrayOf(PropTypes.object).isRequired,
    isLoading: PropTypes.bool.isRequired,
    loadChannels: PropTypes.func.isRequired,
    onChannelSelect: PropTypes.func.isRequired,
    onFilterChannelClick: PropTypes.func.isRequired,
    privateCount: PropTypes.number.isRequired,
    userChats: PropTypes.array.isRequired,
  };

  state = {
    scrollabeNodeStyle: {},
  };

  scrollabeNode = null;

  componentDidMount() {
    this.setScrollabeNodeStyle();
    this.props.loadChannels();
  }

  /**
   * This is needed to show the scroll bar because scroll bars need height to be
   * set.
   */
  setScrollabeNodeStyle() {
    if (!this.scrollabeNode) return;
    const { top } = this.scrollabeNode.getBoundingClientRect();
    const scrollabeNodeStyle = { height: window.innerHeight - top };
    this.setState({ scrollabeNodeStyle });
  }

  @autobind
  setScrollabeNode(node) {
    this.scrollabeNode = node;
  }

  render() {
    const {
      activeChannelKind,
      errorFetchingChannel,
      familyCount,
      filteredChannels,
      isLoading,
      onChannelSelect,
      onFilterChannelClick,
      privateCount,
      userChats,
    } = this.props;

    const { scrollabeNodeStyle } = this.state;

    return (
      <div
        className="MessagesOtherUsersChannelSideBar"
        ref={this.setScrollabeNode}
        style={scrollabeNodeStyle}
      >
        <PageTitle variation="medium">Mensagens</PageTitle>
        <MessagesOtherUsersChannelSideBarFilter
          activeChannelKind={activeChannelKind}
          onFilterChannelClick={onFilterChannelClick}
          familyCount={familyCount}
          privateCount={privateCount}
        />
        <PageTitle variation="small">Canais</PageTitle>
        <hr></hr>
        <Loader isLoading={isLoading}>
          <MessagesOtherUsersChannelSideBarList
            channels={filteredChannels}
            errorFetchingChannel={errorFetchingChannel}
            activeChannelKind={activeChannelKind}
            onChannelSelect={onChannelSelect}
            userChats={userChats}
          />
        </Loader>
      </div>
    );
  }
}

export default withAppContext(MessagesOtherUsersChannelSideBar);
