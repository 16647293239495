/* eslint-disable no-extra-boolean-cast */
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { fetchApi } from 'core/utils/api';
import actions from './actions';
import {
  buildToast,
  toastCss,
  ToastTypes,
} from 'store/middlewares/toast/actions';

function* requestUrl(action) {
  try {
    const dataArea = yield select((state) => state.root.dataArea);
    const { id } = action;

    const { data, meta } = yield call(
      fetchApi,
      `/${dataArea}/dynamic_modules/${id}.json`
    );

    if (meta.url === '') throw new Error('URL de acesso não foi gerada');
    yield put({
      type: actions.REQUEST_URL_SUCCESS,
      url: meta.url,
      fullScreen: data?.attributes?.full_screen ? data.attributes.full_screen : false,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        'Erro ao solicitar URL de integração',
        ToastTypes.error,
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* dynamicModuleSagas() {
  yield all([takeLatest(actions.REQUESTURL, requestUrl)]);
}

export default dynamicModuleSagas;
