import React, { useState } from 'react';
import PropTypes from 'prop-types';

import styled, { keyframes, css } from 'styled-components';
import { Tab, Tabs as ReactTabs, TabList, TabPanel } from 'react-tabs';

import 'react-tabs/style/react-tabs.css';
import './style.scss';

function Tabs(props) {
  const [selectedTab, setSeletectTab] = useState(0);
  const [previousTab, setPreviousTab] = useState(0);
  const [animationClass, setAnimationClass] = useState('');

  const {
    children,
    onSelect,
    animated,
    variation,
    header,
    label,
    defaultIndex,
  } = props;

  const titles = React.Children.map(children, (child) => {
    if (child) {
      let childContent = {
        title: child.props.title,
        flag: child.props.flag ? child.props.flag : null,
      };
      return childContent;
    }
  });

  const setTabsState = (selectedIndex) => {
    const lastTab = selectedTab;

    setPreviousTab(lastTab);
    setSeletectTab(selectedIndex);
    setAnimationClass(animated ? 'animated-tab' : '');

    onSelect({ selectedTab: selectedIndex, previousTab: lastTab });
  };

  const animationStart = () => {
    if (previousTab <= selectedTab) {
      return -((selectedTab - previousTab) * 100);
    }

    return (previousTab - selectedTab) * 100;
  };

  const translate = keyframes`
    0% {
        opacity: 0;
        transform: translateX(${animationStart()}%);
    }

    100% {
        opacity: 1;
        transform: translateX(0%);
    }
  `;

  const StyledDefaultTab = styled(Tab)`
    &.selected-tab {
      color: ${(props) => props.theme.primaryColor} !important;

      &:after {
        background-color: ${(props) => props.theme.primaryColor};
        width: 84px;
      }
    }

    &.animated-tab {
      &:after {
        ${css`
          animation: ${translate} 0.5s ease;
        `}
      }
    }
  `;

  const StyledBoxTab = styled(Tab)`
    border: solid 1px ${(props) => props.theme.primaryColor};

    &:not(.selected-tab) {
      color: ${(props) => props.theme.primaryColor};
    }

    &.selected-tab {
      color: ${(props) => props.theme.primaryColor};
      background-color: ${(props) => props.theme.primaryColor};

      .badge-tab {
        background: ${(props) => props.theme.primaryColor}20;
      }
    }

    &:focus {
      border-color: ${(props) => props.theme.primaryColor};
      box-shadow: none;
    }
  `;

  const FlagName = styled.span`
    padding: 3px 5px;
    border-radius: 5px;
    margin-left: 5px;
    color: #3facde;
    background-color: rgba(63, 172, 222, 0.2);
    font-size: 10px;
    position: relative;
    top: -2px;
    font-weight: normal;
  `;

  StyledDefaultTab.tabsRole = 'Tab';
  StyledBoxTab.tabsRole = 'Tab';

  const styledTabs = {
    default: StyledDefaultTab,
    box: StyledBoxTab,
  };

  const StyledTab = styledTabs[variation];

  return (
    <ReactTabs
      className={`react-tabs Tabs ${variation}`}
      selectedTabClassName="react-tabs__tab--selected selected-tab"
      onSelect={(tabIndex) => setTabsState(tabIndex)}
      defaultIndex={defaultIndex}
    >
      <div className="tabs-header">
        <div className="tab-list">
          {label && <div className="tabs-label">{label}</div>}
          <TabList>
            {titles.map((item) => {
              return (
                <StyledTab
                  onAnimationEnd={() => setAnimationClass('')}
                  className={`react-tabs__tab tab-style ${animationClass}`}
                  key={item.title}
                >
                  <span>{item.title}</span>
                  {item.flag ? <FlagName>{item.flag}</FlagName> : null}
                </StyledTab>
              );
            })}
          </TabList>
        </div>
        {header}
      </div>
      {React.Children.map(children, (child) => {
        if (!child) return;
        return (
          <TabPanel className="react-tabs__tab-panel tab-panel-style">
            {child}
          </TabPanel>
        );
      })}
    </ReactTabs>
  );
}

Tabs.defaultProps = {
  onSelect: () => {},
  animated: true,
  variation: 'default',
  header: <div />,
  label: '',
  defaultIndex: 0,
};

Tabs.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element).isRequired,
    PropTypes.element.isRequired,
  ]),
  onSelect: PropTypes.func,
  animated: PropTypes.bool,
  variation: PropTypes.oneOf(['default', 'box']),
  header: PropTypes.element,
  label: PropTypes.string,
  defaultIndex: PropTypes.number,
};

export default Tabs;
