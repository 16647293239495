import styled, { css } from 'styled-components';

const leftColumnSize = '310px';

const defaultTextSpacing = css`
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
`;

export const FlexDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const FixedWidthDiv = styled.div`
  width: ${leftColumnSize};
  display: inline-block;
`;

export const Subtitle = styled.span`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  ${defaultTextSpacing};
  color: ${(props) => props.theme.gray2};
`;

export const List = styled.ul`
  list-style: none;
  padding-left: 0px;
  li {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    ${defaultTextSpacing};
    color: ${(props) => props.theme.black};
  }
`;

export const SubtotalContainer = styled(FlexDiv)`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 600;
  ${defaultTextSpacing};
  color: ${(props) => props.theme.black};
`;

export const Divider = styled.hr`
  border-bottom: 1px solid ${(props) => props.theme.gray3};
  width: 100%;
`;

export const NegativeMarginDivider = styled(Divider)`
  margin-left: -24px;
  margin-right: -24px;
  margin-bottom: 10px;
  margin-top: 20px;
  width: auto;
`;

export const BillInfosContainer = styled(FlexDiv)`
  flex-wrap: wrap;

  .LabeledContainer {
    margin-bottom: 16px;

    .detail-header .detail-title {
      font-family: Roboto;
      font-size: 14px;
      font-weight: 400;
      ${defaultTextSpacing};
      color: ${(props) => props.theme.gray2};
    }

    .information-content p {
      font-family: Roboto;
      font-size: 16px;
      font-weight: 400;
      ${defaultTextSpacing};
      color: ${(props) => props.theme.black};
    }
  }
`;

export const ObservationContainer = styled.div`
  p {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 700;
    line-height: 14px;
    color: ${(props) => props.theme.black};
  }
`;

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
`;

export const Th = styled.th`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  ${defaultTextSpacing};
  color: ${(props) => props.theme.gray2};

  &:nth-child(1) {
    width: ${leftColumnSize};
  }
`;

export const Td = styled.td`
  text-align: 'left';
  font-family: Roboto;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  color: ${(props) => props.theme.black};
  padding-top: 16px;
`;

export const BillSummaryContainer = styled.div`
  border: 1px solid ${(props) => props.theme.gray12};
  border-radius: 6px;
  padding: 24px;
  box-sizing: border-box;
`;

export const BillSummaryTitle = styled.span<{ size?: string }>`
  font-family: Roboto;
  font-size: ${(props) => props.size || '14px'};
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
`;

export const InfoText = styled.span`
  font-family: Roboto;
  font-size: 11px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
`;

export const BottomContainer = styled.div`
  .ButtonRow {
    margin-top: 0px;
    padding-bottom: 21px;
  }
`;
