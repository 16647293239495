import React from 'react';
import { useTheme } from 'styled-components';

import Text from 'components/Text';

import { TagProps } from './types';

import * as S from './styles';

const Tag = ({ description }: TagProps) => {
  const theme = useTheme();

  return (
    <S.TagContainer className="badge">
      <Text
        element="span"
        font="Quicksand"
        size={12}
        weight="700"
        color={theme.gray2}
      >
        {description}
      </Text>
    </S.TagContainer>
  );
};

export default Tag;
