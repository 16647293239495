import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autobind from 'autobind-decorator';
import FormSelectorSelectableColumn from 'components/Form/Selector/SelectableColumn';
import TextFieldWithIcon from 'components/Form/TextFieldWithIcon';
import { TextField } from '@agendaedu/ae-web-components';

import './style.scss';

class FormSelector extends Component {
  static propTypes = {
    options: PropTypes.array.isRequired,
    optionComponent: PropTypes.func.isRequired,
    optionAccessor: PropTypes.string,
    search: PropTypes.func,
    newSearchField: PropTypes.bool,
    searchPlaceholder: PropTypes.string,
    selectAllLabel: PropTypes.string,
    deselectAllLabel: PropTypes.string,
    selectedValues: PropTypes.array,
    onSelectBehavior: PropTypes.string,
    counterLabel: PropTypes.func,
    onChange: PropTypes.func,
    emptyState: PropTypes.node,
  };

  static defaultProps = {
    optionGroup: false,
    onSelectBehavior: 'remove',
    selectAllLabel: 'Selecionar todos',
    deselectAllLabel: 'Remover todos',
  };

  state = {
    isSearching: false,
    filteredOptions: [],
    selectedOptions: [],
    options: [],
  };

  componentDidMount() {
    const { options } = this.props;

    this.setState({ options });
    this.getSelectedOptions();
  }

  @autobind
  componentDidUpdate(prevProps, prevState) {
    const { options, selectedValues } = this.props;

    // used to change options after load new classrooms
    if (prevProps.options !== options) {
      this.setState({ options });
    }

    // it's used to clear the selectedOptions after form submit
    if (prevProps.selectedValues !== selectedValues) {
      this.getSelectedOptions();
    }
  }

  @autobind
  getSelectedOptions() {
    const { options, selectedValues, optionAccessor } = this.props;

    let { selectedOptions } = this.state;

    if (selectedValues.length) {
      const selectedOptionsValues = this.getSelectedValues(selectedOptions);

      // add new option to selected if there is one
      options.forEach((option) => {
        if (
          selectedValues.includes(optionAccessor(option)) &&
          !selectedOptionsValues.includes(optionAccessor(option))
        ) {
          selectedOptions.push(option);
        }
      });

      this.setState({ options, selectedOptions });
    } else {
      this.setState({ selectedOptions: [] });
    }
  }

  @autobind
  getSelectedValues(selectedOptions) {
    const { optionAccessor } = this.props;

    return selectedOptions.map((option) => optionAccessor(option));
  }

  @autobind
  removeEmptyGroups(options) {
    return options.filter((group) => group.options.length > 0);
  }

  @autobind
  onOptionSelect({ option, placeOption }) {
    const { onChange } = this.props;

    const { filteredOptions, selectedOptions, options } = this.state;

    let newSelected = [];
    let newFiltered = filteredOptions;
    let newOptions = options;

    newSelected = placeOption(selectedOptions, option);

    const selectedValues = this.getSelectedValues(newSelected);

    onChange({ selectedValues });
    this.setState({
      filteredOptions: newFiltered,
      selectedOptions: newSelected,
      options: newOptions,
    });
  }

  @autobind
  onOptionDeselect({ option, removeSelectedOption }) {
    const { onChange } = this.props;

    let newSelected = [];

    const { selectedOptions } = this.state;

    newSelected = removeSelectedOption(selectedOptions, option);

    this.setState({ selectedOptions: newSelected });
    const selectedValues = this.getSelectedValues(newSelected);
    onChange({ selectedValues });
  }

  @autobind
  selectAll({ placeOption }) {
    let { filteredOptions, selectedOptions, options } = this.state;

    const { isSearching } = this.state;
    const visibleOptions = isSearching ? filteredOptions : options;
    const { onChange } = this.props;

    visibleOptions.forEach(function (option) {
      selectedOptions = placeOption(selectedOptions, option);
    });

    this.setState({ selectedOptions: selectedOptions });
    const selectedValues = this.getSelectedValues(selectedOptions);
    onChange({ selectedValues });
  }

  @autobind
  deselectAll({ placeOption }) {
    let { selectedOptions, options } = this.state;

    const { isSearching, searchTerm } = this.state;
    const { search, onChange } = this.props;

    selectedOptions.forEach(function (option) {
      options = placeOption(options, option);
    });

    this.setState({ selectedOptions: [] });
    const selectedValues = [];
    onChange({ selectedValues });

    if (isSearching) {
      const filteredOptions = search(searchTerm);
      this.setState({ filteredOptions });
    }
  }

  @autobind
  onSearch(searchTerm) {
    const isSearching = searchTerm.length ? true : false;
    const { options } = this.state;
    const filteredOptions = this.props.search({ options, searchTerm });

    this.setState({ isSearching, searchTerm, filteredOptions });
  }

  render() {
    const {
      optionComponent,
      optionAccessor,
      search,
      searchPlaceholder,
      selectAllLabel,
      deselectAllLabel,
      onSelectBehavior,
      counterLabel,
      emptyState,
      newSearchField,
    } = this.props;

    const { isSearching, filteredOptions, selectedOptions, options } =
      this.state;

    return (
      <div className="FormSelector">
        <div className="container-wrap">
          <div className="selector-filters item-wrap">
            {search &&
              (!newSearchField ? (
                <TextFieldWithIcon
                  attributeName="searchTerm"
                  placeholder={searchPlaceholder}
                  onChange={(e) => this.onSearch(e)}
                />
              ) : (
                <TextField
                  fullWidth
                  icon="search"
                  placeholder={searchPlaceholder}
                  onChange={(e) => this.onSearch(e.target.value)}
                />
              ))}
          </div>
          <div className="item-wrap"></div>
        </div>
        <div className="container-wrap">
          <div className="options-column item-wrap">
            <FormSelectorSelectableColumn
              options={isSearching ? filteredOptions : options}
              optionComponent={optionComponent}
              onOptionSelect={this.onOptionSelect}
              onOptionDeselect={this.onOptionDeselect}
              hasOptionGroup={false}
              optionAccessor={optionAccessor}
              selectAllLabel={selectAllLabel}
              onSelectBehavior={onSelectBehavior}
              selected={selectedOptions}
              selectAll={this.selectAll}
              counterLabel={counterLabel}
              emptyState={emptyState}
            />
          </div>
          <div className="selected-column item-wrap">
            <FormSelectorSelectableColumn
              options={selectedOptions}
              optionComponent={optionComponent}
              onOptionSelect={this.onOptionDeselect}
              onGroupSelect={this.onGroupDeselect}
              hasOptionGroup={false}
              optionAccessor={optionAccessor}
              selectAllLabel={deselectAllLabel}
              onSelectBehavior={onSelectBehavior}
              selected={[]}
              selectAll={this.deselectAll}
              counterLabel={(length) =>
                length === 1 ? 'selecionado' : 'selecionados'
              }
            />
          </div>
        </div>
      </div>
    );
  }
}

export default FormSelector;
