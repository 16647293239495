import React, { useEffect, useCallback } from 'react';

import withFormContext, { formPropTypes } from 'core/hoc/withFormContext';
import FormContext from 'core/contexts/Form';
import tabifyForWizard from 'core/hoc/tabifyForWizard';
import FormSelector from 'components/Form/Selector';
import Loader from 'components/Loader';

import './style.scss';

const HeadquartersTab = ({ formContext }) => {
  const { changeMeta, form, formMeta } = formContext;

  const optionComponent = (option) => (
    <span className="headquarter-name">{option.name}</span>
  );

  const fieldsFillVerification = useCallback(() => {
    changeMeta('nextStepDisabled', !form.headquarters.selected.length);
  }, [changeMeta]);

  useEffect(() => {
    fieldsFillVerification();
  }, [fieldsFillVerification]);

  const updateSelectorColumns = useCallback(
    ({ selectedValues }) => {
      form.headquarters.selected = selectedValues;
      formMeta.headquarter(selectedValues);

      fieldsFillVerification();
    },
    [fieldsFillVerification]
  );

  const searchOptions = ({ options, searchTerm }) =>
    options.filter((option) =>
      option.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

  return (
    <div className="headquartersTab">
      <div>
        <div className="stepInfo">
          <p>
            Selecione quais <b>Unidades</b> serão importadas na integração.
            Essas configurações serão salvas para as próximas integrações.
          </p>
        </div>

        <FormContext.Consumer>
          {({ form }) => (
            <Loader isLoading={form.loading}>
              <FormSelector
                options={form.data.headquarters.filters}
                selectedValues={form.data.headquarters.selected}
                optionComponent={optionComponent}
                onChange={updateSelectorColumns}
                search={searchOptions}
                searchPlaceholder="Buscar unidade"
                optionAccessor={(option) => option.code}
                selectAllLabel="Selecionar todos"
                onSelectBehavior="highlight"
                counterLabel={(length) =>
                  length === 1 ? 'unidade' : 'unidades'
                }
              />
            </Loader>
          )}
        </FormContext.Consumer>
      </div>
    </div>
  );
};

export default tabifyForWizard({
  title: '1. Unidades',
  subTitle: '',
})(withFormContext(HeadquartersTab));
