const actions = {
  ERROR: 'ERROR/summaryday_classrooms',
  FETCH_DISCIPLINES: 'FETCH_DISCIPLINES/summaryday_classrooms',
  FETCH_DISCIPLINES_SUCCESS: 'FETCH_DISCIPLINES_SUCCESS/summaryday_classrooms',
  SET_CLASSROOMS: 'SET_CLASSROOMS/summaryday_classrooms',
  SET_CLASSROOMS_SUCCESS: 'SET_CLASSROOMS_SUCCESS/summaryday_classrooms',
  ADD_SUMMARYDAY_CLASSROOMS: 'ADD_SUMMARYDAY_CLASSROOMS/summaryday_classrooms',
  ADD_SUMMARYDAY_CLASSROOMS_SUCCESS:
    'ADD_SUMMARYDAY_CLASSROOMS_SUCCESS/summaryday_classrooms',
  SET_SUMMARYDAY_CLASSROOM: 'SET_SUMMARYDAY_CLASSROOM/summaryday_classrooms',
  SET_SUMMARYDAY_CLASSROOM_SUCCESS:
    'SET_SUMMARYDAY_CLASSROOM_SUCCESS/summaryday_classrooms',
  UPDATE_SUMMARYDAY_CLASSROOM:
    'UPDATE_SUMMARYDAY_CLASSROOM/summaryday_classrooms',
  UPDATE_SUMMARYDAY_CLASSROOM_SUCCESS:
    'UPDATE_SUMMARYDAY_CLASSROOM_SUCCESS/summaryday_classrooms',
  FETCH_CLASSROOMS_REQUEST: 'FETCH_CLASSROOMS_REQUEST/summaryday_classrooms',
  FETCH_CLASSROOMS_SUCCESS: 'FETCH_CLASSROOMS_SUCCESS/summarydayClassrooms',
  FETCH_CLASSROOM_DETAILS_REQUEST:
    'FETCH_CLASSROOM_DETAILS_REQUEST/summaryday_classrooms',
  FETCH_CLASSROOM_DETAILS_SUCCESS:
    'FETCH_CLASSROOM_DETAILS_SUCCESS/summaryday_classrooms',
  RESET_CLASSROOMS_LIST: 'RESET_CLASSROOMS_LIST/summaryday_classrooms',
  BUILD_TOAST: 'BUILD_TOAST/summaryday_classrooms',

  FETCH_EDUCATIONAL_STAGES_REQUEST:
    'SET_EDUCATIONAL_STAGES_REQUEST/summaryday_classrooms',
  FETCH_EDUCATIONAL_STAGES_SUCCESS:
    'SET_EDUCATIONAL_STAGES_SUCCESS/summaryday_classrooms',
  FETCH_CLASSROOM_FILTER_REQUEST:
    'FETCH_CLASSROOM_FILTER_REQUEST/summaryday_classrooms',
  FETCH_CLASSROOM_FILTER_SUCCESS:
    'FETCH_CLASSROOM_FILTER_SUCCESS/summaryday_classrooms',
  SET_CLASSROOM_APPROVE_REQUEST:
    'SET_CLASSROOM_APPROVE_REQUEST/summaryday_classrooms',
  FETCH_CLASSROOM_DELETE_SUCCESS:
    'FETCH_CLASSROOM_DELETE_SUCCESS/summaryday_classrooms',
  FETCH_CLASSROOM_DELETE_REQUEST:
    'FETCH_CLASSROOM_DELETE_REQUEST/summaryday_classrooms',
  DISAPPROVE_CLASSROOM_REQUEST:
    'DISAPPROVE_CLASSROOM_REQUEST/summaryday_classrooms',
  DISAPPROVE_CLASSROOM_SUCCESS:
    'DISAPPROVE_CLASSROOM_SUCCESS/summaryday_classrooms',
  SET_APPROVE_CLASSROOM_SUCCESS:
    'SET_APPROVE_CLASSROOM_SUCCESS/summaryday_classrooms',
  SET_DISAPPROVE_CLASSROOM_REQUEST:
    'SET_DISAPPROVE_CLASSROOM_REQUEST/summaryday_classrooms',
  SET_DUPLICATE_ACTIVITY_SUCCESS:
    'SET_DUPLICATE_ACTIVITY_SUCCESS/summaryday_classrooms',
  SET_DUPLICATE_ACTIVITY_REQUEST:
    'SET_DUPLICATE_ACTIVITY_REQUEST/summaryday_classrooms',
  FETCH_ACTIVITY_ENGAGEMENT_REQUEST:
    'FETCH_ACTIVITY_ENGAGEMENT_REQUEST/summaryday_classrooms',
  FETCH_ACTIVITY_ENGAGEMENT_SUCCESS:
    'FETCH_ACTIVITY_ENGAGEMENT_SUCCESS/summaryday_classrooms',
  FETCH_ACTIVITY_ENGAGEMENT_REQUEST_PAGINATION:
    'FETCH_ACTIVITY_ENGAGEMENT_REQUEST_PAGINATION/summaryday_classrooms',
  FETCH_ACTIVITY_COMMENTS_REQUEST:
    'FETCH_ACTIVITY_COMMENTS_REQUEST/summaryday_classrooms',
  FETCH_ACTIVITY_COMMENTS_SUCCESS:
    'FETCH_ACTIVITY_COMMENTS_SUCCESS/summaryday_classrooms',
  FETCH_ACTIVITY_COMMENTS_REQUEST_PAGINATION:
    'FETCH_ACTIVITY_COMMENTS_REQUEST_PAGINATION/summaryday_classrooms',
  SET_ACTIVITY_ID_DUPLICATE_REQUEST:
    'SET_ACTIVITY_ID_DUPLICATE_REQUEST/summaryday_classrooms',
  SET_CLASSROOM_SCHEDULE_REQUEST:
    'SET_CLASSROOM_SCHEDULE_REQUEST/summaryday_classrooms',
  SET_CLASSROOM_SCHEDULE_SUCCESS:
    'SET_CLASSROOM_SCHEDULE_SUCCESS/summaryday_classrooms',
  SET_ACTIVITY_RESEND_REQUEST:
    'SET_ACTIVITY_RESEND_REQUEST/summaryday_classrooms',
  SET_ACTIVITY_RESEND_SUCCESS:
    'SET_ACTIVITY_RESEND_SUCCESS/summaryday_classrooms',
  SET_CLASSROOM_CANCELSCHEDULE_REQUEST:
    'SET_CLASSROOM_CANCELSCHEDULE_REQUEST/summaryday_classrooms',
  SET_CLASSROOM_CANCELSCHEDULE_SUCCESS:
    'SET_CLASSROOM_CANCELSCHEDULE_SUCCESS/summaryday_classrooms',
  SET_WITHOUT_DEADLINE: 'SET_WITHOUT_DEADLINE/summaryday_classrooms',
  SET_CLASSROOM_APPROVECHEDULE_REQUEST:
    'SET_CLASSROOM_APPROVECHEDULE_REQUEST/summaryday_classrooms',
  SET_FLAGS_ACTIVITY: 'SET_FLAGS_ACTIVITY/summaryday_classrooms',
  ADD_COMMENT_ACTIVITY_SUCCESS:
    'ADD_COMMENT_ACTIVITY_SUCCESS/summaryday_classrooms',
  ADD_COMMENT_ACTIVITY_REQUEST:
    'ADD_COMMENT_ACTIVITY_REQUEST/summaryday_classrooms',
  SET_COMMENT_HIDE_REQUEST: 'SET_COMMENT_HIDE_REQUEST/summaryday_classrooms',
  SET_COMMENT_HIDE_SUCCESS: 'SET_COMMENT_HIDE_SUCCESS/summaryday_classrooms',
  FETCH_COMMENT_SECTIONS_REQUEST:
    'FETCH_COMMENT_SECTIONS_REQUEST/summaryday_classrooms',
  FETCH_COMMENT_SECTIONS_SUCCESS:
    'FETCH_COMMENT_SECTIONS_SUCCESS/summaryday_classrooms',
  FETCH_TOTAL_COMMENTS_REQUEST:
    'FETCH_TOTAL_COMMENTS_REQUEST/summaryday_classrooms',
  FETCH_TOTAL_COMMENTS_SUCCESS:
    'FETCH_TOTAL_COMMENTS_SUCCESS/summaryday_classrooms',
  SET_UPDATE_NOTIFICATION_REQUEST:
    'SET_UPDATE_NOTIFICATION_REQUEST/summaryday_classrooms',
  SET_UPDATE_NOTIFICATION_SUCCESS:
    'SET_UPDATE_NOTIFICATION_SUCCESS/summaryday_classrooms',

  fetchClassroomsList: (queryParams) => ({
    type: actions.FETCH_CLASSROOMS_REQUEST,
    queryParams,
  }),
  resetClassroomsList: () => ({ type: actions.RESET_CLASSROOMS_LIST }),
  buildToast: (message, typeToast) => ({
    type: actions.BUILD_TOAST,
    message,
    typeToast,
  }),
  fetchDisciplines: () => ({
    type: actions.FETCH_DISCIPLINES,
  }),
  setClassrooms: (id = null) => ({ type: actions.SET_CLASSROOMS, payload: id }),
  addSummarydayClassrooms: (activity) => ({
    type: actions.ADD_SUMMARYDAY_CLASSROOMS,
    payload: activity,
  }),

  setSummarydayClassroom: (id) => ({
    type: actions.SET_SUMMARYDAY_CLASSROOM,
    payload: id,
  }),

  setDuplicateActivity: (id) => ({
    type: actions.SET_DUPLICATE_ACTIVITY_REQUEST,
    payload: id,
  }),

  updateSummarydayClassroom: (id, activity) => ({
    type: actions.UPDATE_SUMMARYDAY_CLASSROOM,
    payload: { id, activity },
  }),

  fetchEducationalStages: (headquarterValue) => ({
    type: actions.FETCH_EDUCATIONAL_STAGES_REQUEST,
    headquarterValue,
  }),
  fetchClassroomFilter: (headquarterValue, educationalStageValue) => ({
    type: actions.FETCH_CLASSROOM_FILTER_REQUEST,
    headquarterValue,
    educationalStageValue,
  }),
  setClassroomApprove: (activityId) => ({
    type: actions.SET_CLASSROOM_APPROVE_REQUEST,
    activityId,
  }),

  fetchClassroomDetails: (activityId) => ({
    type: actions.FETCH_CLASSROOM_DETAILS_REQUEST,
    activityId,
  }),

  fetchClassroomDelete: (classroom) => ({
    type: actions.FETCH_CLASSROOM_DELETE_REQUEST,
    classroom,
  }),

  setClassroomDisapprove: (activityId, cancelReason) => ({
    type: actions.DISAPPROVE_CLASSROOM_REQUEST,
    activityId,
    cancelReason,
  }),

  fetchActivityEngagement: (activityId, page) => ({
    type: actions.FETCH_ACTIVITY_ENGAGEMENT_REQUEST,
    activityId,
    page,
  }),

  fetchActivityEngagementPagination: (activityId, page) => ({
    type: actions.FETCH_ACTIVITY_ENGAGEMENT_REQUEST_PAGINATION,
    activityId,
    page,
  }),

  fetchActivityComments: (queryParams) => ({
    type: actions.FETCH_ACTIVITY_COMMENTS_REQUEST,
    queryParams,
  }),

  fetchActivityCommentsPagination: (activityId, page) => ({
    type: actions.FETCH_ACTIVITY_COMMENTS_REQUEST_PAGINATION,
    activityId,
    page,
  }),

  activityIdDuplicate: (id) => ({
    type: actions.SET_ACTIVITY_ID_DUPLICATE_REQUEST,
    id,
  }),

  setClassroomSchedule: (activityId, dateTimeSchedule, scheduleType) => ({
    type: actions.SET_CLASSROOM_SCHEDULE_REQUEST,
    activityId,
    dateTimeSchedule,
    scheduleType,
  }),

  setClassroomReschedule: (activityId, dateTimeSchedule, scheduleType) => ({
    type: actions.SET_CLASSROOM_SCHEDULE_REQUEST,
    activityId,
    dateTimeSchedule,
    scheduleType,
  }),

  setClassroomResend: (activityId) => ({
    type: actions.SET_ACTIVITY_RESEND_REQUEST,
    activityId,
  }),

  setClassroomCancelSchedule: (activityId) => ({
    type: actions.SET_CLASSROOM_CANCELSCHEDULE_REQUEST,
    activityId,
  }),

  classroomApproveSchedule: (activityId) => ({
    type: actions.SET_CLASSROOM_APPROVECHEDULE_REQUEST,
    activityId,
  }),

  setFlagsActivity: (typeFlag, isActive) => ({
    type: actions.SET_FLAGS_ACTIVITY,
    typeFlag,
    isActive,
  }),

  addCommentActivity: (payload) => ({
    type: actions.ADD_COMMENT_ACTIVITY_REQUEST,
    payload,
  }),

  setCommentHide: (payload) => ({
    type: actions.SET_COMMENT_HIDE_REQUEST,
    payload,
  }),

  fetchCommentSections: (activityId) => ({
    type: actions.FETCH_COMMENT_SECTIONS_REQUEST,
    activityId,
  }),

  fetchTotalComments: (activityId) => ({
    type: actions.FETCH_TOTAL_COMMENTS_REQUEST,
    activityId,
  }),

  setUpdateNotification: (activityId) => ({
    type: actions.SET_UPDATE_NOTIFICATION_REQUEST,
    activityId,
  }),
};

export default actions;
