export const reviewFormRedirect = (
  redirect: string,
  nextId: number
): string => {
  const query = nextId
    ? '?situation=pending&notice_pending_reviews=true'
    : '?notice_completed_reviews=true';

  return !redirect.includes('review') ? `${redirect}${query}` : redirect;
};
