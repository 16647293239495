import React from 'react';
import Tab from 'components/WizardForm/Tab';

import './Tabs.scss';

export default class Tabs extends React.Component {
  render() {
    const { steps, isStepActive, goToStep, CurrentStep } = this.props;

    return (
      <div className="Tabs">
        {steps.map((step, index) => (
          <Tab
            title={step.title}
            isActive={isStepActive(step)}
            goToStep={goToStep}
            index={index}
            key={step.title}
            CurrentStep={CurrentStep}
            step={step}
          />
        ))}
      </div>
    );
  }
}
