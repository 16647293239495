import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as S from '../styles';

import { OmniChannelState } from 'store/messages/omniChannel/types';

export const ChannelInformationDetail = () => {
  const { t } = useTranslation(['messages']);

  const { activeChannel, activeChat, chatIsSearchTab } = useSelector(
    (state: OmniChannelState) => state.omniChannel
  );

  const isResponsibleProfile =
    activeChat?.relationships.main_user.data.type === 'responsible_profile';

  const days = activeChannel?.attendingHours?.days;

  return (
    <S.InformationWrapper data-testid="channel-modal">
      <S.Description>
        {t('omni_channel.modals.details_chat.description')}
      </S.Description>
      <S.InfoBlock>
        <S.Label>{t('omni_channel.modals.details_chat.chat_id_label')}</S.Label>
        <S.Text>
          {t('omni_channel.modals.details_chat.chat_id_text', {
            id: activeChat.id,
          })}
        </S.Text>
      </S.InfoBlock>
      {!chatIsSearchTab && (
        <S.InfoBlock>
          <S.Label>
            {t('omni_channel.modals.details_chat.chat_status_label')}
          </S.Label>
          <S.Text>
            {t(
              `omni_channel.modals.details_chat.chat_status_${activeChat.attributes.read_status}_text`
            )}
          </S.Text>
        </S.InfoBlock>
      )}
      <S.InfoBlock>
        <S.Label>{t('omni_channel.modals.details_chat.channel_label')}</S.Label>
        <S.Text>
          {t('omni_channel.modals.details_chat.channel_text', {
            id: activeChannel.id,
            name: activeChannel.name,
          })}
        </S.Text>
      </S.InfoBlock>
      {isResponsibleProfile && (
        <S.InfoBlock>
          <S.Label>
            {t('omni_channel.modals.details_chat.student_name_label')}
          </S.Label>
          <S.Text>{activeChat.attributes.student_profile_name}</S.Text>
        </S.InfoBlock>
      )}

      {days && (
        <S.InfoBlock>
          <S.Label>
            {t('omni_channel.modals.details_chat.attending_hours_label')}
          </S.Label>
          <S.AttendingHoursWrapper>
            {[
              ...Object.entries(days).filter(([day]) => day !== '0'),
              Object.entries(days).find(([day]) => day === '0'),
            ].map(([day, { active, hours }]) => {
              if (!active) return;

              return (
                <S.Text key={day}>
                  {t(`omni_channel.modals.attending_hours_common.days.${day}`, {
                    openHour: hours[0],
                    closeHour: hours[1],
                  })}
                </S.Text>
              );
            })}
          </S.AttendingHoursWrapper>
        </S.InfoBlock>
      )}
    </S.InformationWrapper>
  );
};
