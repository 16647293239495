import React from 'react';

import FilterContainer, {
  DoubleInputsWrapper,
  HalfInputWrapper,
} from 'components/FilterContainer';

import AgendaIcon from 'components/AgendaIcon';
import Field from 'components/Form/Field';
import Tooltip, { TOP_CENTER } from 'components/Tooltip';
import Search from 'components/Form/Search';
import ButtonAndSideFilter from 'components/Payments/ButtonAndSideFilter';

import { EnrollmentFiltersProps } from './types';

const EnrollmentFilters = ({
  form,
  formMeta,
  handleFilterSubmit,
}: EnrollmentFiltersProps) => {
  const { contract_status: formContractStatus, status: formStatus } = form;
  const {
    select_options: { contract_status: metaContractStatus, status: metaStatus },
    amount,
  } = formMeta;

  const tooltipsLabel = ({ label, description }) => (
    <React.Fragment>
      <span>{label}</span>
      <Tooltip content={description} position={TOP_CENTER}>
        <AgendaIcon name="help" size="small" className="custom-size" />
      </Tooltip>
    </React.Fragment>
  );

  return (
    <FilterContainer
      form={form}
      formMeta={formMeta}
      testID="enrollmentFiltersWrapper"
    >
      <DoubleInputsWrapper>
        <Field
          label={tooltipsLabel({
            label: 'Período de vencimento',
            description: () => (
              <>
                Selecione o período para as datas
                <br />
                de vencimento da matrícula
              </>
            ),
          })}
          className="dateRange"
          testID="rangeDate"
          type="rangeDate"
          attributeName="range"
          onSubmit={(e) => handleFilterSubmit({ range: e.range, page: 1 })}
          showClearDates={false}
        />
        <Field
          label="Contrato"
          testID="contractStatus"
          attributeName="contract_status"
          type="selectWithIcon"
          value={formContractStatus}
          options={metaContractStatus}
          onChange={(e) =>
            handleFilterSubmit({ contract_status: e.value, page: 1 })
          }
          fullWidth
          withArrowSelect
        />
      </DoubleInputsWrapper>
      <DoubleInputsWrapper>
        <Field
          label="Pagamento"
          testID="status"
          attributeName="status"
          type="selectWithIcon"
          value={formStatus}
          options={metaStatus}
          onChange={(e) => handleFilterSubmit({ status: e.value, page: 1 })}
          fullWidth
          withArrowSelect
        />
        <HalfInputWrapper>
          <Search
            label="Buscar"
            testID="searchInput"
            placeholder="Digite o nome do aluno"
            attributeName="school_product_title"
            onSubmit={(params) =>
              handleFilterSubmit({
                school_product_title: params.school_product_title,
                page: 1,
              })
            }
          />
          <ButtonAndSideFilter
            handleFilter={handleFilterSubmit}
            amount={amount}
          />
        </HalfInputWrapper>
      </DoubleInputsWrapper>
    </FilterContainer>
  );
};

export default EnrollmentFilters;
