import styled, { css } from 'styled-components';
import ModalContainer from 'components/Modal/ModalContainer';
import { ThemeProps } from 'config/themes/types';

export const ModalWrapper = styled(ModalContainer)`
  .ReactModal__Content {
    width: 600px !important;
    padding: 32px !important;
    .title {
      width: 100%;
    }
  }
`;

export const ConteinerCharacterCount = styled.div`
  ${({ theme: { space, typography } }: ThemeProps) => css`
    ${typography.label.Medium14}
    text-align: right;
    margin-top: ${space.xs};
  `}
`;
