const truncateName = (name, limit) => {
  if (name.length < limit) return name;

  const initName = name.slice(0, limit - 10);
  const endName = name.slice(name.length - 10, name.length);

  return `${initName} ... ${endName}`;
};

export const truncateWithFileExtension = (name, limit) => {
  if (name.length < limit) return name;

  const initName = name.slice(0, limit - 10);

  const extension = name.slice(name.lastIndexOf('.'));

  return `${initName} ...${extension}`;
};

export default truncateName;
