export const SIZES_MAPPING = {
  extraSmall: 'col-md-2',
  smaller: 'col-md-3',
  small: 'col-md-4',
  half: 'col-md-6',
  medium: 'col-md-8',
  large: 'col-md-12',
};

export const MOBILE_SIZE_MAPPING = {
  small: 'col-xs-3',
  medium: 'col-xs-6',
  large: 'col-xs-9',
  extraLarge: 'col-xs-12',
};
