import styled, { css } from 'styled-components';

import { SubtleIconButton } from '@agendaedu/ae-web-components';

import { ThemeProps } from 'config/themes/types';

export const CategoryTooltip = {
  maxWidth: '120px',
  marginLeft: '-12px',
};

export const SettingsTooltip = {
  maxWidth: '120px',
  marginLeft: '-12px',
};

const SkeletonModifiers = {
  withSpaceBetween: () => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
  `,
  withFlexGap: (space: string) => css`
    display: flex;
    gap: ${space};
  `,
};

export const NewHandoutButtonWrapper = styled.div`
  ${({ theme: { colors } }: ThemeProps) => css`
    a:hover,
    a:active {
      color: ${colors.neutral.white};
    }
  `}
`;

export const HandoutFiltersWrapper = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    width: 100%;
    ${SkeletonModifiers.withFlexGap(space.xl2)};
    flex-direction: column;
  `}
`;

export const FiltersSkeletonWrapper = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    width: 100%;
    ${SkeletonModifiers.withFlexGap(space.xl2)};
    flex-direction: column;
  `}
`;

export const FieldSkeleton = styled.div`
  ${({ theme: { colors, border } }: ThemeProps) => css`
    width: 100%;
    height: 40px;
    border-radius: ${border.radius.md};
    background-color: ${colors.neutral.gray5};
  `}
`;

export const HeaderSkeleton = styled.div`
  width: 100%;
  ${SkeletonModifiers.withSpaceBetween()};
  gap: ${({ theme: { space } }: ThemeProps) => space.sm};
`;

export const FiltersSkeleton = styled.div`
  ${({ theme: { space, breakpoints } }: ThemeProps) => css`
    ${SkeletonModifiers.withSpaceBetween()};
    margin-bottom: ${space.xl2};

    @media screen and (min-width: ${breakpoints.mobileSM}) {
      flex-direction: column;
      gap: ${space.sm};
      align-items: flex-end;
    }

    @media screen and (min-width: ${breakpoints.desktopXL}) {
      flex-direction: row;
    }
  `};
`;

export const FieldsSkeleton = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    width: 100%;
    ${SkeletonModifiers.withFlexGap(space.sm)};
  `}
`;

export const ButtonsSkeleton = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    ${SkeletonModifiers.withFlexGap(space.sm)};
  `}
`;

export const SkeletonWrapper = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    width: 100%;
    ${SkeletonModifiers.withFlexGap(space.xl2)};
    flex-direction: column;
  `}
`;

export const HandoutTitle = styled.h2`
  ${({ theme: { colors, space, typography } }: ThemeProps) => css`
    ${typography.headline.H2Bold24};
    color: ${colors.neutral.black};
    margin: ${space.xs4};
  `}
`;

export const ExtraActionsButton = styled(SubtleIconButton)`
  ${({ theme: { colors, border } }: ThemeProps) => css`
    > button {
      border: ${border.width.sm} ${border.style.solid}
        ${colors.brand.primary.default};
      background-color: ${colors.neutral.white};
      color: ${colors.brand.primary.default};

      svg {
        path {
          fill: ${colors.brand.primary.default};
        }
      }

      &:hover {
        background-color: ${colors.brand.primary.op10};
        border: ${border.width.sm} ${border.style.solid}
          ${colors.brand.primary.op10};
      }

      &:active {
        background-color: ${colors.brand.primary.op20};
        border: ${border.width.sm} ${border.style.solid}
          ${colors.brand.primary.op20};
      }

      &:disabled {
        cursor: not-allowed;
        color: ${colors.neutral.gray3};
        background-color: ${colors.neutral.white};
        border: ${border.width.sm} ${border.style.solid} ${colors.neutral.gray5};

        > svg {
          path {
            fill: ${colors.neutral.gray3};
          }
        }
      }
    }
  `}
`;

export const WrapperButton = styled.div<{ canWriteHandoutCategory: boolean }>`
  ${({ theme: { space }, canWriteHandoutCategory }) => css`
    margin-right: ${space.sm};

    ${canWriteHandoutCategory &&
    css`
      cursor: not-allowed;

      a {
        pointer-events: none;
      }
    `}
  `}
`;

export const WrapperSettingsButton = styled.div`
  ${({ theme: { space } }) => css`
    margin-right: ${space.sm};
  `}
`;
