import styled, { css } from 'styled-components';

import { ThemeProps } from 'config/themes/types';

export const ContentWrapper = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    flex-direction: column;
    min-width: 320px;
    max-width: 80vw;
    padding: 0 ${space.xs3};
    gap: ${space.xs2};
  `}
`;

export const ButtonsWrapper = styled.div`
  ${({ theme: { space } }: ThemeProps) => css`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: ${space.xs};
  `}
`;

export const Separator = styled.div`
  ${({ theme: { border, colors, space } }: ThemeProps) => css`
    width: 100%;
    border-bottom: ${border.width.sm} ${border.style.solid}
      ${colors.neutral.gray4};
    margin: ${space.xs2} 0;
  `}
`;
