import validatePresence from 'core/lib/FormValidator/validators/presence';

export default [
  {
    attributeName: 'type',
    validator: validatePresence,
    message: 'Escolha um tipo de conta',
  },
  {
    attributeName: 'code',
    validator: validatePresence,
    message: 'Escolha seu banco',
  },
  {
    attributeName: 'agency',
    validator: validatePresence,
    message: 'Campo obrigatório',
  },
  {
    attributeName: 'account',
    validator: validatePresence,
    message: 'Campo obrigatório',
  },
  {
    attributeName: 'account_dv',
    validator: validatePresence,
    message: 'Campo obrigatório',
  },
];
