import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import {
  buildToast,
  toastCss,
  ToastTypes,
} from 'store/middlewares/toast/actions';
import { fetchApi } from 'core/utils/api';

import actions from './actions';

function* fetchHeadquartersRequest() {
  try {
    const dataArea = yield select((state) => state.root.dataArea);

    const { data } = yield call(
      fetchApi,
      `/${dataArea}/headquarters/with_active_school_term`
    );

    yield put({
      type: actions.FETCH_HEADQUARTERS_SUCCESS,
      headquarters: data,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        'Não foi possível obter a listagem de unidades.',
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* fetchEducationalStagesRequest(action) {
  try {
    const { headquarter } = action;

    const { data } = yield call(
      fetchApi,
      `/schools/headquarters/${headquarter}/educational_stages`
    );

    yield put({
      type: actions.FETCH_EDUCATIONAL_STAGES_SUCCESS,
      educationalStages: data,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
    });
  }
}

function* fetchClassroomsRequest(action) {
  try {
    const { headquarter, educationalStage } = action;

    const { data } = yield call(
      fetchApi,
      `/schools/headquarters/${headquarter}/${educationalStage}/classrooms`
    );

    yield put({
      type: actions.FETCH_CLASSROOMS_SUCCESS,
      classrooms: data,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
    });
  }
}

function* fetchChartsRequest(action) {
  try {
    const {
      selectedReport,
      selectedHeadquarter,
      selectedEducationalStage,
      selectedClassroom,
      startDate,
      endDate,
    } = yield select((state) => state.panel);

    const config = {
      params: {
        report_id: selectedReport,
        headquarter_id: selectedHeadquarter,
        educational_stage_id: selectedEducationalStage,
        classroom_id: selectedClassroom,
        start_date: startDate,
        end_date: endDate,
      },
    };

    const { history } = yield call(fetchApi, '/schools/panel.json', config);

    yield put({
      type: actions.FETCH_CHARTS_SUCCESS,
      history,
      filters: action.filters,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
    });
  }
}

function* fetchReportsRequest() {
  try {
    const { data } = yield call(fetchApi, '/schools/reports');

    yield put({
      type: actions.FETCH_REPORTS_SUCCESS,
      data,
    });
  } catch (error) {
    yield put({
      type: actions.ERROR,
      error,
      toast: buildToast(
        'Não foi possível obter a listagem de relatórios.',
        toastCss(ToastTypes.error)
      ),
    });
  }
}

function* panelSagas() {
  yield all([
    takeLatest(actions.FETCH_HEADQUARTERS, fetchHeadquartersRequest),
    takeLatest(actions.FETCH_EDUCATIONAL_STAGES, fetchEducationalStagesRequest),
    takeLatest(actions.FETCH_CLASSROOMS, fetchClassroomsRequest),
    takeLatest(actions.FETCH_CHARTS, fetchChartsRequest),
    takeLatest(actions.FETCH_REPORTS, fetchReportsRequest),
  ]);
}

export default panelSagas;
