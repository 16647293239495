import React from 'react';
import PropTypes from 'prop-types';
import { FixedSizeList as List } from 'react-window';
import * as S from './styles';

const DefaultItemHeight = 45;

const CustomMenuList = ({
  children,
  options,
  maxHeight,
  getValue,
  loading,
}) => {
  const [value] = getValue();
  const initialOffset = options.indexOf(value) * DefaultItemHeight;
  const childrenOptions = React.Children.toArray(children);
  const menuHeight =
    maxHeight < childrenOptions.length * DefaultItemHeight
      ? maxHeight
      : childrenOptions.length * DefaultItemHeight;

  if (loading) {
    return (
      <S.MenuWrapper>
        <span>Carregando...</span>
      </S.MenuWrapper>
    );
  }

  if (childrenOptions[0].key === '.0') {
    return (
      <S.MenuWrapper>
        <span>Sem resultados</span>
      </S.MenuWrapper>
    );
  }

  if (!loading && childrenOptions[0].key !== '.0') {
    return (
      <List
        className="CustomMenuList"
        height={menuHeight}
        itemCount={children.length}
        itemSize={DefaultItemHeight}
        initialScrollOffset={initialOffset}
      >
        {({ index, style }) => <div style={style}>{children[index]}</div>}
      </List>
    );
  }
};

CustomMenuList.propTypes = {
  children: PropTypes.node.isRequired,
  maxHeight: PropTypes.number,
  getValue: PropTypes.func,
  loading: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
    })
  ),
};

export default CustomMenuList;
