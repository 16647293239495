const recurrentPlanStatus = {
  active: {
    text: 'Aberto',
    variation: 'success',
  },
  closed: {
    text: 'Concluído',
    variation: 'default',
  },
  canceled: {
    text: 'Cancelado',
    variation: 'dimming',
  },
};

export default recurrentPlanStatus;
