import React, { useState, useEffect } from 'react';

import tabifyForWizard from 'core/hoc/tabifyForWizard';
import withFormContext, { formPropTypes } from 'core/hoc/withFormContext';

import FormFieldset from 'components/Form/Fieldset';
import ColoredText from 'components/ColoredText';

import BillConfiguration from 'components/Payments/PaymentsContainer/FormSections/BillConfiguration';
import GeneralInformations from 'components/Payments/PaymentsContainer/FormSections/GeneralInformations';
import EdupayDiscount from 'components/Payments/PaymentsContainer/FormSections/EdupayDiscount';
import SimpleSummary from 'components/Payments/PaymentsContainer/FormSections/SimpleSummary';
import WalletConfiguration from 'components/Payments/PaymentsContainer/FormSections/WalletConfiguration';
import SchoolProductModal from '../FormSections/SchoolProductModal';

import validations from './validations';
import './style.scss';

const SchoolProductFormInformationsTab = ({
  formContext: {
    form: { billet_expires_at: billetExpiresAt, price },
    formMeta: { old_billet_expiration: oldBilletExpiration, status },
  },
}) => {
  const [billetModalOpen, setBilletModalOpen] = useState(false);
  const [confirmBilletChange, setConfirmBilletChange] = useState(false);

  const changedBilletDate =
    status === 'sent' &&
    oldBilletExpiration !== '' &&
    oldBilletExpiration !== billetExpiresAt;

  useEffect(() => {
    if (!confirmBilletChange && changedBilletDate) {
      setBilletModalOpen(true);
    }
  }, [changedBilletDate, confirmBilletChange]);

  const handleConfirmBilletChange = () => {
    setBilletModalOpen(false);
    setConfirmBilletChange(true);
  };

  const renderSubtitleModal = () => (
    <>
      <ColoredText variation="bold gray-2">
        Os boletos que já foram gerados continuarão com a data de vencimento
        anterior,
      </ColoredText>{' '}
      apenas os novos boletos gerados terão a nova data de vencimento.
    </>
  );

  return (
    <div className="SchoolProductFormInformationsTab">
      <FormFieldset>
        <WalletConfiguration />
        <GeneralInformations withLegacyId />
        <BillConfiguration isSchoolProduct />
        <EdupayDiscount
          variation="schoolProduct"
          validations={validations}
          showCreditCardDiscount
          isNumericField
        />
        <SimpleSummary showTitle price={price} />
      </FormFieldset>
      <SchoolProductModal
        title="Atenção sobre mudança na data de vencimento do boleto"
        subtitle={renderSubtitleModal()}
        labelButton="Entendi"
        isOpen={billetModalOpen}
        onClick={handleConfirmBilletChange}
      />
    </div>
  );
};

SchoolProductFormInformationsTab.propTypes = {
  ...formPropTypes,
};

export default tabifyForWizard({
  title: '1. Informações',
  subTitle: 'Informações gerais',
  validations,
})(withFormContext(SchoolProductFormInformationsTab));
