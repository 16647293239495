import React from 'react';
import AgendaIcon from 'components/AgendaIcon';

import * as S from './styles';
import { Props } from './types';

const StudentSearch = ({
  search,
  setSearch,
  placeholder = 'Buscar',
}: Props) => {
  const onClickClearButton = () => {
    setSearch('');
  };

  return (
    <S.SearchContainer>
      <AgendaIcon className="search-icon" name="search" />
      <S.SearchInput
        type="text"
        placeholder={placeholder}
        onChange={(event) => setSearch(event.target.value)}
        value={search}
        data-testid="search-student-input"
      />
      {search && (
        <AgendaIcon
          name="close"
          onClick={onClickClearButton}
          className="clear-button"
        />
      )}
    </S.SearchContainer>
  );
};

export default StudentSearch;
