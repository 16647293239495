import styled, { css } from 'styled-components';
import colorWithAlpha from 'core/utils/colorWithAlpha';
import { ThemeProps } from 'config/themes/types';

export const EnrollmentPlanFormContractTab = styled.div`
  input[type='file'] {
    display: none;
  }
  .Dropdown {
    margin-bottom: 16px;

    .menu {
      padding: 2px 0;
      border-radius: 6px;

      .open {
        height: 46px;
      }
    }
  }
`;

export const ContractInfo = styled.p`
  ${({ theme }) => css`
    margin-bottom: 24px;
    font-family: 'Roboto';
    font-size: 14px;
    color: ${theme.gray16};
    text-align: left;
  `}
`;

export const ErrorSize = styled.p`
  ${({ theme }) => css`
    color: ${theme.danger};
  `}
`;

export const FileInputContainer = styled.label`
  ${({ theme }) => css`
    width: 198px;
    height: 35px;
    margin: 4px;
    padding: 8px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    color: ${theme.gray16};
    font-family: 'Roboto';

    &:hover {
      color: ${theme.primaryColor};
      background-color: ${colorWithAlpha(`${theme.primaryColor}`, 0.1)};
    }
  `}
`;

export const ButtonTrigger = styled.button`
  ${({ theme, dropdownDisabled }) => css`
    width: 208px;
    height: 41px;
    background-color: white;
    border-radius: 6px;
    border: solid 1px ${dropdownDisabled ? theme.gray5 : theme.primaryColor};
    cursor: ${dropdownDisabled ? `not-allowed` : `point`};

    .TextContainer {
      font-family: 'Quicksand';
      font-size: 16px;
      font-weight: bold;
      color: ${dropdownDisabled ? theme.gray10 : theme.primaryColor};
    }
  }

    .RightIcon {
      width: 16px;
      height: 16px;
      margin-left: 26px;
      color: ${dropdownDisabled ? theme.gray10 : theme.primaryColor};

      i {
        font-size: 14px;
      }
    }

    &:hover {
      background-color: white;
      border: solid 1px ${dropdownDisabled ? theme.gray10 : theme.gray5};
    }
  `}
`;

export const TagContainer = styled.div`
  ${({ theme: { space } }) => css`
    display: flex;
    flex-wrap: wrap;
    gap: ${space.xs};
    margin-bottom: ${space.lg};
  `}
`;

export const InfoText = styled.p`
  ${({ theme: { typography, space } }) => css`
    ${typography.body.Regular12}
    margin-top: ${space.lg}
  `}
`;

export const Label12 = styled.p`
  ${({ theme: { typography } }) => css`
    ${typography.label.Regular14}
    margin-top: 0px;
  `}
`;
