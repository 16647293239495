import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import withFormContext from 'core/hoc/withFormContext';
import tabifyForWizard from 'core/hoc/tabifyForWizard';
import { useTranslation } from 'react-i18next';
import Field from 'components/Form/Field';
import GroupSelector from 'components/Form/GroupSelector';
import { Box, Text } from '@agendaedu/ae-web-components';

import * as S from './styles';

const SchoolTermProgressionForm = ({
  formContext: {
    form,
    formMeta,
    updateAttribute,
    addErrorOnAttribute,
    removeErrorOnAttribute,
  },
}) => {
  const { t } = useTranslation(['school_terms']);

  const { classroom_ids, name } = form;
  const { classrooms } = formMeta;
  const year = new Date().getFullYear();
  var reg = new RegExp('^[0-9|.]+$');

  const mandatoryFieldsAreFilled = () => {
    const onlyNumbersAndDots = reg.test(name);

    return name?.replace(/\s/g, '')?.length > 3 && onlyNumbersAndDots;
  };

  useEffect(() => {
    mandatoryFieldsAreFilled()
      ? updateAttribute('canSave', true)
      : updateAttribute('canSave', false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classroom_ids, name, updateAttribute]);

  const onNameChange = ({ name }) => {
    if (name.length > 25) {
      addErrorOnAttribute('name', 'Limite máximo de 25 caracteres');
    } else {
      const onlyNumbersAndDots = reg.test(name);

      if (onlyNumbersAndDots) {
        updateAttribute('name', name);
        removeErrorOnAttribute('name');
      } else {
        addErrorOnAttribute(
          'name',
          `Formato aceito: ${year}, ${year}.1 ou ${year}.2, por favor ajuste.`
        );
      }
    }
  };

  return (
    <Box
      width="100%"
      display="flex"
      flexDirection="row"
      justifyContent="center"
      alignContent="center"
    >
      <Box
        width="575px"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignContent="center"
      >
        <Text
          variant="title-bold-20"
          color="neutral.black"
          fontWeight={700}
          lineHeight="34px"
        >
          {t('tab_component.title')}
        </Text>

        <Text
          variant="subtitle-medium-14"
          color="neutral.gray1"
          fontWeight={500}
          lineHeight="23.8px"
        >
          {t('tab_component.subtitle')}
        </Text>

        <Text
          variant="body-regular-12"
          color="neutral.gray1"
          fontWeight={400}
          lineHeight="20.4px"
          mb={24}
        >
          {t('tab_component.attention')}
        </Text>

        <S.ContainerField>
          <Field
            attributeName="name"
            label="Novo período*"
            type="text"
            placeholder={`Ex. ${year}, ${year}.1 ou ${year}.2`}
            onChange={onNameChange}
            size="half"
          />
        </S.ContainerField>

        <GroupSelector
          options={classrooms}
          attributeName="classroom_ids"
          lockSelected={!formMeta.canEditClassrooms}
        />
      </Box>
    </Box>
  );
};

SchoolTermProgressionForm.propTypes = {
  formContext: PropTypes.object.isRequired,
};

export default tabifyForWizard({
  validations: [
    {
      attributeName: 'name',
      validator: (input) => {
        return input?.length <= 25 ? true : false;
      },
      message: 'Limite máximo de 25 caracteres, por favor remova o excedente.',
    },
  ],
})(withFormContext(SchoolTermProgressionForm));
