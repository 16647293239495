import styled, { css } from 'styled-components';
import { ThemeProps } from 'config/themes/types';

export const BoxContainer = styled.div`
  ${({ theme }: ThemeProps) => css`
    [data-testid='card-wrapper'] {
      display: flex;
      flex-direction: column;
      padding: ${theme.space.xl2};
      border-radius: ${theme.space.xs};
      box-shadow: 0px 6px 11px 0px #733dd340;
    }

    .Form.vertical {
      width: 100%;

      .Field {
        padding: 0;
      }

      fieldset {
        margin-bottom: ${theme.space.sm};

        .ae-text-field {
          p {
            margin: ${theme.space.xs3} 0 0 0;
          }
        }
      }

      button {
        margin: ${theme.space.xs2} auto 0 auto;
        width: fit-content;
      }
    }
  `}
`;

export const FormTitle = styled.h4`
  ${({ theme: { colors, typography, space } }: ThemeProps) => css`
    margin: ${space.xl} 0 0 0;
    ${typography.title.Bold20};
    color: ${colors.neutral.black};
  `}
`;

export const CompletedFormTitle = styled.h6`
  ${({ theme: { colors, typography, space } }: ThemeProps) => css`
    margin-bottom: ${space.xl};
    ${typography.title.Bold20};
    color: ${colors.brand.primary.light};
  `}
`;

export const CompletedFormIllustration = styled.figure`
  ${({
    theme: {
      space: { xl },
    },
  }: ThemeProps) => css`
    margin: ${xl} 0;
  `}
`;

export const CompletedFormInfo = styled.p`
  ${({ theme: { colors, typography, space } }: ThemeProps) => css`
    margin-bottom: ${space.xl};
    ${typography.body.Regular16};
    color: ${colors.neutral.gray1};
    text-align: center;
  `}
`;

export const PasswordNew = styled.span`
  ${({ theme: { colors, typography, space } }: ThemeProps) => css`
    display: block;
    margin-bottom: ${space.lg};
    ${typography.label.Regular14};
    color: ${colors.neutral.gray1};
    text-align: center;

    a {
      color: ${colors.brand.primary.light};
    }
  `}
`;

export const BoxContainerFooter = styled.span`
  ${({ theme }: ThemeProps) => css`
    display: block;
    margin: 40px 0 56px 0;
    width: 100%;
    text-align: center;

    color: ${theme.colors.neutral.gray2};
    font-family: ${theme.font.family.auxiliary};
    font-size: ${theme.font.size.xs};
    font-weight: ${theme.font.weight.regular};
    line-height: ${theme.font.height.lg};
    font-style: normal;
  `}
`;

export const BoxContainerLink = styled.a`
  text-decoration: revert;
  font: inherit !important;
  color: inherit !important;
`;
