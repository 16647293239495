import React from 'react';
import { Box, DefaultThemeProps, theme } from '@agendaedu/ae-web-components';

import Skeleton from 'components/Skeleton';

export const SurveySkeleton = () => {
  const defaultTheme: DefaultThemeProps = theme;

  const {
    colors: { neutral },
    border: { radius },
  } = defaultTheme;

  return (
    <Box key="skeleton-item" display="flex" width="100%">
      <Skeleton
        width="100%"
        height="100px"
        primaryColor={neutral.gray6}
        borderRadius={radius.md}
        isFullWidth
      />
    </Box>
  );
};
