import validateArrayPresence from 'core/lib/FormValidator/validators/array/presence';
import {
  validateFinePercent,
  validateInterestPercent,
} from 'core/lib/FormValidator/validators/penality';
import validatePresence from 'core/lib/FormValidator/validators/presence';
const maxPrice = 99999999.99;

const validateNegotiationBills = (input, state) => {
  let isValid = true;

  input.some((item) => {
    if (
      item.price_kind === 'percent' &&
      ((state.selected_value_cents / 100) * item.price_value) / 100 < 10
    )
      isValid = false;
    if (
      item.discount_value < 0 ||
      (item.price_kind === 'money' && item.price_value < 10)
    )
      isValid = false;
  });

  return state.negotiationSubtotal >= 10.0 &&
    state.negotiationSubtotal <= maxPrice
    ? isValid
    : false;
};

export default [
  {
    attributeName: 'allowed_payment_method',
    validator: validateArrayPresence,
    message: 'Selecione um método de pagamento para continuar',
  },
  {
    attributeName: 'first_bill_due_date',
    validator: validatePresence,
    message: 'Este campo é de preenchimento obrigatório',
  },
  {
    attributeName: 'due_day',
    validator: validatePresence,
    message: 'Este campo é de preenchimento obrigatório',
  },
  {
    attributeName: 'bill_release_day',
    validator: validatePresence,
    message: 'Este campo é de preenchimento obrigatório',
  },
  {
    attributeName: 'observation',
    validator: validatePresence,
    message: 'Este campo é de preenchimento obrigatório',
  },
  {
    attributeName: 'edupay_penality',
    validator: validateFinePercent,
    message: 'A multa é de no máximo 2%',
  },
  {
    attributeName: 'edupay_penality',
    validator: validateInterestPercent,
    message: 'Os juros são de no máximo 1% a.m.',
  },
  {
    attributeName: 'negotiationBills',
    validator: validateNegotiationBills,
    message: 'Possui itens inválidos, reveja os valores informados',
  },
];
