import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme, isActive }) => css`
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    width: fit-content;
    margin-left: auto;
    order: 4;
  `}
`;
