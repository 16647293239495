import React from 'react';
import PropTypes from 'prop-types';

import Avatar from 'components/Avatar';
import ClassroomsList from 'components/ClassroomsList';

import './style.scss';

function StudentDisplay({ user, withClassrooms, size }) {
  return (
    <div className="StudentDisplay">
      <Avatar user={user} size={size} />
      <div className="student-info">
        <span className="name">{user.attributes.name}</span>
        {withClassrooms && (
          <ClassroomsList classroomsNames={user.attributes.classroom_names} />
        )}
      </div>
    </div>
  );
}

StudentDisplay.propTypes = {
  user: PropTypes.shape({
    attributes: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
  }).isRequired,
  withClassrooms: PropTypes.bool,
  size: PropTypes.string,
};

StudentDisplay.defaultProps = {
  withClassrooms: false,
};

export default StudentDisplay;
