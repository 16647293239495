import validatePresence from 'core/lib/FormValidator/validators/presence';
import validateLength from 'core/lib/FormValidator/validators/length';
import validateTextAreaPresence from 'core/lib/FormValidator/validators/textAreaPresence';

const reportsTitlesToValidate = [];
const validations = [
  {
    attributeName: 'title',
    validator: validatePresence,
    message: 'Campo obrigatório',
  },
  {
    attributeName: 'description',
    validator: validateTextAreaPresence,
    message: 'Campo obrigatório',
  },
  {
    attributeName: 'description',
    validator: validateLength({ max: 160 }),
    message: 'Insira uma descrição de até 160 caracteres',
  },
  {
    attributeName: 'premium',
    validator: validatePresence,
    message: 'Campo obrigatório',
  },
  {
    attributeName: 'title',
    validator: (str) => !reportsTitlesToValidate.includes(str),
    message:
      'Um relatório com esse título já existe. Por favor, escolha um nome diferente.',
  },
];

export function handleReportsTitles(reportsTitles) {
  reportsTitlesToValidate.length = 0;
  reportsTitlesToValidate.push(...reportsTitles);
}

export default validations;
