import { CreateNewGroupRequestParams } from 'store/messages/types';

export const normalizeNewGroup = (values: CreateNewGroupRequestParams) => {
  const { name, description, member_ids, icon } = values;
  const formData = new FormData();

  formData.append('name', name);

  member_ids?.map((member) => {
    formData.append('member_ids[]', member.toString());
  });

  formData.append('description', description);
  icon && formData.append('icon', icon);

  return formData;
};
