import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import parse from 'html-react-parser';

import { RangeDate, Grid, Text } from '@agendaedu/ae-web-components';
import Loader from 'components/Loader';
import DropdownButton from 'components/DropdownButton';
import AvailabilityAccordion from 'components/Accordion/variations/availability';
import EmptyState from 'components/EmptyState';
import CardBalance from '../CardBalance';

import withFormContext from 'core/hoc/withFormContext';
import { IMAGES_PAYMENT_URL } from 'core/constants/index';
import {
  EXTRACT_FROM_OPTIONS,
  EXTRACT_STATUS,
  CARD_BALANCE_TOOLTIP_MESSAGES,
} from 'core/constants/wallet';
import { formatRangeDate } from 'core/utils/date';
import actionAvailabilities from 'store/edupay/availabilities/actions';
import walletsActions from 'store/edupay/wallets/actions';

import * as S from './styles';

const ExtractTab = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['payments', 'common']);

  const thirtyDaysAgo = moment().subtract(30, 'days');
  const now = moment();
  const thirtyDaysFromNow = moment().add(30, 'days');

  const [extractFrom, setExtractFrom] = useState(EXTRACT_FROM_OPTIONS[0].value);
  const [startRangeDate, setStartRangeDate] = useState(thirtyDaysAgo);
  const [endRangeDate, setEndRangeDate] = useState(now);

  const { dataAvailabilities, dataReceivables, meta, isLoading } = useSelector(
    (state) => state.availabilities
  );

  const { currentWallet, balance, isLoadingBalance } = useSelector(
    (state) => state.wallets
  );

  const { fetchAvailabilities, fetchReceivables } = actionAvailabilities;
  const { fetchAccountBalanceRequest } = walletsActions;

  const handleSelectExtractFrom = (option) => {
    setExtractFrom(option);

    if (option == EXTRACT_FROM_OPTIONS[0].value) {
      setStartRangeDate(thirtyDaysAgo);
      setEndRangeDate(now);
    } else {
      setStartRangeDate(now);
      setEndRangeDate(thirtyDaysFromNow);
    }
  };

  const handleRangeDateChange = ({ startDate, endDate }) => {
    setStartRangeDate(startDate);
    setEndRangeDate(endDate);
  };

  const isOutsideRange = (day) => {
    const today = moment(new Date());

    if (extractFrom === EXTRACT_STATUS.available) {
      return day > today;
    }
    return day <= today;
  };

  const filterExtracts = useCallback(() => {
    const filter = {
      startDate: formatRangeDate(startRangeDate),
      endDate: formatRangeDate(endRangeDate),
      typeQuery: extractFrom,
    };

    if (extractFrom === EXTRACT_STATUS.available) {
      return dispatch(fetchAvailabilities(filter));
    }
    return dispatch(fetchReceivables(filter));
  }, [
    currentWallet,
    startRangeDate,
    endRangeDate,
    extractFrom,
    dispatch,
    fetchAvailabilities,
    fetchReceivables,
  ]);

  const fetchBalance = () => {
    dispatch(
      fetchAccountBalanceRequest([
        formatRangeDate(startRangeDate),
        formatRangeDate(endRangeDate),
      ])
    );
  };

  const disabledButtonExport = () => {
    const tab = {
      available: dataAvailabilities.length,
      receivable: dataReceivables.length,
    };

    return !tab[extractFrom];
  };

  const getCardBalanceVariation = (cardType) => {
    return extractFrom === cardType ? cardType : 'inactive';
  };

  const renderExtractOfMonths = (extractOfMonths) => {
    return extractOfMonths.map((extract) => (
      <div key={extract.id}>
        <Text
          variant="title-bold-20"
          lineHeight="md"
          mt="sm"
          mb="lg"
          color={'neutral.black'}
        >
          {extract.attributes.month}
        </Text>

        {extract.attributes.days.map((day) => (
          <AvailabilityAccordion
            key={day.value}
            date={{
              formatted: day.formattedDate,
              value: day.value,
            }}
            income={day.income}
            numberOfPayments={day.number_of_availabilities}
            variation="info"
            selectedFilter={extractFrom}
          />
        ))}
      </div>
    ));
  };

  const renderEmptyState = () => (
    <EmptyState
      imgUrl={IMAGES_PAYMENT_URL.availabilityUrl}
      message={parse(t('wallet.tab_extract.empty_state_message'))}
    />
  );

  useEffect(() => {
    if (Boolean(startRangeDate) === Boolean(endRangeDate && !!currentWallet)) {
      filterExtracts();
    }
  }, [
    filterExtracts,
    currentWallet,
    extractFrom,
    startRangeDate,
    endRangeDate,
  ]);

  useEffect(() => {
    if (!!currentWallet) fetchBalance();
  }, [currentWallet, extractFrom, startRangeDate, endRangeDate]);

  return (
    <>
      <S.WrapperFilters extractFrom={extractFrom}>
        <Grid item gridArea="extract">
          <S.SelectFilter
            label={t('wallet.tab_extract.filters.extract_from')}
            options={EXTRACT_FROM_OPTIONS}
            value={extractFrom}
            onChange={(option) => {
              handleSelectExtractFrom(option.value);
            }}
            fullWidth
          />
        </Grid>
        <Grid item gridArea="rangeDate">
          <RangeDate
            label={t('wallet.tab_extract.filters.range_data')}
            value={{
              startDate: startRangeDate,
              endDate: endRangeDate,
            }}
            handleChange={handleRangeDateChange}
            handleOutsideRange={isOutsideRange}
          />
        </Grid>
        <S.ButtonExport item gridArea="export">
          <DropdownButton
            dropdownItems={[
              {
                path: `/schools/wallets/availabilities.xls?startDate=${formatRangeDate(
                  startRangeDate
                )}&endDate=${formatRangeDate(
                  endRangeDate
                )}&typeQuery=${extractFrom}`,
                text:
                  extractFrom === EXTRACT_STATUS.available
                    ? `${t(
                        'wallet.tab_extract.filters.button_export.dropdown_available_title'
                      )}(.xls)`
                    : `${t(
                        'wallet.tab_extract.filters.button_export.dropdown_receivable_title'
                      )}(.xls)`,
              },
            ]}
            text={t('wallet.tab_extract.filters.button_export.title')}
            variation="secondary"
            disabled={disabledButtonExport()}
          />
        </S.ButtonExport>
      </S.WrapperFilters>

      <S.WrapperCardsBalance>
        <Loader isLoading={isLoadingBalance}>
          <CardBalance
            title={t('wallet.card_balance.available')}
            value={balance?.available || 0}
            tooltipMessage={CARD_BALANCE_TOOLTIP_MESSAGES.available}
            variation={EXTRACT_STATUS.available}
          ></CardBalance>

          <CardBalance
            title={t('wallet.card_balance.received')}
            value={balance?.transferred || 0}
            tooltipMessage={CARD_BALANCE_TOOLTIP_MESSAGES.receivable}
            variation={getCardBalanceVariation(EXTRACT_STATUS.available)}
          ></CardBalance>

          <CardBalance
            title={t('wallet.card_balance.to_receive')}
            value={balance?.waiting_funds || 0}
            tooltipMessage={CARD_BALANCE_TOOLTIP_MESSAGES.waiting_funds}
            variation={getCardBalanceVariation(EXTRACT_STATUS.receivable)}
          ></CardBalance>
        </Loader>
      </S.WrapperCardsBalance>

      <S.WrapperNumberEntries>
        <Text
          variant="subtitle-medium-14"
          lineHeight="md"
          mb={0}
          color={'neutral.gray2'}
        >
          {`${meta.total_entries || 0} ${t('wallet.tab_extract.entries')}`}
        </Text>
      </S.WrapperNumberEntries>

      <Loader isLoading={isLoading}>
        {extractFrom === EXTRACT_STATUS.available
          ? dataAvailabilities.length
            ? renderExtractOfMonths(dataAvailabilities)
            : renderEmptyState()
          : dataReceivables.length
          ? renderExtractOfMonths(dataReceivables)
          : renderEmptyState()}
      </Loader>
    </>
  );
};

export default withFormContext(ExtractTab);
