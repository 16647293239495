import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import FormContainer from 'components/Form/FormContainer';
import Field from 'components/Form/Field';
import ButtonRow from 'components/ButtonRow';
import FormContext from 'core/contexts/Form';
import FormBackendErrorWarning from 'components/Form/BackendErrorWarning';

import actionSchoolProducts from 'store/edupay/schoolProducts/actions';

import { RejectSchoolProductFormProps, FormProps } from './types';
import { SchoolProductsState } from 'store/edupay/schoolProducts/types';

import * as S from './style';

const RejectSchoolProductForm = ({
  schoolProductId,
}: RejectSchoolProductFormProps) => {
  const dispatch = useDispatch();

  const { setHasBackendValidationError, setRedirectToSchoolProductFormError } =
    useSelector((state: SchoolProductsState) => state.schoolProducts);

  const { rejectSchoolProduct } = actionSchoolProducts;

  const onSubmit = async (formState) => {
    dispatch(rejectSchoolProduct({ schoolProductId, formState }));
  };

  const changeVariation = (form) => {
    return () =>
      form.cancellation_description && form.cancellation_description.length
        ? 'primary'
        : 'default';
  };

  const changeButtonStatus = (form) => {
    return () =>
      form.cancellation_description && form.cancellation_description.length
        ? false
        : true;
  };

  const getButtons = (form) => {
    return [
      {
        text: 'Salvar',
        variation: changeVariation(form),
        type: 'submit',
        disabled: changeButtonStatus(form),
      },
      {
        path: `/schools/school_products/${schoolProductId}`,
        text: 'Cancelar',
        variation: 'secondary',
      },
    ];
  };

  if (setRedirectToSchoolProductFormError)
    throw setRedirectToSchoolProductFormError;

  return (
    <div data-testid="reject-school-product-form">
      <FormContainer onSubmit={onSubmit}>
        {setHasBackendValidationError ? <FormBackendErrorWarning /> : null}

        <S.RejectionDescription
          className="col-xs-12"
          data-testid="rejection-description"
        >
          <div className="form-group">
            <Field
              label="Descrição*"
              placeholder="Adicionar um motivo para não aprovação do pagamento"
              attributeName="cancellation_description"
              type="simpleTextArea"
            />
          </div>
          <div
            className="col-xs-12 col-lg-8"
            data-testid="required-field-message"
          >
            *Campo de preenchimento obrigatório.
          </div>
        </S.RejectionDescription>

        <S.RejectPaymentDivider />

        <FormContext.Consumer>
          {({ form }: FormProps) => <ButtonRow buttons={getButtons(form)} />}
        </FormContext.Consumer>
      </FormContainer>
    </div>
  );
};

export default RejectSchoolProductForm;
